import React from "react"

const ShoulderArmorFancyR = ({
  colors = ["#754C28", "#282560", "#FFFFFF"]
}) => {
  return (
    <g id="ShoulderArmorFancyR">
      <path
        fill={colors[0]}
        d="M53.1222801,37.7935295l1.7971649,1.0369797c0.3210182,0.1851997,0.627636,0.4228325,1.0041542,0.454422 c0.2928123,0.0245552,0.4855309-0.0470619,0.6981277-0.2474098c0.3586807-0.3379974,0.6654587-0.771843,0.9895401-1.1642609 c0.0147209-0.0019913,0.027401-0.0068779,0.041584-0.0097275c0.3898964,0.1029434,0.7811356,0.1593552,1.1344452,0.1124535 c0.9725609-0.1291084,2.3468437-2.2757111,2.4512329-2.2654495c1.6241112,0.9906158,3.2481651,1.9812317,4.8722191,2.9718475 c0.1785355,0.1089592,0.543663,0.1060562,0.6901245-0.0653839c1.3574066-1.5885963,2.2586212-3.5043373,3.7057571-4.9979973 c1.2848587-2.0948143,3.4992294-3.8839474,3.2680969-4.1724873c-0.3739166-0.4668064-1.3567505-0.4325008-1.3693771-0.4249802 c-2.332283,1.3931923-3.3201675,2.9121094-5.1351166,4.9312191c-1.1897278-0.3956985-2.3574295-1.4579849-2.7330856-2.4680519 c-0.0185928-0.0500183-0.0603943-0.0858555-0.1100922-0.1154575c0.0195084-0.9946995,0.4398651-2.351572-0.7661972-2.5078106 c-1.0084-0.1306095-2.2612534,0.3466454-3.2673988,0.5039577c-1.8231125,0.2849674-3.5476913,0.3956985-5.3107414-0.268795 c-0.1804657-0.068018-0.7692604-0.0119267-0.6410141,0.3052235c0,0,1.0214577,3.1422691,1.2357178,3.6446152 c-0.6268845,0.5733719-1.2144966,1.1740952-1.7686348,1.8195152c-0.1299095,0.1512947,0.1784821,0.8724174,0.2278023,0.9775581 C53.6624489,36.3099136,52.8519249,37.8963089,53.1222801,37.7935295z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M57.590126,37.7690125 c-0.6728821-1.0624466-2.0717697-1.8592834-3.5591965-2.3019676c-0.0472183,0.1416588,0.1056595,0.3764648,0.1056595,0.3764648 S55.0933762,35.9805603,57.590126,37.7690125z"
      />
      <path
        opacity="0.19"
        fill="#FFF"
        d="M60.0337524,32.740097 c1.7029419,0.7143517,3.7061157,0.2277565,5.2768173,0.4249802c0.7437134,0.460392,1.874115,0.8526764,1.9478149,1.0624466 c-0.1167603,0.1773033,1.5493088-1.6451721,2.1957245-2.4790421c0.4958115-0.2479038,0.5312271,0.9207878,2.1957245-0.2833176 c-0.0618973,0.4003983-4.7134171,6.5319366-4.8164215,6.6579971c-1.3811798-0.3895645-11.1911011-5.8434448-11.1911011-5.8434448 c-0.0318069-0.0065842-1.5453644-3.4748516-1.0270348-3.0102749C54.6152763,29.2694416,57.1348495,31.524065,60.0337524,32.740097 z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M61.1316147,35.7680702 c0.0628586,0.0326385-0.2124901,0.2833214-0.4249802,0.4780998c-1.6290855-1.5936661-5.1882782-3.0102615-5.1882782-3.0102615 l0.1590652-0.1894722C55.6774216,33.0464363,59.2900391,34.8118706,61.1316147,35.7680702z"
      />
      <path
        opacity="0.19"
        fill="#FFF"
        d="M59.7327271,37.0607147 c0,0-0.0531235,1.1155663-1.7530365,0.336441c-1.6999168-0.779129-2.1780167-2.0009422-3.7716866-2.0363579 c-0.2479019-0.460392-0.4958076-0.336441-0.123951-0.5489273C54.4559097,34.5993805,57.0766106,36.688858,59.7327271,37.0607147z"
      />
      <path
        opacity="0.19"
        fill="#FFF"
        d="M57.1651459,38.1585732 c0,0-0.6374664,0.9916191-1.3457642,0.8853722c-0.7082977-0.1062431-2.5852852-1.4874229-2.5852852-1.4874229l0.2479019-0.4249802 c0,0,1.9124069,1.3103523,2.5498734,1.1686935C56.6693382,38.1585732,57.1651459,38.1585732,57.1651459,38.1585732z"
      />
      <path
        opacity="0.42"
        fill="#FFF"
        d="M63.5044098,29.1631966 c0,0,0.5666351,0.2479038,0.6020508,1.9478188c0.0708313,0.9916153,0.885376,1.5936699,0.885376,1.5936699 c0.0559006-0.0186348-2.4732704,0.1160011-4.7101784-0.5312233c-2.0144882-0.5828724-4.64851-2.3036098-5.1351585-2.9748516 c2.3373833,1.133276,5.6920509,0.2333393,7.1538048-0.0708294C63.1916237,28.9423122,63.5044098,29.1631966,63.5044098,29.1631966 z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.7153625,38.4418945l-4.5567169-2.2193336 c0,0,3.8484192,2.4318237,4.2970047,2.4318237S66.7153625,38.4418945,66.7153625,38.4418945z"
      />
    </g>
  )
}

export default ShoulderArmorFancyR
