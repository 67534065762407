import React from "react"

const WizardRobe = ({
  colors = ["#525A55", "#4C3317", "#895A24", "#474D49", "#FFFFFF", "#D7D6D5"]
}) => {
  return (
    <g id="WizardRobe">
      <g id="C_x24_wizardRobe">
        <path
          fill={colors[0]}
          d="M55.6526413,76.7671204c0.0564537,0.4887161,8.5292358,22.5837021,8.5292358,22.5837021 s2.5333481-14.7522354,1.9687805-16.2998276c-0.395195-0.8959808-2.3585396-1.4143677-3.1489258-2.6361465 c-3.0952873-0.6313324-5.0985031-11.6633759-6.2762299-13.0620728c-0.0279083-0.0331421-1.5670853-0.3179016-2.4139366-0.6437149 c-0.1701317-0.6409607-2.2983894-2.4972305-3.5404358-1.3569031c-1.0996819,0.5577393-2.7104721,2.811615-3.2185783,4.1963043 l-3.5704689-4.6318893c-1.0162201-0.9774284-2.0161438,0.3972702-3.5908699,1.7924881 c-1.5288277,0.5364304-1.8797226,0.6053314-2.5480423,1.2606125c-0.9119339,3.2185745-3.5940819,9.8703079-3.5940819,9.8703079 l-4.3610916,3.8830643c-0.9597626,0.6516266-0.8753071,3.2514496-0.8753071,3.2514496l3.2355976,13.8524628l7.0808754-22.6281509 c0.1693687,0.4887085,0.2493019,4.6824417,0.475132,8.1034393c-0.3387413,4.2355194-1.2420464,8.0637894-1.5807877,11.4033356 c-1.7501526,18.7340469-2.5405464,25.0058823-2.5405464,25.0058823H58.886631c0,0-3.1615715-26.6349335-3.3873978-29.1599579 C55.3298645,88.9450302,54.5799637,78.9663391,55.6526413,76.7671204z"
        />
        <path fill={colors[0]} d="M49.824379,63.195446" />
        <path
          opacity="0.4"
          d="M65.9969254,83.3701935c0.2822876,1.9548645-0.2057571,0.3964996-0.2057571,2.6771622 s-1.7702179,12.3915329-1.7702179,12.3915329s-3.218029-9.7742844-3.1051178-13.3581924 C61.7626839,79.8677368,65.6581879,81.4153366,65.9969254,83.3701935z"
        />
        <path
          opacity="0.4"
          d="M31.5176983,81.3720627c1.4678707,0.1629028,2.4813938,1.1312256,2.4813938,1.9457474 c0,2.2806702-1.7347946,14.4773636-1.7347946,14.4773636s-2.047802-10.5676422-2.2171707-11.7079773 S30.0498257,81.2091599,31.5176983,81.3720627z"
        />
        <path
          opacity="0.4"
          d="M48.9942055,76.0027084c0.7644119,2.4541702,0.9430885,4.1348343,1.0559998,10.4066696 c0.1693687,7.5750732,0.50811,16.2090302,0.9033051,23.5397415c0.1129112,2.1177597,1.1291313,7.982338,1.4114151,10.0186462 c0,0.0814514,0.7903938,0.1629028,0.7339363,0c-0.2258301-1.7919464-1.1855888-7.3307114-1.4114151-9.2855759 c-0.3951988-3.502449-0.6774826-6.9234467-0.8468513-10.5073547c-0.395195-8.3081436-0.2258263-15.1501465-0.5645676-20.526001 C50.0502014,76.7979965,48.9942055,76.0027084,48.9942055,76.0027084z"
        />
        <path
          fill={colors[1]}
          d="M39.4363594,81.8480453c-0.2822838,1.1403351-0.1693687,3.7468109,0.6210213,4.2355194 c1.1855888,0.6516266,2.709919,1.0588837,4.008419,1.2217865c1.8066139,0.2443619,3.9519653,0,5.7585754-0.1629028 c1.919529-0.1629028,4.2907028,0,5.3633804-1.9548569c0.112915-0.1629028-0.0564537-2.5250244-0.1693687-2.3621216 c-0.7339363,1.3032379-3.3309402,1.2217865-4.5729866,1.6290512c-1.2420464,0.4072571-2.8228302,0.3258057-4.1213341,0.4072571 c-1.185585,0.0814514-2.3711777,0.0814514-3.5567665-0.3258057c-0.9597626-0.2443619-3.6696777-0.4887161-3.4438515-1.9548645 C39.3799019,82.4182129,39.4928169,81.6036835,39.4363594,81.8480453L39.4363594,81.8480453z"
        />
        <path
          fill={colors[2]}
          d="M39.2669907,82.2553101c-0.2822838,1.1403351,0.0564537,3.2580948,1.0162163,3.7468033 c1.2420464,0.5701675,2.4840927,0.733078,3.7261391,0.8959808c1.8066101,0.2443542,3.9519615,0,5.7585754-0.1629028 c1.9195251-0.1629028,4.403614,0.0814514,5.4762917-1.791954c0.112915-0.1629028,0.2258301-2.9322815,0.112915-2.6879272 c-3.6696815,3.1766434-14.0012436,2.362114-15.9772263-0.5701675L39.2669907,82.2553101z"
        />
        <path
          fill={colors[1]}
          d="M49.2598114,84.8617783c1.2985039,0.4887161,1.6372452,6.7605515,1.8066139,8.4710541 c0.2258263,1.7919464-0.1693687,3.6653595-0.0564575,5.538765c0,0.2443619,1.4678726,1.2217865,1.4678726,0.8959732 c-0.0564537-3.2580948-0.3387375-8.7968597-1.3549614-12.1364059c-0.395195-1.3846893-0.9597626-3.0137405-2.1453514-3.2580948 C48.7517014,84.2916183,49.0904427,84.8617783,49.2598114,84.8617783L49.2598114,84.8617783z"
        />
        <path
          fill={colors[2]}
          d="M49.4291801,84.8617783c1.2985039,0.4887161,1.6372452,6.7605515,1.8066139,8.4710541 c0.2258263,1.7919464-0.1693687,3.6653595-0.0564537,5.538765c0,0.2443619,1.4678688,1.2217865,1.4678688,0.8959732 c-0.0564537-3.2580948-0.3387375-8.7968597-1.3549576-12.1364059c-0.3951988-1.3846893-0.9597626-3.0137405-2.1453514-3.2580948 C48.9210739,84.2916183,49.2598114,84.7803268,49.4291801,84.8617783L49.4291801,84.8617783z"
        />
        <path
          fill={colors[1]}
          d="M48.9775276,84.0472565c-0.1693687,3.0137329-1.3549576,7.4936218-1.2985001,10.5888138 c0,1.2217865,0.2258263,2.6879272,0.3387375,3.9911652c0.0564575,0.8145218,0.6210251,4.1540756,0.112915,4.6427841 c-0.112915,0.0814514,1.4114151,0.4887161,1.5807838,0.3258133c0.50811-0.4887161,0.0564575-1.5475998-0.0564537-2.1992111 c-0.2822838-1.7105026-0.3951988-3.5024567-0.50811-5.2944107c-0.112915-3.3395462,0.0564537-8.2266846,0.28228-11.5662384 C49.4856415,84.1287079,48.9775276,83.8028946,48.9775276,84.0472565L48.9775276,84.0472565z"
        />
        <path
          fill={colors[2]}
          d="M48.8646164,83.9658051c-0.1693687,3.0137405-1.3549614,7.4936218-1.2985039,10.5888138 c0,1.2217865,0.2258301,2.6879272,0.3387413,3.9911652c0.0564537,0.8145218,0.6210213,4.1540756,0.1129112,4.6427841 c-0.1129112,0.0814514,1.4114151,0.4887161,1.5807877,0.3258133c0.50811-0.4887161,0.0564537-1.5475998-0.0564575-2.1992111 c-0.2822838-1.7105026-0.395195-3.5024567-0.50811-5.2944107c-0.0564575-3.502449,0.112915-8.308136,0.3387375-11.6476898 C49.3727264,84.1287079,48.9210739,83.7214432,48.8646164,83.9658051L48.8646164,83.9658051z"
        />
        <path
          opacity="0.4"
          d="M39.8711319,67.1976242c0,0,1.4678688,9.2041245,7.3393593,9.2041245 s6.8312531-9.6113815,6.8312531-9.6113815s-1.7501526,9.2041168-6.7747955,8.7968521 C42.2423096,75.1799622,40.0405006,66.95327,39.8711319,67.1976242z"
        />
        <path
          opacity="0.4"
          d="M35.3461609,78.1448517c0,0,1.8066101-0.6516266,2.4276352,2.443573 C37.9996223,78.7150116,36.3623772,76.5158005,35.3461609,78.1448517z"
        />
        <path
          opacity="0.4"
          d="M59.7949142,78.1448517c0,0-1.8066139-0.6516266-2.4276352,2.443573 C57.1414528,78.7150116,58.7786942,76.5158005,59.7949142,78.1448517z"
        />
        <path
          opacity="0.4"
          d="M47.9035378,69.0529175c0.2822838,0.3258057,1.6372414,1.7919464,1.6372414,1.7919464l-0.0564537-1.058876 l1.6936989-3.7468109l-1.8066139,2.0363083v-0.4887085l0.9597626-1.7105026l-1.0162163,1.2217865v-0.4072647 C49.3149529,66.7722473,48.1858215,67.9940338,47.9035378,69.0529175z"
        />
        <path
          opacity="0.4"
          d="M41.4731102,66.2201996c0,0,2.2018089,1.791954,4.0648804,4.4798813 C44.8040504,67.1976242,42.3764153,65.8129349,41.4731102,66.2201996z"
        />
        <path
          fill={colors[3]}
          d="M41.9727783,65.7910538c0,0,1.1855888-0.651619,2.822834,1.5475998 C43.8923073,65.5466995,42.6502609,65.3023376,41.9727783,65.7910538z"
        />
        <path
          opacity="0.4"
          d="M40.8055687,66.764534c0,0,1.6936989,7.4121628,7.6781006,7.9008789 c-4.7988129-1.7105026-5.0810966-4.3984299-6.718338-8.1452408C41.4265938,65.7056503,40.8055687,66.764534,40.8055687,66.764534 z"
        />
        <path
          opacity="0.4"
          d="M52.7601242,65.4761124c0,0-1.2420464,6.8419952-4.2342491,7.7379761 c1.8066139-0.7330704,2.822834-0.8959732,4.9117279-7.3307114 C53.3246918,65.5575638,52.7601242,65.4761124,52.7601242,65.4761124z"
        />
        <path
          opacity="0.4"
          d="M38.2723122,75.6038818l-0.0779953-1.656723l-0.112915-2.8508377l0.5645676,2.7693863 l-0.5645676-4.7242432l1.2477608,7.0573425L38.2723122,75.6038818z"
        />
        <g opacity="0.4">
          <path d="M58.0670471,70.7705154c0.0564537,0,0.1129112,0,0.1693687-0.0814514 C58.2364159,70.689064,58.1799622,70.689064,58.0670471,70.7705154z" />
          <path d="M55.6794624,72.1806488c0,0,0.2145729,1.8775024,0.1072845,2.1188965 c-0.2682152-0.8314667-0.6168938-2.6821518-0.6168938-2.6821518s0.0828018,4.5895462,0.4215431,4.9968109 c0.0549774-1.4278336,0.7134018-2.4870682,1.2145691-2.8242722 C56.819416,73.7808838,55.6794624,72.1806488,55.6794624,72.1806488z" />
        </g>
        <path
          d="M50.2760315,114.917717c0,0.1629028-0.0564575,0.2443619-0.0564575,0.4072571l0,0l0,0 c0.3387413-0.4072571,0.6210251-0.8145218,1.0726776-1.0588837c0.4516525-0.2443542,0.9033051-0.4072571,1.4114151-0.4887085 c0.3387413-0.0814514,0.7903938-0.1629028,1.1855888-0.1629028 C51.7439041,111.822525,50.332489,114.8362656,50.2760315,114.917717z"
          opacity="0.4"
        />
        <path
          d="M58.4057846,119.8863068c-1.2420464,0.3258133-1.5807877-3.4209976-2.0888939-4.6427841 c-1.1855888-2.4435654-3.7261353-2.9322815-5.5327492-0.0814438c-1.2420464,1.9548569-1.4678726,4.2355194-3.7825928,4.9685898 c-2.0324402,0.6516266-2.4840927-0.9774246-3.5003128-2.7693787c-0.112915-0.2443619,1.1291313,0.8959732,1.2420464,0.9774246 c0.5645676,0.4887085,0.7903938,0.9774246,1.5807838,1.2217865c1.6372452,0.4072571,3.4438553-1.791954,3.8390503-3.7468109 c-0.9033051,0.4072647-1.5243263,1.1403351-2.4276314,1.5475998c-0.2258301,0.0814514-0.4516525,0.0814514-0.7339363,0.0814514 c-1.2985039-0.0814514-2.0324402-0.8145294-2.3147202-1.2217865c-0.4516525-1.0588837-1.0726776-1.9548645-1.7501564-2.443573 c0.112915,0.1629028,0.2258263,0.4072571,0.3387375,0.5701599c0.3387413,0.733078,0.5081139,1.4661484,1.0162201,2.0363159 c0.4516525,0.4887085,1.0726776,0.8145218,1.6372414,1.1403351c0.0564575,0,0.3387413,0.0814514,0.6774826,0.1629028 c-1.5243301,0.1628952-2.3711777-0.4072647-3.4438553-1.9548645c-1.4114151-2.1177597-2.5405464-4.4798813-4.5165291-1.7919464 c-0.8468475,1.1403351-1.2985001,6.3532867-2.8228302,5.8645706l-0.2258263,0.8959732 c2.3711777-0.0814514,21.1712341,0,21.1712341,0c0.6210213-0.1629028,1.1291313,0,1.6936989,0L58.4057846,119.8863068z"
          opacity="0.4"
        />
        <path
          fill={colors[0]}
          d="M39.0411644,116.5675888c-0.395195,0.6793289-1.2985039,1.3479919-1.6372414,1.7556 c-0.3387413,0.4075928,0,2.3776474,0,2.3776474h6.4925117c0,0-0.1693726-1.6983261-0.3387413-2.7173157 c-0.9033051-0.6793289-0.9597626-0.7366028-1.5243301-1.6197281 C41.4687996,115.4806671,39.4363594,115.9561996,39.0411644,116.5675888z"
        />
        <path
          opacity="0.2"
          fill="#FFF"
          d="M39.3060722,116.4045105c-0.1881676,0.1869354-0.4586182,0.639061-0.7095032,0.8883057 c0.4756889-0.2279892,1.4637146-0.4757843,2.6128731,1.1341934c-1.730732-1.4602356-2.1502228-1.2269287-2.6821175-1.0042572 c-0.4756927,0.2279968-0.9538727,1.0699234-0.9538727,1.0699234s1.3171616-1.3085403,2.8898468,0.6144409 c1.2755508-0.2888489,1.3292618,0.3304062,1.8636436-0.5062103c0.5343819-0.8366241,0.0586891-0.6086273-0.3623466-1.5355759 C41.5435562,116.138382,39.8705711,115.8437119,39.3060722,116.4045105z"
        />
        <path d="M43.8964348,120.7008362H37.403923l-0.0564575-0.5701675c0,0,0.1129112-0.8959732,1.2420425-0.9774246 c1.1855888-0.0814514,4.0084229,1.0588837,4.0084229,1.0588837L43.8964348,120.7008362z" />
        <path
          fill={colors[5]}
          d="M39.3340187,117.8273544c-0.0640182,0.0757675-0.2653656-0.188736-0.4901733-0.2099915 c-0.281002-0.0265656-0.5214462,0.1143417-0.5214462,0.1143417c-0.0483818-0.0863953,0.4871292,0.1033325,0.824337,0.1352158 C39.4917603,117.8177185,39.3980408,117.7515793,39.3340187,117.8273544z"
        />
        <g>
          <path
            fill={colors[0]}
            d="M55.0748444,116.2164459c0.3951988,0.614296,1.2985039,1.9657516,1.6372414,2.3343353 s0,2.1500549,0,2.1500549H50.219574c0,0,0.2258301-1.5357513,0.3387413-2.4571991 c0.9033051-0.6143036,0.9597626-1.4128952,1.5243301-2.2114792 C52.7036667,115.2335663,54.6796494,115.6021423,55.0748444,116.2164459z"
          />
          <path
            opacity="0.2"
            fill="#FFF"
            d="M55.0748444,116.5850296c0.1693687,0.184288,0.3951988,0.614296,0.6210213,0.8600159 c-0.4516525-0.2457199-1.4114151-0.5528717-2.7099152,0.7985916c1.8630676-1.1671677,2.2582626-0.9214478,2.7663727-0.6757278 c0.4516563,0.2457199,0.8468513,1.0443115,0.8468513,1.0443115s-1.1855888-1.2900314-2.9357452,0.3071442 c-1.2420464-0.368576-1.3549614,0.1842957-1.8066139-0.614296s0-0.5528717,0.50811-1.3514557 C52.7601242,116.0935822,54.6231918,116.0321579,55.0748444,116.5850296z"
          />
          <path d="M50.1631165,120.7008362h6.5489693l0.1129112-0.5701675c0,0-0.1129112-0.8959732-1.2420464-0.9774246 c-1.1855888-0.0814514-4.008419,1.0588837-4.008419,1.0588837L50.1631165,120.7008362z" />
          <path
            fill={colors[5]}
            d="M54.8034744,118.0582733c0.0564575,0.0814514,0.2822838-0.1629028,0.50811-0.1629028 c0.2822838,0,0.50811,0.1629028,0.50811,0.1629028c0.0564575-0.0814514-0.1693687-0.4072571-0.50811-0.4072571 S54.7470169,118.0582733,54.8034744,118.0582733z"
          />
        </g>
      </g>
    </g>
  )
}

export default WizardRobe
