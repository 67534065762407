import React from "react"
import { withSkinColor } from "../../builderConnector"

const RightThumb = ({ skinColor, color = [] }) => {
  return (
    <g id="RightThumb">
      <path
        fill={skinColor}
        d="M43.9439468,55.847496c0.0804443-0.0950012,0.7985573-1.1266441,1.3103523-1.3457642 c1.5155602-1.3980789,4.1329422,0.1521873,4.2425041,0.4080811c0.1168404,0.3436203-0.2095222,2.8173027-0.2754097,3.0877609 c0,0-0.4167862,0.2995644-0.7017975,0.0582275c-0.2850075-0.2413368,0,0,0,0l-0.8109779-1.2576408L43.9439468,55.847496z"
      />
      <path
        opacity="0.3"
        fill="#282560"
        d="M43.9366684,55.7597694 c0.0804443-0.0950012,0.7985573-1.1266441,1.3103485-1.3457642c2.0186501-1.0624466,4.1402245,0.2399139,4.2497864,0.4958076 c0.1168404,0.3436203-0.2168007,2.7295761-0.2826881,3.0000305c0,0-0.41679,0.2995682-0.7017975,0.0582314 c-0.2850113-0.2413368,0,0,0,0l-0.8109779-1.2576408L43.9366684,55.7597694z"
      />
    </g>
  )
}

export default withSkinColor(RightThumb, { character: "WomanMixed" })
