import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import NobelCape from "./NobelCape"
import OpenCloak from "./OpenCloak"
import SolidColor from "./SolidColor"

const CATEGORY = "background"
const CHARACTER = "ManGnome"
export const IDS = {
  NOBEL_CAPE: "NobelCape",
  OPEN_CLOAK: "OpenCloak",
  SOLID_COLOR: "SolidColor"
}

export const components = {
  [IDS.NOBEL_CAPE]: NobelCape,
  [IDS.OPEN_CLOAK]: OpenCloak,
  [IDS.SOLID_COLOR]: SolidColor
}

export const Group = ({ props }) => (
  <g id="background">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.NOBEL_CAPE,
    name: "Nobel Cape",
    defaultColors: ["#8D4D3A"],
    colorable: true,
    component: components[IDS.NOBEL_CAPE],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.OPEN_CLOAK,
    name: "Open Cloak",
    defaultColors: ["#4A6621"],
    colorable: true,
    component: components[IDS.OPEN_CLOAK],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SOLID_COLOR,
    name: "Solid Color",
    defaultColors: ["#7A7A7A"],
    colorable: true,
    component: components[IDS.SOLID_COLOR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
