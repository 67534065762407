import React from "react"

const RoundWoodShieldRight = ({
  colors = [
    "#D2A673",
    "#7B6144",
    "#675139",
    "#997954",
    "#SVGID_",
    "#36302A",
    "#726658",
    "#8F8579",
    "#FFFFFF",
    "#F6F6F6",
    "#E4E5E6",
    "#C8CACC",
    "#A2A4A7",
    "#757779",
    "#2D2D2E",
    "#000000"
  ]
}) => {
  return (
    <g id="RoundWoodShieldRight">
      <path
        fill={colors[0]}
        d="M39.5999985,74.6999969c-2.4000015-7.0999985-10.1000004-10.9000015-17.2000008-8.5 s-10.8999996,10.0999985-8.5,17.1999969L39.5999985,74.6999969z"
      />
      <path
        fill={colors[1]}
        d="M39.5999985,74.6999969C42,81.7999954,38.1999969,89.5,31.0999985,91.8999939 c-7.1000004,2.4000015-14.7999992-1.4000015-17.2000008-8.5L39.5999985,74.6999969z"
      />
      <path
        fill={colors[0]}
        d="M15.5,82.8000031C17.6000004,89,24.3999996,92.4000015,30.6000004,90.2000046 C36.7999992,88.1000061,40.2000008,81.3000031,38,75.1000061l-0.5,0.3000031 c2,5.9000015-1.2000008,12.4000015-7.1000004,14.4000015S18,88.5999985,16,82.6999969L15.5,82.8000031z"
      />
      <path
        fill={colors[2]}
        d="M38,75.0999985C35.9000015,68.9000015,29.1000004,65.5,22.8999996,67.6999969 C16.7000008,69.7999954,13.2999992,76.5999985,15.5,82.7999954l0.5-0.1999969 c-2-5.9000015,1.2000008-12.4000015,7.1000004-14.4000015S35.5,69.3999939,37.5,75.2999954L38,75.0999985z"
      />
      <circle fill={colors[3]} cx="26.8" cy="79.1" r="13" />
      <circle fill="url(#SVGID_19_)" cx="26.8" cy="79" r="3.2" />
      <circle fill={colors[0]} cx="23" cy="67.2" r="0.3" />
      <circle fill={colors[0]} cx="31" cy="90.8" r="0.3" />
      <circle fill={colors[0]} cx="15" cy="83.3" r="0.3" />
      <circle fill={colors[0]} cx="16" cy="72.6" r="0.3" />
      <circle fill={colors[0]} cx="38.6" cy="75.2" r="0.3" />
      <circle fill={colors[0]} cx="37.2" cy="85.7" r="0.3" />
      <circle fill={colors[0]} cx="31.5" cy="67.4" r="0.3" />
      <circle fill={colors[0]} cx="21.8" cy="90.4" r="0.3" />
      <path
        fill={colors[5]}
        d="M31.1000004,67.9000015L14.8999996,79.1999969c0,0.1999969,0,0.4000015,0,0.5999985 l16.7000008-11.5999985C31.5,68.0999985,31.2999992,68,31.1000004,67.9000015z"
      />
      <path
        fill={colors[5]}
        d="M36.5999985,72.1999969L36.5999985,72.1999969L17,85.8000031l0,0 C17.1000004,85.9000015,17.1000004,86,17.2000008,86.1000061l19.6000023-13.5 C36.7000008,72.5,36.7000008,72.3000031,36.5999985,72.1999969z"
      />
      <path
        fill={colors[5]}
        d="M22.6000004,90.1999969c0.2000008,0.0999985,0.2999992,0.0999985,0.5,0.1999969l15.4999981-10.6999969 c0-0.1999969,0-0.4000015,0-0.5L22.6000004,90.1999969z"
      />
      <path
        fill={colors[6]}
        d="M31.6000004,68.1999969l-16.7000008,11.5C15,81.7999954,15.6999998,83.8999939,17,85.7999954 l19.5999985-13.5C35.2999992,70.4000015,33.5999985,69,31.6000004,68.1999969z"
      />
      <path
        fill={colors[6]}
        d="M36.7999992,72.5999985l-19.5999985,13.5C18.6000004,88,20.5,89.4000015,22.6000004,90.1999969 L38.7000008,79C38.7000008,76.8000031,38.0999985,74.5999985,36.7999992,72.5999985z"
      />
      <path
        fill={colors[6]}
        d="M33.5999985,88.8000031c3.2000008-2.1999969,4.9000015-5.5999985,5.0999985-9.1999969 L23.1000004,90.3000031C26.5,91.4000015,30.3999996,91,33.5999985,88.8000031z"
      />
      <path
        fill={colors[6]}
        d="M20,69.1999969C16.6000004,71.5,14.8000002,75.2999954,14.8999996,79.0999985l16.2000008-11.1999969 C27.6000004,66.5,23.3999996,66.9000015,20,69.1999969z"
      />
      <g>
        <ellipse fill={colors[7]} cx="26.8" cy="79" rx="3.7" ry="3.7" />
        <path
          fill={colors[3]}
          d="M25.7999992,76c-1.6000004,0.5999985-2.5,2.4000015-2,4c0.6000004,1.5999985,2.3999996,2.5,4,2 c1.6000004-0.5999985,2.5-2.4000015,2-4S27.3999996,75.5,25.7999992,76z"
        />
      </g>
      <radialGradient
        id="SVGID_19_"
        cx="-283.924"
        cy="704.793"
        r="3.205"
        gradientTransform="rotate(-18.738 -2024.703 -549.323)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.698" stopColor="#fff" stopOpacity="0.1" />
        <stop offset="0.724" stopColor="#f6f6f6" stopOpacity="0.1" />
        <stop offset="0.762" stopColor="#e4e5e6" stopOpacity="0.1" />
        <stop offset="0.809" stopColor="#c8cacc" stopOpacity="0.1" />
        <stop offset="0.862" stopColor="#a2a4a7" stopOpacity="0.1" />
        <stop offset="0.921" stopColor={colors[13]} stopOpacity="0.1" />
        <stop offset="0.983" stopColor="#2d2d2e" stopOpacity="0.1" />
        <stop offset="0.995" stopOpacity="0.1" />
      </radialGradient>
    </g>
  )
}

export default RoundWoodShieldRight
