import React from "react"

const SunstaffR = ({
  colors = ["#654219", "#FFFFFF", "#282560", "#934520", "#BC8A2C", "#SVGID_"]
}) => {
  return (
    <g id="SunstaffR">
      <path
        fill={colors[0]}
        d="M44.7673645,48.9696465l4.7580223,68.0260315l1.124897,1.090416l0.8202477-1.1985703 l-3.7903709-68.1685181C45.7103539,46.7886772,44.8557701,48.9647331,44.7673645,48.9696465z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M48.5845833,61.794693l-0.1818962-3.2713814 c-0.8890762-0.0392532-1.8273849-2.559063-2.3873787-3.0600624c-0.1866646-0.1669998-0.6139946,0.1228294-0.6974945,0.2161598 l0.2212296,3.9787102C46.3790321,60.4096146,47.5825157,61.3182755,48.5845833,61.794693z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M48.8058128,65.7733994l-0.1720657-3.0945473 c-0.0884171,0.0049171-0.0933304-0.0834999-0.1817474-0.0785828c-0.4519081-0.1522522-0.90382-0.3045006-1.3606491-0.5451698 c-0.4568253-0.2406654-0.9185677-0.5697441-1.3803139-0.8988266l0.0786591,1.41465 C46.8261795,63.6662483,47.7693291,64.6780777,48.8058128,65.7733994z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M49.14011,71.7856674l-0.04916-0.8841553 c-0.9431534-1.0118256-1.9747162-2.0187378-2.9227905-3.1189804l0.1179886,2.1219788 c0.569828,0.6778259,1.1347351,1.2672424,1.7045555,1.9450684C48.3542023,72.0067444,48.7078667,71.9870834,49.14011,71.7856674z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M49.012291,69.4868622l-0.1278191-2.2988129 c-1.0904846-0.4714966-2.1122169-1.3015747-2.9522057-2.0530701l0.0589943,1.0609894 C47.0277405,67.2912903,48.0642242,68.3866196,49.012291,69.4868622z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M49.8577957,83.0979614l-0.2261429-4.0671234 c-0.9824066-0.1227493-1.9009743-0.6924973-2.7360458-1.3555756l0.1474838,2.6524734 C47.9813271,81.2511444,48.8311462,82.1794662,49.8577957,83.0979614z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M47.1070023,81.4771347l0.1179848,2.1219788 c0.8399887,0.7514954,1.753643,1.2328262,2.6378021,1.1836624l-0.0344124-0.6189041 c-0.3782463-0.4224167-0.8449059-0.8399124-1.2231522-1.2623291 C48.1336517,82.3956375,47.5736618,81.8946381,47.1070023,81.4771347z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M51.0721664,106.5330429l-0.1818962-3.2713776 c-0.8104935-0.2210007-1.6357269-0.7072525-2.372551-1.1984177l0.1229057,2.2103958 C49.4806099,105.0251389,50.3156776,105.6882248,51.0721664,106.5330429z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M50.2420883,107.5547867 c0.3634949,0.1571655,0.6385727,0.319252,1.0020676,0.4764175l-0.0344124-0.6189117 c-0.8498192-0.9283295-1.6112213-1.8615723-2.4610405-2.7899017l0.0835724,1.503067 C49.2989349,106.5429535,49.6820984,107.0537872,50.2420883,107.5547867z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M49.3416748,75.4107132l-0.0098343-0.1768265 c-0.9480667-1.1002502-1.8912163-2.1120682-2.9227829-3.118988l0,0 C47.4455414,73.210228,48.3936081,74.3104782,49.3416748,75.4107132z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M46.5270386,74.2368774l0.1081543,1.9451447 c0.093338,0.0835037,0.1866684,0.1670074,0.2799988,0.2505035c0.2799988,0.2504959,1.3901482,1.0756607,2.573967,1.630661 l-0.0294952-0.5304947C48.5115967,76.4324493,47.5635262,75.3322067,46.5270386,74.2368774z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M49.8577957,83.0979614l-0.2261429-4.0671234 c-0.9824066-0.1227493-1.9009743-0.6924973-2.7360458-1.3555756l0.1474838,2.6524734 C47.9813271,81.2511444,48.8311462,82.1794662,49.8577957,83.0979614z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M47.1070023,81.4771347l0.1179848,2.1219788 c0.8399887,0.7514954,1.753643,1.2328262,2.6378021,1.1836624l-0.0344124-0.6189041 c-0.3782463-0.4224167-0.8449059-0.8399124-1.2231522-1.2623291 C48.1336517,82.3956375,47.5736618,81.8946381,47.1070023,81.4771347z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M51.0721664,106.5330429l-0.1818962-3.2713776 c-0.8104935-0.2210007-1.6357269-0.7072525-2.372551-1.1984177l0.1229057,2.2103958 C49.4806099,105.0251389,50.3156776,105.6882248,51.0721664,106.5330429z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M50.2420883,107.5547867 c0.3634949,0.1571655,0.6385727,0.319252,1.0020676,0.4764175l-0.0344124-0.6189117 c-0.8498192-0.9283295-1.6112213-1.8615723-2.4610405-2.7899017l0.0835724,1.503067 C49.2989349,106.5429535,49.6820984,107.0537872,50.2420883,107.5547867z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M51.293396,110.5117645l-0.0639114-1.1494064 c-0.1915779-0.2554169-0.4666595-0.4174957-0.6582375-0.6729126c-0.2701645-0.0736694-0.6336555-0.230835-0.9087372-0.3929214 c-0.1817513-0.0785751-0.4568253-0.2406616-0.7319107-0.4027481l0.0344162,0.6189117 C49.7116699,109.1806793,50.5467377,109.8437576,51.293396,110.5117645z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M49.1075058,109.4803467l0.103241,1.8567276 c0.0982513,0.1719131,0.1964989,0.3438339,0.2898293,0.42733c0.1964989,0.3438263,0.5698242,0.6778259,0.8547363,1.0167465 c0.3733253,0.3339996,0.7417374,0.5795746,1.1150665,0.9135742l-0.0934105-1.6799011 C50.6204796,111.1699982,49.7755814,110.3300858,49.1075058,109.4803467z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M48.1834946,97.647377l0.1179886,2.1219788 c0.8350677,0.6630783,1.6652222,1.2377396,2.5002937,1.9008255l-0.0737419-1.3262329 c-0.4814072-0.6827469-0.8793182-1.4588242-1.3656425-2.2299805 C48.9988976,97.9568024,48.5469856,97.8045502,48.1834946,97.647377z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M47.6330299,90.9376221l0.2752304,3.3548813 l2.5936317,1.9843292l-0.1130714-2.0335693C49.5291672,93.1381073,48.6695137,92.0329514,47.6330299,90.9376221z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M50.0594368,88.3194046l-0.1720657-3.0945435 c-0.9774895-0.0343399-1.8813095-0.3388443-2.6181374-0.8300095l0.0196648,0.3536682 c0.4715767,0.5059128,0.8596535,1.1051636,1.3312302,1.6110764 C49.0966187,86.9539185,49.5780296,87.6366653,50.0594368,88.3194046z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M48.7283592,89.9011383 c0.5501595,0.3241653,1.0119019,0.653244,1.5571442,0.8889999l-0.0688286-1.2378235 c-0.9431496-1.0118256-1.8813858-1.9352417-2.7361221-2.9519806l0.0983238,1.7683105 C47.9620361,88.8794785,48.3451996,89.3903122,48.7283592,89.9011383z"
      />
      <path
        fill={colors[0]}
        d="M48.5934258,41.218338c-3.4826202-0.4271812-4.3568764,1.3952141-2.6867332,2.7213745 c1.6701469,1.3261604,3.5023727,2.3772392,3.5023727,2.3772392l-1.0311127,8.5714836l-3.2418823,0.7123909 c0,0-0.3686371-5.0347786-1.44944-8.5222473c-1.0808067-3.4874649-3.5124283-7.3432693-3.6402473-9.6420784 s3.6150589-3.5711937,5.2556267-4.3719254c1.6405716-0.8007355,2.0336418,1.483326,3.6203575,4.499218 c1.287056,2.4117317,3.4041176,2.2053261,4.2882767,2.1561661c0.2652473-0.0147476,0.4420776-0.0245819,0.4469948,0.0638351 C53.6576385,39.7837944,52.0809746,41.7339287,48.5934258,41.218338z"
      />
      <path
        opacity="0.3"
        fill={colors[2]}
        d="M53.5643082,39.7002983 c0,0-2.0138245,2.0631332-5.5457611-2.4410019c-3.8364372-3.6003151-10.2807274,1.7245941-3.6491699,9.3378792 c0.1915817,0.2554092,3.7872849,2.7161522,3.8219185,8.1242599c0.0049171,0.0884132,0.0098343,0.1768341,0.0147514,0.2652473 l0.0884132-0.0049171l1.0311127-8.5714836c0,0-1.8322258-1.0510826-3.5023689-2.377243 c-1.6701469-1.3261566-0.8843117-3.1436348,2.6867294-2.7213707 C52.0809746,41.7339287,53.5643082,39.7002983,53.5643082,39.7002983z"
      />
      <radialGradient
        id="SVGID_8_"
        cx="380.101"
        cy="170.114"
        r="3.51"
        gradientTransform="matrix(.05552 .99846 1.99692 -.11103 -315.105 -320.384)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={colors[3]} />
        <stop offset="0.824" stopColor="#bc8a2c" />
      </radialGradient>
      <radialGradient
        id="SVGID_9_"
        cx="250.737"
        cy="615.614"
        r="1.39"
        gradientTransform="scale(-.99999 .99999) rotate(48.183 748.393 -4.155)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="SVGID_10_"
        cx="121.473"
        cy="321.059"
        r="2.062"
        gradientTransform="matrix(-1.06279 1.18794 1.4546 1.30135 -291.868 -519.326)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_8_)" cx="45.7" cy="40.226" r="3.542" />
      <circle
        opacity="0.85"
        fill="url(#SVGID_9_)"
        cx="45.322"
        cy="38.207"
        r="1.417"
      />
      <path
        opacity="0.42"
        fill="url(#SVGID_10_)"
        d="M43.0330009,40.1084862 c1.1689949-1.3066444,3.4825592-1.16922,5.1576118,0.2453575s1.9846268,3.7919655,0.8156319,5.0986137 s-3.4776344,1.257637-5.1576118-0.2453537C42.1735802,43.7925262,41.8689232,41.5035477,43.0330009,40.1084862z"
      />
      <g opacity="0.25" fill="#FFF">
        <path d="M43.4704704,46.3810844c0.0147476,0.2652473,0.1179123,0.5255775,0.2210732,0.7859077 c1.0857201,3.5758858,1.44944,8.5222511,1.44944,8.5222511l0.8743248-0.2259941 c-0.1033897-5.04953-2.1616821-8.5713348-2.1616821-8.5713348C43.7553787,46.7200012,43.568718,46.5530014,43.4704704,46.3810844 z" />
        <path d="M53.2941475,39.6266251c-0.9725723,0.0540771-3.0945511,0.1720657-4.2882729-2.1561661 c-1.4983025-3.0208054-1.9797859-5.2999496-3.6203575-4.499218c-1.6356468,0.8891487-5.3785324,2.1615334-5.2556305,4.3719292 c0.0147514,0.2652473,0.0344162,0.6189079,0.1424942,0.9676552c-0.0098343-0.1768303-0.0147476-0.2652473,0.0638313-0.4469948 c0.6580849-2.519886,4.1012383-5.9928303,6.6752739-2.76577c1.2378922,1.5275726,1.5965462,3.1927261,2.608448,3.8459702 C51.1918335,40.098278,52.9454002,39.7347069,53.2941475,39.6266251z" />
        <path d="M53.6576347,39.7837906c-0.0049133-0.0884132-0.1817513-0.0785828-0.3585777-0.0687485 l0.2750816,0.1620827C53.6576385,39.7837944,53.6576385,39.7837944,53.6576347,39.7837906z" />
      </g>
    </g>
  )
}

export default SunstaffR
