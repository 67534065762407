import React from "react"

const Goggles = ({
  colors = [
    "#594F3C",
    "#848484",
    "#FFFFFF",
    "#996600",
    "#FFEC15",
    "#663300",
    "#SVGID_",
    "#3A2803"
  ]
}) => {
  return (
    <g id="Goggles">
      <path
        fill={colors[0]}
        d="M50.3864861,53.2721634c-0.9431152,0-1.727356,0.4812622-2.223877,1.1654663h-1.1981812 c-0.496521-0.6842041-1.2807617-1.1654663-2.223877-1.1654663c-1.614624,0-3.701355,1.4450684-3.701355,2.8967285 c0,1.4517212,2.1403809,2.8967285,3.7550049,2.8967285c0.7338867,0,1.3574829-0.3184204,1.8285522-0.7925415 c0,0,0.4785614-0.5364113,0.9479408-0.5364113c0.4693756,0,0.9335899,0.5364113,0.9335899,0.5364113 c0.4710693,0.4741211,1.0946655,0.7925415,1.8285522,0.7925415c1.614624,0,3.7550049-1.4450073,3.7550049-2.8967285 C54.087841,54.7172318,52.0011101,53.2721634,50.3864861,53.2721634z M45.6839714,57.936409 c-0.5137329,0.2666016-1.3421021,0.1635132-1.8328857,0.1172485c-0.8405762-0.0792847-1.4697876-0.7443237-1.440979-1.6068115 c0.0264893-0.793457,0.5159912-1.9428711,1.3050537-2.2506714c0.3491821-0.1361694,0.7757568-0.0993652,1.1424561-0.1057739 c0.3703003-0.0064697,0.739563-0.0272827,1.0968628,0.0800171c-0.2338867-0.0702515,0.213623,0.1760254,0.4585571,0.2750854 c0.9208984,0.3723755,1.020813,1.8024292,0.6248169,2.5404053c-0.3248291,0.6052856-0.7843018,0.8434448-1.31604,0.9396973 C45.7097893,57.9298782,45.6959953,57.9320755,45.6839714,57.936409z M51.2759514,58.0536575 c-0.4907837,0.0462646-1.3191528,0.149353-1.8328857-0.1172485c-0.0120239-0.0043335-0.0258179-0.0065308-0.0378418-0.0108032 c-0.5317383-0.0962524-0.9912109-0.3344116-1.31604-0.9396973c-0.3959961-0.7379761-0.2960815-2.1680298,0.6248169-2.5404053 c0.2449341-0.0990601,0.6924438-0.3453369,0.4585571-0.2750854c0.3572998-0.1072998,0.7265625-0.0864868,1.0968628-0.0800171 c0.3666992,0.0064087,0.7932739-0.0303955,1.1424561,0.1057739c0.7890625,0.3078003,1.2785645,1.4572144,1.3050537,2.2506714 C52.745739,57.3093338,52.1165276,57.9743729,51.2759514,58.0536575z"
      />
      <circle
        opacity="0.67"
        fill={colors[1]}
        cx="44.805"
        cy="56.136"
        r="1.921"
      />
      <circle opacity="0.5" fill="#FFF" cx="45.423" cy="55.241" r="0.332" />
      <linearGradient
        id="SVGID_7_"
        gradientUnits="userSpaceOnUse"
        x1="42.294"
        y1="56.136"
        x2="47.315"
        y2="56.136"
      >
        <stop offset="0" stopColor="#960" />
        <stop offset="0.515" stopColor={colors[4]} />
        <stop offset="0.998" stopColor="#630" />
      </linearGradient>
      <path
        fill="url(#SVGID_7_)"
        d="M44.8050995,53.6254387c-1.3842773,0-2.5107422,1.1264648-2.5107422,2.5107422 s1.1264648,2.5107422,2.5107422,2.5107422s2.5102539-1.1264648,2.5102539-2.5107422S46.1893768,53.6254387,44.8050995,53.6254387 z M44.8048553,57.9466057c-0.9998779,0-1.8104248-0.8105469-1.8104248-1.8104248s0.8105469-1.8104858,1.8104248-1.8104858 s1.8104248,0.8106079,1.8104248,1.8104858S45.8047333,57.9466057,44.8048553,57.9466057z"
      />
      <path
        opacity="0.67"
        fill={colors[1]}
        d="M44.9119568,54.341629c-0.3391113,0-0.6541138,0.1038208-0.9155273,0.2816162 c-0.1842041,0.2669678-0.2936401,0.5900879-0.2936401,0.9398193c0,0.9115601,0.7334595,1.6505127,1.6383057,1.6505127 c0.3390503,0,0.6539917-0.1038208,0.9153442-0.2815552c0.1842041-0.2669678,0.2937622-0.5900269,0.2937622-0.9398193 C46.5502014,55.0806427,45.8167419,54.341629,44.9119568,54.341629z"
      />
      <path
        fill={colors[7]}
        d="M44.8050995,53.825634c-1.2739258,0-2.3105469,1.0366211-2.3105469,2.3105469 s1.0366211,2.3105469,2.3105469,2.3105469s2.3100586-1.0366211,2.3100586-2.3105469S46.0790253,53.825634,44.8050995,53.825634z M44.8048553,57.9466667c-0.999939,0-1.8104858-0.8106079-1.8104858-1.8104858s0.8105469-1.8104248,1.8104858-1.8104248 c0.9998779,0,1.8104248,0.8105469,1.8104248,1.8104248S45.8047333,57.9466667,44.8048553,57.9466667z"
      />
      <g>
        <circle
          opacity="0.67"
          fill={colors[1]}
          cx="50.322"
          cy="56.136"
          r="1.921"
        />
        <circle opacity="0.5" fill="#FFF" cx="50.894" cy="55.281" r="0.332" />
        <linearGradient
          id="SVGID_8_"
          gradientUnits="userSpaceOnUse"
          x1="35.737"
          y1="56.136"
          x2="40.757"
          y2="56.136"
          gradientTransform="translate(12.075)"
        >
          <stop offset="0" stopColor="#960" />
          <stop offset="0.515" stopColor={colors[4]} />
          <stop offset="0.998" stopColor="#630" />
        </linearGradient>
        <path
          fill="url(#SVGID_8_)"
          d="M50.3224106,53.6254387c-1.3842773,0-2.5107422,1.1264648-2.5107422,2.5107422 s1.1264648,2.5107422,2.5107422,2.5107422s2.5102539-1.1264648,2.5102539-2.5107422S51.7066879,53.6254387,50.3224106,53.6254387 z M50.3221664,57.9466057c-0.9998779,0-1.8104248-0.8105469-1.8104248-1.8104248s0.8105469-1.8104858,1.8104248-1.8104858 s1.8104248,0.8106079,1.8104248,1.8104858S51.3220444,57.9466057,50.3221664,57.9466057z"
        />
        <path
          opacity="0.67"
          fill={colors[1]}
          d="M50.3836632,54.3818626c-0.3391113,0-0.6541138,0.1038208-0.9155273,0.2816162 c-0.1842041,0.2669678-0.2936401,0.5900879-0.2936401,0.9398193c0,0.9115601,0.7334595,1.6505127,1.6383057,1.6505127 c0.3390503,0,0.6539917-0.1038208,0.9153442-0.2815552c0.1842041-0.2669678,0.2937622-0.5900269,0.2937622-0.9398193 C52.0219078,55.1208763,51.2884483,54.3818626,50.3836632,54.3818626z"
        />
        <path
          fill={colors[7]}
          d="M48.0118637,56.1361809c0,1.2739258,1.0361328,2.3105469,2.3100586,2.3105469 s2.3105469-1.0366211,2.3105469-2.3105469s-1.0366211-2.3105469-2.3105469-2.3105469 S48.0118637,54.8622551,48.0118637,56.1361809z M48.5117416,56.1361809c0-0.9998779,0.8105469-1.8104248,1.8104248-1.8104248 c0.999939,0,1.8104858,0.8105469,1.8104858,1.8104248s-0.8105469,1.8104858-1.8104858,1.8104858 C49.3222885,57.9466667,48.5117416,57.1360588,48.5117416,56.1361809z"
        />
      </g>
    </g>
  )
}

export default Goggles
