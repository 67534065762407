import React from "react"

const DaggerLeft = ({
  colors = [
    "#6E5E26",
    "#9AB8DB",
    "#6B92C0",
    "#57779C",
    "#E2E4E5",
    "#A0A2A5",
    "#EED793"
  ]
}) => {
  return (
    <g id="DaggerLeft">
      <polygon
        fill={colors[0]}
        points="73.9,74.3 75,76.2 71.9,77.6 71,80.9 69.3,85 69.5,86.5 68.4,87.5 67.1,87.1 66.7,85.6 67.6,84.7 68.6,80.2 70,77 68.3,74 70.4,73.1"
      />
      <polygon
        fill={colors[1]}
        points="67.2,87 67.6,86.6 68.2,86.8 68.3,87.4"
      />
      <polygon
        fill={colors[2]}
        points="68.4,87.4 68.3,86.8 68.9,86.3 69.5,86.5"
      />
      <polygon
        fill={colors[1]}
        points="67.2,86.9 67.5,86.5 67.4,85.8 66.8,85.6"
      />
      <polygon
        fill={colors[3]}
        points="67.7,84.8 67.9,85.3 68.7,85.5 69.2,85.2"
      />
      <polygon
        fill={colors[2]}
        points="66.9,85.5 67.4,85.7 67.8,85.3 67.7,84.8"
      />
      <polygon
        fill={colors[3]}
        points="69,86.2 68.8,85.6 69.2,85.3 69.4,86.4"
      />
      <polygon
        fill={colors[2]}
        points="67.5,85.7 67.7,86.5 68.3,86.7 68.8,86.2 68.7,85.7 67.9,85.4"
      />
      <path
        fill={colors[4]}
        d="M74,75.7l-2.2-0.9l-2.4-0.5c0,0,1.4-2.5,2.3-7.8c1.4-5.3,5.6-9,5.6-9l0,0L74,75.7z"
      />
      <path
        fill={colors[5]}
        d="M71.8,74.8l5.5-17.3c0,0,0.8,6.3-1.1,11.4c-1.6,3.5-2.3,6.9-2.3,6.9L71.8,74.8z"
      />
      <path
        fill={colors[6]}
        d="M70.1,82.8l-0.8,2c-0.2,0.1-0.5,0.1-0.7,0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.5-0.4l0.7-3.5 C69,81.7,69.4,82.3,70.1,82.8z"
      />
      <path
        fill={colors[6]}
        d="M69.1,79.4l1-2.3l0.7,1l1-0.4l-0.8,3l-0.7,1.6C69.6,81.7,69.2,80.3,69.1,79.4z"
      />
    </g>
  )
}

export default DaggerLeft
