import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GoldAxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GoldAxeRight") ? (
    <g id="GoldAxeRight">
      <path
        id="S_x24_goldAxeRight"
        d="M35,111L17.3,77l-1.9,1.6C9.8,78.4,5,75.5,2.5,71l0,0C0,66.5,0.1,60.9,2.7,55.9l4.1-1.2l3.1-4.2 l2.4-1.4l0.6-0.4h0.3v-0.1l0.4-0.2l4.3-0.4l0.4-0.1l0,0h0.5l3.1-2.7c5.6,0.3,10.4,3.2,12.9,7.7l0,0c2.5,4.5,2.4,10.1-0.2,15.1 L32,68.8c4.8,9.4,9.5,18.6,11.2,21.6c2.4,4.3,6.4,9.7,7.6,11.3l1,6.6c-0.3,0.9-0.7,1.7-1.2,2.5l-4.1,3l-3.4,0.9L35,111z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GoldAxeRight, {
  character: "ManDwarf"
})
