import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Mouth from "./Mouth"
import WideMouth from "./WideMouth"
import SmallMouth from "./SmallMouth"
import Tusks from "./Tusks"
import Nose from "./Nose"
import WideNose from "./WideNose"
import LargeNose from "./LargeNose"
import Brows from "./Brows"
import ThickBrows from "./ThickBrows"
import HighBrow from "./HighBrow"
import ElfEars from "./ElfEars"
import Eyes from "./Eyes"
import PointedEyes from "./PointedEyes"
import AlmondEyes from "./AlmondEyes"
import Eyepatch from "./Eyepatch"

const CATEGORY = "face"
const CHARACTER = "ManMixed"
export const IDS = {
  MOUTH: "Mouth",
  WIDE_MOUTH: "WideMouth",
  SMALL_MOUTH: "SmallMouth",
  TUSKS: "Tusks",
  NOSE: "Nose",
  WIDE_NOSE: "WideNose",
  LARGE_NOSE: "LargeNose",
  BROWS: "Brows",
  THICK_BROWS: "ThickBrows",
  HIGH_BROW: "HighBrow",
  ELF_EARS: "ElfEars",
  EYES: "Eyes",
  POINTED_EYES: "PointedEyes",
  ALMOND_EYES: "AlmondEyes",
  EYEPATCH: "Eyepatch"
}

export const components = {
  [IDS.MOUTH]: Mouth,
  [IDS.WIDE_MOUTH]: WideMouth,
  [IDS.SMALL_MOUTH]: SmallMouth,
  [IDS.TUSKS]: Tusks,
  [IDS.NOSE]: Nose,
  [IDS.WIDE_NOSE]: WideNose,
  [IDS.LARGE_NOSE]: LargeNose,
  [IDS.BROWS]: Brows,
  [IDS.THICK_BROWS]: ThickBrows,
  [IDS.HIGH_BROW]: HighBrow,
  [IDS.ELF_EARS]: ElfEars,
  [IDS.EYES]: Eyes,
  [IDS.POINTED_EYES]: PointedEyes,
  [IDS.ALMOND_EYES]: AlmondEyes,
  [IDS.EYEPATCH]: Eyepatch
}

export const Group = ({ props }) => (
  <g id="face">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.MOUTH,
    name: "Mouth",
    defaultColors: ["#CB776E", "#282560"],
    colorable: true,
    component: components[IDS.MOUTH],
    inUI: true,
    enabled: true,
    subGroupId: 1
  },
  {
    id: IDS.WIDE_MOUTH,
    name: "Wide Mouth",
    defaultColors: ["#CB776E", "#282560"],
    colorable: true,
    component: components[IDS.WIDE_MOUTH],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.SMALL_MOUTH,
    name: "Small Mouth",
    defaultColors: ["#282560", "#905A55"],
    colorable: true,
    component: components[IDS.SMALL_MOUTH],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.TUSKS,
    name: "Tusks",
    defaultColors: ["#E5E5E5"],
    colorable: false,
    component: components[IDS.TUSKS],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.NOSE,
    name: "Nose",
    defaultColors: ["#282560", "#EC1C24", "#SVGID_", "#FFFFFF"],
    colorable: false,
    component: components[IDS.NOSE],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.WIDE_NOSE,
    name: "Wide Nose",
    defaultColors: ["#282560", "#SVGID_", "#FFFFFF", "#EC1C24"],
    colorable: false,
    component: components[IDS.WIDE_NOSE],
    inUI: true,
    enabled: true,
    subGroupId: 3
  },
  {
    id: IDS.LARGE_NOSE,
    name: "Large Nose",
    defaultColors: ["#282560", "#SVGID_", "#FFFFFF", "#EC1C24"],
    colorable: false,
    component: components[IDS.LARGE_NOSE],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.BROWS,
    name: "Brows",
    defaultColors: ["#754C28"],
    colorable: true,
    component: components[IDS.BROWS],
    inUI: true,
    enabled: true,
    subGroupId: 4
  },
  {
    id: IDS.THICK_BROWS,
    name: "Thick Brows",
    defaultColors: ["#754C28"],
    colorable: true,
    component: components[IDS.THICK_BROWS],
    inUI: true,
    enabled: false,
    subGroupId: 4
  },
  {
    id: IDS.HIGH_BROW,
    name: "High Brow",
    defaultColors: ["#282560", "#754C28"],
    colorable: true,
    component: components[IDS.HIGH_BROW],
    inUI: true,
    enabled: false,
    subGroupId: 4
  },
  {
    id: IDS.ELF_EARS,
    name: "Elf Ears",
    defaultColors: ["#CD977A", "#SVGID_", "#EC1C24", "#262261"],
    colorable: false,
    component: components[IDS.ELF_EARS],
    inUI: true,
    enabled: false,
    subGroupId: 5
  },
  {
    id: IDS.EYES,
    name: "Eyes",
    defaultColors: ["#78D1AD"],
    colorable: true,
    component: components[IDS.EYES],
    inUI: true,
    enabled: true,
    subGroupId: 6
  },
  {
    id: IDS.POINTED_EYES,
    name: "Pointed Eyes",
    defaultColors: ["#78D1AD"],
    colorable: true,
    component: components[IDS.POINTED_EYES],
    inUI: true,
    enabled: false,
    subGroupId: 6
  },
  {
    id: IDS.ALMOND_EYES,
    name: "Almond Eyes",
    defaultColors: ["#78D1AD"],
    colorable: true,
    component: components[IDS.ALMOND_EYES],
    inUI: true,
    enabled: false,
    subGroupId: 6
  },
  {
    id: IDS.EYEPATCH,
    name: "Eyepatch",
    defaultColors: ["#3D3D3D"],
    colorable: true,
    component: components[IDS.EYEPATCH],
    inUI: true,
    enabled: false,
    subGroupId: 7
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
