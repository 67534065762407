import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BroadSwordL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BroadSwordL") ? (
    <g id="BroadSwordL">
      <path
        id="path_4_"
        d="M85.883255,70.6817322l-1.7248535-1.3981857l-2.5615234-6.1521187 c0,0,0.2278671-2.2908669,0.4613037-4.6815796l-2.4842911-3.9444008l-0.0939255-1.9081879l2.6742477-3.0558815 l3.9103317-55.7687988l0.1722107-1.2924185l1.1379547-4.2432356l1.0266342-2.2463379l0.5437546-0.7709274l11.9869614,0.5681143 c0.4546967,0.8265915,2.9111557,5.270215,3.0656128,9.6856585c0.0608826,0.7044809,0.1050644,1.7084968-1.0683823,17.3674564 c-0.8899078,12.3696423-2.0967636,28.6276741-2.7695847,37.103363l3.5603256,4.4050102l-0.0170517,2.1023064l-4.4384003,4.1593933 l-0.1450806,4.3987503l-4.7661209,6.4446869l-1.5310745,0.5155716L85.883255,70.6817322z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BroadSwordL, {
  character: "WomanMixed"
})
