import React from "react"

const Antlers = ({ colors = ["#A07955", "#000000", "#SVGID_"] }) => {
  return (
    <g id="Antlers">
      <path
        fill={colors[0]}
        d="M63.6,11.4c0,0.7,0,1.5,0.9,1.1c0.5-0.2,1-0.6,1.2-1.1c0.1-0.3,0.2-0.6,0.1-0.9c-0.1-0.3-0.5-0.5-0.7-0.8 c-0.4-0.6-0.4-1.3-0.4-2c0-0.3,0.1-0.7,0.2-1s0.2-0.6,0.4-0.9c0.2-0.5,0.1-1.1,0.1-1.7s0.2-1.1,0.3-1.7c0-0.2,0.1-0.4,0.1-0.7 s0-0.6-0.3-0.3c-0.4,0.4-0.6,1.2-0.7,1.7c-0.2,0.9-0.3,1.8-0.7,2.6c-0.1,0.2-0.4,0.6-0.6,0.4c-0.4-0.2-0.6-0.7-0.9-1 c-0.5-0.6-1.1-1.1-1.7-1.5c-0.7-0.5-1.6-0.8-2.5-1c-1-0.3-2.5-0.1-3-1.1C55,0.9,55,0.2,55-0.4s0-1.2,0.1-1.8 c0.2-1.1,0.8-2.1,1.3-3.1c0,0,0.3-0.7,0.4-0.8c0-0.1,0.3-0.5,0.3-0.5c-0.2,0.2-0.6,0.4-0.8,0.6c-0.7,0.6-1.2,1.3-1.6,2 c-0.3,0.6-0.7,1.1-0.9,1.8c-0.2,0.9-0.2,1.8-0.1,2.7C53.9,0.9,54,1.3,54,1.8c0,0.6-0.2,1.1-0.5,1.5c-0.2,0.4-0.6,0.7-0.8,1.1 c-0.1,0.3-0.4,0.5-0.5,0.7c-0.4,0.6-1.2,0.7-1.8,0.4c-0.4-0.2-0.8-0.7-1.1-1.3c0,0.2,0,0.4-0.1,0.6c-0.1,0.6,0.2,1.2,0.7,1.7 c0.3,0.3,0.6,0.4,1,0.5c0.1,0,1,0.2,1,0.3c0.1,1.4,0.4,3,1.7,3.8c0.4,0.2,0.8,0.5,1.3,0.6c1,0.3,2.1,0.4,3.1,0.4 c0.6,0,1.7,0.1,2.1-0.4c0.5-0.4-0.7-0.4-0.9-0.4c-0.7,0-1.3,0-1.9-0.1c-0.6-0.1-1.2-0.2-1.8-0.4c-1.3-0.5-2.4-1.9-2.1-3.4 c0.2-1,0.9-2.1,1.8-2.6c1.7-1.1,4-0.4,5.4,0.8c0.6,0.5,1.2,1.1,1.6,1.8C62.5,8,62.7,8.7,63,9.3C63.1,10,63.6,10.6,63.6,11.4z"
      />
      <path
        fill={colors[0]}
        d="M74.7,11.4c0,0.7,0,1.5-0.9,1.1c-0.5-0.2-1-0.6-1.2-1.1c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.5-0.5,0.7-0.8 c0.4-0.6,0.4-1.3,0.4-2c0-0.3-0.1-0.7-0.1-1c-0.1-0.3-0.2-0.6-0.4-0.9C72.9,5.3,73,4.7,73,4.1S72.8,3,72.7,2.4 c0-0.2-0.1-0.4-0.1-0.7s0-0.6,0.3-0.3c0.4,0.4,0.6,1.2,0.7,1.7c0.2,0.9,0.3,1.8,0.7,2.6c0.1,0.2,0.4,0.6,0.6,0.4 c0.4-0.2,0.6-0.7,0.9-1c0.5-0.6,1.1-1.1,1.7-1.5c0.7-0.5,1.6-0.8,2.5-1c1-0.3,2.5-0.1,3-1.1c0.3-0.6,0.4-1.3,0.3-1.9 c0-0.6,0-1.2-0.1-1.8c-0.2-1.1-0.8-2.1-1.3-3.1c0,0-0.3-0.7-0.4-0.8c0-0.1-0.3-0.5-0.3-0.5C81.4-6.4,81.8-6.2,82-6 c0.7,0.6,1.2,1.3,1.6,2c0.3,0.6,0.7,1.1,0.9,1.8c0.2,0.9,0.2,1.8,0.1,2.7c-0.1,0.5-0.2,0.9-0.2,1.4c0,0.6,0.2,1.1,0.5,1.5 c0.2,0.4,0.6,0.7,0.8,1.1c-0.1,0.2,0.1,0.4,0.3,0.7c0.4,0.6,1.2,0.7,1.8,0.4c0.5-0.3,0.9-0.8,1.2-1.4c0,0.2,0,0.4,0.1,0.6 c0.1,0.6-0.2,1.2-0.7,1.7c-0.3,0.3-0.6,0.4-1,0.5c-0.1,0-1,0.2-1,0.3c-0.1,1.4-0.4,3-1.7,3.8c-0.4,0.2-0.8,0.5-1.3,0.6 c-1,0.3-2.1,0.4-3.1,0.4c-0.6,0-1.7,0.1-2.1-0.4c-0.4-0.5,0.7-0.5,0.9-0.5c0.7,0,1.3,0,1.9-0.1s1.2-0.2,1.8-0.4 c1.3-0.5,2.4-1.9,2.1-3.4c-0.2-1-0.9-2.1-1.8-2.6c-1.7-1.1-4-0.4-5.4,0.8c-0.6,0.5-1.2,1.1-1.6,1.8c-0.3,0.6-0.5,1.3-0.8,1.9 C75.2,10,74.7,10.6,74.7,11.4z"
      />
      <g id="grad" opacity="0.69">
        <linearGradient
          id="SVGID_8_"
          gradientUnits="userSpaceOnUse"
          x1="57.693"
          y1="1302.476"
          x2="57.371"
          y2="1272.06"
          gradientTransform="translate(0 -1280)"
        >
          <stop offset="0" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="SVGID_9_"
          gradientUnits="userSpaceOnUse"
          x1="80.94"
          y1="1302.23"
          x2="80.618"
          y2="1271.814"
          gradientTransform="translate(0 -1280)"
        >
          <stop offset="0" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <path
          fill="url(#SVGID_8_)"
          d="M63.6,11.4c0,0.7,0,1.5,0.9,1.1c0.5-0.2,1-0.6,1.2-1.1c0.1-0.3,0.2-0.6,0.1-0.9 c-0.1-0.3-0.5-0.5-0.7-0.8c-0.4-0.6-0.4-1.3-0.4-2c0-0.3,0.1-0.7,0.2-1s0.2-0.6,0.4-0.9c0.2-0.5,0.1-1.1,0.1-1.7s0.2-1.1,0.3-1.7 c0-0.2,0.1-0.4,0.1-0.7s0-0.6-0.3-0.3c-0.4,0.4-0.6,1.2-0.7,1.7c-0.2,0.9-0.3,1.8-0.7,2.6c-0.1,0.2-0.4,0.6-0.6,0.4 c-0.4-0.2-0.6-0.7-0.9-1c-0.5-0.6-1.1-1.1-1.7-1.5c-0.7-0.5-1.6-0.8-2.5-1c-1-0.3-2.5-0.1-3-1.1C55,0.9,55,0.2,55-0.4 s0-1.2,0.1-1.8c0.2-1.1,0.8-2.1,1.3-3.1c0,0,0.3-0.7,0.4-0.8c0-0.1,0.3-0.5,0.3-0.5c-0.2,0.2-0.6,0.4-0.8,0.6 c-0.7,0.6-1.2,1.3-1.6,2c-0.3,0.6-0.7,1.1-0.9,1.8c-0.2,0.9-0.2,1.8-0.1,2.7C53.9,0.9,54,1.3,54,1.8c0,0.6-0.2,1.1-0.5,1.5 c-0.2,0.4-0.6,0.7-0.8,1.1c-0.1,0.3-0.4,0.5-0.5,0.7c-0.4,0.6-1.2,0.7-1.8,0.4c-0.4-0.2-0.8-0.7-1.1-1.3c0,0.2,0,0.4-0.1,0.6 c-0.1,0.6,0.2,1.2,0.7,1.7c0.3,0.3,0.6,0.4,1,0.5c0.1,0,1,0.2,1,0.3c0.1,1.4,0.4,3,1.7,3.8c0.4,0.2,0.8,0.5,1.3,0.6 c1,0.3,2.1,0.4,3.1,0.4c0.6,0,1.7,0.1,2.1-0.4c0.5-0.4-0.7-0.4-0.9-0.4c-0.7,0-1.3,0-1.9-0.1c-0.6-0.1-1.2-0.2-1.8-0.4 c-1.3-0.5-2.4-1.9-2.1-3.4c0.2-1,0.9-2.1,1.8-2.6c1.7-1.1,4-0.4,5.4,0.8c0.6,0.5,1.2,1.1,1.6,1.8C62.5,8,62.7,8.7,63,9.3 C63.1,10,63.6,10.6,63.6,11.4z"
        />
        <path
          fill="url(#SVGID_9_)"
          d="M74.7,11.4c0,0.7,0,1.5-0.9,1.1c-0.5-0.2-1-0.6-1.2-1.1c-0.1-0.3-0.2-0.6-0.1-0.9 c0.1-0.3,0.5-0.5,0.7-0.8c0.4-0.6,0.4-1.3,0.4-2c0-0.3-0.1-0.7-0.1-1c-0.1-0.3-0.2-0.6-0.4-0.9C72.9,5.3,73,4.7,73,4.1 S72.8,3,72.7,2.4c0-0.2-0.1-0.4-0.1-0.7s0-0.6,0.3-0.3c0.4,0.4,0.6,1.2,0.7,1.7c0.2,0.9,0.3,1.8,0.7,2.6c0.1,0.2,0.4,0.6,0.6,0.4 c0.4-0.2,0.6-0.7,0.9-1c0.5-0.6,1.1-1.1,1.7-1.5c0.7-0.5,1.6-0.8,2.5-1c1-0.3,2.5-0.1,3-1.1c0.3-0.6,0.4-1.3,0.3-1.9 c0-0.6,0-1.2-0.1-1.8c-0.2-1.1-0.8-2.1-1.3-3.1c0,0-0.3-0.7-0.4-0.8c0-0.1-0.3-0.5-0.3-0.5C81.4-6.4,81.8-6.2,82-6 c0.7,0.6,1.2,1.3,1.6,2c0.3,0.6,0.7,1.1,0.9,1.8c0.2,0.9,0.2,1.8,0.1,2.7c-0.1,0.5-0.2,0.9-0.2,1.4c0,0.6,0.2,1.1,0.5,1.5 c0.2,0.4,0.6,0.7,0.8,1.1c-0.1,0.2,0.1,0.4,0.3,0.7c0.4,0.6,1.2,0.7,1.8,0.4c0.5-0.3,0.9-0.8,1.2-1.4c0,0.2,0,0.4,0.1,0.6 c0.1,0.6-0.2,1.2-0.7,1.7c-0.3,0.3-0.6,0.4-1,0.5c-0.1,0-1,0.2-1,0.3c-0.1,1.4-0.4,3-1.7,3.8c-0.4,0.2-0.8,0.5-1.3,0.6 c-1,0.3-2.1,0.4-3.1,0.4c-0.6,0-1.7,0.1-2.1-0.4c-0.4-0.5,0.7-0.5,0.9-0.5c0.7,0,1.3,0,1.9-0.1s1.2-0.2,1.8-0.4 c1.3-0.5,2.4-1.9,2.1-3.4c-0.2-1-0.9-2.1-1.8-2.6c-1.7-1.1-4-0.4-5.4,0.8c-0.6,0.5-1.2,1.1-1.6,1.8c-0.3,0.6-0.5,1.3-0.8,1.9 C75.2,10,74.7,10.6,74.7,11.4z"
        />
      </g>
    </g>
  )
}

export default Antlers
