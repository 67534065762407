import React from "react"

const FlameLeft = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameLeft">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M69.053894,89.9493561c-1.8000031-0.5999985-3.5-1.1999969-5-1.9000015c-0.3000031-0.5-0.6999969-1-1-1.5 c-0.7999992-1.3000031-1.5-2.5999985-2.0999985-4c0.0999985-0.4000015,0.2999992-0.8000031,0.4000015-1.1999969 c0.2000008-0.5,0.5-1,0.7000008-1.5c0.4000015-0.6999969,0.7999992-1.5,1.1000023-2.3000031l1-0.1999969l-0.5000076-0.6999969 l-0.0999985-0.0999985c2.0999985-1.3000031,3.4000015-2.6999969,3.8000031-4.1999969 c0.0999985-0.5,0.1999969-1.0999985,0.0999985-1.5999985l0.5999985-0.0999985c0.1999969,0.4000015,0.4000015,0.6999969,0.5,1 c0.5999985,1.1999969,1.5,2.3000031,2.3000031,3.4000015c1,1.3000031,1.9000015,2.5999985,2.5,3.9000015 c1.5,3.3000031,1.1999969,6.5-1,10.3000031C71.1538925,89.7493591,70.1538925,89.9493561,69.053894,89.9493561z"
        />
        <path
          fill={colors[1]}
          d="M68.9538956,90.0493622c-1.5-0.5-3-1-4.3000031-1.5999985 c-0.3000031-0.4000015-0.5999985-0.8000031-0.8000031-1.3000031c-0.8000031-1.1999969-1.4000015-2.4000015-2-3.8000031 c0.0999985-0.4000015,0.2000008-0.6999969,0.4000015-1c0.2000008-0.5,0.5-1,0.6999969-1.4000015 c0.3000031-0.5999985,0.6999969-1.3000031,1-2l1.5-0.1999969l-0.7999954-1.0999985 c1.8000031-1.1999969,2.9000015-2.5,3.4000015-3.8000031c0.5,0.8000031,1.0999985,1.5,1.5999985,2.3000031 c0.9000015,1.3000031,1.8000031,2.5,2.4000015,3.8000031c1.5,3.0999985,1.1999969,6.0999985-0.9000015,9.6999969 C70.4538956,89.8493576,69.753891,90.0493622,68.9538956,90.0493622z"
        />
        <path
          fill={colors[2]}
          d="M68.8538895,90.1493607c-1.1999969-0.4000015-2.4000015-0.8000031-3.5-1.3000031 c-0.1999969-0.3000031-0.5-0.6999969-0.6999969-1c-0.6999969-1.0999985-1.3000031-2.3000031-1.9000015-3.5 c0.0999985-0.3000031,0.1999969-0.5,0.3000031-0.8000031c0.1999969-0.5,0.5-1,0.6999969-1.4000015 c0.3000031-0.5,0.5999985-1.0999985,0.8000031-1.6999969l2.0999985-0.3000031l-1-1.4000015 c1-0.6999969,1.8000031-1.4000015,2.4000015-2.1999969c0.1999969,0.1999969,0.3000031,0.4000015,0.5,0.6999969 c0.9000015,1.3000031,1.8000031,2.5,2.4000015,3.6999969c1.4000015,2.8000031,1.0999985,5.6999969-0.8000031,9.0999985 C69.753891,89.9493561,69.253891,90.0493622,68.8538895,90.1493607z"
        />
        <path
          fill={colors[3]}
          d="M68.753891,90.1493607c-0.9000015-0.3000031-1.8000031-0.5999985-2.6999969-1 c-0.1999969-0.3000031-0.3000031-0.5-0.5-0.8000031c-0.6999969-1.0999985-1.1999969-2.0999985-1.8000031-3.3000031 c0.0999985-0.1999969,0.0999985-0.4000015,0.1999969-0.5999985c0.1999969-0.5,0.5-0.9000015,0.6999969-1.4000015 s0.5-0.9000015,0.6999969-1.4000015l2.5999985-0.4000015l-1.1999969-1.5999985 c0.4000015-0.3000031,0.8000031-0.5999985,1.0999985-0.9000015c0.6999969,1,1.4000015,2,1.9000015,3 c1.3000031,2.5999985,1,5.3000031-0.5999985,8.4000015C69.053894,90.0493622,68.8538895,90.1493607,68.753891,90.1493607z"
        />
        <path
          fill={colors[4]}
          d="M68.3538895,89.8493576c-0.5-0.1999969-1.0999985-0.4000015-1.5999985-0.5999985 c-0.0999985-0.1999969-0.1999969-0.4000015-0.4000015-0.5999985c-0.5999985-1-1.1999969-2-1.6999969-3 c0-0.0999985,0.0999985-0.1999969,0.1999969-0.4000015c0.1999969-0.5,0.5-0.9000015,0.6999969-1.4000015 c0.1999969-0.4000015,0.4000015-0.8000031,0.5999985-1.1999969l2.5999985-0.4000015 C69.9538956,84.6493607,69.753891,87.1493607,68.3538895,89.8493576z"
        />
        <path
          fill={colors[5]}
          d="M68.553894,90.1493607c-0.0999985-0.0999985-0.0999985-0.1999969-0.1999969-0.3000031 c-0.5999985-0.9000015-1.0999985-1.8000031-1.5-2.8000031c0-0.0999985,0-0.0999985,0.0999985-0.1999969 c0.1999969-0.5,0.5-0.9000015,0.6999969-1.4000015c0.0999985-0.3000031,0.3000031-0.5999985,0.4000015-0.9000015l1-0.1999969 C69.6538925,86.2493591,69.553894,88.1493607,68.553894,90.1493607 C68.6538925,90.2493591,68.553894,90.1493607,68.553894,90.1493607z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M76.9538956,71.8493576c-1.4000015-2-1.5999985-3.9000015-3.5999985-5.5999985 c-0.0999985,0-1-0.6999969-1.0999985-0.6999969c-0.1999969,0.0999985-1.4000015,0.1999969-1.4000015-0.2000046 c0.1999969-1,1.8000031-1.2999954,1.3000031-2.2000008c-0.4000015-0.7000008-2.1999969-5-2.6999969-5.7999992 c-0.8000031,1.5999985-3.3000031,5.7000008-4.6999969,7.0999985c-0.0999985,0.0999985-2.0999985,5.2000046-2.7999992,7.5 c0.4000015-0.0999985,1.1000023-0.3000031,1.0000038-0.0999985c-1.0999985,3.0999985-4.0999985,5.5999985-5.2000008,8.6999969 c0,0.0999985-0.2000008,0.1999969-0.4000015,0.3000031c0.4000015,1.9000015,2.0999985,3.5,4,4.9000015 c-1-1.5999985-1.7999992-3.3000031-1.7999992-5.0999985c0-0.1999969,0.5999985-0.5,1.2000008-0.6999969 c0.2000008-2,0.5-3.8000031,2.2999992-5.5999985c2.3000031-2.1999969,3.4000015-5.6999969,3.4000015-5.6999969 c0.0999985-0.4000015,2.6999969-4.5,2.0999985-6.3000031c-0.1999969-0.5,0.5999985,0.9000015,0.5,1.4000015 c0.0999985,0,0.1999969,0,0.1999969,0.0999985c0,1.4000015,1,6.0999985,1.0999985,6.2000046 c2.1999969,1.5999985,2.3000031,1.8999939,3.3000031,3.8999939c1.1999969,2.5,1.9000015,4.0999985,2,6.6999969 c0,0.0999985-0.0999985,0.1999969-0.1999969,0.3000031c0.8000031,1.5-0.4000015,3.0999985-0.8000031,4.5999985 c-0.0999985,0.1999969-0.0999985,0.4000015-0.3000031,0.6999969c1-1.4000015,1.9000015-2.9000015,2.6999969-4.4000015 C77.1538925,78.4493561,77.053894,75.1493607,76.9538956,71.8493576z"
      />
    </g>
  )
}

export default FlameLeft
