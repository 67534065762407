import React from "react"
import { withSkinColor } from "../../builderConnector"

const OrcHead = ({ skinColor, colors = ["#21366C", "#DEA043"] }) => {
  return (
    <g id="OrcHead">
      <path
        opacity="0.3"
        fill={colors[0]}
        d="M59.4260635,20.9591789c1.789463,1.3420811,3.4717751,4.0455456,5.6210709,5.0690136 c0,0,1.0960083-1.0736637,2.773613-1.0736637c1.677597,0,3.3075562,1.0736637,3.3075562,1.0736637 c2.149231-1.023468,4.1455078-3.866375,5.934967-5.208456c-1.9361877-2.7719936-1.9994202-3.9643841-8.3517761-3.9643841 S61.3164864,17.1954098,59.4260635,20.9591789z"
      />
      <path
        fill={skinColor}
        d="M68.3580856,23.2468853c1.60746,0,3.0329971-0.1415405,3.0329971-0.1415405 c2.0594025-0.980669,3.5149765-2.2995911,5.229599-3.5855579c0.9418411-1.2138367-0.4743195-16.8121567-5.1867294-16.9836235 l-3.626442-0.5939432l-2.7243652,0.5939432c-4.71241,0.1714675-6.1285706,15.7697878-5.1867371,16.9836235 c1.7146225,1.2859669,3.1701965,2.6048889,5.229599,3.5855579c0,0,1.4255371,0.1415405,3.0330048,0.1415405H68.3580856z"
      />
      <g>
        <path
          fill={skinColor}
          d="M57.359623,7.4962487c-0.137989,0.0919914-0.8525429,6.2456021,1.6962051,7.9156165 c0.2994614,0.1962166,0.6848907-0.4922647,1.0793991-0.4112005c-0.5892754-2.0379162,0.8881264-2.6813593,0.3598022-4.0092087 c-0.3187447-0.120904-0.5071449-0.9996357-0.822403-1.2850027c-0.7616386-0.6894293-2.0063934-1.2490883-1.9523392-1.8520427 C57.7708244,7.290648,57.359623,7.4962487,57.359623,7.4962487z"
        />
        <path
          fill={skinColor}
          d="M79.2797318,7.4962487c0.1379929,0.0919914,0.8525467,6.2456021-1.6961975,7.9156165 c-0.299469,0.1962166-0.6848984-0.4922647-1.0794067-0.4112005c0.5892792-2.0379162-0.8881226-2.6813593-0.3598022-4.0092087 c0.3187485-0.120904,0.5071487-0.9996357,0.822403-1.2850027c0.7616425-0.6894293,2.0063934-1.2490883,1.9523468-1.8520427 C78.8685379,7.290648,79.2797318,7.4962487,79.2797318,7.4962487z"
        />
        <path
          opacity="0.4"
          fill="#262261"
          d="M59.4162254,10.8147125c-1.4004135-0.4308929-1.8343773-1.5735321-1.9088669-1.8050566 c0.29776,1.1407633,1.0585251,1.9423828,1.5974197,2.6095991c0.1744614,0.3444452-0.2854767,0.4931135,0,1.2976532 c0.0429077,0.0317287,0.0711098,0.3592539,0.1000328,0.6742878l-0.5931282-0.0773668 c0,0.1297674,0.6277084,0.4178267,0.6277084,0.4178267c0.0202713,0.1658068,0.0432739,0.2781954,0.0730247,0.2569494 c0.1033249-0.0738087-0.0595474-1.3731899,0.0259399-1.6869392c0.117527-0.4313803,0.5190697-0.2595339,0.5190697-0.2595339 S60.1169586,11.7749777,59.4162254,10.8147125z"
        />
        <path
          opacity="0.4"
          fill="#262261"
          d="M77.1060181,10.8147125c1.4004135-0.4308929,1.8343811-1.5735321,1.9088669-1.8050566 c-0.29776,1.1407633-1.0585251,1.9423828-1.5974197,2.6095991c-0.1744614,0.3444452,0.2854767,0.4931135,0,1.2976532 c-0.0429077,0.0317287-0.071106,0.3592539-0.100029,0.6742878l0.5931244-0.0773668 c0,0.1297674-0.6277084,0.4178267-0.6277084,0.4178267c-0.0202713,0.1658068-0.0432739,0.2781954-0.0730209,0.2569494 c-0.1033249-0.0738087,0.0595398-1.3731899-0.0259476-1.6869392c-0.1175232-0.4313803-0.5190659-0.2595339-0.5190659-0.2595339 S76.4052887,11.7749777,77.1060181,10.8147125z"
        />
      </g>
    </g>
  )
}

export default withSkinColor(OrcHead, { character: "ManDragonborn" })
