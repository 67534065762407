import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const DaggerLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("DaggerLeft") ? (
    <g id="DaggerLeft">
      <path
        id="S_x24_daggerLeft"
        d="M80.3519897,93.0584412l-3.281868-3.4913559l1.0474014-7.1223602l1.815506-5.3068542 c0,0,5.0973816-12.5688782,5.7956543-13.1973267l0,0l4.2594528,0.4189606l3.8404846,2.0948181l-2.9327393,14.2447205 l-1.3965378,4.4689331l-0.2094879,0.6982727l-1.1870575,2.8629074l-1.3267136,3.0723953l-0.8379211,0.6982727l-4.6784134,0.907753 L80.3519897,93.0584412z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(DaggerLeft, {
  character: "WomanGnome"
})
