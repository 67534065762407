import React from "react"

const LuteR = ({
  colors = ["#94712B", "#D9A53F", "#695020", "#B68B34", "#8E6D2B"]
}) => {
  return (
    <g id="LuteR">
      <path
        fill={colors[0]}
        d="M30.8467731,43.2736092l0.9000015,0.0999985l0.0999985,0.4000015l0.4999981,0.0999985 l0.4000015-0.7000008v-0.5999985l-0.7000008,0.0999985l-0.1999989,0.2999992l-1,0.0999985 C30.8467731,43.0736046,30.7467709,43.2736092,30.8467731,43.2736092z"
      />
      <path
        fill={colors[0]}
        d="M30.7467709,42.4736061l0.9000015,0.0999985l0.0999985,0.2000008l0.3999996-0.2000008 l0.4000015-0.4000015l0.0999985-0.5l-0.6000023,0.2000046l-0.3999996,0.2999992l-1,0.0999985 C30.7467709,42.2736092,30.6467724,42.4736061,30.7467709,42.4736061z"
      />
      <path
        fill={colors[0]}
        d="M30.7467709,41.6736069h0.7999992l0.0999985,0.2000008l0.4999981-0.0999985 l0.4000015-0.4000015l0.0999985-0.5l-0.5,0.2000008l-0.3999996,0.2999992l-1,0.0999985 C30.6467724,41.4736061,30.5467701,41.6736069,30.7467709,41.6736069z"
      />
      <path
        fill={colors[0]}
        d="M31.1467724,44.4736061l0.8999996,0.0999985l0.0999985,0.2000008h0.5 l0.4000015-0.5999985l0.0999985-0.5l-0.7000008,0.2000008l-0.4000015,0.2999992l-0.9999981,0.0999985 C31.0467701,44.3736076,30.9467716,44.4736061,31.1467724,44.4736061z"
      />
      <path
        fill={colors[1]}
        d="M38.6467705,57.9736061 c5.2999992-0.2000008,15.7999992,14.4000053,16.7000008,17.5000038c0.9000015,3.1999969,1.2000008,6.8000031-3.2000008,9.0999985 s-10.3999977-2-12.1000023-4.5C38.246769,77.4736099,35.0467682,58.5736084,38.6467705,57.9736061z"
      />
      <path
        fill={colors[2]}
        d="M28.8467731,43.1736069l0.0999985-1l8.5999966,17 c0,0-3.0999985,28.0000076,14.5999985,25.4000015c0,0-11.2999992,6.5999985-16.2000008-8.6999969 c-1.2000008-7.1999969,0.4000015-15.8000031,0.4000015-15.8000031"
      />
      <path
        fill={colors[0]}
        d="M29.2467709,46.0736084l-0.7999992,0.2999992v0.2000008l-0.4000015,0.2000008 l-0.5-0.2999992l-0.2000008-0.4000015h0.7000008h0.2999992l1-0.0999985 C29.2467709,45.8736076,29.4467716,46.0736084,29.2467709,46.0736084z"
      />
      <path
        fill={colors[0]}
        d="M28.9467716,43.8736076l-0.7999992,0.2999992v0.4000015l-0.4000015,0.2000008l-0.5-0.5 l-0.2000008-0.5999985h0.7000008l0.2999992,0.2000008l1-0.0999985 C29.0467701,43.6736069,29.1467724,43.7736092,28.9467716,43.8736076z"
      />
      <path
        fill={colors[0]}
        d="M28.8467731,43.0736084l-0.7999992,0.2999992v0.2999992l-0.4000015-0.0999985 l-0.5-0.2999992l-0.2000008-0.5h0.5999985l0.5,0.2000008l1-0.0999985 C28.8467731,42.8736076,29.0467701,42.9736061,28.8467731,43.0736084z"
      />
      <path
        fill={colors[0]}
        d="M28.7467709,42.2736092l-0.7999992,0.1999969v0.2000008l-0.5,0.0999985l-0.5-0.2999992 l-0.2000008-0.4000015h0.5999985l0.5,0.2000008l1-0.0999985C28.7467709,42.0736084,28.9467716,42.2736092,28.7467709,42.2736092z"
      />
      <path
        fill={colors[0]}
        d="M29.0467701,45.1736069l-0.7999992,0.2999992v0.2999992l-0.5,0.0999985l-0.5-0.5 l-0.2000008-0.4999962h0.5999985l0.5,0.2000008l1-0.0999985C29.0467701,44.9736061,29.2467709,45.0736084,29.0467701,45.1736069z"
      />
      <polygon
        fill={colors[3]}
        points="40.5467682,58.3736076 31.0467701,41.2736092 28.6467724,41.7736092 37.5467682,59.1736069"
      />
      <polygon
        fill={colors[4]}
        points="53.8467712,77.1736145 48.4467697,79.8736115 48.0467682,78.6736145 53.246769,76.1736145"
      />
      <polygon
        fill={colors[0]}
        points="28.9467716,42.3736076 28.8467731,43.1736069 29.4467716,46.5736084 30.3467731,46.3736076"
      />
      <polygon
        fill={colors[2]}
        points="29.1467724,46.1736069 29.4467716,46.5736084 28.9467716,42.1736069 28.6467724,41.7736092"
      />
      <ellipse
        transform="rotate(-31.851 44.768 67.77)"
        fill={colors[0]}
        cx="44.768"
        cy="67.771"
        rx="1.8"
        ry="2.6"
      />
      <line fill="none" x1="51.947" y1="77.374" x2="30.747" y2="41.374" />
      <line fill="none" x1="51.347" y1="77.474" x2="30.147" y2="41.474" />
      <line fill="none" x1="50.847" y1="77.574" x2="29.547" y2="41.574" />
      <line fill="none" x1="50.247" y1="77.874" x2="29.147" y2="41.674" />
    </g>
  )
}

export default LuteR
