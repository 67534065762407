import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BroadSwordR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BroadSwordR") ? (
    <g id="BroadSwordR">
      <path
        id="path_5_"
        d="M47.2,69.5l1.8-1.3l2.9-6c0,0-0.1-2.3-0.2-4.7l2.8-3.7l0.2-1.9l-2.5-3.2L51.4-7.4l-0.1-1.3L50.3-13l-0.9-2.3 l-0.5-0.8l-12-0.1c-0.5,0.8-3.2,5.1-3.6,9.5c-0.1,0.7-0.2,1.7,0.1,17.4c0.2,12.4,0.5,28.7,0.7,37.2L30.4,52l-0.1,2.1l4.2,4.4 L34.4,63l4.4,6.7l1.5,0.6L47.2,69.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BroadSwordR, {
  character: "ManMixed"
})
