import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import CutlassR from "./CutlassR"
import CutlassL from "./CutlassL"
import Cape from "./Cape"
import LongSwordLeft from "./LongSwordLeft"
import GoldAxeLeft from "./GoldAxeLeft"
import LongBowLeft from "./LongBowLeft"
import DragonStaffR from "./DragonStaffR"
import SlingShotLeft from "./SlingShotLeft"
import LongSwordRight from "./LongSwordRight"
import GoldAxeRight from "./GoldAxeRight"
import LongBowRight from "./LongBowRight"
import NobelCape from "./NobelCape"
import ShortswordLeft from "./ShortswordLeft"
import WizardRobe from "./WizardRobe"
import WizardHat from "./WizardHat"
import BlueFlameRight from "./BlueFlameRight"
import BlueFlameLeft from "./BlueFlameLeft"
import WarhammerL from "./WarhammerL"
import MaceL from "./MaceL"
import AxeLeft from "./AxeLeft"
import LuteLeft from "./LuteLeft"
import DaggerLeft from "./DaggerLeft"
import MorningStarLeft from "./MorningStarLeft"
import MorningStarRight from "./MorningStarRight"
import StaffLeft from "./StaffLeft"
import WarhammerR from "./WarhammerR"
import MaceR from "./MaceR"
import AxeRight from "./AxeRight"
import LuteRight from "./LuteRight"
import BattleAxeLeft from "./BattleAxeLeft"
import BattleAxeRight from "./BattleAxeRight"
import DaggerRight from "./DaggerRight"
import StaffRight from "./StaffRight"
import OpenCloak from "./OpenCloak"
import BodyBase from "./BodyBase"

const CATEGORY = "shadow"
const CHARACTER = "ManGnome"
export const IDS = {
  CUTLASS_R: "CutlassR",
  CUTLASS_L: "CutlassL",
  CAPE: "Cape",
  LONG_SWORD_LEFT: "LongSwordLeft",
  GOLD_AXE_LEFT: "GoldAxeLeft",
  LONG_BOW_LEFT: "LongBowLeft",
  DRAGON_STAFF_R: "DragonStaffR",
  SLING_SHOT_LEFT: "SlingShotLeft",
  LONG_SWORD_RIGHT: "LongSwordRight",
  GOLD_AXE_RIGHT: "GoldAxeRight",
  LONG_BOW_RIGHT: "LongBowRight",
  NOBEL_CAPE: "NobelCape",
  SHORTSWORD_LEFT: "ShortswordLeft",
  WIZARD_ROBE: "WizardRobe",
  WIZARD_HAT: "WizardHat",
  BLUE_FLAME_RIGHT: "BlueFlameRight",
  BLUE_FLAME_LEFT: "BlueFlameLeft",
  WARHAMMER_L: "WarhammerL",
  MACE_L: "MaceL",
  AXE_LEFT: "AxeLeft",
  LUTE_LEFT: "LuteLeft",
  DAGGER_LEFT: "DaggerLeft",
  MORNING_STAR_LEFT: "MorningStarLeft",
  MORNING_STAR_RIGHT: "MorningStarRight",
  STAFF_LEFT: "StaffLeft",
  WARHAMMER_R: "WarhammerR",
  MACE_R: "MaceR",
  AXE_RIGHT: "AxeRight",
  LUTE_RIGHT: "LuteRight",
  BATTLE_AXE_LEFT: "BattleAxeLeft",
  BATTLE_AXE_RIGHT: "BattleAxeRight",
  DAGGER_RIGHT: "DaggerRight",
  STAFF_RIGHT: "StaffRight",
  OPEN_CLOAK: "OpenCloak",
  BODY_BASE: "BodyBase"
}

export const components = {
  [IDS.CUTLASS_R]: CutlassR,
  [IDS.CUTLASS_L]: CutlassL,
  [IDS.CAPE]: Cape,
  [IDS.LONG_SWORD_LEFT]: LongSwordLeft,
  [IDS.GOLD_AXE_LEFT]: GoldAxeLeft,
  [IDS.LONG_BOW_LEFT]: LongBowLeft,
  [IDS.DRAGON_STAFF_R]: DragonStaffR,
  [IDS.SLING_SHOT_LEFT]: SlingShotLeft,
  [IDS.LONG_SWORD_RIGHT]: LongSwordRight,
  [IDS.GOLD_AXE_RIGHT]: GoldAxeRight,
  [IDS.LONG_BOW_RIGHT]: LongBowRight,
  [IDS.NOBEL_CAPE]: NobelCape,
  [IDS.SHORTSWORD_LEFT]: ShortswordLeft,
  [IDS.WIZARD_ROBE]: WizardRobe,
  [IDS.WIZARD_HAT]: WizardHat,
  [IDS.BLUE_FLAME_RIGHT]: BlueFlameRight,
  [IDS.BLUE_FLAME_LEFT]: BlueFlameLeft,
  [IDS.WARHAMMER_L]: WarhammerL,
  [IDS.MACE_L]: MaceL,
  [IDS.AXE_LEFT]: AxeLeft,
  [IDS.LUTE_LEFT]: LuteLeft,
  [IDS.DAGGER_LEFT]: DaggerLeft,
  [IDS.MORNING_STAR_LEFT]: MorningStarLeft,
  [IDS.MORNING_STAR_RIGHT]: MorningStarRight,
  [IDS.STAFF_LEFT]: StaffLeft,
  [IDS.WARHAMMER_R]: WarhammerR,
  [IDS.MACE_R]: MaceR,
  [IDS.AXE_RIGHT]: AxeRight,
  [IDS.LUTE_RIGHT]: LuteRight,
  [IDS.BATTLE_AXE_LEFT]: BattleAxeLeft,
  [IDS.BATTLE_AXE_RIGHT]: BattleAxeRight,
  [IDS.DAGGER_RIGHT]: DaggerRight,
  [IDS.STAFF_RIGHT]: StaffRight,
  [IDS.OPEN_CLOAK]: OpenCloak,
  [IDS.BODY_BASE]: BodyBase
}

export const Group = ({ props }) => (
  <g id="shadow">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.CUTLASS_R,
    name: "Cutlass R",
    defaultColors: ["#01060F"],
    colorable: false,
    component: components[IDS.CUTLASS_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.CUTLASS_L,
    name: "Cutlass L",
    defaultColors: ["#01060F"],
    colorable: false,
    component: components[IDS.CUTLASS_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.CAPE,
    name: "Cape",
    defaultColors: [],
    colorable: false,
    component: components[IDS.CAPE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_SWORD_LEFT,
    name: "Long Sword Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_SWORD_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_LEFT,
    name: "Gold Axe Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.GOLD_AXE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_BOW_LEFT,
    name: "Long Bow Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_BOW_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DRAGON_STAFF_R,
    name: "Dragon Staff R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.DRAGON_STAFF_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SLING_SHOT_LEFT,
    name: "Sling Shot Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.SLING_SHOT_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_SWORD_RIGHT,
    name: "Long Sword Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_SWORD_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_RIGHT,
    name: "Gold Axe Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.GOLD_AXE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_BOW_RIGHT,
    name: "Long Bow Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_BOW_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.NOBEL_CAPE,
    name: "Nobel Cape",
    defaultColors: [],
    colorable: false,
    component: components[IDS.NOBEL_CAPE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SHORTSWORD_LEFT,
    name: "Shortsword Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.SHORTSWORD_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WIZARD_ROBE,
    name: "Wizard Robe",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WIZARD_ROBE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WIZARD_HAT,
    name: "Wizard Hat",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WIZARD_HAT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BLUE_FLAME_RIGHT,
    name: "Blue Flame Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BLUE_FLAME_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BLUE_FLAME_LEFT,
    name: "Blue Flame Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BLUE_FLAME_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WARHAMMER_L,
    name: "Warhammer L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WARHAMMER_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MACE_L,
    name: "Mace L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MACE_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.AXE_LEFT,
    name: "Axe Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.AXE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LUTE_LEFT,
    name: "Lute Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LUTE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DAGGER_LEFT,
    name: "Dagger Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.DAGGER_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MORNING_STAR_LEFT,
    name: "Morning Star Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MORNING_STAR_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MORNING_STAR_RIGHT,
    name: "Morning Star Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MORNING_STAR_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.STAFF_LEFT,
    name: "Staff Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.STAFF_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WARHAMMER_R,
    name: "Warhammer R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WARHAMMER_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MACE_R,
    name: "Mace R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MACE_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.AXE_RIGHT,
    name: "Axe Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.AXE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LUTE_RIGHT,
    name: "Lute Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LUTE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BATTLE_AXE_LEFT,
    name: "Battle Axe Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BATTLE_AXE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BATTLE_AXE_RIGHT,
    name: "Battle Axe Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BATTLE_AXE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DAGGER_RIGHT,
    name: "Dagger Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.DAGGER_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.STAFF_RIGHT,
    name: "Staff Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.STAFF_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.OPEN_CLOAK,
    name: "Open Cloak",
    defaultColors: [],
    colorable: false,
    component: components[IDS.OPEN_CLOAK],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BODY_BASE,
    name: "Body Base",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BODY_BASE],
    inUI: false,
    enabled: true,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
