import React from "react"

const Eyes = ({ colors = ["#010101"] }) => {
  return (
    <g id="Eyes">
      <g id="C_x24_D_x24_eyesRound" fill={colors[0]}>
        <circle cx="45.758" cy="47.29" r="0.5" />
        <circle cx="51.358" cy="47.393" r="0.5" />
      </g>
    </g>
  )
}

export default Eyes
