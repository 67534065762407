import React from "react"

const MaceR = ({
  colors = ["#58595A", "#B9BABA", "#767575", "#9C9D9E", "#E1E2E3", "#E4E4E5"]
}) => {
  return (
    <g id="MaceR">
      <path
        opacity="0.53"
        d="M29,91.0999985l-0.7999992-1.5l-0.1000004-0.3000031L27.8999996,88 v-0.1999969l-9.1999989-23.5c-0.2999992,0.0999985-0.6000004,0.0999985-0.6000004,0.0999985l-0.5-0.3000031l-0.5-1.2999992 C16.8999996,62.5999985,16.1000004,61.7000008,14,61.5l-0.1999998-0.5999985l-0.1999998-0.0999985l0,0H13.5l0.1000004-0.0999985 l0,0v-0.0999985c0.1000004-0.0999985,0.1000004-0.4000015-0.1000004-1c-0.1999998-0.5-0.5-1-0.5-1l0,0l-0.1000004-0.0999985 v-0.4000015l1.8000002-4.2000008l0.3999996-0.2999992c0,0,0.1000004,0,0.1000004-0.0999985L15.1000004,52L16,51.5999985 l0.7000008,1.2000008l0,0l0.6000004-0.0999985L21.5,54.5l0.2999992,0.4000015l0,0l0,0l0,0l0,0 c0,0,0.1000004,0.5999985,0.2999992,1.0999985c0.2000008,0.5999985,0.5,0.7999992,0.5,0.7999992l0,0v0.0999985V57h0.2000008 l-0.1000004,0.2000008l0.1000004,0.0999985l0.2999992,0.5L23,57.7000008l0.1000004,0.2000008 c-1.3999996,1.5-1.3999996,2.7000008-1.3999996,3l0.5,1.2999992L22,62.7999992l0,0c0,0-0.2000008,0.0999985-0.5,0.2999992 l9.2000008,23.5l0.8999996,1.1999969l0.1000004,0.1999969l0,0v0.0999985l0.4000015,1.6999969l-0.2000008,0.5 c-0.1000004,0.0999985-0.7999992,0.5-1.2000008,0.6999969s-1.2999992,0.3000031-1.3999996,0.3000031L29,91.0999985z"
      />
      <path
        fill={colors[0]}
        d="M21.2999992,55.2000008l-4.2000008-1.7999992c-0.2999992,0.2000008-0.5,0.0999985-0.7000008,0 L15.6000004,52l0.3000002,1.5c-0.1000004,0.2999992-0.1999998,0.4000015-0.6000004,0.5L13.5,58.2000008 c0,0,1.3000002,2.0999985,0.6000004,2.7999992c2.6000004,0.2000008,3.5,1.5,3.5,1.5l0.5,1.4000015L19,63.8000031l9.3999996,24 L28.5,89.4000015l0.7999992,1.5l1.2999992-0.3000031L31.6999989,90l-0.3999996-1.6999969l-1-1.1999969l-9.3999996-24 l0.7999992-0.5999985l-0.5-1.4000015c0,0-0.2000008-1.5,1.5-3.5 C21.7999992,57.5999985,21.2999992,55.2000008,21.2999992,55.2000008z"
      />
      <path
        fill={colors[1]}
        d="M21.2999992,55.2999992L17.0999985,53.5c-0.2999992,0.2000008-0.5,0.0999985-0.7000008,0 l-0.8000002-1.2999992l0.3000002,1.5c-0.1000004,0.2999992-0.1999998,0.4000015-0.6000004,0.5l-1.8000002,4.0999985 c0,0,1.3000002,2.0999985,0.6000004,2.7999992c2.5999994,0.2000008,3.500001,1.5,3.500001,1.5l0.5,1.4000015 l0.8999996-0.0999985l9.3999996,24L28.4999981,89.5l0.7999992,1.5c0,0,0.8999996-0.0999985,1.2999992-0.3000031 c0.3999996-0.1999969,1.1000004-0.5999985,1.1000004-0.5999985l-0.3999996-1.6999969l-1-1.1999969l-9.3999996-24 l0.7999992-0.5999985l-0.5-1.4000015c0,0-0.2000008-1.5,1.5-3.5 C21.7999992,57.7000008,21.2999992,55.2999992,21.2999992,55.2999992z M18.2000008,62.0999985l-2.8000002-1.9000015L14.5,58 l1-3.4000015l0.3000002,2.7999992l0.8000002,2.2000008L18.5,62L18.2000008,62.0999985z M20.3999996,61.2999992L20,61.5 l-0.2000008-3l-0.8999996-2.2000008L17.2000008,54l3,1.7999992L21.1000004,58L20.3999996,61.2999992z"
      />
      <path
        fill={colors[2]}
        d="M21.7999992,62.5999985c0,0-0.7000008,0.5999985-1.7000008,1s-1.8999996,0.5-1.8999996,0.5 l-0.5-1.2999992c0,0,1.3999996-0.4000015,1.8999996-0.5999985c0.5-0.2000008,1.7999992-0.7999992,1.7999992-0.7999992 L21.7999992,62.5999985z"
      />
      <path
        fill={colors[2]}
        d="M31.2999992,88.3000031c0,0,0,0.3000031-1.2000008,0.8000031s-1.5,0.3000031-1.5,0.3000031 l-0.2000008-1.5999985c0,0,0.5,0.0999985,1-0.0999985s0.8999996-0.5999985,0.8999996-0.5999985L31.2999992,88.3000031z"
      />
      <g>
        <path
          fill={colors[3]}
          d="M21.1000004,58.0999985l-0.7000008,3.0999985L20,61.4000015l-0.2000008-2.7999992l-2.8999996,1.0999985 l1.7000008,2.2000008l-0.3999996,0.2000008l-2.6999998-1.7999992l-1.3000002,0.5 c0,0.0999985-0.1000004,0.2000008-0.1000004,0.2000008c2.6000004,0.2000008,3.5,1.5,3.5,1.5l0.5,1.4000015l0,0l-0.5-1.2999992 c0,0,1.3999996-0.4000015,1.8999996-0.5999985c0.5-0.2000008,1.7999992-0.7999992,1.7999992-0.7999992l0.5,1.2999992l0,0 l-0.5-1.4000015c0,0-0.2000008-1.5,1.5-3.5c-0.1000004,0-0.2000008,0-0.2999992-0.0999985L21.1000004,58.0999985z"
        />
        <path
          fill={colors[4]}
          d="M14.5,57.9000015L14.5,57.9000015L15.5,54.5l0.3000002,2.7999992v0.0999985l2.999999-1.2000008 l-0.1000004-0.0999985L17,53.9000015l3,1.7999992l0,0l1.1000004-0.4000015c0-0.0999985,0-0.0999985,0-0.0999985 l-4.1999989-1.7999992c-0.2999992,0.2000008-0.5,0.0999985-0.7000008,0L15.6000004,52l0.3000002,1.5 c-0.1000004,0.2999992-0.1999998,0.4000015-0.6000004,0.5L13.5,58.2000008c0,0,0,0,0.1000004,0.0999985L14.5,57.9000015z"
        />
        <polygon
          fill={colors[5]}
          points="20,63.7000008 29.2000008,87.5999985 29.7000008,87.5 20.3999996,63.7000008"
        />
      </g>
    </g>
  )
}

export default MaceR
