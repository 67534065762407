import React from 'react'

export const ColorWheel = () =>
  <svg
    version="1.1"
    id="Layer_1"
    x="0px" y="0px"
    viewBox="0 0 190 186"
    width={15}
    height={15}
  >
   <circle fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" cx="94.9" cy="91.8" r="87.2"/>
    <g>
      <path fill="#F9ED32" d="M171.6,91.8c0-21.2-8.6-40.4-22.5-54.2L94.9,91.8H171.6z"/>
      <path fill="#8DC63F" d="M149.1,146c13.9-13.9,22.5-33.1,22.5-54.2H94.9L149.1,146z"/>
      <path fill="#FBB040" d="M149.1,37.5C135.3,23.6,116.1,15,94.9,15v76.7L149.1,37.5z"/>
      <path fill="#39B54A" d="M94.9,168.5c21.2,0,40.4-8.6,54.2-22.5L94.9,91.8V168.5z"/>
      <path fill="#009444" d="M94.9,91.8L40.7,146c13.9,13.9,33.1,22.5,54.2,22.5V91.8z"/>
      <path fill="#F7941D" d="M94.9,15c-21.2,0-40.4,8.6-54.2,22.5l54.2,54.2V15z"/>
      <path fill="#00A79D" d="M18.2,91.8c0,21.2,8.6,40.4,22.5,54.2l54.2-54.2H18.2z"/>
      <path fill="#F26522" d="M40.7,37.5C26.8,51.4,18.2,70.6,18.2,91.8h76.7L40.7,37.5z"/>
    </g>
  </svg>


export default ColorWheel