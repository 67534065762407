import React from "react"

const Lips = ({ colors = ["#9B3C00"] }) => {
  return (
    <g id="Lips">
      <path
        fill={colors[0]}
        d="M68.5871887,58.598011c-0.1041641,0-0.2604065,0.1562462-0.2604065,0.1562462 s-0.1041641-0.1562462-0.2083359-0.1562462c-0.1041565,0-0.9895554,0.4166565-0.9895554,0.4166565h0.0520782 c0,0,0.0520859,0.5208206,1.1458054,0.5208206c1.1458054,0,1.1458054-0.5208206,1.1458054-0.5208206h0.0520782 C69.5246658,59.0146675,68.6913528,58.598011,68.5871887,58.598011z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M67.1273346,59.0549011h2.3957748 c0,0-0.833313-0.3645744-0.9374847-0.3645744c-0.1041565,0-0.2604065,0.1562462-0.2604065,0.1562462 s-0.1041641-0.1562462-0.2083282-0.1562462S67.1273346,59.0549011,67.1273346,59.0549011z"
      />
    </g>
  )
}

export default Lips
