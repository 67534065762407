import React from "react"
import { withSkinColor } from "../../builderConnector"

const LeftThumb = ({ skinColor, color = [] }) => {
  return (
    <g id="LeftThumb">
      <path
        fill={skinColor}
        d="M92.9795456,55.847496c-0.0804443-0.0950012-0.7985535-1.1266441-1.3103485-1.3457642 c-1.5155563-1.3980789-4.1329422,0.1521873-4.2425079,0.4080811c-0.1168365,0.3436203,0.2095261,2.8173027,0.2754135,3.0877609 c0,0,0.4167862,0.2995644,0.7017975,0.0582275s0,0,0,0l0.8109741-1.2576408L92.9795456,55.847496z"
      />
      <path
        opacity="0.3"
        fill="#282560"
        d="M92.986824,55.7597694 c-0.0804443-0.0950012-0.7985535-1.1266441-1.3103485-1.3457642c-2.0186462-1.0624466-4.1402206,0.2399139-4.2497864,0.4958076 c-0.1168365,0.3436203,0.2168045,2.7295761,0.282692,3.0000305c0,0,0.4167862,0.2995682,0.7017975,0.0582314s0,0,0,0 l0.8109741-1.2576408L92.986824,55.7597694z"
      />
    </g>
  )
}

export default withSkinColor(LeftThumb, { character: "WomanMixed" })
