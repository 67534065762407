import React from "react"

const SimpleVest = ({ colors = ["#6E5238", "#CFA987", "#8C725C"] }) => {
  return (
    <g id="SimpleVest">
      <g id="vest" fill={colors[0]}>
        <path d="M39.6440697,74.0408478c0.3482666,1.8905869,0.7462845,3.1841431,0.7462845,3.1841431 l-0.8955421,6.6816635l7.2638245-0.5472717l-0.0497513-6.0200195l-2.8358765-11.0598602l-2.3881035,0.3980179 c0,0-1.1443024-0.1990051-2.9353828-0.7462845c0.6321144,1.3128586,1.359272,5.5245514,1.1132507,7.9441833 C39.6570663,73.9315414,39.6508369,73.9867096,39.6440697,74.0408478z" />
        <path d="M55.0672531,74.0408478c-0.3482666,1.8905869-0.7462807,3.1841431-0.7462807,3.1841431 l0.8955383,6.6816635l-7.3135719-0.5472717l0.3482628-6.1195221l2.537365-10.9603577l2.4876099,0.3482666 c0,0,1.044796-0.0995026,2.8358727-0.6965332C55.465271,67.2745514,54.7189865,71.6527405,55.0672531,74.0408478z" />
      </g>
      <ellipse fill={colors[1]} cx="46.311" cy="77.389" rx="0.199" ry="0.199" />
      <ellipse fill={colors[2]} cx="46.311" cy="77.389" rx="0.149" ry="0.149" />
      <ellipse fill={colors[1]} cx="46.261" cy="79.23" rx="0.199" ry="0.199" />
      <ellipse fill={colors[2]} cx="46.261" cy="79.23" rx="0.149" ry="0.149" />
      <ellipse fill={colors[1]} cx="46.261" cy="81.021" rx="0.199" ry="0.199" />
      <ellipse fill={colors[2]} cx="46.261" cy="81.021" rx="0.149" ry="0.149" />
      <ellipse fill={colors[1]} cx="46.211" cy="82.862" rx="0.199" ry="0.199" />
      <ellipse fill={colors[2]} cx="46.211" cy="82.862" rx="0.149" ry="0.149" />
    </g>
  )
}

export default SimpleVest
