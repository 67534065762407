import React from "react"

const SmallMouth = ({ colors = ["#282560", "#905A55"] }) => {
  return (
    <g id="SmallMouth">
      <path
        opacity="0.2"
        fill={colors[0]}
        d="M69.8,22.9c0.2,0,0.4,0.1,0.6,0.3c0-0.2,0-0.4,0-0.5 s-0.1-0.1-0.1-0.2l0,0h-0.1c0,0-0.3-0.3-0.8-0.3h-0.1h-0.1c-0.1,0-0.1,0-0.2,0c-0.4,0.1-0.7,0.3-0.9,0.6c0,0.2,0,0.3,0,0.5 C68.6,23,69.2,22.7,69.8,22.9z"
      />
      <polygon fill={colors[1]} points="67.3,21.2 71.4,21.2 69.2,21.6" />
    </g>
  )
}

export default SmallMouth
