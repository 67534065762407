import React from "react"
import { withSkinColor } from "../../builderConnector"

const PointedEars = ({ skinColor, color = [] }) => {
  return (
    <g id="PointedEars" fill={skinColor}>
      <path d="M60.8355637,14.9627943c-0.0938759,0.2682152,0.1475182,2.5748625,0.1475182,2.5748625 c0.1609306,0.0536442,1.4032936-1.4216728,1.4032936-1.4216728s-0.813221-0.2278481-1.2021332-1.1934223 C61.0522308,14.7997141,60.9294395,14.6945791,60.8355637,14.9627943z" />
      <path d="M76.2850494,15.0164375c0.0938797,0.2682142-0.1475143,2.5748634-0.1475143,2.5748634 c-0.1609344,0.0536423-1.4032974-1.4216728-1.4032974-1.4216728s0.8132248-0.227849,1.2021332-1.1934233 C76.0683823,14.8533573,76.1911774,14.7482224,76.2850494,15.0164375z" />
    </g>
  )
}

export default withSkinColor(PointedEars, { character: "WomanMixed" })
