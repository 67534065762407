import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SunHat = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SunHat") ? (
    <g id="SunHat">
      <path
        id="_x3C_path_x3E__3_"
        d="M53.4311523,23.9213867c-0.625,0-1.2758789-0.1044922-1.9892578-0.3193359l-5.7954102-1.7436523 l4.8969727-3.5561523c2.4238281-1.7607422,5.1352539-3.9038086,8.0678711-6.3774414l0.1987305-0.7099609l2.7641602-6.2124023 l0.9169922-0.4838867c0,0,1.9355469-1.0214844,2.5244141-1.3022461c0.7670898-0.3652344,2.0771484-0.5498047,3.8959961-0.5498047 c1.8022461,0,2.5239258,0.2260742,3.0336914,0.4697266c0.703125,0.3364258,2.2294922,1.2363281,2.5283203,1.4130859 l0.8017578,0.4746094l2.8564453,6.1591797l0.0400391,0.2431641c3.0722656,2.125,5.4121094,3.605957,7.0146484,4.6201172 c0.8837891,0.5595703,1.5654297,0.9921875,2.0507812,1.3251953l4.5166016,3.1044922l-5.0488281,2.1318359 c-0.7460938,0.3149414-1.4912109,0.4746094-2.2158203,0.4746094c-0.0009766,0-0.0009766,0-0.0009766,0 c-2.0273438,0-3.2939453-1.1796875-4.0322266-1.9614258c-0.0332031,0.0209961-0.0664062,0.0415039-0.0976562,0.0610352 c-0.9306641,0.5878906-2.3388672,1.4765625-4.5166016,1.4765625c-0.1455078,0-0.2939453-0.0039062-0.4462891-0.012207 c-1.2089844-0.0668945-2.2304688-0.6552734-3.4130859-1.3369141c-1.1054688-0.6367188-2.4814453-1.4291992-3.5419922-1.4291992 c-1.0649414,0.0029297-2.5678711,0.7900391-3.7758789,1.4223633c-1.3266602,0.6948242-2.4726562,1.2944336-3.7363281,1.2944336 c-1.7651367-0.0229492-2.871582-0.3598633-3.597168-0.6401367C56.7045898,22.7612305,55.5322266,23.9213867,53.4311523,23.9213867 z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SunHat, { character: "WomanMixed" })
