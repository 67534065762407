import React from "react"

const MercerCut = ({ colors = ["#A77D2C"] }) => {
  return (
    <g id="MercerCut" fillRule="evenodd" clipRule="evenodd">
      <path
        id="mercerCut_1_"
        opacity="0.3"
        fill="#21376C"
        d="M76.7,17.5c0-0.3-2.4-7.9-3.5-9c-0.8-0.8-5.4-1.2-5.6-1.2c-1,0-4.8,5.6-4.8,6c-0.4,1.1-0.9,3.9-0.9,4.1c-0.5,2.6,1.2,3.9,1.2,3.9 c-0.2,0.7,0.1,1.5,0.1,1.5c0.3,0.3,1.3-1.1,1.3-1.1s-0.1-6.2,0-6.2c0.2-0.3,1.5-2.4,2-3.1c1-1.4,3.1-1.2,3.3-1.2 c0,0.1,0.5,0.5,0.5,0.5s1.6,1.8,1.6,4.1c0.1,0.2-0.2-0.7,0-2.2c0.3,1.5,2.1,2.9,2.2,2.9c-0.2-0.2,0,2.2,0,2.2c0,0.9,0.3,1.7,0.1,2 c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2,0.3,1.5,0.4,1.7C74.7,21.9,76.8,18,76.7,17.5z"
      />
      <path
        id="mercerCut"
        fill={colors[0]}
        d="M77.1,19.2c0.2-0.5,0.2-1.1,0.1-1.7 c0-0.3-0.8-3.9-1.1-4.8c-0.6-1.7-1.5-3.9-2.7-5.1c-0.9-0.9-1.7-1.6-2.8-1.5c-0.2,0-0.4,0-0.6,0.1c-0.3,0.1-0.7,0.4-1,0.5 c-0.4,0-0.8-0.3-1.2-0.4c-0.2,0-0.3,0-0.5,0c-1.1,0-1.9,0.8-2.6,1.5c-1,0.9-1.7,2.2-2.1,3.6c-0.1,0.1-0.1,0.2-0.2,0.3 c-0.2,0.3-0.3,0.7-0.3,1.2c-0.4,1.2-0.9,2.6-0.9,3.7c0,0.3,0,0.6-0.1,0.9c-0.5,2.9,1,3.3,1.1,3.4c-0.3,0.8-0.8,1.1-0.8,1.1 c0.3,0,0.8-0.1,1-0.3c-0.2,0.8-0.7,0.9-0.7,0.9s0.4,0.5,1.6,0.2c-0.2,0.2-0.8,0.7-0.8,0.7c0.4,0.4,0.9,0.6,1.4,0.6 c0.1,0.2,0,1.1-0.1,1.4c0.2,0,0.3-0.5,0.3-0.5c0.4,0,0.2-3.4,0.2-3.9c-0.1-0.1-0.2-1.4-0.2-2.3c0,0,0-3.3,0.1-3.1 c0.2-0.3,1.3-2.5,1.9-3.3c0.7-0.9,1.7-1.4,3.3-1.4l0,0h0.1c0.2,0,0.5,0,0.7,0.1c0,0.1,0,0.2,0,0.2s1.5,0.9,1.6,3.5 c0.1,1.8,0.1,0.3,0.2-1.4c0.3,1.7,1.6,2.6,2.4,2.8h0.1c-0.2-0.2-0.1,2.7-0.1,2.7c0,0.9-0.2,2.1-0.4,2.3c-0.1,0.4,0,4.2,0,4.1 c0.2,0.5,0.7,0.3,0.7,0.3c0-0.1-0.4-0.3-0.2-1c0.1,0,0.2,0,0.2,0c0.4,0,0.8-0.1,1.2-0.5c0,0-0.4-0.2-0.7-0.4 c-0.1-0.1-0.2-0.3-0.3-0.4c0,0,0,0,0-0.1c0.3,0.2,0.5,0.3,0.8,0.3c0.5,0,0.9-0.2,0.9-0.2s-0.4-0.2-0.6-1c0.2,0.4,0.6,0.1,0.8,0.1 c0,0-0.5,0-0.7-1c0.1-0.1,0.3-0.3,0.4-0.4C76.7,20.2,76.9,19.8,77.1,19.2z"
      />
    </g>
  )
}

export default MercerCut
