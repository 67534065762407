import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongswordLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongswordLeft") ? (
    <g id="LongswordLeft">
      <path
        id="S_x24_longswordLeft"
        d="M68.4935303,98.9728394c4.0766525,0.436882,7.7876282-2.283165,8.6222382-6.3171997 l0.0532761-0.4971542c0.2166443-1.0830765,0.2237701-2.0880432,0.0320358-3.1143188l0.628624-3.0503769 c2.8018341-0.8060303,4.8260193-2.8017044,5.7103043-5.4223938c1.3211136-3.8813248-0.6141586-7.4076233-2.0238953-9.2684326 l8.6728134-41.5121956c2.3084946-11.2178802,0.4620132-14.6340885-1.9313812-16.7008896l-7.205162-1.2750311 c-3.7713089,1.4061451-6.008606,5.3904276-8.0720139,14.3214045l-8.672821,41.5121918 c-1.4985886,0.8451233-5.0781631,3.2775497-5.5470161,7.6524887c-0.2983627,2.7840652,0.8272743,5.419014,3.0324593,7.3650665 l-0.649929,3.2492447c-0.5930557,0.8415985-0.8984756,1.8145981-1.1044655,2.7982483l-0.0532761,0.4971542 c-0.866581,4.3323212,1.8996277,8.5511093,6.2319565,9.4176788l1.5695801,0.3693466L68.4935303,98.9728394z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongswordLeft, {
  character: "ManHalfling"
})
