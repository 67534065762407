import React from "react"
import { withSkinColor } from "../../builderConnector"

const HeadBase = ({ skinColor, color = [] }) => {
  return (
    <g id="HeadBase">
      <path
        id="headBase"
        fill={skinColor}
        d="M60.0086823,45.2052307c-0.099884-0.200058-1.9994392-1.0011177-2.8006096,1.0984344 l-0.0000572,0.0999985l-1.0981598-3.3006134c-1.098938-1.9006157-3.1976585-4.201786-5.8976593-4.203289l-3.2999954-0.0018425 c-2.7000008-0.0015068-4.8012238,2.1973228-5.9023438,4.1967087L39.90802,46.3940125l0.0000534-0.0999985 c-0.6988297-2.1003914-2.6992722-1.301506-2.7993851-1.1015625c-0.300499,0.8998337-0.600666,1.1996651,0.2978783,3.8001671 c0.2996674,0.6001663,1.1997223,0.5006676,1.6998367,0.3009491c0.1978798,3.8001099,2.8672752,8.0413818,9.4981995,8.0413818 c6.8066444,0,9.199955-4.7309494,9.4017982-8.030838c0.4998856,0.2002792,1.3998337,0.3007812,1.7001686-0.2990494 C60.6080132,46.4055595,60.3081818,46.1053925,60.0086823,45.2052307z"
      />
    </g>
  )
}

export default withSkinColor(HeadBase, { character: "ManHalfling" })
