import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GoldAxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GoldAxeRight") ? (
    <g id="GoldAxeRight">
      <path
        id="S_x24_goldAxeLeft_3_"
        d="M38.5187759,117.7817612l-5.4700241-4.0298462l-7.7535992-28.2351608l-1.6859779,0.884552 c-4.0676556-1.1310654-7.2576427-4.1095047-8.3873758-7.8900757l0,0 c-1.1297293-3.780571-0.1902723-8.0473862,2.6384478-11.2703323l3.2833691-0.2973862l3.0127735-2.6330338l2.027298-0.6524277 l0.5150623-0.2005386l0.1497135,0.0329628l0.0164814-0.0748558l0.3323898-0.083786l4.0497913,0.4992523l2.7655506-1.5101891 c4.1425095,1.1475449,7.2576408,4.1094933,8.4622269,7.9065514l0,0c1.1297302,3.7805786,0.1902771,8.0473862-2.6384468,11.27034 l-2.0781136,0.1703262c1.9854469,7.7362747,4.0457497,15.4890213,4.8403397,17.9400558 c1.162693,3.6308594,3.1920738,8.3158875,3.8266449,9.7113647l-0.3392334,5.1053314 c-0.3728981,0.6242676-0.8206635,1.232048-1.3103104,1.6736221l-3.5635605,1.5699463L38.5187759,117.7817612z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GoldAxeRight, {
  character: "ManGnome"
})
