import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MorningStarRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MorningStarRight") ? (
    <g id="MorningStarRight">
      <path
        id="S_x24_morningStarRight"
        d="M5.4,88.8c0.1-0.6,0.2-1.2,0.4-1.7c0.1-0.7,0.4-1.5,0.8-2.2C7,84.2,7.5,83.6,8.1,83 c0.2-0.2,0.4-0.5,0.6-0.7c0-0.1,0-0.2,0-0.3v-2.5l0,0l0,0v-2.3c0-0.3,0-0.7,0.1-1c0-0.3-0.1-0.7-0.1-1v-4.3c0-0.3,0-0.7,0.1-1 c0-0.3-0.1-0.7-0.1-1v-2.5l0,0l0,0v-2.3c0-1.9,0.8-3.8,2.2-5.1c0.3-0.3,0.5-0.6,0.8-0.9c1-1,2.8-2.1,5.6-2.1c1.2,0,2.3,0.2,2.7,0.3 c1.7,0.4,3.2,1.3,4.2,2.7l1.3,1.7c0.3,0.4,0.5,0.7,0.7,1.1c0,0,0,0.1,0.1,0.1l3.8,5.8l0.3,0.1c1.1,0.5,2.1,1.2,2.9,2.2l0.8,1 c1.1,1.4,1.6,3.1,1.5,4.8l4.1,6.1c0.8,1.2,1.2,2.7,1.2,4.1c-0.1,2.9-1.4,5.5-3.6,7c-1.4,1-3.2,1.5-5,1.5c-0.7,0-1.2-0.1-1.6-0.1 c-1.6-0.2-3-1-4.1-2.2c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.7-0.4,1.5-0.8,2.2c-0.3,0.5-0.6,1-1,1.4c-0.5,0.9-1.1,1.7-2,2.3 c-0.6,0.4-1.2,0.8-1.8,1c-0.6,0.4-1.2,0.6-1.9,0.8c-0.6,0.2-1.3,0.3-1.9,0.3c-0.3,0-0.5,0-0.8,0c-0.2,0-0.4,0-0.6,0 c-0.3,0-0.6,0-0.9-0.1c-0.6-0.1-1.2-0.2-1.7-0.4c-0.7-0.1-1.5-0.4-2.2-0.8c-0.4-0.2-0.8-0.5-1.1-0.8c-0.8-0.4-1.5-1-2.1-1.7 c-0.4-0.5-0.7-1-1-1.5c-0.5-0.6-0.8-1.3-1.1-2c0.2-0.9,0.1-1.7,0.1-2.6C5.4,90.4,5.3,89.6,5.4,88.8z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MorningStarRight, {
  character: "ManDwarf"
})
