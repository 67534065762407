import React from "react"

const ShoulderPlate = ({ colors = ["#9E9E9E", "#808080"] }) => {
  return (
    <g id="ShoulderPlate">
      <path
        fill={colors[0]}
        d="M25.2,55.4c0,0-0.8,2.5,0.2,4.5c2.9,0.3,10.3-0.1,10.9-2.8c0.2-0.8-0.5-3.2-0.5-3.2 C34.9,53.2,26.9,49.6,25.2,55.4z"
      />
      <path
        fill="gray"
        d="M35.8,53.9c0,0.2,0,1.3-0.1,1.4c-0.6,2.7-7.7,2.8-10.6,2.5c-0.1-0.2-0.1-0.7-0.2-0.9 c0,0.7,0.1,1.7,0.4,2.4c2.9,0.3,10.3-0.1,10.9-2.8C36.4,56.1,36,54.7,35.8,53.9z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M71.5,55.4c0,0,0.8,2.5-0.2,4.5c-2.9,0.3-10.3-0.1-10.9-2.8c-0.2-0.8,0.5-3.2,0.5-3.2 C61.7,53.2,69.7,49.6,71.5,55.4z"
        />
        <path
          fill="gray"
          d="M60.9,53.9c0,0.2,0,1.3,0.1,1.4c0.6,2.7,7.7,2.8,10.6,2.5c0.1-0.2,0.1-0.7,0.2-0.9c0,0.7-0.1,1.7-0.4,2.4 c-2.9,0.3-10.3-0.1-10.9-2.8C60.2,56.1,60.7,54.7,60.9,53.9z"
        />
      </g>
    </g>
  )
}

export default ShoulderPlate
