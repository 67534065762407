import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const NobelCape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("NobelCape") ? (
    <g id="NobelCape">
      <path
        id="S_x24_nobelCape"
        d="M22,111.9c-3.2,0-10.8-4.2-12.9-6.5c-1.3-1.4-3.9-7.7-3.9-7.7c7.5-11.6,13.4-23.2,18.4-35.8 c-1.7-0.9-3.1-1.9-4.2-3.1c-3.6-3.9-4.6-8.4-2.6-12.2c2.1-3.9,6.6-5.4,8.4-5.9c1.4-1.2,44.7-1.5,46.1-0.3c1.8,0.5,6.3,2,8.4,5.9 c2,3.8,1.1,8.4-2.6,12.2c-1.1,1.2-2.5,2.2-4.2,3c5,12.8,10.9,24.4,18.5,36.1c0,0-2.1,5.9-3.4,7.2c-2.1,2.3-10.2,6.9-13.4,6.9 C72.5,111.9,24.1,111.9,22,111.9z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(NobelCape, { character: "ManDwarf" })
