import React from "react"

const Eyes = ({ colors = ["#78D1AD"] }) => {
  return (
    <g id="Eyes" fill={colors[0]}>
      <polygon points="67.5,16.3 67.5,16.1 67.3,15.9 67,15.6 66.4,15.5 65.8,15.6 65.5,16 65.5,16.1 65.6,16.3" />
      <polygon points="71,16.3 71,16.1 71.1,15.8 71.4,15.6 72,15.5 72.7,15.6 72.9,16 73,16.1 72.9,16.3" />
    </g>
  )
}

export default Eyes
