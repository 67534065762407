import React from "react"

const LiteSmile = ({ colors = ["#353891"] }) => {
  return (
    <g id="LiteSmile">
      <path
        id="D_x24_liteSmile"
        opacity="0.3"
        fill={colors[0]}
        d="M52.4555931,60.6870689c0,0-3.3397484,0.8300171-4.9708862,0.8300171 s-4.9709473-0.8300171-4.9709473-0.8300171s4.3007812,1.4939079,4.9709473,1.4885979 C48.1548729,62.1809769,52.4555931,60.6870689,52.4555931,60.6870689z"
      />
    </g>
  )
}

export default LiteSmile
