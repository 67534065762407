import React from "react"

const Pants = ({ colors = ["#6B645F"] }) => {
  return (
    <g id="Pants">
      <path
        fill={colors[0]}
        d="M59.8757935,101.604599c-0.0125732,2.5693359-0.4309082,8.095459-0.5997925,10.3953857h1.4438477 l2.3842773-7.6063843l0.2816772-3.5002441l0.7644043-2.8564453l0.2355957-2.5462646L67.4202271,85.31633h1.7261963 l3.0344238,10.1743164l0.2355957,2.5462646l0.7644043,2.8564453l0.2816772,3.5002441l2.3842773,7.6063843h1.4438477 c-0.1688843-2.2999268-0.5872192-7.8260498-0.5997925-10.3953857c-0.0097046-1.989624-1.0292358-3.0258789-1.2337646-3.8967285 c-0.078064-0.3322754-0.0892944-1.7133789-0.1072998-1.9311523C76.1777344,90.6944427,75.7677002,83.078537,75.875,82.0977631 c0.045105-0.4127197-1.9714355-3.701355-2.5748901-4.4657593c-0.0133057-0.0620117-2.5855713,0.5418091-5.0618286,0.5490723 c-2.5299683,0.0073853-4.9649048-0.5808716-4.9717407-0.5490723c-0.6034546,0.7644043-2.6199951,4.0530396-2.5748901,4.4657593 c0.1072998,0.9807739-0.3027344,8.5966797,0.5252075,13.6789551c-0.0180054,0.2177734-0.0292358,1.598877-0.1072998,1.9311523 C60.9050293,98.5787201,59.885498,99.614975,59.8757935,101.604599z"
        id="C_x24_pants_1_"
      />
    </g>
  )
}

export default Pants
