import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import KneeArmorR from "./KneeArmorR"
import KneeArmorL from "./KneeArmorL"
import CalfWrapR from "./CalfWrapR"
import CalfWrapL from "./CalfWrapL"
import ThighWrapR from "./ThighWrapR"
import ThighWrapL from "./ThighWrapL"

const CATEGORY = "legGear"
const CHARACTER = "ManMixed"
export const IDS = {
  KNEE_ARMOR_R: "KneeArmorR",
  KNEE_ARMOR_L: "KneeArmorL",
  CALF_WRAP_R: "CalfWrapR",
  CALF_WRAP_L: "CalfWrapL",
  THIGH_WRAP_R: "ThighWrapR",
  THIGH_WRAP_L: "ThighWrapL"
}

export const components = {
  [IDS.KNEE_ARMOR_R]: KneeArmorR,
  [IDS.KNEE_ARMOR_L]: KneeArmorL,
  [IDS.CALF_WRAP_R]: CalfWrapR,
  [IDS.CALF_WRAP_L]: CalfWrapL,
  [IDS.THIGH_WRAP_R]: ThighWrapR,
  [IDS.THIGH_WRAP_L]: ThighWrapL
}

export const Group = ({ props }) => (
  <g id="legGear">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.KNEE_ARMOR_R,
    name: "Knee Armor R",
    defaultColors: ["#7C7C7C", "#FFFFFF", "#262261"],
    colorable: true,
    component: components[IDS.KNEE_ARMOR_R],
    inUI: true,
    enabled: false,
    subGroupId: 5
  },
  {
    id: IDS.KNEE_ARMOR_L,
    name: "Knee Armor L",
    defaultColors: ["#7C7C7C", "#FFFFFF", "#262261"],
    colorable: true,
    component: components[IDS.KNEE_ARMOR_L],
    inUI: true,
    enabled: false,
    subGroupId: 4
  },
  {
    id: IDS.CALF_WRAP_R,
    name: "Calf Wrap R",
    defaultColors: ["#493812"],
    colorable: true,
    component: components[IDS.CALF_WRAP_R],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.CALF_WRAP_L,
    name: "Calf Wrap L",
    defaultColors: ["#493812"],
    colorable: true,
    component: components[IDS.CALF_WRAP_L],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.THIGH_WRAP_R,
    name: "Thigh Wrap R",
    defaultColors: ["#493812"],
    colorable: true,
    component: components[IDS.THIGH_WRAP_R],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.THIGH_WRAP_L,
    name: "Thigh Wrap L",
    defaultColors: ["#493812"],
    colorable: true,
    component: components[IDS.THIGH_WRAP_L],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
