import React from "react"

const CutlassR = ({ colors = ["#DDC300", "#FFFFFF", "#7C7A79"] }) => {
  return (
    <g id="CutlassR">
      <g fill="#21376C">
        <path
          opacity="0.2"
          d="M30.866972,81.7715302l0.1482067,0.8727722c0,0,1.5643959,4.0344925,1.6302643,4.0509567 s-0.1811409,0.2140732-0.1811409,0.2140732s-0.5269547,0.6422272-0.592823,0.6751633 c-0.0658703,0.0329361-2.6512375-5.6976929-2.750042-5.6812286c-0.0988045,0.0164719,1.4820576-0.6751556,1.4820576-0.6751556 L30.866972,81.7715302z"
        />
        <path
          opacity="0.2"
          d="M30.6646957,82.0514755l0.1482048,0.8727646c0,0,1.5643959,4.0344925,1.6302643,4.0509644 c0.0658684,0.0164642-0.1811409,0.2140732-0.1811409,0.2140732s-0.5269527,0.6422272-0.592823,0.6751633 c-0.0658684,0.0329285-2.6512375-5.6976929-2.7500401-5.6812286c-0.0988045,0.0164719,1.4820576-0.6751556,1.4820576-0.6751556 L30.6646957,82.0514755z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M28.787653,81.3905411c0.5800838-0.8757095,1.4191437-1.3413162,1.8740673-1.0399551 c0.4549236,0.3013535,0.353447,1.2555313-0.226675,2.131279c-0.0541859,0.0818024,0.3285885,1.9873428,0.7777405,3.1183167 c0.350935,0.8835831,0.7005367,1.3629074,0.9038677,1.5330734l0.4876213-0.3309555l0.1564369-0.1317444 c0.0164528,0.0823288,0.2799454,0.7245636-0.0247002,1.1033096c-0.3046455,0.3787537-0.981987,0.0137482-0.981987,0.0137482 c-0.4957314,0.4989166-1.3177795,1.09935-2.1879807,0.7684631c-1.0144348-0.3857498-1.6961079-0.6257706-2.3877487-2.4701157 C26.4866524,84.2416458,28.207531,82.2662811,28.787653,81.3905411z"
      />
      <g fill="#21376C">
        <path
          opacity="0.42"
          d="M30.9657784,88.0085144 c0.0427933-0.0423813-2.0032005-1.5337753-1.8802528-3.3473969c-0.0207214-0.8410873,0.2746792-2.2720718,0.2746792-2.2720718 s0.3723698-0.5708313,0.1605721-0.0493927c-1.1733036,4.6437836,1.9088058,5.1295853,1.951376,5.1130981 c-1.247406-2.2971802-1.7771263-4.7270813-1.2721004-5.1872177c0.899992-1.2309875,0.5176525-1.7929001,0.1374512-1.8855743 c1.1685238,0.1098022-0.0139408,2.0585175-0.0139408,2.0585175s0.1482067,2.4947662,1.7043533,4.8413773 c-1.0374432,1.2597275-1.6055737,1.3091583-2.4948101,1.1609497 C30.3985844,88.5194626,30.6682415,88.3033524,30.9657784,88.0085144z"
        />
        <path
          opacity="0.42"
          d="M30.3375034,80.3799591 c-0.0645657-0.0060654-0.1320553-0.0098495-0.2115726-0.0040436 C30.1901207,80.3598785,30.2637939,80.3620148,30.3375034,80.3799591z"
        />
      </g>
      <g>
        <path
          opacity="0.66"
          fill="#FFF"
          d="M28.245079,86.2977524 c0.1216755-0.0704117-0.004385-0.5320053,0.1059742-0.6047363c-0.3003845,0.1980438-0.5938358,0.4060593-0.887289,0.6141434 c-0.7474384-1.9972763,0.8369255-3.8602905,1.4206066-4.5004425c0.0816917,0.1368484,0.198494,0.2512512,0.3521347,0.2727661 c0.0952187,0.013298,0.167429-0.0148773,0.2315845-0.0562515c-0.39744,1.087738-0.5861931,1.9819183-0.622654,2.7016983 c-0.1805077,0.4121704-0.2255516,0.8416519-0.0163765,1.3653336c0.0224476,0.0562515,0.0584221,0.1344604,0.0999794,0.2207947 c-0.3437424,0.2710419-0.6835098,0.544899-1.0193825,0.8230972c-0.1079216-0.1497803-0.1987572-0.2995987-0.2771511-0.4490814 C27.8346367,86.5528336,28.035717,86.4190216,28.245079,86.2977524z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M29.0695629,86.5846863 c0.2505817,0.4652634,0.6368561,1.0696335,1.0161953,1.3503418c-0.3203201,0.1361389-0.8487663,0.2582703-1.4665852-0.0129623 c-0.2270126-0.2016068-0.4099941-0.4049377-0.5740509-0.6086426 C28.3800201,87.0576324,28.7212124,86.8149948,29.0695629,86.5846863z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M32.7442474,86.7611313 c0,0,0.3025856,0.771904-0.1667328,1.0683136c-0.4137421,0.1358566-0.7966061-0.0926285-0.7966061-0.0926285 L32.7442474,86.7611313z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M30.3729534,80.808197 c0,0,0.197607,1.2597427-0.4693184,1.4079514c-0.2470112,0.4446182-0.4693184,4.0015564,1.3585529,5.0390015 c-0.6916275-0.2470093-2.4700966-1.6055679-1.6055641-4.9648972 C30.0024376,81.7221298,30.0518398,82.2655487,30.3729534,80.808197z"
        />
        <path
          fill={colors[0]}
          d="M27.3618507,84.2535706c0.0035229,0.0173187,0.0070839,0.034668,0.0106049,0.0519791 c-0.0161495,0.1570129-0.1921196,0.2674637-0.1200581,0.4300995c0.1097584,0.2476196,0.397274-0.0494003,0.5948811,0 s0.9408379,0.5840378,1.0285988,0.3335648c0.0550117-0.1570129,0.0818081-0.426384,0.1220551-0.5723419 c0.0397205-0.1440811,0.14258-0.0940094,0.3420506-0.2147827c0.5597363-0.0643768,0.5380898-1.1096268,0.3907452-1.5060501 c-0.2766266-0.7444763-1.7292099-0.9973068-1.966341-0.2236328c-0.2518196,0.1712723-0.3502769,0.4680023-0.3567238,0.7858963 c-0.0038681,0.1907349-0.130291,0.2423172-0.0811634,0.4257736c-0.049015,0.1180801,0.0672779,0.3849106,0.0507164,0.5075226 C27.3720818,84.2648926,27.3669853,84.2605057,27.3618507,84.2535706z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M28.8661938,83.1918411 c0,0,0.185257,0.2099533,0.185257,0.2717056s-0.1605568,0.2593613-0.1605568,0.2593613l-0.3458138-0.0864563 l-0.1482048-0.2840576l0.3211117-0.2346573L28.8661938,83.1918411z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M28.0628071,83.4582214 c0.0578823-0.0144958,0.1683788,0.2898788,0.101738,0.4368286c-0.0666409,0.1469574-0.2475948-0.0350494-0.2475948-0.0350494 l-0.0711975,0.0267715C27.8457527,83.8867722,27.8885574,83.5018616,28.0628071,83.4582214z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M28.2733707,83.1053848 c0.014389-0.1630096,0.1574249-0.8245239,0.9551163,0.1399612c-0.0145779,0.0155487-0.4940319-0.5187073-0.6504707-0.1893616 c-0.0982552,0.0794754-0.1477413-0.1177979-0.1893749-0.1070404l-0.197607,0.4528503 c0.0164871,0.0247345,0.2248421,0.2041092,0.2470074,0.2387772c0.0109138,0.0170746-0.1646709,0.0494003-0.189373,0.2799454 c-0.0124359,0.0213318-0.0164661-0.2634811-0.0988083-0.4857864c-0.0113354-0.0453262-0.0658646-0.0329361-0.1235008-0.0329361 c-0.0305939-0.0437927,0.1811409-0.2964096,0.1811409-0.2964096S28.2557621,83.1208344,28.2733707,83.1053848z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M28.0098934,82.718399 c0,0,0.0320683,0.1283188-0.0930614,0.158844c-0.1251316,0.0305328-0.2445183-0.0600357-0.392725,0.0799332 C27.6393795,82.6937027,27.7793503,82.5207977,28.0098934,82.718399z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M27.4051933,84.3130646 c0.0053959-0.0203094,0.0067081-0.0361633,0.0052853-0.0491638c0.049427-0.0535126,0.1129055-0.1033096,0.2041893-0.1128311 c0.1567879-0.0163727,0.6112251,0.1731644,0.8869896,0.0296021l-0.2200413-0.0954819 c0,0,0.4474659,0.1937408,0.857235-0.3702316c-0.0670395-0.0511856-0.0513382-0.220192-0.0338764-0.2143478 c0.0246944,0.0082092,0.1729012,0.1482086,0.2387791-0.1646957c0,0,0.1399612,0.2223282-0.0741215,0.3622894 c-0.0532131,0.0347748-0.091547,0.0393906-0.1189022,0.0270615c0.0162258,0.0767059,0.0460529,0.3778-0.4327412,0.5493164 l-0.1883011-0.0817261c0.0251446,0.0721664,0.0400581,0.4136658-0.0154762,0.5180969 c-0.0437698-0.0248032-0.0768585-0.0391541-0.1175919-0.0608902c0.0039349-0.0232315,0.0067463-0.0445175,0.0058842-0.0589828 c-0.0001125-0.0018005-0.0007877-0.0025482-0.0009365-0.0043106c0.0007114-0.0022507,0.001461-0.0045319,0.0021725-0.0067825 c-0.0009747,0.0011215-0.0019112,0.0022125-0.0028858,0.003334c-0.0105667-0.1203232-0.0772305-0.1460342-0.1562614-0.0445938 c-0.005209,0.0066757-0.0078697,0.0184784-0.0126286,0.0260849c-0.031702-0.0154037-0.0557613-0.0237198-0.0853653-0.037178 c0.0101566-0.0338364,0.0227852-0.0679703,0.018774-0.10009c-0.0131531-0.1048126-0.0599194-0.0610809-0.1068726,0.0034866 c-0.0104542,0.0143509-0.014801,0.0324936-0.023159,0.0481567c-0.0428314-0.01754-0.0775318-0.0280304-0.1156406-0.0414505 c0.0163002-0.0459747,0.0225964-0.0841599,0.005846-0.1036835c-0.0531006-0.0618668-0.0804939-0.0421219-0.1482449-0.0009003 c-0.0163383,0.009964-0.0220337,0.0344772-0.0334263,0.0512238c-0.0461655-0.011055-0.0755081-0.0103073-0.1129055-0.0149918 c-0.0006008-0.0036697,0.0001488-0.009552-0.0007877-0.0127029c-0.0007114-0.0843887-0.0272045-0.0949554-0.0794792-0.0315857 c-0.0089569,0.006218-0.0097065,0.0226669-0.0166397,0.0314407c-0.0615311-0.0007477-0.1034622,0.0082016-0.1379013,0.0192566 C27.4001732,84.3512878,27.4027576,84.3319931,27.4051933,84.3130646z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M29.6566238,82.7348709 c0-0.0741043,0.5187206,1.3256149-0.6339912,1.6549606c0.2305431-0.2223053,0.6751595-0.3458099,0.6586933-1.4408875 C29.6154556,82.8007355,29.6566238,82.7348709,29.6566238,82.7348709z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M27.6970139,83.8546448 c0,0-0.3375797-0.2470093-0.2305412-0.3869781C27.5735092,83.3276901,26.9971542,83.7723083,27.6970139,83.8546448z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M29.491951,82.5619659 c0,0-0.979805-0.9221725-1.6220303-0.1482086C28.1745663,82.3478851,28.7838573,82.133812,29.491951,82.5619659z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M28.8538437,84.5750885 c0.0108051,0.023056,0,0.4199219-0.0679283,0.4260941c-0.0679283,0.0061798-0.1482067-0.1667328-0.1790829-0.1790771 c-0.0308762-0.012352,0.0926304-0.5310745,0.0926304-0.5001984S28.7612152,84.3774872,28.8538437,84.5750885z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M27.5446911,84.0069733 c0.0123501,0.0370483-0.1769066,0.239769-0.2470093,0.321106c-0.0701027,0.0813446,0.0617523-0.4940186,0.0617523-0.4940186 L27.5446911,84.0069733z"
        />
        <polygon
          opacity="0.42"
          fill="#21376C"
          points="27.9522572,83.2041855 27.7917004,82.9077759 27.5570412,82.9818802 27.5076408,83.3770981 27.8534546,83.4141464"
        />
      </g>
      <g>
        <path
          fill={colors[2]}
          d="M22.0285473,56.5436058c0,0.2237854,3.0397339,10.4246101,8.0562267,24.1686821 c-0.456892,0.6340561-0.8205414,1.2681122-0.8205414,1.0443268 C21.1334114,60.9446945,22.0285473,56.5436058,22.0285473,56.5436058z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M22.0285473,56.5436058 c3.1236534,13.9492073,7.7112274,24.923954,7.487442,24.7747612c-0.1118908,0.1678391-0.2517567,0.6620331-0.2517567,0.4382477 C21.1334114,60.9446945,22.0285473,56.5436058,22.0285473,56.5436058z"
        />
        <g fill="#FFF">
          <path
            opacity="0.66"
            d="M22.547966,61.0997429 c-0.1907005-0.9158058-0.3187847-1.6688309-0.4013386-2.2746582c0.1743641,0.1450958,0.3473396,0.2921028,0.520689,0.4385872 c0.1896515,0.7789536,0.3825645,1.5467033,0.5776119,2.3021584 C23.0127811,61.4102058,22.7803745,61.254879,22.547966,61.0997429z"
          />
          <path
            opacity="0.66"
            d="M23.3544617,61.9787483 c0.1512794,0.5797462,0.3029327,1.1581078,0.4563847,1.7202034c-0.2513695-0.1292839-0.5024757-0.258152-0.7526836-0.3871727 c-0.1660442-0.6615868-0.3045082-1.2580452-0.4233723-1.8048172 C22.8746929,61.6641998,23.1149712,61.8208733,23.3544617,61.9787483z"
          />
          <path
            opacity="0.66"
            d="M25.7441597,72.0854721 c-0.0153255-0.0107117-0.0309525-0.0211334-0.0463161-0.0318146c-0.845993-2.4872742-1.5024471-4.6015854-2.0081844-6.3870316 c0.2695446,0.1596375,0.5393124,0.3188248,0.8085957,0.4788361c0.6809235,2.3587036,1.3584366,4.5230789,1.9858875,6.4360046 C26.2313118,72.4160614,25.9817772,72.2511826,25.7441597,72.0854721z"
          />
          <path
            opacity="0.66"
            d="M27.3363552,76.6737976 c-0.2449989-0.6668015-0.4824657-1.3194656-0.7057304-1.9450455c0.2551174,0.1822357,0.5072727,0.3684387,0.760891,0.5526886 c0.2215042,0.6440201,0.4303036,1.2400284,0.6259518,1.7925644 C27.7920666,76.9377899,27.5628815,76.8080978,27.3363552,76.6737976z"
          />
          <path
            opacity="0.66"
            d="M28.3999939,79.5144119 c-0.3239193-0.8512039-0.6325855-1.6742249-0.9258499-2.4684296c0.0494652,0.0303497,0.1002789,0.0584564,0.1496677,0.0889206 c0.1797218,0.1107712,0.3581676,0.2249146,0.5362034,0.3405151c0.3455391,0.9699173,0.6381302,1.7705307,0.8644295,2.3906784 C28.8173695,79.7468948,28.6084557,79.6310272,28.3999939,79.5144119z"
          />
        </g>
      </g>
    </g>
  )
}

export default CutlassR
