import React from "react"

const Brows = ({ colors = ["#754C28"] }) => {
  return (
    <g id="Brows" fill={colors[0]}>
      <path d="M70.1,14.6h0.2l0.3-0.3h1.5l1.5,0.1l0.6,0.8l-0.3,0.1L73,14.9L70.9,15c0,0-0.2,0.3-0.4,0.2 S70,14.8,70,14.8L70.1,14.6C70.2,14.7,70.1,14.7,70.1,14.6z" />
      <path d="M68.3,14.6h-0.2l-0.3-0.3h-1.5l-1.5,0.1l-0.6,0.8l0.3,0.1l0.8-0.2l2.1,0.1c0,0,0.2,0.3,0.4,0.2 c0.1-0.1,0.6-0.5,0.6-0.5l-0.2-0.1C68.3,14.7,68.3,14.7,68.3,14.6z" />
    </g>
  )
}

export default Brows
