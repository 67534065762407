import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SunstaffR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SunstaffR") ? (
    <g id="SunstaffR">
      <path
        id="path_3_"
        d="M49,122.6l1-1.3l1.4-4.3v-3.6l0.2-0.1v-1.9l-0.2-0.5v-1l0.2-0.1v-1.3l-0.2-0.6v-0.9l0.2-0.1v-0.7l-0.2-0.6 v-0.5l0.2-0.1v-3.7l-0.2-0.3v-1.3l0.2-0.1v-1.5l-0.2-0.8v-3.7l0.2-0.1v-2.3l-0.2-0.6v-3.1l0.2-0.1v-1.4l-0.2-0.6v-0.8l0.2-0.1 v-3.5L51.4,81v-0.1l0.2-0.2v-0.7l-0.2-0.5v-0.5l0.2-0.1v-4.6L51.4,74v-0.7l0.2-0.1v-0.6l-0.2-0.6v-1.7l0.2-0.1v-0.2l-0.2-0.6v-3.1 l0.2-0.3v-1l-0.2-0.6v-1l0.2-0.1v-2.6l-0.2-0.3v-1.3l0.2-0.1v-3.5l-0.2-0.3v-0.6l0.2-0.1V51l-0.2-0.2v-2.3l0.2-0.2l1.7-9.6 l-0.2-0.4c0.1-0.3,0.2-0.5,0.3-0.8c1.3-0.7,2.3-1.4,2.8-2l0,0c0.3-0.3,0.5-0.5,0.5-0.5l1.6-6.9c-0.8-2.5-2.9-4.4-5.5-4.9 c-0.2,0-0.4-0.1-0.5-0.1c-0.1-0.2-0.1-0.3-0.2-0.5c-1-2.5-2.5-6.3-6.8-7.4c-0.7-0.2-1.4-0.3-2.1-0.3c-1.2,0-2.4,0.3-3.5,0.8 c-0.2,0.1-0.5,0.2-0.8,0.3c-2.9,1.1-9.7,3.8-9.7,10.7c0,2.7,1,5.4,2.1,8.2c0.6,1.6,1.2,3.2,1.5,4.5c0.6,2.7,0.9,7,0.9,8.2l0.3,0.2 l0,4.2l0.1,0.2h0l0,1.6l-0.1,0l0,1.6l0.1,0.2l0,2.7l-0.1,0l0,1.2l0.1,0.2l0,1.7l-0.1,0l0,2.4l0.1,0.2l0,2.3l0,0l-0.1,0l0.1,0.2 l0,2.1l-0.1,0l0,2.2l0.1,0.2l0,1.5l-0.1,0l0,3l0.1,0.2l0,1.1l-0.1,0l0,2.4l0.1,0.2l0,0.8l-0.1,0l0,0.4l0.1,0.2l0,1.9l-0.1,0l0,2 l0.1,0.2l0,2.6l-0.1,0l0.1,3.8l0.1,0.2l0,3.6l-0.1,0l0,2.4l0.1,0.2l0,2.4l-0.1,0l0,2.5l0.1,0.2l0,0.2l-0.1,0l0,1.7l0.1,0.2l0,1.8 l-0.1,0l0,0.7l0.1,0.2l0,0.9l-0.1,0l0,2.1l0.1,0.2l0.1,6.2l1.9,4.7l1.2,1.3L49,122.6z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SunstaffR, { character: "ManMixed" })
