import React from "react"

const LuteL = ({
  colors = ["#93712B", "#D8A43D", "#6A5121", "#B58A32", "#8D6D2B"]
}) => {
  return (
    <g id="LuteL">
      <path
        fill={colors[0]}
        d="M95.6251678,47.7692108l-0.9030685,0.0498161l-0.1219025,0.3933563l-0.5041656,0.0719986 l-0.3600845-0.720253l0.0332718-0.5983467l0.6925201,0.1385384l0.1828156,0.3102608l0.9916992,0.1551743 C95.7359772,47.5753059,95.8246155,47.7803001,95.6251678,47.7692108z"
      />
      <path
        fill={colors[0]}
        d="M95.7747955,46.8772316l-0.9030685,0.0498199l-0.1108093,0.1939049l-0.3878098-0.2216301 l-0.3767242-0.4210815l-0.071991-0.5041656l0.4875259,0.2271729l0.3822708,0.3213539l0.9916992,0.1551743 C95.8800659,46.7830544,95.9742432,46.8883209,95.7747955,46.8772316z"
      />
      <path
        fill={colors[0]}
        d="M95.9133301,46.1847038l-0.7977982-0.0443573l-0.1108093,0.1939049l-0.4930801-0.127449 l-0.3767166-0.4210777l-0.0719986-0.5041695l0.4875336,0.2271767l0.3822632,0.3213501l0.9916992,0.1551743 C96.024147,45.990799,96.1127853,46.195797,95.9133301,46.1847038z"
      />
      <path
        fill={colors[0]}
        d="M95.3591766,48.9548111l-0.9030685,0.0498161l-0.1108093,0.1939049l-0.4986267-0.0277252 l-0.3656311-0.6205254l-0.0719986-0.5041656l0.4875336,0.2271729l0.3822632,0.3213539l0.9916992,0.1551743 C95.4699936,48.76091,95.5586319,48.9659004,95.3591766,48.9548111z"
      />
      <path
        fill={colors[1]}
        d="M87.1368103,61.9020157c-5.2743073-0.4933319-16.5549393,13.4842224-17.6243515,16.5257721 c-1.0749588,3.1412735-1.5737457,6.7147293,2.6865921,9.2523651c4.2603378,2.5376434,10.4822464-1.4178162,12.3161774-3.816658 C86.4488983,81.470192,90.693634,62.6999817,87.1368103,61.9020157z"
      />
      <path
        fill={colors[2]}
        d="M97.7249222,47.7859268l-0.0442734-1.0027885L88.161705,63.2594299 c0,0,1.5388641,28.0947495-15.9681931,24.5204468c0,0,10.9029007,7.2083969,16.6377792-7.7777405 c1.595932-7.1136169,0.4772034-15.7786484,0.4772034-15.7786484"
      />
      <path
        fill={colors[0]}
        d="M97.1652222,50.6557579l0.7811661,0.3435326l-0.0110931,0.1994514l0.3878098,0.2216263 l0.5152588-0.2714462l0.2216263-0.3878098l-0.6980667-0.0388145l-0.2991791-0.0166359l-0.9916992-0.1551704 C97.1763153,50.4563065,96.9713211,50.5449409,97.1652222,50.6557579z"
      />
      <path
        fill={colors[0]}
        d="M97.4866638,48.472908l0.7811661,0.3435326l-0.0221786,0.3988991l0.3878098,0.2216301 l0.5263443-0.4708977l0.2327118-0.5872536l-0.6980667-0.0388145l-0.3102646,0.1828156l-0.9916992-0.1551743 C97.497757,48.2734604,97.3924789,48.3676414,97.4866638,48.472908z"
      />
      <path
        fill={colors[0]}
        d="M97.7360153,47.5864792l0.7811661,0.3435326l-0.0166397,0.2991714l0.4044495-0.0775452 l0.5152512-0.2714462l0.2216263-0.3878098l-0.5983429-0.033268l-0.5097122,0.1717224l-0.9916992-0.1551704 C97.6473846,47.381485,97.5365677,47.575386,97.7360153,47.5864792z"
      />
      <path
        fill={colors[0]}
        d="M97.8745499,46.8939552l0.7867126,0.2438087l-0.0110931,0.1994476l0.4930801,0.127449 l0.5152588-0.2714462l0.2216263-0.3878098l-0.5983505-0.0332718l-0.5097122,0.1717262l-0.9916992-0.1551743 C97.885643,46.6945038,97.6806488,46.7831383,97.8745499,46.8939552z"
      />
      <path
        fill={colors[0]}
        d="M97.4201279,49.6696053l0.7811584,0.3435326l-0.0166321,0.2991714l0.4930801,0.127449 l0.5263443-0.4708977l0.2216263-0.3878098l-0.5983429-0.0332718l-0.5097122,0.1717262l-0.9916992-0.1551743 C97.3314819,49.4646072,97.2206726,49.6585121,97.4201279,49.6696053z"
      />
      <polygon
        fill={colors[3]}
        points="85.2143326,62.2952843 95.6418915,45.6694489 98.0075455,46.3011513 88.161705,63.2594299"
      />
      <polygon
        fill={colors[4]}
        points="70.9085159,80.3060074 76.1439285,83.2979889 76.6093674,82.1234818 71.5678558,79.2423019"
      />
      <polygon
        fill={colors[0]}
        points="97.6695633,46.9825859 97.7249222,47.7859268 96.8383255,51.1377449 95.9518967,50.8883896"
      />
      <polygon
        fill={colors[2]}
        points="97.264946,50.6613045 96.8383255,51.1377449 97.6861954,46.6834145 98.0075455,46.3011513"
      />
      <ellipse
        transform="rotate(-54.966 80.476 71.325)"
        fill={colors[0]}
        cx="80.476"
        cy="71.325"
        rx="2.597"
        ry="1.798"
      />
      <g>
        <rect
          x="63.543"
          y="63.188"
          transform="rotate(-56.31 84.364 63.198)"
          width="41.641"
          height="0.021"
        />
        <rect
          x="64.583"
          y="63.498"
          transform="rotate(-56.31 85.447 63.509)"
          width="41.728"
          height="0.021"
        />
        <line x1="72.798" y1="80.511" x2="95.93" y2="45.886" />
        <line x1="73.385" y1="80.744" x2="96.528" y2="45.919" />
        <line x1="73.878" y1="80.871" x2="97.016" y2="46.146" />
        <line x1="74.366" y1="81.098" x2="97.514" y2="46.174" />
        <polygon points="73.3935547,80.75 73.3759766,80.7382812 96.5195312,45.9135742 96.5371094,45.9243164" />
        <polygon points="74.3740234,81.1044922 74.3564453,81.0927734 97.5058594,46.168457 97.5234375,46.1791992" />
      </g>
    </g>
  )
}

export default LuteL
