import React from "react"

const HalfCircle = ({ colors = [] }) => {
  return (
    <g id="HalfCircle">
      <path
        id="halfCircle"
        d="M80.5,119.4000015C80.5,137,66.1999969,151.3000031,48.5999985,151.3000031 S16.7000008,137,16.7000008,119.4000015H80.5z"
      />
    </g>
  )
}

export default HalfCircle
