import React from "react"

const SimpleVest = ({ colors = ["#6E5238", "#CFA987", "#8C725C"] }) => {
  return (
    <g id="SimpleVest">
      <g id="vest" fill={colors[0]}>
        <path d="M36.4865875,70.7205048c0.555069,3.2299881,1.1894379,5.4399719,1.1894379,5.4399719 l-1.4273262,6.2899704l11.577198-0.9349899l-0.0792961-10.2849503L43.226738,57.4605751l-3.8062019,0.6799965 c0,0-1.8238029-0.3400002-4.6784554-1.2749939C35.7729225,59.1605606,36.9623604,66.6405258,36.4865875,70.7205048z" />
        <path d="M61.0683022,70.7205048c-0.555069,3.2299881-1.1894379,5.4399719-1.1894379,5.4399719l1.42733,6.2899704 l-11.6564941-0.9349899l0.5550728-10.4549561l4.0440865-13.5999298l3.9647942,0.5949974 c0,0,1.6652107-0.170002,4.5198631-1.1899948C61.7026672,59.1605606,60.5132332,66.6405258,61.0683022,70.7205048z" />
      </g>
      <ellipse fill={colors[1]} cx="47.112" cy="71.315" rx="0.317" ry="0.34" />
      <ellipse fill={colors[2]} cx="47.112" cy="71.315" rx="0.238" ry="0.255" />
      <ellipse fill={colors[1]} cx="47.033" cy="74.46" rx="0.317" ry="0.34" />
      <ellipse fill={colors[2]} cx="47.033" cy="74.46" rx="0.238" ry="0.255" />
      <g>
        <ellipse fill={colors[1]} cx="47.033" cy="77.52" rx="0.317" ry="0.34" />
        <ellipse
          fill={colors[2]}
          cx="47.033"
          cy="77.52"
          rx="0.238"
          ry="0.255"
        />
      </g>
      <g>
        <ellipse
          fill={colors[1]}
          cx="46.954"
          cy="80.665"
          rx="0.317"
          ry="0.34"
        />
        <ellipse
          fill={colors[2]}
          cx="46.954"
          cy="80.665"
          rx="0.238"
          ry="0.255"
        />
      </g>
    </g>
  )
}

export default SimpleVest
