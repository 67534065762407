import React from "react"

const HipDaggerRight = ({
  colors = [
    "#66451E",
    "#895A24",
    "#9E8835",
    "#9AB8DB",
    "#6B92C0",
    "#57779C",
    "#E2E4E5",
    "#A0A2A5",
    "#EED793"
  ]
}) => {
  return (
    <g id="HipDaggerRight">
      <polygon
        fill={colors[0]}
        points="36.2,79.3 38.3,73.8 40.6,74.1 39,80.2"
      />
      <polygon
        fill={colors[1]}
        points="34.3,78.3 39.4,79 36,91.6 35.3,92 34.6,91.5"
      />
      <polygon
        fill={colors[0]}
        points="35.5,91.3 38.4,78.8 39.4,79 35.9,91.3 35.8,91.6"
      />
      <polygon
        fill={colors[2]}
        points="38,79.2 39.3,78.2 37.7,76.4 38.1,73.9 38.1,70.7 38.7,69.8 38.2,68.8 37.2,68.7 36.6,69.6 36.9,70.5 36.3,73.7 36.2,76.3 34.3,77.7 35.4,78.9"
      />
      <polygon
        fill={colors[3]}
        points="37.3,68.8 37.4,69.2 37.9,69.2 38.1,68.9"
      />
      <polygon
        fill={colors[4]}
        points="38.2,68.9 38,69.3 38.2,69.7 38.6,69.8"
      />
      <polygon
        fill={colors[3]}
        points="37.2,68.8 37.4,69.2 37,69.7 36.6,69.6"
      />
      <polygon
        fill={colors[5]}
        points="37,70.4 37.2,70.1 37.9,70.2 38.1,70.5"
      />
      <polygon fill={colors[4]} points="36.7,69.7 37,69.7 37.2,70.1 37,70.4" />
      <polygon
        fill={colors[5]}
        points="38.2,69.8 37.9,70.2 38.1,70.5 38.6,69.8"
      />
      <polygon
        fill={colors[4]}
        points="37.1,69.7 37.5,69.2 37.9,69.3 38.2,69.8 37.9,70.1 37.3,70"
      />
      <polygon
        fill={colors[6]}
        points="38.6,78.2 36.9,77.5 35.1,77.8 35.5,91.3 35.5,91.3"
      />
      <polygon fill={colors[7]} points="36.9,77.5 35.5,91.3 38.6,78.2" />
      <path
        fill={colors[8]}
        d="M38,72.4v-1.6c-0.1-0.1-0.3-0.2-0.5-0.3h-0.1c-0.1,0-0.3,0-0.4,0.1l-0.5,2.6 C36.9,72.8,37.4,72.5,38,72.4z"
      />
      <path
        fill={colors[8]}
        d="M36.4,74.4v1.8l0.7-0.5l0.6,0.5L38,74v-1.3C37.3,73,36.7,73.8,36.4,74.4z"
      />
    </g>
  )
}

export default HipDaggerRight
