import React from "react"
import { withSkinColor } from "../../builderConnector"

const Head = ({ skinColor, colors = ["#E7B080"] }) => {
  return (
    <g id="Head">
      <path
        opacity="0.2"
        fill="#21376C"
        d="M70.4406281,63.0799866c0-1.3099976-0.333992-5.1055756-0.333992-5.1055756 l-1.8349533-0.0180016h-0.0488281h-1.7098389c0,0-0.3754883,3.8204956-0.3754883,5.1304932 c0,0-1.1016235,0.3428345-2.6932373,0.6903687c0.9564819,0.3704185,2.1852417,0.8900108,2.9057617,1.4074669 c0.0118408,0.1488647,1.0959473,0.3009644,1.8971558,0.2972412c0.8012695,0.0037231,1.8853149-0.1483765,1.8971558-0.2972412 c0.72052-0.5175171,1.9492798-1.0370483,2.9057617-1.4074669C71.4585724,63.4297371,70.4406281,63.0799866,70.4406281,63.0799866z"
      />
      <path
        fill={skinColor}
        d="M74.5153885,57.2040443c-0.0234375-0.0523071,0.2984619-2.1700439,0.1877441-3.0442505 c-0.0856323-0.6760254-0.4569092-1.2967529-0.6437378-2.0652466c-0.2956543-1.2166138-2.0911484-4.7563133-5.8372498-4.7563133 s-5.3338928,3.5396996-5.6295471,4.7563133c-0.1868286,0.7684937-0.5581055,1.3892212-0.6437378,2.0652466 c-0.1107178,0.8742065,0.2111816,2.9919434,0.1877441,3.0442505c0.0688477,1.026123,4.1837769,3.2285156,5.2922363,4.0245361 c0.0118408,0.1488647,1.7824707,0.1488647,1.7943115,0C70.3316116,60.4324989,74.4465408,58.2301674,74.5153885,57.2040443z"
      />
    </g>
  )
}

export default withSkinColor(Head, { character: "WomanGnome" })
