import React from "react"

const SunstaffR = ({
  colors = ["#664000", "#FFFFFF", "#944200", "#BF8A00", "#SVGID_"]
}) => {
  return (
    <g id="SunstaffR">
      <path
        fill={colors[0]}
        d="M35.0467682,38.8736076l1.0999985,77l1.2000008,1.3000031l1-1.3000031V38.7736092 C36.1467705,36.4736061,35.0467682,38.8736076,35.0467682,38.8736076z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.1467705,50.9736061v-4.5 c0.0999985-0.0999985,0.5999985-0.4000015,0.7999992-0.2000008c0.5999985,0.5999985,1.5,3.5,2.5,3.5999985v3.7000008 C37.246769,52.9736061,36.0467682,51.8736076,35.1467705,50.9736061z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.1467705,54.2736092v-1.5999985 c0.5,0.4000015,1,0.7999992,1.5,1.0999985s1,0.5,1.5,0.7000008c0.0999985,0,0.0999985,0.0999985,0.2000008,0.0999985v3.5 C37.3467712,56.7736092,36.246769,55.5736084,35.1467705,54.2736092z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M37.1467705,64.8736115 c-0.5999985-0.8000031-1.2000008-1.5000038-1.7999992-2.3000031v-2.4000015 c1.0999985,1.2000008,2.0999985,2.4000015,3.2000008,3.6000023v1.0000038 C37.9467697,65.0736084,37.5467682,65.0736084,37.1467705,64.8736115z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.246769,58.3736076v-1.2000008 c0.9000015,0.9000015,2,1.9000015,3.2000008,2.5v2.5999985C37.3467712,60.9736061,36.3467712,59.6736069,35.246769,58.3736076z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.4467697,74.3736115v-3 c0.9000015,0.8000031,1.9000015,1.5,3,1.6999969v4.5999985C37.4467697,76.5736084,36.4467697,75.4736099,35.4467697,74.3736115z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M37.1467705,77.3736115 c0.4000015,0.5,0.9000015,1,1.2999992,1.5v0.6999969c-1,0-2-0.5999985-2.9000015-1.5v-2.4000015 C36.0467682,76.1736145,36.5467682,76.773613,37.1467705,77.3736115z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.8467712,101.4736099v-2.5 c0.7999992,0.5999985,1.7000008,1.1999969,2.5999985,1.5v3.6999969 C37.5467682,103.1736145,36.6467705,102.3736115,35.8467712,101.4736099z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.8467712,103.5736084v-1.6999969 c0.9000015,1.0999985,1.7000008,2.1999969,2.5999985,3.3000031v0.6999969 c-0.4000015-0.1999969-0.7000008-0.4000015-1.0999985-0.5999985 C36.8467712,104.6736145,36.3467712,104.0736084,35.8467712,103.5736084z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.3467712,65.0736084L35.3467712,65.0736084 c1.0999985,1.1999969,2.0999985,2.5,3.0999985,3.6999969v0.1999969 C37.4467697,67.6736145,36.4467697,66.3736115,35.3467712,65.0736084z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M38.4467697,71.3736115v0.5999985 c-1.2999992-0.6999969-2.5-1.6999969-2.7999992-2c-0.0999985-0.0999985-0.2000008-0.1999969-0.2999992-0.3000031v-2.1999969 C36.4467697,68.773613,37.4467697,70.0736084,38.4467697,71.3736115z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.4467697,74.3736115v-3 c0.9000015,0.8000031,1.9000015,1.5,3,1.6999969v4.5999985C37.4467697,76.5736084,36.4467697,75.4736099,35.4467697,74.3736115z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M37.1467705,77.3736115 c0.4000015,0.5,0.9000015,1,1.2999992,1.5v0.6999969c-1,0-2-0.5999985-2.9000015-1.5v-2.4000015 C36.0467682,76.1736145,36.5467682,76.773613,37.1467705,77.3736115z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.8467712,101.4736099v-2.5 c0.7999992,0.5999985,1.7000008,1.1999969,2.5999985,1.5v3.6999969 C37.5467682,103.1736145,36.6467705,102.3736115,35.8467712,101.4736099z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.8467712,103.5736084v-1.6999969 c0.9000015,1.0999985,1.7000008,2.1999969,2.5999985,3.3000031v0.6999969 c-0.4000015-0.1999969-0.7000008-0.4000015-1.0999985-0.5999985 C36.8467712,104.6736145,36.3467712,104.0736084,35.8467712,103.5736084z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.9467697,106.273613v-0.6999969 c0.2999992,0.1999969,0.5999985,0.4000015,0.7999992,0.5c0.2999992,0.1999969,0.7000008,0.4000015,1,0.5 c0.2000008,0.3000031,0.5,0.5,0.7000008,0.8000031v1.3000031 C37.5467682,107.8736115,36.746769,107.0736084,35.9467697,106.273613z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M38.4467697,110.3736115v1.9000015 c-0.4000015-0.4000015-0.7999992-0.6999969-1.2000008-1.0999985c-0.2999992-0.4000015-0.7000008-0.8000031-0.9000015-1.1999969 c-0.0999985-0.0999985-0.2000008-0.3000031-0.2999992-0.5v-2.0999985 C36.746769,108.3736115,37.6467705,109.3736115,38.4467697,110.3736115z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M37.0467682,94.5736084 c0.5,0.9000015,0.9000015,1.8000031,1.4000015,2.5999985v1.5c-0.9000015-0.8000031-1.7999992-1.5-2.7000008-2.3000031v-2.4000015 C36.1467705,94.1736145,36.5467682,94.3736115,37.0467682,94.5736084z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M38.4467697,90.273613v2.3000031l-2.7999992-2.4000015 l-0.0999985-3.8000031C36.5467682,87.6736145,37.5467682,88.9736099,38.4467697,90.273613z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M36.9467697,81.273613 c-0.5-0.5999985-0.9000015-1.3000031-1.4000015-1.9000015v-0.4000015c0.7999992,0.5999985,1.7999992,1,2.9000015,1.0999985v3.5 C37.9467697,82.773613,37.4467697,81.9736099,36.9467697,81.273613z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M35.6467705,83.4736099v-2 c0.9000015,1.1999969,1.9000015,2.3000031,2.9000015,3.5v1.4000015 c-0.5999985-0.3000031-1.0999985-0.6999969-1.7000008-1.0999985 C36.3467712,84.6736145,36.0467682,84.0736084,35.6467705,83.4736099z"
      />
      <path
        fill={colors[0]}
        d="M39.6467705,30.473608c-4-0.7000008-5,1.3000011-3.2000008,2.8999996 s3.7999992,2.9000015,3.7999992,2.9000015l-1.7000008,9.5999985l-3.7000008,0.5999985 c0,0-0.0999985-5.5999985-1.0999985-9.7000008s-3.4999981-8.6000004-3.4999981-11.1000004 s4.2999973-3.7000008,6.1999989-4.6000004c1.9000015-0.7999992,2.2999992,1.7999992,3.7999992,5.2999992 c1.2000008,2.7000008,3.5999985,2.7000008,4.7000008,2.7000008c0.2999992,0,0.5,0,0.5,0.1000004 C45.5467682,29.0736084,43.6467705,31.1736069,39.6467705,30.473608z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M39.6467705,30.473608 c-4-0.7000008-5,1.3000011-3.2000008,2.8999996s3.7999992,2.9000015,3.7999992,2.9000015l-1.7000008,9.5999985h-0.0999985 c0-0.0999985,0-0.2000008,0-0.2999992c0.2999992-6.0999985-3.5999985-9.1000023-3.7999992-9.3999977 c-6.9999981-9,0.5999985-14.6000004,4.7000008-10.2999992c3.7000008,5.2999992,6.0999985,3.1000004,6.0999985,3.1000004 S43.6467705,31.1736069,39.6467705,30.473608z"
      />
      <radialGradient
        id="SVGID_7_"
        cx="311.626"
        cy="176.883"
        r="3.964"
        gradientTransform="matrix(0 1 -2 0 390.278 -282.535)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={colors[2]} />
        <stop offset="0.824" stopColor={colors[3]} />
      </radialGradient>
      <radialGradient
        id="SVGID_8_"
        cx="54.197"
        cy="-94.36"
        r="1.57"
        gradientTransform="rotate(134.999 20.12 -37.527)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="SVGID_9_"
        cx="86.353"
        cy="175.937"
        r="2.329"
        gradientTransform="rotate(134.999 151.687 166.788) scale(1.594 1.9517)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_7_)" cx="36.547" cy="29.074" r="4" />
      <circle
        opacity="0.85"
        fill="url(#SVGID_8_)"
        cx="36.247"
        cy="26.774"
        r="1.6"
      />
      <path
        opacity="0.42"
        fill="url(#SVGID_9_)"
        d="M34.1467705,34.5736084 c1.7999992,1.7999992,4.4000015,2,5.7999992,0.5999985c1.4000015-1.4000015,1.2000008-4.0999985-0.5999985-5.7999992 s-4.4000015-2-5.7999992-0.6000004C32.0467682,30.2736073,32.3467712,32.8736076,34.1467705,34.5736084z"
      />
      <g opacity="0.25" fill="#FFF">
        <path d="M34.0467682,36.4736061c0,0,2.0999985,4.0999985,1.9000015,9.7999992l-1,0.2000008 c0,0-0.0999985-5.5999985-1.0999985-9.7000008c-0.0999985-0.2999992-0.2000008-0.5999985-0.2000008-0.9000015 C33.746769,36.0736084,33.8467712,36.2736092,34.0467682,36.4736061z" />
        <path d="M41.0467682,27.8736076c-1.0999985-0.7999992-1.4000015-2.7000008-2.7000008-4.5 c-2.7000008-3.7999992-6.7999973-0.1000004-7.6999989,2.7000008c-0.0999985,0.2000008-0.0999985,0.2999992-0.0999985,0.5 c-0.0999985-0.3999996-0.0999985-0.7999992-0.0999985-1.1000004c0-2.5,4.2999973-3.7000008,6.1999989-4.6000004 c1.9000015-0.7999992,2.2999992,1.7999992,3.7999992,5.2999992c1.2000008,2.7000008,3.5999985,2.7000008,4.7000008,2.7000008 C44.6467705,28.973608,42.746769,29.2736073,41.0467682,27.8736076z" />
        <path d="M45.4467697,29.1736069l-0.2999992-0.2000008c0.2000008,0,0.4000015,0,0.4000015,0.1000004 C45.546772,29.0736065,45.5467682,29.0736084,45.4467697,29.1736069z" />
      </g>
    </g>
  )
}

export default SunstaffR
