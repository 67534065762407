import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const CutlassR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("CutlassR") ? (
    <g id="CutlassR">
      <path
        id="_x3C_path_x3E__1_"
        fill="#01060F"
        d="M34.3716202,84.1571884l-18.024044-53.1065598l-6.3298731,4.6132984 c0,0-4.398725,12.4451714,8.2610197,45.7038231c-1.2874317,5.5788727-2.6908264,18.3814392,5.7934437,18.5604782 s12.2306023-3.3258667,12.2306023-3.3258667L34.3716202,84.1571884z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(CutlassR, {
  character: "ManHalfling"
})
