import React from "react"

const AxeLeft = ({ colors = ["#8A5F3C", "#6D6E71", "#D1D2D3", "#808184"] }) => {
  return (
    <g id="AxeLeft">
      <path
        fill={colors[0]}
        d="M63.1673012,78.7205811l0.726799-6.5411758 c0.1453514-0.0726776,0.2907143-0.2180405,0.4360771-0.2907181l2.7618256,0.5087585 c0.0726776,0.1453629,0.1453629,0.2907181,0.2180481,0.3633957L65.347702,79.156662 c0,0-4.3607903,18.0245743-5.1602669,22.3126831c-0.6541138,3.4886246-1.0175133,8.1401291-1.0901985,9.7390747 c-0.2180367,0.1453629-0.4360771,0.2180481-0.6541138,0.2907257l-2.1803894-1.0175247l6.3231316-27.8363342L63.1673012,78.7205811 z"
      />
      <path
        fill={colors[1]}
        d="M60.8415489,71.2345657c0.4360771,1.1628799,2.1803894,2.3257599,4.4334679,2.7618408 c2.1803894,0.4360733,4.2154236,0.0726776,5.0875778-0.8721619c1.5262756,1.453598,2.2530746,3.3432617,1.8896713,5.2329407 c-0.3633957,1.8896713-1.8169937,3.3432617-3.7793427,4.142746c-0.4360809-1.1628723-2.180397-2.3257523-4.4334641-2.7618256 c-2.180397-0.4360886-4.2154236-0.0726852-5.0875854,0.8721542c-1.5262642-1.4535904-2.253067-3.3432693-1.8896637-5.2329407 C57.4255981,73.4876404,58.8791885,72.03405,60.8415489,71.2345657z"
      />
      <g fill={colors[2]}>
        <path d="M71.1620712,78.4298706c0.1453629-0.8721619,0-1.7443161-0.3634033-2.616478 c0-0.0726776,0.3634033-0.3633881,0.5814438-0.5814362c-0.5814438,0.2180481-0.726799,0.2180481-0.799469,0.1453629 c-0.3634033-0.5814362-0.7268066-1.0901947-1.3082428-1.5989532c0.4360809-0.1453629,0.8721542-0.3634033,1.0901947-0.6541214 c1.5262756,1.453598,2.2530746,3.3432693,1.8896713,5.2329483c-0.3633957,1.8896637-1.8169937,3.3432617-3.7793427,4.1427383 c-0.0726852-0.2907181-0.2907181-0.5087585-0.5087585-0.726799 C69.6357956,81.1190186,70.7986679,79.9561462,71.1620712,78.4298706z" />
        <path d="M60.8415489,71.2345657c0.1453552,0.3634033,0.4360771,0.726799,0.726799,1.0175247 c-1.7443123,0.5814362-3.1252327,1.8169937-3.415947,3.4886246c-0.0726852,0.5087662-0.0726852,1.0175171,0,1.4535904 c0,0.0726852-0.1453629,0.0726852-0.1453629,0.1453629c0.0726776,0,0.0726776,0,0.1453629,0 c0,0.0726776,0.0726738,0.3634033,0.0726738,0.4360809s-0.145359,0.2180328-0.5087509,0.5814362 c0.2180367-0.1453629,0.6541138-0.2180405,0.7267914-0.1453629c0.2907219,0.6541214,0.726799,1.3082352,1.3082352,1.8896713 c-0.2907143,0.1453629-0.5814362,0.2907257-0.7267914,0.5087662c-1.5262756-1.453598-2.2530746-3.3432693-1.8896751-5.2329407 C57.4982834,73.4876404,58.8791885,71.9613647,60.8415489,71.2345657z" />
      </g>
      <polygon
        fill={colors[3]}
        points="62.7312241,79.6654205 65.5657272,80.1741791 67.2373657,74.2144394 63.312664,73.4149628"
      />
    </g>
  )
}

export default AxeLeft
