import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LuteR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LuteR") ? (
    <g id="LuteR">
      <path d="M57.7,60.5c5.3,6,15.7,22,7,29.9c-9.8,8.9-26.6,0.4-29.1-10.8c-2.3-10.3,0.9-15.6-4.4-25.9c-3.6-6.9-7.7-17.4,5.9-17.9 C45.5,35.5,52.7,54.9,57.7,60.5z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LuteR, { character: "ManMixed" })
