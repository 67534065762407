import React from "react"

const BodyBase = ({ colors = [] }) => {
  return (
    <g id="BodyBase">
      <path
        id="bodyBase_1_"
        d="M41.4000015,119.5c0-1.4000015,4.5-8.3000031,4.9000015-9.8000031 c1.0999985-4.3000031-1.0999985-8,1.5999985-12.3000031c-1.5,0.3000031-4.2000008,1.0999985-4.2000008,1.0999985 c-3.4000015,0.0999985-7.0999985-2.8000031-8.7999992-5.6999969c-2-3.5999985,0.2000008-11.4000015,0.9000015-12.4000015 c2.5-3.5999985,3.9000015-5.0999985,5.5999985-9.3000031c2.0999985-5.3000031,3-11.5999985,5.7999992-16.7000008 c0.5999985-1.0999985,3-2.2000008,3.2999992-3.2000008c0.4000015-1.2000008-0.7000008-3.4000015-0.5-4.7999992 c0.4000015-3.2000008,2.2000008-4.2999992,4.2000008-6.5c4.4000015-4.7999992,8.3999977-8.2999992,15.2999992-8 c6.5,0.2999992,8.8000031,3.4000015,13.0999985,7.5999985c2,2,3.9000015,2.7999992,4.5999985,5.7999992 c0.4000015,1.4000015-0.4000015,3.5,0,4.5999985C87.7999954,51.599987,88.3999939,51.6999855,89.5,53.4999847 c2.9000015,5.0999985,4,11.3000031,6.0999985,16.5999985c1.5999985,4.0999985,3,6.0999985,5.3000031,9.5 c1,1.4000015,4.4000015,8.5,1.6999969,12.9000015c-2.6999969,4.3000031-7.0999985,6-9,6.0999985 s-2.9000015-0.3000031-3.8000031-0.4000015c2.0999985,3.9000015,0.0999985,7.9000015,1.1999969,11.6999969 c0.9000015,3,5.3000031,6.8000031,5,9.6999969C86.8000031,119.5,46.9000015,119.5,41.4000015,119.5z"
      />
    </g>
  )
}

export default BodyBase
