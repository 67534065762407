import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const CutlassR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("CutlassR") ? (
    <g id="CutlassR">
      <path
        id="_x3C_path_x3E__1_"
        fill="#01060F"
        d="M34.2592392,84.056572L23.193203,51.4512901l-3.8862858,2.8323784 c0,0-2.7006397,7.6408348,5.0719337,28.0603027c-0.790432,3.4252014-1.6520596,11.2854614,3.5569401,11.3953857 c5.2089996,0.1099167,7.5090942-2.041954,7.5090942-2.041954L34.2592392,84.056572z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(CutlassR, { character: "ManGnome" })
