import React from "react"

const LongSwordLeft = ({
  colors = [
    "#D0A92B",
    "#A8ABAD",
    "#D5D8DA",
    "#B49330",
    "#FFD144",
    "#EDD692",
    "#7B6528",
    "#A38634",
    "#7951A0"
  ]
}) => {
  return (
    <g id="LongSwordLeft">
      <rect
        x="60.69"
        y="83.297"
        transform="rotate(-80.643 63.31 83.978)"
        fill={colors[0]}
        width="5.24"
        height="1.361"
      />
      <path
        fill={colors[1]}
        d="M62.8958359,80.4813004l2.0960388,0.3562012l4.7458954-28.8110619 c0,0,0.8467789-5.4508286-0.05336-6.1871338c-1.0098419,0.4293976-2.0426865,5.8309326-2.0426865,5.8309326 L62.8958359,80.4813004z"
      />
      <path
        fill={colors[2]}
        d="M69.6255646,45.8735008c0.9343262,0.7951431,0.0533676,6.18713,0.0533676,6.18713l-4.6870575,28.7768707 l-1.0480156-0.1781006L69.6255646,45.8735008z"
      />
      <path
        fill={colors[0]}
        d="M66.766655,79.9636002c0,0-0.2488937-0.5637589-2.6788864-0.4110641 c-2.1930656-0.929512-2.7472878-0.5287399-2.7472878-0.5287399s-1.7914619,2.0642624,2.3664322,2.7178192 C67.9578476,82.4198303,66.766655,79.9636002,66.766655,79.9636002z"
      />
      <path
        fill={colors[3]}
        d="M61.7086372,78.5737305c0,0-0.9128265,1.0027084,1.1681175,1.6038208 c0.2791023,0.0739441,0.4580231,1.4654694,0.8890038,1.5298691c0.4309731,0.0643997,1.0551643-1.1641235,1.3589058-1.1832123 c2.1699905,0.0771027,1.3589096-1.1832123,1.3589096-1.1832123s1.8972855,2.0459137,0.0350189,2.4983826 c-0.1367645-0.2353668-0.4254074-0.4611893-0.797554-0.5597839c-0.5240097-0.0890503-1.1481934,1.1394806-1.3056259,1.5458145 c-0.3681564,0.4500656-0.8046913,0.7824478-1.0837936,0.7084961c-0.4309807-0.0643997-0.6456757-0.569313-0.8603783-1.0742416 c0.005558-0.3967819-0.3252411-1.7787704-0.8492584-1.8678207c-0.3379402-0.0397568-0.6074982,0.0381775-0.8428574,0.1749496 C59.4973145,79.7800217,61.7086372,78.5737305,61.7086372,78.5737305z"
      />
      <path
        fill={colors[4]}
        d="M63.4493141,83.4655304c0.186058,0.0492859,0.5637627-0.2489014,0.9319115-0.6989594 c0.1574326-0.4063263,0.8062668-1.72789,1.4233093-1.6141891c0.337944,0.0397491,0.6854324,0.2313766,0.8563995,0.525589 c0.328392-0.1121216,0.470726-0.2735443,0.5788651-0.4938049c0.0985947-0.3721313-0.1161118-0.8770523-0.414299-1.2547531 c-0.0246506,0.0930328-0.0492935,0.1860657-0.0739441,0.2791061c-0.1916275,0.3474884-0.730751,0.5033417-1.5831604,0.526413 c-0.0588379,0.0341949-0.3188477,0.2639999-0.4269943,0.4842606c-0.3093033,0.4158783-0.5939713,0.7387161-0.9319153,0.6989594 c-0.3379402-0.0397491-0.4596024-0.5200272-0.6401138-0.9661102c-0.0437317-0.2107162-0.1558571-0.5391083-0.2488899-0.5637589 c-0.8714981-0.2806778-1.3804054-0.6146393-1.4678764-1.0360718c-0.0341911-0.0588455-0.0437393-0.2107162-0.0190887-0.3037567 c-0.3188591,0.2639999-0.721199,0.6552277-0.7267609,1.0520096c-0.0151024,0.2449112,0.087471,0.4214249,0.2830849,0.6226044 c0.2353668-0.1367722,0.5049248-0.2146988,0.842865-0.1749496c0.6170464,0.1137009,0.9820442,1.5545197,1.0106773,2.0101471 l0.0341949,0.0588455C62.9650497,83.0385361,63.1113625,83.4257736,63.4493141,83.4655304z"
      />
      <path
        fill={colors[3]}
        d="M62.3019447,87.044548l0.9549866,0.15345 c0.2791061,0.0739517,0.5979576-0.1900482,0.61306-0.4349518l0.0397606-0.3379517 c0.0739403-0.2791061-0.190052-0.5979538-0.4349632-0.61306l-0.9549866-0.15345 c-0.2791061-0.0739517-0.5979576,0.1900558-0.61306,0.4349594l-0.039753,0.337944 C61.7930336,86.7105865,61.9639969,87.0047913,62.3019447,87.044548z"
      />
      <path
        fill={colors[5]}
        d="M64.0369797,86.5085907c0.0739441-0.2791061-0.1900558-0.5979538-0.434967-0.61306 l-0.9891815-0.2122955c-0.2791061-0.0739517-0.5979538,0.1900482-0.61306,0.4349518 C62.5388832,85.9623413,63.5527153,86.0815964,64.0369797,86.5085907z"
      />
      <ellipse
        transform="rotate(-80.643 63.92 80.179)"
        fill={colors[6]}
        cx="63.92"
        cy="80.179"
        rx="0.681"
        ry="0.953"
      />
      <ellipse
        transform="rotate(-80.643 63.92 80.179)"
        fill={colors[7]}
        cx="63.92"
        cy="80.179"
        rx="0.817"
        ry="0.544"
      />
      <ellipse
        transform="rotate(-80.643 63.92 80.179)"
        fill={colors[8]}
        cx="63.92"
        cy="80.179"
        rx="0.681"
        ry="0.408"
      />
    </g>
  )
}

export default LongSwordLeft
