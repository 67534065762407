import React from "react"

const LeatherR = ({ colors = ["#8B5E3C", "#353891"] }) => {
  return (
    <g id="LeatherR">
      <path
        fill={colors[0]}
        d="M32.5995674,80.3075562c0,0,1.7702179-2.132309,3.4599724,0.8046494 c1.6897545,2.9369507-0.6839485,0.9655685-0.6839485,0.9655685s-1.1437111-2.5760956-2.8248596-1.4087448 C32.4788704,80.5489502,32.5995674,80.3075562,32.5995674,80.3075562z"
      />
      <path
        opacity="0.39"
        fill={colors[1]}
        d="M33.9272308,80.3075562c-0.0399704-0.0141678,1.2740211-0.0268173,1.676342,1.8909149 c-0.0536423,0.2816315-0.1341057,0.3755035-0.1341057,0.3218613s-0.3084488,0.1475143-0.2950363,0.1072845 C35.1878395,82.5873871,35.4024124,80.8305817,33.9272308,80.3075562z"
      />
      <polygon
        fill={colors[0]}
        points="34.7318764,81.0719681 35.0537338,82.6410294 35.5365219,82.5203323 35.6974487,81.8363876 35.2146606,80.7098846"
      />
    </g>
  )
}

export default LeatherR
