import React from "react"

const LeatherArmor = ({ colors = ["#914E00", "#223549"] }) => {
  return (
    <g id="LeatherArmor">
      <path
        fill={colors[0]}
        d="M77.8000031,72.4000015c0-2.3000031,0.9000015-6.9000015,0.9000015-6.9000015 c0.5-1,0.8000031-1.5999985,0.5999985-1.4000015l0,0C77,63.2000008,79.1009979,56.6433945,79.1009979,56.6433945L76.4000015,56 l0.1999969-0.2999992L75,55.4000015l-6.4000015,10.3000031l-6.4000015-10.2999992l-1.5999985,0.2999992l0.2000008,0.2999992 l-2.968441,0.5897484c0,0,2.3684425,6.6102524,0.0684433,7.5102539l0,0C57.8000031,63.9000053,58,64.5000076,58.5,65.5000076 c0,0,0.9000015,4.5,0.9000015,6.9000015c0,0-2.2000008,2.3000031-2.7999992,5.5 c2.7999992-0.6999969,7.7999992,5.5999985,10.7999992,8.4000015h2.1999969c3.0999985-2.8000031,8-9.0999985,10.8000031-8.4000015 C80.0999985,74.6999969,77.8000031,72.4000015,77.8000031,72.4000015z"
      />
      <path
        opacity="0.39"
        fill={colors[1]}
        d="M77.8000031,72.4000015 c0-2.3000031,0.898674-6.8652802,0.898674-6.8652802c0.5-1,0.8013306-1.6347198,0.601326-1.4347229 c-0.3000031,0.5-1.4000092,0.4798813-1.4000092,0.5798798c0.0999985,0.6999969,0.8000031,3.0999985-2,5 c-1.9000015,1.1999969-4.6999969,0.8000031-7.3000031-0.6999969l0,0l0,0l0,0l0,0c-2.5999985,1.5-5.5,2-7.2999992,0.6999969 c-2.7999992-1.9000015-2.0999985-4.1999969-2-5c0-0.0999985-1-0.0999985-1.4000015-0.5999985 c-0.0999985-0.1999969,0.0999985,0.4000015,0.5999985,1.4000015c0,0,0.9000015,4.5,0.9000015,6.9000015 c0,0-2.2000008,2.3000031-2.7999992,5.5c2.7999992-0.6999969,7.8000031,5.5999985,10.8000031,8.4000015h2.1999969 c3.0999985-2.8000031,8-9.0999985,10.8000031-8.4000015C80,74.6798782,77.8000031,72.4000015,77.8000031,72.4000015z"
      />
      <g fill={colors[1]}>
        <polygon
          opacity="0.39"
          points="68.6999969,66.3000031 76.4000015,56.0999985 77.0999985,56.2999992"
        />
        <polygon
          opacity="0.39"
          points="68.5999985,66.3000031 60.9000015,56.0999985 60.2000008,56.2999992"
        />
      </g>
      <rect
        x="68.6"
        y="68.9"
        opacity="0.53"
        fill={colors[1]}
        width="0.2"
        height="17.5"
      />
    </g>
  )
}

export default LeatherArmor
