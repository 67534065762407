import React from "react"

const BroadSwordL = ({ colors = ["#888787", "#FFFFFF"] }) => {
  return (
    <g id="BroadSwordL">
      <path
        fill={colors[0]}
        d="M97.0093155-5.0728917c-0.1383743-2.9086452-2.1437836-6.4212661-2.1437836-6.4212661 l-0.542984,0.770071l-1.1359558,4.2382474l-3.98069,58.9980698l1.0248871,1.3574104l2.5484695,0.9419632 C92.6795425,54.8060646,97.1476822-2.1642475,97.0093155-5.0728917z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M90.2141495,54.1688156 c0.2271729-0.4875298,4.7400131-65.4579773,4.7400131-65.4579773s2.0054092,3.5126209,2.1437836,6.4212661 s-4.3353195,59.9786758-4.3353195,59.9786758L90.2141495,54.1688156z"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="94.4111862,-10.5190935 93.275238,-6.2808452 89.18927,52.8114014 90.2141495,54.1688156"
      />
      <path
        fill={colors[0]}
        d="M96.7516022,55.3325806l0.0167236-2.0997581l-9.40728-1.723465l-0.6038971,0.066452l0.0940933,1.9058571 l2.7312927,1.252224c-0.1774368,3.191185-0.6596756,8.2660408-0.6596756,8.2660408l1.7229614,1.396225l1.5291367-0.5151672 l0.2829666-8.6871185L96.7516022,55.3325806z"
      />
    </g>
  )
}

export default BroadSwordL
