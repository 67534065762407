import React from "react"
import { withSkinColor } from "../../builderConnector"

const HeadPointedChin = ({ skinColor, color = [] }) => {
  return (
    <g id="HeadPointedChin">
      <path
        opacity="0.3"
        fill="#21376C"
        d="M71.9,27.3c0.1-0.2,2.1-2.1,2-4.1c0-1,0-1.9,0-1.9h-9.6 c0,0,0,0.9,0,1.9c0.3,2.5,1.9,3.9,2,4.1c0,0,1.7,1.6,3,1.6C70.5,28.8,71.9,27.3,71.9,27.3z"
      />
      <path
        fill={skinColor}
        d="M71.5,25.6c0.2-0.2,1.4-1.4,1.7-2.1c0.3-0.8,1.7-2.7,1.9-3.6c0.3,0.1,0.7,0,0.8-0.5 c0.5-2.3,0.6-3.3,0.4-3.5c-0.2-0.2-0.9-0.7-1.4,0.2c0.2-3-0.1-3.4-0.7-4.5c-1.1-2-2.6-2.7-5.1-2.7s-4,0.7-5.1,2.7 c-0.6,1.1-0.8,1.4-0.7,4.5c-0.5-0.9-1.2-0.4-1.4-0.2s-0.1,1.2,0.4,3.5c0.2,0.4,0.6,0.6,0.8,0.5c0.2,0.9,1.6,2.8,1.9,3.6 c0.3,0.7,1.5,1.9,1.7,2.1c0,0,1.3,0.6,2.5,0.6S71.5,25.6,71.5,25.6z"
      />
    </g>
  )
}

export default withSkinColor(HeadPointedChin, { character: "ManMixed" })
