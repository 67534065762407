import React from 'react'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { apiRoutine } from '../routines'
import { apiRequestAction } from '../reducers/apiReducer'
import { setSavedAlert, resetCharacter } from '../reducers/builderReducer'
import { characterSearch } from '../actions/builderActions'
import { characterSelector } from '../selectors/builderSelectors'
import Character from './Character'
import Controls from './Controls'
import ItemColorPicker, { SkinColor } from './ColorPicker'
import { RequireSignIn } from '../Auth'
import { push } from 'connected-react-router'


const SettingsForm = ({
  character,
  handleRaceChange,
  handleNameChange,
  name
}) => {
  return (
    <div className="level-left">
      <div className="level-item">
        <input
          id="name"
          label="Name"
          placeholder="Name your pawn"
          className="input"
          value={name}
          onChange={handleNameChange}
        />
      </div>
      <div className="level-item">
        <div className="select">
          <select
            id="race"
            label="Race"
            value={character}
            onChange={(e) => handleRaceChange(e.target.value)}
          >
            <option key="WomanMixed" value="WomanMixed">Medium Sized - Female</option>
            <option key="ManMixed" value="ManMixed">Medium Sized - Male</option>
            <option key="ManDragonborn" value="ManDragonborn">Dragonborn - Male</option>
            <option key="ManHalfling" value="ManHalfling">Halfling - Male</option>
            <option key="ManDwarf" value="ManDwarf">Dwarf - Male</option>
            <option key="WomanDwarf" value="WomanDwarf">Dwarf - Female</option>
            <option key="WomanGnome" value="WomanGnome">Gnome - Female</option>
            <option key="ManGnome" value="ManGnome">Gnome - Male</option>
          </select>
        </div>
      </div>

      <ItemColorPicker />
    </div>
  )
}

const ConfirmResetModal = ({ handleConfirm, handleClose, enabled }) =>
  enabled ?
    <div className="modal" style={{ display: 'flex' }}>
      <div onClick={handleClose} className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Reset Character Options?</p>
          <button onClick={handleClose} className="delete" ariaLabel="close"></button>
        </header>
        <section className="modal-card-body">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <button onClick={handleClose} className="button">Cancel</button>
            <button onClick={handleConfirm} className="button is-primary">Confirm</button>
          </div>
        </section>
        <footer className="modal-card-foot"></footer>
      </div>
    </div>
  : null

const SavedAlertModal = ({ handleClose, enabled }) =>
  enabled ?
    <div className="modal" style={{ display: 'flex' }}>
      <div onClick={handleClose} className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Pawn Saved!</p>
          <button onClick={handleClose} className="delete" ariaLabel="close"></button>
        </header>
        <section className="modal-card-body">
          <Link to="/minis">View your pawns</Link>
        </section>
        <footer className="modal-card-foot"></footer>
      </div>
    </div>
  : null

class Builder extends React.Component {
  constructor(props) {
    super(props)

    this.svgRef = React.createRef()
    this.state = { fileName: "", race: "WomanDwarf", confirmResetModalEnabled: false }
  }

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.callsCount !== this.props.callsCount)
      this.props.setSavedAlert(true)
  }

  handleNameChange = (e) => {
    this.setState({ fileName: e.target.value })
  }

  handleModalClose = () => {
    this.setState({ savedAlert: false })
  }

  handleSave = () => {
    const bB = this.svgRef.current.getBBox()
    const viewBox = this.svgRef.current.viewBox
    const maxHeightCm = 5.39
    const heightRatio = viewBox.baseVal.height / bB.height
    const heightCm = Math.round(maxHeightCm / heightRatio * 100) / 100

    this.props.apiRoutine(
      apiRequestAction(
        'minis.save',
        'savedMinis',
        {
          svg: `<svg version="1.1" x="0px" y="0px" width="${10 * bB.width}" height="${10 * bB.height}" viewBox="${bB.x} ${bB.y} ${bB.width} ${bB.height}">
              ${this.svgRef.current.innerHTML}
            </svg>`,
            key: `${this.state.fileName || "untitled"}`,
            height: heightCm,
            settings: this.props
        }
      )
    )
  }

  toggleResetModal = () => {
    const val = this.state.confirmResetModalEnabled
    this.setState({ confirmResetModalEnabled: !val })
  }

  handleResetConfirm = () => {
    this.props.resetCharacter(this.props.character)
    this.toggleResetModal()
  }

  render() {
    const height = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;

    const { search, character, push } = this.props

    return (
      <div>
        <div className="level">
          <SettingsForm
            handleNameChange={this.handleNameChange}
            handleRaceChange={(e) => {
              push(`/builder?${characterSearch(e, search)}`)
            }}
            {...this.props}
          />
          <div className="level-right">
            <button
              className='button'
              style={{ marginRight: 5 }}
              onClick={this.toggleResetModal}>reset</button>
            <RequireSignIn>
              <button className='button is-primary'
                onClick={this.handleSave}
              >save</button>
            </RequireSignIn>
          </div>
        </div>
        <div className="columns is-mobile" style={{ height }}>
          <div className="column is-2"
            style={{ overflow: "scroll", height, minHeight: 400 }}
          >
            <div>Skin Tones</div>
            <SkinColor />
          </div>
          <div className="column is-6 is-8-mobile">
            <div style={{ margin: "0 auto", maxWidth: 400 }}>
              <Character svgRef={this.svgRef} />
            </div>
          </div>
          <div
            className="column is-4 is-2-mobile"
          >
            <Controls />
          </div>
        </div>

        <SavedAlertModal
          enabled={this.props.savedAlertEnabled}
          handleClose={() => this.props.setSavedAlert(false)}
        />
        <ConfirmResetModal
          enabled={this.state.confirmResetModalEnabled}
          handleConfirm={this.handleResetConfirm}
          handleClose={this.toggleResetModal}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  builderProps: state.builderReducer,
  savedAlertEnabled: state.builderReducer.savedAlertEnabled,
  character: characterSelector(state),
  callsCount: get(state, "apiReducer.successfulCalls.savedMinis"),
  search: state.router.location.search,
})

const mapDispatchToProps = {
  apiRoutine,
  setSavedAlert,
  resetCharacter,
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(Builder)
