import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const TatteredCape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("TatteredCape") ? (
    <g id="TatteredCape">
      <path
        id="tatteredCape"
        d="M101.8,100.2c2.7-30.8-15.1-75.2-34.2-73.2s-21.7,21-32.3,39.2C31.4,72.8,24.5,84.7,18.4,91 c-3.5,3.6-4.9,6.7-2.2,12C17.4,105.5,95.3,132,101.8,100.2z M34.1,96.5C34,96.7,32.2,98.4,34.1,96.5z M35.3,95 C33.6,96.8,36.8,93.6,35.3,95z M35.6,86C34.2,85.3,36,85.7,35.6,86z M37.5,98.4C34.1,98.4,38.1,97.3,37.5,98.4z M37.6,85.1 C37.3,84.8,37.6,84.7,37.6,85.1z M37,84.5C34.6,85.3,38,84,37,84.5z M39.3,89.8c-0.4,0,0.5-0.8,0.8-0.8 C40.5,89,39.6,89.8,39.3,89.8z M70.7,107.2C68,110.6,71.9,106,70.7,107.2z M71.6,105.3C67.7,107.9,73.9,103,71.6,105.3z M87.6,96.8c-0.7,0,0.7-1.3,1.2-1.3C89.6,95.4,88.2,96.8,87.6,96.8z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(TatteredCape, {
  character: "ManMixed"
})
