import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Cape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Cape") ? (
    <g id="Cape">
      <polygon points="41,96.5999985 37.2999992,119 46.7999992,118.9000015 48.7999992,95.5" />
      <polygon points="95.9000015,96.5999985 99.5999985,119 90.0999985,118.9000015 88.0999985,95.5" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Cape, { character: "WomanDwarf" })
