import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const StaffLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("StaffLeft") ? (
    <g id="StaffLeft">
      <path
        id="S_x24_staffLeft"
        d="M64.1,119.4L64.1,119.4c-0.5-1.1-1.1-2.4-1.5-3.9l-0.3-1.4c-0.1-0.8-0.2-18.8,0.5-21.8l0.1-0.3l0,0 v-0.2c0.1-0.4,0.2-0.8,0.3-1.1c0.4-1.6,0.5-2,0.5-2.4L63.6,88c-0.1-0.3-0.2-0.7-0.3-1c-0.2-0.8-0.5-1.7-0.7-2.7 c-0.2-0.8-0.2-1.5-0.2-1.9c0-0.1,0-0.2,0-0.3c0,0.1,0.1,0.6,0.4,1.4c0.1,0.2,0.2,0.3,0.3,0.4L63,83.3h-0.1L62,80.5v-0.2l0.3-2.4 h0.1v-0.3l0,0v-0.1c0,0,0,0,0,0.1l0,0v-0.4l0.2-1.3c0.1-0.7,0.2-1,0.2-1.3c0-0.5,0-0.9,0-1.3v-1c0-0.4,0.1-0.9,0.1-1.4l0.2-0.2 c0-0.6,0-1.3,0-2.1h0.6L63,68.2h-0.3V68l0,0v-0.3v-0.4c0.1-1.2,0.2-2.1,0.2-3.1c0-0.8,0-1.5-0.1-2.1v-0.3v-0.1l0,0 c0-0.3-0.1-0.6-0.1-0.8L62.5,60c-0.1-0.6-0.2-1.2-0.3-1.8l-0.1-0.6C62,55.8,62,54,62,52.2l0,0v-0.1v-0.7c0.2-1.5,0.4-3,0.6-4.3 c0.3-1.7,0.6-3.3,0.6-4.6l0.2-1.3c-0.2-0.8-0.4-1.5-0.5-2.3l-0.1-0.5c-0.1-0.8-0.1-1.5,0-2.2l0,0c0-2.4,0.6-4.5,1.5-6.4v-0.1 l0.1-0.1c0.4-1.4,1.1-2.7,2.1-3.9l0.7-0.7c1.9-1.7,4.3-2.7,6.7-2.9h0.3c0.2,0,0.4,0,0.5,0c0.5-0.1,1-0.1,1.4-0.1h0.2 c2.5,0,4.7,1.3,6.1,3.3c2.1,2.1,3.2,4.9,3,7.8c-0.1,1.5-0.5,3.1-1.3,4.4c-0.3,0.5-0.6,0.9-0.9,1.2l-2.1,1.8L81,40.6v0.1l-0.2,0.1 c-0.3,0.4-0.6,0.7-0.9,1c0,0,0,0.1-0.1,0.1c-0.1,0.7-0.1,1.4-0.2,2.2v0.4l-0.3,0.1l-0.1,1h0.1L79.2,46v0.4c-0.1,0.5-0.2,1-0.3,1.5 v0.2l0,0v0.2l-0.2,0.8h-0.1v0.1v0.3l-0.1,0.7h-0.1c-0.1,0.4-0.1,0.8-0.2,1.3v0.3c0,0.1,0,0.6,0,1c0,0.7,0.1,1.5,0,2.5v0.1l0,0v0.5 h-0.1L78,56.3l0.5,0.8L79,57l0.1,1.3l0,0l-0.2,8.6l-0.2,0.4h0.2L79,69c-0.1,1-0.2,2.1-0.2,3.1v0.4c0,0.7-0.1,1.4-0.1,2.1v0.6 c0,0.4,0,0.7,0,1c0,1.6,0.1,3,0.3,4.2c0.1,0.8,0.3,1.6,0.5,2.3c0.1,0.2,0.1,0.4,0.2,0.6c0.4,1,1,2.6,1.1,4.5c0,0.5,0,0.9,0,1.4 c-0.1,1.9-0.7,3.8-1.8,5.4L78.9,95l0,0l0,0l0,0c0,0.4,0,0.8,0.1,1.5v0.2l-0.5,0.6H79l-0.1,15.1c0,0.2,0,0.5,0.1,0.9 c0.3,0.5,0.5,1,0.5,1l0.3,2.6l0.1,1.1l0.1,1.4L64.1,119.4z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(StaffLeft, { character: "ManDwarf" })
