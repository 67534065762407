import React from "react"

const RectangleShieldLeft = ({
  colors = ["#EED793", "#A02932", "#000000", "#SVGID_", "#FFFFFF"]
}) => {
  return (
    <g id="RectangleShieldLeft">
      <path d="M68.7,101.5c-1.8,0-3.6-0.2-5.8-0.6h-0.3c-0.9-0.2-1.8-0.3-2.7-0.6h-0.2V58.8h0.1h0.3 c3.6,0.8,6.2,1.1,8.6,1.1s5-0.3,8.6-1.1l0.3-0.1v41.6h-0.1l-0.2,0.1c-2.7,0.6-4.7,0.9-6.6,1.1h-0.3l0,0 C69.8,101.5,69.2,101.5,68.7,101.5z" />
      <path
        fill={colors[0]}
        d="M77.3,100.1c-6.9,1.5-10.5,1.5-17.4,0c0-13.7,0-27.4,0-41.1c6.9,1.5,10.5,1.5,17.4,0 C77.3,72.7,77.3,86.4,77.3,100.1z"
      />
      <path
        fill={colors[1]}
        d="M75.6,72c-5.4,1-8.3,1-13.7,0c0-4.2,0-6.4,0-10.6c5.4,0.9,8.3,0.9,13.7,0C75.6,65.6,75.6,67.8,75.6,72z"
      />
      <path
        fill={colors[1]}
        d="M75.6,98c-5.4,1-8.3,1-13.7,0c0-7.9,0-15.8,0-23.7c5.4,0.9,8.3,0.9,13.7,0C75.6,82.2,75.6,90.1,75.6,98z"
      />
      <g opacity="0.37">
        <linearGradient
          id="SVGID_25_"
          gradientUnits="userSpaceOnUse"
          x1="62.555"
          y1="74.144"
          x2="59.818"
          y2="74.144"
          gradientTransform="matrix(1 0 0 -1 0 153.987)"
        >
          <stop offset="0.411" stopOpacity="0" />
          <stop offset="0.927" />
        </linearGradient>
        <linearGradient
          id="SVGID_26_"
          gradientUnits="userSpaceOnUse"
          x1="70.652"
          y1="73.894"
          x2="77.196"
          y2="73.894"
          gradientTransform="matrix(1 0 0 -1 0 153.987)"
        >
          <stop offset="0.411" stopOpacity="0" />
          <stop offset="0.927" />
        </linearGradient>
        <path
          fill="url(#SVGID_25_)"
          d="M62.6,59.6c-0.9-0.2-1.8-0.3-2.7-0.6c0,13.7,0,27.4,0,41.1c1,0.2,1.9,0.4,2.7,0.6V59.6z"
        />
        <path
          fill="url(#SVGID_26_)"
          d="M70.7,60.1v41.1c2-0.2,4-0.5,6.5-1.1c0-13.7,0-27.4,0-41.1C74.7,59.6,72.6,60,70.7,60.1z"
        />
      </g>
      <path
        opacity="0.25"
        fill="#FFF"
        d="M67,60.6c-0.9,0-1.9-0.1-2.9-0.3v40.2c1,0.1,1.9,0.2,2.9,0.3 V60.6z"
      />
      <path
        opacity="0.25"
        fill="#FFF"
        d="M66.1,60.7c-0.4,0-0.7-0.1-1.1-0.3v40 c0.4,0.1,0.8,0.2,1.1,0.3V60.7z"
      />
    </g>
  )
}

export default RectangleShieldLeft
