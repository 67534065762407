import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SlingShotLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SlingShotLeft") ? (
    <g id="SlingShotLeft">
      <path
        id="slingshotL"
        d="M89.5007095,111.8086472c1.3267136,0.0698242,4.6784134-0.2793121,4.0499725-6.5637512 l-1.2568893-22.7636261l-0.3491364-1.6060257l-0.6284409-1.4663696l-2.4439468-2.5836029l-5.1672134-0.4887848 l-4.329277,2.0249786c0,0,0.8379288,27.7911835,0.7680969,27.8610153l0.1396561,5.5163422 C80.353363,111.7388229,87.6852036,111.7388229,89.5007095,111.8086472z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SlingShotLeft, {
  character: "WomanGnome"
})
