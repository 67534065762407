import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import SimpleVest from "./SimpleVest"
import BardVest from "./BardVest"

const CATEGORY = "vests"
const CHARACTER = "ManDwarf"
export const IDS = {
  SIMPLE_VEST: "SimpleVest",
  BARD_VEST: "BardVest"
}

export const components = {
  [IDS.SIMPLE_VEST]: SimpleVest,
  [IDS.BARD_VEST]: BardVest
}

export const Group = ({ props }) => (
  <g id="vests">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.SIMPLE_VEST,
    name: "Simple Vest",
    defaultColors: ["#6E5238", "#CFA987", "#8C725C"],
    colorable: true,
    component: components[IDS.SIMPLE_VEST],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BARD_VEST,
    name: "Bard Vest",
    defaultColors: [
      "#8670A2",
      "#D8A16C",
      "#SVGID_",
      "#FFFFFF",
      "#AC7B56",
      "#F9BE7C",
      "#C7AA2E"
    ],
    colorable: true,
    component: components[IDS.BARD_VEST],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
