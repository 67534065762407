import React from "react"
import { withSkinColor } from "../../builderConnector"

const Body = ({ skinColor, color = [] }) => {
  return (
    <g id="Body">
      <path
        id="body_2_"
        fill={skinColor}
        d="M68.4,56.5c-1-1.8,0.1-1.2-4-1.6c-3.7-0.4-5.3-0.9-9.9-1.6v-1.8c0-1.4-1.8-2.5-4-2.6l0,0 h-0.4h-3.2h-0.4l0,0c-2.3,0.1-4,1.2-4,2.6v1.8c-4.6,0.7-6.2,1.2-9.9,1.6c-4.2,0.5-3-0.2-4,1.6c-0.5,3.5,2.8,5.1,2.8,5.1 c0.9,3.1,2.9,7.4,3.8,9.9c0.9,2.5,0.4,1.9,0.5,4.1c-0.3,1.9-1.6,10.8-1.9,12.7c1.2,3.6,1,5.1,2.2,8.7c-0.2,2.2-1.7,3-1.6,5.6 s1.3,8.1,1.6,10.9c-2.6,1.5-3,5.8-2.9,5.9c3.7,0,6.5,0,10.2,0l0,0c0.9-0.1-0.2-4.7-0.8-5.8c1.5-16.5,1.2-7.8,3.6-24.3l0,0v0.1 c0.7,0,1.6,0,2.5,0s1.8,0,2.5,0v-0.1l0,0c2.4,16.5,2.1,7.7,3.6,24.3c-0.6,1.1-1.7,5.7-0.8,5.8l0,0c3.7,0,6.5,0,10.2,0 c0.1-0.1-0.3-4.4-2.9-5.9c0.3-2.9,1.6-8.3,1.6-10.9s-1.4-3.3-1.6-5.6c1.2-3.6,1-5.1,2.2-8.7c-0.3-1.9-1.5-10.9-1.9-12.7 c0.1-2.1-0.4-1.5,0.5-4.1c0.9-2.5,2.9-6.9,3.8-9.9C65.6,61.6,68.9,60,68.4,56.5z"
      />
      <path
        id="neckShadow"
        opacity="0.42"
        fill="#21376C"
        d="M57.2,53.7c-0.8-0.1-1.7-0.3-2.5-0.4v-2.6 c-1.1,0-10.8-0.1-12.5-0.3v2.9c-0.5,0.1-1.2,0.2-1.8,0.3c1.4,2.1,4,3.7,6.9,3.7h1.6H49h1.5C53.3,57.3,55.8,55.7,57.2,53.7z"
      />
    </g>
  )
}

export default withSkinColor(Body, { character: "undefined" })
