import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FrontShieldR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FrontShieldR") ? (
    <g id="FrontShieldR">
      <path
        id="_x3C_path_x3E__1_"
        d="M48.2,85.5c-1,0-1.9-0.1-2.9-0.4c-5-1.3-11.6-5.3-14.9-16.5l-0.1-0.2c-0.2-0.7-0.4-1.5-0.6-2.4 c0-0.1,0-0.1-0.1-0.2l0,0l0,0c-0.4-1.8-0.7-3.6-0.9-5.5c-1.5-11.3-0.8-25.8,10.9-30c1.3-0.5,2.8-0.7,4.2-0.7 c10.7,0,17.2,13.4,18.7,25.8c0.9,7.7-0.2,16-2.9,21.6l-0.1,0.2c-0.5,1.1-1.1,2-1.7,2.9l0,0l0,0l0,0C55.4,83.6,51.9,85.5,48.2,85.5 C48.2,85.5,48.2,85.5,48.2,85.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FrontShieldR, {
  character: "ManMixed"
})
