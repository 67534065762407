import React from "react"

const BigEyes = ({ colors = ["#E0E0E0"] }) => {
  return (
    <g id="BigEyes" fill={colors[0]}>
      <path d="M45.343338,57.1510124c0,0-0.5906105-1.252739-1.1034279-1.3058319 c-0.33918-0.0351448-1.4535027,0.2442055-1.4125328,0.4026642c0.0140457,0.0531273,0.116497,1.2287865,0.1443138,1.2723236 c0.0198326,0.0397682,0.0486412,0.0722084,0.074192,0.1074219c-0.0004234,0.0704155,1.4368324,0.587532,2.1071396,0.4265785 C45.5682144,57.9544754,45.343338,57.1579971,45.343338,57.1510124z" />
      <path d="M49.6260147,57.1510124c0,0,0.5906105-1.252739,1.1034279-1.3058319 c0.3391838-0.0351448,1.4535027,0.2442055,1.4125328,0.4026642c-0.0140419,0.0531273-0.116497,1.2287865-0.14431,1.2723236 c-0.0198364,0.0397682-0.0486412,0.0722084-0.0741959,0.1074219c0.0004234,0.0704155-1.4368286,0.587532-2.1071358,0.4265785 C49.4011383,57.9544754,49.6260147,57.1579971,49.6260147,57.1510124z" />
    </g>
  )
}

export default BigEyes
