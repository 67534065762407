import React from "react"

const StrapL = ({ colors = ["#573B25"] }) => {
  return (
    <g id="StrapL">
      <path
        fill={colors[0]}
        d="M88.6774216,48.2046013l0.3541641,0.7082825c0.0026855-0.0039749-2.8661118-1.0770073-4.5776367,2.028511 c0.1546783-0.1950836,0.3565903-0.449749,0.6347809-0.8008003c2.2192993-1.6645088,4.2025757-0.6610527,4.2025757-0.6610527 l0.4957886,1.0978546c-0.0433578-0.0221367-0.1076126-0.0464745-0.1813812-0.0711899 c0.0878983,0.2020683,0.2104492,0.4663506,0.3112411,0.6142082c-0.6912537-0.3548126-1.5215988-0.1092834-2.2871017,0.4169731 c0.6387634-0.2326927,1.4850693-0.1701012,2.6058121,0.5037956l0.3659821,0.8736c0,0-3.2699738-1.4874268-4.1789856,2.0658531 c-0.2243042-0.3187637-0.6138229-0.8381386-0.6138229-0.8381386s0.1133041-0.6336021,0.4573212-1.2904129 c-0.2781982,0.360775-0.514328,0.7381516-0.681633,1.1015091c-0.2360764-0.2360764-0.6031952-0.8425484-0.6610565-0.9444122 c0.6100693-1.4380531,1.6702042-2.2220917,2.4048691-2.6247749c-1.0448303,0.198307-2.1223221,0.8109016-2.6055374,2.3296547 l-0.6195831-1.035965c-0.0132141,0.0324516-0.0285263,0.0584564-0.0415268,0.0916061 c-0.3791504-0.6195793-0.4924622-0.6233406-0.7082825-1.2867584 C84.8290176,46.6227226,88.6774216,48.2046013,88.6774216,48.2046013z"
      />
    </g>
  )
}

export default StrapL
