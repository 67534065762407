import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SlingShotRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SlingShotRight") ? (
    <g id="SlingShotRight">
      <path
        id="S_x24_slingShotRight"
        d="M19.8799953,119.6649094c-1.6649456,0.0876236-5.8711224-0.3505173-5.0824642-8.2370987 l1.5773163-28.4793243l0.4381447-2.0154648l0.7886562-1.8402023l3.0670052-3.2422562l6.4845219-0.6134033l5.4329796,2.5412369 c0,0-1.0515442,34.8762131-0.9639149,34.9638443l-0.1752586,6.9226685 C31.359354,119.5772781,22.1583424,119.4896545,19.8799953,119.6649094z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SlingShotRight, {
  character: "ManHalfling"
})
