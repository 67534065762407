import React from "react"

const AxeLeft = ({ colors = ["#8B5E3C", "#6D6E71", "#D1D3D4", "#808285"] }) => {
  return (
    <g id="AxeLeft">
      <path
        fill={colors[0]}
        d="M70.7377014,71.1352463l1-9c0.1999969-0.0999985,0.4000015-0.2999992,0.5999985-0.4000015 l3.8000031,0.7000008c0.0999985,0.2000008,0.1999969,0.4000015,0.3000031,0.5l-2.6999969,8.7999992 c0,0-6,24.8000031-7.0999985,30.6999969c-0.9000015,4.8000031-1.4000015,11.1999969-1.5,13.4000015 c-0.3000031,0.1999969-0.5999985,0.3000031-0.9000015,0.4000015l-3-1.4000015l8.6999969-38.3000031L70.7377014,71.1352463z"
      />
      <path
        fill={colors[1]}
        d="M67.5377045,60.835247c0.5999985,1.5999985,3,3.2000008,6.0999985,3.7999992 c3,0.5999985,5.8000031,0.0999985,7-1.2000008c2.0999985,1.9999962,3.0999985,4.5999947,2.5999985,7.2000008 c-0.5,2.5999985-2.5,4.5999985-5.1999969,5.6999969c-0.5999985-1.5999985-3-3.1999969-6.0999985-3.7999954 c-3-0.5999985-5.8000031-0.0999985-7,1.1999969c-2.0999985-2-3.0999985-4.5999985-2.5999985-7.1999969 C62.8376999,63.9352493,64.8376999,61.9352493,67.5377045,60.835247z"
      />
      <g fill={colors[2]}>
        <path d="M81.7377014,70.7352448c0.1999969-1.1999969,0-2.4000015-0.5-3.5999985 c0-0.0999985,0.5-0.5,0.8000031-0.7999954c-0.8000031,0.2999954-1,0.2999954-1.0999985,0.1999969 c-0.5-0.7999954-1-1.5-1.8000031-2.1999969c0.5999985-0.2000046,1.1999969-0.5,1.5-0.9000015 c2.0999985,2,3.0999985,4.5999985,2.5999985,7.1999969s-2.5,4.5999985-5.1999969,5.7000046 c-0.0999985-0.4000015-0.4000015-0.7000046-0.6999969-1C79.6377029,74.4352493,81.2377014,72.8352432,81.7377014,70.7352448z" />
        <path d="M67.5377045,60.835247c0.1999969,0.5,0.5999985,1,1,1.4000015 c-2.4000015,0.7999992-4.3000031,2.4999962-4.7000046,4.7999992c-0.0999985,0.6999969-0.0999985,1.4000015,0,2 c0,0.0999985-0.1999969,0.0999985-0.1999969,0.1999969c0.0999985,0,0.0999985,0,0.1999969,0 c0,0.0999985,0.0999985,0.5,0.0999985,0.5999985s-0.1999969,0.3000031-0.6999969,0.8000031 c0.3000031-0.2000046,0.9000015-0.3000031,1-0.2000046c0.4000015,0.9000015,1,1.8000031,1.8000031,2.5999985 c-0.4000015,0.2000046-0.8000031,0.4000015-1,0.7000046c-2.0999985-2-3.0999985-4.5999985-2.5999985-7.2000046 C62.937706,63.9352493,64.8376999,61.835247,67.5377045,60.835247z" />
      </g>
      <polygon
        fill={colors[3]}
        points="70.1377029,72.4352493 74.0377045,73.1352463 76.3376999,64.9352493 70.937706,63.835247"
      />
    </g>
  )
}

export default AxeLeft
