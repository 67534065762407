import React from "react"

const Eyes = ({ colors = ["#603813", "#231F20"] }) => {
  return (
    <g id="Eyes">
      <path
        fill={colors[0]}
        d="M65.0999985,49c-0.4000015-0.0999985-0.5,0-0.6999969,0 c-0.3000031,0-0.5999985-0.2999992-0.5999985-0.5999985c0-0.0999985-0.2999992-0.2999992-0.2000008-0.5999985 c0.3999977,0.2999992,0.6000023,0,0.7999992,0C65.0999985,47.7999992,65.0999985,48.7999992,65.0999985,49z"
      />
      <path
        fill={colors[0]}
        d="M71.6999969,49.0999985c0.4000015-0.0999985,0.5,0,0.6999969,0 c0.3000031,0,0.5999985-0.2999992,0.5999985-0.5999985c0-0.0999985,0.3000031-0.2999992,0.1999969-0.5999985 c-0.4000015,0.2999992-0.5999985,0-0.8000031,0C71.6999969,47.9000015,71.6999969,48.9000015,71.6999969,49.0999985z"
      />
      <path
        fill={colors[1]}
        d="M63.0999985,46.5999985c0,0,2.1999969-1.9000015,3.0999985,0.9000015 C65.1999969,46.0999985,63.9000015,46.0999985,63.0999985,46.5999985z"
      />
      <path
        fill={colors[1]}
        d="M74,46.5999985c0,0-2.1999969-1.9000015-3.0999985,0.9000015 C71.8000031,46.0999985,73.0999985,46.0999985,74,46.5999985z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M64.4000015,46.2000008 c-0.5,0-1.2000008,0.0999985-1.4000015,0.5999985c-0.2000008,0.4000015,0.2999992,0.7999992,0.7000008,1.0999985v-0.0999985 c0.4999962-1,1.7000008-0.2999992,1.3999977,1.0999985c0,0,0.5,0,0.6999969-0.2999992 C66.9000015,47.7000008,65.6999969,46.2999992,64.4000015,46.2000008z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M73.1999969,46.2000008 c-1-0.4000015-1.8000031,0.5-1.8000031,0.5c-0.0999985,0.0999985-1,1.0999985,0,2.2000008 C71.4999924,49,71.5999908,49,71.6999969,49l0,0c-0.0999985-0.0999985-0.0999985-1,0.1999969-1.2999992 c0.5-0.7000008,1.1999969,0.2000008,1.1999969,0.4000015c0.3000031-0.0999985,0.6999969-0.2999992,0.8000031-0.5999985 C74.1999969,46.7999992,73.8000031,46.2999992,73.1999969,46.2000008z"
      />
    </g>
  )
}

export default Eyes
