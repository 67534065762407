import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WarhammerR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WarhammerR") ? (
    <g id="WarhammerR">
      <path
        id="S_x24_warhammerR"
        d="M23.7000008,74.5l4.8999996-3.3000031c4.9999981-3.3000031,6.3999996-10.2000008,3-15.2000008 l-1.3999996-2.2000008c-3.2999992-5-10.2000008-6.4000015-15.1999998-3l-4.9000006,3.2999992 c-5,3.2999992-6.4000001,10.2000008-3,15.2000008L8.5,71.4999924C11.8000002,76.5,18.7000008,77.9000015,23.7000008,74.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WarhammerR, {
  character: "ManHalfling"
})
