import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GoldAxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GoldAxeLeft") ? (
    <g id="GoldAxeLeft">
      <path
        id="S_x24_goldAxeLeft_2_"
        d="M53.8893585,117.6684341l-3.5635605-1.5699463 c-0.4896545-0.4415741-0.9374199-1.0493546-1.3103142-1.6736221l-0.3392296-5.1053314 c0.6345634-1.3954773,2.6639481-6.0805054,3.8266411-9.7113647c0.79459-2.4510345,2.8548965-10.2037811,4.8403397-17.9400558 l-2.0781174-0.1703262c-2.8287239-3.2229538-3.768177-7.4897614-2.6384468-11.27034l0,0 c1.2045898-3.7970581,4.319725-6.7590065,8.4622269-7.9065514l2.7655525,1.5101891l4.0497932-0.4992523l0.3323975,0.083786 l0.0164795,0.0748558l0.1497116-0.0329628l0.5150528,0.2005386l2.0273056,0.6524277l3.0127716,2.6330338l3.283371,0.2973862 c2.8287201,3.2229462,3.7681732,7.4897614,2.638443,11.2703323l0,0c-1.1297302,3.780571-4.3197174,6.7590103-8.3873749,7.8900757 l-1.6859741-0.884552l-7.7536011,28.2351608l-5.4700203,4.0298462L53.8893585,117.6684341z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GoldAxeLeft, {
  character: "ManGnome"
})
