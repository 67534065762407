import React from "react"

const WideNose = ({
  colors = ["#282560", "#SVGID_", "#FFFFFF", "#EC1C24"]
}) => {
  return (
    <g id="WideNose">
      <path
        opacity="0.3"
        fill={colors[0]}
        d="M70.6,18.2c-0.1,0,0.4,0.2,0.5,1.2l0,0 c-0.2-0.1-0.7-0.4-1.3-0.6c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.1-0.7,0.1-1-0.2c-0.6,0.2-1.1,0.5-1.3,0.6l0,0 c0.1-0.9,0.6-1.2,0.5-1.2c-0.8,0.5-0.7,1.3-0.7,1.3s0.6,0.1,1.1,0.4c0.1,0,0.5,0.3,0.9,0.3c0.5,0,1-0.3,1-0.3 c0.2-0.3,1.2-0.5,1.2-0.5S71.4,18.7,70.6,18.2z"
      />
      <path
        opacity="0.1"
        fill={colors[0]}
        d="M69.3,20.7c0.2,0,0.3-0.1,0.5-0.1h0.1c0.2-0.1,0-1.2-0.1-1.3 l0,0c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-0.8,0c-0.1,0-0.1,0-0.1,0.1s-0.1,0.1-0.1,0.2s-0.1,1,0,1c0,0,0,0,0.1,0 C68.8,20.7,69,20.7,69.3,20.7L69.3,20.7z"
      />
      <path
        fill="url(#SVGID_2_)"
        d="M70.9,18.7c-0.2-0.1-0.9-1.7-0.8-2.6c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.3-0.8-0.4-1.2-0.3 c-0.4,0-0.6,0.2-0.8,0.4c0.1,1-0.1,2.1-0.6,3c-0.1,0.3-0.1,0.5,0,0.5c0.4,0,0-0.2,1-0.4c0.1,0,0.5,0.3,0.9,0.3s0.9-0.4,1-0.4 c0.6,0,0.7,0.3,0.8,0.4c0.1,0,0.1,0,0.2,0.1C71.2,19.5,71.1,18.8,70.9,18.7z"
      />
      <path
        opacity="0.3"
        fill={colors[0]}
        d="M68.2,19.4c0.1,0,0.2,0,0.3,0.1c0.1-0.1,0.1-0.2,0-0.2 c0,0,0,0-0.1-0.1l0,0c-0.2-0.1-0.5,0-0.6,0.1l0,0l0,0l-0.1,0.1C67.8,19.4,68,19.4,68.2,19.4z"
      />
      <path
        opacity="0.3"
        fill={colors[0]}
        d="M70,19.5C70,19.5,70.1,19.5,70,19.5c0.1-0.1,0.3-0.2,0.5-0.2 c0.1,0,0.2,0.1,0.3,0.1l0,0c0-0.1,0-0.1-0.1-0.1l0,0l0,0c-0.2-0.1-0.4-0.2-0.6-0.1l0,0c0,0-0.1,0-0.1,0.1 C69.9,19.3,69.9,19.4,70,19.5z"
      />
      <path
        fill="#FFF"
        d="M69.1,18L69.1,18c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.1,0-0.2,0l-0.1,0.1l0,0V18v0.1l0.1,0.1 c0.1,0,0.1,0,0.2,0l0.1-0.1C69.1,18.1,69.1,18.1,69.1,18C69.1,18.1,69.1,18,69.1,18z"
      />
      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="63.031"
        y1="1300.799"
        x2="63.106"
        y2="1297.032"
        gradientTransform="matrix(1 -.00506 .00506 1 -.297 -1280.978)"
      >
        <stop offset="0" stopColor="#ec1c24" stopOpacity="0.4" />
        <stop offset="1" stopColor="#ec1c24" stopOpacity="0" />
      </linearGradient>
    </g>
  )
}

export default WideNose
