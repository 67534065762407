import React from "react"

const RoundWoodShieldRight = ({
  colors = [
    "#D2A673",
    "#7B6144",
    "#675139",
    "#997954",
    "#SVGID_",
    "#36302A",
    "#726658",
    "#8F8579",
    "#FFFFFF",
    "#F6F6F6",
    "#E4E5E6",
    "#C8CACC",
    "#A2A4A7",
    "#757779",
    "#2D2D2E",
    "#000000"
  ]
}) => {
  return (
    <g id="RoundWoodShieldRight">
      <path
        fill={colors[0]}
        d="M39.6,74.7c-2.4-7.1-10.1-10.9-17.2-8.5s-10.9,10.1-8.5,17.2L39.6,74.7z"
      />
      <path
        fill={colors[1]}
        d="M39.6,74.7c2.4,7.1-1.4,14.8-8.5,17.2c-7.1,2.4-14.8-1.4-17.2-8.5L39.6,74.7z"
      />
      <path
        fill={colors[0]}
        d="M15.5,82.8c2.1,6.2,8.9,9.6,15.1,7.4c6.2-2.1,9.6-8.9,7.4-15.1l-0.5,0.3 c2,5.9-1.2,12.4-7.1,14.4S18,88.6,16,82.7L15.5,82.8z"
      />
      <path
        fill={colors[2]}
        d="M38,75.1c-2.1-6.2-8.9-9.6-15.1-7.4c-6.2,2.1-9.6,8.9-7.4,15.1l0.5-0.2 c-2-5.9,1.2-12.4,7.1-14.4s12.4,1.2,14.4,7.1L38,75.1z"
      />
      <circle fill={colors[3]} cx="26.8" cy="79.1" r="13" />
      <circle fill="url(#SVGID_22_)" cx="26.8" cy="79" r="3.2" />
      <circle fill={colors[0]} cx="23" cy="67.2" r="0.3" />
      <circle fill={colors[0]} cx="31" cy="90.8" r="0.3" />
      <circle fill={colors[0]} cx="15" cy="83.3" r="0.3" />
      <circle fill={colors[0]} cx="16" cy="72.6" r="0.3" />
      <circle fill={colors[0]} cx="38.6" cy="75.2" r="0.3" />
      <circle fill={colors[0]} cx="37.2" cy="85.7" r="0.3" />
      <circle fill={colors[0]} cx="31.5" cy="67.4" r="0.3" />
      <circle fill={colors[0]} cx="21.8" cy="90.4" r="0.3" />
      <path
        fill={colors[5]}
        d="M31.1,67.9L14.9,79.2c0,0.2,0,0.4,0,0.6l16.7-11.6C31.5,68.1,31.3,68,31.1,67.9z"
      />
      <path
        fill={colors[5]}
        d="M36.6,72.2L36.6,72.2L17,85.8l0,0c0.1,0.1,0.1,0.2,0.2,0.3l19.6-13.5C36.7,72.5,36.7,72.3,36.6,72.2z"
      />
      <path
        fill={colors[5]}
        d="M22.6,90.2c0.2,0.1,0.3,0.1,0.5,0.2l15.5-10.7c0-0.2,0-0.4,0-0.5L22.6,90.2z"
      />
      <path
        fill={colors[6]}
        d="M31.6,68.2L14.9,79.7c0.1,2.1,0.8,4.2,2.1,6.1l19.6-13.5C35.3,70.4,33.6,69,31.6,68.2z"
      />
      <path
        fill={colors[6]}
        d="M36.8,72.6L17.2,86.1c1.4,1.9,3.3,3.3,5.4,4.1L38.7,79C38.7,76.8,38.1,74.6,36.8,72.6z"
      />
      <path
        fill={colors[6]}
        d="M33.6,88.8c3.2-2.2,4.9-5.6,5.1-9.2L23.1,90.3C26.5,91.4,30.4,91,33.6,88.8z"
      />
      <path
        fill={colors[6]}
        d="M20,69.2c-3.4,2.3-5.2,6.1-5.1,9.9l16.2-11.2C27.6,66.5,23.4,66.9,20,69.2z"
      />
      <g>
        <ellipse fill={colors[7]} cx="26.8" cy="79" rx="3.7" ry="3.7" />
        <path
          fill={colors[3]}
          d="M25.8,76c-1.6,0.6-2.5,2.4-2,4c0.6,1.6,2.4,2.5,4,2c1.6-0.6,2.5-2.4,2-4S27.4,75.5,25.8,76z"
        />
      </g>
      <radialGradient
        id="SVGID_22_"
        cx="-167.749"
        cy="153.181"
        r="3.206"
        gradientTransform="scale(1 -1) rotate(18.741 633.02 626.434)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.698" stopColor="#fff" stopOpacity="0.1" />
        <stop offset="0.724" stopColor="#f6f6f6" stopOpacity="0.1" />
        <stop offset="0.762" stopColor="#e4e5e6" stopOpacity="0.1" />
        <stop offset="0.809" stopColor="#c8cacc" stopOpacity="0.1" />
        <stop offset="0.862" stopColor="#a2a4a7" stopOpacity="0.1" />
        <stop offset="0.921" stopColor={colors[13]} stopOpacity="0.1" />
        <stop offset="0.983" stopColor="#2d2d2e" stopOpacity="0.1" />
        <stop offset="0.995" stopOpacity="0.1" />
      </radialGradient>
    </g>
  )
}

export default RoundWoodShieldRight
