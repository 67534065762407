import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongSwordRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongSwordRight") ? (
    <g id="LongSwordRight">
      <path d="M45.9000015,99.5999985c-2.4000015,0-4.5999985-1-6.0999985-2.9000015l-0.2999992-0.4000015 c-0.4000015-0.4000015-0.7000008-0.9000015-0.9000015-1.4000015c-0.2999992,0-0.5999985,0-0.9000015,0 c-2.5999985,0-4.9000015-1.0999985-6.5-3.0999985c-2.1000004-2.5999985-2.1000004-5.8000031-1.7999992-7.8000031L7,56.7999992 c-6.8000002-8.2999992-6.6999998-12.1000023-5.5999999-15l5.6999998-4.5l1.0000005-0.0999985 c2.3999996,0,5.5,0.5999985,12.1999989,8.3999977L42.7000008,73c2,0.0999985,5.7000008,0.8000031,8,4.0999985 c1.4000015,2.0999985,1.7999992,4.5999985,1,7.0999985c0.4000015,0.3000031,0.9000015,0.6999969,1.2000008,1.1999969 l0.2999992,0.4000015C56,89.1999969,55.5,94.0999985,52.1000023,96.8999939l-1.2000008,1 C49.5999985,99,47.7999992,99.5999985,45.9000015,99.5999985L45.9000015,99.5999985z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongSwordRight, {
  character: "WomanDwarf"
})
