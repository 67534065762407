import React from "react"

const BlueFlameRight = ({
  colors = ["#80D0DC", "#30BFCA", "#5BC6D1", "#A8DDE6", "#C0E6F0", "#E5F5F8"]
}) => {
  return (
    <g id="BlueFlameRight">
      <path
        opacity="0.77"
        fill={colors[0]}
        d="M54.6459007,36.4262276 c-1.4000015-3.0999985-3.0999985-6.0999985-5.2000008-8.9000015c-0.0999985,0-0.0999985,0-0.2000008,0.0999985 c-0.2000008,0.0999985-1.5999985,0.7999992-1.5,0c0.2000008-1.5,0.6000023-3.4000015,0-4.7999992 c-0.3999977-1.0999985-0.5999985-4.2999992-1.3999977-5.2000008c-0.1000023-0.2000008-2-4.9000015-2.5-6.0999985 c-0.7999992,2.5999985,0.5,5.7999992-0.9000015,8.0999985c-0.0999985,0.0999985-2.7000008,5.5999985-2.8999977,5.7999992 c-0.1000023,3.7000008-0.4000015,7.5-1.2000008,11.1000023c0.3999977-0.2000008,1.0999985-0.5,1-0.2000008 c-1.1000023,4.9000015-4.4000015,8.8999977-5.5,13.7999992c0,0.1999969-0.2000008,0.4000015-0.4000015,0.5 c0.4000015,2.9000015,2.2000008,5.5,4.2000008,7.8000031c-1.0999985-2.5999985-1.8999977-5.1999969-1.8999977-8 c0-0.4000015,0.5999985-0.8000031,1.2999992-1.1999969c0.2000008-3.1999969,0.5-6.0999985,2.5-8.9000015 c2.3999977-3.5,1.5999985-7.2999992,2.2000008-10.9000015c-0.1000023,0-0.1000023,0-0.1000023-0.0999985 c0-0.7999992,0.1000023-1.5999985,0.2000008-2.4000015v-0.0999985c0,0,0,0,0-0.0999985 c0.1000023-0.5999985,0.2000008-1.2000008,0.4000015-1.7999992c0-0.5,3.2000008-3.9000015,3.2999992-3.7000008 c0.2000008,0.7999992,0.5999985,1.4000015,0.5,2.0999985c0.0999985,0,0.2000008,0,0.2000008,0.0999985 c0,2.2000008,0.0999985,4.4000015,0.3999977,6.5999985c0.1000023,0,0.1000023,0,0.2000008,0.0999985 c2.2999992,2.5,3,6.0999985,4.0999985,9.2999992c1.2999992,3.8999977,2,6.3999977,2.0999985,10.4999962 c0,0.1999969-0.0999985,0.3000031-0.2000008,0.5c0.7999992,2.3000031-0.4000015,4.9000015-0.9000015,7.3000031 c-0.0999985,0.3000031-0.2000008,0.6999969-0.2999992,1.0999985c1.0999985-2.1999969,2-4.5,2.9000015-6.9000015 C54.8459015,46.8262291,54.745903,41.6262283,54.6459007,36.4262276z"
      />
      <g opacity="0.98">
        <path
          fill={colors[1]}
          d="M46.6459007,61.526226c-1.8999977-0.5999985-3.5999985-1.3000031-5.2000008-2.1999969 c-0.3999977-0.6999969-0.7000008-1.5-1.0999985-2.1999969c-0.9000015-1.9000015-1.6000023-3.9000015-2.2999992-6.0999985 c0.0999985-0.6999969,0.2000008-1.4000015,0.3999977-2c0.2000008-0.8000031,0.5-1.6999969,0.7000008-2.5 c0.4000015-1.3000031,0.7000008-2.5999985,1-3.9000015l1-0.4000015l-0.5-1c0-0.0999985-0.0999985-0.0999985-0.0999985-0.2000008 c2.0999985-2.5,3.2999992-5,3.7000008-7.4000015c0.0999985-0.9000015,0.0999985-1.7000008,0-2.5999985l0.5999985-0.2999992 c0.2000008,0.5999985,0.4000015,1.0999985,0.6000023,1.5c0.7000008,1.7999992,1.5999985,3.5,2.3999977,5.0999985 c1,2,2,3.7999992,2.7000008,5.8000031c1.7000008,5,1.5,10.3000031-0.7000008,16.9000015 C48.8459015,60.7262306,47.745903,61.2262306,46.6459007,61.526226z M44.245903,29.2262306 c-0.7000008-0.7000008-1.1000023-1.7000008-1.1000023-2.7000008c0.1000023-0.7999992,0.4000015-1.5999985,1-2.0999985 C43.745903,26.1262283,43.9459038,27.8262291,44.245903,29.2262306L44.245903,29.2262306z"
        />
        <path
          fill={colors[2]}
          d="M46.5459023,61.7262306c-1.5999985-0.5-3-1.0999985-4.3999977-1.8000031 c-0.2999992-0.5999985-0.6000023-1.3000031-0.9000015-1.9000015c-0.7999992-1.8000031-1.5-3.6999969-2.2000008-5.6999969 c0.1000023-0.5999985,0.2000008-1.0999985,0.2999992-1.5999985c0.2000008-0.8000031,0.4000015-1.5999985,0.7000008-2.5 c0.2999992-1.0999985,0.6000023-2.1999969,0.9000015-3.4000015l1.5-0.6999969l-0.7999992-1.5999985 c1.7999992-2.2999992,2.8999977-4.5,3.2999992-6.7000008c0.5,1.2000008,1.0999985,2.2999992,1.7000008,3.4000015 c1,1.9000015,2,3.7000008,2.5999985,5.7000008c1.5999985,4.6999969,1.4000015,9.6999969-0.5999985,15.8000031 C48.1459007,61.1262321,47.3459015,61.4262276,46.5459023,61.7262306z"
        />
        <path
          fill={colors[0]}
          d="M46.4459038,61.7262306c-1.2999992-0.4000015-2.5-0.9000015-3.6000023-1.5 c-0.2000008-0.5-0.5-1-0.7000008-1.5999985c-0.7999992-1.6999969-1.3999977-3.4000015-2.0999985-5.4000015 c0.0999985-0.5,0.2000008-0.9000015,0.2999992-1.3000031c0.2000008-0.8000031,0.3999977-1.5999985,0.7000008-2.4000015 c0.2999992-0.9000015,0.5-1.9000015,0.7999992-2.9000015L43.9459,45.726223l-1-2c1-1.3000031,1.7999992-2.5999985,2.2999992-4 c0.2000008,0.2999992,0.2999992,0.7000008,0.5,1c1,1.9000015,1.9000015,3.6999969,2.6000023,5.5 c1.5,4.4000015,1.2999992,9.0999985-0.4000015,14.8000031C47.3459015,61.3262291,46.9459038,61.526226,46.4459038,61.7262306z"
        />
        <path
          fill={colors[3]}
          d="M46.3459015,61.8262291c-1-0.3000031-1.8999977-0.6999969-2.7000008-1.0999985 c-0.2000008-0.4000015-0.3999977-0.8000031-0.5999985-1.1999969c-0.7000008-1.5999985-1.2999992-3.1999969-1.9000015-5 c0.1000023-0.3000031,0.1000023-0.5999985,0.2000008-0.9000015c0.2000008-0.8000031,0.4000015-1.5999985,0.7000008-2.4000015 c0.2000008-0.8000031,0.5-1.5999985,0.7000008-2.4000015l2.7000008-1.1999969l-1.2999992-2.4000015 c0.4000015-0.5999985,0.7999992-1.0999985,1.1000023-1.6999969c0.7999992,1.5,1.5,3,2,4.5 c1.3999977,4,1.2999992,8.4000015-0.2999992,13.6999969C46.6459007,61.6262321,46.5459023,61.7262306,46.3459015,61.8262291z"
        />
        <path
          fill={colors[4]}
          d="M45.9459038,61.4262276c-0.6000023-0.1999969-1.1000023-0.4000015-1.6000023-0.6999969 c-0.0999985-0.3000031-0.2999992-0.5999985-0.3999977-0.8000031c-0.7000008-1.5-1.2999992-3-1.7999992-4.5999985 c0-0.1999969,0.0999985-0.4000015,0.0999985-0.5999985c0.2000008-0.8000031,0.4000015-1.5999985,0.7000008-2.4000015 c0.2000008-0.5999985,0.4000015-1.3000031,0.6000023-2l2.5999985-1.0999985 C47.3459015,52.8262291,47.245903,56.7262306,45.9459038,61.4262276z"
        />
        <path
          fill={colors[5]}
          d="M46.1459007,61.9262276c-0.0999985-0.1999969-0.0999985-0.3000031-0.2000008-0.5 c-0.5999985-1.4000015-1.2000008-2.6999969-1.7000008-4.1999969c0-0.0999985,0-0.1999969,0.1000023-0.3000031 c0.2000008-0.8000031,0.3999977-1.5999985,0.5999985-2.3000031c0.1000023-0.5,0.2999992-1,0.4000015-1.5l1-0.4000015 C47.1459007,55.4262276,47.1459007,58.4262276,46.1459007,61.9262276 C46.245903,61.9262276,46.245903,61.9262276,46.1459007,61.9262276z"
        />
      </g>
    </g>
  )
}

export default BlueFlameRight
