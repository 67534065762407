import React from "react"
import { withSkinColor } from "../../builderConnector"

const Head = ({ skinColor, color = [] }) => {
  return (
    <g id="Head">
      <path
        opacity="0.3"
        fill="#262261"
        d="M71.9,26.6c0.1-0.1,2.1-1.6,2-3.2c0-0.8,0-1.5,0-1.5h-9.6 c0,0,0,0.7,0,1.5c0.3,1.9,1.9,3,2,3.2c0,0,1.7,1.2,3,1.2C70.5,27.8,71.9,26.6,71.9,26.6z"
      />
      <path
        fill={skinColor}
        d="M71.5,25c0.2-0.2,2.8-2.9,3.2-3.7c0.4-0.8,0.2-0.5,0.5-1.4c0.3,0.1,0.7,0,0.8-0.5c0.5-2.3,0.6-3.3,0.4-3.6 c-0.2-0.3-0.9-0.7-1.5,0.2c0.2-3.1-0.1-3.5-0.7-4.5c-1.1-2.1-2.6-3.3-5.2-3.3s-4.1,1.2-5.2,3.3C63.2,12.6,63,13,63.1,16 c-0.5-0.9-1.2-0.4-1.5-0.2S61.5,17,62,19.4c0.2,0.4,0.6,0.6,0.8,0.5c0.2,0.9,0.1,0.7,0.5,1.4c0.4,0.8,3,3.5,3.2,3.7 c0,0,1.3,0.6,2.5,0.6S71.5,25,71.5,25z"
      />
    </g>
  )
}

export default withSkinColor(Head, { character: "ManMixed" })
