import React from "react"

const TurkeyLegR = ({ colors = ["#E8E7C4", "#8C5B1F", "#FFFFFF"] }) => {
  return (
    <g id="TurkeyLegR">
      <path
        fill={colors[0]}
        d="M32.4143486,88.4464417c-0.6016827,0.1866455-0.8560162-0.0662842-0.7629986-0.758728 c-0.2583008-0.6055908-0.5166626-1.2111816-0.7749634-1.8167725c-0.6124268-1.4356689-1.3310547-2.8686523-1.7473145-4.3756104 c-0.2852783-1.0326538,0.3011608-0.9858017-0.0297108-1.9622421c0.225647-2.3897095-0.9684105-4.1521835-0.2682152-6.4371567 c0.0552368-0.2793579-1.1231918-2.9944916-1.2337875-3.0040131c-2.8152466-0.2419434-1.9860878-3.5103607-1.3947182-4.3450775 c0.0136108-0.0405884,0.7379551,0.4235992,0.7122593,0.3879547c-1.0241089-1.5946045-1.8054199-2.3585854-2.1557007-2.7455482 c-0.2422485-0.2677002-0.5007324-0.4759521-0.7652588-0.6431274c-0.0105591-0.008667-0.0248413-0.017334-0.0371704-0.026062 c-0.0305176-0.0186768-0.0606689-0.0378418-0.0913086-0.0552979c-0.062439-0.0349121-0.1334839-0.0692749-0.2142944-0.1035767 c-0.1759644-0.0825806-0.3526001-0.1480103-0.5299072-0.1917114c-0.3244629-0.1019897-0.6264038-0.1785889-0.7039795-0.1933594 c-0.6726074-0.1280518-1.7471924-0.1792603-2.3261108,0.2709351c-1.0559692,0.821228-0.4290771,1.3980713-0.269165,2.6588783 c-2.3631706-0.4319458-3.2391968,2.9459991-1.5048828,5.5760651c0.647644,0.9821167,2.1669159,4.2302399,2.9503632,5.0960846 c1.2438965,1.3747559,2.2016182,1.7443848,3.1649361,3.3258667c0.0247192,0.0405884,1.7185154,2.0325699,1.8238621,2.0920792 c1.0784302,2.0232544,1.2966175,2.997551,1.9906483,5.1506271c0.1939087,0.6014404,0.3878174,1.2029419,0.581665,1.8044434 c0.3445435,0.7398682,0.4351196,1.5179443,0.2717896,2.3341675c0.0582275,0.267395-0.0162964,0.5882568,0.1608276,0.8206787 c0.2905273,0.3811035,0.8878784,0.7208252,1.3855591,0.6861572c0.2167969-0.0150757,0.3719482-0.1725464,0.567627-0.2354126 c0.3196411-0.6831665,0.8544903-1.0013428,1.6044903-0.9544067c0.1038818-0.0255737,0.2070312-0.0534668,0.3096313-0.0838013 C34.8509941,89.7278137,33.3582573,89.0535583,32.4143486,88.4464417z"
      />
      <path
        fill={colors[1]}
        d="M29.0993652,79.5230026c0.225647-2.3897095-0.9683838-4.1521606-0.2681885-6.4371338 c0.0552368-0.2793579-1.12323-2.9945068-1.2338257-3.0040283c-2.8152466-0.2419434-1.986084-3.5103149-1.3947144-4.3450317 c0.0136108-0.0405884,0.7379761,0.423584,0.7122803,0.3879395c-1.0241089-1.5946045-1.8054199-2.3585854-2.1557007-2.7455482 c-0.2422485-0.2677002-0.5007324-0.4759521-0.7652588-0.6431274c-0.0105591-0.008667-0.0248413-0.017334-0.0371704-0.026062 c-0.0305176-0.0186768-0.0606689-0.0378418-0.0913086-0.0552979c-0.062439-0.0349121-0.1334839-0.0692749-0.2142944-0.1035767 c-0.1759644-0.0825806-0.3526001-0.1480103-0.5299072-0.1917114c-0.3244629-0.1019897-0.6264038-0.1785889-0.7039795-0.1933594 c-0.6726074-0.1280518-1.7471924-0.1792603-2.3261108,0.2709351c-1.0559692,0.821228-0.4290771,1.3980713-0.269165,2.6588783 c-2.3631592-0.4319458-3.2391968,2.9459839-1.5048828,5.5760498c0.647644,0.9821167,2.1668701,4.2302246,2.9503174,5.0960693 c1.2438965,1.3747559,2.2016602,1.7443848,3.164978,3.3258667c0.0247192,0.0405884,1.7185059,2.0325928,1.8238525,2.0921021 c0.0784912,0.1472778,0.1436768,0.2788086,0.213562,0.4158325c0.2347412,0.0946045,0.4709473,0.1853638,0.7018433,0.2902222 c0.0855103-0.1970825,0.1693726-0.3692017,0.166748-0.371521c0.4852905,0.4396362,0.9475098,0.8370972,1.5150146,1.1112061 c0.020752-0.5187378,0.1005249-1.0317993,0.2041016-1.5432739C29.0047607,80.4489059,29.3800659,80.3514938,29.0993652,79.5230026 z M23.920105,70.3779831c-0.0725708-0.0249023-0.2773495-0.218811-0.3124447-0.2793579 c-0.1391602-0.2404175-0.2467327-0.521965-0.3620892-0.7744675c-0.0010376-0.0021362-0.0533104-0.3532791-0.0210228-0.353035 c-0.0151863-0.5805893,0.0792847-1.2250977,0.1344604-1.1906128c0.4334831,1.0795593,1.1235352,2.1162109,1.1712036,2.3290405 c0.0003662,0.00177-0.0040283-0.0032349-0.0041504-0.0021973c0.0009766,0.0030518,0.00177,0.0059204,0.0027466,0.0089722 C24.5621338,70.2206955,24.1311035,70.4504929,23.920105,70.3779831z"
      />
      <path
        opacity="0.1"
        fill="#FFF"
        d="M29.0893078,79.5028839c0.0073242-0.0777588,0.0019531-0.1496582,0.0064087-0.2261353 c-0.3869629,0.6766968-0.84729,1.4269409-0.8476562,1.4263916c-0.7399902-1.0348511-1.860014-0.298584-2.6086712-1.3209229 c-0.6970825-0.9519043-3.4599724-4.7071762-3.4599724-4.7071762s-0.7509365-2.0901871-0.5564175-3.8885269 c-0.0496826-0.0233765-0.0955811-0.0537109-0.1447144-0.0782471c-0.8291798,0.4365463-3.1845131-1.9009933-2.8929482-2.8458786 c0.2388306-0.7739868,1.3039417-1.885437,1.9847889-1.9311523c0.1470337-0.1741943,0.5023365-0.4136429,0.6969166-0.3410721 c-0.1963501-0.2703247-0.208313-0.7412109-0.1801758-1.1426392c-0.0344849-0.0966187-0.0205688-0.2770996,0.0509644-0.536377 c0.03302-0.1196899,0.0836792-0.2384033,0.1386719-0.3564453c-0.0842285,0.0631104-0.1558228,0.1015625-0.196228,0.0856323 c-0.0254517-0.0100708-0.173584-0.0769043-0.220459-0.1017456c-0.0147095,0.0119019-0.0413818,0.0403442-0.045105,0.0372925 c-0.0098267-0.0080566-0.5029716-0.747818-0.5127983-0.7559357c-0.0610962-0.0504761,0.6437149-0.3755035,1.317791-0.7509613 c-0.5574341-0.019104-1.1550293,0.0496826-1.5385742,0.3479004c-1.0559692,0.821228-0.4290771,1.3980713-0.269165,2.6588745 c-2.3631592-0.4319458-3.2391968,2.9459839-1.5048828,5.5760498c0.647644,0.9821167,2.1668701,4.2302246,2.9503174,5.0960693 c1.2438965,1.3747559,2.2016602,1.7443848,3.164978,3.3258667c0.0247192,0.0405884,1.7185059,2.0325928,1.8238525,2.0921021 c0.0784912,0.1472778,0.1436768,0.2788086,0.213562,0.4158325c0.2347412,0.0946045,0.4709473,0.1853638,0.7018433,0.2902222 c0.0855103-0.1970825,0.1693726-0.3692017,0.166748-0.371521c0.4852905,0.4396362,0.9475098,0.8370972,1.5150146,1.1112061 c0.020752-0.5187378,0.1005249-1.0317993,0.2041016-1.5432739C28.9947033,80.4287872,29.3700085,80.3313751,29.0893078,79.5028839 z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M26.7261963,69.8460236 c-0.3117676-0.1303101-0.6071777-0.2924805-0.8525391-0.5516357c-0.1798706-0.1900024-0.2422485-0.4187622-0.2434692-0.6569214 c-0.2164307-0.5418701-0.0809937-1.1860962,0.1463623-1.7926636c0.0054932-0.0206909,0.0064697-0.0419312,0.0124512-0.0625 c-0.0102539-0.0449829-0.0048828-0.0949097-0.0251465-0.135437c0.1958618-0.1914673,0.2340698-0.5246582-0.0029907-0.7233887 c-0.0053101,0.0109253-0.0112915,0.0188599-0.0167847,0.0303955c-0.0117188,0.0245361-0.171814-0.0837402-0.3372192-0.2043457 c-0.0116577-0.0029297-0.0235596-0.0026855-0.0352173-0.0053101c0.0662231,0.0540161,0.0256958,0.0259399-0.0596924-0.0089722 c-0.1177979-0.020752-0.2356567-0.0269165-0.3519287-0.0180664c-0.1361694,0.0725098-0.1390991,0.1838379-0.1213989,0.3357544 c-0.001709-0.0147095-0.1952515-0.1260376-0.3445435-0.2130737c-0.2530518,0.1217041-0.4481201,0.3214722-0.5025024,0.5903931 c-0.411438,0.1922607-0.7151489,0.5314941-0.7686157,1.0430298c-0.0327759,0.3135986,0.00354,0.5129395-0.0483398,0.8225098 c-0.0585327,0.3487549-0.0077515,0.7039795,0.0648193,1.0457153c0.0749512,0.3529053,0.1791382,0.6384888,0.3515625,0.8442993 c0.0018311,0.0012207,0.00354,0.0023804,0.0054321,0.0036621c-0.0195923-0.0371094-0.0322876-0.0690308-0.0193481-0.0808716 c0.0187988,0.0058594,0.0375977,0.0116577,0.0563965,0.0175171c0.1398926,0.1045532,0.2706299,0.2203369,0.4070435,0.3291626 c0.0792236,0.0188599,0.1627808,0.0335083,0.2546387,0.0402832c0.1257935-0.0889282,0.190918-0.236084,0.25-0.3708496 c0.013916-0.0317383,0.177124,0.0662231,0.3734741,0.2006836c0.2313232,0.0672607,0.4778442-0.100708,0.5922241-0.281311 c0.0341187-0.053833,0.4395142,0.2280273,0.5535278,0.3084717c0.0025635,0.0004272,0.0050049,0.0007935,0.0076294,0.0011597 c-0.3026123-0.2348022,0.2521362-0.0793457,0.4481812-0.2110596 C26.6395874,70.0624542,26.6879883,69.9626007,26.7261963,69.8460236z"
      />
    </g>
  )
}

export default TurkeyLegR
