import React from "react"

const MaceL = ({
  colors = ["#595A5B", "#B8B9BA", "#777676", "#9A9B9C", "#E0E1E1", "#E4E3E3"]
}) => {
  return (
    <g id="MaceL">
      <path
        opacity="0.53"
        d="M61.4929962,92.2963638l0.5814362-1.0901871 l0.0726852-0.2180481l0.0726776-0.9448318V89.897934l6.6865311-17.0797348 c0.2180481,0.0726776,0.4360733,0.0726776,0.4360733,0.0726776l0.3634033-0.2180328l0.3633957-0.9448395 c0.1453629-0.1453629,0.726799-0.7994766,2.2530746-0.9448395l0.1453552-0.4360733l0.1453629-0.0726776l0,0h0.0726776 l-0.0726776-0.0726852l0,0v-0.0726776c-0.0726852-0.0726776-0.0726852-0.2907181,0.0726776-0.726799 c0.1453552-0.3633957,0.3633957-0.7267914,0.3633957-0.7267914l0,0l0.0726776-0.0726852v-0.2907181l-1.3082352-3.0525436 l-0.2907257-0.2180405c0,0-0.07267,0-0.07267-0.0726776l0.2180328-0.9448395l-0.6541138-0.2907219l-0.5087433,0.8721504l0,0 l-0.4360809-0.07267l-3.1252289,1.2355499l-0.2180405,0.2907181l0,0l0,0l0,0l0,0c0,0-0.0726776,0.4360809-0.2180481,0.7994766 c-0.1453552,0.4360809-0.3633881,0.5814438-0.3633881,0.5814438l0,0v0.07267l0,0h-0.1453629l0.0726776,0.1453629 l-0.0726776,0.0726776l-0.2180405,0.3634033l0.0726776-0.0726776l-0.0726776,0.1453629 c1.0175171,1.0901871,1.0175171,1.9623489,1.0175171,2.1803894l-0.3634033,0.9448318l0.1453629,0.4360809l0,0 c0,0,0.1453552,0.0726776,0.3634033,0.2180405l-6.6865425,17.0797272l-0.6541138,0.8721619l-0.0726776,0.1453629l0,0v0.0726776 l-0.2907219,1.2355499l0.1453629,0.3634033c0.0726738,0.0726776,0.5814362,0.3633957,0.8721504,0.5087585 c0.2907257,0.1453552,0.9448395,0.2180405,1.0175247,0.2180405L61.4929962,92.2963638z"
      />
      <path
        fill={colors[0]}
        d="M67.0893326,66.2043381l3.0525436-1.3082275c0.2180481,0.1453629,0.3634033,0.0726776,0.5087662,0 l0.5814362-0.9448357l-0.2180405,1.0901985c0.0726776,0.2180405,0.1453552,0.2907104,0.4360809,0.3633881l1.3082352,3.0525513 c0,0-0.9448395,1.5262756-0.4360809,2.0350342c-1.8896713,0.1453629-2.5437927,1.0901947-2.5437927,1.0901947 l-0.3633957,1.0175171l-0.6541138-0.0726776l-6.8318977,17.4431381l-0.0726776,1.1628799l-0.5814362,1.0901871 l-0.9448471-0.2180405l-0.8721542-0.5087585l0.2907257-1.2355499l0.726799-0.8721542l6.8318901-17.4431305l-0.5814362-0.4360809 l0.3633957-1.0175247c0,0,0.1453552-1.0901947-1.0901947-2.5437851 C66.7259369,67.9486542,67.0893326,66.2043381,67.0893326,66.2043381z"
      />
      <path
        fill={colors[1]}
        d="M65.9991379,68.0213394c1.2355499,1.3809128,1.0901947,2.5437851,1.0901947,2.5437851 l-0.3633957,1.0175171l0.5814362,0.4360809l-6.8318901,17.4431381l-0.726799,0.8721542l-0.2907257,1.1628723 c0,0,0.5087624,0.3634033,0.7994766,0.4360886c0.2907257,0.1453476,0.9448395,0.2180328,0.9448395,0.2180328 l0.5814438-1.0901947l0.0726776-1.1628799l6.8318901-17.4431305l0.6541214,0.0726776l0.3634033-1.0175171 c0,0,0.6541138-0.9448395,2.5437927-1.0901947c-0.5087585-0.5087662,0.4360733-2.0350342,0.4360733-2.0350342 l-1.3082352-3.0525513c-0.2180405,0-0.3634033-0.1453552-0.4360809-0.3633957l0.2180405-1.0901947l-0.5814362,0.9448318 c-0.1453552,0.0726776-0.3634033,0.1453629-0.5087585,0l-3.0525436,1.3082352 C67.0166626,66.2770233,66.7259369,68.0213394,65.9991379,68.0213394z M69.0516891,71.1465683l1.3809204-1.7443161 l0.5814362-1.5989609l0.2180405-2.0350266l0.726799,2.4711151l-0.5814362,1.5989456l-2.0350418,1.380928L69.0516891,71.1465683z M67.1620178,68.2393799l0.6541138-1.5989532l2.1804047-1.3082428l-1.2355652,1.5989532l-0.6541214,1.5989609 l-0.1453552,2.1803894l-0.2907181-0.1453629L67.1620178,68.2393799z"
      />
      <path
        fill={colors[2]}
        d="M66.7259369,71.5826416c0,0,0.5087509,0.4360733,1.2355499,0.726799 c0.726799,0.2907181,1.3809128,0.3634033,1.3809128,0.3634033l0.3634033-0.9448395c0,0-1.0175171-0.2907257-1.3809128-0.4360809 c-0.3634033-0.1453552-1.3082352-0.5814362-1.3082352-0.5814362L66.7259369,71.5826416z"
      />
      <path
        fill={colors[2]}
        d="M59.8213577,90.2613373c0,0,0,0.2180481,0.8721542,0.5814438 c0.8721619,0.3634033,1.0901985,0.2180405,1.0901985,0.2180405l0.1453552-1.1628799 c0,0-0.3633919,0.0726776-0.7267914-0.0726776s-0.6541252-0.4360733-0.6541252-0.4360733L59.8213577,90.2613373z"
      />
      <g>
        <path
          fill={colors[3]}
          d="M67.2346954,68.3120499l0.5087585,2.2530746l0.2907181,0.1453552l0.1453552-2.0350266 l2.1077118,0.7994766l-1.2355499,1.5989532l0.2907181,0.1453552l1.889679-1.3082352l0.9448395,0.3634033 c0,0.0726852,0.0726776,0.1453629,0.0726776,0.1453629c-1.889679,0.1453552-2.5437927,1.0901947-2.5437927,1.0901947 l-0.3634033,1.0175171l0,0l0.3634033-0.9448395c0,0-1.0175247-0.2907181-1.3809128-0.4360733 c-0.3634033-0.1453629-1.3082352-0.5814438-1.3082352-0.5814438l-0.3634033,0.9448395l0,0l0.3634033-1.0175171 c0,0,0.1453476-1.0901947-1.0902023-2.5437851c0.0726776,0,0.1453552,0,0.2180405-0.0726852L67.2346954,68.3120499z"
        />
        <path
          fill={colors[4]}
          d="M71.9588776,68.1667023L71.9588776,68.1667023l-0.7267914-2.4711151l-0.2180481,2.0350266v0.0726776 l-2.1803894-0.8721542l0.0726776-0.0726776l1.2355499-1.5989532l-2.1803894,1.3082352l0,0l-0.7994766-0.2907257 c0-0.0726776,0-0.0726776,0-0.0726776l3.0525513-1.3082352c0.2180405,0.1453629,0.3633957,0.0726852,0.5087585,0 l0.5814362-0.9448318l-0.2180405,1.0901947c0.0726776,0.2180405,0.1453552,0.2907181,0.4360733,0.3633957l1.3082428,3.0525513 c0,0,0,0-0.0726776,0.0726776L71.9588776,68.1667023z"
        />
        <polygon
          fill={colors[5]}
          points="68.0341721,72.3821182 61.347641,89.7525711 60.9842415,89.6799011 67.743454,72.3821182"
        />
      </g>
    </g>
  )
}

export default MaceL
