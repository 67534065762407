import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FlameRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FlameRight") ? (
    <g id="FlameRight">
      <path d="M45.3710747,49.1393433c0,0-17.8424854,11.1577415-16.9841976,27.7870636 c0.214571,6.4371643-0.6437168,18.5604782,17.1657543,18.6677628 C78.2815628,88.4931793,45.3710747,49.1393433,45.3710747,49.1393433z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FlameRight, {
  character: "WomanDwarf"
})
