import React from "react"

const GreatAxeR = ({ colors = ["#9E9E9E", "#262261"] }) => {
  return (
    <g id="GreatAxeR">
      <path
        fill={colors[0]}
        d="M54.3,21.4c-0.2,0.6-0.7,1.1-1.3,1.3c-2.3,1.2-4.1,1.7-6.6,1.7c-0.3,0.4-1.1,0.8-1.5,1.2 c0-0.2,0-4.1,0-4.4c0,0,0.9-0.1,0.9-0.3c0,0-1.6-0.8-2-5.6c-0.5,4.9-1.9,5.7-1.9,5.7c0,0.2,0.9,0.2,0.9,0.2c0,0.2,0,4.4,0,4.4 s-1-0.6-1.5-1.2c-2.5,0-4.3-0.6-6.6-1.7c-0.5-0.3-1.1-0.7-1.3-1.3c0,0-9.6,13.8,2.6,23.5c-1.7-5.7-2.1-13.7,5-14.4 c0.2,0,1.7-0.1,1.7-0.1l-0.1,5c-0.1,0.2-0.4,0.4-0.8,0.8h0.9v1.5h-1c0,0.1,0,2.1,0,2.2c0.1,0,1.1,0,1.2,0c0,21.3,0,55.5,0,76.8 h-0.1c0.4,1,0.8,1.4,1.2,1.3c0.4,0.2,0.8-0.2,1.2-1.3h-0.1c0-21.3,0-55.5,0-76.8c0.1,0,1.1,0,1.2,0c0-0.1,0-2.1,0-2.2h-1v-1.5 h0.9c-0.2-0.3-0.6-0.5-0.8-0.8L45,30.5c0,0,1.6,0,1.7,0c7,0.7,6.6,8.7,5,14.4C63.8,35.2,54.3,21.4,54.3,21.4z"
      />
      <path
        fill={colors[1]}
        d="M41.2,24.4c-2.4,0-4.1-0.5-6.2-1.5c-2.2,3.8-6,12.4,0.2,18.9c-0.9-5.1-0.2-10.8,5.7-11.3 c0.1,0,1.3,0,1.7-0.1l0.1-4.8C42.4,25.4,41.6,24.8,41.2,24.4z"
        opacity="0.3"
      />
      <path
        fill={colors[1]}
        d="M46.5,24.4c2.4,0,4.1-0.5,6.2-1.5c2.2,3.8,6,12.4-0.2,18.9c0.9-5.1,0.2-10.8-5.7-11.3c-0.1,0-1.4,0-1.8,0 l-0.1-4.8C45.2,25.4,46.1,24.8,46.5,24.4z"
        opacity="0.3"
      />
      <rect
        x="41.6"
        y="39.1"
        opacity="0.3"
        fill={colors[1]}
        width="4.5"
        height="0.8"
      />
      <rect
        x="41.7"
        y="36.1"
        opacity="0.3"
        fill={colors[1]}
        width="4.3"
        height="0.3"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M42.7,21.2c0,0-0.6,0-0.9-0.2c0.6-0.2,2.4-0.3,3.9-0.1 c-0.1,0.1-0.9,0.3-0.9,0.3S43.9,20.8,42.7,21.2z"
      />
      <path
        opacity="0.36"
        fill={colors[1]}
        d="M45.1,61.2v-7.7c0-0.2,0-0.4-0.1-0.6c-0.8,0.1-1.7,0.1-2.5,0.2c0,0.1-0.1,0.3-0.1,0.4v8 c0,0.2,0,0.3,0.1,0.5C43.3,61.9,44.2,61.6,45.1,61.2z"
      />
    </g>
  )
}

export default GreatAxeR
