import React from "react"

const BlueFlameRight = ({
  colors = ["#80D0DC", "#30BFCA", "#5BC6D1", "#A8DDE6", "#C0E6F0", "#E5F5F8"]
}) => {
  return (
    <g id="BlueFlameRight">
      <path
        opacity="0.77"
        fill={colors[0]}
        d="M37.6678963,64.6804657 c-1.4000015-3.1000023-3.0999985-6.1000023-5.2000008-8.9000053c-0.1000023,0-0.1000023,0-0.2000008,0.0999985 c-0.2000008,0.0999985-1.6000004,0.7999992-1.5,0c0.2000008-1.5,0.6000004-3.4000015,0-4.7999992 c-0.3999996-1.0999985-0.6000004-4.2999992-1.3999996-5.2000008c-0.1000004-0.2000008-2-4.9000015-2.5-6.0999985 c-0.7999992,2.5999985,0.5,5.7999992-0.8999996,8.0999985c-0.1000004,0.0999985-2.7000008,5.5999985-2.8999996,5.7999992 c-0.1000004,3.7000008-0.3999996,7.5-1.2000008,11.1000061c0.3999996-0.2000046,1.1000004-0.5,1-0.2000046 c-1.1000004,4.9000015-4.3999996,8.9000015-5.5,13.8000031c0,0.1999969-0.2000008,0.4000015-0.3999996,0.5 c0.3999996,2.9000015,2.2000008,5.5,4.2000008,7.8000031c-1.1000004-2.5999985-1.8999996-5.1999969-1.8999996-8 c0-0.4000015,0.6000004-0.8000031,1.2999992-1.1999969c0.2000008-3.1999969,0.5-6.0999985,2.5-8.9000015 c2.3999996-3.5,1.6000004-7.3000031,2.2000008-10.9000053c-0.1000004,0-0.1000004,0-0.1000004-0.0999985 c0-0.7999992,0.1000004-1.5999985,0.2000008-2.4000015v-0.0999985c0,0,0,0,0-0.0999985 c0.1000004-0.5999985,0.2000008-1.2000008,0.3999996-1.7999992c0-0.5,3.2000008-3.9000015,3.2999992-3.7000008 c0.2000008,0.7999992,0.6000004,1.4000015,0.5,2.0999985c0.1000004,0,0.2000008,0,0.2000008,0.0999985 c0,2.2000008,0.1000004,4.4000015,0.3999996,6.5999985c0.1000004,0,0.1000004,0,0.2000008,0.0999985 c2.2999992,2.5,3,6.1000023,4.0999985,9.2999992c1.2999992,3.9000015,2,6.4000015,2.0999985,10.5 c0,0.1999969-0.0999985,0.3000031-0.2000008,0.5c0.7999992,2.3000031-0.4000015,4.9000015-0.9000015,7.3000031 c-0.0999985,0.3000031-0.2000008,0.6999969-0.2999992,1.0999985c1.0999985-2.1999969,2-4.5,2.9000015-6.9000015 C37.867897,75.0804672,37.7678986,69.8804626,37.6678963,64.6804657z"
      />
      <g opacity="0.98">
        <path
          fill={colors[1]}
          d="M29.6678963,89.7804642c-1.8999996-0.5999985-3.6000004-1.3000031-5.2000008-2.1999969 c-0.3999996-0.6999969-0.7000008-1.5-1.1000004-2.1999969c-0.8999996-1.9000015-1.6000004-3.9000015-2.2999992-6.0999985 c0.1000004-0.6999969,0.2000008-1.4000015,0.3999996-2c0.2000008-0.8000031,0.5-1.6999969,0.7000008-2.5 c0.3999996-1.3000031,0.7000008-2.5999985,1-3.9000015l1-0.4000015l-0.5-1c0-0.0999985-0.1000004-0.0999985-0.1000004-0.2000046 c2.1000004-2.5,3.2999992-5,3.7000008-7.4000015c0.1000004-0.9000015,0.1000004-1.7000008,0-2.5999985l0.6000004-0.2999992 c0.2000008,0.5999985,0.3999996,1.0999985,0.6000004,1.5c0.7000008,1.7999992,1.6000004,3.5,2.3999996,5.1000023 c1,2,2,3.7999954,2.7000008,5.8000031c1.7000008,5,1.5,10.3000031-0.7000008,16.9000015 C31.867897,88.9804688,30.7678967,89.4804688,29.6678963,89.7804642z M27.2678967,57.4804649 c-0.7000008-0.7000008-1.1000004-1.7000008-1.1000004-2.7000008c0.1000004-0.7999992,0.3999996-1.5999985,1-2.0999985 C26.7678967,54.3804626,26.9678974,56.0804634,27.2678967,57.4804649L27.2678967,57.4804649z"
        />
        <path
          fill={colors[2]}
          d="M29.5678978,89.9804688c-1.6000004-0.5-3-1.0999985-4.3999996-1.8000031 c-0.2999992-0.5999985-0.6000004-1.3000031-0.8999996-1.9000015c-0.7999992-1.8000031-1.5-3.6999969-2.2000008-5.6999969 c0.1000004-0.5999985,0.2000008-1.0999985,0.2999992-1.5999985c0.2000008-0.8000031,0.3999996-1.5999985,0.7000008-2.5 c0.2999992-1.0999985,0.6000004-2.1999969,0.8999996-3.4000015l1.5-0.6999969l-0.7999992-1.5999985 c1.7999992-2.3000031,2.8999996-4.5,3.2999992-6.7000046c0.5,1.2000046,1.1000004,2.3000031,1.7000008,3.4000015 c1,1.9000015,2,3.7000046,2.6000004,5.7000046c1.5999985,4.6999969,1.4000015,9.6999969-0.6000004,15.8000031 C31.1678963,89.3804703,30.367897,89.6804657,29.5678978,89.9804688z"
        />
        <path
          fill={colors[0]}
          d="M29.4678974,89.9804688c-1.2999992-0.4000015-2.5-0.9000015-3.6000004-1.5 c-0.2000008-0.5-0.5-1-0.7000008-1.5999985c-0.7999992-1.6999969-1.3999996-3.4000015-2.1000004-5.4000015 c0.1000004-0.5,0.2000008-0.9000015,0.2999992-1.3000031c0.2000008-0.8000031,0.3999996-1.5999985,0.7000008-2.4000015 c0.2999992-0.9000015,0.5-1.9000015,0.7999992-2.9000015l2.1000004-0.9000015l-1-2c1-1.3000031,1.7999992-2.5999985,2.2999992-4 c0.2000008,0.2999954,0.2999992,0.6999969,0.5,1c1,1.9000015,1.8999996,3.6999969,2.6000004,5.5 c1.4999981,4.4000015,1.2999973,9.0999985-0.3999996,14.8000031 C30.367897,89.5804672,29.9678974,89.7804642,29.4678974,89.9804688z"
        />
        <path
          fill={colors[3]}
          d="M29.367897,90.0804672c-1-0.3000031-1.8999996-0.6999969-2.7000008-1.0999985 c-0.2000008-0.4000015-0.3999996-0.8000031-0.6000004-1.1999969c-0.7000008-1.5999985-1.2999992-3.1999969-1.8999996-5 c0.1000004-0.3000031,0.1000004-0.5999985,0.2000008-0.9000015c0.2000008-0.8000031,0.3999996-1.5999985,0.7000008-2.4000015 c0.2000008-0.8000031,0.5-1.5999985,0.7000008-2.4000015l2.7000008-1.1999969l-1.2999992-2.4000015 c0.3999996-0.5999985,0.7999992-1.0999985,1.1000004-1.6999969c0.7999992,1.5,1.5,3,2,4.5 c1.3999996,4,1.2999992,8.4000015-0.2999992,13.6999969C29.6678963,89.8804703,29.5678978,89.9804688,29.367897,90.0804672z"
        />
        <path
          fill={colors[4]}
          d="M28.9678974,89.6804657c-0.6000004-0.1999969-1.1000004-0.4000015-1.6000004-0.6999969 c-0.1000004-0.3000031-0.2999992-0.5999985-0.3999996-0.8000031c-0.7000008-1.5-1.2999992-3-1.7999992-4.5999985 c0-0.1999969,0.1000004-0.4000015,0.1000004-0.5999985c0.2000008-0.8000031,0.3999996-1.5999985,0.7000008-2.4000015 c0.2000008-0.5999985,0.3999996-1.3000031,0.6000004-2l2.6000004-1.0999985 C30.367897,81.0804672,30.2678967,84.9804688,28.9678974,89.6804657z"
        />
        <path
          fill={colors[5]}
          d="M29.1678963,90.1804657c-0.1000004-0.1999969-0.1000004-0.3000031-0.2000008-0.5 c-0.6000004-1.4000015-1.2000008-2.6999969-1.7000008-4.1999969c0-0.0999985,0-0.1999969,0.1000004-0.3000031 c0.2000008-0.8000031,0.3999996-1.5999985,0.6000004-2.3000031c0.1000004-0.5,0.2999992-1,0.3999996-1.5l1-0.4000015 C30.1678963,83.6804657,30.1678963,86.6804657,29.1678963,90.1804657 C29.2678967,90.1804657,29.2678967,90.1804657,29.1678963,90.1804657z"
        />
      </g>
    </g>
  )
}

export default BlueFlameRight
