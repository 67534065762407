import React from "react"

const LeatherTard = ({ colors = ["#754C28", "#262261"] }) => {
  return (
    <g id="LeatherTard">
      <g id="C_x24_leatherTard">
        <path
          fill={colors[0]}
          d="M80.1762924,58.8408585c-1.1614151-6.2628365-2.9349442-5.1375809-3.2604218-7.3296356 c-0.3301468-2.2236481-0.3007584-4.2310448-0.3164978-4.9947739c0.2833557-2.2311134,1.4121552-5.4248543,1.4520187-6.7996178 c-3.1755295-7.0829773-9.1370087-0.9943771-9.1370087-0.9943771h-0.3289642c0,0-5.961483-6.0886002-9.1370125,0.9943771 c0.039814,1.3747635,1.1686668,4.5685043,1.4519691,6.7996178c-0.015686,0.7637291,0.0137024,2.7711258-0.3164482,4.9947739 c-0.3254776,2.1920547-2.0990028,1.0667992-3.2604179,7.3296356c3.5053024,0.1449013,7.5285873,4.0721207,9.9976578,6.778183 c0.0718307,0.1356049,0.1493607,0.252243,0.2356491,0.3386383c0.4367981,0.0870361,0.7759781,0.1252365,1.0770569,0.1323853 c0.0376663,0.001236,0.0773697,0.0007477,0.1160507,0.0009155c0.0386887-0.0001678,0.0783386,0.0003204,0.1159973-0.0009155 c0.3010864-0.0071487,0.6402664-0.0453491,1.0770645-0.1323853c0.0862885-0.0863953,0.1638107-0.2030334,0.2356491-0.3386383 C72.6476517,62.9129791,76.6709366,58.9857597,80.1762924,58.8408585z"
        />
        <path
          opacity="0.3"
          fill="#21376C"
          d="M77.2604752,43.4707794 c-5.3712845,4.285202-8.5068436-0.2378998-8.5068436-0.2378998s-3.143074,4.5231018-8.5143089,0.2378998 c0,0,1.1332588,4.7220039,0.4249763,8.6412163c5.4775085,1.133316,7.9831161,8.1663246,8.0893326,7.8961868 c0.0030136-0.0076294,0.0057526-0.0321312,0.0084381-0.0649033c0.1570969,0.1115913,2.656044-6.710434,8.0734329-7.8312836 C76.1271667,48.1927834,77.2604752,43.4707794,77.2604752,43.4707794z"
        />
        <path
          opacity="0.15"
          fill={colors[1]}
          d="M65.8279114,42.6056099 c-2.2910995,0.6910248-4.7086487,0.2741699-5.3996735-2.0169258c-0.0501099-0.1661835-0.0678711-0.3330574-0.0974503-0.4998169 c-0.1191902,0.6538315-0.1076546,1.3401299,0.0974503,2.0201416c0.6910248,2.2911568,3.1085739,3.5882683,5.3996735,2.8971863 c2.1249161-0.6409187,3.3728409-2.7668686,2.9945755-4.8997993 C68.5401154,41.6554947,67.4389954,42.1196289,65.8279114,42.6056099z"
        />
        <path
          opacity="0.15"
          fill={colors[1]}
          d="M71.6718674,42.6056099 c2.2910995,0.6910248,4.7086563,0.2741699,5.3996811-2.0169258c0.0501022-0.1661835,0.0678635-0.3330574,0.0974503-0.4998169 c0.1191864,0.6538315,0.1076508,1.3401299-0.0974503,2.0201416c-0.6910248,2.2911568-3.1085815,3.5882683-5.3996811,2.8971863 c-2.1249084-0.6409187-3.3728409-2.7668686-2.9945755-4.8997993 C68.9596634,41.6554947,70.0607834,42.1196289,71.6718674,42.6056099z"
        />
      </g>
    </g>
  )
}

export default LeatherTard
