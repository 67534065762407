import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongBowLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongBowLeft") ? (
    <g id="LongBowLeft">
      <path d="M78.898201,107.4062805c-0.8178024,0.5402985-1.6142502,0.9761353-2.5567398,1.327713l-0.58638,0.2066803 c-0.9424896,0.3515778-2.7229843,1.076088-3.7283783,1.4692307l-0.1055984,0.2504959l-0.6504211,0.5200882l-0.2516327,0.1662445 c-2.7050247,1.7871552-5.5459137,1.0434418-7.1499939-0.4270325l-1.5893936-5.0044861l0.0640373-0.3134079l1.1391144-2.3791885 c0.4414825-0.5627823,19.4460945-49.8979416,19.4460945-49.8979416l0.6167679-2.48592l0.1685104-0.2920609l4.5068741-2.6161308 c2.7813644-0.030262,5.0942612,1.9659538,5.6468658,4.8540535l0.1212997,1.004261l-0.0842514,0.1460304 c0.4560471,0.963829,1.306366,2.6612053,1.7208557,3.5621262l0.2695923,0.5448265 c4.2077789,8.967659-1.6472626,18.9809036-3.5850983,22.3396149c-0.5055237,0.8761826-0.7796249,1.4187546-0.9481354,1.7108154 l-0.1280823,0.6268158l-1.7547531,4.5932617l-0.0842514,0.1460342l-0.1056061,0.2505035l-0.2314148,0.3336182 c-0.1055984,0.2505035-0.2336731,0.8773193-0.4471283,1.9220123 C87.9705811,93.0986176,85.8809433,102.792923,78.898201,107.4062805z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongBowLeft, {
  character: "WomanGnome"
})
