import React from "react"

const SideBodyBag = ({ colors = ["#724B2B", "#FFFFFF"] }) => {
  return (
    <g id="SideBodyBag">
      <path
        opacity="0.3"
        fill="#21376C"
        d="M79.5,26.2c-0.1-0.1-9,17.2-9.1,17.1l-0.6,0.2 c-2.7,5-12.1,7.8-12.1,7.8c-5.1,4.2-6.9,12-3.1,17.3c2.1,2.9,8.9-1.6,13.6-8.3c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.9,0.7-1.8,1.1-2.7 c1.5-3.7,2.4-7.7,4.2-11.3c1-2.4,8-14,9.4-17.8C83.3,27.3,79.6,26.3,79.5,26.2z"
      />
      <path
        fill={colors[0]}
        d="M79.3,25.5c-0.1-0.1-9,17.2-9.1,17.1l-0.6,0.2c-2.7,5-12.9,7.7-12.9,7.7c-5.1,4.2-6.1,12.1-2.3,17.4 c1.7,2.3,6.4-0.1,10.6-4.6c1.1-2,2.3-4,3.3-6.1c1.6-3.1,2.6-6.7,4.5-9.7c0.2-0.4,0.3-0.7,0.5-1.1c0-0.4,0-0.6-0.1-0.8 c-0.3-0.5,8-14.1,9.5-18.2C83.2,26.6,79.4,25.6,79.3,25.5z"
      />
      <path
        opacity="0.64"
        fill="#21376C"
        d="M52.5,57.5c-0.1,0.2,1.8,3.9,1.8,3.9s14-14.2,14.3-15.2 c0.3-1,1-1.8,1-1.8s0.2,1.2,0,1.4S64,52.3,64,52.3l-9.5,10.1l-1.9-4.1L52.5,57.5z"
      />
      <path
        opacity="0.64"
        fill="#21376C"
        d="M54.3,59c0,0-0.8,1.4-0.8,3.5c0.1,2.1-1.1,3.5-1.1,3.5v1.3 l1.6-1.1c0,0,1-7.4,1.6-7.4S54.3,59,54.3,59z"
      />
      <path
        fill={colors[0]}
        d="M53.9,59c0,0-0.8,1.4-0.8,3.5c0.1,2.1-1.1,3.5-1.1,3.5v1.3l1.6-1.1c0,0,1-7.4,1.6-7.4S53.9,59,53.9,59z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M82,26.8c-1.4,3.4-6.2,13.3-8.1,16.4c-1.3,4.7-3.7,9.4-6.5,13 c-2.8,3.6-6.6,9.2-11.3,10.2c-0.5,0.1-0.9-0.2-1-0.6c0,0,0,0,0-0.1c0,0,0,0,0,0c-0.1-0.2-0.1-0.5-0.1-0.5 c-0.1-0.3-0.3-1.2-0.3-1.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.3-0.2,1.6-0.5,1.7c-0.1,0.2-0.2,0.4-0.2,0.7c0.3,0.5,0.6,1,0.9,1.5 c1.6,2.3,6.2,0,10.3-4.3c1.2-2.2,2.5-4.4,3.7-6.7c1.6-3.1,2.6-6.5,4.4-9.4c0.2-0.4,0.4-0.8,0.5-1.2c0,0,0-0.1,0.1-0.1 c0-0.2,0-0.4-0.1-0.5c-0.2-0.4,6.8-12.2,9.5-18.2C83,27.3,82.3,26.8,82,26.8z"
      />
      <path fill="#21376C" d="M54.5,57.4L56,57C56,57,55.4,56.3,54.5,57.4z" />
      <path fill="#21376C" d="M53.9,59l3-0.5C56.9,58.4,54.7,58.2,53.9,59z" />
      <polygon
        fill="#21376C"
        points="56.9,58.4 57.1,56.7 57.5,56.8 57.3,58.1"
      />
      <g opacity="0.54" fill="#FFF">
        <path
          opacity="0.23"
          d="M65.3,48.1c1-0.9,3.8-4.2,4.4-5.4c-0.7,0.9-2.3,3.3-4,3.9c-0.4,0.6-1.9,1.6-2.3,2.2 C63.7,48.8,65,48.2,65.3,48.1z"
        />
        <path
          opacity="0.23"
          d="M61.6,49.5c0.2-0.4,3.1-2.1,3.3-2.4c-2.9,1.3-8.2,3.4-8.2,3.4c-0.6,0.5-1.1,1-1.6,1.6 C57.3,51.1,59.5,50.2,61.6,49.5z"
        />
        <path
          opacity="0.23"
          d="M77.9,28.4c-0.1,0.3-0.3,0.6-0.5,0.9c1,0,1.9,0,2.9,0c0.1-0.2,0.2-0.4,0.3-0.6 C79.7,28.6,78.8,28.5,77.9,28.4z"
        />
        <path
          opacity="0.23"
          d="M78.7,26.6c-0.2,0.4-0.4,0.8-0.7,1.3c0.9,0,1.9,0.4,2.8,0.4c0.2-0.4,0.7-1.2,0.9-1.5 C80.9,26.7,79.6,26.7,78.7,26.6z"
        />
        <path
          opacity="0.23"
          d="M75.5,32.6c-0.4,0.7-0.7,1.4-1.1,2.1c0.8,0.2,2.3,0.9,3.1,1c0.3-0.4,1.3-2.3,1.4-2.5 C78.5,33.3,75.8,32.9,75.5,32.6z"
        />
        <path
          opacity="0.23"
          d="M74.2,35.2c-0.4,0.8-0.9,1.6-1.3,2.4c0.8,0.1,2.5,0.5,3.3,0.6c0.4-0.7,0.8-1.5,1.2-2.2 C76.7,35.9,75,35.4,74.2,35.2z"
        />
        <path
          opacity="0.23"
          d="M72.5,38.4c-0.2,0.5-0.5,0.9-0.7,1.3c0.6,0.2,2.4,1.1,3,1.3c0.4-0.6,0.8-1.4,1.2-2 C75.2,39,73.3,38.5,72.5,38.4z"
        />
        <path
          opacity="0.23"
          d="M53.4,60.6c-0.3-0.6-0.7-1.3-1-1.9c0,0-0.1,0-0.1,0c-0.3,1.9-0.1,3.9,0.5,5.7 c0.3-1.2,0.6-2.4,0.8-3.6C53.5,60.8,53.5,60.7,53.4,60.6z"
        />
        <path
          opacity="0.23"
          d="M54.2,57.7c0,0.4,0,0.1,0.1,0.5c0,0.1,0,0.1,0.1,0.2c0.2-0.5,0.6-0.6,1.1-0.5 c0,0,1.2-0.3,1.2-0.3C57.5,56.9,55.2,56.9,54.2,57.7z"
        />
        <path
          opacity="0.23"
          d="M63.7,55.3c0.5,0.2,1.4,0.2,2.2,0.2C66.2,55.1,63.9,55.1,63.7,55.3z"
        />
        <path
          opacity="0.23"
          d="M62,56.9c-1.8,1.8-5.4,4.4-6.9,5.2c-0.1,0.5-0.2,1.6-0.3,2.1c0.3,0,0.5,0.1,0.7,0.4 c1,2.3,6.2-3.9,8.5-6.8C63.3,57.6,62.6,57.2,62,56.9z"
        />
        <path
          opacity="0.23"
          d="M64.1,57.7c0.5-0.6,0.8-1,0.9-1.2c0.1-0.1,0.2-0.2,0.2-0.3c-0.7-0.1-1.3-0.3-1.9-0.6 c-0.4,0.3-0.7,0.7-1.1,1.1C62.8,57,63.5,57.4,64.1,57.7z"
        />
      </g>
    </g>
  )
}

export default SideBodyBag
