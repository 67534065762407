import React from "react"

const LuteL = ({
  colors = ["#94712B", "#D9A53F", "#695020", "#B68B34", "#8E6D2B"]
}) => {
  return (
    <g id="LuteL">
      <path
        fill={colors[0]}
        d="M101.9,45.4l-0.9,0.1l-0.1,0.4l-0.5,0.1l-0.4-0.7l0-0.6l0.7,0.1l0.2,0.3l1,0.1 C102,45.2,102.1,45.4,101.9,45.4z"
      />
      <path
        fill={colors[0]}
        d="M102,44.5l-0.9,0.1l-0.1,0.2l-0.4-0.2l-0.4-0.4l-0.1-0.5l0.5,0.2l0.4,0.3l1,0.1 C102.1,44.4,102.2,44.5,102,44.5z"
      />
      <path
        fill={colors[0]}
        d="M102.1,43.8l-0.8,0l-0.1,0.2l-0.5-0.1l-0.4-0.4l-0.1-0.5l0.5,0.2l0.4,0.3l1,0.1 C102.2,43.6,102.3,43.8,102.1,43.8z"
      />
      <path
        fill={colors[0]}
        d="M101.7,46.6l-0.9,0.1l-0.1,0.2l-0.5,0l-0.4-0.6l-0.1-0.5l0.5,0.2l0.4,0.3l1,0.1 C101.8,46.4,101.9,46.6,101.7,46.6z"
      />
      <path
        fill={colors[1]}
        d="M94.2,60c-5.3-0.2-15.8,14.4-16.7,17.5c-0.9,3.2-1.2,6.8,3.2,9.1c4.4,2.3,10.4-2,12.1-4.5 C94.6,79.6,97.8,60.6,94.2,60z"
      />
      <path
        fill={colors[2]}
        d="M104,45.3l-0.1-1l-8.6,17c0,0,3.1,28-14.6,25.4c0,0,11.3,6.6,16.2-8.7c1.2-7.2-0.4-15.8-0.4-15.8"
      />
      <path
        fill={colors[0]}
        d="M103.6,48.2l0.8,0.3l0,0.2l0.4,0.2l0.5-0.3l0.2-0.4l-0.7,0l-0.3,0l-1-0.1C103.6,48,103.4,48.1,103.6,48.2z"
      />
      <path
        fill={colors[0]}
        d="M103.8,46l0.8,0.3l0,0.4l0.4,0.2l0.5-0.5l0.2-0.6l-0.7,0l-0.3,0.2l-1-0.1C103.8,45.8,103.7,45.9,103.8,46z"
      />
      <path
        fill={colors[0]}
        d="M104,45.1l0.8,0.3l0,0.3l0.4-0.1l0.5-0.3l0.2-0.4l-0.6,0l-0.5,0.2l-1-0.1C103.9,44.9,103.8,45.1,104,45.1z"
      />
      <path
        fill={colors[0]}
        d="M104.1,44.4l0.8,0.2l0,0.2l0.5,0.1l0.5-0.3l0.2-0.4l-0.6,0l-0.5,0.2l-1-0.1 C104.1,44.2,103.9,44.3,104.1,44.4z"
      />
      <path
        fill={colors[0]}
        d="M103.8,47.2l0.8,0.3l0,0.3l0.5,0.1l0.5-0.5l0.2-0.4l-0.6,0l-0.5,0.2l-1-0.1 C103.7,47,103.6,47.2,103.8,47.2z"
      />
      <polygon
        fill={colors[3]}
        points="92.3,60.5 101.8,43.3 104.2,43.8 95.3,61.3"
      />
      <polygon fill={colors[4]} points="79,79.3 84.4,82 84.8,80.8 79.6,78.2" />
      <polygon
        fill={colors[0]}
        points="103.9,44.5 104,45.3 103.3,48.7 102.4,48.5"
      />
      <polygon
        fill={colors[2]}
        points="103.7,48.2 103.3,48.7 103.9,44.2 104.2,43.8"
      />
      <ellipse
        transform="rotate(-58.149 88.076 69.825)"
        fill={colors[0]}
        cx="88.1"
        cy="69.8"
        rx="2.6"
        ry="1.8"
      />
      <line fill={colors[2]} x1="80.9" y1="79.4" x2="102.1" y2="43.5" />
      <line fill={colors[2]} x1="81.5" y1="79.6" x2="102.7" y2="43.5" />
      <line fill={colors[2]} x1="82" y1="79.7" x2="103.2" y2="43.7" />
      <line fill={colors[2]} x1="82.5" y1="79.9" x2="103.7" y2="43.7" />
    </g>
  )
}

export default LuteL
