import React from "react"

const Underwear = ({ colors = ["#8CD3D8"] }) => {
  return (
    <g id="Underwear">
      <path
        fill={colors[0]}
        d="M73.3142624,71.9330673c0.0001221-0.0009155,0.0002441-0.0020142,0.0004883-0.0029297 c0.4515381-0.3516846,0.7480469-0.9124756,0.7480469-1.5500488c0-0.2219238-0.0427246-0.4316406-0.1080322-0.6300659 c0.0841064-0.185791,0.164917-0.331604,0.2437744-0.4623413c-0.407959-0.366333-1.3305664-1.0707397-2.4033203-1.0707397 c-1.4482422,0-3.4196777,1.8390503-3.4196777,1.8390503l-0.0168457,0.581604h-0.1540527l-0.0168457-0.581604 c0,0-1.9714355-1.8390503-3.4196777-1.8390503c-1.0727539,0-1.9953613,0.7044067-2.4033203,1.0707397 c0.0788574,0.1307373,0.159668,0.2765503,0.2437744,0.4623413c-0.0653076,0.1984253-0.1080322,0.4081421-0.1080322,0.6300659 c0,0.6375732,0.2965088,1.1983643,0.7480469,1.5500488c0.0002441,0.0009155,0.0003662,0.0020142,0.0004883,0.0029297 c0.5527344,0.0358887,1.4450684,0.0955811,1.5490723,0.1137695c0.4389648-0.0258789,3.423584-0.8192139,3.423584-0.8192139 h0.119873c0,0,2.9846191,0.793335,3.4238281,0.8192139C71.8694382,72.0286484,72.761528,71.968956,73.3142624,71.9330673z"
      />
      <path
        fill={colors[0]}
        d="M75.1451416,80.5394135c-1.4020996,0.2501221-4.1000977,0.6590576-6.777832,0.6624756 c-2.8800049,0.0036621-5.7258301-0.4923706-6.907959-0.729187c-0.435791,0.7626343-0.7684326,1.4191895-0.7655029,1.6087646 c1.2913818,0.6461792,5.9307861,3.0142822,6.6783447,3.3959351l0.0480957-0.1611328h1.7260742l0.0438232,0.1467285 l6.6812744-3.3917847C75.8651123,81.8770599,75.5560303,81.262558,75.1451416,80.5394135z"
      />
    </g>
  )
}

export default Underwear
