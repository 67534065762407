import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import BeardTwo from "./BeardTwo"
import BeardThree from "./BeardThree"
import BeardFour from "./BeardFour"
import BeardOne from "./BeardOne"

const CATEGORY = "beard"
const CHARACTER = "ManDwarf"
export const IDS = {
  BEARD_TWO: "BeardTwo",
  BEARD_THREE: "BeardThree",
  BEARD_FOUR: "BeardFour",
  BEARD_ONE: "BeardOne"
}

export const components = {
  [IDS.BEARD_TWO]: BeardTwo,
  [IDS.BEARD_THREE]: BeardThree,
  [IDS.BEARD_FOUR]: BeardFour,
  [IDS.BEARD_ONE]: BeardOne
}

export const Group = ({ props }) => (
  <g id="beard">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.BEARD_TWO,
    name: "Beard Two",
    defaultColors: ["#A96F6F", "#40393B", "#D9A53F"],
    colorable: true,
    component: components[IDS.BEARD_TWO],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BEARD_THREE,
    name: "Beard Three",
    defaultColors: ["#A96F6F", "#B9B8A9", "#DECCC2"],
    colorable: true,
    component: components[IDS.BEARD_THREE],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BEARD_FOUR,
    name: "Beard Four",
    defaultColors: [
      "#A96F6F",
      "#EAD8CC",
      "#4C77BB",
      "#00618B",
      "#3953A4",
      "#104B9E"
    ],
    colorable: true,
    component: components[IDS.BEARD_FOUR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BEARD_ONE,
    name: "Beard One",
    defaultColors: ["#AF6C29", "#A96F6F", "#55576E"],
    colorable: true,
    component: components[IDS.BEARD_ONE],
    inUI: true,
    enabled: true,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
