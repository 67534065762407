import React from "react"

const BehindRobe = ({ colors = ["#3A0806"] }) => {
  return (
    <g id="BehindRobe">
      <polygon
        fill={colors[0]}
        points="61.5459328,55.2728958 76.0203018,54.8769493 83.9825134,82.6870651 88.3090363,117.8438721 49.1463242,117.8438721 53.2115555,82.2445145"
      />
    </g>
  )
}

export default BehindRobe
