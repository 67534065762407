import React from "react"

const FlameRight = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameRight">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M27.4,83.7c-1.8-0.6-3.5-1.2-5-1.9c-0.3-0.5-0.7-1-1-1.5c-0.8-1.3-1.5-2.6-2.1-4c0.1-0.4,0.3-0.8,0.4-1.2 c0.2-0.5,0.5-1,0.7-1.5c0.4-0.7,0.8-1.5,1.1-2.3l1-0.2L22,70.4l-0.1-0.1c2.1-1.3,3.4-2.7,3.8-4.2c0.1-0.5,0.2-1.1,0.1-1.6 l0.6-0.1c0.2,0.4,0.4,0.7,0.5,1c0.6,1.2,1.5,2.3,2.3,3.4c1,1.3,1.9,2.6,2.5,3.9c1.5,3.3,1.2,6.5-1,10.3 C29.6,83.5,28.5,83.7,27.4,83.7z"
        />
        <path
          fill={colors[1]}
          d="M27.3,83.8c-1.5-0.5-3-1-4.3-1.6c-0.3-0.4-0.6-0.8-0.8-1.3c-0.8-1.2-1.4-2.4-2-3.8c0.1-0.4,0.2-0.7,0.4-1 c0.2-0.5,0.5-1,0.7-1.4c0.3-0.6,0.7-1.3,1-2l1.5-0.2L23,71.4c1.8-1.2,2.9-2.5,3.4-3.8c0.5,0.8,1.1,1.5,1.6,2.3 c0.9,1.3,1.8,2.5,2.4,3.8c1.5,3.1,1.2,6.1-0.9,9.7C28.8,83.6,28.1,83.8,27.3,83.8z"
        />
        <path
          fill={colors[2]}
          d="M27.2,83.9c-1.2-0.4-2.4-0.8-3.5-1.3c-0.2-0.3-0.5-0.7-0.7-1c-0.7-1.1-1.3-2.3-1.9-3.5 c0.1-0.3,0.2-0.5,0.3-0.8c0.2-0.5,0.5-1,0.7-1.4c0.3-0.5,0.6-1.1,0.8-1.7l2.1-0.3l-1-1.4c1-0.7,1.8-1.4,2.4-2.2 c0.2,0.2,0.3,0.4,0.5,0.7c0.9,1.3,1.8,2.5,2.4,3.7c1.4,2.8,1.1,5.7-0.8,9.1C28.1,83.7,27.7,83.8,27.2,83.9z"
        />
        <path
          fill={colors[3]}
          d="M27.1,83.9c-0.9-0.3-1.8-0.6-2.7-1c-0.2-0.3-0.3-0.5-0.5-0.8c-0.7-1.1-1.2-2.1-1.8-3.3 c0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.5,0.5-0.9,0.7-1.4s0.5-0.9,0.7-1.4l2.6-0.4l-1.2-1.6c0.4-0.3,0.8-0.6,1.1-0.9c0.7,1,1.4,2,1.9,3 c1.3,2.6,1,5.3-0.6,8.4C27.4,83.8,27.3,83.9,27.1,83.9z"
        />
        <path
          fill={colors[4]}
          d="M26.8,83.6c-0.5-0.2-1.1-0.4-1.6-0.6c-0.1-0.2-0.2-0.4-0.4-0.6c-0.6-1-1.2-2-1.7-3c0-0.1,0.1-0.2,0.2-0.4 c0.2-0.5,0.5-0.9,0.7-1.4c0.2-0.4,0.4-0.8,0.6-1.2l2.6-0.4C28.3,78.4,28.2,80.9,26.8,83.6z"
        />
        <path
          fill={colors[5]}
          d="M27,83.9c-0.1-0.1-0.1-0.2-0.2-0.3c-0.6-0.9-1.1-1.8-1.5-2.8c0-0.1,0-0.1,0.1-0.2 c0.2-0.5,0.5-0.9,0.7-1.4c0.1-0.3,0.3-0.6,0.4-0.9l1-0.2C28.1,80,27.9,81.9,27,83.9C27,84,27,83.9,27,83.9z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M35.4,65.6c-1.4-2-1.6-3.9-3.6-5.6c-0.1,0-1-0.7-1.1-0.7c-0.2,0.1-1.4,0.2-1.4-0.2c0.2-1,1.8-1.3,1.3-2.2 c-0.4-0.7-2.2-5-2.7-5.8c-0.8,1.6-3.3,5.7-4.7,7.1c-0.1,0.1-2.1,5.2-2.8,7.5c0.4-0.1,1.1-0.3,1-0.1c-1.1,3.1-4.1,5.6-5.2,8.7 c0,0.1-0.2,0.2-0.4,0.3c0.4,1.9,2.1,3.5,4,4.9c-1-1.6-1.8-3.3-1.8-5.1c0-0.2,0.6-0.5,1.2-0.7c0.2-2,0.5-3.8,2.3-5.6 c2.3-2.2,3.4-5.7,3.4-5.7c0.1-0.4,2.7-4.5,2.1-6.3c-0.2-0.5,0.5,0.9,0.5,1.3c0.1,0,0.2,0,0.2,0.1c0,1.4,1,6.1,1.1,6.2 c2.2,1.6,2.3,1.9,3.3,3.9c1.2,2.5,1.9,4.1,2,6.7c0,0.1-0.1,0.2-0.2,0.3c0.8,1.5-0.4,3.1-0.8,4.6c-0.1,0.2-0.1,0.4-0.3,0.7 c1-1.4,1.9-2.9,2.7-4.4C35.5,72.2,35.5,68.9,35.4,65.6z"
      />
    </g>
  )
}

export default FlameRight
