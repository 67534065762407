import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { apiRoutine } from '../routines'
import { apiRequestAction } from '../reducers/apiReducer'
import MinisGrid from './MinisGrid'

class UserMinis extends React.Component {
  componentDidMount() {
    this.props.apiRoutine(apiRequestAction('minis.sheet.index', 'userSheets'))
  }

  inNewWindow = (image) => {
    const n = window.open()
    n.document.write(`<img width="100%" src="${image}"/>`)
  }

  render() {
    const minis = this.props.thumbnails.map(sheet =>
      <div key={sheet.id}>
        <div className="pawn-container">
          <div className="print-queue-controls">
            <a href={sheet.file.default}
              download={sheet.name}
              className="icon has-text-info">
              <i className="fas fa-cloud-download-alt"></i>
            </a>
            <a href="#"
              onClick={() => this.inNewWindow(sheet.file.default)}
              className="icon has-text-info">
              <i className="fas fa-external-link-alt"></i>
            </a>
          </div>
        </div>
        <img width="200px" src={sheet.file.thumb}/>
      </div>
    )

    return (
      <div>
        { minis.length ? <MinisGrid items={minis} /> : <div>You haven't created any print sheets yet!</div> }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  thumbnails: get(state, 'apiReducer.data.userSheets') || [],
  images: get(state, 'apiReducer.data.userSheets.images') || []
})

const mapDispatchToProps = {
  apiRoutine,
}


export default connect(mapStateToProps, mapDispatchToProps)(UserMinis)
