import React from "react"
import { withSkinColor } from "../../builderConnector"

const Hands = ({ skinColor, colors = ["#E8B180"] }) => {
  return (
    <g id="Hands">
      <path
        fill={skinColor}
        d="M46.9000015,83.0999985c-1.7000008-1.1999969-5,1-5,1l-1.5999985,3.3000031l5.2000008,3.6999969 c0,0,2.4000015-4.5,3-5.5c0.2999992-1,0.0999985-1.0999985,0.0999985-1.0999985S48.5,84.4000015,46.9000015,83.0999985z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M43.5,85l-3.2999992,2.4000015L45,91.8000031L47.4000015,87.5 c0,0,0.7000008-1.1999969,1.0999985-1.9000015c-0.0999985,0.1999969-1.2000008,2-3,0.3000031 C43.7999992,85.3000031,43.5,85,43.5,85z"
      />
      <path
        fill={skinColor}
        d="M90.0999985,83.0999985c1.6999969-1.1999969,5,1,5,1l1.5999985,3.3000031L91.5,91.0999985 c0,0-2.4000015-4.5-3-5.5c-0.3000031-1-0.0999985-1.0999985-0.0999985-1.0999985S88.5,84.4000015,90.0999985,83.0999985z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M93.5,85l3.3000031,2.4000015L92,90.8000031L89.5999985,87.5 c0,0-0.6999969-1.1999969-1.0999985-1.9000015c0.0999985,0.1999969,1.1999969,2,3,0.3000031 C93.1999969,85.3000031,93.5,85,93.5,85z"
      />
    </g>
  )
}

export default withSkinColor(Hands, { character: "WomanDwarf" })
