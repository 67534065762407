import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Arms from "./Arms"

const CATEGORY = "arms"
const CHARACTER = "ManHalfling"
export const IDS = {
  ARMS: "Arms"
}

export const components = {
  [IDS.ARMS]: Arms
}

export const Group = ({ props }) => (
  <g id="arms">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.ARMS,
    name: "Arms",
    defaultColors: ["#E8B180"],
    colorable: false,
    component: components[IDS.ARMS],
    inUI: false,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
