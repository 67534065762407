import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WizardHat = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WizardHat") ? (
    <g id="WizardHat">
      <path
        id="S_x24_wizardHat"
        d="M34.0999985,53.5278702c-1.5-0.0999985-2.8999996-0.0999985-4.2999992-0.2000008 C26.6999989,53.2278709,24,53.1278687,22.0999985,52.9278679l-3.3999996-0.7999992 c-2.6000004-1.0999985-4.3000002-3.4000015-4.5-6.0999985c-0.1999998-2.5,0.8000002-5.7000008,4.5-7.5l0.7999992-0.2999992 c2.1000004-0.7000008,4.8999996-1.2999992,8.6999989-1.9000015c2.3999996-0.3999977,4.5-0.5,6.5-0.5l0.5-3.2000008 c0,0,4.5-12.2999992,5.7000008-14.3999977s6.9000015-3.3999996,6.9000015-3.3999996s5.7999992,1.5,6.5,4.5 c0.7999992,3,2.2000008,8.8999996,2.2000008,8.8999996l3,5.7999992c0.7999992-0.1000023,2-0.1000023,2-0.1000023 c2.5-0.0999985,4.5999985-0.2999992,6.4000015-0.3999977c1.8000031-0.1000023,3.3000031-0.2000008,4.5-0.2999992 c1.5999985-0.4000015,2.6999969-0.5,3.5999985-0.5c3.3000031,0,6.1999969,2.0999985,7.0999985,5.2000008 c0.8000031,2.5,0.3000031,6.0999985-3.4000015,8.5c0,0-1.5,0.7999992-1.5999985,0.9000015l-2.3000031,1 c-2.5,1-9.8000031,2.9000015-17.0999985,3.5999985c-0.0999985,0-0.2000008,0-0.4000015,0 c-0.9000015,0.0999985-1.7999992,0.2000008-2.7000008,0.2999992c-1.9000015,0.2000008-3.9000015,0.4000015-6,0.5999985 c-4.5,0.2999992-10.2999992,0.7000008-15.1000023,0.7999992L34.0999985,53.5278702z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WizardHat, {
  character: "ManHalfling"
})
