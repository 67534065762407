import React from "react"

const GoldAxeRight = ({ colors = ["#8A7441", "#A49255", "#EECE6E"] }) => {
  return (
    <g id="GoldAxeRight">
      <path
        fill={colors[0]}
        d="M12.0040073,68.9557343c1.6999998,0.9000015,4.7000008,0.5,7.6000004-1.0999985 s4.8000011-3.9999962,4.8999996-5.7999954c3.1000004,0.2000008,5.7999992,1.5999985,7.2000008,4.0999985 c1.4000034,2.5,1.2000027,5.5-0.2999992,8.1999969c-1.7000008-0.9000015-4.7000008-0.5-7.6000004,1.0999985 s-4.8000011,4-4.8999996,5.8000031c-3.1000004-0.1999969-5.8000002-1.5999985-7.1999998-4.0999985 C10.3040075,74.6557388,10.5040073,71.6557388,12.0040073,68.9557343z"
      />
      <path
        fill={colors[1]}
        d="M21.7040081,75.1557388l-5.5-8.0999985c0.1000004-0.3000031,0.1000004-0.5,0.2000008-0.8000031 l3.5999985-2c0.2000008,0.0999985,0.3999996,0.0999985,0.7000008,0.2000046l3.8999996,9.0999985 c0,0,12.3000031,24.4000015,15.5,30c2.5999985,4.5,6.5999985,10.0999985,8,12c-0.0999985,0.3000031-0.2999992,0.6999969-0.5,1 l-3.4000015,0.9000015L24.904007,80.1557388L21.7040081,75.1557388z"
      />
      <path
        fill={colors[0]}
        d="M47.6040077,116.4557419c0.0999985-0.0999985-0.2999992-0.3000031-0.2000008-0.5 c-1.7000008-2.3000031-5.5999985-7.8000031-8.1000023-12.0999985 c-3.1999969-5.5000076-15.2999973-29.6000061-15.3999977-29.8000031l-3.7999992-8.8000031 c-0.1000004,0-0.5-0.6999969-0.5-0.7999954l-2.7999992,1.6999969c0,0.0999985,0,0.7000046,0.1999989,0.5999985 l5.2999992,7.8000031l3,5.0999985l19.3000011,37.5L47.6040077,116.4557419z"
      />
      <path
        fill={colors[2]}
        d="M47.3040085,116.5557404c-1.7000008-2.3000031-5.7999992-8.1999969-8.2000008-12.4000015 c-3-5.1999969-14.2000027-27.5-15.5000019-30l-4.5-9.3000031l-1.7999992,1l5.1999989,8.7000046l3.2000008,5l19.2000046,37.7000046 L47.3040085,116.5557404z"
      />
      <g fill={colors[2]}>
        <path d="M30.404007,67.1557388c-0.6000004-1.2000046-1.7000008-2-2.8999996-2.5 c-0.1000004-0.0999985,0.1000004-0.7000008,0.1000004-1.2000008c-0.3999996,0.7999992-0.7000008,0.8999977-0.7999992,0.8999977 c-0.8999996-0.2999954-1.8999996-0.4999962-2.8999996-0.4999962c0.3999996-0.5999985,0.6000004-1.2999992,0.6000004-1.7999992 c3.1000004,0.2000008,5.8000011,1.5999985,7.1999989,4.0999985c1.3999996,2.5,1.2000027,5.5-0.2999992,8.1999969 c-0.3999996-0.1999969-0.7999992-0.3000031-1.2999992-0.4000015 C31.3040066,71.6557388,31.5040073,69.2557373,30.404007,67.1557388z" />
        <path d="M12.0040073,68.9557343c0.5,0.3000031,1.1000004,0.4000015,1.8000002,0.4000015 c-1.3999996,2.4000015-1.6999998,5-0.6000004,7.2000046c0.3000002,0.5999985,0.8999996,1.1999969,1.3999996,1.6999969 c0,0-0.1000004,0.3000031,0,0.3000031c0.1000004-0.0999985,0.1000004-0.0999985,0.1999998-0.1999969 c0.1000004,0.0999985,0.3999996,0.3000031,0.5,0.4000015s0.1000004,0.3000031-0.1000004,1.0999985 c0.1000004-0.3000031,0.6000004-0.8000031,0.6999998-0.8000031c1.000001,0.5,2.0999994,0.8000031,3.3000002,0.9000015 c-0.1999989,0.5-0.2999992,0.9000015-0.3999996,1.3000031c-3.1000004-0.1999969-5.8000002-1.5999985-7.1999998-4.0999985 C10.3040075,74.6557388,10.5040073,71.6557388,12.0040073,68.9557343z" />
      </g>
    </g>
  )
}

export default GoldAxeRight
