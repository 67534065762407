import React from "react"

const CloakBack = ({ colors = ["#19252D"] }) => {
  return (
    <g id="CloakBack">
      <path
        fill={colors[0]}
        d="M57.3755913,25.1817837c0,0.6437149-17.8094711,11.9087429-17.8094711,11.9087429 l-5.6861572,29.3963585l-0.4291458,38.4083786c0,0,69.5213165,0.2145691,69.5213165,0s0-36.691803,0-36.691803 L94.711113,33.6573753l-16.2001877-8.4755917H57.3755913z"
      />
    </g>
  )
}

export default CloakBack
