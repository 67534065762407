import React from "react"

const BehindRobe = ({ colors = ["#3A0806"] }) => {
  return (
    <g id="BehindRobe">
      <polygon
        fill={colors[0]}
        points="59.0997925,52.9137726 77.9834747,52.9137726 88.3090363,83.239296 88.3090363,117.8438721 49.1463242,117.8438721 49.1463242,82.0299911"
      />
    </g>
  )
}

export default BehindRobe
