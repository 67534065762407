import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const AxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("AxeLeft") ? (
    <g id="AxeLeft">
      <path
        id="S_x24_axeLeft_1_"
        d="M56.1900482,116.2233276l-2.180397-1.0175171l-2.7618294-5.8143768l5.6690178-25.0018234h-1.526268 c-2.8345108-2.6891556-4.0700645-6.4685059-3.4159508-9.9571304l0,0c0.726799-3.5613098,3.2705879-6.5411758,6.977253-7.9220886 l2.6891518,1.1628723l3.5613136-0.8721542l2.7618256,0.5087585l3.0525513,2.1077118l2.8345108-0.0726776 c2.8345032,2.6891479,4.0700684,6.4684982,3.4159393,9.9571304v0.07267 c-0.726799,3.5613098-3.2705841,6.4684982-6.9772491,7.9220963l-1.5262756-0.6541214 c-1.5989532,6.8318939-3.1979141,13.518425-3.5613022,15.7714996c-0.5814438,3.2705917-0.9448471,7.8494186-1.0175247,9.1576462 l-2.5437889,4.0700607c-0.5814362,0.3634033-1.1628761,0.5814438-1.8169899,0.7994766L56.1900482,116.2233276z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(AxeLeft, { character: "ManGnome" })
