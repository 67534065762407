import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import RoundWoodShieldLeft from "./RoundWoodShieldLeft"
import RoundWoodShieldRight from "./RoundWoodShieldRight"
import JugL from "./JugL"
import JugR from "./JugR"
import CutlassL from "./CutlassL"
import CutlassR from "./CutlassR"

const CATEGORY = "foreground"
const CHARACTER = "ManHalfling"
export const IDS = {
  ROUND_WOOD_SHIELD_LEFT: "RoundWoodShieldLeft",
  ROUND_WOOD_SHIELD_RIGHT: "RoundWoodShieldRight",
  JUG_L: "JugL",
  JUG_R: "JugR",
  CUTLASS_L: "CutlassL",
  CUTLASS_R: "CutlassR"
}

export const components = {
  [IDS.ROUND_WOOD_SHIELD_LEFT]: RoundWoodShieldLeft,
  [IDS.ROUND_WOOD_SHIELD_RIGHT]: RoundWoodShieldRight,
  [IDS.JUG_L]: JugL,
  [IDS.JUG_R]: JugR,
  [IDS.CUTLASS_L]: CutlassL,
  [IDS.CUTLASS_R]: CutlassR
}

export const Group = ({ props }) => (
  <g id="foreground">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.ROUND_WOOD_SHIELD_LEFT,
    name: "Round Wood Shield Left",
    defaultColors: [
      "#D2A673",
      "#7B6144",
      "#675139",
      "#997954",
      "#SVGID_",
      "#36302A",
      "#726658",
      "#8F8579",
      "#FFFFFF",
      "#F6F6F6",
      "#E4E5E6",
      "#C8CACC",
      "#A2A4A7",
      "#757779",
      "#2D2D2E",
      "#000000"
    ],
    colorable: false,
    component: components[IDS.ROUND_WOOD_SHIELD_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.ROUND_WOOD_SHIELD_RIGHT,
    name: "Round Wood Shield Right",
    defaultColors: [
      "#D2A673",
      "#7B6144",
      "#675139",
      "#997954",
      "#SVGID_",
      "#36302A",
      "#726658",
      "#8F8579",
      "#FFFFFF",
      "#F6F6F6",
      "#E4E5E6",
      "#C8CACC",
      "#A2A4A7",
      "#757779",
      "#2D2D2E",
      "#000000"
    ],
    colorable: false,
    component: components[IDS.ROUND_WOOD_SHIELD_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.JUG_L,
    name: "Jug L",
    defaultColors: ["#9C7000", "#33332C", "#FFFFFF"],
    colorable: true,
    component: components[IDS.JUG_L],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.JUG_R,
    name: "Jug R",
    defaultColors: ["#9C7000", "#33332C", "#FFFFFF"],
    colorable: true,
    component: components[IDS.JUG_R],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.CUTLASS_L,
    name: "Cutlass L",
    defaultColors: ["#DDC300", "#FFFFFF", "#7C7A79"],
    colorable: true,
    component: components[IDS.CUTLASS_L],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.CUTLASS_R,
    name: "Cutlass R",
    defaultColors: ["#DDC300", "#FFFFFF", "#7C7A79"],
    colorable: true,
    component: components[IDS.CUTLASS_R],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
