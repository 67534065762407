import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const RoundWoodShieldRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("RoundWoodShieldRight") ? (
    <g id="RoundWoodShieldRight">
      <path
        id="S_x24_roundWoodShieldRight"
        d="M26.7999992,99.8000031c-8.8999996,0-16.7000008-5.5999985-19.6000004-14 C3.5,74.9000015,9.3000002,63.2000008,20.1000004,59.5c2.1000004-0.7000008,4.3999996-1.0999985,6.6000004-1.0999985 c8.8999977,0,16.7999992,5.5999985,19.6000023,14c1.7999992,5.1999969,1.4000015,10.8000031-1,15.8000031 s-6.7000008,8.6999969-11.9000015,10.4000015C31.2999992,99.4000015,29,99.8000031,26.7999992,99.8000031L26.7999992,99.8000031z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(RoundWoodShieldRight, {
  character: "ManHalfling"
})
