import React from "react"

const InsideCape = ({ colors = ["#2B3B21"] }) => {
  return (
    <g id="InsideCape">
      <path
        id="openCloak_1_"
        fill={colors[0]}
        d="M48.1426239,54.9344254c-6.3298759,0-13.187336,5.7323494-13.1961327,5.7931366 c-0.000309,0.0021324-0.000309,0.0013542-0.0008392,0.0034447L20.0206146,119.61866 c-0.0104504,0.0412292,0.0207577,0.0812683,0.0632954,0.081192l57.4358139-0.0997162 c0.0413818-0.0000687,0.0722198-0.0381851,0.063652-0.0786743L61.8752289,60.1914368 C61.873951,60.1854134,54.472496,54.9344254,48.1426239,54.9344254z"
      />
    </g>
  )
}

export default InsideCape
