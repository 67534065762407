import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaceR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaceR") ? (
    <g id="MaceR">
      <path
        id="S_x24_maceR"
        d="M6.4,63.2l3,5.1c3,5.2,9.8,7,15,4l2.2-1.3c5.2-3,7-9.8,4-15l-3-5.1c-3-5.2-9.8-7-15-4l-2.2,1.3 C5.2,51.3,3.4,58,6.4,63.2z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaceR, { character: "ManDwarf" })
