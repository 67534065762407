import React from "react"

const SlingShotLeft = ({
  colors = ["#352915", "#464646", "#939393", "#724F20"]
}) => {
  return (
    <g id="SlingShotLeft">
      <path
        fill={colors[0]}
        d="M60.0408287,90.8808136l0.7994728-7.2679672l4.5061417-2.1077194 c0,0,1.5989532,1.4535904,1.5262756,3.3432693c0,0.2907104,1.526268,24.8564606,1.526268,24.8564606 s-0.1453552,1.7443237-0.8721542,2.0350418s-2.3257523-0.2907181-2.3257523-0.2907181l-0.3633881-2.0350342l0.3633881-24.8564835 l0.8721619-1.3809128l-0.7994766-1.0901947l-3.8520317,2.0350342l0.726799,1.6716385l-0.0726776,6.3231354l-1.671627,1.9623566 l0.3633995-3.9973831L60.0408287,90.8808136z"
      />
      <path
        fill={colors[1]}
        d="M68.5443573,108.6146698c-0.5814438-0.7994766-0.726799-1.3082352-1.8170013-1.1628723 c-0.1453552,0-1.3809128,0.0726776-1.4535904,0.2180405c-0.3633957,0.5814362-0.8721619,1.9623489-0.8721619,1.9623489 s0.7994766,1.5262756,1.8170013,1.5989532c0.726799,0.0726776,2.253067-0.6541214,2.253067-0.6541214 S68.8350677,108.9780655,68.5443573,108.6146698z"
      />
      <path
        fill={colors[2]}
        d="M66.8727188,108.3966293c-0.1453552-0.2907257-0.2907181-0.6541214-0.4360733-0.9448395 c-0.4360809,0-1.0175171,0.0726776-1.1628799,0.2180405c-0.3633957,0.5814438-0.8721542,1.9623489-0.8721542,1.9623489 s0.2907257,0.5814438,0.7994766,1.0175247c0.5087585-0.1453629,1.0175247-0.4360809,1.5262756-0.7994766 C66.727356,109.4141464,66.8000412,108.9053879,66.8727188,108.3966293z"
      />
      <path
        fill={colors[3]}
        d="M66.0732422,111.0130997l1.0175247-0.0726776l0.1453552-1.7443161l-0.4360809-1.8896713 l-0.9448395-23.4755554l-2.7618332-1.3082352l1.5262756-0.726799l0.726799-0.3634033l1.0175171,0.5087662l0.6541214,1.526268 l1.4535904,26.1647034c0,0-0.2907181,2.0350418-1.2355499,1.7443161c-0.1453552-0.0726776-1.3082428,0-1.7443161-0.2907181 C65.9278793,110.8677444,66.0732422,111.0130997,66.0732422,111.0130997z"
      />
    </g>
  )
}

export default SlingShotLeft
