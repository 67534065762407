import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { Route, Link } from 'react-router-dom'
import {
  Authenticator,
  Greetings,
  SignIn,
  ConfirmSignIn,
  RequireNewPassword,
  SignUp,
  ConfirmSignUp,
  VerifyContact,
  ForgotPassword
} from 'aws-amplify-react'

import Amplify, { Auth } from 'aws-amplify'
import AWSExports from './aws-exports'
Amplify.configure(AWSExports)

const AuthPage = () => <Authenticator
    authState="signIn"
    hideDefault={true}
    amplifyConfig={AWSExports}
>
  <Greetings/>
  <SignIn/>
  <ConfirmSignIn/>
  <RequireNewPassword/>
  <SignUp signUpConfig={{ hideAllDefaults: true,   signUpFields: [
        {
          label: 'Username',
          key: 'username',
          required: true,
          placeholder: 'Username',
          type: 'username',
          displayOrder: 1
        },
        {
          label: 'Email',
          key: 'email',
          required: true,
          placeholder: 'Email',
          type: 'email',
          displayOrder: 2
        },
        {
          label: 'Password',
          key: 'password',
          required: true,
          placeholder: 'Password',
          type: 'password',
          displayOrder: 3
        }
      ]
    }}
  />
  <ConfirmSignUp/>
  <VerifyContact/>
  <ForgotPassword/>
</Authenticator>

export class RequireSignIn extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loggedIn: false };
  }

  componentDidMount() {
    if (this.props.loggedIn) return

    Auth.currentSession()
      .then(data => {
        this.setState({ loggedIn: true })
      })
      .catch(err => console.log(err))

  }

  render() {
    if (this.state.loggedIn) {
      return <div>{this.props.children}</div>
    }
    return (
      <Route render={({ history}) => (
        <button
          className="button is-warning"
          type='button'
          onClick={() => { history.push('/auth') }}
        >
          {this.props.message}
        </button>
      )} />
    )
  }
}


RequireSignIn.protoTypes = {
  message: PropTypes.string
}

RequireSignIn.defaultProps = {
  message: "Sign In to Save"
}

export class LogOut extends React.Component {
  constructor(props) {
    super(props)

    this.state = { session: undefined };
  }

  componentDidMount() {
    if (this.props.session) return

    Auth.currentSession()
      .then(data => {
        this.setState({ session: true, ...data })
      })
      .catch(err => console.log(err))

  }

  signOutHandler = (history) => {
    Auth.signOut()
    .then(_data => history.push('/auth'))
    .catch(err => console.log(err));
  }

  render() {
    const userName = get(this.state, ["idToken", "payload", "cognito:username"])
    if (!this.state.session) {
      return <div>
        <Link to='/auth'>login</Link>
      </div>
    }
    return (
      <Route render={({ history}) => (
        <span>
          {userName} | &nbsp;
          <a
            href="#"
            className="is-secondary"
            onClick={() => this.signOutHandler(history)}
          >
            Logout
          </a>
        </span>
      )} />
    )
  }
}

export default AuthPage