import React from "react"

const LuteRight = ({
  colors = ["#93712B", "#D8A43D", "#6A5121", "#B68B32", "#8E6D2B"]
}) => {
  return (
    <g id="LuteRight">
      <path
        fill={colors[0]}
        d="M49.9545059,77.1378632h0.5586205l0.069828,0.2793121l0.3491325,0.0698242l0.2793121-0.4887924v-0.4189606 l-0.4887886,0.0698318l-0.1396523,0.2094803l-0.6982727,0.0698242 C49.8846817,76.9982071,49.8148575,77.1378632,49.9545059,77.1378632z"
      />
      <path
        fill={colors[0]}
        d="M49.8846817,76.5094147l0.6284447,0.0698318v0.1396484l0.2793083-0.1396484l0.2793121-0.2793121 l0.0698242-0.3491364l-0.3491364,0.1396561l-0.2793083,0.2094803l-0.6982689,0.0698242 C49.8148575,76.4395905,49.7450294,76.5094147,49.8846817,76.5094147z"
      />
      <path
        fill={colors[0]}
        d="M49.8148575,76.0206299h0.5586166l0.069828,0.1396484l0.3491325-0.0698242l0.2793121-0.2793045 l0.0698242-0.3491364l-0.3491364,0.1396484l-0.2793083,0.2094879l-0.6982689,0.0698242 C49.7450294,75.8809738,49.6752052,76.0206299,49.8148575,76.0206299z"
      />
      <path
        fill={colors[0]}
        d="M50.0941658,77.9757843l0.6284447,0.0698242l0.0698242,0.1396561h0.3491364l0.2793083-0.4189606 l0.069828-0.3491364l-0.3491364,0.1396484l-0.2793083,0.2094879L50.16399,77.8361282 C50.0941658,77.8361359,50.0243378,77.9757843,50.0941658,77.9757843z"
      />
      <path
        fill={colors[1]}
        d="M55.4010239,87.3326187C59.10186,87.1929626,66.4337006,97.3877182,67.062149,99.5523605 c0.6284409,2.2344589,0.8379211,4.7482452-2.2344666,6.3542633c-3.0723953,1.6060181-7.2620201-1.3965454-8.4490776-3.1422195 C55.0518875,101.0187225,52.887249,87.7515793,55.4010239,87.3326187z"
      />
      <path
        fill={colors[2]}
        d="M48.4881401,77.0680389l0.0698242-0.6982727l6.0051308,11.8706055 c0,0-2.1646385,19.5515823,10.1947556,17.736084c0,0-7.8904648,4.6085815-11.3119888-6.0749588 c-0.8379211-5.0275497,0.2793083-11.0326843,0.2793083-11.0326843"
      />
      <path
        fill={colors[0]}
        d="M48.7674484,79.0930252l-0.5586166,0.2094803v0.1396561L47.9295197,79.58181l-0.3491325-0.2094803 l-0.1396523-0.2793121h0.4887848h0.2094841l0.6982727-0.0698242 C48.8372765,78.9533691,48.9071007,79.0930252,48.7674484,79.0930252z"
      />
      <path
        fill={colors[0]}
        d="M48.6277962,77.5568237l-0.5586166,0.2094803v0.2793121l-0.2793121,0.1396561l-0.3491325-0.3491364 l-0.1396523-0.4189606h0.4887848l0.2094841,0.1396484H48.6277962 C48.6277962,77.4171753,48.6976242,77.4869995,48.6277962,77.5568237z"
      />
      <path
        fill={colors[0]}
        d="M48.5579643,76.9283752l-0.5586166,0.2094879v0.2094803l-0.2793083-0.0698242l-0.3491364-0.2094803 l-0.1396523-0.2793121h0.4189606l0.3491364,0.1396484l0.6982689-0.0698242 C48.5579643,76.7887268,48.6277962,76.9283752,48.5579643,76.9283752z"
      />
      <path
        fill={colors[0]}
        d="M48.4183121,76.4395905l-0.5586166,0.1396561v0.1396484l-0.3491364,0.0698242l-0.3491364-0.2094803 l-0.1396523-0.2793121h0.4189644l0.3491325,0.1396637l0.6982727-0.0698242 C48.4183121,76.299942,48.5579643,76.3697662,48.4183121,76.4395905z"
      />
      <path
        fill={colors[0]}
        d="M48.6277962,78.3947525l-0.5586166,0.2094803v0.2094879l-0.3491364,0.0698242l-0.3491364-0.349144 l-0.1396523-0.2793045h0.4189606l0.3491364,0.1396484h0.6284447 C48.6976242,78.2550964,48.7674484,78.3947525,48.6277962,78.3947525z"
      />
      <polygon
        fill={colors[3]}
        points="56.6579132,87.6817551 50.0243378,75.6714935 48.3484879,76.0904541 54.5630989,88.2403717"
      />
      <polygon
        fill={colors[4]}
        points="65.9449158,100.8092499 62.1742516,102.6945801 61.8949432,101.8566513 65.5259552,100.041153"
      />
      <polygon
        fill={colors[0]}
        points="48.5579643,76.5094147 48.4881401,77.0680389 48.9769325,79.4421616 49.6053734,79.3024979"
      />
      <polygon
        fill={colors[2]}
        points="48.7674484,79.0930252 48.9769325,79.4421616 48.6277962,76.299942 48.3484879,76.0904541"
      />
      <ellipse
        transform="rotate(-31.851 59.633 94.184)"
        fill={colors[0]}
        cx="59.633"
        cy="94.184"
        rx="1.257"
        ry="1.815"
      />
      <line fill="none" x1="64.618" y1="100.879" x2="49.815" y2="75.811" />
      <line fill="none" x1="64.269" y1="101.019" x2="49.396" y2="75.811" />
      <line fill="none" x1="63.85" y1="101.089" x2="49.047" y2="75.951" />
      <line fill="none" x1="63.501" y1="101.298" x2="48.698" y2="76.021" />
    </g>
  )
}

export default LuteRight
