import React from "react"

const BattleAxeRight = ({ colors = ["#7A7C7D", "#505052"] }) => {
  return (
    <g id="BattleAxeRight">
      <path
        fill={colors[0]}
        d="M45.5798912,63.4751358c0.1349564,0.3035393,0.454834,0.6000748,0.7677116,0.7116852 c1.3178177,0.5673904,2.3773994,0.8359146,3.7951508,0.7822189c0.1919289,0.1779251,0.6327591,0.4081497,0.8886642,0.6453781 c-0.0046692-0.123291-0.1503525-2.3400459-0.1550217-2.4633331c0,0-0.4954681-0.0429649-0.4978027-0.1046066 c0,0,0.9082794-0.466507,0.9265099-3.2450447c0.4132614,2.7621956,1.2925835,3.1610031,1.2925835,3.1610031 c0.0046692,0.1232834-0.4884644,0.1419601-0.4884644,0.1419601c0.0046692,0.1232834,0.1550217,2.4633293,0.1550217,2.4633293 s0.5407677-0.3908615,0.8373032-0.7107391c1.4154205-0.1153336,2.4516525-0.4632263,3.6588058-1.1879768 c0.3035393-0.1349564,0.600071-0.4548378,0.6500397-0.7653809c0,0,5.8418884,7.556736-0.7262421,13.4229088 c0.739254-3.2996826,0.7514229-7.8681641-3.2669525-8.0246277c-0.0616379,0.0023346-0.9885979-0.024292-0.9885979-0.024292 l0.2306671,2.8308411c0.0663109,0.1209488,0.2535706,0.1755905,0.4478302,0.4151535l-0.5547714,0.0210114l0.0326805,0.8629761 l0.6164169-0.0233383c0.0023346,0.0616379,0.0443535,1.1711884,0.0466881,1.2328262 c-0.0616379,0.0023346-0.6164131,0.0233459-0.6780548,0.0256805c0.6424866,12.0747375,1.6837273,31.4185104,2.32621,43.4932556 l0.0616417-0.0023346c0.0350189,0.9246216-1.2664566,0.7887192-1.3561134,0.0513535l0.0616417-0.0023346 c-0.6424866-12.0747375-1.6837273-31.4185104-2.32621-43.493248c-0.0616417,0.0023346-0.6164169,0.0233459-0.6780586,0.0256805 c-0.0023346-0.0616455-0.0443535-1.171196-0.0466881-1.2328339l0.6164169-0.0233459l-0.0326843-0.8629837l-0.5547752,0.0210114 c0.0569725-0.125618,0.2965355-0.3198776,0.4151459-0.4478302l-0.1050491-2.7738724c0,0-0.8606491,0.0943222-0.9839325,0.0989914 c-3.9903564,0.5832367-3.5093956,5.1330414-2.401741,8.3010483 C40.5775299,71.5660248,45.5798912,63.4751358,45.5798912,63.4751358z"
      />
      <path
        fill={colors[1]}
        d="M53.0375748,64.7976761c1.3537788-0.112999,2.3307076-0.3969193,3.47855-1.0576897 c1.3761787,2.1084328,3.7797012,6.8940697,0.4062042,10.7256279c0.3217697-2.9134903-0.2311134-6.1025085-3.5690956-6.2230148 c-0.0616417,0.0023346-0.7396965,0.0280151-0.9246216,0.0350189L52.20261,65.5700607 C52.4421692,65.3757935,52.8596535,64.9896088,53.0375748,64.7976761z"
      />
      <path
        fill={colors[1]}
        d="M50.0811119,64.9713745c-1.3561096,0.0513611-2.3517189-0.1578522-3.5439148-0.6682739 c-1.151123,2.2041473-2.9985352,7.2125092,0.7144127,10.7139587c-0.6644974-2.8761444-0.4160423-6.0955124,2.8985939-6.591423 c0.0616417-0.0023346,0.801342-0.0303497,0.9862633-0.0373535l-0.1050491-2.7738647 C50.8418198,65.4981308,50.3346825,65.146965,50.0811119,64.9713745z"
      />
      <rect
        x="50.814"
        y="73.18"
        transform="rotate(-3.036 52.078 73.427)"
        fill={colors[1]}
        width="2.529"
        height="0.493"
      />
      <rect
        x="50.745"
        y="71.518"
        transform="rotate(-3.036 51.979 71.61)"
        fill={colors[1]}
        width="2.467"
        height="0.185"
      />
      <path
        fill={colors[1]}
        d="M52.1068916,63.0427551c0,0,0.3082085-0.011673,0.4884644-0.1419601 c-0.3128738-0.1116104-1.3607788-0.0719223-2.2190933,0.0840454c0.0639763,0.0593071,0.4978027,0.1046066,0.4978027,0.1046066 S51.421833,62.8835106,52.1068916,63.0427551z"
      />
    </g>
  )
}

export default BattleAxeRight
