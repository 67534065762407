import React from "react"

const BroadSwordR = ({ colors = ["#898888", "#FFFFFF"] }) => {
  return (
    <g id="BroadSwordR">
      <path
        fill={colors[0]}
        d="M35.3467712,52.9736061l2.5999985-0.7999992l1.0999985-1.2999992L38.246769-8.3263912 l-0.9000015-4.3000002l-0.5-0.8000002c0,0-2.2000008,3.4000006-2.5,6.3000002 C34.1467705-4.3263917,35.3467712,52.9736061,35.3467712,52.9736061z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M35.3467712,53.2736092 c0,0-1.2999992-57.2999992-1-60.2000008s2.5-6.3000002,2.5-6.3000002s0.9000015,65.1999969,1.0999985,65.6999969 L35.3467712,53.2736092z"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="37.9467697,52.3736076 39.0467682,51.0736084 38.246769,-8.1263914 37.3467712,-12.4263916"
      />
      <path
        fill={colors[0]}
        d="M35.6467705,53.2736092l-0.2000008,8.7000008l1.5,0.5999985l1.7999992-1.2999992 c0,0-0.2000008-5.0999985-0.2000008-8.2999992l2.7999992-1.0999985l0.2000008-1.9000015l-0.5999985-0.1000023 l-9.4999981,1.2000008l-0.0999985,2.0999985L35.6467705,53.2736092z"
      />
    </g>
  )
}

export default BroadSwordR
