import React from "react"

const GoldAxeLeft = ({ colors = ["#8A7441", "#A49255", "#EECE6E"] }) => {
  return (
    <g id="GoldAxeLeft">
      <path
        fill={colors[0]}
        d="M85.2009583,68.9752731c-1.6999969,0.9000015-4.6999969,0.5-7.5999985-1.0999985 s-4.8000031-4-4.9000015-5.7999992c-3.0999985,0.2000008-5.8000031,1.5999985-7.1999969,4.1000023 c-1.4000015,2.5-1.1999969,5.5,0.3000031,8.1999969c1.6999969-0.9000015,4.6999969-0.5,7.5999985,1.0999985 s4.8000031,4,4.9000015,5.8000031c3.0999985-0.1999969,5.8000031-1.5999985,7.1999969-4.0999985 C86.8009567,74.6752701,86.6009598,71.6752701,85.2009583,68.9752731z"
      />
      <path
        fill={colors[1]}
        d="M75.5009613,75.1752701l5.5-8.0999985c-0.0999985-0.2999954-0.0999985-0.5-0.1999969-0.7999954 l-3.5999985-2c-0.1999969,0.0999985-0.4000015,0.0999985-0.6999969,0.1999969l-4,9.0999985 c0,0-12.3000031,24.4000015-15.5000038,30c-2.5999985,4.5-6.5999985,10.0999985-8,12 c0.0999985,0.3000031,0.2999992,0.6999969,0.5,1l3.4000015,0.9000015L72.300972,80.1752777L75.5009613,75.1752701z"
      />
      <path
        fill={colors[0]}
        d="M49.600956,116.4752731c-0.0999985-0.0999985,0.2999992-0.3000031,0.2000008-0.5 c1.7000008-2.3000031,5.5999985-7.8000031,8.1000023-12.0999985c3.1999969-5.5,15.3000069-29.5999985,15.4000053-29.8000031 l3.8000031-8.7999954c0.0999985,0,0.5-0.7000046,0.5-0.8000031l2.8000031,1.7000046c0,0.0999985,0,0.6999969-0.1999969,0.5999985 l-5.3000183,7.7999954l-3,5.0999985l-19.3000031,37.5L49.600956,116.4752731z"
      />
      <path
        fill={colors[2]}
        d="M49.8009529,116.4752731c1.7000008-2.3000031,5.7999992-8.1999969,8.2000008-12.4000015 c3.0000038-5.1999969,14.2000046-27.5,15.5000076-30l4.5-9.2999954l1.8000031,1l-5.1999969,8.6999969l-3.1999969,5 l-19.2000046,37.7000046L49.8009529,116.4752731z"
      />
      <g fill={colors[2]}>
        <path d="M66.8009567,67.1752701c0.5999985-1.1999969,1.6999969-2,2.9000015-2.5 c0.0999985-0.0999985-0.0999985-0.7000008-0.0999985-1.2000008c0.4000015,0.7999992,0.6999969,0.9000053,0.8000031,0.9000053 c0.9000015-0.3000031,1.9000015-0.5000038,2.9000015-0.5000038c-0.4000092-0.5999985-0.6000061-1.2999992-0.6000061-1.7999992 c-3.0999985,0.2000008-5.8000031,1.5999985-7.1999969,4.0999985c-1.4000015,2.5-1.1999969,5.5,0.3000031,8.1999969 c0.4000015-0.1999969,0.8000031-0.3000031,1.3000031-0.4000015 C65.9009552,71.6752701,65.7009583,69.1752701,66.8009567,67.1752701z" />
        <path d="M85.2009583,68.8752747c-0.5,0.2999954-1.0999985,0.4000015-1.8000031,0.4000015 c1.4000015,2.4000015,1.6999969,4.9999924,0.5999985,7.1999969c-0.3000031,0.5999985-0.9000015,1.1999969-1.4000015,1.6999969 c0,0,0.0999985,0.3000031,0,0.3000031c-0.0999985-0.0999985-0.0999985-0.0999985-0.1999969-0.1999969 c-0.0999985,0.0999985-0.4000015,0.3000031-0.5,0.4000015s-0.0999985,0.3000031,0.0999985,1.0999985 c-0.0999985-0.3000031-0.5999985-0.8000031-0.6999969-0.8000031c-1,0.5-2.0999985,0.8000031-3.3000031,0.9000015 c0.1999969,0.5,0.3000031,0.9000015,0.4000015,1.3000031c3.0999985-0.1999969,5.8000031-1.5999985,7.1999969-4.0999985 C86.9009552,74.6752701,86.6009598,71.6752701,85.2009583,68.8752747z" />
      </g>
    </g>
  )
}

export default GoldAxeLeft
