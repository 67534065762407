import React from "react"

const Buckle = ({ colors = ["#9E812F", "#FFFFFF"] }) => {
  return (
    <g id="Buckle">
      <ellipse fill={colors[0]} cx="69.5" cy="56.1" rx="5" ry="2.8" />
      <path
        opacity="0.46"
        d="M69.5,58.4c-2.6,0-4.5-1.2-4.5-2.2s1.8-0.2,4.5-0.2s4.5-0.8,4.5,0.2 S72.1,58.4,69.5,58.4z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M69.5,53.8c2.6,0,4.5,1.2,4.5,2.2s-1.8,0.2-4.5,0.2 S65,57.1,65,56S66.9,53.8,69.5,53.8z"
      />
      <path
        fill={colors[0]}
        d="M69.5,55.5c-2.3,0-3.9,0.6-4.4,0.1c0,0.1-0.1,0.3-0.1,0.4c0,1.1,1.8,0.2,4.5,0.2S74,57.1,74,56 c0-0.1,0-0.3-0.1-0.5C73.4,56.1,71.8,55.5,69.5,55.5z"
      />
    </g>
  )
}

export default Buckle
