import React from "react"

const TorchR = ({
  colors = [
    "#664000",
    "#F1D212",
    "#FFD400",
    "#FFD500",
    "#SVGID_",
    "#FFFFFF",
    "#F4B719",
    "#F49A35",
    "#F37B20",
    "#F1662E",
    "#EE4223"
  ]
}) => {
  return (
    <g id="TorchR">
      <path
        fill={colors[0]}
        d="M32.246769,43.8736076l5.0999985,20.4000053l1.2999992,0.0999985l1-0.5000038 l-3.9000015-20.5999985C33.246769,43.0736084,32.246769,43.8736076,32.246769,43.8736076z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M37.3467712,52.2736092 c0.0999985,0.2000008,0.0999985,0.2999992,0.2000008,0.5l2.0999985,11.0999985l-0.9000015,0.5000038 c-0.5999985-2.1000023-1.2000008-4.3000031-1.7000008-6.5000038c-1-4.4000015-1.9000015-9.5999985-3.5-14.4000015 c0.0999985,0,0.2000008-0.0999985,0.4000015-0.0999985C34.9467697,46.3736076,36.3467712,49.3736076,37.3467712,52.2736092z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M35.9467697,56.9736061 c0.7999992,2.4000015,1.4000015,4.9000015,2.0999985,7.4000053h-0.7000008l-2.9000015-11.6000023 C34.9467697,54.1736069,35.4467697,55.5736084,35.9467697,56.9736061z"
      />
      <path
        opacity="0.84"
        fill={colors[1]}
        d="M28.7467709,28.0736084 c0.7000008-1.7999992,0.9000015-3.6000004,2-5.2000008c0,0,0.5-0.7000008,0.5999985-0.7000008 c0.0999985,0.1000004,0.7999973,0.2000008,0.6999989-0.2000008c-0.0999966-0.8999996-0.9999981-1.2000008-0.6999989-2.1000004 c0.2000008-0.7000008,1.1999989-4.6999979,1.4999981-5.3999987c0.4000015,1.5,1.7999992,5.2999983,2.5,6.6999998 c0,0,1.0999985,4.8999996,1.5,7c-0.2000008-0.1000004-0.5999985-0.2999992-0.5-0.1000004 c0.5999985,2.8999996,2.2000008,5.3000011,2.7999992,8.1999989c0,0.0999985,0.0999985,0.2000008,0.2000008,0.2999992 c-0.2000008,1.7000008-1.0999985,3.2000008-2.0999985,4.5999985c0.5999985-1.5,1-3.0999985,1-4.7000008 c0-0.2000008-0.2999992-0.5-0.5999985-0.7000008c-0.0999985-1.9000015-0.2999992-3.5999985-1.2999992-5.2000008 c-1.2000008-2.1000004-1.9000015-5.3999996-1.9000015-5.3999996c0-0.3999996-1.5-4.1999989-1.0999985-5.8999996 c0.0999985-0.5-0.2999992,0.7999992-0.2999992,1.2999992c-0.0999985,0-0.0999985,0-0.0999985,0.1000004 c0,1.2999992-0.5999985,5.6999989-0.5999985,5.8000011c-1.1999989,1.5-1.1999989,1.7999992-1.7999973,3.6000004 c-0.7000008,2.3000011-1,3.8000011-1.0999985,6.1999989c0,0.0999985,0,0.2000008,0.0999985,0.2999992 c-0.4000015,1.4000015,0.2000008,2.9000015,0.5,4.2999992c0,0.2000008,0.0999985,0.4000015,0.0999985,0.7000008 c-0.5999985-1.2999992-1-2.7000008-1.5-4.0999985C28.7467709,34.1736069,28.7467709,31.0736084,28.7467709,28.0736084z"
      />
      <radialGradient
        id="SVGID_10_"
        cx="-305.769"
        cy="120.488"
        r="16.697"
        gradientTransform="rotate(180 -136.461 76)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={colors[2]} stopOpacity="0.5" />
        <stop offset="1" stopColor={colors[3]} stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_10_)" cx="32.847" cy="31.474" r="16.7" />
      <g fill="#FFF">
        <path
          opacity="0.18"
          d="M33.3467712,48.1736069l-0.1000023-0.2999992 c1.2999992,0.7999992,2.5999985,1.5999985,3.7999992,2.4000015l0.0999985,0.4000015 C35.8467712,49.8736076,34.5467682,49.0736084,33.3467712,48.1736069z"
        />
        <path
          opacity="0.18"
          d="M33.746769,49.9736061v-0.0999985 c1.2000008,0.5999985,2.4000015,1.2000008,3.5999985,1.7000008l0.0999985,0.2999992 C36.1467705,51.2736092,34.9467697,50.6736069,33.746769,49.9736061z"
        />
        <path
          opacity="0.18"
          d="M32.746769,45.8736076l-0.5-2c0,0,1-0.9000015,3.5-0.7000008 l1.0999985,5.7999992C35.4467697,47.9736061,34.0467682,46.8736076,32.746769,45.8736076z"
        />
      </g>
      <g opacity="0.98">
        <path
          fill={colors[1]}
          d="M31.4467716,44.3736076c-1.2000008-3.5-1.4000015-6.5999985-0.5999985-9.5999985 c0.2999992-1.2000008,0.7999992-2.4000015,1.3999996-3.5999985c0.4000015-1,0.9000015-2.1000004,1.2000008-3.2000008 c0.0999985-0.2999992,0.2000008-0.6000004,0.2999992-1l0.2999992,0.1000004c-0.0999985,0.5,0,1,0,1.5 c0.2000008,1.3999996,0.9000015,2.6999989,2,3.8999996v0.0999985l-0.2999992,0.5999985l0.5,0.0999985 c0.2000008,0.7000008,0.4000015,1.4000015,0.5999985,2.0999985c0.0999985,0.4000015,0.2999992,0.9000015,0.4000015,1.4000015 c0.0999985,0.2999992,0.2000008,0.7000008,0.2000008,1.0999985c-0.2999992,1.2999992-0.7000008,2.5-1.0999985,3.7000008 c-0.2000008,0.5-0.4000015,0.9000015-0.5,1.4000015c-0.7999992,0.7000008-1.7000008,1.2999992-2.7000008,1.7999992 C32.5467682,44.8736076,31.9467716,44.6736069,31.4467716,44.3736076z"
        />
        <path
          fill={colors[6]}
          d="M33.1467705,45.0736084c0.7999992-0.4000015,1.5999985-0.9000015,2.2999992-1.5 c0.2000008-0.4000015,0.2999992-0.7999992,0.4000015-1.2000008c0.4000015-1.0999985,0.7999992-2.2999992,1.0999985-3.5 c-0.0999985-0.2999992-0.0999985-0.5999985-0.2000008-0.9000015c-0.0999985-0.5-0.2999992-0.9000015-0.4000015-1.2999992 c-0.2000008-0.5999985-0.4000015-1.2000008-0.5-1.9000015l-0.7999992-0.2000008l0.4000015-1 c-1-1.0999985-1.5999985-2.2999992-1.9000015-3.5c-0.2999992,0.7000008-0.5999985,1.4000015-0.9000015,2.0999985 c-0.5,1.2000008-0.9999981,2.4000015-1.2999973,3.5c-0.7999992,2.9000015-0.5999985,5.7000008,0.5,9.0999985 C32.3467712,44.8736076,32.746769,44.9736061,33.1467705,45.0736084z"
        />
        <path
          fill={colors[7]}
          d="M33.246769,45.0736084c0.7000008-0.4000015,1.2999992-0.7999992,1.9000015-1.2000008 c0.0999985-0.2999992,0.2000008-0.5999985,0.4000015-1c0.4000015-1,0.7000008-2.0999985,1-3.2999992 c0-0.2999992-0.0999985-0.5-0.2000008-0.7000008c-0.0999985-0.5-0.2999992-0.9000015-0.4000015-1.2999992 c-0.0999985-0.5-0.2999992-1-0.5-1.5999985l-1.0999985-0.2999992l0.5-1.2999992c-0.5-0.7000008-1-1.2999992-1.2999992-2.0999985 c-0.0999985,0.2000008-0.2000008,0.4000015-0.2999992,0.5999985c-0.5,1.2000008-1,2.2999992-1.2999973,3.4000015 c-0.7000008,2.7000008-0.5999985,5.4000015,0.3999996,8.5C32.746769,44.9736061,32.9467697,45.0736084,33.246769,45.0736084z"
        />
        <path
          fill={colors[8]}
          d="M33.246769,45.0736084c0.5-0.2999992,1-0.5999985,1.4000015-0.9000015 c0.0999985-0.2000008,0.2000008-0.5,0.2999992-0.7999992c0.4000015-1,0.7000008-2,1-3.0999985 c0-0.2000008-0.0999985-0.4000015-0.0999985-0.5c-0.0999985-0.4000015-0.2999992-0.9000015-0.4000015-1.2999992 c-0.0999985-0.4000015-0.2999992-0.9000015-0.4000015-1.2999992l-1.4000015-0.4000015l0.5999985-1.5 c-0.2000008-0.2999992-0.4000015-0.5999985-0.5999985-0.9000015c-0.4000015,1-0.7999992,1.9000015-1,2.7999992 c-0.6999989,2.5-0.5999985,5,0.2999992,7.9000015C33.1467705,45.0736084,33.1467705,45.0736084,33.246769,45.0736084z"
        />
        <path
          fill={colors[9]}
          d="M33.4467697,44.8736076c0.2999992-0.2000008,0.5999985-0.4000015,0.7999992-0.5 c0.0999985-0.2000008,0.0999985-0.4000015,0.2000008-0.5c0.2999992-0.9000015,0.5999985-1.7999992,0.9000015-2.7999992 c0-0.0999985-0.0999985-0.2000008-0.0999985-0.2999992c-0.0999985-0.4000015-0.2000008-0.9000015-0.4000015-1.2999992 c-0.0999985-0.2999992-0.2000008-0.7000008-0.2999992-1.0999985l-1.3000031-0.5000038 C32.6467705,40.0736084,32.746769,42.2736092,33.4467697,44.8736076z"
        />
        <path
          fill={colors[10]}
          d="M33.3467712,45.1736069c0-0.0999985,0.0999985-0.2000008,0.0999985-0.2999992 c0.2999992-0.7999992,0.5999985-1.7000008,0.7999992-2.5999985c0-0.0999985,0-0.0999985,0-0.2000008 c-0.0999985-0.4000015-0.2000008-0.9000015-0.4000015-1.2999992c-0.0999985-0.2999992-0.2000008-0.5-0.2000008-0.7999992 l-0.5999985-0.1000023C32.746769,41.4736061,32.8467712,43.2736092,33.3467712,45.1736069L33.3467712,45.1736069z"
        />
      </g>
    </g>
  )
}

export default TorchR
