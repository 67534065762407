import React from "react"

const DaggerLeft = ({
  colors = [
    "#6E5E26",
    "#99B7D9",
    "#6B91BF",
    "#57789B",
    "#E1E2E3",
    "#9EA0A3",
    "#ECD592"
  ]
}) => {
  return (
    <g id="DaggerLeft">
      <polygon
        fill={colors[0]}
        points="65.7810974,80.0861816 66.580574,81.4670868 64.327507,82.4846039 63.6733856,84.8830338 62.4378319,87.8629074 62.5831947,88.9531021 61.7837181,89.6799011 60.8388824,89.3891754 60.5481606,88.2989807 61.2022743,87.6448593 61.9290733,84.3742752 62.9465942,82.0485229 61.7110329,79.8681335 63.2373085,79.2140198"
      />
      <polygon
        fill={colors[1]}
        points="60.9115601,89.3164978 61.2022743,89.0257721 61.6383591,89.1711426 61.7110329,89.6072235"
      />
      <polygon
        fill={colors[2]}
        points="61.7837181,89.6072235 61.7110329,89.1711426 62.1471176,88.8077469 62.5831947,88.9531021"
      />
      <polygon
        fill={colors[1]}
        points="60.9115601,89.2438202 61.1295967,88.9531021 61.0569191,88.4443436 60.620842,88.2989807"
      />
      <polygon
        fill={colors[3]}
        points="61.2749596,87.7175446 61.4203186,88.0809479 62.0017509,88.2263031 62.3651505,88.0082626"
      />
      <polygon
        fill={colors[2]}
        points="60.6935196,88.2263031 61.0569191,88.3716583 61.347641,88.0809479 61.2749596,87.7175446"
      />
      <polygon
        fill={colors[3]}
        points="62.2197952,88.7350616 62.0744324,88.2989807 62.3651505,88.0809479 62.5105095,88.8804245"
      />
      <polygon
        fill={colors[2]}
        points="61.1295967,88.3716583 61.2749596,88.9531021 61.7110329,89.0984573 62.0744324,88.7350616 62.0017509,88.3716583 61.4203186,88.1536255"
      />
      <path
        fill={colors[4]}
        d="M65.8537827,81.1036835l-1.5989532-0.6541138l-1.7443199-0.3633957 c0,0,1.0175247-1.8170013,1.6716347-5.6690292c1.0175247-3.8520279,4.0700684-6.5411758,4.0700684-6.5411758l0,0 L65.8537827,81.1036835z"
      />
      <path
        fill={colors[5]}
        d="M64.2548294,80.4495697l3.9973831-12.5735931c0,0,0.5814438,4.5788269-0.7994766,8.2854919 c-1.1628723,2.5437851-1.6716385,5.0149002-1.6716385,5.0149002L64.2548294,80.4495697z"
      />
      <path
        fill={colors[6]}
        d="M63.0192719,86.2639542l-0.58144,1.4535904c-0.145359,0.0726776-0.3633995,0.0726776-0.5087585,0.0726776 l-0.1453552-0.0726776c-0.145359-0.0726776-0.2907219-0.1453552-0.3633995-0.2907181l0.5087547-2.5437927 C62.2197952,85.4644699,62.5105095,85.9005585,63.0192719,86.2639542z"
      />
      <path
        fill={colors[6]}
        d="M62.2924728,83.7928467l0.726799-1.6716385l0.5087509,0.7267914l0.726799-0.2907181l-0.5814362,2.1803894 l-0.5087624,1.1628799C62.6558723,85.4644699,62.3651505,84.4469604,62.2924728,83.7928467z"
      />
    </g>
  )
}

export default DaggerLeft
