import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Nose from "./Nose"
import Eyes from "./Eyes"
import Mouth from "./Mouth"
import Brows from "./Brows"

const CATEGORY = "face"
const CHARACTER = "ManHalfling"
export const IDS = {
  NOSE: "Nose",
  EYES: "Eyes",
  MOUTH: "Mouth",
  BROWS: "Brows"
}

export const components = {
  [IDS.NOSE]: Nose,
  [IDS.EYES]: Eyes,
  [IDS.MOUTH]: Mouth,
  [IDS.BROWS]: Brows
}

export const Group = ({ props }) => (
  <g id="face">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.NOSE,
    name: "Nose",
    defaultColors: ["#ED1C24", "#SVGID_", "#FFFFFF"],
    colorable: false,
    component: components[IDS.NOSE],
    inUI: true,
    enabled: true,
    subGroupId: 3
  },
  {
    id: IDS.EYES,
    name: "Eyes",
    defaultColors: ["#010101"],
    colorable: true,
    component: components[IDS.EYES],
    inUI: true,
    enabled: true,
    subGroupId: 2
  },
  {
    id: IDS.MOUTH,
    name: "Mouth",
    defaultColors: ["#010101"],
    colorable: false,
    component: components[IDS.MOUTH],
    inUI: true,
    enabled: true,
    subGroupId: 1
  },
  {
    id: IDS.BROWS,
    name: "Brows",
    defaultColors: ["#7C4F2E"],
    colorable: true,
    component: components[IDS.BROWS],
    inUI: true,
    enabled: true,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
