import React from "react"

const SunstaffL = ({
  colors = ["#654219", "#FFFFFF", "#282560", "#934520", "#BC8A2C", "#SVGID_"]
}) => {
  return (
    <g id="SunstaffL">
      <path
        fill={colors[0]}
        d="M89.2760162,48.7190018l-3.7903671,68.1685181l0.8202515,1.1985703l1.1248932-1.090416 l4.7580261-68.0260315C92.1004105,48.9647331,91.2458267,46.7886772,89.2760162,48.7190018z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M91.4171371,59.6581192l0.2212296-3.9787102 c-0.0834961-0.0933304-0.5108261-0.3831596-0.6974945-0.2161598c-0.5599899,0.5009995-1.4983063,3.0208092-2.3873749,3.0600624 l-0.1819,3.2713814C89.3736649,61.3182755,90.5771484,60.4096146,91.4171371,59.6581192z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M91.1664886,62.5709229l0.0786514-1.41465 c-0.4617386,0.3290825-0.9234848,0.6581612-1.3803101,0.8988266c-0.4568253,0.2406693-0.9087372,0.3929176-1.3606491,0.5451698 c-0.0884171-0.0049171-0.0933304,0.0834999-0.1817474,0.0785828l-0.1720657,3.0945473 C89.1868515,64.6780777,90.1300049,63.6662483,91.1664886,62.5709229z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M88.965477,71.8495789 c0.5698242-0.6778259,1.1347275-1.2672424,1.7045593-1.9450684l0.1179886-2.1219788 c-0.9480743,1.1002426-1.9796448,2.1071548-2.9227905,3.1189804l-0.0491638,0.8841553 C88.2483139,71.9870834,88.6019821,72.0067444,88.965477,71.8495789z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M90.96492,66.1959686l0.0589981-1.0609894 c-0.8399887,0.7514954-1.8617249,1.5815735-2.9522095,2.0530701l-0.1278152,2.2988129 C88.8919601,68.3866196,89.9284439,67.2912903,90.96492,66.1959686z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M89.9130936,80.3277359l0.1474838-2.6524734 c-0.8350754,0.6630783-1.7536469,1.2328262-2.7360458,1.3555756l-0.2261429,4.0671234 C88.1250381,82.1794662,88.9748535,81.2511444,89.9130936,80.3277359z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M88.3509521,82.9015427 c-0.3782425,0.4224167-0.844902,0.8399124-1.2231445,1.2623291l-0.0344162,0.6189041 c0.8841553,0.0491638,1.7978134-0.4321671,2.6378021-1.1836624l0.1179886-2.1219788 C89.3825226,81.8946381,88.8225327,82.3956375,88.3509521,82.9015427z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M88.3155594,104.2736435l0.1229019-2.2103958 c-0.736824,0.4911652-1.5620575,0.977417-2.372551,1.1984177l-0.1818924,3.2713776 C86.6405029,105.6882248,87.4755707,105.0251389,88.3155594,104.2736435z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M88.1239014,106.1254578l0.08358-1.503067 c-0.849823,0.9283295-1.6112213,1.8615723-2.4610443,2.7899017l-0.0344086,0.6189117 c0.3634949-0.1571655,0.6385651-0.319252,1.0020676-0.4764175 C87.274086,107.0537872,87.6572418,106.5429535,88.1239014,106.1254578z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M90.5471268,72.1148987L90.5471268,72.1148987 c-1.0315704,1.0069199-1.9747162,2.0187378-2.9227905,3.118988l-0.0098267,0.1768265 C88.5625763,74.3104782,89.510643,73.210228,90.5471268,72.1148987z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M87.496521,77.532692l-0.0294952,0.5304947 c1.183815-0.5550003,2.2939606-1.3801651,2.5739594-1.630661c0.0933304-0.0834961,0.1866608-0.1669998,0.2799988-0.2505035 l0.1081619-1.9451447C89.3926544,75.3322067,88.4445877,76.4324493,87.496521,77.532692z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M89.9130936,80.3277359l0.1474838-2.6524734 c-0.8350754,0.6630783-1.7536469,1.2328262-2.7360458,1.3555756l-0.2261429,4.0671234 C88.1250381,82.1794662,88.9748535,81.2511444,89.9130936,80.3277359z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M88.3509521,82.9015427 c-0.3782425,0.4224167-0.844902,0.8399124-1.2231445,1.2623291l-0.0344162,0.6189041 c0.8841553,0.0491638,1.7978134-0.4321671,2.6378021-1.1836624l0.1179886-2.1219788 C89.3825226,81.8946381,88.8225327,82.3956375,88.3509521,82.9015427z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M88.3155594,104.2736435l0.1229019-2.2103958 c-0.736824,0.4911652-1.5620575,0.977417-2.372551,1.1984177l-0.1818924,3.2713776 C86.6405029,105.6882248,87.4755707,105.0251389,88.3155594,104.2736435z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M88.1239014,106.1254578l0.08358-1.503067 c-0.849823,0.9283295-1.6112213,1.8615723-2.4610443,2.7899017l-0.0344086,0.6189117 c0.3634949-0.1571655,0.6385651-0.319252,1.0020676-0.4764175 C87.274086,107.0537872,87.6572418,106.5429535,88.1239014,106.1254578z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M87.9911652,108.5126877l0.0344162-0.6189117 c-0.2750854,0.1620865-0.5501556,0.324173-0.7319107,0.4027481c-0.2750778,0.1620865-0.6385727,0.319252-0.9087372,0.3929214 c-0.1915741,0.2554169-0.4666595,0.4174957-0.6582336,0.6729126l-0.0639114,1.1494064 C86.4094467,109.8437576,87.2445068,109.1806793,87.9911652,108.5126877z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M85.5792084,112.0148239l-0.0934067,1.6799011 c0.3733292-0.3339996,0.741745-0.5795746,1.1150665-0.9135742c0.2849121-0.3389206,0.6582336-0.6729202,0.8547363-1.0167465 c0.0933304-0.0834961,0.1915741-0.2554169,0.2898331-0.42733l0.103241-1.8567276 C87.180603,110.3300858,86.335701,111.1699982,85.5792084,112.0148239z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M87.5937881,98.1139679 c-0.4863205,0.7711563-0.8842316,1.5472336-1.3656387,2.2299805l-0.0737457,1.3262329 c0.8350754-0.6630859,1.6652298-1.2377472,2.5002975-1.9008255l0.1179886-2.1219788 C88.4091949,97.8045502,87.957283,97.9568024,87.5937881,98.1139679z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M86.5673599,94.2432632l-0.1130676,2.0335693 l2.5936279-1.9843292l0.2752304-3.3548813C88.2866669,92.0329514,87.4270172,93.1381073,86.5673599,94.2432632z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M88.3360519,86.3595963 c0.4715805-0.5059128,0.8596573-1.1051636,1.3312302-1.6110764l0.0196686-0.3536682 c-0.7368317,0.4911652-1.6406479,0.7956696-2.6181412,0.8300095l-0.1720657,3.0945435 C87.3781509,87.6366653,87.8595657,86.9539185,88.3360519,86.3595963z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M89.3773041,88.3686447l0.0983276-1.7683105 c-0.8547363,1.0167389-1.7929764,1.940155-2.7361298,2.9519806l-0.0688248,1.2378235 c0.5452423-0.2357559,1.0069885-0.5648346,1.5571442-0.8889999 C88.6109848,89.3903122,88.9941406,88.8794785,89.3773041,88.3686447z"
      />
      <path
        fill={colors[0]}
        d="M83.2985458,39.7837944c0.0049133-0.0884171,0.1817474-0.0785828,0.446991-0.0638351 c0.8841629,0.04916,3.0012207,0.2555656,4.2882767-2.1561661c1.5867157-3.015892,1.9797897-5.2999535,3.6203537-4.499218 c1.6405716,0.8007317,5.3834534,2.0731163,5.2556305,4.3719254s-2.5594406,6.1546135-3.6402435,9.6420784 c-1.0808029,3.4874687-1.44944,8.5222473-1.44944,8.5222473l-3.2418823-0.7123909l-1.0311127-8.5714836 c0,0,1.832222-1.0510788,3.5023651-2.3772392c1.6701508-1.3261604,0.7958908-3.1485558-2.6867294-2.7213745 C84.875206,41.7339287,83.2985458,39.7837944,83.2985458,39.7837944z"
      />
      <path
        opacity="0.3"
        fill={colors[2]}
        d="M88.4462509,41.3116684 c3.5710373-0.4222641,4.3568726,1.3952141,2.6867294,2.7213707c-1.6701431,1.3261604-3.5023727,2.377243-3.5023727,2.377243 l1.0311127,8.5714836l0.0884171,0.0049171c0.0049133-0.0884132,0.0098343-0.1768341,0.0147476-0.2652473 c0.0346375-5.4081078,3.6303329-7.8688507,3.8219223-8.1242599c6.6315536-7.6132851,0.1872635-12.9381943-3.6491699-9.3378792 c-3.5319366,4.5041351-5.5457611,2.4410019-5.5457611,2.4410019S84.875206,41.7339287,88.4462509,41.3116684z"
      />
      <radialGradient
        id="SVGID_4_"
        cx="358.27"
        cy="-26.192"
        r="3.51"
        gradientTransform="matrix(-.05552 .99846 -1.99692 -.11103 58.841 -320.384)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={colors[3]} />
        <stop offset="0.824" stopColor="#bc8a2c" />
      </radialGradient>
      <radialGradient
        id="SVGID_5_"
        cx="512.923"
        cy="322.555"
        r="1.39"
        gradientTransform="rotate(48.183 620.254 -290.714) scale(.99999)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="SVGID_6_"
        cx="285.959"
        cy="170.91"
        r="2.062"
        gradientTransform="matrix(1.06279 1.18794 -1.4546 1.30135 35.604 -519.326)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_4_)" cx="91.256" cy="40.226" r="3.542" />
      <circle
        opacity="0.85"
        fill="url(#SVGID_5_)"
        cx="91.634"
        cy="38.207"
        r="1.417"
      />
      <path
        opacity="0.42"
        fill="url(#SVGID_6_)"
        d="M93.1075516,45.2071037 c-1.6799774,1.5029907-3.9886169,1.552002-5.1576157,0.2453537c-1.1689911-1.3066483-0.8594208-3.6840363,0.8156357-5.0986137 c1.6750488-1.4145775,3.9886169-1.552002,5.157608-0.2453575 C95.0872574,41.5035477,94.7826004,43.7925262,93.1075516,45.2071037z"
      />
      <g opacity="0.25" fill="#FFF">
        <path d="M93.1025543,46.8919144c0,0-2.0582886,3.5218048-2.1616821,8.5713348l0.874321,0.2259941 c0,0,0.3637238-4.9463654,1.44944-8.5222511c0.1031647-0.2603302,0.2063293-0.5206604,0.221077-0.7859077 C93.3874664,46.5530014,93.2008057,46.7200012,93.1025543,46.8919144z" />
        <path d="M87.3362503,38.9440308c1.0118942-0.653244,1.3705521-2.3183975,2.6084442-3.8459702 c2.5740356-3.2270603,6.017189,0.2458839,6.6752701,2.76577c0.0785828,0.1817474,0.0736694,0.2701645,0.0638351,0.4469948 c0.108078-0.3487473,0.1277466-0.7024078,0.1424942-0.9676552c0.1229019-2.2103958-3.6199799-3.4827805-5.2556305-4.3719292 c-1.6405716-0.8007317-2.1220551,1.4784126-3.6203537,4.499218c-1.1937256,2.3282318-3.3157043,2.2102432-4.2882767,2.1561661 C84.0107803,39.7347069,85.7643509,40.098278,87.3362503,38.9440308z" />
        <path d="M83.3820419,39.8771248l0.2750854-0.1620827 c-0.1768341-0.0098343-0.3536682-0.0196648-0.3585815,0.0687485 C83.2985458,39.7837944,83.2985458,39.7837944,83.3820419,39.8771248z" />
      </g>
    </g>
  )
}

export default SunstaffL
