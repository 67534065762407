import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WideHorns = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WideHorns") ? (
    <g id="WideHorns">
      <path d="M69.1999969,20.8999996c-7.5999985,0-11-3.2000008-12.5999985-5.8999996 C56,14,55.1999969,13.1000004,55.1999969,13.1000004l0,0c0,0,0,0,0.0999985,0.1000004l-0.0999985-0.1000004l-1.7999992,0.6000004 c-0.5-1-2.0999985-4.3999996-0.9000015-8.1999998c0,0,2.7000008-4.5999999,5-5.8000002L59.2999992-1c0.5-0.1,1-0.1,1.5999985-0.1 c0.0999985,0,0.2000008,0,0.4000015,0C64.9000015-1,67.5,1.1,69.3000031,3.2000003 c1.8000031-2,4.4000015-4.3000002,8.1999969-4.3000002c0.5,0,1.0999985,0,1.5999985,0.1l1.4000015,0.4 c1.1999969,0.5,2.3000031,1.2,3.1999969,2.2c1,1,1.8000031,2.3,2.1999969,3.7L86,5.4000001 c1.1999969,3.7999997-0.4000015,7.3000007-0.9000015,8.2000008L83.3000031,13l0,0l0,0l-1.0999985,1.1000004 C82.6000061,13.8000002,82.8000031,13.5,82.8000031,13.5C82.7000046,13.6000004,81.5,15.6000004,81.5,15.6000004 C78.9000015,19.1000004,74.5999985,20.8999996,69.1999969,20.8999996z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WideHorns, {
  character: "WomanMixed"
})
