import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GoldAxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GoldAxeLeft") ? (
    <g id="GoldAxeLeft">
      <path
        id="S_x24_goldAxeLeft"
        d="M75.2114334,115.5493317l-3.4236908-1.5083237 c-0.4704361-0.4242477-0.9006271-1.0081711-1.2588882-1.607933l-0.3259125-4.904953 c0.6096573-1.3407059,2.5593872-5.8418503,3.676445-9.3302002c0.7634048-2.3548279,2.7428436-9.8032837,4.6503601-17.2359161 l-1.9965515-0.1636429c-2.7176971-3.0964508-3.6202774-7.1957855-2.5348892-10.82798l0,0 c1.1573105-3.6480255,4.150177-6.493721,8.1300888-7.5962257l2.6570053,1.4509163l3.8908386-0.4796562l0.3193512,0.0804977 l0.015831,0.0719185l0.143837-0.0316696l0.4948425,0.1926651l1.947731,0.6268234l2.8945236,2.529686l3.1544952,0.2857132 c2.7176971,3.0964508,3.6202774,7.1957932,2.5348892,10.82798l0,0c-1.0853882,3.6321869-4.1501694,6.493721-8.0581741,7.5803909 l-1.6198044-0.8498306l-7.4492722,27.1269455l-5.2553253,3.8716736L75.2114334,115.5493317z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GoldAxeLeft, {
  character: "WomanGnome"
})
