import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SlingShotR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SlingShotR") ? (
    <g id="SlingShotR">
      <path d="M31.2000008,100.6999969c2,0,4-0.5999985,4.7999992-0.9000015l2.5999985-3l0.2999992,0.3000031 C40.1999969,95.9000015,40.9999962,94.5,41.1999969,94l0.4000015-5.6999969c0-0.0999985-0.2000008-0.5-0.4000015-1 l-0.2000008-15.9000015l6.4000015-2.6999969l0.5-4.5999985l-1.0999985-10l-4.0999985-5.5999985L36.5,45.6000061 l-7.7999992,1.2000008C28.1000023,47.4000053,26,49.4000053,24.9000015,52.6000061l-0.2000008,0.7000008 c-0.2999992,1.0999985-0.5,2.2000008-0.3999996,3.2999992c-0.1000004,1.0999985-0.3999996,6.8000031-1.8999996,30.3000031 c-0.7000008,1.9000015-0.7000008,4.0999985,0,6.8000031l0.3999996,0.4000015 c1.1000004,3.9000015,3.5000019,5.5,5.3000011,6.0999985C29.0999985,100.5,30.2000008,100.6999969,31.2000008,100.6999969 L31.2000008,100.6999969z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SlingShotR, {
  character: "ManDragonborn"
})
