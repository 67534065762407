import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const AxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("AxeRight") ? (
    <g id="AxeRight">
      <path
        id="S_x24_axeRight"
        d="M38.9115334,116.2233276l2.1803932-1.0175171l2.7618256-5.8143768l-5.669014-25.0018234h1.526268 c2.8345146-2.6891556,4.0700722-6.4685059,3.4159508-9.9571304l0,0c-0.726799-3.5613098-3.2705879-6.5411758-6.9772568-7.9220886 l-2.6891479,1.1628723l-3.5613098-0.8721542l-2.7618313,0.5087585l-3.0525475,2.1077118l-2.8345108-0.0726776 c-2.8345089,2.6891479-4.0700607,6.4684982-3.4159431,9.9571304v0.07267 c0.7267952,3.5613098,3.2705879,6.4684982,6.977253,7.9220963l1.5262718-0.6541214 c1.5989513,6.8318939,3.1979122,13.518425,3.5613079,15.7714996c0.5814419,3.2705917,0.9448414,7.8494186,1.0175171,9.1576462 l2.5437927,4.0700607c0.58144,0.3634033,1.1628723,0.5814438,1.8169899,0.7994766L38.9115334,116.2233276z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(AxeRight, { character: "ManGnome" })
