import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Antlers = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Antlers") ? (
    <g id="Antlers">
      <path d="M93.4000015,2.9000001C92.8000031,2.2,91.5999985-1.9000001,91.5-2.9000001 c-0.1999969-1.4000001-0.6999969-2.7999997-1.4000015-4c-1.1999969-2.0999999-9.9000015-5.3000007-12-3.9000001 C77.1999969-10.1999998,70.4000015-4.5,69.2999954-4.5c-0.9000015,0-9.0999985-5.8999996-9.7000008-6.3000002 C57.2000008-12.3999996,49.2999992-9.1000004,48.2000008-7C47.5-5.8000002,47.1000023-4.5999999,46.7999992-3.2 c-0.0999985,0.8-0.2000008,1.6-0.2000008,2.4000001c0,0.6-1.2000008,3-1.7999992,3.7 c-0.7999992,0.9000001-2.7000008,4.5-1.5,6.4999995C43.8999977,10.5,45,11.0999994,45.7000008,12.0999994 c0.5999985,0.8000002,0.9000015,1.8000002,1.5999985,2.6999998C50,18.1999989,59.4000015,19,59.7999992,18.8999996 c0.7999992-0.1000004,22.0000038,0,22.2999992,0C84.6999969,18.6999989,87.4000015,18,89.5,16.2999992 c0.8000031-0.6999998,1.5999985-1.5,2.1999969-2.3999996c0.4000015-0.6000004,0.5999985-1.3000002,1-1.8000002 s1-0.8000002,1.4000015-1.3000002C95.5999985,8.8000002,94.1999969,3.8,93.4000015,2.9000001z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Antlers, { character: "WomanMixed" })
