import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FlameRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FlameRight") ? (
    <g id="FlameRight">
      <path d="M53.2439117,57.6721001c0,0-11.6107597,7.2607536-11.0522385,18.082058 c0.1396294,4.1889038-0.4188919,12.0779877,11.1703835,12.1477966 C74.6599731,83.2810822,53.2439117,57.6721001,53.2439117,57.6721001z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FlameRight, {
  character: "WomanGnome"
})
