import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WideHorns = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WideHorns") ? (
    <g id="WideHorns">
      <path d="M68.2614594,15.842804c-8.8915138,0-12.8693008-3.7437963-14.7411957-6.9026222 c-0.7019615-1.1699367-1.6379128-2.2228785-1.6379128-2.2228785l0,0c0,0,0,0,0.116993,0.1169939l-0.116993-0.1169939 l-2.1058846,0.701962c-0.5849686-1.1699362-2.4568634-5.1477184-1.0529442-9.5934753c0,0,3.1588287-5.3817062,5.8496819-6.7856293 l2.1058884-0.8189564c0.5849686-0.1169939,1.1699333-0.1169939,1.8718948-0.1169939c0.116993,0,0.2339897,0,0.4679756,0 c4.2117729,0.1169939,7.2536049,2.5738597,9.3594933,5.0307255c2.1058884-2.3398719,5.1477203-5.0307255,9.5934753-5.0307255 c0.5849686,0,1.2869263,0,1.8718948,0.1169939l1.6379089,0.4679747c1.403923,0.5849676,2.6908569,1.403923,3.7437973,2.5738592 c1.1699371,1.1699362,2.1058884,2.6908531,2.5738525,4.328764l0.1170044,0.1169934 c1.4039154,4.4457569-0.4679794,8.540534-1.052948,9.5934763l-2.1058807-0.7019625l0,0l0,0l-1.2869263,1.2869306 c0.4679794-0.3509812,0.7019577-0.7019625,0.7019577-0.7019625c-0.1169891,0.1169944-1.5209198,2.4568663-1.5209198,2.4568663 C79.6098404,13.7369194,74.5791168,15.842804,68.2614594,15.842804z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WideHorns, {
  character: "ManDragonborn"
})
