import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SlingShotR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SlingShotR") ? (
    <g id="SlingShotR">
      <path d="M37.2,101.7c2,0,4-0.6,4.8-0.9l2.6-3l0.3,0.3c1.3-1.2,2.1-2.6,2.3-3.1l0.4-5.7c0-0.1-0.2-0.5-0.4-1l-0.2-15.9l6.4-2.7 l0.5-4.6l-1.1-10l-4.1-5.6l-6.2-2.9l-7.8,1.2c-0.6,0.6-2.7,2.6-3.8,5.8l-0.2,0.7c-0.3,1.1-0.5,2.2-0.4,3.3 c-0.1,1.1-0.4,6.8-1.9,30.3c-0.7,1.9-0.7,4.1,0,6.8l0.4,0.4c1.1,3.9,3.5,5.5,5.3,6.1C35.1,101.5,36.2,101.7,37.2,101.7L37.2,101.7 z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SlingShotR, {
  character: "ManMixed"
})
