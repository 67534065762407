import React from "react"

const WizardHat = ({ colors = ["#999999", "#FFFFFF", "#9E9E9E"] }) => {
  return (
    <g id="WizardHat">
      <path
        opacity="0.42"
        fill="#21376C"
        d="M60.4091797,42.9854279 c-2.9096069-0.6379395-7.3011475-0.9376221-12.1983032-0.729248c-5.2611084,0.223938-9.881897,0.9836426-12.6916504,1.9643555 c0.4817505,0.9874268,0.6745605,2.2297363,1.1781006,3.1868896c0.1879883,0.3572388,0.3406372,0.7184448,0.491333,1.0802612 c2.4690552,0.4425049,5.7702026,0.6705933,9.4500732,0.6086426c0.2173462-0.1655884,0.4609985-0.2816772,0.7209473-0.3528442 c0.3165283-0.1795044,0.6760254-0.2719727,1.1054688-0.2149658c0.1571655,0.020874,0.3076782,0.0612183,0.4542847,0.112793 c0.1254272,0.0178833,0.2505493,0.0350952,0.3840332,0.0755615c0.2028198,0.0615234,0.4047852,0.1564331,0.6068115,0.2456055 c4.2164307-0.2615356,7.9405518-0.8584595,10.5030518-1.6295166c-0.006958-0.2061768-0.0192871-0.4119873-0.0214233-0.6184082 C60.3789062,45.4635773,60.4673462,44.2271881,60.4091797,42.9854279z"
      />
      <path
        fill="#999"
        d="M78.3987656,40.6119843c-0.1126709,0,0-0.1122131,0.2253418-0.1122131 c-0.7887115-0.6733093-5.9716949,0-14.9855652,0.5610886c-1.9154472,0.1122169-11.2673416,0.7855301-13.7461548,1.1221848 c-3.7182236,0.3366508-8.4505081,0.6733055-12.1687279,0.8977432c-4.0562439,0.2244377-6.8730774,0.2244377-10.8166504,0.7855301 c-2.5914879,0.3366508-5.9716911,1.009964-8.3378315,1.7954865c-0.3380203,0.2244377-0.5633678,0.3366547-0.5633678,0.5610924 c0,0.1122169,0.1126747,0.2244377,0.2253475,0.2244377l0,0c0.3380203,0,0.7887154-0.1122169,1.1267357-0.1122169 c0.1126728,0,0.2253475,0,0.3380184,0c0.7887154-0.1122169,2.3661423-0.3366547,2.7041626,0 c0.1126728,0.1122169-0.5633678,0.1122169-0.5633678,0.2244377c-0.6760406,0-1.3520813,0-2.02812,0 c2.5914879,0.3366547,7.7744637,0.3366547,12.9574432,0.5610924c4.8449554-0.1122169,10.5913048-0.4488754,16.6756668-0.897747 c3.3801994-0.2244377,6.5350571-0.6733055,9.57724-0.8977432c1.9154472-0.2244377,3.8308983-0.5610924,5.5209961-0.7855301 l8.7885284-2.8054543l5.4083252-0.7855301v-0.1122169C78.6241074,40.7242012,78.5114365,40.7242012,78.3987656,40.6119843z M22.9634399,45.2129326c-0.7887154,0.1122169,0.9013863-0.3366547,1.2394085-0.3366547 C24.99156,44.764061,23.3014603,45.1007156,22.9634399,45.2129326z"
      />
      <path
        opacity="0.66"
        fill="#FFF"
        d="M78.3987656,40.6119843 c-0.1126709,0,0-0.1122131,0.2253418-0.1122131c-0.7887115-0.6733093-5.9716949,0-14.9855652,0.5610886 c-1.9154472,0.1122169-11.2673416,0.7855301-13.7461548,1.1221848c-3.7182236,0.3366508-8.4505081,0.6733055-12.1687279,0.8977432 c-4.0562439,0.2244377-6.8730774,0.2244377-10.8166504,0.7855301c-2.5914879,0.3366508-5.9716911,1.009964-8.3378315,1.7954865 c-0.3380203,0.2244377-0.5633678,0.3366547-0.5633678,0.5610924c0,0.1122169,0.1126747,0.2244377,0.2253475,0.2244377l0,0 c0.3380203,0,0.7887154-0.1122169,1.1267357-0.1122169c0.1126728,0,0.2253475,0,0.3380184,0 c0.7887154-0.1122169,2.3661423-0.3366547,2.7041626,0c0.1126728,0.1122169-0.5633678,0.1122169-0.5633678,0.2244377 c-0.6760406,0-1.3520813,0-2.02812,0c2.5914879,0.3366547,7.7744637,0.3366547,12.9574432,0.5610924 c4.8449554-0.1122169,10.5913048-0.4488754,16.6756668-0.897747c3.3801994-0.2244377,6.5350571-0.6733055,9.57724-0.8977432 c1.9154472-0.2244377,3.8308983-0.5610924,5.5209961-0.7855301l8.7885284-2.8054543l5.4083252-0.7855301v-0.1122169 C78.6241074,40.7242012,78.5114365,40.7242012,78.3987656,40.6119843z M22.9634399,45.2129326 c-0.7887154,0.1122169,0.9013863-0.3366547,1.2394085-0.3366547C24.99156,44.764061,23.3014603,45.1007156,22.9634399,45.2129326z"
      />
      <path
        fill="#999"
        d="M56.9908104,42.6319122l-6.6477318-12.2317848c-0.1126747,0-0.2253494,0-0.2253494-0.1122189 c-0.1126709-0.1122189-0.2253494-0.3366547-0.3380203-0.4488716c-0.1126709-0.1122189,0-0.1122189,0-0.2244377 c0.1126709-0.1122189,0.2253494-0.1122189,0.3380203-0.2244377c0,0,0,0,0-0.1122189c-0.1126709,0-0.2253494,0-0.3380203,0 c0,0-0.450695,0-0.3380203-0.1122189c0.2253494-0.2244377,0.3380203-0.4488716,0.4506989-0.5610905l-2.478817-9.987421 l-5.6336708,12.9050941c0.1126709,0,0.2253456,0,0.2253456,0.1122189s-0.2253456,0.2244377-0.450695,0.2244377v0.1122189 v0.1122189c0.1126747,0,0.1126747,0,0.2253494,0c0,0,0.450695,0,0.3380203,0.1122169 c-0.2253494,0.2244377-0.3380203,0.3366547-0.5633698,0.5610924l-1.5774269,10.88517 c-0.1126747,0.1122169-0.2253494,0.2244377-0.2253494,0.3366508c0.1126709,0.897747,4.0562401,1.0099678,8.9012032,0.6733093 c4.8449554-0.3366547,8.6758537-0.8977432,8.5631752-1.907711C57.1034851,42.8563499,56.9908104,42.7441292,56.9908104,42.6319122 z"
      />
      <path
        fill={colors[2]}
        d="M75.2439041,40.7242012c-4.957634,2.6932411-17.126358,4.6009483-18.591114,4.7131691 c8.6758537-0.5610924,17.5770531-2.8054581,19.605175-3.7032013c0.7887115-0.3366547,1.6901016-0.6733093,2.2534714-0.897747 c0.1126709,0,0.1126709-0.1122169,0.2253418-0.1122169c-0.1126709,0-0.3380203,0-0.4506989-0.1122131 c-0.2253418,0,0.4506989-0.2244377,0.5633698-0.2244377s0.338028,0,0.4506989-0.1122169 C80.314209,39.826458,80.8775711,39.0409279,75.2439041,40.7242012z M75.8072739,40.9486389 c-0.7887115,0.1122169,0.9013901-0.3366547,1.2394028-0.3366547 C77.8353958,40.6119843,76.1452942,40.9486389,75.8072739,40.9486389z"
      />
      <path
        opacity="0.69"
        fill="#21376C"
        d="M75.2439041,40.7242012 c-4.957634,2.6932411-17.126358,4.6009483-18.591114,4.7131691c8.6758537-0.5610924,17.5770531-2.8054581,19.605175-3.7032013 c0.7887115-0.3366547,1.6901016-0.6733093,2.2534714-0.897747c0.1126709,0,0.1126709-0.1122169,0.2253418-0.1122169 c-0.1126709,0-0.3380203,0-0.4506989-0.1122131c-0.2253418,0,0.4506989-0.2244377,0.5633698-0.2244377 s0.338028,0,0.4506989-0.1122169C80.314209,39.826458,80.8775711,39.0409279,75.2439041,40.7242012z M75.8072739,40.9486389 c-0.7887115,0.1122169,0.9013901-0.3366547,1.2394028-0.3366547 C77.8353958,40.6119843,76.1452942,40.9486389,75.8072739,40.9486389z"
      />
    </g>
  )
}

export default WizardHat
