import React from "react"

const StrapR = ({ colors = ["#573B25"] }) => {
  return (
    <g id="StrapR">
      <path
        fill={colors[0]}
        d="M48.4461632,48.2046013l-0.3541641,0.7082825 c-0.0026894-0.0039749,2.8661118-1.0770073,4.5776367,2.028511c-0.1546822-0.1950836-0.3565865-0.449749-0.6347847-0.8008003 c-2.2192955-1.6645088-4.2025681-0.6610527-4.2025681-0.6610527l-0.4957924,1.0978546 c0.0433578-0.0221367,0.1076126-0.0464745,0.1813812-0.0711899c-0.0878983,0.2020683-0.2104492,0.4663506-0.3112411,0.6142082 c0.6912498-0.3548126,1.5215988-0.1092834,2.2871017,0.4169731c-0.6387596-0.2326927-1.4850655-0.1701012-2.6058083,0.5037956 l-0.3659859,0.8736c0,0,3.2699776-1.4874268,4.1789818,2.0658531c0.2243118-0.3187637,0.6138306-0.8381386,0.6138306-0.8381386 s-0.1133118-0.6336021-0.457325-1.2904129c0.2781982,0.360775,0.514328,0.7381516,0.681633,1.1015091 c0.2360764-0.2360764,0.6031914-0.8425484,0.6610565-0.9444122c-0.6100693-1.4380531-1.670208-2.2220917-2.4048691-2.6247749 c1.0448265,0.198307,2.1223183,0.8109016,2.6055374,2.3296547l0.6195793-1.035965 c0.0132179,0.0324516,0.0285301,0.0584564,0.0415306,0.0916061c0.3791504-0.6195793,0.4924622-0.6233406,0.7082825-1.2867584 C52.2945671,46.6227226,48.4461632,48.2046013,48.4461632,48.2046013z"
      />
    </g>
  )
}

export default StrapR
