import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaceL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaceL") ? (
    <g id="MaceL">
      <path
        id="S_x24_maceL_1_"
        d="M77.8459396,72.0187225l-2.1803894,3.706665 c-2.180397,3.7793427-9.0070267,23.5701828-12.786377,21.3897934l-7.6213989-3.4338303 c-3.7793427-2.1803894,3.6822281-19.4198685,5.8626175-23.1992188c0,0,2.093792-15.6615639,12.2194061-10.3102074 l1.5989532,0.9448357C78.7180939,63.3698311,80.026329,68.2393799,77.8459396,72.0187225z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaceL, { character: "ManGnome" })
