import React from "react"
import { withSkinColor } from "../../builderConnector"

const Body = ({ skinColor, color = [] }) => {
  return (
    <g id="Body">
      <path
        id="body_1_"
        fill={skinColor}
        d="M46.558567,89.3286743c0.5953674,0,1.3608322,0,2.1263046,0 c0.7654686,0,1.5309372,0,2.1263008,0c0,0,1.6228943,7.1244049,3.0618782,24.6441956 c-0.5103111,1.0292816-1.4458885,5.3335495-0.6804161,5.427124l0,0c3.1469269,0,5.5283852,0,8.675312,0 c0.0850525-0.0935669-0.2551537-4.1171265-2.4665108-5.5206909c0.2551537-2.713562,1.3608322-7.7664032,1.3608322-10.1992493 s-1.190731-3.0878448-1.3608322-5.239975c1.0206261-3.3685532,0.166172-18.33992-0.1740379-20.0241928 c0.0850525-1.9649963,0.2311745-8.1474762,0.9966469-10.5803223c0.7654686-2.3392792,5.3862076-8.4146004,1.8990669-8.7888832 c-3.1469269-0.3742867-4.5077591-0.8421402-8.4201622-1.4971352v-1.6842766c0-1.3099976-1.5309372-2.3392792-3.4020844-2.4328461 l0,0h-0.34021H47.238987h-0.34021l0,0c-1.9561996,0.0935669-3.4020844,1.1228523-3.4020844,2.4328461v1.6842766 c-3.9123993,0.6549988-5.2732353,1.1228523-8.4201584,1.4971352c-3.5721912,0.4678535,1.2426987,6.4227867,2.0081635,8.7620583 c0.7654724,2.3392792,1.5433998,8.548584,1.6284485,10.6071472c-0.2551537,1.7778473-1.7654686,16.6556396-0.7448425,20.0241928 c-0.170105,2.0585632-1.4458885,2.8071289-1.3608322,5.239975c0.0850525,2.4328461,1.1056747,7.5792542,1.3608322,10.1992493 c-2.2113533,1.4035645-2.5515633,5.427124-2.4665146,5.5206909c3.1469307,0,5.528389,0,8.6753197,0l0,0 c0.7654686-0.0935669-0.170105-4.3978348-0.6804161-5.427124C44.7724724,98.5336456,46.558567,89.3286743,46.558567,89.3286743z"
      />
      <path
        id="neckShadow"
        opacity="0.42"
        fill="#21376C"
        d="M55.9993591,57.9238281 c-0.6804161-0.0935707-1.4458885-0.2807121-2.1263046-0.3742867v-2.4328461c-0.9355698,0-9.185627-0.0935707-10.6315155-0.2807121 v2.713562c-0.4252586,0.0935707-1.0206261,0.1871414-1.5309372,0.2807121 c1.190731,1.9649887,3.4020844,3.4621277,5.8685989,3.4621277h1.3608322h0.0850487h1.2757835 C52.6823235,61.2923813,54.8086281,59.7952461,55.9993591,57.9238281z"
      />
      <path
        fill="#AAA59B"
        d="M38.0577393,83.1506348h21.6985893c0,0,0.0938759,3.2185822,0.2011604,3.3258667 c0.1072884,0.1072845-8.408535,2.6016846-9.1729507,3.2856369c-0.7644119,0.6839447-2.9369507,0.6839447-4.1036873,0 c-1.1667366-0.6839523-8.8510933-3.258812-8.8510933-3.258812L38.0577393,83.1506348z"
      />
      <g fill="#FF561E">
        <circle opacity="0.1" cx="41.242" cy="69.954" r="0.536" />
        <circle opacity="0.1" cx="55.94" cy="69.954" r="0.536" />
      </g>
    </g>
  )
}

export default withSkinColor(Body, { character: "ManHalfling" })
