import React from "react"

const CodPiece = ({
  colors = [
    "#964922",
    "#FFFFFF",
    "#21376C",
    "#B3B3B3",
    "#9D9D9D",
    "#CCCCCC",
    "#4D4D4D",
    "#36479C",
    "#304B60"
  ]
}) => {
  return (
    <g id="CodPiece">
      <polygon
        opacity="0.3"
        fill="#262261"
        points="82.7576447,82.4106216 79.1667862,65.5612259 71.2945328,68.5996475 65.0795975,68.8758621 65.0795975,69.0139694 65.0795975,68.7377548 56.7930107,65.5612259 53.2021599,82.4106216 61.6268501,84.3441467 59.969532,95.6691513 68.1180038,88.6255569 76.4045868,95.6691513 74.8853836,84.2060394"
      />
      <polygon
        fill={colors[0]}
        points="61.4887428,55.2029953 53.2021599,80.4770813 62.8698425,82.6868362 65.0795975,58.5176315"
      />
      <polygon
        fill={colors[0]}
        points="75.2997131,55.3411102 82.7576447,80.4770813 73.0899658,82.6868362 70.8801956,58.5176315"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="61.4887428,55.2029953 53.2021599,80.4770813 62.8698425,82.6868362 65.0795975,58.5176315"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="75.2997131,55.3411102 82.7576447,80.4770813 73.0899658,82.6868362 70.8801956,58.5176315"
      />
      <polygon
        fill={colors[0]}
        points="76.5427094,93.735611 71.4326401,58.3795242 65.0795975,58.6557388 60.1076469,93.735611 68.1180038,86.6920166"
      />
      <path
        fill={colors[0]}
        d="M77.5094681,77.8529968l-2.2097549-19.197258 c-0.2762146-1.6573181,1.5192108-4.8338394-1.2429886-4.8338394h-11.186882 c-2.6240883,0-0.8286591,3.1765213-1.1048851,4.8338394l-2.2097511,19.197258c0,0,4.0051842,0.9667587,8.8390274,0.9667587 S77.5094681,77.8529968,77.5094681,77.8529968z"
      />
      <path
        opacity="0.3"
        fill={colors[2]}
        d="M77.5094681,77.8529968l-2.2097549-19.197258 c-0.2762146-1.6573181,1.5192108-4.8338394-1.2429886-4.8338394h-11.186882 c-2.6240883,0-0.8286591,3.1765213-1.1048851,4.8338394l-2.2097511,19.197258c0,0,4.0051842,0.9667587,8.8390274,0.9667587 S77.5094681,77.8529968,77.5094681,77.8529968z"
      />
      <g>
        <polygon
          fill={colors[3]}
          points="69.9343033,74.04673 69.9343033,74.8936234 69.9343033,74.8936234"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,73.9257431 69.9343033,74.04673 69.9343033,74.8936234 70.0552826,74.8936234 70.0552826,74.04673"
        />
        <polygon
          fill="#CCC"
          points="70.0552826,74.8936234 70.9021912,74.8936234 70.0552826,74.04673"
        />
        <polygon
          fill={colors[4]}
          points="69.2083893,74.8936234 69.9343033,74.8936234 69.9343033,74.04673"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,73.8047638 70.1762695,73.8047638 71.0231705,74.6516571 71.0231705,74.772644 71.1441574,74.6516571"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,73.8047638 70.1762695,73.8047638 71.0231705,74.6516571 71.0231705,74.772644 71.1441574,74.6516571"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.6516571 71.0231705,74.772644 71.0231705,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.6516571 71.0231705,74.772644 71.0231705,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.6516571 71.0231705,74.772644 71.0231705,74.772644"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,73.9257431 71.0231705,74.6516571 70.1762695,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,73.9257431 71.0231705,74.6516571 70.1762695,73.8047638"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,74.04673"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,74.04673"
        />
        <polygon
          fill="#CCC"
          points="71.0231705,74.772644 71.0231705,74.772644 71.0231705,74.6516571 70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,74.04673 70.9021912,74.8936234 70.9021912,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,74.772644 71.0231705,74.772644 71.0231705,74.6516571 70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,74.04673 70.9021912,74.8936234 70.9021912,74.8936234"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,72.9578552 71.1441574,74.5306702 70.1762695,73.8047638 70.1762695,73.8047638 71.1441574,74.6516571 71.1441574,74.6516571 71.1441574,74.6516571 71.1441574,72.8368683"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,72.8368683 71.2651443,72.8368683 71.1441574,72.8368683 71.1441574,74.6516571 71.5071182,74.2886963 71.2651443,74.5306702"
        />
        <polygon
          fill="#CCC"
          points="72.1120453,73.6837692 72.1120453,73.6837692 71.2651443,72.8368683 71.2651443,74.5306702 71.5071182,74.2886963"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,73.8047638 71.1441574,74.5306702 71.1441574,72.9578552"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,73.8047638 70.0552826,73.9257431 70.0552826,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,73.8047638 70.0552826,73.9257431 70.0552826,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,73.8047638 70.0552826,73.9257431 70.0552826,73.9257431"
        />
        <polygon
          fill={colors[3]}
          points="70.4182434,73.320816 71.1441574,72.715889 71.0231705,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,73.320816 71.1441574,72.715889 71.0231705,72.594902"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,72.715889 71.1441574,72.9578552 71.1441574,72.8368683 71.1441574,72.8368683"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,72.715889 71.1441574,72.9578552 71.1441574,72.8368683 71.1441574,72.8368683"
        />
        <polygon
          fill={colors[4]}
          points="71.1441574,72.715889 70.4182434,73.320816 70.0552826,73.5627823 70.2972641,73.8047638 70.1762695,73.8047638 70.1762695,73.8047638 71.1441574,72.9578552"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,72.715889 70.4182434,73.320816 70.0552826,73.5627823 70.2972641,73.8047638 70.1762695,73.8047638 70.1762695,73.8047638 71.1441574,72.9578552"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,71.7480011 69.9343033,71.8689804 69.9343033,73.441803 69.3293762,72.8368683 69.9343033,73.5627823 70.0552826,73.5627823"
        />
        <polygon
          fill={colors[4]}
          points="71.0231705,72.594902 70.1762695,73.441803 70.1762695,71.7480011 70.0552826,71.7480011 70.0552826,71.7480011 70.0552826,73.5627823 70.0552826,73.5627823 70.4182434,73.320816"
        />
        <polygon
          fill="#CCC"
          points="70.9021912,72.594902 70.9021912,72.594902 70.1762695,71.7480011 70.1762695,73.441803 71.0231705,72.594902"
        />
        <polygon
          fill={colors[4]}
          points="69.9343033,71.8689804 69.0874023,72.715889 69.3293762,72.8368683 69.9343033,73.441803"
        />
        <polygon
          fill={colors[3]}
          points="69.3293762,72.2319412 69.9343033,71.6270142 69.9343033,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.3293762,72.2319412 69.9343033,71.6270142 69.9343033,71.5060272"
        />
        <polygon
          fill={colors[3]}
          points="69.9343033,71.6270142 69.9343033,71.8689804 70.0552826,71.7480011 70.0552826,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.6270142 69.9343033,71.8689804 70.0552826,71.7480011 70.0552826,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,71.7480011 70.0552826,71.7480011 70.0552826,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,71.7480011 70.0552826,71.7480011 70.0552826,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="69.9343033,71.6270142 69.3293762,72.2319412 68.9664154,72.4739075 69.0874023,72.594902 69.0874023,72.715889 69.9343033,71.8689804"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.6270142 69.3293762,72.2319412 68.9664154,72.4739075 69.0874023,72.594902 69.0874023,72.715889 69.9343033,71.8689804"
        />
        <polygon
          fill={colors[3]}
          points="68.9664154,70.5381393 68.8454285,70.5381393 68.8454285,70.5381393 68.8454285,72.2319412 68.2405014,71.7480011 68.8454285,72.3529282 68.9664154,72.4739075"
        />
        <polygon
          fill={colors[4]}
          points="69.9343033,71.5060272 69.9343033,71.5060272 68.9664154,72.2319412 68.9664154,70.6591263 68.9664154,70.5381393 68.9664154,72.4739075 68.9664154,72.4739075 69.3293762,72.2319412"
        />
        <polygon
          fill="#CCC"
          points="69.8133163,71.5060272 69.8133163,71.5060272 68.9664154,70.6591263 68.9664154,72.2319412 69.9343033,71.5060272"
        />
        <polygon
          fill={colors[4]}
          points="68.2405014,71.7480011 68.8454285,72.2319412 68.8454285,70.5381393 67.9985352,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,71.5060272 70.2972641,71.6270142 71.0231705,72.3529282 71.1441574,72.3529282 71.1441574,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,71.5060272 70.2972641,71.6270142 71.0231705,72.3529282 71.1441574,72.3529282 71.1441574,72.3529282"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,71.6270142 71.0231705,72.3529282 70.2972641,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,71.6270142 71.0231705,72.3529282 70.2972641,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,71.7480011 70.1762695,71.7480011 70.1762695,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,71.7480011 70.1762695,71.7480011 70.1762695,71.7480011"
        />
        <polygon
          fill="#CCC"
          points="70.9021912,72.594902 71.1441574,72.3529282 71.1441574,72.3529282 71.0231705,72.3529282 70.1762695,71.6270142 70.1762695,71.7480011 70.1762695,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9021912,72.594902 71.1441574,72.3529282 71.1441574,72.3529282 71.0231705,72.3529282 70.1762695,71.6270142 70.1762695,71.7480011 70.1762695,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,71.6270142 70.2972641,71.5060272 70.2972641,71.5060272 70.2972641,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,71.6270142 70.2972641,71.5060272 70.2972641,71.5060272 70.2972641,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,71.6270142 70.2972641,71.5060272 70.2972641,71.5060272 70.2972641,71.5060272"
        />
        <polygon
          fill={colors[3]}
          points="69.9343033,71.5060272 69.9343033,71.6270142 69.9343033,71.6270142 69.9343033,71.6270142 70.0552826,71.6270142 70.0552826,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.5060272 69.9343033,71.6270142 69.9343033,71.6270142 69.9343033,71.6270142 70.0552826,71.6270142 70.0552826,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.5060272 69.9343033,71.6270142 69.9343033,71.6270142 69.9343033,71.6270142 70.0552826,71.6270142 70.0552826,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,71.6270142 70.0552826,71.7480011 70.0552826,71.6270142 70.0552826,71.6270142 70.0552826,71.7480011 70.1762695,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,71.6270142 70.0552826,71.7480011 70.0552826,71.6270142 70.0552826,71.6270142 70.0552826,71.7480011 70.1762695,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,71.6270142 70.0552826,71.7480011 70.0552826,71.6270142 70.0552826,71.6270142 70.0552826,71.7480011 70.1762695,71.7480011"
        />
        <polygon
          fill={colors[4]}
          points="70.2972641,71.5060272 70.1762695,71.6270142 70.1762695,71.6270142 70.2972641,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,71.5060272 70.1762695,71.6270142 70.1762695,71.6270142 70.2972641,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,71.5060272 70.1762695,71.6270142 70.1762695,71.6270142 70.2972641,71.6270142"
        />
        <polygon
          fill="#CCC"
          points="69.8133163,71.5060272 69.8133163,71.5060272 69.9343033,71.5060272 69.9343033,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,71.5060272 69.8133163,71.5060272 69.9343033,71.5060272 69.9343033,71.5060272"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,72.2319412 70.2972641,71.5060272 70.2972641,71.5060272 70.2972641,71.5060272 71.1441574,72.3529282 71.1441574,72.3529282 71.2651443,72.3529282 71.2651443,70.6591263 71.1441574,70.6591263"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,70.6591263 71.2651443,72.3529282 71.2651443,72.2319412 71.2651443,72.2319412 71.2651443,70.6591263 71.2651443,70.5381393"
        />
        <polygon
          fill="#CCC"
          points="71.2651443,72.2319412 71.2651443,72.2319412 72.1120453,71.3850479 71.2651443,70.6591263"
        />
        <polygon
          fill={colors[4]}
          points="70.2972641,71.5060272 71.1441574,72.2319412 71.1441574,70.6591263"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,70.41716 69.9343033,71.2640533 69.9343033,71.2640533 70.0552826,71.2640533 69.0874023,70.2961731 69.0874023,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,70.41716 69.9343033,71.2640533 69.9343033,71.2640533 70.0552826,71.2640533 69.0874023,70.2961731 69.0874023,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.2640533 70.0552826,71.2640533 70.0552826,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.2640533 70.0552826,71.2640533 70.0552826,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.2640533 70.0552826,71.2640533 70.0552826,71.2640533"
        />
        <polygon
          fill={colors[3]}
          points="68.8454285,70.5381393 68.9664154,70.5381393 68.9664154,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,70.5381393 68.9664154,70.5381393 68.9664154,70.5381393"
        />
        <polygon
          fill={colors[4]}
          points="69.9343033,71.2640533 69.0874023,70.41716 68.9664154,70.5381393 68.9664154,70.5381393 68.9664154,70.6591263 68.9664154,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.2640533 69.0874023,70.41716 68.9664154,70.5381393 68.9664154,70.5381393 68.9664154,70.6591263 68.9664154,70.5381393"
        />
        <polygon
          fill="#CCC"
          points="69.8133163,71.5060272 69.9343033,71.2640533 69.9343033,71.2640533 69.9343033,71.2640533 68.9664154,70.5381393 68.9664154,70.6591263"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,71.5060272 69.9343033,71.2640533 69.9343033,71.2640533 69.9343033,71.2640533 68.9664154,70.5381393 68.9664154,70.6591263"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,71.3850479 70.0552826,71.3850479 70.2972641,71.5060272 70.2972641,71.5060272 70.1762695,71.5060272 70.0552826,71.3850479 70.0552826,71.2640533 70.0552826,71.2640533 69.9343033,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,71.3850479 70.0552826,71.3850479 70.2972641,71.5060272 70.2972641,71.5060272 70.1762695,71.5060272 70.0552826,71.3850479 70.0552826,71.2640533 70.0552826,71.2640533 69.9343033,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,71.3850479 70.0552826,71.3850479 70.2972641,71.5060272 70.2972641,71.5060272 70.1762695,71.5060272 70.0552826,71.3850479 70.0552826,71.2640533 70.0552826,71.2640533 69.9343033,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,71.3850479 70.0552826,71.3850479 70.2972641,71.5060272 70.2972641,71.5060272 70.1762695,71.5060272 70.0552826,71.3850479 70.0552826,71.2640533 70.0552826,71.2640533 69.9343033,71.2640533"
        />
        <polygon
          fill={colors[3]}
          points="69.9343033,71.5060272 69.9343033,71.5060272 70.0552826,71.6270142 70.0552826,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.5060272 69.9343033,71.5060272 70.0552826,71.6270142 70.0552826,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.5060272 69.9343033,71.5060272 70.0552826,71.6270142 70.0552826,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.5060272 69.9343033,71.5060272 70.0552826,71.6270142 70.0552826,71.3850479"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,71.6270142 70.2972641,71.5060272 70.0552826,71.3850479 70.0552826,71.3850479 70.0552826,71.6270142 70.0552826,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,71.6270142 70.2972641,71.5060272 70.0552826,71.3850479 70.0552826,71.3850479 70.0552826,71.6270142 70.0552826,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,71.6270142 70.2972641,71.5060272 70.0552826,71.3850479 70.0552826,71.3850479 70.0552826,71.6270142 70.0552826,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,71.6270142 70.2972641,71.5060272 70.0552826,71.3850479 70.0552826,71.3850479 70.0552826,71.6270142 70.0552826,71.7480011"
        />
        <polygon
          fill="#CCC"
          points="69.9343033,71.2640533 69.8133163,71.5060272 69.9343033,71.5060272 69.9343033,71.5060272 70.0552826,71.3850479 70.0552826,71.3850479 70.0552826,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.2640533 69.8133163,71.5060272 69.9343033,71.5060272 69.9343033,71.5060272 70.0552826,71.3850479 70.0552826,71.3850479 70.0552826,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,71.2640533 69.8133163,71.5060272 69.9343033,71.5060272 69.9343033,71.5060272 70.0552826,71.3850479 70.0552826,71.3850479 70.0552826,71.3850479"
        />
        <polygon
          fill={colors[3]}
          points="71.0231705,70.41716 70.4182434,71.0220871 71.1441574,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,70.41716 70.4182434,71.0220871 71.1441574,70.41716"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,70.6591263 71.2651443,70.6591263 71.2651443,70.5381393 71.1441574,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.6591263 71.2651443,70.6591263 71.2651443,70.5381393 71.1441574,70.41716"
        />
        <polygon
          fill={colors[3]}
          points="70.2972641,71.5060272 70.2972641,71.5060272 70.1762695,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,71.5060272 70.2972641,71.5060272 70.1762695,71.5060272"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,70.5381393 71.2651443,70.5381393 71.2651443,70.6591263"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,70.5381393 71.2651443,70.5381393 71.2651443,70.6591263"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,71.5060272 70.2972641,71.5060272 71.1441574,70.6591263 71.1441574,70.41716 71.1441574,70.41716 70.4182434,71.0220871 70.0552826,71.3850479 70.0552826,71.2640533 70.0552826,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,71.5060272 70.2972641,71.5060272 71.1441574,70.6591263 71.1441574,70.41716 71.1441574,70.41716 70.4182434,71.0220871 70.0552826,71.3850479 70.0552826,71.2640533 70.0552826,71.3850479"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,69.4492722 70.0552826,71.143074 69.0874023,70.2961731 70.0552826,71.2640533 70.0552826,71.2640533 70.0552826,71.2640533 70.0552826,71.3850479 70.0552826,69.4492722 70.0552826,69.3282852"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,71.3850479 70.4182434,71.0220871 71.0231705,70.41716 71.0231705,70.41716 70.1762695,71.143074 70.1762695,69.4492722 70.0552826,69.4492722"
        />
        <polygon
          fill="#CCC"
          points="71.0231705,70.41716 71.0231705,70.2961731 71.0231705,70.2961731 70.1762695,69.4492722 70.1762695,71.143074"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,70.2961731 69.0874023,70.2961731 69.0874023,70.2961731 69.0874023,70.2961731 70.0552826,71.143074 70.0552826,69.4492722"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,70.41716 72.2330322,71.143074 72.2330322,71.2640533 72.2330322,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,70.41716 72.2330322,71.143074 72.2330322,71.2640533 72.2330322,71.2640533"
        />
        <polygon
          fill={colors[4]}
          points="72.2330322,71.143074 71.3861237,70.41716 71.3861237,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,71.143074 71.3861237,70.41716 71.3861237,70.5381393"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,70.6591263 71.2651443,70.5381393 71.2651443,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,70.6591263 71.2651443,70.5381393 71.2651443,70.5381393"
        />
        <polygon
          fill="#CCC"
          points="71.3861237,70.5381393 71.2651443,70.5381393 71.2651443,70.6591263 72.1120453,71.3850479 72.2330322,71.2640533 72.2330322,71.143074"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,70.5381393 71.2651443,70.5381393 71.2651443,70.6591263 72.1120453,71.3850479 72.2330322,71.2640533 72.2330322,71.143074"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,70.41716 71.2651443,70.5381393 71.2651443,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.41716 71.2651443,70.5381393 71.2651443,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.41716 71.2651443,70.5381393 71.2651443,70.5381393"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,70.41716 71.0231705,70.41716 71.0231705,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.41716 71.0231705,70.41716 71.0231705,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.41716 71.0231705,70.41716 71.0231705,70.41716"
        />
        <polygon
          fill="#CCC"
          points="71.2651443,70.5381393 71.2651443,70.5381393 71.3861237,70.5381393 71.3861237,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,70.5381393 71.2651443,70.5381393 71.3861237,70.5381393 71.3861237,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,70.5381393 71.2651443,70.5381393 71.3861237,70.5381393 71.3861237,70.41716"
        />
        <polygon
          fill="#CCC"
          points="71.0231705,70.41716 71.0231705,70.41716 71.0231705,70.2961731 71.0231705,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,70.41716 71.0231705,70.41716 71.0231705,70.2961731 71.0231705,70.2961731"
        />
        <polygon
          fill={colors[3]}
          points="71.3861237,70.41716 71.3861237,70.41716 71.3861237,70.41716 72.2330322,71.2640533 72.3540115,71.143074 72.3540115,71.143074 72.3540115,71.143074 72.3540115,69.4492722 72.3540115,69.5702591 72.3540115,71.0220871"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,69.4492722 72.3540115,69.4492722 72.3540115,71.143074 72.4749985,71.0220871 72.4749985,71.0220871"
        />
        <polygon
          fill="#CCC"
          points="72.4749985,71.0220871 72.4749985,71.0220871 73.321907,70.1751938 73.321907,70.1751938 73.321907,70.1751938 72.4749985,69.3282852 72.4749985,69.4492722"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,70.41716 72.3540115,71.0220871 72.3540115,69.5702591"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.2072983 71.1441574,70.0541992 71.1441574,70.1751938 71.1441574,70.1751938 70.2972641,69.2072983 70.2972641,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.2072983 71.1441574,70.0541992 71.1441574,70.1751938 71.1441574,70.1751938 70.2972641,69.2072983 70.2972641,69.2072983"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,69.4492722 70.0552826,69.3282852 70.0552826,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.4492722 70.0552826,69.3282852 70.0552826,69.3282852"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,69.4492722 70.1762695,69.4492722 70.1762695,69.3282852 71.1441574,70.0541992 70.1762695,69.2072983 70.0552826,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.4492722 70.1762695,69.4492722 70.1762695,69.3282852 71.1441574,70.0541992 70.1762695,69.2072983 70.0552826,69.3282852"
        />
        <polygon
          fill="#CCC"
          points="70.1762695,69.4492722 71.0231705,70.2961731 71.1441574,70.1751938 71.1441574,70.0541992 70.1762695,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.4492722 71.0231705,70.2961731 71.1441574,70.1751938 71.1441574,70.0541992 70.1762695,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,70.41716 71.3861237,70.41716 71.3861237,70.2961731 71.2651443,70.1751938 71.2651443,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,70.41716 71.3861237,70.41716 71.3861237,70.2961731 71.2651443,70.1751938 71.2651443,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,70.41716 71.3861237,70.41716 71.3861237,70.2961731 71.2651443,70.1751938 71.2651443,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,70.41716 71.3861237,70.41716 71.3861237,70.2961731 71.2651443,70.1751938 71.2651443,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.0541992 71.1441574,70.0541992 71.1441574,70.1751938 71.1441574,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.0541992 71.1441574,70.0541992 71.1441574,70.1751938 71.1441574,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.0541992 71.1441574,70.0541992 71.1441574,70.1751938 71.1441574,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.0541992 71.1441574,70.0541992 71.1441574,70.1751938 71.1441574,70.1751938"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,70.41716 71.1441574,70.41716 71.1441574,70.41716 71.2651443,70.5381393 71.2651443,70.2961731 71.1441574,70.2961731 71.0231705,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.41716 71.1441574,70.41716 71.1441574,70.41716 71.2651443,70.5381393 71.2651443,70.2961731 71.1441574,70.2961731 71.0231705,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.41716 71.1441574,70.41716 71.1441574,70.41716 71.2651443,70.5381393 71.2651443,70.2961731 71.1441574,70.2961731 71.0231705,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.41716 71.1441574,70.41716 71.1441574,70.41716 71.2651443,70.5381393 71.2651443,70.2961731 71.1441574,70.2961731 71.0231705,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,70.5381393 71.2651443,70.5381393 71.2651443,70.41716 71.3861237,70.41716 71.3861237,70.41716 71.2651443,70.1751938 71.2651443,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,70.5381393 71.2651443,70.5381393 71.2651443,70.41716 71.3861237,70.41716 71.3861237,70.41716 71.2651443,70.1751938 71.2651443,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,70.5381393 71.2651443,70.5381393 71.2651443,70.41716 71.3861237,70.41716 71.3861237,70.41716 71.2651443,70.1751938 71.2651443,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,70.5381393 71.2651443,70.5381393 71.2651443,70.41716 71.3861237,70.41716 71.3861237,70.41716 71.2651443,70.1751938 71.2651443,70.2961731"
        />
        <polygon
          fill="#CCC"
          points="71.2651443,70.5381393 71.3861237,70.41716 71.2651443,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,70.5381393 71.3861237,70.41716 71.2651443,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,70.5381393 71.3861237,70.41716 71.2651443,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,70.5381393 71.3861237,70.41716 71.2651443,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="71.1441574,70.2961731 71.0231705,70.41716 71.0231705,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.2961731 71.0231705,70.41716 71.0231705,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.2961731 71.0231705,70.41716 71.0231705,70.41716"
        />
        <polygon
          fill="#CCC"
          points="71.0231705,70.2961731 71.0231705,70.41716 71.1441574,70.2961731 71.2651443,70.2961731 71.2651443,70.1751938 71.2651443,70.1751938 71.1441574,70.1751938 71.2651443,70.1751938 71.1441574,70.1751938 71.1441574,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,70.2961731 71.0231705,70.41716 71.1441574,70.2961731 71.2651443,70.2961731 71.2651443,70.1751938 71.2651443,70.1751938 71.1441574,70.1751938 71.2651443,70.1751938 71.1441574,70.1751938 71.1441574,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,70.2961731 71.0231705,70.41716 71.1441574,70.2961731 71.2651443,70.2961731 71.2651443,70.1751938 71.2651443,70.1751938 71.1441574,70.1751938 71.2651443,70.1751938 71.1441574,70.1751938 71.1441574,70.1751938"
        />
        <polygon
          fill={colors[3]}
          points="72.2330322,69.2072983 71.6281052,69.812233 72.3540115,69.3282852 72.3540115,69.5702591 72.3540115,69.4492722 72.3540115,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,69.2072983 71.6281052,69.812233 72.3540115,69.3282852 72.3540115,69.5702591 72.3540115,69.4492722 72.3540115,69.3282852"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,69.4492722 72.4749985,69.3282852 72.3540115,69.3282852 72.3540115,69.4492722"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,69.4492722 72.4749985,69.3282852 72.3540115,69.3282852 72.3540115,69.4492722"
        />
        <polygon
          fill="#CCC"
          points="72.4749985,69.3282852 72.4749985,69.3282852 72.4749985,69.4492722"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,69.3282852 72.4749985,69.3282852 72.4749985,69.4492722"
        />
        <polygon
          fill={colors[4]}
          points="71.1441574,70.1751938 71.2651443,70.1751938 71.3861237,70.2961731 71.3861237,70.41716 72.3540115,69.5702591 72.3540115,69.3282852 71.6281052,69.812233 71.2651443,70.1751938 71.2651443,70.1751938 71.2651443,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,70.1751938 71.2651443,70.1751938 71.3861237,70.2961731 71.3861237,70.41716 72.3540115,69.5702591 72.3540115,69.3282852 71.6281052,69.812233 71.2651443,70.1751938 71.2651443,70.1751938 71.2651443,70.1751938"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,68.2394104 71.1441574,69.9332123 70.2972641,69.2072983 71.1441574,70.1751938 71.1441574,70.1751938 71.2651443,70.1751938 71.2651443,70.1751938 71.2651443,68.2394104 71.2651443,68.2394104"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,70.1751938 71.6281052,69.812233 72.2330322,69.2072983 71.2651443,69.9332123 71.2651443,68.2394104 71.2651443,68.2394104"
        />
        <polygon
          fill="#CCC"
          points="72.2330322,69.2072983 72.2330322,69.2072983 72.1120453,69.086319 72.1120453,69.086319 71.2651443,68.2394104 71.2651443,69.9332123"
        />
        <polygon
          fill={colors[4]}
          points="70.2972641,69.2072983 70.2972641,69.2072983 70.2972641,69.2072983 70.2972641,69.2072983 71.1441574,69.9332123 71.1441574,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,72.715889 72.1120453,73.441803 72.2330322,73.5627823 72.2330322,73.5627823 71.3861237,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,72.715889 72.1120453,73.441803 72.2330322,73.5627823 72.2330322,73.5627823 71.3861237,72.594902"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,72.715889 72.1120453,73.441803 71.3861237,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,72.715889 72.1120453,73.441803 71.3861237,72.715889"
        />
        <polygon
          fill="#CCC"
          points="72.2330322,73.5627823 72.1120453,73.441803 71.2651443,72.715889 71.2651443,72.8368683 71.2651443,72.8368683 72.1120453,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.5627823 72.1120453,73.441803 71.2651443,72.715889 71.2651443,72.8368683 71.2651443,72.8368683 72.1120453,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,72.715889 71.3861237,72.594902 71.3861237,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,72.715889 71.3861237,72.594902 71.3861237,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,72.715889 71.3861237,72.594902 71.3861237,72.594902"
        />
        <polygon
          fill={colors[3]}
          points="71.0231705,72.594902 71.1441574,72.715889 71.1441574,72.715889 71.1441574,72.715889 71.1441574,72.8368683 71.1441574,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,72.594902 71.1441574,72.715889 71.1441574,72.715889 71.1441574,72.715889 71.1441574,72.8368683 71.1441574,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,72.594902 71.1441574,72.715889 71.1441574,72.715889 71.1441574,72.715889 71.1441574,72.8368683 71.1441574,72.715889"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,72.715889 71.2651443,72.715889 71.2651443,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,72.715889 71.2651443,72.715889 71.2651443,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,72.715889 71.2651443,72.715889 71.2651443,72.715889"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,72.8368683 71.2651443,72.8368683 71.1441574,72.715889 71.1441574,72.8368683"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,72.8368683 71.2651443,72.8368683 71.1441574,72.715889 71.1441574,72.8368683"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,72.8368683 71.2651443,72.8368683 71.1441574,72.715889 71.1441574,72.8368683"
        />
        <polygon
          fill="#CCC"
          points="71.2651443,72.8368683 71.2651443,72.8368683 71.2651443,72.715889 71.2651443,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,72.8368683 71.2651443,72.8368683 71.2651443,72.715889 71.2651443,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,72.8368683 71.2651443,72.8368683 71.2651443,72.715889 71.2651443,72.715889"
        />
        <polygon
          fill="#CCC"
          points="70.9021912,72.594902 70.9021912,72.594902 71.0231705,72.594902 71.0231705,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9021912,72.594902 70.9021912,72.594902 71.0231705,72.594902 71.0231705,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,72.4739075 71.1441574,72.3529282 71.1441574,72.3529282 71.2651443,72.4739075 71.1441574,72.4739075 71.3861237,72.715889 71.3861237,72.594902 71.3861237,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,72.4739075 71.1441574,72.3529282 71.1441574,72.3529282 71.2651443,72.4739075 71.1441574,72.4739075 71.3861237,72.715889 71.3861237,72.594902 71.3861237,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,72.4739075 71.1441574,72.3529282 71.1441574,72.3529282 71.2651443,72.4739075 71.1441574,72.4739075 71.3861237,72.715889 71.3861237,72.594902 71.3861237,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,72.4739075 71.1441574,72.3529282 71.1441574,72.3529282 71.2651443,72.4739075 71.1441574,72.4739075 71.3861237,72.715889 71.3861237,72.594902 71.3861237,72.594902"
        />
        <polygon
          fill={colors[3]}
          points="71.0231705,72.594902 71.0231705,72.594902 71.1441574,72.715889 71.1441574,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,72.594902 71.0231705,72.594902 71.1441574,72.715889 71.1441574,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,72.594902 71.0231705,72.594902 71.1441574,72.715889 71.1441574,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,72.594902 71.0231705,72.594902 71.1441574,72.715889 71.1441574,72.594902"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,72.715889 71.3861237,72.715889 71.1441574,72.4739075 71.1441574,72.594902 71.1441574,72.715889 71.2651443,72.8368683 71.2651443,72.8368683"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,72.715889 71.3861237,72.715889 71.1441574,72.4739075 71.1441574,72.594902 71.1441574,72.715889 71.2651443,72.8368683 71.2651443,72.8368683"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,72.715889 71.3861237,72.715889 71.1441574,72.4739075 71.1441574,72.594902 71.1441574,72.715889 71.2651443,72.8368683 71.2651443,72.8368683"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,72.715889 71.3861237,72.715889 71.1441574,72.4739075 71.1441574,72.594902 71.1441574,72.715889 71.2651443,72.8368683 71.2651443,72.8368683"
        />
        <polygon
          fill="#CCC"
          points="71.1441574,72.3529282 70.9021912,72.594902 71.0231705,72.594902 71.0231705,72.594902 71.1441574,72.594902 71.1441574,72.4739075 71.2651443,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,72.3529282 70.9021912,72.594902 71.0231705,72.594902 71.0231705,72.594902 71.1441574,72.594902 71.1441574,72.4739075 71.2651443,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,72.3529282 70.9021912,72.594902 71.0231705,72.594902 71.0231705,72.594902 71.1441574,72.594902 71.1441574,72.4739075 71.2651443,72.4739075"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,72.3529282 71.1441574,72.4739075 71.2651443,72.3529282 71.2651443,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,72.3529282 71.1441574,72.4739075 71.2651443,72.3529282 71.2651443,72.3529282"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,72.3529282 71.3861237,72.3529282 71.9910507,71.6270142 71.2651443,72.2319412"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,72.3529282 71.3861237,72.3529282 71.9910507,71.6270142 71.2651443,72.2319412"
        />
        <polygon
          fill="#CCC"
          points="71.9910507,71.6270142 72.1120453,71.5060272 72.1120453,71.3850479 71.2651443,72.2319412"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.9910507,71.6270142 72.1120453,71.5060272 72.1120453,71.3850479 71.2651443,72.2319412"
        />
        <polygon
          fill="#CCC"
          points="72.1120453,71.3850479 72.1120453,71.5060272 72.2330322,71.5060272 72.1120453,71.3850479 72.2330322,71.2640533 72.2330322,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,71.3850479 72.1120453,71.5060272 72.2330322,71.5060272 72.1120453,71.3850479 72.2330322,71.2640533 72.2330322,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,71.3850479 72.1120453,71.5060272 72.2330322,71.5060272 72.1120453,71.3850479 72.2330322,71.2640533 72.2330322,71.2640533"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,71.143074 72.3540115,71.143074 72.2330322,71.2640533 72.2330322,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,71.143074 72.3540115,71.143074 72.2330322,71.2640533 72.2330322,71.2640533"
        />
        <polygon
          fill={colors[3]}
          points="71.3861237,72.3529282 72.2330322,71.5060272 72.2330322,71.5060272 72.1120453,71.5060272 71.9910507,71.6270142"
        />
        <polygon
          fill={colors[3]}
          points="72.2330322,73.320816 71.3861237,72.594902 71.3861237,72.594902 71.3861237,72.594902 72.2330322,73.5627823 72.3540115,73.441803 72.3540115,71.6270142 72.2330322,71.5060272"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,71.6270142 72.3540115,71.6270142 72.3540115,73.441803 72.7169724,73.0788422 72.3540115,73.320816"
        />
        <polygon
          fill="#CCC"
          points="72.3540115,73.320816 72.7169724,73.0788422 73.321907,72.4739075 72.3540115,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,72.3529282 71.1441574,72.4739075 71.1441574,72.4739075 71.3861237,72.594902 72.2330322,73.320816 72.2330322,71.5060272 72.2330322,71.5060272 71.3861237,72.3529282"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,71.2640533 72.3540115,71.143074 72.3540115,71.143074"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,71.2640533 72.3540115,71.143074 72.3540115,71.143074"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,71.2640533 72.3540115,71.2640533 72.4749985,71.143074 73.0799255,70.5381393 72.4749985,71.0220871 72.3540115,71.143074"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,71.2640533 72.3540115,71.2640533 72.4749985,71.143074 73.0799255,70.5381393 72.4749985,71.0220871 72.3540115,71.143074"
        />
        <polygon
          fill="#CCC"
          points="72.4749985,71.0220871 73.0799255,70.5381393 73.321907,70.2961731 73.321907,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,71.0220871 73.0799255,70.5381393 73.321907,70.2961731 73.321907,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.3529282 73.4428864,72.3529282 73.4428864,72.3529282 72.5959854,71.5060272 72.5959854,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.3529282 73.4428864,72.3529282 73.4428864,72.3529282 72.5959854,71.5060272 72.5959854,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,71.5060272 72.5959854,71.5060272 72.5959854,71.5060272 72.4749985,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,71.5060272 72.5959854,71.5060272 72.5959854,71.5060272 72.4749985,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,71.5060272 72.5959854,71.5060272 72.5959854,71.5060272 72.4749985,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,71.2640533 72.2330322,71.2640533 72.2330322,71.2640533 72.3540115,71.3850479 72.3540115,71.3850479 72.4749985,71.5060272 72.5959854,71.5060272 72.4749985,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,71.2640533 72.2330322,71.2640533 72.2330322,71.2640533 72.3540115,71.3850479 72.3540115,71.3850479 72.4749985,71.5060272 72.5959854,71.5060272 72.4749985,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,71.2640533 72.2330322,71.2640533 72.2330322,71.2640533 72.3540115,71.3850479 72.3540115,71.3850479 72.4749985,71.5060272 72.5959854,71.5060272 72.4749985,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,71.2640533 72.2330322,71.2640533 72.2330322,71.2640533 72.3540115,71.3850479 72.3540115,71.3850479 72.4749985,71.5060272 72.5959854,71.5060272 72.4749985,71.3850479"
        />
        <polygon
          fill="#CCC"
          points="72.1120453,71.3850479 72.2330322,71.5060272 72.3540115,71.2640533 72.3540115,71.3850479 72.3540115,71.3850479 72.2330322,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,71.3850479 72.2330322,71.5060272 72.3540115,71.2640533 72.3540115,71.3850479 72.3540115,71.3850479 72.2330322,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,71.3850479 72.2330322,71.5060272 72.3540115,71.2640533 72.3540115,71.3850479 72.3540115,71.3850479 72.2330322,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,71.3850479 72.2330322,71.5060272 72.3540115,71.2640533 72.3540115,71.3850479 72.3540115,71.3850479 72.2330322,71.2640533"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,71.2640533 72.3540115,71.143074 72.2330322,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,71.2640533 72.3540115,71.143074 72.2330322,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,71.2640533 72.3540115,71.143074 72.2330322,71.2640533"
        />
        <polygon
          fill={colors[3]}
          points="72.2330322,71.5060272 72.2330322,71.5060272 72.2330322,71.5060272 72.3540115,71.6270142 72.3540115,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,71.5060272 72.2330322,71.5060272 72.2330322,71.5060272 72.3540115,71.6270142 72.3540115,71.2640533"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,71.6270142 72.3540115,71.6270142 72.3540115,71.5060272 73.321907,72.3529282 72.5959854,71.5060272 72.4749985,71.5060272 72.3540115,71.3850479 72.3540115,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,71.6270142 72.3540115,71.6270142 72.3540115,71.5060272 73.321907,72.3529282 72.5959854,71.5060272 72.4749985,71.5060272 72.3540115,71.3850479 72.3540115,71.2640533"
        />
        <polygon
          fill="#CCC"
          points="72.3540115,71.5060272 72.3540115,71.6270142 73.321907,72.4739075 73.4428864,72.3529282 73.321907,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,71.5060272 72.3540115,71.6270142 73.321907,72.4739075 73.4428864,72.3529282 73.321907,72.3529282"
        />
        <polygon
          fill={colors[3]}
          points="73.4428864,72.1109619 72.4749985,71.3850479 72.5959854,71.5060272 72.5959854,71.5060272 73.4428864,72.3529282 73.4428864,72.3529282 73.4428864,72.2319412 73.4428864,70.41716 73.4428864,70.41716"
        />
        <polygon
          fill={colors[3]}
          points="72.4749985,71.143074 73.4428864,70.41716 73.321907,70.2961731 73.0799255,70.5381393"
        />
        <polygon
          fill={colors[4]}
          points="73.4428864,72.2319412 73.8058395,71.8689804 73.5638733,72.1109619 73.5638733,70.5381393 73.4428864,70.41716"
        />
        <polygon
          fill="#CCC"
          points="73.5638733,72.1109619 73.8058395,71.8689804 74.0478134,71.7480011 73.9268341,70.7801208 73.5638733,70.5381393"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,71.143074 72.3540115,71.2640533 72.3540115,71.2640533 72.3540115,71.2640533 72.3540115,71.2640533 72.3540115,71.2640533 72.4749985,71.3850479 73.4428864,72.1109619 73.4428864,70.41716 73.4428864,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,73.8047638 68.9664154,74.6516571 68.9664154,74.6516571"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,73.8047638 68.9664154,74.6516571 68.9664154,74.6516571"
        />
        <polygon
          fill={colors[3]}
          points="69.9343033,73.9257431 69.9343033,74.04673 69.9343033,74.04673"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.9257431 69.9343033,74.04673 69.9343033,74.04673"
        />
        <polygon
          fill={colors[3]}
          points="68.9664154,74.6516571 69.9343033,73.9257431 69.8133163,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,74.6516571 69.9343033,73.9257431 69.8133163,73.8047638"
        />
        <polygon
          fill={colors[4]}
          points="69.9343033,74.04673 70.0552826,73.9257431 69.9343033,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,74.04673 70.0552826,73.9257431 69.9343033,73.9257431"
        />
        <polygon
          fill={colors[4]}
          points="69.9343033,73.9257431 69.9343033,73.9257431 68.9664154,74.6516571 68.9664154,74.6516571 68.8454285,74.772644 68.9664154,74.8936234 69.2083893,74.8936234 69.9343033,74.04673"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.9257431 69.9343033,73.9257431 68.9664154,74.6516571 68.9664154,74.6516571 68.8454285,74.772644 68.9664154,74.8936234 69.2083893,74.8936234 69.9343033,74.04673"
        />
        <polygon
          fill={colors[3]}
          points="69.9343033,73.9257431 69.8133163,73.8047638 69.8133163,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.9257431 69.8133163,73.8047638 69.8133163,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.9257431 69.8133163,73.8047638 69.8133163,73.8047638"
        />
        <polygon
          fill={colors[4]}
          points="69.9343033,73.9257431 69.9343033,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.9257431 69.9343033,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.9257431 69.9343033,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.715889 69.0874023,72.715889 69.8133163,73.5627823 69.9343033,73.5627823 69.9343033,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.715889 69.0874023,72.715889 69.8133163,73.5627823 69.9343033,73.5627823 69.9343033,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,73.8047638 69.8133163,73.8047638 69.8133163,73.8047638 69.9343033,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,73.8047638 69.8133163,73.8047638 69.8133163,73.8047638 69.9343033,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,73.8047638 69.8133163,73.8047638 69.8133163,73.8047638 69.9343033,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,73.8047638 69.8133163,73.8047638 69.8133163,73.8047638 69.9343033,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,73.6837692 70.0552826,73.5627823 70.0552826,73.5627823 69.9343033,73.5627823 69.9343033,73.5627823 70.0552826,73.6837692 70.0552826,73.6837692 70.1762695,73.8047638 70.1762695,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,73.6837692 70.0552826,73.5627823 70.0552826,73.5627823 69.9343033,73.5627823 69.9343033,73.5627823 70.0552826,73.6837692 70.0552826,73.6837692 70.1762695,73.8047638 70.1762695,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,73.6837692 70.0552826,73.5627823 70.0552826,73.5627823 69.9343033,73.5627823 69.9343033,73.5627823 70.0552826,73.6837692 70.0552826,73.6837692 70.1762695,73.8047638 70.1762695,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,73.6837692 70.0552826,73.5627823 70.0552826,73.5627823 69.9343033,73.5627823 69.9343033,73.5627823 70.0552826,73.6837692 70.0552826,73.6837692 70.1762695,73.8047638 70.1762695,73.8047638"
        />
        <polygon
          fill={colors[3]}
          points="69.9343033,73.6837692 69.8133163,73.8047638 69.9343033,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.6837692 69.8133163,73.8047638 69.9343033,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.6837692 69.8133163,73.8047638 69.9343033,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.6837692 69.8133163,73.8047638 69.9343033,73.9257431"
        />
        <polygon
          fill={colors[4]}
          points="69.9343033,73.6837692 69.9343033,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.1762695,73.8047638 70.0552826,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.6837692 69.9343033,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.1762695,73.8047638 70.0552826,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.6837692 69.9343033,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.1762695,73.8047638 70.0552826,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,73.6837692 69.9343033,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.0552826,73.9257431 70.1762695,73.8047638 70.0552826,73.6837692"
        />
        <polygon
          fill={colors[3]}
          points="70.1762695,73.8047638 70.1762695,73.8047638 70.1762695,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,73.8047638 70.1762695,73.8047638 70.1762695,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,73.8047638 70.1762695,73.8047638 70.1762695,73.6837692"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,73.5627823 70.0552826,73.6837692 70.0552826,73.5627823 70.0552826,73.5627823 70.1762695,73.6837692 70.1762695,73.8047638 70.2972641,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,73.5627823 70.0552826,73.6837692 70.0552826,73.5627823 70.0552826,73.5627823 70.1762695,73.6837692 70.1762695,73.8047638 70.2972641,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,73.5627823 70.0552826,73.6837692 70.0552826,73.5627823 70.0552826,73.5627823 70.1762695,73.6837692 70.1762695,73.8047638 70.2972641,73.8047638"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,73.5627823 69.9343033,73.5627823 69.3293762,72.8368683 69.0874023,72.715889 69.0874023,72.715889 69.9343033,73.5627823 69.9343033,73.5627823 70.0552826,73.5627823 70.0552826,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,73.5627823 69.9343033,73.5627823 69.3293762,72.8368683 69.0874023,72.715889 69.0874023,72.715889 69.9343033,73.5627823 69.9343033,73.5627823 70.0552826,73.5627823 70.0552826,73.6837692"
        />
        <polygon
          fill={colors[3]}
          points="69.0874023,72.715889 69.0874023,72.715889 69.0874023,72.715889 69.0874023,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.715889 69.0874023,72.715889 69.0874023,72.715889 69.0874023,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.715889 69.0874023,72.715889 69.0874023,72.715889 69.0874023,72.594902"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,72.594902 69.0874023,72.594902 69.0874023,72.715889 69.0874023,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.594902 69.0874023,72.594902 69.0874023,72.715889 69.0874023,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.594902 69.0874023,72.594902 69.0874023,72.715889 69.0874023,72.715889"
        />
        <polygon
          fill={colors[3]}
          points="68.8454285,74.5306702 68.2405014,74.1677094 68.8454285,74.6516571 68.8454285,74.6516571 68.8454285,72.8368683 68.8454285,72.9578552"
        />
        <polygon
          fill={colors[4]}
          points="69.8133163,73.8047638 69.8133163,73.8047638 68.9664154,74.5306702 68.9664154,72.8368683 68.8454285,72.8368683 68.8454285,74.6516571 68.8454285,74.772644 68.9664154,74.6516571"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,72.715889 68.9664154,72.715889 69.8133163,73.5627823"
        />
        <polygon
          fill="#CCC"
          points="69.9343033,73.6837692 70.0552826,73.6837692 70.0552826,73.6837692 69.9343033,73.5627823 69.9343033,73.5627823 69.8133163,73.5627823 68.9664154,72.715889 68.9664154,72.8368683 68.9664154,74.5306702 69.8133163,73.8047638"
        />
        <polygon
          fill={colors[4]}
          points="68.2405014,74.1677094 68.8454285,74.5306702 68.8454285,72.9578552 67.8775482,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,71.5060272 67.8775482,71.5060272 67.8775482,71.5060272 68.7244415,72.3529282 68.8454285,72.4739075 68.8454285,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,71.5060272 67.8775482,71.5060272 67.8775482,71.5060272 68.7244415,72.3529282 68.8454285,72.4739075 68.8454285,72.3529282"
        />
        <polygon
          fill={colors[3]}
          points="67.9985352,71.5060272 68.8454285,72.3529282 68.8454285,72.3529282 68.2405014,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,71.5060272 68.8454285,72.3529282 68.8454285,72.3529282 68.2405014,71.7480011"
        />
        <polygon
          fill={colors[4]}
          points="67.9985352,71.5060272 67.9985352,71.5060272 67.9985352,71.5060272 67.8775482,71.5060272 67.9985352,71.5060272 68.2405014,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,71.5060272 67.9985352,71.5060272 67.9985352,71.5060272 67.8775482,71.5060272 67.9985352,71.5060272 68.2405014,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,73.5627823 67.8775482,73.5627823 68.7244415,72.715889 68.7244415,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,73.5627823 67.8775482,73.5627823 68.7244415,72.715889 68.7244415,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.594902 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 69.0874023,72.715889 69.0874023,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.594902 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 69.0874023,72.715889 69.0874023,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.594902 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 69.0874023,72.715889 69.0874023,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.594902 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075 69.0874023,72.715889 69.0874023,72.715889"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,72.594902 68.9664154,72.4739075 68.9664154,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.594902 68.9664154,72.4739075 68.9664154,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.594902 68.9664154,72.4739075 68.9664154,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,72.594902 68.9664154,72.4739075 68.9664154,72.4739075 68.8454285,72.4739075 68.8454285,72.4739075"
        />
        <polygon
          fill={colors[3]}
          points="68.9664154,72.4739075 68.9664154,72.4739075 68.8454285,72.3529282 68.8454285,72.3529282 68.8454285,72.4739075 68.8454285,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,72.4739075 68.9664154,72.4739075 68.8454285,72.3529282 68.8454285,72.3529282 68.8454285,72.4739075 68.8454285,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,72.4739075 68.9664154,72.4739075 68.8454285,72.3529282 68.8454285,72.3529282 68.8454285,72.4739075 68.8454285,72.4739075"
        />
        <polygon
          fill={colors[3]}
          points="68.8454285,72.4739075 68.7244415,72.594902 68.7244415,72.594902 68.7244415,72.715889 67.8775482,73.5627823 68.8454285,72.715889 68.8454285,72.9578552 68.8454285,72.8368683"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,72.4739075 68.7244415,72.594902 68.7244415,72.594902 68.7244415,72.715889 67.8775482,73.5627823 68.8454285,72.715889 68.8454285,72.9578552 68.8454285,72.8368683"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,72.715889 69.0874023,72.715889 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.8368683 68.9664154,72.8368683"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,72.715889 69.0874023,72.715889 68.8454285,72.4739075 68.8454285,72.4739075 68.8454285,72.8368683 68.9664154,72.8368683"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,73.5627823 67.8775482,73.6837692 67.8775482,73.6837692 67.8775482,73.8047638 68.8454285,72.9578552 68.8454285,72.715889 67.8775482,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,73.5627823 67.8775482,73.6837692 67.8775482,73.6837692 67.8775482,73.8047638 68.8454285,72.9578552 68.8454285,72.715889 67.8775482,73.5627823"
        />
        <polygon
          fill={colors[3]}
          points="67.6355743,73.441803 67.1516266,72.9578552 67.7565536,73.5627823 67.7565536,71.7480011 67.6355743,71.7480011"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,71.7480011 67.7565536,73.5627823 67.7565536,73.5627823 67.7565536,73.5627823 68.7244415,72.715889 68.7244415,72.594902 68.7244415,72.594902 67.7565536,73.441803 67.7565536,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,71.6270142 68.7244415,72.3529282 67.8775482,71.5060272"
        />
        <polygon
          fill="#CCC"
          points="68.8454285,72.4739075 68.8454285,72.4739075 68.7244415,72.3529282 67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,73.441803 68.7244415,72.594902 68.7244415,72.594902"
        />
        <polygon
          fill={colors[4]}
          points="67.6355743,71.7480011 66.7886734,72.594902 66.7886734,72.594902 66.7886734,72.594902 67.1516266,72.9578552 67.6355743,73.441803"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.8047638 71.2651443,74.6516571 71.2651443,74.6516571"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.8047638 71.2651443,74.6516571 71.2651443,74.6516571"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,74.6516571 71.0231705,74.6516571 71.1441574,74.772644 71.1441574,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,74.6516571 71.0231705,74.6516571 71.1441574,74.772644 71.1441574,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,74.6516571 71.0231705,74.6516571 71.1441574,74.772644 71.1441574,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,74.6516571 71.0231705,74.6516571 71.1441574,74.772644 71.1441574,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.772644 71.1441574,74.8936234 71.2651443,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.772644 71.1441574,74.8936234 71.2651443,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.772644 71.1441574,74.8936234 71.2651443,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.772644 71.1441574,74.8936234 71.2651443,74.8936234"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,74.8936234 71.1441574,74.8936234 71.1441574,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.8936234 71.1441574,74.8936234 71.1441574,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.8936234 71.1441574,74.8936234 71.1441574,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.8936234 71.1441574,74.8936234 71.1441574,74.772644"
        />
        <polygon
          fill={colors[4]}
          points="71.1441574,74.772644 71.1441574,74.8936234 71.1441574,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.772644 71.1441574,74.8936234 71.1441574,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.772644 71.1441574,74.8936234 71.1441574,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.772644 71.1441574,74.8936234 71.1441574,74.8936234"
        />
        <polygon
          fill="#CCC"
          points="71.1441574,74.772644 71.1441574,74.772644 71.1441574,74.772644 71.1441574,74.772644 71.0231705,74.772644 70.9021912,74.8936234 71.1441574,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.772644 71.1441574,74.772644 71.1441574,74.772644 71.1441574,74.772644 71.0231705,74.772644 70.9021912,74.8936234 71.1441574,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.772644 71.1441574,74.772644 71.1441574,74.772644 71.1441574,74.772644 71.0231705,74.772644 70.9021912,74.8936234 71.1441574,74.8936234"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,74.6516571 71.1441574,74.6516571 71.1441574,74.772644 71.1441574,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,74.6516571 71.1441574,74.6516571 71.1441574,74.772644 71.1441574,74.772644"
        />
        <polygon
          fill={colors[4]}
          points="72.1120453,73.8047638 71.5071182,74.2886963 71.1441574,74.6516571 71.1441574,74.772644 71.2651443,74.6516571"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.8047638 71.5071182,74.2886963 71.1441574,74.6516571 71.1441574,74.772644 71.2651443,74.6516571"
        />
        <polygon
          fill="#CCC"
          points="71.5071182,74.2886963 72.1120453,73.8047638 72.1120453,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5071182,74.2886963 72.1120453,73.8047638 72.1120453,73.6837692"
        />
        <polygon
          fill={colors[3]}
          points="72.2330322,73.9257431 72.2330322,73.9257431 72.2330322,74.8936234 72.2330322,74.8936234"
        />
        <polygon
          fill={colors[3]}
          points="71.2651443,74.6516571 72.2330322,73.9257431 72.1120453,73.8047638"
        />
        <polygon
          fill={colors[4]}
          points="72.2330322,73.9257431 72.2330322,74.8936234 72.3540115,74.8936234 72.3540115,74.04673"
        />
        <polygon
          fill="#CCC"
          points="72.3540115,74.8936234 73.2009125,74.8936234 72.3540115,74.04673"
        />
        <polygon
          fill={colors[4]}
          points="72.2330322,73.9257431 72.2330322,73.9257431 71.2651443,74.6516571 71.2651443,74.6516571 71.1441574,74.772644 71.1441574,74.772644 71.1441574,74.772644 71.1441574,74.772644 71.1441574,74.772644 71.2651443,74.8936234 72.2330322,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,74.772644 73.5638733,74.772644 74.4107666,73.9257431 74.4107666,73.8047638 73.4428864,74.6516571"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,73.5627823 72.4749985,73.441803 73.321907,72.594902 73.321907,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,73.5627823 72.4749985,73.441803 73.321907,72.594902 73.321907,72.594902"
        />
        <polygon
          fill="#CCC"
          points="72.1120453,73.6837692 72.1120453,73.6837692 72.2330322,73.5627823 72.2330322,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.6837692 72.1120453,73.6837692 72.2330322,73.5627823 72.2330322,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.6837692 72.1120453,73.6837692 72.2330322,73.5627823 72.2330322,73.5627823"
        />
        <polygon
          fill={colors[3]}
          points="72.2330322,73.5627823 72.2330322,73.5627823 72.2330322,73.441803 72.3540115,73.5627823 72.3540115,73.441803"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.5627823 72.2330322,73.5627823 72.2330322,73.441803 72.3540115,73.5627823 72.3540115,73.441803"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,73.5627823 72.3540115,73.5627823 72.3540115,73.5627823 73.321907,72.594902 73.321907,72.594902 72.7169724,73.0788422 72.3540115,73.441803"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,73.5627823 72.3540115,73.5627823 72.3540115,73.5627823 73.321907,72.594902 73.321907,72.594902 72.7169724,73.0788422 72.3540115,73.441803"
        />
        <polygon
          fill="#CCC"
          points="72.7169724,73.0788422 73.321907,72.594902 73.2009125,72.594902 73.321907,72.4739075 73.321907,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7169724,73.0788422 73.321907,72.594902 73.2009125,72.594902 73.321907,72.4739075 73.321907,72.4739075"
        />
        <polygon
          fill="#CCC"
          points="73.321907,72.4739075 73.321907,72.4739075 73.4428864,72.3529282 73.4428864,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.4739075 73.321907,72.4739075 73.4428864,72.3529282 73.4428864,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.4739075 73.321907,72.4739075 73.4428864,72.3529282 73.4428864,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,74.6516571 73.4428864,74.772644 72.4749985,73.8047638 72.4749985,73.8047638 73.321907,74.6516571 73.4428864,74.772644 73.321907,74.8936234 73.5638733,74.8936234 73.5638733,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,74.6516571 73.4428864,74.772644 72.4749985,73.8047638 72.4749985,73.8047638 73.321907,74.6516571 73.4428864,74.772644 73.321907,74.8936234 73.5638733,74.8936234 73.5638733,74.772644"
        />
        <polygon
          fill="#CCC"
          points="72.1120453,73.6837692 72.1120453,73.6837692 72.1120453,73.8047638 72.1120453,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.6837692 72.1120453,73.6837692 72.1120453,73.8047638 72.1120453,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.6837692 72.1120453,73.6837692 72.1120453,73.8047638 72.1120453,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.8047638 72.1120453,73.8047638 72.2330322,73.6837692 72.1120453,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.8047638 72.1120453,73.8047638 72.2330322,73.6837692 72.1120453,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.8047638 72.1120453,73.8047638 72.2330322,73.6837692 72.1120453,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.8047638 72.1120453,73.8047638 72.2330322,73.6837692 72.1120453,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,73.6837692 72.4749985,73.8047638 72.4749985,73.8047638 72.4749985,73.6837692 72.3540115,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,73.6837692 72.4749985,73.8047638 72.4749985,73.8047638 72.4749985,73.6837692 72.3540115,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,73.6837692 72.4749985,73.8047638 72.4749985,73.8047638 72.4749985,73.6837692 72.3540115,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,73.6837692 72.4749985,73.8047638 72.4749985,73.8047638 72.4749985,73.6837692 72.3540115,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.5627823 72.2330322,73.5627823 72.3540115,73.6837692 72.3540115,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.5627823 72.2330322,73.5627823 72.3540115,73.6837692 72.3540115,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.5627823 72.2330322,73.5627823 72.3540115,73.6837692 72.3540115,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.5627823 72.2330322,73.5627823 72.3540115,73.6837692 72.3540115,73.6837692"
        />
        <polygon
          fill={colors[4]}
          points="72.1120453,73.6837692 72.1120453,73.8047638 72.1120453,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.6837692 72.1120453,73.8047638 72.1120453,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.6837692 72.1120453,73.8047638 72.1120453,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,73.6837692 72.1120453,73.8047638 72.1120453,73.8047638"
        />
        <polygon
          fill="#CCC"
          points="72.2330322,73.5627823 72.3540115,73.5627823 72.2330322,73.5627823 72.1120453,73.6837692 72.1120453,73.8047638 72.1120453,73.6837692 72.2330322,73.6837692 72.2330322,73.6837692 72.3540115,73.6837692 72.3540115,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.5627823 72.3540115,73.5627823 72.2330322,73.5627823 72.1120453,73.6837692 72.1120453,73.8047638 72.1120453,73.6837692 72.2330322,73.6837692 72.2330322,73.6837692 72.3540115,73.6837692 72.3540115,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.5627823 72.3540115,73.5627823 72.2330322,73.5627823 72.1120453,73.6837692 72.1120453,73.8047638 72.1120453,73.6837692 72.2330322,73.6837692 72.2330322,73.6837692 72.3540115,73.6837692 72.3540115,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.5627823 72.3540115,73.5627823 72.2330322,73.5627823 72.1120453,73.6837692 72.1120453,73.8047638 72.1120453,73.6837692 72.2330322,73.6837692 72.2330322,73.6837692 72.3540115,73.6837692 72.3540115,73.5627823"
        />
        <polygon
          fill={colors[3]}
          points="72.2330322,73.441803 72.2330322,73.5627823 72.3540115,73.5627823 72.3540115,73.5627823 72.3540115,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.441803 72.2330322,73.5627823 72.3540115,73.5627823 72.3540115,73.5627823 72.3540115,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.441803 72.2330322,73.5627823 72.3540115,73.5627823 72.3540115,73.5627823 72.3540115,73.5627823"
        />
        <polygon
          fill={colors[3]}
          points="72.2330322,73.6837692 72.2330322,73.6837692 72.1120453,73.8047638 72.1120453,73.8047638 72.2330322,73.9257431 72.2330322,73.8047638 72.2330322,73.9257431 72.2330322,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.6837692 72.2330322,73.6837692 72.1120453,73.8047638 72.1120453,73.8047638 72.2330322,73.9257431 72.2330322,73.8047638 72.2330322,73.9257431 72.2330322,73.9257431"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,73.6837692 72.2330322,73.6837692 72.2330322,73.9257431 72.3540115,74.04673 72.3540115,73.8047638 73.321907,74.6516571 72.4749985,73.8047638 72.4749985,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,73.6837692 72.2330322,73.6837692 72.2330322,73.9257431 72.3540115,74.04673 72.3540115,73.8047638 73.321907,74.6516571 72.4749985,73.8047638 72.4749985,73.8047638"
        />
        <polygon
          fill="#CCC"
          points="73.321907,74.6516571 72.3540115,73.8047638 72.3540115,74.04673 73.2009125,74.8936234 73.321907,74.8936234 73.4428864,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,74.6516571 72.3540115,73.8047638 72.3540115,74.04673 73.2009125,74.8936234 73.321907,74.8936234 73.4428864,74.772644"
        />
        <polygon
          fill={colors[4]}
          points="72.2330322,73.9257431 72.2330322,73.9257431 72.2330322,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,73.9257431 72.2330322,73.9257431 72.2330322,73.8047638"
        />
        <polygon
          fill={colors[3]}
          points="73.321907,74.5306702 72.4749985,73.6837692 72.4749985,73.8047638 72.4749985,73.8047638 73.4428864,74.772644 73.4428864,72.8368683 73.321907,72.715889"
        />
        <polygon
          fill={colors[3]}
          points="73.321907,72.715889 73.321907,72.594902 72.4749985,73.441803"
        />
        <polygon
          fill={colors[4]}
          points="73.5638733,72.8368683 73.4428864,72.8368683 73.4428864,74.772644 73.4428864,74.6516571 74.4107666,73.8047638 74.4107666,73.8047638 73.5638733,74.5306702"
        />
        <polygon
          fill="#CCC"
          points="73.5638733,74.5306702 74.4107666,73.8047638 74.4107666,73.6837692 73.5638733,72.8368683"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,73.5627823 72.3540115,73.5627823 72.3540115,73.5627823 72.3540115,73.5627823 72.3540115,73.5627823 72.2330322,73.5627823 72.3540115,73.5627823 72.4749985,73.6837692 73.321907,74.5306702 73.321907,72.715889 73.321907,72.715889 72.4749985,73.441803"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,72.3529282 74.0478134,71.8689804 74.0478134,71.8689804"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,72.3529282 74.0478134,71.8689804 74.0478134,71.8689804"
        />
        <polygon
          fill={colors[3]}
          points="73.4428864,72.3529282 73.4428864,72.3529282 73.4428864,72.3529282 73.4428864,72.2319412"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,72.3529282 73.4428864,72.3529282 73.4428864,72.3529282 73.4428864,72.2319412"
        />
        <polygon
          fill={colors[4]}
          points="73.4428864,72.2319412 73.4428864,72.3529282 73.5638733,72.3529282 74.0478134,71.8689804 74.0478134,71.7480011 73.8058395,71.8689804"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,72.2319412 73.4428864,72.3529282 73.5638733,72.3529282 74.0478134,71.8689804 74.0478134,71.7480011 73.8058395,71.8689804"
        />
        <polygon
          fill="#CCC"
          points="74.0478134,71.7480011 74.0478134,71.7480011 73.8058395,71.8689804"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="74.0478134,71.7480011 74.0478134,71.7480011 73.8058395,71.8689804"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6848526,72.594902 74.2897797,73.320816 74.2897797,73.320816"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6848526,72.594902 74.2897797,73.320816 74.2897797,73.320816"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6848526,72.594902 73.6848526,72.594902 73.6848526,72.594902 73.5638733,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6848526,72.594902 73.6848526,72.594902 73.6848526,72.594902 73.5638733,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6848526,72.594902 73.6848526,72.594902 73.6848526,72.594902 73.5638733,72.594902"
        />
        <polygon
          fill={colors[4]}
          points="73.321907,72.594902 73.4428864,72.4739075 73.4428864,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.594902 73.4428864,72.4739075 73.4428864,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.594902 73.4428864,72.4739075 73.4428864,72.4739075"
        />
        <polygon
          fill="#CCC"
          points="73.321907,72.594902 73.321907,72.594902 73.321907,72.4739075 73.2009125,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.594902 73.321907,72.594902 73.321907,72.4739075 73.2009125,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.594902 73.321907,72.594902 73.321907,72.4739075 73.2009125,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,72.3529282 73.4428864,72.3529282 73.5638733,72.4739075 73.5638733,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,72.3529282 73.4428864,72.3529282 73.5638733,72.4739075 73.5638733,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,72.3529282 73.4428864,72.3529282 73.5638733,72.4739075 73.5638733,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,72.3529282 73.4428864,72.3529282 73.5638733,72.4739075 73.5638733,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,72.594902 73.6848526,72.594902 73.5638733,72.594902 73.4428864,72.4739075 73.4428864,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,72.594902 73.6848526,72.594902 73.5638733,72.594902 73.4428864,72.4739075 73.4428864,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,72.594902 73.6848526,72.594902 73.5638733,72.594902 73.4428864,72.4739075 73.4428864,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,72.594902 73.6848526,72.594902 73.5638733,72.594902 73.4428864,72.4739075 73.4428864,72.4739075"
        />
        <polygon
          fill="#CCC"
          points="73.321907,72.4739075 73.321907,72.594902 73.321907,72.594902 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.4739075 73.321907,72.594902 73.321907,72.594902 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.4739075 73.321907,72.594902 73.321907,72.594902 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.4739075 73.321907,72.594902 73.321907,72.594902 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.3529282"
        />
        <polygon
          fill={colors[3]}
          points="73.4428864,72.3529282 73.4428864,72.3529282 73.5638733,72.4739075 73.5638733,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,72.3529282 73.4428864,72.3529282 73.5638733,72.4739075 73.5638733,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,72.3529282 73.4428864,72.3529282 73.5638733,72.4739075 73.5638733,72.4739075"
        />
        <polygon
          fill={colors[3]}
          points="73.321907,72.594902 73.321907,72.594902 73.321907,72.715889 73.321907,72.715889 73.321907,72.715889 73.4428864,72.8368683 73.4428864,72.4739075 73.4428864,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,72.594902 73.321907,72.594902 73.321907,72.715889 73.321907,72.715889 73.321907,72.715889 73.4428864,72.8368683 73.4428864,72.4739075 73.4428864,72.4739075"
        />
        <polygon
          fill={colors[4]}
          points="73.5638733,72.594902 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.8368683 73.5638733,72.8368683 73.5638733,72.715889 74.2897797,73.320816 74.2897797,73.320816 73.6848526,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,72.594902 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.8368683 73.5638733,72.8368683 73.5638733,72.715889 74.2897797,73.320816 74.2897797,73.320816 73.6848526,72.594902"
        />
        <polygon
          fill="#CCC"
          points="73.5638733,72.715889 73.5638733,72.8368683 74.4107666,73.6837692 74.2897797,73.320816"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,72.715889 73.5638733,72.8368683 74.4107666,73.6837692 74.2897797,73.320816"
        />
        <polygon
          fill={colors[3]}
          points="74.0478134,71.8689804 74.0478134,71.8689804 73.5638733,72.3529282"
        />
        <polygon
          fill={colors[3]}
          points="73.5638733,72.594902 73.6848526,72.594902 73.6848526,72.594902 74.2897797,73.320816 74.2897797,73.0788422"
        />
        <polygon
          fill={colors[4]}
          points="73.5638733,72.3529282 73.4428864,72.3529282 73.4428864,72.3529282 73.4428864,72.3529282 73.4428864,72.4739075 73.4428864,72.4739075 73.4428864,72.4739075 73.5638733,72.594902 74.2897797,73.0788422 74.0478134,71.8689804"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,73.8047638 67.8775482,73.8047638 68.7244415,74.6516571 68.7244415,74.772644 68.7244415,74.6516571"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,73.8047638 67.8775482,73.8047638 68.7244415,74.6516571 68.7244415,74.772644 68.7244415,74.6516571"
        />
        <polygon
          fill={colors[3]}
          points="67.8775482,73.8047638 67.8775482,73.8047638 68.7244415,74.6516571 68.8454285,74.6516571 68.2405014,74.1677094"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,73.8047638 67.8775482,73.8047638 68.7244415,74.6516571 68.8454285,74.6516571 68.2405014,74.1677094"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,73.6837692 67.8775482,73.8047638 67.8775482,73.8047638 67.8775482,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,73.6837692 67.8775482,73.8047638 67.8775482,73.8047638 67.8775482,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,73.6837692 67.8775482,73.8047638 67.8775482,73.8047638 67.8775482,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.772644 68.8454285,74.772644 68.7244415,74.772644 68.8454285,74.772644 68.7244415,74.8936234 68.7244415,74.8936234 68.8454285,74.772644 68.8454285,74.8936234 68.8454285,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.772644 68.8454285,74.772644 68.7244415,74.772644 68.8454285,74.772644 68.7244415,74.8936234 68.7244415,74.8936234 68.8454285,74.772644 68.8454285,74.8936234 68.8454285,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.772644 68.8454285,74.772644 68.7244415,74.772644 68.8454285,74.772644 68.7244415,74.8936234 68.7244415,74.8936234 68.8454285,74.772644 68.8454285,74.8936234 68.8454285,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.772644 68.8454285,74.772644 68.7244415,74.772644 68.8454285,74.772644 68.7244415,74.8936234 68.7244415,74.8936234 68.8454285,74.772644 68.8454285,74.8936234 68.8454285,74.8936234"
        />
        <polygon
          fill={colors[4]}
          points="68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.8936234 68.9664154,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.8936234 68.9664154,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.8936234 68.9664154,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.8936234 68.9664154,74.8936234"
        />
        <polygon
          fill={colors[3]}
          points="68.8454285,74.6516571 68.8454285,74.6516571 68.7244415,74.6516571 68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.6516571 68.8454285,74.6516571 68.7244415,74.6516571 68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.6516571 68.8454285,74.6516571 68.7244415,74.6516571 68.8454285,74.772644 68.8454285,74.772644 68.8454285,74.772644"
        />
        <polygon
          fill={colors[4]}
          points="68.8454285,74.6516571 68.8454285,74.772644 68.8454285,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.6516571 68.8454285,74.772644 68.8454285,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.6516571 68.8454285,74.772644 68.8454285,74.772644"
        />
        <polygon
          fill={colors[3]}
          points="68.7244415,74.8936234 68.8454285,74.8936234 68.8454285,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,74.8936234 68.8454285,74.8936234 68.8454285,74.772644"
        />
        <polygon
          fill={colors[4]}
          points="68.8454285,74.8936234 68.8454285,74.8936234 68.8454285,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,74.8936234 68.8454285,74.8936234 68.8454285,74.772644"
        />
        <polygon
          fill={colors[3]}
          points="67.6355743,74.04673 67.6355743,74.8936234 67.6355743,74.8936234 67.6355743,73.9257431"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,73.9257431 67.6355743,73.9257431 67.6355743,74.8936234 67.7565536,74.8936234"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,73.8047638 67.7565536,73.9257431 68.7244415,74.6516571"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,74.772644 68.7244415,74.772644 68.7244415,74.6516571 67.7565536,73.9257431 67.7565536,73.9257431 67.7565536,74.8936234 68.7244415,74.8936234 68.8454285,74.772644"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,74.8936234 67.6355743,74.8936234 67.6355743,74.04673"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,73.8047638 66.4257126,74.772644 66.4257126,74.6516571"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,72.715889 66.7886734,72.715889 67.5145874,73.441803 67.6355743,73.5627823 67.6355743,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,72.715889 66.7886734,72.715889 67.5145874,73.441803 67.6355743,73.5627823 67.6355743,73.5627823"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,73.6837692 67.8775482,73.6837692 67.7565536,73.5627823 67.7565536,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,73.6837692 67.8775482,73.6837692 67.7565536,73.5627823 67.7565536,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,73.6837692 67.8775482,73.6837692 67.7565536,73.5627823 67.7565536,73.5627823"
        />
        <polygon
          fill={colors[3]}
          points="67.1516266,72.9578552 66.7886734,72.715889 66.7886734,72.715889 67.6355743,73.5627823 67.6355743,73.5627823 67.7565536,73.5627823 67.7565536,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1516266,72.9578552 66.7886734,72.715889 66.7886734,72.715889 67.6355743,73.5627823 67.6355743,73.5627823 67.7565536,73.5627823 67.7565536,73.5627823"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,72.715889 67.1516266,72.9578552 66.7886734,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,72.715889 67.1516266,72.9578552 66.7886734,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,74.6516571 67.5145874,73.8047638 67.5145874,73.8047638 66.5466995,74.772644 66.4257126,74.6516571 66.4257126,74.772644 66.5466995,74.772644 66.5466995,74.8936234 66.5466995,74.8936234 66.5466995,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,74.6516571 67.5145874,73.8047638 67.5145874,73.8047638 66.5466995,74.772644 66.4257126,74.6516571 66.4257126,74.772644 66.5466995,74.772644 66.5466995,74.8936234 66.5466995,74.8936234 66.5466995,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,73.8047638 67.6355743,73.6837692 67.7565536,73.6837692 67.6355743,73.5627823 67.6355743,73.5627823 67.6355743,73.5627823 67.5145874,73.8047638 67.5145874,73.8047638 67.5145874,73.8047638 67.6355743,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,73.8047638 67.6355743,73.6837692 67.7565536,73.6837692 67.6355743,73.5627823 67.6355743,73.5627823 67.6355743,73.5627823 67.5145874,73.8047638 67.5145874,73.8047638 67.5145874,73.8047638 67.6355743,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,73.8047638 67.6355743,73.6837692 67.7565536,73.6837692 67.6355743,73.5627823 67.6355743,73.5627823 67.6355743,73.5627823 67.5145874,73.8047638 67.5145874,73.8047638 67.5145874,73.8047638 67.6355743,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,73.8047638 67.6355743,73.6837692 67.7565536,73.6837692 67.6355743,73.5627823 67.6355743,73.5627823 67.6355743,73.5627823 67.5145874,73.8047638 67.5145874,73.8047638 67.5145874,73.8047638 67.6355743,73.6837692"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,73.5627823 67.7565536,73.6837692 67.7565536,73.6837692 67.6355743,73.6837692 67.8775482,73.8047638 67.8775482,73.8047638 67.8775482,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,73.5627823 67.7565536,73.6837692 67.7565536,73.6837692 67.6355743,73.6837692 67.8775482,73.8047638 67.8775482,73.8047638 67.8775482,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,73.5627823 67.7565536,73.6837692 67.7565536,73.6837692 67.6355743,73.6837692 67.8775482,73.8047638 67.8775482,73.8047638 67.8775482,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,73.5627823 67.7565536,73.6837692 67.7565536,73.6837692 67.6355743,73.6837692 67.8775482,73.8047638 67.8775482,73.8047638 67.8775482,73.6837692"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,73.5627823 67.6355743,73.5627823 67.6355743,73.5627823 67.6355743,73.5627823 67.7565536,73.6837692 67.7565536,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,73.5627823 67.6355743,73.5627823 67.6355743,73.5627823 67.6355743,73.5627823 67.7565536,73.6837692 67.7565536,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,73.5627823 67.6355743,73.5627823 67.6355743,73.5627823 67.6355743,73.5627823 67.7565536,73.6837692 67.7565536,73.6837692"
        />
        <polygon
          fill={colors[3]}
          points="67.6355743,73.6837692 67.5145874,73.8047638 67.5145874,73.8047638 66.6676941,74.6516571 67.6355743,73.9257431 67.6355743,74.04673 67.6355743,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,73.6837692 67.5145874,73.8047638 67.5145874,73.8047638 66.6676941,74.6516571 67.6355743,73.9257431 67.6355743,74.04673 67.6355743,73.9257431"
        />
        <polygon
          fill={colors[4]}
          points="67.6355743,73.6837692 67.6355743,73.9257431 67.7565536,73.9257431 67.7565536,73.9257431 67.7565536,73.9257431 67.8775482,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,73.6837692 67.6355743,73.9257431 67.7565536,73.9257431 67.7565536,73.9257431 67.7565536,73.9257431 67.8775482,73.8047638"
        />
        <polygon
          fill={colors[4]}
          points="67.6355743,73.9257431 66.6676941,74.6516571 66.5466995,74.772644 66.5466995,74.8936234 66.7886734,74.8936234 67.6355743,74.04673"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,73.9257431 66.6676941,74.6516571 66.5466995,74.772644 66.5466995,74.8936234 66.7886734,74.8936234 67.6355743,74.04673"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,72.9578552 66.4257126,72.9578552 66.4257126,74.5306702 65.5788193,73.8047638 65.5788193,73.8047638 66.4257126,74.6516571 66.5466995,74.772644"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,72.8368683 67.5145874,73.441803 66.7886734,72.715889"
        />
        <polygon
          fill={colors[4]}
          points="67.5145874,73.8047638 66.5466995,74.5306702 66.5466995,72.8368683 66.5466995,72.9578552 66.5466995,74.772644"
        />
        <polygon
          fill="#CCC"
          points="67.6355743,73.5627823 67.6355743,73.5627823 67.5145874,73.441803 66.6676941,72.8368683 66.5466995,72.8368683 66.5466995,74.5306702 67.5145874,73.8047638"
        />
        <polygon
          fill={colors[4]}
          points="66.4257126,74.5306702 66.4257126,72.9578552 65.5788193,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6848526,74.8936234 73.6848526,74.8936234 73.8058395,74.772644 74.4107666,74.04673 74.4107666,73.9257431 73.5638733,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6848526,74.8936234 73.6848526,74.8936234 73.8058395,74.772644 74.4107666,74.04673 74.4107666,73.9257431 73.5638733,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,74.772644 73.5638733,74.8936234 73.5638733,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,74.772644 73.5638733,74.8936234 73.5638733,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,74.772644 73.5638733,74.8936234 73.5638733,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,74.772644 73.5638733,74.8936234 73.6848526,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,74.772644 73.5638733,74.8936234 73.6848526,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,74.772644 73.5638733,74.8936234 73.6848526,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,74.8936234 73.5638733,74.8936234 73.5638733,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,74.8936234 73.5638733,74.8936234 73.5638733,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,74.8936234 73.5638733,74.8936234 73.5638733,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,74.8936234 73.5638733,74.8936234 73.5638733,74.772644"
        />
        <polygon
          fill={colors[3]}
          points="74.4107666,74.1677094 74.4107666,74.04673 73.8058395,74.772644"
        />
        <polygon
          fill={colors[4]}
          points="73.8058395,74.772644 73.6848526,74.8936234 74.5317612,74.8936234 74.4107666,74.1677094"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,72.715889 64.4899445,72.715889 65.3368454,73.6837692 65.3368454,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.5627823 65.3368454,73.5627823 65.3368454,73.5627823 65.3368454,73.5627823 65.3368454,73.6837692 65.3368454,73.6837692 65.5788193,73.8047638 65.5788193,73.8047638 65.4578323,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.5627823 65.3368454,73.5627823 65.3368454,73.5627823 65.3368454,73.5627823 65.3368454,73.6837692 65.3368454,73.6837692 65.5788193,73.8047638 65.5788193,73.8047638 65.4578323,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.715889 66.7886734,72.715889 66.7886734,72.715889 66.7886734,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.715889 66.7886734,72.715889 66.7886734,72.715889 66.7886734,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.715889 66.7886734,72.715889 66.7886734,72.715889 66.7886734,72.715889"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,72.594902 66.7886734,72.715889 66.7886734,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,72.594902 66.7886734,72.715889 66.7886734,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,72.594902 66.7886734,72.715889 66.7886734,72.715889"
        />
        <polygon
          fill={colors[3]}
          points="65.5788193,73.8047638 65.5788193,73.8047638 65.4578323,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,73.8047638 65.5788193,73.8047638 65.4578323,73.8047638"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,72.8368683 66.4257126,72.715889 65.8207855,73.1998291 66.4257126,72.715889 66.4257126,72.9578552 66.5466995,72.9578552"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.8368683 66.4257126,72.715889 65.8207855,73.1998291 66.4257126,72.715889 66.4257126,72.9578552 66.5466995,72.9578552"
        />
        <polygon
          fill={colors[4]}
          points="66.5466995,72.8368683 66.5466995,72.8368683 66.5466995,72.9578552"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.8368683 66.5466995,72.8368683 66.5466995,72.9578552"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,72.715889 66.6676941,72.715889 66.6676941,72.8368683 66.7886734,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.715889 66.6676941,72.715889 66.6676941,72.8368683 66.7886734,72.715889"
        />
        <polygon
          fill="#CCC"
          points="66.6676941,72.715889 66.5466995,72.8368683 66.5466995,72.8368683 66.6676941,72.8368683"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.715889 66.5466995,72.8368683 66.5466995,72.8368683 66.6676941,72.8368683"
        />
        <polygon
          fill={colors[4]}
          points="66.4257126,72.715889 65.8207855,73.1998291 65.4578323,73.6837692 65.3368454,73.5627823 65.3368454,73.5627823 65.3368454,73.5627823 65.4578323,73.8047638 65.5788193,73.8047638 66.4257126,72.9578552"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,72.715889 65.8207855,73.1998291 65.4578323,73.6837692 65.3368454,73.5627823 65.3368454,73.5627823 65.3368454,73.5627823 65.4578323,73.8047638 65.5788193,73.8047638 66.4257126,72.9578552"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,71.7480011 65.3368454,71.7480011 65.3368454,73.441803 64.4899445,72.715889 64.4899445,72.715889 64.4899445,72.715889 65.3368454,73.5627823 65.3368454,73.5627823 65.3368454,73.5627823 65.4578323,73.6837692"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,73.441803 65.4578323,71.7480011 65.4578323,71.7480011 65.4578323,71.7480011 65.4578323,73.6837692 65.8207855,73.1998291 66.4257126,72.715889 66.4257126,72.715889"
        />
        <polygon
          fill="#CCC"
          points="65.4578323,71.7480011 65.4578323,73.441803 66.4257126,72.715889"
        />
        <polygon
          fill={colors[4]}
          points="65.3368454,71.7480011 64.4899445,72.715889 64.4899445,72.715889 65.3368454,73.441803"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.4739075 66.6676941,72.4739075 66.6676941,72.3529282 67.5145874,71.5060272 67.5145874,71.5060272 67.5145874,71.5060272 67.5145874,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.4739075 66.6676941,72.4739075 66.6676941,72.3529282 67.5145874,71.5060272 67.5145874,71.5060272 67.5145874,71.5060272 67.5145874,71.5060272"
        />
        <polygon
          fill={colors[4]}
          points="67.9985352,71.5060272 67.9985352,71.5060272 67.8775482,71.5060272 67.8775482,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,71.5060272 67.9985352,71.5060272 67.8775482,71.5060272 67.8775482,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,71.5060272 67.9985352,71.5060272 67.8775482,71.5060272 67.8775482,71.5060272"
        />
        <polygon
          fill={colors[3]}
          points="67.5145874,71.5060272 66.6676941,72.3529282 67.6355743,71.6270142 67.6355743,71.7480011 67.7565536,71.7480011 67.7565536,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,71.5060272 66.6676941,72.3529282 67.6355743,71.6270142 67.6355743,71.7480011 67.7565536,71.7480011 67.7565536,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,71.5060272 67.7565536,71.6270142 67.7565536,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,71.5060272 67.7565536,71.6270142 67.7565536,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,71.7480011"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,72.594902 67.6355743,71.7480011 67.6355743,71.6270142 66.6676941,72.3529282 66.6676941,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,72.594902 67.6355743,71.7480011 67.6355743,71.6270142 66.6676941,72.3529282 66.6676941,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,72.4739075 66.4257126,72.3529282 65.5788193,71.5060272 65.5788193,71.5060272 66.4257126,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,72.4739075 66.4257126,72.3529282 65.5788193,71.5060272 65.5788193,71.5060272 66.4257126,72.3529282"
        />
        <polygon
          fill={colors[7]}
          points="66.5466995,72.4739075 66.6676941,72.4739075 66.6676941,72.4739075 66.6676941,72.4739075"
        />
        <polygon
          fill={colors[7]}
          points="66.5466995,72.4739075 66.6676941,72.4739075 66.6676941,72.4739075 66.6676941,72.4739075"
        />
        <polygon
          fill={colors[7]}
          points="66.5466995,72.4739075 66.6676941,72.4739075 66.6676941,72.4739075 66.6676941,72.4739075"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,72.4739075 66.6676941,72.4739075 66.7886734,72.594902 66.7886734,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.4739075 66.6676941,72.4739075 66.7886734,72.594902 66.7886734,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.4739075 66.6676941,72.4739075 66.7886734,72.594902 66.7886734,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.594902 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.715889 66.7886734,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.594902 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.715889 66.7886734,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.594902 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.715889 66.7886734,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.594902 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.715889 66.7886734,72.715889"
        />
        <polygon
          fill={colors[3]}
          points="66.7886734,72.715889 66.7886734,72.715889 66.6676941,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,72.715889 66.7886734,72.715889 66.6676941,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,72.715889 66.7886734,72.715889 66.6676941,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,72.715889 66.7886734,72.715889 66.6676941,72.594902"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.594902 66.7886734,72.715889 66.7886734,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.594902 66.7886734,72.715889 66.7886734,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.594902 66.7886734,72.715889 66.7886734,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.6676941,72.594902 66.7886734,72.715889 66.7886734,72.594902"
        />
        <polygon
          fill={colors[3]}
          points="66.4257126,72.594902 66.4257126,72.715889 66.5466995,72.8368683 66.5466995,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,72.594902 66.4257126,72.715889 66.5466995,72.8368683 66.5466995,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,72.594902 66.4257126,72.715889 66.5466995,72.8368683 66.5466995,72.594902"
        />
        <polygon
          fill={colors[4]}
          points="66.5466995,72.4739075 66.5466995,72.594902 66.5466995,72.8368683 66.5466995,72.8368683 66.5466995,72.8368683 66.5466995,72.715889 66.6676941,72.715889 66.6676941,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.4739075 66.5466995,72.594902 66.5466995,72.8368683 66.5466995,72.8368683 66.5466995,72.8368683 66.5466995,72.715889 66.6676941,72.715889 66.6676941,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.4739075 66.5466995,72.594902 66.5466995,72.8368683 66.5466995,72.8368683 66.5466995,72.8368683 66.5466995,72.715889 66.6676941,72.715889 66.6676941,72.715889"
        />
        <polygon
          fill="#CCC"
          points="66.5466995,72.8368683 66.6676941,72.715889 66.5466995,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.8368683 66.6676941,72.715889 66.5466995,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.8368683 66.6676941,72.715889 66.5466995,72.715889"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,71.7480011 65.4578323,71.7480011 65.4578323,71.7480011 65.4578323,71.7480011 65.4578323,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.7480011 65.4578323,71.7480011 65.4578323,71.7480011 65.4578323,71.7480011 65.4578323,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,71.5060272 65.4578323,71.6270142 66.4257126,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,71.5060272 65.4578323,71.6270142 66.4257126,72.3529282"
        />
        <polygon
          fill={colors[4]}
          points="66.4257126,72.715889 66.4257126,72.715889 66.4257126,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,72.715889 66.4257126,72.715889 66.4257126,72.594902"
        />
        <polygon
          fill="#CCC"
          points="66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.4257126,72.4739075 66.4257126,72.3529282 65.4578323,71.6270142 65.4578323,71.6270142 65.4578323,71.7480011 66.4257126,72.715889 66.4257126,72.594902 66.5466995,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,72.4739075 66.4257126,72.4739075 66.4257126,72.3529282 65.4578323,71.6270142 65.4578323,71.6270142 65.4578323,71.7480011 66.4257126,72.715889 66.4257126,72.594902 66.5466995,72.594902"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,72.4739075 66.5466995,72.4739075 66.5466995,70.5381393 66.5466995,70.6591263 66.5466995,72.2319412 65.5788193,71.5060272 65.5788193,71.5060272 66.4257126,72.3529282"
        />
        <polygon
          fill={colors[4]}
          points="66.5466995,72.4739075 67.5145874,71.5060272 67.5145874,71.5060272 66.6676941,72.2319412 66.6676941,70.6591263 66.5466995,70.5381393 66.5466995,70.5381393"
        />
        <polygon
          fill="#CCC"
          points="66.6676941,70.6591263 66.6676941,72.2319412 67.5145874,71.5060272"
        />
        <polygon
          fill={colors[4]}
          points="66.5466995,72.2319412 66.5466995,70.6591263 65.5788193,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,70.41716 65.4578323,71.2640533 65.5788193,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,70.41716 65.4578323,71.2640533 65.5788193,71.2640533"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,71.6270142 65.5788193,71.5060272 65.5788193,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.6270142 65.5788193,71.5060272 65.5788193,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.6270142 65.5788193,71.5060272 65.5788193,71.5060272"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,71.6270142 65.4578323,71.6270142 65.4578323,71.7480011 65.4578323,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.6270142 65.4578323,71.6270142 65.4578323,71.7480011 65.4578323,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.6270142 65.4578323,71.6270142 65.4578323,71.7480011 65.4578323,71.7480011"
        />
        <polygon
          fill={colors[3]}
          points="65.5788193,71.2640533 66.4257126,70.41716 66.4257126,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,71.2640533 66.4257126,70.41716 66.4257126,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="66.5466995,70.6591263 66.5466995,70.5381393 66.4257126,70.41716 65.5788193,71.2640533 65.4578323,71.2640533 65.4578323,71.2640533 65.6998062,71.5060272 65.5788193,71.5060272 65.5788193,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.6591263 66.5466995,70.5381393 66.4257126,70.41716 65.5788193,71.2640533 65.4578323,71.2640533 65.4578323,71.2640533 65.6998062,71.5060272 65.5788193,71.5060272 65.5788193,71.5060272"
        />
        <polygon
          fill={colors[3]}
          points="64.8529053,70.6591263 65.4578323,71.2640533 65.4578323,69.4492722 65.3368454,69.5702591 65.3368454,71.143074"
        />
        <polygon
          fill={colors[4]}
          points="66.4257126,70.41716 66.4257126,70.41716 65.4578323,71.143074 65.4578323,69.4492722 65.4578323,69.4492722 65.4578323,69.4492722 65.4578323,71.2640533 65.4578323,71.2640533 65.4578323,71.2640533"
        />
        <polygon
          fill="#CCC"
          points="66.4257126,70.2961731 65.4578323,69.4492722 65.4578323,71.143074 66.4257126,70.41716 66.3047333,70.2961731"
        />
        <polygon
          fill={colors[4]}
          points="65.3368454,71.143074 65.3368454,69.5702591 64.4899445,70.2961731 64.8529053,70.6591263"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.1751938 66.5466995,70.1751938 65.6998062,69.2072983 65.5788193,69.3282852 66.4257126,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.1751938 66.5466995,70.1751938 65.6998062,69.2072983 65.5788193,69.3282852 66.4257126,70.0541992"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,69.3282852 65.5788193,69.3282852 66.4257126,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,69.3282852 65.5788193,69.3282852 66.4257126,70.0541992"
        />
        <polygon
          fill="#CCC"
          points="66.5466995,70.1751938 66.4257126,70.0541992 65.5788193,69.3282852 65.4578323,69.4492722 65.4578323,69.4492722 66.4257126,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.1751938 66.4257126,70.0541992 65.5788193,69.3282852 65.4578323,69.4492722 65.4578323,69.4492722 66.4257126,70.2961731"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,70.0541992 66.5466995,70.0541992 66.5466995,68.360405 66.5466995,68.360405 66.5466995,69.9332123 65.6998062,69.2072983 65.6998062,69.2072983 66.5466995,70.1751938"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,69.9332123 66.6676941,68.2394104 66.6676941,68.2394104 66.5466995,68.360405 66.5466995,70.0541992 66.9096603,69.691246"
        />
        <polygon
          fill="#CCC"
          points="67.5145874,69.086319 66.6676941,68.2394104 66.6676941,69.9332123 66.9096603,69.691246 67.5145874,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="66.5466995,69.9332123 66.5466995,68.360405 65.6998062,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,69.2072983 65.6998062,69.2072983 65.5788193,69.3282852 65.5788193,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,69.2072983 65.6998062,69.2072983 65.5788193,69.3282852 65.5788193,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,69.2072983 65.6998062,69.2072983 65.5788193,69.3282852 65.5788193,69.3282852"
        />
        <polygon
          fill="#CCC"
          points="65.5788193,69.3282852 65.5788193,69.3282852 65.4578323,69.3282852 65.4578323,69.4492722"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,69.3282852 65.5788193,69.3282852 65.4578323,69.3282852 65.4578323,69.4492722"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,69.3282852 65.5788193,69.3282852 65.4578323,69.3282852 65.4578323,69.4492722"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,68.1184311 66.5466995,68.360405 66.5466995,68.360405 66.5466995,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,68.1184311 66.5466995,68.360405 66.5466995,68.360405 66.5466995,68.2394104"
        />
        <polygon
          fill={colors[3]}
          points="66.4257126,68.1184311 65.8207855,68.7233582 66.5466995,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,68.1184311 65.8207855,68.7233582 66.5466995,68.1184311"
        />
        <polygon
          fill={colors[4]}
          points="66.5466995,68.2394104 66.5466995,68.360405 66.6676941,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,68.2394104 66.5466995,68.360405 66.6676941,68.2394104"
        />
        <polygon
          fill={colors[4]}
          points="66.5466995,68.360405 66.5466995,68.1184311 66.5466995,68.1184311 65.8207855,68.7233582 65.4578323,69.086319 65.6998062,69.2072983 65.6998062,69.2072983 65.6998062,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,68.360405 66.5466995,68.1184311 66.5466995,68.1184311 65.8207855,68.7233582 65.4578323,69.086319 65.6998062,69.2072983 65.6998062,69.2072983 65.6998062,69.2072983"
        />
        <polygon
          fill={colors[3]}
          points="64.7319107,68.2394104 65.4578323,68.965332 65.4578323,68.965332 65.4578323,67.2715302 65.4578323,67.2715302 65.4578323,68.8443375"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,69.086319 65.8207855,68.7233582 66.4257126,68.1184311 66.4257126,68.1184311 65.5788193,68.8443375 65.5788193,67.1505508 65.5788193,67.1505508 65.4578323,67.2715302 65.4578323,68.965332"
        />
        <polygon
          fill="#CCC"
          points="66.4257126,67.9974442 66.4257126,67.9974442 65.5788193,67.1505508 65.5788193,68.8443375 66.4257126,68.1184311"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,68.8443375 65.4578323,67.2715302 64.6109314,68.1184311 64.7319107,68.2394104"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,67.1505508 65.4578323,67.0295563 65.4578323,67.2715302 65.4578323,67.2715302"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,67.1505508 65.4578323,67.0295563 65.4578323,67.2715302 65.4578323,67.2715302"
        />
        <polygon
          fill={colors[3]}
          points="65.3368454,67.0295563 64.7319107,67.6344833 65.4578323,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,67.0295563 64.7319107,67.6344833 65.4578323,67.0295563"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,67.1505508 65.4578323,67.1505508 65.4578323,67.2715302"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,67.1505508 65.4578323,67.1505508 65.4578323,67.2715302"
        />
        <polygon
          fill={colors[4]}
          points="64.6109314,68.1184311 65.4578323,67.2715302 65.4578323,67.0295563 65.4578323,67.0295563 64.7319107,67.6344833 64.3689651,67.8764572 64.4899445,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,68.1184311 65.4578323,67.2715302 65.4578323,67.0295563 65.4578323,67.0295563 64.7319107,67.6344833 64.3689651,67.8764572 64.4899445,68.1184311"
        />
        <polygon
          fill={colors[3]}
          points="64.3689651,67.8764572 64.3689651,66.3036423 64.2479782,66.4246292 64.2479782,67.7554779 64.1269836,67.5134964 64.1269836,67.6344833 64.2479782,67.8764572"
        />
        <polygon
          fill={colors[4]}
          points="64.3689651,67.8764572 64.7319107,67.6344833 65.3368454,67.0295563 65.3368454,67.0295563 64.4899445,67.7554779 64.4899445,66.3036423 64.3689651,66.3036423"
        />
        <polygon
          fill="#CCC"
          points="65.2158585,66.9085693 65.3368454,66.9085693 64.4899445,66.182663 64.4899445,66.3036423 64.4899445,67.7554779 65.3368454,67.0295563"
        />
        <polygon
          fill={colors[4]}
          points="64.2479782,66.4246292 64.2479782,66.4246292 64.1269836,67.5134964 64.2479782,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,67.0295563 65.6998062,67.0295563 66.4257126,67.7554779 66.5466995,67.8764572 66.5466995,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,67.0295563 65.6998062,67.0295563 66.4257126,67.7554779 66.5466995,67.8764572 66.5466995,67.8764572"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,67.0295563 66.4257126,67.7554779 65.6998062,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,67.0295563 66.4257126,67.7554779 65.6998062,67.0295563"
        />
        <polygon
          fill="#CCC"
          points="66.4257126,67.9974442 66.5466995,67.8764572 66.4257126,67.7554779 65.5788193,67.0295563 65.5788193,67.1505508 65.5788193,67.1505508"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,67.9974442 66.5466995,67.8764572 66.4257126,67.7554779 65.5788193,67.0295563 65.5788193,67.1505508 65.5788193,67.1505508"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,67.0295563 65.4578323,67.0295563 65.4578323,67.1505508 65.4578323,67.0295563 65.3368454,66.9085693 65.3368454,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,67.0295563 65.4578323,67.0295563 65.4578323,67.1505508 65.4578323,67.0295563 65.3368454,66.9085693 65.3368454,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,67.0295563 65.4578323,67.0295563 65.4578323,67.1505508 65.4578323,67.0295563 65.3368454,66.9085693 65.3368454,67.0295563"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,67.1505508 65.5788193,67.1505508 65.5788193,67.1505508 65.4578323,67.0295563 65.4578323,67.1505508"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,67.1505508 65.5788193,67.1505508 65.5788193,67.1505508 65.4578323,67.0295563 65.4578323,67.1505508"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,67.1505508 65.5788193,67.1505508 65.5788193,67.1505508 65.4578323,67.0295563 65.4578323,67.1505508"
        />
        <polygon
          fill={colors[4]}
          points="65.6998062,67.0295563 65.6998062,67.0295563 65.5788193,67.0295563 65.5788193,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,67.0295563 65.6998062,67.0295563 65.5788193,67.0295563 65.5788193,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,67.0295563 65.6998062,67.0295563 65.5788193,67.0295563 65.5788193,67.0295563"
        />
        <polygon
          fill="#CCC"
          points="65.5788193,67.0295563 65.5788193,67.1505508 65.5788193,67.1505508"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,67.0295563 65.5788193,67.1505508 65.5788193,67.1505508"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,67.0295563 65.5788193,67.1505508 65.5788193,67.1505508"
        />
        <polygon
          fill="#CCC"
          points="65.3368454,66.9085693 65.2158585,66.9085693 65.3368454,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,66.9085693 65.2158585,66.9085693 65.3368454,67.0295563"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,67.8764572 66.6676941,67.7554779 66.6676941,66.061676 66.5466995,66.182663 66.5466995,67.6344833 65.6998062,66.9085693 65.6998062,66.9085693 65.6998062,67.0295563"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,67.6344833 66.6676941,66.061676 66.6676941,66.061676 66.6676941,66.061676 66.6676941,67.7554779"
        />
        <polygon
          fill="#CCC"
          points="66.6676941,66.061676 66.6676941,67.6344833 66.6676941,67.6344833 67.5145874,66.78759"
        />
        <polygon
          fill={colors[4]}
          points="66.5466995,67.6344833 66.5466995,66.182663 65.6998062,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,66.78759 65.4578323,66.6666031 64.7319107,65.9406891 64.7319107,66.061676 65.3368454,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,66.78759 65.4578323,66.6666031 64.7319107,65.9406891 64.7319107,66.061676 65.3368454,66.6666031"
        />
        <polygon
          fill={colors[4]}
          points="64.7319107,66.061676 64.6109314,66.061676 65.3368454,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.7319107,66.061676 64.6109314,66.061676 65.3368454,66.6666031"
        />
        <polygon
          fill="#CCC"
          points="65.3368454,66.9085693 65.4578323,66.78759 65.3368454,66.6666031 64.6109314,66.061676 64.4899445,66.182663"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,66.9085693 65.4578323,66.78759 65.3368454,66.6666031 64.6109314,66.061676 64.4899445,66.182663"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,66.6666031 65.4578323,66.6666031 65.4578323,66.78759 65.4578323,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,66.6666031 65.4578323,66.6666031 65.4578323,66.78759 65.4578323,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,66.6666031 65.4578323,66.6666031 65.4578323,66.78759 65.4578323,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,66.78759 65.5788193,66.78759 65.6998062,67.0295563 65.6998062,66.9085693 65.6998062,66.9085693 65.4578323,66.78759 65.4578323,66.78759 65.4578323,66.6666031 65.4578323,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,66.78759 65.5788193,66.78759 65.6998062,67.0295563 65.6998062,66.9085693 65.6998062,66.9085693 65.4578323,66.78759 65.4578323,66.78759 65.4578323,66.6666031 65.4578323,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,66.78759 65.5788193,66.78759 65.6998062,67.0295563 65.6998062,66.9085693 65.6998062,66.9085693 65.4578323,66.78759 65.4578323,66.78759 65.4578323,66.6666031 65.4578323,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,66.78759 65.5788193,66.78759 65.6998062,67.0295563 65.6998062,66.9085693 65.6998062,66.9085693 65.4578323,66.78759 65.4578323,66.78759 65.4578323,66.6666031 65.4578323,66.78759"
        />
        <polygon
          fill={colors[3]}
          points="65.3368454,66.9085693 65.3368454,66.9085693 65.4578323,67.0295563 65.4578323,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,66.9085693 65.3368454,66.9085693 65.4578323,67.0295563 65.4578323,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,66.9085693 65.3368454,66.9085693 65.4578323,67.0295563 65.4578323,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,66.9085693 65.3368454,66.9085693 65.4578323,67.0295563 65.4578323,66.9085693"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,67.1505508 65.5788193,67.0295563 65.5788193,67.0295563 65.6998062,67.0295563 65.5788193,66.78759 65.4578323,66.9085693 65.4578323,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,67.1505508 65.5788193,67.0295563 65.5788193,67.0295563 65.6998062,67.0295563 65.5788193,66.78759 65.4578323,66.9085693 65.4578323,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,67.1505508 65.5788193,67.0295563 65.5788193,67.0295563 65.6998062,67.0295563 65.5788193,66.78759 65.4578323,66.9085693 65.4578323,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,67.1505508 65.5788193,67.0295563 65.5788193,67.0295563 65.6998062,67.0295563 65.5788193,66.78759 65.4578323,66.9085693 65.4578323,67.0295563"
        />
        <polygon
          fill="#CCC"
          points="65.4578323,66.9085693 65.5788193,66.78759 65.5788193,66.78759 65.4578323,66.78759 65.4578323,66.78759 65.3368454,66.9085693 65.3368454,66.9085693 65.3368454,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,66.9085693 65.5788193,66.78759 65.5788193,66.78759 65.4578323,66.78759 65.4578323,66.78759 65.3368454,66.9085693 65.3368454,66.9085693 65.3368454,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,66.9085693 65.5788193,66.78759 65.5788193,66.78759 65.4578323,66.78759 65.4578323,66.78759 65.3368454,66.9085693 65.3368454,66.9085693 65.3368454,66.9085693"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,65.8197021 65.8207855,66.4246292 66.5466995,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,65.8197021 65.8207855,66.4246292 66.5466995,65.9406891"
        />
        <polygon
          fill={colors[3]}
          points="66.6676941,65.9406891 66.5466995,65.9406891 66.5466995,66.182663 66.6676941,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.9406891 66.5466995,65.9406891 66.5466995,66.182663 66.6676941,66.061676"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,65.9406891 66.6676941,66.061676 66.6676941,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.9406891 66.6676941,66.061676 66.6676941,66.061676"
        />
        <polygon
          fill={colors[4]}
          points="65.6998062,66.9085693 65.6998062,66.9085693 66.5466995,66.182663 66.5466995,65.9406891 66.5466995,65.9406891 65.8207855,66.4246292 65.5788193,66.78759 65.4578323,66.78759 65.4578323,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,66.9085693 65.6998062,66.9085693 66.5466995,66.182663 66.5466995,65.9406891 66.5466995,65.9406891 65.8207855,66.4246292 65.5788193,66.78759 65.4578323,66.78759 65.4578323,66.78759"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,66.6666031 65.4578323,66.78759 65.5788193,66.78759 65.5788193,65.2147751 65.4578323,65.2147751 65.4578323,66.5456238 64.7319107,65.9406891 64.7319107,65.9406891"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,66.78759 65.8207855,66.4246292 66.5466995,65.8197021 66.5466995,65.8197021 65.5788193,66.5456238 65.5788193,65.0937881 65.5788193,65.2147751"
        />
        <polygon
          fill="#CCC"
          points="66.4257126,65.6987152 66.4257126,65.6987152 65.6998062,64.9728012 65.5788193,65.0937881 65.5788193,66.5456238 66.5466995,65.8197021"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,66.5456238 65.4578323,65.2147751 64.7319107,65.9406891 64.7319107,65.9406891 64.7319107,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031 66.9096603,65.8197021 66.9096603,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031 66.9096603,65.8197021 66.9096603,65.8197021"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,65.9406891 67.6355743,66.6666031 66.9096603,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,65.9406891 67.6355743,66.6666031 66.9096603,65.8197021"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,66.061676 66.6676941,66.061676 66.6676941,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,66.061676 66.6676941,66.061676 66.6676941,65.9406891"
        />
        <polygon
          fill="#CCC"
          points="67.5145874,66.78759 67.7565536,66.6666031 67.6355743,66.6666031 66.7886734,65.9406891 66.6676941,65.9406891 66.6676941,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,66.78759 67.7565536,66.6666031 67.6355743,66.6666031 66.7886734,65.9406891 66.6676941,65.9406891 66.6676941,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,65.8197021 66.9096603,65.8197021 66.7886734,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,65.8197021 66.9096603,65.8197021 66.7886734,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,65.8197021 66.9096603,65.8197021 66.7886734,65.8197021"
        />
        <polygon
          fill={colors[3]}
          points="66.6676941,65.9406891 66.5466995,65.8197021 66.5466995,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.9406891 66.5466995,65.8197021 66.5466995,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.9406891 66.5466995,65.8197021 66.5466995,65.9406891"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,65.8197021 66.5466995,65.8197021 66.5466995,65.8197021 66.5466995,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,65.8197021 66.5466995,65.8197021 66.5466995,65.8197021 66.5466995,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,65.8197021 66.5466995,65.8197021 66.5466995,65.8197021 66.5466995,65.9406891"
        />
        <polygon
          fill={colors[4]}
          points="66.9096603,65.8197021 66.7886734,65.8197021 66.7886734,65.9406891 66.7886734,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,65.8197021 66.7886734,65.8197021 66.7886734,65.9406891 66.7886734,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,65.8197021 66.7886734,65.8197021 66.7886734,65.9406891 66.7886734,65.9406891"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891 66.6676941,66.061676"
        />
        <polygon
          fill="#CCC"
          points="66.7886734,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,65.9406891 66.6676941,65.9406891 66.6676941,65.9406891"
        />
        <polygon
          fill="#CCC"
          points="66.4257126,65.6987152 66.4257126,65.6987152 66.5466995,65.8197021 66.5466995,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,65.6987152 66.4257126,65.6987152 66.5466995,65.8197021 66.5466995,65.8197021"
        />
        <polygon
          fill={colors[3]}
          points="66.9096603,65.8197021 67.7565536,66.6666031 67.7565536,66.6666031 67.7565536,66.5456238 67.7565536,64.8518219 67.7565536,64.9728012 67.7565536,66.5456238"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,66.5456238 67.8775482,66.5456238 67.8775482,64.8518219 67.7565536,64.8518219 67.7565536,66.5456238"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,65.6987152 68.7244415,65.5777283 67.8775482,64.8518219 67.8775482,64.8518219 67.8775482,66.5456238 67.8775482,66.5456238"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,66.5456238 67.7565536,64.9728012 66.9096603,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,65.5777283 66.6676941,65.5777283 65.9417725,64.8518219 65.8207855,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,65.5777283 66.6676941,65.5777283 65.9417725,64.8518219 65.8207855,64.8518219"
        />
        <polygon
          fill={colors[4]}
          points="65.8207855,64.8518219 65.8207855,64.9728012 66.5466995,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,64.8518219 65.8207855,64.9728012 66.5466995,65.5777283"
        />
        <polygon
          fill="#CCC"
          points="66.4257126,65.6987152 66.5466995,65.5777283 66.5466995,65.5777283 66.5466995,65.5777283 65.8207855,64.9728012 65.6998062,64.9728012"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,65.6987152 66.5466995,65.5777283 66.5466995,65.5777283 66.5466995,65.5777283 65.8207855,64.9728012 65.6998062,64.9728012"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,65.8197021 66.7886734,65.6987152 66.6676941,65.6987152 66.6676941,65.6987152 66.7886734,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,65.8197021 66.7886734,65.6987152 66.6676941,65.6987152 66.6676941,65.6987152 66.7886734,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,65.8197021 66.7886734,65.6987152 66.6676941,65.6987152 66.6676941,65.6987152 66.7886734,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,65.8197021 66.7886734,65.6987152 66.6676941,65.6987152 66.6676941,65.6987152 66.7886734,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.5777283 66.6676941,65.5777283 66.7886734,65.5777283 66.7886734,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.5777283 66.6676941,65.5777283 66.7886734,65.5777283 66.7886734,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.5777283 66.6676941,65.5777283 66.7886734,65.5777283 66.7886734,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.5777283 66.6676941,65.5777283 66.7886734,65.5777283 66.7886734,65.5777283"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,65.8197021 66.6676941,65.9406891 66.6676941,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,65.8197021 66.6676941,65.9406891 66.6676941,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,65.8197021 66.6676941,65.9406891 66.6676941,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,65.8197021 66.6676941,65.9406891 66.6676941,65.6987152"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,65.9406891 66.6676941,65.8197021 66.7886734,65.9406891 66.7886734,65.8197021 66.6676941,65.6987152 66.6676941,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.9406891 66.6676941,65.8197021 66.7886734,65.9406891 66.7886734,65.8197021 66.6676941,65.6987152 66.6676941,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.9406891 66.6676941,65.8197021 66.7886734,65.9406891 66.7886734,65.8197021 66.6676941,65.6987152 66.6676941,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.9406891 66.6676941,65.8197021 66.7886734,65.9406891 66.7886734,65.8197021 66.6676941,65.6987152 66.6676941,65.6987152"
        />
        <polygon
          fill="#CCC"
          points="66.6676941,65.8197021 66.6676941,65.9406891 66.7886734,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.8197021 66.6676941,65.9406891 66.7886734,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.8197021 66.6676941,65.9406891 66.7886734,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.8197021 66.6676941,65.9406891 66.7886734,65.9406891"
        />
        <polygon
          fill="#CCC"
          points="66.6676941,65.6987152 66.6676941,65.6987152 66.6676941,65.6987152 66.6676941,65.5777283 66.6676941,65.5777283 66.5466995,65.5777283 66.4257126,65.6987152 66.5466995,65.8197021 66.5466995,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.6987152 66.6676941,65.6987152 66.6676941,65.6987152 66.6676941,65.5777283 66.6676941,65.5777283 66.5466995,65.5777283 66.4257126,65.6987152 66.5466995,65.8197021 66.5466995,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.6987152 66.6676941,65.6987152 66.6676941,65.6987152 66.6676941,65.5777283 66.6676941,65.5777283 66.5466995,65.5777283 66.4257126,65.6987152 66.5466995,65.8197021 66.5466995,65.8197021"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,64.9728012 67.7565536,64.8518219 67.7565536,64.7308273 67.6355743,64.609848 67.6355743,64.609848 67.0306396,65.335762 67.7565536,64.7308273"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.9728012 67.7565536,64.8518219 67.7565536,64.7308273 67.6355743,64.609848 67.6355743,64.609848 67.0306396,65.335762 67.7565536,64.7308273"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,64.8518219 67.7565536,64.7308273 67.7565536,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,64.8518219 67.7565536,64.7308273 67.7565536,64.8518219"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,65.5777283 66.6676941,65.6987152 66.7886734,65.6987152 66.9096603,65.8197021 67.7565536,64.9728012 67.7565536,64.7308273 67.0306396,65.335762"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,65.5777283 66.6676941,65.6987152 66.7886734,65.6987152 66.9096603,65.8197021 67.7565536,64.9728012 67.7565536,64.7308273 67.0306396,65.335762"
        />
        <polygon
          fill={colors[3]}
          points="66.6676941,65.5777283 66.6676941,65.5777283 66.6676941,65.5777283 66.6676941,65.5777283 66.6676941,64.004921 66.6676941,64.1259003 66.6676941,65.456749 65.9417725,64.8518219 65.9417725,64.8518219"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,65.5777283 67.0306396,65.335762 67.6355743,64.609848 66.7886734,65.456749 66.7886734,64.004921 66.6676941,64.004921"
        />
        <polygon
          fill="#CCC"
          points="67.5145874,64.4888611 67.5145874,64.4888611 66.9096603,63.7629471 66.7886734,64.004921 66.7886734,65.456749 67.6355743,64.609848 67.6355743,64.609848"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,65.456749 66.6676941,64.1259003 65.9417725,64.8518219 65.9417725,64.8518219 65.9417725,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,68.1184311 66.7886734,68.1184311 67.6355743,68.965332 67.6355743,68.965332 67.7565536,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,68.1184311 66.7886734,68.1184311 67.6355743,68.965332 67.6355743,68.965332 67.7565536,68.965332"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,68.2394104 67.6355743,68.965332 66.7886734,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,68.2394104 67.6355743,68.965332 66.7886734,68.1184311"
        />
        <polygon
          fill="#CCC"
          points="67.6355743,68.965332 67.6355743,68.965332 66.7886734,68.2394104 66.6676941,68.2394104 66.6676941,68.2394104 67.5145874,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,68.965332 67.6355743,68.965332 66.7886734,68.2394104 66.6676941,68.2394104 66.6676941,68.2394104 67.5145874,69.086319"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,68.1184311 66.5466995,68.1184311 66.5466995,68.2394104 66.5466995,68.2394104 66.4257126,68.1184311 66.4257126,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,68.1184311 66.5466995,68.1184311 66.5466995,68.2394104 66.5466995,68.2394104 66.4257126,68.1184311 66.4257126,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,68.1184311 66.5466995,68.1184311 66.5466995,68.2394104 66.5466995,68.2394104 66.4257126,68.1184311 66.4257126,68.1184311"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,68.2394104 66.6676941,68.2394104 66.6676941,68.2394104 66.5466995,68.2394104 66.5466995,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,68.2394104 66.6676941,68.2394104 66.6676941,68.2394104 66.5466995,68.2394104 66.5466995,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,68.2394104 66.6676941,68.2394104 66.6676941,68.2394104 66.5466995,68.2394104 66.5466995,68.2394104"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,68.1184311 66.7886734,68.1184311 66.6676941,68.2394104 66.7886734,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,68.1184311 66.7886734,68.1184311 66.6676941,68.2394104 66.7886734,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,68.1184311 66.7886734,68.1184311 66.6676941,68.2394104 66.7886734,68.2394104"
        />
        <polygon
          fill="#CCC"
          points="66.6676941,68.2394104 66.6676941,68.2394104 66.6676941,68.2394104 66.7886734,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,68.2394104 66.6676941,68.2394104 66.6676941,68.2394104 66.7886734,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,68.2394104 66.6676941,68.2394104 66.6676941,68.2394104 66.7886734,68.2394104"
        />
        <polygon
          fill="#CCC"
          points="66.4257126,67.9974442 66.4257126,67.9974442 66.4257126,68.1184311 66.4257126,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,67.9974442 66.4257126,67.9974442 66.4257126,68.1184311 66.4257126,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,68.1184311 66.7886734,67.9974442 66.6676941,67.8764572 66.6676941,67.8764572 66.5466995,67.8764572 66.5466995,67.8764572 66.6676941,67.8764572 66.6676941,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,68.1184311 66.7886734,67.9974442 66.6676941,67.8764572 66.6676941,67.8764572 66.5466995,67.8764572 66.5466995,67.8764572 66.6676941,67.8764572 66.6676941,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,68.1184311 66.7886734,67.9974442 66.6676941,67.8764572 66.6676941,67.8764572 66.5466995,67.8764572 66.5466995,67.8764572 66.6676941,67.8764572 66.6676941,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,68.1184311 66.7886734,67.9974442 66.6676941,67.8764572 66.6676941,67.8764572 66.5466995,67.8764572 66.5466995,67.8764572 66.6676941,67.8764572 66.6676941,67.9974442"
        />
        <polygon
          fill={colors[3]}
          points="66.4257126,68.1184311 66.4257126,68.1184311 66.5466995,68.2394104 66.5466995,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,68.1184311 66.4257126,68.1184311 66.5466995,68.2394104 66.5466995,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,68.1184311 66.4257126,68.1184311 66.5466995,68.2394104 66.5466995,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,68.1184311 66.4257126,68.1184311 66.5466995,68.2394104 66.5466995,67.9974442"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,68.2394104 66.6676941,68.1184311 66.6676941,68.2394104 66.7886734,68.1184311 66.6676941,67.9974442 66.5466995,67.9974442 66.5466995,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,68.2394104 66.6676941,68.1184311 66.6676941,68.2394104 66.7886734,68.1184311 66.6676941,67.9974442 66.5466995,67.9974442 66.5466995,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,68.2394104 66.6676941,68.1184311 66.6676941,68.2394104 66.7886734,68.1184311 66.6676941,67.9974442 66.5466995,67.9974442 66.5466995,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,68.2394104 66.6676941,68.1184311 66.6676941,68.2394104 66.7886734,68.1184311 66.6676941,67.9974442 66.5466995,67.9974442 66.5466995,68.2394104"
        />
        <polygon
          fill="#CCC"
          points="66.6676941,68.1184311 66.6676941,68.2394104 66.6676941,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,68.1184311 66.6676941,68.2394104 66.6676941,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,68.1184311 66.6676941,68.2394104 66.6676941,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,68.1184311 66.6676941,68.2394104 66.6676941,68.2394104"
        />
        <polygon
          fill="#CCC"
          points="66.4257126,68.1184311 66.4257126,68.1184311 66.5466995,67.9974442 66.6676941,67.9974442 66.6676941,67.8764572 66.5466995,67.8764572 66.5466995,67.8764572 66.4257126,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,68.1184311 66.4257126,68.1184311 66.5466995,67.9974442 66.6676941,67.9974442 66.6676941,67.8764572 66.5466995,67.8764572 66.5466995,67.8764572 66.4257126,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,68.1184311 66.4257126,68.1184311 66.5466995,67.9974442 66.6676941,67.9974442 66.6676941,67.8764572 66.5466995,67.8764572 66.5466995,67.8764572 66.4257126,67.9974442"
        />
        <polygon
          fill={colors[3]}
          points="66.6676941,67.8764572 66.6676941,67.8764572 66.6676941,67.7554779 66.5466995,67.8764572 66.5466995,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,67.8764572 66.6676941,67.8764572 66.6676941,67.7554779 66.5466995,67.8764572 66.5466995,67.8764572"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,67.8764572 66.7886734,67.7554779 67.3936005,67.0295563 66.6676941,67.6344833 66.6676941,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,67.8764572 66.7886734,67.7554779 67.3936005,67.0295563 66.6676941,67.6344833 66.6676941,67.7554779"
        />
        <polygon
          fill="#CCC"
          points="67.5145874,66.78759 66.6676941,67.6344833 67.3936005,67.0295563 67.6355743,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,66.78759 66.6676941,67.6344833 67.3936005,67.0295563 67.6355743,66.9085693"
        />
        <polygon
          fill="#CCC"
          points="67.5145874,66.78759 67.6355743,66.9085693 67.6355743,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,66.78759 67.6355743,66.9085693 67.6355743,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,66.78759 67.6355743,66.9085693 67.6355743,66.9085693"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,68.965332 67.7565536,68.8443375 67.7565536,67.0295563 67.7565536,67.0295563 67.7565536,68.7233582 66.7886734,67.9974442 66.7886734,68.1184311 66.7886734,68.1184311"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,67.0295563 67.6355743,66.9085693 67.6355743,66.9085693 67.3936005,67.0295563 66.7886734,67.7554779"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,68.7233582 67.8775482,67.1505508 67.7565536,67.0295563 67.7565536,68.8443375 68.1195145,68.4813919"
        />
        <polygon
          fill="#CCC"
          points="68.1195145,68.4813919 68.7244415,67.9974442 67.8775482,67.1505508 67.8775482,68.7233582"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,67.9974442 67.7565536,68.7233582 67.7565536,67.0295563 67.7565536,67.0295563 66.7886734,67.7554779 66.6676941,67.8764572 66.6676941,67.8764572 66.6676941,67.8764572"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,66.5456238 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,66.5456238 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,66.6666031 67.9985352,66.5456238 68.6034622,65.9406891 67.8775482,66.5456238 67.7565536,66.5456238 67.7565536,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,66.6666031 67.9985352,66.5456238 68.6034622,65.9406891 67.8775482,66.5456238 67.7565536,66.5456238 67.7565536,66.6666031"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,65.6987152 68.7244415,65.6987152 67.8775482,66.5456238 68.6034622,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,65.6987152 68.7244415,65.6987152 67.8775482,66.5456238 68.6034622,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.7554779 67.9985352,66.9085693 67.9985352,66.9085693 68.7244415,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.7554779 67.9985352,66.9085693 67.9985352,66.9085693 68.7244415,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,66.78759 67.7565536,66.78759 67.9985352,66.9085693 67.9985352,66.9085693 67.9985352,66.78759 67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,66.78759 67.7565536,66.78759 67.9985352,66.9085693 67.9985352,66.9085693 67.9985352,66.78759 67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,66.78759 67.7565536,66.78759 67.9985352,66.9085693 67.9985352,66.9085693 67.9985352,66.78759 67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,66.78759 67.7565536,66.78759 67.9985352,66.9085693 67.9985352,66.9085693 67.9985352,66.78759 67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          fill="#CCC"
          points="67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.6666031 67.5145874,66.78759 67.6355743,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.6666031 67.5145874,66.78759 67.6355743,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.6666031 67.5145874,66.78759 67.6355743,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.78759 67.7565536,66.6666031 67.5145874,66.78759 67.6355743,66.9085693"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,66.78759 67.7565536,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,66.78759 67.7565536,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,66.78759 67.7565536,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,66.78759 67.6355743,66.9085693 67.7565536,67.0295563 67.7565536,67.0295563 67.7565536,67.0295563 67.7565536,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,66.78759 67.6355743,66.9085693 67.7565536,67.0295563 67.7565536,67.0295563 67.7565536,67.0295563 67.7565536,67.0295563"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,67.0295563 67.8775482,67.1505508 67.8775482,67.0295563 68.7244415,67.7554779 67.9985352,66.9085693 67.9985352,66.9085693 67.7565536,66.78759 67.7565536,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,67.0295563 67.8775482,67.1505508 67.8775482,67.0295563 68.7244415,67.7554779 67.9985352,66.9085693 67.9985352,66.9085693 67.7565536,66.78759 67.7565536,66.78759"
        />
        <polygon
          fill="#CCC"
          points="68.8454285,67.7554779 68.7244415,67.7554779 67.8775482,67.0295563 67.8775482,67.1505508 68.7244415,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.7554779 68.7244415,67.7554779 67.8775482,67.0295563 67.8775482,67.1505508 68.7244415,67.9974442"
        />
        <polygon
          fill={colors[3]}
          points="68.8454285,65.8197021 68.7244415,65.6987152 68.6034622,65.9406891 67.9985352,66.5456238"
        />
        <polygon
          fill={colors[3]}
          points="68.8454285,67.7554779 68.8454285,67.7554779 68.8454285,67.7554779 68.9664154,67.7554779 68.9664154,65.8197021 68.8454285,65.8197021 68.8454285,67.6344833 67.9985352,66.78759 67.9985352,66.9085693 67.9985352,66.9085693"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,67.7554779 69.3293762,67.3925171 68.9664154,67.6344833 68.9664154,65.9406891 68.9664154,65.8197021"
        />
        <polygon
          fill="#CCC"
          points="69.3293762,67.3925171 69.8133163,66.78759 69.8133163,66.78759 69.9343033,66.78759 68.9664154,65.9406891 68.9664154,67.6344833"
        />
        <polygon
          fill={colors[4]}
          points="68.8454285,67.6344833 68.8454285,65.8197021 68.8454285,65.8197021 67.9985352,66.5456238 67.8775482,66.6666031 67.7565536,66.6666031 67.7565536,66.6666031 67.7565536,66.78759 67.7565536,66.78759 67.9985352,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.2158585,69.2072983 65.2158585,69.2072983 64.3689651,70.1751938 64.4899445,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.2158585,69.2072983 65.2158585,69.2072983 64.3689651,70.1751938 64.4899445,70.0541992"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,69.3282852 65.3368454,69.3282852 65.3368454,69.5702591 65.4578323,69.4492722"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.3282852 65.3368454,69.3282852 65.3368454,69.5702591 65.4578323,69.4492722"
        />
        <polygon
          fill={colors[3]}
          points="64.4899445,70.0541992 65.3368454,69.3282852 65.2158585,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.0541992 65.3368454,69.3282852 65.2158585,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,69.3282852 65.4578323,69.4492722 65.4578323,69.4492722"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.3282852 65.4578323,69.4492722 65.4578323,69.4492722"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,70.2961731 65.3368454,69.5702591 65.3368454,69.3282852 65.3368454,69.3282852 64.4899445,70.0541992 64.3689651,70.1751938 64.3689651,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.2961731 65.3368454,69.5702591 65.3368454,69.3282852 65.3368454,69.3282852 64.4899445,70.0541992 64.3689651,70.1751938 64.3689651,70.1751938"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,69.3282852 65.3368454,69.2072983 65.2158585,69.2072983 65.3368454,69.3282852 65.3368454,69.3282852 65.3368454,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.3282852 65.3368454,69.2072983 65.2158585,69.2072983 65.3368454,69.3282852 65.3368454,69.3282852 65.3368454,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.3282852 65.3368454,69.2072983 65.2158585,69.2072983 65.3368454,69.3282852 65.3368454,69.3282852 65.3368454,69.3282852"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,69.4492722 65.4578323,69.3282852 65.4578323,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.4492722 65.4578323,69.3282852 65.4578323,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.4492722 65.4578323,69.3282852 65.4578323,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,68.1184311 64.4899445,68.1184311 65.3368454,68.965332 65.3368454,68.965332 65.4578323,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,68.1184311 64.4899445,68.1184311 65.3368454,68.965332 65.3368454,68.965332 65.4578323,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,68.965332 65.3368454,68.965332 65.3368454,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,68.965332 65.3368454,68.965332 65.3368454,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,68.965332 65.3368454,68.965332 65.3368454,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,69.2072983 65.5788193,69.2072983 65.4578323,69.086319 65.4578323,69.086319 65.4578323,68.965332 65.3368454,68.965332 65.4578323,69.086319 65.4578323,69.086319 65.5788193,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,69.2072983 65.5788193,69.2072983 65.4578323,69.086319 65.4578323,69.086319 65.4578323,68.965332 65.3368454,68.965332 65.4578323,69.086319 65.4578323,69.086319 65.5788193,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,69.2072983 65.5788193,69.2072983 65.4578323,69.086319 65.4578323,69.086319 65.4578323,68.965332 65.3368454,68.965332 65.4578323,69.086319 65.4578323,69.086319 65.5788193,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,69.2072983 65.5788193,69.2072983 65.4578323,69.086319 65.4578323,69.086319 65.4578323,68.965332 65.3368454,68.965332 65.4578323,69.086319 65.4578323,69.086319 65.5788193,69.3282852"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,69.086319 65.3368454,69.2072983 65.3368454,69.2072983 65.4578323,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.086319 65.3368454,69.2072983 65.3368454,69.2072983 65.4578323,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.086319 65.3368454,69.2072983 65.3368454,69.2072983 65.4578323,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.086319 65.3368454,69.2072983 65.3368454,69.2072983 65.4578323,69.3282852"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,69.4492722 65.4578323,69.3282852 65.4578323,69.3282852 65.5788193,69.3282852 65.5788193,69.3282852 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.4492722 65.4578323,69.3282852 65.4578323,69.3282852 65.5788193,69.3282852 65.5788193,69.3282852 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.4492722 65.4578323,69.3282852 65.4578323,69.3282852 65.5788193,69.3282852 65.5788193,69.3282852 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.4492722 65.4578323,69.3282852 65.4578323,69.3282852 65.5788193,69.3282852 65.5788193,69.3282852 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.3282852"
        />
        <polygon
          fill="#CCC"
          points="65.4578323,69.3282852 65.4578323,69.3282852 65.5788193,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.3282852 65.4578323,69.3282852 65.5788193,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.3282852 65.4578323,69.3282852 65.5788193,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,69.3282852 65.4578323,69.3282852 65.5788193,69.3282852"
        />
        <polygon
          fill={colors[3]}
          points="65.5788193,69.2072983 65.6998062,69.2072983 65.6998062,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,69.2072983 65.6998062,69.2072983 65.6998062,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,69.2072983 65.6998062,69.2072983 65.6998062,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="65.6998062,69.2072983 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.5788193,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,69.2072983 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.5788193,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,69.2072983 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.5788193,69.2072983"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,68.965332 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.4578323,68.965332 65.4578323,68.965332 64.7319107,68.2394104 64.6109314,68.1184311 64.6109314,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,68.965332 65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.4578323,68.965332 65.4578323,68.965332 64.7319107,68.2394104 64.6109314,68.1184311 64.6109314,68.1184311"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,68.965332 65.4578323,69.086319 65.4578323,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,68.965332 65.4578323,69.086319 65.4578323,69.086319"
        />
        <polygon
          fill={colors[4]}
          points="64.6109314,68.1184311 64.6109314,68.1184311 64.7319107,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,68.1184311 64.6109314,68.1184311 64.7319107,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,68.1184311 64.4899445,68.1184311 64.4899445,68.1184311 64.4899445,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,68.1184311 64.4899445,68.1184311 64.4899445,68.1184311 64.4899445,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,68.1184311 64.4899445,68.1184311 64.4899445,68.1184311 64.4899445,68.1184311"
        />
        <polygon
          fill={colors[3]}
          points="64.6109314,68.1184311 64.4899445,68.1184311 64.4899445,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,68.1184311 64.4899445,68.1184311 64.4899445,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,68.1184311 64.4899445,68.1184311 64.4899445,68.1184311"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,68.1184311 64.4899445,68.1184311 64.6109314,68.1184311 64.6109314,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,68.1184311 64.4899445,68.1184311 64.6109314,68.1184311 64.6109314,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,68.1184311 64.4899445,68.1184311 64.6109314,68.1184311 64.6109314,68.1184311"
        />
        <polygon
          fill={colors[3]}
          points="64.2479782,70.0541992 64.2479782,70.1751938 64.2479782,68.360405 64.2479782,68.360405 64.2479782,69.9332123 63.7640343,69.5702591 63.7640343,69.5702591"
        />
        <polygon
          fill={colors[4]}
          points="64.3689651,70.1751938 64.3689651,70.1751938 65.2158585,69.2072983 65.3368454,69.2072983 65.3368454,69.2072983 64.3689651,69.9332123 64.3689651,68.2394104 64.2479782,68.360405 64.2479782,70.1751938"
        />
        <polygon
          fill={colors[4]}
          points="65.3368454,68.965332 64.4899445,68.1184311 64.4899445,68.1184311 64.3689651,68.2394104"
        />
        <polygon
          fill="#CCC"
          points="65.4578323,69.086319 65.4578323,69.086319 65.4578323,69.086319 65.3368454,68.965332 65.3368454,68.965332 65.3368454,68.965332 64.3689651,68.2394104 64.3689651,68.2394104 64.3689651,69.9332123 65.3368454,69.2072983 65.3368454,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="64.2479782,68.360405 63.8850174,68.7233582 63.7640343,69.5702591 64.2479782,69.9332123"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,67.8764572 64.2479782,67.8764572 64.0060043,67.6344833 64.0060043,67.7554779 64.1269836,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,67.8764572 64.2479782,67.8764572 64.0060043,67.6344833 64.0060043,67.7554779 64.1269836,67.7554779"
        />
        <polygon
          fill={colors[3]}
          points="64.2479782,67.8764572 64.1269836,67.6344833 64.0060043,67.6344833"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,67.8764572 64.1269836,67.6344833 64.0060043,67.6344833"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,68.1184311 64.0060043,68.2394104 64.0060043,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,68.1184311 64.0060043,68.2394104 64.0060043,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,68.1184311 64.4899445,68.1184311 64.3689651,67.8764572 64.3689651,67.8764572 64.2479782,67.8764572 64.2479782,67.8764572 64.3689651,67.9974442 64.2479782,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,68.1184311 64.4899445,68.1184311 64.3689651,67.8764572 64.3689651,67.8764572 64.2479782,67.8764572 64.2479782,67.8764572 64.3689651,67.9974442 64.2479782,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,68.1184311 64.4899445,68.1184311 64.3689651,67.8764572 64.3689651,67.8764572 64.2479782,67.8764572 64.2479782,67.8764572 64.3689651,67.9974442 64.2479782,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,68.1184311 64.4899445,68.1184311 64.3689651,67.8764572 64.3689651,67.8764572 64.2479782,67.8764572 64.2479782,67.8764572 64.3689651,67.9974442 64.2479782,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,68.1184311 64.1269836,68.1184311 64.2479782,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,68.1184311 64.1269836,68.1184311 64.2479782,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,68.1184311 64.1269836,68.1184311 64.2479782,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,68.1184311 64.1269836,68.1184311 64.2479782,67.9974442"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,68.1184311 64.3689651,67.8764572 64.3689651,67.9974442 64.3689651,67.8764572 64.3689651,67.8764572 64.3689651,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,68.1184311 64.3689651,67.8764572 64.3689651,67.9974442 64.3689651,67.8764572 64.3689651,67.8764572 64.3689651,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,68.1184311 64.3689651,67.8764572 64.3689651,67.9974442 64.3689651,67.8764572 64.3689651,67.8764572 64.3689651,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,68.1184311 64.3689651,67.8764572 64.3689651,67.9974442 64.3689651,67.8764572 64.3689651,67.8764572 64.3689651,67.8764572"
        />
        <polygon
          fill={colors[3]}
          points="64.3689651,67.8764572 64.3689651,67.8764572 64.3689651,67.9974442 64.3689651,67.8764572 64.2479782,67.8764572 64.2479782,67.8764572 64.2479782,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,67.8764572 64.3689651,67.8764572 64.3689651,67.9974442 64.3689651,67.8764572 64.2479782,67.8764572 64.2479782,67.8764572 64.2479782,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,67.8764572 64.3689651,67.8764572 64.3689651,67.9974442 64.3689651,67.8764572 64.2479782,67.8764572 64.2479782,67.8764572 64.2479782,67.8764572"
        />
        <polygon
          fill={colors[4]}
          points="64.3689651,67.9974442 64.3689651,67.8764572 64.3689651,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,67.9974442 64.3689651,67.8764572 64.3689651,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,67.9974442 64.3689651,67.8764572 64.3689651,67.8764572"
        />
        <polygon
          fill={colors[3]}
          points="64.2479782,68.1184311 64.2479782,68.360405 64.2479782,68.360405 64.2479782,67.9974442 64.2479782,67.9974442 64.1269836,68.1184311 64.1269836,68.1184311 64.0060043,68.2394104 63.8850174,68.360405"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,68.1184311 64.2479782,68.360405 64.2479782,68.360405 64.2479782,67.9974442 64.2479782,67.9974442 64.1269836,68.1184311 64.1269836,68.1184311 64.0060043,68.2394104 63.8850174,68.360405"
        />
        <polygon
          fill={colors[4]}
          points="64.3689651,68.2394104 64.3689651,68.1184311 64.3689651,68.2394104 64.4899445,68.1184311 64.2479782,67.9974442 64.2479782,67.9974442 64.2479782,68.360405"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,68.2394104 64.3689651,68.1184311 64.3689651,68.2394104 64.4899445,68.1184311 64.2479782,67.9974442 64.2479782,67.9974442 64.2479782,68.360405"
        />
        <polygon
          fill="#CCC"
          points="64.3689651,68.1184311 64.3689651,68.2394104 64.3689651,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,68.1184311 64.3689651,68.2394104 64.3689651,68.2394104"
        />
        <polygon
          fill={colors[4]}
          points="64.2479782,68.1184311 63.8850174,68.360405 63.8850174,68.7233582 64.2479782,68.360405"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,68.1184311 63.8850174,68.360405 63.8850174,68.7233582 64.2479782,68.360405"
        />
        <polygon
          fill={colors[4]}
          points="64.0060043,67.7554779 64.0060043,67.7554779 64.1269836,67.7554779"
        />
        <polygon
          fill={colors[4]}
          points="64.1269836,68.1184311 64.1269836,68.1184311 64.0060043,68.2394104 64.0060043,68.2394104"
        />
        <polygon
          fill="#CCC"
          points="64.1269836,68.1184311 64.2479782,67.9974442 64.2479782,67.9974442 64.2479782,67.9974442 64.3689651,67.9974442 64.2479782,67.8764572 64.2479782,67.8764572 64.1269836,67.7554779 64.0060043,67.7554779 64.0060043,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,71.2640533 68.6034622,70.41716 68.6034622,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.2961731 67.7565536,71.2640533 67.8775482,71.3850479 67.9985352,71.2640533 68.7244415,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.2961731 67.7565536,71.2640533 67.8775482,71.3850479 67.9985352,71.2640533 68.7244415,70.41716"
        />
        <polygon
          fill={colors[3]}
          points="67.9985352,71.2640533 68.8454285,70.5381393 68.7244415,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,71.2640533 68.8454285,70.5381393 68.7244415,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="68.8454285,70.5381393 67.9985352,71.2640533 67.8775482,71.3850479 67.9985352,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,70.5381393 67.9985352,71.2640533 67.8775482,71.3850479 67.9985352,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,70.2961731 69.0874023,70.41716 69.0874023,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,70.2961731 69.0874023,70.41716 69.0874023,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,70.2961731 69.0874023,70.41716 69.0874023,70.41716"
        />
        <polygon
          fill={colors[3]}
          points="68.9664154,70.41716 68.8454285,70.5381393 68.8454285,70.5381393 68.9664154,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,70.41716 68.8454285,70.5381393 68.8454285,70.5381393 68.9664154,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,70.41716 68.8454285,70.5381393 68.8454285,70.5381393 68.9664154,70.5381393"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,70.41716 68.9664154,70.5381393 69.0874023,70.41716 69.0874023,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,70.41716 68.9664154,70.5381393 69.0874023,70.41716 69.0874023,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,70.41716 68.9664154,70.5381393 69.0874023,70.41716 69.0874023,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.2640533 67.7565536,71.2640533 67.8775482,71.3850479 67.8775482,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.2640533 67.7565536,71.2640533 67.8775482,71.3850479 67.8775482,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.2640533 67.7565536,71.2640533 67.8775482,71.3850479 67.8775482,71.3850479"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,71.3850479 67.8775482,71.3850479 67.9985352,71.5060272 67.9985352,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,71.3850479 67.8775482,71.3850479 67.9985352,71.5060272 67.9985352,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,71.3850479 67.8775482,71.3850479 67.9985352,71.5060272 67.9985352,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,70.41716 67.5145874,71.2640533 67.6355743,71.2640533 67.6355743,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,70.41716 67.5145874,71.2640533 67.6355743,71.2640533 67.6355743,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.3850479 67.6355743,71.2640533 67.6355743,71.2640533 67.7565536,71.3850479 67.7565536,71.2640533 67.7565536,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.3850479 67.6355743,71.2640533 67.6355743,71.2640533 67.7565536,71.3850479 67.7565536,71.2640533 67.7565536,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.3850479 67.6355743,71.2640533 67.6355743,71.2640533 67.7565536,71.3850479 67.7565536,71.2640533 67.7565536,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,71.5060272 67.5145874,71.5060272 67.6355743,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,71.5060272 67.5145874,71.5060272 67.6355743,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,71.5060272 67.5145874,71.5060272 67.6355743,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.3850479 67.8775482,71.3850479 67.7565536,71.2640533 67.7565536,71.3850479 67.7565536,71.3850479 67.6355743,71.5060272 67.5145874,71.5060272 67.5145874,71.5060272 67.7565536,71.3850479 67.8775482,71.5060272 67.8775482,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.3850479 67.8775482,71.3850479 67.7565536,71.2640533 67.7565536,71.3850479 67.7565536,71.3850479 67.6355743,71.5060272 67.5145874,71.5060272 67.5145874,71.5060272 67.7565536,71.3850479 67.8775482,71.5060272 67.8775482,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.3850479 67.8775482,71.3850479 67.7565536,71.2640533 67.7565536,71.3850479 67.7565536,71.3850479 67.6355743,71.5060272 67.5145874,71.5060272 67.5145874,71.5060272 67.7565536,71.3850479 67.8775482,71.5060272 67.8775482,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.3850479 67.8775482,71.3850479 67.7565536,71.2640533 67.7565536,71.3850479 67.7565536,71.3850479 67.6355743,71.5060272 67.5145874,71.5060272 67.5145874,71.5060272 67.7565536,71.3850479 67.8775482,71.5060272 67.8775482,71.5060272"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,71.3850479 67.7565536,71.3850479 67.8775482,71.5060272 67.9985352,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,71.3850479 67.7565536,71.3850479 67.8775482,71.5060272 67.9985352,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,71.3850479 67.7565536,71.3850479 67.8775482,71.5060272 67.9985352,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,71.3850479 67.7565536,71.3850479 67.8775482,71.5060272 67.9985352,71.5060272"
        />
        <polygon
          fill={colors[3]}
          points="67.5145874,71.5060272 67.7565536,71.6270142 67.7565536,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,71.5060272 67.7565536,71.6270142 67.7565536,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,71.5060272 67.7565536,71.6270142 67.7565536,71.3850479"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,71.6270142 67.8775482,71.5060272 67.7565536,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,71.6270142 67.8775482,71.5060272 67.7565536,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,71.6270142 67.7565536,71.6270142 67.8775482,71.5060272 67.7565536,71.3850479"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,70.5381393 67.5145874,71.2640533 66.7886734,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,70.5381393 67.5145874,71.2640533 66.7886734,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="66.5466995,70.5381393 66.6676941,70.6591263 66.6676941,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.5381393 66.6676941,70.6591263 66.6676941,70.5381393"
        />
        <polygon
          fill="#CCC"
          points="67.5145874,71.2640533 66.6676941,70.5381393 66.6676941,70.5381393 66.6676941,70.6591263 67.5145874,71.5060272 67.5145874,71.5060272 67.6355743,71.5060272 67.7565536,71.3850479 67.7565536,71.3850479 67.6355743,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,71.2640533 66.6676941,70.5381393 66.6676941,70.5381393 66.6676941,70.6591263 67.5145874,71.5060272 67.5145874,71.5060272 67.6355743,71.5060272 67.7565536,71.3850479 67.7565536,71.3850479 67.6355743,71.2640533"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,70.5381393 66.4257126,70.41716 66.4257126,70.41716 66.4257126,70.41716 66.5466995,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.5381393 66.4257126,70.41716 66.4257126,70.41716 66.4257126,70.41716 66.5466995,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.5381393 66.4257126,70.41716 66.4257126,70.41716 66.4257126,70.41716 66.5466995,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,70.41716 66.6676941,70.41716 66.6676941,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,70.41716 66.6676941,70.41716 66.6676941,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,70.41716 66.6676941,70.41716 66.6676941,70.5381393"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,70.5381393 66.5466995,70.5381393 66.5466995,70.5381393 66.5466995,70.5381393 66.5466995,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,70.5381393 66.5466995,70.5381393 66.5466995,70.5381393 66.5466995,70.5381393 66.5466995,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,70.5381393 66.5466995,70.5381393 66.5466995,70.5381393 66.5466995,70.5381393 66.5466995,70.5381393"
        />
        <polygon
          fill="#CCC"
          points="66.6676941,70.5381393 66.6676941,70.5381393 66.6676941,70.5381393 66.6676941,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,70.5381393 66.6676941,70.5381393 66.6676941,70.5381393 66.6676941,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,70.5381393 66.6676941,70.5381393 66.6676941,70.5381393 66.6676941,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="66.4257126,70.41716 66.5466995,70.5381393 66.5466995,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,70.41716 66.5466995,70.5381393 66.5466995,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,70.41716 66.5466995,70.5381393 66.5466995,70.41716"
        />
        <polygon
          fill="#CCC"
          points="66.4257126,70.2961731 66.3047333,70.2961731 66.4257126,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,70.2961731 66.3047333,70.2961731 66.4257126,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,69.2072983 67.5145874,69.2072983 66.6676941,70.0541992 66.7886734,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,69.2072983 67.5145874,69.2072983 66.6676941,70.0541992 66.7886734,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.1751938 66.5466995,70.1751938 66.6676941,70.2961731 66.6676941,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.1751938 66.5466995,70.1751938 66.6676941,70.2961731 66.6676941,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.1751938 66.5466995,70.1751938 66.6676941,70.2961731 66.6676941,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.1751938 66.5466995,70.1751938 66.6676941,70.2961731 66.6676941,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,70.2961731 66.4257126,70.2961731 66.4257126,70.41716 66.5466995,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,70.2961731 66.4257126,70.2961731 66.4257126,70.41716 66.5466995,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,70.2961731 66.4257126,70.2961731 66.4257126,70.41716 66.5466995,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,70.2961731 66.4257126,70.2961731 66.4257126,70.41716 66.5466995,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,70.2961731 66.5466995,70.1751938 66.5466995,70.1751938 66.7886734,70.41716 66.7886734,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,70.2961731 66.5466995,70.1751938 66.5466995,70.1751938 66.7886734,70.41716 66.7886734,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,70.2961731 66.5466995,70.1751938 66.5466995,70.1751938 66.7886734,70.41716 66.7886734,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,70.2961731 66.5466995,70.1751938 66.5466995,70.1751938 66.7886734,70.41716 66.7886734,70.41716"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,70.2961731 66.4257126,70.41716 66.5466995,70.5381393 66.5466995,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.2961731 66.4257126,70.41716 66.5466995,70.5381393 66.5466995,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.2961731 66.4257126,70.41716 66.5466995,70.5381393 66.5466995,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.2961731 66.4257126,70.41716 66.5466995,70.5381393 66.5466995,70.1751938"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,70.5381393 66.6676941,70.41716 66.6676941,70.41716 66.7886734,70.41716 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.5381393 66.5466995,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,70.5381393 66.6676941,70.41716 66.6676941,70.41716 66.7886734,70.41716 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.5381393 66.5466995,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,70.5381393 66.6676941,70.41716 66.6676941,70.41716 66.7886734,70.41716 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.5381393 66.5466995,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,70.5381393 66.6676941,70.41716 66.6676941,70.41716 66.7886734,70.41716 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.5381393 66.5466995,70.5381393"
        />
        <polygon
          fill="#CCC"
          points="66.5466995,70.2961731 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.4257126,70.2961731 66.4257126,70.2961731 66.4257126,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.2961731 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.4257126,70.2961731 66.4257126,70.2961731 66.4257126,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.2961731 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.4257126,70.2961731 66.4257126,70.2961731 66.4257126,70.2961731"
        />
        <polygon
          fill={colors[3]}
          points="66.5466995,70.1751938 66.5466995,70.0541992 66.5466995,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.1751938 66.5466995,70.0541992 66.5466995,70.0541992"
        />
        <polygon
          fill={colors[4]}
          points="66.5466995,70.1751938 66.6676941,70.0541992 67.5145874,69.2072983 67.5145874,69.2072983 66.9096603,69.691246 66.5466995,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,70.1751938 66.6676941,70.0541992 67.5145874,69.2072983 67.5145874,69.2072983 66.9096603,69.691246 66.5466995,70.0541992"
        />
        <polygon
          fill="#CCC"
          points="66.9096603,69.691246 67.5145874,69.2072983 67.5145874,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,69.691246 67.5145874,69.2072983 67.5145874,69.2072983"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,69.4492722 67.6355743,69.3282852 67.6355743,71.143074 66.7886734,70.2961731 66.7886734,70.41716 66.7886734,70.41716 67.6355743,71.2640533 67.7565536,71.3850479"
        />
        <polygon
          fill={colors[3]}
          points="66.7886734,70.0541992 67.6355743,69.3282852 67.5145874,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="68.7244415,70.2961731 67.7565536,71.143074 67.7565536,69.4492722 67.7565536,69.4492722 67.7565536,71.3850479 67.7565536,71.2640533 67.7565536,71.2640533"
        />
        <polygon
          fill="#CCC"
          points="67.7565536,71.143074 68.7244415,70.2961731 67.7565536,69.4492722"
        />
        <polygon
          fill={colors[4]}
          points="67.6355743,71.143074 67.6355743,69.3282852 67.6355743,69.3282852 66.7886734,70.0541992 66.6676941,70.0541992 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.5466995,70.1751938 66.7886734,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,70.1751938 68.9664154,70.1751938 68.9664154,70.1751938 68.9664154,70.1751938 69.8133163,69.2072983 69.8133163,69.2072983 69.8133163,69.2072983 69.8133163,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,70.1751938 69.0874023,70.0541992 69.9343033,69.2072983 69.8133163,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,70.1751938 69.0874023,70.0541992 69.9343033,69.2072983 69.8133163,69.2072983"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,69.3282852 69.9343033,69.2072983 69.0874023,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.3282852 69.9343033,69.2072983 69.0874023,70.0541992"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.4492722"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.4492722"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,70.1751938 68.9664154,70.1751938 69.0874023,70.2961731 70.0552826,69.4492722 70.0552826,69.3282852 70.0552826,69.3282852 69.0874023,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,70.1751938 68.9664154,70.1751938 69.0874023,70.2961731 70.0552826,69.4492722 70.0552826,69.3282852 70.0552826,69.3282852 69.0874023,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,69.2072983 69.9343033,69.2072983 69.8133163,69.2072983 69.8133163,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,69.2072983 69.9343033,69.2072983 69.8133163,69.2072983 69.8133163,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,69.2072983 69.9343033,69.2072983 69.8133163,69.2072983 69.8133163,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.2072983 70.1762695,69.2072983 70.2972641,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.2072983 70.1762695,69.2072983 70.2972641,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.2072983 70.1762695,69.2072983 70.2972641,69.2072983"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 69.9343033,69.2072983 69.9343033,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 69.9343033,69.2072983 69.9343033,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 69.9343033,69.2072983 69.9343033,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 70.1762695,69.2072983 70.1762695,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 70.1762695,69.2072983 70.1762695,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 70.1762695,69.2072983 70.1762695,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,69.2072983 70.2972641,69.2072983 70.2972641,69.2072983 70.2972641,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.2072983 70.2972641,69.2072983 70.2972641,69.2072983 70.2972641,69.2072983"
        />
        <polygon
          fill="#CCC"
          points="67.5145874,69.2072983 67.5145874,69.086319 67.5145874,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,69.2072983 67.5145874,69.086319 67.5145874,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,68.965332 67.8775482,68.8443375 68.7244415,68.1184311 68.7244415,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,68.965332 67.8775482,68.8443375 68.7244415,68.1184311 68.7244415,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,68.965332 67.7565536,68.965332 67.6355743,68.965332 67.6355743,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,68.965332 67.7565536,68.965332 67.6355743,68.965332 67.6355743,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,68.965332 67.7565536,68.965332 67.6355743,68.965332 67.6355743,68.965332"
        />
        <polygon
          fill="#CCC"
          points="67.6355743,68.965332 67.6355743,68.965332 67.5145874,69.086319 67.5145874,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,68.965332 67.6355743,68.965332 67.5145874,69.086319 67.5145874,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,68.965332 67.6355743,68.965332 67.5145874,69.086319 67.5145874,69.086319"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,68.965332 67.7565536,68.965332 67.7565536,68.8443375"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,68.965332 67.7565536,68.965332 67.7565536,68.8443375"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,68.965332 67.7565536,68.965332 67.8775482,68.965332 68.7244415,68.1184311 68.7244415,68.1184311 68.1195145,68.4813919 67.7565536,68.8443375"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,68.965332 67.7565536,68.965332 67.8775482,68.965332 68.7244415,68.1184311 68.7244415,68.1184311 68.1195145,68.4813919 67.7565536,68.8443375"
        />
        <polygon
          fill="#CCC"
          points="68.1195145,68.4813919 68.7244415,68.1184311 68.6034622,67.9974442 68.7244415,67.9974442 68.7244415,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,68.4813919 68.7244415,68.1184311 68.6034622,67.9974442 68.7244415,67.9974442 68.7244415,67.9974442"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,67.9974442 68.7244415,67.9974442 68.8454285,67.7554779 68.8454285,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,67.9974442 68.7244415,67.9974442 68.8454285,67.7554779 68.8454285,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,67.9974442 68.7244415,67.9974442 68.8454285,67.7554779 68.8454285,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.0541992 68.8454285,70.1751938 68.9664154,70.1751938 68.9664154,70.1751938 68.8454285,70.1751938 67.8775482,69.2072983 67.8775482,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.0541992 68.8454285,70.1751938 68.9664154,70.1751938 68.9664154,70.1751938 68.8454285,70.1751938 67.8775482,69.2072983 67.8775482,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.2961731 68.7244415,70.41716 68.7244415,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.2961731 68.7244415,70.41716 68.7244415,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.2961731 68.7244415,70.41716 68.7244415,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,70.1751938 68.9664154,70.1751938 68.9664154,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,70.1751938 68.9664154,70.1751938 68.9664154,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,70.1751938 68.9664154,70.1751938 68.9664154,70.1751938"
        />
        <polygon
          fill={colors[3]}
          points="68.7244415,70.41716 68.7244415,70.41716 68.8454285,70.5381393 68.8454285,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.41716 68.7244415,70.41716 68.8454285,70.5381393 68.8454285,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.41716 68.7244415,70.41716 68.8454285,70.5381393 68.8454285,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,70.1751938 68.8454285,70.1751938 68.7244415,70.2961731 68.7244415,70.2961731 68.7244415,70.41716 68.9664154,70.2961731 69.0874023,70.41716 69.0874023,70.2961731 68.9664154,70.1751938 68.9664154,70.1751938 68.9664154,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,70.1751938 68.8454285,70.1751938 68.7244415,70.2961731 68.7244415,70.2961731 68.7244415,70.41716 68.9664154,70.2961731 69.0874023,70.41716 69.0874023,70.2961731 68.9664154,70.1751938 68.9664154,70.1751938 68.9664154,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,70.1751938 68.8454285,70.1751938 68.7244415,70.2961731 68.7244415,70.2961731 68.7244415,70.41716 68.9664154,70.2961731 69.0874023,70.41716 69.0874023,70.2961731 68.9664154,70.1751938 68.9664154,70.1751938 68.9664154,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,70.1751938 68.8454285,70.1751938 68.7244415,70.2961731 68.7244415,70.2961731 68.7244415,70.41716 68.9664154,70.2961731 69.0874023,70.41716 69.0874023,70.2961731 68.9664154,70.1751938 68.9664154,70.1751938 68.9664154,70.1751938"
        />
        <polygon
          fill={colors[3]}
          points="68.7244415,70.41716 68.8454285,70.5381393 68.8454285,70.5381393 68.8454285,70.5381393 68.8454285,70.5381393 68.9664154,70.41716 68.9664154,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.41716 68.8454285,70.5381393 68.8454285,70.5381393 68.8454285,70.5381393 68.8454285,70.5381393 68.9664154,70.41716 68.9664154,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.41716 68.8454285,70.5381393 68.8454285,70.5381393 68.8454285,70.5381393 68.8454285,70.5381393 68.9664154,70.41716 68.9664154,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.41716 68.8454285,70.5381393 68.8454285,70.5381393 68.8454285,70.5381393 68.8454285,70.5381393 68.9664154,70.41716 68.9664154,70.2961731"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,70.2961731 68.9664154,70.41716 69.0874023,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,70.2961731 68.9664154,70.41716 69.0874023,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,70.2961731 68.9664154,70.41716 69.0874023,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,70.2961731 68.9664154,70.41716 69.0874023,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,70.2961731 69.0874023,70.2961731 68.9664154,70.1751938 68.9664154,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,70.2961731 69.0874023,70.2961731 68.9664154,70.1751938 68.9664154,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,70.2961731 69.0874023,70.2961731 68.9664154,70.1751938 68.9664154,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.2072983 67.6355743,69.2072983 67.5145874,69.2072983 67.5145874,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.2072983 67.6355743,69.2072983 67.5145874,69.2072983 67.5145874,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.2072983 67.6355743,69.2072983 67.5145874,69.2072983 67.5145874,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="67.6355743,69.2072983 67.5145874,69.2072983 67.5145874,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.2072983 67.5145874,69.2072983 67.5145874,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.2072983 67.5145874,69.2072983 67.5145874,69.2072983"
        />
        <polygon
          fill="#CCC"
          points="67.5145874,69.086319 67.5145874,69.2072983 67.5145874,69.2072983 67.6355743,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,69.086319 67.5145874,69.2072983 67.5145874,69.2072983 67.6355743,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,69.086319 67.5145874,69.2072983 67.5145874,69.2072983 67.6355743,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,68.965332 67.7565536,68.965332 67.8775482,68.965332 67.8775482,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,68.965332 67.7565536,68.965332 67.8775482,68.965332 67.8775482,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,68.965332 67.7565536,68.965332 67.8775482,68.965332 67.8775482,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,68.965332 67.7565536,68.965332 67.8775482,68.965332 67.8775482,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,69.086319 67.8775482,69.2072983 67.8775482,69.2072983 67.8775482,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,69.086319 67.8775482,69.2072983 67.8775482,69.2072983 67.8775482,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,69.086319 67.8775482,69.2072983 67.8775482,69.2072983 67.8775482,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,69.086319 67.8775482,69.2072983 67.8775482,69.2072983 67.8775482,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.2072983 67.6355743,69.2072983 67.6355743,69.2072983 67.6355743,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.2072983 67.6355743,69.2072983 67.6355743,69.2072983 67.6355743,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.2072983 67.6355743,69.2072983 67.6355743,69.2072983 67.6355743,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.2072983 67.6355743,69.2072983 67.6355743,69.2072983 67.6355743,69.086319"
        />
        <polygon
          fill="#CCC"
          points="67.6355743,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.6355743,68.965332 67.5145874,69.086319 67.6355743,69.2072983 67.6355743,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.6355743,68.965332 67.5145874,69.086319 67.6355743,69.2072983 67.6355743,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.6355743,68.965332 67.5145874,69.086319 67.6355743,69.2072983 67.6355743,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.6355743,68.965332 67.5145874,69.086319 67.6355743,69.2072983 67.6355743,69.2072983"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,69.086319 67.6355743,69.086319 67.6355743,69.2072983 67.5145874,69.2072983 67.6355743,69.3282852 67.6355743,69.3282852 67.6355743,69.3282852 67.7565536,69.4492722"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,69.086319 67.6355743,69.086319 67.6355743,69.2072983 67.5145874,69.2072983 67.6355743,69.3282852 67.6355743,69.3282852 67.6355743,69.3282852 67.7565536,69.4492722"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,69.3282852 68.7244415,70.0541992 67.8775482,69.2072983 67.8775482,69.2072983 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.4492722 67.7565536,69.4492722"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,69.3282852 68.7244415,70.0541992 67.8775482,69.2072983 67.8775482,69.2072983 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.4492722 67.7565536,69.4492722"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,70.2961731 68.7244415,70.2961731 68.8454285,70.1751938 68.8454285,70.1751938 68.8454285,70.1751938 68.7244415,70.0541992 67.7565536,69.3282852 67.7565536,69.4492722"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,70.2961731 68.7244415,70.2961731 68.8454285,70.1751938 68.8454285,70.1751938 68.8454285,70.1751938 68.7244415,70.0541992 67.7565536,69.3282852 67.7565536,69.4492722"
        />
        <polygon
          fill={colors[3]}
          points="68.8454285,69.9332123 67.8775482,69.2072983 67.8775482,69.2072983 67.8775482,69.2072983 68.8454285,70.1751938 68.8454285,68.2394104 68.8454285,68.1184311"
        />
        <polygon
          fill={colors[3]}
          points="68.8454285,68.1184311 68.7244415,68.1184311 67.8775482,68.8443375"
        />
        <polygon
          fill={colors[4]}
          points="68.8454285,70.1751938 68.9664154,70.1751938 69.8133163,69.2072983 69.8133163,69.2072983 68.9664154,69.9332123 68.9664154,68.360405 68.8454285,68.2394104"
        />
        <polygon
          fill="#CCC"
          points="69.8133163,69.2072983 68.9664154,68.360405 68.9664154,69.9332123"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,68.965332 67.7565536,68.965332 67.7565536,68.965332 67.7565536,68.965332 67.7565536,69.086319 67.7565536,69.086319 67.7565536,69.086319 67.8775482,69.2072983 68.8454285,69.9332123 68.8454285,68.1184311 68.8454285,68.1184311 67.8775482,68.8443375"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,68.965332 70.0552826,68.965332 71.0231705,67.9974442 71.0231705,67.9974442 71.0231705,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,68.965332 70.0552826,68.965332 70.0552826,68.965332 70.1762695,68.965332 70.2972641,68.965332 71.1441574,68.1184311 71.0231705,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,68.965332 70.0552826,68.965332 70.0552826,68.965332 70.1762695,68.965332 70.2972641,68.965332 71.1441574,68.1184311 71.0231705,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,68.965332 70.0552826,68.965332 70.1762695,69.086319 70.1762695,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,68.965332 70.0552826,68.965332 70.1762695,69.086319 70.1762695,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,68.965332 70.0552826,68.965332 70.1762695,69.086319 70.1762695,69.086319"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,68.1184311 71.1441574,68.2394104 71.2651443,68.2394104 71.1441574,68.1184311 70.2972641,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,68.1184311 71.1441574,68.2394104 71.2651443,68.2394104 71.1441574,68.1184311 70.2972641,68.965332"
        />
        <polygon
          fill={colors[4]}
          points="70.2972641,68.965332 70.1762695,68.965332 70.1762695,69.086319 70.2972641,69.2072983 71.1441574,68.2394104 71.1441574,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,68.965332 70.1762695,68.965332 70.1762695,69.086319 70.2972641,69.2072983 71.1441574,68.2394104 71.1441574,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,67.7554779 69.0874023,67.7554779 69.9343033,66.9085693 69.9343033,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,67.7554779 69.0874023,67.7554779 69.9343033,66.9085693 69.9343033,66.9085693"
        />
        <polygon
          fill={colors[3]}
          points="68.9664154,67.7554779 68.8454285,67.7554779 68.9664154,67.8764572 68.9664154,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,67.7554779 68.8454285,67.7554779 68.9664154,67.8764572 68.9664154,67.8764572"
        />
        <polygon
          fill={colors[4]}
          points="69.3293762,67.3925171 68.9664154,67.7554779 68.9664154,67.8764572 68.9664154,67.7554779 69.9343033,66.9085693 69.9343033,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.3293762,67.3925171 68.9664154,67.7554779 68.9664154,67.8764572 68.9664154,67.7554779 69.9343033,66.9085693 69.9343033,66.9085693"
        />
        <polygon
          fill="#CCC"
          points="69.8133163,66.78759 69.3293762,67.3925171 69.9343033,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,66.78759 69.3293762,67.3925171 69.9343033,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,68.965332 69.0874023,68.1184311 69.0874023,68.1184311 69.9343033,68.8443375 70.0552826,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,68.965332 69.0874023,68.1184311 69.0874023,68.1184311 69.9343033,68.8443375 70.0552826,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,69.2072983 69.9343033,69.086319 69.9343033,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,69.2072983 69.9343033,69.086319 69.9343033,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.086319 70.0552826,68.965332 70.0552826,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.086319 70.0552826,68.965332 70.0552826,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.086319 70.0552826,68.965332 70.0552826,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,69.2072983 69.8133163,69.2072983 69.9343033,69.086319 69.9343033,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,69.2072983 69.8133163,69.2072983 69.9343033,69.086319 69.9343033,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8133163,69.2072983 69.8133163,69.2072983 69.9343033,69.086319 69.9343033,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.086319 69.9343033,69.086319 69.8133163,69.2072983 69.9343033,69.2072983 70.0552826,69.086319 70.1762695,69.2072983 70.1762695,69.2072983 70.0552826,69.086319 70.1762695,69.086319 70.0552826,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.086319 69.9343033,69.086319 69.8133163,69.2072983 69.9343033,69.2072983 70.0552826,69.086319 70.1762695,69.2072983 70.1762695,69.2072983 70.0552826,69.086319 70.1762695,69.086319 70.0552826,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.086319 69.9343033,69.086319 69.8133163,69.2072983 69.9343033,69.2072983 70.0552826,69.086319 70.1762695,69.2072983 70.1762695,69.2072983 70.0552826,69.086319 70.1762695,69.086319 70.0552826,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.086319 69.9343033,69.086319 69.8133163,69.2072983 69.9343033,69.2072983 70.0552826,69.086319 70.1762695,69.2072983 70.1762695,69.2072983 70.0552826,69.086319 70.1762695,69.086319 70.0552826,68.965332"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.086319 69.9343033,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.086319 69.9343033,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.086319 69.9343033,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.3282852 70.0552826,69.086319 69.9343033,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,69.2072983 70.0552826,69.086319 70.0552826,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.2072983 70.0552826,69.086319 70.0552826,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.2072983 70.0552826,69.086319 70.0552826,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.2072983 70.0552826,69.086319 70.0552826,69.3282852"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,69.2072983 70.2972641,69.2072983 70.1762695,69.086319 70.0552826,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.2072983 70.2972641,69.2072983 70.1762695,69.086319 70.0552826,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,69.2072983 70.2972641,69.2072983 70.1762695,69.086319 70.0552826,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,68.1184311 68.7244415,68.1184311 68.7244415,67.9974442 68.7244415,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,68.1184311 68.7244415,68.1184311 68.7244415,67.9974442 68.7244415,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,68.1184311 68.7244415,68.1184311 68.7244415,67.9974442 68.7244415,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,68.1184311 69.0874023,68.1184311 69.0874023,68.1184311 69.0874023,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,68.1184311 69.0874023,68.1184311 69.0874023,68.1184311 69.0874023,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,68.1184311 69.0874023,68.1184311 69.0874023,68.1184311 69.0874023,67.9974442"
        />
        <polygon
          fill={colors[4]}
          points="68.7244415,68.1184311 68.7244415,67.9974442 68.7244415,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,68.1184311 68.7244415,67.9974442 68.7244415,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,68.1184311 68.7244415,67.9974442 68.7244415,67.9974442"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,68.1184311 68.7244415,67.9974442 68.7244415,67.9974442 68.6034622,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,68.1184311 68.7244415,67.9974442 68.7244415,67.9974442 68.6034622,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,68.1184311 68.7244415,67.9974442 68.7244415,67.9974442 68.6034622,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.8764572 69.0874023,68.1184311 69.0874023,67.9974442 69.0874023,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.8764572 69.0874023,68.1184311 69.0874023,67.9974442 69.0874023,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.8764572 69.0874023,68.1184311 69.0874023,67.9974442 69.0874023,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.8764572 69.0874023,68.1184311 69.0874023,67.9974442 69.0874023,67.9974442"
        />
        <polygon
          fill="#CCC"
          points="68.8454285,67.8764572 68.9664154,67.8764572 68.8454285,67.7554779 68.7244415,67.9974442 68.7244415,67.9974442 68.7244415,67.9974442 68.8454285,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.8764572 68.9664154,67.8764572 68.8454285,67.7554779 68.7244415,67.9974442 68.7244415,67.9974442 68.7244415,67.9974442 68.8454285,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.8764572 68.9664154,67.8764572 68.8454285,67.7554779 68.7244415,67.9974442 68.7244415,67.9974442 68.7244415,67.9974442 68.8454285,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.8764572 68.9664154,67.8764572 68.8454285,67.7554779 68.7244415,67.9974442 68.7244415,67.9974442 68.7244415,67.9974442 68.8454285,67.9974442"
        />
        <polygon
          fill={colors[3]}
          points="68.8454285,67.7554779 68.9664154,67.8764572 68.9664154,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.7554779 68.9664154,67.8764572 68.9664154,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.7554779 68.9664154,67.8764572 68.9664154,67.8764572"
        />
        <polygon
          fill={colors[3]}
          points="68.7244415,67.9974442 68.7244415,68.1184311 68.8454285,68.1184311 68.8454285,68.1184311 68.8454285,68.1184311 68.8454285,68.2394104 68.8454285,67.8764572 68.8454285,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,67.9974442 68.7244415,68.1184311 68.8454285,68.1184311 68.8454285,68.1184311 68.8454285,68.1184311 68.8454285,68.2394104 68.8454285,67.8764572 68.8454285,67.9974442"
        />
        <polygon
          fill={colors[4]}
          points="69.9343033,69.2072983 69.8133163,69.2072983 69.8133163,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,69.2072983 69.8133163,69.2072983 69.8133163,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="68.8454285,67.8764572 68.8454285,67.8764572 68.8454285,68.2394104 68.9664154,68.360405 68.9664154,68.1184311 69.9343033,68.8443375 69.0874023,68.1184311 69.0874023,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,67.8764572 68.8454285,67.8764572 68.8454285,68.2394104 68.9664154,68.360405 68.9664154,68.1184311 69.9343033,68.8443375 69.0874023,68.1184311 69.0874023,68.1184311"
        />
        <polygon
          fill="#CCC"
          points="68.9664154,68.1184311 68.9664154,68.360405 69.8133163,69.2072983 69.9343033,69.2072983 69.9343033,69.086319 69.9343033,69.086319 70.0552826,69.086319 70.0552826,69.086319 70.0552826,69.086319 69.9343033,68.8443375"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,68.1184311 68.9664154,68.360405 69.8133163,69.2072983 69.9343033,69.2072983 69.9343033,69.086319 69.9343033,69.086319 70.0552826,69.086319 70.0552826,69.086319 70.0552826,69.086319 69.9343033,68.8443375"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,67.0295563 69.9343033,66.9085693 69.0874023,67.7554779 69.9343033,67.0295563 69.9343033,68.7233582 69.0874023,67.9974442 69.0874023,67.9974442 69.0874023,68.1184311 70.0552826,68.965332"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,67.0295563 70.0552826,68.965332 70.0552826,68.965332 71.0231705,67.9974442 70.0552826,68.7233582"
        />
        <polygon
          fill="#CCC"
          points="70.0552826,68.7233582 71.0231705,67.9974442 71.0231705,67.9974442 70.9021912,67.8764572 71.0231705,67.8764572 70.0552826,67.0295563"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,67.8764572 68.9664154,67.8764572 68.9664154,67.8764572 68.9664154,67.8764572 68.8454285,67.8764572 68.8454285,67.8764572 69.0874023,67.9974442 69.9343033,68.7233582 69.9343033,67.0295563 69.0874023,67.7554779 68.9664154,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.2158585,71.5060272 65.2158585,71.5060272 64.2479782,72.4739075 64.2479782,72.4739075 64.3689651,72.3529282"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.2158585,71.5060272 65.2158585,71.5060272 64.2479782,72.4739075 64.2479782,72.4739075 64.3689651,72.3529282"
        />
        <polygon
          fill={colors[3]}
          points="64.3689651,72.3529282 65.3368454,71.6270142 65.2158585,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,72.3529282 65.3368454,71.6270142 65.2158585,71.5060272"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,71.7480011 65.3368454,71.6270142 65.3368454,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.7480011 65.3368454,71.6270142 65.3368454,71.7480011"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,72.715889 65.3368454,71.7480011 65.3368454,71.6270142 65.3368454,71.6270142 64.3689651,72.3529282 64.2479782,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,72.715889 65.3368454,71.7480011 65.3368454,71.6270142 65.3368454,71.6270142 64.3689651,72.3529282 64.2479782,72.4739075"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,71.6270142 65.2158585,71.5060272 65.2158585,71.5060272 65.3368454,71.6270142 65.3368454,71.6270142 65.3368454,71.6270142 65.4578323,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.6270142 65.2158585,71.5060272 65.2158585,71.5060272 65.3368454,71.6270142 65.3368454,71.6270142 65.3368454,71.6270142 65.4578323,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.6270142 65.2158585,71.5060272 65.2158585,71.5060272 65.3368454,71.6270142 65.3368454,71.6270142 65.3368454,71.6270142 65.4578323,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.41716 64.4899445,70.41716 65.2158585,71.2640533 65.3368454,71.2640533 65.3368454,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.41716 64.4899445,70.41716 65.2158585,71.2640533 65.3368454,71.2640533 65.3368454,71.2640533"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.3850479 65.5788193,71.5060272 65.5788193,71.5060272 65.5788193,71.5060272 65.4578323,71.3850479 65.2158585,71.5060272 65.2158585,71.5060272 65.2158585,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.3850479 65.5788193,71.5060272 65.5788193,71.5060272 65.5788193,71.5060272 65.4578323,71.3850479 65.2158585,71.5060272 65.2158585,71.5060272 65.2158585,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.3850479 65.5788193,71.5060272 65.5788193,71.5060272 65.5788193,71.5060272 65.4578323,71.3850479 65.2158585,71.5060272 65.2158585,71.5060272 65.2158585,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.3850479 65.5788193,71.5060272 65.5788193,71.5060272 65.5788193,71.5060272 65.4578323,71.3850479 65.2158585,71.5060272 65.2158585,71.5060272 65.2158585,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,71.2640533 65.3368454,71.2640533 65.3368454,71.3850479 65.3368454,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,71.2640533 65.3368454,71.2640533 65.3368454,71.3850479 65.3368454,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,71.2640533 65.3368454,71.2640533 65.3368454,71.3850479 65.3368454,71.3850479"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,71.2640533 65.3368454,71.2640533 65.3368454,71.3850479 65.3368454,71.3850479"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,71.3850479 65.2158585,71.5060272 65.4578323,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.3850479 65.2158585,71.5060272 65.4578323,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.3850479 65.2158585,71.5060272 65.4578323,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.3850479 65.2158585,71.5060272 65.4578323,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,71.7480011 65.4578323,71.6270142 65.4578323,71.6270142 65.4578323,71.6270142 65.5788193,71.5060272 65.4578323,71.3850479 65.4578323,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.7480011 65.4578323,71.6270142 65.4578323,71.6270142 65.4578323,71.6270142 65.5788193,71.5060272 65.4578323,71.3850479 65.4578323,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.7480011 65.4578323,71.6270142 65.4578323,71.6270142 65.4578323,71.6270142 65.5788193,71.5060272 65.4578323,71.3850479 65.4578323,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,71.7480011 65.4578323,71.6270142 65.4578323,71.6270142 65.4578323,71.6270142 65.5788193,71.5060272 65.4578323,71.3850479 65.4578323,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="65.6998062,71.5060272 65.4578323,71.2640533 65.4578323,71.3850479 65.4578323,71.3850479 65.4578323,71.3850479 65.4578323,71.3850479 65.4578323,71.3850479 65.5788193,71.5060272 65.5788193,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,71.5060272 65.4578323,71.2640533 65.4578323,71.3850479 65.4578323,71.3850479 65.4578323,71.3850479 65.4578323,71.3850479 65.4578323,71.3850479 65.5788193,71.5060272 65.5788193,71.5060272"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,71.5060272 65.4578323,71.2640533 65.4578323,71.3850479 65.4578323,71.3850479 65.4578323,71.3850479 65.4578323,71.3850479 65.4578323,71.3850479 65.5788193,71.5060272 65.5788193,71.5060272"
        />
        <polygon
          fill={colors[3]}
          points="65.3368454,71.2640533 65.4578323,71.3850479 65.4578323,71.3850479 65.4578323,71.2640533 64.8529053,70.6591263 64.4899445,70.41716 64.4899445,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,71.2640533 65.4578323,71.3850479 65.4578323,71.3850479 65.4578323,71.2640533 64.8529053,70.6591263 64.4899445,70.41716 64.4899445,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,70.2961731 64.4899445,70.41716 64.8529053,70.6591263"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.2961731 64.4899445,70.41716 64.8529053,70.6591263"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.41716 64.4899445,70.41716 64.3689651,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.41716 64.4899445,70.41716 64.3689651,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.41716 64.4899445,70.41716 64.3689651,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,70.2961731 64.4899445,70.41716 64.4899445,70.41716 64.4899445,70.2961731 64.3689651,70.1751938 64.3689651,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.2961731 64.4899445,70.41716 64.4899445,70.41716 64.4899445,70.2961731 64.3689651,70.1751938 64.3689651,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.2961731 64.4899445,70.41716 64.4899445,70.41716 64.4899445,70.2961731 64.3689651,70.1751938 64.3689651,70.1751938"
        />
        <polygon
          fill={colors[3]}
          points="64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.0541992 64.2479782,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.0541992 64.2479782,70.0541992"
        />
        <polygon
          fill={colors[4]}
          points="64.3689651,70.1751938 64.3689651,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,70.1751938 64.3689651,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938"
        />
        <polygon
          fill={colors[3]}
          points="64.2479782,72.4739075 64.2479782,70.5381393 64.2479782,70.6591263 64.2479782,72.2319412 63.4010735,71.6270142 63.4010735,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="65.2158585,71.5060272 65.2158585,71.5060272 65.2158585,71.5060272 64.3689651,72.2319412 64.3689651,70.5381393 64.2479782,70.5381393 64.2479782,72.4739075 64.2479782,72.4739075"
        />
        <polygon
          fill={colors[4]}
          points="65.2158585,71.2640533 64.4899445,70.41716 64.3689651,70.41716 64.3689651,70.41716"
        />
        <polygon
          fill="#CCC"
          points="65.4578323,71.3850479 65.4578323,71.3850479 65.3368454,71.2640533 65.3368454,71.2640533 65.2158585,71.2640533 64.3689651,70.41716 64.3689651,70.5381393 64.3689651,72.2319412 65.2158585,71.5060272"
        />
        <polygon
          fill={colors[4]}
          points="64.2479782,70.6591263 63.4010735,71.3850479 63.4010735,71.6270142 64.2479782,72.2319412"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,70.1751938 64.2479782,70.1751938 63.7640343,69.691246 63.7640343,69.691246 64.1269836,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,70.1751938 64.2479782,70.1751938 63.7640343,69.691246 63.7640343,69.691246 64.1269836,70.0541992"
        />
        <polygon
          fill={colors[3]}
          points="64.2479782,70.0541992 63.7640343,69.5702591 63.7640343,69.691246 64.2479782,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,70.0541992 63.7640343,69.5702591 63.7640343,69.691246 64.2479782,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,70.41716 63.5220566,70.9011002 63.5220566,71.0220871"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,70.41716 63.5220566,70.9011002 63.5220566,71.0220871"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.41716 64.4899445,70.2961731 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.1269836,70.2961731 64.1269836,70.41716 64.1269836,70.41716 64.2479782,70.1751938 64.3689651,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.41716 64.4899445,70.2961731 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.1269836,70.2961731 64.1269836,70.41716 64.1269836,70.41716 64.2479782,70.1751938 64.3689651,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.41716 64.4899445,70.2961731 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.1269836,70.2961731 64.1269836,70.41716 64.1269836,70.41716 64.2479782,70.1751938 64.3689651,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.41716 64.4899445,70.2961731 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.1269836,70.2961731 64.1269836,70.41716 64.1269836,70.41716 64.2479782,70.1751938 64.3689651,70.41716"
        />
        <polygon
          fill={colors[3]}
          points="64.4899445,70.2961731 64.4899445,70.41716 64.4899445,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.2961731 64.4899445,70.41716 64.4899445,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.2961731 64.4899445,70.41716 64.4899445,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.2961731 64.4899445,70.41716 64.4899445,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,70.2961731 64.3689651,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.4899445,70.2961731 64.4899445,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.2961731 64.3689651,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.4899445,70.2961731 64.4899445,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.2961731 64.3689651,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.4899445,70.2961731 64.4899445,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,70.2961731 64.3689651,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.4899445,70.2961731 64.4899445,70.41716"
        />
        <polygon
          fill={colors[3]}
          points="64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.0541992"
        />
        <polygon
          fill={colors[4]}
          points="64.2479782,70.1751938 64.2479782,70.1751938 64.3689651,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,70.1751938 64.2479782,70.1751938 64.3689651,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,70.1751938 64.2479782,70.1751938 64.3689651,70.1751938"
        />
        <polygon
          fill={colors[3]}
          points="64.2479782,70.41716 64.2479782,70.6591263 64.2479782,70.5381393 64.2479782,70.1751938 64.1269836,70.41716 64.1269836,70.41716 63.5220566,71.0220871 63.5220566,71.0220871"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,70.41716 64.2479782,70.6591263 64.2479782,70.5381393 64.2479782,70.1751938 64.1269836,70.41716 64.1269836,70.41716 63.5220566,71.0220871 63.5220566,71.0220871"
        />
        <polygon
          fill={colors[4]}
          points="64.3689651,70.5381393 64.3689651,70.41716 64.3689651,70.41716 64.3689651,70.41716 64.2479782,70.1751938 64.2479782,70.5381393"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,70.5381393 64.3689651,70.41716 64.3689651,70.41716 64.3689651,70.41716 64.2479782,70.1751938 64.2479782,70.5381393"
        />
        <polygon
          fill={colors[4]}
          points="64.2479782,70.41716 63.5220566,71.0220871 63.4010735,71.3850479 64.2479782,70.6591263"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,70.41716 63.5220566,71.0220871 63.4010735,71.3850479 64.2479782,70.6591263"
        />
        <polygon
          fill={colors[4]}
          points="63.7640343,69.691246 63.7640343,69.691246 64.1269836,70.0541992"
        />
        <polygon
          fill={colors[4]}
          points="64.1269836,70.41716 64.1269836,70.2961731 63.5220566,70.7801208 63.5220566,70.9011002"
        />
        <polygon
          fill="#CCC"
          points="64.1269836,70.2961731 64.2479782,70.1751938 64.2479782,70.1751938 64.2479782,70.1751938 64.1269836,70.0541992 63.7640343,69.691246 63.5220566,70.7801208"
        />
        <polygon
          fill={colors[3]}
          points="73.321907,67.5134964 73.321907,67.3925171 72.9589462,67.1505508"
        />
        <polygon
          fill={colors[4]}
          points="72.5959854,66.78759 72.5959854,66.78759 72.9589462,67.1505508 73.321907,67.3925171 73.0799255,66.3036423"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6848526,70.2961731 73.8058395,70.41716 73.8058395,70.41716 73.8058395,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6848526,70.2961731 73.8058395,70.41716 73.8058395,70.41716 73.8058395,70.2961731"
        />
        <polygon
          fill="#CCC"
          points="73.321907,70.1751938 73.321907,70.2961731 73.321907,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,70.1751938 73.321907,70.2961731 73.321907,70.2961731"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,70.1751938 73.321907,70.2961731 73.321907,70.2961731"
        />
        <polygon
          fill={colors[3]}
          points="73.321907,70.2961731 73.321907,70.2961731 73.4428864,70.41716 73.4428864,70.41716 73.4428864,70.41716 73.4428864,70.41716 73.4428864,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,70.2961731 73.321907,70.2961731 73.4428864,70.41716 73.4428864,70.41716 73.4428864,70.41716 73.4428864,70.41716 73.4428864,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="73.6848526,70.2961731 73.4428864,70.41716 73.4428864,70.41716 73.4428864,70.41716 73.5638733,70.5381393 73.5638733,70.41716 73.8058395,70.6591263 73.8058395,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6848526,70.2961731 73.4428864,70.41716 73.4428864,70.41716 73.4428864,70.41716 73.5638733,70.5381393 73.5638733,70.41716 73.8058395,70.6591263 73.8058395,70.41716"
        />
        <polygon
          fill="#CCC"
          points="73.5638733,70.41716 73.5638733,70.5381393 73.9268341,70.7801208 73.8058395,70.6591263"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,70.41716 73.5638733,70.5381393 73.9268341,70.7801208 73.8058395,70.6591263"
        />
        <polygon
          fill={colors[3]}
          points="73.6848526,70.2961731 73.6848526,70.2961731 73.8058395,70.2961731 73.8058395,70.41716 73.8058395,70.2961731"
        />
        <polygon
          fill={colors[4]}
          points="73.6848526,70.2961731 73.8058395,70.2961731 73.8058395,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,70.0541992 73.4428864,70.0541992 73.4428864,70.0541992 72.5959854,69.2072983 72.5959854,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,70.0541992 73.4428864,70.0541992 73.4428864,70.0541992 72.5959854,69.2072983 72.5959854,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="73.321907,70.0541992 72.5959854,69.2072983 72.4749985,69.3282852"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,70.0541992 72.5959854,69.2072983 72.4749985,69.3282852"
        />
        <polygon
          fill="#CCC"
          points="72.4749985,69.3282852 73.321907,70.1751938 73.4428864,70.0541992 73.321907,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,69.3282852 73.321907,70.1751938 73.4428864,70.0541992 73.321907,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,69.2072983 72.5959854,69.2072983 72.4749985,69.086319 72.4749985,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,69.2072983 72.5959854,69.2072983 72.4749985,69.086319 72.4749985,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,69.2072983 72.5959854,69.2072983 72.4749985,69.086319 72.4749985,69.086319"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,69.3282852 72.3540115,69.2072983 72.3540115,69.2072983 72.2330322,69.2072983 72.2330322,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,69.3282852 72.3540115,69.2072983 72.3540115,69.2072983 72.2330322,69.2072983 72.2330322,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,69.3282852 72.3540115,69.2072983 72.3540115,69.2072983 72.2330322,69.2072983 72.2330322,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,69.3282852 72.4749985,69.3282852 72.4749985,69.3282852 72.4749985,69.3282852 72.5959854,69.2072983 72.4749985,69.086319 72.3540115,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,69.3282852 72.4749985,69.3282852 72.4749985,69.3282852 72.4749985,69.3282852 72.5959854,69.2072983 72.4749985,69.086319 72.3540115,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,69.3282852 72.4749985,69.3282852 72.4749985,69.3282852 72.4749985,69.3282852 72.5959854,69.2072983 72.4749985,69.086319 72.3540115,69.2072983"
        />
        <polygon
          fill="#CCC"
          points="72.2330322,69.2072983 72.2330322,69.2072983 72.1120453,69.086319 72.1120453,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,69.2072983 72.2330322,69.2072983 72.1120453,69.086319 72.1120453,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,70.0541992 73.6848526,69.9332123 73.6848526,69.9332123 73.6848526,69.812233"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,70.0541992 73.6848526,69.9332123 73.6848526,69.9332123 73.6848526,69.812233"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,70.0541992 73.5638733,69.9332123 73.5638733,69.9332123"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,70.0541992 73.5638733,69.9332123 73.5638733,69.9332123"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,70.0541992 73.5638733,69.9332123 73.5638733,69.9332123"
        />
        <polygon
          fill="#CCC"
          points="73.321907,70.1751938 73.321907,70.1751938 73.4428864,70.0541992 73.4428864,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,70.1751938 73.321907,70.1751938 73.4428864,70.0541992 73.4428864,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,70.1751938 73.321907,70.1751938 73.4428864,70.0541992 73.4428864,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,70.1751938 73.4428864,70.1751938 73.6848526,70.2961731 73.6848526,70.2961731 73.6848526,70.1751938 73.4428864,70.0541992 73.5638733,70.0541992 73.5638733,70.0541992 73.4428864,70.0541992 73.4428864,70.0541992 73.4428864,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,70.1751938 73.4428864,70.1751938 73.6848526,70.2961731 73.6848526,70.2961731 73.6848526,70.1751938 73.4428864,70.0541992 73.5638733,70.0541992 73.5638733,70.0541992 73.4428864,70.0541992 73.4428864,70.0541992 73.4428864,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,70.1751938 73.4428864,70.1751938 73.6848526,70.2961731 73.6848526,70.2961731 73.6848526,70.1751938 73.4428864,70.0541992 73.5638733,70.0541992 73.5638733,70.0541992 73.4428864,70.0541992 73.4428864,70.0541992 73.4428864,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,70.1751938 73.4428864,70.1751938 73.6848526,70.2961731 73.6848526,70.2961731 73.6848526,70.1751938 73.4428864,70.0541992 73.5638733,70.0541992 73.5638733,70.0541992 73.4428864,70.0541992 73.4428864,70.0541992 73.4428864,70.0541992"
        />
        <polygon
          fill="#CCC"
          points="73.321907,70.1751938 73.321907,70.2961731 73.4428864,70.1751938 73.4428864,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,70.1751938 73.321907,70.2961731 73.4428864,70.1751938 73.4428864,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,70.1751938 73.321907,70.2961731 73.4428864,70.1751938 73.4428864,70.0541992"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,70.1751938 73.321907,70.2961731 73.4428864,70.1751938 73.4428864,70.0541992"
        />
        <polygon
          fill={colors[3]}
          points="73.4428864,70.1751938 73.4428864,70.1751938 73.321907,70.2961731 73.4428864,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,70.1751938 73.4428864,70.1751938 73.321907,70.2961731 73.4428864,70.41716"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,70.1751938 73.4428864,70.1751938 73.321907,70.2961731 73.4428864,70.41716"
        />
        <polygon
          fill={colors[4]}
          points="73.4428864,70.41716 73.4428864,70.41716 73.6848526,70.2961731 73.4428864,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,70.41716 73.4428864,70.41716 73.6848526,70.2961731 73.4428864,70.1751938"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4428864,70.41716 73.4428864,70.41716 73.6848526,70.2961731 73.4428864,70.1751938"
        />
        <polygon
          fill={colors[4]}
          points="73.5638733,70.0541992 73.5638733,70.0541992 73.4428864,70.0541992 73.6848526,70.1751938 73.6848526,70.2961731 73.8058395,70.1751938 73.6848526,69.9332123 73.6848526,69.9332123"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5638733,70.0541992 73.5638733,70.0541992 73.4428864,70.0541992 73.6848526,70.1751938 73.6848526,70.2961731 73.8058395,70.1751938 73.6848526,69.9332123 73.6848526,69.9332123"
        />
        <polygon
          fill={colors[3]}
          points="73.4428864,69.812233 72.4749985,69.086319 72.4749985,69.086319 72.5959854,69.2072983 73.4428864,70.0541992 73.4428864,70.0541992 73.4428864,70.0541992 73.4428864,68.6023712 73.4428864,68.2394104 73.4428864,68.2394104"
        />
        <polygon
          fill={colors[4]}
          points="73.4428864,70.0541992 73.5638733,70.0541992 73.5638733,70.0541992 73.6848526,69.812233 73.6848526,69.691246 73.5638733,69.812233 73.5638733,68.965332 73.4428864,68.6023712"
        />
        <polygon
          fill="#CCC"
          points="73.6848526,69.691246 73.5638733,68.965332 73.5638733,69.812233"
        />
        <polygon
          fill={colors[4]}
          points="73.4428864,69.812233 73.4428864,68.2394104 72.4749985,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,67.9974442 71.3861237,67.9974442 71.3861237,68.1184311 72.2330322,68.965332 72.3540115,68.8443375"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,67.9974442 71.3861237,67.9974442 71.3861237,68.1184311 72.2330322,68.965332 72.3540115,68.8443375"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,68.2394104 71.2651443,68.2394104 71.2651443,68.2394104 71.2651443,68.1184311 72.2330322,68.965332 72.2330322,68.965332 71.3861237,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,68.2394104 71.2651443,68.2394104 71.2651443,68.2394104 71.2651443,68.1184311 72.2330322,68.965332 72.2330322,68.965332 71.3861237,68.1184311"
        />
        <polygon
          fill="#CCC"
          points="72.2330322,68.965332 71.2651443,68.1184311 71.2651443,68.2394104 72.1120453,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,68.965332 71.2651443,68.1184311 71.2651443,68.2394104 72.1120453,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,68.1184311 71.1441574,67.9974442 71.0231705,67.9974442 71.0231705,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,68.1184311 71.1441574,67.9974442 71.0231705,67.9974442 71.0231705,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,68.1184311 71.1441574,67.9974442 71.0231705,67.9974442 71.0231705,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,68.1184311 71.3861237,67.9974442 71.3861237,67.9974442 71.3861237,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,68.1184311 71.3861237,67.9974442 71.3861237,67.9974442 71.3861237,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,68.1184311 71.3861237,67.9974442 71.3861237,67.9974442 71.3861237,67.9974442"
        />
        <polygon
          fill={colors[3]}
          points="71.2651443,68.1184311 71.1441574,67.9974442 71.1441574,68.1184311 71.2651443,68.2394104 71.2651443,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,68.1184311 71.1441574,67.9974442 71.1441574,68.1184311 71.2651443,68.2394104 71.2651443,68.2394104"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,68.1184311 71.1441574,67.9974442 71.1441574,68.1184311 71.2651443,68.2394104 71.2651443,68.2394104"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,68.1184311 71.2651443,68.2394104 71.3861237,68.1184311 71.3861237,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,68.1184311 71.2651443,68.2394104 71.3861237,68.1184311 71.3861237,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,68.1184311 71.2651443,68.2394104 71.3861237,68.1184311 71.3861237,67.9974442"
        />
        <polygon
          fill="#CCC"
          points="71.0231705,67.9974442 71.0231705,67.9974442 71.0231705,67.8764572 70.9021912,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,67.9974442 71.0231705,67.9974442 71.0231705,67.8764572 70.9021912,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,66.9085693 73.321907,67.6344833 73.321907,67.6344833 73.321907,67.6344833 72.5959854,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,66.9085693 73.321907,67.6344833 73.321907,67.6344833 73.321907,67.6344833 72.5959854,66.78759"
        />
        <polygon
          fill={colors[3]}
          points="72.5959854,66.78759 72.5959854,66.78759 73.321907,67.6344833 73.321907,67.5134964 72.9589462,67.1505508"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,66.78759 72.5959854,66.78759 73.321907,67.6344833 73.321907,67.5134964 72.9589462,67.1505508"
        />
        <polygon
          fill={colors[4]}
          points="72.9589462,67.1505508 72.5959854,66.78759 72.5959854,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.9589462,67.1505508 72.5959854,66.78759 72.5959854,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,68.965332 72.3540115,68.965332 72.4749985,68.8443375 73.321907,67.9974442 73.321907,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,68.965332 72.3540115,68.965332 72.4749985,68.8443375 73.321907,67.9974442 73.321907,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,68.965332 72.3540115,68.8443375 72.2330322,68.965332 72.2330322,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,68.965332 72.3540115,68.8443375 72.2330322,68.965332 72.2330322,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,68.965332 72.3540115,68.8443375 72.2330322,68.965332 72.2330322,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,69.086319 72.3540115,69.086319 72.4749985,69.086319 72.4749985,69.086319 72.4749985,69.086319 72.3540115,68.965332 72.3540115,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,69.086319 72.3540115,69.086319 72.4749985,69.086319 72.4749985,69.086319 72.4749985,69.086319 72.3540115,68.965332 72.3540115,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,69.086319 72.3540115,69.086319 72.4749985,69.086319 72.4749985,69.086319 72.4749985,69.086319 72.3540115,68.965332 72.3540115,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,69.086319 72.3540115,69.086319 72.4749985,69.086319 72.4749985,69.086319 72.4749985,69.086319 72.3540115,68.965332 72.3540115,68.965332"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,69.2072983 72.3540115,69.086319 72.2330322,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,69.2072983 72.3540115,69.086319 72.2330322,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,69.2072983 72.3540115,69.086319 72.2330322,69.2072983"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,69.2072983 72.3540115,69.086319 72.2330322,69.2072983"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,69.2072983 72.4749985,69.086319 72.3540115,69.086319 72.3540115,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,69.2072983 72.4749985,69.086319 72.3540115,69.086319 72.3540115,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,69.2072983 72.4749985,69.086319 72.3540115,69.086319 72.3540115,69.086319"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,69.2072983 72.4749985,69.086319 72.3540115,69.086319 72.3540115,69.086319"
        />
        <polygon
          fill="#CCC"
          points="72.1120453,69.086319 72.2330322,69.2072983 72.3540115,69.086319 72.3540115,69.086319 72.4749985,69.086319 72.3540115,68.965332 72.2330322,68.965332 72.2330322,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,69.086319 72.2330322,69.2072983 72.3540115,69.086319 72.3540115,69.086319 72.4749985,69.086319 72.3540115,68.965332 72.2330322,68.965332 72.2330322,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,69.086319 72.2330322,69.2072983 72.3540115,69.086319 72.3540115,69.086319 72.4749985,69.086319 72.3540115,68.965332 72.2330322,68.965332 72.2330322,68.965332"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,67.9974442 73.321907,67.9974442 73.321907,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,67.9974442 73.321907,67.9974442 73.321907,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,67.9974442 73.321907,67.9974442 73.321907,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,67.8764572 73.321907,67.9974442 73.4428864,67.8764572 73.4428864,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,67.8764572 73.321907,67.9974442 73.4428864,67.8764572 73.4428864,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,67.8764572 73.321907,67.9974442 73.4428864,67.8764572 73.4428864,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,67.8764572 73.321907,67.9974442 73.4428864,67.8764572 73.4428864,67.8764572"
        />
        <polygon
          fill={colors[3]}
          points="73.321907,67.9974442 72.4749985,68.8443375 73.4428864,67.9974442 73.4428864,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.321907,67.9974442 72.4749985,68.8443375 73.4428864,67.9974442 73.4428864,67.8764572"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,68.8443375 72.3540115,68.965332 72.3540115,68.965332 72.3540115,68.965332 72.4749985,69.086319 72.4749985,69.086319 73.4428864,68.2394104 73.4428864,68.1184311 73.4428864,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,68.8443375 72.3540115,68.965332 72.3540115,68.965332 72.3540115,68.965332 72.4749985,69.086319 72.4749985,69.086319 73.4428864,68.2394104 73.4428864,68.1184311 73.4428864,67.9974442"
        />
        <polygon
          fill={colors[3]}
          points="72.2330322,68.7233582 71.3861237,67.9974442 71.3861237,67.9974442 72.3540115,68.8443375 72.3540115,68.965332 72.3540115,67.0295563 72.2330322,67.1505508"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,67.0295563 72.3540115,68.965332 72.3540115,68.965332 73.321907,67.9974442 73.321907,67.8764572 73.321907,67.8764572 72.4749985,68.7233582 72.4749985,67.0295563"
        />
        <polygon
          fill={colors[4]}
          points="73.321907,67.6344833 72.5959854,66.9085693 72.4749985,66.9085693"
        />
        <polygon
          fill="#CCC"
          points="72.4749985,66.9085693 72.4749985,67.0295563 72.4749985,68.7233582 73.321907,67.8764572 73.4428864,67.8764572 73.321907,67.6344833 73.321907,67.6344833"
        />
        <polygon
          fill={colors[4]}
          points="72.2330322,68.7233582 72.2330322,67.1505508 71.3861237,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,66.9085693 70.1762695,66.9085693 71.1441574,67.7554779 71.1441574,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,66.9085693 70.2972641,66.9085693 70.2972641,66.78759 70.2972641,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,66.9085693 70.2972641,66.9085693 70.2972641,66.78759 70.2972641,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,66.9085693 71.0231705,67.7554779 71.1441574,67.7554779 71.1441574,67.7554779 70.2972641,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,66.9085693 71.0231705,67.7554779 71.1441574,67.7554779 71.1441574,67.7554779 70.2972641,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,66.9085693 70.2972641,66.9085693 70.2972641,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,66.9085693 70.2972641,66.9085693 70.2972641,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,66.9085693 70.2972641,66.9085693 70.2972641,66.78759"
        />
        <polygon
          fill="#CCC"
          points="69.9343033,66.9085693 69.9343033,66.78759 69.9343033,66.78759 69.8133163,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.9085693 69.9343033,66.78759 69.9343033,66.78759 69.8133163,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.9085693 69.9343033,66.78759 69.9343033,66.78759 69.8133163,66.78759"
        />
        <polygon
          fill={colors[3]}
          points="69.9343033,66.9085693 70.0552826,67.0295563 70.0552826,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.9085693 70.0552826,67.0295563 70.0552826,66.9085693"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,67.0295563 71.0231705,67.7554779 70.1762695,66.9085693 70.1762695,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,67.0295563 71.0231705,67.7554779 70.1762695,66.9085693 70.1762695,66.9085693"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,66.9085693 70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,66.9085693 70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563"
        />
        <polygon
          fill="#CCC"
          points="70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563 71.0231705,67.8764572 71.1441574,67.7554779 71.0231705,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563 71.0231705,67.8764572 71.1441574,67.7554779 71.0231705,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,67.7554779 71.1441574,67.7554779 71.2651443,67.8764572 71.2651443,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,67.7554779 71.1441574,67.7554779 71.2651443,67.8764572 71.2651443,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,67.8764572 71.2651443,67.8764572 71.3861237,67.9974442 71.3861237,67.9974442 71.3861237,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,67.8764572 71.2651443,67.8764572 71.3861237,67.9974442 71.3861237,67.9974442 71.3861237,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,67.8764572 71.2651443,67.8764572 71.3861237,67.9974442 71.3861237,67.9974442 71.3861237,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,67.7554779 71.1441574,67.8764572 71.2651443,67.8764572 71.2651443,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,67.7554779 71.1441574,67.8764572 71.2651443,67.8764572 71.2651443,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,67.7554779 71.1441574,67.8764572 71.2651443,67.8764572 71.2651443,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,67.8764572 71.1441574,67.8764572 71.1441574,67.9974442 71.0231705,67.9974442 71.1441574,67.9974442 71.2651443,67.8764572 71.3861237,67.9974442 71.3861237,67.9974442 71.2651443,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,67.8764572 71.1441574,67.8764572 71.1441574,67.9974442 71.0231705,67.9974442 71.1441574,67.9974442 71.2651443,67.8764572 71.3861237,67.9974442 71.3861237,67.9974442 71.2651443,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,67.8764572 71.1441574,67.8764572 71.1441574,67.9974442 71.0231705,67.9974442 71.1441574,67.9974442 71.2651443,67.8764572 71.3861237,67.9974442 71.3861237,67.9974442 71.2651443,67.8764572"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,67.8764572 71.1441574,67.8764572 71.1441574,67.9974442 71.0231705,67.9974442 71.1441574,67.9974442 71.2651443,67.8764572 71.3861237,67.9974442 71.3861237,67.9974442 71.2651443,67.8764572"
        />
        <polygon
          fill={colors[3]}
          points="71.2651443,68.1184311 71.2651443,67.8764572 71.1441574,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,68.1184311 71.2651443,67.8764572 71.1441574,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,68.1184311 71.2651443,67.8764572 71.1441574,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,68.1184311 71.2651443,67.8764572 71.1441574,67.9974442"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,67.9974442 71.2651443,67.8764572 71.2651443,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,67.9974442 71.2651443,67.8764572 71.2651443,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,67.9974442 71.2651443,67.8764572 71.2651443,68.1184311"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,67.9974442 71.2651443,67.8764572 71.2651443,68.1184311"
        />
        <polygon
          fill="#CCC"
          points="71.0231705,67.8764572 71.0231705,67.9974442 71.1441574,67.9974442 71.1441574,67.8764572 71.1441574,67.8764572 71.1441574,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,67.8764572 71.0231705,67.9974442 71.1441574,67.9974442 71.1441574,67.8764572 71.1441574,67.8764572 71.1441574,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.0231705,67.8764572 71.0231705,67.9974442 71.1441574,67.9974442 71.1441574,67.8764572 71.1441574,67.8764572 71.1441574,67.7554779"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,66.9085693 72.5959854,66.9085693 72.5959854,66.78759 72.5959854,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,66.9085693 72.5959854,66.9085693 72.5959854,66.78759 72.5959854,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,66.9085693 72.5959854,66.9085693 72.5959854,66.78759 72.5959854,66.78759"
        />
        <polygon
          fill={colors[3]}
          points="71.3861237,67.8764572 71.3861237,67.9974442 71.3861237,67.9974442"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,67.8764572 71.3861237,67.9974442 71.3861237,67.9974442"
        />
        <polygon
          fill={colors[3]}
          points="71.7490845,67.3925171 72.2330322,66.9085693 72.2330322,67.1505508 72.3540115,67.0295563 72.3540115,66.9085693 72.2330322,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7490845,67.3925171 72.2330322,66.9085693 72.2330322,67.1505508 72.3540115,67.0295563 72.3540115,66.9085693 72.2330322,66.78759"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,67.0295563 72.3540115,66.9085693 72.3540115,67.0295563 72.4749985,67.0295563 72.4749985,67.0295563"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,67.0295563 72.3540115,66.9085693 72.3540115,67.0295563 72.4749985,67.0295563 72.4749985,67.0295563"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,66.9085693 72.4749985,66.9085693 72.5959854,66.9085693 72.5959854,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,66.9085693 72.4749985,66.9085693 72.5959854,66.9085693 72.5959854,66.9085693"
        />
        <polygon
          fill="#CCC"
          points="72.4749985,67.0295563 72.4749985,67.0295563 72.4749985,66.9085693 72.4749985,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,67.0295563 72.4749985,67.0295563 72.4749985,66.9085693 72.4749985,66.9085693"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,67.7554779 71.1441574,67.7554779 71.1441574,67.7554779 71.1441574,67.7554779 71.2651443,67.7554779 71.2651443,67.8764572 71.3861237,67.8764572 71.3861237,67.9974442 72.2330322,67.1505508 72.2330322,66.9085693 71.7490845,67.3925171"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,67.7554779 71.1441574,67.7554779 71.1441574,67.7554779 71.1441574,67.7554779 71.2651443,67.7554779 71.2651443,67.8764572 71.3861237,67.8764572 71.3861237,67.9974442 72.2330322,67.1505508 72.2330322,66.9085693 71.7490845,67.3925171"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,67.6344833 70.2972641,66.78759 70.2972641,66.78759 70.2972641,66.9085693 71.1441574,67.7554779 71.1441574,67.7554779 71.2651443,67.7554779 71.2651443,67.7554779 71.2651443,65.9406891 71.1441574,65.9406891"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,65.9406891 71.2651443,67.7554779 71.7490845,67.3925171 72.2330322,66.78759 72.2330322,66.78759 71.2651443,67.6344833 71.2651443,65.9406891 71.2651443,65.8197021"
        />
        <polygon
          fill="#CCC"
          points="71.2651443,67.6344833 72.2330322,66.78759 71.2651443,65.9406891"
        />
        <polygon
          fill={colors[4]}
          points="70.2972641,66.78759 71.1441574,67.6344833 71.1441574,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,66.6666031 72.5959854,66.5456238 73.0799255,66.061676 73.0799255,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,66.6666031 72.5959854,66.5456238 73.0799255,66.061676 73.0799255,65.9406891"
        />
        <polygon
          fill={colors[3]}
          points="73.0799255,66.061676 73.0799255,66.061676 72.5959854,66.5456238"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.0799255,66.061676 73.0799255,66.061676 72.5959854,66.5456238"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,66.6666031 72.5959854,66.78759 73.0799255,66.3036423 73.0799255,66.061676 72.5959854,66.5456238"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,66.6666031 72.5959854,66.78759 73.0799255,66.3036423 73.0799255,66.061676 72.5959854,66.5456238"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,66.5456238 72.2330322,66.5456238 72.3540115,66.5456238 71.3861237,65.6987152 71.3861237,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,66.5456238 72.2330322,66.5456238 72.3540115,66.5456238 71.3861237,65.6987152 71.3861237,65.6987152"
        />
        <polygon
          fill={colors[4]}
          points="72.5959854,66.78759 72.5959854,66.78759 72.4749985,66.6666031 72.4749985,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,66.78759 72.5959854,66.78759 72.4749985,66.6666031 72.4749985,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,66.78759 72.5959854,66.78759 72.4749985,66.6666031 72.4749985,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.5456238 72.3540115,66.5456238 72.3540115,66.6666031 72.3540115,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.5456238 72.3540115,66.5456238 72.3540115,66.6666031 72.3540115,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.5456238 72.3540115,66.5456238 72.3540115,66.6666031 72.3540115,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.3540115,66.6666031 72.5959854,66.9085693 72.5959854,66.78759 72.5959854,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.3540115,66.6666031 72.5959854,66.9085693 72.5959854,66.78759 72.5959854,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.3540115,66.6666031 72.5959854,66.9085693 72.5959854,66.78759 72.5959854,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.3540115,66.6666031 72.5959854,66.9085693 72.5959854,66.78759 72.5959854,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.3540115,66.6666031 72.4749985,66.6666031 72.4749985,66.6666031 72.3540115,66.6666031 72.3540115,66.5456238"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.3540115,66.6666031 72.4749985,66.6666031 72.4749985,66.6666031 72.3540115,66.6666031 72.3540115,66.5456238"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.3540115,66.6666031 72.4749985,66.6666031 72.4749985,66.6666031 72.3540115,66.6666031 72.3540115,66.5456238"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.3540115,66.6666031 72.4749985,66.6666031 72.4749985,66.6666031 72.3540115,66.6666031 72.3540115,66.5456238"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,66.6666031 72.5959854,66.78759 72.5959854,66.78759 72.5959854,66.78759 72.4749985,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.5959854,66.78759 72.5959854,66.78759 72.5959854,66.78759 72.4749985,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.5959854,66.78759 72.5959854,66.78759 72.5959854,66.78759 72.4749985,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.5959854,66.78759 72.5959854,66.78759 72.5959854,66.78759 72.4749985,66.6666031"
        />
        <polygon
          fill={colors[3]}
          points="72.2330322,66.78759 72.2330322,66.78759 72.3540115,66.9085693 72.3540115,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,66.78759 72.2330322,66.78759 72.3540115,66.9085693 72.3540115,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,66.78759 72.2330322,66.78759 72.3540115,66.9085693 72.3540115,66.6666031"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,66.6666031 72.3540115,66.9085693 72.3540115,67.0295563 72.4749985,67.0295563 72.4749985,66.9085693 72.4749985,66.9085693 72.5959854,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.3540115,66.9085693 72.3540115,67.0295563 72.4749985,67.0295563 72.4749985,66.9085693 72.4749985,66.9085693 72.5959854,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,66.6666031 72.3540115,66.9085693 72.3540115,67.0295563 72.4749985,67.0295563 72.4749985,66.9085693 72.4749985,66.9085693 72.5959854,66.9085693"
        />
        <polygon
          fill="#CCC"
          points="72.4749985,67.0295563 72.4749985,66.9085693 72.4749985,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,67.0295563 72.4749985,66.9085693 72.4749985,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,67.0295563 72.4749985,66.9085693 72.4749985,66.9085693"
        />
        <polygon
          fill={colors[4]}
          points="72.2330322,66.5456238 71.3861237,65.6987152 71.3861237,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,66.5456238 71.3861237,65.6987152 71.3861237,65.8197021"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.2651443,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.2651443,65.9406891"
        />
        <polygon
          fill="#CCC"
          points="72.2330322,66.5456238 71.3861237,65.8197021 71.2651443,65.8197021 71.2651443,65.9406891 72.2330322,66.78759 72.2330322,66.78759 72.3540115,66.6666031 72.3540115,66.6666031 72.3540115,66.6666031 72.3540115,66.6666031 72.3540115,66.6666031 72.3540115,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,66.5456238 71.3861237,65.8197021 71.2651443,65.8197021 71.2651443,65.9406891 72.2330322,66.78759 72.2330322,66.78759 72.3540115,66.6666031 72.3540115,66.6666031 72.3540115,66.6666031 72.3540115,66.6666031 72.3540115,66.6666031 72.3540115,66.6666031"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,66.4246292 71.3861237,65.6987152 71.3861237,65.6987152 72.3540115,66.5456238 72.3540115,66.5456238 72.3540115,66.6666031 72.3540115,64.7308273 72.3540115,64.8518219"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,66.4246292 72.4749985,64.7308273 72.4749985,64.7308273 72.3540115,64.7308273 72.3540115,66.6666031 72.4749985,66.6666031 72.4749985,66.6666031 73.0799255,65.9406891 73.0799255,65.9406891"
        />
        <polygon
          fill="#CCC"
          points="72.4749985,66.4246292 73.0799255,65.9406891 72.9589462,65.2147751 72.4749985,64.7308273"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,65.6987152 72.3540115,66.4246292 72.3540115,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,65.335762 72.2330322,64.609848 72.2330322,64.4888611 71.3861237,65.456749"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,65.335762 72.2330322,64.609848 72.2330322,64.4888611 71.3861237,65.456749"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,65.8197021 71.3861237,65.6987152 71.3861237,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,65.8197021 71.3861237,65.6987152 71.3861237,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,65.8197021 71.3861237,65.6987152 71.3861237,65.6987152"
        />
        <polygon
          fill="#CCC"
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.3861237,65.8197021 71.3861237,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.3861237,65.8197021 71.3861237,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.3861237,65.8197021 71.3861237,65.8197021"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,64.609848 72.2330322,64.609848 71.3861237,65.335762"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.609848 72.2330322,64.609848 71.3861237,65.335762"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,64.609848 72.3540115,64.8518219 72.3540115,64.7308273 72.3540115,64.7308273"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.609848 72.3540115,64.8518219 72.3540115,64.7308273 72.3540115,64.7308273"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,65.335762 71.3861237,65.456749 71.2651443,65.456749 71.5071182,65.6987152 71.3861237,65.6987152 71.3861237,65.6987152 72.3540115,64.8518219 72.3540115,64.609848 72.3540115,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,65.335762 71.3861237,65.456749 71.2651443,65.456749 71.5071182,65.6987152 71.3861237,65.6987152 71.3861237,65.6987152 72.3540115,64.8518219 72.3540115,64.609848 72.3540115,64.609848"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,65.335762 70.6602097,64.8518219 71.2651443,65.456749 71.2651443,63.641964 71.1441574,63.641964"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,63.641964 71.2651443,65.456749 71.2651443,65.456749 71.3861237,65.456749 72.2330322,64.4888611 72.2330322,64.4888611 71.3861237,65.335762 71.3861237,63.641964"
        />
        <polygon
          fill="#CCC"
          points="71.3861237,63.641964 71.3861237,65.335762 72.2330322,64.4888611 72.2330322,64.4888611 72.2330322,64.4888611"
        />
        <polygon
          fill={colors[4]}
          points="70.6602097,64.8518219 71.1441574,65.335762 71.1441574,63.641964 70.2972641,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,64.2468872 72.3540115,64.3678741 72.3540115,64.2468872 71.5071182,63.3999901 71.5071182,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,64.2468872 72.3540115,64.3678741 72.3540115,64.2468872 71.5071182,63.3999901 71.5071182,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.3678741 72.3540115,64.2468872 72.3540115,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.3678741 72.3540115,64.2468872 72.3540115,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.3678741 72.3540115,64.2468872 72.3540115,64.2468872"
        />
        <polygon
          fill={colors[4]}
          points="72.2330322,64.2468872 71.5071182,63.3999901 71.3861237,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,64.2468872 71.5071182,63.3999901 71.3861237,63.5209732"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,63.5209732 71.2651443,63.641964 71.3861237,63.641964 71.3861237,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.5209732 71.2651443,63.641964 71.3861237,63.641964 71.3861237,63.5209732"
        />
        <polygon
          fill="#CCC"
          points="72.2330322,64.2468872 71.3861237,63.5209732 71.3861237,63.5209732 71.3861237,63.641964 72.2330322,64.4888611 72.3540115,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,64.2468872 71.3861237,63.5209732 71.3861237,63.5209732 71.3861237,63.641964 72.2330322,64.4888611 72.3540115,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,64.1259003 71.5071182,63.3999901 71.5071182,63.3999901 72.3540115,64.2468872 72.3540115,64.2468872 72.4749985,64.2468872 72.4749985,62.5530891 72.3540115,62.5530891"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,64.2468872 72.7169724,64.004921 72.7169724,63.8839302 72.4749985,64.1259003 72.4749985,62.6740723 72.4749985,62.432106 72.4749985,62.5530891"
        />
        <polygon
          fill="#CCC"
          points="72.7169724,63.8839302 72.4749985,62.6740723 72.4749985,64.1259003"
        />
        <polygon
          fill={colors[4]}
          points="71.5071182,63.3999901 72.3540115,64.1259003 72.3540115,62.5530891"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,63.5209732 71.2651443,63.5209732 71.3861237,63.5209732 71.3861237,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.5209732 71.2651443,63.5209732 71.3861237,63.5209732 71.3861237,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.5209732 71.2651443,63.5209732 71.3861237,63.5209732 71.3861237,63.5209732"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,63.5209732 71.5071182,63.3999901 71.5071182,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,63.5209732 71.5071182,63.3999901 71.5071182,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,63.5209732 71.5071182,63.3999901 71.5071182,63.3999901"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,62.5530891 72.4749985,62.5530891 72.4749985,62.432106 72.3540115,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,62.5530891 72.4749985,62.5530891 72.4749985,62.432106 72.3540115,62.3111191"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,62.3111191 72.3540115,62.3111191 71.6281052,62.9160461"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,62.3111191 72.3540115,62.3111191 71.6281052,62.9160461"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,62.5530891 72.4749985,62.432106 72.4749985,62.432106 72.4749985,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,62.5530891 72.4749985,62.432106 72.4749985,62.432106 72.4749985,62.432106"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,63.1580162 71.5071182,63.3999901 71.5071182,63.3999901 71.5071182,63.3999901 72.3540115,62.5530891 72.3540115,62.3111191 72.3540115,62.3111191 71.6281052,62.9160461"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,63.1580162 71.5071182,63.3999901 71.5071182,63.3999901 71.5071182,63.3999901 72.3540115,62.5530891 72.3540115,62.3111191 72.3540115,62.3111191 71.6281052,62.9160461"
        />
        <polygon
          fill={colors[3]}
          points="71.2651443,63.0370331 70.6602097,62.432106 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,61.3432312 71.2651443,61.4642181"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,61.3432312 71.2651443,63.1580162 71.3861237,63.1580162 71.6281052,62.9160461 72.3540115,62.3111191 72.3540115,62.1901321 71.3861237,63.0370331 71.3861237,61.3432312 71.3861237,61.3432312"
        />
        <polygon
          fill="#CCC"
          points="71.3861237,63.0370331 72.3540115,62.1901321 72.2330322,62.1901321 72.2330322,62.1901321 71.3861237,61.3432312"
        />
        <polygon
          fill={colors[4]}
          points="70.6602097,62.432106 71.2651443,63.0370331 71.2651443,61.4642181 70.4182434,62.3111191"
        />
        <polygon
          fill={colors[3]}
          points="71.2651443,61.2222481 71.1441574,61.1012611 70.5392303,61.827179"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,61.2222481 71.1441574,61.1012611 70.5392303,61.827179"
        />
        <polygon
          fill={colors[3]}
          points="71.2651443,61.4642181 71.2651443,61.3432312 71.2651443,61.2222481 71.2651443,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,61.4642181 71.2651443,61.3432312 71.2651443,61.2222481 71.2651443,61.2222481"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,61.3432312 71.2651443,61.2222481 71.2651443,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,61.3432312 71.2651443,61.2222481 71.2651443,61.3432312"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,62.0691452 70.4182434,62.1901321 70.4182434,62.3111191 71.2651443,61.4642181 71.2651443,61.2222481 71.2651443,61.2222481 70.5392303,61.827179"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.0691452 70.4182434,62.1901321 70.4182434,62.3111191 71.2651443,61.4642181 71.2651443,61.2222481 71.2651443,61.2222481 70.5392303,61.827179"
        />
        <polygon
          fill={colors[3]}
          points="69.4503555,61.3432312 70.1762695,61.9481621 70.1762695,62.0691452 70.1762695,60.4963303 70.1762695,60.617321 70.1762695,61.827179"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.5392303,61.827179 71.1441574,61.1012611 71.1441574,61.1012611 70.2972641,61.827179 70.2972641,60.3753471 70.1762695,60.4963303"
        />
        <polygon
          fill="#CCC"
          points="70.2972641,60.3753471 70.2972641,61.827179 71.1441574,61.1012611 71.1441574,61.1012611 71.1441574,61.1012611 70.4182434,60.3753471"
        />
        <polygon
          fill={colors[4]}
          points="69.4503555,61.3432312 70.1762695,61.827179 70.1762695,60.617321 69.4503555,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5071182,61.2222481 72.3540115,61.9481621 72.3540115,62.0691452 72.3540115,61.9481621 72.3540115,61.9481621 71.5071182,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5071182,61.2222481 72.3540115,61.9481621 72.3540115,62.0691452 72.3540115,61.9481621 72.3540115,61.9481621 71.5071182,61.1012611"
        />
        <polygon
          fill={colors[4]}
          points="72.3540115,61.9481621 71.5071182,61.2222481 71.5071182,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,61.9481621 71.5071182,61.2222481 71.5071182,61.2222481"
        />
        <polygon
          fill="#CCC"
          points="71.3861237,61.3432312 71.3861237,61.3432312 72.2330322,62.1901321 72.3540115,62.0691452 72.3540115,61.9481621 71.5071182,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,61.3432312 71.3861237,61.3432312 72.2330322,62.1901321 72.3540115,62.0691452 72.3540115,61.9481621 71.5071182,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5071182,61.2222481 71.5071182,61.1012611 71.5071182,61.1012611 71.5071182,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5071182,61.2222481 71.5071182,61.1012611 71.5071182,61.1012611 71.5071182,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5071182,61.2222481 71.5071182,61.1012611 71.5071182,61.1012611 71.5071182,61.1012611"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,61.1012611 71.2651443,61.2222481 71.2651443,61.2222481 71.2651443,61.2222481 71.2651443,61.2222481 71.2651443,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,61.1012611 71.2651443,61.2222481 71.2651443,61.2222481 71.2651443,61.2222481 71.2651443,61.2222481 71.2651443,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,61.1012611 71.2651443,61.2222481 71.2651443,61.2222481 71.2651443,61.2222481 71.2651443,61.2222481 71.2651443,61.2222481"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,61.3432312 71.2651443,61.2222481 71.2651443,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,61.3432312 71.2651443,61.2222481 71.2651443,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,61.3432312 71.2651443,61.2222481 71.2651443,61.2222481"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,61.2222481 71.5071182,61.2222481 71.5071182,61.2222481 71.5071182,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,61.2222481 71.5071182,61.2222481 71.5071182,61.2222481 71.5071182,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,61.2222481 71.5071182,61.2222481 71.5071182,61.2222481 71.5071182,61.1012611"
        />
        <polygon
          fill="#CCC"
          points="71.3861237,61.3432312 71.5071182,61.2222481 71.3861237,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,61.3432312 71.5071182,61.2222481 71.3861237,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,61.3432312 71.5071182,61.2222481 71.3861237,61.2222481"
        />
        <polygon
          fill={colors[3]}
          points="71.5071182,61.1012611 71.5071182,61.1012611 71.5071182,61.1012611 72.3540115,61.9481621 72.3540115,61.827179"
        />
        <polygon
          fill={colors[4]}
          points="71.5071182,61.1012611 72.3540115,61.827179 72.1120453,60.617321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,60.8592873 71.2651443,60.8592873 71.2651443,60.8592873 70.5392303,60.1333771 70.5392303,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,60.8592873 71.2651443,60.8592873 71.2651443,60.8592873 70.5392303,60.1333771 70.5392303,60.1333771"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,60.8592873 70.5392303,60.1333771 70.4182434,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,60.8592873 70.5392303,60.1333771 70.4182434,60.2543602"
        />
        <polygon
          fill="#CCC"
          points="70.4182434,60.3753471 71.1441574,61.1012611 71.2651443,60.8592873 71.2651443,60.8592873 70.4182434,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,60.3753471 71.1441574,61.1012611 71.2651443,60.8592873 71.2651443,60.8592873 70.4182434,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,60.8592873 71.3861237,60.980278 71.3861237,60.980278 71.5071182,61.1012611 71.5071182,61.1012611 71.5071182,61.1012611 71.2651443,60.980278 71.3861237,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,60.8592873 71.3861237,60.980278 71.3861237,60.980278 71.5071182,61.1012611 71.5071182,61.1012611 71.5071182,61.1012611 71.2651443,60.980278 71.3861237,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,60.8592873 71.3861237,60.980278 71.3861237,60.980278 71.5071182,61.1012611 71.5071182,61.1012611 71.5071182,61.1012611 71.2651443,60.980278 71.3861237,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,60.8592873 71.3861237,60.980278 71.3861237,60.980278 71.5071182,61.1012611 71.5071182,61.1012611 71.5071182,61.1012611 71.2651443,60.980278 71.3861237,60.980278"
        />
        <polygon
          fill={colors[3]}
          points="71.2651443,61.2222481 71.2651443,60.980278 71.2651443,61.1012611 71.1441574,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,61.2222481 71.2651443,60.980278 71.2651443,61.1012611 71.1441574,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,61.2222481 71.2651443,60.980278 71.2651443,61.1012611 71.1441574,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,61.2222481 71.2651443,60.980278 71.2651443,61.1012611 71.1441574,61.1012611"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,61.2222481 71.3861237,61.3432312 71.3861237,61.3432312 71.3861237,61.2222481 71.3861237,61.2222481 71.5071182,61.1012611 71.3861237,60.980278 71.2651443,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,61.2222481 71.3861237,61.3432312 71.3861237,61.3432312 71.3861237,61.2222481 71.3861237,61.2222481 71.5071182,61.1012611 71.3861237,60.980278 71.2651443,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,61.2222481 71.3861237,61.3432312 71.3861237,61.3432312 71.3861237,61.2222481 71.3861237,61.2222481 71.5071182,61.1012611 71.3861237,60.980278 71.2651443,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,61.2222481 71.3861237,61.3432312 71.3861237,61.3432312 71.3861237,61.2222481 71.3861237,61.2222481 71.5071182,61.1012611 71.3861237,60.980278 71.2651443,60.980278"
        />
        <polygon
          fill="#CCC"
          points="71.3861237,61.3432312 71.3861237,61.2222481 71.3861237,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,61.3432312 71.3861237,61.2222481 71.3861237,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,61.3432312 71.3861237,61.2222481 71.3861237,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,61.3432312 71.3861237,61.2222481 71.3861237,61.2222481"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,61.1012611 71.1441574,61.1012611 71.1441574,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,61.1012611 71.1441574,61.1012611 71.1441574,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,61.1012611 71.1441574,61.1012611 71.1441574,61.1012611"
        />
        <polygon
          fill="#CCC"
          points="71.1441574,61.1012611 71.1441574,61.1012611 71.2651443,61.1012611 71.2651443,60.980278 71.3861237,60.980278 71.3861237,60.980278 71.2651443,60.8592873 71.2651443,60.8592873"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,61.1012611 71.1441574,61.1012611 71.2651443,61.1012611 71.2651443,60.980278 71.3861237,60.980278 71.3861237,60.980278 71.2651443,60.8592873 71.2651443,60.8592873"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,61.1012611 71.1441574,61.1012611 71.2651443,61.1012611 71.2651443,60.980278 71.3861237,60.980278 71.3861237,60.980278 71.2651443,60.8592873 71.2651443,60.8592873"
        />
        <polygon
          fill={colors[3]}
          points="72.1120453,60.2543602 72.1120453,60.2543602 71.7490845,60.617321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.1120453,60.2543602 72.1120453,60.2543602 71.7490845,60.617321"
        />
        <polygon
          fill={colors[4]}
          points="71.3861237,60.980278 71.3861237,60.980278 71.2651443,60.980278 71.5071182,61.1012611 71.5071182,61.1012611 72.1120453,60.617321 72.1120453,60.2543602 71.7490845,60.617321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3861237,60.980278 71.3861237,60.980278 71.2651443,60.980278 71.5071182,61.1012611 71.5071182,61.1012611 72.1120453,60.617321 72.1120453,60.2543602 71.7490845,60.617321"
        />
        <polygon
          fill={colors[4]}
          points="70.5392303,60.1333771 70.5392303,60.1333771 70.5392303,60.1333771 71.2651443,60.7383041 71.2651443,59.4074631 71.2651443,59.4074631"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,62.3111191 72.3540115,62.3111191 72.3540115,62.3111191 72.4749985,62.432106 72.4749985,62.3111191 72.3540115,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,62.3111191 72.3540115,62.3111191 72.3540115,62.3111191 72.4749985,62.432106 72.4749985,62.3111191 72.3540115,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,62.3111191 72.3540115,62.3111191 72.3540115,62.3111191 72.4749985,62.432106 72.4749985,62.3111191 72.3540115,62.1901321"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,62.432106 72.4749985,62.432106 72.4749985,62.432106 72.4749985,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,62.432106 72.4749985,62.432106 72.4749985,62.432106 72.4749985,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,62.432106 72.4749985,62.432106 72.4749985,62.432106 72.4749985,62.3111191"
        />
        <polygon
          fill={colors[7]}
          points="72.3540115,62.3111191 72.3540115,62.1901321 72.3540115,62.1901321 72.3540115,62.1901321"
        />
        <polygon
          fill={colors[7]}
          points="72.3540115,62.3111191 72.3540115,62.1901321 72.3540115,62.1901321 72.3540115,62.1901321"
        />
        <polygon
          fill="#CCC"
          points="72.3540115,62.1901321 72.3540115,62.1901321 72.2330322,62.1901321 72.2330322,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,62.1901321 72.3540115,62.1901321 72.2330322,62.1901321 72.2330322,62.1901321"
        />
        <polygon
          fill={colors[7]}
          points="72.3540115,62.0691452 72.3540115,61.9481621 72.3540115,61.9481621 72.3540115,61.9481621"
        />
        <polygon
          fill={colors[7]}
          points="72.3540115,62.0691452 72.3540115,61.9481621 72.3540115,61.9481621 72.3540115,61.9481621"
        />
        <polygon
          fill={colors[7]}
          points="72.3540115,62.0691452 72.3540115,61.9481621 72.3540115,61.9481621 72.3540115,61.9481621"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,62.1901321 72.4749985,62.3111191 72.4749985,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,62.1901321 72.4749985,62.3111191 72.4749985,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,62.1901321 72.4749985,62.3111191 72.4749985,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,62.1901321 72.4749985,62.3111191 72.4749985,62.3111191"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,62.432106 72.4749985,62.3111191 72.4749985,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,62.432106 72.4749985,62.3111191 72.4749985,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,62.432106 72.4749985,62.3111191 72.4749985,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,62.432106 72.4749985,62.3111191 72.4749985,62.3111191"
        />
        <polygon
          fill="#CCC"
          points="72.3540115,62.0691452 72.2330322,62.1901321 72.3540115,62.1901321 72.3540115,62.1901321 72.3540115,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,62.0691452 72.2330322,62.1901321 72.3540115,62.1901321 72.3540115,62.1901321 72.3540115,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,62.0691452 72.2330322,62.1901321 72.3540115,62.1901321 72.3540115,62.1901321 72.3540115,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,64.2468872 70.2972641,64.2468872 71.1441574,63.3999901 71.1441574,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,64.2468872 70.2972641,64.2468872 71.1441574,63.3999901 71.1441574,63.3999901"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,63.5209732 71.1441574,63.641964 71.2651443,63.641964 71.2651443,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,63.5209732 71.1441574,63.641964 71.2651443,63.641964 71.2651443,63.5209732"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,63.5209732 71.1441574,63.3999901 70.2972641,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,63.5209732 71.1441574,63.3999901 70.2972641,64.2468872"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,63.641964 71.2651443,63.641964 71.2651443,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.641964 71.2651443,63.641964 71.2651443,63.5209732"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,64.2468872 70.1762695,64.3678741 70.2972641,64.4888611 71.1441574,63.641964 71.1441574,63.5209732 71.1441574,63.5209732 70.2972641,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,64.2468872 70.1762695,64.3678741 70.2972641,64.4888611 71.1441574,63.641964 71.1441574,63.5209732 71.1441574,63.5209732 70.2972641,64.2468872"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,63.3999901 71.1441574,63.5209732 71.1441574,63.5209732 71.1441574,63.5209732 71.2651443,63.5209732 71.2651443,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,63.3999901 71.1441574,63.5209732 71.1441574,63.5209732 71.1441574,63.5209732 71.2651443,63.5209732 71.2651443,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,63.3999901 71.1441574,63.5209732 71.1441574,63.5209732 71.1441574,63.5209732 71.2651443,63.5209732 71.2651443,63.5209732"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,63.641964 71.2651443,63.5209732 71.2651443,63.5209732 71.2651443,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.641964 71.2651443,63.5209732 71.2651443,63.5209732 71.2651443,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.641964 71.2651443,63.5209732 71.2651443,63.5209732 71.2651443,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 70.4182434,62.3111191 70.2972641,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 70.4182434,62.3111191 70.2972641,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.2790031 71.2651443,63.2790031 71.5071182,63.3999901 71.5071182,63.3999901 71.3861237,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.2790031 71.2651443,63.2790031 71.5071182,63.3999901 71.5071182,63.3999901 71.3861237,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.2790031 71.2651443,63.2790031 71.5071182,63.3999901 71.5071182,63.3999901 71.3861237,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.2790031 71.2651443,63.2790031 71.5071182,63.3999901 71.5071182,63.3999901 71.3861237,63.3999901"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,63.3999901 71.2651443,63.5209732 71.2651443,63.2790031 71.1441574,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,63.3999901 71.2651443,63.5209732 71.2651443,63.2790031 71.1441574,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,63.3999901 71.2651443,63.5209732 71.2651443,63.2790031 71.1441574,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,63.3999901 71.2651443,63.5209732 71.2651443,63.2790031 71.1441574,63.2790031"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,63.2790031 71.2651443,63.5209732 71.2651443,63.5209732 71.3861237,63.5209732 71.3861237,63.5209732 71.3861237,63.5209732 71.5071182,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.2790031 71.2651443,63.5209732 71.2651443,63.5209732 71.3861237,63.5209732 71.3861237,63.5209732 71.3861237,63.5209732 71.5071182,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.2790031 71.2651443,63.5209732 71.2651443,63.5209732 71.3861237,63.5209732 71.3861237,63.5209732 71.3861237,63.5209732 71.5071182,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.2790031 71.2651443,63.5209732 71.2651443,63.5209732 71.3861237,63.5209732 71.3861237,63.5209732 71.3861237,63.5209732 71.5071182,63.3999901"
        />
        <polygon
          fill={colors[3]}
          points="71.5071182,63.3999901 71.5071182,63.3999901 71.3861237,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5071182,63.3999901 71.5071182,63.3999901 71.3861237,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5071182,63.3999901 71.5071182,63.3999901 71.3861237,63.3999901"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.3861237,63.3999901 71.5071182,63.3999901 71.5071182,63.3999901 71.3861237,63.1580162 71.2651443,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.3861237,63.3999901 71.5071182,63.3999901 71.5071182,63.3999901 71.3861237,63.1580162 71.2651443,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.3861237,63.3999901 71.5071182,63.3999901 71.5071182,63.3999901 71.3861237,63.1580162 71.2651443,63.2790031"
        />
        <polygon
          fill={colors[3]}
          points="71.2651443,63.1580162 70.6602097,62.432106 70.4182434,62.3111191 70.4182434,62.3111191 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.1580162 70.6602097,62.432106 70.4182434,62.3111191 70.4182434,62.3111191 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.1580162 71.2651443,63.2790031"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,63.2790031 71.3861237,63.1580162 71.2651443,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,63.2790031 71.3861237,63.1580162 71.2651443,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,62.3111191 70.2972641,62.3111191 70.4182434,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,62.3111191 70.2972641,62.3111191 70.4182434,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,62.3111191 70.2972641,62.3111191 70.4182434,62.3111191"
        />
        <polygon
          fill={colors[3]}
          points="70.2972641,62.3111191 70.4182434,62.3111191 70.4182434,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,62.3111191 70.4182434,62.3111191 70.4182434,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,62.3111191 70.4182434,62.3111191 70.4182434,62.3111191"
        />
        <polygon
          fill={colors[4]}
          points="70.4182434,62.1901321 70.2972641,62.3111191 70.4182434,62.3111191 70.4182434,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,62.1901321 70.2972641,62.3111191 70.4182434,62.3111191 70.4182434,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,62.1901321 70.2972641,62.3111191 70.4182434,62.3111191 70.4182434,62.3111191"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,64.1259003 69.5713425,63.7629471 70.0552826,64.2468872 70.0552826,64.2468872 70.1762695,64.2468872 70.1762695,62.432106 70.0552826,62.5530891"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,62.432106 70.1762695,64.2468872 70.1762695,64.3678741 70.1762695,64.2468872 71.1441574,63.3999901 71.1441574,63.3999901 71.1441574,63.3999901 70.1762695,64.1259003"
        />
        <polygon
          fill={colors[4]}
          points="71.1441574,63.1580162 70.2972641,62.3111191 70.2972641,62.3111191 70.1762695,62.432106"
        />
        <polygon
          fill="#CCC"
          points="71.1441574,63.1580162 70.1762695,62.432106 70.1762695,62.432106 70.1762695,64.1259003 71.1441574,63.3999901 71.1441574,63.2790031 71.2651443,63.2790031 71.2651443,63.2790031 71.2651443,63.2790031 71.2651443,63.1580162 71.2651443,63.1580162"
        />
        <polygon
          fill={colors[4]}
          points="69.5713425,63.7629471 70.0552826,64.1259003 70.0552826,62.5530891 69.2083893,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,62.0691452 70.0552826,62.0691452 69.4503555,61.3432312 69.3293762,61.3432312 69.9343033,61.9481621"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,62.0691452 70.0552826,62.0691452 69.4503555,61.3432312 69.3293762,61.3432312 69.9343033,61.9481621"
        />
        <polygon
          fill={colors[3]}
          points="70.1762695,61.9481621 69.4503555,61.3432312 69.4503555,61.3432312 69.4503555,61.3432312 70.0552826,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,61.9481621 69.4503555,61.3432312 69.4503555,61.3432312 69.4503555,61.3432312 70.0552826,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,62.3111191 69.9343033,62.3111191 69.0874023,63.1580162 69.0874023,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,62.3111191 69.9343033,62.3111191 69.0874023,63.1580162 69.0874023,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.0552826,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.2972641,62.3111191 70.2972641,62.3111191 70.2972641,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.0552826,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.2972641,62.3111191 70.2972641,62.3111191 70.2972641,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.0552826,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.2972641,62.3111191 70.2972641,62.3111191 70.2972641,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.0552826,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.2972641,62.3111191 70.2972641,62.3111191 70.2972641,62.1901321"
        />
        <polygon
          fill={colors[3]}
          points="70.2972641,62.3111191 70.2972641,62.3111191 70.2972641,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,62.3111191 70.2972641,62.3111191 70.2972641,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,62.3111191 70.2972641,62.3111191 70.2972641,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,62.3111191 70.2972641,62.3111191 70.2972641,62.1901321"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.2972641,62.1901321 70.2972641,62.3111191 70.4182434,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.2972641,62.1901321 70.2972641,62.3111191 70.4182434,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.2972641,62.1901321 70.2972641,62.3111191 70.4182434,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.2972641,62.1901321 70.2972641,62.3111191 70.4182434,62.1901321"
        />
        <polygon
          fill={colors[3]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,61.9481621 70.0552826,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,61.9481621 70.0552826,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,61.9481621 70.0552826,62.0691452"
        />
        <polygon
          fill={colors[3]}
          points="69.9343033,62.3111191 69.0874023,63.1580162 70.0552826,62.3111191 70.0552826,62.5530891 70.1762695,62.432106 70.1762695,62.0691452 70.0552826,62.1901321 69.9343033,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,62.3111191 69.0874023,63.1580162 70.0552826,62.3111191 70.0552826,62.5530891 70.1762695,62.432106 70.1762695,62.0691452 70.0552826,62.1901321 69.9343033,62.1901321"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,62.0691452 70.1762695,62.432106 70.1762695,62.432106 70.1762695,62.3111191 70.1762695,62.432106 70.2972641,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.0691452 70.1762695,62.432106 70.1762695,62.432106 70.1762695,62.3111191 70.1762695,62.432106 70.2972641,62.3111191"
        />
        <polygon
          fill="#CCC"
          points="70.1762695,62.432106 70.1762695,62.432106 70.1762695,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,62.432106 70.1762695,62.432106 70.1762695,62.3111191"
        />
        <polygon
          fill={colors[4]}
          points="69.2083893,63.3999901 70.0552826,62.5530891 70.0552826,62.3111191 69.0874023,63.1580162 69.0874023,63.1580162 68.9664154,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,63.3999901 70.0552826,62.5530891 70.0552826,62.3111191 69.0874023,63.1580162 69.0874023,63.1580162 68.9664154,63.1580162"
        />
        <polygon
          fill={colors[3]}
          points="68.3614807,62.5530891 68.9664154,63.1580162 68.9664154,63.1580162 68.9664154,63.1580162 68.9664154,61.7061882 68.9664154,61.827179 68.9664154,63.0370331"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,63.1580162 68.9664154,63.1580162 69.0874023,63.1580162 69.9343033,62.3111191 69.9343033,62.1901321 69.9343033,62.1901321 69.0874023,63.0370331 69.0874023,61.7061882 68.9664154,61.7061882"
        />
        <polygon
          fill={colors[4]}
          points="69.3293762,61.3432312 69.3293762,61.4642181 69.9343033,61.9481621"
        />
        <polygon
          fill="#CCC"
          points="69.0874023,61.7061882 69.0874023,63.0370331 69.9343033,62.1901321 70.0552826,62.1901321 70.1762695,62.0691452 70.1762695,62.0691452 70.1762695,62.0691452 70.0552826,62.0691452 70.0552826,62.0691452 69.9343033,61.9481621 69.3293762,61.4642181"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,63.0370331 68.9664154,61.827179 68.2405014,62.432106 68.2405014,62.432106 68.2405014,62.432106 68.3614807,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,64.609848 72.8379593,64.8518219 72.8379593,64.8518219 72.5959854,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,64.609848 72.8379593,64.8518219 72.8379593,64.8518219 72.5959854,64.4888611"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,64.609848 72.8379593,64.9728012 72.8379593,64.8518219 72.5959854,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,64.609848 72.8379593,64.9728012 72.8379593,64.8518219 72.5959854,64.609848"
        />
        <polygon
          fill="#CCC"
          points="72.4749985,64.7308273 72.4749985,64.7308273 72.9589462,65.2147751 72.8379593,64.9728012 72.4749985,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,64.7308273 72.4749985,64.7308273 72.9589462,65.2147751 72.8379593,64.9728012 72.4749985,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,64.609848 72.5959854,64.4888611 72.5959854,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,64.609848 72.5959854,64.4888611 72.5959854,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,64.609848 72.5959854,64.4888611 72.5959854,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,64.4888611 72.2330322,64.4888611 72.2330322,64.4888611 72.2330322,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,64.4888611 72.2330322,64.4888611 72.2330322,64.4888611 72.2330322,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,64.4888611 72.2330322,64.4888611 72.2330322,64.4888611 72.2330322,64.609848"
        />
        <polygon
          fill={colors[3]}
          points="72.2330322,64.609848 72.3540115,64.609848 72.3540115,64.609848 72.3540115,64.609848 72.3540115,64.7308273 72.3540115,64.609848 72.2330322,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,64.609848 72.3540115,64.609848 72.3540115,64.609848 72.3540115,64.609848 72.3540115,64.7308273 72.3540115,64.609848 72.2330322,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,64.609848 72.3540115,64.609848 72.3540115,64.609848 72.3540115,64.609848 72.3540115,64.7308273 72.3540115,64.609848 72.2330322,64.4888611"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,64.609848 72.4749985,64.609848 72.5959854,64.609848 72.5959854,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,64.609848 72.4749985,64.609848 72.5959854,64.609848 72.5959854,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,64.609848 72.4749985,64.609848 72.5959854,64.609848 72.5959854,64.609848"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,64.7308273 72.3540115,64.609848 72.3540115,64.7308273 72.4749985,64.7308273 72.4749985,64.7308273 72.4749985,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,64.7308273 72.3540115,64.609848 72.3540115,64.7308273 72.4749985,64.7308273 72.4749985,64.7308273 72.4749985,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,64.7308273 72.3540115,64.609848 72.3540115,64.7308273 72.4749985,64.7308273 72.4749985,64.7308273 72.4749985,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,64.2468872 72.7169724,64.004921 72.7169724,64.004921 72.4749985,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,64.2468872 72.7169724,64.004921 72.7169724,64.004921 72.4749985,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,64.609848 72.5959854,64.4888611 72.5959854,64.4888611 72.4749985,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,64.609848 72.5959854,64.4888611 72.5959854,64.4888611 72.4749985,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,64.609848 72.5959854,64.4888611 72.5959854,64.4888611 72.4749985,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5959854,64.609848 72.5959854,64.4888611 72.5959854,64.4888611 72.4749985,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.2468872 72.3540115,64.2468872 72.3540115,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.2468872 72.3540115,64.2468872 72.3540115,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.2468872 72.3540115,64.2468872 72.3540115,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.2468872 72.3540115,64.2468872 72.3540115,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,64.609848 72.3540115,64.3678741 72.3540115,64.4888611 72.2330322,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.609848 72.3540115,64.3678741 72.3540115,64.4888611 72.2330322,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.609848 72.3540115,64.3678741 72.3540115,64.4888611 72.2330322,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.609848 72.3540115,64.3678741 72.3540115,64.4888611 72.2330322,64.4888611"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,64.7308273 72.4749985,64.609848 72.4749985,64.609848 72.4749985,64.609848 72.5959854,64.609848 72.3540115,64.3678741 72.3540115,64.3678741 72.3540115,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,64.7308273 72.4749985,64.609848 72.4749985,64.609848 72.4749985,64.609848 72.5959854,64.609848 72.3540115,64.3678741 72.3540115,64.3678741 72.3540115,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,64.7308273 72.4749985,64.609848 72.4749985,64.609848 72.4749985,64.609848 72.5959854,64.609848 72.3540115,64.3678741 72.3540115,64.3678741 72.3540115,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,64.7308273 72.4749985,64.609848 72.4749985,64.609848 72.4749985,64.609848 72.5959854,64.609848 72.3540115,64.3678741 72.3540115,64.3678741 72.3540115,64.609848"
        />
        <polygon
          fill="#CCC"
          points="72.2330322,64.4888611 72.2330322,64.4888611 72.2330322,64.4888611 72.3540115,64.4888611 72.3540115,64.3678741 72.3540115,64.3678741 72.4749985,64.3678741 72.3540115,64.3678741 72.3540115,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,64.4888611 72.2330322,64.4888611 72.2330322,64.4888611 72.3540115,64.4888611 72.3540115,64.3678741 72.3540115,64.3678741 72.4749985,64.3678741 72.3540115,64.3678741 72.3540115,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2330322,64.4888611 72.2330322,64.4888611 72.2330322,64.4888611 72.3540115,64.4888611 72.3540115,64.3678741 72.3540115,64.3678741 72.4749985,64.3678741 72.3540115,64.3678741 72.3540115,64.3678741 72.3540115,64.3678741"
        />
        <polygon
          fill={colors[3]}
          points="72.3540115,64.2468872 72.4749985,64.3678741 72.4749985,64.3678741 72.4749985,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3540115,64.2468872 72.4749985,64.3678741 72.4749985,64.3678741 72.4749985,64.2468872"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,64.3678741 72.4749985,64.2468872 72.7169724,64.004921 72.7169724,64.004921 72.4749985,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4749985,64.3678741 72.4749985,64.2468872 72.7169724,64.004921 72.7169724,64.004921 72.4749985,64.2468872"
        />
        <polygon
          fill={colors[3]}
          points="72.5959854,64.4888611 72.5959854,64.4888611 72.5959854,64.4888611 72.8379593,64.8518219 72.8379593,64.7308273"
        />
        <polygon
          fill={colors[3]}
          points="72.7169724,64.1259003 72.7169724,64.004921 72.5959854,64.2468872"
        />
        <polygon
          fill={colors[4]}
          points="72.4749985,64.2468872 72.4749985,64.3678741 72.4749985,64.3678741 72.3540115,64.3678741 72.3540115,64.3678741 72.4749985,64.3678741 72.5959854,64.4888611 72.8379593,64.7308273 72.7169724,64.1259003 72.5959854,64.2468872"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,65.5777283 68.7244415,65.6987152 68.7244415,65.6987152 68.7244415,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,65.5777283 68.7244415,65.6987152 68.7244415,65.6987152 68.7244415,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.5456238 70.0552826,66.6666031 70.0552826,66.6666031 70.0552826,66.6666031 70.0552826,66.6666031 69.2083893,65.6987152 69.2083893,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.5456238 70.0552826,66.6666031 70.0552826,66.6666031 70.0552826,66.6666031 70.0552826,66.6666031 69.2083893,65.6987152 69.2083893,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,65.8197021 69.2083893,65.6987152 69.0874023,65.6987152 69.0874023,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,65.8197021 69.2083893,65.6987152 69.0874023,65.6987152 69.0874023,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,65.8197021 69.2083893,65.6987152 69.0874023,65.6987152 69.0874023,65.6987152"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,65.6987152 68.7244415,65.6987152 68.7244415,65.6987152 68.8454285,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,65.6987152 68.7244415,65.6987152 68.7244415,65.6987152 68.8454285,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,65.6987152 68.7244415,65.6987152 68.7244415,65.6987152 68.8454285,65.6987152"
        />
        <polygon
          fill={colors[3]}
          points="68.8454285,65.8197021 68.8454285,65.8197021 68.8454285,65.8197021 68.9664154,65.8197021 68.9664154,65.8197021 68.8454285,65.6987152 68.7244415,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,65.8197021 68.8454285,65.8197021 68.8454285,65.8197021 68.9664154,65.8197021 68.9664154,65.8197021 68.8454285,65.6987152 68.7244415,65.6987152"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,65.8197021 68.9664154,65.8197021 68.9664154,65.9406891 68.9664154,65.8197021 69.9343033,66.5456238 69.2083893,65.8197021 69.0874023,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,65.8197021 68.9664154,65.8197021 68.9664154,65.9406891 68.9664154,65.8197021 69.9343033,66.5456238 69.2083893,65.8197021 69.0874023,65.6987152"
        />
        <polygon
          fill="#CCC"
          points="68.9664154,65.8197021 68.9664154,65.9406891 69.9343033,66.78759 69.9343033,66.6666031 70.0552826,66.6666031 69.9343033,66.5456238"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,65.8197021 68.9664154,65.9406891 69.9343033,66.78759 69.9343033,66.6666031 70.0552826,66.6666031 69.9343033,66.5456238"
        />
        <polygon
          fill="#CCC"
          points="69.9343033,66.6666031 70.0552826,66.6666031 70.0552826,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.6666031 70.0552826,66.6666031 70.0552826,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.6666031 70.0552826,66.6666031 70.0552826,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,66.5456238 71.0231705,65.6987152 71.0231705,65.6987152 70.1762695,66.6666031 70.1762695,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,66.5456238 71.0231705,65.6987152 71.0231705,65.6987152 70.1762695,66.6666031 70.1762695,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,66.6666031 70.2972641,66.78759 70.2972641,66.78759 70.2972641,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,66.6666031 70.2972641,66.78759 70.2972641,66.78759 70.2972641,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,66.6666031 70.2972641,66.78759 70.2972641,66.78759 70.2972641,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,66.6666031 70.0552826,66.6666031 70.1762695,66.6666031 70.1762695,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,66.6666031 70.0552826,66.6666031 70.1762695,66.6666031 70.1762695,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,66.6666031 70.0552826,66.6666031 70.1762695,66.6666031 70.1762695,66.6666031"
        />
        <polygon
          fill="#CCC"
          points="69.9343033,66.6666031 69.9343033,66.78759 69.9343033,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.6666031 69.9343033,66.78759 69.9343033,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.6666031 69.9343033,66.78759 69.9343033,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,66.6666031 70.0552826,66.78759 70.1762695,66.9085693 70.2972641,66.78759 70.1762695,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,66.6666031 70.0552826,66.78759 70.1762695,66.9085693 70.2972641,66.78759 70.1762695,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,66.6666031 70.0552826,66.78759 70.1762695,66.9085693 70.2972641,66.78759 70.1762695,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,66.6666031 70.0552826,66.78759 70.1762695,66.9085693 70.2972641,66.78759 70.1762695,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.9085693 70.0552826,66.78759 69.9343033,66.78759 69.9343033,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.9085693 70.0552826,66.78759 69.9343033,66.78759 69.9343033,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.9085693 70.0552826,66.78759 69.9343033,66.78759 69.9343033,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.9085693 70.0552826,66.78759 69.9343033,66.78759 69.9343033,66.78759"
        />
        <polygon
          fill="#CCC"
          points="69.9343033,66.6666031 69.9343033,66.78759 69.9343033,66.78759 69.9343033,66.78759 70.0552826,66.78759 70.0552826,66.78759 70.0552826,66.78759 70.0552826,66.6666031 70.0552826,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.6666031 69.9343033,66.78759 69.9343033,66.78759 69.9343033,66.78759 70.0552826,66.78759 70.0552826,66.78759 70.0552826,66.78759 70.0552826,66.6666031 70.0552826,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.6666031 69.9343033,66.78759 69.9343033,66.78759 69.9343033,66.78759 70.0552826,66.78759 70.0552826,66.78759 70.0552826,66.78759 70.0552826,66.6666031 70.0552826,66.6666031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.6666031 69.9343033,66.78759 69.9343033,66.78759 69.9343033,66.78759 70.0552826,66.78759 70.0552826,66.78759 70.0552826,66.78759 70.0552826,66.6666031 70.0552826,66.6666031"
        />
        <polygon
          fill={colors[3]}
          points="69.9343033,66.9085693 70.0552826,66.9085693 70.0552826,66.78759 70.0552826,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.9085693 70.0552826,66.9085693 70.0552826,66.78759 70.0552826,66.78759"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,66.9085693 70.0552826,66.9085693 70.0552826,66.78759 70.0552826,66.78759"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,66.78759 70.0552826,66.78759 70.0552826,66.9085693 70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563 70.1762695,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,66.78759 70.0552826,66.78759 70.0552826,66.9085693 70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563 70.1762695,66.9085693"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,66.78759 70.0552826,66.78759 70.0552826,66.9085693 70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563 70.0552826,67.0295563 70.1762695,66.9085693"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,65.8197021 71.1441574,65.9406891 71.2651443,65.9406891 71.2651443,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,65.8197021 71.1441574,65.9406891 71.2651443,65.9406891 71.2651443,65.8197021"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,65.8197021 71.0231705,65.6987152 70.2972641,66.5456238"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,65.8197021 71.0231705,65.6987152 70.2972641,66.5456238"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,65.9406891 71.2651443,65.8197021 71.2651443,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.9406891 71.2651443,65.8197021 71.2651443,65.8197021"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,66.6666031 70.0552826,66.6666031 70.0552826,66.6666031 70.1762695,66.6666031 70.2972641,66.78759 70.2972641,66.78759 71.1441574,65.9406891 71.1441574,65.8197021 71.1441574,65.8197021 70.2972641,66.5456238"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,66.6666031 70.0552826,66.6666031 70.0552826,66.6666031 70.1762695,66.6666031 70.2972641,66.78759 70.2972641,66.78759 71.1441574,65.9406891 71.1441574,65.8197021 71.1441574,65.8197021 70.2972641,66.5456238"
        />
        <polygon
          fill={colors[3]}
          points="71.1441574,65.8197021 71.1441574,65.8197021 71.1441574,65.8197021 71.2651443,65.8197021 71.2651443,65.8197021 71.1441574,65.6987152 71.0231705,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,65.8197021 71.1441574,65.8197021 71.1441574,65.8197021 71.2651443,65.8197021 71.2651443,65.8197021 71.1441574,65.6987152 71.0231705,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,65.8197021 71.1441574,65.8197021 71.1441574,65.8197021 71.2651443,65.8197021 71.2651443,65.8197021 71.1441574,65.6987152 71.0231705,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,65.335762 71.1441574,65.456749 71.2651443,65.456749 70.2972641,64.609848 70.2972641,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,65.335762 71.1441574,65.456749 71.2651443,65.456749 70.2972641,64.609848 70.2972641,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.5777283 71.1441574,65.6987152 71.0231705,65.6987152 71.1441574,65.6987152 71.2651443,65.5777283 71.3861237,65.6987152 71.3861237,65.6987152 71.3861237,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.5777283 71.1441574,65.6987152 71.0231705,65.6987152 71.1441574,65.6987152 71.2651443,65.5777283 71.3861237,65.6987152 71.3861237,65.6987152 71.3861237,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.5777283 71.1441574,65.6987152 71.0231705,65.6987152 71.1441574,65.6987152 71.2651443,65.5777283 71.3861237,65.6987152 71.3861237,65.6987152 71.3861237,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.5777283 71.1441574,65.6987152 71.0231705,65.6987152 71.1441574,65.6987152 71.2651443,65.5777283 71.3861237,65.6987152 71.3861237,65.6987152 71.3861237,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,65.456749 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,65.456749 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,65.456749 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1441574,65.456749 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749"
        />
        <polygon
          fill={colors[3]}
          points="71.2651443,65.5777283 71.1441574,65.6987152 71.2651443,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.5777283 71.1441574,65.6987152 71.2651443,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.5777283 71.1441574,65.6987152 71.2651443,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.5777283 71.1441574,65.6987152 71.2651443,65.8197021"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.2651443,65.8197021 71.2651443,65.8197021 71.3861237,65.8197021 71.3861237,65.6987152 71.2651443,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.2651443,65.8197021 71.2651443,65.8197021 71.3861237,65.8197021 71.3861237,65.6987152 71.2651443,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.2651443,65.8197021 71.2651443,65.8197021 71.3861237,65.8197021 71.3861237,65.6987152 71.2651443,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.2651443,65.8197021 71.2651443,65.8197021 71.3861237,65.8197021 71.3861237,65.6987152 71.2651443,65.5777283"
        />
        <polygon
          fill="#CCC"
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.3861237,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.3861237,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.3861237,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.8197021 71.2651443,65.8197021 71.3861237,65.8197021"
        />
        <polygon
          fill={colors[4]}
          points="71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.5777283 71.2651443,65.5777283 71.3861237,65.6987152 71.3861237,65.6987152 71.5071182,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.5777283 71.2651443,65.5777283 71.3861237,65.6987152 71.3861237,65.6987152 71.5071182,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.5777283 71.2651443,65.5777283 71.3861237,65.6987152 71.3861237,65.6987152 71.5071182,65.6987152"
        />
        <polygon
          fill={colors[3]}
          points="70.6602097,64.8518219 70.2972641,64.4888611 70.2972641,64.609848 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6602097,64.8518219 70.2972641,64.4888611 70.2972641,64.609848 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749 71.2651443,65.456749"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,64.609848 70.2972641,64.609848 70.2972641,64.609848 70.2972641,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,64.609848 70.2972641,64.609848 70.2972641,64.609848 70.2972641,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,64.609848 70.2972641,64.609848 70.2972641,64.609848 70.2972641,64.4888611"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,64.3678741 70.1762695,64.3678741 70.2972641,64.4888611 70.2972641,64.4888611 70.2972641,64.4888611 70.2972641,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,64.3678741 70.1762695,64.3678741 70.2972641,64.4888611 70.2972641,64.4888611 70.2972641,64.4888611 70.2972641,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,64.3678741 70.1762695,64.3678741 70.2972641,64.4888611 70.2972641,64.4888611 70.2972641,64.4888611 70.2972641,64.4888611"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,64.2468872 70.0552826,64.2468872 70.1762695,64.2468872 70.1762695,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,64.2468872 70.0552826,64.2468872 70.1762695,64.2468872 70.1762695,64.2468872"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,64.3678741 70.1762695,64.3678741 70.1762695,64.2468872 70.1762695,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,64.3678741 70.1762695,64.3678741 70.1762695,64.2468872 70.1762695,64.2468872"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,66.4246292 69.0874023,65.6987152 69.0874023,65.6987152 69.2083893,65.6987152 70.0552826,66.6666031 70.0552826,66.6666031 70.0552826,64.7308273 70.0552826,64.8518219"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,66.4246292 70.1762695,64.609848 70.0552826,64.7308273 70.0552826,66.6666031 70.1762695,66.6666031 71.0231705,65.6987152 71.0231705,65.6987152 71.1441574,65.6987152"
        />
        <polygon
          fill={colors[4]}
          points="70.2972641,64.609848 70.1762695,64.609848 71.1441574,65.335762"
        />
        <polygon
          fill="#CCC"
          points="71.1441574,65.456749 71.1441574,65.335762 70.1762695,64.609848 70.1762695,64.609848 70.1762695,66.4246292 71.1441574,65.6987152 71.2651443,65.5777283 71.2651443,65.5777283 71.2651443,65.456749"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,65.6987152 70.0552826,66.4246292 70.0552826,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,65.456749 68.8454285,65.456749 68.1195145,64.609848 67.9985352,64.7308273"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,65.456749 68.8454285,65.456749 68.1195145,64.609848 67.9985352,64.7308273"
        />
        <polygon
          fill={colors[4]}
          points="67.9985352,64.7308273 67.8775482,64.7308273 68.8454285,65.456749"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,64.7308273 67.8775482,64.7308273 68.8454285,65.456749"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,65.5777283 68.8454285,65.456749 68.8454285,65.456749 67.8775482,64.7308273 67.8775482,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,65.5777283 68.8454285,65.456749 68.8454285,65.456749 67.8775482,64.7308273 67.8775482,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,64.4888611 67.8775482,64.609848 67.9985352,64.7308273 68.1195145,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,64.4888611 67.8775482,64.609848 67.9985352,64.7308273 68.1195145,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,64.4888611 67.8775482,64.609848 67.9985352,64.7308273 68.1195145,64.609848"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,64.7308273 67.7565536,64.609848 67.6355743,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.7308273 67.7565536,64.609848 67.6355743,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.7308273 67.7565536,64.609848 67.6355743,64.609848"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,64.7308273 67.8775482,64.7308273 67.9985352,64.7308273 67.8775482,64.609848 67.7565536,64.7308273 67.7565536,64.7308273 67.8775482,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,64.7308273 67.8775482,64.7308273 67.9985352,64.7308273 67.8775482,64.609848 67.7565536,64.7308273 67.7565536,64.7308273 67.8775482,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,64.7308273 67.8775482,64.7308273 67.9985352,64.7308273 67.8775482,64.609848 67.7565536,64.7308273 67.7565536,64.7308273 67.8775482,64.8518219"
        />
        <polygon
          fill="#CCC"
          points="67.8775482,64.7308273 67.8775482,64.7308273 67.8775482,64.8518219 67.8775482,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,64.7308273 67.8775482,64.7308273 67.8775482,64.8518219 67.8775482,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,64.7308273 67.8775482,64.7308273 67.8775482,64.8518219 67.8775482,64.8518219"
        />
        <polygon
          fill="#CCC"
          points="67.5145874,64.4888611 67.5145874,64.4888611 67.6355743,64.609848 67.7565536,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5145874,64.4888611 67.5145874,64.4888611 67.6355743,64.609848 67.7565536,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,64.2468872 70.0552826,64.3678741 70.0552826,64.2468872 69.2083893,63.3999901 69.0874023,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9343033,64.2468872 70.0552826,64.3678741 70.0552826,64.2468872 69.2083893,63.3999901 69.0874023,63.3999901"
        />
        <polygon
          fill={colors[3]}
          points="69.2083893,63.3999901 70.0552826,64.2468872 70.0552826,64.2468872 69.5713425,63.7629471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,63.3999901 70.0552826,64.2468872 70.0552826,64.2468872 69.5713425,63.7629471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,63.3999901 69.0874023,63.3999901 69.2083893,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,63.3999901 69.0874023,63.3999901 69.2083893,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,63.3999901 69.0874023,63.3999901 69.2083893,63.3999901"
        />
        <polygon
          fill={colors[4]}
          points="69.2083893,63.3999901 69.0874023,63.3999901 69.2083893,63.3999901 69.2083893,63.3999901 68.9664154,63.1580162 68.9664154,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,63.3999901 69.0874023,63.3999901 69.2083893,63.3999901 69.2083893,63.3999901 68.9664154,63.1580162 68.9664154,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,63.3999901 69.0874023,63.3999901 69.2083893,63.3999901 69.2083893,63.3999901 68.9664154,63.1580162 68.9664154,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,65.456749 68.9664154,65.456749 69.0874023,65.335762 69.9343033,64.609848 69.9343033,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,65.456749 68.9664154,65.456749 69.0874023,65.335762 69.9343033,64.609848 69.9343033,64.4888611"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,65.5777283 68.7244415,65.6987152 68.8454285,65.5777283 68.8454285,65.456749"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,65.5777283 68.7244415,65.6987152 68.8454285,65.5777283 68.8454285,65.456749"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,65.5777283 68.7244415,65.6987152 68.8454285,65.5777283 68.8454285,65.456749"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,65.5777283 68.9664154,65.456749 68.9664154,65.456749 68.9664154,65.456749 68.9664154,65.456749 68.8454285,65.5777283 68.9664154,65.5777283 68.9664154,65.5777283 69.0874023,65.6987152 69.0874023,65.6987152 69.0874023,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,65.5777283 68.9664154,65.456749 68.9664154,65.456749 68.9664154,65.456749 68.9664154,65.456749 68.8454285,65.5777283 68.9664154,65.5777283 68.9664154,65.5777283 69.0874023,65.6987152 69.0874023,65.6987152 69.0874023,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,65.5777283 68.9664154,65.456749 68.9664154,65.456749 68.9664154,65.456749 68.9664154,65.456749 68.8454285,65.5777283 68.9664154,65.5777283 68.9664154,65.5777283 69.0874023,65.6987152 69.0874023,65.6987152 69.0874023,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,65.5777283 68.9664154,65.456749 68.9664154,65.456749 68.9664154,65.456749 68.9664154,65.456749 68.8454285,65.5777283 68.9664154,65.5777283 68.9664154,65.5777283 69.0874023,65.6987152 69.0874023,65.6987152 69.0874023,65.6987152"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,65.6987152 68.8454285,65.6987152 68.9664154,65.5777283 68.8454285,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,65.6987152 68.8454285,65.6987152 68.9664154,65.5777283 68.8454285,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,65.6987152 68.8454285,65.6987152 68.9664154,65.5777283 68.8454285,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,65.6987152 68.8454285,65.6987152 68.9664154,65.5777283 68.8454285,65.5777283"
        />
        <polygon
          fill={colors[3]}
          points="68.8454285,65.6987152 68.9664154,65.8197021 68.9664154,65.5777283 68.9664154,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,65.6987152 68.9664154,65.8197021 68.9664154,65.5777283 68.9664154,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,65.6987152 68.9664154,65.8197021 68.9664154,65.5777283 68.9664154,65.5777283"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,65.8197021 68.9664154,65.8197021 69.0874023,65.6987152 68.9664154,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,65.8197021 68.9664154,65.8197021 69.0874023,65.6987152 68.9664154,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,65.8197021 68.9664154,65.8197021 69.0874023,65.6987152 68.9664154,65.5777283"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,64.3678741 70.1762695,64.3678741 70.0552826,64.3678741 70.0552826,64.3678741 70.0552826,64.3678741 69.9343033,64.4888611 69.9343033,64.4888611 69.9343033,64.4888611 70.0552826,64.3678741 70.2972641,64.609848 70.2972641,64.4888611 70.2972641,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,64.3678741 70.1762695,64.3678741 70.0552826,64.3678741 70.0552826,64.3678741 70.0552826,64.3678741 69.9343033,64.4888611 69.9343033,64.4888611 69.9343033,64.4888611 70.0552826,64.3678741 70.2972641,64.609848 70.2972641,64.4888611 70.2972641,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,64.3678741 70.1762695,64.3678741 70.0552826,64.3678741 70.0552826,64.3678741 70.0552826,64.3678741 69.9343033,64.4888611 69.9343033,64.4888611 69.9343033,64.4888611 70.0552826,64.3678741 70.2972641,64.609848 70.2972641,64.4888611 70.2972641,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,64.3678741 70.1762695,64.3678741 70.0552826,64.3678741 70.0552826,64.3678741 70.0552826,64.3678741 69.9343033,64.4888611 69.9343033,64.4888611 69.9343033,64.4888611 70.0552826,64.3678741 70.2972641,64.609848 70.2972641,64.4888611 70.2972641,64.4888611"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,64.3678741 70.0552826,64.3678741 70.2972641,64.4888611 70.2972641,64.4888611 70.2972641,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,64.3678741 70.0552826,64.3678741 70.2972641,64.4888611 70.2972641,64.4888611 70.2972641,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,64.3678741 70.0552826,64.3678741 70.2972641,64.4888611 70.2972641,64.4888611 70.2972641,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,64.3678741 70.0552826,64.3678741 70.2972641,64.4888611 70.2972641,64.4888611 70.2972641,64.4888611"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,64.3678741 70.1762695,64.3678741 70.1762695,64.3678741 70.1762695,64.2468872 70.0552826,64.2468872 70.0552826,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,64.3678741 70.1762695,64.3678741 70.1762695,64.3678741 70.1762695,64.2468872 70.0552826,64.2468872 70.0552826,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,64.3678741 70.1762695,64.3678741 70.1762695,64.3678741 70.1762695,64.2468872 70.0552826,64.2468872 70.0552826,64.2468872"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,64.2468872 70.1762695,64.3678741 70.1762695,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,64.2468872 70.1762695,64.3678741 70.1762695,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,64.2468872 70.1762695,64.3678741 70.1762695,64.3678741"
        />
        <polygon
          fill={colors[3]}
          points="70.0552826,64.3678741 69.9343033,64.4888611 69.9343033,64.609848 69.0874023,65.335762 70.0552826,64.609848 70.0552826,64.8518219 70.0552826,64.7308273"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,64.3678741 69.9343033,64.4888611 69.9343033,64.609848 69.0874023,65.335762 70.0552826,64.609848 70.0552826,64.8518219 70.0552826,64.7308273"
        />
        <polygon
          fill={colors[4]}
          points="70.0552826,64.3678741 70.0552826,64.7308273 70.1762695,64.609848 70.1762695,64.609848 70.1762695,64.609848 70.2972641,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.0552826,64.3678741 70.0552826,64.7308273 70.1762695,64.609848 70.1762695,64.609848 70.1762695,64.609848 70.2972641,64.609848"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,65.456749 68.9664154,65.5777283 69.0874023,65.6987152 69.0874023,65.6987152 70.0552826,64.8518219 70.0552826,64.609848 69.0874023,65.335762"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,65.456749 68.9664154,65.5777283 69.0874023,65.6987152 69.0874023,65.6987152 70.0552826,64.8518219 70.0552826,64.609848 69.0874023,65.335762"
        />
        <polygon
          fill={colors[3]}
          points="68.9664154,63.5209732 68.8454285,63.641964 68.8454285,65.2147751 67.9985352,64.4888611 67.9985352,64.4888611 68.1195145,64.609848 68.8454285,65.456749 68.9664154,65.456749 68.9664154,65.456749"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,63.3999901 69.0874023,63.5209732 69.9343033,64.2468872"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,65.2147751 68.9664154,63.5209732 68.9664154,63.5209732 68.9664154,65.456749 68.9664154,65.456749 68.9664154,65.456749 69.9343033,64.4888611 69.9343033,64.4888611 69.9343033,64.4888611"
        />
        <polygon
          fill="#CCC"
          points="69.9343033,64.2468872 69.0874023,63.5209732 68.9664154,63.5209732 68.9664154,65.2147751 69.9343033,64.4888611 70.0552826,64.3678741 70.0552826,64.3678741 70.0552826,64.3678741"
        />
        <polygon
          fill={colors[4]}
          points="68.8454285,65.2147751 68.8454285,63.641964 67.9985352,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,63.641964 67.6355743,64.3678741 67.7565536,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,63.641964 67.6355743,64.3678741 67.7565536,64.2468872"
        />
        <polygon
          fill={colors[4]}
          points="67.6355743,64.3678741 67.0306396,63.641964 66.9096603,63.7629471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6355743,64.3678741 67.0306396,63.641964 66.9096603,63.7629471"
        />
        <polygon
          fill="#CCC"
          points="66.9096603,63.7629471 66.9096603,63.7629471 67.5145874,64.4888611 67.6355743,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,63.7629471 66.9096603,63.7629471 67.5145874,64.4888611 67.6355743,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,63.1580162 68.8454285,63.1580162 68.2405014,62.432106 68.2405014,62.5530891 68.7244415,63.0370331"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8454285,63.1580162 68.8454285,63.1580162 68.2405014,62.432106 68.2405014,62.5530891 68.7244415,63.0370331"
        />
        <polygon
          fill={colors[3]}
          points="68.9664154,63.1580162 68.3614807,62.5530891 68.2405014,62.432106 68.2405014,62.432106 68.8454285,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.1580162 68.3614807,62.5530891 68.2405014,62.432106 68.2405014,62.432106 68.8454285,63.1580162"
        />
        <polygon
          fill={colors[4]}
          points="68.2405014,62.432106 68.2405014,62.432106 68.3614807,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,62.432106 68.2405014,62.432106 68.3614807,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,63.3999901 68.7244415,63.3999901 67.7565536,64.3678741 67.7565536,64.3678741 67.9985352,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,63.3999901 68.7244415,63.3999901 67.7565536,64.3678741 67.7565536,64.3678741 67.9985352,64.2468872"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.2468872 67.6355743,64.3678741 67.6355743,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.2468872 67.6355743,64.3678741 67.6355743,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.2468872 67.6355743,64.3678741 67.6355743,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,64.4888611 67.7565536,64.3678741 67.7565536,64.3678741 67.8775482,64.4888611 67.8775482,64.4888611 67.8775482,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,64.4888611 67.7565536,64.3678741 67.7565536,64.3678741 67.8775482,64.4888611 67.8775482,64.4888611 67.8775482,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,64.4888611 67.7565536,64.3678741 67.7565536,64.3678741 67.8775482,64.4888611 67.8775482,64.4888611 67.8775482,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,64.4888611 67.7565536,64.3678741 67.7565536,64.3678741 67.8775482,64.4888611 67.8775482,64.4888611 67.8775482,64.609848"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,64.609848 67.7565536,64.7308273 67.7565536,64.7308273 67.7565536,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.609848 67.7565536,64.7308273 67.7565536,64.7308273 67.7565536,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.609848 67.7565536,64.7308273 67.7565536,64.7308273 67.7565536,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.609848 67.7565536,64.7308273 67.7565536,64.7308273 67.7565536,64.4888611"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,64.4888611 67.7565536,64.7308273 67.8775482,64.609848 67.8775482,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.4888611 67.7565536,64.7308273 67.8775482,64.609848 67.8775482,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.4888611 67.7565536,64.7308273 67.8775482,64.609848 67.8775482,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.4888611 67.7565536,64.7308273 67.8775482,64.609848 67.8775482,64.4888611"
        />
        <polygon
          fill="#CCC"
          points="67.7565536,64.609848 67.7565536,64.4888611 67.8775482,64.4888611 67.8775482,64.4888611 67.7565536,64.3678741 67.6355743,64.3678741 67.6355743,64.3678741 67.5145874,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.609848 67.7565536,64.4888611 67.8775482,64.4888611 67.8775482,64.4888611 67.7565536,64.3678741 67.6355743,64.3678741 67.6355743,64.3678741 67.5145874,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,64.609848 67.7565536,64.4888611 67.8775482,64.4888611 67.8775482,64.4888611 67.7565536,64.3678741 67.6355743,64.3678741 67.6355743,64.3678741 67.5145874,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,63.3999901 68.7244415,63.3999901 68.7244415,63.3999901 68.8454285,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,63.3999901 68.7244415,63.3999901 68.7244415,63.3999901 68.8454285,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7244415,63.3999901 68.7244415,63.3999901 68.7244415,63.3999901 68.8454285,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 69.0874023,63.3999901 69.0874023,63.3999901 69.0874023,63.3999901 68.9664154,63.2790031 68.9664154,63.2790031 68.9664154,63.1580162 68.9664154,63.1580162 68.9664154,63.1580162 68.7244415,63.3999901 68.7244415,63.3999901 68.8454285,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 69.0874023,63.3999901 69.0874023,63.3999901 69.0874023,63.3999901 68.9664154,63.2790031 68.9664154,63.2790031 68.9664154,63.1580162 68.9664154,63.1580162 68.9664154,63.1580162 68.7244415,63.3999901 68.7244415,63.3999901 68.8454285,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 69.0874023,63.3999901 69.0874023,63.3999901 69.0874023,63.3999901 68.9664154,63.2790031 68.9664154,63.2790031 68.9664154,63.1580162 68.9664154,63.1580162 68.9664154,63.1580162 68.7244415,63.3999901 68.7244415,63.3999901 68.8454285,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 69.0874023,63.3999901 69.0874023,63.3999901 69.0874023,63.3999901 68.9664154,63.2790031 68.9664154,63.2790031 68.9664154,63.1580162 68.9664154,63.1580162 68.9664154,63.1580162 68.7244415,63.3999901 68.7244415,63.3999901 68.8454285,63.3999901"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,63.2790031 68.9664154,63.2790031 69.0874023,63.3999901 69.0874023,63.3999901 69.2083893,63.3999901 68.9664154,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 68.9664154,63.2790031 69.0874023,63.3999901 69.0874023,63.3999901 69.2083893,63.3999901 68.9664154,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 68.9664154,63.2790031 69.0874023,63.3999901 69.0874023,63.3999901 69.2083893,63.3999901 68.9664154,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 68.9664154,63.2790031 69.0874023,63.3999901 69.0874023,63.3999901 69.2083893,63.3999901 68.9664154,63.1580162"
        />
        <polygon
          fill={colors[3]}
          points="68.9664154,63.2790031 68.9664154,63.2790031 68.9664154,63.1580162 68.9664154,63.1580162 68.8454285,63.1580162 68.9664154,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 68.9664154,63.2790031 68.9664154,63.1580162 68.9664154,63.1580162 68.8454285,63.1580162 68.9664154,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 68.9664154,63.2790031 68.9664154,63.1580162 68.9664154,63.1580162 68.8454285,63.1580162 68.9664154,63.1580162"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,63.2790031 68.9664154,63.1580162 68.9664154,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 68.9664154,63.1580162 68.9664154,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 68.9664154,63.1580162 68.9664154,63.1580162"
        />
        <polygon
          fill={colors[3]}
          points="68.9664154,63.2790031 68.8454285,63.3999901 68.7244415,63.3999901 67.9985352,64.2468872 68.8454285,63.5209732 68.8454285,63.641964 68.9664154,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 68.8454285,63.3999901 68.7244415,63.3999901 67.9985352,64.2468872 68.8454285,63.5209732 68.8454285,63.641964 68.9664154,63.5209732"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,63.2790031 68.9664154,63.5209732 68.9664154,63.5209732 68.9664154,63.5209732 69.0874023,63.5209732 69.0874023,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,63.2790031 68.9664154,63.5209732 68.9664154,63.5209732 68.9664154,63.5209732 69.0874023,63.5209732 69.0874023,63.3999901"
        />
        <polygon
          fill={colors[4]}
          points="67.9985352,64.4888611 68.8454285,63.641964 68.8454285,63.5209732 67.9985352,64.2468872 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,64.4888611 68.8454285,63.641964 68.8454285,63.5209732 67.9985352,64.2468872 67.7565536,64.3678741 67.7565536,64.3678741 67.7565536,64.3678741"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,62.9160461 67.7565536,63.0370331 67.7565536,64.1259003 67.0306396,63.641964 67.0306396,63.641964 67.7565536,64.2468872 67.7565536,64.3678741"
        />
        <polygon
          fill={colors[4]}
          points="68.2405014,62.5530891 68.1195145,62.5530891 68.7244415,63.0370331"
        />
        <polygon
          fill={colors[4]}
          points="68.7244415,63.3999901 68.7244415,63.3999901 67.8775482,64.1259003 67.8775482,62.795063 67.7565536,62.9160461 67.7565536,64.3678741 67.7565536,64.3678741"
        />
        <polygon
          fill="#CCC"
          points="68.7244415,63.3999901 68.9664154,63.1580162 68.9664154,63.1580162 68.8454285,63.1580162 68.7244415,63.0370331 68.1195145,62.5530891 67.8775482,62.795063 67.8775482,64.1259003"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,64.1259003 67.7565536,63.0370331 67.0306396,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,74.8936234 66.5466995,74.8936234 66.5466995,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,74.8936234 66.5466995,74.8936234 66.5466995,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,74.8936234 66.5466995,74.8936234 66.5466995,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,73.8047638 65.5788193,73.9257431 65.5788193,73.9257431 66.4257126,74.772644 66.4257126,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,73.8047638 65.5788193,73.9257431 65.5788193,73.9257431 66.4257126,74.772644 66.4257126,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,74.772644 66.4257126,74.772644 66.4257126,74.772644 66.4257126,74.8936234 66.4257126,74.8936234 66.5466995,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,74.772644 66.4257126,74.772644 66.4257126,74.772644 66.4257126,74.8936234 66.4257126,74.8936234 66.5466995,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,74.772644 66.4257126,74.772644 66.4257126,74.772644 66.4257126,74.8936234 66.4257126,74.8936234 66.5466995,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,73.6837692 65.4578323,73.6837692 65.5788193,73.9257431 65.5788193,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,73.6837692 65.4578323,73.6837692 65.5788193,73.9257431 65.5788193,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,73.6837692 65.4578323,73.6837692 65.5788193,73.9257431 65.5788193,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,74.8936234 66.5466995,74.8936234 66.5466995,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,74.8936234 66.5466995,74.8936234 66.5466995,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,74.8936234 66.5466995,74.8936234 66.5466995,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4257126,74.8936234 66.5466995,74.8936234 66.5466995,74.772644"
        />
        <polygon
          fill={colors[3]}
          points="65.3368454,74.1677094 65.3368454,74.8936234 65.3368454,74.8936234 65.3368454,74.04673"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,74.04673 66.4257126,74.772644 66.4257126,74.772644 65.5788193,73.9257431 65.3368454,74.04673 65.3368454,74.8936234 65.4578323,74.8936234"
        />
        <polygon
          fill="#CCC"
          points="65.4578323,74.04673 65.4578323,74.8936234 66.4257126,74.8936234 66.4257126,74.772644"
        />
        <polygon
          fill={colors[4]}
          points="64.6109314,74.8936234 65.3368454,74.8936234 65.3368454,74.1677094"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,72.4739075 64.2479782,72.4739075 63.4010735,71.6270142 63.4010735,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,72.4739075 64.2479782,72.4739075 63.4010735,71.6270142 63.4010735,71.7480011"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,72.715889 64.4899445,72.715889 64.3689651,72.594902 64.2479782,72.4739075 64.2479782,72.4739075 64.2479782,72.4739075 64.2479782,72.4739075 64.2479782,72.4739075 64.1269836,72.4739075 64.1269836,72.4739075 64.1269836,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,72.715889 64.4899445,72.715889 64.3689651,72.594902 64.2479782,72.4739075 64.2479782,72.4739075 64.2479782,72.4739075 64.2479782,72.4739075 64.2479782,72.4739075 64.1269836,72.4739075 64.1269836,72.4739075 64.1269836,72.4739075"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,72.715889 64.4899445,72.715889 64.3689651,72.594902 64.2479782,72.4739075 64.2479782,72.4739075 64.2479782,72.4739075 64.2479782,72.4739075 64.2479782,72.4739075 64.1269836,72.4739075 64.1269836,72.4739075 64.1269836,72.4739075"
        />
        <polygon
          fill={colors[3]}
          points="64.3689651,72.594902 64.4899445,72.715889 64.4899445,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,72.594902 64.4899445,72.715889 64.4899445,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,72.594902 64.4899445,72.715889 64.4899445,72.715889"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,72.715889 64.2479782,72.4739075 64.2479782,72.4739075 64.3689651,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,72.715889 64.2479782,72.4739075 64.2479782,72.4739075 64.3689651,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,72.715889 64.2479782,72.4739075 64.2479782,72.4739075 64.3689651,72.594902"
        />
        <polygon
          fill={colors[3]}
          points="64.2479782,72.4739075 64.2479782,72.4739075 63.4010735,71.6270142 63.4010735,71.6270142"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,72.4739075 64.2479782,72.4739075 63.4010735,71.6270142 63.4010735,71.6270142"
        />
        <polygon
          fill={colors[4]}
          points="63.4010735,73.1998291 63.1591034,73.441803 63.1591034,72.9578552 63.0381203,73.441803 63.0381203,73.5627823"
        />
        <polygon
          fill={colors[4]}
          points="63.4010735,71.7480011 63.4010735,71.8689804 64.1269836,72.4739075"
        />
        <polygon
          fill="#CCC"
          points="63.4010735,73.1998291 64.1269836,72.4739075 64.1269836,72.4739075 64.1269836,72.4739075 63.4010735,71.8689804 63.1591034,72.9578552 63.1591034,73.441803"
        />
        <polygon
          fill={colors[3]}
          points="63.0381203,74.04673 62.9171295,74.04673 62.9171295,74.1677094 62.9171295,74.8936234 63.0381203,74.8936234"
        />
        <polygon
          fill={colors[4]}
          points="63.0381203,74.04673 63.0381203,74.8936234 63.0381203,74.8936234 63.0381203,74.1677094"
        />
        <polygon
          fill="#CCC"
          points="63.0381203,74.8936234 63.7640343,74.8936234 63.0381203,74.1677094"
        />
        <polygon
          fill={colors[4]}
          points="62.9171295,74.1677094 62.7961426,74.8936234 62.9171295,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.2158585,73.8047638 65.2158585,73.9257431 64.2479782,74.8936234 64.2479782,74.8936234 64.2479782,74.8936234"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.5627823 64.4899445,72.715889 64.3689651,72.8368683 65.2158585,73.5627823 65.2158585,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.5627823 64.4899445,72.715889 64.3689651,72.8368683 65.2158585,73.5627823 65.2158585,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.5627823 65.2158585,73.6837692 65.3368454,73.6837692 65.3368454,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.5627823 65.2158585,73.6837692 65.3368454,73.6837692 65.3368454,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.5627823 65.2158585,73.6837692 65.3368454,73.6837692 65.3368454,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.9257431 65.2158585,73.8047638 64.2479782,74.8936234 64.2479782,74.8936234 64.2479782,74.772644 64.2479782,74.8936234 64.3689651,74.8936234 64.3689651,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.9257431 65.2158585,73.8047638 64.2479782,74.8936234 64.2479782,74.8936234 64.2479782,74.772644 64.2479782,74.8936234 64.3689651,74.8936234 64.3689651,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,73.8047638 65.4578323,73.9257431 65.5788193,73.9257431 65.5788193,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,73.8047638 65.4578323,73.9257431 65.5788193,73.9257431 65.5788193,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,73.8047638 65.4578323,73.9257431 65.5788193,73.9257431 65.5788193,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.2158585,73.8047638 65.2158585,73.8047638 65.2158585,73.8047638 65.3368454,73.9257431 65.3368454,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.2158585,73.8047638 65.2158585,73.8047638 65.2158585,73.8047638 65.3368454,73.9257431 65.3368454,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.2158585,73.8047638 65.2158585,73.8047638 65.2158585,73.8047638 65.3368454,73.9257431 65.3368454,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.6837692 65.3368454,73.6837692 65.2158585,73.8047638 65.3368454,73.9257431 65.3368454,73.8047638 65.4578323,73.9257431 65.5788193,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.6837692 65.3368454,73.6837692 65.2158585,73.8047638 65.3368454,73.9257431 65.3368454,73.8047638 65.4578323,73.9257431 65.5788193,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.6837692 65.3368454,73.6837692 65.2158585,73.8047638 65.3368454,73.9257431 65.3368454,73.8047638 65.4578323,73.9257431 65.5788193,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.6837692 65.3368454,73.6837692 65.2158585,73.8047638 65.3368454,73.9257431 65.3368454,73.8047638 65.4578323,73.9257431 65.5788193,73.8047638"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,74.8936234 64.2479782,74.8936234 64.2479782,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,74.8936234 64.2479782,74.8936234 64.2479782,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,74.8936234 64.2479782,74.8936234 64.2479782,74.772644"
        />
        <polygon
          fill={colors[3]}
          points="65.3368454,73.8047638 65.3368454,73.9257431 65.3368454,73.9257431 64.3689651,74.772644 65.3368454,74.04673 65.3368454,74.1677094 65.3368454,74.04673"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.8047638 65.3368454,73.9257431 65.3368454,73.9257431 64.3689651,74.772644 65.3368454,74.04673 65.3368454,74.1677094 65.3368454,74.04673"
        />
        <polygon
          fill={colors[4]}
          points="65.3368454,73.8047638 65.3368454,74.04673 65.5788193,73.9257431 65.4578323,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,73.8047638 65.3368454,74.04673 65.5788193,73.9257431 65.4578323,73.9257431"
        />
        <polygon
          fill={colors[4]}
          points="65.3368454,74.04673 64.3689651,74.772644 64.3689651,74.8936234 64.6109314,74.8936234 65.3368454,74.1677094"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.3368454,74.04673 64.3689651,74.772644 64.3689651,74.8936234 64.6109314,74.8936234 65.3368454,74.1677094"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,72.715889 63.1591034,73.6837692 63.1591034,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,72.715889 63.1591034,73.6837692 63.1591034,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,72.715889 64.3689651,72.715889 64.3689651,72.8368683 64.4899445,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,72.715889 64.3689651,72.715889 64.3689651,72.8368683 64.4899445,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,72.715889 64.3689651,72.715889 64.3689651,72.8368683 64.4899445,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,72.4739075 64.1269836,72.4739075 64.2479782,72.594902 64.1269836,72.594902 64.3689651,72.715889 64.3689651,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,72.4739075 64.1269836,72.4739075 64.2479782,72.594902 64.1269836,72.594902 64.3689651,72.715889 64.3689651,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,72.4739075 64.1269836,72.4739075 64.2479782,72.594902 64.1269836,72.594902 64.3689651,72.715889 64.3689651,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,72.4739075 64.1269836,72.4739075 64.2479782,72.594902 64.1269836,72.594902 64.3689651,72.715889 64.3689651,72.715889"
        />
        <polygon
          fill={colors[3]}
          points="63.0381203,73.6837692 63.0381203,73.5627823 63.0381203,73.5627823"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.0381203,73.6837692 63.0381203,73.5627823 63.0381203,73.5627823"
        />
        <polygon
          fill={colors[4]}
          points="63.4010735,73.1998291 63.0381203,73.5627823 63.0381203,73.6837692 63.1591034,73.6837692 64.1269836,72.715889"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.4010735,73.1998291 63.0381203,73.5627823 63.0381203,73.6837692 63.1591034,73.6837692 64.1269836,72.715889"
        />
        <polygon
          fill="#CCC"
          points="64.1269836,72.4739075 63.4010735,73.1998291 64.1269836,72.715889 64.1269836,72.715889 64.1269836,72.715889 64.1269836,72.594902 64.1269836,72.594902 64.1269836,72.594902 64.2479782,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.1269836,72.4739075 63.4010735,73.1998291 64.1269836,72.715889 64.1269836,72.715889 64.1269836,72.715889 64.1269836,72.594902 64.1269836,72.594902 64.1269836,72.594902 64.2479782,72.594902"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.1591034,73.9257431 64.0060043,74.772644 64.1269836,74.8936234 64.1269836,74.8936234 63.2800903,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.1591034,73.9257431 64.0060043,74.772644 64.1269836,74.8936234 64.1269836,74.8936234 63.2800903,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.1591034,73.9257431 63.2800903,73.9257431 63.2800903,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.1591034,73.9257431 63.2800903,73.9257431 63.2800903,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.1591034,73.9257431 63.2800903,73.9257431 63.2800903,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.0381203,73.8047638 63.1591034,73.9257431 63.2800903,73.9257431 63.1591034,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.0381203,73.8047638 63.1591034,73.9257431 63.2800903,73.9257431 63.1591034,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.0381203,73.8047638 63.1591034,73.9257431 63.2800903,73.9257431 63.1591034,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.0381203,73.8047638 63.1591034,73.9257431 63.2800903,73.9257431 63.1591034,73.9257431"
        />
        <polygon
          fill="#CCC"
          points="63.0381203,73.8047638 63.0381203,73.8047638 63.0381203,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.0381203,73.8047638 63.0381203,73.8047638 63.0381203,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.0381203,73.8047638 63.0381203,73.8047638 63.0381203,73.6837692"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.0381203,73.8047638 63.0381203,73.8047638 63.0381203,73.6837692"
        />
        <polygon
          fill={colors[3]}
          points="63.0381203,73.8047638 63.0381203,73.8047638 62.9171295,74.04673 63.0381203,74.04673"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.0381203,73.8047638 63.0381203,73.8047638 62.9171295,74.04673 63.0381203,74.04673"
        />
        <polygon
          fill={colors[4]}
          points="63.0381203,73.8047638 63.0381203,73.8047638 63.0381203,74.04673 63.0381203,74.1677094 63.0381203,74.04673 64.0060043,74.772644 63.1591034,73.9257431 63.1591034,73.9257431"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.0381203,73.8047638 63.0381203,73.8047638 63.0381203,74.04673 63.0381203,74.1677094 63.0381203,74.04673 64.0060043,74.772644 63.1591034,73.9257431 63.1591034,73.9257431"
        />
        <polygon
          fill="#CCC"
          points="63.0381203,74.04673 63.0381203,74.1677094 63.7640343,74.8936234 64.1269836,74.8936234 64.0060043,74.772644"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="63.0381203,74.04673 63.0381203,74.1677094 63.7640343,74.8936234 64.1269836,74.8936234 64.0060043,74.772644"
        />
        <polygon
          fill={colors[3]}
          points="64.1269836,72.594902 64.1269836,72.594902 64.1269836,72.715889 64.1269836,72.715889 63.1591034,73.5627823 64.1269836,72.8368683 64.1269836,74.6516571 63.1591034,73.9257431 63.2800903,73.9257431 63.2800903,73.9257431 64.1269836,74.8936234 64.1269836,74.8936234"
        />
        <polygon
          fill={colors[4]}
          points="65.2158585,73.9257431 64.2479782,74.6516571 64.2479782,72.8368683 65.2158585,73.5627823 64.3689651,72.8368683 64.3689651,72.715889 64.1269836,72.594902 64.1269836,72.594902 64.1269836,74.8936234 64.2479782,74.8936234 64.2479782,74.8936234"
        />
        <polygon
          fill="#CCC"
          points="65.2158585,73.8047638 65.3368454,73.6837692 65.3368454,73.6837692 65.2158585,73.6837692 65.2158585,73.5627823 64.2479782,72.8368683 64.2479782,74.6516571 65.2158585,73.9257431"
        />
        <polygon
          fill={colors[4]}
          points="63.0381203,73.8047638 63.1591034,73.9257431 64.1269836,74.6516571 64.1269836,72.8368683 63.1591034,73.5627823 63.1591034,73.6837692 63.0381203,73.6837692 63.0381203,73.6837692 63.0381203,73.6837692 63.0381203,73.6837692 63.0381203,73.6837692 63.0381203,73.6837692"
        />
        <polygon
          fill={colors[4]}
          points="64.8529053,63.0370331 64.8529053,63.1580162 64.9738922,63.0370331"
        />
        <polygon
          fill="#CCC"
          points="65.4578323,62.432106 65.4578323,62.432106 65.4578323,62.432106 65.0948715,61.9481621 64.8529053,63.0370331 64.9738922,63.0370331"
        />
        <polygon
          fill={colors[3]}
          points="64.3689651,66.3036423 64.3689651,66.061676 64.3689651,65.9406891 64.2479782,66.182663 64.2479782,66.4246292"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,66.3036423 64.3689651,66.061676 64.3689651,65.9406891 64.2479782,66.182663 64.2479782,66.4246292"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,66.061676 64.3689651,66.061676 64.3689651,66.3036423 64.4899445,66.3036423"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,66.061676 64.3689651,66.061676 64.3689651,66.3036423 64.4899445,66.3036423"
        />
        <polygon
          fill="#CCC"
          points="64.4899445,66.182663 64.4899445,66.182663 64.4899445,66.061676 64.4899445,66.3036423"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,66.182663 64.4899445,66.182663 64.4899445,66.061676 64.4899445,66.3036423"
        />
        <polygon
          fill={colors[4]}
          points="64.2479782,66.182663 64.2479782,66.4246292 64.2479782,66.4246292"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.2479782,66.182663 64.2479782,66.4246292 64.2479782,66.4246292"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.7319107,65.9406891 64.7319107,65.9406891 64.7319107,65.9406891 64.7319107,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.7319107,65.9406891 64.7319107,65.9406891 64.7319107,65.9406891 64.7319107,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.7319107,65.9406891 64.7319107,65.9406891 64.7319107,65.9406891 64.7319107,66.061676"
        />
        <polygon
          fill={colors[4]}
          points="64.6109314,66.061676 64.7319107,66.061676 64.7319107,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,66.061676 64.7319107,66.061676 64.7319107,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,66.061676 64.7319107,66.061676 64.7319107,65.9406891"
        />
        <polygon
          fill="#CCC"
          points="64.4899445,66.182663 64.6109314,66.061676 64.6109314,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,66.182663 64.6109314,66.061676 64.6109314,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,66.182663 64.6109314,66.061676 64.6109314,66.061676"
        />
        <polygon
          fill={colors[3]}
          points="65.5788193,65.2147751 65.5788193,64.8518219 65.4578323,64.8518219 65.4578323,64.8518219 65.4578323,65.2147751"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,65.2147751 65.5788193,64.8518219 65.4578323,64.8518219 65.4578323,64.8518219 65.4578323,65.2147751"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,64.9728012 65.5788193,64.8518219 65.5788193,65.2147751 65.5788193,65.0937881"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.9728012 65.5788193,64.8518219 65.5788193,65.2147751 65.5788193,65.0937881"
        />
        <polygon
          fill="#CCC"
          points="65.6998062,64.9728012 65.6998062,64.9728012 65.5788193,64.9728012 65.5788193,65.0937881"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,64.9728012 65.6998062,64.9728012 65.5788193,64.9728012 65.5788193,65.0937881"
        />
        <polygon
          fill={colors[4]}
          points="65.4578323,65.2147751 65.4578323,64.8518219 64.6109314,65.8197021 64.7319107,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,65.2147751 65.4578323,64.8518219 64.6109314,65.8197021 64.7319107,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,64.8518219 65.8207855,64.8518219 65.8207855,64.8518219 65.8207855,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,64.8518219 65.8207855,64.8518219 65.8207855,64.8518219 65.8207855,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,64.8518219 65.8207855,64.8518219 65.8207855,64.8518219 65.8207855,64.8518219"
        />
        <polygon
          fill={colors[4]}
          points="65.8207855,64.8518219 65.8207855,64.8518219 65.8207855,64.8518219 65.8207855,64.9728012"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,64.8518219 65.8207855,64.8518219 65.8207855,64.8518219 65.8207855,64.9728012"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,64.8518219 65.8207855,64.8518219 65.8207855,64.8518219 65.8207855,64.9728012"
        />
        <polygon
          fill="#CCC"
          points="65.6998062,64.9728012 65.8207855,64.9728012 65.8207855,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,64.9728012 65.8207855,64.9728012 65.8207855,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,64.9728012 65.8207855,64.9728012 65.8207855,64.8518219"
        />
        <polygon
          fill={colors[3]}
          points="65.9417725,64.8518219 65.9417725,64.8518219 65.8207855,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,64.8518219 65.9417725,64.8518219 65.8207855,64.8518219"
        />
        <polygon
          fill={colors[3]}
          points="64.3689651,66.061676 64.3689651,65.9406891 64.3689651,65.9406891 64.3689651,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,66.061676 64.3689651,65.9406891 64.3689651,65.9406891 64.3689651,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,66.061676 64.3689651,65.9406891 64.3689651,65.9406891 64.3689651,65.9406891"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,66.061676 64.3689651,66.061676 64.3689651,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,66.061676 64.3689651,66.061676 64.3689651,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,66.061676 64.3689651,66.061676 64.3689651,66.061676"
        />
        <polygon
          fill="#CCC"
          points="64.4899445,66.182663 64.4899445,66.061676 64.4899445,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,66.182663 64.4899445,66.061676 64.4899445,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,66.182663 64.4899445,66.061676 64.4899445,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,65.8197021 64.4899445,65.8197021 64.7319107,65.9406891 64.7319107,65.9406891 64.4899445,65.8197021 64.4899445,65.8197021 64.4899445,65.8197021 64.3689651,65.6987152 64.3689651,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,65.8197021 64.4899445,65.8197021 64.7319107,65.9406891 64.7319107,65.9406891 64.4899445,65.8197021 64.4899445,65.8197021 64.4899445,65.8197021 64.3689651,65.6987152 64.3689651,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,65.8197021 64.4899445,65.8197021 64.7319107,65.9406891 64.7319107,65.9406891 64.4899445,65.8197021 64.4899445,65.8197021 64.4899445,65.8197021 64.3689651,65.6987152 64.3689651,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,65.8197021 64.4899445,65.8197021 64.7319107,65.9406891 64.7319107,65.9406891 64.4899445,65.8197021 64.4899445,65.8197021 64.4899445,65.8197021 64.3689651,65.6987152 64.3689651,65.6987152"
        />
        <polygon
          fill={colors[3]}
          points="64.3689651,65.9406891 64.3689651,65.9406891 64.3689651,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,65.9406891 64.3689651,65.9406891 64.3689651,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,65.9406891 64.3689651,65.9406891 64.3689651,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,65.9406891 64.3689651,65.9406891 64.3689651,66.061676"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,65.9406891 64.6109314,66.061676 64.7319107,65.9406891 64.4899445,65.8197021 64.3689651,65.9406891 64.3689651,66.061676 64.4899445,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,65.9406891 64.6109314,66.061676 64.7319107,65.9406891 64.4899445,65.8197021 64.3689651,65.9406891 64.3689651,66.061676 64.4899445,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,65.9406891 64.6109314,66.061676 64.7319107,65.9406891 64.4899445,65.8197021 64.3689651,65.9406891 64.3689651,66.061676 64.4899445,66.061676"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,65.9406891 64.6109314,66.061676 64.7319107,65.9406891 64.4899445,65.8197021 64.3689651,65.9406891 64.3689651,66.061676 64.4899445,66.061676"
        />
        <polygon
          fill={colors[4]}
          points="64.3689651,65.6987152 64.3689651,65.6987152 64.4899445,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,65.6987152 64.3689651,65.6987152 64.4899445,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,65.6987152 64.3689651,65.6987152 64.4899445,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,65.6987152 64.3689651,65.6987152 64.4899445,65.8197021"
        />
        <polygon
          fill="#CCC"
          points="64.6109314,66.061676 64.4899445,65.9406891 64.4899445,66.061676 64.4899445,66.182663"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,66.061676 64.4899445,65.9406891 64.4899445,66.061676 64.4899445,66.182663"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,66.061676 64.4899445,65.9406891 64.4899445,66.061676 64.4899445,66.182663"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.6109314,66.061676 64.4899445,65.9406891 64.4899445,66.061676 64.4899445,66.182663"
        />
        <polygon
          fill={colors[3]}
          points="64.4899445,65.8197021 64.7319107,65.9406891 64.7319107,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,65.8197021 64.7319107,65.9406891 64.7319107,65.9406891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.4899445,65.8197021 64.7319107,65.9406891 64.7319107,65.9406891"
        />
        <polygon
          fill={colors[4]}
          points="64.7319107,65.9406891 64.6109314,65.8197021 64.4899445,65.8197021 64.4899445,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.7319107,65.9406891 64.6109314,65.8197021 64.4899445,65.8197021 64.4899445,65.8197021"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.7319107,65.9406891 64.6109314,65.8197021 64.4899445,65.8197021 64.4899445,65.8197021"
        />
        <polygon
          fill="#CCC"
          points="64.3689651,65.9406891 64.4899445,65.8197021 64.4899445,65.8197021 64.4899445,65.8197021 64.3689651,65.6987152"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.3689651,65.9406891 64.4899445,65.8197021 64.4899445,65.8197021 64.4899445,65.8197021 64.3689651,65.6987152"
        />
        <polygon
          fill={colors[3]}
          points="64.4899445,64.9728012 64.4899445,65.335762 64.4899445,65.5777283 64.3689651,65.5777283 64.3689651,65.6987152 64.4899445,65.8197021"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,65.8197021 64.4899445,65.8197021 64.6109314,65.8197021 65.4578323,64.8518219 65.4578323,64.8518219 65.4578323,64.8518219 64.6109314,65.5777283 64.6109314,64.609848 64.4899445,64.9728012"
        />
        <polygon
          fill="#CCC"
          points="64.6109314,65.5777283 65.4578323,64.8518219 64.7319107,64.004921 64.6109314,64.609848"
        />
        <polygon
          fill={colors[4]}
          points="64.4899445,65.335762 64.3689651,65.5777283 64.4899445,65.5777283"
        />
        <polygon
          fill={colors[3]}
          points="66.6676941,64.004921 66.6676941,63.641964 66.6676941,63.641964 66.6676941,63.7629471 66.6676941,64.1259003"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,64.004921 66.6676941,63.641964 66.6676941,63.641964 66.6676941,63.7629471 66.6676941,64.1259003"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,63.641964 66.6676941,63.641964 66.6676941,63.641964 66.6676941,64.004921 66.7886734,64.004921"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,63.641964 66.6676941,63.641964 66.6676941,63.641964 66.6676941,64.004921 66.7886734,64.004921"
        />
        <polygon
          fill="#CCC"
          points="66.7886734,63.641964 66.7886734,64.004921 66.9096603,63.7629471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,63.641964 66.7886734,64.004921 66.9096603,63.7629471"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,64.1259003 66.6676941,63.7629471 65.6998062,64.609848 65.9417725,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,64.1259003 66.6676941,63.7629471 65.6998062,64.609848 65.9417725,64.8518219"
        />
        <polygon
          fill={colors[4]}
          points="67.0306396,63.641964 67.0306396,63.641964 66.9096603,63.7629471 66.9096603,63.7629471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,63.641964 67.0306396,63.641964 66.9096603,63.7629471 66.9096603,63.7629471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,63.641964 67.0306396,63.641964 66.9096603,63.7629471 66.9096603,63.7629471"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,63.641964 66.6676941,63.641964 66.6676941,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,63.641964 66.6676941,63.641964 66.6676941,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,63.641964 66.6676941,63.641964 66.6676941,63.641964"
        />
        <polygon
          fill="#CCC"
          points="66.9096603,63.7629471 66.9096603,63.7629471 66.9096603,63.7629471 66.7886734,63.641964 66.7886734,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,63.7629471 66.9096603,63.7629471 66.9096603,63.7629471 66.7886734,63.641964 66.7886734,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,63.7629471 66.9096603,63.7629471 66.9096603,63.7629471 66.7886734,63.641964 66.7886734,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,62.5530891 64.8529053,63.2790031 64.8529053,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,62.5530891 64.8529053,63.2790031 64.8529053,63.2790031"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,62.5530891 64.9738922,63.0370331 64.8529053,63.1580162 64.8529053,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,62.5530891 64.9738922,63.0370331 64.8529053,63.1580162 64.8529053,63.2790031"
        />
        <polygon
          fill="#CCC"
          points="64.9738922,63.0370331 65.5788193,62.5530891 65.4578323,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.9738922,63.0370331 65.5788193,62.5530891 65.4578323,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,64.4888611 64.7319107,63.7629471 64.7319107,63.7629471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,64.4888611 64.7319107,63.7629471 64.7319107,63.7629471"
        />
        <polygon
          fill={colors[3]}
          points="65.5788193,64.8518219 65.4578323,64.8518219 65.4578323,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.8518219 65.4578323,64.8518219 65.4578323,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.8518219 65.4578323,64.8518219 65.4578323,64.8518219"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,64.9728012 65.5788193,64.8518219 65.5788193,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.9728012 65.5788193,64.8518219 65.5788193,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.9728012 65.5788193,64.8518219 65.5788193,64.8518219"
        />
        <polygon
          fill="#CCC"
          points="65.6998062,64.9728012 65.6998062,64.9728012 65.5788193,64.9728012 65.5788193,64.9728012"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,64.9728012 65.6998062,64.9728012 65.5788193,64.9728012 65.5788193,64.9728012"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,64.9728012 65.6998062,64.9728012 65.5788193,64.9728012 65.5788193,64.9728012"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,64.8518219 65.8207855,64.8518219 65.6998062,64.609848 65.6998062,64.609848 65.5788193,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611 65.4578323,64.4888611 65.4578323,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,64.8518219 65.8207855,64.8518219 65.6998062,64.609848 65.6998062,64.609848 65.5788193,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611 65.4578323,64.4888611 65.4578323,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,64.8518219 65.8207855,64.8518219 65.6998062,64.609848 65.6998062,64.609848 65.5788193,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611 65.4578323,64.4888611 65.4578323,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,64.8518219 65.8207855,64.8518219 65.6998062,64.609848 65.6998062,64.609848 65.5788193,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611 65.4578323,64.4888611 65.4578323,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611"
        />
        <polygon
          fill={colors[3]}
          points="65.5788193,64.8518219 65.5788193,64.8518219 65.5788193,64.8518219 65.4578323,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.8518219 65.5788193,64.8518219 65.5788193,64.8518219 65.4578323,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.8518219 65.5788193,64.8518219 65.5788193,64.8518219 65.4578323,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.8518219 65.5788193,64.8518219 65.5788193,64.8518219 65.4578323,64.8518219"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,64.9728012 65.5788193,64.7308273 65.8207855,64.8518219 65.8207855,64.8518219 65.5788193,64.4888611 65.5788193,64.609848 65.6998062,64.7308273 65.5788193,64.8518219 65.5788193,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.9728012 65.5788193,64.7308273 65.8207855,64.8518219 65.8207855,64.8518219 65.5788193,64.4888611 65.5788193,64.609848 65.6998062,64.7308273 65.5788193,64.8518219 65.5788193,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.9728012 65.5788193,64.7308273 65.8207855,64.8518219 65.8207855,64.8518219 65.5788193,64.4888611 65.5788193,64.609848 65.6998062,64.7308273 65.5788193,64.8518219 65.5788193,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.9728012 65.5788193,64.7308273 65.8207855,64.8518219 65.8207855,64.8518219 65.5788193,64.4888611 65.5788193,64.609848 65.6998062,64.7308273 65.5788193,64.8518219 65.5788193,64.8518219"
        />
        <polygon
          fill="#CCC"
          points="65.8207855,64.8518219 65.5788193,64.7308273 65.5788193,64.9728012 65.6998062,64.9728012"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,64.8518219 65.5788193,64.7308273 65.5788193,64.9728012 65.6998062,64.9728012"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,64.8518219 65.5788193,64.7308273 65.5788193,64.9728012 65.6998062,64.9728012"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,64.8518219 65.5788193,64.7308273 65.5788193,64.9728012 65.6998062,64.9728012"
        />
        <polygon
          fill={colors[3]}
          points="65.6998062,64.609848 65.6998062,64.609848 65.8207855,64.8518219 65.9417725,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,64.609848 65.6998062,64.609848 65.8207855,64.8518219 65.9417725,64.8518219"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,64.609848 65.6998062,64.609848 65.8207855,64.8518219 65.9417725,64.8518219"
        />
        <polygon
          fill={colors[4]}
          points="65.9417725,64.8518219 65.6998062,64.609848 65.6998062,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,64.8518219 65.6998062,64.609848 65.6998062,64.609848"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,64.8518219 65.6998062,64.609848 65.6998062,64.609848"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,64.4888611 65.4578323,64.4888611 65.4578323,64.4888611 64.7319107,63.7629471 64.7319107,63.8839302"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,64.4888611 65.4578323,64.4888611 65.4578323,64.4888611 64.7319107,63.7629471 64.7319107,63.8839302"
        />
        <polygon
          fill="#CCC"
          points="65.4578323,64.8518219 65.5788193,64.8518219 65.5788193,64.8518219 65.6998062,64.7308273 65.5788193,64.609848 65.5788193,64.4888611 65.5788193,64.4888611 64.7319107,63.8839302 64.7319107,64.004921"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,64.8518219 65.5788193,64.8518219 65.5788193,64.8518219 65.6998062,64.7308273 65.5788193,64.609848 65.5788193,64.4888611 65.5788193,64.4888611 64.7319107,63.8839302 64.7319107,64.004921"
        />
        <polygon
          fill={colors[3]}
          points="65.4578323,64.4888611 65.5788193,64.4888611 65.5788193,64.4888611 65.6998062,64.609848 65.6998062,62.6740723 65.5788193,62.5530891 64.8529053,63.2790031 64.8529053,63.2790031 65.5788193,62.6740723 65.5788193,64.3678741 64.7319107,63.641964 64.7319107,63.7629471"
        />
        <polygon
          fill={colors[4]}
          points="65.6998062,64.609848 65.6998062,64.609848 66.6676941,63.7629471 66.6676941,63.641964 65.6998062,64.3678741 65.6998062,62.6740723 65.6998062,62.6740723"
        />
        <polygon
          fill="#CCC"
          points="66.6676941,63.641964 66.6676941,63.641964 65.6998062,62.6740723 65.6998062,64.3678741"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,64.3678741 65.5788193,62.6740723 64.8529053,63.2790031 64.7319107,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,62.432106 65.9417725,62.432106 66.5466995,63.1580162 66.5466995,63.1580162"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,63.1580162 66.5466995,63.1580162 66.6676941,63.2790031 66.6676941,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5466995,63.1580162 66.5466995,63.1580162 66.6676941,63.2790031 66.6676941,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,63.3999901 66.9096603,63.3999901 66.7886734,63.3999901 66.6676941,63.2790031 66.6676941,63.2790031 67.0306396,63.641964 67.0306396,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,63.3999901 66.9096603,63.3999901 66.7886734,63.3999901 66.6676941,63.2790031 66.6676941,63.2790031 67.0306396,63.641964 67.0306396,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,63.3999901 66.9096603,63.3999901 66.7886734,63.3999901 66.6676941,63.2790031 66.6676941,63.2790031 67.0306396,63.641964 67.0306396,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,62.432106 68.2405014,62.432106 68.2405014,62.432106 68.2405014,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,62.432106 68.2405014,62.432106 68.2405014,62.432106 68.2405014,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,62.432106 68.2405014,62.432106 68.2405014,62.432106 68.2405014,62.5530891"
        />
        <polygon
          fill={colors[3]}
          points="67.0306396,63.641964 66.9096603,63.3999901 66.9096603,63.3999901"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,63.641964 66.9096603,63.3999901 66.9096603,63.3999901"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,62.9160461 67.7565536,62.5530891 67.7565536,62.6740723 67.7565536,63.0370331"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,62.9160461 67.7565536,62.5530891 67.7565536,62.6740723 67.7565536,63.0370331"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,62.795063 67.8775482,62.432106 67.7565536,62.5530891 67.7565536,62.9160461"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,62.795063 67.8775482,62.432106 67.7565536,62.5530891 67.7565536,62.9160461"
        />
        <polygon
          fill={colors[4]}
          points="68.1195145,62.5530891 68.2405014,62.5530891 68.2405014,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,62.5530891 68.2405014,62.5530891 68.2405014,62.432106"
        />
        <polygon
          fill="#CCC"
          points="67.8775482,62.795063 68.1195145,62.5530891 68.1195145,62.5530891 67.9985352,62.6740723 67.8775482,62.432106 67.8775482,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,62.795063 68.1195145,62.5530891 68.1195145,62.5530891 67.9985352,62.6740723 67.8775482,62.432106 67.8775482,62.432106"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,63.3999901 66.6676941,63.1580162 66.6676941,63.1580162 66.6676941,63.2790031 66.6676941,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,63.3999901 66.6676941,63.1580162 66.6676941,63.1580162 66.6676941,63.2790031 66.6676941,63.2790031"
        />
        <polygon
          fill={colors[4]}
          points="67.7565536,63.0370331 67.7565536,62.6740723 66.9096603,63.3999901 67.0306396,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,63.0370331 67.7565536,62.6740723 66.9096603,63.3999901 67.0306396,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,62.5530891 65.9417725,62.432106 65.9417725,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,62.5530891 65.9417725,62.432106 65.9417725,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,63.2790031 66.6676941,63.1580162 66.6676941,63.1580162 66.5466995,63.1580162 66.5466995,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,63.2790031 66.6676941,63.1580162 66.6676941,63.1580162 66.5466995,63.1580162 66.5466995,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,63.2790031 66.6676941,63.1580162 66.6676941,63.1580162 66.5466995,63.1580162 66.5466995,63.2790031"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,63.641964 66.6676941,63.2790031 66.6676941,63.2790031 66.6676941,63.3999901 66.6676941,63.3999901 66.6676941,63.3999901 66.7886734,63.5209732 66.7886734,63.5209732 66.7886734,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,63.641964 66.6676941,63.2790031 66.6676941,63.2790031 66.6676941,63.3999901 66.6676941,63.3999901 66.6676941,63.3999901 66.7886734,63.5209732 66.7886734,63.5209732 66.7886734,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,63.641964 66.6676941,63.2790031 66.6676941,63.2790031 66.6676941,63.3999901 66.6676941,63.3999901 66.6676941,63.3999901 66.7886734,63.5209732 66.7886734,63.5209732 66.7886734,63.5209732"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,63.641964 66.6676941,63.2790031 66.6676941,63.2790031 66.6676941,63.3999901 66.6676941,63.3999901 66.6676941,63.3999901 66.7886734,63.5209732 66.7886734,63.5209732 66.7886734,63.5209732"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,63.641964 66.9096603,63.7629471 67.0306396,63.641964 66.7886734,63.5209732 66.6676941,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,63.641964 66.9096603,63.7629471 67.0306396,63.641964 66.7886734,63.5209732 66.6676941,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,63.641964 66.9096603,63.7629471 67.0306396,63.641964 66.7886734,63.5209732 66.6676941,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,63.641964 66.9096603,63.7629471 67.0306396,63.641964 66.7886734,63.5209732 66.6676941,63.641964"
        />
        <polygon
          fill="#CCC"
          points="66.9096603,63.7629471 66.7886734,63.641964 66.7886734,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,63.7629471 66.7886734,63.641964 66.7886734,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,63.7629471 66.7886734,63.641964 66.7886734,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,63.7629471 66.7886734,63.641964 66.7886734,63.641964"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,62.5530891 65.9417725,62.432106 65.8207855,62.5530891 65.8207855,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,62.5530891 65.9417725,62.432106 65.8207855,62.5530891 65.8207855,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,62.5530891 65.9417725,62.432106 65.8207855,62.5530891 65.8207855,62.5530891"
        />
        <polygon
          fill="#CCC"
          points="65.4578323,62.432106 65.4578323,62.432106 65.5788193,62.5530891 65.5788193,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,62.432106 65.4578323,62.432106 65.5788193,62.5530891 65.5788193,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.4578323,62.432106 65.4578323,62.432106 65.5788193,62.5530891 65.5788193,62.432106"
        />
        <polygon
          fill={colors[3]}
          points="65.5788193,62.432106 65.5788193,62.5530891 65.6998062,62.6740723 65.6998062,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,62.432106 65.5788193,62.5530891 65.6998062,62.6740723 65.6998062,62.5530891"
        />
        <polygon
          fill={colors[4]}
          points="65.6998062,62.6740723 65.6998062,62.6740723 65.6998062,62.6740723 65.6998062,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.6740723 65.6998062,62.6740723 65.6998062,62.6740723 65.6998062,62.5530891"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,63.3999901 66.6676941,63.2790031 66.5466995,63.2790031 65.8207855,62.5530891 65.8207855,62.5530891 65.6998062,62.6740723"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,63.3999901 66.6676941,63.2790031 66.5466995,63.2790031 65.8207855,62.5530891 65.8207855,62.5530891 65.6998062,62.6740723"
        />
        <polygon
          fill="#CCC"
          points="66.6676941,63.641964 66.7886734,63.5209732 66.7886734,63.5209732 66.7886734,63.5209732 66.6676941,63.3999901 66.6676941,63.3999901 66.6676941,63.3999901 65.6998062,62.6740723 65.6998062,62.6740723 65.6998062,62.6740723"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,63.641964 66.7886734,63.5209732 66.7886734,63.5209732 66.7886734,63.5209732 66.6676941,63.3999901 66.6676941,63.3999901 66.6676941,63.3999901 65.6998062,62.6740723 65.6998062,62.6740723 65.6998062,62.6740723"
        />
        <polygon
          fill={colors[3]}
          points="66.6676941,63.1580162 66.7886734,63.3999901 66.9096603,63.3999901 66.9096603,61.4642181 66.7886734,61.5852051 66.7886734,63.1580162 65.9417725,62.432106 65.9417725,62.432106 65.9417725,62.5530891 66.5466995,63.1580162"
        />
        <polygon
          fill={colors[4]}
          points="66.9096603,63.3999901 67.7565536,62.6740723 67.7565536,62.5530891 67.8775482,62.432106 67.8775482,62.432106 67.8775482,62.432106 66.9096603,63.1580162 66.9096603,61.5852051 66.9096603,61.4642181 66.9096603,61.4642181"
        />
        <polygon
          fill="#CCC"
          points="66.9096603,61.5852051 66.9096603,63.1580162 67.8775482,62.432106"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,63.1580162 66.7886734,61.5852051 65.9417725,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,61.3432312 69.3293762,61.3432312 69.3293762,61.3432312 69.3293762,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,61.3432312 69.3293762,61.3432312 69.3293762,61.3432312 69.3293762,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,61.3432312 69.3293762,61.3432312 69.3293762,61.3432312 69.3293762,61.3432312"
        />
        <polygon
          fill={colors[3]}
          points="69.4503555,61.3432312 69.4503555,61.2222481 69.3293762,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,61.3432312 69.4503555,61.2222481 69.3293762,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,61.3432312 69.4503555,61.2222481 69.3293762,61.3432312"
        />
        <polygon
          fill={colors[3]}
          points="68.9664154,61.7061882 68.9664154,61.3432312 68.9664154,61.4642181 68.9664154,61.827179"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,61.7061882 68.9664154,61.3432312 68.9664154,61.4642181 68.9664154,61.827179"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,61.7061882 69.0874023,61.3432312 68.9664154,61.3432312 68.9664154,61.3432312 68.9664154,61.7061882"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,61.7061882 69.0874023,61.3432312 68.9664154,61.3432312 68.9664154,61.3432312 68.9664154,61.7061882"
        />
        <polygon
          fill={colors[4]}
          points="69.3293762,61.4642181 69.3293762,61.3432312 69.3293762,61.3432312 69.3293762,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.3293762,61.4642181 69.3293762,61.3432312 69.3293762,61.3432312 69.3293762,61.3432312"
        />
        <polygon
          fill="#CCC"
          points="69.0874023,61.3432312 69.0874023,61.7061882 69.3293762,61.4642181 69.3293762,61.3432312 69.2083893,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,61.3432312 69.0874023,61.7061882 69.3293762,61.4642181 69.3293762,61.3432312 69.2083893,61.4642181"
        />
        <polygon
          fill={colors[4]}
          points="68.9664154,61.827179 68.9664154,61.4642181 68.1195145,62.3111191 68.2405014,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,61.827179 68.9664154,61.4642181 68.1195145,62.3111191 68.2405014,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,61.3432312 67.0306396,61.3432312 67.7565536,62.0691452 67.7565536,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,61.3432312 67.0306396,61.3432312 67.7565536,62.0691452 67.7565536,62.0691452"
        />
        <polygon
          fill={colors[4]}
          points="68.2405014,62.432106 68.1195145,62.3111191 68.1195145,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,62.432106 68.1195145,62.3111191 68.1195145,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,62.432106 68.1195145,62.3111191 68.1195145,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,61.9481621 67.7565536,61.9481621 67.7565536,62.0691452 67.7565536,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,61.9481621 67.7565536,61.9481621 67.7565536,62.0691452 67.7565536,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,61.9481621 67.7565536,61.9481621 67.7565536,62.0691452 67.7565536,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,62.0691452 67.8775482,62.1901321 67.9985352,62.3111191 67.9985352,62.3111191 68.2405014,62.432106 68.2405014,62.432106 67.9985352,62.3111191 67.9985352,62.1901321 67.8775482,62.0691452 67.8775482,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,62.0691452 67.8775482,62.1901321 67.9985352,62.3111191 67.9985352,62.3111191 68.2405014,62.432106 68.2405014,62.432106 67.9985352,62.3111191 67.9985352,62.1901321 67.8775482,62.0691452 67.8775482,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,62.0691452 67.8775482,62.1901321 67.9985352,62.3111191 67.9985352,62.3111191 68.2405014,62.432106 68.2405014,62.432106 67.9985352,62.3111191 67.9985352,62.1901321 67.8775482,62.0691452 67.8775482,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7565536,62.0691452 67.8775482,62.1901321 67.9985352,62.3111191 67.9985352,62.3111191 68.2405014,62.432106 68.2405014,62.432106 67.9985352,62.3111191 67.9985352,62.1901321 67.8775482,62.0691452 67.8775482,62.0691452"
        />
        <polygon
          fill={colors[3]}
          points="68.2405014,62.432106 67.9985352,62.3111191 67.9985352,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,62.432106 67.9985352,62.3111191 67.9985352,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,62.432106 67.9985352,62.3111191 67.9985352,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,62.432106 67.9985352,62.3111191 67.9985352,62.3111191"
        />
        <polygon
          fill={colors[4]}
          points="67.9985352,62.1901321 67.8775482,62.0691452 67.8775482,62.0691452 67.8775482,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,62.1901321 67.8775482,62.0691452 67.8775482,62.0691452 67.8775482,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,62.1901321 67.8775482,62.0691452 67.8775482,62.0691452 67.8775482,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,62.1901321 67.8775482,62.0691452 67.8775482,62.0691452 67.8775482,62.0691452"
        />
        <polygon
          fill={colors[4]}
          points="68.1195145,62.3111191 67.9985352,62.3111191 68.2405014,62.432106 68.2405014,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,62.3111191 67.9985352,62.3111191 68.2405014,62.432106 68.2405014,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,62.3111191 67.9985352,62.3111191 68.2405014,62.432106 68.2405014,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,62.3111191 67.9985352,62.3111191 68.2405014,62.432106 68.2405014,62.432106"
        />
        <polygon
          fill={colors[4]}
          points="68.2405014,62.432106 67.9985352,62.3111191 67.9985352,62.432106 68.1195145,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,62.432106 67.9985352,62.3111191 67.9985352,62.432106 68.1195145,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,62.432106 67.9985352,62.3111191 67.9985352,62.432106 68.1195145,62.5530891"
        />
        <polygon
          fill="#CCC"
          points="68.1195145,62.5530891 67.9985352,62.432106 67.8775482,62.432106 67.8775482,62.432106 67.9985352,62.6740723"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,62.5530891 67.9985352,62.432106 67.8775482,62.432106 67.8775482,62.432106 67.9985352,62.6740723"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,62.5530891 67.9985352,62.432106 67.8775482,62.432106 67.8775482,62.432106 67.9985352,62.6740723"
        />
        <polygon
          fill={colors[4]}
          points="66.9096603,61.5852051 66.9096603,61.4642181 66.9096603,61.4642181 66.9096603,61.4642181 66.9096603,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.5852051 66.9096603,61.4642181 66.9096603,61.4642181 66.9096603,61.4642181 66.9096603,61.4642181"
        />
        <polygon
          fill={colors[4]}
          points="67.8775482,62.1901321 67.7565536,62.0691452 67.7565536,62.0691452 67.7565536,62.0691452 67.0306396,61.3432312 67.0306396,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,62.1901321 67.7565536,62.0691452 67.7565536,62.0691452 67.7565536,62.0691452 67.0306396,61.3432312 67.0306396,61.4642181"
        />
        <polygon
          fill="#CCC"
          points="66.9096603,61.5852051 67.8775482,62.432106 67.8775482,62.432106 67.9985352,62.432106 67.9985352,62.3111191 67.9985352,62.3111191 67.8775482,62.1901321 67.0306396,61.4642181 66.9096603,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.5852051 67.8775482,62.432106 67.8775482,62.432106 67.9985352,62.432106 67.9985352,62.3111191 67.9985352,62.3111191 67.8775482,62.1901321 67.0306396,61.4642181 66.9096603,61.4642181"
        />
        <polygon
          fill={colors[3]}
          points="67.7565536,61.9481621 67.7565536,62.0691452 67.8775482,62.0691452 67.8775482,62.0691452 67.9985352,62.1901321 67.9985352,62.3111191 67.9985352,60.3753471 67.9985352,60.4963303 67.9985352,62.0691452 67.0306396,61.3432312 67.0306396,61.3432312"
        />
        <polygon
          fill={colors[4]}
          points="68.1195145,62.3111191 68.1195145,62.3111191 68.9664154,61.4642181 68.9664154,61.3432312 68.9664154,61.3432312 68.9664154,61.3432312 68.1195145,62.0691452 68.1195145,60.3753471 68.1195145,60.3753471 67.9985352,60.3753471 67.9985352,62.3111191 67.9985352,62.3111191"
        />
        <polygon
          fill="#CCC"
          points="68.1195145,62.0691452 68.9664154,61.3432312 68.1195145,60.3753471"
        />
        <polygon
          fill={colors[4]}
          points="67.9985352,62.0691452 67.9985352,60.4963303 67.0306396,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,60.1333771 67.8775482,60.1333771 67.0306396,61.1012611 67.0306396,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,60.1333771 67.8775482,60.1333771 67.0306396,61.1012611 67.0306396,60.980278"
        />
        <polygon
          fill={colors[4]}
          points="67.0306396,61.4642181 67.0306396,61.3432312 67.0306396,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,61.4642181 67.0306396,61.3432312 67.0306396,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,61.4642181 67.0306396,61.3432312 67.0306396,61.3432312"
        />
        <polygon
          fill="#CCC"
          points="67.0306396,61.4642181 67.0306396,61.4642181 66.9096603,61.4642181 66.9096603,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,61.4642181 67.0306396,61.4642181 66.9096603,61.4642181 66.9096603,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,61.4642181 67.0306396,61.4642181 66.9096603,61.4642181 66.9096603,61.4642181"
        />
        <polygon
          fill={colors[3]}
          points="67.0306396,60.980278 67.9985352,60.2543602 67.8775482,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,60.980278 67.9985352,60.2543602 67.8775482,60.1333771"
        />
        <polygon
          fill={colors[3]}
          points="67.9985352,60.3753471 67.9985352,60.2543602 67.9985352,60.4963303"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,60.3753471 67.9985352,60.2543602 67.9985352,60.4963303"
        />
        <polygon
          fill={colors[4]}
          points="67.9985352,60.3753471 67.9985352,60.3753471 68.1195145,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,60.3753471 67.9985352,60.3753471 68.1195145,60.3753471"
        />
        <polygon
          fill={colors[4]}
          points="67.1516266,61.3432312 67.0306396,61.3432312 67.0306396,61.3432312 67.9985352,60.4963303 67.9985352,60.2543602 67.9985352,60.2543602 67.0306396,60.980278 67.0306396,61.1012611 66.9096603,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1516266,61.3432312 67.0306396,61.3432312 67.0306396,61.3432312 67.9985352,60.4963303 67.9985352,60.2543602 67.9985352,60.2543602 67.0306396,60.980278 67.0306396,61.1012611 66.9096603,61.1012611"
        />
        <polygon
          fill={colors[3]}
          points="66.3047333,60.4963303 66.9096603,61.1012611 66.9096603,59.2864761 66.7886734,59.2864761 66.7886734,60.8592873"
        />
        <polygon
          fill={colors[4]}
          points="66.9096603,61.1012611 66.9096603,61.1012611 67.0306396,61.1012611 67.8775482,60.1333771 67.8775482,60.1333771 67.0306396,60.8592873 67.0306396,59.2864761 66.9096603,59.165493 66.9096603,59.2864761"
        />
        <polygon
          fill="#CCC"
          points="67.8775482,60.1333771 67.0306396,59.2864761 67.0306396,60.8592873 67.8775482,60.1333771 67.7565536,60.1333771"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,60.8592873 66.7886734,59.2864761 65.9417725,60.1333771 66.3047333,60.4963303"
        />
        <polygon
          fill={colors[4]}
          points="67.0306396,59.2864761 67.0306396,59.165493 66.9096603,59.165493 66.9096603,59.165493 66.9096603,59.165493"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,59.2864761 67.0306396,59.165493 66.9096603,59.165493 66.9096603,59.165493 66.9096603,59.165493"
        />
        <polygon
          fill="#CCC"
          points="67.0306396,59.2864761 67.8775482,60.1333771 67.9985352,60.012394 67.8775482,59.8914032 67.0306396,59.165493 67.0306396,59.165493"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,59.2864761 67.8775482,60.1333771 67.9985352,60.012394 67.8775482,59.8914032 67.0306396,59.165493 67.0306396,59.165493"
        />
        <polygon
          fill={colors[4]}
          points="67.0306396,59.165493 67.0306396,59.165493 66.9096603,59.165493 66.9096603,59.165493"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,59.165493 67.0306396,59.165493 66.9096603,59.165493 66.9096603,59.165493"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.0306396,59.165493 67.0306396,59.165493 66.9096603,59.165493 66.9096603,59.165493"
        />
        <polygon
          fill={colors[3]}
          points="70.1762695,60.4963303 70.1762695,60.1333771 70.1762695,60.1333771 70.1762695,60.1333771 70.1762695,60.617321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,60.4963303 70.1762695,60.1333771 70.1762695,60.1333771 70.1762695,60.1333771 70.1762695,60.617321"
        />
        <polygon
          fill={colors[4]}
          points="70.2972641,60.2543602 70.1762695,60.1333771 70.1762695,60.4963303 70.2972641,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,60.2543602 70.1762695,60.1333771 70.1762695,60.4963303 70.2972641,60.3753471"
        />
        <polygon
          fill="#CCC"
          points="70.2972641,60.3753471 70.2972641,60.2543602 70.2972641,60.3753471 70.4182434,60.3753471 70.4182434,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,60.3753471 70.2972641,60.2543602 70.2972641,60.3753471 70.4182434,60.3753471 70.4182434,60.3753471"
        />
        <polygon
          fill={colors[4]}
          points="70.1762695,60.617321 70.1762695,60.1333771 69.2083893,61.1012611 69.4503555,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,60.617321 70.1762695,60.1333771 69.2083893,61.1012611 69.4503555,61.2222481"
        />
        <polygon
          fill={colors[4]}
          points="70.4182434,60.2543602 70.5392303,60.1333771 70.5392303,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,60.2543602 70.5392303,60.1333771 70.5392303,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,60.2543602 70.5392303,60.1333771 70.5392303,60.1333771"
        />
        <polygon
          fill="#CCC"
          points="70.4182434,60.3753471 70.4182434,60.2543602 70.4182434,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,60.3753471 70.4182434,60.2543602 70.4182434,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,60.3753471 70.4182434,60.2543602 70.4182434,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,60.1333771 68.2405014,60.1333771 68.8454285,60.8592873 68.8454285,60.8592873"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,60.1333771 68.2405014,60.1333771 68.8454285,60.8592873 68.8454285,60.8592873"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,60.8592873 68.9664154,60.8592873 68.9664154,60.980278 68.9664154,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,60.8592873 68.9664154,60.8592873 68.9664154,60.980278 68.9664154,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,60.8592873 68.9664154,60.8592873 68.9664154,60.980278 68.9664154,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,60.980278 69.2083893,61.1012611 69.2083893,61.2222481 69.3293762,61.3432312 69.3293762,61.3432312 69.2083893,61.1012611 69.2083893,61.1012611 69.2083893,61.1012611 69.0874023,60.980278 69.0874023,60.980278 69.0874023,60.980278 68.9664154,60.980278 68.9664154,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,60.980278 69.2083893,61.1012611 69.2083893,61.2222481 69.3293762,61.3432312 69.3293762,61.3432312 69.2083893,61.1012611 69.2083893,61.1012611 69.2083893,61.1012611 69.0874023,60.980278 69.0874023,60.980278 69.0874023,60.980278 68.9664154,60.980278 68.9664154,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,60.980278 69.2083893,61.1012611 69.2083893,61.2222481 69.3293762,61.3432312 69.3293762,61.3432312 69.2083893,61.1012611 69.2083893,61.1012611 69.2083893,61.1012611 69.0874023,60.980278 69.0874023,60.980278 69.0874023,60.980278 68.9664154,60.980278 68.9664154,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,60.980278 69.2083893,61.1012611 69.2083893,61.2222481 69.3293762,61.3432312 69.3293762,61.3432312 69.2083893,61.1012611 69.2083893,61.1012611 69.2083893,61.1012611 69.0874023,60.980278 69.0874023,60.980278 69.0874023,60.980278 68.9664154,60.980278 68.9664154,60.980278"
        />
        <polygon
          fill={colors[3]}
          points="69.2083893,61.1012611 69.3293762,61.3432312 69.4503555,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,61.1012611 69.3293762,61.3432312 69.4503555,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,61.1012611 69.3293762,61.3432312 69.4503555,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,61.1012611 69.3293762,61.3432312 69.4503555,61.2222481"
        />
        <polygon
          fill={colors[4]}
          points="69.4503555,61.2222481 69.4503555,61.2222481 69.2083893,61.1012611 69.2083893,61.1012611 69.2083893,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,61.2222481 69.4503555,61.2222481 69.2083893,61.1012611 69.2083893,61.1012611 69.2083893,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,61.2222481 69.4503555,61.2222481 69.2083893,61.1012611 69.2083893,61.1012611 69.2083893,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,61.2222481 69.4503555,61.2222481 69.2083893,61.1012611 69.2083893,61.1012611 69.2083893,61.1012611"
        />
        <polygon
          fill={colors[4]}
          points="69.3293762,61.3432312 69.3293762,61.3432312 69.2083893,61.2222481 69.0874023,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.3293762,61.3432312 69.3293762,61.3432312 69.2083893,61.2222481 69.0874023,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.3293762,61.3432312 69.3293762,61.3432312 69.2083893,61.2222481 69.0874023,61.2222481"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,61.3432312 69.0874023,61.2222481 69.0874023,61.3432312 68.9664154,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,61.3432312 69.0874023,61.2222481 69.0874023,61.3432312 68.9664154,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,61.3432312 69.0874023,61.2222481 69.0874023,61.3432312 68.9664154,61.3432312"
        />
        <polygon
          fill="#CCC"
          points="69.2083893,61.4642181 69.3293762,61.3432312 69.0874023,61.2222481 69.0874023,61.2222481 69.0874023,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,61.4642181 69.3293762,61.3432312 69.0874023,61.2222481 69.0874023,61.2222481 69.0874023,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.2083893,61.4642181 69.3293762,61.3432312 69.0874023,61.2222481 69.0874023,61.2222481 69.0874023,61.3432312"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,60.980278 68.9664154,60.980278 68.9664154,60.8592873 68.2405014,60.2543602 68.1195145,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,60.980278 68.9664154,60.980278 68.9664154,60.8592873 68.2405014,60.2543602 68.1195145,60.2543602"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,61.3432312 68.9664154,61.3432312 68.9664154,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,61.3432312 68.9664154,61.3432312 68.9664154,61.3432312"
        />
        <polygon
          fill="#CCC"
          points="68.1195145,60.2543602 68.1195145,60.3753471 68.1195145,60.3753471 68.9664154,61.3432312 69.0874023,61.3432312 69.0874023,61.2222481 69.0874023,61.2222481 69.2083893,61.2222481 69.2083893,61.1012611 69.0874023,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,60.2543602 68.1195145,60.3753471 68.1195145,60.3753471 68.9664154,61.3432312 69.0874023,61.3432312 69.0874023,61.2222481 69.0874023,61.2222481 69.2083893,61.2222481 69.2083893,61.1012611 69.0874023,60.980278"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,60.2543602 68.2405014,60.1333771 68.2405014,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,60.2543602 68.2405014,60.1333771 68.2405014,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,60.2543602 68.2405014,60.1333771 68.2405014,60.1333771"
        />
        <polygon
          fill={colors[3]}
          points="67.9985352,60.2543602 67.8775482,60.1333771 67.8775482,60.1333771 67.9985352,60.2543602 67.9985352,60.2543602 67.9985352,60.2543602 67.9985352,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,60.2543602 67.8775482,60.1333771 67.8775482,60.1333771 67.9985352,60.2543602 67.9985352,60.2543602 67.9985352,60.2543602 67.9985352,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,60.2543602 67.8775482,60.1333771 67.8775482,60.1333771 67.9985352,60.2543602 67.9985352,60.2543602 67.9985352,60.2543602 67.9985352,60.3753471"
        />
        <polygon
          fill={colors[4]}
          points="68.1195145,60.3753471 68.1195145,60.3753471 68.1195145,60.2543602 68.1195145,60.3753471 67.9985352,60.2543602 67.9985352,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,60.3753471 68.1195145,60.3753471 68.1195145,60.2543602 68.1195145,60.3753471 67.9985352,60.2543602 67.9985352,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,60.3753471 68.1195145,60.3753471 68.1195145,60.2543602 68.1195145,60.3753471 67.9985352,60.2543602 67.9985352,60.3753471"
        />
        <polygon
          fill={colors[4]}
          points="68.2405014,60.2543602 68.2405014,60.2543602 68.1195145,60.2543602 68.1195145,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,60.2543602 68.2405014,60.2543602 68.1195145,60.2543602 68.1195145,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,60.2543602 68.2405014,60.2543602 68.1195145,60.2543602 68.1195145,60.2543602"
        />
        <polygon
          fill="#CCC"
          points="68.1195145,60.2543602 68.1195145,60.2543602 68.1195145,60.2543602 68.1195145,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,60.2543602 68.1195145,60.2543602 68.1195145,60.2543602 68.1195145,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,60.2543602 68.1195145,60.2543602 68.1195145,60.2543602 68.1195145,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,59.8914032 69.0874023,59.0445023 69.0874023,59.0445023 68.1195145,59.8914032"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,59.8914032 69.0874023,59.0445023 69.0874023,59.0445023 68.1195145,59.8914032"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,60.1333771 68.2405014,60.1333771 68.1195145,60.012394 67.9985352,60.012394 68.2405014,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,60.1333771 68.2405014,60.1333771 68.1195145,60.012394 67.9985352,60.012394 68.2405014,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,60.1333771 68.2405014,60.1333771 68.1195145,60.012394 67.9985352,60.012394 68.2405014,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2405014,60.1333771 68.2405014,60.1333771 68.1195145,60.012394 67.9985352,60.012394 68.2405014,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,59.8914032 67.9985352,59.8914032 67.9985352,60.012394 67.9985352,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,59.8914032 67.9985352,59.8914032 67.9985352,60.012394 67.9985352,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,59.8914032 67.9985352,59.8914032 67.9985352,60.012394 67.9985352,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,59.8914032 67.9985352,59.8914032 67.9985352,60.012394 67.9985352,60.012394"
        />
        <polygon
          fill={colors[3]}
          points="67.9985352,60.2543602 67.9985352,60.012394 67.9985352,60.1333771 67.8775482,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,60.2543602 67.9985352,60.012394 67.9985352,60.1333771 67.8775482,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,60.2543602 67.9985352,60.012394 67.9985352,60.1333771 67.8775482,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9985352,60.2543602 67.9985352,60.012394 67.9985352,60.1333771 67.8775482,60.1333771"
        />
        <polygon
          fill={colors[4]}
          points="68.1195145,60.3753471 68.1195145,60.2543602 68.1195145,60.2543602 68.1195145,60.2543602 68.2405014,60.2543602 67.9985352,60.012394 67.9985352,60.012394 67.9985352,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,60.3753471 68.1195145,60.2543602 68.1195145,60.2543602 68.1195145,60.2543602 68.2405014,60.2543602 67.9985352,60.012394 67.9985352,60.012394 67.9985352,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,60.3753471 68.1195145,60.2543602 68.1195145,60.2543602 68.1195145,60.2543602 68.2405014,60.2543602 67.9985352,60.012394 67.9985352,60.012394 67.9985352,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,60.3753471 68.1195145,60.2543602 68.1195145,60.2543602 68.1195145,60.2543602 68.2405014,60.2543602 67.9985352,60.012394 67.9985352,60.012394 67.9985352,60.2543602"
        />
        <polygon
          fill="#CCC"
          points="67.8775482,60.1333771 67.8775482,60.1333771 67.8775482,60.1333771 67.9985352,60.1333771 67.9985352,60.012394 67.9985352,60.012394 68.1195145,60.012394 67.9985352,60.012394 67.9985352,60.012394 67.9985352,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,60.1333771 67.8775482,60.1333771 67.8775482,60.1333771 67.9985352,60.1333771 67.9985352,60.012394 67.9985352,60.012394 68.1195145,60.012394 67.9985352,60.012394 67.9985352,60.012394 67.9985352,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8775482,60.1333771 67.8775482,60.1333771 67.8775482,60.1333771 67.9985352,60.1333771 67.9985352,60.012394 67.9985352,60.012394 68.1195145,60.012394 67.9985352,60.012394 67.9985352,60.012394 67.9985352,60.012394"
        />
        <polygon
          fill={colors[3]}
          points="68.1195145,60.012394 68.1195145,59.8914032 67.9985352,59.8914032 67.9985352,59.8914032"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,60.012394 68.1195145,59.8914032 67.9985352,59.8914032 67.9985352,59.8914032"
        />
        <polygon
          fill={colors[4]}
          points="68.1195145,60.012394 68.1195145,59.8914032 69.0874023,59.0445023 68.9664154,59.0445023 68.4824753,59.5284462 68.1195145,59.8914032"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1195145,60.012394 68.1195145,59.8914032 69.0874023,59.0445023 68.9664154,59.0445023 68.4824753,59.5284462 68.1195145,59.8914032"
        />
        <polygon
          fill="#CCC"
          points="68.9664154,59.0445023 68.9664154,59.0445023 68.9664154,59.0445023 68.4824753,59.5284462"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9664154,59.0445023 68.9664154,59.0445023 68.9664154,59.0445023 68.4824753,59.5284462"
        />
        <polygon
          fill={colors[3]}
          points="69.0874023,60.8592873 68.2405014,60.1333771 68.2405014,60.1333771 68.2405014,60.1333771 68.9664154,60.8592873 68.9664154,60.980278 69.0874023,60.980278 69.0874023,60.980278 69.2083893,61.1012611 69.2083893,59.165493 69.0874023,59.165493"
        />
        <polygon
          fill={colors[3]}
          points="69.0874023,59.165493 69.0874023,59.0445023 68.2405014,59.8914032"
        />
        <polygon
          fill={colors[4]}
          points="69.2083893,61.1012611 69.2083893,61.1012611 69.2083893,61.1012611 70.1762695,60.1333771 70.1762695,60.1333771 70.1762695,60.1333771 69.3293762,60.8592873 69.3293762,59.2864761 69.2083893,59.165493"
        />
        <polygon
          fill="#CCC"
          points="69.3293762,60.8592873 70.1762695,60.1333771 69.3293762,59.2864761"
        />
        <polygon
          fill={colors[4]}
          points="68.1195145,59.8914032 68.1195145,60.012394 68.1195145,60.012394 67.9985352,60.012394 67.9985352,60.012394 68.1195145,60.012394 68.2405014,60.1333771 69.0874023,60.8592873 69.0874023,59.165493 69.0874023,59.165493 68.2405014,59.8914032"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,59.0445023 69.3293762,59.0445023 70.1762695,59.7704201 70.1762695,59.7704201"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,59.0445023 69.3293762,59.0445023 70.1762695,59.7704201 70.1762695,59.7704201"
        />
        <polygon
          fill={colors[4]}
          points="70.2972641,60.2543602 70.1762695,60.1333771 70.1762695,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,60.2543602 70.1762695,60.1333771 70.1762695,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,60.2543602 70.1762695,60.1333771 70.1762695,60.1333771"
        />
        <polygon
          fill="#CCC"
          points="70.2972641,60.3753471 70.4182434,60.3753471 70.2972641,60.2543602 70.2972641,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,60.3753471 70.4182434,60.3753471 70.2972641,60.2543602 70.2972641,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,60.3753471 70.4182434,60.3753471 70.2972641,60.2543602 70.2972641,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,59.8914032 70.2972641,60.012394 70.4182434,60.012394 70.4182434,60.012394 70.5392303,60.1333771 70.5392303,60.1333771 70.4182434,60.012394 70.4182434,60.012394 70.2972641,60.012394 70.1762695,59.7704201 70.1762695,59.7704201"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,59.8914032 70.2972641,60.012394 70.4182434,60.012394 70.4182434,60.012394 70.5392303,60.1333771 70.5392303,60.1333771 70.4182434,60.012394 70.4182434,60.012394 70.2972641,60.012394 70.1762695,59.7704201 70.1762695,59.7704201"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,59.8914032 70.2972641,60.012394 70.4182434,60.012394 70.4182434,60.012394 70.5392303,60.1333771 70.5392303,60.1333771 70.4182434,60.012394 70.4182434,60.012394 70.2972641,60.012394 70.1762695,59.7704201 70.1762695,59.7704201"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,59.8914032 70.2972641,60.012394 70.4182434,60.012394 70.4182434,60.012394 70.5392303,60.1333771 70.5392303,60.1333771 70.4182434,60.012394 70.4182434,60.012394 70.2972641,60.012394 70.1762695,59.7704201 70.1762695,59.7704201"
        />
        <polygon
          fill={colors[4]}
          points="70.2972641,60.2543602 70.2972641,60.1333771 70.4182434,60.2543602 70.5392303,60.1333771 70.4182434,60.012394 70.1762695,60.1333771 70.1762695,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,60.2543602 70.2972641,60.1333771 70.4182434,60.2543602 70.5392303,60.1333771 70.4182434,60.012394 70.1762695,60.1333771 70.1762695,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,60.2543602 70.2972641,60.1333771 70.4182434,60.2543602 70.5392303,60.1333771 70.4182434,60.012394 70.1762695,60.1333771 70.1762695,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.2972641,60.2543602 70.2972641,60.1333771 70.4182434,60.2543602 70.5392303,60.1333771 70.4182434,60.012394 70.1762695,60.1333771 70.1762695,60.1333771"
        />
        <polygon
          fill="#CCC"
          points="70.4182434,60.2543602 70.2972641,60.1333771 70.2972641,60.2543602 70.4182434,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,60.2543602 70.2972641,60.1333771 70.2972641,60.2543602 70.4182434,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,60.2543602 70.2972641,60.1333771 70.2972641,60.2543602 70.4182434,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,60.2543602 70.2972641,60.1333771 70.2972641,60.2543602 70.4182434,60.3753471"
        />
        <polygon
          fill={colors[3]}
          points="70.4182434,60.012394 70.5392303,60.1333771 70.5392303,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,60.012394 70.5392303,60.1333771 70.5392303,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4182434,60.012394 70.5392303,60.1333771 70.5392303,60.1333771"
        />
        <polygon
          fill={colors[4]}
          points="70.5392303,60.1333771 70.5392303,60.1333771 70.4182434,59.8914032 70.4182434,60.012394 70.4182434,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5392303,60.1333771 70.5392303,60.1333771 70.4182434,59.8914032 70.4182434,60.012394 70.4182434,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5392303,60.1333771 70.5392303,60.1333771 70.4182434,59.8914032 70.4182434,60.012394 70.4182434,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,59.0445023 69.3293762,59.0445023 69.3293762,59.0445023"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,59.0445023 69.3293762,59.0445023 69.3293762,59.0445023"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.4503555,59.0445023 69.3293762,59.0445023 69.3293762,59.0445023"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,59.0445023 69.0874023,59.0445023 69.0874023,59.0445023 68.9664154,59.0445023"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,59.0445023 69.0874023,59.0445023 69.0874023,59.0445023 68.9664154,59.0445023"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,59.0445023 69.0874023,59.0445023 69.0874023,59.0445023 68.9664154,59.0445023"
        />
        <polygon
          fill="#CCC"
          points="69.0874023,59.0445023 68.9664154,59.0445023 68.9664154,59.0445023"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,59.0445023 68.9664154,59.0445023 68.9664154,59.0445023"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,59.0445023 68.9664154,59.0445023 68.9664154,59.0445023"
        />
        <polygon
          fill={colors[3]}
          points="69.0874023,59.0445023 69.0874023,59.165493 69.0874023,59.0445023 69.0874023,59.165493 69.2083893,59.165493 69.2083893,59.0445023"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,59.0445023 69.0874023,59.165493 69.0874023,59.0445023 69.0874023,59.165493 69.2083893,59.165493 69.2083893,59.0445023"
        />
        <polygon
          fill="#CCC"
          points="70.1762695,60.1333771 70.1762695,60.1333771 70.1762695,60.1333771 70.4182434,60.012394 70.4182434,60.012394 70.2972641,60.012394 70.1762695,59.8914032 70.1762695,59.8914032 70.1762695,59.8914032 69.3293762,59.0445023 69.3293762,59.2864761"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1762695,60.1333771 70.1762695,60.1333771 70.1762695,60.1333771 70.4182434,60.012394 70.4182434,60.012394 70.2972641,60.012394 70.1762695,59.8914032 70.1762695,59.8914032 70.1762695,59.8914032 69.3293762,59.0445023 69.3293762,59.2864761"
        />
        <polygon
          fill={colors[4]}
          points="69.0874023,59.0445023 69.0874023,59.165493 69.0874023,59.165493"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.0874023,59.0445023 69.0874023,59.165493 69.0874023,59.165493"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.0948715,61.7061882 65.0948715,61.7061882 65.5788193,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.0948715,61.7061882 65.0948715,61.7061882 65.5788193,62.1901321"
        />
        <polygon
          fill={colors[4]}
          points="65.0948715,61.7061882 65.0948715,61.827179 65.5788193,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.0948715,61.7061882 65.0948715,61.827179 65.5788193,62.1901321"
        />
        <polygon
          fill="#CCC"
          points="65.5788193,62.3111191 65.6998062,62.3111191 65.5788193,62.1901321 65.0948715,61.827179 65.0948715,61.9481621 65.4578323,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,62.3111191 65.6998062,62.3111191 65.5788193,62.1901321 65.0948715,61.827179 65.0948715,61.9481621 65.4578323,62.432106"
        />
        <polygon
          fill="#CCC"
          points="65.6998062,62.3111191 65.6998062,62.3111191 65.5788193,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.3111191 65.6998062,62.3111191 65.5788193,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.3111191 65.6998062,62.3111191 65.5788193,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,61.3432312 66.6676941,61.3432312 65.6998062,62.3111191 65.8207855,62.3111191 65.9417725,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6676941,61.3432312 66.6676941,61.3432312 65.6998062,62.3111191 65.8207855,62.3111191 65.9417725,62.1901321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191"
        />
        <polygon
          fill="#CCC"
          points="65.5788193,62.3111191 65.4578323,62.432106 65.4578323,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,62.3111191 65.4578323,62.432106 65.4578323,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,62.3111191 65.4578323,62.432106 65.4578323,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.3111191 65.6998062,62.432106 65.8207855,62.5530891 65.9417725,62.432106 65.8207855,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.3111191 65.6998062,62.432106 65.8207855,62.5530891 65.9417725,62.432106 65.8207855,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.3111191 65.6998062,62.432106 65.8207855,62.5530891 65.9417725,62.432106 65.8207855,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.3111191 65.6998062,62.432106 65.8207855,62.5530891 65.9417725,62.432106 65.8207855,62.3111191"
        />
        <polygon
          fill="#CCC"
          points="65.5788193,62.432106 65.5788193,62.432106 65.5788193,62.432106 65.6998062,62.432106 65.6998062,62.432106 65.6998062,62.3111191 65.6998062,62.3111191 65.5788193,62.3111191 65.4578323,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,62.432106 65.5788193,62.432106 65.5788193,62.432106 65.6998062,62.432106 65.6998062,62.432106 65.6998062,62.3111191 65.6998062,62.3111191 65.5788193,62.3111191 65.4578323,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,62.432106 65.5788193,62.432106 65.5788193,62.432106 65.6998062,62.432106 65.6998062,62.432106 65.6998062,62.3111191 65.6998062,62.3111191 65.5788193,62.3111191 65.4578323,62.432106"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,62.432106 65.5788193,62.432106 65.5788193,62.432106 65.6998062,62.432106 65.6998062,62.432106 65.6998062,62.3111191 65.6998062,62.3111191 65.5788193,62.3111191 65.4578323,62.432106"
        />
        <polygon
          fill={colors[3]}
          points="65.6998062,62.432106 65.5788193,62.432106 65.5788193,62.432106 65.6998062,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.432106 65.5788193,62.432106 65.5788193,62.432106 65.6998062,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.432106 65.5788193,62.432106 65.5788193,62.432106 65.6998062,62.5530891"
        />
        <polygon
          fill={colors[4]}
          points="65.6998062,62.6740723 65.8207855,62.5530891 65.6998062,62.432106 65.6998062,62.432106 65.6998062,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.6740723 65.8207855,62.5530891 65.6998062,62.432106 65.6998062,62.432106 65.6998062,62.5530891"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,62.6740723 65.8207855,62.5530891 65.6998062,62.432106 65.6998062,62.432106 65.6998062,62.5530891"
        />
        <polygon
          fill={colors[3]}
          points="66.9096603,61.4642181 66.7886734,61.4642181 66.7886734,61.5852051"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.4642181 66.7886734,61.4642181 66.7886734,61.5852051"
        />
        <polygon
          fill={colors[3]}
          points="65.9417725,62.1901321 66.7886734,61.4642181 66.6676941,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,62.1901321 66.7886734,61.4642181 66.6676941,61.3432312"
        />
        <polygon
          fill={colors[4]}
          points="65.9417725,62.432106 66.7886734,61.5852051 66.7886734,61.4642181 66.7886734,61.4642181 65.9417725,62.1901321 65.8207855,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.8207855,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,62.432106 66.7886734,61.5852051 66.7886734,61.4642181 66.7886734,61.4642181 65.9417725,62.1901321 65.8207855,62.3111191 65.6998062,62.3111191 65.6998062,62.3111191 65.8207855,62.3111191"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,61.3432312 66.6676941,61.3432312 66.6676941,61.3432312 66.6676941,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,61.3432312 66.6676941,61.3432312 66.6676941,61.3432312 66.6676941,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,61.3432312 66.6676941,61.3432312 66.6676941,61.3432312 66.6676941,61.3432312"
        />
        <polygon
          fill={colors[3]}
          points="66.9096603,61.4642181 66.7886734,61.3432312 66.6676941,61.3432312 66.7886734,61.4642181 66.7886734,61.4642181 66.7886734,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.4642181 66.7886734,61.3432312 66.6676941,61.3432312 66.7886734,61.4642181 66.7886734,61.4642181 66.7886734,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.4642181 66.7886734,61.3432312 66.6676941,61.3432312 66.7886734,61.4642181 66.7886734,61.4642181 66.7886734,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.9417725,60.2543602 66.7886734,60.980278 66.7886734,61.1012611 66.7886734,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.9417725,60.2543602 66.7886734,60.980278 66.7886734,61.1012611 66.7886734,61.1012611"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.2222481 67.0306396,61.3432312 67.0306396,61.3432312 67.0306396,61.3432312 66.9096603,61.2222481 66.7886734,61.3432312 66.6676941,61.3432312 66.7886734,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.2222481 67.0306396,61.3432312 67.0306396,61.3432312 67.0306396,61.3432312 66.9096603,61.2222481 66.7886734,61.3432312 66.6676941,61.3432312 66.7886734,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.2222481 67.0306396,61.3432312 67.0306396,61.3432312 67.0306396,61.3432312 66.9096603,61.2222481 66.7886734,61.3432312 66.6676941,61.3432312 66.7886734,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.2222481 67.0306396,61.3432312 67.0306396,61.3432312 67.0306396,61.3432312 66.9096603,61.2222481 66.7886734,61.3432312 66.6676941,61.3432312 66.7886734,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.2222481 66.9096603,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.2222481 66.9096603,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.2222481 66.9096603,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.2222481 66.9096603,61.2222481"
        />
        <polygon
          fill={colors[3]}
          points="66.9096603,61.2222481 66.7886734,61.3432312 66.9096603,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.2222481 66.7886734,61.3432312 66.9096603,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.2222481 66.7886734,61.3432312 66.9096603,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.2222481 66.7886734,61.3432312 66.9096603,61.4642181"
        />
        <polygon
          fill={colors[4]}
          points="66.9096603,61.4642181 66.9096603,61.4642181 66.9096603,61.4642181 67.0306396,61.4642181 67.0306396,61.3432312 66.9096603,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.4642181 66.9096603,61.4642181 66.9096603,61.4642181 67.0306396,61.4642181 67.0306396,61.3432312 66.9096603,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.4642181 66.9096603,61.4642181 66.9096603,61.4642181 67.0306396,61.4642181 67.0306396,61.3432312 66.9096603,61.2222481"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.4642181 66.9096603,61.4642181 66.9096603,61.4642181 67.0306396,61.4642181 67.0306396,61.3432312 66.9096603,61.2222481"
        />
        <polygon
          fill="#CCC"
          points="66.9096603,61.4642181 66.9096603,61.4642181 67.0306396,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.4642181 66.9096603,61.4642181 67.0306396,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.4642181 66.9096603,61.4642181 67.0306396,61.4642181"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.4642181 66.9096603,61.4642181 67.0306396,61.4642181"
        />
        <polygon
          fill={colors[4]}
          points="66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.2222481 67.0306396,61.3432312 67.0306396,61.3432312 67.1516266,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.2222481 67.0306396,61.3432312 67.0306396,61.3432312 67.1516266,61.3432312"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.2222481 67.0306396,61.3432312 67.0306396,61.3432312 67.1516266,61.3432312"
        />
        <polygon
          fill={colors[3]}
          points="66.7886734,61.1012611 66.7886734,61.1012611 66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.1012611 66.3047333,60.4963303 65.9417725,60.1333771 65.9417725,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7886734,61.1012611 66.7886734,61.1012611 66.9096603,61.1012611 66.9096603,61.1012611 66.9096603,61.1012611 66.3047333,60.4963303 65.9417725,60.1333771 65.9417725,60.1333771"
        />
        <polygon
          fill={colors[4]}
          points="66.3047333,60.4963303 65.9417725,60.1333771 65.9417725,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.3047333,60.4963303 65.9417725,60.1333771 65.9417725,60.1333771"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.9417725,60.1333771 65.9417725,60.2543602 65.9417725,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.9417725,60.1333771 65.9417725,60.2543602 65.9417725,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.9417725,60.1333771 65.9417725,60.2543602 65.9417725,60.2543602"
        />
        <polygon
          fill={colors[4]}
          points="65.9417725,60.1333771 65.9417725,60.1333771 65.9417725,60.1333771 65.9417725,60.1333771 65.8207855,60.012394 65.8207855,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.9417725,60.1333771 65.9417725,60.1333771 65.9417725,60.1333771 65.8207855,60.012394 65.8207855,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.9417725,60.1333771 65.9417725,60.1333771 65.9417725,60.1333771 65.8207855,60.012394 65.8207855,60.012394"
        />
        <polygon
          fill={colors[3]}
          points="65.8207855,59.8914032 65.8207855,59.8914032 65.6998062,59.8914032 65.6998062,59.8914032"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,59.8914032 65.8207855,59.8914032 65.6998062,59.8914032 65.6998062,59.8914032"
        />
        <polygon
          fill={colors[4]}
          points="65.8207855,60.012394 65.8207855,60.012394 65.8207855,59.8914032 65.8207855,59.8914032"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,60.012394 65.8207855,60.012394 65.8207855,59.8914032 65.8207855,59.8914032"
        />
        <polygon
          fill={colors[3]}
          points="65.6998062,62.3111191 65.6998062,60.3753471 65.6998062,60.3753471 65.6998062,62.0691452 65.0948715,61.5852051 65.0948715,61.7061882"
        />
        <polygon
          fill={colors[4]}
          points="66.6676941,61.3432312 66.6676941,61.3432312 66.7886734,61.3432312 65.8207855,62.0691452 65.8207855,60.2543602 65.6998062,60.3753471 65.6998062,62.3111191 65.6998062,62.3111191"
        />
        <polygon
          fill={colors[4]}
          points="66.7886734,60.980278 65.9417725,60.2543602 65.9417725,60.2543602 65.8207855,60.2543602"
        />
        <polygon
          fill="#CCC"
          points="66.9096603,61.1012611 66.9096603,61.1012611 66.7886734,61.1012611 66.7886734,61.1012611 66.7886734,60.980278 65.8207855,60.2543602 65.8207855,60.2543602 65.8207855,62.0691452 66.7886734,61.3432312 66.9096603,61.2222481"
        />
        <polygon
          fill={colors[4]}
          points="65.6998062,60.3753471 65.2158585,60.8592873 65.0948715,61.5852051 65.6998062,62.0691452"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,60.1333771 65.3368454,60.3753471 65.3368454,60.4963303 65.5788193,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.5788193,60.1333771 65.3368454,60.3753471 65.3368454,60.4963303 65.5788193,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.9417725,60.1333771 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.5788193,60.1333771 65.5788193,60.1333771 65.5788193,60.1333771 65.6998062,60.012394 65.9417725,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.9417725,60.1333771 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.5788193,60.1333771 65.5788193,60.1333771 65.5788193,60.1333771 65.6998062,60.012394 65.9417725,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.9417725,60.1333771 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.5788193,60.1333771 65.5788193,60.1333771 65.5788193,60.1333771 65.6998062,60.012394 65.9417725,60.2543602"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.9417725,60.1333771 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.6998062,60.012394 65.5788193,60.1333771 65.5788193,60.1333771 65.5788193,60.1333771 65.6998062,60.012394 65.9417725,60.2543602"
        />
        <polygon
          fill={colors[4]}
          points="65.9417725,60.1333771 65.8207855,60.012394 65.8207855,60.012394 65.6998062,60.012394 65.6998062,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.8207855,60.012394 65.8207855,60.012394 65.6998062,60.012394 65.6998062,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.8207855,60.012394 65.8207855,60.012394 65.6998062,60.012394 65.6998062,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9417725,60.1333771 65.8207855,60.012394 65.8207855,60.012394 65.6998062,60.012394 65.6998062,60.012394"
        />
        <polygon
          fill={colors[3]}
          points="65.6998062,60.012394 65.8207855,60.012394 65.8207855,59.8914032 65.6998062,59.8914032 65.6998062,59.8914032"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,60.012394 65.8207855,60.012394 65.8207855,59.8914032 65.6998062,59.8914032 65.6998062,59.8914032"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,60.012394 65.8207855,60.012394 65.8207855,59.8914032 65.6998062,59.8914032 65.6998062,59.8914032"
        />
        <polygon
          fill={colors[4]}
          points="65.8207855,59.8914032 65.8207855,60.012394 65.8207855,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,59.8914032 65.8207855,60.012394 65.8207855,60.012394"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,59.8914032 65.8207855,60.012394 65.8207855,60.012394"
        />
        <polygon
          fill={colors[3]}
          points="65.6998062,60.2543602 65.6998062,60.3753471 65.6998062,60.3753471 65.6998062,60.012394 65.5788193,60.1333771 65.5788193,60.2543602 65.3368454,60.4963303 65.2158585,60.617321"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,60.2543602 65.6998062,60.3753471 65.6998062,60.3753471 65.6998062,60.012394 65.5788193,60.1333771 65.5788193,60.2543602 65.3368454,60.4963303 65.2158585,60.617321"
        />
        <polygon
          fill={colors[4]}
          points="65.8207855,60.2543602 65.8207855,60.2543602 65.8207855,60.2543602 65.9417725,60.2543602 65.6998062,60.012394 65.6998062,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8207855,60.2543602 65.8207855,60.2543602 65.8207855,60.2543602 65.9417725,60.2543602 65.6998062,60.012394 65.6998062,60.3753471"
        />
        <polygon
          fill={colors[4]}
          points="65.6998062,60.2543602 65.2158585,60.617321 65.2158585,60.8592873 65.6998062,60.3753471"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.6998062,60.2543602 65.2158585,60.617321 65.2158585,60.8592873 65.6998062,60.3753471"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,59.7704201 65.4578323,59.7704201 65.5788193,59.8914032"
        />
        <polygon
          fill={colors[4]}
          points="65.5788193,60.1333771 65.5788193,60.1333771 65.3368454,60.3753471 65.3368454,60.3753471"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,73.8047638L70.1762695,73.8047638 L70.1762695,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,73.8047638L70.1762695,73.8047638 L70.1762695,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,73.8047638L70.1762695,73.8047638 L70.1762695,73.8047638z"
        />
        <path
          fill={colors[4]}
          d="M70.0552826,73.9257431L70.0552826,73.9257431L70.0552826,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,73.9257431L70.0552826,73.9257431 L70.0552826,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,73.9257431L70.0552826,73.9257431 L70.0552826,73.9257431z"
        />
        <path
          fill="#CCC"
          d="M70.0552826,73.9257431L70.0552826,73.9257431L70.0552826,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,73.9257431L70.0552826,73.9257431 L70.0552826,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,73.9257431L70.0552826,73.9257431 L70.0552826,73.9257431z"
        />
        <path
          fill={colors[7]}
          d="M70.1762695,73.8047638L70.1762695,73.8047638L70.1762695,73.8047638L70.1762695,73.8047638 L70.1762695,73.8047638z"
        />
        <path
          fill={colors[7]}
          d="M70.1762695,73.8047638L70.1762695,73.8047638L70.1762695,73.8047638L70.1762695,73.8047638 L70.1762695,73.8047638z"
        />
        <path
          fill={colors[4]}
          d="M71.1441574,72.8368683L71.1441574,72.8368683L71.1441574,72.8368683z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,72.8368683L71.1441574,72.8368683 L71.1441574,72.8368683z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,72.3529282L71.1441574,72.3529282 L71.1441574,72.3529282z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,72.3529282L71.1441574,72.3529282 L71.1441574,72.3529282z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,72.3529282L71.1441574,72.3529282 L71.1441574,72.3529282z"
        />
        <path
          fill="#CCC"
          d="M70.1762695,71.6270142L70.1762695,71.6270142L70.1762695,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,71.6270142L70.1762695,71.6270142 L70.1762695,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,71.6270142L70.1762695,71.6270142 L70.1762695,71.6270142z"
        />
        <path
          fill={colors[4]}
          d="M69.9343033,71.6270142L69.9343033,71.6270142L69.9343033,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,71.6270142L69.9343033,71.6270142 L69.9343033,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,71.6270142L69.9343033,71.6270142 L69.9343033,71.6270142z"
        />
        <path
          fill={colors[7]}
          d="M69.9343033,71.5060272L69.9343033,71.5060272L69.9343033,71.5060272L69.9343033,71.5060272 L69.9343033,71.5060272z"
        />
        <path
          fill={colors[7]}
          d="M69.9343033,71.5060272L69.9343033,71.5060272L69.9343033,71.5060272L69.9343033,71.5060272 L69.9343033,71.5060272z"
        />
        <path
          fill="#CCC"
          d="M70.1762695,71.6270142L70.1762695,71.6270142L70.1762695,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,71.6270142L70.1762695,71.6270142 L70.1762695,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,71.6270142L70.1762695,71.6270142 L70.1762695,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,71.6270142L70.1762695,71.6270142 L70.1762695,71.6270142z"
        />
        <path
          fill={colors[4]}
          d="M69.9343033,71.5060272L69.9343033,71.5060272L69.9343033,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,71.5060272L69.9343033,71.5060272 L69.9343033,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,71.5060272L69.9343033,71.5060272 L69.9343033,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,70.41716L71.3861237,70.41716 L71.3861237,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,70.41716L71.3861237,70.41716 L71.3861237,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,70.41716L71.3861237,70.41716 L71.3861237,70.41716z"
        />
        <path
          fill={colors[4]}
          d="M71.3861237,70.41716L71.3861237,70.41716L71.3861237,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,70.41716L71.3861237,70.41716 L71.3861237,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,70.41716L71.3861237,70.41716 L71.3861237,70.41716z"
        />
        <path
          fill={colors[4]}
          d="M71.2651443,70.5381393L71.2651443,70.5381393L71.2651443,70.5381393z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,70.5381393L71.2651443,70.5381393 L71.2651443,70.5381393z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,70.5381393L71.2651443,70.5381393 L71.2651443,70.5381393z"
        />
        <path
          fill={colors[4]}
          d="M71.1441574,70.41716L71.1441574,70.41716L71.1441574,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,70.41716L71.1441574,70.41716 L71.1441574,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,70.41716L71.1441574,70.41716 L71.1441574,70.41716z"
        />
        <path
          fill={colors[7]}
          d="M71.0231705,70.41716L71.0231705,70.41716L71.0231705,70.41716L71.0231705,70.41716L71.0231705,70.41716z"
        />
        <path
          fill={colors[7]}
          d="M71.0231705,70.41716L71.0231705,70.41716L71.0231705,70.41716L71.0231705,70.41716L71.0231705,70.41716z"
        />
        <path
          fill={colors[7]}
          d="M71.1441574,70.1751938L71.1441574,70.1751938L71.1441574,70.1751938L71.1441574,70.1751938 L71.1441574,70.1751938z"
        />
        <path
          fill={colors[7]}
          d="M71.1441574,70.1751938L71.1441574,70.1751938L71.1441574,70.1751938L71.1441574,70.1751938 L71.1441574,70.1751938z"
        />
        <path
          fill={colors[7]}
          d="M71.1441574,70.1751938L71.1441574,70.1751938L71.1441574,70.1751938L71.1441574,70.1751938 L71.1441574,70.1751938z"
        />
        <path
          fill={colors[4]}
          d="M71.1441574,70.41716L71.1441574,70.41716L71.1441574,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,70.41716L71.1441574,70.41716 L71.1441574,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,70.41716L71.1441574,70.41716 L71.1441574,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,70.41716L71.1441574,70.41716 L71.1441574,70.41716z"
        />
        <path
          fill={colors[3]}
          d="M71.3861237,70.41716L71.3861237,70.41716L71.3861237,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,70.41716L71.3861237,70.41716 L71.3861237,70.41716z"
        />
        <path
          fill={colors[4]}
          d="M71.2651443,72.8368683L71.2651443,72.8368683L71.2651443,72.8368683z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,72.8368683L71.2651443,72.8368683 L71.2651443,72.8368683z"
        />
        <path
          fill={colors[4]}
          d="M71.1441574,72.715889L71.1441574,72.715889L71.1441574,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,72.715889L71.1441574,72.715889 L71.1441574,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,72.715889L71.1441574,72.715889 L71.1441574,72.715889z"
        />
        <path
          fill={colors[7]}
          d="M71.0231705,72.594902L71.0231705,72.594902L71.0231705,72.594902z"
        />
        <path
          fill={colors[7]}
          d="M71.0231705,72.594902L71.0231705,72.594902L71.0231705,72.594902z"
        />
        <path
          fill="#CCC"
          d="M71.2651443,72.715889L71.2651443,72.715889L71.2651443,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,72.715889L71.2651443,72.715889 L71.2651443,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,72.715889L71.2651443,72.715889 L71.2651443,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,72.715889L71.2651443,72.715889 L71.2651443,72.715889z"
        />
        <path
          fill={colors[4]}
          d="M71.0231705,72.594902L71.0231705,72.594902L71.0231705,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.0231705,72.594902L71.0231705,72.594902 L71.0231705,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.0231705,72.594902L71.0231705,72.594902 L71.0231705,72.594902z"
        />
        <path
          fill={colors[7]}
          d="M72.2330322,71.2640533L72.2330322,71.2640533L72.2330322,71.2640533L72.2330322,71.2640533 L72.2330322,71.2640533z"
        />
        <path
          fill={colors[7]}
          d="M72.2330322,71.2640533L72.2330322,71.2640533L72.2330322,71.2640533L72.2330322,71.2640533 L72.2330322,71.2640533z"
        />
        <path
          fill={colors[7]}
          d="M72.2330322,71.2640533L72.2330322,71.2640533L72.2330322,71.2640533L72.2330322,71.2640533 L72.2330322,71.2640533z"
        />
        <path
          fill={colors[4]}
          d="M72.3540115,71.2640533L72.3540115,71.2640533L72.3540115,71.2640533z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,71.2640533L72.3540115,71.2640533 L72.3540115,71.2640533z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,71.2640533L72.3540115,71.2640533 L72.3540115,71.2640533z"
        />
        <path
          fill={colors[4]}
          d="M72.3540115,71.2640533L72.3540115,71.2640533L72.3540115,71.2640533z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,71.2640533L72.3540115,71.2640533 L72.3540115,71.2640533z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,71.2640533L72.3540115,71.2640533 L72.3540115,71.2640533z"
        />
        <path
          fill={colors[4]}
          d="M72.2330322,71.5060272L72.2330322,71.5060272L72.2330322,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,71.5060272L72.2330322,71.5060272 L72.2330322,71.5060272z"
        />
        <path
          fill={colors[7]}
          d="M69.8133163,73.8047638L69.8133163,73.8047638L69.8133163,73.8047638L69.8133163,73.8047638 L69.8133163,73.8047638z"
        />
        <path
          fill={colors[7]}
          d="M69.8133163,73.8047638L69.8133163,73.8047638L69.8133163,73.8047638L69.8133163,73.8047638 L69.8133163,73.8047638z"
        />
        <path
          fill={colors[7]}
          d="M69.8133163,73.8047638L69.8133163,73.8047638L69.8133163,73.8047638L69.8133163,73.8047638 L69.8133163,73.8047638z"
        />
        <path
          fill={colors[4]}
          d="M69.9343033,73.9257431L69.9343033,73.9257431L69.9343033,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,73.9257431L69.9343033,73.9257431 L69.9343033,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,73.9257431L69.9343033,73.9257431 L69.9343033,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,73.5627823L69.9343033,73.5627823 L69.9343033,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,73.5627823L69.9343033,73.5627823 L69.9343033,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,73.5627823L69.9343033,73.5627823 L69.9343033,73.5627823z"
        />
        <path
          fill="#CCC"
          d="M70.0552826,73.9257431L70.0552826,73.9257431L70.0552826,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,73.9257431L70.0552826,73.9257431 L70.0552826,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,73.9257431L70.0552826,73.9257431 L70.0552826,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,73.9257431L70.0552826,73.9257431 L70.0552826,73.9257431z"
        />
        <path
          fill={colors[4]}
          d="M70.0552826,73.5627823L70.0552826,73.5627823L70.0552826,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,73.5627823L70.0552826,73.5627823 L70.0552826,73.5627823z"
        />
        <path
          fill={colors[4]}
          d="M69.0874023,72.715889l0.2419739,0.1209793L69.0874023,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,72.715889l0.2419739,0.1209793 L69.0874023,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,72.715889L69.0874023,72.715889 L69.0874023,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,72.715889L69.0874023,72.715889 L69.0874023,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,72.715889L69.0874023,72.715889 L69.0874023,72.715889z"
        />
        <path
          fill={colors[7]}
          d="M68.7244415,72.715889C68.7244415,72.715889,68.7244415,72.594902,68.7244415,72.715889 C68.7244415,72.594902,68.7244415,72.594902,68.7244415,72.715889 C68.7244415,72.594902,68.7244415,72.594902,68.7244415,72.715889L68.7244415,72.715889z"
        />
        <path
          fill={colors[7]}
          d="M68.7244415,72.715889C68.7244415,72.715889,68.7244415,72.594902,68.7244415,72.715889 C68.7244415,72.594902,68.7244415,72.594902,68.7244415,72.715889 C68.7244415,72.594902,68.7244415,72.594902,68.7244415,72.715889L68.7244415,72.715889z"
        />
        <path
          fill={colors[7]}
          d="M68.7244415,72.715889C68.7244415,72.715889,68.7244415,72.594902,68.7244415,72.715889 C68.7244415,72.594902,68.7244415,72.594902,68.7244415,72.715889 C68.7244415,72.594902,68.7244415,72.594902,68.7244415,72.715889L68.7244415,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,72.594902L68.7244415,72.594902 L68.7244415,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,72.594902L68.7244415,72.594902 L68.7244415,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,72.594902L68.7244415,72.594902 L68.7244415,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,72.594902L68.7244415,72.594902 L68.7244415,72.594902z"
        />
        <path
          fill={colors[3]}
          d="M69.0874023,72.594902L69.0874023,72.594902L69.0874023,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,72.594902L69.0874023,72.594902 L69.0874023,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,72.594902L69.0874023,72.594902 L69.0874023,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,72.594902L69.0874023,72.594902 L69.0874023,72.594902z"
        />
        <path
          fill={colors[4]}
          d="M68.9664154,72.4739075L68.9664154,72.4739075L68.9664154,72.4739075z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,72.4739075L68.9664154,72.4739075 L68.9664154,72.4739075z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,72.4739075L68.9664154,72.4739075 L68.9664154,72.4739075z"
        />
        <path
          fill="#CCC"
          d="M68.9664154,72.715889L68.9664154,72.715889L68.9664154,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,72.715889L68.9664154,72.715889 L68.9664154,72.715889z"
        />
        <path
          fill="#CCC"
          d="M72.1120453,73.6837692L72.1120453,73.6837692L72.1120453,73.6837692z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.1120453,73.6837692L72.1120453,73.6837692 L72.1120453,73.6837692z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,73.5627823L72.2330322,73.5627823 L72.2330322,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,73.5627823L72.2330322,73.5627823 L72.2330322,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,73.5627823L72.2330322,73.5627823 L72.2330322,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4428864,72.3529282L73.4428864,72.3529282 L73.4428864,72.3529282z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4428864,72.3529282L73.4428864,72.3529282 L73.4428864,72.3529282z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4428864,72.3529282L73.4428864,72.3529282 L73.4428864,72.3529282z"
        />
        <path
          fill={colors[3]}
          d="M73.4428864,72.3529282L73.4428864,72.3529282L73.4428864,72.3529282z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4428864,72.3529282L73.4428864,72.3529282 L73.4428864,72.3529282z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,73.8047638L72.4749985,73.8047638 L72.4749985,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,73.8047638L72.4749985,73.8047638 L72.4749985,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,73.8047638L72.4749985,73.8047638 L72.4749985,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.1120453,73.8047638L72.1120453,73.8047638 L72.1120453,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.1120453,73.8047638L72.1120453,73.8047638 L72.1120453,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.1120453,73.8047638L72.1120453,73.8047638 L72.1120453,73.8047638z"
        />
        <path
          fill={colors[4]}
          d="M72.1120453,73.8047638L72.1120453,73.8047638L72.1120453,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.1120453,73.8047638L72.1120453,73.8047638 L72.1120453,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.1120453,73.8047638L72.1120453,73.8047638 L72.1120453,73.8047638z"
        />
        <path
          fill={colors[4]}
          d="M72.3540115,73.5627823L72.3540115,73.5627823L72.3540115,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,73.5627823L72.3540115,73.5627823 L72.3540115,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,73.5627823L72.3540115,73.5627823 L72.3540115,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,72.594902L73.321907,72.594902 L73.321907,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,72.594902L73.321907,72.594902 L73.321907,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,72.594902L73.321907,72.594902 L73.321907,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,72.594902L73.321907,72.594902 L73.321907,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,72.594902L73.321907,72.594902 L73.321907,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,72.594902L73.321907,72.594902 L73.321907,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,72.594902L73.321907,72.594902 L73.321907,72.594902z"
        />
        <path
          fill={colors[4]}
          d="M73.321907,72.594902L73.321907,72.594902L73.321907,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,72.594902L73.321907,72.594902 L73.321907,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,72.594902L73.321907,72.594902 L73.321907,72.594902z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,72.594902L73.321907,72.594902 L73.321907,72.594902z"
        />
        <path
          fill={colors[4]}
          d="M73.321907,72.715889L73.321907,72.715889L73.321907,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,72.715889L73.321907,72.715889 L73.321907,72.715889z"
        />
        <path
          fill={colors[4]}
          d="M67.8775482,73.8047638l0.3629608,0.3629608L67.8775482,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,73.8047638l0.3629608,0.3629608 L67.8775482,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,73.8047638L67.8775482,73.8047638 L67.8775482,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,73.8047638L67.8775482,73.8047638 L67.8775482,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,73.8047638L67.8775482,73.8047638 L67.8775482,73.8047638z"
        />
        <path
          fill={colors[3]}
          d="M67.8775482,73.8047638L67.8775482,73.8047638L67.8775482,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,73.8047638L67.8775482,73.8047638 L67.8775482,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,73.8047638L67.8775482,73.8047638 L67.8775482,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,74.772644L68.7244415,74.772644 L68.7244415,74.772644z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,74.772644L68.7244415,74.772644 L68.7244415,74.772644z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,74.772644L68.7244415,74.772644 L68.7244415,74.772644z"
        />
        <path
          fill={colors[4]}
          d="M67.7565536,73.5627823L67.7565536,73.5627823L67.7565536,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,73.5627823L67.7565536,73.5627823 L67.7565536,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.5145874,73.8047638L67.5145874,73.8047638 L67.5145874,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.5145874,73.8047638L67.5145874,73.8047638 L67.5145874,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.5145874,73.8047638L67.5145874,73.8047638 L67.5145874,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6355743,73.5627823L67.6355743,73.5627823 L67.6355743,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6355743,73.5627823L67.6355743,73.5627823 L67.6355743,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6355743,73.5627823L67.6355743,73.5627823 L67.6355743,73.5627823z"
        />
        <path
          fill={colors[3]}
          d="M67.8775482,73.8047638L67.8775482,73.8047638L67.8775482,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,73.8047638L67.8775482,73.8047638 L67.8775482,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,73.8047638L67.8775482,73.8047638 L67.8775482,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,73.8047638L67.8775482,73.8047638 L67.8775482,73.8047638z"
        />
        <path
          fill={colors[4]}
          d="M67.7565536,73.5627823L67.7565536,73.5627823L67.7565536,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,73.5627823L67.7565536,73.5627823 L67.7565536,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,73.5627823L67.7565536,73.5627823 L67.7565536,73.5627823z"
        />
        <path
          fill="#CCC"
          d="M67.7565536,73.9257431L67.7565536,73.9257431L67.7565536,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,73.9257431L67.7565536,73.9257431 L67.7565536,73.9257431z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.5638733,74.772644L73.5638733,74.772644 L73.5638733,74.772644z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.5638733,74.772644L73.5638733,74.772644 L73.5638733,74.772644z"
        />
        <path
          fill={colors[7]}
          d="M66.7886734,72.594902L66.7886734,72.594902L66.7886734,72.594902L66.7886734,72.594902 L66.7886734,72.594902z"
        />
        <path
          fill={colors[7]}
          d="M66.7886734,72.594902L66.7886734,72.594902L66.7886734,72.594902L66.7886734,72.594902 L66.7886734,72.594902z"
        />
        <path
          fill={colors[7]}
          d="M66.7886734,72.715889L66.7886734,72.715889L66.7886734,72.715889L66.7886734,72.715889 L66.7886734,72.715889z"
        />
        <path
          fill={colors[7]}
          d="M66.7886734,72.715889L66.7886734,72.715889L66.7886734,72.715889L66.7886734,72.715889 L66.7886734,72.715889z"
        />
        <path
          fill={colors[7]}
          d="M66.7886734,72.715889L66.7886734,72.715889L66.7886734,72.715889L66.7886734,72.715889 L66.7886734,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,71.5060272L67.8775482,71.5060272 L67.8775482,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,71.5060272L67.8775482,71.5060272 L67.8775482,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,71.5060272L67.8775482,71.5060272 L67.8775482,71.5060272z"
        />
        <path
          fill={colors[7]}
          d="M67.5145874,71.5060272L67.5145874,71.5060272L67.5145874,71.5060272z"
        />
        <path
          fill={colors[7]}
          d="M67.5145874,71.5060272L67.5145874,71.5060272L67.5145874,71.5060272z"
        />
        <path
          fill={colors[7]}
          d="M67.5145874,71.5060272L67.5145874,71.5060272L67.5145874,71.5060272z"
        />
        <path
          fill="#CCC"
          d="M67.7565536,71.6270142L67.7565536,71.6270142L67.7565536,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,71.6270142L67.7565536,71.6270142 L67.7565536,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.4257126,72.4739075L66.4257126,72.4739075 L66.4257126,72.4739075z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.4257126,72.4739075L66.4257126,72.4739075 L66.4257126,72.4739075z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.4257126,72.4739075L66.4257126,72.4739075 L66.4257126,72.4739075z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,71.5060272L65.5788193,71.5060272 L65.5788193,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,71.5060272L65.5788193,71.5060272 L65.5788193,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,71.5060272L65.5788193,71.5060272 L65.5788193,71.5060272z"
        />
        <path
          fill="#CCC"
          d="M65.4578323,71.6270142L65.4578323,71.6270142L65.4578323,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,71.6270142L65.4578323,71.6270142 L65.4578323,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,71.6270142L65.4578323,71.6270142 L65.4578323,71.6270142z"
        />
        <path
          fill={colors[3]}
          d="M66.5466995,70.5381393L66.5466995,70.5381393L66.5466995,70.5381393z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,70.5381393L66.5466995,70.5381393 L66.5466995,70.5381393z"
        />
        <path
          fill={colors[7]}
          d="M65.5788193,71.5060272L65.5788193,71.5060272L65.5788193,71.5060272z"
        />
        <path
          fill={colors[7]}
          d="M65.5788193,71.5060272L65.5788193,71.5060272L65.5788193,71.5060272z"
        />
        <path
          fill={colors[4]}
          d="M66.5466995,70.5381393L66.5466995,70.5381393L66.5466995,70.5381393z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,70.5381393L66.5466995,70.5381393 L66.5466995,70.5381393z"
        />
        <path
          fill={colors[4]}
          d="M65.4578323,69.4492722L65.4578323,69.4492722L65.4578323,69.4492722z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,69.4492722L65.4578323,69.4492722 L65.4578323,69.4492722z"
        />
        <path
          fill={colors[4]}
          d="M65.4578323,69.4492722L65.4578323,69.4492722L65.4578323,69.4492722z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,69.4492722L65.4578323,69.4492722 L65.4578323,69.4492722z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,69.4492722L65.4578323,69.4492722 L65.4578323,69.4492722z"
        />
        <path
          fill={colors[4]}
          d="M65.5788193,69.3282852L65.5788193,69.3282852L65.5788193,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,69.3282852L65.5788193,69.3282852 L65.5788193,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,69.3282852L65.5788193,69.3282852 L65.5788193,69.3282852z"
        />
        <path
          fill={colors[7]}
          d="M65.6998062,69.2072983L65.6998062,69.2072983L65.6998062,69.2072983L65.6998062,69.2072983 L65.6998062,69.2072983z"
        />
        <path
          fill={colors[7]}
          d="M65.6998062,69.2072983L65.6998062,69.2072983L65.6998062,69.2072983L65.6998062,69.2072983 L65.6998062,69.2072983z"
        />
        <path
          fill={colors[4]}
          d="M65.5788193,67.1505508L65.5788193,67.1505508L65.5788193,67.1505508z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,67.1505508L65.5788193,67.1505508 L65.5788193,67.1505508z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,67.0295563L65.6998062,67.0295563 L65.6998062,67.0295563z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,67.0295563L65.6998062,67.0295563 L65.6998062,67.0295563z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,67.0295563L65.6998062,67.0295563 L65.6998062,67.0295563z"
        />
        <path
          fill={colors[4]}
          d="M65.4578323,67.0295563L65.4578323,67.0295563L65.4578323,67.0295563z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,67.0295563L65.4578323,67.0295563 L65.4578323,67.0295563z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,67.0295563L65.4578323,67.0295563 L65.4578323,67.0295563z"
        />
        <path
          fill={colors[7]}
          d="M65.3368454,66.9085693C65.3368454,66.9085693,65.3368454,67.0295563,65.3368454,66.9085693 C65.3368454,67.0295563,65.3368454,67.0295563,65.3368454,66.9085693 C65.3368454,67.0295563,65.3368454,67.0295563,65.3368454,66.9085693L65.3368454,66.9085693z"
        />
        <path
          fill={colors[7]}
          d="M65.3368454,66.9085693C65.3368454,66.9085693,65.3368454,67.0295563,65.3368454,66.9085693 C65.3368454,67.0295563,65.3368454,67.0295563,65.3368454,66.9085693 C65.3368454,67.0295563,65.3368454,67.0295563,65.3368454,66.9085693L65.3368454,66.9085693z"
        />
        <path
          fill="#CCC"
          d="M65.5788193,67.0295563L65.5788193,67.0295563L65.5788193,67.0295563z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,67.0295563L65.5788193,67.0295563 L65.5788193,67.0295563z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,67.0295563L65.5788193,67.0295563 L65.5788193,67.0295563z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,67.0295563L65.5788193,67.0295563 L65.5788193,67.0295563z"
        />
        <path
          fill={colors[4]}
          d="M65.3368454,66.9085693L65.3368454,66.9085693L65.3368454,66.9085693z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.3368454,66.9085693L65.3368454,66.9085693 L65.3368454,66.9085693z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.3368454,66.9085693L65.3368454,66.9085693 L65.3368454,66.9085693z"
        />
        <path
          fill={colors[3]}
          d="M65.6998062,66.9085693L65.6998062,66.9085693L65.6998062,66.9085693z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,66.9085693L65.6998062,66.9085693 L65.6998062,66.9085693z"
        />
        <path
          fill={colors[7]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021 C66.5466995,65.9406891,66.5466995,65.9406891,66.5466995,65.8197021 C66.5466995,65.9406891,66.5466995,65.9406891,66.5466995,65.8197021L66.5466995,65.8197021z"
        />
        <path
          fill={colors[7]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021 C66.5466995,65.9406891,66.5466995,65.9406891,66.5466995,65.8197021 C66.5466995,65.9406891,66.5466995,65.9406891,66.5466995,65.8197021L66.5466995,65.8197021z"
        />
        <path
          fill={colors[7]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021 C66.5466995,65.9406891,66.5466995,65.9406891,66.5466995,65.8197021 C66.5466995,65.9406891,66.5466995,65.9406891,66.5466995,65.8197021L66.5466995,65.8197021z"
        />
        <path
          fill={colors[7]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021L66.5466995,65.8197021L66.5466995,65.8197021 L66.5466995,65.8197021z"
        />
        <path
          fill={colors[7]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021L66.5466995,65.8197021L66.5466995,65.8197021 L66.5466995,65.8197021z"
        />
        <path
          fill={colors[7]}
          d="M66.6676941,65.5777283L66.6676941,65.5777283 C66.5466995,65.5777283,66.5466995,65.5777283,66.6676941,65.5777283 C66.5466995,65.5777283,66.5466995,65.5777283,66.6676941,65.5777283 C66.5466995,65.5777283,66.6676941,65.5777283,66.6676941,65.5777283z"
        />
        <path
          fill={colors[7]}
          d="M66.6676941,65.5777283L66.6676941,65.5777283 C66.5466995,65.5777283,66.5466995,65.5777283,66.6676941,65.5777283 C66.5466995,65.5777283,66.5466995,65.5777283,66.6676941,65.5777283 C66.5466995,65.5777283,66.6676941,65.5777283,66.6676941,65.5777283z"
        />
        <path
          fill={colors[7]}
          d="M66.6676941,65.5777283L66.6676941,65.5777283 C66.5466995,65.5777283,66.5466995,65.5777283,66.6676941,65.5777283 C66.5466995,65.5777283,66.5466995,65.5777283,66.6676941,65.5777283 C66.5466995,65.5777283,66.6676941,65.5777283,66.6676941,65.5777283z"
        />
        <path
          fill={colors[4]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021L66.5466995,65.8197021z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021 L66.5466995,65.8197021z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021 L66.5466995,65.8197021z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021 L66.5466995,65.8197021z"
        />
        <path
          fill={colors[4]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021L66.5466995,65.8197021z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021 L66.5466995,65.8197021z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,65.8197021L66.5466995,65.8197021 L66.5466995,65.8197021z"
        />
        <path
          fill={colors[3]}
          d="M66.9096603,65.8197021L66.9096603,65.8197021L66.9096603,65.8197021z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,65.8197021L66.9096603,65.8197021 L66.9096603,65.8197021z"
        />
        <path
          fill="#CCC"
          d="M67.8775482,64.8518219L67.8775482,64.8518219L67.8775482,64.8518219z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,64.8518219L67.8775482,64.8518219 L67.8775482,64.8518219z"
        />
        <path
          fill={colors[4]}
          d="M66.6676941,68.2394104L66.6676941,68.2394104L66.6676941,68.2394104z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,68.2394104L66.6676941,68.2394104 L66.6676941,68.2394104z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7886734,68.1184311L66.7886734,68.1184311 L66.7886734,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7886734,68.1184311L66.7886734,68.1184311 L66.7886734,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7886734,68.1184311L66.7886734,68.1184311 L66.7886734,68.1184311z"
        />
        <path
          fill={colors[4]}
          d="M66.5466995,68.1184311L66.5466995,68.1184311L66.5466995,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,68.1184311L66.5466995,68.1184311 L66.5466995,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,68.1184311L66.5466995,68.1184311 L66.5466995,68.1184311z"
        />
        <path
          fill={colors[7]}
          d="M66.4257126,68.1184311L66.4257126,68.1184311L66.4257126,68.1184311z"
        />
        <path
          fill={colors[7]}
          d="M66.4257126,68.1184311L66.4257126,68.1184311L66.4257126,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,67.8764572L66.5466995,67.8764572 L66.5466995,67.8764572z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,67.8764572L66.5466995,67.8764572 L66.5466995,67.8764572z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.5466995,67.8764572L66.5466995,67.8764572 L66.5466995,67.8764572z"
        />
        <path
          fill={colors[4]}
          d="M66.4257126,68.1184311L66.4257126,68.1184311L66.4257126,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.4257126,68.1184311L66.4257126,68.1184311 L66.4257126,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.4257126,68.1184311L66.4257126,68.1184311 L66.4257126,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9985352,66.9085693L67.9985352,66.9085693 L67.9985352,66.9085693z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9985352,66.9085693L67.9985352,66.9085693 L67.9985352,66.9085693z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9985352,66.9085693L67.9985352,66.9085693 L67.9985352,66.9085693z"
        />
        <path
          fill={colors[4]}
          d="M67.7565536,66.78759L67.7565536,66.78759L67.7565536,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,66.78759L67.7565536,66.78759 L67.7565536,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,66.78759L67.7565536,66.78759 L67.7565536,66.78759z"
        />
        <path
          fill={colors[4]}
          d="M67.7565536,67.0295563L67.7565536,67.0295563L67.7565536,67.0295563z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,67.0295563L67.7565536,67.0295563 L67.7565536,67.0295563z"
        />
        <path
          fill={colors[7]}
          d="M65.3368454,69.2072983L65.3368454,69.2072983 C65.2158585,69.2072983,65.2158585,69.2072983,65.3368454,69.2072983 C65.2158585,69.2072983,65.2158585,69.2072983,65.3368454,69.2072983L65.3368454,69.2072983z"
        />
        <path
          fill={colors[7]}
          d="M65.3368454,69.2072983L65.3368454,69.2072983 C65.2158585,69.2072983,65.2158585,69.2072983,65.3368454,69.2072983 C65.2158585,69.2072983,65.2158585,69.2072983,65.3368454,69.2072983L65.3368454,69.2072983z"
        />
        <path
          fill={colors[7]}
          d="M65.3368454,69.2072983L65.3368454,69.2072983 C65.2158585,69.2072983,65.2158585,69.2072983,65.3368454,69.2072983 C65.2158585,69.2072983,65.2158585,69.2072983,65.3368454,69.2072983L65.3368454,69.2072983z"
        />
        <path
          fill={colors[4]}
          d="M65.3368454,69.3282852L65.3368454,69.3282852L65.3368454,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.3368454,69.3282852L65.3368454,69.3282852 L65.3368454,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.3368454,69.3282852L65.3368454,69.3282852 L65.3368454,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.3368454,69.2072983L65.3368454,69.2072983 L65.3368454,69.2072983z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.3368454,69.2072983L65.3368454,69.2072983 L65.3368454,69.2072983z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.3368454,69.2072983L65.3368454,69.2072983 L65.3368454,69.2072983z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.3368454,69.2072983L65.3368454,69.2072983 L65.3368454,69.2072983z"
        />
        <path
          fill={colors[7]}
          d="M64.1269836,68.1184311L64.1269836,68.1184311L64.1269836,68.1184311L64.1269836,68.1184311 L64.1269836,68.1184311z"
        />
        <path
          fill={colors[7]}
          d="M64.1269836,68.1184311L64.1269836,68.1184311L64.1269836,68.1184311L64.1269836,68.1184311 L64.1269836,68.1184311z"
        />
        <path
          fill={colors[7]}
          d="M64.1269836,68.1184311L64.1269836,68.1184311L64.1269836,68.1184311L64.1269836,68.1184311 L64.1269836,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.2479782,67.8764572L64.2479782,67.8764572 L64.2479782,67.8764572z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.2479782,67.8764572L64.2479782,67.8764572 L64.2479782,67.8764572z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.2479782,67.8764572L64.2479782,67.8764572 L64.2479782,67.8764572z"
        />
        <path
          fill={colors[3]}
          d="M64.4899445,68.1184311L64.4899445,68.1184311L64.4899445,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.4899445,68.1184311L64.4899445,68.1184311 L64.4899445,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.4899445,68.1184311L64.4899445,68.1184311 L64.4899445,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.4899445,68.1184311L64.4899445,68.1184311 L64.4899445,68.1184311z"
        />
        <path
          fill={colors[3]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393L68.8454285,70.5381393z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393 L68.8454285,70.5381393z"
        />
        <path
          fill={colors[4]}
          d="M69.0874023,70.2961731L69.0874023,70.2961731L69.0874023,70.2961731z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,70.2961731L69.0874023,70.2961731 L69.0874023,70.2961731z"
        />
        <path
          fill={colors[7]}
          d="M67.7565536,71.2640533L67.7565536,71.2640533L67.7565536,71.2640533z"
        />
        <path
          fill={colors[7]}
          d="M67.7565536,71.2640533L67.7565536,71.2640533L67.7565536,71.2640533z"
        />
        <path
          fill="#CCC"
          d="M67.7565536,71.6270142L67.7565536,71.6270142L67.7565536,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,71.6270142L67.7565536,71.6270142 L67.7565536,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,71.6270142L67.7565536,71.6270142 L67.7565536,71.6270142z"
        />
        <path
          fill={colors[4]}
          d="M67.5145874,71.5060272L67.5145874,71.5060272L67.5145874,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.5145874,71.5060272L67.5145874,71.5060272 L67.5145874,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.4257126,70.41716L66.4257126,70.41716 L66.4257126,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.4257126,70.41716L66.4257126,70.41716 L66.4257126,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.4257126,70.41716L66.4257126,70.41716 L66.4257126,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7886734,70.41716L66.7886734,70.41716 L66.7886734,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7886734,70.41716L66.7886734,70.41716 L66.7886734,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7886734,70.41716L66.7886734,70.41716 L66.7886734,70.41716z"
        />
        <path
          fill={colors[4]}
          d="M66.4257126,70.41716L66.4257126,70.41716L66.4257126,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.4257126,70.41716L66.4257126,70.41716 L66.4257126,70.41716z"
        />
        <path
          fill="#CCC"
          d="M66.6676941,70.41716L66.6676941,70.41716L66.6676941,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,70.41716L66.6676941,70.41716 L66.6676941,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,70.41716L66.6676941,70.41716 L66.6676941,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,70.41716L66.6676941,70.41716 L66.6676941,70.41716z"
        />
        <path
          fill={colors[4]}
          d="M66.4257126,70.2961731L66.4257126,70.2961731L66.4257126,70.2961731z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.4257126,70.2961731L66.4257126,70.2961731 L66.4257126,70.2961731z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.4257126,70.2961731L66.4257126,70.2961731 L66.4257126,70.2961731z"
        />
        <path
          fill={colors[7]}
          d="M68.9664154,70.1751938L68.9664154,70.1751938L68.9664154,70.1751938L68.9664154,70.1751938 L68.9664154,70.1751938z"
        />
        <path
          fill={colors[7]}
          d="M68.9664154,70.1751938L68.9664154,70.1751938L68.9664154,70.1751938L68.9664154,70.1751938 L68.9664154,70.1751938z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,70.1751938L68.9664154,70.1751938 L68.9664154,70.1751938z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,70.1751938L68.9664154,70.1751938 L68.9664154,70.1751938z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,70.1751938L68.9664154,70.1751938 L68.9664154,70.1751938z"
        />
        <path
          fill={colors[7]}
          d="M69.8133163,69.2072983L69.8133163,69.2072983L69.8133163,69.2072983z"
        />
        <path
          fill={colors[7]}
          d="M69.8133163,69.2072983L69.8133163,69.2072983L69.8133163,69.2072983z"
        />
        <path
          fill={colors[3]}
          d="M70.0552826,69.3282852L70.0552826,69.3282852L70.0552826,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,69.3282852L70.0552826,69.3282852 L70.0552826,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,69.3282852L70.0552826,69.3282852 L70.0552826,69.3282852z"
        />
        <path
          fill={colors[4]}
          d="M70.0552826,69.3282852L70.0552826,69.3282852L70.0552826,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,69.3282852L70.0552826,69.3282852 L70.0552826,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,69.3282852L70.0552826,69.3282852 L70.0552826,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,67.7554779L68.8454285,67.7554779 L68.8454285,67.7554779z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,67.7554779L68.8454285,67.7554779 L68.8454285,67.7554779z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,67.7554779L68.8454285,67.7554779 L68.8454285,67.7554779z"
        />
        <path
          fill={colors[7]}
          d="M68.8454285,67.7554779L68.8454285,67.7554779L68.8454285,67.7554779z"
        />
        <path
          fill={colors[7]}
          d="M68.8454285,67.7554779L68.8454285,67.7554779L68.8454285,67.7554779z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,70.2961731L68.7244415,70.2961731 L68.7244415,70.2961731z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,70.2961731L68.7244415,70.2961731 L68.7244415,70.2961731z"
        />
        <path
          fill={colors[7]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393L68.8454285,70.5381393L68.8454285,70.5381393 L68.8454285,70.5381393z"
        />
        <path
          fill={colors[7]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393L68.8454285,70.5381393L68.8454285,70.5381393 L68.8454285,70.5381393z"
        />
        <path
          fill={colors[7]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393L68.8454285,70.5381393L68.8454285,70.5381393 L68.8454285,70.5381393z"
        />
        <path
          fill={colors[7]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393L68.8454285,70.5381393z"
        />
        <path
          fill={colors[7]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393L68.8454285,70.5381393z"
        />
        <path
          fill={colors[7]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393L68.8454285,70.5381393z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,70.2961731L68.7244415,70.2961731 L68.7244415,70.2961731z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,70.2961731L68.7244415,70.2961731 L68.7244415,70.2961731z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,70.2961731L68.7244415,70.2961731 L68.7244415,70.2961731z"
        />
        <path
          fill={colors[4]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393L68.8454285,70.5381393z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393 L68.8454285,70.5381393z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393 L68.8454285,70.5381393z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,70.5381393L68.8454285,70.5381393 L68.8454285,70.5381393z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,69.2072983L67.8775482,69.2072983 L67.8775482,69.2072983z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,69.2072983L67.8775482,69.2072983 L67.8775482,69.2072983z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,69.2072983L67.8775482,69.2072983 L67.8775482,69.2072983z"
        />
        <path
          fill={colors[4]}
          d="M67.6355743,69.2072983L67.6355743,69.2072983L67.6355743,69.2072983z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6355743,69.2072983L67.6355743,69.2072983 L67.6355743,69.2072983z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6355743,69.2072983L67.6355743,69.2072983 L67.6355743,69.2072983z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6355743,69.2072983L67.6355743,69.2072983 L67.6355743,69.2072983z"
        />
        <path
          fill={colors[3]}
          d="M67.7565536,68.965332L67.7565536,68.965332L67.7565536,68.965332z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,68.965332L67.7565536,68.965332 L67.7565536,68.965332z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,68.965332L67.7565536,68.965332 L67.7565536,68.965332z"
        />
        <path
          fill={colors[4]}
          d="M67.7565536,68.965332L67.7565536,68.965332L67.7565536,68.965332z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,68.965332L67.7565536,68.965332 L67.7565536,68.965332z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7565536,68.965332L67.7565536,68.965332 L67.7565536,68.965332z"
        />
        <path
          fill={colors[4]}
          d="M68.7244415,70.2961731L68.7244415,70.2961731L68.7244415,70.2961731z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,70.2961731L68.7244415,70.2961731 L68.7244415,70.2961731z"
        />
        <path
          fill={colors[4]}
          d="M67.6355743,69.3282852L67.6355743,69.3282852L67.6355743,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6355743,69.3282852L67.6355743,69.3282852 L67.6355743,69.3282852z"
        />
        <path
          fill={colors[4]}
          d="M70.0552826,69.3282852L70.0552826,69.3282852L70.0552826,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,69.3282852L70.0552826,69.3282852 L70.0552826,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,69.3282852L70.0552826,69.3282852 L70.0552826,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,69.3282852L70.0552826,69.3282852 L70.0552826,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,67.8764572L68.8454285,67.8764572 L68.8454285,67.8764572z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,67.8764572L68.8454285,67.8764572 L68.8454285,67.8764572z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,67.8764572L68.8454285,67.8764572 L68.8454285,67.8764572z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,67.8764572L68.8454285,67.8764572 L68.8454285,67.8764572z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,67.9974442L68.7244415,67.9974442 L68.7244415,67.9974442z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,67.9974442L68.7244415,67.9974442 L68.7244415,67.9974442z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,67.9974442L68.7244415,67.9974442 L68.7244415,67.9974442z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,67.9974442L68.7244415,67.9974442 L68.7244415,67.9974442z"
        />
        <path
          fill={colors[4]}
          d="M68.7244415,67.9974442L68.7244415,67.9974442L68.7244415,67.9974442z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,67.9974442L68.7244415,67.9974442 L68.7244415,67.9974442z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,67.9974442L68.7244415,67.9974442 L68.7244415,67.9974442z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7244415,67.9974442L68.7244415,67.9974442 L68.7244415,67.9974442z"
        />
        <path
          fill={colors[4]}
          d="M68.8454285,68.1184311L68.8454285,68.1184311L68.8454285,68.1184311z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,68.1184311L68.8454285,68.1184311 L68.8454285,68.1184311z"
        />
        <path
          fill={colors[4]}
          d="M65.4578323,71.7480011L65.4578323,71.7480011L65.4578323,71.7480011z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,71.7480011L65.4578323,71.7480011 L65.4578323,71.7480011z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.2158585,71.5060272L65.2158585,71.5060272 L65.2158585,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.2158585,71.5060272L65.2158585,71.5060272 L65.2158585,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.2158585,71.5060272L65.2158585,71.5060272 L65.2158585,71.5060272z"
        />
        <path
          fill={colors[7]}
          d="M65.4578323,71.6270142C65.4578323,71.6270142,65.4578323,71.7480011,65.4578323,71.6270142 C65.4578323,71.7480011,65.4578323,71.7480011,65.4578323,71.6270142 C65.4578323,71.7480011,65.4578323,71.7480011,65.4578323,71.6270142 C65.4578323,71.7480011,65.4578323,71.6270142,65.4578323,71.6270142z"
        />
        <path
          fill={colors[7]}
          d="M65.4578323,71.6270142C65.4578323,71.6270142,65.4578323,71.7480011,65.4578323,71.6270142 C65.4578323,71.7480011,65.4578323,71.7480011,65.4578323,71.6270142 C65.4578323,71.7480011,65.4578323,71.7480011,65.4578323,71.6270142 C65.4578323,71.7480011,65.4578323,71.6270142,65.4578323,71.6270142z"
        />
        <path
          fill={colors[7]}
          d="M65.4578323,71.6270142C65.4578323,71.6270142,65.4578323,71.7480011,65.4578323,71.6270142 C65.4578323,71.7480011,65.4578323,71.7480011,65.4578323,71.6270142 C65.4578323,71.7480011,65.4578323,71.7480011,65.4578323,71.6270142 C65.4578323,71.7480011,65.4578323,71.6270142,65.4578323,71.6270142z"
        />
        <path
          fill={colors[4]}
          d="M65.3368454,71.6270142L65.3368454,71.6270142L65.3368454,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.3368454,71.6270142L65.3368454,71.6270142 L65.3368454,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.3368454,71.6270142L65.3368454,71.6270142 L65.3368454,71.6270142z"
        />
        <path
          fill={colors[7]}
          d="M65.3368454,71.2640533L65.3368454,71.2640533L65.3368454,71.2640533L65.3368454,71.2640533 L65.3368454,71.2640533z"
        />
        <path
          fill={colors[7]}
          d="M65.3368454,71.2640533L65.3368454,71.2640533L65.3368454,71.2640533L65.3368454,71.2640533 L65.3368454,71.2640533z"
        />
        <path
          fill={colors[7]}
          d="M65.3368454,71.2640533L65.3368454,71.2640533L65.3368454,71.2640533L65.3368454,71.2640533 L65.3368454,71.2640533z"
        />
        <path
          fill="#CCC"
          d="M65.4578323,71.6270142L65.4578323,71.6270142L65.4578323,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,71.6270142L65.4578323,71.6270142 L65.4578323,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,71.6270142L65.4578323,71.6270142 L65.4578323,71.6270142z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,71.6270142L65.4578323,71.6270142 L65.4578323,71.6270142z"
        />
        <path
          fill={colors[3]}
          d="M65.5788193,71.5060272L65.5788193,71.5060272L65.5788193,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,71.5060272L65.5788193,71.5060272 L65.5788193,71.5060272z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,71.5060272L65.5788193,71.5060272 L65.5788193,71.5060272z"
        />
        <path
          fill={colors[4]}
          d="M65.4578323,71.2640533L65.4578323,71.2640533L65.4578323,71.2640533z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,71.2640533L65.4578323,71.2640533 L65.4578323,71.2640533z"
        />
        <path
          fill={colors[7]}
          d="M64.4899445,70.41716L64.4899445,70.41716L64.4899445,70.41716L64.4899445,70.41716L64.4899445,70.41716z"
        />
        <path
          fill={colors[7]}
          d="M64.4899445,70.41716L64.4899445,70.41716L64.4899445,70.41716L64.4899445,70.41716L64.4899445,70.41716z"
        />
        <path
          fill={colors[7]}
          d="M64.4899445,70.41716L64.4899445,70.41716L64.4899445,70.41716L64.4899445,70.41716L64.4899445,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.1269836,70.41716L64.1269836,70.41716 L64.1269836,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.1269836,70.41716L64.1269836,70.41716 L64.1269836,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.1269836,70.41716L64.1269836,70.41716 L64.1269836,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.2479782,70.1751938L64.2479782,70.1751938 L64.2479782,70.1751938z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.2479782,70.1751938L64.2479782,70.1751938 L64.2479782,70.1751938z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.2479782,70.1751938L64.2479782,70.1751938 L64.2479782,70.1751938z"
        />
        <path
          fill="#CCC"
          d="M64.3689651,70.41716L64.3689651,70.41716L64.3689651,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.3689651,70.41716L64.3689651,70.41716 L64.3689651,70.41716z"
        />
        <path
          fill="#CCC"
          d="M73.321907,70.1751938L73.321907,70.1751938L73.321907,70.1751938z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.321907,70.1751938L73.321907,70.1751938 L73.321907,70.1751938z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.6848526,70.2961731L73.6848526,70.2961731 L73.6848526,70.2961731z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.6848526,70.2961731L73.6848526,70.2961731 L73.6848526,70.2961731z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.6848526,70.2961731L73.6848526,70.2961731 L73.6848526,70.2961731z"
        />
        <path
          fill={colors[4]}
          d="M73.4428864,70.41716L73.4428864,70.41716L73.4428864,70.41716z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4428864,70.41716L73.4428864,70.41716 L73.4428864,70.41716z"
        />
        <path
          fill="#CCC"
          d="M72.4749985,69.3282852L72.4749985,69.3282852L72.4749985,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,69.3282852L72.4749985,69.3282852 L72.4749985,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,69.3282852L72.4749985,69.3282852 L72.4749985,69.3282852z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.5638733,70.0541992L73.5638733,70.0541992 L73.5638733,70.0541992z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.5638733,70.0541992L73.5638733,70.0541992 L73.5638733,70.0541992z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.5638733,70.0541992L73.5638733,70.0541992 L73.5638733,70.0541992z"
        />
        <path
          fill={colors[3]}
          d="M73.6848526,69.9332123L73.6848526,69.9332123L73.6848526,69.9332123z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.6848526,69.9332123L73.6848526,69.9332123 L73.6848526,69.9332123z"
        />
        <path
          fill={colors[3]}
          d="M73.6848526,70.2961731L73.6848526,70.2961731L73.6848526,70.2961731z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.6848526,70.2961731L73.6848526,70.2961731 L73.6848526,70.2961731z"
        />
        <path
          fill={colors[3]}
          d="M71.2651443,68.2394104L71.2651443,68.2394104L71.2651443,68.2394104z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,68.2394104L71.2651443,68.2394104 L71.2651443,68.2394104z"
        />
        <path
          fill={colors[7]}
          d="M71.0231705,67.9974442L71.0231705,67.9974442L71.0231705,67.9974442L71.0231705,67.9974442 L71.0231705,67.9974442z"
        />
        <path
          fill={colors[7]}
          d="M71.0231705,67.9974442L71.0231705,67.9974442L71.0231705,67.9974442L71.0231705,67.9974442 L71.0231705,67.9974442z"
        />
        <path
          fill={colors[4]}
          d="M72.2330322,68.965332L72.2330322,68.965332L72.2330322,68.965332z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,68.965332L72.2330322,68.965332 L72.2330322,68.965332z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,68.965332L72.2330322,68.965332 L72.2330322,68.965332z"
        />
        <path
          fill={colors[3]}
          d="M73.4428864,68.2394104L73.4428864,68.2394104L73.4428864,68.2394104z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4428864,68.2394104L73.4428864,68.2394104 L73.4428864,68.2394104z"
        />
        <path
          fill={colors[3]}
          d="M72.4749985,69.086319L72.4749985,69.086319L72.4749985,69.086319z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,69.086319L72.4749985,69.086319 L72.4749985,69.086319z"
        />
        <path
          fill={colors[7]}
          d="M69.8133163,66.78759L69.8133163,66.78759C69.8133163,66.78759,69.9343033,66.78759,69.8133163,66.78759 C69.9343033,66.78759,69.9343033,66.78759,69.8133163,66.78759L69.8133163,66.78759z"
        />
        <path
          fill={colors[7]}
          d="M69.8133163,66.78759L69.8133163,66.78759C69.8133163,66.78759,69.9343033,66.78759,69.8133163,66.78759 C69.9343033,66.78759,69.9343033,66.78759,69.8133163,66.78759L69.8133163,66.78759z"
        />
        <path
          fill={colors[7]}
          d="M69.9343033,66.78759C69.9343033,66.78759,69.9343033,66.9085693,69.9343033,66.78759 C69.9343033,66.9085693,69.9343033,66.9085693,69.9343033,66.78759 C69.9343033,66.9085693,69.9343033,66.9085693,69.9343033,66.78759 C69.9343033,66.9085693,69.9343033,66.78759,69.9343033,66.78759z"
        />
        <path
          fill={colors[7]}
          d="M69.9343033,66.78759C69.9343033,66.78759,69.9343033,66.9085693,69.9343033,66.78759 C69.9343033,66.9085693,69.9343033,66.9085693,69.9343033,66.78759 C69.9343033,66.9085693,69.9343033,66.9085693,69.9343033,66.78759 C69.9343033,66.9085693,69.9343033,66.78759,69.9343033,66.78759z"
        />
        <path
          fill={colors[7]}
          d="M69.9343033,66.78759C69.9343033,66.78759,69.9343033,66.9085693,69.9343033,66.78759 C69.9343033,66.9085693,69.9343033,66.9085693,69.9343033,66.78759 C69.9343033,66.9085693,69.9343033,66.9085693,69.9343033,66.78759 C69.9343033,66.9085693,69.9343033,66.78759,69.9343033,66.78759z"
        />
        <path
          fill={colors[7]}
          d="M69.9343033,66.9085693L69.9343033,66.9085693 C69.9343033,66.78759,69.9343033,66.78759,69.9343033,66.9085693 C69.9343033,66.78759,69.9343033,66.9085693,69.9343033,66.9085693L69.9343033,66.9085693z"
        />
        <path
          fill={colors[7]}
          d="M69.9343033,66.9085693L69.9343033,66.9085693 C69.9343033,66.78759,69.9343033,66.78759,69.9343033,66.9085693 C69.9343033,66.78759,69.9343033,66.9085693,69.9343033,66.9085693L69.9343033,66.9085693z"
        />
        <path
          fill={colors[7]}
          d="M69.9343033,66.9085693L69.9343033,66.9085693 C69.9343033,66.78759,69.9343033,66.78759,69.9343033,66.9085693 C69.9343033,66.78759,69.9343033,66.9085693,69.9343033,66.9085693L69.9343033,66.9085693z"
        />
        <path
          fill={colors[7]}
          d="M72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759z"
        />
        <path
          fill={colors[7]}
          d="M72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,67.8764572L71.2651443,67.8764572 L71.2651443,67.8764572z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,67.8764572L71.2651443,67.8764572 L71.2651443,67.8764572z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.0231705,67.9974442L71.0231705,67.9974442 L71.0231705,67.9974442z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.0231705,67.9974442L71.0231705,67.9974442 L71.0231705,67.9974442z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.0231705,67.9974442L71.0231705,67.9974442 L71.0231705,67.9974442z"
        />
        <path
          fill={colors[7]}
          d="M72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759z"
        />
        <path
          fill={colors[7]}
          d="M72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759z"
        />
        <path
          fill={colors[7]}
          d="M72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759z"
        />
        <path
          fill={colors[4]}
          d="M72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5959854,66.78759L72.5959854,66.78759 L72.5959854,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5959854,66.78759L72.5959854,66.78759 L72.5959854,66.78759z"
        />
        <path
          fill={colors[7]}
          d="M72.4749985,66.6666031L72.4749985,66.6666031L72.4749985,66.6666031z"
        />
        <path
          fill={colors[7]}
          d="M72.4749985,66.6666031L72.4749985,66.6666031L72.4749985,66.6666031z"
        />
        <path
          fill={colors[7]}
          d="M72.4749985,66.6666031L72.4749985,66.6666031L72.4749985,66.6666031z"
        />
        <path
          fill={colors[3]}
          d="M72.5959854,66.78759L72.5959854,66.78759L72.5959854,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5959854,66.78759L72.5959854,66.78759 L72.5959854,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5959854,66.78759L72.5959854,66.78759 L72.5959854,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5959854,66.78759L72.5959854,66.78759 L72.5959854,66.78759z"
        />
        <path
          fill={colors[4]}
          d="M72.2330322,66.78759L72.2330322,66.78759L72.2330322,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,66.78759L72.2330322,66.78759 L72.2330322,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,65.6987152L71.3861237,65.6987152 L71.3861237,65.6987152z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,65.6987152L71.3861237,65.6987152 L71.3861237,65.6987152z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,65.6987152L71.3861237,65.6987152 L71.3861237,65.6987152z"
        />
        <path
          fill={colors[4]}
          d="M71.2651443,65.8197021L71.2651443,65.8197021L71.2651443,65.8197021z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,65.8197021L71.2651443,65.8197021 L71.2651443,65.8197021z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,65.8197021L71.2651443,65.8197021 L71.2651443,65.8197021z"
        />
        <path
          fill={colors[7]}
          d="M71.3861237,65.6987152L71.3861237,65.6987152L71.3861237,65.6987152z"
        />
        <path
          fill={colors[7]}
          d="M71.3861237,65.6987152L71.3861237,65.6987152L71.3861237,65.6987152z"
        />
        <path
          fill={colors[4]}
          d="M72.3540115,64.7308273L72.3540115,64.7308273L72.3540115,64.7308273z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,64.7308273L72.3540115,64.7308273 L72.3540115,64.7308273z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5071182,63.3999901L71.5071182,63.3999901 L71.5071182,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5071182,63.3999901L71.5071182,63.3999901 L71.5071182,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5071182,63.3999901L71.5071182,63.3999901 L71.5071182,63.3999901z"
        />
        <path
          fill="#CCC"
          d="M71.3861237,63.5209732L71.3861237,63.5209732L71.3861237,63.5209732z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,63.5209732L71.3861237,63.5209732 L71.3861237,63.5209732z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,63.5209732L71.3861237,63.5209732 L71.3861237,63.5209732z"
        />
        <path
          fill={colors[7]}
          d="M71.5071182,63.3999901L71.5071182,63.3999901L71.5071182,63.3999901L71.5071182,63.3999901 L71.5071182,63.3999901z"
        />
        <path
          fill={colors[7]}
          d="M71.5071182,63.3999901L71.5071182,63.3999901L71.5071182,63.3999901L71.5071182,63.3999901 L71.5071182,63.3999901z"
        />
        <path
          fill={colors[4]}
          d="M71.3861237,61.3432312L71.3861237,61.3432312L71.3861237,61.3432312z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,61.3432312L71.3861237,61.3432312 L71.3861237,61.3432312z"
        />
        <path
          fill={colors[4]}
          d="M71.2651443,61.2222481L71.2651443,61.2222481L71.2651443,61.2222481z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,61.2222481L71.2651443,61.2222481 L71.2651443,61.2222481z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,61.2222481L71.2651443,61.2222481 L71.2651443,61.2222481z"
        />
        <path
          fill={colors[7]}
          d="M71.1441574,61.1012611L71.1441574,61.1012611L71.1441574,61.1012611L71.1441574,61.1012611 L71.1441574,61.1012611z"
        />
        <path
          fill={colors[7]}
          d="M71.1441574,61.1012611L71.1441574,61.1012611L71.1441574,61.1012611L71.1441574,61.1012611 L71.1441574,61.1012611z"
        />
        <path
          fill="#CCC"
          d="M71.1441574,61.1012611L71.1441574,61.1012611L71.1441574,61.1012611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,61.1012611L71.1441574,61.1012611 L71.1441574,61.1012611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,60.8592873L71.2651443,60.8592873 L71.2651443,60.8592873z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,60.8592873L71.2651443,60.8592873 L71.2651443,60.8592873z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,60.8592873L71.2651443,60.8592873 L71.2651443,60.8592873z"
        />
        <path
          fill={colors[3]}
          d="M71.5071182,61.1012611L71.5071182,61.1012611L71.5071182,61.1012611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5071182,61.1012611L71.5071182,61.1012611 L71.5071182,61.1012611z"
        />
        <path
          fill={colors[3]}
          d="M70.5392303,60.1333771L70.5392303,60.1333771l0.725914,0.7259102l0,0l0,0l0,0v-1.451828 c0,0,0,0-0.1209869,0v1.3308411L70.5392303,60.1333771z"
        />
        <path
          fill={colors[4]}
          d="M71.3861237,60.7383041v-1.2098579c0,0,0,0-0.1209793,0v1.4518318l0.3629608-0.362957l0.3629608-0.362957 l0,0L71.3861237,60.7383041z"
        />
        <path
          fill="#CCC"
          d="M71.3861237,59.5284462v1.2098579l0.6049271-0.4839439v-0.1209831 C71.9910507,59.8914032,71.8700714,59.6494331,71.3861237,59.5284462z"
        />
        <path
          fill={colors[4]}
          d="M72.3540115,62.3111191L72.3540115,62.3111191L72.3540115,62.3111191z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,62.3111191L72.3540115,62.3111191 L72.3540115,62.3111191z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,62.3111191L72.3540115,62.3111191 L72.3540115,62.3111191z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,62.0691452L72.3540115,62.0691452 L72.3540115,62.0691452z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,62.0691452L72.3540115,62.0691452 L72.3540115,62.0691452z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,62.0691452L72.3540115,62.0691452 L72.3540115,62.0691452z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,62.0691452L72.3540115,62.0691452 L72.3540115,62.0691452z"
        />
        <path
          fill={colors[4]}
          d="M72.3540115,62.1901321L72.3540115,62.1901321L72.3540115,62.1901321z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,62.1901321L72.3540115,62.1901321 L72.3540115,62.1901321z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,62.1901321L72.3540115,62.1901321 L72.3540115,62.1901321z"
        />
        <path
          fill={colors[7]}
          d="M71.1441574,63.3999901L71.1441574,63.3999901L71.1441574,63.3999901L71.1441574,63.3999901 L71.1441574,63.3999901z"
        />
        <path
          fill={colors[7]}
          d="M71.1441574,63.3999901L71.1441574,63.3999901L71.1441574,63.3999901L71.1441574,63.3999901 L71.1441574,63.3999901z"
        />
        <path
          fill={colors[7]}
          d="M71.1441574,63.3999901L71.1441574,63.3999901L71.1441574,63.3999901L71.1441574,63.3999901 L71.1441574,63.3999901z"
        />
        <path
          fill={colors[4]}
          d="M71.1441574,63.5209732L71.1441574,63.5209732L71.1441574,63.5209732z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,63.5209732L71.1441574,63.5209732 L71.1441574,63.5209732z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,63.5209732L71.1441574,63.5209732 L71.1441574,63.5209732z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,63.1580162L71.2651443,63.1580162 L71.2651443,63.1580162z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,63.1580162L71.2651443,63.1580162 L71.2651443,63.1580162z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,63.1580162L71.2651443,63.1580162 L71.2651443,63.1580162z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,63.3999901L71.1441574,63.3999901 L71.1441574,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,63.3999901L71.1441574,63.3999901 L71.1441574,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,63.3999901L71.1441574,63.3999901 L71.1441574,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,63.3999901L71.1441574,63.3999901 L71.1441574,63.3999901z"
        />
        <path
          fill="#CCC"
          d="M71.3861237,63.5209732L71.3861237,63.5209732L71.3861237,63.5209732z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,63.5209732L71.3861237,63.5209732 L71.3861237,63.5209732z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,63.5209732L71.3861237,63.5209732 L71.3861237,63.5209732z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,63.5209732L71.3861237,63.5209732 L71.3861237,63.5209732z"
        />
        <path
          fill={colors[4]}
          d="M70.4182434,62.3111191l0.2419662,0.1209831L70.4182434,62.3111191z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.4182434,62.3111191l0.2419662,0.1209831 L70.4182434,62.3111191z"
        />
        <path
          fill={colors[4]}
          d="M69.4503555,61.3432312L69.4503555,61.3432312L69.4503555,61.3432312z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.4503555,61.3432312L69.4503555,61.3432312 L69.4503555,61.3432312z"
        />
        <path
          fill={colors[7]}
          d="M69.9343033,62.1901321C69.9343033,62.1901321,69.9343033,62.3111191,69.9343033,62.1901321 C69.9343033,62.3111191,69.9343033,62.3111191,69.9343033,62.1901321 C69.9343033,62.3111191,69.9343033,62.3111191,69.9343033,62.1901321L69.9343033,62.1901321z"
        />
        <path
          fill={colors[7]}
          d="M69.9343033,62.1901321C69.9343033,62.1901321,69.9343033,62.3111191,69.9343033,62.1901321 C69.9343033,62.3111191,69.9343033,62.3111191,69.9343033,62.1901321 C69.9343033,62.3111191,69.9343033,62.3111191,69.9343033,62.1901321L69.9343033,62.1901321z"
        />
        <path
          fill={colors[7]}
          d="M69.9343033,62.1901321C69.9343033,62.1901321,69.9343033,62.3111191,69.9343033,62.1901321 C69.9343033,62.3111191,69.9343033,62.3111191,69.9343033,62.1901321 C69.9343033,62.3111191,69.9343033,62.3111191,69.9343033,62.1901321L69.9343033,62.1901321z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,62.0691452L70.0552826,62.0691452 L70.0552826,62.0691452z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,62.0691452L70.0552826,62.0691452 L70.0552826,62.0691452z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,62.0691452L70.0552826,62.0691452 L70.0552826,62.0691452z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,62.1901321L69.9343033,62.1901321 L69.9343033,62.1901321z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,62.1901321L69.9343033,62.1901321 L69.9343033,62.1901321z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,62.1901321L69.9343033,62.1901321 L69.9343033,62.1901321z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,62.1901321L69.9343033,62.1901321 L69.9343033,62.1901321z"
        />
        <path
          fill={colors[4]}
          d="M70.1762695,62.0691452L70.1762695,62.0691452L70.1762695,62.0691452z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,62.0691452L70.1762695,62.0691452 L70.1762695,62.0691452z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,62.0691452L70.1762695,62.0691452 L70.1762695,62.0691452z"
        />
        <path
          fill={colors[4]}
          d="M72.4749985,64.7308273L72.4749985,64.7308273L72.4749985,64.7308273z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,64.7308273L72.4749985,64.7308273 L72.4749985,64.7308273z"
        />
        <path
          fill="#CCC"
          d="M72.4749985,64.609848L72.4749985,64.609848L72.4749985,64.609848z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,64.609848L72.4749985,64.609848 L72.4749985,64.609848z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,64.609848L72.4749985,64.609848 L72.4749985,64.609848z"
        />
        <path
          fill={colors[4]}
          d="M72.3540115,64.609848L72.3540115,64.609848L72.3540115,64.609848z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,64.609848L72.3540115,64.609848 L72.3540115,64.609848z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3540115,64.609848L72.3540115,64.609848 L72.3540115,64.609848z"
        />
        <path
          fill={colors[4]}
          d="M72.2330322,64.4888611L72.2330322,64.4888611L72.2330322,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,64.4888611L72.2330322,64.4888611 L72.2330322,64.4888611z"
        />
        <path
          fill="#CCC"
          d="M72.2330322,64.4888611L72.2330322,64.4888611L72.2330322,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,64.4888611L72.2330322,64.4888611 L72.2330322,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,64.4888611L72.2330322,64.4888611 L72.2330322,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,64.4888611L72.2330322,64.4888611 L72.2330322,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,64.4888611L72.2330322,64.4888611 L72.2330322,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,64.4888611L72.2330322,64.4888611 L72.2330322,64.4888611z"
        />
        <path
          fill="#CCC"
          d="M72.4749985,64.609848L72.4749985,64.609848L72.4749985,64.609848z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,64.609848L72.4749985,64.609848 L72.4749985,64.609848z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,64.609848L72.4749985,64.609848 L72.4749985,64.609848z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4749985,64.609848L72.4749985,64.609848 L72.4749985,64.609848z"
        />
        <path
          fill={colors[4]}
          d="M72.2330322,64.4888611L72.2330322,64.4888611L72.2330322,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,64.4888611L72.2330322,64.4888611 L72.2330322,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.2330322,64.4888611L72.2330322,64.4888611 L72.2330322,64.4888611z"
        />
        <path
          fill={colors[4]}
          d="M68.8454285,65.8197021L68.8454285,65.8197021L68.8454285,65.8197021z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,65.8197021L68.8454285,65.8197021 L68.8454285,65.8197021z"
        />
        <path
          fill={colors[7]}
          d="M70.0552826,66.6666031L70.0552826,66.6666031L70.0552826,66.6666031L70.0552826,66.6666031 L70.0552826,66.6666031z"
        />
        <path
          fill={colors[7]}
          d="M70.0552826,66.6666031L70.0552826,66.6666031L70.0552826,66.6666031L70.0552826,66.6666031 L70.0552826,66.6666031z"
        />
        <path
          fill={colors[7]}
          d="M70.0552826,66.6666031L70.0552826,66.6666031L70.0552826,66.6666031L70.0552826,66.6666031 L70.0552826,66.6666031z"
        />
        <path
          fill={colors[4]}
          d="M69.9343033,66.78759L69.9343033,66.78759L69.9343033,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,66.78759L69.9343033,66.78759 L69.9343033,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,66.78759L69.9343033,66.78759 L69.9343033,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,66.78759L69.9343033,66.78759 L69.9343033,66.78759z"
        />
        <path
          fill="#CCC"
          d="M70.0552826,67.0295563L70.0552826,67.0295563L70.0552826,67.0295563z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,67.0295563L70.0552826,67.0295563 L70.0552826,67.0295563z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,67.0295563L70.0552826,67.0295563 L70.0552826,67.0295563z"
        />
        <path
          fill={colors[3]}
          d="M70.2972641,66.78759L70.2972641,66.78759L70.2972641,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.2972641,66.78759L70.2972641,66.78759 L70.2972641,66.78759z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.0231705,65.6987152L71.0231705,65.6987152 L71.0231705,65.6987152z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.0231705,65.6987152L71.0231705,65.6987152 L71.0231705,65.6987152z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.0231705,65.6987152L71.0231705,65.6987152 L71.0231705,65.6987152z"
        />
        <path
          fill={colors[7]}
          d="M71.2651443,65.9406891C71.2651443,65.9406891,71.2651443,65.8197021,71.2651443,65.9406891 C71.2651443,65.8197021,71.2651443,65.8197021,71.2651443,65.9406891 C71.2651443,65.8197021,71.2651443,65.8197021,71.2651443,65.9406891 C71.2651443,65.8197021,71.2651443,65.9406891,71.2651443,65.9406891z"
        />
        <path
          fill={colors[7]}
          d="M71.2651443,65.9406891C71.2651443,65.9406891,71.2651443,65.8197021,71.2651443,65.9406891 C71.2651443,65.8197021,71.2651443,65.8197021,71.2651443,65.9406891 C71.2651443,65.8197021,71.2651443,65.8197021,71.2651443,65.9406891 C71.2651443,65.8197021,71.2651443,65.9406891,71.2651443,65.9406891z"
        />
        <path
          fill={colors[7]}
          d="M71.2651443,65.9406891C71.2651443,65.9406891,71.2651443,65.8197021,71.2651443,65.9406891 C71.2651443,65.8197021,71.2651443,65.8197021,71.2651443,65.9406891 C71.2651443,65.8197021,71.2651443,65.8197021,71.2651443,65.9406891 C71.2651443,65.8197021,71.2651443,65.9406891,71.2651443,65.9406891z"
        />
        <path
          fill={colors[4]}
          d="M71.1441574,65.8197021L71.1441574,65.8197021L71.1441574,65.8197021z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,65.8197021L71.1441574,65.8197021 L71.1441574,65.8197021z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.1441574,65.8197021L71.1441574,65.8197021 L71.1441574,65.8197021z"
        />
        <path
          fill={colors[7]}
          d="M71.1441574,65.456749L71.1441574,65.456749 C71.2651443,65.456749,71.2651443,65.456749,71.1441574,65.456749 C71.2651443,65.456749,71.2651443,65.456749,71.1441574,65.456749L71.1441574,65.456749z"
        />
        <path
          fill={colors[7]}
          d="M71.1441574,65.456749L71.1441574,65.456749 C71.2651443,65.456749,71.2651443,65.456749,71.1441574,65.456749 C71.2651443,65.456749,71.2651443,65.456749,71.1441574,65.456749L71.1441574,65.456749z"
        />
        <path
          fill={colors[7]}
          d="M71.1441574,65.456749L71.1441574,65.456749 C71.2651443,65.456749,71.2651443,65.456749,71.1441574,65.456749 C71.2651443,65.456749,71.2651443,65.456749,71.1441574,65.456749L71.1441574,65.456749z"
        />
        <path
          fill={colors[3]}
          d="M71.3861237,65.6987152L71.3861237,65.6987152L71.3861237,65.6987152z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,65.6987152L71.3861237,65.6987152 L71.3861237,65.6987152z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.3861237,65.6987152L71.3861237,65.6987152 L71.3861237,65.6987152z"
        />
        <path
          fill={colors[4]}
          d="M71.2651443,65.456749L71.2651443,65.456749L71.2651443,65.456749z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.2651443,65.456749L71.2651443,65.456749 L71.2651443,65.456749z"
        />
        <path
          fill={colors[4]}
          d="M70.2972641,64.4888611l0.3629608,0.3629532L70.2972641,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.2972641,64.4888611l0.3629608,0.3629532 L70.2972641,64.4888611z"
        />
        <path
          fill={colors[7]}
          d="M70.2972641,64.4888611L70.2972641,64.4888611 C70.2972641,64.4888611,70.2972641,64.609848,70.2972641,64.4888611 C70.2972641,64.609848,70.2972641,64.609848,70.2972641,64.4888611L70.2972641,64.4888611z"
        />
        <path
          fill={colors[7]}
          d="M70.2972641,64.4888611L70.2972641,64.4888611 C70.2972641,64.4888611,70.2972641,64.609848,70.2972641,64.4888611 C70.2972641,64.609848,70.2972641,64.609848,70.2972641,64.4888611L70.2972641,64.4888611z"
        />
        <path
          fill={colors[7]}
          d="M70.2972641,64.4888611L70.2972641,64.4888611 C70.2972641,64.4888611,70.2972641,64.609848,70.2972641,64.4888611 C70.2972641,64.609848,70.2972641,64.609848,70.2972641,64.4888611L70.2972641,64.4888611z"
        />
        <path
          fill={colors[4]}
          d="M69.2083893,63.3999901l0.3629608,0.362957L69.2083893,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.2083893,63.3999901l0.3629608,0.362957 L69.2083893,63.3999901z"
        />
        <path
          fill={colors[3]}
          d="M69.2083893,63.3999901L69.2083893,63.3999901L69.2083893,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.2083893,63.3999901L69.2083893,63.3999901 L69.2083893,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.2083893,63.3999901L69.2083893,63.3999901 L69.2083893,63.3999901z"
        />
        <path
          fill={colors[3]}
          d="M68.9664154,63.1580162L68.9664154,63.1580162L68.9664154,63.1580162z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,63.1580162L68.9664154,63.1580162 L68.9664154,63.1580162z"
        />
        <path
          fill={colors[4]}
          d="M68.9664154,63.1580162L68.9664154,63.1580162L68.9664154,63.1580162z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,63.1580162L68.9664154,63.1580162 L68.9664154,63.1580162z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,65.456749L68.8454285,65.456749 L68.8454285,65.456749z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,65.456749L68.8454285,65.456749 L68.8454285,65.456749z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,65.456749L68.8454285,65.456749 L68.8454285,65.456749z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,65.456749L68.9664154,65.456749 L68.9664154,65.456749z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,65.456749L68.9664154,65.456749 L68.9664154,65.456749z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,65.456749L68.9664154,65.456749 L68.9664154,65.456749z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,64.4888611L69.9343033,64.4888611 L69.9343033,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,64.4888611L69.9343033,64.4888611 L69.9343033,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9343033,64.4888611L69.9343033,64.4888611 L69.9343033,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,64.2468872L70.0552826,64.2468872 L70.0552826,64.2468872z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,64.2468872L70.0552826,64.2468872 L70.0552826,64.2468872z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.0552826,64.2468872L70.0552826,64.2468872 L70.0552826,64.2468872z"
        />
        <path
          fill={colors[3]}
          d="M70.2972641,64.4888611L70.2972641,64.4888611L70.2972641,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.2972641,64.4888611L70.2972641,64.4888611 L70.2972641,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.2972641,64.4888611L70.2972641,64.4888611 L70.2972641,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.2972641,64.4888611L70.2972641,64.4888611 L70.2972641,64.4888611z"
        />
        <path
          fill={colors[3]}
          d="M69.0874023,65.6987152L69.0874023,65.6987152L69.0874023,65.6987152z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,65.6987152L69.0874023,65.6987152 L69.0874023,65.6987152z"
        />
        <path
          fill="#CCC"
          d="M70.1762695,64.609848L70.1762695,64.609848L70.1762695,64.609848z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,64.609848L70.1762695,64.609848 L70.1762695,64.609848z"
        />
        <path
          fill={colors[4]}
          d="M67.6355743,64.3678741L67.6355743,64.3678741L67.6355743,64.3678741z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6355743,64.3678741L67.6355743,64.3678741 L67.6355743,64.3678741z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6355743,64.3678741L67.6355743,64.3678741 L67.6355743,64.3678741z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,63.1580162L68.8454285,63.1580162 L68.8454285,63.1580162z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,63.1580162L68.8454285,63.1580162 L68.8454285,63.1580162z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8454285,63.1580162L68.8454285,63.1580162 L68.8454285,63.1580162z"
        />
        <path
          fill={colors[3]}
          d="M69.0874023,63.3999901L69.0874023,63.3999901L69.0874023,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,63.3999901L69.0874023,63.3999901 L69.0874023,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,63.3999901L69.0874023,63.3999901 L69.0874023,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,63.3999901L69.0874023,63.3999901 L69.0874023,63.3999901z"
        />
        <path
          fill={colors[3]}
          d="M67.9985352,64.4888611L67.9985352,64.4888611L67.9985352,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9985352,64.4888611L67.9985352,64.4888611 L67.9985352,64.4888611z"
        />
        <path
          fill="#CCC"
          d="M68.9664154,63.5209732L68.9664154,63.5209732L68.9664154,63.5209732z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9664154,63.5209732L68.9664154,63.5209732 L68.9664154,63.5209732z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.4899445,72.715889L64.4899445,72.715889 L64.4899445,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.4899445,72.715889L64.4899445,72.715889 L64.4899445,72.715889z"
        />
        <path
          fill={colors[3]}
          d="M64.4899445,72.715889L64.4899445,72.715889L64.4899445,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.4899445,72.715889L64.4899445,72.715889 L64.4899445,72.715889z"
        />
        <path
          fill={colors[4]}
          d="M64.4899445,72.715889L64.4899445,72.715889L64.4899445,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.4899445,72.715889L64.4899445,72.715889 L64.4899445,72.715889z"
        />
        <path
          fill={colors[4]}
          d="M64.2479782,72.4739075L64.2479782,72.4739075L64.2479782,72.4739075z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.2479782,72.4739075L64.2479782,72.4739075 L64.2479782,72.4739075z"
        />
        <path
          fill={colors[3]}
          d="M63.0381203,73.5627823L63.0381203,73.5627823L63.0381203,73.5627823z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.2158585,73.8047638L65.2158585,73.8047638 L65.2158585,73.8047638z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.2158585,73.8047638L65.2158585,73.8047638 L65.2158585,73.8047638z"
        />
        <path
          fill={colors[7]}
          d="M64.1269836,72.715889L64.1269836,72.715889L64.1269836,72.715889L64.1269836,72.715889 L64.1269836,72.715889z"
        />
        <path
          fill={colors[7]}
          d="M64.1269836,72.715889L64.1269836,72.715889L64.1269836,72.715889L64.1269836,72.715889 L64.1269836,72.715889z"
        />
        <path
          fill={colors[7]}
          d="M64.1269836,72.715889L64.1269836,72.715889L64.1269836,72.715889L64.1269836,72.715889 L64.1269836,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.1269836,72.715889L64.1269836,72.715889 L64.1269836,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.1269836,72.715889L64.1269836,72.715889 L64.1269836,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.1269836,72.715889L64.1269836,72.715889 L64.1269836,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.1269836,72.715889L64.1269836,72.715889 L64.1269836,72.715889z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.2479782,74.8936234L64.2479782,74.8936234 L64.2479782,74.8936234z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.2479782,74.8936234L64.2479782,74.8936234 L64.2479782,74.8936234z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M63.0381203,73.6837692L63.0381203,73.6837692 L63.0381203,73.6837692z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M63.0381203,73.6837692L63.0381203,73.6837692 L63.0381203,73.6837692z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M63.0381203,73.6837692L63.0381203,73.6837692 L63.0381203,73.6837692z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M63.0381203,73.6837692L63.0381203,73.6837692 L63.0381203,73.6837692z"
        />
        <path
          fill="#CCC"
          d="M63.0381203,73.6837692L63.0381203,73.6837692L63.0381203,73.6837692z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M63.0381203,73.6837692L63.0381203,73.6837692 L63.0381203,73.6837692z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M63.0381203,73.6837692L63.0381203,73.6837692 L63.0381203,73.6837692z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M63.0381203,73.6837692L63.0381203,73.6837692 L63.0381203,73.6837692z"
        />
        <path
          fill={colors[3]}
          d="M63.0381203,73.6837692L63.0381203,73.6837692L63.0381203,73.6837692z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M63.0381203,73.6837692L63.0381203,73.6837692 L63.0381203,73.6837692z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M63.0381203,73.6837692L63.0381203,73.6837692 L63.0381203,73.6837692z"
        />
        <path
          fill={colors[3]}
          d="M64.7319107,65.9406891L64.7319107,65.9406891L64.7319107,65.9406891z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.7319107,65.9406891L64.7319107,65.9406891 L64.7319107,65.9406891z"
        />
        <path
          fill={colors[7]}
          d="M64.7319107,65.9406891L64.7319107,65.9406891L64.7319107,65.9406891L64.7319107,65.9406891 L64.7319107,65.9406891z"
        />
        <path
          fill={colors[7]}
          d="M64.7319107,65.9406891L64.7319107,65.9406891L64.7319107,65.9406891L64.7319107,65.9406891 L64.7319107,65.9406891z"
        />
        <path
          fill={colors[7]}
          d="M65.9417725,64.7308273C65.9417725,64.7308273,65.9417725,64.8518219,65.9417725,64.7308273 C65.9417725,64.8518219,65.9417725,64.8518219,65.9417725,64.7308273 C65.9417725,64.8518219,65.9417725,64.8518219,65.9417725,64.7308273 C65.9417725,64.8518219,65.9417725,64.7308273,65.9417725,64.7308273z"
        />
        <path
          fill={colors[7]}
          d="M65.9417725,64.7308273C65.9417725,64.7308273,65.9417725,64.8518219,65.9417725,64.7308273 C65.9417725,64.8518219,65.9417725,64.8518219,65.9417725,64.7308273 C65.9417725,64.8518219,65.9417725,64.8518219,65.9417725,64.7308273 C65.9417725,64.8518219,65.9417725,64.7308273,65.9417725,64.7308273z"
        />
        <path
          fill={colors[7]}
          d="M64.3689651,65.9406891L64.3689651,65.9406891L64.3689651,65.9406891z"
        />
        <path
          fill={colors[7]}
          d="M64.3689651,65.9406891L64.3689651,65.9406891L64.3689651,65.9406891z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,63.641964L67.0306396,63.641964 L67.0306396,63.641964z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,63.641964L67.0306396,63.641964 L67.0306396,63.641964z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,63.641964L67.0306396,63.641964 L67.0306396,63.641964z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,64.4888611L65.4578323,64.4888611 L65.4578323,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,64.4888611L65.4578323,64.4888611 L65.4578323,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.4578323,64.4888611L65.4578323,64.4888611 L65.4578323,64.4888611z"
        />
        <path
          fill={colors[3]}
          d="M65.5788193,64.4888611L65.5788193,64.4888611L65.5788193,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,64.4888611L65.5788193,64.4888611 L65.5788193,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,64.4888611L65.5788193,64.4888611 L65.5788193,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,64.4888611L65.5788193,64.4888611 L65.5788193,64.4888611z"
        />
        <path
          fill={colors[4]}
          d="M65.5788193,64.4888611L65.5788193,64.4888611L65.5788193,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,64.4888611L65.5788193,64.4888611 L65.5788193,64.4888611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,64.4888611L65.5788193,64.4888611 L65.5788193,64.4888611z"
        />
        <path
          fill={colors[7]}
          d="M65.4578323,64.8518219L65.4578323,64.8518219L65.4578323,64.8518219L65.4578323,64.8518219 L65.4578323,64.8518219z"
        />
        <path
          fill={colors[7]}
          d="M65.4578323,64.8518219L65.4578323,64.8518219L65.4578323,64.8518219L65.4578323,64.8518219 L65.4578323,64.8518219z"
        />
        <path
          fill={colors[7]}
          d="M68.2405014,62.432106L68.2405014,62.432106L68.2405014,62.432106L68.2405014,62.432106 L68.2405014,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M68.2405014,62.432106L68.2405014,62.432106L68.2405014,62.432106L68.2405014,62.432106 L68.2405014,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,63.2790031L66.6676941,63.2790031 L66.6676941,63.2790031z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,63.2790031L66.6676941,63.2790031 L66.6676941,63.2790031z"
        />
        <path
          fill={colors[3]}
          d="M68.2405014,62.432106L68.2405014,62.432106L68.2405014,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.2405014,62.432106L68.2405014,62.432106 L68.2405014,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.2405014,62.432106L68.2405014,62.432106 L68.2405014,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M68.2405014,62.432106L68.2405014,62.432106L68.2405014,62.432106L68.2405014,62.432106 L68.2405014,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M68.2405014,62.432106L68.2405014,62.432106L68.2405014,62.432106L68.2405014,62.432106 L68.2405014,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M68.2405014,62.432106L68.2405014,62.432106L68.2405014,62.432106L68.2405014,62.432106 L68.2405014,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M65.4578323,62.432106L65.4578323,62.432106L65.4578323,62.432106L65.4578323,62.432106 L65.4578323,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M65.4578323,62.432106L65.4578323,62.432106L65.4578323,62.432106L65.4578323,62.432106 L65.4578323,62.432106z"
        />
        <path
          fill={colors[3]}
          d="M66.6676941,63.3999901L66.6676941,63.3999901L66.6676941,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,63.3999901L66.6676941,63.3999901 L66.6676941,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,63.3999901L66.6676941,63.3999901 L66.6676941,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,63.3999901L66.6676941,63.3999901 L66.6676941,63.3999901z"
        />
        <path
          fill={colors[4]}
          d="M66.6676941,63.3999901L66.6676941,63.3999901L66.6676941,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,63.3999901L66.6676941,63.3999901 L66.6676941,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,63.3999901L66.6676941,63.3999901 L66.6676941,63.3999901z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,63.3999901L66.6676941,63.3999901 L66.6676941,63.3999901z"
        />
        <path
          fill={colors[4]}
          d="M66.6676941,63.1580162L66.6676941,63.1580162L66.6676941,63.1580162z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,63.1580162L66.6676941,63.1580162 L66.6676941,63.1580162z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6676941,63.1580162L66.6676941,63.1580162 L66.6676941,63.1580162z"
        />
        <path
          fill={colors[7]}
          d="M65.5788193,62.432106L65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106L65.5788193,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M65.5788193,62.432106L65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106L65.5788193,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M65.5788193,62.432106L65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106L65.5788193,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M65.5788193,62.432106L65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106L65.5788193,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M65.5788193,62.432106L65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106L65.5788193,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M65.5788193,62.432106L65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106 C65.5788193,62.5530891,65.5788193,62.5530891,65.5788193,62.432106L65.5788193,62.432106z"
        />
        <path
          fill={colors[7]}
          d="M69.4503555,61.3432312C69.4503555,61.3432312,69.4503555,61.2222481,69.4503555,61.3432312 C69.4503555,61.2222481,69.4503555,61.2222481,69.4503555,61.3432312 C69.4503555,61.2222481,69.4503555,61.3432312,69.4503555,61.3432312L69.4503555,61.3432312z"
        />
        <path
          fill={colors[7]}
          d="M69.4503555,61.3432312C69.4503555,61.3432312,69.4503555,61.2222481,69.4503555,61.3432312 C69.4503555,61.2222481,69.4503555,61.2222481,69.4503555,61.3432312 C69.4503555,61.2222481,69.4503555,61.3432312,69.4503555,61.3432312L69.4503555,61.3432312z"
        />
        <path
          fill={colors[7]}
          d="M69.4503555,61.3432312C69.4503555,61.3432312,69.4503555,61.2222481,69.4503555,61.3432312 C69.4503555,61.2222481,69.4503555,61.2222481,69.4503555,61.3432312 C69.4503555,61.2222481,69.4503555,61.3432312,69.4503555,61.3432312L69.4503555,61.3432312z"
        />
        <path
          fill={colors[4]}
          d="M67.8775482,62.432106L67.8775482,62.432106L67.8775482,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,62.432106L67.8775482,62.432106 L67.8775482,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,61.3432312L67.0306396,61.3432312 L67.0306396,61.3432312z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,61.3432312L67.0306396,61.3432312 L67.0306396,61.3432312z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,61.3432312L67.0306396,61.3432312 L67.0306396,61.3432312z"
        />
        <path
          fill={colors[4]}
          d="M66.9096603,61.4642181L66.9096603,61.4642181L66.9096603,61.4642181z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,61.4642181L66.9096603,61.4642181 L66.9096603,61.4642181z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,61.4642181L66.9096603,61.4642181 L66.9096603,61.4642181z"
        />
        <path
          fill={colors[7]}
          d="M67.0306396,61.3432312L67.0306396,61.3432312L67.0306396,61.3432312z"
        />
        <path
          fill={colors[7]}
          d="M67.0306396,61.3432312L67.0306396,61.3432312L67.0306396,61.3432312z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9985352,60.012394L67.9985352,60.012394 l-0.8469009-0.8469009c0,0,0,0-0.1209793,0L67.9985352,60.012394L67.9985352,60.012394z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9985352,60.012394L67.9985352,60.012394 l-0.8469009-0.8469009c0,0,0,0-0.1209793,0L67.9985352,60.012394L67.9985352,60.012394z"
        />
        <path
          fill={colors[4]}
          d="M67.8775482,59.8914032l-0.725914-0.7259102l0,0l0,0L67.8775482,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,59.8914032l-0.725914-0.7259102l0,0l0,0 L67.8775482,59.8914032z"
        />
        <path
          fill={colors[3]}
          d="M67.9985352,59.8914032L67.9985352,59.8914032v-0.8469009c0,0,0,0-0.1209869,0v0.725914 l-0.8468933-0.6049309l0,0L67.9985352,59.8914032L67.9985352,59.8914032z"
        />
        <path
          fill={colors[4]}
          d="M68.4824753,59.5284462l-0.3629608,0.2419739v-0.725914c0,0,0,0-0.1209793,0v0.8469009 L68.4824753,59.5284462z"
        />
        <path
          fill="#CCC"
          d="M68.4824753,59.5284462l0.4839478-0.4839439h-0.725914l0,0v0.725914L68.4824753,59.5284462z"
        />
        <path
          fill={colors[4]}
          d="M67.9985352,59.7704201v-0.725914c-0.2419739,0-0.6049347,0-0.8469009,0.1209869L67.9985352,59.7704201z"
        />
        <path
          fill={colors[4]}
          d="M67.0306396,59.165493L67.0306396,59.165493L67.0306396,59.165493L67.0306396,59.165493 L67.0306396,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,59.165493L67.0306396,59.165493 L67.0306396,59.165493L67.0306396,59.165493L67.0306396,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,59.165493L67.0306396,59.165493 L67.0306396,59.165493L67.0306396,59.165493L67.0306396,59.165493z"
        />
        <path
          fill="#CCC"
          d="M67.0306396,59.165493L67.0306396,59.165493L67.0306396,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,59.165493L67.0306396,59.165493 L67.0306396,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,59.165493L67.0306396,59.165493 L67.0306396,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.9417725,59.8914032l0.6049271-0.6049271l0,0 L65.9417725,59.8914032L65.9417725,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.9417725,59.8914032l0.6049271-0.6049271l0,0 L65.9417725,59.8914032L65.9417725,59.8914032z"
        />
        <path
          fill={colors[3]}
          d="M66.7886734,59.165493c-0.1209793,0-0.1209793,0-0.2419662,0l-0.6049271,0.6049271L66.7886734,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7886734,59.165493c-0.1209793,0-0.1209793,0-0.2419662,0 l-0.6049271,0.6049271L66.7886734,59.165493z"
        />
        <path
          fill={colors[3]}
          d="M66.9096603,59.2864761L66.9096603,59.2864761L66.9096603,59.2864761 C66.9096603,59.165493,66.7886734,59.165493,66.9096603,59.2864761L66.9096603,59.2864761L66.9096603,59.2864761z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.2864761L66.9096603,59.2864761 L66.9096603,59.2864761C66.9096603,59.165493,66.7886734,59.165493,66.9096603,59.2864761L66.9096603,59.2864761 L66.9096603,59.2864761z"
        />
        <path
          fill={colors[4]}
          d="M66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493z"
        />
        <path
          fill={colors[4]}
          d="M65.8207855,59.8914032L65.8207855,59.8914032l0.1209869,0.2419739l0.8468933-0.8469009V59.165493 c0,0,0,0-0.1209869,0L65.8207855,59.8914032L65.8207855,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.8207855,59.8914032L65.8207855,59.8914032 l0.1209869,0.2419739l0.8468933-0.8469009V59.165493c0,0,0,0-0.1209869,0L65.8207855,59.8914032L65.8207855,59.8914032z"
        />
        <path
          fill={colors[3]}
          d="M66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          fill={colors[4]}
          d="M66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493z"
        />
        <path
          fill={colors[3]}
          d="M66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          fill={colors[4]}
          d="M66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,59.165493L66.9096603,59.165493 L66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493L66.9096603,59.165493z"
        />
        <path
          fill={colors[4]}
          d="M67.0306396,59.165493L67.0306396,59.165493L67.0306396,59.165493L67.0306396,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,59.165493L67.0306396,59.165493 L67.0306396,59.165493L67.0306396,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,59.165493L67.0306396,59.165493 L67.0306396,59.165493L67.0306396,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,59.165493L67.0306396,59.165493 L67.0306396,59.165493L67.0306396,59.165493z"
        />
        <path
          fill="#CCC"
          d="M67.0306396,59.165493L67.0306396,59.165493L67.0306396,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,59.165493L67.0306396,59.165493 L67.0306396,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,59.165493L67.0306396,59.165493 L67.0306396,59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,59.165493L67.0306396,59.165493 L67.0306396,59.165493z"
        />
        <path
          fill={colors[3]}
          d="M65.6998062,59.8914032L65.6998062,59.8914032v-0.2419701c0,0,0,0-0.1209869,0v0.1209831h-0.1209869l0,0 L65.6998062,59.8914032L65.6998062,59.8914032z"
        />
        <path
          fill={colors[4]}
          d="M65.8207855,59.8914032L65.8207855,59.8914032L65.8207855,59.8914032l0.725914-0.7259102h-0.1209869 l-0.6049271,0.4839439v-0.2419739c0,0,0,0-0.1209793,0L65.8207855,59.8914032L65.8207855,59.8914032z"
        />
        <path
          fill="#CCC"
          d="M66.4257126,59.2864761c-0.2419662,0.1209831-0.4839401,0.2419701-0.6049271,0.2419701v0.2419739 L66.4257126,59.2864761z"
        />
        <path
          fill={colors[4]}
          d="M65.6998062,59.6494331L65.6998062,59.6494331L65.6998062,59.6494331L65.6998062,59.6494331z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5392303,60.1333771L70.5392303,60.1333771 L70.5392303,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5392303,60.1333771L70.5392303,60.1333771 L70.5392303,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5392303,60.1333771L70.5392303,60.1333771 L70.5392303,60.1333771z"
        />
        <path
          fill={colors[3]}
          d="M70.5392303,60.1333771L70.5392303,60.1333771L70.5392303,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5392303,60.1333771L70.5392303,60.1333771 L70.5392303,60.1333771z"
        />
        <path
          fill={colors[7]}
          d="M70.5392303,60.1333771L70.5392303,60.1333771L70.5392303,60.1333771L70.5392303,60.1333771 L70.5392303,60.1333771z"
        />
        <path
          fill={colors[7]}
          d="M70.5392303,60.1333771L70.5392303,60.1333771L70.5392303,60.1333771L70.5392303,60.1333771 L70.5392303,60.1333771z"
        />
        <path
          fill={colors[4]}
          d="M69.0874023,60.980278L69.0874023,60.980278L69.0874023,60.980278z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,60.980278L69.0874023,60.980278 L69.0874023,60.980278z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,60.980278L69.0874023,60.980278 L69.0874023,60.980278z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,60.980278L69.0874023,60.980278 L69.0874023,60.980278z"
        />
        <path
          fill={colors[4]}
          d="M68.1195145,60.3753471L68.1195145,60.3753471L68.1195145,60.3753471z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1195145,60.3753471L68.1195145,60.3753471 L68.1195145,60.3753471z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771 L67.8775482,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771 L67.8775482,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771 L67.8775482,60.1333771z"
        />
        <path
          fill={colors[4]}
          d="M67.9985352,60.2543602L67.9985352,60.2543602L67.9985352,60.2543602z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9985352,60.2543602L67.9985352,60.2543602 L67.9985352,60.2543602z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9985352,60.2543602L67.9985352,60.2543602 L67.9985352,60.2543602z"
        />
        <path
          fill={colors[4]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771L67.8775482,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771 L67.8775482,60.1333771z"
        />
        <path
          fill="#CCC"
          d="M67.8775482,60.1333771L67.8775482,60.1333771L67.8775482,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771 L67.8775482,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771 L67.8775482,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771 L67.8775482,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771 L67.8775482,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771 L67.8775482,60.1333771z"
        />
        <path
          fill="#CCC"
          d="M68.1195145,60.2543602L68.1195145,60.2543602L68.1195145,60.2543602z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1195145,60.2543602L68.1195145,60.2543602 L68.1195145,60.2543602z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1195145,60.2543602L68.1195145,60.2543602 L68.1195145,60.2543602z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1195145,60.2543602L68.1195145,60.2543602 L68.1195145,60.2543602z"
        />
        <path
          fill={colors[4]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771L67.8775482,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771 L67.8775482,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8775482,60.1333771L67.8775482,60.1333771 L67.8775482,60.1333771z"
        />
        <path
          fill={colors[4]}
          d="M70.4182434,59.8914032l0.1209869,0.2419739l0.725914-0.725914 c-0.1209869,0-0.1209869-0.1209831-0.2419739-0.1209831L70.4182434,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.4182434,59.8914032l0.1209869,0.2419739 l0.725914-0.725914c-0.1209869,0-0.1209869-0.1209831-0.2419739-0.1209831L70.4182434,59.8914032z"
        />
        <path
          fill={colors[3]}
          d="M70.1762695,60.1333771L70.1762695,60.1333771L70.1762695,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,60.1333771L70.1762695,60.1333771 L70.1762695,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,60.1333771L70.1762695,60.1333771 L70.1762695,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,59.7704201L70.1762695,59.7704201 L70.1762695,59.7704201z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,59.7704201L70.1762695,59.7704201 L70.1762695,59.7704201z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,59.7704201L70.1762695,59.7704201 L70.1762695,59.7704201z"
        />
        <path
          fill={colors[3]}
          d="M70.1762695,60.1333771L70.1762695,60.1333771L70.1762695,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,60.1333771L70.1762695,60.1333771 L70.1762695,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,60.1333771L70.1762695,60.1333771 L70.1762695,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,60.1333771L70.1762695,60.1333771 L70.1762695,60.1333771z"
        />
        <path
          fill={colors[3]}
          d="M70.1762695,59.8914032L70.1762695,59.8914032L70.1762695,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,59.8914032L70.1762695,59.8914032 L70.1762695,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,59.8914032L70.1762695,59.8914032 L70.1762695,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,59.8914032L70.1762695,59.8914032 L70.1762695,59.8914032z"
        />
        <path
          fill={colors[4]}
          d="M70.1762695,59.8914032L70.1762695,59.8914032L70.1762695,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,59.8914032L70.1762695,59.8914032 L70.1762695,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,59.8914032L70.1762695,59.8914032 L70.1762695,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1762695,59.8914032L70.1762695,59.8914032 L70.1762695,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,59.0445023L69.0874023,59.0445023 L69.0874023,59.0445023z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,59.0445023L69.0874023,59.0445023 L69.0874023,59.0445023z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,59.0445023L69.0874023,59.0445023 L69.0874023,59.0445023z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.4503555,59.0445023L69.4503555,59.0445023 C69.3293762,59.0445023,69.3293762,59.0445023,69.4503555,59.0445023L69.4503555,59.0445023L69.4503555,59.0445023z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.4503555,59.0445023L69.4503555,59.0445023 C69.3293762,59.0445023,69.3293762,59.0445023,69.4503555,59.0445023L69.4503555,59.0445023L69.4503555,59.0445023z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.4503555,59.0445023L69.4503555,59.0445023 C69.3293762,59.0445023,69.3293762,59.0445023,69.4503555,59.0445023L69.4503555,59.0445023L69.4503555,59.0445023z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.4503555,59.0445023L69.4503555,59.0445023 C69.3293762,59.0445023,69.3293762,59.0445023,69.4503555,59.0445023L69.4503555,59.0445023L69.4503555,59.0445023z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,59.0445023L69.0874023,59.0445023 L69.0874023,59.0445023z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,59.0445023L69.0874023,59.0445023 L69.0874023,59.0445023z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,59.0445023L69.0874023,59.0445023 L69.0874023,59.0445023z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.0874023,59.0445023L69.0874023,59.0445023 L69.0874023,59.0445023z"
        />
        <path
          fill={colors[7]}
          d="M70.1762695,60.1333771L70.1762695,60.1333771L70.1762695,60.1333771L70.1762695,60.1333771 L70.1762695,60.1333771z"
        />
        <path
          fill={colors[7]}
          d="M70.1762695,60.1333771L70.1762695,60.1333771L70.1762695,60.1333771L70.1762695,60.1333771 L70.1762695,60.1333771z"
        />
        <path
          fill={colors[4]}
          d="M69.2083893,59.165493L69.2083893,59.165493L69.2083893,59.165493l0.9678879,0.7259102 l-0.1209869-0.1209831l0,0l-0.725914-0.7259178l0,0l0,0h-0.1209869l0,0V59.165493z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.2083893,59.165493L69.2083893,59.165493 L69.2083893,59.165493l0.9678879,0.7259102l-0.1209869-0.1209831l0,0l-0.725914-0.7259178l0,0l0,0h-0.1209869l0,0V59.165493z"
        />
        <path
          fill={colors[3]}
          d="M69.4503555,59.0445023l0.725914,0.725914l0,0l0.2419662,0.2419701v-0.8469009c0,0,0,0-0.1209869,0 v0.6049309L69.4503555,59.0445023C69.3293762,59.0445023,69.3293762,59.0445023,69.4503555,59.0445023L69.4503555,59.0445023 L69.4503555,59.0445023z"
        />
        <path
          fill={colors[4]}
          d="M70.2972641,60.012394L70.2972641,60.012394L70.2972641,60.012394l0.7259064-0.725914l0,0 l-0.6049271,0.4839439v-0.6049271c0,0,0,0-0.1209793,0V60.012394z"
        />
        <path
          fill="#CCC"
          d="M70.4182434,59.7704201l0.6049271-0.4839439 c-0.1209793-0.1209831-0.3629608-0.1209831-0.6049271-0.1209831V59.7704201z"
        />
        <path
          fill={colors[4]}
          d="M70.2972641,59.7704201V59.165493c-0.2419739,0-0.6049271-0.1209869-0.8469009-0.1209869 L70.2972641,59.7704201z"
        />
        <path
          fill={colors[7]}
          d="M65.6998062,62.3111191L65.6998062,62.3111191L65.6998062,62.3111191L65.6998062,62.3111191 L65.6998062,62.3111191z"
        />
        <path
          fill={colors[7]}
          d="M65.6998062,62.3111191L65.6998062,62.3111191L65.6998062,62.3111191L65.6998062,62.3111191 L65.6998062,62.3111191z"
        />
        <path
          fill={colors[7]}
          d="M65.6998062,62.3111191L65.6998062,62.3111191L65.6998062,62.3111191L65.6998062,62.3111191 L65.6998062,62.3111191z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.9417725,62.432106L65.9417725,62.432106 L65.9417725,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.9417725,62.432106L65.9417725,62.432106 L65.9417725,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.9417725,62.432106L65.9417725,62.432106 L65.9417725,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,62.432106L65.5788193,62.432106 L65.5788193,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,62.432106L65.5788193,62.432106 L65.5788193,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,62.432106L65.5788193,62.432106 L65.5788193,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,62.432106L65.5788193,62.432106 L65.5788193,62.432106z"
        />
        <path
          fill={colors[4]}
          d="M65.5788193,62.432106L65.5788193,62.432106L65.5788193,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,62.432106L65.5788193,62.432106 L65.5788193,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,62.432106L65.5788193,62.432106 L65.5788193,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,62.432106L65.5788193,62.432106 L65.5788193,62.432106z"
        />
        <path
          fill="#CCC"
          d="M65.6998062,62.6740723L65.6998062,62.6740723L65.6998062,62.6740723z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,62.6740723L65.6998062,62.6740723 L65.6998062,62.6740723z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,62.6740723L65.6998062,62.6740723 L65.6998062,62.6740723z"
        />
        <path
          fill={colors[3]}
          d="M65.9417725,62.432106L65.9417725,62.432106L65.9417725,62.432106z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.9417725,62.432106L65.9417725,62.432106 L65.9417725,62.432106z"
        />
        <path
          fill={colors[4]}
          d="M66.9096603,61.4642181L66.9096603,61.4642181L66.9096603,61.4642181z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,61.4642181L66.9096603,61.4642181 L66.9096603,61.4642181z"
        />
        <path
          fill={colors[4]}
          d="M66.9096603,61.4642181L66.9096603,61.4642181L66.9096603,61.4642181z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,61.4642181L66.9096603,61.4642181 L66.9096603,61.4642181z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,61.4642181L66.9096603,61.4642181 L66.9096603,61.4642181z"
        />
        <path
          fill={colors[4]}
          d="M66.7886734,61.4642181L66.7886734,61.4642181L66.7886734,61.4642181z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7886734,61.4642181L66.7886734,61.4642181 L66.7886734,61.4642181z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7886734,61.4642181L66.7886734,61.4642181 L66.7886734,61.4642181z"
        />
        <path
          fill={colors[7]}
          d="M66.9096603,61.1012611C66.9096603,61.1012611,66.7886734,61.1012611,66.9096603,61.1012611 C66.7886734,61.1012611,66.7886734,61.1012611,66.9096603,61.1012611 C66.7886734,61.1012611,66.7886734,61.1012611,66.9096603,61.1012611 C66.7886734,61.1012611,66.9096603,61.1012611,66.9096603,61.1012611z"
        />
        <path
          fill={colors[7]}
          d="M66.9096603,61.1012611C66.9096603,61.1012611,66.7886734,61.1012611,66.9096603,61.1012611 C66.7886734,61.1012611,66.7886734,61.1012611,66.9096603,61.1012611 C66.7886734,61.1012611,66.7886734,61.1012611,66.9096603,61.1012611 C66.7886734,61.1012611,66.9096603,61.1012611,66.9096603,61.1012611z"
        />
        <path
          fill={colors[7]}
          d="M66.9096603,61.1012611C66.9096603,61.1012611,66.7886734,61.1012611,66.9096603,61.1012611 C66.7886734,61.1012611,66.7886734,61.1012611,66.9096603,61.1012611 C66.7886734,61.1012611,66.7886734,61.1012611,66.9096603,61.1012611 C66.7886734,61.1012611,66.9096603,61.1012611,66.9096603,61.1012611z"
        />
        <path
          fill={colors[3]}
          d="M67.0306396,61.3432312L67.0306396,61.3432312L67.0306396,61.3432312z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,61.3432312L67.0306396,61.3432312 L67.0306396,61.3432312z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.0306396,61.3432312L67.0306396,61.3432312 L67.0306396,61.3432312z"
        />
        <path
          fill={colors[4]}
          d="M66.9096603,61.1012611L66.9096603,61.1012611L66.9096603,61.1012611z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9096603,61.1012611L66.9096603,61.1012611 L66.9096603,61.1012611z"
        />
        <path
          fill={colors[7]}
          d="M65.9417725,60.1333771L65.9417725,60.1333771L65.9417725,60.1333771L65.9417725,60.1333771 L65.9417725,60.1333771z"
        />
        <path
          fill={colors[7]}
          d="M65.9417725,60.1333771L65.9417725,60.1333771L65.9417725,60.1333771L65.9417725,60.1333771 L65.9417725,60.1333771z"
        />
        <path
          fill={colors[7]}
          d="M65.9417725,60.1333771L65.9417725,60.1333771L65.9417725,60.1333771L65.9417725,60.1333771 L65.9417725,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,60.012394L65.6998062,60.012394 L65.6998062,60.012394L65.6998062,60.012394L65.6998062,60.012394L65.6998062,60.012394z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,60.012394L65.6998062,60.012394 L65.6998062,60.012394L65.6998062,60.012394L65.6998062,60.012394L65.6998062,60.012394z"
        />
        <path
          fill={colors[3]}
          d="M65.6998062,59.8914032L65.6998062,59.8914032L65.6998062,59.8914032L65.6998062,59.8914032 L65.6998062,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,59.8914032L65.6998062,59.8914032 L65.6998062,59.8914032L65.6998062,59.8914032L65.6998062,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,60.1333771L65.5788193,60.1333771 L65.5788193,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,60.1333771L65.5788193,60.1333771 L65.5788193,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.5788193,60.1333771L65.5788193,60.1333771 L65.5788193,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,59.8914032L65.6998062,59.8914032 L65.6998062,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,59.8914032L65.6998062,59.8914032 L65.6998062,59.8914032z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.6998062,59.8914032L65.6998062,59.8914032 L65.6998062,59.8914032z"
        />
        <path
          fill={colors[3]}
          d="M65.9417725,60.1333771L65.9417725,60.1333771L65.9417725,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.9417725,60.1333771L65.9417725,60.1333771 L65.9417725,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.9417725,60.1333771L65.9417725,60.1333771 L65.9417725,60.1333771z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.9417725,60.1333771L65.9417725,60.1333771 L65.9417725,60.1333771z"
        />
        <path
          fill="#CCC"
          d="M65.8207855,60.2543602L65.8207855,60.2543602L65.8207855,60.2543602z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.8207855,60.2543602L65.8207855,60.2543602 L65.8207855,60.2543602z"
        />
        <path
          fill="#CCC"
          d="M65.5788193,60.1333771L65.5788193,60.1333771L65.5788193,60.1333771L65.5788193,60.1333771v-0.2419739 l0,0C65.4578323,60.012394,65.4578323,60.012394,65.5788193,60.1333771l-0.1209869,0.3629532L65.5788193,60.1333771z"
        />
        <rect
          x="66.183"
          y="62.453"
          transform="rotate(-44.25 66.243 62.937)"
          opacity="0.23"
          fill={colors[6]}
          width="0.121"
          height="0.968"
        />
        <rect
          x="66.183"
          y="62.453"
          transform="rotate(-44.25 66.243 62.937)"
          opacity="0.23"
          fill={colors[6]}
          width="0.121"
          height="0.968"
        />
      </g>
      <path
        fill={colors[8]}
        d="M55.0397797,55.9783859l1.0473709-4.2942162c0,0,6.388958,0,12.9873886,0 c3.4563293,0,11.3116074,0,11.3116074,0l0.9426346,4.8179016c0,0,0.1047363,1.46632-0.5236893,1.6757889 c-2.3042145,0.7331619-8.7979126,1.1521111-12.463707,1.1521111c-3.561058,0-9.950016-0.8378944-12.4637032-1.780529 C55.0397797,57.3399658,55.0397797,55.9783859,55.0397797,55.9783859z"
      />
    </g>
  )
}

export default CodPiece
