import React from "react"

const LongSwordRight = ({
  colors = [
    "#D0A92B",
    "#A8ABAD",
    "#D5D8DA",
    "#B49330",
    "#FFD144",
    "#EDD692",
    "#7B6528",
    "#A38634",
    "#7951A0"
  ]
}) => {
  return (
    <g id="LongSwordRight">
      <rect
        x="51.954"
        y="80.664"
        transform="rotate(-9.357 52.608 83.18)"
        fill={colors[0]}
        width="1.308"
        height="5.034"
      />
      <path
        fill={colors[1]}
        d="M48.4462204,52.1415329c0,0-0.9922981-5.1895256-1.9625053-5.6020699 c-0.8648071,0.7074051-0.0512695,5.9442902-0.0512695,5.9442902l4.5596199,27.6802406l2.0137749-0.3422165L48.4462204,52.1415329 z"
      />
      <path
        fill={colors[2]}
        d="M51.998951,79.9928818l-1.0068855,0.1711121l-4.5030861-27.6473885 c0,0-0.846386-5.1803551,0.0512695-5.9442902L51.998951,79.9928818z"
      />
      <path
        fill={colors[0]}
        d="M52.2265968,81.0326233c3.9946976-0.6279068,2.2735481-2.611145,2.2735481-2.611145 s-0.5324669-0.3850403-2.6394501,0.507988c-2.3346252-0.1467056-2.5737534,0.394928-2.5737534,0.394928 S48.1425095,81.6842194,52.2265968,81.0326233z"
      />
      <path
        fill={colors[3]}
        d="M55.0394707,80.0960617c-0.2261238-0.1314011-0.4850998-0.2062759-0.8097801-0.1680832 c-0.5034447,0.085556-0.8212662,1.4132996-0.8159218,1.7945099c-0.2062759,0.4851074-0.4125481,0.9702072-0.8266106,1.0320816 c-0.2681503,0.0710449-0.6875534-0.248291-1.041256-0.6806946c-0.1512566-0.3903809-0.7509422-1.570694-1.2543831-1.4851379 c-0.3575325,0.0947266-0.6348457,0.3116837-0.7662506,0.5378113c-1.7891655-0.4347076,0.0336456-2.400322,0.0336456-2.400322 s-0.7792435,1.2108459,1.3055725,1.1367722c0.2918282,0.0183411,0.89151,1.1986465,1.3055725,1.1367722 s0.5859642-1.3987808,0.8541145-1.4698257c1.9992676-0.5775146,1.1222649-1.5408707,1.1222649-1.5408707 S56.2709732,79.1480179,55.0394707,80.0960617z"
      />
      <path
        fill={colors[4]}
        d="M53.0233803,81.8737564l0.0328484-0.0565338c0.0275116-0.4377441,0.3781853-1.8220139,0.9710083-1.9312515 c0.3246765-0.0381927,0.5836563,0.0366821,0.8097839,0.1680832c0.1879349-0.1932831,0.2864838-0.3628693,0.2719765-0.5981674 c-0.0053406-0.3812103-0.3918953-0.7570801-0.6982346-1.0107193c0.0236816,0.089386,0.0145111,0.2352982-0.0183411,0.291832 c-0.0840378,0.404892-0.5729713,0.7257462-1.4102631,0.9954071c-0.0893822,0.0236816-0.1971016,0.3391876-0.2391243,0.5416336 c-0.1734238,0.4285736-0.2903099,0.8899994-0.6149902,0.9281921c-0.3246765,0.0381927-0.5981712-0.2719727-0.89534-0.671524 c-0.1038933-0.2116165-0.3537025-0.4324036-0.4102325-0.4652557c-0.8189545-0.0221634-1.3369102-0.1719055-1.521019-0.5057526 c-0.0236816-0.089386-0.0473633-0.178772-0.0710449-0.2681503c-0.2864838,0.3628769-0.4927559,0.8479767-0.3980331,1.2055054 c0.1038933,0.2116165,0.2406387,0.3666992,0.5561485,0.4744186c0.1642532-0.2826614,0.4981003-0.4667664,0.8227806-0.5049591 c0.592823-0.1092377,1.2161903,1.1604538,1.3674469,1.5508347c0.3537025,0.4323959,0.7165756,0.7188797,0.89534,0.671524 C52.7987709,82.6506805,52.9393425,82.2786407,53.0233803,81.8737564z"
      />
      <path
        fill={colors[3]}
        d="M53.9943047,85.5384216l-0.0381927-0.3246841 c-0.0145111-0.2352905-0.3208504-0.4889297-0.5889969-0.4178848l-0.9175072,0.1474304 c-0.2352905,0.0145111-0.4889297,0.3208466-0.4178848,0.5889969l0.0381927,0.3246841 c0.0145111,0.2352905,0.3208466,0.4889297,0.5889969,0.4178848l0.9175072-0.1474304 C53.9011002,86.0892258,54.0653534,85.8065643,53.9943047,85.5384216z"
      />
      <path
        fill={colors[5]}
        d="M53.8667297,85.2374191c-0.0145111-0.2352905-0.3208466-0.4889297-0.5889969-0.4178848 l-0.9503555,0.2039642c-0.2352943,0.0145111-0.4889297,0.3208466-0.4178886,0.5889969 C52.3747444,85.2022629,53.3487778,85.0876846,53.8667297,85.2374191z"
      />
      <ellipse
        transform="rotate(-9.357 52.022 79.531)"
        fill={colors[6]}
        cx="52.022"
        cy="79.531"
        rx="0.915"
        ry="0.654"
      />
      <ellipse
        transform="rotate(-9.357 52.022 79.531)"
        fill={colors[7]}
        cx="52.022"
        cy="79.531"
        rx="0.523"
        ry="0.785"
      />
      <ellipse
        transform="rotate(-9.357 52.022 79.531)"
        fill={colors[8]}
        cx="52.022"
        cy="79.531"
        rx="0.392"
        ry="0.654"
      />
    </g>
  )
}

export default LongSwordRight
