import React from "react"

const RoundWoodShieldLeft = ({
  colors = [
    "#D2A673",
    "#7B6144",
    "#675139",
    "#997954",
    "#SVGID_",
    "#36302A",
    "#726658",
    "#8F8579",
    "#FFFFFF",
    "#F6F6F6",
    "#E4E5E6",
    "#C8CACC",
    "#A2A4A7",
    "#757779",
    "#2D2D2E",
    "#000000"
  ]
}) => {
  return (
    <g id="RoundWoodShieldLeft">
      <path
        fill={colors[0]}
        d="M82.4000015,74.6999969c-2.4000015-7.0999985-10.0999985-10.9000015-17.2000046-8.5 c-7.0999985,2.4000015-10.9000015,10.0999985-8.5,17.1999969L82.4000015,74.6999969z"
      />
      <path
        fill={colors[1]}
        d="M82.4000015,74.6999969C84.8000031,81.7999954,81,89.5,73.9000015,91.8999939 c-7.0999985,2.4000015-14.7999992-1.4000015-17.2000008-8.5L82.4000015,74.6999969z"
      />
      <path
        fill={colors[0]}
        d="M58.2999992,82.8000031C60.3999977,89,67.1999969,92.4000015,73.4000015,90.2000046 C79.5999985,88.1000061,83,81.3000031,80.8000031,75.1000061l-0.5,0.1999969 c2,5.9000015-1.1999969,12.4000015-7.0999985,14.4000015s-12.4000015-1.1999969-14.4000015-7.0999985L58.2999992,82.8000031z"
      />
      <path
        fill={colors[2]}
        d="M80.8000031,75.0999985C78.7000046,68.9000015,71.9000015,65.5,65.7000046,67.6999969 c-6.2000008,2.0999985-9.5999985,8.9000015-7.4000015,15.0999985l0.5-0.1999969 c-2-5.9000015,1.2000008-12.4000015,7.0999985-14.4000015s12.4000015,1.1999969,14.4000015,7.0999985L80.8000031,75.0999985z"
      />
      <circle fill={colors[3]} cx="69.6" cy="79.1" r="13" />
      <circle fill="url(#SVGID_18_)" cx="69.6" cy="79" r="3.2" />
      <circle fill={colors[0]} cx="65.8" cy="67.2" r="0.3" />
      <circle fill={colors[0]} cx="73.8" cy="90.8" r="0.3" />
      <circle fill={colors[0]} cx="57.8" cy="83.3" r="0.3" />
      <circle fill={colors[0]} cx="58.8" cy="72.6" r="0.3" />
      <circle fill={colors[0]} cx="81.4" cy="75.2" r="0.3" />
      <circle fill={colors[0]} cx="80" cy="85.7" r="0.3" />
      <circle fill={colors[0]} cx="74.3" cy="67.4" r="0.3" />
      <circle fill={colors[0]} cx="64.6" cy="90.4" r="0.3" />
      <path
        fill={colors[5]}
        d="M73.9000015,67.9000015L57.7000008,79.1999969c0,0.1999969,0,0.4000015,0,0.5999985 l16.7000008-11.5999985C74.3000031,68.0999985,74.0999985,68,73.9000015,67.9000015z"
      />
      <path
        fill={colors[5]}
        d="M79.4000015,72.1999969L79.4000015,72.1999969L59.7999992,85.8000031l0,0 C59.8999977,85.9000015,59.8999977,86,60,86.1000061l19.5999985-13.5C79.5,72.5,79.4000015,72.3000031,79.4000015,72.1999969z"
      />
      <path
        fill={colors[5]}
        d="M65.4000015,90.1999969c0.1999969,0.0999985,0.3000031,0.0999985,0.5,0.1999969L81.5,79.5999908 c0-0.1999969,0-0.4000015,0-0.5L65.4000015,90.1999969z"
      />
      <path
        fill={colors[6]}
        d="M74.4000015,68.1999969l-16.7000008,11.5c0.0999985,2.0999985,0.7999992,4.1999969,2.0999985,6.0999985 l19.6000023-13.5C78.0999985,70.4000015,76.4000015,69,74.4000015,68.1999969z"
      />
      <path
        fill={colors[6]}
        d="M79.5999985,72.5999985L60,86.0999985C61.4000015,88,63.2999992,89.4000015,65.4000015,90.1999969 L81.5,79C81.5,76.8000031,80.9000015,74.5999985,79.5999985,72.5999985z"
      />
      <path
        fill={colors[6]}
        d="M76.4000015,88.8000031c3.1999969-2.1999969,4.9000015-5.5999985,5.0999985-9.1999969 L65.9000015,90.3000031C69.3000031,91.4000015,73.1999969,91,76.4000015,88.8000031z"
      />
      <path
        fill={colors[6]}
        d="M62.7999992,69.1999969C59.3999977,71.5,57.5999985,75.2999954,57.7000008,79.0999985 l16.2000008-11.1999969C70.4000015,66.5,66.1999969,66.9000015,62.7999992,69.1999969z"
      />
      <g>
        <ellipse fill={colors[7]} cx="69.6" cy="79" rx="3.7" ry="3.7" />
        <path
          fill={colors[3]}
          d="M68.5999985,76c-1.5999985,0.5999985-2.5,2.4000015-2,4s2.4000015,2.5,4,2 c1.5999985-0.5999985,2.5-2.4000015,2-4S70.1999969,75.5,68.5999985,76z"
        />
      </g>
      <radialGradient
        id="SVGID_18_"
        cx="-243.398"
        cy="718.544"
        r="3.206"
        gradientTransform="rotate(-18.738 -2024.703 -549.323)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.698" stopColor="#fff" stopOpacity="0.1" />
        <stop offset="0.724" stopColor="#f6f6f6" stopOpacity="0.1" />
        <stop offset="0.762" stopColor="#e4e5e6" stopOpacity="0.1" />
        <stop offset="0.809" stopColor="#c8cacc" stopOpacity="0.1" />
        <stop offset="0.862" stopColor="#a2a4a7" stopOpacity="0.1" />
        <stop offset="0.921" stopColor={colors[13]} stopOpacity="0.1" />
        <stop offset="0.983" stopColor="#2d2d2e" stopOpacity="0.1" />
        <stop offset="0.995" stopOpacity="0.1" />
      </radialGradient>
    </g>
  )
}

export default RoundWoodShieldLeft
