import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const OpenCloak = ({ allEnabledElements }) => {
  return allEnabledElements.includes("OpenCloak") ? (
    <g id="OpenCloak">
      <path
        id="S_x24_openCloak"
        d="M85.3,119.5l-5-50.6c0-1.2,0.1-4.5-0.2-7.8c-0.4-5.6-1.6-12.2-6.9-14.7c-1.2-0.6-2.4-1-3.5-1.3 c-0.6-1.9-1.5-4.1-2.8-6.5c-2.7-4.9-8.3-15-18.3-15.1c-11.3,0-18,15.8-18,15.9c-0.4,1.1-1.5,3.7-2.5,6c-1.4,0.4-2.8,0.8-3.8,1.1 c-4.4,1.7-5.6,5.3-6.3,7.4c-0.1,0.2-0.2,0.5-0.3,0.8c-0.3,0.9-1.2,3.4-1.1,17.9l-3.4,47L85.3,119.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(OpenCloak, { character: "ManDwarf" })
