import React from "react"

const KneeArmorR = ({ colors = ["#7C7C7C", "#FFFFFF", "#262261"] }) => {
  return (
    <g id="KneeArmorR">
      <path
        fill={colors[0]}
        d="M53.7,81.6c-0.1,0.1-0.6,5.7-0.8,7.6c0,0-0.1,0-0.1,0l-1.9,4.4l3.7,1l4.9,0.2L63.8,82 C63.8,82,58.5,77.1,53.7,81.6z"
      />
      <path
        fill={colors[0]}
        d="M53.2,88.9c0,0,4.2-3.8,7.3,1.2c-2.3,1.9-3.3,2-4.8,2C54.2,92,53.9,89.9,53.2,88.9z"
      />
      <path
        opacity="0.23"
        fill="#FFF"
        d="M58.6,79.8c0.2,0-0.9,5.7-1,5.6c-0.1,0-4.6,3.1-4.4,2.9c0.1-0.2,0.7-6.7,0.7-6.7 S56.1,79.8,58.6,79.8z"
      />
      <path
        opacity="0.23"
        fill="#FFF"
        d="M56.9,88l-1,1.4v2c0,0-0.6,0.9-2.1-2.4C54.9,87.9,56.9,88,56.9,88z"
      />
      <path
        opacity="0.23"
        fill="#FFF"
        d="M52.8,89.8c-0.1,0.2-1.3,3.5-1.3,3.5l3.4,1.1L52.8,89.8z"
      />
      <polygon
        opacity="0.3"
        fill={colors[2]}
        points="52.9,88.8 57.5,86.1 61,90.2 55.2,93.9"
      />
    </g>
  )
}

export default KneeArmorR
