import React from "react"

const ShoulderArmorR = ({ colors = ["#573B25", "#FFFFFF"] }) => {
  return (
    <g id="ShoulderArmorR">
      <path
        fill={colors[0]}
        d="M65.0911331,32.7046432c-1.0978394-1.5464096-3.6123123-5.8316269-3.8956146-6.1149273 c-0.2833023-0.2833023-0.8263359,3.8484058-0.8263359,3.8484058l0.2857742,0.3878021 c-1.090065-0.5020256-2.508831-0.6783562-4.2522697,0.084404c-4.5330963,1.9832191-3.0692558,6.6579971-3.0692558,6.6579971 c5.146946-2.3609886,8.5349464-3.1165352,8.5349464-3.1165352l1.0978851,1.8888206l2.3137665,0.2360764l-1.6848717-2.8080902 C63.948143,33.5768471,64.451622,33.2528191,65.0911331,32.7046432z"
      />
      <path
        opacity="0.19"
        fill="#FFF"
        d="M62.1163254,31.6658344l-1.7949066-0.855814 c-0.0053711,0.0215435-0.0110664,0.0419598-0.0169792,0.0635052l0.2447815,0.4747295 c0.0489464-0.1044445,0.0920868-0.1668758,0.0969772-0.1267948c0.0201988,0.1653709,0.0513611,0.3278408,0.0894547,0.4883766 l1.5403481,2.9872684c0.0592613,0.0849419,0.8083115,1.5726776,0.8083115,1.5726776l1.8651962,0.1652794L62.1163254,31.6658344z"
      />
      <path
        opacity="0.19"
        fill="#FFF"
        d="M57.7884102,32.2049828 c-0.0705414,0.1197052-0.1386147,0.2408066-0.207222,0.3615837c0.360508-0.5277061,0.6859283-1.0769558,1.0219917-1.620779 c-0.8048286-0.1545181-1.9003754-0.0280457-3.3369751,0.8330345c-0.3510513,0.3367062-2.0496292,1.5507717-1.7590179,3.9080944 c0.0661354-0.2006721,0.2215157-0.5900841,0.2058258-0.2831421c-0.0002136,0.0030098-0.0001602,0.0059662-0.0003204,0.0089722 c0.1159401-0.2507439,0.2301102-0.5023994,0.374527-0.7384224c0.0467453-0.0763969,0.0571671-0.0070381,0.0533524,0.1010094 c0.0728531-0.0685577,0.1476402-0.1348572,0.2122231-0.2126007c0.0543175-0.0653839,0.0439987,0.2860451,0.0388947,0.3217201 c-0.0083809,0.0588837-0.0222397,0.1251297-0.0356712,0.1899757c0.5684814-0.6903877,1.2025146-1.2114868,1.839447-1.6205635 c0.0412064-0.2473564,0.1296425-0.5899734,0.2467651-0.5899734c0.0158501,0-0.0112267,0.2434349-0.067318,0.4682808 c0.2345161-0.1422653,0.4686584-0.2688484,0.6994705-0.3809738c0.2940979-0.495739,0.57407-0.9995365,0.870266-1.4939842 c0.092411-0.1543045,0.0584564,0.1383991,0.0510406,0.1817036C57.9622192,31.8338928,57.8887215,32.034832,57.7884102,32.2049828 z"
      />
      <polygon
        opacity="0.19"
        fill="#FFF"
        points="61.1719093,26.9438648 60.6052704,30.5325718 62.4940643,31.7602863 63.3912392,30.3436928"
      />
    </g>
  )
}

export default ShoulderArmorR
