import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LeatherHelm from "./LeatherHelm"
import PlateHelm from "./PlateHelm"
import PlateHelmGold from "./PlateHelmGold"
import WizardHat from "./WizardHat"

const CATEGORY = "helm"
const CHARACTER = "ManDwarf"
export const IDS = {
  LEATHER_HELM: "LeatherHelm",
  PLATE_HELM: "PlateHelm",
  PLATE_HELM_GOLD: "PlateHelmGold",
  WIZARD_HAT: "WizardHat"
}

export const components = {
  [IDS.LEATHER_HELM]: LeatherHelm,
  [IDS.PLATE_HELM]: PlateHelm,
  [IDS.PLATE_HELM_GOLD]: PlateHelmGold,
  [IDS.WIZARD_HAT]: WizardHat
}

export const Group = ({ props }) => (
  <g id="helm">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LEATHER_HELM,
    name: "Leather Helm",
    defaultColors: ["#8B5E3C", "#C0A172", "#996464", "#603913"],
    colorable: false,
    component: components[IDS.LEATHER_HELM],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.PLATE_HELM,
    name: "Plate Helm",
    defaultColors: ["#9E9E9E", "#BCBEC0", "#999999", "#737373", "#DEDEDE"],
    colorable: false,
    component: components[IDS.PLATE_HELM],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.PLATE_HELM_GOLD,
    name: "Plate Helm Gold",
    defaultColors: ["#A59A76", "#8A784E", "#E6BB21", "#EED793"],
    colorable: false,
    component: components[IDS.PLATE_HELM_GOLD],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WIZARD_HAT,
    name: "Wizard Hat",
    defaultColors: ["#6C7670", "#7E8A83", "#49504C"],
    colorable: true,
    component: components[IDS.WIZARD_HAT],
    inUI: true,
    enabled: false,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
