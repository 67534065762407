import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BattleAxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BattleAxeLeft") ? (
    <g id="BattleAxeLeft">
      <path
        id="S_x24_battleAxeLeft"
        d="M80.056282,119.6609268l0.1999969-1.1999969l-1-60.8999977l3.5999985,0.4000015 c6.5999985-5.4000015,9.3000031-12.9000015,7.5999985-21.0999985c-1.0999985-5.6000023-4-9.7000027-4.3000031-10.1000023 l-9.1999893-0.6000042l-1-2.6000004l-5.1999969-3.2000008l-5.4000015,3.5000019l-0.9000015,2.5l-9.2000008,0.7000008 c-0.2999992,0.5-3,4.6000004-4,10.3000011c-1.4000015,8.2000008,1.5999985,15.7000008,8.2999992,20.8999977l3.5999985-0.5 l1,60.8999977l0.0999985,0.9000015C65.7562866,119.5609283,77.556282,119.7609253,80.056282,119.6609268z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BattleAxeLeft, {
  character: "ManHalfling"
})
