import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import FlameLeft from "./FlameLeft"
import FlameRight from "./FlameRight"
import BlueFlameLeft from "./BlueFlameLeft"
import BlueFlameRight from "./BlueFlameRight"

const CATEGORY = "magic"
const CHARACTER = "ManHalfling"
export const IDS = {
  FLAME_LEFT: "FlameLeft",
  FLAME_RIGHT: "FlameRight",
  BLUE_FLAME_LEFT: "BlueFlameLeft",
  BLUE_FLAME_RIGHT: "BlueFlameRight"
}

export const components = {
  [IDS.FLAME_LEFT]: FlameLeft,
  [IDS.FLAME_RIGHT]: FlameRight,
  [IDS.BLUE_FLAME_LEFT]: BlueFlameLeft,
  [IDS.BLUE_FLAME_RIGHT]: BlueFlameRight
}

export const Group = ({ props }) => (
  <g id="magic">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.FLAME_LEFT,
    name: "Flame Left",
    defaultColors: [
      "#F1D212",
      "#F4B81A",
      "#F49A35",
      "#F47B20",
      "#F1662F",
      "#EF4223"
    ],
    colorable: false,
    component: components[IDS.FLAME_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.FLAME_RIGHT,
    name: "Flame Right",
    defaultColors: [
      "#F1D212",
      "#F4B81A",
      "#F49A35",
      "#F47B20",
      "#F1662F",
      "#EF4223"
    ],
    colorable: false,
    component: components[IDS.FLAME_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.BLUE_FLAME_LEFT,
    name: "Blue Flame Left",
    defaultColors: [
      "#80D0DC",
      "#30BFCA",
      "#5BC6D1",
      "#A8DDE6",
      "#C0E6F0",
      "#E5F5F8"
    ],
    colorable: false,
    component: components[IDS.BLUE_FLAME_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.BLUE_FLAME_RIGHT,
    name: "Blue Flame Right",
    defaultColors: [
      "#80D0DC",
      "#30BFCA",
      "#5BC6D1",
      "#A8DDE6",
      "#C0E6F0",
      "#E5F5F8"
    ],
    colorable: false,
    component: components[IDS.BLUE_FLAME_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
