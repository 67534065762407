import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BattleAxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BattleAxeLeft") ? (
    <g id="BattleAxeLeft">
      <path
        id="S_x24_battleAxeLeft_1_"
        d="M68.153923,120.2361221l0.1574707-0.7650604l0.8381882-39.0976181l2.3000412,0.3441238 c4.3657608-3.3042603,6.2803116-8.0506363,5.3888397-13.3530426c-0.5696793-3.6196785-2.3306885-6.3206978-2.5134583-6.5846291 l-5.8881073-0.6085091l-0.5784225-1.6924515l-3.2585526-2.1794662l-3.5496712,2.1143799l-0.6381874,1.5821228l-5.9197159,0.2255745 c-0.2046242,0.3135109-2.0365601,2.8784561-2.8166618,6.5112648c-1.0974884,5.2270813,0.6450615,10.1119614,4.8174171,13.6110687 l2.3218994-0.2333221l-0.8381882,39.0976105l0.0422859,0.5798721 C58.9815025,119.8244858,66.5475006,120.2395325,68.153923,120.2361221z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BattleAxeLeft, {
  character: "ManGnome"
})
