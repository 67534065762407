import React from "react"

const MiniRamHorns = ({ colors = ["#806829", "#262261"] }) => {
  return (
    <g id="MiniRamHorns">
      <g fill={colors[0]}>
        <path d="M60.5521507,9.7993813c1.2102165-0.949729,4.1361809-1.4102449,4.4122276,2.2026367 c-0.4358215,0.3525867-0.926918,0.6094484-1.4681625,0.7789526c-0.3204002-0.2030897-0.9539566-0.8555202-1.708992-0.8236933 c-1.2762299,0-0.7469749,2.3310375-0.7469749,2.3310375s0.4999619,0.1865387,0.5823402-1.0122929 c0.6998253,1.9980326-0.6998825,2.5974178-0.6998825,2.5974178s-0.6587296,0.22649-1.4409294-1.1988316 C58.6995735,13.2493477,58.6172562,11.3178806,60.5521507,9.7993813z" />
        <path d="M76.5171738,9.7993813c-1.2102127-0.949729-4.1361771-1.4102449-4.4122238,2.2026367 c0.4358215,0.3525867,0.926918,0.6094484,1.4681625,0.7789526c0.3203964-0.2030897,0.9539566-0.8555202,1.708992-0.8236933 c1.2762299,0,0.7469788,2.3310375,0.7469788,2.3310375s-0.4999695,0.1865387-0.5823441-1.0122929 c-0.6998215,1.9980326,0.6998825,2.5974178,0.6998825,2.5974178s0.6587296,0.22649,1.4409332-1.1988316 C78.369751,13.2493477,78.4520721,11.3178806,76.5171738,9.7993813z" />
      </g>
      <g fill={colors[1]}>
        <path
          opacity="0.2"
          d="M76.5839386,10.601613 c-0.1381531-0.0146103-0.2704926-0.0400724-0.4198914-0.0336456c-0.1174774,0.0050316-0.2310257,0.0231581-0.3448792,0.0403748 c-0.2562027,0.1150036-0.4613037,0.2192755-0.545433,0.2722607c-0.6657257,0.4195757-1.0911713,1.2190189-1.6274261,1.8436241 c0.3352051-0.2367353,0.929039-0.7967758,1.6357956-0.7669497c1.2762299,0,0.7469788,2.3310375,0.7469788,2.3310375 s-0.3342667,0.113245-0.5004654-0.5055399c0.1266022,0.6368504,0.4631119,1.2082882,1.1428223,1.1415415 c0.2340851-0.139555,0.4234543-0.3022079,0.5952072-0.4735909c0.123291-0.2007856,0.2389603-0.4020557,0.3435745-0.6107225 c0.134285-0.3755016,0.2244034-0.7811346,0.2585678-1.1698532C77.836441,11.7863188,77.3970566,10.988452,76.5839386,10.601613 z"
        />
        <path
          opacity="0.2"
          d="M60.4853859,10.601613 c0.1381531-0.0146103,0.2705002-0.0400724,0.4198952-0.0336456c0.1174774,0.0050316,0.2310257,0.0231581,0.344883,0.0403748 c0.2561951,0.1150036,0.4612999,0.2192755,0.5454292,0.2722607c0.6657219,0.4195757,1.0911751,1.2190189,1.6274223,1.8436241 c-0.3352013-0.2367353-0.9290352-0.7967758-1.6357918-0.7669497c-1.2762299,0-0.7469749,2.3310375-0.7469749,2.3310375 s0.3342667,0.113245,0.5004616-0.5055399c-0.1265984,0.6368504-0.4631119,1.2082882-1.1428261,1.1415415 c-0.2340851-0.139555-0.4234543-0.3022079-0.5952072-0.4735909c-0.1232872-0.2007856-0.2389565-0.4020557-0.3435707-0.6107225 c-0.1342812-0.3755016-0.2244072-0.7811346-0.2585716-1.1698532 C59.2328873,11.7863188,59.6722679,10.988452,60.4853859,10.601613z"
        />
      </g>
    </g>
  )
}

export default MiniRamHorns
