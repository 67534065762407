import React from "react"

const TuckedInShirt = ({ colors = ["#EDE3C7", "#262262", "#8D6945"] }) => {
  return (
    <g id="TuckedInShirt">
      <g id="tuckedInShirt">
        <path
          fill={colors[0]}
          d="M74,77.7c0,0-0.7-5-2.3-6.3c-0.1-1.7,0.8-5.1-0.4-8.5c-0.3-0.9-0.2-2.2-0.2-3.6c-2.1-6.2-7.4-4.6-7.4-4.6 l-9.1-1.6c0,0,0.7,0.9,0.7,2.3c0.1,2.3-1.9,5.8-6,7.7c0,1.3-0.8,4.6-0.8,5.8c0-1.2-0.8-4.6-0.7-5.8c-4.1-1.9-6.6-5.4-6.5-7.7 c0-1.4,1-2.2,1-2.2l-9.4,1.3c0,0-2.3-0.9-4.6,1.4c-3.2,3.1-2.2,6.8-2.6,8.7c-0.5,1.9-0.2,6.7-0.2,6.7s-1.8,1.6-2.3,4.9 c1.1,5.2,7,3.6,8.6,1.5c1.8-2.3,0.8-7.5,0.8-7.5l0.6-2.1l1.6,4.6l0.4,3.4l-0.3,1.6c0,0,1.7,1,4.2,1.4c2.7,0.5,6.3,0.4,8.9,0.4 c2.9,0,7.3,0.1,10.1-0.4c2.1-0.4,3.7-1.3,3.7-1.3v-4.7l1.7-5.4l1.2,3.9l-0.4,3.6c0,0,0,1.2,1.7,3.5C67.6,80.7,74,77.7,74,77.7z"
        />
        <path
          opacity="0.42"
          fill={colors[1]}
          d="M68.9,70.9c0,0-1.7,1.2-3-0.8s-0.6-3.3-1.1-4.8 c-0.9,1.4-1.6-0.1-1.6-0.1s-1.1-0.1-1.1,2.7c0.4,2.7,1.3-0.2,1.3-0.2l1.2,3.9c0,0-1.6,3.5,0.8,6.3c1,2,5.2,3.2,5.2,3.2 s-3.5-5.6-3.6-7.2C67,72.3,68.9,70.9,68.9,70.9z"
        />
        <path
          opacity="0.42"
          fill={colors[1]}
          d="M27.9,70c0,0,2.5,1.3,3.7-0.8c1.2-2.1-0.1-5.6-0.1-5.6 s1,1.1,2,1.1s1.3,0.3,1.3,3.1c0,3.4-1.7,0.5-1.7,0.5s-0.2,1-0.6,2.1c0.5,1.1,0.2,3.5,0.2,3.5l-0.2,2.6c0,0-4.1-1.2-3.1-2.5 C31,72,27.9,70,27.9,70z"
        />
        <path
          fill={colors[0]}
          d="M27.3,80l3.9-1.5l1.4-2.2c0,0,0.2-4.4-5.3-4.4c-0.2,0-1.1,1.9-1.7,2.9c0-1.1,0.1-2.6-0.1-2.6 c-1.2,0.1-1.6,1.1-1.6,1.1l-1,3.4L27.3,80z"
        />
        <path
          fill={colors[0]}
          d="M72.7,76.1l-4.3,3.1l-1.9,0.4l-2-3.2c0,0,0.5-2.6,2.8-3.9c0.5-0.3,1-0.5,1.5-0.5c0.2,0,0.8,1.1,1.5,2.1 c0-1.1-0.5-2.2-0.3-2.1c1.2,0.1,3,0.9,3,0.9l1.2,2.9L72.7,76.1z"
        />
        <polygon
          opacity="0.42"
          fill={colors[1]}
          points="69.8,72.1 68.9,72.1 69.7,73.3 70.1,74"
        />
        <polygon
          opacity="0.42"
          fill={colors[1]}
          points="25.4,72.3 26.6,72.7 25.8,74.6 24.7,75.7"
        />
      </g>
      <path
        fill={colors[2]}
        d="M46.9,67l0.4-0.2c0.1-0.1,0.6-0.3,1.1-0.6l-1.7-0.9l1.7-1.1c-0.8-0.5-1.5-0.8-1.5-0.8l0.1-0.2 c0,0,0.7,0.4,1.6,0.9l1.5-0.9l0.1,0.2l-1.4,0.9c0.6,0.3,1.2,0.6,1.7,0.9l0.2,0.1l-0.2,0.1c0,0-0.8,0.4-1.6,0.8l1.6,0.8H50 c0,0-1.8,0-2.6,0H46.9z M48.4,66.8c0.4,0,0.7,0,1,0l-0.8-0.4c-0.3,0.2-0.6,0.3-0.8,0.4C48.1,66.8,48.2,66.8,48.4,66.8z M47.2,65.3l1.4,0.8c0.6-0.3,1.2-0.6,1.6-0.8c-0.5-0.3-1.1-0.6-1.6-0.9L47.2,65.3z"
      />
    </g>
  )
}

export default TuckedInShirt
