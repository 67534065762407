import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const RectangleShieldRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("RectangleShieldRight") ? (
    <g id="RectangleShieldRight">
      <path
        id="S_x24_rectangleShieldRight"
        d="M28.8999996,108.5999985c-2.1000004,0-4.3000011-0.1999969-6.8000011-0.5999985 h-0.6000004c-1-0.1999969-2-0.4000015-3-0.5999985h-0.2000008l-5.5-6.9000015V58.7999992l7.1999989-5.7000008v-1.2999992h0.2999992 L21.8999958,52c3.1000004,0.7000008,5.1999989,1,7.1000004,1s4-0.2999992,7.0999985-1l0.2999992-0.0999985l8.5999985,6.9000015 v41.5999985l-5.0999985,6.5l0.0999985,0.3000031l-0.2000008,0.0999985L39.1999931,107.5 c-3,0.6999969-5.2999992,1-7.6000004,1.1999969H31.399992l-0.1000004-0.0999985v0.0999985 C30.3999996,108.5,29.7000008,108.5999985,28.8999996,108.5999985z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(RectangleShieldRight, {
  character: "ManHalfling"
})
