import React from "react"

const BigEyes = ({ colors = ["#E0E0E0"] }) => {
  return (
    <g id="BigEyes">
      <path
        fill={colors[0]}
        d="M65.4846954,53.4926186c-0.8649979-0.4425545-2.1733627-0.0892487-2.122139,0.1088791 c0.0175629,0.0664253-0.4728432,2.4257507,2.9078369,2.2585106 C66.7895203,55.7353554,66.3496857,53.935173,65.4846954,53.4926186z"
      />
      <path
        fill={colors[0]}
        d="M71.2753754,53.4926186c0.8649902-0.4425545,2.1733627-0.0892487,2.122139,0.1088791 c-0.0175629,0.0664253,0.4728394,2.4257507-2.9078369,2.2585106 C69.9705429,55.7353554,70.4103851,53.935173,71.2753754,53.4926186z"
      />
    </g>
  )
}

export default BigEyes
