import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WizardRobe = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WizardRobe") ? (
    <g id="WizardRobe">
      <path
        id="S_x24_wizardRobe"
        d="M29.1849155,121.4771271c0-0.0819092,0.7723503-5.4060287,2.2468357-17.3648224 l-2.3170471,0.1638107l-0.7723503-1.7201004l-0.4212818-1.1467285l-2.5979042-11.5492477l-0.1404266-1.3105469 c-0.1404285-4.5050278,0.6319218-7.699501,2.3170471-9.665329l0.1404285-0.2457352l0.1404285-1.8839188v-0.3276443 l0.2808533-2.5391922c-0.21064-2.3753738-0.21064-7.535675,3.3000393-10.56633 c2.3872643-2.1296463,4.844738-2.539196,6.038372-2.7030144c0.0702133,0,0.1404305,0,0.21064,0 c1.7553406-0.7371864,3.5106812-1.3924637,5.2660217-1.3924637c1.6149139,0,3.0191841,0.4914551,4.2830315,1.4743729 l0.21064,0.1638184l1.0532036,0.9010048c0.1404266-0.0819092,0.491497-0.4095497,0.491497-0.4095497l0.21064-0.1638184 c1.4042702-1.0648232,2.8787575-1.556282,4.4936714-1.556282c1.3340607,0,2.5276909,0.4095459,3.7213211,0.8190956 c0.1404266,0.0819054,0.3510666,0.0819054,0.491497,0.1638184c0.21064,0,0.6319237,0.0819092,0.9127769,0.0819092 c2.3170471,0.1638184,5.546875,0.3276367,7.7937088,2.3753777c2.3170547,2.0477409,3.3702621,4.8326607,3.7915421,6.3889427 c0.5617065,1.8020096,0.6319199,4.0135651,0.7021332,6.3070374v0.5733643 c-0.0702133,0.8190918-0.0702133,1.802002-0.0702133,2.5391922c0.8425598,1.3105545,1.6149139,2.7030182,1.9659882,3.52211 c0.9829865,2.0477448,1.8255463,3.7678375-3.1596146,21.8698502l-4.212822,0.0819092 c0.8425674,6.0613022,1.9659882,13.5150681,2.5276947,17.2009964L29.1849155,121.4771271L29.1849155,121.4771271z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WizardRobe, {
  character: "ManGnome"
})
