import React from "react"

const BodyBase = ({ colors = [] }) => {
  return (
    <g id="BodyBase">
      <path
        id="bodyBase"
        d="M53.3,125.1c-0.2,0-0.4,0-0.6,0h-6.2c-0.1,0-0.2,0-0.3,0c-2.2,0-4.2-0.8-5.6-2.3c-1.4-1.5-2.2-3.4-2.1-5.6 c0,0,4.5-18.7,5-21.6l0.2-0.8c0.9-3.4,2.2-24,5-29.4c0.1-0.7-3.7,4.2-8.6,2.8c-4-0.9-11.9-7.1-5.4-16.5c0,0,7.1-14.8,8.1-16.7 c0.3-2.8,1.4-6.1,3.4-8.5c2.2-2.7,5.2-4.2,8.5-4.3l0,0c0.6-0.2,1.6-0.6,2.6-1c0-0.2-1.7-10.1,2.1-14.5c5.6-7,17-4.8,20.2,0.3 c3.1,5,1.4,13.8,1.4,14.1c0.9,0.4,2.1,0.9,2.7,1.1c0,0,6.7,0.7,8.8,3.4c2,2.4,2.6,6.6,2.9,9.5c1,1.9,6.7,13.4,7.6,15.6 c2.4,2.9,4.8,7.6,3,11.6c-1.4,3.2-6.2,6.4-10.8,5.5c-2.2-0.4-5.9-3.1-5.7-2.3c0.6,3,4.1,25.9,5,29.4l0.2,0.8 c0.4,2.9,5,21.6,5,21.6c0.1,2.1-0.7,4.1-2.1,5.6s-3.4,2.3-5.6,2.3c-0.1,0-0.2,0-0.3,0h-6.2c-0.2,0-0.4,0-0.6,0 C81.9,125.1,56.4,125.1,53.3,125.1z"
      />
    </g>
  )
}

export default BodyBase
