import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import WornShieldRight from "./WornShieldRight"
import WornShieldLeft from "./WornShieldLeft"

const CATEGORY = "equipped"
const CHARACTER = "ManDwarf"
export const IDS = {
  WORN_SHIELD_RIGHT: "WornShieldRight",
  WORN_SHIELD_LEFT: "WornShieldLeft"
}

export const components = {
  [IDS.WORN_SHIELD_RIGHT]: WornShieldRight,
  [IDS.WORN_SHIELD_LEFT]: WornShieldLeft
}

export const Group = ({ props }) => (
  <g id="equipped">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.WORN_SHIELD_RIGHT,
    name: "Worn Shield Right",
    defaultColors: ["#8A784E", "#A59A76", "#EED793", "#B0B0B1"],
    colorable: false,
    component: components[IDS.WORN_SHIELD_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.WORN_SHIELD_LEFT,
    name: "Worn Shield Left",
    defaultColors: ["#8A784E", "#A59A76", "#EED793", "#B0B0B1"],
    colorable: false,
    component: components[IDS.WORN_SHIELD_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
