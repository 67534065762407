import React from "react"

const TorchL = ({
  colors = [
    "#664000",
    "#262261",
    "#F1D212",
    "#FFD400",
    "#FFD500",
    "#SVGID_",
    "#FFFFFF",
    "#F4B719",
    "#F49A35",
    "#F37B20",
    "#F1662E",
    "#EE4223"
  ]
}) => {
  return (
    <g id="TorchL">
      <path
        fill={colors[0]}
        d="M96.6,44.3L92.7,65l1,0.5l1.3-0.1l5.1-20.4C100.1,45,99.1,44.2,96.6,44.3z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M95,53.4c-0.1,0.2-0.1,0.3-0.2,0.5L92.7,65l0.9,0.5 c0.6-2.1,1.2-4.3,1.7-6.5c1-4.4,1.9-9.6,3.5-14.4c-0.1,0-0.2-0.1-0.4-0.1C97.4,47.5,96,50.5,95,53.4z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M96.4,58.1c-0.8,2.4-1.4,4.9-2.1,7.4l0.7,0l2.9-11.6 C97.4,55.3,96.9,56.7,96.4,58.1z"
      />
      <path
        opacity="0.84"
        fill={colors[2]}
        d="M103.6,29.2c-0.7-1.8-0.9-3.6-2-5.2c0,0-0.5-0.7-0.6-0.7c-0.1,0.1-0.8,0.2-0.7-0.2 c0.1-0.9,1-1.2,0.7-2.1c-0.2-0.7-1.2-4.7-1.5-5.4c-0.4,1.5-1.8,5.3-2.5,6.7c0,0-1.1,4.9-1.5,7c0.2-0.1,0.6-0.3,0.5-0.1 c-0.6,2.9-2.2,5.3-2.8,8.2c0,0.1-0.1,0.2-0.2,0.3c0.2,1.7,1.1,3.2,2.1,4.6c-0.6-1.5-1-3.1-1-4.7c0-0.2,0.3-0.5,0.6-0.7 c0.1-1.9,0.3-3.6,1.3-5.2c1.2-2.1,1.9-5.4,1.9-5.4c0-0.4,1.5-4.2,1.1-5.9c-0.1-0.5,0.3,0.8,0.3,1.3c0.1,0,0.1,0,0.1,0.1 c0,1.3,0.6,5.7,0.6,5.8c1.2,1.5,1.2,1.8,1.8,3.6c0.7,2.3,1,3.8,1.1,6.2c0,0.1,0,0.2-0.1,0.3c0.4,1.4-0.2,2.9-0.5,4.3 c0,0.2-0.1,0.4-0.1,0.7c0.6-1.3,1-2.7,1.5-4.1C103.6,35.3,103.6,32.2,103.6,29.2z"
      />
      <radialGradient
        id="SVGID_55_"
        cx="99.539"
        cy="32.638"
        r="16.681"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffd400" stopOpacity="0.5" />
        <stop offset="1" stopColor="#ffd500" stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_55_)" cx="99.5" cy="32.6" r="16.7" />
      <g fill="#FFF">
        <path
          opacity="0.18"
          d="M99.1,49.3l0.1-0.3c-1.3,0.8-2.6,1.6-3.8,2.4l-0.1,0.4C96.5,51,97.8,50.2,99.1,49.3z"
        />
        <path
          opacity="0.18"
          d="M98.6,51.1l0-0.1c-1.2,0.6-2.4,1.2-3.6,1.7L95,53C96.2,52.4,97.4,51.8,98.6,51.1z"
        />
        <path
          opacity="0.18"
          d="M99.6,47l0.5-1.9c0,0-1-0.9-3.5-0.7l-1.1,5.8C96.9,49.1,98.3,48,99.6,47z"
        />
      </g>
      <g opacity="0.98">
        <path
          fill={colors[2]}
          d="M99.2,46.1c-1-0.5-1.9-1.1-2.7-1.8c-0.2-0.5-0.4-0.9-0.5-1.4c-0.4-1.2-0.8-2.4-1.1-3.7 c0.1-0.4,0.1-0.8,0.2-1.1c0.1-0.5,0.3-0.9,0.4-1.4c0.2-0.7,0.4-1.4,0.6-2.1l0.5-0.1l-0.3-0.6c0,0,0-0.1,0-0.1 c1.2-1.2,1.8-2.5,2-3.9c0.1-0.5,0.1-1,0-1.5l0.3-0.1c0.1,0.4,0.2,0.7,0.3,1c0.3,1.1,0.8,2.2,1.2,3.2c0.5,1.2,1,2.4,1.4,3.6 c0.8,3,0.7,6.1-0.6,9.6C100.4,45.8,99.8,46,99.2,46.1z"
        />
        <path
          fill={colors[7]}
          d="M99.2,46.2c-0.8-0.4-1.6-0.9-2.3-1.5c-0.2-0.4-0.3-0.8-0.4-1.2c-0.4-1.1-0.8-2.3-1.1-3.5 c0.1-0.3,0.1-0.6,0.2-0.9c0.1-0.5,0.3-0.9,0.4-1.3c0.2-0.6,0.4-1.2,0.5-1.9l0.8-0.2l-0.4-1c1-1.1,1.6-2.3,1.9-3.5 c0.3,0.7,0.6,1.4,0.9,2.1c0.5,1.2,1,2.4,1.3,3.5c0.8,2.9,0.6,5.7-0.5,9.1C100,46,99.6,46.1,99.2,46.2z"
        />
        <path
          fill={colors[8]}
          d="M99.1,46.2c-0.7-0.4-1.3-0.8-1.9-1.2c-0.1-0.3-0.2-0.6-0.4-1c-0.4-1-0.7-2.1-1-3.3c0-0.3,0.1-0.5,0.2-0.7 c0.1-0.5,0.3-0.9,0.4-1.3c0.1-0.5,0.3-1,0.5-1.6l1.1-0.3l-0.5-1.3c0.5-0.7,1-1.3,1.3-2.1c0.1,0.2,0.2,0.4,0.3,0.6 c0.5,1.2,1,2.3,1.3,3.4c0.7,2.7,0.6,5.4-0.4,8.5C99.6,46.1,99.4,46.2,99.1,46.2z"
        />
        <path
          fill={colors[9]}
          d="M99.1,46.2c-0.5-0.3-1-0.6-1.4-0.9c-0.1-0.2-0.2-0.5-0.3-0.8c-0.4-1-0.7-2-1-3.1c0-0.2,0.1-0.4,0.1-0.5 c0.1-0.4,0.3-0.9,0.4-1.3c0.1-0.4,0.3-0.9,0.4-1.3l1.4-0.4l-0.6-1.5c0.2-0.3,0.4-0.6,0.6-0.9c0.4,1,0.8,1.9,1,2.8 c0.7,2.5,0.6,5-0.3,7.9C99.3,46.2,99.2,46.2,99.1,46.2z"
        />
        <path
          fill={colors[10]}
          d="M98.9,46c-0.3-0.2-0.6-0.4-0.8-0.5c-0.1-0.2-0.1-0.4-0.2-0.5c-0.3-0.9-0.6-1.8-0.9-2.8 c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.2-0.9,0.4-1.3c0.1-0.3,0.2-0.7,0.3-1.1l1.4-0.4C99.7,41.2,99.6,43.4,98.9,46z"
        />
        <path
          fill={colors[11]}
          d="M99,46.3c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.8-0.6-1.7-0.8-2.6c0-0.1,0-0.1,0-0.2c0.1-0.4,0.2-0.9,0.4-1.3 c0.1-0.3,0.2-0.5,0.2-0.8l0.6-0.2C99.6,42.6,99.5,44.4,99,46.3C99,46.3,99,46.3,99,46.3z"
        />
      </g>
    </g>
  )
}

export default TorchL
