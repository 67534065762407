import React from "react"

const HalfCircle = ({ colors = [] }) => {
  return (
    <g id="HalfCircle">
      <path
        id="halfCircle"
        d="M100.4000015,117.8000031c0,17.6000061-14.3000031,31.8999939-31.9000015,31.8999939 s-31.9000015-14.3000031-31.9000015-31.9000015C36.5999985,117.8000031,100.4000015,117.8000031,100.4000015,117.8000031z"
      />
    </g>
  )
}

export default HalfCircle
