import React from "react"

const GoldAxeRight = ({ colors = ["#897441", "#A39155", "#EDCD6E"] }) => {
  return (
    <g id="GoldAxeRight">
      <path
        fill={colors[0]}
        d="M41.7405777,75.8743744c0.610981,2.0196457,2.3310852,3.4540405,4.5288811,4.0887527 c0.3727798-1.3506088,2.1192627-2.7757874,4.4582481-3.4672699s4.5598717-0.5041122,5.6399651,0.4123459 c1.5063133-1.7042694,2.1251984-3.8301468,1.5142097-5.8497849c-0.610981-2.0196457-2.331089-3.4540405-4.5288811-4.0887527 c-0.3727798,1.3506088-2.1192627,2.7757874-4.4582481,3.4672699s-4.5598717,0.5041122-5.6399651-0.4123459 C41.804554,71.8166122,41.1137581,73.9266586,41.7405777,75.8743744z"
      />
      <path
        fill={colors[1]}
        d="M50.5990715,80.1623688l8.1176682,29.9132767l2.5877342-0.1088791 c0.1913376-0.1840897,0.3985176-0.4400864,0.5179367-0.640007c-0.6901588-1.6600494-2.6959724-6.2489471-3.8532791-9.8969727 c-1.4146347-4.5341263-6.3968849-24.0298538-6.3968849-24.0298538l-1.3638458-7.162117 c-0.1999168-0.1194229-0.3437576-0.1510925-0.471756-0.2546768l-2.9057503,0.868309 c-0.1194229,0.1999207-0.1510925,0.3437576-0.2705154,0.5436783l2.6887207,6.6243744L50.5990715,80.1623688z"
      />
      <path
        fill={colors[0]}
        d="M59.195755,109.9549103l-8.0299187-29.9693756l-1.3499718-4.142868l-2.5765533-6.4488678 c-0.159668,0.0402451-0.0646591-0.3912659-0.0488243-0.4631805l2.2829018-0.7792358c0,0,0.1768303,0.5667648,0.2487488,0.5826035 l1.3394318,6.9305267c0.0402451,0.1596756,4.9261703,19.4079742,6.356636,23.8701935 c1.1328964,3.4164276,3.0667915,7.9894867,3.9251976,9.9128036c-0.1035919,0.1279984,0.1524124,0.3351746,0.0646629,0.3912582 L59.195755,109.9549103z"
      />
      <path
        fill={colors[2]}
        d="M59.4273453,109.9304886l-7.8385773-30.15345l-1.5096436-4.1026306l-2.4340401-7.0961227 l1.452877-0.4341583l1.7636833,7.4009628c0.5232353,2.0757294,5.062748,19.8150711,6.3968849,24.0298615 c1.0609703,3.4006042,3.0753708,8.2929993,3.9337769,10.2163162L59.4273453,109.9304886z"
      />
      <g fill={colors[2]}>
        <path d="M55.4802322,76.4865952c0.3437576,0.1510925,0.615593,0.2863541,0.8715973,0.4935303 c1.5063133-1.7042694,2.1251984-3.8301468,1.5142097-5.8497849c-0.6109772-2.0196457-2.3310852-3.4540405-4.5288811-4.0887527 c-0.079174,0.3595886-0.4057693,0.8155136-0.7165337,1.1995163c0.7191811,0.1583481,1.4066925,0.4605331,2.0064545,0.8188019 c0.0719223,0.015831,0.3035088-0.0085831,0.7178612-0.5205841c-0.079174,0.3595886-0.3180161,0.7594299-0.2619362,0.8471832 c0.7838478,0.5496063,1.4482689,1.2991409,1.6897621,2.257164 C57.2313347,73.3281326,56.7074661,75.0224991,55.4802322,76.4865952z" />
        <path d="M41.8124962,75.8902206c0.610981,2.0196381,2.331089,3.4540405,4.5288849,4.0887527 c0.0633392-0.287674,0.1986008-0.5595169,0.421608-0.8874359c-0.847187-0.26194-1.5907822-0.6518784-2.2307892-1.1698151 c-0.0719185-0.0158386-0.5106812,0.2645798-0.6301041,0.4645081c0.198597-0.5595093,0.2302666-0.7033539,0.1741829-0.7910995 c-0.0560837-0.0877533-0.2401695-0.2790985-0.2962532-0.3668518c-0.0158348,0.0719147-0.0158348,0.0719147-0.1035843,0.1279984 c-0.0719185-0.015831,0.0475044-0.2157516,0.0475044-0.2157516c-0.2804184-0.4387665-0.6169167-0.9652863-0.7376633-1.4442978 c-0.5146523-1.7722168,0.1128044-3.5945816,1.4996948-5.098938c-0.5034332-0.1108398-0.9191055-0.277771-1.2311897-0.5727005 C41.7484741,71.7288666,41.1137581,73.9266586,41.8124962,75.8902206z" />
      </g>
    </g>
  )
}

export default GoldAxeRight
