import React from "react"
import { withSkinColor } from "../../builderConnector"

const LeftThumb = ({ skinColor, colors = ["#E7B080"] }) => {
  return (
    <g id="LeftThumb">
      <path
        fill={skinColor}
        d="M82.5073318,82.157341c0.0804672-0.194458-0.7644119-1.8506851-0.6034851-1.9713745 c0.1609268-0.120697,1.8909149-0.5230255,2.0116119-0.5632553s1.5690613,0.9253387,1.5690613,0.9253387l-1.9311523,0.4827881 L82.5073318,82.157341z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M82.5073318,82.157341c0.0804672-0.194458-0.7644119-1.8506851-0.6034851-1.9713745 c0.1609268-0.120697,1.8909149-0.5230255,2.0116119-0.5632553s1.5690613,0.9253387,1.5690613,0.9253387l-1.9311523,0.4827881 L82.5073318,82.157341z"
      />
    </g>
  )
}

export default withSkinColor(LeftThumb, { character: "WomanGnome" })
