import React from 'react'
import { connect } from 'react-redux'
import {
  categorySelector,
  allEnabledElementsSelector,
  itemColorSelector
} from './reducers/builderReducer'

import { skinColorSelector } from './selectors/builderSelectors'

const mapColorToProps = (componentId) => state => ({
  colors: itemColorSelector(componentId)(state)
})

const Null = () => null

const ConnectedElements = (components, { character = 'ManMixed', category}) => ({ enabled = []}) => {
  return (
    <g id={category} className={character}>
      {
        enabled.map(ele => {
          let Component = components[ele.id] || Null
          if (ele.colorable) {
            Component = connect(mapColorToProps(ele.id))(Component)
          }
          return <Component key={ele.id} colors={ele.colors} />
        })
      }
    </g>
  )
}

const mapStateToProps = payload => state => ({
  enabled: categorySelector(state, payload)
})

const connectToBuilder = (components, { character = 'ManMixed', category }) => {
  return connect(mapStateToProps({ character, category }))(ConnectedElements(components, { character, category }))
}

const mapStateToProps2 = (character) => (state) => ({
  allEnabledElements: allEnabledElementsSelector(state, character)
})

const ConnectedToAllElements = Component => ({ allEnabledElements }) =>
  <Component allEnabledElements={allEnabledElements} />

export const connectToAllEnabledElements = (component, { character }) =>
  connect(mapStateToProps2(character))(ConnectedToAllElements(component, character))

export const withSkinColor = (Component, { character }) =>
  connect(state => ({ skinColor: skinColorSelector(state, character)}))(Component)

export default connectToBuilder