import React from "react"

const Goatee = ({ colors = ["#642B00"] }) => {
  return (
    <g id="Goatee">
      <path
        fill={colors[0]}
        d="M72.1,20.6c-1.2-1.1-2.2-0.6-2.5-0.5l-0.2,0.1l-0.2-0.1c-0.3-0.1-1.4-0.6-2.5,0.5 c-1.2,1.1-2.3,0.5-2.4,0.6c0,0,0.5,1.5,2.2,0.9C66.4,22.4,66,23,66,23.7c0.7,2.9,3.1,3.5,3.4,3.8c0,0,3.4-1.6,3.5-3.8 c0-0.8-0.3-1.3-0.5-1.6c1.6,0.6,2.2-0.9,2.2-0.9C74.3,21.1,73.3,21.7,72.1,20.6z M67.5,21.9c0,0,1.6-0.5,1.8-0.7 c0.1,0.3,1.8,0.7,1.8,0.7S70.2,22,69.2,22C68.3,22,67.5,21.9,67.5,21.9z"
      />
    </g>
  )
}

export default Goatee
