import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GoldAxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GoldAxeLeft") ? (
    <g id="GoldAxeLeft">
      <path
        id="S_x24_goldAxeLeft"
        d="M47.7009544,123.3752747l-4.0999985-3c-0.5-0.6999969-0.9000015-1.5999985-1.2000008-2.5 l1-6.5999985c1.2000008-1.5999985,5.0999985-7.0999985,7.5999985-11.3000031 c1.7000008-2.9000015,6.4000015-12.1999969,11.2000008-21.5999985l-2.5999985-0.8000031 c-2.7000008-4.9000015-2.7999992-10.5999985-0.2000008-15.0999985l0,0c2.4999962-4.5,7.4000015-7.4000015,12.9000015-7.7000008 l3.0999985,2.7000008l5.3000031,0.5l0.4000015,0.2000008v0.0999985h0.3000031l0.5999985,0.4000015l2.4000015,1.4000015 l3.0999985,4.2000008l4.0999985,1.2000046c2.6999969,4.9000015,2.8000031,10.5999985,0.1999969,15.0999985l0,0l0,0 c-2.5,4.5-7.3000031,7.4000015-12.9000015,7.6999969l-1.9000015-1.5999985l-17.7000046,34l-8.1000023,3.5999985 L47.7009544,123.3752747z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GoldAxeLeft, {
  character: "ManHalfling"
})
