import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongSwordRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongSwordRight") ? (
    <g id="LongSwordRight">
      <path d="M49.6825027,90.7462006c1.0175743,0.5913162,2.2650986,0.7869034,3.3285255,0.6486435l1.0068855-0.1711121 c2.8418999-0.4659653,4.7342377-3.0717697,4.2682686-5.9136734l-0.0381927-0.3246765 c-0.0053406-0.3812103-0.1565971-0.7715912-0.2841721-1.0725861c1.2735214-1.1504898,1.8686562-2.6951904,1.7670746-4.3422623 c-0.2161636-2.621109-2.0778809-4.2323074-3.1756668-4.9458542l-3.5963554-22.9613228 c-1.2252808-6.6104736-2.7806587-7.9680328-4.1374245-8.7564468l-0.5981674-0.2719765l-4.7005882,0.6714478 c-1.5745125,1.2780685-2.8793716,3.3934212-1.7618103,10.3194046l3.7185783,22.8810959 c-0.8266068,1.0320892-1.8778267,2.8411102-1.5447769,5.0007935c0.2474937,1.65625,1.1863708,3.0336609,2.6561966,3.8877792 c0.1695976,0.0985489,0.3391876,0.1971054,0.5087852,0.2956543c-0.0511856,0.3483582-0.045845,0.7295685,0.0488777,1.0871048 l0.0381966,0.3246765C47.4105492,88.669754,48.3257408,89.9577866,49.6825027,90.7462006L49.6825027,90.7462006z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongSwordRight, {
  character: "WomanGnome"
})
