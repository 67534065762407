import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const RamHorns = ({ allEnabledElements }) => {
  return allEnabledElements.includes("RamHorns") ? (
    <g id="RamHorns">
      <path d="M53.1692848,16.4277725l-1.7549057-0.4679747 c-2.8078499-1.1699362-4.7967377-3.9777822-5.3817062-8.1895523c-0.2339859-2.92484,6.2006607-16.6130924,8.6575279-17.9000225 c0.7019615-0.5849686,7.3705978-0.8189554,13.9222412-0.8189554c6.2006607,0,12.4013214,0.2339869,13.1032791,0.8189554 c2.4568634,1.403923,9.1255035,15.0921745,8.6575317,17.9000225c-0.5849686,4.2117705-2.5738602,7.0196166-5.3817062,8.1895523 l-1.7549057,0.4679747C82.8856583,16.4277725,53.6372604,16.4277725,53.1692848,16.4277725z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(RamHorns, {
  character: "ManDragonborn"
})
