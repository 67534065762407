import React from "react"
import { withSkinColor } from "../../builderConnector"

const Body = ({ skinColor, color = [] }) => {
  return (
    <g id="Body">
      <path
        fill={skinColor}
        d="M97,54.1c0.5,1.3,0.9,2.4-0.7,3.3c-1.1,0.7-3.2-0.1-3.3-0.3c-6-7.8-5.1-8.5-5.9-10.1 c-0.5-1-2.9-3.9-3.7-5.6c-0.7-1.5-2-3.1-2-3.1s-0.5-9.5,2-9.1c4.4,0,5.5,6.3,5.2,7.8c0.8,1,2.2,4.4,2.9,6.2 c1.2,3.2,1.8,3.3,2.7,4.3C95,48.5,96.9,54.1,97,54.1z"
      />
      <path
        fill={skinColor}
        d="M41.4,54.1c-0.5,1.3-0.9,2.4,0.7,3.3c1.1,0.7,3.2-0.1,3.3-0.3c6-7.8,5.1-8.5,5.9-10.1 c0.5-1,2.9-3.9,3.7-5.6c0.7-1.5,2-3.1,2-3.1s0.5-9.5-2-9.1c-4.4,0-5.5,6.3-5.2,7.8c-0.8,1-2.2,4.4-2.9,6.2 c-1.2,3.2-1.8,3.3-2.7,4.3C43.3,48.5,41.5,54.1,41.4,54.1z"
      />
      <path
        fill={skinColor}
        d="M91.1,115.4c-0.6-1.6-1-2.3-1.8-3.3c-0.9-4-0.7-9.8-1.5-15.5c-1.2-4.5-3-6.6-3.1-7.7 c0-0.5-0.8-2.6-0.7-3.9c0.1-1.3-0.3-7-0.4-8.8c-0.2-2.4-0.3-6.4-0.9-9.4c-0.8-4-3.3-10-3.4-10.6c0,0-0.2-2.8,0.4-6.2 s2.8-11.3,3.2-11.6c1.3-0.9,2.9-3.1,3.4-4.9c0.2-0.4,0.3-0.7,0.3-1.1c-0.1-2.6-2.8-3.1-2.8-3.1c-0.4,0-0.8-0.1-1.4-0.2 c-1.6-0.4-5.9-2.4-8.3-3.1L74,24.4c0-1-0.1-3.6-0.1-3.6h-9.4l0,0h-0.1c0,0-0.1,2.6-0.1,3.6L64.2,26c-2,0.7-5.6,2.3-7.5,2.9 C56.4,29,56.2,29,56,29.1c-0.6,0.1-1,0.2-1.4,0.2c0,0-0.1,0-0.3,0.1c-0.7,0.2-2.5,1-2.6,3.1c0,0.4,0.1,0.8,0.3,1.1 c0.5,1.7,2.1,3.8,3.4,4.7c0.4,0.3,2.6,8.2,3.2,11.6c0.6,3.5,0.4,6.2,0.4,6.2c-0.1,0.6-2.6,6.6-3.4,10.6 c-0.6,2.9-0.8,6.9-0.9,9.4c-0.1,1.8-0.5,7.5-0.4,8.8s-0.7,3.4-0.7,3.9c-0.1,1.1-1.8,3.2-3.1,7.7c-0.8,5.7-0.6,11.5-1.5,15.5 c-0.8,1-1.2,1.7-1.8,3.3c-0.4,1-0.8,1.4-1.6,2c0,0.5,0.4,0.6,0.8,0.6c2.4,0,6.6,0,6.6,0c0.6,0.1,1.8-0.1,1.1-1.2 c-1-1.6,0.3-2.8-0.1-5.9c0-0.1,0.1-0.3,0.1-0.5c0.5-2.4,2.4-7.5,3.5-9.8c1.1-2.4,1.2-7.9,2.5-9.5s0.8-3.2,1.5-5 c0.7-1.9,1.6-3.8,2-5.9c0.4-1.8,1-3.4,4.3-12.5c0.4,0.3,1.2,0.3,1.8,0.2c0.2,0,0.6-0.2,0.6-0.2c3.3,9,3.9,10.7,4.3,12.5 c0.5,2,1.4,4,2,5.9c0.7,1.9,0.2,3.4,1.5,5s1.5,7.1,2.5,9.5c1.1,2.4,3,7.5,3.5,9.8c0,0.2,0.1,0.4,0.1,0.5 c-0.3,3.1,0.9,4.3-0.1,5.9c-0.7,1.2,0.5,1.3,1.1,1.2c0,0,4.2,0,6.6,0c0.4,0,0.8,0,0.8-0.6C91.9,116.8,91.5,116.4,91.1,115.4z"
      />
      <path
        fill="#BABABA"
        d="M81,61c-0.7-1.9-1.3-3.7-1.7-4.7c0,0,0-0.3-0.1-0.5c-1.5,0.4-6.5,1.7-10.2,1.7c-3.6,0-8.5-1.2-10-1.6 c0,0.2-0.1,0.3-0.1,0.3c0,0.3-0.9,2.6-1.8,5.2l10.5,6.1l0.2,0.2c0.5,0.4,1.9,0.4,2.4,0C70.4,67.7,81,61,81,61z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M68.7,31.1c0,0,0.5,0.3,0.8,0.3s0.8-0.3,0.8-0.3l-0.9,1.2 L68.7,31.1z"
      />
    </g>
  )
}

export default withSkinColor(Body, { character: "ManMixed" })
