import React from "react"

const SlingShotL = ({
  colors = ["#362812", "#454545", "#949494", "#724F1F"]
}) => {
  return (
    <g id="SlingShotL">
      <path
        fill={colors[0]}
        d="M97.4000015,65.8000031l1.0999985-10l6.1999969-2.9000015 c0,0,2.1999969,2,2.0999985,4.5999985c0,0.4000015,2.0999985,34.1999969,2.0999985,34.1999969 S108.6999969,94.0999985,107.6999969,94.5S104.5,94.0999985,104.5,94.0999985l-0.5-2.8000031l0.5-34.2000008l1.1999969-1.9000015 l-1.0999985-1.5l-5.3000031,2.7999992l1,2.2999992l-0.0999985,8.7000008L98,70.3000031l0.5-5.5L97.4000015,65.8000031z"
      />
      <path
        fill={colors[1]}
        d="M109.0999985,90.1999969c-0.8000031-1.0999985-1-1.8000031-2.5-1.5999985 c-0.1999969,0-1.9000015,0.0999985-2,0.3000031c-0.5,0.8000031-1.1999969,2.6999969-1.1999969,2.6999969 s1.0999985,2.0999985,2.5,2.1999969c1,0.0999985,3.0999985-0.9000015,3.0999985-0.9000015 S109.5,90.8000031,109.0999985,90.1999969z"
      />
      <path
        fill={colors[2]}
        d="M106.8000031,90c-0.1999969-0.4000015-0.4000015-0.9000015-0.5999985-1.3000031 c-0.5999985,0-1.4000015,0.0999985-1.5999985,0.3000031c-0.5,0.8000031-1.1999969,2.6999969-1.1999969,2.6999969 s0.4000015,0.8000031,1.0999985,1.4000015C105.2000046,92.9000015,105.9000092,92.5,106.6000061,92 C106.6999969,91.3000031,106.8000031,90.5999985,106.8000031,90z"
      />
      <path
        fill={colors[3]}
        d="M105.6999969,93.5l1.4000015-0.0999985L107.2999954,91l-0.5999985-2.5999985 l-1.3000031-32.2000008l0.3000031-0.9000015l0.8000031,0.5l2.3000031,35.8999977c0,0-0.4000015,2.8000031-1.6999969,2.4000015 c-0.1999969-0.0999985-1.8000031,0-2.4000015-0.4000015C105.5999985,93.4000015,105.6999969,93.5,105.6999969,93.5z"
      />
    </g>
  )
}

export default SlingShotL
