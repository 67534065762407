import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongBowR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongBowR") ? (
    <g id="LongBowR">
      <path d="M52.7,113.7c-3.1,0-5.8-1.9-6.9-4.8l0.5-2.8c-0.1-0.3-0.2-0.6-0.2-0.9l0,0C37.6,92.7,35,75,34.4,62.3l0.2,0l-0.4-0.4 l0-1.3c0-0.9,0-1.8,0-2.7c0-1.3-0.1-2.6-0.1-4l0.4-0.4l-0.1,0c0.2-4.9,0.7-9.7,1.4-14.1l0.1-0.4l0,0l0,0l0.3-1.7 c0.4-2,0.8-4,1.2-5.9l0,0l0,0l0,0c0.8-3.5,1.8-6.7,3-9.7c1.8-4.6,3.5-7.7,4.7-9.6c0-0.1,0-0.1,0-0.2c0,0,0,0.1,0,0.2 c0.2-0.2,0.3-0.5,0.4-0.7c0.1-0.8,0.4-1.6,0.7-2.3l-0.4-2.2c1.1-2.9,3.8-4.8,6.9-4.8c1.6,0,4.1,0.5,6.2,3.1 c0.1,0.2,0.3,0.3,0.4,0.5c0.2,0.3,0.5,0.6,0.7,1c0.3,0.4,0.5,0.8,0.7,1.2c0.1,0.3,0.3,0.5,0.4,0.7c0.1,0.1,0.1,0.2,0.2,0.3 l-0.4,3.8l0.1,0c0.1,1.4-0.1,2.7-0.6,3.8v82.8c0.6,1.1,0.8,2.4,0.8,3.8l-0.3,0l0.4,3.8c-0.1,0.1-0.2,0.3-0.3,0.5 c0,0-0.2,0.4-0.3,0.6l0,0C59.8,109.6,57.4,113.7,52.7,113.7C52.7,113.7,52.7,113.7,52.7,113.7z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongBowR, { character: "ManMixed" })
