import React from "react"

const Mouth = ({ colors = ["#CB776E", "#282560"] }) => {
  return (
    <g id="Mouth">
      <path
        fill={colors[0]}
        d="M69.8,20.8c0,0-0.4,0.1-0.6,0.1s-0.6-0.1-0.6-0.1L67,21.2c0.1,0.2,0.2,0.3,0.5,0.4 c0.2,0.1,0.5,0.2,0.8,0.3c0.2,0,1.5,0,1.8,0c0.3-0.1,0.6-0.1,0.8-0.3c0.2-0.1,0.4-0.3,0.5-0.4L69.8,20.8z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M69.8,20.8c0,0-0.4,0.1-0.6,0.1s-0.6-0.1-0.6-0.1L67,21.2 c0,0.1,1.1,0,2.2,0.1c1.1-0.1,2.1,0,2.2-0.1L69.8,20.8z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M68.6,22h1.2l0.4,0.7c0,0-0.3-0.4-1-0.4s-0.9,0.4-0.9,0.4 S68.6,21.9,68.6,22z"
      />
    </g>
  )
}

export default Mouth
