import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import BotaBagRight from "./BotaBagRight"
import BotaBagLeft from "./BotaBagLeft"
import QuiverRight from "./QuiverRight"
import QuiverLeft from "./QuiverLeft"
import HipDaggerRight from "./HipDaggerRight"
import HipDaggerLeft from "./HipDaggerLeft"
import SwordRightHip from "./SwordRightHip"
import SwordLeftHip from "./SwordLeftHip"
import ScrollRight from "./ScrollRight"
import ScrollLeft from "./ScrollLeft"

const CATEGORY = "gear"
const CHARACTER = "ManHalfling"
export const IDS = {
  BOTA_BAG_RIGHT: "BotaBagRight",
  BOTA_BAG_LEFT: "BotaBagLeft",
  QUIVER_RIGHT: "QuiverRight",
  QUIVER_LEFT: "QuiverLeft",
  HIP_DAGGER_RIGHT: "HipDaggerRight",
  HIP_DAGGER_LEFT: "HipDaggerLeft",
  SWORD_RIGHT_HIP: "SwordRightHip",
  SWORD_LEFT_HIP: "SwordLeftHip",
  SCROLL_RIGHT: "ScrollRight",
  SCROLL_LEFT: "ScrollLeft"
}

export const components = {
  [IDS.BOTA_BAG_RIGHT]: BotaBagRight,
  [IDS.BOTA_BAG_LEFT]: BotaBagLeft,
  [IDS.QUIVER_RIGHT]: QuiverRight,
  [IDS.QUIVER_LEFT]: QuiverLeft,
  [IDS.HIP_DAGGER_RIGHT]: HipDaggerRight,
  [IDS.HIP_DAGGER_LEFT]: HipDaggerLeft,
  [IDS.SWORD_RIGHT_HIP]: SwordRightHip,
  [IDS.SWORD_LEFT_HIP]: SwordLeftHip,
  [IDS.SCROLL_RIGHT]: ScrollRight,
  [IDS.SCROLL_LEFT]: ScrollLeft
}

export const Group = ({ props }) => (
  <g id="gear">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.BOTA_BAG_RIGHT,
    name: "Bota Bag Right",
    defaultColors: [
      "#715C40",
      "#CDA774",
      "#B09064",
      "#A17955",
      "#C5962C",
      "#EED793",
      "#80682A"
    ],
    colorable: false,
    component: components[IDS.BOTA_BAG_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BOTA_BAG_LEFT,
    name: "Bota Bag Left",
    defaultColors: [
      "#715C40",
      "#CDA774",
      "#B09064",
      "#A17955",
      "#C5962C",
      "#EED793",
      "#80682A"
    ],
    colorable: false,
    component: components[IDS.BOTA_BAG_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 9
  },
  {
    id: IDS.QUIVER_RIGHT,
    name: "Quiver Right",
    defaultColors: [
      "#3C2415",
      "#8B5E3C",
      "#C49A6C",
      "#6E634F",
      "#4076B7",
      "#43A3DB",
      "#9F7731",
      "#359C5A",
      "#63C298"
    ],
    colorable: false,
    component: components[IDS.QUIVER_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 8
  },
  {
    id: IDS.QUIVER_LEFT,
    name: "Quiver Left",
    defaultColors: [
      "#3C2415",
      "#8B5E3C",
      "#C49A6C",
      "#6E634F",
      "#4076B7",
      "#43A3DB",
      "#9F7731",
      "#359C5A",
      "#63C298"
    ],
    colorable: false,
    component: components[IDS.QUIVER_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 7
  },
  {
    id: IDS.HIP_DAGGER_RIGHT,
    name: "Hip Dagger Right",
    defaultColors: [
      "#66451E",
      "#895A24",
      "#9E8835",
      "#9AB8DB",
      "#6B92C0",
      "#57779C",
      "#E2E4E5",
      "#A0A2A5",
      "#EED793"
    ],
    colorable: false,
    component: components[IDS.HIP_DAGGER_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 6
  },
  {
    id: IDS.HIP_DAGGER_LEFT,
    name: "Hip Dagger Left",
    defaultColors: [
      "#66451E",
      "#895A24",
      "#9E8835",
      "#9AB8DB",
      "#6B92C0",
      "#57779C",
      "#E2E4E5",
      "#A0A2A5",
      "#EED793"
    ],
    colorable: false,
    component: components[IDS.HIP_DAGGER_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 5
  },
  {
    id: IDS.SWORD_RIGHT_HIP,
    name: "Sword Right Hip",
    defaultColors: [
      "#8A7C6B",
      "#665C4F",
      "#3D3730",
      "#615240",
      "#838383",
      "#C7C7C6",
      "#4D4D4D",
      "#5C3434"
    ],
    colorable: false,
    component: components[IDS.SWORD_RIGHT_HIP],
    inUI: true,
    enabled: false,
    subGroupId: 4
  },
  {
    id: IDS.SWORD_LEFT_HIP,
    name: "Sword Left Hip",
    defaultColors: [
      "#8A7C6B",
      "#665C4F",
      "#3D3730",
      "#615240",
      "#838383",
      "#C7C7C6",
      "#4D4D4D",
      "#5C3434"
    ],
    colorable: false,
    component: components[IDS.SWORD_LEFT_HIP],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.SCROLL_RIGHT,
    name: "Scroll Right",
    defaultColors: ["#4D391B", "#805F2D", "#B0823D", "#CBA02C"],
    colorable: false,
    component: components[IDS.SCROLL_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.SCROLL_LEFT,
    name: "Scroll Left",
    defaultColors: ["#4D391B", "#805F2D", "#B0823D", "#CBA02C"],
    colorable: false,
    component: components[IDS.SCROLL_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
