import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongSwordLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongSwordLeft") ? (
    <g id="LongSwordLeft">
      <path d="M90.9000015,99.5999985c2.4000015,0,4.5999985-1,6.0999985-2.9000015l0.3000031-0.4000015 C97.7000046,95.8999939,98,95.3999939,98.2000046,94.8999939c0.3000031,0,0.5999985,0,0.9000015,0 c2.5999985,0,4.9000015-1.0999985,6.5-3.0999985c2.0999985-2.5999985,2.0999985-5.8000031,1.8000031-7.8000031l22.5-27.4000015 c6.8000031-8.2999992,6.6999969-12.0999985,5.6000061-15l-5.6999969-4.5l-1-0.0999985 c-2.4000015,0-5.5,0.5999985-12.1999969,8.4000015L94.1999969,73c-2,0.0999985-5.6999969,0.8000031-8,4.0999985 c-1.4000015,2.0999985-1.8000031,4.5999985-1,7.0999985C84.7999954,84.5,84.2999954,84.8999939,84,85.3999939 l-0.3000031,0.4000015c-2.8000031,3.4000015-2.3000031,8.3000031,1.0999985,11.0999985l1.1999969,1 C87.3000031,98.9000015,89.0999985,99.5999985,90.9000015,99.5999985L90.9000015,99.5999985z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongSwordLeft, {
  character: "WomanDwarf"
})
