import React from "react"

const HalfCircle = ({ colors = [] }) => {
  return (
    <g id="HalfCircle">
      <path
        id="halfCircle"
        d="M79.3846741,120.6682129c0,17.6000061-14.3000031,31.9000092-31.8999977,31.9000092 s-31.8999977-14.3000031-31.8999977-31.9000092H79.3846741z"
      />
    </g>
  )
}

export default HalfCircle
