import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Antlers = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Antlers") ? (
    <g id="Antlers">
      <path
        id="S_x24_antlers"
        d="M29.2000008,44.2000008c-1.5-0.4000015-2.7999992-1.0999985-3.5-1.5999985 c-1.5-1-3.2000008-2.5999985-4.2999992-5.4000015c-0.3999996-0.2999992-0.7000008-0.5999985-1.1000004-0.9000015 c-2.2999992-2.2000008-3.3999996-5.2000008-2.8999996-8.2000008c0-0.2000008,5.2000008-7.2000008,5.7999992-9.2999992 c0.3999996-1.6000004,1.1000004-2.8000002,1.6000004-3.6000004l0.2000008-0.3000002 c0.7000008-1.1000004,1.7000008-2.9000006,3.5-4.3000002c13.1999989-4,12.6999989,9.2000008,20.2000027,9.2999992 c5.7000008,0.1000004,6.5-12.1000004,19.7999954-9.3000002c1.6999969,1.5,2.8000031,3.1999998,3.5,4.3000002l0.1999969,0.3000002 c0.5,0.8000002,1.1999969,1.999999,1.5999985,3.5999994c0.5999985,2.3999996,5.8000031,9.1000004,5.8000031,9.3999996 C80,31.1999969,79,34.0999985,76.6999969,36.2999954C76.2999954,36.5999947,76,36.8999939,75.5999985,37.1999969 c-1.0999985,2.7999992-2.8000031,4.5-4.3000031,5.5c-0.8000031,0.5-2,1.2000008-3.5,1.5999985 C66,44.5999985,30.7999992,44.5999985,29.2000008,44.2000008z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Antlers, {
  character: "ManHalfling"
})
