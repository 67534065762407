import React from "react"

const Skarf = ({ colors = ["#7F0E05", "#262261"] }) => {
  return (
    <g id="Skarf">
      <path
        fill={colors[0]}
        d="M64.1276703,28.7934475c0,0-1.6249657-0.7065067-2.7553749-0.3532543 c-0.1413002-0.4945545-1.2717094-0.5652046-1.6249619-0.4239025c-1.3423653,0.7065048-1.4130135,2.4727688-1.4130135,2.4727688 s3.1086273,7.9128647,8.1248169,8.5487213c0,0,2.9673233,0.3532524,4.8748932-0.0706482 c1.9075623-0.3532524,7.3476562-8.5487213,7.3476562-8.5487213s0-1.6249638-1.3423615-2.1195183 c-1.3423615-0.4239044-1.9782181-0.2826023-2.8966751,0.3532524c-0.494545-0.4945545-1.2717056,0.1413021-1.2717056,0.1413021 s-2.0488739,2.3314686-4.5922928,2.1901684C66.1058807,30.8423138,65.0461197,29.2173481,64.1276703,28.7934475z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M61.4429436,28.3695431 c0,0-0.1413002,5.6520443,8.4074211,6.4292049C66.600441,33.4563828,64.0570145,32.2553253,61.4429436,28.3695431z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M74.2306976,29.0760479 c0,0-2.5434189,5.5107479-8.4074173,3.1086254C67.9428024,33.4563828,72.2524872,34.798748,74.2306976,29.0760479z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M60.948391,32.3966255 c3.3205833,1.9075661,7.9835167,5.0868416,13.1410103,1.6956139c-1.6249695,1.3423653-4.521637,2.7553749-10.95084,1.1304092 c-0.2119522,0.8478088,6.7118034,5.369442,12.9290581-2.1901665c-1.1304092,1.4836617-4.521637,7.5596085-11.021492,4.521637 C62.6440048,36.3530617,60.948391,32.3966255,60.948391,32.3966255z"
      />
    </g>
  )
}

export default Skarf
