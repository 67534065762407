import React from "react"

const LongBowR = ({ colors = ["#5E4E2D", "#21376C", "#FFFFFF"] }) => {
  return (
    <g id="LongBowR">
      <path
        fill={colors[0]}
        d="M48.8467712,10.8736086c-0.2999992-0.6999998-2-3.6999998-2.4000015-2.5 c1.5999985,1.8000002,0.7000008,3.1999998-0.2000008,4.6999998c-0.7999992,1.3999996-9.3999977,11.7999992-10.7999992,39.7000008 c-0.0999985,0-0.0999985,0-0.2000008,0c0,1.4000015,0,2.7000008,0.0999985,4c0,1.2999992-0.0999985,2.5999985-0.0999985,4 c0.0999985,0,0.0999985,0,0.2000008,0c1.4000015,28.0000038,10,38.2999992,10.7999992,39.7000084 c0.9000015,1.5,1.7999992,2.9000015,0.2000008,4.6999969c0.5,1.1999969,2.0999985-1.8000031,2.4000015-2.5 c-9.2999992-12.6999969-10.7999992-36.5-11.0999985-46.0000038 C37.9467697,47.3736076,39.5467682,23.5736084,48.8467712,10.8736086z"
      />
      <path
        fill={colors[1]}
        d="M47.9467697,11.8736086c0-0.6000004-0.4000015-0.8999996-0.7000008-1.3999996 c0-0.1000004-0.0999985,0.3000002-0.0999985,0.3000002c0,0.1999998,0,0.5,0,0.6000004c0,0,0,0.1000004,0.0999985,0.1000004 c0,0,0,0,0,0.1000004v0.1000004c-0.2000008-0.3000002-0.4000015-0.6000004-0.5-0.8999996 c0-0.1000004-0.0999985,0.3000002-0.0999985,0.3000002c0,0.1000004,0,0.1999998,0,0.3000002 c-0.0999985-0.1000004-0.0999985-0.1999998-0.0999985-0.3000002s-0.0999985,0.3000002-0.0999985,0.3000002 c0,0.1000004,0,0.1999998,0,0.3999996c-0.0999985-0.1999998-0.2000008-0.3000002-0.2999992-0.5c0,0,0,0.3999996,0,0.5 s0,0.3999996,0,0.5c0.2000008,0.3000002,0.4000015,0.6000004,0.5999985,0.8999996c0,0.1000004,0,0.3999996,0.0999985,0.3999996 c0.0999985,0.1000004,0.2000008,0.1000004,0.2999992,0.1999998c0,0,0-0.1999998,0-0.3999996c0,0.1000004,0,0.1000004,0,0.1000004 l0.0999985,0.1000004v-0.3999996c0-0.1999998,0-0.3999996,0-0.5l0,0c0.0999985,0.1999998,0.2000008,0.3000002,0.2999992,0.5 c0,0.1000004,0.0999985-0.3000002,0.0999985-0.3000002c0-0.1000004,0-0.3000002,0-0.3999996 c0,0.1000004,0.0999985,0.1999998,0.0999985,0.1999998s0-0.1999998,0.0999985-0.3000002v0.1000004 c0,0.1000004,0,0.3000002,0.0999985,0.1999998C47.9467697,12.473609,47.9467697,11.973609,47.9467697,11.8736086z"
      />
      <path
        fill={colors[1]}
        d="M48.0467682,102.0736084c0,0.5999985-0.2999992,0.9000015-0.5999985,1.4000015 c0,0.0999985,0-0.3000031,0-0.3000031c0-0.1999969,0-0.5,0-0.5999985v-0.0999985c0,0,0,0,0-0.0999985v-0.0999985 c-0.0999985,0.3000031-0.2999992,0.5999985-0.4000015,0.9000015c0,0.0999985,0-0.3000031,0-0.3000031 c0-0.0999985,0-0.1999969,0-0.3000031c0,0.0999985-0.0999985,0.1999969-0.0999985,0.3000031c0,0.0999985,0-0.3000031,0-0.3000031 c0-0.0999985,0-0.1999969,0-0.4000015c-0.0999985,0.1999969-0.2000008,0.3000031-0.2999992,0.5c0,0,0-0.4000015,0-0.5 s0-0.4000015,0-0.5c0.2000008-0.3000031,0.2999992-0.5999985,0.5-0.9000015c0-0.0999985,0-0.4000015,0-0.4000015 c0.0999985-0.0999985,0.2000008-0.0999985,0.2999992-0.1999969c0,0,0,0.1999969,0,0.4000015c0-0.0999985,0-0.0999985,0-0.0999985 s0-0.0999985,0.0999985-0.0999985v0.4000015c0,0.1999969,0,0.4000015,0,0.5l0,0 c0.0999985-0.1999969,0.2000008-0.3000031,0.2000008-0.5c0-0.0999985,0,0.3000031,0,0.3000031 c0,0.0999985,0,0.3000031,0,0.4000015c0-0.0999985,0.0999985-0.1999969,0.0999985-0.1999969s0,0.1999969,0,0.3000031v-0.0999985 c0-0.0999985,0-0.3000031,0.0999985-0.1999969C48.1467705,101.4736099,48.0467682,101.8736115,48.0467682,102.0736084z"
      />
      <g opacity="0.8" fill="#FFF">
        <path
          opacity="0.23"
          d="M46.4467697,12.5736084 c0.2000008,0.3000002,0.4000015,0.6000004,0.5999985,0.8999996c0.5-0.8999996,1.0999985-1.8000002,1.7000008-2.6999998 c-0.2999992-0.6999998-2-3.6999998-2.4000015-2.5C47.8467712,9.973609,47.246769,11.2736082,46.4467697,12.5736084z"
        />
        <path
          opacity="0.23"
          d="M44.9467697,17.3736076 c0.0999985-0.2000008,0.2999992-0.3999996,0.4000015-0.6000004c0.5-0.999999,1-1.999999,1.5-2.8999987 c-0.2000008-0.3000002-0.4000015-0.6999998-0.5999985-1c0,0.1000004-0.0999985,0.1000004-0.0999985,0.1999998 c-0.2999992,0.3999996-1.2999992,1.8000002-2.7000008,4.3999996c0.2999992,0.3999996,0.5999985,0.7999992,0.7999992,1.2000008 C44.5467682,18.1736069,44.746769,17.7736073,44.9467697,17.3736076z"
        />
        <path
          opacity="0.23"
          d="M44.1467705,18.973608 c-0.2999992-0.3999996-0.5-0.8999996-0.7999992-1.2999992c-0.4000015,0.7999992-0.7999992,1.6000004-1.2999992,2.6000004 c0.4000015,0.2999992,0.7000008,0.7000008,1.0999985,1C43.5467682,20.473608,43.8467712,19.6736069,44.1467705,18.973608z"
        />
        <path
          opacity="0.23"
          d="M40.0467682,33.5736084 C40.0467682,33.5736084,39.9467697,33.5736084,40.0467682,33.5736084c-0.7000008-0.5999985-1.2999992-1-1.7999992-1.5 c-0.2000008,0.7999992-0.4000015,1.5999985-0.5999985,2.5c0.5999985,0.4000015,1.2999992,0.7999992,1.9000015,1.2000008 C39.746769,35.0736084,39.8467712,34.3736076,40.0467682,33.5736084z"
        />
        <path
          opacity="0.23"
          d="M38.8467712,39.8736076 c0.0999985-0.7999992,0.2999992-1.7000008,0.4000015-2.5c-0.5999985-0.4000015-1.2999992-0.7000008-1.7000008-1 c-0.0999985,0-0.0999985-0.0999985-0.2000008-0.0999985c-0.2000008,0.9000015-0.2999992,1.9000015-0.5,2.7999992 c0.2999992,0.0999985,0.7000008,0.2000008,1,0.4000015C38.0467682,39.5736084,38.4467697,39.6736069,38.8467712,39.8736076z"
        />
        <path
          opacity="0.23"
          d="M41.4467697,27.6736069 c0.2999992-1.2999992,0.5999985-2.6000004,1-3.8999996c-0.5-0.2000008-1-0.3999996-1.5-0.6000004 c-0.2000008,0.6000004-0.4000015,1.1000004-0.5999985,1.7999992c0.2999992,0.7999992,0.5999985,1.5,0.9000015,2.2999992 C41.3467712,27.2736073,41.3467712,27.473608,41.4467697,27.6736069z"
        />
        <path
          opacity="0.23"
          d="M40.6467705,30.9736061c-0.2999992-0.5-0.7000008-1-1-1.5 c-0.0999985-0.2000008,0-0.7000008-0.0999985-0.8999996c0.4000015,0.6000004,0.7999992,1.2000008,1.2000008,1.7999992 c0.0999985-0.3999996,0.2000008-0.7000008,0.2999992-1.1000004c-0.4000015-0.8999996-0.7999992-1.7999992-1.2999992-2.7000008 v-0.1000004c-0.5,1.6000004-1,3.3999996-1.5,5.3999996c0.5999985,0.5,1.2000008,1,1.7999992,1.5 C40.246769,32.5736084,40.4467697,31.7736092,40.6467705,30.9736061z"
        />
        <path
          opacity="0.23"
          d="M38.246769,43.9736061 c-0.0999985-0.2000008-0.2000008-0.2999992-0.4000015-0.5c-0.4000015-0.7000008-0.9000015-1.2999992-1.4000015-1.9000015 c-0.0999985,0.5999985-0.2000008,1.2000008-0.2000008,1.7999992c0.5999985,0.7000008,1.2000008,1.5,1.7999992,2.2000008 C38.0467682,44.9736061,38.1467705,44.4736061,38.246769,43.9736061z"
        />
        <path
          opacity="0.23"
          d="M38.746769,40.1736069 c-0.2000008-0.0999985-0.4000015-0.2000008-0.5999985-0.2000008c-0.5-0.2000008-0.9000015-0.4000015-1.4000015-0.5999985 c-0.0999985,0.5-0.0999985,1-0.2000008,1.4000015c0.5999985,0.7000008,1.2000008,1.4000015,1.7999992,2.0999985 C38.4467697,41.9736061,38.6467705,41.0736084,38.746769,40.1736069z"
        />
        <path
          opacity="0.23"
          d="M37.9467697,80.4736099 c0.0999985,0.5,0.2000008,1,0.2999992,1.5c0.5,0.5999985,1,1.1999969,1.5,1.8000031 C39.246769,82.6736145,38.5467682,81.5736084,37.9467697,80.4736099z"
        />
        <path
          opacity="0.23"
          d="M41.4467697,87.9736099 c-0.5-0.5999985-1-1.3000031-1.4000015-2c0.0999985,0.1999969,0.5999985,0.4000015,1,0.5999985 c0-0.0999985-0.0999985-0.3000031-0.0999985-0.4000015c-0.2000008-0.5-0.5-0.9000015-0.5999985-1.4000015 c0,0,0.0999985,0.0999985,0.2000008,0.1999969c-0.0999985-0.1999969-0.0999985-0.4000015-0.2000008-0.5 c-0.5999985-0.6999969-1.2000008-1.4000015-1.7999992-2.0999985c0.4000015,1.5999985,0.7999992,3.0999985,1.2999992,4.5 c0.5999985,0.9000015,1.2999992,1.6999969,2,2.5c0.0999985,0.1999969,0.9000015,1.3000031,0.2999992,0.5999985 c-0.7000008-0.9000015-1.5-1.8000031-2.2000008-2.6999969c1.2000008,3.8000031,2.5,6.6999969,3.7000008,8.8000031 c1,0.9000015,2,1.9000015,3,2.9000015c-0.5999985-1-1.0999985-2.0999985-1.5999985-3.1999969 C43.4467697,93.273613,42.3467712,90.6736145,41.4467697,87.9736099z"
        />
        <path
          opacity="0.23"
          d="M38.0467682,75.1736145 c-0.4000015-0.5-0.9000015-1-1.2999992-1.5999985c0,0,0-0.0999985-0.0999985-0.0999985 c0.2999992,2,0.7000008,3.9000015,1,5.6999969c0.0999985,0.0999985,0.2000008,0.1999969,0.2999992,0.3000031 c0.5,0.8000031,1,1.6999969,1.5,2.5C38.8467712,79.773613,38.246769,77.4736099,38.0467682,75.1736145z"
        />
        <path
          opacity="0.23"
          d="M46.1467705,100.5736084 c0.5999985,1,1.2000008,1.9000015,1.0999985,3c0.2000008,0.3000031,0.4000015,0.5999985,0.5999985,0.9000015 c0.4000015-0.5999985,0.7999992-1.3000031,0.9000015-1.5999985c-0.2999992-0.4000015-0.5999985-0.8000031-0.7999992-1.1999969 c-1.2999992-1.5-2.5-2.9000015-3.9000015-4.3000031C45.1467705,99.0736084,45.9467697,100.1736145,46.1467705,100.5736084z"
        />
        <path
          opacity="0.23"
          d="M37.8467712,46.5736084 c-0.5999985-0.7000008-1.2000008-1.5-1.7999992-2.2000008c-0.0999985,0.9000015-0.2000008,1.9000015-0.2999992,2.7999992 c0.5999985,0.5999985,1.2000008,1.2999992,1.7999992,2C37.6467705,48.2736092,37.746769,47.3736076,37.8467712,46.5736084z"
        />
        <path
          opacity="0.23"
          d="M37.0467682,60.6736069c0-0.2999992,0-0.5,0-0.7999992 c-0.5999985-0.7000008-1.2999992-1.5-1.9000015-2.2999992c0,0.7000008,0,1.2999992,0,2 c0.5999985,0.7000008,1.2999992,1.2999992,1.9000015,2C37.0467682,61.2736092,37.0467682,60.9736061,37.0467682,60.6736069z"
        />
        <path
          opacity="0.23"
          d="M37.0467682,61.8736076 c-0.5999985-0.7000008-1.2999992-1.2999992-1.9000015-2c0,0.2999992,0,0.5999985,0,0.7999992h0.2000008 c0,0.2999992,0,0.5999985,0.0999985,0.9000015c0.5999985,0.5999985,1.0999985,1.2000008,1.7000008,1.7000008 C37.1467705,62.8736076,37.0467682,62.3736076,37.0467682,61.8736076z"
        />
        <path
          opacity="0.23"
          d="M37.1467705,64.0736084 c-0.5999985-0.6000023-1.0999985-1.2000046-1.7000008-1.8000031c0.0999985,1.3999977,0.2000008,2.7000046,0.2999992,4 c0.5999985,0.8000031,1.2000008,1.5999985,1.7999992,2.5C37.4467697,67.1736145,37.246769,65.6736145,37.1467705,64.0736084z"
        />
        <path
          opacity="0.23"
          d="M37.9467697,73.4736099 c0-0.9000015-0.0999985-1.8000031-0.2000008-2.5999985c-0.5999985-0.8000031-1.2000008-1.5-1.7999992-2.3000031 c0.0999985,1.0999985,0.2999992,2.0999985,0.4000015,3.0999985c0.5,0.6999969,1,1.4000015,1.5,2.0999985 C37.9467697,73.773613,37.9467697,73.6736145,37.9467697,73.4736099z"
        />
      </g>
      <rect x="47.247" y="12.674" fill={colors[1]} width="0.1" height="88.5" />
    </g>
  )
}

export default LongBowR
