import React from "react"

const White = ({ colors = ["#D6D3C9"] }) => {
  return (
    <g id="White">
      <path
        fill={colors[0]}
        d="M60.7926636,80.4807892c0.4595337-0.21875,0.9277954-0.1426392,1.4100952-0.0656128 c-0.0601196-0.1002197-0.114563-0.1921387-0.1533813-0.260437c-0.114624-0.0286255-1.2503052-1.2785034-1.6495361-1.850647 c-0.524231-1.1410522-2.2279663-4.4266357-2.9369507-7.2015991c0.3621216-2.092041-1.1571655-3.8736572-1.7299805-3.8623047 c-0.2427368,0.0048218-0.9740601-0.102417-1.1290283,0.0323486c-2.7648926-0.4121704-5.0247192-1.1230469-5.0247192-1.1230469 c0-0.1400757,0.5842896,2.3096924-2.0131836,2.3096924s-2.2062378-2.4497681-2.2062378-2.3096924 c0,0-2.2598267,0.7108765-5.0247192,1.1230469c-0.1549683-0.1347656-0.8862915-0.0275269-1.1290283-0.0323486 c-0.5728149-0.0113525-2.092041,1.7702637-1.7299805,3.8623047c-0.7089844,2.7749634-2.4126587,6.0605469-2.9369507,7.2015991 c-0.399231,0.5721436-1.5348511,1.8220215-1.6494751,1.850647c-0.0334473,0.0588989-0.0882568,0.1497803-0.1376953,0.232666 c0.1658325-0.0263062,0.3087769-0.0402832,0.3739624-0.0394897c0.4226074,0.0053101,0.927063,0.0684814,1.3153076,0.2419434 c0.6177368,0.2759399,0.8604736,0.7630005,0.8917236,1.3057861c0.8683472-1.3927002,1.5233154-2.6716309,1.579895-2.7467041 c0.0229492-0.1314087,0.2372437-0.477478,0.4023438-0.8850708c0.7444458-1.8382568,2.5368042-5.8217773,2.9620361-6.4779053 c0.7072144,1.4714966,0.9066772,3.4916382,1.1931763,4.3671875c0.7654419,2.3392944-0.3828125,7.3999023-0.2755127,8.3806763 c0.0174561,0.159668-0.1508179,0.7177734-0.213562,1.2185059l13.0084229,0.0267944 c-0.0627441-0.5007324-0.2630615-1.0856323-0.2456055-1.2453003c0.1072998-0.9807739-1.0409546-6.0413818-0.2755127-8.3806763 c0.286499-0.8755493,0.4859619-2.8956909,1.1931763-4.3671875c0.425293,0.6561279,2.2176514,4.6396484,2.9620972,6.4779053 c0.1651001,0.4075928,0.3793335,0.7536621,0.4023438,0.8850708c0.055481,0.0736084,0.6859131,1.3036499,1.5289307,2.6638794 C59.8101196,81.2470856,60.2489014,80.7395172,60.7926636,80.4807892z"
      />
    </g>
  )
}

export default White
