import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const TorchL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("TorchL") ? (
    <g id="TorchL">
      <path
        id="path"
        d="M90.3,71.7l-4.5-8.1l2-10.4c-7.3-4.1-11.9-11.9-11.9-20.6c0-13,10.6-23.6,23.5-23.8l0.3,0 c13,0.1,23.7,10.7,23.7,23.8c0,11.3-8,20.8-18.6,23.2l-2.8,11.3l-6.4,5.4l-1.3,0.1L90.3,71.7z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(TorchL, { character: "ManMixed" })
