import React from "react"

const AxeLeft = ({ colors = ["#8B5E3C", "#6D6E71", "#D1D3D4", "#808285"] }) => {
  return (
    <g id="AxeLeft">
      <path
        fill={colors[0]}
        d="M74.6,58.1l1-9c0.2-0.1,0.4-0.3,0.6-0.4l3.8,0.7c0.1,0.2,0.2,0.4,0.3,0.5l-2.7,8.8c0,0-6,24.8-7.1,30.7 c-0.9,4.8-1.4,11.2-1.5,13.4c-0.3,0.2-0.6,0.3-0.9,0.4l-3-1.4l8.7-38.3L74.6,58.1z"
      />
      <path
        fill={colors[1]}
        d="M71.4,47.8c0.6,1.6,3,3.2,6.1,3.8c3,0.6,5.8,0.1,7-1.2c2.1,2,3.1,4.6,2.6,7.2c-0.5,2.6-2.5,4.6-5.2,5.7 c-0.6-1.6-3-3.2-6.1-3.8c-3-0.6-5.8-0.1-7,1.2c-2.1-2-3.1-4.6-2.6-7.2C66.7,50.9,68.7,48.9,71.4,47.8z"
      />
      <g fill={colors[2]}>
        <path d="M85.6,57.7c0.2-1.2,0-2.4-0.5-3.6c0-0.1,0.5-0.5,0.8-0.8c-0.8,0.3-1,0.3-1.1,0.2c-0.5-0.8-1-1.5-1.8-2.2 c0.6-0.2,1.2-0.5,1.5-0.9c2.1,2,3.1,4.6,2.6,7.2c-0.5,2.6-2.5,4.6-5.2,5.7c-0.1-0.4-0.4-0.7-0.7-1C83.5,61.4,85.1,59.8,85.6,57.7 z" />
        <path d="M71.4,47.8c0.2,0.5,0.6,1,1,1.4C70,50,68.1,51.7,67.7,54c-0.1,0.7-0.1,1.4,0,2c0,0.1-0.2,0.1-0.2,0.2 c0.1,0,0.1,0,0.2,0c0,0.1,0.1,0.5,0.1,0.6s-0.2,0.3-0.7,0.8c0.3-0.2,0.9-0.3,1-0.2c0.4,0.9,1,1.8,1.8,2.6c-0.4,0.2-0.8,0.4-1,0.7 c-2.1-2-3.1-4.6-2.6-7.2C66.8,50.9,68.7,48.8,71.4,47.8z" />
      </g>
      <polygon
        fill={colors[3]}
        points="74,59.4 77.9,60.1 80.2,51.9 74.8,50.8"
      />
    </g>
  )
}

export default AxeLeft
