import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Lips from "./Lips"
import Nose from "./Nose"
import Eyes from "./Eyes"

const CATEGORY = "face"
const CHARACTER = "WomanDwarf"
export const IDS = {
  LIPS: "Lips",
  NOSE: "Nose",
  EYES: "Eyes"
}

export const components = {
  [IDS.LIPS]: Lips,
  [IDS.NOSE]: Nose,
  [IDS.EYES]: Eyes
}

export const Group = ({ props }) => (
  <g id="face">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LIPS,
    name: "Lips",
    defaultColors: ["#9B3C00"],
    colorable: true,
    component: components[IDS.LIPS],
    inUI: true,
    enabled: true,
    subGroupId: 3
  },
  {
    id: IDS.NOSE,
    name: "Nose",
    defaultColors: ["#ED1C24", "#redGra", "#FFFFFF"],
    colorable: true,
    component: components[IDS.NOSE],
    inUI: true,
    enabled: true,
    subGroupId: 2
  },
  {
    id: IDS.EYES,
    name: "Eyes",
    defaultColors: ["#603813", "#231F20"],
    colorable: true,
    component: components[IDS.EYES],
    inUI: true,
    enabled: true,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
