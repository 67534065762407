import React from "react"

const ArmPlateR = ({ colors = ["#754C28", "#FFFFFF", "#282560"] }) => {
  return (
    <g id="ArmPlateR">
      <path
        fill={colors[0]}
        d="M58.2088737,40.2480278l0.389576-1.0270424l-1.6485558-0.0992889 c0.0493736-0.220871,0.0991783-0.4415779,0.1256676-0.6798058c0.2124901-2.4081993-2.3727875-3.6477394-2.6560898-3.6831436 c-0.1273346-0.0159035-0.4398689,1.9149399-1.4472466,4.1233292l-0.1540375-0.009243 c-0.1746101,0.2570839-0.5236206,1.1273003-0.5236206,1.1273003l0.1672516,0.0069847 c-0.207386,0.4596329-0.4113846,0.9141083-0.6059837,1.3494568l-0.0924644-0.0106926l-0.4958458,1.2041283l0.0537796,0.0066071 c-0.5319481,1.1983261-0.9037399,2.0474777-0.9037399,2.0474777s2.0677338-0.3682442,3.9064827-1.6793938l2.4328041,0.2981834 l0.5312004-1.2395325l-1.6993294-0.1960487c0.4070358-0.4631271,0.7679176-0.9894905,1.0326309-1.6058426L58.2088737,40.2480278z"
      />
      <path
        opacity="0.19"
        fill="#FFF"
        d="M53.8882484,38.0169144 c0,0-3.0810928,6.3392639-2.9748497,6.1621895s1.7353287-0.7082977,2.3019676-1.2749367 c0.5666389-0.5666351-0.9562035,0.0708313-0.9562035,0.0708313l1.0270309-0.779129l-0.8853683,0.1770744 C52.4008255,42.3729439,54.2069817,41.558403,53.8882484,38.0169144z"
      />
      <path
        opacity="0.3"
        fill={colors[2]}
        d="M50.7953491,44.2263222 c0,0,2.1485023-0.6138573,3.8956375-2.1012802c1.7471313-1.4874268,1.8179626-5.3830605,1.8179626-5.3830605 s0.2124901,0.1180496,0.2124901,0.2124863c0,0.0944405,0.4013672,3.7303696-1.5346451,5.1941833 s-3.9428558,2.266552-4.4150543,2.2901611S50.7953491,44.2263222,50.7953491,44.2263222z"
      />
    </g>
  )
}

export default ArmPlateR
