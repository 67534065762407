import React from "react"

const OpenCloak = ({ colors = ["#297544", "#0E0D0D"] }) => {
  return (
    <g id="OpenCloak">
      <g id="openCloak">
        <path
          fill={colors[0]}
          d="M27.9317856,80.6944885c0,0,16.2999992-14.5009155,17.7999992-15.7411194 c-10.4000015-3.7206306-6.5999985-11.5434875-4.2999992-21.6559677c3,2.1942139,4.2000008,3.4344254,7.5,3.4344254 c1,0,5.9000015-3.5298271,6.2999992-3.2436256c2.9000015,12.6882935,3.7999992,16.3135262-4,21.3697624 c0.5,0.3816071,17.9388924,15.8250961,17.9388924,15.8250961s-0.538887,37.5993195-0.738884,38.6487274h9.5999985 l-5.0999985-48.7497787c0,0-1.3339767-8.8681679-4.8339767-10.394577 c-3.5999985-1.5264091-4.7660294-10.3073769-7.7660294-15.3636169 c-2.7000008-4.7700348-6.6000023-9.3081512-12.1000023-9.3081512s-10.7999992,7.2093353-11.5999985,9.2127533 c-0.7999992,2.0034142-6.6297855,15.3157539-8.229784,15.8881569s-5.1702137,13.5906715-5.1702137,13.5906715 l-3.5000038,45.1245422h9.7000008C29.5317841,119.2363815,27.9317856,80.6944885,27.9317856,80.6944885z"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M73.3317871,77.7370682l-1.1999969-2.7666245 c0,0-14.8000031-9.9216766-14.1000023-9.9216766c8.5000038-3.8160286,1.2000008-15.7411194,0.9000015-16.9813309 c-1.2000008-3.2436256-2.9000015-5.8194427-4.2999992-6.4872475c-0.5-0.1908035-5.4000015,3.4344254-6.4000015,3.4344254 c-3.2000008,0-3.7999992-1.1448097-6.2999992-3.4344254c-0.2999992,0.477005-15.3999996,24.4225883-1.9000015,22.6099739 L23.3317852,77.6416702l-0.7000008,41.5947113h10.5000019c0,0,13-52.2796021,11.7000008-54.6646118 c-6-3.3390274-6.2999992-13.3561058-3.0999985-16.4089279c1.2000008,0.8586082,2.4000015,1.3356094,3.7999992,0.1907997 c0.5999985-0.4770012,1.5-0.8586082,2.7999992-0.7632027c1.0999985,0,1.7999992,0.4770012,2.7999992,1.0494041 s1.7000008,0.3816071,4-1.2402077c3.5,7.7274628,0,14.0239067-3.0999985,17.4583359 c-0.4000015,0.4769974,13.9000053,54.4738159,13.9000053,54.4738159h7.9000015 C73.9317856,118.2823792,72.7317886,84.2243195,73.3317871,77.7370682z"
        />
      </g>
    </g>
  )
}

export default OpenCloak
