import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BroadSwordR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BroadSwordR") ? (
    <g id="BroadSwordR">
      <path
        id="path_5_"
        d="M50.2975807,69.7507629l1.7332458-1.387764l2.5985603-6.1365662 c0,0-0.2140541-2.2921982-0.4330788-4.6842766l2.6128616-3.8344421l0.1054306-1.9075851l-2.655777-3.0719452l-3.584034-55.9911079 L50.510376-8.5563564l-1.2122345-4.2450542l-1.013073-2.2524843l-0.5391006-0.7741919l-11.9901695,0.4958496 c-0.4596672,0.8238363-2.9428711,5.252573-3.1239395,9.6670046c-0.0651283,0.704102-0.1153641,1.7078335,0.9636765,17.3735809 c0.8153419,12.3747816,1.9241638,28.6397934,2.5458908,37.1193848l-3.4918976,4.2786255l0.0043755,2.102375l4.4132538,4.1860695 l0.1235237,4.4994164l4.7271881,6.4733047l1.5279388,0.5247879L50.2975807,69.7507629z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BroadSwordR, {
  character: "WomanMixed"
})
