import React from "react"

const LeafPlateL = ({ colors = ["#BBBDBF", "#231F20", "#D0D2D3"] }) => {
  return (
    <g id="LeafPlateL">
      <path
        fill={colors[0]}
        d="M102.7128983,33.0983925c0,0-8.7164841,1.2921982-13.1036606-8.8696308 C91.1227798,20.0476189,104.4889297,17.2429104,102.7128983,33.0983925z"
      />
      <path
        opacity="0.2"
        fill={colors[1]}
        d="M88.1442795,24.2096138c0,0,0.6044388,9.8383408,14.5363388,6.4548302 C99.7831802,18.0752068,94.7353134,22.0566101,88.1442795,24.2096138z"
      />
      <path
        fill={colors[2]}
        d="M86.6619568,23.4685078c0,0,3.4306717,9.5135784,17.3625641,6.1300697 C101.1270905,17.0093403,93.2529907,21.3155022,86.6619568,23.4685078z"
      />
      <path
        opacity="0.2"
        fill={colors[1]}
        d="M101.8098297,24.3848019c0,0-7.3751602,6.8555641-18.0248871,0.0983448 C87.1807098,21.6885433,98.04776,17.1566639,101.8098297,24.3848019z"
      />
      <path
        fill={colors[0]}
        d="M102.245224,23.2318516c0,0-7.3751602,6.8555641-18.0248871,0.0983448 C87.6161041,20.535593,92.6856842,16.1339188,102.245224,23.2318516z"
      />
    </g>
  )
}

export default LeafPlateL
