import React from "react"

const PlateGoldGauntlets = ({ colors = ["#A59A76", "#8A784E", "#EED793"] }) => {
  return (
    <g id="PlateGoldGauntlets">
      <path
        fill={colors[0]}
        d="M33.283371,79.1575089c0,0,2.6447487,0.1747742,2.3315277,3.0645905 c-0.4944649,0.9526062-1.597683,1.6836319-1.597683,1.6836319s-1.1730042-3.6769943-3.0670643-2.396698 C32.5046959,78.7263489,33.283371,79.1575089,33.283371,79.1575089z"
      />
      <polygon
        fill={colors[1]}
        points="33.3100128,82.4664841 35.3505173,80.6485748 35.5401039,81.2100143 33.547081,82.8974762"
      />
      <polygon
        fill={colors[2]}
        points="33.5166092,79.1931686 31.8634453,81.2997894 32.4010582,81.4462357 34.0383949,79.3830872"
      />
      <g id="plateGoldGauntlets_1_">
        <path
          fill={colors[0]}
          d="M61.5338058,79.1575089c0,0-2.6447487,0.1747742-2.3315315,3.0645905 c0.4944687,0.9526062,1.5976868,1.6836319,1.5976868,1.6836319s1.1730003-3.6769943,3.0670624-2.396698 C62.3124809,78.7263489,61.5338058,79.1575089,61.5338058,79.1575089z"
        />
        <polygon
          fill={colors[1]}
          points="61.507164,82.4664841 59.4666595,80.6485748 59.2770729,81.2100143 61.2700958,82.8974762"
        />
        <polygon
          fill={colors[2]}
          points="61.3005676,79.1931686 62.9537315,81.2997894 62.4161186,81.4462357 60.7787819,79.3830872"
        />
      </g>
    </g>
  )
}

export default PlateGoldGauntlets
