import React from "react"

const SwordLeftHip = ({
  colors = [
    "#8A7C6B",
    "#665C4F",
    "#3D3730",
    "#615240",
    "#838383",
    "#C7C7C6",
    "#4D4D4D",
    "#5C3434"
  ]
}) => {
  return (
    <g id="SwordLeftHip">
      <path
        fill={colors[0]}
        d="M55.3,75.1l23.2,35.8c0,0,1.9,2.8,1.1,3.6c-0.8,0.9-2.4-2.1-2.4-2.1L52.8,76.9c0,0-0.3-0.5,0.8-1.5 S55.3,75.1,55.3,75.1z"
      />
      <path
        fill={colors[1]}
        d="M79.7,114.5c-0.8,0.9-2.4-2.1-2.4-2.1L52.8,76.9c0,0-0.3-0.5,0.8-1.5c-0.1,1.1,2,4,4.9,8.5 c5.5,8.4,14.3,21,19.7,29.1C78.6,113.8,79.2,113.9,79.7,114.5z"
      />
      <path
        fill={colors[2]}
        d="M55.6,75.5c0,0,0.4,0.7-0.8,1.5s-1.9,0.3-1.9,0.3L55.6,75.5z"
      />
      <path
        fill={colors[3]}
        d="M54.3,79.2c0,0,0.9,1,2.9-1.2c-0.1-0.2-0.2-0.4-0.4-0.6c0.2,0.3,6.3-2.7,6.3-2.7l0.4,2.3l-5.4,2.3 c0,0,0.7,2-2.4,1.9c-0.1,0-0.3-0.3-0.3-0.3c-3-0.1-3.6,0.5-3.6,0.5l-1-1.2c0,0,1.9-1.5,3.3-1.4C54.2,78.8,54.3,79.1,54.3,79.2z"
      />
      <path
        fill={colors[4]}
        d="M48.5,68.2c0.6-0.5,1.4-0.5,1.7-0.1c0.2,0.3,0.2,0.6,0,0.9l4.1,5.1l4.2-1.6l0.2,0.9L50,79.5l-1-0.1 l2.8-3.7l-3.3-5.6c-0.3,0-0.5-0.1-0.6-0.3C47.6,69.4,47.8,68.6,48.5,68.2z"
      />
      <path
        fill={colors[5]}
        d="M49,68.1c0,0-0.2,1.6-0.6,1.7S47.3,69.1,49,68.1z"
      />
      <path
        fill={colors[6]}
        d="M50.2,69.1c0,0-0.2,0.4-0.7,0.8s-1,0.3-1,0.3s0.3,0.6,1.2-0.1C50.6,69.5,50.3,69.1,50.2,69.1z"
      />
      <path
        fill={colors[7]}
        d="M50.2,69.1c0,0,0.3,0.4-0.5,1s-1.2,0-1.2,0l3.4,5.5c0,0,0.8,0.8,1.8,0s0.6-1.5,0.6-1.5L50.2,69.1z"
      />
      <polygon
        fill={colors[5]}
        points="49.2,79.4 52,75.7 54.4,74.2 58.6,72.5 58.8,73.4 55,75.2 52.7,76.8 50.1,79.5"
      />
    </g>
  )
}

export default SwordLeftHip
