import React from "react"

const BattleAxeRight = ({ colors = ["#7A7B7D", "#505052"] }) => {
  return (
    <g id="BattleAxeRight">
      <path
        fill={colors[0]}
        d="M36.1,30.8c-0.2,0.5-0.7,1-1.2,1.2c-2.1,1-3.8,1.5-6.1,1.5c-0.3,0.3-1,0.7-1.4,1.1c0-0.2,0.1-3.8,0.1-4 c0,0,0.8-0.1,0.8-0.2c0,0-1.5-0.7-1.7-5.2c-0.5,4.5-1.9,5.2-1.9,5.2c0,0.2,0.8,0.2,0.8,0.2c0,0.2-0.1,4-0.1,4S24.5,34,24,33.4 c-2.3-0.1-4-0.6-6-1.7c-0.5-0.2-1-0.7-1.1-1.2c0,0-9,12.6,2,21.7c-1.4-5.3-1.7-12.7,4.8-13.2c0.1,0,1.6-0.1,1.6-0.1l-0.2,4.6 c-0.1,0.2-0.4,0.3-0.7,0.7h0.9v1.4h-1c0,0.1,0,1.9,0,2c0.1,0,1,0,1.1,0c-0.3,19.6-0.8,51-1.1,70.6h-0.1c0.3,0.9,0.7,1.3,1.1,1.2 c0.4,0.2,0.8-0.2,1.1-1.1h-0.1c0.3-19.6,0.8-51,1.1-70.6c0.1,0,1,0,1.1,0c0-0.1,0-1.9,0-2h-1v-1.4h0.9c-0.1-0.2-0.5-0.5-0.7-0.7 v-4.5c0,0,1.4,0.1,1.6,0.1c6.5,0.7,6,8.1,4.4,13.3C44.7,43.7,36.1,30.8,36.1,30.8z"
      />
      <path
        fill={colors[1]}
        d="M24,33.4c-2.2-0.1-3.8-0.5-5.7-1.5c-2.1,3.5-5.7,11.4,0,17.4c-0.7-4.7,0-9.9,5.4-10.3c0.1,0,1.2,0,1.5,0 l0.2-4.4C25.1,34.4,24.4,33.8,24,33.4z"
      />
      <path
        fill={colors[1]}
        d="M28.8,33.5c2.2,0,3.8-0.4,5.7-1.3c2,3.5,5.3,11.5-0.5,17.4c0.9-4.7,0.3-9.9-5.1-10.5c-0.1,0-1.3,0-1.6,0 v-4.5C27.7,34.4,28.5,33.8,28.8,33.5z"
      />
      <rect
        x="25.7"
        y="45.2"
        transform="matrix(.01513 -.9999 .9999 .01513 -21.557 72.703)"
        fill={colors[1]}
        width="0.8"
        height="4.1"
      />
      <rect
        x="26"
        y="42.4"
        transform="matrix(.01513 -.9999 .9999 .01513 -18.659 69.945)"
        fill={colors[1]}
        width="0.3"
        height="4"
      />
      <path
        fill={colors[1]}
        d="M25.4,30.5c0,0-0.5,0-0.8-0.2c0.5-0.2,2.2-0.2,3.6,0c-0.1,0.1-0.8,0.2-0.8,0.2S26.5,30.2,25.4,30.5z"
      />
    </g>
  )
}

export default BattleAxeRight
