import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FlameRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FlameRight") ? (
    <g id="FlameRight">
      <path
        id="S_x24_flameRight"
        d="M28.8579674,52.2254524c0.1609306-0.5632477-14.3226776,16.3342934-14.1617479,26.6337471 c0.1609287,10.2994537,6.0348368,16.4147568,12.9547815,16.4147568 c15.4491806-0.4023285,17.7499619-20.2616425,13.1157074-25.6681747 C35.8426285,63.8611221,28.8579674,52.2254524,28.8579674,52.2254524z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FlameRight, {
  character: "ManHalfling"
})
