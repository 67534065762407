import React from "react"

const GreatAxeR = ({ colors = ["#9D9D9D", "#282560"] }) => {
  return (
    <g id="GreatAxeR">
      <path
        fill={colors[0]}
        d="M37.7822762,45.9316216c-1.9116554-5.5955772-2.7541504-13.5513535,4.187748-14.6375732 c0.09972-0.0055447,1.6953125-0.0942631,1.6953125-0.0942631l0.171978,4.8920422 c-0.1828156,0.3102646-0.5706253,0.5318947-0.753437,0.8421555l0.897522-0.0499039l0.0831718,1.4958649l-0.997242,0.0554504 c0.0055428,0.0997276,0.1164436,2.0942154,0.1219902,2.1939392c0.09972-0.0055428,1.0969658-0.0609932,1.1966896-0.0665398 c1.1810799,21.2413139,3.0774574,55.3470879,4.2585373,76.5883942l-0.0997238,0.0055466 c0.4598885,1.0747986,0.8809662,1.4515076,1.2687759,1.2298813c0.404438,0.0775452,0.7811584-0.3435364,1.1246071-1.3629608 l-0.0997238,0.0055466c-1.1810799-21.2413177-3.0774574-55.347084-4.2585373-76.5883942 c0.0997238-0.0055428,1.0969658-0.0609932,1.1966896-0.0665359c-0.0055466-0.0997276-0.1164436-2.0942192-0.1219902-2.1939392 l-0.997242,0.0554466l-0.0831757-1.4958649l0.897522-0.0499039c-0.4210815-0.3767166-0.7313461-0.5595322-0.8421593-0.753437 l-0.3769722-4.9806786c0,0,1.5014114,0.0165501,1.7008591,0.0054607 c7.1192513,0.3043785,7.1639481,8.3045139,5.7846985,14.0830746c11.6285172-10.349762,1.2897644-23.5794201,1.2897644-23.5794201 c-0.1661835,0.6094341-0.7423477,1.041605-1.2243347,1.3685017c-2.2326622,1.2245045-3.9944382,1.9226608-6.4875488,2.061285 c-0.4653511,0.6260719-1.4293289,1.2798691-1.4293289,1.2798691s-0.2328873-4.188427-0.2439766-4.3878765 c0,0,0.897522-0.0499058,0.8864326-0.2493553c0,0-1.440506-0.7201653-2.2108307-5.5789385 c-0.13274,4.8089552-1.6839714,5.6954699-1.6839714,5.6954699c0.0110893,0.1994476,0.9141579,0.2492676,0.9141579,0.2492676 c0.0166321,0.2991734,0.2328873,4.188427,0.2439766,4.3878765c-0.4210777-0.3767185-1.2410507-0.7312565-1.5624046-1.1135197 c-2.4931145,0.1386242-4.3158722-0.2601891-6.6760788-1.3293476c-0.6094437-0.1661777-1.1357918-0.6370754-1.3685074-1.2243328 C34.185524,22.6239052,25.4769077,36.9126549,37.7822762,45.9316216z"
      />
      <path
        fill={colors[1]}
        d="M45.9864578,26.1691132l0.3658829,4.7812309c0.404438,0.0775452,1.6011353,0.0110054,1.7008591,0.0054607 c5.9114609,0.1714687,6.9255981,5.8169498,6.3108711,10.9527988c5.8224907-6.8258781,1.5560913-15.1914711-0.8485527-18.8590107 c-2.038765,1.1136875-3.7063522,1.7065754-6.0997391,1.8396549 C47.0390587,25.3103256,46.2745361,25.9530315,45.9864578,26.1691132z"
        opacity="0.3"
      />
      <path
        fill={colors[1]}
        d="M43.7980537,26.390831l0.1664352,4.7923183c-0.3988991,0.0221806-1.6953201,0.094265-1.7950439,0.0998096 c-5.8560104,0.8257751-6.2380257,6.5488853-5.0577087,11.5849266c-6.5433369-6.1383018-3.2306709-14.9253159-1.247448-18.836834 c2.149662,0.8808002,3.8726997,1.2851582,6.2660904,1.1520786C42.5514565,25.5598488,43.4822502,26.1082916,43.7980537,26.390831 z"
        opacity="0.3"
      />
      <rect
        x="43.363"
        y="39.695"
        transform="rotate(-3.183 45.61 40.095)"
        opacity="0.3"
        fill={colors[1]}
        width="4.495"
        height="0.799"
      />
      <rect
        x="43.283"
        y="36.704"
        transform="rotate(-3.183 45.43 36.854)"
        opacity="0.3"
        fill={colors[1]}
        width="4.295"
        height="0.3"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M43.6482582,21.8976822 c0,0-0.8088875-0.1550884-0.9141541-0.2492676c1.4847755-0.2826214,3.2853661-0.2827091,3.8947983-0.1165295 c-0.2880859,0.2160854-0.8864288,0.2493553-0.8864288,0.2493553 C44.5236015,21.4488811,43.6482582,21.8976822,43.6482582,21.8976822z"
      />
      <path
        opacity="0.36"
        fill={colors[1]}
        d="M45.5117111,62.6074486 c-0.1108131-0.1939049-0.1163597-0.2936287-0.127449-0.4930763l-0.4435997-7.9779587 c-0.0055428-0.0997276,0.0830879-0.3047218,0.0775452-0.4044418c0.7922516-0.1440887,1.6897659-0.1939926,2.4820213-0.3380737 c0.1108131,0.1939011,0.1219025,0.3933487,0.1329918,0.5928001l0.4269638,7.6787834 C47.1848412,62.1142807,46.3039627,62.4633636,45.5117111,62.6074486z"
      />
    </g>
  )
}

export default GreatAxeR
