import React from "react"

const SteelBoots = ({
  colors = ["#BBBDBF", "#21366C", "#FFFFFF", "#4F4845", "#D0D2D3"]
}) => {
  return (
    <g id="SteelBoots">
      <path
        fill={colors[0]}
        d="M83.3029556,108.1759491C80.973732,102.4880829,78.0378189,89.91539,78.0378189,89.91539 l0.6940308-0.333374l5.1039429,3.5561523c0,0,0.2667236,0.3178101,0.6047363,1.0617065l5.2907715-3.9395142l-0.1401138-0.0962219 l-4.0530243-2.7833099l0.040062-0.3018646l5.0871582,2.3931274l0.2337646,0.1099243l-2.7716675,17.7150879 c1.7507324,3.1002808,6.5706177,7.6242065,6.0942383,10.6724854c-2.5491943,0.0397339-6.7130737,0.0397339-6.7130737,0.0397339 h-6.2071533l2.1812744-9.6671143C83.4225235,108.2860565,83.3662491,108.2402191,83.3029556,108.1759491z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M84.3365326,94.2773285c0,0,0.7292328,3.4968262,1.5070572,10.7922745l2.3334732,2.3334732 l2.7220993-17.8210449l-0.233757-0.1099625l-5.0871811-2.3931274l0.0403976,0.3823547l3.7013702,2.5212173L84.3365326,94.2773285 z"
      />
      <path
        opacity="0.6"
        fill="#FFF"
        d="M78.7318573,89.5820312l5.1039352,3.5561218c0,0,0.454361,0.3646774,0.6047363,1.0617218 c0.3132477,1.4520416,0.5480576,4.6139832,1.3091888,10.8026733c-0.3218613,0.6034851-1.191246,1.19133-1.9618988,1.9876709 c-0.0765381,0.4396744-0.2777023,1.0833893-0.3050537,1.3519897c-2.3291855-5.6879196-5.4449539-18.4268494-5.4449539-18.4268494 L78.7318573,89.5820312z"
      />
      <path
        opacity="0.6"
        fill="#FFF"
        d="M87.4975967,108.6504364c-1.1830444-1.0263672-1.4248047-1.7055054-1.4248047-1.7055054 l-0.7213745,2.031311c0,0-1.932251,1.3300171-2.7764282,3.1780396c-0.0050049,0.0109863-0.0025024,0.0179443-0.007019,0.0286255 c0.041626-0.182251,0.1340332-0.3806152,0.1340332-0.3806152l1.0858154-4.8120728l1.1109009-1.0140381l0.8334961-1.0216064 c0,0,1.2120972,1.3171387,2.3950806,2.3435059c0.7280884,0.9277954,0.9709473,1.7149658,2.725647,3.8251953 C89.3804703,109.4660492,88.1749039,109.2380829,87.4975967,108.6504364z"
      />
      <path
        opacity="0.6"
        fill="#FFF"
        d="M86.4251862,111.0343933c0,0,0.2417908,0.6791534,1.4248199,1.7055359 c0.831192,0.7211227,3.8453522,0.250412,5.7882919,2.5464554c0.6033249,1.0983276,0.7469711,2.6885223,0.5834274,2.6832047 c-5.6915817-0.1851425-11.4345932,0.1779404-12.9202423,0.0397568c0.33004-1.4627228,0.5157776-2.2858658,0.5157776-2.2858658 s3.3613129-2.2708893,3.8865585-2.6577606C86.2290726,112.6788406,86.4251862,111.0343933,86.4251862,111.0343933z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M87.8500137,112.7399139c-1.1830444-1.0263672-1.4248047-1.7055054-1.4248047-1.7055054 s-0.196106,1.6444092-0.7213745,2.031311s-3.8863525,2.6576538-3.8865967,2.6577759l0.3121338-1.3831177l3.5526733-2.1434326 l0.7213745-2.031311c0,0,0.2418213,0.6791382,1.4248657,1.7055054c0.305481,0.2650757,4.0880737,1.0671387,4.5606079,1.3223267 c0.6918945,0.6393433,0.8562622,1.2286987,1.1380615,1.9747925 C91.5821915,113.0138397,88.6658096,113.4476776,87.8500137,112.7399139z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M87.4507828,109.1562347c-1.1830444-1.0263672-1.3438721-1.3438721-1.3438721-1.3438721 s-0.2365723,1.9547119-1.2624512,2.3532715c-1.0258789,0.3984985-2.343689,2.3299561-2.343689,2.3299561l-0.0414429,0.1975708 c-0.0645142-0.015686-0.0003662-0.2849121,0.1156616-0.5388794c0.8441772-1.8480225,2.7764282-3.1780396,2.7764282-3.1780396 l0.7213745-2.031311c0,0,0.2417603,0.6791382,1.4248047,1.7055054c0.8311768,0.7211304,2.4577026,0.8991699,4.3969727,3.84375 C90.5063248,110.7527313,88.0440445,109.6708832,87.4507828,109.1562347z"
      />
      <path
        fill={colors[3]}
        d="M94.0467453,116.3064499c0,0-0.7317123,1.4425125-5.4149628,1.4017944 c-4.6832504-0.0407257-6.9460678-1.4017944-6.9460678-1.4017944l-0.4688339,1.7447586l13.0486603-0.0402298 C94.2655411,118.0109787,94.4551926,117.3265228,94.0467453,116.3064499z"
      />
      <polygon
        fill={colors[4]}
        points="84.4684906,94.1829224 78.0378113,89.9153595 78.7318573,89.5820312 84.4684906,93.5789795 89.9349442,89.5820312 85.5782242,87.0789413 90.8991623,89.5820312"
      />
      <path
        opacity="0.6"
        fill="#FFF"
        d="M87.8282852,111.8711395c-1.1830444-1.0263672-1.4248657-1.7055054-1.4248657-1.7055054 l-0.7213745,2.031311l-3.5526733,2.1434326l-0.213501,0.9459839l0,0l0.5848999-2.7907715 c0,0,1.3178101-1.9314575,2.343689-2.3299561c1.0258789-0.3985596,1.2624512-2.3532715,1.2624512-2.3532715 s0.1608276,0.3175049,1.3438721,1.3438721c0.5932617,0.5146484,3.055542,1.5964966,4.4437866,3.3379517 c0.1113892,0.1397095,0.2609863,0.3848267,0.4277344,0.6707153 C91.6608658,112.8842621,88.1236343,112.1274261,87.8282852,111.8711395z"
      />
      <g id="footwear">
        <g id="C_x24_steelBoots">
          <path
            fill={colors[0]}
            d="M53.3851929,108.1759491c2.3292236-5.6878662,5.2651367-18.2605591,5.2651367-18.2605591 l-0.6940308-0.333374l-5.1039429,3.5561523c0,0-0.2667236,0.3178101-0.6047363,1.0617065l-5.2907715-3.9395142 l4.1530762-3.1813965l-5.0871582,2.3931274l-0.2337646,0.1099243l2.7716675,17.7150879 c-1.7507324,3.1002808-6.5706177,7.6242065-6.0942383,10.6724854c2.5491943,0.0397339,6.7130737,0.0397339,6.7130737,0.0397339 h6.2071533l-2.1812744-9.6671143C53.265625,108.2860565,53.3218994,108.2402191,53.3851929,108.1759491z"
          />
          <path
            opacity="0.3"
            fill={colors[1]}
            d="M52.3516121,94.2773285c0,0-0.7292328,3.4968262-1.5070534,10.7922745 l-2.3334732,2.3334732l-2.7220993-17.8210449l0.233757-0.1099625l5.0871849-2.3931274l-4.1531067,3.1813889 L52.3516121,94.2773285z"
          />
          <path
            opacity="0.6"
            fill="#FFF"
            d="M57.9562912,89.5820312l-5.1039352,3.5561218 c0,0-0.454361,0.3646774-0.6047363,1.0617218c-0.3132515,1.4520416-0.5480576,4.6139832-1.3091888,10.8026733 c0.3218613,0.6034851,1.191246,1.19133,1.9618988,1.9876709c0.0765381,0.4396744,0.2776985,1.0833893,0.3050537,1.3519897 c2.3291855-5.6879196,5.4449501-18.4268494,5.4449501-18.4268494L57.9562912,89.5820312z"
          />
          <path
            fill={colors[4]}
            d="M49.1905518,108.6504364c1.1830444-1.0263672,1.4248047-1.7055054,1.4248047-1.7055054 l0.7213745,2.031311c0,0,1.932251,1.3300171,2.7764282,3.1780396c0.0050049,0.0109863,0.0025024,0.0179443,0.007019,0.0286255 c-0.041626-0.182251-0.1340332-0.3806152-0.1340332-0.3806152l-1.0858154-4.8120728l-1.1109009-1.0140381l-0.8334961-1.0216064 c0,0-1.2120972,1.3171387-2.3950806,2.3435059c-0.7280884,0.9277954-0.9709473,1.7149658-2.725647,3.8251953 C47.3076782,109.4660492,48.5132446,109.2380829,49.1905518,108.6504364z"
          />
          <path
            opacity="0.6"
            fill="#FFF"
            d="M50.2629623,111.0343933c0,0-0.2417946,0.6791534-1.4248238,1.7055359 c-0.831192,0.7211227-3.8453484,0.250412-5.7882881,2.5464554c-0.6033287,1.0983276-0.7469711,2.6885223-0.5834274,2.6832047 c5.6915779-0.1851425,11.4345894,0.1779404,12.9202461,0.0397568 c-0.3300476-1.4627228-0.5157814-2.2858658-0.5157814-2.2858658s-3.3613091-2.2708893-3.8865623-2.6577606 C50.4590759,112.6788406,50.2629623,111.0343933,50.2629623,111.0343933z"
          />
          <path
            opacity="0.3"
            fill={colors[1]}
            d="M48.8381348,112.7399139c1.1830444-1.0263672,1.4248047-1.7055054,1.4248047-1.7055054 s0.196106,1.6444092,0.7213745,2.031311s3.8863525,2.6576538,3.8865967,2.6577759l-0.3121338-1.3831177l-3.5526733-2.1434326 l-0.7213745-2.031311c0,0-0.2418213,0.6791382-1.4248657,1.7055054 c-0.305481,0.2650757-4.0880737,1.0671387-4.5606079,1.3223267c-0.6918945,0.6393433-0.8562622,1.2286987-1.1380615,1.9747925 C45.105957,113.0138397,48.0223389,113.4476776,48.8381348,112.7399139z"
          />
          <path
            opacity="0.3"
            fill={colors[1]}
            d="M49.2373657,109.1562347c1.1830444-1.0263672,1.3438721-1.3438721,1.3438721-1.3438721 s0.2365723,1.9547119,1.2624512,2.3532715c1.0258789,0.3984985,2.343689,2.3299561,2.343689,2.3299561l0.0414429,0.1975708 c0.0645142-0.015686,0.0003662-0.2849121-0.1156616-0.5388794c-0.8441772-1.8480225-2.7764282-3.1780396-2.7764282-3.1780396 l-0.7213745-2.031311c0,0-0.2417603,0.6791382-1.4248047,1.7055054 c-0.8311768,0.7211304-2.4577026,0.8991699-4.3969727,3.84375 C46.1818237,110.7527313,48.644104,109.6708832,49.2373657,109.1562347z"
          />
          <path
            fill={colors[3]}
            d="M42.6414032,116.3064499c0,0,0.7317123,1.4425125,5.4149628,1.4017944 c4.6832466-0.0407257,6.946064-1.4017944,6.946064-1.4017944l0.4688339,1.7447586l-13.0486565-0.0402298 C42.4226074,118.0109787,42.2329521,117.3265228,42.6414032,116.3064499z"
          />
          <polygon
            fill={colors[4]}
            points="52.2196617,94.1829224 58.6503334,89.9153595 57.9562912,89.5820312 52.2196617,93.5789795 46.7532005,89.5820312 51.1099281,87.0789413 45.7889862,89.5820312"
          />
          <path
            opacity="0.6"
            fill="#FFF"
            d="M48.8598633,111.8711395c1.1830444-1.0263672,1.4248657-1.7055054,1.4248657-1.7055054 l0.7213745,2.031311l3.5526733,2.1434326l0.213501,0.9459839l0,0l-0.5848999-2.7907715 c0,0-1.3178101-1.9314575-2.343689-2.3299561c-1.0258789-0.3985596-1.2624512-2.3532715-1.2624512-2.3532715 s-0.1608276,0.3175049-1.3438721,1.3438721c-0.5932617,0.5146484-3.055542,1.5964966-4.4437866,3.3379517 c-0.1113892,0.1397095-0.2609863,0.3848267-0.4277344,0.6707153 C45.0272827,112.8842621,48.5645142,112.1274261,48.8598633,111.8711395z"
          />
          <g>
            <path
              fill={colors[0]}
              d="M53.3851929,108.1759491c2.3292236-5.6878662,5.2651367-18.2605591,5.2651367-18.2605591 l-0.6940308-0.333374l-5.1039429,3.5561523c0,0-0.2667236,0.3178101-0.6047363,1.0617065l-5.2907715-3.9395142 l4.1530762-3.1813965l-5.0871582,2.3931274l-0.2337646,0.1099243l2.7716675,17.7150879 c-1.7507324,3.1002808-6.5706177,7.6242065-6.0942383,10.6724854c2.5491943,0.0397339,6.7130737,0.0397339,6.7130737,0.0397339 h6.2071533l-2.1812744-9.6671143C53.265625,108.2860565,53.3218994,108.2402191,53.3851929,108.1759491z"
            />
            <path
              opacity="0.3"
              fill={colors[1]}
              d="M52.3516121,94.2773285c0,0-0.7292328,3.4968262-1.5070534,10.7922745 l-2.3334732,2.3334732l-2.7220993-17.8210449l0.233757-0.1099625l5.0871849-2.3931274l-4.1531067,3.1813889 L52.3516121,94.2773285z"
            />
            <path
              opacity="0.6"
              fill="#FFF"
              d="M57.9562912,89.5820312l-5.1039352,3.5561218 c0,0-0.454361,0.3646774-0.6047363,1.0617218c-0.3132515,1.4520416-0.5480576,4.6139832-1.3091888,10.8026733 c0.3218613,0.6034851,1.191246,1.19133,1.9618988,1.9876709c0.0765381,0.4396744,0.2776985,1.0833893,0.3050537,1.3519897 c2.3291855-5.6879196,5.4449501-18.4268494,5.4449501-18.4268494L57.9562912,89.5820312z"
            />
            <path
              opacity="0.6"
              fill="#FFF"
              d="M49.1905518,108.6504364c1.1830444-1.0263672,1.4248047-1.7055054,1.4248047-1.7055054 l0.7213745,2.031311c0,0,1.932251,1.3300171,2.7764282,3.1780396c0.0050049,0.0109863,0.0025024,0.0179443,0.007019,0.0286255 c-0.041626-0.182251-0.1340332-0.3806152-0.1340332-0.3806152l-1.0858154-4.8120728l-1.1109009-1.0140381l-0.8334961-1.0216064 c0,0-1.2120972,1.3171387-2.3950806,2.3435059c-0.7280884,0.9277954-0.9709473,1.7149658-2.725647,3.8251953 C47.3076782,109.4660492,48.5132446,109.2380829,49.1905518,108.6504364z"
            />
            <path
              opacity="0.6"
              fill="#FFF"
              d="M50.2629623,111.0343933c0,0-0.2417946,0.6791534-1.4248238,1.7055359 c-0.831192,0.7211227-3.8453484,0.250412-5.7882881,2.5464554c-0.6033287,1.0983276-0.7469711,2.6885223-0.5834274,2.6832047 c5.6915779-0.1851425,11.4345894,0.1779404,12.9202461,0.0397568 c-0.3300476-1.4627228-0.5157814-2.2858658-0.5157814-2.2858658s-3.3613091-2.2708893-3.8865623-2.6577606 C50.4590759,112.6788406,50.2629623,111.0343933,50.2629623,111.0343933z"
            />
            <path
              opacity="0.3"
              fill={colors[1]}
              d="M48.8381348,112.7399139c1.1830444-1.0263672,1.4248047-1.7055054,1.4248047-1.7055054 s0.196106,1.6444092,0.7213745,2.031311s3.8863525,2.6576538,3.8865967,2.6577759l-0.3121338-1.3831177l-3.5526733-2.1434326 l-0.7213745-2.031311c0,0-0.2418213,0.6791382-1.4248657,1.7055054 c-0.305481,0.2650757-4.0880737,1.0671387-4.5606079,1.3223267c-0.6918945,0.6393433-0.8562622,1.2286987-1.1380615,1.9747925 C45.105957,113.0138397,48.0223389,113.4476776,48.8381348,112.7399139z"
            />
            <path
              opacity="0.3"
              fill={colors[1]}
              d="M49.2373657,109.1562347c1.1830444-1.0263672,1.3438721-1.3438721,1.3438721-1.3438721 s0.2365723,1.9547119,1.2624512,2.3532715c1.0258789,0.3984985,2.343689,2.3299561,2.343689,2.3299561l0.0414429,0.1975708 c0.0645142-0.015686,0.0003662-0.2849121-0.1156616-0.5388794c-0.8441772-1.8480225-2.7764282-3.1780396-2.7764282-3.1780396 l-0.7213745-2.031311c0,0-0.2417603,0.6791382-1.4248047,1.7055054 c-0.8311768,0.7211304-2.4577026,0.8991699-4.3969727,3.84375 C46.1818237,110.7527313,48.644104,109.6708832,49.2373657,109.1562347z"
            />
            <path
              fill={colors[3]}
              d="M42.6414032,116.3064499c0,0,0.7317123,1.4425125,5.4149628,1.4017944 c4.6832466-0.0407257,6.946064-1.4017944,6.946064-1.4017944l0.4688339,1.7447586l-13.0486565-0.0402298 C42.4226074,118.0109787,42.2329521,117.3265228,42.6414032,116.3064499z"
            />
            <polygon
              fill={colors[4]}
              points="52.2196617,94.1829224 58.6503334,89.9153595 57.9562912,89.5820312 52.2196617,93.5789795 46.7532005,89.5820312 51.1099281,87.0789413 45.7889862,89.5820312"
            />
            <path
              opacity="0.6"
              fill="#FFF"
              d="M48.8598633,111.8711395c1.1830444-1.0263672,1.4248657-1.7055054,1.4248657-1.7055054 l0.7213745,2.031311l3.5526733,2.1434326l0.213501,0.9459839l0,0l-0.5848999-2.7907715 c0,0-1.3178101-1.9314575-2.343689-2.3299561c-1.0258789-0.3985596-1.2624512-2.3532715-1.2624512-2.3532715 s-0.1608276,0.3175049-1.3438721,1.3438721c-0.5932617,0.5146484-3.055542,1.5964966-4.4437866,3.3379517 c-0.1113892,0.1397095-0.2609863,0.3848267-0.4277344,0.6707153 C45.0272827,112.8842621,48.5645142,112.1274261,48.8598633,111.8711395z"
            />
          </g>
        </g>
      </g>
    </g>
  )
}

export default SteelBoots
