import React from "react"

const BattleAxeRight = ({ colors = ["#7A7C7D", "#505052"] }) => {
  return (
    <g id="BattleAxeRight">
      <path
        fill={colors[0]}
        d="M24.4764233,63.4668503c0.1404686,0.3159409,0.4734154,0.624588,0.7990742,0.7407608 c1.3716545,0.5905685,2.4745235,0.8700638,3.9501953,0.8141785c0.1997681,0.1851883,0.6586075,0.4248199,0.9249687,0.6717377 c-0.0048599-0.1283264-0.156496-2.4356422-0.161356-2.5639648c0,0-0.5157089-0.0447197-0.5181389-0.1088791 c0,0,0.945385-0.4855652,0.9643612-3.3776169c0.4301434,2.875042,1.3453884,3.2901421,1.3453884,3.2901421 c0.0048599,0.1283188-0.508419,0.1477585-0.508419,0.1477585c0.0048599,0.1283188,0.1613541,2.563961,0.1613541,2.563961 s0.5628605-0.4068298,0.8715115-0.739769c1.4732437-0.1200485,2.5518074-0.4821548,3.8082771-1.2365112 c0.3159409-0.1404724,0.6245842-0.4734192,0.6765976-0.7966499c0,0,6.0805473,7.8654518-0.7559128,13.9712715 c0.769455-3.4344788,0.7821198-8.1895981-3.4004173-8.3524551c-0.0641556,0.0024338-1.028986-0.0252838-1.028986-0.0252838 l0.2400913,2.9464874c0.0690193,0.1258926,0.2639313,0.1827621,0.4661255,0.4321136l-0.5774345,0.0218735l0.0340157,0.8982315 l0.6415977-0.024292c0.00243,0.0641556,0.0461655,1.2190323,0.0485954,1.2831879 c-0.0641556,0.0024338-0.6415939,0.0242996-0.7057552,0.0267334c0.6687336,12.5680237,1.752512,32.7020493,2.4212437,45.2700806 l0.0641594-0.0024261c0.0364494,0.9623947-1.3181953,0.8209381-1.4115143,0.0534515l0.0641594-0.0024338 c-0.6687355-12.5680237-1.752512-32.7020493-2.4212418-45.2700729c-0.0641613,0.0024261-0.6415997,0.0242996-0.705761,0.0267258 c-0.00243-0.0641632-0.0461655-1.2190399-0.0485954-1.2831955l0.6415997-0.0242996l-0.0340195-0.8982391l-0.5774384,0.0218658 c0.0592995-0.1307449,0.3086491-0.3329468,0.432106-0.4661255l-0.1093407-2.8871918c0,0-0.8958092,0.098175-1.0241299,0.103035 c-4.1533737,0.6070633-3.6527653,5.3427429-2.4998589,8.6401749 C19.269701,71.8882751,24.4764233,63.4668503,24.4764233,63.4668503z"
      />
      <path
        fill={colors[1]}
        d="M32.2387733,64.8434219c1.4090881-0.1176147,2.4259262-0.4131317,3.6206627-1.1008987 c1.4323997,2.1945648,3.9341125,7.1757126,0.4227982,11.1638031c0.3349152-3.0325165-0.2405548-6.3518143-3.7149048-6.4772491 c-0.0641594,0.0024338-0.7699146,0.0291595-0.9623947,0.0364532l-0.2352352-2.8181686 C31.6190453,65.4451599,32.0535851,65.0431976,32.2387733,64.8434219z"
      />
      <path
        fill={colors[1]}
        d="M29.1615314,65.0242157c-1.4115105,0.0534592-2.447794-0.164299-3.688694-0.6955719 c-1.1981506,2.2941895-3.1210346,7.5071564,0.7435989,11.1516571c-0.6916447-2.9936447-0.4330387-6.3445358,3.0170097-6.8607025 c0.0641594-0.0024338,0.8340797-0.0315933,1.026556-0.0388794l-0.1093407-2.8871918 C29.9533176,65.5724945,29.4254608,65.2069778,29.1615314,65.0242157z"
      />
      <rect
        x="29.924"
        y="73.569"
        transform="rotate(-3.036 31.24 73.825)"
        fill={colors[1]}
        width="2.632"
        height="0.514"
      />
      <rect
        x="29.852"
        y="71.838"
        transform="rotate(-3.036 31.137 71.934)"
        fill={colors[1]}
        width="2.568"
        height="0.193"
      />
      <path
        fill={colors[1]}
        d="M31.270071,63.0168076c0,0,0.3207989-0.0121498,0.508419-0.1477585 c-0.325655-0.1161728-1.4163704-0.0748634-2.3097496,0.0874786c0.0665894,0.0617294,0.5181389,0.1088791,0.5181389,0.1088791 S30.5570259,62.8510551,31.270071,63.0168076z"
      />
    </g>
  )
}

export default BattleAxeRight
