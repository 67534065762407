import React from "react"

const BeardOne = ({ colors = ["#AF6C29", "#A96F6F", "#55576E"] }) => {
  return (
    <g id="BeardOne">
      <path
        fill={colors[0]}
        d="M61.4,67.4c0-2.1-0.6-3.2-1.2-3.8c-0.3-0.3-0.7-0.5-1.1-0.5c0.5-1.2,1.8-4.8,1.5-9.1c0,0,0.8,0,1.5,0.4 c0.2-1.4-0.6-2.8-1.2-3.3c0.5-0.3,1.4,0.2,1.4,0.2c-0.1-3.5-4.8-5.6-4.8-5.6l-5.3,4l-2.9,1.9H48l-2.9-1.9l-5.3-4c0,0-4.1,0-5,4.7 c1-0.1,1.3,0.2,1.3,0.2s-0.5,0.1-1,3.1c0,0.1,0.6-0.2,1.5-0.4c-1.8,3.1,1.4,8.9,1.8,10.1c-0.5,0-1,0.1-1.4,0.5 c-0.6,0.6-1.3,1.8-1.2,3.8l-4,2.6c0,0,0.2,1.2,1.8,2.1c1.6,0.9,2.7,0.9,2.7,0.9l0.9-4.7c0.6-0.4,1.8-1.7,2.2-2.2 c0.5-0.5,0.7-1.4-0.2-2.6c6.4-2,9.2-7.8,9.2-7.8h0.3c0,0,2.9,6.2,9.3,7.8c-0.8,1.1-0.5,1.9-0.1,2.5s1.7,1.7,2.2,2.2l0.9,4.7 c0,0,1.1-0.1,2.7-0.9s1.8-2.1,1.8-2.1L61.4,67.4z"
      />
      <path
        fill={colors[1]}
        d="M49.2,51.6h-1.4c-0.2,0-0.4-0.2-0.4-0.4l0,0c0-0.2,0.2-0.4,0.4-0.4h1.4c0.2,0,0.4,0.2,0.4,0.4l0,0 C49.6,51.4,49.4,51.6,49.2,51.6z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M61.4,67.4c0-2.1-0.6-3.2-1.2-3.8c-0.3-0.3-0.7-0.5-1.1-0.5 c0.5-1.2,1.8-4.8,1.5-9.1c0,0,0.8,0,1.5,0.4c0.2-1.4-0.6-2.8-1.2-3.3c0.5-0.3,1.4,0.2,1.4,0.2c-0.1-3.5-4.8-5.6-4.8-5.6l-5.3,4 l-2.9,1.9H48l-2.9-1.9l-5.3-4c0,0-4.1,0-5,4.7c1-0.1,1.3,0.2,1.3,0.2s-0.5,0.1-1,3.1c0,0.1,0.6-0.2,1.5-0.4 c-1.8,3.1,1.4,8.9,1.8,10.1c-0.5,0-1,0.1-1.4,0.5c-0.6,0.6-1.3,1.8-1.2,3.8l-4,2.6c0,0,0.2,1.2,1.8,2.1c1.6,0.9,2.7,0.9,2.7,0.9 l0.9-4.7c0.6-0.4,1.8-1.7,2.2-2.2c0.5-0.5,0.7-1.4-0.2-2.6c6.4-2,9.2-7.8,9.2-7.8h0.3c0,0,2.9,6.2,9.3,7.8 c-0.8,1.1-0.5,1.9-0.1,2.5s1.7,1.7,2.2,2.2l0.9,4.7c0,0,1.1-0.1,2.7-0.9s1.8-2.1,1.8-2.1L61.4,67.4z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M46.8,51.9c0,0,1,0.5,1.6,0.5s1.7-0.5,1.7-0.5l-1,2.2V53 l-0.6,1.2L47.6,53l-0.5,0.6L46.8,51.9z"
      />
      <path
        fill={colors[0]}
        d="M55.8,67.9c-0.2-0.3-0.4-0.6-0.7-0.8c1.2-1.6,2.1-10,0.8-10.9c-0.6-12.7-7.4-7.7-7.4-7.7l0,0 c0,0-6.7-5-7.4,7.7c-1.4,0.9-0.4,9.3,0.8,10.9c-0.3,0.2-0.5,0.4-0.7,0.8c-0.4,0.8-0.6,2.1,0.2,4.1c0.2,0.6,0.8,2.4,1.4,3L41,80.2 h5.2l-3-5.2l0.4-3.7c0,0,0.7-2.4-0.6-4.3c0.1-1.2,0.9-9.3,0-10.9c1-0.9,3.7-4,4.8-5.3h1.1c1.1,1.3,3.8,4.3,4.8,5.3 c-0.8,1.6-0.1,9.9,0,11c-1.2,1.9-0.5,4.2-0.5,4.2l0.4,3.5l-3,5.3h5.2L54.1,75c0.6-0.6,1.2-2.5,1.4-3.1 C56.4,70,56.2,68.7,55.8,67.9z"
      />
      <path
        fill={colors[2]}
        d="M41.1,55.6c0,0,0,0.3,2.3,0.2c-0.3,0.3-0.3,0.3-0.3,0.3s-1,0.8-2,0.1V55.6z"
      />
      <path
        fill={colors[2]}
        d="M55.8,55.6c0,0,0,0.3-2.3,0.2c0.3,0.3,0.3,0.3,0.3,0.3s1,0.8,2,0.1V55.6z"
      />
      <line fill="none" x1="63.1" y1="69.6" x2="63.2" y2="69.6" />
      <rect x="42.5" y="74.7" fill={colors[2]} width="1" height="0.5" />
      <rect x="41.6" y="66.8" fill={colors[2]} width="1.8" height="0.5" />
      <rect
        x="38.2"
        y="62.5"
        transform="rotate(-65.934 38.487 63.424)"
        fill={colors[2]}
        width="0.5"
        height="1.8"
      />
      <rect
        x="36"
        y="66.7"
        transform="rotate(-65.934 36.28 67.941)"
        fill={colors[2]}
        width="0.5"
        height="2.4"
      />
      <rect
        x="57.5"
        y="63.1"
        transform="rotate(-23.56 58.363 63.352)"
        fill={colors[2]}
        width="1.8"
        height="0.5"
      />
      <rect
        x="59.5"
        y="67.7"
        transform="rotate(-35.363 60.645 67.96)"
        fill={colors[2]}
        width="2.3"
        height="0.5"
      />
      <rect x="53.6" y="66.8" fill={colors[2]} width="1.8" height="0.5" />
      <rect x="53.5" y="74.7" fill={colors[2]} width="1" height="0.5" />
    </g>
  )
}

export default BeardOne
