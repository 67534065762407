import React from "react"

const LeatherArmor = ({ colors = ["#914E00", "#353891", "#223549"] }) => {
  return (
    <g id="LeatherArmor">
      <path
        fill={colors[0]}
        d="M73.4181976,77.4769897c0.1106415,0.2313385-0.4731445-1.6575165-0.4324951-2.6754456 c0.0570831-1.4294968,0.5632477-2.8162537,0.5632477-2.8162537c0.8448792-0.7644119,0.9356613-2.8259888,0.8217239-2.675705l0,0 c-1.310257-0.6762924,0.5864029-5.2902756,0.5864029-5.2902756l-2.2384644-0.7963448l0.1139374-0.2254295l-0.9114838-0.2254295 l-3.6023026,6.8394814l-3.689537-6.8394775l-0.9114761,0.2254295l0.1139374,0.2254295l-2.5535698,0.8365707 c0,0,2.2117653,4.573761,0.9015121,5.2500534l0,0c-0.0569649-0.1502914,0.0640602,2.1929092,0.8687057,2.6354675 c0,0,0.3332253,1.3542023,0.3218575,2.7760239c-0.0082359,1.0305786-0.3620872,2.0583878-0.3620872,2.8162537 c0,0-2.1928291,2.1014252-2.5346336,4.506012c1.595089-0.526001,7.1552582,3.5806732,7.1552582,3.5806732h1.2532806 c0,0,5.5260162-4.0463257,7.121109-3.5203247C75.8322144,79.6990967,73.3075562,77.2456589,73.4181976,77.4769897z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M73.3477936,77.5373383c0-0.7188263-0.4027405-1.717865-0.3620911-2.7357941 c0.0570831-1.4294968,0.5632477-2.8162537,0.5632477-2.8162537c0.8448792-0.7644119,0.8217239-2.675705,0.8217239-2.675705 c-2.0085754,4.3051147-6.038559,1.6531601-6.038559,1.6531601s-4.5193672,2.6720657-6.1524849-1.6531525l0,0 c-0.0569649-0.1502914,0.0640602,2.1929092,0.8687057,2.6354675c0,0,0.3332253,1.3542023,0.3218575,2.7760239 c-0.0082359,1.0305786-0.3620872,2.0583878-0.3620872,2.8162537c0,0-1.4215393,1.4483643-1.9589882,2.521225 c7.1891785-0.1609344,7.1857986,1.6629333,7.1857986,1.6629333s0.1096497-1.6629333,7.0306091-1.7702255 C74.5269394,78.2781525,73.3477936,77.5373383,73.3477936,77.5373383z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M73.4181976,77.4769897c-0.1106415-0.6839447-0.4530334-1.597168-0.412384-2.615097 c0.0570908-1.4294968,0.5632553-2.8162537,0.5632553-2.8162537c-2.1457214,1.3142548-3.3637085-0.1818771-5.3106537-0.1877518 c-2.0803146-0.006279-3.1649399,1.394722-5.1899605,0.147522c0,0,0.3332214,1.3542023,0.3218575,2.7760239 c-0.0082397,1.0305786-0.3620911,2.0583878-0.3620911,2.8162537c0,0,5.2268143,2.1841583,5.2268143,2.1841583 L73.4181976,77.4769897z"
      />
      <g fill={colors[2]}>
        <polygon
          opacity="0.39"
          points="68.3321152,70.9627457 72.7186127,63.298111 73.1173859,63.4483986"
        />
        <polygon
          opacity="0.39"
          points="68.2751465,70.9627457 63.8886566,63.298111 63.4898834,63.4483986"
        />
      </g>
      <rect
        x="68.235"
        y="70.959"
        opacity="0.53"
        fill={colors[2]}
        width="0.114"
        height="13.15"
      />
    </g>
  )
}

export default LeatherArmor
