import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaulL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaulL") ? (
    <g id="MaulL">
      <path d="M43.0088081,96.9134598c1.9292183,1.0926056,4.1454086,1.600914,6.2164116,1.3416595 c2.466423-0.2158203,25.9466553-1.2328339,38.8838882-2.2404099c1.219902-0.0950089,2.3731842,0.6321259,2.7586823,1.7934113 c1.3239136,3.9882278,2.993515,5.7166748,5.0343323,6.9138565c0.2278671,0.1336746,0.4741821,0.2263336,0.7315063,0.2861023 c0.8653107,0.2009888,2.9514313,0.6849213,3.7455902,0.8635864c0.2024994,0.0455551,10.8323975,0.2396393,11.0399246,0.2357864 c2.2285309-0.0413666,4.4593735-1.0855026,8.0792847-12.9008255c1.8125534-5.9161377,1.0822296-15.2937393,1.0650177-15.4122467 c-2.0543976-14.1471405-24.1075745-11.201973-26.9093475-4.0525665c-0.3556213,0.9074478-1.1964264,1.5212555-2.1657333,1.6230392 c-10.4039307,1.0924911-42.2090454,0.2086868-43.6108932,0.354744l-0.663826-0.0069351l-0.2558479,0.0484009l-0.1279221,0.0241928 c-0.3837814,0.0725937-1.6630363,0.3145828-1.6630363,0.3145828c-0.511692,0.0967865-0.9991951,0.3215103-1.486702,0.5462265l0,0 l-0.4875069,0.2247162c-0.847084,0.4252472-1.5662384,0.8262787-2.2369957,1.4831772 c-2.0364799,1.8427429-3.3918686,4.4841843-3.6512527,7.3158035c-0.1659966,1.2239227-0.1003304,2.2715225,0.1174545,3.4228516 C38.0509911,92.4186707,40.038929,95.222702,43.0088081,96.9134598z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaulL, { character: "WomanDwarf" })
