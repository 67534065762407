import React from "react"

const Boots = ({ colors = ["#7C5A41", "#E5C3AC", "#3C2A1F", "#603813"] }) => {
  return (
    <g id="Boots">
      <path
        fill={colors[0]}
        d="M80.1999969,103.3000031L83.7999954,102c0,0,0.0999985,3,0,8.0999985 c0.1999969,0.1999969,0.8000031,1.0999985,1.5999985,2.8000031c0.8000031,1.5,1.9000015,3.5,2.3000031,4.9000015l0,0l0,0l0,0l0,0 l-7.8144684,0.1103897l-1.3855362-0.2103882l-1-7l-2.4000015-7.4000015H80.1999969z"
      />
      <path
        fill={colors[1]}
        d="M85.5,115.6999969c0,0,0.0999985-0.8000031-1-0.6999969 c-0.5,0.0999985-0.1999969,0.6999969-0.1999969,0.6999969H85.5z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M84,110.4000015 c-1.8000031-1.0999985-2.4000015-1.0999985-4.3000031-0.0999985c-0.3000031,0.0999985-1,1.1999969-2.0999985,0.8000031 l-2.5-7.5999985l5-0.0999985l3.5999985-1.3000031c0,0,0.0999985,3,0,8.0999985 C83.9000015,110.0999985,83.9000015,110.1999969,84,110.4000015z"
      />
      <polygon
        fill={colors[2]}
        points="78.0999985,114.5 78.0999985,117.8000031 79.4000015,117.8000031"
      />
      <path
        fill={colors[3]}
        d="M76.9000015,103.5l3.3000031-0.0999985l2.4000015-0.5999985l0.0999985,7.3000031 c0,0,2.5,3.6999969,2.5,4.6999969c-0.3000031,0-0.5999985-0.1999969-0.9000015-0.1999969c-0.5,0-1,0.1999969-1,0.1999969 l-3.4000015-4.6999969L79.5,110.2000046L76.9000015,103.5z"
      />
      <path
        fill={colors[2]}
        d="M87.8000031,117.9000015l-2-0.9000015h-1.5999985l-4.6999969,0.9000015 C79.5,117.8000031,87.8000031,117.9000015,87.8000031,117.9000015z"
      />
      <g fill={colors[0]}>
        <path d="M83.0999985,104.6999969L83.1999969,107c-0.5999985,1-3.8000031,1.3000031-4.6999969,1 l-1.0999985-2.6999969C78.5,105.6999969,82.3000031,105.5,83.0999985,104.6999969z" />
        <path d="M82.9000015,102.5999985l-0.0999985,1.0999985c-0.6999969,0.5999985-4.5,1.0999985-5.4000015,0.8000031 l-0.5-0.9000015C78,103.5999985,82.0999985,103.0999985,82.9000015,102.5999985z" />
        <path d="M83.8000031,110.6999969c0,0,0.0999985,0.4000015,0.6999969,1.4000015c-1.1999969-0.5999985-3,0-3,0.5 l-0.8000031-1.1999969C82,110.1999969,83.8000031,110.6999969,83.8000031,110.6999969z" />
        <path d="M84.8000031,112.6999969c0.5999985,0.3000031,0.3000031,0.4000015,0.9000015,1.4000015 c-1.1999969-0.9000015-2.8000031-0.1999969-2.9000015,0.3000031l-0.9000015-1.3000031 C82.1999969,112.9000015,83.6999969,112.0999985,84.8000031,112.6999969z" />
        <path d="M80.4000015,109.4000015c1,0,2.0999985-0.6999969,2.9000015-1.3000031v2.1999969 c-1.4000015-0.5999985-3.5999985,0.5-3.5999985,0.5l-1-1.6999969C79.1999969,109,80.1999969,109.4000015,80.4000015,109.4000015 z" />
      </g>
      <g id="boots">
        <path
          fill={colors[0]}
          d="M57,103.1999969l-3.5999985-1.3000031c0,0-0.0999985,3,0,8.0999985 c-0.2000008,0.1999969-0.7999992,1.0999985-1.5999985,2.8000031c-0.7999992,1.5-1.9000015,3.8103943-2.2999992,5.2103958 l7.7999992-0.0999985h1.4000015l1-7.3103943l2.4000015-7.4000015H57z"
        />
        <path
          fill={colors[1]}
          d="M51.7999992,115.6999969c0,0-0.0999985-0.8000031,1-0.6999969 C53.2999992,115.0999985,53,115.6999969,53,115.6999969H51.7999992z"
        />
        <path
          opacity="0.2"
          fill="#21376C"
          d="M53.2000008,110.3000031 C55,109.2000046,55.6000023,109.2000046,57.5,110.2000046c0.2999992,0.0999985,1,1.1999969,2.0999985,0.8000031l2.5-7.5999985 l-5-0.0999985L53.5,102.0000076c0,0-0.0999985,3,0,8.0999985 C53.4000015,110.0999985,53.2999992,110.1999969,53.2000008,110.3000031z"
        />
        <polygon
          fill={colors[2]}
          points="59.2000008,114.5 59.2000008,117.8000031 57.9000015,117.8000031"
        />
        <path
          fill={colors[3]}
          d="M60.2999992,103.4000015L57,103.3000031l-2.4000015-0.5999985L54.5,110.0000076 c0,0-2.5,3.6999969-2.5,4.6999969c0.2999992,0,0.5999985-0.1999969,0.9000015-0.1999969c0.5,0,1,0.1999969,1,0.1999969 l3.4000015-4.6999969l0.4000015,0.0999985L60.2999992,103.4000015z"
        />
        <path
          fill={colors[2]}
          d="M49.5,117.8000031l2-0.9000015h1.5999985l4.7000008,0.9000015H49.5z"
        />
        <g fill={colors[0]}>
          <path d="M54.2000008,104.5999985l-0.0999985,2.3000031c0.5999985,1,3.7999992,1.3000031,4.7000008,1 l1.0999985-2.6999969C58.7999992,105.6999969,55,105.4000015,54.2000008,104.5999985z" />
          <path d="M54.4000015,102.5999985L54.5,103.6999969c0.7000008,0.5999985,4.5,1.0999985,5.4000015,0.8000031 l0.5-0.9000015C59.2000008,103.5999985,55.2000008,103.0999985,54.4000015,102.5999985z" />
          <path d="M53.5,110.6999969c0,0-0.0999985,0.4000015-0.7000008,1.4000015c1.2000008-0.5999985,3,0,3,0.5 l0.7999992-1.1999969C55.2999992,110.1999969,53.5,110.6999969,53.5,110.6999969z" />
          <path d="M52.5,112.6999969c-0.5999985,0.3000031-0.2999992,0.4000015-0.9000015,1.4000015 c1.2000008-0.9000015,2.7999992-0.1999969,2.9000015,0.3000031l0.9000015-1.3000031 C55.0999985,112.8000031,53.5,112,52.5,112.6999969z" />
          <path d="M56.7999992,109.3000031c-1,0-2.0999985-0.6999969-2.9000015-1.3000031v2.1999969 c1.4000015-0.5999985,3.5999985,0.5,3.5999985,0.5l1-1.6999969C58.0999985,109,57.0999985,109.3000031,56.7999992,109.3000031z" />
        </g>
      </g>
    </g>
  )
}

export default Boots
