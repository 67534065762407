import React from "react"

const HalfTank = ({ colors = ["#CBC8A9", "#262261", "#FFFFFF"] }) => {
  return (
    <g id="HalfTank">
      <g id="boobs_1_">
        <path
          id="halfTank"
          fill={colors[0]}
          d="M75.313179,36.0317268c-0.2553024,0.1460457-0.5324478,0.2374992-0.5324478,0.149189 v-0.2256317c-0.1827698,0.1762047-0.4143295,0.3539429-0.5746918,0.3539429 c-0.0446777,0,0.2764587-0.3874512,0.5746918-0.5852966v-0.2261887c0-0.1378746,0.2220688-0.3117752,0.4512558-0.4373627 c0.0130539-2.4496727,1.2863693-4.6930771,1.2863693-4.6930771l-2.8686066-0.9562016c0,0-0.1032104-0.043047-1.1750183,0.6898289 c0.0295258,0.0619221,0.0541687,0.1283836,0.0612183,0.2072697c0.1599426,1.7829037-1.5179672,2.1429195-2.904068,2.4146252 c-0.301445,0.0590591-0.5229568-0.1028328-0.6342316-0.317852c-3.8507767,2.4662857-9.0705261,7.1515923-10.1867332,6.3910713 c0.0565414,0.5169334,0.9139824,0.939537,1.0270309,1.7530365c0.1527252,1.0990219-0.3541489,0.123951,1.1096649,5.1509209 c0.2335167,4.2852631,0.0184479,5.6410446-0.6020508,6.6953545c-0.5306511,0.9016418-1.3713341,1.5696259-2.3314819,3.8070984 c0,0,6.1917,1.682209,10.6067543,1.682209s10.7425156-1.9950409,10.7425156-1.9950409 c-0.4987564-1.0901756-1.5576859-2.5716629-1.9596252-3.1283112c-1.2736282-1.7638512-1.3806076-1.2478256-1.0152206-7.4390678 c0.5194168-1.7471352,1.0388336-3.2077827,1.062439-4.5771599c0.4249802-0.9207878,0.7521515-1.6726799,0.802742-1.8179626 C77.28582,38.4592476,75.4917526,36.9429016,75.313179,36.0317268z M73.440773,30.3421974 c0.0141754-0.1887703,0.023674-0.4351902,0.1735611-0.5061188c0.2090836-0.0987816,0.3000107,0.2732468,0.5312271,0.2479038 c0.2418213-0.0265274,0.4495239,0.0253468,0.3895569,0.106245c-0.6319656,0.8526154-0.3364029,0.1277447-0.5715256,0.153574 c-0.0784683,0.0170345-0.1569366,0.0341377-0.235405,0.0511723 C73.5590363,30.4749775,73.4262543,30.5358524,73.440773,30.3421974z M74.6284027,34.3061371 c0.059967,0-0.5390854,0.6988792-0.8570709,0.6988792C73.7113647,35.0050163,74.3104095,34.3061371,74.6284027,34.3061371z"
        />
        <path
          display="none"
          opacity="0.1"
          fill={colors[1]}
          d="M77.1689835,39.2037277 c-0.0295486,0.1667671-0.0473328,0.3336411-0.0974655,0.4998207c-0.690979,2.2911263-6.3257904,3.873764-8.1945801,0.1644554 c-0.0056992-0.2147446-0.2483826-0.2146912-0.2540741,0.0000534c-1.5602798,3.4259377-7.5036049,2.1266174-8.1946373-0.1645088 c-0.0500755-0.1661797-0.0678596-0.3330536-0.0974617-0.4998207c-0.1191673,0.6538544-0.1076164,1.3401604,0.0974617,2.0201836 c0.6910324,2.2911263,3.1085815,8.8698235,5.3997078,8.1787338c1.5144577-0.4567871,4.3294754-0.4567871,5.8439255,0 c2.2911301,0.6910896,4.7086792-5.8876076,5.3996582-8.1787338 C77.2766495,40.5438881,77.2882004,39.8575821,77.1689835,39.2037277z"
        />
        <path
          opacity="0.05"
          fill={colors[1]}
          d="M77.1689835,40.0888367 c-0.0295486,0.1667671-0.0473328,0.3336411-0.0974655,0.4998207c-0.690979,2.2911263-6.325798,3.8737602-8.1945801,0.1644554 c-0.0056992-0.2147446-0.2483826-0.2146912-0.2540741,0.0000572c-1.5602798,3.4259453-7.5036049,2.1266136-8.1946373-0.1645126 c-0.0500755-0.1661797-0.0678596-0.3330536-0.0974617-0.4998207c-0.1191673,0.6538544-0.1076164,1.3401604,0.0974617,2.0201836 c0.4385185,1.4538498,1.881321,7.1707268,3.1865387,7.9507713s8.7215004,0.4908638,10.228199-0.0639877 c1.5066986-0.5548553,2.8043823-6.4803772,3.2285538-7.8867836C77.2766495,41.428997,77.2882004,40.742691,77.1689835,40.0888367 z"
        />
        <path
          opacity="0.15"
          fill="#FFF"
          d="M61.7503624,37.8398285 c0.0887833-0.1452789,3.6477242-0.5800133,5.949688,1.4453278s-1.8415756,3.1586189-1.8415756,3.1586189 C62.9149971,42.5848885,59.4349213,41.6288071,61.7503624,37.8398285z"
        />
        <path
          opacity="0.1"
          fill={colors[1]}
          d="M77.1689835,40.0888367 c-0.0295486,0.1667671-0.0473328,0.3336411-0.0974655,0.4998207c-0.690979,2.2911263-6.3257904,3.873764-8.1945801,0.1644554 c-0.0056992-0.2147446-0.2483826-0.2146912-0.2540741,0.0000572c-1.5602798,3.4259338-7.5036049,2.1266136-8.1946373-0.1645126 c-0.0500755-0.1661797-0.0678596-0.3330536-0.0974617-0.4998207c-0.1191673,0.6538544-0.1076164,1.3401604,0.0974617,2.0201836 c0.6910324,2.2911301,3.1085815,3.5882568,5.3997078,2.8971672c1.5144577-0.4567871,4.3294754-0.4567871,5.8439255,0 c2.2911301,0.6910896,4.7086792-0.6060371,5.3996582-2.8971672C77.2766495,41.428997,77.2882004,40.742691,77.1689835,40.0888367 z"
        />
        <path
          opacity="0.15"
          fill="#FFF"
          d="M75.8575592,37.8398285 c-0.0887833-0.1452789-3.6477203-0.5800133-5.9496841,1.4453278c-2.3019714,2.025341,1.841568,3.1586189,1.841568,3.1586189 C74.6929245,42.5848885,78.1729965,41.6288071,75.8575592,37.8398285z"
        />
      </g>
    </g>
  )
}

export default HalfTank
