import React from "react"

const PlateGoldArmor = ({
  colors = ["#8A784E", "#EED793", "#A59A76", "#E6BB21"]
}) => {
  return (
    <g id="PlateGoldArmor">
      <path
        fill={colors[0]}
        d="M62.9,53.8L57.4,53c0,0-6,2-8.7,2s-9-2.1-9-2.1l-6.8,1.3c0,0-2.6,4.4-2.1,8.9s3.1,8.8,3.1,8.8L33.6,83 h29.6L63,71.8c0,0,2.7-4.4,3.1-8.8C66.6,58.5,62.9,53.8,62.9,53.8z"
      />
      <path
        fill={colors[1]}
        d="M37.2,80.2c0,0-3.3-17.6,11.2-17.6s12.2,17.1,12.2,17.1L37.2,80.2z"
      />
      <path
        fill={colors[0]}
        d="M49.1,58.5L49.1,58.5h-0.4h-0.4l0,0l-16,0.4l1.2,4.9c0,0,0,0.9,9,2.9c2.8-0.2,4.7,0,6.2-0.5 c1.6,0.5,3.4,0.4,6.2,0.5c9-2,9-2.9,9-2.9l1.2-4.9L49.1,58.5z"
      />
      <path
        fill={colors[0]}
        d="M48.9,55.7L48.9,55.7h-0.4h-0.4l0,0l-13.3,1.9l0.4,5.6c0,0,0.7,0.2,7,2.2c2.8-0.3,4.7,0,6.2-0.6 c1.6,0.6,3.4,0.4,6.2,0.6c5.1-1.5,6.3-2,6.3-2l1-5.1L48.9,55.7z"
      />
      <path
        fill={colors[1]}
        d="M48.5,63.5c0,0,3.7,0.8,5.8,0.6c2.1-0.2,7.1-2.2,7.1-2.2l0.8-3.9L49,55.4l0,0h-0.4h-0.4l0,0l-13.3,1.9 l0.3,4.8c0,0,4.8,1.6,7.2,1.7S48.5,63.5,48.5,63.5z"
      />
      <path
        fill={colors[2]}
        d="M61,62.9c0,0-3.1,1.6-6.4,2.1c-2.6,0.3-5.6-0.4-6.2-0.7c-0.7,0.3-3.2,0.9-6,0.5c-3.2-0.4-7.3-1.9-7.3-1.9 L35,57.7h-0.2l0.4,5.6c0,0,0.7,0.2,7,2.2c2.8-0.3,4.7,0,6.2-0.6c1.6,0.6,3.4,0.4,6.2,0.6c5.1-1.5,6.3-2,6.3-2l1-5.1l-0.6-0.1 L61,62.9z"
      />
      <path
        fill={colors[0]}
        d="M33.6,76.8c0,0,9.5,2.2,15.7,2.2s13.9-2.5,13.9-2.5l0.7,3.2c0,0-8.2,4.4-14.5,4.4s-16-4.4-16-4.4 L33.6,76.8z"
      />
      <path
        fill={colors[0]}
        d="M37.1,77.9c0,0.2,0.1,0.3,0.1,0.3l23.2-0.5c-0.6-5.7-5.5-10.2-11.6-10.2C42.6,67.5,37.5,72.1,37.1,77.9z"
      />
      <path
        fill={colors[2]}
        d="M37.3,79.5c0,0.2,0.1,0.3,0.1,0.3l23.1-0.5c-0.6-5.7-5.5-10.2-11.6-10.2C42.7,69.1,37.7,73.7,37.3,79.5z"
      />
      <path
        fill={colors[2]}
        d="M35.4,79.9c-0.8-0.3-1.4-0.6-1.7-0.7l-1.4,11.3l0.6,4.2l2.9-3.2l-0.4-0.5V79.9z"
      />
      <path
        fill={colors[2]}
        d="M64.7,90.1l-1.1-10.8c-0.4,0.2-0.9,0.4-1.5,0.7l-0.5,10.7L61.4,91l3.2,3.7L64.7,90.1z"
      />
      <path
        fill={colors[1]}
        d="M39.6,81.5c-0.6-0.2-1.1-0.4-1.6-0.6l-1.5,9.7l-0.7,0.8l2.5,3.8l1.9-3.2l-0.6-0.7V81.5z"
      />
      <path
        fill={colors[1]}
        d="M37.9,80.9c-1-0.4-1.8-0.7-2.6-1v11l0.4,0.5l0.7-0.8L37.9,80.9z"
      />
      <path
        fill={colors[1]}
        d="M59.7,81c-0.4,0.2-0.8,0.3-1.2,0.5L58,91.1l-0.9,1.4l1.9,2.7l2.4-4.2l-0.3-0.3L59.7,81z"
      />
      <path
        fill={colors[1]}
        d="M62,80c-0.7,0.3-1.4,0.6-2.3,1l1.3,9.7l0.3,0.3l0.2-0.3L62,80z"
      />
      <path
        fill={colors[2]}
        d="M56,82.4c-0.8,0.3-1.7,0.5-2.5,0.7l-1.1,9.3l2.7,3.3l2.1-3.2L56,90.9V82.4z"
      />
      <path
        fill={colors[2]}
        d="M58.5,81.5c-0.8,0.3-1.6,0.6-2.5,0.9v8.5l1.1,1.6l0.9-1.4L58.5,81.5z"
      />
      <path
        fill={colors[2]}
        d="M41.3,82l-0.4,8.7L40.1,92l3,3.7l2.3-3.5L44.8,83C43.6,82.7,42.4,82.4,41.3,82z"
      />
      <path
        fill={colors[2]}
        d="M41.3,82c-0.6-0.2-1.1-0.4-1.7-0.6v9.8l0.6,0.7l0.7-1.3L41.3,82z"
      />
      <path
        fill={colors[1]}
        d="M51.4,83.4c-0.7,0.1-1.4,0.1-2,0.1c-0.8,0-1.8-0.1-2.7-0.3l-0.5,7.8l-0.7,1.1l0.3,3.8l2.8,3l3.3-3.2 l0.4-3.4l-0.9-1.1V83.4z"
      />
      <path
        fill={colors[1]}
        d="M51.4,83.4v7.8l0.9,1.1l1.1-9.3C52.7,83.2,52,83.4,51.4,83.4z"
      />
      <path
        fill={colors[1]}
        d="M46.7,83.3c-0.6-0.1-1.2-0.2-1.9-0.4l0.7,9.2l0.7-1.1L46.7,83.3z"
      />
      <path
        fill={colors[3]}
        d="M35.4,79.3h2.8l-4.5-0.4l0,0.2c0.4,0.2,1,0.4,1.7,0.7V79.3z"
      />
      <path
        fill={colors[3]}
        d="M59.5,79.2l0,0.1h2.6l0,0.7c0.6-0.3,1.1-0.5,1.5-0.7l-0.1-0.5L59.5,79.2z"
      />
      <path
        fill={colors[3]}
        d="M63.3,77.1c-2,0.6-8.7,2.4-14,2.4S36,77.7,33.7,77.2l-0.1,1.9c0,0,0.1,0,0.1,0l0-0.2l4.5,0.4h-2.8v0.6 c0.7,0.3,1.6,0.6,2.6,1c0.5,0.2,1.1,0.4,1.6,0.6c0.5,0.2,1.1,0.4,1.7,0.6c1.2,0.4,2.4,0.7,3.5,1c0.6,0.1,1.3,0.3,1.9,0.4 c1,0.2,1.9,0.3,2.7,0.3c0.6,0,1.3-0.1,2-0.1c0.7-0.1,1.4-0.2,2.1-0.4c0.9-0.2,1.7-0.4,2.5-0.7c0.9-0.3,1.7-0.6,2.5-0.9 c0.4-0.2,0.8-0.3,1.2-0.5c0.9-0.4,1.7-0.7,2.3-1l0-0.7h-2.6l0-0.1l4-0.4l0.1,0.5c0.1,0,0.2-0.1,0.2-0.1L63.3,77.1z"
      />
    </g>
  )
}

export default PlateGoldArmor
