import React from "react"

const BroadSwordR = ({ colors = ["#898888", "#FFFFFF"] }) => {
  return (
    <g id="BroadSwordR">
      <path
        fill={colors[0]}
        d="M41.4,54.1l2.6-0.8l1.1-1.3L44.3-7.2l-0.9-4.3l-0.5-0.8c0,0-2.2,3.4-2.5,6.3 C40.2-3.2,41.4,54.1,41.4,54.1z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M41.4,54.4c0,0-1.3-57.3-1-60.2s2.5-6.3,2.5-6.3 s0.9,65.2,1.1,65.7L41.4,54.4z"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="44,53.5 45.1,52.2 44.3,-7 43.4,-11.3"
      />
      <path
        fill={colors[0]}
        d="M41.7,54.4l-0.2,8.7l1.5,0.6l1.8-1.3c0,0-0.2-5.1-0.2-8.3l2.8-1.1l0.2-1.9L47,51l-9.5,1.2l-0.1,2.1 L41.7,54.4z"
      />
    </g>
  )
}

export default BroadSwordR
