import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const ShortswordRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("ShortswordRight") ? (
    <g id="ShortswordRight">
      <path
        id="S_x24_shortswordRight"
        d="M30.4894371,98.306015c-2.2000008,0-4.2000008-0.8000031-5.7999992-2.3000031 c-1.1000004-1-1.8999996-2.3000031-2.2999992-3.6999969v-0.0999985c-0.1000004-0.3000031-0.2000008-0.6999969-0.2000008-1 c-2-0.0999985-3.8000011-0.9000015-5.3000011-2.3000031c-1.6999998-1.5999985-2.6999998-3.9000015-2.6999998-6.1999969 c0-2.5,1-4.6999969,2.6999998-6.3000031L6.9894352,45.506012l-0.0999999-4l1.2999997-4.9000015l11.1000013-3.6000023 l4.1000004,3.2000008l2.5,3.7000008l8.3999996,30.3999977c1.7000008,0.1999969,3.4000015,1,4.7000008,2.1999969 c1.0999985,1,1.9000015,2.3000031,2.2999992,3.6999969v0.0999985c1.0999985,3.5999985-0.2000008,7.4000015-3.2000008,9.5 c0.2000008,0.4000015,0.2999992,0.8000031,0.5,1.1999969v0.0999985c1.4000015,4.4000015-0.9000015,9.0999985-5.2999992,10.5999985 C32.3894348,98.2060089,31.4894371,98.306015,30.4894371,98.306015z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(ShortswordRight, {
  character: "ManHalfling"
})
