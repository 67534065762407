import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BowLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BowLeft") ? (
    <g id="BowLeft">
      <path
        id="S_x24_bowLeft"
        d="M34.5,119.4l-0.3-1.4l-0.2-1.8v-0.1l0.2-1.8l0.1-0.5L35,112l0.3-0.6l0.2-0.3l33.8-76.9V34l0.2-0.6 l0.8-1.7l0.3-0.5l1.2-1.4l0.1-0.1l1.5-1.1l0.9-0.5l1.7-0.6h0.1l1.8-0.2c4.3,0.2,7.7,3.4,8.4,7.9l0.1,0.6V36v1 c0.4,1,1.1,2.6,2.3,5.4l0.4,0.8C94.9,57,70.1,114,56,119.1L34.5,119.4z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BowLeft, { character: "ManDwarf" })
