import React from "react"

const FlameRight = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameRight">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M39.6682777,63.7213135c-2.1204948-0.7068405-4.1231842-1.413662-5.8902626-2.2383118 c-0.3534164-0.5890274-0.8246384-1.178051-1.1780548-1.7670784c-0.9424381-1.5314713-1.7670765-3.0629272-2.4739113-4.7122078 c0.1178093-0.4712143,0.3534145-0.9424477,0.4712238-1.413662c0.235611-0.5890236,0.5890255-1.178051,0.8246365-1.7670784 c0.4712181-0.8246307,0.94244-1.7670784,1.2958565-2.7095222l1.178051-0.2356071l-0.5890236-0.8246346l-0.1178055-0.117794 c2.4739075-1.5314713,4.0053749-3.1807365,4.4765968-4.9478149c0.1178055-0.5890274,0.2356148-1.2958641,0.1178055-1.8848915 l0.7068329-0.117794c0.235611,0.4712143,0.4712181,0.8246346,0.5890274,1.178051 c0.7068291,1.413662,1.7670784,2.7095261,2.7095184,4.0053711c1.178051,1.5314713,2.2382965,3.0629425,2.9451294,4.5944176 c1.7670746,3.8875732,1.413662,7.6573372-1.178051,12.1339417 C42.2599907,63.4857063,40.9641304,63.7213135,39.6682777,63.7213135z"
        />
        <path
          fill={colors[1]}
          d="M39.5504684,63.8391228c-1.7670784-0.5890236-3.5341568-1.178051-5.0656242-1.8848877 c-0.3534164-0.4712143-0.7068291-0.9424477-0.94244-1.5314751c-0.9424438-1.4136581-1.649271-2.8273163-2.3561058-4.4766006 c0.1178074-0.4712143,0.235611-0.8246346,0.4712238-1.178051c0.235611-0.5890274,0.5890255-1.1780548,0.8246365-1.6492691 c0.3534164-0.7068367,0.8246384-1.5314713,1.178051-2.356102l1.7670784-0.2356071l-0.9424438-1.2958641 c2.1204948-1.413662,3.4163551-2.9451332,4.0053787-4.4766045c0.5890274,0.9424477,1.2958565,1.7670784,1.8848839,2.7095261 c1.0602493,1.5314713,2.120491,2.9451294,2.8273277,4.4766006c1.7670784,3.6519699,1.413662,7.1861267-1.0602493,11.4271049 C41.3175468,63.6035004,40.4929123,63.8391228,39.5504684,63.8391228z"
        />
        <path
          fill={colors[2]}
          d="M39.432663,63.9569168c-1.413662-0.4712105-2.8273201-0.9424438-4.1231804-1.5314713 c-0.235611-0.3534164-0.5890274-0.8246307-0.8246384-1.178051c-0.8246384-1.295845-1.5314674-2.7095222-2.2382965-4.1231842 c0.1178017-0.3534164,0.235611-0.5890236,0.3534126-0.9424438c0.2356148-0.5890274,0.5890274-1.178051,0.8246384-1.6492653 c0.3534164-0.5890274,0.7068291-1.2958641,0.94244-2.0026855l2.4739113-0.3534203l-1.1780548-1.6492844 c1.1780548-0.8246307,2.1204948-1.6492653,2.8273277-2.5917091c0.235611,0.2356033,0.3534164,0.4712296,0.5890274,0.8246307 c1.0602455,1.5314713,2.120491,2.9451294,2.8273239,4.3587914c1.6492729,3.2985497,1.2958603,6.7148933-0.9424438,10.7202835 C40.4929123,63.7213135,40.0216904,63.8391228,39.432663,63.9569168z"
        />
        <path
          fill={colors[3]}
          d="M39.3148613,63.9569168c-1.0602493-0.3534164-2.1204948-0.7068176-3.1807404-1.178051 c-0.2356148-0.3534203-0.3534164-0.5890274-0.5890274-0.9424438c-0.8246384-1.2958488-1.4136658-2.4738998-2.1204948-3.8875771 c0.1178055-0.2356071,0.1178055-0.4712143,0.235611-0.7068214c0.235611-0.5890274,0.5890274-1.060257,0.8246384-1.6492844 c0.235611-0.5890236,0.5890274-1.0602379,0.8246384-1.6492653l3.0629349-0.4712296l-1.4136658-1.8848763 c0.4712219-0.3534164,0.9424438-0.7068367,1.2958603-1.060257c0.8246384,1.178051,1.6492729,2.3561058,2.2382965,3.5341568 c1.5314674,3.0629425,1.1780548,6.2436829-0.7068291,9.895649 C39.6682777,63.8391228,39.5504684,63.9569168,39.3148613,63.9569168z"
        />
        <path
          fill={colors[4]}
          d="M38.961441,63.6035004c-0.5890236-0.2356071-1.2958565-0.4712143-1.8848801-0.7068214 c-0.1178093-0.2356071-0.235611-0.4712334-0.4712219-0.7068405c-0.7068329-1.178051-1.4136658-2.356102-2.0026932-3.5341568 c0-0.117794,0.1178093-0.2356071,0.2356148-0.4712105c0.235611-0.5890274,0.5890236-1.0602608,0.8246346-1.6492844 c0.235611-0.4712143,0.4712257-0.9424477,0.7068367-1.413662l3.0629311-0.4712105 C40.7285194,57.4776306,40.6107178,60.42276,38.961441,63.6035004z"
        />
        <path
          fill={colors[5]}
          d="M39.197052,63.9569168c-0.1178017-0.117794-0.1178017-0.2356033-0.235611-0.3534164 c-0.7068291-1.0602417-1.2958565-2.1204987-1.7670784-3.2985497c0-0.117794,0-0.117794,0.1178093-0.2356071 c0.235611-0.5890274,0.5890274-1.0602417,0.8246384-1.6492691c0.1178017-0.3534164,0.3534126-0.7068367,0.4712181-1.060257 l1.178051-0.2356071C40.4929123,59.3625221,40.2573013,61.6008148,39.197052,63.9569168 C39.197052,64.0747299,39.197052,63.9569168,39.197052,63.9569168z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M49.0926933,42.3985596c-1.6492729-2.3561058-1.8848801-4.5943985-4.2409859-6.597084 c-0.1178093,0-1.178051-0.8246422-1.2958603-0.8246422c-0.235611,0.1178055-1.6492691,0.2356148-1.6492691-0.2356148 c0.235611-1.178051,2.120491-1.5314636,1.5314674-2.591713c-0.4712257-0.8246365-2.5917168-5.8902607-3.1807442-6.8327007 c-0.94244,1.884882-3.8875694,6.7148991-5.5368462,8.364172c-0.1178017,0.117794-2.4739075,6.1258698-3.2985458,8.835392 c0.4712238-0.1178093,1.2958603-0.3534164,1.178051-0.1178093c-1.2958546,3.6519661-4.8300114,6.5970993-6.1258717,10.2490501 c0,0.1178131-0.235611,0.2356071-0.4712181,0.3534203c0.4712181,2.238308,2.4739094,4.1231804,4.7122097,5.7724648 c-1.1780529-1.8848915-2.1204929-3.8875771-2.1204929-6.0080719c0-0.2356071,0.7068291-0.5890274,1.4136639-0.8246346 c0.235611-2.3561058,0.5890255-4.4766006,2.7095184-6.5970802c2.7095184-2.591713,4.0053749-6.7149048,4.0053749-6.7149048 c0.1178093-0.4712219,3.1807442-5.3012352,2.4739113-7.4217281c-0.235611-0.5890274,0.5890274,1.0602474,0.5890274,1.5314655 c0.1178055,0,0.235611,0,0.235611,0.1178055c0,1.6492729,1.1780548,7.1861153,1.2958565,7.3039207 c2.5917168,1.8848915,2.7095184,2.238308,3.8875732,4.5944138c1.413662,2.9451294,2.2383003,4.8300018,2.356102,7.8929482 c0,0.117794-0.1178017,0.2356071-0.235611,0.3534164c0.94244,1.7670784-0.4712219,3.6519508-0.94244,5.4190292 c-0.1178017,0.2356071-0.1178017,0.4712334-0.3534164,0.8246346c1.1780548-1.6492653,2.2383041-3.4163437,3.1807442-5.1834221 C49.2104988,50.1737099,49.2104988,46.2861328,49.0926933,42.3985596z"
      />
    </g>
  )
}

export default FlameRight
