import React from "react"

const DaggerRight = ({
  colors = [
    "#6E5E26",
    "#9AB8DB",
    "#6B92C0",
    "#57779C",
    "#E2E4E5",
    "#A0A2A5",
    "#EED793"
  ]
}) => {
  return (
    <g id="DaggerRight">
      <polygon
        fill={colors[0]}
        points="25.4311485,78.9497299 24.2311478,80.8497238 27.3311481,82.2497253 28.2311478,85.6497269 29.9311485,89.7497253 29.7311478,91.2497253 30.9311485,92.1497269 32.1311493,91.7497253 32.531147,90.2497253 31.6311493,89.3497238 30.6311493,84.8497238 29.3311481,81.6497269 30.9311485,78.6497269 28.9311485,77.8497238"
      />
      <polygon
        fill={colors[1]}
        points="32.031147,91.6497269 31.6311493,91.2497253 31.0311489,91.4497299 30.9311485,92.0497284"
      />
      <polygon
        fill={colors[2]}
        points="30.8311481,92.0497284 30.9311485,91.4497299 30.3311481,90.9497299 29.8311481,91.1497269"
      />
      <polygon
        fill={colors[1]}
        points="32.1311493,91.6497269 31.7311478,91.2497253 31.9311485,90.4497299 32.4311485,90.2497253"
      />
      <polygon
        fill={colors[3]}
        points="31.5311489,89.4497299 31.3311481,89.9497299 30.5311489,90.2497253 30.0311489,89.9497299"
      />
      <polygon
        fill={colors[2]}
        points="32.3311462,90.2497253 31.8311481,90.3497238 31.5311489,89.9497299 31.6311493,89.4497299"
      />
      <polygon
        fill={colors[3]}
        points="30.3311481,90.8497238 30.4311485,90.3497238 30.0311489,89.9497299 29.8311481,91.0497284"
      />
      <polygon
        fill={colors[2]}
        points="31.8311481,90.4497299 31.6311493,91.1497269 31.0311489,91.3497238 30.4311485,90.8497238 30.6311493,90.3497238 31.4311485,90.0497284"
      />
      <path
        fill={colors[4]}
        d="M25.2311478,80.4497299l2.2000008-0.9000015l2.3999996-0.5c0,0-1.3999996-2.5-2.2999992-7.8000031 c-1.3999996-5.2999954-5.6000004-9-5.6000004-9l0,0L25.2311478,80.4497299z"
      />
      <path
        fill={colors[5]}
        d="M27.5311489,79.4497299l-5.6000004-17.3000031c0,0-0.7999992,6.3000031,1.1000004,11.4000015 c1.6000004,3.5,2.2999992,6.9000015,2.2999992,6.9000015L27.5311489,79.4497299z"
      />
      <path
        fill={colors[6]}
        d="M29.1311493,87.5497284l0.7999992,2c0.2000008,0.0999985,0.5,0.0999985,0.7000008,0.0999985 l0.2000008-0.0999985c0.2000008-0.0999985,0.3999996-0.1999969,0.5-0.4000015l-0.7000008-3.5 C30.3311481,86.3497238,29.8311481,87.0497284,29.1311493,87.5497284z"
      />
      <path
        fill={colors[6]}
        d="M30.1311493,84.0497284l-1-2.3000031l-0.7000008,1l-1-0.4000015l0.7999992,3l0.7000008,1.5999985 C29.6311493,86.3497238,30.0311489,85.0497284,30.1311493,84.0497284z"
      />
    </g>
  )
}

export default DaggerRight
