import React from "react"

const Buckle = ({ colors = ["#9E812F", "#FFFFFF"] }) => {
  return (
    <g id="Buckle">
      <ellipse fill={colors[0]} cx="68.551" cy="55.15" rx="5.237" ry="2.933" />
      <path
        opacity="0.46"
        d="M68.5508575,57.5594406 c-2.7231598,0-4.7131653-1.2568474-4.7131653-2.3042145c0-1.0473709,1.8852692-0.2094765,4.7131653-0.2094765 s4.7131653-0.8378944,4.7131653,0.2094765C73.2640228,56.3025932,71.2740173,57.5594406,68.5508575,57.5594406z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M68.5508575,52.7415352 c2.7231598,0,4.7131653,1.2568436,4.7131653,2.3042145s-1.8852692,0.2094765-4.7131653,0.2094765 s-4.7131653,0.9426308-4.7131653-0.2094765S65.8276978,52.7415352,68.5508575,52.7415352z"
      />
      <path
        fill={colors[0]}
        d="M68.5508575,54.5220642c-2.4089508,0-4.0847473,0.6284218-4.608429,0.1047363 c0,0.1047363-0.1047363,0.314209-0.1047363,0.4189491c0,1.1521072,1.8852692,0.2094765,4.7131653,0.2094765 s4.7131653,0.9426308,4.7131653-0.2094765c0-0.1047363,0-0.314209-0.1047363-0.5236855 C72.6356049,55.150486,70.959816,54.5220642,68.5508575,54.5220642z"
      />
    </g>
  )
}

export default Buckle
