import React from "react"

const PlateBasiceGauntlets = ({
  colors = ["#9E9E9E", "#808080", "#B8B8B8"]
}) => {
  return (
    <g id="PlateBasiceGauntlets">
      <path
        fill={colors[0]}
        d="M32.7890739,79.5685043c0,0,2.814846-0.1572571,2.5016289,2.7325592 c-0.4944687,0.9526062-1.4868965,1.3792572-1.4868965,1.3792572s-1.4975395-2.5148087-3.3916035-1.2345047 C31.9390888,79.6038208,32.7890739,79.5685043,32.7890739,79.5685043z"
      />
      <polygon
        fill="gray"
        points="32.8948479,82.6600723 35.0421448,80.6840591 35.2752113,81.261322 33.2228813,82.9764404"
      />
      <polygon
        fill={colors[2]}
        points="32.7890739,79.5685043 31.0052948,82.1692657 31.6813507,82.0706406 33.461132,79.6161575"
      />
      <g id="plateBasiceGauntlets_1_">
        <path
          fill={colors[0]}
          d="M62.0281029,79.5685043c0,0-2.814846-0.1572571-2.5016289,2.7325592 c0.4944687,0.9526062,1.4868965,1.3792572,1.4868965,1.3792572s1.4975395-2.5148087,3.3916054-1.2345047 C62.8780861,79.6038208,62.0281029,79.5685043,62.0281029,79.5685043z"
        />
        <polygon
          fill="gray"
          points="61.9223289,82.6600723 59.775032,80.6840591 59.5419655,81.261322 61.5942955,82.9764404"
        />
        <polygon
          fill={colors[2]}
          points="62.0281029,79.5685043 63.811882,82.1692657 63.1358261,82.0706406 61.3560448,79.6161575"
        />
      </g>
    </g>
  )
}

export default PlateBasiceGauntlets
