import React from "react"

const MaulR = ({ colors = ["#898888", "#262261", "#FFFFFF"] }) => {
  return (
    <g id="MaulR">
      <path
        fill={colors[0]}
        d="M40,41.2c-0.3,0.4,4.1,37.9,4.1,37.9c-0.1,0.4,0.8,1.3,2.1,0.9c1.3-0.4,1.1-1.5,1.1-1.5 s-4.4-38.1-4.5-38.2C42.6,40.1,40.4,40.8,40,41.2z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M41.3,48c-0.3,0.1-0.5,0.1-0.7,0.1c-0.4-4-0.7-6.8-0.6-6.9 c0.3-0.4,2.6-1.1,2.8-0.9c0,0,0.4,2.7,0.8,6.6C42.6,47.5,41.6,48,41.3,48z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M44.8,56.5c0,0.2,0,0.3,0.1,0.5c-1.2-0.6-2.3-1.2-3.4-1.9 c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0C42.5,55.3,43.6,55.9,44.8,56.5z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M45.9,78.7c0-1.5,0-3-0.1-4.4c0.4,0.2,0.7,0.5,1.1,0.7 c0.3,2.2,0.4,3.6,0.4,3.6s0.1,0.4-0.2,0.8C46.7,79.1,46.3,78.9,45.9,78.7z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M42.7,67.3c-0.1-0.7-0.2-1.5-0.3-2.3c0.4,0.3,0.9,0.6,1.3,0.9 c0.1,0.7,0.1,1.4,0.2,2.2C43.5,67.8,43.1,67.5,42.7,67.3z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M43.9,77.6c-0.1-1.1-0.3-2.9-0.6-5c0.4,0.2,0.7,0.5,1.1,0.7 c0.2,1.6,0.3,3.2,0.5,4.8C44.6,78,44.3,77.8,43.9,77.6z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M41.1,53.1c0-0.1,0-0.3,0-0.4c0.1,0,0.1,0,0.2,0 c1.1,0.5,2.2,1,3.2,1.6c0,0.2,0.1,0.5,0.1,0.7C43.4,54.4,42.3,53.8,41.1,53.1z"
      />
      <path
        fill={colors[0]}
        d="M48.4,41.4c0,0-10.6,5.2-10.4,5.6s-6.1,0.1-6-0.3c0.1-0.5-3.2-10.2-3.2-10.2s6.8-3,12.1-4.6 c3.9-0.1,7-0.3,7.2-0.2L48.4,41.4z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M35.5,37.7l0.1,0.4c0.2,0,0.5,0,0.5,0c-0.6-0.8,0,0,0.1,0.2 c0.1,0.1,0.1,0.3,0.2,0.4c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.3,0.3l0.3,1.3 c0.2-0.2,0.3-0.3,0.3-0.4c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.3-0.2,0.5l0.1,0.2c0.2-0.2,0.4-0.4,0.4-0.4c0.1,0.1,0.1,0.1,0.1,0.2 c0,0.1-0.2,0.3-0.5,0.5l0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.2-0.1,0.3-0.2,0.3-0.2c0,0,0,0.1,0,0.1 c0,0.1-0.1,0.1-0.2,0.2c0.1,0.1,0.3,0.3,0.4,0.5c0,0,0,0.1-0.1,0.2c0.1,0.1,0.4,1.5,0.1,0.2l0.3-5.2L35.5,37.7z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M36.8,37.7l1.3,9.1c0,0,9.2-5.1,10-5.3 c0.8-0.2-0.1-9.6-0.1-9.6L47.6,32c0,0.3-0.1,0.5-0.4,0.5c-0.2,0-0.3,0-0.4,0l-1.3,0.7c0,0,0.1,0.1,0.1,0.1c0,0-0.1,0-0.1,0 c-0.2,0.1-0.5,0.1-0.7,0.2l-1.7,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.1,0.3-0.2,0.3-0.5,0.4 c-0.1,0-0.1,0-0.2,0c-0.2,0.1-0.4,0.2-0.6,0.3c0,0-0.1,0-0.1,0c0,0,0,0,0,0L41,35.4c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.1,0.3,0,0.5 c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.6,0.1-1,0.1c-0.3,0-0.5,0-0.8,0l-1.2,0.6c0,0,0,0,0,0 c0.1,0.2,0.2,0.3,0.3,0.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0C37.8,37.5,37.3,37.6,36.8,37.7z"
      />
    </g>
  )
}

export default MaulR
