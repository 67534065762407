import React from "react"

const SunstaffL = ({
  colors = ["#664000", "#FFFFFF", "#944200", "#BF8A00", "#SVGID_"]
}) => {
  return (
    <g id="SunstaffL">
      <path
        fill={colors[0]}
        d="M100.1999969,39.9000015V117l1,1.3000031L102.3999939,117l1.0999985-77 C103.4000015,40,102.3000031,37.5999985,100.1999969,39.9000015z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M103.3000031,52.0999985v-4.5 C103.2000046,47.5,102.7000046,47.1999969,102.5,47.3999977C101.9000015,48,101,50.9000015,100,51v3.7000008 C101.0999985,54.0999985,102.4000015,53,103.3000031,52.0999985z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M103.1999969,55.4000015v-1.5999985 c-0.5,0.4000015-1,0.7999992-1.5,1.0999985s-1,0.5-1.5,0.7000008c-0.0999985,0-0.0999985,0.0999985-0.1999969,0.0999985v3.5 C101.0999985,57.9000015,102.0999985,56.7000008,103.1999969,55.4000015z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M101.3000031,66 c0.5999985-0.8000031,1.1999969-1.5,1.8000031-2.2999992v-2.4000015C102.0999985,62.5999985,101,63.7999992,100,65v1 C100.5,66.1999969,100.9000015,66.1999969,101.3000031,66z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M103.1999969,59.5v-1.2000008 c-0.9000015,0.9000015-2,1.9000015-3.1999969,2.5v2.5999985C101,62.0999985,102.0999985,60.7999992,103.1999969,59.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M102.9000015,75.5v-3 C102,73.3000031,101,74,99.9000015,74.1999969v4.5999985C101,77.6999969,101.9000015,76.5999985,102.9000015,75.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M101.3000031,78.5C100.9000015,79,100.4000015,79.5,100,80 v0.6999969c1,0,2-0.5999985,2.9000015-1.5v-2.4000015C102.4000015,77.3000031,101.8000031,77.9000015,101.3000031,78.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M102.5999985,102.5999985v-2.5 c-0.8000031,0.5999985-1.6999969,1.1999969-2.5999985,1.5v3.6999969 C100.8000031,104.3000031,101.6999969,103.5,102.5999985,102.5999985z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M102.5,104.6999969V103 c-0.9000015,1.0999985-1.6999969,2.1999969-2.5999985,3.3000031V107 c0.4000015-0.1999969,0.6999969-0.4000015,1.0999985-0.5999985C101.5999985,105.8000031,102,105.1999969,102.5,104.6999969z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M103.0999985,66.1999969L103.0999985,66.1999969 C102,67.4000015,101,68.5999985,100,69.9000015v0.1999969C101,68.8000031,102,67.5,103.0999985,66.1999969z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M100,72.5v0.5999985 c1.3000031-0.6999969,2.5-1.6999969,2.8000031-2C102.9000015,71,103,70.9000015,103.1000061,70.7999954v-2.1999969 C102,69.9000015,101,71.1999969,100,72.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M102.9000015,75.5v-3 C102,73.3000031,101,74,99.9000015,74.1999969v4.5999985C101,77.6999969,101.9000015,76.5999985,102.9000015,75.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M101.3000031,78.5C100.9000015,79,100.4000015,79.5,100,80 v0.6999969c1,0,2-0.5999985,2.9000015-1.5v-2.4000015C102.4000015,77.3000031,101.8000031,77.9000015,101.3000031,78.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M102.5999985,102.5999985v-2.5 c-0.8000031,0.5999985-1.6999969,1.1999969-2.5999985,1.5v3.6999969 C100.8000031,104.3000031,101.6999969,103.5,102.5999985,102.5999985z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M102.5,104.6999969V103 c-0.9000015,1.0999985-1.6999969,2.1999969-2.5999985,3.3000031V107 c0.4000015-0.1999969,0.6999969-0.4000015,1.0999985-0.5999985C101.5999985,105.8000031,102,105.1999969,102.5,104.6999969z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M102.5,107.4000015v-0.6999969 c-0.3000031,0.1999969-0.5999985,0.4000015-0.8000031,0.5c-0.3000031,0.1999969-0.6999969,0.4000015-1,0.5 c-0.1999969,0.3000031-0.5,0.5-0.6999969,0.8000031v1.3000031C100.8000031,109,101.6999969,108.1999969,102.5,107.4000015z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M100,111.5v1.9000015 c0.4000015-0.4000015,0.8000031-0.6999969,1.1999969-1.0999985c0.3000031-0.4000015,0.6999969-0.8000031,0.9000015-1.1999969 c0.0999985-0.0999985,0.1999969-0.3000031,0.3000031-0.5v-2.0999985C101.6999969,109.5,100.8000031,110.5,100,111.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M101.4000015,95.6999969 C100.9000015,96.5999985,100.5,97.5,100,98.2999954v1.5c0.9000015-0.8000031,1.8000031-1.5,2.6999969-2.3000031v-2.4000015 C102.3000031,95.3000031,101.8000031,95.5,101.4000015,95.6999969z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M100,91.4000015v2.3000031l2.8000031-2.4000015 L102.9000015,87.5C101.8000031,88.8000031,100.9000015,90.0999985,100,91.4000015z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M101.5,82.4000015 c0.5-0.5999985,0.9000015-1.3000031,1.4000015-1.9000015v-0.4000015c-0.8000031,0.5999985-1.8000031,1-2.9000015,1.0999985v3.5 C100.5,83.9000015,101,83.0999985,101.5,82.4000015z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M102.8000031,84.5999985v-2 c-0.9000015,1.1999969-1.9000015,2.3000031-2.9000015,3.5V87.5C100.5,87.1999969,101,86.8000031,101.5999985,86.4000015 C102,85.8000031,102.4000015,85.1999969,102.8000031,84.5999985z"
      />
      <path
        fill={colors[0]}
        d="M92.9000015,30.2000008c0-0.1000004,0.1999969-0.1000004,0.5-0.1000004 c1,0,3.4000015,0.1000004,4.6999969-2.7000008c1.5999985-3.5,1.9000015-6.1000004,3.8000031-5.3000011 s6.1999969,2,6.1999969,4.6000004s-2.5,7.1000004-3.5,11.1000004S103.5,47.5,103.5,47.5l-3.6999969-0.5999985 l-1.6999969-9.5999985c0,0,2-1.2999992,3.8000031-2.9000015c1.8000031-1.5999985,0.6999969-3.6000004-3.1999969-2.8999996 C94.8000031,32.2999992,92.9000015,30.2000008,92.9000015,30.2000008z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M98.8000031,31.6000004 c4-0.7000008,5,1.3000011,3.1999969,2.8999996s-3.8000031,2.9000015-3.8000031,2.9000015L99.8999939,47h0.0999985 c0-0.0999985,0-0.2000008,0-0.2999992c-0.3000031-6.0999985,3.5999985-9.1000023,3.8000031-9.3999977 c7-9-0.5999985-14.6000004-4.6999969-10.2999992C95.4000015,32.3000031,93,30.1000042,93,30.1000042 S94.8000031,32.2999992,98.8000031,31.6000004z"
      />
      <radialGradient
        id="SVGID_11_"
        cx="276.818"
        cy="376.922"
        r="3.964"
        gradientTransform="matrix(0 1 2 0 -651.943 -246.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={colors[2]} />
        <stop offset="0.824" stopColor={colors[3]} />
      </radialGradient>
      <radialGradient
        id="SVGID_12_"
        cx="352.779"
        cy="202.629"
        r="1.57"
        gradientTransform="scale(1 -1) rotate(-45 -50.76 389.846)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="SVGID_13_"
        cx="273.672"
        cy="328.101"
        r="2.329"
        gradientTransform="matrix(1.1271 1.1271 1.3801 -1.3801 -659.592 177.466)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_11_)" cx="101.9" cy="30.2" r="4" />
      <circle
        opacity="0.85"
        fill="url(#SVGID_12_)"
        cx="102.2"
        cy="27.9"
        r="1.6"
      />
      <path
        opacity="0.42"
        fill="url(#SVGID_13_)"
        d="M104.3000031,35.7000008 C102.5,37.5,99.9000015,37.7000008,98.5,36.2999992S97.3000031,32.2000008,99.0999985,30.5s4.4000015-2,5.8000031-0.6000004 C106.3000031,31.3999996,106.0999985,34,104.3000031,35.7000008z"
      />
      <g opacity="0.25" fill="#FFF">
        <path d="M104.4000015,37.5999985c0,0-2.0999985,4.0999985-1.9000015,9.7999992l1,0.2000008 c0,0,0.0999985-5.5999985,1.0999985-9.7000008c0.0999985-0.2999992,0.1999969-0.5999985,0.1999969-0.9000015 C104.6999969,37.2000008,104.5,37.4000015,104.4000015,37.5999985z" />
        <path d="M97.4000015,29c1.0999985-0.7999992,1.4000015-2.7000008,2.6999969-4.5 c2.6999969-3.7999992,6.8000031-0.1000004,7.6999969,2.7000008c0.0999985,0.2000008,0.0999985,0.2999992,0.0999985,0.5 c0.0999985-0.3999996,0.0999985-0.7999992,0.0999985-1.1000004c0-2.5-4.3000031-3.7000008-6.1999969-4.6000004 c-1.9000015-0.7999992-2.3000031,1.7999992-3.8000031,5.2999992C96.7999954,30,94.3999939,30,93.2999954,30 C93.6999969,30.1000004,95.6999969,30.3999996,97.4000015,29z" />
        <path d="M93,30.2999992l0.3000031-0.2000008c-0.1999969,0-0.4000015,0-0.4000015,0.1000004 C92.9000015,30.1999989,92.9000015,30.2000008,93,30.2999992z" />
      </g>
    </g>
  )
}

export default SunstaffL
