import React from "react"

const ShortswordLeft = ({ colors = ["#828284", "#CCCCCB", "#FFFFFF"] }) => {
  return (
    <g id="ShortswordLeft">
      <path d="M76.446991,84.4497299c-0.3000031,0.3000031-0.5999985,0.4000015-1,0.4000015h-0.0999985 c-0.8000031,0-1.4000015-0.6999969-1.4000015-1.5c0-0.1999969,0-0.4000015,0.0999985-0.5999985l-2.8000031-1 c-0.1999969,2.5999985-1,5.0999985-2.5999985,7.5999985c0.4000015,0.4000015,0.5999985,1,0.4000015,1.5v0.0999985 c-0.0999985,0.1999969-0.1999969,0.4000015-0.4000015,0.5999985c-0.4000015,0.4000015-0.9000015,0.5-1.5,0.3000031 c-0.8000031-0.3000031-1.1999969-1.0999985-0.9000015-1.9000015v-0.0999985 c0.0999985-0.1999969,0.1999969-0.4000015,0.4000015-0.5999985c0.1999969-0.1999969,0.5-0.3000031,0.8000031-0.4000015 c0.1999969-3.0999985,1.1999969-5.9000015,2.0999985-7.8000031l-3.0999985-1.0999985v0.0999985 c-0.0999985,0.1999969-0.1999969,0.4000015-0.4000015,0.5999985c-0.4000015,0.4000015-0.9000015,0.5-1.5,0.3000031 c-0.8000031-0.3000031-1.1999969-1.0999985-0.9000015-1.9000015v-0.0999985 c0.0999985-0.1999969,0.1999969-0.4000015,0.4000015-0.5999985c0.4000015-0.4000015,0.9000015-0.5,1.5-0.3000031 c0.1999969,0.0999985,0.4000015,0.1999969,0.5,0.3000031l2.9000015,1l10.3000031-37l4.0999985-3.2000008l1.3000031,4.9000015 L72.8469925,80.3497238l0,0l-0.1999969,0.5l2.8000031,1l0,0c0.8000031,0,1.4000015,0.6999969,1.4000015,1.5 C76.946991,83.8497238,76.746994,84.2497253,76.446991,84.4497299z" />
      <g id="longsworsShadow_1_">
        <path
          fill={colors[0]}
          d="M75.446991,82.1497269L75.446991,82.1497269l-3-1.0999985l11.5999985-37l-1.0999985-4.2000008 l-3.4000015,2.7999992L69.0469971,79.7497253l-3.3000031-1.1999969c-0.0999985-0.0999985-0.3000031-0.1999969-0.5-0.3000031 c-0.6999969-0.1999969-1.4000015,0.0999985-1.5999985,0.8000031v0.0999985 c-0.1999969,0.6999969,0.1999969,1.4000015,0.8000031,1.5999985l0,0 c0.6999969,0.1999969,1.4000015-0.0999985,1.5999985-0.8000031v-0.0999985c0-0.0999985,0-0.1999969,0.0999985-0.1999969 l3.5999985,1.3000031c-0.9000015,1.9000015-2,4.8000031-2.0999985,8.0999985c-0.5,0-1,0.3000031-1.1999969,0.8000031v0.0999985 c-0.1999969,0.6999969,0.1999969,1.4000015,0.8000031,1.5999985l0,0 c0.6999969,0.1999969,1.4000015-0.0999985,1.5999985-0.8000031v-0.0999985c0.1999969-0.5,0-1.0999985-0.5-1.4000015 c1.5-2.3000031,2.5999985-4.8000031,2.6999969-7.9000015l3.3000031,1.1999969 c-0.0999985,0.1999969-0.1999969,0.4000015-0.1999969,0.6999969c0,0.6999969,0.5999985,1.3000031,1.1999969,1.3000031h0.0999985 c0.6999969,0,1.1999969-0.5,1.3000031-1.1999969C76.6469955,82.7497253,76.1469955,82.1497269,75.446991,82.1497269z"
        />
        <polygon
          fill={colors[1]}
          points="82.8469925,39.9497261 70.6469955,80.2497253 72.6469955,80.1497269 84.0469971,44.0497284"
        />
      </g>
      <polygon
        fill="#FFF"
        points="70.5469971,80.2497253 82.8469925,40.0497284 71.0469971,80.2497253"
      />
      <polygon
        fill={colors[1]}
        points="66.3469925,79.6497269 74.3469925,82.5497284 74.446991,82.3497238 66.446991,79.5497284"
      />
      <path
        fill={colors[1]}
        d="M63.746994,79.6497269c0,0,0.8000031,1.5999985,2.0999985-0.0999985c0.0999985,0,0.1999969,0,0.1999969,0 s-0.1999969,1.3000031-1.4000015,1.1999969C63.946991,80.5497284,63.746994,79.6497269,63.746994,79.6497269z"
      />
      <path
        fill={colors[1]}
        d="M74.246994,83.2497253c0,0,1.1999969,1.8000031,2.1999969-0.1999969 c0.1999969,0.6999969-0.5999985,1.4000015-0.5999985,1.4000015S74.446991,84.8497238,74.246994,83.2497253z"
      />
    </g>
  )
}

export default ShortswordLeft
