import React from "react"

const Skirt = ({ colors = ["#914E00", "#223549"] }) => {
  return (
    <g id="Skirt">
      <path
        id="skirt"
        fill={colors[0]}
        d="M53.4000015,81.5c0,0,6.2000008,6.4000015,14.5,6.9000015S84.5,82,84.5,82l-6.1999969-9 c0,0-5.5,3.0999985-9.5,3.0999985S59,72.9000015,59,72.9000015L53.4000015,81.5z"
      />
      <g opacity="0.39" fill={colors[1]}>
        <path d="M58.5999985,75.9000015c0,0-2.5,0.5-3.5,4.1999969c-1,3.8000031,2,1.9000015,2,1.9000015 s3.5-4.0999985,2.5999985-5.8000031C58.7999992,74.5,58.5999985,75.9000015,58.5999985,75.9000015z" />
        <path d="M62.7000008,78.6999969c0,0-2.2999992,1-2.2000008,4.6999969s2.4000015,1.3000031,2.4000015,1.3000031 s2.1999969-4.6999969,0.9000015-6C62.5999985,77.4000015,62.7000008,78.6999969,62.7000008,78.6999969z" />
        <path d="M68.1999969,79.9000015c0,0-2,1.5-0.9000015,5.1999969s2.8000031,0.6999969,2.8000031,0.6999969 S71,80.5999985,69.4000015,79.5999985C67.6999969,78.5999985,68.1999969,79.9000015,68.1999969,79.9000015z" />
        <path d="M74.1999969,78.9000015c0,0-1.4000015,1.9000015,0.6999969,5 c2.0999985,3.1999969,2.8000031,0,2.8000031,0s-0.6999969-5-2.5-5.5 C73.3000031,77.8000031,74.1999969,78.9000015,74.1999969,78.9000015z" />
        <path d="M77.8000031,76.5999985c0,0-0.8000031,2.3000031,2.3000031,4.8000031 s2.8000031-0.6999969,2.8000031-0.6999969s-2.3000031-4.8000031-4.3000031-4.9000015 C76.5999985,75.8000031,77.8000031,76.5999985,77.8000031,76.5999985z" />
      </g>
    </g>
  )
}

export default Skirt
