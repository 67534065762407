import React from "react"

const SlingShotR = ({
  colors = ["#362812", "#454545", "#949494", "#724F1F"]
}) => {
  return (
    <g id="SlingShotR">
      <path
        fill={colors[0]}
        d="M40.9467697,64.6736145l-1.0999985-10.0000038l-6.2000008-2.8000031 c0,0-2.1999989,2-2.0999966,4.5999985c0,0.4000015-2.0999985,34.2000084-2.0999985,34.2000084 s0.2000008,2.4000015,1.2000008,2.8000031c0.9000015,0.3000031,3.1999989-0.4000015,3.1999989-0.4000015l0.5-2.8000031 l-0.5-34.2000046l-1.2000008-1.9000015l1.0999985-1.5l5.2999992,2.7999992l-1,2.2999992l0.0999985,8.7000046l2.2999992,2.6999969 l-0.5-5.4999962L40.9467697,64.6736145z"
      />
      <path
        fill={colors[1]}
        d="M29.2467709,89.0736084c0.7999992-1.0999985,1-1.8000031,2.5-1.5999985 c0.2000008,0,1.8999996,0.0999985,1.9999981,0.3000031c0.5,0.8000031,1.2000008,2.6999969,1.2000008,2.6999969 s-1.0999985,2.0999985-2.5,2.1999969c-0.9999981,0.0999985-3.0999966-0.9000015-3.0999966-0.9000015 S28.8467731,89.6736145,29.2467709,89.0736084z"
      />
      <path
        fill={colors[2]}
        d="M31.5467701,88.8736115c0.2000008-0.4000015,0.4000015-0.9000015,0.5999966-1.3000031 c0.5999985,0,1.4000015,0.0999985,1.5999985,0.3000031c0.5,0.8000031,1.2000008,2.6999969,1.2000008,2.6999969 s-0.4000015,0.8000031-1.0999985,1.4000015c-0.7000008-0.1999969-1.4000015-0.5999985-2.0999966-1.0999985 C31.6467724,90.1736145,31.6467724,89.4736099,31.5467701,88.8736115z"
      />
      <path
        fill={colors[3]}
        d="M32.6467705,92.3736115l-1.3999996-0.0999985l-0.2000008-2.4000015l0.5999985-2.5999985 l1.3000011-32.2000046l-0.2999992-0.9000015l-0.7999973,0.5l-2.2999992,35.9000015c0,0,0.4000015,2.8000031,1.7000008,2.4000015 c0.2000008-0.0999985,1.7999973,0,2.3999996-0.4000015C32.8467712,92.273613,32.6467705,92.3736115,32.6467705,92.3736115z"
      />
    </g>
  )
}

export default SlingShotR
