import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const DaggerRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("DaggerRight") ? (
    <g id="DaggerRight">
      <path
        id="S_x24_daggerRight"
        d="M35.8616524,94.2587204l3.415947-3.6339874l-1.0901947-7.4133301l-1.8896751-5.5236511 c0,0-5.305624-13.0823593-6.0324192-13.7364769l0,0l-4.4334641,0.4360771l-3.997385,2.180397l3.0525513,14.8266602 l1.4535942,4.6514969l0.2180424,0.7268066l1.2355518,2.9798584l1.3809185,3.1979141l0.8721523,0.726799l4.8695412,0.9448395 L35.8616524,94.2587204z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(DaggerRight, {
  character: "ManGnome"
})
