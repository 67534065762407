import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import WizardRobe from "./WizardRobe"

const CATEGORY = "robe"
const CHARACTER = "ManHalfling"
export const IDS = {
  WIZARD_ROBE: "WizardRobe"
}

export const components = {
  [IDS.WIZARD_ROBE]: WizardRobe
}

export const Group = ({ props }) => (
  <g id="robe">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.WIZARD_ROBE,
    name: "Wizard Robe",
    defaultColors: [
      "#999999",
      "#525A55",
      "#4C3317",
      "#895A24",
      "#FFFFFF",
      "#313633",
      "#D7D6D5"
    ],
    colorable: true,
    component: components[IDS.WIZARD_ROBE],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
