import React from "react"

const HipDaggerLeft = ({
  colors = [
    "#66451E",
    "#895A24",
    "#9E8835",
    "#9AB8DB",
    "#6B92C0",
    "#57779C",
    "#E2E4E5",
    "#A0A2A5",
    "#EED793"
  ]
}) => {
  return (
    <g id="HipDaggerLeft">
      <polygon
        fill={colors[0]}
        points="61,78.9 58.9,73.3 56.6,73.7 58.2,79.8"
      />
      <polygon
        fill={colors[1]}
        points="62.9,77.9 57.8,78.5 61.1,91.2 61.9,91.6 62.6,91.1"
      />
      <polygon
        fill={colors[0]}
        points="61.7,90.9 58.8,78.4 57.8,78.5 61.3,90.8 61.4,91.2"
      />
      <polygon
        fill={colors[2]}
        points="59.1,78.7 57.8,77.8 59.4,75.9 59.1,73.5 59,70.2 58.5,69.3 59,68.3 59.9,68.2 60.6,69.1 60.3,70 60.9,73.3 60.9,75.8 62.9,77.3 61.8,78.5"
      />
      <polygon
        fill={colors[3]}
        points="59.9,68.3 59.7,68.7 59.3,68.7 59,68.4"
      />
      <polygon
        fill={colors[4]}
        points="59,68.4 59.2,68.8 58.9,69.3 58.5,69.3"
      />
      <polygon
        fill={colors[3]}
        points="59.9,68.4 59.8,68.7 60.1,69.2 60.5,69.1"
      />
      <polygon
        fill={colors[5]}
        points="60.2,70 59.9,69.7 59.3,69.7 59.1,70.1"
      />
      <polygon
        fill={colors[4]}
        points="60.5,69.2 60.1,69.3 60,69.6 60.2,69.9"
      />
      <polygon
        fill={colors[5]}
        points="58.9,69.4 59.2,69.7 59,70.1 58.6,69.4"
      />
      <polygon
        fill={colors[4]}
        points="60.1,69.2 59.7,68.8 59.3,68.8 59,69.3 59.3,69.6 59.9,69.6"
      />
      <polygon
        fill={colors[6]}
        points="58.6,77.8 60.3,77 62.1,77.4 61.7,90.9 61.7,90.9"
      />
      <polygon fill={colors[7]} points="60.3,77 61.7,90.8 58.6,77.7" />
      <path
        fill={colors[8]}
        d="M59.2,71.9v-1.6c0.1-0.1,0.3-0.2,0.5-0.3h0.1c0.1,0,0.3,0,0.4,0.1l0.5,2.5 C60.2,72.4,59.7,72.1,59.2,71.9z"
      />
      <path
        fill={colors[8]}
        d="M60.8,73.9v1.8l-0.7-0.5l-0.6,0.6l-0.3-2.2v-1.3C59.8,72.6,60.4,73.3,60.8,73.9z"
      />
    </g>
  )
}

export default HipDaggerLeft
