import React from "react"

const ShoulderPlateGold = ({ colors = ["#8A784E", "#EED793", "#A59A76"] }) => {
  return (
    <g id="ShoulderPlateGold">
      <path
        opacity="0.42"
        fill="#21376C"
        d="M42.5992279,67.3687973 c-0.2929077-0.4881821-3.7101631-1.9039001-5.5652466,0.4393616c0,0,0.1952744,0.9763565,0.244091,2.0991745 c0.7322693,0.8787231,1.806263,1.1228104,3.1731682,1.1228104c0.6346321,0,1.1228104-0.1464539,1.3669014-0.4881821 c0.0488167-0.0488129,0.0488167-0.0976334,0.0976372-0.2440872 C42.2086868,69.5167847,42.5992279,67.3687973,42.5992279,67.3687973z"
      />
      <path
        fill={colors[0]}
        d="M36.8387108,67.1735229c0,0-0.7810898,0.9763565-0.6834526,2.0991745 c1.269268,0.6346283,4.7353439,1.7574463,5.4676094,0.5858154c0.244091-0.3417282,0.7810898-3.1243515,0.7810898-3.1243515 C42.1110497,66.2947998,38.6449738,64.8790817,36.8387108,67.1735229z"
      />
      <path
        fill={colors[1]}
        d="M42.0134125,66.8318024c-0.3417244-0.4393616-2.6849861-1.3669052-4.5400696,0.0488129 C40.1583328,68.7845154,41.9645958,66.8806152,42.0134125,66.8318024z"
      />
      <path
        fill={colors[2]}
        d="M36.7410736,67.3687973C36.7410736,67.3687973,36.936348,67.0758896,36.7410736,67.3687973 c-0.1952705,0.2440872-0.6834488,1.0739975-0.5858154,1.9039001c1.269268,0.6346359,4.7353439,1.7574463,5.4676094,0.5858154 c0.1464539-0.1952744,0.4393654-1.6598129,0.5858192-2.3920822 C41.2811432,68.3939743,39.6213341,69.8096924,36.7410736,67.3687973z"
      />
      <g>
        <path
          opacity="0.42"
          fill="#21376C"
          d="M53.095089,67.3687973 c0.2929039-0.4881821,3.7101631-1.9039001,5.5652428,0.4393616c0,0,0.1464577,0.7322693,0.0488167,1.8550797 c-0.8787231,1.0739975-2.1479874,1.3669052-3.5148926,1.3669052c-0.6346321,0-1.1228104-0.1464539-1.3669014-0.4881821 c-0.0488167-0.0488129-0.0488167-0.0976334-0.0976372-0.2440872C53.48563,69.5167847,53.095089,67.3687973,53.095089,67.3687973z"
        />
        <path
          fill={colors[0]}
          d="M58.8556061,67.1735229c0,0,0.781086,0.9763565,0.6834526,2.0991745 c-1.269268,0.6346283-4.7353439,1.7574463-5.4676132,0.5858154c-0.2440872-0.3417282-0.781086-3.1243515-0.781086-3.1243515 C53.5832672,66.2947998,57.0493431,64.8790817,58.8556061,67.1735229z"
        />
        <path
          fill={colors[1]}
          d="M53.6809044,66.8318024c0.3417244-0.4393616,2.6849861-1.3669052,4.5400696,0.0488129 C55.535984,68.7845154,53.7297211,66.8806152,53.6809044,66.8318024z"
        />
        <path
          fill={colors[2]}
          d="M58.9532394,67.3687973C58.9532394,67.3687973,58.7579727,67.0758896,58.9532394,67.3687973 c0.1952744,0.2440872,0.6834526,1.0739975,0.5858154,1.9039001c-1.269268,0.6346359-4.7353439,1.7574463-5.4676132,0.5858154 c-0.1464539-0.1952744-0.4393616-1.6598129-0.5858154-2.3920822 C54.4131737,68.3939743,56.0729828,69.8096924,58.9532394,67.3687973z"
        />
      </g>
    </g>
  )
}

export default ShoulderPlateGold
