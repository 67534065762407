import React from "react"

const BracesR = ({ colors = ["#754C28", "#282560", "#262261", "#FFFFFF"] }) => {
  return (
    <g id="BracesR">
      <path
        fill={colors[0]}
        d="M53.4281502,50.9551506c0.2833214-0.796833-1.5139847-4.630497-4.8518372-2.5852852 c-1.5582542,2.4436264-2.7327766,5.277092-2.7505531,5.583744c-0.0177803,0.3066521-0.4064407,1.2726364-0.3169289,1.1460533 c0.0675621-0.0955238,0.3180771-0.361515,0.3641472-0.4377556c0.357338-0.4706459,0.546917-1.473259,2.0846176-1.0955009 c1.5377007,0.3777618,2.3246384,1.3325043,2.2123871,1.9454575c-0.0442696,0.097393-0.3483696,0.3318214-0.3069305,0.377758 c0.0138168,0.015316,0.2917862,0.0164528,0.3069305,0.0472221C52.3657074,52.5724297,53.1448326,51.7519836,53.4281502,50.9551506 z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M48.8976135,49.5296402 c-0.1870232,0.0209541-0.4315338,0.0508232-0.8288956,0.1033173c0.1711197-0.2113609,0.547905-0.2775536,0.9242096-0.2880325 L49.3097,48.7310448c-0.1832085,0.0208435-0.4205208,0.0500717-0.7924194,0.0991783 c0.165802-0.2048073,0.5249138-0.273468,0.8894501-0.2872772l0.3146782-0.6098557l0.4249802,0.0235863 c-0.0440025,0.0880051-0.1684875,0.3203735-0.3151093,0.5915337c0.345356,0.0211143,0.6219978,0.0695229,0.6219978,0.0695229 c-0.3479347,0.0376091-0.5273285,0.0528145-0.6963005,0.0678558c-0.1198654,0.2212486-0.2475204,0.455658-0.3622284,0.6660004 c0.3397179,0.0215454,0.6099625,0.0688782,0.6099625,0.0688782c-0.3395004,0.0366936-0.5184631,0.0520058-0.6839447,0.0667267 c-0.1485519,0.2721291-0.2604675,0.4763451-0.2604675,0.4763451l-1.7470894,3.0456657l-0.259716,0.0944519L48.8976135,49.5296402z"
      />
      <path
        opacity="0.2"
        fill={colors[2]}
        d="M48.9512482,53.3435249 c0.0199356,0.0307846,0.0407791,0.0520592,0.0629692,0.0505562c0.553009-0.038147,1.0901184-0.0787621,1.5752182,0.2398376 c0.0406723,0.0266457,0.1221199-0.7364883-0.0597992-0.8480263c-0.012516-0.0076828-0.0254669-0.0109062-0.0380898-0.0172997 c-0.0072556-0.067749-0.0140228-0.1354446-0.0205269-0.2028198c0.0414772,0.048893,0.0834923,0.0973549,0.124218,0.1468887 c0.059906,0.0728531,0.0670509-0.2851791,0.0644188-0.319458c-0.0119286-0.1566162-0.0254669-0.4042435-0.1358223-0.5276527 c-0.181057-0.2024956-0.370285-0.397419-0.5514526-0.5998077c-0.0108528-0.0121422-0.0171928,0.0012894-0.0239067,0.0121956 c-0.0006447-0.0005341-0.0010757-0.0011253-0.0017204-0.0016632c-0.0469017-0.0373421-0.0997162-0.0631294-0.1496277-0.0948296 c0.1080437-0.1331329,0.2170563-0.2663231,0.3235435-0.3985977c0.0044022,0.0048866,0.0065002,0.0133781,0.0112801,0.0176201 c0.5642929,0.5019188,1.1839256,1.1154251,1.3089485,1.8905411c0.0741959,0.4597435,0.1812744-0.0344925,0.1703682-0.2281227 c-0.0330429-0.5863228-0.2540741-1.0669098-0.5705795-1.4950562c0.1882591,0.1949196,0.3570671,0.4022522,0.4975662,0.6406364 c0.0916557,0.1554871,0.0892372-0.1193275,0.0881653-0.1755791c-0.0036545-0.1916428-0.0439491-0.4169731-0.1331367-0.5880394 c-0.1384544-0.265625-0.3418617-0.466835-0.546402-0.6721764c0.0667839-0.0065575,0.1328125,0.0062294,0.19981,0.0413132 c0.0324516,0.035675,0.055069,0.0450783,0.0695229,0.0310555c0.1279793,0.0891342,0.2388153,0.2267799,0.2901268,0.3368683 c0.0162773,0.0231552,0.0351372,0.0254669,0.0565186,0.0069847c0.6531563-0.2793808,0.8350792-0.8989067,0.8262138-1.5732841 c0.3638382,0.4212723,0.8057938,1.0961342,0.8265877,1.9277191c-0.8145561,1.4520226-2.7977753,4.1435242-2.7977753,4.1435242 s-0.1363029-1.4287567-1.6540337-1.7218399C48.8263893,53.3582458,48.8883896,53.3479309,48.9512482,53.3435249z"
      />
      <g fill="#FFF">
        <path
          opacity="0.19"
          d="M49.3908577,49.7274361 c-0.0211296,0.1056442-1.4982605,2.6883812-1.8084373,3.2299614c-0.0364151,0.0635872-0.0567474,0.0990372-0.0567474,0.0990372 l1.7707443-3.4470482L49.3908577,49.7274361z"
        />
        <path
          opacity="0.19"
          d="M50.2880325,48.0747414 c0,0.0138435-0.2124901,0.4013672-0.2124901,0.4013672l-0.1416588-0.0236092L50.2880325,48.0747414z"
        />
        <polygon
          opacity="0.19"
          points="49.9338837,48.8066483 49.603344,49.3024559 49.4852943,49.2552376 49.815834,48.7594299"
        />
      </g>
    </g>
  )
}

export default BracesR
