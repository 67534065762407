import React from "react"

const Straps = ({ colors = ["#8B5E3C"] }) => {
  return (
    <g id="Straps" fill={colors[0]}>
      <path d="M44.8703995,74.1117935c-0.3534393-0.0883636-0.9655724,0.3620911-0.6839485,0.7241821 c0.2816277,0.3620911,0.6839485,0.8046417,0.8448792,0.4827881 C45.1922569,74.9969025,45.0313301,74.1520233,44.8703995,74.1117935z" />
      <path d="M44.5887756,77.3303757c-0.241394,0.4023209-0.5230217,0.8046417-0.2011642,1.005806 c0.3218613,0.2011566,0.6839485,0.4023209,0.7241821-0.0402374c0.0402336-0.4425507-0.120697-0.8046417-0.3218575-0.8851089 C44.5887756,77.3303757,44.5887756,77.3303757,44.5887756,77.3303757z" />
      <path d="M44.7963943,80.2135925c-0.4389458,0.421524-0.6839485,0.4827805-0.4425545,0.7241745 s0.8415604,0.7152023,0.8029861,0.1966782C45.1182518,80.6159134,44.7963943,80.2135925,44.7963943,80.2135925z" />
      <path d="M46.2382965,77.6119995c0.120697,0.0402298,3.258812,0.1609268,3.258812,0.1609268v1.1667328 l-3.258812-0.2816238V77.6119995z" />
      <path d="M55.8672142,75.4260483c-0.1609306-0.1072845,2.8430786-2.6285095,2.8430786-2.6285095 l0.5364265,0.8046494l-1.8238602,0.8046417c0,0,2.1598244-0.4186325,2.0920753-0.2145691 c-0.067749,0.2040558,0.3218613,0.6437149,0.3218613,0.6437149s-3.5404396,1.9311447-3.5404396,1.7165756L55.8672142,75.4260483z" />
      <path d="M40.4046211,66.6688309l-0.3620911,6.2762299l0.7241821,2.0920715 c0,0,0.7443695-7.8822479,0.7241783-8.0464478c-0.0201874-0.1641922-0.2011604-0.6034775-0.2011604-0.6034775 L40.4046211,66.6688309z" />
      <path d="M35.2951279,75.1175995c0,0,2.2342682,0.3763046,3.0683975,1.1134949 s0.7938957-0.4295502,0.7938957-0.4295502l-3.7415962-2.0518417L35.2951279,75.1175995z" />
      <path d="M35.6572151,74.1922607c0-0.2011642,0.4023247-1.3276672,0.4023247-1.3276672l3.9025269,1.7299881 l-0.4425545,0.7644119l-3.5806694-0.9655762L35.6572151,74.1922607z" />
      <polygon points="46.104187,74.1922607 49.5373383,73.9508667 49.5373383,75.3589935 45.9969025,75.1578293" />
      <polygon points="49.3768578,80.4593582 49.2154808,81.3535995 46.2382965,81.2731323 46.1980629,80.3075562" />
      <polygon points="53.6812592,66.5883636 54.0835838,75.0371323 54.8882294,73.5083084 54.5663719,66.4274368" />
    </g>
  )
}

export default Straps
