import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const AxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("AxeRight") ? (
    <g id="AxeRight">
      <path
        id="S_x24_axeRight"
        d="M27.9,110.1c-0.8-0.2-1.6-0.6-2.5-1.1l-3.5-5.6c-0.1-1.8-0.6-8.1-1.4-12.6c-0.6-3.1-2.7-12.3-4.9-21.7 L13.5,70c-5-2-8.6-6-9.6-10.9l0,0c-1-4.9,0.8-10,4.7-13.7l3.9,0.1l4.2-2.9l3.8-0.7l4.9,1.2l3.7-1.6c5,2,8.6,6,9.6,10.9l0,0 c1,4.9-0.8,10-4.7,13.7h-2.1l7.8,34.4l-3.8,8l-3,1.4L27.9,110.1z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(AxeRight, { character: "ManDwarf" })
