import React from "react"
import { withSkinColor } from "../../builderConnector"

const LeftHand = ({ skinColor, color = [] }) => {
  return (
    <g id="LeftHand">
      <path
        fill={skinColor}
        d="M89.2399216,54.5156822c0.088028,0.1760521,2.9928894-0.4401321,4.8414383,0.9682846 c0.4401321,0.5281563,0,0.9682846-0.088028,1.1443405c0,0.1760521,0.3521118,0.5281525,0.3521118,0.8802605 c0,0.3521042-0.4401321,0.6161842-0.4401321,0.8802605s0.3521042,0.4401283,0.3521042,0.8802605 s-0.9682846,1.0563126-1.1443405,1.5844688c0,0.1760521,0,0.5281563-0.2640762,0.7922363 s-1.7605209,0.0880241-1.7605209,0.0880241l-2.6407852-1.0563126l-0.3521042-0.2640762 c0,0-0.1760559-0.4401321-0.088028-0.7042084c0-0.26408,0.3521118-0.4401321,0.3521118-0.4401321l-0.2640839-0.1760521 c0,0-0.3521042-0.4401321-0.3521042-0.7042084s0.3521042-0.6161842,0.3521042-0.6161842h0.4401245l-0.3521042-0.0880241 c0,0-0.4401321-0.4401321-0.4401321-0.7922363s0.5281601-0.7922363,0.5281601-0.7922363h0.7042084l-0.4401321-0.1760483 c0,0-0.3521042-0.4401321-0.3521042-0.8802643C88.271637,54.6917305,88.9758453,54.5156822,89.2399216,54.5156822z"
      />
    </g>
  )
}

export default withSkinColor(LeftHand, { character: "WomanMixed" })
