import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GoldAxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GoldAxeRight") ? (
    <g id="GoldAxeRight">
      <path
        id="S_x24_goldAxeLeft_3_"
        d="M63.2999992,123.5l4.1000023-3 c0.5-0.6999969,0.9000015-1.5999985,1.1999969-2.5l-1-6.5999985c-1.1999969-1.5999985-5.0999985-7-7.5999985-11.3000031 c-1.7000008-2.9000015-6.5-12.1999969-11.2000008-21.5l2.5999985-0.8000031 c2.7000008-4.9000015,2.7000008-10.5999985,0.2000008-15.0999985l0,0c-2.5999985-4.5-7.4000015-7.4000015-13-7.7000008 L35.5,57.6999969l-5.2999992,0.5l-0.3999996,0.2000008v0.0999985h-0.2000008L29.1000004,59l-2.3999996,1.4000015 l-3.1000004,4.1999969L19.5,65.9000015C16.7999992,70.8000031,16.7999992,76.5,19.2999992,81l0,0 c2.5,4.5,7.3999996,7.4000015,12.8999977,7.6999969l1.9000015-1.5999985L51.7999992,121l8.1000023,3.5999985L63.2999992,123.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GoldAxeRight, {
  character: "WomanDwarf"
})
