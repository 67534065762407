import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WizardHat = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WizardHat") ? (
    <g id="WizardHat">
      <path
        id="S_x24_wizardHat"
        d="M34.1,48.7c-1.5-0.1-2.9-0.1-4.3-0.2c-3.1-0.1-5.8-0.2-7.7-0.4l-3.4-0.8c-2.6-1.1-4.3-3.4-4.5-6.1 c-0.2-2.5,0.8-5.7,4.5-7.5l0.8-0.3c2.1-0.7,4.9-1.3,8.7-1.9c2.4-0.4,4.5-0.5,6.5-0.5l0.5-3.2c0,0,4.5-12.3,5.7-14.4 s6.9-3.4,6.9-3.4s5.8,1.5,6.5,4.5c0.8,3,2.2,8.9,2.2,8.9l3,5.8c0.8-0.1,2-0.1,2-0.1c2.5-0.1,4.6-0.3,6.4-0.4s3.3-0.2,4.5-0.3 c1.6-0.4,2.7-0.5,3.6-0.5c3.3,0,6.2,2.1,7.1,5.2c0.8,2.5,0.3,6.1-3.4,8.5c0,0-1.5,0.8-1.6,0.9l-2.3,1c-2.5,1-9.8,2.9-17.1,3.6 c-0.1,0-0.2,0-0.4,0c-0.9,0.1-1.8,0.2-2.7,0.3c-1.9,0.2-3.9,0.4-6,0.6c-4.5,0.3-10.3,0.7-15.1,0.8L34.1,48.7z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WizardHat, { character: "ManDwarf" })
