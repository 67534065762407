import React from "react"

const Boots = ({ colors = ["#7C5A41", "#E5C3AC", "#3C2A1F", "#603813"] }) => {
  return (
    <g id="Boots">
      <path
        fill={colors[0]}
        d="M73.9091568,110.1327133l1.2498322,3.8536453l0.5207672,3.8069839h0.7290649l4.0619507,0.0520706 c-0.2083054-0.7290649-0.7811432-1.9322357-1.1977539-2.7133789c-0.4166107-0.8852997-0.7290649-1.3539886-0.8332214-1.4581375 c0.0520782-2.6558914,0-4.2181778,0-4.2181778l-1.8747406,0.6769943H73.9091568z"
      />
      <path
        fill={colors[1]}
        d="M78.6481018,116.6422501c0,0-0.1562271-0.3124619,0.1041565-0.3645325 c0.5728378-0.0520782,0.5207596,0.3645325,0.5207596,0.3645325H78.6481018z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M78.3877258,113.7259827 c0.0520706-2.6558914,0-4.2181778,0-4.2181778l-1.8747482,0.6769867l-2.6038132,0.0520782l1.3019028,3.9578018 c0.5728455,0.2082977,0.9373779-0.3645401,1.093605-0.4166183c0.9894485-0.5207596,1.3019104-0.5207596,2.2392807,0.0520782 C78.4918747,113.7780533,78.4397964,113.725975,78.3877258,113.7259827z"
      />
      <polygon
        fill={colors[2]}
        points="76.1365967,117.7962036 75.45961,117.7962036 75.45961,116.0776825"
      />
      <path
        fill={colors[3]}
        d="M76.2005157,113.7259827l0.2083054-0.0520782l1.7705994,2.4475861 c0,0,0.260376-0.1041489,0.5207596-0.1041489c0.1562347,0,0.3124619,0.1041489,0.468689,0.1041489 c0-0.5207672-1.3019104-2.4475861-1.3019104-2.4475861l-0.0520706-3.8015671l-1.2498322,0.3124542l-1.7185211,0.0520782 L76.2005157,113.7259827z"
      />
      <path
        fill={colors[2]}
        d="M76.1685562,117.7962036l2.4475937-0.468689h0.8332138l1.0415268,0.468689H76.1685562z"
      />
      <g fill={colors[0]}>
        <path d="M75.0548401,111.1742401l0.5728378,1.4060593c0.468689,0.1562271,2.1351318,0,2.4475861-0.5207596 l-0.0520706-1.1977615C77.6065826,111.278389,75.6276779,111.4346161,75.0548401,111.1742401z" />
        <path d="M74.7944565,110.3410187l0.2603836,0.468689 c0.468689,0.1562271,2.4475861-0.1041565,2.8121185-0.4166183l0.0520782-0.5728378 C77.5024261,110.0806351,75.4193726,110.3410187,74.7944565,110.3410187z" />
        <path d="M76.7733612,114.4029694l0.4166107,0.6249161c0-0.2603836,0.9373703-0.5728378,1.5622864-0.2603836 c-0.3124619-0.5207596-0.3645325-0.7290649-0.3645325-0.7290649S77.4503479,113.7780533,76.7733612,114.4029694z" />
        <path d="M77.3982773,115.288269l0.4686813,0.6769867 c0.0520782-0.260376,0.8852997-0.6249161,1.5102158-0.1562271c-0.3124619-0.5207596-0.1562271-0.5728378-0.468689-0.7290726 C78.3877258,114.7154236,77.5545044,115.1320419,77.3982773,115.288269z" />
        <path d="M75.7839127,113.1531372l0.5207596,0.8852997c0,0,1.1456757-0.5728455,1.8747482-0.2603836v-1.1456757 c-0.4166107,0.3124619-0.9894485,0.6769943-1.5102158,0.6769943 C76.5129776,113.3093719,75.992218,113.1531372,75.7839127,113.1531372z" />
      </g>
      <g id="L2_x24_C_x24_boots">
        <g id="boots">
          <path
            fill={colors[0]}
            d="M60.4704666,110.1327133l-1.8747444-0.6769943c0,0-0.0520744,1.5622864,0,4.2181778 c-0.1041527,0.1041489-0.4166107,0.5728378-0.8332214,1.4581375c-0.4166107,0.7811432-0.9894485,1.984314-1.1977539,2.7133789 l4.0619507-0.0520706h0.7290688l0.5207634-3.8069839l1.2498322-3.8536453H60.4704666z"
          />
          <path
            fill={colors[1]}
            d="M57.7625008,116.6422501c0,0-0.0520782-0.4166107,0.5207634-0.3645325 c0.2603798,0.0520706,0.1041527,0.3645325,0.1041527,0.3645325H57.7625008z"
          />
          <path
            opacity="0.2"
            fill="#21376C"
            d="M58.4915695,113.8301315 c0.9373703-0.5728378,1.2498322-0.5728378,2.2392807-0.0520782c0.1562271,0.0520782,0.5207596,0.6249161,1.0936012,0.4166183 l1.3019066-3.9578018l-2.603817-0.0520782l-1.8747444-0.6769867c0,0-0.0520744,1.5622864,0,4.2181778 C58.5957222,113.725975,58.543644,113.7780533,58.4915695,113.8301315z"
          />
          <polygon
            fill={colors[2]}
            points="61.5759125,116.0776825 61.5759125,117.7962036 60.898922,117.7962036"
          />
          <path
            fill={colors[3]}
            d="M62.1889839,110.2368698l-1.7185173-0.0520782l-1.2498322-0.3124542l-0.0520744,3.8015671 c0,0-1.3019066,1.9268188-1.3019066,2.4475861c0.1562271,0,0.3124542-0.1041489,0.4686852-0.1041489 c0.2603836,0,0.5207634,0.1041489,0.5207634,0.1041489l1.7705956-2.4475861l0.2083054,0.0520782L62.1889839,110.2368698z"
          />
          <path
            fill={colors[2]}
            d="M56.5446281,117.7962036l1.0415268-0.468689h0.8332214l2.4475861,0.468689H56.5446281z"
          />
          <g fill={colors[0]}>
            <path d="M59.0123329,110.8617783l-0.0520782,1.1977615 c0.312458,0.5207596,1.9789009,0.6769867,2.4475861,0.5207596l0.5728416-1.4060593 C61.4078407,111.4346161,59.4289398,111.278389,59.0123329,110.8617783z" />
            <path d="M59.1164856,109.8202515l0.0520744,0.5728378 c0.3645325,0.3124619,2.3434334,0.5728455,2.8121223,0.4166183l0.2603798-0.468689 C61.6161461,110.3410187,59.5330925,110.0806351,59.1164856,109.8202515z" />
            <path d="M58.6477966,114.0384369c0,0-0.0520744,0.2083054-0.3645325,0.7290649 c0.6249161-0.3124542,1.5622864,0,1.5622864,0.2603836l0.4166107-0.6249161 C59.5851707,113.7780533,58.6477966,114.0384369,58.6477966,114.0384369z" />
            <path d="M58.1270332,115.0799561c-0.312458,0.1562347-0.1562271,0.208313-0.4686852,0.7290726 c0.6249161-0.468689,1.4581337-0.1041489,1.5102119,0.1562271l0.468689-0.6769867 C59.4810181,115.1320419,58.6477966,114.7154236,58.1270332,115.0799561z" />
            <path d="M60.3663139,113.3093719c-0.5207634,0-1.0936012-0.3645325-1.5102119-0.6769943v1.1456757 c0.7290688-0.3124619,1.8747444,0.2603836,1.8747444,0.2603836l0.5207634-0.8852997 C61.0433044,113.1531372,60.522541,113.3093719,60.3663139,113.3093719z" />
          </g>
        </g>
      </g>
    </g>
  )
}

export default Boots
