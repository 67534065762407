import React from "react"

const AxeLeft = ({ colors = ["#8A5F3C", "#6D6E71", "#D1D2D3", "#808184"] }) => {
  return (
    <g id="AxeLeft">
      <path
        fill={colors[0]}
        d="M84.1252213,78.1301651l0.6982727-6.2844391c0.1396484-0.0698242,0.2793045-0.2094803,0.4189606-0.2793045 l2.6534271,0.4887848c0.0698242,0.1396561,0.1396561,0.2793121,0.2094879,0.3491364l-1.8853302,6.144783 c0,0-4.1896286,17.3171234-4.9577255,21.4369202c-0.6284409,3.3516998-0.9775772,7.8206329-1.0474091,9.3568268 c-0.2094803,0.1396561-0.4189606,0.2094879-0.6284409,0.2793121l-2.0948105-0.9775848l6.0749512-26.7437744L84.1252213,78.1301651 z"
      />
      <path
        fill={colors[1]}
        d="M81.8907547,70.937973c0.4189606,1.1172333,2.0948105,2.2344742,4.2594528,2.6534348 c2.0948105,0.4189606,4.0499725,0.0698242,4.8878937-0.8379288c1.4663696,1.3965454,2.1646423,3.2120438,1.815506,5.0275497 s-1.7456818,3.2120438-3.6310043,3.9801483c-0.4189682-1.1172333-2.0948181-2.2344666-4.2594528-2.6534271 c-2.0948181-0.4189682-4.0499725-0.0698318-4.8879013,0.8379211c-1.466362-1.3965378-2.1646347-3.2120438-1.8154984-5.0275497 C78.6088791,73.1026154,80.0054169,71.7060776,81.8907547,70.937973z"
      />
      <g fill={colors[2]}>
        <path d="M91.8061981,77.8508606c0.1396561-0.8379288,0-1.6758499-0.3491364-2.5137787 c0-0.0698242,0.3491364-0.3491287,0.5586243-0.5586166c-0.5586243,0.2094879-0.6982727,0.2094879-0.7680969,0.1396561 c-0.3491364-0.5586166-0.6982727-1.0474014-1.2568893-1.5361938c0.4189606-0.1396561,0.8379211-0.3491364,1.0474014-0.6284485 c1.4663696,1.3965454,2.1646423,3.2120514,1.815506,5.0275574c-0.3491364,1.8154984-1.7456818,3.2120438-3.6310043,3.9801407 c-0.0698318-0.2793121-0.2793121-0.4887924-0.4887924-0.6982727 C90.3398285,80.4344635,91.4570618,79.3172302,91.8061981,77.8508606z" />
        <path d="M81.8907547,70.937973c0.1396484,0.3491364,0.4189606,0.6982727,0.6982727,0.9775848 c-1.6758499,0.5586166-3.0025711,1.7456741-3.2818756,3.3516998c-0.0698318,0.4887924-0.0698318,0.9775772,0,1.3965378 c0,0.0698318-0.1396561,0.0698318-0.1396561,0.1396561c0.0698242,0,0.0698242,0,0.1396561,0 c0,0.0698242,0.0698242,0.3491364,0.0698242,0.4189606c0,0.0698318-0.1396561,0.2094803-0.4887848,0.5586166 c0.2094803-0.1396561,0.6284409-0.2094803,0.6982651-0.1396561c0.2793121,0.6284485,0.6982727,1.2568893,1.2568893,1.815506 c-0.2793045,0.1396561-0.5586166,0.2793121-0.6982651,0.4887924c-1.4663696-1.3965454-2.1646423-3.2120438-1.815506-5.0275497 S80.0054169,71.6362457,81.8907547,70.937973z" />
      </g>
      <polygon
        fill={colors[3]}
        points="83.7062607,79.0379181 86.429512,79.5267105 88.0355377,73.8008881 84.2648773,73.0327911"
      />
    </g>
  )
}

export default AxeLeft
