import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongSwordLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongSwordLeft") ? (
    <g id="LongSwordLeft">
      <path d="M66.3549347,91.8520203c1.4121933-0.8206253,2.3647766-2.1612778,2.5977325-3.7921524l0.0397568-0.3379364 c0.098587-0.3721466,0.1041489-0.7689285,0.0508728-1.1315155c0.1765289-0.1025772,0.3530426-0.205162,0.5295715-0.3077316 c1.5298691-0.8890152,2.507103-2.3227005,2.7647095-4.046608c0.3466644-2.2479172-0.7475052-4.1308365-1.6078873-5.2050934 l3.870491-23.8158569c1.1632156-7.208931-0.1949463-9.4107018-1.833786-10.7409821l-4.8926163-0.6988792l-0.6226044,0.2830887 c-1.4121933,0.8206215-3.0311203,2.2336426-4.3064461,9.1141739l-3.7432823,23.8993568 c-1.1426315,0.7426987-3.0804062,2.4197235-3.3054008,5.1479111c-0.1057358,1.7143631,0.5137138,3.3221664,1.8392639,4.5196533 c-0.1327896,0.3132935-0.2902222,0.7196274-0.2957802,1.1164093l-0.039753,0.3379364 c-0.4850121,2.9580078,1.4846382,5.6702652,4.4426346,6.1552658l1.0480232,0.1781006 C63.997303,92.6710739,65.2957993,92.4674911,66.3549347,91.8520203L66.3549347,91.8520203z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongSwordLeft, {
  character: "ManGnome"
})
