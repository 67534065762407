import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const CutlassL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("CutlassL") ? (
    <g id="CutlassL">
      <path
        id="_x3C_path_x3E_"
        fill="#01060F"
        d="M62.7335167,84.1571884l18.024044-53.1065598l6.3298721,4.6132984 c0,0,4.3987274,12.4451714-8.2610245,45.7038231c1.2874374,5.5788727,2.6908264,18.3814392-5.7934418,18.5604782 s-12.2305984-3.3258667-12.2305984-3.3258667L62.7335167,84.1571884z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(CutlassL, {
  character: "ManHalfling"
})
