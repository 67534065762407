import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import { apiRoutine } from '../routines'
import { apiRequestAction } from '../reducers/apiReducer'
import { incrementPrintQueue, decrementPrintQueue } from '../reducers/printQueueReducer'
import MinisGrid from './MinisGrid'
import { RequireSignIn } from '../Auth'
import { flash, clearFlash } from '../Flash'

import { remixCharacter } from '../actions/builderActions'

const ERROR_KEY = 'CREATE_PRINT_PAGE_KEY'

const ConfirmDeleteModal = ({ handleConfirm, handleClose, enabled }) =>
  enabled ?
    <div className="modal" style={{ display: 'flex' }}>
      <div onClick={handleClose} className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Arey you sure you want to delete this pawn?</p>
          <button onClick={handleClose} className="delete" ariaLabel="close"></button>
        </header>
        <section className="modal-card-body">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <button onClick={handleClose} className="button">Cancel</button>
            <button onClick={handleConfirm} className="button is-primary">Confirm</button>
          </div>
        </section>
        <footer className="modal-card-foot"></footer>
      </div>
    </div>
  : null


class UserMinis extends React.Component {
  constructor(props) {
    super(props)
    this.state = { deleteModalEnabled: false, deleteId: undefined }
  }

  componentDidMount() {
    this.props.apiRoutine(apiRequestAction('minis.get', 'userMinis'))
  }

  assembleSheetsFromQueue = () => {
    const sum = Object.keys(this.props.queue).reduce((sum, key) => {
      sum += this.props.queue[key] || 0
      return sum
    }, 0)

    if (sum < 1) {
      this.props.flash({
        key: ERROR_KEY,
        message: "You must add at least one mini to print"
      })

      setTimeout(() => this.props.clearFlash({ key: ERROR_KEY }), 4000)
      return
    }

    this.props.apiRoutine(apiRequestAction(
      'minis.sheet.create',
      'printSheet',
      this.props.userMinis
        .filter(mini => this.props.queue[mini.id])
        .map(mini => ({ files: mini.files, copies: this.props.queue[mini.id] }))
    ))
  }

  handleDelete = (id) => {
    this.setState({ deleteModalEnabled: true, deleteId: id })
  }

  handleDeleteConfirm = (id) => {
    this.props.apiRoutine(apiRequestAction(
      'minis.file.patch',
      'deletedFile',
      { id: this.state.deleteId, payload: { deleted: true }}
    ))

    this.setState({ deleteModalEnabled: false, deleteId: undefined })
  }

  render() {
    const { queue, decrementPrintQueue, incrementPrintQueue, userMinis } = this.props
    const noMinis = !userMinis.length

    const minis = userMinis.map(mini =>
      <div key={mini.id}>
        <div className="pawn-container">
          <div className="pawn-ui">
            <div className="print-queue-controls">
              <button
                onClick={(e) => {
                  incrementPrintQueue({ id: mini.id })
                }}
                className="icon has-text-info">
                <i className="fas fa-plus"></i>
              </button>
              <span> { queue[mini.id] || 0 } </span>
              <button
                onClick={(e) => {
                  decrementPrintQueue({ id: mini.id })
                }}
                className="icon has-text-info">
                <i className="fas fa-minus"></i>
              </button>
            </div>

            <div className="pawn-action-buttons">
              <button onClick={() => this.props.remixCharacter(mini.id)}><i className="fas fa-pen-square"></i></button>
              <button onClick={() => this.handleDelete(mini.id)}><i className="fas fa-trash-alt"></i></button>
            </div>
          </div>
          <div className="pawn-image">
            <img width="200" src={mini.file.default} />
          </div>

          <div className="pawn-name">{mini.name || "Untitled"}</div>

        </div>
      </div>
    )

    return (
      <div>
        <div className="level">
        {
          noMinis && <Link to="/builder">
            <h2 className="subtitle">You haven't created any pawns yet. <b>Click here to create some!</b></h2>
          </Link>
        }
          <div>
            There are {Object.keys(queue).reduce((count, key) => (count + queue[key]), 0)} pawns in your print queue.
          </div>
          <RequireSignIn message="Sign up to save your pawns!" >
            <button className="button is-primary"
              onClick={this.assembleSheetsFromQueue}
            >
              Create Print Sheet
            </button>
          </RequireSignIn>
        </div>
        <div className="content"><MinisGrid items={minis} /></div>
        <ConfirmDeleteModal
          handleConfirm={this.handleDeleteConfirm}
          handleClose={() => this.setState({ deleteModalEnabled: false })}
          enabled={this.state.deleteModalEnabled}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userMinis: get(state, 'apiReducer.data.userMinis') || [],
  queue: get(state, 'printQueueReducer.queue')
})

const mapDispatchToProps = {
  apiRoutine,
  incrementPrintQueue,
  decrementPrintQueue,
  flash,
  clearFlash,
  remixCharacter
}


export default connect(mapStateToProps, mapDispatchToProps)(UserMinis)
