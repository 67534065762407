import React from "react"

const HandHiderR = ({ colors = ["#3C7626", "#SVGID_"] }) => {
  return (
    <g id="HandHiderR">
      <radialGradient
        id="SVGID_50_"
        cx="92.716"
        cy="65.188"
        r="14.372"
        gradientTransform="matrix(1 0 0 -1 0 152)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.302" stopColor={colors[0]} />
        <stop offset="1" stopColor={colors[0]} stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_50_)" cx="92.716" cy="86.812" r="14.372" />
    </g>
  )
}

export default HandHiderR
