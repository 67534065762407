import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FrontShieldR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FrontShieldR") ? (
    <g id="FrontShieldR">
      <path
        id="_x3C_path_x3E__1_"
        d="M52.2000008,85.5c-1,0-1.9000015-0.0999985-2.9000015-0.4000015 c-5-1.3000031-11.6000023-5.3000031-14.8999977-16.5l-0.1000023-0.1999969 c-0.2000008-0.6999969-0.3999977-1.5-0.6000023-2.4000015c0-0.0999985,0-0.0999985-0.0999985-0.1999969l0,0l0,0 c-0.4000015-1.8000031-0.7000008-3.5999985-0.9000015-5.5c-1.4999981-11.2999992-0.7999973-25.7999992,10.9000015-30 c1.2999992-0.5,2.7999992-0.7000008,4.2000008-0.7000008C58.5,29.6000004,65,43,66.5,55.4000015 c0.9000015,7.7000008-0.1999969,16-2.9000015,21.5999985L63.5,77.1999969c-0.5,1.0999985-1.0999985,2-1.7000008,2.9000015l0,0l0,0 l0,0C59.4000015,83.5999985,55.9000015,85.5,52.2000008,85.5L52.2000008,85.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FrontShieldR, {
  character: "WomanMixed"
})
