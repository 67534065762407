import React from "react"

const RightShoulderGuard = ({ colors = ["#898888"] }) => {
  return (
    <g id="RightShoulderGuard">
      <path
        fill={colors[0]}
        d="M49.6,24.3c-0.3,0.1-0.7,0.1-1.1,0.1c0.8,0.5,1.4,1.2,1.8,2.1v0.1l0,0 c1.3,2-0.9,7.3-1.5,8.5c-1.1,3.1-2.6,6.1-4.2,9.1c1.3-0.4,4.1-1.4,4.2-1.5c0.6-0.5,2.7-2.1,3-2.7c2-4.3,4.4-9.5,4.8-13.8 c-1.4-0.6-4.8-1-5.9-2.2C50.4,24.1,50,24.2,49.6,24.3z"
      />
      <path
        opacity="0.28"
        d="M55.9,26.3c-0.4,4.2-2.7,9.3-4.7,13.5 c-0.3,0.6-2.4,2.2-3,2.7c-0.1,0.1-1.4,0.6-2.6,1c-0.2,0.4-0.4,0.8-0.6,1.1c1.3-0.4,4.1-1.4,4.2-1.5c0.6-0.5,2.7-2.1,3-2.7 c2-4.3,4.4-9.5,4.8-13.8C56.6,26.5,56.2,26.4,55.9,26.3z"
      />
    </g>
  )
}

export default RightShoulderGuard
