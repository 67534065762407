import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Cloak = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Cloak") ? (
    <g id="Cloak">
      <polygon points="102.3,64.8 111.2,117.9 93.9,117.9 96.8,57.9" />
      <g id="S_x24_cloak_1_">
        <path
          id="S_x24_cloak"
          fill="#191919"
          d="M65.6,8.5c-1,0-4.2,18.9-4.2,18.9l-15,16.1L41,61.4l-0.3,50.8l62.8,0.3l-7.2-50.7 c0,0-15.2-32.4-15.8-32.4s-4.2-5.5-4.2-5.5L75,11L65.6,8.5z"
        />
      </g>
      <polygon points="35.9,64.8 27.1,117.9 44.3,117.9 41.4,57.9" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Cloak, { character: "ManMixed" })
