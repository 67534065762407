import React from "react"

const NoseWide = ({ colors = ["#ED1C24", "#SVGID_", "#FFFFFF"] }) => {
  return (
    <g id="NoseWide">
      <linearGradient
        id="SVGID_14_"
        gradientUnits="userSpaceOnUse"
        x1="48.476"
        y1="105.594"
        x2="48.476"
        y2="111.91"
        gradientTransform="matrix(1 0 0 -1 0 153.987)"
      >
        <stop offset="0" stopColor="#ed1c24" stopOpacity="0.5" />
        <stop offset="1" stopColor="#ed1c24" stopOpacity="0" />
      </linearGradient>
      <path
        fill="url(#SVGID_14_)"
        d="M47.5,43.1l-2.3,3l3.1,2.3l3.3-2.2l-2.4-3.1c0,0-0.1-1-0.9-1C47.7,42.1,47.5,43.1,47.5,43.1z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M45.3,46.1c0,0,1.1-0.2,1.5,0.2s0.8,0.7,1.1,0.9s0.6,0.3,1.1,0 c0.5-0.2,0.4-0.8,1-1s1.8-0.1,1.8-0.1l-2.3,2.2c0,0-0.2,0.3-0.9,0.3s-1.1-0.3-1.1-0.3L45.3,46.1z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M45.3,46.1c0,0,1.1-0.2,1.5,0.2s0.8,0.7,1.1,0.9 s0.6,0.3,1.1,0c0.5-0.2,0.4-0.8,1-1s1.1-0.2,1.8-0.1c-0.8,0.3-1.3-0.4-2.3,1.1c-1,1-2,0-2,0C46.3,45.4,45.5,46.6,45.3,46.1z"
      />
      <ellipse opacity="0.66" fill="#FFF" cx="48" cy="46.4" rx="0.4" ry="0.3" />
    </g>
  )
}

export default NoseWide
