import React from "react"

const LeafPlateR = ({ colors = ["#BBBDBF", "#231F20", "#D0D2D3"] }) => {
  return (
    <g id="LeafPlateR">
      <path
        fill={colors[0]}
        d="M35.738575,33.0983925c0,0,8.7164841,1.2921982,13.1036606-8.8696308 C47.3286934,20.0476189,33.9625397,17.2429104,35.738575,33.0983925z"
      />
      <path
        opacity="0.2"
        fill={colors[1]}
        d="M50.1840935,24.2259274c0,0-0.6044388,9.8383427-14.536335,6.4548302 C38.5451965,18.0915203,43.5930634,22.0729237,50.1840935,24.2259274z"
      />
      <path
        fill={colors[2]}
        d="M51.7895126,23.4685078c0,0-3.4306717,9.5135784-17.3625641,6.1300697 C37.3243828,17.0093403,45.1984787,21.3155022,51.7895126,23.4685078z"
      />
      <path
        opacity="0.2"
        fill={colors[1]}
        d="M36.5185471,24.4011154c0,0,7.3751564,6.8555641,18.0248833,0.0983448 C51.1476631,21.7048569,40.2806129,17.1729794,36.5185471,24.4011154z"
      />
      <path
        fill={colors[0]}
        d="M36.2062531,23.2318516c0,0,7.3751564,6.8555641,18.0248833,0.0983448 C50.8353653,20.535593,45.7657928,16.1339188,36.2062531,23.2318516z"
      />
    </g>
  )
}

export default LeafPlateR
