import React from "react"

const DarkPatch = ({ colors = [] }) => {
  return (
    <g id="DarkPatch" fill="#21376C">
      <path
        opacity="0.42"
        d="M44.4581909,90.098732 c-0.6704712-0.4052124-3.0344849-1.0709839-2.5548706-0.6451416c-0.1682739,0.4355469-0.3364868,0.8710938-0.5047607,1.3066406 c-0.0462036,0.0662842-0.0722656,0.142395-0.0854492,0.2211304c-0.288208,0.7459106-0.576355,1.4918823-0.864563,2.237793 c-0.0964355,0.2495117,2.8907471,1.1290283,2.9500122,0.9356079c0.354126-1.156189,0.7801514-2.2805176,1.2761841-3.3828735 C44.7915649,90.5121841,44.7000732,90.2449722,44.4581909,90.098732z"
      />
      <path d="M42.4564667,89.0379562c-0.0804672,0.0201187-0.1810455,0.6034851-0.1810455,0.6034851 l0.2816238-0.5833664L42.4564667,89.0379562z" />
      <path d="M40.3241577,92.7594376c0,0-0.1810455,0.2011642-0.0804672,0.2011642 c0.1005821,0,0.5029068,0.1609268,0.5029068,0.1609268L40.3241577,92.7594376z" />
      <path d="M41.7121696,93.6445465c-0.0804634,0.0402298-0.4425545,0.5833664-0.4425545,0.5833664l0.241394,0.120697 L41.7121696,93.6445465z" />
      <path d="M43.3214607,93.6445465c0.0517464-0.0310516,0.5230179,0.3822098,0.5230179,0.3822098 s0.1408119-0.241394,0.0804634-0.2816315C43.8645935,93.704895,43.2208786,93.704895,43.3214607,93.6445465z" />
      <polygon points="43.3818054,89.2793503 43.2007637,89.8627167 43.562851,89.3396988" />
      <polygon points="44.2266846,89.7017899 44.0657539,90.1644592 44.4077301,89.842598" />
      <polygon points="40.5052032,92.216301 41.0081062,92.4174652 40.5856667,92.0151443" />
      <polygon points="40.8270607,91.3915405 40.7667122,91.6329346 41.3299637,91.6731644" />
      <polygon points="41.048336,90.6271286 40.9879875,90.8282928 41.5713577,90.969101" />
      <polygon points="41.2897301,89.9230652 41.2092667,90.1845703 41.8328667,90.3656158" />
      <polygon points="41.0885696,93.3830338 40.6259003,93.9060593 40.806942,94.0066376" />
      <polygon points="42.6375122,93.9664078 42.2754211,94.4693069 42.5369301,94.5698853" />
      <polygon points="43.5829697,92.8600159 44.1261024,93.1617584 44.186451,92.9404831" />
      <polygon points="43.8645935,92.2565308 44.2870331,92.5180435 44.3674965,92.2766495" />
      <polygon points="44.1261024,91.6530533 44.4680786,91.8542099 44.5284271,91.6530533" />
      <polygon points="44.3473816,91.089798 44.7295876,91.4116592 44.7698212,91.1300354" />
    </g>
  )
}

export default DarkPatch
