import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongBowLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongBowLeft") ? (
    <g id="LongBowLeft">
      <path d="M57.7267418,109.1927185c-0.8512115,0.5623703-1.6801987,1.0160065-2.66119,1.3819504l-0.6103363,0.215126 c-0.9809914,0.3659363-2.8342247,1.1200485-3.8806915,1.5292511l-0.1099129,0.2607269l-0.6769943,0.5413361l-0.2619133,0.1730347 c-2.8155327,1.8601685-5.77248,1.0860748-7.4420929-0.4444733l-1.6543236-5.2089386l0.0666542-0.32621l1.1856499-2.476387 c0.4595184-0.5857697,20.2405243-51.9364204,20.2405243-51.9364204l0.641964-2.5874748l0.175396-0.3039932l4.6909904-2.7230072 c2.8949966-0.031498,5.3023834,2.0462685,5.8775635,5.0523567l0.1262512,1.0452881l-0.0876923,0.1519966 c0.474678,1.0032043,1.3597336,2.7699203,1.7911606,3.7076492l0.2806015,0.5670815 c4.3796844,9.3340187-1.7145538,19.7563286-3.7315598,23.2522545c-0.5261765,0.9119797-0.8114777,1.4767151-0.9868698,1.7807083 l-0.133316,0.6524277l-1.8264389,4.7809067l-0.0876923,0.1520004l-0.1099167,0.2607346l-0.2408752,0.3472519 c-0.1099091,0.2607346-0.2432175,0.9131622-0.4653931,2.0005341 C67.169754,94.3005447,64.994751,104.390892,57.7267418,109.1927185z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongBowLeft, {
  character: "ManGnome"
})
