import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const TorchR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("TorchR") ? (
    <g id="TorchR">
      <path
        id="path_1_"
        d="M48.1,71.7l4.5-8.1l-2-10.4c7.3-4.1,11.9-11.9,11.9-20.6c0-13-10.6-23.6-23.5-23.8l-0.3,0 c-13,0.1-23.7,10.7-23.7,23.8c0,11.3,8,20.8,18.6,23.2l2.8,11.3l6.4,5.4l1.3,0.1L48.1,71.7z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(TorchR, { character: "ManMixed" })
