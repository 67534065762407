import React from "react"

const PuffySleeves = ({
  colors = ["#00A69C", "#FFFFFF", "#282560", "#262261"]
}) => {
  return (
    <g id="PuffySleeves">
      <path
        fill={colors[0]}
        d="M59.4100189,31.3146496c0-0.377758-2.1721115-1.0388355-3.9664688-0.7555161 c-1.7943535,0.2833176-4.2261734,4.2852001-4.721981,6.421896c-0.4958076,2.1366997,1.8887939,2.9276314,2.266552,2.9276314 s2.8331909,1.8887939,4.721981,1.8887939c1.8887939,0,2.7859726-3.4470482,3.0692902-3.6359291 c0.1974297-0.1316185,0.9186974-0.3702049,1.5346451-1.3693733c0.2679596-0.4346733-1.2871132-4.026432-1.2871132-4.026432 L59.4100189,31.3146496z"
      />
      <path
        opacity="0.42"
        fill="#FFF"
        d="M59.944191,31.7720928 c-6.1031609,1.8651829-6.6343842,7.6850262-6.8232651,8.2162495c0.3069305,0.1652718,3.7539787,1.6645012,3.7539787,1.6645012 s0.811512,0.3300858,1.5936661,0.0590248c0.399025-0.1382866,0.7912674-0.692524,1.0624466-0.9916153 c0.3668213-0.6432304,0.861763-1.4638176,1.239521-2.3727989c0.0968933-0.5086861,0.0133286-2.0455246,0-3.3644142 c-0.0126534-1.2521095,0.0472221-2.3846016,0.0472221-2.3846016L59.944191,31.7720928z"
      />
      <path
        opacity="0.3"
        fill={colors[2]}
        d="M56.5089493,32.6574631 c1.2200508-0.9478989,3.222538-0.9414978,3.3998299-0.8971767c0.1997108,0.0499287-0.8853722,0.0708313-3.0692902,1.5582561 c-2.8095818,2.0658684-3.7539787,6.7052155-3.7539787,6.7052155S53.4278564,35.3371887,56.5089493,32.6574631z"
      />
      <path
        opacity="0.3"
        fill={colors[2]}
        d="M60.805954,38.2294044 c0,0,0.484005-4.4150543,0.0590248-5.5365257C60.6879044,34.8886032,60.8767853,36.6357346,60.805954,38.2294044z"
      />
      <path
        opacity="0.2"
        fill={colors[3]}
        d="M50.7109413,37.6178207 c0.2816353,0.4628029,0.668148,0.8327141,1.1247177,1.1208496c0.261219,0.1648331,0.7354126,0.4663506,1.1210098,0.5162086 c1.4517555,1.1595306,4.7475243,3.2652512,6.2246895,1.612236c0.0440025-0.0026321,0.0878983,0.0004845,0.1319542-0.0038147 c-0.4321785,0.5490875-0.9652596,0.9665985-1.6199722,0.9665985c-1.8888245,0-4.3442497-1.8887672-4.7220039-1.8887672 c-0.3486862,0-2.402504-0.6766357-2.3269615-2.4611206C50.6691933,37.5247116,50.6842918,37.5740318,50.7109413,37.6178207z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M78.1338272,31.3825283c0-0.377758,2.1721115-1.0388355,3.9664688-0.755518 c1.7943497,0.2833195,4.2261734,4.285202,4.7219849,6.4218979c0.4958038,2.1366997-1.8887939,2.9276314-2.2665558,2.9276314 s-2.8331909,1.8887939-4.7219849,1.8887939s-2.785965-3.4470482-3.0692902-3.6359291 c-0.2833176-0.1888771-1.3321533-0.5194168-1.4029846-1.1096649c-0.0708237-0.5902481,1.2857132-4.4030418,1.2857132-4.4030418 L78.1338272,31.3825283z"
        />
        <path
          opacity="0.42"
          fill="#FFF"
          d="M77.6734314,31.7720928 c6.1031647,1.8651829,6.6343918,7.6850262,6.8232727,8.2162495c-0.3069305,0.1652718-3.7539825,1.6645012-3.7539825,1.6645012 s-0.8115082,0.3300858-1.5936661,0.0590248c-0.399025-0.1382866-0.7912674-0.692524-1.0624466-0.9916153 c-0.3668213-0.6432304-0.861763-1.4638176-1.2395248-2.3727989c-0.0968857-0.5086861-0.0133286-2.0455246,0-3.3644142 c0.0126572-1.2521095-0.0472183-2.3846016-0.0472183-2.3846016L77.6734314,31.7720928z"
        />
        <path
          opacity="0.3"
          fill={colors[2]}
          d="M81.1086807,32.6574631 c-1.2200546-0.9478989-3.2225418-0.9414978-3.3998337-0.8971767c-0.1997147,0.0499287,0.885376,0.0708313,3.0692902,1.5582561 c2.8095856,2.0658684,3.7539749,6.7052155,3.7539749,6.7052155S84.1897736,35.3371887,81.1086807,32.6574631z"
        />
        <path
          opacity="0.3"
          fill={colors[2]}
          d="M76.811676,38.2294044 c0,0-0.4840088-4.4150543-0.0590286-5.5365257C76.9297256,34.8886032,76.7408447,36.6357346,76.811676,38.2294044z"
        />
        <path
          opacity="0.2"
          fill={colors[3]}
          d="M86.816124,37.6532364 c-0.2816315,0.4628029-0.6681442,0.8327103-1.1247177,1.1208496c-0.2612228,0.1648331-0.7354126,0.4663467-1.1210098,0.5162086 c-1.4517517,1.1595306-4.7475204,3.2652473-6.2246857,1.612236c-0.0440063-0.002636-0.0878983,0.0004807-0.131958-0.0038147 c0.4321823,0.5490875,0.9652557,0.9665985,1.6199722,0.9665985c1.8888245,0,4.3442459-1.8887672,4.7220001-1.8887672 c0.3486938,0,2.4025116-0.6766357,2.3269653-2.4611206C86.857872,37.5601273,86.8427734,37.6094475,86.816124,37.6532364z"
        />
      </g>
    </g>
  )
}

export default PuffySleeves
