import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const TatteredCape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("TatteredCape") ? (
    <g id="TatteredCape">
      <path
        id="tatteredCape"
        d="M93.8485107,107.9203873 c2.1120758-24.0933304-11.8119888-58.8252716-26.7529831-57.2607727 c-14.9409943,1.5645027-16.9748459,16.4272766-25.2667084,30.664238 c-3.0507812,5.1628647-8.4483109,14.4716415-13.2200432,19.399826c-2.7378788,2.8161087-3.8330288,5.2410812-1.720953,9.3870163 C27.8265247,112.0663223,88.7638855,132.7959747,93.8485107,107.9203873z M40.8901176,105.026062 C40.8118935,105.1825104,39.4038429,106.5123367,40.8901176,105.026062z M41.8288193,103.852684 C40.4989929,105.2607422,43.0021935,102.7575302,41.8288193,103.852684z M42.0634918,96.8124237 C40.9683418,96.2648544,42.3763962,96.5777512,42.0634918,96.8124237z M43.5497704,106.5123367 C40.8901176,106.5123367,44.0191193,105.6518631,43.5497704,106.5123367z M43.6279945,96.1083984 C43.393322,95.8737259,43.6279945,95.7954941,43.6279945,96.1083984z M43.1586456,95.6390457 C41.2812424,96.2648544,43.9408951,95.2479248,43.1586456,95.6390457z M44.9578209,99.7849808 c-0.3129005,0,0.3911285-0.6258011,0.6258011-0.6258011C45.8965225,99.1591797,45.1924973,99.7849808,44.9578209,99.7849808z M69.5205002,113.3961487C67.4084244,116.0558014,70.4592056,112.4574432,69.5205002,113.3961487z M70.2245331,111.909874 C67.1737518,113.9437256,72.0237122,110.1106949,70.2245331,111.909874z M82.7405472,105.2607422 c-0.5475769,0,0.5475693-1.0169296,0.9386978-1.0169296C84.3050461,104.1655884,83.2098923,105.2607422,82.7405472,105.2607422z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(TatteredCape, {
  character: "WomanDwarf"
})
