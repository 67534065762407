import React from "react"

const Wave = ({ colors = ["#C07F00", "#FCE300"] }) => {
  return (
    <g id="Wave">
      <path
        fill={colors[0]}
        d="M71.2399597,46.9937286c1.5362778,0.5774841,3.5114975,2.8049316,3.8772736,5.6923599 c0.2194748,1.6499596,1.4631271,1.9799538,0.438942,3.7949066c-0.6584091,1.0724716,0.8778687,1.6499596,0.2194672,3.2999191 c-0.2926254,0.8249779-1.0973434,1.2374687-0.9510345,2.2274437c0.1463165,0.5774879,0.5852509,1.4024696,2.1946869,1.3199692 c-1.9020615,1.3199654-3.6578064,0.6599808-4.2430573,0c-0.7315598-0.8249817-1.3168106-2.0624504-0.2926254-3.3824158 c1.0973434-1.4024658,1.1704941-2.9699287,1.5362778-4.6198883c0.0731583-0.412487-0.0731583-1.1549721-0.1463089-1.6499596 c-0.1463089-0.6599808-0.2194748-1.0724716-0.5120926-1.7324562c-1.2436523-0.3299904-1.5362778-2.0624466-1.6094284-1.97995 c-2.9262466,1.5674629-3.2188721,2.9699249-7.8276978,2.9699249c-0.2926254,0-0.9510307,2.0624504-0.0731583,3.6299133 c0.7315598,1.2374687,0.8778763,1.97995,0.4389343,3.134922c-0.2926216,0.7424812-1.1704941,1.4849625-2.1215248,2.0624504 c-2.340992,1.5674629,3.6578026,6.3523445-0.8778725,7.837307c1.9020576-2.8049316-2.9262428-2.8874283-1.0973396-5.3623657 c0.585247-0.742485,0.2194672-1.3199654,0.0731544-1.7324562c-0.2926254-0.989975-0.9510307-1.1549721-0.3657799-1.9799538 c0.3657799-0.4949875,1.0973396-0.8249779,0.5120926-1.9799538c-0.585247-1.1549721-0.1463127-1.8149567,0.5120888-2.6399384 c0.4389343-0.5774841-0.6584053-1.3199654-0.0731544-2.4749374c0.2926254-0.6599846,0.9510269-0.9074783,1.1704941-1.8974533 c0.4389381-1.8149567,1.0973434-2.9699249,2.2678413-3.9599037 C66.6311264,45.9212532,69.0452805,46.0862465,71.2399597,46.9937286z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M62.7538605,60.1109047 c-0.5852509-0.0824966-1.1704979-0.1649971-1.7557487-0.2474937c-0.0731544,0.4124908-0.4389343,0.5774879-0.7315598,0.9074745 c-0.1463127,0.2474937-0.2194672,0.4124908-0.2194672,0.5774879c0.9510269-0.4124908,1.2436523-0.2474937,1.6825867,0.2474937 C61.8759842,61.1008797,62.2417679,60.5233955,62.7538605,60.1109047z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M62.7538605,65.8857651 c-0.2194672-0.5774918-0.5120926-1.1549759-0.7315636-1.73246c-0.4389343-0.0824966-0.9510269-0.1649971-1.3899651-0.1649971 c-0.0731544,0.1649971-0.1463127,0.2474937-0.2194672,0.4124947c-0.3657799,0.4949799-0.5120926,0.9074707-0.4389343,1.2374649 c0.3657799,0,0.0731544-0.5774841,1.0973396-0.4949875C62.0954552,65.2257843,62.1686096,65.8032684,62.7538605,65.8857651z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M66.1921921,46.9937286 c-1.316803,0.7424812-1.8288956,2.2274437-2.4873047,3.3824158c0.8778763-0.8249817,1.8289032-1.4849663,2.7067719-1.8974533 c1.3168106-0.6599846,2.926239-0.9899788,4.4625168-1.5674629c-0.5852509-0.1649971-1.1704941-0.3299942-1.7557449-0.4124908 C68.0942535,46.6637344,67.5090027,46.2512436,66.1921921,46.9937286z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M71.0204926,47.7362061 c-1.7557449,0.6599846-3.730957,0.4949875-5.4135513,1.5674629c-1.3899612,0.8249817-2.7067719,2.1449471-3.0725517,3.9599037 c0.5120926-1.5674629,1.9752159-2.3099442,3.5846443-2.8049316c2.4141541-0.742485,4.1698914-1.3199692,5.7793274-2.9699287 c0,0-0.0731506,0-0.0731506-0.0824966C71.6057434,47.4887123,71.313118,47.5712128,71.0204926,47.7362061z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M70.1426239,50.046154 c-1.6825943,0.6599808-4.8283005,0.1649971-6.29142,2.3924408c0.5852432-0.4949875,1.0241852-0.0824966,1.8289032-0.2474937 c2.121521-0.4949837,3.5846481-1.1549721,5.0477676-2.5574341C70.5084,49.7986565,70.3620911,49.9636536,70.1426239,50.046154z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M74.8977661,52.438591 c-0.2926254-1.6499596-1.1704941-3.134922-2.1215286-4.124897C71.8983688,50.4586411,73.8735809,51.2011223,74.8977661,52.438591z"
      />
      <path
        fill="#21376C"
        d="M72.5567703,60.1109047c-1.0241852,1.3199654-0.5120926,2.5574341,0.2926254,3.3824158 c0.2926254,0.3299942,0.7315598,0.5774879,1.3899612,0.6599846c0.5120926-0.0824966,0.9510345-0.2474937,1.3168182-0.5774879 c0,0,0,0-0.0731583,0c-0.3657837,0.0824966-0.7315598,0-1.0241852-0.1649971 c-0.8778687-0.4949875-1.0973434-1.5674629-0.804718-2.5574379c0.0731583-0.2474899,0.1463089-0.4949837,0.2926254-0.7424812 c0.0731583-0.1649971,0.1463089-0.3299942,0.1463089-0.4949875l0,0c0.3657837-2.7224312,0.6584091-5.3623695-0.4389343-7.9198074 c-0.2926254-0.1649971-0.512085-0.4124908-0.7315598-0.7424812c-0.4389343-0.6599846-0.6584015-1.4849663-0.8778687-2.3099442 c-0.7315598,0.989975-1.8289032,1.8149567-2.7799301,2.3924408c-1.6825943,1.0724754-3.6578064,1.4024696-5.559864,1.6499596 c0,0.0824966-0.0731544,0.1649971-0.1463127,0.2474937c-1.1704941,1.8149567,0.219471,3.7949066-0.0731544,5.6923637 c-0.1463127,1.0724716-0.8047142,1.6499596-1.2436523,2.4749374c-0.5120926,0.9899788-0.6584053,1.5674629-0.5120926,2.5574379 h0.0731544c-0.2194672-0.742485-0.2194672-1.4024658,0.4389343-1.8149567 c0.9510307-0.6599846,1.8289032-1.3199654,2.1215286-2.0624504c0.4389343-1.1549721,0.2926254-1.8974533-0.4389343-3.134922 c-0.8778763-1.5674629-0.219471-3.6299133,0.0731544-3.6299133c4.6088295,0,4.9014549-1.4024658,7.8276939-2.9699249 c0.0731583,0,0.3657837,1.7324562,1.609436,1.9799538c0.2926254,0.6599808,0.3657761,1.0724716,0.512085,1.7324562 c0.0731583,0.4124908,0.2194748,1.2374687,0.1463165,1.6499596 C73.8004227,57.2234764,73.6541061,58.7084389,72.5567703,60.1109047z"
        opacity="0.2"
      />
    </g>
  )
}

export default Wave
