import React from "react"

const WornShieldLeft = ({
  colors = ["#8A784E", "#A59A76", "#EED793", "#B0B0B1"]
}) => {
  return (
    <g id="WornShieldLeft">
      <path
        fill={colors[0]}
        d="M86,69.1c0,0-3.4-0.5-7.3-2.4S74,63,74,63S62.1,81.2,63.5,97.4C79.1,91.2,86,69.1,86,69.1z"
      />
      <path
        fill={colors[1]}
        d="M64.6,95c-0.6-11.9,6.3-24.8,8.9-29.4c0.7,0.7,1.9,1.8,4.2,3c2.3,1.2,4.6,1.7,5.5,1.8 C82,75.4,76.3,88.4,64.6,95z"
      />
      <path
        fill={colors[0]}
        d="M65.1,94c-0.3-10.8,5.4-22.3,8.3-27.4c0.7,0.7,1.9,1.7,4,2.8c2.1,1,3.6,1.4,4.6,1.5 C79.7,76.3,74,87.8,65.1,94z"
      />
      <path
        fill={colors[2]}
        d="M77.4,69.5c2.1,1,3.6,1.4,4.6,1.5c0,0,0,0,0,0.1l0,0c0.2-0.2,1.2-0.5,1.4-0.7l-0.7-0.1 c-1-0.1-2.6-0.4-5-1.6c-2.3-1.2-3.6-2.3-4.2-3c0,0,0,0.1-0.1,0.1c0,0.3-0.1,0.6-0.1,0.9C74.1,67.5,75.3,68.5,77.4,69.5z"
      />
      <ellipse
        transform="rotate(-63.486 73.723 64.379)"
        fill={colors[3]}
        cx="73.7"
        cy="64.4"
        rx="0.5"
        ry="0.2"
      />
      <ellipse
        transform="rotate(-63.486 84.476 70.037)"
        fill={colors[3]}
        cx="84.5"
        cy="70"
        rx="0.5"
        ry="0.2"
      />
      <ellipse
        transform="rotate(-63.486 64.06 96.037)"
        fill={colors[3]}
        cx="64.1"
        cy="96"
        rx="0.5"
        ry="0.2"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M74.1,62.8c0,0,0.8,1.8,4.7,3.7s7.3,2.4,7.3,2.4 S79.2,91,63.5,97.2C62,81.1,74.1,62.8,74.1,62.8z"
      />
    </g>
  )
}

export default WornShieldLeft
