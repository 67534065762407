import React from "react"

const WarhammerR = ({
  colors = [
    "#B8B9BA",
    "#727474",
    "#E6E7E7",
    "#646565",
    "#7F8080",
    "#636565",
    "#CDCFCF",
    "#A0A0A1",
    "#D6D7D5"
  ]
}) => {
  return (
    <g id="WarhammerR">
      <path
        opacity="0.63"
        d="M39.4315109,103.8684082 c0.1445045,0.0157623,0.2968864-0.0407257,0.4492683-0.0972214c0.6174164-0.2982025,0.6883507-0.9484558,0.5753708-1.2532272 l-1.1047974-2.6062927l-0.1445007-0.0157623l-7.9437733-18.9980469l0.0157623-0.1445007l-0.2824402-0.7619171l0.0157642-0.1445007 l-0.1129742-0.3047714l-0.1445007-0.0157623l-1.4516087-3.4483337l-0.1366177-0.0880203l-0.9353333-2.1491318l0.0157642-0.1445084 l-0.1694679-0.4571457l1.3070831-0.5885239l0.1839066-0.3454971l0.3533783,0.1116562l0.7698002-0.3546982l0.3691368-0.0328369 l1.4226704-2.9885788l-0.2338295-0.5372849l-3.1002388-1.0693054l-0.2968845,0.0407333l-0.7698002,0.3546906l-0.1839085,0.3454895 l-0.3533726-0.1116638l-4.5951614,1.9113998l-0.3533745-0.1116638l-0.9221802,0.4111862l-0.11166,0.353363l-0.6896667,0.290329 l-0.1037769,0.2811203l-0.0722523-0.0078735l-0.1825905,1.0036316l0.048605,0.2246399l1.0483055,2.4539032l0.1287403,0.1602554 l0.8197212,0.5280838l0.3612556,0.0394135l0.6975555-0.3625793l0.2811222,0.1037827l0.9221821-0.4111786l0.0958958-0.2088776 l1.3793392-0.5806427l0.169466,0.4571533l0.0722485,0.0078888l0.8630829,2.1412506l-0.0078812,0.0722504l1.4437275,3.5205917 l-0.0157623,0.1445007l0.1129723,0.3047638l0.1445007,0.0157623l0.2824402,0.7619247l0.0722485,0.0078735l8.0882759,19.0138245 l-0.0078812,0.0722504l1.1048012,2.6062927C38.6039009,103.4125748,38.9257507,103.8132324,39.4315109,103.8684082z"
      />
      <rect
        x="32.829"
        y="72.358"
        transform="rotate(-22.733 33.41 88.312)"
        fill={colors[0]}
        width="1.163"
        height="31.908"
      />
      <rect
        x="26.487"
        y="73.432"
        transform="rotate(-22.733 27.178 73.469)"
        fill={colors[1]}
        width="1.381"
        height="0.073"
      />
      <rect
        x="27.808"
        y="76.435"
        transform="rotate(-22.733 28.498 76.471)"
        fill={colors[1]}
        width="1.381"
        height="0.073"
      />
      <rect
        x="27.71"
        y="76.395"
        transform="rotate(-22.733 28.4 76.431)"
        fill={colors[2]}
        width="1.381"
        height="0.073"
      />
      <rect
        x="26.608"
        y="73.426"
        transform="rotate(-22.733 27.298 73.789)"
        fill={colors[3]}
        width="1.381"
        height="0.727"
      />
      <rect
        x="28.765"
        y="76.363"
        transform="rotate(-22.733 29.456 78.834)"
        fill={colors[4]}
        width="1.381"
        height="4.942"
      />
      <rect
        x="29.844"
        y="81.069"
        transform="rotate(-22.733 30.426 81.214)"
        fill={colors[5]}
        width="1.163"
        height="0.291"
      />
      <rect
        x="33.512"
        y="84.394"
        transform="rotate(-22.733 34.093 89.918)"
        fill={colors[4]}
        width="1.163"
        height="11.048"
      />
      <rect
        x="38.26"
        y="100.187"
        transform="rotate(-22.733 38.95 101.568)"
        fill={colors[4]}
        width="1.381"
        height="2.762"
      />
      <rect
        x="37.834"
        y="100.262"
        transform="rotate(-22.733 38.415 100.335)"
        fill={colors[6]}
        width="1.163"
        height="0.145"
      />
      <polyline
        fill={colors[7]}
        points="30.5865421,79.7273865 30.6995163,80.0321579 29.3201828,80.6128006 29.207201,80.3080292"
      />
      <path
        fill={colors[8]}
        d="M38.8521843,103.1472092l1.2269478-0.5241547c0,0,0.1458244,0.6739044-0.3835831,0.8354797 C39.2305222,103.7002563,38.8521843,103.1472092,38.8521843,103.1472092z"
      />
      <path
        fill={colors[7]}
        d="M30.7152405,71.8454514l-0.7698002,0.3546906c0,0-0.0564899-0.1523819-0.1773491-0.3848953 c-0.5215187,0.089325-0.3599415,0.6187286-0.3599415,0.6187286l-6.5919094,2.7902374l-1.6775589-4.0578766l6.5919151-2.7902374 c0,0,0.2338333,0.5372849,0.706749,0.22332c-0.169466-0.4571533-0.169466-0.4571533-0.169466-0.4571533l0.7698002-0.3546906 l3.1002331,1.0693054L30.7152405,71.8454514z"
      />
      <rect
        x="22.796"
        y="70.208"
        transform="rotate(-22.733 23.304 72.607)"
        fill={colors[3]}
        width="1.018"
        height="4.797"
      />
      <rect
        x="29.077"
        y="67.953"
        transform="rotate(-22.733 29.259 70.134)"
        fill={colors[3]}
        width="0.363"
        height="4.361"
      />
      <rect
        x="28.216"
        y="68.285"
        transform="rotate(-22.733 28.361 70.465)"
        fill={colors[3]}
        width="0.291"
        height="4.361"
      />
      <polygon
        fill={colors[3]}
        points="22.8162403,75.2242126 21.9321499,74.6159973 20.8838348,72.1620941 21.1386852,71.1663361"
      />
      <polygon
        fill={colors[3]}
        points="32.1300316,68.9291306 29.9086437,69.856575 30.7152405,71.8454514"
      />
      <path
        fill={colors[3]}
        d="M29.0048542,71.4395447l0.4807968-0.3862228l0.2824402,0.7619247 c0,0-0.5136375,0.0170822-0.3599415,0.6187363C29.1743202,71.8966904,29.0048542,71.4395447,29.0048542,71.4395447z"
      />
    </g>
  )
}

export default WarhammerR
