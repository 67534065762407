import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SwordLeftHip = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SwordLeftHip") ? (
    <g id="SwordLeftHip">
      <path
        id="S_x24_swordLeftHip"
        d="M69,119.4000015h18.3000031c0,0,6.1999969-8.4000015-15.9000015-34.0999985 S69,119.4000015,69,119.4000015z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SwordLeftHip, {
  character: "ManHalfling"
})
