import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LuteRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LuteRight") ? (
    <g id="LuteRight">
      <path
        id="S_x24_luteRight"
        d="M37.7,119.4c-11.4,0-16.1-9.3-17.5-13.8l-0.2-0.9c-1-6.1-0.4-12.6,0-16l-3.6-8.1c0,0-4.6-8.4-4.6-12 s9.7-6.8,9.7-6.8l15.3,19c7.4,4.8,15.3,16.4,16.4,20.3c3,10.3-2.8,15.2-6.6,17.3C45.5,119,42.1,119.4,37.7,119.4L37.7,119.4z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LuteRight, { character: "ManDwarf" })
