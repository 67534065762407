import React from "react"

const ThickBrows = ({ colors = ["#754C28"] }) => {
  return (
    <g id="ThickBrows" fill={colors[0]}>
      <path
        opacity="0.82"
        d="M68.1,14.6h-0.2l-0.3-0.5L66.1,14l-1.5,0.1L64,15.5l0.3,0.1 l0.8-0.4l2.1,0.2c0,0,0.3,0.5,0.4,0.4s0.6-0.8,0.6-0.8L68,14.9C68.1,14.8,68.2,14.7,68.1,14.6z"
      />
      <path
        opacity="0.82"
        d="M70.2,14.6h0.2l0.3-0.5l1.5-0.1l1.5,0.1l0.6,1.4L74,15.6 l-0.8-0.4L71,15.4c0,0-0.3,0.5-0.4,0.4S70,15,70,15l0.2-0.1C70.3,14.8,70.2,14.7,70.2,14.6z"
      />
    </g>
  )
}

export default ThickBrows
