import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongBowL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongBowL") ? (
    <g id="LongBowL">
      <path d="M79.5,114.6999969c3.0999985,0,5.8000031-1.9000015,6.9000015-4.8000031l-0.5-2.8000031 c0.0999985-0.3000031,0.1999969-0.5999985,0.1999969-0.9000015l0,0c8.5-12.5,11-30.2000046,11.6999969-42.7999992h-0.1999969 L98,63v-1.2999992C98,60.7999992,98,59.9000015,98,59c0-1.2999992,0.0999985-2.5999985,0.0999985-4l-0.4000015-0.4000015 h0.0999985C97.5999985,49.6999969,97.0999985,44.8999977,96.3999939,40.5l-0.0999985-0.4000015l0,0l0,0l-0.3000031-1.7000008 c-0.4000015-2-0.8000031-4-1.1999969-5.8999977l0,0l0,0l0,0c-0.8000031-3.5000019-1.8000031-6.7000008-3-9.7000008 c-1.8000031-4.6000004-3.5-7.6999998-4.6999969-9.6000004c0-0.1000004,0-0.1000004,0-0.1999998c0,0,0,0.1000004,0,0.1999998 c-0.1999969-0.1999998-0.3000031-0.5-0.4000015-0.6999998C86.5999985,11.6999989,86.2999954,10.8999987,86,10.1999989 L86.4000015,8C85.3000031,5.0999999,82.5999985,3.1999998,79.5,3.1999998c-1.5999985,0-4.0999985,0.5-6.1999969,3.0999999 c-0.0999985,0.1999998-0.3000031,0.3000002-0.4000015,0.5c-0.1999969,0.3000002-0.5,0.5999999-0.6999969,1 c-0.3000031,0.4000001-0.5,0.8000007-0.6999969,1.2000003c-0.0999985,0.3000002-0.3000031,0.5-0.4000015,0.6999998 C71,9.6999998,71,9.8999996,70.9000015,10l0.4000015,3.8000002h-0.0999985 C71.1000061,15.1999998,71.3000031,16.5,71.8000031,17.6000004v82.8000031C71.2000046,101.5,71,102.8000031,71,104.2000046 h0.3000031l-0.4000015,3.8000031c0.0999985,0.0999985,0.1999969,0.3000031,0.3000031,0.5 c0,0,0.1999969,0.4000015,0.3000031,0.5999985l0,0C72.4000015,110.5999985,74.8000031,114.6999969,79.5,114.6999969 L79.5,114.6999969z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongBowL, {
  character: "WomanMixed"
})
