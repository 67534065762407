import React from "react"

const BobR = ({ colors = ["#4691FF", "#FFFFFF"] }) => {
  return (
    <g id="BobR">
      <path
        opacity="0.2"
        fill="#21376C"
        d="M68.9572372,11.5794373 c-0.1072845,0.1609287-0.4291382,0.9655733-0.4291382,0.9655733s-2.521225-0.0536423-2.6821518,0 c-0.1609268,0.0536432-1.4483643,1.7702188-1.6629333,2.5212212c-0.2145729,0.7510014-0.4291458,3.9695807-0.3754997,4.8815126 c0.0536423,0.9119301,0.4827843,3.0040073,1.1801414,3.7550087c0.6973648,0.7510014,2.7357941,1.9847908,2.7357941,1.9847908 l0.3755035,0.2682152l-1.7702179-0.1072865l-1.9847946-1.3947182l-2.5748634-4.1305103l2.682148-9.0656652L68.9572372,11.5794373z"
      />
      <path
        fill={colors[0]}
        d="M68.3269348,8.2401609c-0.7644119-0.1609287-1.7702179-1.005806-3.9829941,0.6839485 c-4.3853111,7.1613379-4.7876358,12.4317617-4.7876358,12.1099043c0-0.175415,2.8418159,3.995924,3.7818298,5.0290298 c0.2769165,0.3043404,1.6253052,1.1778679,2.132309,0.9655743c0-0.6034832-0.1060257-1.3281174,0.0402374-1.4483604 c-0.3218536-0.3620853-1.6495247-1.9311485-1.6495247-1.9311485s1.4483604,1.5690575,3.4599724,2.0920773 c-1.9311447-1.0862713-2.6955643-1.2874317-4.0634575-5.2301922c0.0804634-2.4943981,0.3977203-5.4226522,1.3276634-7.0406418 c0.8562393-1.489747,1.431282-1.5262518,3.9427643-1.2471991C68.9706497,9.487361,69.0913467,8.4010906,68.3269348,8.2401609z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M60.2804871,21.878891l1.8909149,0.8046455 c0,0-1.126503-0.8851109-0.6839485-2.6553288c0.120697,1.1667347,1.7299843,3.3392754,1.7299843,3.3392754l-0.2011604-0.7241802 l1.6092873,2.0116119l0.8851166,0.9253426l-0.0402374,1.4483604l-0.7644119-0.0804653l-1.2472-0.7241802l-3.1783447-4.1439209 V21.878891z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M68.5984955,8.6022453 c-0.0300293-0.024231-0.0604858-0.0386963-0.0906372-0.0611572l0.0001221-0.0092163l-0.0123291,0.0009766 c-1.0624008-0.7839241-2.3265457-0.5983996-3.1861343-0.091526c-0.8761444,0.5166368-1.3379974,1.3636608-1.3779564,1.4885597 c1.3026161-1.177124,3.7013588-0.4224243,3.7013588-0.4224243S65.892868,9.3163567,65.41008,9.7086296 c1.8507004-0.3218498,2.1624756,1.0762329,2.1624756,1.0762329s-0.4928207-0.6537857-1.7299805-0.0905151 c0.9957886-0.4224854,2.4541626,1.0862427,2.4541626,1.0862427l0.2011719-2.4240112l0.0095825-0.7827759 C68.5377045,8.5855827,68.5683441,8.5894279,68.5984955,8.6022453z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M66.3756714,11.4721508 c-0.6238098,0.1490536-1.1774292,0.4364738-1.6592636,0.8292103c-0.9196243,1.0899467-2.7625275,5.0721493-2.176136,7.9668655 c0.3022537,1.492054,0.9630966,2.8385429,1.998127,3.7161503c-0.6598701-0.9313889-1.1871948-2.180624-1.4802513-3.4938679 c0.3145409-6.1677065,1.1777306-7.1601524,2.0099754-8.1868916 C65.4219666,11.8670845,66.0804214,11.6131649,66.3756714,11.4721508z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M63.0946693,13.1635008 c-0.0003662-0.6397095,0.6457901-2.1875467,1.2291603-3.1732368c-1.3276672,1.2069674-2.1725197,3.3996162-2.1725197,3.3996162 s-1.0794868,2.3104715-1.6294289,4.3652067c-0.3678818,1.3744946-0.269474,2.6668034-0.3017426,2.9570694 c0.2229004-0.4457397,0.0477295-0.6447735,1.4282455-2.5949802c-0.125061,1.2064209-0.0402412,1.8909168,0.382206,2.7961407 c0.0402222-2.1121826,0.0402145-4.0433331,1.9915123-8.5493765 C64.0221024,12.3639402,63.5230141,12.6173582,63.0946693,13.1635008z"
      />
    </g>
  )
}

export default BobR
