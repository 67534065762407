import React from "react"

const Cape = ({ colors = ["#7B0A00", "#SVGID_", "#FFFFFF"] }) => {
  return (
    <g id="Cape">
      <path
        fill={colors[0]}
        d="M47.2143097,87.8463593c-1,11-2,26.0999985-4.5,29.9000015h11l-4-35.4000015 c0,0-0.2999992,0.5-1.0999985,2.0999985c0.2000008,0.1999969,0,0.6999969-0.0999985,1.0999985 c-0.0999985,0.3000031-0.2999992,0.5-0.2999992,0.5C48.1143074,86.3463593,47.6143074,87.1463547,47.2143097,87.8463593z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M83.4143143,73.4463577 c-0.0999985-0.8000031-4.8000031-10.5999985-3.9000015-11.2999992c0.8000031-0.7000008,4-2,7.5,6.4999962 c-0.8000031-3.5999985-1.8000031-7.4000015-1.8000031-7.4000015s-4-5.2999992-9.0999985-2.5999985 c6.1999969-6.5999985-0.8000031-16.7999992-7.6999969-18.0999985 c-6.8000031,1.0999985-13.0000038,15.2999992-7.2000046,18.0999985c-5.0999985-2.7000008-9.1000023,2.5999985-9.1000023,2.5999985 s-1,3.8000031-1.7999992,7.4000015c3.5-8.5,6.7000008-7.2000008,7.5-6.5c0.9000015,0.7000008-3.7999992,10.5-3.9000015,11.3000031 c-0.4000015,3.9000015-3.5999985,6-4.0999985,9c1.2999992,12.8000031-0.2000008,22-0.9000015,35.3000031 c1.2000008,0,3.9000015,0,4.7999992,0c7.2000008,0,7.7999992-56.4000015,15.0000038-56.5999985 c7.1999969,0.2000008,7.5,56.4000015,14.6999969,56.4000015c1.3000031,0,3.1999969,0,4.8000031,0 c-0.5-10.3000031-1.9000015-23-0.5999985-34.9000015C86.8143158,80.6463547,84.0143127,77.1463547,83.4143143,73.4463577z M75.6143112,53.4463577c-0.4000015,3.9000015-3.6999969,6-6.9000015,6.2000008 c-3.0999985-0.4000015-6.6000023-2.2000008-6.6000023-6.2000008s3.1000023-10.0999985,6.4000053-10.0999985 C71.7143097,43.246357,76.1143112,48.5463562,75.6143112,53.4463577z"
      />
      <path
        fill={colors[0]}
        d="M47.2143097,87.8463593c-1,11-2,26.0999985-4.5,29.9000015h11l-4-35.4000015 c0,0-0.2999992,0.5-1.0999985,2.0999985c0.2000008,0.1999969,0,0.6999969-0.0999985,1.0999985 c-0.0999985,0.3000031-0.2999992,0.5-0.2999992,0.5C48.1143074,86.3463593,47.6143074,87.1463547,47.2143097,87.8463593z"
      />
      <path
        fill={colors[0]}
        d="M88.4143143,84.4463577c-0.1999969,0.1999969-0.0999985,0.5999985,0.0999985,1.0999985 c0.1999969,0.3000031,0.1999969,0.4000015,0.8000031,1.3000031c1,11.0999985,2,27,4.5999985,30.9000015h-11l4.0999985-36.1000061 L88.4143143,84.4463577z"
      />
      <path
        fill={colors[0]}
        d="M79.0143127,54.4463577c0.5999985-2.0999985,2.1999969-7.0999985,1.1999969-8.4000015 c-0.8000031-0.7999992-1.5999985-0.5-2.4000015-1.5c-1.5999985-4.5999985-4.9000015-6.7999992-10-6.5999985 c-4.5000038-0.0999985-6.7000046,2.5999985-8.4000053,6.5999985c-0.7000008,0.9000015-2,0.7000008-2.5999985,1.2000008 c-1.2000008,2.5999985,0.9000015,7.5,1,8.7000008c-1,0.2000008-4.2000008,2-5.2000008,3.2000008 s-1.9000015,7.3999977-2.7000008,11.6000023c0.2999992-0.4000015,0.7999992-2.0999985,1-2.5 c0.5-1.1999969,0.4000015-4.9000015,2.2000008-5.9000015c3-1.7000008,12.3000031,2.5999985,14.4000015-0.9000015 c-4.5000038-1.2000008-8.2000046-1.7000008-7.3000031-7.2000008c1.2999992-8.5,8.0000076-11.2999992,8.0000076-11.2999992 s8.9000015,4.2000008,8.5,11.0999985c-0.3000031,5.5-2.6999969,6.2000008-7.1999969,7.5 c2.0999985,3.5,10.9000015-0.9000015,13.9000015,0.7999992c1.8000031,1,2.1999969,5.6000023,2.6999969,6.7999992 c0.1999969,0.4000015,1,2.5999985,1.3000031,3.5c-0.5999985-4.5-2.1999969-12.2000008-3.3000031-13.7000008 S80.1143112,54.6463585,79.0143127,54.4463577z"
      />
      <path
        opacity="0.58"
        fill="url(#SVGID_5_)"
        d="M79.0143127,54.4463577 c0.5999985-2.0999985,2.1999969-7.0999985,1.1999969-8.4000015c-0.8000031-0.7999992-1.5999985-0.5-2.4000015-1.5 c-1.5999985-4.5999985-4.9000015-6.7999992-10-6.5999985c-4.5000038-0.0999985-6.7000046,2.5999985-8.4000053,6.5999985 c-0.7000008,0.9000015-2,0.7000008-2.5999985,1.2000008c-1.2000008,2.5999985,0.9000015,7.5,1,8.7000008 c-1,0.2000008-4.2000008,2-5.2000008,3.2000008s-1.9000015,7.3999977-2.7000008,11.6000023 c0.2999992-0.4000015,0.7999992-2.0999985,1-2.5c0.5-1.1999969,0.4000015-4.9000015,2.2000008-5.9000015 c3-1.7000008,12.3000031,2.5999985,14.4000015-0.9000015c-4.5000038-1.2000008-8.2000046-1.7000008-7.3000031-7.2000008 c1.2999992-8.5,8.0000076-11.2999992,8.0000076-11.2999992s8.9000015,4.2000008,8.5,11.0999985 c-0.3000031,5.5-2.6999969,6.2000008-7.1999969,7.5c2.0999985,3.5,10.9000015-0.9000015,13.9000015,0.7999992 c1.8000031,1,2.1999969,5.6000023,2.6999969,6.7999992c0.1999969,0.4000015,1,2.5999985,1.3000031,3.5 c-0.5999985-4.5-2.1999969-12.2000008-3.3000031-13.7000008S80.1143112,54.6463585,79.0143127,54.4463577z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M83.3143158,73.4463577 c-0.0999985-0.8000031-4.8000031-10.5999985-3.9000015-11.2999992c0.8000031-0.7000008,4-2,7.5,6.4999962 c-0.8000031-3.5999985-1.8000031-7.4000015-1.8000031-7.4000015s-4-5.2999992-9.0999985-2.5999985 c6.1999969-6.5999985-0.8000031-16.7999992-7.6999969-18.0999985 c-6.8000031,1.0999985-13.0000038,15.2999992-7.2000046,18.0999985c-5.1000023-2.5999985-9.1000023,2.7000046-9.1000023,2.7000046 s-1,3.8000031-1.7999992,7.4000015c3.5-8.5,6.7000008-7.2000008,7.5-6.5c0.9000015,0.7000008-3.7999992,10.5-3.9000015,11.3000031 c-0.4000015,3.9000015-3.5999985,6-4.0999985,9c1.2999992,12.8000031-0.2000008,21.9000015-1,35.3000031 c0.9000015,0,39.3000031,0,39.3000031,0s-1.8000031-23-0.5-34.9000015 C86.8143158,81.2463608,82.1143112,72.8463593,83.3143158,73.4463577z M75.5143127,53.4463577 c-0.4000015,3.9000015-3.6999969,6-6.9000015,6.2000008c-3.0999985-0.4000015-6.6000023-2.2000008-6.6000023-6.2000008 s3.1000023-10.0999985,6.4000053-10.0999985C71.6143112,43.3463593,76.0143127,48.5463562,75.5143127,53.4463577z"
      />
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1="68.664"
        y1="1299.28"
        x2="68.664"
        y2="1338.969"
        gradientTransform="translate(0 -1280)"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="1" stopColor="#fff" stopOpacity="0" />
      </linearGradient>
    </g>
  )
}

export default Cape
