import React from "react"

const LeatherHelm = ({
  colors = ["#8B5E3C", "#C0A172", "#996464", "#603913"]
}) => {
  return (
    <g id="LeatherHelm">
      <path
        opacity="0.16"
        d="M57.7447166,44.5c0,0,0-0.0999985-0.1049957-0.2000008v-0.2000008h-0.1049957 C57.0097427,42.6999969,55.014801,38,50.3949432,36.7999992l0,0l-1.8899422-1.8999977l-2.0999374,1.8999977 c-4.7248573,1.0999985-6.719799,5.9000015-7.139782,7.2999992h-0.1049957v0.2000008 c0,0.0999985-0.1049957,0.2000008-0.1049957,0.2000008h0.1049957v0.0999985v2l7.6647682,1.5h3.0449104l7.6647682-1.5v-2 L57.7447166,44.5L57.7447166,44.5z"
      />
      <path
        fill={colors[0]}
        d="M39.1602783,43.5999985h18.5844383c0,0-2.2049294-7.9000015-9.3447151-7.9000015 S39.1602783,43.5999985,39.1602783,43.5999985z"
      />
      <rect
        x="39.265"
        y="43.2"
        fill={colors[1]}
        stroke={colors[2]}
        strokeWidth="0.175"
        strokeMiterlimit="10"
        width="18.374"
        height="3"
      />
      <rect x="39.265" y="43.7" fill={colors[3]} width="18.374" height="2" />
      <polygon
        fill={colors[1]}
        points="46.4050598,35.9000015 47.035038,43.2999992 49.7649612,43.2999992 50.499939,35.9000015 48.5049973,34"
      />
      <polygon
        fill={colors[3]}
        points="48.295002,34.2000008 46.7200508,35.7000008 47.3500328,43 48.1900063,43"
      />
      <polygon
        fill={colors[0]}
        points="48.5049973,34.2000008 50.0799484,35.7000008 49.4499702,43 48.4000015,43"
      />
    </g>
  )
}

export default LeatherHelm
