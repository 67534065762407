import React from "react"

const StrapsRb = ({ colors = ["#573B25"] }) => {
  return (
    <g id="StrapsRb">
      <path
        fill={colors[0]}
        d="M61.2741013,89.5470047c-2.8822861,0.1330795-5.7369576-0.0150986-8.589798-0.4458313 c-0.0379333-0.0057449-0.2074394,1.7292023,0,1.7605286c1.2891731,0.1946487,2.5789948,0.3233261,3.8712349,0.4026794 c-1.353756,0.2374191-2.7075119,0.4747849-4.0613213,0.712204c-0.2150688,0.0377731-0.0284233,1.7655182,0,1.7605209 c0.9463463-0.1659622,1.8926353-0.331871,2.8389816-0.4978333c1.7685814,0.3607254,3.4985847,0.8589859,5.1975899,1.5065536 c-0.0393791-0.0150375,0.1489334-0.8235245,0-0.8802567c-1.0158119-0.3872147-2.044735-0.7126389-3.0840302-0.9969025 c1.1961212-0.2097549,2.392189-0.4195023,3.5883102-0.6292496c0.110302-0.0193405,0.1145477-0.4820938,0.0890808-0.9286194 c0.0500717-0.0021439,0.0998764-0.0009613,0.1499519-0.003273C61.4230843,91.3006439,61.3755913,89.5423279,61.2741013,89.5470047 z"
      />
    </g>
  )
}

export default StrapsRb
