import React from "react"

const ArmorBoots = ({ colors = ["#898888", "#FFFFFF", "#4F4845"] }) => {
  return (
    <g id="ArmorBoots">
      <path
        fill={colors[0]}
        d="M54.4,113.8L54.4,113.8c0-0.1,0.3-3.4,0.3-3.4c1-4.7,4.4-14.2,4.4-14.2l-8.9-1.3l-1.1,14.7 c0,0-0.2,1-0.3,1c-0.5,1.4-0.9,2-1.4,2.9c-0.6,1.1-2.2,3.4-2.3,3.7c0,0.2,0,0.4,0,0.5c0,0.2,0,0.3,0,0.3c2,0,9.3,0,9.7,0 l-0.1-2.1L54.4,113.8z"
      />
      <path
        opacity="0.5"
        fill="#FFF"
        d="M47,114.8c0,0,1.8-2,4.6-1.3s3.1,2.3,3.1,2.3l-0.4-1.9 l-2.8-1.8l-3.2,0.9L47,114.8z"
      />
      <path
        opacity="0.5"
        fill="#FFF"
        d="M46.8,116.1c-0.3,0.2,1.6-2,4.5-1.4s3.1,2.6,3.1,2.6l-0.4-1.9 l-2.8-1.8l-3,0.8L46.8,116.1z"
      />
      <path
        fill="#FFF"
        d="M51,114.9l2.1,1.4c0,0-1.9-0.9-2.3-0.7C50.9,115.3,51,114.9,51,114.9z"
      />
      <path
        fill="#FFF"
        d="M51.2,113.7l-0.2,0.9l0.8,0.2l0.2-0.7L51.2,113.7L51.2,113.7z"
      />
      <path
        fill="#FFF"
        d="M51.7,111.6L53,107c0,0-0.3,4.6-0.6,4.9C52.3,111.8,52,111.8,51.7,111.6"
      />
      <path
        opacity="0.5"
        fill="#FFF"
        d="M48.1,112.5c1-0.2,2.3-0.9,3.4-0.7c1.3,0.2,2.4,1.4,3,1.5 c0-0.6-0.5-6.5-1.5-6.5S49.6,110.7,48.1,112.5z"
      />
      <path fill={colors[2]} d="M53.3,97" />
      <path fill={colors[2]} d="M53.2,97" />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="50.4,95 50.3,96.4 58.6,97.7 59,96.4"
      />
      <polygon
        opacity="0.5"
        fill="#FFF"
        points="47.7,116.3 51,114.9 53.7,116.6 53.9,117.6 47.5,117.4 46.3,117.5"
      />
      <polygon
        fill="#FFF"
        points="51.5,112.1 51.2,113.3 52.1,113.5 52.3,112.6"
      />
      <g>
        <path
          fill={colors[0]}
          d="M83.6,113.8L83.6,113.8c0-0.1-0.3-3.4-0.3-3.4c-1-4.7-4.4-14.2-4.4-14.2l8.9-1.3l1.1,14.7 c0,0,0.2,1,0.3,1c0.5,1.4,0.9,2,1.4,2.9c0.6,1.1,2.2,3.4,2.3,3.7c0,0.2,0,0.4,0,0.5c0,0.2,0,0.3,0,0.3c-2,0-9.3,0-9.7,0l0.1-2.1 L83.6,113.8z"
        />
        <path
          opacity="0.5"
          fill="#FFF"
          d="M91.1,114.8c0,0-1.8-2-4.6-1.3s-3.1,2.3-3.1,2.3l0.4-1.9 l2.8-1.8l3.2,0.9L91.1,114.8z"
        />
        <path
          opacity="0.5"
          fill="#FFF"
          d="M91.2,116.1c0.3,0.2-1.6-2-4.5-1.4s-3.1,2.6-3.1,2.6l0.4-1.9 l2.8-1.8l3,0.8L91.2,116.1z"
        />
        <path
          fill="#FFF"
          d="M87.1,114.9l-2.1,1.4c0,0,1.9-0.9,2.3-0.7C87.2,115.3,87.1,114.9,87.1,114.9z"
        />
        <path
          fill="#FFF"
          d="M86.9,113.7l0.2,0.9l-0.8,0.2l-0.2-0.7L86.9,113.7L86.9,113.7z"
        />
        <path
          fill="#FFF"
          d="M86.4,111.6l-1.3-4.6c0,0,0.3,4.6,0.6,4.9C85.8,111.8,86.1,111.8,86.4,111.6"
        />
        <path
          opacity="0.5"
          fill="#FFF"
          d="M90,112.5c-1-0.2-2.3-0.9-3.4-0.7c-1.3,0.2-2.4,1.4-3,1.5 c0-0.6,0.5-6.5,1.5-6.5S88.4,110.7,90,112.5z"
        />
        <path fill={colors[2]} d="M84.8,97" />
        <path fill={colors[2]} d="M84.8,97" />
        <polygon
          opacity="0.2"
          fill="#FFF"
          points="87.7,95 87.8,96.4 79.5,97.7 79.1,96.4"
        />
        <polygon
          opacity="0.5"
          fill="#FFF"
          points="90.4,116.3 87.1,114.9 84.4,116.6 84.2,117.6 90.6,117.4 91.7,117.5"
        />
        <polygon
          fill="#FFF"
          points="86.6,112.1 86.8,113.3 86,113.5 85.8,112.6"
        />
      </g>
    </g>
  )
}

export default ArmorBoots
