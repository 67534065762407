import React from "react"

const Ring = ({ colors = ["#D89628"] }) => {
  return (
    <g id="Ring" fill={colors[0]}>
      <path d="M66.9552917,50.7958488c0.1826172-0.8567276,0.943924-1.501667,1.8543243-1.501667 c0.9037399,0,1.6589813,0.6362343,1.8486862,1.4835625c0.1489868,0.0000534,0.2979202-0.0023651,0.4468994-0.0022049 c-0.1967468-1.091835-1.1469574-1.9217033-2.2954788-1.9217033c-1.1562042,0-2.1099625,0.841526-2.2977371,1.9441605 C66.6597366,50.7968712,66.8075409,50.7968178,66.9552917,50.7958488z" />
      <path d="M70.6367645,51.6743393c-0.215126,0.8114891-0.9488754,1.4142494-1.8271484,1.4142494 c-0.884346,0-1.6230316-0.610714-1.8322983-1.4307442c-0.1527481-0.0008087-0.3053818-0.0011292-0.4581299-0.0020981 c0.2154999,1.0682526,1.158783,1.8728676,2.290535,1.8728676c1.1243439,0,2.0619354-0.7944069,2.2855453-1.8522339 C70.9424133,51.6765404,70.7896118,51.6742287,70.6367645,51.6743393z" />
    </g>
  )
}

export default Ring
