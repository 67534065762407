import React from "react"

const SlingShotLeft = ({
  colors = ["#362812", "#454545", "#949494", "#724E1F"]
}) => {
  return (
    <g id="SlingShotLeft">
      <path
        fill={colors[0]}
        d="M67.2240448,92.111618l0.9421616-8.565094l5.3103561-2.4838791 c0,0,1.8843155,1.7130203,1.7986679,3.9399414c0,0.3426056,1.7986679,29.2926254,1.7986679,29.2926254 s-0.1713028,2.0556259-1.0278091,2.3982315s-2.7408295-0.3426056-2.7408295-0.3426056l-0.4282532-2.3982239l0.4282532-29.2926407 l1.0278091-1.6273651l-0.9421616-1.2847672l-4.5394974,2.3982315l0.8565063,1.9699707l-0.0856476,7.4516373l-1.9699707,2.3125839 l0.4282532-4.7108002L67.2240448,92.111618z"
      />
      <path
        fill={colors[1]}
        d="M77.2452011,113.0104599c-0.6852112-0.9421616-0.8565063-1.5417252-2.1412735-1.3704147 c-0.1712952,0-1.6273651,0.0856476-1.7130203,0.2569504c-0.4282532,0.6852112-1.0278091,2.3125763-1.0278091,2.3125763 s0.9421616,1.7986679,2.1412811,1.8843155c0.8565063,0.0856552,2.6551743-0.7708588,2.6551743-0.7708588 S77.5878067,113.4387131,77.2452011,113.0104599z"
      />
      <path
        fill={colors[2]}
        d="M75.275238,112.7535019c-0.1713028-0.3426056-0.3426056-0.7708588-0.5139084-1.1134644 c-0.5139008,0-1.1991119,0.0856476-1.3704147,0.256958c-0.4282532,0.6852036-1.0278091,2.3125687-1.0278091,2.3125687 s0.3426056,0.6852112,0.9421616,1.1991196c0.599556-0.1713028,1.1991119-0.5139084,1.7986679-0.9421616 C75.1039276,113.9526138,75.1895828,113.3530655,75.275238,112.7535019z"
      />
      <path
        fill={colors[3]}
        d="M74.3330765,115.836937l1.1991119-0.0856476l0.1713028-2.0556259l-0.5139084-2.2269287 l-1.1134644-27.6652527l-3.2547379-1.5417252l1.7986679-0.8565063l0.856514-0.4282532l1.1991119,0.5995483l0.7708588,1.7986755 l1.7130203,30.834343c0,0-0.3426056,2.3982315-1.4560623,2.0556259c-0.1713028-0.0856476-1.5417175,0-2.0556259-0.3426056 C74.1617737,115.6656342,74.3330765,115.836937,74.3330765,115.836937z"
      />
    </g>
  )
}

export default SlingShotLeft
