import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GoldAxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GoldAxeRight") ? (
    <g id="GoldAxeRight">
      <path
        id="S_x24_goldAxeRight"
        d="M38.0040092,120.6557388l-17.7000027-34l-1.8999996,1.5999985 c-5.5999994-0.1999969-10.3999996-3.0999985-12.8999996-7.5999985l0,0c-2.5-4.5-2.4000001-10.0999985,0.2000003-15.0999985 l4.0999999-1.2000046l3.0999994-4.1999969l2.3999996-1.4000015l0.6000004-0.4000015h0.3000011v-0.0999985l0.3999996-0.2000008 l4.2999992-0.4000015l0.3999996-0.0999985l0,0h0.5l3.1000004-2.7000008 c5.6000004,0.2999992,10.4000015,3.2000008,12.9000015,7.7000008l0,0c2.5,4.4999962,2.4000015,10.1000023-0.2000008,15.1000023 l-2.5999985,0.8000031c4.7999992,9.4000015,9.5,18.5999985,11.2000008,21.5999985 c2.4000015,4.3000031,6.4000015,9.6999969,7.5999985,11.3000031l1,6.5999985 c-0.2999992,0.9000015-0.7000008,1.6999969-1.2000008,2.5l-4.0999985,3l-3.4000015,0.9000015L38.0040092,120.6557388z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GoldAxeRight, {
  character: "ManHalfling"
})
