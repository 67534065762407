import React from "react"

const BattleAxeLeft = ({ colors = ["#7A7B7D"] }) => {
  return (
    <g id="BattleAxeLeft">
      <path
        fill={colors[0]}
        d="M80.1999969,31C80,31.5,79.5,32,79,32.2000008c-2.0999985,1-3.8000031,1.5-6.0999985,1.5 C72.5999985,34,71.9000015,34.4000015,71.5,34.7999992c0-0.2000008,0.0999985-3.7999992,0.0999985-4 c0,0,0.8000031-0.1000004,0.8000031-0.2000008c0,0-1.5-0.7000008-1.6999969-5.2000008 c-0.5,4.5-1.9000015,5.2000008-1.9000015,5.2000008c0,0.2000008,0.8000031,0.2000008,0.8000031,0.2000008 c0,0.2000008-0.0999985,4-0.0999985,4s-0.9000015-0.5999985-1.4000015-1.0999985c-2.3000031-0.0999985-4-0.5999985-6-1.7000008 c-0.5-0.2000008-1-0.7000008-1.0999985-1.2000008c0,0-9,12.6000023,2,21.7000008 c-1.4000015-5.2999992-1.7000008-12.7000008,4.8000031-13.2000008c0.0999985,0,1.5999985-0.0999985,1.5999985-0.0999985 l-0.1999969,4.5999985C69.1000137,44,68.8000107,44.0999985,68.5000153,44.5h0.9000015v1.4000015h-1c0,0.0999985,0,1.9000015,0,2 c0.0999985,0,1,0,1.0999985,0C69.2000122,67.5,68.7000122,98.9000015,68.4000168,118.5h-0.0999985 c0,1.5,2.0999985,1.1999969,2.1999969,0h-0.0999985c0.3000031-19.5999985,0.8000031-51,1.0999985-70.5999985 c0.0999985,0,1,0,1.0999985,0c0-0.0999985,0-1.9000015,0-2h-1V44.5h0.9000015c-0.0999985-0.2000008-0.5-0.5-0.6999969-0.7000008 v-4.5c0,0,1.4000015,0.0999985,1.5999985,0.0999985c6.5,0.7000008,6,8.1000023,4.4000015,13.2999992 C88.8000031,43.7999992,80.1999969,31,80.1999969,31z"
      />
      <path
        d="M68.1999969,33.5999985c-2.1999969-0.0999985-3.8000031-0.5-5.7000008-1.5 c-2.0999985,3.5-5.7000008,11.4000015,0,17.4000015c-0.7000008-4.7000008,0-9.9000015,5.3999977-10.2999992 c0.0999985,0,1.1999969,0,1.5,0l0.1999969-4.4000015C69.1999969,34.5,68.5,33.9000015,68.1999969,33.5999985z"
        opacity="0.52"
      />
      <path
        d="M73,33.7000008c2.1999969,0,3.8000031-0.4000015,5.6999969-1.2999992c2,3.5,5.3000031,11.5-0.5,17.4000015 c0.9000015-4.7000008,0.3000031-9.9000015-5.0999985-10.5c-0.0999985,0-1.3000031,0-1.5999985,0v-4.5 C71.8000031,34.5999985,72.5999985,34,73,33.7000008z"
        opacity="0.52"
      />
      <rect
        x="69.883"
        y="45.471"
        transform="rotate(-89.133 70.283 47.52)"
        opacity="0.52"
        width="0.8"
        height="4.1"
      />
      <rect
        x="70.184"
        y="42.571"
        transform="rotate(-89.133 70.334 44.571)"
        opacity="0.52"
        width="0.3"
        height="4"
      />
      <path
        opacity="0.52"
        d="M69.5999985,30.7000008c0,0-0.5,0-0.8000031-0.2000008c0.5-0.2000008,2.1999969-0.2000008,3.5999985,0 c-0.0999985,0.1000004-0.8000031,0.2000008-0.8000031,0.2000008S70.6999969,30.3999996,69.5999985,30.7000008z"
      />
    </g>
  )
}

export default BattleAxeLeft
