import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BookSpellL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BookSpellL") ? (
    <g id="BookSpellL">
      <path d="M86.0349045,48.6338158c0.1154099-0.2321854-11.8071976-8.5274086-1.2299118-18.8587093 c10.5772705-10.3312893,16.6448593-3.93573,16.6448593-3.93573s-2.541832-6.8055325,9.7573318-6.6415443 c12.2991562,0.1639919,13.0371017,9.2653637,13.0371017,9.2653637s9.0193863-7.5434818,14.5130081,12.3811493 s-5.9036102,25.500267-5.9036102,25.500267s-1.3119049,7.3794861-9.593338,13.3650818 c-8.2814255,5.9855881-14.7589798,2.8697968-14.7589798,2.8697968s-3.5257568,3.1157913-8.1994476,3.9357376 C95.6282425,87.3351746,84.9689789,73.232132,84.9689789,73.232132S78.9833832,62.8188515,86.0349045,48.6338158z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BookSpellL, {
  character: "ManDragonborn"
})
