import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LongPants from "./LongPants"
import Breeches from "./Breeches"
import Rope from "./Rope"
import DarkPatch from "./DarkPatch"
import LightPatch from "./LightPatch"

const CATEGORY = "bottom"
const CHARACTER = "ManHalfling"
export const IDS = {
  LONG_PANTS: "LongPants",
  BREECHES: "Breeches",
  ROPE: "Rope",
  DARK_PATCH: "DarkPatch",
  LIGHT_PATCH: "LightPatch"
}

export const components = {
  [IDS.LONG_PANTS]: LongPants,
  [IDS.BREECHES]: Breeches,
  [IDS.ROPE]: Rope,
  [IDS.DARK_PATCH]: DarkPatch,
  [IDS.LIGHT_PATCH]: LightPatch
}

export const Group = ({ props }) => (
  <g id="bottom">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LONG_PANTS,
    name: "Long Pants",
    defaultColors: ["#7A695C"],
    colorable: true,
    component: components[IDS.LONG_PANTS],
    inUI: true,
    enabled: false,
    subGroupId: 4
  },
  {
    id: IDS.BREECHES,
    name: "Breeches",
    defaultColors: ["#77634F"],
    colorable: true,
    component: components[IDS.BREECHES],
    inUI: true,
    enabled: false,
    subGroupId: 4
  },
  {
    id: IDS.ROPE,
    name: "Rope",
    defaultColors: ["#FFFFFF"],
    colorable: false,
    component: components[IDS.ROPE],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.DARK_PATCH,
    name: "Dark Patch",
    defaultColors: [],
    colorable: false,
    component: components[IDS.DARK_PATCH],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.LIGHT_PATCH,
    name: "Light Patch",
    defaultColors: ["#FFFFFF"],
    colorable: false,
    component: components[IDS.LIGHT_PATCH],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
