import React from "react"

const FlameLeft = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameLeft">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M87.3198013,60.8262444c-2.1999969-3.8000031-2.5-7-1-10.3000031 c0.5999985-1.3000031,1.5-2.5999985,2.5-3.9000015c0.8000031-1.0999985,1.7000046-2.2000046,2.3000031-3.4000015 c0.0999985-0.3000031,0.3000031-0.5999985,0.5-1l0.5999985,0.0999985c-0.0999985,0.5,0,1.0999985,0.0999985,1.5999985 c0.4000015,1.5,1.7000046,2.8999939,3.8000031,4.1999969l-0.0999985,0.0999985l-0.5000076,0.6999969l1,0.1999969 c0.3000031,0.8000031,0.7000046,1.6000061,1.1000061,2.3000031c0.1999969,0.5,0.5,1,0.6999969,1.5 c0.1000061,0.3999939,0.3000031,0.7999954,0.4000015,1.1999969c-0.5999985,1.4000015-1.2999954,2.6999969-2.0999985,4 c-0.3000031,0.5-0.6999969,1-1,1.5c-1.5,0.7000046-3.1999969,1.3000031-5,1.9000015 C89.5198059,61.526226,88.5198059,61.3262291,87.3198013,60.8262444z"
        />
        <path
          fill={colors[1]}
          d="M88.5198059,61.2262306c-2.0999985-3.5999985-2.4000015-6.5999985-0.9000015-9.6999969 c0.5999985-1.3000031,1.5-2.5,2.4000015-3.8000031c0.5-0.8000031,1.0999985-1.5,1.5999985-2.3000031 c0.5,1.3000031,1.5999985,2.6000061,3.4000015,3.8000031l-0.7999954,1.0999985l1.5,0.1999969 c0.3000031,0.6999969,0.6999969,1.4000015,1,2c0.1999969,0.4000015,0.5,0.9000015,0.6999969,1.4000015 c0.2000046,0.3000031,0.3000031,0.5999985,0.4000015,1c-0.5999985,1.4000015-1.1999969,2.6000061-2,3.8000031 c-0.2000046,0.5-0.5,0.9000015-0.8000031,1.3000031c-1.3000031,0.5999985-2.8000031,1.0999985-4.3000031,1.5999985 C89.9198074,61.6262321,89.2198029,61.4262276,88.5198059,61.2262306z"
        />
        <path
          fill={colors[2]}
          d="M89.5198059,61.6262169c-1.9000015-3.4000015-2.2000046-6.2999954-0.8000031-9.0999985 c0.5999985-1.1999969,1.5-2.3999939,2.4000015-3.6999969c0.1999969-0.2999954,0.3000031-0.5,0.5-0.6999969 c0.5999985,0.7999954,1.4000015,1.5,2.4000015,2.1999969l-1,1.4000015l2.0999985,0.3000031 c0.2000046,0.5999985,0.5,1.1999969,0.8000031,1.6999969c0.1999969,0.4000015,0.5,0.9000015,0.6999969,1.4000015 c0.1000061,0.3000031,0.2000046,0.5,0.3000031,0.8000031c-0.5999985,1.1999969-1.2000046,2.4000015-1.9000015,3.5 c-0.1999969,0.3000031-0.5,0.6999969-0.6999969,1c-1.0999985,0.5-2.3000031,0.9000015-3.5,1.3000031 C90.4198074,61.6262321,89.9198074,61.526226,89.5198059,61.6262169z"
        />
        <path
          fill={colors[3]}
          d="M90.5198135,61.726223c-1.5999985-3.0999985-1.9000015-5.8000031-0.5999985-8.4000015 c0.5-1,1.2000046-2,1.9000015-3c0.2999954,0.3000031,0.6999969,0.5999985,1.0999985,0.9000015l-1.1999969,1.5999985 l2.5999985,0.4000015c0.1999969,0.5,0.5,0.9000015,0.6999969,1.4000015s0.5,0.9000015,0.6999969,1.4000015 c0.0999985,0.1999969,0.0999985,0.4000015,0.1999969,0.5999985c-0.6000061,1.2000046-1.1000061,2.2000046-1.8000031,3.3000031 c-0.1999969,0.3000031-0.3000031,0.5-0.5,0.8000031c-0.8999939,0.4000015-1.7999954,0.6999969-2.6999969,1 C90.819809,61.7262306,90.6198044,61.6262321,90.5198135,61.726223z"
        />
        <path
          fill={colors[4]}
          d="M90.9198151,53.8262291l2.5999985,0.4000015 c0.1999969,0.3999939,0.4000015,0.7999954,0.5999985,1.1999969c0.1999969,0.5,0.5,0.9000015,0.6999969,1.4000015 c0.0999985,0.2000046,0.1999969,0.3000031,0.1999969,0.4000015c-0.5,1-1.0999985,2-1.6999969,3 c-0.2000046,0.1999969-0.3000031,0.4000015-0.4000015,0.5999985c-0.5,0.1999969-1.0999985,0.4000015-1.5999985,0.5999985 C89.9198074,58.7262306,89.7198029,56.2262306,90.9198151,53.8262291z"
        />
        <path
          fill={colors[5]}
          d="M91.1198044,61.7262306c-1-2-1.0999985-3.9000015-0.5-5.8000031l1,0.1999969 c0.0999985,0.3000031,0.3000031,0.5999985,0.4000015,0.9000015c0.1999969,0.5,0.5,0.9000015,0.6999969,1.4000015 c0.0999985,0.0999985,0.0999985,0.0999985,0.0999985,0.1999969c-0.4000015,1-0.9000015,1.9000015-1.5,2.8000031 C91.2198029,61.5262337,91.2198029,61.6262321,91.1198044,61.7262306 C91.1198044,61.7262306,91.0198059,61.8262291,91.1198044,61.7262306z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M82.6197968,53.4262276c0.7999954,1.5,1.6999969,3,2.6999969,4.4000015 c-0.2000046-0.2999954-0.2000046-0.5-0.3000031-0.6999969c-0.4000015-1.5-1.6000061-3.0999985-0.8000031-4.5999985 c-0.0999985-0.1000061-0.1999969-0.2000046-0.1999969-0.3000031c0.0999985-2.5999985,0.8000031-4.1999969,2-6.6999969 c1-2,1.1000061-2.2999954,3.3000031-3.8999977c0.0999985-0.1000023,1.0999985-4.7999992,1.0999985-6.2000008 c0-0.0999985,0.0999985-0.0999985,0.1999969-0.0999985c-0.0999985-0.5,0.6999969-1.9000015,0.5-1.4000015 c-0.5999985,1.7999992,2,5.8999977,2.0999985,6.2999992c0,0,1.0999985,3.5000038,3.4000015,5.7000008 c1.8000031,1.7999954,2.0999985,3.5999985,2.3000031,5.5999985c0.5999985,0.1999969,1.1999969,0.5,1.1999969,0.6999969 c0,1.7999954-0.7999954,3.5-1.7999954,5.0999985c1.9000015-1.4000015,3.5999985-3,4-4.9000015 c-0.2000046-0.1000061-0.4000015-0.2000046-0.4000015-0.3000031c-1.1000061-3.0999985-4.1000061-5.5999985-5.2000046-8.6999969 c-0.0999985-0.2000046,0.6000061,0,1.0000076,0.0999985c-0.7000046-2.2999992-2.7000046-7.4000015-2.8000031-7.5 c-1.3999939-1.3999977-3.8999939-5.5-4.6999969-7.0999985c-0.5,0.7999992-2.2999954,5.0999985-2.6999969,5.7999992 c-0.5,0.9000015,1.1000061,1.2000008,1.3000031,2.2000008c0,0.4000015-1.2000046,0.2999992-1.4000015,0.2000008 c-0.0999985,0-1,0.7000008-1.0999985,0.7000008c-2,1.6999969-2.1999969,3.5999985-3.5999985,5.5999985 C82.6198044,46.7262306,82.5198059,50.026226,82.6197968,53.4262276z"
      />
    </g>
  )
}

export default FlameLeft
