import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WornShieldLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WornShieldLeft") ? (
    <g id="WornShieldLeft">
      <path
        id="S_x24_wornShieldLeft"
        d="M65.9000015,103.9000015L65.9000015,103.9000015l-5-3.0999985L56.4000015,98v-0.0999985 h-0.0999985c-1.5-18.2999954,11.1999969-38,11.8000031-38.9000015c0,0,14.1999969,0.5,18.9000015,3 s5.8000031,9.3000031,5.8000031,9.3000031c-0.3000031,1-8.0999985,25.4000015-26.7000046,32.8000031L65.9000015,103.9000015z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WornShieldLeft, {
  character: "ManHalfling"
})
