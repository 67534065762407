import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const NobelCape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("NobelCape") ? (
    <g id="NobelCape">
      <path
        id="S_x24_nobelCape"
        d="M22,120.9000015c-3.2000008,0-10.8000002-4.1999969-12.8999996-6.5 C7.8000002,113,5.2000003,106.7000046,5.2000003,106.7000046C12.7000008,95.1000061,18.6000004,83.5,23.6000004,70.9000092 C21.8999996,70,20.5,69,19.4000015,67.8000031c-3.6000004-3.8999977-4.6000004-8.3999939-2.6000004-12.1999969 c2.1000004-3.9000015,6.6000004-5.4000015,8.3999996-5.9000015c1.3999996-1.2000008,44.7000008-1.5,46.1000023-0.2999992 c1.8000031,0.5,6.3000031,2,8.4000015,5.9000015c2,3.7999992,1.0999985,8.3999977-2.5999985,12.2000008 c-1.0999985,1.2000046-2.5,2.2000046-4.1999969,3c5,12.8000031,10.9000015,24.4000015,18.5,36.0999985 c0,0-2.0999985,5.9000015-3.4000015,7.1999969c-2.0999985,2.3000031-10.1999969,6.9000015-13.4000015,6.9000015 C72.5,120.9000015,24.1000004,120.9000015,22,120.9000015z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(NobelCape, {
  character: "ManHalfling"
})
