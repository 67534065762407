import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const PoleArmR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("PoleArmR") ? (
    <g id="PoleArmR">
      <path d="M35.2000008,19c0-0.1000004,0-0.1000004,0-0.2000008C35,16.5999985,35.5,14.499999,35.8000031,12.3999996 l-0.6000023-0.1000004l6-27.1000004l14,1.5v48.7000046c0,0.7999992-0.0999985,1.5999985-0.4000015,2.4000015l-0.5,78.8000031 l-0.5999985,2.8000031L53,121.1000061l-13.2000008-0.5l-0.5999985-1.6999969l-0.4000015-2.4000015V36l-0.4000015-0.5v0.2000008 V35.5h0.0999985l-0.0999985-0.2000008c0-1.9000015-0.2000008-2.7999992-0.5-4 C37.7999992,30.8999996,37.6999969,30.3999996,37.5999985,30c-0.4000015-1.6000004-0.9000015-3.6000004-2.2000008-6l0,0 l-0.1000023-0.2000008l0,0l0,0l0.4000015-0.2000008c-0.2000008-0.6000004-0.4000015-1.2999992-0.5-2 C35,20.6000004,35.0999985,19.7999992,35.2000008,19z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(PoleArmR, {
  character: "WomanMixed"
})
