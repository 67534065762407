import React from "react"

const TorchL = ({
  colors = [
    "#654219",
    "#282560",
    "#F0D118",
    "#FDD301",
    "#FDD400",
    "#SVGID_",
    "#FFFFFF",
    "#F2B61B",
    "#F39934",
    "#F17B21",
    "#F0672F",
    "#ED4424"
  ]
}) => {
  return (
    <g id="TorchL">
      <path
        fill={colors[0]}
        d="M91.3923798,45.1034203l-5.0370636,20.4267082l0.9695206,0.5540771l1.3019638-0.0276413 l6.2171173-20.0610008C94.8439178,45.9955635,93.891037,45.1423187,91.3923798,45.1034203z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M89.2921982,54.0896263 c-0.110817,0.1939049-0.1163559,0.2936287-0.2271729,0.4875336l-2.7097092,10.9529686l0.8697968,0.5485306 c0.7147903-2.0609436,1.4351196-4.2216148,2.0557327-6.3878288c1.2412262-4.332428,2.4270859-9.4681931,4.2888336-14.1662483 c-0.0997238-0.0055466-0.1939011-0.1108131-0.3933563-0.1219025 C92.0127411,48.3389626,90.4502487,51.253067,89.2921982,54.0896263z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M90.4277267,58.8543053 c-0.9308777,2.3490295-1.6678467,4.8088684-2.5045395,7.2631645l0.6980667,0.0388184l3.5352325-11.4072342 C91.5802307,56.1174698,91.0039825,57.4858894,90.4277267,58.8543053z"
      />
      <path
        opacity="0.84"
        fill={colors[2]}
        d="M99.2103806,30.4331741 c-0.598259-1.8338547-0.6978989-3.639986-1.7061462-5.2965717c0,0-0.4598083-0.7257977-0.5595322-0.7313423 c-0.1052704,0.0941792-0.8088913,0.1550903-0.6869812-0.2382641c0.1496277-0.8919754,1.0637894-1.1412449,0.8145142-2.0554008 c-0.1606293-0.7091618-0.9360733-4.7535877-1.196434-5.4682941c-0.4820786,1.4736862-2.0889282,5.1855869-2.8646317,6.5429153 c0,0-1.3686676,4.8255043-1.8840103,6.8975372c0.2049866-0.0886345,0.6149826-0.2659016,0.5041656-0.0719986 c-0.7591476,2.8587399-2.4878159,5.1634083-3.2469788,8.0221462c-0.0055389,0.09972-0.1108093,0.1939049-0.2160797,0.2880821 c0.1051865,1.7064056,0.9195328,3.2521782,1.8391495,4.7037659c-0.5151749-1.5291367-0.8253555-3.146904-0.7366333-4.7425003 c0.0110855-0.1994476,0.3268967-0.481987,0.6371613-0.6647987c0.2050781-1.889225,0.4987946-3.5734444,1.5847549-5.1135902 c1.3131409-2.0276737,2.1941986-5.2797661,2.1941986-5.2797661c0.0221786-0.3988972,1.7287521-4.1052513,1.424118-5.8227482 c-0.0719986-0.5041676,0.2548218,0.8144302,0.2270966,1.3130531c0.0997162,0.0055447,0.0997162,0.0055447,0.0941772,0.1052685 c-0.0720901,1.2964172,0.28228,5.7175636,0.2767334,5.8172913c1.1135178,1.5624046,1.0968857,1.861578,1.5954285,3.689888 c0.5705338,2.3324814,0.7865295,3.8449821,0.7531738,6.2439117c-0.0055389,0.0997238-0.0110855,0.1994514-0.1163559,0.2936287 c0.3212738,1.4183235-0.3602524,2.8809204-0.7370529,4.2604256c-0.0110931,0.1994514-0.1219025,0.3933563-0.1385422,0.6925278 c0.670433-1.2631493,1.1469574-2.6371117,1.7232132-4.0055275C98.872139,36.5163651,99.0440369,33.4249077,99.2103806,30.4331741z"
      />
      <radialGradient
        id="SVGID_7_"
        cx="111.784"
        cy="660.868"
        r="16.66"
        gradientTransform="rotate(3.183 11392.633 44.62)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fdd301" stopOpacity="0.5" />
        <stop offset="1" stopColor="#fdd400" stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_7_)" cx="94.933" cy="33.596" r="16.68" />
      <g fill="#FFF">
        <path
          opacity="0.18"
          d="M93.6082458,50.2282677l0.1163559-0.2936287 c-1.3407822,0.725708-2.6815567,1.4514198-3.9226151,2.1826782l-0.1219025,0.3933563 C90.9211426,51.7794151,92.2619247,51.0537033,93.6082458,50.2282677z"
        />
        <path
          opacity="0.18"
          d="M93.0098114,51.9955826l0.0055466-0.0997238 c-1.2299652,0.5318069-2.4599304,1.0636139-3.6843491,1.495697l-0.0166321,0.2991714 C90.5443344,53.1589241,91.7743073,52.6271133,93.0098114,51.9955826z"
        />
        <path
          opacity="0.18"
          d="M94.2343979,47.9623299l0.603981-1.8670425 c0,0-0.9473419-0.9529724-3.4515457-0.8921471l-1.4185715,5.7230263 C91.4253998,49.906826,92.8825378,48.8874893,94.2343979,47.9623299z"
        />
      </g>
      <g opacity="0.98">
        <path
          fill={colors[2]}
          d="M93.8854065,47.0426292c-0.9695206-0.5540733-1.8337708-1.2023239-2.5927505-1.9447556 c-0.1717224-0.5097122-0.3489914-0.9197006-0.42099-1.4238701c-0.3323593-1.2188721-0.6647186-2.437748-0.8918076-3.7508011 c0.1219025-0.3933525,0.1440811-0.7922478,0.2604446-1.0858765c0.127449-0.4930763,0.3490829-0.8808861,0.4765244-1.3739624 c0.238266-0.686985,0.476532-1.3739662,0.7147903-2.0609436l0.5041656-0.0719986l-0.2658997-0.6149788l0.0055389-0.0997238 c1.2632294-1.1301537,1.93367-2.3933029,2.2107468-3.7783546c0.127449-0.4930782,0.1551743-0.9916992,0.0831757-1.4958668 l0.304718-0.0830898c0.0775452,0.4044418,0.1606293,0.7091618,0.2437286,1.0138798 c0.2381821,1.1136036,0.6758118,2.2382984,1.019249,3.2577229c0.4320831,1.2244186,0.8641663,2.4488373,1.1965256,3.6677094 c0.6314545,3.0360947,0.3598251,6.1220055-1.130661,9.5402794 C95.0987396,46.8099937,94.4893036,46.9761734,93.8854065,47.0426292z"
        />
        <path
          fill={colors[7]}
          d="M93.8798599,47.1423531c-0.7756195-0.4432564-1.5456848-0.9862404-2.210495-1.6234016 c-0.1772614-0.4099884-0.2548141-0.8144302-0.3323593-1.2188721c-0.3378983-1.1191483-0.6702576-2.3380241-0.9028931-3.5513535 c0.1163635-0.2936249,0.1329956-0.5928001,0.2493515-0.8864288c0.127449-0.4930801,0.3490829-0.8808899,0.4709854-1.2742386 c0.2327194-0.5872574,0.4654388-1.1745148,0.603981-1.8670425l0.8088837-0.1550903l-0.3434448-1.0194244 c1.0582352-1.0415154,1.7231216-2.2049408,2.0888367-3.3850021c0.2603607,0.7147102,0.5207138,1.4294167,0.7810745,2.1441193 c0.4320831,1.2244186,0.8641663,2.4488373,1.1023483,3.562439c0.6369934,2.9363708,0.28228,5.7175674-1.003212,9.0472031 C94.6887512,46.9872627,94.2843018,47.0648079,93.8798599,47.1423531z"
        />
        <path
          fill={colors[8]}
          d="M93.7801361,47.1368103c-0.6758881-0.4377136-1.2520599-0.8698807-1.8282242-1.3020515 c-0.0830917-0.3047142-0.1661758-0.6094322-0.3434525-1.0194244c-0.3434525-1.0194244-0.5816193-2.1330261-0.8142624-3.3463554 c0.0166397-0.2991714,0.127449-0.4930763,0.238266-0.6869812c0.1274414-0.4930763,0.3490753-0.8808861,0.4709778-1.2742386 c0.127449-0.4930763,0.3546295-0.9806099,0.5873489-1.5678635l1.1136017-0.2381783l-0.4265442-1.3241425 c0.5374374-0.6703491,1.0693359-1.2409668,1.4128723-2.0221291c0.0886307,0.2049942,0.1772614,0.4099922,0.2658997,0.6149826 c0.4320831,1.2244186,0.8697128,2.3491096,1.1078949,3.462719c0.5483551,2.7313728,0.2989197,5.4183922-0.870224,8.4543991 C94.2843018,47.0648079,94.0793152,47.1534462,93.7801361,47.1368103z"
        />
        <path
          fill={colors[9]}
          d="M93.7801361,47.1368103c-0.481987-0.3268967-0.963974-0.6537971-1.3462372-0.9751511 c-0.0886307-0.2049942-0.1717224-0.5097122-0.2548218-0.8144302c-0.3434448-1.0194244-0.5871658-2.0333061-0.8253479-3.1469078 c0.0110931-0.1994514,0.1219025-0.3933525,0.127449-0.4930763c0.1219025-0.3933563,0.3490829-0.8808861,0.4709854-1.2742386 s0.3490829-0.8808861,0.4709854-1.2742386l1.4183197-0.32127l-0.5151672-1.5291367 c0.2160797-0.2880821,0.4321671-0.5761642,0.6482468-0.8642502c0.3434525,1.0194244,0.6924438,1.9391251,0.8419876,2.8477325 c0.5594406,2.531929,0.3210983,5.0194931-0.7372284,7.861599C93.9795914,47.1478996,93.8798599,47.1423531,93.7801361,47.1368103 z"
        />
        <path
          fill={colors[10]}
          d="M93.5917816,46.9262695c-0.2880859-0.2160835-0.5761642-0.4321671-0.7700729-0.5429802 c-0.0886383-0.204998-0.0775452-0.4044456-0.1717224-0.5097122c-0.2492752-0.9141579-0.4985352-1.8283119-0.7422638-2.8421898 c0.0055466-0.0997238,0.110817-0.1939049,0.1163559-0.2936287c0.1219025-0.3933525,0.2493515-0.8864326,0.4709854-1.2742386 c0.1163559-0.2936249,0.2382584-0.6869812,0.3601761-1.0803299l1.4183197-0.32127 C94.6557312,42.183857,94.434021,44.3722496,93.5917816,46.9262695z"
        />
        <path
          fill={colors[11]}
          d="M93.6748657,47.2309875c0.0055466-0.0997238-0.0886307-0.2049942-0.0830841-0.304718 c-0.2548141-0.8144302-0.5040817-1.7285843-0.6536331-2.6371956c0.0055466-0.09972,0.0055466-0.09972,0.0110931-0.1994476 c0.1219025-0.3933563,0.2493515-0.8864326,0.4709854-1.2742386c0.1163559-0.2936287,0.2271652-0.4875336,0.2438049-0.786705 l0.609436-0.1661797C94.4783783,43.5744514,94.2788467,45.3639488,93.6748657,47.2309875L93.6748657,47.2309875z"
        />
      </g>
    </g>
  )
}

export default TorchL
