import React from "react"

const LongBowL = ({ colors = ["#5E4E2D", "#FFFFFF"] }) => {
  return (
    <g id="LongBowL">
      <path
        fill={colors[0]}
        d="M84.3,12c0.3-0.7,2-3.7,2.4-2.5c-1.6,1.8-0.7,3.2,0.2,4.7c0.8,1.4,9.4,11.8,10.8,39.7c0.1,0,0.1,0,0.2,0 c0,1.4,0,2.7-0.1,4c0,1.3,0.1,2.6,0.1,4c-0.1,0-0.1,0-0.2,0c-1.4,28-10,38.3-10.8,39.7c-0.9,1.5-1.8,2.9-0.2,4.7 c-0.5,1.2-2.1-1.8-2.4-2.5c9.3-12.7,10.8-36.5,11.1-46C95.2,48.5,93.6,24.7,84.3,12z"
      />
      <path
        fill="#21376C"
        d="M85.2,13c0-0.6,0.4-0.9,0.7-1.4c0-0.1,0.1,0.3,0.1,0.3c0,0.2,0,0.5,0,0.6 c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0.2-0.3,0.4-0.6,0.5-0.9c0-0.1,0.1,0.3,0.1,0.3c0,0.1,0,0.2,0,0.3 c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1,0.3,0.1,0.3c0,0.1,0,0.2,0,0.4c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0,0.4,0,0.5c0,0.1,0,0.4,0,0.5 c-0.2,0.3-0.4,0.6-0.6,0.9c0,0.1,0,0.4-0.1,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0-0.2,0-0.4c0,0.1,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1 c0,0,0-0.4,0-0.4c0-0.2,0-0.4,0-0.5c0,0,0,0,0,0c-0.1,0.2-0.2,0.3-0.3,0.5c0,0.1-0.1-0.3-0.1-0.3c0-0.1,0-0.3,0-0.4 c0,0.1-0.1,0.2-0.1,0.2c0,0,0-0.2-0.1-0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.3-0.1,0.2C85.2,13.6,85.2,13.1,85.2,13z"
      />
      <path
        fill="#21376C"
        d="M85.1,103.2c0,0.6,0.3,0.9,0.6,1.4c0,0.1,0-0.3,0-0.3c0-0.2,0-0.5,0-0.6 c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0.1,0.3,0.3,0.6,0.4,0.9c0,0.1,0-0.3,0-0.3c0-0.1,0-0.2,0-0.3 c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0-0.3,0-0.3c0-0.1,0-0.2,0-0.4c0.1,0.2,0.2,0.3,0.3,0.5c0,0,0-0.4,0-0.5c0-0.1,0-0.4,0-0.5 c-0.2-0.3-0.3-0.6-0.5-0.9c0-0.1,0-0.4,0-0.4c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0.2,0,0.4c0-0.1,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1 c0,0,0,0.4,0,0.4c0,0.2,0,0.4,0,0.5c0,0,0,0,0,0c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0,0.3,0,0.3c0,0.1,0,0.3,0,0.4 c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0.2,0,0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.3-0.1-0.2C85,102.6,85.1,103,85.1,103.2z"
      />
      <g opacity="0.8" fill="#FFF">
        <path
          opacity="0.23"
          d="M86.7,13.7c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5-0.9-1.1-1.8-1.7-2.7c0.3-0.7,2-3.7,2.4-2.5 C85.3,11.1,85.9,12.4,86.7,13.7z"
        />
        <path
          opacity="0.23"
          d="M88.2,18.5c-0.1-0.2-0.3-0.4-0.4-0.6c-0.5-1-1-2-1.5-2.9c0.2-0.3,0.4-0.7,0.6-1 c0,0.1,0.1,0.1,0.1,0.2c0.3,0.4,1.3,1.8,2.7,4.4c-0.3,0.4-0.6,0.8-0.8,1.2C88.6,19.3,88.4,18.9,88.2,18.5z"
        />
        <path
          opacity="0.23"
          d="M89,20.1c0.3-0.4,0.5-0.9,0.8-1.3c0.4,0.8,0.8,1.6,1.3,2.6c-0.4,0.3-0.7,0.7-1.1,1 C89.6,21.6,89.3,20.8,89,20.1z"
        />
        <path
          opacity="0.23"
          d="M93.1,34.7C93.1,34.7,93.2,34.7,93.1,34.7c0.7-0.6,1.3-1,1.8-1.5 c0.2,0.8,0.4,1.6,0.6,2.5c-0.6,0.4-1.3,0.8-1.9,1.2C93.4,36.2,93.3,35.5,93.1,34.7z"
        />
        <path
          opacity="0.23"
          d="M94.3,41c-0.1-0.8-0.3-1.7-0.4-2.5c0.6-0.4,1.3-0.7,1.7-1c0.1,0,0.1-0.1,0.2-0.1 c0.2,0.9,0.3,1.9,0.5,2.8c-0.3,0.1-0.7,0.2-1,0.4C95.1,40.7,94.8,40.8,94.3,41z"
        />
        <path
          opacity="0.23"
          d="M91.7,28.8c-0.3-1.3-0.6-2.6-1-3.9c0.5-0.2,1-0.4,1.5-0.6c0.2,0.6,0.4,1.1,0.6,1.8 c-0.3,0.8-0.6,1.5-0.9,2.3C91.9,28.4,91.8,28.6,91.7,28.8z"
        />
        <path
          opacity="0.23"
          d="M92.5,32.1c0.3-0.5,0.7-1,1-1.5c0.1-0.2,0-0.7,0.1-0.9c-0.4,0.6-0.8,1.2-1.2,1.8 c-0.1-0.4-0.2-0.7-0.3-1.1c0.4-0.9,0.8-1.8,1.3-2.7c0,0,0-0.1,0-0.1c0.5,1.6,1,3.4,1.5,5.4c-0.6,0.5-1.2,1-1.8,1.5 C92.9,33.7,92.7,32.9,92.5,32.1z"
        />
        <path
          opacity="0.23"
          d="M95,45.1c0.1-0.2,0.2-0.3,0.4-0.5c0.4-0.7,0.9-1.3,1.4-1.9c0.1,0.6,0.2,1.2,0.2,1.8 c-0.6,0.7-1.2,1.5-1.8,2.2C95.1,46.1,95,45.6,95,45.1z"
        />
        <path
          opacity="0.23"
          d="M94.4,41.3c0.2-0.1,0.4-0.2,0.6-0.2c0.5-0.2,0.9-0.4,1.4-0.6c0.1,0.5,0.1,1,0.2,1.4 c-0.6,0.7-1.2,1.4-1.8,2.1C94.7,43.1,94.5,42.2,94.4,41.3z"
        />
        <path
          opacity="0.23"
          d="M95.2,81.6c-0.1,0.5-0.2,1-0.3,1.5c-0.5,0.6-1,1.2-1.5,1.8C94,83.8,94.6,82.7,95.2,81.6z"
        />
        <path
          opacity="0.23"
          d="M91.8,89.1c0.5-0.6,1-1.3,1.4-2c-0.1,0.2-0.6,0.4-1,0.6c0-0.1,0.1-0.3,0.1-0.4 c0.2-0.5,0.5-0.9,0.6-1.4c0,0-0.1,0.1-0.2,0.2c0.1-0.2,0.1-0.4,0.2-0.5c0.6-0.7,1.2-1.4,1.8-2.1c-0.4,1.6-0.8,3.1-1.3,4.5 c-0.6,0.9-1.3,1.7-2,2.5c-0.1,0.2-0.9,1.3-0.3,0.6c0.7-0.9,1.5-1.8,2.2-2.7c-1.2,3.8-2.5,6.7-3.7,8.8c-1,0.9-2,1.9-3,2.9 c0.6-1,1.1-2.1,1.6-3.2C89.7,94.4,90.8,91.8,91.8,89.1z"
        />
        <path
          opacity="0.23"
          d="M95.1,76.3c0.4-0.5,0.9-1,1.3-1.6c0,0,0-0.1,0.1-0.1c-0.3,2-0.7,3.9-1,5.7 c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.8-1,1.7-1.5,2.5C94.3,80.9,94.9,78.6,95.1,76.3z"
        />
        <path
          opacity="0.23"
          d="M87,101.7c-0.6,1-1.2,1.9-1.1,3c-0.2,0.3-0.4,0.6-0.6,0.9c-0.4-0.6-0.8-1.3-0.9-1.6 c0.3-0.4,0.6-0.8,0.8-1.2c1.3-1.5,2.5-2.9,3.9-4.3C88,100.2,87.2,101.3,87,101.7z"
        />
        <path
          opacity="0.23"
          d="M95.3,47.7c0.6-0.7,1.2-1.5,1.8-2.2c0.1,0.9,0.2,1.9,0.3,2.8c-0.6,0.6-1.2,1.3-1.8,2 C95.5,49.4,95.4,48.5,95.3,47.7z"
        />
        <path
          opacity="0.23"
          d="M96.1,61.8c0-0.3,0-0.5,0-0.8c0.6-0.7,1.3-1.5,1.9-2.3c0,0.7,0,1.3,0,2 c-0.6,0.7-1.3,1.3-1.9,2C96.1,62.4,96.1,62.1,96.1,61.8z"
        />
        <path
          opacity="0.23"
          d="M96.1,63c0.6-0.7,1.3-1.3,1.9-2c0,0.3,0,0.6,0,0.8h-0.2c0,0.3,0,0.6-0.1,0.9 c-0.6,0.6-1.1,1.2-1.7,1.7C96,64,96.1,63.5,96.1,63z"
        />
        <path
          opacity="0.23"
          d="M96,65.2c0.6-0.6,1.1-1.2,1.7-1.8c-0.1,1.4-0.2,2.7-0.3,4c-0.6,0.8-1.2,1.6-1.8,2.5 C95.7,68.3,95.9,66.8,96,65.2z"
        />
        <path
          opacity="0.23"
          d="M95.2,74.6c0-0.9,0.1-1.8,0.2-2.6c0.6-0.8,1.2-1.5,1.8-2.3c-0.1,1.1-0.3,2.1-0.4,3.1 c-0.5,0.7-1,1.4-1.5,2.1C95.2,74.9,95.2,74.8,95.2,74.6z"
        />
      </g>
      <rect
        x="85.8"
        y="13.8"
        transform="rotate(-180 85.87 58.044)"
        fill="#21376C"
        width="0.1"
        height="88.5"
      />
    </g>
  )
}

export default LongBowL
