import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Curly from "./Curly"
import Spikey from "./Spikey"
import FlavorSaver from "./FlavorSaver"

const CATEGORY = "hair"
const CHARACTER = "ManGnome"
export const IDS = {
  CURLY: "Curly",
  SPIKEY: "Spikey",
  FLAVOR_SAVER: "FlavorSaver"
}

export const components = {
  [IDS.CURLY]: Curly,
  [IDS.SPIKEY]: Spikey,
  [IDS.FLAVOR_SAVER]: FlavorSaver
}

export const Group = ({ props }) => (
  <g id="hair">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.CURLY,
    name: "Curly",
    defaultColors: ["#7C7C7C"],
    colorable: true,
    component: components[IDS.CURLY],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SPIKEY,
    name: "Spikey",
    defaultColors: ["#1387BA"],
    colorable: true,
    component: components[IDS.SPIKEY],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FLAVOR_SAVER,
    name: "Flavor Saver",
    defaultColors: ["#1387BA"],
    colorable: true,
    component: components[IDS.FLAVOR_SAVER],
    inUI: true,
    enabled: false,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
