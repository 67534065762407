import React from "react"

const Skarf = ({ colors = ["#7F0E05", "#262261"] }) => {
  return (
    <g id="Skarf">
      <path
        fill={colors[0]}
        d="M62.9,25.2c0,0-2.3-1-3.9-0.5c-0.2-0.7-1.8-0.8-2.3-0.6c-1.9,1-2,3.5-2,3.5s4.4,11.2,11.5,12.1 c0,0,4.2,0.5,6.9-0.1c2.7-0.5,10.4-12.1,10.4-12.1s0-2.3-1.9-3c-1.9-0.6-2.8-0.4-4.1,0.5c-0.7-0.7-1.8,0.2-1.8,0.2 s-2.9,3.3-6.5,3.1C65.7,28.1,64.2,25.8,62.9,25.2z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M59.1,24.6c0,0-0.2,8,11.9,9.1C66.4,31.8,62.8,30.1,59.1,24.6z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M77.2,25.6c0,0-3.6,7.8-11.9,4.4 C68.3,31.8,74.4,33.7,77.2,25.6z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M58.4,30.3c4.7,2.7,11.3,7.2,18.6,2.4 c-2.3,1.9-6.4,3.9-15.5,1.6c-0.3,1.2,9.5,7.6,18.3-3.1c-1.6,2.1-6.4,10.7-15.6,6.4C60.8,35.9,58.4,30.3,58.4,30.3z"
      />
    </g>
  )
}

export default Skarf
