import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Cape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Cape") ? (
    <g id="Cape">
      <polygon points="26.1680164,81.6769409 21.9109459,121.2600708 31.7990494,121.1559906 34.0965118,83.5753098" />
      <polygon points="66.0338211,82.4586334 72.6858063,121.2600708 62.797699,121.1559906 59.222023,83.5753098" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Cape, { character: "ManGnome" })
