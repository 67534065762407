import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import DragonSkull from "./DragonSkull"

const CATEGORY = "headGear"
const CHARACTER = "WomanGnome"
export const IDS = {
  DRAGON_SKULL: "DragonSkull"
}

export const components = {
  [IDS.DRAGON_SKULL]: DragonSkull
}

export const Group = ({ props }) => (
  <g id="headGear">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.DRAGON_SKULL,
    name: "Dragon Skull",
    defaultColors: [
      "#B5B5AE",
      "#SVGID_",
      "#BF0A00",
      "#E59572",
      "#FFF4BF",
      "#FFF7D2",
      "#FFFBE6",
      "#FFFDF4",
      "#FFFFFD",
      "#FFFFFF",
      "#E7A4A0"
    ],
    colorable: false,
    component: components[IDS.DRAGON_SKULL],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
