import React from "react"

const LeafClasp = ({ colors = ["#FCDF54", "#421115"] }) => {
  return (
    <g id="LeafClasp">
      <path
        fill={colors[0]}
        d="M67.4953842,69.3959427c-0.1569595,0-0.2354507-0.0892715-0.3924103-0.0892715 c-0.5493698-0.2678375-0.6278534-2.1427002-0.706337-2.3212585v-0.0892792l0,0c0,0,0.706337-0.9820633,1.0987473-1.0713501 c0.1569595-0.0892792,0.3139267-0.0892792,0.5493698,0c0.4708939,0.0892792,1.0987473,0.2678375,1.0987473,0.2678375 L69.378952,66.1819l-0.706337,0.6249542c0.078476,0,0.078476,0,0.1569595,0 c0.3139267,0.0892715,0.6278534,0.0892715,0.8632965,0.1785583c0.5493698,0.1785583,1.5696411,0.8035126,1.5696411,0.8927917 l0.2354431,0.1785583l-0.2354431,0.0892715c-0.0784836,0-1.8835678,0.7142334-2.5114212,0.6249542h-0.078476 c-0.3139343,0-0.5493698-0.0892715-0.706337-0.1785583l0.2354431,0.6249542l-0.078476,0.0892792 C68.0447617,69.3066635,67.8093109,69.3959427,67.4953842,69.3959427z"
      />
      <path
        opacity="0.63"
        fill={colors[1]}
        d="M67.4953842,69.3959427 c-0.1569595,0-0.2354507-0.0892715-0.3924103-0.0892715c-0.5493698-0.2678375-0.6278534-2.1427002-0.706337-2.3212585v-0.0892792 l0,0c0,0,0.706337-0.9820633,1.0987473-1.0713501c0.1569595-0.0892792,0.3139267-0.0892792,0.5493698,0 c0.4708939,0.0892792,1.0987473,0.2678375,1.0987473,0.2678375L69.378952,66.1819l-0.706337,0.6249542 c0.078476,0,0.078476,0,0.1569595,0c0.3139267,0.0892715,0.6278534,0.0892715,0.8632965,0.1785583 c0.5493698,0.1785583,1.5696411,0.8035126,1.5696411,0.8927917l0.2354431,0.1785583l-0.2354431,0.0892715 c-0.0784836,0-1.8835678,0.7142334-2.5114212,0.6249542h-0.078476c-0.3139343,0-0.5493698-0.0892715-0.706337-0.1785583 l0.2354431,0.6249542l-0.078476,0.0892792C68.0447617,69.3066635,67.8093109,69.3959427,67.4953842,69.3959427z"
      />
      <path
        fill={colors[0]}
        d="M67.1814651,69.1281052c-0.4708939-0.2678375-0.627861-2.2319794-0.627861-2.2319794 s0.627861-0.8927917,1.0987473-1.0713501c0.3924103-0.1785583,1.5696411,0.2678375,1.5696411,0.2678375l-0.8633041,0.714241 c0,0,0.7848206,0,1.3341904,0.2678299c0.5493698,0.1785583,1.5696411,0.8927917,1.5696411,0.8927917 s-1.8835678,0.7142334-2.4329376,0.6249542c-0.627861,0-1.0987473-0.1785583-1.0987473-0.1785583l0.2354431,0.8035126 C68.0447617,69.1281052,67.6523514,69.3959427,67.1814651,69.1281052z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M67.5738678,69.1281052 c-0.078476,0-0.2354431-0.0892792-0.3139267-0.0892792c-0.3139267-0.1785583-0.4708862-1.3391876-0.5493698-2.0534134 c0.1569595-0.2678375,0.706337-0.8927917,1.0202637-0.9820709c0.0784836,0,0.2354507,0,0.4708939,0 c0.3139267,0,0.6278534,0.0892792,0.7848206,0.1785583l-0.9417801,0.8035126h0.3139267c0,0,0.2354507,0,0.4708862,0.0892792 c0.3139343,0.0892792,0.627861,0.0892792,0.7848206,0.1785583c0.3924103,0.1785583,0.9417801,0.535675,1.3341904,0.7142334 c-0.6278534,0.2678375-1.7266006,0.6249542-2.1190109,0.535675h-0.078476 c-0.4708939-0.0892792-0.9417801-0.2678375-0.9417801-0.2678375l-0.2354507-0.0892792l0.3924103,0.9820709 C67.8877945,69.1281052,67.730835,69.1281052,67.5738678,69.1281052z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.7105637,66.9854126l2.1974945-0.8035202 C68.9865417,66.1818924,67.6523514,66.3604584,66.7105637,66.9854126z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.7105637,66.9854126 c0,0,0.706337,0.9820633,4.2380219,0.9820633C69.7713623,67.7889175,67.1814651,67.4318085,66.7105637,66.9854126z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.7105637,67.0746841 c0,0,0.2354507,1.517746,1.1772308,2.053421C67.1029739,68.2353134,66.7105637,67.0746841,66.7105637,67.0746841z"
      />
      <path
        fill={colors[0]}
        d="M66.7890549,67.1639709l0.1569595,0.3571167l0.0784836-0.0892792 c0,0,0.1569595,0.2678375,0.2354431,0.3571167c0.0784836-0.1785583,0.1569595-0.2678375,0.1569595-0.2678375 S66.8675308,67.1639709,66.7890549,67.1639709C66.7105637,67.0746841,66.7890549,67.1639709,66.7890549,67.1639709z"
      />
      <path
        fill={colors[0]}
        d="M67.024498,67.6103668L67.024498,67.6103668l0.4708862,0.5356674h-0.2354507 C67.2599411,68.1460342,67.1029739,67.8782043,67.024498,67.6103668z"
      />
      <path
        fill={colors[0]}
        d="M67.3384247,68.2353134h0.2354431l0.3139343,0.8035126 C67.8093109,68.9495468,67.4953842,68.5031586,67.3384247,68.2353134z"
      />
      <path
        fill={colors[0]}
        d="M67.3384247,67.7889175c0,0,0.1569595,0.2678375,0.3924103,0.3571167 c0.2354431,0.0892792,0.5493698,0.1785583,0.5493698,0.1785583l-0.0784836-0.6249542c0,0-0.1569595-0.0892715-0.3139267-0.0892715 c-0.0784836,0-0.0784836,0.1785583-0.0784836,0.3571167c-0.078476-0.0892792,0-0.3571167-0.078476-0.3571167 c-0.1569672-0.0892792-0.3139343-0.0892792-0.3139343-0.0892792C67.4169006,67.52108,67.3384247,67.7889175,67.3384247,67.7889175 z"
      />
      <path
        fill={colors[0]}
        d="M68.2802048,67.7889175l0.078476,0.6249542c0,0,0.3924103,0.0892792,0.5493774,0.0892792 c0.1569595,0,0.1569595,0,0.1569595,0s-0.2354507-0.3571167-0.3139343-0.6249542 C68.5156479,67.7889175,68.2802048,67.7889175,68.2802048,67.7889175z"
      />
      <path
        fill={colors[0]}
        d="M68.8295822,67.8782043c0,0,0.1569595,0.4463882,0.3139267,0.5356674 c0.1569595,0,0.8632965-0.1785583,0.8632965-0.1785583l-0.3139267-0.2678375 C69.6143951,67.9674759,68.9080582,67.8782043,68.8295822,67.8782043z"
      />
      <path
        fill={colors[0]}
        d="M66.8675308,66.8068542c0,0,0.627861-0.3571243,0.8633041-0.3571243c0-0.1785583,0-0.3571167,0-0.3571167 S67.2599411,66.3604584,66.8675308,66.8068542z"
      />
      <path
        fill={colors[0]}
        d="M67.8093109,66.0926208v0.3571167l0.5493698-0.0892792v-0.0892792L68.4371643,66.1819 C68.4371719,66.0926208,68.1232452,66.003334,67.8093109,66.0926208z"
      />
      <polygon
        fill={colors[0]}
        points="69.8498383,67.9674759 70.085289,68.2353134 70.6346588,67.9674759"
      />
      <polygon
        fill={colors[0]}
        points="68.5156479,66.1818924 68.4371719,66.2711792 68.7510986,66.1818924"
      />
    </g>
  )
}

export default LeafClasp
