import React from "react"

const BracesL = ({ colors = ["#754C28", "#282560", "#262261", "#FFFFFF"] }) => {
  return (
    <g id="BracesL">
      <path
        fill={colors[0]}
        d="M83.7784042,50.9551506c-0.2833176-0.796833,1.5139847-4.630497,4.8518372-2.5852852 c1.5582581,2.4436264,2.7327805,5.277092,2.7505569,5.583744s0.4064407,1.2726364,0.316925,1.1460533 c-0.0675583-0.0955238-0.3180771-0.361515-0.3641434-0.4377556c-0.357338-0.4706459-0.5469208-1.473259-2.0846176-1.0955009 c-1.5377045,0.3777618-2.3246384,1.3325043-2.2123871,1.9454575c0.0442657,0.097393,0.3483658,0.3318214,0.3069305,0.377758 c-0.0138168,0.015316-0.2917862,0.0164528-0.3069305,0.0472221 C84.8408508,52.5724297,84.0617218,51.7519836,83.7784042,50.9551506z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M88.3089447,49.5296402 c0.1870193,0.0209541,0.4315338,0.0508232,0.8288956,0.1033173c-0.1711197-0.2113609-0.547905-0.2775536-0.9242096-0.2880325 l-0.3167725-0.6138802c0.1832047,0.0208435,0.420517,0.0500717,0.7924194,0.0991783 c-0.165802-0.2048073-0.5249176-0.273468-0.8894501-0.2872772l-0.314682-0.6098557l-0.4249802,0.0235863 c0.0440063,0.0880051,0.1684875,0.3203735,0.3151093,0.5915337c-0.3453522,0.0211143-0.621994,0.0695229-0.621994,0.0695229 c0.3479309,0.0376091,0.5273285,0.0528145,0.6962967,0.0678558c0.1198654,0.2212486,0.2475204,0.455658,0.3622284,0.6660004 c-0.3397141,0.0215454-0.6099625,0.0688782-0.6099625,0.0688782c0.3395004,0.0366936,0.5184631,0.0520058,0.6839447,0.0667267 c0.1485519,0.2721291,0.2604675,0.4763451,0.2604675,0.4763451l1.7470932,3.0456657l0.2597122,0.0944519L88.3089447,49.5296402z"
      />
      <path
        opacity="0.2"
        fill={colors[2]}
        d="M88.2553101,53.3435249 c-0.0199356,0.0307846-0.0407791,0.0520592-0.062973,0.0505562c-0.553009-0.038147-1.0901184-0.0787621-1.5752182,0.2398376 c-0.0406723,0.0266457-0.1221161-0.7364883,0.0597992-0.8480263c0.0125198-0.0076828,0.0254669-0.0109062,0.0380936-0.0172997 c0.0072556-0.067749,0.0140228-0.1354446,0.0205231-0.2028198c-0.0414734,0.048893-0.0834885,0.0973549-0.1242142,0.1468887 c-0.059906,0.0728531-0.0670547-0.2851791-0.0644226-0.319458c0.0119324-0.1566162,0.0254669-0.4042435,0.1358261-0.5276527 c0.1810608-0.2024956,0.370285-0.397419,0.5514526-0.5998077c0.010849-0.0121422,0.017189,0.0012894,0.0239029,0.0121956 c0.0006485-0.0005341,0.0010757-0.0011253,0.0017242-0.0016632c0.0469055-0.0373421,0.0997162-0.0631294,0.1496277-0.0948296 c-0.1080475-0.1331329-0.2170563-0.2663231-0.3235397-0.3985977c-0.0044098,0.0048866-0.0065079,0.0133781-0.0112839,0.0176201 c-0.5642929,0.5019188-1.1839294,1.1154251-1.3089523,1.8905411c-0.0741959,0.4597435-0.1812744-0.0344925-0.1703644-0.2281227 c0.0330429-0.5863228,0.2540741-1.0669098,0.5705795-1.4950562c-0.1882629,0.1949196-0.3570709,0.4022522-0.4975662,0.6406364 c-0.0916595,0.1554871-0.089241-0.1193275-0.0881653-0.1755791c0.0036545-0.1916428,0.0439453-0.4169731,0.1331329-0.5880394 c0.1384583-0.265625,0.3418655-0.466835,0.546402-0.6721764c-0.0667801-0.0065575-0.1328125,0.0062294-0.1998062,0.0413132 c-0.0324554,0.035675-0.055069,0.0450783-0.0695267,0.0310555c-0.1279755,0.0891342-0.2388153,0.2267799-0.290123,0.3368683 c-0.0162811,0.0231552-0.035141,0.0254669-0.0565186,0.0069847c-0.6531601-0.2793808-0.8350754-0.8989067-0.8262177-1.5732841 c-0.3638382,0.4212723-0.8057938,1.0961342-0.8265839,1.9277191c0.8145523,1.4520226,2.7977753,4.1435242,2.7977753,4.1435242 s0.1362991-1.4287567,1.6540298-1.7218399C88.3801651,53.3582458,88.3181686,53.3479309,88.2553101,53.3435249z"
      />
      <g fill="#FFF">
        <path
          opacity="0.19"
          d="M87.8157043,49.7274361 c0.0211258,0.1056442,1.4982529,2.6883812,1.8084335,3.2299614c0.0364151,0.0635872,0.0567474,0.0990372,0.0567474,0.0990372 l-1.7707443-3.4470482L87.8157043,49.7274361z"
        />
        <path
          opacity="0.19"
          d="M86.9185257,48.0747414 c0,0.0138435,0.2124863,0.4013672,0.2124863,0.4013672l0.1416626-0.0236092L86.9185257,48.0747414z"
        />
        <polygon
          opacity="0.19"
          points="87.2726746,48.8066483 87.6032104,49.3024559 87.7212601,49.2552376 87.3907242,48.7594299"
        />
      </g>
    </g>
  )
}

export default BracesL
