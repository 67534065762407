import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import UI from './ui'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
})

const Controls = ({}) => {
  return (
    <div>
      <UI />
    </div>
  )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Controls))