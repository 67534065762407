import React from "react"
import { withSkinColor } from "../../builderConnector"

const RightThumb = ({ skinColor, colors = ["#E7B080"] }) => {
  return (
    <g id="RightThumb">
      <path
        fill={skinColor}
        d="M54.0064888,82.157341c-0.0804634-0.194458,0.7644157-1.8506851,0.6034851-1.9713745 c-0.1609268-0.120697-1.8909149-0.5230255-2.0116119-0.5632553s-1.5690575,0.9253387-1.5690575,0.9253387l1.9311485,0.4827881 L54.0064888,82.157341z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M54.0064888,82.157341c-0.0804634-0.194458,0.7644157-1.8506851,0.6034851-1.9713745 c-0.1609268-0.120697-1.8909149-0.5230255-2.0116119-0.5632553s-1.5690575,0.9253387-1.5690575,0.9253387l1.9311485,0.4827881 L54.0064888,82.157341z"
      />
    </g>
  )
}

export default withSkinColor(RightThumb, { character: "WomanGnome" })
