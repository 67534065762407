import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WornShieldRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WornShieldRight") ? (
    <g id="WornShieldRight">
      <path
        id="S_x24_wornShieldRight"
        d="M30.8999996,103.6999969C12.3000002,96.3000031,4.5,71.9000015,4.1999998,70.9000015 c0,0,1.9000001-5.3000031,5.8000002-9.2999992s18.8999996-3,18.8999996-3 c0.5,0.7999992,13.3000011,20.5000038,11.8000011,38.8999977h-0.0999985v0.0999985l-9.5,6H31L30.8999996,103.6999969z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WornShieldRight, {
  character: "ManHalfling"
})
