import React from "react"

const BattleAxeLeft = ({ colors = ["#7A7C7D", "#505052"] }) => {
  return (
    <g id="BattleAxeLeft">
      <path
        fill={colors[0]}
        d="M70.6251602,63.4668503c-0.1404724,0.3159409-0.4734192,0.624588-0.7990799,0.7407608 c-1.3716583,0.5905685-2.4745255,0.8700638-3.9501953,0.8141785c-0.1997681,0.1851883-0.6585999,0.4248199-0.9249649,0.6717377 c0.0048523-0.1283264,0.1564865-2.4356422,0.1613464-2.5639648c0,0,0.5157089-0.0447197,0.5181427-0.1088791 c0,0-0.9453888-0.4855652-0.9643555-3.3776169c-0.4301453,2.875042-1.3453941,3.2901421-1.3453941,3.2901421 c-0.0048599,0.1283188,0.508419,0.1477585,0.508419,0.1477585c-0.0048599,0.1283188-0.1613464,2.563961-0.1613464,2.563961 s-0.5628624-0.4068298-0.8715096-0.739769c-1.4732513-0.1200485-2.5518188-0.4821548-3.8082886-1.2365112 c-0.3159332-0.1404724-0.6245804-0.4734192-0.6765938-0.7966499c0,0-6.0805473,7.8654518,0.7559204,13.9712715 c-0.7694588-3.4344788-0.7821274-8.1895981,3.4004059-8.3524551c0.064167,0.0024338,1.0289917-0.0252838,1.0289917-0.0252838 l-0.2400932,2.9464874c-0.0690231,0.1258926-0.2639275,0.1827621-0.4661255,0.4321136l0.5774384,0.0218735l-0.0340195,0.8982315 l-0.6416016-0.024292c-0.00243,0.0641556-0.0461617,1.2190323-0.0485916,1.2831879 c0.0641556,0.0024338,0.6415939,0.0242996,0.7057571,0.0267334c-0.6687355,12.5680237-1.7525177,32.7020493-2.4212418,45.2700806 l-0.064167-0.0024261c-0.0364418,0.9623947,1.3181953,0.8209381,1.4115219,0.0534515l-0.064167-0.0024338 c0.6687355-12.5680237,1.7525177-32.7020493,2.4212456-45.2700729c0.0641632,0.0024261,0.6416016,0.0242996,0.7057648,0.0267258 c0.0024261-0.0641632,0.0461578-1.2190399,0.0485916-1.2831955l-0.6415939-0.0242996l0.0340118-0.8982391l0.577446,0.0218658 c-0.0593109-0.1307449-0.3086548-0.3329468-0.432106-0.4661255l0.1093369-2.8871918c0,0,0.895813,0.098175,1.0241318,0.103035 c4.1533737,0.6070633,3.6527557,5.3427429,2.499855,8.6401749 C75.8318787,71.8882751,70.6251602,63.4668503,70.6251602,63.4668503z"
      />
      <path
        fill={colors[1]}
        d="M62.8628006,64.8434219c-1.4090805-0.1176147-2.4259186-0.4131317-3.6206551-1.1008987 c-1.4324036,2.1945648-3.9341125,7.1757126-0.4227982,11.1638031c-0.3349152-3.0325165,0.2405586-6.3518143,3.7148972-6.4772491 c0.064167,0.0024338,0.7699203,0.0291595,0.9623985,0.0364532l0.2352371-2.8181686 C63.4825287,65.4451599,63.0479927,65.0431976,62.8628006,64.8434219z"
      />
      <path
        fill={colors[1]}
        d="M65.9400482,65.0242157c1.4115067,0.0534592,2.4477921-0.164299,3.6886902-0.6955719 c1.1981506,2.2941895,3.1210403,7.5071564-0.7435989,11.1516571c0.6916428-2.9936447,0.4330444-6.3445358-3.0170059-6.8607025 c-0.0641632-0.0024338-0.8340836-0.0315933-1.0265579-0.0388794l0.1093445-2.8871918 C65.148262,65.5724945,65.6761169,65.2069778,65.9400482,65.0242157z"
      />
      <rect
        x="63.604"
        y="72.509"
        transform="rotate(-86.964 63.861 73.825)"
        fill={colors[1]}
        width="0.514"
        height="2.632"
      />
      <rect
        x="63.869"
        y="70.65"
        transform="rotate(-86.964 63.965 71.934)"
        fill={colors[1]}
        width="0.193"
        height="2.568"
      />
      <path
        fill={colors[1]}
        d="M63.8315086,63.0168076c0,0-0.3208046-0.0121498-0.508419-0.1477585 c0.3256569-0.1161728,1.4163666-0.0748634,2.3097458,0.0874786c-0.0665894,0.0617294-0.5181351,0.1088791-0.5181351,0.1088791 S64.5445557,62.8510551,63.8315086,63.0168076z"
      />
    </g>
  )
}

export default BattleAxeLeft
