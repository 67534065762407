import React from "react"

const ArmPlateL = ({ colors = ["#754C28", "#FFFFFF", "#282560"] }) => {
  return (
    <g id="ArmPlateL">
      <path
        fill={colors[0]}
        d="M78.7140656,40.2480278l-0.3895798-1.0270424l1.6485596-0.0992889 c-0.0493774-0.220871-0.0991821-0.4415779-0.1256714-0.6798058c-0.2124863-2.4081993,2.8685989-3.0811005,3.1519012-3.1165047 c0.1273346-0.0159035-0.0559387,1.3483009,0.9514389,3.5566902l0.1540375-0.009243 c0.1746063,0.2570839,0.5236206,1.1273003,0.5236206,1.1273003l-0.1672516,0.0069847 c0.2073822,0.4596329,0.4113846,0.9141083,0.6059799,1.3494568l0.0924683-0.0106926l0.495842,1.2041283l-0.0537796,0.0066071 c0.5319519,1.1983261,0.9037399,2.0474777,0.9037399,2.0474777s-2.0677338-0.3682442-3.9064789-1.6793938l-2.4328079,0.2981834 l-0.5311966-1.2395325l1.6993256-0.1960487c-0.4070358-0.4631271-0.7679138-0.9894905-1.0326309-1.6058426L78.7140656,40.2480278z"
      />
      <path
        opacity="0.19"
        fill="#FFF"
        d="M83.0346909,38.0169144 c0,0,3.0810928,6.3392639,2.9748459,6.1621895c-0.1062393-0.1770744-1.7353287-0.7082977-2.3019638-1.2749367 c-0.5666428-0.5666351,0.9561996,0.0708313,0.9561996,0.0708313l-1.0270309-0.779129l0.8853683,0.1770744 C84.52211,42.3729439,82.7159576,41.558403,83.0346909,38.0169144z"
      />
      <path
        opacity="0.3"
        fill={colors[2]}
        d="M86.1275864,44.2263222 c0,0-2.1484985-0.6138573-3.8956375-2.1012802c-1.7471313-1.4874268-1.7117157-5.1705742-1.7117157-5.1705742 s-0.4249725,0.2951241-0.4249725,0.3895645s-0.2951279,3.3408051,1.6408844,4.8046188s3.9428558,2.266552,4.4150543,2.2901611 S86.1275864,44.2263222,86.1275864,44.2263222z"
      />
    </g>
  )
}

export default ArmPlateL
