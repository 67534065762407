import React from "react"
import { withSkinColor } from "../../builderConnector"

const BodyBase = ({ skinColor, color = [] }) => {
  return (
    <g id="BodyBase">
      <path
        fill={skinColor}
        d="M90.1577377,118.3964005l-5.4668121-8.2881775l-0.3125305-12.664444 c0.7717285-4.3562851-2.1720734-9.9633789-2.1720734-9.9633789l-0.566658-3.3962936 c2.2439041-11.5461197-0.5750351-20.2097931-0.5750351-20.2097931s-0.0928955-0.7022095-0.982933-3.4550667 c-0.5187378-0.9621429-0.521637-1.8151627-0.8384094-3.7338028c0,0-1.8119202-3.8104668-3.4453964-5.2415962 c-0.9312515-2.9948959,0.0778503-7.2618828,0.2046967-7.5606041c0.735466-0.7561531,1.1754913-1.7607918,1.2209473-2.8319969 c0.9686966-2.2115059,2.1119995-4.725174,2.3346481-4.7953949c0.4657516-1.257637,1.2183075-3.0710793,1.2183075-3.0710793 l-2.0870743-1.4903851l-7.0424652-2.8880882v-5.3340569h-6.2106094v5.3340569l-7.0424118,2.8880882l-2.3251381,1.1770496 l0.9486008,3.2564392l3.0823097,4.5512009c-0.0253563,0.4709702,0.0188065,0.9517212,0.1630096,1.4299431 c0.1792336,0.5942192,0.4783821,1.1173553,0.8575325,1.5604973c0,0,0.951828,5.2443314,0.1652107,7.7743301 c-1.770031,2.1426277-3.262188,4.4733543-3.3955917,5.2810326c-0.3167725,1.9186401-0.3196754,2.7716599-0.8384094,3.7338028 c-0.8900375,2.7528572-0.982933,3.4550667-0.982933,3.4550667s-2.8189392,8.6637268-0.5750389,20.2097931l-0.5666542,3.3962936 c0,0-2.9438591,5.6070938-2.1721268,9.9633789l-0.3124733,12.664444l-5.4668159,8.2881775h8.8655243l-0.0944519-9.5584946 l3.7856483-10.6939087l0.8730621-7.7517624l1.1324005-3.7582474l0.5909958-3.880806c0,0,0.8708038-2.9638977,1.8644829-6.0691986 c2.8804092-7.4607315,3.0580292-11.2912445,3.0580292-11.2912445l1.4905396,0.2839508l1.4905472-0.2839508l0.052063,0.0049438 c0.0232086,0.4481354,0.3212891,4.1639404,3.0558243,11.2468643c0.9936752,3.1053085,1.8644791,6.0691986,1.8644791,6.0691986 l0.5909424,3.880806l1.1324539,3.7582474l0.8730621,7.7517624l3.7856522,10.6939087l-0.0944519,9.5584946H90.1577377z"
      />
      <ellipse
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#262261"
        cx="68.48"
        cy="52.674"
        rx="0.161"
        ry="0.322"
      />
      <g id="K_x24_D_x24_arms">
        <path
          fill={skinColor}
          d="M91.6396408,55.0530548c0.4401321,1.1443405,0.6610794,4.6908875-2.9040146,2.394825 c-5.7608261-5.9496994-6.1988373-10.4358253-6.1988373-10.4358253l-4.331192-8.404892 c0,0-1.5542679-7.0958633,0.6463852-6.7437592c3.3103256,0.5898476,4.0942383,4.2579994,4.3398819,5.7319145 c1.1399536,2.9478607,2.9466705,7.2448654,3.3716507,8.0475998 C87.1773682,45.6901398,91.5516205,55.0530548,91.6396408,55.0530548z"
        />
        <path
          fill={skinColor}
          d="M45.4935036,55.0530548c-0.4401283,1.1443405-0.6610756,4.6908875,2.9040222,2.394825 c5.7608185-5.9496994,6.1988297-10.4358253,6.1988297-10.4358253l4.331192-8.404892 c0,0,1.5542679-7.0958633-0.6463852-6.7437592c-3.3103256,0.5898476-4.0942383,4.2579994-4.3398743,5.7319145 c-1.0106583,2.6645393-2.9466743,7.2448654-3.3716507,8.0475998 C49.95578,45.6901398,45.5815277,55.0530548,45.4935036,55.0530548z"
        />
        <path
          opacity="0.2"
          fill="#262261"
          d="M82.1248474,45.3765144 c0.4958038,0.6610794,2.4082108,1.5346451,2.4082108,1.5346451s0.7319031-0.3305359,0.9443893-0.3305359 c-0.0236053-0.3305397-0.0221024-0.6512794-0.2124863-1.2277145 C85.2649612,45.3529091,83.3761749,46.2973022,82.1248474,45.3765144z"
        />
        <path
          opacity="0.2"
          fill="#262261"
          d="M55.1971817,45.3765144 c-0.4958076,0.6610794-2.4082146,1.5346451-2.4082146,1.5346451s-0.7318993-0.3305359-0.9443932-0.3305359 c0.0236092-0.3305397,0.02211-0.6512794,0.2124901-1.2277145 C52.0570641,45.3529091,53.9458542,46.2973022,55.1971817,45.3765144z"
        />
      </g>
    </g>
  )
}

export default withSkinColor(BodyBase, { character: "WomanMixed" })
