import React from "react"

const PlateHelmGold = ({
  colors = ["#A59A76", "#8A784E", "#E6BB21", "#EED793"]
}) => {
  return (
    <g id="PlateHelmGold">
      <path
        opacity="0.44"
        fill="#21376C"
        d="M54.1816254,53.8058014c0,0,0-0.0826988-0.0762939-0.1653976 v-0.1653976h-0.0763016c-0.3814888-1.1577759-1.8311462-5.0445938-5.1882477-6.036972l0,0l-1.4496574-1.5712662 l-1.3733597,1.5712662c-3.433403,0.9096794-4.8830605,4.8792-5.1882515,6.036972h-0.0762939v0.1653976 c0,0.082695-0.0762978,0.1653976-0.0762978,0.1653976h0.0762978v0.082695v1.653965l5.5697365,0.4134941l0.1525955,3.3906288 h1.907444l0.1525955-3.3906288l5.5697403-0.4134941L54.1816254,53.8058014L54.1816254,53.8058014L54.1816254,53.8058014z"
      />
      <path
        fill={colors[0]}
        d="M40.7532158,53.0615196h13.5047073c0,0-1.602253-6.533165-6.7905006-6.533165 S40.7532158,53.0615196,40.7532158,53.0615196z"
      />
      <rect
        x="40.753"
        y="52.731"
        fill={colors[1]}
        width="13.352"
        height="2.481"
      />
      <rect
        x="40.753"
        y="53.144"
        fill={colors[2]}
        width="13.352"
        height="1.654"
      />
      <polygon
        fill={colors[0]}
        points="46.017765,46.6937523 46.4755478,59.0984917 48.4592934,59.0984917 48.9170799,46.6937523 47.4674225,45.1224823"
      />
      <polygon
        fill={colors[1]}
        points="47.3911247,45.2051811 46.2466545,46.5283546 46.7044449,58.7676964 47.3911247,58.7676964"
      />
      <polygon
        fill={colors[3]}
        points="47.4674225,45.2051811 48.6881866,46.5283546 48.1541023,58.7676964 47.4674225,58.7676964"
      />
    </g>
  )
}

export default PlateHelmGold
