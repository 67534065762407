import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BlueFlameRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BlueFlameRight") ? (
    <g id="BlueFlameRight">
      <path d="M50.7256889,46.5939331c0,0-10.0533447,18.9896545-9.4948273,29.8109665 c0.1396294,4.1888962-0.4188881,12.07798,11.1703873,12.1477966 C73.6991653,83.9318237,50.7256889,46.5939331,50.7256889,46.5939331z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BlueFlameRight, {
  character: "WomanGnome"
})
