import React from "react"

const Pouch = ({ colors = ["#4D391B", "#7D5B3C", "#362812", "#A76F3A"] }) => {
  return (
    <g id="Pouch">
      <polygon
        fill={colors[0]}
        points="79.1398239,53.0033035 74.5398178,55.1033058 74.0398178,55.0033035 72.7398224,49.5033035 77.9398193,48.3033028 78.4398193,48.9033051"
      />
      <path
        fill={colors[1]}
        d="M73.1398239,50.2033043l0.8000031-0.0999985c0,0,1.3000031,4.0999985,1,4.7000008 c-0.1999969,0.5999985-0.8000031,0.2000008-0.8000031,0.2000008L73.1398239,50.2033043z"
      />
      <path
        fill={colors[1]}
        d="M74.1398239,50.1033058l4.0999985-1.0999985l0.9000015,4l-4.1999969,1.7000008 C74.9398193,54.7033043,75.1398239,53.7033043,74.1398239,50.1033058z"
      />
      <path
        fill={colors[0]}
        d="M72.7398224,49.7033043c0,0,1.6999969,0,2.4000015,1.5999985c0.6999969,1.5,1.9000015,1.5,1.9000015,1.5 s1.1999969-1.5999985,1-2.4000015c-0.1999969-0.9000015-0.1999969-1.7999992-0.1999969-1.7999992L72.7398224,49.7033043z"
      />
      <path
        fill={colors[1]}
        d="M72.9398193,49.5033035c0,0,1.6999969,0,2.4000015,1.5999985c0.6999969,1.5,2,1.2999992,2,1.2999992 s0.8000031-1.5,0.5999985-2.4000015c-0.1999969-0.9000015,0.0999985-1.7000008,0.0999985-1.7000008L72.9398193,49.5033035z"
      />
      <ellipse
        transform="rotate(-11.958 76.942 51.804)"
        fill={colors[2]}
        cx="76.942"
        cy="51.804"
        rx="0.2"
        ry="0.3"
      />
      <ellipse
        transform="rotate(-11.958 77.041 51.702)"
        fill={colors[3]}
        cx="77.041"
        cy="51.702"
        rx="0.2"
        ry="0.3"
      />
    </g>
  )
}

export default Pouch
