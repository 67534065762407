import React from "react"

const WarhammerL = ({
  colors = [
    "#B8B9BA",
    "#727474",
    "#E6E7E7",
    "#646565",
    "#7F8080",
    "#636565",
    "#CDCFCF",
    "#A0A0A1",
    "#D6D7D5"
  ]
}) => {
  return (
    <g id="WarhammerL">
      <path
        opacity="0.63"
        d="M76.9222488,102.290947 c-0.1388321,0.0151443-0.2852325-0.0391235-0.431633-0.093399c-0.5931854-0.286499-0.6613312-0.911232-0.5527878-1.2040405 l1.0614319-2.5039978l0.1388321-0.0151443l7.6319809-18.2523804l-0.0151443-0.1388321l0.2713547-0.7320099l-0.0151443-0.1388321 l0.1085434-0.2928085l0.1388245-0.0151443l1.3946381-3.3129883l0.1312561-0.0845642l0.8986206-2.0647812l-0.0151443-0.1388321 l0.1628113-0.439209l-1.2557755-0.5654221l-0.1766891-0.3319321l-0.3395081,0.1072693l-0.7395859-0.3407745l-0.3546524-0.0315475 l-1.3668289-2.8712769l0.2246552-0.5161972l2.9785538-1.0273361l0.2852325,0.0391312l0.7395859,0.3407669l0.1766891,0.3319321 l0.3395004-0.1072769l4.4148026,1.8363724l0.3395081-0.1072769l0.8859863,0.3950424l0.1072769,0.3395004l0.6625977,0.2789307 l0.0997009,0.2700882l0.0694199-0.0075684l0.1754227,0.964241l-0.0466995,0.2158203l-1.0071564,2.3575897l-0.1236877,0.1539688 l-0.7875443,0.5073547l-0.347084,0.0378647l-0.6701736-0.3483429l-0.2700882,0.0997086l-0.8859863-0.3950424l-0.0921326-0.200676 l-1.3252029-0.5578537l-0.1628113,0.439209l-0.0694122,0.007576l-0.8292084,2.0572128l0.0075684,0.0694122l-1.3870621,3.3824081 l0.0151443,0.1388321l-0.1085358,0.2928009l-0.1388321,0.0151443l-0.2713547,0.7320175l-0.0694122,0.0075684l-7.770813,18.26754 l0.0075684,0.0694122l-1.0614319,2.5039978C77.7173767,101.8530045,77.4081573,102.2379379,76.9222488,102.290947z"
      />
      <rect
        x="67.379"
        y="86.786"
        transform="rotate(-67.267 82.707 87.345)"
        fill={colors[0]}
        width="30.655"
        height="1.117"
      />
      <rect
        x="88.66"
        y="72.421"
        transform="rotate(-67.267 88.695 73.084)"
        fill={colors[1]}
        width="0.07"
        height="1.327"
      />
      <rect
        x="87.391"
        y="75.306"
        transform="rotate(-67.267 87.426 75.97)"
        fill={colors[1]}
        width="0.07"
        height="1.327"
      />
      <rect
        x="87.485"
        y="75.267"
        transform="rotate(-67.267 87.52 75.93)"
        fill={colors[2]}
        width="0.07"
        height="1.327"
      />
      <rect
        x="88.23"
        y="72.729"
        transform="rotate(-67.267 88.58 73.392)"
        fill={colors[3]}
        width="0.698"
        height="1.327"
      />
      <rect
        x="84.132"
        y="77.576"
        transform="rotate(-67.267 86.507 78.24)"
        fill={colors[4]}
        width="4.748"
        height="1.327"
      />
      <rect
        x="85.435"
        y="79.967"
        transform="rotate(-67.267 85.575 80.526)"
        fill={colors[5]}
        width="0.279"
        height="1.117"
      />
      <rect
        x="76.744"
        y="88.329"
        transform="rotate(-67.267 82.051 88.888)"
        fill={colors[4]}
        width="10.614"
        height="1.117"
      />
      <rect
        x="76.058"
        y="99.418"
        transform="rotate(-67.267 77.384 100.081)"
        fill={colors[4]}
        width="2.654"
        height="1.327"
      />
      <rect
        x="77.829"
        y="98.337"
        transform="rotate(-67.267 77.898 98.896)"
        fill={colors[6]}
        width="0.14"
        height="1.117"
      />
      <polyline
        fill={colors[7]}
        points="85.4200592,79.0974503 85.3115234,79.3902588 86.6367111,79.9481125 86.7452621,79.655304"
      />
      <path
        fill={colors[8]}
        d="M77.4788361,101.5980606l-1.1787872-0.5035858c0,0-0.1400986,0.6474533,0.3685226,0.8026886 C77.1153488,102.1293945,77.4788361,101.5980606,77.4788361,101.5980606z"
      />
      <path
        fill={colors[7]}
        d="M85.2964096,71.5248795l0.7395859,0.3407669c0,0,0.0542755-0.1464005,0.1703873-0.3697891 c0.5010529,0.0858231,0.3458176,0.5944443,0.3458176,0.5944443l6.3331757,2.6807251l1.6117172-3.8986053l-6.3331833-2.6807251 c0,0-0.2246552,0.5161972-0.6790085,0.2145538c0.1628113-0.439209,0.1628113-0.439209,0.1628113-0.439209l-0.7395859-0.3407669 l-2.9785461,1.0273361L85.2964096,71.5248795z"
      />
      <rect
        x="90.112"
        y="71.768"
        transform="rotate(-67.267 92.416 72.256)"
        fill={colors[3]}
        width="4.609"
        height="0.978"
      />
      <rect
        x="84.601"
        y="69.706"
        transform="rotate(-67.267 86.696 69.88)"
        fill={colors[3]}
        width="4.19"
        height="0.349"
      />
      <rect
        x="85.463"
        y="70.059"
        transform="rotate(-67.267 87.558 70.2)"
        fill={colors[3]}
        width="4.19"
        height="0.279"
      />
      <polygon
        fill={colors[3]}
        points="92.885376,74.7710266 93.7347717,74.1866837 94.7419357,71.8290939 94.4970932,70.8724213"
      />
      <polygon
        fill={colors[3]}
        points="83.937149,68.7230225 86.0713501,69.6140671 85.2964096,71.5248795"
      />
      <path
        fill={colors[3]}
        d="M86.9396667,71.134903l-0.4619293-0.3710632l-0.2713547,0.7320175 c0,0,0.4934769,0.0164108,0.3458176,0.5944519C86.7768478,71.5741043,86.9396667,71.134903,86.9396667,71.134903z"
      />
    </g>
  )
}

export default WarhammerL
