import React from "react"

const LeafClasp = ({ colors = ["#FCDF54", "#421115"] }) => {
  return (
    <g id="LeafClasp">
      <path
        fill={colors[0]}
        d="M67.3386688,34.6495056c-0.1798935,0-0.3598022-0.0899467-0.5396957-0.0899467 c-0.8095398-0.3597984-0.8994904-2.6984749-0.9894409-2.8783722v-0.0899506l0,0c0,0,0.9894409-1.2592869,1.5291367-1.3492374 c0.1798935-0.0899487,0.4497452-0.0899487,0.8095398,0c0.6296463,0.0899506,1.5291367,0.3597965,1.5291367,0.3597965 l0.3597946,0.0899506l-0.9894333,0.7195911c0.0899429,0,0.0899429,0,0.1798935,0 c0.4497452,0.0899506,0.8994904,0.0899506,1.259285,0.1798992c0.8095474,0.1798992,2.2487335,0.9894409,2.2487335,1.0793915 l0.3597946,0.1798973l-0.3597946,0.0899506c-0.0899506,0-2.6984787,0.8994904-3.5979691,0.8095436h-0.0899429 c-0.4497528,0-0.8095474-0.0899467-0.9894409-0.1798973l0.3597946,0.8095436l-0.0899429,0.0899467 C68.1482086,34.5595589,67.788414,34.6495056,67.3386688,34.6495056z"
      />
      <path
        opacity="0.63"
        fill={colors[1]}
        d="M67.3386688,34.6495056 c-0.1798935,0-0.3598022-0.0899467-0.5396957-0.0899467c-0.8095398-0.3597984-0.8994904-2.6984749-0.9894409-2.8783722v-0.0899506 l0,0c0,0,0.9894409-1.2592869,1.5291367-1.3492374c0.1798935-0.0899487,0.4497452-0.0899487,0.8095398,0 c0.6296463,0.0899506,1.5291367,0.3597965,1.5291367,0.3597965l0.3597946,0.0899506l-0.9894333,0.7195911 c0.0899429,0,0.0899429,0,0.1798935,0c0.4497452,0.0899506,0.8994904,0.0899506,1.259285,0.1798992 c0.8095474,0.1798992,2.2487335,0.9894409,2.2487335,1.0793915l0.3597946,0.1798973l-0.3597946,0.0899506 c-0.0899506,0-2.6984787,0.8994904-3.5979691,0.8095436h-0.0899429c-0.4497528,0-0.8095474-0.0899467-0.9894409-0.1798973 l0.3597946,0.8095436l-0.0899429,0.0899467C68.1482086,34.5595589,67.788414,34.6495056,67.3386688,34.6495056z"
      />
      <path
        fill={colors[0]}
        d="M66.8889236,34.3796616c-0.6296463-0.3597984-0.8994904-2.7884254-0.8994904-2.7884254 s0.8994904-1.0793896,1.529129-1.3492374c0.5396957-0.1798992,2.2487335,0.3597965,2.2487335,0.3597965l-1.2592926,0.8994923 c0,0,1.0793839,0,1.8889313,0.3597965c0.8095398,0.1798973,2.2487259,1.0793915,2.2487259,1.0793915 s-2.6984711,0.8994865-3.4180679,0.8095398c-0.8994904,0-1.5291367-0.1798973-1.5291367-0.1798973l0.3598022,0.9894371 C68.1482086,34.3796616,67.6085129,34.6495056,66.8889236,34.3796616z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M67.4286194,34.3796616 c-0.0899506,0-0.3598022-0.0899506-0.4497528-0.0899506c-0.4497452-0.1798973-0.6296387-1.7090302-0.8095398-2.5185738 c0.1798935-0.3597965,0.9894409-1.0793915,1.4391861-1.2592888c0.0899429,0,0.3597946,0,0.6296387,0 c0.4497452,0,0.8994904,0.0899506,1.0793915,0.1798992l-1.3492432,0.9894409h0.4497528c0,0,0.3598022,0,0.6296463,0.0899506 c0.4497452,0.0899487,0.8994904,0.0899487,1.0793839,0.1798992c0.5396957,0.1798973,1.3492355,0.6296425,1.8889313,0.8994904 c-0.8994904,0.3597984-2.428627,0.8095436-2.9683228,0.6296425h-0.0899506 c-0.6296387-0.0899467-1.3492355-0.3597946-1.3492355-0.3597946L67.248703,33.030426l0.5396957,1.2592888 C67.8783646,34.3796616,67.6984558,34.3796616,67.4286194,34.3796616z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.2592773,31.6811867l3.1482162-0.9894409 C69.4974442,30.6917458,67.6085129,30.8716431,66.2592773,31.6811867z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.2592773,31.6811867 c0,0,0.9894333,1.2592888,6.0265884,1.2592888C70.5768356,32.6706238,66.8889236,32.2208786,66.2592773,31.6811867z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.2592773,31.7711334 c0,0,0.3597946,1.8889313,1.7090302,2.5185738C66.7989655,33.2103195,66.2592773,31.7711334,66.2592773,31.7711334z"
      />
      <path
        fill={colors[0]}
        d="M66.3492203,31.861084l0.2698517,0.4497452l0.0899506-0.0899506 c0,0,0.1798935,0.3597984,0.3597946,0.4497452c0.0899506-0.1798973,0.1798935-0.3597946,0.1798935-0.3597946 S66.4391785,31.861084,66.3492203,31.861084C66.2592773,31.7711334,66.3492203,31.861084,66.3492203,31.861084z"
      />
      <path
        fill={colors[0]}
        d="M66.7090225,32.4907265L66.7090225,32.4907265l0.6296387,0.6296463h-0.3597946 C67.0688171,33.120369,66.7989655,32.7605743,66.7090225,32.4907265z"
      />
      <path
        fill={colors[0]}
        d="M67.1587677,33.2103195h0.3597946l0.4497452,0.9894409 C67.788414,34.1098137,67.3386688,33.570118,67.1587677,33.2103195z"
      />
      <path
        fill={colors[0]}
        d="M67.1587677,32.6706238c0,0,0.1798935,0.3597984,0.5396881,0.4497452 c0.3598022,0.0899506,0.8095474,0.1799011,0.8095474,0.1799011l-0.0899506-0.8095436c0,0-0.1798935-0.0899467-0.4497452-0.0899467 c-0.0899429,0-0.0899429,0.1798973-0.0899429,0.4497452c-0.0899506-0.0899506,0-0.4497452-0.0899506-0.4497452 c-0.3597946-0.0899506-0.5396957-0.0899506-0.5396957-0.0899506L67.1587677,32.6706238z"
      />
      <path
        fill={colors[0]}
        d="M68.5080032,32.6706238l0.0899506,0.8095436 c0,0,0.5396881,0.0899506,0.8095398,0.0899506c0.1798935,0,0.1798935,0,0.1798935,0s-0.3597946-0.449749-0.4497452-0.8095436 C68.7778549,32.6706238,68.5080032,32.6706238,68.5080032,32.6706238z"
      />
      <path
        fill={colors[0]}
        d="M69.2276001,32.7605743c0,0,0.1798935,0.5396957,0.4497452,0.6296425 c0.1798935,0,1.2592926-0.1798973,1.2592926-0.1798973l-0.4497528-0.3597984 C70.3969345,32.8505249,69.317543,32.7605743,69.2276001,32.7605743z"
      />
      <path
        fill={colors[0]}
        d="M66.4391785,31.4113369c0,0,0.8994904-0.4497452,1.259285-0.4497452 c0-0.1798992,0-0.4497452,0-0.4497452S67.0688171,30.8716431,66.4391785,31.4113369z"
      />
      <path
        fill={colors[0]}
        d="M67.788414,30.6017952v0.4497452l0.8095398-0.0899487v-0.0899487l0.0899506-0.0899506 C68.6879044,30.6017952,68.2381592,30.421896,67.788414,30.6017952z"
      />
      <polygon
        fill={colors[0]}
        points="70.6667862,32.8505249 71.0265808,33.2103195 71.8361282,32.8505249"
      />
      <polygon
        fill={colors[0]}
        points="68.7778549,30.6917458 68.6879044,30.7816944 69.1376495,30.6917458"
      />
    </g>
  )
}

export default LeafClasp
