import React from "react"

const KneeArmorL = ({ colors = ["#7C7C7C", "#FFFFFF", "#262261"] }) => {
  return (
    <g id="KneeArmorL">
      <path
        fill={colors[0]}
        d="M84.7,81.6c0.1,0.1,0.6,5.7,0.8,7.6c0,0,0.1,0,0.1,0l1.9,4.4l-3.7,1l-4.9,0.2L74.5,82 C74.5,82,79.8,77.1,84.7,81.6z"
      />
      <path
        fill={colors[0]}
        d="M85.2,88.9c0,0-4.2-3.8-7.3,1.2c2.3,1.9,3.3,2,4.8,2S84.5,89.9,85.2,88.9z"
      />
      <path
        opacity="0.23"
        fill="#FFF"
        d="M79.8,79.8c-0.2,0,0.9,5.7,1,5.6c0.1,0,4.6,3.1,4.4,2.9c-0.1-0.2-0.7-6.7-0.7-6.7 S82.2,79.8,79.8,79.8z"
      />
      <path
        opacity="0.23"
        fill="#FFF"
        d="M81.5,88l1,1.4v2c0,0,0.6,0.9,2.1-2.4C83.4,87.9,81.5,88,81.5,88z"
      />
      <path
        opacity="0.23"
        fill="#FFF"
        d="M85.5,89.8c0.1,0.2,1.3,3.5,1.3,3.5l-3.4,1.1L85.5,89.8z"
      />
      <polygon
        opacity="0.3"
        fill={colors[2]}
        points="85.4,88.8 80.8,86.1 77.4,90.2 83.1,93.9"
      />
    </g>
  )
}

export default KneeArmorL
