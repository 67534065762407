import React from "react"

const Belt = ({ colors = ["#996841", "#C49C2D"] }) => {
  return (
    <g id="Belt">
      <path
        fill={colors[0]}
        d="M59.4000015,72.1999969c0,0,5.8000031,2.5,10,2.3000031c4.3000031-0.3000031,8.5-2.1999969,8.5-2.1999969 l0.6999969,0.9000015c0,0-6.5,2.6999969-9.3000031,2.8000031c-2.5999985,0.0999985-8.5999985-0.5-10.5-3.0999985 C58.7000008,72.8000031,59.4000015,72.1999969,59.4000015,72.1999969z"
      />
      <path
        fill={colors[1]}
        d="M69.0999985,74.3000031l-1,0.0999985C67.5,74.4000015,67,74.9000015,67,75.5 c0,0.5,0.5999985,1,1.1999969,0.9000015l1-0.0999985c0.5999985,0,1.0999985-0.5,1.0999985-1.0999985 C70.3000031,74.6999969,69.8000031,74.1999969,69.0999985,74.3000031z M69.0999985,76.0999985l-0.9000015,0.0999985 c-0.4000015,0-0.8000031-0.3000031-0.9000015-0.6999969c0-0.4000015,0.3000031-0.8000031,0.8000031-0.8000031L69,74.5999985 c0.4000015,0,0.8000031,0.3000031,0.9000015,0.6999969C69.9000015,75.6999969,69.5999985,76,69.0999985,76.0999985z"
      />
      <path
        fill={colors[1]}
        d="M68.4000015,75.1999969c0.4000015,0.0999985,1.5,0,1.5,0v0.1999969l-1.5,0.0999985V75.1999969z"
      />
    </g>
  )
}

export default Belt
