import React from "react"
import { withSkinColor } from "../../builderConnector"

const LeftThumb = ({ skinColor, color = [] }) => {
  return (
    <g id="LeftThumb">
      <path
        fill={skinColor}
        d="M27.6684227,79.1625137c1.6701698,0.0878983,2.4613037,0.7032318,2.4613037,0.7032318l0.26371,1.0548401 l-5.4500294,1.933876l-0.351614-0.7911301c0,0,0.8790359-0.9669418,1.4064598-1.8459778 C26.2619629,79.6899338,27.0530968,79.4262161,27.6684227,79.1625137z"
      />
      <path
        opacity="0.19"
        fill="#21376C"
        d="M27.6684227,79.1625137 c1.6701698,0.0878983,2.4613037,0.7032318,2.4613037,0.7032318l0.26371,1.0548401l-5.4500294,1.933876l-0.351614-0.7911301 c0,0,0.8790359-0.9669418,1.4064598-1.8459778C26.2619629,79.6899338,27.0530968,79.4262161,27.6684227,79.1625137z"
      />
    </g>
  )
}

export default withSkinColor(LeftThumb, { character: "ManHalfling" })
