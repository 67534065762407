import React from "react"

const LongSwordRight = ({
  colors = [
    "#D0A92B",
    "#A8ABAD",
    "#D5D8DA",
    "#B49330",
    "#FFD144",
    "#EDD692",
    "#7B6528",
    "#A38634",
    "#7951A0"
  ]
}) => {
  return (
    <g id="LongSwordRight">
      <rect
        x="31.111"
        y="81.358"
        transform="rotate(-9.357 31.792 83.978)"
        fill={colors[0]}
        width="1.361"
        height="5.24"
      />
      <path
        fill={colors[1]}
        d="M27.4598503,51.6702385c0,0-1.0328369-5.401535-2.0426788-5.8309326 c-0.9001389,0.7363052-0.0533657,6.1871338-0.0533657,6.1871338l4.7458954,28.8110619l2.0960445-0.3562012L27.4598503,51.6702385 z"
      />
      <path
        fill={colors[2]}
        d="M31.1577206,80.6594009l-1.0480194,0.1781006l-4.6870518-28.7768707 c0,0-0.8809624-5.3919868,0.0533638-6.18713L31.1577206,80.6594009z"
      />
      <path
        fill={colors[0]}
        d="M31.3946667,81.7416153c4.1578922-0.6535568,2.3664303-2.7178192,2.3664303-2.7178192 s-0.5542221-0.4007721-2.7472801,0.5287399c-2.4300022-0.1526947-2.6788998,0.4110641-2.6788998,0.4110641 S27.1437321,82.4198303,31.3946667,81.7416153z"
      />
      <path
        fill={colors[3]}
        d="M34.3224564,80.7667923c-0.235363-0.1367722-0.504921-0.2147064-0.842865-0.1749496 c-0.5240097,0.0890503-0.8548164,1.4710388-0.8492546,1.8678207c-0.2147026,0.5049286-0.4294014,1.0098419-0.8603783,1.0742416 c-0.2791061,0.0739517-0.7156429-0.2584305-1.0837955-0.7084961c-0.1574364-0.4063339-0.78162-1.6348648-1.3056278-1.5458145 c-0.372139,0.0985947-0.6607819,0.3244171-0.797554,0.5597839C26.7207222,81.3869095,28.618,79.3409958,28.618,79.3409958 s-0.8110771,1.2603149,1.3589096,1.1832123c0.303751,0.0190887,0.9279308,1.247612,1.3589096,1.1832123 s0.6099014-1.455925,0.8890076-1.5298691c2.0809441-0.6011124,1.1681137-1.6038208,1.1681137-1.6038208 S35.6042671,79.7800217,34.3224564,80.7667923z"
      />
      <path
        fill={colors[4]}
        d="M32.223999,82.6171112l0.0341911-0.0588455 c0.0286369-0.4556274,0.3936348-1.8964462,1.0106773-2.0101471c0.3379402-0.0397491,0.607502,0.0381775,0.842865,0.1749496 c0.1956139-0.2011795,0.2981873-0.3776932,0.2830887-0.6226044c-0.005558-0.3967819-0.4079056-0.7880096-0.7267609-1.0520096 c0.0246506,0.0930405,0.0151062,0.2449112-0.0190887,0.3037567c-0.087471,0.4214325-0.5963783,0.755394-1.4678764,1.0360718 c-0.0930328,0.0246506-0.2051544,0.3530426-0.2488937,0.5637589c-0.1805077,0.4460831-0.3021698,0.9263611-0.6401138,0.9661102 c-0.3379402,0.0397568-0.6226082-0.2830811-0.9319172-0.6989594c-0.1081371-0.2202606-0.3681526-0.4500656-0.4269924-0.4842606 c-0.8524113-0.0230713-1.3915272-0.1789246-1.5831566-0.526413c-0.0246487-0.0930405-0.0492992-0.1860733-0.0739479-0.2791061 c-0.2981873,0.3777008-0.512886,0.8826218-0.4142933,1.2547531c0.1081371,0.2202606,0.2504692,0.3816833,0.5788689,0.4938049 c0.1709633-0.2942123,0.5184498-0.4858398,0.8563938-0.525589c0.6170406-0.1137009,1.2658749,1.2078629,1.4233112,1.6141891 c0.3681526,0.450058,0.7458496,0.7482452,0.9319172,0.6989594C31.9902153,83.4257736,32.136528,83.0385361,32.223999,82.6171112z"
      />
      <path
        fill={colors[3]}
        d="M33.2345886,86.431488l-0.039753-0.337944 c-0.0151024-0.2449036-0.3339577-0.5089111-0.6130562-0.4349594l-0.9549923,0.15345 c-0.2449017,0.0151062-0.5089035,0.3339539-0.4349556,0.61306l0.039753,0.3379517 c0.0151043,0.2449036,0.3339539,0.5089035,0.6130581,0.4349518l0.9549904-0.15345 C33.1375771,87.0047913,33.3085403,86.7105865,33.2345886,86.431488z"
      />
      <path
        fill={colors[5]}
        d="M33.1018028,86.118187c-0.0151024-0.2449036-0.3339539-0.5089035-0.61306-0.4349518 l-0.9891796,0.2122955c-0.2449055,0.0151062-0.5089035,0.3339539-0.4349594,0.61306 C31.5488663,86.0815964,32.5626907,85.9623413,33.1018028,86.118187z"
      />
      <ellipse
        transform="rotate(-9.357 31.182 80.179)"
        fill={colors[6]}
        cx="31.182"
        cy="80.179"
        rx="0.953"
        ry="0.681"
      />
      <ellipse
        transform="rotate(-9.357 31.182 80.179)"
        fill={colors[7]}
        cx="31.182"
        cy="80.179"
        rx="0.544"
        ry="0.817"
      />
      <ellipse
        transform="rotate(-9.357 31.182 80.179)"
        fill={colors[8]}
        cx="31.182"
        cy="80.179"
        rx="0.408"
        ry="0.681"
      />
    </g>
  )
}

export default LongSwordRight
