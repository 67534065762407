import React from "react"

const BobL = ({ colors = ["#4691FF", "#FFFFFF"] }) => {
  return (
    <g id="BobL">
      <path
        opacity="0.1"
        fill="#21376C"
        d="M68.0562973,41.8513374 c0.132164,0.1512451,0.5286636,0.9074554,0.5286636,0.9074554s3.1059265-0.050415,3.3041763,0 s1.784256,1.663662,2.0485916,2.3694611c0.2643356,0.7057953,0.5286636,3.7306404,0.4625854,4.5876808 c-0.0660858,0.8570366-0.5947571,2.823185-1.4538422,3.5289841c-0.8590851,0.7057953-3.3702545,1.8653183-3.3702545,1.8653183 l-0.4625854,0.2520714l2.1807556-0.1008301l2.4450912-1.3107643l3.1720047-3.8818817l-3.3041763-8.5199776L68.0562973,41.8513374z"
      />
      <path
        fill={colors[0]}
        d="M68.8327789,38.7130623c0.9416885-0.1512413,2.1807556-0.9452629,4.9067001,0.6427803 c5.4023285,6.730278,5.8979492,11.6834602,5.8979492,11.3809738c0-0.164856-3.5008698,3.7553978-4.6588821,4.7263184 c-0.3411407,0.2860222-2.0022354,1.1069679-2.6268234,0.9074554c0-0.5671577,0.1306152-1.2481766-0.0495605-1.3611794 c0.396492-0.3402901,2.0320663-1.8149071,2.0320663-1.8149071s-1.784256,1.4746094-4.2623825,1.9661484 c2.3790054-1.0208855,3.320694-1.209938,5.0058212-4.9153709c-0.0991211-2.3442535-0.4899521-5.0962486-1.6355667-6.616848 c-1.0548096-1.400074-1.7632141-1.4343796-4.8571396-1.1721268C68.039772,39.8851891,67.8910828,38.8643074,68.8327789,38.7130623 z"
      />
      <path
        opacity="0.39"
        fill="#21376C"
        d="M78.7453003,51.530838l-2.3294373,0.7562103 c0,0,1.3877487-0.8318291,0.8425598-2.4954948c-0.1486893,1.096508-2.1311951,3.1382751-2.1311951,3.1382751l0.247818-0.6805878 L73.39254,54.1397667l-1.0903778,0.8696442l0.0495605,1.3611794l0.9416885-0.0756226l1.5364456-0.6805878l3.9154434-3.8944893 V51.530838z"
      />
      <path
        opacity="0.39"
        fill="#21376C"
        d="M68.4982376,39.0533524 c0.0369949-0.0227737,0.0745163-0.0363655,0.1116562-0.057476l-0.000145-0.0086594l0.0151825,0.0009155 c1.3087921-0.7367363,2.8661041-0.5623779,3.9250412-0.0860176c1.0793381,0.4855385,1.6483002,1.2815781,1.697525,1.3989601 c-1.6047058-1.1062698-4.5597534-0.3969955-4.5597534-0.3969955s2.1435928-0.1795998,2.7383499,0.1890602 c-2.2799072-0.302475-2.6639862,1.0114517-2.6639862,1.0114517s0.6071091-0.6144333,2.1311874-0.0850677 c-1.2267227-0.3970528-3.0233154,1.0208588-3.0233154,1.0208588l-0.2478256-2.2781029l-0.0118027-0.7356567 C68.5731277,39.037693,68.5353851,39.0413055,68.4982376,39.0533524z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M71.2365646,41.7505112 c0.7684784,0.1400795,1.4504929,0.410202,2.0440598,0.7792969c1.1329041,1.0243416,3.4032059,4.7668419,2.6808167,7.4873161 c-0.372345,1.4022446-1.1864471,2.6676826-2.4615097,3.492466c0.8128967-0.8753242,1.4625168-2.049366,1.8235321-3.2835617 c-0.3874893-5.7964554-1.4508591-6.7291641-2.4761124-7.6940994 C72.4114456,42.1216736,71.6002808,41.8830376,71.2365646,41.7505112z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M75.2784729,43.3400536 c0.0004501-0.6012039-0.7955551-2.0558739-1.5142136-2.9822311c1.6355667,1.1343155,2.6763535,3.1949844,2.6763535,3.1949844 s1.3298264,2.1713982,2.0073166,4.1024513c0.4531937,1.2917595,0.3319702,2.5062828,0.3717194,2.7790756 c-0.2745972-0.418911-0.0587997-0.6059647-1.7594757-2.4387817c0.154068,1.1338043,0.0495682,1.7770958-0.4708405,2.6278343 c-0.0495529-1.9850464-0.0495453-3.7999535-2.4533691-8.0347672 C74.1359634,42.5886192,74.7507858,42.826786,75.2784729,43.3400536z"
      />
    </g>
  )
}

export default BobL
