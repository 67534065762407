import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WarhammerR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WarhammerR") ? (
    <g id="WarhammerR">
      <path
        id="S_x24_warhammerR"
        d="M23.7,74.5l4.9-3.3c5-3.3,6.4-10.2,3-15.2l-1.4-2.2c-3.3-5-10.2-6.4-15.2-3l-4.9,3.3 c-5,3.3-6.4,10.2-3,15.2l1.4,2.2C11.8,76.5,18.7,77.9,23.7,74.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WarhammerR, {
  character: "ManDwarf"
})
