import React from "react"

const LongBowR = ({ colors = ["#5E4E2D", "#FFFFFF"] }) => {
  return (
    <g id="LongBowR">
      <path
        fill={colors[0]}
        d="M54.9,12c-0.3-0.7-2-3.7-2.4-2.5c1.6,1.8,0.7,3.2-0.2,4.7c-0.8,1.4-9.4,11.8-10.8,39.7 c-0.1,0-0.1,0-0.2,0c0,1.4,0,2.7,0.1,4c0,1.3-0.1,2.6-0.1,4c0.1,0,0.1,0,0.2,0c1.4,28,10,38.3,10.8,39.7c0.9,1.5,1.8,2.9,0.2,4.7 c0.5,1.2,2.1-1.8,2.4-2.5c-9.3-12.7-10.8-36.5-11.1-46C44,48.5,45.6,24.7,54.9,12z"
      />
      <path
        fill="#21376C"
        d="M54,13c0-0.6-0.4-0.9-0.7-1.4c0-0.1-0.1,0.3-0.1,0.3c0,0.2,0,0.5,0,0.6 c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c-0.2-0.3-0.4-0.6-0.5-0.9c0-0.1-0.1,0.3-0.1,0.3c0,0.1,0,0.2,0,0.3 c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1,0.3-0.1,0.3c0,0.1,0,0.2,0,0.4c-0.1-0.2-0.2-0.3-0.3-0.5c0,0,0,0.4,0,0.5c0,0.1,0,0.4,0,0.5 c0.2,0.3,0.4,0.6,0.6,0.9c0,0.1,0,0.4,0.1,0.4c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0-0.2,0-0.4c0,0.1,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1 c0,0,0-0.4,0-0.4c0-0.2,0-0.4,0-0.5c0,0,0,0,0,0c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0.1-0.3,0.1-0.3c0-0.1,0-0.3,0-0.4 c0,0.1,0.1,0.2,0.1,0.2c0,0,0-0.2,0.1-0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0.1,0.2C54,13.6,54,13.1,54,13z"
      />
      <path
        fill="#21376C"
        d="M54.1,103.2c0,0.6-0.3,0.9-0.6,1.4c0,0.1,0-0.3,0-0.3c0-0.2,0-0.5,0-0.6 c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c-0.1,0.3-0.3,0.6-0.4,0.9c0,0.1,0-0.3,0-0.3c0-0.1,0-0.2,0-0.3 c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0-0.3,0-0.3c0-0.1,0-0.2,0-0.4c-0.1,0.2-0.2,0.3-0.3,0.5c0,0,0-0.4,0-0.5c0-0.1,0-0.4,0-0.5 c0.2-0.3,0.3-0.6,0.5-0.9c0-0.1,0-0.4,0-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0.2,0,0.4c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1 c0,0,0,0.4,0,0.4c0,0.2,0,0.4,0,0.5c0,0,0,0,0,0c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1,0,0.3,0,0.3c0,0.1,0,0.3,0,0.4 c0-0.1,0.1-0.2,0.1-0.2c0,0,0,0.2,0,0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0.1-0.2C54.2,102.6,54.1,103,54.1,103.2z"
      />
      <g opacity="0.8" fill="#FFF">
        <path
          opacity="0.23"
          d="M52.5,13.7c0.2,0.3,0.4,0.6,0.6,0.9c0.5-0.9,1.1-1.8,1.7-2.7c-0.3-0.7-2-3.7-2.4-2.5 C53.9,11.1,53.3,12.4,52.5,13.7z"
        />
        <path
          opacity="0.23"
          d="M51,18.5c0.1-0.2,0.3-0.4,0.4-0.6c0.5-1,1-2,1.5-2.9c-0.2-0.3-0.4-0.7-0.6-1 c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.4-1.3,1.8-2.7,4.4c0.3,0.4,0.6,0.8,0.8,1.2C50.6,19.3,50.8,18.9,51,18.5z"
        />
        <path
          opacity="0.23"
          d="M50.2,20.1c-0.3-0.4-0.5-0.9-0.8-1.3c-0.4,0.8-0.8,1.6-1.3,2.6c0.4,0.3,0.7,0.7,1.1,1 C49.6,21.6,49.9,20.8,50.2,20.1z"
        />
        <path
          opacity="0.23"
          d="M46.1,34.7C46.1,34.7,46,34.7,46.1,34.7c-0.7-0.6-1.3-1-1.8-1.5 c-0.2,0.8-0.4,1.6-0.6,2.5c0.6,0.4,1.3,0.8,1.9,1.2C45.8,36.2,45.9,35.5,46.1,34.7z"
        />
        <path
          opacity="0.23"
          d="M44.9,41c0.1-0.8,0.3-1.7,0.4-2.5c-0.6-0.4-1.3-0.7-1.7-1c-0.1,0-0.1-0.1-0.2-0.1 c-0.2,0.9-0.3,1.9-0.5,2.8c0.3,0.1,0.7,0.2,1,0.4C44.1,40.7,44.5,40.8,44.9,41z"
        />
        <path
          opacity="0.23"
          d="M47.5,28.8c0.3-1.3,0.6-2.6,1-3.9c-0.5-0.2-1-0.4-1.5-0.6c-0.2,0.6-0.4,1.1-0.6,1.8 c0.3,0.8,0.6,1.5,0.9,2.3C47.4,28.4,47.4,28.6,47.5,28.8z"
        />
        <path
          opacity="0.23"
          d="M46.7,32.1c-0.3-0.5-0.7-1-1-1.5c-0.1-0.2,0-0.7-0.1-0.9c0.4,0.6,0.8,1.2,1.2,1.8 c0.1-0.4,0.2-0.7,0.3-1.1c-0.4-0.9-0.8-1.8-1.3-2.7c0,0,0-0.1,0-0.1c-0.5,1.6-1,3.4-1.5,5.4c0.6,0.5,1.2,1,1.8,1.5 C46.3,33.7,46.5,32.9,46.7,32.1z"
        />
        <path
          opacity="0.23"
          d="M44.3,45.1c-0.1-0.2-0.2-0.3-0.4-0.5c-0.4-0.7-0.9-1.3-1.4-1.9c-0.1,0.6-0.2,1.2-0.2,1.8 c0.6,0.7,1.2,1.5,1.8,2.2C44.1,46.1,44.2,45.6,44.3,45.1z"
        />
        <path
          opacity="0.23"
          d="M44.8,41.3c-0.2-0.1-0.4-0.2-0.6-0.2c-0.5-0.2-0.9-0.4-1.4-0.6c-0.1,0.5-0.1,1-0.2,1.4 c0.6,0.7,1.2,1.4,1.8,2.1C44.5,43.1,44.7,42.2,44.8,41.3z"
        />
        <path
          opacity="0.23"
          d="M44,81.6c0.1,0.5,0.2,1,0.3,1.5c0.5,0.6,1,1.2,1.5,1.8C45.3,83.8,44.6,82.7,44,81.6z"
        />
        <path
          opacity="0.23"
          d="M47.5,89.1c-0.5-0.6-1-1.3-1.4-2c0.1,0.2,0.6,0.4,1,0.6c0-0.1-0.1-0.3-0.1-0.4 c-0.2-0.5-0.5-0.9-0.6-1.4c0,0,0.1,0.1,0.2,0.2c-0.1-0.2-0.1-0.4-0.2-0.5c-0.6-0.7-1.2-1.4-1.8-2.1c0.4,1.6,0.8,3.1,1.3,4.5 c0.6,0.9,1.3,1.7,2,2.5c0.1,0.2,0.9,1.3,0.3,0.6c-0.7-0.9-1.5-1.8-2.2-2.7c1.2,3.8,2.5,6.7,3.7,8.8c1,0.9,2,1.9,3,2.9 c-0.6-1-1.1-2.1-1.6-3.2C49.5,94.4,48.4,91.8,47.5,89.1z"
        />
        <path
          opacity="0.23"
          d="M44.1,76.3c-0.4-0.5-0.9-1-1.3-1.6c0,0,0-0.1-0.1-0.1c0.3,2,0.7,3.9,1,5.7 c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.8,1,1.7,1.5,2.5C44.9,80.9,44.3,78.6,44.1,76.3z"
        />
        <path
          opacity="0.23"
          d="M52.2,101.7c0.6,1,1.2,1.9,1.1,3c0.2,0.3,0.4,0.6,0.6,0.9c0.4-0.6,0.8-1.3,0.9-1.6 c-0.3-0.4-0.6-0.8-0.8-1.2c-1.3-1.5-2.5-2.9-3.9-4.3C51.2,100.2,52,101.3,52.2,101.7z"
        />
        <path
          opacity="0.23"
          d="M43.9,47.7c-0.6-0.7-1.2-1.5-1.8-2.2c-0.1,0.9-0.2,1.9-0.3,2.8c0.6,0.6,1.2,1.3,1.8,2 C43.7,49.4,43.8,48.5,43.9,47.7z"
        />
        <path
          opacity="0.23"
          d="M43.1,61.8c0-0.3,0-0.5,0-0.8c-0.6-0.7-1.3-1.5-1.9-2.3c0,0.7,0,1.3,0,2 c0.6,0.7,1.3,1.3,1.9,2C43.1,62.4,43.1,62.1,43.1,61.8z"
        />
        <path
          opacity="0.23"
          d="M43.1,63c-0.6-0.7-1.3-1.3-1.9-2c0,0.3,0,0.6,0,0.8h0.2c0,0.3,0,0.6,0.1,0.9 c0.6,0.6,1.1,1.2,1.7,1.7C43.2,64,43.1,63.5,43.1,63z"
        />
        <path
          opacity="0.23"
          d="M43.2,65.2c-0.6-0.6-1.1-1.2-1.7-1.8c0.1,1.4,0.2,2.7,0.3,4c0.6,0.8,1.2,1.6,1.8,2.5 C43.5,68.3,43.3,66.8,43.2,65.2z"
        />
        <path
          opacity="0.23"
          d="M44,74.6c0-0.9-0.1-1.8-0.2-2.6c-0.6-0.8-1.2-1.5-1.8-2.3c0.1,1.1,0.3,2.1,0.4,3.1 c0.5,0.7,1,1.4,1.5,2.1C44,74.9,44,74.8,44,74.6z"
        />
      </g>
      <rect x="53.3" y="13.8" fill="#21376C" width="0.1" height="88.5" />
    </g>
  )
}

export default LongBowR
