import React from "react"

const Chainhood = ({ colors = ["#898888", "#FFFFFF"] }) => {
  return (
    <g id="Chainhood">
      <path
        opacity="0.9"
        fill={colors[0]}
        d="M64.1,24.8l-6.6,1.9l3.8,8.3c0,0,6.4,3.4,8.1,4 c-0.4-4-0.5-7.6-0.5-7.6L64.1,24.8z"
      />
      <path
        opacity="0.9"
        fill={colors[0]}
        d="M74.9,24.8l6.1,2.3l-3.3,7.8c0,0-6.4,3.4-8.1,4 c0.4-4,0.5-7.6,0.5-7.6L74.9,24.8z"
      />
      <path
        opacity="0.9"
        fill={colors[0]}
        d="M76.5,15.3c-1.7-8-7-8.3-7-8.3h-0.8c0,0-5.4,0.4-7.2,8.4 c0.8,8.2,2.9,9.3,1.9,10.1c-1,0.9,5.3,6.1,5.3,6.1s-1.7-4.2-2.2-5.9c-0.5-1.6-2.3-5-2.3-8.9s3.4-4.9,5-5.1 c2.4,0.1,4.9,0.5,4.9,5.9c-0.7,5-1.7,5.9-2.7,8.2s-1.9,5.8-1.9,5.8s6.3-5.2,5.3-6.1C73.9,24.7,75.8,23.5,76.5,15.3z"
      />
      <path
        opacity="0.4"
        d="M64.6,25.4c0,0,0-0.1-0.1-0.1l0,0c0-0.1,0-0.1-0.1-0.3l-0.1-0.1 c0-0.1,0-0.1,0-0.2s-0.2-0.2-0.2-0.2v0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0.1,0.1,0-0.1,0-0.1c-0.1-0.1-0.2-0.3-0.2-0.5 c0.1,0.1,0.2,0.1,0.3,0c0-0.1-0.1-0.2-0.2-0.2c0,0.1-0.2-0.1-0.3-0.3c0.1,0,0.2,0,0.3-0.1c0-0.1-0.2-0.2-0.2-0.2 c-0.1,0.2-0.2,0.1-0.3-0.2c0-0.1-0.2-0.2-0.2-0.2c0,0.2,0.1,0.3,0.1,0.4l0,0c0,0,0.1,0.2,0.2,0.4l0,0c0,0.3,0.1,0.5,0.2,0.7h-0.1 l0,0l0,0l0,0c0.1,0.2,0.1,0.3,0.3,0.5l0,0c0,0.1,0.1,0.2,0.1,0.3c0,0.2,0.2,0.4,0.4,0.5C64.7,25.7,64.6,25.5,64.6,25.4z"
      />
      <path
        opacity="0.4"
        d="M64.2,24.4c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1-0.2-0.2-0.1,0 C63.9,24.1,64,24.3,64.2,24.4C64.1,24.4,64.3,24.6,64.2,24.4z"
      />
      <path
        opacity="0.4"
        d="M64.3,24c-0.1-0.2-0.2-0.3-0.3-0.5c0-0.1-0.2-0.2-0.2-0.1 C64,23.7,64.1,23.9,64.3,24C64.4,24.1,64.4,24.1,64.3,24z"
      />
      <path
        opacity="0.4"
        d="M64.9,25.1L64.8,25v-0.1c0,0,0,0,0-0.1l-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.2 c0-0.1-0.2-0.2-0.2-0.1c0.1,0.2,0.2,0.3,0.3,0.4c0,0.3,0.1,0.5,0.3,0.6c0,0,0.1,0.1,0.1,0C65,25.2,65,25.2,64.9,25.1z"
      />
      <path
        opacity="0.4"
        d="M65.2,26.6c-0.1-0.2-0.1-0.3-0.2-0.5L64.9,26l0,0l0,0v-0.1L65,26 c0.1,0.1,0-0.1,0-0.1c-0.1-0.2-0.1-0.3-0.2-0.5c0,0,0,0,0-0.1v-0.1l-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2s-0.2-0.2-0.2-0.1 c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0,0.3,0.1,0.4c0,0.1-0.1,0.1-0.1,0.2l0,0c-0.1-0.2-0.3-0.3-0.4-0.5c0-0.1-0.2-0.2-0.1,0 s0.2,0.4,0.4,0.6l0.1,0.1c0,0.1,0,0.1,0.1,0.2l0,0c-0.1-0.1-0.1-0.1-0.2-0.2L64.5,26l0,0l0,0c0,0.1,0,0.2,0,0.2l0,0 c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1l0,0c0,0,0,0,0-0.1s-0.2-0.1-0.2-0.1c0,0.1,0,0.2,0,0.3l0,0 c0,0.1,0,0.2,0,0.2C64,25.6,64,25.6,64,25.6c-0.1,0.3,0.1,0.6,0.5,0.7c0.1,0-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2 c0.1,0.1,0.2,0.2,0.3,0.3l0,0c0.1,0.1,0.3,0.2,0.4,0.2C65,26.5,65.1,26.6,65.2,26.6C65.2,26.7,65.2,26.7,65.2,26.6 c-0.1,0.1-0.1,0-0.2,0c-0.1-0.1-0.2-0.1-0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0.2,0.1,0.1,0c0,0,0-0.1-0.1-0.1l0,0 c0.1,0.1,0.2,0.1,0.3,0.2C65.4,26.8,65.2,26.6,65.2,26.6z"
      />
      <path
        opacity="0.4"
        d="M65.4,26C65.4,25.9,65.4,25.9,65.4,26C65.3,25.9,65.3,25.9,65.4,26 c-0.1-0.2-0.1-0.3-0.2-0.3c0-0.1-0.2-0.2-0.2-0.2c0,0.3,0.2,0.6,0.5,0.7C65.6,26.2,65.5,26,65.4,26z"
      />
      <path
        opacity="0.4"
        d="M64.6,26.6c0-0.1-0.2-0.2-0.2-0.1v-0.1c0-0.1-0.2-0.2-0.2-0.2 c-0.1,0.3,0.1,0.5,0.3,0.6h0.1C64.7,26.7,64.6,26.7,64.6,26.6z"
      />
      <path
        opacity="0.4"
        d="M63.7,22.7c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.2-0.2-0.1,0 c0.1,0.2,0.2,0.3,0.4,0.4C63.8,22.9,63.7,22.7,63.7,22.7z"
      />
      <path
        opacity="0.4"
        d="M64.3,23.6c-0.1-0.1-0.1-0.2-0.2-0.4c0-0.1-0.2-0.2-0.2-0.1 C64,23.3,64.1,23.4,64.3,23.6C64.3,23.7,64.5,23.7,64.3,23.6z"
      />
      <path
        opacity="0.4"
        d="M66.4,26.4C66.4,26.3,66.4,26.3,66.4,26.4C66.4,26.3,66.4,26.3,66.4,26.4 L66.4,26.4c-0.1-0.2-0.2-0.3-0.2-0.4l0.1,0.1c0.1,0-0.1-0.2-0.1-0.2l-0.1-0.1c0,0,0,0-0.1-0.1c0.1,0.1,0.2,0.1,0.3,0.1 c0.1,0-0.1-0.2-0.1-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1-0.2-0.2-0.2-0.1s0.1,0.3,0.2,0.3l0,0c0,0.1,0.1,0.1,0.1,0.2 c0,0-0.1,0,0,0.1s0.1,0.2,0.1,0.2l0,0c0,0.1,0.1,0.1,0.1,0.2C66.3,26.3,66.5,26.5,66.4,26.4z"
      />
      <path
        opacity="0.4"
        d="M66.8,27L66.8,27c0,0,0-0.1-0.1-0.2l0,0c0,0,0-0.1-0.1-0.1l0,0l0,0 c0,0,0,0-0.1-0.1s-0.2,0-0.1,0.1c0,0,0.1,0.1,0.2,0.2l0,0l0,0l0,0C66.6,26.9,66.7,27.1,66.8,27C66.8,27.1,66.8,27.1,66.8,27z"
      />
      <path
        opacity="0.4"
        d="M65.9,25.9C65.9,25.9,65.8,25.8,65.9,25.9c-0.1-0.2-0.2-0.2-0.3-0.3l-0.1-0.1 c-0.1,0-0.1-0.4-0.1-0.5s-0.2-0.2-0.2-0.1s0,0.3,0.1,0.4l0,0c-0.1-0.1-0.1-0.1-0.1,0s0,0.1,0.1,0.2l0.1,0.1l0,0c0,0,0,0,0.1,0.1 C65.3,25.8,65.5,26.2,65.9,25.9c0,0.1,0,0.1,0.1,0.2L65.9,25.9z"
      />
      <path
        opacity="0.4"
        d="M67.4,28.3c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.2-0.2-0.2-0.1 c0,0.3,0.2,0.4,0.4,0.6C67.6,28.6,67.5,28.4,67.4,28.3z"
      />
      <path
        opacity="0.4"
        d="M65.1,26.1l0.1,0.2C65.3,26.3,65.1,26.1,65.1,26.1z"
      />
      <path opacity="0.4" d="M65.1,26.8l0.1,0.2C65.3,27,65.1,26.8,65.1,26.8z" />
      <path
        opacity="0.4"
        d="M65.2,27.5l0.1,0.2C65.4,27.6,65.3,27.5,65.2,27.5z"
      />
      <path
        opacity="0.4"
        d="M64.7,26.7l0.1,0.2C64.9,26.9,64.8,26.7,64.7,26.7z"
      />
      <path
        opacity="0.4"
        d="M73.5,25.3L73.5,25.3c0-0.1-0.2-0.2-0.2-0.1c0,0.2-0.1,0.3-0.3,0.5 c0,0,0.1,0.2,0.2,0.2C73.4,25.7,73.5,25.6,73.5,25.3c0,0.1,0.2,0.2,0.2,0.2l0,0C73.7,25.4,73.5,25.3,73.5,25.3z"
      />
      <path
        opacity="0.4"
        d="M73.6,24.2c0,0.2-0.1,0.4-0.3,0.4c-0.1,0,0.1,0.2,0.1,0.2 c0.3,0,0.4-0.2,0.4-0.5C73.7,24.2,73.6,24.1,73.6,24.2z"
      />
      <path
        opacity="0.4"
        d="M74.5,24.5c0.1-0.1,0.1-0.1,0.2-0.3c0,0-0.1-0.2-0.2-0.2l-0.1,0.1v0.1 c0,0.1-0.1,0.1-0.2,0.1c0-0.1,0-0.1-0.1-0.2c0-0.1-0.2-0.2-0.1,0s0,0.3-0.1,0.4c0,0,0.1,0.2,0.2,0.2c0.1-0.1,0.1-0.1,0.2-0.2 c0,0.1,0.1,0.1,0.1,0.1C74.4,24.5,74.4,24.5,74.5,24.5c-0.1,0.2-0.1,0.3-0.2,0.4c0,0-0.1-0.1-0.1,0s0,0.1,0,0.2 c0-0.1-0.1-0.1-0.1-0.1L74,25.1c0-0.2-0.1-0.2-0.3-0.2l0,0c-0.1,0-0.1,0.1-0.2,0.1c0,0,0.1,0.2,0.2,0.2s0.1,0,0.2,0v-0.1l0,0 l0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0.1l0.1,0.2c0,0.1,0.1,0.2,0.2,0.1s0.2-0.2,0.2-0.3c0,0,0,0,0.1,0c0.1-0.2,0.2-0.3,0.2-0.5 C74.6,24.6,74.5,24.5,74.5,24.5z"
      />
      <path
        opacity="0.4"
        d="M73.5,25.9c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.1-0.1,0.2-0.2,0.3v0.1v0.1 c0,0.1,0.2,0.2,0.2,0.2c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1C73.5,26,73.5,26,73.5,25.9z"
      />
      <path
        opacity="0.4"
        d="M72.6,26.2l0.1,0.2C72.8,26.4,72.7,26.2,72.6,26.2z"
      />
      <path
        opacity="0.4"
        d="M74.3,23.6v0.2c0,0.1,0.2,0.2,0.2,0.2v-0.2C74.5,23.7,74.3,23.6,74.3,23.6z"
      />
      <path
        opacity="0.4"
        d="M74.8,25.2L74.8,25.2c0-0.1-0.1-0.2-0.2-0.2l-0.1,0.1l0,0 c0,0.1-0.1,0.1-0.1,0.2c0,0,0.1,0.2,0.2,0.2C74.7,25.4,74.7,25.3,74.8,25.2L74.8,25.2L74.8,25.2z"
      />
      <path
        opacity="0.4"
        d="M74.7,23.2l-0.1,0.3c0,0.1,0.2,0.2,0.2,0.2c0-0.1,0.1-0.2,0.1-0.3 C74.9,23.3,74.7,23.1,74.7,23.2z"
      />
      <path opacity="0.4" d="M74.9,22.3l0.1,0.2C75.1,22.5,75,22.3,74.9,22.3z" />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.9,13.6c-0.2,0.1-0.2,0.2-0.2,0.3l0,0 c-0.2,0.2-0.3,0.4-0.2,0.7c0,0.1,0.2,0.2,0.2,0.1c0-0.2,0-0.4,0.2-0.6l0,0c0.1-0.1,0.1-0.2,0.2-0.3L64.9,13.6z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M65.4,13.1c0,0-0.1-0.2-0.2-0.2c0,0,0,0-0.1,0 c0-0.1,0.1-0.2,0.1-0.3c0,0-0.1-0.2-0.2-0.2c0,0.2,0,0.3,0,0.5l0.1,0.1C65,13.1,65,13.1,65,13.2c0,0.1,0.2,0.2,0.2,0.2 c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0.1,0h0.1C65.5,13.3,65.5,13.2,65.4,13.1C65.3,13.2,65.3,13.2,65.4,13.1z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M65.7,12.8L65.7,12.8C65.6,12.7,65.5,12.7,65.7,12.8h-0.2 c0,0.1,0.1,0.3,0.2,0.3l0,0c0,0,0,0,0-0.1C65.8,12.9,65.7,12.8,65.7,12.8z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M66.2,12.6c0,0,0-0.1-0.1-0.1l0,0c0,0,0,0-0.1-0.1 c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0.1,0.2,0.2,0.2l0,0c0,0.1,0.1,0.1,0.1,0.1C66,12.7,66.1,12.6,66.2,12.6 C66.1,12.6,66.2,12.6,66.2,12.6L66.2,12.6z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M65,12c-0.2,0.1-0.2,0.2-0.2,0.4c0,0.1,0.2,0.2,0.2,0.2 c0-0.2,0-0.3,0.2-0.4L65,12z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.6,12.7c-0.2,0.2-0.2,0.3-0.2,0.6c0,0.1,0.2,0.2,0.2,0.1 c0-0.1,0-0.2,0.1-0.3c0,0,0.1,0.1,0.1,0s0-0.2,0.1-0.2C64.8,12.8,64.7,12.7,64.6,12.7z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.4,11.8c-0.1,0.1-0.1,0.1-0.2,0.3c0,0.1,0.2,0.2,0.2,0.2 c0-0.1,0.1-0.2,0.2-0.3C64.6,11.9,64.4,11.7,64.4,11.8z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.5,10.7C64.6,10.7,64.6,10.6,64.5,10.7 c0.1-0.1-0.1-0.3-0.1-0.2l0,0c0-0.4-0.5-0.1-0.6,0.2h-0.1v-0.1c0-0.1-0.2-0.2-0.2-0.2c0,0.2-0.1,0.4-0.1,0.7c0,0,0,0.1,0.1,0.1 c0,0.1,0.2,0.1,0.2,0.1c0-0.2,0-0.3,0.1-0.4l0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.2,0.2,0.2,0.2c0-0.1,0.1-0.2,0.2-0.3 c0,0,0,0,0.1,0c0-0.1,0.1-0.2,0.1-0.2s0-0.1-0.1-0.1c0-0.1,0.1-0.1,0.2-0.2c0,0-0.1-0.2-0.2-0.2s-0.1,0.1-0.2,0.1 c0.1-0.1,0.2-0.3,0.2-0.3s0.1,0,0.1,0.1l0,0C64.3,10.5,64.5,10.7,64.5,10.7z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M65.1,9.7c0,0-0.1-0.2-0.2-0.2l-0.1,0.1c0-0.1-0.1-0.2-0.2-0.2 c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1-0.1-0.1-0.2-0.1c-0.1,0.1-0.3,0.2-0.4,0.4c0,0,0.1,0.2,0.2,0.2l0.1-0.1c0,0.1,0.1,0.2,0.2,0.1 c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0.1,0.2,0.2,0.1C64.9,9.9,64.9,9.8,65.1,9.7L65.1,9.7C65,9.8,65.1,9.7,65.1,9.7z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M65,11.1c0,0-0.1-0.2-0.2-0.2c-0.3,0.1-0.5,0.3-0.6,0.6 c0,0.1,0.1,0.2,0.2,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0,0-0.1C64.7,11.2,64.9,11.2,65,11.1z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M65.4,22.5L65.4,22.5c0.1,0,0-0.2-0.1-0.2h-0.1 c0,0,0,0.1,0.1,0.1l0,0v0.2c0,0.1,0.2,0.2,0.2,0.2L65.4,22.5C65.5,22.5,65.4,22.5,65.4,22.5z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M65,21.8l0.1,0.2C65.2,22,65.1,21.8,65,21.8z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.9,21.5l0.1,0.2C65.1,21.7,65,21.5,64.9,21.5z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.8,20.9l0.1,0.2C65,21.1,64.9,20.9,64.8,20.9z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M65.7,9.3c-0.1,0-0.2,0-0.3-0.1C65.5,9.1,65.6,9,65.7,9 c0,0-0.1-0.1-0.1-0.2l0,0c0,0-0.1-0.2-0.2-0.2s-0.1,0.1-0.1,0.1l0,0h-0.1v0.1l-0.1,0.1c0,0,0,0.1,0.1,0.1l0,0l0.1,0.2l0.1,0.1 c-0.1,0-0.1,0-0.2,0.1l0,0l0.1,0.2c0,0,0,0,0-0.1l0,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0.1l0,0c0-0.1,0-0.1,0.1-0.2 C65.9,9.4,65.7,9.2,65.7,9.3z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M65.8,8.3L65.8,8.3c-0.1,0.1,0.1,0.3,0.1,0.2L66,8.4L65.8,8.3z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M66.3,8.7c0-0.1-0.1-0.2-0.2-0.2C66,8.6,66,8.7,65.9,8.8 C65.8,8.9,66,9,66,9c0-0.1,0.1-0.1,0.1-0.2h0.1C66.3,8.8,66.3,8.8,66.3,8.7z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M66.4,7.7l0.1,0.2C66.6,7.9,66.4,7.7,66.4,7.7z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M65.6,8.5l0.1,0.2C65.8,8.7,65.6,8.5,65.6,8.5z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.5,20.3L64.5,20.3c0,0.2,0.2,0.3,0.2,0.2v-0.1L64.5,20.3z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.9,20.3c0-0.1-0.2-0.2-0.2-0.2l0,0c0,0,0,0-0.1,0l0,0l0,0 C64.6,20.2,64.7,20.3,64.9,20.3L64.9,20.3L64.9,20.3L64.9,20.3z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.8,19.7l-0.1-0.2c0,0-0.2-0.2-0.2-0.1l0.1,0.2 C64.6,19.6,64.8,19.8,64.8,19.7z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.4,18.6C64.4,18.5,64.3,18.5,64.4,18.6 C64.3,18.6,64.3,18.6,64.4,18.6l-0.1,0.1c0,0,0,0,0,0.1l0,0l0.1,0.2c0,0,0,0,0-0.1l0,0c0.1,0,0.1-0.1,0.1-0.1 C64.5,18.7,64.4,18.6,64.4,18.6z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.4,15.1l-0.1,0.1c0,0,0.1,0.2,0.2,0.2 c0.1-0.1,0.1,0,0.1-0.2C64.6,15.2,64.4,15.1,64.4,15.1z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.3,16.1c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0.2,0.2,0.2,0.2 c0-0.1,0.1-0.2,0.1-0.2C64.5,16.2,64.3,16,64.3,16.1z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M64.2,17.3l0.1,0.2C64.4,17.5,64.3,17.3,64.2,17.3z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M74.3,16.9v0.3c0,0.1,0.2,0.2,0.2,0.2V17 C74.4,17,74.3,16.8,74.3,16.9z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M74.2,17.9c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0.2,0.2,0.2,0.2 c0-0.1,0.1-0.2,0.1-0.3C74.4,18,74.2,17.9,74.2,17.9z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M74.3,17.7l0.1,0.2C74.5,17.9,74.4,17.7,74.3,17.7z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M74.2,19.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.2-0.2-0.2-0.2 c0,0.2-0.1,0.3-0.1,0.5c0,0.1,0.2,0.2,0.2,0.2l0,0h0.1L74.2,19.1C74.3,19.2,74.3,19.2,74.2,19.1z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M74,20.3l0.1,0.2C74.1,20.4,74,20.3,74,20.3z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M73.9,19.7L73.9,19.7c0,0.2,0.2,0.3,0.2,0.2v-0.1 C74,19.8,73.9,19.7,73.9,19.7z"
      />
      <path
        opacity="0.4"
        fill="#FFF"
        d="M73.5,20.8L73.5,20.8c0,0.2,0.2,0.3,0.2,0.2l0,0 C73.7,20.9,73.5,20.8,73.5,20.8z"
      />
      <path
        opacity="0.3"
        d="M74.6,14.5c-1-3.5-5.5-4.6-8.7-1.7c-2.2,2-1.7,5-1.2,7.6 c0.5,2.5,1.7,8.4,3.4,9.4c2.1,1.3,1.4,2.1,2.8-0.7C73.2,24.8,76,19.3,74.6,14.5z M73.8,18.9c-0.5,1.4-1.6,5.3-4.1,5.7 c-3.2,0.5-5.1-4-5-6.4C64.8,10.6,75.6,10.9,73.8,18.9z"
      />
      <path opacity="0.3" d="M66.5,24.7L66.5,24.7L66.5,24.7L66.5,24.7z" />
      <path opacity="0.3" d="M67.9,28.5L67.9,28.5L67.9,28.5L67.9,28.5z" />
      <path opacity="0.3" d="M68.1,25.1L68.1,25.1L68.1,25.1L68.1,25.1z" />
      <path d="M74.1,17.1c0-0.2,0-0.4,0-0.6C74.1,16.7,74.1,16.9,74.1,17.1z" />
    </g>
  )
}

export default Chainhood
