import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SlingShotL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SlingShotL") ? (
    <g id="SlingShotL">
      <path d="M101.2,101.7c-2,0-4-0.6-4.8-0.9l-2.6-3l-0.3,0.3c-1.3-1.2-2.1-2.6-2.3-3.1l-0.4-5.7c0-0.1,0.2-0.5,0.4-1l0.2-15.9 l-6.4-2.7l-0.5-4.6l1.1-10l4.1-5.6l6.2-2.9l7.8,1.2c0.6,0.6,2.7,2.6,3.8,5.8l0.2,0.7c0.3,1.1,0.5,2.2,0.4,3.3 c0.1,1.1,0.4,6.8,1.9,30.3c0.7,1.9,0.7,4.1,0,6.8l-0.4,0.4c-1.1,3.9-3.5,5.5-5.3,6.1C103.3,101.5,102.3,101.7,101.2,101.7 L101.2,101.7z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SlingShotL, {
  character: "ManMixed"
})
