import React from "react"

const LongswordLeft = ({
  colors = [
    "#D0AA2B",
    "#A9ABAE",
    "#D6D8DB",
    "#B49330",
    "#SVGID_",
    "#EED793",
    "#7B6529",
    "#A38634",
    "#7951A1",
    "#FFFFFF",
    "#F6F6F6",
    "#E4E5E6",
    "#C8CACC",
    "#A2A4A7",
    "#757779",
    "#2D2D2E",
    "#000000"
  ]
}) => {
  return (
    <g id="LongswordLeft">
      <rect
        x="64.8"
        y="79.2"
        transform="rotate(-84.514 71.16 80.328) scale(.99998)"
        fill={colors[0]}
        width="12.7"
        height="2.2"
      />
      <path
        fill={colors[1]}
        d="M70.2,72.8l3.4,0.3l5-49.9c0,0,0.8-8.8-0.8-9.9c-1.6,0.8-2.6,9.6-2.6,9.6L70.2,72.8z"
      />
      <path
        fill={colors[2]}
        d="M77.8,13.3c1.6,1.1,0.8,9.9,0.8,9.9l-5,49.9L71.9,73L77.8,13.3z"
      />
      <path
        fill={colors[0]}
        d="M75.9,70.6c0,0-0.1,1.6-4,2.1c-3.6-1.2-3.7-2.9-3.7-2.9s-3.2,4.1,3.6,4.8S75.9,70.6,75.9,70.6z"
      />
      <path
        fill={colors[3]}
        d="M68.2,69.9c0,0-1.4,1.7,2.1,2.5c0.5,0.1,0.8,2.3,1.5,2.3c0.7,0.1,1.5-2,2-2c3.5-0.1,2.1-2.1,2.1-2.1 s3.3,3,0.4,4c-0.2-0.4-0.7-0.7-1.3-0.8c-0.9-0.1-1.7,1.9-1.9,2.6c-0.5,0.8-1.1,1.3-1.7,1.3c-0.7-0.1-1.1-0.9-1.4-1.6 c0-0.7-0.7-2.9-1.6-2.9c-0.5-0.1-1,0.1-1.3,0.4C64.9,72.1,68.2,69.9,68.2,69.9z"
      />
      <path
        fill="url(#SVGID_20_)"
        d="M71,74.7c-0.1,0.6,0.2,1.1,0.6,1.1s0.7-0.4,0.8-1c0.1-0.6-0.2-1.1-0.6-1.1S71.1,74.1,71,74.7z"
      />
      <path
        fill={colors[3]}
        d="M69.7,87.7l1.6,0.2c0.5,0,0.9-0.3,1-0.8v-0.5c0-0.5-0.3-0.9-0.8-1l-1.6-0.2c-0.5,0-0.9,0.3-1,0.8v0.5 C68.9,87.3,69.2,87.7,69.7,87.7z"
      />
      <path
        fill={colors[5]}
        d="M72.3,86.6c0-0.5-0.3-0.9-0.8-1l-1.6-0.2c-0.5,0-0.9,0.3-1,0.8C70,85.9,71.6,86.1,72.3,86.6z"
      />
      <ellipse
        transform="rotate(-84.514 71.703 74.806) scale(.99998)"
        fill={colors[6]}
        cx="71.7"
        cy="74.8"
        rx="1.5"
        ry="1.1"
      />
      <ellipse
        transform="rotate(-84.514 71.703 74.806) scale(.99998)"
        fill={colors[7]}
        cx="71.7"
        cy="74.8"
        rx="1.3"
        ry="0.9"
      />
      <ellipse
        transform="rotate(-84.514 71.703 74.805) scale(.99998)"
        fill={colors[8]}
        cx="71.7"
        cy="74.8"
        rx="1.1"
        ry="0.7"
      />
      <radialGradient
        id="SVGID_20_"
        cx="-337.803"
        cy="136.202"
        r="0.903"
        gradientTransform="rotate(-174.514 -134.52 95.68) scale(.99998)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.698" stopColor="#fff" stopOpacity="0.1" />
        <stop offset="0.724" stopColor="#f6f6f6" stopOpacity="0.1" />
        <stop offset="0.762" stopColor="#e4e5e6" stopOpacity="0.1" />
        <stop offset="0.809" stopColor="#c8cacc" stopOpacity="0.1" />
        <stop offset="0.862" stopColor="#a2a4a7" stopOpacity="0.1" />
        <stop offset="0.921" stopColor={colors[14]} stopOpacity="0.1" />
        <stop offset="0.983" stopColor="#2d2d2e" stopOpacity="0.1" />
        <stop offset="0.995" stopOpacity="0.1" />
      </radialGradient>
    </g>
  )
}

export default LongswordLeft
