import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongSwordLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongSwordLeft") ? (
    <g id="LongSwordLeft">
      <path d="M87.1877441,90.7462006c1.3567657-0.788414,2.2719574-2.0764465,2.4957733-3.6433105l0.0381927-0.3246765 c0.0947189-0.3575363,0.1000595-0.7387466,0.0488739-1.0871048c0.1696014-0.0985489,0.3391876-0.1971054,0.5087891-0.2956543 c1.4698257-0.8541183,2.408699-2.2315292,2.6561966-3.8877792c0.3330536-2.1596832-0.7181702-3.9687042-1.5447769-5.0007935 l3.7185745-22.8810959c1.1175613-6.9259834-0.187294-9.0413361-1.7618103-10.3194046l-4.7005844-0.6714478l-0.5981674,0.2719765 c-1.3567657,0.788414-2.9121475,2.1459732-4.1374207,8.7564468l-3.5963593,22.9613228 c-1.0977859,0.7135468-2.9595032,2.3247452-3.1756668,4.9458542c-0.1015854,1.6470718,0.4935532,3.1917725,1.7670746,4.3422623 c-0.1275787,0.3009949-0.2788315,0.6913757-0.2841721,1.0725861l-0.0381927,0.3246765 c-0.4659729,2.8419037,1.4263687,5.4477081,4.2682648,5.9136734l1.0068893,0.1711121 C84.9226456,91.5331039,86.1701736,91.3375168,87.1877441,90.7462006L87.1877441,90.7462006z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongSwordLeft, {
  character: "WomanGnome"
})
