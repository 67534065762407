import React from "react"

const Skarf = ({ colors = ["#7F0E05", "#262261"] }) => {
  return (
    <g id="Skarf">
      <path
        fill={colors[0]}
        d="M63.6985245,55.4371643c0,0-1.6249657-0.7065086-2.7553749-0.3532562 c-0.1413002-0.4945526-1.2717094-0.5652046-1.6249619-0.4239006c-1.3423653,0.7065048-1.4130135,2.4727669-1.4130135,2.4727669 s3.1086273,7.9128647,8.1248169,8.5487213c0,0,2.9673233,0.3532562,4.8748932-0.0706482 c1.9075623-0.3532486,7.3476562-8.5487213,7.3476562-8.5487213s0-1.6249619-1.3423615-2.1195183 c-1.3423615-0.4239044-1.9782181-0.2826004-2.8966751,0.3532524c-0.494545-0.4945526-1.2717056,0.141304-1.2717056,0.141304 s-2.0488739,2.3314667-4.5922928,2.1901665C65.6767349,57.4860306,64.6169739,55.8610649,63.6985245,55.4371643z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M61.0137978,55.0132599 c0,0-0.1413002,5.6520424,8.4074211,6.429203C66.1712952,60.1000977,63.6278687,58.8990402,61.0137978,55.0132599z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M73.8015518,55.7197647 c0,0-2.5434189,5.510746-8.4074173,3.1086235C67.5136566,60.1000977,71.8233414,61.4424629,73.8015518,55.7197647z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M60.5192451,59.0403404 c3.3205833,1.9075661,7.9835167,5.0868416,13.1410103,1.6956139c-1.6249695,1.3423653-4.521637,2.7553749-10.95084,1.1304092 c-0.2119522,0.8478088,6.7118034,5.3694458,12.9290581-2.1901665c-1.1304092,1.4836617-4.521637,7.5596123-11.021492,4.5216408 C62.214859,62.9967766,60.5192451,59.0403404,60.5192451,59.0403404z"
      />
    </g>
  )
}

export default Skarf
