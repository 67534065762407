import React from "react"

const CalfWrapL = ({ colors = ["#493812"] }) => {
  return (
    <g id="CalfWrapL">
      <path
        fill={colors[0]}
        d="M79.1,96.8c-0.1-0.4-0.2-0.8-0.3-1.2c0.9,1,3.4,1.1,4.4,1.2c1,0.1,2,0.1,3-0.2c-2.6-0.2-5,0.1-7.4-1.3 c-0.2-0.1-0.9-3.1-1.1-3.3c1.1,0.4,4,0.6,5.5,0.5c0.9-0.1,1.9-1.1,2.9-2.3c0.2,0.2,0.4,0.5,0.5,0.8c0.2-0.1,0.6-0.1,0.9-0.3 c0.2,0.4,0.7,1.6,0.9,2.1c-1.4,0.9-3.5,1.3-5.2,1.4c-0.7,0.1-1.6,0.1-2.4,0.1c2.1,0.7,5.1,0,7.5,0.3c0.1,0,0.8,2.4,0.5,2.7 c0,0-2,0.8-2.5,0.9c0.8,0.1,1.9,0.1,2.7,0.1c0.2,0,0.1,2.6,0.1,2.6c-1.6-0.1-8-0.6-9-1.3C79.8,99,79.3,97.4,79.1,96.8z"
      />
    </g>
  )
}

export default CalfWrapL
