import React from "react"

const BrownBoots = ({
  colors = [
    "#614723",
    "#453627",
    "#926259",
    "#352515",
    "#EED793",
    "#4D3A21",
    "#564027",
    "#6D5036",
    "#856146",
    "#SVGID_",
    "#69533A",
    "#FFD245",
    "#524028",
    "#996A38",
    "#FED712"
  ]
}) => {
  return (
    <g id="BrownBoots">
      <polygon
        fill={colors[0]}
        points="43.9,114 34.7,112.5 34.3,106 33.2,101.2 44.6,100.9 43.6,107"
      />
      <path
        fill={colors[0]}
        d="M34.7,111.7c0,0-2.9,3.7-3,4.8c0,0.7,0.2,2.5,0.2,2.5H45c0,0,1-1.7,0.3-3s-1.5-4.8-1.5-4.8L34.7,111.7z"
      />
      <path
        fill={colors[1]}
        d="M36.3,111.5c0,0-2.9,2.3-1.6,3.5c-0.6,0.6,2.1,3,4.7,2.9c3-0.1,4.6-2.5,4.6-2.5s0.1-2.5-1.4-3.7 C41,110.6,36.3,111.5,36.3,111.5z"
      />
      <path
        fill={colors[2]}
        d="M36.3,110.8c0,0-3.3,2.3-2.2,4c0.4,0.9,2.7,2.5,5.2,2.4c2.9-0.1,4.6-2.3,4.6-2.3s0-2.7-1.5-3.9 S36.3,110.8,36.3,110.8z"
      />
      <polygon
        fill={colors[3]}
        points="45,119.4 31.9,119.4 31.7,118.7 45.1,118.7"
      />
      <path
        fill={colors[4]}
        d="M41.5,115.4c-0.1,0.1-4.4,0.1-4.5,0s1.4-0.8,2.2-0.8S41.6,115.3,41.5,115.4z"
      />
      <path
        fill={colors[1]}
        d="M41.7,114.6c-1.1-0.2-3.6-0.2-4.7,0c-0.3-1.1-0.8-12.4-1.1-13.5c1.6-0.3,4.5-0.5,6,0.1 C41.6,102.3,42,113.5,41.7,114.6z"
      />
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="39.083"
        y1="54.398"
        x2="38.873"
        y2="41.611"
        gradientTransform="matrix(1 0 0 -1 0 153.987)"
      >
        <stop offset="0.033" stopColor="#4d3a21" />
        <stop offset="0.235" stopColor={colors[6]} />
        <stop offset="0.605" stopColor="#6d5036" />
        <stop offset="0.912" stopColor={colors[8]} />
      </linearGradient>
      <path
        fill="url(#SVGID_3_)"
        d="M41.4,114.5c-0.8-0.2-3.2-0.2-4,0c-0.2-1.1-0.7-12.2-0.9-13.3c1.2-0.2,3.9-0.2,5.1,0 C41.3,102.3,41.6,113.3,41.4,114.5z"
      />
      <path
        fill={colors[1]}
        d="M36.3,114.1c-0.3,0-0.7-0.4-0.8-0.6c-0.1-0.1-0.5-0.1-0.4-0.3c0.1-0.1-0.4,0.2-0.4,0.2 c-0.1,0.3,0.3,0.7,1.6,1c1.4-0.8,4.4-0.9,5.6-0.4v-0.3C40.7,113.1,37.7,113.2,36.3,114.1z"
      />
      <path
        fill={colors[1]}
        d="M41.9,113.6v0.2l0,0C41.9,113.7,41.9,113.7,41.9,113.6L41.9,113.6z"
      />
      <path
        fill={colors[2]}
        d="M42.2,112.5c0-0.1,0-0.1,0-0.2c-1.5-0.6-5.9-0.4-7.6,0.6c0,0,0,0.1-0.1,0.1 C36.3,112.1,40.6,111.9,42.2,112.5z"
      />
      <path
        fill={colors[2]}
        d="M42.2,112.5v-0.2l0,0C42.2,112.4,42.2,112.5,42.2,112.5L42.2,112.5z"
      />
      <path
        fill={colors[10]}
        d="M35.4,113.8c0.1,0.2,0.7,0.3,0.9,0.3c1.3-0.8,4.4-1,5.6-0.5l0.1-0.3c0.1-0.2,0.1-0.5,0.2-0.8 c-1.6-0.6-5.4-0.4-7.1,0.6C35,113.4,35.3,113.6,35.4,113.8z"
      />
      <path
        fill={colors[2]}
        d="M41.9,113.6L41.9,113.6c0-0.1,0-0.2,0.1-0.3L41.9,113.6z"
      />
      <path
        fill={colors[2]}
        d="M42.2,112.5c-0.1,0.3-0.1,0.5-0.2,0.8L42.2,112.5L42.2,112.5z"
      />
      <g>
        <path
          fill={colors[11]}
          d="M36.9,111.9c0.1,0.8,0.2,1.2,0.3,2c1-0.2,3.5-0.2,4.5-0.1c0.1-0.8,0.1-1.2,0.2-2 C40.7,111.7,38.1,111.7,36.9,111.9z M41.4,113.4c-0.8-0.1-3.2-0.1-4,0.1c-0.1-0.5-0.1-0.8-0.2-1.3c0.9-0.2,3.4-0.2,4.3-0.1 C41.5,112.6,41.5,112.9,41.4,113.4z"
        />
        <path
          fill={colors[11]}
          d="M36.9,111.8c0.1,0.6,0.1,0.9,0.2,1.4c0.1,0.3,0.4,0.6,0.7,0.5c1.2-0.1,3-0.1,3.9,0l0.2-2 C40.7,111.5,38.1,111.5,36.9,111.8z M41.4,113.2c-0.8-0.1-3.2-0.1-4,0.1c-0.1-0.5-0.1-0.8-0.2-1.3c0.9-0.1,3.4-0.1,4.3-0.1 C41.5,112.5,41.5,112.7,41.4,113.2z"
        />
      </g>
      <g>
        <path
          fill={colors[12]}
          d="M35.8,104.3c0,0,4.2,0.8,6.9-0.1c0.1,0.7,0,1.6,0,1.6s-5.4,0.9-6.9-0.1 C35.7,105.1,35.8,104.3,35.8,104.3z"
        />
        <path
          fill={colors[2]}
          d="M35.7,104.1c0,0,4.2,0.8,6.9-0.1c0.1,0.7,0,1.6,0,1.6s-5.4,0.9-6.9-0.1C35.7,105,35.7,104.1,35.7,104.1 z"
        />
      </g>
      <g>
        <path
          fill={colors[12]}
          d="M36.3,108.3c0,0,3.8,0.8,5.9-0.1c0,0.7,0,1.6,0,1.6s-4.7,0.9-5.9-0.1C36.2,109.2,36.3,108.3,36.3,108.3 z"
        />
        <path
          fill={colors[2]}
          d="M36.2,108.1c0,0,3.8,0.8,5.9-0.1c0,0.7,0,1.6,0,1.6s-4.7,0.9-5.9-0.1C36.2,109,36.2,108.1,36.2,108.1z"
        />
      </g>
      <path
        fill={colors[13]}
        d="M33.2,104.1C33.3,104.1,33.3,104.1,33.2,104.1c-0.4,0.3-1.2,0.7-1.3,0.7l0,0l0,0c0.5-0.1,1,0,1.5,0 c0.4,0,0.8,0,1.2-0.1c0.2,0,0.9-0.1,1-0.2c-0.3,0.3-0.8,0.4-1.3,0.5c-0.2,0-0.4,0.1-0.4,0.1c1.4,0.1,1.9,0,2.1-0.1l-0.3,0.2 c0.2,0,0.5-0.2,0.7,0c0,0,0-0.1,0.1-0.1c0.1-0.2,0.6-0.4,0.7-0.6h0.4c0.1,0,0.2,0.1,0.2,0.1v0.2c0,0.1,1.3,0.1,1.4,0.2 c0-0.8,0.7-0.4,0.7-0.4c0-0.1,0.1-0.1,0.3-0.2h0.4c0.2,0.2,0.6,0.4,0.7,0.6c0,0.1,0,0.1,0.1,0.1c0.2-0.1,0.5,0,0.7,0l-0.3-0.2 c0.1,0.1,0.7,0.3,2.1,0.1c0,0-0.2,0-0.4-0.1c-0.5-0.1-1-0.2-1.3-0.5c0.1,0.1,0.8,0.1,1,0.2c0.4,0,0.8,0.1,1.2,0.1 c0.5,0,1-0.1,1.5,0l0,0l0,0c-0.1,0-0.9-0.5-1.3-0.7l0,0c0.3,0.1,0.5,0.2,0.8,0.3c0.4,0.1,0.8,0.4,1,0.7c0,0.1,0.1,0.1,0.1,0.2 c-0.1-0.3-0.1-0.6-0.1-0.8c-0.2-0.6-0.5-0.8-1-1.1c0,0-0.1,0-0.1-0.1c0.1-0.1,0.4-0.3,0.6,0c0.2-0.4-0.3-0.7-0.3-0.9 c0.5-0.3,0.6-1.2,0.2-1.6c0,0-0.4,0.7-0.6,0.7c-0.1,0-0.1-0.5-0.1-0.5l0,0c0,0,0.4-0.1,0.1-0.6c-0.1,0.4-0.3,0.3-0.5,0.3 c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2-0.2-0.6-0.7-0.7-0.7c-0.2,0.1-0.5-0.1-0.7-0.1c0,0,0.3,0.2,0.3,0.3c-0.1-0.1-0.7-0.3-2-0.3 c0,0,0.1,0.1,0.4,0.1c0.5,0.1,0.9,0.3,1.2,0.5l0,0c-0.2-0.1-0.8-0.2-1-0.2c-0.4-0.1-0.8-0.1-1.1-0.2c-0.4,0-0.9,0-1.3,0 c0-0.2,0-0.3,0-0.5c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.5-0.6,0.6-0.6,0.6s-0.1,0-0.1,0.1h-0.4c0.3-0.2,0.2-0.6,0.2-0.6 c-0.5,0.4-0.7,0.3-1,0.2c-0.5-0.1-2.2,1.2-1.1,0.2c0.1-0.1-0.8,0.1-1,0.2l0,0c0.3-0.3,0.8-0.4,1.2-0.5c0.2,0,0.4-0.1,0.4-0.1 c-1.4,0-1.9,0.2-2,0.3l0.3-0.3c-0.2,0-0.5,0.2-0.7,0.1c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.8,0.2-1-0.2c-0.3,0.6,0.2,0.9,0.1,0.9 c-0.2,0-0.5-0.1-0.5-0.2s0-0.1,0-0.1c-0.1,0.2-0.1,0.4,0,0.5c0,0.1,0.1,0.1,0.1,0.1l0,0c0,0,0,0.1,0.1,0.1 c-0.1,0.3-0.3,0.2-0.5-0.2c-0.3,0.3-0.2,0.6-0.2,0.8c-0.5,0.1-0.7,1-0.7,1.1c0.3-0.3,0.5-0.4,1-0.1c0,0.2,0.1,0.4,0.2,0.6 c0,0-0.1,0-0.1,0.1c-0.5,0.3-0.8,0.7-1,1.3C32,104.2,33,104.2,33.2,104.1z M39.3,100.2c0-0.1,0-0.2,0-0.2 c0.1,0.1,0.3,0.2,0.5,0.3h-0.4C39.3,100.3,39.3,100.3,39.3,100.2C39.3,100.3,39.3,100.3,39.3,100.2z"
      />
      <g>
        <polygon
          fill={colors[0]}
          points="52.9,114 62,112.5 62.4,106 63.6,101.2 52.1,100.9 53.1,107"
        />
        <path
          fill={colors[0]}
          d="M62,111.7c0,0,2.9,3.7,3,4.8c0,0.7-0.2,2.5-0.2,2.5H51.7c0,0-1-1.7-0.3-3s1.5-4.8,1.5-4.8L62,111.7z"
        />
        <path
          fill={colors[1]}
          d="M60.5,111.5c0,0,2.9,2.3,1.6,3.5c0.6,0.6-2.1,3-4.7,2.9c-3-0.1-4.6-2.5-4.6-2.5s-0.1-2.5,1.4-3.7 C55.7,110.6,60.5,111.5,60.5,111.5z"
        />
        <path
          fill={colors[2]}
          d="M60.4,110.8c0,0,3.3,2.3,2.2,4c-0.4,0.9-2.7,2.5-5.2,2.4c-2.9-0.1-4.6-2.3-4.6-2.3s0-2.7,1.5-3.9 C55.7,109.9,60.4,110.8,60.4,110.8z"
        />
        <polygon
          fill={colors[3]}
          points="51.7,119.4 64.9,119.4 65,118.7 51.6,118.7"
        />
        <path
          fill={colors[4]}
          d="M55.2,115.4c0.1,0.1,4.4,0.1,4.5,0s-1.4-0.8-2.2-0.8S55.1,115.3,55.2,115.4z"
        />
        <path
          fill={colors[1]}
          d="M55,114.6c1.1-0.2,3.6-0.2,4.7,0c0.3-1.1,0.8-12.4,1.1-13.5c-1.6-0.3-4.5-0.5-6,0.1 C55.1,102.3,54.7,113.5,55,114.6z"
        />
        <linearGradient
          id="SVGID_4_"
          gradientUnits="userSpaceOnUse"
          x1="-197.57"
          y1="54.399"
          x2="-197.78"
          y2="41.612"
          gradientTransform="rotate(180 -69.952 76.994)"
        >
          <stop offset="0.033" stopColor="#4d3a21" />
          <stop offset="0.235" stopColor={colors[6]} />
          <stop offset="0.605" stopColor="#6d5036" />
          <stop offset="0.912" stopColor={colors[8]} />
        </linearGradient>
        <path
          fill="url(#SVGID_4_)"
          d="M55.4,114.5c0.8-0.2,3.2-0.2,4,0c0.2-1.1,0.7-12.2,0.9-13.3c-1.2-0.2-3.9-0.2-5.1,0 C55.4,102.3,55.2,113.3,55.4,114.5z"
        />
        <path
          fill={colors[1]}
          d="M60.4,114.1c0.3,0,0.7-0.4,0.8-0.6c0.1-0.1,0.5-0.1,0.4-0.3c-0.1-0.1,0.4,0.2,0.4,0.2 c0.1,0.3-0.3,0.7-1.6,1c-1.4-0.8-4.4-0.9-5.6-0.4v-0.3C56,113.1,59.1,113.2,60.4,114.1z"
        />
        <path
          fill={colors[1]}
          d="M54.8,113.6v0.2l0,0C54.8,113.7,54.8,113.7,54.8,113.6L54.8,113.6z"
        />
        <path
          fill={colors[2]}
          d="M54.5,112.5c0-0.1,0-0.1,0-0.2c1.5-0.6,5.9-0.4,7.6,0.6c0,0,0,0.1,0.1,0.1 C60.4,112.1,56.1,111.9,54.5,112.5z"
        />
        <path
          fill={colors[2]}
          d="M54.5,112.5v-0.2l0,0C54.5,112.4,54.5,112.5,54.5,112.5L54.5,112.5z"
        />
        <path
          fill={colors[10]}
          d="M61.3,113.8c-0.1,0.2-0.7,0.3-0.9,0.3c-1.3-0.8-4.4-1-5.6-0.5l-0.1-0.3c-0.1-0.2-0.1-0.5-0.2-0.8 c1.6-0.6,5.4-0.4,7.1,0.6C61.7,113.4,61.4,113.6,61.3,113.8z"
        />
        <path
          fill={colors[2]}
          d="M54.8,113.6L54.8,113.6c0-0.1,0-0.2-0.1-0.3L54.8,113.6z"
        />
        <path
          fill={colors[2]}
          d="M54.5,112.5c0.1,0.3,0.1,0.5,0.2,0.8L54.5,112.5L54.5,112.5z"
        />
        <g>
          <path
            fill={colors[14]}
            d="M54.9,111.9c0.1,0.8,0.1,1.2,0.2,2c1-0.2,3.5-0.1,4.5,0.1c0.1-0.8,0.2-1.2,0.3-2 C58.7,111.7,56.1,111.7,54.9,111.9z M55.2,112.1c0.9-0.1,3.4-0.1,4.3,0.1c-0.1,0.5-0.1,0.8-0.2,1.3c-0.8-0.1-3.2-0.2-4-0.1 C55.3,112.9,55.2,112.6,55.2,112.1z"
          />
          <path
            fill={colors[14]}
            d="M54.9,111.7l0.2,2c0.8-0.1,2.7-0.1,3.9,0c0.3,0,0.6-0.2,0.7-0.5c0.1-0.4,0.1-0.8,0.2-1.4 C58.7,111.5,56.1,111.5,54.9,111.7z M55.2,112c0.9-0.1,3.4-0.1,4.3,0.1c-0.1,0.5-0.1,0.8-0.2,1.3c-0.8-0.1-3.2-0.2-4-0.1 C55.3,112.7,55.2,112.5,55.2,112z"
          />
        </g>
        <g>
          <path
            fill={colors[12]}
            d="M60.9,104.3c0,0-4.2,0.8-6.9-0.1c-0.1,0.7,0,1.6,0,1.6s5.4,0.9,6.9-0.1C61,105.1,60.9,104.3,60.9,104.3 z"
          />
          <path
            fill={colors[2]}
            d="M61,104.1c0,0-4.2,0.8-6.9-0.1c-0.1,0.7,0,1.6,0,1.6s5.4,0.9,6.9-0.1C61,105,61,104.1,61,104.1z"
          />
        </g>
        <g>
          <path
            fill={colors[12]}
            d="M60.4,108.3c0,0-3.8,0.8-5.9-0.1c0,0.7,0,1.6,0,1.6s4.7,0.9,5.9-0.1C60.5,109.2,60.4,108.3,60.4,108.3z"
          />
          <path
            fill={colors[2]}
            d="M60.5,108.1c0,0-3.8,0.8-5.9-0.1c0,0.7,0,1.6,0,1.6s4.7,0.9,5.9-0.1C60.5,109,60.5,108.1,60.5,108.1z"
          />
        </g>
        <path
          fill={colors[13]}
          d="M52.2,104.1L52.2,104.1c-0.4,0.3-1.2,0.7-1.3,0.7l0,0l0,0c0.5-0.1,1,0,1.5,0c0.4,0,0.8,0,1.2-0.1 c0.2,0,0.9-0.1,1-0.2c-0.3,0.3-0.8,0.4-1.3,0.5c-0.2,0-0.4,0.1-0.4,0.1c1.4,0.1,1.9,0,2.1-0.1l-0.3,0.2c0.2,0,0.5-0.2,0.7,0 c0,0,0-0.1,0.1-0.1c0.1-0.2,0.6-0.4,0.7-0.6h0.4c0.1,0,0.2,0.1,0.2,0.1v0.2c0,0.1,1.3,0.1,1.4,0.2c0-0.8,0.7-0.4,0.7-0.4 c0-0.1,0.1-0.1,0.3-0.2h0.4c0.2,0.2,0.6,0.4,0.7,0.6c0,0.1,0,0.1,0.1,0.1c0.2-0.1,0.5,0,0.7,0l-0.3-0.2c0.1,0.1,0.7,0.3,2.1,0.1 c0,0-0.2,0-0.4-0.1c-0.5-0.1-1-0.2-1.3-0.5c0.1,0.1,0.8,0.1,1,0.2c0.4,0,0.8,0.1,1.2,0.1c0.5,0,1-0.1,1.5,0l0,0l0,0 c-0.1,0-0.9-0.5-1.3-0.7l0,0c0.3,0.1,0.5,0.2,0.8,0.3c0.4,0.1,0.8,0.4,1,0.7c0,0.1,0.1,0.1,0.1,0.2c-0.1-0.3-0.1-0.6-0.1-0.8 c-0.2-0.6-0.5-0.8-1-1.1c0,0-0.1,0-0.1-0.1c0.1-0.1,0.4-0.3,0.6,0c0.2-0.4-0.3-0.7-0.3-0.9c0.5-0.3,0.6-1.2,0.2-1.6 c0,0-0.4,0.7-0.6,0.7c-0.1,0-0.1-0.5-0.1-0.5l0,0c0,0,0.4-0.1,0.1-0.6c-0.1,0.4-0.3,0.3-0.5,0.3c-0.1-0.1-0.2-0.1-0.3-0.1 c-0.2-0.2-0.6-0.7-0.7-0.7c-0.2,0.1-0.5-0.1-0.7-0.1c0,0,0.3,0.2,0.3,0.3c-0.1-0.1-0.7-0.3-2-0.3c0,0,0.1,0.1,0.4,0.1 c0.5,0.1,0.9,0.3,1.2,0.5l0,0c-0.2-0.1-0.8-0.2-1-0.2c-0.4-0.1-0.8-0.1-1.1-0.2c-0.4,0-0.9,0-1.3,0c0-0.2,0-0.3,0-0.5 c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.5-0.6,0.6-0.6,0.6s-0.1,0-0.1,0.1h-0.4c0.3-0.2,0.2-0.6,0.2-0.6c-0.5,0.4-0.7,0.3-1,0.2 c-0.5-0.1-2.2,1.2-1.1,0.2c0.1-0.1-0.8,0.1-1,0.2l0,0c0.3-0.3,0.8-0.4,1.2-0.5c0.2,0,0.4-0.1,0.4-0.1c-1.4,0-1.9,0.2-2,0.3 l0.3-0.3c-0.2,0-0.5,0.2-0.7,0.1c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.8,0.2-1-0.2c-0.3,0.6,0.2,0.9,0.1,0.9c-0.2,0-0.5-0.1-0.5-0.2 s0-0.1,0-0.1c-0.1,0.2-0.1,0.4,0,0.5c0,0.1,0.1,0.1,0.1,0.1l0,0c0,0,0,0.1,0.1,0.1c-0.1,0.3-0.3,0.2-0.5-0.2 c-0.3,0.3-0.2,0.6-0.2,0.8c-0.5,0.1-0.7,1-0.7,1.1c0.3-0.3,0.5-0.4,1-0.1c0,0.2,0.1,0.4,0.2,0.6c0,0-0.1,0-0.1,0.1 c-0.5,0.3-0.8,0.7-1,1.3C51,104.2,52,104.2,52.2,104.1z M58.3,100.2c0-0.1,0-0.2,0-0.2c0.1,0.1,0.3,0.2,0.5,0.3h-0.4 C58.3,100.3,58.3,100.3,58.3,100.2C58.2,100.3,58.3,100.3,58.3,100.2z"
        />
      </g>
    </g>
  )
}

export default BrownBoots
