import React from "react"

const GreatAxeL = ({ colors = ["#9E9E9E", "#21366C"] }) => {
  return (
    <g id="GreatAxeL">
      <path
        fill={colors[0]}
        d="M115.0065994,4.7300873c-0.234993,0.7049975-0.8224945,1.2924953-1.5274963,1.527493 c-2.7024918,1.4099956-4.817482,1.9974933-7.7549667,1.9974933c-0.3525009,0.4699974-1.2924957,0.9399958-1.7624969,1.4099951 c0-0.2349997,0-4.8174815,0-5.1699791c0,0,1.0575027-0.1175003,1.0575027-0.3524981c0,0-1.8799896-0.9399953-2.3499908-6.5799751 c-0.5875015,5.7574773-2.2324982,6.6974726-2.2324982,6.6974726c0,0.2350001,1.0575027,0.2350001,1.0575027,0.2350001 c0,0.2349997,0,5.1699791,0,5.1699791s-1.1749954-0.7049971-1.7624969-1.4099951 c-2.9374847,0-5.0524826-0.704998-7.7549667-1.9974933c-0.5875015-0.3524976-1.2924957-0.8224978-1.5274963-1.527493 c0,0-11.279953,16.2149391,3.054985,27.6123962c-1.9974899-6.6974754-2.4674911-16.0974388,5.8749771-16.9199352 c0.234993,0,1.9974899-0.1175003,1.9974899-0.1175003l-0.1175003,5.8749771 c-0.1174927,0.2350006-0.4700012,0.4700012-0.9400024,0.9399967h1.0575027v1.7624931h-1.1749954 c0,0.1174965,0,2.4674873,0,2.5849895c0.1174927,0,1.2924957,0,1.4099884,0c0,25.0274029,0,65.2122498,0,90.2396545h-0.1174927 c0.4699936,1.1749954,0.9399948,1.6449966,1.4099884,1.5274963c0.4700012,0.234993,0.9400024-0.234993,1.4099884-1.5274963 h-0.1174927c0-25.0273972,0-65.2122498,0-90.2396545c0.1174927,0,1.2924881,0,1.4099884,0c0-0.1174965,0-2.4674873,0-2.5849895 h-1.1749954v-1.7624931h1.0574951c-0.234993-0.352499-0.7049942-0.5874977-0.9399948-0.9399967l-0.1175003-5.7574768 c0,0,1.8799896,0,1.9974899,0c8.224968,0.8224983,7.7549667,10.2224598,5.8749771,16.9199352 C126.1690598,20.9450264,115.0065994,4.7300873,115.0065994,4.7300873z"
      />
      <path
        fill={colors[1]}
        d="M99.6141663,8.2550735c-2.8199921,0-4.817482-0.5874977-7.2849731-1.7624931 c-2.5849838,4.464982-7.0499725,14.5699425,0.234993,22.2074127c-1.0574951-5.9924736-0.234993-12.689949,6.6974716-13.2774467 c0.1175003,0,1.527504,0,1.9974899-0.1175003l0.1175003-5.6399794 C101.0241547,9.430069,100.0841599,8.725071,99.6141663,8.2550735z"
        opacity="0.6"
      />
      <path
        fill={colors[1]}
        d="M105.8416443,8.2550735c2.8199921,0,4.8174744-0.5874977,7.2849655-1.7624931 c2.5849838,4.464982,7.0499725,14.5699425-0.234993,22.2074127c1.0574951-5.9924736,0.234993-12.689949-6.6974716-13.2774467 c-0.1175003,0-1.6449966,0-2.1149979,0l-0.1174927-5.6399775C104.3141479,9.430069,105.3716431,8.725071,105.8416443,8.2550735z"
        opacity="0.6"
      />
      <rect
        x="100.084"
        y="25.528"
        opacity="0.6"
        fill={colors[1]}
        width="5.287"
        height="0.94"
      />
      <rect
        x="100.202"
        y="22.003"
        opacity="0.6"
        fill={colors[1]}
        width="5.052"
        height="0.352"
      />
      <path
        opacity="0.6"
        fill={colors[1]}
        d="M101.3766556,4.4950895c0,0-0.7049942,0-1.0574951-0.2350001 c0.7049942-0.2350001,2.8199921-0.3524978,4.5824814-0.1174998c-0.1175003,0.1174998-1.0574951,0.3524976-1.0574951,0.3524976 S102.786644,4.0250893,101.3766556,4.4950895z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M101.2591553,64.4949036v-9.0474625 c0-0.2350006,0-0.4700012,0.1175003-0.7049942c0.9400024,0.1174965,1.9974899,0.1174965,2.9374924,0.2350006 c0,0.1174965,0.1174927,0.3524971,0.1174927,0.4699974v9.3999672c0,0.2350006,0,0.3524933-0.1174927,0.5874939 C103.3741455,65.3174057,102.316658,64.9649048,101.2591553,64.4949036z"
      />
    </g>
  )
}

export default GreatAxeL
