import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const CapeFastensers = ({ allEnabledElements }) => {
  return allEnabledElements.includes("CapeFastensers") ? (
    <g id="CapeFastensers">
      <path d="M34.2018204,18.7982674c0,0,7.0808754-12.5524588,18.5068321-8.2073765 s9.8627586,14.8551388,9.8627586,14.8551388l-9.0581169,9.1232758l-16.7366104-4.3450813L34.2018204,18.7982674z" />
      <path d="M103.6435623,18.7982674c0,0-7.0808792-12.5524588-18.5068359-8.2073765 s-9.8627548,14.8551388-9.8627548,14.8551388l9.0581131,9.1232758l16.7366104-4.3450813L103.6435623,18.7982674z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(CapeFastensers, {
  character: "ManDragonborn"
})
