import React from "react"

const Mouth = ({ colors = ["#010101"] }) => {
  return (
    <g id="Mouth">
      <path
        fill={colors[0]}
        d="M52.0682678,52.5500336c0,0-2.0421867,0.4724617-3.6142235,0.4456406 c-1.5720329-0.0268211-3.4063454-0.4456406-3.4063454-0.4456406c0.2389526,0.0880127,1.9598808,0.7138557,3.4063454,0.7138557 C49.9005127,53.2638893,51.8293152,52.6380463,52.0682678,52.5500336z"
      />
      <polygon
        opacity="0.42"
        fill="#21376C"
        points="47.5257263,53.7542801 47.2441025,54.397995 49.8189659,54.397995 49.4568748,53.7542801"
      />
    </g>
  )
}

export default Mouth
