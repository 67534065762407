import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FlameLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FlameLeft") ? (
    <g id="FlameLeft">
      <path d="M93.3123856,50.1393433c0,0,17.8424835,11.1577415,16.9841995,27.7870636 c-0.2145767,6.4371643,0.6437149,18.5604782-17.1657562,18.6677628 C60.4018974,89.4931793,93.3123856,50.1393433,93.3123856,50.1393433z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FlameLeft, {
  character: "WomanDwarf"
})
