import React from "react"

const SolidBackground = ({ colors = ["#7F7F7F"] }) => {
  return (
    <g id="SolidBackground">
      <rect
        x="3.673"
        y="9.821"
        fill={colors[0]}
        width="90.12"
        height="109.754"
      />
    </g>
  )
}

export default SolidBackground
