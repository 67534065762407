import React from "react"

const LeafClasp = ({ colors = ["#FCDF54", "#421115"] }) => {
  return (
    <g id="LeafClasp">
      <path
        fill={colors[0]}
        d="M67.8,33.5c-0.2,0-0.4-0.1-0.6-0.1c-0.9-0.4-1-3-1.1-3.2v-0.1l0,0c0,0,1.1-1.4,1.7-1.5 c0.2-0.1,0.5-0.1,0.9,0c0.7,0.1,1.7,0.4,1.7,0.4l0.4,0.1l-1.1,0.8c0.1,0,0.1,0,0.2,0c0.5,0.1,1,0.1,1.4,0.2 c0.9,0.2,2.5,1.1,2.5,1.2l0.4,0.2l-0.4,0.1c-0.1,0-3,1-4,0.9h-0.1c-0.5,0-0.9-0.1-1.1-0.2l0.4,0.9l-0.1,0.1 C68.7,33.4,68.3,33.5,67.8,33.5z"
      />
      <path
        opacity="0.63"
        fill={colors[1]}
        d="M67.8,33.5c-0.2,0-0.4-0.1-0.6-0.1c-0.9-0.4-1-3-1.1-3.2v-0.1 l0,0c0,0,1.1-1.4,1.7-1.5c0.2-0.1,0.5-0.1,0.9,0c0.7,0.1,1.7,0.4,1.7,0.4l0.4,0.1l-1.1,0.8c0.1,0,0.1,0,0.2,0 c0.5,0.1,1,0.1,1.4,0.2c0.9,0.2,2.5,1.1,2.5,1.2l0.4,0.2l-0.4,0.1c-0.1,0-3,1-4,0.9h-0.1c-0.5,0-0.9-0.1-1.1-0.2l0.4,0.9l-0.1,0.1 C68.7,33.4,68.3,33.5,67.8,33.5z"
      />
      <path
        fill={colors[0]}
        d="M67.3,33.2c-0.7-0.4-1-3.1-1-3.1s1-1.2,1.7-1.5c0.6-0.2,2.5,0.4,2.5,0.4l-1.4,1c0,0,1.2,0,2.1,0.4 c0.9,0.2,2.5,1.2,2.5,1.2s-3,1-3.8,0.9c-1,0-1.7-0.2-1.7-0.2l0.4,1.1C68.7,33.2,68.1,33.5,67.3,33.2z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M67.9,33.2c-0.1,0-0.4-0.1-0.5-0.1c-0.5-0.2-0.7-1.9-0.9-2.8 c0.2-0.4,1.1-1.2,1.6-1.4c0.1,0,0.4,0,0.7,0c0.5,0,1,0.1,1.2,0.2l-1.5,1.1H69c0,0,0.4,0,0.7,0.1c0.5,0.1,1,0.1,1.2,0.2 c0.6,0.2,1.5,0.7,2.1,1c-1,0.4-2.7,0.9-3.3,0.7h-0.1c-0.7-0.1-1.5-0.4-1.5-0.4l-0.4-0.1l0.6,1.4C68.4,33.2,68.2,33.2,67.9,33.2z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.6,30.2l3.5-1.1C70.2,29.1,68.1,29.3,66.6,30.2z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.6,30.2c0,0,1.1,1.4,6.7,1.4C71.4,31.3,67.3,30.8,66.6,30.2 z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.6,30.3c0,0,0.4,2.1,1.9,2.8C67.2,31.9,66.6,30.3,66.6,30.3 z"
      />
      <path
        fill={colors[0]}
        d="M66.7,30.4l0.3,0.5l0.1-0.1c0,0,0.2,0.4,0.4,0.5c0.1-0.2,0.2-0.4,0.2-0.4S66.8,30.4,66.7,30.4 C66.6,30.3,66.7,30.4,66.7,30.4z"
      />
      <path
        fill={colors[0]}
        d="M67.1,31.1L67.1,31.1l0.7,0.7h-0.4C67.5,31.8,67.2,31.4,67.1,31.1z"
      />
      <path
        fill={colors[0]}
        d="M67.6,31.9H68l0.5,1.1C68.3,32.9,67.8,32.3,67.6,31.9z"
      />
      <path
        fill={colors[0]}
        d="M67.6,31.3c0,0,0.2,0.4,0.6,0.5s0.9,0.2,0.9,0.2L69,31.1c0,0-0.2-0.1-0.5-0.1c-0.1,0-0.1,0.2-0.1,0.5 c-0.1-0.1,0-0.5-0.1-0.5c-0.4-0.1-0.6-0.1-0.6-0.1L67.6,31.3z"
      />
      <path
        fill={colors[0]}
        d="M69.1,31.3l0.1,0.9c0,0,0.6,0.1,0.9,0.1c0.2,0,0.2,0,0.2,0s-0.4-0.5-0.5-0.9 C69.4,31.3,69.1,31.3,69.1,31.3z"
      />
      <path
        fill={colors[0]}
        d="M69.9,31.4c0,0,0.2,0.6,0.5,0.7c0.2,0,1.4-0.2,1.4-0.2l-0.5-0.4C71.2,31.5,70,31.4,69.9,31.4z"
      />
      <path
        fill={colors[0]}
        d="M66.8,29.9c0,0,1-0.5,1.4-0.5c0-0.2,0-0.5,0-0.5S67.5,29.3,66.8,29.9z"
      />
      <path
        fill={colors[0]}
        d="M68.3,29v0.5l0.9-0.1v-0.1l0.1-0.1C69.3,29,68.8,28.8,68.3,29z"
      />
      <polygon fill={colors[0]} points="71.5,31.5 71.9,31.9 72.8,31.5" />
      <polygon fill={colors[0]} points="69.4,29.1 69.3,29.2 69.8,29.1" />
    </g>
  )
}

export default LeafClasp
