import React from "react"

const Cloak = ({ colors = ["#304B60", "#21376C"] }) => {
  return (
    <g id="Cloak">
      <path
        fill={colors[0]}
        d="M35.7903366,55.3566933c0,0-2.5315361,1.8055458-4.2253704,1.6035805 c-2.4197636,11.208931-7.6314621,42.6262207-7.1475124,42.727211 c0.7259274,4.8470993,16.8173485,10.5020523,16.8173485,10.5020523L35.7903366,55.3566933z"
      />
      <path
        fill={colors[0]}
        d="M100.6399612,55.4576721c0,0,4.1535187,0.25877,5.2424164,0.0568047 c2.5407486,11.7138329,5.4045334,47.1014595,4.7995911,47.2024422 c-0.8469162,3.7363052-15.7284546,6.5637894-15.7284546,6.5637894L100.6399612,55.4576721z"
      />
      <path
        fill="#262261"
        d="M93.2596893,30.6162777c-0.6049423-2.1206055-15.4864807-8.280468-16.21241-10.1991119 C76.563324,19.3063717,76.9262924,9.1072617,76.563324,7.9964671c-1.330864-1.9186444-4.7185364-7.0686908-8.8321304-6.6647654 c-4.1136017,0.4039253-7.0173111,4.4431767-7.8642311,6.5637846c-0.2419777,0.5049062,0.7259293,11.0069599,0.2419777,12.117754 c-0.8469162,1.918644-16.4543839,8.4824314-17.1803131,10.6030369 c-1.1745338,3.6761684-6.6059265,8.239706-9.4509888,11.5971546c-0.6717529,0.7927361,0.4328117,0.4211502-0.0987244,1.2119865 c5.9999886-1.5348816,17.2929573-9.375782,21.4065475-11.4963856 c2.1777878-1.2117767,13.1876984-6.0588779,12.8247452-7.8765411 c-7.8642273-8.0785027-5.5654526-13.3295307-4.7185402-13.4305105c3.0247002,0.6058884,7.0173149,0.8078499,10.0420189,0 c0.8469086,0,2.903717,4.140234-4.5975571,13.4305105c-0.2419662,1.8176632,11.856842,6.765749,13.9136353,8.0785046 c3.9926147,2.5245323,17.8152466,9.2215652,22.2918015,10.7362823 C102.000824,38.6260643,94.7115402,35.1604347,93.2596893,30.6162777z"
        opacity="0.3"
      />
      <path
        fill={colors[0]}
        d="M37.5813293,26.1205349c-1.9841003,2.177475-5.1446152,14.3645267-4.5864754,16.7366123 c0,0.1009789,18.2819557-10.0192833,22.2745667-12.2408695c2.177784-1.2117767,12.2769356,0.2547417,12.155941-1.5629234 l-0.9428864-3.7115974c-3.5510254-3.6823235-7.0463715-8.9737225-6.5919838-11.0790701 c0.5673904-2.6289597,1.1729736-4.112339,1.3073425-4.9534626l0,0c4.2345924-4.9480829,11.2518959-3.4333639,14.1556129,0l0,0 c0.6049423,2.8274755,2.2708511,6.8339109-5.230423,16.0232086l-1.3488312,4.1395264 c-0.1209869,1.8176632,11.176796-0.2694206,13.3545837,0.9423561 c3.9926147,2.2215881,21.031105,11.2358665,22.4127884,12.4529648 c1.0322495-2.2631378-2.9070129-12.3035679-4.3588715-16.7467442 c-0.6049347-2.1206055-16.4591675-5.3290768-17.5480652-6.1369286c-0.327774-0.429903-2.9055939-0.2252045-3.2092972-1.9534855 c-0.4810104-2.7372952,2.0501862-7.9282722,0.4186096-11.0232334c-2.0567932-4.0392518-6.2764435-8.511611-11.7209091-8.511611 c-5.0814972-0.1009812-8.8610077,4.3511486-10.9178009,8.4913816 c-2.0568008,3.9382696,1.7367744,9.0825529,1.0108452,12.7178774 C58.2160797,19.6035519,39.6111565,23.8928738,37.5813293,26.1205349z"
      />
      <g fill={colors[1]}>
        <path
          opacity="0.36"
          d="M57.6891823,17.9936142 c0,0.1009808,0.2419777,2.8274746,7.985218,6.6647644C56.9632568,21.830904,57.6891823,17.9936142,57.6891823,17.9936142z"
        />
        <path
          opacity="0.36"
          d="M78.6201324,17.9936142 c0,0.1009808-0.2419739,2.8274746-7.9852142,6.6647644C79.3460541,21.9318829,78.6201324,17.9936142,78.6201324,17.9936142z"
        />
      </g>
    </g>
  )
}

export default Cloak
