import React from "react"

const LightPatch = ({ colors = ["#FFFFFF"] }) => {
  return (
    <g id="LightPatch">
      <path
        opacity="0.2"
        fill="#FFF"
        d="M53.8290596,107.233017 c0.6175461-0.0382233,2.4520035-0.6684647,1.9491272-0.7294312c-0.0864944-0.3584824-0.1730309-0.7169342-0.2595253-1.0754166 c0.0002098-0.0638123-0.0170784-0.1249542-0.0439072-0.1820145c-0.1481133-0.613945-0.296299-1.2278976-0.4444122-1.8418427 c-0.04953-0.2053833-2.3847504,0.5661621-2.3362961,0.7184143c0.2897224,0.9100113,0.5184364,1.8316422,0.6918068,2.7704773 C53.4267235,107.1143723,53.6062469,107.2467728,53.8290596,107.233017z"
      />
      <g fill="#21376C">
        <path d="M55.6057968,107.0221329c0.0432129-0.0492249-0.1536865-0.4732819-0.1536865-0.4732819 l0.0793266,0.5054321L55.6057968,107.0221329z" />
        <path d="M55.3177605,103.6469421c0,0,0.0271492-0.2120132-0.038166-0.1668015 c-0.0653191,0.0452042-0.3989258,0.1215363-0.3989258,0.1215363L55.3177605,103.6469421z" />
        <path d="M54.0185318,103.6960449c0.0341682-0.062294,0.025177-0.5777664,0.025177-0.5777664l-0.2110138,0.0301285 L54.0185318,103.6960449z" />
        <path d="M52.9734535,104.4194031c-0.0196495,0.0434265-0.5114479-0.0131149-0.5114479-0.0131149 s0.0170593,0.2200546,0.0743332,0.2190552S53.0116425,104.3350067,52.9734535,104.4194031z" />
        <polygon points="54.8963737,107.2813034 54.7517281,106.8210907 54.7516747,107.323494" />
        <polygon points="54.1578255,107.386734 54.0543671,107.0139389 53.9769592,107.3766708" />
        <polygon points="55.4443207,104.0810318 55.0273132,104.176445 55.4824867,104.2478333" />
        <polygon points="55.6060257,104.7613068 55.5367126,104.5774231 55.1528511,104.8044662" />
        <polygon points="55.8059235,105.3571854 55.7546959,105.1994247 55.312561,105.3701935" />
        <polygon points="55.9656334,105.922905 55.900341,105.7169113 55.4139938,105.8796463" />
        <polygon points="54.5410461,103.5855713 54.6064148,103.0379486 54.4436302,103.0540161" />
        <polygon points="53.2729378,103.9029617 53.282032,103.41362 53.0669975,103.4658432" />
        <polygon points="53.1562653,105.0464249 52.6679192,105.0946045 52.7281914,105.2654343" />
        <polygon points="53.2446365,105.5649185 52.8527565,105.5849762 52.909008,105.7779007" />
        <polygon points="53.3460693,106.0743713 53.0335693,106.0974503 53.0848007,106.2552109" />
        <polygon points="53.4555473,106.5396042 53.0626678,106.502388 53.1631317,106.7033615" />
      </g>
    </g>
  )
}

export default LightPatch
