import React from "react"

const LeatherL = ({ colors = ["#8B5E3C", "#353891"] }) => {
  return (
    <g id="LeatherL">
      <path
        fill={colors[0]}
        d="M59.0589714,81.8363876c-0.0133133-0.0399399,0.0813293-0.3828278,0.2363815-0.78405 c0.2202454-0.5699005,0.5623741-1.2574921,0.8901215-1.3616714c1.3985596-0.4445572,1.9847908,0.3218536,1.9847908,0.3218536 l0.2145691,0.5900726l-1.8640938,0.7912369L60.2391167,83.08358L59.0589714,81.8363876z"
      />
      <path
        opacity="0.39"
        fill={colors[1]}
        d="M60.0379562,81.3737106c-0.0360184,0.0773773-0.502903,0.8851089-0.502903,0.8851089 s0.5632515,0.7040634,0.6034813,0.583374c0.0402336-0.120697,0.2615089-1.4885941,0.2615089-1.4885941l-0.1810417-0.1810455 c0,0,0.7241783-1.2069702,1.7098694-0.8046494C61.4863167,79.9655838,60.581089,80.2069778,60.0379562,81.3737106z"
      />
      <polygon
        fill={colors[0]}
        points="58.7639351,82.0241318 59.40765,80.8439865 60.3195801,79.6638412 60.8023682,80.2270966 59.2467194,82.5873871"
      />
    </g>
  )
}

export default LeatherL
