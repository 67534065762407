import React from "react"

const LuteLeft = ({
  colors = ["#93712B", "#D8A43D", "#6A5121", "#B68B32", "#8E6D2B"]
}) => {
  return (
    <g id="LuteLeft">
      <path
        fill={colors[0]}
        d="M86.915741,77.1378632h-0.5586166l-0.0698242,0.2793121l-0.3491364,0.0698242l-0.2793121-0.4887924 v-0.4189606l0.4887848,0.0698318l0.1396561,0.2094803l0.6982727,0.0698242 C86.9855652,76.9982071,87.055397,77.1378632,86.915741,77.1378632z"
      />
      <path
        fill={colors[0]}
        d="M86.9855652,76.5094147l-0.6284409,0.0698318v0.1396484l-0.2793121-0.1396484l-0.2793121-0.2793121 l-0.0698242-0.3491364l0.3491364,0.1396561l0.2793121,0.2094803l0.6982727,0.0698242 C87.055397,76.4395905,87.1252213,76.5094147,86.9855652,76.5094147z"
      />
      <path
        fill={colors[0]}
        d="M87.055397,76.0206299h-0.5586243l-0.0698242,0.1396484l-0.3491364-0.0698242l-0.2793121-0.2793045 l-0.0698242-0.3491364l0.3491364,0.1396484l0.2793121,0.2094879l0.6982727,0.0698242 C87.1252213,75.8809738,87.1950455,76.0206299,87.055397,76.0206299z"
      />
      <path
        fill={colors[0]}
        d="M86.7760849,77.9757843l-0.6284485,0.0698242l-0.0698242,0.1396561h-0.3491364l-0.2793045-0.4189606 l-0.0698318-0.3491364l0.3491364,0.1396484l0.2793121,0.2094879l0.6982727,0.0698242 C86.7760849,77.8361359,86.8459091,77.9757843,86.7760849,77.9757843z"
      />
      <path
        fill={colors[1]}
        d="M81.469223,87.3326187c-3.7008362-0.1396561-11.0326767,10.0550995-11.6611252,12.2197418 c-0.6284409,2.2344589-0.8379211,4.7482452,2.2344742,6.3542633c3.0723877,1.6060181,7.2620163-1.3965454,8.4490738-3.1422195 C81.8183594,101.0187225,83.9830017,87.7515793,81.469223,87.3326187z"
      />
      <path
        fill={colors[2]}
        d="M88.3821106,77.0680389l-0.0698242-0.6982727l-6.0051346,11.8706055 c0,0,2.1646423,19.5515823-10.1947556,17.736084c0,0,7.8904648,4.6085815,11.3119888-6.0749588 c0.8379211-5.0275497-0.2793045-11.0326843-0.2793045-11.0326843"
      />
      <path
        fill={colors[0]}
        d="M88.1027985,79.0930252l0.5586166,0.2094803v0.1396561l0.2793121,0.1396484l0.3491364-0.2094803 l0.1396484-0.2793121h-0.4887848h-0.2094803l-0.6982727-0.0698242 C88.0329742,78.9533691,87.96315,79.0930252,88.1027985,79.0930252z"
      />
      <path
        fill={colors[0]}
        d="M88.2424545,77.5568237l0.5586166,0.2094803v0.2793121l0.2793121,0.1396561l0.3491287-0.3491364 l0.1396561-0.4189606h-0.4887848l-0.2094879,0.1396484H88.2424545 C88.2424545,77.4171753,88.1726227,77.4869995,88.2424545,77.5568237z"
      />
      <path
        fill={colors[0]}
        d="M88.3122864,76.9283752l0.5586166,0.2094879v0.2094803l0.2793121-0.0698242l0.3491287-0.2094803 l0.1396561-0.2793121h-0.4189606l-0.3491364,0.1396484l-0.6982727-0.0698242 C88.3122864,76.7887268,88.2424545,76.9283752,88.3122864,76.9283752z"
      />
      <path
        fill={colors[0]}
        d="M88.4519348,76.4395905l0.5586166,0.1396561v0.1396484l0.3491364,0.0698242l0.3491364-0.2094803 l0.1396561-0.2793121H89.429512l-0.3491287,0.1396637l-0.6982727-0.0698242 C88.4519348,76.299942,88.3122864,76.3697662,88.4519348,76.4395905z"
      />
      <path
        fill={colors[0]}
        d="M88.2424545,78.3947525l0.5586166,0.2094803v0.2094879l0.3491364,0.0698242l0.3491364-0.349144 l0.1396484-0.2793045h-0.4189606l-0.3491364,0.1396484h-0.6284409 C88.1726227,78.2550964,88.1027985,78.3947525,88.2424545,78.3947525z"
      />
      <polygon
        fill={colors[3]}
        points="80.2123337,87.6817551 86.8459091,75.6714935 88.521759,76.0904541 82.3071518,88.2403717"
      />
      <polygon
        fill={colors[4]}
        points="70.9253387,100.8092499 74.6959991,102.6945801 74.9753036,101.8566513 71.3442993,100.041153"
      />
      <polygon
        fill={colors[0]}
        points="88.3122864,76.5094147 88.3821106,77.0680389 87.8933182,79.4421616 87.2648773,79.3024979"
      />
      <polygon
        fill={colors[2]}
        points="88.1027985,79.0930252 87.8933182,79.4421616 88.2424545,76.299942 88.521759,76.0904541"
      />
      <ellipse
        transform="rotate(-58.149 77.237 94.184)"
        fill={colors[0]}
        cx="77.237"
        cy="94.184"
        rx="1.815"
        ry="1.257"
      />
      <line fill="none" x1="72.252" y1="100.879" x2="87.055" y2="75.811" />
      <line fill="none" x1="72.601" y1="101.019" x2="87.474" y2="75.811" />
      <line fill="none" x1="73.02" y1="101.089" x2="87.823" y2="75.951" />
      <line fill="none" x1="73.369" y1="101.298" x2="88.173" y2="76.021" />
    </g>
  )
}

export default LuteLeft
