import React from "react"

const LuteLeft = ({
  colors = ["#94712B", "#D9A53F", "#695020", "#B78C34", "#8F6D2B", "#000102"]
}) => {
  return (
    <g id="LuteLeft">
      <path
        fill={colors[0]}
        d="M74.3000031,71H73.5l-0.0999985,0.4000015l-0.5,0.0999985L72.5,70.8000031v-0.5999985 l0.6999969,0.0999985l0.1999969,0.3000031l1,0.0999985C74.4000015,70.8000031,74.5,71,74.3000031,71z"
      />
      <path
        fill={colors[0]}
        d="M74.4000015,70.0999985L73.5,70.1999969v0.1999969l-0.4000015-0.1999969l-0.4000015-0.4000015 l-0.0999985-0.5l0.5,0.1999969L73.5,69.7999954l1,0.0999985C74.5,70,74.5999985,70.0999985,74.4000015,70.0999985z"
      />
      <path
        fill={colors[0]}
        d="M74.0999985,72.1999969l-0.9000015,0.0999985l-0.0999985,0.1999969h-0.5l-0.4000015-0.5999985 l-0.0999985-0.5l0.5,0.1999969L73,71.8999939l1,0.0999985C74.0999985,72,74.1999969,72.1999969,74.0999985,72.1999969z"
      />
      <path
        fill={colors[0]}
        d="M74.5,69.4000015h-0.8000031l-0.0999985,0.1999969l-0.5-0.0999985l-0.4000015-0.4000015l-0.0999985-0.5 l0.5,0.1999969L73.5,69.0999985l1,0.0999985C74.5999985,69.1999969,74.6999969,69.4000015,74.5,69.4000015z"
      />
      <path
        fill={colors[1]}
        d="M66.5,85.5999985c-5.2999992-0.1999969-15.7999992,14.4000015-16.7000008,17.5 c-0.9000015,3.1999969-1.2000008,6.8000031,3.2000008,9.0999985s10.4000015-2,12.0999985-4.5 C67,105.1999969,70.0999985,86.1999969,66.5,85.5999985z"
      />
      <path
        fill={colors[2]}
        d="M76.4000015,70.9000015l-0.0999985-1l-8.5999985,17c0,0,3.0999985,28-14.5999985,25.4000015 c0,0,11.3000031,6.5999985,16.1999969-8.6999969C70.5,96.4000092,68.9000015,87.8000031,68.9000015,87.8000031"
      />
      <polygon
        fill={colors[3]}
        points="64.6999969,86.0999985 74.1999969,68.9000015 76.5999985,69.5 67.6999969,86.9000015"
      />
      <polygon
        fill={colors[4]}
        points="51.4000015,104.9000015 56.7999992,107.5999985 57.2000008,106.4000015 52,103.8000031"
      />
      <polygon
        fill={colors[0]}
        points="76.3000031,70.0999985 76.4000015,70.9000015 75.6999969,74.3000031 74.8000031,74.0999985"
      />
      <ellipse
        transform="rotate(-58.149 60.439 95.411)"
        fill={colors[0]}
        cx="60.439"
        cy="95.411"
        rx="2.6"
        ry="1.8"
      />
      <g fill={colors[0]}>
        <path d="M76,73.8000031l0.8000031,0.3000031v0.1999969L77.2000046,74.5l0.5-0.3000031l0.1999969-0.4000015 h-0.6999969h-0.3000031l-1-0.0999985C75.9000015,73.5999985,75.8000031,73.8000031,76,73.8000031z" />
        <path d="M76.1999969,71.5999985L77,71.9000015v0.4000015L77.4000015,72.5l0.5-0.5l0.1999969-0.5999985 h-0.6999969l-0.3000031,0.1999969H76.1999969C76.1999969,71.4000015,76.0999985,71.5,76.1999969,71.5999985z" />
        <path d="M76.3000031,70.6999969L77.1000061,71v0.3000031l0.4000015-0.0999985l0.5-0.3000031L78.2000046,70.5 h-0.5999985l-0.5,0.1999969l-1-0.0999985C76.3000031,70.5,76.1999969,70.6999969,76.3000031,70.6999969z" />
        <path d="M76.5,70l0.8000031,0.1999969v0.1999969l0.5,0.0999985l0.5-0.3000031L78.5,69.7999878h-0.5999985 L77.4000015,70l-1-0.0999985C76.5,69.8000031,76.3000031,69.9000015,76.5,70z" />
        <path d="M76.1999969,72.8000031L77,73.1000061v0.3000031l0.5,0.0999985L78,73l0.1999969-0.4000015h-0.5999985 l-0.5,0.1999969h-0.9000015C76.0999985,72.5999985,76,72.8000031,76.1999969,72.8000031z" />
      </g>
      <g fill={colors[5]}>
        <line x1="53.3" y1="105" x2="74.5" y2="69.1" />
        <line x1="53.8" y1="105.2" x2="75.1" y2="69.1" />
        <line x1="54.4" y1="105.3" x2="75.6" y2="69.3" />
        <line x1="54.9" y1="105.6" x2="76.1" y2="69.4" />
      </g>
      <ellipse
        transform="rotate(-58.149 60.439 95.411)"
        fill={colors[0]}
        cx="60.439"
        cy="95.411"
        rx="2.6"
        ry="1.8"
      />
      <polygon
        fill={colors[2]}
        points="76,73.8000031 75.6999969,74.3000031 76.1999969,69.8000031 76.5999985,69.5"
      />
    </g>
  )
}

export default LuteLeft
