import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SlingShotR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SlingShotR") ? (
    <g id="SlingShotR">
      <path d="M41.2000008,101.6999969c2,0,4-0.5999985,4.7999992-0.9000015l2.5999985-3l0.2999992,0.3000031 C50.1999969,96.9000015,50.9999962,95.5,51.1999969,95l0.4000015-5.6999969c0-0.0999985-0.2000008-0.5-0.4000015-1L51,72.4000015 l6.4000015-2.6999969l0.5-4.5999985l-1.0999985-10l-4.0999985-5.5999985l-6.2000008-2.9000015l-7.7999992,1.2000008 c-0.5999985,0.5999985-2.7000008,2.5999985-3.7999992,5.7999992l-0.2000008,0.7000008 c-0.2999992,1.0999985-0.5,2.2000008-0.4000015,3.2999992c-0.0999985,1.0999985-0.3999977,6.8000031-1.8999977,30.3000031 c-0.7000008,1.9000015-0.7000008,4.0999985,0,6.8000031l0.3999977,0.4000015c1.1000023,3.9000015,3.5,5.5,5.3000031,6.0999985 C39.0999985,101.5,40.2000008,101.6999969,41.2000008,101.6999969L41.2000008,101.6999969z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SlingShotR, {
  character: "WomanMixed"
})
