import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Pants from "./Pants"
import Breeches from "./Breeches"
import DarkPatch from "./DarkPatch"
import LightPatch from "./LightPatch"

const CATEGORY = "pants"
const CHARACTER = "ManGnome"
export const IDS = {
  PANTS: "Pants",
  BREECHES: "Breeches",
  DARK_PATCH: "DarkPatch",
  LIGHT_PATCH: "LightPatch"
}

export const components = {
  [IDS.PANTS]: Pants,
  [IDS.BREECHES]: Breeches,
  [IDS.DARK_PATCH]: DarkPatch,
  [IDS.LIGHT_PATCH]: LightPatch
}

export const Group = ({ props }) => (
  <g id="pants">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.PANTS,
    name: "Pants",
    defaultColors: ["#54545F"],
    colorable: true,
    component: components[IDS.PANTS],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.BREECHES,
    name: "Breeches",
    defaultColors: ["#77634F", "#FFFFFF"],
    colorable: true,
    component: components[IDS.BREECHES],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.DARK_PATCH,
    name: "Dark Patch",
    defaultColors: [],
    colorable: false,
    component: components[IDS.DARK_PATCH],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.LIGHT_PATCH,
    name: "Light Patch",
    defaultColors: ["#FFFFFF"],
    colorable: false,
    component: components[IDS.LIGHT_PATCH],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
