import React from "react"

const FlameRight = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameRight">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M52.1863518,84.6040649c-1.1713257-0.3904495-2.2775764-0.7808838-3.2536812-1.2364044 c-0.1952209-0.3253708-0.4555168-0.6507416-0.6507378-0.9761047c-0.5205879-0.8459625-0.9761047-1.6919098-1.3665504-2.602951 c0.0650787-0.2602921,0.1952209-0.5205917,0.2602959-0.7808762c0.1301498-0.3253708,0.3253708-0.6507416,0.4555168-0.9761047 c0.2602959-0.4555206,0.5205879-0.9761047,0.7158089-1.4966965l0.6507378-0.1301498l-0.325367-0.455513l-0.0650749-0.0650635 c1.3665466-0.8459625,2.2125053-1.7569885,2.4728012-2.7330933c0.0650711-0.3253708,0.130146-0.7158127,0.0650711-1.0411835 l0.3904419-0.0650711c0.1301498,0.2602921,0.2602959,0.455513,0.3253708,0.6507416 c0.3904419,0.7808762,0.9761047,1.4966965,1.4966927,2.2124939c0.6507378,0.8459625,1.2363968,1.691925,1.6268425,2.5378799 c0.9761009,2.147438,0.78088,4.2297897-0.6507378,6.7025909C53.6179733,84.4739151,52.9021606,84.6040649,52.1863518,84.6040649z"
        />
        <path
          fill={colors[1]}
          d="M52.1212769,84.6691437c-0.9761047-0.3253708-1.9522095-0.6507416-2.7981682-1.0411835 c-0.1952171-0.2602921-0.3904381-0.5205917-0.5205879-0.8459625c-0.5205879-0.7808838-0.9110298-1.5617599-1.3014717-2.4728012 c0.0650749-0.2602921,0.130146-0.455513,0.2602959-0.6507339c0.130146-0.3253708,0.325367-0.6507339,0.4555168-0.911026 c0.1952171-0.3904495,0.455513-0.8459625,0.6507339-1.3014755l0.9761047-0.1301422l-0.5205917-0.7158203 c1.1713257-0.7808762,1.8871384-1.6268387,2.2125053-2.4728012c0.3253708,0.5205917,0.7158127,0.9761047,1.0411797,1.4966965 c0.5856628,0.8459625,1.1713257,1.6268463,1.5617676,2.4728012c0.9761047,2.0172882,0.7808838,3.9694977-0.5856628,6.312149 C53.0973816,84.5389862,52.6418686,84.6691437,52.1212769,84.6691437z"
        />
        <path
          fill={colors[2]}
          d="M52.0562057,84.7342072c-0.7808876-0.2602921-1.5617676-0.5205917-2.2775803-0.8459549 c-0.130146-0.1952286-0.325367-0.455513-0.4555168-0.6507416c-0.455513-0.7158051-0.8459549-1.4966965-1.2363968-2.2775726 c0.0650711-0.1952286,0.1301498-0.3253708,0.1952209-0.5205917c0.130146-0.3253708,0.325367-0.6507416,0.4555168-0.9110336 c0.1952209-0.3253632,0.3904419-0.7158127,0.5205879-1.1062469l1.3665466-0.1952209l-0.6507378-0.9110413 c0.6507378-0.455513,1.1713257-0.911026,1.5617676-1.4316177c0.1301498,0.1301498,0.1952209,0.2602997,0.3253708,0.455513 c0.585659,0.8459625,1.1713257,1.6268463,1.5617638,2.4077225c0.9110336,1.8220673,0.7158127,3.709198-0.5205879,5.9217072 C52.6418686,84.6040649,52.3815727,84.6691437,52.0562057,84.7342072z"
        />
        <path
          fill={colors[3]}
          d="M51.9911308,84.7342072c-0.5856628-0.1952209-1.1713257-0.3904343-1.7569885-0.6507339 c-0.130146-0.1952209-0.1952209-0.3253708-0.325367-0.5205917c-0.4555168-0.7158051-0.7808838-1.366539-1.1713257-2.1474304 c0.0650711-0.1301498,0.0650711-0.2602921,0.130146-0.3904419c0.1301498-0.3253632,0.3253708-0.5856628,0.4555168-0.9110336 c0.1301498-0.3253708,0.325367-0.5856628,0.4555168-0.911026l1.6919136-0.2602997l-0.7808838-1.0411758 c0.2602959-0.1952209,0.5205879-0.3904419,0.7158127-0.5856705c0.455513,0.6507416,0.9110298,1.3014755,1.2363968,1.9522095 c0.8459587,1.691925,0.6507378,3.4489059-0.3904419,5.4661942 C52.1863518,84.6691437,52.1212769,84.7342072,51.9911308,84.7342072z"
        />
        <path
          fill={colors[4]}
          d="M51.7959099,84.5389862c-0.3253708-0.1301422-0.7158089-0.2602921-1.0411797-0.3904343 c-0.0650749-0.1301498-0.130146-0.2602997-0.2602959-0.3904495c-0.3904381-0.6507339-0.7808838-1.3014679-1.1062508-1.9522095 c0-0.0650635,0.0650749-0.1301422,0.130146-0.2602921c0.1301498-0.3253632,0.3253708-0.5856628,0.4555168-0.9110336 c0.1301498-0.2602921,0.2602959-0.5205917,0.3904419-0.7808838l1.6919174-0.2602921 C52.7720146,81.155159,52.7069397,82.7819977,51.7959099,84.5389862z"
        />
        <path
          fill={colors[5]}
          d="M51.9260559,84.7342072c-0.0650711-0.0650635-0.0650711-0.1301422-0.130146-0.1952209 c-0.3904419-0.5856552-0.7158089-1.1713257-0.9761047-1.8220673c0-0.0650635,0-0.0650635,0.0650749-0.1301422 c0.130146-0.3253708,0.325367-0.5856628,0.4555168-0.911026c0.0650711-0.1952209,0.1952171-0.3904495,0.2602921-0.5856705 l0.6507378-0.1301422C52.6418686,82.1963425,52.5117188,83.4327393,51.9260559,84.7342072 C51.9260559,84.7992859,51.9260559,84.7342072,51.9260559,84.7342072z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M57.3922462,72.8257294c-0.9110336-1.3014755-1.0411797-2.5378723-2.3426514-3.6441193 c-0.0650749,0-0.6507378-0.4555206-0.7158127-0.4555206c-0.130146,0.0650711-0.9110298,0.1301498-0.9110298-0.1301498 c0.1301498-0.6507339,1.1713257-0.8459549,0.8459587-1.4316177c-0.2602959-0.4555206-1.4316216-3.253685-1.7569923-3.7742729 c-0.5205879,1.0411797-2.1474304,3.7092018-3.0584602,4.6202278c-0.0650749,0.0650711-1.3665466,3.3838348-1.8220634,4.8805313 c0.2602959-0.0650787,0.7158127-0.1952286,0.6507378-0.0650787c-0.7158089,2.0172882-2.6680183,3.6441269-3.383831,5.6614075 c0,0.0650711-0.130146,0.1301422-0.2602921,0.1952209c0.2602921,1.2364044,1.3665428,2.2775803,2.6029434,3.1886139 c-0.6507339-1.0411835-1.1713219-2.1474304-1.1713219-3.3187637c0-0.1301422,0.3904381-0.3253632,0.7808838-0.455513 c0.130146-1.3014679,0.325367-2.4728012,1.4966927-3.6441193c1.4966927-1.4316177,2.2125015-3.709198,2.2125015-3.709198 c0.0650749-0.2602997,1.7569923-2.9283142,1.3665504-4.0996399c-0.1301498-0.3253708,0.325367,0.5856628,0.325367,0.8459549 c0.0650749,0,0.1301498,0,0.1301498,0.0650711c0,0.9110336,0.6507339,3.9694977,0.7158089,4.0345688 c1.4316216,1.0411835,1.4966927,1.2364044,2.1474304,2.5378799c0.7808838,1.6268387,1.2364006,2.6680145,1.3014717,4.3599319 c0,0.0650635-0.0650711,0.1301422-0.130146,0.1952209c0.5205879,0.9761047-0.2602959,2.0172806-0.5205917,2.9933853 c-0.0650711,0.1301422-0.0650711,0.2602997-0.1952171,0.455513c0.6507339-0.911026,1.2364006-1.8871307,1.7569885-2.8632355 C57.4573174,77.1205902,57.4573174,74.9731598,57.3922462,72.8257294z"
      />
    </g>
  )
}

export default FlameRight
