import React from "react"

const LongBowLeft = ({
  colors = [
    "#695E60",
    "#42130B",
    "#FFCD03",
    "#EDD692",
    "#B5AC95",
    "#915B25",
    "#A98A30"
  ]
}) => {
  return (
    <g id="LongBowLeft">
      <line
        fill="none"
        stroke={colors[0]}
        strokeWidth="0.14"
        strokeMiterlimit="10"
        x1="56.8"
        y1="101.6"
        x2="118.5"
        y2="57.7"
      />
      <polygon
        fill={colors[1]}
        points="90.5,90.3000031 92.1999969,89.1999969 95.1999969,87 96.9000015,85.8000031 96,83.6999969 93.9000015,85.1999969 90.9000015,87.3000031 88.8000031,88.8000031"
      />
      <polygon
        fill={colors[2]}
        points="90.4000015,90.6999969 87.8000031,89.0999985 88.5,88.5 91.4000015,90.6999969"
      />
      <g fill={colors[3]}>
        <polygon points="95.1999969,87 94.1999969,85 93.9000015,85.0999985 93.3000031,85.5999985 94.4000015,87.5999985" />
        <polygon points="96.1999969,86.3000031 95.0999985,84.3000031 94.4000015,84.8000031 95.4000015,86.8000031" />
        <polygon points="91.1999969,88.5999985 90.5,87.5999985 89.8000031,88 90.1999969,88.5999985" />
        <polygon points="91.0999985,89.9000015 91.8000031,89.4000015 91.1999969,88.5999985 90.3000031,88.6999969" />
        <polygon points="93.5999985,88.1999969 92.3000031,86.3000031 91.5,86.8000031 92.6999969,88.8000031" />
        <polygon points="92.1999969,89.0999985 92.5,88.9000015 91.4000015,86.9000015 90.9000015,87.3000031 90.5999985,87.5 92,89.3000031" />
        <polygon points="90.5999985,90.3000031 90.9000015,90 89.8000031,88.0999985 88.8000031,88.8000031" />
      </g>
      <g fill={colors[4]}>
        <polygon points="93.6999969,88.0999985 94.3000031,87.6999969 93.5,86.3000031 92.5999985,86.5" />
        <polygon points="92.4000015,86.1999969 92.5,86.4000015 93.4000015,86.1999969 93.0999985,85.6999969" />
      </g>
      <g fill={colors[4]}>
        <polygon points="96.4000015,86.1999969 96.9000015,85.8000031 96.6999969,85.4000015 96.0999985,85.5999985" />
        <polygon points="95.3000031,84.1999969 96,85.5 96.6999969,85.3000031 96,83.5999985" />
      </g>
      <path
        fill={colors[5]}
        d="M117.6999969,58.7999992c-0.5999985,1.4000015-1.4000015,5-2.0999985,8.7000008 c-1.5999985,7.6999969-11,12-16.2000046,14.5999985c-1.1999969,0.5999985-2.1999969,1.0999985-2.9000015,1.5l0.4000015,2 c0.5-0.4000015,1.9000015-1.0999985,3.4000015-1.9000015C105.6999969,81,115.4999924,75.5999985,116.6999969,67.0999985 c0.4000015-3.0999985,1-7.9000015,1.5-8.4000015s0.5999985-0.5,0.5999985-0.5s0.3000031-0.7000008,0-0.7999992 l-0.4000015,0.2000008C118.3000031,57.5999985,118.0999985,57.9000015,117.6999969,58.7999992z"
      />
      <ellipse
        transform="rotate(-13.733 96.697 84.502)"
        fill={colors[6]}
        cx="96.697"
        cy="84.502"
        rx="0.6"
        ry="1.5"
      />
      <polygon
        fill={colors[2]}
        points="97.3000031,85.9000015 96.5999985,82.8000031 95.8000031,83.4000015 96.9000015,86.8000031"
      />
      <path
        fill={colors[5]}
        d="M58.2000008,101.0999985C59.7000008,101,63.4000015,101.5,67.0999985,102 c7.8000031,1.0999985,15-6.5,19.0999985-10.5c1-1,1.8000031-1.6999969,2.4000015-2.1999969l1.8000031,1.0999985 C89.9000015,90.7000046,88.7000046,91.8000031,87.5,93c-4.3000031,4.1999969-12.6999969,11.5999985-21.0999985,10 c-3.0999985-0.6999969-7.7999992-1.6999969-8.4000015-1.4000015C57.4000015,101.9000015,57.4000015,102,57.4000015,102 s-0.7000008,0-0.7999992-0.3000031l0.2999992-0.3000031C56.7999992,101.3000031,57.0999985,101.1999969,58.2000008,101.0999985z"
      />
      <ellipse
        transform="rotate(-57.044 89.34 89.77)"
        fill={colors[6]}
        cx="89.339"
        cy="89.77"
        rx="0.6"
        ry="1.5"
      />
      <polygon
        fill={colors[6]}
        points="90.6999969,90.1999969 88.5,88.5 89,88.3000031 91.0999985,89.9000015"
      />
      <polygon
        fill={colors[6]}
        points="96.5,86.0999985 95.5999985,83.5 95.8000031,83.4000015 96.5999985,86"
      />
    </g>
  )
}

export default LongBowLeft
