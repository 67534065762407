import React from "react"
import { withSkinColor } from "../../builderConnector"

const WideHorns = ({ skinColor, color = [] }) => {
  return (
    <g id="WideHorns">
      <path
        id="K_x24_wideHorns"
        fill={skinColor}
        d="M77.1070633,10.4098654L77.1070633,10.4098654 c-0.1693192-0.3386364-0.3386383-0.5926142-0.5079575-0.8465919c-0.1693115-0.1693182-0.4232941-0.3386374-0.6772766-0.423296 c-1.8625031-0.3386374-3.6403503,2.9630728-4.5716019,3.8943243c-0.3386383,0.423296-0.6772766,0.1693182-0.9312439,0.2539778 c-0.1693192,0-0.9312515,0.0846596-1.7778473,0.1693182c-0.7619324-0.1693182-1.5238647-0.1693182-1.6931839-0.1693182l0,0 c0,0,0,0-0.0846558,0s-0.2539825,0-0.3386383,0c-0.1693192,0-0.3386383-0.0846596-0.5079575-0.2539778 c-0.7619324-0.9312525-2.5397797-3.8943253-4.3176231-3.8943253c-0.0846558,0-0.2539749,0-0.3386383,0 c-0.5926132,0.3386374-0.9312477,0.7619333-1.1852303,1.2698889l0,0 c-0.3386383,1.1005697,0.3386383,2.3704586,0.3386383,2.3704586s-0.2539749-2.0318222,0.5079536-1.7778435 c0.0846596,0,0.1693192,0.0846596,0.2539787,0.1693182c0.0846596,0.0846596,0.0846596,0.0846596,0.1693192,0.1693182 c0.4232979,0.3386364,0.9312515,1.1005697,1.6931839,2.3704586c0.3386383,0.5926142,1.3545456,2.0318222,5.5028534,2.0318222 s5.2488708-1.6931849,5.5028534-2.0318213c0.7619324-1.2698889,1.2698898-2.0318213,1.6931839-2.3704586 c0.0846558-0.0846596,0.0846558-0.0846596,0.1693115-0.1693182c0.0846634-0.0846596,0.2539825-0.1693182,0.2539825-0.1693182 c0.7619324-0.2539787,0.5079575,1.7778425,0.5079575,1.7778425S77.4457016,11.5104361,77.1070633,10.4098654z"
      />
    </g>
  )
}

export default withSkinColor(WideHorns, { character: "WomanMixed" })
