import React from "react"

const Mouth = ({ colors = ["#010101"] }) => {
  return (
    <g id="Mouth">
      <path
        fill={colors[0]}
        d="M51.0376015,61.268158c0,0-2.0421867,0.4724617-3.6142235,0.4456406 c-1.5720329-0.0268211-3.4063454-0.4456406-3.4063454-0.4456406c0.2389526,0.0880127,1.9598808,0.7138557,3.4063454,0.7138557 C48.8698463,61.9820137,50.7986488,61.3561707,51.0376015,61.268158z"
      />
      <polygon
        opacity="0.42"
        fill="#21376C"
        points="46.5218849,62.2041893 46.2402573,62.847908 48.8151207,62.847908 48.4530296,62.2041893"
      />
    </g>
  )
}

export default Mouth
