import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LuteRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LuteRight") ? (
    <g id="LuteRight">
      <path
        id="S_x24_luteRight"
        d="M40.4404793,112.8647308c3.1979065,0,5.741703-0.2907104,6.4684944-0.7267914 c2.7618332-1.453598,7.0499382-5.0149078,4.7968636-12.5735931c-0.7994766-2.8345108-6.5411797-11.3380356-11.9194756-14.7539902 l-6.0324249-10.829277l-5.4509792-2.8345108l-3.0525455,1.8169861c0,0-3.6339874,2.180397-3.6339874,3.5613098 s2.5437927,8.1401291,2.5437927,8.1401291l3.4159412,5.9597397c-0.3633957,2.4711075-0.7994747,7.1952972,0,11.6287613 l0.145359,0.6541138C28.8117199,106.1781921,32.1549835,112.8647308,40.4404793,112.8647308z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LuteRight, { character: "ManGnome" })
