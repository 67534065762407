import React from "react"

const Turban = ({ colors = ["#785C8E", "#96842D"] }) => {
  return (
    <g id="Turban">
      <path
        fill={colors[0]}
        d="M75.8133392,11.6439915c-0.4763184-0.4763212-3.6200485-2.1910791-7.33535-0.5715857 c-3.8105698-1.2384367-5.9063873-0.3810577-6.8590317,0.2857924c-0.8573799,0.3810568-0.5715828,1.52423-0.4763184,1.6194935 c0,0,0.3810577,2.2863436,1.1431694,2.8579292c0.0952644,0.3810577,0.3810616,1.1431713,0.5715866,1.1431713 c2.3816109-2.0958138,5.5253334-2.9531927,5.5253334-2.9531927c2.0958099,0.2857933,5.7158585,2.9531946,5.7158585,2.9531946 s0.2857971-0.666851,0.4763184-1.1431723c0.6668472-0.5715857,0.9526443-2.0005503,1.1431732-2.4768715 C75.9085999,12.7871637,76.2896652,12.1203136,75.8133392,11.6439915z"
      />
      <circle fill={colors[1]} cx="68.478" cy="12.501" r="2.096" />
    </g>
  )
}

export default Turban
