import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SpellBookL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SpellBookL") ? (
    <g id="SpellBookL">
      <path d="M73.6670761,79.6130981l0.5069427,5.5763474c0,0,8.8352432,3.7296295,11.3699341,2.8605881 c2.5347061-0.8690414,9.5232391-7.6765213,9.5594482-7.7851562c0.0362091-0.1086273-0.3258896-6.264328-0.3258896-6.264328 l-11.9493027,3.9831009L73.6670761,79.6130981z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SpellBookL, {
  character: "WomanGnome"
})
