import React from  'react'
import { connect } from 'react-redux'
import { loading } from './selectors/apiSelectors'

// function getModalStyle() {
//   const top = 50;
//   const left = 50;

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//     display: `flex`,
//     justifyContent: 'center'
//   };
// }

const LoadingModal = ({ classes, loading = false, ...props }) =>
  loading
    ?
      <div
        className="modal"
        style={{ display: "flex" }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    : null

const mapStateToProps = state => ({
  loading: loading(state)
})

export default connect(mapStateToProps)(LoadingModal)