import React from "react"
import { withSkinColor } from "../../builderConnector"

const RightHand = ({ skinColor, color = [] }) => {
  return (
    <g id="RightHand">
      <path
        fill={skinColor}
        d="M47.6835709,54.5156822c-0.0880241,0.1760521-2.9928894-0.4401321-4.8414345,0.9682846 c-0.4401321,0.5281563,0,0.9682846,0.0880241,1.1443405c0,0.1760521-0.3521042,0.5281525-0.3521042,0.8802605 c0,0.3521042,0.4401283,0.6161842,0.4401283,0.8802605s-0.3521042,0.4401283-0.3521042,0.8802605 s0.9682846,1.0563126,1.1443367,1.5844688c0,0.1760521,0,0.5281563,0.26408,0.7922363 c0.2640762,0.26408,1.7605209,0.0880241,1.7605209,0.0880241l2.6407814-1.0563126l0.352108-0.2640762 c0,0,0.1760521-0.4401321,0.0880241-0.7042084c0-0.26408-0.3521042-0.4401321-0.3521042-0.4401321l0.2640762-0.1760521 c0,0,0.3521042-0.4401321,0.3521042-0.7042084s-0.3521042-0.6161842-0.3521042-0.6161842h-0.4401245l0.352108-0.0880241 c0,0,0.4401283-0.4401321,0.4401283-0.7922363s-0.5281563-0.7922363-0.5281563-0.7922363h-0.7042084l0.4401283-0.1760483 c0,0,0.352108-0.4401321,0.352108-0.8802643C48.6518593,54.6917305,47.9476509,54.5156822,47.6835709,54.5156822z"
      />
    </g>
  )
}

export default withSkinColor(RightHand, { character: "WomanMixed" })
