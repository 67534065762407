import { apiRoutine } from '../routines'

export const apiRequestAction = (method, key, data) => ({
  method,
  key,
  data
})

const apiReducer = (state = { data: {}, successfulCalls: {} }, action) => {
  switch (action.type) {
    case apiRoutine.TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case apiRoutine.SUCCESS:
      return {
        ...state,
        error: null,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.data
        },
        successfulCalls: {
          ...state.successfulCalls,
          [action.payload.key]: incrementCalls(state.successfulCalls[action.payload.key])
        }
      };
    case apiRoutine.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case apiRoutine.FULFILL:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

function incrementCalls(calls = 0) {
  return calls + 1
}

export default apiReducer