import React from "react"

const BlackBoots = ({
  colors = [
    "#616161",
    "#FFFFFF",
    "#352515",
    "#454443",
    "#919091",
    "#454545",
    "#525252"
  ]
}) => {
  return (
    <g id="BlackBoots">
      <g id="blackBoots">
        <path
          fill={colors[0]}
          d="M36.9450569,113.5686417c0,0-2.2871399,2.9180756-2.3660088,3.785614 c0,0.552063,0.1577377,1.9716721,0.1577377,1.9716721h10.3315544c0,0,0.7886696-1.3407364,0.236599-2.3660049 c-0.5520668-1.0252762-1.1830025-3.785614-1.1830025-3.785614L36.9450569,113.5686417z"
        />
        <path
          fill={colors[0]}
          d="M60.4873199,113.6491089c0,0,2.2871399,2.9180679,2.3660049,3.785614 c0,0.552063-0.1577301,1.9716644-0.1577301,1.9716644h-10.331562c0,0-0.7886696-1.3407288-0.236599-2.3660049 c0.5520668-1.0252686,1.1830025-3.7856064,1.1830025-3.7856064L60.4873199,113.6491089z"
        />
        <polygon
          fill={colors[0]}
          points="44.2008057,115.3825836 36.9450569,114.1995773 36.6295853,109.0732346 35.6831856,105.2876205 44.7528725,105.0510254 43.9642029,109.8619003"
        />
        <polygon
          fill={colors[0]}
          points="53.2315674,115.4630508 60.4084549,114.2800446 60.7239189,109.1537018 61.6703224,105.3680878 52.0585594,104.9833298 52.9168472,110.3476334"
        />
        <path
          opacity="0.2"
          fill="#FFF"
          d="M38.2069244,112.8588486 c0,0-2.6026077,1.8139343-1.7350731,3.1546707c0.3154716,0.7098007,2.129406,1.9716721,4.101078,1.892807 c2.2871399-0.0788651,3.6278763-1.813942,3.6278763-1.813942s0-2.1294022-1.1830063-3.0758057 C41.8347969,112.0701752,38.2069244,112.8588486,38.2069244,112.8588486z"
        />
        <polygon
          fill={colors[2]}
          points="45.0683403,119.6413956 34.7367821,119.6413956 34.5790482,119.089325 45.1472054,119.089325"
        />
        <path
          fill="#FFF"
          d="M42.3080025,116.4867172c-0.0788651,0.0788727-3.4701424,0.0788727-3.5490112,0 c-0.0788651-0.0788651,1.1041374-0.6309357,1.7350731-0.6309357S42.3868675,116.4078522,42.3080025,116.4867172z"
        />
        <path
          fill={colors[3]}
          d="M42.4657364,115.8557816c-0.8675346-0.1577301-2.8392067-0.1577301-3.7067451,0 c-0.236599-0.8675308-0.6309319-9.7794876-0.8675346-10.6470261c1.2618713-0.2366028,3.5490112-0.3943329,4.7320137,0.0788651 C42.3868675,106.1551666,42.7023354,114.9882507,42.4657364,115.8557816z"
        />
        <path
          fill="#FFF"
          d="M42.1502686,115.7769165c-0.6309357-0.1577301-2.5237427-0.1577301-3.1546745,0 c-0.1577339-0.8675308-0.5520706-9.6217499-0.7098045-10.489296c0.9464035-0.1577301,3.0758095-0.1577301,4.0222092,0 C42.1502686,106.1551666,42.3080025,114.8305206,42.1502686,115.7769165z"
          opacity="0.2"
        />
        <path
          fill={colors[3]}
          d="M38.2069244,115.4614487c-0.236599,0-0.5520706-0.3154678-0.6309357-0.4731979 c-0.0788651-0.0788651-0.3943329-0.0788651-0.3154678-0.2366028c0.0788651-0.0788651-0.3154678,0.1577301-0.3154678,0.1577301 c-0.0788651,0.2366028,0.236599,0.552063,1.2618675,0.7886658c1.1041374-0.6309357,3.4701424-0.7098007,4.4165421-0.3154678 v-0.2366028C41.6770668,114.6727829,39.232193,114.7516479,38.2069244,115.4614487z"
        />
        <path
          fill={colors[3]}
          d="M42.6234703,115.0671158v0.1577301l0,0 C42.6234703,115.1459808,42.6234703,115.1459808,42.6234703,115.0671158L42.6234703,115.0671158z"
        />
        <path
          fill={colors[4]}
          d="M42.8600693,114.1995773c0-0.0788651,0-0.0788651,0-0.1577301 c-1.1830025-0.4731979-4.6531448-0.3154678-5.9938812,0.4732056c0,0,0,0.0788651-0.0788651,0.0788651 C38.2069244,113.8841095,41.5981979,113.7263794,42.8600693,114.1995773z"
        />
        <path
          fill={colors[4]}
          d="M42.8600693,114.1995773v-0.1577301l0,0 C42.8600693,114.1207123,42.8600693,114.1995773,42.8600693,114.1995773L42.8600693,114.1995773z"
        />
        <path
          opacity="0.55"
          fill="#FFF"
          d="M37.4971237,115.2248535 c0.0788651,0.1577301,0.5520706,0.2366028,0.7098045,0.2366028c1.0252686-0.6309357,3.4701424-0.7886734,4.4165421-0.3943329 l0.0788651-0.2366028c0.0788651-0.1577377,0.0788651-0.3943405,0.1577339-0.6309433 c-1.2618675-0.4731979-4.258812-0.3154678-5.5995445,0.4732056 C37.1816559,114.9093857,37.4182549,115.0671158,37.4971237,115.2248535z"
        />
        <path
          fill={colors[4]}
          d="M42.6234703,115.0671158L42.6234703,115.0671158c0-0.0788651,0-0.1577301,0.0788651-0.2366028 L42.6234703,115.0671158z"
        />
        <path
          fill={colors[4]}
          d="M42.8600693,114.1995773c-0.0788651,0.2366028-0.0788651,0.3943405-0.1577339,0.6309433 L42.8600693,114.1995773L42.8600693,114.1995773z"
        />
        <g>
          <path
            fill={colors[5]}
            d="M38.6801262,113.7263794c0.0788651,0.6309357,0.1577339,0.9464035,0.236599,1.5773392 c0.7886696-0.1577301,2.7603416-0.1577301,3.5490112-0.0788651c0.0788651-0.6309357,0.0788651-0.9464035,0.1577339-1.5773392 C41.5981979,113.5686417,39.5476608,113.5686417,38.6801262,113.7263794z M42.2291374,114.9093857 c-0.6309357-0.0788651-2.5237427-0.0788651-3.1546745,0.0788651 c-0.0788689-0.3943329-0.0788689-0.6309357-0.1577377-1.0252762 c0.7098045-0.1577301,2.6814766-0.1577301,3.3912773-0.0788651 C42.3080025,114.27845,42.2291374,114.5150528,42.2291374,114.9093857z"
          />
          <path
            fill="#FFF"
            d="M38.6801262,113.6475143c0.0788651,0.4731979,0.0788651,0.7098007,0.1577339,1.1041412 c0.0788651,0.2366028,0.3154716,0.4731979,0.5520706,0.3943329c0.9464035-0.0788651,2.3660049-0.0788651,3.0758095,0 l0.1577339-1.5773392C41.5981979,113.4109116,39.5476608,113.4109116,38.6801262,113.6475143z M42.2291374,114.7516479 c-0.6309357-0.0788651-2.5237427-0.0788651-3.1546745,0.0788651 c-0.0788689-0.3943329-0.0788689-0.6309357-0.1577377-1.0252762 c0.7098045-0.0788651,2.6814766-0.0788651,3.3912773-0.0788651 C42.3080025,114.1995773,42.2291374,114.3573151,42.2291374,114.7516479z"
          />
        </g>
        <g>
          <path
            fill={colors[6]}
            d="M37.8125916,107.7324982c0,0,3.3124084,0.6309433,5.4418144-0.0788651 c0.0788651,0.5520706,0,1.2618713,0,1.2618713s-4.258812,0.7098007-5.4418144-0.0788651 C37.7337227,108.3634338,37.8125916,107.7324982,37.8125916,107.7324982z"
          />
          <path
            opacity="0.55"
            fill="#FFF"
            d="M37.7337227,107.5747604 c0,0,3.3124084,0.6309433,5.4418144-0.0788651c0.0788689,0.5520706,0,1.2618713,0,1.2618713 s-4.258812,0.7098007-5.4418144-0.0788651C37.7337227,108.2845688,37.7337227,107.5747604,37.7337227,107.5747604z"
          />
        </g>
        <g>
          <path
            fill={colors[6]}
            d="M38.2069244,110.8871765c0,0,2.9969406,0.6309357,4.6531448-0.0788651 c0,0.552063,0,1.2618637,0,1.2618637s-3.7067413,0.7098083-4.6531448-0.0788651 C38.1280594,111.5969696,38.2069244,110.8871765,38.2069244,110.8871765z"
          />
          <path
            opacity="0.55"
            fill="#FFF"
            d="M38.1280594,110.7294388 c0,0,2.9969406,0.6309357,4.6531448-0.0788651c0,0.552063,0,1.2618637,0,1.2618637s-3.7067413,0.7098083-4.6531448-0.0788651 C38.1280594,111.4392395,38.1280594,110.7294388,38.1280594,110.7294388z"
          />
        </g>
        <g>
          <path
            opacity="0.2"
            fill="#FFF"
            d="M59.1465836,112.9393082 c0,0,2.6026039,1.813942,1.7350693,3.1546783c-0.3154678,0.7098007-2.129406,1.9716721-4.101078,1.892807 c-2.2871399-0.0788651-3.6278725-1.813942-3.6278725-1.813942s0-2.1294022,1.1830025-3.0758057 C55.5187073,112.2295074,59.1465836,112.9393082,59.1465836,112.9393082z"
          />
          <polygon
            fill={colors[2]}
            points="52.3640327,119.7218628 62.6955948,119.7218628 62.7744598,119.1697845 52.2062988,119.1697845"
          />
          <path
            fill="#FFF"
            d="M55.1243744,116.5671844c0.0788651,0.0788651,3.4701424,0.0788651,3.5490074,0 s-1.1041374-0.6309357-1.7350731-0.6309357C56.3073769,115.9362488,55.0455055,116.4883194,55.1243744,116.5671844z"
          />
          <path
            fill={colors[3]}
            d="M54.8877716,115.9362488c0.8675346-0.1577301,2.8392067-0.1577301,3.7067451,0 c0.236599-0.8675385,0.6309319-9.7794952,0.8675346-10.6470261 c-1.2618713-0.2366028-3.5490112-0.3943329-4.7320137,0.0788651 C54.9666405,106.2356262,54.7300377,115.0687103,54.8877716,115.9362488z"
          />
          <path
            fill="#FFF"
            d="M55.2032394,115.8573837c0.6309319-0.1577301,2.5237389-0.1577301,3.1546745,0 c0.1577339-0.8675385,0.5520668-9.6217575,0.7098007-10.489296c-0.9463997-0.1577301-3.0758057-0.1577301-4.0222054,0 C55.2821083,106.2356262,55.0455055,114.9109802,55.2032394,115.8573837z"
            opacity="0.2"
          />
          <path
            fill={colors[3]}
            d="M59.1465836,115.5419159c0.236599,0,0.5520668-0.3154678,0.6309319-0.4732056 c0.0788651-0.0788651,0.3943367-0.0788651,0.3154678-0.2366028 c-0.0788651-0.0788651,0.3154716,0.1577377,0.3154716,0.1577377c0.0788651,0.2366028-0.2366028,0.552063-1.2618713,0.7886658 c-1.1041374-0.6309357-3.4701424-0.7098007-4.4165421-0.3154678v-0.2366028 C55.6764412,114.7532425,58.121315,114.8321075,59.1465836,115.5419159z"
          />
          <path
            fill={colors[3]}
            d="M54.7300377,115.147583v0.1577301l0,0 C54.7300377,115.2264481,54.7300377,115.2264481,54.7300377,115.147583L54.7300377,115.147583z"
          />
          <path
            fill={colors[4]}
            d="M54.5723038,114.2800446c0-0.0788651,0-0.0788651,0-0.1577301 c1.1830025-0.4731979,4.6531448-0.3154678,5.9938812,0.4731979c0,0,0,0.0788651,0.0788651,0.0788651 C59.2254486,113.9645767,55.7553062,113.8068466,54.5723038,114.2800446z"
          />
          <path
            fill={colors[4]}
            d="M54.5723038,114.2800446v-0.1577301l0,0 C54.4934387,114.2011795,54.5723038,114.2800446,54.5723038,114.2800446L54.5723038,114.2800446z"
          />
          <path
            opacity="0.55"
            fill="#FFF"
            d="M59.9352493,115.3053131 c-0.0788651,0.1577377-0.5520668,0.2366028-0.7098007,0.2366028 c-1.0252686-0.6309357-3.4701424-0.7886658-4.4165459-0.3943329l-0.0788651-0.2366028 c-0.0788651-0.1577301-0.0788651-0.3943329-0.1577339-0.6309357c1.2618675-0.4731979,4.258812-0.3154678,5.5995483,0.4731979 C60.2507172,114.9898453,60.0141182,115.147583,59.9352493,115.3053131z"
          />
          <path
            fill={colors[4]}
            d="M54.7300377,115.147583L54.7300377,115.147583c0-0.0788727,0-0.1577377-0.0788651-0.2366104 L54.7300377,115.147583z"
          />
          <path
            fill={colors[4]}
            d="M54.5723038,114.2800446c0.0788651,0.2366028,0.0788651,0.3943329,0.1577339,0.6309357 L54.5723038,114.2800446L54.5723038,114.2800446z"
          />
          <g>
            <path
              fill={colors[5]}
              d="M54.8089027,113.8068466c0.0788689,0.6309357,0.0788689,0.9463959,0.1577377,1.5773392 c0.7886658-0.1577377,2.7603378-0.0788727,3.5490074,0.0788651c0.0788651-0.6309433,0.1577339-0.9464035,0.236599-1.5773392 C57.8058434,113.6491089,55.7553062,113.6491089,54.8089027,113.8068466z M55.0455055,113.9645767 c0.7098007-0.0788651,2.6814728-0.0788651,3.3912735,0.0788651 c-0.0788651,0.3943329-0.0788651,0.6309357-0.1577339,1.0252686 c-0.6309357-0.0788651-2.5237389-0.1577301-3.1546745-0.0788651 C55.1243744,114.5955124,55.1243744,114.3589096,55.0455055,113.9645767z"
            />
            <path
              fill="#FFF"
              d="M54.8089027,113.6491089l0.1577377,1.5773392c0.6309319-0.0788651,2.129406-0.0788651,3.0758057,0 c0.2366028,0,0.4732018-0.1577377,0.5520706-0.3943405c0.0788651-0.3154678,0.0788651-0.6309357,0.1577339-1.1041336 C57.8058434,113.4913788,55.7553062,113.4913788,54.8089027,113.6491089z M55.0455055,113.8857117 c0.7098007-0.0788651,2.6814728-0.0788651,3.3912735,0.0788651 c-0.0788651,0.3943329-0.0788651,0.6309357-0.1577339,1.0252686 c-0.6309357-0.0788651-2.5237389-0.1577301-3.1546745-0.0788651 C55.1243744,114.4377747,55.1243744,114.2800446,55.0455055,113.8857117z"
            />
          </g>
          <g>
            <path
              fill={colors[6]}
              d="M59.6197853,107.8129654c0,0-3.3124084,0.6309357-5.4418144-0.0788651 c-0.0788651,0.552063,0,1.2618713,0,1.2618713s4.258812,0.7098007,5.4418144-0.0788727 C59.6197853,108.4438934,59.6197853,107.8129654,59.6197853,107.8129654z"
            />
            <path
              opacity="0.55"
              fill="#FFF"
              d="M59.6197853,107.6552277 c0,0-3.3124084,0.6309357-5.4418144-0.0788651c-0.0788651,0.552063,0,1.2618713,0,1.2618713 s4.258812,0.7098007,5.4418144-0.0788651C59.6986504,108.3650284,59.6197853,107.6552277,59.6197853,107.6552277z"
            />
          </g>
          <g>
            <path
              fill={colors[6]}
              d="M59.2254486,110.9676361c0,0-2.9969368,0.6309433-4.6531448-0.0788651 c0,0.5520706,0,1.2618713,0,1.2618713s3.7067451,0.7098007,4.6531448-0.0788651 C59.2254486,111.6774368,59.2254486,110.9676361,59.2254486,110.9676361z"
            />
            <path
              opacity="0.55"
              fill="#FFF"
              d="M59.2254486,110.809906 c0,0-2.9969368,0.6309357-4.6531448-0.0788727c0,0.5520706,0,1.2618713,0,1.2618713s3.7067451,0.7098007,4.6531448-0.0788651 C59.3043175,111.5197067,59.2254486,110.809906,59.2254486,110.809906z"
            />
          </g>
        </g>
      </g>
    </g>
  )
}

export default BlackBoots
