// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:79fd5927-011c-455b-b6f2-b57a6aa2b58e",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_bAWti3yCJ",
    "aws_user_pools_web_client_id": "7t36tofes7e4r2gnhmhigrp9n0",
    "aws_content_delivery_bucket": "pawnbuilder-20181224170030-hostingbucket",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d3mosn99tgh01m.cloudfront.net"
};


export default awsmobile;
