import React from "react"

const Tunic = ({ colors = ["#DDD4BA", "#262261", "#604A23"] }) => {
  return (
    <g id="Tunic">
      <path
        fill={colors[0]}
        d="M92.9,45.7c-1.1-1.6-2.8-6-3.2-7.9c-0.7-2.2-1.5-5.4-2.9-6.7c-0.3-0.6-0.1-0.7-0.6-1.2 c-0.6-0.3-5.5-1.6-6.2-1.9c-1.1-0.4-4.7-2.2-5.9-2.1V26c0.6,0.2,2.4,0.8,2.5,1.1c-4.4,0.8-7.3,9-7.5,9.1H69 c-0.3-0.3-3.1-8.2-7.5-9c0.1-0.2,1.9-0.9,2.5-1.1V26c-1.2-0.1-4.9,1.7-5.9,2.1c-0.4,0.2-3.1,0.7-4.9,1.5c-1.3,0.6-1.6,1.5-1.9,1.6 c-1.8,2.2-2,3.9-2.8,6.7c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.6-0.4,1.3-0.6,1.9c-0.3,0.8-0.6,1.7-1,2.6c-0.3,0.7-0.6,1.3-0.9,1.9 c-0.2,0.4-0.4,0.7-0.5,0.9c0,0.1-0.1,0.1-0.1,0.1c-0.2,0.2-0.4,0.5-0.6,0.7c-0.1,0.1-0.2,0.2-0.3,0.2c-1.4,1.5-2.4,2.6-2,4.4 c0.4-0.6,1-0.9,3.2-0.1c2.2,0.8,1.9,2.6,1.7,3.4c0.1-0.1,0.2-0.1,0.3-0.1l0,0c0.7-0.4,1.3-1,1.7-1.8c0.1-0.2,0.7-1.6,1.3-2.9 c0.3-0.8,0.6-1.6,0.8-2.1c0.1-0.2,0.3-0.5,0.4-0.7c0.8-1.3,3.5-4.6,3.3-5.9c0.5,0.9,1.8,3.9,2.1,5.2c0.4,0.6,2.1,6.7,1.5,9.7 c1.8,0.6,6.4,1.5,10.2,1.5l0.1-0.8l0.1,0.8c4.5,0.3,8.6-0.9,10.4-1.5c-0.7-3.9,0.7-8.2,1.6-11.7c0-0.2,0.2-0.3,0.3-0.4 c0.2-0.9,0.5-1.7,0.8-2.2c0.1,0,0.5-0.7,0.7-0.5c0.8,2.3,3.5,6.1,3.6,6.3s2.2,4.9,2.3,5.2c0.4,1,1.1,1.6,2,2 c-0.1-0.7-0.5-2.6,1.7-3.5c2.2-0.8,2.8-0.5,3.2,0.1C96.4,49,94.9,47.9,92.9,45.7z"
      />
      <path
        opacity="0.2"
        fill={colors[1]}
        d="M69.4,39.4L69.4,39.4c0-0.1,0-0.2,0-0.2v-0.1h0.1 c0.7-2.8,0.9-5.8,2.8-8.2c1.3-1.6,2.9-2.8,4.6-3.9c0,0,0-0.1,0.1-0.1c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.6,0.2-0.9,0.1 c-1,0.6-1.8,1.1-2.6,1.9c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.3 c-0.3,0.4-0.5,0.8-0.8,1.2c-0.1,0.2-0.3,0.3-0.4,0.5c-0.4,0.5-0.7,1.1-1,1.7c-0.4,0.6-0.7,2-1,2.7c-0.5-1.1-1.4-3-1.9-4.1 c-1-1.8-2.3-3-3.9-4.1c-0.3-0.3-0.7-0.6-0.9-0.7h-0.1c-0.3,0.2-0.6,0.4-1,0.6c2,0.4,3.7,2.6,4.7,4.3c0.1,0.2,0.2,0.4,0.3,0.6 c1,1.4,1.8,2.9,2.1,4.7c0,0-0.1,0-0.1,0.1c0.3,0.9,0.4,1.9,0.7,2.8C69.2,39.7,69.4,39.6,69.4,39.4z"
      />
      <path
        opacity="0.21"
        fill="#21376C"
        d="M58.2,48.4L58.2,48.4c-0.4-2.1-0.8-4.2-1.1-6.3 c-1-1.3-1.1-3.2-0.8-5c-0.1,0-0.2,0-0.3-0.1c-0.3,0.6-0.7,1.3-1,1.9c0,1.6-0.6,3.3-1.5,4.7s-2.6,3.3-4.1,1.7 c-0.2,0.3-0.8,0.3-0.8,0.3h-0.2l0,0c1,0.3,1.9,0.7,2.1,1.9c0.2,1.5-0.9,2.6-1.6,3.8c-0.4,0.6-3.9-2.9-5.6-1.5h-0.1 c0.5-1.6,1.8-2.9,3.3-3.8c0.1,0.1,0.2,0.1,0.3,0.1c-0.2-0.1-0.3-0.3-0.2-0.5c0.6-1.6,1.1-3.2,1.5-4.9c-0.3,0.8-1.9,4.4-1.9,4.4 c-0.3,0.6-2.5,2.7-2.6,2.7c-0.8,0.9-1.5,1.8-1.1,3.1c0.1,0,4.7-0.7,4.9,3.4c2.1-1.3,3.4-5.8,4.2-7.1c1.2-1.8,3.1-4.4,3.7-6.4 C56,40.1,58,47.5,58.2,48.4z"
      />
      <path
        opacity="0.21"
        fill="#21376C"
        d="M95.1,47.9c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.7-0.5-1-0.8 c-0.1,0-0.1-0.1-0.1-0.1c-0.5-0.5-0.8-1.1-1.2-1.7l0,0c-0.1,0-0.1-0.1-0.2-0.1c0.1,0,0.1,0,0.2-0.1c-0.3-0.6-0.6-1.2-0.9-1.8 c-0.4-0.8-0.7-1.6-1-2.4c0.4,1.6,1,3.2,1.5,4.9c0.1,0.2,0,0.4-0.2,0.5c0.1,0,0.2,0,0.3-0.1c1.5,0.9,2.8,2.2,3.3,3.8h-0.1 c-0.9-0.1-1.7,0.2-2.5,0.5c-0.5,0.2-1.1,0.5-1.6,0.8c0,0,0,0-0.1,0l0,0c-0.2,0.6-1,0.8-1.4,0.2c-0.7-1.2-1.8-2.3-1.6-3.8 c0.2-1.2,1-1.6,2.1-1.9l0,0h-0.1h-0.1l0,0c-0.2,0-0.5-0.1-0.8-0.3c-1.4,1.6-3.2-0.3-4.1-1.7s-1.5-3-1.5-4.7 c-0.3-0.6-0.7-1.3-1-1.9c-0.1,0-0.2,0.1-0.3,0.1c0.2,1.8,0.2,3.7-0.8,5c-0.1,0.9-0.3,1.9-0.4,2.8c0.1-0.1,0.1-0.2,0.2-0.3 c0-0.1,0.1-0.1,0.2-0.1c0.1-0.6,0.3-1.2,0.6-1.8c0.1-0.2,0.4-1.3,0.6-1.3c0,0,0.2,0,0.9,0.7c1.4,1.7,2.5,3.6,3.6,5.5 c0.9,1.5,1.7,5.9,3.4,6.7c0.1,0,0.2,0.1,0.4,0.1c0.2,0,0.3,0.1,0.4,0.2c0,0,0-0.1,0.1-0.1c-0.4-1-0.1-2,1-2.7 c0.9-0.6,2.1-0.8,3.1-0.5c0.1,0,0.2,0,0.2,0l0,0c0.1,0,0.2,0,0.3,0C96.3,50,96,48.8,95.1,47.9z"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#21376C"
        d="M51.2,30.2 c0,0,0.2-0.2,0.6,0.1l-0.5,0.6C51.3,30.9,51.1,30.6,51.2,30.2z"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#21376C"
        d="M62,26v0.4l2.2-0.5 v-0.4C64.2,25.6,62.7,25.7,62,26z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M67.7,36.1c-0.1-0.2-0.3-0.1-0.2,0c0,0,0,0-0.1,0l0,0 c0,0,0,0-0.1,0.1l0,0C67.4,36.6,67.9,36.5,67.7,36.1z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M71.1,35.9C71,35.9,71,35.8,71.1,35.9 c-0.2,0-0.3,0.1-0.3,0.2l0,0c0,0,0,0-0.1,0l0,0c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.3,0.3,0.2c0.1,0,0.1-0.1,0.1-0.2 C71.2,36.2,71.2,36,71.1,35.9z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.7,33.8L66.7,33.8c-0.1-0.2-0.3-0.3-0.4-0.1l0,0l0,0 c-0.1,0.2,0.1,0.4,0.3,0.4h0.1c0.1,0,0.1-0.1,0.2-0.1C66.8,34,66.8,33.9,66.7,33.8z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M71.5,33.8L71.5,33.8L71.5,33.8L71.5,33.8 c-0.1-0.1-0.2-0.1-0.2-0.1l0,0l0,0v0.1l0,0l0,0v0.1c0.1,0.2,0.4,0.3,0.5,0.2c0,0,0.1,0,0.1-0.1C72.3,33.8,71.7,33.2,71.5,33.8z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M68.4,38.5l0.2-0.3c0-0.1-0.3,0-0.3,0l0,0h-0.1l0,0l0,0l0,0 c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2h0.1L68.4,38.5C68.3,38.6,68.3,38.6,68.4,38.5C68.4,38.6,68.4,38.5,68.4,38.5 L68.4,38.5L68.4,38.5z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M70.3,38.3c0,0-0.1-0.2-0.2-0.2l0,0c-0.1,0-0.1-0.1-0.2,0 l0,0c0,0,0,0,0,0.1s0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.1l0,0l0,0l0,0C70.2,38.5,70.3,38.5,70.3,38.3z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M69.5,38.7L69.5,38.7c0-1.7-0.2-4.7-0.2-0.2l0,0l0,0l0,0 c0,0,0,0.1,0.1,0.1C69.4,38.7,69.4,38.7,69.5,38.7c-0.4,0.1-0.4,0.3-0.1,0.7l0,0c0,0.1,0,0.1,0,0.2c-0.1,0-0.2,0.4,0,0.4 c-0.1,0.1-0.1,0.2,0,0.3c-0.1,0.3-0.1,0.5,0.2,0.7c0-0.3,0-0.6,0-1l0,0l0,0c0-0.1,0-0.2,0-0.3C69.6,39.4,69.5,39,69.5,38.7z"
      />
      <path
        opacity="0.3"
        fill={colors[2]}
        d="M66.3,34L66.3,34c-0.1,0.1,0.1,0.3,0.1,0.2l0,0 C66.6,34.1,66.4,33.9,66.3,34z"
      />
      <path
        fill={colors[2]}
        d="M71,36.2L71,36.2L71,36.2c0-0.1-0.1-0.3-0.2-0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3-0.2-0.6-0.4-0.8-0.6 c-0.2-0.1-0.4-0.2-0.5-0.3c0.1,0,0.2-0.1,0.2-0.1c0.7-0.4,1.5-0.8,2.2-1.2c0,0-0.1-0.2-0.2-0.2L69.4,35 c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.7-0.4-1.5-0.9-2.3-1c-0.1,0,0.1,0.2,0.1,0.2c0.8,0.1,1.7,0.7,2.5,1.1 c-0.5,0.2-1.2,0.5-1.4,0.9c0,0.1,0.1,0.2,0.2,0.2c0.3-0.5,0.9-0.7,1.5-1c0.4,0.2,0.7,0.4,1.1,0.6c0.1,0.1,0.2,0.2,0.3,0.2 c-0.5,0.4-1.1,0.9-1.6,1.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4-0.3-0.8-0.7-1.3-1.1c-0.1-0.1-0.1,0,0,0.1c0.5,0.4,1,0.8,1.5,1.2 c-0.2,0.2-0.6,0.6-0.8,0.8c0,0,0.1,0.2,0.2,0.2c0.2-0.2,0.6-0.6,0.8-0.8l0,0c0.2,0.2,0.8,0.9,1.1,0.7c0,0-0.1-0.2-0.2-0.2 c-0.1,0.1-0.6-0.3-0.9-0.6c0.5-0.4,1-0.9,1.6-1.3l0.1,0.1c0.1,0,0.2,0,0.3,0C71.2,36.4,71.1,36.3,71,36.2z"
      />
    </g>
  )
}

export default Tunic
