import React from 'react'
import shortId from 'shortid'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import { connect } from 'react-redux'
import { uiSchemaService } from '../uiSchema'
import ColorWheel from '../coloringIcon'
import CenteredSVG from '../CenteredSVG'
import {
  toggleElement,
  setDefaultEnabled,
  setColorActiveItem,
  itemSelected
} from '../reducers/builderReducer'

import { characterSelector } from '../selectors/builderSelectors'

const ms2p = (state) => ({
  currentColorMode: state.builderReducer.currentColorMode
})

const ColorableIcon = ({ item, setColorActiveItem, currentColorMode }) => {
  return <a onClick={() => setColorActiveItem(item)}><ColorWheel /></a>
}

const ConnectedColorableIcon = connect(ms2p, { setColorActiveItem })(ColorableIcon)

const SVGButton = ({ item, category, character }) => {
  const mapStateToProps = state => ({
    enabled: itemSelected(state, {character, category, id: item.id }),
    colors:  get(state.builderReducer, ['enabledElements', character, item.id])
  })

  const Component = ({ toggleElement, enabled, ...props }) =>
    <div style={{ border: `${enabled ? '1px solid lightblue' : '1px solid white'}`,  margin: 10 }} >
      <div onClick={() => toggleElement({ character, category, element: item }) } >
        <CenteredSVG  width="50" height="50" render={item.component} {...props} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {
          item.colorable ? <ConnectedColorableIcon item={item} /> : null
        }
      </div>
    </div>

  return connect(mapStateToProps, { toggleElement })(Component)
}

const GridListCategory = ({
  onSelect, selectedCategory = "", category, schema, character
}) =>
  <div id={category} key={shortId.generate()}>
    <div style={{ borderBottom: '1px solid #ccc', textAlign: 'left' }}>
      <a className="button is-white is-medium is-fullwidth" onClick={() => (onSelect(category))}>{startCase(category)}</a>
    </div>
    {selectedCategory === category &&
      <div className="is-clearfix">
        {
          schema[category].map(item => (
            GridTile({
              item,
              category,
              character
            })
          ))
        }
      </div>
    }
  </div>

const GridTile = ({ item, category, character }) => {
  const SVGButtonComponent = SVGButton({ item, category, character })

  return (
    <div className="is-pulled-left" key={shortId.generate()}>
      <SVGButtonComponent />
    </div>
  )
}

/**
 *
 */
class UI extends React.Component {
  constructor(props) {
    super(props)
    this.state = { selectedCategory: "hair" }
  }

  onSelect = (category) => {
    this.setState({ selectedCategory: category })
  }

  render() {
    const { character } = this.props
    const schema = uiSchemaService.get(character) || {}

    return (
      <div>
        <div style={{ maxHeight: 700, overflowY: "scroll", overflowX: "visible" }}>
          {
            Object.keys(schema).reduce((acc, category) => {
              acc.push(GridListCategory({
                onSelect: this.onSelect,
                selectedCategory: this.state.selectedCategory,
                category,
                schema,
                character
              }))
              return acc
            }, [])
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  enabledElements: state.builderReducer.enabledElements,
  character: characterSelector(state)
})

const mapDispatchToProps = {
  toggleElement,
  setDefaultEnabled
}


export default connect(mapStateToProps, mapDispatchToProps)(UI)