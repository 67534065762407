import React from "react"

const LongSwordRight = ({
  colors = [
    "#D0A92B",
    "#A8ABAD",
    "#D5D8DA",
    "#B49330",
    "#FFD144",
    "#EDD692",
    "#7B6528",
    "#A38634",
    "#7951A0"
  ]
}) => {
  return (
    <g id="LongSwordRight">
      <rect
        x="42.903"
        y="84.186"
        transform="rotate(-39.412 43.903 88.036)"
        fill={colors[0]}
        width="2"
        height="7.7"
      />
      <path
        fill={colors[1]}
        d="M42,83.4000015l-2.4000015,2L12.3999996,52.2999992c0,0-5.0999999-6.2999992-4.5-7.9000015 C9.5,44.1999969,14.7999992,50.2999992,14.7999992,50.2999992L42,83.4000015z"
      />
      <path
        fill={colors[2]}
        d="M7.9000001,44.4000015c-0.5999999,1.7000008,4.4999995,7.9000015,4.4999995,7.9000015 l27.1999989,33.0999985l1.2000008-1L7.9000001,44.4000015z"
      />
      <path
        fill={colors[0]}
        d="M36.7000008,85.5999985c0,0-0.0999985-0.9000015,3.0999985-2.5 c2.0999985-2.8000031,3.0999985-2.6999969,3.0999985-2.6999969s3.7999992,1.3000031-1,5.1999969 C37,89.5999985,36.7000008,85.5999985,36.7000008,85.5999985z"
      />
      <path
        fill={colors[3]}
        d="M42.0999985,80.0999985c0,0,1.9000015,0.5999985-0.2999992,2.9000015 c-0.2999992,0.3000031,0.5,2.1999969,0,2.5999985s-2.2000008-0.6999969-2.5999985-0.5 c-2.7000008,1.6999969-2.5999985-0.5-2.5999985-0.5s-0.9000015,4,1.7999992,3.1999969 c0-0.4000015,0.2000008-0.9000015,0.5999985-1.3000031c0.5999985-0.5,2.2999992,0.5999985,2.7999992,1 c0.7999992,0.3000031,1.5999985,0.4000015,1.9000015,0.0999985c0.5-0.4000015,0.4000015-1.1999969,0.2999992-2 c-0.2999992-0.5-0.9000015-2.5-0.2999992-3c0.4000015-0.3000031,0.7999992-0.4000015,1.2000008-0.4000015 C45.7999992,80.0999985,42.0999985,80.0999985,42.0999985,80.0999985z"
      />
      <path
        fill={colors[4]}
        d="M43.5,87.5999985c-0.2000008,0.1999969-0.9000015,0.0999985-1.7000008-0.1999969 c-0.5-0.4000015-2.2999992-1.5999985-3-1c-0.4000015,0.3000031-0.7000008,0.8000031-0.7000008,1.3000031 c-0.5,0.0999985-0.7999992,0-1.0999985-0.1999969c-0.4000015-0.4000015-0.5-1.1999969-0.4000015-1.9000015 c0.0999985,0.0999985,0.2000008,0.1999969,0.2999992,0.3000031c0.5,0.3000031,1.2999992,0.0999985,2.4000015-0.5 c0.0999985,0,0.5999985,0.0999985,0.9000015,0.3000031c0.7000008,0.3000031,1.2999992,0.5,1.7000008,0.1999969 s0.2000008-1,0.0999985-1.6999969c-0.0999985-0.3000031-0.2000008-0.8000031-0.0999985-0.9000015 c0.9000015-1,1.2999992-1.8000031,1.0999985-2.4000015c0-0.0999985-0.0999985-0.3000031-0.2000008-0.4000015 c0.5999985,0.0999985,1.4000015,0.3000031,1.7000008,0.8000031c0.2000008,0.3000031,0.2000008,0.5999985,0.0999985,1 c-0.4000015,0-0.7999992,0.0999985-1.2000008,0.4000015c-0.7000008,0.5999985-0.0999985,2.6999969,0.2000008,3.3000031v0.0999985 C43.7999992,86.6999969,43.9000015,87.3000031,43.5,87.5999985z"
      />
      <path
        fill={colors[3]}
        d="M47.5999985,91.4000015L46.5,92.3000031C46.2000008,92.6000061,45.5999985,92.5,45.4000015,92.2000046 L45,91.9000015C44.7000008,91.5999985,44.7999992,91,45.0999985,90.8000031l1.0999985-0.9000015 C46.4999962,89.5999985,47.0999985,89.7000046,47.2999954,90l0.2999992,0.4000015 C48,90.5999985,48,91.0999985,47.5999985,91.4000015z"
      />
      <path
        fill={colors[5]}
        d="M45,91.9000015C44.7000008,91.5999985,44.7999992,91,45.0999985,90.8000031l1.0999985-0.9000015 C46.4999962,89.5999985,47.0999985,89.7000046,47.2999954,90C46.5,90.1999969,45.2999992,91.0999985,45,91.9000015z"
      />
      <ellipse
        transform="rotate(-39.412 40.401 83.787)"
        fill={colors[6]}
        cx="40.401"
        cy="83.787"
        rx="1.4"
        ry="1"
      />
      <ellipse
        transform="rotate(-39.412 40.401 83.787)"
        fill={colors[7]}
        cx="40.401"
        cy="83.787"
        rx="0.8"
        ry="1.2"
      />
      <ellipse
        transform="rotate(-39.412 40.401 83.787)"
        fill={colors[8]}
        cx="40.401"
        cy="83.787"
        rx="0.6"
        ry="1"
      />
    </g>
  )
}

export default LongSwordRight
