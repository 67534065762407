import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const TatteredCape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("TatteredCape") ? (
    <g id="TatteredCape">
      <path
        id="tatteredCape"
        d="M93.8485107,107.9203873c2.1120758-24.0933304-11.8119888-58.8252716-26.7529831-57.2607727 c-14.9409943,1.5645027-16.9748459,16.4272766-25.2667084,30.664238 c-3.0507812,5.1628647-8.4483109,14.4716415-13.2200432,19.399826c-2.7378788,2.8161087-3.8330288,5.2410812-1.720953,9.3870163 C27.8265247,112.0663223,88.7638855,132.7959747,93.8485107,107.9203873z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(TatteredCape, {
  character: "WomanGnome"
})
