import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import BomberJacket from "./BomberJacket"
import CaptiansJacket from "./CaptiansJacket"
import PlateArmor from "./PlateArmor"
import SimpleVest from "./SimpleVest"
import WizardRobe from "./WizardRobe"

const CATEGORY = "coats"
const CHARACTER = "ManGnome"
export const IDS = {
  BOMBER_JACKET: "BomberJacket",
  CAPTIANS_JACKET: "CaptiansJacket",
  PLATE_ARMOR: "PlateArmor",
  SIMPLE_VEST: "SimpleVest",
  WIZARD_ROBE: "WizardRobe"
}

export const components = {
  [IDS.BOMBER_JACKET]: BomberJacket,
  [IDS.CAPTIANS_JACKET]: CaptiansJacket,
  [IDS.PLATE_ARMOR]: PlateArmor,
  [IDS.SIMPLE_VEST]: SimpleVest,
  [IDS.WIZARD_ROBE]: WizardRobe
}

export const Group = ({ props }) => (
  <g id="coats">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.BOMBER_JACKET,
    name: "Bomber Jacket",
    defaultColors: ["#353891", "#60664D"],
    colorable: true,
    component: components[IDS.BOMBER_JACKET],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.CAPTIANS_JACKET,
    name: "Captians Jacket",
    defaultColors: ["#204563", "#1E2F44", "#9C7000", "#FFFFFF"],
    colorable: true,
    component: components[IDS.CAPTIANS_JACKET],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.PLATE_ARMOR,
    name: "Plate Armor",
    defaultColors: ["#7F7F7F", "#FFFFFF"],
    colorable: true,
    component: components[IDS.PLATE_ARMOR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SIMPLE_VEST,
    name: "Simple Vest",
    defaultColors: ["#6E5238", "#CFA987", "#8C725C"],
    colorable: true,
    component: components[IDS.SIMPLE_VEST],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WIZARD_ROBE,
    name: "Wizard Robe",
    defaultColors: [
      "#525A55",
      "#4C3317",
      "#895A24",
      "#474D49",
      "#FFFFFF",
      "#D7D6D5"
    ],
    colorable: true,
    component: components[IDS.WIZARD_ROBE],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
