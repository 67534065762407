import React from "react"

const NobelCape = ({ colors = ["#8D4D3A"] }) => {
  return (
    <g id="NobelCape">
      <g fill={colors[0]}>
        <path d="M38.8778114,68.7368011c-3.8871651,12.9089584-8.7461224,24.1432343-15.4838753,35.5170746 c0,0,4.3406658-2.9306793,4.2758808-0.4884491l0,0c5.3124599-11.3738327,9.1348362-22.6081161,12.1797848-35.0286179 C39.4608841,68.7368011,39.2665291,68.7368011,38.8778114,68.7368011z" />
        <path d="M55.9813385,68.7368011c3.8871613,12.9787369,8.7461166,24.1432343,15.4838715,35.5170746 c0,0-4.3406677-2.9306793-4.2758789-0.4884491l0,0c-5.312458-11.3738327-9.1348381-22.6081161-12.1797829-35.0286179 C55.398262,68.7368011,55.5926208,68.7368011,55.9813385,68.7368011z" />
        <path d="M55.0095482,68.7368011c-0.4535065,0.0697784-0.6478615,0.1395569-1.1013641,0.2791138 c-1.1013641,0.1395569-1.6844406,0.2093353-2.7858009,0.3488922c-0.5830765,0.0697784-0.9070091,0.1395569-1.4900818,0.2093353 l0,0c-0.5182877,0.0697784-0.7774315,0.1395569-1.295723,0.2093353c-0.7126427,0-1.1013641,0-1.8787956,0 c-0.5182877-0.0697784-0.7774353-0.1395569-1.295723-0.2093353l0,0c-0.5830765-0.0697784-0.9070053-0.1395569-1.490078-0.2093353 c-1.1013641-0.1395569-1.6844368-0.2093353-2.7858009-0.3488922c-0.4535027-0.0697784-0.6478615-0.1395569-1.1013641-0.2791138 C36.7398682,81.087532,32.9174919,92.3915939,27.605032,103.765419c-0.1295738,2.4422379,2.721014,3.419136,4.2110958,2.302681 c0.064785-0.0697708,0.194355-0.1395493,0.2591438-0.2093353c1.6196518-0.8373337,5.312458-0.6977768,5.5716057,0.1395569 c0,0.0697784,0,0.0697784,0,0.1395493c-0.064785,0.9768982,2.7858009,3.6982498,5.8307457,0.4884491 c0.1295738-0.1395569,0.2591476-0.2093353,0.3887177-0.3488922c0,0,0.064785,0,0.064785-0.0697784 c0,0.0697784,0.064785,0.0697784,0.12957,0c0.7126465-0.6279984,1.9435844-1.3955612,3.3688774-1.4653397 c1.4252968,0.0697784,2.7210159,0.8373413,3.3688774,1.4653397c0.064785,0.0697784,0.064785,0,0.1295738,0 c0,0,0.064785,0,0.064785,0.0697784l0,0c0.1295738,0.1395569,0.2591438,0.2093353,0.3887138,0.3488922 c3.0449486,3.2097931,5.8307495,0.4884415,5.8307495-0.4884491c0-0.0697708,0-0.0697708,0-0.1395493 c0.2591438-0.8373337,3.9519501-0.9768982,5.5716019-0.1395569c0.12957,0.0697784,0.1943588,0.1395569,0.2591438,0.2093353 c1.4900856,1.1164551,4.3406639,0.1395569,4.2110939-2.302681C61.876873,92.3915939,58.054493,81.1573105,55.0095482,68.7368011z" />
      </g>
      <path
        opacity="0.35"
        fill="#21376C"
        d="M55.0095482,68.7368011 c-0.4535065,0.0697784-0.6478615,0.1395569-1.1013641,0.2791138c-1.1013641,0.1395569-1.6844406,0.2093353-2.7858009,0.3488922 c-0.5830765,0.0697784-0.9070091,0.1395569-1.4900818,0.2093353l0,0c-0.5182877,0.0697784-0.7774315,0.1395569-1.295723,0.2093353 c-0.7126427,0-1.1013641,0-1.8787956,0c-0.5182877-0.0697784-0.7774353-0.1395569-1.295723-0.2093353l0,0 c-0.5830765-0.0697784-0.9070053-0.1395569-1.490078-0.2093353c-1.1013641-0.1395569-1.6844368-0.2093353-2.7858009-0.3488922 c-0.4535027-0.0697784-0.6478615-0.1395569-1.1013641-0.2791138C36.7398682,81.087532,32.9174919,92.3915939,27.605032,103.765419 c-0.1295738,2.4422379,2.721014,3.419136,4.2110958,2.302681c0.064785-0.0697708,0.194355-0.1395493,0.2591438-0.2093353 c1.6196518-0.8373337,5.312458-0.6977768,5.5716057,0.1395569c0,0.0697784,0,0.0697784,0,0.1395493 c-0.064785,0.9768982,2.7858009,3.6982498,5.8307457,0.4884491c0.1295738-0.1395569,0.2591476-0.2093353,0.3887177-0.3488922 c0,0,0.064785,0,0.064785-0.0697784c0,0.0697784,0.064785,0.0697784,0.12957,0 c0.7126465-0.6279984,1.9435844-1.3955612,3.3688774-1.4653397c1.4252968,0.0697784,2.7210159,0.8373413,3.3688774,1.4653397 c0.064785,0.0697784,0.064785,0,0.1295738,0c0,0,0.064785,0,0.064785,0.0697784l0,0 c0.1295738,0.1395569,0.2591438,0.2093353,0.3887138,0.3488922c3.0449486,3.2097931,5.8307495,0.4884415,5.8307495-0.4884491 c0-0.0697708,0-0.0697708,0-0.1395493c0.2591438-0.8373337,3.9519501-0.9768982,5.5716019-0.1395569 c0.12957,0.0697784,0.1943588,0.1395569,0.2591438,0.2093353c1.4900856,1.1164551,4.3406639,0.1395569,4.2110939-2.302681 C61.876873,92.3915939,58.054493,81.1573105,55.0095482,68.7368011z"
      />
    </g>
  )
}

export default NobelCape
