import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const AxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("AxeRight") ? (
    <g id="AxeRight">
      <path
        id="S_x24_axeRight"
        d="M59.4484291,114.1609421l2.0948143-0.9775772l2.6534271-5.5861664L58.7501602,83.576683h1.4663658 c2.723259-2.5836029,3.9103203-6.2146149,3.2818756-9.5663147l0,0c-0.6982727-3.4215317-3.1422195-6.2844391-6.7034035-7.6111526 l-2.5835991,1.1172333l-3.4215317-0.8379211l-2.6534309,0.4887848l-2.9327354,2.0249863l-2.723259-0.0698242 c-2.7232552,2.5836029-3.9103127,6.2146149-3.281868,9.5663147v0.0698242 c0.6982689,3.421524,3.1422195,6.2146072,6.7033997,7.6111526l1.4663658-0.6284485 c1.5361938,6.5637512,3.0723953,12.9878387,3.4215279,15.1524811c0.5586205,3.1422195,0.9077568,7.5413284,0.977581,8.7982101 l2.4439507,3.9103165c0.5586166,0.3491364,1.1172295,0.5586166,1.7456741,0.7680969L59.4484291,114.1609421z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(AxeRight, {
  character: "WomanGnome"
})
