import React from "react"

const Skarf = ({ colors = ["#7F0E05"] }) => {
  return (
    <g id="Skarf">
      <path
        fill={colors[0]}
        d="M56.9328957,18.9614716c-0.2291756-0.8021088-2.0625687-0.9166946-2.6355019-0.6875229 c-2.3138657-0.894989-4.3245354,2.9382534-4.3245354,2.9382534s7.0746269,13.9060421,15.2103004,14.9373264 c0,0,4.8126526,0.5729332,7.9065094-0.1145859c3.0938416-0.572937,13.7775116-16.0978489,13.7775116-16.0978489 s-1.2558136-2.1395302-4.0376205-1.2047939c-2.1771545-0.6875229-1.4029694-1.1288109-2.892601-0.0975285 c-0.8021011-0.8021107-7.7818146,8.0663681-11.9069519,7.8371964 C64.0194626,26.2427902,58.7662888,18.3885365,56.9328957,18.9614716z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M57.0474777,18.8468838 c0,0-0.2291718,9.1669617,13.6358643,10.4274216C65.4123383,27.0971508,61.2872009,25.1491718,57.0474777,18.8468838z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M81.2392807,19.7045364 c0,0-7.576683,10.2260094-17.08741,6.3300476C67.5894928,28.0971508,78.0308533,28.9860878,81.2392807,19.7045364z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M56.2453728,25.3783436 c5.3855934,3.0938511,12.9483376,8.2502689,21.3131905,2.7500916c-2.6355057,2.1771545-7.3335724,4.4688969-17.760994,1.8333912 c-0.3437576,1.3750458,10.8857727,8.7086143,20.969429-3.5521984c-1.8333893,2.4063282-7.3335724,12.2608128-17.8755722,7.3335705 C58.9954605,31.7952213,56.2453728,25.3783436,56.2453728,25.3783436z"
      />
    </g>
  )
}

export default Skarf
