import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LongBowRight from "./LongBowRight"
import LongSwordRight from "./LongSwordRight"
import GoldAxeRight from "./GoldAxeRight"
import WarhammerR from "./WarhammerR"
import MaceR from "./MaceR"
import StaffRight from "./StaffRight"
import AxeRight from "./AxeRight"
import LuteRight from "./LuteRight"
import DaggerRight from "./DaggerRight"
import BattleAxeRight from "./BattleAxeRight"
import MorningStarRight from "./MorningStarRight"
import SlingShotRight from "./SlingShotRight"
import DragonStaffR from "./DragonStaffR"

const CATEGORY = "rightHandItem"
const CHARACTER = "ManGnome"
export const IDS = {
  LONG_BOW_RIGHT: "LongBowRight",
  LONG_SWORD_RIGHT: "LongSwordRight",
  GOLD_AXE_RIGHT: "GoldAxeRight",
  WARHAMMER_R: "WarhammerR",
  MACE_R: "MaceR",
  STAFF_RIGHT: "StaffRight",
  AXE_RIGHT: "AxeRight",
  LUTE_RIGHT: "LuteRight",
  DAGGER_RIGHT: "DaggerRight",
  BATTLE_AXE_RIGHT: "BattleAxeRight",
  MORNING_STAR_RIGHT: "MorningStarRight",
  SLING_SHOT_RIGHT: "SlingShotRight",
  DRAGON_STAFF_R: "DragonStaffR"
}

export const components = {
  [IDS.LONG_BOW_RIGHT]: LongBowRight,
  [IDS.LONG_SWORD_RIGHT]: LongSwordRight,
  [IDS.GOLD_AXE_RIGHT]: GoldAxeRight,
  [IDS.WARHAMMER_R]: WarhammerR,
  [IDS.MACE_R]: MaceR,
  [IDS.STAFF_RIGHT]: StaffRight,
  [IDS.AXE_RIGHT]: AxeRight,
  [IDS.LUTE_RIGHT]: LuteRight,
  [IDS.DAGGER_RIGHT]: DaggerRight,
  [IDS.BATTLE_AXE_RIGHT]: BattleAxeRight,
  [IDS.MORNING_STAR_RIGHT]: MorningStarRight,
  [IDS.SLING_SHOT_RIGHT]: SlingShotRight,
  [IDS.DRAGON_STAFF_R]: DragonStaffR
}

export const Group = ({ props }) => (
  <g id="rightHandItem">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LONG_BOW_RIGHT,
    name: "Long Bow Right",
    defaultColors: [
      "#695E60",
      "#42130B",
      "#FFCD03",
      "#EDD692",
      "#B5AC95",
      "#915B25",
      "#A98A30"
    ],
    colorable: false,
    component: components[IDS.LONG_BOW_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_SWORD_RIGHT,
    name: "Long Sword Right",
    defaultColors: [
      "#D0A92B",
      "#A8ABAD",
      "#D5D8DA",
      "#B49330",
      "#FFD144",
      "#EDD692",
      "#7B6528",
      "#A38634",
      "#7951A0"
    ],
    colorable: false,
    component: components[IDS.LONG_SWORD_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_RIGHT,
    name: "Gold Axe Right",
    defaultColors: ["#897441", "#A39155", "#EDCD6E"],
    colorable: false,
    component: components[IDS.GOLD_AXE_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WARHAMMER_R,
    name: "Warhammer R",
    defaultColors: [
      "#B8B9BA",
      "#727474",
      "#E6E7E7",
      "#646565",
      "#7F8080",
      "#636565",
      "#CDCFCF",
      "#A0A0A1",
      "#D6D7D5"
    ],
    colorable: false,
    component: components[IDS.WARHAMMER_R],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MACE_R,
    name: "Mace R",
    defaultColors: [
      "#595A5B",
      "#B8B9BA",
      "#777676",
      "#9A9B9C",
      "#E0E1E1",
      "#E4E3E3"
    ],
    colorable: false,
    component: components[IDS.MACE_R],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.STAFF_RIGHT,
    name: "Staff Right",
    defaultColors: [
      "#865623",
      "#B68D5F",
      "#525A56",
      "#FFFFFF",
      "#27A8E0",
      "#8080BC",
      "#SVGID_",
      "#292C6A"
    ],
    colorable: false,
    component: components[IDS.STAFF_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.AXE_RIGHT,
    name: "Axe Right",
    defaultColors: ["#8A5F3C", "#6D6E71", "#D1D2D3", "#808184"],
    colorable: false,
    component: components[IDS.AXE_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LUTE_RIGHT,
    name: "Lute Right",
    defaultColors: ["#93712B", "#D8A43D", "#6A5121", "#B68B32", "#8E6D2B"],
    colorable: false,
    component: components[IDS.LUTE_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DAGGER_RIGHT,
    name: "Dagger Right",
    defaultColors: [
      "#6E5E26",
      "#99B7D9",
      "#6B91BF",
      "#57789B",
      "#E1E2E3",
      "#9EA0A3",
      "#ECD592"
    ],
    colorable: false,
    component: components[IDS.DAGGER_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BATTLE_AXE_RIGHT,
    name: "Battle Axe Right",
    defaultColors: ["#7A7C7D", "#505052"],
    colorable: false,
    component: components[IDS.BATTLE_AXE_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MORNING_STAR_RIGHT,
    name: "Morning Star Right",
    defaultColors: [
      "#D8D8D7",
      "#939393",
      "#BCBCBB",
      "#B68D5F",
      "#856845",
      "#533F2B",
      "#535352",
      "#C1C1BF",
      "#78787A",
      "#SVGID_",
      "#FFFFFF",
      "#F5F5F5",
      "#E4E4E5",
      "#C7C8CA",
      "#A0A2A5",
      "#757779",
      "#2D2D2E",
      "#000000"
    ],
    colorable: false,
    component: components[IDS.MORNING_STAR_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SLING_SHOT_RIGHT,
    name: "Sling Shot Right",
    defaultColors: ["#352915", "#464646", "#939393", "#724F20"],
    colorable: false,
    component: components[IDS.SLING_SHOT_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DRAGON_STAFF_R,
    name: "Dragon Staff R",
    defaultColors: ["#EFCA14", "#FFFFFF"],
    colorable: true,
    component: components[IDS.DRAGON_STAFF_R],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
