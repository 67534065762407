import React from "react"

const DaggerLeft = ({
  colors = [
    "#6E5E26",
    "#9AB8DB",
    "#6B92C0",
    "#57779C",
    "#E2E4E5",
    "#A0A2A5",
    "#EED793"
  ]
}) => {
  return (
    <g id="DaggerLeft">
      <polygon
        fill={colors[0]}
        points="72.6930313,79.4497299 73.7930298,81.3497238 70.6930313,82.7497253 69.7930298,86.0497284 68.0930328,90.1497269 68.2930298,91.6497269 67.1930313,92.6497269 65.8930283,92.2497253 65.4930267,90.7497253 66.3930283,89.8497238 67.3930283,85.3497238 68.7930298,82.1497269 67.0930328,79.1497269 69.1930313,78.2497253"
      />
      <polygon
        fill={colors[1]}
        points="65.9930267,92.1497269 66.3930283,91.7497253 66.9930267,91.9497299 67.0930328,92.5497284"
      />
      <polygon
        fill={colors[2]}
        points="67.1930313,92.5497284 67.0930328,91.9497299 67.6930313,91.4497299 68.2930298,91.6497269"
      />
      <polygon
        fill={colors[1]}
        points="65.9930267,92.0497284 66.2930298,91.6497269 66.1930313,90.9497299 65.5930328,90.7497253"
      />
      <polygon
        fill={colors[3]}
        points="66.4930267,89.9497299 66.6930313,90.4497299 67.4930267,90.6497269 67.9930267,90.3497238"
      />
      <polygon
        fill={colors[2]}
        points="65.6930313,90.6497269 66.1930313,90.8497238 66.5930328,90.4497299 66.4930267,89.9497299"
      />
      <polygon
        fill={colors[3]}
        points="67.7930298,91.3497238 67.5930328,90.7497253 67.9930267,90.4497299 68.1930313,91.5497284"
      />
      <polygon
        fill={colors[2]}
        points="66.2930298,90.8497238 66.4930267,91.6497269 67.0930328,91.8497238 67.5930328,91.3497238 67.4930267,90.8497238 66.6930313,90.5497284"
      />
      <path
        fill={colors[4]}
        d="M72.7930298,80.8497238l-2.1999969-0.9000015l-2.4000015-0.5c0,0,1.4000015-2.5,2.3000031-7.8000031 c1.4000015-5.2999954,5.5999985-9,5.5999985-9l0,0L72.7930298,80.8497238z"
      />
      <path
        fill={colors[5]}
        d="M70.5930328,79.9497299l5.5-17.2999992c0,0,0.8000031,6.2999992-1.0999985,11.3999977 c-1.5999985,3.5-2.3000031,6.9000015-2.3000031,6.9000015L70.5930328,79.9497299z"
      />
      <path
        fill={colors[6]}
        d="M68.8930283,87.9497299l-0.8000031,2c-0.1999969,0.0999985-0.5,0.0999985-0.6999969,0.0999985 l-0.1999969-0.0999985c-0.1999969-0.0999985-0.4000015-0.1999969-0.5-0.4000015l0.6999969-3.5 C67.7930298,86.8497238,68.1930313,87.4497299,68.8930283,87.9497299z"
      />
      <path
        fill={colors[6]}
        d="M67.8930283,84.5497284l1-2.3000031l0.6999969,1l1-0.4000015l-0.8000031,3l-0.6999969,1.5999985 C68.3930283,86.8497238,67.9930267,85.4497299,67.8930283,84.5497284z"
      />
    </g>
  )
}

export default DaggerLeft
