import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import RightCuff from "./RightCuff"
import LeftCuff from "./LeftCuff"

const CATEGORY = "braces"
const CHARACTER = "ManMixed"
export const IDS = {
  RIGHT_CUFF: "RightCuff",
  LEFT_CUFF: "LeftCuff"
}

export const components = {
  [IDS.RIGHT_CUFF]: RightCuff,
  [IDS.LEFT_CUFF]: LeftCuff
}

export const Group = ({ props }) => (
  <g id="braces">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.RIGHT_CUFF,
    name: "Right Cuff",
    defaultColors: ["#898888", "#FFFFFF"],
    colorable: true,
    component: components[IDS.RIGHT_CUFF],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.LEFT_CUFF,
    name: "Left Cuff",
    defaultColors: ["#898888", "#FFFFFF"],
    colorable: true,
    component: components[IDS.LEFT_CUFF],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
