import React from "react"

const ShoulderPlate = ({ colors = ["#9E9E9E", "#808080"] }) => {
  return (
    <g id="ShoulderPlate">
      <path
        fill={colors[0]}
        d="M36.4969864,67.8081589c0,0-0.3905449,1.2204514,0.0976334,2.1968079 c1.4157219,0.1464539,5.0282478-0.0488205,5.3211594-1.3669052c0.0976372-0.3905411-0.244091-1.5621719-0.244091-1.5621719 C41.2323265,66.7341614,37.326889,64.9767151,36.4969864,67.8081589z"
      />
      <path
        fill="gray"
        d="M41.6716881,67.0758896c0,0.0976334,0,0.6346359-0.0488205,0.6834488 c-0.2929039,1.3180923-3.7589798,1.3669052-5.1747017,1.2204514c-0.0488167-0.0976334-0.0488167-0.3417282-0.0976372-0.4393616 c0,0.3417282,0.0488205,0.8299026,0.1952744,1.1716309c1.4157181,0.1464539,5.0282478-0.0488129,5.3211555-1.3668976 C41.9645958,68.1498795,41.7693214,67.4664307,41.6716881,67.0758896z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M59.0996933,67.8081589c0,0,0.3905487,1.2204514-0.0976334,2.1968079 c-1.4157219,0.1464539-5.0282478-0.0488205-5.3211555-1.3669052c-0.0976372-0.3905411,0.2440872-1.5621719,0.2440872-1.5621719 C54.3155365,66.7341614,58.2209702,64.9767151,59.0996933,67.8081589z"
        />
        <path
          fill="gray"
          d="M53.9249916,67.0758896c0,0.0976334,0,0.6346359,0.0488205,0.6834488 c0.2929039,1.3180923,3.7589798,1.3669052,5.1747017,1.2204514c0.0488167-0.0976334,0.0488167-0.3417282,0.0976334-0.4393616 c0,0.3417282-0.0488167,0.8299026-0.1952744,1.1716309c-1.4157181,0.1464539-5.0282478-0.0488129-5.3211555-1.3668976 C53.5832672,68.1498795,53.8273582,67.4664307,53.9249916,67.0758896z"
        />
      </g>
    </g>
  )
}

export default ShoulderPlate
