import React from 'react'
import './App.scss'
import { BrowserRouter as Router, Route, Link } from "react-router-dom"

import  LoadingModal from './Modal'
import FlashMessages from './Flash'

import  Logo from './Logo'
import { LogOut } from './Auth'

// Pages
import Home from './home'
import RecentMinis from './catalog/RecentMinis'
import UserMinis from './catalog/UserMinis'
import UserSheets from './catalog/UserSheets'
import Builder from './builder'
import AuthPage from './Auth';

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = { active: false }
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active })
  }

  render() {
    const { active } = this.state
    const activeClass = active ? 'is-active' : ''
    return (
      <div>
        <div className="navbar" role="navigation" arialabel="main navigation">
          <div className='navbar-brand'>
            <div className='navbar-item'><Link to='/'><Logo /></Link></div>
            <a
              onClick={this.toggleActive}
              data-target="pawn-navbar"
              role="button"
              className={`navbar-burger ${activeClass}`}
              aria-label="menu"
              aria-expanded="false"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div id="pawn-navbar" className={`navbar-menu ${activeClass}`} >
            <Link className='navbar-item' to='/builder'>Builder</Link>
            <Link className='navbar-item' to='/minis'>Pawns</Link>
            <Link className='navbar-item' to='/sheets'>Printable Sheets</Link>
            <Link className='navbar-item' to='/feed'>Feed</Link>
          </div>
          <div className="navbar-end">
            <div className='navbar-item'><LogOut /></div>
          </div>
        </div>
        <div className="container">
          <main className="container">
            <div>
              <Route exact path="/" component={Home} />
              <Route path="/minis" component={UserMinis} />
              <Route path="/feed" component={RecentMinis} />
              <Route path="/sheets" component={UserSheets} />
              <Route path="/builder" component={Builder} />
              <Route path="/auth" component={AuthPage} />
            </div>
          </main>
          <LoadingModal />
          <FlashMessages />
        </div>
      </div>
    )
  }
}



export default App
