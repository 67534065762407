import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const RoundWoodShieldLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("RoundWoodShieldLeft") ? (
    <g id="RoundWoodShieldLeft">
      <path
        id="S_x24_roundWoodShieldLeft"
        d="M69.6,99.8c-8.9,0-16.7-5.6-19.6-14C48.2,80.6,48.6,75,51,70s6.7-8.7,11.9-10.4 c2.1-0.7,4.4-1.1,6.6-1.1c8.9,0,16.7,5.6,19.6,14c3.7,10.8-2.1,22.6-12.9,26.2C74.1,99.4,71.8,99.8,69.6,99.8L69.6,99.8z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(RoundWoodShieldLeft, {
  character: "ManDwarf"
})
