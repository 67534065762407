import React from "react"

const CutlassR = ({ colors = ["#DDC300", "#FFFFFF", "#7C7A79"] }) => {
  return (
    <g id="CutlassR">
      <g fill="#21376C">
        <path
          opacity="0.2"
          d="M28.8463917,80.435379l0.241394,1.4215393c0,0,2.5480423,6.5712662,2.6553268,6.5980911 c0.1072865,0.0268173-0.2950363,0.3486786-0.2950363,0.3486786s-0.8582878,1.0460358-0.9655724,1.099678 c-0.1072865,0.0536423-4.3182621-9.2802353-4.4791908-9.253418c-0.1609287,0.026825,2.4139347-1.099678,2.4139347-1.099678 L28.8463917,80.435379z"
        />
        <path
          opacity="0.2"
          d="M28.5169296,80.8913422l0.241394,1.4215393c0,0,2.5480404,6.5712662,2.6553268,6.5980911 c0.1072865,0.0268173-0.2950363,0.3486786-0.2950363,0.3486786s-0.8582878,1.0460358-0.9655743,1.099678 c-0.1072845,0.0536499-4.3182602-9.2802353-4.4791889-9.2534103c-0.1609287,0.0268173,2.4139347-1.0996857,2.4139347-1.0996857 L28.5169296,80.8913422z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M25.4596558,79.814827c0.9448242-1.4263306,2.3114624-2.1846924,3.0524292-1.6938477 s0.5756836,2.0449829-0.3692017,3.4713745c-0.0882568,0.1332397,0.5351925,3.2369232,1.2667599,5.0790253 c0.5715942,1.4391479,1.141016,2.2198639,1.4721928,2.4970245l0.7942238-0.5390549l0.2548046-0.2145767 c0.0267944,0.1340942,0.455965,1.1801453-0.0402336,1.7970428c-0.4961967,0.6168976-1.599432,0.0223846-1.599432,0.0223846 c-0.8074341,0.8126221-2.1463623,1.7905884-3.5637207,1.2516479c-1.6522827-0.6282959-2.7625732-1.0192261-3.8890991-4.0232544 C21.711853,84.4586258,24.5147705,81.2412186,25.4596558,79.814827z"
      />
      <g fill="#21376C">
        <path
          opacity="0.42"
          d="M29.0073242,90.5940018 c0.0697021-0.0690308-3.2627563-2.4981689-3.0625-5.4521484c-0.0337524-1.3699341,0.4473877-3.7006836,0.4473877-3.7006836 s0.6065063-0.9297485,0.2615356-0.0804443c-1.9110451,7.5636597,3.1090088,8.3549194,3.1783447,8.328064 c-2.0317383-3.7415771-2.8945312-7.6993408-2.0719604-8.4487915c1.4658813-2.0050049,0.8431396-2.9202271,0.223877-3.071167 c1.9032593,0.178833-0.0227051,3.3528442-0.0227051,3.3528442s0.241394,4.0634155,2.776001,7.885498 c-1.6897583,2.0518188-2.6151123,2.1323242-4.0634766,1.8909302 C28.0834961,91.4262161,28.5227051,91.0742264,29.0073242,90.5940018z"
        />
        <path
          opacity="0.42"
          d="M27.9840088,78.1688309 c-0.1051636-0.0098877-0.2150879-0.0160522-0.3446045-0.0065918 C27.7439575,78.136116,27.8639526,78.139595,27.9840088,78.1688309z"
        />
      </g>
      <g>
        <path
          opacity="0.66"
          fill="#FFF"
          d="M24.5759277,87.8075638 c0.1981812-0.1146851-0.0071411-0.8665161,0.1726074-0.9849854c-0.4892578,0.3225708-0.9672241,0.661377-1.4451904,1.0003052 c-1.2174072-3.2531128,1.3631592-6.2875366,2.3138428-7.3302002c0.1330566,0.2229004,0.3233032,0.4092407,0.5735474,0.4442749 c0.1550903,0.0216675,0.2727051-0.024231,0.3771973-0.0916138c-0.6473389,1.7716675-0.9547729,3.2280884-1.0141602,4.4004517 c-0.2940063,0.6713257-0.3673706,1.3708496-0.0266724,2.2238159c0.0365601,0.0916138,0.0951538,0.2189941,0.1628418,0.3596191 c-0.5598755,0.4414673-1.1132812,0.8875122-1.6603394,1.3406372c-0.1757812-0.2439575-0.3237305-0.4879761-0.451416-0.7314453 C23.9074097,88.2230301,24.2349243,88.0050735,24.5759277,87.8075638z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M25.9188232,88.27491 c0.4081421,0.7578125,1.0372925,1.7421875,1.6551514,2.1994019c-0.5217285,0.2217407-1.3824463,0.4206543-2.3887329-0.0211182 c-0.369751-0.3283691-0.6677856-0.6595459-0.9349976-0.991333C24.7957153,89.0452347,25.3514404,88.650032,25.9188232,88.27491z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M31.9040432,88.562294 c0,0,0.4928455,1.2572556-0.2715683,1.7400436c-0.6738911,0.2212753-1.2974892-0.1508713-1.2974892-0.1508713 L31.9040432,88.562294z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M28.041748,78.8663254 c0,0,0.3218575,2.0518417-0.7644138,2.2932358c-0.4023228,0.7241821-0.7644119,6.5176239,2.2127743,8.2073746 c-1.126503-0.4023209-4.0232239-2.615097-2.615097-8.0866776C27.4382629,80.3549118,27.5187283,81.2400208,28.041748,78.8663254 z"
        />
        <path
          fill={colors[0]}
          d="M23.137352,84.4780579c0.0057373,0.0281982,0.0115356,0.0564575,0.0172729,0.0846558 c-0.0263062,0.2557373-0.3129215,0.4356461-0.1955509,0.7005386c0.178772,0.4033203,0.647068-0.0804672,0.9689255,0 c0.3218594,0.0804596,1.5324116,0.9512558,1.6753559,0.5432968c0.0895996-0.2557373,0.1332455-0.6944809,0.1987972-0.9322128 c0.0646973-0.2346802,0.232233-0.1531143,0.5571232-0.3498306c0.9116821-0.1048584,0.8764229-1.8073273,0.6364326-2.4530182 c-0.4505615-1.2125854-2.8164864-1.6243744-3.2027168-0.3642426c-0.4101582,0.2789688-0.5705242,0.7622681-0.5810242,1.2800446 c-0.0063,0.3106613-0.2122135,0.3946838-0.1321964,0.6934891c-0.079834,0.1923218,0.1095829,0.6269302,0.0826054,0.8266373 C23.1540146,84.4964905,23.1457138,84.4893494,23.137352,84.4780579z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M25.5875797,82.7487335 c0,0,0.3017426,0.3419724,0.3017426,0.4425583c0,0.1005783-0.2615089,0.4224319-0.2615089,0.4224319l-0.5632515-0.1408081 l-0.241394-0.4626694l0.5230198-0.3822098L25.5875797,82.7487335z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M24.279047,83.1826172 c0.0942783-0.023613,0.27425,0.4721375,0.1657085,0.7114944c-0.1085434,0.2393494-0.4032745-0.0570908-0.4032745-0.0570908 l-0.1159668,0.043602C23.9255142,83.8806229,23.9952354,83.2536926,24.279047,83.1826172z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M24.6220074,82.6079178 c0.0234375-0.2655029,0.2564106-1.3429565,1.555666,0.2279663c-0.0237427,0.0253296-0.8046646-0.8448563-1.0594692-0.3084259 c-0.1600342,0.1294556-0.2406368-0.1918564-0.3084469-0.1743393l-0.3218575,0.737587 c0.0268555,0.0402832,0.3662186,0.3324509,0.4023209,0.388916c0.0177765,0.0278015-0.2682133,0.0804596-0.3084469,0.4559631 c-0.020256,0.0347519-0.0268211-0.4291458-0.1609364-0.7912292c-0.0184631-0.0738373-0.1072788-0.0536499-0.2011528-0.0536499 c-0.0498333-0.0713272,0.2950363-0.4827881,0.2950363-0.4827881S24.5933266,82.6330872,24.6220074,82.6079178z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M24.1928635,81.9776154 c0,0,0.0522327,0.208992-0.151577,0.2587204c-0.2038078,0.0497284-0.3982639-0.0977936-0.639658,0.130188 C23.5893784,81.9373856,23.8173618,81.6557541,24.1928635,81.9776154z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M23.2079468,84.5749588 c0.0087891-0.0330811,0.0109253-0.0588989,0.008606-0.0800781c0.0805054-0.0871582,0.1838989-0.1682739,0.3325806-0.1837769 c0.2553711-0.0266724,0.9955444,0.2820435,1.4447021,0.0482178L24.635437,84.203804 c0,0,0.7288208,0.3155518,1.3962402-0.6030273c-0.1091919-0.083374-0.0836182-0.3586426-0.0551758-0.3491211 c0.0402222,0.0133667,0.2816162,0.241394,0.388916-0.2682495c0,0,0.2279663,0.3621216-0.1207275,0.5900879 c-0.0866699,0.0566406-0.1491089,0.0641479-0.1936646,0.0440674c0.0264282,0.124939,0.0750122,0.6153564-0.704834,0.8947144 l-0.3067017-0.1331177c0.0409546,0.1175537,0.0652466,0.6737671-0.0252075,0.8438721 c-0.0712891-0.0404053-0.1251831-0.0637817-0.1915283-0.0991821c0.0064087-0.0378418,0.0109863-0.0725098,0.0095825-0.0960693 c-0.0001831-0.0029297-0.0012817-0.0041504-0.0015259-0.007019c0.0011597-0.0036621,0.0023804-0.0073853,0.00354-0.0110474 c-0.0015869,0.0018311-0.0031128,0.0036011-0.0046997,0.0054321c-0.0172119-0.1959839-0.1257935-0.237854-0.2545166-0.0726318 c-0.0084839,0.0108643-0.0128174,0.0300903-0.0205688,0.0424805c-0.0516357-0.0250854-0.0908203-0.0386353-0.1390381-0.0605469 c0.0165405-0.0551147,0.0371094-0.1107178,0.0305786-0.1630249c-0.0214233-0.1707153-0.0975952-0.0994873-0.1740723,0.0056763 c-0.0170288,0.0233765-0.0241089,0.0529175-0.0377197,0.0784302c-0.0697632-0.0285645-0.1262817-0.0456543-0.1883545-0.0675049 c0.0265503-0.0748901,0.0368042-0.137085,0.0095215-0.1688843c-0.0864868-0.100769-0.1311035-0.0686035-0.2414551-0.0014648 c-0.0266113,0.0162354-0.0358887,0.0561523-0.0544434,0.0834351c-0.0751953-0.0180054-0.1229858-0.0167847-0.1838989-0.0244141 c-0.0009766-0.0059814,0.0002441-0.015564-0.0012817-0.0206909c-0.0011597-0.1374512-0.0443115-0.1546631-0.1294556-0.0514526 c-0.0145874,0.0101318-0.0158081,0.0369263-0.0270996,0.0512085c-0.1002197-0.0012207-0.1685181,0.0133667-0.2246094,0.0313721 C23.1997681,84.6372147,23.2039795,84.6057816,23.2079468,84.5749588z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M26.8750114,82.0044403 c0-0.120697,0.8448772,2.1591263-1.0326271,2.6955566c0.3755016-0.3620911,1.0996819-0.5632477,1.0728607-2.3468781 C26.8079586,82.1117249,26.8750114,82.0044403,26.8750114,82.0044403z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M23.6832542,83.8283005 c0,0-0.5498409-0.4023209-0.3755016-0.6303101C23.4820938,82.9700089,22.5433407,83.694191,23.6832542,83.8283005z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M26.6067963,81.7228088 c0,0-1.5958786-1.5019989-2.6419163-0.241394C24.4610767,81.3741302,25.4534721,81.0254517,26.6067963,81.7228088z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M25.5674648,85.0017395 c0.017601,0.0375519,0,0.6839447-0.1106396,0.6940079c-0.1106377,0.0100555-0.241394-0.2715683-0.2916832-0.291687 c-0.0502911-0.0201187,0.1508713-0.8649902,0.1508713-0.8147049 C25.3160133,84.6396484,25.4165936,84.6798782,25.5674648,85.0017395z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M23.4351559,84.0764008 c0.0201149,0.0603485-0.2881413,0.3905334-0.4023228,0.5230179s0.1005802-0.8046494,0.1005802-0.8046494L23.4351559,84.0764008z"
        />
        <polygon
          opacity="0.42"
          fill="#21376C"
          points="24.0989876,82.7688522 23.8374786,82.2860641 23.4552708,82.4067612 23.3748074,83.0504761 23.9380589,83.1108246"
        />
      </g>
      <g>
        <path
          fill={colors[2]}
          d="M14.4506168,39.3448524c0,0.3644905,4.9510326,16.9793053,13.1217537,39.3652611 c-0.744173,1.0327301-1.3364754,2.0654602-1.3364754,1.7009659 C12.9926443,46.5132179,14.4506168,39.3448524,14.4506168,39.3448524z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M14.4506168,39.3448524 c5.0877171,22.7200737,12.5598278,40.5954247,12.1953335,40.3524284 c-0.1822453,0.2733688-0.4100552,1.0782928-0.4100552,0.7137985 C12.9926443,46.5132179,14.4506168,39.3448524,14.4506168,39.3448524z"
        />
        <g fill="#FFF">
          <path
            opacity="0.66"
            d="M15.2966299,46.7657547 c-0.3106079-1.4916382-0.5192261-2.7181396-0.6536865-3.704895c0.2839966,0.2363281,0.5657349,0.475769,0.8480835,0.7143555 c0.3088989,1.2687378,0.6231089,2.5192261,0.9407969,3.7496948 C16.0537109,47.271431,15.6751699,47.0184402,15.2966299,46.7657547z"
          />
          <path
            opacity="0.66"
            d="M16.6102295,48.1974564 c0.2463989,0.9442749,0.4934082,1.8862915,0.7433472,2.8018188c-0.4094238-0.2105713-0.8184204-0.4204712-1.2259521-0.6306152 c-0.2704477-1.0775757-0.4959726-2.0490723-0.6895761-2.9396362 C15.8287954,47.6851273,16.2201538,47.9403152,16.6102295,48.1974564z"
          />
          <path
            opacity="0.66"
            d="M20.5025024,64.6590042 c-0.0249634-0.0174561-0.050415-0.0344238-0.0754395-0.0518188c-1.3779297-4.0512085-2.4471436-7.4949341-3.270874-10.4030151 c0.4390259,0.2600098,0.878418,0.5192871,1.3170166,0.7799072c1.1090698,3.8417969,2.2125854,7.3670654,3.2345581,10.4827881 C21.2959595,65.1974564,20.8895264,64.9289017,20.5025024,64.6590042z"
          />
          <path
            opacity="0.66"
            d="M23.0958252,72.1323318 c-0.3990479-1.0860596-0.7858276-2.1491089-1.1494751-3.1680298c0.4155273,0.296814,0.8262329,0.6000977,1.2393188,0.9002075 c0.3607788,1.0489502,0.7008667,2.0197144,1.0195312,2.9196777 C23.8380737,72.5623245,23.4647827,72.3510818,23.0958252,72.1323318z"
          />
          <path
            opacity="0.66"
            d="M24.8282471,76.7590408 c-0.5275879-1.3864136-1.0303345-2.7269287-1.5079956-4.0205078c0.0805664,0.0494385,0.1633301,0.0952148,0.2437744,0.1448364 c0.2927246,0.1804199,0.583374,0.366333,0.8733521,0.5546265c0.5628052,1.5797729,1.0393677,2.8837891,1.407959,3.8938599 C25.5080566,77.1377029,25.1677856,76.9489822,24.8282471,76.7590408z"
          />
        </g>
      </g>
    </g>
  )
}

export default CutlassR
