import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const ShortswordLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("ShortswordLeft") ? (
    <g id="ShortswordLeft">
      <path
        id="S_x24_shortswordLeft"
        d="M67.8079224,98.1450882c-1,0-2-0.1999969-2.9000015-0.5 c-4.2999954-1.5999985-6.6999969-6.3000031-5.2999954-10.6999969v-0.0999985 c0.0999985-0.4000015,0.2999992-0.8000031,0.5-1.1999969c-2.9000015-2.0999985-4.2999992-5.9000015-3.2000008-9.5v-0.0999985 c0.4000015-1.4000015,1.2000008-2.6999969,2.2999992-3.6999969c1.2999992-1.1999969,2.8999977-2,4.7000008-2.1999969 l8.4999962-30.4000053l2.5-3.7000008l4.0999985-3.2000008l11.1999969,3.7000008l1.3000031,4.9000015l-0.0999985,4 l-9.9000015,30.8999863c1.6999969,1.5999985,2.6999969,3.8000031,2.6999969,6.3000031c0,2.4000015-1,4.5999985-2.6999969,6.1999969 c-1.4000015,1.4000015-3.3000031,2.0999985-5.3000031,2.3000031c-0.0999985,0.3000031-0.0999985,0.6999969-0.1999969,1v0.0999985 c-0.4000015,1.4000015-1.1999969,2.6999969-2.3000031,3.6999969 C72.0079193,97.3450851,69.9079208,98.1450882,67.8079224,98.1450882z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(ShortswordLeft, {
  character: "ManHalfling"
})
