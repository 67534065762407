import React from "react"

const AxeRight = ({
  colors = ["#8A5F3C", "#6D6E71", "#D1D2D3", "#808184"]
}) => {
  return (
    <g id="AxeRight">
      <path
        fill={colors[0]}
        d="M31.9342785,78.7205811l-0.7267952-6.5411758 c-0.1453571-0.0726776-0.2907238-0.2180405-0.4360809-0.2907181l-2.7618313,0.5087585 c-0.0726757,0.1453629-0.1453571,0.2907181-0.2180424,0.3633957l1.9623528,6.3958206 c0,0,4.3607845,18.0245743,5.1602573,22.3126831c0.6541214,3.4886246,1.0175209,8.1401291,1.0901985,9.7390747 c0.2180405,0.1453629,0.4360771,0.2180481,0.6541214,0.2907257l2.1803894-1.0175247l-6.3231354-27.8363342L31.9342785,78.7205811z"
      />
      <path
        fill={colors[1]}
        d="M34.2600288,71.2345657c-0.4360809,1.1628799-2.1803932,2.3257599-4.4334641,2.7618408 c-2.1803932,0.4360733-4.2154255,0.0726776-5.0875816-0.8721619c-1.5262718,1.453598-2.2530708,3.3432617-1.8896713,5.2329407 c0.3633995,1.8896713,1.8169937,3.3432617,3.7793427,4.142746c0.436079-1.1628723,2.1803932-2.3257523,4.4334641-2.7618256 c2.1803932-0.4360886,4.2154236-0.0726852,5.0875816,0.8721542c1.5262718-1.4535904,2.2530708-3.3432693,1.8896713-5.2329407 C37.6759796,73.4876404,36.2223854,72.03405,34.2600288,71.2345657z"
      />
      <g fill={colors[2]}>
        <path d="M23.9395065,78.4298706c-0.1453571-0.8721619,0-1.7443161,0.3633995-2.616478 c0-0.0726776-0.3633995-0.3633881-0.5814419-0.5814362c0.5814419,0.2180481,0.726799,0.2180481,0.7994766,0.1453629 c0.3633995-0.5814362,0.726799-1.0901947,1.3082371-1.5989532c-0.4360771-0.1453629-0.8721523-0.3634033-1.0901947-0.6541214 c-1.5262718,1.453598-2.2530708,3.3432693-1.8896713,5.2329483c0.3633995,1.8896637,1.8169937,3.3432617,3.7793427,4.1427383 c0.0726795-0.2907181,0.2907219-0.5087585,0.5087566-0.726799C25.4657822,81.1190186,24.302906,79.9561462,23.9395065,78.4298706 z" />
        <path d="M34.2600288,71.2345657c-0.1453552,0.3634033-0.4360809,0.726799-0.726799,1.0175247 c1.7443123,0.5814362,3.1252327,1.8169937,3.4159508,3.4886246c0.0726814,0.5087662,0.0726814,1.0175171,0,1.4535904 c0,0.0726852,0.145359,0.0726852,0.145359,0.1453629c-0.0726776,0-0.0726776,0-0.145359,0 c0,0.0726776-0.0726776,0.3634033-0.0726776,0.4360809s0.145359,0.2180328,0.5087585,0.5814362 c-0.2180443-0.1453629-0.6541176-0.2180405-0.726799-0.1453629c-0.2907181,0.6541214-0.7267952,1.3082352-1.308239,1.8896713 c0.2907181,0.1453629,0.5814438,0.2907257,0.7267952,0.5087662c1.5262756-1.453598,2.2530746-3.3432693,1.8896751-5.2329407 C37.6032944,73.4876404,36.2223854,71.9613647,34.2600288,71.2345657z" />
      </g>
      <polygon
        fill={colors[3]}
        points="32.3703537,79.6654205 29.5358467,80.1741791 27.8642139,74.2144394 31.7889175,73.4149628"
      />
    </g>
  )
}

export default AxeRight
