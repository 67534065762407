import React from "react"

const BotaBagLeft = ({
  colors = [
    "#715C40",
    "#CDA774",
    "#B09064",
    "#A17955",
    "#C5962C",
    "#EED793",
    "#80682A"
  ]
}) => {
  return (
    <g id="BotaBagLeft">
      <path
        fill={colors[0]}
        d="M55.4,79.5c0,0,0.7-0.1,0.4-0.4c-0.3-0.4-22.5-24.9-22.5-24.9s-0.4-0.5-0.7-0.2c-0.3,0.2,0,0.7,0,0.7 L55.4,79.5z"
      />
      <path
        fill={colors[1]}
        d="M61.1,81c0,0,4.3,0.8,4,6.6c-0.3,5.8-4.5,5.4-7.5,0.3s-3.7-10.5-3.7-10.5l1.3-1 C55.2,76.4,57.9,81.9,61.1,81z"
      />
      <path
        fill={colors[2]}
        d="M55.8,82.7c-1.3-2.8-1.9-5.3-1.9-5.3l1.3-1c0,0,0.6,1.2,1.1,1.8C56.3,79.8,55.9,81.1,55.8,82.7z"
      />
      <path
        fill={colors[2]}
        d="M60.1,87.3c0.7-0.7,0.7-1.7,0-2.4c-0.1-0.1-0.2-0.1-0.3-0.1c0.2,0,0.2-0.1,0-0.2 c-0.2-0.2-0.6-0.2-0.8-0.2c-0.9,0-1.3,0.8-1.8,1.7c0,0.1,0-0.1,0,0c-0.3-0.5-0.5-1.1-0.8-1.6c0.4-1.7,0.6-3.5,0.5-5.4 c0.5,0.5,1.4,1.2,2,1.7c-0.3,0.3-0.6,0-0.6,0.4c0.1,0.6-0.3,0.9,0.3,1c0.5,0.1,1,0.2,1.3-0.3c0.1-0.2,0.2-0.5,0.2-0.8 c0.3,0,0.6,0,0.9-0.1c0,0,0.1,0,0.2,0c1.3,2.5,2,4.9,1.9,8.1c0,1-0.1,1.9-0.5,2.7c-1.5,0.1-3.4-1.2-5-3.9c0,0,1.1,0,1.1-0.1 C59.5,88.1,59.4,88,60.1,87.3z"
      />
      <path
        fill={colors[2]}
        d="M62.6,81.7c1.2,0.8,2.7,2.5,2.5,5.9c-0.1,1.3-0.4,2.3-0.8,3C65,87.6,64,84.5,62.6,81.7z"
      />
      <path
        fill={colors[3]}
        d="M62.1,91.8c0,0-1.8-0.1-3.5-1.8c-0.9-1-4.2-7.4-5.5-13.2l0.8,0.5C54.8,79.5,57.1,89.4,62.1,91.8z"
      />
      <path
        fill={colors[4]}
        d="M55.6,76.7l-1.5-2.9c0,0-1.2-0.6-1.1,0.4c0.1,1.5,0.1,3.3,0.1,3.3s1,1.4,2.3-0.5 C55.5,76.9,55.5,76.8,55.6,76.7z"
      />
      <path
        fill={colors[5]}
        d="M54.2,74.3c0,0-0.2,0.3-0.4,0.2c0.1,0.5,1,3.1,1,3.1l0.6-0.6L54.2,74.3z"
      />
      <ellipse
        transform="rotate(-86.811 53.553 74.116) scale(1.00005)"
        fill={colors[6]}
        cx="53.6"
        cy="74.1"
        rx="0.3"
        ry="0.5"
      />
    </g>
  )
}

export default BotaBagLeft
