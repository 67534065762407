import React from "react"

const BodyBase = ({ colors = [] }) => {
  return (
    <g id="BodyBase">
      <path
        id="D_x24_bodyBase"
        d="M60.6296692,120.8550644c-6.8663025,0-20.5989075,0-27.3579216,0 S32.3800621,103.2052078,32.6973,94.2910995c-0.4799232,0.0933609-3.9174728,0.108963-4.1572285-0.003418 c0.0224609,0.0048828,0.0449219,0.0102539,0.0678711,0.0151367c-14.6854744-5.4381485-1.5296574-24.0592957,3.7895527-30.729496 c-2.6593056-6.1535072-2.154604-23.9843597,9.6635742-18.2612305c1.0078125-0.331543,2.0507812-0.5253906,3.1103516-0.5742188 v0.0102539c1.2481613-0.0672722,2.5207901-0.0673294,3.769043,0v-0.0102539 c1.0600586,0.0488281,2.1030273,0.2431641,3.1103516,0.5742188c11.8119049-5.7174072,12.2947807,12.0582542,9.6826172,18.2094727 c1.183403,3.6334801,16.3688011,17.7247429,9.7998009,26.1626015c-1.7750168,2.5885696-3.0663223,4.2357788-6.2172852,4.7524414 l-0.0024414-0.0102539l-0.0102539-0.0004883l-0.0009766-0.0039062l-1.9038048,0.0986328 c0,0-1.3472519-0.0697174-1.9238281-0.2451172C61.8088226,103.5401382,67.4959717,120.8550644,60.6296692,120.8550644z"
      />
    </g>
  )
}

export default BodyBase
