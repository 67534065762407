import apiClient from '../ApiClient'
import axios from 'axios'
const host = "https://api.printablepawns.com"

const minis = {
  save: (payload) => apiClient().then(client => client.post(`/svg/create`, payload)),
  get: () => apiClient().then(client => client.get(`/pawns`)),
  recent: () => axios.get(`${host}/recent`),
  sheet: {
    create: (payload) => apiClient().then(client => client.post(`/sheets/create`, payload)),
    index: () => apiClient().then(client => client.get(`/sheets`))
  },
  file: {
    get: (id) => axios.get(`${host}/pawns/${id}`),
    patch: ({ id, payload }) => apiClient().then(client => client.patch(`/files/${id}`, payload))
  }
}

export default {
  minis
}