import React from "react"

const WarhammerL = ({
  colors = [
    "#B8B9BA",
    "#727474",
    "#E6E7E7",
    "#646565",
    "#7F8080",
    "#636565",
    "#CDCFCF",
    "#A0A0A1",
    "#D6D7D5"
  ]
}) => {
  return (
    <g id="WarhammerL">
      <path
        opacity="0.63"
        d="M55.6700668,103.8684082 c-0.1445045,0.0157623-0.2968864-0.0407257-0.4492683-0.0972214c-0.6174164-0.2982025-0.6883469-0.9484558-0.5753708-1.2532272 l1.1047935-2.6062927l0.1445045-0.0157623l7.9437714-18.9980469l-0.0157623-0.1445007l0.2824364-0.7619171l-0.0157623-0.1445007 l0.1129837-0.3047714l0.1444931-0.0157623l1.4516144-3.4483337l0.1366196-0.0880203l0.9353256-2.1491318l-0.0157623-0.1445084 l0.1694641-0.4571457l-1.3070755-0.5885239l-0.1839066-0.3454971l-0.3533783,0.1116562l-0.7698059-0.3546982l-0.3691406-0.0328369 l-1.4226646-2.9885788l0.2338333-0.5372849l3.1002388-1.0693054l0.2968826,0.0407333l0.7697983,0.3546906l0.1839066,0.3454895 l0.3533707-0.1116638l4.5951614,1.9113998l0.3533783-0.1116638l0.9221802,0.4111862l0.1116638,0.353363l0.6896667,0.290329 l0.103775,0.2811203l0.0722504-0.0078735l0.1825943,1.0036316l-0.0486069,0.2246399l-1.0483017,2.4539032l-0.128746,0.1602554 l-0.8197174,0.5280838l-0.3612595,0.0394135l-0.6975555-0.3625793l-0.2811203,0.1037827l-0.9221802-0.4111786 l-0.0959015-0.2088776l-1.3793411-0.5806427l-0.1694565,0.4571533L68.562439,74.606781l-0.8630829,2.1412506l0.0078735,0.0722504 l-1.4437256,3.5205917l0.0157623,0.1445007l-0.1129684,0.3047638l-0.1445084,0.0157623l-0.2824402,0.7619247l-0.0722427,0.0078735 l-8.0882759,19.0138245l0.0078773,0.0722504l-1.1047935,2.6062927 C56.4976768,103.4125748,56.1758232,103.8132324,55.6700668,103.8684082z"
      />
      <rect
        x="45.737"
        y="87.73"
        transform="rotate(-67.267 61.691 88.312)"
        fill={colors[0]}
        width="31.908"
        height="1.163"
      />
      <rect
        x="67.887"
        y="72.778"
        transform="rotate(-67.267 67.924 73.469)"
        fill={colors[1]}
        width="0.073"
        height="1.381"
      />
      <rect
        x="66.567"
        y="75.781"
        transform="rotate(-67.267 66.603 76.471)"
        fill={colors[1]}
        width="0.073"
        height="1.381"
      />
      <rect
        x="66.665"
        y="75.741"
        transform="rotate(-67.267 66.7 76.431)"
        fill={colors[2]}
        width="0.073"
        height="1.381"
      />
      <rect
        x="67.44"
        y="73.099"
        transform="rotate(-67.267 67.803 73.789)"
        fill={colors[3]}
        width="0.727"
        height="1.381"
      />
      <rect
        x="63.175"
        y="78.144"
        transform="rotate(-67.267 65.646 78.834)"
        fill={colors[4]}
        width="4.942"
        height="1.381"
      />
      <rect
        x="64.531"
        y="80.633"
        transform="rotate(-67.267 64.676 81.214)"
        fill={colors[5]}
        width="0.291"
        height="1.163"
      />
      <rect
        x="55.485"
        y="89.337"
        transform="rotate(-67.267 61.008 89.918)"
        fill={colors[4]}
        width="11.048"
        height="1.163"
      />
      <rect
        x="54.77"
        y="100.878"
        transform="rotate(-67.267 56.151 101.568)"
        fill={colors[4]}
        width="2.762"
        height="1.381"
      />
      <rect
        x="56.613"
        y="99.753"
        transform="rotate(-67.267 56.686 100.335)"
        fill={colors[6]}
        width="0.145"
        height="1.163"
      />
      <polyline
        fill={colors[7]}
        points="64.5150375,79.7273865 64.4020691,80.0321579 65.781395,80.6128006 65.8943787,80.3080292"
      />
      <path
        fill={colors[8]}
        d="M56.2493896,103.1472092l-1.226944-0.5241547c0,0-0.1458206,0.6739044,0.3835793,0.8354797 C55.8710556,103.7002563,56.2493896,103.1472092,56.2493896,103.1472092z"
      />
      <path
        fill={colors[7]}
        d="M64.3863373,71.8454514l0.7697983,0.3546906c0,0,0.0564957-0.1523819,0.1773453-0.3848953 c0.5215225,0.089325,0.3599472,0.6187286,0.3599472,0.6187286l6.5919037,2.7902374l1.6775665-4.0578766l-6.5919189-2.7902374 c0,0-0.2338333,0.5372849-0.7067413,0.22332c0.1694565-0.4571533,0.1694565-0.4571533,0.1694565-0.4571533 l-0.7697983-0.3546906l-3.1002274,1.0693054L64.3863373,71.8454514z"
      />
      <rect
        x="69.399"
        y="72.098"
        transform="rotate(-67.267 71.797 72.607)"
        fill={colors[3]}
        width="4.797"
        height="1.018"
      />
      <rect
        x="63.662"
        y="69.952"
        transform="rotate(-67.267 65.843 70.134)"
        fill={colors[3]}
        width="4.361"
        height="0.363"
      />
      <rect
        x="64.56"
        y="70.32"
        transform="rotate(-67.267 66.74 70.465)"
        fill={colors[3]}
        width="4.361"
        height="0.291"
      />
      <polygon
        fill={colors[3]}
        points="72.2853317,75.2242126 73.1694336,74.6159973 74.2177429,72.1620941 73.9628983,71.1663361"
      />
      <polygon
        fill={colors[3]}
        points="62.9715462,68.9291306 65.1929321,69.856575 64.3863373,71.8454514"
      />
      <path
        fill={colors[3]}
        d="M66.0967255,71.4395447l-0.4808044-0.3862228l-0.2824402,0.7619247 c0,0,0.5136414,0.0170822,0.3599472,0.6187363C65.9272537,71.8966904,66.0967255,71.4395447,66.0967255,71.4395447z"
      />
    </g>
  )
}

export default WarhammerL
