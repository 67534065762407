import React from "react"

const Nose = ({ colors = ["#ED1C24", "#SVGID_", "#FFFFFF"] }) => {
  return (
    <g id="Nose">
      <g id="C_x24_D_x24_noseWide">
        <linearGradient
          id="SVGID_5_"
          gradientUnits="userSpaceOnUse"
          x1="47.441"
          y1="699.346"
          x2="47.441"
          y2="693.029"
          gradientTransform="translate(.38 -638.04)"
        >
          <stop offset="0" stopColor={colors[0]} stopOpacity="0.5" />
          <stop offset="1" stopColor={colors[0]} stopOpacity="0" />
        </linearGradient>
        <path
          fill="url(#SVGID_5_)"
          d="M45.6532745,58.0316086c0,0-0.7292328,0.5237198-0.7828751,1.0601501 c-0.0536423,0.5364265,2.581028,2.2408524,2.581028,2.2408524l2.0654831-1.3753242 c0.6092453-0.4056702,0.6441574-1.2881851,0.0688667-1.7407227h-0.0000038 c-0.1506538-0.1185074-0.2657013-0.2744942-0.3363152-0.4526901c-0.2921333-0.7372169-1.1424255-2.730896-1.7945175-2.7312622 C46.85495,55.03228,45.6532745,58.0316086,45.6532745,58.0316086z"
        />
        <path
          opacity="0.3"
          fill="#21376C"
          d="M44.8435783,59.1990433 c0,0,0.4961357-0.2087784,0.8959122,0.1914482c0.3997803,0.4002228,0.638401,0.6318207,0.9382896,0.8319893 s1.0822868,0.3274269,1.5824509,0.0277061c0.5001144-0.1997223,0.5536499-0.5775146,1.153759-0.7771797 c0.6001091-0.1996689,0.5256729-0.0057487,0.5256729-0.0057487s-1.3410759,2.063179-2.4944,2.171505 C46.2919388,61.7470856,44.8435783,59.1990433,44.8435783,59.1990433z"
        />
        <ellipse
          opacity="0.66"
          fill="#FFF"
          cx="47.153"
          cy="59.332"
          rx="0.4"
          ry="0.3"
        />
      </g>
    </g>
  )
}

export default Nose
