import axios from 'axios'
import { Auth } from 'aws-amplify';

const host = "https://api.printablepawns.com"
let client

const getApiClient = async () => {
  const user = await Auth.currentAuthenticatedUser()
  const token = user.signInUserSession.idToken.jwtToken

  client = axios.create({
    baseURL: host,
    headers: {
      Authorization: token,
    }
  })

  return Promise.resolve(client)
}

export default getApiClient