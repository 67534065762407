import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import WarhammerR from "./WarhammerR"
import MaceR from "./MaceR"
import BowRight from "./BowRight"
import StaffRight from "./StaffRight"
import AxeRight from "./AxeRight"
import LuteRight from "./LuteRight"
import DaggerRight from "./DaggerRight"
import GoldAxeRight from "./GoldAxeRight"
import BattleAxeRight from "./BattleAxeRight"
import MorningStarRight from "./MorningStarRight"
import LongswordRight from "./LongswordRight"
import ShortswordRight from "./ShortswordRight"
import SlingShoRight from "./SlingShoRight"

const CATEGORY = "weaponRight"
const CHARACTER = "ManDwarf"
export const IDS = {
  WARHAMMER_R: "WarhammerR",
  MACE_R: "MaceR",
  BOW_RIGHT: "BowRight",
  STAFF_RIGHT: "StaffRight",
  AXE_RIGHT: "AxeRight",
  LUTE_RIGHT: "LuteRight",
  DAGGER_RIGHT: "DaggerRight",
  GOLD_AXE_RIGHT: "GoldAxeRight",
  BATTLE_AXE_RIGHT: "BattleAxeRight",
  MORNING_STAR_RIGHT: "MorningStarRight",
  LONGSWORD_RIGHT: "LongswordRight",
  SHORTSWORD_RIGHT: "ShortswordRight",
  SLING_SHO_RIGHT: "SlingShoRight"
}

export const components = {
  [IDS.WARHAMMER_R]: WarhammerR,
  [IDS.MACE_R]: MaceR,
  [IDS.BOW_RIGHT]: BowRight,
  [IDS.STAFF_RIGHT]: StaffRight,
  [IDS.AXE_RIGHT]: AxeRight,
  [IDS.LUTE_RIGHT]: LuteRight,
  [IDS.DAGGER_RIGHT]: DaggerRight,
  [IDS.GOLD_AXE_RIGHT]: GoldAxeRight,
  [IDS.BATTLE_AXE_RIGHT]: BattleAxeRight,
  [IDS.MORNING_STAR_RIGHT]: MorningStarRight,
  [IDS.LONGSWORD_RIGHT]: LongswordRight,
  [IDS.SHORTSWORD_RIGHT]: ShortswordRight,
  [IDS.SLING_SHO_RIGHT]: SlingShoRight
}

export const Group = ({ props }) => (
  <g id="weaponRight">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.WARHAMMER_R,
    name: "Warhammer R",
    defaultColors: [
      "#B9BABB",
      "#727373",
      "#E7E8E8",
      "#646464",
      "#7F8080",
      "#636464",
      "#CECFCF",
      "#A2A2A3",
      "#D6D7D6",
      "#A1A2A3"
    ],
    colorable: false,
    component: components[IDS.WARHAMMER_R],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MACE_R,
    name: "Mace R",
    defaultColors: [
      "#58595A",
      "#B9BABA",
      "#767575",
      "#9C9D9E",
      "#E1E2E3",
      "#E4E4E5"
    ],
    colorable: false,
    component: components[IDS.MACE_R],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BOW_RIGHT,
    name: "Bow Right",
    defaultColors: [
      "#939393",
      "#EED793",
      "#B5AC95",
      "#925C25",
      "#AA8B2F",
      "#FFCE06",
      "#42140B"
    ],
    colorable: false,
    component: components[IDS.BOW_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.STAFF_RIGHT,
    name: "Staff Right",
    defaultColors: [
      "#87551D",
      "#B78E5F",
      "#525A55",
      "#FFFFFF",
      "#27AAE1",
      "#8080BD",
      "#SVGID_",
      "#262A6B"
    ],
    colorable: false,
    component: components[IDS.STAFF_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.AXE_RIGHT,
    name: "Axe Right",
    defaultColors: ["#8B5E3C", "#6D6E71", "#D1D3D4", "#808285"],
    colorable: false,
    component: components[IDS.AXE_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LUTE_RIGHT,
    name: "Lute Right",
    defaultColors: ["#94712B", "#D9A53F", "#695020", "#B78C34", "#8F6D2B"],
    colorable: false,
    component: components[IDS.LUTE_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DAGGER_RIGHT,
    name: "Dagger Right",
    defaultColors: [
      "#6E5E26",
      "#9AB8DB",
      "#6B92C0",
      "#57779C",
      "#E2E4E5",
      "#A0A2A5",
      "#EED793"
    ],
    colorable: false,
    component: components[IDS.DAGGER_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_RIGHT,
    name: "Gold Axe Right",
    defaultColors: ["#8A7441", "#A49255", "#EECE6E"],
    colorable: false,
    component: components[IDS.GOLD_AXE_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BATTLE_AXE_RIGHT,
    name: "Battle Axe Right",
    defaultColors: ["#7A7B7D", "#505052"],
    colorable: false,
    component: components[IDS.BATTLE_AXE_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MORNING_STAR_RIGHT,
    name: "Morning Star Right",
    defaultColors: [
      "#949494",
      "#BDBDBC",
      "#D9D9D8",
      "#B78E5F",
      "#856745",
      "#523F2B",
      "#525252",
      "#C2C2C1",
      "#787879",
      "#SVGID_",
      "#FFFFFF",
      "#F6F6F6",
      "#E4E5E6",
      "#C8CACC",
      "#A2A4A7",
      "#757779",
      "#2D2D2E",
      "#000000"
    ],
    colorable: false,
    component: components[IDS.MORNING_STAR_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONGSWORD_RIGHT,
    name: "Longsword Right",
    defaultColors: [
      "#D0AA2B",
      "#A9ABAE",
      "#D6D8DB",
      "#B49330",
      "#SVGID_",
      "#EED793",
      "#7B6529",
      "#A38634",
      "#7951A1",
      "#FFFFFF",
      "#F6F6F6",
      "#E4E5E6",
      "#C8CACC",
      "#A2A4A7",
      "#757779",
      "#2D2D2E",
      "#000000"
    ],
    colorable: false,
    component: components[IDS.LONGSWORD_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SHORTSWORD_RIGHT,
    name: "Shortsword Right",
    defaultColors: ["#828284", "#CCCCCB", "#FFFFFF"],
    colorable: false,
    component: components[IDS.SHORTSWORD_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SLING_SHO_RIGHT,
    name: "Sling Sho Right",
    defaultColors: ["#362812", "#454545", "#949494", "#724E1F"],
    colorable: false,
    component: components[IDS.SLING_SHO_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
