import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Leggings from "./Leggings"
import ArmorBoots from "./ArmorBoots"
import WizardShoes from "./WizardShoes"
import BlousedPants from "./BlousedPants"

const CATEGORY = "legs"
const CHARACTER = "ManMixed"
export const IDS = {
  LEGGINGS: "Leggings",
  ARMOR_BOOTS: "ArmorBoots",
  WIZARD_SHOES: "WizardShoes",
  BLOUSED_PANTS: "BlousedPants"
}

export const components = {
  [IDS.LEGGINGS]: Leggings,
  [IDS.ARMOR_BOOTS]: ArmorBoots,
  [IDS.WIZARD_SHOES]: WizardShoes,
  [IDS.BLOUSED_PANTS]: BlousedPants
}

export const Group = ({ props }) => (
  <g id="legs">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LEGGINGS,
    name: "Leggings",
    defaultColors: ["#6B6B6B"],
    colorable: true,
    component: components[IDS.LEGGINGS],
    inUI: true,
    enabled: true,
    subGroupId: 2
  },
  {
    id: IDS.ARMOR_BOOTS,
    name: "Armor Boots",
    defaultColors: ["#898888", "#FFFFFF", "#4F4845"],
    colorable: true,
    component: components[IDS.ARMOR_BOOTS],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.WIZARD_SHOES,
    name: "Wizard Shoes",
    defaultColors: ["#40706A", "#FFFFFF"],
    colorable: true,
    component: components[IDS.WIZARD_SHOES],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.BLOUSED_PANTS,
    name: "Bloused Pants",
    defaultColors: ["#5E513F", "#4C3B22", "#685E4C", "#636100"],
    colorable: true,
    component: components[IDS.BLOUSED_PANTS],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
