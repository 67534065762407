import React from "react"

const PointedEyes = ({ colors = ["#78D1AD"] }) => {
  return (
    <g id="PointedEyes" fill={colors[0]}>
      <polygon points="67.2,16.3 67.2,16 67,15.8 66.7,15.5 65.6,15.4 64.7,16 66.4,16.4" />
      <polygon points="71.1,16.3 71.1,16 71.3,15.8 71.7,15.5 72.7,15.4 73.7,16 71.9,16.4" />
    </g>
  )
}

export default PointedEyes
