import React from "react"

const BlueFlameRight = ({
  colors = ["#80D0DC", "#30BFCA", "#5BC6D1", "#A8DDE6", "#C0E6F0", "#E5F5F8"]
}) => {
  return (
    <g id="BlueFlameRight">
      <path
        opacity="0.77"
        fill={colors[0]}
        d="M57.3271713,68.9213104 c-0.9110336-2.0172806-2.0172844-3.9694901-3.383831-5.7915573c-0.0650749,0-0.0650749,0-0.1301498,0.0650711 c-0.130146,0.0650749-1.0411758,0.5205917-0.9761047,0c0.1301498-0.9761047,0.3904457-2.2125053,0-3.1235352 c-0.2602921-0.7158089-0.3904419-2.7981644-0.9110298-3.383831c-0.0650749-0.130146-1.3014717-3.1886101-1.6268425-3.9694901 c-0.5205879,1.6919136,0.3253708,3.7742729-0.5856628,5.2709656c-0.0650711,0.0650711-1.7569885,3.6441231-1.8871346,3.7742729 c-0.0650749,2.4077225-0.2602959,4.8805237-0.7808838,7.223175c0.2602921-0.1301498,0.7158089-0.3253632,0.6507378-0.1301498 c-0.7158127,3.1886139-2.8632431,5.7915573-3.579052,8.9801636c0,0.1301498-0.1301498,0.2602997-0.2602959,0.3253708 c0.2602959,1.8871384,1.4316216,3.5790482,2.7330933,5.0757446c-0.7158089-1.6919098-1.2363968-3.3838272-1.2363968-5.2058868 c0-0.2602921,0.3904419-0.5205917,0.8459549-0.7808838c0.1301498-2.0823593,0.3253708-3.9694901,1.6268425-5.7915573 c1.5617676-2.2775726,1.0411797-4.7503738,1.4316216-7.0930252c-0.0650749,0-0.0650749,0-0.0650749-0.0650787 c0-0.5205879,0.0650749-1.0411758,0.130146-1.5617676v-0.0650711c0,0,0,0,0-0.0650749 c0.0650749-0.3904381,0.1301498-0.7808838,0.2602959-1.1713219c0-0.3253708,2.0823593-2.5378761,2.1474304-2.4077263 c0.1301498,0.5205879,0.3904419,0.9110298,0.3253708,1.3665428c0.0650711,0,0.130146,0,0.130146,0.0650749 c0,1.4316216,0.0650749,2.8632431,0.2602921,4.294857c0.0650749,0,0.0650749,0,0.1301498,0.0650787 c1.4966927,1.6268387,1.9522095,3.9694901,2.6680183,6.0518494c0.8459587,2.5378647,1.3014717,4.164711,1.3665466,6.8327332 c0,0.1301422-0.0650749,0.1952209-0.1301498,0.3253708c0.5205917,1.4966888-0.2602959,3.1885986-0.5856628,4.7503738 c-0.0650711,0.1952209-0.130146,0.455513-0.1952209,0.7158051c0.7158089-1.4316177,1.3014755-2.9283142,1.8871384-4.4900742 C57.4573174,75.6889725,57.3922462,72.3051376,57.3271713,68.9213104z"
      />
      <g opacity="0.98">
        <path
          fill={colors[1]}
          d="M52.1212769,85.2547989c-1.2363968-0.3904419-2.3426514-0.8459549-3.383831-1.4316177 c-0.2602921-0.455513-0.4555168-0.9761047-0.7158089-1.4316177c-0.5856628-1.2364044-1.0411797-2.5378799-1.4966927-3.9694977 c0.0650711-0.455513,0.130146-0.911026,0.2602921-1.3014755c0.130146-0.5205917,0.325367-1.1062469,0.4555168-1.6268387 c0.2602959-0.8459625,0.4555168-1.6919098,0.6507378-2.5378723l0.6507339-0.2602997l-0.325367-0.6507339 c0-0.0650711-0.0650749-0.0650711-0.0650749-0.1301422c1.3665466-1.6268463,2.1474304-3.253685,2.4077263-4.8154526 c0.0650749-0.5856628,0.0650749-1.1062546,0-1.6919174l0.3904419-0.1952209 c0.1301498,0.3904419,0.2602959,0.7158127,0.3904457,0.9761047c0.455513,1.1713257,1.0411758,2.2775803,1.5617638,3.3187561 c0.6507378,1.3014755,1.3014755,2.4728012,1.7569885,3.7742767c1.1062546,3.253685,0.9761047,6.7025909-0.455513,10.9974442 C53.5528984,84.7342072,52.8370895,85.0595779,52.1212769,85.2547989z M50.5595093,64.2360077 c-0.455513-0.4555168-0.7158089-1.1062508-0.7158089-1.7569885c0.0650749-0.5205879,0.2602959-1.0411758,0.6507339-1.3665466 C50.2341423,62.2187233,50.3642883,63.3249779,50.5595093,64.2360077L50.5595093,64.2360077z"
        />
        <path
          fill={colors[2]}
          d="M52.0562057,85.3849487c-1.0411797-0.3253708-1.9522095-0.7158051-2.8632393-1.1713333 c-0.1952209-0.3904343-0.3904457-0.8459549-0.5856667-1.2363968c-0.5205879-1.1713257-0.9761047-2.4077225-1.4316216-3.7091904 c0.0650749-0.3904495,0.1301498-0.7158203,0.1952209-1.0411835c0.1301498-0.5205917,0.2602959-1.0411758,0.4555168-1.6268463 c0.1952209-0.7157974,0.3904457-1.4316177,0.5856628-2.2124939l0.9761047-0.4555206l-0.5205879-1.0411758 c1.1713257-1.4966965,1.8871346-2.9283142,2.1474304-4.3599396c0.3253708,0.7808838,0.7158089,1.4966965,1.1062546,2.2125092 c0.6507339,1.2363968,1.3014717,2.4077301,1.6919136,3.709198c1.0411758,3.0584641,0.9110336,6.312149-0.3904419,10.2816467 C53.0973816,84.9945068,52.5767937,85.1897202,52.0562057,85.3849487z"
        />
        <path
          fill={colors[0]}
          d="M51.9911308,85.3849487c-0.8459549-0.2602921-1.6268425-0.5856628-2.3426514-0.9761047 c-0.1301498-0.3253708-0.3253708-0.6507416-0.4555168-1.0411758c-0.5205879-1.1062469-0.9110298-2.2125092-1.3665466-3.5139847 c0.0650749-0.3253708,0.1301498-0.5856552,0.1952209-0.8459549c0.130146-0.5205917,0.2602921-1.0411758,0.4555168-1.5617676 c0.1952209-0.5856705,0.325367-1.2364044,0.5205879-1.8871384l1.3665466-0.5856628l-0.6507378-1.3014755 c0.6507378-0.8459549,1.1713257-1.6919174,1.4966927-2.6029434c0.1301498,0.1952209,0.1952209,0.455513,0.3253708,0.6507339 c0.6507339,1.2363968,1.2364006,2.4077225,1.6919174,3.5790558c0.9761047,2.8632355,0.8459549,5.9216919-0.2602959,9.6309052 C52.5767937,85.1246567,52.3165016,85.2547989,51.9911308,85.3849487z"
        />
        <path
          fill={colors[3]}
          d="M51.9260559,85.4500198c-0.6507378-0.1952209-1.2363968-0.455513-1.7569885-0.7158127 c-0.1301498-0.2602921-0.2602921-0.5205917-0.3904419-0.7808762c-0.4555168-1.0411758-0.8459549-2.0823593-1.2364006-3.253685 c0.0650749-0.1952209,0.0650749-0.3904343,0.1301498-0.5856628c0.130146-0.5205917,0.2602959-1.0411835,0.4555168-1.5617752 c0.130146-0.5205841,0.325367-1.0411682,0.455513-1.5617599l1.7569923-0.7808838l-0.8459587-1.5617752 c0.2602959-0.3904343,0.5205879-0.7158051,0.7158127-1.1062469c0.5205879,0.9761047,0.9761047,1.9522095,1.3014717,2.9283142 c0.9110298,2.6029434,0.8459587,5.4661942-0.1952209,8.9150925 C52.1212769,85.3198776,52.0562057,85.3849487,51.9260559,85.4500198z"
        />
        <path
          fill={colors[4]}
          d="M51.6657639,85.1897202c-0.3904457-0.1301422-0.7158127-0.2602844-1.0411797-0.455513 c-0.0650749-0.1952209-0.1952209-0.3904343-0.2602959-0.5205917c-0.455513-0.9761047-0.8459549-1.9522095-1.1713219-2.9933777 c0-0.1301498,0.0650711-0.2602997,0.0650711-0.3904495c0.130146-0.5205917,0.2602959-1.0411682,0.4555168-1.5617599 c0.130146-0.3904495,0.2602959-0.8459625,0.3904419-1.3014755l1.6919136-0.7158127 C52.5767937,79.5933914,52.5117188,82.1312637,51.6657639,85.1897202z"
        />
        <path
          fill={colors[5]}
          d="M51.7959099,85.5150909C51.730835,85.3849487,51.730835,85.31987,51.66576,85.1897202 c-0.3904419-0.911026-0.7808838-1.7569809-1.1062508-2.7330856c0-0.0650711,0-0.1301498,0.0650749-0.1952286 c0.130146-0.5205917,0.2602921-1.0411682,0.3904419-1.4966965c0.0650749-0.3253632,0.1952171-0.6507339,0.2602921-0.9761047 l0.6507378-0.2602921C52.4466438,81.2853012,52.4466438,83.2375107,51.7959099,85.5150909 C51.8609848,85.5150909,51.8609848,85.5150909,51.7959099,85.5150909z"
        />
      </g>
    </g>
  )
}

export default BlueFlameRight
