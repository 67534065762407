import React from "react"

const ShortswordRight = ({ colors = ["#828284", "#CCCCCB", "#FFFFFF"] }) => {
  return (
    <g id="ShortswordRight">
      <path d="M18.8,79.3c0.3,0.3,0.6,0.4,1,0.4h0.1c0.8,0,1.4-0.7,1.4-1.5c0-0.2,0-0.4-0.1-0.6l2.8-1c0.2,2.6,1,5.1,2.6,7.6 c-0.4,0.4-0.6,1-0.4,1.5v0.1c0.1,0.2,0.2,0.4,0.4,0.6c0.4,0.4,0.9,0.5,1.5,0.3c0.8-0.3,1.2-1.1,0.9-1.9v-0.1 c-0.1-0.2-0.2-0.4-0.4-0.6s-0.5-0.3-0.8-0.4c-0.2-3.1-1.2-5.9-2.1-7.8l3.1-1.1v0.1c0.1,0.2,0.2,0.4,0.4,0.6 c0.4,0.4,0.9,0.5,1.5,0.3c0.8-0.3,1.2-1.1,0.9-1.9v-0.1c-0.1-0.2-0.2-0.4-0.4-0.6c-0.4-0.4-0.9-0.5-1.5-0.3 c-0.2,0.1-0.4,0.2-0.5,0.3l-2.9,1L16,37.2L11.9,34l-1.1,5l11.6,36.2l0,0l0.2,0.5l-2.8,1l0,0c-0.8,0-1.4,0.7-1.4,1.5 C18.4,78.7,18.5,79.1,18.8,79.3z" />
      <g id="longsworsShadow_2_">
        <path
          fill={colors[0]}
          d="M19.8,77L19.8,77l3-1.1l-11.6-37l1.1-4.2l3.4,2.8l10.5,37.2l3.3-1.2c0.1-0.1,0.3-0.2,0.5-0.3 c0.7-0.2,1.4,0.1,1.6,0.8v0.1c0.2,0.7-0.2,1.4-0.8,1.6l0,0c-0.7,0.2-1.4-0.1-1.6-0.8v-0.1c0-0.1,0-0.2-0.1-0.2l-3.6,1.3 c0.9,1.9,2,4.8,2.1,8.1c0.5,0,1,0.3,1.2,0.8v0.1c0.2,0.7-0.2,1.4-0.8,1.6l0,0c-0.7,0.2-1.4-0.1-1.6-0.8v-0.1 c-0.2-0.5,0-1.1,0.5-1.4c-1.5-2.3-2.6-4.8-2.7-7.9l-3.3,1.2c0.1,0.2,0.2,0.4,0.2,0.7c0,0.7-0.6,1.3-1.2,1.3h-0.1 c-0.7,0-1.2-0.5-1.3-1.2C18.6,77.6,19.2,77,19.8,77z"
        />
        <polygon
          fill={colors[1]}
          points="12.4,34.8 24.7,75.1 22.6,75 11.2,38.9"
        />
      </g>
      <polygon fill="#FFF" points="24.7,75.1 12.4,34.9 24.2,75.1" />
      <polygon
        fill={colors[1]}
        points="28.9,74.5 21,77.4 20.8,77.2 28.8,74.4"
      />
      <path
        fill={colors[1]}
        d="M31.5,74.5c0,0-0.8,1.6-2.1-0.1c-0.1,0-0.2,0-0.2,0s0.2,1.3,1.4,1.2C31.3,75.4,31.5,74.5,31.5,74.5z"
      />
      <path
        fill={colors[1]}
        d="M21.1,78.1c0,0-1.2,1.8-2.2-0.2c-0.2,0.7,0.6,1.4,0.6,1.4S20.9,79.7,21.1,78.1z"
      />
    </g>
  )
}

export default ShortswordRight
