import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SlingShotLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SlingShotLeft") ? (
    <g id="SlingShotLeft">
      <path
        id="slingshotL"
        d="M79.5,119.5c1.9,0.1,6.7-0.4,5.8-9.4l-1.8-32.6L83,75.2l-0.9-2.1l-3.5-3.7l-7.4-0.7L65,71.6 c0,0,1.2,39.8,1.1,39.9l0.2,7.9C66.4,119.4,76.9,119.4,79.5,119.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SlingShotLeft, {
  character: "ManDwarf"
})
