import React from "react"

const ShoulderPlateGold = ({ colors = ["#8A784E", "#EED793", "#A59A76"] }) => {
  return (
    <g id="ShoulderPlateGold">
      <path
        opacity="0.42"
        fill="#21376C"
        d="M39.5139694,59.0133514 c-0.4676933-0.7794876-5.924118-3.0400085-8.8861732,0.7015419c0,0,0.3117943,1.5589752,0.3897438,3.351799 c1.1692352,1.40308,2.8841095,1.7928276,5.0666809,1.7928276c1.0133324,0,1.79282-0.2338486,2.1825676-0.7794876 c0.0779457-0.0779495,0.0779457-0.1559029,0.1558952-0.3897476 C38.890377,62.4431038,39.5139694,59.0133514,39.5139694,59.0133514z"
      />
      <path
        fill={colors[0]}
        d="M30.316,58.7015533c0,0-1.2471828,1.558979-1.0912838,3.3518028 c2.0266705,1.0133362,7.5610428,2.8061562,8.7302742,0.9353867c0.3897438-0.5456429,1.2471809-4.9887276,1.2471809-4.9887276 C38.734478,57.2984772,33.2001076,55.0379601,30.316,58.7015533z"
      />
      <path
        fill={colors[1]}
        d="M38.5785789,58.1559143c-0.5456429-0.7015419-4.2871857-2.1825676-7.2492428,0.0779495 C35.6165237,61.2738686,38.5006332,58.2338638,38.5785789,58.1559143z"
      />
      <path
        fill={colors[2]}
        d="M30.1601028,59.0133514C30.1601028,59.0133514,30.4718971,58.5456581,30.1601028,59.0133514 c-0.3117962,0.3897438-1.0912838,1.7148781-0.9353867,3.0400085c2.0266705,1.0133324,7.5610428,2.80616,8.7302742,0.9353867 c0.2338448-0.3117981,0.7015419-2.6502647,0.9353867-3.8194962 C37.4093475,60.6502762,34.7590866,62.9107971,30.1601028,59.0133514z"
      />
      <g>
        <path
          opacity="0.42"
          fill="#21376C"
          d="M57.8003731,58.9336777 c0.4676933-0.7794876,5.924118-3.0400047,8.8861732,0.7015419c0,0,0.2338486,1.1692314,0.0779419,2.9620552 c-1.4030762,1.7148743-3.4297485,2.1825714-5.6123161,2.1825714c-1.0133362,0-1.7928238-0.2338486-2.1825676-0.7794876 c-0.0779495-0.0779495-0.0779495-0.1559029-0.155899-0.3897476C58.4239655,62.36343,57.8003731,58.9336777,57.8003731,58.9336777 z"
        />
        <path
          fill={colors[0]}
          d="M66.9983444,58.6218834c0,0,1.2471771,1.5589752,1.0912781,3.351799 c-2.0266647,1.0133362-7.561039,2.8061562-8.7302704,0.9353867c-0.3897438-0.5456429-1.2471809-4.9887276-1.2471809-4.9887276 C58.5798607,57.2188034,64.1142349,54.9582863,66.9983444,58.6218834z"
        />
        <path
          fill={colors[1]}
          d="M58.7357597,58.0762405c0.5456429-0.7015381,4.2871895-2.1825676,7.2492485,0.0779495 C61.6978149,61.1941986,58.8137093,58.1541901,58.7357597,58.0762405z"
        />
        <path
          fill={colors[2]}
          d="M67.1542358,58.9336777C67.1542358,58.9336777,66.8424454,58.4659882,67.1542358,58.9336777 c0.3117981,0.3897438,1.0912857,1.7148781,0.9353867,3.0400085c-2.0266724,1.0133362-7.5610428,2.80616-8.730278,0.9353867 c-0.2338448-0.3117981-0.7015419-2.6502609-0.9353867-3.8194962 C59.904995,60.5706024,62.5552559,62.8311234,67.1542358,58.9336777z"
        />
      </g>
    </g>
  )
}

export default ShoulderPlateGold
