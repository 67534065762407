import React from "react"

const Brows = ({ colors = ["#231F20"] }) => {
  return (
    <g id="Brows">
      <path
        fill={colors[0]}
        d="M62.9477539,52.5899391c0,0,2.1999969-1.9000015,3.0999985,0.9000015 C65.0477524,52.0899391,63.747757,52.0899391,62.9477539,52.5899391z"
      />
      <path
        fill={colors[0]}
        d="M73.8477554,52.5899391c0,0-2.1999969-1.9000015-3.0999985,0.9000015 C71.6477585,52.0899391,72.9477539,52.0899391,73.8477554,52.5899391z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M64.247757,52.1899414 c-0.5,0-1.2000008,0.0999985-1.4000015,0.5999985c-0.2000008,0.4000015,0.2999992,0.7999992,0.7000008,1.0999985v-0.0999985 c0.4999962-1,1.7000008-0.2999992,1.3999977,1.0999985c0,0,0.5,0,0.6999969-0.2999992 C66.747757,53.6899414,65.5477524,52.2899399,64.247757,52.1899414z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M73.0477524,52.1899414 c-1-0.4000015-1.8000031,0.5-1.8000031,0.5c-0.0999985,0.0999985-1,1.0999985,0,2.2000008 c0.0999985,0.0999985,0.1999969,0.0999985,0.3000031,0.0999985l0,0c-0.0999985-0.0999985-0.0999985-1,0.1999969-1.2999992 c0.5-0.7000008,1.1999969,0.2000008,1.1999969,0.4000015c0.3000031-0.0999985,0.6999969-0.2999992,0.8000031-0.5999985 C74.0477524,52.7899399,73.6477585,52.2899399,73.0477524,52.1899414z"
      />
    </g>
  )
}

export default Brows
