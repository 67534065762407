import React from "react"

const GoatHorns = ({ colors = ["#70706E"] }) => {
  return (
    <g id="GoatHorns" fill={colors[0]}>
      <path d="M65.9,11.7c0.2,0,0.4,0,0.5,0.1c-2.1-6.6-4.6-7.1-4.6-6.8c0,0,1.3,1.7,2.8,7.5 C64.9,12,65.4,11.7,65.9,11.7z" />
      <path d="M72.4,11.7c-0.2,0-0.4,0-0.5,0.1C74,5.2,76.5,4.7,76.5,5c0,0-1.3,1.7-2.8,7.5C73.4,12,72.9,11.7,72.4,11.7 z" />
    </g>
  )
}

export default GoatHorns
