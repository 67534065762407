import React from "react"

const StrapsLb = ({ colors = ["#573B25"] }) => {
  return (
    <g id="StrapsLb">
      <path
        fill={colors[0]}
        d="M76.0455246,89.5470047c2.8822861,0.1330795,5.7369537-0.0150986,8.589798-0.4458313 c0.0379333-0.0057449,0.2074356,1.7292023,0,1.7605286c-1.2891769,0.1946487-2.5790024,0.3233261-3.8712387,0.4026794 c1.3537598,0.2374191,2.7075119,0.4747849,4.0613251,0.712204c0.215065,0.0377731,0.0284195,1.7655182,0,1.7605209 c-0.9463501-0.1659622-1.8926392-0.331871-2.8389816-0.4978333c-1.7685852,0.3607254-3.4985886,0.8589859-5.1975937,1.5065536 c0.0393829-0.0150375-0.1489334-0.8235245,0-0.8802567c1.0158157-0.3872147,2.0447388-0.7126389,3.0840302-0.9969025 c-1.1961212-0.2097549-2.392189-0.4195023-3.5883102-0.6292496c-0.1102982-0.0193405-0.1145477-0.4820938-0.0890808-0.9286194 c-0.0500717-0.0021439-0.0998764-0.0009613-0.1499481-0.003273 C75.8965378,91.3006439,75.9440308,89.5423279,76.0455246,89.5470047z"
      />
    </g>
  )
}

export default StrapsLb
