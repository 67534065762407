import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import QuiverLeft from "./QuiverLeft"
import QuiverRight from "./QuiverRight"

const CATEGORY = "gear"
const CHARACTER = "WomanGnome"
export const IDS = {
  QUIVER_LEFT: "QuiverLeft",
  QUIVER_RIGHT: "QuiverRight"
}

export const components = {
  [IDS.QUIVER_LEFT]: QuiverLeft,
  [IDS.QUIVER_RIGHT]: QuiverRight
}

export const Group = ({ props }) => (
  <g id="gear">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.QUIVER_LEFT,
    name: "Quiver Left",
    defaultColors: [
      "#3B2314",
      "#8A5D3B",
      "#C3996B",
      "#6E634F",
      "#4076B7",
      "#42A2DA",
      "#9E7731",
      "#359B5A",
      "#63C197"
    ],
    colorable: false,
    component: components[IDS.QUIVER_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.QUIVER_RIGHT,
    name: "Quiver Right",
    defaultColors: [
      "#3B2314",
      "#8A5D3B",
      "#C3996B",
      "#6E634F",
      "#4076B7",
      "#42A2DA",
      "#9E7731",
      "#359B5A",
      "#63C197"
    ],
    colorable: false,
    component: components[IDS.QUIVER_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
