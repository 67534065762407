import React from "react"

const Nose = ({ colors = ["#EC1E24", "#SVGID_", "#353891", "#FFFFFF"] }) => {
  return (
    <g id="Nose">
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="68.338"
        y1="59.886"
        x2="68.311"
        y2="52.543"
      >
        <stop offset="0" stopColor={colors[0]} />
        <stop offset="1" stopColor={colors[0]} stopOpacity="0" />
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M68.3259964,53.4936829l-0.3235474,0.5811653c0,0-0.6988678,1.8781052-0.861496,1.9812508 c-0.2649384,0.1680412-0.359848,0.5599403-0.3745651,0.5547523c-0.0749054-0.0264168,0.3745651,0.5283318,0.4494781,0.501915 s0.2060089-0.0792503,0.430748,0.1849174s0.2247391,0.5019188,0.4120178,0.6604195h0.534729 c0.1872787-0.1585007,0.1872787-0.3962517,0.4120178-0.6604195s0.355835-0.2113342,0.430748-0.1849174 s0.5243835-0.5283318,0.4494705-0.501915c-0.0147095,0.005188-0.1096191-0.3867111-0.3745575-0.5547523 c-0.1626282-0.1031456-0.861496-1.9812508-0.861496-1.9812508L68.3259964,53.4936829L68.3259964,53.4936829z"
      />
      <path
        opacity="0.32"
        fill={colors[2]}
        d="M66.8235245,56.7438889c0-0.0400543,0.1277924-0.1802521,0.6105576,0.1001434 c0.4827728,0.2803955,0.6389542,0.7009888,0.9371414,0.7009888c0.2981796,0,0.5504227-0.3906555,0.9371338-0.7210197 c0.1836624-0.1569023,0.4456024-0.1149483,0.539566-0.1401939c0.0148697,0.1678047-0.3946686,0.4700508-0.7525482,0.8011284 c-0.3255539,0.3011703-0.425972,0.6208725-0.8235474,0.6208725c-0.1673508,0-0.3726883-0.3372955-0.6531525-0.6208725 C67.2328339,57.0948181,66.8235245,56.7438889,66.8235245,56.7438889z"
      />
      <ellipse
        opacity="0.3"
        fill="#FFF"
        cx="68.229"
        cy="57.174"
        rx="0.142"
        ry="0.18"
      />
    </g>
  )
}

export default Nose
