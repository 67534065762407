import React from "react"

const Panties = ({ colors = ["#9EDFE8"] }) => {
  return (
    <g id="Panties">
      <path
        fill={colors[0]}
        d="M57.4158249,59.3724823c0,0,7.2514877,1.2654381,9.6352921,6.0597076 c1.5458679,0.8859634,2.9810791,0,2.9810791,0s6.634758-6.6028404,9.7728729-5.9390106 c0.0495071-0.9881477-0.2816315-1.4885941-0.2816315-1.4885941s-6.4718399,2.859417-10.981781,2.859417 c-4.5099487,0-10.9045563-2.7186012-10.9045563-2.7186012L57.4158249,59.3724823z"
      />
    </g>
  )
}

export default Panties
