import React from "react"
import { withSkinColor } from "../../builderConnector"

const LeftThumb = ({ skinColor, color = [] }) => {
  return (
    <g id="LeftThumb">
      <path
        fill={skinColor}
        d="M96.5,53.4c-0.1-0.1-0.6-0.8-1.2-1S94,52,93.4,52.8c-1.7,1.9-1.8,1.4-1.9,1.7c-0.1,0.4,0.9,2.6,1,2.9 c0,0,0.5,0.3,0.8,0s0,0,0,0l0.8-1.5L96.5,53.4z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M96.5,53.3c-0.1-0.1-0.6-0.8-1.2-1s-1.3-0.4-1.9,0.4 c-1.7,1.9-1.8,1.4-1.9,1.7c-0.1,0.4,0.9,2.6,1,2.9c0,0,0.5,0.3,0.8,0s0,0,0,0l0.8-1.5L96.5,53.3z"
      />
    </g>
  )
}

export default withSkinColor(LeftThumb, { character: "ManMixed" })
