import React from "react"

const BareFeet = ({ colors = ["#6D4724"] }) => {
  return (
    <g id="BareFeet">
      <path
        fill={colors[0]}
        d="M56.2129631,113.8792267c0.7940674-0.0623169,1.6051636-0.0701904,2.4483643-0.0211182 c0.0471802,0.0027466,0.0964355,0.0044556,0.144104,0.006897c0.0602417,0.0396118,0.118042,0.0764771,0.1821899,0.1209106 c-1.1294556-0.0726318-2.3064575-0.0720215-3.4518433,0.0592041 C55.7615471,113.9902496,55.9883537,113.9356232,56.2129631,113.8792267z"
      />
      <path
        fill={colors[0]}
        d="M54.1579704,114.6585236c1.6611328-0.522644,3.3214111-0.7247314,5.0997314-0.4187622 c-0.2363892,0.039856-0.4839478,0.1046143-0.6947632,0.0742188c0.1899414,0.0274048,0.2753906,0.046936,0.402771,0.1364136 c-0.036438-0.0025635-0.0778809,0.0066528-0.1114502-0.0025024c0.0529785,0.0144043,0.1068726,0.0268555,0.1607666,0.0390625 c0.0133057,0.0103149,0.0237427,0.0164795,0.038269,0.0283813c0.0078125,0.0064087,0.0394897-0.0061646,0.0548706-0.0057373 c0.0720825,0.0161743,0.1444702,0.0315552,0.2149658,0.0522461c-1.7559814-0.1777344-3.6184082-0.1621094-5.2404175,0.3086548 C54.1039543,114.7992096,54.1335564,114.7296906,54.1579704,114.6585236z"
      />
      <path
        fill={colors[0]}
        d="M59.884716,114.7299957c0.0223999,0.0233765,0.0361938,0.0482178,0.0574951,0.0718994 c-0.152832-0.0106812-0.3052979-0.0244751-0.4605103-0.0370483c-0.1602783-0.0130005-0.3625488,0.0088501-0.5513306,0.0275879 c-0.1118164,0.0056152-0.2237549,0.0112305-0.3355713,0.0168457c-0.0245972-0.0007324-0.055481,0.0018921-0.076416-0.0009155 c0.4746094,0.0635986,0.9645386,0.0866699,1.3997192,0.2944946c-1.907959-0.0892334-3.8097534-0.0674438-5.7033691,0.0964966 C56.009655,114.6105499,58.0369987,114.4513092,59.884716,114.7299957z"
      />
      <path
        fill={colors[0]}
        d="M54.3975334,115.6444244c0.3087158-0.0410767,0.6114502-0.1028442,0.9174805-0.1589966 c-0.1298218,0.0134277-0.3877563-0.0770264-0.6262817-0.1157837c1.8481445-0.1973877,3.6934204-0.2834473,5.6012573-0.0367432 c0.0221558,0.0522461,0.0580444,0.1044312,0.0714111,0.1564331c-0.4887695-0.0136719-0.9810791-0.0067749-1.4746094,0.0131226 c-1.5510864-0.0012817-2.9943237,0.137207-4.5615234,0.4196777c-0.0895386,0.0161133-0.2646484,0.0549316-0.4869995,0.1060791 c0.0115967-0.0875854,0.0308228-0.2016602,0.0534058-0.3248901 C54.0673943,115.6841583,54.2398796,115.6654205,54.3975334,115.6444244z"
      />
      <path
        fill={colors[0]}
        d="M60.3816032,115.6735382c0.0005493,0.0328369,0.0001831,0.0655518-0.0001831,0.0982666 c-0.1382446-0.0148926-0.2770996-0.0227661-0.4169922-0.0424194c-0.1170044-0.0164795-0.2510986,0.0003662-0.3867188,0.0202026 c-0.175354,0.0091553-0.350647,0.0183105-0.526001,0.0274658c0.0256348-0.0002441,0.050354,0.0075073,0.0759277,0.0078735 c-0.0493774-0.0023193-0.100647-0.0009155-0.1444702-0.0137329c0.3326416,0.0973511,0.8613281,0.0946045,1.21521,0.2727661 c-0.0823364,0.0539551-0.185791,0.1018677-0.3103027,0.1434326c-1.272583-0.2805176-2.5350952-0.3164673-3.8012085-0.2747803 C57.523632,115.6429596,58.9701653,115.4878082,60.3816032,115.6735382z"
      />
      <path
        fill={colors[0]}
        d="M54.9760857,116.1374664c0.0957031-0.020813,0.1906128-0.0429688,0.286438-0.0634766 c1.255249-0.0525513,2.4976807-0.0294189,3.7531738,0.2858276c-0.2925415,0.03302-0.6207886,0.0512085-0.9591064,0.0631714 C57.0489616,116.2879791,56.0097771,116.1650543,54.9760857,116.1374664z"
      />
      <path
        fill={colors[0]}
        d="M54.3975334,115.6444244c0.3087158-0.0410767,0.6114502-0.1028442,0.9174805-0.1589966 c-0.1298218,0.0134277-0.3877563-0.0770264-0.6262817-0.1157837c1.8481445-0.1973877,3.6934204-0.2834473,5.6012573-0.0367432 c0.0221558,0.0522461,0.0580444,0.1044312,0.0714111,0.1564331c-0.4887695-0.0136719-0.9810791-0.0067749-1.4746094,0.0131226 c-1.5510864-0.0012817-2.9943237,0.137207-4.5615234,0.4196777c-0.0895386,0.0161133-0.2646484,0.0549316-0.4869995,0.1060791 c0.0115967-0.0875854,0.0308228-0.2016602,0.0534058-0.3248901 C54.0673943,115.6841583,54.2398796,115.6654205,54.3975334,115.6444244z"
      />
      <path
        fill={colors[0]}
        d="M60.3816032,115.6735382c0.0005493,0.0328369,0.0001831,0.0655518-0.0001831,0.0982666 c-0.1382446-0.0148926-0.2770996-0.0227661-0.4169922-0.0424194c-0.1170044-0.0164795-0.2510986,0.0003662-0.3867188,0.0202026 c-0.175354,0.0091553-0.350647,0.0183105-0.526001,0.0274658c0.0256348-0.0002441,0.050354,0.0075073,0.0759277,0.0078735 c-0.0493774-0.0023193-0.100647-0.0009155-0.1444702-0.0137329c0.3326416,0.0973511,0.8613281,0.0946045,1.21521,0.2727661 c-0.0823364,0.0539551-0.185791,0.1018677-0.3103027,0.1434326c-1.272583-0.2805176-2.5350952-0.3164673-3.8012085-0.2747803 C57.523632,115.6429596,58.9701653,115.4878082,60.3816032,115.6735382z"
      />
      <path
        fill={colors[0]}
        d="M54.9760857,116.1374664c0.0957031-0.020813,0.1906128-0.0429688,0.286438-0.0634766 c1.255249-0.0525513,2.4976807-0.0294189,3.7531738,0.2858276c-0.2925415,0.03302-0.6207886,0.0512085-0.9591064,0.0631714 C57.0489616,116.2879791,56.0097771,116.1650543,54.9760857,116.1374664z"
      />
      <path
        fill={colors[0]}
        d="M54.9760857,116.1374664c0.0957031-0.020813,0.1906128-0.0429688,0.286438-0.0634766 c1.255249-0.0525513,2.4976807-0.0294189,3.7531738,0.2858276c-0.2925415,0.03302-0.6207886,0.0512085-0.9591064,0.0631714 C57.0489616,116.2879791,56.0097771,116.1650543,54.9760857,116.1374664z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M55.4193573,118.4477234 c-0.0128059-0.1025467,0.1979713,0.0044098,0.2145729-0.1072845c0.0394897,0.1656494-0.1341057,1.0460358,0.8046455,1.0460358 s0.8461685-0.7981644,0.8851089-0.858284c0.1072884,0.3754959-0.0804634,0.8046417,0.7778244,0.8046417 s0.8003311-0.8575516,0.8851089-0.6705399c0.1166992,0.2574463,0.1877518,0.7778244,0.8851089,0.7778244 c0.697361,0,0.7711067-0.9119034,0.7711067-0.9119034s0.014679,0.3620224,0.0335388,0.3486557 c0.117981,0.2975464,0.3109512,0.493103,0.6437149,0.4827881c0.6705399,0,0.4428635-0.7321396,0.4428635-0.7321396 l0.086853,0.8259888c0,0-4.2183838-0.007019-6.8604126,0.0101929 C55.2563972,119.4887543,55.4789581,118.9250031,55.4193573,118.4477234z"
      />
      <g id="L2_x24_C_x24_bareFeet">
        <g fill={colors[0]}>
          <path d="M41.0996094,113.8792267c-0.7940674-0.0623169-1.6051636-0.0701904-2.4483643-0.0211182 c-0.0471802,0.0027466-0.0964355,0.0044556-0.144104,0.006897c-0.0602417,0.0396118-0.118042,0.0764771-0.1821899,0.1209106 c1.1294556-0.0726318,2.3064575-0.0720215,3.4518433,0.0592041 C41.5510254,113.9902496,41.3242188,113.9356232,41.0996094,113.8792267z" />
          <path d="M43.1546021,114.6585236c-1.6611328-0.522644-3.3214111-0.7247314-5.0997314-0.4187622 c0.2363892,0.039856,0.4839478,0.1046143,0.6947632,0.0742188c-0.1899414,0.0274048-0.2753906,0.046936-0.402771,0.1364136 c0.036438-0.0025635,0.0778809,0.0066528,0.1114502-0.0025024c-0.0529785,0.0144043-0.1068726,0.0268555-0.1607666,0.0390625 c-0.0133057,0.0103149-0.0237427,0.0164795-0.038269,0.0283813c-0.0078125,0.0064087-0.0394897-0.0061646-0.0548706-0.0057373 c-0.0720825,0.0161743-0.1444702,0.0315552-0.2149658,0.0522461c1.7559814-0.1777344,3.6184082-0.1621094,5.2404175,0.3086548 C43.2086182,114.7992096,43.1790161,114.7296906,43.1546021,114.6585236z" />
          <path d="M37.4278564,114.7299957c-0.0223999,0.0233765-0.0361938,0.0482178-0.0574951,0.0718994 c0.152832-0.0106812,0.3052979-0.0244751,0.4605103-0.0370483c0.1602783-0.0130005,0.3625488,0.0088501,0.5513306,0.0275879 c0.1118164,0.0056152,0.2237549,0.0112305,0.3355713,0.0168457c0.0245972-0.0007324,0.055481,0.0018921,0.076416-0.0009155 c-0.4746094,0.0635986-0.9645386,0.0866699-1.3997192,0.2944946c1.907959-0.0892334,3.8097534-0.0674438,5.7033691,0.0964966 C41.3029175,114.6105499,39.2755737,114.4513092,37.4278564,114.7299957z" />
          <path d="M42.9150391,115.6444244c-0.3087158-0.0410767-0.6114502-0.1028442-0.9174805-0.1589966 c0.1298218,0.0134277,0.3877563-0.0770264,0.6262817-0.1157837c-1.8481445-0.1973877-3.6934204-0.2834473-5.6012573-0.0367432 c-0.0221558,0.0522461-0.0580444,0.1044312-0.0714111,0.1564331c0.4887695-0.0136719,0.9810791-0.0067749,1.4746094,0.0131226 c1.5510864-0.0012817,2.9943237,0.137207,4.5615234,0.4196777c0.0895386,0.0161133,0.2646484,0.0549316,0.4869995,0.1060791 c-0.0115967-0.0875854-0.0308228-0.2016602-0.0534058-0.3248901 C43.2451782,115.6841583,43.0726929,115.6654205,42.9150391,115.6444244z" />
          <path d="M36.9309692,115.6735382c-0.0005493,0.0328369-0.0001831,0.0655518,0.0001831,0.0982666 c0.1382446-0.0148926,0.2770996-0.0227661,0.4169922-0.0424194c0.1170044-0.0164795,0.2510986,0.0003662,0.3867188,0.0202026 c0.175354,0.0091553,0.350647,0.0183105,0.526001,0.0274658c-0.0256348-0.0002441-0.050354,0.0075073-0.0759277,0.0078735 c0.0493774-0.0023193,0.100647-0.0009155,0.1444702-0.0137329c-0.3326416,0.0973511-0.8613281,0.0946045-1.21521,0.2727661 c0.0823364,0.0539551,0.185791,0.1018677,0.3103027,0.1434326c1.272583-0.2805176,2.5350952-0.3164673,3.8012085-0.2747803 C39.7889404,115.6429596,38.3424072,115.4878082,36.9309692,115.6735382z" />
          <path d="M42.3364868,116.1374664c-0.0957031-0.020813-0.1906128-0.0429688-0.286438-0.0634766 c-1.255249-0.0525513-2.4976807-0.0294189-3.7531738,0.2858276c0.2925415,0.03302,0.6207886,0.0512085,0.9591064,0.0631714 C40.2636108,116.2879791,41.3027954,116.1650543,42.3364868,116.1374664z" />
          <path d="M42.9150391,115.6444244c-0.3087158-0.0410767-0.6114502-0.1028442-0.9174805-0.1589966 c0.1298218,0.0134277,0.3877563-0.0770264,0.6262817-0.1157837c-1.8481445-0.1973877-3.6934204-0.2834473-5.6012573-0.0367432 c-0.0221558,0.0522461-0.0580444,0.1044312-0.0714111,0.1564331c0.4887695-0.0136719,0.9810791-0.0067749,1.4746094,0.0131226 c1.5510864-0.0012817,2.9943237,0.137207,4.5615234,0.4196777c0.0895386,0.0161133,0.2646484,0.0549316,0.4869995,0.1060791 c-0.0115967-0.0875854-0.0308228-0.2016602-0.0534058-0.3248901 C43.2451782,115.6841583,43.0726929,115.6654205,42.9150391,115.6444244z" />
          <path d="M36.9309692,115.6735382c-0.0005493,0.0328369-0.0001831,0.0655518,0.0001831,0.0982666 c0.1382446-0.0148926,0.2770996-0.0227661,0.4169922-0.0424194c0.1170044-0.0164795,0.2510986,0.0003662,0.3867188,0.0202026 c0.175354,0.0091553,0.350647,0.0183105,0.526001,0.0274658c-0.0256348-0.0002441-0.050354,0.0075073-0.0759277,0.0078735 c0.0493774-0.0023193,0.100647-0.0009155,0.1444702-0.0137329c-0.3326416,0.0973511-0.8613281,0.0946045-1.21521,0.2727661 c0.0823364,0.0539551,0.185791,0.1018677,0.3103027,0.1434326c1.272583-0.2805176,2.5350952-0.3164673,3.8012085-0.2747803 C39.7889404,115.6429596,38.3424072,115.4878082,36.9309692,115.6735382z" />
          <path d="M42.3364868,116.1374664c-0.0957031-0.020813-0.1906128-0.0429688-0.286438-0.0634766 c-1.255249-0.0525513-2.4976807-0.0294189-3.7531738,0.2858276c0.2925415,0.03302,0.6207886,0.0512085,0.9591064,0.0631714 C40.2636108,116.2879791,41.3027954,116.1650543,42.3364868,116.1374664z" />
          <path d="M42.3364868,116.1374664c-0.0957031-0.020813-0.1906128-0.0429688-0.286438-0.0634766 c-1.255249-0.0525513-2.4976807-0.0294189-3.7531738,0.2858276c0.2925415,0.03302,0.6207886,0.0512085,0.9591064,0.0631714 C40.2636108,116.2879791,41.3027954,116.1650543,42.3364868,116.1374664z" />
        </g>
        <path
          opacity="0.42"
          fill="#21376C"
          d="M41.8932152,118.4477234 c0.0128059-0.1025391-0.1979713,0.0044098-0.2145729-0.1072845c-0.0394897,0.1656494,0.1341057,1.0460358-0.8046455,1.0460358 s-0.8461685-0.7981644-0.8851089-0.858284c-0.1072845,0.3754959,0.0804634,0.8046417-0.7778244,0.8046417 s-0.8003311-0.8575516-0.8851089-0.6705399c-0.1166992,0.2574463-0.187748,0.7778244-0.8851089,0.7778244 c-0.6973572,0-0.7711067-0.9119034-0.7711067-0.9119034s-0.014679,0.3620224-0.0335388,0.3486557 c-0.117981,0.2975464-0.3109512,0.493103-0.6437149,0.4827881c-0.670536,0-0.4428635-0.7321396-0.4428635-0.7321396 l-0.086853,0.8259888c0,0,4.2183838-0.007019,6.8604126,0.0101929 C42.0561752,119.4887543,41.8336143,118.9250031,41.8932152,118.4477234z"
        />
      </g>
    </g>
  )
}

export default BareFeet
