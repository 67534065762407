import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const PoleArmR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("PoleArmR") ? (
    <g id="PoleArmR">
      <path d="M24.9315834,17.6584206c0-0.1000004,0-0.1000004,0-0.2000008 c-0.2000008-2.2000017,0.2999992-4.3000011,0.6000004-6.4000006l-0.6000004-0.1000004l6-27.0999985l14,1.499999v48.7000046 c0,0.7999992-0.0999985,1.5999985-0.4000015,2.4000015l-0.5,78.8000031l-0.5999985,2.8000031l-0.7000008,1.6999969 l-13.2000008-0.5l-0.5999985-1.6999969l-0.4000015-2.4000015v-80.5l-0.4000015-0.5v0.2000008v-0.2000008h0.0999985 l-0.0999985-0.2000008c0-1.9000015-0.2000008-2.7999992-0.5-4c-0.0999985-0.3999996-0.2000008-0.8999996-0.2999992-1.2999992 c-0.4000015-1.6000004-0.9000015-3.6000004-2.2000008-6l0,0L25.03158,22.4584198l0,0l0,0l0.3999996-0.2000008 c-0.2000008-0.6000004-0.3999996-1.2999992-0.5-2C24.7315826,19.2584209,24.831583,18.4584198,24.9315834,17.6584206z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(PoleArmR, {
  character: "ManDragonborn"
})
