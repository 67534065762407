import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LongSkirt from "./LongSkirt"
import Skirt from "./Skirt"
import FurLiner from "./FurLiner"
import Belt from "./Belt"
import LeatherArmor from "./LeatherArmor"

const CATEGORY = "Armor"
const CHARACTER = "WomanDwarf"
export const IDS = {
  LONG_SKIRT: "LongSkirt",
  SKIRT: "Skirt",
  FUR_LINER: "FurLiner",
  BELT: "Belt",
  LEATHER_ARMOR: "LeatherArmor"
}

export const components = {
  [IDS.LONG_SKIRT]: LongSkirt,
  [IDS.SKIRT]: Skirt,
  [IDS.FUR_LINER]: FurLiner,
  [IDS.BELT]: Belt,
  [IDS.LEATHER_ARMOR]: LeatherArmor
}

export const Group = ({ props }) => (
  <g id="Armor">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LONG_SKIRT,
    name: "Long Skirt",
    defaultColors: ["#6B6B6B", "#231F20"],
    colorable: true,
    component: components[IDS.LONG_SKIRT],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.SKIRT,
    name: "Skirt",
    defaultColors: ["#914E00", "#223549"],
    colorable: true,
    component: components[IDS.SKIRT],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.FUR_LINER,
    name: "Fur Liner",
    defaultColors: ["#BFA181"],
    colorable: true,
    component: components[IDS.FUR_LINER],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.BELT,
    name: "Belt",
    defaultColors: ["#996841", "#C49C2D"],
    colorable: true,
    component: components[IDS.BELT],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.LEATHER_ARMOR,
    name: "Leather Armor",
    defaultColors: ["#914E00", "#223549"],
    colorable: true,
    component: components[IDS.LEATHER_ARMOR],
    inUI: true,
    enabled: false,
    subGroupId: 4
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
