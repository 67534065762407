import React from "react"

const CutlassL = ({ colors = ["#DDC300", "#FFFFFF", "#7C7A79"] }) => {
  return (
    <g id="CutlassL">
      <g fill="#21376C">
        <path
          opacity="0.2"
          d="M64.0645676,81.7715302l-0.1482086,0.8727722c0,0-1.5643959,4.0344925-1.6302643,4.0509567 s0.1811409,0.2140732,0.1811409,0.2140732s0.5269547,0.6422272,0.592823,0.6751633s2.6512375-5.6976929,2.7500381-5.6812286 c0.0988083,0.0164719-1.4820557-0.6751556-1.4820557-0.6751556L64.0645676,81.7715302z"
        />
        <path
          opacity="0.2"
          d="M64.2668381,82.0514755l-0.148201,0.8727646c0,0-1.5643959,4.0344925-1.6302643,4.0509644 c-0.0658722,0.0164642,0.1811409,0.2140732,0.1811409,0.2140732s0.5269508,0.6422272,0.592823,0.6751633 c0.0658684,0.0329285,2.6512375-5.6976929,2.7500381-5.6812286c0.0988083,0.0164719-1.4820557-0.6751556-1.4820557-0.6751556 L64.2668381,82.0514755z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M66.1438828,81.3905411c-0.5800858-0.8757095-1.4191437-1.3413162-1.8740692-1.0399551 c-0.4549217,0.3013535-0.3534431,1.2555313,0.2266769,2.131279c0.054184,0.0818024-0.3285828,1.9873428-0.7777405,3.1183167 c-0.3509331,0.8835831-0.7005348,1.3629074-0.9038658,1.5330734l-0.4876213-0.3309555l-0.1564407-0.1317444 c-0.016449,0.0823288-0.2799416,0.7245636,0.024704,1.1033096c0.3046455,0.3787537,0.981987,0.0137482,0.981987,0.0137482 c0.4957314,0.4989166,1.3177795,1.09935,2.1879807,0.7684631c1.0144348-0.3857498,1.696106-0.6257706,2.3877487-2.4701157 C68.4448853,84.2416458,66.7240067,82.2662811,66.1438828,81.3905411z"
      />
      <g fill="#21376C">
        <path
          opacity="0.42"
          d="M63.9657593,88.0085144 c-0.0427933-0.0423813,2.0032043-1.5337753,1.880249-3.3473969c0.0207214-0.8410873-0.2746735-2.2720718-0.2746735-2.2720718 s-0.3723755-0.5708313-0.1605759-0.0493927c1.1733017,4.6437836-1.9088058,5.1295853-1.9513741,5.1130981 c1.2474022-2.2971802,1.7771263-4.7270813,1.2720985-5.1872177c-0.8999901-1.2309875-0.5176544-1.7929001-0.1374512-1.8855743 c-1.1685219,0.1098022,0.0139389,2.0585175,0.0139389,2.0585175s-0.148201,2.4947662-1.7043495,4.8413773 c1.0374413,1.2597275,1.6055717,1.3091583,2.4948082,1.1609497 C64.5329514,88.5194626,64.263298,88.3033524,63.9657593,88.0085144z"
        />
        <path
          opacity="0.42"
          d="M64.5940323,80.3799591 c0.0645676-0.0060654,0.1320572-0.0098495,0.2115784-0.0040436 C64.7414169,80.3598785,64.6677399,80.3620148,64.5940323,80.3799591z"
        />
      </g>
      <g>
        <path
          opacity="0.66"
          fill="#FFF"
          d="M66.6864548,86.2977524 c-0.1216736-0.0704117,0.0043869-0.5320053-0.1059723-0.6047363c0.3003845,0.1980438,0.5938416,0.4060593,0.887291,0.6141434 c0.7474365-1.9972763-0.8369217-3.8602905-1.4206085-4.5004425c-0.0816879,0.1368484-0.198494,0.2512512-0.3521347,0.2727661 c-0.0952148,0.013298-0.1674271-0.0148773-0.2315826-0.0562515c0.397438,1.087738,0.5861893,1.9819183,0.6226501,2.7016983 c0.1805115,0.4121704,0.2255554,0.8416519,0.0163803,1.3653336c-0.0224457,0.0562515-0.0584183,0.1344604-0.0999756,0.2207947 c0.3437347,0.2710419,0.6835022,0.544899,1.0193787,0.8230972c0.1079178-0.1497803,0.1987534-0.2995987,0.277153-0.4490814 C67.0969009,86.5528336,66.8958206,86.4190216,66.6864548,86.2977524z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M65.8619766,86.5846863 c-0.2505875,0.4652634-0.6368561,1.0696335-1.0161972,1.3503418c0.3203201,0.1361389,0.8487625,0.2582703,1.4665833-0.0129623 c0.2270126-0.2016068,0.409996-0.4049377,0.5740509-0.6086426 C66.5515137,87.0576324,66.2103271,86.8149948,65.8619766,86.5846863z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M62.1872902,86.7611313 c0,0-0.3025856,0.771904,0.1667328,1.0683136c0.4137421,0.1358566,0.7966042-0.0926285,0.7966042-0.0926285 L62.1872902,86.7611313z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M64.5585861,80.808197 c0,0-0.1976089,1.2597427,0.4693146,1.4079514c0.2470093,0.4446182,0.4693222,4.0015564-1.358551,5.0390015 c0.6916275-0.2470093,2.4701004-1.6055679,1.6055603-4.9648972C64.9291,81.7221298,64.8796997,82.2655487,64.5585861,80.808197z"
        />
        <path
          fill={colors[0]}
          d="M67.5543213,84.2715988c-0.0165634-0.122612,0.0997314-0.3894424,0.0507126-0.5075226 c0.0491333-0.1834564-0.0772934-0.2350388-0.0811615-0.4257736c-0.0064468-0.317894-0.1049042-0.614624-0.35672-0.7858963 c-0.2371368-0.773674-1.6897125-0.5208435-1.9663391,0.2236328c-0.1473465,0.3964233-0.1689987,1.4416733,0.3907394,1.5060501 c0.1994705,0.1207733,0.30233,0.0707016,0.3420486,0.2147827c0.0402527,0.1459579,0.0670471,0.415329,0.1220551,0.5723419 c0.0877609,0.250473,0.8309937-0.2841644,1.0286026-0.3335648s0.4851227,0.2476196,0.5948792,0 c0.0720596-0.1626358-0.1039047-0.2730865-0.1200562-0.4300995c0.0035248-0.0173111,0.0070801-0.0346603,0.0106049-0.0519791 C67.5645523,84.2605057,67.5594559,84.2648926,67.5543213,84.2715988z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M66.0653458,83.1918411 c0,0-0.185257,0.2099533-0.185257,0.2717056s0.160553,0.2593613,0.160553,0.2593613l0.3458176-0.0864563l0.148201-0.2840576 l-0.3211136-0.2346573L66.0653458,83.1918411z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M66.8687286,83.4582214 c-0.0578842-0.0144958-0.1683807,0.2898788-0.101738,0.4368286c0.0666428,0.1469574,0.2475967-0.0350494,0.2475967-0.0350494 l0.0711975,0.0267715C67.0857849,83.8867722,67.0429764,83.5018616,66.8687286,83.4582214z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M66.658165,83.1053848 c-0.014389-0.1630096-0.1574249-0.8245239-0.9551163,0.1399612c0.0145798,0.0155487,0.4940338-0.5187073,0.6504745-0.1893616 c0.0982513,0.0794754,0.1477432-0.1177979,0.1893692-0.1070404l0.1976089,0.4528503 c-0.0164871,0.0247345-0.2248383,0.2041092-0.2470093,0.2387772c-0.01091,0.0170746,0.1646729,0.0494003,0.1893768,0.2799454 c0.0124359,0.0213318,0.0164642-0.2634811,0.0988083-0.4857864c0.0113373-0.0453262,0.0658646-0.0329361,0.123497-0.0329361 c0.0306015-0.0437927-0.1811371-0.2964096-0.1811371-0.2964096S66.6757736,83.1208344,66.658165,83.1053848z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M66.9216461,82.718399 c0,0-0.032074,0.1283188,0.0930557,0.158844c0.1251373,0.0305328,0.2445221-0.0600357,0.3927307,0.0799332 C67.29216,82.6937027,67.1521835,82.5207977,66.9216461,82.718399z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M67.5263443,84.3130646 c-0.005394-0.0203094-0.0067062-0.0361633-0.0052872-0.0491638c-0.0494232-0.0535126-0.1129074-0.1033096-0.2041855-0.1128311 c-0.1567917-0.0163727-0.6112289,0.1731644-0.8869934,0.0296021l0.220047-0.0954819 c0,0-0.4474716,0.1937408-0.8572388-0.3702316c0.0670395-0.0511856,0.0513382-0.220192,0.0338745-0.2143478 c-0.0246964,0.0082092-0.1728973,0.1482086-0.2387772-0.1646957c0,0-0.1399612,0.2223282,0.0741196,0.3622894 c0.053215,0.0347748,0.0915451,0.0393906,0.1189041,0.0270615c-0.0162277,0.0767059-0.046051,0.3778,0.4327393,0.5493164 l0.1883011-0.0817261c-0.0251389,0.0721664-0.0400543,0.4136658,0.01548,0.5180969 c0.0437698-0.0248032,0.0768585-0.0391541,0.1175919-0.0608902c-0.0039368-0.0232315-0.0067444-0.0445175-0.0058899-0.0589828 c0.0001144-0.0018005,0.0007935-0.0025482,0.0009384-0.0043106c-0.0007095-0.0022507-0.0014572-0.0045319-0.0021744-0.0067825 c0.0009766,0.0011215,0.001915,0.0022125,0.0028915,0.003334c0.0105667-0.1203232,0.0772324-0.1460342,0.1562576-0.0445938 c0.0052109,0.0066757,0.0078735,0.0184784,0.0126343,0.0260849c0.0317001-0.0154037,0.0557556-0.0237198,0.0853577-0.037178 c-0.0101547-0.0338364-0.0227814-0.0679703-0.0187683-0.10009c0.0131531-0.1048126,0.0599136-0.0610809,0.1068726,0.0034866 c0.0104523,0.0143509,0.014801,0.0324936,0.0231552,0.0481567c0.0428314-0.01754,0.0775299-0.0280304,0.1156464-0.0414505 c-0.016304-0.0459747-0.0225983-0.0841599-0.0058517-0.1036835c0.0531006-0.0618668,0.0804977-0.0421219,0.1482468-0.0009003 c0.0163345,0.009964,0.0220337,0.0344772,0.0334244,0.0512238c0.0461655-0.011055,0.0755081-0.0103073,0.1129074-0.0149918 c0.0006027-0.0036697-0.0001526-0.009552,0.0007858-0.0127029c0.0007095-0.0843887,0.0272064-0.0949554,0.079483-0.0315857 c0.0089569,0.006218,0.0097046,0.0226669,0.0166397,0.0314407c0.0615311-0.0007477,0.1034622,0.0082016,0.1379013,0.0192566 C67.5313644,84.3512878,67.5287781,84.3319931,67.5263443,84.3130646z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M65.27491,82.7348709 c0-0.0741043-0.5187149,1.3256149,0.6339951,1.6549606c-0.230545-0.2223053-0.6751633-0.3458099-0.6586914-1.4408875 C65.3160782,82.8007355,65.27491,82.7348709,65.27491,82.7348709z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M67.23452,83.8546448 c0,0,0.3375854-0.2470093,0.230545-0.3869781C67.3580246,83.3276901,67.9343872,83.7723083,67.23452,83.8546448z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M65.4395828,82.5619659 c0,0,0.979805-0.9221725,1.6220322-0.1482086C66.7569733,82.3478851,66.1476822,82.133812,65.4395828,82.5619659z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M66.0776978,84.5750885 c-0.0108109,0.023056,0,0.4199219,0.0679245,0.4260941c0.0679245,0.0061798,0.1482086-0.1667328,0.1790848-0.1790771 c0.0308762-0.012352-0.0926285-0.5310745-0.0926285-0.5001984S66.1703262,84.3774872,66.0776978,84.5750885z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M67.3868484,84.0069733 c-0.012352,0.0370483,0.1769028,0.239769,0.2470093,0.321106c0.0700989,0.0813446-0.0617523-0.4940186-0.0617523-0.4940186 L67.3868484,84.0069733z"
        />
        <polygon
          opacity="0.42"
          fill="#21376C"
          points="66.9792786,83.2041855 67.1398392,82.9077759 67.3744965,82.9818802 67.4238968,83.3770981 67.0780869,83.4141464"
        />
      </g>
      <g>
        <path
          fill={colors[2]}
          d="M72.9029922,56.5436058c0,0.2237854-3.0397339,10.4246101-8.0562286,24.1686821 c0.4568939,0.6340561,0.8205414,1.2681122,0.8205414,1.0443268 C73.7981262,60.9446945,72.9029922,56.5436058,72.9029922,56.5436058z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M72.9029922,56.5436058 c-3.1236572,13.9492073-7.7112274,24.923954-7.487442,24.7747612c0.1118851,0.1678391,0.2517548,0.6620331,0.2517548,0.4382477 C73.7981262,60.9446945,72.9029922,56.5436058,72.9029922,56.5436058z"
        />
        <g fill="#FFF">
          <path
            opacity="0.66"
            d="M72.3835754,61.0997429 c0.1906967-0.9158058,0.318779-1.6688309,0.4013367-2.2746582c-0.1743622,0.1450958-0.3473434,0.2921028-0.5206909,0.4385872 c-0.1896515,0.7789536-0.3825607,1.5467033-0.5776138,2.3021584 C71.9187546,61.4102058,72.1511612,61.254879,72.3835754,61.0997429z"
          />
          <path
            opacity="0.66"
            d="M71.5770721,61.9787483 c-0.1512756,0.5797462-0.3029327,1.1581078-0.4563828,1.7202034c0.2513733-0.1292839,0.5024796-0.258152,0.7526855-0.3871727 c0.1660461-0.6615868,0.3045044-1.2580452,0.4233704-1.8048172 C72.0568466,61.6641998,71.8165665,61.8208733,71.5770721,61.9787483z"
          />
          <path
            opacity="0.66"
            d="M69.1873779,72.0854721 c0.0153275-0.0107117,0.0309525-0.0211334,0.0463181-0.0318146c0.845993-2.4872742,1.5024414-4.6015854,2.0081787-6.3870316 c-0.2695389,0.1596375-0.5393066,0.3188248-0.8085938,0.4788361c-0.6809235,2.3587036-1.3584366,4.5230789-1.9858856,6.4360046 C68.7002258,72.4160614,68.9497604,72.2511826,69.1873779,72.0854721z"
          />
          <path
            opacity="0.66"
            d="M67.5951843,76.6737976 c0.2449951-0.6668015,0.48246-1.3194656,0.7057266-1.9450455c-0.2551117,0.1822357-0.5072708,0.3684387-0.7608871,0.5526886 c-0.2215042,0.6440201-0.4303055,1.2400284-0.6259537,1.7925644 C67.139473,76.9377899,67.36866,76.8080978,67.5951843,76.6737976z"
          />
          <path
            opacity="0.66"
            d="M66.5315475,79.5144119 c0.3239136-0.8512039,0.6325836-1.6742249,0.9258423-2.4684296c-0.0494614,0.0303497-0.1002731,0.0584564-0.1496658,0.0889206 c-0.179718,0.1107712-0.3581696,0.2249146-0.5362015,0.3405151c-0.3455429,0.9699173-0.6381302,1.7705307-0.8644333,2.3906784 C66.1141663,79.7468948,66.323082,79.6310272,66.5315475,79.5144119z"
          />
        </g>
      </g>
    </g>
  )
}

export default CutlassL
