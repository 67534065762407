import React from "react"

const BodyBase = ({ colors = [] }) => {
  return (
    <g id="BodyBase">
      <path
        id="bodyBase"
        d="M24.7,119.5c0.6-1.8,1.5-6.7,3.3-8.8c0-0.2-0.1-0.3-0.1-0.5c-0.5-2.8-1-5.4-1-7.4c-0.1-2.8,0.7-4.7,1.3-6 c-0.3-1-0.4-1.9-0.6-2.7c-0.2-1.2-0.4-2.2-0.9-3.6l-0.3-3.2c0.2-1.6,1.2-8.3,1.7-11.6C28,74.9,28,74.2,28,73.5 c-0.3-0.7-0.7-1.7-1.1-2.7c-0.7-1.6-1.4-3.3-2-5c-2-1.9-3.5-4.5-3.4-7.8c0.2-4,2.5-6.9,3.2-7.7c0,0,6.2-2.5,6.7-2.5 c1.1-0.1,2.6-0.4,4.2-0.7c1.8-3.3,5.7-5.4,10.4-5.4h3.6c4.7,0,8.6,2.1,10.4,5.3c0,0,0,0,0.1,0c1.7,0.3,2.8,0.5,4.7,0.7 c0.9,0.1,1.5,0.1,1.9,0.2c1.5,0.1,5.4,0.3,7.5,4.9c0,0.1,0.1,0.1,0.1,0.2l0.8,2.3c0.7,4.4-1.1,8.1-3.7,10.5c-0.6,1.7-1.3,3.4-2,5 c-0.4,1-0.8,2-1.1,2.7c0.1,0.6,0.1,1.3-0.1,2.1c0.5,3.3,1.5,9.9,1.7,11.6l-0.3,3.2c-0.5,1.4-0.7,2.4-0.9,3.6 c-0.2,0.8-0.3,1.7-0.6,2.7c0.6,1.3,1.4,3.2,1.3,6c-0.1,2-0.5,4.6-1,7.4c0,0.2-0.1,0.3-0.1,0.5c2.2,2.6,3.4,8.8,3.4,8.8L24.7,119.5 z"
      />
      <path
        id="rightArm_2_"
        d="M25,91.5c-1.6,0-3.3-0.4-4.7-1.3c-3.4-2-4.1-6.2-4.3-9.3c-0.2-2.8,0.7-9.4,2.7-12.4 c0-1.8,0.6-11.5,1.5-13.4c0-0.1,0-0.1,0,0.2l1.6-3.2l0.1-0.1c1.3-1.6,3.3-3.6,7.5-4.4c0.7-0.1,1.4-0.2,2.2-0.2 c4.1,0,7.2,2.1,8,2.7l2.7,7.1l-3,14.2c0.2,1.5,0.3,3.5-0.2,5.6c-0.2,0.8-0.7,2.9-5.4,10.1l-1.2,1.4C31.7,89.2,28.9,91.5,25,91.5z"
      />
      <path
        id="leftArm"
        d="M71.8,91.5c-3.8,0-6.7-2.3-7.5-3l-1.2-1.4c-4.7-7.2-5.2-9.3-5.4-10.1c-0.5-2-0.4-4.1-0.2-5.6l-3-14.2 l2.7-7.1c0.8-0.6,3.9-2.7,8-2.7c0.7,0,1.5,0.1,2.2,0.2c4.2,0.8,6.2,2.8,7.5,4.4l0.1,0.1c0,0,3,6.8,2.6,9.7c0.6,2.4,0.6,5,0.5,7.4 c1.4,2.4,2.5,5.8,3.4,11.2v0.3c0.3,2.9-0.8,7.1-5,9.5C75.1,91.1,73.5,91.5,71.8,91.5z"
      />
      <path
        id="head_1_"
        d="M48.4,60.4c-1.9,0-3.6-0.6-4.8-1.2c-4.9-0.8-8.7-3.9-10.6-8.3c-0.7-0.6-1.4-1.3-1.8-2.1l-0.6-1.2 c-1.6-2.9-0.1-8.2-0.1-8.2c0.9-2.7,2.8-4.5,5.2-5.1c2.8-3.9,6.9-6.3,11.1-6.3h3.3c4.3,0,8.3,2.3,11.1,6.3c2.3,0.6,4.5,2.2,5.4,4.9 v0.1c0.9,2.6,0.8,4.9-0.4,8.3l-0.6,1.2c-0.5,0.8-1.1,1.6-1.8,2.1c-1.9,4.6-5.9,7.8-10.8,8.4C51.8,59.9,50.2,60.4,48.4,60.4z"
      />
    </g>
  )
}

export default BodyBase
