import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LuteL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LuteL") ? (
    <g id="LuteL">
      <path d="M81.2,61.4c-5.3,6-15.7,22-7,29.9c9.8,8.9,26.6,0.4,29.1-10.8c2.3-10.3-0.9-15.6,4.4-25.9c3.6-6.9,7.7-17.4-5.9-17.9 C93.3,36.5,86.2,55.8,81.2,61.4z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LuteL, { character: "ManMixed" })
