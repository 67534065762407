import React from "react"

const Shaved = ({ colors = ["#282560"] }) => {
  return (
    <g id="Shaved">
      <path
        id="_x3C_path_x3E__2_"
        opacity="0.3"
        fill={colors[0]}
        d="M63.7002258,14.2749977c-0.0023193-0.0213013,0.5833664-4.2780285,4.8278732-2.7894354 c3.1783447-0.9454584,4.5126648,0.256237,4.8882141,2.6888542c0.0443878,0.2874842,0.8656464,1.3587236,1.0259247,2.1725416 c0.29879,1.5171585-0.0003738,3.0845699,0.0544128,3.6661167c0.3108749-1.3155651,0.2741089-4.0093384,0.2886963-4.0706177 c-0.0740967-1.4091797-0.7550659-7.3077393-6.1703491-7.3077393c-6.3631325-0.0726986-6.1806679,7.542717-6.1787758,7.5610275 c0.1708374,1.6340942,0.3011513,4.2750168,0.3185463,4.2750168c0.0195541-0.2544842-0.120697-1.9915009,0.120697-4.0634613 C62.9861374,15.5208874,62.8899498,15.0751534,63.7002258,14.2749977z"
      />
    </g>
  )
}

export default Shaved
