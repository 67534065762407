import React from "react"

const CapeFastensers = ({
  colors = [
    "#505F94",
    "#21366C",
    "#D8A16D",
    "#SVGID_",
    "#FFFFFF",
    "#AC7C56",
    "#F9BD7C"
  ]
}) => {
  return (
    <g id="CapeFastensers">
      <path
        fill={colors[0]}
        d="M51.1320152,27.4752026c0,0,0,5.666666,4.1666679,0.333334s0.7083321-8.875,0.7083321-8.875 s-5.6634865-3.4139605-5.307354-1.6078434c0.4941177,2.5058823-1.317646-0.1838245-1.317646-0.1838245 s-2.6509781-2.8612738-3.317646-1.0279408c-0.1618004,0.4449539-0.0990181,0.9862747,0.192646,1.5696087 c0.4721413,0.9442768-2.75-0.5416679-2.75-0.5416679s-3.0579758,0.7788849-3.9166641,2.375 c-0.7227554,1.3434429,0.8124962,3.291666,1.4791603,4C43.7361832,26.3502026,51.1320152,27.4752026,51.1320152,27.4752026z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M53.5538902,27.4856186c0,0,1.984375-2.21875,1.453125-4.010416 c-0.749382-2.5273323-2.5946312-3.146841-4.2105904-4.7916756c-0.3874359-0.3943596-0.6123314-0.5104084-0.6123314-0.5104084 c0.7760468,0.171875,0.8541718-0.046875,0.8229218-0.8437405c1.0416718,1.166647,4.4583359,1.8541584,5.171875,5.7031155 C56.2882652,24.0324936,56.3507652,26.6418686,53.5538902,27.4856186z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M43.5070152,17.1418686c0,0,7.1922913,3.7085686,8.875,4.9166679 c1.625,1.166666,3.1979179,1.901041,1.1874962,5.2552071c1.5625038-2.3333321,2.8125038-4.171875-6.9791603-10.109375 C46.2466011,17.7043686,43.5070152,17.1418686,43.5070152,17.1418686z"
      />
      <circle fill={colors[2]} cx="53.014" cy="28.054" r="1.529" />
      <circle fill="url(#SVGID_5_)" cx="52.998" cy="27.805" r="1.529" />
      <radialGradient
        id="SVGID_5_"
        cx="52.998"
        cy="27.805"
        r="1.529"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.662" stopColor={colors[5]} />
        <stop offset="1" stopColor={colors[6]} />
      </radialGradient>
      <g>
        <path
          fill={colors[0]}
          d="M87.7025986,28.6031055c0,0,0,5.666666-4.1666641,0.333334s-0.7083359-8.875-0.7083359-8.875 s5.6634903-3.4139595,5.3073578-1.6078434c-0.4941177,2.5058823,1.3176422-0.1838226,1.3176422-0.1838226 s2.6509781-2.8612747,3.3176498-1.0279427c0.1617966,0.4449558,0.0990219,0.9862747-0.1926498,1.5696087 c-0.4721375,0.9442768,2.75-0.541666,2.75-0.541666s3.0579758,0.778883,3.9166718,2.375 c0.7227478,1.343441-0.8125,3.2916641-1.4791718,4C95.0984344,27.4781055,87.7025986,28.6031055,87.7025986,28.6031055z"
        />
        <path
          opacity="0.3"
          fill={colors[1]}
          d="M85.2807236,28.6135235c0,0-1.984375-2.21875-1.453125-4.0104179 c0.749382-2.5273304,2.594635-3.1468391,4.2105942-4.7916756c0.3874359-0.3943596,0.6123276-0.5104065,0.6123276-0.5104065 c-0.7760468,0.171875-0.8541718-0.046875-0.8229218-0.84375c-1.0416718,1.1666565-4.4583282,1.854166-5.171875,5.703125 C82.5463486,25.1603985,82.4838486,27.7697735,85.2807236,28.6135235z"
        />
        <path
          opacity="0.3"
          fill={colors[1]}
          d="M95.3275986,18.2697735c0,0-7.1922913,3.7085667-8.875,4.916666 c-1.625,1.166666-3.1979141,1.901041-1.1875,5.255209c-1.5625-2.333334-2.8125-4.171875,6.9791718-10.109375 C92.5880203,18.8322735,95.3275986,18.2697735,95.3275986,18.2697735z"
        />
        <circle fill={colors[2]} cx="85.82" cy="29.182" r="1.529" />
        <circle fill="url(#SVGID_6_)" cx="85.837" cy="28.933" r="1.529" />
        <radialGradient
          id="SVGID_6_"
          cx="-238.471"
          cy="28.933"
          r="1.529"
          gradientTransform="matrix(-1 0 0 1 -152.634 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.662" stopColor={colors[5]} />
          <stop offset="1" stopColor={colors[6]} />
        </radialGradient>
      </g>
    </g>
  )
}

export default CapeFastensers
