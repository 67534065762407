import React from "react"

const FlameRight = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameRight">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M28.7678967,89.6195297c-1.7999992-0.5999985-3.5-1.1999969-5-1.9000015 c-0.2999992-0.5-0.7000008-1-1-1.5c-0.7999992-1.3000031-1.5-2.5999985-2.1000004-4 c0.1000004-0.4000015,0.2999992-0.8000031,0.3999996-1.1999969c0.2000008-0.5,0.5-1,0.7000008-1.5 c0.3999996-0.6999969,0.7999992-1.5,1.1000004-2.3000031l1-0.1999969l-0.5-0.6999969l-0.1000004-0.0999985 c2.1000004-1.3000031,3.3999996-2.6999969,3.7999992-4.1999969c0.1000004-0.5,0.2000008-1.0999985,0.1000004-1.5999985 l0.6000004-0.0999985c0.2000008,0.4000015,0.3999996,0.6999969,0.5,1c0.6000004,1.1999969,1.5,2.3000031,2.2999992,3.4000015 c1,1.3000031,1.8999996,2.5999985,2.4999981,3.9000015c1.5,3.3000031,1.2000008,6.5-1,10.3000031 C30.9678974,89.4195328,29.867897,89.6195297,28.7678967,89.6195297z"
        />
        <path
          fill={colors[1]}
          d="M28.6678963,89.7195358c-1.5-0.5-3-1-4.2999992-1.5999985 c-0.2999992-0.4000015-0.6000004-0.8000031-0.7999992-1.3000031c-0.7999992-1.1999969-1.3999996-2.4000015-2-3.8000031 c0.1000004-0.4000015,0.2000008-0.6999969,0.3999996-1c0.2000008-0.5,0.5-1,0.7000008-1.4000015 c0.2999992-0.5999985,0.7000008-1.3000031,1-2l1.5-0.1999969l-0.8000011-1.0999985 c1.7999992-1.1999969,2.8999996-2.5,3.3999996-3.8000031c0.5,0.8000031,1.1000004,1.5,1.6000004,2.3000031 c0.8999996,1.3000031,1.7999992,2.5,2.3999996,3.8000031c1.4999981,3.0999985,1.1999989,6.0999985-0.8999996,9.6999969 C30.1678963,89.5195312,29.4678974,89.7195358,28.6678963,89.7195358z"
        />
        <path
          fill={colors[2]}
          d="M28.5678978,89.8195343c-1.2000008-0.4000015-2.3999996-0.8000031-3.5-1.3000031 c-0.2000008-0.3000031-0.5-0.6999969-0.7000008-1c-0.7000008-1.0999985-1.2999992-2.3000031-1.8999996-3.5 c0.1000004-0.3000031,0.2000008-0.5,0.2999992-0.8000031c0.2000008-0.5,0.5-1,0.7000008-1.4000015 c0.2999992-0.5,0.6000004-1.0999985,0.7999992-1.6999969l2.1000004-0.3000031l-1-1.4000015 c1-0.6999969,1.7999992-1.4000015,2.3999996-2.1999969c0.2000008,0.1999969,0.2999992,0.4000015,0.5,0.6999969 c0.8999996,1.3000031,1.7999992,2.5,2.3999996,3.6999969c1.3999977,2.8000031,1.1000004,5.6999969-0.7999992,9.0999985 C29.4678974,89.6195297,29.0678978,89.7195358,28.5678978,89.8195343z"
        />
        <path
          fill={colors[3]}
          d="M28.4678974,89.8195343c-0.8999996-0.3000031-1.7999992-0.5999985-2.7000008-1 c-0.2000008-0.3000031-0.2999992-0.5-0.5-0.8000031c-0.7000008-1.0999985-1.2000008-2.0999985-1.7999992-3.3000031 c0.1000004-0.1999969,0.1000004-0.4000015,0.2000008-0.5999985c0.2000008-0.5,0.5-0.9000015,0.7000008-1.4000015 s0.5-0.9000015,0.7000008-1.4000015l2.6000004-0.4000015l-1.2000008-1.5999985 c0.3999996-0.3000031,0.7999992-0.5999985,1.1000004-0.9000015c0.7000008,1,1.3999996,2,1.8999996,3 c1.2999992,2.5999985,1,5.3000031-0.6000004,8.4000015C28.7678967,89.7195358,28.6678963,89.8195343,28.4678974,89.8195343z"
        />
        <path
          fill={colors[4]}
          d="M28.1678963,89.5195312c-0.5-0.1999969-1.1000004-0.4000015-1.6000004-0.5999985 c-0.1000004-0.1999969-0.2000008-0.4000015-0.3999996-0.5999985c-0.6000004-1-1.2000008-2-1.7000008-3 c0-0.0999985,0.1000004-0.1999969,0.2000008-0.4000015c0.2000008-0.5,0.5-0.9000015,0.7000008-1.4000015 c0.2000008-0.4000015,0.3999996-0.8000031,0.6000004-1.1999969l2.6000004-0.4000015 C29.6678963,84.3195343,29.5678978,86.8195343,28.1678963,89.5195312z"
        />
        <path
          fill={colors[5]}
          d="M28.367897,89.8195343c-0.1000004-0.0999985-0.1000004-0.1999969-0.2000008-0.3000031 c-0.6000004-0.9000015-1.1000004-1.8000031-1.5-2.8000031c0-0.0999985,0-0.0999985,0.1000004-0.1999969 c0.2000008-0.5,0.5-0.9000015,0.7000008-1.4000015c0.1000004-0.3000031,0.2999992-0.5999985,0.3999996-0.9000015l1-0.1999969 C29.4678974,85.9195328,29.2678967,87.8195343,28.367897,89.8195343 C28.367897,89.9195328,28.367897,89.8195343,28.367897,89.8195343z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M36.7678986,71.5195312c-1.4000015-2-1.5999985-3.9000015-3.6000023-5.5999985 c-0.0999985,0-1-0.6999969-1.0999985-0.6999969c-0.2000008,0.0999985-1.3999996,0.1999969-1.3999996-0.2000046 c0.2000008-1,1.7999973-1.2999954,1.2999992-2.1999969c-0.3999996-0.7000008-2.2000008-5-2.7000008-5.7999992 c-0.7999992,1.5999985-3.2999992,5.7000008-4.6999989,7.0999947c-0.1000004,0.0999985-2.1000004,5.2000046-2.7999992,7.5 c0.3999996-0.0999985,1.1000004-0.3000031,1-0.0999985c-1.1000004,3.0999985-4.1000004,5.5999985-5.2000008,8.6999969 c0,0.0999985-0.2000008,0.1999969-0.3999996,0.3000031c0.3999996,1.9000015,2.1000004,3.5,4,4.9000015 c-1-1.5999985-1.7999992-3.3000031-1.7999992-5.0999985c0-0.1999969,0.6000004-0.5,1.2000008-0.6999969 c0.2000008-2,0.5-3.8000031,2.2999992-5.5999985c2.2999992-2.1999969,3.3999996-5.6999969,3.3999996-5.6999969 c0.1000004-0.4000015,2.7000008-4.5,2.1000004-6.2999992c-0.2000008-0.5,0.5,0.9000015,0.5,1.2999992 c0.1000004,0,0.2000008,0,0.2000008,0.0999985c0,1.4000015,1,6.0999985,1.1000004,6.1999969 c2.2000008,1.5999985,2.2999992,1.9000015,3.2999992,3.9000015c1.2000008,2.5,1.9000015,4.0999985,2,6.6999969 c0,0.0999985-0.0999985,0.1999969-0.2000008,0.3000031c0.7999992,1.5-0.4000015,3.0999985-0.7999992,4.5999985 c-0.0999985,0.1999969-0.0999985,0.4000015-0.2999992,0.6999969c1-1.4000015,1.9000015-2.9000015,2.7000008-4.4000015 C36.867897,78.1195297,36.867897,74.8195343,36.7678986,71.5195312z"
      />
    </g>
  )
}

export default FlameRight
