import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GoldAxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GoldAxeLeft") ? (
    <g id="GoldAxeLeft">
      <path
        id="S_x24_goldAxeLeft"
        d="M73.6999969,123.6999969l-4.0999985-3 c-0.5-0.6999969-0.9000015-1.5999985-1.1999969-2.5l1-6.5999985C70.5999985,110,74.5,104.5999985,77,100.2999954 c1.6999969-2.9000015,6.5-12.1999969,11.1999969-21.5L85.5999985,78 c-2.6999969-4.9000015-2.6999969-10.5999985-0.1999969-15.0999985l0,0c2.5999985-4.5,7.4000015-7.4000015,13-7.7000008 L101.5,57.9000015l5.3000031,0.5l0.4000015,0.2000008v0.0999985h0.1999969L108,59.1000023l2.4000015,1.4000015L113.5,64.7000046 l4.0999985,1.3000031c2.6999969,4.9000015,2.6999969,10.5999985,0.1999969,15.0999985l0,0 c-2.5,4.5-7.4000015,7.4000015-12.9000015,7.6999969l-1.9000015-1.5999985L85.1999969,121l-8.0999985,3.5999985 L73.6999969,123.6999969z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GoldAxeLeft, {
  character: "WomanDwarf"
})
