import React from "react"

const BotaBagRight = ({
  colors = [
    "#715C40",
    "#CDA774",
    "#B09064",
    "#A17955",
    "#C5962C",
    "#EED793",
    "#80682A"
  ]
}) => {
  return (
    <g id="BotaBagRight">
      <path
        fill={colors[0]}
        d="M43.4016304,78.2024536c0,0-0.5629845-0.0804291-0.3217087-0.3217087 c0.2412796-0.2412872,18.0959816-20.0262222,18.0959816-20.0262222s0.3217087-0.4021339,0.5629845-0.1608543 c0.2412834,0.1608543,0,0.5629883,0,0.5629883L43.4016304,78.2024536z"
      />
      <path
        fill={colors[1]}
        d="M38.8977432,79.4088516c0,0-3.4583435,0.6434097-3.2170639,5.3081512 s3.6191978,4.3430405,6.0319939,0.2412796c2.4127998-4.1017532,2.9757843-8.4447861,2.9757843-8.4447861l-1.0455437-0.8042679 C43.6429138,75.7092285,41.3909645,80.1326904,38.8977432,79.4088516z"
      />
      <path
        fill={colors[2]}
        d="M43.1603508,80.7761002c1.0455475-2.2519455,1.5281067-4.2626114,1.5281067-4.2626114 l-1.0455437-0.8042679c0,0-0.4825592,0.9651184-0.8846931,1.4476852 C42.6777954,78.4437332,42.9994965,79.4892731,43.1603508,80.7761002z"
      />
      <path
        fill={colors[2]}
        d="M39.7020111,84.4757309c-0.5629883-0.5629883-0.5629883-1.3672562,0-1.9302444 c0.0804253-0.0804214,0.1608505-0.0804214,0.2412758-0.0804214c-0.1608505,0-0.1608505-0.0804291,0-0.1608505 c0.1608543-0.1608505,0.4825592-0.1608505,0.6434135-0.1608505c0.7238388,0,1.0455437,0.6434097,1.4476776,1.3672485 c0,0.0804214,0-0.0804291,0,0c0.2412796-0.4021378,0.4021339-0.8846893,0.6434135-1.2868271 c-0.4825592-1.3672485-0.5629883-2.8149261-0.5629883-4.3430328c-0.4021339,0.4021301-1.1259727,0.9651108-1.608532,1.3672485 c0.2412796,0.2412796,0.4825592,0,0.4825592,0.3217087c-0.0804253,0.4825516,0.2412796,0.7238388-0.2412796,0.8042603 c-0.4021339,0.0804291-0.8042641,0.1608505-1.0455437-0.2412796c-0.0804253-0.1608505-0.1608543-0.4021301-0.1608543-0.6434174 c-0.2412796,0-0.4825592,0-0.7238426-0.0804214c0,0-0.0804253,0-0.1608505,0 c-1.0455475,2.0106659-1.608532,3.9409027-1.5281067,6.5145493c0,0.8042679,0.0804253,1.5281067,0.4021301,2.1715164 c1.2064018,0.0804291,2.7345085-0.9651184,4.0213318-3.1366348c0,0-0.8846931,0-0.8846931-0.0804291 C40.1041412,85.119133,40.2649956,85.0387115,39.7020111,84.4757309z"
      />
      <path
        fill={colors[2]}
        d="M37.61092,79.9718323c-0.9651184,0.6434174-2.1715202,2.0106659-2.0106659,4.7451706 c0.0804253,1.0455475,0.3217087,1.8498154,0.6434135,2.412796C35.6806793,84.7170029,36.5653725,82.2237778,37.61092,79.9718323z"
      />
      <path
        fill={colors[3]}
        d="M38.0130539,88.0949249c0,0,1.4476776-0.0804291,2.81493-1.4476852 c0.7238388-0.8042603,3.3779144-5.9515686,4.4234619-10.6163025l-0.6434135,0.4021301 C43.8841934,78.2024536,42.0343819,86.1646805,38.0130539,88.0949249z"
      />
      <path
        fill={colors[4]}
        d="M43.2407799,75.9505081l1.206398-2.3323746c0,0,0.9651222-0.4825592,0.8846931,0.3217087 c0,1.2868195,0,2.6540756,0,2.6540756s-0.8042679,1.206398-1.9302406-0.4021301 C43.4016304,76.1113586,43.3212051,76.0309372,43.2407799,75.9505081z"
      />
      <path
        fill={colors[5]}
        d="M44.3667488,74.0202713c0,0,0.1608543,0.2412796,0.3217087,0.1608505 c-0.0804253,0.4021301-0.8042641,2.4932251-0.8042641,2.4932251l-0.482563-0.4825592L44.3667488,74.0202713z"
      />
      <ellipse
        transform="rotate(-3.189 44.851 73.861)"
        fill={colors[6]}
        cx="44.851"
        cy="73.861"
        rx="0.402"
        ry="0.241"
      />
    </g>
  )
}

export default BotaBagRight
