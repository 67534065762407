import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Ruffle from "./Ruffle"
import Tank from "./Tank"
import PuffyShirt from "./PuffyShirt"
import CaptiansJacket from "./CaptiansJacket"
import PlateArmor from "./PlateArmor"
import LeatherArmor from "./LeatherArmor"
import PlateGoldArmor from "./PlateGoldArmor"
import SimpleVest from "./SimpleVest"
import BardVest from "./BardVest"
import BicepWraps from "./BicepWraps"
import Belt from "./Belt"

const CATEGORY = "top"
const CHARACTER = "ManHalfling"
export const IDS = {
  RUFFLE: "Ruffle",
  TANK: "Tank",
  PUFFY_SHIRT: "PuffyShirt",
  CAPTIANS_JACKET: "CaptiansJacket",
  PLATE_ARMOR: "PlateArmor",
  LEATHER_ARMOR: "LeatherArmor",
  PLATE_GOLD_ARMOR: "PlateGoldArmor",
  SIMPLE_VEST: "SimpleVest",
  BARD_VEST: "BardVest",
  BICEP_WRAPS: "BicepWraps",
  BELT: "Belt"
}

export const components = {
  [IDS.RUFFLE]: Ruffle,
  [IDS.TANK]: Tank,
  [IDS.PUFFY_SHIRT]: PuffyShirt,
  [IDS.CAPTIANS_JACKET]: CaptiansJacket,
  [IDS.PLATE_ARMOR]: PlateArmor,
  [IDS.LEATHER_ARMOR]: LeatherArmor,
  [IDS.PLATE_GOLD_ARMOR]: PlateGoldArmor,
  [IDS.SIMPLE_VEST]: SimpleVest,
  [IDS.BARD_VEST]: BardVest,
  [IDS.BICEP_WRAPS]: BicepWraps,
  [IDS.BELT]: Belt
}

export const Group = ({ props }) => (
  <g id="top">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.RUFFLE,
    name: "Ruffle",
    defaultColors: ["#EDDFC0"],
    colorable: true,
    component: components[IDS.RUFFLE],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.TANK,
    name: "Tank",
    defaultColors: ["#77634F"],
    colorable: true,
    component: components[IDS.TANK],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.PUFFY_SHIRT,
    name: "Puffy Shirt",
    defaultColors: ["#E8E7C4"],
    colorable: true,
    component: components[IDS.PUFFY_SHIRT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.CAPTIANS_JACKET,
    name: "Captians Jacket",
    defaultColors: ["#204563", "#1E2F44", "#9C7000", "#FFFFFF"],
    colorable: true,
    component: components[IDS.CAPTIANS_JACKET],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.PLATE_ARMOR,
    name: "Plate Armor",
    defaultColors: ["#737373", "#9E9E9E", "#595A5A"],
    colorable: false,
    component: components[IDS.PLATE_ARMOR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LEATHER_ARMOR,
    name: "Leather Armor",
    defaultColors: ["#754C29", "#FFFFFF", "#010101"],
    colorable: true,
    component: components[IDS.LEATHER_ARMOR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.PLATE_GOLD_ARMOR,
    name: "Plate Gold Armor",
    defaultColors: ["#8A784E", "#EED793", "#A59A76", "#E6BB21"],
    colorable: false,
    component: components[IDS.PLATE_GOLD_ARMOR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SIMPLE_VEST,
    name: "Simple Vest",
    defaultColors: ["#6E5238", "#CFA987", "#8C725C"],
    colorable: true,
    component: components[IDS.SIMPLE_VEST],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BARD_VEST,
    name: "Bard Vest",
    defaultColors: [
      "#8670A2",
      "#D8A16C",
      "#SVGID_",
      "#FFFFFF",
      "#AC7B56",
      "#F9BE7C",
      "#C7AA2E"
    ],
    colorable: true,
    component: components[IDS.BARD_VEST],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BICEP_WRAPS,
    name: "Bicep Wraps",
    defaultColors: ["#ADADAD"],
    colorable: true,
    component: components[IDS.BICEP_WRAPS],
    inUI: true,
    enabled: false,
    subGroupId: 4
  },
  {
    id: IDS.BELT,
    name: "Belt",
    defaultColors: ["#1E1E1E", "#9C7000", "#FFFFFF"],
    colorable: true,
    component: components[IDS.BELT],
    inUI: true,
    enabled: false,
    subGroupId: 3
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
