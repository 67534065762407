import React from "react"

const LargeNose = ({
  colors = ["#282560", "#SVGID_", "#FFFFFF", "#EC1C24"]
}) => {
  return (
    <g id="LargeNose">
      <path
        opacity="0.2"
        fill={colors[0]}
        d="M70.3,18.1c-0.1,0,0.3,0.2,0.4,1.1l0,0 c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0.1-0.3,0.1-0.4,0.2c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1s-0.1,0-0.2,0s-0.1,0-0.2,0 s-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.1-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2l0,0c0.1-0.9,0.5-1.1,0.4-1.1 c-0.6,0.5-0.5,1.2-0.5,1.2s0.4,0.2,0.8,0.5c0,0,0.4,0.7,0.8,0.7s0.7-0.6,0.7-0.6c0.2-0.3,0.9-0.6,0.9-0.6S70.9,18.6,70.3,18.1z"
      />
      <path
        fill="url(#SVGID_3_)"
        d="M70.5,18.5c-0.2-0.1-0.1,0.5-0.2,0.4l0,0c-0.3-0.7-0.1-4-0.1-4c-0.2-0.3-0.7-0.2-1-0.2 s-0.7,0-0.9,0.2c0.1,0.9-0.1,3.7-0.1,3.9c0,0.1-0.1-0.9-0.4,0c-0.1,0.3,0,0.5,0,0.5c0.3,0,0.7,0.5,1,0.7s0.5,0.2,0.8,0.2 c0.2-0.1,0.4-0.7,0.6-0.8l0,0l0,0c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.3,0,0.3,0.1C70.7,19.2,70.6,18.5,70.5,18.5z"
      />
      <path
        opacity="0.2"
        fill={colors[0]}
        d="M67.7,19.2c0,0,0.9,1.7,1.5,1.7s1.5-1.7,1.5-1.7L70.2,19 l-0.7,1h-0.6l-0.7-1L67.7,19.2z"
      />
      <path
        fill="#FFF"
        d="M69.1,18.5L69.1,18.5c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.1,0-0.2,0l-0.1,0.1l0,0v0.1v0.1l0.1,0.1 c0.1,0,0.1,0,0.2,0l0.1-0.1C69.1,18.6,69.1,18.6,69.1,18.5C69.1,18.6,69.1,18.6,69.1,18.5z"
      />
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="69.205"
        y1="1300.369"
        x2="69.304"
        y2="1295.411"
        gradientTransform="translate(0 -1280)"
      >
        <stop offset="0.004" stopColor="#ec1c24" stopOpacity="0.4" />
        <stop offset="1" stopColor="#ec1c24" stopOpacity="0" />
      </linearGradient>
    </g>
  )
}

export default LargeNose
