import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SlingshotL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SlingshotL") ? (
    <g id="SlingshotL">
      <path
        id="S_x24_slingshotL"
        d="M76.4423523,119.6609268c1.9000015,0.0999985,6.6999969-0.4000015,5.8000031-9.4000015 l-1.8000031-32.5999985l-0.5-2.3000031l-0.9000015-2.0999985l-3.5-3.6999969l-7.4000015-0.6999969l-6.2000008,2.8999939 c0,0,1.1999969,39.7999954,1.0999985,39.9000015l0.1999969,7.9000015 C63.34235,119.5609283,73.8423538,119.5609283,76.4423523,119.6609268z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SlingshotL, {
  character: "ManHalfling"
})
