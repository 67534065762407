import React from "react"

const HalfCircle = ({ colors = [] }) => {
  return (
    <g id="HalfCircle">
      <path
        id="halfCircle"
        d="M80.5,119.4c0,17.6-14.3,31.9-31.9,31.9S16.7,137,16.7,119.4H80.5z"
      />
    </g>
  )
}

export default HalfCircle
