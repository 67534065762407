import React from "react"

const Short = ({ colors = ["#FDDD40"] }) => {
  return (
    <g id="Short">
      <path
        fill={colors[0]}
        d="M68.5468674,8.49512C68.5434341,8.5002623,68.5502396,8.4893026,68.5468674,8.49512 C68.5434875,8.4893026,68.550293,8.5002623,68.5468674,8.49512c-7.0305443,0.0012989-6.1604919,7.7549715-6.1604919,7.7549715 c0.0913162,0.228241,0.5586815,3.856905,0.6043396,3.7199287c0.0370369-0.1110554-0.1483536-2.1570683,0.2396698-4.6794071 c0.0075836-0.3390369-0.1141205-1.5179529-0.1026955-1.8945971c0.0113754-0.3766441,1.2697258-3.3776207,5.4012871-1.8596678 c0.0036316-0.0043116,3.7727737-1.5690575,5.4370422,1.8596678c0.0114288,0.3766441-0.1102753,1.5555601-0.1026917,1.8945971 c0.3880234,2.5223389,0.2026367,4.5683517,0.2396698,4.6794071c0.0456543,0.1369762,0.5524597-3.4916878,0.6437759-3.7199287 C74.7467728,16.2500916,75.5774002,8.496419,68.5468674,8.49512z"
      />
    </g>
  )
}

export default Short
