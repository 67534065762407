import React from "react"

const Wave = ({ colors = ["#C07F00", "#FCE300"] }) => {
  return (
    <g id="Wave">
      <path
        fill={colors[0]}
        d="M72.4000015,39.2000008c2.0999985,0.7000008,4.8000031,3.4000015,5.3000031,6.9000015 c0.3000031,2,2,2.4000015,0.5999985,4.5999985c-0.9000015,1.2999992,1.1999969,2,0.3000031,4 c-0.4000015,1-1.5,1.5-1.3000031,2.7000008c0.1999969,0.7000008,0.8000031,1.7000008,3,1.5999985 c-2.5999985,1.5999985-5,0.7999992-5.8000031,0c-1-1-1.8000031-2.5-0.4000015-4.0999985 c1.5-1.7000008,1.5999985-3.5999985,2.0999985-5.5999985c0.0999985-0.5-0.0999985-1.4000015-0.1999969-2 c-0.1999969-0.7999992-0.3000031-1.2999992-0.6999969-2.0999985c-1.6999969-0.4000015-2.0999985-2.5-2.1999969-2.4000015 c-4,1.9000015-4.4000015,3.5999985-10.7000008,3.5999985c-0.4000015,0-1.2999992,2.5-0.0999985,4.4000015 c1,1.5,1.2000008,2.4000015,0.5999985,3.7999992c-0.4000015,0.9000015-1.5999985,1.7999992-2.9000015,2.5 C56.8000031,59.0000038,65,64.8000031,58.8000031,66.6000061c2.5999985-3.4000015-4-3.5-1.5-6.5 c0.7999992-0.9000015,0.2999992-1.5999985,0.0999985-2.0999985c-0.4000015-1.2000008-1.2999992-1.4000015-0.5-2.4000015 c0.5-0.5999985,1.5-1,0.7000008-2.4000015S57.4000015,51,58.2999992,50c0.5999985-0.7000008-0.9000015-1.5999985-0.0999985-3 c0.4000015-0.7999992,1.2999992-1.0999985,1.5999985-2.2999992c0.5999985-2.2000008,1.5-3.5999985,3.0999985-4.7999992 C66.0999985,37.9000015,69.4000015,38.0999985,72.4000015,39.2000008z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M60.7999992,55.0999985 C60,55,59.2000008,54.8999977,58.3999977,54.7999992c-0.0999985,0.5-0.5999985,0.7000008-1,1.0999985 c-0.2000008,0.2999992-0.2999992,0.5-0.2999992,0.7000008c1.2999992-0.5,1.7000008-0.2999992,2.2999992,0.2999992 C59.5999985,56.2999992,60.0999985,55.5999985,60.7999992,55.0999985z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M60.7999992,62.0999985 C60.5,61.3999977,60.0999985,60.6999969,59.7999992,60C59.2000008,59.9000015,58.5,59.7999992,57.8999977,59.7999992 c-0.0999985,0.2000008-0.2000008,0.2999992-0.2999992,0.5c-0.5,0.5999985-0.7000008,1.0999985-0.5999985,1.5 c0.5,0,0.0999985-0.7000008,1.5-0.5999985S60,62,60.7999992,62.0999985z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M65.5,39.2000008 c-1.7999992,0.9000015-2.5,2.7000008-3.4000015,4.0999985c1.2000008-1,2.5-1.7999992,3.6999969-2.2999992 c1.8000031-0.7999992,4-1.2000008,6.0999985-1.9000015c-0.8000031-0.2000008-1.5999985-0.4000015-2.4000015-0.5 C68.0999985,38.7999992,67.3000031,38.2999992,65.5,39.2000008z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M72.0999985,40.0999985 C69.6999969,40.8999977,67,40.6999969,64.6999969,42c-1.9000015,1-3.7000008,2.5999985-4.2000008,4.7999992 C61.1999969,44.8999977,63.1999969,44,65.3999939,43.3999977c3.3000031-0.9000015,5.6999969-1.5999985,7.9000015-3.5999985 c0,0-0.0999985,0-0.0999985-0.0999985C72.9000015,39.7999992,72.5,39.9000015,72.0999985,40.0999985z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M70.9000015,42.9000015 c-2.3000031,0.7999992-6.5999985,0.2000008-8.5999985,2.9000015c0.7999992-0.5999985,1.4000015-0.0999985,2.5-0.2999992 c2.9000015-0.5999985,4.9000015-1.4000015,6.9000015-3.0999985 C71.4000015,42.5999985,71.1999969,42.7999992,70.9000015,42.9000015z"
      />
      <path
        opacity="0.43"
        fill={colors[1]}
        d="M77.4000015,45.7999992 C77,43.7999992,75.8000031,42,74.5,40.7999992C73.3000031,43.4000015,76,44.2999992,77.4000015,45.7999992z"
      />
      <path
        fill="#21376C"
        d="M74.1999969,55.0999985C72.7999954,56.6999969,73.5,58.1999969,74.5999985,59.1999969 c0.4000015,0.4000015,1,0.7000008,1.9000015,0.7999992c0.6999969-0.0999985,1.3000031-0.2999992,1.8000031-0.7000008 c0,0,0,0-0.0999985,0c-0.5,0.0999985-1,0-1.4000015-0.2000008c-1.1999969-0.5999985-1.5-1.9000015-1.0999985-3.0999985 c0.0999985-0.2999992,0.1999969-0.5999985,0.4000015-0.9000015c0.0999985-0.2000008,0.1999969-0.4000015,0.1999969-0.5999985l0,0 c0.5-3.2999992,0.9000015-6.5-0.5999985-9.6000023c-0.4000015-0.2000008-0.6999969-0.5-1-0.9000015 c-0.5999985-0.7999992-0.9000015-1.7999992-1.1999969-2.7999992c-1,1.2000008-2.5,2.2000008-3.8000031,2.9000015 c-2.3000031,1.2999992-5,1.7000008-7.5999985,2c0,0.0999985-0.0999985,0.2000008-0.2000008,0.2999992 c-1.5999985,2.2000008,0.2999992,4.5999985-0.0999985,6.9000015c-0.2000008,1.2999992-1.0999985,2-1.7000008,3 c-0.7000008,1.2000008-0.9000015,1.9000015-0.7000008,3.0999985h0.0999985 c-0.2999992-0.9000015-0.2999992-1.7000008,0.5999985-2.2000008c1.2999992-0.7999992,2.5-1.5999985,2.9000015-2.5 c0.5999985-1.4000015,0.4000015-2.2999992-0.5999985-3.7999992c-1.2000008-1.9000015-0.2999992-4.4000015,0.0999985-4.4000015 c6.2999992,0,6.7000008-1.7000008,10.7000008-3.5999985c0.0999985,0,0.5,2.0999985,2.1999969,2.4000015 c0.4000015,0.7999992,0.5,1.2999992,0.6999969,2.0999985c0.0999985,0.5,0.3000031,1.5,0.1999969,2 C75.9000015,51.5999985,75.6999969,53.4000015,74.1999969,55.0999985z"
        opacity="0.2"
      />
    </g>
  )
}

export default Wave
