import React from "react"

const NosePointy = ({
  colors = [
    "#EF4E23",
    "#EF4C23",
    "#ED3324",
    "#EC2324",
    "#EC1E24",
    "#SVGID_",
    "#353891",
    "#FFFFFF"
  ]
}) => {
  return (
    <g id="NosePointy">
      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1="47.5"
        y1="63.474"
        x2="47.465"
        y2="53.64"
      >
        <stop offset="0" stopColor={colors[0]} />
        <stop offset="0.029" stopColor={colors[1]} stopOpacity="0.971" />
        <stop offset="0.396" stopColor={colors[2]} stopOpacity="0.604" />
        <stop offset="0.731" stopColor={colors[3]} stopOpacity="0.269" />
        <stop offset="1" stopColor={colors[4]} stopOpacity="0" />
      </linearGradient>
      <path
        fill="url(#SVGID_6_)"
        d="M47.4846764,54.9129066l-0.6111717,0.7782898 c0,0,0.1631317,1.5435257-0.5306511,2.5471306c-0.1224403,0.1771164-0.7894821-0.0320053-1.0966797,0.1061325 c-0.5004654,0.2250366-0.6797485,0.7498589-0.7075386,0.7429123c-0.1415062-0.0353775,0.7075386,0.7075348,0.8490448,0.6721573 s0.3891449-0.1061287,0.8136673,0.2476387c0.4245186,0.3537674,0.4245186,0.6721611,0.7782898,0.8844185h1.0100822 c0.3537674-0.2122574,0.3537674-0.5306511,0.7782898-0.8844185c0.4245186-0.3537674,0.6721573-0.2830162,0.8136635-0.2476387 c0.14151,0.0353775,0.990551-0.7075348,0.8490448-0.6721573c-0.0277901,0.0069466-0.2070694-0.5178757-0.7075348-0.7429123 c-0.3072014-0.1381378-0.9742432,0.0709839-1.0966835-0.1061325c-0.6937828-1.0036049-0.5306511-2.5471306-0.5306511-2.5471306 L47.4846764,54.9129066L47.4846764,54.9129066z"
      />
      <path
        opacity="0.32"
        fill={colors[6]}
        d="M44.5657463,59.2655373 c0-0.0536461,0.241394-0.241394,1.1533241,0.1341057s1.2069626,0.9387512,1.7702179,0.9387512 s1.0397263-0.5231552,1.7702103-0.9655724c0.3469391-0.210125,0.841732-0.1539421,1.0192261-0.1877518 c0.0280914,0.2247238-0.7455139,0.6294861-1.4215393,1.0728607c-0.6149559,0.4033203-0.8046455,0.8314667-1.5556488,0.8314667 c-0.3161163,0-0.7039948-0.4517059-1.2337875-0.8314667C45.338913,59.7354927,44.5657463,59.2655373,44.5657463,59.2655373z"
      />
      <ellipse
        opacity="0.3"
        fill="#FFF"
        cx="47.221"
        cy="59.842"
        rx="0.268"
        ry="0.241"
      />
    </g>
  )
}

export default NosePointy
