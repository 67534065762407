import React from "react"

const PlateArmor = ({ colors = ["#7F7F7F", "#FFFFFF"] }) => {
  return (
    <g id="PlateArmor">
      <path
        fill={colors[0]}
        d="M54.9934502,66.5182571c-1.5842781-0.533371-2.7842407-0.5461426-2.7842407-0.5461426 s-3.437355,1.2867889-4.8041763,1.2867889s-4.1560287-1.3550873-4.1560287-1.3550873l-3.4423256,0.8875732 c-1.984993,1.8555984,0.1829224,7.9000854,0.5062256,12.0839844l-0.8397179,13.2056885l0.3037109,2.8674316l1.4680786-2.1846924 l-0.0423584-0.0714111l0.059082-0.022644l-0.0167236,0.0258179l1.265564,2.5942993l0.9199829-2.0896606l1.5098877,2.5114746 l1.1643066-2.3895264l0.0506592-0.0682373l0.1518555,2.5942993l1.4174194,2.0481567l1.6705322-2.1846924l0.2025146-2.321228 l0.0505981,0.0682373l1.3668213,2.2529907l1.0386353-2.1344604l0.9356689,1.7930908l1.2149048-2.8673706l1.6199341,2.526001 l0.0506592-3.1405029l-0.8308716-13.0812988C54.8684425,75.2361984,57.7314034,67.9940262,54.9934502,66.5182571z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M41.8393669,82.8006821c0,0-1.6312103-8.9947052,5.7090721-8.9947052 s6.1366272,8.9947052,6.1366272,8.9947052s-0.7077179-7.2398529-6.206131-7.2398529S41.8393669,82.8006821,41.8393669,82.8006821z"
      />
      <path
        opacity="0.2"
        d="M42.1042595,82.5471573c1.8900871,0,11.1276016,0,11.1276016,0 c-0.2877808-3.4048386-2.6380081-6.092865-5.5638008-6.092865C44.6943054,76.4542847,42.2217827,79.2408829,42.1042595,82.5471573 z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M47.4203644,67.4925537l-4.4006462-1.1187592l-3.0576515,0.6437149 l0.6437149,3.6611328c0,0,2.3474388,1.4804153,3.5624046,1.5487137c1.2149658,0.0682373,3.0880127-0.204834,3.0880127-0.204834 l0.1842041-3.0244751l0.1842041,3.0244751c0,0,1.8730469,0.2730713,3.0879517,0.204834 c1.2149658-0.0682983,3.4112587-1.1061554,3.4112587-1.1061554l1.0862694-3.9427643l-2.8162575-0.8448792L47.4203644,67.4925537z"
      />
      <path
        opacity="0.2"
        d="M54.3573952,71.6702042c0,0-2.4298706,1.0923462-3.6448364,1.1606445 c-1.2149048,0.0682373-3.0879517-0.204834-3.0879517-0.204834h-0.3684082c0,0-1.8730469,0.2730713-3.0880127,0.204834 c-1.2149658-0.0682983-3.8842621-1.7096481-3.8842621-1.7096481l-0.6839485-3.620903l0.241394,4.1841507 c0,0,3.1118507,1.9631729,4.3268166,2.0314713c1.2149658,0.0682373,3.0880127-0.204834,3.0880127-0.204834h0.3684082 c0,0,1.8730469,0.2730713,3.0879517,0.204834c1.2149658-0.0682983,4.2159004-1.70961,4.2159004-1.70961l0.4827881-3.6611404 L54.3573952,71.6702042z"
      />
      <path
        opacity="0.2"
        d="M45.9164391,84.9208527c0.0804634,0.1609344,0.3907928,10.9273605,0.3907928,10.9273605 l-0.0689354-11.0480576L45.9164391,84.9208527z"
      />
      <path
        opacity="0.2"
        d="M49.295948,84.9208527c-0.0402336,0.120697,0.0804634,10.621315,0.0804634,10.621315 l0.120697-10.7017746L49.295948,84.9208527z"
      />
      <path
        opacity="0.2"
        d="M42.4966965,95.0593796l-1.2940788-2.3669891l0.5296669-7.9324646l1.367897,0.0402298 c0,0,0.2816238,8.3280792,0.3620911,8.2073822C43.5427361,92.8868408,42.4966965,95.0593796,42.4966965,95.0593796z"
      />
      <path
        opacity="0.2"
        d="M52.9894218,95.3703079l1.2282677-2.8321457l-0.7392654-7.7782364l-0.8834343,0.0804672 c0,0-0.4425545,8.7303925-0.5230179,8.6096954S52.9894218,95.3703079,52.9894218,95.3703079z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M46.6406174,84.9409714 c-0.0402298,0.0603485,0.120697,10.6414261,0.120697,10.6414261l0.9052277,1.8708038c0,0,1.4483604-1.8104553,1.4282417-1.8506851 c-0.0201149-0.0402298-0.1206932-10.7218933-0.1206932-10.7218933l-0.3218613,0.120697l0.1609306,10.4804993 c0,0-1.1466179,1.6294022-1.126503,1.5690536c0.0201149-0.0603409-0.6437149-1.5087051-0.6437149-1.5087051l-0.120697-10.4603806 L46.6406174,84.9409714z"
      />
      <rect
        x="41.129"
        y="82.748"
        opacity="0.2"
        fill="#FFF"
        width="12.995"
        height="1.891"
      />
      <polygon
        opacity="0.2"
        points="40.2436905,82.7483139 40.1632271,84.478302 40.7264786,84.5587692 40.7264786,82.7483139"
      />
      <polygon
        opacity="0.2"
        points="54.3249779,82.8287811 54.3652077,84.598999 55.1296234,84.598999 55.0491562,82.7885437"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="39.9620667,84.5587692 39.4731865,92.0810623 39.8413696,94.7777557 41.2026176,92.6923904 41.7322845,84.7599258"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="55.248642,84.5587692 55.8749199,91.8884354 55.8242607,95.0289383 54.2043266,92.5029373 53.4784241,84.7599258"
      />
    </g>
  )
}

export default PlateArmor
