import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LongBowLeft from "./LongBowLeft"
import LongSwordLeft from "./LongSwordLeft"
import GoldAxeLeft from "./GoldAxeLeft"
import WarhammerL from "./WarhammerL"
import MaceL from "./MaceL"
import StaffLeft from "./StaffLeft"
import AxeLeft from "./AxeLeft"
import LuteLeft from "./LuteLeft"
import DaggerLeft from "./DaggerLeft"
import BattleAxeLeft from "./BattleAxeLeft"
import MorningStarLeft from "./MorningStarLeft"
import SlingShotLeft from "./SlingShotLeft"

const CATEGORY = "leftHandItem"
const CHARACTER = "WomanGnome"
export const IDS = {
  LONG_BOW_LEFT: "LongBowLeft",
  LONG_SWORD_LEFT: "LongSwordLeft",
  GOLD_AXE_LEFT: "GoldAxeLeft",
  WARHAMMER_L: "WarhammerL",
  MACE_L: "MaceL",
  STAFF_LEFT: "StaffLeft",
  AXE_LEFT: "AxeLeft",
  LUTE_LEFT: "LuteLeft",
  DAGGER_LEFT: "DaggerLeft",
  BATTLE_AXE_LEFT: "BattleAxeLeft",
  MORNING_STAR_LEFT: "MorningStarLeft",
  SLING_SHOT_LEFT: "SlingShotLeft"
}

export const components = {
  [IDS.LONG_BOW_LEFT]: LongBowLeft,
  [IDS.LONG_SWORD_LEFT]: LongSwordLeft,
  [IDS.GOLD_AXE_LEFT]: GoldAxeLeft,
  [IDS.WARHAMMER_L]: WarhammerL,
  [IDS.MACE_L]: MaceL,
  [IDS.STAFF_LEFT]: StaffLeft,
  [IDS.AXE_LEFT]: AxeLeft,
  [IDS.LUTE_LEFT]: LuteLeft,
  [IDS.DAGGER_LEFT]: DaggerLeft,
  [IDS.BATTLE_AXE_LEFT]: BattleAxeLeft,
  [IDS.MORNING_STAR_LEFT]: MorningStarLeft,
  [IDS.SLING_SHOT_LEFT]: SlingShotLeft
}

export const Group = ({ props }) => (
  <g id="leftHandItem">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LONG_BOW_LEFT,
    name: "Long Bow Left",
    defaultColors: [
      "#695E60",
      "#42130B",
      "#FFCD03",
      "#EDD692",
      "#B5AC95",
      "#915B25",
      "#A98A30"
    ],
    colorable: false,
    component: components[IDS.LONG_BOW_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_SWORD_LEFT,
    name: "Long Sword Left",
    defaultColors: [
      "#D0A92B",
      "#A8ABAD",
      "#D5D8DA",
      "#B49330",
      "#FFD144",
      "#EDD692",
      "#7B6528",
      "#A38634",
      "#7951A0"
    ],
    colorable: false,
    component: components[IDS.LONG_SWORD_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_LEFT,
    name: "Gold Axe Left",
    defaultColors: ["#897441", "#A39155", "#EDCD6E"],
    colorable: false,
    component: components[IDS.GOLD_AXE_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WARHAMMER_L,
    name: "Warhammer L",
    defaultColors: [
      "#B8B9BA",
      "#727474",
      "#E6E7E7",
      "#646565",
      "#7F8080",
      "#636565",
      "#CDCFCF",
      "#A0A0A1",
      "#D6D7D5"
    ],
    colorable: false,
    component: components[IDS.WARHAMMER_L],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MACE_L,
    name: "Mace L",
    defaultColors: [
      "#595A5B",
      "#B8B9BA",
      "#777676",
      "#9A9B9C",
      "#E0E1E1",
      "#E4E3E3"
    ],
    colorable: false,
    component: components[IDS.MACE_L],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.STAFF_LEFT,
    name: "Staff Left",
    defaultColors: [
      "#865623",
      "#B68D5F",
      "#525A56",
      "#FFFFFF",
      "#27A8E0",
      "#8080BC",
      "#SVGID_",
      "#292C6A"
    ],
    colorable: false,
    component: components[IDS.STAFF_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.AXE_LEFT,
    name: "Axe Left",
    defaultColors: ["#8A5F3C", "#6D6E71", "#D1D2D3", "#808184"],
    colorable: false,
    component: components[IDS.AXE_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LUTE_LEFT,
    name: "Lute Left",
    defaultColors: ["#93712B", "#D8A43D", "#6A5121", "#B68B32", "#8E6D2B"],
    colorable: false,
    component: components[IDS.LUTE_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DAGGER_LEFT,
    name: "Dagger Left",
    defaultColors: [
      "#6E5E26",
      "#99B7D9",
      "#6B91BF",
      "#57789B",
      "#E1E2E3",
      "#9EA0A3",
      "#ECD592"
    ],
    colorable: false,
    component: components[IDS.DAGGER_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BATTLE_AXE_LEFT,
    name: "Battle Axe Left",
    defaultColors: ["#7A7C7D", "#505052"],
    colorable: false,
    component: components[IDS.BATTLE_AXE_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MORNING_STAR_LEFT,
    name: "Morning Star Left",
    defaultColors: [
      "#939393",
      "#BCBCBB",
      "#D8D8D7",
      "#B68D5F",
      "#856845",
      "#533F2B",
      "#535352",
      "#C1C1BF",
      "#78787A",
      "#SVGID_",
      "#FFFFFF",
      "#F5F5F5",
      "#E4E4E5",
      "#C7C8CA",
      "#A0A2A5",
      "#757779",
      "#2D2D2E",
      "#000000"
    ],
    colorable: false,
    component: components[IDS.MORNING_STAR_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SLING_SHOT_LEFT,
    name: "Sling Shot Left",
    defaultColors: ["#352915", "#464646", "#939393", "#724F20"],
    colorable: false,
    component: components[IDS.SLING_SHOT_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
