import React from "react"

const BlueFlameRight = ({
  colors = ["#80D0DC", "#30BFCA", "#5BC6D1", "#A8DDE6", "#C0E6F0", "#E5F5F8"]
}) => {
  return (
    <g id="BlueFlameRight">
      <path
        opacity="0.77"
        fill={colors[0]}
        d="M35.9240837,74.4078827 c-0.7610893-1.6852646-1.6852646-3.3161621-2.8268967-4.8383408c-0.0543633,0-0.0543633,0-0.1087265,0.0543671 c-0.1087265,0.0543594-0.8698158,0.434906-0.8154526,0c0.1087303-0.8154526,0.3261833-1.8483582,0-2.6094437 c-0.2174511-0.5979996-0.3261795-2.3376236-0.7610855-2.8268967c-0.0543633-0.1087265-1.0872669-2.6638069-1.3590851-3.3161659 c-0.434906,1.4134483,0.2718182,3.15308-0.4892693,4.4034309c-0.0543633,0.0543671-1.4678116,3.0443497-1.5765381,3.1530762 c-0.0543633,2.0114441-0.217453,4.0772552-0.6523609,6.0343399c0.217453-0.1087265,0.5979977-0.2718201,0.5436344-0.1087265 c-0.5979977,2.6638031-2.3919888,4.8383331-2.9899864,7.5021439c0,0.1087265-0.1087265,0.217453-0.217453,0.2718124 c0.217453,1.5765381,1.1959953,2.9899902,2.2832623,4.2403488c-0.5979977-1.4134521-1.0329037-2.8268967-1.0329037-4.3490753 c0-0.217453,0.3261795-0.434906,0.7067242-0.6523514c0.1087265-1.7396317,0.2718163-3.3161697,1.3590832-4.8383484 c1.3047218-1.9027176,0.8698139-3.9685211,1.1959953-5.9256058c-0.0543633,0-0.0543633,0-0.0543633-0.0543594 c0-0.434906,0.0543633-0.869812,0.1087265-1.3047256V69.189003c0,0,0,0,0-0.0543671 c0.0543633-0.3261795,0.1087265-0.652359,0.217453-0.9785385c0-0.2718124,1.7396278-2.1201706,1.7939911-2.0114441 c0.1087265,0.434906,0.3261814,0.7610855,0.2718163,1.1416321c0.0543652,0,0.1087284,0,0.1087284,0.0543594 c0,1.1959991,0.0543633,2.3919907,0.217453,3.5879822c0.0543633,0,0.0543633,0,0.1087265,0.0543671 c1.2503567,1.3590775,1.6309032,3.3161621,2.228899,5.0557861c0.7067223,2.1201706,1.087265,3.4792557,1.1416283,5.7081528 c0,0.1087265-0.0543633,0.1630936-0.1087265,0.2718201c0.434906,1.2503586-0.217453,2.6638031-0.4892731,3.9685287 c-0.0543594,0.1630936-0.1087265,0.3805389-0.1630898,0.5979919c0.5979996-1.1959915,1.0872688-2.4463501,1.5765419-3.7510681 C36.0328102,80.061676,35.978447,77.2347794,35.9240837,74.4078827z"
      />
      <g opacity="0.98">
        <path
          fill={colors[1]}
          d="M31.5750141,88.053093c-1.0329056-0.3261795-1.9570827-0.7067261-2.8268967-1.1959915 c-0.217453-0.3805466-0.3805447-0.8154526-0.5979977-1.1959991c-0.4892693-1.0329056-0.8698139-2.1201706-1.2503567-3.3161621 c0.0543633-0.3805389,0.1087265-0.7610855,0.217453-1.087265c0.1087265-0.4349136,0.2718163-0.9241791,0.3805447-1.3590851 c0.217453-0.7067261,0.3805428-1.4134445,0.5436325-2.1201706l0.5436344-0.2174606l-0.2718163-0.5436325 c0-0.0543594-0.0543633-0.0543594-0.0543633-0.1087265c1.1416302-1.3590851,1.7939892-2.7181702,2.0114441-4.0228882 c0.0543633-0.4892731,0.0543633-0.9241791,0-1.4134445l0.3261814-0.1630936 c0.1087265,0.3261795,0.217453,0.5979996,0.3261795,0.8154526c0.3805447,0.9785385,0.8698139,1.9027176,1.3047218,2.7725296 c0.5436325,1.087265,1.087265,2.0658112,1.4678116,3.1530762c0.9241753,2.7181702,0.8154488,5.5994339-0.3805466,9.1874084 C32.7710075,87.618187,32.1730118,87.8899994,31.5750141,88.053093z M30.2702923,70.493721 c-0.3805447-0.3805389-0.5979977-0.9241714-0.5979977-1.4678116c0.0543633-0.434906,0.217453-0.869812,0.5436344-1.1416245 C29.998476,68.8084564,30.1072025,69.7326355,30.2702923,70.493721L30.2702923,70.493721z"
        />
        <path
          fill={colors[2]}
          d="M31.5206509,88.1618195c-0.8698139-0.2718201-1.6309013-0.5979996-2.3919888-0.9785385 c-0.1630898-0.3261795-0.3261795-0.7067261-0.4892693-1.0329056c-0.4349079-0.9785461-0.8154507-2.0114441-1.1959953-3.0987167 c0.0543633-0.3261795,0.1087265-0.5979919,0.1630898-0.869812c0.1087265-0.434906,0.217453-0.869812,0.3805447-1.3590851 c0.1630898-0.5979919,0.3261795-1.1959915,0.4892693-1.8483505l0.8154507-0.3805466l-0.434906-0.869812 c0.9785404-1.2503586,1.5765381-2.4463501,1.7939911-3.6423416c0.2718163,0.652359,0.5979977,1.250351,0.9241772,1.8483505 c0.5436344,1.0329056,1.0872669,2.0114441,1.4134464,3.0987167c0.8698158,2.5550766,0.7610893,5.2732391-0.3261795,8.5894089 C32.3904648,87.83564,31.9555569,87.9987259,31.5206509,88.1618195z"
        />
        <path
          fill={colors[0]}
          d="M31.4662876,88.1618195c-0.7067242-0.217453-1.3590851-0.4892731-1.9570827-0.8154526 c-0.1087265-0.2718124-0.2718163-0.5436325-0.3805428-0.869812c-0.4349079-0.9241714-0.7610874-1.8483582-1.1416321-2.9356232 c0.0543633-0.2718124,0.1087284-0.4892731,0.1630898-0.7067261c0.1087284-0.434906,0.217453-0.869812,0.3805447-1.304718 c0.1630898-0.4892731,0.2718163-1.0329056,0.434906-1.5765381l1.1416321-0.4892731l-0.5436344-1.087265 c0.5436344-0.7067261,0.9785404-1.4134521,1.2503567-2.1745377c0.1087284,0.1630859,0.1630898,0.3805466,0.2718182,0.5436325 c0.5436325,1.0329056,1.0329018,2.0114441,1.4134483,2.9899902c0.8154488,2.391983,0.7067223,4.9470596-0.217453,8.0457764 C31.9555569,87.9443665,31.7381039,88.053093,31.4662876,88.1618195z"
        />
        <path
          fill={colors[3]}
          d="M31.4119244,88.2161789c-0.5436344-0.1630859-1.0329056-0.3805389-1.4678116-0.5979919 c-0.1087284-0.217453-0.2174549-0.434906-0.3261814-0.652359c-0.3805447-0.869812-0.7067223-1.739624-1.0329037-2.7181702 c0.0543633-0.1630936,0.0543633-0.3261795,0.1087265-0.4892731c0.1087284-0.434906,0.2174549-0.869812,0.3805447-1.304718 c0.1087265-0.4349136,0.2718163-0.869812,0.3805447-1.3047256l1.4678116-0.652359l-0.7067242-1.304718 c0.217453-0.3261795,0.434906-0.5979996,0.5979977-0.9241791c0.434906,0.8154526,0.8154507,1.6309052,1.0872669,2.4463577 c0.7610855,2.17453,0.7067223,4.5665207-0.1630898,7.4477768C31.5750141,88.10746,31.5206509,88.1618195,31.4119244,88.2161789z"
        />
        <path
          fill={colors[4]}
          d="M31.1944695,87.9987259c-0.3261795-0.1087265-0.5979958-0.217453-0.8698139-0.3805389 c-0.0543633-0.1630936-0.1630898-0.3261795-0.217453-0.434906c-0.3805447-0.8154526-0.7067223-1.6309052-0.9785404-2.5007172 c0-0.1087265,0.0543633-0.217453,0.0543633-0.3261795c0.1087284-0.434906,0.2174549-0.869812,0.3805447-1.3047256 c0.1087265-0.3261795,0.217453-0.7067261,0.3261814-1.087265l1.4134464-0.5979919 C31.9555569,83.3234787,31.9011936,85.4436493,31.1944695,87.9987259z"
        />
        <path
          fill={colors[5]}
          d="M31.303196,88.270546c-0.0543633-0.1087265-0.0543633-0.1630936-0.1087265-0.2718201 c-0.3261814-0.7610855-0.6523609-1.467804-0.9241772-2.2832565c0-0.0543671,0-0.1087265,0.0543633-0.1630936 c0.1087265-0.434906,0.217453-0.869812,0.3261795-1.2503586c0.0543633-0.2718201,0.1630898-0.5436325,0.217453-0.8154526 l0.5436344-0.217453C31.8468304,84.7369232,31.8468304,86.3678284,31.303196,88.270546 C31.3575592,88.270546,31.3575592,88.270546,31.303196,88.270546z"
        />
      </g>
    </g>
  )
}

export default BlueFlameRight
