import React from "react"
import { withSkinColor } from "../../builderConnector"

const Thumbs = ({ skinColor, colors = ["#DEA043", "#21366C"] }) => {
  return (
    <g id="Thumbs">
      <path
        fill={skinColor}
        d="M98.1367722,55.6740341c0,0-0.283905-0.6604843,0.05336-1.4051819 c0.3372574-0.7447014,1.3977585-0.4431763,1.9918747-0.1623878c0.5941238,0.2807846,1.7810516,1.2178879,3.8346786,1.9977303 c0.5180435,0.1967201,1.5083847-0.6851616,1.8656921-0.5897179c1.7737503,0.4738121,1.3696671,2.0549545,1.3696671,2.0549545 c-0.035759,1.0367165-0.1110992,1.9255676-0.635437,3.2500267l-1.9902573,2.8400154 c-2.1320724,1.9078484-3.4153671,1.8902473-3.9198608,1.7587852c-0.100708-0.0351791-0.1506119-0.0683746-0.1506119-0.0683746 l-0.0911255-0.0609436l-4.5447083-2.8514442c0,0-0.8907318-0.950058-0.5544281-1.4177628 c0.3363113-0.4677048,0.726181-0.4980164,0.726181-0.4980164l-0.3786926-0.4881439c0,0-0.1972427-0.1864471-0.0979919-0.7433281 s1.0936737-0.670742,1.0936737-0.670742s0.2355652,0.1442947,0.3783875-0.1295509 c0.1428299-0.2738457-0.1592865-0.354248-0.1592865-0.354248s-0.9069061-0.6552162-0.5393829-1.4667435 c0.3675308-0.8115273,1.4113846-0.5832787,1.4113846-0.5832787s0.5747757,0.3919563,0.747345,0.0939674 C98.7198029,55.8816566,98.1367722,55.6740341,98.1367722,55.6740341z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M107.2520447,57.5694313c-0.035759,1.0367165-0.1110992,1.9255676-0.635437,3.2500267 l-1.9902573,2.8400154c-2.1320724,1.9078484-3.4153671,1.8902473-3.9198608,1.7587852 c-0.100708-0.0351791-0.1506119-0.0683746-0.1506119-0.0683746l-0.0911255-0.0609436 c0.0632019,0.0179062,0.1264496,0.0383224,0.1888504,0.0448227c0.4997406,0.0520706,0.9593964-0.1713104,1.1132507-0.580986 c0.1906967-0.5077133-0.1606903-1.1094513-0.7849274-1.3438454l0.0439148-0.1168785 c0.8267441,0.3104362,1.6660309,0.1116791,1.8747711-0.4439583c0.2086868-0.5556602-0.2923355-1.2578049-1.1190796-1.5682373 l-0.0000458-0.0000229l0.1713104-0.4560585c0.1105042,0.0640831,0.2268448,0.1233673,0.3541412,0.171154 c0.8267288,0.310482,1.663475,0.1185799,1.869072-0.4286194c0.2054291-0.5472107-0.2981873-1.2425232-1.1249084-1.5530052 c-0.1272049-0.0477409-0.25383-0.0797195-0.3792267-0.1042061l0.1713333-0.4561081 c0.8266525,0.3105049,1.6657257,0.1126099,1.8739929-0.4420013c0.2082977-0.5545387-0.2930145-1.2559128-1.119812-1.5663681 c0,0,0.5536346,0.0219116,1.2702026,0.396965c0.4268188,0.2233963,0.7659073,0.6658134,1.2452774,0.2818565 C107.5385513,55.2489433,107.2520447,57.5694313,107.2520447,57.5694313z"
      />
      <path
        fill={skinColor}
        d="M40.1712532,55.6740341c0,0,0.283905-0.6604843-0.05336-1.4051819 c-0.3372612-0.7447014-1.3977585-0.4431763-1.9918785-0.1623878c-0.5941162,0.2807846-1.7810516,1.2178879-3.8346786,1.9977303 c-0.5180397,0.1967201-1.5083809-0.6851616-1.8656845-0.5897179c-1.7737503,0.4738121-1.3696709,2.0549545-1.3696709,2.0549545 c0.0357628,1.0367165,0.1110973,1.9255676,0.6354351,3.2500267l1.9902592,2.8400154 c2.1320724,1.9078484,3.4153633,1.8902473,3.9198608,1.7587852c0.100708-0.0351791,0.1506157-0.0683746,0.1506157-0.0683746 l0.0911255-0.0609436l4.5447083-2.8514442c0,0,0.890728-0.950058,0.5544205-1.4177628 c-0.3363037-0.4677048-0.7261772-0.4980164-0.7261772-0.4980164l0.3786888-0.4881439 c0,0,0.1972466-0.1864471,0.0979958-0.7433281s-1.0936699-0.670742-1.0936699-0.670742 s-0.2355652,0.1442947-0.3783951-0.1295509c-0.1428261-0.2738457,0.1592903-0.354248,0.1592903-0.354248 s0.9069061-0.6552162,0.5393791-1.4667435s-1.411377-0.5832787-1.411377-0.5832787s-0.5747795,0.3919563-0.7473488,0.0939674 C39.5882225,55.8816566,40.1712532,55.6740341,40.1712532,55.6740341z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M31.0559807,57.5694313c0.0357628,1.0367165,0.1110973,1.9255676,0.6354351,3.2500267 l1.9902592,2.8400154c2.1320724,1.9078484,3.4153633,1.8902473,3.9198608,1.7587852 c0.100708-0.0351791,0.1506157-0.0683746,0.1506157-0.0683746l0.0911255-0.0609436 c-0.0632019,0.0179062-0.1264572,0.0383224-0.1888504,0.0448227c-0.4997444,0.0520706-0.9594002-0.1713104-1.1132507-0.580986 c-0.1907005-0.5077133,0.1606865-1.1094513,0.7849236-1.3438454l-0.043911-0.1168785 c-0.8267441,0.3104362-1.6660385,0.1116791-1.8747749-0.4439583c-0.2086906-0.5556602,0.2923355-1.2578049,1.1190796-1.5682373 l0.0000458-0.0000229l-0.1713066-0.4560585c-0.1105042,0.0640831-0.2268486,0.1233673-0.3541489,0.171154 c-0.826725,0.310482-1.663475,0.1185799-1.8690643-0.4286194c-0.2054329-0.5472107,0.2981796-1.2425232,1.1249046-1.5530052 c0.1272087-0.0477409,0.25383-0.0797195,0.3792267-0.1042061l-0.1713295-0.4561081 c-0.8266563,0.3105049-1.6657295,0.1126099-1.8740005-0.4420013c-0.2082977-0.5545387,0.2930183-1.2559128,1.119812-1.5663681 c0,0-0.553627,0.0219116-1.2701988,0.396965c-0.4268188,0.2233963-0.7659073,0.6658134-1.2452774,0.2818565 C30.7694702,55.2489433,31.0559807,57.5694313,31.0559807,57.5694313z"
      />
    </g>
  )
}

export default withSkinColor(Thumbs, { character: "ManDragonborn" })
