import React from "react"

const FrontShieldR = ({ colors = ["#A8A6A3", "#262261", "#FFFFFF"] }) => {
  return (
    <g id="FrontShieldR">
      <path
        fill={colors[0]}
        d="M42.1,37c6.3-2.3,12.1,7.9,13.5,19.3s-2.5,23.6-8.4,22s-10-7.4-11.4-18.8C34.3,48.1,35.7,39.3,42.1,37z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M41.6,38.2c0.5-0.2,1-0.3,1.5-0.3c4.6,0,9.4,8.4,10.7,18.7 c1,7.7-0.6,16.1-3.6,19.6c-0.9,1-1.8,1.5-2.7,1.5c-0.3,0-0.5,0-0.8-0.1C40.9,76,37.3,70,36,59.7c0-0.1,0-0.1,0-0.2 c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c1.3,9.6,4.6,15.1,9.9,16.6c0.2,0,0.4,0.1,0.5,0.1c0.6,0,1.3-0.4,2-1.1 c2.8-3.2,4.3-11.4,3.4-18.8C51.1,46.1,46.3,39,42.7,39c-0.4,0-0.7,0.1-1.1,0.2c-5.2,1.8-6,9.4-5.5,16.6c0.3,0,0.6-0.1,0.7,0 c0.9,1.4,2.3,2.4,4,2.4c0.7,0,0.1,0.1-0.3,0.1c-1.8-0.1-3.4-0.8-4.4-2.1c0.1,0.7,0.1,1.3,0.2,2c-0.2,0-0.3-0.1-0.4-0.2 c0,0,0,0,0,0c-0.1,0.1-0.1,0.3-0.1,0.4C34.9,50.2,35.3,40.5,41.6,38.2z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M39.8,60.7c-0.5,2-0.8,4-1,6c0,0.1,1,0.1,1,0 c0.2-1.7,0.5-3.4,0.8-5c0.4,1,1.1,2.4,2,2.7c0.1,0,0.2,0,0.3,0c0.5,3.2,1.1,6.3,1.9,9.4c0,0.2,1,0,1,0c-0.8-3.2-1.4-6.4-1.9-9.6 c0.8-0.4,1.4-1.3,1.8-2c0.8,2.5,1.9,4.8,3.3,7c0.1,0.2,1-0.1,1,0c-1.7-2.6-2.9-5.5-3.8-8.5c0.1-0.6,0.2-1.2,0.2-1.8 c1.4-0.2,2.7-0.7,3.9-1.3c-0.1,0-0.7-0.2-1,0c-0.9,0.4-1.9,1-3,1.2c0-0.6,0-1.1-0.1-1.7c-0.1-0.5-0.2-1.2-0.3-1.9 c0.2,0,0.3,0,0.4-0.1c0.9-1.5,1.6-3.1,2-4.8c0,0-1-0.2-1,0c-0.4,1.4-0.9,2.7-1.6,4c-0.3-1-0.7-1.9-1.4-2.2c-0.1,0-1-0.1-1,0 c0,0.2,0,0.3,0,0.5c-0.3,0-0.6,0-0.8,0c-0.4-4.6-0.6-10.6,0.5-10.8c0.4-0.1-0.6-0.1-0.7-0.1c-1.6,0.3-0.9,3.6-0.9,4.8 c0,2.1,0.1,4.2,0.2,6.3c-0.6,0.1-1.1,0.4-1.5,0.8c-0.7-1.5-1.5-3-2.2-4.6c-0.1-0.2-1,0-1,0c0.8,1.6,1.6,3.3,2.4,4.9 c0.1,0.1,0.3,0.1,0.5,0.1c-0.2,0.3-0.3,0.7-0.4,1.2c-0.2,1.8,0.1,3.7,0.7,5.4C40,60.6,39.8,60.6,39.8,60.7z M43.8,63.5 c-0.3-2-0.6-4-0.8-6c-0.1-0.6-0.2-2.3-0.4-4.4c0.3,0.2,0.5,0.3,0.8,0.5c0.1,0.1,1,0.1,1,0c0-0.1,0-0.2,0-0.3 c0.8,1.5,1.1,4.3,1.1,5.7c-0.3,0-0.6,0-1-0.1c-0.2-0.1-1,0-0.9,0.1c0.6,0.2,1.2,0.2,1.8,0.2c0,0,0,0.1,0,0.1 c0,0.4-0.1,0.9-0.2,1.3c-0.1,0-0.1,0-0.1,0c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.3-0.2,0.7-0.3,1c-0.2,0.4-0.4,0.8-0.6,1.2 C44.1,63.1,44,63.3,43.8,63.5z M40.5,57.6c-0.1-0.9-0.2-2.2,0.1-3.1c0.2-0.6,0.6-1,1.1-1.3c0,0.8,0.1,1.5,0.1,2.3 c0.2,2.7,0.6,5.4,1,8.1C41.3,62.8,40.6,59.1,40.5,57.6z"
      />
      <g opacity="0.43" fill="#FFF">
        <path
          opacity="0.35"
          d="M42.5,64.9c0-0.3,0-0.6,0-0.9c0.1,0,0.3,0.1,0.4,0.1 c0.1,0,0.1,0,0.2-0.1c0.4,2.6,1.1,5.2,1.9,7.8c0,0.1,0.1,0.2,0.1,0.3c0.1,0.4,0.2,0.9,0.4,1.3c-0.8-0.4-1.5-0.9-2.3-1.4 c0.2-0.3,1-1.1,0.9-1.2c-0.6-1-1.3-2-2-3C42.5,66.8,42.5,65.7,42.5,64.9z"
        />
        <path
          opacity="0.35"
          d="M46.2,69.9c0.3,0.6,0.6,1.2,0.9,1.8c0,0.3-0.1,0.5-0.1,0.8 c0,0.2,0,0.9,0,1.4C47,73.9,46.8,74,46.7,74c-0.2-0.1-0.4-0.2-0.6-0.4c0-0.2-0.1-0.4-0.1-0.7C46.1,72,46.1,71,46.2,69.9 C46.1,69.9,46.2,69.9,46.2,69.9z"
        />
        <path
          opacity="0.35"
          d="M47.2,62.2c0.9,0.6,1.8,1.2,2.7,1.8c-0.1,0,0.6-1.3,0.5-1.4 c-0.9-0.7-1.9-1.4-2.9-2c0-0.2,0-0.3,0-0.5c1.3-0.1,2.7-0.2,3.9-0.5c-0.1,0-0.3-1.2-0.5-1.2c-0.2-0.1-0.4-0.1-0.5,0.1 c-0.9,0.5-1.9,0.8-2.9,0.9c0-0.8-0.1-1.6-0.2-2.5c1.2-0.2,2.4-0.5,3.6-0.6c0.1,0,0.1-1.4,0.1-1.4c-1.3,0.2-2.6,0.7-3.9,1.1 c-0.1-0.3-0.2-0.7-0.2-1c0.2-0.2,0.3-0.4,0.5-0.6c0.3-0.5,0.7-2.1,1.1-2.4c0,0-0.1-0.3-0.3-0.6c0,0,0,0,0,0c0,0,0,0,0,0 c-0.2-0.2-0.3-0.4-0.3-0.5l-0.2,1c-0.3,0.3-0.7,0.6-1,1c-0.2,0.1-0.3,0.2-0.5,0.2c0-0.1-0.1-0.1-0.1-0.2 c0.1-0.1,0.1-0.1,0.1-0.2c0-1.1,0-2.2-0.2-3.4c0.1-0.1,0.4-0.3,0.6-0.5c1.5,1.4,3.1,2.8,4.6,4.3c0.5,3.3,0.7,6.7,0.6,9.5 c0,0.5,0,1.1-0.1,1.7c-0.1,0.8-0.2,1.7-0.4,2.5c-0.1,0.5-0.2,1.1-0.3,1.6c-0.4,1.3-1,2.5-1.8,3.5c-0.6-1.2-1.3-2.4-2-3.5 c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0.2-0.2,0.4-0.4,0.5-0.5c0.5,0.6,0.9,1.2,1.4,1.7c0,0,0,0,0,0c0-2.7-1-4.3-2.5-5.9 C46.9,63,47,62.6,47.2,62.2z"
        />
        <path
          opacity="0.35"
          d="M44.6,47.6c0.1-0.2,0-0.4,0-0.6c0.1,0.1,0.2,0.2,0.3,0.3 c-0.1,0.2-0.2,0.4-0.2,0.5c0,0.8,0.1,1.6,0.1,2.4c-0.2-0.5-0.4-0.9-0.5-1.4c0-0.3,0-0.5,0-0.8C44.4,47.9,44.6,47.8,44.6,47.6z"
        />
        <path
          opacity="0.35"
          d="M44.7,67.4C44.7,67.4,44.7,67.4,44.7,67.4 c0,0.1,0,0.1,0,0.2c0.1,0.3,0.1,0.6,0.2,0.9c0,0.4,0,0.8,0,1.2c-0.2-1-0.4-2.1-0.5-3.1C44.6,66.8,44.7,67.1,44.7,67.4z"
        />
        <path
          opacity="0.35"
          d="M38.4,53.8c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.2,0.4 c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1-0.2-0.2-0.4-0.4-0.6c0,0,0,0.1-0.1,0.2c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-1.2-0.3-2.3-0.3-3.5 c0.9,1.3,1.6,2.8,2.3,4.2c0,0-0.1,0-0.1,0.1c-0.2-0.3-0.5-0.4-0.8-0.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.2-0.1-0.4-0.2-0.6-0.3 c0,0,0,0,0.1,0C37.9,53.5,38.1,53.7,38.4,53.8z"
        />
        <path
          opacity="0.35"
          d="M50.2,42.8c-0.4-0.9-0.8-1.8-1.1-2.7C50,41,50.8,42,51.5,43 c0.2,0.4,0.5,0.9,0.7,1.3C51.6,43.8,50.9,43.3,50.2,42.8z"
        />
        <path
          opacity="0.35"
          d="M53,46.3c1,2.5,1.8,5.4,2.3,8.3c-0.5-0.5-1-1-1.6-1.5 c-0.7-2.8-1.6-5.6-2.7-8.2C51.7,45.3,52.4,45.8,53,46.3z"
        />
        <path
          opacity="0.35"
          d="M55.1,67.7c-0.4,1.3-0.7,3.2-1.3,5.2 c-0.6,1.6-1.4,2.9-2.3,3.8c-0.6-0.3-1.1-0.6-1.7-0.9c0,0,0.1,0,0.1-0.1c0.1,0,0.2,0,0.2,0c1.1-0.2,1.8-1.8,2.2-3.8 c0.5-0.8,0.8-1.7,1.1-2.5c1.4-3.9,1.5-8,1-12c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.3,0.5,0.6,0.7,1c-0.4-1-0.8-1.9-1.2-2.8 c0,0,0-0.1,0-0.1c0.4,0.4,0.8,0.8,1.3,1.1c0.3,3.1,0.2,6.3-0.2,9.2C55.4,66.8,55.2,67.3,55.1,67.7z"
        />
        <path
          opacity="0.35"
          d="M48.6,41.6c-0.9-1.7-1.9-3.2-3-4.6c0,0,0,0,0,0 c1.3,0.9,2.4,1.9,3.5,3.1C49.1,40.3,48.8,41,48.6,41.6C48.6,41.6,48.6,41.6,48.6,41.6z"
        />
        <path
          opacity="0.35"
          d="M36,60.5c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.3-0.1-0.5-0.1-0.8 c-0.1-1-0.2-2-0.3-2.9c0.2,0.2,0.4,0.4,0.5,0.6c0.1,1,0.3,2,0.4,3C36.3,60.3,36.1,60.4,36,60.5z"
        />
        <path
          opacity="0.35"
          d="M37.4,65.3c0.3,1.3,0.7,2.6,1.1,3.8 c-0.2-0.1-0.4-0.3-0.6-0.4c-0.5-1.3-0.9-2.8-1.3-4.4C36.9,64.6,37.1,65,37.4,65.3z"
        />
      </g>
    </g>
  )
}

export default FrontShieldR
