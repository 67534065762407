import React from "react"

const Nose = ({ colors = ["#ED1C24", "#SVGID_", "#FFFFFF"] }) => {
  return (
    <g id="Nose">
      <g id="C_x24_D_x24_noseWide">
        <linearGradient
          id="SVGID_10_"
          gradientUnits="userSpaceOnUse"
          x1="48.56"
          y1="690.413"
          x2="48.56"
          y2="684.096"
          gradientTransform="translate(.38 -638.04)"
        >
          <stop offset="0" stopColor="#ed1c24" stopOpacity="0.5" />
          <stop offset="1" stopColor="#ed1c24" stopOpacity="0" />
        </linearGradient>
        <path
          fill="url(#SVGID_10_)"
          d="M46.6571198,49.0989113l-1.30056,0.9992752l3.0987129,2.3017273l3.3012314-2.1981583 l-1.3993912-1.1007805c0,0-1.0983238-3.0006142-1.8983269-3.0010605 C47.8587914,46.0995789,46.6571198,49.0989113,46.6571198,49.0989113z"
        />
        <path
          opacity="0.3"
          fill="#21376C"
          d="M45.3565598,50.0981865 c0,0,0.9869995-0.0406189,1.386776,0.3596077c0.3997803,0.4002228,0.638401,0.6318207,0.9382896,0.8319893 c0.2998848,0.2001686,1.082283,0.3274231,1.5824509,0.0277023c0.5001106-0.1997185,0.5536499-0.5775108,1.153759-0.7771797 c0.6001091-0.1996651,1.3386688-0.3385506,1.3386688-0.3385506l-2.0639572,2.322937c0,0-0.4803848,0.4288788-1.1803856,0.4284859 c-0.7000008-0.0003891-1.1530533-0.483429-1.1530533-0.483429L45.3565598,50.0981865z"
        />
        <ellipse
          opacity="0.66"
          fill="#FFF"
          cx="48.156"
          cy="50.4"
          rx="0.4"
          ry="0.3"
        />
      </g>
    </g>
  )
}

export default Nose
