import React from "react"

const FlameLeft = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameLeft">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M82.4936905,84.7992935c-1.4316177-2.4728012-1.6268387-4.5551529-0.6507339-6.7025833 c0.3904419-0.8459625,0.9761047-1.691925,1.6268387-2.5378799c0.5205917-0.7158051,1.1062546-1.4316177,1.4966965-2.2125015 c0.0650787-0.1952209,0.1952286-0.3904495,0.3253708-0.6507339l0.3904419,0.0650635 c-0.0650711,0.3253708,0,0.7158127,0.0650711,1.0411835c0.2602997,0.9761047,1.1062546,1.8871307,2.4728012,2.7330933 l-0.0650711,0.0650635l-0.3253708,0.4555206l0.6507339,0.1301422c0.1952209,0.5205917,0.4555206,1.0411835,0.7158127,1.4966965 c0.1301422,0.3253708,0.3253708,0.6507339,0.455513,0.9761047c0.0650787,0.2602921,0.1952209,0.5205917,0.2602997,0.7808838 c-0.3904419,0.9110336-0.8459549,1.7569809-1.3665466,2.6029434c-0.1952286,0.3253708-0.455513,0.6507339-0.6507416,0.9761047 c-0.9761047,0.4555206-2.0823517,0.8459625-3.2536774,1.2364044 C83.9253159,85.2547989,83.2745819,85.1246567,82.4936905,84.7992935z"
        />
        <path
          fill={colors[1]}
          d="M83.2745819,85.0595779c-1.3665466-2.3426437-1.5617676-4.2948532-0.5856628-6.3121414 c0.3904343-0.8459625,0.9761047-1.6268463,1.5617676-2.4728012c0.3253632-0.5205917,0.7158051-0.9761047,1.0411758-1.4966965 c0.3253708,0.8459549,1.0411758,1.6919174,2.2125015,2.4728012l-0.5205841,0.7158127l0.9761047,0.1301422 c0.1952209,0.455513,0.455513,0.9110336,0.6507416,1.3014755c0.1301422,0.2602921,0.3253632,0.5856628,0.455513,0.911026 c0.1301498,0.1952286,0.1952209,0.3904495,0.2602921,0.6507416c-0.3904419,0.9110336-0.7808838,1.6919174-1.3014755,2.4728012 c-0.1301498,0.3253632-0.3253632,0.5856628-0.5205917,0.8459549c-0.8459549,0.3904495-1.8220596,0.7158127-2.7981644,1.0411835 C84.1856155,85.3198776,83.7300949,85.1897202,83.2745819,85.0595779z"
        />
        <path
          fill={colors[2]}
          d="M83.9253159,85.31987c-1.2363968-2.2125092-1.4316254-4.0996475-0.5205917-5.9217072 c0.3904419-0.7808838,0.9761047-1.5617676,1.5617676-2.4077225c0.1301498-0.1952133,0.1952286-0.3253708,0.3253708-0.455513 c0.3904419,0.5205917,0.9110336,0.9761047,1.5617676,1.4316177l-0.6507339,0.9110336l1.3665466,0.1952209 c0.1301498,0.3904419,0.3253632,0.7808838,0.5205917,1.1062546c0.1301422,0.2602921,0.3253632,0.5856552,0.455513,0.911026 c0.0650787,0.1952209,0.1301498,0.3253708,0.1952209,0.5205917c-0.3904419,0.7808838-0.7808838,1.5617752-1.2364044,2.2775803 c-0.1301422,0.1952209-0.3253632,0.455513-0.455513,0.6507339c-0.7158051,0.3253708-1.4966965,0.5856705-2.2775726,0.8459625 C84.5109787,85.3198776,84.1856155,85.2547989,83.9253159,85.31987z"
        />
        <path
          fill={colors[3]}
          d="M84.5760574,85.3849487c-1.0411758-2.0172882-1.2363968-3.7742767-0.3904419-5.4661942 c0.3253708-0.6507416,0.7808914-1.3014755,1.2364044-1.9522095c0.1952133,0.1952209,0.455513,0.3904419,0.7158051,0.5856628 l-0.7808838,1.0411758l1.6919174,0.2602997c0.1301422,0.3253708,0.3253708,0.5856628,0.455513,0.911026 c0.1301498,0.3253708,0.3253708,0.5856705,0.455513,0.9110413c0.0650711,0.1301422,0.0650711,0.2602921,0.1301498,0.3904343 c-0.3904495,0.7808914-0.7158203,1.4316254-1.1713333,2.1474304c-0.1301422,0.1952286-0.1952209,0.3253708-0.3253632,0.5205917 c-0.5856628,0.2602997-1.1713257,0.455513-1.7569885,0.6507416 C84.7712784,85.3849487,84.6411285,85.3198776,84.5760574,85.3849487z"
        />
        <path
          fill={colors[4]}
          d="M84.8363571,80.244133l1.6919098,0.2602921c0.1301498,0.2602844,0.2602997,0.5205841,0.3904419,0.7808762 c0.1301422,0.3253708,0.3253708,0.5856705,0.455513,0.9110413c0.0650711,0.1301422,0.1301422,0.1952209,0.1301422,0.2602921 c-0.3253632,0.6507339-0.7158051,1.3014679-1.1062469,1.9522095c-0.1301498,0.1301422-0.1952209,0.2602997-0.2602921,0.3904419 c-0.3253708,0.1301498-0.7158127,0.2602921-1.0411835,0.3904343 C84.1856155,83.4327393,84.0554581,81.8058929,84.8363571,80.244133z"
        />
        <path
          fill={colors[5]}
          d="M84.9664917,85.3849487c-0.6507339-1.3014755-0.7158051-2.5378723-0.3253632-3.7742767 l0.6507339,0.1301422c0.0650711,0.1952286,0.1952209,0.3904495,0.2602921,0.5856705 c0.1301498,0.3253708,0.3253708,0.5856628,0.4555206,0.911026c0.0650711,0.0650787,0.0650711,0.0650787,0.0650711,0.1301498 c-0.2602997,0.6507339-0.5856628,1.2364044-0.9761047,1.8220596 C85.0315704,85.2547989,85.0315704,85.3198776,84.9664917,85.3849487 C84.9664917,85.3849487,84.9014206,85.4500198,84.9664917,85.3849487z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M79.4352264,79.9838333c0.5205841,0.9761047,1.1062546,1.9522095,1.7569885,2.8632431 c-0.1301498-0.1952209-0.1301498-0.3253708-0.1952209-0.455513c-0.2602997-0.9761047-1.0411835-2.0172882-0.5205917-2.9933929 c-0.0650711-0.0650787-0.1301498-0.1301422-0.1301498-0.1952209c0.0650787-1.6919098,0.5205917-2.7330933,1.3014755-4.3599319 c0.6507339-1.3014755,0.7158127-1.4966888,2.1474304-2.5378723c0.0650787-0.0650787,0.7158127-3.1235352,0.7158127-4.0345612 c0-0.0650787,0.0650711-0.0650787,0.1301422-0.0650787c-0.0650711-0.3253708,0.455513-1.2363968,0.3253708-0.9110336 c-0.3904419,1.1713257,1.3014755,3.8393478,1.3665466,4.0996399c0,0,0.7158051,2.2775879,2.2125015,3.7092056 c1.1713333,1.1713181,1.3665466,2.3426437,1.4966965,3.6441193c0.3904419,0.1301422,0.7808838,0.3253632,0.7808838,0.455513 c0,1.1713257-0.5205841,2.2775803-1.1713181,3.3187561c1.2363968-0.9110336,2.3426437-1.9522095,2.6029434-3.1886139 c-0.1301498-0.0650787-0.2602997-0.1301422-0.2602997-0.1952209c-0.7158127-2.0172806-2.6680222-3.6441193-3.3838272-5.6614075 c-0.0650787-0.1301422,0.3904419,0,0.6507416,0.0650787c-0.4555206-1.4966965-1.7569962-4.8154602-1.8220673-4.8805237 c-0.911026-0.9110336-2.5378723-3.5790558-3.0584564-4.6202316c-0.3253708,0.5205841-1.4966965,3.3187561-1.7569885,3.7742691 c-0.3253708,0.5856628,0.7158127,0.7808838,0.8459549,1.4316177c0,0.2602997-0.7808838,0.1952286-0.911026,0.1301498 c-0.0650787,0-0.6507416,0.4555206-0.7158127,0.4555206c-1.3014755,1.1062546-1.4316177,2.3426437-2.3426514,3.6441193 C79.4352341,75.6238937,79.370163,77.7713318,79.4352264,79.9838333z"
      />
    </g>
  )
}

export default FlameLeft
