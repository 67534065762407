import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WarhammerL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WarhammerL") ? (
    <g id="WarhammerL">
      <path
        id="S_x24_warhammerL_1_"
        d="M53.4152374,108.086647c-2.3869209-1.3190918-3.1534653-3.330925-1.4237823-8.1239166 c2.8828125-7.9883194,10.9714737-24.2879868,10.9714737-24.2879868c-3.8726501-1.9902191-3.9363289-8.2076721-3.9363289-8.2076721 c1.9902115-3.8726463,6.0704765-5.8615685,9.9510078-3.7991066l4.8730392,2.0403175 c3.8726501,1.9902115,4.3553543,6.8229599,2.2928925,10.7034988c0,0-2.4845047,3.0234909-5.642952,2.6967773 c-0.5933075-0.0613708-7.0968895,16.9147568-10.5527191,24.6230011 C57.7222939,108.6957169,55.9086456,109.4645844,53.4152374,108.086647z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WarhammerL, {
  character: "ManGnome"
})
