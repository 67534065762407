import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import BarbaricHair from "./BarbaricHair"
import Short from "./Short"
import MercerCut from "./MercerCut"
import Goatee from "./Goatee"

const CATEGORY = "hair"
const CHARACTER = "ManMixed"
export const IDS = {
  BARBARIC_HAIR: "BarbaricHair",
  SHORT: "Short",
  MERCER_CUT: "MercerCut",
  GOATEE: "Goatee"
}

export const components = {
  [IDS.BARBARIC_HAIR]: BarbaricHair,
  [IDS.SHORT]: Short,
  [IDS.MERCER_CUT]: MercerCut,
  [IDS.GOATEE]: Goatee
}

export const Group = ({ props }) => (
  <g id="hair">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.BARBARIC_HAIR,
    name: "Barbaric Hair",
    defaultColors: ["#7B5300"],
    colorable: true,
    component: components[IDS.BARBARIC_HAIR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SHORT,
    name: "Short",
    defaultColors: ["#642B00"],
    colorable: true,
    component: components[IDS.SHORT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MERCER_CUT,
    name: "Mercer Cut",
    defaultColors: ["#A77D2C"],
    colorable: true,
    component: components[IDS.MERCER_CUT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOATEE,
    name: "Goatee",
    defaultColors: ["#642B00"],
    colorable: true,
    component: components[IDS.GOATEE],
    inUI: true,
    enabled: false,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
