import React from "react"

const MaceR = ({
  colors = ["#595A5B", "#B8B9BA", "#777676", "#9A9B9C", "#E0E1E1", "#E4E3E3"]
}) => {
  return (
    <g id="MaceR">
      <path
        opacity="0.53"
        d="M33.6085815,92.2963638l-0.58144-1.0901871 l-0.0726814-0.2180481l-0.0726776-0.9448318V89.897934l-6.686533-17.0797348 c-0.2180424,0.0726776-0.4360771,0.0726776-0.4360771,0.0726776l-0.3633995-0.2180328l-0.3633995-0.9448395 c-0.1453571-0.1453629-0.7267952-0.7994766-2.2530708-0.9448395l-0.1453571-0.4360733l-0.1453571-0.0726776l0,0h-0.0726776 l0.0726776-0.0726852l0,0v-0.0726776c0.0726814-0.0726776,0.0726814-0.2907181-0.0726776-0.726799 c-0.1453571-0.3633957-0.3633995-0.7267914-0.3633995-0.7267914l0,0l-0.0726757-0.0726852v-0.2907181l1.3082371-3.0525436 l0.2907181-0.2180405c0,0,0.0726814,0,0.0726814-0.0726776l-0.2180424-0.9448395l0.6541195-0.2907219l0.5087452,0.8721504l0,0 l0.4360752-0.07267l3.1252308,1.2355499l0.2180424,0.2907181l0,0l0,0l0,0l0,0c0,0,0.0726776,0.4360809,0.2180424,0.7994766 c0.1453571,0.4360809,0.3633957,0.5814438,0.3633957,0.5814438l0,0v0.07267l0,0h0.1453571l-0.0726757,0.1453629 l0.0726757,0.0726776l0.2180424,0.3634033l-0.0726757-0.0726776l0.0726757,0.1453629 c-1.0175171,1.0901871-1.0175171,1.9623489-1.0175171,2.1803894l0.3633995,0.9448318l-0.145359,0.4360809l0,0 c0,0-0.1453571,0.0726776-0.3633995,0.2180405l6.6865349,17.0797272l0.6541176,0.8721619l0.0726738,0.1453629l0,0v0.0726776 l0.2907219,1.2355499l-0.1453552,0.3634033c-0.0726814,0.0726776-0.5814438,0.3633957-0.8721581,0.5087585 c-0.2907181,0.1453552-0.9448357,0.2180405-1.0175171,0.2180405L33.6085815,92.2963638z"
      />
      <path
        fill={colors[0]}
        d="M28.0122433,66.2043381l-3.0525455-1.3082275c-0.2180424,0.1453629-0.3633995,0.0726776-0.5087585,0 l-0.5814362-0.9448357l0.2180367,1.0901985c-0.0726757,0.2180405-0.1453533,0.2907104-0.4360752,0.3633881l-1.3082371,3.0525513 c0,0,0.9448376,1.5262756,0.4360752,2.0350342c1.8896751,0.1453629,2.5437946,1.0901947,2.5437946,1.0901947l0.3633919,1.0175171 l0.6541176-0.0726776l6.8318977,17.4431381l0.0726776,1.1628799l0.58144,1.0901871l0.9448395-0.2180405l0.8721542-0.5087585 l-0.2907181-1.2355499l-0.726799-0.8721542l-6.8318977-17.4431305l0.5814419-0.4360809l-0.3633995-1.0175247 c0,0-0.1453571-1.0901947,1.0901947-2.5437851C28.3756428,67.9486542,28.0122433,66.2043381,28.0122433,66.2043381z"
      />
      <path
        fill={colors[1]}
        d="M28.0849171,66.1316605l-3.0525475-1.3082352c-0.1453571,0.1453629-0.3633995,0.0726776-0.5087566,0 l-0.5814381-0.9448318l0.2180424,1.0901947c-0.0726814,0.2180405-0.2180386,0.3633957-0.4360809,0.3633957l-1.3082371,3.0525513 c0,0,0.9448338,1.526268,0.4360771,2.0350342c1.8896751,0.1453552,2.5437927,1.0901947,2.5437927,1.0901947l0.3633995,1.0175171 l0.6541195-0.0726776L33.245182,89.897934l0.0726776,1.1628799l0.5814438,1.0901947 c0,0,0.6541176-0.0726852,0.9448357-0.2180328c0.2907181-0.0726852,0.7994766-0.4360886,0.7994766-0.4360886 l-0.2907181-1.1628723l-0.726799-0.8721542l-6.8318977-17.4431381l0.5814419-0.4360809l-0.3633995-1.0175171 c0,0-0.1453571-1.1628723,1.0901947-2.5437851C28.3756428,68.0213394,28.0849247,66.2770233,28.0849171,66.1316605z M25.7591686,71.2192535l-2.0350361-1.380928l-0.5814419-1.5989456l0.726799-2.4711151l0.2180424,2.0350266l0.5814381,1.5989609 l1.3809185,1.7443161L25.7591686,71.2192535z M27.4308014,70.5651245l-0.2907181,0.1453629l-0.1453571-2.1803894 l-0.6541195-1.5989609l-1.2355556-1.5989532l2.1803894,1.3082428l0.6541176,1.5989532L27.4308014,70.5651245z"
      />
      <path
        fill={colors[2]}
        d="M28.3756428,71.5826416c0,0-0.5087566,0.4360733-1.2355518,0.726799 c-0.726799,0.2907181-1.3809185,0.3634033-1.3809185,0.3634033l-0.3633995-0.9448395 c0,0,1.017519-0.2907257,1.3809185-0.4360809s1.3082371-0.5814362,1.3082371-0.5814362L28.3756428,71.5826416z"
      />
      <path
        fill={colors[2]}
        d="M35.2802162,90.2613373c0,0,0,0.2180481-0.8721542,0.5814438 c-0.8721542,0.3634033-1.0901947,0.2180405-1.0901947,0.2180405l-0.1453552-1.1628799 c0,0,0.3633957,0.0726776,0.7267952-0.0726776s0.6541176-0.4360733,0.6541176-0.4360733L35.2802162,90.2613373z"
      />
      <g>
        <path
          fill={colors[3]}
          d="M27.8668823,68.3120499l-0.5087566,2.2530746l-0.29072,0.1453552l-0.1453571-2.0350266 l-2.1077137,0.7994766l1.2355537,1.5989532l-0.29072,0.1453552l-1.8896751-1.3082352l-0.9448376,0.3634033 c0,0.0726852-0.0726795,0.1453629-0.0726795,0.1453629c1.8896751,0.1453552,2.5437927,1.0901947,2.5437927,1.0901947 l0.3633995,1.0175171l0,0l-0.3633995-0.9448395c0,0,1.017519-0.2907181,1.3809147-0.4360733 c0.3633995-0.1453629,1.3082409-0.5814438,1.3082409-0.5814438l0.3633957,0.9448395l0,0l-0.3633957-1.0175171 c0,0-0.1453571-1.0901947,1.0901947-2.5437851c-0.0726814,0-0.1453571,0-0.2180424-0.0726852L27.8668823,68.3120499z"
        />
        <path
          fill={colors[4]}
          d="M23.1426983,68.1667023L23.1426983,68.1667023l0.7267952-2.4711151l0.2180424,2.0350266v0.0726776 l2.1803951-0.8721542l-0.0726814-0.0726776l-1.2355518-1.5989532l2.1803932,1.3082352l0,0l0.7994766-0.2907257 c0-0.0726776,0-0.0726776,0-0.0726776l-3.0525513-1.3082352c-0.2180386,0.1453629-0.3633957,0.0726852-0.5087528,0 l-0.5814419-0.9448318l0.2180424,1.0901947c-0.0726776,0.2180405-0.1453571,0.2907181-0.4360771,0.3633957l-1.3082371,3.0525513 c0,0,0,0,0.0726776,0.0726776L23.1426983,68.1667023z"
        />
        <polygon
          fill={colors[5]}
          points="27.0674057,72.3821182 33.7539368,89.7525711 34.1173363,89.6799011 27.3581257,72.3821182"
        />
      </g>
    </g>
  )
}

export default MaceR
