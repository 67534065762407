import React from "react"

const LuteRight = ({
  colors = ["#94712B", "#D9A53F", "#695020", "#B78C34", "#8F6D2B"]
}) => {
  return (
    <g id="LuteRight">
      <path
        fill={colors[0]}
        d="M22.7,71h0.8l0.1,0.4l0.5,0.1l0.4-0.7v-0.6l-0.7,0.1l-0.2,0.3l-1,0.1C22.6,70.8,22.5,71,22.7,71z"
      />
      <path
        fill={colors[0]}
        d="M22.5,70.1l0.9,0.1l0.1,0.2l0.4-0.2l0.4-0.4l0.1-0.5l-0.5,0.2l-0.4,0.3L22.5,70.1 C22.5,70,22.4,70.1,22.5,70.1z"
      />
      <path
        fill={colors[0]}
        d="M22.5,69.4h0.8l0.1,0.2l0.5-0.1l0.4-0.4l0.1-0.5l-0.5,0.2l-0.4,0.3l-1,0.1C22.4,69.2,22.3,69.4,22.5,69.4z"
      />
      <path
        fill={colors[0]}
        d="M22.9,72.2l0.9,0.1l0.1,0.2h0.5l0.4-0.6l0.1-0.5l-0.5,0.2L24,71.9L23,72C22.8,72,22.7,72.2,22.9,72.2z"
      />
      <path
        fill={colors[1]}
        d="M30.4,85.6c5.3-0.2,15.8,14.4,16.7,17.5c0.9,3.2,1.2,6.8-3.2,9.1s-10.4-2-12.1-4.5 C30,105.2,26.8,86.2,30.4,85.6z"
      />
      <path
        fill={colors[2]}
        d="M20.6,70.9l0.1-1l8.6,17c0,0-3.1,28,14.6,25.4c0,0-11.3,6.6-16.2-8.7c-1.2-7.2,0.4-15.8,0.4-15.8"
      />
      <path
        fill={colors[0]}
        d="M21,73.8l-0.8,0.3v0.2l-0.4,0.2l-0.5-0.3l-0.2-0.4h0.7h0.3l1-0.1C21,73.6,21.2,73.8,21,73.8z"
      />
      <path
        fill={colors[0]}
        d="M20.7,71.6l-0.8,0.3v0.4l-0.4,0.2L19,72l-0.2-0.6h0.7l0.3,0.2H20.7C20.8,71.4,20.9,71.5,20.7,71.6z"
      />
      <path
        fill={colors[0]}
        d="M20.6,70.7L19.8,71v0.3l-0.4-0.1l-0.5-0.3l-0.2-0.4h0.6l0.5,0.2l1-0.1C20.7,70.5,20.8,70.7,20.6,70.7z"
      />
      <path
        fill={colors[0]}
        d="M20.5,70l-0.8,0.2v0.2l-0.5,0.1l-0.5-0.3l-0.2-0.5h0.6l0.5,0.2l1-0.1C20.5,69.8,20.7,69.9,20.5,70z"
      />
      <path
        fill={colors[0]}
        d="M20.8,72.8L20,73.1v0.3l-0.5,0.1L19.1,73l-0.2-0.4h0.6l0.5,0.2l1-0.1C20.8,72.6,21,72.8,20.8,72.8z"
      />
      <polygon
        fill={colors[3]}
        points="32.3,86.1 22.8,68.9 20.4,69.5 29.3,86.9"
      />
      <polygon
        fill={colors[4]}
        points="45.6,104.9 40.2,107.6 39.8,106.4 45,103.8"
      />
      <polygon
        fill={colors[0]}
        points="20.7,70.1 20.6,70.9 21.3,74.3 22.1,74.1"
      />
      <polygon
        fill={colors[2]}
        points="20.9,73.8 21.3,74.3 20.7,69.8 20.4,69.5"
      />
      <ellipse
        transform="rotate(-31.851 36.463 95.407)"
        fill={colors[0]}
        cx="36.5"
        cy="95.4"
        rx="1.8"
        ry="2.6"
      />
      <line fill="none" x1="43.7" y1="105" x2="22.5" y2="69.1" />
      <line fill="none" x1="43.1" y1="105.2" x2="21.9" y2="69.1" />
      <line fill="none" x1="42.6" y1="105.3" x2="21.3" y2="69.3" />
      <line fill="none" x1="42.1" y1="105.6" x2="20.9" y2="69.4" />
    </g>
  )
}

export default LuteRight
