import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WornShieldLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WornShieldLeft") ? (
    <g id="WornShieldLeft">
      <path
        id="S_x24_wornShieldLeft"
        d="M65.9,103.9L65.9,103.9l-5-3.1L56.4,98v-0.1h-0.1c-1.5-18.3,11.2-38,11.8-38.9 c0,0,14.2,0.5,18.9,3s5.8,9.3,5.8,9.3c-0.3,1-8.1,25.4-26.7,32.8L65.9,103.9z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WornShieldLeft, {
  character: "ManDwarf"
})
