import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import RightFingers from "./RightFingers"
import LeftFingers from "./LeftFingers"

const CATEGORY = "fingers"
const CHARACTER = "WomanDwarf"
export const IDS = {
  RIGHT_FINGERS: "RightFingers",
  LEFT_FINGERS: "LeftFingers"
}

export const components = {
  [IDS.RIGHT_FINGERS]: RightFingers,
  [IDS.LEFT_FINGERS]: LeftFingers
}

export const Group = ({ props }) => (
  <g id="fingers">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.RIGHT_FINGERS,
    name: "Right Fingers",
    defaultColors: ["#E8B180", "#SVGID_", "#BE1E2D"],
    colorable: false,
    component: components[IDS.RIGHT_FINGERS],
    inUI: false,
    enabled: true,
    subGroupId: 0
  },
  {
    id: IDS.LEFT_FINGERS,
    name: "Left Fingers",
    defaultColors: ["#E8B180", "#SVGID_", "#BE1E2D"],
    colorable: false,
    component: components[IDS.LEFT_FINGERS],
    inUI: false,
    enabled: true,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
