import React from "react"

const OpenCloak = ({ colors = ["#4A6621", "#353891"] }) => {
  return (
    <g id="OpenCloak">
      <g id="openCloak">
        <path
          fill={colors[0]}
          d="M32.0497246,82.2923508c0,0,14.3785362-13.0425034,15.3955383-14.055481 c-12.029438-0.68293-7.2015724-11.1015244,0-14.4825897c5.7934418,2.1338654,12.6329231,14.4031448,0,14.4825897 c0.3390007,0.3116913,15.1809654,13.8409042,15.1809654,13.8409042s-3.0829811,37.8731079-3.2185783,38.7302399 l4.8278694-0.1072845L63.913662,81.5413437l-7.6977692-16.1599503c0,0,0.4425545-17.3400917-8.7706299-17.9853592 c-9.3741112,0.9671249-9.0522537,18.1462936-9.0522537,18.1462936L30.6215153,81.30439l-0.3956509,39.2890549l4.935154,0.1072845 C35.228817,120.6228104,32.0497246,82.2923508,32.0497246,82.2923508z"
        />
        <g id="cloaks_1_" opacity="0.3">
          <g id="C_x24_openCloak_4_">
            <path
              fill={colors[1]}
              d="M47.4452629,68.2368698c-11.2801933-0.6288147-2.132309-10.2999878,0-10.2999878 S59.2910957,68.1591263,47.4452629,68.2368698c0.3179817,0.288681,6.4031029,51.7611237,6.2762299,52.5553207 c0,0,1.3947182-3.3100433,3.0576515-3.631897c1.6629333-0.3218613,2.627697,3.5712509,2.627697,3.5712509l3.7810669-0.0403366 L62.873436,81.7240372L48.4644814,68.5061035c0,0,16.3611107-1.4483643-1.0192184-16.3079872 c-17.1657562,13.1430473-0.9655724,16.3616295-0.9655724,16.3616295L31.6554108,81.5153885l-0.3582916,39.0875549 l3.9663315,0.0449524c0,0,0.0096054-2.2168655,2.1797714-3.3082047 c2.170166-1.0913315,2.4816132,3.3610382,2.4816132,3.3610382 C39.9883842,120.6285095,46.491951,69.1757507,47.4452629,68.2368698z"
              id="openCloak_1_"
            />
          </g>
        </g>
      </g>
    </g>
  )
}

export default OpenCloak
