import React from "react"
import { withSkinColor } from "../../builderConnector"

const Head = ({ skinColor, colors = ["#21366C", "#DEA043"] }) => {
  return (
    <g id="Head">
      <path
        opacity="0.3"
        fill={colors[0]}
        d="M59.4260635,20.9591789c1.789463,1.3420811,3.4717751,4.0455456,5.6210709,5.0690136 c0,0,1.0960083-1.0736637,2.773613-1.0736637c1.677597,0,3.3075562,1.0736637,3.3075562,1.0736637 c2.149231-1.023468,4.1455078-3.866375,5.934967-5.208456c-1.9361877-2.7719936-1.9994202-3.9643841-8.3517761-3.9643841 S61.3164864,17.1954098,59.4260635,20.9591789z"
      />
      <path
        fill={skinColor}
        d="M65.266983,22.5696754c-2.1492691-1.0234585-3.6683578-3.3999348-5.457798-4.7420158 c-0.982933-1.2668018,0.5815697-4.3841333-2.5275841-8.5445833c0,0,3.0226059-7.0011826,7.9406433-7.1801319l2.8432465-0.6198604 l3.0842743,0.6645958c4.9180374,0.1789496,8.2314301,6.9564552,8.2314301,6.9564552 c-2.6841583,4.6078081-1.592308,7.4567232-2.5752487,8.723525c-1.7894363,1.3420811-3.3085327,3.7185574-5.4577942,4.7420158 c0,0-1.6299286-0.5528278-3.3075333-0.5528278S65.266983,22.5696754,65.266983,22.5696754z"
      />
    </g>
  )
}

export default withSkinColor(Head, { character: "ManDragonborn" })
