import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const DaggerRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("DaggerRight") ? (
    <g id="DaggerRight">
      <path
        id="S_x24_daggerRight"
        d="M26.3311481,97.6497269l-1.2000008-1l-3.8999996-9.4000015 c0,0-6.6000013-23.9000015-6.4000006-25.9000015l5.4000006-5.2999992l6.3999996,0.7999992 c1,0.9000015,5.9999981,5.5999985,7.8000011,12.5l5,22.6999969l-0.4000015,1.5l-4.7000008,5l-1.2999992,0.4000015 L26.3311481,97.6497269z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(DaggerRight, {
  character: "ManHalfling"
})
