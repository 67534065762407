import React from "react"
import { withSkinColor } from "../../builderConnector"

const LeftFingers = ({
  skinColor,
  colors = ["#E8B180", "#SVGID_", "#BE1E2D"]
}) => {
  return (
    <g id="LeftFingers">
      <path
        fill={skinColor}
        d="M91.9000015,91.8000031c0,0-0.6999969-0.6999969-1.3000031-1.6999969 c-0.3000031-0.5-0.5-0.8000031-0.4000015-1.4000015c0.5-0.8000031,1.1999969-0.0999985,1.1999969-0.0999985 s-0.5999985-0.8000031-0.3000031-1.4000015c0.3000031-0.5999985,0.9000015-0.0999985,0.9000015-0.0999985 s-0.1999969-0.5999985,0.0999985-1c0.3000031-0.3000031,0.9000015-0.1999969,0.9000015-0.1999969s0-0.5,0.5-0.5 c0.3000031-0.5999985,3.1999969,2,3.1999969,2l-0.1999969,1.1999969l-1.8000031,2.5999985L91.9000015,91.8000031z"
      />
      <path
        opacity="0.55"
        fill="url(#SVGID_7_)"
        d="M92,90.8000031 c1.1999969-0.6999969,2.6999969-2,3.3000031-3.4000015C95.6000061,87.2000046,96,87.0999985,96.2000046,86.8000031 l0.0999985,0.0999985c0.3000031,1.1999969-1.3000031,3.5-2,3.8000031c-0.5,0.3000031-1.6999969,0.5999985-2.6999969,0.5999985 C91.5000076,91.2000046,91.4000092,91.1000061,91.3000031,91C91.5999985,91,91.8000031,90.9000015,92,90.8000031z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M92.5999985,84.5999985l2.9000015,0.5 c0,0,0.6999969,1.4000015,0,2.8000031C94.8000031,88.2000046,93.5999985,88.5,92.9000015,88.7000046 c-0.3000031,0.0999985-1.4000015,0-1.0999985-1c0-0.0999985,0.4000015-1,1.4000015-1.3000031 C93.4000015,85.4000015,92.5999985,84.5999985,92.5999985,84.5999985z"
      />
      <path
        fill={skinColor}
        d="M91.4000015,82.6999969c0,0,3.5,0.0999985,4.0999985,1.6999969c0.1999969,0.4000015,0,1.6999969,0,2.5 c-0.1999969,0.1999969-2,0.9000015-2.6999969,1.4000015c-0.5,0.0999985-0.6999969-0.1999969-0.6999969-0.1999969 s0.5-1.5,1.4000015-1.6999969S91.4000015,82.6999969,91.4000015,82.6999969z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M91,90.5999985c0,0,0.5999985,0.5,1.3000031,0.8000031 C92.7000046,91.3000031,92.7000046,90.5,92.7000046,90.5s0.8000031,0.4000015,1.0999985,0.0999985s0-1.3000031,0-1.3000031 s0.6999969,0.5999985,1,0.3000031s-0.0999985-0.9000015-0.0999985-0.9000015s0.6999969,0.1999969,0.9000015-0.0999985 c0.1999969-0.3000031-0.0999985-1.4000015-0.0999985-1.4000015L95.6000061,85l1.1999969,2.5l-0.1999969,1.1999969l-1.8000031,2.5 l-2.6999969,0.6999969L91,90.5999985z"
      />
      <linearGradient
        id="SVGID_7_"
        gradientUnits="userSpaceOnUse"
        x1="-2398.751"
        y1="-1213.866"
        x2="-2403.751"
        y2="-1218.491"
        gradientTransform="rotate(180 -1154.147 -564)"
      >
        <stop offset="0" stopColor={colors[2]} />
        <stop offset="0.998" stopColor={colors[2]} stopOpacity="0" />
      </linearGradient>
    </g>
  )
}

export default withSkinColor(LeftFingers, { character: "WomanDwarf" })
