import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const OpenCloak = ({ allEnabledElements }) => {
  return allEnabledElements.includes("OpenCloak") ? (
    <g id="OpenCloak">
      <path
        id="S_x24_openCloak"
        d="M71,81.4824371l-0.6884766-2.9892578l-7.1181641-14.9433594 c-0.8720703-10.2841797-5.4238281-21.6035156-15.7480469-21.6035156c-10.2519531,0-14.9624023,11.3540039-16,21.6621094 l-7.1796875,14.5625l-0.7304688,3.0625l-0.4169922,39.4404907l48.203125-0.031311L71,81.4824371z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(OpenCloak, { character: "ManGnome" })
