import React from "react"

const Spikey = ({ colors = ["#1387BA"] }) => {
  return (
    <g id="Spikey">
      <path
        fill={colors[0]}
        d="M41.0885696,55.9670525l0.5833664,3.8421783c0,0-0.2551231-2.4996796,0.120697-4.1640358 c1.5288277-4.1640358,5.6727448-1.8909149,5.6727448-1.8909149s4.8479881-2.1725426,5.7733269,2.0920753l-0.0402298,3.9628754 c0,0,0.6437149-4.4255447,0.6034813-4.3450813c-0.0402298,0.0804634-0.2816238-0.0603485-0.3218575,0.0402336 c-0.0402298,0.1005783,0.4425545-1.629406,0.4425545-1.629406l-0.7644119,0.6638298 c0.2011604-0.3419724,0.4827881-0.9387512,0.7241821-2.2060661c-0.2816277,0.4425545-1.2472,0.5967789-1.2472,0.5967789 l0.5766602-1.5891762l-2.5882721,0.6437187c0.241394,0.120697,0.670536-1.0460396,1.1130905-1.6092911 c-1.7702179,1.0862694-2.561451-1.6092911-5.2167816-0.9253426c-2.6553268,0.6839485-2.5748634,2.0920753-2.5748634,2.0920753 c-1.0525818-0.3184662-1.0067787-0.6668625-1.3075447-1.005806l-0.1005821,2.0518494 c-0.4412422-0.0391312-0.628994-0.4092484-0.9052238-0.6638336c0,0,0.1408119,2.2328873,0.1408119,2.755909 c0,0.5230179-0.8046455-0.744297-0.8046455-0.744297c0.1807938,1.0631409,0.4343033,1.817482,0.4425545,1.8305664 c0.1094284,0.1735229-0.2800293,0.0000076-0.2816238,0.120697"
      />
    </g>
  )
}

export default Spikey
