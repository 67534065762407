import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LongSwordRight from "./LongSwordRight"
import GoldAxeRight from "./GoldAxeRight"
import LongBowRight from "./LongBowRight"
import MaulR from "./MaulR"

const CATEGORY = "weaponRight"
const CHARACTER = "WomanDwarf"
export const IDS = {
  LONG_SWORD_RIGHT: "LongSwordRight",
  GOLD_AXE_RIGHT: "GoldAxeRight",
  LONG_BOW_RIGHT: "LongBowRight",
  MAUL_R: "MaulR"
}

export const components = {
  [IDS.LONG_SWORD_RIGHT]: LongSwordRight,
  [IDS.GOLD_AXE_RIGHT]: GoldAxeRight,
  [IDS.LONG_BOW_RIGHT]: LongBowRight,
  [IDS.MAUL_R]: MaulR
}

export const Group = ({ props }) => (
  <g id="weaponRight">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LONG_SWORD_RIGHT,
    name: "Long Sword Right",
    defaultColors: [
      "#D0A92B",
      "#A8ABAD",
      "#D5D8DA",
      "#B49330",
      "#FFD144",
      "#EDD692",
      "#7B6528",
      "#A38634",
      "#7951A0"
    ],
    colorable: false,
    component: components[IDS.LONG_SWORD_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_RIGHT,
    name: "Gold Axe Right",
    defaultColors: ["#897441", "#A39155", "#EDCD6E"],
    colorable: false,
    component: components[IDS.GOLD_AXE_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_BOW_RIGHT,
    name: "Long Bow Right",
    defaultColors: [
      "#695E60",
      "#42130B",
      "#FFCD03",
      "#EDD692",
      "#B5AC95",
      "#915B25",
      "#A98A30"
    ],
    colorable: false,
    component: components[IDS.LONG_BOW_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MAUL_R,
    name: "Maul R",
    defaultColors: ["#888787", "#FFFFFF"],
    colorable: true,
    component: components[IDS.MAUL_R],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
