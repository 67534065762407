import React from "react"

const RamHorns = ({ colors = ["#898888", "#262261"] }) => {
  return (
    <g id="RamHorns">
      <path
        fill={colors[0]}
        d="M57.3079338,13.9639759c0.0874023,0.7866392,1.1362572,1.2236614,1.1362572,1.2236614 s1.1362572,0.1748095,2.0102997-0.6118307c0.8740463-0.7866392,1.9228973-4.1954126,1.9228973-4.1954126l1.485878,3.233964 l1.1362534,0.8740444l1.7480927-0.4370222l0.4370193-1.835494L64.212883,7.0590253c0,0-0.5244255-1.3110666-1.835495-1.2236624 c-1.3110657,0.0874043-2.0102997,1.4858751-2.0102997,1.4858751l-1.7480888,6.0309052l-0.8740425-1.5732794 C57.744957,11.7788649,57.2205315,13.1773367,57.3079338,13.9639759z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M59.8426628,14.8380203 c0.0874023,0,0.2622147,0,0.3496208-0.0874052c0.2622108-0.1748085,0.5244255-0.4370222,0.7866402-0.8740435 c0.5244255-0.8740444,0.9614487-2.2725153,1.398468-3.4961777l-1.6606827-2.8843465 c-0.1748123-0.0874043-0.2622147-0.0874043-0.4370232-0.0874043l-1.7480888,5.9435019l0.8740425,1.3110666 C59.580452,14.7506161,59.6678543,14.8380203,59.8426628,14.8380203z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M79.8582764,13.9639759c-0.0874023,0.7866392-1.136261,1.2236614-1.136261,1.2236614 s-1.136261,0.1748095-2.0103073-0.6118307c-0.8740387-0.7866392-1.9228897-4.1954126-1.9228897-4.1954126l-1.485878,3.233964 l-1.136261,0.8740444l-1.748085-0.4370222l-0.4370193-1.835494l2.9717484-5.1568608c0,0,0.5244293-1.3110666,1.835495-1.2236624 S76.799118,7.321238,76.799118,7.321238l1.7480927,6.0309052l0.8740463-1.5732794 C79.421257,11.7788649,79.9456787,13.1773367,79.8582764,13.9639759z"
        />
        <path
          opacity="0.3"
          fill={colors[1]}
          d="M77.3235474,14.8380203 c-0.0874023,0-0.2622147,0-0.349617-0.0874052c-0.2622223-0.1748085-0.5244293-0.4370222-0.786644-0.8740435 c-0.5244293-0.8740444-0.9614487-2.2725153-1.398468-3.4961777l1.6606827-2.8843465 c0.1748047-0.0874043,0.2622147-0.0874043,0.4370193-0.0874043l1.7480927,5.9435019l-0.8740463,1.3110666 C77.4983597,14.7506161,77.4109573,14.8380203,77.3235474,14.8380203z"
        />
      </g>
    </g>
  )
}

export default RamHorns
