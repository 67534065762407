import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BroadSwordL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BroadSwordL") ? (
    <g id="BroadSwordL">
      <path
        id="path_4_"
        d="M91.8,69.8L90,68.5l-2.9-6c0,0,0.1-2.3,0.2-4.7L84.6,54l-0.2-1.9l2.5-3.2L87.7-7l0.1-1.3l0.9-4.3l0.9-2.3 l0.5-0.8l12-0.1c0.5,0.8,3.2,5.1,3.6,9.5c0.1,0.7,0.2,1.7-0.1,17.4c-0.2,12.4-0.5,28.7-0.7,37.2l3.8,4.2l0.1,2.1l-4.2,4.4l0.1,4.4 l-4.4,6.7l-1.5,0.6L91.8,69.8z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BroadSwordL, {
  character: "ManMixed"
})
