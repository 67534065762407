import React from "react"

const Skirt = ({ colors = ["#914E00", "#223549"] }) => {
  return (
    <g id="Skirt">
      <path
        id="skirt"
        fill={colors[0]}
        d="M59.4140244,84.1648788c0,0,3.635994,4.8801804,8.5035324,5.2614441 s9.7350845-4.8801804,9.7350845-4.8801804s-2.8967209-6.8746948-3.8891144-6.6333008 c-0.5632553,0.241394-3.0721436,1.5020065-5.4179459,1.5020065s-4.6136856-1.092659-5.6329002-1.6022644 C61.5861778,77.9735107,59.4140244,84.1648788,59.4140244,84.1648788z"
      />
      <g opacity="0.39" fill={colors[1]}>
        <path d="M62.4635658,79.894722c0,0-1.4661255,0.3812637-2.052578,3.2026138 c-0.5864487,2.8976059,1.1729012,1.4488068,1.1729012,1.4488068s2.052578-3.1263657,1.5247726-4.4226608 S62.4635658,79.894722,62.4635658,79.894722z" />
        <path d="M64.8680191,82.0298004c0,0-1.3488388,0.7625275-1.2901955,3.5838776 s1.4074821,0.9912872,1.4074821,0.9912872s1.2901917-3.5838776,0.5278091-4.5751648 C64.8093719,81.0385132,64.8680191,82.0298004,64.8680191,82.0298004z" />
        <path d="M68.0934906,82.9448318c0,0-1.1728973,1.1437912-0.5278015,3.9651413 c0.6450882,2.8213501,1.6420593,0.5337677,1.6420593,0.5337677s0.5278091-3.9651413-0.4105148-4.7276688 C67.8002701,81.9535446,68.0934906,82.9448318,68.0934906,82.9448318z" />
        <path d="M71.6121979,82.1823044c0,0-0.8210297,1.4488068,0.4105148,3.8126373 c1.2315445,2.4400864,1.6420593,0,1.6420593,0s-0.4105148-3.8126373-1.4661255-4.1939011 C71.0843964,81.3435287,71.6121979,82.1823044,71.6121979,82.1823044z" />
        <path d="M73.7234192,80.4284897c0,0-0.469162,1.7538147,1.3488388,3.6601334 s1.642067-0.5337677,1.642067-0.5337677s-1.3488388-3.6601334-2.5217361-3.7363815 C73.0196762,79.8184738,73.7234192,80.4284897,73.7234192,80.4284897z" />
      </g>
    </g>
  )
}

export default Skirt
