import React from "react"
import { withSkinColor } from "../../builderConnector"

const Arms = ({ skinColor, color = [] }) => {
  return (
    <g id="Arms">
      <g id="rightArm_1_">
        <path
          fill={skinColor}
          d="M32.8080559,58.4586067c2.3999977-0.5,4.5999985,1.2000008,4.5999985,1.2000008L34.0080528,75.258606 c0,0,0.003231,1.9810562-0.3967705,3.6810532c-0.4000015,1.7000046-3.9032326,8.1189499-3.9032326,8.1189499 s-2.1000061,1.9000015-3.8000031,0.9000015s-1.5-2.6999969-1.5-2.6999969s1.4000015-9,3.4000015-9.8000031 c0.3999939-0.1000061,1.572588-5.0609818,1.5386143-8.8300171 C29.2197075,62.8339691,30.3080482,58.8586044,32.8080559,58.4586067z"
        />
        <path
          fill={skinColor}
          d="M67.9814453,66.6285934c-0.0339737,3.7690353,1.1386185,8.730011,1.5386124,8.8300171 c2,0.8000031,3.4000015,9.8000031,3.4000015,9.8000031s0.1999969,1.6999969-1.5,2.6999969 s-3.8000031-0.9000015-3.8000031-0.9000015s-3.5032272-6.4189453-3.9032288-8.1189499 c-0.4000015-1.6999969-0.3967705-3.6810532-0.3967705-3.6810532l-3.4000015-15.5999985 c0,0,2.2000008-1.7000008,4.5999947-1.2000008C67.0200577,58.8586044,68.1084061,62.8339691,67.9814453,66.6285934z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M67.8783493,75.8390732 c0,0-2.8000031-0.5999985-3.1999969-0.1999969S66.9783478,72.8390732,67.8783493,75.8390732z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M29.5094967,75.8390732 c0,0,2.8000011-0.5999985,3.2000027-0.1999969S30.3094978,72.8390732,29.5094967,75.8390732z"
          id="leftArm_2_"
        />
      </g>
    </g>
  )
}

export default withSkinColor(Arms, { character: "ManHalfling" })
