import React from "react"
import { withSkinColor } from "../../builderConnector"

const RightFingers = ({
  skinColor,
  colors = ["#E8B180", "#SVGID_", "#BE1E2D"]
}) => {
  return (
    <g id="RightFingers">
      <path
        fill={skinColor}
        d="M45,91.8000031C45,91.8000031,45.7999992,91,46.4000015,90 c0.2999992-0.5,0.5-0.8000031,0.4000015-1.4000015C46.3000031,87.7999954,45.6000023,88.5,45.6000023,88.5 s0.5999985-0.8000031,0.2999992-1.4000015C45.5999985,86.5,45,87,45,87s0.2000008-0.5999985-0.0999985-1 S44,85.8000031,44,85.8000031s0-0.5-0.5-0.5c-0.2999992-0.5999985-3.2000008,2-3.2000008,2L40.5,88.5l1.7999992,2.5L45,91.8000031 z"
      />
      <path
        opacity="0.55"
        fill="url(#SVGID_6_)"
        d="M45,90.8000031 c-1.2000008-0.6999969-2.7000008-2-3.2999992-3.4000015C41.4000015,87.2000046,41,87.0999985,40.7999992,86.8000031 l-0.0999985,0.0999985c-0.2999992,1.1999969,1.2999992,3.5,2,3.8000031c0.5,0.3000031,1.7000008,0.5999985,2.7000008,0.5999985 C45.5,91.2000046,45.6000023,91.1000061,45.7000008,91C45.4000015,91,45.2000008,90.9000015,45,90.8000031z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M44.4000015,84.5999985L41.5,85 c0,0-0.7000008,1.4000015,0,2.8000031c0.7000008,0.3000031,1.9000015,0.5999985,2.5999985,0.8000031 c0.2999992,0.0999985,1.4000015,0,1.0999985-1c0-0.0999985-0.4000015-1-1.4000015-1.3000031 C43.5999985,85.4000015,44.4000015,84.5999985,44.4000015,84.5999985z"
      />
      <path
        fill={skinColor}
        d="M45.5999985,82.6999969c0,0-3.5,0.0999985-4.0999985,1.6999969c-0.2000008,0.4000015,0,1.6999969,0,2.5 c0.2000008,0.1999969,2,0.9000015,2.7000008,1.4000015c0.5,0.0999985,0.7000008-0.1999969,0.7000008-0.1999969 s-0.5-1.5-1.4000015-1.6999969C42.5999985,86.1999969,45.5999985,82.6999969,45.5999985,82.6999969z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M46,90.5999985c0,0-0.5999985,0.6999969-1.2000008,1 C44.3999977,91.5,44.2999992,90.5,44.2999992,90.5S43.5,90.9000015,43.2000008,90.5999985s0-1.3000031,0-1.3000031 s-0.7000008,0.5999985-1,0.3000031c-0.2999992-0.3000031,0.0999985-0.9000015,0.0999985-0.9000015 s-0.7000008,0.1999969-0.9000015-0.0999985c-0.2000008-0.3000031,0.0999985-1.4000015,0.0999985-1.4000015V85l-1.2000008,2.5 l0.2000008,1.1999969l1.7999992,2.5l2.7000008,0.6999969L46,90.5999985z"
      />
      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1="46.57"
        y1="-1213.84"
        x2="41.57"
        y2="-1218.465"
        gradientTransform="matrix(1 0 0 -1 0 -1128)"
      >
        <stop offset="0" stopColor={colors[2]} />
        <stop offset="0.998" stopColor={colors[2]} stopOpacity="0" />
      </linearGradient>
    </g>
  )
}

export default withSkinColor(RightFingers, { character: "WomanDwarf" })
