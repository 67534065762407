import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaceR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaceR") ? (
    <g id="MaceR">
      <path
        id="S_x24_maceR"
        d="M6.4000001,63.2000008l2.9999995,5.1000023c3,5.1999969,9.8000011,7,15,4L26.6000004,71 c5.1999989-3,6.9999981-9.7999992,4-15l-3-5.0999985c-3-5.2000008-9.8000011-7-15-4l-2.1999998,1.2999992 C5.1999998,51.2999992,3.4000001,58,6.4000001,63.2000008z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaceR, { character: "ManHalfling" })
