import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaulR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaulR") ? (
    <g id="MaulR">
      <path d="M100.3427277,89.0926132c0.2177811-1.151329,0.2834473-2.1989288,0.1174545-3.4228516 c-0.2593842-2.8316193-1.6147766-5.4730606-3.6512527-7.3158035c-0.6707611-0.6568985-1.3899155-1.05793-2.2369995-1.4831772 l-0.4875031-0.2247162l0,0c-0.4875107-0.2247162-0.9750137-0.44944-1.486702-0.5462265 c0,0-1.2792587-0.2419891-1.6630402-0.3145828l-0.1279221-0.0241928l-0.2558441-0.0484009l-0.663826,0.0069351 c-1.4018478-0.1460571-33.2069664,0.7377472-43.6108971-0.354744c-0.9693069-0.1017838-1.8101082-0.7155914-2.1657295-1.6230392 c-2.8017807-7.1494064-24.85495-10.094574-26.9093494,4.0525665c-0.01721,0.1185074-0.7475395,9.496109,1.0650158,15.4122467 c3.6199131,11.8153229,5.8507519,12.8594589,8.0792904,12.9008255c0.2075233,0.0038528,10.8374195-0.1902313,11.0399189-0.2357864 c0.7941628-0.1786652,2.8802834-0.6625977,3.7455902-0.8635864c0.2573242-0.0597687,0.503643-0.1524277,0.7315063-0.2861023 c2.0408173-1.1971817,3.7104225-2.9256287,5.0343361-6.9138565c0.3854942-1.1612854,1.5387802-1.8884201,2.7586823-1.7934113 c12.9372292,1.007576,36.4174652,2.0245895,38.8838882,2.2404099c2.0709991,0.2592545,4.2871933-0.249054,6.2164078-1.3416595 C97.7256317,95.222702,99.7135696,92.4186707,100.3427277,89.0926132z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaulR, { character: "WomanDwarf" })
