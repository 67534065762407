import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaceR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaceR") ? (
    <g id="MaceR">
      <path
        id="S_x24_maceR"
        d="M38.6425209,71.6913528l2.0948143,3.5611801 c2.0948105,3.6310043,8.6534996,22.6450577,12.2845078,20.5502472l7.3222694-3.2990494 c3.6310043-2.0948105-3.5377007-18.6576462-5.632515-22.2886581c0,0-2.0116119-15.0468597-11.7397995-9.9055405 l-1.5361938,0.907753C37.8045959,63.3819237,36.5477066,68.0603409,38.6425209,71.6913528z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaceR, { character: "WomanGnome" })
