import React from "react"

const HalfCircle = ({ colors = [] }) => {
  return (
    <g id="HalfCircle">
      <path
        id="halfCircle"
        d="M100.2238617,117.8000031c0,17.6000061-14.3000031,31.8999939-31.9000015,31.8999939 s-31.8999977-14.3000031-31.8999977-31.9000015C36.4238625,117.8000031,100.2238617,117.8000031,100.2238617,117.8000031z"
      />
    </g>
  )
}

export default HalfCircle
