import React from "react"

const PlateArmor = ({ colors = ["#737373", "#9E9E9E", "#595A5A"] }) => {
  return (
    <g id="PlateArmor">
      <path
        fill={colors[0]}
        d="M62.9,53.8l-5.6-0.6c0,0-5.9,1.7-8.8,1.7s-9.2-1.7-9.2-1.7L32.8,54c0,0-2.6,4.4-2.1,8.9s3.1,8.8,3.1,8.8 l-0.3,11.2h29.6l-0.2-11.2c0,0,2.7-4.4,3.1-8.8C66.5,58.4,62.9,53.8,62.9,53.8z"
      />
      <path
        fill={colors[1]}
        d="M37.1,80.1c0,0-3.3-17.6,11.2-17.6s12.2,17.1,12.2,17.1L37.1,80.1z"
      />
      <path
        fill={colors[0]}
        d="M49,58.4L49,58.4h-0.4h-0.4l0,0l-16,0.4l1.2,4.9c0,0,0,0.9,9,2.9c2.8-0.2,4.7,0,6.2-0.5 c1.6,0.5,3.4,0.4,6.2,0.5c9-2,9-2.9,9-2.9l1.2-4.9L49,58.4z"
      />
      <path
        fill={colors[1]}
        d="M48.8,55.6L48.8,55.6h-0.4H48l0,0l-13.3,1.9l0.4,5.6c0,0,0.7,0.2,7,2.2c2.8-0.3,4.7,0,6.2-0.6 c1.6,0.6,3.4,0.4,6.2,0.6c5.1-1.5,6.3-2,6.3-2l1-5.1L48.8,55.6z"
      />
      <polygon
        opacity="0.62"
        points="64.5,91.6 64.5,94.6 61.2,90.9 58.9,95.1 57,92.4 54.9,95.6 52.2,92.4 51.8,95.7 48.6,99 45.7,95.9 45.4,92.1 43.1,95.6 40,91.9 38.2,95.1 35.6,91.3 32.8,94.5 32.7,94.2 32.9,95.4 35.5,92.2 38.3,96.1 39.4,93.3 42.7,97.3 45.2,93.5 45.6,97.6 48.6,101 52.2,97.4 52.6,93.8 55.5,97.3 57.8,93.8 59.9,96.8 61.5,92.4 64.7,95.9"
      />
      <polygon fill={colors[0]} points="64.6,90 64.4,88.3 64.5,91.6" />
      <path
        fill={colors[0]}
        d="M35.3,90.8v-3.4v-7c-0.9-0.4-1.5-0.6-1.8-0.8l-1.3,10.6l0.6,3.9l0,0.3l2.9-3.2L35.3,90.8z"
      />
      <path
        fill={colors[0]}
        d="M64.4,88.3l-0.1-1.1l-0.8-7.5c-0.3,0.2-0.9,0.4-1.6,0.8l-0.4,6.7l-0.2,3.4l-0.2,0.3l3.3,3.7l0.1-3 L64.4,88.3z"
      />
      <path
        fill={colors[1]}
        d="M39.5,91.3v-3.9V82c-0.6-0.2-1.2-0.4-1.7-0.6c-0.9-0.3-1.8-0.7-2.5-0.9v7v3.4l0.4,0.5l2.5,3.8l1.8-3.2 L39.5,91.3z"
      />
      <path
        fill={colors[1]}
        d="M59.7,81.5c-0.4,0.2-0.9,0.3-1.3,0.5l-0.3,5.3l-0.2,3.8L57,92.4l1.9,2.7l2.3-4.2l0.2-0.3l0.2-3.4 l0.4-6.7C61.3,80.8,60.5,81.1,59.7,81.5z"
      />
      <path
        fill={colors[0]}
        d="M55.9,82.8c-0.9,0.3-1.7,0.5-2.6,0.7l-0.5,3.8l-0.6,5.1l2.7,3.2l2.1-3.2l0.9-1.3l0.2-3.8l0.3-5.3 C57.6,82.3,56.7,82.6,55.9,82.8z"
      />
      <path
        fill={colors[0]}
        d="M45,87.3l-0.3-3.9c-1.2-0.3-2.4-0.6-3.6-0.9c-0.6-0.2-1.1-0.3-1.7-0.5v5.4v3.9l0.5,0.6l3.1,3.7l2.3-3.5 L45,87.3z"
      />
      <path
        fill={colors[1]}
        d="M51.3,83.9c-0.7,0.1-1.4,0.1-2,0.1c-0.9,0-1.8-0.1-2.8-0.2c-0.6-0.1-1.2-0.2-1.8-0.3l0.3,3.9l0.4,4.8 l0.3,3.8l2.9,3.1l3.2-3.3l0.4-3.3l0.6-5.1l0.5-3.8C52.6,83.7,51.9,83.8,51.3,83.9z"
      />
      <path
        fill={colors[2]}
        d="M63.1,76.4c0,0-7.7,2.5-13.9,2.5s-15.7-2.2-15.7-2.2l-0.2,2.9c0,0,0.1,0,0.2,0.1c0.3,0.1,0.9,0.4,1.8,0.8 c0.7,0.3,1.5,0.6,2.5,0.9c0.5,0.2,1.1,0.4,1.7,0.6c0.5,0.2,1.1,0.4,1.7,0.5c1.2,0.4,2.4,0.7,3.6,0.9c0.6,0.1,1.2,0.2,1.8,0.3 c1,0.1,1.9,0.2,2.8,0.2c0.6,0,1.3-0.1,2-0.1c0.7-0.1,1.3-0.2,2-0.3c0.9-0.2,1.8-0.4,2.6-0.7c0.9-0.3,1.7-0.5,2.5-0.8 c0.5-0.2,0.9-0.3,1.3-0.5c0.8-0.3,1.6-0.7,2.2-1c0.7-0.3,1.3-0.6,1.6-0.8c0.2-0.1,0.3-0.1,0.3-0.1L63.1,76.4z"
      />
    </g>
  )
}

export default PlateArmor
