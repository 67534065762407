import React from "react"

const HalfTank = ({ colors = ["#CBC8A9", "#262261", "#FFFFFF"] }) => {
  return (
    <g id="HalfTank">
      <g id="boobs_1_">
        <path
          id="halfTank"
          fill={colors[0]}
          d="M76.5946121,60.3311806c-0.3000107,0.1460457-0.6256866,0.2374992-0.6256866,0.149189 v-0.2256317c-0.2147751,0.1762047-0.4868851,0.3539429-0.6753235,0.3539429 c-0.0525055,0,0.3248672-0.3874512,0.6753235-0.5852966v-0.2261887c0-0.1378746,0.2609558-0.3117752,0.5302734-0.4373627 c0.0153427-2.4496727,1.4753036-2.9444199,1.4753036-2.9444199s-3.258812-0.9253426-4.7071762-1.1667328 c0.0346985,0.0619202,0.063652,0.1283836,0.0719376,0.2072678c0.1879501,1.7829056-1.7919846,1.2946243-3.4207993,1.56633 c-0.3542328,0.0590591-0.6145325-0.1028328-0.7452927-0.317852c-4.5250854,2.4662857-10.0835915,7.592617-11.3952599,6.8320961 c0.0664444,0.5169296,0.872963,2.0027542,1.005806,2.8162537c0.1794701,1.0990219,0.6151199,4.6195602,0.8162804,5.9472275 c-0.9369774,0.6106262-1.9427834,2.4613113-2.9083557,5.7603531l11.8282776,0.9253464l11.9087448-0.9253464 c-0.5860901-1.0901718-0.7241821-4.2243805-2.6955566-5.7129745c0-1.7299881,1.0585251-4.8666229,1.0862656-6.2360001 c0.2011642-0.3620911,0.5211792-2.266098,0.5806274-2.4113808C78.2626572,63.2321281,76.804451,61.2423553,76.5946121,60.3311806 z M75.7899246,58.6055908c0.0704651,0-0.6334839,0.6988792-1.0071564,0.6988792 C74.7123032,59.3044701,75.4162445,58.6055908,75.7899246,58.6055908z"
        />
        <path
          opacity="0.1"
          fill={colors[1]}
          d="M78.775383,63.5031815 c-0.0347214,0.1667671-0.0556183,0.3336411-0.1145325,0.4998245c-0.8119736,2.2911224-7.4334946,3.873764-9.6295319,0.1644516 c-0.0066986-0.2147446-0.2918777-0.2146912-0.2985611,0.0000534c-1.8335037,3.4259415-8.8175659,2.1266174-9.6296043-0.164505 c-0.0588455-0.1661835-0.0797424-0.3330574-0.1145287-0.4998245c-0.1400337,0.6538544-0.126461,1.3401642,0.1145287,2.0201874 c0.8120384,2.2911224,3.6529236,8.8698196,6.3452492,8.1787338c1.7796555-0.4567871,5.0876083-0.4567871,6.8672562,0 c2.6923294,0.6910858,5.5332184-5.8876114,6.345192-8.1787338C78.9019012,64.8433456,78.9154816,64.1570358,78.775383,63.5031815 z"
        />
        <path
          opacity="0.05"
          fill={colors[1]}
          d="M78.775383,64.3882904 c-0.0347214,0.1667709-0.0556183,0.3336411-0.1145325,0.4998245c-0.8119736,2.2911224-7.4335098,3.8737564-9.6295319,0.1644516 c-0.0066986-0.2147446-0.2918777-0.2146912-0.2985611,0.000061c-1.8335037,3.4259415-8.8175659,2.1266098-9.6296043-0.1645126 c-0.0588455-0.1661835-0.0797424-0.3330536-0.1145287-0.4998245c-0.1400337,0.6538544-0.126461,1.3401642,0.1145287,2.0201874 c0.5153084,1.4538498,2.2107582,7.170723,3.7445335,7.9507675s10.2487259,0.4908676,12.0192566-0.0639877 c1.7705383-0.5548553,3.2954636-6.4803772,3.7939072-7.8867798 C78.9019012,65.7284546,78.9154816,65.0421448,78.775383,64.3882904z"
        />
        <path
          opacity="0.15"
          fill="#FFF"
          d="M60.6568069,62.1392822 c0.104332-0.1452789,4.2864761-0.5800133,6.991539,1.4453278c2.7050629,2.0253448-2.1640549,3.1586227-2.1640549,3.1586227 C62.025383,66.884346,57.9359093,65.9282608,60.6568069,62.1392822z"
        />
        <path
          opacity="0.1"
          fill={colors[1]}
          d="M78.775383,64.3882904 c-0.0347214,0.1667709-0.0556183,0.3336411-0.1145325,0.4998245c-0.8119736,2.2911224-7.4334946,3.873764-9.6295319,0.1644516 c-0.0066986-0.2147446-0.2918777-0.2146912-0.2985611,0.000061c-1.8335037,3.4259338-8.8175659,2.1266098-9.6296043-0.1645126 c-0.0588455-0.1661835-0.0797424-0.3330536-0.1145287-0.4998245c-0.1400337,0.6538544-0.126461,1.3401642,0.1145287,2.0201874 c0.8120384,2.2911301,3.6529236,3.5882568,6.3452492,2.8971634c1.7796555-0.4567871,5.0876083-0.4567871,6.8672562,0 c2.6923294,0.6910934,5.5332184-0.6060333,6.345192-2.8971634C78.9019012,65.7284546,78.9154816,65.0421448,78.775383,64.3882904 z"
        />
        <path
          opacity="0.15"
          fill="#FFF"
          d="M77.234314,62.1392822 c-0.1043243-0.1452789-4.2864685-0.5800133-6.9915314,1.4453278c-2.7050705,2.0253448,2.1640472,3.1586227,2.1640472,3.1586227 C75.8657455,66.884346,79.9552078,65.9282608,77.234314,62.1392822z"
        />
      </g>
    </g>
  )
}

export default HalfTank
