import React from "react"

const StrapBoots = ({
  colors = [
    "#A87F54",
    "#754C29",
    "#9C5D26",
    "#262262",
    "#3A3019",
    "#5C3C20",
    "#644223"
  ]
}) => {
  return (
    <g id="StrapBoots">
      <path
        fill={colors[0]}
        d="M44.3,106.3c0,0-5.5,2.6-10.3,0.8c-0.7,3.3,1,6,0.9,6c0,0-0.7,0.2-1.8,1.6c-1.2,1.2-1.5,2.8-1.5,3.6 c0.8,0,1,0.5,6.6,0.7s5.6-0.8,5.6-0.8l0.1-3.9l-0.3-1.2L44.3,106.3z"
      />
      <path
        fill={colors[1]}
        d="M34,107.1c0,0,4.4,3.7,9.7,3.7c0.4,0.5,0.2,2.6-0.3,2.5c-4.3-0.9-7.8-2.4-9.6-4.4 C33.6,107.4,34,107.1,34,107.1z"
      />
      <path
        fill={colors[1]}
        d="M44.2,107.1c0,0-4.1,2.9-9.9,4.6c-0.3,0.8,0.6,1.4,0.6,1.4c2.9-0.4,5.4-0.1,9-3.4 C44.6,108.3,44.2,107.1,44.2,107.1z"
      />
      <path
        fill={colors[2]}
        d="M43.9,116c0,0-4.9-4.1-9.3-3c-0.8,0.5-1,0.8-0.9,1c0,0,8.6-0.9,10.1,4.4C44.2,117,43.9,116,43.9,116z"
      />
      <path
        fill={colors[2]}
        d="M43.5,113.1c-3.3-1-8.1-0.5-11.5,3.3c-0.2,1.4-0.4,1-0.4,1.9c3.7-5.8,12.3-3.7,12.3-3.7L43.5,113.1z"
      />
      <path
        opacity="0.42"
        fill={colors[3]}
        d="M43.4,113.1c0,0-1.1-0.8-4.6-0.6s-4.3,0.5-4.3,0.5 s-0.8-4.7-0.8-8.9c2.7,1.9,10.2-0.2,10.2-0.2s0.4,2.3,0,4.6S43.5,113.1,43.4,113.1z"
      />
      <path
        fill={colors[4]}
        d="M31.1,119.5h13v-1.3c0,0-1.2,0.5-5.3,0.5s-7.8-0.5-7.8-0.5L31.1,119.5z"
      />
      <polygon
        opacity="0.42"
        fill={colors[3]}
        points="40.1,116.9 40.2,118.5 39.7,118.6"
      />
      <polygon
        opacity="0.42"
        fill={colors[3]}
        points="41.5,118.5 40.2,118.4 40.4,115.8 39.2,118.4 38.1,118.6 39.6,118.7"
      />
      <path
        fill={colors[5]}
        d="M44.4,105.3l-0.1,1.5c0,0-3.9,3.4-10.5,0.2c0.1-1,1-2.2,1-2.2S36.6,106,44.4,105.3z"
      />
      <path
        fill={colors[6]}
        d="M44,103.9v1.7c0,0-2.7,1-6.6,1c-2.6,0-3.5-0.8-3.5-0.8l-0.2-1.8C33.8,104.1,37,106,44,103.9z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M52.8,106.3c0,0,5.5,2.6,10.3,0.8c0.7,3.3-1,6-0.9,6c0,0,0.7,0.2,1.8,1.6c1.2,1.2,1.5,2.8,1.5,3.6 c-0.8,0-1,0.5-6.6,0.7s-5.6-0.8-5.6-0.8l-0.1-3.9l0.3-1.2L52.8,106.3z"
        />
        <path
          fill={colors[1]}
          d="M63,107.1c0,0-4.4,3.7-9.7,3.7c-0.4,0.5-0.2,2.6,0.3,2.5c4.3-0.9,7.8-2.4,9.6-4.4 C63.4,107.4,63,107.1,63,107.1z"
        />
        <path
          fill={colors[1]}
          d="M52.9,107.1c0,0,4.1,2.9,9.9,4.6c0.3,0.8-0.6,1.4-0.6,1.4c-2.9-0.4-5.4-0.1-9-3.4 C52.5,108.3,52.9,107.1,52.9,107.1z"
        />
        <path
          fill={colors[2]}
          d="M53.2,116c0,0,4.9-4.1,9.3-3c0.8,0.5,1,0.8,0.9,1c0,0-8.6-0.9-10.1,4.4C52.8,117,53.2,116,53.2,116z"
        />
        <path
          fill={colors[2]}
          d="M53.5,113.1c3.3-1,8.1-0.5,11.5,3.3c0.2,1.4,0.4,1,0.4,1.9c-3.7-5.8-12.3-3.7-12.3-3.7L53.5,113.1z"
        />
        <path
          opacity="0.42"
          fill={colors[3]}
          d="M53.6,113.1c0,0,1.1-0.8,4.6-0.6c3.5,0.1,4.3,0.5,4.3,0.5 s0.8-4.7,0.8-8.9c-2.7,1.9-10.2-0.2-10.2-0.2s-0.4,2.3,0,4.6C53.4,110.8,53.6,113.1,53.6,113.1z"
        />
        <path
          fill={colors[4]}
          d="M65.9,119.5h-13v-1.3c0,0,1.2,0.5,5.3,0.5s7.8-0.5,7.8-0.5L65.9,119.5z"
        />
        <polygon
          opacity="0.42"
          fill={colors[3]}
          points="57,116.9 56.9,118.5 57.3,118.6"
        />
        <polygon
          opacity="0.42"
          fill={colors[3]}
          points="55.6,118.5 56.9,118.4 56.6,115.8 57.8,118.4 59,118.6 57.4,118.7"
        />
        <path
          fill={colors[5]}
          d="M52.7,105.3l0.1,1.5c0,0,3.9,3.4,10.5,0.2c-0.1-1-1-2.2-1-2.2S60.4,106,52.7,105.3z"
        />
        <path
          fill={colors[6]}
          d="M53,103.9v1.7c0,0,2.7,1,6.6,1c2.6,0,3.5-0.8,3.5-0.8l0.2-1.8C63.3,104.1,60,106,53,103.9z"
        />
      </g>
    </g>
  )
}

export default StrapBoots
