import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const TatteredCape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("TatteredCape") ? (
    <g id="TatteredCape">
      <path
        id="tatteredCape"
        d="M104.6669846,103.5988541 c3.0029755-34.2562637-16.7944641-83.6386642-38.037796-81.4142303S42.494091,45.5411682,30.7045994,65.7835007 c-4.3376522,7.3406372-12.0119438,20.576004-18.796463,27.5829697c-3.8927574,4.0039749-5.4498596,7.4518433-2.4468765,13.3465958 C10.7959194,109.4936066,97.4375763,138.9673462,104.6669846,103.5988541z M29.3699303,99.4836578 M30.7045918,97.8153381 M31.0382557,87.8053894 M33.1514664,101.5968704 M33.2626877,86.80439 M32.5953598,86.1370621 M35.1534576,92.0318146 c-0.4448891,0,0.5561066-0.8897781,0.8897705-0.8897781C36.4881172,91.1420364,35.4871216,92.0318146,35.1534576,92.0318146z M88.8735046,99.8173294c-0.7785492,0,0.7785492-1.4458847,1.3346558-1.4458847 C91.0979385,98.2602234,89.5408325,99.8173294,88.8735046,99.8173294z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(TatteredCape, {
  character: "ManDragonborn"
})
