import React from "react"

const LongSwordLeft = ({
  colors = [
    "#D0A92B",
    "#A8ABAD",
    "#D5D8DA",
    "#B49330",
    "#FFD144",
    "#EDD692",
    "#7B6528",
    "#A38634",
    "#7951A0"
  ]
}) => {
  return (
    <g id="LongSwordLeft">
      <rect
        x="88.995"
        y="86.348"
        transform="rotate(-50.482 92.845 87.348)"
        fill={colors[0]}
        width="7.7"
        height="2"
      />
      <path
        fill={colors[1]}
        d="M94.9000015,82.5999985l2.4000015,2L124.6000061,51.5c0,0,5.1000061-6.2999992,4.5-7.9000015 C127.5000076,43.3999977,122.2000046,49.5,122.2000046,49.5L94.9000015,82.5999985z"
      />
      <path
        fill={colors[2]}
        d="M129,43.5999985C129.6000061,45.2999992,124.5,51.5,124.5,51.5L97.3000031,84.5999985l-1.1999969-1 L129,43.5999985z"
      />
      <path
        fill={colors[0]}
        d="M100.1999969,84.8000031c0,0,0.0999985-0.9000015-3.0999985-2.5C95,79.5,94,79.5999985,94,79.5999985 s-3.8000031,1.3000031,1,5.1999969C99.9000015,88.8000031,100.1999969,84.8000031,100.1999969,84.8000031z"
      />
      <path
        fill={colors[3]}
        d="M94.8000031,79.3000031c0,0-1.9000015,0.5999985,0.3000031,2.9000015 c0.3000031,0.3000031-0.5,2.1999969,0,2.5999985s2.1999969-0.6999969,2.5999985-0.5 c2.6999969,1.6999969,2.5999985-0.5,2.5999985-0.5S101.2000046,87.8000031,98.5,87 c0-0.4000015-0.1999969-0.9000015-0.5999985-1.3000031c-0.5999985-0.5-2.3000031,0.5999985-2.8000031,1 C94.2999954,87,93.5,87.0999985,93.1999969,86.7999954c-0.5-0.4000015-0.4000015-1.1999969-0.3000031-2 c0.3000031-0.5,0.9000015-2.5,0.3000031-3C92.7999954,81.4999924,92.3999939,81.3999939,92,81.3999939 C91.0999985,79.1999969,94.8000031,79.3000031,94.8000031,79.3000031z"
      />
      <path
        fill={colors[4]}
        d="M93.4000015,86.8000031C93.5999985,87,94.3000031,86.9000015,95.0999985,86.6000061 c0.5-0.4000015,2.3000031-1.5999985,3-1c0.4000015,0.3000031,0.6999969,0.8000031,0.6999969,1.3000031 c0.5,0.0999985,0.8000031,0,1.0999985-0.1999969c0.4000015-0.4000015,0.5-1.1999969,0.4000015-1.9000015 c-0.0999985,0.0999985-0.1999969,0.1999969-0.3000031,0.3000031c-0.5,0.3000031-1.3000031,0.0999985-2.4000015-0.5 c-0.0999985,0-0.5999985,0.0999985-0.9000015,0.3000031c-0.6999969,0.3000031-1.3000031,0.5-1.6999969,0.1999969 c-0.4000015-0.3000031-0.1999969-1-0.0999985-1.6999969c0.0999985-0.3000031,0.1999969-0.8000031,0.0999985-0.9000015 c-0.9000015-1-1.3000031-1.8000031-1.0999985-2.4000015c0-0.0999985,0.0999985-0.3000031,0.1999969-0.4000015 c-0.5999985,0.0999985-1.4000015,0.3000031-1.6999969,0.8000031c-0.1999969,0.3000031-0.1999969,0.5999985-0.0999985,1 c0.4000015,0,0.8000031,0.0999985,1.1999969,0.4000015c0.6999969,0.5999985,0.0999985,2.6999969-0.1999969,3.3000031v0.0999985 C93.0999985,85.9000015,93,86.5,93.4000015,86.8000031z"
      />
      <path
        fill={colors[3]}
        d="M89.3000031,90.5l1.0999985,0.9000015c0.3000031,0.3000031,0.9000015,0.1999969,1.0999985-0.0999985 l0.3000031-0.4000015C92.1000061,90.5999985,92,90,91.7000046,89.8000031l-1.0999985-0.9000015 C90.3000031,88.5999985,89.7000046,88.7000046,89.5000076,89l-0.3000031,0.4000015 C88.9000015,89.6999969,88.9000015,90.1999969,89.3000031,90.5z"
      />
      <path
        fill={colors[5]}
        d="M91.9000015,91.0999985C92.2000046,90.7999954,92.0999985,90.1999969,91.8000031,90l-1.0999985-1 c-0.3000031-0.3000031-0.9000015-0.1999969-1.0999985,0.0999985 C90.4000015,89.3000031,91.5999985,90.1999969,91.9000015,91.0999985z"
      />
      <ellipse
        transform="rotate(-50.482 96.424 82.972)"
        fill={colors[6]}
        cx="96.424"
        cy="82.972"
        rx="1"
        ry="1.4"
      />
      <ellipse
        transform="rotate(-50.482 96.424 82.972)"
        fill={colors[7]}
        cx="96.424"
        cy="82.972"
        rx="1.2"
        ry="0.8"
      />
      <ellipse
        transform="rotate(-50.482 96.424 82.972)"
        fill={colors[8]}
        cx="96.424"
        cy="82.972"
        rx="1"
        ry="0.6"
      />
    </g>
  )
}

export default LongSwordLeft
