import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const PoleArmL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("PoleArmL") ? (
    <g id="PoleArmL">
      <path d="M113.5,19c0-0.1000004,0-0.1000004,0-0.2000008 c0.1999969-2.2000008-0.3000031-4.3000002-0.5999985-6.3999996L113.5,12.2999992l-6-27.1000004l-14,1.5v48.7000046 c0,0.7999992,0.0999985,1.5999985,0.4000015,2.4000015l0.5,78.8000031L95,119.4000092l0.6999969,1.6999969l13.1999969-0.5 l0.5999985-1.6999969l0.4000015-2.4000015V36l0.4000015-0.5v0.2000008V35.5h-0.0999985l0.0999985-0.2000008 c0-1.9000015,0.1999969-2.7999992,0.5-4c0.0999985-0.3999996,0.1999969-0.8999996,0.3000031-1.2999992 C111.5,28.3999996,112,26.3999996,113.2999954,24l0,0l0.0999985-0.2000008l0,0l0,0l-0.4000015-0.2000008 c0.1999969-0.6000004,0.4000015-1.2999992,0.5-2C113.6999969,20.6000004,113.5999985,19.7999992,113.5,19z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(PoleArmL, {
  character: "ManDragonborn"
})
