import React from "react"

const LongswordRight = ({
  colors = [
    "#D0AA2B",
    "#A9ABAE",
    "#D6D8DB",
    "#B49330",
    "#SVGID_",
    "#EED793",
    "#7B6529",
    "#A38634",
    "#7951A1",
    "#FFFFFF",
    "#F6F6F6",
    "#E4E5E6",
    "#C8CACC",
    "#A2A4A7",
    "#757779",
    "#2D2D2E",
    "#000000"
  ]
}) => {
  return (
    <g id="LongswordRight">
      <rect
        x="25.7"
        y="72.7"
        transform="rotate(-5.486 26.78 79.039) scale(.99998)"
        fill={colors[0]}
        width="2.2"
        height="12.7"
      />
      <path
        fill={colors[1]}
        d="M27.7,71.5l-3.4,0.3l-5-49.9c0,0-0.8-8.8,0.8-9.9c1.6,0.8,2.6,9.6,2.6,9.6L27.7,71.5z"
      />
      <path
        fill={colors[2]}
        d="M20.1,12c-1.6,1.1-0.8,9.9-0.8,9.9l5,49.9l1.7-0.2L20.1,12z"
      />
      <path
        fill={colors[0]}
        d="M22,69.3c0,0,0.1,1.6,4,2.1c3.6-1.2,3.7-2.9,3.7-2.9s3.2,4.1-3.6,4.8C19.4,74,22,69.3,22,69.3z"
      />
      <path
        fill={colors[3]}
        d="M29.8,68.6c0,0,1.4,1.7-2.1,2.5c-0.5,0.1-0.8,2.3-1.5,2.3c-0.7,0.1-1.5-2-2-2c-3.5-0.1-2.1-2.1-2.1-2.1 s-3.3,3-0.4,4c0.2-0.4,0.7-0.7,1.3-0.8c0.9-0.1,1.7,1.9,1.9,2.6c0.5,0.8,1.1,1.3,1.7,1.3c0.7-0.1,1.1-0.9,1.4-1.6 c0-0.7,0.7-2.9,1.6-2.9c0.5-0.1,1,0.1,1.3,0.4C33.1,70.8,29.8,68.6,29.8,68.6z"
      />
      <path
        fill="url(#SVGID_17_)"
        d="M26.9,73.4c0.1,0.6-0.2,1.1-0.6,1.1c-0.4,0-0.7-0.4-0.8-1s0.2-1.1,0.6-1.1S26.9,72.8,26.9,73.4z"
      />
      <path
        fill={colors[3]}
        d="M28.3,86.4l-1.6,0.2c-0.5,0-0.9-0.3-1-0.8v-0.5c0-0.5,0.3-0.9,0.8-1l1.6-0.2c0.5,0,0.9,0.3,1,0.8v0.5 C29.1,85.9,28.8,86.4,28.3,86.4z"
      />
      <path
        fill={colors[5]}
        d="M25.6,85.3c0-0.5,0.3-0.9,0.8-1l1.6-0.2c0.5,0,0.9,0.3,1,0.8C28,84.6,26.4,84.7,25.6,85.3z"
      />
      <ellipse
        transform="rotate(-5.486 26.28 73.486) scale(.99998)"
        fill={colors[6]}
        cx="26.3"
        cy="73.5"
        rx="1.1"
        ry="1.5"
      />
      <ellipse
        transform="rotate(-5.486 26.28 73.486) scale(.99998)"
        fill={colors[7]}
        cx="26.3"
        cy="73.5"
        rx="0.9"
        ry="1.3"
      />
      <ellipse
        transform="rotate(-5.486 26.28 73.486) scale(.99998)"
        fill={colors[8]}
        cx="26.3"
        cy="73.5"
        rx="0.7"
        ry="1.1"
      />
      <radialGradient
        id="SVGID_17_"
        cx="175.208"
        cy="88.271"
        r="0.903"
        gradientTransform="matrix(.9954 -.0956 -.0956 -.9954 -139.737 178.062)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.698" stopColor="#fff" stopOpacity="0.1" />
        <stop offset="0.724" stopColor="#f6f6f6" stopOpacity="0.1" />
        <stop offset="0.762" stopColor="#e4e5e6" stopOpacity="0.1" />
        <stop offset="0.809" stopColor="#c8cacc" stopOpacity="0.1" />
        <stop offset="0.862" stopColor="#a2a4a7" stopOpacity="0.1" />
        <stop offset="0.921" stopColor={colors[14]} stopOpacity="0.1" />
        <stop offset="0.983" stopColor="#2d2d2e" stopOpacity="0.1" />
        <stop offset="0.995" stopOpacity="0.1" />
      </radialGradient>
    </g>
  )
}

export default LongswordRight
