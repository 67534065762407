import React from "react"

const LeatherShoulderLayered = ({
  colors = ["#A97C50", "#8B5E3C", "#603913", "#SVGID_", "#FFFFFF", "#C49A6C"]
}) => {
  return (
    <g id="LeatherShoulderLayered">
      <g opacity="0.2">
        <path d="M37.961525,71.3718719c-0.6834526-1.4157257-2.1479912-2.2456284-2.1479912-2.2456284l-0.0488167-0.4393616 c0.2440872-0.2440872,0.4881783-0.4881821,0.781086-0.6834488c-0.0976372-0.097641-0.1952705-0.1952744-0.1952705-0.1952744 l0.0488167-0.4393616c0.6346321-0.3417282,1.3180847-0.6346359,2.0991745-0.8299103 c-0.0488205-0.1464539-0.0976372-0.1952667-0.0976372-0.1952667l0.1464539-0.3905487 c0.6834488-0.2929077,1.4157181-0.4393616,2.2456245-0.4393616c1.5621719,0,2.7826233,0.4881821,2.83144,0.5369949 l0.1464539,0.3417282l-0.781086,3.1243515c0.1464539,0.1952744,0.1952705,0.3905411,0.1952705,0.6346283l0,0 c0,0.0488205,0,0.097641,0,0.097641c0,0.3905411-0.1952705,0.7322693-0.5369949,0.9275436l0,0 c-0.0976372,0.1464539-0.2929077,0.1464539-0.4393616,0.1464539l0,0c-0.244091,0-0.5858192-0.0488205-1.1228142-0.1464539 c-0.2929077,0.0488129-0.6346321,0.0976334-1.025177,0.0976334c-0.1464539,0-0.3417244,0-0.4881783,0l-1.3180885,0.2440948 L37.961525,71.3718719z" />
        <path d="M42.8433189,70.0537872c0-0.1952744-0.0976372-0.3905487-0.1952744-0.5370026l0.8299065-3.2707977 c0,0-2.5385323-1.0739975-4.8329773-0.097641c0,0,0.0976372,0.1952744,0.244091,0.5370026 c-0.7322693,0.1464462-1.5621758,0.4393616-2.3432617,0.8787155c0,0,0.1952705,0.1464539,0.4881783,0.4393692 c-0.3417244,0.2440872-0.7322693,0.4881744-1.025177,0.8299026c0,0,1.5133553,0.8787231,2.2944412,2.3920822 c0.5369987-0.097641,0.9275436-0.1952744,1.269268-0.2929077c0.7322693,0.0488129,1.2204514,0,1.5621758-0.097641 c1.2204475,0.2440948,1.4157219,0.1952744,1.3669014,0.097641c0.2929077-0.1464539,0.4881783-0.4393616,0.4881783-0.7322693 C42.8433189,70.1514206,42.8433189,70.1514206,42.8433189,70.0537872 C42.8433189,70.1026001,42.8433189,70.0537872,42.8433189,70.0537872z" />
        <path d="M55.9753456,71.2254181c-0.1952744,0-0.3417244,0-0.4881783,0c-0.3905449,0-0.7322693-0.0488205-1.025177-0.097641 c-0.5369987,0.097641-0.9275436,0.1464539-1.1228142,0.1464539c-0.1464539,0-0.3417244,0-0.4393616-0.1464539l0,0 c-0.3417244-0.1952667-0.5369949-0.5369949-0.5369949-0.927536c0-0.0488205,0-0.097641,0-0.1464539l0,0 c0-0.2440948,0.0488167-0.4393692,0.1952705-0.6346359l-0.781086-3.1243515l0.1464539-0.3417206 c0.0488167,0,1.2692642-0.5370026,2.83144-0.5370026c0.8299065,0,1.5621758,0.1464539,2.2456284,0.4393616l0.1464539,0.3905487 c0,0-0.0488167,0.0976334-0.0976334,0.1952667c0.781086,0.1952744,1.5133553,0.4881821,2.0991745,0.8299103l0.0488167,0.4393616 c0,0-0.0976372,0.0488129-0.1952744,0.1952744c0.2929077,0.1952667,0.5858154,0.4393616,0.781086,0.6834488l-0.0976334,0.4881821 c0,0-1.4645386,0.8787231-2.1479912,2.2456207l-0.341732,0.1952744L55.9753456,71.2254181z" />
        <path d="M58.5138817,68.0034332c0.244091-0.2929077,0.4881783-0.4393692,0.4881783-0.4393692 c-0.781086-0.4393616-1.6598091-0.7322693-2.3432617-0.8787155c0.1464539-0.3417282,0.244091-0.5370026,0.244091-0.5370026 c-2.3432617-0.9763565-4.8329811,0.097641-4.8329811,0.097641l0.8299065,3.2707977 c-0.1464539,0.1464539-0.1952705,0.3417282-0.1952705,0.5370026l0,0c0,0.0488129,0,0.0976334,0,0.0976334 c0,0.3417282,0.1952705,0.6346359,0.4881783,0.7322693c-0.0488167,0.0976334,0.1464539,0.1952744,1.3669014-0.0976334 c0.3417282,0.0976334,0.8299065,0.1464539,1.5621719,0.0976334c0.3417244,0.0488205,0.7810898,0.1464539,1.269268,0.2929077 c0.7322693-1.5133591,2.2944412-2.3920822,2.2944412-2.3920822 C59.246151,68.4916077,58.8556061,68.2475204,58.5138817,68.0034332z" />
      </g>
      <path
        fill={colors[0]}
        d="M35.9599876,68.9309692c0,0,1.5133553,0.8787231,2.2944412,2.3920822 c2.7338066-0.5858154,2.7338066-0.5858154,2.7338066-0.5858154l-1.5133591-3.5148926 C39.4748764,67.2223434,37.2780724,67.4664307,35.9599876,68.9309692z"
      />
      <path
        fill={colors[1]}
        d="M36.5458031,67.661705c0,0,1.7574463,1.3668976,1.9039001,3.2708054 c2.83144,0.4881744,3.3196182-0.2929077,3.3196182-0.2929077l-1.3180847-4.0518951 C40.4512367,66.5388947,38.352066,66.636528,36.5458031,67.661705z"
      />
      <path
        fill={colors[0]}
        d="M38.6449738,66.2459869c0,0,1.1228142,2.0991669,1.025177,4.3936157 c3.075531,0.7810822,2.83144,0.4393616,2.6361694,0.2929077l1.1716347-4.5888901 C43.5267677,66.3436203,40.9882355,65.2696228,38.6449738,66.2459869z"
      />
      <circle fill={colors[2]} cx="42.062" cy="70.298" r="0.83" />
      <circle fill="url(#SVGID_1_)" cx="42.062" cy="70.151" r="0.83" />
      <radialGradient
        id="SVGID_1_"
        cx="42.051"
        cy="708.171"
        r="0.832"
        gradientTransform="translate(0 -638.013)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.662" stopColor={colors[1]} />
        <stop offset="1" stopColor={colors[5]} />
      </radialGradient>
      <g>
        <path
          fill={colors[0]}
          d="M59.5878754,68.9309692c0,0-1.5133553,0.8787231-2.2944412,2.3920822 c-2.7338066-0.5858154-2.7338066-0.5858154-2.7338066-0.5858154l1.5133553-3.5148926 C56.0729828,67.2223434,58.2697906,67.4664307,59.5878754,68.9309692z"
        />
        <path
          fill={colors[1]}
          d="M59.0020599,67.661705c0,0-1.7574463,1.3668976-1.9039001,3.2708054 c-2.83144,0.4881744-3.3196182-0.2929077-3.3196182-0.2929077l1.3180847-4.0518951 C55.0966225,66.5388947,57.1957932,66.636528,59.0020599,67.661705z"
        />
        <path
          fill={colors[0]}
          d="M56.9028893,66.2459869c0,0-1.122818,2.0991669-1.025177,4.3936157 c-3.075531,0.7810822-2.83144,0.4393616-2.6361694,0.2929077l-1.1716347-4.5888901 C52.0210915,66.3436203,54.5596275,65.2696228,56.9028893,66.2459869z"
        />
        <circle fill={colors[2]} cx="53.486" cy="70.298" r="0.83" />
        <circle fill="url(#SVGID_2_)" cx="53.486" cy="70.151" r="0.83" />
        <radialGradient
          id="SVGID_2_"
          cx="-1001.66"
          cy="708.171"
          r="0.831"
          gradientTransform="matrix(-1 0 0 1 -948.182 -638.013)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.662" stopColor={colors[1]} />
          <stop offset="1" stopColor={colors[5]} />
        </radialGradient>
      </g>
    </g>
  )
}

export default LeatherShoulderLayered
