import React from "react"

const WornShieldRight = ({
  colors = ["#8A784E", "#A59A76", "#EED793", "#B0B0B1"]
}) => {
  return (
    <g id="WornShieldRight">
      <path
        fill={colors[0]}
        d="M10.6,69.1c0,0,3.4-0.5,7.3-2.4s4.6-3.7,4.6-3.7s12,18.3,10.7,34.4C17.5,91.2,10.6,69.1,10.6,69.1z"
      />
      <path
        fill={colors[1]}
        d="M32,95c0.6-11.9-6.3-24.8-8.9-29.4c-0.7,0.7-1.9,1.8-4.2,3s-4.6,1.7-5.5,1.8C14.7,75.4,20.3,88.4,32,95z"
      />
      <path
        fill={colors[0]}
        d="M31.5,94c0.3-10.8-5.4-22.3-8.3-27.4c-0.7,0.7-1.9,1.7-4,2.8c-2.1,1-3.6,1.4-4.6,1.5 C16.9,76.3,22.6,87.8,31.5,94z"
      />
      <path
        fill={colors[2]}
        d="M19.3,69.5c-2.1,1-3.6,1.4-4.6,1.5c0,0,0,0,0,0.1l0,0c-0.2-0.2-1.2-0.5-1.4-0.7l0.7-0.1 c1-0.1,2.6-0.4,5-1.6c2.3-1.2,3.6-2.3,4.2-3c0,0,0,0.1,0.1,0.1c0,0.3,0.1,0.6,0.1,0.9C22.6,67.5,21.3,68.5,19.3,69.5z"
      />
      <ellipse
        transform="rotate(-26.514 22.856 64.393)"
        fill={colors[3]}
        cx="22.9"
        cy="64.4"
        rx="0.2"
        ry="0.5"
      />
      <ellipse
        transform="rotate(-26.514 12.065 70.003)"
        fill={colors[3]}
        cx="12.1"
        cy="70"
        rx="0.2"
        ry="0.5"
      />
      <ellipse
        transform="rotate(-26.514 32.461 96.008)"
        fill={colors[3]}
        cx="32.5"
        cy="96"
        rx="0.2"
        ry="0.5"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M22.6,62.8c0,0-0.8,1.8-4.7,3.7s-7.3,2.4-7.3,2.4 S17.5,91,33.2,97.2C34.6,81.1,22.6,62.8,22.6,62.8z"
      />
    </g>
  )
}

export default WornShieldRight
