import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const DaggerRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("DaggerRight") ? (
    <g id="DaggerRight">
      <path
        id="S_x24_daggerRight"
        d="M24.4,92.5l-1.2-1l-3.9-9.4c0,0-6.6-23.9-6.4-25.9l5.4-5.3l6.4,0.8c1,0.9,6,5.6,7.8,12.5l5,22.7 l-0.4,1.5l-4.7,5l-1.3,0.4L24.4,92.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(DaggerRight, {
  character: "ManDwarf"
})
