import React from "react"
import { withSkinColor } from "../../builderConnector"

const RightThumb = ({ skinColor, colors = ["#E7B080"] }) => {
  return (
    <g id="RightThumb">
      <path
        fill={skinColor}
        d="M33.6858368,83.8479996c-0.0926628-0.223938-0.1097107-2.5163345-0.2950363-2.6553345 c-0.1853256-0.1389923-1.4869232-0.494957-1.6259174-0.5412903c-0.1389961-0.0463257-1.8069401,1.0656357-1.8069401,1.0656357 l1.7427673,0.4356918c0.3139915,0.0784988,0.5984077,0.2465591,0.8186378,0.4837265l0.6702919,1.3724976L33.6858368,83.8479996z"
      />
      <path
        opacity="0.3"
        d="M33.6858368,83.8882294c-0.0670547-0.4693756-0.1097107-2.5565643-0.2950363-2.6955643 c-0.1853256-0.1389923-1.4869232-0.494957-1.6259174-0.5412903c-0.1389961-0.0463257-1.8069401,1.0656357-1.8069401,1.0656357 l1.7427673,0.4356918c0.3139915,0.0784988,1.2552872,1.6726913,1.4755173,1.9098663L33.6858368,83.8882294z"
      />
    </g>
  )
}

export default withSkinColor(RightThumb, { character: "ManGnome" })
