import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WideHorns = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WideHorns") ? (
    <g id="WideHorns">
      <path d="M69.2,20.9c-7.6,0-11-3.2-12.6-5.9c-0.6-1-1.4-1.9-1.4-1.9l0,0c0,0,0,0,0.1,0.1l-0.1-0.1l-1.8,0.6c-0.5-1-2.1-4.4-0.9-8.2 c0,0,2.7-4.6,5-5.8L59.3-1c0.5-0.1,1-0.1,1.6-0.1c0.1,0,0.2,0,0.4,0c3.6,0.1,6.2,2.2,8,4.3c1.8-2,4.4-4.3,8.2-4.3 c0.5,0,1.1,0,1.6,0.1l1.4,0.4c1.2,0.5,2.3,1.2,3.2,2.2c1,1,1.8,2.3,2.2,3.7L86,5.4c1.2,3.8-0.4,7.3-0.9,8.2L83.3,13l0,0l0,0 l-1.1,1.1c0.4-0.3,0.6-0.6,0.6-0.6c-0.1,0.1-1.3,2.1-1.3,2.1C78.9,19.1,74.6,20.9,69.2,20.9z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WideHorns, { character: "ManMixed" })
