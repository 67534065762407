import React from "react"

const Rope = ({ colors = ["#FFFFFF"] }) => {
  return (
    <g id="Rope" fill="#FFF">
      <path d="M40.8331299,80.3260803c-0.0061035-0.0465088,0.0057983-0.0948486,0.0119019-0.1426392 c-0.8201904-0.1680298-2.2886734-0.7266617-2.6129532-1.1575089c-0.0563965-0.0749512,0.1653862,0.4384155-0.0804634,0.4827881 c-0.0454483-0.049942,0.4023247,1.4081268,2.1539268,0.9675064c0.1693115,0.045166,0.3616943,0.0687866,0.5524902,0.0935059 C40.8499146,80.4884338,40.84375,80.4073181,40.8331299,80.3260803z" />
      <path d="M44.8502846,80.4240036c-0.8527222-0.0927124-1.6304245-0.1665192-2.4764328-0.3372345 c-0.0742798-0.0150146-0.3371582,0.0296631-0.5718994,0.069519c0.0130615,0.0248413,0.0189819,0.0514526,0.0325928,0.0766602 c0.0540771,0.1004639-0.0167236,0.3414307-0.0386963,0.4674072c0.1156006,0.0147705,0.2391357,0.0266724,0.347229,0.0449829 c0.0910645,0.0154419,0.1760254,0.0130005,0.265564,0.0241699c0.7571411,0.1846313,1.5277214,0.0291443,2.3310051,0.0769348 C44.7813339,80.8489456,44.8568764,80.4396896,44.8502846,80.4240036z" />
      <path d="M51.6308899,80.4033966c0.0001221-0.0009766,0.0005493-0.0019531,0.0006714-0.0029297 c-0.000061-0.0007324-0.0002441-0.0015869-0.0003052-0.0023193c-0.005188-0.0149536-0.0097656-0.0977783-0.0117798-0.1231079 c-0.0932007,0.0007324-0.1859741,0.0056152-0.2800293-0.0010376c-0.0292969,0.006958-0.059021,0.012085-0.0882568,0.019165 c-0.003479,0.0161743-0.0088501,0.0280151-0.0131836,0.0422363c-0.5215454,0.0408936-1.1374512,0.1096191-1.3261719,0.0993042 c-0.6569824,0.1264648-1.3278809,0.1802368-1.9525757-0.0422974c-0.4025879,0.0683594-0.8035889,0.147522-1.2078247,0.2542114 c-0.0272217,0.0071411-0.0316772,0.0144043-0.0394287,0.0214844c-0.3036499-0.076416-0.6442871-0.0662842-0.9951172-0.0274658 c0.0092163,0.1325073,0.0249634,0.2647095,0.0333862,0.3972778c0.7047119,0.0132446,2.1468506-0.175415,2.2711182-0.1737671 c0.1433716,0.036377,0.2868652,0.0728149,0.4302368,0.1091919c0.1864014,0.0473022,0.4281616-0.0061035,0.6151733-0.0324097 c0.0522461-0.0073853,0.3136597-0.0673218,0.3306885-0.0907593c0.3301392-0.0107422,0.6640015-0.0302734,0.9938354-0.052002 c-0.0657349-0.0307007,0.4284668-0.0610962,0.2941895-0.0241699c0.1224976-0.0092773,0.2455444-0.0195312,0.3683472-0.0302734 c-0.1403198,0.1608276,0.2747803,0.1362305,0.5805054,0.0266724 C51.6286926,80.6477203,51.6308289,80.4038849,51.6308899,80.4033966z" />
      <path d="M47.3620605,84.5506744c0.1443481-0.0357056,0.2764893-0.1520996,0.3850098-0.2958984 c0.0043945-0.3108521,0.0057373-0.5446167,0.0118408-0.5908203c0.0530396-0.1381226,0.1061401-0.2763062,0.1591187-0.4144287 c0.0689697-0.1795044,0.0443726-0.4258423,0.0402832-0.6146851c-0.0011597-0.0527344-0.0299072-0.319397-0.0511475-0.3390503 c0.0282593-0.3291016,0.0481567-0.6629639,0.0654297-0.993042c-0.038208,0.0616455-0.0101929-0.4326782,0.0106812-0.2949829 c0.005188-0.1227417,0.0095215-0.2461548,0.0133057-0.3693848c0.1431885,0.1583252,0.1676636-0.2567749,0.0949097-0.5733032 c-0.1224976-0.0088501-0.3643799-0.0396729-0.3648682-0.0397949c-0.0009766-0.0002441-0.0018921-0.0007935-0.0028076-0.0009766 c-0.0007324-0.000061-0.0016479,0-0.0023804,0c-0.0154419,0.003418-0.0982056-0.0018311-0.1235962-0.0028076 c-0.0102539,0.0926514-0.0163574,0.1853638-0.0340576,0.2779541c0.003479,0.0299072,0.0050659,0.0600586,0.008667,0.0899048 c0.015625,0.0053711,0.0267334,0.012085,0.0403442,0.0180664c-0.020813,0.5227051-0.0251465,1.1424561-0.0576172,1.3286133 c0.0482178,0.6673584,0.022583,1.3399048-0.2720337,1.934021C47.2977905,83.9621735,47.3303223,84.2563629,47.3620605,84.5506744z" />
      <path d="M48.9523926,83.7500763c0.0513306,0.1535645,0.1175537,0.3049927,0.1732178,0.4576416 c0.1108398-0.1047974,0.2338867-0.1921997,0.3648071-0.2686157c-0.045105-0.1699829-0.0768433-0.2909546-0.0814209-0.322998 c0.013855-0.1472778,0.027832-0.2946167,0.041687-0.4418945c0.0181274-0.1914673-0.0718384-0.4221191-0.1265869-0.6029053 c-0.0153198-0.0504761-0.1147461-0.2995605-0.140564-0.3127441c-0.0614014-0.324585-0.132019-0.6514893-0.2042236-0.9740601 c-0.0202026,0.0697021-0.1262817-0.4139404-0.0690918-0.2869263c-0.0280151-0.1196289-0.0570679-0.239624-0.0866089-0.359314 c0.180542,0.1138916,0.0923462-0.2924805-0.0628662-0.5776978c-0.1203613,0.0244751-0.3616333,0.0598145-0.3621216,0.0598755 c-0.0009766,0-0.0020142-0.0002441-0.0029907-0.0002441c-0.0007324,0.0001831-0.0015259,0.0004883-0.0022583,0.0006714 c-0.0139771,0.0074463-0.0950928,0.0246582-0.119812,0.0305786c0.0150146,0.09198,0.0341187,0.1828613,0.0419922,0.2768555 c0.0114136,0.0278931,0.0210571,0.0564575,0.0325317,0.0842285c0.0165405,0.0009766,0.0290527,0.0044556,0.0437622,0.0065308 c0.1206055,0.5090942,0.2832642,1.1070557,0.302063,1.295166C48.9199219,82.443924,49.0762329,83.098587,48.9523926,83.7500763z" />
      <path d="M55.6412544,80.3933487c-0.4267578-0.2616577-0.6441383-0.1459808-0.9944191-0.1604462 c-0.6504517,0.1431885-0.9627304,0.0585403-1.6193466,0.0905228c0.003418,0.1357422-0.0436935,0.5394516,0.0402298,0.5129623 c0.000061,0,0.1910439-0.0100632,0.1911049-0.0100632c0.906189,0.0477905,2.4523201,0.0850143,2.464222,0.0100632 c0.0018921-0.0317383-0.0799599-0.2338104-0.0709267-0.2682953c0.0089111-0.0463257,0.0254517-0.0865479,0.0369873-0.131958 C55.3426094,80.4692764,55.5587349,80.3427505,55.6412544,80.3933487z" />
      <path d="M59.3942375,79.4684906c-1.222229,0.3566284-1.8523521,0.666626-3.1263733,0.8812027 c0.0066528,0.1334839-0.0266724,0.5964966,0.012085,0.5718384c0.1151733-0.0731201,0.2302856-0.1463013,0.345459-0.2194824 c0.2163696-0.0023193,0.5198975-0.0194702,0.6347656-0.05896c0.5110474-0.130127,1.0220947-0.2603149,1.5331421-0.3904419 c0.1257935-0.0201416,0.6934814-0.0989075,0.7216187-0.0197449C59.5262871,80.294426,59.474865,79.4449921,59.3942375,79.4684906z" />
    </g>
  )
}

export default Rope
