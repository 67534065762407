import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WarhammerL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WarhammerL") ? (
    <g id="WarhammerL">
      <path
        id="S_x24_warhammerL"
        d="M73.8,74.5l-4.9-3.3c-5-3.3-6.4-10.2-3-15.2l1.4-2.2c3.3-5,10.2-6.4,15.2-3l4.9,3.3 c5,3.3,6.4,10.2,3,15.2L89,71.5C85.7,76.5,78.8,77.9,73.8,74.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WarhammerL, {
  character: "ManDwarf"
})
