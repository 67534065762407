import React from "react"

const LongSkirt = ({ colors = ["#6B6B6B", "#231F20"] }) => {
  return (
    <g id="LongSkirt" fillRule="evenodd" clipRule="evenodd">
      <path
        fill={colors[0]}
        d="M59.0999985,72.6999969 c0,0-5.9000015,5.5999985-6.9000015,25.6999969c-1.0999985,6.6999969-5.9000015,19.5-5.9000015,19.5h46 c0,0-6.0999985-9.5-7.6999969-19.1999969C81.5,71.3999939,77.9000015,72.5999985,77.9000015,72.5999985 s-4.0999985,2.8000031-9.1999969,2.8000031C63.5999985,75.5999985,59.0999985,72.6999969,59.0999985,72.6999969z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M56.0999985,118 h26.7000046l-7.0999985-44.0999985c0,0-4.0999985,1.5-7.0999985,1.5S62,74.0999985,62,74.0999985L56.0999985,118z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M64.5,74.8000031 c0,0,0.4000015,17.5,0.4000015,23.5S61.4000015,118,61.4000015,118h-9.5999985c0,0,4.7000008-8,5.7000008-19.8000031 C58.5000038,86.2999954,59.7000046,73,59.7000046,73L64.5,74.8000031z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M72.8000031,74.8000031c0,0-0.1999969,15.3000031,0.0999985,22.5999985C73.2000046,104.7000046,76.5999985,118,76.5999985,118 h9.5999985c0,0-4.6999969-12.4000015-6.0999985-21.4000015c-1.3000031-9-2.9000015-23.4000015-2.9000015-23.4000015 L72.8000031,74.8000031z"
      />
    </g>
  )
}

export default LongSkirt
