import React from "react"

const WarhammerR = ({
  colors = [
    "#B8B9BA",
    "#727474",
    "#E6E7E7",
    "#646565",
    "#7F8080",
    "#636565",
    "#CDCFCF",
    "#A0A0A1",
    "#D6D7D5"
  ]
}) => {
  return (
    <g id="WarhammerR">
      <path
        opacity="0.63"
        d="M59.947998,102.290947 c0.1388321,0.0151443,0.2852364-0.0391235,0.4316368-0.093399c0.5931816-0.286499,0.6613312-0.911232,0.5527878-1.2040405 l-1.0614357-2.5039978l-0.1388283-0.0151443L52.100174,80.2219849l0.0151443-0.1388321l-0.2713547-0.7320099l0.0151443-0.1388321 l-0.1085396-0.2928085l-0.1388283-0.0151443l-1.3946342-3.3129883l-0.1312561-0.0845642l-0.8986206-2.0647812l0.0151443-0.1388321 l-0.1628151-0.439209l1.2557793-0.5654221l0.1766891-0.3319321l0.3395081,0.1072693l0.7395859-0.3407745l0.3546486-0.0315475 l1.3668327-2.8712769l-0.2246552-0.5161972l-2.9785538-1.0273361l-0.2852325,0.0391312l-0.7395859,0.3407669l-0.1766891,0.3319321 l-0.3395042-0.1072769l-4.4148026,1.8363724l-0.3395042-0.1072769l-0.8859863,0.3950424l-0.1072769,0.3395004 l-0.6625977,0.2789307l-0.0997047,0.2700882l-0.069416-0.0075684l-0.1754227,0.964241l0.0466957,0.2158203l1.0071602,2.3575897 l0.1236877,0.1539688l0.7875481,0.5073547l0.3470764,0.0378647l0.6701775-0.3483429l0.2700882,0.0997086l0.8859863-0.3950424 l0.0921326-0.200676l1.3251991-0.5578537l0.1628151,0.439209l0.0694122,0.007576l0.8292084,2.0572128l-0.0075722,0.0694122 l1.3870621,3.3824081l-0.0151443,0.1388321l0.1085396,0.2928009l0.1388283,0.0151443l0.2713547,0.7320175l0.0694122,0.0075684 l7.7708168,18.26754l-0.0075722,0.0694122l1.0614357,2.5039978 C59.152874,101.8530045,59.4620895,102.2379379,59.947998,102.290947z"
      />
      <rect
        x="53.605"
        y="72.017"
        transform="rotate(-22.733 54.163 87.345)"
        fill={colors[0]}
        width="1.117"
        height="30.655"
      />
      <rect
        x="47.512"
        y="73.05"
        transform="rotate(-22.733 48.175 73.084)"
        fill={colors[1]}
        width="1.327"
        height="0.07"
      />
      <rect
        x="48.781"
        y="75.934"
        transform="rotate(-22.733 49.444 75.97)"
        fill={colors[1]}
        width="1.327"
        height="0.07"
      />
      <rect
        x="48.687"
        y="75.896"
        transform="rotate(-22.733 49.35 75.93)"
        fill={colors[2]}
        width="1.327"
        height="0.07"
      />
      <rect
        x="47.628"
        y="73.043"
        transform="rotate(-22.733 48.29 73.392)"
        fill={colors[3]}
        width="1.327"
        height="0.698"
      />
      <rect
        x="49.7"
        y="75.865"
        transform="rotate(-22.733 50.364 78.24)"
        fill={colors[4]}
        width="1.327"
        height="4.748"
      />
      <rect
        x="50.737"
        y="80.386"
        transform="rotate(-22.733 51.296 80.526)"
        fill={colors[5]}
        width="1.117"
        height="0.279"
      />
      <rect
        x="54.261"
        y="83.581"
        transform="rotate(-22.733 54.82 88.888)"
        fill={colors[4]}
        width="1.117"
        height="10.614"
      />
      <rect
        x="58.823"
        y="98.754"
        transform="rotate(-22.733 59.486 100.081)"
        fill={colors[4]}
        width="1.327"
        height="2.654"
      />
      <rect
        x="58.413"
        y="98.826"
        transform="rotate(-22.733 58.972 98.896)"
        fill={colors[6]}
        width="1.117"
        height="0.14"
      />
      <polyline
        fill={colors[7]}
        points="51.4501915,79.0974503 51.5587311,79.3902588 50.2335358,79.9481125 50.1249886,79.655304"
      />
      <path
        fill={colors[8]}
        d="M59.3914108,101.5980606l1.178791-0.5035858c0,0,0.1400986,0.6474533-0.3685265,0.8026886 C59.7548981,102.1293945,59.3914108,101.5980606,59.3914108,101.5980606z"
      />
      <path
        fill={colors[7]}
        d="M51.5738373,71.5248795l-0.7395859,0.3407669c0,0-0.0542717-0.1464005-0.1703873-0.3697891 c-0.501049,0.0858231-0.3458138,0.5944443-0.3458138,0.5944443l-6.3331795,2.6807251l-1.6117172-3.8986053l6.3331871-2.6807251 c0,0,0.2246552,0.5161972,0.6790085,0.2145538c-0.1628151-0.439209-0.1628151-0.439209-0.1628151-0.439209l0.7395859-0.3407669 l2.97855,1.0273361L51.5738373,71.5248795z"
      />
      <rect
        x="43.965"
        y="69.952"
        transform="rotate(-22.733 44.454 72.256)"
        fill={colors[3]}
        width="0.978"
        height="4.609"
      />
      <rect
        x="50"
        y="67.785"
        transform="rotate(-22.733 50.174 69.88)"
        fill={colors[3]}
        width="0.349"
        height="4.19"
      />
      <rect
        x="49.172"
        y="68.104"
        transform="rotate(-22.733 49.312 70.2)"
        fill={colors[3]}
        width="0.279"
        height="4.19"
      />
      <polygon
        fill={colors[3]}
        points="43.9848709,74.7710266 43.135479,74.1866837 42.1283112,71.8290939 42.3731575,70.8724213"
      />
      <polygon
        fill={colors[3]}
        points="52.9330978,68.7230225 50.7989006,69.6140671 51.5738373,71.5248795"
      />
      <path
        fill={colors[3]}
        d="M49.930584,71.134903l0.4619255-0.3710632l0.2713547,0.7320175 c0,0-0.4934769,0.0164108-0.3458138,0.5944519C50.093399,71.5741043,49.930584,71.134903,49.930584,71.134903z"
      />
    </g>
  )
}

export default WarhammerR
