import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Ears from "./Ears"
import Brows from "./Brows"
import Mouth from "./Mouth"
import Lips from "./Lips"
import Eyes from "./Eyes"
import BigEyes from "./BigEyes"
import SmNose from "./SmNose"
import Nose from "./Nose"

const CATEGORY = "face"
const CHARACTER = "WomanGnome"
export const IDS = {
  EARS: "Ears",
  BROWS: "Brows",
  MOUTH: "Mouth",
  LIPS: "Lips",
  EYES: "Eyes",
  BIG_EYES: "BigEyes",
  SM_NOSE: "SmNose",
  NOSE: "Nose"
}

export const components = {
  [IDS.EARS]: Ears,
  [IDS.BROWS]: Brows,
  [IDS.MOUTH]: Mouth,
  [IDS.LIPS]: Lips,
  [IDS.EYES]: Eyes,
  [IDS.BIG_EYES]: BigEyes,
  [IDS.SM_NOSE]: SmNose,
  [IDS.NOSE]: Nose
}

export const Group = ({ props }) => (
  <g id="face">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.EARS,
    name: "Ears",
    defaultColors: ["#E7B080", "#353891"],
    colorable: true,
    component: components[IDS.EARS],
    inUI: true,
    enabled: true,
    subGroupId: 5
  },
  {
    id: IDS.BROWS,
    name: "Brows",
    defaultColors: ["#231F20"],
    colorable: true,
    component: components[IDS.BROWS],
    inUI: true,
    enabled: true,
    subGroupId: 4
  },
  {
    id: IDS.MOUTH,
    name: "Mouth",
    defaultColors: ["#353891"],
    colorable: true,
    component: components[IDS.MOUTH],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.LIPS,
    name: "Lips",
    defaultColors: ["#9B3C00"],
    colorable: true,
    component: components[IDS.LIPS],
    inUI: true,
    enabled: true,
    subGroupId: 3
  },
  {
    id: IDS.EYES,
    name: "Eyes",
    defaultColors: ["#603813"],
    colorable: true,
    component: components[IDS.EYES],
    inUI: true,
    enabled: true,
    subGroupId: 2
  },
  {
    id: IDS.BIG_EYES,
    name: "Big Eyes",
    defaultColors: ["#E0E0E0"],
    colorable: true,
    component: components[IDS.BIG_EYES],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.SM_NOSE,
    name: "Sm Nose",
    defaultColors: ["#ED1C24", "#redGra", "#FFFFFF"],
    colorable: true,
    component: components[IDS.SM_NOSE],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.NOSE,
    name: "Nose",
    defaultColors: ["#EC1E24", "#SVGID_", "#353891", "#FFFFFF"],
    colorable: true,
    component: components[IDS.NOSE],
    inUI: true,
    enabled: true,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
