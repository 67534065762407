import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaceR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaceR") ? (
    <g id="MaceR">
      <path
        id="S_x24_maceR_1_"
        d="M17.25564,72.0187225l2.1803932,3.706665 c2.1803913,3.7793427,9.007021,23.5701828,12.7863674,21.3897934l7.6214066-3.4338303 c3.7793427-2.1803894-3.6822281-19.4198685-5.8626213-23.1992188c0,0-2.093792-15.6615639-12.2194042-10.3102074 l-1.5989532,0.9448357C16.3834839,63.3698311,15.0752468,68.2393799,17.25564,72.0187225z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaceR, { character: "ManGnome" })
