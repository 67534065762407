import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongswordLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongswordLeft") ? (
    <g id="LongswordLeft">
      <path
        id="S_x24_longswordLeft"
        d="M71.4,95c4.1,0,7.5-3.1,7.9-7.2v-0.5c0.1-1.1,0-2.1-0.3-3.1l0.3-3.1c2.7-1.1,4.5-3.3,5.1-6 c0.9-4-1.4-7.3-3-9l4.2-42.2c1.1-11.4-1.1-14.6-3.7-16.4L74.6,7c-3.6,1.8-5.4,6-6.5,15.1l-4.2,42.2c-1.4,1-4.7,3.8-4.7,8.2 c0,2.8,1.4,5.3,3.8,7l-0.3,3.3c-0.5,0.9-0.7,1.9-0.8,2.9v0.5c-0.4,4.4,2.8,8.3,7.2,8.7l1.6,0.2L71.4,95z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongswordLeft, {
  character: "ManDwarf"
})
