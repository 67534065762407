import React from "react"

const DaggerRight = ({
  colors = [
    "#6E5E26",
    "#99B7D9",
    "#6B91BF",
    "#57789B",
    "#E1E2E3",
    "#9EA0A3",
    "#ECD592"
  ]
}) => {
  return (
    <g id="DaggerRight">
      <polygon
        fill={colors[0]}
        points="50.2338181,79.4421616 49.4657211,80.7688675 51.6303596,81.7464523 52.2588043,84.0507431 53.4458618,86.9136581 53.3062096,87.9610596 54.0743065,88.6593323 54.9820633,88.3800201 55.2613716,87.3326187 54.6329269,86.7041702 53.9346542,83.5619507 52.9570732,81.3274841 54.1441345,79.2326736 52.6777649,78.6042328"
      />
      <polygon
        fill={colors[1]}
        points="54.9122353,88.3101959 54.6329269,88.0308838 54.2139664,88.1705475 54.1441345,88.5895081"
      />
      <polygon
        fill={colors[2]}
        points="54.0743065,88.5895081 54.1441345,88.1705475 53.7251701,87.8214111 53.3062096,87.9610596"
      />
      <polygon
        fill={colors[1]}
        points="54.9122353,88.2403717 54.7027512,87.9610596 54.7725792,87.4722748 55.1915398,87.3326187"
      />
      <polygon
        fill={colors[3]}
        points="54.5630989,86.7740021 54.4234428,87.1231384 53.86483,87.2627869 53.5156937,87.0533066"
      />
      <polygon
        fill={colors[2]}
        points="55.1217155,87.2627869 54.7725792,87.4024429 54.4932671,87.1231384 54.5630989,86.7740021"
      />
      <polygon
        fill={colors[3]}
        points="53.6553459,87.7515793 53.7949982,87.3326187 53.5156937,87.1231384 53.3760376,87.8912354"
      />
      <polygon
        fill={colors[2]}
        points="54.7027512,87.4024429 54.5630989,87.9610596 54.1441345,88.1007156 53.7949982,87.7515793 53.86483,87.4024429 54.4234428,87.1929626"
      />
      <path
        fill={colors[4]}
        d="M50.16399,80.4197311l1.5361938-0.6284409l1.6758537-0.3491364 c0,0-0.977581-1.7456818-1.6060257-5.4465179c-0.977581-3.7008362-3.9103165-6.2844391-3.9103165-6.2844391l0,0 L50.16399,80.4197311z"
      />
      <path
        fill={colors[5]}
        d="M51.7001839,79.7912903l-3.8404884-12.0800858c0,0-0.5586205,4.3991089,0.7680969,7.960289 c1.1172333,2.4439468,1.6060257,4.8180695,1.6060257,4.8180695L51.7001839,79.7912903z"
      />
      <path
        fill={colors[6]}
        d="M52.887249,85.3774643l0.5586205,1.3965378c0.1396523,0.0698242,0.3491325,0.0698242,0.4887848,0.0698242 l0.1396523-0.0698242c0.1396523-0.0698242,0.2793121-0.1396484,0.3491364-0.2793121l-0.4887886-2.4439468 C53.6553459,84.6093597,53.3760376,85.0283279,52.887249,85.3774643z"
      />
      <path
        fill={colors[6]}
        d="M53.5855217,83.0033417L52.887249,81.397316l-0.4887886,0.6982651l-0.6982689-0.2793045 l0.5586166,2.0948105l0.4887886,1.1172333C53.2363853,84.6093597,53.5156937,83.6317825,53.5855217,83.0033417z"
      />
    </g>
  )
}

export default DaggerRight
