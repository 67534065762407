import React from "react"

const SlingShotR = ({
  colors = ["#362812", "#454545", "#949494", "#724F1F"]
}) => {
  return (
    <g id="SlingShotR">
      <path
        fill={colors[0]}
        d="M47,65.8l-1.1-10L39.7,53c0,0-2.2,2-2.1,4.6c0,0.4-2.1,34.2-2.1,34.2s0.2,2.4,1.2,2.8 c0.9,0.3,3.2-0.4,3.2-0.4l0.5-2.8l-0.5-34.2l-1.2-1.9l1.1-1.5l5.3,2.8l-1,2.3l0.1,8.7l2.3,2.7L46,64.8L47,65.8z"
      />
      <path
        fill={colors[1]}
        d="M35.3,90.2c0.8-1.1,1-1.8,2.5-1.6c0.2,0,1.9,0.1,2,0.3c0.5,0.8,1.2,2.7,1.2,2.7s-1.1,2.1-2.5,2.2 c-1,0.1-3.1-0.9-3.1-0.9S34.9,90.8,35.3,90.2z"
      />
      <path
        fill={colors[2]}
        d="M37.6,90c0.2-0.4,0.4-0.9,0.6-1.3c0.6,0,1.4,0.1,1.6,0.3c0.5,0.8,1.2,2.7,1.2,2.7s-0.4,0.8-1.1,1.4 c-0.7-0.2-1.4-0.6-2.1-1.1C37.7,91.3,37.7,90.6,37.6,90z"
      />
      <path
        fill={colors[3]}
        d="M38.7,93.5l-1.4-0.1L37.1,91l0.6-2.6L39,56.2l-0.3-0.9l-0.8,0.5l-2.3,35.9c0,0,0.4,2.8,1.7,2.4 c0.2-0.1,1.8,0,2.4-0.4C38.9,93.4,38.7,93.5,38.7,93.5z"
      />
    </g>
  )
}

export default SlingShotR
