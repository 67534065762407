import React from "react"

const BowRight = ({
  colors = [
    "#939393",
    "#EED793",
    "#B5AC95",
    "#925C25",
    "#AA8B2F",
    "#FFCE06",
    "#42140B"
  ]
}) => {
  return (
    <g id="BowRight">
      <path
        opacity="0.25"
        d="M53.6,115.1l-0.2-0.1l-0.3-0.6c0,0.1,0,0.1,0,0.2c0,0,0-0.1-0.2-0.4 c-0.8-0.6-5.5-2.2-8-3l-0.7-0.2c-10.5-3.8-14.3-18.2-15.5-23c-0.3-1-0.6-2.2-0.9-3.1c-0.1-0.1-0.2-0.1-0.3-0.2h-0.1l-0.9-0.8 l0.3-1.2L24,76.4l-1.1-0.6l0.1-1.1l0.1-0.1c0-0.1,0-0.2,0.1-0.3c-0.4-0.8-1.1-1.8-1.7-2.7c-2.7-4.1-10.8-16.7-6.5-27l0.3-0.7 c1-2.3,3.1-7,3.2-8c-0.1-0.3-0.1-0.4-0.1-0.4s0,0.1,0.1,0.1L18.2,35v-0.2c0.2-1,0.7-1.7,1.4-1.7l0.9,0.5l0.3,0.4l0.2,0.5 c0,0.1,0,0.1,0,0.3l34.5,78.3c0.1,0.1,0.1,0.1,0.2,0.2l0.3,0.5l0.1,0.5l-0.3,0.9c-0.2,0.2-0.6,0.4-0.9,0.4 C54.3,115.5,53.8,115.2,53.6,115.1z M20.3,38.6c-0.6,2.1-1.6,4.9-2.5,7.7c-2.7,7.6,3.2,16.8,6.8,22.3l0.5,0.8 c0.6,0.9,1.1,1.6,1.4,2.3l0.9,0.1l0.5,1V73H28l0.1,0.2l-0.2,0.5l3.3,7.6l0.5,0.3l0.3,0.5v0.1l0.1,0.1l0.4,1.1L32,84.1 c0.2,0.7,0.5,1.6,0.7,2.6l0.3,1c1.7,6.3,4.4,16.9,11.9,20c2.7,1.2,5.4,2.3,7.4,3.3L20.3,38.6z"
      />
      <polygon
        fill={colors[0]}
        points="54.7,114.3 54.8,114.2 19.7,34.5 19.6,34.6"
      />
      <g fill={colors[1]}>
        <polygon points="25.4,77.2 27.5,75.8 27.7,76.1 28.1,77 25.8,78.3" />
        <polygon points="24.8,75.9 27.1,74.6 27.5,75.6 25.2,77" />
        <polygon points="28.4,81.1 29.6,80.6 30.1,81.4 29.3,81.8" />
        <polygon points="27.7,82.6 27.3,81.6 28.3,81.2 29.2,81.9" />
        <polygon points="26.3,79.4 28.6,78.2 29.1,79.3 26.8,80.5" />
        <polygon points="27.1,81.1 26.9,80.7 29.2,79.4 29.4,80 29.6,80.4 27.2,81.4" />
        <polygon points="28,83.2 27.8,82.7 30.1,81.6 30.6,82.8" />
      </g>
      <g fill={colors[2]}>
        <polygon points="26.2,79.2 25.9,78.4 27.5,77.6 28.2,78.3" />
        <polygon points="28.6,78.1 28.4,78.2 27.6,77.5 28.2,77.2" />
      </g>
      <g fill={colors[2]}>
        <polygon points="24.7,75.7 24.4,75 24.8,74.7 25.4,75.3" />
        <polygon points="26.9,74.4 25.5,75.3 24.9,74.6 26.5,73.4" />
      </g>
      <path
        fill={colors[3]}
        d="M19.8,36.1c-0.2,1.8-1.6,5.8-3,9.8c-3,8.5,3.8,18.4,7.4,24c0.9,1.3,1.5,2.4,2,3.2l-1.7,1.8 c-0.2-0.7-1.2-2.2-2.2-3.8c-3.8-5.8-10.2-17-6.4-26.1c1.4-3.3,3.7-8.4,3.5-9.2C19.2,35,19.1,35,19.1,35s0.1-0.8,0.5-0.8 l0.3,0.4C19.9,34.6,19.9,35,19.8,36.1z"
      />
      <ellipse
        transform="rotate(-45.436 25.28 73.815)"
        fill={colors[4]}
        cx="25.3"
        cy="73.8"
        rx="1.7"
        ry="0.7"
      />
      <polygon fill={colors[5]} points="24,74.9 26.4,72.3 26.9,73.3 23.8,76" />
      <polygon
        fill={colors[6]}
        points="28,83.3 27.1,81.2 25.4,77.2 24.4,75.1 26.5,73.5 27.7,76.2 29.5,80.1 30.7,82.8"
      />
      <polygon
        fill={colors[5]}
        points="27.9,83.8 31.5,83.7 31.1,82.7 27,83.1"
      />
      <path
        fill={colors[3]}
        d="M53.6,113c-1.5-1-5.3-2.7-9.3-4.4c-8.3-3.5-11-15.2-12.7-21.7c-0.4-1.5-0.7-2.7-1-3.6h-2.4 c0.3,0.6,0.8,2.4,1.3,4.2c1.8,6.7,5.6,19,14.9,22.3c3.4,1.2,8.7,2.9,9.2,3.6s0.4,0.7,0.4,0.7s0.7,0.5,0.9,0.2l-0.1-0.5 C54.9,114,54.6,113.7,53.6,113z"
      />
      <ellipse
        transform="matrix(.9993 -.03707 .03707 .9993 -3.075 1.154)"
        fill={colors[4]}
        cx="29.6"
        cy="83.5"
        rx="1.7"
        ry="0.7"
      />
      <polygon
        fill={colors[4]}
        points="27.9,83 31.1,82.7 30.8,82.1 27.7,82.5"
      />
      <polygon
        fill={colors[4]}
        points="24.6,75.6 27,73.5 26.9,73.3 24.6,75.4"
      />
    </g>
  )
}

export default BowRight
