import React from "react"

const WarhammerL = ({
  colors = [
    "#B9BABB",
    "#727373",
    "#E7E8E8",
    "#646464",
    "#7F8080",
    "#636464",
    "#CECFCF",
    "#A2A2A3",
    "#D6D7D6",
    "#A1A2A3"
  ]
}) => {
  return (
    <g id="WarhammerL">
      <path
        opacity="0.63"
        d="M58.2999992,103.8000031 c-0.2000008,0-0.4000015-0.0999985-0.5999985-0.1999969c-0.7999992-0.5-0.7999992-1.4000015-0.5999985-1.8000031 l1.9000015-3.4000015h0.2000008l13.6999969-24.7999954v-0.1999969l0.5-1v-0.1999969l0.1999969-0.4000015h0.1999969l2.5-4.5 l0.1999969-0.0999985l1.5999985-2.8000031v-0.1999969l0.3000031-0.5999985l-1.6999969-1l-0.1999969-0.5l-0.5,0.099987 l-1-0.5999985l-0.5-0.0999985L73,57.2000008L73.4000015,56.5l4.4000015-1l0.4000015,0.0999985l1,0.5999985l0.1999969,0.5 l0.5-0.0999985l6,3.2999992l0.5-0.0999985L87.5999985,60.5l0.0999985,0.5l0.9000015,0.5l0.0999985,0.4000015h0.0999985 l0.0999985,1.4000015l-0.0999985,0.2999992L87,66.8000031L86.8000031,67l-1.1999969,0.5999985h-0.5L84.1999969,67 l-0.4000015,0.0999985l-1.1999969-0.6999969L82.5,66.0999985l-1.8000031-1l-0.3000031,0.5999985h-0.0999985l-1.5,2.8000031 v0.0999985l-2.5,4.5999985v0.1999969l-0.1999969,0.4000015h-0.1999969l-0.5,1h-0.0999985L61.4000015,99.5999985v0.0999985 L59.5,103.0999985C59.5,103.3000031,59,103.8000031,58.2999992,103.8000031z"
      />
      <rect
        x="46.906"
        y="82.62"
        transform="rotate(-61.041 68.857 83.42)"
        fill={colors[0]}
        width="43.902"
        height="1.6"
      />
      <rect
        x="79.546"
        y="63.098"
        transform="rotate(-61.041 79.596 64.048)"
        fill={colors[1]}
        width="0.1"
        height="1.9"
      />
      <rect
        x="77.292"
        y="67.008"
        transform="rotate(-61.041 77.342 67.958)"
        fill={colors[1]}
        width="0.1"
        height="1.9"
      />
      <rect
        x="77.432"
        y="66.967"
        transform="rotate(-61.041 77.482 67.918)"
        fill={colors[2]}
        width="0.1"
        height="1.9"
      />
      <rect
        x="78.884"
        y="63.518"
        transform="rotate(-61.041 79.384 64.468)"
        fill={colors[3]}
        width="1"
        height="1.9"
      />
      <rect
        x="72.28"
        y="70.097"
        transform="rotate(-61.041 75.68 71.047)"
        fill={colors[4]}
        width="6.8"
        height="1.9"
      />
      <rect
        x="73.798"
        y="73.358"
        transform="rotate(-61.041 73.998 74.158)"
        fill={colors[5]}
        width="0.4"
        height="1.6"
      />
      <rect
        x="60.083"
        y="84.715"
        transform="rotate(-61.041 67.683 85.515)"
        fill={colors[4]}
        width="15.201"
        height="1.6"
      />
      <rect
        x="57.401"
        y="99.776"
        transform="rotate(-61.041 59.301 100.726)"
        fill={colors[4]}
        width="3.8"
        height="1.9"
      />
      <rect
        x="60.117"
        y="98.318"
        transform="rotate(-61.041 60.217 99.118)"
        fill={colors[6]}
        width="0.2"
        height="1.6"
      />
      <polyline
        fill={colors[7]}
        points="74,72.0999985 73.8000031,72.5 75.5999985,73.5 75.8000031,73.0999985"
      />
      <path
        fill={colors[8]}
        d="M59.2000008,102.9000015L57.6000023,102c0,0-0.2999992,0.9000015,0.4000015,1.1999969 C58.5999985,103.5999985,59.2000008,102.9000015,59.2000008,102.9000015z"
      />
      <path
        fill={colors[9]}
        d="M75,61.2999992l1,0.5999985c0,0,0.0999985-0.2000008,0.3000031-0.5 C77,61.5999985,76.7000046,62.2999992,76.7000046,62.2999992l8.5999985,4.7999992l2.9000015-5.2999992L79.5999985,57 c0,0-0.4000015,0.7000008-1,0.2000008c0.3000031-0.5999985,0.3000031-0.5999985,0.3000031-0.5999985l-1-0.5999985l-4.4000015,1 L75,61.2999992z"
      />
      <rect
        x="81.723"
        y="62.747"
        transform="rotate(-61.041 85.023 63.447)"
        fill={colors[3]}
        width="6.6"
        height="1.4"
      />
      <rect
        x="74.248"
        y="58.926"
        transform="rotate(-61.041 77.248 59.176)"
        fill={colors[3]}
        width="6"
        height="0.5"
      />
      <rect
        x="75.426"
        y="59.564"
        transform="rotate(-61.041 78.426 59.764)"
        fill={colors[3]}
        width="6"
        height="0.4"
      />
      <polygon
        fill={colors[3]}
        points="85.3000031,67.0999985 86.5999985,66.4000015 88.4000015,63.2000008 88.1999969,61.7999992"
      />
      <polygon
        fill={colors[3]}
        points="73.5,57.0999985 76.4000015,58.7000008 75,61.2999992"
      />
      <path
        fill={colors[3]}
        d="M77.4000015,61l-0.5999985-0.5999985l-0.5,1c0,0,0.6999969,0.0999985,0.4000015,0.9000015 C77.0999985,61.5999985,77.4000015,61,77.4000015,61z"
      />
    </g>
  )
}

export default WarhammerL
