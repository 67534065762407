import React from "react"

const BroadSwordL = ({ colors = ["#898888", "#FFFFFF"] }) => {
  return (
    <g id="BroadSwordL">
      <path
        fill={colors[0]}
        d="M104.5999985-5.6999998c-0.3000031-2.9000006-2.5-6.3000002-2.5-6.3000002l-0.5,0.8000002 l-0.9000015,4.3000002L100,52.2999992l1.0999985,1.2999992l2.5999985,0.7999992 C103.5999985,54.4000015,104.9000015-2.8,104.5999985-5.6999998z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M101.0999985,53.9000015 c0.1999969-0.5,1.0999985-65.6999969,1.0999985-65.6999969s2.1999969,3.3999996,2.5,6.3000002s-1,60.2000008-1,60.2000008 L101.0999985,53.9000015z"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="101.6999969,-11 100.8000031,-6.6999998 100,52.5999985 101.0999985,53.9000015"
      />
      <path
        fill={colors[0]}
        d="M107.6999969,54.7000008l-0.0999985-2.0999985l-9.5-1.2000008L97.5,51.5l0.1999969,1.9000015L100.5,54.5 c0,3.2000008-0.1999969,8.2999992-0.1999969,8.2999992l1.8000031,1.2999992l1.5-0.5999985l-0.1999969-8.7000008 L107.6999969,54.7000008z"
      />
    </g>
  )
}

export default BroadSwordL
