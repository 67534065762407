import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FrontShieldL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FrontShieldL") ? (
    <g id="FrontShieldL">
      <path
        id="_x3C_path_x3E_"
        d="M97.4311218,95.8823318c-5.0719452,0-9.8697357-2.6045151-13.29673-7.4023132l0,0 c-0.8224792-1.2337189-1.6449509-2.4674377-2.3303528-3.9753113l-0.2741623-0.2741547 c-0.2741547-0.6854019-0.5483246-1.3707962-0.9595566-2.0561981c-3.0157471-7.6764603-4.2494736-17.9574432-3.0157471-27.5530281 c2.0561905-17.1349678,10.9663773-35.5036545,25.6339188-35.5036545c1.9191132,0,3.9753113,0.4112377,5.7573471,1.0966358 c16.0383224,5.757349,16.9978867,25.633913,14.9416885,41.2610092c-0.2741547,2.4674339-0.6853943,4.9348717-1.2337189,7.4023094 l0,0l0,0v0.1370773c-0.2741547,1.2337189-0.548317,2.3303528-0.8224716,3.4269943l-0.1370773,0.2741547 c-4.5236359,15.2158508-13.5709,20.6990433-20.4248886,22.4810791 C100.0356445,95.7452545,98.6648407,95.8823318,97.4311218,95.8823318z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FrontShieldL, {
  character: "ManDragonborn"
})
