import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Tail = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Tail") ? (
    <g id="Tail">
      <path d="M53.0999985,99.8000031c-3.2999992,0-6-0.5-8.2000008-1.5C42.2999992,97.1000061,35.3999977,93.9000015,34.5,86 C33.2000008,74.3000031,44,59.4000015,63.4000015,46.2000008l0.7999954-0.5c2.7000046-1.2999992,5.1000061-2,7.3000031-2 c3.0999985,0,5.9000015,1.2999992,7.8000031,3.5c3.4000015,4,2.9000015,9.3999977,2.5999985,10.9999962l-3.5,5.0999985 c-10.5,6-20.2999992,14.1999969-23.4000015,19.2999954c4.7999992-0.0999985,13.3000031-1.0999985,17.5-2.5l9.1999969,6.0999985 c0.1999969,2.1999969-0.5999985,4.3000031-2.0999985,5.9000015C75.4000015,96.9000015,60,99.8000031,53.0999985,99.8000031z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Tail, { character: "WomanMixed" })
