import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BlueFlameRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BlueFlameRight") ? (
    <g id="BlueFlameRight">
      <path
        id="S_x24_blueFlameRight"
        d="M24.0185051,88.922905c-1.3047218-1.522171-2.8268967-3.5879822-3.2074394-6.2517853 c0,0,0.5436344-2.7181702,0.2718163-1.6852646c0.4349079-1.739624,2.6094437-6.2517853,2.8812599-7.0128784 c0,0,0.0543633-3.5879745,0.1087265-5.5994263c0,0,0.6523609-2.0658035,0.5979977-2.0114441 c0.1087265-0.217453,0.5436325-1.0329056,1.1959934-2.3376236c0.1087284-0.1630898,0.1630898-0.7610855,0.1630898-0.9241753 c-0.0543633-0.8698158-0.1087265-2.0658112,0.3261814-3.370533l3.3705292-2.9899826l3.8054371,2.5550766 c0.1630898,0.3805466,0.4892693,1.0872688,0.869812,2.1201744c0.1630898,0.3805428,0.2718163,0.7610855,0.3805428,0.9785385 c0.5979996,0.9785423,1.0329056,3.370533,1.0329056,3.4792595c0.1630898,0.4892654,0.2718163,0.9785385,0.3261795,1.4134445 H36.1959c1.1959953,1.5765381,2.2832603,3.3705292,3.2618027,5.4906998l0.3805428,1.5765381 c0.0543633,2.3919907-1.3590851,12.7210312-1.9570808,14.0257492l-6.7410564,3.8598022L24.0185051,88.922905z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BlueFlameRight, {
  character: "ManGnome"
})
