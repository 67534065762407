import React from "react"

const SideBodyBagR = ({ colors = ["#724B2B", "#FFFFFF"] }) => {
  return (
    <g id="SideBodyBagR">
      <path
        opacity="0.3"
        fill="#21376C"
        d="M75.2044907,28.3653336 c-0.0683746-0.0683765-6.1538467,11.7606926-6.2222214,11.692318l-0.4102554,0.1367531 c-1.8461533,3.4188042-8.27351,5.3333359-8.27351,5.3333359c-3.4871788,2.8717957-4.7179527,8.2051315-2.1196556,11.8290672 c1.435894,1.9829102,6.0854721-1.094017,9.2991486-5.6752167c0.0683746-0.0683746,0.0683746-0.1367531,0.1367493-0.2051277 c0.2051315-0.615387,0.4786301-1.2307701,0.7521362-1.8461571c1.0256424-2.5299149,1.6410294-5.2649574,2.8717957-7.7264977 c0.6837616-1.6410294,5.4700851-9.5726547,6.4273529-12.170948 C77.8027878,29.1174698,75.2728653,28.4337082,75.2044907,28.3653336z"
      />
      <path
        fill={colors[0]}
        d="M75.0677414,27.8866997c-0.0683746-0.0683765-6.1538467,11.7606926-6.2222214,11.6923141 l-0.4102554,0.1367531c-1.8461533,3.4188042-8.8205223,5.2649612-8.8205223,5.2649612 c-3.4871788,2.8717957-4.1709404,8.27351-1.5726471,11.8974457c1.1623917,1.5726509,4.3760719-0.0683784,7.2478676-3.1453018 c0.7521362-1.3675232,1.5726547-2.7350426,2.2564163-4.1709404c1.094017-2.1196594,1.7777786-4.5811996,3.0769196-6.6324844 c0.1367569-0.2735062,0.2051315-0.4786339,0.3418808-0.7521362c0-0.2735062,0-0.4102554-0.0683746-0.5470085 c-0.2051315-0.3418808,5.4700928-9.6410313,6.4957352-12.4444523 C77.7344055,28.6388378,75.136116,27.9550762,75.0677414,27.8866997z"
      />
      <path
        opacity="0.64"
        fill="#21376C"
        d="M56.7429428,49.7670555 c-0.0683746,0.1367531,1.2307701,2.6666679,1.2307701,2.6666679s9.5726585-9.7094078,9.7777824-10.3931694 c0.2051315-0.6837578,0.6837616-1.2307663,0.6837616-1.2307663s0.1367493,0.8205109,0,0.9572639 s-3.8290634,4.4444466-3.8290634,4.4444466L58.110466,53.117485l-1.2991486-2.8034172L56.7429428,49.7670555z"
      />
      <path
        opacity="0.64"
        fill="#21376C"
        d="M57.9737129,50.7926979 c0,0-0.5470085,0.9572678-0.5470085,2.3931618c0.0683746,1.4358978-0.7521362,2.3931656-0.7521362,2.3931656v0.8888931 l1.094017-0.7521362c0,0,0.6837616-5.0598335,1.094017-5.0598335S57.9737129,50.7926979,57.9737129,50.7926979z"
      />
      <path
        fill={colors[0]}
        d="M57.7002106,50.7926979c0,0-0.5470085,0.9572678-0.5470085,2.3931618 c0.0683746,1.4358978-0.75214,2.3931656-0.75214,2.3931656v0.8888931l1.094017-0.7521362 c0,0,0.6837616-5.0598335,1.094017-5.0598335S57.7002106,50.7926979,57.7002106,50.7926979z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M76.9138947,28.775589 c-0.9572678,2.3247871-4.2393112,9.0940208-5.5384598,11.2136803c-0.8888931,3.2136765-2.5299149,6.4273567-4.4444504,8.8888931 c-1.9145355,2.4615402-4.5128212,6.2906036-7.7264977,6.9743614c-0.3418808,0.0683784-0.615387-0.1367493-0.6837616-0.4102554 c0,0,0,0,0-0.0683746l0,0c-0.0683784-0.1367493-0.0683784-0.3418808-0.0683784-0.3418808 c-0.0683746-0.2051277-0.2051277-0.8205109-0.2051277-0.7521362c-0.0683746,0.0683746-0.1367531,0.0683746-0.2051277,0.1367531 c-0.0683746,0.2051277-0.1367531,1.0940132-0.3418808,1.1623917c-0.0683746,0.1367493-0.1367531,0.2735023-0.1367531,0.4786301 c0.2051277,0.3418808,0.4102554,0.6837616,0.615387,1.0256424c1.094017,1.5726509,4.2393188,0,7.0427361-2.9401741 c0.8205109-1.5042763,1.709404-3.0085487,2.5299149-4.5811996c1.094017-2.1196594,1.7777786-4.4444466,3.0085526-6.4273567 c0.1367493-0.2735062,0.2735062-0.5470085,0.3418808-0.8205109c0,0,0-0.0683784,0.0683746-0.0683784 c0-0.1367493,0-0.2735023-0.0683746-0.3418808c-0.1367493-0.2735023,4.6495743-8.3418846,6.4957275-12.4444504 C77.5976562,29.1174698,77.1190262,28.775589,76.9138947,28.775589z"
      />
      <path
        fill="#21376C"
        d="M58.110466,49.6986809l1.0256424-0.2735062 C59.1361084,49.4251747,58.725853,48.9465408,58.110466,49.6986809z"
      />
      <path
        fill="#21376C"
        d="M57.7002106,50.7926979l2.0512848-0.3418808 C59.7514954,50.3824425,58.2472191,50.2456894,57.7002106,50.7926979z"
      />
      <polygon
        fill="#21376C"
        points="59.7514954,50.3824425 59.8882446,49.220047 60.1617508,49.2884216 60.0249977,50.1773109"
      />
      <g opacity="0.54" fill="#FFF">
        <path
          opacity="0.23"
          d="M65.4950867,43.3396988 c0.6837616-0.6153831,2.5982971-2.8717957,3.0085526-3.6923103c-0.4786301,0.615387-1.5726547,2.2564125-2.7350464,2.6666718 c-0.2735062,0.4102554-1.2991486,1.094017-1.5726471,1.5042725 C64.4010696,43.8183327,65.2899551,43.4080772,65.4950867,43.3396988z"
        />
        <path
          opacity="0.23"
          d="M62.965168,44.2969666 c0.1367531-0.2735062,2.1196556-1.4358978,2.2564125-1.6410255c-1.9829063,0.8888855-5.6068382,2.3247871-5.6068382,2.3247871 c-0.4102554,0.3418808-0.7521362,0.6837616-1.094017,1.094017C60.0249977,45.3909836,61.5292702,44.7756004,62.965168,44.2969666 z"
        />
        <path
          opacity="0.23"
          d="M74.1104736,29.8696079 c-0.0683746,0.2051277-0.2051239,0.4102554-0.3418808,0.6153851c0.6837616,0,1.2991486,0,1.9829102,0 c0.0683746-0.1367531,0.1367493-0.2735043,0.2051315-0.4102573 C75.3412476,30.006361,74.7258606,29.9379845,74.1104736,29.8696079z"
        />
        <path
          opacity="0.23"
          d="M74.657486,28.6388378 c-0.1367569,0.2735043-0.2735062,0.5470085-0.4786377,0.8888893c0.615387,0,1.2991486,0.2735043,1.9145355,0.2735043 c0.1367493-0.2735043,0.4786301-0.8205147,0.615387-1.0256424C76.1617584,28.7072144,75.2728653,28.7072144,74.657486,28.6388378 z"
        />
        <path
          opacity="0.23"
          d="M72.4694519,32.7414017 c-0.2735062,0.4786339-0.4786301,0.9572678-0.7521362,1.4358978c0.5470047,0.1367531,1.5726471,0.615387,2.1196594,0.6837616 c0.2051239-0.2735062,0.8888855-1.5726509,0.9572601-1.709404 C74.5207291,33.2200356,72.6745834,32.9465332,72.4694519,32.7414017z"
        />
        <path
          opacity="0.23"
          d="M71.5805588,34.5191841 c-0.2735062,0.5470085-0.615387,1.094017-0.8888931,1.6410255c0.5470123,0.0683784,1.709404,0.3418808,2.2564163,0.4102554 c0.2735062-0.4786301,0.5470123-1.0256386,0.8205109-1.5042725 C73.2899628,34.997818,72.1275711,34.6559372,71.5805588,34.5191841z"
        />
        <path
          opacity="0.23"
          d="M70.4181671,36.7072182 c-0.1367493,0.3418808-0.3418808,0.615387-0.4786301,0.8888893c0.4102554,0.1367531,1.6410294,0.7521362,2.0512848,0.8888893 c0.2734985-0.4102554,0.5470047-0.9572678,0.8205109-1.3675232 C72.2643204,37.1174736,70.9651794,36.7755966,70.4181671,36.7072182z"
        />
        <path
          opacity="0.23"
          d="M57.3583298,51.8867149 c-0.2051277-0.4102554-0.4786339-0.8888893-0.6837616-1.2991486h-0.0683746 c-0.2051277,1.2991486-0.0683746,2.6666718,0.3418808,3.897438c0.2051277-0.8205147,0.4102554-1.6410294,0.5470085-2.4615402 C57.4267044,52.023468,57.4267044,51.9550934,57.3583298,51.8867149z"
        />
        <path
          opacity="0.23"
          d="M57.9053383,49.9038086 c0,0.2735062,0,0.0683746,0.0683746,0.3418808c0,0.0683746,0,0.0683746,0.0683746,0.1367531 c0.1367531-0.3418808,0.4102554-0.4102554,0.7521362-0.3418808l0.8205147-0.2051277 C60.1617508,49.3568001,58.5890999,49.3568001,57.9053383,49.9038086z"
        />
        <path
          opacity="0.23"
          d="M64.4010696,48.2627792 c0.3418808,0.1367531,0.9572601,0.1367531,1.5042725,0.1367531 C66.110466,48.1260262,64.5378189,48.1260262,64.4010696,48.2627792z"
        />
        <path
          opacity="0.23"
          d="M63.2386742,49.3568001 c-1.2307701,1.2307701-3.6923103,3.0085487-4.7179527,3.5555573c-0.0683746,0.3418808-0.1367531,1.094017-0.2051277,1.4359016 c0.2051277,0,0.3418808,0.0683746,0.4786339,0.2735062c0.6837616,1.5726509,4.2393188-2.6666718,5.8119659-4.6495781 C64.1275635,49.8354301,63.6489296,49.5619278,63.2386742,49.3568001z"
        />
        <path
          opacity="0.23"
          d="M64.6745682,49.9038086 c0.3418808-0.4102554,0.5470123-0.6837616,0.615387-0.8205147c0.0683746-0.0683746,0.1367493-0.1367531,0.1367493-0.2051277 c-0.4786301-0.0683746-0.8888855-0.2051277-1.2991409-0.4102554c-0.27351,0.2051277-0.4786377,0.4786339-0.75214,0.7521362 C63.7856827,49.4251747,64.2643127,49.6986809,64.6745682,49.9038086z"
        />
      </g>
    </g>
  )
}

export default SideBodyBagR
