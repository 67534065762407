import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import BodyBase from "./BodyBase"
import Cape from "./Cape"
import LongSwordRight from "./LongSwordRight"
import LongSwordLeft from "./LongSwordLeft"
import GoldAxeLeft from "./GoldAxeLeft"
import GoldAxeRight from "./GoldAxeRight"
import LongBowLeft from "./LongBowLeft"
import LongBowRight from "./LongBowRight"
import TatteredCape from "./TatteredCape"
import MaulR from "./MaulR"
import MaulL from "./MaulL"
import FrontShieldR from "./FrontShieldR"
import FrontShieldL from "./FrontShieldL"
import BlueFlameRight from "./BlueFlameRight"
import BlueFlameLeft from "./BlueFlameLeft"
import FlameLeft from "./FlameLeft"
import FlameRight from "./FlameRight"
import BookSpellL from "./BookSpellL"
import SpellBookL from "./SpellBookL"
import DragonStaffR from "./DragonStaffR"

const CATEGORY = "shadow"
const CHARACTER = "WomanDwarf"
export const IDS = {
  BODY_BASE: "BodyBase",
  CAPE: "Cape",
  LONG_SWORD_RIGHT: "LongSwordRight",
  LONG_SWORD_LEFT: "LongSwordLeft",
  GOLD_AXE_LEFT: "GoldAxeLeft",
  GOLD_AXE_RIGHT: "GoldAxeRight",
  LONG_BOW_LEFT: "LongBowLeft",
  LONG_BOW_RIGHT: "LongBowRight",
  TATTERED_CAPE: "TatteredCape",
  MAUL_R: "MaulR",
  MAUL_L: "MaulL",
  FRONT_SHIELD_R: "FrontShieldR",
  FRONT_SHIELD_L: "FrontShieldL",
  BLUE_FLAME_RIGHT: "BlueFlameRight",
  BLUE_FLAME_LEFT: "BlueFlameLeft",
  FLAME_LEFT: "FlameLeft",
  FLAME_RIGHT: "FlameRight",
  BOOK_SPELL_L: "BookSpellL",
  SPELL_BOOK_L: "SpellBookL",
  DRAGON_STAFF_R: "DragonStaffR"
}

export const components = {
  [IDS.BODY_BASE]: BodyBase,
  [IDS.CAPE]: Cape,
  [IDS.LONG_SWORD_RIGHT]: LongSwordRight,
  [IDS.LONG_SWORD_LEFT]: LongSwordLeft,
  [IDS.GOLD_AXE_LEFT]: GoldAxeLeft,
  [IDS.GOLD_AXE_RIGHT]: GoldAxeRight,
  [IDS.LONG_BOW_LEFT]: LongBowLeft,
  [IDS.LONG_BOW_RIGHT]: LongBowRight,
  [IDS.TATTERED_CAPE]: TatteredCape,
  [IDS.MAUL_R]: MaulR,
  [IDS.MAUL_L]: MaulL,
  [IDS.FRONT_SHIELD_R]: FrontShieldR,
  [IDS.FRONT_SHIELD_L]: FrontShieldL,
  [IDS.BLUE_FLAME_RIGHT]: BlueFlameRight,
  [IDS.BLUE_FLAME_LEFT]: BlueFlameLeft,
  [IDS.FLAME_LEFT]: FlameLeft,
  [IDS.FLAME_RIGHT]: FlameRight,
  [IDS.BOOK_SPELL_L]: BookSpellL,
  [IDS.SPELL_BOOK_L]: SpellBookL,
  [IDS.DRAGON_STAFF_R]: DragonStaffR
}

export const Group = ({ props }) => (
  <g id="shadow">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.BODY_BASE,
    name: "Body Base",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BODY_BASE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.CAPE,
    name: "Cape",
    defaultColors: [],
    colorable: false,
    component: components[IDS.CAPE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_SWORD_RIGHT,
    name: "Long Sword Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_SWORD_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_SWORD_LEFT,
    name: "Long Sword Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_SWORD_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_LEFT,
    name: "Gold Axe Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.GOLD_AXE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_RIGHT,
    name: "Gold Axe Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.GOLD_AXE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_BOW_LEFT,
    name: "Long Bow Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_BOW_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_BOW_RIGHT,
    name: "Long Bow Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_BOW_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.TATTERED_CAPE,
    name: "Tattered Cape",
    defaultColors: [],
    colorable: false,
    component: components[IDS.TATTERED_CAPE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MAUL_R,
    name: "Maul R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MAUL_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MAUL_L,
    name: "Maul L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MAUL_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FRONT_SHIELD_R,
    name: "Front Shield R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.FRONT_SHIELD_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FRONT_SHIELD_L,
    name: "Front Shield L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.FRONT_SHIELD_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BLUE_FLAME_RIGHT,
    name: "Blue Flame Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BLUE_FLAME_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BLUE_FLAME_LEFT,
    name: "Blue Flame Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BLUE_FLAME_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FLAME_LEFT,
    name: "Flame Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.FLAME_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FLAME_RIGHT,
    name: "Flame Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.FLAME_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BOOK_SPELL_L,
    name: "Book Spell L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BOOK_SPELL_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SPELL_BOOK_L,
    name: "Spell Book L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.SPELL_BOOK_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DRAGON_STAFF_R,
    name: "Dragon Staff R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.DRAGON_STAFF_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
