import React from "react"

const HipDaggerLeft = ({
  colors = [
    "#66451E",
    "#895A24",
    "#9E8835",
    "#9AB8DB",
    "#6B92C0",
    "#57779C",
    "#E2E4E5",
    "#A0A2A5",
    "#EED793"
  ]
}) => {
  return (
    <g id="HipDaggerLeft">
      <polygon
        fill={colors[0]}
        points="60.6952705,84.4633713 58.7558975,79.2917023 56.6318169,79.6611023 58.109436,85.2945328"
      />
      <polygon
        fill={colors[1]}
        points="62.4499474,83.539856 57.7400322,84.0939636 60.7876205,95.8225708 61.5264359,96.1919785 62.1728897,95.7302246"
      />
      <polygon
        fill={colors[0]}
        points="61.341732,95.5455246 58.6635437,84.0016174 57.7400322,84.0939636 60.9723244,95.4531708 61.0646782,95.8225708"
      />
      <polygon
        fill={colors[2]}
        points="58.9405975,84.2786636 57.7400322,83.4475098 59.2176514,81.6928329 58.9405975,79.4764023 58.8482475,76.4288101 58.3864899,75.5976486 58.8482475,74.6741409 59.679409,74.5817795 60.3258667,75.4129486 60.0488129,76.2441101 60.6029205,79.2917023 60.6029205,81.6004868 62.4499474,82.9857559 61.434082,84.0939636"
      />
      <polygon
        fill={colors[3]}
        points="59.679409,74.6741409 59.4947052,75.043541 59.1253014,75.043541 58.8482475,74.7664871"
      />
      <polygon
        fill={colors[4]}
        points="58.8482475,74.7664871 59.0329514,75.1358948 58.7558975,75.5976486 58.3864899,75.5976486"
      />
      <polygon
        fill={colors[3]}
        points="59.679409,74.7664871 59.5870552,75.043541 59.864109,75.5052948 60.2335167,75.4129486"
      />
      <polygon
        fill={colors[5]}
        points="59.9564629,76.2441101 59.679409,75.9670486 59.1253014,75.9670486 58.9405975,76.3364563"
      />
      <polygon
        fill={colors[4]}
        points="60.2335167,75.5052948 59.864109,75.5976486 59.771759,75.8747025 59.9564629,76.1517563"
      />
      <polygon
        fill={colors[5]}
        points="58.7558975,75.6900024 59.0329514,75.9670486 58.8482475,76.3364563 58.4788399,75.6900024"
      />
      <polygon
        fill={colors[4]}
        points="59.864109,75.5052948 59.4947052,75.1358948 59.1253014,75.1358948 58.8482475,75.5976486 59.1253014,75.8747025 59.679409,75.8747025"
      />
      <polygon
        fill={colors[6]}
        points="58.4788399,83.4475098 60.0488129,82.7086945 61.7111359,83.0781021 61.341732,95.5455246 61.341732,95.5455246"
      />
      <polygon
        fill={colors[7]}
        points="60.0488129,82.7086945 61.341732,95.4531708 58.4788399,83.3551483"
      />
      <path
        fill={colors[8]}
        d="M59.0329514,77.9987793v-1.4776154c0.09235-0.0923462,0.27705-0.1847,0.4617538-0.2770538h0.09235 c0.09235,0,0.2770538,0,0.3694077,0.0923462l0.4617577,2.3087845 C59.9564629,78.4605408,59.4947052,78.1834793,59.0329514,77.9987793z"
      />
      <path
        fill={colors[8]}
        d="M60.5105667,79.8458099v1.662323l-0.6464577-0.4617538l-0.5541077,0.5541077l-0.27705-2.0317307 V78.368187C59.5870552,78.6452408,60.1411667,79.2917023,60.5105667,79.8458099z"
      />
    </g>
  )
}

export default HipDaggerLeft
