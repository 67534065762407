import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const ShortswordLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("ShortswordLeft") ? (
    <g id="ShortswordLeft">
      <path
        id="S_x24_shortswordLeft"
        d="M62.6727867,93.4781418c-0.6634827,0-1.3269615-0.1326904-1.9240952-0.3317337 c-2.8529663-1.0615692-4.4453201-4.1799316-3.5164452-7.0992432v-0.0663452 c0.066349-0.2653961,0.1990433-0.5307846,0.3317413-0.7961731c-1.9240952-1.3933105-2.8529663-3.9145355-2.1231384-6.3030624 V78.815239c0.2653923-0.9288788,0.7961769-1.7913971,1.5260048-2.4548798 c0.8625221-0.7961731,1.9240913-1.3269577,3.1183586-1.4596558l5.6395798-20.1697998l1.6587067-2.4548798l2.7202682-2.1231384 l7.4309769,2.4548798l0.8625259,3.2510529l-0.0663528,2.653923l-6.5684509,20.5015335 c1.1279144,1.0615616,1.7913895,2.521225,1.7913895,4.179924c0,1.5923538-0.663475,3.0520096-1.7913895,4.1135788 c-0.9288788,0.9288712-2.1894913,1.3933029-3.516449,1.5260086c-0.0663452,0.1990433-0.0663452,0.4644318-0.1326981,0.663475 v0.0663528c-0.2653885,0.9288712-0.7961731,1.7913895-1.526001,2.4548721 C65.459404,92.9473572,64.0660934,93.4781418,62.6727867,93.4781418z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(ShortswordLeft, {
  character: "ManGnome"
})
