import React from "react"

const HairOne = ({ colors = ["#EAD8CC"] }) => {
  return (
    <g id="HairOne">
      <path
        id="hair1"
        fill={colors[0]}
        d="M54.7,40.7c0.2,0.5,0.3,1,0.2,1.5c0.2,0,1.5,3.2,1.8,3.4 c0-0.2,0.2-0.8,0.2-0.8c-0.1-0.4-0.4-1.4-0.4-1.8c0.1,0,0.2,0.1,0.3,0.2c0-0.2,0-0.5,0-0.8l0.3-0.5c0-0.5-1.2-4.8-1.5-4.4 c0-0.1-1.5-2.5-1.9-2.8c-1.7-0.9-3-1-2.8-1c-3.9-1.4-12.4,1.5-11,9.3c0.1,0,0.1-0.1,0.3-0.2c0,0.2-0.2,1.9-0.3,2.5 c0.5-0.7,1.3-2.3,1.7-2.2c1-1.4,0.8-1.5,2.2-2.5c-0.6,0.6-1.3,2.2-1.6,2.4h0.1l0,0c1.5-0.8,4.8-0.8,8-3.1c-0.6,1-1.3,1.2-2.6,1.9 c3.5-1,4.7-1.9,4.9-2.2C53.7,39.5,54.4,40.1,54.7,40.7z"
      />
    </g>
  )
}

export default HairOne
