import React from "react"

const SolidColor = ({ colors = ["#7F7F7F"] }) => {
  return (
    <g id="SolidColor">
      <rect
        x="25.646"
        y="24.002"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={colors[0]}
        width="85.561"
        height="93.768"
      />
    </g>
  )
}

export default SolidColor
