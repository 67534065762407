import React from "react"
import { withSkinColor } from "../../builderConnector"

const WideHorns = ({ skinColor, color = [] }) => {
  return (
    <g id="WideHorns">
      <path
        id="K_x24_wideHorns"
        fill={skinColor}
        d="M79.2,7.7L79.2,7.7c-0.2-0.4-0.4-0.7-0.6-1c-0.2-0.2-0.5-0.4-0.8-0.5 c-2.2-0.4-4.3,3.5-5.4,4.6c-0.4,0.5-0.8,0.2-1.1,0.3c-0.2,0-1.1,0.1-2.1,0.2c-0.9-0.2-1.8-0.2-2-0.2l0,0c0,0,0,0-0.1,0 s-0.3,0-0.4,0c-0.2,0-0.4-0.1-0.6-0.3c-0.9-1.1-3-4.6-5.1-4.6c-0.1,0-0.3,0-0.4,0c-0.7,0.4-1.1,0.9-1.4,1.5l0,0 c-0.4,1.3,0.4,2.8,0.4,2.8s-0.3-2.4,0.6-2.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.5,0.4,1.1,1.3,2,2.8 c0.4,0.7,1.6,2.4,6.5,2.4s6.2-2,6.5-2.4c0.9-1.5,1.5-2.4,2-2.8c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.2,0.3-0.2 c0.9-0.3,0.6,2.1,0.6,2.1S79.6,9,79.2,7.7z"
      />
    </g>
  )
}

export default withSkinColor(WideHorns, { character: "ManMixed" })
