import React from "react"

const NobelCape = ({
  colors = ["#8D4D3A", "#D8A16C", "#SVGID_", "#FFFFFF", "#AC7B56", "#F9BE7C"]
}) => {
  return (
    <g id="NobelCape">
      <path
        fill={colors[0]}
        d="M41.3001213,70.7987061c0,0,0,2.7151642,2.0006485,0.1428986 c2.0006523-2.5722656,0.3334427-4.2394714,0.3334427-4.2394714s-2.715168-1.6195755-2.5246277-0.7621536 c0.2381706,1.1908646-0.6192513-0.0952682-0.6192513-0.0952682s-1.2861328-1.3814011-1.5719376-0.4763412 c-0.0952682,0.1905365-0.0476341,0.4763412,0.0952682,0.762146c0.2381744,0.4287109-1.3337669-0.2381668-1.3337669-0.2381668 s-1.4766693,0.381073-1.8577461,1.1432266c-0.3334427,0.6192474,0.3810768,1.5719376,0.7145195,1.9053802 C37.8227997,70.2747269,41.3001213,70.7987061,41.3001213,70.7987061z"
      />
      <path
        opacity="0.35"
        fill="#21376C"
        d="M42.4909821,70.7987061 c0,0,0.9526939-1.0479584,0.7145195-1.9053802c-0.3334427-1.1908646-1.2384987-1.4766693-2.0006523-2.2864609 c-0.1905365-0.1905365-0.2858047-0.2381744-0.2858047-0.2381744c0.3810768,0.0952759,0.4287109,0,0.3810768-0.381073 c0.4763451,0.5716171,2.1435547,0.9050598,2.4769936,2.7151718 C43.7771149,69.1791306,43.824749,70.4176254,42.4909821,70.7987061z"
      />
      <path
        opacity="0.35"
        fill="#21376C"
        d="M37.6798973,65.8923492 c0,0,3.4296837,1.7624741,4.2394714,2.3340912c0.7621536,0.5716171,1.5243073,0.9050598,0.5716133,2.5246277 c0.7621536-1.0955887,1.3337669-2.0006485-3.3344154-4.8110886 C39.0136604,66.1305237,37.6798973,65.8923492,37.6798973,65.8923492z"
      />
      <circle fill={colors[1]} cx="42.205" cy="71.085" r="0.715" />
      <circle fill="url(#SVGID_3_)" cx="42.205" cy="70.942" r="0.715" />
      <radialGradient
        id="SVGID_3_"
        cx="42.201"
        cy="708.97"
        r="0.728"
        gradientTransform="translate(0 -638.013)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.662" stopColor={colors[4]} />
        <stop offset="1" stopColor={colors[5]} />
      </radialGradient>
      <g>
        <path
          fill={colors[0]}
          d="M54.3519821,70.6557999c0,0,0,2.7151718-2.0006523,0.1429062s-0.3334389-4.1918411-0.3334389-4.1918411 s2.715168-1.6195755,2.5246277-0.7621536c-0.2381706,1.1908646,0.6192474-0.0952682,0.6192474-0.0952682 s1.2861328-1.3814011,1.5719414-0.4763412c0.0952682,0.1905365,0.0476341,0.4763412-0.0952682,0.762146 c-0.2381706,0.4287186,1.3337669-0.2381668,1.3337669-0.2381668s1.4766731,0.381073,1.8577499,1.1432266 c0.3334389,0.6192474-0.3810768,1.5719376-0.7145195,1.9053802 C57.8293037,70.1318207,54.3519821,70.6557999,54.3519821,70.6557999z"
        />
        <path
          opacity="0.35"
          fill="#21376C"
          d="M53.1611176,70.6557999 c0,0-0.9526901-1.0479584-0.7145195-1.9053802c0.3334427-1.1908646,1.2384987-1.4766693,2.0006523-2.2864532 c0.1905365-0.1905441,0.2858047-0.2381744,0.2858047-0.2381744c-0.381073,0.0952682-0.4287109,0-0.381073-0.3810806 c-0.4763489,0.5716171-2.1435547,0.9050598-2.4769974,2.7151718 C51.8749847,69.0362244,51.8273506,70.2747269,53.1611176,70.6557999z"
        />
        <path
          opacity="0.35"
          fill="#21376C"
          d="M57.9722023,65.7494431 c0,0-3.4296837,1.7624817-4.2394714,2.3340912c-0.7621536,0.5716171-1.5243073,0.9050598-0.5716171,2.5246353 c-0.7621498-1.0955963-1.3337631-2.0006561,3.3344154-4.8110886 C56.6384392,65.9876175,57.9722023,65.7494431,57.9722023,65.7494431z"
        />
        <circle fill={colors[1]} cx="53.447" cy="70.942" r="0.715" />
        <circle fill="url(#SVGID_4_)" cx="53.447" cy="70.799" r="0.715" />
        <radialGradient
          id="SVGID_4_"
          cx="-1041.11"
          cy="708.821"
          r="0.728"
          gradientTransform="matrix(-1 0 0 1 -987.681 -638.013)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.662" stopColor={colors[4]} />
          <stop offset="1" stopColor={colors[5]} />
        </radialGradient>
      </g>
    </g>
  )
}

export default NobelCape
