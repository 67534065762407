import React from "react"

const Breeches = ({ colors = ["#77634F"] }) => {
  return (
    <g id="Breeches">
      <path
        fill={colors[0]}
        d="M62.6999893,96.9978943c-0.1731606-1.5446014-2.2215042-9.0837479-2.4608727-11.8624649 c-0.1718102-1.9944916-0.4686356-5.2918472-0.9186363-6.998436c-0.0046425-0.0175629-5.1732216,0.7149048-10.426857,0.7222061 c-5.1061974,0.0070953-10.3016472-0.7114563-10.30616-0.6943817c-0.4589539,1.7405472-0.7440453,4.60009-0.9588661,6.6487503 c-0.3129272,2.9842453-1.9409332,10.8360977-2.0920753,12.1843262c-0.2423096,2.1612625-0.5782471,2.2893143,0.5297241,4.3188248 c0.762207,1.3961487,2.2538261,2.2049026,3.7263603,2.4369431c2.5442505,0.4009476,4.9249382-0.5518341,6.1573601-3.0907516 c0.5942497-1.2242584,0.7956657-4.9238434,1.1332054-7.7224274c0.3352699-3.379509,3.3392792-2.5480423,3.3392792,0.2816238 c0.2794838,2.8058395,0.4248238,6.2592773,1.0054092,7.4553757c1.2324219,2.5389175,3.8178711,3.3658295,6.3621216,2.9648743 c1.4725342-0.2320328,2.9291382-0.9311218,3.6913452-2.3272629 C62.5892982,99.2855759,62.9422989,99.1591568,62.6999893,96.9978943z"
      />
      <g fill="#21376C">
        <path
          opacity="0.42"
          d="M45.2458992,99.8004303 c0,0-0.1206932,1.5441132-0.4425545,1.7527771c0.5632515-0.5425262,0.7107697-0.6190414,0.7107697-0.6190414 s-0.4693756,1.6484451-2.4239922,2.0240402C44.0959282,101.8314209,45.2458992,99.8004303,45.2458992,99.8004303z"
        />
        <path
          opacity="0.42"
          d="M38.9361458,103.3824921 c-0.8717003-0.2921295-1.8856163-2.776619-1.9780846-3.7768173c-0.1508789,0.5425186,0.1341057,1.8918839,0.1341057,1.8918839 c-0.1796265-0.402977-0.6973572-0.9876709-1.126503-2.8239136 C35.7858543,98.6657333,34.8726883,101.7340469,38.9361458,103.3824921z"
        />
        <path
          opacity="0.42"
          d="M45.3531876,97.414711 c0,0,0.2816238,1.2519836,0.2615089,1.8153763C45.8158569,98.0615692,45.3531876,97.414711,45.3531876,97.414711z"
        />
        <path
          opacity="0.42"
          d="M36.8038368,96.9139175 c0,0-0.502903,1.1059189-0.2816277,2.5665588C36.6227913,98.3119659,36.6026764,97.99897,36.8038368,96.9139175z"
        />
        <g opacity="0.42">
          <path d="M40.1833496,103.4868011l-0.4425659-1.7110519l0.1474609,1.2082977l-0.6503296-1.5838623 l-0.1207275,0.8138046c0,0.166893,0.2816162,1.314537,0.2816162,1.314537l0.7219849,0.1182632l0.1229248,0.1530304 L40.1833496,103.4868011z" />
          <path d="M42.4155273,103.3245392l-0.1401367-1.6530685l-0.7241211,1.9196701l0.8031006-0.0189285 c-0.0096436,0.0880661,0.0045776,0.1377029,0.0819702,0.081543c0.4023438-0.2921143,0.6035156-1.7945023,0.6035156-1.7945023 S42.590332,102.7958221,42.4155273,103.3245392z" />
        </g>
        <g>
          <path
            opacity="0.42"
            d="M52.2444,99.6960983 c0,0,0.1206932,1.5441132,0.4425545,1.7527771c-0.5632515-0.5425262-0.7107697-0.6190338-0.7107697-0.6190338 s0.4693756,1.6484375,2.4239922,2.0240326C53.394371,101.7270889,52.2444,99.6960983,52.2444,99.6960983z"
          />
          <path
            opacity="0.42"
            d="M58.5541534,103.2781601 c0.8717003-0.2921295,1.8856163-2.776619,1.9780846-3.7768173c0.1508789,0.5425186-0.1341057,1.8918839-0.1341057,1.8918839 c0.1796265-0.402977,0.6973572-0.9876709,1.126503-2.8239136 C61.7044449,98.5614014,62.6176109,101.629715,58.5541534,103.2781601z"
          />
          <path
            opacity="0.42"
            d="M60.6864624,96.8095856 c0,0,0.502903,1.1059189,0.2816277,2.5665588C60.8675079,98.207634,60.8876228,97.8946381,60.6864624,96.8095856z"
          />
          <g opacity="0.42">
            <path d="M57.3069496,103.3824692l0.4425659-1.7110519l-0.1474609,1.2082977l0.6503296-1.5838623 l0.1207275,0.8138046c0,0.166893-0.2816162,1.314537-0.2816162,1.314537l-0.7219849,0.1182632l-0.1229248,0.1530304 L57.3069496,103.3824692z" />
            <path d="M55.0747719,103.2202072l0.1401367-1.6530685l0.7241211,1.9196701l-0.8031006-0.0189285 c0.0096436,0.0880661-0.0045776,0.1377029-0.0819702,0.081543c-0.4023438-0.2921143-0.6035156-1.7945023-0.6035156-1.7945023 S54.8999672,102.6914902,55.0747719,103.2202072z" />
          </g>
        </g>
      </g>
    </g>
  )
}

export default Breeches
