import React from "react"

const LeafClasp = ({ colors = ["#FCDF54", "#421115"] }) => {
  return (
    <g id="LeafClasp">
      <path
        fill={colors[0]}
        d="M67.2708511,63.5437164c-0.1999969,0-0.3000031-0.0999985-0.5-0.0999985 c-0.6999969-0.2999992-0.8000031-2.4000015-0.9000015-2.5999985V60.743721l0,0c0,0,0.9000015-1.0999985,1.4000015-1.2000008 c0.1999969-0.0999985,0.4000015-0.0999985,0.6999969,0c0.5999985,0.0999985,1.4000015,0.2999992,1.4000015,0.2999992 l0.3000031,0.0999985l-0.9000015,0.6999969c0.0999985,0,0.0999985,0,0.1999969,0 c0.4000015,0.0999985,0.8000031,0.0999985,1.0999985,0.2000008c0.6999969,0.2000008,2,0.9000015,2,1l0.3000031,0.2000008 l-0.3000031,0.0999985c-0.0999985,0-2.4000015,0.7999992-3.1999969,0.7000008h-0.0999985 c-0.4000015,0-0.6999969-0.0999985-0.9000015-0.2000008l0.3000031,0.7000008l-0.0999985,0.0999985 C67.9708557,63.4437141,67.6708527,63.5437164,67.2708511,63.5437164z"
      />
      <path
        opacity="0.63"
        fill={colors[1]}
        d="M67.2708511,63.5437164 c-0.1999969,0-0.3000031-0.0999985-0.5-0.0999985c-0.6999969-0.2999992-0.8000031-2.4000015-0.9000015-2.5999985V60.743721l0,0 c0,0,0.9000015-1.0999985,1.4000015-1.2000008c0.1999969-0.0999985,0.4000015-0.0999985,0.6999969,0 c0.5999985,0.0999985,1.4000015,0.2999992,1.4000015,0.2999992l0.3000031,0.0999985l-0.9000015,0.6999969 c0.0999985,0,0.0999985,0,0.1999969,0c0.4000015,0.0999985,0.8000031,0.0999985,1.0999985,0.2000008 c0.6999969,0.2000008,2,0.9000015,2,1l0.3000031,0.2000008l-0.3000031,0.0999985 c-0.0999985,0-2.4000015,0.7999992-3.1999969,0.7000008h-0.0999985c-0.4000015,0-0.6999969-0.0999985-0.9000015-0.2000008 l0.3000031,0.7000008l-0.0999985,0.0999985C67.9708557,63.4437141,67.6708527,63.5437164,67.2708511,63.5437164z"
      />
      <path
        fill={colors[0]}
        d="M66.8708572,63.2437134c-0.5999985-0.2999992-0.8000031-2.5-0.8000031-2.5 s0.8000031-1,1.4000015-1.2000008c0.5-0.2000008,2,0.2999992,2,0.2999992l-1.0999985,0.8000031c0,0,1,0,1.6999969,0.2999992 c0.6999969,0.2000008,2,1,2,1s-2.4000015,0.7999992-3.0999985,0.7000008c-0.8000031,0-1.4000015-0.2000008-1.4000015-0.2000008 l0.3000031,0.9000015C67.9708557,63.2437134,67.4708557,63.5437164,66.8708572,63.2437134z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M67.3708572,63.2437134 c-0.0999985,0-0.3000031-0.0999985-0.4000015-0.0999985c-0.4000015-0.2000008-0.5999985-1.5-0.6999969-2.2999992 c0.1999969-0.2999992,0.9000015-1,1.3000031-1.0999985c0.0999985,0,0.3000031,0,0.5999985,0 c0.4000015,0,0.8000031,0.0999985,1,0.2000008l-1.1999969,0.9000015h0.4000015c0,0,0.3000031,0,0.5999985,0.0999985 c0.4000015,0.0999985,0.8000031,0.0999985,1,0.2000008c0.5,0.2000008,1.1999969,0.5999985,1.6999969,0.7999992 c-0.8000031,0.2999992-2.1999969,0.7000008-2.6999969,0.5999985h-0.0999985 c-0.5999985-0.0999985-1.1999969-0.2999992-1.1999969-0.2999992l-0.3000031-0.0999985l0.5,1.0999985 C67.7708511,63.2437134,67.5708542,63.2437134,67.3708572,63.2437134z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.2708511,60.8437157l2.8000031-0.9000015 C69.1708527,59.9437141,67.4708557,60.1437149,66.2708511,60.8437157z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.2708511,60.8437157 c0,0,0.9000015,1.0999985,5.4000015,1.0999985C70.1708527,61.7437134,66.8708572,61.3437157,66.2708511,60.8437157z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.2708511,60.9437141c0,0,0.3000031,1.7000008,1.5,2.2999992 C66.7708511,62.2437134,66.2708511,60.9437141,66.2708511,60.9437141z"
      />
      <path
        fill={colors[0]}
        d="M66.3708572,61.0437164l0.1999969,0.4000015l0.0999985-0.0999985 c0,0,0.1999969,0.2999992,0.3000031,0.4000015c0.0999985-0.2000008,0.1999969-0.2999992,0.1999969-0.2999992 S66.4708557,61.0437164,66.3708572,61.0437164C66.2708511,60.9437141,66.3708572,61.0437164,66.3708572,61.0437164z"
      />
      <path
        fill={colors[0]}
        d="M66.6708527,61.5437164L66.6708527,61.5437164l0.5999985,0.5999985h-0.3000031 C66.9708557,62.1437149,66.7708511,61.8437157,66.6708527,61.5437164z"
      />
      <path
        fill={colors[0]}
        d="M67.0708542,62.2437134h0.3000031l0.4000015,0.9000015 C67.6708527,63.0437164,67.2708511,62.5437164,67.0708542,62.2437134z"
      />
      <path
        fill={colors[0]}
        d="M67.0708542,61.7437134c0,0,0.1999969,0.2999992,0.5,0.4000015 c0.3000031,0.0999985,0.6999969,0.2000008,0.6999969,0.2000008l-0.0999985-0.7000008c0,0-0.1999969-0.0999985-0.4000015-0.0999985 c-0.0999985,0-0.0999985,0.2000008-0.0999985,0.4000015c-0.0999985-0.0999985,0-0.4000015-0.0999985-0.4000015 c-0.1999969-0.0999985-0.4000015-0.0999985-0.4000015-0.0999985 C67.1708527,61.4437141,67.0708542,61.7437134,67.0708542,61.7437134z"
      />
      <path
        fill={colors[0]}
        d="M68.2708511,61.7437134l0.0999985,0.7000008c0,0,0.5,0.0999985,0.6999969,0.0999985 s0.1999969,0,0.1999969,0s-0.3000031-0.4000015-0.4000015-0.7000008 C68.5708542,61.7437134,68.2708511,61.7437134,68.2708511,61.7437134z"
      />
      <path
        fill={colors[0]}
        d="M68.9708557,61.8437157c0,0,0.1999969,0.5,0.4000015,0.5999985 c0.1999969,0,1.0999985-0.2000008,1.0999985-0.2000008l-0.4000015-0.2999992 C69.9708557,61.9437141,69.0708542,61.8437157,68.9708557,61.8437157z"
      />
      <path
        fill={colors[0]}
        d="M66.4708557,60.6437149c0,0,0.8000031-0.4000015,1.0999985-0.4000015c0-0.2000008,0-0.4000015,0-0.4000015 S66.9708557,60.1437149,66.4708557,60.6437149z"
      />
      <path
        fill={colors[0]}
        d="M67.6708527,59.8437157v0.4000015l0.6999969-0.0999985v-0.0999985l0.0999985-0.0999985 C68.4708557,59.8437157,68.0708542,59.7437134,67.6708527,59.8437157z"
      />
      <polygon
        fill={colors[0]}
        points="70.2708511,61.9437141 70.5708542,62.2437134 71.2708511,61.9437141"
      />
      <polygon
        fill={colors[0]}
        points="68.5708542,59.9437141 68.4708557,60.0437164 68.8708572,59.9437141"
      />
    </g>
  )
}

export default LeafClasp
