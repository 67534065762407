import React from "react"

const FrontShieldL = ({ colors = ["#A8A6A3", "#262261", "#FFFFFF"] }) => {
  return (
    <g id="FrontShieldL">
      <path
        fill={colors[0]}
        d="M102.1,59.5c-1.4,11.4-5.5,17.2-11.4,18.8s-9.8-10.6-8.4-22S89.5,34.7,95.8,37 C102.2,39.3,103.6,48.1,102.1,59.5z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M96.3,38.2c-0.5-0.2-1-0.3-1.5-0.3c-4.6,0-9.4,8.4-10.7,18.7 c-1,7.7,0.6,16.1,3.6,19.6c0.9,1,1.8,1.5,2.7,1.5c0.3,0,0.5,0,0.8-0.1c5.8-1.6,9.4-7.6,10.7-17.9c0-0.1,0-0.1,0-0.2 c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-1.3,9.6-4.6,15.1-9.9,16.6c-0.2,0-0.4,0.1-0.5,0.1c-0.6,0-1.3-0.4-2-1.1 c-2.8-3.2-4.3-11.4-3.4-18.8C86.8,46.1,91.6,39,95.2,39c0.4,0,0.7,0.1,1.1,0.2c5.2,1.8,6,9.4,5.5,16.6c-0.3,0-0.6-0.1-0.7,0 c-0.9,1.4-2.3,2.4-4,2.4c-0.7,0-0.1,0.1,0.3,0.1c1.8-0.1,3.4-0.8,4.4-2.1c-0.1,0.7-0.1,1.3-0.2,2c0.2,0,0.3-0.1,0.4-0.2 c0,0,0,0,0,0c0.1,0.1,0.1,0.3,0.1,0.4C103,50.2,102.6,40.5,96.3,38.2z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M97.6,60.6c0.6-1.7,0.9-3.6,0.7-5.4c-0.1-0.5-0.2-0.9-0.4-1.2 c0.2,0,0.5,0,0.5-0.1c0.8-1.6,1.6-3.3,2.4-4.9c0,0-0.9-0.2-1,0c-0.7,1.5-1.5,3-2.2,4.6c-0.4-0.4-0.9-0.7-1.5-0.8 c0.1-2.1,0.2-4.2,0.2-6.3c0-1.1,0.6-4.5-0.9-4.8c-0.1,0-1.1,0-0.7,0.1c1.2,0.2,0.9,6.3,0.5,10.8c-0.3,0-0.5,0-0.8,0 c0-0.2,0-0.3,0-0.5c0-0.1-0.9-0.1-1,0c-0.7,0.3-1.1,1.2-1.4,2.2c-0.7-1.3-1.2-2.6-1.6-4c0-0.2-1,0-1,0c0.5,1.7,1.2,3.3,2,4.8 c0,0.1,0.2,0.1,0.4,0.1c-0.1,0.7-0.2,1.4-0.3,1.9c-0.1,0.5-0.1,1.1-0.1,1.7c-1-0.2-2.1-0.7-3-1.2c-0.3-0.1-0.9,0.1-1,0 c1.3,0.6,2.6,1.2,3.9,1.3c0,0.6,0.1,1.2,0.2,1.8c-0.9,3-2.1,5.8-3.8,8.5c0-0.1,0.9,0.2,1,0c1.4-2.2,2.5-4.5,3.3-7 c0.4,0.7,1,1.6,1.8,2c-0.5,3.2-1.2,6.4-1.9,9.6c0,0,1,0.2,1,0c0.8-3.1,1.4-6.2,1.9-9.4c0.1,0,0.2,0,0.3,0c0.9-0.3,1.6-1.7,2-2.7 c0.4,1.7,0.7,3.3,0.8,5c0,0.1,1,0.1,1,0c-0.2-2-0.6-4-1-6C98,60.6,97.8,60.6,97.6,60.6z M93.6,62.9c-0.3-0.4-0.5-0.8-0.6-1.2 c-0.1-0.3-0.2-0.7-0.3-1c0-0.1,0.1-0.2,0.1-0.2c0,0-0.1,0-0.1,0c-0.1-0.4-0.1-0.9-0.2-1.3c0,0,0-0.1,0-0.1c0.6,0,1.2-0.1,1.8-0.2 c0.1,0-0.7-0.1-0.9-0.1c-0.3,0.1-0.6,0.1-1,0.1c0-1.4,0.2-4.1,1.1-5.7c0,0.1,0,0.2,0,0.3c0,0.1,0.9,0.1,1,0 c0.3-0.2,0.5-0.3,0.8-0.5c-0.2,2.1-0.4,3.9-0.4,4.4c-0.2,2-0.5,4-0.8,6C93.9,63.3,93.7,63.1,93.6,62.9z M95,63.7 c0.4-2.7,0.8-5.4,1-8.1c0.1-0.8,0.1-1.5,0.1-2.3c0.5,0.3,0.9,0.6,1.1,1.3c0.3,0.9,0.2,2.1,0.1,3.1C97.2,59.1,96.5,62.8,95,63.7z"
      />
      <g opacity="0.43" fill="#FFF">
        <path
          opacity="0.35"
          d="M95.4,64.9c0-0.3,0-0.6,0-0.9c-0.1,0-0.3,0.1-0.4,0.1 c-0.1,0-0.1,0-0.2-0.1c-0.4,2.6-1.1,5.2-1.9,7.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.4-0.2,0.9-0.4,1.3c0.8-0.4,1.5-0.9,2.3-1.4 c-0.2-0.3-1-1.1-0.9-1.2c0.6-1,1.3-2,2-3C95.4,66.8,95.4,65.7,95.4,64.9z"
        />
        <path
          opacity="0.35"
          d="M91.6,69.9c-0.3,0.6-0.6,1.2-0.9,1.8c0,0.3,0.1,0.5,0.1,0.8 c0,0.2,0,0.9,0,1.4c0.1,0.1,0.3,0.1,0.4,0.1c0.2-0.1,0.4-0.2,0.6-0.4c0-0.2,0.1-0.4,0.1-0.7C91.8,72,91.7,71,91.6,69.9 C91.7,69.9,91.7,69.9,91.6,69.9z"
        />
        <path
          opacity="0.35"
          d="M90.7,62.2c-0.9,0.6-1.8,1.2-2.7,1.8c0.1,0-0.6-1.3-0.5-1.4 c0.9-0.7,1.9-1.4,2.9-2c0-0.2,0-0.3,0-0.5c-1.3-0.1-2.7-0.2-3.9-0.5c0.1,0,0.3-1.2,0.5-1.2c0.2-0.1,0.4-0.1,0.5,0.1 c0.9,0.5,1.9,0.8,2.9,0.9c0-0.8,0.1-1.6,0.2-2.5c-1.2-0.2-2.4-0.5-3.6-0.6c-0.1,0-0.1-1.4-0.1-1.4c1.3,0.2,2.6,0.7,3.9,1.1 c0.1-0.3,0.2-0.7,0.2-1c-0.2-0.2-0.3-0.4-0.5-0.6c-0.3-0.5-0.7-2.1-1.1-2.4c0,0,0.1-0.3,0.3-0.6c0,0,0,0,0,0c0,0,0,0,0,0 c0.2-0.2,0.3-0.4,0.3-0.5l0.2,1c0.3,0.3,0.7,0.6,1,1c0.2,0.1,0.3,0.2,0.5,0.2c0-0.1,0.1-0.1,0.1-0.2c-0.1-0.1-0.1-0.1-0.1-0.2 c0-1.1,0-2.2,0.2-3.4c-0.1-0.1-0.4-0.3-0.6-0.5c-1.5,1.4-3.1,2.8-4.6,4.3c-0.5,3.3-0.7,6.7-0.6,9.5c0,0.5,0,1.1,0.1,1.7 c0.1,0.8,0.2,1.7,0.4,2.5c0.1,0.5,0.2,1.1,0.3,1.6c0.4,1.3,1,2.5,1.8,3.5c0.6-1.2,1.3-2.4,2-3.5c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0 c-0.2-0.2-0.4-0.4-0.5-0.5c-0.5,0.6-0.9,1.2-1.4,1.7c0,0,0,0,0,0c0-2.7,1-4.3,2.5-5.9C91,63,90.8,62.6,90.7,62.2z"
        />
        <path
          opacity="0.35"
          d="M93.2,47.6c-0.1-0.2,0-0.4,0-0.6c-0.1,0.1-0.2,0.2-0.3,0.3 c0.1,0.2,0.2,0.4,0.2,0.5c0,0.8-0.1,1.6-0.1,2.4c0.2-0.5,0.4-0.9,0.5-1.4c0-0.3,0-0.5,0-0.8C93.4,47.9,93.3,47.8,93.2,47.6z"
        />
        <path
          opacity="0.35"
          d="M93.1,67.4C93.1,67.4,93.2,67.4,93.1,67.4 c0,0.1,0,0.1,0,0.2c-0.1,0.3-0.1,0.6-0.2,0.9c0,0.4,0,0.8,0,1.2c0.2-1,0.4-2.1,0.5-3.1C93.3,66.8,93.2,67.1,93.1,67.4z"
        />
        <path
          opacity="0.35"
          d="M99.5,53.8c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.3-0.2,0.4 c0.2,0.1,0.4,0.2,0.5,0.3c0.1-0.2,0.2-0.4,0.4-0.6c0,0,0,0.1,0.1,0.2c0.2-0.3,0.4-0.6,0.6-0.9c0.1-1.2,0.3-2.3,0.3-3.5 c-0.9,1.3-1.6,2.8-2.3,4.2c0,0,0.1,0,0.1,0.1c0.2-0.3,0.5-0.4,0.8-0.5c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.1,0.4-0.2,0.6-0.3 c0,0,0,0-0.1,0C99.9,53.5,99.7,53.7,99.5,53.8z"
        />
        <path
          opacity="0.35"
          d="M87.6,42.8c0.4-0.9,0.8-1.8,1.1-2.7 C87.9,41,87.1,42,86.3,43c-0.2,0.4-0.5,0.9-0.7,1.3C86.3,43.8,86.9,43.3,87.6,42.8z"
        />
        <path
          opacity="0.35"
          d="M84.8,46.3c-1,2.5-1.8,5.4-2.3,8.3c0.5-0.5,1-1,1.6-1.5 c0.7-2.8,1.6-5.6,2.7-8.2C86.1,45.3,85.5,45.8,84.8,46.3z"
        />
        <path
          opacity="0.35"
          d="M82.8,67.7c0.4,1.3,0.7,3.2,1.3,5.2 c0.6,1.6,1.4,2.9,2.3,3.8c0.6-0.3,1.1-0.6,1.7-0.9c0,0-0.1,0-0.1-0.1c-0.1,0-0.2,0-0.2,0c-1.1-0.2-1.8-1.8-2.2-3.8 c-0.5-0.8-0.8-1.7-1.1-2.5c-1.4-3.9-1.5-8-1-12c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.3-0.5,0.6-0.7,1c0.4-1,0.8-1.9,1.2-2.8 c0,0,0-0.1,0-0.1c-0.4,0.4-0.8,0.8-1.3,1.1c-0.3,3.1-0.2,6.3,0.2,9.2C82.5,66.8,82.6,67.3,82.8,67.7z"
        />
        <path
          opacity="0.35"
          d="M89.3,41.6c0.9-1.7,1.9-3.2,3-4.6c0,0,0,0,0,0 c-1.3,0.9-2.4,1.9-3.5,3.1C88.8,40.3,89.1,41,89.3,41.6C89.2,41.6,89.3,41.6,89.3,41.6z"
        />
        <path
          opacity="0.35"
          d="M101.8,60.5c0.1-0.1,0.1-0.1,0.2-0.2c0-0.3,0.1-0.5,0.1-0.8 c0.1-1,0.2-2,0.3-2.9c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,1-0.3,2-0.4,3C101.6,60.3,101.7,60.4,101.8,60.5z"
        />
        <path
          opacity="0.35"
          d="M100.4,65.3c-0.3,1.3-0.7,2.6-1.1,3.8 c0.2-0.1,0.4-0.3,0.6-0.4c0.5-1.3,0.9-2.8,1.3-4.4C101,64.6,100.7,65,100.4,65.3z"
        />
      </g>
    </g>
  )
}

export default FrontShieldL
