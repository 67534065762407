import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongBowLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongBowLeft") ? (
    <g id="LongBowLeft">
      <path d="M68.8000031,110.1999969c-1.3000031,0-2.5-0.0999985-3.8000031-0.4000015l-0.8000031-0.1999969 c-1.2999992-0.3000031-3.7999992-0.8000031-5.2000008-1.0999985l-0.2999992,0.1999969l-1.0999985,0.0999985h-0.4000015 c-4.2999992,0-6.9000015-2.9000015-7.5999985-5.6999969L51.5,96.4000015l0.2999992-0.3000031l3-1.8000031 c0.9000015-0.3000031,58.0000038-41,58.0000038-41l2.5-2.2999992l0.4000015-0.2000008l6.9000015,0.4000015 c3.0999985,2,4.1999969,5.9000015,2.6999969,9.5l-0.5999985,1.2000008l-0.1999969,0.0999985 c-0.1999969,1.4000015-0.5,3.8999977-0.6999969,5.2000008L123.7000122,68 c-1.9000015,13-15.6999969,19.8000031-20.3000031,22.0999985c-1.1999969,0.5999985-1.9000015,1-2.3000031,1.1999969 l-0.4000015-0.5999985L100.4000015,91.5l0.1999969,0.3000031L100.4000015,91.5l-0.0999985,0.1999969L100.5,91.8999939 l-5.3000031,3.8000031L95,95.4000015v0.4000015L94.6999969,96l-1.5999985-2.4000015L92.7999954,94.5l1.4000015,1.6999969 C93.8999939,96.3999939,93.2999954,97,92.2999954,98C89.3000031,101,79.9000015,110.1999969,68.8000031,110.1999969z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongBowLeft, {
  character: "WomanDwarf"
})
