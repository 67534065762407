import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LuteLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LuteLeft") ? (
    <g id="LuteLeft">
      <path
        id="S_x24_luteLeft"
        d="M58.5999985,119.4000015c-4.4000015,0-7.9000015-0.4000015-8.9000015-1 c-3.7999992-2-9.7000008-6.9000015-6.5999985-17.2999954c1.0999985-3.9000015,9-15.5999985,16.4000015-20.3000031 l8.3000031-14.9000015l7.5-3.9000015L79.5,64.5c0,0,5,3,5,4.9000015S81,80.5999985,81,80.5999985l-4.6999969,8.1999969 c0.5,3.4000015,1.0999985,9.9000015,0,16l-0.1999969,0.9000015C74.5999985,110.1999969,70,119.4000015,58.5999985,119.4000015z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LuteLeft, {
  character: "ManHalfling"
})
