import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MorningStarLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MorningStarLeft") ? (
    <g id="MorningStarLeft">
      <path
        id="S_x24_morningStarLeft"
        d="M92,88.8c-0.1-0.6-0.2-1.2-0.4-1.7c-0.1-0.7-0.4-1.5-0.8-2.2s-0.9-1.3-1.5-1.9 c-0.2-0.2-0.4-0.5-0.6-0.7c0-0.1,0-0.2,0-0.3v-2.5l0,0l0,0v-2.3c0-0.3,0-0.7-0.1-1c0-0.3,0.1-0.7,0.1-1v-4.3c0-0.3,0-0.7-0.1-1 c0-0.3,0.1-0.7,0.1-1v-2.5l0,0l0,0v-2.3c0-1.9-0.8-3.8-2.2-5.1c-0.3-0.3-0.5-0.6-0.8-0.9c-1-1-2.8-2.1-5.6-2.1 c-1.2,0-2.3,0.2-2.7,0.3c-1.7,0.4-3.2,1.3-4.2,2.7l-1.3,1.7c-0.3,0.4-0.5,0.7-0.7,1.1c0,0,0,0.1-0.1,0.1l-3.8,5.8L67,67.8 c-1.1,0.5-2.1,1.2-2.9,2.2l-0.8,1c-1.1,1.4-1.6,3.1-1.5,4.8L57.5,82c-0.8,1.2-1.2,2.7-1.2,4.1c0.1,2.9,1.4,5.5,3.6,7 c1.4,1,3.2,1.5,5,1.5c0.7,0,1.2-0.1,1.6-0.1c1.6-0.2,3-1,4.1-2.2c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.7,0.4,1.5,0.8,2.2 c0.3,0.5,0.6,1,1,1.4c0.5,0.9,1.1,1.7,2,2.3c0.6,0.4,1.2,0.8,1.8,1c0.6,0.4,1.2,0.6,1.9,0.8c0.6,0.2,1.3,0.3,1.9,0.3 c0.3,0,0.5,0,0.8,0c0.2,0,0.4,0,0.6,0c0.3,0,0.6,0,0.9-0.1c0.6-0.1,1.2-0.2,1.7-0.4c0.7-0.1,1.5-0.4,2.2-0.8 c0.4-0.2,0.8-0.5,1.1-0.8c0.8-0.4,1.5-1,2.1-1.7c0.4-0.5,0.7-1,1-1.5c0.5-0.6,0.8-1.3,1.1-2c0.3-0.8,0.4-1.7,0.3-2.5 C92,90.4,92.1,89.6,92,88.8z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MorningStarLeft, {
  character: "ManDwarf"
})
