import React from "react"

const FlameRight = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameRight">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M31.085743,88.053093c-0.9785404-0.3261795-1.9027176-0.652359-2.7181683-1.0329056 c-0.1630898-0.2718201-0.3805447-0.5436325-0.5436344-0.8154526c-0.434906-0.7067261-0.8154507-1.4134445-1.1416302-2.1745377 c0.0543633-0.217453,0.1630898-0.434906,0.217453-0.652359c0.1087265-0.2718124,0.2718163-0.5436325,0.3805447-0.8154449 c0.217453-0.3805466,0.434906-0.8154526,0.5979958-1.2503586l0.5436344-0.1087265l-0.2718163-0.3805466l-0.0543633-0.0543594 c1.1416302-0.7067261,1.8483543-1.4678116,2.0658073-2.2832565c0.0543633-0.2718201,0.1087265-0.5979996,0.0543633-0.8698196 l0.3261795-0.0543594c0.1087284,0.217453,0.2174549,0.3805389,0.2718182,0.5436325 c0.3261795,0.652359,0.8154507,1.2503586,1.2503548,1.8483582c0.5436363,0.7067261,1.0329056,1.4134445,1.3590851,2.1201706 c0.8154526,1.7939911,0.6523628,3.5336227-0.5436325,5.5994263C32.2817383,87.9443665,31.6837406,88.053093,31.085743,88.053093z"
        />
        <path
          fill={colors[1]}
          d="M31.0313797,88.10746c-0.8154507-0.2718201-1.6309013-0.5436401-2.3376255-0.8698196 c-0.1630898-0.217453-0.3261795-0.434906-0.434906-0.7067184c-0.434906-0.652359-0.7610874-1.3047256-1.0872669-2.0658112 c0.0543633-0.217453,0.1087265-0.3805466,0.217453-0.5436325c0.1087265-0.2718201,0.2718163-0.5436401,0.3805447-0.7610931 c0.1630898-0.3261795,0.3805428-0.7067261,0.5436325-1.087265l0.8154507-0.1087265l-0.4349079-0.5979919 c0.9785404-0.652359,1.5765381-1.3590851,1.8483543-2.0658112c0.2718182,0.434906,0.5979977,0.8154449,0.8698158,1.2503586 c0.4892693,0.7067261,0.9785404,1.3590851,1.3047199,2.0658112c0.8154488,1.685257,0.652359,3.3161621-0.4892693,5.2732391 C31.8468304,87.9987259,31.4662876,88.10746,31.0313797,88.10746z"
        />
        <path
          fill={colors[2]}
          d="M30.9770164,88.1618195c-0.6523609-0.217453-1.3047199-0.434906-1.9027176-0.7067261 c-0.1087265-0.1630936-0.2718163-0.3805389-0.3805447-0.5436325c-0.3805428-0.5979996-0.7067223-1.2503586-1.0329037-1.9027176 c0.0543633-0.1630936,0.1087284-0.2718201,0.1630898-0.434906c0.1087284-0.2718201,0.2718182-0.5436401,0.3805447-0.7610931 c0.1630898-0.2718124,0.3261795-0.5979919,0.434906-0.9241714l1.1416321-0.1630936l-0.5436344-0.7610855 c0.5436344-0.3805466,0.9785404-0.7610931,1.3047199-1.1959991c0.1087284,0.1087265,0.1630898,0.2174606,0.2718182,0.3805466 c0.4892693,0.7067261,0.9785404,1.3590851,1.3047218,2.0114441c0.7610855,1.522171,0.5979958,3.0987091-0.4349079,4.9470673 C31.4662876,88.053093,31.2488346,88.10746,30.9770164,88.1618195z"
        />
        <path
          fill={colors[3]}
          d="M30.9226532,88.1618195c-0.4892693-0.1630936-0.9785404-0.3261795-1.4678116-0.5436325 c-0.1087265-0.1630936-0.1630898-0.2718201-0.2718163-0.434906c-0.3805447-0.5979996-0.6523609-1.1416321-0.9785404-1.7939987 c0.0543633-0.1087265,0.0543633-0.217453,0.1087265-0.3261795c0.1087284-0.2718124,0.2718182-0.4892654,0.3805447-0.7610855 s0.2718163-0.4892731,0.3805447-0.7610855l1.4134464-0.2174606l-0.6523609-0.869812 c0.217453-0.1630859,0.4349079-0.3261795,0.5979977-0.4892654c0.3805447,0.5436325,0.7610874,1.087265,1.0329037,1.6308975 c0.7067242,1.4134445,0.5436344,2.8812637-0.3261795,4.5665207C31.085743,88.10746,31.0313797,88.1618195,30.9226532,88.1618195z"
        />
        <path
          fill={colors[4]}
          d="M30.7595634,87.9987259c-0.2718182-0.1087265-0.5979977-0.217453-0.8698158-0.3261795 c-0.0543633-0.1087265-0.1087265-0.217453-0.217453-0.3261795c-0.3261795-0.5436325-0.6523609-1.087265-0.9241772-1.6308975 c0-0.0543671,0.0543633-0.1087265,0.1087265-0.217453c0.1087284-0.2718201,0.2718182-0.4892731,0.3805447-0.7610931 c0.1087265-0.217453,0.217453-0.434906,0.3261795-0.652359l1.4134483-0.217453 C31.5750141,85.1718369,31.5206509,86.5309219,30.7595634,87.9987259z"
        />
        <path
          fill={colors[5]}
          d="M30.8682899,88.1618195c-0.0543633-0.0543594-0.0543633-0.1087265-0.1087265-0.1630936 c-0.3261814-0.4892654-0.5979977-0.9785385-0.8154507-1.522171c0-0.0543671,0-0.0543671,0.0543633-0.1087265 c0.1087265-0.2718201,0.2718163-0.4892731,0.3805428-0.7610931c0.0543652-0.1630859,0.1630898-0.3261795,0.2174549-0.4892654 l0.5436325-0.1087265C31.4662876,86.0416489,31.3575592,87.0745544,30.8682899,88.1618195 C30.8682899,88.2161789,30.8682899,88.1618195,30.8682899,88.1618195z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M35.4348145,78.2133179c-0.7610893-1.087265-0.869812-2.1201706-1.9570808-3.044342 c-0.0543633,0-0.5436363-0.3805466-0.5979996-0.3805466c-0.1087265,0.0543594-0.7610855,0.1087265-0.7610855-0.1087265 c0.1087265-0.5436325,0.9785385-0.7067261,0.7067223-1.1959991c-0.217453-0.3805389-1.1959934-2.7181625-1.4678116-3.1530685 c-0.434906,0.869812-1.7939911,3.0987091-2.5550766,3.8597946c-0.0543633,0.0543594-1.1416321,2.8268967-1.5221748,4.0772552 c0.217453-0.0543671,0.5979977-0.1630936,0.5436344-0.0543671c-0.5979977,1.6852646-2.228899,3.0443497-2.8268967,4.7296143 c0,0.0543594-0.1087265,0.1087265-0.217453,0.1630936c0.217453,1.0329056,1.1416321,1.9027176,2.1745358,2.6638031 c-0.5436344-0.869812-0.9785404-1.7939911-0.9785404-2.7725296c0-0.1087265,0.3261795-0.2718201,0.6523609-0.3805389 c0.1087265-1.0872726,0.2718163-2.0658112,1.2503567-3.0443497c1.2503567-1.1959915,1.8483543-3.0987167,1.8483543-3.0987167 c0.0543633-0.217453,1.4678116-2.4463501,1.1416302-3.4248886c-0.1087265-0.2718201,0.2718182,0.4892731,0.2718182,0.7067261 c0.0543633,0,0.1087265,0,0.1087265,0.0543594c0,0.7610855,0.5436344,3.3161621,0.5979977,3.3705292 c1.1959953,0.869812,1.2503586,1.0329056,1.7939911,2.1201706c0.652359,1.3590851,1.0329056,2.2288971,1.0872688,3.6423492 c0,0.0543594-0.0543633,0.1087189-0.1087303,0.1630859c0.4349098,0.8154526-0.217453,1.6852646-0.434906,2.5007172 c-0.0543633,0.1087265-0.0543633,0.217453-0.1630898,0.3805389c0.5436363-0.7610855,1.0329056-1.5765381,1.4678116-2.391983 C35.4891777,81.8013,35.4891777,80.0073166,35.4348145,78.2133179z"
      />
    </g>
  )
}

export default FlameRight
