import React from "react"

const Tusks = ({ colors = ["#E5E5E5"] }) => {
  return (
    <g id="Tusks" fill={colors[0]}>
      <path
        id="fang"
        d="M67.2,21.2l0.1-1.2l0.4,1.2c0,0-0.1,0-0.2,0S67.2,21.2,67.2,21.2z"
      />
      <path
        id="fang_1_"
        d="M71.2,21.2L71.1,20l-0.4,1.2c0,0,0.1,0,0.2,0S71.2,21.2,71.2,21.2z"
      />
    </g>
  )
}

export default Tusks
