import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongBowRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongBowRight") ? (
    <g id="LongBowRight">
      <path d="M68.4000015,110.0999985c1.3000031,0,2.5-0.0999985,3.8000031-0.4000015L73.0000076,109.5 c1.3000031-0.3000031,3.8000031-0.8000031,5.1999969-1.0999985l0.3000031,0.1999969l1.0999985,0.0999985H80 c4.3000031,0,6.9000015-2.9000015,7.5999985-5.6999969l-1.9000015-6.6999969L85.4000015,96l-3-1.8000031 c-0.9000015-0.3000031-58-41-58-41L22,51.0999985L21.6000004,51l-6.8999996,0.4000015 c-3.1000004,2-4.1999998,5.9000015-2.6999998,9.5l0.6000004,1.2000008l0.1999998,0.0999985 c0.1999998,1.4000015,0.5,3.8999977,0.6999998,5.2000008l0.1000004,0.8000031 C15.3999996,80.9000015,29.2000008,87.8000031,33.7999992,90C35,90.5999985,35.7000008,91,36.0999985,91.1999969L36.5,90.5999985 l0.2999992,0.8000031l-0.2000008,0.3000031l0.2000008-0.3000031l0.0999985,0.1999969l-0.2000008,0.1999969l5.2999992,3.8000031 l0.2000008-0.3000031v0.4000015l0.2999992,0.1999969l1.5999985-2.4000015l0.2999992,0.9000015L43,96.0999985 c0.2999992,0.1999969,0.9000015,0.8000031,1.9000015,1.8000031 C47.9000015,100.9000015,57.4000015,110.0999985,68.4000015,110.0999985z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongBowRight, {
  character: "WomanDwarf"
})
