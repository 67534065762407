import React from "react"
import { withSkinColor } from "../../builderConnector"

const Body = ({ skinColor, colors = ["#E8B180", "#CD977A"] }) => {
  return (
    <g id="Body">
      <path
        fill={skinColor}
        d="M70,86.3000031l3.8000031,12.3000031c0,0,0.0999985,2.5,1.5,4.0999985 C75.5,104.6000061,77.1000061,109.9000015,78,111.6000061c0.1999969,0.4000015,0.4000015,1.1999969,0.4000015,1.1999969 L79,114.9000015l-0.0999985,2.9000015h8.6999969L83.5,112.4000015L83.6999969,109 c0.1999969-3.8000031,0.4000015-6.6999969-0.8000031-8c-0.1999969-0.1999969-1-1.5999985-1.4000015-2 s-0.3000031-1.8000031-0.1999969-1.9000015c0.6999969-1.3000031,0.5-3.5,0.8000031-5.5 c0.1999969-1.9000015-0.4000015-9-1.5999985-11c0.1999969-5.1999969-2.9000015-8.1999969-2.9000015-8.1999969l0.5-6.4000015 l2.3000031-4.0999985c-0.1999969-0.4000015,1.5-4.5999985,1.5-4.5999985l-9.5999985-2.0999985v-1.7000008H65v1.5999985 l-9.7000008,2.0999985c0,0,1.7000008,4.2000008,1.5,4.5999985l2.2999992,4.1000023l0.5,6.4000015 c0,0-3.0999985,3-2.9000015,8.1999969c-1.2000008,2-1.7999992,9.0999985-1.5999985,11s0.0999985,4.1999969,0.7999992,5.5 c0,0.0999985,0.0999985,1.5-0.2000008,1.9000015c-0.5,0.4000015-1.2000008,1.8000031-1.4000015,2 c-1.0999985,1.3000031-0.9000015,4.1999969-0.7999992,8l0.2000008,3.4000015l-4.0999985,5.4000015h8.7000008 l-0.0999985-2.9000015l0.5999985-2.0999985c0,0,0.2000008-0.8000031,0.4000015-1.1999969 c0.9000015-1.6999969,2.5-7,2.7000008-8.9000015c1.4000015-1.5999985,1.5-4.0999985,1.5-4.0999985l3.8000031-12.1999969 c0,0,0.4000015-1,1.5999985-1C69.8000031,85.3000031,70,86.3000031,70,86.3000031z"
      />
      <path
        fill={skinColor}
        d="M48.4000015,72.5999985c-2.0999985,2.9000015-3,10.9000015-3,10.9000015 s1,1.1999969,2.7999992,1.8000031c0.9000015-2.1999969,6.0999985-11,5.5-12.0999985 c0.7999992-1.4000015,3.0999985-7.3000031,3.7999992-9c0.7000008-1.7000008,0.5-7.4000015,0.5-7.4000015 s-1.2000008-0.9000015-3.5,0c-2,0.9000015-3,3.5999985-3,6c-1,2.3000031-1.4000015,6.9000015-1.9000015,8.0999985 C49.5999985,71.4000015,48.5,72.5999985,48.4000015,72.5999985z"
      />
      <path fill={colors[1]} d="M52.7999992,76.5999985" />
      <path
        fill={skinColor}
        d="M88.5999985,72.5999985c2.0999985,2.9000015,3,10.9000015,3,10.9000015 s-1,1.1999969-2.8000031,1.8000031c-0.9000015-2.1999969-6.0999985-11-5.5-12.0999985 c-0.8000031-1.4000015-3.0999985-7.3000031-3.8000031-9c-0.6999969-1.7000008-0.5-7.4000015-0.5-7.4000015 s1.1999969-0.9000015,3.5,0c2,0.9000015,3,3.5999985,3,6c1,2.3000031,1.4000015,6.9000015,1.9000015,8.0999985 C87.4000015,71.4000015,88.5,72.5999985,88.5999985,72.5999985z"
      />
      <path fill={colors[1]} d="M84.1999969,76.5999985" />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M56,64.6999969 c-0.0999985,0.1999969-1.5999985,3.5-2.7000008,5.5999985c-0.2999992,0.5-0.5999985,0.9000015-0.9000015,1.1999969 c-0.2000008,0.0999985-0.5,0.0999985-0.7000008,0c-0.2999992-0.0999985-1.0999985,0.1999969-0.7000008,0.5 c0.0999985,0.0999985,0.2000008,0.0999985,0.2999992,0.1999969v0.0999985 c0.2999992,0.9000015,0.2000008,2.1999969-0.0999985,3.5c-0.7999992,1.8000031-1.5999985,3.5999985-2.4000015,5.4000015 c-0.2999992,0.4000015-0.5999985,0.6999969-0.7999992,1c-0.2000008,0.1999969,0,0.3000031,0.2000008,0.3000031 c0,0.0999985-0.0999985,0.1999969-0.0999985,0.1999969C48,82.6999893,48,82.5999908,47.8999977,82.5999908 c-0.2999992-0.1999969-1.2000008,0.1999969-0.7999992,0.5c0.2000008,0.1999969,0.5,0.5,0.7000008,0.6999969 c0,0,0,0.0999985,0.0999985,0.0999985l0,0C48,84,48.2000008,84,48.4000015,84 c0-0.1999969,0.0999985-0.4000015,0.2000008-0.5999985c0-0.0999985,0.0999985-0.0999985,0.0999985-0.1999969 c0,0,0,0,0.0999985-0.0999985c0.2999992-0.5999985,0.5-1.0999985,0.7999992-1.6999969c0.4000015-0.5,0.7000008-1,0.9000015-1.5 c0.5-0.8000031,1-1.6999969,1.4000015-2.6999969c0.7000008-1.4000015,1.5-2.9000015,1.4000015-4.5l0,0 c0.0999985,0,0.2999992-0.0999985,0.2999992-0.1999969c0.4000015-0.9000015,0.7999992-1.8000031,1.0999985-2.6999969 c1-1.6999969,1.5999985-4.0999985,2-5.4000015C57.0999985,64.1999969,56.0999985,64.3000031,56,64.6999969z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M81.1999969,64.6999969 c0.0999985,0.1999969,1.5999985,3.5,2.6999969,5.5999985c0.3000031,0.5,0.5999985,0.9000015,0.9000015,1.1999969 c0.1999969,0.0999985,0.5,0.0999985,0.6999969,0c0.3000031-0.0999985,1.0999985,0.1999969,0.6999969,0.5 c-0.0999985,0.0999985-0.1999969,0.0999985-0.3000031,0.1999969v0.0999985 c-0.3000031,0.9000015-0.1999969,2.1999969,0.0999985,3.5c0.8000031,1.8000031,1.5999985,3.5999985,2.4000015,5.4000015 c0.3000031,0.4000015,0.5999985,0.6999969,0.8000031,1c0.1999969,0.1999969,0,0.3000031-0.1999969,0.3000031 c0,0.0999985,0.0999985,0.1999969,0.0999985,0.1999969c0.0999985,0,0.0999985-0.0999985,0.1999969-0.0999985 c0.3000031-0.1999969,1.1999969,0.1999969,0.8000031,0.5c-0.1999969,0.1999969-0.5,0.5-0.6999969,0.6999969 c0,0,0,0.0999985-0.0999985,0.0999985l0,0C89.1999969,84,89,84,88.8000031,84 c0-0.1999969-0.0999985-0.4000015-0.1999969-0.5999985c0-0.0999985-0.0999985-0.0999985-0.0999985-0.1999969 c0,0,0,0-0.0999985-0.0999985c-0.3000031-0.5999985-0.5-1.0999985-0.8000031-1.6999969 c-0.4000015-0.5-0.6999969-1-0.9000015-1.5c-0.5-0.8000031-1-1.6999969-1.4000015-2.6999969 c-0.6999969-1.4000015-1.5-2.9000015-1.4000015-4.5l0,0c-0.0999985,0-0.3000031-0.0999985-0.3000031-0.1999969 C83.1999969,71.6000137,82.7999954,70.7000122,82.5,69.8000183c-1-1.6999969-1.5999985-4.0999985-2-5.4000015 C80.0999985,64.1999969,81,64.3000031,81.1999969,64.6999969z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M62.9000015,84.5 C63,85.0999985,63.1000023,85.8000031,63.1000023,86.4000015c0.0999985,0.9000015-0.7000008,8.8000031-3.0999985,10.8000031 h0.0999985c0.2999992,0.0999985,1.2999992,1.0999985,0.5,2.3000031c-1.0999985,1.4000015-2.5,0.9000015-2.5999985,0.9000015 c-0.2999992,1.0999985,0.4000015,2.1999969,0.5,2.1999969c0,0-0.2000008,5.1999969-1.7999992,9.5999985 c0.9000015,0.9000015,1,0.9000015,1.0999985,1.4000015c0.9000015-2.0999985,1.5-4,2.0999985-5.5 s0.9000015-3.8000031,1.5999985-6.0999985c0-0.0999985,0.2999992-0.3000031,0.4000015-0.3000031 c0.9000015-0.5,1.0999985-3.5999985,1.2000008-3.8000031c0.4000015-1.8000031,2.2999992-7,2.5000038-7.8000031 c0.4000015-1.1999969,0.5999985-2.6999969,1.0999985-3.8000031C65.5,85.8000031,63,84.5999985,62.9000015,84.5z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M74.0999985,84.5 C74,85.0999985,73.9000015,85.9000015,74,86.4000015c-0.0999985,0.9000015,0.6999969,8.8000031,3.0999985,10.8000031H77 c-0.3000031,0.0999985-1.3000031,1.0999985-0.5,2.3000031c1.0999985,1.4000015,2.5,0.9000015,2.5999985,0.9000015 c0.3000031,1.0999985-0.4000015,2.1999969-0.5,2.1999969c0,0,0.1999969,5.1999969,1.8000031,9.5999985 c-0.9000015,0.9000015-1,0.9000015-1.0999985,1.4000015c-0.9000015-2.0999985-1.5-4-2.0999985-5.5 s-0.9000015-3.8000031-1.5999985-6.0999985c0-0.0999985-0.3000031-0.3000031-0.4000015-0.3000031 c-0.9000015-0.5-1.0999985-3.5999985-1.1999969-3.8000031c-0.4000015-1.8000031-2.3000031-7-2.5-7.8000031 c-0.4000015-1.1999969-0.6999969-2.6999969-1.0999985-3.8000031C71.5,85.8000031,74.0999985,84.5,74.0999985,84.5z"
      />
      <path
        id="chinShadow"
        opacity="0.2"
        fill="#21376C"
        d="M62,55.7000008 c0,0,2.5999985,4.4000015,6.8000031,4.4000015C73,60.1000023,75.5,55.8000031,75.5,55.8000031l-3.1999969-0.7000008v-0.5999985 L65,54.4000015v0.7000008L62,55.7000008z"
      />
    </g>
  )
}

export default withSkinColor(Body, { character: "WomanDwarf" })
