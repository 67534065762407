import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WarHammerR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WarHammerR") ? (
    <g id="WarHammerR">
      <path d="M50.5793648,109.3549881c3.5462151-1.1076126,5.5300789-4.8936234,4.4225807-8.439476 L37.6359863,45.3154449l1.5073357-0.3391991c3.9646072-1.6499367,5.7217598-3.0685921,6.4833984-5.2339058 c0.4708481-1.3390656,0.427887-2.7997055-0.106514-4.0932083c-0.3635445-1.1125565-1.809063-6.3996181-3.0420799-11.0396957 l-5.9558487-4.0997868c-1.4413948,0.1520996-3.2567139,0.3299141-3.6762123,0.3510075 c0,0-2.2240276,0.5931377-2.0957394,0.5350132c-0.0246506,0.0097065-0.1217747,0.0404434-0.1393204,0.0459232 c-0.1800804,0.0554428-1.7679386-1.7977123-2.3092728-3.0388966L17.7794647,21.821991 c0.1519814,0.689558,0.4821186,2.7562046,0.7736359,4.5797424c0.0428886,0.2670517,0.0843372,0.5269299,0.1246014,0.7791557 c-0.6888428,0.560194-1.3531246,1.2619686-1.842905,2.0933952c-0.4998417,0.4305496-0.9734659,0.9283371-1.4278641,1.4979553 c-0.0460434,0.0581131-4.4965315,6.1178856-4.749898,9.8488617l8.2516069,5.2315254 c0.0164051-0.0091362,5.1408463,1.1602859,5.1408463,1.1602859l0.2241592,0.0153656l1.1283989,0.0773392l0.0008888,0.0041351 c-0.379179,0.0409966-0.8301353,0.0474434-1.3308392-0.0244598l18.0679073,57.8474846 C43.2475014,108.4786301,47.0335083,110.4624939,50.5793648,109.3549881z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WarHammerR, {
  character: "ManDragonborn"
})
