import React from "react"

const HipDaggerRight = ({
  colors = [
    "#66451E",
    "#895A24",
    "#9E8835",
    "#9AB8DB",
    "#6B92C0",
    "#57779C",
    "#E2E4E5",
    "#A0A2A5",
    "#EED793"
  ]
}) => {
  return (
    <g id="HipDaggerRight">
      <polygon
        fill={colors[0]}
        points="37.79216,84.8327789 39.7315369,79.7534561 41.8556137,80.0305099 40.3779945,85.6639328"
      />
      <polygon
        fill={colors[1]}
        points="36.037487,83.9092636 40.7474022,84.5557175 37.6074562,96.1919785 36.9609985,96.5613861 36.314537,96.0996246"
      />
      <polygon
        fill={colors[0]}
        points="37.1457024,95.9149246 39.8238907,84.3710175 40.7474022,84.5557175 37.5151062,95.9149246 37.4227562,96.1919785"
      />
      <polygon
        fill={colors[2]}
        points="39.454483,84.7404175 40.6550484,83.8169098 39.1774292,82.1545868 39.546833,79.8458099 39.546833,76.890564 40.1009407,76.0594101 39.6391869,75.1358948 38.7156715,75.043541 38.1615639,75.8747025 38.4386215,76.705864 37.88451,79.6611023 37.79216,82.0622406 36.037487,83.3551483 37.0533524,84.4633713"
      />
      <polygon
        fill={colors[3]}
        points="38.8080215,75.1358948 38.9003754,75.5052948 39.362133,75.5052948 39.546833,75.2282486"
      />
      <polygon
        fill={colors[4]}
        points="39.6391869,75.2282486 39.454483,75.5976486 39.6391869,75.9670486 40.0085907,76.0594101"
      />
      <polygon
        fill={colors[3]}
        points="38.7156715,75.1358948 38.9003754,75.5052948 38.5309715,75.9670486 38.1615639,75.8747025"
      />
      <polygon
        fill={colors[5]}
        points="38.5309715,76.6135178 38.7156715,76.3364563 39.362133,76.4288101 39.546833,76.705864"
      />
      <polygon
        fill={colors[4]}
        points="38.2539177,75.9670486 38.5309715,75.9670486 38.7156715,76.3364563 38.5309715,76.6135178"
      />
      <polygon
        fill={colors[5]}
        points="39.6391869,76.0594101 39.362133,76.4288101 39.546833,76.705864 40.0085907,76.0594101"
      />
      <polygon
        fill={colors[4]}
        points="38.6233215,75.9670486 38.9927254,75.5052948 39.362133,75.5976486 39.6391869,76.0594101 39.362133,76.3364563 38.8080215,76.2441101"
      />
      <polygon
        fill={colors[6]}
        points="40.0085907,83.8169098 38.4386215,83.1704483 36.7762947,83.4475098 37.1457024,95.9149246 37.1457024,95.9149246"
      />
      <polygon
        fill={colors[7]}
        points="38.4386215,83.1704483 37.1457024,95.9149246 40.0085907,83.8169098"
      />
      <path
        fill={colors[8]}
        d="M39.454483,78.4605408v-1.477623c-0.09235-0.0923462-0.2770538-0.1847-0.4617577-0.2770538h-0.09235 c-0.09235,0-0.27705,0-0.3694038,0.0923462l-0.4617577,2.4011383 C38.4386215,78.8299484,38.9003754,78.552887,39.454483,78.4605408z"
      />
      <path
        fill={colors[8]}
        d="M37.9768639,80.3075638v1.662323l0.6464615-0.4617538l0.5541039,0.4617538l0.2770538-2.0317307 V78.737587C38.8080215,79.0146484,38.2539177,79.7534561,37.9768639,80.3075638z"
      />
    </g>
  )
}

export default HipDaggerRight
