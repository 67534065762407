import React from "react"

const MaulL = ({ colors = ["#888787", "#282560", "#FFFFFF"] }) => {
  return (
    <g id="MaulL">
      <path
        fill={colors[0]}
        d="M91.3301544,43.5468636c-0.0886917,0.0793457-5.5651932,31.8835793-5.5651932,31.8835793 s-0.2194138,0.9148254,0.8540878,1.311615c1.0735168,0.3967896,1.8716888-0.3173065,1.806366-0.6580353 c0,0,5.4484787-31.3001442,5.2151108-31.6502228C93.3231506,44.0790558,91.5075226,43.388176,91.3301544,43.5468636z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M92.2306747,50.0861053 c0.2473755,0.0980301,0.4154053,0.1073723,0.5834274,0.1167145c0.5229263-3.3419189,0.9057693-5.6803322,0.8264236-5.7690201 c-0.2333603-0.3500748-2.1330032-1.0456238-2.310379-0.88694c0,0-0.4621964,2.2497253-0.9804459,5.5076294 C91.1618347,49.6053009,91.9786224,50.0720901,92.2306747,50.0861053z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M88.9770813,57.0685654 c-0.009346,0.1680298-0.0140152,0.2520447-0.1073761,0.4154053c1.0362091-0.4480324,1.9884109-0.9007416,2.945282-1.4374619 c0.0046692-0.0840111,0.0093384-0.1680298,0.0140152-0.2520447l0,0 C90.9654846,56.1678276,89.9292755,56.61586,88.9770813,57.0685654z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M86.9318237,75.6638718 c0.070076-1.260231,0.1401443-2.5204544,0.2895584-3.6920013c-0.3454056,0.1493454-0.6114578,0.3873825-0.9568634,0.5367203 c-0.3548203,1.8343201-0.5042343,3.0058594-0.5042343,3.0058594s-0.1026993,0.3313904,0.1306534,0.6814651 C86.2410202,75.9625626,86.5864258,75.8132172,86.9318237,75.6638718z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M90.1528625,66.2356339 c0.1167145-0.5834274,0.2380981-1.250885,0.3594894-1.918335c-0.3500748,0.2333603-0.7841644,0.4620438-1.1342392,0.6954041 c-0.1167145,0.5834351-0.1494141,1.1715469-0.2707977,1.8389893 C89.4573822,66.6183395,89.807457,66.3849792,90.1528625,66.2356339z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M88.6635132,74.8331375 c0.1353989-0.9194946,0.3875198-2.4224319,0.7376633-4.1727295c-0.3454056,0.1493378-0.6114578,0.3873749-0.9568634,0.5367126 c-0.242775,1.3348999-0.401535,2.674469-0.64431,4.0093765C88.0567245,75.1364975,88.4021301,74.9871521,88.6635132,74.8331375z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M92.1604538,54.3802223 c0.0046768-0.0840149,0.0140152-0.2520447,0.0186844-0.3360634c-0.0840073-0.004673-0.0840073-0.004673-0.1680222-0.0093422 c-0.947525,0.3686905-1.89505,0.7373772-2.7632294,1.1947517c-0.009346,0.1680336-0.1073685,0.4154053-0.1167145,0.5834389 C90.1673737,55.364975,91.1195755,54.9122696,92.1604538,54.3802223z"
      />
      <path
        fill={colors[0]}
        d="M87.2837601,35.9899559c0.1727066-0.0746727,2.8518524,0.2428474,6.0397491,0.5043793 c4.378067,1.5918312,9.9509506,4.4299469,9.9509506,4.4299469s-3.2303162,8.0793266-3.164978,8.420063 c0.0606537,0.4247475-5.2463074,0.3824883-5.054924-0.028244c0.1913834-0.4107361-8.475975-5.1906853-8.475975-5.1906853 L87.2837601,35.9899559z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M95.7457275,41.4326859l0.0091248,4.3828049 c-0.3127747,1.0781822,0.0886917-0.0793419,0.0933609-0.1633606c0.0046692-0.0840111-0.0746689-0.1727028-0.0746689-0.1727028 c0.0933533-0.1633568,0.1820374-0.2427025,0.3594131-0.4013901c-0.0793381-0.0886841-0.1586838-0.177372-0.1586838-0.177372 l0.0046768-0.0840149c0,0,0.0793381,0.0886879,0.2426987,0.182045l0,0c0,0,0,0-0.0046692,0.0840149 c0.0840149,0.004673,0.0793457,0.0886841,0.1633606,0.0933571l0,0c-0.2427063-0.182045-0.4013901-0.3594208-0.3967209-0.4434319 c0.0046692-0.0840149,0.0886841-0.0793419,0.0933533-0.1633606c0,0,0.1586914,0.177372,0.3173828,0.3547478l0.0933533-0.1633606 c-0.0746689-0.1727028-0.1493454-0.3454056-0.1446686-0.4294167c0.0046692-0.0840149,0.0093384-0.1680336,0.0140152-0.2520447 c-0.0046768,0.0840111,0.0746689,0.1727028,0.2333603,0.3500748l0.3127747-1.0781822 c-0.163353-0.0933571-0.2380295-0.2660599-0.2380295-0.2660599c0.0886841-0.0793419,0.0933533-0.1633568,0.1820374-0.2427025 c-0.0840149-0.0046692-0.163353-0.0933533-0.2473755-0.0980263l0,0c0.0886841-0.0793419,0.0980301-0.2473755,0.1867142-0.3267212 c0.0933609-0.1633568,0.6301498-0.7234344,0.0933609-0.1633568c0.0886841-0.0793419,0.2567139-0.0699997,0.4200745,0.0233574 l0.1026993-0.3313904L95.7457275,41.4326859z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M95.2416382,41.4046593 L95.2416382,41.4046593C95.1622849,41.3159714,95.1622849,41.3159714,95.2416382,41.4046593 C95.1576157,41.3999863,95.1622849,41.3159714,95.2416382,41.4046593c-0.0840149-0.004673-0.0840149-0.004673-0.0840149-0.004673 c0.0933533-0.1633606,0.1820374-0.2427025,0.2754059-0.4060593l0,0l-0.9801559-0.5601501 c-0.2520447-0.0140152-0.4200745-0.0233574-0.6721191-0.0373726c-0.2520523-0.0140114-0.5927734,0.0513153-0.8354797-0.1307259 l-0.0793457-0.0886879l0,0l0,0c-0.0793381-0.0886841-0.0699997-0.2567139,0.0233612-0.4200745 c0.0046692-0.0840149,0.0886841-0.0793419,0.0933533-0.1633606l-0.4107285-0.1913872l0,0c0,0,0,0-0.0840149-0.004673 c-0.0840149-0.0046692-0.2473755-0.0980263-0.4900742-0.2800713c-0.0840149-0.004673-0.0840149-0.004673-0.1680298-0.009346 c-0.2473755-0.0980263-0.3313904-0.1026993-0.4013901-0.359417c0,0-0.0840149-0.004673-0.0793457-0.0886841l-0.0793381-0.0886879 l0,0l0,0l-1.3862152-0.8355522c-0.163353-0.0933571-0.4154053-0.1073723-0.5787582-0.2007332l-0.0840149-0.0046692 l0.0886841-0.0793419l-1.0595016-0.648838c-0.0840073-0.0046692-0.2520447-0.0140152-0.3360596-0.0186844 c-0.2520447-0.0140152-0.3313904-0.1026993-0.312706-0.4387627l-0.3267136-0.186718c0,0-1.1952591,7.8553848-0.5324783,8.0607834 c0.6627808,0.2054024,8.153923,4.9199524,8.153923,4.9199524l1.5173111-7.5846519 C96.0771179,41.5353851,95.6617126,41.4280167,95.2416382,41.4046593z"
      />
    </g>
  )
}

export default MaulL
