import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SlingShotRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SlingShotRight") ? (
    <g id="SlingShotRight">
      <path
        id="S_x24_slingShotRight"
        d="M17.5,119.5c-1.9,0.1-6.7-0.4-5.8-9.4l1.8-32.5l0.5-2.3l0.9-2.1l3.5-3.7l7.4-0.7l6.2,2.9 c0,0-1.2,39.8-1.1,39.9l-0.2,7.9C30.6,119.4,20.1,119.3,17.5,119.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SlingShotRight, {
  character: "ManDwarf"
})
