import React from "react"

const GoldAxeLeft = ({ colors = ["#897441", "#A39155", "#EDCD6E"] }) => {
  return (
    <g id="GoldAxeLeft">
      <path
        fill={colors[0]}
        d="M73.0452499,70.2838669c-1.1242218,0.9539032-3.4358368,1.1489258-5.8703842,0.4291916 c-2.4345322-0.7197266-4.2523651-2.2031326-4.6403732-3.6089172c-2.2875786,0.6606445-4.0779572,2.1536407-4.7139015,4.2557907 c-0.6359482,2.10215,0.0082169,4.3148727,1.5760727,6.088768c1.124218-0.9538956,3.435833-1.1489258,5.8703728-0.4291916 s4.2523727,2.2031326,4.6403809,3.6089172c2.2875824-0.6606445,4.0779572-2.1536331,4.7138977-4.2557907 C75.2737427,74.3453522,74.5547256,72.1491013,73.0452499,70.2838669z"
      />
      <path
        fill={colors[1]}
        d="M66.8060455,76.5236893l2.7985611-6.8949966 c-0.1242981-0.2080917-0.1572647-0.3578033-0.2815628-0.5658875l-3.0244598-0.9037857 c-0.1332245,0.1078186-0.2829437,0.1407776-0.4910278,0.2650833l-1.4195633,7.4547119 c0,0-5.185791,20.2921829-6.6582184,25.0115433c-1.2045822,3.7970581-3.2923393,8.5734253-4.0106964,10.3012924 c0.1243019,0.2080917,0.3399429,0.4745407,0.5390968,0.666153l2.6934547,0.113327l8.449295-31.1353226L66.8060455,76.5236893z"
      />
      <path
        fill={colors[0]}
        d="M54.1503487,111.7245789c-0.0913277-0.0583801,0.1751328-0.2740173,0.0673103-0.4072418 c0.8934746-2.0018921,2.9063797-6.7617722,4.0855484-10.3177719c1.4889069-4.644516,6.5744324-24.6791687,6.6163254-24.8453674 l1.3941498-7.2136536c0.0748596-0.0164871,0.2589111-0.6064072,0.2589111-0.6064072l2.3761673,0.8110733 c0.0164795,0.074852,0.1153717,0.5239868-0.0508194,0.4821014l-2.6818161,6.7123184l-1.4051132,4.3121185l-8.3579712,31.193718 L54.1503487,111.7245789z"
      />
      <path
        fill={colors[2]}
        d="M54.3749237,111.6751328c0.8934746-2.0018845,2.9901733-7.0941544,4.0944824-10.6336823 c1.3886414-4.3869781,6.1136093-22.8510208,6.6582184-25.0115509l1.8357315-7.7033157l1.5122375,0.4518967l-2.5334778,7.3860168 l-1.5713196,4.2702408l-8.1587982,31.3853073L54.3749237,111.6751328z"
      />
      <g fill={colors[2]}>
        <path d="M58.9750137,71.9690933c0.2513618-0.9971619,0.9429245-1.7773132,1.7588005-2.3493729 c0.0583649-0.0913391-0.1902313-0.507515-0.2726402-0.8817978c0.4312782,0.5329208,0.6723289,0.5583344,0.7471886,0.5418549 c0.6242676-0.3729095,1.339859-0.687439,2.0884247-0.8522568c-0.3234634-0.3996887-0.6633949-0.8742371-0.7458076-1.2485199 c-2.2875786,0.6606445-4.077961,2.1536407-4.7138977,4.2557907c-0.635952,2.10215,0.0082207,4.3148727,1.5760651,6.088768 c0.2664642-0.2156372,0.5494118-0.3564224,0.9072075-0.5136948C59.0429916,75.4859619,58.497715,73.722374,58.9750137,71.9690933 z" />
        <path d="M73.0452499,70.2838669c-0.3248367,0.3069839-0.7574921,0.4807358-1.2814941,0.5960999 c1.4435501,1.5658112,2.0966415,3.4626312,1.5609741,5.3072433c-0.1256866,0.4985809-0.4759369,1.0466156-0.767807,1.5033035 c0,0,0.1242981,0.2080917,0.0494461,0.2245636c-0.0913391-0.0583725-0.0913391-0.0583725-0.1078186-0.1332245 c-0.0583725,0.0913391-0.2499847,0.2904968-0.3083572,0.3818359c-0.0583725,0.0913315-0.0254059,0.2410507,0.1813049,0.8234177 c-0.1243057-0.2080917-0.5809937-0.4999695-0.6558456-0.4834824c-0.666153,0.539093-1.4401321,0.9449615-2.3219299,1.2176056 c0.2321243,0.3413162,0.3729095,0.6242676,0.4388351,0.9236908c2.2875748-0.6606445,4.0779572-2.1536407,4.7139053-4.2557907 C75.2737427,74.3453522,74.6131058,72.0577698,73.0452499,70.2838669z" />
      </g>
    </g>
  )
}

export default GoldAxeLeft
