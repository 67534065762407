import React from "react"

const Mouth = ({ colors = ["#353891"] }) => {
  return (
    <g id="Mouth">
      <path
        id="L_x24_C_x24_D_x24_mouth"
        opacity="0.5"
        fill={colors[0]}
        d="M71.859581,58.1126213 c0,0-2.3769226,0.5907288-3.5378113,0.5907288s-3.5378494-0.5907288-3.5378494-0.5907288s3.0377655,0.800972,3.5147247,0.7971916 C68.7756042,58.9135933,71.859581,58.1126213,71.859581,58.1126213z"
      />
    </g>
  )
}

export default Mouth
