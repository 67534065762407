import React from "react"

const Belt = ({ colors = ["#996841", "#C49C2D"] }) => {
  return (
    <g id="Belt">
      <path
        fill={colors[0]}
        d="M63.0516205,77.3531876c0,0,3.2764053,1.6406708,5.6489639,1.5094223 c2.4290619-0.1968842,4.8016205-1.4437943,4.8016205-1.4437943l0.3954239,0.5906448c0,0-3.6718216,1.7719269-5.2535324,1.8375626 c-1.4687347,0.0656204-5.0459175-0.7003937-5.9314156-2.0344391 C62.6561928,77.7469559,63.0516205,77.3531876,63.0516205,77.3531876z"
      />
      <path
        fill={colors[1]}
        d="M68.5311127,78.7313614l-0.5648956,0.0656204c-0.3389359,0-0.6213837,0.3281403-0.6213837,0.7219009 c0,0.3281326,0.3389359,0.6562653,0.6778717,0.5906372l0.5648956-0.0656204c0.3389435,0,0.6213913-0.3281403,0.6213913-0.7219009 C69.208992,78.9938583,68.9265442,78.6657257,68.5311127,78.7313614z M68.5311127,79.9126434l-0.5084076,0.0656204 c-0.2259598,0-0.4519196-0.1968842-0.5084076-0.4593811c0-0.2625122,0.1694717-0.5250244,0.4519196-0.5250244l0.5084076-0.0656204 c0.2259598,0,0.4519196,0.1968842,0.5084076,0.4593811C68.9830322,79.6501312,68.8135605,79.8470154,68.5311127,79.9126434z"
      />
      <path
        fill={colors[1]}
        d="M68.1356888,79.3219986c0.2259598,0.0656204,0.8473434,0,0.8473434,0v0.1312485l-0.8473434,0.0656281 V79.3219986z"
      />
    </g>
  )
}

export default Belt
