import React from "react"
import { withSkinColor } from "../../builderConnector"

const LeftHand = ({ skinColor, color = [] }) => {
  return (
    <g id="LeftHand">
      <path
        fill={skinColor}
        d="M94.3,53.4c0.1,0.2,3.4-0.5,5.5,1.1c0.5,0.6,0,1.1-0.1,1.3c0,0.2,0.4,0.6,0.4,1s-0.5,0.7-0.5,1 s0.4,0.5,0.4,1s-1.1,1.2-1.3,1.8c0,0.2,0,0.6-0.3,0.9c-0.4,0.3-2,0.1-2,0.1l-3-1.2L93,60.1c0,0-0.2-0.5-0.1-0.8s0.4-0.5,0.4-0.5 L93,58.6c0,0-0.4-0.5-0.4-0.8s0.4-0.7,0.4-0.7h0.5L93,57c0,0-0.5-0.5-0.5-0.9s0.6-0.9,0.6-0.9h0.8L93.6,55c0,0-0.4-0.5-0.4-1 C93.2,53.6,94.1,53.4,94.3,53.4z"
      />
    </g>
  )
}

export default withSkinColor(LeftHand, { character: "ManMixed" })
