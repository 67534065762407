import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import WarhammerL from "./WarhammerL"
import MaceL from "./MaceL"
import BowLeft from "./BowLeft"
import StaffLeft from "./StaffLeft"
import AxeLeft from "./AxeLeft"
import LuteLeft from "./LuteLeft"
import DaggerLeft from "./DaggerLeft"
import GoldAxeLeft from "./GoldAxeLeft"
import BattleAxeLeft from "./BattleAxeLeft"
import MorningStarLeft from "./MorningStarLeft"
import LongswordLeft from "./LongswordLeft"
import ShortswordLeft from "./ShortswordLeft"
import SlingShotLeft from "./SlingShotLeft"

const CATEGORY = "weaponLeft"
const CHARACTER = "ManDwarf"
export const IDS = {
  WARHAMMER_L: "WarhammerL",
  MACE_L: "MaceL",
  BOW_LEFT: "BowLeft",
  STAFF_LEFT: "StaffLeft",
  AXE_LEFT: "AxeLeft",
  LUTE_LEFT: "LuteLeft",
  DAGGER_LEFT: "DaggerLeft",
  GOLD_AXE_LEFT: "GoldAxeLeft",
  BATTLE_AXE_LEFT: "BattleAxeLeft",
  MORNING_STAR_LEFT: "MorningStarLeft",
  LONGSWORD_LEFT: "LongswordLeft",
  SHORTSWORD_LEFT: "ShortswordLeft",
  SLING_SHOT_LEFT: "SlingShotLeft"
}

export const components = {
  [IDS.WARHAMMER_L]: WarhammerL,
  [IDS.MACE_L]: MaceL,
  [IDS.BOW_LEFT]: BowLeft,
  [IDS.STAFF_LEFT]: StaffLeft,
  [IDS.AXE_LEFT]: AxeLeft,
  [IDS.LUTE_LEFT]: LuteLeft,
  [IDS.DAGGER_LEFT]: DaggerLeft,
  [IDS.GOLD_AXE_LEFT]: GoldAxeLeft,
  [IDS.BATTLE_AXE_LEFT]: BattleAxeLeft,
  [IDS.MORNING_STAR_LEFT]: MorningStarLeft,
  [IDS.LONGSWORD_LEFT]: LongswordLeft,
  [IDS.SHORTSWORD_LEFT]: ShortswordLeft,
  [IDS.SLING_SHOT_LEFT]: SlingShotLeft
}

export const Group = ({ props }) => (
  <g id="weaponLeft">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.WARHAMMER_L,
    name: "Warhammer L",
    defaultColors: [
      "#B9BABB",
      "#727373",
      "#E7E8E8",
      "#646464",
      "#7F8080",
      "#636464",
      "#CECFCF",
      "#A2A2A3",
      "#D6D7D6",
      "#A1A2A3"
    ],
    colorable: false,
    component: components[IDS.WARHAMMER_L],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MACE_L,
    name: "Mace L",
    defaultColors: [
      "#58595A",
      "#B9BABA",
      "#767575",
      "#9C9D9E",
      "#E1E2E3",
      "#E4E4E5"
    ],
    colorable: false,
    component: components[IDS.MACE_L],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BOW_LEFT,
    name: "Bow Left",
    defaultColors: [
      "#939393",
      "#EED793",
      "#B5AC95",
      "#925C25",
      "#AA8B2F",
      "#FFCE06",
      "#42140B"
    ],
    colorable: false,
    component: components[IDS.BOW_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.STAFF_LEFT,
    name: "Staff Left",
    defaultColors: [
      "#87551D",
      "#B78E5F",
      "#525A55",
      "#FFFFFF",
      "#27AAE1",
      "#8080BD",
      "#SVGID_",
      "#262A6B"
    ],
    colorable: false,
    component: components[IDS.STAFF_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.AXE_LEFT,
    name: "Axe Left",
    defaultColors: ["#8B5E3C", "#6D6E71", "#D1D3D4", "#808285"],
    colorable: false,
    component: components[IDS.AXE_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LUTE_LEFT,
    name: "Lute Left",
    defaultColors: ["#94712B", "#D9A53F", "#695020", "#B78C34", "#8F6D2B"],
    colorable: false,
    component: components[IDS.LUTE_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DAGGER_LEFT,
    name: "Dagger Left",
    defaultColors: [
      "#6E5E26",
      "#9AB8DB",
      "#6B92C0",
      "#57779C",
      "#E2E4E5",
      "#A0A2A5",
      "#EED793"
    ],
    colorable: false,
    component: components[IDS.DAGGER_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_LEFT,
    name: "Gold Axe Left",
    defaultColors: ["#8A7441", "#A49255", "#EECE6E"],
    colorable: false,
    component: components[IDS.GOLD_AXE_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BATTLE_AXE_LEFT,
    name: "Battle Axe Left",
    defaultColors: ["#7A7B7D", "#505052"],
    colorable: false,
    component: components[IDS.BATTLE_AXE_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MORNING_STAR_LEFT,
    name: "Morning Star Left",
    defaultColors: [
      "#949494",
      "#BDBDBC",
      "#D9D9D8",
      "#B78E5F",
      "#856745",
      "#523F2B",
      "#525252",
      "#C2C2C1",
      "#787879",
      "#SVGID_",
      "#FFFFFF",
      "#F6F6F6",
      "#E4E5E6",
      "#C8CACC",
      "#A2A4A7",
      "#757779",
      "#2D2D2E",
      "#000000"
    ],
    colorable: false,
    component: components[IDS.MORNING_STAR_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONGSWORD_LEFT,
    name: "Longsword Left",
    defaultColors: [
      "#D0AA2B",
      "#A9ABAE",
      "#D6D8DB",
      "#B49330",
      "#SVGID_",
      "#EED793",
      "#7B6529",
      "#A38634",
      "#7951A1",
      "#FFFFFF",
      "#F6F6F6",
      "#E4E5E6",
      "#C8CACC",
      "#A2A4A7",
      "#757779",
      "#2D2D2E",
      "#000000"
    ],
    colorable: false,
    component: components[IDS.LONGSWORD_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SHORTSWORD_LEFT,
    name: "Shortsword Left",
    defaultColors: ["#828284", "#CCCCCB", "#FFFFFF"],
    colorable: false,
    component: components[IDS.SHORTSWORD_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SLING_SHOT_LEFT,
    name: "Sling Shot Left",
    defaultColors: ["#362812", "#454545", "#949494", "#724E1F"],
    colorable: false,
    component: components[IDS.SLING_SHOT_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
