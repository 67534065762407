import React from "react"

const SlingShotL = ({
  colors = ["#352915", "#464646", "#939393", "#724F1F"]
}) => {
  return (
    <g id="SlingShotL">
      <path
        fill={colors[0]}
        d="M86.7787399,64.6889725l1.381897-8.2935867l5.3082199-2.1322746 c0,0,1.7430191,1.7710037,1.5389328,3.9359665c-0.0185547,0.333786,0.1655426,28.6360588,0.1655426,28.6360588 s-0.278244,1.9934311-1.1312714,2.2808151c-0.8530197,0.2873917-2.6517181-0.4822617-2.6517181-0.4822617l-0.2873154-2.3596954 l2.0040665-28.5154228l1.0895081-1.5298042l-0.8483124-1.3027306l-4.5525665,2.09058l0.7277451,1.9656639l-0.487114,7.2551842 l-1.9657288,2.2344284l0.6724243-4.5663452L86.7787399,64.6889725z"
      />
      <path
        fill={colors[1]}
        d="M95.4098282,85.59272c-0.616539-0.9550247-0.750946-1.5484314-2.0119247-1.4511337 c-0.1668854-0.009285-1.5901184-0.004715-1.6828384,0.1575394c-0.4543533,0.6443787-1.1266327,2.1973724-1.1266327,2.1973724 s0.8204727,1.8034058,1.9840775,1.9518127c0.8298264,0.1298447,2.6285934-0.6071854,2.6285934-0.6071854 S95.7157745,86.1119614,95.4098282,85.59272z"
      />
      <path
        fill={colors[2]}
        d="M93.4998474,85.3191147c-0.1483307-0.343071-0.2920303-0.769577-0.440361-1.112648 c-0.500679-0.0278397-1.1728897,0.0184937-1.3490601,0.1761093c-0.4543457,0.644371-1.1266251,2.1973648-1.1266251,2.1973648 s0.296669,0.6861343,0.852951,1.2192917c0.593399-0.1344147,1.1960831-0.4357224,1.8034058-0.8204727 C93.3560791,86.3992767,93.4720078,85.8197861,93.4998474,85.3191147z"
      />
      <path
        fill={colors[3]}
        d="M92.4195328,88.1886902l1.1728897-0.018486l0.2782516-1.9934311l-0.380043-2.1974411 l0.4092331-26.9300156l0.292099-0.7370987l0.644371,0.4543533l0.2535553,30.0639191c0,0-0.4636993,2.3179398-1.5299377,1.9238358 c-0.1622467-0.09272-1.502037-0.083519-1.9841537-0.445137C92.3407288,88.1006088,92.4195328,88.1886902,92.4195328,88.1886902z"
      />
    </g>
  )
}

export default SlingShotL
