import React from "react"

const ShoulderPlate = ({ colors = ["#9E9E9E"] }) => {
  return (
    <g id="ShoulderPlate">
      <path
        fill={colors[0]}
        d="M28.8527203,59.2710533c0,0-0.6741524,2.1067276,0.168539,3.7921104 c2.4438057,0.2528076,8.6797218-0.0842667,9.1853371-2.3595352c0.168541-0.6741524-0.4213448-2.6966133-0.4213448-2.6966133 C37.0268288,57.4171295,30.2852955,54.3834381,28.8527203,59.2710533z"
      />
      <path
        fill={colors[0]}
        d="M68.6739807,59.2710533c0,0,0.6741562,2.1067276-0.168541,3.7921104 c-2.4438019,0.2528076-8.679718-0.0842667-9.1853371-2.3595352c-0.1685371-0.6741524,0.4213486-2.6966133,0.4213486-2.6966133 C60.4156036,57.4171295,67.1571274,54.3834381,68.6739807,59.2710533z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M37.7852478,58.0070152 c0,0.1685371,0,1.0954971-0.0842667,1.1797676c-0.5056152,2.2752686-6.4887257,2.3595352-8.9325294,2.1067314 c-0.0842705-0.168541-0.0842705-0.5898857-0.168539-0.7584267c0,0.5898857,0.0842686,1.432579,0.3370762,2.0224609 c2.4438038,0.2528076,8.6797218-0.0842667,9.1853333-2.3595352 C38.2908669,59.8609352,37.9537888,58.6811676,37.7852478,58.0070152z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M59.7414513,58.0070152 c0,0.1685371,0,1.0954971,0.0842667,1.1797676c0.5056152,2.2752686,6.4887199,2.3595352,8.9325294,2.1067314 c0.0842667-0.168541,0.0842667-0.5898857,0.1685333-0.7584267c0,0.5898857-0.0842667,1.432579-0.3370743,2.0224609 c-2.4438095,0.2528076-8.6797218-0.0842667-9.1853409-2.3595352 C59.1515656,59.8609352,59.5729103,58.6811676,59.7414513,58.0070152z"
      />
    </g>
  )
}

export default ShoulderPlate
