import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongswordRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongswordRight") ? (
    <g id="LongswordRight">
      <path
        id="S_x24_longswordRight"
        d="M30.1381493,100.4786835c-4.0580349,0.5851135-7.865633-1.9979477-8.8466492-5.9988937 l-0.0713539-0.4948807c-0.2559586-1.0744705-0.2996902-2.0785065-0.1454716-3.111084l-0.7393284-3.0254593 c-2.8293457-0.7034225-4.9248829-2.6240311-5.9040585-5.210762c-1.4616261-3.8306198,0.3438892-7.4250793,1.684906-9.3360138 L5.9368334,32.1329041C3.1222253,21.0208416,4.927742,17.4263859,7.2442517,15.2737637l7.0549493-1.5223913 c3.8200293,1.2678213,6.2009859,5.1679544,8.5883856,14.0178328l10.1793613,41.1686859 c1.5283813,0.7899704,5.194191,3.0903702,5.822113,7.4453278c0.3995895,2.771347-0.6293068,5.445549-2.7621346,7.4706497 l0.7678719,3.2234116c0.6233215,0.8194351,0.9639816,1.7806625,1.2056694,2.7561569l0.0713539,0.4948807 c1.0238304,4.2978821-1.5868492,8.6146317-5.8847313,9.6384583l-1.5550823,0.4262848L30.1381493,100.4786835z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongswordRight, {
  character: "ManHalfling"
})
