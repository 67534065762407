import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WornShieldRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WornShieldRight") ? (
    <g id="WornShieldRight">
      <path
        id="S_x24_wornShieldRight"
        d="M30.9,103.7C12.3,96.3,4.5,71.9,4.2,70.9c0,0,1.9-5.3,5.8-9.3s18.9-3,18.9-3 c0.5,0.8,13.3,20.5,11.8,38.9h-0.1v0.1l-9.5,6H31L30.9,103.7z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WornShieldRight, {
  character: "ManDwarf"
})
