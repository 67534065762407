import React from "react"

const TorchR = ({
  colors = [
    "#664000",
    "#262261",
    "#F1D212",
    "#FFD400",
    "#FFD500",
    "#SVGID_",
    "#FFFFFF",
    "#F4B719",
    "#F49A35",
    "#F37B20",
    "#F1662E",
    "#EE4223"
  ]
}) => {
  return (
    <g id="TorchR">
      <path
        fill={colors[0]}
        d="M38.3,45l5.1,20.4l1.3,0.1l1-0.5l-3.9-20.6C39.3,44.2,38.3,45,38.3,45z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M43.4,53.4c0.1,0.2,0.1,0.3,0.2,0.5L45.7,65l-0.9,0.5 c-0.6-2.1-1.2-4.3-1.7-6.5c-1-4.4-1.9-9.6-3.5-14.4c0.1,0,0.2-0.1,0.4-0.1C41,47.5,42.4,50.5,43.4,53.4z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M42,58.1c0.8,2.4,1.4,4.9,2.1,7.4l-0.7,0l-2.9-11.6 C41,55.3,41.5,56.7,42,58.1z"
      />
      <path
        opacity="0.84"
        fill={colors[2]}
        d="M34.8,29.2c0.7-1.8,0.9-3.6,2-5.2c0,0,0.5-0.7,0.6-0.7c0.1,0.1,0.8,0.2,0.7-0.2 c-0.1-0.9-1-1.2-0.7-2.1c0.2-0.7,1.2-4.7,1.5-5.4c0.4,1.5,1.8,5.3,2.5,6.7c0,0,1.1,4.9,1.5,7c-0.2-0.1-0.6-0.3-0.5-0.1 c0.6,2.9,2.2,5.3,2.8,8.2c0,0.1,0.1,0.2,0.2,0.3c-0.2,1.7-1.1,3.2-2.1,4.6c0.6-1.5,1-3.1,1-4.7c0-0.2-0.3-0.5-0.6-0.7 c-0.1-1.9-0.3-3.6-1.3-5.2c-1.2-2.1-1.9-5.4-1.9-5.4c0-0.4-1.5-4.2-1.1-5.9c0.1-0.5-0.3,0.8-0.3,1.3c-0.1,0-0.1,0-0.1,0.1 c0,1.3-0.6,5.7-0.6,5.8c-1.2,1.5-1.2,1.8-1.8,3.6c-0.7,2.3-1,3.8-1.1,6.2c0,0.1,0,0.2,0.1,0.3c-0.4,1.4,0.2,2.9,0.5,4.3 c0,0.2,0.1,0.4,0.1,0.7c-0.6-1.3-1-2.7-1.5-4.1C34.8,35.3,34.8,32.2,34.8,29.2z"
      />
      <radialGradient
        id="SVGID_59_"
        cx="160.209"
        cy="32.638"
        r="16.681"
        gradientTransform="matrix(-1 0 0 1 199.077 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffd400" stopOpacity="0.5" />
        <stop offset="1" stopColor="#ffd500" stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_59_)" cx="38.9" cy="32.6" r="16.7" />
      <g fill="#FFF">
        <path
          opacity="0.18"
          d="M39.4,49.3L39.3,49c1.3,0.8,2.6,1.6,3.8,2.4l0.1,0.4C41.9,51,40.6,50.2,39.4,49.3z"
        />
        <path
          opacity="0.18"
          d="M39.8,51.1l0-0.1c1.2,0.6,2.4,1.2,3.6,1.7l0.1,0.3C42.2,52.4,41,51.8,39.8,51.1z"
        />
        <path
          opacity="0.18"
          d="M38.8,47L38.3,45c0,0,1-0.9,3.5-0.7l1.1,5.8C41.5,49.1,40.1,48,38.8,47z"
        />
      </g>
      <g opacity="0.98">
        <path
          fill={colors[2]}
          d="M37.5,45.5c-1.2-3.5-1.4-6.6-0.6-9.6c0.3-1.2,0.8-2.4,1.4-3.6c0.4-1,0.9-2.1,1.2-3.2 c0.1-0.3,0.2-0.6,0.3-1l0.3,0.1c-0.1,0.5,0,1,0,1.5c0.2,1.4,0.9,2.7,2,3.9c0,0,0,0.1,0,0.1l-0.3,0.6l0.5,0.1 c0.2,0.7,0.4,1.4,0.6,2.1c0.1,0.4,0.3,0.9,0.4,1.4c0.1,0.3,0.2,0.7,0.2,1.1c-0.3,1.3-0.7,2.5-1.1,3.7c-0.2,0.5-0.4,0.9-0.5,1.4 c-0.8,0.7-1.7,1.3-2.7,1.8C38.6,46,38,45.8,37.5,45.5z"
        />
        <path
          fill={colors[7]}
          d="M39.2,46.2c0.8-0.4,1.6-0.9,2.3-1.5c0.2-0.4,0.3-0.8,0.4-1.2c0.4-1.1,0.8-2.3,1.1-3.5 c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.5-0.3-0.9-0.4-1.3c-0.2-0.6-0.4-1.2-0.5-1.9l-0.8-0.2l0.4-1c-1-1.1-1.6-2.3-1.9-3.5 c-0.3,0.7-0.6,1.4-0.9,2.1c-0.5,1.2-1,2.4-1.3,3.5c-0.8,2.9-0.6,5.7,0.5,9.1C38.4,46,38.8,46.1,39.2,46.2z"
        />
        <path
          fill={colors[8]}
          d="M39.3,46.2c0.7-0.4,1.3-0.8,1.9-1.2c0.1-0.3,0.2-0.6,0.4-1c0.4-1,0.7-2.1,1-3.3c0-0.3-0.1-0.5-0.2-0.7 c-0.1-0.5-0.3-0.9-0.4-1.3c-0.1-0.5-0.3-1-0.5-1.6l-1.1-0.3l0.5-1.3c-0.5-0.7-1-1.3-1.3-2.1c-0.1,0.2-0.2,0.4-0.3,0.6 c-0.5,1.2-1,2.3-1.3,3.4c-0.7,2.7-0.6,5.4,0.4,8.5C38.8,46.1,39,46.2,39.3,46.2z"
        />
        <path
          fill={colors[9]}
          d="M39.3,46.2c0.5-0.3,1-0.6,1.4-0.9c0.1-0.2,0.2-0.5,0.3-0.8c0.4-1,0.7-2,1-3.1c0-0.2-0.1-0.4-0.1-0.5 c-0.1-0.4-0.3-0.9-0.4-1.3c-0.1-0.4-0.3-0.9-0.4-1.3l-1.4-0.4l0.6-1.5c-0.2-0.3-0.4-0.6-0.6-0.9c-0.4,1-0.8,1.9-1,2.8 c-0.7,2.5-0.6,5,0.3,7.9C39.2,46.2,39.2,46.2,39.3,46.2z"
        />
        <path
          fill={colors[10]}
          d="M39.5,46c0.3-0.2,0.6-0.4,0.8-0.5c0.1-0.2,0.1-0.4,0.2-0.5c0.3-0.9,0.6-1.8,0.9-2.8 c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.4-0.2-0.9-0.4-1.3c-0.1-0.3-0.2-0.7-0.3-1.1L39.3,39C38.7,41.2,38.8,43.4,39.5,46z"
        />
        <path
          fill={colors[11]}
          d="M39.4,46.3c0-0.1,0.1-0.2,0.1-0.3c0.3-0.8,0.6-1.7,0.8-2.6c0-0.1,0-0.1,0-0.2c-0.1-0.4-0.2-0.9-0.4-1.3 c-0.1-0.3-0.2-0.5-0.2-0.8L39.1,41C38.8,42.6,38.9,44.4,39.4,46.3C39.4,46.3,39.4,46.3,39.4,46.3z"
        />
      </g>
    </g>
  )
}

export default TorchR
