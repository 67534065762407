import React from "react"

const ShortSkirt = ({ colors = ["#004152"] }) => {
  return (
    <g id="ShortSkirt">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={colors[0]}
        d="M79.4228592,57.0993614 c0,0-6.0015793,1.2185783-10.9431686,1.2185783c-4.9415283,0.2000122-10.5898285-1.5930595-10.5898285-1.5930595 s-6.1467857,13.7315865-2.8668518,28.1673164c0.0402832-0.8640747,1.0255775,1.0411987,1.226078,0.2088013 c0.5637817-2.3401489,1.549736-11.2723007,2.4139328-13.5180283c0.1112671-0.2892456,1.6092911,13.5180283,1.6092911,13.5180283 s14.818264-0.5894165,22.9586449-0.6508789C83.4460831,64.5020905,79.4228592,57.0993614,79.4228592,57.0993614z"
      />
    </g>
  )
}

export default ShortSkirt
