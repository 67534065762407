import React from "react"

const BotaBagLeft = ({
  colors = [
    "#715C40",
    "#CDA774",
    "#B09064",
    "#A17955",
    "#C5962C",
    "#EED793",
    "#80682A"
  ]
}) => {
  return (
    <g id="BotaBagLeft">
      <path
        fill={colors[0]}
        d="M54.0983696,78.2024536c0,0,0.5629845-0.0804291,0.3217087-0.3217087 c-0.2412796-0.3217087-18.0959816-20.0262222-18.0959816-20.0262222s-0.3217087-0.4021339-0.5629883-0.1608543 c-0.2412796,0.1608543,0,0.5629883,0,0.5629883L54.0983696,78.2024536z"
      />
      <path
        fill={colors[1]}
        d="M58.682682,79.4088516c0,0,3.4583435,0.6434097,3.2170639,5.3081512 c-0.2412834,4.6647415-3.6191978,4.3430405-6.0319939,0.2412796c-2.4127998-4.1017532-2.9757843-8.4447861-2.9757843-8.4447861 l1.0455437-0.8042679C53.9375153,75.7092285,56.1090317,80.1326904,58.682682,79.4088516z"
      />
      <path
        fill={colors[2]}
        d="M54.4200745,80.7761002c-1.0455475-2.2519455-1.5281067-4.2626114-1.5281067-4.2626114 l1.0455437-0.8042679c0,0,0.4825592,0.9651184,0.8846931,1.4476852 C54.8222046,78.4437332,54.5005035,79.4892731,54.4200745,80.7761002z"
      />
      <path
        fill={colors[2]}
        d="M57.8784142,84.4757309c0.5629883-0.5629883,0.5629883-1.3672562,0-1.9302444 c-0.0804253-0.0804214-0.1608505-0.0804214-0.2412758-0.0804214c0.1608505,0,0.1608505-0.0804291,0-0.1608505 c-0.1608543-0.1608505-0.4825592-0.1608505-0.6434135-0.1608505c-0.7238388,0-1.0455437,0.6434097-1.4476776,1.3672485 c0,0.0804214,0-0.0804291,0,0c-0.2412796-0.4021378-0.4021339-0.8846893-0.6434135-1.2868271 c0.3217087-1.3672485,0.4825592-2.8149261,0.4021339-4.3430328c0.4021339,0.4021301,1.1259727,0.9651108,1.608532,1.3672485 c-0.2412796,0.2412796-0.4825592,0-0.4825592,0.3217087c0.0804253,0.4825516-0.2412796,0.7238388,0.2412796,0.8042603 c0.4021339,0.0804291,0.8042679,0.1608505,1.0455437-0.2412796c0.0804253-0.1608505,0.1608543-0.4021301,0.1608543-0.6434174 c0.2412796,0,0.4825592,0,0.7238426-0.0804214c0,0,0.0804253,0,0.1608543,0 c1.0455437,2.0106659,1.6085281,3.9409027,1.5281029,6.5145493c0,0.8042679-0.0804253,1.5281067-0.4021301,2.1715164 c-1.206398,0.0804291-2.7345047-0.9651184-4.0213318-3.1366348c0,0,0.8846931,0,0.8846931-0.0804291 C57.3958588,85.119133,57.3154335,85.0387115,57.8784142,84.4757309z"
      />
      <path
        fill={colors[2]}
        d="M59.88908,79.9718323c0.9651184,0.6434174,2.1715164,2.0106659,2.0106659,4.7451706 c-0.0804253,1.0455475-0.3217087,1.8498154-0.6434174,2.412796C61.8193207,84.7170029,61.0150528,82.2237778,59.88908,79.9718323 z"
      />
      <path
        fill={colors[3]}
        d="M59.4869461,88.0949249c0,0-1.4476776-0.0804291-2.81493-1.4476852 c-0.7238388-0.8042603-3.3779144-5.9515686-4.4234619-10.6163025l0.6434135,0.4021301 C53.6158066,78.2024536,55.4656181,86.1646805,59.4869461,88.0949249z"
      />
      <path
        fill={colors[4]}
        d="M54.2592201,75.9505081l-1.206398-2.3323746c0,0-0.9651222-0.4825592-0.8846931,0.3217087 c0.0804253,1.206398,0.0804253,2.6540756,0.0804253,2.6540756s0.8042679,1.1259766,1.8498116-0.4021301 C54.1787949,76.1113586,54.1787949,76.0309372,54.2592201,75.9505081z"
      />
      <path
        fill={colors[5]}
        d="M53.1332512,74.0202713c0,0-0.1608543,0.2412796-0.3217087,0.1608505 c0.0804253,0.4021301,0.8042641,2.4932251,0.8042641,2.4932251l0.4825592-0.4825592L53.1332512,74.0202713z"
      />
      <ellipse
        transform="rotate(-86.811 52.606 73.832)"
        fill={colors[6]}
        cx="52.606"
        cy="73.832"
        rx="0.241"
        ry="0.402"
      />
    </g>
  )
}

export default BotaBagLeft
