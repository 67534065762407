import { takeEvery, put } from 'redux-saga/effects'
import { apiRoutine } from '../routines'
import { apiRequestAction } from '../reducers/apiReducer'

import get from 'lodash/get'

export function* apiSuccessWatcherSaga() {
  yield takeEvery(apiRoutine.SUCCESS, apiSuccessWorker)
}

const onSuccessCallbackActions = {
  deletedFile: [
    {
      action: apiRoutine(apiRequestAction('minis.get', 'userMinis'))
    }
  ]
}

function* apiSuccessWorker(action) {
  try {
    const callbacks = get(onSuccessCallbackActions, action.payload.key)

    for(let i in callbacks) {
      yield put(callbacks[i].action)
    }
  }
  catch(e) {
    console.log(e)
  }
}
