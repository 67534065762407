import React from "react"

const Belt = ({ colors = ["#1E1E1E", "#9C7000", "#FFFFFF"] }) => {
  return (
    <g id="Belt">
      <polygon
        fill={colors[0]}
        points="37.8470688,78.2270966 60.0375099,78.468483 59.961483,83.7791443 37.7085152,83.7187958"
      />
      <g fill={colors[1]}>
        <path d="M45.3095894,78.0661926v5.7129517h7.1684341v-5.7129517H45.3095894z M51.8129082,82.8940125h-5.9121437 v-3.7817993h5.9121437V82.8940125z" />
        <rect x="49.726" y="80.688" width="2.566" height="0.744" />
      </g>
      <path
        opacity="0.2"
        fill="#FFF"
        d="M59.9774551,83.7791672l0.0739098-5.3106766 l-2.2216187-0.0180588c0.0813408,0.2750854,0.1553345,0.5712891,0.2217064,0.8556519 c0.4484558,1.5009766,0.9217415,2.9891968,1.4161644,4.4713745L59.9774551,83.7791672z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M58.8037338,83.7756271l0.3311272,0.0009155 c-0.053421-0.1040039-0.1073494-0.2077026-0.1606026-0.3117676c-0.2622337-0.4841309-0.2803383-1.3665771-0.4430733-1.7550659 c0.2436256,0.581665,0.4603958,1.1637573,0.6775589,1.7571411c0.0482674,0.1318359,0.084198,0.2293701,0.1141319,0.3101807 l0.0132866,0.000061c-0.0818977-0.2406616-0.2032623-0.5921021-0.2407646-0.7116089 c-0.2680626-0.8538818-0.5241318-1.7122192-0.7752647-2.5721436c-0.0465851-0.159668-0.1427231-0.6471558-0.2686806-1.1872559 c-0.0851517-0.2849121-0.1662102-0.5706177-0.2496223-0.8560181l-0.7752647-0.0090942 C57.6927452,80.1785202,58.3238869,81.9690475,58.8037338,83.7756271z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M57.6741333,81.7383347 c0.0117722,0.0361938,0.021637,0.0731201,0.0332985,0.109314c0.0415382,0.140625,0.0833015,0.2807617,0.1238289,0.4214478 c0.0294304,0.0952759,0.0549927,0.1920776,0.0844231,0.2873535c0.1559486,0.2861938,0.3188515,0.5874634,0.4732323,0.9004517 c-0.4022636-1.4974365-0.840126-2.9680176-1.4955978-4.3583374c0.0802727,0.3018799,0.1616135,0.6033325,0.2430077,0.9048462 C57.3227119,80.5799484,57.5018158,81.1583176,57.6741333,81.7383347z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M54.2936287,80.9282761 c0.1388512,0.8590088,0.2108841,1.8817749,0.3876305,2.8362427l0.8446655,0.0023193l-0.1752892-0.3156738 c-0.2776489-0.5001221-0.7666321-1.5836792-0.156456-0.47052c-0.4455376-0.8128052-0.5143204-1.9909058-0.6992531-2.907959 c-0.1115532-0.5532837-0.2079124-1.1097412-0.3097687-1.664978l-0.4198647-0.0048828 C53.9749451,79.2355881,54.1553917,80.0731125,54.2936287,80.9282761z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M53.5866394,78.4007492l-0.0187225-0.0002441 c-0.0092506,0.2088013-0.0179367,0.4177246-0.0169296,0.6268921c0.0773582,0.6137085,0.1511841,1.2279053,0.2352142,1.8406372 c0.1254005,0.9136963,0.1547165,1.9671631,0.4122429,2.8952026l0.4077568,0.0010986 C54.133194,82.0172653,53.8964081,80.2451096,53.5866394,78.4007492z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M43.536438,78.2830734l-0.3173943-0.0037231 c-0.1090851,1.8250732-0.3548393,3.6347656-0.5683022,5.4527588l0.4200897,0.0011597 C42.8679619,81.971611,43.0439224,80.0189133,43.536438,78.2830734z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M42.3342438,78.2689743 c-0.0392952,1.8171387-0.1563416,3.6392822-0.305172,5.4614258l0.0632324,0.0001831 c0.2301102-1.8138428,0.4346085-3.6292114,0.4749107-5.4588623L42.3342438,78.2689743z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M40.9785652,81.4753952 c0.0089684-0.1308594,0.1696854-0.2468262,0.3294449-0.3605957c0.0871696-0.9521484,0.1781502-1.9044189,0.2840958-2.8545532 l-0.4811897-0.0056152c0.0014572,0.00354,0.0014572,0.0105591,0.0031967,0.0133667 c-0.002243-0.00354-0.0026932-0.0093994-0.0045967-0.0133667l-0.1512985-0.00177 c-0.3187408,1.8172607-0.6274986,3.6396484-0.8592949,5.4723511l0.6931419,0.0018921 C40.8578758,82.9769211,40.9268799,82.2270432,40.9785652,81.4753952z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M40.3630066,78.245903l-0.1191788-0.0014038 c-0.3280983,1.5853271-0.4899368,3.2790527-0.572731,4.822876c-0.0130043,0.2424316-0.3737297,0.4416504-0.5109596,0.6553345 l0.4489059,0.0012207C39.8403893,81.8955612,40.0895042,80.0695724,40.3630066,78.245903z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M39.2243156,78.2401657l-1.3872986-0.0130615 l-0.1346474,5.4916992l0.7615929,0.0037842C38.6754761,81.7363052,38.9340973,80.4385376,39.2243156,78.2401657z"
      />
      <rect
        x="45.431"
        y="78.214"
        opacity="0.36"
        fill="#FFF"
        width="6.885"
        height="0.362"
      />
      <rect
        x="45.924"
        y="82.948"
        opacity="0.36"
        fill="#FFF"
        width="5.838"
        height="0.134"
      />
    </g>
  )
}

export default Belt
