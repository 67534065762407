import React from 'react'

const Home = () => (
  <div>
    <section className="hero" >
        <div className="container">
        <img src="hero.jpg" />
        </div>
    </section>
    <section className="section">
      <div className="container">
        <div style={{ width: 560, margin: "0 auto"}}>
          <h2 className="subtitle">Watch to get started!</h2>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/2Dpimi7-NKs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h1 className="title">Customizable paper minis for tabletop gaming</h1>
        <p>
          Hi! I'm Jeremy and I created Printablepawns to make it easy to customize and print the minis I need for my games. Currently, there are only dwarves but I'm working feverishly on the other races. If you have any feedback, hit me up on Twitter at @printablepawns.
        </p>
      </div>
    </section>
    <section className="section">
      <div>
        <p>
         <h2 className="subtitle"> How it works:</h2>
          <ol>
            <li>Click on the "builder" link to customize your pawn.</li>
            <li>Click save when you are ready (login required). </li>
            <li>Go to the pawns page and click on the "+" sign to add a pawn on the print sheet.</li>
            <li>Click "create print sheet" when you have selected all the pawns you want to print.</li>
            <li>The printable sheet will appear on the "printable sheets" page.</li>
            <li>Click the cloud icon to download a sheet and print, cut and enjoy!</li>
          </ol>
        </p>
      </div>
    </section>
  </div>
)

export default Home