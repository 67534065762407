import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import PuffyShirt from "./PuffyShirt"
import White from "./White"
import Tank from "./Tank"

const CATEGORY = "shirts"
const CHARACTER = "ManGnome"
export const IDS = {
  PUFFY_SHIRT: "PuffyShirt",
  WHITE: "White",
  TANK: "Tank"
}

export const components = {
  [IDS.PUFFY_SHIRT]: PuffyShirt,
  [IDS.WHITE]: White,
  [IDS.TANK]: Tank
}

export const Group = ({ props }) => (
  <g id="shirts">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.PUFFY_SHIRT,
    name: "Puffy Shirt",
    defaultColors: ["#E8E7C4"],
    colorable: true,
    component: components[IDS.PUFFY_SHIRT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WHITE,
    name: "White",
    defaultColors: ["#D6D3C9"],
    colorable: true,
    component: components[IDS.WHITE],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.TANK,
    name: "Tank",
    defaultColors: ["#77634F"],
    colorable: true,
    component: components[IDS.TANK],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
