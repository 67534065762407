import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Cloak = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Cloak") ? (
    <g id="Cloak">
      <path d="M49.7582855,15.9551897c1.7325211-0.0977182-2.3323822-12.6956205,6.9735909-20.0624771 c6.7391777-5.3349152,23.1737709-4.2914391,27.1433525,1.6092896s3.8487549,17.5523777,4.6132965,17.7021866l16.843895,7.1881599 c4.4975052,1.9733963,4.7346497,11.9101048,5.2802124,16.7911091l6.3537064,56.8444977 c4.780838,12.3004608-7.9899368,14.4171524-9.4570236,14.9780502c-3.790596,1.4492264-11.1094513,4.212532-11.1889114,3.974144 c-0.0972977-0.2918854-40.6063957,0.8279877-48.1499557,1.0390854c-0.800724,0.0224075-1.6007271-0.025116-2.3931847-0.1420364 l-7.9794235-1.1772919c-2.5599861-0.3777008-14.2173309-6.0496292-14.2173309-6.0496292 c-3.7052555-3.1643448-5.7793198-12.152298-4.9351559-16.9511871l7.7245903-46.0256844 c0.1231499-0.7000809,2.8483028-15.9731483,4.6132965-20.8134785 C32.9972496,19.3366928,49.7582855,15.9551897,49.7582855,15.9551897z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Cloak, {
  character: "ManDragonborn"
})
