import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GoldAxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GoldAxeRight") ? (
    <g id="GoldAxeRight">
      <path
        id="S_x24_goldAxeLeft_3_"
        d="M59.0710869,115.6582108l-5.2553253-3.8716736l-7.449276-27.1269455l-1.6198044,0.8498306 c-3.9080009-1.0866699-6.9727821-3.948204-8.0581741-7.5803909l0,0c-1.0853882-3.6321869-0.1828041-7.7315292,2.5348892-10.82798 l3.1544991-0.2857132l2.8945236-2.529686l1.9477272-0.6268234l0.4948463-0.1926651l0.143837,0.0316696l0.0158348-0.0719185 l0.3193436-0.0804977l3.8908386,0.4796562l2.6570053-1.4509163c3.9799156,1.1025047,6.9727821,3.9482002,8.1300888,7.5962257l0,0 c1.0853882,3.6321945,0.1828079,7.7315292-2.5348892,10.82798l-1.9965477,0.1636429 c1.9075165,7.4326324,3.8869553,14.8810883,4.6503563,17.2359161c1.1170578,3.4883499,3.0667877,7.9894943,3.6764526,9.3302002 l-0.3259201,4.904953c-0.3582611,0.599762-0.7884521,1.1836853-1.2588806,1.607933l-3.4236946,1.5083237L59.0710869,115.6582108z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GoldAxeRight, {
  character: "WomanGnome"
})
