import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Curly from "./Curly"

const CATEGORY = "hair"
const CHARACTER = "ManHalfling"
export const IDS = {
  CURLY: "Curly"
}

export const components = {
  [IDS.CURLY]: Curly
}

export const Group = ({ props }) => (
  <g id="hair">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.CURLY,
    name: "Curly",
    defaultColors: ["#7C4F2E", "#FFFFFF"],
    colorable: true,
    component: components[IDS.CURLY],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
