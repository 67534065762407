import React from "react"

const Feet = ({
  colors = ["#494846", "#01060F", "#FFFFFF", "#9C7000", "#6B6967"]
}) => {
  return (
    <g id="Feet">
      <g id="C_x24_boots_2_">
        <path
          fill={colors[0]}
          d="M41.3158455,112.4605255c-0.233078,2.0456238-1.3598366,3.9029388-1.5585098,5.5686264 c-0.0667877,1.0209885-0.0707245,1.4131241-0.1791992,2.6359329c-1.7259521,0-2.5889282,0-4.3148804,0 c0,0,1.4794807-2.6038055,2.179966-3.3253937c0.3865204-1.0503235,0.2060471-4.5816116,0.2060471-4.5816116 S39.0858612,113.7438507,41.3158455,112.4605255z"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M38.2562904,116.8008728 c0.2239761-0.4704742,0.5019264-3.3096848,0.6036797-3.7041931c0.2218704,0.027565,0.5976486-0.0958252,0.9360733-0.1275864 c-0.1112061,0.4361954-0.2883492,2.8945465-0.7874603,3.9612808 C38.8308868,116.7388229,38.5770912,116.7066345,38.2562904,116.8008728z"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M36.5532761,118.4537354 c0.0086555-0.020752,0.0129852-0.0311279,0.0216179-0.0518494c0,0,1.6910324-2.6605682,2.9927902,0.1588898 c-0.0959511,0.0302505-0.2710915,0.0944366-0.3739662,0.1405029 c-0.0036354-0.0008698-0.0821381-0.0632172-0.137867-0.0732574 C38.2976418,116.504715,36.8415947,118.2546997,36.5532761,118.4537354z"
        />
        <path
          fill={colors[0]}
          d="M37.0409737,117.7924805c0.3461914-0.4822083,0.5617752-0.9360275,1.5269318-0.9187775 c0.5780334,0.0693512,0.7876396,0.6180954,0.9934654,1.6425018 c-0.1193428,0.0308228-0.2425957,0.0804291-0.3723183,0.1394196 c-0.0024414-0.0006485-0.0081482-0.0017624-0.0100555-0.0023041 c-0.1255112-0.5028152-0.5372124-1.2412643-1.0310249-1.2095184 c-0.6576538,0.0422897-1.1193542,0.572937-1.5662422,0.9767914 C36.4834824,118.4260178,36.7707405,118.1703644,37.0409737,117.7924805z"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M38.483284,117.7119293 c-0.0870476,0.09729-0.1317329,0.147934-0.2221832,0.2531891c0,0-0.2434807-0.3034515-0.7050323,0.0679932 c0.3874207-0.6018372,0.3061409-0.4060898,0.3061409-0.4060898S38.1758652,117.3602295,38.483284,117.7119293z"
        />
        <path
          opacity="0.2"
          fill="#FFF"
          d="M39.5744591,118.5121765 c-0.1192932,0.0294037-0.2423286,0.0786285-0.3718872,0.1371841 c-0.1184158-0.5297012-0.6690063-1.3881454-1.1540184-1.3538895 c-0.287899,0.0203323-0.4191856-0.0330429-0.4158859-0.122673 C38.3574944,116.4536285,39.5792694,117.3456268,39.5744591,118.5121765z"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M39.8940125,112.9987488 c-0.4365959,1.5595551-0.3092194,2.841011-0.8527184,3.9798203c0,0,0.2675362,0.4439697,0.4089775,0.0884781 c0.141449-0.3554916,0.6461143-3.5236435,0.8079872-3.7688065 C40.4201355,113.0530701,40.2205315,112.8223572,39.8940125,112.9987488z"
        />
        <path
          fill={colors[0]}
          d="M39.8417854,112.9170303c-0.4262543,1.5406723-0.2641907,2.8811874-0.8215714,3.9904785 c0,0,0.2677956,0.4292297,0.4139214,0.0781937c0.146122-0.3510513,0.6173058-3.5329361,0.7763901-3.77565 C40.3696175,112.9673462,40.166954,112.7408752,39.8417854,112.9170303z"
        />
        <path
          fill={colors[0]}
          d="M38.8367805,112.9456482c-0.3806305,1.501709-0.1200294,2.788208-0.6164436,3.8979492 c0,0-0.3753967,0.3281784-0.3883591-0.1563873c-0.0129585-0.4845581,0.5702286-3.1680145,0.5629845-3.4193954 C38.3877258,113.0164337,38.6613159,113.0113602,38.8367805,112.9456482z"
        />
        <g>
          <g opacity="0.3" fill={colors[1]}>
            <path d="M38.5879288,113.8465195 c0.1342049-0.0954056,0.2039223-0.1424026,0.3510551-0.2330856 c-0.1035271-0.1062622-0.1521301-0.1578064-0.2482452-0.2586365 c-0.0290375-0.0304565-0.027256-0.0992279,0.0043411-0.1526947 c0.0315895-0.0534286,0.081974-0.0722351,0.1115494-0.0419769c0.1266403,0.1295013,0.1804886,0.1932678,0.344944,0.3379517 c0.2255898-0.1077957,0.3384323-0.1564331,0.5701752-0.2526321c0.0541229-0.0224762,0.106575,0.0070724,0.11549,0.0663834 c0.0089149,0.0592957-0.0285988,0.1265488-0.0822029,0.1487579 c-0.1775169,0.0735321-0.2643585,0.1105957-0.4370193,0.1896667 c0.1246681,0.1188202,0.1856003,0.1816101,0.2988358,0.3132172 c0.0341835,0.0397339,0.0298119,0.1160736-0.0077057,0.1687622 c-0.0375252,0.0527267-0.0941658,0.062561-0.1280136,0.0228043 c-0.1449394-0.1701279-0.225605-0.2498169-0.3902283-0.3972168 c-0.2223015,0.1089783-0.3004799,0.1732788-0.4744873,0.2964325 c-0.0406532,0.0287399-0.0797806,0.0055008-0.0875664-0.0515823 C38.5210724,113.9455414,38.5474052,113.8752899,38.5879288,113.8465195z" />
            <path d="M38.3918037,115.1252441 c0.125248-0.0951385,0.1905212-0.1424942,0.3296432-0.2353058 c-0.1090508-0.1106033-0.1590385-0.1648026-0.2554855-0.2714615 c-0.0291367-0.0322113-0.030365-0.1044617-0.0028381-0.1607819 c0.0275269-0.056282,0.0741768-0.0760727,0.1037865-0.0437775c0.1267624,0.1382065,0.1871033,0.20327,0.3472672,0.356575 c0.2026176-0.1095123,0.2979546-0.1560287,0.4959717-0.2467957 c0.0462494-0.0212173,0.0897293,0.013855,0.0956039,0.0779953c0.0058708,0.0641403-0.02631,0.1332169-0.070488,0.15345 c-0.1462975,0.0670166-0.2174454,0.1012115-0.3647461,0.177536 c0.112999,0.1272125,0.1640244,0.1950531,0.2551956,0.3366165 c0.0275192,0.0427475,0.0251236,0.1201172-0.0026054,0.1707535 c-0.0277328,0.0506897-0.0712891,0.0568619-0.0995522,0.0149994 c-0.121006-0.1791687-0.1911736-0.2635193-0.3504829-0.4164886 c-0.1973839,0.1093826-0.2782326,0.1760864-0.4418411,0.2974243 c-0.0382271,0.0283127-0.0778465,0.0043716-0.0885086-0.0533676 C38.3320618,115.2248383,38.353981,115.1539383,38.3918037,115.1252441z" />
            <path d="M38.276165,116.3775024 c0.1180115-0.0884323,0.1800117-0.1326752,0.3140717-0.2200775 c-0.1178551-0.1067352-0.1713676-0.1597748-0.2734261-0.2652054 c-0.0308304-0.0318375-0.0363655-0.1024857-0.0126076-0.157402c0.0237617-0.0548782,0.0685234-0.0737,0.0998383-0.0414276 c0.1340752,0.1381073,0.2079811,0.2041855,0.3696365,0.3495178 c0.1838417-0.1073456,0.2593727-0.148819,0.4102783-0.2263641 c0.0352402-0.0181274,0.0702591,0.0192566,0.0782051,0.0822144 c0.0079498,0.0629501-0.0118866,0.1273575-0.0445633,0.1439972 c-0.1081886,0.0550766-0.1628342,0.0842896-0.288414,0.1548157 c0.1109047,0.1218719,0.1592216,0.1874695,0.2475586,0.3235703 c0.0266685,0.0410995,0.0338135,0.1120453,0.0177116,0.1564865 c-0.016098,0.0444641-0.0517311,0.0477905-0.0809441,0.0086365 c-0.1250725-0.1675949-0.193779-0.2488022-0.3606453-0.3876114 c-0.1854172,0.109436-0.2682648,0.1653214-0.4228477,0.2770462 c-0.0361176,0.0260773-0.077198,0.0035095-0.0917969-0.0506744 C38.2236214,116.4708023,38.2405243,116.4041824,38.276165,116.3775024z" />
          </g>
        </g>
        <g>
          <g fill={colors[3]}>
            <path d="M37.9903145,116.7197723c0.043541,0.0037689,0.0835075-0.0626984,0.0893784-0.1501999 c0.005867-0.0875092-0.0247879-0.1626511-0.0685081-0.1665268 c-0.043396-0.0038605-0.0839005,0.0649872-0.0895882,0.1525879 C37.9159088,116.6432495,37.9471016,116.7160339,37.9903145,116.7197723z" />
            <path d="M38.0591049,116.1083679c0.0440903,0.0039215,0.0876694-0.0658875,0.0972099-0.1573029 c0.0095367-0.0914078-0.0187531-0.1693573-0.0629997-0.1732025 c-0.0439186-0.0037994-0.0876236,0.0677719-0.0970039,0.1590958 C37.986927,116.0282745,38.0153465,116.1044769,38.0591049,116.1083679z" />
            <path d="M38.134594,115.4714813c0.0444717,0.0037079,0.0910454-0.068512,0.1038017-0.162262 c0.0127487-0.0937347-0.0133896-0.1729431-0.0581055-0.176445 c-0.0443802-0.0034714-0.0908813,0.0698318-0.1033897,0.1633606 C38.0643883,115.3896637,38.0904579,115.467804,38.134594,115.4714813z" />
            <path d="M38.2268257,114.821167c0.0450211,0.0032883,0.0943985-0.0701752,0.1100197-0.164566 s-0.0088234-0.1734009-0.0542946-0.1764526c-0.0451317-0.0030212-0.0943451,0.0707855-0.10952,0.1649475 C38.1578522,114.7392502,38.1821404,114.8179016,38.2268257,114.821167z" />
            <path d="M38.3377151,114.1692047c0.0460091,0.0028458,0.0983009-0.0704956,0.116539-0.1638184 c0.0182419-0.0933228-0.0051193-0.1707458-0.051899-0.1733932 c-0.0464287-0.0026398-0.0985565,0.0702896-0.1160355,0.1634216S38.2920456,114.1663742,38.3377151,114.1692047z" />
            <path d="M38.4978828,113.5291595c0.0479126,0.002655,0.1038742-0.068924,0.1247902-0.1593781 c0.0209122-0.0904541-0.0021133-0.1651154-0.0511971-0.1677094 c-0.0487175-0.0025787-0.1045723,0.0678253-0.1243095,0.1582184 C38.4274216,113.4506989,38.4503326,113.526535,38.4978828,113.5291595z" />
          </g>
          <g fill={colors[3]}>
            <path d="M39.2676697,116.8488083c0.0359116,0.0167694,0.0742798-0.0260239,0.0747147-0.1129684 c0.0004311-0.0869598-0.0242348-0.1734161-0.0604515-0.1895447 c-0.0359421-0.0160065-0.0692596,0.0440369-0.0688705,0.1307068S39.232029,116.8321609,39.2676697,116.8488083z" />
            <path d="M39.3172264,116.2561035c0.0373573,0.0160065,0.0729256-0.0441818,0.0838585-0.1376266 c0.0109291-0.0934525-0.0094452-0.183609-0.0494881-0.1988144 c-0.0397453-0.0150833-0.0822105,0.0497513-0.0904541,0.142395 C39.252903,116.1547012,39.2801476,116.2402191,39.3172264,116.2561035z" />
            <path d="M39.399929,115.6061478c0.0429878,0.0143585,0.0902824-0.0511246,0.1087418-0.1485062 c0.0184555-0.0973816-0.0023193-0.1883392-0.0490456-0.2015457 c-0.0463867-0.0130997-0.0986023,0.0555115-0.1133194,0.151741 C39.3315926,115.5040741,39.3572578,115.5919037,39.399929,115.6061478z" />
            <path d="M39.5225143,114.932724c0.0503654,0.0120468,0.1083374-0.0571594,0.1312866-0.1559143 s-0.0014687-0.1880341-0.0558548-0.1987381c-0.0539818-0.0106277-0.1149216,0.0602417-0.1338501,0.1576462 C39.4451714,114.8331299,39.4725189,114.9207687,39.5225143,114.932724z" />
            <path d="M39.6709785,114.2545776c0.0579338,0.0094376,0.1243439-0.0616913,0.1488304-0.1592712 c0.0244865-0.0975647-0.0054893-0.1829834-0.0669594-0.19104 c-0.0610161-0.0079956-0.1284332,0.0632935-0.1493797,0.1594849S39.6134758,114.2452087,39.6709785,114.2545776z" />
            <path d="M39.8713112,113.593544c0.0641899,0.0070419,0.1360474-0.0640488,0.1595917-0.1581573 s-0.0124435-0.1740417-0.0789986-0.1797714c-0.0660591-0.0056915-0.1368141,0.0639648-0.1579857,0.1567764 C39.772747,113.5051956,39.8076096,113.5865631,39.8713112,113.593544z" />
          </g>
          <g fill={colors[0]}>
            <path d="M41.3488426,112.4391785c0.0263214-0.025322,0.1080017,1.0779495,0.1831131,1.6785126 c-0.743618,0.877533-1.1036453,0.9190826-1.3559532,0.9298401 c-0.0036316,0.0001678-0.0072441,0.0003128-0.0108414,0.0004578 c-0.2147522-0.4285126-0.3204842-2.1782379-0.3204842-2.1782379S40.9925308,112.990921,41.3488426,112.4391785z" />
            <path d="M38.6826515,113.0441589c0,0,0.0494804-0.0699768-0.0234528-0.0541687 c-0.2220345,0.0481262-1.0048676-0.3278809-1.1148758-0.2819061 c-0.0544319,0.2635651-0.0584145,1.4121857-0.4718323,2.1760406 c-0.0204239,0.0736237,0.0171776,0.047905-0.0015106,0.1086884 c0.1943817,0.0652084,0.7201309,0.0813599,0.7201309,0.0813599 C37.9900322,114.5632324,38.3585777,113.7010803,38.6826515,113.0441589z" />
          </g>
          <path
            fill={colors[0]}
            d="M40.0736008,115.1651306c0.035778-0.0465469,0.0541-0.0699768,0.0915604-0.1171417 c0,0,0.6275902-0.0214539,1.3667946-0.9302979c-0.1833878,0.3505249-0.9430313,1.0594025-1.4677086,1.0717545"
          />
          <path
            opacity="0.23"
            fill={colors[1]}
            d="M40.0736008,115.1651306 c0.035778-0.0465469,0.0541-0.0699768,0.0915604-0.1171417c0,0,0.6275902-0.0214539,1.3667946-0.9302979 c-0.1833878,0.3505249-0.9430313,1.0594025-1.4677086,1.0717545"
          />
          <path
            opacity="0.2"
            fill="#FFF"
            d="M38.6591988,112.9899902 c0.009388,0.0216064,0.0140762,0.0324402,0.0234528,0.0541687c0,0-0.4807777,0.9457397-0.8656311,2.0399704 c-0.0124092-0.0475693,0.0035057-0.109726,0.0035057-0.109726S38.3336411,113.4608002,38.6591988,112.9899902z"
          />
          <g>
            <path
              fill={colors[0]}
              d="M40.1651611,115.0479889c-0.0374603,0.0471649-0.0557823,0.0705948-0.0915604,0.1171417 c0,0-0.1171532-0.7037811-0.2623291-2.1162949c0.0386505-0.1283493,0.0621109-0.2133865,0.0621109-0.2133865 S40.0057716,114.2121582,40.1651611,115.0479889z"
            />
            <path
              opacity="0.2"
              fill="#FFF"
              d="M40.1651611,115.0479889 c-0.0374603,0.0471649-0.0557823,0.0705948-0.0915604,0.1171417c0,0-0.1171532-0.7037811-0.2623291-2.1162949 c0.0386505-0.1283493,0.0621109-0.2133865,0.0621109-0.2133865S40.0057716,114.2121582,40.1651611,115.0479889z"
            />
          </g>
          <g>
            <path
              opacity="0.23"
              fill={colors[1]}
              d="M38.2203369,116.8435974 c0,0,0.6153374-3.7496643,0.5816383-3.707489c-0.0337029,0.0421753-0.1112366,0.218689-0.1112366,0.218689 c-0.3113136,1.4004822-0.0812645,2.4948349-0.5233574,3.5117035 C38.1883545,116.8572083,38.1989403,116.8526306,38.2203369,116.8435974z"
            />
            <path
              fill={colors[4]}
              d="M37.8710098,116.9286804c0,0-0.1177368,0.1628265-0.0317726-0.5845032 c0.0859604-0.7473221,0.3306351-2.1193848,0.3306351-2.1193848 c-0.2148132,0.9573517-0.3070259,1.4625244-0.405983,2.3553772 C37.7638893,116.5801697,37.6693802,116.9145737,37.8710098,116.9286804z"
            />
            <path
              opacity="0.5"
              fill={colors[1]}
              d="M39.6372833,119.7333679 c-0.0375366,0.2413406-0.0574226,0.366333-0.0991936,0.6318817 c-0.172657,0.0157166-0.2581863,0.0233307-0.4292183,0.0391006 c0.0211601-0.2237778,0.0325737-0.3326721,0.0569687-0.5465164c0,0-0.3244553,0.6479492-0.7657166,0.6464386 c-0.2339058-0.0008087-1.2676811-0.392807-1.9876633-0.3909454 c-0.6382599,0.0016479-1.0788078,0.3645172-1.0788078,0.3645172 c-0.0309296,0.0654602-0.0451202,0.0983505-0.0702591,0.1646194 c1.7276649,0.0002365,2.5888138,0.0001678,4.3178024,0.0001831 C39.6101036,120.2515869,39.619545,120.075676,39.6372833,119.7333679z"
            />
            <path
              fill="#FFF"
              d="M38.9853134,118.8796768c0.26548-0.1107407,0.3860283-0.1512527,0.6288528-0.2310715 c-0.0099678-0.3113251-0.0830231-0.7750168-0.1940613-1.3075943 c0.2127075,0.0161362,0.3191414-1.8316269,0.4246025-2.3691864 c0.0292473-0.1490631,0.0076714,0.5503006,0.0076714,0.5503006 c0.1802254-0.0643768,0.2771454-0.098381,0.4959145-0.1825256 c-0.6935806,1.714447-0.5307465,2.2608109-0.7046013,3.4032135 c-0.0907059,1.3206024-0.1950569,0.9643784-0.3473854,0.6277618 c-0.042614-0.094162-0.2316551,0.7409973-0.6617393,0.8918991 c-0.4300804,0.1509018-0.7978096-0.9216232-1.9396591-0.7591705 c-0.6463394,0.0919495-0.6648064,0.1572952-1.0568848,0.333046 c0.1252937-0.2841797,0.8822479-1.3128204,0.9062119-1.3158188 c0.0093079-0.0011673,0.7419167-0.6240616,1.0720444-0.7810287 c0.0493851-0.0234833-0.1448479,0.325264-0.0945396,0.3059464 c0.2839279-0.1090393,0.5198669-0.1801682,0.7813911-0.0060501 c0.115242-0.0460434,0.1418228-0.2010193,0.2303429-0.2730713 C38.8223343,117.9382477,38.8877754,118.3614655,38.9853134,118.8796768z"
              opacity="0.1"
            />
            <path
              opacity="0.1"
              fill="#FFF"
              d="M36.5820923,118.8791809 c0.2117157-0.1921997,0.7079697-0.1785583,0.2933197-0.3220978 c0.5008965-0.2710266,0.6042824-0.2792816,0.6042824-0.2792816 c-0.0048218-0.0550079,0.0920563-0.044281-0.2567101-0.0289383c0.5273399-0.3801498,0.9181709-0.126091,0.9181709-0.126091 c-0.1718597-0.0055695-0.2499428-0.0069733-0.3985939-0.0083618 c0.1367073,0.0533066,0.1744461,0.0460587,0.3806801,0.1459656c0,0-0.3285942,0.8668365-0.5171356,0.665596 c-0.0475311-0.0507355-0.1384201-0.0788803-0.2443924-0.0923538 c-0.1032677,0.2428207-0.3606071,0.5050278-0.3939323,0.5496216 c-0.0354538,0.0474396-0.3894463,0.0522614-0.3894463,0.0522614 c0.0614166-0.2353592,0.1007042-0.3519745,0.1844597-0.5816269 C36.6583366,118.8670349,36.5820923,118.8791809,36.5820923,118.8791809z"
            />
            <path
              opacity="0.7"
              fill="#FFF"
              d="M36.7672195,119.0870514 c-0.0198174,0.0494919,0.0183754,0.0855255,0.0846939,0.0803452 c0.0663223-0.0051804,0.1342583-0.0503311,0.1521759-0.1007156 c0.0179176-0.0503922-0.019474-0.0862045-0.0838966-0.0801239 C36.8557777,118.99263,36.7870369,119.0375595,36.7672195,119.0870514z"
            />
            <path
              opacity="0.11"
              fill="#FFF"
              d="M38.0051193,114.7754059c0,0-0.3148232,1.888855-0.3233757,2.0935745 c-0.0168953,0.0200729,0.0901489,0.1129074,0.0760345,0.1326294 c-0.0988197,0.1380997-0.2296448,0.1891327-0.2296448,0.1891327 c0.2012711-0.7455521,0.0343018-1.2223587,0.052124-2.1420441c0.0999947,0.019043,0.1483727,0.0282288,0.2454453,0.0468521 C37.8962402,114.9680328,37.9319496,114.9038773,38.0051193,114.7754059z"
            />
            <path
              opacity="0.23"
              fill={colors[1]}
              d="M37.8170204,115.0841293 c0,0,0.0053635-0.1293259,0.0035057-0.109726c-0.2791176-0.0317383-0.4131966-0.0453873-0.7480354-0.0902786 c-0.0006371,0.0434875-0.000946,0.0652313-0.0015106,0.1086884 C37.4052277,115.0378952,37.5385513,115.0518036,37.8170204,115.0841293z"
            />
          </g>
        </g>
        <g>
          <path
            opacity="0.1"
            fill="#FFF"
            d="M39.5504532,118.5140152 c-0.1355629,0.0500412-0.2040062,0.0757751-0.3478813,0.1353455c0,0-0.0652885-2.3098221-2.4961472-0.4120789 c0.3810272-0.4184036,0.4239159-0.5410614,0.6796799-0.850174 C39.3459015,115.9588165,39.5504532,118.5140152,39.5504532,118.5140152z"
          />
          <path
            fill={colors[3]}
            d="M36.5069923,118.4497833c-0.0058784,0.0255737-0.0088234,0.0383911-0.0146828,0.0640411 c0.2860641-0.1924973,0.4166794-0.292511,0.6635399-0.4912491 c0.225666-0.3717422,0.3301926-0.5565262,0.4969406-0.8967361c0,0-0.1099167-0.0411758-0.1161537-0.0214081 c-0.0062332,0.0197754-0.3254814,0.4088974-0.3254814,0.4088974 C36.9693718,117.8828659,36.8215218,118.0750732,36.5069923,118.4497833z M36.6509018,118.3492432 c-0.0454636-0.0283356,0.6196213-0.866127,0.6232834-0.8654785 c0.0975761-0.1064529,0.1440582-0.1591949,0.2342453-0.2617264 c-0.1711349,0.3285217-0.2770576,0.5034561-0.5069733,0.8527069 C37.0014572,118.0747452,36.6621399,118.3562546,36.6509018,118.3492432z"
          />
          <path
            fill={colors[3]}
            d="M37.1973152,117.693718c-0.0248222,0.029274-0.0373688,0.0439529-0.0627441,0.0733414 c-0.090435,0.0906219-0.1371613,0.1362305-0.2341118,0.2271576c0.057869-0.080864,0.0860443-0.1212616,0.1405144-0.2017365 c0.0771065-0.0629578,0.1145325-0.0944366,0.1877403-0.1569977 C37.2162933,117.6587372,37.2100143,117.6703873,37.1973152,117.693718z"
          />
        </g>
      </g>
      <g id="C_x24_boots_1_">
        <path
          fill={colors[0]}
          d="M53.4793663,112.4605179c0.233078,2.0456314,1.3598366,3.9029388,1.5585098,5.568634 c0.0667877,1.0209885,0.0707245,1.4131241,0.1791992,2.6359253c1.7259521,0.0000076,2.5889282,0.0000076,4.3148804,0.0000076 c0,0-1.4794807-2.6037979-2.179966-3.325386c-0.3865204-1.0503311-0.2060471-4.5816193-0.2060471-4.5816193 S55.7093506,113.7438507,53.4793663,112.4605179z"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M56.5389214,116.8008804 c-0.2239761-0.4704819-0.5019264-3.3096924-0.6036797-3.7042007 c-0.2218704,0.027565-0.5976486-0.0958328-0.9360733-0.1275864c0.1112061,0.4361954,0.2883492,2.8945465,0.7874603,3.9612732 C55.964325,116.7388229,56.2181206,116.7066269,56.5389214,116.8008804z"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M58.2419357,118.453743 c-0.0086555-0.0207596-0.0129852-0.0311279-0.0216179-0.051857c0,0-1.6910324-2.6605682-2.9927902,0.1588898 c0.0959511,0.0302505,0.2710915,0.0944366,0.3739662,0.1405029 c0.0036354-0.0008698,0.0821381-0.0632172,0.137867-0.0732574 C56.49757,116.504715,57.9536171,118.2546997,58.2419357,118.453743z"
        />
        <path
          fill={colors[0]}
          d="M57.7542381,117.7924805c-0.3461914-0.4822083-0.5617752-0.9360275-1.5269318-0.9187851 c-0.5780334,0.0693512-0.7876396,0.618103-0.9934654,1.6425095 c0.1193428,0.0308228,0.2425957,0.0804291,0.3723183,0.1394119 c0.0024414-0.0006409,0.0081482-0.0017548,0.0100555-0.0023041 c0.1255112-0.5028076,0.5372124-1.2412567,1.0310249-1.2095108 c0.6576538,0.0422897,1.1193542,0.5729446,1.5662422,0.976799 C58.3117294,118.4260178,58.0244713,118.170372,57.7542381,117.7924805z"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M56.3119278,117.7119217 c0.0870476,0.0972977,0.1317329,0.1479416,0.2221832,0.2531967c0,0,0.2434807-0.3034515,0.7050323,0.0679932 c-0.3874207-0.6018372-0.3061409-0.4060898-0.3061409-0.4060898S56.6193466,117.3602295,56.3119278,117.7119217z"
        />
        <path
          opacity="0.2"
          fill="#FFF"
          d="M55.2207527,118.5121765 c0.1192932,0.0293961,0.2423286,0.0786209,0.3718872,0.1371841 c0.1184158-0.5297012,0.6690063-1.3881454,1.1540184-1.3538895c0.287899,0.0203323,0.4191856-0.0330429,0.4158859-0.122673 C56.4377174,116.4536285,55.2159424,117.3456268,55.2207527,118.5121765z"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M54.9011993,112.9987488 c0.4365959,1.5595551,0.3092194,2.841011,0.8527184,3.9798203c0,0-0.2675362,0.4439697-0.4089775,0.0884781 c-0.141449-0.3554916-0.6461143-3.5236435-0.8079872-3.7688065 C54.3750763,113.0530701,54.5746803,112.8223572,54.9011993,112.9987488z"
        />
        <path
          fill={colors[0]}
          d="M54.9534264,112.9170303c0.4262543,1.5406647,0.2641907,2.8811874,0.8215714,3.9904785 c0,0-0.2677956,0.4292297-0.4139214,0.078186c-0.146122-0.3510437-0.6173058-3.5329285-0.7763901-3.7756424 C54.4255943,112.9673462,54.6282578,112.7408676,54.9534264,112.9170303z"
        />
        <path
          fill={colors[0]}
          d="M55.9584312,112.9456406c0.3806305,1.5017166,0.1200294,2.7882156,0.6164436,3.8979568 c0,0,0.3753967,0.3281784,0.3883591-0.1563797c0.0129585-0.4845657-0.5702286-3.1680222-0.5629845-3.4194031 C56.407486,113.0164337,56.1338959,113.0113602,55.9584312,112.9456406z"
        />
        <g>
          <g opacity="0.3" fill={colors[1]}>
            <path d="M56.207283,113.8465195 c-0.1342049-0.0954056-0.2039223-0.1424026-0.3510551-0.2330933 c0.1035271-0.1062546,0.1521301-0.1577988,0.2482452-0.2586365 c0.0290375-0.0304489,0.027256-0.0992203-0.0043411-0.1526871 c-0.0315895-0.0534286-0.081974-0.0722427-0.1115494-0.0419769 c-0.1266403,0.1295013-0.1804886,0.1932678-0.344944,0.3379517 c-0.2255898-0.1077957-0.3384323-0.1564331-0.5701752-0.2526321 c-0.0541229-0.0224762-0.106575,0.0070724-0.11549,0.0663757s0.0285988,0.1265564,0.0822029,0.1487579 c0.1775169,0.0735397,0.2643585,0.1106033,0.4370193,0.1896744 c-0.1246681,0.1188126-0.1856003,0.1816025-0.2988358,0.3132172 c-0.0341835,0.0397339-0.0298119,0.1160736,0.0077057,0.1687546 c0.0375252,0.0527344,0.0941658,0.062561,0.1280136,0.0228119c0.1449394-0.1701355,0.225605-0.2498169,0.3902283-0.3972168 c0.2223015,0.1089783,0.3004799,0.1732788,0.4744873,0.2964325c0.0406532,0.0287399,0.0797806,0.0055008,0.0875664-0.05159 C56.2741394,113.9455414,56.2478065,113.8752899,56.207283,113.8465195z" />
            <path d="M56.4034081,115.1252441 c-0.125248-0.0951385-0.1905212-0.1424942-0.3296432-0.2353134 c0.1090508-0.1105957,0.1590385-0.1647949,0.2554855-0.2714539 c0.0291367-0.0322113,0.030365-0.1044617,0.0028381-0.1607819 c-0.0275269-0.056282-0.0741768-0.0760727-0.1037865-0.0437775 c-0.1267624,0.1382065-0.1871033,0.2032623-0.3472672,0.356575 c-0.2026176-0.1095123-0.2979546-0.1560287-0.4959717-0.2468033 c-0.0462494-0.0212097-0.0897293,0.013855-0.0956039,0.0780029c-0.0058708,0.0641403,0.02631,0.1332169,0.070488,0.15345 c0.1462975,0.067009,0.2174454,0.1012039,0.3647461,0.177536c-0.112999,0.1272049-0.1640244,0.1950531-0.2551956,0.3366165 c-0.0275192,0.0427399-0.0251236,0.1201172,0.0026054,0.1707535 c0.0277328,0.0506821,0.0712891,0.0568619,0.0995522,0.0149994 c0.121006-0.1791763,0.1911736-0.2635193,0.3504829-0.4164886 c0.1973839,0.1093826,0.2782326,0.1760864,0.4418411,0.2974243 c0.0382271,0.0283127,0.0778465,0.0043716,0.0885086-0.0533676 C56.46315,115.2248383,56.4412308,115.1539383,56.4034081,115.1252441z" />
            <path d="M56.5190468,116.3775101 c-0.1180115-0.0884399-0.1800117-0.1326828-0.3140717-0.2200928 c0.1178551-0.1067352,0.1713676-0.1597748,0.2734261-0.2651978 c0.0308304-0.0318298,0.0363655-0.1024857,0.0126076-0.157402c-0.0237617-0.0548782-0.0685234-0.0737-0.0998383-0.0414276 c-0.1340752,0.1381073-0.2079811,0.2041855-0.3696365,0.3495178 c-0.1838417-0.1073456-0.2593727-0.1488266-0.4102783-0.2263718 c-0.0352402-0.0181198-0.0702591,0.0192566-0.0782051,0.0822144 c-0.0079498,0.0629578,0.0118866,0.1273651,0.0445633,0.1439972 c0.1081886,0.0550842,0.1628342,0.0842972,0.288414,0.1548233 c-0.1109047,0.1218719-0.1592216,0.1874695-0.2475586,0.3235703 c-0.0266685,0.0410995-0.0338135,0.1120453-0.0177116,0.1564789 c0.016098,0.0444717,0.0517311,0.0477905,0.0809441,0.0086365c0.1250725-0.1675873,0.193779-0.2487946,0.3606453-0.3876038 c0.1854172,0.109436,0.2682648,0.1653137,0.4228477,0.2770462c0.0361176,0.0260773,0.077198,0.0035172,0.0917969-0.0506744 C56.5715904,116.4708023,56.5546875,116.4041824,56.5190468,116.3775101z" />
          </g>
        </g>
        <g>
          <g fill={colors[3]}>
            <path d="M56.8048973,116.7197723c-0.043541,0.0037766-0.0835075-0.0626984-0.0893784-0.1501999 c-0.005867-0.0875092,0.0247879-0.1626434,0.0685081-0.1665268 c0.043396-0.0038528,0.0839005,0.0649872,0.0895882,0.1525955 C56.879303,116.6432571,56.8481102,116.7160339,56.8048973,116.7197723z" />
            <path d="M56.7361069,116.1083755c-0.0440903,0.0039215-0.0876694-0.0658951-0.0972099-0.1573105 c-0.0095367-0.0914078,0.0187531-0.1693573,0.0629997-0.1731949 c0.0439186-0.0038071,0.0876236,0.0677643,0.0970039,0.1590881 C56.8082848,116.0282822,56.7798653,116.1044769,56.7361069,116.1083755z" />
            <path d="M56.6606178,115.471489c-0.0444717,0.0037003-0.0910454-0.0685196-0.1038017-0.162262 c-0.0127487-0.0937347,0.0133896-0.1729431,0.0581055-0.1764526 c0.0443802-0.0034714,0.0908813,0.0698318,0.1033897,0.1633606 C56.7308235,115.3896713,56.7047539,115.4678116,56.6606178,115.471489z" />
            <path d="M56.5683861,114.8211746c-0.0450211,0.0032806-0.0943985-0.0701752-0.1100197-0.164566 c-0.0156212-0.0943985,0.0088234-0.1734009,0.0542946-0.1764526 c0.0451317-0.0030289,0.0943451,0.0707855,0.10952,0.1649399 C56.6373596,114.7392502,56.6130714,114.8179016,56.5683861,114.8211746z" />
            <path d="M56.4574966,114.1692047c-0.0460091,0.0028458-0.0983009-0.0705032-0.116539-0.1638184 c-0.0182419-0.0933228,0.0051193-0.1707458,0.051899-0.1733932 c0.0464287-0.0026398,0.0985565,0.0702896,0.1160355,0.1634216S56.5031662,114.1663742,56.4574966,114.1692047z" />
            <path d="M56.2973289,113.5291595c-0.0479126,0.002655-0.1038742-0.068924-0.1247902-0.1593857 c-0.0209122-0.0904541,0.0021133-0.1651077,0.0511971-0.1677017 c0.0487175-0.0025787,0.1045723,0.0678177,0.1243095,0.1582184 C56.3677902,113.4506912,56.3448792,113.526535,56.2973289,113.5291595z" />
          </g>
          <g fill={colors[3]}>
            <path d="M55.5275421,116.8488083c-0.0359116,0.0167694-0.0742798-0.0260239-0.0747147-0.1129761 c-0.0004311-0.0869522,0.0242348-0.1734161,0.0604515-0.189537 c0.0359421-0.0160065,0.0692596,0.0440369,0.0688705,0.1307068 C55.5817604,116.7636642,55.5631828,116.8321609,55.5275421,116.8488083z" />
            <path d="M55.4779854,116.2561035c-0.0373573,0.0160065-0.0729256-0.0441818-0.0838585-0.1376266 c-0.0109291-0.0934525,0.0094452-0.183609,0.0494881-0.1988144 c0.0397453-0.0150833,0.0822105,0.0497513,0.0904541,0.142395 C55.5423088,116.1547012,55.5150642,116.2402115,55.4779854,116.2561035z" />
            <path d="M55.3952827,115.6061478c-0.0429878,0.0143585-0.0902824-0.0511246-0.1087418-0.1485062 c-0.0184555-0.0973816,0.0023193-0.1883392,0.0490456-0.2015457 c0.0463867-0.0131073,0.0986023,0.0555115,0.1133194,0.151741 C55.4636192,115.5040741,55.4379539,115.5918961,55.3952827,115.6061478z" />
            <path d="M55.2726974,114.932724c-0.0503654,0.0120468-0.1083374-0.0571671-0.1312866-0.1559219 c-0.0229492-0.0987473,0.0014687-0.1880264,0.0558548-0.1987305 c0.0539818-0.0106277,0.1149216,0.0602417,0.1338501,0.1576462 C55.3500404,114.8331299,55.3226929,114.9207687,55.2726974,114.932724z" />
            <path d="M55.1242332,114.2545776c-0.0579338,0.0094376-0.1243439-0.0616913-0.1488304-0.1592712 c-0.0244865-0.0975723,0.0054893-0.182991,0.0669594-0.1910477 c0.0610161-0.0079956,0.1284332,0.0632935,0.1493797,0.1594849 C55.2126884,114.1599426,55.181736,114.2452087,55.1242332,114.2545776z" />
            <path d="M54.9239006,113.593544c-0.0641899,0.0070343-0.1360474-0.0640488-0.1595917-0.1581573 c-0.0235443-0.0941162,0.0124435-0.1740417,0.0789986-0.1797791 c0.0660591-0.0056839,0.1368141,0.0639725,0.1579857,0.1567841 C55.0224648,113.5051956,54.9876022,113.5865555,54.9239006,113.593544z" />
          </g>
          <g fill={colors[0]}>
            <path d="M53.4463692,112.4391785c-0.0263214-0.025322-0.1080017,1.0779495-0.1831131,1.6785049 c0.743618,0.8775406,1.1036453,0.9190903,1.3559532,0.9298477 c0.0036316,0.0001602,0.0072441,0.0003128,0.0108414,0.0004578 c0.2147522-0.4285202,0.3204842-2.1782379,0.3204842-2.1782379S53.802681,112.9909134,53.4463692,112.4391785z" />
            <path d="M56.1125603,113.0441589c0,0-0.0494804-0.0699768,0.0234528-0.0541687 c0.2220345,0.0481262,1.0048676-0.3278809,1.1148758-0.2819061 c0.0544319,0.2635727,0.0584145,1.4121857,0.4718323,2.1760406 c0.0204239,0.0736237-0.0171776,0.047905,0.0015106,0.108696 c-0.1943817,0.0652008-0.7201309,0.0813522-0.7201309,0.0813522 C56.8051796,114.5632324,56.4366341,113.701088,56.1125603,113.0441589z" />
          </g>
          <path
            fill={colors[0]}
            d="M54.721611,115.1651306c-0.035778-0.0465469-0.0541-0.0699768-0.0915604-0.1171417 c0,0-0.6275902-0.0214539-1.3667946-0.9303055c0.1833878,0.3505249,0.9430313,1.0594101,1.4677086,1.0717621"
          />
          <path
            opacity="0.23"
            fill={colors[1]}
            d="M54.721611,115.1651306 c-0.035778-0.0465469-0.0541-0.0699768-0.0915604-0.1171417c0,0-0.6275902-0.0214539-1.3667946-0.9303055 c0.1833878,0.3505249,0.9430313,1.0594101,1.4677086,1.0717621"
          />
          <path
            opacity="0.2"
            fill="#FFF"
            d="M56.136013,112.9899902 c-0.009388,0.0215988-0.0140762,0.0324326-0.0234528,0.0541687c0,0,0.4807777,0.9457397,0.8656311,2.0399704 c0.0124092-0.0475616-0.0035057-0.109726-0.0035057-0.109726S56.4615707,113.4608002,56.136013,112.9899902z"
          />
          <g>
            <path
              fill={colors[0]}
              d="M54.6300507,115.0479889c0.0374603,0.0471649,0.0557823,0.0705948,0.0915604,0.1171417 c0,0,0.1171532-0.7037811,0.2623291-2.1162949c-0.0386505-0.1283493-0.0621109-0.2133865-0.0621109-0.2133865 S54.7894402,114.2121582,54.6300507,115.0479889z"
            />
            <path
              opacity="0.2"
              fill="#FFF"
              d="M54.6300507,115.0479889 c0.0374603,0.0471649,0.0557823,0.0705948,0.0915604,0.1171417c0,0,0.1171532-0.7037811,0.2623291-2.1162949 c-0.0386505-0.1283493-0.0621109-0.2133865-0.0621109-0.2133865S54.7894402,114.2121582,54.6300507,115.0479889z"
            />
          </g>
          <g>
            <path
              opacity="0.23"
              fill={colors[1]}
              d="M56.5748749,116.8435974 c0,0-0.6153374-3.7496643-0.5816383-3.7074966c0.0337029,0.0421829,0.1112366,0.218689,0.1112366,0.218689 c0.3113136,1.4004898,0.0812645,2.4948425,0.5233574,3.5117111 C56.6068573,116.8572159,56.5962715,116.8526306,56.5748749,116.8435974z"
            />
            <path
              fill={colors[4]}
              d="M56.924202,116.9286804c0,0,0.1177368,0.1628265,0.0317726-0.5844955 c-0.0859604-0.7473297-0.3306351-2.1193924-0.3306351-2.1193924 c0.2148132,0.9573517,0.3070259,1.4625244,0.405983,2.3553772 C57.0313225,116.5801697,57.1258316,116.9145737,56.924202,116.9286804z"
            />
            <path
              opacity="0.5"
              fill={colors[1]}
              d="M55.1579285,119.7333679 c0.0375366,0.2413406,0.0574226,0.366333,0.0991936,0.6318817 c0.172657,0.0157166,0.2581863,0.0233307,0.4292183,0.0391006 c-0.0211601-0.2237854-0.0325737-0.3326721-0.0569687-0.5465164c0,0,0.3244553,0.6479492,0.7657166,0.6464386 c0.2339058-0.0008087,1.2676811-0.392807,1.9876633-0.3909454 c0.6382599,0.0016479,1.0788078,0.3645172,1.0788078,0.3645172 c0.0309296,0.0654678,0.0451202,0.0983505,0.0702591,0.1646194 c-1.7276649,0.0002441-2.5888138,0.0001678-4.3178024,0.0001831 C55.1851082,120.2515793,55.1756668,120.075676,55.1579285,119.7333679z"
            />
            <path
              fill="#FFF"
              d="M55.8098984,118.8796768c-0.26548-0.1107407-0.3860283-0.1512604-0.6288528-0.2310715 c0.0099678-0.3113251,0.0830231-0.7750168,0.1940613-1.3075943 c-0.2127075,0.0161285-0.3191414-1.8316269-0.4246025-2.3691864 c-0.0292473-0.1490631-0.0076714,0.5503006-0.0076714,0.5503006 c-0.1802254-0.0643768-0.2771454-0.098381-0.4959145-0.1825256 c0.6935806,1.7144394,0.5307465,2.2608109,0.7046013,3.4032059c0.0907059,1.32061,0.1950569,0.964386,0.3473854,0.6277695 c0.042614-0.094162,0.2316551,0.7409973,0.6617393,0.8918991 c0.4300804,0.1509018,0.7978096-0.9216232,1.9396591-0.7591705 c0.6463394,0.0919495,0.6648064,0.1572952,1.0568848,0.3330536 c-0.1252937-0.2841873-0.8822479-1.3128281-0.9062119-1.3158264 c-0.0093079-0.0011673-0.7419167-0.6240616-1.0720444-0.7810287 c-0.0493851-0.0234833,0.1448479,0.325264,0.0945396,0.3059464 c-0.2839279-0.1090393-0.5198669-0.1801682-0.7813911-0.0060425 c-0.115242-0.046051-0.1418228-0.2010345-0.2303429-0.2730789 C55.9728775,117.9382477,55.9074364,118.3614655,55.8098984,118.8796768z"
              opacity="0.1"
            />
            <path
              opacity="0.1"
              fill="#FFF"
              d="M58.2131195,118.8791809 c-0.2117157-0.1921997-0.7079697-0.1785583-0.2933197-0.3220978 c-0.5008965-0.271019-0.6042824-0.2792816-0.6042824-0.2792816 c0.0048218-0.0550079-0.0920563-0.044281,0.2567101-0.0289383c-0.5273399-0.3801422-0.9181709-0.126091-0.9181709-0.126091 c0.1718597-0.0055618,0.2499428-0.0069656,0.3985939-0.0083618 c-0.1367073,0.0533066-0.1744461,0.0460587-0.3806801,0.1459732c0,0,0.3285942,0.8668289,0.5171356,0.6655884 c0.0475311-0.0507355,0.1384201-0.0788803,0.2443924-0.0923538 c0.1032677,0.2428207,0.3606071,0.5050278,0.3939323,0.5496292 c0.0354538,0.0474396,0.3894463,0.0522614,0.3894463,0.0522614 c-0.0614166-0.2353668-0.1007042-0.3519821-0.1844597-0.5816345 C58.1368752,118.8670349,58.2131195,118.8791809,58.2131195,118.8791809z"
            />
            <path
              opacity="0.7"
              fill="#FFF"
              d="M58.0279922,119.0870514 c0.0198174,0.0494919-0.0183754,0.0855255-0.0846939,0.0803452 c-0.0663223-0.0051804-0.1342583-0.0503311-0.1521759-0.1007156 c-0.0179176-0.0503922,0.019474-0.0862045,0.0838966-0.0801239 C57.9394341,118.99263,58.0081749,119.0375595,58.0279922,119.0870514z"
            />
            <path
              opacity="0.11"
              fill="#FFF"
              d="M56.7900925,114.7754059c0,0,0.3148232,1.888855,0.3233757,2.0935745 c0.0168953,0.0200806-0.0901489,0.1129074-0.0760345,0.1326294 c0.0988197,0.1381073,0.2296448,0.1891327,0.2296448,0.1891327 c-0.2012711-0.7455521-0.0343018-1.2223511-0.052124-2.1420441 c-0.0999947,0.019043-0.1483727,0.0282288-0.2454453,0.0468597 C56.8989716,114.9680328,56.8632622,114.9038773,56.7900925,114.7754059z"
            />
            <path
              opacity="0.23"
              fill={colors[1]}
              d="M56.9781914,115.0841293 c0,0-0.0053635-0.1293259-0.0035057-0.109726c0.2791176-0.0317383,0.4131966-0.0453796,0.7480354-0.0902786 c0.0006371,0.0434875,0.000946,0.0652313,0.0015106,0.108696 C57.3899841,115.0378952,57.2566605,115.0518036,56.9781914,115.0841293z"
            />
          </g>
        </g>
        <g>
          <path
            opacity="0.1"
            fill="#FFF"
            d="M55.2447586,118.5140152 c0.1355629,0.0500412,0.2040062,0.0757751,0.3478813,0.1353455c0,0,0.0652885-2.3098221,2.4961472-0.4120789 c-0.3810272-0.4184036-0.4239159-0.5410614-0.6796799-0.850174 C55.4493103,115.9588165,55.2447586,118.5140152,55.2447586,118.5140152z"
          />
          <path
            fill={colors[3]}
            d="M57.5840569,117.5133286c0,0-0.3192482-0.389122-0.3254814-0.4088898 c-0.006237-0.0197754-0.1161537,0.0214005-0.1161537,0.0214005c0.166748,0.34021,0.2712746,0.5250015,0.4969406,0.8967361 c0.2468605,0.1987457,0.3774757,0.2987518,0.6635399,0.4912567 c-0.0058594-0.0256577-0.0088043-0.0384674-0.0146828-0.0640488 C57.97369,118.0750732,57.82584,117.8828659,57.5840569,117.5133286z M57.7937546,118.0747452 c-0.2299156-0.3492432-0.3358383-0.5241852-0.5069733-0.8527069 c0.0901871,0.1025314,0.1366692,0.1552734,0.2342453,0.2617264 c0.0036621-0.0006485,0.6687469,0.8371506,0.6232834,0.8654861 C58.1330719,118.3562546,57.7937546,118.0747452,57.7937546,118.0747452z"
          />
          <path
            fill={colors[3]}
            d="M57.5978966,117.693718c0.0248222,0.0292816,0.0373688,0.0439529,0.0627441,0.0733414 c0.090435,0.0906296,0.1371613,0.1362305,0.2341118,0.2271576c-0.057869-0.080864-0.0860443-0.1212616-0.1405144-0.2017365 c-0.0771065-0.0629501-0.1145325-0.0944366-0.1877403-0.1569901 C57.5789185,117.6587448,57.5851974,117.6703873,57.5978966,117.693718z"
          />
        </g>
      </g>
    </g>
  )
}

export default Feet
