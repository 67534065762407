import React from "react"

const LongBowRight = ({
  colors = [
    "#695E60",
    "#42130B",
    "#FFCD03",
    "#EDD692",
    "#B5AC95",
    "#915B25",
    "#A98A30"
  ]
}) => {
  return (
    <g id="LongBowRight">
      <line
        fill="none"
        stroke={colors[0]}
        strokeWidth="0.106"
        strokeMiterlimit="10"
        x1="48.527"
        y1="53.724"
        x2="69.095"
        y2="106.984"
      />
      <polygon
        fill={colors[1]}
        points="54.2847404,85.6316986 53.5871086,83.8152924 52.5726776,81.2473755 51.8750458,79.4309616 50.4360771,80.3779755 51.0067711,81.8394165 51.979641,84.4702301 52.5918846,85.8687668"
      />
      <polygon
        fill={colors[2]}
        points="51.8594704,85.7463379 54.5981522,85.5676651 54.7891312,86.2360382 52.4885483,86.1619568"
      />
      <g fill={colors[3]}>
        <polygon points="51.2606583,82.5493469 52.7838745,81.7483749 52.6142387,81.1844711 52.4670792,80.9968796 51.0067711,81.8394165" />
        <polygon points="50.9640732,81.6304779 52.4243851,80.787941 52.1918411,80.1824722 50.6686249,80.9834442" />
        <polygon points="53.4871635,84.9240189 53.9881592,84.712822 53.7140541,84.1702576 52.8580856,84.5084076" />
        <polygon points="53.3826904,84.9453659 52.8580856,84.5084076 52.14814,84.762291 52.3806877,85.3677673" />
        <polygon points="51.8313446,84.0107956 53.417469,83.2513809 53.1220169,82.6043472 51.5145493,83.2592926" />
        <polygon points="52.0638885,84.6162643 53.6927109,84.0657883 53.5871086,83.8152924 53.4388161,83.3558502 51.9155998,84.1568222 52.0212021,84.4073257" />
        <polygon points="54.2847404,85.6316986 53.9465981,84.7757263 52.4649391,85.5137939 52.5289803,85.8272095" />
      </g>
      <g fill={colors[4]}>
        <polygon points="52.850174,82.605484 52.3671265,82.1056137 51.2820015,82.6538162 51.493206,83.1548233" />
        <polygon points="52.8681335,81.8944092 52.4715996,82.084259 52.9546432,82.584137 53.1006737,82.4998779" />
      </g>
      <g fill={colors[4]}>
        <polygon points="51.0224648,80.5846481 50.7281418,80.209465 50.4360771,80.3779755 50.5843697,80.83741" />
        <polygon points="51.9166069,79.3680573 50.7697029,80.1465607 51.1269341,80.5633011 52.1075897,80.0364304" />
      </g>
      <path
        fill={colors[5]}
        d="M48.6313629,53.7022514l-0.1685066-0.2920647 c-0.2302856-0.061779-0.3324928,0.5032616-0.3324928,0.5032616s0.0629082,0.0415611,0.1696358,0.5639076 s-1.5107918,3.7912827-2.5475731,5.9076653c-2.7778549,5.84589,1.1427498,13.3159523,3.4175949,17.2587929 c0.6111183,1.1266861,1.2008972,2.1489029,1.3491859,2.608345l1.0828667-1.0919037 c-0.2741089-0.5425644-0.6953773-1.2727203-1.2008934-2.1489105 c-2.1905937-3.7968063-6.3167534-10.4086456-4.1230202-15.9175148c1.097435-2.6185112,2.0903931-5.2156792,2.2948151-6.3457565 C48.6953964,54.0156593,48.6942673,53.7438087,48.6313629,53.7022514z"
      />
      <ellipse
        transform="rotate(-42.815 51.104 79.646)"
        fill={colors[6]}
        cx="51.104"
        cy="79.646"
        rx="1.131"
        ry="0.452"
      />
      <polygon
        fill={colors[2]}
        points="50.0204582,81.0070496 52.1255417,79.3253708 51.8716545,78.6154327 50.1428833,80.2746277"
      />
      <path
        fill={colors[5]}
        d="M69.1154861,106.8162537l0.0640411,0.3134155 c-0.1875992,0.1471634-0.6279526-0.1437759-0.6279526-0.1437759s0.0415649-0.0629044-0.2111969-0.5009995 c-0.2527618-0.4380875-3.6250381-1.762413-5.8661041-2.6104813c-5.9492264-2.4846649-8.1578903-10.631012-9.1173248-15.0602875 c-0.2561569-1.2536316-0.5538673-2.4443588-0.7437172-2.840889l1.5895157,0.0561295 c0.1696358,0.5639114,0.3819695,1.3367538,0.5954285,2.3814468c0.9167404,4.2203369,2.2873917,11.9937592,7.6513672,14.5435944 c2.5353889,1.2232437,5.0707741,2.4464951,5.9728279,3.1328278 C69.0727997,106.6073227,69.2199554,106.7949142,69.1154861,106.8162537z"
      />
      <ellipse
        transform="rotate(-89.504 53.542 86.018)"
        fill={colors[6]}
        cx="53.542"
        cy="86.018"
        rx="0.452"
        ry="1.131"
      />
      <polygon
        fill={colors[6]}
        points="52.3806877,85.3677673 54.3667336,85.2340393 54.5981522,85.5676651 52.507637,85.7227325"
      />
      <polygon
        fill={colors[6]}
        points="50.5416794,80.6284714 52.1255417,79.3253708 52.2098007,79.4713974 50.5630264,80.7329407"
      />
    </g>
  )
}

export default LongBowRight
