import React from "react"

const LeatherShoulderLayered = ({
  colors = ["#A97C50", "#8B5E3C", "#603913", "#SVGID_", "#FFFFFF", "#C49A6C"]
}) => {
  return (
    <g id="LeatherShoulderLayered">
      <g opacity="0.2">
        <path d="M28.2,62.7c-1.4-2.9-4.4-4.6-4.4-4.6l-0.1-0.9c0.5-0.5,1-1,1.6-1.4c-0.2-0.2-0.4-0.4-0.4-0.4l0.1-0.9 c1.3-0.7,2.7-1.3,4.3-1.7c-0.1-0.3-0.2-0.4-0.2-0.4l0.3-0.8c1.4-0.6,2.9-0.9,4.6-0.9c3.2,0,5.7,1,5.8,1.1l0.3,0.7l-1.6,6.4 c0.3,0.4,0.4,0.8,0.4,1.3l0,0c0,0.1,0,0.2,0,0.2c0,0.8-0.4,1.5-1.1,1.9l0,0c-0.2,0.3-0.6,0.3-0.9,0.3l0,0c-0.5,0-1.2-0.1-2.3-0.3 c-0.6,0.1-1.3,0.2-2.1,0.2c-0.3,0-0.7,0-1,0L28.8,63L28.2,62.7z" />
        <path d="M38.2,60c0-0.4-0.2-0.8-0.4-1.1l1.7-6.7c0,0-5.2-2.2-9.9-0.2c0,0,0.2,0.4,0.5,1.1c-1.5,0.3-3.2,0.9-4.8,1.8 c0,0,0.4,0.3,1,0.9c-0.7,0.5-1.5,1-2.1,1.7c0,0,3.1,1.8,4.7,4.9c1.1-0.2,1.9-0.4,2.6-0.6c1.5,0.1,2.5,0,3.2-0.2 c2.5,0.5,2.9,0.4,2.8,0.2c0.6-0.3,1-0.9,1-1.5C38.2,60.2,38.2,60.2,38.2,60C38.2,60.1,38.2,60,38.2,60z" />
        <path d="M65.1,62.4c-0.4,0-0.7,0-1,0c-0.8,0-1.5-0.1-2.1-0.2c-1.1,0.2-1.9,0.3-2.3,0.3c-0.3,0-0.7,0-0.9-0.3l0,0 c-0.7-0.4-1.1-1.1-1.1-1.9c0-0.1,0-0.2,0-0.3l0,0c0-0.5,0.1-0.9,0.4-1.3l-1.6-6.4l0.3-0.7c0.1,0,2.6-1.1,5.8-1.1 c1.7,0,3.2,0.3,4.6,0.9l0.3,0.8c0,0-0.1,0.2-0.2,0.4c1.6,0.4,3.1,1,4.3,1.7l0.1,0.9c0,0-0.2,0.1-0.4,0.4c0.6,0.4,1.2,0.9,1.6,1.4 l-0.2,1c0,0-3,1.8-4.4,4.6L67.6,63L65.1,62.4z" />
        <path d="M70.3,55.8c0.5-0.6,1-0.9,1-0.9c-1.6-0.9-3.4-1.5-4.8-1.8C66.8,52.4,67,52,67,52c-4.8-2-9.9,0.2-9.9,0.2l1.7,6.7 c-0.3,0.3-0.4,0.7-0.4,1.1l0,0c0,0.1,0,0.2,0,0.2c0,0.7,0.4,1.3,1,1.5c-0.1,0.2,0.3,0.4,2.8-0.2c0.7,0.2,1.7,0.3,3.2,0.2 c0.7,0.1,1.6,0.3,2.6,0.6c1.5-3.1,4.7-4.9,4.7-4.9C71.8,56.8,71,56.3,70.3,55.8z" />
      </g>
      <path
        fill={colors[0]}
        d="M24.1,57.7c0,0,3.1,1.8,4.7,4.9c5.6-1.2,5.6-1.2,5.6-1.2l-3.1-7.2C31.3,54.2,26.8,54.7,24.1,57.7z"
      />
      <path
        fill={colors[1]}
        d="M25.3,55.1c0,0,3.6,2.8,3.9,6.7c5.8,1,6.8-0.6,6.8-0.6l-2.7-8.3C33.3,52.8,29,53,25.3,55.1z"
      />
      <path
        fill={colors[0]}
        d="M29.6,52.2c0,0,2.3,4.3,2.1,9c6.3,1.6,5.8,0.9,5.4,0.6l2.4-9.4C39.6,52.4,34.4,50.2,29.6,52.2z"
      />
      <circle fill={colors[2]} cx="36.6" cy="60.5" r="1.7" />
      <circle fill="url(#SVGID_10_)" cx="36.6" cy="60.2" r="1.7" />
      <radialGradient
        id="SVGID_10_"
        cx="36.576"
        cy="93.773"
        r="1.704"
        gradientTransform="matrix(1 0 0 -1 0 153.987)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.662" stopColor="#8b5e3c" />
        <stop offset="1" stopColor="#c49a6c" />
      </radialGradient>
      <g>
        <path
          fill={colors[0]}
          d="M72.5,57.7c0,0-3.1,1.8-4.7,4.9c-5.6-1.2-5.6-1.2-5.6-1.2l3.1-7.2C65.3,54.2,69.8,54.7,72.5,57.7z"
        />
        <path
          fill={colors[1]}
          d="M71.3,55.1c0,0-3.6,2.8-3.9,6.7c-5.8,1-6.8-0.6-6.8-0.6l2.7-8.3C63.3,52.8,67.6,53,71.3,55.1z"
        />
        <path
          fill={colors[0]}
          d="M67,52.2c0,0-2.3,4.3-2.1,9c-6.3,1.6-5.8,0.9-5.4,0.6l-2.4-9.4C57,52.4,62.2,50.2,67,52.2z"
        />
        <circle fill={colors[2]} cx="60" cy="60.5" r="1.7" />
        <circle fill="url(#SVGID_11_)" cx="60" cy="60.2" r="1.7" />
        <radialGradient
          id="SVGID_11_"
          cx="-492.166"
          cy="93.773"
          r="1.702"
          gradientTransform="rotate(180 -216.09 76.994)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.662" stopColor="#8b5e3c" />
          <stop offset="1" stopColor="#c49a6c" />
        </radialGradient>
      </g>
    </g>
  )
}

export default LeatherShoulderLayered
