import React from "react"
import { withSkinColor } from "../../builderConnector"

const Head = ({ skinColor, color = [] }) => {
  return (
    <g id="Head">
      <path
        opacity="0.2"
        fill="#262261"
        d="M72.9231567,29.3161945l-1.2433395-0.5098991v-5.334053 h-6.2105331v5.334053l-1.0607071,0.4349918c0.8449936,1.0952663,1.7411575,2.0329685,2.9580383,2.798027h2.6076508 C71.1624451,31.2922668,72.0774612,30.3784409,72.9231567,29.3161945z"
      />
      <path
        id="D_x24_K_x24_head"
        fill={skinColor}
        d="M68.5453339,26.9536953h1.1157303 c2.2678757-1.2223644,3.717186-3.2856598,4.3565826-4.8507347c0.0106888-0.0195751,0.3358612-0.9754524,0.3410187-0.9997807 c0.0965347,0.048193,0.7326508,0.1229572,0.8500061-0.0714722c0.3296509-0.5460014,0.8167038-2.3139286,0.8167038-2.3139286 s0.3320999-2.0620117-0.0707321-2.4260139c-0.2707901-0.2446728-0.9508972-0.3568192-1.2078705-0.0416737 c-0.0003052-0.0120983-0.0011292-0.0250549-0.001442-0.0370865c-0.0227814-0.9557524-0.4791489-7.5006752-6.1787567-7.5006752l0,0 c-5.6996078,0-6.1559753,6.5449228-6.1787567,7.5006752c-0.000309,0.0120316-0.001133,0.0249882-0.001442,0.0370865 c-0.2569771-0.3151455-0.9370842-0.2029991-1.2078705,0.0416737c-0.4028282,0.3640022-0.0707283,2.4260139-0.0707283,2.4260139 s0.4870529,1.7679272,0.8166962,2.3139286c0.1173553,0.1944294,0.753479,0.1196651,0.8500099,0.0714722 c0.0051537,0.0243282,0.3303337,0.9802055,0.3410149,0.9997807c0.6394005,1.5650749,2.0887108,3.6283703,4.3565865,4.8507347 h1.1157303H68.5453339z"
      />
    </g>
  )
}

export default withSkinColor(Head, { character: "WomanMixed" })
