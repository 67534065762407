import { all, call } from 'redux-saga/effects'
import { apiWatcherSaga } from './apiSaga'
import { remixMiniSaga } from './remixSaga'
import { apiSuccessWatcherSaga } from './apiSuccessSaga'

function * rootSaga() {
  yield all([
    call(apiWatcherSaga),
    call(apiSuccessWatcherSaga),
    call(remixMiniSaga),
  ])
}

export default rootSaga