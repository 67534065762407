import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FrontShieldR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FrontShieldR") ? (
    <g id="FrontShieldR">
      <path
        id="_x3C_path_x3E__1_"
        d="M55.6225128,97.728096c-0.6436119,0-1.2228622-0.0643616-1.8664742-0.2574463 c-3.2180595-0.8366928-7.4659004-3.4111404-9.5898132-10.6195908L44.10186,86.7223358 c-0.1287231-0.4505234-0.2574425-0.965416-0.3861656-1.5446701c0-0.0643616,0-0.0643616-0.0643616-0.1287155l0,0l0,0 c-0.2574463-1.1585083-0.450531-2.3170013-0.5792503-3.5398712c-0.9654198-7.2728119-0.5148888-16.6051788,7.0153694-19.3083496 c0.8366928-0.3218079,1.8021126-0.450531,2.703167-0.450531c6.8866463,0,11.0701256,8.6243973,12.0355453,16.6051865 c0.5792465,4.9558105-0.1287231,10.2977905-1.866478,13.9020157l-0.0643616,0.1287155 c-0.321804,0.7079773-0.7079697,1.2872238-1.0941391,1.866478l0,0l0,0l0,0 C60.2565193,96.5052338,58.0038757,97.728096,55.6225128,97.728096L55.6225128,97.728096z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FrontShieldR, {
  character: "WomanGnome"
})
