import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LeatherArmor from "./LeatherArmor"
import Skirt from "./Skirt"
import FurLiner from "./FurLiner"

const CATEGORY = "Armor"
const CHARACTER = "WomanGnome"
export const IDS = {
  LEATHER_ARMOR: "LeatherArmor",
  SKIRT: "Skirt",
  FUR_LINER: "FurLiner"
}

export const components = {
  [IDS.LEATHER_ARMOR]: LeatherArmor,
  [IDS.SKIRT]: Skirt,
  [IDS.FUR_LINER]: FurLiner
}

export const Group = ({ props }) => (
  <g id="Armor">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LEATHER_ARMOR,
    name: "Leather Armor",
    defaultColors: ["#914E00", "#353891", "#223549"],
    colorable: true,
    component: components[IDS.LEATHER_ARMOR],
    inUI: true,
    enabled: false,
    subGroupId: 4
  },
  {
    id: IDS.SKIRT,
    name: "Skirt",
    defaultColors: ["#914E00", "#223549"],
    colorable: true,
    component: components[IDS.SKIRT],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.FUR_LINER,
    name: "Fur Liner",
    defaultColors: ["#BFA181"],
    colorable: true,
    component: components[IDS.FUR_LINER],
    inUI: true,
    enabled: false,
    subGroupId: 2
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
