import React from "react"

const MaceL = ({
  colors = ["#595A5B", "#B8B9BA", "#777676", "#9A9B9C", "#E0E1E1", "#E4E3E3"]
}) => {
  return (
    <g id="MaceL">
      <path
        opacity="0.53"
        d="M82.5166321,91.1731033l0.5586166-1.0474014l0.0698318-0.2094879 l0.0698242-0.9077454v-0.1396561l6.4240875-16.4093628c0.2094879,0.0698242,0.4189606,0.0698242,0.4189606,0.0698242 l0.3491364-0.2094803l0.3491364-0.907753c0.1396561-0.1396561,0.6982727-0.7680969,2.1646423-0.907753l0.1396484-0.4189606 l0.1396561-0.0698242l0,0h0.0698242l-0.0698242-0.0698318l0,0v-0.0698242 c-0.0698318-0.0698242-0.0698318-0.2793045,0.0698242-0.6982727c0.1396484-0.3491287,0.3491364-0.6982651,0.3491364-0.6982651l0,0 l0.0698242-0.0698318v-0.2793045l-1.2568893-2.9327393l-0.2793121-0.2094803c0,0-0.0698242,0-0.0698242-0.0698242 l0.2094803-0.907753l-0.6284409-0.2793121l-0.4887772,0.8379211l0,0l-0.4189606-0.0698242l-3.0025711,1.1870575 l-0.2094803,0.2793121l0,0l0,0l0,0l0,0c0,0-0.0698242,0.4189606-0.2094879,0.7680969 c-0.1396484,0.4189606-0.3491287,0.5586166-0.3491287,0.5586166l0,0v0.0698242l0,0h-0.1396561l0.0698242,0.1396561 l-0.0698242,0.0698242l-0.2094803,0.3491364l0.0698242-0.0698242l-0.0698242,0.1396561 c0.9775772,1.0474014,0.9775772,1.8853302,0.9775772,2.0948105l-0.3491364,0.907753l0.1396561,0.4189606l0,0 c0,0,0.1396484,0.0698242,0.3491364,0.2094803L81.329567,88.0308838l-0.6284409,0.8379288l-0.0698242,0.1396561l0,0v0.0698242 l-0.2793121,1.1870575l0.1396561,0.3491364c0.0698242,0.0698242,0.5586166,0.3491364,0.8379211,0.4887924 c0.2793121,0.1396484,0.907753,0.2094803,0.9775848,0.2094803L82.5166321,91.1731033z"
      />
      <path
        fill={colors[0]}
        d="M87.8933182,66.1051788l2.9327316-1.2568817c0.2094879,0.1396561,0.3491364,0.0698242,0.4887924,0 l0.5586166-0.907753l-0.2094803,1.0474091c0.0698242,0.2094803,0.1396484,0.2793045,0.4189606,0.3491287l1.2568893,2.9327393 c0,0-0.907753,1.4663696-0.4189606,1.955162c-1.815506,0.1396561-2.4439545,1.0474014-2.4439545,1.0474014l-0.3491287,0.9775772 l-0.6284409-0.0698242l-6.5637512,16.7585068l-0.0698242,1.1172333l-0.5586166,1.0474014l-0.9077606-0.2094803 L80.56147,90.4050064l0.2793121-1.1870575l0.6982727-0.8379211l6.5637436-16.7584991l-0.5586166-0.4189606l0.3491364-0.9775848 c0,0,0.1396484-1.0474091-1.0474091-2.4439468C87.5441818,67.7810287,87.8933182,66.1051788,87.8933182,66.1051788z"
      />
      <path
        fill={colors[1]}
        d="M86.8459091,67.8508606c1.1870575,1.3267136,1.0474091,2.4439468,1.0474091,2.4439468 l-0.3491364,0.9775772l0.5586166,0.4189682L81.5390549,88.449852l-0.6982727,0.8379211L80.56147,90.4050064 c0,0,0.4887924,0.3491364,0.7680969,0.4189682c0.2793121,0.1396484,0.907753,0.2094803,0.907753,0.2094803l0.5586243-1.0474091 l0.0698242-1.1172333l6.5637436-16.7584991l0.6284485,0.0698242l0.3491364-0.9775772 c0,0,0.6284409-0.907753,2.4439468-1.0474091c-0.4887848-0.4887924,0.4189606-1.9551544,0.4189606-1.9551544 l-1.2568893-2.9327393c-0.2094803,0-0.3491364-0.1396561-0.4189606-0.3491364l0.2094803-1.0474052l-0.5586166,0.9077492 c-0.1396484,0.0698242-0.3491364,0.1396561-0.4887848,0l-2.9327393,1.2568893 C87.823494,66.1750107,87.5441818,67.8508606,86.8459091,67.8508606z M89.7786484,70.8534241l1.3267212-1.6758499 l0.5586166-1.5362015l0.2094803-1.9551544l0.6982727,2.3741226l-0.5586166,1.5361938l-1.955162,1.3267212L89.7786484,70.8534241 z M87.96315,68.0603409l0.6284409-1.5361938l2.0948181-1.2568893l-1.1870651,1.5361938l-0.6284485,1.5362015 l-0.1396484,2.0948105l-0.2793121-0.1396561L87.96315,68.0603409z"
      />
      <path
        fill={colors[2]}
        d="M87.5441818,71.2723846c0,0,0.4887848,0.4189606,1.1870575,0.6982727 c0.6982727,0.2793045,1.3267136,0.3491364,1.3267136,0.3491364l0.3491364-0.907753c0,0-0.9775772-0.2793121-1.3267136-0.4189606 c-0.3491364-0.1396561-1.2568893-0.5586166-1.2568893-0.5586166L87.5441818,71.2723846z"
      />
      <path
        fill={colors[2]}
        d="M80.9106064,89.2179489c0,0,0,0.2094879,0.8379211,0.5586243 c0.8379288,0.3491364,1.0474091,0.2094803,1.0474091,0.2094803l0.1396484-1.1172333 c0,0-0.3491287,0.0698242-0.6982651-0.0698242c-0.3491364-0.1396561-0.6284485-0.4189606-0.6284485-0.4189606 L80.9106064,89.2179489z"
      />
      <g>
        <path
          fill={colors[3]}
          d="M88.0329742,68.1301651l0.4887848,2.1646423l0.2793121,0.1396484l0.1396484-1.9551544 l2.0249863,0.7680969l-1.1870575,1.5361938l0.2793121,0.1396561l1.815506-1.2568893l0.907753,0.3491364 c0,0.0698318,0.0698242,0.1396561,0.0698242,0.1396561c-1.815506,0.1396561-2.4439468,1.0474091-2.4439468,1.0474091 l-0.3491364,0.9775772l0,0l0.3491364-0.907753c0,0-0.9775848-0.2793045-1.3267136-0.4189606 c-0.3491364-0.1396561-1.2568893-0.5586166-1.2568893-0.5586166l-0.3491364,0.907753l0,0l0.3491364-0.9775772 c0,0,0.1396484-1.0474091-1.0474091-2.4439468c0.0698242,0,0.1396484,0,0.2094803-0.0698318L88.0329742,68.1301651z"
        />
        <path
          fill={colors[4]}
          d="M92.5717316,67.9905167L92.5717316,67.9905167l-0.6982651-2.3741226l-0.2094879,1.9551544v0.0698242 l-2.0948105-0.8379211l0.0698242-0.0698242l1.1870575-1.5362015l-2.0948105,1.2568893l0,0l-0.7680969-0.2793121 c0-0.0698242,0-0.0698242,0-0.0698242l2.9327393-1.2568893c0.2094803,0.1396561,0.3491287,0.0698318,0.4887848,0 l0.5586166-0.9077492l-0.2094803,1.0474052c0.0698242,0.2094803,0.1396484,0.2793121,0.4189606,0.3491364l1.2568893,2.9327393 c0,0,0,0-0.0698242,0.0698242L92.5717316,67.9905167z"
        />
        <polygon
          fill={colors[5]}
          points="88.8010712,72.0404816 82.3769836,88.7291565 82.0278473,88.6593323 88.521759,72.0404816"
        />
      </g>
    </g>
  )
}

export default MaceL
