import React from "react"

const SlingShoRight = ({
  colors = ["#362812", "#454545", "#949494", "#724E1F"]
}) => {
  return (
    <g id="SlingShoRight">
      <path
        fill={colors[0]}
        d="M30.1,88L29,78l-6.2-2.9c0,0-2.2,2-2.1,4.6c0,0.4-2.1,34.2-2.1,34.2s0.2,2.4,1.2,2.8 c0.9,0.3,3.2-0.4,3.2-0.4l0.5-2.8L23,79.3l-1.2-1.9l1.1-1.5l5.3,2.8l-1,2.3l0.1,8.7l2.3,2.7l-0.5-5.5L30.1,88z"
      />
      <path
        fill={colors[1]}
        d="M18.4,112.4c0.8-1.1,1-1.8,2.5-1.6c0.2,0,1.9,0.1,2,0.3c0.5,0.8,1.2,2.7,1.2,2.7s-1.1,2.1-2.5,2.2 c-1,0.1-3.1-0.9-3.1-0.9S18,112.9,18.4,112.4z"
      />
      <path
        fill={colors[2]}
        d="M20.7,112.1c0.2-0.4,0.4-0.9,0.6-1.3c0.6,0,1.4,0.1,1.6,0.3c0.5,0.8,1.2,2.7,1.2,2.7s-0.4,0.8-1.1,1.4 c-0.7-0.2-1.4-0.6-2.1-1.1C20.9,113.5,20.8,112.8,20.7,112.1z"
      />
      <path
        fill={colors[3]}
        d="M21.8,115.7l-1.4-0.1l-0.2-2.4l0.6-2.6l1.3-32.2l3.8-1.8l-2.1-1l-1-0.5l-1.4,0.7l-0.9,2.1l-2,36 c0,0,0.4,2.8,1.7,2.4c0.2-0.1,1.8,0,2.4-0.4C22,115.5,21.8,115.7,21.8,115.7z"
      />
    </g>
  )
}

export default SlingShoRight
