import React from "react"

const DragonSkull = ({
  colors = [
    "#262261",
    "#B5B5AE",
    "#SVGID_",
    "#33322F",
    "#BF0A00",
    "#E59572",
    "#FFF4BF",
    "#FFF7D2",
    "#FFFBE6",
    "#FFFDF4",
    "#FFFFFD",
    "#FFFFFF",
    "#E7A4A0"
  ]
}) => {
  return (
    <g id="DragonSkull">
      <ellipse
        opacity="0.3"
        fill={colors[0]}
        cx="69.3"
        cy="14"
        rx="7.4"
        ry="3.5"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M78.1,14.2l-17-0.1l1.1,2.5c0,0.6-0.1,1.4,0.1,1.9 c0.1-0.8,0.8-1.6,1.3-1.9h0.3c-0.1,0.4-0.1,1.2,0.2,1.7c0-0.3,0.3-0.8,0.5-1.1c0.1,0.1,0.2,0.2,0.3,0.2c0,0.4,0.1,0.8,0.3,1.1 l0.3-0.7c0.1,0.1,0.2,0.2,0.3,0.2c0,0.8,0,1.8,0.3,2.4c0.1-0.8,0.6-1.4,0.9-1.6h0.1c0,0,0.1,0.1,0.1,0.2c0,0.6,0.1,1.8,0.5,2.1 c0-0.4,0.2-0.9,0.4-1.2h2.8c0.2,0.3,0.4,0.8,0.4,1.2c0.4-0.3,0.5-1.5,0.5-2c0.1-0.1,0.2-0.2,0.2-0.2l0,0c0.3,0.3,0.8,0.8,0.9,1.6 c0.3-0.7,0.3-1.7,0.3-2.4c0.1-0.1,0.2-0.2,0.4-0.3l0.3,0.6c0.2-0.3,0.3-0.7,0.3-1c0.1-0.1,0.3-0.2,0.4-0.3 c0.2,0.3,0.5,0.8,0.5,1.1c0.3-0.5,0.3-1.1,0.3-1.6c0.1,0,0.1-0.1,0.2-0.1h0.1c0.4,0.4,1.2,1.2,1.3,1.9c0.2-0.6,0.2-1.4,0.1-1.9 L78.1,14.2z"
      />
      <path
        fill={colors[1]}
        d="M78.8,14.4L76.7,13c0,0,2.2-3.9,1.4-3.9c-1.8,0.2-2.3-1-2.9-1.9C75,7,75,6.8,74.9,6.7c-0.5-1-0.3-3-0.3-3 l-1.5,1.2c-1.2-1-2.5-0.1-3-0.2l-0.9-3l-1,3c-0.5,0.1-1.8-0.9-2.9,0.2L64,3.7c0,0,0.3,1.9-0.2,2.8c-0.2,0.3-0.4,0.6-0.4,0.8 c-0.3,1.1-0.7,2.2-2.8,2.2C60.1,9.6,62,13,62,13l-2.1,1.5H62c0,0.4-0.1,0.9,0.1,1.3c0.1-0.5,0.8-1,1.3-1.3h0.3 c-0.1,0.3-0.1,0.7,0.2,1.1c0-0.2,0.3-0.5,0.5-0.7c0.1,0,0.2,0.1,0.3,0.2c0,0.2,0.1,0.5,0.3,0.7l0.3-0.4c0.1,0.1,0.2,0.1,0.3,0.2 c0,0.5,0,1.1,0.3,1.6c0.1-0.5,0.6-0.9,0.9-1h0.1c0,0,0.1,0,0.1,0.1c0,0.4,0.1,1.1,0.5,1.4c0-0.3,0.2-0.6,0.4-0.8h2.8 c0.2,0.2,0.4,0.5,0.4,0.8c0.4-0.2,0.5-0.9,0.5-1.3c0.1-0.1,0.2-0.1,0.2-0.1l0,0c0.3,0.2,0.8,0.5,0.9,1c0.3-0.4,0.3-1.1,0.3-1.5 c0.1-0.1,0.2-0.1,0.4-0.2l0.3,0.4c0.2-0.2,0.3-0.4,0.3-0.6c0.1-0.1,0.3-0.1,0.4-0.2c0.2,0.2,0.5,0.5,0.5,0.7 c0.3-0.3,0.3-0.7,0.3-1c0.1,0,0.1-0.1,0.2-0.1h0.1c0.4,0.3,1.2,0.8,1.3,1.3c0.2-0.4,0.2-0.9,0.1-1.3h2.2L78.8,14.4L78.8,14.4z"
      />
      <path
        fill="url(#SVGID_10_)"
        d="M76.6,11.4c0-0.1-0.3,0.4-0.5,1C76,12,75.7,11.8,75.7,12c0,0.1,0,0.8,0,0.9c0,0.2-0.2-0.9-0.2-0.6 c-0.1,0-0.3,0.2-0.4,0.3c-0.1,0.1,0,0.8-0.1,0.8s-0.1-0.5-0.2-0.6c-0.2,0-0.5,0.1-0.5,0.1c-0.1,0-0.1,0.5-0.1,1.3 c-0.1,0-0.2-0.9-0.4-1.1c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.4-0.1-0.4,0c0,0,0.2,1.1,0.1,1.1c-0.1,0-0.2-0.9-0.5-1.1 c-0.3,0.1-0.8,0-0.8,0l-0.2,1.3l-0.2-1.4c0,0-0.2-0.1-0.5-0.2c-0.2-0.1-0.3,1.5-0.4,1.4c-0.3-0.1-0.8-1.5-0.9-1.8 c0-0.1,0-0.2,0-0.2s-0.6,1.9-1,1.9c-0.1,0-0.2-1.5-0.4-1.4c-0.3,0.1-0.5,0.2-0.5,0.2l-0.2,1.4l-0.2-1.3c0,0-0.5,0-0.8,0 c-0.2,0.2-0.3,1.1-0.5,1.1c-0.1,0,0.1-1.1,0.1-1.1s-0.2,0-0.4,0c-0.2,0-0.3,0-0.5,0.1C65.2,13.2,65.1,14,65,14 c0-0.8-0.1-1.3-0.1-1.3c-0.1,0-0.3-0.1-0.5-0.1c-0.1,0.1-0.1,0.6-0.2,0.6c-0.1,0,0-0.7-0.1-0.8c-0.1,0-0.3-0.2-0.4-0.3 c0-0.3-0.2,0.8-0.2,0.6c0-0.1,0-0.8,0-0.9c-0.1-0.2-0.3,0-0.4,0.4c-0.2-0.6-0.5-1-0.5-1c0,0.1,0.2,1.6,0.2,1.6L62.1,14v0.1 c0,0,0.6-0.6,0.9-0.7c0.3-0.1,0.4,0.7,0.4,0.7l0.3,0.1c0,0,0.2-0.3,0.4-0.4c0.2,0,0.1,0.6,0.1,0.6l0.3,0.2c0,0,0.1-0.6,0.4-0.3 c0.3,0.2,0.2,0.6,0.2,0.6s0.3,0.2,0.4,0.1c0-0.1,0.6-0.7,0.8-0.6c0.2,0.1,0.3,1.2,0.3,1.2l0.2,0.2c0,0,0.4-0.9,0.6-0.8 c0.2,0.1,0.3,0.9,0.3,0.9l0.4-0.2c0,0,0.2,0.1,0.2,0.2c0,0.1,0.4,0.1,0.6,0c0.1,0,0.1-0.7,0.1-1.4c0,0.8,0,1.4,0.1,1.5 c0.2,0.1,0.6,0,0.6,0l0.2-0.2l0.4,0.2c0,0,0.1-0.9,0.3-0.9c0.2-0.1,0.6,0.8,0.6,0.8l0.2-0.2c0,0,0.1-1.1,0.3-1.2 c0.2-0.1,0.8,0.6,0.8,0.6c0,0.1,0.4-0.1,0.4-0.1s0-0.4,0.2-0.6c0.3-0.2,0.4,0.3,0.4,0.3l0.3-0.2c0,0-0.1-0.7,0.1-0.6 c0.2,0,0.4,0.4,0.4,0.4l0.3-0.1c0,0,0.1-0.8,0.4-0.7s0.9,0.7,0.9,0.7v-0.1L75.3,13C76.4,12.9,76.6,11.5,76.6,11.4z"
      />
      <path
        fill={colors[3]}
        d="M66,10.7c0.4-0.3,2.2-3.6,1.8-4c-0.5-0.4-1-0.9-1.7-0.9s-2.4,2.8-2.6,3.5c-0.1,0.2,0.1,1.6,0.6,1.8 C64.4,11.3,65.6,11,66,10.7z"
      />
      <path
        opacity="0.41"
        d="M63.9,9.7c0.2-0.7,1.9-3.5,2.6-3.5c0.6,0,1,0.3,1.4,0.6c0-0.1,0-0.1-0.1-0.2 c-0.5-0.4-1-0.9-1.7-0.9s-2.4,2.8-2.6,3.5c-0.1,0.2,0.1,1.6,0.6,1.8h0.1C63.9,10.6,63.8,9.8,63.9,9.7z"
      />
      <path
        fill={colors[3]}
        d="M67.9,12l-1.6,0.3l1.8-2.2C68.3,10,68.9,11.3,67.9,12z"
      />
      <polygon opacity="0.41" points="67,12.2 68.4,10.3 68.3,10 66.5,12.3" />
      <path
        fill={colors[3]}
        d="M72.7,10.6c-0.4-0.3-2.2-3.6-1.8-4c0.5-0.4,1-0.9,1.7-0.9s2.4,2.8,2.6,3.5c0.1,0.2-0.1,1.6-0.6,1.8 C74.2,11.2,73.1,10.9,72.7,10.6z"
      />
      <path
        opacity="0.41"
        d="M74.9,9.6C74.8,9,73,6.1,72.3,6.1c-0.6,0-1,0.3-1.4,0.6c0-0.1,0-0.1,0.1-0.2 c0.5-0.4,1-0.9,1.7-0.9c0.7,0,2.4,2.8,2.6,3.5c0.1,0.2-0.1,1.6-0.6,1.8h-0.1C74.9,10.5,74.9,9.8,74.9,9.6z"
      />
      <g>
        <path
          fill={colors[3]}
          d="M70.8,11.9l1.6,0.3l-1.8-2.2C70.5,9.9,69.8,11.2,70.8,11.9z"
        />
        <polygon
          opacity="0.41"
          points="71.8,12.1 70.3,10.2 70.5,9.9 72.2,12.2"
        />
      </g>
      <g>
        <linearGradient
          id="SVGID_11_"
          gradientUnits="userSpaceOnUse"
          x1="69.301"
          y1="-1146.781"
          x2="69.572"
          y2="-1124.164"
          gradientTransform="matrix(1 0 0 -1 0 -1128)"
        >
          <stop offset="0" stopColor="#bf0a00" />
          <stop offset="0" stopColor="#e59572" stopOpacity="0.936" />
          <stop offset="0" stopColor="#fff4bf" stopOpacity="0.89" />
          <stop offset="0.079" stopColor="#fff7d2" stopOpacity="0.795" />
          <stop offset="0.191" stopColor="#fffbe6" stopOpacity="0.661" />
          <stop offset="0.319" stopColor="#fffdf4" stopOpacity="0.507" />
          <stop offset="0.476" stopColor="#fffffd" stopOpacity="0.319" />
          <stop offset="0.742" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="SVGID_12_"
          gradientUnits="userSpaceOnUse"
          x1="69.481"
          y1="-1124.335"
          x2="69.451"
          y2="-1141.008"
          gradientTransform="matrix(1 0 0 -1 0 -1128)"
        >
          <stop offset="0.059" stopColor="#bf0a00" />
          <stop offset="0.522" stopColor="#e7a4a0" stopOpacity="0.383" />
          <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <path
          fill="url(#SVGID_11_)"
          d="M78.8,14.4L76.7,13c0,0,2.2-3.9,1.4-3.9c-1.8,0.2-2.3-1-2.9-1.9C75,7,75,6.8,74.9,6.7 c-0.5-1-0.3-3-0.3-3l-1.5,1.2c-1.2-1-2.5-0.1-3-0.2l-0.9-3l-1,3c-0.5,0.1-1.8-0.9-2.9,0.2L64,3.7c0,0,0.3,1.9-0.2,2.8 c-0.2,0.3-0.4,0.6-0.4,0.8c-0.3,1.1-0.7,2.2-2.8,2.2C60.1,9.6,62,13,62,13l-2.1,1.5H62c0,0.4-0.1,0.9,0.1,1.3 c0.1-0.5,0.8-1,1.3-1.3h0.3c-0.1,0.3-0.1,0.7,0.2,1.1c0-0.2,0.3-0.5,0.5-0.7c0.1,0,0.2,0.1,0.3,0.2c0,0.2,0.1,0.5,0.3,0.7 l0.3-0.4c0.1,0.1,0.2,0.1,0.3,0.2c0,0.5,0,1.1,0.3,1.6c0.1-0.5,0.6-0.9,0.9-1h0.1c0,0,0.1,0,0.1,0.1c0,0.4,0.1,1.1,0.5,1.4 c0-0.3,0.2-0.6,0.4-0.8h2.8c0.2,0.2,0.4,0.5,0.4,0.8c0.4-0.2,0.5-0.9,0.5-1.3c0.1-0.1,0.2-0.1,0.2-0.1l0,0c0.3,0.2,0.8,0.5,0.9,1 c0.3-0.4,0.3-1.1,0.3-1.5c0.1-0.1,0.2-0.1,0.4-0.2l0.3,0.4c0.2-0.2,0.3-0.4,0.3-0.6c0.1-0.1,0.3-0.1,0.4-0.2 c0.2,0.2,0.5,0.5,0.5,0.7c0.3-0.3,0.3-0.7,0.3-1c0.1,0,0.1-0.1,0.2-0.1h0.1c0.4,0.3,1.2,0.8,1.3,1.3c0.2-0.4,0.2-0.9,0.1-1.3h2.2 L78.8,14.4L78.8,14.4z"
        />
        <path
          fill="url(#SVGID_12_)"
          d="M78.9,14.3l-2.1-1.5c0,0,2.2-3.9,1.4-3.9c-1.8,0.2-2.3-1-2.9-1.9c-0.1-0.1-0.1-0.3-0.2-0.4 c-0.5-1-0.3-3-0.3-3l-1.5,1.2c-1.2-1-2.5-0.1-3-0.2l-0.9-3l-1,3c-0.5,0.1-1.8-0.9-2.9,0.2l-1.4-1.2c0,0,0.3,1.9-0.2,2.8 c-0.2,0.3-0.4,0.6-0.4,0.8c-0.3,1.1-0.7,2.2-2.8,2.2c-0.6,0,1.4,3.4,1.4,3.4L60,14.3h2.1c0,0.4-0.1,0.9,0.1,1.3 c0.1-0.5,0.8-1,1.3-1.3h0.3c-0.1,0.3-0.1,0.7,0.2,1.1c0-0.2,0.3-0.5,0.5-0.7c0.1,0,0.2,0.1,0.3,0.2c0,0.2,0.1,0.5,0.3,0.7 l0.3-0.4c0.1,0.1,0.2,0.1,0.3,0.2c0,0.5,0,1.1,0.3,1.6c0.1-0.5,0.6-0.9,0.9-1h0.1c0,0,0.1,0,0.1,0.1c0,0.4,0.1,1.1,0.5,1.4 c0-0.3,0.2-0.6,0.4-0.8h2.9c0.2,0.2,0.4,0.5,0.4,0.8c0.4-0.2,0.5-0.9,0.5-1.3c0.1-0.1,0.2-0.1,0.2-0.1l0,0c0.3,0.2,0.8,0.5,0.9,1 C73,16.7,73,16,73,15.6c0.1-0.1,0.2-0.1,0.4-0.2l0.3,0.4c0.2-0.2,0.3-0.4,0.3-0.6c0.1-0.1,0.3-0.1,0.4-0.2 c0.2,0.2,0.5,0.5,0.5,0.7c0.3-0.3,0.3-0.7,0.3-1c0.1,0,0.1-0.1,0.2-0.1h0.1c0.4,0.3,1.2,0.8,1.3,1.3c0.2-0.4,0.2-0.9,0.1-1.3h2.1 C78.9,14.6,78.9,14.3,78.9,14.3z"
        />
        <linearGradient
          id="SVGID_13_"
          gradientUnits="userSpaceOnUse"
          x1="67.45"
          y1="-1143.158"
          x2="67.447"
          y2="-1145.254"
          gradientTransform="matrix(1 0 0 -1 0 -1128)"
        >
          <stop offset="0.06" stopColor="#fff" />
          <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <path
          fill="url(#SVGID_13_)"
          d="M67.6,15.2c-0.2,0.6-0.6,1.1-0.3,1.7c0,0.1,0.3,0.2,0.2,0c-0.3-0.5,0.1-1.1,0.3-1.6 C67.7,15.3,67.6,15.1,67.6,15.2L67.6,15.2z"
        />
        <linearGradient
          id="SVGID_14_"
          gradientUnits="userSpaceOnUse"
          x1="66.155"
          y1="-1142.341"
          x2="66.15"
          y2="-1144.638"
          gradientTransform="matrix(1 0 0 -1 0 -1128)"
        >
          <stop offset="0.06" stopColor="#fff" />
          <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <path
          fill="url(#SVGID_14_)"
          d="M66.4,14.7c-0.6,0.5-0.6,1.2-0.7,1.8c0,0.1,0.2,0.2,0.2,0.2c0.1-0.7,0.1-1.4,0.7-1.8 C66.7,14.8,66.5,14.6,66.4,14.7L66.4,14.7z"
        />
        <linearGradient
          id="SVGID_15_"
          gradientUnits="userSpaceOnUse"
          x1="64.984"
          y1="-1142.34"
          x2="64.982"
          y2="-1143.623"
          gradientTransform="matrix(1 0 0 -1 0 -1128)"
        >
          <stop offset="0.06" stopColor="#fff" />
          <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <path
          fill="url(#SVGID_15_)"
          d="M64.9,14.4c-0.1,0.3-0.1,0.6,0,0.9c0,0.1,0.2,0.2,0.2,0.1c-0.1-0.3-0.1-0.6,0-0.9 C65.1,14.5,64.9,14.3,64.9,14.4L64.9,14.4z"
        />
        <linearGradient
          id="SVGID_16_"
          gradientUnits="userSpaceOnUse"
          x1="64.047"
          y1="-1141.849"
          x2="64.045"
          y2="-1143.196"
          gradientTransform="matrix(1 0 0 -1 0 -1128)"
        >
          <stop offset="0.06" stopColor="#fff" />
          <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <path
          fill="url(#SVGID_16_)"
          d="M64.1,14.2c-0.3,0.3-0.4,0.5-0.4,0.9c0,0.1,0.2,0.2,0.2,0.2c0-0.4,0.1-0.7,0.4-0.9 C64.3,14.3,64.1,14.1,64.1,14.2L64.1,14.2z"
        />
        <linearGradient
          id="SVGID_17_"
          gradientUnits="userSpaceOnUse"
          x1="62.553"
          y1="-1141.181"
          x2="62.549"
          y2="-1143.329"
          gradientTransform="matrix(1 0 0 -1 0 -1128)"
        >
          <stop offset="0.06" stopColor="#fff" />
          <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <path
          fill="url(#SVGID_17_)"
          d="M63.1,13.5c-0.6,0.3-1,0.9-1.2,1.6c0,0.1,0.2,0.2,0.2,0.2c0.1-0.7,0.5-1.3,1.1-1.6 C63.3,13.7,63.1,13.5,63.1,13.5L63.1,13.5z"
        />
        <linearGradient
          id="SVGID_18_"
          gradientUnits="userSpaceOnUse"
          x1="67.43"
          y1="-1142.967"
          x2="67.426"
          y2="-1145.14"
          gradientTransform="matrix(1 0 0 -1 0 -1128)"
        >
          <stop offset="0.06" stopColor="#fff" />
          <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <path
          fill="url(#SVGID_18_)"
          d="M67.6,15.3c-0.4,0.5-0.5,1.1-0.4,1.7c0,0.1,0.2,0.2,0.2,0.1c-0.2-0.6,0-1.2,0.3-1.6 C67.8,15.4,67.6,15.2,67.6,15.3L67.6,15.3z"
        />
        <g>
          <linearGradient
            id="SVGID_19_"
            gradientUnits="userSpaceOnUse"
            x1="67.37"
            y1="-1143.066"
            x2="67.366"
            y2="-1145.266"
            gradientTransform="matrix(1 0 0 -1 0 -1128)"
          >
            <stop offset="0.06" stopColor="#fff" />
            <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <path
            fill="url(#SVGID_19_)"
            d="M67.5,15.4c-0.4,0.5-0.5,1.1-0.2,1.7c0,0.1,0.2,0.2,0.2,0.1c-0.2-0.5-0.1-1.1,0.2-1.6 C67.6,15.5,67.5,15.3,67.5,15.4L67.5,15.4z"
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_20_"
            gradientUnits="userSpaceOnUse"
            x1="66.075"
            y1="-1142.389"
            x2="66.071"
            y2="-1144.74"
            gradientTransform="matrix(1 0 0 -1 0 -1128)"
          >
            <stop offset="0.06" stopColor="#fff" />
            <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <path
            fill="url(#SVGID_20_)"
            d="M66.3,14.7c-0.5,0.5-0.8,1.2-0.6,1.8c0,0.1,0.2,0.2,0.2,0.1c-0.1-0.7,0.1-1.3,0.6-1.8 C66.6,14.9,66.4,14.7,66.3,14.7L66.3,14.7z"
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_21_"
            gradientUnits="userSpaceOnUse"
            x1="66.09"
            y1="-1142.399"
            x2="66.087"
            y2="-1144.146"
            gradientTransform="matrix(1 0 0 -1 0 -1128)"
          >
            <stop offset="0.06" stopColor="#fff" />
            <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <path
            fill="url(#SVGID_21_)"
            d="M66.3,14.8c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2,0,0.5,0,0.8c0,0.1,0.2,0.2,0.2,0.1 c0-0.3-0.1-0.6,0-0.8c0.1-0.2,0.3-0.3,0.4-0.4C66.5,14.9,66.4,14.7,66.3,14.8L66.3,14.8z"
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_22_"
            gradientUnits="userSpaceOnUse"
            x1="65.026"
            y1="-1142.117"
            x2="65.023"
            y2="-1143.784"
            gradientTransform="matrix(1 0 0 -1 0 -1128)"
          >
            <stop offset="0.06" stopColor="#fff" />
            <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <path
            fill="url(#SVGID_22_)"
            d="M65,14.5c-0.3,0.3-0.2,0.7,0,1c0.1,0.1,0.2,0.1,0.1-0.1c-0.2-0.2-0.1-0.5,0.1-0.7 C65.2,14.6,65,14.4,65,14.5L65,14.5z"
          />
        </g>
        <g>
          <linearGradient
            id="SVGID_23_"
            gradientUnits="userSpaceOnUse"
            x1="64.957"
            y1="-1142.012"
            x2="64.954"
            y2="-1143.8"
            gradientTransform="matrix(1 0 0 -1 0 -1128)"
          >
            <stop offset="0.06" stopColor="#fff" />
            <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <path
            fill="url(#SVGID_23_)"
            d="M64.9,14.4c-0.4,0.3-0.2,0.7,0,1.1c0,0.1,0.3,0.2,0.2,0c-0.2-0.3-0.3-0.7,0.1-0.9 C65.2,14.6,65,14.4,64.9,14.4L64.9,14.4z"
          />
          <g>
            <linearGradient
              id="SVGID_24_"
              gradientUnits="userSpaceOnUse"
              x1="64.084"
              y1="-1141.637"
              x2="64.081"
              y2="-1143.365"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_24_)"
              d="M64.2,14c-0.4,0.2-0.6,0.6-0.5,1c0,0.1,0.2,0.2,0.2,0.1c-0.1-0.4,0.1-0.8,0.5-1 C64.4,14.2,64.3,14,64.2,14L64.2,14z"
            />
            <linearGradient
              id="SVGID_25_"
              gradientUnits="userSpaceOnUse"
              x1="64.043"
              y1="-1141.776"
              x2="64.04"
              y2="-1143.691"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_25_)"
              d="M64.1,14.1c-0.4,0.3-0.5,0.8-0.4,1.3c0,0.1,0.2,0.2,0.2,0.1c-0.2-0.5,0-0.9,0.4-1.2 C64.4,14.3,64.2,14.1,64.1,14.1L64.1,14.1z"
            />
            <linearGradient
              id="SVGID_26_"
              gradientUnits="userSpaceOnUse"
              x1="62.625"
              y1="-1141.136"
              x2="62.62"
              y2="-1143.972"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_26_)"
              d="M63.1,13.6c-0.5-0.1-0.9,0.4-1,0.8c-0.1,0.3-0.1,0.8,0.1,1.1c0.1,0.1,0.2,0.1,0.1-0.1 c-0.1-0.1,0-0.8,0-0.9c0.1-0.3,0.5-0.8,0.9-0.7C63.3,13.8,63.2,13.6,63.1,13.6L63.1,13.6z"
            />
            <linearGradient
              id="SVGID_27_"
              gradientUnits="userSpaceOnUse"
              x1="62.773"
              y1="-1141.365"
              x2="62.769"
              y2="-1143.532"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_27_)"
              d="M63.1,13.8c-0.6,0.3-0.9,0.9-0.8,1.6c0,0.1,0.2,0.2,0.2,0.1c-0.1-0.7,0.2-1.2,0.8-1.5 C63.3,14,63.2,13.7,63.1,13.8L63.1,13.8z"
            />
            <linearGradient
              id="SVGID_28_"
              gradientUnits="userSpaceOnUse"
              x1="62.657"
              y1="-1141.063"
              x2="62.653"
              y2="-1143.25"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_28_)"
              d="M63.2,13.4c-0.3,0.1-0.7,0.3-0.9,0.6C62,14.3,62,14.7,62,15.1c0,0.1,0.2,0.2,0.2,0.2 c0-0.4,0-0.8,0.2-1.1c0.2-0.3,0.6-0.4,0.9-0.5C63.4,13.6,63.3,13.4,63.2,13.4L63.2,13.4z"
            />
            <linearGradient
              id="SVGID_29_"
              gradientUnits="userSpaceOnUse"
              x1="71.298"
              y1="-1142.88"
              x2="71.293"
              y2="-1145.239"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_29_)"
              d="M71.1,15.3c0.4,0.5,0.4,1.1,0.1,1.6c0,0.1,0.2,0.3,0.2,0.2C71.7,16.5,71.6,15.8,71.1,15.3 C71.1,15.1,71,15.2,71.1,15.3L71.1,15.3z"
            />
            <linearGradient
              id="SVGID_30_"
              gradientUnits="userSpaceOnUse"
              x1="71.277"
              y1="-1143.032"
              x2="71.273"
              y2="-1145.376"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_30_)"
              d="M71.1,15.4c0.4,0.5,0.4,1.1,0.1,1.7c0,0.1,0.2,0.2,0.2,0.2c0.3-0.6,0.2-1.3-0.1-1.8 C71.2,15.3,71,15.2,71.1,15.4L71.1,15.4z"
            />
            <linearGradient
              id="SVGID_31_"
              gradientUnits="userSpaceOnUse"
              x1="72.483"
              y1="-1142.275"
              x2="72.479"
              y2="-1144.687"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_31_)"
              d="M72.2,14.7c0.5,0.5,0.4,1.3,0.4,1.8c0,0.1,0.2,0.2,0.2,0.2C72.8,16,72.9,15.3,72.2,14.7 C72.1,14.5,72.1,14.6,72.2,14.7L72.2,14.7z"
            />
            <linearGradient
              id="SVGID_32_"
              gradientUnits="userSpaceOnUse"
              x1="72.681"
              y1="-1142.342"
              x2="72.677"
              y2="-1144.671"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_32_)"
              d="M72.5,15c0.4,0.2,0.1,1.2,0.1,1.5c0,0.1,0.2,0.2,0.2,0.2c0.2-0.6,0.4-1.6-0.4-1.8 C72.3,14.8,72.4,15,72.5,15L72.5,15z"
            />
            <linearGradient
              id="SVGID_33_"
              gradientUnits="userSpaceOnUse"
              x1="72.6"
              y1="-1142.411"
              x2="72.597"
              y2="-1143.884"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_33_)"
              d="M72.3,14.8c0.3,0.3,0.4,0.6,0.4,1c0,0.1,0.2,0.2,0.2,0.2C72.9,15.5,72.7,15.2,72.3,14.8 C72.4,14.7,72.2,14.6,72.3,14.8L72.3,14.8z"
            />
            <linearGradient
              id="SVGID_34_"
              gradientUnits="userSpaceOnUse"
              x1="73.801"
              y1="-1142.408"
              x2="73.799"
              y2="-1143.835"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_34_)"
              d="M73.7,14.6c0.1,0.3,0,0.6-0.1,0.8c0,0.1,0.2,0.3,0.2,0.2c0.2-0.3,0.3-0.5,0.2-0.9 C73.8,14.6,73.6,14.5,73.7,14.6L73.7,14.6z"
            />
            <linearGradient
              id="SVGID_35_"
              gradientUnits="userSpaceOnUse"
              x1="74.719"
              y1="-1141.722"
              x2="74.717"
              y2="-1143.191"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_35_)"
              d="M74.7,14.1L74.7,14.1C74.6,14.1,74.6,14.1,74.7,14.1c0,0.3,0,0.6,0,0.9 c0,0.1,0.2,0.2,0.2,0.2c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1,0-0.1C74.9,14.3,74.8,14.1,74.7,14.1L74.7,14.1z"
            />
            <linearGradient
              id="SVGID_36_"
              gradientUnits="userSpaceOnUse"
              x1="76.049"
              y1="-1141.225"
              x2="76.045"
              y2="-1143.243"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_36_)"
              d="M75.7,13.7c0.4,0.3,0.4,0.8,0.5,1.3c0,0.1,0.2,0.2,0.2,0.1c-0.1-0.7-0.2-1.2-0.7-1.6 C75.6,13.5,75.7,13.7,75.7,13.7L75.7,13.7z"
            />
            <linearGradient
              id="SVGID_37_"
              gradientUnits="userSpaceOnUse"
              x1="76.251"
              y1="-1141.53"
              x2="76.249"
              y2="-1142.908"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_37_)"
              d="M76,13.8c0.2,0.3,0.3,0.6,0.3,1c0,0.1,0.2,0.2,0.2,0.2c0-0.4-0.1-0.8-0.3-1.1 C76.1,13.8,75.9,13.7,76,13.8L76,13.8z"
            />
            <linearGradient
              id="SVGID_38_"
              gradientUnits="userSpaceOnUse"
              x1="76.397"
              y1="-1141.751"
              x2="76.395"
              y2="-1142.85"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_38_)"
              d="M76.2,14c0.1,0.2,0.2,0.4,0.2,0.7c0,0.1,0.2,0.2,0.2,0.2c0-0.3-0.1-0.5-0.2-0.8 C76.3,14,76.1,13.9,76.2,14L76.2,14z"
            />
            <linearGradient
              id="SVGID_39_"
              gradientUnits="userSpaceOnUse"
              x1="67.233"
              y1="-1133.413"
              x2="67.223"
              y2="-1138.996"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_39_)"
              d="M67,5.9c0.5,0.4,0.9,0.8,0.8,1.4c-0.1,0.4-0.3,0.8-0.4,1.2c-0.3,0.8-0.6,1.6-0.9,2.2 c0,0.1,0.2,0.3,0.2,0.2c0.4-1.2,1-2.3,1.3-3.5C68.1,6.7,67.5,6.2,67,5.9C66.9,5.7,66.9,5.9,67,5.9L67,5.9z"
            />
            <linearGradient
              id="SVGID_40_"
              gradientUnits="userSpaceOnUse"
              x1="71.223"
              y1="-1133.166"
              x2="71.215"
              y2="-1137.83"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_40_)"
              d="M71.8,5.6c-0.6,0.4-1.4,0.7-1.4,1.6c0.1,0.9,0.9,1.7,0.7,2.4c0,0.1,0.2,0.2,0.2,0.2 c0.1-0.7-0.4-1.4-0.6-1.9c-0.2-0.5-0.2-1,0.2-1.4c0.3-0.3,0.7-0.5,1-0.7C72,5.8,71.9,5.5,71.8,5.6L71.8,5.6z"
            />
            <linearGradient
              id="SVGID_41_"
              gradientUnits="userSpaceOnUse"
              x1="68.318"
              y1="-1137.249"
              x2="68.315"
              y2="-1138.72"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_41_)"
              d="M68.1,10.1c0.1-0.1,0.1-0.2,0.2-0.1c0.1,0,0.2,0.4,0.2,0.4c0,0.1,0.2,0.2,0.2,0.1 c-0.1-0.2-0.2-0.6-0.4-0.8c-0.2-0.2-0.3,0-0.4,0.2C67.8,10,68,10.2,68.1,10.1L68.1,10.1z"
            />
            <linearGradient
              id="SVGID_42_"
              gradientUnits="userSpaceOnUse"
              x1="70.347"
              y1="-1137.329"
              x2="70.344"
              y2="-1138.736"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_42_)"
              d="M70.8,10.1c-0.1-0.1-0.2-0.3-0.4-0.3c-0.3-0.1-0.4,0.3-0.5,0.6c0,0.1,0.2,0.3,0.2,0.2 C70.2,10.5,70.4,9.8,70.8,10.1C70.8,10.2,70.9,10.2,70.8,10.1L70.8,10.1z"
            />
            <linearGradient
              id="SVGID_43_"
              gradientUnits="userSpaceOnUse"
              x1="62.003"
              y1="-1137.019"
              x2="62.001"
              y2="-1137.989"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="SVGID_44_"
              gradientUnits="userSpaceOnUse"
              x1="61.308"
              y1="-1141.047"
              x2="61.306"
              y2="-1142.351"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="SVGID_45_"
              gradientUnits="userSpaceOnUse"
              x1="64.73"
              y1="-1133.704"
              x2="64.725"
              y2="-1136.631"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="SVGID_46_"
              gradientUnits="userSpaceOnUse"
              x1="74.603"
              y1="-1134.629"
              x2="74.599"
              y2="-1137"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="SVGID_47_"
              gradientUnits="userSpaceOnUse"
              x1="64.631"
              y1="-1131.763"
              x2="64.628"
              y2="-1133.522"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="SVGID_48_"
              gradientUnits="userSpaceOnUse"
              x1="69.198"
              y1="-1129.864"
              x2="69.192"
              y2="-1132.896"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="SVGID_49_"
              gradientUnits="userSpaceOnUse"
              x1="73.685"
              y1="-1131.804"
              x2="73.682"
              y2="-1133.445"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="SVGID_50_"
              gradientUnits="userSpaceOnUse"
              x1="76.82"
              y1="-1136.873"
              x2="76.819"
              y2="-1137.535"
              gradientTransform="matrix(1 0 0 -1 0 -1128)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="SVGID_51_"
              gradientUnits="userSpaceOnUse"
              x1="-2302.965"
              y1="-1140.937"
              x2="-2302.967"
              y2="-1142.24"
              gradientTransform="rotate(180 -1112.834 -564)"
            >
              <stop offset="0.06" stopColor="#fff" />
              <stop offset="0.81" stopColor="#fff" stopOpacity="0" />
            </linearGradient>
            <path
              fill="url(#SVGID_43_)"
              d="M63.1,9.4L63.1,9.4c-0.1,0-0.1-0.1-0.2-0.1l0,0c0,0-0.1-0.2-0.2-0.2c-0.6,0.3-1.3,0.6-1.9,0.8 c-0.1,0,0.1,0.2,0.2,0.2c0.2,0,0.3-0.1,0.5-0.1c0,0.1,0.1,0.2,0.2,0.2h0.1c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.4-0.1,0.9-0.2,1.3-0.3C63.4,9.6,63.2,9.4,63.1,9.4z"
            />
            <path
              fill="url(#SVGID_44_)"
              d="M62,13.5C62,13.5,62.1,13.5,62,13.5c0.1-0.1,0.1-0.2,0-0.3c0,0,0,0,0.1,0c0,0-0.1-0.3-0.2-0.2 c-0.4,0.2-0.8,0.5-1.2,0.7v0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.3-0.1c-0.1,0-0.2,0.1-0.2,0.1 s0.1,0.2,0.2,0.2c0.4-0.2,0.9-0.4,1.3-0.7C62.2,13.6,62.1,13.5,62,13.5z"
            />
            <path
              fill="url(#SVGID_45_)"
              d="M65.7,5.8c-1,0.7-1.6,1.7-2.1,2.7c0,0.1,0.2,0.3,0.2,0.2c0.6-1,1.2-2,2.1-2.7 C65.9,5.9,65.7,5.7,65.7,5.8z"
            />
            <path
              fill="url(#SVGID_46_)"
              d="M75.3,8.9C75,8.5,74.9,8,74.7,7.6C74.5,7.3,74.3,7,74,6.7c-0.1-0.1-0.2-0.2-0.1,0 c0.3,0.4,0.6,0.8,0.8,1.3c0.1,0.3,0.3,0.6,0.5,0.9C75.2,9,75.4,9.1,75.3,8.9z"
            />
            <path
              fill="url(#SVGID_47_)"
              d="M65,5.1c0,0-0.1-0.2-0.2-0.2c0-0.1,0.1-0.2,0.1-0.3s-0.2-0.3-0.2-0.2c-0.1,0.1-0.2,0.3-0.2,0.4 c0-0.2,0-0.4,0.1-0.6c0-0.1-0.2-0.2-0.2-0.2c0,0.1,0,0.1,0,0.2c0-0.1,0-0.2,0-0.3s-0.2-0.2-0.2-0.1c0.1,0.4,0.1,0.8,0.1,1.2 c0,0.1-0.1,0.3-0.1,0.4s0.2,0.2,0.2,0.2l0,0c0.1,0,0.1,0.1,0.1,0.1c0-0.1,0-0.1,0-0.2l0,0l0,0l0.1,0.1l0.1-0.1l0,0 C64.9,5.2,65,5.1,65,5.1z"
            />
            <path
              fill="url(#SVGID_48_)"
              d="M69.4,4.4c-0.1,0-0.2,0.1-0.2,0.1c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.1-0.2-0.3-0.2-0.2 c-0.1,0.1-0.2,0.2-0.3,0.3l0.5-0.6c0-0.1-0.2-0.3-0.2-0.2c-0.1,0.2-0.3,0.3-0.4,0.5c0.1-0.1,0.2-0.3,0.2-0.4 c0.1-0.2,0.2-0.4,0.1-0.6c0,0,0,0-0.1-0.1c0.1-0.2,0-0.3,0.1-0.6c0-0.1-0.1-0.4-0.1-0.3c-0.2,0.6-0.4,1.3-0.6,1.9 c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1-0.1,0.2,0,0.2v0.1c0,0.1,0.2,0.2,0.2,0.2V4.8c0,0.1,0.1,0.1,0.1,0.1c0.2-0.1,0.5-0.2,0.7-0.3 C69.6,4.6,69.5,4.4,69.4,4.4z"
            />
            <path
              fill="url(#SVGID_49_)"
              d="M74.3,4.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0-0.1-0.3-0.2-0.2c-0.5,0.5-1.1,1-1.6,1.5 c-0.1,0.1,0.1,0.3,0.2,0.2c0.1-0.1,0.3-0.3,0.4-0.4l0.1,0.1c0,0,0,0,0.1,0c0,0,0.1,0.2,0.2,0.2c0,0-0.1-0.2-0.2-0.2 C73.8,5.1,74,5,74.2,5c0,0-0.1-0.2-0.2-0.2l0,0c0.1-0.1,0.3-0.2,0.4-0.2C74.4,4.4,74.4,4.4,74.3,4.3z"
            />
            <path
              fill="url(#SVGID_50_)"
              d="M78.1,9.1c-0.1,0.1,0,0.1-0.2,0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0s-0.3,0-0.4,0 c-0.4,0-0.9,0-1.3-0.3c-0.1-0.1,0,0.1,0.1,0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0,0,0.1,0,0.1s0,0-0.1,0l0,0l0,0 c0,0.1,0.2,0.2,0.2,0.2c0,0.1,0.8,0.2,1,0.2c0.4,0,0.8,0,1.2-0.2C78.1,9.3,78,9.3,78.1,9.1C78.2,9.2,78.1,9,78.1,9.1z"
            />
            <path
              fill="url(#SVGID_51_)"
              d="M76.6,13.4C76.6,13.4,76.5,13.4,76.6,13.4c-0.1-0.1-0.1-0.2,0-0.3c0,0,0,0-0.1,0 c0,0,0.1-0.3,0.2-0.2c0.4,0.2,0.8,0.5,1.2,0.7v0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0-0.1,0.2-0.2,0.2s-0.2-0.1-0.3-0.1 c0.1,0,0.2,0.1,0.2,0.1s-0.1,0.2-0.2,0.2c-0.4-0.2-0.9-0.4-1.3-0.7C76.4,13.5,76.5,13.4,76.6,13.4z"
            />
          </g>
        </g>
      </g>
      <linearGradient
        id="SVGID_10_"
        gradientUnits="userSpaceOnUse"
        x1="69.264"
        y1="-1144.248"
        x2="69.458"
        y2="-1136.382"
        gradientTransform="matrix(1 0 0 -1 0 -1128)"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.742" stopColor="#fff" stopOpacity="0" />
      </linearGradient>
    </g>
  )
}

export default DragonSkull
