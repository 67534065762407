import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Boots from "./Boots"
import Feet from "./Feet"

const CATEGORY = "shoes"
const CHARACTER = "ManGnome"
export const IDS = {
  BOOTS: "Boots",
  FEET: "Feet"
}

export const components = {
  [IDS.BOOTS]: Boots,
  [IDS.FEET]: Feet
}

export const Group = ({ props }) => (
  <g id="shoes">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.BOOTS,
    name: "Boots",
    defaultColors: ["#77634F"],
    colorable: true,
    component: components[IDS.BOOTS],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FEET,
    name: "Feet",
    defaultColors: ["#494846", "#01060F", "#FFFFFF", "#9C7000", "#6B6967"],
    colorable: false,
    component: components[IDS.FEET],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
