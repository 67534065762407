import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import ManMixed from '../ManMixed'
import WomanMixed from '../WomanMixed'
import ManHalfling from '../ManHalfling'
import ManDwarf from '../ManDwarf'
import WomanDwarf from '../WomanDwarf'
import WomanGnome from '../WomanGnome'
import ManGnome from '../ManGnome'
import ManDragonborn from '../ManDragonborn'

import { remixCharacter } from '../actions/builderActions'

import { characterSelector, characterIdSelector } from '../selectors/builderSelectors'

import { uiSchemaService } from '../uiSchema'

import {
  setDefaultEnabled,
} from '../reducers/builderReducer'

class Character extends React.Component {
  componentDidMount() {
    if (this.props.characterId) {
      this.props.remixCharacter(this.props.characterId)
    }
    this.centerArt()
    this.loadDefaults()
  }

  componentDidUpdate() {
    this.centerArt()
    this.loadDefaults()
  }

  loadDefaults = () => {
    const { enabledElements, character, setDefaultEnabled } = this.props
    const elements = get(enabledElements, character) || {}
    const keys = Object.keys(elements)
    if (keys.length === 0) setDefaultEnabled({ elements: uiSchemaService.getDefaultEnabled(character), character })
  }

  centerArt() {
    const { svgRef } = this.props
    const bB = svgRef.current.getBBox()
    const viewBox = svgRef.current.viewBox
    svgRef.current.setAttribute('viewBox', bB.x + ',' + bB.y + ',' + bB.width + ',' + viewBox.baseVal.height)
  }

  render() {
    const { character, svgRef } = this.props

    switch(character) {
      case "WomanMixed":
        return <WomanMixed svgRef={svgRef} />
      case "ManMixed":
        return <ManMixed svgRef={svgRef} />
      case "ManHalfling":
        return <ManHalfling svgRef={svgRef} />
      case "ManDwarf":
        return <ManDwarf svgRef={svgRef} />
      case "WomanDwarf":
        return <WomanDwarf svgRef={svgRef} />
      case "WomanGnome":
        return <WomanGnome svgRef={svgRef} />
      case "ManGnome":
        return <ManGnome svgRef={svgRef} />
      case "ManDragonborn":
        return <ManDragonborn svgRef={svgRef} />
      default:
        return <WomanMixed svgRef={svgRef} />
    }
  }
}

const mapStateToProps = state => ({
  enabledElements: state.builderReducer.enabledElements,
  character: characterSelector(state),
  characterId: characterIdSelector(state)
})

const mapDispatchToProps = {
  setDefaultEnabled,
  remixCharacter
}

export default connect(mapStateToProps, mapDispatchToProps)(Character)