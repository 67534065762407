import React from "react"

const Pants = ({ colors = ["#54545F"] }) => {
  return (
    <g id="Pants">
      <path
        fill={colors[0]}
        d="M39.7438927,115.5537872l2.5126953-8.0159302l0.2816772-3.5002441l0.7644043-2.8564453 l0.2355957-2.5462646l2.0344238-10.1743164l1.8725739,0.1943817l1.9409027-0.1943817l2.0343628,10.1743164l0.2355957,2.5462646 l0.7644043,2.8564453l0.2816772,3.5002441l2.5126953,8.0159302h1.3479614 c-0.0006714-0.0078125-0.0036621-0.0134277-0.0042114-0.0213623c-0.144165-1.9962158-0.6146851-8.0567627-0.6280518-10.7835693 c-0.0097046-1.989624-1.0292358-3.0258789-1.2337646-3.8967285c-0.078064-0.3322754-0.0892944-1.7133789-0.1072998-1.9311523 c0.6895142-4.2329712-0.4205933-10.6599121-0.7498779-13.2612915h-12.72052 c-0.3292847,2.6013794-1.4393921,9.0283203-0.7498169,13.2612915c-0.0180054,0.2177734-0.0292358,1.598877-0.1072998,1.9311523 c-0.2045288,0.8708496-1.2240601,1.9071045-1.2337646,3.8967285c-0.0133667,2.7268066-0.4839478,8.7873535-0.6281128,10.7835693 c-0.0005493,0.0079346-0.00354,0.0135498-0.0042114,0.0213623H39.7438927z"
      />
      <g id="kneeShadow" fill="#21376C">
        <path
          opacity="0.42"
          d="M41.2495003,101.8988647 c0,0,1.1970253,0.3804855,1.6897507-0.8046494c0.4927292-1.1851273-0.5230179,2.1725388-0.5230179,2.1725388 L41.2495003,101.8988647z"
        />
        <path
          opacity="0.42"
          d="M53.5881615,101.8988647 c0,0-1.1970253,0.3804855-1.6897545-0.8046494c-0.4927292-1.1851273,0.5230217,2.1725388,0.5230217,2.1725388 L53.5881615,101.8988647z"
        />
      </g>
    </g>
  )
}

export default Pants
