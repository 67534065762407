import React from "react"

const BlueFlameRight = ({
  colors = ["#80D0DC", "#30BFCA", "#5BC6D1", "#A8DDE6", "#C0E6F0", "#E5F5F8"]
}) => {
  return (
    <g id="BlueFlameRight">
      <path
        opacity="0.77"
        fill={colors[0]}
        d="M45.6459007,37.4262238 c-1.4000015-3.0999947-3.0999985-6.0999947-5.2000008-8.8999977c-0.0999985,0-0.0999985,0-0.2000008,0.0999985 c-0.2000008,0.0999985-1.5999985,0.7999992-1.5,0c0.2000008-1.5,0.6000023-3.4000015,0-4.7999992 c-0.3999977-1.0999985-0.5999985-4.2999992-1.3999977-5.2000008c-0.1000023-0.2000008-2-4.9000015-2.5-6.0999985 c-0.7999992,2.5999985,0.5,5.7999992-0.9000015,8.0999985c-0.0999985,0.0999985-2.7000008,5.5999985-2.8999977,5.7999992 c-0.1000023,3.7000008-0.4000015,7.5-1.2000008,11.1000061c0.3999977-0.2000046,1.0999985-0.5,1-0.2000046 c-1.1000023,4.9000015-4.4000015,8.9000015-5.5,13.7999954c0,0.1999969-0.2000008,0.4000092-0.4000015,0.5 c0.4000015,2.9000092,2.2000008,5.5,4.2000008,7.8000031c-1.0999985-2.5999908-1.8999977-5.1999969-1.8999977-8 c0-0.3999939,0.5999985-0.8000031,1.2999992-1.1999969c0.2000008-3.1999969,0.5-6.0999908,2.5-8.8999939 c2.3999977-3.5,1.5999985-7.3000031,2.2000008-10.9000053c-0.1000023,0-0.1000023,0-0.1000023-0.0999985 c0-0.7999992,0.1000023-1.5999985,0.2000008-2.4000015v-0.0999985c0,0,0,0,0-0.0999985 c0.1000023-0.5999985,0.2000008-1.2000008,0.4000015-1.7999992c0-0.5,3.2000008-3.9000015,3.2999992-3.7000008 c0.2000008,0.7999992,0.5999985,1.4000015,0.5,2.0999985c0.0999985,0,0.2000008,0,0.2000008,0.0999985 c0,2.2000008,0.0999985,4.4000015,0.3999977,6.5999985c0.1000023,0,0.1000023,0,0.2000008,0.0999985 c2.2999992,2.5,3,6.1000023,4.0999985,9.2999992c1.2999992,3.8999939,2,6.3999939,2.0999985,10.5 c0,0.1999969-0.0999985,0.3000031-0.2000008,0.5c0.7999992,2.3000031-0.4000015,4.8999939-0.9000015,7.3000031 c-0.0999985,0.3000031-0.2000008,0.6999969-0.2999992,1.0999908c1.0999985-2.1999969,2-4.5,2.9000015-6.8999939 C45.8459015,47.8262329,45.745903,42.6262283,45.6459007,37.4262238z"
      />
      <g opacity="0.98">
        <path
          fill={colors[1]}
          d="M37.6459007,62.5262299c-1.8999977-0.6000061-3.5999985-1.3000031-5.2000008-2.1999969 c-0.3999977-0.6999969-0.7000008-1.5-1.0999985-2.1999969c-0.9000015-1.9000092-1.6000023-3.9000092-2.2999992-6.1000061 c0.0999985-0.6999969,0.2000008-1.3999939,0.3999977-2c0.2000008-0.8000031,0.5-1.6999969,0.7000008-2.5 c0.4000015-1.3000031,0.7000008-2.5999908,1-3.8999939l1-0.4000092l-0.5-1c0-0.0999908-0.0999985-0.0999908-0.0999985-0.1999969 c2.0999985-2.5,3.2999992-5,3.7000008-7.4000015c0.0999985-0.9000015,0.0999985-1.7000008,0-2.5999985l0.5999985-0.2999992 c0.2000008,0.5999985,0.4000015,1.0999985,0.6000023,1.5c0.7000008,1.7999992,1.5999985,3.4999962,2.3999977,5.1000023 c1,2,2,3.7999954,2.7000008,5.8000031c1.7000008,5,1.5,10.3000031-0.7000008,16.8999939 C39.8459015,61.7262268,38.745903,62.2262268,37.6459007,62.5262299z M35.245903,30.2262306 c-0.7000008-0.7000008-1.1000023-1.7000008-1.1000023-2.7000008c0.1000023-0.7999992,0.4000015-1.5999985,1-2.0999985 C34.745903,27.1262283,34.9459038,28.8262291,35.245903,30.2262306L35.245903,30.2262306z"
        />
        <path
          fill={colors[2]}
          d="M37.5459023,62.7262268c-1.5999985-0.5-3-1.0999908-4.3999977-1.8000031 c-0.2999992-0.5999908-0.6000023-1.3000031-0.9000015-1.8999939c-0.7999992-1.8000031-1.5-3.6999969-2.2000008-5.6999969 c0.1000023-0.6000061,0.2000008-1.1000061,0.2999992-1.6000061c0.2000008-0.8000031,0.4000015-1.5999908,0.7000008-2.5 c0.2999992-1.0999908,0.6000023-2.1999969,0.9000015-3.3999939l1.5-0.6999969l-0.7999992-1.6000061 c1.7999992-2.2999954,2.8999977-4.5,3.2999992-6.6999969c0.5,1.1999969,1.0999985,2.3000031,1.7000008,3.4000015 c1,1.9000015,2,3.7000046,2.5999985,5.7000046c1.5999985,4.6999969,1.4000015,9.6999969-0.5999985,15.8000031 C39.1459007,62.126236,38.3459015,62.4262238,37.5459023,62.7262268z"
        />
        <path
          fill={colors[0]}
          d="M37.4459038,62.7262268c-1.2999992-0.3999939-2.5-0.8999939-3.6000023-1.5 c-0.2000008-0.5-0.5-1-0.7000008-1.5999908c-0.7999992-1.6999969-1.3999977-3.4000092-2.0999985-5.4000092 c0.0999985-0.5,0.2000008-0.8999939,0.2999992-1.3000031c0.2000008-0.8000031,0.3999977-1.5999908,0.7000008-2.3999939 c0.2999992-0.9000092,0.5-1.9000092,0.7999992-2.9000092L34.9459,46.7262268l-1-2c1-1.3000031,1.7999992-2.6000061,2.2999992-4 c0.2000008,0.2999954,0.2999992,0.6999969,0.5,1c1,1.8999939,1.9000015,3.6999969,2.6000023,5.5 c1.5,4.3999939,1.2999992,9.0999908-0.4000015,14.8000031C38.3459015,62.3262329,37.9459038,62.5262299,37.4459038,62.7262268z"
        />
        <path
          fill={colors[3]}
          d="M37.3459015,62.8262329c-1-0.3000031-1.8999977-0.6999969-2.7000008-1.1000061 c-0.2000008-0.3999939-0.3999977-0.8000031-0.5999985-1.1999969c-0.7000008-1.5999908-1.2999992-3.1999969-1.9000015-5 c0.1000023-0.3000031,0.1000023-0.5999908,0.2000008-0.8999939c0.2000008-0.8000031,0.4000015-1.6000061,0.7000008-2.4000092 c0.2000008-0.8000031,0.5-1.5999908,0.7000008-2.3999939l2.7000008-1.1999969l-1.2999992-2.4000092 c0.4000015-0.5999908,0.7999992-1.0999908,1.1000023-1.6999969c0.7999992,1.5,1.5,3,2,4.5 c1.3999977,4,1.2999992,8.4000092-0.2999992,13.6999969C37.6459007,62.626236,37.5459023,62.7262268,37.3459015,62.8262329z"
        />
        <path
          fill={colors[4]}
          d="M36.9459038,62.4262238c-0.6000023-0.1999969-1.1000023-0.3999939-1.6000023-0.6999969 c-0.0999985-0.3000031-0.2999992-0.5999908-0.3999977-0.8000031c-0.7000008-1.5-1.2999992-3-1.7999992-4.5999908 c0-0.1999969,0.0999985-0.4000092,0.0999985-0.6000061c0.2000008-0.8000031,0.4000015-1.5999908,0.7000008-2.3999939 c0.2000008-0.6000061,0.4000015-1.3000031,0.6000023-2l2.5999985-1.1000061 C38.3459015,53.8262329,38.245903,57.7262268,36.9459038,62.4262238z"
        />
        <path
          fill={colors[5]}
          d="M37.1459007,62.9262238c-0.0999985-0.1999969-0.0999985-0.3000031-0.2000008-0.5 c-0.5999985-1.3999939-1.2000008-2.6999969-1.7000008-4.1999969c0-0.0999908,0-0.1999969,0.1000023-0.3000031 c0.2000008-0.8000031,0.3999977-1.5999908,0.5999985-2.3000031c0.1000023-0.5,0.2999992-1,0.4000015-1.5l1-0.3999939 C38.1459007,56.4262238,38.1459007,59.4262238,37.1459007,62.9262238 C37.245903,62.9262238,37.245903,62.9262238,37.1459007,62.9262238z"
        />
      </g>
    </g>
  )
}

export default BlueFlameRight
