import React from "react"

const WarhammerR = ({
  colors = [
    "#B9BABB",
    "#727373",
    "#E7E8E8",
    "#646464",
    "#7F8080",
    "#636464",
    "#CECFCF",
    "#A2A2A3",
    "#D6D7D6",
    "#A1A2A3"
  ]
}) => {
  return (
    <g id="WarhammerR">
      <path
        opacity="0.63"
        d="M38.9000015,102.9000015 c0.2000008,0,0.4000015-0.0999985,0.5999985-0.1999969c0.7999992-0.5,0.7999992-1.4000015,0.5999985-1.8000031L38.1999969,97.5H38 L24.3999996,72.5999985v-0.1999969l-0.5-1v-0.1999969l-0.2000008-0.4000015h-0.2000008L21,66.3000031l-0.2000008-0.0999985 l-1.6000004-2.7999992v-0.2000008l-0.2999992-0.5999985l1.7000008-1l0.2000008-0.5l0.5,0.0999985l1-0.5999985l0.2000008-0.2999992 L24,56l-0.3999996-0.7000008l-4.3999996-1l-0.3999996,0.0999985l-1,0.5999985l-0.2000008,0.5l-0.5-0.0999985l-6,3.2999992 l-0.5-0.0999985l-1.1999998,0.7000008L9.6000004,60l-0.8999996,0.5l-0.1000004,0.4000015H8.5l-0.1000004,1.4000015L8.5,62.6000023 l1.8000002,3.2000008L10.5,66l1.1999998,0.5999985h0.5l0.8999996-0.5l0.3999996,0.0999985L14.6999989,65.5l0.1000004-0.4000015 l1.7999992-1l0.2999992,0.5999985H17L18.5,67.5v0.0999985l2.5,4.5999985v0.1999969l0.2000008,0.4000015h0.2000008l0.5,1H22 l13.7000008,24.7999954v0.0999985l1.9000015,3.4000015C37.7000008,102.3000031,38.2000008,102.9000015,38.9000015,102.9000015z"
      />
      <rect
        x="27.496"
        y="60.492"
        transform="rotate(-28.959 28.296 82.443)"
        fill={colors[0]}
        width="1.6"
        height="43.902"
      />
      <rect
        x="16.711"
        y="62.98"
        transform="rotate(-28.959 17.661 63.03)"
        fill={colors[1]}
        width="1.9"
        height="0.1"
      />
      <rect
        x="18.902"
        y="66.978"
        transform="rotate(-28.959 19.852 67.028)"
        fill={colors[1]}
        width="1.9"
        height="0.1"
      />
      <rect
        x="18.801"
        y="67.004"
        transform="rotate(-28.959 19.751 67.054)"
        fill={colors[2]}
        width="1.9"
        height="0.1"
      />
      <rect
        x="16.901"
        y="63.101"
        transform="rotate(-28.959 17.85 63.6)"
        fill={colors[3]}
        width="1.9"
        height="1"
      />
      <rect
        x="20.608"
        y="66.679"
        transform="rotate(-28.959 21.558 70.079)"
        fill={colors[4]}
        width="1.9"
        height="6.8"
      />
      <rect
        x="22.394"
        y="73.1"
        transform="rotate(-28.959 23.194 73.3)"
        fill={colors[5]}
        width="1.6"
        height="0.4"
      />
      <rect
        x="28.708"
        y="76.997"
        transform="rotate(-28.959 29.508 84.598)"
        fill={colors[4]}
        width="1.6"
        height="15.201"
      />
      <rect
        x="37.004"
        y="97.886"
        transform="rotate(-28.959 37.954 99.786)"
        fill={colors[4]}
        width="1.9"
        height="3.8"
      />
      <rect
        x="36.21"
        y="98.106"
        transform="rotate(-28.959 37.01 98.206)"
        fill={colors[6]}
        width="1.6"
        height="0.2"
      />
      <polyline
        fill={colors[7]}
        points="23.2999992,71.0999985 23.5,71.5 21.7000008,72.5999985 21.3999996,72.0999985"
      />
      <path
        fill={colors[8]}
        d="M38.0999985,102l1.5999985-0.9000015c0,0,0.2999992,0.9000015-0.4000015,1.1999969 C38.5999985,102.6999969,38.0999985,102,38.0999985,102z"
      />
      <path
        fill={colors[9]}
        d="M22.2000008,60.4000015l-1,0.5999985c0,0-0.1000004-0.2000008-0.2999992-0.5 c-0.7000008,0.2000008-0.3999996,0.9000015-0.3999996,0.9000015l-8.6000004,4.8000031L9,60.9000015l8.6000004-4.7999992 c0,0,0.3999996,0.7000008,1,0.2000008c-0.2999992-0.5999985-0.2999992-0.5999985-0.2999992-0.5999985l1-0.5999985l4.3999996,1 L22.2000008,60.4000015z"
      />
      <rect
        x="11.504"
        y="59.183"
        transform="rotate(-28.959 12.204 62.483)"
        fill={colors[3]}
        width="1.4"
        height="6.6"
      />
      <rect
        x="19.691"
        y="55.185"
        transform="rotate(-28.959 19.941 58.185)"
        fill={colors[3]}
        width="0.5"
        height="6"
      />
      <rect
        x="18.701"
        y="55.778"
        transform="rotate(-28.959 18.9 58.778)"
        fill={colors[3]}
        width="0.4"
        height="6"
      />
      <polygon
        fill={colors[3]}
        points="11.8999996,66.1999969 10.6999998,65.5 8.8999996,62.2999992 9,60.9000015"
      />
      <polygon
        fill={colors[3]}
        points="23.7999992,56.0999985 20.7999992,57.7999992 22.2000008,60.4000015"
      />
      <path
        fill={colors[3]}
        d="M19.7999992,60.0999985L20.3999996,59.5l0.5,1c0,0-0.7000008,0.0999985-0.3999996,0.9000015 C20.2000008,60.7000008,19.7999992,60.0999985,19.7999992,60.0999985z"
      />
    </g>
  )
}

export default WarhammerR
