import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LeftThumb from "./LeftThumb"
import RightTheme from "./RightTheme"

const CATEGORY = "thumbs"
const CHARACTER = "ManHalfling"
export const IDS = {
  LEFT_THUMB: "LeftThumb",
  RIGHT_THEME: "RightTheme"
}

export const components = {
  [IDS.LEFT_THUMB]: LeftThumb,
  [IDS.RIGHT_THEME]: RightTheme
}

export const Group = ({ props }) => (
  <g id="thumbs">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LEFT_THUMB,
    name: "Left Thumb",
    defaultColors: ["#E8B180"],
    colorable: false,
    component: components[IDS.LEFT_THUMB],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.RIGHT_THEME,
    name: "Right Theme",
    defaultColors: ["#E8B180"],
    colorable: false,
    component: components[IDS.RIGHT_THEME],
    inUI: false,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
