import React from "react"

const BobL = ({ colors = ["#4691FF", "#FFFFFF"] }) => {
  return (
    <g id="BobL">
      <path
        opacity="0.1"
        fill="#21376C"
        d="M68.0864029,49.7857208 c0.0895767,0.1338005,0.3583298,0.8027954,0.3583298,0.8027954s2.1051865-0.0446014,2.239563,0 c0.1343689,0.0446014,1.2093582,1.4717865,1.3885269,2.0961838c0.1791687,0.6243935,0.3583298,3.3003731,0.3135376,4.058567 s-0.4031219,2.4975777-0.9854126,3.1219749c-0.582283,0.6243935-2.2843475,1.6501846-2.2843475,1.6501846l-0.3135376,0.2229996 l1.4781113-0.0892029l1.6572723-1.1595879l2.1499786-3.4341698l-2.239563-7.5373421L68.0864029,49.7857208z"
      />
      <path
        fill={colors[0]}
        d="M68.6127014,47.0093956c0.6382751-0.1338005,1.4781113-0.8362427,3.3257446,0.5686455 c3.6616898,5.954052,3.9976196,10.3359642,3.9976196,10.068367c0-0.1458435-2.372879,3.3222733-3.1577759,4.1812134 c-0.2312317,0.2530365-1.3571167,0.9792976-1.7804565,0.8027954c0-0.5017433,0.0885315-1.1042175-0.0335922-1.2041893 c0.2687378-0.3010406,1.377327-1.605587,1.377327-1.605587s-1.2093658,1.3045387-2.8890305,1.7393875 c1.6124802-0.9031448,2.2507553-1.0703926,3.3929291-4.348465c-0.0671768-2.0738831-0.3320847-4.5084801-1.1085815-5.8537025 c-0.7149429-1.2386017-1.1950989-1.2689476-3.2921524-1.0369415 C68.0752029,48.0463371,67.9744186,47.1431961,68.6127014,47.0093956z"
      />
      <path
        opacity="0.39"
        fill="#21376C"
        d="M75.3313828,58.3488503 l-1.5788879,0.6689949c0,0,0.9406128-0.7358894,0.5710831-2.2076797c-0.1007767,0.9700432-1.444519,2.7763252-1.444519,2.7763252 l0.1679764-0.6020927l-1.3437424,1.6724854l-0.7390518,0.7693443l0.0335922,1.2041893l0.6382751-0.0668983l1.0413971-0.6020927 l2.6538773-3.4453239V58.3488503z"
      />
      <path
        opacity="0.39"
        fill="#21376C"
        d="M68.3859482,47.3104362 c0.0250778-0.0201454,0.0505066-0.0321693,0.0756836-0.0508461l-0.0000992-0.0076599l0.0102921,0.0008087 c0.8870926-0.6517639,1.9426346-0.4975166,2.6603775-0.0760956c0.731575,0.4295387,1.117218,1.13377,1.1505814,1.2376137 c-1.0876694-0.9786797-3.0905914-0.3512077-3.0905914-0.3512077s1.4529266-0.158886,1.8560486,0.1672554 c-1.5453186-0.2675896-1.8056412,0.8947945-1.8056412,0.8947945s0.4114914-0.5435677,1.4445114-0.0752563 c-0.8314667-0.3512573-2.0491943,0.9031219-2.0491943,0.9031219l-0.1679764-2.0153618l-0.0080032-0.6508102 C68.4367065,47.2965851,68.4111252,47.2997818,68.3859482,47.3104362z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M70.2419815,49.6965256 c0.520874,0.1239204,0.983139,0.3628922,1.3854599,0.689415c0.7678757,0.9062004,2.3066788,4.2170677,1.8170471,6.6237793 c-0.2523727,1.2405205-0.8041687,2.3600082-1.6684036,3.0896683c0.5509796-0.7743721,0.9912872-1.8130074,1.2359848-2.9048576 c-0.2626419-5.1279297-0.9833908-5.9530678-1.678299-6.8067131 C71.0383148,50.0248795,70.4885025,49.8137665,70.2419815,49.6965256z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M72.981575,51.1027374 c0.0003052-0.5318642-0.5392227-1.8187599-1.026329-2.638279c1.1085815,1.0034904,1.8140259,2.8264961,1.8140259,2.8264961 s0.9013519,1.9209633,1.3605576,3.629303c0.3071671,1.1427765,0.2250061,2.2172241,0.2519455,2.4585533 c-0.1861191-0.3705978-0.039856-0.5360756-1.1925659-2.1575089c0.1044235,1.0030403,0.0335922,1.5721359-0.3191376,2.3247566 c-0.0335846-1.7561035-0.033577-3.3616905-1.6628876-7.1080894C72.2071838,50.4379692,72.623909,50.6486702,72.981575,51.1027374z"
      />
    </g>
  )
}

export default BobL
