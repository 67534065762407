import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import builderReducer from './builderReducer'
import catalogReducer from './catalogReducer'
import printQueueReducer from './printQueueReducer'
import apiReducer from './apiReducer'
import flashReducer from './flashReducer'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  apiReducer,
  builderReducer,
  catalogReducer,
  printQueueReducer,
  flashReducer
})

export default createRootReducer