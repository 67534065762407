import React from "react"

const ShoulderArmorFancyL = ({
  colors = ["#754C28", "#282560", "#FFFFFF"]
}) => {
  return (
    <g id="ShoulderArmorFancyL">
      <path
        fill={colors[0]}
        d="M82.9467392,35.8435097c0.049324-0.1051407,0.3577194-0.8262634,0.2278061-0.9775581 c-0.5541382-0.6454201-1.1417542-1.2461433-1.7686386-1.8195152c0.2142639-0.502346,1.2357178-3.6446152,1.2357178-3.6446152 c0.1282501-0.3171501-0.4605484-0.3732414-0.6410141-0.3052235c-1.7630463,0.6644936-3.4876251,0.5537624-5.3107376,0.268795 c-1.0061417-0.1573124-2.2590027-0.6345673-3.2674026-0.5039577c-1.2060623,0.1562386-0.7856979,1.5131111-0.7661972,2.5078106 c-0.0496979,0.0296021-0.0914993,0.0654392-0.1100845,0.1154575c-0.3756638,1.010067-1.5433578,2.0723534-2.7330856,2.4680519 c-1.814949-2.0191097-2.8028412-3.5380268-5.1351242-4.9312191c-0.0126266-0.0075207-0.9954605-0.0418262-1.3693733,0.4249802 c-0.2311325,0.2885399,1.9832344,2.077673,3.2680931,4.1724873c1.4471359,1.49366,2.3483505,3.4094009,3.7057571,4.9979973 c0.1464615,0.1714401,0.5115891,0.1743431,0.6901245,0.0653839c1.624054-0.9906158,3.2481079-1.9812317,4.8722229-2.9718475 c0.104393-0.0102615,1.4786682,2.1363411,2.4512329,2.2654495c0.3533096,0.0469017,0.744545-0.00951,1.1344452-0.1124535 c0.014183,0.0028496,0.0268631,0.0077362,0.0415802,0.0097275c0.3240814,0.3924179,0.6308594,0.8262634,0.9895401,1.1642609 c0.2126007,0.2003479,0.4053192,0.271965,0.6981277,0.2474098c0.3765182-0.0315895,0.6831436-0.2692223,1.004158-0.454422 l1.7971649-1.0369797C84.2314072,37.8963089,83.4208832,36.3099136,82.9467392,35.8435097z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M79.4932022,37.7690125 c0.6728821-1.0624466,2.0717697-1.8592834,3.5591965-2.3019676c0.0472183,0.1416588-0.1056595,0.3764648-0.1056595,0.3764648 S81.9899521,35.9805603,79.4932022,37.7690125z"
      />
      <path
        opacity="0.19"
        fill="#FFF"
        d="M77.0495758,32.740097 c-1.7029419,0.7143517-3.7061081,0.2277565-5.2768173,0.4249802c-0.7437057,0.460392-1.874115,0.8526764-1.9478149,1.0624466 c0.1167603,0.1773033-1.5493088-1.6451721-2.1957245-2.4790421c-0.4958038-0.2479038-0.5312195,0.9207878-2.1957169-0.2833176 c0.0618896,0.4003983,4.7134094,6.5319366,4.8164215,6.6579971c1.3811798-0.3895645,11.1910934-5.8434448,11.1910934-5.8434448 c0.0318069-0.0065842,1.5453644-3.4748516,1.0270386-3.0102749C82.4680557,29.2694416,79.9484787,31.524065,77.0495758,32.740097z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M75.9517136,35.7680702 c-0.0628586,0.0326385,0.2124939,0.2833214,0.4249802,0.4780998c1.6290894-1.5936661,5.1882782-3.0102615,5.1882782-3.0102615 l-0.1590652-0.1894722C81.4059067,33.0464363,77.7932892,34.8118706,75.9517136,35.7680702z"
      />
      <path
        opacity="0.19"
        fill="#FFF"
        d="M77.3506012,37.0607147 c0,0,0.0531235,1.1155663,1.7530365,0.336441c1.6999207-0.779129,2.1780167-2.0009422,3.7716904-2.0363579 c0.2479019-0.460392,0.4958038-0.336441,0.1239471-0.5489273C82.6274185,34.5993805,80.0067215,36.688858,77.3506012,37.0607147z"
      />
      <path
        opacity="0.19"
        fill="#FFF"
        d="M79.9181824,38.1585732 c0,0,0.6374664,0.9916191,1.3457642,0.8853722c0.7082977-0.1062431,2.585289-1.4874229,2.585289-1.4874229l-0.2479019-0.4249802 c0,0-1.9124069,1.3103523-2.5498734,1.1686935C80.4139938,38.1585732,79.9181824,38.1585732,79.9181824,38.1585732z"
      />
      <path
        opacity="0.42"
        fill="#FFF"
        d="M73.5789185,29.1631966 c0,0-0.5666351,0.2479038-0.6020508,1.9478188c-0.0708313,0.9916153-0.885376,1.5936699-0.885376,1.5936699 c-0.0559006-0.0186348,2.4732742,0.1160011,4.7101822-0.5312233c2.0144882-0.5828724,4.6485062-2.3036098,5.1351547-2.9748516 c-2.3373795,1.133276-5.6920471,0.2333393-7.153801-0.0708294C73.8917084,28.9423122,73.5789185,29.1631966,73.5789185,29.1631966 z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M70.3679733,38.4418945l4.5567093-2.2193336 c0,0-3.8484116,2.4318237-4.2970047,2.4318237C70.1790924,38.6543846,70.3679733,38.4418945,70.3679733,38.4418945z"
      />
    </g>
  )
}

export default ShoulderArmorFancyL
