import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BlueFlameLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BlueFlameLeft") ? (
    <g id="BlueFlameLeft">
      <path
        id="S_x24_blueFlameLeft"
        d="M58.0420876,88.764061c-1.2426186-1.4703293-2.637001-3.425499-3.0443344-5.9110718 c-0.0193863-0.1183014-0.0168533-0.2369308,0.0063972-0.354538c0.1149902-0.581604,0.4671021-2.3849716,0.2375298-1.5125961 c0.411232-1.644928,2.3778496-5.7687531,2.8205376-6.8562851c0.0420341-0.1032639,0.0614967-0.208107,0.0632362-0.3195877 c0.0110207-0.7056885,0.0565186-3.5414734,0.1028366-5.3096008c0.0022926-0.087471,0.0161133-0.1671371,0.0423393-0.2506104 c0.1165657-0.3709793,0.4596672-1.4681702,0.5435753-1.7955093c0.0196037-0.0764771,0.0435791-0.1479111,0.0798416-0.2180405 c0.1701279-0.32901,0.5704041-1.0893326,1.1316071-2.2117386c0.1087265-0.1630898,0.1630898-0.7610855,0.1630898-0.9241753 c-0.0507202-0.8115273-0.1014404-1.9069824,0.2451935-3.1097641c0.0487289-0.1690903,0.1502151-0.3232956,0.2808075-0.4412498 l2.6256485-2.3715553c0.3203316-0.2893295,0.7964706-0.3235054,1.1548386-0.0828896l2.9673615,1.9923706 c0.1487198,0.0998573,0.2644119,0.2372437,0.3363266,0.4013062c0.1790924,0.4085693,0.4524841,1.0326195,0.7617798,1.8721504 c0.1630936,0.3805466,0.2718201,0.7610893,0.3805466,0.9785423c0.5979919,0.9785385,1.0328979,3.3705292,1.0328979,3.4792557 c0.1630936,0.4892731,0.2718201,0.9785385,0.3261795,1.4134445h0.0543671 c1.178566,1.553566,2.2515488,3.3182983,3.218956,5.3982239c0.0281219,0.060463,0.0507355,0.1251678,0.0663834,0.1899872 l0.330864,1.3707428c0.0173645,0.0719376,0.0271683,0.13871,0.027565,0.2127151 c0.0128555,2.4046936-1.1936493,11.3484268-1.8330002,13.5599899c-0.0664749,0.2299271-0.2240829,0.417778-0.4317932,0.536705 l-6.0152283,3.4441986c-0.2633209,0.1507797-0.5832062,0.1652603-0.8590851,0.0389099l-6.4892082-2.9721603 C58.2431755,88.9530792,58.1322289,88.8707275,58.0420876,88.764061z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BlueFlameLeft, {
  character: "ManGnome"
})
