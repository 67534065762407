import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const OpenCloak = ({ allEnabledElements }) => {
  return allEnabledElements.includes("OpenCloak") ? (
    <g id="OpenCloak">
      <path
        id="S_x24_openCloak"
        d="M85.3000031,119.5l-5-50.5999985c0-1.1999969,0.0999985-4.5-0.1999969-7.7999992 c-0.4000015-5.5999985-1.5999985-12.2000008-6.9000015-14.7000008c-1.1999969-0.5999985-2.4000015-1-3.5-1.2999992 c-0.5999985-1.9000015-1.5-4.0999985-2.8000031-6.5c-2.6999969-4.9000015-8.2999992-15-18.2999992-15.1000004 c-11.2999992,0-18,15.8000011-18,15.8999996c-0.3999996,1.0999985-1.5,3.7000008-2.5,6 C26.7000027,45.8000031,25.3000031,46.2000008,24.3000031,46.5c-4.3999996,1.7000008-5.6000004,5.2999992-6.2999992,7.4000015 c-0.1000004,0.2000008-0.2000008,0.5-0.2999992,0.7999992c-0.2999992,0.9000015-1.2000008,3.4000015-1.1000004,17.8999977 l-3.3999996,47L85.3000031,119.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(OpenCloak, {
  character: "ManHalfling"
})
