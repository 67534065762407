import React from "react"

const GoldAxeLeft = ({ colors = ["#897441", "#A39155", "#EDCD6E"] }) => {
  return (
    <g id="GoldAxeLeft">
      <path
        fill={colors[0]}
        d="M111.4000015,68.6999969c-1.6999969,0.9000015-4.6999969,0.5-7.5999985-1.0999985 s-4.8000031-4-4.9000015-5.9000015c-3.0999985,0.2000008-5.8000031,1.5999985-7.1999969,4.0999985 c-1.4000015,2.5-1.1999969,5.5,0.3000031,8.1999969c1.6999969-0.9000015,4.6999969-0.5,7.5999985,1.0999985 s4.8000031,4,4.9000015,5.9000015c3.0999985-0.1999969,5.8000031-1.5999985,7.1999969-4.0999985 C113.0999985,74.5,112.8000031,71.5,111.4000015,68.6999969z"
      />
      <path
        fill={colors[1]}
        d="M101.6999969,74.9000015l5.5-8c-0.0999985-0.3000031-0.0999985-0.5-0.1999969-0.8000031l-3.5999985-2 c-0.1999969,0.0999985-0.4000015,0.0999985-0.6999969,0.1999969l-3.9000015,9.0999985c0,0-12.3000031,24.4000015-15.5,30 c-2.5999985,4.5-6.5999985,10-8,12c0.0999985,0.3000031,0.3000031,0.6999969,0.5,1l3.4000015,0.9000015L98.6999969,80 L101.6999969,74.9000015z"
      />
      <path
        fill={colors[0]}
        d="M75.6999969,116.1999969c-0.0999985-0.0999985,0.3000031-0.3000031,0.1999969-0.5 c1.6999969-2.3000031,5.5999985-7.8000031,8.0999985-12c3.1999969-5.5,15.3000031-29.5999985,15.4000015-29.8000031 l3.8000031-8.8000031c0.0999985,0,0.5-0.6999969,0.5-0.6999969L106.5,66.0999908c0,0.0999985,0,0.6999969-0.1999969,0.5999985 L101,74.4999924l-3,5.0999985L78.5999985,117L75.6999969,116.1999969z"
      />
      <path
        fill={colors[2]}
        d="M76,116.1999969c1.6999969-2.3000031,5.8000031-8.1999969,8.1999969-12.4000015 c3-5.1999969,14.1999969-27.4000015,15.5-30l4.5-9.3000031l1.8000031,1l-5.3000031,8.6999969l-3.1999969,5l-19.1999969,37.7000046 L76,116.1999969z"
      />
      <g fill={colors[2]}>
        <path d="M93,66.9000015c0.5999985-1.1999969,1.6999969-2,2.9000015-2.5 C96,64.3000031,95.8000031,63.7000008,95.8000031,63.2000008C96.2000046,64,96.5,64.0999985,96.6000061,64.0999985 c0.9000015-0.2999992,1.9000015-0.5,2.9000015-0.5C99.2000046,63,98.9000092,62.2999992,98.9000092,61.7999992 C95.8000107,62,93.1000061,63.3999977,91.7000122,65.9000015c-1.4000015,2.5-1.1999969,5.5,0.3000031,8.1999969 c0.4000015-0.1999969,0.8000031-0.3000031,1.3000031-0.4000015C92.0999985,71.4000015,91.9000015,69,93,66.9000015z" />
        <path d="M111.4000015,68.6999969c-0.5,0.3000031-1.0999985,0.4000015-1.8000031,0.4000015 c1.4000015,2.4000015,1.6999969,5,0.5,7.1999969c-0.3000031,0.5999985-0.9000015,1.1999969-1.4000015,1.6999969 c0,0,0.0999985,0.3000031,0,0.3000031c-0.0999985-0.0999985-0.0999985-0.0999985-0.0999985-0.1999969 c-0.0999985,0.0999985-0.4000015,0.3000031-0.5,0.4000015s-0.0999985,0.3000031,0,1.0999985 C108,79.2999954,107.5,78.7999954,107.4000015,78.7999954c-1,0.5-2.0999985,0.8000031-3.3000031,0.9000015 c0.1999969,0.5,0.3000031,0.9000015,0.3000031,1.3000031c3.0999985-0.1999969,5.8000031-1.5999985,7.1999969-4.0999985 C113.0999985,74.5,112.9000015,71.4000015,111.4000015,68.6999969z" />
      </g>
    </g>
  )
}

export default GoldAxeLeft
