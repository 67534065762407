import React from "react"

const ThighWrapR = ({ colors = ["#493812"] }) => {
  return (
    <g id="ThighWrapR">
      <path
        fill={colors[0]}
        d="M66.4,72c-0.7,0.1-1.5,0.2-2.3,0.3c0-0.3-7.3-1-10.2-2c0,0-0.4,2,0,2.1c0,0,0.1,0,0.1,0 c-0.2,0-0.3-0.1-0.5-0.1c0,0-0.1,0.2-0.1,0.6c-0.1,0-0.1-0.1-0.2-0.1c0,0.7,0,1.4,0,2.1c1.7,0.8,4,1.7,6.3,2.2 c-2-0.1-3.9-0.6-5.7-1.3c0,0-0.3,1.3-0.1,1.9c-0.1-0.1-0.2-0.1-0.4-0.2c0,0.3,0,0.7-0.1,1c0,0.3,0,0.7,0,1 c0.3,0.1,0.1,0.1,0.4,0.2c-0.1,0.5-0.4,1.7-0.1,2c0.9,0.9,2.4,1.6,3.4,2.1c-1.4-0.4-2.8-0.8-4.1-1.4c0,0.5,0,1,0,1.5 c0,0.2,0,0.4,0,0.6c1.9,0.9,4,1.4,6.1,1.9c1,0.2,2.3,0.6,3.4,0.5c0.2-0.8,0.3-1.5,0.5-2.2c-0.6,0.3-1.5,0.2-2.3,0.1 c0.8,0,2.5-0.8,2.6-1.1c0-0.6,0.3-1.6,0.3-1.6c-3.1,0.7-6.7,0.2-9-1.9c1.6,0.7,3.3,1.2,5,1.5c1.1,0.2,3.5,0.9,4.3-0.2 c0.1-0.3,0.1-0.5,0.2-0.8c0-0.6-0.2-1.2-0.1-1.4c-0.7,1.4-4,0.4-5.2,0.1c-0.8-0.2-1.5-0.4-2.3-0.6c2.5,0.6,5.4,0.9,7.8-0.1 c0.2-0.1,0.3-0.6,0.3-1c0.3,0,0.2-0.1,0.4-0.2c0.2-0.7,0.4-1.5,0.7-2.2c-2,0.4-4.3,0.1-6.6-0.5c2.4-0.1,4.8-0.5,7.1-0.8 c0.1-0.2,0.2-0.5,0.3-0.7C66.6,72.8,66.5,72,66.4,72z"
      />
    </g>
  )
}

export default ThighWrapR
