import React from "react"
import { withSkinColor } from "../../builderConnector"

const Arms = ({ skinColor, color = [] }) => {
  return (
    <g id="Arms">
      <g id="leftArm_2_">
        <path
          id="leftArm_1_"
          fill={skinColor}
          d="M26.5,62.4c-1.2,3.6-0.2,9.4-0.6,9.3c-2.3-0.4-3.4,9.8-3.4,9.8s-0.2,1.7,1.5,2.7 c1.7,1,3.8-0.9,3.8-0.9s4.1-6.2,4.5-7.9c0.4-1.7-0.2-3.9-0.2-3.9l3.3-15.6c0,0-2.2-1.7-4.6-1.2C28.3,55.1,25.5,59.1,26.5,62.4z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M27.3,72c0,0,2.8-0.6,3.2-0.2S28.1,69,27.3,72z"
        />
      </g>
      <g id="rightArm_1_">
        <path
          fill={skinColor}
          d="M70.4,62.4c1.6,3,0.3,9.2,0.7,9.3c2,0.8,3.4,9.8,3.4,9.8s0.2,1.7-1.5,2.7s-3.8-0.9-3.8-0.9 s-4.1-6.2-4.5-7.9c-0.4-1.7,0.2-3.9,0.2-3.9l-3.4-15.6c0,0,2.2-1.7,4.6-1.2C68.6,55.1,71.3,59,70.4,62.4z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M69.6,72c0,0-2.8-0.6-3.2-0.2S68.7,69,69.6,72z"
        />
      </g>
    </g>
  )
}

export default withSkinColor(Arms, { character: "undefined" })
