import React from "react"

const Eyes = ({ colors = ["#262261"] }) => {
  return (
    <g id="Eyes" fill={colors[0]}>
      <path d="M70.2842026,18.8851357c0.5207596-0.1012383,0.6682968,0.0723858,1.0042953,0.1087799 c0.4479904,0.0485229,0.8504944-0.2753086,0.8990173-0.723299c0.0186691-0.172348,0.4326401-0.3153706,0.3334198-0.7845631 c-0.6122055,0.342123-0.7810745-0.0846024-1.0567169-0.1144581C70.4599228,17.2628174,70.3117905,18.630455,70.2842026,18.8851357 z" />
      <path d="M66.7231369,18.8851357c-0.520752-0.1012383-0.6682968,0.0723858-1.0042953,0.1087799 c-0.4479904,0.0485229-0.8504944-0.2753086-0.8990173-0.723299c-0.0186691-0.172348-0.4326401-0.3153706-0.3334198-0.7845631 c0.6122055,0.342123,0.7810745-0.0846024,1.0567169-0.1144581C66.5474167,17.2628174,66.695549,18.630455,66.7231369,18.8851357z" />
    </g>
  )
}

export default Eyes
