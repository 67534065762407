import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WarhammerL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WarhammerL") ? (
    <g id="WarhammerL">
      <path
        id="S_x24_warhammerL"
        d="M74.7559204,106.3436279c-2.2932358-1.2673187-3.0296936-3.2001877-1.367897-7.8050613 c2.7696609-7.6747818,10.5408478-23.3346939,10.5408478-23.3346939c-3.7206497-1.9121017-3.7818298-7.8855209-3.7818298-7.8855209 c1.9120941-3.7206497,5.8322144-5.6315079,9.5604324-3.6499977l4.681778,1.9602394 c3.7206497,1.9120941,4.1844101,6.5551605,2.2028961,10.2833862c0,0-2.3869858,2.9048233-5.4214706,2.5909348 c-0.570015-0.05896-6.8183365,16.2508621-10.1385269,23.6565552 C78.8939285,106.9287872,77.1514664,107.6674805,74.7559204,106.3436279z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WarhammerL, {
  character: "WomanGnome"
})
