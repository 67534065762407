import React from "react"

const BasicPants = ({ colors = ["#646464"] }) => {
  return (
    <g id="BasicPants">
      <path
        id="basicPants"
        fill={colors[0]}
        d="M33.9,102.8c0.1,2.3,1.1,7,1.5,10h7.1c2-13.9,2.2-12.7,3.7-23.1c2,0.1,2.6,0,4.4,0 c1.5,12.5,2.2,8,3.6,23.1h7.3c0.4-3,1.3-7.7,1.4-10.1c0.1-2.6-1.4-3.5-1.7-5.6c1.2-3.6,2.2-7.1,2.3-8.9s-1.2-7-1.6-10.3 c0,0-4.3,1.1-12.9,1.1s-13.9-1-13.9-1c-0.5,3.3-1.7,8.9-1.7,10.5s1.1,5.2,2.2,8.8C35.1,99.5,33.8,100.2,33.9,102.8z"
      />
    </g>
  )
}

export default BasicPants
