import React from "react"

const EyesRound = ({ colors = ["#010101"] }) => {
  return (
    <g id="EyesRound" fill={colors[0]}>
      <circle cx="45.6" cy="43.5" r="0.5" />
      <circle cx="51.2" cy="43.6" r="0.5" />
    </g>
  )
}

export default EyesRound
