import React from "react"

const PlateHelmGold = ({
  colors = ["#A59A76", "#8A784E", "#E6BB21", "#EED793"]
}) => {
  return (
    <g id="PlateHelmGold">
      <path
        opacity="0.42"
        fill="#21376C"
        d="M57.6397209,44.2999992c0,0,0-0.0999985-0.1049957-0.2000008 v-0.2000008h-0.1049957c-0.5249863-1.4000015-2.519928-6.0999985-7.1397858-7.2999992l0,0l-1.9949417-1.9000015 l-1.8899422,1.9000015c-4.7248573,1.0999985-6.719799,5.9000015-7.139782,7.2999992h-0.1049957v0.2000008 c0,0.0999985-0.1049957,0.2000008-0.1049957,0.2000008h0.1049957v0.0999985v2l7.6647682,0.5l0.2099915,4.0999985h2.6249237 l0.2099915-4.0999985l7.6647682-0.5L57.6397209,44.2999992L57.6397209,44.2999992L57.6397209,44.2999992z"
      />
      <path
        fill={colors[0]}
        d="M39.1602783,43.4000015h18.5844383c0,0-2.2049294-7.9000015-9.3447151-7.9000015 S39.1602783,43.4000015,39.1602783,43.4000015z"
      />
      <rect x="39.16" y="43" fill={colors[1]} width="18.374" height="3" />
      <rect x="39.16" y="43.5" fill={colors[2]} width="18.374" height="2" />
      <polygon
        fill={colors[0]}
        points="46.4050598,35.7000008 47.035038,50.7000008 49.7649612,50.7000008 50.3949394,35.7000008 48.4000015,33.7999992"
      />
      <polygon
        fill={colors[1]}
        points="48.295002,33.9000015 46.7200508,35.5 47.3500328,50.2999992 48.295002,50.2999992"
      />
      <polygon
        fill={colors[3]}
        points="48.4000015,33.9000015 50.0799484,35.5 49.3449707,50.2999992 48.4000015,50.2999992"
      />
    </g>
  )
}

export default PlateHelmGold
