import React from "react"

const NobelCapeShoulders = ({
  colors = ["#8D4D3A", "#D8A16C", "#SVGID_", "#FFFFFF", "#AC7B56", "#F9BE7C"]
}) => {
  return (
    <g id="NobelCapeShoulders">
      <path
        fill={colors[0]}
        d="M37.7210045,61.0232048c0,0,0,4.4430885,3.2738533,0.2338448s0.5456429-6.9374504,0.5456429-6.9374504 s-4.4430847-2.6502647-4.1312904-1.2471809c0.3897476,1.9487228-1.0133324-0.155899-1.0133324-0.155899 s-2.1046219-2.2605171-2.5723114-0.7794876c-0.155899,0.3117943-0.0779495,0.7794876,0.1558952,1.2471809 c0.3897438,0.7015419-2.1825676-0.3897438-2.1825676-0.3897438s-2.4164143,0.6235886-3.0400047,1.8707733 c-0.5456429,1.0133362,0.6235905,2.5723114,1.1692333,3.1179543 C32.030735,60.1657677,37.7210045,61.0232048,37.7210045,61.0232048z"
      />
      <path
        opacity="0.35"
        fill="#21376C"
        d="M39.6697273,61.0232048 c0,0,1.558979-1.7148781,1.1692352-3.1179543c-0.5456429-1.9487228-2.0266724-2.4164162-3.2738571-3.7415466 c-0.3117943-0.3117981-0.4676895-0.3897438-0.4676895-0.3897438c0.6235886,0.155899,0.7015419,0,0.6235886-0.6235924 c0.7794914,0.9353867,3.5077019,1.4810295,4.0533447,4.4430885 C41.7743492,58.3729439,41.8522949,60.3996124,39.6697273,61.0232048z"
      />
      <path
        opacity="0.35"
        fill="#21376C"
        d="M31.7968903,52.9944687 c0,0,5.6123199,2.8841095,6.9374504,3.8194962c1.2471809,0.9353905,2.4943657,1.4810333,0.9353867,4.1312904 c1.2471809-1.7928238,2.1825676-3.2738533-5.4564209-7.8728371 C33.9794579,53.3842163,31.7968903,52.9944687,31.7968903,52.9944687z"
      />
      <circle fill={colors[1]} cx="39.202" cy="61.491" r="1.169" />
      <circle fill="url(#SVGID_8_)" cx="39.202" cy="61.257" r="1.169" />
      <radialGradient
        id="SVGID_8_"
        cx="39.195"
        cy="699.296"
        r="1.192"
        gradientTransform="translate(0 -638.013)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.662" stopColor="#ac7b56" />
        <stop offset="1" stopColor="#f9be7c" />
      </radialGradient>
      <g>
        <path
          fill={colors[0]}
          d="M59.0789948,60.7893562c0,0,0,4.4430885-3.2738533,0.2338486s-0.545639-6.8595009-0.545639-6.8595009 s4.4430885-2.6502647,4.1312904-1.2471809c-0.3897438,1.9487228,1.0133362-0.155899,1.0133362-0.155899 s2.1046181-2.2605171,2.5723114-0.7794876c0.1558952,0.3117943,0.0779457,0.7794876-0.1558952,1.2471809 c-0.3897438,0.7015419,2.1825676-0.3897438,2.1825676-0.3897438s2.41642,0.6235886,3.0400085,1.8707733 c0.545639,1.0133324-0.6235886,2.5723114-1.1692276,3.1179543 C64.7692642,59.9319229,59.0789948,60.7893562,59.0789948,60.7893562z"
        />
        <path
          opacity="0.35"
          fill="#21376C"
          d="M57.1302757,60.7893562 c0,0-1.558979-1.7148743-1.1692352-3.1179543c0.5456429-1.9487228,2.0266685-2.4164124,3.2738533-3.7415428 c0.3117981-0.3117981,0.4676933-0.3897476,0.4676933-0.3897476c-0.6235924,0.155899-0.7015419,0-0.6235924-0.6235886 c-0.7794876,0.9353867-3.5076981,1.4810295-4.0533409,4.4430847 C55.0256577,58.1390953,54.9477081,60.1657677,57.1302757,60.7893562z"
        />
        <path
          opacity="0.35"
          fill="#21376C"
          d="M65.0031128,52.7606239 c0,0-5.6123238,2.8841095-6.9374542,3.8194962c-1.2471809,0.9353867-2.4943657,1.4810295-0.9353867,4.1312904 c-1.2471809-1.7928238-2.1825676-3.2738533,5.4564171-7.8728371 C62.8205452,53.1503677,65.0031128,52.7606239,65.0031128,52.7606239z"
        />
        <circle fill={colors[1]} cx="57.598" cy="61.257" r="1.169" />
        <circle fill="url(#SVGID_9_)" cx="57.598" cy="61.023" r="1.169" />
        <radialGradient
          id="SVGID_9_"
          cx="-1045.25"
          cy="699.052"
          r="1.192"
          gradientTransform="matrix(-1 0 0 1 -987.681 -638.013)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.662" stopColor="#ac7b56" />
          <stop offset="1" stopColor="#f9be7c" />
        </radialGradient>
      </g>
    </g>
  )
}

export default NobelCapeShoulders
