import React from "react"

const OpenCloak = ({ colors = ["#4A6621"] }) => {
  return (
    <g id="OpenCloak">
      <path
        id="C_x24_openCloak_1_"
        fill={colors[0]}
        d="M64.2355347,120.7006989l-0.3218994-39.1593628L56.2158813,65.381424 c0,0-0.8448486-16.3487549-8.7706299-16.3487549c-7.9257202,0-9.0522461,16.5096436-9.0522461,16.5096436l-7.7714844,15.762085 l-0.4492989,39.3695068L64.2355347,120.7006989z"
      />
      <path
        id="C_x24_openCloak_2_"
        opacity="0.5"
        d="M64.2355347,120.7006989l-0.3218994-39.1593628L56.2158813,65.381424 c0,0-0.8448486-16.3487549-8.7706299-16.3487549c-7.9257202,0-9.0522461,16.5096436-9.0522461,16.5096436l-7.7714844,15.762085 l-0.4492989,39.3695068L64.2355347,120.7006989z"
      />
    </g>
  )
}

export default OpenCloak
