import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LeatherR from "./LeatherR"
import LeatherL from "./LeatherL"
import PlateBasiceGauntlets from "./PlateBasiceGauntlets"
import PlateGoldGauntlets from "./PlateGoldGauntlets"

const CATEGORY = "braces"
const CHARACTER = "ManGnome"
export const IDS = {
  LEATHER_R: "LeatherR",
  LEATHER_L: "LeatherL",
  PLATE_BASICE_GAUNTLETS: "PlateBasiceGauntlets",
  PLATE_GOLD_GAUNTLETS: "PlateGoldGauntlets"
}

export const components = {
  [IDS.LEATHER_R]: LeatherR,
  [IDS.LEATHER_L]: LeatherL,
  [IDS.PLATE_BASICE_GAUNTLETS]: PlateBasiceGauntlets,
  [IDS.PLATE_GOLD_GAUNTLETS]: PlateGoldGauntlets
}

export const Group = ({ props }) => (
  <g id="braces">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LEATHER_R,
    name: "Leather R",
    defaultColors: ["#8B5E3C", "#353891"],
    colorable: true,
    component: components[IDS.LEATHER_R],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.LEATHER_L,
    name: "Leather L",
    defaultColors: ["#8B5E3C", "#353891"],
    colorable: true,
    component: components[IDS.LEATHER_L],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.PLATE_BASICE_GAUNTLETS,
    name: "Plate Basice Gauntlets",
    defaultColors: ["#9E9E9E", "#808080", "#B8B8B8"],
    colorable: false,
    component: components[IDS.PLATE_BASICE_GAUNTLETS],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.PLATE_GOLD_GAUNTLETS,
    name: "Plate Gold Gauntlets",
    defaultColors: ["#A59A76", "#8A784E", "#EED793"],
    colorable: false,
    component: components[IDS.PLATE_GOLD_GAUNTLETS],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
