import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaceL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaceL") ? (
    <g id="MaceL">
      <path
        id="S_x24_maceL"
        d="M90.5,63.2l-3,5.1c-3,5.2-9.8,7-15,4L70.3,71c-5.2-3-7-9.8-4-15l3-5.1c3-5.2,9.8-7,15-4l2.2,1.3 C91.7,51.3,93.5,58,90.5,63.2z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaceL, { character: "ManDwarf" })
