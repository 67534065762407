import React from "react"

const HalfTank = ({ colors = ["#CBC8A9", "#FFFFFF", "#262261"] }) => {
  return (
    <g id="HalfTank">
      <g id="boobs_1_">
        <path
          id="halfTank"
          fill={colors[0]}
          d="M73.0557556,67.2152786c-0.1666183,0.1257477-0.3474884,0.2044907-0.3474884,0.1284561 v-0.1942749c-0.119278,0.1517181-0.270401,0.3047562-0.3750534,0.3047562c-0.0291595,0,0.1804276-0.3336029,0.3750534-0.503952 v-0.1947556c0-0.1187134,0.144928-0.2684402,0.2945023-0.3765717c0.0085144-2.1092148,0.8193359-2.5351982,0.8193359-2.5351982 s-1.8098373-0.7967377-2.614212-1.0045776c0.0192642,0.0533142,0.035347,0.1105423,0.0399475,0.178463 c0.1043854,1.5351105-0.9952087,1.1146927-1.8997955,1.3486328c-0.1967316,0.0508499-0.3412933-0.0885391-0.4139175-0.273674 c-2.5130844,2.1235123-5.891571,5.7614441-6.6200294,5.1066284c0.0369034,0.4450836,0.0167656,2.1088333,0.7543564,2.6452637 c0.3419724,1.1466217,0.5330772,3.2889862,0.1508713,5.6928635c-0.5203667,0.5257645-0.8951683,0.8649979-2.1826019,3.4298019 l7.5346107,2.3100052l6.9489975-2.2597198c-0.3254929-0.938652-1.0575867-2.258728-2.1524277-3.5404358 c-0.7577057-2.5681534-0.3017426-5.0893784,0.34198-5.920845c0.4812012-0.9123077,0.5568085-2.2060471,0.5833664-2.3066483 C73.6616058,68.8466492,73.1722946,67.9998169,73.0557556,67.2152786z M72.6088562,65.7295151 c0.0391388,0-0.3518143,0.6017532-0.5593414,0.6017532C72.0103836,66.3312683,72.401329,65.7295151,72.6088562,65.7295151z"
        />
        <path
          opacity="0.15"
          fill="#FFF"
          d="M63.5612984,69.0684509 c0.0528259-0.1250839,2.6553307-1.4584198,4.1271629,0.7167511 C69.0581284,71.5290604,65.0674057,69.5530472,63.5612984,69.0684509z"
        />
        <path
          opacity="0.1"
          fill={colors[2]}
          d="M74.0920868,69.551239 c-0.0098267,0.0799561-1.032486,2.0426712-2.5183258,2.4893875c-1.1826782,0.3555298-2.7822723-0.2464371-3.1343231-1.4533997 c-0.2111969,1.0762024-1.8749542,1.9145203-3.0576324,1.5589905c-1.4857788-0.4467163-2.7410736-2.3188782-2.7509003-2.3988342 c-0.0708618,0.5629883-0.4274445,3.5856857,5.7683182,2.3988342 C73.7501144,73.6549301,74.1629486,70.1142273,74.0920868,69.551239z"
        />
        <path
          opacity="0.15"
          fill="#FFF"
          d="M68.9603119,69.7751465 c1.4718399-2.1751709,4.0743408-0.841835,4.1271667-0.7167511 C71.5813751,69.5429916,67.5906448,71.5190048,68.9603119,69.7751465z"
        />
      </g>
    </g>
  )
}

export default HalfTank
