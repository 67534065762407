import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Boots from "./Boots"
import BootsKneeHigh from "./BootsKneeHigh"
import FurTopper from "./FurTopper"

const CATEGORY = "footwear"
const CHARACTER = "WomanGnome"
export const IDS = {
  BOOTS: "Boots",
  BOOTS_KNEE_HIGH: "BootsKneeHigh",
  FUR_TOPPER: "FurTopper"
}

export const components = {
  [IDS.BOOTS]: Boots,
  [IDS.BOOTS_KNEE_HIGH]: BootsKneeHigh,
  [IDS.FUR_TOPPER]: FurTopper
}

export const Group = ({ props }) => (
  <g id="footwear">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.BOOTS,
    name: "Boots",
    defaultColors: ["#7C5A41", "#E5C3AC", "#3C2A1F", "#603813"],
    colorable: true,
    component: components[IDS.BOOTS],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.BOOTS_KNEE_HIGH,
    name: "Boots Knee High",
    defaultColors: ["#353891", "#936E54", "#FFFFFF"],
    colorable: true,
    component: components[IDS.BOOTS_KNEE_HIGH],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FUR_TOPPER,
    name: "Fur Topper",
    defaultColors: ["#BFA181"],
    colorable: true,
    component: components[IDS.FUR_TOPPER],
    inUI: true,
    enabled: false,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
