import React from "react"

const Lips = ({ colors = ["#9B3C00"] }) => {
  return (
    <g id="Lips">
      <path
        fill={colors[0]}
        d="M69,51.7999992c-0.1999969,0-0.5,0.2999992-0.5,0.2999992s-0.1999969-0.2999992-0.4000015-0.2999992 c-0.1999969,0-1.9000015,0.7999992-1.9000015,0.7999992h0.0999985c0,0,0.0999985,1,2.1999969,1 c2.1999969,0,2.1999969-1,2.1999969-1h0.0999985C70.8000031,52.5999985,69.1999969,51.7999992,69,51.7999992z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M66.3000031,52.5999985h4.5999985 c0,0-1.5999985-0.7000008-1.8000031-0.7000008c-0.1999969,0-0.5,0.2999992-0.5,0.2999992 s-0.1999969-0.2999992-0.4000015-0.2999992C68,51.9000015,66.3000031,52.5999985,66.3000031,52.5999985z"
      />
    </g>
  )
}

export default Lips
