import React from "react"

const BattleAxeLeft = ({ colors = ["#7A7C7D", "#505052"] }) => {
  return (
    <g id="BattleAxeLeft">
      <path
        fill={colors[0]}
        d="M91.2903595,63.4751358c-0.1349564,0.3035393-0.454834,0.6000748-0.7677155,0.7116852 c-1.3178177,0.5673904-2.3773956,0.8359146-3.7951508,0.7822189c-0.191925,0.1779251-0.6327515,0.4081497-0.8886566,0.6453781 c0.0046616-0.123291,0.1503448-2.3400459,0.155014-2.4633331c0,0,0.4954681-0.0429649,0.4978027-0.1046066 c0,0-0.9082794-0.466507-0.926506-3.2450447c-0.4132614,2.7621956-1.2925873,3.1610031-1.2925873,3.1610031 c-0.0046692,0.1232834,0.4884644,0.1419601,0.4884644,0.1419601c-0.0046692,0.1232834-0.155014,2.4633293-0.155014,2.4633293 s-0.5407715-0.3908615-0.8373032-0.7107391c-1.4154282-0.1153336-2.4516602-0.4632263-3.6588135-1.1879768 c-0.3035355-0.1349564-0.6000671-0.4548378-0.6500397-0.7653809c0,0-5.8418884,7.556736,0.7262497,13.4229088 c-0.7392578-3.2996826-0.7514267-7.8681641,3.2669449-8.0246277c0.0616455,0.0023346,0.9886017-0.024292,0.9886017-0.024292 l-0.2306671,2.8308411c-0.0663147,0.1209488-0.2535706,0.1755905-0.4478302,0.4151535l0.5547714,0.0210114l-0.0326843,0.8629761 l-0.6164169-0.0233383c-0.0023346,0.0616379-0.0443497,1.1711884-0.0466843,1.2328262 c0.0616379,0.0023346,0.6164093,0.0233459,0.6780548,0.0256805c-0.6424866,12.0747375-1.6837311,31.4185104-2.32621,43.4932556 l-0.0616455-0.0023346c-0.0350113,0.9246216,1.2664566,0.7887192,1.3561172,0.0513535l-0.0616455-0.0023346 c0.6424866-12.0747375,1.6837311-31.4185104,2.32621-43.493248c0.0616455,0.0023346,0.6164169,0.0233459,0.6780624,0.0256805 c0.0023346-0.0616455,0.0443497-1.171196,0.0466843-1.2328339l-0.6164093-0.0233459l0.0326767-0.8629837l0.5547791,0.0210114 c-0.0569763-0.125618-0.2965393-0.3198776-0.4151459-0.4478302l0.1050491-2.7738724c0,0,0.8606491,0.0943222,0.9839325,0.0989914 c3.9903564,0.5832367,3.5093918,5.1330414,2.401741,8.3010483C96.292717,71.5660248,91.2903595,63.4751358,91.2903595,63.4751358 z"
      />
      <path
        fill={colors[1]}
        d="M83.8326721,64.7976761c-1.353775-0.112999-2.3307037-0.3969193-3.4785461-1.0576897 c-1.3761826,2.1084328-3.7797012,6.8940697-0.4062042,10.7256279c-0.3217697-2.9134903,0.2311172-6.1025085,3.5690918-6.2230148 c0.0616455,0.0023346,0.7397003,0.0280151,0.9246216,0.0350189l0.2260056-2.7075577 C84.4280777,65.3757935,84.0105972,64.9896088,83.8326721,64.7976761z"
      />
      <path
        fill={colors[1]}
        d="M86.7891388,64.9713745c1.3561096,0.0513611,2.3517151-0.1578522,3.5439148-0.6682739 c1.151123,2.2041473,2.9985352,7.2125092-0.7144165,10.7139587c0.6644974-2.8761444,0.4160461-6.0955124-2.8985901-6.591423 c-0.0616455-0.0023346-0.8013458-0.0303497-0.9862671-0.0373535l0.1050568-2.7738647 C86.0284271,65.4981308,86.5355682,65.146965,86.7891388,64.9713745z"
      />
      <rect
        x="84.545"
        y="72.163"
        transform="rotate(-86.964 84.792 73.427)"
        fill={colors[1]}
        width="0.493"
        height="2.529"
      />
      <rect
        x="84.799"
        y="70.377"
        transform="rotate(-86.964 84.892 71.61)"
        fill={colors[1]}
        width="0.185"
        height="2.467"
      />
      <path
        fill={colors[1]}
        d="M84.7633591,63.0427551c0,0-0.3082123-0.011673-0.4884644-0.1419601 c0.3128738-0.1116104,1.3607788-0.0719223,2.2190933,0.0840454c-0.0639801,0.0593071-0.4978027,0.1046066-0.4978027,0.1046066 S85.4484177,62.8835106,84.7633591,63.0427551z"
      />
    </g>
  )
}

export default BattleAxeLeft
