import React from "react"

const RamHorns = ({ colors = ["#898888", "#262261"] }) => {
  return (
    <g id="RamHorns">
      <path
        fill={colors[0]}
        d="M56.3,13.2c0.1,0.9,1.3,1.4,1.3,1.4s1.3,0.2,2.3-0.7s2.2-4.8,2.2-4.8l1.7,3.7l1.3,1l2-0.5l0.5-2.1 l-3.4-5.9c0,0-0.6-1.5-2.1-1.4c-1.5,0.1-2.3,1.7-2.3,1.7l-2,6.9l-1-1.8C56.8,10.7,56.2,12.3,56.3,13.2z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M59.2,14.2c0.1,0,0.3,0,0.4-0.1c0.3-0.2,0.6-0.5,0.9-1 c0.6-1,1.1-2.6,1.6-4l-1.9-3.3c-0.2-0.1-0.3-0.1-0.5-0.1l-2,6.8l1,1.5C58.9,14.1,59,14.2,59.2,14.2z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M82.1,13.2c-0.1,0.9-1.3,1.4-1.3,1.4s-1.3,0.2-2.3-0.7s-2.2-4.8-2.2-4.8l-1.7,3.7l-1.3,1l-2-0.5l-0.5-2.1 l3.4-5.9c0,0,0.6-1.5,2.1-1.4c1.5,0.1,2.3,1.7,2.3,1.7l2,6.9l1-1.8C81.6,10.7,82.2,12.3,82.1,13.2z"
        />
        <path
          opacity="0.3"
          fill={colors[1]}
          d="M79.2,14.2c-0.1,0-0.3,0-0.4-0.1c-0.3-0.2-0.6-0.5-0.9-1 c-0.6-1-1.1-2.6-1.6-4l1.9-3.3c0.2-0.1,0.3-0.1,0.5-0.1l2,6.8l-1,1.5C79.4,14.1,79.3,14.2,79.2,14.2z"
        />
      </g>
    </g>
  )
}

export default RamHorns
