import { call, put, takeEvery } from 'redux-saga/effects'
import { apiRoutine } from '../routines'
import api from '../api'
import get from 'lodash/get'

export function* apiWatcherSaga() {
  yield takeEvery(apiRoutine.TRIGGER, apiWorker)
}

function* apiWorker(action) {
  try {
    // trigger request action
    yield put(apiRoutine.request())
    // perform request to '/some_url' to fetch some data
    const response = yield call(get(api, action.payload.method), action.payload.data)
    // if request successfully finished
    yield put(apiRoutine.success({
      key: action.payload.key,
      data: response.data
    }))
  } catch (error) {
    // if request failed
    yield put(apiRoutine.failure(error.message))
  } finally {
    // trigger fulfill action
    yield put(apiRoutine.fulfill())
  }
}
