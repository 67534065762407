import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Head from "./Head"
import OrcHead from "./OrcHead"

const CATEGORY = "head"
const CHARACTER = "ManDragonborn"
export const IDS = {
  HEAD: "Head",
  ORC_HEAD: "OrcHead"
}

export const components = {
  [IDS.HEAD]: Head,
  [IDS.ORC_HEAD]: OrcHead
}

export const Group = ({ props }) => (
  <g id="head">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.HEAD,
    name: "Head",
    defaultColors: ["#21366C", "#DEA043"],
    colorable: false,
    component: components[IDS.HEAD],
    inUI: true,
    enabled: true,
    subGroupId: 0
  },
  {
    id: IDS.ORC_HEAD,
    name: "Orc Head",
    defaultColors: ["#21366C", "#DEA043"],
    colorable: false,
    component: components[IDS.ORC_HEAD],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
