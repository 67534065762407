import React from "react"

const BlueFlameLeft = ({
  colors = ["#80D0DC", "#30BFCA", "#5BC6D1", "#A8DDE6", "#C0E6F0", "#E5F5F8"]
}) => {
  return (
    <g id="BlueFlameLeft">
      <path
        opacity="0.77"
        fill={colors[0]}
        d="M79.2,57.6c-1.4-3.1-3.1-6.1-5.2-8.9c-0.1,0-0.1,0-0.2,0.1 c-0.2,0.1-1.6,0.8-1.5,0c0.2-1.5,0.6-3.4,0-4.8c-0.4-1.1-0.6-4.3-1.4-5.2c-0.1-0.2-2-4.9-2.5-6.1c-0.8,2.6,0.5,5.8-0.9,8.1 c-0.1,0.1-2.7,5.6-2.9,5.8c-0.1,3.7-0.4,7.5-1.2,11.1c0.4-0.2,1.1-0.5,1-0.2c-1.1,4.9-4.4,8.9-5.5,13.8c0,0.2-0.2,0.4-0.4,0.5 c0.4,2.9,2.2,5.5,4.2,7.8c-1.1-2.6-1.9-5.2-1.9-8c0-0.4,0.6-0.8,1.3-1.2c0.2-3.2,0.5-6.1,2.5-8.9c2.4-3.5,1.6-7.3,2.2-10.9 c-0.1,0-0.1,0-0.1-0.1c0-0.8,0.1-1.6,0.2-2.4V48c0,0,0,0,0-0.1c0.1-0.6,0.2-1.2,0.4-1.8c0-0.5,3.2-3.9,3.3-3.7 c0.2,0.8,0.6,1.4,0.5,2.1c0.1,0,0.2,0,0.2,0.1c0,2.2,0.1,4.4,0.4,6.6c0.1,0,0.1,0,0.2,0.1c2.3,2.5,3,6.1,4.1,9.3 c1.3,3.9,2,6.4,2.1,10.5c0,0.2-0.1,0.3-0.2,0.5c0.8,2.3-0.4,4.9-0.9,7.3c-0.1,0.3-0.2,0.7-0.3,1.1c1.1-2.2,2-4.5,2.9-6.9 C79.4,68,79.3,62.8,79.2,57.6z"
      />
      <g opacity="0.98">
        <path
          fill={colors[1]}
          d="M71.2,82.7c-1.9-0.6-3.6-1.3-5.2-2.2c-0.4-0.7-0.7-1.5-1.1-2.2c-0.9-1.9-1.6-3.9-2.3-6.1 c0.1-0.7,0.2-1.4,0.4-2c0.2-0.8,0.5-1.7,0.7-2.5c0.4-1.3,0.7-2.6,1-3.9l1-0.4l-0.5-1c0-0.1-0.1-0.1-0.1-0.2 c2.1-2.5,3.3-5,3.7-7.4c0.1-0.9,0.1-1.7,0-2.6l0.6-0.3c0.2,0.6,0.4,1.1,0.6,1.5c0.7,1.8,1.6,3.5,2.4,5.1c1,2,2,3.8,2.7,5.8 c1.7,5,1.5,10.3-0.7,16.9C73.4,81.9,72.3,82.4,71.2,82.7z M68.9,50.5c-0.7-0.7-1.1-1.7-1.1-2.7c0.1-0.8,0.4-1.6,1-2.1 C68.3,47.3,68.5,49,68.9,50.5L68.9,50.5z"
        />
        <path
          fill={colors[2]}
          d="M71.1,82.9c-1.6-0.5-3-1.1-4.4-1.8c-0.3-0.6-0.6-1.3-0.9-1.9c-0.8-1.8-1.5-3.7-2.2-5.7 c0.1-0.6,0.2-1.1,0.3-1.6c0.2-0.8,0.4-1.6,0.7-2.5c0.3-1.1,0.6-2.2,0.9-3.4l1.5-0.7l-0.8-1.6c1.8-2.3,2.9-4.5,3.3-6.7 c0.5,1.2,1.1,2.3,1.7,3.4c1,1.9,2,3.7,2.6,5.7c1.6,4.7,1.4,9.7-0.6,15.8C72.7,82.3,71.9,82.7,71.1,82.9z"
        />
        <path
          fill={colors[0]}
          d="M71,82.9c-1.3-0.4-2.5-0.9-3.6-1.5c-0.2-0.5-0.5-1-0.7-1.6c-0.8-1.7-1.4-3.4-2.1-5.4 c0.1-0.5,0.2-0.9,0.3-1.3c0.2-0.8,0.4-1.6,0.7-2.4c0.3-0.9,0.5-1.9,0.8-2.9l2.1-0.8l-1-2c1-1.3,1.8-2.6,2.3-4 c0.2,0.3,0.3,0.7,0.5,1c1,1.9,1.9,3.7,2.6,5.5c1.5,4.4,1.3,9.1-0.4,14.8C72,82.6,71.5,82.8,71,82.9z"
        />
        <path
          fill={colors[3]}
          d="M70.9,83c-1-0.3-1.9-0.7-2.7-1.1c-0.2-0.4-0.4-0.8-0.6-1.2c-0.7-1.6-1.3-3.2-1.9-5 c0.1-0.3,0.1-0.6,0.2-0.9c0.2-0.8,0.4-1.6,0.7-2.4c0.2-0.8,0.5-1.6,0.7-2.4l2.7-1.2l-1.3-2.4c0.4-0.6,0.8-1.1,1.1-1.7 c0.8,1.5,1.5,3,2,4.5c1.4,4,1.3,8.4-0.3,13.7C71.2,82.9,71.1,82.9,70.9,83z"
        />
        <path
          fill={colors[4]}
          d="M70.5,82.6c-0.6-0.2-1.1-0.4-1.6-0.7c-0.1-0.3-0.3-0.6-0.4-0.8c-0.7-1.5-1.3-3-1.8-4.6 c0-0.2,0.1-0.4,0.1-0.6c0.2-0.8,0.4-1.6,0.7-2.4c0.2-0.6,0.4-1.3,0.6-2l2.6-1.1C71.9,74,71.9,77.9,70.5,82.6z"
        />
        <path
          fill={colors[5]}
          d="M70.8,83.1c-0.1-0.2-0.1-0.3-0.2-0.5c-0.6-1.4-1.2-2.7-1.7-4.2c0-0.1,0-0.2,0.1-0.3 c0.2-0.8,0.4-1.6,0.6-2.3c0.1-0.5,0.3-1,0.4-1.5l1-0.4C71.7,76.6,71.7,79.6,70.8,83.1L70.8,83.1z"
        />
      </g>
    </g>
  )
}

export default BlueFlameLeft
