import React from "react"

const LeatherShoulderLayered = ({
  colors = ["#A97C50", "#8B5E3C", "#603913", "#SVGID_", "#FFFFFF", "#C49A6C"]
}) => {
  return (
    <g id="LeatherShoulderLayered">
      <g opacity="0.2">
        <path d="M39.8055,63.3013306c0-0.3117943-0.155899-0.6235886-0.3117981-0.8574371l1.3251343-5.2225723 c0,0-4.0533447-1.7148781-7.716938-0.155899c0,0,0.1558952,0.3117943,0.3897438,0.8574371 c-1.1692352,0.2338448-2.4943657,0.7015381-3.7415485,1.4030762c0,0,0.3117962,0.2338486,0.7794895,0.7015419 c-0.5456429,0.3897438-1.1692333,0.7794876-1.6369267,1.3251305c0,0,2.4164143,1.40308,3.6635952,3.8194962 c0.8574371-0.155899,1.4810295-0.3117981,2.0266724-0.4676895c1.1692352,0.0779495,1.9487228,0,2.4943619-0.155899 c1.9487228,0.3897476,2.2605209,0.3117981,2.1825676,0.155899c0.4676933-0.2338486,0.7794914-0.7015381,0.7794914-1.1692352 C39.8055,63.4572296,39.8055,63.4572296,39.8055,63.3013306C39.8055,63.3792801,39.8055,63.3013306,39.8055,63.3013306z" />
        <path d="M65.953598,60.0274773c0.38974-0.4676895,0.7794876-0.7015419,0.7794876-0.7015419 c-1.2471848-0.7015381-2.6502686-1.1692314-3.7415504-1.4030762c0.2338486-0.5456429,0.3897438-0.8574371,0.3897438-0.8574371 c-3.7415466-1.558979-7.716938,0.155899-7.716938,0.155899l1.3251305,5.2225723 c-0.2338486,0.2338486-0.3117981,0.5456429-0.3117981,0.8574371l0,0c0,0.0779495,0,0.155899,0,0.155899 c0,0.5456467,0.3117981,1.0133362,0.7794914,1.1692352c-0.0779495,0.155899,0.2338448,0.3117981,2.1825676-0.155899 c0.5456429,0.155899,1.3251305,0.2338486,2.4943619,0.155899c0.545639,0.0779495,1.2471809,0.2338409,2.0266647,0.4676895 c1.1692352-2.4164124,3.6635971-3.8194962,3.6635971-3.8194962 C67.1228256,60.8069649,66.4992371,60.4172211,65.953598,60.0274773z" />
      </g>
      <path
        fill={colors[0]}
        d="M28.4584236,60.9452553c0,0,2.4164162,1.40308,3.6635971,3.8195 c4.3651352-0.9353905,4.3651352-0.9353905,4.3651352-0.9353905l-2.4164162-5.6123199 C34.0707436,58.2170448,30.5630436,58.6067886,28.4584236,60.9452553z"
      />
      <path
        fill={colors[1]}
        d="M29.3938103,58.9185829c0,0,2.80616,2.1825676,3.0400047,5.2225761 c4.5210381,0.7794876,5.3005257-0.4676933,5.3005257-0.4676933l-2.1046219-6.4697533 C35.6297188,57.1257591,32.2779198,57.2816582,29.3938103,58.9185829z"
      />
      <path
        fill={colors[0]}
        d="M32.7456131,56.6580696c0,0,1.7928238,3.351799,1.6369247,7.0153961 c4.9107819,1.2471809,4.5210342,0.7015419,4.2092438,0.4676933l1.8707733-7.3271942 C40.5405006,56.8139648,36.4871597,55.0990906,32.7456131,56.6580696z"
      />
      <circle fill={colors[2]} cx="38.202" cy="63.128" r="1.325" />
      <circle fill="url(#SVGID_6_)" cx="38.202" cy="62.894" r="1.325" />
      <radialGradient
        id="SVGID_6_"
        cx="38.184"
        cy="700.917"
        r="1.329"
        gradientTransform="translate(0 -638.013)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.662" stopColor="#8b5e3c" />
        <stop offset="1" stopColor="#c49a6c" />
      </radialGradient>
      <g>
        <path
          fill={colors[0]}
          d="M68.6684647,60.9452553c0,0-2.4164124,1.40308-3.6635895,3.8195 c-4.365139-0.9353905-4.365139-0.9353905-4.365139-0.9353905l2.4164162-5.6123199 C63.0561523,58.2170448,66.5638504,58.6067886,68.6684647,60.9452553z"
        />
        <path
          fill={colors[1]}
          d="M67.7330856,58.9185829c0,0-2.80616,2.1825676-3.0400085,5.2225761 c-4.5210342,0.7794876-5.3005257-0.4676933-5.3005257-0.4676933l2.1046219-6.4697533 C61.4971695,57.1257591,64.8489685,57.2816582,67.7330856,58.9185829z"
        />
        <path
          fill={colors[0]}
          d="M64.381279,56.6580696c0,0-1.7928276,3.351799-1.6369247,7.0153961 c-4.910778,1.2471809-4.5210342,0.7015419-4.20924,0.4676933l-1.8707733-7.3271942 C56.5863914,56.8139648,60.6397324,55.0990906,64.381279,56.6580696z"
        />
        <circle fill={colors[2]} cx="58.925" cy="63.128" r="1.325" />
        <circle fill="url(#SVGID_7_)" cx="58.925" cy="62.894" r="1.325" />
        <radialGradient
          id="SVGID_7_"
          cx="-1007.095"
          cy="700.917"
          r="1.327"
          gradientTransform="matrix(-1 0 0 1 -948.182 -638.013)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.662" stopColor="#8b5e3c" />
          <stop offset="1" stopColor="#c49a6c" />
        </radialGradient>
      </g>
    </g>
  )
}

export default LeatherShoulderLayered
