import React from "react"

const OpenCloak = ({ colors = ["#297544", "#0E0D0D"] }) => {
  return (
    <g id="OpenCloak">
      <g id="openCloak">
        <path
          fill={colors[0]}
          d="M28.2,79.1c0,0,16.3-15.2,17.8-16.5c-10.4-3.9-6.6-12.1-4.3-22.7c3,2.3,4.2,3.6,7.5,3.6 c1,0,5.9-3.7,6.3-3.4c2.9,13.3,3.8,17.1-4,22.4C52,62.9,73.4,76,73.4,76s-4.5,42.5-4.7,43.6h9.6l-5.1-51.1c0,0,0.6-14.7-2.9-16.3 c-3.6-1.6-6.4-1.2-6.4-1.2s-0.3-4.2-3.3-9.5C57.9,36.5,54,30,48.5,30s-10.8,9.3-11.6,11.4c-0.8,2.1-3.8,9.5-3.8,9.5 s-4.7,1-6.3,1.6s-1.7,1.6-2.5,4c-0.9,2.3-0.8,15.8-0.8,15.8L20,119.6h9.7C29.8,119.5,28.2,79.1,28.2,79.1z"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M73.6,76l-1.2-2.9c0,0-14.8-10.4-14.1-10.4 c8.5-4,1.2-16.5,0.9-17.8c-1.2-3.4-2.9-6.1-4.3-6.8c-0.5-0.2-5.4,3.6-6.4,3.6c-3.2,0-3.8-1.2-6.3-3.6 c-0.3,0.5-15.4,25.6-1.9,23.7L23.6,75.9l-0.7,43.6h10.5c0,0,13-54.8,11.7-57.3c-6-3.5-6.3-14-3.1-17.2c1.2,0.9,2.4,1.4,3.8,0.2 c0.6-0.5,1.5-0.9,2.8-0.8c1.1,0,1.8,0.5,2.8,1.1s1.7,0.4,4-1.3c3.5,8.1,0,14.7-3.1,18.3c-0.4,0.5,13.9,57.1,13.9,57.1h7.9 C74.2,118.5,73,82.8,73.6,76z"
        />
      </g>
    </g>
  )
}

export default OpenCloak
