import React from "react"
import { withSkinColor } from "../../builderConnector"

const HeadBase = ({ skinColor, colors = ["#E7B080"] }) => {
  return (
    <g id="HeadBase">
      <path
        fill={skinColor}
        d="M53.6740685,60.4054527c-0.0234375-0.0523071,0.2984619-2.1700439,0.1877441-3.0442505 c-0.0856323-0.6760254-0.4569092-1.2967529-0.6437378-2.0652466c-0.2956543-1.2166138-1.0097656-1.8236694-1.2634277-2.3755493 c-0.8269043-0.6577759-1.812439-1.1064453-2.9120483-1.1571045c-0.0054932-0.005127-0.0108643-0.0179443-0.0164795-0.0179443 l-0.1654663-0.0001221c-0.0053711,0-0.0103149-0.0015259-0.015686-0.0015259l-1.3602905,0.0007935l-1.3602905-0.0007935 c-0.0053711,0-0.0103149,0.0015259-0.015686,0.0015259l-0.1654663,0.0001221 c-0.0056152,0-0.0109863,0.0128174-0.0164795,0.0179443c-1.0996094,0.0506592-2.085144,0.4993286-2.9120483,1.1571045 c-0.2536621,0.5518799-0.9677734,1.1589355-1.2634277,2.3755493c-0.1868286,0.7684937-0.5581055,1.3892212-0.6437378,2.0652466 c-0.1107178,0.8742065,0.2111816,2.9919434,0.1877441,3.0442505c0.0688477,1.026123-0.3154907,1.2009888,0.2011719,2.1725464 c0.6585693,0.24823,2.982605,1.0559692,4.0910645,1.8519859c0.0118408,0.1488647,1.0959473,0.3009644,1.8971558,0.2972412 c0.8012695,0.0037231,1.8853149-0.1483765,1.8971558-0.2972412c1.1084595-0.7960777,3.4324951-1.603756,4.0910645-1.8519859 C53.9895592,61.6064415,53.6052208,61.4315758,53.6740685,60.4054527z"
      />
    </g>
  )
}

export default withSkinColor(HeadBase, { character: "ManGnome" })
