import React from "react"

const Nose = ({ colors = ["#282560", "#EC1C24", "#SVGID_", "#FFFFFF"] }) => {
  return (
    <g id="Nose">
      <polygon
        opacity="0.3"
        fill={colors[0]}
        points="67.8,19.6 69.2,20 70.6,19.6 70.1,19.4 69.5,19.7 68.9,19.7 68.3,19.4"
      />
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="69.193"
        y1="1299.797"
        x2="69.269"
        y2="1295.974"
        gradientTransform="translate(0 -1280)"
      >
        <stop offset="0.004" stopColor="#ec1c24" stopOpacity="0.4" />
        <stop offset="1" stopColor="#ec1c24" stopOpacity="0" />
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M70.4,18.9c-0.1,0-0.5-1.5-0.4-2.2c0-0.9-0.4-0.9-0.7-0.9c-0.3,0-0.8-0.1-0.9,0.8 c0.1,0.8-0.2,1.9-0.5,2.7c-0.1,0.2,0,0.4,0,0.4c0.3,0,0.6-0.1,0.8,0.1c0.2,0.1,0.5,0.2,0.7,0.1c0.2,0,0.4-0.1,0.6-0.2l0,0l0,0 c0.1,0,0.1-0.1,0.2-0.1s0.3,0,0.3,0.1C70.6,19.6,70.5,19,70.4,18.9z"
      />
      <path
        fill="#FFF"
        d="M69.4,19.1C69.4,19,69.4,19,69.4,19.1c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.1,0-0.2,0L69,19l0,0v0.1v0.1 l0.1,0.1c0.1,0,0.1,0,0.2,0l0.1-0.1C69.4,19.2,69.4,19.2,69.4,19.1L69.4,19.1z"
      />
      <path
        opacity="0.3"
        fill={colors[0]}
        d="M70.2,18.6c-0.1,0,0.3,0.2,0.4,1l0,0c-0.1-0.1-0.3-0.2-0.5-0.2 c-0.1,0.1-0.3,0.1-0.4,0.2c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2-0.1c0,0-0.1,0-0.1-0.1 c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2,0-0.3,0.1-0.5,0.2l0,0c0-0.8,0.4-1,0.4-1c-0.6,0.4-0.5,1.1-0.5,1.1s0.4,0.1,0.8,0.4 c0,0,0.3,0.3,0.6,0.3c0.3,0,0.7-0.2,0.7-0.3c0.1-0.3,0.8-0.4,0.8-0.4S70.8,19.1,70.2,18.6z"
      />
    </g>
  )
}

export default Nose
