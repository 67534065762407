import React from "react"

const ShortswordRight = ({ colors = ["#828284", "#CCCCCB", "#FFFFFF"] }) => {
  return (
    <g id="ShortswordRight">
      <path d="M21.5894356,83.806015c0.2999992,0.3000031,0.6000004,0.4000015,1,0.4000015h0.1000004 c0.7999992,0,1.3999996-0.6999969,1.3999996-1.5c0-0.1999969,0-0.4000015-0.1000004-0.5999985l2.7999992-1 c0.2000008,2.5999985,1,5.0999985,2.6000004,7.5999985c-0.3999996,0.4000015-0.6000004,1-0.3999996,1.5v0.0999985 c0.1000004,0.1999969,0.2000008,0.4000015,0.3999996,0.5999985c0.3999996,0.4000015,0.8999996,0.5,1.5,0.3000031 c0.7999992-0.3000031,1.2000008-1.0999985,0.8999996-1.9000015v-0.0999985 c-0.1000004-0.1999969-0.2000008-0.4000015-0.3999996-0.5999985s-0.5-0.3000031-0.7999992-0.4000015 c-0.2000008-3.0999985-1.2000008-5.9000015-2.1000004-7.8000031l3.1000004-1.0999985v0.0999985 c0.1000004,0.1999969,0.2000008,0.4000015,0.3999996,0.5999985c0.3999996,0.4000015,0.8999996,0.5,1.4999981,0.3000031 c0.7999992-0.3000031,1.2000008-1.0999985,0.9000015-1.9000015V78.306015 c-0.1000023-0.1999969-0.2000008-0.4000015-0.4000015-0.5999985c-0.3999977-0.4000015-0.8999977-0.5-1.5-0.3000031 c-0.2000008,0.0999985-0.3999977,0.1999969-0.4999981,0.3000031l-2.8999996,1L18.7894363,41.7060127L14.689435,38.506012 l-1.1000004,5L25.189436,79.7060089l0,0l0.2000008,0.5l-2.7999992,1l0,0c-0.7999992,0-1.3999996,0.6999969-1.3999996,1.5 C21.189436,83.2060089,21.2894363,83.6060104,21.5894356,83.806015z" />
      <g id="longsworsShadow_2_">
        <path
          fill={colors[0]}
          d="M22.5894356,81.506012L22.5894356,81.506012l3-1.0999985l-11.6000013-37l1.1000004-4.2000008 l3.4000006,2.7999992l10.5,37.1999969l3.2999973-1.1999969c0.1000023-0.0999985,0.2999992-0.1999969,0.5-0.3000031 c0.7000008-0.1999969,1.4000015,0.0999985,1.6000023,0.8000031v0.0999985 c0.2000008,0.6999969-0.2000008,1.4000015-0.7999992,1.5999985l0,0 c-0.7000008,0.1999969-1.4000015-0.0999985-1.6000004-0.8000031v-0.0999985c0-0.0999985,0-0.1999969-0.1000004-0.1999969 l-3.6000004,1.3000031c0.8999996,1.9000015,2,4.8000031,2.1000004,8.0999985c0.5,0,1,0.3000031,1.2000008,0.8000031v0.0999985 c0.2000008,0.6999969-0.2000008,1.4000015-0.7999992,1.5999985l0,0 c-0.7000008,0.1999969-1.3999996-0.0999985-1.6000004-0.8000031v-0.0999985c-0.2000008-0.5,0-1.0999985,0.5-1.4000015 c-1.5-2.3000031-2.6000004-4.8000031-2.7000008-7.9000015l-3.2999992,1.1999969 c0.1000004,0.1999969,0.2000008,0.4000015,0.2000008,0.6999969c0,0.6999969-0.6000004,1.3000031-1.2000008,1.3000031h-0.1000004 c-0.7000008,0-1.2000008-0.5-1.2999992-1.1999969C21.3894367,82.1060104,21.9894371,81.506012,22.5894356,81.506012z"
        />
        <polygon
          fill={colors[1]}
          points="15.189435,39.3060112 27.4894371,79.6060104 25.3894367,79.506012 13.9894352,43.4060135"
        />
      </g>
      <polygon
        fill="#FFF"
        points="27.4894371,79.6060104 15.189435,39.4060135 26.9894371,79.6060104"
      />
      <polygon
        fill={colors[1]}
        points="31.689436,79.006012 23.7894363,81.9060135 23.5894356,81.7060089 31.5894356,78.9060135"
      />
      <path
        fill={colors[1]}
        d="M34.2894363,79.006012c0,0-0.7999992,1.5999985-2.1000023-0.0999985 c-0.0999985,0-0.1999989,0-0.1999989,0s0.1999989,1.3000031,1.3999996,1.1999969 C34.0894356,79.9060135,34.2894363,79.006012,34.2894363,79.006012z"
      />
      <path
        fill={colors[1]}
        d="M23.8894367,82.6060104c0,0-1.2000008,1.8000031-2.2000008-0.1999969 c-0.2000008,0.6999969,0.6000004,1.4000015,0.6000004,1.4000015S23.689436,84.2060089,23.8894367,82.6060104z"
      />
    </g>
  )
}

export default ShortswordRight
