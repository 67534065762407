import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SunstaffL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SunstaffL") ? (
    <g id="SunstaffL">
      <path
        id="path_2_"
        d="M89.5,122.6l-1-1.3l-1.4-4.3v-3.6l-0.2-0.1v-1.9l0.2-0.5v-1l-0.2-0.1v-1.3l0.2-0.6v-0.9l-0.2-0.1v-0.7 l0.2-0.6v-0.5l-0.2-0.1v-3.7l0.2-0.3v-1.3l-0.2-0.1v-1.5l0.2-0.8v-3.7l-0.2-0.1v-2.3l0.2-0.6v-3.1l-0.2-0.1v-1.4l0.2-0.6v-0.8 l-0.2-0.1v-3.5l0.2-0.2v-0.1l-0.2-0.2v-0.7l0.2-0.5v-0.5l-0.2-0.1v-4.6l0.2-0.3v-0.7l-0.2-0.1v-0.6l0.2-0.6v-1.7l-0.2-0.1v-0.2 l0.2-0.6v-3.1L86.9,66v-1l0.2-0.6v-1l-0.2-0.1v-2.6l0.2-0.3v-1.3l-0.2-0.1v-3.5l0.2-0.3v-0.6l-0.2-0.1V51l0.2-0.2v-2.3l-0.2-0.2 l-1.7-9.6l0.2-0.4c-0.1-0.3-0.2-0.5-0.3-0.8c-1.3-0.7-2.3-1.4-2.8-2l0,0c-0.3-0.3-0.5-0.5-0.5-0.5L80.1,28 c0.8-2.5,2.9-4.4,5.5-4.9c0.2,0,0.4-0.1,0.5-0.1c0.1-0.2,0.1-0.3,0.2-0.5c1-2.5,2.5-6.3,6.8-7.4c0.7-0.2,1.4-0.3,2.1-0.3 c1.2,0,2.4,0.3,3.5,0.8c0.2,0.1,0.5,0.2,0.8,0.3c2.9,1.1,9.7,3.8,9.7,10.7c0,2.7-1,5.4-2.1,8.2c-0.6,1.6-1.2,3.2-1.5,4.5 c-0.6,2.7-0.9,7-0.9,8.2l-0.3,0.2l0,4.2l-0.1,0.2h0l0,1.6l0.1,0l0,1.6l-0.1,0.2l0,2.7l0.1,0l0,1.2l-0.1,0.2l0,1.7l0.1,0l0,2.4 l-0.1,0.2l0,2.3l0,0l0.1,0l-0.1,0.2l0,2.1l0.1,0l0,2.2l-0.1,0.2l0,1.5l0.1,0l0,3l-0.1,0.2l0,1.1l0.1,0l0,2.4l-0.1,0.2l0,0.8l0.1,0 l0,0.4l-0.1,0.2l0,1.9l0.1,0l0,2l-0.1,0.2l0,2.6l0.1,0l-0.1,3.8l-0.1,0.2l0,3.6l0.1,0l0,2.4l-0.1,0.2l0,2.4l0.1,0l0,2.5l-0.1,0.2 l0,0.2l0.1,0l0,1.7l-0.1,0.2l0,1.8l0.1,0l0,0.7l-0.1,0.2l0,0.9l0.1,0l0,2.1l-0.1,0.2l-0.1,6.2l-1.9,4.7l-1.2,1.3L89.5,122.6z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SunstaffL, { character: "ManMixed" })
