import React from "react"

const PlateHelmGold = ({
  colors = ["#A59A76", "#8A784E", "#E6BB21", "#EED793"]
}) => {
  return (
    <g id="PlateHelmGold">
      <path
        opacity="0.42"
        fill="#21376C"
        d="M57.2,40.3c0,0,0-0.1-0.1-0.2v-0.2H57 c-0.5-1.4-2.4-6.1-6.8-7.3l0,0l-1.9-1.9l-1.8,1.9c-4.5,1.1-6.4,5.9-6.8,7.3h-0.1v0.2c0,0.1-0.1,0.2-0.1,0.2h0.1v0.1v2l7.3,0.5 l0.2,4.1h2.5l0.2-4.1l7.3-0.5L57.2,40.3L57.2,40.3L57.2,40.3z"
      />
      <path
        fill={colors[0]}
        d="M39.6,39.4h17.7c0,0-2.1-7.9-8.9-7.9S39.6,39.4,39.6,39.4z"
      />
      <rect x="39.6" y="39" fill={colors[1]} width="17.5" height="3" />
      <rect x="39.6" y="39.5" fill={colors[2]} width="17.5" height="2" />
      <polygon
        fill={colors[0]}
        points="46.5,31.7 47.1,46.7 49.7,46.7 50.3,31.7 48.4,29.8"
      />
      <polygon
        fill={colors[1]}
        points="48.3,29.9 46.8,31.5 47.4,46.3 48.3,46.3"
      />
      <polygon
        fill={colors[3]}
        points="48.4,29.9 50,31.5 49.3,46.3 48.4,46.3"
      />
    </g>
  )
}

export default PlateHelmGold
