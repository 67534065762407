import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BowRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BowRight") ? (
    <g id="BowRight">
      <path
        id="S_x24_bowRight"
        d="M62.2467003,123.4878387l0.1897659-1.3283463l0.1897621-1.7078781v-0.0948792l-0.1897621-1.7078705 l-0.094883-0.474411l-0.6641693-1.7078705l-0.2846451-0.5692902l-0.1897659-0.2846451L29.133007,42.8384361V42.648674 l-0.189764-0.5692902l-0.7590523-1.5181084l-0.2846432-0.4744072l-1.1385803-1.3283424l-0.094883-0.094883l-1.4232254-1.0436974 l-0.8539333-0.474411l-1.6129894-0.5692902h-0.0948811l-1.7078686-0.1897621 c-4.0799122,0.1897621-7.3058872,3.225975-7.9700584,7.4956512l-0.094882,0.5692902v0.1897621v0.9488182 c-0.3795261,0.9488144-1.0436983,2.4669189-2.1822777,5.1236076l-0.3795271,0.7590523 C4.8433061,64.56633,28.5637169,118.5539856,41.942028,123.392952h0.9488144 C46.0219421,123.4878387,62.2467003,123.4878387,62.2467003,123.4878387z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BowRight, {
  character: "ManHalfling"
})
