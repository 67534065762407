import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import RightScaled from "./RightScaled"
import LeftScaled from "./LeftScaled"
import RightShoulderGuard from "./RightShoulderGuard"
import LeftShoulderGuard from "./LeftShoulderGuard"

const CATEGORY = "shoulderPlates"
const CHARACTER = "ManMixed"
export const IDS = {
  RIGHT_SCALED: "RightScaled",
  LEFT_SCALED: "LeftScaled",
  RIGHT_SHOULDER_GUARD: "RightShoulderGuard",
  LEFT_SHOULDER_GUARD: "LeftShoulderGuard"
}

export const components = {
  [IDS.RIGHT_SCALED]: RightScaled,
  [IDS.LEFT_SCALED]: LeftScaled,
  [IDS.RIGHT_SHOULDER_GUARD]: RightShoulderGuard,
  [IDS.LEFT_SHOULDER_GUARD]: LeftShoulderGuard
}

export const Group = ({ props }) => (
  <g id="shoulderPlates">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.RIGHT_SCALED,
    name: "Right Scaled",
    defaultColors: ["#9D9D9D", "#FFFFFF"],
    colorable: true,
    component: components[IDS.RIGHT_SCALED],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.LEFT_SCALED,
    name: "Left Scaled",
    defaultColors: ["#9D9D9D", "#FFFFFF"],
    colorable: true,
    component: components[IDS.LEFT_SCALED],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.RIGHT_SHOULDER_GUARD,
    name: "Right Shoulder Guard",
    defaultColors: ["#898888"],
    colorable: true,
    component: components[IDS.RIGHT_SHOULDER_GUARD],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.LEFT_SHOULDER_GUARD,
    name: "Left Shoulder Guard",
    defaultColors: ["#898888"],
    colorable: true,
    component: components[IDS.LEFT_SHOULDER_GUARD],
    inUI: true,
    enabled: false,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
