import React from "react"

const SolidColor = ({ colors = ["#7A7A7A"] }) => {
  return (
    <g id="SolidColor">
      <rect
        x="13.972"
        y="27.952"
        fill={colors[0]}
        width="66.946"
        height="92.695"
      />
    </g>
  )
}

export default SolidColor
