import React from "react"

const Eyepatch = ({ colors = ["#3D3D3D"] }) => {
  return (
    <g id="Eyepatch">
      <path
        id="ep"
        fill={colors[0]}
        d="M68,15.1c-0.4-1.1-3.9,0-3.7,0.4l-1-0.4v0.3l1,0.6c0,0.1,0.2,1.8,3,0.8c0.8-0.4,0.8-1.1,0.8-1.3 l6.6-3.2L74.5,12L68,15.1z"
      />
    </g>
  )
}

export default Eyepatch
