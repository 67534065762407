import React from "react"
import { withSkinColor } from "../../builderConnector"

const RightHand = ({ skinColor, color = [] }) => {
  return (
    <g id="RightHand">
      <path
        fill={skinColor}
        d="M65.5,82.1c0.4-0.6,0.2-0.8,0.2-0.8s-0.3-0.7,0-1.3s0.9-0.7,0.9-0.7s-0.6-0.8-0.2-1.7 c0.1-0.2,0.3-0.6,0.9-0.9c-0.2-2.1,1.3-2.1,1.9-2c0,0,0.1,0,0.1-0.1c1.4,0.9,3.1,1.2,4.6,1.8c0.4,0.1,0.7,0.1,1.1,0.1 c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.1,0,0.2,0.1c0.5,0.9,0.7,1.7,0.8,2.5c0,0.8-1,3.4-1.4,4.1c-0.5,0.6-0.9,1-1.2,1.4c0,0,0,0-0.1,0 h-0.1l0,0c-0.4,0.2-0.9,0.3-1.3,0.4l0,0c-0.3,0.1-0.6,0.2-0.9,0.3c-0.4,0.1-0.7,0.2-1,0.3c-0.1,0-0.2,0.1-0.3,0.1 c-0.2-0.1-0.5-0.1-0.7-0.2c0.1,0,0.1,0,0.3,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1-0.1c-0.3-0.1-0.6-0.1-0.8-0.3 c-1.4-0.7-2.7-2.2-2.7-2.2S65.1,82.7,65.5,82.1z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M65.5,82.1c0.4-0.6,0.2-0.8,0.2-0.8s-0.3-0.7,0-1.3 s0.9-0.7,0.9-0.7s-0.6-0.8-0.2-1.7c0.1-0.2,0.3-0.6,0.9-0.9c-0.1-1.3,0.4-1.8,0.9-2c-0.2,0.1-0.4,0.4-0.6,0.9 c-0.3,0.8,2.2,2.5,2.2,2.5l-1.5-0.5c0,0-1.1-0.5-1.5,0.4s0.1,1.3,0.1,1.3l1.4,0.9c0,0-1.7-0.6-2,0s0,1.3,0,1.3l1.4,1 c0,0-1-0.6-1.4,0s-0.1,1-0.1,1s1.3,1.5,2.7,2.2c0.1,0,0.2,0,0.3,0c0,0,0,0-0.1,0c0.2,0,0.7,0.2,1.3-0.2s0.7-1,0.3-1.5 c-0.1-0.1,0.2-0.2,0.2-0.3c1.1,0.5,1.6-0.4,1.7-0.8s-0.4-1.1-0.4-1.2c0.1-0.1,1.3,0.2,1.7-0.4c0.6-0.7-0.1-2-1.1-2.2 c0-0.1,0,0,0-0.1c1.3,0.2,1.8-0.3,1.5-1.6c-0.2-0.4-0.8-0.8-1.6-1.2l-1.2-0.5l-2.7-1.1c0.1,0,0.2,0,0.4,0c0,0,0.1,0,0.1-0.1 c1.4,0.9,3.1,1.2,4.6,1.8c0.4,0.1,0.7,0.1,1.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.1,0,0.2,0.1c0.5,0.9,0.7,1.7,0.8,2.5 c0,0.8-1,3.4-1.4,4.1c-0.5,0.6-0.9,1-1.2,1.4c0,0,0,0-0.1,0h-0.1l0,0c-0.4,0.2-0.9,0.3-1.3,0.4l0,0c-0.3,0.1-0.6,0.2-0.9,0.3 c-0.4,0.1-0.7,0.2-1,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.2-0.1-0.5-0.1-0.7-0.2c0.1,0,0.1,0,0.3,0c-0.1,0-0.2,0-0.3,0 c0,0-0.1,0-0.1-0.1c-0.3-0.1-0.6-0.1-0.8-0.3c-1.4-0.7-2.7-2.2-2.7-2.2S65.1,82.7,65.5,82.1z"
      />
    </g>
  )
}

export default withSkinColor(RightHand, { character: "undefined" })
