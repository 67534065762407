import React from "react"

const BeardThree = ({ colors = ["#A96F6F", "#B9B8A9", "#DECCC2"] }) => {
  return (
    <g id="BeardThree">
      <path
        fill={colors[0]}
        d="M51.1,49.8h-5.2c-0.2,0-0.4-0.2-0.4-0.4l0,0c0-0.2,0.2-0.4,0.4-0.4h5.2c0.2,0,0.4,0.2,0.4,0.4l0,0 C51.6,49.6,51.4,49.8,51.1,49.8z"
      />
      <path
        fill={colors[1]}
        d="M46.4,77.8h-1.7c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h1.7c0.1,0,0.2,0.1,0.2,0.2l0,0 C46.7,77.7,46.6,77.8,46.4,77.8z"
      />
      <path
        fill={colors[1]}
        d="M46.4,77.4h-1.7c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h1.7c0.1,0,0.2,0.1,0.2,0.2l0,0 C46.7,77.2,46.6,77.4,46.4,77.4z"
      />
      <path
        fill={colors[1]}
        d="M46.4,76.9h-1.7c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h1.7c0.1,0,0.2,0.1,0.2,0.2l0,0 C46.7,76.8,46.6,76.9,46.4,76.9z"
      />
      <path
        fill={colors[1]}
        d="M52.6,77.8h-1.7c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h1.7c0.1,0,0.2,0.1,0.2,0.2l0,0 C52.8,77.7,52.7,77.8,52.6,77.8z"
      />
      <path
        fill={colors[1]}
        d="M52.6,77.4h-1.7c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h1.7c0.1,0,0.2,0.1,0.2,0.2l0,0 C52.8,77.2,52.7,77.4,52.6,77.4z"
      />
      <path
        fill={colors[1]}
        d="M52.6,76.9h-1.7c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h1.7c0.1,0,0.2,0.1,0.2,0.2l0,0 C52.8,76.8,52.7,76.9,52.6,76.9z"
      />
      <path
        fill={colors[2]}
        d="M57.9,56.7c0-1.9-0.8-11.1-1.1-11.2c-0.5-0.2-1.8,2.3-2.8,2.8c-1.2,0.7-2.5-0.7-3.4-0.6 c-1.2,0.1-2.3,0.5-2.3,0.5s-1.4-0.7-2.3-0.7c-0.9,0-1.9,1.2-2.8,0.6c-1.1-0.7-2.6-2.7-3.4-2.6C39,45.6,39,55,39,56.7 c0.3,7.8,5.9,20.4,5.9,20.4s-1.2,2-1.1,3.7c0.5,1.7,0.5,1.7,1.6,3.9c2.3-5.1,0.5-7.3,0.7-7.7c0.4-1,2.4-18.7,2.4-18.7l2.7,19 c0,0-1.4,4.8,0.4,7.4c0.4-1.9,1.8-2,1.9-3.6s-1.4-3.9-1.4-3.9S57.9,63.1,57.9,56.7z M50.7,49.8c0,0-0.6,0.1-2.2,0.1 s-2.2-0.2-2.2-0.2s-0.8-0.3,0.3-0.3c2.1-0.1,1.8-0.6,1.8-0.6s0,0.9,1.9,0.7C51.8,49.3,50.7,49.8,50.7,49.8z"
      />
    </g>
  )
}

export default BeardThree
