import React from "react"

const BrownShoes = ({
  colors = [
    "#774F1F",
    "#6B441B",
    "#A16628",
    "#372513",
    "#948F8B",
    "#EED793",
    "#70431B",
    "#794B22",
    "#925F3A",
    "#9E6A47",
    "#SVGID_",
    "#594B1F"
  ]
}) => {
  return (
    <g id="BrownShoes">
      <path
        opacity="0.5"
        d="M45.1776085,119.4980927c0,0,0.3417015-3.0753174-0.1708527-3.6733017 c-0.0854225-0.0854263-0.4271278-0.5979767-1.4522362-3.2461777c-0.0854225-0.256279-0.1708488-0.5125504-0.3417015-0.8542557 l-0.0854263-0.3417053h-0.0854225c-0.2562752-0.427124-0.4271278-0.683403-0.5125542-0.7688293l0.0854263-0.170845h-0.3417053 c-0.0854225,0-0.1708488-0.0854263-0.3417015-0.0854263h-0.0854263h-0.0854225 c-0.4271278-0.0854263-1.0251083-0.170845-1.7085114-0.170845c-0.5125542,0-1.0251083,0.0854187-1.4522362,0.256279 l-0.2562752,0.0854187l0,0c-0.0854263,0.0854263-0.1708527,0.0854263-0.2562752,0.1708527l-0.256279,0.0854263v0.0854187l0,0 c-0.4271278,0.3417053-0.683403,0.6834106-0.8542557,1.195961c-0.1708488,0.4271317-1.0251045,2.9044724-1.623085,4.1004333 c-0.5125542,1.0251007-0.3417053,2.306488-0.1708527,2.9044647l0.0854263,0.5979767h9.9093666V119.4980927z"
      />
      <path
        fill={colors[0]}
        d="M42.956543,111.6389389c0,0,1.3668098,3.758728,1.879364,4.2712784 c0.4271278,0.512558,0,3.075325,0,3.075325h-9.2259636c0,0-0.5125504-1.6230927,0.0854263-2.9898987 s1.623085-4.2712784,1.623085-4.2712784L42.956543,111.6389389z"
      />
      <path
        fill={colors[1]}
        d="M42.3585663,111.4680862c0,0,1.9647865,2.306488,0.683403,3.5878754 c0.5979805,0.5979691-0.2562752,2.9898911-2.9044724,2.9044647c-2.9898949-0.0854187-2.6481895-2.9044647-2.6481895-2.9044647 s-1.0251083-2.2210693,0.5125504-3.331604C39.5395203,110.5284042,42.3585663,111.4680862,42.3585663,111.4680862z"
      />
      <path
        fill={colors[2]}
        d="M42.3585663,110.6992569c0,0,1.8793602,2.306488,0.5979767,3.5878677 c0.5979805,0.5979767-0.2562752,2.9898987-2.8190422,2.9044724c-2.9044685-0.0854263-2.562767-2.9044724-2.562767-2.9044724 s-1.0251083-2.2210617,0.5125542-3.3315964C39.6249466,109.7595749,42.3585663,110.6992569,42.3585663,110.6992569z"
      />
      <rect
        x="35.525"
        y="118.815"
        fill={colors[3]}
        width="9.226"
        height="0.683"
      />
      <rect
        x="35.525"
        y="118.644"
        fill={colors[4]}
        width="9.226"
        height="0.171"
      />
      <path
        fill={colors[5]}
        d="M39.1123924,115.3976593c0.0854263,0.0854263,0.5979805-0.256279,1.2813835-0.256279 c0.6834068,0,1.195961,0.3417053,1.2813835,0.256279c0.0854263-0.0854187-0.4271278-0.7688293-1.2813835-0.7688293 C39.5395203,114.6288376,39.0269661,115.2268143,39.1123924,115.3976593z"
      />
      <path
        fill={colors[1]}
        d="M38.8561134,114.5434036c1.0251083-0.170845,1.6230888-0.170845,2.7336197,0 c0.2562752-1.1105347,0.4271278-2.7336121,0.683403-3.8441467c-1.623085-0.3417053-2.4773407-0.5125504-4.0149994,0.0854263 C38.5144157,111.9806366,38.5998383,113.4328766,38.8561134,114.5434036z"
      />
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="-975.948"
        y1="747.985"
        x2="-976.011"
        y2="751.832"
        gradientTransform="matrix(-1 0 0 1 -935.65 -638.013)"
      >
        <stop offset="0.033" stopColor="#70431b" />
        <stop offset="0.276" stopColor="#794b22" />
        <stop offset="0.719" stopColor="#925f3a" />
        <stop offset="0.912" stopColor="#9e6a47" />
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M39.2832413,114.4579849c0.8542557-0.1708527,1.2813835-0.1708527,2.0502167,0 c0.1708527-1.1105347,0.3417015-2.7336197,0.5125504-3.8441544c-1.1959572-0.256279-1.8793602-0.256279-3.0753174,0 C38.9415436,111.7243652,39.0269661,113.2620239,39.2832413,114.4579849z"
      />
      <path
        fill={colors[1]}
        d="M42.3585663,114.0308533c0.2562752,0,0.7688293-0.0854263,0.8542557-0.256279 c0.0854225-0.0854263,0-0.5125504-0.1708527-0.6834106l0,0c0.0854225,0.3417053,0.683403,0.768837-0.683403,1.1105423 c-1.3668098-0.8542557-2.4773445-1.0251083-3.6733017-0.4271317v-0.170845 C39.8812218,113.0911713,40.9917526,113.1765976,42.3585663,114.0308533z"
      />
      <path
        fill={colors[1]}
        d="M38.6852646,113.6037292v0.170845l0,0 C38.6852646,113.6891479,38.6852646,113.6037292,38.6852646,113.6037292L38.6852646,113.6037292z"
      />
      <path
        fill={colors[2]}
        d="M38.4289856,112.4931946c0-0.0854187,0-0.0854187,0-0.170845 c1.5376625-0.5979767,2.9044724-0.4271317,4.6129837,0.683403c0,0,0.0854225,0.0854263,0.0854225,0.1708527 C41.3334579,112.0660706,39.9666481,111.8097916,38.4289856,112.4931946z"
      />
      <path
        fill={colors[2]}
        d="M38.4289856,112.4931946v-0.170845l0,0 C38.4289856,112.4077682,38.4289856,112.4077682,38.4289856,112.4931946L38.4289856,112.4931946z"
      />
      <path
        fill={colors[2]}
        d="M43.212822,113.8600082c-0.0854263,0.170845-0.5979805,0.170845-0.8542557,0.256279 c-1.3668098-0.8542557-2.3919182-1.0251083-3.6733017-0.512558l-0.0854225-0.256279 c-0.0854225-0.256279-0.0854225-0.5125504-0.1708527-0.7688293c1.5376587-0.5979767,2.9044724-0.427124,4.6984062,0.5979767 C43.212822,113.3474503,43.2982445,113.6891479,43.212822,113.8600082z"
      />
      <path
        fill={colors[2]}
        d="M38.6852646,113.6037292L38.6852646,113.6037292c0-0.0854263-0.0854225-0.1708527-0.0854225-0.256279 L38.6852646,113.6037292z"
      />
      <path
        fill={colors[2]}
        d="M38.4289856,112.4931946c0.0854263,0.256279,0.0854263,0.512558,0.1708527,0.768837 L38.4289856,112.4931946L38.4289856,112.4931946z"
      />
      <g>
        <path
          fill={colors[11]}
          d="M38.7706909,111.8097916c0.0854225,0.7688293,0.0854225,1.195961,0.1708527,1.9647903 c1.0251045-0.1708527,1.5376587-0.1708527,2.4773407,0.0854263c0.0854263-0.768837,0.1708527-1.195961,0.3417053-1.9647903 C40.5646248,111.6389389,39.9666481,111.6389389,38.7706909,111.8097916z M39.1123924,112.0660706 c0.939682-0.0854263,1.3668098-0.0854263,2.3064919,0.0854263c-0.0854225,0.5125504-0.0854225,0.7688293-0.1708527,1.2813797 c-0.8542557-0.170845-1.1959572-0.170845-2.0502129-0.0854263 C39.1123924,112.8348923,39.1123924,112.5786209,39.1123924,112.0660706z"
        />
        <path
          fill={colors[5]}
          d="M38.7706909,111.6389389c0.0854225,0.768837,0.0854225,1.195961,0.1708527,1.9647903 c1.0251045-0.170845,1.5376587-0.170845,2.4773407,0.0854263c0.0854263-0.7688293,0.1708527-1.195961,0.3417053-1.9647903 C40.5646248,111.4680862,39.9666481,111.4680862,38.7706909,111.6389389z M39.1123924,111.8952179 c0.939682-0.0854263,1.3668098-0.0854263,2.3064919,0.0854187c-0.0854225,0.512558-0.0854225,0.768837-0.1708527,1.2813873 c-0.8542557-0.1708527-1.1959572-0.1708527-2.0502129-0.0854263 C39.1123924,112.6640472,39.1123924,112.4077682,39.1123924,111.8952179z"
        />
      </g>
      <g>
        <path
          opacity="0.5"
          d="M62.4335709,119.4126663c0,0,0.3417015-3.0753174-0.1708527-3.5878677 c-0.0854225-0.0854263-0.4271278-0.5979767-1.4522362-3.2461777c-0.0854225-0.256279-0.1708527-0.5125504-0.3417015-0.8542557 l-0.0854263-0.3417053h-0.0854225c-0.2562752-0.427124-0.4271278-0.683403-0.5125542-0.7688293l0.0854263-0.170845h-0.3417053 c-0.0854225,0-0.1708527-0.0854263-0.3417015-0.0854263h-0.0854263h-0.0854225 c-0.4271278-0.0854263-1.0251083-0.1708527-1.7085114-0.1708527c-0.5125542,0-1.0251083,0.0854263-1.4522362,0.256279 l-0.2562752,0.0854263l0,0c-0.0854263,0.0854263-0.1708527,0.0854263-0.2562752,0.1708527l-0.256279,0.0854187v0.0854263l0,0 c-0.4271278,0.3417053-0.683403,0.6834106-0.8542557,1.195961c-0.1708488,0.427124-1.0251045,2.9044724-1.623085,4.1004257 c-0.5125542,1.0251083-0.3417053,2.306488-0.1708527,2.9044724v0.5979767L62.4335709,119.4126663z"
        />
        <path
          fill={colors[0]}
          d="M60.2125053,111.6389389c0,0,1.3668098,3.758728,1.879364,4.2712784 c0.4271317,0.512558,0,3.075325,0,3.075325h-9.2259598c0,0-0.5125542-1.6230927,0.0854225-2.9898987 c0.5979805-1.366806,1.6230888-4.2712784,1.6230888-4.2712784L60.2125053,111.6389389z"
        />
        <path
          fill={colors[1]}
          d="M59.6145287,111.4680862c0,0,1.9647903,2.306488,0.6834068,3.5878754 c0.5979767,0.5979691-0.256279,2.9898911-2.9044724,2.9044647c-2.9898949-0.0854187-2.6481895-2.9044647-2.6481895-2.9044647 s-1.0251083-2.2210693,0.5125504-3.331604C56.7954826,110.5284042,59.6145287,111.4680862,59.6145287,111.4680862z"
        />
        <path
          fill={colors[2]}
          d="M59.6145287,110.6992569c0,0,1.879364,2.306488,0.5979805,3.5878677 c0.5979805,0.5979767-0.2562752,2.9898987-2.8190422,2.9044724c-2.9044724-0.0854263-2.562767-2.9044724-2.562767-2.9044724 s-1.0251083-2.2210617,0.5125504-3.3315964C56.8809128,109.7595749,59.6145287,110.6992569,59.6145287,110.6992569z"
        />
        <rect
          x="52.78"
          y="118.815"
          fill={colors[3]}
          width="9.226"
          height="0.683"
        />
        <rect
          x="52.78"
          y="118.644"
          fill={colors[4]}
          width="9.226"
          height="0.171"
        />
        <path
          fill={colors[5]}
          d="M56.3683548,115.3976593c0.0854263,0.0854263,0.5979805-0.256279,1.2813835-0.256279 c0.6834068,0,1.195961,0.3417053,1.2813835,0.256279c0.0854263-0.0854187-0.4271278-0.7688293-1.2813835-0.7688293 S56.2829323,115.2268143,56.3683548,115.3976593z"
        />
        <path
          fill={colors[1]}
          d="M56.1120796,114.5434036c1.0251083-0.170845,1.6230888-0.170845,2.7336197,0 c0.2562752-1.1105347,0.4271278-2.7336121,0.683403-3.8441467c-1.623085-0.3417053-2.4773407-0.5125504-4.0150032,0.0854263 C55.7703781,111.9806366,55.8558044,113.4328766,56.1120796,114.5434036z"
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="-993.204"
          y1="747.985"
          x2="-993.267"
          y2="751.832"
          gradientTransform="matrix(-1 0 0 1 -935.65 -638.013)"
        >
          <stop offset="0.033" stopColor="#70431b" />
          <stop offset="0.276" stopColor="#794b22" />
          <stop offset="0.719" stopColor="#925f3a" />
          <stop offset="0.912" stopColor="#9e6a47" />
        </linearGradient>
        <path
          fill="url(#SVGID_2_)"
          d="M56.5392075,114.4579849c0.8542557-0.1708527,1.2813835-0.1708527,2.0502167,0 c0.1708488-1.1105347,0.3417015-2.7336197,0.5125504-3.8441544c-1.1959572-0.256279-1.879364-0.256279-3.0753174,0 C56.197506,111.7243652,56.2829323,113.2620239,56.5392075,114.4579849z"
        />
        <path
          fill={colors[1]}
          d="M59.6145287,114.0308533c0.256279,0,0.7688332-0.0854263,0.8542557-0.256279 c0.0854263-0.0854263,0-0.5125504-0.1708488-0.6834106l0,0c0.0854225,0.3417053,0.683403,0.768837-0.6834068,1.1105423 c-1.366806-0.8542557-2.4773407-1.0251083-3.6732979-0.4271317v-0.170845 C57.137188,113.0911713,58.2477188,113.1765976,59.6145287,114.0308533z"
        />
        <path
          fill={colors[1]}
          d="M55.941227,113.6037292v0.170845l0,0 C55.941227,113.6891479,55.941227,113.6037292,55.941227,113.6037292L55.941227,113.6037292z"
        />
        <path
          fill={colors[2]}
          d="M55.6849518,112.4931946c0-0.0854187,0-0.0854187,0-0.170845 c1.5376587-0.5979767,2.9044724-0.4271317,4.6129837,0.683403c0,0,0.0854225,0.0854263,0.0854225,0.1708527 C58.5894241,112.0660706,57.2226105,111.8097916,55.6849518,112.4931946z"
        />
        <path
          fill={colors[2]}
          d="M55.6849518,112.4931946v-0.170845l0,0 C55.6849518,112.4077682,55.6849518,112.4077682,55.6849518,112.4931946L55.6849518,112.4931946z"
        />
        <path
          fill={colors[2]}
          d="M60.4687843,113.8600082c-0.0854225,0.170845-0.5979767,0.170845-0.8542557,0.256279 c-1.366806-0.8542557-2.3919144-1.0251083-3.6732979-0.512558l-0.0854225-0.256279 c-0.0854263-0.256279-0.0854263-0.5125504-0.1708527-0.7688293c1.5376587-0.5979767,2.9044685-0.427124,4.6984062,0.5979767 C60.4687843,113.3474503,60.5542107,113.6891479,60.4687843,113.8600082z"
        />
        <path
          fill={colors[2]}
          d="M55.941227,113.6037292L55.941227,113.6037292c0-0.0854263-0.0854225-0.1708527-0.0854225-0.256279 L55.941227,113.6037292z"
        />
        <path
          fill={colors[2]}
          d="M55.6849518,112.4931946c0.0854263,0.256279,0.0854263,0.512558,0.1708527,0.768837 L55.6849518,112.4931946L55.6849518,112.4931946z"
        />
        <g>
          <path
            fill={colors[11]}
            d="M56.0266571,111.8097916c0.0854225,0.7688293,0.0854225,1.195961,0.1708488,1.9647903 c1.0251083-0.1708527,1.5376625-0.1708527,2.4773445,0.0854263c0.0854225-0.768837,0.1708527-1.195961,0.3417015-1.9647903 C57.820591,111.6389389,57.2226105,111.6389389,56.0266571,111.8097916z M56.3683548,112.0660706 c0.939682-0.0854263,1.3668098-0.0854263,2.3064919,0.0854263c-0.0854225,0.5125504-0.0854225,0.7688293-0.1708527,1.2813797 c-0.8542557-0.170845-1.1959572-0.170845-2.0502129-0.0854263 C56.3683548,112.8348923,56.3683548,112.5786209,56.3683548,112.0660706z"
          />
          <path
            fill={colors[5]}
            d="M56.0266571,111.6389389c0.0854225,0.768837,0.0854225,1.195961,0.1708488,1.9647903 c1.0251083-0.170845,1.5376625-0.170845,2.4773445,0.0854263c0.0854225-0.7688293,0.1708527-1.195961,0.3417015-1.9647903 C57.820591,111.4680862,57.2226105,111.4680862,56.0266571,111.6389389z M56.3683548,111.8952179 c0.939682-0.0854263,1.3668098-0.0854263,2.3064919,0.0854187c-0.0854225,0.512558-0.0854225,0.768837-0.1708527,1.2813873 c-0.8542557-0.1708527-1.1959572-0.1708527-2.0502129-0.0854263 C56.4537849,112.6640472,56.3683548,112.4077682,56.3683548,111.8952179z"
          />
        </g>
      </g>
    </g>
  )
}

export default BrownShoes
