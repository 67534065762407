import React from "react"

const Pouch = ({ colors = ["#4D391B", "#7D5B3C", "#362812", "#A76F3A"] }) => {
  return (
    <g id="Pouch">
      <polygon
        fill={colors[0]}
        points="82.8,54.8 78.2,56.9 77.7,56.8 76.4,51.3 81.6,50.1 82.1,50.7"
      />
      <path
        fill={colors[1]}
        d="M76.8,52l0.8-0.1c0,0,1.3,4.1,1,4.7c-0.2,0.6-0.8,0.2-0.8,0.2L76.8,52z"
      />
      <path
        fill={colors[1]}
        d="M77.8,51.9l4.1-1.1l0.9,4l-4.2,1.7C78.6,56.5,78.8,55.5,77.8,51.9z"
      />
      <path
        fill={colors[0]}
        d="M76.4,51.5c0,0,1.7,0,2.4,1.6c0.7,1.5,1.9,1.5,1.9,1.5s1.2-1.6,1-2.4c-0.2-0.9-0.2-1.8-0.2-1.8L76.4,51.5z"
      />
      <path
        fill={colors[1]}
        d="M76.6,51.3c0,0,1.7,0,2.4,1.6c0.7,1.5,2,1.3,2,1.3s0.8-1.5,0.6-2.4c-0.2-0.9,0.1-1.7,0.1-1.7L76.6,51.3z"
      />
      <ellipse
        transform="rotate(-11.958 80.552 53.566)"
        fill={colors[2]}
        cx="80.6"
        cy="53.6"
        rx="0.2"
        ry="0.3"
      />
      <ellipse
        transform="rotate(-11.958 80.661 53.525)"
        fill={colors[3]}
        cx="80.7"
        cy="53.5"
        rx="0.2"
        ry="0.3"
      />
    </g>
  )
}

export default Pouch
