import React from "react"

const MedSkirt = ({ colors = ["#006B3A", "#231F20"] }) => {
  return (
    <g id="MedSkirt" fillRule="evenodd" clipRule="evenodd">
      <path
        fill={colors[0]}
        d="M54.8284912,88.7875214 c1.637085,0.4260864,1.9469604,1.5494385,3.8317871,1.3067627c1.3984985-0.1800537,1.9205322-0.861145,3.3962402-0.5595703 c1.2764282,0.2608643,2.2511597,0.8196411,3.4093018,1.1424561c1.4036865-0.0012817,2.807251-0.0082397,4.2109375-0.0076294 c2.5862427-0.4612427,5.1120605-1.3864746,7.7794189-1.2667847c2.6102905,0.1171875,5.9840698-0.1203003,8.6846924,0.5690308 c-0.894165-3.9553833-1.6741943-7.6871948-2.1091309-10.4086914c-3.0036621-27.2999878-6.4918213-26.0999756-6.4918213-26.0999756 s-3.9725952,2.7999878-8.9141846,2.7999878c-4.9415283,0.2000122-9.3017578-2.7000122-9.3017578-2.7000122 s-5.7166748,5.6000366-6.6856079,25.7000122c-0.3390503,2.1314697-1.0419312,6.2005615-1.8410034,10.7501221 C52.0631714,89.1786957,53.2438354,88.3751068,54.8284912,88.7875214z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M58.6602783,90.0942841c1.3984985-0.1800537,1.9205322-0.861145,3.3962402-0.5595703 c1.2960815,0.2648315,2.2808838,0.8372803,3.4628296,1.1573486c1.3370972-0.00177,2.6741943-0.0006714,4.0113525-0.0008545 c2.637146-0.4515381,5.2080688-1.4104004,7.9254761-1.2884521c0.6654663,0.0298462,1.3880005,0.034729,2.1300659,0.038147 l-4.1779785-34.6777954c0,0-3.9725952,1.5-6.8793945,1.5s-6.3949585-1.2999878-6.3949585-1.2999878l-3.5299072,35.1339111 C58.6243896,90.0947113,58.6395264,90.0969696,58.6602783,90.0942841z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M58.6602783,90.0942841c1.3984985-0.1800537,1.9205322-0.861145,3.3962402-0.5595703 c0.7445068,0.1521606,1.3848267,0.4071655,2.019043,0.6577759c0.4960327-4.7938843,0.8682251-9.0461426,0.8682251-11.0293579 c0-6-0.3875732-23.5-0.3875732-23.5l-4.6508789-1.7999878c0,0-1.1627197,13.2999878-2.1316528,25.1999512 c-0.2510376,3.0571899-0.7439575,6.7319946-1.3344727,10.4976196 C57.0258179,89.9265594,57.5889893,90.2322235,58.6602783,90.0942841z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M73.7086792,89.7863617c1.2316284-0.2559814,2.4731445-0.4407959,3.7474976-0.383606 c1.2703247,0.0570068,2.7244873,0.02948,4.1937256,0.0622559c-0.8139038-4.75177-1.5534668-9.1832886-1.9783325-12.0018921 c-1.2596436-9-2.8099365-23.4000244-2.8099365-23.4000244l-4.2632446,1.6000366c0,0-0.1937866,15.2999878,0.0968628,22.5999756 C72.7893677,80.625412,73.1993408,84.9822235,73.7086792,89.7863617z"
      />
    </g>
  )
}

export default MedSkirt
