import React from "react"

const LongBowLeft = ({
  colors = [
    "#695E60",
    "#42130B",
    "#FFCD03",
    "#EDD692",
    "#B5AC95",
    "#915B25",
    "#A98A30"
  ]
}) => {
  return (
    <g id="LongBowLeft">
      <line
        fill="none"
        stroke={colors[0]}
        strokeWidth="0.11"
        strokeMiterlimit="10"
        x1="46.149"
        y1="108.753"
        x2="67.558"
        y2="53.317"
      />
      <polygon
        fill={colors[1]}
        points="63.3267021,86.7753296 63.9639587,85.3196564 64.9765701,82.5813675 65.5705795,81.0602264 64.0728226,80.0745239 63.3466911,81.9651413 62.2908211,84.6379623 61.5646896,86.5285797"
      />
      <polygon
        fill={colors[2]}
        points="63.4342575,87.0804977 61.0396881,87.157608 61.2384758,86.4619293 64.089035,86.6478958"
      />
      <g fill={colors[3]}>
        <polygon points="64.9765701,82.5813675 63.456604,81.7044144 63.3034363,81.8996658 63.1268654,82.4866104 64.7123108,83.3203049" />
        <polygon points="65.3285294,81.6904297 63.743084,80.8567352 63.5010414,81.4869385 65.0210114,82.3638916" />
        <polygon points="63.0496216,85.3593979 62.1586876,85.007431 61.8733826,85.5721588 62.3948479,85.7919846" />
        <polygon points="63.5465279,86.2538605 63.7885704,85.6236496 63.0496216,85.3593979 62.5035858,85.8142014" />
        <polygon points="64.4480438,84.0592499 62.77491,83.3775482 62.467392,84.0510178 64.118309,84.8414536" />
        <polygon points="63.9206963,85.2541809 64.0306168,84.9934464 62.4451714,84.1597519 62.2908211,84.6379623 62.1809082,84.8986969 63.8762665,85.4716568" />
        <polygon points="63.3921776,86.7320709 63.4588356,86.4058533 61.9166451,85.6376343 61.5646896,86.5285797" />
      </g>
      <g fill={colors[4]}>
        <polygon points="64.4702682,83.9505157 64.6901016,83.429039 63.5606384,82.8584442 63.0578575,83.3787308" />
        <polygon points="62.7971268,83.2688141 62.9491196,83.356514 63.4519005,82.8362122 63.0391655,82.6386108" />
      </g>
      <g fill={colors[4]}>
        <polygon points="65.4162292,81.5384293 65.5705795,81.0602264 65.2665787,80.8848267 64.9602356,81.2753372" />
        <polygon points="63.83078,80.7047272 64.8515015,81.2531204 65.2233276,80.8193512 64.0295639,80.0090485" />
      </g>
      <path
        fill={colors[5]}
        d="M67.5097961,54.3832626c0.2127686,1.1762466,1.2462921,3.8795166,2.3885651,6.6049995 c2.2833481,5.7339211-2.0113754,12.6158791-4.2914581,16.567791c-0.5261688,0.9119873-0.964653,1.6719742-1.2499542,2.236702 l1.1271057,1.1365128c0.1543427-0.4782104,0.768219-1.5421906,1.4043045-2.7149048 c2.3677826-4.10392,6.448555-11.8791504,3.5572128-17.9638672c-1.0791397-2.2028427-2.7627335-5.6053238-2.6516495-6.1490097 c0.1110916-0.5436859,0.1765671-0.5869446,0.1765671-0.5869446s-0.1063843-0.5881233-0.346077-0.5238228l-0.1753922,0.303997 C67.3835449,53.3379707,67.38237,53.6209259,67.5097961,54.3832626z"
      />
      <ellipse
        transform="rotate(-47.185 64.876 80.298)"
        fill={colors[6]}
        cx="64.876"
        cy="80.298"
        rx="0.471"
        ry="1.177"
      />
      <polygon
        fill={colors[2]}
        points="65.8757477,80.952652 64.076355,79.2256775 63.8120918,79.9646149 66.0031815,81.7149963"
      />
      <path
        fill={colors[5]}
        d="M46.8494911,107.8197861c0.9389038-0.7143784,3.5778732-1.9876022,6.2168388-3.2608185 c5.5831108-2.6539993,7.0097542-10.7449951,7.9639473-15.1377411c0.2221756-1.0873718,0.4431839-1.8917847,0.6197548-2.4787369 l1.6544495-0.0584259c-0.1976051,0.412735-0.5074806,1.6521072-0.7741013,2.9569473 c-0.9986305,4.6102295-3.2975273,13.0893784-9.4897957,15.6755524c-2.3326187,0.8827133-5.8426628,2.2611389-6.105751,2.7171249 c-0.2630806,0.4559937-0.2198257,0.5214691-0.2198257,0.5214691s-0.4583435,0.3028183-0.6535988,0.1496429l0.0666504-0.3262177 C46.0193214,108.5563736,46.1724968,108.3611145,46.8494911,107.8197861z"
      />
      <ellipse
        transform="rotate(-.496 62.337 86.93)"
        fill={colors[6]}
        cx="62.338"
        cy="86.93"
        rx="1.177"
        ry="0.471"
      />
      <polygon
        fill={colors[6]}
        points="63.4143867,86.6233292 61.2384758,86.4619293 61.4793472,86.1146698 63.5465279,86.2538605"
      />
      <polygon
        fill={colors[6]}
        points="65.4384384,81.4296875 63.7243919,80.1166077 63.8120918,79.9646149 65.4606628,81.3209534"
      />
    </g>
  )
}

export default LongBowLeft
