import React from "react"

const BlueFlameLeft = ({
  colors = ["#80D0DC", "#30BFCA", "#5BC6D1", "#A8DDE6", "#C0E6F0", "#E5F5F8"]
}) => {
  return (
    <g id="BlueFlameLeft">
      <path
        opacity="0.77"
        fill={colors[0]}
        d="M81.4198227,51.9262199 c0.9000015,2.4000015,1.8000031,4.7000046,2.9000015,6.9000015c-0.1000061-0.4000015-0.2000046-0.7999954-0.3000031-1.0999985 c-0.5-2.4000015-1.7000046-5-0.9000015-7.3000031c-0.0999985-0.1999969-0.1999969-0.3000031-0.1999969-0.5 c0.0999985-4.0999985,0.7999954-6.5999985,2.0999985-10.4999962c1.0999985-3.2000008,1.7999954-6.7999992,4.0999985-9.2999992 c0.0999985-0.0999985,0.0999985-0.0999985,0.1999969-0.0999985c0.3000031-2.1999969,0.4000015-4.3999977,0.4000015-6.5999985 c0-0.0999985,0.0999985-0.0999985,0.1999969-0.0999985c-0.0999985-0.6999969,0.3000031-1.2999992,0.5-2.0999985 c0.1000061-0.2000008,3.3000031,3.2000008,3.3000031,3.7000008c0.2000046,0.5999985,0.3000031,1.2000008,0.4000015,1.7999992 c0,0.0999985,0,0.0999985,0,0.0999985v0.0999985c0.0999985,0.8000031,0.1999969,1.6000023,0.1999969,2.4000015 c0,0.0999985,0,0.0999985-0.0999985,0.0999985c0.5999985,3.6000023-0.2000046,7.4000015,2.1999969,10.9000015 c2,2.8000031,2.3000031,5.7000046,2.5,8.9000015c0.7000046,0.3999939,1.3000031,0.7999954,1.3000031,1.1999969 c0,2.8000031-0.8000031,5.4000015-1.9000015,8c2-2.3000031,3.7999954-4.9000015,4.1999969-7.8000031 c-0.1999969-0.0999985-0.4000015-0.3000031-0.4000015-0.5c-1.0999985-4.9000015-4.4000015-8.8999977-5.5-13.7999992 c-0.1000061-0.2999992,0.5999985,0,1,0.2000008c-0.8000031-3.6000023-1.1000061-7.4000015-1.2000046-11.1000023 c-0.2000046-0.2000008-2.8000031-5.7000008-2.9000015-5.7999992c-1.4000015-2.2999992-0.0999985-5.5-0.9000015-8.0999985 c-0.5,1.1999969-2.4000015,5.8999977-2.5,6.0999985c-0.8000031,0.9000015-1,4.1000023-1.4000015,5.2000008 c-0.5999985,1.3999977-0.1999969,3.2999992,0,4.7999992c0.0999985,0.7999992-1.3000031,0.0999985-1.5,0 c-0.0999985-0.0999985-0.0999985-0.0999985-0.1999969-0.0999985c-2.0999985,2.8000031-3.7999954,5.8000031-5.1999969,8.9000015 C81.7198029,41.6262283,81.6198044,46.8262291,81.4198227,51.9262199z"
      />
      <g opacity="0.98">
        <path
          fill={colors[1]}
          d="M86.6198044,60.0262337c-2.1999969-6.5999985-2.3999939-11.9000015-0.6999969-16.9000015 c0.6999969-2.0000038,1.6999969-3.8000031,2.6999969-5.8000031c0.8000031-1.5999985,1.7000046-3.2999992,2.4000015-5.0999985 c0.1999969-0.4000015,0.4000015-0.9000015,0.5999985-1.5l0.5999985,0.2999992 c-0.0999985,0.8999977-0.0999985,1.6999969,0,2.5999985c0.3999939,2.4000015,1.5999985,4.9000015,3.6999969,7.4000015 c0,0.1000023-0.0999985,0.1000023-0.0999985,0.2000008l-0.5,1l1,0.4000015c0.3000031,1.3000031,0.5999985,2.5999985,1,3.9000015 c0.2000046,0.8000031,0.5,1.6999969,0.7000046,2.5c0.1999969,0.5999985,0.3000031,1.3000031,0.4000015,2 c-0.7000046,2.1999969-1.4000015,4.1999969-2.3000031,6.0999985c-0.4000015,0.6999969-0.6999969,1.5-1.0999985,2.1999969 c-1.5999985,0.8999939-3.2999954,1.5999985-5.1999969,2.1999969 C88.7198029,61.2262306,87.6198044,60.7262306,86.6198044,60.0262337z M92.1198044,29.3262291 c0.4000015-1.5,0.5999985-3.2000008,0.0999985-4.7999992c0.5999985,0.5,0.9000015,1.2999992,1,2.0999985 C93.2198029,27.6262283,92.8198013,28.6262283,92.1198044,29.3262291L92.1198044,29.3262291z"
        />
        <path
          fill={colors[2]}
          d="M87.8198166,60.7262383c-2-6.1000061-2.1999969-11.1000061-0.5999985-15.8000031 c0.5999985-2,1.5999985-3.7999992,2.5999985-5.7000008c0.5999985-1.1000023,1.1999969-2.2000008,1.6999969-3.4000015 c0.4000015,2.2000008,1.5,4.4000015,3.3000031,6.7000008l-0.8000031,1.5999985l1.5,0.6999969 c0.3000031,1.2000046,0.5999985,2.3000031,0.9000015,3.4000015c0.2999954,0.9000015,0.5,1.6999969,0.6999969,2.5 c0.0999985,0.5,0.2000046,1,0.3000031,1.5999985c-0.7000046,2-1.4000015,3.8999939-2.2000046,5.6999969 c-0.3000031,0.5999985-0.5999985,1.3000031-0.9000015,1.9000015c-1.4000015,0.7000046-2.8000031,1.3000031-4.4000015,1.8000031 C89.1198044,61.526226,88.319809,61.1262321,87.8198166,60.7262383z"
        />
        <path
          fill={colors[0]}
          d="M88.5197983,61.1262245c-1.7000046-5.7000046-1.9000015-10.4000015-0.4000015-14.8000031 c0.6999969-1.8000031,1.5999985-3.5999985,2.5999985-5.5c0.1999969-0.2999992,0.3000031-0.7000008,0.5-1 c0.5,1.4000015,1.3000031,2.7000008,2.3000031,4l-1,2l2.0999985,0.8000031c0.3000031,1,0.5,2,0.8000031,2.9000015 c0.2999954,0.8000031,0.5,1.5999985,0.6999969,2.4000015c0.1000061,0.4000015,0.2000046,0.8000031,0.3000031,1.3000031 c-0.6999969,2-1.2999954,3.7000046-2.0999985,5.4000015c-0.1999969,0.5999985-0.5,1.0999985-0.6999969,1.5999985 c-1.0999985,0.5999985-2.2999954,1.0999985-3.5999985,1.5C89.5198059,61.6262321,89.0198059,61.4262276,88.5197983,61.1262245z"
        />
        <path
          fill={colors[3]}
          d="M89.5198212,61.7262306c-1.6000061-5.2999954-1.7000046-9.6999969-0.3000031-13.6999969 c0.5-1.5,1.1999969-3,2-4.5c0.2999954,0.5999985,0.6999969,1.0999985,1.0999985,1.6999969l-1.3000031,2.4000015 l2.6999969,1.1999969c0.1999969,0.8000031,0.5,1.5999985,0.6999969,2.4000015 c0.2999954,0.8000031,0.5,1.5999985,0.6999969,2.4000015c0.0999985,0.3000031,0.0999985,0.5999985,0.1999969,0.9000015 c-0.5999985,1.8000031-1.2000046,3.4000015-1.9000015,5c-0.1999969,0.3999939-0.4000015,0.7999954-0.5999985,1.1999969 c-0.7999954,0.4000015-1.6999969,0.7999954-2.6999969,1.0999985 C89.9198074,61.7262306,89.819809,61.7262306,89.5198212,61.7262306z"
        />
        <path
          fill={colors[4]}
          d="M90.3198166,49.2262306l2.5999985,1.0999985c0.1999969,0.6999969,0.4000015,1.4000015,0.5999985,2 c0.2999954,0.8000031,0.5,1.5999985,0.6999969,2.4000015c0,0.1999969,0.0999985,0.4000015,0.0999985,0.5999985 c-0.5,1.5999985-1.1000061,3.0999985-1.8000031,4.5999985c-0.0999985,0.2000046-0.3000031,0.5-0.4000015,0.8000031 c-0.5,0.2999954-1,0.5-1.5999985,0.6999969C89.1198044,56.7262306,89.1198044,52.8262291,90.3198166,49.2262306z"
        />
        <path
          fill={colors[5]}
          d="M90.2198029,61.9262276c-0.8999939-3.5-0.8999939-6.5-0.2000046-9.2000046l1,0.4000015 c0.0999985,0.5,0.3000031,1,0.4000015,1.5c0.1999969,0.7000046,0.4000015,1.5,0.5999985,2.3000031 c0.0999985,0.1000061,0.0999985,0.2000046,0.0999985,0.3000031c-0.5,1.5-1.0999985,2.7999954-1.6999969,4.1999969 C90.3198013,61.6262245,90.3198013,61.7262306,90.2198029,61.9262276L90.2198029,61.9262276z"
        />
      </g>
    </g>
  )
}

export default BlueFlameLeft
