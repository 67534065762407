import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LuteLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LuteLeft") ? (
    <g id="LuteLeft">
      <path
        id="S_x24_luteLeft"
        d="M58.6,119.4c-4.4,0-7.9-0.4-8.9-1c-3.8-2-9.7-6.9-6.6-17.3c1.1-3.9,9-15.6,16.4-20.3l8.3-14.9l7.5-3.9 l4.2,2.5c0,0,5,3,5,4.9S81,80.6,81,80.6l-4.7,8.2c0.5,3.4,1.1,9.9,0,16l-0.2,0.9C74.6,110.2,70,119.4,58.6,119.4z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LuteLeft, { character: "ManDwarf" })
