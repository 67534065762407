import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BlueFlameRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BlueFlameRight") ? (
    <g id="BlueFlameRight">
      <path
        id="S_x24_blueFlameRight"
        d="M14.3999996,91.3000031C12,88.5,9.1999998,84.7000046,8.5,79.8000031c0,0,1-5,0.5-3.0999985 c0.8000002-3.1999969,4.8000002-11.5,5.3000002-12.9000015c0,0,0.1000004-6.5999985,0.1999998-10.2999992 c0,0,1.1999998-3.7999992,1.1000004-3.7000008c0.1999998-0.4000015,1-1.9000015,2.2000008-4.2999992 c0.2000008-0.2999992,0.2999992-1.4000015,0.2999992-1.7000008C18,42.2000046,17.8999996,40.0000038,18.7000008,37.6000023 l6.2000008-5.5l7,4.7000008c0.2999992,0.7000008,0.9000015,2,1.5999985,3.9000015 c0.2999992,0.7000008,0.5,1.4000015,0.7000008,1.7999992c1.0999985,1.7999992,1.9000015,6.2000008,1.9000015,6.4000015 c0.2999992,0.9000015,0.5,1.7999992,0.5999985,2.5999985h0.0999985C39,54.4000053,41,57.7000046,42.7999992,61.6000061 L43.5,64.5000076c0.0999985,4.4000015-2.5,23.4000015-3.5999985,25.7999954l-12.3999996,7.0999985L14.3999996,91.3000031z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BlueFlameRight, {
  character: "ManHalfling"
})
