import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SlingShotL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SlingShotL") ? (
    <g id="SlingShotL">
      <path d="M94.1999969,102.6999969c-2,0-4-0.5999985-4.8000031-0.9000015l-2.5999985-3l-0.3000031,0.3000031 C85.1999893,97.9000015,84.3999939,96.5,84.1999893,96l-0.4000015-5.6999969c0-0.0999985,0.1999969-0.5,0.4000015-1 l0.1999969-15.9000015L78,70.6999969l-0.5-4.5999985l1.0999985-10L82.6999969,50.5l6.1999969-2.9000015l7.8000031,1.2000008 c0.5999985,0.5999985,2.6999969,2.5999985,3.8000031,5.7999992l0.1999969,0.7000008 c0.3000031,1.0999985,0.5,2.2000008,0.4000015,3.2999992C101.1999969,59.6999969,101.5,65.4000015,103,88.8999939 c0.6999969,1.9000015,0.6999969,4.0999985,0,6.8000031l-0.4000015,0.4000015c-1.0999985,3.9000015-3.5,5.5-5.3000031,6.0999985 C96.3000031,102.5,95.3000031,102.6999969,94.1999969,102.6999969L94.1999969,102.6999969z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SlingShotL, {
  character: "WomanMixed"
})
