import React from "react"

const SunstaffR = ({
  colors = ["#664000", "#FFFFFF", "#262261", "#944200", "#BF8A00", "#SVGID_"]
}) => {
  return (
    <g id="SunstaffR">
      <path
        fill={colors[0]}
        d="M41.1,40l1.1,77l1.2,1.3l1-1.3V39.9C42.2,37.6,41.1,40,41.1,40z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M41.2,52.1l0-4.5c0.1-0.1,0.6-0.4,0.8-0.2c0.6,0.6,1.5,3.5,2.5,3.6v3.7 C43.3,54.1,42.1,53,41.2,52.1z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M41.2,55.4l0-1.6c0.5,0.4,1,0.8,1.5,1.1c0.5,0.3,1,0.5,1.5,0.7c0.1,0,0.1,0.1,0.2,0.1v3.5 C43.4,57.9,42.3,56.7,41.2,55.4z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M43.2,66c-0.6-0.8-1.2-1.5-1.8-2.3l0-2.4c1.1,1.2,2.1,2.4,3.2,3.6v1 C44,66.2,43.6,66.2,43.2,66z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M41.3,59.5l0-1.2c0.9,0.9,2,1.9,3.2,2.5v2.6C43.4,62.1,42.4,60.8,41.3,59.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M41.5,75.5l0-3c0.9,0.8,1.9,1.5,3,1.7v4.6C43.5,77.7,42.5,76.6,41.5,75.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M43.2,78.5c0.4,0.5,0.9,1,1.3,1.5v0.7c-1,0-2-0.6-2.9-1.5l0-2.4 C42.1,77.3,42.6,77.9,43.2,78.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M41.9,102.6l0-2.5c0.8,0.6,1.7,1.2,2.6,1.5v3.7C43.6,104.3,42.7,103.5,41.9,102.6z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M41.9,104.7l0-1.7c0.9,1.1,1.7,2.2,2.6,3.3v0.7c-0.4-0.2-0.7-0.4-1.1-0.6 C42.9,105.8,42.4,105.2,41.9,104.7z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M41.4,66.2L41.4,66.2c1.1,1.2,2.1,2.5,3.1,3.7v0.2C43.5,68.8,42.5,67.5,41.4,66.2z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M44.5,72.5v0.6c-1.3-0.7-2.5-1.7-2.8-2c-0.1-0.1-0.2-0.2-0.3-0.3l0-2.2 C42.5,69.9,43.5,71.2,44.5,72.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M41.5,75.5l0-3c0.9,0.8,1.9,1.5,3,1.7v4.6C43.5,77.7,42.5,76.6,41.5,75.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M43.2,78.5c0.4,0.5,0.9,1,1.3,1.5v0.7c-1,0-2-0.6-2.9-1.5l0-2.4 C42.1,77.3,42.6,77.9,43.2,78.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M41.9,102.6l0-2.5c0.8,0.6,1.7,1.2,2.6,1.5v3.7C43.6,104.3,42.7,103.5,41.9,102.6z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M41.9,104.7l0-1.7c0.9,1.1,1.7,2.2,2.6,3.3v0.7c-0.4-0.2-0.7-0.4-1.1-0.6 C42.9,105.8,42.4,105.2,41.9,104.7z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M42,107.4l0-0.7c0.3,0.2,0.6,0.4,0.8,0.5c0.3,0.2,0.7,0.4,1,0.5c0.2,0.3,0.5,0.5,0.7,0.8 v1.3C43.6,109,42.8,108.2,42,107.4z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M44.5,111.5v1.9c-0.4-0.4-0.8-0.7-1.2-1.1c-0.3-0.4-0.7-0.8-0.9-1.2 c-0.1-0.1-0.2-0.3-0.3-0.5l0-2.1C42.8,109.5,43.7,110.5,44.5,111.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M43.1,95.7c0.5,0.9,0.9,1.8,1.4,2.6v1.5c-0.9-0.8-1.8-1.5-2.7-2.3l0-2.4 C42.2,95.3,42.6,95.5,43.1,95.7z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M44.5,91.4v2.3l-2.8-2.4l-0.1-3.8C42.6,88.8,43.6,90.1,44.5,91.4z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M43,82.4c-0.5-0.6-0.9-1.3-1.4-1.9l0-0.4c0.8,0.6,1.8,1,2.9,1.1v3.5 C44,83.9,43.5,83.1,43,82.4z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M41.7,84.6l0-2c0.9,1.2,1.9,2.3,2.9,3.5v1.4c-0.6-0.3-1.1-0.7-1.7-1.1 C42.4,85.8,42.1,85.2,41.7,84.6z"
      />
      <path
        fill={colors[0]}
        d="M45.7,31.6c-4-0.7-5,1.3-3.2,2.9c1.8,1.6,3.8,2.9,3.8,2.9l-1.7,9.6l-3.7,0.6c0,0-0.1-5.6-1.1-9.7 c-1-4.1-3.5-8.6-3.5-11.1s4.3-3.7,6.2-4.6c1.9-0.8,2.3,1.8,3.8,5.3c1.2,2.7,3.6,2.7,4.7,2.7c0.3,0,0.5,0,0.5,0.1 C51.6,30.2,49.7,32.3,45.7,31.6z"
      />
      <path
        opacity="0.3"
        fill={colors[2]}
        d="M45.7,31.6c-4-0.7-5,1.3-3.2,2.9c1.8,1.6,3.8,2.9,3.8,2.9 l-1.7,9.6l-0.1,0c0-0.1,0-0.2,0-0.3c0.3-6.1-3.6-9.1-3.8-9.4c-7-9,0.6-14.6,4.7-10.3c3.7,5.3,6.1,3.1,6.1,3.1 S49.7,32.3,45.7,31.6z"
      />
      <radialGradient
        id="SVGID_56_"
        cx="228.753"
        cy="180.143"
        r="3.964"
        gradientTransform="matrix(0 1 2 0 -317.722 -198.535)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={colors[3]} />
        <stop offset="0.824" stopColor="#bf8a00" />
      </radialGradient>
      <radialGradient
        id="SVGID_57_"
        cx="227.316"
        cy="178.282"
        r="1.57"
        gradientTransform="scale(-1 1) rotate(45 274.073 -222.331)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="SVGID_58_"
        cx="282.903"
        cy="97.229"
        r="2.329"
        gradientTransform="matrix(-1.1271 1.1271 1.3801 1.3801 227.466 -419.937)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_56_)" cx="42.6" cy="30.2" r="4" />
      <circle
        opacity="0.85"
        fill="url(#SVGID_57_)"
        cx="42.3"
        cy="27.9"
        r="1.6"
      />
      <path
        opacity="0.42"
        fill="url(#SVGID_58_)"
        d="M40.2,35.7c1.8,1.8,4.4,2,5.8,0.6c1.4-1.4,1.2-4.1-0.6-5.8s-4.4-2-5.8-0.6 C38.1,31.4,38.4,34,40.2,35.7z"
      />
      <g opacity="0.25" fill="#FFF">
        <path d="M40.1,37.6c0,0,2.1,4.1,1.9,9.8l-1,0.2c0,0-0.1-5.6-1.1-9.7c-0.1-0.3-0.2-0.6-0.2-0.9 C39.8,37.2,39.9,37.4,40.1,37.6z" />
        <path d="M47.1,29c-1.1-0.8-1.4-2.7-2.7-4.5c-2.7-3.8-6.8-0.1-7.7,2.7c-0.1,0.2-0.1,0.3-0.1,0.5 c-0.1-0.4-0.1-0.8-0.1-1.1c0-2.5,4.3-3.7,6.2-4.6c1.9-0.8,2.3,1.8,3.8,5.3c1.2,2.7,3.6,2.7,4.7,2.7C50.7,30.1,48.8,30.4,47.1,29 z" />
        <path d="M51.5,30.3l-0.3-0.2c0.2,0,0.4,0,0.4,0.1C51.6,30.2,51.6,30.2,51.5,30.3z" />
      </g>
    </g>
  )
}

export default SunstaffR
