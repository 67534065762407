import React from "react"

const LongBowRight = ({
  colors = [
    "#695E60",
    "#42130B",
    "#FFCD03",
    "#EDD692",
    "#B5AC95",
    "#915B25",
    "#A98A30"
  ]
}) => {
  return (
    <g id="LongBowRight">
      <line
        fill="none"
        stroke={colors[0]}
        strokeWidth="0.14"
        strokeMiterlimit="10"
        x1="80.4"
        y1="101.5"
        x2="18.7"
        y2="57.7"
      />
      <polygon
        fill={colors[1]}
        points="46.7000008,90.3000031 45,89.0999985 42,86.9000015 40.4000015,85.8000031 41.2000008,83.5999985 43.2999992,85.0999985 46.4000015,87.3000031 48.5,88.8000031"
      />
      <polygon
        fill={colors[2]}
        points="46.7999992,90.6999969 49.4000015,89.0999985 48.7000008,88.4000015 45.7999992,90.5999985"
      />
      <g fill={colors[3]}>
        <polygon points="42,86.9000015 43,84.9000015 43.2999992,85.0999985 43.9000015,85.5999985 42.7999992,87.5" />
        <polygon points="41,86.1999969 42.0999985,84.1999969 42.9000015,84.8000031 41.7999992,86.8000031" />
        <polygon points="46,88.5 46.7000008,87.5 47.4000015,88 47,88.5999985" />
        <polygon points="46.0999985,89.9000015 45.4000015,89.3000031 46,88.5999985 46.9000015,88.6999969" />
        <polygon points="43.7000008,88.0999985 44.9000015,86.1999969 45.7000008,86.8000031 44.5,88.6999969" />
        <polygon points="45,89.0999985 44.7000008,88.8000031 45.7999992,86.9000015 46.2999992,87.1999969 46.5999985,87.4000015 45.2000008,89.1999969" />
        <polygon points="46.7000008,90.1999969 46.2999992,90 47.5,88.0999985 48.4000015,88.6999969" />
      </g>
      <g fill={colors[4]}>
        <polygon points="43.5,88 42.9000015,87.5999985 43.7000008,86.3000031 44.5999985,86.4000015" />
        <polygon points="44.7999992,86.0999985 44.7000008,86.3000031 43.7999992,86.1999969 44.0999985,85.5999985" />
      </g>
      <g fill={colors[4]}>
        <polygon points="40.9000015,86.0999985 40.2999992,85.6999969 40.5,85.3000031 41.2000008,85.5" />
        <polygon points="41.9000015,84.0999985 41.2000008,85.4000015 40.5,85.1999969 41.2000008,83.5999985" />
      </g>
      <path
        fill={colors[5]}
        d="M19.5,58.7999992c0.6000004,1.4000015,1.3999996,5,2.1000004,8.7000008 c1.6000004,7.6999969,10.9999981,12,16.2000027,14.5999985c1.2000008,0.5999985,2.2000008,1.0999985,2.9000015,1.5 l-0.4000015,2c-0.5-0.4000015-1.9000015-1.0999985-3.4000015-1.9000015 C31.5000019,81,21.7000008,75.5999985,20.5000019,67.0999985c-0.3999996-3.0999985-1-7.9000015-1.5-8.4000015 s-0.6000004-0.5-0.6000004-0.5s-0.2999992-0.7000008,0-0.7999992l0.3999996,0.2000008 C18.8999996,57.5999985,19.1000004,57.7999992,19.5,58.7999992z"
      />
      <ellipse
        transform="rotate(-76.267 40.51 84.34)"
        fill={colors[6]}
        cx="40.511"
        cy="84.34"
        rx="1.5"
        ry="0.6"
      />
      <polygon
        fill={colors[2]}
        points="39.9000015,85.8000031 40.5999985,82.8000031 41.4000015,83.3000031 40.2999992,86.6999969"
      />
      <path
        fill={colors[5]}
        d="M79.0999985,101c-1.5-0.0999985-5.1999969,0.4000015-8.9000015,0.9000015 c-7.7999992,1.0999985-15-6.5-19.0999985-10.5c-1-1-1.7999992-1.6999969-2.4000015-2.1999969l-1.7999992,1.0999985 c0.5,0.3000031,1.7000008,1.4000015,2.9000015,2.5999985c4.2999992,4.1999969,12.7000008,11.5999985,21.1000023,10 C74,102.2000046,78.7000046,101.2000046,79.3000031,101.5c0.5999985,0.3000031,0.5999985,0.4000015,0.5999985,0.4000015 s0.6999969,0,0.8000031-0.3000031l-0.3000031-0.3000031C80.4000015,101.1999969,80.0999985,101.0999985,79.0999985,101z"
      />
      <ellipse
        transform="rotate(-32.956 48.007 89.687)"
        fill={colors[6]}
        cx="48.007"
        cy="89.687"
        rx="1.5"
        ry="0.6"
      />
      <polygon
        fill={colors[6]}
        points="46.5,90.0999985 48.7000008,88.4000015 48.2000008,88.1999969 46.0999985,89.8000031"
      />
      <polygon
        fill={colors[6]}
        points="40.7000008,86 41.5999985,83.5 41.4000015,83.3000031 40.5999985,86"
      />
    </g>
  )
}

export default LongBowRight
