import React from "react"

const BobL = ({ colors = ["#4691FF", "#FFFFFF"] }) => {
  return (
    <g id="BobL">
      <path
        opacity="0.2"
        fill="#21376C"
        d="M68.139183,11.525794 c0.1072845,0.1609287,0.4291458,0.9655743,0.4291458,0.9655743s2.5212173-0.0536432,2.6821518,0 c0.1609268,0.0536423,1.4483566,1.7702179,1.6629257,2.5212202c0.2145767,0.7510014,0.4291458,3.9695816,0.3755035,4.8815117 c-0.0536423,0.911932-0.4827881,3.0040073-1.1801453,3.7550087c-0.6973572,0.7510033-2.7357941,1.9847908-2.7357941,1.9847908 l-0.3754959,0.2682152l1.7702179-0.1072865l1.984787-1.3947163l2.5748672-4.1305103l-2.6821518-9.0656652L68.139183,11.525794z"
      />
      <path
        fill={colors[0]}
        d="M68.7694855,8.1865187c0.7644196-0.1609297,1.7702255-1.0058064,3.9829941,0.6839476 c4.3853149,7.1613388,4.7876358,12.4317627,4.7876358,12.1099052c0-0.175415-2.8418121,3.9959221-3.7818298,5.0290298 c-0.2769165,0.3043385-1.6253052,1.177866-2.132309,0.9655743c0-0.6034851,0.1060333-1.3281174-0.0402298-1.4483604 c0.3218536-0.3620853,1.6495209-1.9311485,1.6495209-1.9311485s-1.4483643,1.5690575-3.4599762,2.0920773 c1.9311523-1.0862713,2.6955643-1.2874317,4.0634613-5.2301922c-0.0804672-2.4943981-0.3977203-5.4226532-1.3276672-7.0406418 c-0.8562393-1.489747-1.431282-1.5262527-3.9427567-1.2472C68.1257706,9.4337177,68.0050735,8.3474474,68.7694855,8.1865187z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M76.8159409,21.8252487l-1.8909225,0.8046436 c0,0,1.126503-0.8851089,0.6839523-2.6553268c-0.120697,1.1667347-1.7299881,3.3392754-1.7299881,3.3392754l0.2011642-0.7241802 l-1.6092911,2.0116119l-0.8851089,0.9253426l0.0402298,1.4483604l0.7644119-0.0804653l1.2472-0.7241802l3.1783524-4.1439209 V21.8252487z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M68.4979324,8.5486021 c0.0300293-0.024231,0.0604858-0.0386963,0.0906372-0.0611572l-0.0001221-0.0092163l0.0123291,0.0009766 c1.0624008-0.7839241,2.3265381-0.5983992,3.1861267-0.091526c0.876152,0.5166368,1.3379974,1.3636608,1.3779602,1.4885597 c-1.3026123-1.177124-3.701355-0.4224243-3.701355-0.4224243s1.7400513-0.1911011,2.2228394,0.2011719 c-1.850708-0.3218489-2.1624756,1.0762329-2.1624756,1.0762329s0.4928131-0.6537857,1.7299805-0.0905151 c-0.9957886-0.4224854-2.4541626,1.0862427-2.4541626,1.0862427l-0.2011719-2.4240112l-0.0095825-0.7827759 C68.5587234,8.5319395,68.5280838,8.5357847,68.4979324,8.5486021z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M70.7207489,11.4185085c0.6238174,0.1490526,1.1774292,0.4364738,1.6592636,0.8292093 c0.9196243,1.0899467,2.7625351,5.0721502,2.1761398,7.9668665c-0.3022537,1.492054-0.9630966,2.8385429-1.9981232,3.7161503 c0.6598663-0.9313889,1.1871872-2.1806259,1.4802475-3.4938679c-0.3145447-6.1677074-1.1777267-7.1601534-2.0099792-8.1868925 C71.6744614,11.8134422,71.0159988,11.5595217,70.7207489,11.4185085z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M74.0017548,13.1098576c0.0003662-0.6397095-0.6457901-2.1875467-1.2291565-3.1732368 c1.3276596,1.2069674,2.1725159,3.3996162,2.1725159,3.3996162s1.079483,2.3104715,1.6294327,4.3652058 c0.3678741,1.3744946,0.2694702,2.6668053,0.3017349,2.9570694c-0.2229004-0.4457397-0.0477219-0.6447735-1.4282379-2.5949783 c0.125061,1.2064209,0.0402374,1.8909149-0.3822098,2.7961407c-0.0402222-2.1121826-0.0402145-4.043335-1.9915085-8.5493774 C73.0743256,12.310297,73.57341,12.563715,74.0017548,13.1098576z"
      />
    </g>
  )
}

export default BobL
