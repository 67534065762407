import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BookSpellL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BookSpellL") ? (
    <g id="BookSpellL">
      <path d="M77.6139679,78.0198593c0.050972-0.1025391-5.214241-3.7658386-0.5431519-8.3283005 c4.6710968-4.5624619,7.3506317-1.7380829,7.3506317-1.7380829s-1.1225052-3.0054245,4.3089905-2.9330063 c5.4315033,0.0724182,5.7573929,4.0917282,5.7573929,4.0917282s3.9831009-3.3313217,6.4091721,5.4677124 c2.4260635,8.7990265-2.6071243,11.2613144-2.6071243,11.2613144s-0.579361,3.2588959-4.2365723,5.9022293 c-3.6572037,2.6433334-6.5177994,1.2673492-6.5177994,1.2673492s-1.5570297,1.3759842-3.6210022,1.7380829 c-2.0639648,0.3620987-6.7712631-5.8660278-6.7712631-5.8660278S74.4999084,84.2841949,77.6139679,78.0198593z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BookSpellL, {
  character: "WomanGnome"
})
