import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const PoleArmL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("PoleArmL") ? (
    <g id="PoleArmL">
      <path d="M107.5,19c0-0.1,0-0.1,0-0.2c0.2-2.2-0.3-4.3-0.6-6.4l0.6-0.1l-6-27.1l-14,1.5v48.7c0,0.8,0.1,1.6,0.4,2.4l0.5,78.8 l0.6,2.8l0.7,1.7l13.2-0.5l0.6-1.7l0.4-2.4V36l0.4-0.5l0,0.2l0-0.2h-0.1l0.1-0.2c0-1.9,0.2-2.8,0.5-4c0.1-0.4,0.2-0.9,0.3-1.3 c0.4-1.6,0.9-3.6,2.2-6l0,0l0.1-0.2l0,0l0,0l-0.4-0.2c0.2-0.6,0.4-1.3,0.5-2C107.7,20.6,107.6,19.8,107.5,19z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(PoleArmL, { character: "ManMixed" })
