import React from "react"

const LeftCuff = ({ colors = ["#898888", "#FFFFFF"] }) => {
  return (
    <g id="LeftCuff">
      <path
        fill={colors[0]}
        d="M91.8,56.3c-0.7-1.1-1.8-2.1-2.6-3.2c-0.4-0.5-0.8-2.2-1.2-2.8c-0.1-0.3-0.1-0.7,0-1.1 c0.3-1.5,1.3-2.1,2.7-2.5c1.8-0.6,2.5-0.6,3.6,0.3c0.8,0.5,1.5,2,1.6,2.1c0.5,1.5,0.5,2.9,1.1,4.3c0,0.1,0.8,2.5-2.1,3.6 C92.1,58.4,91.9,56.3,91.8,56.3z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M94.3,47.1l1.5,5.5h-1l-1.9-6.2c0,0,0.2,0,0.8,0.2 C94.2,46.9,94.3,47.1,94.3,47.1z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M91.3,46.7l2.4,7.3L92,55.9l-4-6.5c0,0,0.2-1,1-1.6 S91.3,46.7,91.3,46.7z"
      />
    </g>
  )
}

export default LeftCuff
