import React from "react"

const Belt = ({ colors = ["#898888", "#000000", "#SVGID_"] }) => {
  return (
    <g id="Belt">
      <path
        opacity="0.3"
        fill="#262261"
        d="M67.5519257,52.6622963 c-3.8474579,0-12.645256-0.9346123-14.8792725-1.1346092c0.4964523,0.5999985,0.3582001,1.2346115,0.4823074,2.2346115 c0,0.7999992,0,1.5,0,2.2999992c0,0-0.3723335,0.0999985-0.868782,0.2000008v0.5c0,0,5.9573631,1,14.8934097,1 s15.3525009-1.3530998,15.3525009-1.3530998l-0.1128922-4.7205849 C82.4192047,51.5886116,71.8958435,52.6622963,67.5519257,52.6622963z"
      />
      <path
        fill={colors[0]}
        d="M82.6935577,51.9622955c-3.3510132,0.4000015-11.7906113,1.0999985-15.1416321,1.0999985 c-3.2268982,0-11.5423889-0.5-15.0175171-0.7999992c0,0.5999985-0.4964485,3.9000015-0.3723335,4.0999985 c1.7375641,0.2000008,7.4467049,0.9000015,14.7692986,0.9000015c7.446701,0,14.0246277-0.9000015,15.7621841-1.2000008 C82.6935577,54.4622955,82.8176804,53.6622963,82.6935577,51.9622955z"
      />
      <polygon
        opacity="0.3"
        fill="#262261"
        points="62.871315,55.1827431 66.8722839,51.6264191 71.4700012,54.9844208 67.4690399,58.540741"
      />
      <polygon
        fill={colors[0]}
        points="63.7298203,55.1641159 67.7307892,51.6077919 72.3285065,54.9657936 68.3275375,58.5221138"
      />
      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="52.142"
        y1="54.612"
        x2="82.749"
        y2="54.612"
      >
        <stop offset="0" stopOpacity="0.6" />
        <stop offset="0.494" stopOpacity="0" />
        <stop offset="0.993" stopOpacity="0.6" />
      </linearGradient>
      <path
        fill="url(#SVGID_2_)"
        d="M82.6935577,51.9622955c-3.3510132,0.4000015-11.7906113,1.0999985-15.1416321,1.0999985 c-3.2268982,0-11.5423889-0.5-15.0175171-0.7999992c0,0.5999985-0.4964485,3.9000015-0.3723335,4.0999985 c1.7375641,0.2000008,7.4467049,0.9000015,14.7692986,0.9000015c7.446701,0,14.0246277-0.9000015,15.7621841-1.2000008 C82.6935577,54.4622955,82.8176804,53.6622963,82.6935577,51.9622955z"
      />
    </g>
  )
}

export default Belt
