import React from "react"

const MaulR = ({ colors = ["#888787", "#FFFFFF"] }) => {
  return (
    <g id="MaulR">
      <path
        fill={colors[0]}
        d="M40.774601,76.4017639c0.1373138,0.202858,0.1507607,2.4251633,0.5876122,6.5665894 C40.3852768,88.9542007,36.5743256,93.65522,36.5743256,93.65522s-9.8571529,0.2346954-10.2772331,0.4007263 c-0.5286827,0.1803741-3.5104942-6.8841019-2.9387512-6.7386627c0.5717468,0.1454391,6.8202553-10.1822662,6.8202553-10.1822662 L40.774601,76.4017639z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M33.7568016,87.7873001 c0.2567101,0.4324112,2.817524,5.8679199,2.817524,5.8679199s4.440155-7.803215,4.7620125-8.9833603 c0.0596237-1.6759262,0.0258751-1.7035065,0.0258751-1.7035065l-0.8108902-6.345047L33.7568016,87.7873001z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M34.0127563,85.5433884l-6.1382847,1.4717178 c0.537693,0.12117,0.8075008,0.3934937,0.9161129,0.3791351c0.1086006-0.0143509,0.2313347-0.2715683,0.2313347-0.2715683 c0.2315655,0.079895,0.1752872-0.3903122,0.4212112-0.2017975c0.0942497-0.122963,0.1885033-0.2459183,0.1885033-0.2459183 l0.1086082-0.0143585c0,0-0.0942554,0.122963-0.1741505,0.354538l0,0c0,0,0,0-0.1086082,0.0143509 c0.0143528,0.1086044-0.0942497,0.122963-0.0798969,0.2315674l0,0c0.1741505-0.354538,0.3626595-0.6004562,0.471262-0.6148071 c0.1086063-0.0143585,0.1229591,0.0942535,0.2315712,0.079895c0,0-0.1885052,0.2459183-0.3770123,0.4918518l0.2315693,0.079895 c0.2028618-0.1373138,0.4057236-0.2746277,0.5143242-0.2889862c0.1086082-0.0143509,0.2172184-0.0287094,0.3258209-0.0430603 c-0.1086025,0.0143509-0.2028599,0.1373138-0.3913651,0.3832397l1.4549561,0.1392212 c0.0798988-0.2315674,0.2827587-0.3688889,0.2827587-0.3688889c0.1229591,0.0942535,0.2315636,0.0799026,0.3545303,0.1741486 c-0.0143585-0.1086044,0.0798912-0.2315674,0.0655365-0.3401794l0,0c0.1229591,0.0942535,0.3401794,0.0655441,0.4631424,0.1597977 c0.2315674,0.079895,1.0779419,0.6310577,0.2315674,0.079895c0.1229553,0.0942535,0.1516685,0.31147,0.0717697,0.5430374 l0.4487839,0.0511856L34.0127563,85.5433884z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M33.9266281,84.8917465L33.9266281,84.8917465 C34.0208817,84.7687759,34.0208817,84.7687759,33.9266281,84.8917465 C33.9122734,84.7831268,34.0208817,84.7687759,33.9266281,84.8917465 c-0.0143547-0.1086044-0.0143547-0.1086044-0.0143547-0.1086044c0.2315712,0.079895,0.3545303,0.1741409,0.5860977,0.2540512l0,0 l1.0975838-0.6708221c-0.0430603-0.3258209-0.6899605-1.2616196-0.7330246-1.5874481 c-0.0430679-0.3258209,1.4091072,0.972702,1.583252,0.6181641l0.0942535-0.1229553l0,0l0,0 c0.0942497-0.122963,0.3114662-0.1516724,0.5430336-0.0717697c0.1086082-0.0143509,0.1229591,0.0942459,0.2315712,0.079895 l0.145443-0.5717468l0,0c0,0,0,0-0.0143509-0.1086044c-0.0143585-0.1086044,0.0655403-0.3401794,0.2396927-0.6947021 c-0.0143547-0.1086044-0.0143547-0.1086044-0.0287056-0.2172165c0.0655403-0.3401718,0.0511856-0.4487839,0.3626556-0.6004486 c0,0-0.0143509-0.1086044,0.0942497-0.122963l0.0942535-0.1229553l0,0l0,0l0.7334442-1.9755096 c0.0798988-0.2315598,0.4738617,0.2303467,0.5537643-0.0012207l-0.0143547-0.1086044l0.1229591,0.0942535l0.5736465-1.5123825 c-0.0143585-0.1085968-0.0430603-0.3258209-0.0574188-0.4344254c-0.0430641-0.3258209-0.1571655,0.045311,0.2772675-0.0121078 l0.1597977-0.4631348c0,0-9.2925186,0.7045288-10.4586811,0.9361191 c-0.1023808,0.8975677-6.7864094,10.0946884-6.7864094,10.0946884l10.7066917-1.0346756 C33.9615669,85.9921722,33.9983978,85.4347839,33.9266281,84.8917465z"
      />
      <path
        fill={colors[0]}
        d="M38.0820389,87.036911c0.3913689,0.2592392,55.1232986,0.2226944,55.1232986,0.2226944 c0.4200668,0.1123047,1.5267181-0.4614944,1.2789001-1.4553833c-0.2478256-0.9938889-1.4712143-0.9537735-1.4712143-0.9537735 s-54.3412895-0.481926-54.4642563-0.4181671c-0.1470375,0.0762405-0.8167763,0.5524979-0.8167763,1.4996338 S37.9304428,86.9031296,38.0820389,87.036911z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M49.2820091,87.1195374L49.2820091,87.1195374 c0.1086082,0.0097046,0.2172203,0.0194168,0.3258209,0.029129c0.918148-0.7401581,1.7276955-1.4900208,2.5515862-2.3133469 c-0.2315712,0.0540466-0.3401756,0.0443344-0.5573921,0.02491 C50.7924843,85.6100922,49.9685936,86.4334183,49.2820091,87.1195374z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M92.2852478,85.3995056 c-0.3913727-0.2592316-8.8401566-0.2246094-8.8401566-0.2246094s-1.5348511-0.0624695-3.9672699-0.055687 c-0.2746277,0.2744446-0.6435165,0.4657059-0.9181519,0.7401505c1.5348663,0.0624771,3.1639709,0.2081223,4.7930756,0.3537674 C83.6273804,85.9386902,91.9020157,85.6642456,92.2852478,85.3995056z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M64.0856171,86.138855 c-0.8832054-0.0042114-1.6434631-0.0721817-2.4180641-0.0666885c-0.3832397,0.26474-0.7808266,0.6029358-1.1640663,0.8676682 c0.883213,0.0042114,1.7664337,0.0084305,2.5410271,0.0029297C63.3191452,86.6683197,63.7023849,86.4035797,64.0856171,86.138855 z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M75.0898743,86.3751678 c-1.7664261-0.0084305-3.5184937-0.0903168-5.2849197-0.0987396c-0.2746201,0.2744446-0.6435165,0.4657059-0.9181366,0.7401505 c2.3238068-0.0164871,4.3074493,0.0113525,5.5164795,0.044693 C74.6635742,86.8602982,74.9382095,86.5858536,75.0898743,86.3751678z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M49.9585609,84.6429138 c-0.2172203-0.0194168-0.5573921,0.0249176-0.7746086,0.0055008c-0.7951851,0.6763916-1.4961128,1.4359665-2.1970482,2.1955338 c-0.0143547,0.0734711-0.0143547,0.0734711-0.0287094,0.1469345c0.1086121,0.0097046,0.3258247,0.029129,0.434433,0.0388336 C48.3251266,86.2161026,49.1346703,85.4662323,49.9585609,84.6429138z"
      />
    </g>
  )
}

export default MaulR
