import React from "react"

const ThighArmor = ({ colors = ["#9D9D9D", "#BBBDBF", "#FFFFFF"] }) => {
  return (
    <g id="ThighArmor">
      <g fill={colors[0]}>
        <path d="M58.2787476,73.6450043c-6.5060425-1.6289673-6.5060425,2.6063232-6.5060425,2.6063232 l1.4819336,9.093689c0,0-5.6420708,3.6987457-6.0175705,4.4765701 c-0.3755035,0.7778244,2.8306808,3.7573776,4.9825974,3.7573776s5.6923561-2.2017365,5.6923561-2.2017365l-0.4291458-2.6016846 l3.0119972-11.5501556C60.494873,77.2253876,61.9547729,74.4594574,58.2787476,73.6450043z" />
        <path d="M83.7262573,85.1825409l1.6901245-8.9312134c0,0,0-4.2352905-6.5060425-2.6063232 c-3.6760254,0.8144531-2.6012802,3.6408081-2.6012802,3.6408081l3.1553574,9.7677002l-0.2293091,1.5342865l-0.3754959,3.2722168 c0,0,1.6135788,2.0749664,5.446907,2.0749664s5.2012253-3.8451843,5.2012253-3.8451843L83.7262573,85.1825409z" />
      </g>
      <ellipse fill={colors[0]} cx="56.046" cy="85.539" rx="3.146" ry="4.044" />
      <path
        fill={colors[1]}
        d="M53.4628334,85.182518c0,0,2.5625,2.7120132,5.1875,0.0435104 c0,2.5821457-0.5245514,3.4430237-2.5435257,3.7310028C54.0878334,89.245018,53.0878334,86.057518,53.4628334,85.182518z"
      />
      <ellipse fill={colors[0]} cx="81.063" cy="85.539" rx="3.146" ry="4.044" />
      <path
        fill={colors[1]}
        d="M78.4792099,85.182518c0,0,2.5625,2.7120132,5.1875,0.0435104 c0,2.5821457-0.524559,3.4430237-2.5435257,3.7310028C79.1042099,89.245018,78.1042099,86.057518,78.4792099,85.182518z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M80.791748,59.4557114c0,0,5.0287781,1.7368431,6.3730316,7.505867 c0.7114029,3.0530624-0.2874146,8.1756287-2.2530365,12.8872681c-0.3149185-1.3179779-5.1299667-4.0007706-7.7905884-0.0639648 c-0.217804-1.4191971-4.787529-13.304184-6.334816-16.543396C72.7802124,60.4909592,76.8538971,56.7729836,80.791748,59.4557114z"
        />
        <path
          opacity="0.6"
          fill="#FFF"
          d="M83.4011612,78.1632309c1.6965256-5.6369247,2.170578-13.8735962-4.1982727-16.3605728 c-10.0360184-3.9189682-1.9294434,10.6985168,0.1605301,15.9484787 C80.8756714,77.3288116,81.9215546,77.4857025,83.4011612,78.1632309z"
        />
      </g>
      <g>
        <path
          fill={colors[0]}
          d="M56.5582695,59.375248c0,0-5.0287819,1.7368431-6.3730354,7.5058632 c-0.7113991,3.0530624,0.2874146,8.1756363,2.2530365,12.8872757c0.3149185-1.3179855,4.7309799-4.1205978,7.3916092-0.1837921 c0.2178001-1.4191895,5.0051804-13.1444168,6.5524673-16.3836327 C64.3884735,60.4504395,60.4961205,56.6925201,56.5582695,59.375248z"
        />
        <path
          opacity="0.6"
          fill="#FFF"
          d="M53.9488564,78.0827713c-1.6965294-5.6369324-2.1705818-13.8736038,4.1982689-16.3605766 c10.0360184-3.9189682,1.9294434,10.698513-0.1605301,15.9484825 C56.4743423,77.2483521,55.428463,77.4052353,53.9488564,78.0827713z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M56.4262466,55.347744l0.1320229,4.027504c0,0,8.149662,0.6629295,10.0763893,4.8873405 c2.1127853,0.6895294,3.9069748,0,3.9069748,0s0.6435242-4.9374809,10.08918-4.8873405 c-0.4147797-2.9265976-0.9403915-3.8670044-0.9403915-3.8670044L56.4262466,55.347744z"
      />
    </g>
  )
}

export default ThighArmor
