import React from "react"

const TorchL = ({
  colors = [
    "#664000",
    "#F1D212",
    "#FFD400",
    "#FFD500",
    "#SVGID_",
    "#FFFFFF",
    "#F4B719",
    "#F49A35",
    "#F37B20",
    "#F1662E",
    "#EE4223"
  ]
}) => {
  return (
    <g id="TorchL">
      <path
        fill={colors[0]}
        d="M102.5999985,44.2999992L98.6999969,65l1,0.5L101,65.4000015L106.0999985,45 C106.0999985,45,105.0999985,44.2000008,102.5999985,44.2999992z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M101,53.4000015 c-0.0999985,0.2000008-0.0999985,0.2999992-0.1999969,0.5L98.6999969,65l0.9000015,0.5 c0.5999985-2.0999985,1.1999969-4.2999992,1.6999969-6.5c1-4.4000015,1.9000015-9.5999985,3.5-14.4000015 c-0.0999985,0-0.1999969-0.0999985-0.4000015-0.0999985C103.4000015,47.5,102,50.5,101,53.4000015z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M102.4000015,58.0999985 C101.5999985,60.5,101,63,100.3000031,65.5H101l2.9000015-11.5999985 C103.4000015,55.2999992,102.9000015,56.7000008,102.4000015,58.0999985z"
      />
      <path
        opacity="0.84"
        fill={colors[1]}
        d="M109.5999985,29.2000008 c-0.6999969-1.7999992-0.9000015-3.6000004-2-5.2000008c0,0-0.5-0.7000008-0.5999985-0.7000008 c-0.0999985,0.1000004-0.8000031,0.2000008-0.6999969-0.2000008c0.0999985-0.8999996,1-1.2000008,0.6999969-2.1000004 c-0.1999969-0.7000008-1.1999969-4.6999989-1.5-5.3999996c-0.4000015,1.5-1.8000031,5.2999992-2.5,6.7000008 c0,0-1.0999985,4.8999996-1.5,7C101.6999969,29.1999989,102.0999985,29,102,29.1999989 c-0.5999985,2.8999996-2.1999969,5.3000011-2.8000031,8.1999989c0,0.0999985-0.0999985,0.2000008-0.1999969,0.2999992 c0.1999969,1.7000008,1.0999985,3.2000008,2.0999985,4.5999985c-0.5999985-1.5-1-3.0999985-1-4.7000008 c0-0.2000008,0.3000031-0.5,0.5999985-0.7000008C100.7999954,34.9999924,101,33.2999954,102,31.6999931 c1.1999969-2.1000004,1.9000015-5.3999996,1.9000015-5.3999996c0-0.3999996,1.5-4.1999989,1.0999985-5.8999996 c-0.0999985-0.5,0.3000031,0.7999992,0.3000031,1.2999992c0.0999985,0,0.0999985,0,0.0999985,0.1000004 c0,1.2999992,0.5999985,5.6999989,0.5999985,5.8000011c1.1999969,1.5,1.1999969,1.7999992,1.8000031,3.6000004 c0.6999969,2.3000011,1,3.8000011,1.0999985,6.1999989c0,0.0999985,0,0.2000008-0.0999985,0.2999992 c0.4000015,1.4000015-0.1999969,2.9000015-0.5,4.2999992c0,0.2000008-0.0999985,0.4000015-0.0999985,0.7000008 c0.5999985-1.2999992,1-2.7000008,1.5-4.0999985C109.5999985,35.2999992,109.5999985,32.2000008,109.5999985,29.2000008z"
      />
      <radialGradient
        id="SVGID_14_"
        cx="105.539"
        cy="119.362"
        r="16.681"
        gradientTransform="matrix(1 0 0 -1 0 152)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={colors[2]} stopOpacity="0.5" />
        <stop offset="1" stopColor={colors[3]} stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_14_)" cx="105.5" cy="32.6" r="16.7" />
      <g fill="#FFF">
        <path
          opacity="0.18"
          d="M105.0999985,49.2999992L105.1999969,49 c-1.3000031,0.7999992-2.5999985,1.5999985-3.8000031,2.4000015l-0.0999985,0.4000015 C102.5,51,103.8000031,50.2000008,105.0999985,49.2999992z"
        />
        <path
          opacity="0.18"
          d="M104.5999985,51.0999985V51 c-1.1999969,0.5999985-2.4000015,1.2000008-3.5999985,1.7000008V53 C102.1999969,52.4000015,103.4000015,51.7999992,104.5999985,51.0999985z"
        />
        <path
          opacity="0.18"
          d="M105.5999985,47l0.5-1.9000015 c0,0-1-0.9000015-3.5-0.7000008L101.5,50.1999969C102.9000015,49.0999985,104.3000031,48,105.5999985,47z"
        />
      </g>
      <g opacity="0.98">
        <path
          fill={colors[1]}
          d="M105.1999969,46.0999985c-1-0.5-1.9000015-1.0999985-2.6999969-1.7999992 c-0.1999969-0.5-0.4000015-0.9000015-0.5-1.4000015c-0.4000015-1.2000008-0.8000031-2.4000015-1.0999985-3.7000008 C101,38.7999954,101,38.3999977,101.0999985,38.0999985c0.0999985-0.5,0.3000031-0.9000015,0.4000015-1.4000015 c0.1999969-0.7000008,0.4000015-1.4000015,0.5999985-2.0999985l0.5-0.0999985l-0.3000031-0.5999985v-0.0999985 c1.1999969-1.2000008,1.8000031-2.5,2-3.8999996c0.0999985-0.5,0.0999985-1,0-1.5l0.3000031-0.1000004 c0.0999985,0.3999996,0.1999969,0.7000008,0.3000031,1c0.3000031,1.1000004,0.8000031,2.2000008,1.1999969,3.2000008 c0.5,1.2000008,1,2.4000015,1.4000015,3.5999985c0.8000031,3,0.6999969,6.0999985-0.5999985,9.6000023 C106.4000015,45.7999992,105.8000031,46,105.1999969,46.0999985z"
        />
        <path
          fill={colors[6]}
          d="M105.1999969,46.2000008c-0.8000031-0.4000015-1.5999985-0.9000015-2.3000031-1.5 c-0.1999969-0.4000015-0.3000031-0.7999992-0.4000015-1.2000008c-0.4000015-1.0999985-0.8000031-2.2999992-1.0999985-3.5 c0.0999985-0.2999992,0.0999985-0.5999985,0.1999969-0.9000015c0.0999985-0.5,0.3000031-0.9000015,0.4000015-1.2999992 c0.1999969-0.5999985,0.4000015-1.2000008,0.5-1.9000015l0.8000031-0.2000008l-0.4000015-1 c1-1.0999985,1.5999985-2.2999992,1.9000015-3.5c0.3000031,0.7000008,0.5999985,1.4000015,0.9000015,2.0999985 c0.5,1.2000008,1,2.4000015,1.3000031,3.5c0.8000031,2.9000015,0.5999985,5.7000008-0.5,9.0999985 C106,46,105.5999985,46.0999985,105.1999969,46.2000008z"
        />
        <path
          fill={colors[7]}
          d="M105.0999985,46.2000008c-0.6999969-0.4000015-1.3000031-0.7999992-1.9000015-1.2000008 c-0.0999985-0.2999992-0.1999969-0.5999985-0.4000015-1c-0.4000015-1-0.6999969-2.0999985-1-3.2999992 c0-0.2999992,0.0999985-0.5,0.1999969-0.7000008c0.0999985-0.5,0.3000031-0.9000015,0.4000015-1.2999992 c0.0999985-0.5,0.3000031-1,0.5-1.5999985l1.0999985-0.2999992l-0.5-1.2999992c0.5-0.7000008,1-1.2999992,1.3000031-2.0999985 c0.0999985,0.2000008,0.1999969,0.4000015,0.3000031,0.5999985c0.5,1.2000008,1,2.2999992,1.3000031,3.4000015 C107.0999985,40.1000061,107,42.8000069,106,45.9000053C105.5999985,46.0999985,105.4000015,46.2000008,105.0999985,46.2000008z"
        />
        <path
          fill={colors[8]}
          d="M105.0999985,46.2000008c-0.5-0.2999992-1-0.5999985-1.4000015-0.9000015 c-0.0999985-0.2000008-0.1999969-0.5-0.3000031-0.7999992c-0.4000015-1-0.6999969-2-1-3.0999985 c0-0.2000008,0.0999985-0.4000015,0.0999985-0.5C102.5999908,40.5,102.7999954,40,102.8999939,39.6000023 c0.0999985-0.4000015,0.3000031-0.9000015,0.4000015-1.2999992l1.4000015-0.4000015l-0.5999985-1.5 C104.2999954,36.1000023,104.5,35.8000031,104.6999969,35.5c0.4000015,1,0.8000031,1.9000015,1,2.7999992 c0.6999969,2.5,0.5999985,5-0.3000031,7.9000015C105.3000031,46.2000008,105.1999969,46.2000008,105.0999985,46.2000008z"
        />
        <path
          fill={colors[9]}
          d="M104.9000015,46c-0.3000031-0.2000008-0.5999985-0.4000015-0.8000031-0.5 C104,45.2999992,104,45.0999985,103.9000015,45c-0.3000031-0.9000015-0.5999985-1.7999992-0.9000015-2.7999992 c0-0.0999985,0.0999985-0.2000008,0.0999985-0.2999992C103.1999969,41.5,103.2999954,41,103.5,40.6000023 c0.0999985-0.2999992,0.1999969-0.7000008,0.3000031-1.0999985l1.4000015-0.4000015 C105.6999969,41.2000008,105.5999985,43.4000015,104.9000015,46z"
        />
        <path
          fill={colors[10]}
          d="M105,46.2999992c0-0.0999985-0.0999985-0.2000008-0.0999985-0.2999992 c-0.3000031-0.7999992-0.5999985-1.7000008-0.8000031-2.5999985c0-0.0999985,0-0.0999985,0-0.2000008 c0.0999985-0.4000015,0.1999969-0.9000015,0.4000015-1.2999992c0.0999985-0.2999992,0.1999969-0.5,0.1999969-0.7999992 l0.5999985-0.2000008C105.5999985,42.5999985,105.5,44.4000015,105,46.2999992L105,46.2999992z"
        />
      </g>
    </g>
  )
}

export default TorchL
