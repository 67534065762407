import React from "react"

const MaceL = ({
  colors = ["#58595A", "#B9BABA", "#767575", "#9C9D9E", "#E1E2E3", "#E4E4E5"]
}) => {
  return (
    <g id="MaceL">
      <path
        opacity="0.53"
        d="M68,91.1l0.8-1.5l0.1-0.3L69,88v-0.2l9.2-23.5c0.3,0.1,0.6,0.1,0.6,0.1 l0.5-0.3l0.5-1.3c0.2-0.2,1-1.1,3.1-1.3l0.2-0.6l0.2-0.1l0,0h0.1l-0.1-0.1l0,0v-0.1c-0.1-0.1-0.1-0.4,0.1-1c0.2-0.5,0.5-1,0.5-1 l0,0l0.1-0.1v-0.4l-1.8-4.2l-0.4-0.3c0,0-0.1,0-0.1-0.1l0.3-1.3l-0.9-0.4L80.4,53l0,0l-0.6-0.1l-4.3,1.7L75.2,55l0,0l0,0l0,0l0,0 c0,0-0.1,0.6-0.3,1.1c-0.2,0.6-0.5,0.8-0.5,0.8l0,0V57v0h-0.2l0.1,0.2l-0.1,0.1l-0.3,0.5l0.1-0.1l-0.1,0.2c1.4,1.5,1.4,2.7,1.4,3 l-0.5,1.3l0.2,0.6l0,0c0,0,0.2,0.1,0.5,0.3l-9.2,23.5l-0.9,1.2L65.3,88l0,0v0.1l-0.4,1.7l0.2,0.5c0.1,0.1,0.8,0.5,1.2,0.7 c0.4,0.2,1.3,0.3,1.4,0.3L68,91.1z"
      />
      <path
        fill={colors[0]}
        d="M75.7,55.2l4.2-1.8c0.3,0.2,0.5,0.1,0.7,0l0.8-1.3l-0.3,1.5c0.1,0.3,0.2,0.4,0.6,0.5l1.8,4.2 c0,0-1.3,2.1-0.6,2.8c-2.6,0.2-3.5,1.5-3.5,1.5L78.9,64L78,63.9l-9.4,24l-0.1,1.6L67.7,91l-1.3-0.3L65.2,90l0.4-1.7l1-1.2l9.4-24 l-0.8-0.6l0.5-1.4c0,0,0.2-1.5-1.5-3.5C75.2,57.6,75.7,55.2,75.7,55.2z"
      />
      <path
        fill={colors[1]}
        d="M74.2,57.7c1.7,1.9,1.5,3.5,1.5,3.5l-0.5,1.4l0.8,0.6l-9.4,24l-1,1.2L65.2,90c0,0,0.7,0.5,1.1,0.6 c0.4,0.2,1.3,0.3,1.3,0.3l0.8-1.5l0.1-1.6l9.4-24l0.9,0.1l0.5-1.4c0,0,0.9-1.3,3.5-1.5c-0.7-0.7,0.6-2.8,0.6-2.8L81.6,54 c-0.3,0-0.5-0.2-0.6-0.5l0.3-1.5l-0.8,1.3c-0.2,0.1-0.5,0.2-0.7,0l-4.2,1.8C75.6,55.3,75.2,57.7,74.2,57.7z M78.4,62l1.9-2.4 l0.8-2.2l0.3-2.8l1,3.4l-0.8,2.2l-2.8,1.9L78.4,62z M75.8,58l0.9-2.2l3-1.8L78,56.2l-0.9,2.2l-0.2,3l-0.4-0.2L75.8,58z"
      />
      <path
        fill={colors[2]}
        d="M75.2,62.6c0,0,0.7,0.6,1.7,1s1.9,0.5,1.9,0.5l0.5-1.3c0,0-1.4-0.4-1.9-0.6c-0.5-0.2-1.8-0.8-1.8-0.8 L75.2,62.6z"
      />
      <path
        fill={colors[2]}
        d="M65.7,88.3c0,0,0,0.3,1.2,0.8s1.5,0.3,1.5,0.3l0.2-1.6c0,0-0.5,0.1-1-0.1s-0.9-0.6-0.9-0.6L65.7,88.3z"
      />
      <g>
        <path
          fill={colors[3]}
          d="M75.9,58.1l0.7,3.1l0.4,0.2l0.2-2.8l2.9,1.1l-1.7,2.2l0.4,0.2l2.6-1.8l1.3,0.5c0,0.1,0.1,0.2,0.1,0.2 c-2.6,0.2-3.5,1.5-3.5,1.5l-0.5,1.4l0,0l0.5-1.3c0,0-1.4-0.4-1.9-0.6c-0.5-0.2-1.8-0.8-1.8-0.8l-0.5,1.3l0,0l0.5-1.4 c0,0,0.2-1.5-1.5-3.5c0.1,0,0.2,0,0.3-0.1L75.9,58.1z"
        />
        <path
          fill={colors[4]}
          d="M82.4,57.9L82.4,57.9l-1-3.4l-0.3,2.8v0.1l-3-1.2l0.1-0.1l1.7-2.2l-3,1.8l0,0l-1.1-0.4 c0-0.1,0-0.1,0-0.1l4.2-1.8c0.3,0.2,0.5,0.1,0.7,0l0.8-1.3l-0.3,1.5c0.1,0.3,0.2,0.4,0.6,0.5l1.8,4.2c0,0,0,0-0.1,0.1L82.4,57.9 z"
        />
        <polygon
          fill={colors[5]}
          points="77,63.7 67.8,87.6 67.3,87.5 76.6,63.7"
        />
      </g>
    </g>
  )
}

export default MaceL
