import React from "react"

const LongSide = ({ colors = ["#D96842"] }) => {
  return (
    <g id="LongSide">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={colors[0]}
        d="M59.6219635,33.5417633 c0.5246239-1.8116989,2.387207-4.1415749,2.546627-7.7373829c0.1255684-2.8330975-0.3742371-4.8331776-0.2437439-8.3828011 c0.0491867-1.3389549,0.1334648-2.7285881,0.7594566-4.1793079c0.346817-0.8037844,0.774437-2.2640905,0.6003532-2.8298206 c-0.1313324-0.4268198-0.1681709-0.7575493-0.0985107-1.2147741c0.5253296-3.4473262,4.2842827-3.2982793,5.6715126-3.2549086 c1.6152496,0.0504885,3.3222885,0.162406,4.3375626,2.0640049c0.2943726,0.551342,0.4508286,1.1151352,0.4323883,1.7793045 c-0.026268,0.9463329,0.1687546,1.1365042,0.7779083,2.3408699c0.7854538,1.5528774,0.6512375,3.8661928,0.5690002,5.578598 c-0.1850586,3.8556576-2.0415573,5.3111-2.0410614,5.3103466c1.1459732-1.8392277,1.2535477-4.0508862,1.1024246-5.7715225 c-0.2593918-5.317584-3.9041824-4.9722576-5.3175812-4.9958382c-2.9801712-0.0496302-4.474556,1.3643169-4.8636475,3.4394722 c-0.1298561,0.9460049-0.3519211,4.3042603-0.0648499,5.83638c0.11092,2.0409088,0.3625183,2.4248104,0.78022,3.0143051 c0.1444473,0.2064362,1.0009537,1.1148872,1.3427505,1.4363327c0.0648422,3.1451569,6.1069641,7.3092995,1.4540482,12.5292053 c0.4509201-1.0045204,0.7192383-1.4602165,0.6568832-3.0688667c-0.4612198,2.0160713-0.783699,4.0602188-2.971283,4.770401 c0.737793-1.1256142,0.2729874-2.0506287,0.0343628-3.5238152c0.2433014,3.9997673-1.8065872,4.2018242-1.4564629,6.9167747 C60.4444923,41.1276016,58.5390015,37.2815971,59.6219635,33.5417633z"
      />
    </g>
  )
}

export default LongSide
