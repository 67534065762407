import React from "react"

const LongPants = ({ colors = ["#7A695C"] }) => {
  return (
    <g id="LongPants">
      <path
        fill={colors[0]}
        d="M38.2320786,79.0259323c-0.0186081,0.1488876-0.9678879,6.8591232-1.0862694,12.3320847 c-0.0882034,4.0778961,0.6437149,7.3490906,0.6437149,7.3490906s-1.5020027,2.6285019-1.3947144,4.1305084 c0.1072845,1.5019989,1.3410721,9.7630234,1.4483604,10.4067383c1.5020027,0,5.7934418,0,5.7934418,0l0.6437149-6.8126602 l0.4827881-3.9159393l2.5748634-11.21138h3.0576477c0,0,1.4741249,5.4234543,2.253006,11.0236282 c0.1897087,1.3640137,0.3735504,3.1667175,0.5364304,4.4255524c0.4743805,3.6662979,0.5852623,6.451828,0.6437149,6.490799 c0.1609306,0.1072845,5.6861572,0,5.6861572,0s1.5020065-9.0120239,1.5020065-11.2113876S59.40765,98.7607498,59.40765,98.7607498 s1.0192184-5.0424423,1.126503-7.9928055c0.1072845-2.9503708-0.9119301-11.8014603-0.9119301-11.8014603L38.2320786,79.0259323z"
      />
    </g>
  )
}

export default LongPants
