import React from "react"
import { withSkinColor } from "../../builderConnector"

const HeadBase = ({ skinColor, color = [] }) => {
  return (
    <g id="HeadBase">
      <path
        id="headBase"
        fill={skinColor}
        d="M59.9,41.4c-0.1-0.2-2-1-2.8,1.1v0.1L56,39.3c-1.1-1.9-3.2-4.2-5.9-4.2h-1.6h-0.1h-1.6 c-2.7,0-4.8,2.2-5.9,4.2l-1.1,3.3v-0.1c-0.7-2.1-2.7-1.3-2.8-1.1c-0.3,0.9-0.6,1.2,0.3,3.8c0.3,0.6,1.2,0.5,1.7,0.3 c0.2,3.8,2.7,6.8,7,6.8c0,0,1.1,1,2.4,1s2.5-1,2.5-1c4.3-0.7,6.8-3.5,7-6.8c0.5,0.2,1.4,0.3,1.7-0.3 C60.5,42.6,60.2,42.3,59.9,41.4z"
      />
    </g>
  )
}

export default withSkinColor(HeadBase, { character: "undefined" })
