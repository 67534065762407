import React from "react"

const ScrollRight = ({
  colors = ["#4D391B", "#805F2D", "#B0823D", "#CBA02C"]
}) => {
  return (
    <g id="ScrollRight">
      <polygon
        fill={colors[0]}
        points="30.3999996,99.8000031 39.4000015,76.5 41.2000008,76.5999985 40.9000015,93.3000031 39.2999992,93.5999985 39.4000015,80.5999985 32,98.6999969"
      />
      <path
        fill={colors[1]}
        d="M31.2999992,102.5c0.5,0,11.2999992-6.8000031,11.2999992-6.8000031s1.2000008-0.5,1.0999985-1.8000031 c-0.0999985-1.3000031-1.0999985-1.8000031-2.0999985-1.6999969c-1,0.0999985-11.5,7.8000031-11.5,7.8000031 s-0.3999996,0.5999985-0.2000008,1.5C30,102.3000031,30.7999992,102.5,31.2999992,102.5z"
      />
      <path
        fill={colors[2]}
        d="M40.4000015,92.5c0,0-0.7999992,1.0999985-0.2999992,2.5 c0.4000015,1.5,2.0999985,1.4000015,2.0999985,1.4000015l1.7000008-1.0999985l-0.2999992-2.0999985L42,91.9000015L40.4000015,92.5 z"
      />
      <path
        fill={colors[3]}
        d="M42.0999985,91.8000031c0,0-1.2000008,0.9000015-0.7000008,2.4000015 s2.4000015,1.0999985,2.4000015,1.0999985l-0.2999992-2l-0.7000008,1.5999985l-1.0999985-0.8000031L42,92.6999969l1.2999992,0.5 L42.0999985,91.8000031z"
      />
      <path
        fill={colors[0]}
        d="M31,99.3000031c0,0-0.8999996,0.9000015-0.2999992,1.8000031s1.4000015,0.9000015,1.4000015,0.9000015 l-0.3999996,0.3000031c0,0-1.2999992,0-1.5-1s0.2999992-1.9000015,0.2999992-1.9000015L31,99.3000031z"
      />
      <path
        fill={colors[2]}
        d="M30.7999992,99.3000031c0,0-0.7999992,1-0.2000008,2s1.3999996,0.9000015,1.3999996,0.9000015 l-0.7000008,0.4000015c0,0-1,0-1.5-0.9000015s0.2000008-1.8000031,0.2000008-1.8000031L30.7999992,99.3000031z"
      />
    </g>
  )
}

export default ScrollRight
