import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WarhammerL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WarhammerL") ? (
    <g id="WarhammerL">
      <path
        id="S_x24_warhammerL"
        d="M73.8000031,74.5l-4.9000015-3.3000031c-5-3.3000031-6.4000015-10.2000008-3-15.2000008 l1.4000015-2.2000008c3.3000031-5,10.1999969-6.4000015,15.1999969-3l4.9000015,3.2999992 c5,3.2999992,6.4000015,10.2000008,3,15.2000008L89,71.5C85.6999969,76.5,78.8000031,77.9000015,73.8000031,74.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WarhammerL, {
  character: "ManHalfling"
})
