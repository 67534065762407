import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Tunic from "./Tunic"
import BreastPlate from "./BreastPlate"
import Vest from "./Vest"
import CrusaderTunic from "./CrusaderTunic"
import WizardRobe from "./WizardRobe"
import Sash from "./Sash"

const CATEGORY = "torso"
const CHARACTER = "ManMixed"
export const IDS = {
  TUNIC: "Tunic",
  BREAST_PLATE: "BreastPlate",
  VEST: "Vest",
  CRUSADER_TUNIC: "CrusaderTunic",
  WIZARD_ROBE: "WizardRobe",
  SASH: "Sash"
}

export const components = {
  [IDS.TUNIC]: Tunic,
  [IDS.BREAST_PLATE]: BreastPlate,
  [IDS.VEST]: Vest,
  [IDS.CRUSADER_TUNIC]: CrusaderTunic,
  [IDS.WIZARD_ROBE]: WizardRobe,
  [IDS.SASH]: Sash
}

export const Group = ({ props }) => (
  <g id="torso">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.TUNIC,
    name: "Tunic",
    defaultColors: ["#DDD4BA", "#262261", "#604A23"],
    colorable: true,
    component: components[IDS.TUNIC],
    inUI: true,
    enabled: true,
    subGroupId: 0
  },
  {
    id: IDS.BREAST_PLATE,
    name: "Breast Plate",
    defaultColors: ["#898888", "#FFFFFF", "#262261"],
    colorable: true,
    component: components[IDS.BREAST_PLATE],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.VEST,
    name: "Vest",
    defaultColors: ["#775217", "#262261"],
    colorable: true,
    component: components[IDS.VEST],
    inUI: true,
    enabled: false,
    subGroupId: 5
  },
  {
    id: IDS.CRUSADER_TUNIC,
    name: "Crusader Tunic",
    defaultColors: ["#B2230B", "#CECECE", "#262261", "#898888"],
    colorable: true,
    component: components[IDS.CRUSADER_TUNIC],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.WIZARD_ROBE,
    name: "Wizard Robe",
    defaultColors: ["#40706A", "#9B883A"],
    colorable: true,
    component: components[IDS.WIZARD_ROBE],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.SASH,
    name: "Sash",
    defaultColors: ["#9B883A"],
    colorable: true,
    component: components[IDS.SASH],
    inUI: true,
    enabled: false,
    subGroupId: 4
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
