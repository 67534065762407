import React from "react"

const FireFistsL = ({
  colors = [
    "#CC9900",
    "#CC7900",
    "#CC6500",
    "#E15000",
    "#F24000",
    "#FC3600",
    "#FF3300",
    "#SVGID_"
  ]
}) => {
  return (
    <g id="FireFistsL">
      <linearGradient
        id="SVGID_60_"
        gradientUnits="userSpaceOnUse"
        x1="88.525"
        y1="684.512"
        x2="99.495"
        y2="702.01"
        gradientTransform="translate(0 -640)"
      >
        <stop offset="0" stopColor="#c90" stopOpacity="0" />
        <stop offset="0.152" stopColor="#cc7900" stopOpacity="0.449" />
        <stop offset="0.271" stopColor="#cc6500" stopOpacity="0.8" />
        <stop offset="0.415" stopColor="#e15000" stopOpacity="0.8" />
        <stop offset="0.584" stopColor="#f24000" stopOpacity="0.8" />
        <stop offset="0.769" stopColor="#fc3600" stopOpacity="0.8" />
        <stop offset="1" stopColor="#f30" stopOpacity="0.8" />
      </linearGradient>
      <path
        fill="url(#SVGID_60_)"
        d="M93.3,45.4c2.6,3.5,5.4,6.4,8.1,8.2c1.1,1.9-0.1,7.5-1.1,8.2c-1,0.7-5.4,2.7-8,0 c0,0-0.7-3.6-0.7-4.1s-5-10-5-10C86,44.3,90.4,41.5,93.3,45.4z"
      />
    </g>
  )
}

export default FireFistsL
