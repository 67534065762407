import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const RoundWoodShieldLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("RoundWoodShieldLeft") ? (
    <g id="RoundWoodShieldLeft">
      <path
        id="S_x24_roundWoodShieldLeft"
        d="M69.5999985,99.8000031c-8.9000015,0-16.7000008-5.5999985-19.5999985-14 C48.2000008,80.5999985,48.5999985,75,51,70s6.7000008-8.7000008,11.9000015-10.4000015C65,58.8999977,67.3000031,58.5,69.5,58.5 c8.9000015,0,16.6999969,5.5999985,19.5999985,14C92.7999954,83.3000031,87,95.0999985,76.1999969,98.6999969 C74.0999985,99.4000015,71.8000031,99.8000031,69.5999985,99.8000031L69.5999985,99.8000031z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(RoundWoodShieldLeft, {
  character: "ManHalfling"
})
