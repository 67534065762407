import React from "react"

const LeatherArmor = ({ colors = ["#754C29", "#603913", "#8B5E3C"] }) => {
  return (
    <g id="LeatherArmor">
      <path
        fill={colors[0]}
        d="M65.3,54.1l-9-1.2l-7.8,3.2l-8.6-3.2l-9.2,1.4c0,0,2.4,3.7,2.7,7.5s-0.5,5.6-0.5,5.6l1.8,7l-2.5,13.2 L47,88.8l0.9-8.6l0.9,8.6l15.7-1.2L62,75l1.7-8.6c0,0-1-0.8-0.6-4.6C63.5,57.9,65.3,54.1,65.3,54.1z"
      />
      <path
        fill={colors[1]}
        d="M39.6,76.2c0,0-0.1-6,8.4-6s9.8,6,9.8,6l-9.7,4L39.6,76.2z"
      />
      <path
        fill={colors[1]}
        d="M48.5,60.2L48.5,60.2h-0.3h-0.3l0,0l-11.8,0.4l0.9,8.9c0,0,0,1,6.6,3.1c1.7,0.5,3.5,0,4.6-0.6 c1.1,0.5,2.9,1.1,4.6,0.6c6.6-2.1,6.6-3.1,6.6-3.1l0.9-8.9L48.5,60.2z"
      />
      <path
        fill={colors[2]}
        d="M48.7,57.5L48.7,57.5h-0.3h-0.3l0,0l-12,2L35.8,68c0,0,0,1.1,7.4,3.5c1.9,0.6,3.9,0,5.1-0.6 c1.3,0.6,3.3,1.3,5.1,0.6c7.4-2.4,7.4-3.5,7.4-3.5l0.2-8.5L48.7,57.5z"
      />
      <path
        fill={colors[2]}
        d="M39.6,77.2c0,0-0.1-4.5,8.4-4.5s9.8,4.5,9.8,4.5l-9.7,3L39.6,77.2z"
      />
      <polygon
        fill={colors[2]}
        points="35.5,74.5 48,80.2 61.4,74.5 64.4,88.5 55.7,89.3 54.8,78.2 54.5,89.3 50.3,88.9 48.9,88.8 48.4,84.2 47.1,88.8 41.3,89.2 41.7,78.5 39.9,89.2 32.5,88.5"
      />
      <polygon
        fill={colors[1]}
        points="35.5,74.5 48.5,79.3 61.4,74.5 48.5,80.4"
      />
      <polygon
        fill={colors[1]}
        points="46.5,57.8 48.5,69.5 50.4,57.9 48.6,56.3"
      />
    </g>
  )
}

export default LeatherArmor
