import React from "react"

const LongBowL = ({ colors = ["#5F4F2D", "#20376C", "#FFFFFF"] }) => {
  return (
    <g id="LongBowL">
      <path
        fill={colors[0]}
        d="M79.1175232,7.5688348c0.3840103-0.7742205,2.4991531-4.0662103,2.87677-2.6813745 c-1.9262466,1.9386539-0.9947205,3.5815949-0.0694962,5.3378396c0.8182297,1.6366415,9.9070816,13.9619503,9.7356339,45.6616821 c0.1133041,0.0062981,0.1133041,0.0062981,0.2266006,0.0125999c-0.0881958,1.5862427-0.1700974,3.0591812-0.3652954,4.5258179 c-0.0819016,1.4729385-0.050499,2.9521751-0.1387024,4.5384216c-0.1132965-0.0063019-0.1132965-0.0063019-0.2266006-0.0126038 c-3.350235,31.6366348-13.743187,42.7650375-14.7378159,44.3008957 c-1.1142273,1.6428452-2.2221603,3.1723862-0.5227051,5.3126297c-0.6421127,1.3281326-2.2659607-2.1717529-2.5617676-2.9837723 c11.3372726-13.8035736,14.5362167-40.6751862,15.474617-51.4200706 C89.168045,49.6111183,88.8545914,22.5442123,79.1175232,7.5688348z"
      />
      <path
        fill={colors[1]}
        d="M80.0742416,8.758564c0.037796-0.6798182,0.5099106-0.9945264,0.8813171-1.5421414 c0.0063019-0.1133032,0.0943985,0.346209,0.0943985,0.346209c-0.0125961,0.2266059-0.0315018,0.566515-0.037796,0.6798182 c0,0-0.0063019,0.1133032-0.119606,0.1070032c0,0,0,0-0.0062943,0.1133041l-0.0063019,0.1133032 c0.2454987-0.3273096,0.4910126-0.6546183,0.6232147-0.9882264c0.0063019-0.1133037,0.0943985,0.346209,0.0943985,0.346209 c-0.0063019,0.1133032-0.0125961,0.2266054-0.018898,0.3399086c0.1195984-0.1070032,0.1259003-0.2203054,0.1322021-0.3336086 c0.0063019-0.1133037,0.0943985,0.3462086,0.0943985,0.3462086c-0.0063019,0.1133032-0.0125961,0.2266064-0.0251999,0.4532118 c0.1259003-0.2203064,0.2455063-0.3273096,0.3714142-0.5476151c0,0-0.0251999,0.4532118-0.0315018,0.566515 s-0.0251999,0.4532118-0.0315018,0.566515c-0.2454987,0.3273096-0.4910126,0.6546183-0.7365112,0.9819269 c-0.0063019,0.1133032-0.0251999,0.4532108-0.138504,0.4469118c-0.1195984,0.1070032-0.2329025,0.1007032-0.3525085,0.2077055 c0,0,0.0125961-0.2266064,0.0251999-0.4532118c-0.0063019,0.1133032-0.0063019,0.1133032-0.0063019,0.1133032 l-0.1195984,0.1070042l0.0251999-0.4532118c0.0125961-0.2266054,0.0251999-0.4532118,0.0314941-0.566515l0,0 c-0.1259003,0.2203054-0.2454987,0.3273096-0.3714066,0.5476151c-0.0063019,0.1133032-0.0944061-0.3462095-0.0944061-0.3462095 c0.0063019-0.1133032,0.018898-0.3399086,0.0251999-0.4532118c-0.0063019,0.1133041-0.1259003,0.2203064-0.1259003,0.2203064 s0.0126038-0.2266054-0.0943985-0.3462095l-0.0063019,0.1133041c-0.0063019,0.1133032-0.018898,0.3399086-0.1259003,0.2203054 C80.036438,9.4383821,80.0679398,8.8718672,80.0742416,8.758564z"
      />
      <path
        fill={colors[1]}
        d="M74.2783585,110.9515457c-0.037796,0.6798172,0.2832108,1.0386276,0.5916214,1.6240463 c-0.0063019,0.1132965,0.018898-0.3399124,0.018898-0.3399124c0.0125961-0.2266006,0.0315018-0.5665131,0.037796-0.6798172 l0.0063019-0.1133041c0,0,0,0,0.0063019-0.1132965l0.0063019-0.1133041 c0.0943985,0.3462143,0.3021088,0.6987152,0.3965073,1.0449295c-0.0062943,0.1133041,0.0189056-0.3399124,0.0189056-0.3399124 c0.0062943-0.1133041,0.0125961-0.2266006,0.018898-0.3399124c-0.0063019,0.1133041,0.1007004,0.2329025,0.0943985,0.3462143 c-0.0062943,0.1132965,0.0189056-0.3399124,0.0189056-0.3399124c0.0062943-0.1133041,0.0125961-0.2266083,0.0251999-0.4532166 c0.1007004,0.2329025,0.2077026,0.3525162,0.3084106,0.5854187c0,0,0.0251999-0.4532166,0.0315018-0.5665207 c0.0062943-0.1132965,0.0251999-0.4532089,0.0314941-0.5665131c-0.2077026-0.3525085-0.3021088-0.6987152-0.5098114-1.0512238 c0.0063019-0.1133041,0.0251999-0.4532166,0.0251999-0.4532166c-0.1070023-0.1195984-0.2203064-0.1259003-0.3273163-0.2455063 c0,0-0.0125961,0.2266083-0.0251999,0.4532166c0.0063019-0.1133041,0.0063019-0.1133041,0.0063019-0.1133041 s0.0063019-0.1132965-0.1070023-0.1195984l-0.0251999,0.4532166c-0.0125961,0.2266006-0.0251999,0.4532089-0.0315018,0.5665131 l0,0c-0.1007004-0.2329025-0.2077026-0.3525162-0.1950989-0.5791168c0.0063019-0.1132965-0.018898,0.3399124-0.018898,0.3399124 c-0.0063019,0.1133041-0.0189056,0.3399124-0.0251999,0.4532166c0.0062943-0.1133041-0.1007004-0.2329025-0.1007004-0.2329025 s-0.0126038,0.2266006-0.0189056,0.3399124l0.0063019-0.1133041c0.0063019-0.1132965,0.018898-0.3399124-0.1007004-0.2329025 C74.202858,110.2654343,74.2909622,110.7249451,74.2783585,110.9515457z"
      />
      <g opacity="0.8" fill="#FFF">
        <path
          opacity="0.23"
          d="M81.7296829,9.6461849 c-0.2454987,0.3273087-0.4910126,0.6546183-0.7365112,0.981926c-0.509819-1.0512257-1.1329346-2.1087532-1.7560501-3.1662793 c0.3840103-0.7742205,2.4991608-4.0662107,2.87677-2.681375C80.307251,6.6121082,80.9051666,8.1228456,81.7296829,9.6461849z"
        />
        <path
          opacity="0.23"
          d="M83.1268311,15.1792269 c-0.1007004-0.2329063-0.3147125-0.4721107-0.4154129-0.705018c-0.5035172-1.1645298-1.0070343-2.3290596-1.5168457-3.3802853 c0.2454987-0.3273096,0.4973145-0.7679205,0.7428131-1.0952301c-0.0062943,0.1133032,0.1070023,0.1196032,0.1007004,0.2329054 c0.3147125,0.4721117,1.3595428,2.1213541,2.7819824,5.1554308c-0.3651123,0.4343119-0.730217,0.8686237-0.9820251,1.3092365 C83.5296478,16.1108494,83.3282394,15.6450386,83.1268311,15.1792269z"
        />
        <path
          opacity="0.23"
          d="M83.932457,17.0424747 c0.3651123-0.4343109,0.6232147-0.9882259,0.988327-1.4225368c0.4028168,0.9316235,0.8056259,1.8632469,1.3091431,3.0277767 c-0.4721146,0.3147087-0.8372192,0.7490234-1.3093262,1.0637302 C84.5177765,18.7798195,84.2282715,17.854496,83.932457,17.0424747z"
        />
        <path
          opacity="0.23"
          d="M87.6580811,33.8430099 C87.6580811,33.8430099,87.7713852,33.849308,87.6580811,33.8430099 c0.8309174-0.6357155,1.5359421-1.0511284,2.1339569-1.5861435c0.1762009,0.9190216,0.352417,1.8380432,0.522316,2.8703728 c-0.7050171,0.4154129-1.5233383,0.8245239-2.2283554,1.2399368 C87.9034958,35.5614548,87.8342972,34.7620316,87.6580811,33.8430099z"
        />
        <path
          opacity="0.23"
          d="M88.6208267,41.0566978 c-0.0629044-0.9127235-0.232811-1.9450531-0.2957153-2.8577766c0.7050171-0.4154129,1.5170441-0.7112198,1.9891434-1.0259285 c0.1133041,0.0062981,0.119606-0.1070023,0.2329025-0.1007042c0.1699066,1.0323296,0.2202148,2.1716614,0.3901215,3.2039833 c-0.3462143,0.0944023-0.8057175,0.1825066-1.1582336,0.390213 C89.5461426,40.767189,89.1999435,40.8615913,88.6208267,41.0566978z"
        />
        <path
          opacity="0.23"
          d="M86.4435349,27.0699329 c-0.2580109-1.4918385-0.5160141-2.9836788-0.8873291-4.4818153c0.5791168-0.1951084,1.1582336-0.390213,1.7373428-0.5853195 c0.1888046,0.6924191,0.3839188,1.271534,0.5664215,2.0772533c-0.3903122,0.8875217-0.7743149,1.6617451-1.1646271,2.5492668 C86.6953506,26.6293221,86.5694504,26.8496284,86.4435349,27.0699329z"
        />
        <path
          opacity="0.23"
          d="M87.142067,30.8593292 c0.3714142-0.5476151,0.8561172-1.0889301,1.2275314-1.6365452c0.1259003-0.2203064,0.0440979-0.7931213,0.1699982-1.0134258 c-0.4910126,0.6546192-0.9820251,1.3092365-1.4730301,1.9638538c-0.0881042-0.4595108-0.1825027-0.8057213-0.2706146-1.265234 c0.5099106-0.9945259,1.0198288-1.9890518,1.6430435-2.9772816l0.0063019-0.1133022 c0.4657135,1.844347,0.9188232,3.9153004,1.3593445,6.2128601c-0.711319,0.5287132-1.4226379,1.0574303-2.1339569,1.5861435 C87.4944763,32.6973801,87.3182678,31.7783566,87.142067,30.8593292z"
        />
        <path
          opacity="0.23"
          d="M89.1556396,45.7462158 c0.1259079-0.2203064,0.2455063-0.3273087,0.4847183-0.5413132c0.4973145-0.7679214,1.1016235-1.4162407,1.7059402-2.0645599 c0.0755005,0.6861153,0.151001,1.3722382,0.113205,2.0520515c-0.7239151,0.7553215-1.4541321,1.6239471-2.1780548,2.3792686 C89.2059479,46.8855476,89.1241455,46.3127327,89.1556396,45.7462158z"
        />
        <path
          opacity="0.23"
          d="M88.7152252,41.4029045 c0.2329025-0.1007004,0.4658127-0.2014084,0.692421-0.1888084c0.5791092-0.1951065,1.0449219-0.3965111,1.6240387-0.5916138 c0.0818024,0.5728149,0.0503006,1.139328,0.1384048,1.5988426c-0.7239151,0.7553215-1.4478302,1.510643-2.1717606,2.2659607 C88.9417343,43.4612579,88.7718277,42.4289322,88.7152252,41.4029045z"
        />
        <path
          opacity="0.23"
          d="M87.082756,87.1144028 c-0.1448059,0.5602188-0.2896042,1.12043-0.4344101,1.6806488c-0.6043167,0.6483154-1.2086334,1.2966309-1.8129501,1.9449539 C85.5845261,89.5314713,86.3336411,88.322937,87.082756,87.1144028z"
        />
        <path
          opacity="0.23"
          d="M82.7579651,95.3979263 c0.6043091-0.6483154,1.2149277-1.4099426,1.7122421-2.1778564c-0.1259003,0.2202988-0.7050171,0.4154129-1.1708298,0.6168137 c0.0062943-0.1132965,0.1322021-0.3336105,0.1384964-0.4469147c0.2581024-0.5539169,0.6232147-0.9882278,0.7680206-1.548439 c0,0-0.119606,0.1070023-0.2392044,0.2139969c0.1259003-0.2202988,0.138504-0.446907,0.2581024-0.5539093 c0.7239151-0.7553177,1.4478302-1.510643,2.1717529-2.2659607c-0.5540085,1.7876434-1.1017227,3.4619904-1.7564392,5.0167313 c-0.7365112,0.981926-1.58004,1.8442459-2.4235611,2.7065735c-0.1259003,0.2203064-1.1016235,1.4162445-0.3777084,0.6609192 c0.8498154-0.9756317,1.8129425-1.9449615,2.6627579-2.920578c-1.5990295,4.2299118-3.2546692,7.4337921-4.7466049,9.7375641 c-1.1897278,0.9567261-2.3857574,2.0267563-3.5817871,3.0967865c0.7428207-1.0952301,1.3786316-2.3100586,2.0144501-3.5248871 C80.044693,101.2706909,81.454834,98.3941116,82.7579651,95.3979263z"
        />
        <path
          opacity="0.23"
          d="M87.3033524,81.1030502 c0.4847107-0.5413132,1.0827255-1.0763321,1.5737381-1.7309418c0,0,0.0063019-0.1133041,0.119606-0.1070023 c-0.4659119,2.2471542-1.0388184,4.3747177-1.4921341,6.3952637c-0.1195984,0.1070023-0.2391968,0.2140045-0.3588104,0.3210144 c-0.6169128,0.8749237-1.2401276,1.8631439-1.8570404,2.7380753 C86.107132,86.2645874,86.9318466,83.6964111,87.3033524,81.1030502z"
        />
        <path
          opacity="0.23"
          d="M76.5256195,109.3717041 c-0.7428207,1.0952301-1.479332,2.0771561-1.4353333,3.3297882c-0.2454987,0.3273163-0.4910126,0.6546173-0.7365112,0.981926 c-0.4154205-0.7050171-0.8245316-1.5233383-0.9189301-1.869545c0.3651123-0.4343109,0.730217-0.8686218,0.9820251-1.309227 c1.5674438-1.6176453,3.015274-3.1282883,4.6897202-4.6263351 C77.7531509,107.7351608,76.77742,108.9310989,76.5256195,109.3717041z"
        />
        <path
          opacity="0.23"
          d="M89.3317566,48.7109985 c0.7239151-0.7553253,1.4541321-1.6239471,2.1780548-2.3792686c0.0566025,1.0260277,0.1069031,2.1653595,0.1635132,3.1913834 c-0.7176132,0.6420174-1.4415283,1.3973389-2.1654587,2.1526604 C89.4512558,50.6497498,89.3946609,49.6237183,89.3317566,48.7109985z"
        />
        <path
          opacity="0.23"
          d="M89.3498764,64.737114 c0.018898-0.3399048,0.0315018-0.5665131,0.0503998-0.9064217c0.7239151-0.7553215,1.5674438-1.6176453,2.2976608-2.4862671 c-0.0440979,0.7931213-0.0819016,1.4729347-0.1259995,2.2660561c-0.7239151,0.7553253-1.5548401,1.391037-2.2787628,2.1463623 C89.3120804,65.4169312,89.3309784,65.0770187,89.3498764,64.737114z"
        />
        <path
          opacity="0.23"
          d="M89.2742767,66.0967484 c0.7239151-0.7553177,1.5548401-1.391037,2.2787628-2.1463585c-0.0189056,0.3399086-0.0378036,0.679821-0.0503998,0.9064217 l-0.2266083-0.0125961c-0.018898,0.3399048-0.037796,0.6798172-0.1699982,1.0134277 c-0.7176132,0.6420135-1.3219299,1.2903366-2.0332489,1.8190536 C89.0979767,67.2234802,89.242775,66.663269,89.2742767,66.0967484z"
        />
        <path
          opacity="0.23"
          d="M89.022377,68.5831146 c0.7176132-0.6420212,1.3219299-1.2903366,2.0395508-1.9323578c-0.2015076,1.5799408-0.3967056,3.0465851-0.5919113,4.5132141 c-0.730217,0.8686295-1.460434,1.7372513-2.1969604,2.7191772 C88.4871674,72.0766144,88.8082733,70.3896713,89.022377,68.5831146z"
        />
        <path
          opacity="0.23"
          d="M87.5237503,79.183197 c0.0567017-1.0197296,0.2266998-2.0331573,0.3904037-2.9332809c0.730217-0.8686218,1.4541321-1.6239395,2.1843567-2.492569 c-0.1826019,1.2400284-0.4722137,2.3604584-0.6485138,3.4871902c-0.610611,0.7616196-1.2212296,1.5232468-1.8318405,2.2848587 C87.5048523,79.5231094,87.5111542,79.4098053,87.5237503,79.183197z"
        />
      </g>
      <rect
        x="27.804"
        y="59.776"
        transform="rotate(-86.817 78.018 59.833)"
        fill={colors[1]}
        width="100.428"
        height="0.113"
      />
    </g>
  )
}

export default LongBowL
