import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const NobelCape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("NobelCape") ? (
    <g id="NobelCape">
      <g fill="#8D4D3A">
        <path d="M35.3,48.5c-6,18.5-13.5,34.6-23.9,50.9c0,0,6.7-4.2,6.6-0.7l0,0c8.2-16.3,14.1-32.4,18.8-50.2 C36.2,48.5,35.9,48.5,35.3,48.5z" />
        <path d="M61.7,48.5c6,18.6,13.5,34.6,23.9,50.9c0,0-6.7-4.2-6.6-0.7l0,0c-8.2-16.3-14.1-32.4-18.8-50.2 C60.8,48.5,61.1,48.5,61.7,48.5z" />
        <path d="M60.2,48.5c-0.7,0.1-1,0.2-1.7,0.4c-1.7,0.2-2.6,0.3-4.3,0.5c-0.9,0.1-1.4,0.2-2.3,0.3l0,0 c-0.8,0.1-1.2,0.2-2,0.3c-1.1,0-1.7,0-2.9,0c-0.8-0.1-1.2-0.2-2-0.3l0,0c-0.9-0.1-1.4-0.2-2.3-0.3c-1.7-0.2-2.6-0.3-4.3-0.5 c-0.7-0.1-1-0.2-1.7-0.4C32,66.2,26.1,82.4,17.9,98.7c-0.2,3.5,4.2,4.9,6.5,3.3c0.1-0.1,0.3-0.2,0.4-0.3c2.5-1.2,8.2-1,8.6,0.2 c0,0.1,0,0.1,0,0.2c-0.1,1.4,4.3,5.3,9,0.7c0.2-0.2,0.4-0.3,0.6-0.5c0,0,0.1,0,0.1-0.1c0,0.1,0.1,0.1,0.2,0c1.1-0.9,3-2,5.2-2.1 c2.2,0.1,4.2,1.2,5.2,2.1c0.1,0.1,0.1,0,0.2,0c0,0,0.1,0,0.1,0.1l0,0c0.2,0.2,0.4,0.3,0.6,0.5c4.7,4.6,9,0.7,9-0.7 c0-0.1,0-0.1,0-0.2c0.4-1.2,6.1-1.4,8.6-0.2c0.2,0.1,0.3,0.2,0.4,0.3c2.3,1.6,6.7,0.2,6.5-3.3C70.8,82.4,64.9,66.3,60.2,48.5z" />
      </g>
      <path
        opacity="0.35"
        fill="#21376C"
        d="M60.2,48.5c-0.7,0.1-1,0.2-1.7,0.4c-1.7,0.2-2.6,0.3-4.3,0.5 c-0.9,0.1-1.4,0.2-2.3,0.3l0,0c-0.8,0.1-1.2,0.2-2,0.3c-1.1,0-1.7,0-2.9,0c-0.8-0.1-1.2-0.2-2-0.3l0,0c-0.9-0.1-1.4-0.2-2.3-0.3 c-1.7-0.2-2.6-0.3-4.3-0.5c-0.7-0.1-1-0.2-1.7-0.4C32,66.2,26.1,82.4,17.9,98.7c-0.2,3.5,4.2,4.9,6.5,3.3c0.1-0.1,0.3-0.2,0.4-0.3 c2.5-1.2,8.2-1,8.6,0.2c0,0.1,0,0.1,0,0.2c-0.1,1.4,4.3,5.3,9,0.7c0.2-0.2,0.4-0.3,0.6-0.5c0,0,0.1,0,0.1-0.1c0,0.1,0.1,0.1,0.2,0 c1.1-0.9,3-2,5.2-2.1c2.2,0.1,4.2,1.2,5.2,2.1c0.1,0.1,0.1,0,0.2,0c0,0,0.1,0,0.1,0.1l0,0c0.2,0.2,0.4,0.3,0.6,0.5 c4.7,4.6,9,0.7,9-0.7c0-0.1,0-0.1,0-0.2c0.4-1.2,6.1-1.4,8.6-0.2c0.2,0.1,0.3,0.2,0.4,0.3c2.3,1.6,6.7,0.2,6.5-3.3 C70.8,82.4,64.9,66.3,60.2,48.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(NobelCape, { character: "ManDwarf" })
