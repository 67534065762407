import React from "react"

const HalfCircle = ({ colors = [] }) => {
  return (
    <g id="HalfCircle">
      <path d="M101.1,118.1c0,17.6-14.3,31.9-31.9,31.9s-31.9-14.3-31.9-31.9H101.1z" />
    </g>
  )
}

export default HalfCircle
