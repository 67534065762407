import React from "react"

const SilverBraces = ({ colors = ["#BBBDBF", "#9D9D9D"] }) => {
  return (
    <g id="SilverBraces">
      <path
        fill={colors[0]}
        d="M107.2698059,52.5334663c0,0-0.0291138-1.8216209-2.8747711-2.9460869 c-1.5826569-0.6253891-5.6091385,0.8740883-7.2147522,2.2537804c-1.9647522,1.6883011-0.5942459,3.0117798-0.5942459,3.0117798 l1.5586319,4.7724609c0,0-1.3764648-5.9737663,3.42379-5.3408089c4.8002625,0.6329575,3.8116913,4.4881096,3.8116913,4.4881096 s1.0532761-1.2201881,1.5121918-3.5297623S107.2698059,52.5334663,107.2698059,52.5334663z"
      />
      <path
        fill={colors[1]}
        d="M100.845459,53.225441c0.0393066-0.354248,0.0704346-0.7110596,0.0998535-1.0684204 c0.0861816-0.7540283,0.2144775-1.4961548,0.3924561-2.2289429c1.194519-0.3366699,2.3239746-0.4611206,3.0029907-0.1928101 c0.7099609,0.2805786,1.2443848,0.6044922,1.6469727,0.9327393c-0.1871948,1.784729-0.5510864,3.7155151-1.0723267,5.6091309 c-0.5032959-0.8276367-1.4885254-1.5924072-3.4012451-1.8446045c-0.2580566-0.0339966-0.4899292-0.0402832-0.7134399-0.0380859 C100.8137817,54.0041275,100.8186035,53.6097794,100.845459,53.225441z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M30.2761097,51.9043388c0,0,0.0291176-1.8216171,2.8747692-2.9460831 c1.5826607-0.6253929,5.6091461,0.8740845,7.2147522,2.2537766c1.9647522,1.6883011,0.5942459,3.0117798,0.5942459,3.0117798 l-1.5586281,4.7724609c0,0,1.3764648-5.9737625-3.4237938-5.3408051 c-4.8002586,0.6329536-3.8116875,4.4881058-3.8116875,4.4881058s-1.0532799-1.2201881-1.5121918-3.5297623 C30.1946621,52.3042412,30.2761097,51.9043388,30.2761097,51.9043388z"
        />
        <path
          fill={colors[1]}
          d="M36.7004585,52.5963173c-0.0393066-0.354248-0.0704346-0.7110596-0.0998535-1.0684204 c-0.0861816-0.7540283-0.2144775-1.4961548-0.3924561-2.2289429c-1.194519-0.3366699-2.3239746-0.4611206-3.0029907-0.1928101 c-0.7099609,0.2805786-1.2443867,0.6044922-1.6469746,0.9327393c0.1871948,1.784729,0.5510883,3.7155151,1.0723286,5.6091309 c0.5032959-0.8276367,1.4885254-1.5924072,3.4012451-1.8446045c0.2580566-0.0339966,0.4899292-0.0402832,0.7134399-0.0380859 C36.7321358,53.3750038,36.727314,52.9806557,36.7004585,52.5963173z"
        />
      </g>
    </g>
  )
}

export default SilverBraces
