import React from "react"

const Vest = ({ colors = ["#775217", "#262261"] }) => {
  return (
    <g id="Vest">
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          fill={colors[0]}
          d="M61.9,25.9c0,0,1.7-0.4,2.3-0.3c0,0.1,0,0.4,0,0.4 c0,0.1-2,0.7-2.1,0.8c0.6,2.2,3,10.8,5.7,12.5c0.3,5.3,0.6,17.2,0.6,17.2s-8-0.9-10.7-1.9c-1-8.9-2.5-13.4-2.5-13.5 c0.5-0.8-1-9.5-3-10.6c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3-0.5-0.3-1-0.4-1C53.3,28,61.9,25.9,61.9,25.9z"
        />
        <path
          opacity="0.3"
          fill={colors[1]}
          d="M51.2,30.2 c0,0,0.1-0.2,0.9,0.3l-0.6,0.6C51.5,31.1,51.1,30.6,51.2,30.2z"
        />
        <path
          opacity="0.3"
          fill={colors[1]}
          d="M62,26l0.1,0.8 l2.1-0.8l0.1-0.5C64.2,25.6,62.7,25.7,62,26z"
        />
      </g>
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          fill={colors[0]}
          d="M76.4,25.9c0,0-1.7-0.4-2.3-0.3c0,0.1,0,0.4,0,0.4 c0,0.1,2,0.7,2.1,0.8c-0.6,2.2-3,10.8-5.7,12.5C70.2,44.6,70,56.5,70,56.5s8.8-1.3,10.6-2.5C81.9,42,83,41.1,83,41 c-0.5-0.8,1.2-9.4,3.2-10.5c0.2,0.2,0.4,0.4,0.6,0.6c0.3-0.5,0.3-1,0.4-1C85,28,76.4,25.9,76.4,25.9z"
        />
        <path
          opacity="0.3"
          fill={colors[1]}
          d="M87.2,30.2 c0,0-0.1-0.2-0.9,0.3l0.6,0.6C86.9,31.1,87.3,30.6,87.2,30.2z"
        />
        <path
          opacity="0.3"
          fill={colors[1]}
          d="M76.4,26l-0.1,0.8 L74.2,26l-0.1-0.5C74.2,25.6,75.7,25.7,76.4,26z"
        />
      </g>
      <g fill={colors[1]}>
        <path
          opacity="0.21"
          d="M52.7,29.4c0,0,4.1,7.5,3,11.5 C56.2,40.3,56.4,34.9,52.7,29.4z"
        />
        <path
          opacity="0.21"
          d="M85.4,29.2c0,0-4.1,7.5-3,11.5 C81.9,40.1,81.7,34.7,85.4,29.2z"
        />
        <path
          opacity="0.21"
          d="M60.9,26.6c0,0,1.3,11,6,13.2C64.4,39.2,60.8,32.9,60.9,26.6 z"
        />
        <path
          opacity="0.21"
          d="M77.4,26.6c0,0-1.3,11-6,13.2C73.9,39.2,77.5,32.9,77.4,26.6 z"
        />
        <g opacity="0.7">
          <path d="M67.3,41.5c0,0.1-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.2l0,0c0,0.2,0.1,0.3,0.3,0.3 C67.2,41.9,67.4,41.8,67.3,41.5C67.4,41.6,67.3,41.6,67.3,41.5z" />
          <path d="M67.4,44.1c0,0.1-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.2l0,0c0,0.2,0.1,0.3,0.3,0.3 C67.3,44.5,67.4,44.4,67.4,44.1C67.4,44.2,67.4,44.1,67.4,44.1z" />
          <path d="M67.5,46.5c0,0.1-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.2l0,0c0,0.2,0.1,0.3,0.3,0.3 C67.4,46.9,67.6,46.7,67.5,46.5C67.6,46.5,67.5,46.5,67.5,46.5z" />
          <path d="M67.6,48.9c0,0.1-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.2l0,0c0,0.2,0.1,0.3,0.3,0.3 C67.6,49.3,67.7,49.2,67.6,48.9C67.7,49,67.7,49,67.6,48.9z" />
          <path d="M67.7,51.4c0,0.1-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.2l0,0c0,0.2,0.1,0.3,0.3,0.3 S67.8,51.7,67.7,51.4C67.8,51.5,67.7,51.4,67.7,51.4z" />
          <path d="M67.9,53.9c0,0.1-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.2l0,0c0,0.2,0.1,0.3,0.3,0.3 S67.9,54.1,67.9,53.9L67.9,53.9z" />
        </g>
      </g>
    </g>
  )
}

export default Vest
