import React from "react"

const BotaBagRight = ({
  colors = [
    "#715C40",
    "#CDA774",
    "#B09064",
    "#A17955",
    "#C5962C",
    "#EED793",
    "#80682A"
  ]
}) => {
  return (
    <g id="BotaBagRight">
      <path
        fill={colors[0]}
        d="M42.1,79.5c0,0-0.7-0.1-0.4-0.4s22.5-24.9,22.5-24.9s0.4-0.5,0.7-0.2c0.3,0.2,0,0.7,0,0.7L42.1,79.5z"
      />
      <path
        fill={colors[1]}
        d="M36.5,81c0,0-4.3,0.8-4,6.6c0.3,5.8,4.5,5.4,7.5,0.3s3.7-10.5,3.7-10.5l-1.3-1 C42.4,76.4,39.6,81.9,36.5,81z"
      />
      <path
        fill={colors[2]}
        d="M41.8,82.7c1.3-2.8,1.9-5.3,1.9-5.3l-1.3-1c0,0-0.6,1.2-1.1,1.8C41.2,79.8,41.6,81.1,41.8,82.7z"
      />
      <path
        fill={colors[2]}
        d="M37.5,87.3c-0.7-0.7-0.7-1.7,0-2.4c0.1-0.1,0.2-0.1,0.3-0.1c-0.2,0-0.2-0.1,0-0.2 c0.2-0.2,0.6-0.2,0.8-0.2c0.9,0,1.3,0.8,1.8,1.7c0,0.1,0-0.1,0,0c0.3-0.5,0.5-1.1,0.8-1.6c-0.6-1.7-0.7-3.5-0.7-5.4 c-0.5,0.5-1.4,1.2-2,1.7c0.3,0.3,0.6,0,0.6,0.4c-0.1,0.6,0.3,0.9-0.3,1c-0.5,0.1-1,0.2-1.3-0.3c-0.1-0.2-0.2-0.5-0.2-0.8 c-0.3,0-0.6,0-0.9-0.1c0,0-0.1,0-0.2,0c-1.3,2.5-2,4.9-1.9,8.1c0,1,0.1,1.9,0.5,2.7c1.5,0.1,3.4-1.2,5-3.9c0,0-1.1,0-1.1-0.1 C38,88.1,38.2,88,37.5,87.3z"
      />
      <path
        fill={colors[2]}
        d="M34.9,81.7c-1.2,0.8-2.7,2.5-2.5,5.9c0.1,1.3,0.4,2.3,0.8,3C32.5,87.6,33.6,84.5,34.9,81.7z"
      />
      <path
        fill={colors[3]}
        d="M35.4,91.8c0,0,1.8-0.1,3.5-1.8c0.9-1,4.2-7.4,5.5-13.2l-0.8,0.5C42.7,79.5,40.4,89.4,35.4,91.8z"
      />
      <path
        fill={colors[4]}
        d="M41.9,76.7l1.5-2.9c0,0,1.2-0.6,1.1,0.4c0,1.6,0,3.3,0,3.3s-1,1.5-2.4-0.5C42.1,76.9,42,76.8,41.9,76.7z"
      />
      <path
        fill={colors[5]}
        d="M43.3,74.3c0,0,0.2,0.3,0.4,0.2c-0.1,0.5-1,3.1-1,3.1L42.1,77L43.3,74.3z"
      />
      <ellipse
        transform="rotate(-3.19 43.878 74.103) scale(1.00005)"
        fill={colors[6]}
        cx="43.9"
        cy="74.1"
        rx="0.5"
        ry="0.3"
      />
    </g>
  )
}

export default BotaBagRight
