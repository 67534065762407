import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const ClubL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("ClubL") ? (
    <g id="ClubL">
      <path d="M92.7,78.2l-6-8c0.1-0.7,0.2-1.5,0.3-2.2c0.1-0.5,0.1-0.9,0.2-1.3c0.1-1,0.3-2.3,0.3-2.9c-0.1-0.6-0.2-0.9-0.2-0.9l0,0.1 l1.4-7.2c-0.9,1-1.5,2.3-1.6,3.8c0.1-0.7,0.1-1.4,0.1-2c0-0.7,0.1-1.4,0.1-2.1c0-0.6,0-1.3,0-1.9c0-0.9,0-1.8,0-2.7l0-0.6 c0-2,0-4.2,0.1-6.4c0-0.1,0-0.2,0-0.3l0,0l0-0.2l0,0l0-0.3c0.1-1,0.1-2,0.2-3c0.1-0.6,0.1-1.1,0.2-1.7c0.1-0.6,0.2-1.2,0.3-1.8 l0,0c0.1-0.3,0.1-0.7,0.2-1l0,0c0-0.3,0.1-0.6,0.1-0.9l0-0.3c0-0.1,0-0.3,0-0.4l0-0.5c-0.1-0.4-0.1-0.9-0.1-1.3 c0-0.5-0.1-1-0.1-1.6c0-0.6-0.1-1.3-0.2-1.9c0-0.3-0.1-0.6-0.1-0.9c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.3 c0-0.1-0.1-0.2-0.1-0.4c0,0.1,0.1,0.2,0.1,0.4l-0.5-2.9l0.1-2.6l0.3-1.8l0.1-0.4c0.1-0.4,0.2-0.8,0.3-1.1l0-0.2 c0.1-0.3,0.2-0.6,0.2-0.9l0.2-1.1c0.1-0.3,0.1-0.6,0.2-1l0-0.1c0.1-0.4,0.1-0.7,0.1-1c0.1-0.5,0.1-0.9,0.2-1.4 c0-0.2,0.1-0.4,0.1-0.6l1.3-3.1c2.3-3.1,5.7-4.8,9.6-4.8c0.2,0,0.4,0,0.6,0c0.5,0,1.1,0.1,1.6,0.2c0.3,0,0.6,0.1,0.9,0.2l0.1,0 c1.6,0.4,3.1,1,4.3,1.9c0.2,0.1,0.4,0.3,0.6,0.4l2.5,3.9l0.1,0.3c0.2,0.6,0.4,1.2,0.6,1.8c0.3,0.9,0.6,1.8,0.9,2.9l0.1,0.3 c0.1,0.3,0.1,0.6,0.2,0.9l0.1,0.3c0.1,0.5,0.2,1,0.2,1.5c0,0.3,0,0.5,0.1,0.7l0,0l0,1.2c-0.2,1.8-0.6,3.3-0.9,4.1l-1.4,7l-0.7,1.9 c-0.2,0.4-0.4,1.6-0.5,2.4l0,0.3c-0.3,1.6-0.7,3-1,4.2c-0.3,1-0.6,1.9-0.7,2.6l0,0.2c0,0.2,0,0.4,0,0.7l0,0.1l0,0.2 c0,0.9,0,2.1-0.2,3.4c-0.2,1.1-0.6,2.1-1,2.8l0.4,0.4l-0.3,1.6l-0.5,0.6l0.1,0.3c-0.3,1.5-0.6,2.9-1,4.2c-0.4,1.5-0.7,2.9-0.9,4.2 c0,0.1-0.1,0.6-0.1,0.6c-0.1,0.4-0.1,0.8-0.1,1.2c-0.1,0.6-0.1,1.2-0.2,1.7l-0.1,1.3l-0.1,0.7l-1.2,5.8l-8,5.6L92.7,78.2z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(ClubL, { character: "ManMixed" })
