import React from "react"

const GoldAxeRight = ({ colors = ["#8A7441", "#A49255", "#EECE6E"] }) => {
  return (
    <g id="GoldAxeRight">
      <path
        fill={colors[0]}
        d="M9,59.3c1.7,0.9,4.7,0.5,7.6-1.1s4.8-4,4.9-5.8c3.1,0.2,5.8,1.6,7.2,4.1s1.2,5.5-0.3,8.2 c-1.7-0.9-4.7-0.5-7.6,1.1s-4.8,4-4.9,5.8c-3.1-0.2-5.8-1.6-7.2-4.1C7.3,65,7.5,62,9,59.3z"
      />
      <path
        fill={colors[1]}
        d="M18.7,65.5l-5.5-8.1c0.1-0.3,0.1-0.5,0.2-0.8l3.6-2c0.2,0.1,0.4,0.1,0.7,0.2l3.9,9.1 c0,0,12.3,24.4,15.5,30c2.6,4.5,6.6,10.1,8,12c-0.1,0.3-0.3,0.7-0.5,1l-3.4,0.9L21.9,70.5L18.7,65.5z"
      />
      <path
        fill={colors[0]}
        d="M44.6,106.8c0.1-0.1-0.3-0.3-0.2-0.5c-1.7-2.3-5.6-7.8-8.1-12.1C33.1,88.7,21,64.6,20.9,64.4l-3.8-8.8 c-0.1,0-0.5-0.7-0.5-0.8l-2.8,1.7c0,0.1,0,0.7,0.2,0.6l5.3,7.8l3,5.1l19.3,37.5L44.6,106.8z"
      />
      <path
        fill={colors[2]}
        d="M44.3,106.9c-1.7-2.3-5.8-8.2-8.2-12.4c-3-5.2-14.2-27.5-15.5-30l-4.5-9.3l-1.8,1l5.2,8.7l3.2,5l19.2,37.7 L44.3,106.9z"
      />
      <g fill={colors[2]}>
        <path d="M27.4,57.5c-0.6-1.2-1.7-2-2.9-2.5c-0.1-0.1,0.1-0.7,0.1-1.2c-0.4,0.8-0.7,0.9-0.8,0.9 c-0.9-0.3-1.9-0.5-2.9-0.5c0.4-0.6,0.6-1.3,0.6-1.8c3.1,0.2,5.8,1.6,7.2,4.1s1.2,5.5-0.3,8.2c-0.4-0.2-0.8-0.3-1.3-0.4 C28.3,62,28.5,59.6,27.4,57.5z" />
        <path d="M9,59.3c0.5,0.3,1.1,0.4,1.8,0.4c-1.4,2.4-1.7,5-0.6,7.2c0.3,0.6,0.9,1.2,1.4,1.7c0,0-0.1,0.3,0,0.3 c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.1,0.4,0.3,0.5,0.4s0.1,0.3-0.1,1.1c0.1-0.3,0.6-0.8,0.7-0.8c1,0.5,2.1,0.8,3.3,0.9 c-0.2,0.5-0.3,0.9-0.4,1.3c-3.1-0.2-5.8-1.6-7.2-4.1C7.3,65,7.5,62,9,59.3z" />
      </g>
    </g>
  )
}

export default GoldAxeRight
