import React from "react"

const ScrollRight = ({
  colors = ["#4D391B", "#805F2D", "#B0823D", "#CBA02C"]
}) => {
  return (
    <g id="ScrollRight">
      <polygon
        fill={colors[0]}
        points="30.4,99.8 39.4,76.5 41.2,76.6 40.9,93.3 39.3,93.6 39.4,80.6 32,98.7"
      />
      <path
        fill={colors[1]}
        d="M31.3,102.5c0.5,0,11.3-6.8,11.3-6.8s1.2-0.5,1.1-1.8c-0.1-1.3-1.1-1.8-2.1-1.7c-1,0.1-11.5,7.8-11.5,7.8 s-0.4,0.6-0.2,1.5C30,102.3,30.8,102.5,31.3,102.5z"
      />
      <path
        fill={colors[2]}
        d="M40.4,92.5c0,0-0.8,1.1-0.3,2.5c0.4,1.5,2.1,1.4,2.1,1.4l1.7-1.1l-0.3-2.1L42,91.9L40.4,92.5z"
      />
      <path
        fill={colors[3]}
        d="M42.1,91.8c0,0-1.2,0.9-0.7,2.4s2.4,1.1,2.4,1.1l-0.3-2l-0.7,1.6l-1.1-0.8l0.3-1.4l1.3,0.5L42.1,91.8z"
      />
      <path
        fill={colors[0]}
        d="M31,99.3c0,0-0.9,0.9-0.3,1.8s1.4,0.9,1.4,0.9l-0.4,0.3c0,0-1.3,0-1.5-1s0.3-1.9,0.3-1.9L31,99.3z"
      />
      <path
        fill={colors[2]}
        d="M30.8,99.3c0,0-0.8,1-0.2,2s1.4,0.9,1.4,0.9l-0.7,0.4c0,0-1,0-1.5-0.9s0.2-1.8,0.2-1.8L30.8,99.3z"
      />
    </g>
  )
}

export default ScrollRight
