import React from "react"

const LongBowRight = ({
  colors = [
    "#695E60",
    "#42130B",
    "#FFCD03",
    "#EDD692",
    "#B5AC95",
    "#915B25",
    "#A98A30"
  ]
}) => {
  return (
    <g id="LongBowRight">
      <line
        fill="none"
        stroke={colors[0]}
        strokeWidth="0.11"
        strokeMiterlimit="10"
        x1="27.544"
        y1="53.317"
        x2="48.952"
        y2="108.753"
      />
      <polygon
        fill={colors[1]}
        points="33.5368919,86.5285797 32.8107605,84.6379623 31.7548847,81.9651413 31.0287533,80.0745239 29.5309982,81.0602264 30.1250076,82.5813675 31.1376209,85.3196564 31.7748775,86.7753296"
      />
      <polygon
        fill={colors[2]}
        points="31.0125408,86.6478958 33.8631058,86.4619293 34.0618896,87.157608 31.6673183,87.0804977"
      />
      <g fill={colors[3]}>
        <polygon points="30.389267,83.3203049 31.9747105,82.4866104 31.7981453,81.8996658 31.6449738,81.7044144 30.1250076,82.5813675" />
        <polygon points="30.0805645,82.3638916 31.6005344,81.4869385 31.35849,80.8567352 29.7730465,81.6904297" />
        <polygon points="32.7067299,85.7919846 33.2281952,85.5721588 32.9428902,85.007431 32.0519524,85.3593979" />
        <polygon points="32.5979881,85.8142014 32.0519524,85.3593979 31.3130035,85.6236496 31.5550518,86.2538605" />
        <polygon points="30.9832668,84.8414536 32.6341896,84.0510178 32.3266678,83.3775482 30.6535301,84.0592499" />
        <polygon points="31.2253113,85.4716568 32.9206772,84.8986969 32.8107605,84.6379623 32.6564102,84.1597519 31.0709648,84.9934464 31.1808815,85.2541809" />
        <polygon points="33.5368919,86.5285797 33.1849365,85.6376343 31.642746,86.4058533 31.7094021,86.7320709" />
      </g>
      <g fill={colors[4]}>
        <polygon points="32.0437202,83.3787308 31.5409374,82.8584442 30.4114819,83.429039 30.6313152,83.9505157" />
        <polygon points="32.0624123,82.6386108 31.6496773,82.8362122 32.1524544,83.356514 32.304451,83.2688141" />
      </g>
      <g fill={colors[4]}>
        <polygon points="30.1413422,81.2753372 29.8349953,80.8848267 29.5309982,81.0602264 29.6853485,81.5384293" />
        <polygon points="31.0720119,80.0090485 29.8782539,80.8193512 30.2500782,81.2531204 31.2707977,80.7047272" />
      </g>
      <path
        fill={colors[5]}
        d="M27.6525555,53.2947159l-0.1753902-0.303997 c-0.2396927-0.0643005-0.3460751,0.5238228-0.3460751,0.5238228s0.0654774,0.0432587,0.1765652,0.5869446 s-1.5725117,3.946167-2.6516495,6.1490097c-2.8913383,6.0847168,1.189436,13.8599472,3.5572147,17.9638672 c0.6360836,1.1727142,1.249958,2.2366943,1.4043045,2.7149048l1.1271038-1.1365128 c-0.2853069-0.5647278-0.7237854-1.3247147-1.2499523-2.236702c-2.2800865-3.9519119-6.5748119-10.8338699-4.2914581-16.567791 c1.1422691-2.7254829,2.1757908-5.4287529,2.3885651-6.6049995 C27.7192059,53.6209259,27.7180309,53.3379707,27.6525555,53.2947159z"
      />
      <ellipse
        transform="rotate(-42.815 30.226 80.298)"
        fill={colors[6]}
        cx="30.226"
        cy="80.298"
        rx="1.177"
        ry="0.471"
      />
      <polygon
        fill={colors[2]}
        points="29.0984001,81.7149963 31.2894821,79.9646149 31.0252228,79.2256775 29.2258263,80.952652"
      />
      <path
        fill={colors[5]}
        d="M48.9735184,108.5785828l0.066658,0.3262177 c-0.1952667,0.1531754-0.6536064-0.1496429-0.6536064-0.1496429s0.0432625-0.0654755-0.2198257-0.5214691 c-0.2630882-0.455986-3.7731323-1.8344116-6.105751-2.7171249c-6.1922722-2.586174-8.4911652-11.0653229-9.4897957-15.6755524 c-0.2666245-1.3048401-0.5764961-2.5442123-0.7741013-2.9569473l1.6544533,0.0584259 c0.1765633,0.5869522,0.3975716,1.3913651,0.619751,2.4787369c0.9541931,4.392746,2.3808403,12.4837418,7.9639511,15.1377411 c2.6389656,1.2732162,5.2779274,2.5464401,6.216835,3.2608185 C48.9290886,108.3611145,49.0822563,108.5563736,48.9735184,108.5785828z"
      />
      <ellipse
        transform="rotate(-89.504 32.764 86.93)"
        fill={colors[6]}
        cx="32.764"
        cy="86.93"
        rx="0.471"
        ry="1.177"
      />
      <polygon
        fill={colors[6]}
        points="31.5550518,86.2538605 33.6222343,86.1146698 33.8631058,86.4619293 31.6871872,86.6233292"
      />
      <polygon
        fill={colors[6]}
        points="29.6409149,81.3209534 31.2894821,79.9646149 31.3771839,80.1166077 29.6631336,81.4296875"
      />
    </g>
  )
}

export default LongBowRight
