import React from "react"

const SlingShotLeft = ({
  colors = ["#352915", "#464646", "#939393", "#724F20"]
}) => {
  return (
    <g id="SlingShotLeft">
      <path
        fill={colors[0]}
        d="M81.12146,89.8131104l0.7680969-6.9827042l4.329277-2.0249863 c0,0,1.5361938,1.3965378,1.4663696,3.2120438c0,0.2793045,1.466362,23.8808594,1.466362,23.8808594 s-0.1396484,1.6758575-0.8379211,1.955162c-0.6982727,0.2793121-2.2344666-0.2793045-2.2344666-0.2793045l-0.3491287-1.955162 l0.3491287-23.8808746l0.8379288-1.3267136l-0.7680969-1.0474091l-3.7008438,1.955162l0.6982727,1.6060257l-0.0698242,6.0749588 l-1.6060181,1.8853378l0.3491364-3.8404922L81.12146,89.8131104z"
      />
      <path
        fill={colors[1]}
        d="M89.2912292,106.8509216c-0.5586243-0.7680969-0.6982727-1.2568893-1.7456818-1.1172256 c-0.1396484,0-1.3267136,0.0698242-1.3965378,0.2094803c-0.3491364,0.5586166-0.8379288,1.8853302-0.8379288,1.8853302 s0.7680969,1.4663696,1.7456818,1.5361938c0.6982727,0.0698242,2.1646347-0.6284485,2.1646347-0.6284485 S89.5705338,107.200058,89.2912292,106.8509216z"
      />
      <path
        fill={colors[2]}
        d="M87.6852036,106.6414413c-0.1396484-0.2793121-0.2793045-0.6284485-0.4189606-0.907753 c-0.4189606,0-0.9775772,0.0698242-1.1172333,0.2094803c-0.3491364,0.5586243-0.8379211,1.8853302-0.8379211,1.8853302 s0.2793121,0.5586166,0.7680969,0.9775848c0.4887848-0.1396561,0.9775848-0.4189682,1.4663696-0.7680969 C87.5455475,107.6190186,87.6153793,107.1302338,87.6852036,106.6414413z"
      />
      <path
        fill={colors[3]}
        d="M86.9171066,109.1552124l0.9775848-0.0698242l0.1396484-1.6758499l-0.4189606-1.815506 l-0.907753-22.5541458l-2.6534348-1.2568893l1.4663696-0.6982727l0.6982727-0.3491364l0.9775772,0.4887924l0.6284485,1.466362 l1.3965378,25.1377563c0,0-0.2793045,1.955162-1.1870575,1.6758499c-0.1396484-0.0698242-1.2568893,0-1.6758499-0.2793045 C86.7774506,109.015564,86.9171066,109.1552124,86.9171066,109.1552124z"
      />
    </g>
  )
}

export default SlingShotLeft
