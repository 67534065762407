import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const RamHorns = ({ allEnabledElements }) => {
  return allEnabledElements.includes("RamHorns") ? (
    <g id="RamHorns">
      <path d="M56.3,21.4L54.8,21c-2.4-1-4.1-3.4-4.6-7C50,11.5,55.5-0.2,57.6-1.3C58.2-1.8,63.9-2,69.5-2c5.3,0,10.6,0.2,11.2,0.7 c2.1,1.2,7.8,12.9,7.4,15.3c-0.5,3.6-2.2,6-4.6,7L82,21.4C81.7,21.4,56.7,21.4,56.3,21.4z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(RamHorns, { character: "ManMixed" })
