import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const OpenCloak = ({ allEnabledElements }) => {
  return allEnabledElements.includes("OpenCloak") ? (
    <g id="OpenCloak">
      <path
        id="openCloak_1_"
        fill="#2B3B21"
        d="M69.1,54.7l-7.6-0.9c0.6-0.3-0.4-6.6-0.9-8.3c-1.1-3.5-5.3-8.2-7.7-9.2 c-0.7-0.3-4.3-0.5-5.6-0.5c-4.7,0-6.9,2.2-7,2.2c-0.3,0.2-4.8,6.9-4.9,8.8c0.1,0.3-2.2,6.2-1.2,6.6l-4.4,0.2L20,119.7l57.6-0.1 L69.1,54.7z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(OpenCloak, { character: "ManDwarf" })
