import React from "react"

const WizardRobe = ({ colors = ["#40706A", "#9B883A"] }) => {
  return (
    <g id="WizardRobe">
      <path
        fill={colors[0]}
        d="M69,72.8C75.5,86,95,96,95,96S80.1,57.7,80,56.2c2.4-0.7,2.8-4.4,4.4-6.7c0.1,0.4,0.2,0.8,0.3,1 c0.2,0.3,0.3,0.8,0.6,0.7c0.1,0.3,0.2,1.7,0.6,1.4c0.1,0.5,0.5,1.5,0.8,1.1c-0.8,1.8,0.1,4.4,0.4,5.1c0.2,3.2,1.4,16.6,2.4,16.5 c0.2,0.4,0.5,0.7,0.9,0.7c0.7,0,3.9-5.6,4.9-7.9c1.7-4.2,2.8-8.7,3.5-13.2c0.2-1.3,0.6-4.4-0.6-5.5c-0.4-0.4-0.7-0.5-1-0.3 c-0.2-0.3-0.5-0.5-0.8-0.5c-0.1,0-0.2,0-0.2,0c-0.4-0.5-0.8-1-1.2-1.5c-0.6-0.8-1.1-0.3-1.7-1.6c-1.4-3.3-2.5-9.2-3.2-11.6 c-0.8-3-0.7-2.8-3.2-4.6c-0.5-0.4-5.4-1.2-9.4-4.8c-0.1-0.1-5.1,8.2-6.8,12.4c-1.5,3.7-3.1,8.2-3.7,12.2 C65.8,56.9,68.3,64.7,69,72.8z"
      />
      <path
        fill={colors[1]}
        d="M91.8,52.4C88.9,57.6,90,74.1,90.5,76c-0.2,0.2-0.8-0.3-0.8-0.3s-3.3-28.1,6.5-27 c0.5-0.1,0.8,0.1,1.2,0.6C96.7,49.5,94,49.4,91.8,52.4z"
      />
      <path
        fill={colors[1]}
        d="M89.1,31.2c0.1,0,0.2,0.1,0.2,0.2c0.1-0.2,0.9,1.8,0.7,2.7c0.1,0.4-4.4,3.4-5.7,15.5 C83.8,41.5,88.8,31.1,89.1,31.2z"
      />
      <path
        fill={colors[0]}
        d="M96.9,51.7c0-0.3-1.9-0.8-2.2-0.9s-3,2.7-3,2.7l-0.9,20.8C90.9,74.3,96.3,63.8,96.9,51.7z"
      />
      <path
        fill={colors[1]}
        d="M77.4,24.7c0.4-0.9,0.9,0.4,0.9,0.6c-0.2,3.8-5.7,8.2-6.3,12.1c-0.3,1.9-0.3,3.7,0,5.6 c-4.5,14.6-0.7,29.5-0.8,29.7c7.6,9.8,22.4,21,22.4,21s0.5,0.8,1,1.9c-12.3-6.3-24.9-22-24.9-22S58.6,48.7,77.4,24.7z"
      />
      <path
        opacity="0.55"
        fill="#21376C"
        d="M91.3,45c-1.6-1.3-4.5,2.7-5.3,4.2 c0.1-0.6,0.5-1.8,0.7-2.3c3.4-7.6,6.3-1.1,6.3-1.1c0,0,0,0-0.1,0c0.1,0.1,0.1,0.1,0.1,0.1c-1.7-0.7-2.8-0.3-3.8,0.3 c-1.2,0.6-2.2,3.3-3,5.3C86.3,51.1,87.7,44.9,91.3,45z"
      />
      <path
        opacity="0.56"
        fill="#21376C"
        d="M98.4,51.4c-9.5-2.3-8.6,20.4-7.3,22.9 C92.6,73,99.2,57.2,98.4,51.4z"
      />
      <path
        opacity="0.56"
        fill="#21376C"
        d="M79,56.5c0,0,4.7-1.9,3.4-6.8c-1.3,2.7-4.3,7.1-4.3,7.1 s1.4-1.4,1.4-4.3c-1.3,2.7-2.6,5.1-2.6,5.1L79,56.5z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M71.5,49.1c-0.6-4-2.2-8.5-3.7-12.2c-1.7-4.2-6.7-12.5-6.8-12.4c-4,3.6-8.9,4.4-9.4,4.8 c-2.5,1.8-2.4,1.6-3.2,4.6c-0.7,2.5-1.9,8.3-3.2,11.6c-0.5,1.3-1.1,0.8-1.7,1.6c-0.4,0.6-0.8,1.1-1.2,1.5c-0.1,0-0.1,0-0.2,0 c-0.3,0-0.6,0.2-0.8,0.5c-0.3-0.1-0.6-0.1-1,0.3c-1.1,1.1-0.8,4.1-0.6,5.5c0.7,4.5,1.8,8.9,3.5,13.2c0.9,2.3,4.2,7.9,4.9,7.9 c0.4,0,0.7-0.3,0.9-0.7c1,0.1,2.3-13.4,2.4-16.5c0.3-0.7,1.2-3.3,0.4-5.1c0.4,0.3,0.7-0.6,0.8-1.1c0.4,0.3,0.5-1.1,0.6-1.4 c0.2,0.1,0.3-0.4,0.6-0.7c0.2-0.2,0.3-0.6,0.3-1c1.6,2.3,2,6,4.4,6.7c-0.1,1.5-15,39.8-15,39.8s19.5-10,26-23.1 C70.1,64.7,72.6,56.9,71.5,49.1z"
        />
        <path
          opacity="0.56"
          fill="#21376C"
          d="M61.8,24.9c-0.4-0.9-0.9,0.4-0.9,0.6 c0.2,3.8,5.7,8.2,6.3,12.1c0.3,1.9,0.3,3.7,0,5.6c4.5,14.6,0.7,29.5,0.8,29.7c-7.6,9.8-22.4,21-22.4,21s-0.5,0.8-1,1.9 c12.3-6.3,24.9-22,24.9-22S80.7,49,61.8,24.9z"
        />
        <path
          fill={colors[1]}
          d="M46.7,52.4c2.9,5.1,1.8,21.7,1.3,23.6c0.2,0.2,0.8-0.3,0.8-0.3s3.3-28.1-6.5-27 c-0.5-0.1-0.8,0.1-1.2,0.6C41.8,49.5,44.5,49.4,46.7,52.4z"
        />
        <path
          fill={colors[1]}
          d="M49.3,31.2c-0.1,0-0.2,0.1-0.2,0.2c-0.1-0.2-0.9,1.8-0.7,2.7c-0.1,0.4,4.4,3.4,5.7,15.5 C54.6,41.5,49.7,31.1,49.3,31.2z"
        />
        <path
          fill={colors[0]}
          d="M41.5,51.7c0-0.3,1.9-0.8,2.2-0.9s3,2.7,3,2.7l0.9,20.8C47.6,74.3,42.2,63.8,41.5,51.7z"
        />
        <path
          fill={colors[1]}
          d="M61,24.7c-0.4-0.9-0.9,0.4-0.9,0.6c0.2,3.8,5.7,8.2,6.3,12.1c0.3,1.9,0.3,3.7,0,5.6 c4.5,14.6,0.7,29.5,0.8,29.7c-7.6,9.8-22.4,21-22.4,21s-0.5,0.8-1,1.9c12.3-6.3,24.9-22,24.9-22S79.8,48.7,61,24.7z"
        />
        <path
          opacity="0.55"
          fill="#21376C"
          d="M47.2,45c1.6-1.3,4.5,2.7,5.3,4.2 c-0.1-0.6-0.5-1.8-0.7-2.3c-3.4-7.6-6.3-1.1-6.3-1.1c0,0,0,0,0.1,0c-0.1,0.1-0.1,0.1-0.1,0.1c1.7-0.7,2.8-0.3,3.8,0.3 c1.2,0.6,2.2,3.3,3,5.3C52.2,51.1,50.8,44.9,47.2,45z"
        />
        <path
          opacity="0.56"
          fill="#21376C"
          d="M40.1,51.4c9.5-2.3,8.6,20.4,7.3,22.9 C45.9,73,39.2,57.2,40.1,51.4z"
        />
        <path
          opacity="0.56"
          fill="#21376C"
          d="M59.5,56.5c0,0-4.7-1.9-3.4-6.8c1.3,2.7,4.3,7.1,4.3,7.1 S59,55.3,59,52.4c1.3,2.7,2.6,5.1,2.6,5.1L59.5,56.5z"
        />
      </g>
    </g>
  )
}

export default WizardRobe
