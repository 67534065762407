export const INCREMENT_QUEUE = `printQueue.INC`
export const DECREMENT_QUEUE = `printQueue.DEC`

export const incrementPrintQueue = ({ id }) => ({
  type: INCREMENT_QUEUE,
  payload: { id }
})

export const decrementPrintQueue = ({ id }) => ({
  type: DECREMENT_QUEUE,
  payload: { id }
})


const defaultState = {
  queue: {}
}

const printQueueReducer = (state = defaultState, action) => {
  switch(action.type){
    case INCREMENT_QUEUE:
      return { ...state,
        queue: {
          ...state.queue,
          [action.payload.id]: isNaN(state.queue[action.payload.id]) ? 1 : state.queue[action.payload.id] + 1
        }
      }

    case DECREMENT_QUEUE:
      return { ...state,
        queue: {
          ...state.queue,
          [action.payload.id]:  isNaN(state.queue[action.payload.id]) ? 0 : Math.max(state.queue[action.payload.id] - 1, 0)
        }
      }
    default:
      return state
  }
}

export default printQueueReducer