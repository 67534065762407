import React from "react"

const LeafClasp = ({ colors = ["#FCDF54", "#421115"] }) => {
  return (
    <g id="LeafClasp">
      <path
        fill={colors[0]}
        d="M67.345726,31.2844658c-0.1999969,0-0.4000015-0.0999985-0.5999985-0.0999985 c-0.9000015-0.4000015-1-3-1.0999985-3.2000008v-0.1000004l0,0c0,0,1.0999985-1.3999996,1.6999969-1.5 c0.1999969-0.1000004,0.5-0.1000004,0.9000015,0c0.6999969,0.1000004,1.6999969,0.3999996,1.6999969,0.3999996 l0.4000015,0.1000004l-1.0999985,0.7999992c0.0999985,0,0.0999985,0,0.1999969,0c0.5,0.1000004,1,0.1000004,1.4000015,0.2000008 c0.9000015,0.2000008,2.5,1.1000004,2.5,1.2000008l0.4000015,0.2000008l-0.4000015,0.1000004c-0.0999985,0-3,1-4,0.9000015 h-0.0999985c-0.5,0-0.9000015-0.0999985-1.0999985-0.2000008l0.4000015,0.9000015l-0.0999985,0.0999985 C68.2457199,31.1844673,67.845726,31.2844658,67.345726,31.2844658z"
      />
      <path
        opacity="0.63"
        fill={colors[1]}
        d="M67.345726,31.2844658 c-0.1999969,0-0.4000015-0.0999985-0.5999985-0.0999985c-0.9000015-0.4000015-1-3-1.0999985-3.2000008v-0.1000004l0,0 c0,0,1.0999985-1.3999996,1.6999969-1.5c0.1999969-0.1000004,0.5-0.1000004,0.9000015,0 c0.6999969,0.1000004,1.6999969,0.3999996,1.6999969,0.3999996l0.4000015,0.1000004l-1.0999985,0.7999992 c0.0999985,0,0.0999985,0,0.1999969,0c0.5,0.1000004,1,0.1000004,1.4000015,0.2000008 c0.9000015,0.2000008,2.5,1.1000004,2.5,1.2000008l0.4000015,0.2000008l-0.4000015,0.1000004c-0.0999985,0-3,1-4,0.9000015 h-0.0999985c-0.5,0-0.9000015-0.0999985-1.0999985-0.2000008l0.4000015,0.9000015l-0.0999985,0.0999985 C68.2457199,31.1844673,67.845726,31.2844658,67.345726,31.2844658z"
      />
      <path
        fill={colors[0]}
        d="M66.845726,30.9844666c-0.6999969-0.4000015-1-3.1000004-1-3.1000004s1-1.2000008,1.6999969-1.5 c0.5999985-0.2000008,2.5,0.3999996,2.5,0.3999996l-1.4000015,1c0,0,1.1999969,0,2.0999985,0.3999996 c0.9000015,0.2000008,2.5,1.2000008,2.5,1.2000008s-3,0.9999981-3.8000031,0.8999996 c-1,0-1.6999969-0.2000008-1.6999969-0.2000008l0.4000015,1.0999985 C68.2457199,30.9844666,67.6457214,31.2844658,66.845726,30.9844666z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M67.4457245,30.9844666 c-0.0999985,0-0.4000015-0.0999985-0.5-0.0999985c-0.5-0.2000008-0.6999969-1.8999996-0.9000015-2.7999992 c0.1999969-0.3999996,1.0999985-1.2000008,1.5999985-1.3999996c0.0999985,0,0.4000015,0,0.6999969,0 c0.5,0,1,0.1000004,1.1999969,0.2000008l-1.5,1.1000004h0.5000076c0,0,0.4000015,0,0.6999969,0.1000004 c0.5,0.1000004,1,0.1000004,1.1999969,0.2000008c0.5999985,0.2000008,1.5,0.7000008,2.0999985,1 c-1,0.3999996-2.6999969,0.8999996-3.3000031,0.6999989h-0.0999985c-0.6999969-0.0999985-1.5-0.3999996-1.5-0.3999996 l-0.4000015-0.1000004l0.5999985,1.4000015C67.9457245,30.9844666,67.7457199,30.9844666,67.4457245,30.9844666z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.1457214,27.9844666l3.5-1.1000004 C69.7457199,26.8844662,67.6457214,27.084465,66.1457214,27.9844666z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.1457214,27.9844666 c0,0,1.0999985,1.3999996,6.6999969,1.3999996C70.9457245,29.084465,66.845726,28.584465,66.1457214,27.9844666z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M66.1457214,28.084465 c0,0,0.4000015,2.0999985,1.9000015,2.7999992C66.7457199,29.6844654,66.1457214,28.084465,66.1457214,28.084465z"
      />
      <path
        fill={colors[0]}
        d="M66.2457199,28.1844654l0.3000031,0.5l0.0999985-0.1000004c0,0,0.1999969,0.3999996,0.4000015,0.5 c0.0999985-0.2000008,0.1999969-0.3999996,0.1999969-0.3999996S66.345726,28.1844654,66.2457199,28.1844654 C66.1457214,28.084465,66.2457199,28.1844654,66.2457199,28.1844654z"
      />
      <path
        fill={colors[0]}
        d="M66.6457214,28.8844662L66.6457214,28.8844662l0.6999969,0.7000008h-0.4000015 C67.045723,29.584465,66.7457199,29.1844654,66.6457214,28.8844662z"
      />
      <path
        fill={colors[0]}
        d="M67.1457214,29.6844654h0.4000015l0.5,1.1000004 C67.845726,30.6844673,67.345726,30.084465,67.1457214,29.6844654z"
      />
      <path
        fill={colors[0]}
        d="M67.1457214,29.084465c0,0,0.1999969,0.3999996,0.5999985,0.5s0.9000015,0.2000008,0.9000015,0.2000008 l-0.0999985-0.8999996c0,0-0.1999969-0.1000004-0.5-0.1000004c-0.0999985,0-0.0999985,0.2000008-0.0999985,0.5 c-0.0999985-0.1000004,0-0.5-0.0999985-0.5c-0.4000015-0.1000004-0.5999985-0.1000004-0.5999985-0.1000004L67.1457214,29.084465z"
      />
      <path
        fill={colors[0]}
        d="M68.6457214,29.084465l0.0999985,0.9000015c0,0,0.5999985,0.0999985,0.9000015,0.0999985 c0.1999969,0,0.1999969,0,0.1999969,0s-0.4000015-0.5-0.5-0.8999996 C68.9457245,29.084465,68.6457214,29.084465,68.6457214,29.084465z"
      />
      <path
        fill={colors[0]}
        d="M69.4457245,29.1844654c0,0,0.1999969,0.6000004,0.5,0.6999989 c0.1999969,0,1.4000015-0.2000008,1.4000015-0.2000008l-0.5-0.3999996 C70.7457199,29.2844658,69.545723,29.1844654,69.4457245,29.1844654z"
      />
      <path
        fill={colors[0]}
        d="M66.345726,27.6844654c0,0,1-0.5,1.4000015-0.5c0-0.2000008,0-0.5,0-0.5 S67.045723,27.084465,66.345726,27.6844654z"
      />
      <path
        fill={colors[0]}
        d="M67.845726,26.7844658v0.5l0.9000015-0.1000004V27.084465l0.0999985-0.1000004 C68.845726,26.7844658,68.345726,26.584465,67.845726,26.7844658z"
      />
      <polygon
        fill={colors[0]}
        points="71.045723,29.2844658 71.4457245,29.6844654 72.345726,29.2844658"
      />
      <polygon
        fill={colors[0]}
        points="68.9457245,26.8844662 68.845726,26.9844666 69.345726,26.8844662"
      />
    </g>
  )
}

export default LeafClasp
