import React from "react"

const StrapBoots = ({
  colors = [
    "#A87F54",
    "#754C29",
    "#9C5D26",
    "#262262",
    "#3A3019",
    "#5C3C20",
    "#644223"
  ]
}) => {
  return (
    <g id="StrapBoots">
      <path
        fill={colors[0]}
        d="M44.8338852,106.5682144c0,0-4.4396744,2.5999985-8.3142967,0.8000031 c-0.5650482,3.3000031,0.8072166,6,0.7264938,6c0,0-0.5650482,0.1999969-1.45298,1.5999985 c-0.9686584,1.1999969-1.2108231,2.8000031-1.2108231,3.5999985c0.645771,0,0.8072128,0.5,5.3276062,0.6999969 s4.5203934-0.8000031,4.5203934-0.8000031l0.080719-3.9000015l-0.2421646-1.1999969L44.8338852,106.5682144z"
      />
      <path
        fill={colors[1]}
        d="M36.5195885,107.3682098c0,0,3.5517426,3.6999969,7.8299713,3.6999969 c0.3228836,0.5,0.1614418,2.5999985-0.2421646,2.5c-3.4710159-0.9000015-6.2962608-2.4000015-7.7492447-4.4000015 C36.1967049,107.6682129,36.5195885,107.3682098,36.5195885,107.3682098z"
      />
      <path
        fill={colors[1]}
        d="M44.7531662,107.3682098c0,0-3.3095741,2.9000015-7.9914093,4.5999985 c-0.2421646,0.8000031,0.4843254,1.4000015,0.4843254,1.4000015c2.3409195-0.4000015,4.3589516-0.0999985,7.2649193-3.4000015 C45.0760498,108.5682144,44.7531662,107.3682098,44.7531662,107.3682098z"
      />
      <path
        fill={colors[2]}
        d="M44.5110016,116.2682114c0,0-3.9553452-4.0999985-7.5070801-3 c-0.645771,0.5-0.8072128,0.8000031-0.7264938,1c0,0,6.9420357-0.9000015,8.1528549,4.4000015 C44.7531662,117.2682114,44.5110016,116.2682114,44.5110016,116.2682114z"
      />
      <path
        fill={colors[2]}
        d="M44.1881142,113.3682098c-2.6638031-1-6.5384254-0.5-9.2829514,3.3000031 c-0.1614418,1.4000015-0.3228836,1-0.3228836,1.9000015c2.9866867-5.8000031,9.9287224-3.6999969,9.9287224-3.6999969 L44.1881142,113.3682098z"
      />
      <path
        opacity="0.42"
        fill={colors[3]}
        d="M44.1073952,113.3682098 c0,0-0.8879318-0.8000031-3.7131805-0.5999985c-2.8252449,0.2000046-3.4710159,0.5-3.4710159,0.5 s-0.6457672-4.6999969-0.6457672-8.9000015c2.1794739,1.9000015,8.2335739-0.1999969,8.2335739-0.1999969 s0.3228836,2.3000031,0,4.5999985C44.188118,111.0682068,44.1881142,113.3682098,44.1073952,113.3682098z"
      />
      <path
        fill={colors[4]}
        d="M34.1786728,119.7682114h10.4937668v-1.3000031c0,0-0.9686546,0.5-4.2782288,0.5 c-3.3095703,0-6.2962608-0.5-6.2962608-0.5L34.1786728,119.7682114z"
      />
      <polygon
        opacity="0.42"
        fill={colors[3]}
        points="41.4435883,117.1682129 41.5243111,118.7682114 41.1207047,118.8682098"
      />
      <polygon
        opacity="0.42"
        fill={colors[3]}
        points="42.5736885,118.7682114 41.5243111,118.6682129 41.6857567,116.0682144 40.7170982,118.6682129 39.8291626,118.8682098 41.0399818,118.9682083"
      />
      <path
        fill={colors[5]}
        d="M44.914608,105.5682144l-0.080719,1.5c0,0-3.1481323,3.4000015-8.4757385,0.1999969 c0.080719-1,0.8072128-2.1999969,0.8072128-2.1999969S38.6183434,106.2682114,44.914608,105.5682144z"
      />
      <path
        fill={colors[6]}
        d="M44.5917206,104.1682129v1.6999969c0,0-2.1794739,1-5.3276062,1 c-2.0987511,0-2.8252449-0.8000031-2.8252449-0.8000031l-0.1614418-1.8000031 C36.3581467,104.3682098,38.9412308,106.2682114,44.5917206,104.1682129z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M52.4269829,106.5145721c0,0,4.4396706,2.5999985,8.3142929,0.8000031 c0.5650482,3.3000031-0.8072128,6-0.7264938,6c0,0,0.565052,0.1999969,1.4529839,1.5999985 c0.9686546,1.1999969,1.2108231,2.8000031,1.2108231,3.5999985c-0.6457748,0-0.8072128,0.5-5.3276062,0.6999969 s-4.5203934-0.8000031-4.5203934-0.8000031l-0.080719-3.9000015l0.2421646-1.1999969L52.4269829,106.5145721z"
        />
        <path
          fill={colors[1]}
          d="M60.6605568,107.3145676c0,0-3.5517387,3.6999969-7.8299675,3.6999969 c-0.3228874,0.5-0.1614456,2.5999985,0.2421608,2.5c3.4710159-0.9000015,6.2962608-2.4000015,7.7492447-4.4000015 C60.9834404,107.6145706,60.6605568,107.3145676,60.6605568,107.3145676z"
        />
        <path
          fill={colors[1]}
          d="M52.5077057,107.3145676c0,0,3.3095703,2.9000015,7.9914093,4.5999985 c0.2421646,0.8000031-0.4843254,1.4000015-0.4843254,1.4000015c-2.3409195-0.4000015-4.3589516-0.0999985-7.2649193-3.4000015 C52.1848183,108.5145721,52.5077057,107.3145676,52.5077057,107.3145676z"
        />
        <path
          fill={colors[2]}
          d="M52.7498665,116.2145691c0,0,3.9553452-4.0999985,7.5070839-3 c0.6457672,0.5,0.8072128,0.8000031,0.72649,1c0,0-6.9420319-0.9000015-8.1528511,4.4000015 C52.4269829,117.2145691,52.7498665,116.2145691,52.7498665,116.2145691z"
        />
        <path
          fill={colors[2]}
          d="M52.9920311,113.3145676c2.6638031-1,6.5384254-0.5,9.2829514,3.3000031 c0.161438,1.4000015,0.3228874,1,0.3228874,1.9000015c-2.9866905-5.8000031-9.9287224-3.6999969-9.9287224-3.6999969 L52.9920311,113.3145676z"
        />
        <path
          opacity="0.42"
          fill={colors[3]}
          d="M53.0727501,113.3145676 c0,0,0.8879356-0.8000031,3.7131805-0.5999985c2.8252449,0.0999985,3.4710159,0.5,3.4710159,0.5 s0.6457672-4.6999969,0.6457672-8.9000015c-2.1794739,1.9000015-8.2335739-0.1999969-8.2335739-0.1999969 s-0.3228836,2.3000031,0,4.5999985C52.9113121,111.0145721,53.0727501,113.3145676,53.0727501,113.3145676z"
        />
        <path
          fill={colors[4]}
          d="M63.0014763,119.7145691H52.5077057v-1.3000031c0,0,0.9686546,0.5,4.2782288,0.5 c3.3095703,0,6.2962608-0.5,6.2962608-0.5L63.0014763,119.7145691z"
        />
        <polygon
          opacity="0.42"
          fill={colors[3]}
          points="55.817276,117.1145706 55.736557,118.7145691 56.0594406,118.8145676"
        />
        <polygon
          opacity="0.42"
          fill={colors[3]}
          points="54.6871758,118.7145691 55.736557,118.6145706 55.4943886,116.0145721 56.463047,118.6145706 57.4317017,118.8145676 56.1401634,118.914566"
        />
        <path
          fill={colors[5]}
          d="M52.3462601,105.5145721l0.0807228,1.5c0,0,3.1481323,3.4000015,8.4757347,0.1999969 c-0.080719-1-0.8072128-2.1999969-0.8072128-2.1999969S58.5618019,106.2145691,52.3462601,105.5145721z"
        />
        <path
          fill={colors[6]}
          d="M52.5884247,104.1145706v1.6999969c0,0,2.1794739,1,5.3276062,1 c2.0987511,0,2.8252449-0.8000031,2.8252449-0.8000031l0.1614418-1.8000031 C60.9027176,104.3145676,58.2389145,106.2145691,52.5884247,104.1145706z"
        />
      </g>
    </g>
  )
}

export default StrapBoots
