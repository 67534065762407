import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaceL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaceL") ? (
    <g id="MaceL">
      <path
        id="S_x24_maceL"
        d="M98.2277298,71.6913528l-2.0948105,3.5611801 c-2.0948181,3.6310043-8.6535034,22.6450577-12.2845154,20.5502472l-7.3222656-3.2990494 c-3.6310043-2.0948105,3.5377045-18.6576462,5.632515-22.2886581c0,0,2.0116119-15.0468597,11.7397995-9.9055405 l1.5361938,0.907753C99.0656509,63.3819237,100.3225403,68.0603409,98.2277298,71.6913528z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaceL, { character: "WomanGnome" })
