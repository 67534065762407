import React from "react"

const FireFistsR = ({
  colors = [
    "#CC9900",
    "#CC7900",
    "#CC6500",
    "#E15000",
    "#F24000",
    "#FC3600",
    "#FF3300",
    "#SVGID_"
  ]
}) => {
  return (
    <g id="FireFistsR">
      <linearGradient
        id="SVGID_61_"
        gradientUnits="userSpaceOnUse"
        x1="138.252"
        y1="684.512"
        x2="149.222"
        y2="702.01"
        gradientTransform="matrix(-1 0 0 1 188.278 -640)"
      >
        <stop offset="0" stopColor="#c90" stopOpacity="0" />
        <stop offset="0.152" stopColor="#cc7900" stopOpacity="0.449" />
        <stop offset="0.271" stopColor="#cc6500" stopOpacity="0.8" />
        <stop offset="0.415" stopColor="#e15000" stopOpacity="0.8" />
        <stop offset="0.584" stopColor="#f24000" stopOpacity="0.8" />
        <stop offset="0.769" stopColor="#fc3600" stopOpacity="0.8" />
        <stop offset="1" stopColor="#f30" stopOpacity="0.8" />
      </linearGradient>
      <path
        fill="url(#SVGID_61_)"
        d="M52.1,47.7c0,0-5,9.5-5,10s-0.7,4.1-0.7,4.1c-2.6,2.7-7,0.8-8,0 c-1-0.7-2.2-6.3-1.1-8.2c2.7-1.8,5.5-4.7,8.1-8.2C48.2,41.5,52.6,44.3,52.1,47.7z"
      />
    </g>
  )
}

export default FireFistsR
