import React from "react"

const NobelCape = ({ colors = ["#8D4D3A"] }) => {
  return (
    <g id="NobelCape">
      <g fill={colors[0]}>
        <path d="M35.2999992,59.5c-6,18.5-13.5,34.5999985-23.8999996,50.9000015 c0,0,6.6999989-4.1999969,6.6000004-0.6999969l0,0c8.2000008-16.2999954,14.0999985-32.4000015,18.7999992-50.2000008 C36.2000008,59.5,35.9000015,59.5,35.2999992,59.5z" />
        <path d="M61.7000008,59.5c5.9999962,18.5999985,13.4999962,34.5999985,23.8999977,50.9000015 c0,0-6.6999969-4.1999969-6.5999985-0.6999969l0,0c-8.1999969-16.2999954-14.0999985-32.4000015-18.7999992-50.2000008 C60.7999992,59.5,61.0999985,59.5,61.7000008,59.5z" />
        <path d="M60.2000008,59.5c-0.7000008,0.0999985-1,0.2000008-1.7000008,0.4000015 c-1.7000008,0.2000008-2.5999985,0.2999992-4.2999992,0.5C53.2999992,60.5,52.7999992,60.6000023,51.9000015,60.7000008l0,0 c-0.7999992,0.0999985-1.2000008,0.2000008-2,0.2999992C48.8000031,61,48.2000008,61,47,61 c-0.7999992-0.0999985-1.2000008-0.2000008-2-0.2999992l0,0C44.0999985,60.6000023,43.5999985,60.5,42.7000008,60.4000015 c-1.7000008-0.2000008-2.5999985-0.2999992-4.2999992-0.5c-0.7000008-0.0999985-1-0.2000008-1.7000008-0.4000015 C32,77.1999969,26.1000004,93.4000015,17.8999996,109.6999969c-0.2000008,3.5,4.1999989,4.9000015,6.5,3.3000031 c0.1000004-0.0999985,0.2999992-0.1999969,0.3999996-0.3000031c2.5-1.1999969,8.2000008-1,8.6000023,0.1999969 c0,0.0999985,0,0.0999985,0,0.1999969c-0.0999985,1.4000015,4.2999992,5.3000031,9,0.6999969 c0.2000008-0.1999969,0.4000015-0.3000031,0.5999985-0.5c0,0,0.0999985,0,0.0999985-0.0999985 c0,0.0999985,0.0999985,0.0999985,0.2000008,0c1.0999985-0.9000015,3-2,5.2000008-2.0999985 c2.2000008,0.0999985,4.2000008,1.1999969,5.2000008,2.0999985c0.0999985,0.0999985,0.0999985,0,0.2000008,0 c0,0,0.0999985,0,0.0999985,0.0999985l0,0c0.2000008,0.1999969,0.4000015,0.3000031,0.5999985,0.5 c4.7000008,4.5999985,9,0.6999969,9-0.6999969c0-0.0999985,0-0.0999985,0-0.1999969 c0.4000015-1.1999969,6.0999985-1.4000015,8.5999985-0.1999969C72.3999939,112.7999954,72.5,112.8999939,72.5999985,113 c2.3000031,1.5999985,6.6999969,0.1999969,6.5-3.3000031C70.8000031,93.4000015,64.9000015,77.3000031,60.2000008,59.5z" />
      </g>
      <path
        opacity="0.35"
        fill="#21376C"
        d="M60.2000008,59.5 c-0.7000008,0.0999985-1,0.2000008-1.7000008,0.4000015c-1.7000008,0.2000008-2.5999985,0.2999992-4.2999992,0.5 C53.2999992,60.5,52.7999992,60.6000023,51.9000015,60.7000008l0,0c-0.7999992,0.0999985-1.2000008,0.2000008-2,0.2999992 C48.8000031,61,48.2000008,61,47,61c-0.7999992-0.0999985-1.2000008-0.2000008-2-0.2999992l0,0 C44.0999985,60.6000023,43.5999985,60.5,42.7000008,60.4000015c-1.7000008-0.2000008-2.5999985-0.2999992-4.2999992-0.5 c-0.7000008-0.0999985-1-0.2000008-1.7000008-0.4000015C32,77.1999969,26.1000004,93.4000015,17.8999996,109.6999969 c-0.2000008,3.5,4.1999989,4.9000015,6.5,3.3000031c0.1000004-0.0999985,0.2999992-0.1999969,0.3999996-0.3000031 c2.5-1.1999969,8.2000008-1,8.6000023,0.1999969c0,0.0999985,0,0.0999985,0,0.1999969 c-0.0999985,1.4000015,4.2999992,5.3000031,9,0.6999969c0.2000008-0.1999969,0.4000015-0.3000031,0.5999985-0.5 c0,0,0.0999985,0,0.0999985-0.0999985c0,0.0999985,0.0999985,0.0999985,0.2000008,0c1.0999985-0.9000015,3-2,5.2000008-2.0999985 c2.2000008,0.0999985,4.2000008,1.1999969,5.2000008,2.0999985c0.0999985,0.0999985,0.0999985,0,0.2000008,0 c0,0,0.0999985,0,0.0999985,0.0999985l0,0c0.2000008,0.1999969,0.4000015,0.3000031,0.5999985,0.5 c4.7000008,4.5999985,9,0.6999969,9-0.6999969c0-0.0999985,0-0.0999985,0-0.1999969 c0.4000015-1.1999969,6.0999985-1.4000015,8.5999985-0.1999969C72.3999939,112.7999954,72.5,112.8999939,72.5999985,113 c2.3000031,1.5999985,6.6999969,0.1999969,6.5-3.3000031C70.8000031,93.4000015,64.9000015,77.3000031,60.2000008,59.5z"
      />
    </g>
  )
}

export default NobelCape
