import React from "react"

const SwordRightHip = ({
  colors = [
    "#8A7C6B",
    "#665C4F",
    "#3D3730",
    "#615240",
    "#838383",
    "#C7C7C6",
    "#4D4D4D",
    "#5C3434"
  ]
}) => {
  return (
    <g id="SwordRightHip">
      <path
        fill={colors[0]}
        d="M41.4,75.1l-23.2,35.8c0,0-1.9,2.8-1.1,3.6c0.8,0.9,2.4-2.1,2.4-2.1L44,76.9c0,0,0.3-0.5-0.8-1.5 C42.1,74.6,41.4,75.1,41.4,75.1z"
      />
      <path
        fill={colors[1]}
        d="M17.1,114.5c0.8,0.9,2.4-2.1,2.4-2.1L44,76.9c0,0,0.3-0.5-0.8-1.5c0.1,1.1-2,4-4.9,8.5 c-5.5,8.4-14.3,21-19.7,29.1C18.1,113.8,17.5,113.9,17.1,114.5z"
      />
      <path
        fill={colors[2]}
        d="M41.1,75.5c0,0-0.4,0.7,0.8,1.5s1.9,0.3,1.9,0.3L41.1,75.5z"
      />
      <path
        fill={colors[3]}
        d="M42.4,79.2c0,0-0.9,1-2.9-1.2c0.1-0.2,0.2-0.4,0.4-0.6c-0.2,0.3-6.6-2.2-6.6-2.2v2.2l5.4,1.9 c0,0-0.7,2,2.4,1.9c0.1,0,0.3-0.3,0.3-0.3c3-0.1,3.6,0.5,3.6,0.5l1-1.2c0,0-1.9-1.5-3.3-1.4C42.6,78.8,42.5,79.1,42.4,79.2z"
      />
      <path
        fill={colors[4]}
        d="M48.3,68.2c-0.6-0.5-1.4-0.5-1.7-0.1c-0.2,0.3-0.2,0.6,0,0.9l-4.1,5.1l-4.2-1.6l-0.2,0.9l8.7,6.1l1-0.1 L45,75.7l3.4-5.5c0.3,0,0.5-0.1,0.6-0.3C49.1,69.4,48.9,68.6,48.3,68.2z"
      />
      <path
        fill={colors[5]}
        d="M47.7,68.1c0,0,0.2,1.6,0.6,1.7S49.4,69.1,47.7,68.1z"
      />
      <path
        fill={colors[6]}
        d="M46.5,69.1c0,0,0.2,0.4,0.7,0.8s1,0.3,1,0.3s-0.3,0.6-1.2-0.1C46.2,69.5,46.5,69.1,46.5,69.1z"
      />
      <path
        fill={colors[7]}
        d="M46.5,69.1c0,0-0.3,0.4,0.5,1s1.2,0.1,1.2,0.1l-3.4,5.5c0,0-0.8,0.8-1.8,0s-0.6-1.5-0.6-1.5L46.5,69.1z"
      />
      <polygon
        fill={colors[5]}
        points="47.5,79.4 44.8,75.7 42.4,74.2 38.1,72.5 37.9,73.4 41.7,75.2 44,76.8 46.6,79.5"
      />
    </g>
  )
}

export default SwordRightHip
