import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FlameLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FlameLeft") ? (
    <g id="FlameLeft">
      <path d="M84.4410782,58.3228378c0,0,11.6107559,7.2607498,11.0522385,18.0820618 c-0.1396332,4.1888962,0.4188919,12.07798-11.1703873,12.1477966 C63.0250168,83.9318237,84.4410782,58.3228378,84.4410782,58.3228378z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FlameLeft, {
  character: "WomanGnome"
})
