import React from "react"

const RightShoulderGuard = ({ colors = ["#898888"] }) => {
  return (
    <g id="RightShoulderGuard">
      <path
        fill={colors[0]}
        d="M35.2713394,21.5950947c-0.3811493,0.1270504-0.8893471,0.1270504-1.397541,0.1270504 c1.0163918,0.6352444,1.7786903,1.5245895,2.2868843,2.6680298v0.1270504l0,0 c1.6516342,2.5409813-1.1434441,9.27458-1.905735,10.7991657c-1.3975372,3.9385185-3.3032742,7.7499886-5.3360615,11.5614624 c1.6516361-0.5081978,5.2090092-1.7786903,5.3360615-1.905735c0.762291-0.6352463,3.4303246-2.6680298,3.81147-3.4303284 c2.5409813-5.4631081,5.5901604-12.0696583,6.0983543-17.5327663c-1.7786903-0.7622948-6.0983543-1.2704906-7.4958954-2.7950802 C36.287735,21.3409996,35.7795372,21.4680481,35.2713394,21.5950947z"
      />
      <path
        opacity="0.28"
        d="M43.2754326,24.136076 c-0.5081978,5.3360615-3.4303284,11.8155613-5.9713058,17.1516209c-0.3811493,0.762291-3.0491791,2.7950821-3.8114738,3.4303246 c-0.1270447,0.1270485-1.7786884,0.7622948-3.3032722,1.2704926c-0.2540989,0.5081978-0.5081978,1.0163918-0.7622929,1.3975372 c1.651638-0.5081978,5.2090092-1.7786903,5.3360615-1.905735c0.762291-0.6352463,3.4303246-2.6680298,3.81147-3.4303284 c2.5409813-5.4631081,5.5901604-12.0696583,6.0983543-17.5327663 C44.164772,24.3901749,43.6565781,24.2631264,43.2754326,24.136076z"
      />
    </g>
  )
}

export default RightShoulderGuard
