import React from "react"

const Socks = ({ colors = ["#FFF8CA"] }) => {
  return (
    <g id="Socks">
      <g fill={colors[0]}>
        <path d="M60.8023682,102.1939011c0.4023209,2.0384293-1.2606125,11.2650223-1.2606125,11.2650223 c0.9655762,1.126503,2.2798271,2.7089767,2.4139366,5.9543762c-6.7590179,0-9.0924873,0-9.0924873,0 s-0.3486786-0.9119339,0.9387512-5.5788727c-0.4023209-5.3911209-0.8851089-9.3338776-1.1533241-11.506424 C54.0433502,103.7763672,59.0857925,103.6959,60.8023682,102.1939011z" />
        <path d="M36.6248436,101.9831161c-0.5096092,1.7165756,1.2337875,11.479599,1.2337875,11.479599 c-0.9655762,1.126503-2.2798271,2.7089691-2.4139366,5.9543686c6.7590179,0,9.0924873,0,9.0924873,0 s0.3486786-0.9119339-0.9387512-5.5788727c0.4023209-5.3911209,0.8851089-9.3338776,1.1533241-11.5064163 C43.3570366,103.7801514,38.3414192,103.4851151,36.6248436,101.9831161z" />
      </g>
      <g opacity="0.2" fill="#21376C">
        <polygon points="53.7214928,103.0924149 54.6066017,113.0968323 53.4532776,102.8778458" />
        <polygon points="54.9754066,103.2916641 55.1966743,113.0968323 54.8211746,103.1460648" />
        <polygon points="57.7715378,103.2533493 57.5301437,113.0968323 57.5569687,103.0924149" />
        <polygon points="59.0857925,102.8242035 58.3616104,113.0968323 58.951683,102.8510284" />
        <polygon points="43.6902542,103.0924149 42.8051453,113.0968323 43.9584694,102.8778458" />
        <polygon points="42.4363403,103.2916641 42.2150726,113.0968323 42.5905724,103.1460648" />
        <polygon points="39.6402092,103.2533493 39.8816032,113.0968323 39.8547821,103.0924149" />
        <polygon points="38.3259544,102.8242035 39.0501366,113.0968323 38.4600639,102.8510284" />
        <path d="M56.2158928,103.44104c0,0,0,9.6825638,0.0536423,9.6557388 c0.0536423-0.0268173-0.2682152-9.7093811-0.2682152-9.7093811L56.2158928,103.44104z" />
        <path d="M60.2391167,102.3145981c0,0-1.0192184,10.1653442-1.3142548,10.7822342 c0.1072884-1.099678,1.1801453-10.7285919,1.1801453-10.7554169 C60.1050072,102.3145981,60.2391167,102.3145981,60.2391167,102.3145981z" />
        <path d="M41.1958542,103.44104c0,0,0,9.6825638-0.0536423,9.6557388 c-0.0536423-0.0268173,0.2682152-9.7093811,0.2682152-9.7093811L41.1958542,103.44104z" />
        <path d="M37.1726303,102.3145981c0,0,1.0192184,10.1653442,1.3142548,10.7822342 c-0.1072884-1.099678-1.1801453-10.7285919-1.1801453-10.7554169 C37.3067398,102.3145981,37.1726303,102.3145981,37.1726303,102.3145981z" />
      </g>
      <g fill="#21376C">
        <path
          opacity="0.6"
          d="M35.4962883,118.5684204l0.0402336,0.0938721 c0,0-0.026825,0.2950363,1.2471962,0.509613c2.132309,0.241394,4.506012-0.0402374,4.9888-0.2816238 c0.4827881,0.1609268,1.1130905,0.4023209,1.8372726,0.3620834c0.7241783-0.0402298,0.9387512-0.9253387,0.9387512-0.9253387 c0.0126724,0.3638916,0.0978699,0.7381134,0,1.0862732h-9.0924873L35.4962883,118.5684204z"
        />
        <path
          opacity="0.6"
          d="M61.9168549,118.5684204l-0.0402336,0.0938721 c0,0,0.0268211,0.2950363-1.2472,0.509613c-2.132309,0.241394-4.506012-0.0402374-4.9887962-0.2816238 c-0.4827881,0.1609268-1.1130943,0.4023209-1.8372726,0.3620834c-0.7241821-0.0402298-0.938755-0.9253387-0.938755-0.9253387 c-0.0126724,0.3638916-0.0978699,0.7381058,0,1.0862656l9.0924873,0.0000076L61.9168549,118.5684204z"
        />
      </g>
    </g>
  )
}

export default Socks
