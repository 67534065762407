import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WarHammerL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WarHammerL") ? (
    <g id="WarHammerL">
      <path d="M86.6440277,107.8511658c-3.5462189-1.1076202-5.5300827-4.8936234-4.4225845-8.439476 l17.3659592-55.600071l-1.5073318-0.3391991c-3.9646072-1.6499367-5.7217636-3.0685921-6.4834061-5.2339058 c-0.4708481-1.3390694-0.4278793-2.7997055,0.1065216-4.0932083c0.3635406-1.1125603,1.8090591-6.3996181,3.0420761-11.0396957 l5.9558487-4.0997868c1.4413986,0.1520996,3.2567139,0.3299141,3.6762161,0.3510056c0,0,2.2240219,0.5931396,2.0957336,0.5350151 c0.0246506,0.0097065,0.1217804,0.0404415,0.1393204,0.0459232c0.1800842,0.0554428,1.7679443-1.7977123,2.3092728-3.0388985 l10.5222702,3.4192963c-0.1519775,0.689558-0.4821167,2.7562046-0.7736359,4.5797405 c-0.0428848,0.2670536-0.0843353,0.5269318-0.1246033,0.7791557c0.6888428,0.5601959,1.3531265,1.2619686,1.8429108,2.0933971 c0.4998398,0.4305477,0.973465,0.9283371,1.4278641,1.4979553c0.0460434,0.0581131,4.4965286,6.1178818,4.7498932,9.8488617 l-8.2516022,5.2315254c-0.0164108-0.0091362-5.1408463,1.1602859-5.1408463,1.1602859l-0.2241592,0.0153656l-1.1284027,0.0773392 l-0.000885,0.0041351c0.3791733,0.0409966,0.8301315,0.0474434,1.3308334-0.0244598l-18.0679016,57.8474808 C93.9758835,106.9748001,90.1898804,108.9586639,86.6440277,107.8511658z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WarHammerL, {
  character: "ManDragonborn"
})
