import React from "react"

const CalfWrapR = ({ colors = ["#493812"] }) => {
  return (
    <g id="CalfWrapR">
      <path
        fill={colors[0]}
        d="M59.3,96.8c0.1-0.4,0.2-0.8,0.3-1.2c-0.9,1-3.4,1.1-4.4,1.2c-1,0.1-2,0.1-3-0.2c2.6-0.2,5,0.1,7.4-1.3 c0.2-0.1,0.9-3.1,1.1-3.3c-1.1,0.4-4,0.6-5.5,0.5c-0.9-0.1-1.9-1.1-2.9-2.3c-0.2,0.2-0.4,0.5-0.5,0.8c-0.2-0.1-0.6-0.1-0.9-0.3 c-0.2,0.4-0.7,1.6-0.9,2.1c1.4,0.9,3.5,1.3,5.2,1.4c0.7,0.1,1.6,0.1,2.4,0.1c-2.1,0.7-5.1,0-7.5,0.3c-0.1,0-0.8,2.4-0.5,2.7 c0,0,2,0.8,2.5,0.9c-0.8,0.1-1.9,0.1-2.7,0.1c-0.2,0-0.1,2.6-0.1,2.6c1.6-0.1,8-0.6,9-1.3C58.5,99,59,97.4,59.3,96.8z"
      />
    </g>
  )
}

export default CalfWrapR
