import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LongBowLeft from "./LongBowLeft"
import LongSwordLeft from "./LongSwordLeft"
import MaulL from "./MaulL"
import GoldAxeLeft from "./GoldAxeLeft"

const CATEGORY = "weaponLeft"
const CHARACTER = "WomanDwarf"
export const IDS = {
  LONG_BOW_LEFT: "LongBowLeft",
  LONG_SWORD_LEFT: "LongSwordLeft",
  MAUL_L: "MaulL",
  GOLD_AXE_LEFT: "GoldAxeLeft"
}

export const components = {
  [IDS.LONG_BOW_LEFT]: LongBowLeft,
  [IDS.LONG_SWORD_LEFT]: LongSwordLeft,
  [IDS.MAUL_L]: MaulL,
  [IDS.GOLD_AXE_LEFT]: GoldAxeLeft
}

export const Group = ({ props }) => (
  <g id="weaponLeft">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LONG_BOW_LEFT,
    name: "Long Bow Left",
    defaultColors: [
      "#695E60",
      "#42130B",
      "#FFCD03",
      "#EDD692",
      "#B5AC95",
      "#915B25",
      "#A98A30"
    ],
    colorable: false,
    component: components[IDS.LONG_BOW_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_SWORD_LEFT,
    name: "Long Sword Left",
    defaultColors: [
      "#D0A92B",
      "#A8ABAD",
      "#D5D8DA",
      "#B49330",
      "#FFD144",
      "#EDD692",
      "#7B6528",
      "#A38634",
      "#7951A0"
    ],
    colorable: false,
    component: components[IDS.LONG_SWORD_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MAUL_L,
    name: "Maul L",
    defaultColors: ["#888787", "#FFFFFF"],
    colorable: true,
    component: components[IDS.MAUL_L],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_LEFT,
    name: "Gold Axe Left",
    defaultColors: ["#897441", "#A39155", "#EDCD6E"],
    colorable: false,
    component: components[IDS.GOLD_AXE_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
