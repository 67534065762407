import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const AxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("AxeLeft") ? (
    <g id="AxeLeft">
      <path
        id="S_x24_axeLeft"
        d="M77.4218216,114.1609421l-2.0948181-0.9775772l-2.6534271-5.5861664l5.4465103-24.0205154h-1.466362 c-2.723259-2.5836029-3.9103165-6.2146149-3.2818756-9.5663147l0,0c0.6982727-3.4215317,3.1422195-6.2844391,6.7033997-7.6111526 l2.5836029,1.1172333l3.4215317-0.8379211l2.6534271,0.4887848l2.9327393,2.0249863l2.723259-0.0698242 c2.7232513,2.5836029,3.9103165,6.2146149,3.281868,9.5663147v0.0698242 c-0.6982727,3.421524-3.1422195,6.2146072-6.7033997,7.6111526l-1.4663696-0.6284485 c-1.5361938,6.5637512-3.0723953,12.9878387-3.421524,15.1524811c-0.5586243,3.1422195-0.9077606,7.5413284-0.9775848,8.7982101 l-2.4439468,3.9103165c-0.5586166,0.3491364-1.1172333,0.5586166-1.7456741,0.7680969L77.4218216,114.1609421z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(AxeLeft, { character: "WomanGnome" })
