import React from "react"

const BardVest = ({
  colors = [
    "#8670A2",
    "#D8A16C",
    "#SVGID_",
    "#FFFFFF",
    "#AC7B56",
    "#F9BE7C",
    "#C7AA2E"
  ]
}) => {
  return (
    <g id="BardVest">
      <path
        fill={colors[0]}
        d="M36.5839996,70.3586502c0.5486526,3.2804642,1.1756783,5.5249863,1.1756783,5.5249863 L36.3488655,83.13517l13.0892105-1.0359344l-1.0189171-11.2226105l-5.0946045-13.7261238l-3.9189262,0.7769508 c0,0-1.7243271-0.7769508-4.5459518-1.8128815C35.8785934,58.6180687,37.0542717,66.2149124,36.5839996,70.3586502z"
      />
      <path
        fill={colors[0]}
        d="M60.88134,70.3586502c-0.5486526,3.2804642-1.1756783,5.5249863-1.1756783,5.5249863 L61.116478,83.13517l-12.3054276-0.9496078l0.0783768-11.3089371l5.1729813-13.6397934l3.9189262,0.7769508 c0,0,1.7243271-0.7769508,4.5459518-1.8128815C61.5083618,58.6180687,60.3326874,66.2149124,60.88134,70.3586502z"
      />
      <ellipse fill={colors[1]} cx="47.4" cy="71.999" rx="0.392" ry="0.432" />
      <ellipse
        fill="url(#SVGID_3_)"
        cx="47.4"
        cy="71.913"
        rx="0.392"
        ry="0.432"
      />
      <radialGradient
        id="SVGID_3_"
        cx="-1035.113"
        cy="709.594"
        r="0.395"
        gradientTransform="matrix(-1.04961 0 0 1.15607 -1039.103 -748.451)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.662" stopColor="#ac7b56" />
        <stop offset="1" stopColor="#f9be7c" />
      </radialGradient>
      <ellipse fill={colors[1]} cx="47.4" cy="75.279" rx="0.392" ry="0.432" />
      <ellipse
        fill="url(#SVGID_4_)"
        cx="47.4"
        cy="75.279"
        rx="0.392"
        ry="0.432"
      />
      <radialGradient
        id="SVGID_4_"
        cx="-1035.115"
        cy="712.497"
        r="0.395"
        gradientTransform="matrix(-1.04961 0 0 1.15607 -1039.103 -748.451)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.662" stopColor="#ac7b56" />
        <stop offset="1" stopColor="#f9be7c" />
      </radialGradient>
      <g>
        <ellipse fill={colors[1]} cx="47.4" cy="78.646" rx="0.392" ry="0.432" />
        <ellipse
          fill="url(#SVGID_5_)"
          cx="47.4"
          cy="78.646"
          rx="0.392"
          ry="0.432"
        />
        <radialGradient
          id="SVGID_5_"
          cx="-1035.111"
          cy="715.399"
          r="0.395"
          gradientTransform="matrix(-1.04961 0 0 1.15607 -1039.103 -748.451)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.662" stopColor="#ac7b56" />
          <stop offset="1" stopColor="#f9be7c" />
        </radialGradient>
      </g>
      <g>
        <path
          fill={colors[6]}
          d="M34.7029152,56.5462036c1.7243271,4.4027176,2.2729797,9.409729,1.7243271,14.1577606 c0,0.0863266,0.3918953-0.0863266,0.3918953-0.2589874c0.5486488-4.748024,0-9.7550392-1.7243271-14.1577606 C35.0948105,56.2008896,34.7029152,56.4598732,34.7029152,56.5462036L34.7029152,56.5462036z"
        />
      </g>
      <g>
        <path
          fill={colors[6]}
          d="M62.8408012,56.7188568c-1.7243233,4.4027176-2.2729797,9.409729-1.7243233,14.1577606 c0,0.0863266-0.3918953-0.0863266-0.3918953-0.2589874c-0.5486488-4.748024,0-9.7550392,1.7243271-14.1577606 C62.4489098,56.3735466,62.8408012,56.6325302,62.8408012,56.7188568L62.8408012,56.7188568z"
        />
      </g>
      <polygon
        opacity="0.42"
        fill="#21376C"
        points="49.438076,82.1855621 48.8894234,75.7973022 50.3002396,82.2718887"
      />
    </g>
  )
}

export default BardVest
