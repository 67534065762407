import React from "react"

const Knee = ({ colors = ["#996841", "#282560", "#FFFFFF"] }) => {
  return (
    <g id="Knee">
      <path
        fill={colors[0]}
        d="M54.490303,80.1134033c0.1062431-0.861763,9.0189857-0.4367905,8.8183022,0.6846848 c-0.1416588,1.1568832-0.5666389,4.9698868-0.6846886,5.5483322c-0.1180496,0.5784378-1.3103485,3.7775879-1.5818634,3.9428558 s-7.8384933,0.4013672-8.0037613-0.5430298c-0.1652718-0.944397,1.6290855-5.0525208,1.6290855-5.0525208 S54.3840561,80.9751587,54.490303,80.1134033z"
      />
      <path
        opacity="0.2"
        fill={colors[1]}
        d="M61.3253708,85.0832901 c0,2.1906128-1.5405159,3.9546585-2.9748497,3.9546585c-1.43433,0-2.5852852-2.1536026-2.5852852-4.344223 s1.2217827,1.2749329,2.6561165,1.2749329S61.3253708,82.8926697,61.3253708,85.0832901z"
      />
      <path
        opacity="0.19"
        fill="#FFF"
        d="M56.1193848,83.7861099 c0,0,2.5660248-3.2616196,4.9580841,0.191864C59.5552483,80.9082108,59.1638222,78.2861252,56.1193848,83.7861099z"
      />
      <path
        opacity="0.2"
        fill={colors[1]}
        d="M55.3048439,85.0478745 c0,0-2.1957245,4.4622726-1.9124031,4.32061c0.2833176-0.141655,0.8499565,0.3541489,0.8499565,0.3541489L55.3048439,85.0478745z"
      />
      <polygon
        opacity="0.19"
        fill="#FFF"
        points="54.8090363,80.5147705 55.1631851,84.6228943 55.517334,79.9481277"
      />
    </g>
  )
}

export default Knee
