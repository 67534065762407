import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Body from "./Body"

const CATEGORY = "body"
const CHARACTER = "WomanDwarf"
export const IDS = {
  BODY: "Body"
}

export const components = {
  [IDS.BODY]: Body
}

export const Group = ({ props }) => (
  <g id="body">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.BODY,
    name: "Body",
    defaultColors: ["#E8B180", "#CD977A"],
    colorable: false,
    component: components[IDS.BODY],
    inUI: false,
    enabled: true,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
