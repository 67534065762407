import React from "react"

const LongSkirt = ({ colors = ["#6B6B6B", "#231F20"] }) => {
  return (
    <g id="LongSkirt" fillRule="evenodd" clipRule="evenodd">
      <path
        fill={colors[0]}
        d="M84.0317383,79.5630951 c-3.0036621-27.2999878-6.4918213-26.0999756-6.4918213-26.0999756s-3.9725952,2.7999878-8.9141846,2.7999878 c-4.9415283,0.2000122-9.3017578-2.7000122-9.3017578-2.7000122s-5.7166748,5.6000366-6.6856079,25.7000122 c-0.9529419,5.9906006-4.7693481,27.2360229-5.5674438,31.6709595c1.8273315,0.3311157,3.9761963-0.5462036,5.6775513,0.3825073 c0.2106323,0.1149902,0.4743042,0.2792358,0.7648315,0.4465332h2.9124146 c0.0247803-0.0871582,0.0438843-0.1820679,0.078064-0.2560425c0.227478-0.4924316,0.2836304-0.1542969,0.2947388,0.2560425 h0.0253906c0.0581055-0.255127,0.1379395-0.4796753,0.2538452-0.6369019 c0.5440063-0.7377319,1.730835-0.7354126,2.5529785-0.5826416c0.8984985,0.1669312,1.5557861,0.5863037,2.1101074,1.2195435 h0.2836914c0.112854-0.3372192,0.2150879-0.552002,0.2895508-0.5390015 c2.1467896,0.3748779,4.3461914,0.3594971,6.5241699,0.5390015h6.4949341 c0.2877197-0.0844116,0.5770264-0.1879883,0.867981-0.3112183c0.2719116-0.2662354,0.6453247-0.4277344,1.0562134-0.5256958 c0.0065308-0.0038452,0.0128784-0.0062866,0.0194092-0.0101318c-0.0021973,0.0012817-0.0030518,0.0053101-0.005188,0.0067139 c0.7084351-0.1659546,1.520874-0.1426392,2.1181641-0.0923462c1.0706787,0.0901489,1.9761353,0.4110718,2.8168335,0.9326782 h0.2076416c0.0328979-0.2603149,0.0305786-0.4406128-0.036499-0.4849243 c0.2160034,0.1425781,0.4682007,0.3158569,0.7398682,0.4849243h3.086792 c0.319458-0.3970947,0.4707642-0.8067627,1.2720947-0.9894409c1.2203979-0.2781982,2.5789185,0.0921631,3.9012451,0.5501709 C90.5586548,108.182785,85.4562378,88.476181,84.0317383,79.5630951z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M75.4082642,54.7631073c0,0-3.9725952,1.5-6.8793945,1.5s-6.3949585-1.2999878-6.3949585-1.2999878L56.4279785,111.7556 c0.024231-0.0842896,0.0426636-0.1766968,0.0758057-0.2485352c0.2460938-0.5327759,0.2918091-0.095459,0.2965698,0.3560791 h0.0045776c0.059082-0.2958374,0.142395-0.5599976,0.2728271-0.7369385 c0.5440063-0.7377319,1.730835-0.7354126,2.5529785-0.5826416c0.9436646,0.175354,1.6206055,0.6306152,2.1916504,1.3195801 h0.1696777c0.1257935-0.3953857,0.2405396-0.6532593,0.3220215-0.6390381 c2.4932251,0.4354248,5.0584717,0.3338623,7.5770264,0.6390381h5.0592651 c0.401001-0.0958862,0.8031006-0.2258301,1.2086792-0.3949585c0.7340088-0.7555542,2.2699585-0.7186279,3.2307129-0.6377563 c1.1013794,0.0927734,2.0299683,0.4243774,2.8907471,0.9735107L75.4082642,54.7631073z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M56.5037842,111.5070648c0.2460938-0.5327759,0.2918091-0.095459,0.2965698,0.3560791h0.0045776 c0.059082-0.2958374,0.142395-0.5599976,0.2728271-0.7369385c0.5440063-0.7377319,1.730835-0.7354126,2.5529785-0.5826416 c0.8150024,0.1514282,1.4352417,0.50177,1.9573975,1.0378418c0.3583984-2.8417358,3.3556519-26.7769165,3.3556519-32.4182739 c0-6-0.3875732-23.5-0.3875732-23.5l-4.6508789-1.7999878c0,0-1.1627197,13.2999878-2.1316528,25.1999512 c-0.8657837,10.5444336-4.5917969,28.4226074-5.3754272,32.1105957c0.1170044,0.0466309,0.2369995,0.0810547,0.3502197,0.1428833 c0.2528076,0.1380005,0.5817871,0.3463745,0.942688,0.5465698h2.7040405 C56.4259033,111.7368011,56.4562988,111.6099701,56.5037842,111.5070648z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M82.401062,111.8631439c0.0472412-0.3164062,0.0521851-0.5350952-0.0233154-0.5849609 c0.2595215,0.1713257,0.5726929,0.3858032,0.9083862,0.5849609h2.2958984c0,0-4.5539551-25.4000244-5.9104614-34.4000244 c-1.2596436-9-2.8099365-23.4000244-2.8099365-23.4000244l-4.2632446,1.6000366c0,0-0.1937866,15.2999878,0.0968628,22.5999756 c0.2687988,6.7492065,3.1030884,29.7232056,3.529541,33.1541138c0.7547607-0.6959229,2.2293701-0.6655884,3.164978-0.586792 c1.1448975,0.0964355,2.1057129,0.4433594,2.994873,1.0327148H82.401062z"
      />
    </g>
  )
}

export default LongSkirt
