import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const TatteredCape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("TatteredCape") ? (
    <g id="TatteredCape">
      <path
        id="tatteredCape"
        d="M101.8000031,100.1999969C104.5,69.4000015,86.6999969,25,67.5999985,27 S45.9000015,48,35.2999992,66.1999969C31.3999996,72.8000031,24.5,84.6999969,18.3999996,91 c-3.5,3.5999985-4.8999996,6.6999969-2.2000008,12C17.3999996,105.5,95.3000031,132,101.8000031,100.1999969z M34.0999985,96.5 C34,96.6999969,32.2000008,98.4000015,34.0999985,96.5z M35.2999992,95 C33.5999985,96.8000031,36.7999992,93.5999985,35.2999992,95z M35.5999985,86C34.2000008,85.3000031,36,85.6999969,35.5999985,86z M37.5,98.4000015C34.0999985,98.4000015,38.0999985,97.3000031,37.5,98.4000015z M37.5999985,85.0999985 C37.2999992,84.8000031,37.5999985,84.6999969,37.5999985,85.0999985z M37,84.5C34.5999985,85.3000031,38,84,37,84.5z M39.2999992,89.8000031c-0.4000015,0,0.5-0.8000031,0.7999992-0.8000031C40.5,89,39.5999985,89.8000031,39.2999992,89.8000031z M70.6999969,107.1999969C68,110.5999985,71.9000015,106,70.6999969,107.1999969z M71.5999985,105.3000031 C67.6999969,107.9000015,73.9000015,103,71.5999985,105.3000031z M87.5999985,96.8000031 c-0.6999969,0,0.6999969-1.3000031,1.1999969-1.3000031C89.5999985,95.4000015,88.1999969,96.8000031,87.5999985,96.8000031z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(TatteredCape, {
  character: "WomanMixed"
})
