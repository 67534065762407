import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
// import { createStore, applyMiddleware, compose } from 'redux'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import createStore, { history } from './store'

import App from './App';

const store = createStore()

// render the application
const ConnectedApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>

ReactDOM.render(<ConnectedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
