import React from "react"

const WizardHat = ({ colors = ["#6C7670", "#7E8A83", "#49504C"] }) => {
  return (
    <g id="WizardHat">
      <path
        fill={colors[0]}
        d="M74.8,35.7c-0.1,0,0-0.1,0.2-0.1c-0.7-0.6-5.3,0-13.3,0.5c-1.7,0.1-10,0.7-12.2,1 c-3.3,0.3-7.5,0.6-10.8,0.8c-3.6,0.2-6.1,0.2-9.6,0.7c-2.3,0.3-5.3,0.9-7.4,1.6c-0.3,0.2-0.5,0.3-0.5,0.5c0,0.1,0.1,0.2,0.2,0.2 l0,0c0.3,0,0.7-0.1,1-0.1c0.1,0,0.2,0,0.3,0c0.7-0.1,2.1-0.3,2.4,0c0.1,0.1-0.5,0.1-0.5,0.2c-0.6,0-1.2,0-1.8,0 c2.3,0.3,6.9,0.3,11.5,0.5c4.3-0.1,9.4-0.4,14.8-0.8c3-0.2,5.8-0.6,8.5-0.8c1.7-0.2,3.4-0.5,4.9-0.7l7.8-2.5l4.8-0.7v-0.1 C75,35.8,74.9,35.8,74.8,35.7z M25.6,39.8c-0.7,0.1,0.8-0.3,1.1-0.3C27.4,39.4,25.9,39.7,25.6,39.8z"
      />
      <path
        fill={colors[1]}
        d="M55.8,37.5l-5.9-10.9c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.2-0.3-0.3-0.4s0-0.1,0-0.2c0.1-0.1,0.2-0.1,0.3-0.2 c0,0,0,0,0-0.1c-0.1,0-0.2,0-0.3,0c0,0-0.4,0-0.3-0.1c0.2-0.2,0.3-0.4,0.4-0.5l-2.2-8.9l-5,11.5c0.1,0,0.2,0,0.2,0.1 s-0.2,0.2-0.4,0.2V28v0.1c0.1,0,0.1,0,0.2,0c0,0,0.4,0,0.3,0.1c-0.2,0.2-0.3,0.3-0.5,0.5l-1.4,9.7c-0.1,0.1-0.2,0.2-0.2,0.3 c0.1,0.8,3.6,0.9,7.9,0.6c4.3-0.3,7.7-0.8,7.6-1.7C55.9,37.7,55.8,37.6,55.8,37.5z"
      />
      <path
        fill={colors[2]}
        d="M72,35.8c-4.4,2.4-15.2,4.1-16.5,4.2c7.7-0.5,15.6-2.5,17.4-3.3c0.7-0.3,1.5-0.6,2-0.8 c0.1,0,0.1-0.1,0.2-0.1c-0.1,0-0.3,0-0.4-0.1c-0.2,0,0.4-0.2,0.5-0.2s0.3,0,0.4-0.1C76.5,35,77,34.3,72,35.8z M72.5,36 c-0.7,0.1,0.8-0.3,1.1-0.3C74.3,35.7,72.8,36,72.5,36z"
      />
    </g>
  )
}

export default WizardHat
