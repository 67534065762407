import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BattleAxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BattleAxeLeft") ? (
    <g id="BattleAxeLeft">
      <path
        id="S_x24_battleAxeLeft"
        d="M88.9161224,118.0162354l0.1512909-0.7350311l0.8052902-37.5630493l2.2097626,0.3306122 c4.1944046-3.1745682,6.0338135-7.7346497,5.17733-12.8289413c-0.5473175-3.4776077-2.239212-6.0726128-2.4148026-6.3261833 l-5.6570053-0.5846252l-0.5557175-1.6260262l-3.130661-2.0939217l-3.410347,2.0313911l-0.6131363,1.5200272l-5.6873703,0.2167206 c-0.1965942,0.3012047-1.9566269,2.7654762-2.7061081,6.2556992c-1.0544128,5.0219193,0.6197433,9.7150726,4.628334,13.0768433 l2.2307663-0.2241669l-0.8052902,37.5630493l0.0406265,0.5571136 C80.103714,117.6207581,87.3727493,118.019516,88.9161224,118.0162354z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BattleAxeLeft, {
  character: "WomanGnome"
})
