import React from "react"
import { withSkinColor } from "../../builderConnector"

const Ears = ({ skinColor, colors = ["#E7B080", "#353891"] }) => {
  return (
    <g id="Ears">
      <g fill={skinColor}>
        <path d="M74.1299744,53.0284271c0.1072845-0.1072884,1.6943054-1.8189735,1.774765-1.8457985 c0.03862-0.0128708,0.648735-1.7771416,0.4023209-2.682148c0.1328201-0.1097908,0.4887543-0.246109,0.8314667,0.0536423 c1.3142548,2.3602943-0.61689,5.9735909-0.61689,5.9735909s-0.026825,1.9311447-0.0536423,2.0116119 c-0.014389,0.0431519-0.3375702,0.684124-0.8896637,0.9682884c-0.5900726-0.8582878-0.9819031-0.6581001-1.0192184-0.670536 C74.478653,56.810257,74.1299744,53.0284271,74.1299744,53.0284271z" />
        <path d="M62.5220184,53.0284271c-0.1072845-0.1072884-1.6943016-1.8189735-1.7747688-1.8457985 c-0.0386124-0.0128708-0.6487312-1.7771416-0.4023209-2.682148c-0.1328163-0.1097908-0.4887505-0.246109-0.8314667,0.0536423 c-1.3142509,2.3602943,0.6168938,5.9735909,0.6168938,5.9735909s0.0268211,1.9311447,0.0536423,2.0116119 c0.0143852,0.0431519,0.3375702,0.684124,0.8896599,0.9682884c0.5900726-0.8582878,0.9819069-0.6581001,1.0192184-0.670536 C62.1733398,56.810257,62.5220184,53.0284271,62.5220184,53.0284271z" />
      </g>
      <g fill={colors[1]}>
        <path
          opacity="0.2"
          d="M75.2267609,52.3819809c0.7241745,0.1609268,1.5288239-0.8104515,1.9311447-2.8220634 c0.1609268,2.132309-1.0121536,2.9391098-1.7299881,3.8278694c0.1609268,0.2270813,0.3802719,0.2559814,0,1.3276634 c-0.0571518,0.042263-0.1223984,0.3447723-0.1609268,0.7644119l0.7644119-0.3620911 c0,0.1728554-0.5230179,0.2557068-0.6236038,0.617794c-0.1005783,0.3620911-0.0636063,0.6647186-0.1032333,0.6364174 c-0.0777206-0.0555153-0.2235031-0.5328026-0.1985016-1.0990868c0.0192642-0.4365425,0.2135162-0.9660568,0.1639481-1.1479874 c-0.1565552-0.5746155-0.6914215-0.3457069-0.6914215-0.3457069S74.2933502,53.6610947,75.2267609,52.3819809z"
        />
        <path
          opacity="0.2"
          d="M61.4252357,52.3819809c-0.7241821,0.1609268-1.5288277-0.8104515-1.9311485-2.8220634 c-0.1609306,2.132309,1.0121536,2.9391098,1.7299881,3.8278694c-0.1609306,0.2270813-0.3802681,0.2559814,0,1.3276634 c0.0571518,0.042263,0.1223984,0.3447723,0.1609268,0.7644119l-0.7644119-0.3620911 c0,0.1728554,0.5230179,0.2557068,0.6236,0.617794c0.1005783,0.3620911,0.0636101,0.6647186,0.1032372,0.6364174 c0.0777206-0.0555153,0.2234993-0.5328026,0.1985054-1.0990868c-0.0192719-0.4365425-0.2135201-0.9660568-0.1639481-1.1479874 c0.1565514-0.5746155,0.6914177-0.3457069,0.6914177-0.3457069S62.3586388,53.6610947,61.4252357,52.3819809z"
        />
      </g>
    </g>
  )
}

export default withSkinColor(Ears, { character: "WomanGnome" })
