import React from "react"

const LuteLeft = ({
  colors = ["#93712B", "#D8A43D", "#6A5121", "#B68B32", "#8E6D2B"]
}) => {
  return (
    <g id="LuteLeft">
      <path
        fill={colors[0]}
        d="M66.0718231,77.6877441H65.490387l-0.0726776,0.2907181l-0.3634033,0.0726776 l-0.2907181-0.5087585V77.106308l0.5087509,0.0726776l0.1453629,0.2180405l0.726799,0.0726776 C66.1445007,77.5423813,66.217186,77.6877441,66.0718231,77.6877441z"
      />
      <path
        fill={colors[0]}
        d="M66.1445007,77.0336227L65.490387,77.106308v0.1453476l-0.2907257-0.1453476 l-0.2907257-0.2907257l-0.0726776-0.3634033l0.3634033,0.1453629l0.2907257,0.2180405l0.726799,0.0726776 C66.217186,76.9609451,66.2898636,77.0336227,66.1445007,77.0336227z"
      />
      <path
        fill={colors[0]}
        d="M66.217186,76.5248642h-0.5814514l-0.07267,0.1453552l-0.3634033-0.0726776 l-0.2907257-0.2907104l-0.0726776-0.3634033l0.3634033,0.1453552l0.2907257,0.2180481l0.726799,0.0726776 C66.2898636,76.379509,66.3625336,76.5248642,66.217186,76.5248642z"
      />
      <path
        fill={colors[0]}
        d="M65.9264603,78.5598984l-0.6541214,0.07267l-0.0726776,0.1453629h-0.3634033 l-0.2907104-0.4360733l-0.0726852-0.3634033l0.3633957,0.1453552l0.2907257,0.2180481l0.726799,0.0726776 C65.9264603,78.4145432,65.9991379,78.5598984,65.9264603,78.5598984z"
      />
      <path
        fill={colors[1]}
        d="M60.4027977,88.2989807c-3.8520279-0.1453552-11.4833946,10.4658813-12.137516,12.718956 c-0.6541176,2.3257446-0.8721542,4.9422302,2.3257561,6.6138535c3.1979065,1.6716309,7.5586929-1.453598,8.7942467-3.2705841 C60.7661972,102.5442047,63.0192719,88.7350616,60.4027977,88.2989807z"
      />
      <path
        fill={colors[2]}
        d="M67.5980988,77.6150665l-0.0726776-0.726799l-6.2504616,12.3555527 c0,0,2.2530746,20.3503265-10.6112442,18.4606552c0,0,8.2128143,4.7968597,11.7741165-6.3231354 c0.8721542-5.2329407-0.2907143-11.4834061-0.2907143-11.4834061"
      />
      <path
        fill={colors[0]}
        d="M67.307373,79.7227783l0.5814362,0.2180405v0.1453629l0.2907257,0.1453476 l0.3634033-0.2180328l0.1453476-0.2907257h-0.5087509h-0.2180405l-0.726799-0.0726776 C67.2346954,79.5774155,67.1620178,79.7227783,67.307373,79.7227783z"
      />
      <path
        fill={colors[0]}
        d="M67.4527359,78.1238174l0.5814362,0.2180405v0.2907257l0.2907257,0.1453552 l0.3633881-0.3633957l0.1453629-0.4360809h-0.5087509l-0.2180481,0.1453552H67.4527359 C67.4527359,77.9784622,67.3800507,78.0511398,67.4527359,78.1238174z"
      />
      <path
        fill={colors[0]}
        d="M67.5254211,77.469696l0.5814362,0.2180481v0.2180328l0.2907257-0.07267 l0.3633881-0.2180405l0.1453629-0.2907257h-0.4360733l-0.3634033,0.1453552l-0.726799-0.0726776 C67.5254211,77.3243408,67.4527359,77.469696,67.5254211,77.469696z"
      />
      <path
        fill={colors[0]}
        d="M67.6707764,76.9609451l0.5814362,0.1453629v0.1453476l0.3633957,0.0726776 l0.3634033-0.2180328l0.1453629-0.2907257h-0.4360886l-0.3633881,0.1453705l-0.726799-0.0726776 C67.6707764,76.8155899,67.5254211,76.8882675,67.6707764,76.9609451z"
      />
      <path
        fill={colors[0]}
        d="M67.4527359,78.9959793l0.5814362,0.2180405v0.2180405l0.3634033,0.0726776 l0.3633957-0.3634033l0.1453552-0.2907181H68.470253l-0.3634033,0.1453552h-0.6541138 C67.3800507,78.8506165,67.307373,78.9959793,67.4527359,78.9959793z"
      />
      <polygon
        fill={colors[3]}
        points="59.0945587,88.662384 65.9991379,76.1614685 67.743454,76.5975418 61.2749596,89.2438202"
      />
      <polygon
        fill={colors[4]}
        points="49.4281654,102.3261795 53.3528671,104.2885284 53.6435814,103.4163666 49.8642387,101.5267029"
      />
      <polygon
        fill={colors[0]}
        points="67.5254211,77.0336227 67.5980988,77.6150665 67.0893326,80.0861816 66.4352188,79.9408112"
      />
      <polygon
        fill={colors[2]}
        points="67.307373,79.7227783 67.0893326,80.0861816 67.4527359,76.8155899 67.743454,76.5975418"
      />
      <ellipse
        transform="rotate(-58.149 55.998 95.43)"
        fill={colors[0]}
        cx="55.998"
        cy="95.43"
        rx="1.89"
        ry="1.308"
      />
      <line fill="none" x1="50.809" y1="102.399" x2="66.217" y2="76.307" />
      <line fill="none" x1="51.172" y1="102.544" x2="66.653" y2="76.307" />
      <line fill="none" x1="51.609" y1="102.617" x2="67.017" y2="76.452" />
      <line fill="none" x1="51.972" y1="102.835" x2="67.38" y2="76.525" />
    </g>
  )
}

export default LuteLeft
