import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BattleAxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BattleAxeRight") ? (
    <g id="BattleAxeRight">
      <path
        id="S_x24_battleAxeRight"
        d="M17,119.4l1-62.1l-3.6,0.4c-6.6-5.4-9.3-12.9-7.6-21.1c1.1-5.6,4-9.7,4.3-10.1l9.2-0.5l1-2.6 l5.2-3.2l5.4,3.5l0.9,2.5l9.2,0.7c0.3,0.5,3,4.6,4,10.3c1.4,8.3-1.5,15.7-8.2,20.9l-3.6-0.5l-1,61.9L17,119.4z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BattleAxeRight, {
  character: "ManDwarf"
})
