import React from "react"

const SideBodyBag = ({ colors = ["#21376C", "#724B2B", "#FFFFFF"] }) => {
  return (
    <g id="SideBodyBag">
      <path
        opacity="0.3"
        fill={colors[0]}
        d="M79.4000092,18.5 c-0.0999985-0.1000004-9,17.2000008-9.0999985,17.1000023l-0.5999985,0.2000008 c-2.6999969,5-12.0999985,7.7999992-12.0999985,7.7999992c-5.0999985,4.2000008-6.9000015,12-3.0999985,17.3000031 c2.0999985,2.9000015,8.9000015-1.5999985,13.5999985-8.2999992c0.0999985-0.0999985,0.0999985-0.2000008,0.1999969-0.2999992 c0.3000031-0.9000015,0.6999969-1.7999992,1.0999985-2.7000008c1.5-3.7000008,2.4000015-7.7000008,4.1999969-11.2999992 c1-2.4000015,8-14,9.4000015-17.7999992C83.2000122,19.5999985,79.5000076,18.5999985,79.4000092,18.5z"
      />
      <path
        fill={colors[1]}
        d="M79.2000122,17.7999992c-0.0999985-0.1000004-9,17.2000008-9.0999985,17.0999985l-0.5999985,0.2000008 c-2.6999969,5-12.9000015,7.7000008-12.9000015,7.7000008C51.5000153,47,50.5000153,54.8999977,54.3000145,60.2000008 c1.7000008,2.3000031,6.4000015-0.0999985,10.6000023-4.5999985c1.0999985-2,2.3000031-4,3.3000031-6.0999985 c1.5999985-3.0999985,2.5999985-6.7000008,4.5-9.7000008c0.1999969-0.4000015,0.3000031-0.7000008,0.5-1.0999985 c0-0.4000015,0-0.5999985-0.0999985-0.7999992c-0.3000031-0.5,8-14.1000004,9.5-18.2000008 C83.1000061,18.8999996,79.3000107,17.8999996,79.2000122,17.7999992z"
      />
      <path
        opacity="0.64"
        fill={colors[0]}
        d="M52.4000092,49.7999992 C52.3000107,50,54.2000084,53.7000008,54.2000084,53.7000008S68.2000122,39.5,68.5000076,38.5 c0.3000031-1,1-1.7999992,1-1.7999992s0.1999969,1.2000008,0,1.4000015s-5.5999985,6.4999962-5.5999985,6.4999962 l-9.5,10.1000023l-1.9000015-4.0999985L52.4000092,49.7999992z"
      />
      <path
        opacity="0.64"
        fill={colors[0]}
        d="M54.2000084,51.2999992 c0,0-0.7999992,1.4000015-0.7999992,3.5c0.0999985,2.0999985-1.0999985,3.5-1.0999985,3.5v1.3000031l1.5999985-1.0999985 c0,0,1-7.4000015,1.5999985-7.4000015S54.2000084,51.2999992,54.2000084,51.2999992z"
      />
      <path
        fill={colors[1]}
        d="M53.8000107,51.2999992c0,0-0.7999992,1.4000015-0.7999992,3.5 c0.0999985,2.0999985-1.0999985,3.5-1.0999985,3.5v1.3000031l1.5999985-1.0999985c0,0,1-7.4000015,1.5999985-7.4000015 S53.8000107,51.2999992,53.8000107,51.2999992z"
      />
      <path
        opacity="0.3"
        fill={colors[0]}
        d="M81.9000092,19.0999985 c-1.4000015,3.3999996-6.1999969,13.2999992-8.0999985,16.3999977c-1.3000031,4.7000008-3.6999969,9.4000015-6.5,13 s-6.5999985,9.1999969-11.2999992,10.1999969c-0.5,0.0999985-0.9000015-0.1999969-1-0.5999985c0,0,0,0,0-0.0999985l0,0 c-0.0999985-0.1999969-0.0999985-0.5-0.0999985-0.5c-0.0999985-0.3000031-0.2999992-1.2000008-0.2999992-1.0999985 c-0.0999985,0.0999985-0.2000008,0.0999985-0.2999992,0.1999969c-0.0999985,0.3000031-0.2000008,1.5999985-0.5,1.6999969 c-0.0999985,0.1999969-0.2000008,0.4000015-0.2000008,0.6999969c0.2999992,0.5,0.5999985,1,0.9000015,1.5 c1.5999985,2.3000031,6.2000008,0,10.3000031-4.2999992c1.1999969-2.2000008,2.5-4.4000015,3.6999969-6.7000008 c1.5999985-3.0999985,2.5999985-6.5,4.4000015-9.4000015c0.1999969-0.4000015,0.4000015-0.7999992,0.5-1.2000008 c0,0,0-0.0999985,0.0999985-0.0999985c0-0.2000008,0-0.4000015-0.0999985-0.5 c-0.1999969-0.4000015,6.8000031-12.2000008,9.5-18.2000008C82.9000092,19.5999985,82.2000122,19.0999985,81.9000092,19.0999985z"
      />
      <path
        fill={colors[0]}
        d="M54.4000092,49.7000008l1.5-0.4000015 C55.9000092,49.2999992,55.3000107,48.5999985,54.4000092,49.7000008z"
      />
      <path
        fill={colors[0]}
        d="M53.8000107,51.2999992l3-0.5C56.8000107,50.7000008,54.6000099,50.5,53.8000107,51.2999992z"
      />
      <polygon
        fill={colors[0]}
        points="56.8000107,50.7000008 57.0000076,49 57.4000092,49.0999985 57.2000084,50.3999977"
      />
      <g opacity="0.54" fill="#FFF">
        <path
          opacity="0.23"
          d="M65.2000122,40.3999977 c1-0.9000015,3.8000031-4.2000008,4.4000015-5.4000015c-0.6999969,0.9000015-2.3000031,3.2999992-4,3.9000015 c-0.4000015,0.5999985-1.9000015,1.5999985-2.2999992,2.2000008C63.6000099,41.0999985,64.9000092,40.5,65.2000122,40.3999977z"
        />
        <path
          opacity="0.23"
          d="M61.5000076,41.7999992 c0.2000008-0.4000015,3.0999985-2.0999985,3.3000031-2.4000015c-2.9000015,1.2999992-8.2000008,3.4000015-8.2000008,3.4000015 c-0.5999985,0.5-1.0999985,1-1.5999985,1.5999985C57.2000084,43.3999977,59.4000092,42.5,61.5000076,41.7999992z"
        />
        <path
          opacity="0.23"
          d="M77.8000107,20.6999989 c-0.0999985,0.2999992-0.3000031,0.6000004-0.5,0.8999996c1,0,1.9000015,0,2.9000015,0 c0.0999985-0.2000008,0.1999969-0.3999996,0.3000031-0.6000004 C79.6000061,20.8999996,78.7000122,20.7999992,77.8000107,20.6999989z"
        />
        <path
          opacity="0.23"
          d="M78.6000061,18.8999996 c-0.1999969,0.3999996-0.4000015,0.7999992-0.6999969,1.2999992c0.9000015,0,1.9000015,0.3999996,2.8000031,0.3999996 c0.1999969-0.3999996,0.6999969-1.2000008,0.9000015-1.5C80.8000107,19,79.5000076,19,78.6000061,18.8999996z"
        />
        <path
          opacity="0.23"
          d="M75.4000092,24.8999977 c-0.4000015,0.7000008-0.6999969,1.4000015-1.0999985,2.0999985c0.8000031,0.2000008,2.3000031,0.9000015,3.0999985,1 c0.3000031-0.4000015,1.3000031-2.2999992,1.4000015-2.5C78.4000092,25.5999985,75.7000122,25.2000008,75.4000092,24.8999977z"
        />
        <path
          opacity="0.23"
          d="M74.1000061,27.5 c-0.4000015,0.7999992-0.9000015,1.5999985-1.3000031,2.4000015c0.8000031,0.0999985,2.5,0.5,3.3000031,0.5999985 c0.4000015-0.7000008,0.8000031-1.5,1.1999969-2.2000008C76.6000061,28.2000008,74.9000092,27.7000008,74.1000061,27.5z"
        />
        <path
          opacity="0.23"
          d="M72.4000092,30.7000008 c-0.1999969,0.5-0.5,0.9000015-0.6999969,1.2999992c0.5999985,0.2000008,2.4000015,1.0999985,3,1.2999992 c0.4000015-0.5999985,0.8000031-1.4000015,1.1999969-2C75.1000061,31.2999992,73.2000122,30.7999992,72.4000092,30.7000008z"
        />
        <path
          opacity="0.23"
          d="M53.3000107,52.8999977 c-0.2999992-0.5999985-0.7000008-1.2999992-1-1.9000015h-0.0999985c-0.2999992,1.9000015-0.0999985,3.9000015,0.5,5.6999969 c0.2999992-1.2000008,0.5999985-2.4000015,0.7999992-3.5999985C53.4000092,53.0999985,53.4000092,53,53.3000107,52.8999977z"
        />
        <path
          opacity="0.23"
          d="M54.1000099,50c0,0.4000015,0,0.0999985,0.0999985,0.5 c0,0.0999985,0,0.0999985,0.0999985,0.2000008c0.2000008-0.5,0.5999985-0.5999985,1.0999985-0.5l1.2000008-0.2999992 C57.4000092,49.2000008,55.1000099,49.2000008,54.1000099,50z"
        />
        <path
          opacity="0.23"
          d="M63.6000099,47.5999985 c0.4999962,0.2000008,1.3999977,0.2000008,2.2000008,0.2000008 C66.1000061,47.3999977,63.8000107,47.3999977,63.6000099,47.5999985z"
        />
        <path
          opacity="0.23"
          d="M61.9000092,49.2000008 C60.1000099,51,56.5000076,53.6000023,55.0000076,54.4000015c-0.0999985,0.5-0.2000008,1.5999985-0.2999992,2.1000023 c0.2999992,0,0.5,0.0999985,0.7000008,0.4000015c1,2.3000031,6.2000008-3.9000015,8.5-6.7999992 C63.2000084,49.8999977,62.5000076,49.5,61.9000092,49.2000008z"
        />
        <path
          opacity="0.23"
          d="M64.0000076,50 c0.5-0.5999985,0.8000031-1,0.9000015-1.2000008c0.0999985-0.0999985,0.1999969-0.2000008,0.1999969-0.2999992 c-0.6999969-0.0999985-1.2999992-0.2999992-1.9000015-0.5999985C62.8000031,48.2000008,62.5000038,48.6000023,62.1000061,49 C62.7000084,49.2999992,63.4000092,49.7000008,64.0000076,50z"
        />
      </g>
    </g>
  )
}

export default SideBodyBag
