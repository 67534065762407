import React from "react"

const BeardTwo = ({ colors = ["#A96F6F", "#40393B", "#D9A53F"] }) => {
  return (
    <g id="BeardTwo">
      <path
        fill={colors[0]}
        d="M50.6,50.3h-4c-0.3,0-0.6-0.3-0.6-0.6l0,0c0-0.3,0.3-0.6,0.6-0.6h4c0.3,0,0.6,0.3,0.6,0.6l0,0 C51.3,50,51,50.3,50.6,50.3z"
      />
      <path
        fill={colors[1]}
        d="M49.5,66.4C55.3,60,58,52.7,58,52.7c0-1.9-0.8-7.1-1.1-7.2c-0.5-0.2-1.8,2.3-2.8,2.8 c-1.2,0.7-2.5-0.7-3.4-0.6c-1.2,0.1-2.3,0.5-2.3,0.5s-1.4-0.7-2.3-0.7c-0.9,0-1.9,1.2-2.8,0.6c-1.1-0.7-2.6-2.7-3.4-2.6 c-0.8,0.1-0.8,5.5-0.8,7.2c2.8,8.1,8.9,14.4,8.9,14.4s-1.2,2-1.1,3.7c0.5,1.7,0.5,1.7,1.6,3.9c2.3-5.1,0.5-7.3,0.7-7.7 M50.8,49.9 c0,0-0.6,0.1-2.2,0.1s-2.2-0.2-2.2-0.2s-0.8-0.3,0.3-0.3c2.1-0.1,1.8-0.6,1.8-0.6s0,0.9,1.9,0.7C52,49.3,50.8,49.9,50.8,49.9z"
      />
      <path
        fill={colors[2]}
        d="M49.8,67.9h-2.3c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h2.3c0.1,0,0.2,0.1,0.2,0.2l0,0 C50,67.8,49.9,67.9,49.8,67.9z"
      />
      <path
        fill={colors[2]}
        d="M49.8,67.4h-2.3c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h2.3c0.1,0,0.2,0.1,0.2,0.2l0,0 C50,67.3,49.9,67.4,49.8,67.4z"
      />
      <path
        fill={colors[2]}
        d="M49.8,66.9h-2.3c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h2.3c0.1,0,0.2,0.1,0.2,0.2l0,0 C50,66.8,49.9,66.9,49.8,66.9z"
      />
    </g>
  )
}

export default BeardTwo
