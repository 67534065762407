import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BattleAxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BattleAxeRight") ? (
    <g id="BattleAxeRight">
      <path
        id="S_x24_battleAxeRight"
        d="M37.2545052,120.811142l0.1225777-0.7875061l-1.0833092-39.887352l2.3610992,0.2367172 c4.2855568-3.5838394,6.0015411-8.5159302,4.8302422-13.8756332c-0.7599678-3.6607094-2.68853-6.326149-2.887867-6.586071 l-6.0309525-0.3283577l-0.6733627-1.6961784l-3.428915-2.0596924l-3.5128231,2.3307533L26.3792,59.8018494l-6.0218163,0.5232506 c-0.1930065,0.3296471-1.9328938,3.034462-2.5478935,6.7754478c-0.8594532,5.3815079,1.1585236,10.2735138,5.5841274,13.6328125 l2.3547707-0.3528519l1.0833111,39.887352l0.0718346,0.5888748 C27.8861599,120.8461838,35.6175079,120.8942261,37.2545052,120.811142z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BattleAxeRight, {
  character: "ManGnome"
})
