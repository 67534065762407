import React from "react"

const SwordRightHip = ({
  colors = [
    "#8A7C6B",
    "#665C4F",
    "#3D3730",
    "#615240",
    "#838383",
    "#C7C7C6",
    "#4D4D4D",
    "#5C3434"
  ]
}) => {
  return (
    <g id="SwordRightHip">
      <path
        fill={colors[0]}
        d="M43.9365654,80.6222534l-16.881424,26.0497818c0,0-1.3825302,2.0374146-0.8004131,2.6195297 c0.5821171,0.6548843,1.7463531-1.5280609,1.7463531-1.5280609l17.8273678-25.8314819c0,0,0.2182922-0.3638229-0.582119-1.0914688 C44.445919,80.2584305,43.9365654,80.6222534,43.9365654,80.6222534z"
      />
      <path
        fill={colors[1]}
        d="M26.2547283,109.2915726c0.5821171,0.6548843,1.7463531-1.5280609,1.7463531-1.5280609 l17.8273678-25.8314896c0,0,0.2182922-0.3638229-0.582119-1.0914688c0.0727654,0.8004074-1.4552956,2.9105835-3.5654716,6.1849976 c-4.0020638,6.1122437-10.4053631,15.2806015-14.3346615,21.1745453 C26.9823761,108.7822189,26.5457859,108.8549805,26.2547283,109.2915726z"
      />
      <path
        fill={colors[2]}
        d="M43.7182693,80.9133148c0,0-0.2910614,0.509346,0.582119,1.0914688 c0.8731766,0.5821228,1.3825302,0.2182999,1.3825302,0.2182999L43.7182693,80.9133148z"
      />
      <path
        fill={colors[3]}
        d="M44.6642151,83.6056061c0,0-0.6548843,0.7276459-2.1101799-0.8731766 c0.0727615-0.1455231,0.1455307-0.2910538,0.2910576-0.4365845c-0.1455269,0.2182999-4.8024712-1.6008224-4.8024712-1.6008224 v1.6008224l3.9292984,1.3825302c0,0-0.5093536,1.4552994,1.746357,1.3825302c0.0727615,0,0.2182922-0.2182922,0.2182922-0.2182922 c2.1829414-0.0727615,2.6195297,0.3638229,2.6195297,0.3638229l0.7276497-0.8731766c0,0-1.382534-1.0914688-2.4012375-1.0187073 C44.809742,83.3145523,44.7369766,83.5328445,44.6642151,83.6056061z"
      />
      <path
        fill={colors[4]}
        d="M48.9573326,75.6014862c-0.4365883-0.3638229-1.0187073-0.3638229-1.2369995-0.0727692 c-0.1455307,0.2182999-0.1455307,0.4365921,0,0.6548843l-2.9833565,3.7110062l-3.056118-1.164238l-0.1455307,0.6548843 l6.3305321,4.438652l0.7276497-0.0727692l-2.0374146-2.6922989l2.4740028-4.0020599 c0.2182961,0,0.3638268-0.0727615,0.4365883-0.2182922C49.5394516,76.4746628,49.3939247,75.8925476,48.9573326,75.6014862z"
      />
      <path
        fill={colors[5]}
        d="M48.5207443,75.528717c0,0,0.1455307,1.164238,0.4365883,1.2369995 C49.2483902,76.8384857,49.7577477,76.2563705,48.5207443,75.528717z"
      />
      <path
        fill={colors[6]}
        d="M47.6475677,76.2563705c0,0,0.1455307,0.2910538,0.5093536,0.5821152 s0.7276497,0.2182999,0.7276497,0.2182999s-0.2182961,0.4365845-0.8731804-0.0727615 C47.4292755,76.5474243,47.6475677,76.2563705,47.6475677,76.2563705z"
      />
      <path
        fill={colors[7]}
        d="M47.6475677,76.2563705c0,0-0.2182922,0.2910538,0.3638229,0.7276459 c0.582119,0.4365845,0.8731804,0.0727615,0.8731804,0.0727615l-2.4740067,4.0020599c0,0-0.5821152,0.5821228-1.3097649,0 c-0.7276459-0.5821152-0.4365845-1.0914688-0.4365845-1.0914688L47.6475677,76.2563705z"
      />
      <polygon
        fill={colors[5]}
        points="48.3752136,83.7511368 46.4105644,81.0588379 44.6642151,79.9673691 41.5353279,78.7303696 41.389801,79.3852539 44.1548615,80.695015 45.8284492,81.8592606 47.7203331,83.8239059"
      />
    </g>
  )
}

export default SwordRightHip
