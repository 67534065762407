import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const RectangleShieldLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("RectangleShieldLeft") ? (
    <g id="RectangleShieldLeft">
      <path
        id="S_x24_rectangleShieldLeft"
        d="M68.6999969,108.5999985c-2.0999985,0-4.3000031-0.1999969-6.7999992-0.5999985h-0.5999985 c-1-0.1999969-2-0.4000015-3-0.5999985h-0.2000008l-5.5-6.9000015V58.7999992l7.2000008-5.7000008v-1.2999992h0.2999992 L61.6999969,52c3.0999985,0.7000008,5.1999969,1,7.0999985,1s4-0.2999992,7.0999985-1l0.3000031-0.0999985l8.5999985,6.9000015 v41.5999985l-5.0999985,6.5l0.0999985,0.3000031l-0.1999969,0.0999985L79,107.5c-3,0.6999969-5.3000031,1-7.5999985,1.1999969 h-0.1999969l-0.0999985-0.0999985v0.0999985C70.0999985,108.5,69.4000015,108.5999985,68.6999969,108.5999985z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(RectangleShieldLeft, {
  character: "ManHalfling"
})
