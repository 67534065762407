import React from "react"

const DaggerRight = ({
  colors = [
    "#6E5E26",
    "#9AB8DB",
    "#6B92C0",
    "#57779C",
    "#E2E4E5",
    "#A0A2A5",
    "#EED793"
  ]
}) => {
  return (
    <g id="DaggerRight">
      <polygon
        fill={colors[0]}
        points="23.5,73.8 22.3,75.7 25.4,77.1 26.3,80.5 28,84.6 27.8,86.1 29,87 30.2,86.6 30.6,85.1 29.7,84.2 28.7,79.7 27.4,76.5 29,73.5 27,72.7"
      />
      <polygon
        fill={colors[1]}
        points="30.1,86.5 29.7,86.1 29.1,86.3 29,86.9"
      />
      <polygon fill={colors[2]} points="28.9,86.9 29,86.3 28.4,85.8 27.9,86" />
      <polygon
        fill={colors[1]}
        points="30.2,86.5 29.8,86.1 30,85.3 30.5,85.1"
      />
      <polygon
        fill={colors[3]}
        points="29.6,84.3 29.4,84.8 28.6,85.1 28.1,84.8"
      />
      <polygon
        fill={colors[2]}
        points="30.4,85.1 29.9,85.2 29.6,84.8 29.7,84.3"
      />
      <polygon
        fill={colors[3]}
        points="28.4,85.7 28.5,85.2 28.1,84.8 27.9,85.9"
      />
      <polygon
        fill={colors[2]}
        points="29.9,85.3 29.7,86 29.1,86.2 28.5,85.7 28.7,85.2 29.5,84.9"
      />
      <path
        fill={colors[4]}
        d="M23.3,75.3l2.2-0.9l2.4-0.5c0,0-1.4-2.5-2.3-7.8c-1.4-5.3-5.6-9-5.6-9l0,0L23.3,75.3z"
      />
      <path
        fill={colors[5]}
        d="M25.6,74.3L20,57c0,0-0.8,6.3,1.1,11.4c1.6,3.5,2.3,6.9,2.3,6.9L25.6,74.3z"
      />
      <path
        fill={colors[6]}
        d="M27.2,82.4l0.8,2c0.2,0.1,0.5,0.1,0.7,0.1l0.2-0.1c0.2-0.1,0.4-0.2,0.5-0.4l-0.7-3.5 C28.4,81.2,27.9,81.9,27.2,82.4z"
      />
      <path
        fill={colors[6]}
        d="M28.2,78.9l-1-2.3l-0.7,1l-1-0.4l0.8,3l0.7,1.6C27.7,81.2,28.1,79.9,28.2,78.9z"
      />
    </g>
  )
}

export default DaggerRight
