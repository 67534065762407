import React from "react"

const LuteRight = ({
  colors = ["#94712B", "#D9A53F", "#695020", "#B78C34", "#8F6D2B"]
}) => {
  return (
    <g id="LuteRight">
      <path
        fill={colors[0]}
        d="M22.7000008,71H23.5l0.1000004,0.4000015l0.5,0.0999985L24.5,70.8000031v-0.5999985l-0.7000008,0.0999985 l-0.2000008,0.3000031l-1,0.0999985C22.6000004,70.8000031,22.5,71,22.7000008,71z"
      />
      <path
        fill={colors[0]}
        d="M22.5,70.0999985l0.8999996,0.0999985L23.5,70.3999939l0.3999996-0.1999969l0.3999996-0.4000015 l0.1000004-0.5l-0.5,0.1999969L23.5,69.7999954L22.5,70.0999985C22.5,70,22.3999996,70.0999985,22.5,70.0999985z"
      />
      <path
        fill={colors[0]}
        d="M22.5,69.4000015h0.7999992l0.1000004,0.1999969l0.5-0.0999985l0.3999996-0.4000015l0.1000004-0.5 l-0.5,0.1999969L23.5,69.0999985l-1,0.0999985C22.3999996,69.1999969,22.2999992,69.4000015,22.5,69.4000015z"
      />
      <path
        fill={colors[0]}
        d="M22.8999996,72.1999969l0.8999996,0.0999985l0.1000004,0.1999969h0.5l0.3999996-0.5999985l0.1000004-0.5 l-0.5,0.1999969L24,71.9000015L23,72C22.7999992,72,22.7000008,72.1999969,22.8999996,72.1999969z"
      />
      <path
        fill={colors[1]}
        d="M30.3999996,85.5999985c5.3000011-0.1999969,15.8000011,14.4000015,16.6999989,17.5 c0.9000015,3.1999969,1.2000008,6.8000031-3.2000008,9.0999985s-10.3999977-2-12.1000004-4.5 C30,105.1999969,26.7999992,86.1999969,30.3999996,85.5999985z"
      />
      <path
        fill={colors[2]}
        d="M20.6000004,70.9000015l0.1000004-1l8.6000004,17c0,0-3.1000004,28,14.6000004,25.4000015 c0,0-11.2999992,6.5999985-16.2000008-8.6999969C26.5,96.4000092,28.1000004,87.8000031,28.1000004,87.8000031"
      />
      <polygon
        fill={colors[3]}
        points="32.2999992,86.0999985 22.7999992,68.9000015 20.3999996,69.5 29.2999992,86.9000015"
      />
      <ellipse
        transform="rotate(-31.851 36.49 95.379)"
        fill={colors[0]}
        cx="36.49"
        cy="95.379"
        rx="1.8"
        ry="2.6"
      />
      <line x1="43.7" y1="105" x2="22.5" y2="69.1" />
      <line x1="43.1" y1="105.2" x2="21.9" y2="69.1" />
      <line x1="42.6" y1="105.3" x2="21.3" y2="69.3" />
      <line x1="42.1" y1="105.6" x2="20.9" y2="69.4" />
      <g>
        <polygon
          fill={colors[4]}
          points="45.5999985,104.9000015 40.2000008,107.5999985 39.7999992,106.4000015 45,103.8000031"
        />
        <polygon
          fill={colors[0]}
          points="20.7000008,70.0999985 20.6000004,70.9000015 21.2999992,74.3000031 22.1000004,74.0999985"
        />
        <polygon
          fill={colors[2]}
          points="20.8999996,73.8000031 21.2999992,74.3000031 20.7000008,69.8000031 20.3999996,69.5"
        />
      </g>
      <g fill={colors[0]}>
        <path d="M21,73.8000031l-0.7999992,0.3000031v0.1999969L19.8000011,74.5l-0.5-0.3000031l-0.2000008-0.4000015 h0.7000008h0.2999992l1-0.0999985C21,73.5999985,21.2000008,73.8000031,21,73.8000031z" />
        <path d="M20.7000008,71.5999985l-0.7999992,0.3000031v0.4000015L19.5000019,72.5L19,72l-0.2000008-0.5999985 H19.5l0.2999992,0.1999969H20.7000008C20.7999992,71.4000015,20.8999996,71.5,20.7000008,71.5999985z" />
        <path d="M20.6000004,70.6999969L19.7999992,71v0.3000031l-0.3999996-0.0999985l-0.5-0.3000031L18.6999989,70.5 h0.6000004l0.5,0.1999969l1-0.0999985C20.7000008,70.5,20.7999992,70.6999969,20.6000004,70.6999969z" />
        <path d="M20.5,70l-0.7999992,0.1999969v0.1999969l-0.5,0.0999985l-0.5-0.3000031l-0.2000008-0.5h0.6000004 l0.5,0.1999969l1-0.0999985C20.5,69.8000031,20.7000008,69.9000015,20.5,70z" />
        <path d="M20.7999992,72.8000031L20,73.0999985v0.3000031L19.5,73.5L19.1000004,73l-0.2000008-0.4000015H19.5 l0.5,0.1999969l1-0.0999985C20.7999992,72.5999985,21,72.8000031,20.7999992,72.8000031z" />
      </g>
    </g>
  )
}

export default LuteRight
