import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const DaggerLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("DaggerLeft") ? (
    <g id="DaggerLeft">
      <path
        id="S_x24_daggerLeft_1_"
        d="M59.2399216,94.2587204l-3.4159431-3.6339874l1.0901909-7.4133301l1.8896751-5.5236511 c0,0,5.3056221-13.0823593,6.0324287-13.7364769l0,0l4.4334641,0.4360771l3.9973755,2.180397l-3.0525513,14.8266602 l-1.4535904,4.6514969l-0.2180481,0.7268066l-1.2355499,2.9798584l-1.3809128,3.1979141l-0.8721542,0.726799l-4.8695374,0.9448395 L59.2399216,94.2587204z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(DaggerLeft, {
  character: "ManGnome"
})
