import React from "react"
import { withSkinColor } from "../../builderConnector"

const RightTheme = ({ skinColor, color = [] }) => {
  return (
    <g id="RightTheme">
      <path
        fill={skinColor}
        d="M69.8404236,79.5141296c-1.6701736,0.0878983-2.4613037,0.7032318-2.4613037,0.7032318 l-0.2637177,1.0548401l5.4500275,1.933876l0.3516159-0.7911301c0,0-0.8790359-0.9669418-1.406456-1.8459778 C71.3347931,79.9536438,70.5436554,79.6899338,69.8404236,79.5141296z"
      />
      <path
        opacity="0.19"
        fill="#21376C"
        d="M69.8404236,79.5141296 c-1.6701736,0.0878983-2.4613037,0.7032318-2.4613037,0.7032318l-0.2637177,1.0548401l5.4500275,1.933876l0.3516159-0.7911301 c0,0-0.8790359-0.9669418-1.406456-1.8459778C71.3347931,79.9536438,70.5436554,79.6899338,69.8404236,79.5141296z"
      />
    </g>
  )
}

export default withSkinColor(RightTheme, { character: "ManHalfling" })
