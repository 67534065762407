import React from "react"

const CutlassL = ({ colors = ["#DDC300", "#FFFFFF", "#7C7A79"] }) => {
  return (
    <g id="CutlassL">
      <g fill="#21376C">
        <path
          opacity="0.2"
          d="M68.2587433,80.435379l-0.241394,1.4215393c0,0-2.5480423,6.5712662-2.6553268,6.5980911 c-0.1072845,0.0268173,0.2950363,0.3486786,0.2950363,0.3486786s0.858284,1.0460358,0.9655762,1.099678 c0.1072845,0.0536423,4.3182602-9.2802353,4.479187-9.253418c0.1609268,0.026825-2.4139328-1.099678-2.4139328-1.099678 L68.2587433,80.435379z"
        />
        <path
          opacity="0.2"
          d="M68.5882034,80.8913422l-0.241394,1.4215393c0,0-2.5480423,6.5712662-2.6553268,6.5980911 c-0.1072845,0.0268173,0.2950363,0.3486786,0.2950363,0.3486786s0.8582916,1.0460358,0.9655762,1.099678 c0.1072845,0.0536499,4.3182602-9.2802353,4.479187-9.2534103c0.1609344,0.0268173-2.4139328-1.0996857-2.4139328-1.0996857 L68.5882034,80.8913422z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M71.6454773,79.814827c-0.9448242-1.4263306-2.3114624-2.1846924-3.0524292-1.6938477 s-0.5756836,2.0449829,0.3692017,3.4713745c0.0882568,0.1332397-0.5351944,3.2369232-1.2667618,5.0790253 c-0.5715942,1.4391479-1.1410141,2.2198639-1.4721909,2.4970245l-0.79422-0.5390549l-0.2548065-0.2145767 c-0.0267944,0.1340942-0.4559631,1.1801453,0.0402298,1.7970428c0.4962006,0.6168976,1.5994339,0.0223846,1.5994339,0.0223846 c0.8074341,0.8126221,2.1463623,1.7905884,3.5637207,1.2516479c1.6522827-0.6282959,2.7625732-1.0192261,3.8890991-4.0232544 C75.39328,84.4586258,72.5903625,81.2412186,71.6454773,79.814827z"
      />
      <g fill="#21376C">
        <path
          opacity="0.42"
          d="M68.0978088,90.5940018 c-0.0697021-0.0690308,3.2627563-2.4981689,3.0625-5.4521484c0.0337524-1.3699341-0.4473877-3.7006836-0.4473877-3.7006836 s-0.6065063-0.9297485-0.2615356-0.0804443c1.9110489,7.5636597-3.1090088,8.3549194-3.1783447,8.328064 c2.0317383-3.7415771,2.8945312-7.6993408,2.0719604-8.4487915c-1.4658813-2.0050049-0.8431396-2.9202271-0.223877-3.071167 c-1.9032593,0.178833,0.0227051,3.3528442,0.0227051,3.3528442s-0.241394,4.0634155-2.776001,7.885498 c1.6897583,2.0518188,2.6151123,2.1323242,4.0634766,1.8909302 C69.021637,91.4262161,68.582428,91.0742264,68.0978088,90.5940018z"
        />
        <path
          opacity="0.42"
          d="M69.1211243,78.1688309 c0.1051636-0.0098877,0.2150879-0.0160522,0.3446045-0.0065918 C69.3611755,78.136116,69.2411804,78.139595,69.1211243,78.1688309z"
        />
      </g>
      <g>
        <path
          opacity="0.66"
          fill="#FFF"
          d="M72.5292053,87.8075638 c-0.1981812-0.1146851,0.0071411-0.8665161-0.1726074-0.9849854c0.4892578,0.3225708,0.9672241,0.661377,1.4451904,1.0003052 c1.2174072-3.2531128-1.3631592-6.2875366-2.3138428-7.3302002c-0.1330566,0.2229004-0.3233032,0.4092407-0.5735474,0.4442749 c-0.1550903,0.0216675-0.2727051-0.024231-0.3771973-0.0916138c0.6473389,1.7716675,0.9547729,3.2280884,1.0141602,4.4004517 c0.2940063,0.6713257,0.3673706,1.3708496,0.0266724,2.2238159c-0.0365601,0.0916138-0.0951538,0.2189941-0.1628418,0.3596191 c0.5598755,0.4414673,1.1132812,0.8875122,1.6603394,1.3406372c0.1757812-0.2439575,0.3237305-0.4879761,0.451416-0.7314453 C73.1977234,88.2230301,72.8702087,88.0050735,72.5292053,87.8075638z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M71.1863098,88.27491 c-0.4081421,0.7578125-1.0372925,1.7421875-1.6551514,2.1994019c0.5217285,0.2217407,1.3824463,0.4206543,2.3887329-0.0211182 c0.369751-0.3283691,0.6677856-0.6595459,0.9349976-0.991333C72.3094177,89.0452347,71.7536926,88.650032,71.1863098,88.27491z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M65.2010956,88.562294 c0,0-0.4928513,1.2572556,0.2715683,1.7400436c0.6738892,0.2212753,1.2974854-0.1508713,1.2974854-0.1508713 L65.2010956,88.562294z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M69.063385,78.8663254 c0,0-0.3218536,2.0518417,0.7644119,2.2932358c0.4023285,0.7241821,0.7644196,6.5176239-2.2127686,8.2073746 c1.126503-0.4023209,4.0232239-2.615097,2.615097-8.0866776C69.6668701,80.3549118,69.5864105,81.2400208,69.063385,78.8663254z"
        />
        <path
          fill={colors[0]}
          d="M73.9427567,84.5074158c-0.0269775-0.199707,0.1624451-0.6343155,0.0826111-0.8266373 c0.0800171-0.2988052-0.1259003-0.3828278-0.1322021-0.6934891c-0.010498-0.5177765-0.1708603-1.0010757-0.5810242-1.2800446 c-0.3862305-1.2601318-2.7521515-0.8483429-3.202713,0.3642426c-0.2399902,0.6456909-0.2752533,2.3481598,0.6364288,2.4530182 c0.3248901,0.1967163,0.4924316,0.1151505,0.5571289,0.3498306c0.0655518,0.2377319,0.1091919,0.6764755,0.1987915,0.9322128 c0.1429443,0.407959,1.3535004-0.4628372,1.675354-0.5432968c0.3218613-0.0804672,0.7901611,0.4033203,0.9689331,0 c0.1173706-0.2648926-0.1692505-0.4448013-0.1955566-0.7005386c0.0057373-0.0281982,0.0115356-0.0564575,0.0172729-0.0846558 C73.9594193,84.4893494,73.9511185,84.4964905,73.9427567,84.5074158z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M71.5175552,82.7487335 c0,0-0.3017426,0.3419724-0.3017426,0.4425583c0,0.1005783,0.2615128,0.4224319,0.2615128,0.4224319l0.5632477-0.1408081 l0.241394-0.4626694l-0.5230179-0.3822098L71.5175552,82.7487335z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M72.826088,83.1826172 c-0.0942764-0.023613-0.2742538,0.4721375-0.1657104,0.7114944c0.1085434,0.2393494,0.4032745-0.0570908,0.4032745-0.0570908 l0.1159668,0.043602C73.1796188,83.8806229,73.1099014,83.2536926,72.826088,83.1826172z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M72.4831314,82.6079178 c-0.0234375-0.2655029-0.2564163-1.3429565-1.5556717,0.2279663c0.0237427,0.0253296,0.8046646-0.8448563,1.0594711-0.3084259 c0.1600342,0.1294556,0.2406387-0.1918564,0.3084488-0.1743393l0.3218536,0.737587 c-0.0268555,0.0402832-0.3662186,0.3324509-0.4023209,0.388916c-0.0177765,0.0278015,0.268219,0.0804596,0.3084488,0.4559631 c0.020256,0.0347519,0.0268173-0.4291458,0.1609344-0.7912292c0.0184631-0.0738373,0.1072769-0.0536499,0.2011566-0.0536499 c0.0498352-0.0713272-0.2950363-0.4827881-0.2950363-0.4827881S72.5118103,82.6330872,72.4831314,82.6079178z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M72.9122696,81.9776154 c0,0-0.0522308,0.208992,0.1515808,0.2587204c0.203804,0.0497284,0.398262-0.0977936,0.6396561,0.130188 C73.5157547,81.9373856,73.2877731,81.6557541,72.9122696,81.9776154z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M73.8971863,84.5749588 c-0.0087891-0.0330811-0.0109253-0.0588989-0.008606-0.0800781c-0.0805054-0.0871582-0.1838989-0.1682739-0.3325806-0.1837769 c-0.2553711-0.0266724-0.9955444,0.2820435-1.4447021,0.0482178l0.3583984-0.1555176 c0,0-0.7288208,0.3155518-1.3962402-0.6030273c0.1091919-0.083374,0.0836182-0.3586426,0.0551758-0.3491211 c-0.0402222,0.0133667-0.2816162,0.241394-0.388916-0.2682495c0,0-0.2279663,0.3621216,0.1207275,0.5900879 c0.0866699,0.0566406,0.1491089,0.0641479,0.1936646,0.0440674c-0.0264282,0.124939-0.0750122,0.6153564,0.704834,0.8947144 l0.3067017-0.1331177c-0.0409546,0.1175537-0.0652466,0.6737671,0.0252075,0.8438721 c0.0712891-0.0404053,0.1251831-0.0637817,0.1915283-0.0991821c-0.0064087-0.0378418-0.0109863-0.0725098-0.0095825-0.0960693 c0.0001831-0.0029297,0.0012817-0.0041504,0.0015259-0.007019c-0.0011597-0.0036621-0.0023804-0.0073853-0.00354-0.0110474 c0.0015869,0.0018311,0.0031128,0.0036011,0.0046997,0.0054321c0.0172119-0.1959839,0.1257935-0.237854,0.2545166-0.0726318 c0.0084839,0.0108643,0.0128174,0.0300903,0.0205688,0.0424805c0.0516357-0.0250854,0.0908203-0.0386353,0.1390381-0.0605469 c-0.0165405-0.0551147-0.0371094-0.1107178-0.0305786-0.1630249c0.0214233-0.1707153,0.0975952-0.0994873,0.1740723,0.0056763 c0.0170288,0.0233765,0.0241089,0.0529175,0.0377197,0.0784302c0.0697632-0.0285645,0.1262817-0.0456543,0.1883545-0.0675049 c-0.0265503-0.0748901-0.0368042-0.137085-0.0095215-0.1688843c0.0864868-0.100769,0.1311035-0.0686035,0.2414551-0.0014648 c0.0266113,0.0162354,0.0358887,0.0561523,0.0544434,0.0834351c0.0751953-0.0180054,0.1229858-0.0167847,0.1838989-0.0244141 c0.0009766-0.0059814-0.0002441-0.015564,0.0012817-0.0206909c0.0011597-0.1374512,0.0443115-0.1546631,0.1294556-0.0514526 c0.0145874,0.0101318,0.0158081,0.0369263,0.0270996,0.0512085c0.1002197-0.0012207,0.1685181,0.0133667,0.2246094,0.0313721 C73.905365,84.6372147,73.9011536,84.6057816,73.8971863,84.5749588z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M70.2301254,82.0044403 c0-0.120697-0.8448792,2.1591263,1.0326233,2.6955566c-0.3754959-0.3620911-1.099678-0.5632477-1.0728607-2.3468781 C70.2971802,82.1117249,70.2301254,82.0044403,70.2301254,82.0044403z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M73.4218826,83.8283005 c0,0,0.5498352-0.4023209,0.3754959-0.6303101C73.6230392,82.9700089,74.5617905,83.694191,73.4218826,83.8283005z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M70.4983368,81.7228088 c0,0,1.5958786-1.5019989,2.641922-0.241394C72.6440582,81.3741302,71.6516647,81.0254517,70.4983368,81.7228088z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M71.537674,85.0017395 c-0.017601,0.0375519,0,0.6839447,0.1106339,0.6940079c0.1106415,0.0100555,0.241394-0.2715683,0.291687-0.291687 c0.0502853-0.0201187-0.1508713-0.8649902-0.1508713-0.8147049 C71.7891235,84.6396484,71.6885452,84.6798782,71.537674,85.0017395z"
        />
        <path
          opacity="0.42"
          fill="#21376C"
          d="M73.6699829,84.0764008 c-0.0201187,0.0603485,0.2881393,0.3905334,0.4023209,0.5230179s-0.1005859-0.8046494-0.1005859-0.8046494 L73.6699829,84.0764008z"
        />
        <polygon
          opacity="0.42"
          fill="#21376C"
          points="73.0061493,82.7688522 73.2676544,82.2860641 73.6498642,82.4067612 73.7303314,83.0504761 73.1670761,83.1108246"
        />
      </g>
      <g>
        <path
          fill={colors[2]}
          d="M82.6545181,39.3448524c0,0.3644905-4.9510345,16.9793053-13.1217575,39.3652611 c0.7441788,1.0327301,1.3364792,2.0654602,1.3364792,1.7009659 C84.1124878,46.5132179,82.6545181,39.3448524,82.6545181,39.3448524z"
        />
        <path
          opacity="0.66"
          fill="#FFF"
          d="M82.6545181,39.3448524 C77.566803,62.0649261,70.0946884,79.9402771,70.4591827,79.6972809c0.182251,0.2733688,0.4100571,1.0782928,0.4100571,0.7137985 C84.1124878,46.5132179,82.6545181,39.3448524,82.6545181,39.3448524z"
        />
        <g fill="#FFF">
          <path
            opacity="0.66"
            d="M81.8085022,46.7657547 c0.3106079-1.4916382,0.5192261-2.7181396,0.6536865-3.704895c-0.2839966,0.2363281-0.5657349,0.475769-0.8480835,0.7143555 c-0.3088989,1.2687378-0.6231079,2.5192261-0.9407959,3.7496948 C81.0514221,47.271431,81.4299622,47.0184402,81.8085022,46.7657547z"
          />
          <path
            opacity="0.66"
            d="M80.4949036,48.1974564 c-0.2463989,0.9442749-0.4934082,1.8862915-0.7433472,2.8018188c0.4094238-0.2105713,0.8184204-0.4204712,1.2259521-0.6306152 c0.2704468-1.0775757,0.4959717-2.0490723,0.6895752-2.9396362 C81.2763367,47.6851273,80.8849792,47.9403152,80.4949036,48.1974564z"
          />
          <path
            opacity="0.66"
            d="M76.6026306,64.6590042 c0.0249634-0.0174561,0.050415-0.0344238,0.0754395-0.0518188c1.3779297-4.0512085,2.4471436-7.4949341,3.270874-10.4030151 c-0.4390259,0.2600098-0.878418,0.5192871-1.3170166,0.7799072c-1.1090698,3.8417969-2.2125854,7.3670654-3.2345581,10.4827881 C75.8091736,65.1974564,76.2156067,64.9289017,76.6026306,64.6590042z"
          />
          <path
            opacity="0.66"
            d="M74.0093079,72.1323318 c0.3990479-1.0860596,0.7858276-2.1491089,1.1494751-3.1680298c-0.4155273,0.296814-0.8262329,0.6000977-1.2393188,0.9002075 c-0.3607788,1.0489502-0.7008667,2.0197144-1.0195312,2.9196777 C73.2670593,72.5623245,73.6403503,72.3510818,74.0093079,72.1323318z"
          />
          <path
            opacity="0.66"
            d="M72.276886,76.7590408 c0.5275879-1.3864136,1.0303345-2.7269287,1.5079956-4.0205078c-0.0805664,0.0494385-0.1633301,0.0952148-0.2437744,0.1448364 c-0.2927246,0.1804199-0.583374,0.366333-0.8733521,0.5546265c-0.5628052,1.5797729-1.0393677,2.8837891-1.407959,3.8938599 C71.5970764,77.1377029,71.9373474,76.9489822,72.276886,76.7590408z"
          />
        </g>
      </g>
    </g>
  )
}

export default CutlassL
