import React from "react"
import { withSkinColor } from "../../builderConnector"

const SquareJaw = ({ skinColor, color = [] }) => {
  return (
    <g id="SquareJaw">
      <path
        opacity="0.3"
        fill="#21376C"
        d="M71.9,27.6c0.1-0.1,2.1-1.6,2-3.2c0-0.8,0-1.5,0-1.5h-9.6 c0,0,0,0.7,0,1.5c0.3,1.9,1.9,3,2,3.2c0,0,1.7,1.2,3,1.2C70.5,28.8,71.9,27.6,71.9,27.6z"
      />
      <path
        fill={skinColor}
        d="M72.2,25.3c0.3-0.2,2.2-0.6,2.6-1.4s0.2-2.9,0.5-3.8c0.3,0.1,0.7,0,0.9-0.5c0.5-2.4,0.6-3.4,0.4-3.6 c-0.2-0.2-0.9-0.7-1.5,0.2c0.2-3.1-0.1-3.5-0.7-4.6c-1.1-2.1-2.6-3.3-5.2-3.3s-4.1,1.2-5.2,3.3c-0.6,1.1-0.9,1.5-0.7,4.6 c-0.5-0.9-1.2-0.4-1.5-0.2c-0.2,0.2-0.1,1.2,0.4,3.6c0.2,0.4,0.6,0.6,0.9,0.5c0.2,0.9,0.1,3,0.5,3.8c0.4,0.8,2.3,1.1,2.6,1.3 c0,0,1.6,0.6,3.2,0.7C70.9,25.9,72.2,25.3,72.2,25.3z"
      />
    </g>
  )
}

export default withSkinColor(SquareJaw, { character: "ManMixed" })
