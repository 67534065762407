import React from 'react'
import { connect } from 'react-redux'

import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import { SET_FLASH, CLEAR_FLASH, CLEAR_ALL_FLASHES } from './reducers/flashReducer'

export const clearFlash = ({ key }) => ({
  type: CLEAR_FLASH,
  payload: {
    key
  }
})

export const clearAllFlashes = () => ({ type: CLEAR_ALL_FLASHES })

export const flash = ({ key, message }) => {
  setTimeout(() => clearFlash({ key }))
  return {
    type: SET_FLASH,
    payload: {
      key,
      message
    }
  }
}

const TransitionUp = props =>
  <Slide {...props} direction="up" />

const Flash = ({ message }) =>
  <Snackbar
    open
    TransitionComponent={TransitionUp}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{message}</span>}
  />

const FlashMessages = ({ messages = {} }) => (
  <div>
    { Object.keys(messages).map(key => <Flash message={messages[key].message} />) }
  </div>
)

const mapStateToProps = state => ({
  messages: state.flashReducer
})

export default connect(mapStateToProps)(FlashMessages)