import React from "react"

const SlingShoRight = ({
  colors = ["#362812", "#454545", "#949494", "#724E1F"]
}) => {
  return (
    <g id="SlingShoRight">
      <path
        fill={colors[0]}
        d="M30.9212112,92.0618668l-0.9639168-8.7628708l-5.4329796-2.5412292 c0,0-1.9278316,1.7525711-1.8402023,4.0309143c0,0.3505173-1.8402023,29.9690094-1.8402023,29.9690094 s0.1752567,2.103096,1.0515442,2.4536057c0.7886581,0.2628937,2.8041191-0.3505096,2.8041191-0.3505096l0.4381428-2.4536057 l-0.4381428-29.9690094l-1.0515442-1.6649399l0.9639149-1.3144379l4.6443214,2.4536133l-0.8762856,2.0154572l0.0876274,7.6236954 l2.015461,2.3659744l-0.4381447-4.8195801L30.9212112,92.0618668z"
      />
      <path
        fill={colors[1]}
        d="M20.6686535,113.4432678c0.7010288-0.963913,0.8762875-1.5773163,2.1907177-1.4020538 c0.1752567,0,1.6649437,0.0876236,1.752573,0.262886c0.4381447,0.7010345,1.0515461,2.3659744,1.0515461,2.3659744 s-0.9639168,1.8402023-2.1907177,1.9278259c-0.8762875,0.0876312-2.7164898-0.7886581-2.7164898-0.7886581 S20.3181381,113.8814163,20.6686535,113.4432678z"
      />
      <path
        fill={colors[2]}
        d="M22.6841145,113.1803818c0.1752586-0.3505173,0.3505135-0.7886581,0.5257721-1.1391754 c0.5257721,0,1.2268009,0.0876236,1.4020596,0.262886c0.4381428,0.7010345,1.0515442,2.3659744,1.0515442,2.3659744 s-0.3505135,0.7010345-0.9639149,1.226799c-0.6134014-0.1752548-1.2268009-0.5257645-1.8402023-0.963913 C22.8593712,114.4071808,22.7717419,113.7937851,22.6841145,113.1803818z"
      />
      <path
        fill={colors[3]}
        d="M23.6480293,116.3350143l-1.2268028-0.0876312l-0.1752567-2.1030884l0.5257721-2.2783432 l1.1391716-28.2164459l3.3298893-1.5773163L25.4006004,81.1959l-0.8762875-0.4381409l-1.2268009,0.6133957l-0.7886581,1.8402023 l-1.752573,31.5463333c0,0,0.3505135,2.4536057,1.4896889,2.1030884c0.1752567-0.0876312,1.5773144,0,2.1030884-0.3505173 C23.8232861,116.1597595,23.6480293,116.3350143,23.6480293,116.3350143z"
      />
    </g>
  )
}

export default SlingShoRight
