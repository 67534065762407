import React from "react"

const Cape = ({ colors = ["#7B0A00"] }) => {
  return (
    <g id="Cape">
      <path
        opacity="0.2"
        fill="#21376C"
        d="M80.1650772,78.2369385 c-0.0784836-0.714241-3.7671356-9.4635849-3.060791-10.0885391c0.6278534-0.6249542,3.1392746-1.7855835,5.8861389,5.8031387 c-0.627861-3.2140503-1.412674-6.6066589-1.412674-6.6066589s-3.1392822-4.7317886-7.1418533-2.3212509 c4.8658752-5.8924217-0.627861-14.9988937-6.0430984-16.1595192c-5.3367729,0.9820671-10.202652,13.6597023-5.6507034,16.1595192 c-4.0025749-2.4105377-7.1418533,2.3212509-7.1418533,2.3212509s-0.7848167,3.3926086-1.4126701,6.6066589 c2.7468643-7.5887299,5.2582855-6.4281006,5.8861389-5.8031464c0.706337,0.6249542-2.9823112,9.3743057-3.0607948,10.0885468 c-0.3139267,3.4818802-2.8253441,5.3567429-3.2177544,8.0351181c1.0202637,11.4277267-0.1569633,19.6414032-0.7063408,31.5155258 c0.9417839,0,3.0440063-0.098465,3.7503395-0.098465c5.6506958,0,6.1383781-50.2549515,11.7890739-50.4335098 c5.6506958,0.1785583,5.8861465,50.3534164,11.5368347,50.3534164c1.0202713,0,2.5114212,0,3.7671356,0 c-0.3924103-9.1957474-1.4911575-20.5341949-0.4708939-31.1584091 C82.8334656,84.6650314,80.6359711,81.5402603,80.1650772,78.2369385z M74.0434875,60.381115 c-0.3139267,3.4818878-2.9038239,5.3567467-5.4152527,5.535305c-2.4329376-0.3571167-5.1798019-1.9641418-5.1798019-5.535305 s2.4329376-9.017189,5.0228424-9.017189C70.9826965,51.2746429,74.4358978,56.0064354,74.0434875,60.381115z"
      />
      <path
        fill={colors[0]}
        d="M48.22295,117.8680496h8.3479958l-2.8542633-31.604805c0,0-0.4204102-6.4127731-1.0482635-4.9843063 c0.1569633,0.1785583-0.0422173,0.7291565-0.120697,1.0862732C52.4692421,82.633049,50.1772194,114.488884,48.22295,117.8680496z"
      />
      <path
        fill={colors[0]}
        d="M85.3235855,81.8690109c0.1569672,0.2678375,1.0415649,32.4635086,3.0820923,35.9453964 l-8.2586365,0.0088196l3.5672607-35.0556946C83.7143021,82.7675323,85.166626,81.4226151,85.3235855,81.8690109z"
      />
      <path
        fill={colors[0]}
        d="M76.7118759,61.2739067c0.4708862-1.8748627,1.726593-6.3388176,0.9417801-7.4994469 c-0.627861-0.7142334-1.2557144-0.4463959-1.8835678-1.3391876c-1.2557068-4.1068382-3.8456116-6.0709801-7.8481903-5.8924179 c-3.5316849-0.0892792-5.2582893,2.3212547-6.5924797,5.8924179c-0.5493736,0.8035126-1.5696373,0.6249542-2.0405273,1.0713501 c-0.9417839,2.3212547,0.706337,6.6959343,0.7848167,7.7672844c-0.7848167,0.1785583-2.7179413,2.2373009-3.5027618,3.308651 c-0.7848167,1.0713501-3.6367607,11.0155029-4.2646179,14.7652359c0.2354469-0.3571167,1.3142548-2.0518494,1.7165756-2.5346375 c0.4023247-0.4827805,0.8757477-0.5895081,1.5824699-2.0116119c0.2325516-0.4679489,10.4329567-5.4925156,12.0810814-8.6172867 c-3.5316925-1.0713501-6.4355202-1.517746-5.7291832-6.4280968c1.0202637-7.588726,6.278553-10.0885391,6.278553-10.0885391 s6.9848938,3.7497215,6.6709671,9.9099808c-0.2354507,4.9103508-2.1190109,5.535305-5.6506958,6.6959343 c1.6481171,3.1247635,11.5445709,7.9662704,11.9369812,9.0376205c0.0550308,0.125206,0.9387512,1.1533203,1.6264801,1.7575226 c0.6667557,1.1123734,0.9511719,1.4676971,1.4311676,2.1986465c-0.4708862-4.0175629-3.2672043-13.3455811-4.1305084-14.6847687 S77.5751724,61.4524651,76.7118759,61.2739067z"
      />
      <path
        opacity="0.2"
        fill="#21376C"
        d="M83.2851562,77.6580353 c-0.0784836-0.7142334-4.9251633-15.0449448-8.927742-12.634407c4.8658752-5.8924217-0.6278534-14.9988937-6.0430984-16.1595192 c-5.3367691,0.9820671-10.2026482,13.6597023-5.6506996,16.1595192 c-4.0006218-2.3201256-10.1983376,13.3283005-10.2768211,14.0425339c3.3984604-2.3546448,3.5976143-1.5516739,2.9168358,1.126503 c-0.0489922,0.1927338-2.0378876,1.8553238-2.0920753,2.0518494c-0.4408302,1.5986938,0.6437302,3.170784,0.505127,4.1168289 c1.0202637,11.4277267-0.1569672,19.552124-0.7848206,31.5155258c0.706337,0,30.8433838,0,30.8433838,0 s-1.4126816-20.5341949-0.3924103-31.1584091c-0.2343826-0.6475143,0.7696304-2.1164246,0.0230179-3.8704605 c-0.5363083-1.2599716-1.8506851-1.8909225-2.8564911-3.7013702 C79.463089,75.3647995,83.0337753,77.5150604,83.2851562,77.6580353z M73.9650116,60.381115 c-0.3139343,3.4818878-2.9038315,5.3567467-5.4152527,5.535305c-2.4329376-0.3571167-5.1798096-1.9641418-5.1798096-5.535305 s2.4329453-9.017189,5.02285-9.017189C70.904213,51.3639259,74.3574142,56.0064354,73.9650116,60.381115z"
      />
    </g>
  )
}

export default Cape
