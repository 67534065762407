import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WarhammerR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WarhammerR") ? (
    <g id="WarhammerR">
      <path
        id="S_x24_warhammerR"
        d="M41.6863441,108.086647c2.3869247-1.3190918,3.1534615-3.330925,1.4237785-8.1239166 C40.227314,91.974411,32.138649,75.6747437,32.138649,75.6747437c3.8726501-1.9902191,3.9363289-8.2076721,3.9363289-8.2076721 c-1.9902115-3.8726463-6.0704765-5.8615685-9.9510078-3.7991066l-4.8730431,2.0403175 c-3.8726444,1.9902115-4.3553467,6.8229599-2.2928867,10.7034988c0,0,2.4844971,3.0234909,5.6429539,2.6967773 c0.5933018-0.0613708,7.0968819,16.9147568,10.5527153,24.6230011 C37.3792839,108.6957169,39.1929321,109.4645844,41.6863441,108.086647z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WarhammerR, {
  character: "ManGnome"
})
