import React from "react"

const Tusks = ({ colors = ["#B7B7B7"] }) => {
  return (
    <g id="Tusks" fill={colors[0]}>
      <path d="M72.6346359,16.2534561c0,0,0.5141296-2.6274815,0.2408218-3.3320923 c-0.6377487,1.2835989-1.305481,3.0912075-1.5074768,3.1619129 C71.7182083,16.2074375,72.1741943,16.3479328,72.6346359,16.2534561z" />
      <path d="M64.2240982,16.2534561c0,0-0.5141335-2.6274815-0.2408218-3.3320923 c0.6377487,1.2835989,1.305481,3.0912075,1.5074768,3.1619129C65.1405258,16.2074375,64.6845398,16.3479328,64.2240982,16.2534561 z" />
    </g>
  )
}

export default Tusks
