import React from "react"

const MaulR = ({ colors = ["#898888", "#FFFFFF"] }) => {
  return (
    <g id="MaulR">
      <path
        fill={colors[0]}
        d="M33.9467697,40.0736084c-0.2999992,0.4000015,4.0999985,37.9000092,4.0999985,37.9000092 c-0.0999985,0.4000015,0.7999992,1.3000031,2.0999985,0.9000015s1.0999985-1.5,1.0999985-1.5 s-4.4000015-38.1000023-4.5-38.2000046C36.5467682,38.9736061,34.3467712,39.6736069,33.9467697,40.0736084z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M35.246769,46.8736076 c-0.2999992,0.0999985-0.5,0.0999985-0.7000008,0.0999985c-0.4000015-4-0.7000008-6.7999992-0.5999985-6.9000015 c0.2999992-0.4000015,2.5999985-1.0999985,2.7999992-0.9000015c0,0,0.4000015,2.7000008,0.7999992,6.5999985 C36.5467682,46.3736076,35.5467682,46.8736076,35.246769,46.8736076z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M38.746769,55.3736076 c0,0.2000008,0,0.2999992,0.0999985,0.5c-1.2000008-0.5999985-2.2999992-1.2000008-3.4000015-1.9000015 c0-0.0999985,0-0.2000008,0-0.2999992l0,0C36.4467697,54.1736069,37.5467682,54.7736092,38.746769,55.3736076z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M39.8467712,77.5736084 c0-1.5,0-3-0.0999985-4.4000015c0.4000015,0.1999969,0.7000008,0.5,1.0999985,0.6999969 c0.2999992,2.1999969,0.4000015,3.5999985,0.4000015,3.5999985s0.0999985,0.4000015-0.2000008,0.8000031 C40.6467705,77.9736099,40.246769,77.773613,39.8467712,77.5736084z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M36.6467705,66.1736145 c-0.0999985-0.6999969-0.2000008-1.5-0.2999992-2.3000069c0.4000015,0.3000069,0.9000015,0.6000023,1.2999992,0.9000053 c0.0999985,0.6999969,0.0999985,1.4000015,0.2000008,2.1999969 C37.4467697,66.6736145,37.0467682,66.3736115,36.6467705,66.1736145z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M37.8467712,76.4736099 c-0.0999985-1.0999985-0.2999992-2.9000015-0.5999985-5c0.4000015,0.1999969,0.7000008,0.5,1.0999985,0.6999969 c0.2000008,1.5999985,0.2999992,3.1999969,0.5,4.8000031C38.5467682,76.8736115,38.246769,76.6736145,37.8467712,76.4736099z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M35.0467682,51.9736061 c0-0.0999985,0-0.2999992,0-0.4000015c0.0999985,0,0.0999985,0,0.2000008,0c1.0999985,0.5,2.2000008,1,3.2000008,1.5999985 c0,0.2000008,0.0999985,0.5,0.0999985,0.7000008C37.3467712,53.2736092,36.246769,52.6736069,35.0467682,51.9736061z"
      />
      <path
        fill={colors[0]}
        d="M42.3467712,40.2736092c0,0-10.5999966,5.2000008-10.3999996,5.5999985 c0.199995,0.3999977-6.1000004,0.0999985-6-0.2999992c0.0999985-0.5-3.2000008-10.2000008-3.2000008-10.2000008 s6.7999992-3,12.1000004-4.6000004c3.9000015-0.1000004,7-0.2999992,7.2000008-0.2000008L42.3467712,40.2736092z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M29.4467716,36.5736084l0.0999985,0.4000015 c0.2000008,0,0.5,0,0.5,0c-0.5999985-0.7999992,0,0,0.0999985,0.2000008 c0.0999985,0.0999985,0.0999985,0.2999992,0.2000008,0.4000015l0,0c-0.0999985,0-0.2000008,0.0999985-0.2999992,0.0999985 c0.0999985,0.0999985,0.2000008,0.2000008,0.2000008,0.2999992s-0.0999985,0.2000008-0.2999992,0.2999992l0.2999992,1.2999992 c0.2000008-0.2000008,0.2999992-0.2999992,0.2999992-0.4000015c0,0.0999985,0,0.2000008,0,0.2999992 s-0.0999985,0.2999992-0.2000008,0.5l0.0999985,0.2000008c0.2000008-0.2000008,0.4000015-0.4000015,0.4000015-0.4000015 c0.0999985,0.0999985,0.0999985,0.0999985,0.0999985,0.2000008c0,0.0999985-0.2000008,0.2999992-0.5,0.5l0,0 c0.0999985,0,0.0999985-0.0999985,0.2000008-0.0999985v-0.0999985l0,0 c0.2000008-0.0999985,0.2999992-0.2000008,0.2999992-0.2000008v0.0999985c0,0.0999985-0.0999985,0.0999985-0.2000008,0.2000008 c0.0999985,0.0999985,0.2999992,0.2999992,0.4000015,0.5c0,0,0,0.0999985-0.0999985,0.2000008 c0.0999985,0.0999985,0.4000015,1.5,0.0999985,0.2000008l0.2999992-5.2000008L29.4467716,36.5736084z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M30.7467709,36.5736084l1.2999973,9.1000023 c0,0,9.2000008-5.0999985,10-5.2999992s-0.0999985-9.6000004-0.0999985-9.6000004l-0.4000015,0.0999966 c0,0.2999992-0.0999985,0.5-0.4000015,0.5c-0.2000008,0-0.2999992,0-0.4000015,0l-1.2999992,0.7000008l0.0999985,0.0999985 h-0.0999985c-0.2000008,0.0999985-0.5,0.0999985-0.7000008,0.2000008l-1.7000008,0.9000015l0,0l0,0l-0.0999985,0.0999985 c0,0-0.0999985,0-0.0999985,0.0999985c-0.0999985,0.2999992-0.2000008,0.2999992-0.5,0.4000015 c-0.0999985,0-0.0999985,0-0.2000008,0c-0.2000008,0.0999985-0.4000015,0.2000008-0.5999985,0.2999992h-0.0999985l0,0 l-0.5,0.1000023c0,0.0999985,0.0999985,0.0999985,0.0999985,0.2000008c0.0999985,0.2000008,0.0999985,0.2999992,0,0.5l0,0l0,0 l-0.0999985,0.0999985c-0.2999992,0.2000008-0.5999985,0.0999985-1,0.0999985c-0.2999992,0-0.5,0-0.7999992,0 l-1.1999989,0.5999985l0,0c0.0999966,0.2000008,0.1999989,0.2999992,0.2999973,0.5l0,0l0,0l0,0h-0.0999985 C31.7467709,36.3736076,31.2467709,36.4736061,30.7467709,36.5736084z"
      />
    </g>
  )
}

export default MaulR
