import React from "react"

const Breeches = ({ colors = ["#77634F", "#FFFFFF"] }) => {
  return (
    <g id="Breeches">
      <path
        fill={colors[0]}
        d="M55.6526413,97.1246338c-0.1179314-0.9222412-1.5546761-10.8074188-1.823864-11.8282776 c-0.0027771-0.0105057-3.220253,0.3875961-6.3629036,0.3919678c-3.0544624,0.0042419-6.2673187-0.4558182-6.2700195-0.44561 c-0.2745399,1.0411758-1.6296082,10.4894409-1.7200203,11.295929c-0.6805038,3.1340332,1.73769,2.9415894,2.6185417,3.0803909 c1.5219345,0.2398453,2.8734207,0.630722,3.6106415-0.8880234c0.3554726-0.7323303,0.475956-2.9453735,0.6778679-4.6194534 c0.2005539-2.0215759,1.997509-1.5242004,1.997509,0.1684647c0.1671867,1.6784134,0.2541275,3.7442169,0.6014252,4.4597015 c0.7372169,1.5187454,2.1285706,0.7651596,3.6668129,0.8524857 C53.9029388,99.6634216,55.8195419,98.4298172,55.6526413,97.1246338z"
      />
      <g fill="#21376C">
        <path
          opacity="0.42"
          d="M45.3374977,97.7457123 c0,0-0.0722008,0.9236603-0.2647324,1.0484848c0.3369293-0.3245316,0.4251747-0.3703003,0.4251747-0.3703003 s-0.2807732,0.9860764-1.4500008,1.2107544C44.649601,98.9606247,45.3374977,97.7457123,45.3374977,97.7457123z"
        />
        <path
          opacity="0.42"
          d="M40.6996574,99.3239975 c-0.5214386-0.1747437-0.3718071-1.4988022-0.4271202-2.0971069c-0.0902557,0.3245239-0.0958977,1.5740891-0.0958977,1.5740891 c-0.1074524-0.2410583-0.2410316-1.0332031-0.4977417-2.1316147 C39.5713387,96.6646271,38.8623848,98.4657135,40.6996574,99.3239975z"
        />
        <path
          opacity="0.42"
          d="M39.9620667,95.9176712 c0,0-0.1972694,1.1259613,0.120697,1.9177322C40.1603203,97.0797882,39.9620667,95.9176712,39.9620667,95.9176712z"
        />
        <g opacity="0.42">
          <path d="M41.6117897,99.2668991l-0.26474-1.0235291l0.0882111,0.7227859l-0.389019-0.9474411 l-0.072216,0.4868088c0,0.0998306,0.168457,0.7863312,0.168457,0.7863312l0.431881,0.0707474l0.0735321,0.0915375 L41.6117897,99.2668991z" />
          <path d="M44.04673,99.5051041l-0.083828-0.9888382l-0.4331627,1.1483154l0.4804039-0.011322 c-0.0057678,0.052681,0.002739,0.0823746,0.0490341,0.0487823c0.2406769-0.1747437,0.3610153-1.0734482,0.3610153-1.0734482 S44.1512947,99.1888351,44.04673,99.5051041z" />
        </g>
        <g>
          <path
            opacity="0.42"
            d="M49.1752243,97.6162491 c0,0,0.0722008,0.9236603,0.2647285,1.0484848c-0.3369293-0.3245316-0.4251709-0.3703003-0.4251709-0.3703003 s0.2807732,0.9860764,1.4500008,1.2107544C49.863121,98.8311615,49.1752243,97.6162491,49.1752243,97.6162491z"
          />
          <path
            opacity="0.42"
            d="M53.5933533,99.1957321 c0.5214348-0.1747437,1.1279488-1.6609344,1.1832619-2.2592316c0.0902557,0.3245239-0.0802193,1.1316986-0.0802193,1.1316986 c0.1074486-0.2410583,0.4171486-0.5908203,0.6738586-1.6892319 C55.4778137,96.3742371,56.0240555,98.2096558,53.5933533,99.1957321z"
          />
          <path
            opacity="0.42"
            d="M54.5201912,96.4260025 c0,0,0.3008308,0.6615448,0.1684647,1.5352859C54.6284904,97.2622986,54.640522,97.0750656,54.5201912,96.4260025z"
          />
          <g opacity="0.42">
            <path d="M53.2496109,99.0972061l0.26474-1.0235291l-0.0882111,0.7227859l0.389019-0.9474487l0.072216,0.4868088 c0,0.0998306-0.168457,0.7863388-0.168457,0.7863388l-0.431881,0.0707474l-0.0735321,0.0915375L53.2496109,99.0972061z" />
            <path d="M50.6537437,99.3488159l0.083828-0.9888382l0.4331589,1.1483231l-0.4804039-0.0113297 c0.0057678,0.052681-0.002739,0.0823746-0.0490341,0.0487823c-0.2406769-0.1747437-0.3610153-1.0734482-0.3610153-1.0734482 S50.5491791,99.032547,50.6537437,99.3488159z" />
          </g>
        </g>
      </g>
      <g opacity="0.66" fill="#FFF">
        <path d="M42.8479462,86.3365555c-0.0035248-0.0244904,0.0033493-0.0499344,0.0068741-0.0750961 c-0.4738083-0.0884552-1.3221283-0.3825378-1.5094604-0.6093521c-0.0325775-0.0394592,0.0955391,0.2307968-0.0464821,0.254158 c-0.0262566-0.0262909,0.2324142,0.7412949,1.2442894,0.5093307c0.0978088,0.0237732,0.2089462,0.0362091,0.3191643,0.0492249 C42.8576431,86.42202,42.8540802,86.3793182,42.8479462,86.3365555z" />
        <path d="M45.1685905,86.3881073c-0.4926033-0.0488129-0.9418716-0.0876617-1.4305954-0.177536 c-0.0429115-0.0079041-0.1947708,0.0156174-0.3303757,0.0365982c0.0075455,0.0130768,0.0109634,0.0270844,0.0188255,0.0403595 c0.0312424,0.052887-0.0096588,0.1797409-0.0223541,0.2460556c0.0667801,0.007782,0.1381454,0.0140457,0.2005882,0.0236816 c0.0526085,0.0081329,0.1016884,0.0068436,0.1534119,0.0127258c0.4373894,0.0971985,0.8825417,0.0153427,1.3465843,0.0405045 C45.1287575,86.6118088,45.1723976,86.3963623,45.1685905,86.3881073z" />
        <path d="M49.0856361,86.3772583c0.0000725-0.0005188,0.0003204-0.00103,0.0003891-0.0015411 c-0.0000343-0.0003891-0.0001411-0.0008392-0.0001755-0.0012207c-0.0029984-0.0078735-0.0056419-0.0514755-0.0068054-0.0648117 c-0.0538406,0.0003891-0.1074333,0.0029602-0.1617699-0.0005493c-0.016922,0.0036697-0.0340958,0.0063629-0.0509834,0.0100937 c-0.0020103,0.0085144-0.0051117,0.0147476-0.0076141,0.0222321c-0.3012886,0.0215302-0.6570892,0.0577087-0.7661095,0.0522766 c-0.379528,0.0665817-0.7670975,0.0948868-1.1279716-0.0222626c-0.2325706,0.0359879-0.464222,0.0776596-0.6977425,0.1338272 c-0.0157242,0.0037613-0.0182991,0.0075836-0.0227776,0.0113068c-0.1754112-0.0402298-0.3721924-0.0348892-0.5748634-0.0144577 c0.0053253,0.0697556,0.0144234,0.1393509,0.0192871,0.2091446c0.4071007,0.0069733,1.2402039-0.0923462,1.3119888-0.0914764 c0.0828247,0.0191498,0.1657181,0.0383301,0.2485428,0.0574799c0.1076813,0.0249023,0.2473412-0.003212,0.3553734-0.0170593 c0.0301819-0.003891,0.1811981-0.0354462,0.1910362-0.0477829c0.1907158-0.0056534,0.3835831-0.0159378,0.5741196-0.0273743 c-0.0379715-0.0161591,0.2475204-0.0321655,0.1699486-0.0127258c0.0707664-0.0048828,0.1418495-0.0102768,0.2127914-0.0159378 c-0.0810623,0.084671,0.1587334,0.0717163,0.3353462,0.0140457 C49.0843697,86.5058823,49.0856018,86.3775177,49.0856361,86.3772583z" />
        <path d="M46.6196022,88.5605469c0.0833893-0.0187912,0.1597252-0.0800705,0.2224159-0.1557693 c0.0025368-0.1636429,0.003315-0.2867126,0.0068398-0.3110352c0.0306396-0.0727081,0.0613136-0.1454544,0.0919189-0.2181702 c0.0398445-0.0944977,0.0256348-0.2241821,0.0232735-0.3235931c-0.0006714-0.0277634-0.0172768-0.1681442-0.0295486-0.1784897 c0.0163231-0.1732559,0.0278206-0.3490143,0.037796-0.5227737c-0.0220718,0.0324478-0.0058861-0.2277832,0.0061722-0.1552963 c0.0029984-0.0646133,0.0055008-0.1295853,0.0076866-0.194458c0.0827179,0.0833511,0.0968552-0.1351776,0.0548286-0.3018112 c-0.0707664-0.0046539-0.2104988-0.0208817-0.2107811-0.0209503c-0.0005646-0.0001221-0.001091-0.000412-0.0016212-0.0005112 c-0.0004234-0.0000305-0.0009499,0-0.0013733,0c-0.0089226,0.0018005-0.0567322-0.0009613-0.0713997-0.0014801 c-0.0059242,0.0487747-0.009449,0.0975876-0.0196762,0.1463318c0.0020103,0.0157394,0.0029259,0.0316162,0.0050087,0.0473251 c0.0090256,0.0028305,0.0154419,0.0063629,0.023304,0.0095139c-0.0120239,0.2751694-0.0145264,0.6014328-0.0332832,0.6994324 c0.0278549,0.351326,0.0130463,0.7053833-0.1571503,1.0181427 C46.5824738,88.2507401,46.6012688,88.4056091,46.6196022,88.5605469z" />
        <path d="M47.538311,88.1390839c0.0296555,0.0808411,0.0679092,0.1605606,0.1000671,0.240921 c0.0640297-0.0551682,0.1351128-0.101181,0.210743-0.1414108c-0.0260582-0.0894852-0.0443916-0.1531677-0.0470352-0.1700363 c0.0080032-0.0775375,0.0160789-0.155098,0.0240822-0.2326355c0.0104713-0.1007919-0.0415001-0.2222214-0.0731277-0.3173904 c-0.0088501-0.0265732-0.066288-0.1576996-0.0812035-0.1646423c-0.0354691-0.1708755-0.0762634-0.3429718-0.1179733-0.5127869 c-0.011673,0.0366974-0.0729523-0.2179108-0.0399132-0.1510468c-0.0161858-0.0629807-0.0329704-0.1261444-0.0500336-0.1891556 c0.1042938,0.0599518,0.0533447-0.1539764-0.0363159-0.3041229c-0.0695343,0.012886-0.2089119,0.0314865-0.2091942,0.031517 c-0.0005646,0-0.0011635-0.0001297-0.0017281-0.0001297c-0.0004234,0.0000992-0.0008812,0.0002594-0.0013046,0.0003586 c-0.0080719,0.0039139-0.0549316,0.0129776-0.0692139,0.016098c0.0086746,0.0484161,0.0197105,0.0962601,0.0242615,0.1457443 c0.0065918,0.0146866,0.0121613,0.0297241,0.0187912,0.044342c0.0095558,0.0005112,0.0167847,0.0023422,0.02528,0.0034409 c0.0696716,0.2680054,0.1636391,0.5827942,0.1744995,0.6818237 C47.5195541,87.4514694,47.6098518,87.7961121,47.538311,88.1390839z" />
        <path d="M51.402359,86.3719635c-0.2465286-0.1377411-0.3721085-0.0768509-0.5744591-0.084465 c-0.3757553,0.075386-0.5561523,0.0308228-0.9354706,0.0476532c0.001976,0.0714645-0.025238,0.283989,0.023243,0.27005 c0.0000343,0,0.1103592-0.0052948,0.1103973-0.0052948c0.5234909,0.0251541,1.4166641,0.0447464,1.423542,0.0052948 c0.001091-0.0167084-0.0461922-0.1230927-0.0409737-0.141243c0.005146-0.0243912,0.0147018-0.0455627,0.0213661-0.0694656 C51.2298393,86.4119339,51.3546906,86.3453293,51.402359,86.3719635z" />
        <path d="M53.5704002,85.8850861c-0.7060623,0.1877441-1.070076,0.3509369-1.806057,0.4638977 c0.0038414,0.0702744-0.0154076,0.3140182,0.0069809,0.3010406c0.0665359-0.0384979,0.1330338-0.0770187,0.1995659-0.1155472 c0.1249962-0.0012207,0.3003387-0.0102463,0.3666954-0.0310364c0.2952232-0.0685043,0.5904465-0.1370392,0.8856697-0.2055435 c0.07267-0.0106049,0.4006157-0.0520706,0.4168701-0.0103989C53.6466827,86.3198853,53.6169777,85.8727112,53.5704002,85.8850861 z" />
      </g>
    </g>
  )
}

export default Breeches
