import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SlingShotLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SlingShotLeft") ? (
    <g id="SlingShotLeft">
      <path
        id="slingshotL_1_"
        d="M68.7623978,113.7749329c1.3809128,0.0726776,4.8695374-0.2907257,4.2154236-6.8319016 l-1.3082352-23.6935883l-0.3634033-1.6716385l-0.6541138-1.5262756l-2.5437927-2.6891479l-5.3783073-0.5087509 l-4.5061417,2.1077042c0,0,0.8721619,28.9265366,0.7994766,28.9992218l0.1453629,5.7416992 C59.2413521,113.7022552,66.8727188,113.7022552,68.7623978,113.7749329z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SlingShotLeft, {
  character: "ManGnome"
})
