import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SwordLefttHip = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SwordLefttHip") ? (
    <g id="SwordLefttHip">
      <path
        id="S_x24_swordLefttHip"
        d="M69,119.4h18.3c0,0,6.2-8.4-15.9-34.1S69,119.4,69,119.4z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SwordLefttHip, {
  character: "ManDwarf"
})
