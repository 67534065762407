import React from "react"

const BroadSwordR = ({ colors = ["#888787", "#FFFFFF"] }) => {
  return (
    <g id="BroadSwordR">
      <path
        fill={colors[0]}
        d="M44.176918,54.8116035l2.5484772-0.9419632l1.0248833-1.3574104L43.7695847-6.4858394 l-1.1359558-4.2382474l-0.5429802-0.770071c0,0-2.0054054,3.5126209-2.1437798,6.4212661 C39.8084984-2.1642475,44.2766418,54.8060646,44.176918,54.8116035z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M44.1935539,55.1107826 c0,0-4.47369-57.0700302-4.3353157-59.9786758c0.1383705-2.9086452,2.1437759-6.4212666,2.1437759-6.4212666 s4.5128441,64.9704437,4.7400169,65.4579773L44.1935539,55.1107826z"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="46.7420311,54.1688156 47.7669106,52.8114014 43.6809464,-6.2808452 42.5449982,-10.5190935"
      />
      <path
        fill={colors[0]}
        d="M44.4982605,55.1938705l0.2829666,8.6871185l1.5291367,0.5151672l1.7229576-1.396225 c0,0-0.4822388-5.0748558-0.6596756-8.2660408l2.7312927-1.252224l0.0940895-1.9058571l-0.6038895-0.066452l-9.4072838,1.723465 l0.0167198,2.0997581L44.4982605,55.1938705z"
      />
    </g>
  )
}

export default BroadSwordR
