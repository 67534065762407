import React from "react"

const LuteL = ({
  colors = ["#94712B", "#D9A53F", "#695020", "#B68B34", "#8E6D2B"]
}) => {
  return (
    <g id="LuteL">
      <path
        fill={colors[0]}
        d="M107.9000015,45.4000015L107,45.5l-0.0999985,0.4000015l-0.5,0.0999985L106,45.2999992 v-0.5999985l0.6999969,0.0999985l0.1999969,0.2999992l1,0.0999985C108,45.2000008,108.0999985,45.4000015,107.9000015,45.4000015z"
      />
      <path
        fill={colors[0]}
        d="M108,44.5l-0.9000015,0.0999985L107,44.7999992l-0.4000015-0.2000008 l-0.4000015-0.4000015l-0.0999985-0.5l0.5,0.2000008L107,44.1999969l1,0.0999985 C108.0999985,44.4000015,108.1999969,44.5,108,44.5z"
      />
      <path
        fill={colors[0]}
        d="M108.0999985,43.7999992h-0.8000031L107.1999969,44l-0.5-0.0999985L106.2999954,43.5 l-0.0999985-0.5l0.5,0.2000008L107.0999985,43.5l1,0.0999985 C108.1999969,43.5999985,108.3000031,43.7999992,108.0999985,43.7999992z"
      />
      <path
        fill={colors[0]}
        d="M107.6999969,46.5999985l-0.9000015,0.0999985l-0.0999985,0.2000008h-0.5 l-0.4000015-0.5999985l-0.0999985-0.5l0.5,0.2000008l0.4000015,0.2999992l1,0.0999985 C107.8000031,46.4000015,107.9000015,46.5999985,107.6999969,46.5999985z"
      />
      <path
        fill={colors[1]}
        d="M100.1999969,60C94.8999939,59.7999992,84.3999939,74.4000015,83.5,77.5 c-0.9000015,3.1999969-1.1999969,6.8000031,3.1999969,9.0999985c4.4000015,2.3000031,10.4000015-2,12.0999985-4.5 C100.5999985,79.5999985,103.8000031,60.5999985,100.1999969,60z"
      />
      <path
        fill={colors[2]}
        d="M110,45.2999992l-0.0999985-1l-8.5999985,17 c0,0,3.0999985,28.0000038-14.5999985,25.3999977c0,0,11.3000031,6.5999985,16.2000046-8.6999969 c1.1999969-7.1999969-0.4000015-15.7999992-0.4000015-15.7999992"
      />
      <path
        fill={colors[0]}
        d="M109.5999985,48.2000008L110.4000015,48.5v0.2000008l0.4000015,0.2000008l0.5-0.2999992 L111.5,48.2000008h-0.6999969H110.5l-1-0.0999985C109.5999985,48,109.4000015,48.0999985,109.5999985,48.2000008z"
      />
      <path
        fill={colors[0]}
        d="M109.8000031,46l0.8000031,0.2999992v0.4000015l0.4000015,0.2000008l0.5-0.5 l0.1999969-0.5999985h-0.6999969l-0.3000031,0.2000008l-1-0.0999985 C109.8000031,45.7999992,109.6999969,45.9000015,109.8000031,46z"
      />
      <path
        fill={colors[0]}
        d="M110,45.0999985l0.8000031,0.2999992v0.2999992l0.4000015-0.0999985l0.5-0.2999992 l0.1999969-0.4000015h-0.5999985l-0.5,0.2000008l-1-0.0999985C109.9000015,44.9000015,109.8000031,45.0999985,110,45.0999985z"
      />
      <path
        fill={colors[0]}
        d="M110.0999985,44.4000015l0.8000031,0.2000008v0.2000008l0.5,0.0999985l0.5-0.2999992 l0.1999969-0.4000015H111.5l-0.5,0.2000008l-1-0.0999985C110.0999985,44.2000008,109.9000015,44.2999992,110.0999985,44.4000015z"
      />
      <path
        fill={colors[0]}
        d="M109.8000031,47.2000008L110.6000061,47.5v0.2999992l0.5,0.0999985l0.5-0.5 l0.1999969-0.4000015h-0.5999985l-0.5,0.2000008l-1-0.0999985C109.6999969,47,109.5999985,47.2000008,109.8000031,47.2000008z"
      />
      <polygon
        fill={colors[3]}
        points="98.3000031,60.5 107.8000031,43.2999992 110.1999969,43.7999992 101.3000031,61.2999992"
      />
      <polygon
        fill={colors[4]}
        points="85,79.3000031 90.4000015,82 90.8000031,80.8000031 85.5999985,78.1999969"
      />
      <polygon
        fill={colors[0]}
        points="109.9000015,44.5 110,45.2999992 109.3000031,48.7000008 108.4000015,48.5"
      />
      <polygon
        fill={colors[2]}
        points="109.6999969,48.2000008 109.3000031,48.7000008 109.9000015,44.2000008 110.1999969,43.7999992"
      />
      <ellipse
        transform="rotate(-58.149 94.065 69.79)"
        fill={colors[0]}
        cx="94.065"
        cy="69.79"
        rx="2.6"
        ry="1.8"
      />
      <line fill="none" x1="86.9" y1="79.4" x2="108.1" y2="43.5" />
      <line fill="none" x1="87.5" y1="79.6" x2="108.7" y2="43.5" />
      <line fill="none" x1="88" y1="79.7" x2="109.2" y2="43.7" />
      <line fill="none" x1="88.5" y1="79.9" x2="109.7" y2="43.7" />
    </g>
  )
}

export default LuteL
