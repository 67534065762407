import React from "react"

const Short = ({ colors = ["#642B00"] }) => {
  return (
    <g id="Short">
      <path
        fill={colors[0]}
        d="M75.2,13.3c0.1,0.1,0.4,0.2,0.5,0.3c-0.3-0.3-0.4-1.2-0.5-1.6c-0.1-0.4-0.3-0.9-0.4-1.3l0.5,0.3 c-0.6-0.6-1.2-2.3-2.4-2.9c-0.1,0-1-0.5-1-0.5C72,7,72.5,7.1,73,7.1c-0.8-0.2-0.6-0.2-0.9-0.2c-0.4,0-0.6,0.4-0.8,0.7 c-0.7-0.7-1.7-0.8-3.2-0.7c-1.1,0.1-2.4,0.8-3,1.5C64.4,9,63.8,9.6,63,10.2h0.5c-1,1.8-1,2.6-1.8,3.8c0.1,0,0.5,0,0.6,0 c0,0.3,0.8,1.5,0.8,1.5c0.1,0.9,0,1.8,0.5,2.4c0-0.3-0.1-3.2-0.1-3.4l0.3,0.3c0,0.1,0,0.1,0.1,0.1c0.1-0.3,0.1-0.6,0.2-0.9 c0.1-0.2,0.2-0.5,0.4-0.7c0.2-0.2,0.4-0.3,0.6-0.6c0,0,0-0.1,0.1-0.2c0.2-0.1,0.4-0.1,0.4-0.2c1-0.6,1.6-0.7,2.8,0 c0.9,0.5,2.5,0.9,2.2,0.6c-0.6-0.6-0.6-0.6-0.3-0.6c0.2,0,0.7,0.6,1.7,0.6c0.2,0-0.7-0.7-0.7-0.7s0.8,0,1.3,0.4 c0.1,0.1,1.2,1.2,1.5,1.4c0.2,0.6,0,3.1,0,3.8c0.3-0.5,0.5-2.8,1.1-3C75.5,14.9,75.4,13.5,75.2,13.3z M71.2,11.2L71.2,11.2 L71.2,11.2L71.2,11.2z"
      />
    </g>
  )
}

export default Short
