import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import SmallEyes from "./SmallEyes"
import BigEyes from "./BigEyes"
import Mouth from "./Mouth"
import LiteSmile from "./LiteSmile"
import Ears from "./Ears"
import Nose from "./Nose"
import NosePointy from "./NosePointy"
import Brows from "./Brows"

const CATEGORY = "face"
const CHARACTER = "ManGnome"
export const IDS = {
  SMALL_EYES: "SmallEyes",
  BIG_EYES: "BigEyes",
  MOUTH: "Mouth",
  LITE_SMILE: "LiteSmile",
  EARS: "Ears",
  NOSE: "Nose",
  NOSE_POINTY: "NosePointy",
  BROWS: "Brows"
}

export const components = {
  [IDS.SMALL_EYES]: SmallEyes,
  [IDS.BIG_EYES]: BigEyes,
  [IDS.MOUTH]: Mouth,
  [IDS.LITE_SMILE]: LiteSmile,
  [IDS.EARS]: Ears,
  [IDS.NOSE]: Nose,
  [IDS.NOSE_POINTY]: NosePointy,
  [IDS.BROWS]: Brows
}

export const Group = ({ props }) => (
  <g id="face">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.SMALL_EYES,
    name: "Small Eyes",
    defaultColors: ["#010101"],
    colorable: true,
    component: components[IDS.SMALL_EYES],
    inUI: true,
    enabled: false,
    subGroupId: 4
  },
  {
    id: IDS.BIG_EYES,
    name: "Big Eyes",
    defaultColors: ["#E0E0E0"],
    colorable: true,
    component: components[IDS.BIG_EYES],
    inUI: true,
    enabled: true,
    subGroupId: 4
  },
  {
    id: IDS.MOUTH,
    name: "Mouth",
    defaultColors: ["#010101"],
    colorable: false,
    component: components[IDS.MOUTH],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.LITE_SMILE,
    name: "Lite Smile",
    defaultColors: ["#353891"],
    colorable: false,
    component: components[IDS.LITE_SMILE],
    inUI: true,
    enabled: true,
    subGroupId: 3
  },
  {
    id: IDS.EARS,
    name: "Ears",
    defaultColors: ["#E7B080", "#353891"],
    colorable: true,
    component: components[IDS.EARS],
    inUI: true,
    enabled: true,
    subGroupId: 5
  },
  {
    id: IDS.NOSE,
    name: "Nose",
    defaultColors: ["#ED1C24", "#SVGID_", "#FFFFFF"],
    colorable: false,
    component: components[IDS.NOSE],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.NOSE_POINTY,
    name: "Nose Pointy",
    defaultColors: [
      "#EF4E23",
      "#EF4C23",
      "#ED3324",
      "#EC2324",
      "#EC1E24",
      "#SVGID_",
      "#353891",
      "#FFFFFF"
    ],
    colorable: false,
    component: components[IDS.NOSE_POINTY],
    inUI: true,
    enabled: true,
    subGroupId: 1
  },
  {
    id: IDS.BROWS,
    name: "Brows",
    defaultColors: ["#5E5E5E"],
    colorable: true,
    component: components[IDS.BROWS],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
