import React from "react"

const LongBowLeft = ({
  colors = [
    "#695E60",
    "#42130B",
    "#FFCD03",
    "#EDD692",
    "#B5AC95",
    "#915B25",
    "#A98A30"
  ]
}) => {
  return (
    <g id="LongBowLeft">
      <line
        fill="none"
        stroke={colors[0]}
        strokeWidth="0.106"
        strokeMiterlimit="10"
        x1="67.775"
        y1="106.984"
        x2="88.343"
        y2="53.724"
      />
      <polygon
        fill={colors[1]}
        points="84.2783661,85.8687668 84.8906097,84.4702301 85.8634796,81.8394165 86.4341736,80.3779755 84.9952011,79.4309616 84.2975693,81.2473755 83.2831421,83.8152924 82.5855103,85.6316986"
      />
      <polygon
        fill={colors[2]}
        points="84.3816986,86.1619568 82.0811157,86.2360382 82.2721024,85.5676651 85.0107803,85.7463379"
      />
      <g fill={colors[3]}>
        <polygon points="85.8634796,81.8394165 84.4031677,80.9968796 84.256012,81.1844711 84.0863724,81.7483749 85.6095886,82.5493469" />
        <polygon points="86.201622,80.9834442 84.6784058,80.1824722 84.4458618,80.787941 85.9061737,81.6304779" />
        <polygon points="84.0121613,84.5084076 83.1561966,84.1702576 82.8820877,84.712822 83.3830872,84.9240189" />
        <polygon points="84.489563,85.3677673 84.7221069,84.762291 84.0121613,84.5084076 83.4875565,84.9453659" />
        <polygon points="85.3556976,83.2592926 83.74823,82.6043472 83.4527817,83.2513809 85.0389023,84.0107956" />
        <polygon points="84.8490448,84.4073257 84.9546509,84.1568222 83.4314346,83.3558502 83.2831421,83.8152924 83.1775436,84.0657883 84.8063583,84.6162643" />
        <polygon points="84.3412704,85.8272095 84.4053116,85.5137939 82.9236526,84.7757263 82.5855103,85.6316986" />
      </g>
      <g fill={colors[4]}>
        <polygon points="85.3770447,83.1548233 85.5882492,82.6538162 84.5031204,82.1056137 84.0200729,82.605484" />
        <polygon points="83.769577,82.4998779 83.9156036,82.584137 84.3986511,82.084259 84.0021133,81.8944092" />
      </g>
      <g fill={colors[4]}>
        <polygon points="86.285881,80.83741 86.4341736,80.3779755 86.1421051,80.209465 85.8477859,80.5846481" />
        <polygon points="84.7626572,80.0364304 85.7433167,80.5633011 86.1005478,80.1465607 84.9536438,79.3680573" />
      </g>
      <path
        fill={colors[5]}
        d="M88.2972717,54.7480736c0.204422,1.1300774,1.1973801,3.7272453,2.2948151,6.3457565 c2.1937332,5.5088692-1.9324265,12.1207085-4.1230164,15.9175148 c-0.5055161,0.8761902-0.9267883,1.6063461-1.2008972,2.1489105l1.0828705,1.0919037 c0.1482849-0.4594421,0.7380676-1.4816589,1.3491821-2.608345c2.2748489-3.9428406,6.1954575-11.4129028,3.4175949-17.2587929 c-1.0367813-2.1163826-2.6542969-5.3853188-2.5475693-5.9076653s0.169632-0.5639076,0.169632-0.5639076 s-0.1022034-0.5650406-0.332489-0.5032616l-0.1685104,0.2920647 C88.1759796,53.7438087,88.1748505,54.0156593,88.2972717,54.7480736z"
      />
      <ellipse
        transform="rotate(-47.185 85.767 79.646)"
        fill={colors[6]}
        cx="85.767"
        cy="79.646"
        rx="0.452"
        ry="1.131"
      />
      <polygon
        fill={colors[2]}
        points="86.7273636,80.2746277 84.9985962,78.6154327 84.7447052,79.3253708 86.8497925,81.0070496"
      />
      <path
        fill={colors[5]}
        d="M68.447876,106.0872345c0.9020538-0.6863327,3.4374466-1.909584,5.9728317-3.1328278 c5.3639755-2.5498352,6.7346268-10.3232574,7.6513672-14.5435944c0.2134552-1.044693,0.4257889-1.8175354,0.5954285-2.3814468 l1.5895157-0.0561295c-0.1898499,0.3965302-0.4875641,1.5872574-0.743721,2.840889 c-0.9594345,4.4292755-3.1680984,12.5756226-9.1173248,15.0602875c-2.241066,0.8480682-5.6133423,2.1723938-5.8661041,2.6104813 c-0.2527542,0.4380951-0.2111969,0.5009995-0.2111969,0.5009995s-0.4403534,0.2909393-0.6279449,0.1437759l0.0640335-0.3134155 C67.6502914,106.7949142,67.7974548,106.6073227,68.447876,106.0872345z"
      />
      <ellipse
        transform="rotate(-.496 83.328 86.018)"
        fill={colors[6]}
        cx="83.328"
        cy="86.018"
        rx="1.131"
        ry="0.452"
      />
      <polygon
        fill={colors[6]}
        points="84.3626099,85.7227325 82.2721024,85.5676651 82.5035172,85.2340393 84.489563,85.3677673"
      />
      <polygon
        fill={colors[6]}
        points="86.3072205,80.7329407 84.6604462,79.4713974 84.7447052,79.3253708 86.3285675,80.6284714"
      />
    </g>
  )
}

export default LongBowLeft
