import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import CutlassR from "./CutlassR"
import CutlassL from "./CutlassL"
import JugL from "./JugL"
import JugR from "./JugR"
import BodyBase from "./BodyBase"
import NobelCape from "./NobelCape"
import WornShieldRight from "./WornShieldRight"
import LongswordRight from "./LongswordRight"
import LongswordLeft from "./LongswordLeft"
import RectangleShieldLeft from "./RectangleShieldLeft"
import RectangleShieldRight from "./RectangleShieldRight"
import ShortswordLeft from "./ShortswordLeft"
import ShortswordRight from "./ShortswordRight"
import SlingShotRight from "./SlingShotRight"
import SlingshotL from "./SlingshotL"
import WarhammerL from "./WarhammerL"
import WarhammerR from "./WarhammerR"
import MaceR from "./MaceR"
import MaceL from "./MaceL"
import OpenCloak from "./OpenCloak"
import BowLeft from "./BowLeft"
import BowRight from "./BowRight"
import WizardRobe from "./WizardRobe"
import WizardHat from "./WizardHat"
import StaffLeft from "./StaffLeft"
import StaffRight from "./StaffRight"
import AxeLeft from "./AxeLeft"
import AxeRight from "./AxeRight"
import Antlers from "./Antlers"
import SwordRightHip from "./SwordRightHip"
import SwordLeftHip from "./SwordLeftHip"
import LuteRight from "./LuteRight"
import LuteLeft from "./LuteLeft"
import DaggerLeft from "./DaggerLeft"
import DaggerRight from "./DaggerRight"
import WornShieldLeft from "./WornShieldLeft"
import GoldAxeRight from "./GoldAxeRight"
import GoldAxeLeft from "./GoldAxeLeft"
import BattleAxeRight from "./BattleAxeRight"
import BattleAxeLeft from "./BattleAxeLeft"
import BlueFlameRight from "./BlueFlameRight"
import BlueFlameLeft from "./BlueFlameLeft"
import FlameLeft from "./FlameLeft"
import FlameRight from "./FlameRight"
import RoundWoodShieldRight from "./RoundWoodShieldRight"
import RoundWoodShieldLeft from "./RoundWoodShieldLeft"
import MorningStarLeft from "./MorningStarLeft"
import MorningStarRight from "./MorningStarRight"
import TurkeyLegR from "./TurkeyLegR"

const CATEGORY = "shadow"
const CHARACTER = "ManHalfling"
export const IDS = {
  CUTLASS_R: "CutlassR",
  CUTLASS_L: "CutlassL",
  JUG_L: "JugL",
  JUG_R: "JugR",
  BODY_BASE: "BodyBase",
  NOBEL_CAPE: "NobelCape",
  WORN_SHIELD_RIGHT: "WornShieldRight",
  LONGSWORD_RIGHT: "LongswordRight",
  LONGSWORD_LEFT: "LongswordLeft",
  RECTANGLE_SHIELD_LEFT: "RectangleShieldLeft",
  RECTANGLE_SHIELD_RIGHT: "RectangleShieldRight",
  SHORTSWORD_LEFT: "ShortswordLeft",
  SHORTSWORD_RIGHT: "ShortswordRight",
  SLING_SHOT_RIGHT: "SlingShotRight",
  SLINGSHOT_L: "SlingshotL",
  WARHAMMER_L: "WarhammerL",
  WARHAMMER_R: "WarhammerR",
  MACE_R: "MaceR",
  MACE_L: "MaceL",
  OPEN_CLOAK: "OpenCloak",
  BOW_LEFT: "BowLeft",
  BOW_RIGHT: "BowRight",
  WIZARD_ROBE: "WizardRobe",
  WIZARD_HAT: "WizardHat",
  STAFF_LEFT: "StaffLeft",
  STAFF_RIGHT: "StaffRight",
  AXE_LEFT: "AxeLeft",
  AXE_RIGHT: "AxeRight",
  ANTLERS: "Antlers",
  SWORD_RIGHT_HIP: "SwordRightHip",
  SWORD_LEFT_HIP: "SwordLeftHip",
  LUTE_RIGHT: "LuteRight",
  LUTE_LEFT: "LuteLeft",
  DAGGER_LEFT: "DaggerLeft",
  DAGGER_RIGHT: "DaggerRight",
  WORN_SHIELD_LEFT: "WornShieldLeft",
  GOLD_AXE_RIGHT: "GoldAxeRight",
  GOLD_AXE_LEFT: "GoldAxeLeft",
  BATTLE_AXE_RIGHT: "BattleAxeRight",
  BATTLE_AXE_LEFT: "BattleAxeLeft",
  BLUE_FLAME_RIGHT: "BlueFlameRight",
  BLUE_FLAME_LEFT: "BlueFlameLeft",
  FLAME_LEFT: "FlameLeft",
  FLAME_RIGHT: "FlameRight",
  ROUND_WOOD_SHIELD_RIGHT: "RoundWoodShieldRight",
  ROUND_WOOD_SHIELD_LEFT: "RoundWoodShieldLeft",
  MORNING_STAR_LEFT: "MorningStarLeft",
  MORNING_STAR_RIGHT: "MorningStarRight",
  TURKEY_LEG_R: "TurkeyLegR"
}

export const components = {
  [IDS.CUTLASS_R]: CutlassR,
  [IDS.CUTLASS_L]: CutlassL,
  [IDS.JUG_L]: JugL,
  [IDS.JUG_R]: JugR,
  [IDS.BODY_BASE]: BodyBase,
  [IDS.NOBEL_CAPE]: NobelCape,
  [IDS.WORN_SHIELD_RIGHT]: WornShieldRight,
  [IDS.LONGSWORD_RIGHT]: LongswordRight,
  [IDS.LONGSWORD_LEFT]: LongswordLeft,
  [IDS.RECTANGLE_SHIELD_LEFT]: RectangleShieldLeft,
  [IDS.RECTANGLE_SHIELD_RIGHT]: RectangleShieldRight,
  [IDS.SHORTSWORD_LEFT]: ShortswordLeft,
  [IDS.SHORTSWORD_RIGHT]: ShortswordRight,
  [IDS.SLING_SHOT_RIGHT]: SlingShotRight,
  [IDS.SLINGSHOT_L]: SlingshotL,
  [IDS.WARHAMMER_L]: WarhammerL,
  [IDS.WARHAMMER_R]: WarhammerR,
  [IDS.MACE_R]: MaceR,
  [IDS.MACE_L]: MaceL,
  [IDS.OPEN_CLOAK]: OpenCloak,
  [IDS.BOW_LEFT]: BowLeft,
  [IDS.BOW_RIGHT]: BowRight,
  [IDS.WIZARD_ROBE]: WizardRobe,
  [IDS.WIZARD_HAT]: WizardHat,
  [IDS.STAFF_LEFT]: StaffLeft,
  [IDS.STAFF_RIGHT]: StaffRight,
  [IDS.AXE_LEFT]: AxeLeft,
  [IDS.AXE_RIGHT]: AxeRight,
  [IDS.ANTLERS]: Antlers,
  [IDS.SWORD_RIGHT_HIP]: SwordRightHip,
  [IDS.SWORD_LEFT_HIP]: SwordLeftHip,
  [IDS.LUTE_RIGHT]: LuteRight,
  [IDS.LUTE_LEFT]: LuteLeft,
  [IDS.DAGGER_LEFT]: DaggerLeft,
  [IDS.DAGGER_RIGHT]: DaggerRight,
  [IDS.WORN_SHIELD_LEFT]: WornShieldLeft,
  [IDS.GOLD_AXE_RIGHT]: GoldAxeRight,
  [IDS.GOLD_AXE_LEFT]: GoldAxeLeft,
  [IDS.BATTLE_AXE_RIGHT]: BattleAxeRight,
  [IDS.BATTLE_AXE_LEFT]: BattleAxeLeft,
  [IDS.BLUE_FLAME_RIGHT]: BlueFlameRight,
  [IDS.BLUE_FLAME_LEFT]: BlueFlameLeft,
  [IDS.FLAME_LEFT]: FlameLeft,
  [IDS.FLAME_RIGHT]: FlameRight,
  [IDS.ROUND_WOOD_SHIELD_RIGHT]: RoundWoodShieldRight,
  [IDS.ROUND_WOOD_SHIELD_LEFT]: RoundWoodShieldLeft,
  [IDS.MORNING_STAR_LEFT]: MorningStarLeft,
  [IDS.MORNING_STAR_RIGHT]: MorningStarRight,
  [IDS.TURKEY_LEG_R]: TurkeyLegR
}

export const Group = ({ props }) => (
  <g id="shadow">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.CUTLASS_R,
    name: "Cutlass R",
    defaultColors: ["#01060F"],
    colorable: false,
    component: components[IDS.CUTLASS_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.CUTLASS_L,
    name: "Cutlass L",
    defaultColors: ["#01060F"],
    colorable: false,
    component: components[IDS.CUTLASS_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.JUG_L,
    name: "Jug L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.JUG_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.JUG_R,
    name: "Jug R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.JUG_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BODY_BASE,
    name: "Body Base",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BODY_BASE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.NOBEL_CAPE,
    name: "Nobel Cape",
    defaultColors: [],
    colorable: false,
    component: components[IDS.NOBEL_CAPE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WORN_SHIELD_RIGHT,
    name: "Worn Shield Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WORN_SHIELD_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONGSWORD_RIGHT,
    name: "Longsword Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONGSWORD_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONGSWORD_LEFT,
    name: "Longsword Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONGSWORD_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.RECTANGLE_SHIELD_LEFT,
    name: "Rectangle Shield Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.RECTANGLE_SHIELD_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.RECTANGLE_SHIELD_RIGHT,
    name: "Rectangle Shield Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.RECTANGLE_SHIELD_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SHORTSWORD_LEFT,
    name: "Shortsword Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.SHORTSWORD_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SHORTSWORD_RIGHT,
    name: "Shortsword Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.SHORTSWORD_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SLING_SHOT_RIGHT,
    name: "Sling Shot Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.SLING_SHOT_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SLINGSHOT_L,
    name: "Slingshot L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.SLINGSHOT_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WARHAMMER_L,
    name: "Warhammer L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WARHAMMER_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WARHAMMER_R,
    name: "Warhammer R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WARHAMMER_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MACE_R,
    name: "Mace R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MACE_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MACE_L,
    name: "Mace L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MACE_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.OPEN_CLOAK,
    name: "Open Cloak",
    defaultColors: [],
    colorable: false,
    component: components[IDS.OPEN_CLOAK],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BOW_LEFT,
    name: "Bow Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BOW_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BOW_RIGHT,
    name: "Bow Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BOW_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WIZARD_ROBE,
    name: "Wizard Robe",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WIZARD_ROBE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WIZARD_HAT,
    name: "Wizard Hat",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WIZARD_HAT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.STAFF_LEFT,
    name: "Staff Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.STAFF_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.STAFF_RIGHT,
    name: "Staff Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.STAFF_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.AXE_LEFT,
    name: "Axe Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.AXE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.AXE_RIGHT,
    name: "Axe Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.AXE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.ANTLERS,
    name: "Antlers",
    defaultColors: [],
    colorable: false,
    component: components[IDS.ANTLERS],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SWORD_RIGHT_HIP,
    name: "Sword Right Hip",
    defaultColors: [],
    colorable: false,
    component: components[IDS.SWORD_RIGHT_HIP],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SWORD_LEFT_HIP,
    name: "Sword Left Hip",
    defaultColors: [],
    colorable: false,
    component: components[IDS.SWORD_LEFT_HIP],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LUTE_RIGHT,
    name: "Lute Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LUTE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LUTE_LEFT,
    name: "Lute Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LUTE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DAGGER_LEFT,
    name: "Dagger Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.DAGGER_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DAGGER_RIGHT,
    name: "Dagger Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.DAGGER_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WORN_SHIELD_LEFT,
    name: "Worn Shield Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WORN_SHIELD_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_RIGHT,
    name: "Gold Axe Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.GOLD_AXE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_LEFT,
    name: "Gold Axe Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.GOLD_AXE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BATTLE_AXE_RIGHT,
    name: "Battle Axe Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BATTLE_AXE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BATTLE_AXE_LEFT,
    name: "Battle Axe Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BATTLE_AXE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BLUE_FLAME_RIGHT,
    name: "Blue Flame Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BLUE_FLAME_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BLUE_FLAME_LEFT,
    name: "Blue Flame Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BLUE_FLAME_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FLAME_LEFT,
    name: "Flame Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.FLAME_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FLAME_RIGHT,
    name: "Flame Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.FLAME_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.ROUND_WOOD_SHIELD_RIGHT,
    name: "Round Wood Shield Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.ROUND_WOOD_SHIELD_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.ROUND_WOOD_SHIELD_LEFT,
    name: "Round Wood Shield Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.ROUND_WOOD_SHIELD_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MORNING_STAR_LEFT,
    name: "Morning Star Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MORNING_STAR_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MORNING_STAR_RIGHT,
    name: "Morning Star Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MORNING_STAR_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.TURKEY_LEG_R,
    name: "Turkey Leg R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.TURKEY_LEG_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
