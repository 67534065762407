import React from "react"

const BobR = ({ colors = ["#4691FF", "#FFFFFF"] }) => {
  return (
    <g id="BobR">
      <path
        opacity="0.1"
        fill="#21376C"
        d="M68.9091263,41.8513374 c-0.132164,0.1512451-0.5286636,0.9074554-0.5286636,0.9074554s-3.1059265-0.050415-3.3041763,0 s-1.784256,1.663662-2.0485916,2.3694611c-0.2643318,0.7057953-0.5286674,3.7306404-0.4625816,4.5876808 c0.066082,0.8570366,0.5947495,2.823185,1.4538383,3.5289841c0.8590851,0.7057953,3.3702545,1.8653183,3.3702545,1.8653183 l0.4625854,0.2520714l-2.1807556-0.1008301l-2.4450874-1.3107643l-3.1720085-3.8818817l3.3041725-8.5199776L68.9091263,41.8513374 z"
      />
      <path
        fill={colors[0]}
        d="M68.1326447,38.7130623c-0.9416885-0.1512413-2.1807556-0.9452629-4.9066963,0.6427803 c-5.4023285,6.730278-5.8979568,11.6834602-5.8979568,11.3809738c0-0.164856,3.5008698,3.7553978,4.6588898,4.7263184 c0.3411369,0.2860222,2.0022316,1.1069679,2.6268196,0.9074554c0-0.5671577-0.1306152-1.2481766,0.0495605-1.3611794 c-0.396492-0.3402901-2.0320663-1.8149071-2.0320663-1.8149071s1.784256,1.4746094,4.2623901,1.9661484 c-2.3790131-1.0208855-3.3206978-1.209938-5.0058289-4.9153709c0.0991249-2.3442535,0.4899559-5.0962486,1.6355667-6.616848 c1.0548096-1.400074,1.7632141-1.4343796,4.8571396-1.1721268C68.9256516,39.8851891,69.0743408,38.8643074,68.1326447,38.7130623 z"
      />
      <path
        opacity="0.39"
        fill="#21376C"
        d="M58.2201195,51.530838l2.3294449,0.7562103 c0,0-1.3877525-0.8318291-0.8425636-2.4954948c0.1486855,1.096508,2.1311913,3.1382751,2.1311913,3.1382751l-0.2478104-0.6805878 l1.9825058,1.8905258l1.090374,0.8696442l-0.0495605,1.3611794l-0.9416885-0.0756226l-1.5364418-0.6805878l-3.915451-3.8944893 V51.530838z"
      />
      <path
        opacity="0.39"
        fill="#21376C"
        d="M68.467186,39.0533524 c-0.0369949-0.0227737-0.0745163-0.0363655-0.1116562-0.057476l0.000145-0.0086594l-0.0151825,0.0009155 c-1.3087845-0.7367363-2.8661041-0.5623779-3.9250412-0.0860176c-1.0793381,0.4855385-1.6482964,1.2815781-1.6975212,1.3989601 c1.604702-1.1062698,4.5597496-0.3969955,4.5597496-0.3969955s-2.1435928-0.1795998-2.7383499,0.1890602 c2.2799072-0.302475,2.6639862,1.0114517,2.6639862,1.0114517s-0.6071091-0.6144333-2.1311874-0.0850677 c1.2267227-0.3970528,3.0233154,1.0208588,3.0233154,1.0208588l0.2478256-2.2781029l0.0118103-0.7356567 C68.3922958,39.037693,68.4300385,39.0413055,68.467186,39.0533524z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M65.7288589,41.7505112 c-0.7684784,0.1400795-1.4504929,0.410202-2.0440636,0.7792969c-1.1329002,1.0243416-3.4031982,4.7668419-2.6808128,7.4873161 c0.3723488,1.4022446,1.186451,2.6676826,2.4615135,3.492466c-0.8128967-0.8753242-1.4625168-2.049366-1.8235359-3.2835617 c0.3874855-5.7964554,1.4508553-6.7291641,2.4761124-7.6940994C64.553978,42.1216736,65.3651428,41.8830376,65.7288589,41.7505112 z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M61.6869507,43.3400536 c-0.0004501-0.6012039,0.7955551-2.0558739,1.5142136-2.9822311c-1.6355667,1.1343155-2.6763496,3.1949844-2.6763496,3.1949844 s-1.329834,2.1713982-2.0073166,4.1024513c-0.4531975,1.2917595-0.3319702,2.5062828-0.3717232,2.7790756 c0.2745972-0.418911,0.0587997-0.6059647,1.7594757-2.4387817c-0.1540642,1.1338043-0.0495682,1.7770958,0.4708443,2.6278343 c0.0495491-1.9850464,0.0495453-3.7999535,2.4533691-8.0347672C62.829464,42.5886192,62.2146339,42.826786,61.6869507,43.3400536z"
      />
    </g>
  )
}

export default BobR
