import React from "react"
import { withSkinColor } from "../../builderConnector"

const Tail = ({ skinColor, color = [] }) => {
  return (
    <g id="Tail">
      <path
        fill={skinColor}
        d="M67.9564209,64.4415817c0,0-21.3352814,12.2466125-21.6433716,21.4893341 c-0.0770226,1.540451,1.2323608,2.3877029,3.234951,3.2349472c1.8485489,0.8472519,12.9398117-0.2310638,18.1773567-1.8485413 c0.1540451,1.7715225-15.8666725,6.0847931-20.7191048,3.8511353c-2.8498383-1.3093872-4.6213608-2.6957932-4.8524284-5.1605225 c-0.6161842-6.3928757,6.5469284-16.328804,19.9488754-25.5715256 C69.111763,56.893364,67.9564209,64.4415817,67.9564209,64.4415817z"
      />
    </g>
  )
}

export default withSkinColor(Tail, { character: "WomanMixed" })
