import React from "react"

const GreatAxeL = ({ colors = ["#9D9D9D", "#282560"] }) => {
  return (
    <g id="GreatAxeL">
      <path
        fill={colors[0]}
        d="M102.7706528,22.6239052c-0.2327118,0.5872574-0.7590561,1.0581551-1.3684998,1.2243328 c-2.3602066,1.0691586-4.1829681,1.4679718-6.6760788,1.3293476c-0.3213577,0.3822632-1.1413269,0.7368011-1.5624084,1.1135197 c0.0110931-0.1994495,0.2273407-4.0887032,0.2439804-4.3878765c0,0,0.9030685-0.0498199,0.9141541-0.2492676 c0,0-1.5512314-0.8865147-1.6839676-5.6954699c-0.7703247,4.8587732-2.2108307,5.5789385-2.2108307,5.5789385 c-0.0110931,0.1994495,0.8864288,0.2493553,0.8864288,0.2493553c-0.0110855,0.1994495-0.2439804,4.3878765-0.2439804,4.3878765 s-0.963974-0.6537971-1.4293213-1.2798691c-2.4931107-0.1386242-4.2548904-0.8367805-6.4875488-2.061285 c-0.481987-0.3268967-1.0581589-0.7590675-1.2243423-1.3685017c0,0-10.3387527,13.2296581,1.289772,23.5794201 c-1.3792496-5.7785606-1.3345566-13.7786961,5.7846985-14.0830746c0.1994476,0.0110893,1.7008591-0.0054607,1.7008591-0.0054607 l-0.376976,4.9806786c-0.1108093,0.1939049-0.4210815,0.3767204-0.8421555,0.753437l0.897522,0.0499039l-0.0831757,1.4958649 l-0.9972458-0.0554466c-0.0055466,0.09972-0.1164474,2.0942116-0.1219864,2.1939392 c0.0997238,0.0055428,1.096962,0.0609932,1.1966858,0.0665359c-1.181076,21.2413101-3.0774536,55.3470764-4.2585373,76.5883942 l-0.0997238-0.0055466c0.3434525,1.0194244,0.7201691,1.440506,1.1246109,1.3629608 c0.3878098,0.2216263,0.8088837-0.1550827,1.2687683-1.2298813l-0.0997162-0.0055466 c1.181076-21.2413025,3.0774536-55.3470764,4.2585373-76.5883942c0.0997238,0.0055466,1.096962,0.060997,1.1966858,0.0665398 c0.0055466-0.0997238,0.1164474-2.0942116,0.121994-2.1939392L92.8916092,38.37994l0.0831757-1.4958649l0.897522,0.0499039 c-0.1828156-0.3102608-0.5706253-0.5318909-0.7534409-0.8421555l0.1719818-4.8920422 c0,0,1.5955887,0.0887184,1.6953125,0.0942631c6.9418945,1.0862198,6.0994034,9.041996,4.1877441,14.6375732 C111.4792709,36.9126549,102.7706528,22.6239052,102.7706528,22.6239052z"
      />
      <path
        fill={colors[1]}
        d="M89.5404053,24.8892479c-2.3933868-0.1330795-4.0609818-0.7259674-6.0997391-1.8396549 c-2.4046478,3.6675396-6.6710434,12.0331326-0.8485565,18.8590107 c-0.6147308-5.135849,0.3994141-10.7813301,6.3108749-10.9527988c0.0997238,0.0055447,1.2964172,0.0720844,1.7008591-0.0054607 l0.3658829-4.7812309C90.6816406,25.9530315,89.9171219,25.3103256,89.5404053,24.8892479z"
        opacity="0.3"
      />
      <path
        fill={colors[1]}
        d="M94.8258057,25.1831303c2.3933868,0.1330795,4.1164246-0.2712784,6.2660828-1.1520786 c1.9832306,3.9115181,5.2958908,12.6985321-1.2474442,18.836834c1.1803131-5.0360413,0.7983017-10.7591515-5.0577087-11.5849266 c-0.0997238-0.0055447-1.3961411-0.0776291-1.7950439-0.0998096l0.1664352-4.7923183 C93.4739304,26.1082916,94.4047241,25.5598488,94.8258057,25.1831303z"
        opacity="0.3"
      />
      <rect
        x="90.946"
        y="37.847"
        transform="rotate(-86.817 91.346 40.095)"
        opacity="0.3"
        fill={colors[1]}
        width="0.799"
        height="4.495"
      />
      <rect
        x="91.376"
        y="34.706"
        transform="rotate(-86.817 91.526 36.854)"
        opacity="0.3"
        fill={colors[1]}
        width="0.3"
        height="4.295"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M91.213707,21.7812405 c0,0-0.5983429-0.0332699-0.8864288-0.2493553c0.609436-0.1661797,2.4100266-0.1660919,3.8947983,0.1165295 c-0.1052628,0.0941792-0.9141541,0.2492676-0.9141541,0.2492676S92.432579,21.4488811,91.213707,21.7812405z"
      />
      <path
        opacity="0.36"
        fill={colors[1]}
        d="M88.8959961,61.6654816l0.4269638-7.6787834 c0.0110931-0.1994514,0.0221786-0.3988991,0.1329956-0.5928001c0.7922516,0.1440811,1.6897659,0.193985,2.4820175,0.3380737 c-0.0055466,0.09972,0.0830917,0.3047142,0.0775452,0.4044418l-0.4435959,7.9779587 c-0.0110931,0.1994476-0.0166397,0.2991714-0.127449,0.4930763 C90.6522217,62.4633636,89.7713394,62.1142807,88.8959961,61.6654816z"
      />
    </g>
  )
}

export default GreatAxeL
