import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LuteLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LuteLeft") ? (
    <g id="LuteLeft">
      <path
        id="S_x24_luteLeft"
        d="M75.9528809,110.9341736c-3.0723877,0-5.5163345-0.2793045-6.2146072-0.6982651 c-2.6534271-1.3965454-6.7732315-4.8180771-4.6085892-12.0800858c0.7680969-2.723259,6.2844391-10.8930283,11.4516449-14.1749039 l5.7956543-10.4042358l5.23703-2.723259l2.9327316,1.7456741c0,0,3.4913559,2.0948181,3.4913559,3.4215317 s-2.4439468,7.8206329-2.4439468,7.8206329l-3.281868,5.7258224c0.3491287,2.374115,0.7680969,6.9128799,0,11.1723328 l-0.1396561,0.6284409C87.1252213,104.5100784,83.9131775,110.9341736,75.9528809,110.9341736z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LuteLeft, {
  character: "WomanGnome"
})
