import React from "react"

const GoldAxeLeft = ({ colors = ["#897441", "#A39155", "#EDCD6E"] }) => {
  return (
    <g id="GoldAxeLeft">
      <path
        fill={colors[0]}
        d="M93.6154633,70.0245895c-1.0800934,0.9164581-3.3009796,1.1038284-5.6399689,0.4123459 c-2.3389816-0.6914825-4.0854645-2.1166611-4.4582443-3.4672699c-2.1977921,0.6347122-3.9179001,2.0691071-4.5288849,4.0887527 c-0.6109848,2.0196381,0.0078964,4.1455154,1.5142136,5.8497849c1.0800934-0.9164581,3.3009796-1.1038284,5.6399612-0.4123459 c2.3389893,0.6914825,4.0854721,2.1166611,4.458252,3.4672699c2.1977921-0.6347122,3.9179001-2.0691071,4.5288773-4.0887527 C95.7564926,73.9266586,95.0656967,71.8166122,93.6154633,70.0245895z"
      />
      <path
        fill={colors[1]}
        d="M87.6211472,76.0195007l2.6887207-6.6243744 c-0.1194229-0.1999207-0.1510925-0.3437576-0.2705154-0.5436783l-2.9057465-0.868309 c-0.1279984,0.1035843-0.271843,0.1352539-0.471756,0.2546768l-1.3638458,7.162117c0,0-4.982254,19.4957275-6.3968887,24.0298538 c-1.1573029,3.6480255-3.1631165,8.2369232-3.8532791,9.8969727c0.1194229,0.1999207,0.3265991,0.4559174,0.5179367,0.640007 l2.587738,0.1088791l8.1176682-29.9132767L87.6211472,76.0195007z"
      />
      <path
        fill={colors[0]}
        d="M75.4621811,109.838768c-0.0877457-0.0560837,0.1682587-0.2632599,0.0646667-0.3912582 c0.8584061-1.923317,2.792305-6.496376,3.9251938-9.9128036c1.4304657-4.4622192,6.316391-23.7105179,6.356636-23.8701935 l1.3394318-6.9305267c0.0719223-0.0158386,0.2487488-0.5826035,0.2487488-0.5826035l2.2829056,0.7792358 c0.015831,0.0719147,0.1108398,0.5034256-0.0488281,0.4631805l-2.5765533,6.4488678l-1.349968,4.142868l-8.0299225,29.9693756 L75.4621811,109.838768z"
      />
      <path
        fill={colors[2]}
        d="M75.6779404,109.7912674c0.8584061-1.923317,2.8728104-6.815712,3.9337769-10.2163162 c1.334137-4.2147903,5.8736496-21.9541321,6.3968887-24.0298615l1.7636795-7.4009628l1.4528809,0.4341583l-2.4340439,7.0961227 l-1.5096436,4.1026306l-7.8385696,30.15345L75.6779404,109.7912674z"
      />
      <g fill={colors[2]}>
        <path d="M80.0974808,71.6436691c0.2414932-0.9580231,0.9059143-1.7075577,1.6897659-2.257164 c0.056076-0.0877533-0.1827621-0.4875946-0.26194-0.8471832c0.4143524,0.512001,0.6459427,0.5364151,0.717865,0.5205841 c0.599762-0.3582687,1.2872696-0.6604538,2.0064545-0.8188019c-0.3107681-0.3840027-0.6373596-0.8399277-0.7165375-1.1995163 c-2.1977921,0.6347122-3.9179001,2.0691071-4.5288773,4.0887527c-0.6109924,2.0196381,0.0078964,4.1455154,1.5142059,5.8497849 c0.2560043-0.2071762,0.5278473-0.3424377,0.8715973-0.4935303 C80.1627884,75.0224991,79.638916,73.3281326,80.0974808,71.6436691z" />
        <path d="M93.6154633,70.0245895c-0.312088,0.2949295-0.7277603,0.4618607-1.2311935,0.5727005 c1.3868942,1.5043564,2.0143509,3.3267212,1.4997025,5.098938c-0.1207504,0.4790115-0.4572525,1.0055313-0.7376709,1.4442978 c0,0,0.1194229,0.1999207,0.0475082,0.2157516c-0.0877533-0.0560837-0.0877533-0.0560837-0.1035843-0.1279984 c-0.0560837,0.0877533-0.2401733,0.2790985-0.296257,0.3668518c-0.0560837,0.0877457-0.0244064,0.2315903,0.1741867,0.7910995 c-0.1194229-0.1999283-0.5581894-0.4803467-0.6301041-0.4645081c-0.640007,0.5179367-1.383606,0.9078751-2.2307892,1.1698151 c0.2230072,0.327919,0.3582687,0.599762,0.421608,0.8874359c2.1977921-0.6347122,3.9179001-2.0691147,4.5288849-4.0887527 C95.7564926,73.9266586,95.1217804,71.7288666,93.6154633,70.0245895z" />
      </g>
    </g>
  )
}

export default GoldAxeLeft
