import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BattleAxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BattleAxeLeft") ? (
    <g id="BattleAxeLeft">
      <path
        id="S_x24_battleAxeLeft"
        d="M80.7,119.5l0.2-1.2l-1-60.9l3.6,0.4c6.6-5.4,9.3-12.9,7.6-21.1c-1.1-5.6-4-9.7-4.3-10.1L77.6,26 l-1-2.6l-5.2-3.2L66,23.7l-0.9,2.5l-9.2,0.7c-0.3,0.5-3,4.6-4,10.3c-1.4,8.2,1.6,15.7,8.3,20.9l3.6-0.5l1,60.9l0.1,0.9 C66.4,119.4,78.2,119.6,80.7,119.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BattleAxeLeft, {
  character: "ManDwarf"
})
