import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const AxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("AxeLeft") ? (
    <g id="AxeLeft">
      <path
        id="S_x24_axeLeft"
        d="M65,109.7l-3-1.4l-3.8-8L66,65.9h-2.1c-3.9-3.7-5.6-8.9-4.7-13.7l0,0c1-4.9,4.5-9,9.6-10.9l3.7,1.6 l4.9-1.2l3.8,0.7l4.2,2.9l3.9-0.1c3.9,3.7,5.6,8.9,4.7,13.7V59c-1,4.9-4.5,8.9-9.6,10.9L82.3,69c-2.2,9.4-4.4,18.6-4.9,21.7 c-0.8,4.5-1.3,10.8-1.4,12.6l-3.5,5.6c-0.8,0.5-1.6,0.8-2.5,1.1L65,109.7z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(AxeLeft, { character: "ManDwarf" })
