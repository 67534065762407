import React from "react"
import { withSkinColor } from "../../builderConnector"

const HeadBase = ({
  skinColor,
  colors = ["#E8B180", "#BE1E2D", "#SVGID_", "#FFFFFF"]
}) => {
  return (
    <g id="HeadBase">
      <path
        fill={skinColor}
        d="M79.9000015,46.2000008c-0.0999985-0.2000008-2-1-2.8000031,1.0999985v0.0999985L76,43.0999985 c-1.0999985-1.9000015-3.1999969-4.2000008-5.9000015-4.2000008H68.5h-0.0999985h-1.5999985 c-2.6999969,0-4.7999992,2.2000008-5.9000015,4.2000008l-1.0999985,4.2999992v-0.0999985 C59.1000023,45.2000008,57.1000023,46,57.0000038,46.2000008C56.7000046,47.1000023,56.4000053,47.4000015,57.3000031,50 c0.2999992,0.5999985,1.2000008,0.5,1.7000008,0.2999992C60.4000053,53.5,64.5,54.7000008,65.9000015,55.5 c1.5,0.9000015,4,0.9000015,5.0999985,0c1-0.7999992,5.5999985-1.7999992,6.8000031-5.2000008 C78.3000031,50.5,79.2000046,50.5999985,79.5,50C80.5999985,47.4000015,80.1999969,47.0999985,79.9000015,46.2000008z"
      />
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="-2369.767"
        y1="-1154.401"
        x2="-2369.767"
        y2="-1184.528"
        gradientTransform="rotate(180 -1155.754 -564)"
      >
        <stop offset="0" stopColor={colors[1]} />
        <stop offset="0.998" stopColor={colors[1]} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="56.18"
        y1="-1174.814"
        x2="59.68"
        y2="-1177.251"
        gradientTransform="matrix(1 0 0 -1 0 -1128)"
      >
        <stop offset="0" stopColor={colors[1]} />
        <stop offset="0.998" stopColor={colors[1]} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="65.299"
        y1="-1154.401"
        x2="65.299"
        y2="-1184.528"
        gradientTransform="matrix(1 0 0 -1 13.44 -1128)"
      >
        <stop offset="0" stopColor={colors[1]} />
        <stop offset="0.998" stopColor={colors[1]} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SVGID_4_"
        gradientUnits="userSpaceOnUse"
        x1="-2378.854"
        y1="-1174.785"
        x2="-2375.354"
        y2="-1177.222"
        gradientTransform="rotate(180 -1149.046 -564)"
      >
        <stop offset="0" stopColor={colors[1]} />
        <stop offset="0.998" stopColor={colors[1]} stopOpacity="0" />
      </linearGradient>
      <path
        opacity="0.55"
        fill="url(#SVGID_1_)"
        d="M58.7999992,49.0999985 c0-0.2000008,0-0.4000015,0.2000008-0.5h0.0999985l0,0c0.0999985,0,0.2999992-0.0999985,0.4000015,0 c0.0999985-0.2000008,0.0999985-0.2999992,0.2000008-0.4000015c0-0.2000008,0-0.5,0.0999985-0.7000008 c0.0999985-0.0999985-0.2000008-0.2999992-0.0999985-0.4000015C58.9000015,45.2999954,57.1000023,45.9999962,57,46.1999931 c-0.2999992,0.9000015-0.5999985,1.2000008,0.2999992,3.7999992c0.2000008,0.4000015,0.5999985,0.5,1.0999985,0.4000015 s0.5999985-0.2000008,0.7999992-0.5c0-0.0999985,0-0.2000008,0-0.2999992 C58.9000015,49.5999985,58.7000008,49.4000015,58.7999992,49.0999985z"
      />
      <path
        opacity="0.31"
        fill="url(#SVGID_2_)"
        d="M58.4000015,49.7000008 C57.9000015,49.5,57.5,48.9000015,57.5,48.2999992c0-0.7000008,0.5999985-1.2000008,1.2999992-1 c0.4000015,0.0999985,0.7000008,0.4000015,0.7999992,0.7999992c0,0,0.2000008-0.4000015,0.0999985-0.5999985 c-0.0999985-0.0999985-0.0999985-0.4000015-0.2999992-0.7999992C58.5,45.4000015,57,46,57,46.2000008 C56.7000008,47.1000023,56.4000015,47.4000015,57.2999992,50C57.5999985,50.5999985,58.5,50.5,59,50.2999992 c0.2000008,0.4000015,0.4000015,0.7999992,0.7000008,1.2000008c-0.0999985-0.5999985-0.0999985-1.2000008-0.0999985-1.7999992 C59.2999992,49.9000015,58.7999992,49.9000015,58.4000015,49.7000008z"
      />
      <path
        opacity="0.61"
        fill="#FFF"
        d="M58.5999985,46.2999992L58.5999985,46.2999992 C58.5999985,46.2000008,58.5999985,46.2000008,58.5999985,46.2999992 C58.5,46.2000008,58.3999977,46.2000008,58.3999977,46.2000008C57.9999962,46.1000023,57.4999962,46.1000023,57.2999992,46.5l0,0 c0,0-0.0999985,0-0.0999985,0.0999985s0,0.0999985,0.0999985,0.0999985c0.0999985,0.0999985,0.2999992,0,0.5-0.0999985 c0.0999985,0,0.5999985-0.0999985,0.7999992-0.0999985C58.5999985,46.4000015,58.7000008,46.4000015,58.5999985,46.2999992 L58.5999985,46.2999992z"
      />
      <path
        opacity="0.55"
        fill="url(#SVGID_3_)"
        d="M78.1999969,49.0999985 c0-0.2000008,0-0.4000015-0.1999969-0.5h-0.0999985l0,0c-0.0999985,0-0.3000031-0.0999985-0.4000015,0 c-0.0999985-0.2000008-0.0999985-0.2999992-0.1999969-0.4000015c0-0.2000008,0-0.5-0.0999985-0.7000008 c-0.0999985-0.0999985,0.1999969-0.2999992,0.0999985-0.4000015C78.1000061,45.2999954,79.9000015,45.9999962,80,46.1999931 c0.3000031,0.9000015,0.5999985,1.2000008-0.3000031,3.7999992c-0.1999969,0.4000015-0.5999985,0.5-1.0999985,0.4000015 s-0.5999985-0.2000008-0.8000031-0.5c0-0.0999985,0-0.2000008,0-0.2999992 C78,49.5999985,78.1999969,49.4000015,78.1999969,49.0999985z"
      />
      <path
        opacity="0.31"
        fill="url(#SVGID_4_)"
        d="M78.5,49.7000008 C79,49.5,79.4000015,48.9000015,79.4000015,48.2999992c0-0.7000008-0.5999985-1.2000008-1.3000031-1 c-0.4000015,0.0999985-0.6999969,0.4000015-0.8000031,0.7999992c0,0-0.1999969-0.4000015-0.0999985-0.5999985 c0.0999985-0.0999985,0.0999985-0.4000015,0.3000031-0.7999992c0.9000015-1.2999992,2.4000015-0.7000008,2.4000015-0.5 C80.2000046,47.1000023,80.5,47.4000015,79.5999985,50c-0.3000031,0.5999985-1.1999969,0.5-1.6999969,0.2999992 C77.7000046,50.7000008,77.5,51.0999985,77.2000046,51.5c0.0999985-0.5999985,0.0999985-1.2000008,0.0999985-1.7999992 C77.5999985,49.9000015,78.0999985,49.9000015,78.5,49.7000008z"
      />
      <path
        opacity="0.61"
        fill="#FFF"
        d="M78.4000015,46.2999992L78.4000015,46.2999992 C78.4000015,46.2000008,78.4000015,46.2000008,78.4000015,46.2999992 c0.0999985-0.0999985,0.1999969-0.0999985,0.1999969-0.0999985C79,46.1000023,79.5,46.1000023,79.6999969,46.5l0,0 c0,0,0.0999985,0,0.0999985,0.0999985s0,0.0999985-0.0999985,0.0999985c-0.0999985,0.0999985-0.3000031,0-0.5-0.0999985 c-0.0999985,0-0.5999985-0.0999985-0.8000031-0.0999985C78.4000015,46.4000015,78.3000031,46.4000015,78.4000015,46.2999992 C78.3000031,46.2999992,78.4000015,46.2999992,78.4000015,46.2999992z"
      />
    </g>
  )
}

export default withSkinColor(HeadBase, { character: "WomanDwarf" })
