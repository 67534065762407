import React from 'react'
import Masonry from 'react-masonry-component'

const MinisGrid = ({ items=[]}) =>
  <div>
    <Masonry
        className={'my-gallery-class'}
    >
        {
          items.map((gitem, i) => {
            return (
              <div className="grid-item-wrapper" key={gitem.key}>
                <div className="grid-item" key={`${gitem.key}-${i}`}>
                    {gitem}
                </div>
              </div>
            )
          })
        }
    </Masonry>
  </div>

export default MinisGrid