import React from "react"

const LeatherHelm = ({
  colors = ["#8B5E3C", "#C0A172", "#996464", "#603913"]
}) => {
  return (
    <g id="LeatherHelm">
      <path
        opacity="0.44"
        fill="#21376C"
        d="M54.2579231,53.971199c0,0,0-0.0826988-0.0762978-0.1653976 v-0.1653976h-0.0762939c-0.3814888-1.1577759-1.8311501-5.0445938-5.1882515-6.036972l0,0l-1.3733597-1.57127l-1.5259552,1.57127 c-3.4333992,0.9096794-4.8830566,4.8791962-5.1882477,6.036972h-0.0762978v0.1653976 c0,0.082695-0.0762939,0.1653976-0.0762939,0.1653976h0.0762939v0.082695v1.653965l5.5697403,1.2404747h2.212635 l5.5697365-1.2404747v-1.653965L54.2579231,53.971199L54.2579231,53.971199z"
      />
      <path
        fill={colors[0]}
        d="M40.7532158,53.2269135h13.5047073c0,0-1.602253-6.533165-6.7905006-6.533165 S40.7532158,53.2269135,40.7532158,53.2269135z"
      />
      <rect
        x="40.83"
        y="52.896"
        fill={colors[1]}
        stroke={colors[2]}
        strokeWidth="0.135"
        strokeMiterlimit="10"
        width="13.352"
        height="2.481"
      />
      <rect
        x="40.83"
        y="53.31"
        fill={colors[3]}
        width="13.352"
        height="1.654"
      />
      <polygon
        fill={colors[1]}
        points="46.017765,46.8591461 46.4755478,52.978817 48.4592934,52.978817 48.9933777,46.8591461 47.5437202,45.2878799"
      />
      <polygon
        fill={colors[3]}
        points="47.3911247,45.4532776 46.2466545,46.6937523 46.7044449,52.7307243 47.314827,52.7307243"
      />
      <polygon
        fill={colors[0]}
        points="47.5437202,45.4532776 48.6881866,46.6937523 48.2304001,52.7307243 47.4674225,52.7307243"
      />
    </g>
  )
}

export default LeatherHelm
