import React from "react"

const WarhammerL = ({
  colors = [
    "#B9BABB",
    "#727373",
    "#E7E8E8",
    "#646464",
    "#7F8080",
    "#636464",
    "#CECFCF",
    "#A2A2A3",
    "#D6D7D6",
    "#A1A2A3"
  ]
}) => {
  return (
    <g id="WarhammerL">
      <path
        opacity="0.63"
        d="M58.3,103.8c-0.2,0-0.4-0.1-0.6-0.2c-0.8-0.5-0.8-1.4-0.6-1.8l1.9-3.4h0.2 l13.7-24.8v-0.2l0.5-1v-0.2l0.2-0.4h0.2l2.5-4.5l0.2-0.1l1.6-2.8v-0.2l0.3-0.6l-1.7-1l-0.2-0.5L76,62.2l-1-0.6l-0.5-0.1L73,57.2 l0.4-0.7l4.4-1l0.4,0.1l1,0.6l0.2,0.5l0.5-0.1l6,3.3l0.5-0.1l1.2,0.7l0.1,0.5l0.9,0.5l0.1,0.4h0.1l0.1,1.4l-0.1,0.3L87,66.8 L86.8,67l-1.2,0.6h-0.5L84.2,67l-0.4,0.1l-1.2-0.7l-0.1-0.3l-1.8-1l-0.3,0.6h-0.1l-1.5,2.8v0.1l-2.5,4.6v0.2l-0.2,0.4h-0.2l-0.5,1 h-0.1L61.4,99.6v0.1l-1.9,3.4C59.5,103.3,59,103.8,58.3,103.8z"
      />
      <rect
        x="46.9"
        y="82.6"
        transform="rotate(-61.041 68.866 83.405)"
        fill={colors[0]}
        width="43.9"
        height="1.6"
      />
      <rect
        x="79.5"
        y="63.1"
        transform="rotate(-61.041 79.568 64.011)"
        fill={colors[1]}
        width="0.1"
        height="1.9"
      />
      <rect
        x="77.3"
        y="67"
        transform="rotate(-61.041 77.35 67.962)"
        fill={colors[1]}
        width="0.1"
        height="1.9"
      />
      <rect
        x="77.4"
        y="67"
        transform="rotate(-61.041 77.435 67.908)"
        fill={colors[2]}
        width="0.1"
        height="1.9"
      />
      <rect
        x="78.9"
        y="63.5"
        transform="rotate(-61.041 79.404 64.474)"
        fill={colors[3]}
        width="1"
        height="1.9"
      />
      <rect
        x="72.3"
        y="70.1"
        transform="rotate(-61.041 75.684 71.067)"
        fill={colors[4]}
        width="6.8"
        height="1.9"
      />
      <rect
        x="73.8"
        y="73.4"
        transform="rotate(-61.041 73.96 74.181)"
        fill={colors[5]}
        width="0.4"
        height="1.6"
      />
      <rect
        x="60.1"
        y="84.7"
        transform="rotate(-61.041 67.7 85.522)"
        fill={colors[4]}
        width="15.2"
        height="1.6"
      />
      <rect
        x="57.4"
        y="99.8"
        transform="rotate(-61.041 59.275 100.737)"
        fill={colors[4]}
        width="3.8"
        height="1.9"
      />
      <rect
        x="60.1"
        y="98.3"
        transform="rotate(-61.041 60.22 99.095)"
        fill={colors[6]}
        width="0.2"
        height="1.6"
      />
      <polyline
        fill={colors[7]}
        points="74,72.1 73.8,72.5 75.6,73.5 75.8,73.1"
      />
      <path
        fill={colors[8]}
        d="M59.2,102.9l-1.6-0.9c0,0-0.3,0.9,0.4,1.2C58.6,103.6,59.2,102.9,59.2,102.9z"
      />
      <path
        fill={colors[9]}
        d="M75,61.3l1,0.6c0,0,0.1-0.2,0.3-0.5c0.7,0.2,0.4,0.9,0.4,0.9l8.6,4.8l2.9-5.3L79.6,57 c0,0-0.4,0.7-1,0.2c0.3-0.6,0.3-0.6,0.3-0.6l-1-0.6l-4.4,1L75,61.3z"
      />
      <rect
        x="81.7"
        y="62.8"
        transform="rotate(-61.041 84.963 63.456)"
        fill={colors[3]}
        width="6.6"
        height="1.4"
      />
      <rect
        x="74.2"
        y="58.9"
        transform="rotate(-61.041 77.243 59.124)"
        fill={colors[3]}
        width="6"
        height="0.5"
      />
      <rect
        x="75.4"
        y="59.6"
        transform="rotate(-61.041 78.379 59.761)"
        fill={colors[3]}
        width="6"
        height="0.4"
      />
      <polygon
        fill={colors[3]}
        points="85.3,67.1 86.6,66.4 88.4,63.2 88.2,61.8"
      />
      <polygon fill={colors[3]} points="73.5,57.1 76.4,58.7 75,61.3" />
      <path
        fill={colors[3]}
        d="M77.4,61l-0.6-0.6l-0.5,1c0,0,0.7,0.1,0.4,0.9C77.1,61.6,77.4,61,77.4,61z"
      />
    </g>
  )
}

export default WarhammerL
