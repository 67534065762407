import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const ShortswordRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("ShortswordRight") ? (
    <g id="ShortswordRight">
      <path
        id="S_x24_shortswordRight"
        d="M27.7,93.8c-2.2,0-4.2-0.8-5.8-2.3c-1.1-1-1.9-2.3-2.3-3.7v-0.1c-0.1-0.3-0.2-0.7-0.2-1 c-2-0.1-3.8-0.9-5.3-2.3c-1.7-1.6-2.7-3.9-2.7-6.2c0-2.5,1-4.7,2.7-6.3L4.2,41l-0.1-4l1.3-4.9l11.1-3.6l4.1,3.2l2.5,3.7l8.4,30.4 c1.7,0.2,3.4,1,4.7,2.2c1.1,1,1.9,2.3,2.3,3.7v0.1c1.1,3.6-0.2,7.4-3.2,9.5c0.2,0.4,0.3,0.8,0.5,1.2v0.1c1.4,4.4-0.9,9.1-5.3,10.6 C29.6,93.7,28.7,93.8,27.7,93.8z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(ShortswordRight, {
  character: "ManDwarf"
})
