import React from "react"

const AxeRight = ({
  colors = ["#8A5F3C", "#6D6E71", "#D1D2D3", "#808184"]
}) => {
  return (
    <g id="AxeRight">
      <path
        fill={colors[0]}
        d="M52.7450294,78.1301651l-0.6982689-6.2844391 c-0.1396523-0.0698242-0.2793121-0.2094803-0.4189644-0.2793045l-2.6534309,0.4887848 c-0.0698242,0.1396561-0.1396523,0.2793121-0.2094841,0.3491364l1.8853302,6.144783 c0,0,4.1896248,17.3171234,4.9577217,21.4369202c0.6284447,3.3516998,0.977581,7.8206329,1.0474091,9.3568268 c0.2094803,0.1396561,0.4189606,0.2094879,0.6284447,0.2793121l2.0948105-0.9775848l-6.074955-26.7437744L52.7450294,78.1301651z"
      />
      <path
        fill={colors[1]}
        d="M54.979496,70.937973c-0.4189644,1.1172333-2.0948143,2.2344742-4.2594528,2.6534348 c-2.0948143,0.4189606-4.0499725,0.0698242-4.8878975-0.8379288c-1.4663658,1.3965454-2.1646385,3.2120438-1.8155022,5.0275497 s1.7456779,3.2120438,3.6310043,3.9801483c0.4189644-1.1172333,2.0948143-2.2344666,4.2594528-2.6534271 c2.0948143-0.4189682,4.0499725-0.0698318,4.8878975,0.8379211c1.4663658-1.3965378,2.1646385-3.2120438,1.8155022-5.0275497 C58.2613716,73.1026154,56.86483,71.7060776,54.979496,70.937973z"
      />
      <g fill={colors[2]}>
        <path d="M45.0640488,77.8508606c-0.1396523-0.8379288,0-1.6758499,0.3491364-2.5137787 c0-0.0698242-0.3491364-0.3491287-0.5586205-0.5586166c0.5586205,0.2094879,0.6982727,0.2094879,0.7680969,0.1396561 c0.3491364-0.5586166,0.6982727-1.0474014,1.2568893-1.5361938c-0.4189606-0.1396561-0.8379211-0.3491364-1.0474052-0.6284485 c-1.4663658,1.3965454-2.1646385,3.2120514-1.8155022,5.0275574c0.3491364,1.8154984,1.7456779,3.2120438,3.6310043,3.9801407 c0.069828-0.2793121,0.2793121-0.4887924,0.4887886-0.6982727 C46.5304184,80.4344635,45.4131851,79.3172302,45.0640488,77.8508606z" />
        <path d="M54.979496,70.937973c-0.1396523,0.3491364-0.4189644,0.6982727-0.6982727,0.9775848 c1.6758499,0.5586166,3.0025673,1.7456741,3.2818756,3.3516998c0.069828,0.4887924,0.069828,0.9775772,0,1.3965378 c0,0.0698318,0.1396523,0.0698318,0.1396523,0.1396561c-0.0698242,0-0.0698242,0-0.1396523,0 c0,0.0698242-0.0698242,0.3491364-0.0698242,0.4189606c0,0.0698318,0.1396523,0.2094803,0.4887886,0.5586166 c-0.2094841-0.1396561-0.6284447-0.2094803-0.6982727-0.1396561c-0.2793083,0.6284485-0.6982689,1.2568893-1.2568893,1.815506 c0.2793083,0.1396561,0.5586205,0.2793121,0.6982689,0.4887924c1.4663696-1.3965454,2.1646423-3.2120438,1.815506-5.0275497 S56.86483,71.6362457,54.979496,70.937973z" />
      </g>
      <polygon
        fill={colors[3]}
        points="53.16399,79.0379181 50.4407349,79.5267105 48.834713,73.8008881 52.6053734,73.0327911"
      />
    </g>
  )
}

export default AxeRight
