import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Belt from "./Belt"
import PotionPouch from "./PotionPouch"
import Dagger from "./Dagger"
import Straps from "./Straps"
import ArmWrap from "./ArmWrap"

const CATEGORY = "gear"
const CHARACTER = "ManGnome"
export const IDS = {
  BELT: "Belt",
  POTION_POUCH: "PotionPouch",
  DAGGER: "Dagger",
  STRAPS: "Straps",
  ARM_WRAP: "ArmWrap"
}

export const components = {
  [IDS.BELT]: Belt,
  [IDS.POTION_POUCH]: PotionPouch,
  [IDS.DAGGER]: Dagger,
  [IDS.STRAPS]: Straps,
  [IDS.ARM_WRAP]: ArmWrap
}

export const Group = ({ props }) => (
  <g id="gear">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.BELT,
    name: "Belt",
    defaultColors: ["#1E1E1E", "#9C7000", "#FFFFFF"],
    colorable: true,
    component: components[IDS.BELT],
    inUI: true,
    enabled: false,
    subGroupId: 4
  },
  {
    id: IDS.POTION_POUCH,
    name: "Potion Pouch",
    defaultColors: [
      "#8B5E3C",
      "#353891",
      "#B400FF",
      "#00DBFF",
      "#99FF00",
      "#686868",
      "#FFFFFF",
      "#BA9D72"
    ],
    colorable: true,
    component: components[IDS.POTION_POUCH],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.DAGGER,
    name: "Dagger",
    defaultColors: ["#8B5E3C", "#353891", "#878791", "#FFFFFF", "#4C2C26"],
    colorable: true,
    component: components[IDS.DAGGER],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.STRAPS,
    name: "Straps",
    defaultColors: ["#8B5E3C"],
    colorable: true,
    component: components[IDS.STRAPS],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.ARM_WRAP,
    name: "Arm Wrap",
    defaultColors: ["#ADADAD"],
    colorable: true,
    component: components[IDS.ARM_WRAP],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
