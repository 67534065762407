import React from "react"

const LeftShoulderGuard = ({ colors = ["#898888"] }) => {
  return (
    <g id="LeftShoulderGuard">
      <path
        fill={colors[0]}
        d="M89,24.3c0.3,0.1,0.7,0.1,1.1,0.1c-0.8,0.5-1.4,1.2-1.8,2.1v0.1l0,0 c-1.3,2,0.9,7.3,1.5,8.5c1.1,3.1,2.6,6.1,4.2,9.1c-1.3-0.4-4.1-1.4-4.2-1.5c-0.6-0.5-2.7-2.1-3-2.7c-2-4.3-4.4-9.5-4.8-13.8 c1.4-0.6,4.8-1,5.9-2.2C88.2,24.1,88.6,24.2,89,24.3z"
      />
      <path
        opacity="0.28"
        d="M82.8,26.3c0.4,4.2,2.7,9.3,4.7,13.5c0.3,0.6,2.4,2.2,3,2.7 c0.1,0.1,1.4,0.6,2.6,1c0.2,0.4,0.4,0.8,0.6,1.1c-1.3-0.4-4.1-1.4-4.2-1.5c-0.6-0.5-2.7-2.1-3-2.7c-2-4.3-4.4-9.5-4.8-13.8 C82,26.5,82.4,26.4,82.8,26.3z"
      />
    </g>
  )
}

export default LeftShoulderGuard
