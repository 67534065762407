import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BlueFlameLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BlueFlameLeft") ? (
    <g id="BlueFlameLeft">
      <path
        id="S_x24_blueFlameLeft"
        d="M57,85.3c-2.4-2.8-5.2-6.6-5.9-11.5c0,0,1-5,0.5-3.1c0.8-3.2,4.8-11.5,5.3-12.9 c0,0,0.1-6.6,0.2-10.3c0,0,1.2-3.8,1.1-3.7c0.2-0.4,1-1.9,2.2-4.3c0.2-0.3,0.3-1.4,0.3-1.7c-0.1-1.6-0.2-3.8,0.6-6.2l6.2-5.6l7,4.7 c0.3,0.7,0.9,2,1.6,3.9c0.3,0.7,0.5,1.4,0.7,1.8c1.1,1.8,1.9,6.2,1.9,6.4c0.3,0.9,0.5,1.8,0.6,2.6h0.1c2.2,2.9,4.2,6.2,6,10.1 l0.7,2.9c0.1,4.4-2.5,23.4-3.6,25.8l-12.4,7.1L57,85.3z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BlueFlameLeft, {
  character: "ManDwarf"
})
