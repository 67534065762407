import React from "react"

const ScrollLeft = ({
  colors = ["#4D391B", "#805F2D", "#B0823D", "#CBA02C"]
}) => {
  return (
    <g id="ScrollLeft">
      <polygon
        fill={colors[0]}
        points="65.6,99.8 56.7,76.5 54.8,76.6 55.1,93.3 56.7,93.6 56.6,80.6 64,98.7"
      />
      <path
        fill={colors[1]}
        d="M64.8,102.5c-0.5,0-11.3-6.8-11.3-6.8s-1.2-0.5-1.1-1.8c0.1-1.3,1.1-1.8,2.1-1.7c1,0.1,11.5,7.8,11.5,7.8 s0.4,0.6,0.2,1.5C66,102.3,65.2,102.5,64.8,102.5z"
      />
      <path
        fill={colors[2]}
        d="M55.6,92.5c0,0,0.8,1.1,0.3,2.5c-0.4,1.5-2.1,1.4-2.1,1.4l-1.7-1.1l0.3-2.1l1.6-1.3L55.6,92.5z"
      />
      <path
        fill={colors[3]}
        d="M54,91.8c0,0,1.2,0.9,0.7,2.4s-2.4,1.1-2.4,1.1l0.3-2l0.7,1.6l1.1-0.8l-0.3-1.4l-1.3,0.5L54,91.8z"
      />
      <path
        fill={colors[0]}
        d="M65,99.3c0,0,0.9,0.9,0.3,1.8s-1.4,0.9-1.4,0.9l0.4,0.3c0,0,1.3,0,1.5-1s-0.3-1.9-0.3-1.9L65,99.3z"
      />
      <path
        fill={colors[2]}
        d="M65.2,99.3c0,0,0.8,1,0.2,2s-1.4,0.9-1.4,0.9l0.7,0.4c0,0,1,0,1.5-0.9c0.6-0.9-0.2-1.8-0.2-1.8L65.2,99.3z"
      />
    </g>
  )
}

export default ScrollLeft
