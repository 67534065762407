import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaceL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaceL") ? (
    <g id="MaceL">
      <path
        id="S_x24_maceL"
        d="M90.5,63.2000008l-3,5.1000023c-3,5.1999969-9.8000031,7-15,4L70.3000031,71 c-5.1999969-3-7-9.7999992-4-15l3-5.0999985c3-5.2000008,9.8000031-7,15-4L86.5,48.2000008 C91.6999969,51.2999992,93.5,58,90.5,63.2000008z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaceL, { character: "ManHalfling" })
