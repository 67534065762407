import React from "react"

import { Group as Shadow } from "./shadow"
import Background from "./background"
import { Group as Body } from "./body"
import { Group as Arms } from "./arms"
import Bottom from "./bottom"
import Feet from "./feet"
import Top from "./top"
import Robe from "./robe"
import Gear from "./gear"
import Shoulders from "./shoulders"
import { Group as Head } from "./head"
import Face from "./face"
import Hair from "./hair"
import Cloaks from "./cloaks"
import Headwear from "./headWear"
import { Group as Thumbs } from "./thumbs"
import Weaponright from "./weaponRight"
import Weaponleft from "./weaponLeft"
import { Group as Hands } from "./hands"
import Foreground from "./foreground"
import Magic from "./magic"
import Base from "./base"

class ManHalfling extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <svg
        version="1.1"
        ref={this.props.svgRef}
        x="0px"
        y="0px"
        viewBox="0 0 95 152.8000031"
      >
        <Shadow />
        <Background />
        <Body />
        <Arms />
        <Bottom />
        <Feet />
        <Top />
        <Robe />
        <Gear />
        <Shoulders />
        <Head />
        <Face />
        <Hair />
        <Cloaks />
        <Headwear />
        <Thumbs />
        <Weaponright />
        <Weaponleft />
        <Hands />
        <Foreground />
        <Magic />
        <Base />
      </svg>
    )
  }
}

export default ManHalfling
