import React from "react"

const Pouch = ({ colors = ["#4D391B", "#7D5B3C", "#362812", "#A76F3A"] }) => {
  return (
    <g id="Pouch">
      <polygon
        fill={colors[0]}
        points="82.7000122,55.0999985 78.1000061,57.2000008 77.6000061,57.0999985 76.3000107,51.5999985 81.5000076,50.3999977 82.0000076,51"
      />
      <path
        fill={colors[1]}
        d="M76.7000122,52.2999992l0.8000031-0.0999985c0,0,1.3000031,4.0999985,1,4.7000008 C78.3000183,57.5,77.7000122,57.1000023,77.7000122,57.1000023L76.7000122,52.2999992z"
      />
      <path
        fill={colors[1]}
        d="M77.7000122,52.2000008l4.0999985-1.0999985l0.9000015,4l-4.1999969,1.7000008 C78.5000076,56.7999992,78.7000122,55.7999992,77.7000122,52.2000008z"
      />
      <path
        fill={colors[0]}
        d="M76.3000107,51.7999992c0,0,1.6999969,0,2.4000015,1.5999985c0.6999969,1.5,1.9000015,1.5,1.9000015,1.5 s1.1999969-1.5999985,1-2.4000015c-0.1999969-0.9000015-0.1999969-1.7999992-0.1999969-1.7999992L76.3000107,51.7999992z"
      />
      <path
        fill={colors[1]}
        d="M76.5000076,51.5999985c0,0,1.6999969,0,2.4000015,1.5999985c0.6999969,1.5,2,1.2999992,2,1.2999992 s0.8000031-1.5,0.5999985-2.4000015c-0.1999969-0.9000015,0.0999985-1.7000008,0.0999985-1.7000008L76.5000076,51.5999985z"
      />
      <ellipse
        transform="rotate(-11.958 80.506 53.89)"
        fill={colors[2]}
        cx="80.506"
        cy="53.889"
        rx="0.2"
        ry="0.3"
      />
      <ellipse
        transform="rotate(-11.958 80.594 53.793)"
        fill={colors[3]}
        cx="80.594"
        cy="53.793"
        rx="0.2"
        ry="0.3"
      />
    </g>
  )
}

export default Pouch
