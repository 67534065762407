import React from "react"

const BlueFlameLeft = ({
  colors = ["#80D0DC", "#30BFCA", "#5BC6D1", "#A8DDE6", "#C0E6F0", "#E5F5F8"]
}) => {
  return (
    <g id="BlueFlameLeft">
      <path
        opacity="0.77"
        fill={colors[0]}
        d="M84.4198227,81.9262238 c0.9000015,2.3999939,1.8000031,4.6999969,2.9000015,6.8999939c-0.1000061-0.3999939-0.2000046-0.7999878-0.3000031-1.0999908 c-0.5-2.4000092-1.7000046-5-0.9000015-7.3000031c-0.0999985-0.1999969-0.1999969-0.3000031-0.1999969-0.5 c0.0999985-4.1000061,0.7999954-6.6000061,2.0999985-10.5c1.0999985-3.1999969,1.7999954-6.7999992,4.0999985-9.2999992 c0.0999985-0.0999985,0.0999985-0.0999985,0.1999969-0.0999985c0.3000031-2.1999969,0.4000015-4.3999977,0.4000015-6.5999985 c0-0.0999985,0.0999985-0.0999985,0.1999969-0.0999985c-0.0999985-0.6999969,0.3000031-1.2999992,0.5-2.0999985 c0.1000061-0.2000008,3.3000031,3.2000008,3.3000031,3.7000008c0.2000046,0.5999985,0.3000031,1.2000008,0.4000015,1.7999992 c0,0.0999985,0,0.0999985,0,0.0999985v0.0999985c0.0999985,0.8000031,0.1999969,1.6000023,0.1999969,2.4000015 c0,0.0999985,0,0.0999985-0.0999985,0.0999985c0.5999985,3.6000023-0.2000046,7.4000053,2.1999969,10.9000053 c2,2.8000031,2.3000031,5.6999969,2.5,8.8999939c0.7000046,0.3999939,1.3000031,0.8000031,1.3000031,1.1999969 c0,2.8000031-0.8000031,5.4000092-1.9000015,8c2-2.3000031,3.7999954-4.8999939,4.1999969-7.8000031 c-0.1999969-0.0999908-0.4000015-0.3000031-0.4000015-0.5c-1.0999985-4.8999939-4.4000015-8.8999939-5.5-13.7999954 c-0.1000061-0.2999954,0.5999985,0,1,0.2000046c-0.8000031-3.6000061-1.1000061-7.4000053-1.2000046-11.1000061 c-0.2000046-0.2000008-2.8000031-5.7000008-2.9000015-5.7999992c-1.4000015-2.2999992-0.0999985-5.5-0.9000015-8.0999985 c-0.5,1.1999969-2.4000015,5.8999977-2.5,6.0999985c-0.8000031,0.9000015-1,4.1000023-1.4000015,5.2000008 c-0.5999985,1.3999977-0.1999969,3.2999992,0,4.7999992c0.0999985,0.7999992-1.3000031,0.0999985-1.5,0 c-0.0999985-0.0999985-0.0999985-0.0999985-0.1999969-0.0999985c-2.0999985,2.8000031-3.7999954,5.8000031-5.1999969,8.8999977 C84.7198029,71.6262283,84.6198044,76.8262329,84.4198227,81.9262238z"
      />
      <g opacity="0.98">
        <path
          fill={colors[1]}
          d="M89.6198044,90.0262299c-2.1999969-6.5999908-2.3999939-11.8999939-0.6999969-16.8999939 c0.6999969-2.0000076,1.6999969-3.8000031,2.6999969-5.8000031c0.8000031-1.6000061,1.7000046-3.3000031,2.4000015-5.1000023 c0.1999969-0.4000015,0.4000015-0.9000015,0.5999985-1.5l0.5999985,0.2999992 c-0.0999985,0.8999977-0.0999985,1.6999969,0,2.5999985c0.3999939,2.4000015,1.5999985,4.9000015,3.6999969,7.4000015 c0,0.1000061-0.0999985,0.1000061-0.0999985,0.1999969l-0.5,1l1,0.4000092c0.3000031,1.3000031,0.5999985,2.5999908,1,3.8999939 c0.2000046,0.8000031,0.5,1.6999969,0.7000046,2.5c0.1999969,0.6000061,0.3000031,1.3000031,0.4000015,2 c-0.7000046,2.1999969-1.4000015,4.1999969-2.3000031,6.1000061c-0.4000015,0.6999969-0.6999969,1.5-1.0999985,2.1999969 c-1.5999985,0.8999939-3.2999954,1.5999908-5.1999969,2.1999969 C91.7198029,91.2262268,90.6198044,90.7262268,89.6198044,90.0262299z M95.1198044,59.3262291 c0.4000015-1.5,0.5999985-3.2000008,0.0999985-4.7999992c0.5999985,0.5,0.9000015,1.2999992,1,2.0999985 C96.2198029,57.6262283,95.8198013,58.6262283,95.1198044,59.3262291L95.1198044,59.3262291z"
        />
        <path
          fill={colors[2]}
          d="M90.8198166,90.7262421c-2-6.1000061-2.1999969-11.1000061-0.5999985-15.8000031 c0.5999985-2,1.5999985-3.8000031,2.5999985-5.7000046c0.5999985-1.0999985,1.1999969-2.2000046,1.6999969-3.4000015 c0.4000015,2.1999969,1.5,4.4000015,3.3000031,6.6999969l-0.8000031,1.6000061l1.5,0.6999969 c0.3000031,1.1999969,0.5999985,2.3000031,0.9000015,3.3999939c0.2999954,0.9000092,0.5,1.6999969,0.6999969,2.5 c0.0999985,0.5,0.2000046,1,0.3000031,1.6000061c-0.7000046,2-1.4000015,3.8999939-2.2000046,5.6999969 c-0.3000031,0.5999908-0.5999985,1.3000031-0.9000015,1.8999939c-1.4000015,0.7000122-2.8000031,1.3000031-4.4000015,1.8000031 C92.1198044,91.5262299,91.319809,91.126236,90.8198166,90.7262421z"
        />
        <path
          fill={colors[0]}
          d="M91.5197983,91.1262207c-1.7000046-5.6999969-1.9000015-10.3999939-0.4000015-14.8000031 c0.6999969-1.8000031,1.5999985-3.5999908,2.5999985-5.5c0.1999969-0.2999954,0.3000031-0.6999969,0.5-1 c0.5,1.4000092,1.3000031,2.7000046,2.3000031,4l-1,2l2.0999985,0.8000031c0.3000031,1,0.5,2,0.8000031,2.9000092 c0.2999954,0.8000031,0.5,1.5999908,0.6999969,2.3999939c0.1000061,0.4000092,0.2000046,0.8000031,0.3000031,1.3000031 c-0.6999969,2-1.2999954,3.7000122-2.0999985,5.4000092c-0.1999969,0.5999908-0.5,1.0999908-0.6999969,1.5999908 c-1.0999985,0.6000061-2.2999954,1.1000061-3.5999985,1.5C92.5198059,91.626236,92.0198059,91.4262238,91.5197983,91.1262207z"
        />
        <path
          fill={colors[3]}
          d="M92.5198212,91.7262268c-1.6000061-5.2999878-1.7000046-9.6999969-0.3000031-13.6999969 c0.5-1.5,1.1999969-3,2-4.5c0.2999954,0.6000061,0.6999969,1.1000061,1.0999985,1.6999969l-1.3000031,2.4000092 l2.6999969,1.1999969c0.1999969,0.8000031,0.5,1.5999908,0.6999969,2.3999939 c0.2999954,0.8000031,0.5,1.6000061,0.6999969,2.4000092c0.0999985,0.3000031,0.0999985,0.5999908,0.1999969,0.8999939 c-0.5999985,1.8000031-1.2000046,3.4000092-1.9000015,5c-0.1999969,0.3999939-0.4000015,0.8000031-0.5999985,1.1999969 c-0.7999954,0.4000092-1.6999969,0.8000031-2.6999969,1.1000061 C92.9198074,91.7262268,92.819809,91.7262268,92.5198212,91.7262268z"
        />
        <path
          fill={colors[4]}
          d="M93.3198166,79.2262268l2.5999985,1.1000061c0.1999969,0.6999969,0.4000015,1.3999939,0.5999985,2 c0.2999954,0.8000031,0.5,1.5999908,0.6999969,2.3999939c0,0.1999969,0.0999985,0.4000092,0.0999985,0.6000061 c-0.5,1.5999908-1.1000061,3.0999908-1.8000031,4.5999908c-0.0999985,0.2000122-0.3000031,0.5-0.4000015,0.8000031 c-0.5,0.3000031-1,0.5-1.5999985,0.6999969C92.1198044,86.7262268,92.1198044,82.8262329,93.3198166,79.2262268z"
        />
        <path
          fill={colors[5]}
          d="M93.2198029,91.9262238c-0.8999939-3.5-0.8999939-6.5-0.2000046-9.1999969l1,0.3999939 c0.0999985,0.5,0.3000031,1,0.4000015,1.5c0.1999969,0.7000122,0.4000015,1.5,0.5999985,2.3000031 c0.0999985,0.1000061,0.0999985,0.2000122,0.0999985,0.3000031c-0.5,1.5-1.0999985,2.8000031-1.6999969,4.1999969 C93.3198013,91.6262207,93.3198013,91.7262268,93.2198029,91.9262238L93.2198029,91.9262238z"
        />
      </g>
    </g>
  )
}

export default BlueFlameLeft
