import React from "react"

const BlueFlameLeft = ({
  colors = ["#80D0DC", "#30BFCA", "#5BC6D1", "#A8DDE6", "#C0E6F0", "#E5F5F8"]
}) => {
  return (
    <g id="BlueFlameLeft">
      <path
        opacity="0.77"
        fill={colors[0]}
        d="M78.6543579,79.0077286 c0.5856705,1.5617599,1.1713333,3.0584564,1.8871384,4.4900742c-0.0650787-0.2602921-0.1301498-0.5205841-0.1952209-0.7158051 c-0.3253708-1.5617752-1.1062546-3.253685-0.5856628-4.7503738c-0.0650711-0.1301498-0.1301498-0.1952286-0.1301498-0.3253708 c0.0650787-2.6680222,0.5205917-4.2948685,1.3665466-6.8327332c0.7158127-2.0823593,1.1713257-4.4250107,2.6680222-6.0518494 c0.0650711-0.0650787,0.0650711-0.0650787,0.1301422-0.0650787c0.1952286-1.4316139,0.2602997-2.8632355,0.2602997-4.294857 c0-0.0650749,0.0650711-0.0650749,0.1301422-0.0650749c-0.0650711-0.455513,0.1952209-0.8459549,0.3253708-1.3665428 c0.0650787-0.1301498,2.1474304,2.0823555,2.1474304,2.4077263c0.1301498,0.3904381,0.1952209,0.7808838,0.2602997,1.1713219 c0,0.0650749,0,0.0650749,0,0.0650749v0.0650711c0.0650711,0.5205917,0.1301422,1.0411797,0.1301422,1.5617676 c0,0.0650787,0,0.0650787-0.0650711,0.0650787c0.3904419,2.3426514-0.1301498,4.8154526,1.4316177,7.0930252 c1.3014755,1.8220673,1.4966965,3.709198,1.6268387,5.7915573c0.4555206,0.2602921,0.8459625,0.5205917,0.8459625,0.7808838 c0,1.8220596-0.5205917,3.5139771-1.2364044,5.2058868c1.3014755-1.4966965,2.4728012-3.1886063,2.7330933-5.0757446 c-0.1301422-0.0650711-0.2602921-0.1952209-0.2602921-0.3253708c-0.7158127-3.1886063-2.8632431-5.7915497-3.5790558-8.9801636 c-0.0650711-0.1952133,0.3904419,0,0.6507416,0.1301498c-0.5205917-2.3426514-0.7158127-4.8154526-0.7808914-7.223175 c-0.1301498-0.1301498-1.8220596-3.7092018-1.8871384-3.7742729c-0.911026-1.4966927-0.0650711-3.579052-0.5856628-5.2709656 c-0.3253632,0.78088-1.5617676,3.839344-1.6268387,3.9694901c-0.5205917,0.5856667-0.6507339,2.6680222-0.9110336,3.383831 c-0.3904419,0.9110298-0.1301422,2.1474304,0,3.1235352c0.0650711,0.5205917-0.8459549,0.0650749-0.9761047,0 c-0.0650711-0.0650711-0.0650711-0.0650711-0.1301422-0.0650711c-1.3665466,1.8220673-2.4728012,3.7742767-3.3838272,5.7915573 C78.8495712,72.3051376,78.7844925,75.6889725,78.6543579,79.0077286z"
      />
      <g opacity="0.98">
        <path
          fill={colors[1]}
          d="M82.0381775,84.2786942c-1.4316177-4.2948532-1.5617599-7.7437592-0.455513-10.9974442 c0.455513-1.3014755,1.1062546-2.4728012,1.7569885-3.7742767c0.5205917-1.0411758,1.1062546-2.1474304,1.5617676-3.3187561 c0.1301498-0.2602921,0.2602997-0.5856628,0.3904419-0.9761047l0.3904419,0.1952209 c-0.0650711,0.5856628-0.0650711,1.1062546,0,1.6919174c0.2602921,1.5617676,1.0411758,3.1886063,2.4077225,4.8154526 c0,0.0650711-0.0650711,0.0650711-0.0650711,0.1301422l-0.3253708,0.6507339l0.6507339,0.2602997 c0.1952286,0.8459625,0.3904419,1.6919098,0.6507416,2.5378723c0.1301498,0.5205917,0.3253632,1.1062469,0.4555206,1.6268387 c0.1301422,0.3904495,0.1952209,0.8459625,0.2602921,1.3014755c-0.4555206,1.4316177-0.9110336,2.7330933-1.4966965,3.9694977 c-0.2602921,0.455513-0.455513,0.9761047-0.7158127,1.4316177c-1.0411758,0.5856628-2.1474228,1.0411758-3.3838272,1.4316177 C83.4047241,85.0595779,82.6889191,84.7342072,82.0381775,84.2786942z M85.6172333,64.3010788 c0.2602921-0.9761009,0.3904419-2.0823555,0.0650711-3.1235313c0.3904419,0.325367,0.5856628,0.8459549,0.6507339,1.3665428 C86.3330383,63.194828,86.0727463,63.8455658,85.6172333,64.3010788L85.6172333,64.3010788z"
        />
        <path
          fill={colors[2]}
          d="M82.8190689,84.7342224c-1.3014679-3.9694977-1.4316177-7.2231827-0.3904419-10.2816467 c0.3904419-1.3014679,1.0411835-2.4728012,1.6919174-3.709198c0.3904419-0.7158127,0.7808838-1.4316254,1.1062469-2.2125092 c0.2602997,1.4316254,0.9761047,2.8632431,2.147438,4.3599396l-0.5205917,1.0411758l0.9761047,0.4555206 c0.1952209,0.7808762,0.3904419,1.4966965,0.5856628,2.2124939c0.1952209,0.5856705,0.3253708,1.1062546,0.455513,1.6268463 c0.0650711,0.3253632,0.1301498,0.6507339,0.1952209,1.0411835c-0.455513,1.3014679-0.9110336,2.5378647-1.4316177,3.7091904 c-0.1952286,0.3904419-0.3904419,0.8459625-0.5856705,1.2363968c-0.911026,0.4555283-1.8220596,0.8459625-2.8632355,1.1713333 C83.6650238,85.2547989,83.1444321,84.9945068,82.8190689,84.7342224z"
        />
        <path
          fill={colors[0]}
          d="M83.2745743,84.9944992c-1.1062546-3.709198-1.2363968-6.7676544-0.2602921-9.6309052 c0.455513-1.1713257,1.0411758-2.3426437,1.6919098-3.5790482c0.1301498-0.1952209,0.1952209-0.455513,0.3253708-0.6507339 c0.3253708,0.9110336,0.8459549,1.7569885,1.4966965,2.6029434l-0.6507416,1.3014755l1.3665466,0.5205917 c0.1952286,0.6507339,0.3253708,1.3014679,0.5205917,1.8871384c0.1952209,0.5205917,0.3253708,1.0411758,0.455513,1.5617676 c0.0650787,0.2602997,0.1301498,0.5205841,0.1952286,0.8459549c-0.455513,1.3014755-0.8459549,2.4077377-1.3665466,3.5139847 c-0.1301498,0.3904343-0.3253708,0.7158051-0.455513,1.0411758c-0.7158127,0.3904419-1.4966965,0.7158127-2.3426514,0.9761047 C83.9253159,85.3198776,83.5999451,85.1897202,83.2745743,84.9944992z"
        />
        <path
          fill={colors[3]}
          d="M83.9253235,85.3849487c-1.0411758-3.4488983-1.1062546-6.312149-0.1952209-8.9150925 c0.3253708-0.9761047,0.7808838-1.9522095,1.3014755-2.9283142c0.1952133,0.3904419,0.455513,0.7158127,0.7158051,1.1062469 l-0.8459549,1.5617752l1.7569885,0.7808838c0.1301422,0.5205917,0.3253632,1.0411758,0.455513,1.5617599 c0.1952133,0.5205917,0.3253632,1.0411835,0.455513,1.5617752c0.0650711,0.1952286,0.0650711,0.3904419,0.1301422,0.5856628 c-0.3904419,1.1713257-0.7808838,2.2125092-1.2363968,3.253685c-0.1301498,0.2602844-0.2602997,0.5205841-0.3904419,0.7808762 c-0.5205917,0.2602997-1.1062546,0.5205917-1.7569885,0.7158127 C84.1856155,85.3849487,84.1205368,85.3849487,83.9253235,85.3849487z"
        />
        <path
          fill={colors[4]}
          d="M84.4459152,77.2507401l1.6919098,0.7158127c0.1301498,0.455513,0.2602997,0.911026,0.3904419,1.3014755 c0.1952209,0.5205917,0.3253708,1.0411682,0.455513,1.5617599c0,0.1301498,0.0650711,0.2602997,0.0650711,0.3904495 c-0.3253632,1.0411682-0.7158127,2.0172729-1.1713257,2.9933777c-0.0650711,0.1301575-0.1952209,0.3253708-0.2602921,0.5205917 c-0.3253708,0.1952286-0.6507416,0.3253708-1.0411835,0.455513 C83.6650238,82.1312637,83.6650238,79.5933914,84.4459152,77.2507401z"
        />
        <path
          fill={colors[5]}
          d="M84.3808289,85.5150909c-0.5856552-2.2775803-0.5856552-4.2297897-0.1301498-5.9867783 l0.6507339,0.2602921c0.0650787,0.3253708,0.1952286,0.6507416,0.2602997,0.9761047 c0.1301422,0.4555283,0.2602921,0.9761047,0.3904419,1.4966965c0.0650711,0.0650787,0.0650711,0.1301575,0.0650711,0.1952286 c-0.3253708,0.9761047-0.7158127,1.8220596-1.1062469,2.7330856C84.4459,85.31987,84.4459,85.3849487,84.3808289,85.5150909 L84.3808289,85.5150909z"
        />
      </g>
    </g>
  )
}

export default BlueFlameLeft
