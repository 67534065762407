import React from "react"

const LuteR = ({
  colors = ["#94712B", "#D9A53F", "#695020", "#B68B34", "#8E6D2B"]
}) => {
  return (
    <g id="LuteR">
      <path
        fill={colors[0]}
        d="M36.9,44.4l0.9,0.1l0.1,0.4l0.5,0.1l0.4-0.7l0-0.6l-0.7,0.1l-0.2,0.3l-1,0.1 C36.9,44.2,36.8,44.4,36.9,44.4z"
      />
      <path
        fill={colors[0]}
        d="M36.8,43.6l0.9,0.1l0.1,0.2l0.4-0.2l0.4-0.4l0.1-0.5L38.1,43l-0.4,0.3l-1,0.1 C36.8,43.4,36.7,43.6,36.8,43.6z"
      />
      <path
        fill={colors[0]}
        d="M36.8,42.8l0.8,0l0.1,0.2l0.5-0.1l0.4-0.4l0.1-0.5l-0.5,0.2l-0.4,0.3l-1,0.1 C36.7,42.6,36.6,42.8,36.8,42.8z"
      />
      <path
        fill={colors[0]}
        d="M37.2,45.6l0.9,0.1l0.1,0.2l0.5,0l0.4-0.6l0.1-0.5L38.5,45l-0.4,0.3l-1,0.1C37.1,45.5,37,45.6,37.2,45.6z"
      />
      <path
        fill={colors[1]}
        d="M44.7,59.1c5.3-0.2,15.8,14.4,16.7,17.5c0.9,3.2,1.2,6.8-3.2,9.1s-10.4-2-12.1-4.5 C44.3,78.6,41.1,59.7,44.7,59.1z"
      />
      <path
        fill={colors[2]}
        d="M34.9,44.3l0.1-1l8.6,17c0,0-3.1,28,14.6,25.4c0,0-11.3,6.6-16.2-8.7c-1.2-7.2,0.4-15.8,0.4-15.8"
      />
      <path
        fill={colors[0]}
        d="M35.3,47.2l-0.8,0.3l0,0.2l-0.4,0.2l-0.5-0.3l-0.2-0.4l0.7,0l0.3,0l1-0.1C35.3,47,35.5,47.2,35.3,47.2z"
      />
      <path
        fill={colors[0]}
        d="M35,45l-0.8,0.3l0,0.4l-0.4,0.2l-0.5-0.5l-0.2-0.6l0.7,0l0.3,0.2l1-0.1C35.1,44.8,35.2,44.9,35,45z"
      />
      <path
        fill={colors[0]}
        d="M34.9,44.2l-0.8,0.3l0,0.3l-0.4-0.1l-0.5-0.3L33,43.9l0.6,0l0.5,0.2l1-0.1C34.9,44,35.1,44.1,34.9,44.2z"
      />
      <path
        fill={colors[0]}
        d="M34.8,43.4L34,43.6l0,0.2l-0.5,0.1L33,43.6l-0.2-0.4l0.6,0l0.5,0.2l1-0.1C34.8,43.2,35,43.4,34.8,43.4z"
      />
      <path
        fill={colors[0]}
        d="M35.1,46.3l-0.8,0.3l0,0.3l-0.5,0.1l-0.5-0.5L33.1,46l0.6,0l0.5,0.2l1-0.1C35.1,46.1,35.3,46.2,35.1,46.3z"
      />
      <polygon
        fill={colors[3]}
        points="46.6,59.5 37.1,42.4 34.7,42.9 43.6,60.3"
      />
      <polygon
        fill={colors[4]}
        points="59.9,78.3 54.5,81 54.1,79.8 59.3,77.3"
      />
      <polygon
        fill={colors[0]}
        points="35,43.5 34.9,44.3 35.5,47.7 36.4,47.5"
      />
      <polygon
        fill={colors[2]}
        points="35.2,47.3 35.5,47.7 35,43.3 34.7,42.9"
      />
      <ellipse
        transform="rotate(-31.851 50.81 68.86)"
        fill={colors[0]}
        cx="50.8"
        cy="68.9"
        rx="1.8"
        ry="2.6"
      />
      <line fill={colors[2]} x1="58" y1="78.5" x2="36.8" y2="42.5" />
      <line fill={colors[2]} x1="57.4" y1="78.6" x2="36.2" y2="42.6" />
      <line fill={colors[2]} x1="56.9" y1="78.7" x2="35.6" y2="42.7" />
      <line fill={colors[2]} x1="56.3" y1="79" x2="35.2" y2="42.8" />
    </g>
  )
}

export default LuteR
