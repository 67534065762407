import React from "react"

const SlingShotRight = ({
  colors = ["#352915", "#464646", "#939393", "#724F20"]
}) => {
  return (
    <g id="SlingShotRight">
      <path
        fill={colors[0]}
        d="M35.0607529,90.8808136l-0.7994766-7.2679672l-4.5061417-2.1077194 c0,0-1.5989513,1.4535904-1.5262737,3.3432693c0,0.2907104-1.5262718,24.8564606-1.5262718,24.8564606 s0.1453571,1.7443237,0.8721523,2.0350418c0.726799,0.2907181,2.3257523-0.2907181,2.3257523-0.2907181l0.3633957-2.0350342 L29.9004936,84.557663l-0.8721581-1.3809128l0.7994766-1.0901947l3.8520317,2.0350342l-0.726799,1.6716385l0.0726776,6.3231354 l1.6716309,1.9623566l-0.3633995-3.9973831L35.0607529,90.8808136z"
      />
      <path
        fill={colors[1]}
        d="M26.5572243,108.6146698c0.5814419-0.7994766,0.726799-1.3082352,1.8169937-1.1628723 c0.1453571,0,1.3809166,0.0726776,1.4535942,0.2180405c0.3633995,0.5814362,0.8721561,1.9623489,0.8721561,1.9623489 s-0.7994747,1.5262756-1.8169937,1.5989532c-0.726799,0.0726776-2.2530708-0.6541214-2.2530708-0.6541214 S26.2665043,108.9780655,26.5572243,108.6146698z"
      />
      <path
        fill={colors[2]}
        d="M28.228857,108.3966293c0.1453571-0.2907257,0.2907181-0.6541214,0.4360752-0.9448395 c0.4360771,0,1.017519,0.0726776,1.1628761,0.2180405c0.3633995,0.5814438,0.8721523,1.9623489,0.8721523,1.9623489 s-0.2907181,0.5814438-0.7994766,1.0175247c-0.5087528-0.1453629-1.0175171-0.4360809-1.5262699-0.7994766 C28.374218,109.4141464,28.3015327,108.9053879,28.228857,108.3966293z"
      />
      <path
        fill={colors[3]}
        d="M29.0283318,111.0130997l-1.0175171-0.0726776l-0.1453571-1.7443161l0.4360752-1.8896713 l0.9448414-23.4755554l2.7618313-1.3082352l-1.5262756-0.726799l-0.7267952-0.3634033l-1.0175171,0.5087662l-0.6541195,1.526268 l-1.4535942,26.1647034c0,0,0.29072,2.0350418,1.2355537,1.7443161c0.1453571-0.0726776,1.3082371,0,1.7443123-0.2907181 C29.1736946,110.8677444,29.0283318,111.0130997,29.0283318,111.0130997z"
      />
    </g>
  )
}

export default SlingShotRight
