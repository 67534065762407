import React from "react"

const ShortswordLeft = ({ colors = ["#828284", "#CCCCCB", "#FFFFFF"] }) => {
  return (
    <g id="ShortswordLeft">
      <path d="M78.7,79.3c-0.3,0.3-0.6,0.4-1,0.4h-0.1c-0.8,0-1.4-0.7-1.4-1.5c0-0.2,0-0.4,0.1-0.6l-2.8-1c-0.2,2.6-1,5.1-2.6,7.6 c0.4,0.4,0.6,1,0.4,1.5v0.1c-0.1,0.2-0.2,0.4-0.4,0.6c-0.4,0.4-0.9,0.5-1.5,0.3c-0.8-0.3-1.2-1.1-0.9-1.9v-0.1 c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.5-0.3,0.8-0.4c0.2-3.1,1.2-5.9,2.1-7.8l-3.1-1.1v0.1c-0.1,0.2-0.2,0.4-0.4,0.6 c-0.4,0.4-0.9,0.5-1.5,0.3c-0.8-0.3-1.2-1.1-0.9-1.9v-0.1c0.1-0.2,0.2-0.4,0.4-0.6c0.4-0.4,0.9-0.5,1.5-0.3 c0.2,0.1,0.4,0.2,0.5,0.3l2.9,1l10.3-37l4.1-3.2l1.3,4.9L75.1,75.2l0,0l-0.2,0.5l2.8,1l0,0c0.8,0,1.4,0.7,1.4,1.5 C79.2,78.7,79,79.1,78.7,79.3z" />
      <g id="longsworsShadow_1_">
        <path
          fill={colors[0]}
          d="M77.7,77L77.7,77l-3-1.1l11.6-37l-1.1-4.2l-3.4,2.8L71.3,74.6L68,73.4c-0.1-0.1-0.3-0.2-0.5-0.3 c-0.7-0.2-1.4,0.1-1.6,0.8V74c-0.2,0.7,0.2,1.4,0.8,1.6l0,0c0.7,0.2,1.4-0.1,1.6-0.8v-0.1c0-0.1,0-0.2,0.1-0.2l3.6,1.3 c-0.9,1.9-2,4.8-2.1,8.1c-0.5,0-1,0.3-1.2,0.8v0.1c-0.2,0.7,0.2,1.4,0.8,1.6l0,0c0.7,0.2,1.4-0.1,1.6-0.8v-0.1 c0.2-0.5,0-1.1-0.5-1.4c1.5-2.3,2.6-4.8,2.7-7.9l3.3,1.2c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.7,0.6,1.3,1.2,1.3h0.1 c0.7,0,1.2-0.5,1.3-1.2C78.9,77.6,78.4,77,77.7,77z"
        />
        <polygon
          fill={colors[1]}
          points="85.1,34.8 72.9,75.1 74.9,75 86.3,38.9"
        />
      </g>
      <polygon fill="#FFF" points="72.8,75.1 85.1,34.9 73.3,75.1" />
      <polygon
        fill={colors[1]}
        points="68.6,74.5 76.6,77.4 76.7,77.2 68.7,74.4"
      />
      <path
        fill={colors[1]}
        d="M66,74.5c0,0,0.8,1.6,2.1-0.1c0.1,0,0.2,0,0.2,0s-0.2,1.3-1.4,1.2C66.2,75.4,66,74.5,66,74.5z"
      />
      <path
        fill={colors[1]}
        d="M76.5,78.1c0,0,1.2,1.8,2.2-0.2c0.2,0.7-0.6,1.4-0.6,1.4S76.7,79.7,76.5,78.1z"
      />
    </g>
  )
}

export default ShortswordLeft
