import React from "react"

const GreatAxeR = ({ colors = ["#9E9E9E", "#21366C"] }) => {
  return (
    <g id="GreatAxeR">
      <path
        fill={colors[0]}
        d="M47.5291901,4.3689251c-0.2350006,0.704998-0.8224983,1.2924957-1.5274925,1.5274935 c-2.7024918,1.4099951-4.817482,1.9974928-7.7549706,1.9974928c-0.3524971,0.4699984-1.2924919,0.9399958-1.7624931,1.409996 c0-0.2350006,0-4.817482,0-5.16998c0,0,1.0574989-0.1174998,1.0574989-0.3524976c0,0-1.8799896-0.9399955-2.3499908-6.5799751 c-0.5874977,5.7574768-2.2324944,6.6974726-2.2324944,6.6974726c0,0.2349999,1.0574989,0.2349999,1.0574989,0.2349999 c0,0.2350001,0,5.16998,0,5.16998s-1.1749954-0.704998-1.7624931-1.409996c-2.9374886,0-5.0524807-0.7049975-7.7549686-1.9974928 c-0.5874977-0.3524981-1.2924938-0.8224978-1.5274925-1.5274935c0,0-11.2799578,16.2149391,3.054985,27.6123962 c-1.9974918-6.6974754-2.4674873-16.0974388,5.8749771-16.9199352c0.2349987,0,1.9974918-0.1174994,1.9974918-0.1174994 l-0.1174965,5.8749762c-0.1174965,0.2350006-0.4700012,0.4700012-0.9399948,0.9399967h1.0574989v1.7624931h-1.1749954 c0,0.1174965,0,2.4674873,0,2.5849895c0.1174965,0,1.2924919,0,1.409996,0c0,25.0274048,0,65.2122498,0,90.2396545h-0.1175003 c0.4700012,1.1749954,0.9399948,1.644989,1.409996,1.5274963c0.4700012,0.234993,0.9399948-0.2350006,1.409996-1.5274963 h-0.1174965c0-25.0274048,0-65.2122498,0-90.2396545c0.1174965,0,1.2924919,0,1.4099922,0c0-0.1174965,0-2.4674873,0-2.5849895 h-1.1749954v-1.7624931h1.0574989c-0.2350006-0.352499-0.7049942-0.5874977-0.9399948-0.9399967l-0.4700165-5.7574787 c0,0,1.8799896,0,1.9974899,0c8.224968,0.8224974,7.7549706,10.2224617,5.8749771,16.9199371 C58.6916466,20.5838642,47.5291901,4.3689251,47.5291901,4.3689251z"
      />
      <path
        fill={colors[1]}
        d="M32.1367493,7.8939114c-2.8199902,0-4.8174782-0.5874977-7.2849712-1.7624931 c-2.5849915,4.464982-7.0499725,14.5699425,0.2350006,22.2074127c-1.0574989-5.9924736-0.2350006-12.689949,6.6974735-13.2774467 c0.1174984,0,1.5274925,0,1.9974937-0.1175003l0.1174965-5.6399794 C33.5467453,9.0689068,32.6067467,8.3639097,32.1367493,7.8939114z"
        opacity="0.6"
      />
      <path
        fill={colors[1]}
        d="M38.3642273,7.8939114c2.8199883,0,4.8174782-0.5874977,7.2849693-1.7624931 c2.5849915,4.464982,7.0499725,14.5699425-0.2349968,22.2074127c1.0574951-5.9924736,0.2349968-12.689949-6.6974754-13.2774467 c-0.1175003,0-1.6449966,0-2.1149902,0l-0.1175003-5.6399775C36.836731,9.0689068,37.8942261,8.3639097,38.3642273,7.8939114z"
        opacity="0.6"
      />
      <rect
        x="32.607"
        y="25.166"
        opacity="0.6"
        fill={colors[1]}
        width="5.287"
        height="0.94"
      />
      <rect
        x="32.724"
        y="21.641"
        opacity="0.6"
        fill={colors[1]}
        width="5.052"
        height="0.352"
      />
      <path
        opacity="0.6"
        fill={colors[1]}
        d="M33.8992424,4.1339273c0,0-0.7049942,0-1.0574951-0.2349999 c0.7049942-0.2349999,2.8199883-0.3524978,4.5824814-0.1175001c-0.1174965,0.1175001-1.0574951,0.3524981-1.0574951,0.3524981 S35.3092384,3.6639276,33.8992424,4.1339273z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M36.7192307,64.1337433v-9.0474625 c0-0.2350006,0-0.4700012-0.1174965-0.704998c-0.9399986,0.1175003-1.9974937,0.1175003-2.9374924,0.2350006 c0,0.1174965-0.1174965,0.3524971-0.1174965,0.4700012v9.3999596c0,0.2350006,0,0.3525009,0.1174965,0.5875015 C34.6042404,64.9562454,35.6617355,64.6037445,36.7192307,64.1337433z"
      />
    </g>
  )
}

export default GreatAxeR
