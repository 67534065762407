import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaulR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaulR") ? (
    <g id="MaulR">
      <path d="M45.6,87.2c0.9,0,1.7-0.1,2.6-0.4c2.1-0.6,3.9-2,5-3.8c0.4-0.6,0.6-1.2,0.8-1.9l0.1-0.4l0,0c0.1-0.4,0.2-0.8,0.2-1.2 l0-0.4c0-0.4,0-0.6,0-0.9l0-0.1h0l0-0.2l-0.1-0.5C54,75,52.1,58.5,50.9,48.1c0.4-0.2,0.6-0.3,0.6-0.3l3.8-6.2l0.1,0 c0-0.1,0-0.2,0-0.3l0-0.1l0-0.3c0.1-1.1,0.1-3.1-0.2-7l-0.1-2.6l-3.6-6c-1.7-0.9-3.3-0.9-3.9-0.9c-0.7,0-1.8,0-3.3,0.1 c-1.1,0-2.4,0.1-3.7,0.2l-1.9,0.3C33.2,26.8,26.2,29.9,26,30l-3.9,8.8c1.2,3.6,2.7,8.2,2.9,9.1c-0.1-0.2-0.3-1.4,0.1-2.9l1.3,6.1 c1.4,1.7,3.2,2.9,7.7,3.2c1.1,10.2,2.7,23.3,2.9,25.3c0.1,1.6,0.8,3.2,1.9,4.5C40.6,86.1,43,87.2,45.6,87.2z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaulR, { character: "ManMixed" })
