import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import RoundWoodShieldLeft from "./RoundWoodShieldLeft"
import RoundWoodShieldRight from "./RoundWoodShieldRight"
import RectangleShieldRight from "./RectangleShieldRight"
import RectangleShieldLeft from "./RectangleShieldLeft"

const CATEGORY = "frontShield"
const CHARACTER = "ManDwarf"
export const IDS = {
  ROUND_WOOD_SHIELD_LEFT: "RoundWoodShieldLeft",
  ROUND_WOOD_SHIELD_RIGHT: "RoundWoodShieldRight",
  RECTANGLE_SHIELD_RIGHT: "RectangleShieldRight",
  RECTANGLE_SHIELD_LEFT: "RectangleShieldLeft"
}

export const components = {
  [IDS.ROUND_WOOD_SHIELD_LEFT]: RoundWoodShieldLeft,
  [IDS.ROUND_WOOD_SHIELD_RIGHT]: RoundWoodShieldRight,
  [IDS.RECTANGLE_SHIELD_RIGHT]: RectangleShieldRight,
  [IDS.RECTANGLE_SHIELD_LEFT]: RectangleShieldLeft
}

export const Group = ({ props }) => (
  <g id="frontShield">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.ROUND_WOOD_SHIELD_LEFT,
    name: "Round Wood Shield Left",
    defaultColors: [
      "#D2A673",
      "#7B6144",
      "#675139",
      "#997954",
      "#SVGID_",
      "#36302A",
      "#726658",
      "#8F8579",
      "#FFFFFF",
      "#F6F6F6",
      "#E4E5E6",
      "#C8CACC",
      "#A2A4A7",
      "#757779",
      "#2D2D2E",
      "#000000"
    ],
    colorable: false,
    component: components[IDS.ROUND_WOOD_SHIELD_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.ROUND_WOOD_SHIELD_RIGHT,
    name: "Round Wood Shield Right",
    defaultColors: [
      "#D2A673",
      "#7B6144",
      "#675139",
      "#997954",
      "#SVGID_",
      "#36302A",
      "#726658",
      "#8F8579",
      "#FFFFFF",
      "#F6F6F6",
      "#E4E5E6",
      "#C8CACC",
      "#A2A4A7",
      "#757779",
      "#2D2D2E",
      "#000000"
    ],
    colorable: false,
    component: components[IDS.ROUND_WOOD_SHIELD_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.RECTANGLE_SHIELD_RIGHT,
    name: "Rectangle Shield Right",
    defaultColors: ["#EED793", "#A02932", "#000000", "#SVGID_", "#FFFFFF"],
    colorable: false,
    component: components[IDS.RECTANGLE_SHIELD_RIGHT],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.RECTANGLE_SHIELD_LEFT,
    name: "Rectangle Shield Left",
    defaultColors: ["#EED793", "#A02932", "#000000", "#SVGID_", "#FFFFFF"],
    colorable: false,
    component: components[IDS.RECTANGLE_SHIELD_LEFT],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
