import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaulL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaulL") ? (
    <g id="MaulL">
      <path d="M93,87.2c-0.9,0-1.7-0.1-2.6-0.4c-2.1-0.6-3.9-2-5-3.8c-0.4-0.6-0.6-1.2-0.8-1.9l-0.1-0.4l0,0c-0.1-0.4-0.2-0.8-0.2-1.2 l0-0.4c0-0.4,0-0.6,0-0.9l0-0.1h0l0-0.2l0.1-0.5c0.3-2.4,2.2-18.9,3.4-29.3c-0.4-0.2-0.6-0.3-0.6-0.3l-3.8-6.2l-0.1,0 c0-0.1,0-0.2,0-0.3l0-0.1l0-0.3c-0.1-1.1-0.1-3.1,0.2-7l0.1-2.6l3.6-6c1.7-0.9,3.3-0.9,3.9-0.9c0.7,0,1.8,0,3.3,0.1 c1.1,0,2.4,0.1,3.7,0.2l1.9,0.3c5.6,1.7,12.5,4.8,12.8,4.9l3.9,8.8c-1.2,3.6-2.7,8.2-2.9,9.1c0.1-0.2,0.3-1.4-0.1-2.9l-1.3,6.1 c-1.4,1.7-3.2,2.9-7.7,3.2c-1.1,10.2-2.7,23.3-2.9,25.3c-0.1,1.6-0.8,3.2-1.9,4.5C98,86.1,95.6,87.2,93,87.2z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaulL, { character: "ManMixed" })
