import React from "react"

const LongSkirt = ({ colors = ["#6B6B6B", "#231F20"] }) => {
  return (
    <g id="LongSkirt" fillRule="evenodd" clipRule="evenodd">
      <path
        fill={colors[0]}
        d="M61.3317604,79.4684906 c0,0-2.6769829,4.028801-3.2990265,18.1726685c-0.6842461,4.7146149-3.6700592,13.7216568-3.6700592,13.7216568h28.6140327 c0,0-3.7944717-6.6849136-4.7897415-13.5105591c-1.9283295-19.2103195-2.5727615-18.6117554-4.7855301-20.4222031 c-1.2773514,0.3074799-2.8070602,1.8104477-5.0290298,1.8104477c-2.2807159,0.101181-4.0852966-1.5857162-5.3777122-1.86409 C62.4893799,77.2675705,61.3317604,79.4684906,61.3317604,79.4684906z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M60.4452972,111.4734192h16.6085815L72.3822174,77.845787c0,0-3.2990417,1.3947144-4.1305084,1.4081268 s-3.5270309-1.099678-3.5270309-1.099678L60.4452972,111.4734192z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M66.2937393,78.9186478c0,0-0.3744507,14.4703293-0.3744507,18.6923752c0,4.2220535-2.1771545,13.8623962-2.1771545,13.8623962 h-5.9716225c0,0,2.9236069-5.6294022,3.5456505-13.9327621c0.6220436-8.3737335,2.3893013-19.9228516,2.3893013-19.9228516 L66.2937393,78.9186478z"
      />
      <path
        opacity="0.35"
        fill={colors[1]}
        d="M70.7863388,78.6638412c0,0-0.0773087,13.1770554,0.1093063,18.3138733 c0.186615,5.1368332,2.3015594,14.4957047,2.3015594,14.4957047h5.9716263c0,0-2.9236069-8.7255707-3.7944717-15.0586395 c-0.8086624-6.3330765-2.3484268-18.8237991-2.3484268-18.8237991L70.7863388,78.6638412z"
      />
    </g>
  )
}

export default LongSkirt
