import React from "react"

const Tank = ({ colors = ["#77634F"] }) => {
  return (
    <g id="Tank">
      <path
        fill={colors[0]}
        d="M38.2723122,79.7863388h21.0280533l1.9311447-13.8014603l-0.5364265-7.5100174 l-3.7550125-0.7509995c0,0-4.3987236,7.2954483-8.4755898,7.2954483s-7.9391632-7.6173058-7.9391632-7.6173058 l-4.2914391,1.1801453v7.402729L38.2723122,79.7863388z"
      />
    </g>
  )
}

export default Tank
