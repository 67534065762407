import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Cape from "./Cape"
import LongSwordLeft from "./LongSwordLeft"
import GoldAxeLeft from "./GoldAxeLeft"
import LongBowLeft from "./LongBowLeft"
import TatteredCape from "./TatteredCape"
import FrontShieldR from "./FrontShieldR"
import FrontShieldL from "./FrontShieldL"
import BlueFlameRight from "./BlueFlameRight"
import BlueFlameLeft from "./BlueFlameLeft"
import FlameLeft from "./FlameLeft"
import FlameRight from "./FlameRight"
import BookSpellL from "./BookSpellL"
import SpellBookL from "./SpellBookL"
import DragonStaffR from "./DragonStaffR"
import LongSwordRight from "./LongSwordRight"
import GoldAxeRight from "./GoldAxeRight"
import LongBowRight from "./LongBowRight"
import SlingShotLeft from "./SlingShotLeft"
import BodyBase from "./BodyBase"
import WarhammerL from "./WarhammerL"
import MaceL from "./MaceL"
import AxeLeft from "./AxeLeft"
import LuteLeft from "./LuteLeft"
import DaggerLeft from "./DaggerLeft"
import BattleAxeLeft from "./BattleAxeLeft"
import MorningStarLeft from "./MorningStarLeft"
import WarhammerR from "./WarhammerR"
import MaceR from "./MaceR"
import AxeRight from "./AxeRight"
import LuteRight from "./LuteRight"
import DaggerRight from "./DaggerRight"
import StaffRight from "./StaffRight"
import StaffLeft from "./StaffLeft"

const CATEGORY = "shadow"
const CHARACTER = "WomanGnome"
export const IDS = {
  CAPE: "Cape",
  LONG_SWORD_LEFT: "LongSwordLeft",
  GOLD_AXE_LEFT: "GoldAxeLeft",
  LONG_BOW_LEFT: "LongBowLeft",
  TATTERED_CAPE: "TatteredCape",
  FRONT_SHIELD_R: "FrontShieldR",
  FRONT_SHIELD_L: "FrontShieldL",
  BLUE_FLAME_RIGHT: "BlueFlameRight",
  BLUE_FLAME_LEFT: "BlueFlameLeft",
  FLAME_LEFT: "FlameLeft",
  FLAME_RIGHT: "FlameRight",
  BOOK_SPELL_L: "BookSpellL",
  SPELL_BOOK_L: "SpellBookL",
  DRAGON_STAFF_R: "DragonStaffR",
  LONG_SWORD_RIGHT: "LongSwordRight",
  GOLD_AXE_RIGHT: "GoldAxeRight",
  LONG_BOW_RIGHT: "LongBowRight",
  SLING_SHOT_LEFT: "SlingShotLeft",
  BODY_BASE: "BodyBase",
  WARHAMMER_L: "WarhammerL",
  MACE_L: "MaceL",
  AXE_LEFT: "AxeLeft",
  LUTE_LEFT: "LuteLeft",
  DAGGER_LEFT: "DaggerLeft",
  BATTLE_AXE_LEFT: "BattleAxeLeft",
  MORNING_STAR_LEFT: "MorningStarLeft",
  WARHAMMER_R: "WarhammerR",
  MACE_R: "MaceR",
  AXE_RIGHT: "AxeRight",
  LUTE_RIGHT: "LuteRight",
  DAGGER_RIGHT: "DaggerRight",
  STAFF_RIGHT: "StaffRight",
  STAFF_LEFT: "StaffLeft"
}

export const components = {
  [IDS.CAPE]: Cape,
  [IDS.LONG_SWORD_LEFT]: LongSwordLeft,
  [IDS.GOLD_AXE_LEFT]: GoldAxeLeft,
  [IDS.LONG_BOW_LEFT]: LongBowLeft,
  [IDS.TATTERED_CAPE]: TatteredCape,
  [IDS.FRONT_SHIELD_R]: FrontShieldR,
  [IDS.FRONT_SHIELD_L]: FrontShieldL,
  [IDS.BLUE_FLAME_RIGHT]: BlueFlameRight,
  [IDS.BLUE_FLAME_LEFT]: BlueFlameLeft,
  [IDS.FLAME_LEFT]: FlameLeft,
  [IDS.FLAME_RIGHT]: FlameRight,
  [IDS.BOOK_SPELL_L]: BookSpellL,
  [IDS.SPELL_BOOK_L]: SpellBookL,
  [IDS.DRAGON_STAFF_R]: DragonStaffR,
  [IDS.LONG_SWORD_RIGHT]: LongSwordRight,
  [IDS.GOLD_AXE_RIGHT]: GoldAxeRight,
  [IDS.LONG_BOW_RIGHT]: LongBowRight,
  [IDS.SLING_SHOT_LEFT]: SlingShotLeft,
  [IDS.BODY_BASE]: BodyBase,
  [IDS.WARHAMMER_L]: WarhammerL,
  [IDS.MACE_L]: MaceL,
  [IDS.AXE_LEFT]: AxeLeft,
  [IDS.LUTE_LEFT]: LuteLeft,
  [IDS.DAGGER_LEFT]: DaggerLeft,
  [IDS.BATTLE_AXE_LEFT]: BattleAxeLeft,
  [IDS.MORNING_STAR_LEFT]: MorningStarLeft,
  [IDS.WARHAMMER_R]: WarhammerR,
  [IDS.MACE_R]: MaceR,
  [IDS.AXE_RIGHT]: AxeRight,
  [IDS.LUTE_RIGHT]: LuteRight,
  [IDS.DAGGER_RIGHT]: DaggerRight,
  [IDS.STAFF_RIGHT]: StaffRight,
  [IDS.STAFF_LEFT]: StaffLeft
}

export const Group = ({ props }) => (
  <g id="shadow">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.CAPE,
    name: "Cape",
    defaultColors: [],
    colorable: false,
    component: components[IDS.CAPE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_SWORD_LEFT,
    name: "Long Sword Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_SWORD_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_LEFT,
    name: "Gold Axe Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.GOLD_AXE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_BOW_LEFT,
    name: "Long Bow Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_BOW_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.TATTERED_CAPE,
    name: "Tattered Cape",
    defaultColors: [],
    colorable: false,
    component: components[IDS.TATTERED_CAPE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FRONT_SHIELD_R,
    name: "Front Shield R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.FRONT_SHIELD_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FRONT_SHIELD_L,
    name: "Front Shield L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.FRONT_SHIELD_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BLUE_FLAME_RIGHT,
    name: "Blue Flame Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BLUE_FLAME_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BLUE_FLAME_LEFT,
    name: "Blue Flame Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BLUE_FLAME_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FLAME_LEFT,
    name: "Flame Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.FLAME_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.FLAME_RIGHT,
    name: "Flame Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.FLAME_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BOOK_SPELL_L,
    name: "Book Spell L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BOOK_SPELL_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SPELL_BOOK_L,
    name: "Spell Book L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.SPELL_BOOK_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DRAGON_STAFF_R,
    name: "Dragon Staff R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.DRAGON_STAFF_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_SWORD_RIGHT,
    name: "Long Sword Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_SWORD_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.GOLD_AXE_RIGHT,
    name: "Gold Axe Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.GOLD_AXE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LONG_BOW_RIGHT,
    name: "Long Bow Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LONG_BOW_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SLING_SHOT_LEFT,
    name: "Sling Shot Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.SLING_SHOT_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BODY_BASE,
    name: "Body Base",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BODY_BASE],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WARHAMMER_L,
    name: "Warhammer L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WARHAMMER_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MACE_L,
    name: "Mace L",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MACE_L],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.AXE_LEFT,
    name: "Axe Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.AXE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LUTE_LEFT,
    name: "Lute Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LUTE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DAGGER_LEFT,
    name: "Dagger Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.DAGGER_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BATTLE_AXE_LEFT,
    name: "Battle Axe Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.BATTLE_AXE_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MORNING_STAR_LEFT,
    name: "Morning Star Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MORNING_STAR_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.WARHAMMER_R,
    name: "Warhammer R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.WARHAMMER_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.MACE_R,
    name: "Mace R",
    defaultColors: [],
    colorable: false,
    component: components[IDS.MACE_R],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.AXE_RIGHT,
    name: "Axe Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.AXE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LUTE_RIGHT,
    name: "Lute Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.LUTE_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.DAGGER_RIGHT,
    name: "Dagger Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.DAGGER_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.STAFF_RIGHT,
    name: "Staff Right",
    defaultColors: [],
    colorable: false,
    component: components[IDS.STAFF_RIGHT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.STAFF_LEFT,
    name: "Staff Left",
    defaultColors: [],
    colorable: false,
    component: components[IDS.STAFF_LEFT],
    inUI: false,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
