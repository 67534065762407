import React from "react"

const RightScaled = ({ colors = ["#9D9D9D", "#FFFFFF"] }) => {
  return (
    <g id="RightScaled">
      <path
        fill={colors[0]}
        d="M47.9,36.7c-0.4,0.1-0.8,0.2-1.2,0.3c2-2.7,2.8-6.2,5.5-8.6c0.3-0.3,0.7-0.6,1.1-0.9 c1.5-0.3,2.9-0.8,4.2-1.7l0,0c-0.5,1.7-1.1,3.2-2,4.7c-0.3,0.6-0.7,1.2-1.2,1.7c-0.2,0.8-0.5,1.6-0.8,2.3 c-0.1,0.2-0.2,0.5-0.3,0.7c-1.5,2.3-2.8,4.8-6.5,5.8C47.2,39.6,47.5,38.1,47.9,36.7z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M54.6,31.6c-0.3,0.1-0.7,0.3-0.9,0.7 c-1.3,2.2-4,3.6-6.4,4.7c-0.3,0.1-0.7,0.2-1,0.2c2-2.7,2.8-6.2,5.5-8.6c0.3-0.3,0.7-0.6,1.1-0.9c1.5-0.3,2.9-0.8,4.2-1.7l0,0 c-0.5,1.7-1.1,3.2-2,4.7C55,31.1,54.8,31.4,54.6,31.6z"
      />
      <path
        opacity="0.28"
        d="M53.7,31.1c0.2-0.4,0.6-0.6,0.9-0.7 c0.2-0.3,0.4-0.6,0.5-0.9c0.7-1,1.1-2.2,1.6-3.4c0.2-0.1,0.5-0.3,0.7-0.4l0,0c-0.5,1.7-1.1,3.2-2,4.7c-0.2,0.3-0.3,0.6-0.5,0.9 c-0.3,0.1-0.7,0.3-0.9,0.7c-1.3,2.2-4,3.6-6.4,4.7c-0.3,0.1-0.7,0.2-1,0.2c0.3-0.4,0.5-0.8,0.8-1.2C49.8,34.6,52.5,33.3,53.7,31.1 z"
      />
    </g>
  )
}

export default RightScaled
