import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongBowL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongBowL") ? (
    <g id="LongBowL">
      <path d="M86.5,113.7c3.1,0,5.8-1.9,6.9-4.8l-0.5-2.8c0.1-0.3,0.2-0.6,0.2-0.9l0,0c8.5-12.5,11-30.2,11.7-42.8l-0.2,0l0.4-0.4 l0-1.3c0-0.9,0-1.8,0-2.7c0-1.3,0.1-2.6,0.1-4l-0.4-0.4l0.1,0c-0.2-4.9-0.7-9.7-1.4-14.1l-0.1-0.4l0,0l0,0l-0.3-1.7 c-0.4-2-0.8-4-1.2-5.9l0,0l0,0l0,0c-0.8-3.5-1.8-6.7-3-9.7c-1.8-4.6-3.5-7.7-4.7-9.6c0-0.1,0-0.1,0-0.2c0,0,0,0.1,0,0.2 c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.8-0.4-1.6-0.7-2.3l0.4-2.2c-1.1-2.9-3.8-4.8-6.9-4.8c-1.6,0-4.1,0.5-6.2,3.1 c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.3-0.5,0.6-0.7,1c-0.3,0.4-0.5,0.8-0.7,1.2c-0.1,0.3-0.3,0.5-0.4,0.7C78,8.7,78,8.9,77.9,9 l0.4,3.8l-0.1,0c-0.1,1.4,0.1,2.7,0.6,3.8v82.8c-0.6,1.1-0.8,2.4-0.8,3.8l0.3,0l-0.4,3.8c0.1,0.1,0.2,0.3,0.3,0.5 c0,0,0.2,0.4,0.3,0.6l0,0C79.4,109.6,81.8,113.7,86.5,113.7C86.5,113.7,86.5,113.7,86.5,113.7z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongBowL, { character: "ManMixed" })
