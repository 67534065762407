import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const DaggerLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("DaggerLeft") ? (
    <g id="DaggerLeft">
      <path
        id="S_x24_daggerLeft"
        d="M64.9,93.8l-4.7-5l1.5-10.2l2.6-7.6c0,0,7.3-18,8.3-18.9l0,0l6.1,0.6l5.5,3L80,76.1l-2,6.4l-0.3,1 L76,87.6L74.1,92l-1.2,1l-6.7,1.3L64.9,93.8z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(DaggerLeft, {
  character: "ManDwarf"
})
