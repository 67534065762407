import React from "react"

const BrownShoes = ({
  colors = [
    "#774F1F",
    "#6B441B",
    "#A16628",
    "#372513",
    "#948F8B",
    "#EED793",
    "#70431B",
    "#794B22",
    "#925F3A",
    "#9E6A47",
    "#SVGID_",
    "#594B1F"
  ]
}) => {
  return (
    <g id="BrownShoes">
      <path
        opacity="0.5"
        d="M44,119.5c0,0,0.4-3.6-0.2-4.3c-0.1-0.1-0.5-0.7-1.7-3.8 c-0.1-0.3-0.2-0.6-0.4-1l-0.1-0.4h-0.1c-0.3-0.5-0.5-0.8-0.6-0.9l0.1-0.2h-0.4c-0.1,0-0.2-0.1-0.4-0.1h-0.1H40 c-0.5-0.1-1.2-0.2-2-0.2c-0.6,0-1.2,0.1-1.7,0.3L36,109l0,0c-0.1,0.1-0.2,0.1-0.3,0.2l-0.3,0.1v0.1l0,0c-0.5,0.4-0.8,0.8-1,1.4 c-0.2,0.5-1.2,3.4-1.9,4.8c-0.6,1.2-0.4,2.7-0.2,3.4l0.1,0.7H44V119.5z"
      />
      <path
        fill={colors[0]}
        d="M41.4,110.3c0,0,1.6,4.4,2.2,5c0.5,0.6,0,3.6,0,3.6H32.8c0,0-0.6-1.9,0.1-3.5c0.7-1.6,1.9-5,1.9-5 L41.4,110.3z"
      />
      <path
        fill={colors[1]}
        d="M40.7,110.1c0,0,2.3,2.7,0.8,4.2c0.7,0.7-0.3,3.5-3.4,3.4c-3.5-0.1-3.1-3.4-3.1-3.4s-1.2-2.6,0.6-3.9 C37.4,109,40.7,110.1,40.7,110.1z"
      />
      <path
        fill={colors[2]}
        d="M40.7,109.2c0,0,2.2,2.7,0.7,4.2c0.7,0.7-0.3,3.5-3.3,3.4c-3.4-0.1-3-3.4-3-3.4s-1.2-2.6,0.6-3.9 C37.5,108.1,40.7,109.2,40.7,109.2z"
      />
      <rect x="32.7" y="118.7" fill={colors[3]} width="10.8" height="0.8" />
      <rect x="32.7" y="118.5" fill={colors[4]} width="10.8" height="0.2" />
      <path
        fill={colors[5]}
        d="M36.9,114.7c0.1,0.1,0.7-0.3,1.5-0.3s1.4,0.4,1.5,0.3s-0.5-0.9-1.5-0.9 C37.4,113.8,36.8,114.5,36.9,114.7z"
      />
      <path
        fill={colors[1]}
        d="M36.6,113.7c1.2-0.2,1.9-0.2,3.2,0c0.3-1.3,0.5-3.2,0.8-4.5c-1.9-0.4-2.9-0.6-4.7,0.1 C36.2,110.7,36.3,112.4,36.6,113.7z"
      />
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1="-457.914"
        y1="45.638"
        x2="-457.989"
        y2="41.134"
        gradientTransform="rotate(180 -209.825 76.994)"
      >
        <stop offset="0.033" stopColor="#70431b" />
        <stop offset="0.276" stopColor="#794b22" />
        <stop offset="0.719" stopColor="#925f3a" />
        <stop offset="0.912" stopColor="#9e6a47" />
      </linearGradient>
      <path
        fill="url(#SVGID_5_)"
        d="M37.1,113.6c1-0.2,1.5-0.2,2.4,0c0.2-1.3,0.4-3.2,0.6-4.5c-1.4-0.3-2.2-0.3-3.6,0 C36.7,110.4,36.8,112.2,37.1,113.6z"
      />
      <path
        fill={colors[1]}
        d="M40.7,113.1c0.3,0,0.9-0.1,1-0.3c0.1-0.1,0-0.6-0.2-0.8l0,0c0.1,0.4,0.8,0.9-0.8,1.3 c-1.6-1-2.9-1.2-4.3-0.5v-0.2C37.8,112,39.1,112.1,40.7,113.1z"
      />
      <path
        fill={colors[1]}
        d="M36.4,112.6v0.2l0,0C36.4,112.7,36.4,112.6,36.4,112.6L36.4,112.6z"
      />
      <path
        fill={colors[2]}
        d="M36.1,111.3c0-0.1,0-0.1,0-0.2c1.8-0.7,3.4-0.5,5.4,0.8c0,0,0.1,0.1,0.1,0.2 C39.5,110.8,37.9,110.5,36.1,111.3z"
      />
      <path
        fill={colors[2]}
        d="M36.1,111.3v-0.2l0,0C36.1,111.2,36.1,111.2,36.1,111.3L36.1,111.3z"
      />
      <path
        fill={colors[2]}
        d="M41.7,112.9c-0.1,0.2-0.7,0.2-1,0.3c-1.6-1-2.8-1.2-4.3-0.6l-0.1-0.3c-0.1-0.3-0.1-0.6-0.2-0.9 c1.8-0.7,3.4-0.5,5.5,0.7C41.7,112.3,41.8,112.7,41.7,112.9z"
      />
      <path
        fill={colors[2]}
        d="M36.4,112.6L36.4,112.6c0-0.1-0.1-0.2-0.1-0.3L36.4,112.6z"
      />
      <path
        fill={colors[2]}
        d="M36.1,111.3c0.1,0.3,0.1,0.6,0.2,0.9L36.1,111.3L36.1,111.3z"
      />
      <g>
        <path
          fill={colors[11]}
          d="M36.5,110.5c0.1,0.9,0.1,1.4,0.2,2.3c1.2-0.2,1.8-0.2,2.9,0.1c0.1-0.9,0.2-1.4,0.4-2.3 C38.6,110.3,37.9,110.3,36.5,110.5z M36.9,110.8c1.1-0.1,1.6-0.1,2.7,0.1c-0.1,0.6-0.1,0.9-0.2,1.5c-1-0.2-1.4-0.2-2.4-0.1 C36.9,111.7,36.9,111.4,36.9,110.8z"
        />
        <path
          fill={colors[5]}
          d="M36.5,110.3c0.1,0.9,0.1,1.4,0.2,2.3c1.2-0.2,1.8-0.2,2.9,0.1c0.1-0.9,0.2-1.4,0.4-2.3 C38.6,110.1,37.9,110.1,36.5,110.3z M36.9,110.6c1.1-0.1,1.6-0.1,2.7,0.1c-0.1,0.6-0.1,0.9-0.2,1.5c-1-0.2-1.4-0.2-2.4-0.1 C36.9,111.5,36.9,111.2,36.9,110.6z"
        />
      </g>
      <g>
        <path
          opacity="0.5"
          d="M64.2,119.4c0,0,0.4-3.6-0.2-4.2c-0.1-0.1-0.5-0.7-1.7-3.8 c-0.1-0.3-0.2-0.6-0.4-1l-0.1-0.4h-0.1c-0.3-0.5-0.5-0.8-0.6-0.9l0.1-0.2h-0.4c-0.1,0-0.2-0.1-0.4-0.1h-0.1h-0.1 c-0.5-0.1-1.2-0.2-2-0.2c-0.6,0-1.2,0.1-1.7,0.3l-0.3,0.1l0,0c-0.1,0.1-0.2,0.1-0.3,0.2l-0.3,0.1v0.1l0,0c-0.5,0.4-0.8,0.8-1,1.4 c-0.2,0.5-1.2,3.4-1.9,4.8c-0.6,1.2-0.4,2.7-0.2,3.4v0.7L64.2,119.4z"
        />
        <path
          fill={colors[0]}
          d="M61.6,110.3c0,0,1.6,4.4,2.2,5c0.5,0.6,0,3.6,0,3.6H53c0,0-0.6-1.9,0.1-3.5c0.7-1.6,1.9-5,1.9-5 L61.6,110.3z"
        />
        <path
          fill={colors[1]}
          d="M60.9,110.1c0,0,2.3,2.7,0.8,4.2c0.7,0.7-0.3,3.5-3.4,3.4c-3.5-0.1-3.1-3.4-3.1-3.4s-1.2-2.6,0.6-3.9 C57.6,109,60.9,110.1,60.9,110.1z"
        />
        <path
          fill={colors[2]}
          d="M60.9,109.2c0,0,2.2,2.7,0.7,4.2c0.7,0.7-0.3,3.5-3.3,3.4c-3.4-0.1-3-3.4-3-3.4s-1.2-2.6,0.6-3.9 C57.7,108.1,60.9,109.2,60.9,109.2z"
        />
        <rect x="52.9" y="118.7" fill={colors[3]} width="10.8" height="0.8" />
        <rect x="52.9" y="118.5" fill={colors[4]} width="10.8" height="0.2" />
        <path
          fill={colors[5]}
          d="M57.1,114.7c0.1,0.1,0.7-0.3,1.5-0.3s1.4,0.4,1.5,0.3s-0.5-0.9-1.5-0.9S57,114.5,57.1,114.7z"
        />
        <path
          fill={colors[1]}
          d="M56.8,113.7c1.2-0.2,1.9-0.2,3.2,0c0.3-1.3,0.5-3.2,0.8-4.5c-1.9-0.4-2.9-0.6-4.7,0.1 C56.4,110.7,56.5,112.4,56.8,113.7z"
        />
        <linearGradient
          id="SVGID_6_"
          gradientUnits="userSpaceOnUse"
          x1="-478.114"
          y1="45.638"
          x2="-478.188"
          y2="41.134"
          gradientTransform="rotate(180 -209.825 76.994)"
        >
          <stop offset="0.033" stopColor="#70431b" />
          <stop offset="0.276" stopColor="#794b22" />
          <stop offset="0.719" stopColor="#925f3a" />
          <stop offset="0.912" stopColor="#9e6a47" />
        </linearGradient>
        <path
          fill="url(#SVGID_6_)"
          d="M57.3,113.6c1-0.2,1.5-0.2,2.4,0c0.2-1.3,0.4-3.2,0.6-4.5c-1.4-0.3-2.2-0.3-3.6,0 C56.9,110.4,57,112.2,57.3,113.6z"
        />
        <path
          fill={colors[1]}
          d="M60.9,113.1c0.3,0,0.9-0.1,1-0.3c0.1-0.1,0-0.6-0.2-0.8l0,0c0.1,0.4,0.8,0.9-0.8,1.3 c-1.6-1-2.9-1.2-4.3-0.5v-0.2C58,112,59.3,112.1,60.9,113.1z"
        />
        <path
          fill={colors[1]}
          d="M56.6,112.6v0.2l0,0C56.6,112.7,56.6,112.6,56.6,112.6L56.6,112.6z"
        />
        <path
          fill={colors[2]}
          d="M56.3,111.3c0-0.1,0-0.1,0-0.2c1.8-0.7,3.4-0.5,5.4,0.8c0,0,0.1,0.1,0.1,0.2 C59.7,110.8,58.1,110.5,56.3,111.3z"
        />
        <path
          fill={colors[2]}
          d="M56.3,111.3v-0.2l0,0C56.3,111.2,56.3,111.2,56.3,111.3L56.3,111.3z"
        />
        <path
          fill={colors[2]}
          d="M61.9,112.9c-0.1,0.2-0.7,0.2-1,0.3c-1.6-1-2.8-1.2-4.3-0.6l-0.1-0.3c-0.1-0.3-0.1-0.6-0.2-0.9 c1.8-0.7,3.4-0.5,5.5,0.7C61.9,112.3,62,112.7,61.9,112.9z"
        />
        <path
          fill={colors[2]}
          d="M56.6,112.6L56.6,112.6c0-0.1-0.1-0.2-0.1-0.3L56.6,112.6z"
        />
        <path
          fill={colors[2]}
          d="M56.3,111.3c0.1,0.3,0.1,0.6,0.2,0.9L56.3,111.3L56.3,111.3z"
        />
        <g>
          <path
            fill={colors[11]}
            d="M56.7,110.5c0.1,0.9,0.1,1.4,0.2,2.3c1.2-0.2,1.8-0.2,2.9,0.1c0.1-0.9,0.2-1.4,0.4-2.3 C58.8,110.3,58.1,110.3,56.7,110.5z M57.1,110.8c1.1-0.1,1.6-0.1,2.7,0.1c-0.1,0.6-0.1,0.9-0.2,1.5c-1-0.2-1.4-0.2-2.4-0.1 C57.1,111.7,57.1,111.4,57.1,110.8z"
          />
          <path
            fill={colors[5]}
            d="M56.7,110.3c0.1,0.9,0.1,1.4,0.2,2.3c1.2-0.2,1.8-0.2,2.9,0.1c0.1-0.9,0.2-1.4,0.4-2.3 C58.8,110.1,58.1,110.1,56.7,110.3z M57.1,110.6c1.1-0.1,1.6-0.1,2.7,0.1c-0.1,0.6-0.1,0.9-0.2,1.5c-1-0.2-1.4-0.2-2.4-0.1 C57.2,111.5,57.1,111.2,57.1,110.6z"
          />
        </g>
      </g>
    </g>
  )
}

export default BrownShoes
