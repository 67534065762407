import React from "react"

const PlateArmor = ({ colors = ["#737373", "#9E9E9E", "#595A5A"] }) => {
  return (
    <g id="PlateArmor">
      <path
        fill={colors[0]}
        d="M60.8768845,58.111084l-4.6154327-0.4945107 c0,0-4.8626862,1.4011154-7.2528152,1.4011154c-2.3901329,0-7.5824966-1.4011154-7.5824966-1.4011154l-5.3571968,0.6593475 c0,0-2.1428795,3.6264114-1.7307892,7.3352394c0.4120941,3.7088318,2.5549736,7.2528229,2.5549736,7.2528229l-0.2472572,9.2308578 h24.3958473l-0.1648369-9.2308578c0,0,2.225296-3.6264114,2.5549698-7.2528229 C63.8439445,61.9023323,60.8768845,58.111084,60.8768845,58.111084z"
      />
      <path
        fill={colors[1]}
        d="M39.6129341,79.7871246c0,0-2.7198067-14.5056381,9.2308617-14.5056381 s10.0550423,14.093544,10.0550423,14.093544L39.6129341,79.7871246z"
      />
      <path
        fill={colors[0]}
        d="M49.420723,61.9023323L49.420723,61.9023323h-0.3296738h-0.3296738l0,0 l-13.1869469,0.3296738l0.9890213,4.0385017c0,0,0,0.7417679,7.4176559,2.3901367 c2.3077164-0.1648331,3.8736687,0,5.1099434-0.4120941c1.3186913,0.4120941,2.802227,0.3296738,5.1099396,0.4120941 c7.4176559-1.6483688,7.4176559-2.3901367,7.4176559-2.3901367l0.9890213-4.0385017L49.420723,61.9023323z"
      />
      <path
        fill={colors[1]}
        d="M49.2558861,59.5946159L49.2558861,59.5946159h-0.3296738h-0.3296738l0,0 l-10.961647,1.5659485l0.3296776,4.6154289c0,0,0.5769272,0.1648407,5.7692871,1.8132019 c2.3077126-0.2472534,3.8736649,0,5.1099396-0.4945068c1.3186951,0.4945068,2.802227,0.3296738,5.1099434,0.4945068 c4.2033386-1.2362747,5.1923599-1.6483688,5.1923599-1.6483688l0.8241806-4.2033348L49.2558861,59.5946159z"
      />
      <polygon
        opacity="0.62"
        points="62.1955757,89.2652359 62.1955757,91.737793 59.475769,88.6883163 57.5801468,92.1498795 56.0141945,89.9245911 54.2834091,92.5619736 52.0581131,89.9245911 51.7284393,92.6443939 49.0910492,95.3642044 46.7009163,92.8092346 46.4536629,89.67733 44.5580368,92.5619736 42.003067,89.5124969 40.5195351,92.1498795 38.3766556,89.0179901 36.068943,91.6553726 35.9865265,91.4081192 36.1513634,92.3971405 38.294239,89.7597504 40.6019554,92.9740677 41.5085602,90.6663589 44.2283669,93.963089 46.288826,90.831192 46.6184959,94.2103424 49.0910492,97.0125732 52.0581131,94.0455093 52.3877831,91.0784454 54.7779198,93.963089 56.673542,91.0784454 58.4043312,93.5510025 59.7230225,89.9245911 62.3604088,92.8092346"
      />
      <polygon
        fill={colors[0]}
        points="62.2779922,87.9465485 62.1131592,86.545433 62.1955757,89.2652359"
      />
      <path
        fill={colors[0]}
        d="M38.1294022,88.605896v-2.8022308v-5.7692871 c-0.7417679-0.3296738-1.2362747-0.4945068-1.483532-0.6593475l-1.0714378,8.736351l0.4945107,3.2143173v0.247261 l2.3901329-2.6373901L38.1294022,88.605896z"
      />
      <path
        fill={colors[0]}
        d="M62.1131592,86.545433l-0.0824165-0.906601l-0.6593475-6.1813812 c-0.2472534,0.1648407-0.7417679,0.3296814-1.3186951,0.6593552l-0.3296738,5.5220261l-0.1648369,2.8022308 l-0.1648369,0.2472534l2.7198067,3.0494843l0.0824165-2.4725571L62.1131592,86.545433z"
      />
      <path
        fill={colors[1]}
        d="M41.5909767,89.0179901v-3.214325v-4.450592 c-0.4945107-0.1648331-0.9890213-0.3296738-1.4011154-0.4945068c-0.7417679-0.247261-1.4835281-0.5769272-2.0604591-0.7417679 v5.7692871v2.8022308l0.3296738,0.4120865l2.0604591,3.1319046l1.483532-2.6373901L41.5909767,89.0179901z"
      />
      <path
        fill={colors[1]}
        d="M58.2394943,80.940979c-0.3296776,0.1648407-0.7417679,0.247261-1.0714378,0.4120941 l-0.2472572,4.3681793l-0.1648369,3.1319046l-0.7417679,1.071434l1.5659523,2.2252884l1.8956223-3.4615631l0.1648369-0.247261 l0.1648369-2.8022308l0.3296776-5.5220261C59.5581856,80.3640518,58.898838,80.6113052,58.2394943,80.940979z"
      />
      <path
        fill={colors[0]}
        d="M55.1075935,82.0124207c-0.7417641,0.247261-1.4011116,0.4120941-2.1428757,0.5769272 l-0.4120941,3.1319046l-0.4945068,4.2033386l2.225296,2.6373825l1.7307854-2.6373825l0.7417679-1.0714417l0.1648369-3.1319046 l0.2472534-4.3681793C56.5087051,81.6003342,55.7669411,81.84758,55.1075935,82.0124207z"
      />
      <path
        fill={colors[0]}
        d="M46.1239891,85.7212524l-0.2472572-3.2143173 c-0.9890213-0.247261-1.9780426-0.4945145-2.9670601-0.7417679c-0.4945107-0.1648331-0.906601-0.247261-1.4011116-0.4120941 v4.450592v3.214325l0.4120903,0.4945068l2.5549698,3.0494766l1.8956223-2.8846436L46.1239891,85.7212524z"
      />
      <path
        fill={colors[1]}
        d="M51.3163452,82.9190216c-0.5769272,0.0824203-1.1538582,0.0824203-1.6483688,0.0824203 c-0.7417641,0-1.4835281-0.0824203-2.3077126-0.1648331c-0.4945107-0.0824203-0.9890213-0.1648331-1.483532-0.247261 l0.2472572,3.2143173l0.3296738,3.9560928l0.2472534,3.131897l2.3901367,2.5549698l2.6373901-2.7198105l0.3296738-2.7198105 l0.4945068-4.2033386l0.4120941-3.131897C52.3877831,82.7541809,51.8108597,82.8366089,51.3163452,82.9190216z"
      />
      <path
        fill={colors[2]}
        d="M61.0417175,76.737648c0,0-6.3462181,2.0604553-11.4561615,2.0604553 s-12.9396896-1.8132019-12.9396896-1.8132019l-0.1648369,2.3901367c0,0,0.0824203,0,0.1648369,0.0824127 c0.2472572,0.0824203,0.7417679,0.3296814,1.483532,0.6593552c0.576931,0.2472534,1.2362747,0.4945068,2.0604591,0.7417679 c0.4120941,0.1648331,0.906601,0.3296738,1.4011154,0.4945068c0.4120903,0.1648331,0.906601,0.3296738,1.4011116,0.4120941 c0.9890213,0.3296738,1.9780426,0.5769272,2.9670639,0.7417603c0.4945068,0.0824203,0.9890213,0.1648407,1.4835281,0.247261 c0.8241844,0.0824203,1.5659523,0.1648331,2.3077164,0.1648331c0.4945068,0,1.0714378-0.0824127,1.6483688-0.0824127 c0.5769272-0.0824203,1.0714378-0.1648407,1.648365-0.247261c0.7417679-0.1648331,1.483532-0.3296738,2.1428795-0.5769272 c0.7417679-0.2472534,1.4011116-0.4120941,2.0604591-0.6593475c0.4120903-0.1648331,0.7417679-0.247261,1.0714378-0.4120941 c0.6593475-0.2472534,1.3186951-0.5769272,1.8132057-0.8241806c0.576931-0.247261,1.0714378-0.4945145,1.3186951-0.6593552 c0.1648369-0.0824127,0.2472534-0.0824127,0.2472534-0.0824127L61.0417175,76.737648z"
      />
    </g>
  )
}

export default PlateArmor
