import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const StaffRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("StaffRight") ? (
    <g id="StaffRight">
      <path
        id="S_x24_staffRight"
        d="M17.9,119.4v-1.6v-1v-0.1H18l0.3-2.6c0,0,0.2-0.5,0.5-1c0-0.3,0-0.7,0.1-0.9l0.2-15h0.5l-0.5-0.6 v-0.2c0.1-0.6,0.1-1.1,0.1-1.5l0,0l0,0v-0.1l-0.1-0.4c-1.1-1.6-1.6-3.5-1.7-5.4c0-0.5,0-1,0-1.4c0.2-1.9,0.8-3.4,1.2-4.5 c0.1-0.2,0.2-0.4,0.2-0.6c0.2-0.7,0.4-1.5,0.6-2.3c0.2-1.2,0.3-2.6,0.4-4.3c0-0.3,0-0.6,0-0.9v-0.7c0-0.7,0-1.4-0.1-2.1v-0.4 c-0.1-1-0.1-2.1-0.2-3.1l-0.1-1.5h0.2l-0.2-0.4v-8.6l0,0l0.1-1.2l0.5,0.1l0.5-0.9l-0.1-0.4h-0.1v-0.5l0,0v-0.1c0-0.9,0-1.8,0-2.5 c0-0.3,0.1-0.9,0-1v-0.3c-0.1-0.4-0.1-0.8-0.2-1.2H20l-0.1-0.7v-0.3v-0.1h-0.1l-0.3-0.9V48l0,0v-0.2c-0.1-0.5-0.2-1-0.3-1.5v-0.4 l-0.1-0.4h0.1v-1l-0.3-0.1v-0.3c-0.1-0.9-0.1-1.6-0.2-2.2v-0.1c-0.3-0.3-0.6-0.7-0.9-1.1l-0.3-0.1v-0.1l-0.1-0.1l-2.1-1.8 c-0.3-0.4-0.6-0.8-0.9-1.3c-0.8-1.4-1.2-2.9-1.2-4.4c-0.2-2.9,1-5.7,3.1-7.7c1.4-2,3.6-3.2,6.1-3.2c0.6,0,1.1,0,1.6,0.1 c0.2,0,0.3,0,0.5,0h0.3c2.5,0.3,4.8,1.3,6.7,3l0.7,0.7c1,1.1,1.7,2.5,2,3.9l0.1,0.1l0.1,0.1c0.9,1.8,1.4,3.9,1.4,6.3l0,0 c0,0.7,0,1.4-0.1,2.2l0.2,0.6c-0.1,0.8-0.3,1.5-0.5,2.3l0.1,1.3c0,1.4,0.2,2.9,0.5,4.6c0.2,1.3,0.4,2.8,0.6,4.3v0.7v0.1l0,0 c0,1.8,0,3.6-0.2,5.4l-0.1,0.6c-0.1,0.6-0.2,1.2-0.3,1.8l-0.2,0.7c0,0.3-0.1,0.6-0.1,0.8l0,0v0.1l-0.1,0.4 c-0.1,0.6-0.1,1.3-0.1,2.1c0,0.9,0.1,1.8,0.2,3v0.4v0.3l0,0V68h-0.2l-0.7,0.4h0.6c0,0.8,0,1.5,0,2.1l0.2,0.2c0,0.5,0,1,0.1,1.4v1 c0,0.4,0,0.9-0.1,1.3c0,0.3,0,0.6,0.1,1.3l0.1,1.4v0.4l0,0v-0.1v0.1h-0.1v0.2h0.1l0.2,2.4v0.2L35.1,83L35,82.9l-0.1,0.6 c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.8,0.5-1.3,0.5-1.4c0,0.1,0,0.2,0,0.3c0,0.4-0.1,1.1-0.3,1.9c-0.2,1-0.5,1.9-0.7,2.7 c0.1,0.6-0.1,1-0.1,1.3l-0.1,0.3c-0.1,0.5,0,0.9,0.4,2.5c0,0,0.2,0.8,0.3,1.1v0.2l0,0l0.1,0.3c0.7,3.2,0.2,21.6,0.2,21.8l-0.3,1.4 c-0.5,1.5-1,2.8-1.5,3.8l0,0H17.9z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(StaffRight, {
  character: "ManDwarf"
})
