import React from "react"

const SlingShotLeft = ({
  colors = ["#362812", "#454545", "#949494", "#724E1F"]
}) => {
  return (
    <g id="SlingShotLeft">
      <path
        fill={colors[0]}
        d="M67.5,88l1.1-10l6.2-2.9c0,0,2.2,2,2.1,4.6c0,0.4,2.1,34.2,2.1,34.2s-0.2,2.4-1.2,2.8s-3.2-0.4-3.2-0.4 l-0.5-2.8l0.5-34.2l1.2-1.9l-1.1-1.5l-5.3,2.8l1,2.3l-0.1,8.7L68,92.4l0.5-5.5L67.5,88z"
      />
      <path
        fill={colors[1]}
        d="M79.2,112.4c-0.8-1.1-1-1.8-2.5-1.6c-0.2,0-1.9,0.1-2,0.3c-0.5,0.8-1.2,2.7-1.2,2.7s1.1,2.1,2.5,2.2 c1,0.1,3.1-0.9,3.1-0.9S79.6,112.9,79.2,112.4z"
      />
      <path
        fill={colors[2]}
        d="M76.9,112.1c-0.2-0.4-0.4-0.9-0.6-1.3c-0.6,0-1.4,0.1-1.6,0.3c-0.5,0.8-1.2,2.7-1.2,2.7s0.4,0.8,1.1,1.4 c0.7-0.2,1.4-0.6,2.1-1.1C76.7,113.5,76.8,112.8,76.9,112.1z"
      />
      <path
        fill={colors[3]}
        d="M75.8,115.7l1.4-0.1l0.2-2.4l-0.6-2.6l-1.3-32.3l-3.8-1.8l2.1-1l1-0.5l1.4,0.7l0.9,2.1l2,36 c0,0-0.4,2.8-1.7,2.4c-0.2-0.1-1.8,0-2.4-0.4C75.6,115.5,75.8,115.7,75.8,115.7z"
      />
    </g>
  )
}

export default SlingShotLeft
