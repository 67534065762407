import React from "react"

const MaceL = ({
  colors = ["#58595A", "#B9BABA", "#767575", "#9C9D9E", "#E1E2E3", "#E4E4E5"]
}) => {
  return (
    <g id="MaceL">
      <path
        opacity="0.53"
        d="M68,91.0999985l0.8000031-1.5l0.0999985-0.3000031L69,88v-0.1999969 l9.1999969-23.5c0.3000031,0.0999985,0.5999985,0.0999985,0.5999985,0.0999985l0.5-0.3000031l0.5-1.2999992 c0.1999969-0.2000008,1-1.0999985,3.0999985-1.2999992l0.1999969-0.5999985l0.1999969-0.0999985l0,0h0.0999985 l-0.0999985-0.0999985l0,0v-0.0999985c-0.0999985-0.0999985-0.0999985-0.4000015,0.0999985-1c0.1999969-0.5,0.5-1,0.5-1l0,0 l0.0999985-0.0999985v-0.4000015l-1.8000031-4.2000008l-0.4000015-0.2999992c0,0-0.0999985,0-0.0999985-0.0999985 l0.3000031-1.2999992l-0.9000015-0.4000015L80.4000015,53l0,0l-0.5999985-0.0999985L75.5,54.6000023L75.1999969,55l0,0l0,0l0,0 l0,0c0,0-0.0999985,0.5999985-0.3000031,1.0999985c-0.1999969,0.5999985-0.5,0.7999992-0.5,0.7999992l0,0V57l0,0h-0.1999969 l0.0999985,0.2000008l-0.0999985,0.0999985l-0.3000031,0.5l0.0999985-0.0999985l-0.0999985,0.2000008 c1.4000015,1.5,1.4000015,2.7000008,1.4000015,3l-0.5,1.2999992l0.1999969,0.5999985l0,0c0,0,0.1999969,0.0999985,0.5,0.2999992 l-9.1999969,23.5l-0.9000015,1.1999969L65.3000031,88l0,0v0.0999985l-0.4000015,1.6999969l0.1999969,0.5 c0.0999985,0.0999985,0.8000031,0.5,1.1999969,0.6999969c0.4000015,0.1999969,1.3000031,0.3000031,1.4000015,0.3000031 L68,91.0999985z"
      />
      <path
        fill={colors[0]}
        d="M75.6999969,55.2000008l4.1999969-1.7999992c0.3000031,0.2000008,0.5,0.0999985,0.6999969,0 l0.8000031-1.2999992l-0.3000031,1.5c0.0999985,0.2999992,0.1999969,0.4000015,0.5999985,0.5l1.8000031,4.2000008 c0,0-1.3000031,2.0999985-0.5999985,2.7999992c-2.5999985,0.2000008-3.5,1.5-3.5,1.5L78.9000015,64L78,63.9000015l-9.4000015,24 L68.5,89.5L67.6999969,91l-1.3000031-0.3000031L65.1999969,90l0.4000015-1.6999969l1-1.1999969l9.4000015-24 l-0.8000031-0.5999985l0.5-1.4000015c0,0,0.1999969-1.5-1.5-3.5 C75.1999969,57.5999985,75.6999969,55.2000008,75.6999969,55.2000008z"
      />
      <path
        fill={colors[1]}
        d="M74.1999969,57.7000008c1.6999969,1.9000015,1.5,3.5,1.5,3.5l-0.5,1.4000015L76,63.2000008 l-9.4000015,23.9999962l-1,1.1999969L65.1999969,90c0,0,0.6999969,0.5,1.0999985,0.5999985 c0.4000015,0.1999969,1.3000031,0.3000031,1.3000031,0.3000031l0.8000031-1.5L68.5,87.8000031l9.4000015-24l0.9000015,0.0999985 l0.5-1.4000015c0,0,0.9000015-1.2999992,3.5-1.5c-0.6999969-0.7000008,0.5999985-2.7999992,0.5999985-2.7999992L81.5999985,54 c-0.3000031,0-0.5-0.2000008-0.5999985-0.5l0.3000031-1.5L80.5,53.2999992c-0.1999969,0.0999985-0.5,0.2000008-0.6999969,0 l-4.1999969,1.7999992C75.5999985,55.2999992,75.1999969,57.7000008,74.1999969,57.7000008z M78.4000015,62l1.9000015-2.4000015 l0.8000031-2.2000008l0.3000031-2.7999992l1,3.4000015l-0.8000031,2.2000008l-2.8000031,1.9000015L78.4000015,62z M75.8000031,58l0.9000015-2.2000008l3-1.7999992L78,56.2000008l-0.9000015,2.2000008l-0.1999969,3L76.5,61.2000008 L75.8000031,58z"
      />
      <path
        fill={colors[2]}
        d="M75.1999969,62.5999985c0,0,0.6999969,0.5999985,1.6999969,1s1.9000015,0.5,1.9000015,0.5l0.5-1.2999992 c0,0-1.4000015-0.4000015-1.9000015-0.5999985c-0.5-0.2000008-1.8000031-0.7999992-1.8000031-0.7999992L75.1999969,62.5999985z"
      />
      <path
        fill={colors[2]}
        d="M65.6999969,88.3000031c0,0,0,0.3000031,1.1999969,0.8000031s1.5,0.3000031,1.5,0.3000031 l0.1999969-1.5999985c0,0-0.5,0.0999985-1-0.0999985s-0.9000015-0.5999985-0.9000015-0.5999985L65.6999969,88.3000031z"
      />
      <g>
        <path
          fill={colors[3]}
          d="M75.9000015,58.0999985l0.6999969,3.0999985L77,61.3999977l0.1999969-2.7999992l2.9000015,1.0999985 l-1.6999969,2.2000008l0.4000015,0.2000008l2.5999985-1.7999992l1.3000031,0.5 C82.7000046,60.8999977,82.8000031,61,82.8000031,61c-2.5999985,0.2000008-3.5,1.5-3.5,1.5l-0.5,1.4000015l0,0l0.5-1.2999992 c0,0-1.4000015-0.4000015-1.9000015-0.5999985c-0.5-0.2000008-1.8000031-0.7999992-1.8000031-0.7999992l-0.5,1.2999992l0,0 l0.5-1.4000015c0,0,0.1999969-1.5-1.5-3.5c0.0999985,0,0.1999969,0,0.3000031-0.0999985L75.9000015,58.0999985z"
        />
        <path
          fill={colors[4]}
          d="M82.4000015,57.9000015L82.4000015,57.9000015l-1-3.4000015l-0.3000031,2.7999992v0.0999985 l-3-1.2000008l0.0999985-0.0999985l1.6999969-2.2000008l-3,1.7999992l0,0l-1.0999985-0.4000015 c0-0.0999985,0-0.0999985,0-0.0999985l4.1999969-1.7999992c0.3000031,0.2000008,0.5,0.0999985,0.6999969,0l0.8000031-1.2999992 l-0.3000031,1.5c0.0999985,0.2999992,0.1999969,0.4000015,0.5999985,0.5l1.8000031,4.2000008c0,0,0,0-0.0999985,0.0999985 L82.4000015,57.9000015z"
        />
        <polygon
          fill={colors[5]}
          points="77,63.7000008 67.8000031,87.5999985 67.3000031,87.5 76.5999985,63.7000008"
        />
      </g>
    </g>
  )
}

export default MaceL
