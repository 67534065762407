import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FrontShieldL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FrontShieldL") ? (
    <g id="FrontShieldL">
      <path
        id="_x3C_path_x3E_"
        d="M82.6999969,86.5C79,86.5,75.5,84.5999985,73,81.0999985l0,0 c-0.5999985-0.9000015-1.1999969-1.8000031-1.6999969-2.9000015L71.0999985,78c-0.1999969-0.5-0.4000015-1-0.6999969-1.5 c-2.1999969-5.5999985-3.0999985-13.0999985-2.1999969-20.0999985c1.5-12.5,8-25.8999996,18.7000046-25.8999996 c1.4000015,0,2.9000015,0.2999992,4.1999969,0.7999992C102.8000031,35.5,103.5000076,50,102.0000076,61.4000015 c-0.1999969,1.7999992-0.5,3.5999985-0.9000015,5.4000015l0,0l0,0v0.0999985 c-0.1999969,0.9000015-0.4000015,1.6999969-0.5999985,2.5l-0.0999985,0.1999969 C97.1000061,80.6999969,90.5000076,84.6999969,85.5000076,86C84.5999985,86.4000015,83.5999985,86.5,82.6999969,86.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FrontShieldL, {
  character: "WomanMixed"
})
