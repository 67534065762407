import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FrontShieldL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FrontShieldL") ? (
    <g id="FrontShieldL">
      <path
        id="_x3C_path_x3E_"
        d="M89.7,85.5c-3.7,0-7.2-1.9-9.7-5.4l0,0c-0.6-0.9-1.2-1.8-1.7-2.9L78.1,77c-0.2-0.5-0.4-1-0.7-1.5 c-2.2-5.6-3.1-13.1-2.2-20.1c1.5-12.5,8-25.9,18.7-25.9c1.4,0,2.9,0.3,4.2,0.8c11.7,4.2,12.4,18.7,10.9,30.1 c-0.2,1.8-0.5,3.6-0.9,5.4l0,0l0,0l0,0.1c-0.2,0.9-0.4,1.7-0.6,2.5l-0.1,0.2c-3.3,11.1-9.9,15.1-14.9,16.4 C91.6,85.4,90.6,85.5,89.7,85.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FrontShieldL, {
  character: "ManMixed"
})
