import React from "react"

const BroadSwordL = ({ colors = ["#898888", "#FFFFFF"] }) => {
  return (
    <g id="BroadSwordL">
      <path
        fill={colors[0]}
        d="M98.6-5.7c-0.3-2.9-2.5-6.3-2.5-6.3l-0.5,0.8l-0.9,4.3L94,52.3l1.1,1.3l2.6,0.8 C97.6,54.4,98.9-2.8,98.6-5.7z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M95.1,53.9c0.2-0.5,1.1-65.7,1.1-65.7s2.2,3.4,2.5,6.3 s-1,60.2-1,60.2L95.1,53.9z"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="95.7,-11 94.8,-6.7 94,52.6 95.1,53.9"
      />
      <path
        fill={colors[0]}
        d="M101.7,54.7l-0.1-2.1l-9.5-1.2l-0.6,0.1l0.2,1.9l2.8,1.1c0,3.2-0.2,8.3-0.2,8.3l1.8,1.3l1.5-0.6l-0.2-8.7 L101.7,54.7z"
      />
    </g>
  )
}

export default BroadSwordL
