import React from "react"

const BardVest = ({
  colors = [
    "#8670A2",
    "#D8A16C",
    "#SVGID_",
    "#FFFFFF",
    "#AC7B56",
    "#F9BE7C",
    "#C7AA2E"
  ]
}) => {
  return (
    <g id="BardVest">
      <path
        fill={colors[0]}
        d="M32.9,67.9c0.7,3.8,1.5,6.4,1.5,6.4l-1.8,8.4l16.7-1.2l-1.3-13l-6.5-15.9l-5,0.9c0,0-2.2-0.9-5.8-2.1 C32,54.3,33.5,63.1,32.9,67.9z"
      />
      <path
        fill={colors[0]}
        d="M63.9,67.9c-0.7,3.8-1.5,6.4-1.5,6.4l1.8,8.4l-15.7-1.1l0.1-13.1l6.6-15.8l5,0.9c0,0,2.2-0.9,5.8-2.1 C64.7,54.3,63.2,63.1,63.9,67.9z"
      />
      <circle fill={colors[1]} cx="46.7" cy="69.8" r="0.5" />
      <circle fill="url(#SVGID_7_)" cx="46.7" cy="69.7" r="0.5" />
      <radialGradient
        id="SVGID_7_"
        cx="-518.337"
        cy="84.317"
        r="0.529"
        gradientTransform="rotate(180 -235.84 76.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.662" stopColor="#ac7b56" />
        <stop offset="1" stopColor="#f9be7c" />
      </radialGradient>
      <circle fill={colors[1]} cx="46.7" cy="73.6" r="0.5" />
      <circle fill="url(#SVGID_8_)" cx="46.7" cy="73.6" r="0.5" />
      <radialGradient
        id="SVGID_8_"
        cx="-518.34"
        cy="80.43"
        r="0.529"
        gradientTransform="rotate(180 -235.84 76.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.662" stopColor="#ac7b56" />
        <stop offset="1" stopColor="#f9be7c" />
      </radialGradient>
      <g>
        <circle fill={colors[1]} cx="46.7" cy="77.5" r="0.5" />
        <circle fill="url(#SVGID_9_)" cx="46.7" cy="77.5" r="0.5" />
        <radialGradient
          id="SVGID_9_"
          cx="-518.334"
          cy="76.543"
          r="0.529"
          gradientTransform="rotate(180 -235.84 76.994)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.662" stopColor="#ac7b56" />
          <stop offset="1" stopColor="#f9be7c" />
        </radialGradient>
      </g>
      <g>
        <path
          fill={colors[6]}
          d="M30.5,51.9c2.2,5.1,2.9,10.9,2.2,16.4c0,0.1,0.5-0.1,0.5-0.3c0.7-5.5,0-11.3-2.2-16.4 C31,51.5,30.5,51.8,30.5,51.9L30.5,51.9z"
        />
      </g>
      <g>
        <path
          fill={colors[6]}
          d="M66.4,52.1c-2.2,5.1-2.9,10.9-2.2,16.4c0,0.1-0.5-0.1-0.5-0.3c-0.7-5.5,0-11.3,2.2-16.4 C65.9,51.7,66.4,52,66.4,52.1L66.4,52.1z"
        />
      </g>
      <polygon
        opacity="0.42"
        fill="#21376C"
        points="49.3,81.6 48.6,74.2 50.4,81.7"
      />
    </g>
  )
}

export default BardVest
