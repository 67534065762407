import React from "react"

const Sash = ({ colors = ["#9B883A"] }) => {
  return (
    <g id="Sash">
      <path
        opacity="0.56"
        fill="#21376C"
        d="M56.9,54.8c-0.2,0.4-0.6,7.1-0.6,7.1s6.8,0.6,12.9,0.6 S81.9,62,81.9,62L81.4,55c0,0-15.4,1-12.5,1C71.7,56,56.9,54.8,56.9,54.8z"
      />
      <path
        fill={colors[0]}
        d="M56.9,55.2c-0.2,0.3-0.6,6.5-0.6,6.5s6.8,0.5,12.9,0.5s12.8-0.5,12.8-0.5l-0.5-6.4c0,0-15.4,0.9-12.5,0.9 C71.7,56.2,56.9,55.2,56.9,55.2z"
      />
      <g opacity="0.4" fill="#21376C">
        <path
          opacity="0.56"
          d="M80.7,56.5c-1,1-8.8,1.6-9.3,1.8c0.3,0.2,7.1,1.6,9.3-0.2 c0.3,0,0.6,0,0.9,0l-0.2-2.8c0,0-4,1.2-8.2,1.2C73.9,56.6,78.4,57.2,80.7,56.5z"
        />
        <path
          opacity="0.56"
          d="M67.5,59.8c0.1,0.2,5.2,1.4,10.8-0.8 C78,59,72.8,59.8,67.5,59.8z"
        />
        <path
          opacity="0.56"
          d="M81.7,59.1c-0.4,0-1.2,0.9-7.3,1.3c-0.1,0.3,5.1-0.2,5-0.2 c-1.9,1.2-7.5,1.1-7.6,1.2c0.1,0.3,6.9,0,6.8,0.1c1.1,0,2.3,0.1,3.4,0.1L81.7,59.1z"
        />
        <path
          opacity="0.56"
          d="M66.9,61.5c-0.1-0.1-6.8-0.5-6.8-0.6c-0.5,0-2.2-0.3-2.7-0.3 c-0.6,0,6.4,0.2,7.2,0.2c-1.3-0.7-8.8-0.7-7.8-3.5c4,1.1,13.7,0.2,14.9,0.1c0,0-7.2,0.8-14.9-2.3c-0.1,1.3-0.6,6.5-0.6,6.5 s0.2,0,0.4,0C57.9,61.5,65.7,61.7,66.9,61.5z"
        />
      </g>
    </g>
  )
}

export default Sash
