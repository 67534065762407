import { call, put, takeLatest } from 'redux-saga/effects'
import api from '../api'

import { push } from 'connected-react-router'

import {
  setCharacterState,
  REMIX_CHARACTER
} from '../actions/builderActions'

import {
  characterSelector,
  skinColorSelector
} from '../selectors/builderSelectors'

const queryString = require('query-string')

export function* remixMiniSaga() {
  yield takeLatest(REMIX_CHARACTER, remixMiniWorkerSaga)
}

export function* remixMiniWorkerSaga(action) {
  try {
    const response = yield call(api.minis.file.get, action.character_id)
    const character = response.data[0]
    const selectedCharacter = characterSelector(character.settings)
    const skinColor = skinColorSelector(character.settings)
    const search = character.settings.search || `?${queryString.stringify({ character: selectedCharacter, skinColor })}`

    yield put(push(`/builder${search}`))
    yield put(setCharacterState({
      character: selectedCharacter,
      enabledElements: character.settings.builderProps.enabledElements[selectedCharacter],
      colors: character.settings.builderProps.colors
    }))
  } catch (error) {
    console.log(error)
  }
}