import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaulL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaulL") ? (
    <g id="MaulL">
      <path d="M86,88.1999969c-0.9000015,0-1.6999969-0.0999985-2.5999985-0.4000015c-2.0999985-0.5999985-3.9000015-2-5-3.8000031 C78,83.3999939,77.8000031,82.7999954,77.5999985,82.0999908L77.5,81.6999893l0,0 c-0.0999985-0.4000015-0.1999969-0.8000031-0.1999969-1.1999969v-0.4000015c0-0.4000015,0-0.5999985,0-0.9000015v-0.0999985l0,0 v-0.1999969l0.0999985-0.5c0.3000031-2.4000015,2.1999969-18.9000015,3.4000015-29.2999992 c-0.4000015-0.2000008-0.5999985-0.2999992-0.5999985-0.2999992l-3.8000031-6.2000008h-0.0999985 c0-0.0999985,0-0.2000008,0-0.2999992v-0.0999985v-0.2999992c-0.0999985-1.0999985-0.0999985-3.0999985,0.1999969-7 l0.0999985-2.6000023l3.5999985-5.9999981c1.6999969-0.8999996,3.3000031-0.8999996,3.9000015-0.8999996 c0.6999969,0,1.8000031,0,3.3000031,0.1000004c1.0999985,0,2.4000015,0.1000004,3.6999969,0.2000008L93,26 c5.5999985,1.7000008,12.5,4.7999992,12.8000031,4.8999996l3.9000015,8.8000011 c-1.1999969,3.5999985-2.6999969,8.2000008-2.9000015,9.1000023c0.0999985-0.2000008,0.3000031-1.4000015-0.0999985-2.9000015 L105.4000015,52C104,53.7000008,102.2000046,54.9000015,97.7000046,55.2000008 C96.6000061,65.4000015,95.0000076,78.5,94.8000031,80.5C94.7000046,82.0999985,94,83.6999969,92.9000015,85 C91,87.0999985,88.5999985,88.1999969,86,88.1999969z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaulL, { character: "WomanMixed" })
