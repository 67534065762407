import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Antlers = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Antlers") ? (
    <g id="Antlers">
      <path d="M93.4,2.9c-0.6-0.7-1.8-4.8-1.9-5.8c-0.2-1.4-0.7-2.8-1.4-4c-1.2-2.1-9.9-5.3-12-3.9c-0.9,0.6-7.7,6.3-8.8,6.3 c-0.9,0-9.1-5.9-9.7-6.3C57.2-12.4,49.3-9.1,48.2-7c-0.7,1.2-1.1,2.4-1.4,3.8c-0.1,0.8-0.2,1.6-0.2,2.4c0,0.6-1.2,3-1.8,3.7 c-0.8,0.9-2.7,4.5-1.5,6.5c0.6,1.1,1.7,1.7,2.4,2.7c0.6,0.8,0.9,1.8,1.6,2.7c2.7,3.4,12.1,4.2,12.5,4.1c0.8-0.1,22,0,22.3,0 c2.6-0.2,5.3-0.9,7.4-2.6c0.8-0.7,1.6-1.5,2.2-2.4c0.4-0.6,0.6-1.3,1-1.8s1-0.8,1.4-1.3C95.6,8.8,94.2,3.8,93.4,2.9z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Antlers, { character: "ManMixed" })
