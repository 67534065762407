import React from "react"

const FlameRight = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameRight">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M43.745903,90.5262299c-1.7999992-0.6000061-3.5-1.1999969-5-1.9000092c-0.2999992-0.5-0.7000008-1-1-1.5 c-0.7999992-1.3000031-1.5-2.5999908-2.1000023-4c0.1000023-0.3999939,0.2999992-0.8000031,0.4000015-1.1999969 c0.2000008-0.5,0.5-1,0.7000008-1.5c0.3999977-0.6999969,0.7999992-1.5,1.0999985-2.3000031l1-0.1999969l-0.5-0.6999969 L38.245903,77.126236c2.0999985-1.3000031,3.3999977-2.6999969,3.7999992-4.1999969 c0.0999985-0.5,0.2000008-1.1000061,0.0999985-1.6000061l0.6000023-0.0999908c0.2000008,0.3999939,0.3999977,0.6999969,0.5,1 c0.5999985,1.1999969,1.5,2.3000031,2.2999992,3.3999939c1,1.3000031,1.8999977,2.6000061,2.5,3.9000092 c1.4999962,3.3000031,1.1999969,6.5-1,10.3000031C45.9459038,90.3262329,44.8459015,90.5262299,43.745903,90.5262299z"
        />
        <path
          fill={colors[1]}
          d="M43.6459007,90.626236c-1.5-0.5-3-1-4.2999992-1.6000061 c-0.2999992-0.3999939-0.5999985-0.8000031-0.7999992-1.3000031c-0.7999992-1.1999969-1.3999977-2.3999939-2-3.8000031 c0.1000023-0.3999939,0.2000008-0.6999969,0.4000015-1c0.2000008-0.5,0.5-1,0.7000008-1.3999939 c0.2999992-0.6000061,0.7000008-1.3000031,1-2l1.5-0.1999969l-0.8000031-1.1000061 c1.7999992-1.1999969,2.9000015-2.5,3.4000015-3.8000031c0.5,0.8000031,1.0999985,1.5,1.5999985,2.3000031 c0.9000015,1.3000031,1.7999992,2.5,2.4000015,3.8000031c1.5,3.1000061,1.2000008,6.1000061-0.9000015,9.6999969 C45.1459007,90.4262238,44.4459038,90.626236,43.6459007,90.626236z"
        />
        <path
          fill={colors[2]}
          d="M43.5459023,90.7262268c-1.2000008-0.3999939-2.3999977-0.8000031-3.5-1.3000031 c-0.2000008-0.3000031-0.5-0.6999969-0.7000008-1c-0.7000008-1.0999908-1.2999992-2.3000031-1.8999977-3.5 c0.0999985-0.3000031,0.2000008-0.5,0.2999992-0.8000031c0.2000008-0.5,0.5-1,0.7000008-1.3999939 c0.2999992-0.5,0.5999985-1.1000061,0.7999992-1.6999969l2.0999985-0.3000031l-1-1.4000092 c1-0.6999969,1.7999992-1.3999939,2.4000015-2.1999969c0.2000008,0.1999969,0.2999992,0.4000092,0.5,0.6999969 c0.8999977,1.3000031,1.7999992,2.5,2.3999977,3.6999969c1.4000015,2.8000031,1.1000023,5.6999969-0.7999992,9.1000061 C44.4459038,90.5262299,44.0459023,90.626236,43.5459023,90.7262268z"
        />
        <path
          fill={colors[3]}
          d="M43.4459038,90.7262268c-0.9000015-0.3000031-1.7999992-0.5999908-2.7000008-1 c-0.2000008-0.3000031-0.2999992-0.5-0.5-0.8000031c-0.7000008-1.0999908-1.2000008-2.0999908-1.7999992-3.3000031 c0.0999985-0.1999969,0.0999985-0.3999939,0.2000008-0.5999908c0.2000008-0.5,0.5-0.9000092,0.7000008-1.4000092 s0.5-0.8999939,0.7000008-1.3999939l2.5999985-0.4000092l-1.2000008-1.5999908 c0.4000015-0.3000031,0.7999992-0.6000061,1.1000023-0.9000092c0.7000008,1,1.3999977,2,1.8999977,3 c1.2999992,2.6000061,1,5.3000031-0.5999985,8.4000092C43.745903,90.626236,43.6459007,90.7262268,43.4459038,90.7262268z"
        />
        <path
          fill={colors[4]}
          d="M43.1459007,90.4262238c-0.5-0.1999969-1.0999985-0.3999939-1.5999985-0.5999908 c-0.1000023-0.1999969-0.2000008-0.4000092-0.4000015-0.6000061c-0.5999985-1-1.2000008-2-1.7000008-3 c0-0.0999908,0.1000023-0.1999969,0.2000008-0.3999939c0.2000008-0.5,0.5-0.9000092,0.7000008-1.4000092 c0.2000008-0.3999939,0.4000015-0.8000031,0.6000023-1.1999969l2.5999985-0.3999939 C44.6459007,85.2262268,44.5459023,87.7262268,43.1459007,90.4262238z"
        />
        <path
          fill={colors[5]}
          d="M43.3459015,90.7262268c-0.0999985-0.0999908-0.0999985-0.1999969-0.2000008-0.3000031 c-0.5999985-0.8999939-1.0999985-1.8000031-1.5-2.8000031c0-0.0999908,0-0.0999908,0.1000023-0.1999969 c0.2000008-0.5,0.5-0.8999939,0.7000008-1.3999939c0.0999985-0.3000031,0.2999992-0.6000061,0.3999977-0.9000092l1-0.1999969 C44.4459038,86.8262329,44.245903,88.7262268,43.3459015,90.7262268 C43.3459015,90.8262329,43.3459015,90.7262268,43.3459015,90.7262268z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M51.745903,72.4262238c-1.4000015-2-1.5999985-3.8999939-3.5999985-5.5999908 c-0.1000023,0-1-0.7000046-1.1000023-0.7000046c-0.2000008,0.0999985-1.3999977,0.2000046-1.3999977-0.2000046 c0.2000008-1,1.7999992-1.2999954,1.2999992-2.1999969c-0.4000015-0.7000008-2.2000008-5-2.7000008-5.7999992 c-0.7999992,1.5999985-3.2999992,5.7000008-4.7000008,7.1000023c-0.0999985,0.0999908-2.0999985,5.1999969-2.7999992,7.5 c0.4000015-0.1000061,1.1000023-0.3000031,1-0.1000061c-1.0999985,3.1000061-4.0999985,5.6000061-5.2000008,8.6999969 c0,0.1000061-0.2000008,0.1999969-0.3999977,0.3000031c0.3999977,1.9000092,2.0999985,3.5,4,4.9000092 c-1-1.6000061-1.7999992-3.3000031-1.7999992-5.1000061c0-0.1999969,0.5999985-0.5,1.2000008-0.6999969 c0.2000008-2,0.5-3.8000031,2.2999992-5.5999908c2.2999992-2.1999969,3.3999977-5.7000046,3.3999977-5.7000046 c0.1000023-0.4000015,2.7000008-4.5,2.1000023-6.2999992c-0.2000008-0.5,0.5,0.9000015,0.5,1.2999992 c0.0999985,0,0.2000008,0,0.2000008,0.0999985c0,1.4000015,1,6.0999985,1.0999985,6.1999969 c2.2000008,1.6000061,2.2999992,1.9000092,3.2999992,3.9000092c1.2000008,2.5,1.9000015,4.0999908,2,6.6999969 c0,0.0999908-0.0999985,0.1999969-0.2000008,0.3000031c0.7999992,1.5-0.4000015,3.0999908-0.7999992,4.5999908 c-0.0999985,0.1999969-0.0999985,0.4000092-0.2999992,0.6999969c1-1.3999939,1.9000015-2.8999939,2.7000008-4.3999939 C51.8459015,79.0262299,51.8459015,75.7262268,51.745903,72.4262238z"
      />
    </g>
  )
}

export default FlameRight
