import React from "react"

const BattleAxeLeft = ({ colors = ["#7A7B7D", "#505052"] }) => {
  return (
    <g id="BattleAxeLeft">
      <path
        fill={colors[0]}
        d="M81.2,31c-0.2,0.5-0.7,1-1.2,1.2c-2.1,1-3.8,1.5-6.1,1.5c-0.3,0.3-1,0.7-1.4,1.1c0-0.2,0.1-3.8,0.1-4 c0,0,0.8-0.1,0.8-0.2c0,0-1.5-0.7-1.7-5.2c-0.5,4.5-1.9,5.2-1.9,5.2c0,0.2,0.8,0.2,0.8,0.2c0,0.2-0.1,4-0.1,4s-0.9-0.6-1.4-1.1 c-2.3-0.1-4-0.6-6-1.7c-0.5-0.2-1-0.7-1.1-1.2c0,0-9,12.6,2,21.7c-1.4-5.3-1.7-12.7,4.8-13.2c0.1,0,1.6-0.1,1.6-0.1l-0.2,4.6 c-0.1,0.2-0.4,0.3-0.7,0.7h0.9v1.4h-1c0,0.1,0,1.9,0,2c0.1,0,1,0,1.1,0c-0.3,19.6-0.8,51-1.1,70.6h-0.1c0,1.5,2.1,1.2,2.2,0h-0.1 c0.3-19.6,0.8-51,1.1-70.6c0.1,0,1,0,1.1,0c0-0.1,0-1.9,0-2h-1v-1.4h0.9c-0.1-0.2-0.5-0.5-0.7-0.7v-4.5c0,0,1.4,0.1,1.6,0.1 c6.5,0.7,6,8.1,4.4,13.3C89.8,43.8,81.2,31,81.2,31z"
      />
      <path
        fill={colors[1]}
        d="M69.2,33.6c-2.2-0.1-3.8-0.5-5.7-1.5c-2.1,3.5-5.7,11.4,0,17.4c-0.7-4.7,0-9.9,5.4-10.3 c0.1,0,1.2,0,1.5,0l0.2-4.4C70.2,34.5,69.5,33.9,69.2,33.6z"
      />
      <path
        fill={colors[1]}
        d="M74,33.7c2.2,0,3.8-0.4,5.7-1.3c2,3.5,5.3,11.5-0.5,17.4c0.9-4.7,0.3-9.9-5.1-10.5c-0.1,0-1.3,0-1.6,0 v-4.5C72.8,34.6,73.6,34,74,33.7z"
      />
      <rect
        x="70.9"
        y="45.4"
        transform="matrix(.01513 -.9999 .9999 .01513 22.76 118.096)"
        fill={colors[1]}
        width="0.8"
        height="4.1"
      />
      <rect
        x="71.2"
        y="42.5"
        transform="matrix(.01513 -.9999 .9999 .01513 25.759 115.24)"
        fill={colors[1]}
        width="0.3"
        height="4"
      />
      <path
        fill={colors[1]}
        d="M70.6,30.7c0,0-0.5,0-0.8-0.2c0.5-0.2,2.2-0.2,3.6,0c-0.1,0.1-0.8,0.2-0.8,0.2S71.7,30.4,70.6,30.7z"
      />
    </g>
  )
}

export default BattleAxeLeft
