import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongBowL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongBowL") ? (
    <g id="LongBowL">
      <path d="M92.5,113.6999969c3.0999985,0,5.8000031-1.9000015,6.9000015-4.8000031l-0.5-2.8000031 c0.0999985-0.3000031,0.1999969-0.5999985,0.1999969-0.9000015l0,0c8.5-12.5,11-30.2000046,11.6999969-42.7999992h-0.1999969 L111,61.9999886v-1.2999992c0-0.9000015,0-1.7999992,0-2.7000008c0-1.2999992,0.0999985-2.5999985,0.0999985-4 l-0.4000015-0.4000015h0.0999985c-0.1999969-4.9000015-0.6999969-9.7000008-1.4000015-14.1000023l-0.0999985-0.4000015l0,0l0,0 l-0.3000031-1.7000008c-0.4000015-2-0.8000031-4-1.1999969-5.8999996l0,0l0,0l0,0c-0.8000031-3.5-1.8000031-6.6999989-3-9.6999989 c-1.8000031-4.6000004-3.5-7.6999998-4.6999969-9.6000004c0-0.1000004,0-0.1000004,0-0.1999998c0,0,0,0.1000004,0,0.1999998 c-0.1999969-0.1999998-0.3000031-0.5-0.4000015-0.6999998C99.5999985,10.6999836,99.2999954,9.8999834,99,9.1999836 l0.4000015-2.1999998C98.3000031,4.0999837,95.5999985,2.1999836,92.5,2.1999836c-1.5999985,0-4.0999985,0.5-6.1999969,3.0999999 c-0.0999985,0.1999998-0.3000031,0.3000002-0.4000015,0.5c-0.1999969,0.3000002-0.5,0.5999999-0.6999969,1 c-0.3000031,0.4000001-0.5,0.8000002-0.6999969,1.2000003c-0.0999985,0.3000002-0.3000031,0.5-0.4000015,0.6999998 C84,8.6999998,84,8.8999996,83.9000015,9l0.4000015,3.8000002h-0.0999985 C84.1000061,14.1999998,84.3000031,15.5,84.8000031,16.6000004v82.8000031C84.2000046,100.5,84,101.8000031,84,103.2000046 h0.3000031l-0.4000015,3.8000031c0.0999985,0.0999985,0.1999969,0.3000031,0.3000031,0.5 c0,0,0.1999969,0.4000015,0.3000031,0.5999985l0,0C85.4000015,109.5999985,87.8000031,113.6999969,92.5,113.6999969 L92.5,113.6999969z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongBowL, {
  character: "ManDragonborn"
})
