import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const NobelCape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("NobelCape") ? (
    <g id="NobelCape">
      <path
        id="S_x24_nobelCape"
        d="M30.261261,112.9761353c-2.0731544,0-6.9968967-2.9306793-8.3574047-4.5355759 c-0.8422184-0.9768906-2.5266571-5.3729095-2.5266571-5.3729095c4.8589573-8.0942688,8.6813354-16.18853,11.920639-24.9805756 c-1.1013641-0.6279984-2.0083675-1.3257828-2.721014-2.1631165c-2.3323002-2.7213516-2.9801598-5.8613663-1.6844387-8.5129318 c1.360508-2.7213516,4.2758808-3.7680244,5.44203-4.1169128c0.9070053-0.8373375,28.9593811-1.0466728,29.8663864-0.2093353 c1.166153,0.3488922,4.0815239,1.3955612,5.4420357,4.1169128c1.295723,2.6515656,0.7126465,5.8613586-1.6844406,8.5129318 c-0.7126465,0.8373337-1.6196518,1.5351181-2.7210121,2.0933456c3.2393074,8.9316025,7.0616875,17.0258636,11.985424,25.1899033 c0,0-1.3605042,4.1169052-2.2027283,5.024025c-1.3605042,1.6048965-6.6081772,4.8146896-8.6813354,4.8146896 C62.9782333,112.9761353,31.621769,112.9761353,30.261261,112.9761353z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(NobelCape, { character: "ManGnome" })
