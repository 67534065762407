import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FrontShieldR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FrontShieldR") ? (
    <g id="FrontShieldR">
      <path
        id="_x3C_path_x3E__1_"
        d="M40.5430298,95.8823318c-1.3707962,0-2.6045151-0.1370773-3.9753151-0.5483246 c-6.8539867-1.7820358-15.9012527-7.2652283-20.424881-22.6181564l-0.1370811-0.2741547 c-0.2741604-0.9595566-0.5483189-2.0561981-0.8224792-3.289917c0-0.1370773,0-0.1370773-0.1370802-0.2741547l0,0l0,0 c-0.5483189-2.4674377-0.9595594-4.9348717-1.233717-7.5393867c-2.0561962-15.4900131-1.0966368-35.3665771,14.9416933-41.1239281 c1.7820358-0.6853981,3.8382301-0.9595585,5.7573509-0.9595585c14.6675339,0,23.5777168,18.3686848,25.6339149,35.3665771 c1.2337189,10.5551414-0.2741623,21.9327583-3.9753151,29.6092262l-0.1370773,0.2741547 c-0.6853981,1.5078735-1.5078773,2.7415924-2.3303566,3.9753113l0,0l0,0l0,0 C50.4127731,93.2778168,45.6149826,95.8823318,40.5430298,95.8823318L40.5430298,95.8823318z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FrontShieldR, {
  character: "ManDragonborn"
})
