import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WizardHat = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WizardHat") ? (
    <g id="WizardHat">
      <path
        id="S_x24_wizardHat"
        d="M35.6415672,60.7524567c-1.2404747-0.082695-2.3982506-0.082695-3.5560265-0.1653976 c-2.5636463-0.082695-4.7964973-0.1653976-6.3677673-0.3307953l-2.8117409-0.6615829 c-2.1501541-0.9096794-3.5560246-2.8117447-3.7214222-5.0445938c-0.1653957-2.0674591,0.6615868-4.7138023,3.7214222-6.2023697 l0.6615868-0.2480965c1.7366638-0.5788879,4.0522137-1.0750771,7.1947479-1.5712662 c1.9847603-0.3307915,3.7214241-0.4134903,5.3753891-0.4134903l0.4134903-2.646347c0,0,3.7214241-10.1718864,4.7138023-11.9085503 s5.7061806-2.8117409,5.7061806-2.8117409s4.7965012,1.2404747,5.3753891,3.7214241 c0.6615868,2.4809456,1.8193626,7.3601456,1.8193626,7.3601456l2.4809494,4.7964973 c0.6615829-0.0826988,1.653965-0.0826988,1.653965-0.0826988c2.0674553-0.0826988,3.8041191-0.2480927,5.2926903-0.3307915 c1.4885674-0.0826988,2.7290459-0.1653976,3.7214241-0.2480965c1.3231659-0.3307915,2.2328491-0.4134903,2.9771347-0.4134903 c2.7290421,0,5.1272888,1.7366638,5.8715744,4.300312c0.6615906,2.0674553,0.2481003,5.0445938-2.8117447,7.0293541 c0,0-1.2404709,0.6615829-1.3231659,0.7442856l-1.9020615,0.8269806c-2.0674591,0.8269844-8.104435,2.3982506-14.141407,2.9771385 c-0.082695,0-0.1653976,0-0.3307953,0c-0.7442856,0.082695-1.4885674,0.1653976-2.2328529,0.2480927 c-1.5712662,0.1653976-3.225235,0.3307953-4.961895,0.4961891c-3.7214241,0.2480927-8.5179214,0.5788879-12.487442,0.6615868 L35.6415672,60.7524567z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WizardHat, { character: "ManGnome" })
