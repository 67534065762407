import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import EyesRound from "./EyesRound"

const CATEGORY = "eyes"
const CHARACTER = "ManDwarf"
export const IDS = {
  EYES_ROUND: "EyesRound"
}

export const components = {
  [IDS.EYES_ROUND]: EyesRound
}

export const Group = ({ props }) => (
  <g id="eyes">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.EYES_ROUND,
    name: "Eyes Round",
    defaultColors: ["#010101"],
    colorable: true,
    component: components[IDS.EYES_ROUND],
    inUI: true,
    enabled: true,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
