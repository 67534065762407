import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LongSkirt from "./LongSkirt"
import Belt from "./Belt"

const CATEGORY = "skirts"
const CHARACTER = "WomanGnome"
export const IDS = {
  LONG_SKIRT: "LongSkirt",
  BELT: "Belt"
}

export const components = {
  [IDS.LONG_SKIRT]: LongSkirt,
  [IDS.BELT]: Belt
}

export const Group = ({ props }) => (
  <g id="skirts">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LONG_SKIRT,
    name: "Long Skirt",
    defaultColors: ["#6B6B6B", "#231F20"],
    colorable: true,
    component: components[IDS.LONG_SKIRT],
    inUI: true,
    enabled: false,
    subGroupId: 3
  },
  {
    id: IDS.BELT,
    name: "Belt",
    defaultColors: ["#996841", "#C49C2D"],
    colorable: true,
    component: components[IDS.BELT],
    inUI: true,
    enabled: false,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
