import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Cape = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Cape") ? (
    <g id="Cape">
      <polygon points="48.5781403,80.9704895 44.4881592,119 53.9881592,118.9000015 56.195446,82.7943497" />
      <polygon points="86.8792343,81.7214966 93.2701263,119 83.7701263,118.9000015 80.3347931,82.7943497" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Cape, { character: "WomanGnome" })
