import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import SolidColor from "./SolidColor"
import TatteredCape from "./TatteredCape"
import BehindRobe from "./BehindRobe"

const CATEGORY = "background"
const CHARACTER = "WomanDwarf"
export const IDS = {
  SOLID_COLOR: "SolidColor",
  TATTERED_CAPE: "TatteredCape",
  BEHIND_ROBE: "BehindRobe"
}

export const components = {
  [IDS.SOLID_COLOR]: SolidColor,
  [IDS.TATTERED_CAPE]: TatteredCape,
  [IDS.BEHIND_ROBE]: BehindRobe
}

export const Group = ({ props }) => (
  <g id="background">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.SOLID_COLOR,
    name: "Solid Color",
    defaultColors: ["#7F7F7F"],
    colorable: true,
    component: components[IDS.SOLID_COLOR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.TATTERED_CAPE,
    name: "Tattered Cape",
    defaultColors: ["#9B0000", "#000000", "#grad_2"],
    colorable: true,
    component: components[IDS.TATTERED_CAPE],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.BEHIND_ROBE,
    name: "Behind Robe",
    defaultColors: ["#3A0806"],
    colorable: true,
    component: components[IDS.BEHIND_ROBE],
    inUI: true,
    enabled: false,
    subGroupId: 2
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
