import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const MaulR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("MaulR") ? (
    <g id="MaulR">
      <path d="M49.5999985,87.1999969c0.9000015,0,1.7000008-0.0999985,2.5999985-0.4000015 c2.0999985-0.5999985,3.9000015-2,5-3.8000031c0.4000015-0.5999985,0.5999985-1.1999969,0.7999992-1.9000015l0.0999985-0.4000015 l0,0c0.0999985-0.4000015,0.2000008-0.8000031,0.2000008-1.1999969v-0.4000015c0-0.4000015,0-0.5999985,0-0.9000015v-0.0999985 l0,0v-0.1999969l-0.0999985-0.5C58,75,56.0999985,58.5,54.9000015,48.0999985 C55.3000031,47.8999977,55.5,47.7999992,55.5,47.7999992l3.7999992-6.2000008h0.0999985c0-0.0999985,0-0.2000008,0-0.2999992 v-0.0999985v-0.2999992c0.0999985-1.0999985,0.0999985-3.0999985-0.2000008-7l-0.0999985-2.6000004l-3.5999985-6 c-1.7000008-0.8999996-3.2999992-0.8999996-3.9000015-0.8999996c-0.7000008,0-1.7999992,0-3.2999992,0.1000004 c-1.0999985,0-2.4000015,0.1000004-3.7000008,0.2000008L42.7000008,25c-5.5,1.7999992-12.5,4.8999996-12.7000008,5 l-3.8999996,8.7999992C27.3000011,42.3999977,28.8000011,47,29,47.9000015C28.8999996,47.7000008,28.7000008,46.5,29.1000004,45 l1.2999992,6.0999985C31.7999992,52.7999992,33.5999985,54,38.0999985,54.2999992 C39.1999969,64.5,40.7999992,77.5999985,41,79.5999985c0.0999985,1.5999985,0.7999992,3.1999969,1.9000015,4.5 C44.5999985,86.0999985,47,87.1999969,49.5999985,87.1999969z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(MaulR, { character: "WomanMixed" })
