import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongBowRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongBowRight") ? (
    <g id="LongBowRight">
      <path d="M48.2592964,89.9645386c-0.213459-1.044693-0.3415298-1.6715088-0.4471321-1.9220123l-0.2314148-0.3336182 l-0.1056023-0.2505035l-0.0842514-0.1460342l-1.7547569-4.5932617l-0.1280785-0.6268158 c-0.1685066-0.2920609-0.4426155-0.8346329-0.9481354-1.7108154c-1.9378357-3.3587112-7.792881-13.3719559-3.5850983-22.3396149 l0.2695885-0.5448265c0.4144897-0.9009209,1.2648087-2.5982971,1.7208595-3.5621262l-0.0842514-0.1460304l0.1212959-1.004261 c0.5526085-2.8880997,2.8655014-4.8843155,5.6468697-4.8540535l4.5068741,2.6161308l0.1685104,0.2920609l0.6167679,2.48592 c0,0,19.004612,49.3351593,19.4460945,49.8979416l1.1391144,2.3791885l0.0640335,0.3134079l-1.5893936,5.0044861 c-1.6040726,1.4704742-4.4449692,2.2141876-7.1499939,0.4270325l-0.2516327-0.1662445l-0.6504211-0.5200882l-0.1055984-0.2504959 c-1.0053902-0.3931427-2.7858887-1.1176529-3.7283745-1.4692307l-0.5863838-0.2066803 c-0.9424896-0.3515778-1.7389374-0.7874146-2.5567398-1.327713 C50.9893036,102.792923,48.8996696,93.0986176,48.2592964,89.9645386z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongBowRight, {
  character: "WomanGnome"
})
