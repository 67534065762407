import React from "react"

const AlmondEyes = ({ colors = ["#78D1AD"] }) => {
  return (
    <g id="AlmondEyes" fill={colors[0]}>
      <path d="M67.5,15.7l-0.1-0.2c0,0-0.2-0.5-0.7-0.5s-0.8,0.3-1.5,0.7c0.4,0.1,0.8,0.3,1.1,0.3c0.5,0,0.7-0.2,1-0.2 C67.4,15.7,67.5,15.7,67.5,15.7z" />
      <path d="M70.7,15.7l0.1-0.2c0,0,0.2-0.5,0.7-0.5s0.8,0.3,1.5,0.7c-0.4,0.1-0.8,0.3-1.1,0.3c-0.5,0-0.7-0.2-1-0.2 C70.8,15.7,70.7,15.7,70.7,15.7z" />
    </g>
  )
}

export default AlmondEyes
