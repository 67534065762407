import React from "react"

const LuteLeft = ({
  colors = ["#94712B", "#D9A53F", "#695020", "#B78C34", "#8F6D2B"]
}) => {
  return (
    <g id="LuteLeft">
      <path
        fill={colors[0]}
        d="M74.3,71h-0.8l-0.1,0.4l-0.5,0.1l-0.4-0.7v-0.6l0.7,0.1l0.2,0.3l1,0.1C74.4,70.8,74.5,71,74.3,71z"
      />
      <path
        fill={colors[0]}
        d="M74.4,70.1l-0.9,0.1v0.2l-0.4-0.2l-0.4-0.4l-0.1-0.5l0.5,0.2l0.4,0.3l1,0.1C74.5,70,74.6,70.1,74.4,70.1z"
      />
      <path
        fill={colors[0]}
        d="M74.5,69.4h-0.8l-0.1,0.2l-0.5-0.1l-0.4-0.4l-0.1-0.5l0.5,0.2l0.4,0.3l1,0.1 C74.6,69.2,74.7,69.4,74.5,69.4z"
      />
      <path
        fill={colors[0]}
        d="M74.1,72.2l-0.9,0.1l-0.1,0.2h-0.5l-0.4-0.6l-0.1-0.5l0.5,0.2l0.4,0.3l1,0.1C74.1,72,74.2,72.2,74.1,72.2z"
      />
      <path
        fill={colors[1]}
        d="M66.5,85.6c-5.3-0.2-15.8,14.4-16.7,17.5c-0.9,3.2-1.2,6.8,3.2,9.1s10.4-2,12.1-4.5 C67,105.2,70.1,86.2,66.5,85.6z"
      />
      <path
        fill={colors[2]}
        d="M76.4,70.9l-0.1-1l-8.6,17c0,0,3.1,28-14.6,25.4c0,0,11.3,6.6,16.2-8.7c1.2-7.2-0.4-15.8-0.4-15.8"
      />
      <path
        fill={colors[0]}
        d="M76,73.8l0.8,0.3v0.2l0.4,0.2l0.5-0.3l0.2-0.4h-0.7h-0.3l-1-0.1C75.9,73.6,75.8,73.8,76,73.8z"
      />
      <path
        fill={colors[0]}
        d="M76.2,71.6l0.8,0.3v0.4l0.4,0.2l0.5-0.5l0.2-0.6h-0.7l-0.3,0.2H76.2C76.2,71.4,76.1,71.5,76.2,71.6z"
      />
      <path
        fill={colors[0]}
        d="M76.3,70.7l0.8,0.3v0.3l0.4-0.1l0.5-0.3l0.2-0.4h-0.6l-0.5,0.2l-1-0.1C76.3,70.5,76.2,70.7,76.3,70.7z"
      />
      <path
        fill={colors[0]}
        d="M76.5,70l0.8,0.2v0.2l0.5,0.1l0.5-0.3l0.2-0.4h-0.6L77.4,70l-1-0.1C76.5,69.8,76.3,69.9,76.5,70z"
      />
      <path
        fill={colors[0]}
        d="M76.2,72.8l0.8,0.3v0.3l0.5,0.1L78,73l0.2-0.4h-0.6l-0.5,0.2H76.2C76.1,72.6,76,72.8,76.2,72.8z"
      />
      <polygon
        fill={colors[3]}
        points="64.7,86.1 74.2,68.9 76.6,69.5 67.7,86.9"
      />
      <polygon
        fill={colors[4]}
        points="51.4,104.9 56.8,107.6 57.2,106.4 52,103.8"
      />
      <polygon
        fill={colors[0]}
        points="76.3,70.1 76.4,70.9 75.7,74.3 74.8,74.1"
      />
      <polygon
        fill={colors[2]}
        points="76,73.8 75.7,74.3 76.2,69.8 76.6,69.5"
      />
      <ellipse
        transform="rotate(-58.149 60.433 95.37)"
        fill={colors[0]}
        cx="60.4"
        cy="95.4"
        rx="2.6"
        ry="1.8"
      />
      <line fill="none" x1="53.3" y1="105" x2="74.5" y2="69.1" />
      <line fill="none" x1="53.8" y1="105.2" x2="75.1" y2="69.1" />
      <line fill="none" x1="54.4" y1="105.3" x2="75.6" y2="69.3" />
      <line fill="none" x1="54.9" y1="105.6" x2="76.1" y2="69.4" />
    </g>
  )
}

export default LuteLeft
