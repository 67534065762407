import React from "react"
import { withSkinColor } from "../../builderConnector"

const RightTheme = ({ skinColor, color = [] }) => {
  return (
    <g id="RightTheme">
      <path
        fill={skinColor}
        d="M71.6,73.8c-1.9,0.1-2.8,0.8-2.8,0.8l-0.3,1.2l6.2,2.2l0.4-0.9c0,0-1-1.1-1.6-2.1 C73.3,74.3,72.4,74,71.6,73.8z"
      />
      <path
        opacity="0.19"
        fill="#21376C"
        d="M71.6,73.8c-1.9,0.1-2.8,0.8-2.8,0.8l-0.3,1.2l6.2,2.2 l0.4-0.9c0,0-1-1.1-1.6-2.1C73.3,74.3,72.4,74,71.6,73.8z"
      />
    </g>
  )
}

export default withSkinColor(RightTheme, { character: "undefined" })
