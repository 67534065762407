import React from "react"

const BowLeft = ({
  colors = [
    "#939393",
    "#EED793",
    "#B5AC95",
    "#925C25",
    "#AA8B2F",
    "#FFCE06",
    "#42140B"
  ]
}) => {
  return (
    <g id="BowLeft">
      <path
        opacity="0.25"
        d="M42.8,115.7c-0.4,0-0.7-0.1-0.9-0.4l-0.3-0.9l0.1-0.5l0.3-0.5 c0,0,0.1-0.1,0.2-0.2l34.5-78.4c0-0.1,0-0.2,0-0.3l0.2-0.5l0.3-0.4l0.9-0.5c0.7,0,1.3,0.7,1.4,1.7V35l-0.3,0.6 c0.1-0.1,0.1-0.1,0.1-0.1s0,0.1-0.1,0.4c0.1,1,2.2,5.7,3.2,8l0.3,0.7c4.3,10.3-3.8,22.9-6.5,27c-0.6,0.9-1.2,2-1.7,2.7 c0,0.1,0,0.2,0.1,0.3l0.1,0.1l0.1,1.1l-1.1,0.6l-2.8,6.3l0.3,1.2l-0.9,0.6h-0.1c-0.1,0.1-0.2,0.1-0.3,0.2 c-0.3,0.9-0.6,2.1-0.9,3.1c-1.3,4.8-5.1,19.2-15.5,23l-0.7,0.2c-2.5,0.9-7.2,2.4-8,3c-0.2,0.3-0.2,0.4-0.2,0.4s0-0.1,0-0.2 l-0.3,0.6l-0.2,0.1C43.9,115.4,43.4,115.7,42.8,115.7z M45.5,111.2c1.9-1,4.7-2.2,7.4-3.3c7.5-3.2,10.2-13.7,11.9-20l0.3-1 c0.3-1,0.5-1.9,0.7-2.6l-0.5-0.7l0.4-1.1l0.1-0.1v-0.1l0.3-0.5l0.5-0.3l3.3-7.6l-0.2-0.5l0.1-0.2h0.1V73l0.5-1l0.9-0.1 c0.4-0.7,0.9-1.4,1.4-2.3l0.5-0.8c3.5-5.5,9.5-14.6,6.8-22.3c-1-2.8-2-5.6-2.5-7.7L45.5,111.2z"
      />
      <polygon
        fill={colors[0]}
        points="43,114.5 42.9,114.5 78,34.8 78.2,34.8"
      />
      <g fill={colors[1]}>
        <polygon points="72.4,77.4 70.2,76 70,76.3 69.7,77.2 71.9,78.5" />
        <polygon points="72.9,76.1 70.7,74.8 70.2,75.8 72.5,77.2" />
        <polygon points="69.3,81.3 68.1,80.8 67.7,81.6 68.4,82" />
        <polygon points="70,82.8 70.4,81.8 69.4,81.4 68.5,82.1" />
        <polygon points="71.4,79.6 69.1,78.4 68.6,79.5 70.9,80.7" />
        <polygon points="70.7,81.3 70.8,80.9 68.5,79.6 68.3,80.2 68.1,80.6 70.5,81.6" />
        <polygon points="69.7,83.4 69.9,83 67.6,81.8 67.1,83" />
      </g>
      <g fill={colors[2]}>
        <polygon points="71.5,79.4 71.8,78.7 70.2,77.8 69.5,78.5" />
        <polygon points="69.1,78.3 69.4,78.4 70.1,77.7 69.5,77.4" />
      </g>
      <g fill={colors[2]}>
        <polygon points="73,76 73.3,75.2 72.9,74.9 72.4,75.5" />
        <polygon points="70.8,74.6 72.2,75.5 72.8,74.8 71.2,73.6" />
      </g>
      <path
        fill={colors[3]}
        d="M77.9,36.3c0.2,1.8,1.6,5.8,3,9.8c3,8.5-3.8,18.4-7.4,24c-0.9,1.3-1.5,2.4-2,3.2l1.6,1.7 c0.2-0.7,1.2-2.2,2.2-3.8c3.8-5.8,10.2-17,6.4-26.1c-1.4-3.3-3.7-8.4-3.5-9.2s0.3-0.8,0.3-0.8s-0.1-0.8-0.5-0.8l-0.3,0.4 C77.8,34.8,77.8,35.2,77.9,36.3z"
      />
      <ellipse
        transform="rotate(-44.561 72.364 73.992)"
        fill={colors[4]}
        cx="72.4"
        cy="74"
        rx="0.7"
        ry="1.7"
      />
      <polygon
        fill={colors[5]}
        points="73.7,75.1 71.3,72.5 70.8,73.5 73.9,76.2"
      />
      <polygon
        fill={colors[6]}
        points="69.7,83.5 70.6,81.4 72.3,77.4 73.3,75.3 71.2,73.7 70,76.4 68.2,80.3 67,83"
      />
      <polygon
        fill={colors[5]}
        points="69.8,84 66.3,83.9 66.7,82.9 70.8,83.3"
      />
      <path
        fill={colors[3]}
        d="M44.1,113.2c1.5-1,5.3-2.7,9.3-4.4c8.3-3.5,11-15.2,12.7-21.7c0.4-1.5,0.7-2.7,1-3.6h2.4 c-0.3,0.6-0.8,2.4-1.3,4.2c-1.8,6.7-5.6,19-14.9,22.3c-3.4,1.2-8.7,2.9-9.2,3.6s-0.4,0.7-0.4,0.7s-0.7,0.5-0.9,0.2l0.1-0.5 C42.9,114.2,43.1,113.9,44.1,113.2z"
      />
      <ellipse
        transform="scale(.99999) rotate(-87.875 68.09 83.718)"
        fill={colors[4]}
        cx="68.1"
        cy="83.7"
        rx="0.7"
        ry="1.7"
      />
      <polygon
        fill={colors[4]}
        points="69.8,83.2 66.7,82.9 66.9,82.4 70.1,82.7"
      />
      <polygon
        fill={colors[4]}
        points="73.1,75.8 70.7,73.8 70.8,73.5 73.2,75.6"
      />
    </g>
  )
}

export default BowLeft
