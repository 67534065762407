import React from "react"

const ShoulderPlateGold = ({ colors = ["#8A784E", "#EED793", "#A59A76"] }) => {
  return (
    <g id="ShoulderPlateGold">
      <path
        opacity="0.42"
        fill="#21376C"
        d="M37.7,54.5c-0.6-1-7.6-3.9-11.4,0.9c0,0,0.4,2,0.5,4.3 c1.5,1.8,3.7,2.3,6.5,2.3c1.3,0,2.3-0.3,2.8-1c0.1-0.1,0.1-0.2,0.2-0.5C36.9,58.9,37.7,54.5,37.7,54.5z"
      />
      <path
        fill={colors[0]}
        d="M25.9,54.1c0,0-1.6,2-1.4,4.3c2.6,1.3,9.7,3.6,11.2,1.2c0.5-0.7,1.6-6.4,1.6-6.4 C36.7,52.3,29.6,49.4,25.9,54.1z"
      />
      <path
        fill={colors[1]}
        d="M36.5,53.4c-0.7-0.9-5.5-2.8-9.3,0.1C32.7,57.4,36.4,53.5,36.5,53.4z"
      />
      <path
        fill={colors[2]}
        d="M25.7,54.5C25.7,54.5,26.1,53.9,25.7,54.5c-0.4,0.5-1.4,2.2-1.2,3.9c2.6,1.3,9.7,3.6,11.2,1.2 c0.3-0.4,0.9-3.4,1.2-4.9C35,56.6,31.6,59.5,25.7,54.5z"
      />
      <g>
        <path
          opacity="0.42"
          fill="#21376C"
          d="M59.2,54.5c0.6-1,7.6-3.9,11.4,0.9c0,0,0.3,1.5,0.1,3.8 c-1.8,2.2-4.4,2.8-7.2,2.8c-1.3,0-2.3-0.3-2.8-1c-0.1-0.1-0.1-0.2-0.2-0.5C60,58.9,59.2,54.5,59.2,54.5z"
        />
        <path
          fill={colors[0]}
          d="M71,54.1c0,0,1.6,2,1.4,4.3c-2.6,1.3-9.7,3.6-11.2,1.2c-0.5-0.7-1.6-6.4-1.6-6.4 C60.2,52.3,67.3,49.4,71,54.1z"
        />
        <path
          fill={colors[1]}
          d="M60.4,53.4c0.7-0.9,5.5-2.8,9.3,0.1C64.2,57.4,60.5,53.5,60.4,53.4z"
        />
        <path
          fill={colors[2]}
          d="M71.2,54.5C71.2,54.5,70.8,53.9,71.2,54.5c0.4,0.5,1.4,2.2,1.2,3.9c-2.6,1.3-9.7,3.6-11.2,1.2 c-0.3-0.4-0.9-3.4-1.2-4.9C61.9,56.6,65.3,59.5,71.2,54.5z"
        />
      </g>
    </g>
  )
}

export default ShoulderPlateGold
