import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WizardRobe = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WizardRobe") ? (
    <g id="WizardRobe">
      <path
        id="S_x24_wizardRobe"
        d="M20.6,119.4c0-0.1,1.1-6.6,3.2-21.2l-3.3,0.2l-1.1-2.1l-0.6-1.4l-3.7-14.1l-0.2-1.6 c-0.2-5.5,0.9-9.4,3.3-11.8l0.2-0.3l0.2-2.3v-0.4l0.4-3.1c-0.3-2.9-0.3-9.2,4.7-12.9c3.4-2.6,6.9-3.1,8.6-3.3c0.1,0,0.2,0,0.3,0 c2.5-0.9,5-1.7,7.5-1.7c2.3,0,4.3,0.6,6.1,1.8l0.3,0.2l1.5,1.1c0.2-0.1,0.7-0.5,0.7-0.5l0.3-0.2c2-1.3,4.1-1.9,6.4-1.9 c1.9,0,3.6,0.5,5.3,1c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0,0.9,0.1,1.3,0.1c3.3,0.2,7.9,0.4,11.1,2.9c3.3,2.5,4.8,5.9,5.4,7.8 c0.8,2.2,0.9,4.9,1,7.7v0.7c-0.1,1-0.1,2.2-0.1,3.1c1.2,1.6,2.3,3.3,2.8,4.3c1.4,2.5,2.6,4.6-4.5,26.7l-6,0.1 c1.2,7.4,2.8,16.5,3.6,21L20.6,119.4L20.6,119.4z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WizardRobe, {
  character: "ManDwarf"
})
