import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Socks from "./Socks"
import BareFeet from "./BareFeet"
import Boots from "./Boots"
import StrapBoots from "./StrapBoots"
import BrownShoes from "./BrownShoes"
import BlackBoots from "./BlackBoots"
import FurTops from "./FurTops"

const CATEGORY = "feet"
const CHARACTER = "ManHalfling"
export const IDS = {
  SOCKS: "Socks",
  BARE_FEET: "BareFeet",
  BOOTS: "Boots",
  STRAP_BOOTS: "StrapBoots",
  BROWN_SHOES: "BrownShoes",
  BLACK_BOOTS: "BlackBoots",
  FUR_TOPS: "FurTops"
}

export const components = {
  [IDS.SOCKS]: Socks,
  [IDS.BARE_FEET]: BareFeet,
  [IDS.BOOTS]: Boots,
  [IDS.STRAP_BOOTS]: StrapBoots,
  [IDS.BROWN_SHOES]: BrownShoes,
  [IDS.BLACK_BOOTS]: BlackBoots,
  [IDS.FUR_TOPS]: FurTops
}

export const Group = ({ props }) => (
  <g id="feet">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.SOCKS,
    name: "Socks",
    defaultColors: ["#FFF8CA"],
    colorable: true,
    component: components[IDS.SOCKS],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.BARE_FEET,
    name: "Bare Feet",
    defaultColors: ["#6D4724"],
    colorable: true,
    component: components[IDS.BARE_FEET],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.BOOTS,
    name: "Boots",
    defaultColors: ["#494846", "#01060F", "#FFFFFF", "#6B6967", "#9C7000"],
    colorable: true,
    component: components[IDS.BOOTS],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.STRAP_BOOTS,
    name: "Strap Boots",
    defaultColors: [
      "#A87F54",
      "#754C29",
      "#9C5D26",
      "#262262",
      "#3A3019",
      "#5C3C20",
      "#644223"
    ],
    colorable: false,
    component: components[IDS.STRAP_BOOTS],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.BROWN_SHOES,
    name: "Brown Shoes",
    defaultColors: [
      "#774F1F",
      "#6B441B",
      "#A16628",
      "#372513",
      "#948F8B",
      "#EED793",
      "#70431B",
      "#794B22",
      "#925F3A",
      "#9E6A47",
      "#SVGID_",
      "#594B1F"
    ],
    colorable: false,
    component: components[IDS.BROWN_SHOES],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.BLACK_BOOTS,
    name: "Black Boots",
    defaultColors: [
      "#616161",
      "#FFFFFF",
      "#352515",
      "#454443",
      "#919091",
      "#454545",
      "#525252"
    ],
    colorable: true,
    component: components[IDS.BLACK_BOOTS],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.FUR_TOPS,
    name: "Fur Tops",
    defaultColors: ["#1F1F1F"],
    colorable: true,
    component: components[IDS.FUR_TOPS],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
