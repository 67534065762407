import React from "react"

const Underwear = ({ colors = ["#7CB1DD"] }) => {
  return (
    <g id="Underwear" fill={colors[0]}>
      <path
        id="C_x24_D_x24_bra"
        d="M73.1999969,60.5C70.7999954,60.5,68.7999954,62.2999992,68.5,63 c-0.4000015-0.7000008-2.4000015-2.5-4.7000008-2.5C61.3999977,60.5,59.5,61.7000008,59.0999985,63l-1.2999992,0.7000008 L59.0999985,66L59.5,71.1999969h18.1999969L78.0999985,66l1.3000031-2.2999992L78,63 C77.6999969,62,75.5999985,60.5,73.1999969,60.5z"
      />
      <path
        id="C_x24_D_x24_panties"
        d="M80.5,80.5999985c0-0.8000031,0-1.5-0.0999985-2.1999969L69.5,80l-1,0.0999985 L67.5,80l-10.9000015-1.6999969C56.5,79,56.3999977,79.7000046,56.5,80.5c-0.0999985,0.1999969-0.2000008,0.5-0.4000015,0.8000031 l10.8000031,5v-0.0999985H70v0.0999985l10.8000031-5C80.6999969,81.0999985,80.5999985,80.8000031,80.5,80.5999985z"
      />
    </g>
  )
}

export default Underwear
