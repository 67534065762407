import React from "react"

const StrapsLt = ({ colors = ["#573B25"] }) => {
  return (
    <g id="StrapsLt">
      <path
        fill={colors[0]}
        d="M72.2092056,73.7551193c0.2339859,0.2054977,0.4740906,0.3930054,0.7169876,0.5721893 c-0.0006943-0.0032806,0.0004807-0.0099411-0.0003738-0.0130005c0.0029526,0.0106354,0.0162811,0.0183716,0.0218124,0.0282593 c0.059639,0.0437851,0.1198654,0.0857468,0.1800385,0.1280289c0.8513565,0.313446,4.136055-0.0896683,4.675148-0.1484985 c1.8215561-0.1987915,3.6060944-0.5711746,5.3713989-1.0590668c-0.0146103,0.0040817,0.1312027,0.8440475,0,0.8802643 c-2.0184097,0.5578461-4.0617523,0.9604187-6.1509705,1.1333618c-0.2819595,0.0233231-1.2318497,0.1405563-2.149292,0.1843948 c2.4438171,1.0883484,5.1847534,1.1967087,7.965065,0.7788239c0.0092392-0.0013962,0.0261078,0.1016541,0.0434113,0.2545013 c0.1012192-0.0348663,0.2030334-0.0654373,0.3041458-0.1013794c-0.0673218,0.0239105,0.2900162,1.6574707,0,1.7605209 c-1.4100113,0.5010071-2.8346863,0.8912201-4.2767105,1.1838226c1.435791,0.0240097,2.8718567,0.0342178,4.3087845,0.018692 c0.1173401-0.0012894,0.1335678,1.7590714,0,1.7605209c-3.1666641,0.0342255-6.3297806-0.027504-9.4926224-0.1853027 c-0.081398-0.0040283-0.1027298-0.5147552-0.0906372-0.9857788c-0.0952606,0.0028458-0.1896057,0.009613-0.2850266,0.0116577 c-0.1114273,0.0023651-0.1393661-1.7575684,0-1.7605209c1.2216949-0.026001,2.4265747-0.1229248,3.6183472-0.2863617 c-1.7098618-0.3815689-3.3222046-1.1321869-4.7595062-2.3946075 C71.8615952,75.2103653,72.4149857,73.9359055,72.2092056,73.7551193z"
      />
    </g>
  )
}

export default StrapsLt
