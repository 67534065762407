import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const ShortswordLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("ShortswordLeft") ? (
    <g id="ShortswordLeft">
      <path
        id="S_x24_shortswordLeft"
        d="M69.9,93.8c-1,0-2-0.2-2.9-0.5c-4.3-1.6-6.7-6.3-5.3-10.7v-0.1c0.1-0.4,0.3-0.8,0.5-1.2 c-2.9-2.1-4.3-5.9-3.2-9.5v-0.1c0.4-1.4,1.2-2.7,2.3-3.7c1.3-1.2,2.9-2,4.7-2.2l8.5-30.4l2.5-3.7l4.1-3.2l11.2,3.7l1.3,4.9l-0.1,4 L83.6,72c1.7,1.6,2.7,3.8,2.7,6.3c0,2.4-1,4.6-2.7,6.2c-1.4,1.4-3.3,2.1-5.3,2.3c-0.1,0.3-0.1,0.7-0.2,1v0.1 c-0.4,1.4-1.2,2.7-2.3,3.7C74.1,93,72,93.8,69.9,93.8z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(ShortswordLeft, {
  character: "ManDwarf"
})
