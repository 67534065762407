import React from "react"
import { withSkinColor } from "../../builderConnector"

const LeftHand = ({ skinColor, color = [] }) => {
  return (
    <g id="LeftHand">
      <path
        fill={skinColor}
        d="M32.1,81.8c-0.4-0.6-0.2-0.8-0.2-0.8s0.3-0.7,0-1.3C31.6,79.1,31,79,31,79s0.6-0.8,0.2-1.7 c-0.1-0.2-0.3-0.6-0.9-0.9c0.2-2.1-1.3-2.1-1.9-2c0,0-0.1,0-0.1-0.1c-1.4,0.9-3.1,1.2-4.6,1.8c-0.4,0.1-0.7,0.1-1.1,0.1 c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.1,0-0.2,0.1c-0.5,0.9-0.7,1.7-0.8,2.5c0,0.8,1,3.4,1.4,4.1c0.5,0.6,0.9,1,1.2,1.4 c0,0,0,0,0.1,0h0.1l0,0c0.4,0.2,0.9,0.3,1.3,0.4l0,0c0.3,0.1,0.6,0.2,0.9,0.3c0.4,0.1,0.7,0.2,1,0.3c0.1,0,0.2,0.1,0.3,0.1 c0.2-0.1,0.5-0.1,0.7-0.2c-0.1,0-0.1,0-0.3,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1-0.1c0.3-0.1,0.6-0.1,0.8-0.3 c1.4-0.7,2.7-2.2,2.7-2.2S32.4,82.4,32.1,81.8z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M32.1,81.8c-0.4-0.6-0.2-0.8-0.2-0.8s0.3-0.7,0-1.3 C31.6,79.1,31,79,31,79s0.6-0.8,0.2-1.7c-0.1-0.2-0.3-0.6-0.9-0.9c0.1-1.3-0.4-1.8-0.9-2c0.2,0.1,0.4,0.4,0.6,0.9 c0.3,0.8-2.2,2.5-2.2,2.5l1.5-0.5c0,0,1.1-0.5,1.5,0.4S30.7,79,30.7,79l-1.4,0.9c0,0,1.7-0.6,2,0s0,1.3,0,1.3l-1.4,1 c0,0,1-0.6,1.4,0s0.1,1,0.1,1s-1.3,1.5-2.7,2.2c-0.1,0-0.2,0-0.3,0c0,0,0,0,0.1,0c-0.2,0-0.7,0.2-1.3-0.2s-0.7-1-0.3-1.5 c0.1-0.1-0.2-0.2-0.2-0.3c-1.1,0.5-1.6-0.4-1.7-0.8c-0.1-0.4,0.4-1.1,0.4-1.2c-0.1-0.1-1.3,0.2-1.7-0.4c-0.6-0.7,0.1-2,1.1-2.2 c0-0.1,0,0,0-0.1c-1.3,0.2-1.8-0.3-1.5-1.6c0.2-0.4,0.8-0.8,1.6-1.2l1.2-0.5l2.7-1.1c-0.1,0-0.2,0-0.4,0c0,0-0.1,0-0.1-0.1 c-1.4,0.9-3.1,1.2-4.6,1.8c-0.4,0.1-0.7,0.1-1.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.1,0-0.2,0.1c-0.5,0.9-0.7,1.7-0.8,2.5 c0,0.8,1,3.4,1.4,4.1c0.5,0.6,0.9,1,1.2,1.4c0,0,0,0,0.1,0h0.1l0,0c0.4,0.2,0.9,0.3,1.3,0.4l0,0c0.3,0.1,0.6,0.2,0.9,0.3 c0.4,0.1,0.7,0.2,1,0.3c0.1,0,0.2,0.1,0.3,0.1c0.2-0.1,0.5-0.1,0.7-0.2c-0.1,0-0.1,0-0.3,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1-0.1 c0.3-0.1,0.6-0.1,0.8-0.3c1.4-0.7,2.7-2.2,2.7-2.2S32.4,82.4,32.1,81.8z"
      />
    </g>
  )
}

export default withSkinColor(LeftHand, { character: "undefined" })
