
export const SET_FLASH = 'flash.SET_FLASH'
export const CLEAR_FLASH = 'flash.CLEAR_FLASH'
export const CLEAR_ALL_FLASHES = 'flash.CLEAR_ALL_FLASHES'

const defaultState = {}

const message = ({ message, severity = "warning"}) => ({
  message,
  severity
})

const flashReducer = (state = defaultState, action) => {
  switch(action.type) {
    case SET_FLASH:
    return { ...state, [action.payload.key]: message(action.payload) }
    case CLEAR_FLASH:
      return Object.keys(state).reduce((newState, key) => {
        if (key !== action.payload.key) newState[key] = state[key]
        return newState
      }, {})
    case CLEAR_ALL_FLASHES:
      return {}
    default:
      return state
  }
}

export default flashReducer