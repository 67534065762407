import React from "react"

const Dicky = ({ colors = ["#746880", "#262261"] }) => {
  return (
    <g id="Dicky">
      <path
        fill={colors[0]}
        d="M65.197403,25.3383904c0.0472183,0.2833176,0.2833176,2.8804092,0,3.3053875 c-0.2833252,0.4249783-2.2193375,0.8971767-2.2193375,0.8971767s1.747139,5.9496994,5.7136002,5.7608185 c3.9664688-0.1888771,5.7136002-5.8080387,5.7136002-5.8080387l-2.5970917-0.8971767v-3.2109489L65.197403,25.3383904z"
      />
      <g fill={colors[1]}>
        <path
          opacity="0.15"
          d="M65.8348694,25.6925392 c0,0,0.1652679,2.7623596-0.0944443,3.1401176c-0.2597046,0.3777599-2.4790382,1.3929863-2.4790382,1.3929863 s2.4318199-0.5902481,2.6443062-1.1568871C66.118187,28.5021191,65.8348694,25.6925392,65.8348694,25.6925392z"
        />
        <path
          opacity="0.15"
          d="M66.4251175,26.1411266 c0,0,0,2.8095798-0.2360992,3.1637287s-2.3846054,2.3137722-2.3846054,2.3137722s2.7623596-1.6526947,2.7859726-2.0540619 C66.6139908,29.1631966,66.4251175,26.1411266,66.4251175,26.1411266z"
        />
        <path
          opacity="0.15"
          d="M67.2278519,26.3536167l-0.0472183,3.399828 c0,0-2.1248932,4.1081238-2.0304565,3.8956375c0.0944443-0.2124901,2.2665558-3.3053894,2.3609924-3.801199 C67.6056137,29.3520756,67.2278519,26.3536167,67.2278519,26.3536167z"
        />
      </g>
      <g fill={colors[1]}>
        <path
          opacity="0.15"
          d="M71.0998764,25.6925392 c0,0-0.1652679,2.7623596,0.0944443,3.1401176c0.2597046,0.3777599,2.4790421,1.3929863,2.4790421,1.3929863 s-2.4318237-0.5902481-2.6443176-1.1568871C70.8165588,28.5021191,71.0998764,25.6925392,71.0998764,25.6925392z"
        />
        <path
          opacity="0.15"
          d="M70.5096283,26.1411266 c0,0,0,2.8095798,0.2360992,3.1637287s2.3846054,2.3137722,2.3846054,2.3137722s-2.7623596-1.6526947-2.7859726-2.0540619 C70.3207474,29.1631966,70.5096283,26.1411266,70.5096283,26.1411266z"
        />
        <path
          opacity="0.15"
          d="M69.7068939,26.3536167l0.0472183,3.399828 c0,0,2.1248932,4.1081238,2.0304565,3.8956375c-0.0944443-0.2124901-2.2665558-3.3053894-2.3609924-3.801199 C69.3291321,29.3520756,69.7068939,26.3536167,69.7068939,26.3536167z"
        />
        <path
          opacity="0.15"
          d="M68.5839081,30.0641537 c0.0004883-0.0091343,0.0015106-0.027401,0.0015106-0.027401s-0.0944519-2.8568192-0.0944519-2.9512177h-0.15345 c0,0.0943985-0.0944519,2.9512177-0.0944519,2.9512177l0.0010757,0.0326653 c-0.0006943,0.008812-0.0010757,0.011713-0.0010757,0.0204697c0.0000534,0.2076015,0.0008087,4.7640171,0.035408,4.8341312 c0.0152054,0.0307312,0.2563324,0.0307312,0.2715378,0c0.0345993-0.0701141,0.035408-4.6265297,0.035408-4.8341312 c0-0.0087566-0.0003815-0.0116577-0.0010757-0.0204697L68.5839081,30.0641537z"
        />
      </g>
    </g>
  )
}

export default Dicky
