import React from "react"

const SunstaffL = ({
  colors = ["#664000", "#FFFFFF", "#262261", "#944200", "#BF8A00", "#SVGID_"]
}) => {
  return (
    <g id="SunstaffL">
      <path
        fill={colors[0]}
        d="M94.2,39.9V117l1,1.3l1.2-1.3l1.1-77C97.4,40,96.3,37.6,94.2,39.9z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M97.3,52.1l0-4.5c-0.1-0.1-0.6-0.4-0.8-0.2C95.9,48,95,50.9,94,51v3.7 C95.1,54.1,96.4,53,97.3,52.1z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M97.2,55.4l0-1.6c-0.5,0.4-1,0.8-1.5,1.1c-0.5,0.3-1,0.5-1.5,0.7c-0.1,0-0.1,0.1-0.2,0.1 v3.5C95.1,57.9,96.1,56.7,97.2,55.4z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M95.3,66c0.6-0.8,1.2-1.5,1.8-2.3l0-2.4C96.1,62.6,95,63.8,94,65v1 C94.5,66.2,94.9,66.2,95.3,66z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M97.2,59.5l0-1.2c-0.9,0.9-2,1.9-3.2,2.5v2.6C95,62.1,96.1,60.8,97.2,59.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M96.9,75.5l0-3c-0.9,0.8-1.9,1.5-3,1.7v4.6C95,77.7,95.9,76.6,96.9,75.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M95.3,78.5c-0.4,0.5-0.9,1-1.3,1.5v0.7c1,0,2-0.6,2.9-1.5l0-2.4 C96.4,77.3,95.8,77.9,95.3,78.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M96.6,102.6l0-2.5c-0.8,0.6-1.7,1.2-2.6,1.5v3.7C94.8,104.3,95.7,103.5,96.6,102.6z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M96.5,104.7l0-1.7c-0.9,1.1-1.7,2.2-2.6,3.3v0.7c0.4-0.2,0.7-0.4,1.1-0.6 C95.6,105.8,96,105.2,96.5,104.7z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M97.1,66.2L97.1,66.2C96,67.4,95,68.6,94,69.9v0.2C95,68.8,96,67.5,97.1,66.2z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M94,72.5v0.6c1.3-0.7,2.5-1.7,2.8-2c0.1-0.1,0.2-0.2,0.3-0.3l0-2.2 C96,69.9,95,71.2,94,72.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M96.9,75.5l0-3c-0.9,0.8-1.9,1.5-3,1.7v4.6C95,77.7,95.9,76.6,96.9,75.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M95.3,78.5c-0.4,0.5-0.9,1-1.3,1.5v0.7c1,0,2-0.6,2.9-1.5l0-2.4 C96.4,77.3,95.8,77.9,95.3,78.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M96.6,102.6l0-2.5c-0.8,0.6-1.7,1.2-2.6,1.5v3.7C94.8,104.3,95.7,103.5,96.6,102.6z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M96.5,104.7l0-1.7c-0.9,1.1-1.7,2.2-2.6,3.3v0.7c0.4-0.2,0.7-0.4,1.1-0.6 C95.6,105.8,96,105.2,96.5,104.7z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M96.5,107.4l0-0.7c-0.3,0.2-0.6,0.4-0.8,0.5c-0.3,0.2-0.7,0.4-1,0.5 c-0.2,0.3-0.5,0.5-0.7,0.8v1.3C94.8,109,95.7,108.2,96.5,107.4z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M94,111.5v1.9c0.4-0.4,0.8-0.7,1.2-1.1c0.3-0.4,0.7-0.8,0.9-1.2c0.1-0.1,0.2-0.3,0.3-0.5 l0-2.1C95.7,109.5,94.8,110.5,94,111.5z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M95.4,95.7c-0.5,0.9-0.9,1.8-1.4,2.6v1.5c0.9-0.8,1.8-1.5,2.7-2.3l0-2.4 C96.3,95.3,95.8,95.5,95.4,95.7z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M94,91.4v2.3l2.8-2.4l0.1-3.8C95.8,88.8,94.9,90.1,94,91.4z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M95.5,82.4c0.5-0.6,0.9-1.3,1.4-1.9l0-0.4c-0.8,0.6-1.8,1-2.9,1.1v3.5 C94.5,83.9,95,83.1,95.5,82.4z"
      />
      <path
        opacity="0.18"
        fill="#FFF"
        d="M96.8,84.6l0-2c-0.9,1.2-1.9,2.3-2.9,3.5v1.4c0.6-0.3,1.1-0.7,1.7-1.1 C96,85.8,96.4,85.2,96.8,84.6z"
      />
      <path
        fill={colors[0]}
        d="M86.9,30.2c0-0.1,0.2-0.1,0.5-0.1c1,0,3.4,0.1,4.7-2.7c1.6-3.5,1.9-6.1,3.8-5.3c1.9,0.8,6.2,2,6.2,4.6 s-2.5,7.1-3.5,11.1s-1.1,9.7-1.1,9.7l-3.7-0.6l-1.7-9.6c0,0,2-1.3,3.8-2.9c1.8-1.6,0.7-3.6-3.2-2.9 C88.8,32.3,86.9,30.2,86.9,30.2z"
      />
      <path
        opacity="0.3"
        fill={colors[2]}
        d="M92.8,31.6c4-0.7,5,1.3,3.2,2.9c-1.8,1.6-3.8,2.9-3.8,2.9 l1.7,9.6l0.1,0c0-0.1,0-0.2,0-0.3c-0.3-6.1,3.6-9.1,3.8-9.4c7-9-0.6-14.6-4.7-10.3c-3.7,5.3-6.1,3.1-6.1,3.1 S88.8,32.3,92.8,31.6z"
      />
      <radialGradient
        id="SVGID_52_"
        cx="192.818"
        cy="216.078"
        r="3.964"
        gradientTransform="matrix(0 1 -2 0 528.057 -162.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={colors[3]} />
        <stop offset="0.824" stopColor="#bf8a00" />
      </radialGradient>
      <radialGradient
        id="SVGID_53_"
        cx="191.381"
        cy="214.216"
        r="1.57"
        gradientTransform="rotate(45.001 368.715 6.154)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="SVGID_54_"
        cx="260.36"
        cy="115.64"
        r="2.329"
        gradientTransform="rotate(45.001 487.817 -256.074) scale(1.594 1.9517)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.861" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_52_)" cx="95.9" cy="30.2" r="4" />
      <circle
        opacity="0.85"
        fill="url(#SVGID_53_)"
        cx="96.2"
        cy="27.9"
        r="1.6"
      />
      <path
        opacity="0.42"
        fill="url(#SVGID_54_)"
        d="M98.3,35.7c-1.8,1.8-4.4,2-5.8,0.6c-1.4-1.4-1.2-4.1,0.6-5.8s4.4-2,5.8-0.6 C100.3,31.4,100.1,34,98.3,35.7z"
      />
      <g opacity="0.25" fill="#FFF">
        <path d="M98.4,37.6c0,0-2.1,4.1-1.9,9.8l1,0.2c0,0,0.1-5.6,1.1-9.7c0.1-0.3,0.2-0.6,0.2-0.9 C98.7,37.2,98.5,37.4,98.4,37.6z" />
        <path d="M91.4,29c1.1-0.8,1.4-2.7,2.7-4.5c2.7-3.8,6.8-0.1,7.7,2.7c0.1,0.2,0.1,0.3,0.1,0.5 c0.1-0.4,0.1-0.8,0.1-1.1c0-2.5-4.3-3.7-6.2-4.6c-1.9-0.8-2.3,1.8-3.8,5.3c-1.2,2.7-3.6,2.7-4.7,2.7C87.7,30.1,89.7,30.4,91.4,29 z" />
        <path d="M87,30.3l0.3-0.2c-0.2,0-0.4,0-0.4,0.1C86.9,30.2,86.9,30.2,87,30.3z" />
      </g>
    </g>
  )
}

export default SunstaffL
