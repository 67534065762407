import React from "react"

const SlingShotR = ({
  colors = ["#352915", "#464646", "#939393", "#724F1F"]
}) => {
  return (
    <g id="SlingShotR">
      <path
        fill={colors[0]}
        d="M49.2131348,63.9055481l0.6724243,4.5663452l-1.9657326-2.2344284l-0.4871101-7.2551842 l0.7277451-1.9656639l-4.5525703-2.09058l-0.8483124,1.3027306l1.0895119,1.5298042l2.0040627,28.5154228l-0.2873154,2.3596954 c0,0-1.7986984,0.7696533-2.6517181,0.4822617c-0.8530235-0.287384-1.1312714-2.2808151-1.1312714-2.2808151 s0.1841011-28.3022728,0.1655426-28.6360588c-0.2040825-2.1649628,1.5389366-3.9359665,1.5389366-3.9359665l5.3082199,2.1322746 l1.3818932,8.2935867L49.2131348,63.9055481z"
      />
      <path
        fill={colors[1]}
        d="M41.7550774,87.8411255c0,0,1.7987671,0.73703,2.6285896,0.6071854 c1.1636124-0.148407,1.9840813-1.9518127,1.9840813-1.9518127s-0.6722794-1.5529938-1.1266289-2.1973724 c-0.0927238-0.1622543-1.5159531-0.1668243-1.6828461-0.1575394c-1.2609711-0.0972977-1.3953819,0.496109-2.0119171,1.4511337 C41.2404099,86.1119614,41.7550774,87.8411255,41.7550774,87.8411255z"
      />
      <path
        fill={colors[2]}
        d="M43.7160225,86.9787598c0.6073227,0.3847504,1.2100105,0.686058,1.8034096,0.8204727 c0.556282-0.5331573,0.852951-1.2192917,0.852951-1.2192917s-0.6722794-1.5529938-1.1266289-2.1973648 c-0.1761703-0.1576157-0.8483849-0.203949-1.3490601-0.1761093c-0.1483307,0.343071-0.2920265,0.769577-0.4403572,1.112648 C43.4841766,85.8197861,43.6001053,86.3992767,43.7160225,86.9787598z"
      />
      <path
        fill={colors[3]}
        d="M45.3803825,88.3091888c-0.4821167,0.361618-1.8219032,0.352417-1.9841537,0.445137 c-1.0662384,0.394104-1.5299416-1.9238358-1.5299416-1.9238358l0.2535591-30.0639191l0.6443748-0.4543533l0.292099,0.7370987 l0.4092293,26.9300156l-0.3800392,2.1974411l0.2782478,1.9934311l1.1728859,0.018486 C44.536644,88.1886902,44.6154518,88.1006088,45.3803825,88.3091888z"
      />
    </g>
  )
}

export default SlingShotR
