import React from "react"

const WizardRobe = ({
  colors = [
    "#525A55",
    "#3B403D",
    "#4C3317",
    "#895A24",
    "#474D49",
    "#6F7873",
    "#313633",
    "#D7D6D5"
  ]
}) => {
  return (
    <g id="WizardRobe">
      <path
        fill={colors[0]}
        d="M64,68.2c0.1,0.6-0.5,4.1,0.5,7.7c2.4,8.7,7.2,20.2,7.2,20.2s6.1-19.1,5.1-21c-0.7-1.1-2.3-3.6-3.7-5.1 c-0.3-2,0-6.4,0-6.4s-0.1-5.3-0.5-5.4c0,0-0.8-3-3-4.7s-8.2-1.3-9.7-1.7c-3.3-0.9-4.8-1.6-7-0.2c-1.5,1.1-3.4,2.4-4.3,4.1L42.3,51 c-1.8-1.2-4.4-0.4-7.7,0.8C33.5,52.2,30.7,52,28,54s-1.8,7.2-1.8,7.2l-0.5,4l-0.3,4.2l-1.9,2.9c-1.7,0.8-1.6,6.7-1.6,6.7l3.7,14.1 l1.1,2.1L33,76.4l0.7-7.2c0.3,0.6,0.8,1.4,1.2,5.6c-0.6,5.2-2.2,9.9-2.8,14c-3.1,23-4.5,30.7-4.5,30.7h41.1c0,0-5.6-32.7-6-35.8 C62.4,80.5,62.1,70.9,64,68.2z"
      />
      <path fill={colors[0]} d="M53,48.9" />
      <path
        fill={colors[1]}
        d="M75.3,74.8c0.5,2.4,0.1,0.3,0.1,3.1s-3.6,15.4-3.6,15.4s-5.7-12-5.5-16.4C67.8,70.5,74.7,72.4,75.3,74.8z"
      />
      <path
        fill={colors[1]}
        d="M26.2,72.6c2.6,0.2,3.9,1.7,3.9,2.7c0,2.8-3.5,18.4-3.5,18.4s-3.2-13.6-3.5-15S23.6,72.4,26.2,72.6z"
      />
      <path
        fill={colors[1]}
        d="M50.8,62.9c3,5.7,2.4,6.8,2.6,14.5c0.3,9.3,0.9,19.9,1.6,28.9c0.2,2.6,2,9.8,2.5,12.3c0,0.1,1.4,0.2,1.3,0 c-0.4-2.2-2.1-9-2.5-11.4c-0.7-4.3-1.2-8.5-1.5-12.9c-0.7-10.2-0.4-18.6-1-25.2c-0.4-3.5-1.5-4.7-2.2-6.4 C51.6,63,50.8,62.9,50.8,62.9z"
      />
      <path
        fill={colors[2]}
        d="M34.6,71.8c-0.5,1.4-0.3,4.6,1.1,5.2c2.1,0.8,4.8,1.3,7.1,1.5c3.2,0.3,7,0,10.2-0.2c3.4-0.2,7.6,0,9.5-2.4 c0.2-0.2-0.1-3.1-0.3-2.9c-1.3,1.6-5.9,1.5-8.1,2c-2.2,0.5-5,0.4-7.3,0.5c-2.1,0.1-4.2,0.1-6.3-0.4c-1.7-0.3-6.5-0.6-6.1-2.4 C34.5,72.5,34.7,71.5,34.6,71.8L34.6,71.8z"
      />
      <path
        fill={colors[3]}
        d="M34.3,72.3c-0.5,1.4,0.1,4,1.8,4.6c2.2,0.7,4.4,0.9,6.6,1.1c3.2,0.3,7,0,10.2-0.2c3.4-0.2,7.8,0.1,9.7-2.2 c0.2-0.2,0.4-3.6,0.2-3.3c-6.5,3.9-24.8,2.9-28.3-0.7L34.3,72.3z"
      />
      <path
        fill={colors[2]}
        d="M52,75.5c2.3,0.6,2.9,8.3,3.2,10.4c0.4,2.2-0.3,4.5-0.1,6.8c0,0.3,2.6,1.5,2.6,1.1 c-0.1-4-0.6-10.8-2.4-14.9c-0.7-1.7-1.7-3.7-3.8-4C51.1,74.8,51.7,75.5,52,75.5L52,75.5z"
      />
      <path
        fill={colors[3]}
        d="M52.3,75.5c2.3,0.6,2.9,8.3,3.2,10.4c0.4,2.2-0.3,4.5-0.1,6.8c0,0.3,2.6,1.5,2.6,1.1 c-0.1-4-0.6-10.8-2.4-14.9c-0.7-1.7-1.7-3.7-3.8-4C51.4,74.8,52,75.4,52.3,75.5L52.3,75.5z"
      />
      <path
        fill={colors[2]}
        d="M51.5,74.5c-0.3,3.7-2.4,9.2-2.3,13c0,1.5,0.4,3.3,0.6,4.9c0.1,1,1.1,5.1,0.2,5.7 c-0.2,0.1,2.5,0.6,2.8,0.4c0.9-0.6,0.1-1.9-0.1-2.7c-0.5-2.1-0.7-4.3-0.9-6.5c-0.2-4.1,0.1-10.1,0.5-14.2 C52.4,74.6,51.5,74.2,51.5,74.5L51.5,74.5z"
      />
      <path
        fill={colors[3]}
        d="M51.3,74.4c-0.3,3.7-2.4,9.2-2.3,13c0,1.5,0.4,3.3,0.6,4.9c0.1,1,1.1,5.1,0.2,5.7 c-0.2,0.1,2.5,0.6,2.8,0.4c0.9-0.6,0.1-1.9-0.1-2.7c-0.5-2.1-0.7-4.3-0.9-6.5c-0.1-4.3,0.2-10.2,0.6-14.3 C52.2,74.6,51.4,74.1,51.3,74.4L51.3,74.4z"
      />
      <path
        fill={colors[1]}
        d="M34.8,52.2c0,0,2.6,11.3,13,11.3s12.1-11.8,12.1-11.8s-3.1,11.3-12,10.8S35.1,51.9,34.8,52.2z"
      />
      <path
        fill={colors[1]}
        d="M26.5,68.9c0,0,3.2-0.8,4.3,3C31.2,69.6,28.3,66.9,26.5,68.9z"
      />
      <path
        fill={colors[1]}
        d="M48.6,55.7c0.5,0.4,2.9,2.2,2.9,2.2l-0.1-1.3l3-4.6l-3.2,2.5v-0.6l1.7-2.1l-1.8,1.5v-0.5 C51.1,52.9,49.1,54.4,48.6,55.7z"
      />
      <path
        fill={colors[1]}
        d="M37.4,51c0,0,3.9,2.2,7.2,5.5C43.3,52.2,39,50.5,37.4,51z"
      />
      <path
        fill={colors[4]}
        d="M38,51c0,0,2.1-0.8,5,1.9C41.4,50.7,39.2,50.4,38,51z"
      />
      <path
        fill={colors[1]}
        d="M35.6,51.8c0,0,3,9.1,13.6,9.7c-8.5-2.1-9-5.4-11.9-10C36.7,50.5,35.6,51.8,35.6,51.8z"
      />
      <path
        fill={colors[1]}
        d="M58.2,51.7c0,0-2.2,8.4-7.5,9.5c3.2-0.9,5-1.1,8.7-9C59.2,51.8,58.2,51.7,58.2,51.7z"
      />
      <path
        fill={colors[1]}
        d="M32.4,65.7l0.5,3.4c0,0-1,3.4-1.6,1.2c0.4-1.9-0.4-7-0.4-7.7c2.4-0.3,1.5-0.5,1.5-0.5l-0.2-3.5l1,3.4 l-1-5.8l2,6.9C34.2,63.1,35,69.5,32.4,65.7z"
      />
      <g fill={colors[1]}>
        <path d="M67.6,58.2c0.1,0,0.2,0,0.3-0.1C67.9,58.1,67.8,58.1,67.6,58.2z" />
        <path d="M67.4,59.4c-2.7,0.5-2.9,4.4-2.6-0.1c-1.6,0.5-1,4.6-1.7,4.9c-0.1,0,0,0-1-1c0.2,2.3,0.8,2.3,1.4,2.8 C63.6,64.2,66.6,59.6,67.4,59.4z" />
      </g>
      <path
        fill={colors[1]}
        d="M53.8,112.4c0,0.2-0.1,0.3-0.1,0.5l0,0l0,0c0.6-0.5,1.1-1,1.9-1.3c0.8-0.3,1.6-0.5,2.5-0.6 c0.6-0.1,1.4-0.2,2.1-0.2C56.4,108.6,53.9,112.3,53.8,112.4z"
      />
      <path
        fill={colors[1]}
        d="M68.2,118.5c-2.2,0.4-2.8-4.2-3.7-5.7c-2.1-3-6.6-3.6-9.8-0.1c-2.2,2.4-2.6,5.2-6.7,6.1 c-3.6,0.8-4.4-1.2-6.2-3.4c-0.2-0.3,2,1.1,2.2,1.2c1,0.6,1.4,1.2,2.8,1.5c2.9,0.5,6.1-2.2,6.8-4.6c-1.6,0.5-2.7,1.4-4.3,1.9 c-0.4,0.1-0.8,0.1-1.3,0.1c-2.3-0.1-3.6-1-4.1-1.5c-0.8-1.3-1.9-2.4-3.1-3c0.2,0.2,0.4,0.5,0.6,0.7c0.6,0.9,0.9,1.8,1.8,2.5 c0.8,0.6,1.9,1,2.9,1.4c0.1,0,0.6,0.1,1.2,0.2c-2.7,0.2-4.2-0.5-6.1-2.4c-2.5-2.6-4.5-5.5-8-2.2c-1.5,1.4-2.3,7.8-5,7.2l-0.4,1.1 c4.2-0.1,37.5,0,37.5,0c1.1-0.2,2,0,3,0L68.2,118.5z"
      />
      <path
        fill={colors[0]}
        d="M33.9,112.2c-0.7,1-2.3,3.2-2.9,3.8c-0.6,0.6,0,3.5,0,3.5h11.5c0,0-0.3-2.5-0.6-4c-1.6-1-1.7-2.3-2.7-3.6 S34.6,111.3,33.9,112.2z"
      />
      <path
        fill={colors[5]}
        d="M33.8,112.8c-0.3,0.3-0.7,1-1.1,1.4c0.8-0.4,2.5-0.9,4.8,1.3c-3.3-1.9-4-1.5-4.9-1.1 c-0.8,0.4-1.5,1.7-1.5,1.7s2.1-2.1,5.2,0.5c2.2-0.6,2.4,0.3,3.2-1s0-0.9-0.9-2.2S34.7,111.9,33.8,112.8z"
      />
      <path
        fill={colors[6]}
        d="M42.5,119.5H31l-0.1-0.7c0,0,0.2-1.1,2.2-1.2c2.1-0.1,7.1,1.3,7.1,1.3L42.5,119.5z"
      />
      <path
        fill={colors[7]}
        d="M34.4,114.8c-0.1,0.1-0.5-0.2-0.9-0.2c-0.5,0-0.9,0.2-0.9,0.2c-0.1-0.1,0.3-0.5,0.9-0.5 C34.1,114.2,34.5,114.7,34.4,114.8z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M62.3,112.2c0.7,1,2.3,3.2,2.9,3.8c0.6,0.6,0,3.5,0,3.5H53.7c0,0,0.4-2.5,0.6-4c1.6-1,1.7-2.3,2.7-3.6 C58.1,110.6,61.6,111.2,62.3,112.2z"
        />
        <path
          fill={colors[5]}
          d="M62.3,112.8c0.3,0.3,0.7,1,1.1,1.4c-0.8-0.4-2.5-0.9-4.8,1.3c3.3-1.9,4-1.5,4.9-1.1 c0.8,0.4,1.5,1.7,1.5,1.7s-2.1-2.1-5.2,0.5c-2.2-0.6-2.4,0.3-3.2-1c-0.8-1.3,0-0.9,0.9-2.2C58.2,112,61.5,111.9,62.3,112.8z"
        />
        <path
          fill={colors[6]}
          d="M53.6,119.5h11.6l0.2-0.7c0,0-0.2-1.1-2.2-1.2c-2.1-0.1-7.1,1.3-7.1,1.3L53.6,119.5z"
        />
        <path
          fill={colors[7]}
          d="M61.7,114.7c0.1,0.1,0.5-0.2,0.9-0.2c0.5,0,0.9,0.2,0.9,0.2c0.1-0.1-0.3-0.5-0.9-0.5 S61.6,114.7,61.7,114.7z"
        />
      </g>
    </g>
  )
}

export default WizardRobe
