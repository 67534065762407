import React from "react"

const MediumSleve = ({ colors = ["#00A69C", "#262261", "#FFFFFF"] }) => {
  return (
    <g id="MediumSleve">
      <g id="boobs">
        <path
          fill={colors[0]}
          d="M78.6267395,66.4128952c0.4836349-1.0478592,0.8559875-1.9035492,0.9135208-2.0688782 c-0.0395584-0.0191422,0.3245468,1.5247574,0.6872406,2.2099228c1.5603027,3.4686813,2.1713867,4.7305527,2.8162613,6.7187805 c1.6120834-0.9135284,3.8873825-1.555542,4.9083328-1.6092911c-0.1622162-0.5853729-0.2436523-0.3342819-0.5521011-0.7634277 c-0.6682739-3.1390991-1.539978-7.6048737-1.539978-7.6048737s-0.7644119-7.9257545-5.7554626-7.2011986 c-1.168808,0.5844002-2.5884781,1.3573647-2.271637,1.1824951c0.084137-0.0464058-0.2297134,0.1190453-0.8113556,0.384037 c-0.0168152-0.0129013-3.0015564,1.0686493-5.0986786,1.7892151c-1.1873245,0.2883492-2.4444809,0.4945831-3.6674576,0.5133553 c-3.3578796,0.0516014-8.4471436-2.3270874-8.5106087-2.2725487c-0.4921989-0.2273903-0.7478981-0.3626366-0.6706161-0.3200188 c0.316906,0.1748657-1.1027641-0.5980988-2.2715645-1.1824989c-5.0595589-0.4967308-5.7032738,6.9462318-5.7032738,6.9462318 s-0.5096092,2.950367-1.0460396,5.6191063c-0.2620926,1.0960617-0.6761131,1.8947067-0.9253387,2.7491989 c1.021019,0.0537491,3.0950279,0.816452,4.7071724,1.7299881c0.6448097-1.9882355,1.5933418-3.8294373,3.0174179-7.2820358 c0.3626938-0.6851654,0.9233856-1.5329056,0.8837624-1.5137634c0.0575333,0.165329,0.2619705,0.463501,0.7456055,1.5113602 c0.0268402,1.558403,1.3644028,5.0696106,0.663868,6.560257c-0.2629852,0.5596008-2.2932358,3.0174179-2.1725388,4.2243881 c-0.7456093,0.8866653,9.1303596,3.1381149,11.4259529,3.1381149c5.0244522,0,11.1041031-1.6897583,11.989212-2.3334732 c-0.4023209-1.9311447-2.0491104-4.2595825-2.2127762-4.5462418 C77.3419266,71.5308304,78.5998917,67.9712982,78.6267395,66.4128952z"
        />
        <path
          opacity="0.05"
          fill={colors[1]}
          d="M78.1909714,65.2637787 c-0.0336304,0.1897888-0.0538712,0.3796921-0.1109238,0.5688095c-0.7863464,2.6073608-7.1989136,4.4084396-9.3256378,0.1871567 c-0.006485-0.2443848-0.2826691-0.2443237-0.2891388,0.000061c-1.7756424,3.898819-8.5392952,2.4201431-9.3257103-0.1872177 c-0.0569878-0.1891174-0.0772247-0.3790207-0.1109123-0.5688095c-0.1356163,0.7441025-0.1224709,1.5251389,0.1109123,2.2990189 c0.4990463,1.6545181,2.1409912,8.1604691,3.6263618,9.0481796c1.4853745,0.8877106,9.925293,0.5586166,11.6399536-0.0728149 c1.7146606-0.6314392,3.1914597-7.3748322,3.6741714-8.9753647 C78.3134918,66.7889175,78.3266373,66.0078812,78.1909714,65.2637787z"
        />
        <path
          opacity="0.15"
          fill="#FFF"
          d="M60.6441841,62.7043533 c0.1010399-0.1653328,4.1512032-0.6600723,6.7709007,1.6448212c2.6196899,2.3048859-2.0957642,3.5945892-2.0957642,3.5945892 C61.9695702,68.1043472,58.0091553,67.016304,60.6441841,62.7043533z"
        />
        <path
          opacity="0.1"
          fill={colors[1]}
          d="M78.1909714,65.2637787 c-0.0336304,0.1897888-0.0538712,0.3796921-0.1109238,0.5688095c-0.7863464,2.6073608-7.1989059,4.4084396-9.3256378,0.1871567 c-0.006485-0.2443848-0.2826691-0.2443237-0.2891388,0.000061c-1.7756424,3.8988037-8.5392952,2.4201431-9.3257103-0.1872177 c-0.0569878-0.1891174-0.0772247-0.3790207-0.1109123-0.5688095c-0.1356163,0.7441025-0.1224709,1.5251389,0.1109123,2.2990189 c0.7864151,2.6073685,3.5376472,4.0835342,6.1450081,3.2970505c1.7234879-0.5198364,4.9270477-0.5198364,6.6505356,0 c2.6073608,0.7864838,5.3585968-0.689682,6.1449432-3.2970505 C78.3134918,66.7889175,78.3266373,66.0078812,78.1909714,65.2637787z"
        />
        <path
          opacity="0.15"
          fill="#FFF"
          d="M76.6985321,62.7043533 c-0.1010361-0.1653328-4.1511993-0.6600723-6.7708893,1.6448212c-2.6197052,2.3048859,2.0957489,3.5945892,2.0957489,3.5945892 C75.3731537,68.1043472,79.3335648,67.016304,76.6985321,62.7043533z"
        />
      </g>
    </g>
  )
}

export default MediumSleve
