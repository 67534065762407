import set from 'lodash/set'

const uiSchema = {}

const activeSchema = character => uiSchema[character] || {}
const activeSchemaKeys = (character) => Object.keys(activeSchema(character))

const activeCategory = (character) => (category) => {
  const schema = activeSchema(character)
  return schema[category] || []
}

export const uiSchemaService = {
  register: (character, category, schema) => set(uiSchema, [character, category], schema),
  getDefaultEnabled: (character) => {
    return activeSchemaKeys(character).reduce((acc, category) => {
      const enabled = [...activeCategory(character)(category)].filter(ele => (ele.enabled))
      acc[category] = enabled
      return acc
    }, {})
  },
  get: (character) => {
    return activeSchemaKeys(character).reduce((acc, category) => {
      const enabled = [...activeCategory(character)(category)].filter(ele => (ele.inUI))
      if (enabled.length !== 0) acc[category] = enabled
      return acc
    }, {})
  }
}  