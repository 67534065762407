import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Tail = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Tail") ? (
    <g id="Tail">
      <path d="M50.0999985,106.8000031c-3.2999992,0-6-0.5-8.2000008-1.5 c-2.5999985-1.1999969-9.5-4.4000015-10.3999996-12.3000031C30.2000008,81.3000031,41,66.4000015,60.4000015,53.2000008 l0.7999992-0.5c2.7000008-1.2999992,5.1000023-2,7.2999992-2c3.0999985,0,5.9000015,1.2999992,7.8000031,3.5 c3.4000015,4,2.9000015,9.3999977,2.5999985,10.9999962l-3.5,5.0999985c-10.5,6-20.2999992,14.1999969-23.4000015,19.2999954 c4.7999992-0.0999985,13.3000031-1.0999985,17.5-2.5l9.1999969,6.0999985 c0.1999969,2.1999969-0.5999985,4.3000031-2.0999985,5.9000015C72.4000015,103.9000015,57,106.8000031,50.0999985,106.8000031z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Tail, { character: "ManDragonborn" })
