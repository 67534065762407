import React from "react"

const AxeRight = ({
  colors = ["#8B5E3C", "#6D6E71", "#D1D3D4", "#808285"]
}) => {
  return (
    <g id="AxeRight">
      <path
        fill={colors[0]}
        d="M27.3695812,74.4074707l-1-9c-0.2000008-0.0999985-0.3999996-0.3000031-0.6000004-0.4000015 l-3.7999992,0.7000046c-0.1000004,0.1999969-0.2000008,0.4000015-0.2999992,0.5l2.7000008,8.7999954 c0,0,6,24.8000031,7.1000004,30.7000046c0.8999996,4.8000031,1.3999996,11.1999969,1.4999981,13.4000015 c0.2999992,0.1999969,0.6000023,0.3000031,0.9000015,0.4000015l3-1.4000015l-8.7000008-38.3000031L27.3695812,74.4074707z"
      />
      <path
        fill={colors[1]}
        d="M30.569582,64.1074677c-0.6000004,1.5999985-3,3.2000046-6.1000004,3.8000031 s-5.8000011,0.0999985-7-1.2000046c-2.1000004,2-3.1000004,4.5999985-2.6000004,7.2000046 c0.5,2.5999985,2.5,4.5999985,5.1999989,5.6999969c0.6000004-1.5999985,3-3.1999969,6.1000004-3.7999954 c3-0.5999985,5.8000011-0.0999985,6.9999981,1.1999969c2.1000023-2,3.1000023-4.5999985,2.6000023-7.1999969 S33.2695808,65.2074738,30.569582,64.1074677z"
      />
      <g fill={colors[2]}>
        <path d="M16.4695816,74.0074692c-0.2000008-1.1999969,0-2.4000015,0.5-3.5999985 c0-0.0999985-0.5-0.5-0.8000011-0.7999954c0.8000011,0.2999954,1,0.2999954,1.1000004,0.1999969 c0.5-0.7999954,1-1.5,1.8000011-2.1999969c-0.6000004-0.2000046-1.2000008-0.5-1.5-0.9000015 c-2.1000004,2-3.1000004,4.5999985-2.6000004,7.1999969s2.5,4.5999985,5.2000008,5.7000046 c0.1000004-0.4000015,0.3999996-0.7000046,0.7000008-1C18.4695816,77.7074738,16.8695812,76.1074677,16.4695816,74.0074692z" />
        <path d="M30.569582,64.1074677c-0.2000008,0.5-0.6000004,1-1,1.4000015 c2.3999996,0.8000031,4.3000011,2.5,4.6999989,4.8000031c0.0999985,0.6999969,0.0999985,1.4000015,0,2 c0,0.0999985,0.2000008,0.0999985,0.2000008,0.1999969c-0.1000023,0-0.1000023,0-0.2000008,0 c0,0.0999985-0.1000023,0.5-0.1000023,0.5999985s0.2000008,0.3000031,0.7000008,0.8000031 c-0.2999992-0.2000046-0.8999977-0.3000031-1-0.2000046c-0.3999977,0.9000015-1,1.8000031-1.7999992,2.5999985 c0.4000015,0.2000046,0.7999992,0.4000015,1,0.7000046c2.1000023-2,3.1000023-4.5999985,2.6000023-7.2000046 C35.2695808,67.2074738,33.2695808,65.1074677,30.569582,64.1074677z" />
      </g>
      <polygon
        fill={colors[3]}
        points="28.069582,75.7074738 24.1695824,76.4074707 21.8695812,68.2074738 27.1695824,67.1074677"
      />
    </g>
  )
}

export default AxeRight
