import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BowRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BowRight") ? (
    <g id="BowRight">
      <path
        id="S_x24_bowRight"
        d="M62.8,119.4L63,118l0.2-1.8v-0.1l-0.2-1.8l-0.1-0.5l-0.7-1.8l-0.3-0.6l-0.2-0.3L27.9,34.4v-0.2 l-0.2-0.6L26.9,32l-0.3-0.5l-1.2-1.4L25.3,30l-1.5-1.1l-0.9-0.5l-1.7-0.6h-0.1l-1.8-0.2c-4.3,0.2-7.7,3.4-8.4,7.9l-0.1,0.6v0.2v1 c-0.4,1-1.1,2.6-2.3,5.4l-0.4,0.8c-5.8,13.8,19.2,70.7,33.3,75.8h1C45.7,119.4,62.8,119.4,62.8,119.4z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BowRight, { character: "ManDwarf" })
