import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Underwear from "./Underwear"

const CATEGORY = "underGarments"
const CHARACTER = "WomanGnome"
export const IDS = {
  UNDERWEAR: "Underwear"
}

export const components = {
  [IDS.UNDERWEAR]: Underwear
}

export const Group = ({ props }) => (
  <g id="underGarments">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.UNDERWEAR,
    name: "Underwear",
    defaultColors: ["#8CD3D8"],
    colorable: true,
    component: components[IDS.UNDERWEAR],
    inUI: true,
    enabled: true,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
