import React from "react"

const DaggerRight = ({
  colors = [
    "#6E5E26",
    "#99B7D9",
    "#6B91BF",
    "#57789B",
    "#E1E2E3",
    "#9EA0A3",
    "#ECD592"
  ]
}) => {
  return (
    <g id="DaggerRight">
      <polygon
        fill={colors[0]}
        points="29.3204765,80.0861816 28.5209999,81.4670868 30.7740707,82.4846039 31.4281902,84.8830338 32.6637421,87.8629074 32.518383,88.9531021 33.3178596,89.6799011 34.2627029,89.3891754 34.553421,88.2989807 33.8993034,87.6448593 33.1725044,84.3742752 32.1549835,82.0485229 33.3905411,79.8681335 31.8642654,79.2140198"
      />
      <polygon
        fill={colors[1]}
        points="34.1900215,89.3164978 33.8993034,89.0257721 33.4632263,89.1711426 33.3905411,89.6072235"
      />
      <polygon
        fill={colors[2]}
        points="33.3178596,89.6072235 33.3905411,89.1711426 32.9544601,88.8077469 32.518383,88.9531021"
      />
      <polygon
        fill={colors[1]}
        points="34.1900215,89.2438202 33.9719772,88.9531021 34.0446587,88.4443436 34.4807358,88.2989807"
      />
      <polygon
        fill={colors[3]}
        points="33.826622,87.7175446 33.6812592,88.0809479 33.0998268,88.2263031 32.7364273,88.0082626"
      />
      <polygon
        fill={colors[2]}
        points="34.4080582,88.2263031 34.0446587,88.3716583 33.7539368,88.0809479 33.826622,87.7175446"
      />
      <polygon
        fill={colors[3]}
        points="32.8817825,88.7350616 33.0271416,88.2989807 32.7364273,88.0809479 32.5910645,88.8804245"
      />
      <polygon
        fill={colors[2]}
        points="33.9719772,88.3716583 33.826622,88.9531021 33.3905411,89.0984573 33.0271416,88.7350616 33.0998268,88.3716583 33.6812592,88.1536255"
      />
      <path
        fill={colors[4]}
        d="M29.2477951,81.1036835l1.5989532-0.6541138l1.7443161-0.3633957 c0,0-1.0175171-1.8170013-1.6716366-5.6690292c-1.0175171-3.8520279-4.0700645-6.5411758-4.0700645-6.5411758l0,0 L29.2477951,81.1036835z"
      />
      <path
        fill={colors[5]}
        d="M30.8467484,80.4495697l-3.997385-12.5735931c0,0-0.5814419,4.5788269,0.7994766,8.2854919 c1.1628761,2.5437851,1.6716366,5.0149002,1.6716366,5.0149002L30.8467484,80.4495697z"
      />
      <path
        fill={colors[6]}
        d="M32.0823097,86.2639542l0.58144,1.4535904c0.145359,0.0726776,0.3633957,0.0726776,0.5087547,0.0726776 l0.1453552-0.0726776c0.145359-0.0726776,0.2907219-0.1453552,0.3633995-0.2907181l-0.5087547-2.5437927 C32.8817825,85.4644699,32.5910645,85.9005585,32.0823097,86.2639542z"
      />
      <path
        fill={colors[6]}
        d="M32.8091087,83.7928467l-0.726799-1.6716385l-0.5087585,0.7267914l-0.7267952-0.2907181 l0.5814381,2.1803894l0.5087566,1.1628799C32.4457092,85.4644699,32.7364273,84.4469604,32.8091087,83.7928467z"
      />
    </g>
  )
}

export default DaggerRight
