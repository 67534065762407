import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const RoundWoodShieldRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("RoundWoodShieldRight") ? (
    <g id="RoundWoodShieldRight">
      <path
        id="S_x24_roundWoodShieldRight"
        d="M26.8,99.8c-8.9,0-16.7-5.6-19.6-14C3.5,74.9,9.3,63.2,20.1,59.5c2.1-0.7,4.4-1.1,6.6-1.1 c8.9,0,16.8,5.6,19.6,14c1.8,5.2,1.4,10.8-1,15.8s-6.7,8.7-11.9,10.4C31.3,99.4,29,99.8,26.8,99.8L26.8,99.8z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(RoundWoodShieldRight, {
  character: "ManDwarf"
})
