import React from "react"

const GoldAxeLeft = ({ colors = ["#8A7441", "#A49255", "#EECE6E"] }) => {
  return (
    <g id="GoldAxeLeft">
      <path
        fill={colors[0]}
        d="M88.5,59.4c-1.7,0.9-4.7,0.5-7.6-1.1s-4.8-4-4.9-5.8c-3.1,0.2-5.8,1.6-7.2,4.1c-1.4,2.5-1.2,5.5,0.3,8.2 c1.7-0.9,4.7-0.5,7.6,1.1s4.8,4,4.9,5.8c3.1-0.2,5.8-1.6,7.2-4.1C90.1,65.1,89.9,62.1,88.5,59.4z"
      />
      <path
        fill={colors[1]}
        d="M78.8,65.6l5.5-8.1c-0.1-0.3-0.1-0.5-0.2-0.8l-3.6-2c-0.2,0.1-0.4,0.1-0.7,0.2l-4,9.1 c0,0-12.3,24.4-15.5,30c-2.6,4.5-6.6,10.1-8,12c0.1,0.3,0.3,0.7,0.5,1l3.4,0.9l19.4-37.3L78.8,65.6z"
      />
      <path
        fill={colors[0]}
        d="M52.9,106.9c-0.1-0.1,0.3-0.3,0.2-0.5c1.7-2.3,5.6-7.8,8.1-12.1c3.2-5.5,15.3-29.6,15.4-29.8l3.8-8.8 c0.1,0,0.5-0.7,0.5-0.8l2.8,1.7c0,0.1,0,0.7-0.2,0.6L78.2,65l-3,5.1l-19.3,37.5L52.9,106.9z"
      />
      <path
        fill={colors[2]}
        d="M53.1,106.9c1.7-2.3,5.8-8.2,8.2-12.4c3-5.2,14.2-27.5,15.5-30l4.5-9.3l1.8,1l-5.2,8.7l-3.2,5l-19.2,37.7 L53.1,106.9z"
      />
      <g fill={colors[2]}>
        <path d="M70.1,57.6c0.6-1.2,1.7-2,2.9-2.5c0.1-0.1-0.1-0.7-0.1-1.2c0.4,0.8,0.7,0.9,0.8,0.9 c0.9-0.3,1.9-0.5,2.9-0.5C76.2,53.7,76,53,76,52.5c-3.1,0.2-5.8,1.6-7.2,4.1c-1.4,2.5-1.2,5.5,0.3,8.2c0.4-0.2,0.8-0.3,1.3-0.4 C69.2,62.1,69,59.6,70.1,57.6z" />
        <path d="M88.5,59.3c-0.5,0.3-1.1,0.4-1.8,0.4c1.4,2.4,1.7,5,0.6,7.2c-0.3,0.6-0.9,1.2-1.4,1.7c0,0,0.1,0.3,0,0.3 c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0.1-0.4,0.3-0.5,0.4s-0.1,0.3,0.1,1.1c-0.1-0.3-0.6-0.8-0.7-0.8c-1,0.5-2.1,0.8-3.3,0.9 c0.2,0.5,0.3,0.9,0.4,1.3c3.1-0.2,5.8-1.6,7.2-4.1C90.2,65.1,89.9,62.1,88.5,59.3z" />
      </g>
    </g>
  )
}

export default GoldAxeLeft
