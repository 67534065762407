import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongswordRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongswordRight") ? (
    <g id="LongswordRight">
      <path
        id="S_x24_longswordRight"
        d="M26.6,93.7c-4.1,0-7.5-3.1-7.9-7.2V86c-0.1-1.1,0-2.1,0.3-3.1l-0.3-3.1c-2.7-1.1-4.5-3.3-5.1-6 c-0.9-4,1.4-7.3,3-9l-4.2-42.2c-1.2-11.4,1.1-14.7,3.7-16.5l7.2-0.5c3.6,1.8,5.4,6,6.5,15.1L34,62.9c1.4,1,4.7,3.8,4.7,8.2 c0,2.8-1.4,5.3-3.8,7l0.3,3.3c0.5,0.9,0.7,1.9,0.8,2.9v0.5c0.4,4.4-2.8,8.3-7.2,8.7l-1.6,0.2H26.6z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongswordRight, {
  character: "ManDwarf"
})
