import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import WhiteCollar from "./WhiteCollar"
import TuckedInShirt from "./TuckedInShirt"

const CATEGORY = "shirts"
const CHARACTER = "ManDwarf"
export const IDS = {
  WHITE_COLLAR: "WhiteCollar",
  TUCKED_IN_SHIRT: "TuckedInShirt"
}

export const components = {
  [IDS.WHITE_COLLAR]: WhiteCollar,
  [IDS.TUCKED_IN_SHIRT]: TuckedInShirt
}

export const Group = ({ props }) => (
  <g id="shirts">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.WHITE_COLLAR,
    name: "White Collar",
    defaultColors: ["#F1F2F2"],
    colorable: true,
    component: components[IDS.WHITE_COLLAR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.TUCKED_IN_SHIRT,
    name: "Tucked In Shirt",
    defaultColors: ["#EDE3C7", "#262262", "#8D6945"],
    colorable: true,
    component: components[IDS.TUCKED_IN_SHIRT],
    inUI: true,
    enabled: true,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
