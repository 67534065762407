import React from "react"

const MaceR = ({
  colors = ["#595A5B", "#B8B9BA", "#777676", "#9A9B9C", "#E0E1E1", "#E4E3E3"]
}) => {
  return (
    <g id="MaceR">
      <path
        opacity="0.53"
        d="M54.3536186,91.1731033l-0.5586205-1.0474014l-0.069828-0.2094879 l-0.0698242-0.9077454v-0.1396561l-6.4240913-16.4093628c-0.2094841,0.0698242-0.4189606,0.0698242-0.4189606,0.0698242 l-0.3491364-0.2094803l-0.3491364-0.907753c-0.1396523-0.1396561-0.6982689-0.7680969-2.1646385-0.907753l-0.1396523-0.4189606 l-0.1396523-0.0698242l0,0h-0.0698242l0.0698242-0.0698318l0,0v-0.0698242 c0.069828-0.0698242,0.069828-0.2793045-0.0698242-0.6982727c-0.1396523-0.3491287-0.3491364-0.6982651-0.3491364-0.6982651l0,0 l-0.0698242-0.0698318v-0.2793045l1.2568893-2.9327393l0.2793083-0.2094803c0,0,0.069828,0,0.069828-0.0698242 l-0.2094841-0.907753l0.6284447-0.2793121l0.4887772,0.8379211l0,0l0.4189606-0.0698242l3.0025673,1.1870575l0.2094841,0.2793121 l0,0l0,0l0,0l0,0c0,0,0.0698242,0.4189606,0.2094841,0.7680969c0.1396523,0.4189606,0.3491325,0.5586166,0.3491325,0.5586166l0,0 v0.0698242l0,0h0.1396523l-0.0698242,0.1396561l0.0698242,0.0698242l0.2094841,0.3491364l-0.0698242-0.0698242 l0.0698242,0.1396561c-0.977581,1.0474014-0.977581,1.8853302-0.977581,2.0948105l0.3491364,0.907753l-0.1396523,0.4189606l0,0 c0,0-0.1396523,0.0698242-0.3491364,0.2094803l6.4240913,16.4093628l0.6284447,0.8379288l0.0698204,0.1396561l0,0v0.0698242 l0.2793121,1.1870575l-0.1396523,0.3491364c-0.069828,0.0698242-0.5586205,0.3491364-0.837925,0.4887924 c-0.2793083,0.1396484-0.907753,0.2094803-0.977581,0.2094803L54.3536186,91.1731033z"
      />
      <path
        fill={colors[0]}
        d="M48.9769325,66.1051788l-2.9327354-1.2568817c-0.2094841,0.1396561-0.3491364,0.0698242-0.4887886,0 l-0.5586166-0.907753l0.2094803,1.0474091c-0.0698242,0.2094803-0.1396484,0.2793045-0.4189606,0.3491287l-1.2568893,2.9327393 c0,0,0.907753,1.4663696,0.4189606,1.955162c1.815506,0.1396561,2.4439507,1.0474014,2.4439507,1.0474014l0.3491287,0.9775772 l0.6284447-0.0698242l6.5637474,16.7585068l0.069828,1.1172333l0.5586166,1.0474014l0.9077568-0.2094803l0.8379211-0.4887924 l-0.2793083-1.1870575l-0.6982727-0.8379211l-6.5637474-16.7584991l0.5586205-0.4189606l-0.3491364-0.9775848 c0,0-0.1396523-1.0474091,1.0474052-2.4439468C49.3260689,67.7810287,48.9769325,66.1051788,48.9769325,66.1051788z"
      />
      <path
        fill={colors[1]}
        d="M49.0467529,66.0353546l-2.9327354-1.2568893c-0.1396523,0.1396561-0.3491364,0.0698242-0.4887886,0 l-0.5586166-0.9077492l0.2094841,1.0474052c-0.069828,0.2094803-0.2094803,0.3491364-0.4189644,0.3491364l-1.2568893,2.9327393 c0,0,0.9077492,1.466362,0.4189606,1.9551544c1.815506,0.1396561,2.4439507,1.0474091,2.4439507,1.0474091l0.3491364,0.9775772 l0.6284447-0.0698242l6.5637474,16.7584991l0.0698242,1.1172333l0.5586205,1.0474091 c0,0,0.6284447-0.0698318,0.907753-0.2094803c0.2793083-0.0698318,0.7680969-0.4189682,0.7680969-0.4189682 l-0.2793083-1.1172333l-0.6982727-0.8379211l-6.5637474-16.7584991l0.5586205-0.4189682l-0.3491364-0.9775772 c0,0-0.1396523-1.1172333,1.0474052-2.4439468C49.3260689,67.8508606,49.0467606,66.1750107,49.0467529,66.0353546z M46.8122902,70.9232559l-1.955162-1.3267212l-0.5586205-1.5361938l0.6982727-2.3741226l0.2094841,1.9551544 l0.5586166,1.5362015l1.3267174,1.6758499L46.8122902,70.9232559z M48.4183121,70.2948074l-0.2793083,0.1396561 l-0.1396523-2.0948105l-0.6284447-1.5362015l-1.1870613-1.5361938l2.0948105,1.2568893l0.6284447,1.5361938 L48.4183121,70.2948074z"
      />
      <path
        fill={colors[2]}
        d="M49.3260689,71.2723846c0,0-0.4887886,0.4189606-1.1870575,0.6982727 c-0.6982727,0.2793045-1.3267174,0.3491364-1.3267174,0.3491364l-0.3491364-0.907753 c0,0,0.977581-0.2793121,1.3267174-0.4189606c0.3491364-0.1396561,1.2568893-0.5586166,1.2568893-0.5586166 L49.3260689,71.2723846z"
      />
      <path
        fill={colors[2]}
        d="M55.9596405,89.2179489c0,0,0,0.2094879-0.8379211,0.5586243 c-0.837925,0.3491364-1.0474052,0.2094803-1.0474052,0.2094803l-0.1396523-1.1172333 c0,0,0.3491325,0.0698242,0.6982689-0.0698242c0.3491364-0.1396561,0.6284447-0.4189606,0.6284447-0.4189606 L55.9596405,89.2179489z"
      />
      <g>
        <path
          fill={colors[3]}
          d="M48.8372765,68.1301651l-0.4887886,2.1646423l-0.2793083,0.1396484l-0.1396523-1.9551544 l-2.0249863,0.7680969l1.1870575,1.5361938l-0.2793083,0.1396561l-1.815506-1.2568893l-0.907753,0.3491364 c0,0.0698318-0.069828,0.1396561-0.069828,0.1396561c1.815506,0.1396561,2.4439507,1.0474091,2.4439507,1.0474091 l0.3491364,0.9775772l0,0l-0.3491364-0.907753c0,0,0.977581-0.2793045,1.3267136-0.4189606 c0.3491364-0.1396561,1.2568932-0.5586166,1.2568932-0.5586166l0.3491325,0.907753l0,0l-0.3491325-0.9775772 c0,0-0.1396523-1.0474091,1.0474052-2.4439468c-0.069828,0-0.1396523,0-0.2094841-0.0698318L48.8372765,68.1301651z"
        />
        <path
          fill={colors[4]}
          d="M44.2985153,67.9905167L44.2985153,67.9905167l0.6982689-2.3741226l0.2094841,1.9551544v0.0698242 l2.0948143-0.8379211l-0.069828-0.0698242l-1.1870575-1.5362015l2.0948143,1.2568893l0,0l0.7680969-0.2793121 c0-0.0698242,0-0.0698242,0-0.0698242l-2.9327393-1.2568893c-0.2094803,0.1396561-0.3491325,0.0698318-0.4887848,0 l-0.5586205-0.9077492l0.2094841,1.0474052c-0.0698242,0.2094803-0.1396523,0.2793121-0.4189606,0.3491364l-1.2568893,2.9327393 c0,0,0,0,0.0698242,0.0698242L44.2985153,67.9905167z"
        />
        <polygon
          fill={colors[5]}
          points="48.0691795,72.0404816 54.4932671,88.7291565 54.8424034,88.6593323 48.3484879,72.0404816"
        />
      </g>
    </g>
  )
}

export default MaceR
