import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GoatHorns = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GoatHorns") ? (
    <g id="GoatHorns">
      <path
        id="goatHorns"
        fill="#050505"
        d="M83.6,3.6c0-3.9-3.2-7.2-7.2-7.2c-1.8,0-4.6,0.7-7.2,3.9c-2.6-3.2-5.4-3.9-7.2-3.9 c-4,0-7.2,3.2-7.2,7.2c0,0,2,5.3,3,9.3l6.3,0.9l5.1-3.1l5.1,3.1l6.3-0.9C81.6,8.9,83.6,3.6,83.6,3.6z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GoatHorns, { character: "ManMixed" })
