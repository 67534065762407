import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import DragonFace from "./DragonFace"
import OrcFace from "./OrcFace"
import Tusks from "./Tusks"

const CATEGORY = "face"
const CHARACTER = "ManDragonborn"
export const IDS = {
  DRAGON_FACE: "DragonFace",
  ORC_FACE: "OrcFace",
  TUSKS: "Tusks"
}

export const components = {
  [IDS.DRAGON_FACE]: DragonFace,
  [IDS.ORC_FACE]: OrcFace,
  [IDS.TUSKS]: Tusks
}

export const Group = ({ props }) => (
  <g id="face">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.DRAGON_FACE,
    name: "Dragon Face",
    defaultColors: [
      "#99999A",
      "#DEA043",
      "#21366C",
      "#FFFFFF",
      "#231F20",
      "#F6F7F7",
      "#5C5C5C",
      "#3F4040"
    ],
    colorable: true,
    component: components[IDS.DRAGON_FACE],
    inUI: true,
    enabled: true,
    subGroupId: 0
  },
  {
    id: IDS.ORC_FACE,
    name: "Orc Face",
    defaultColors: ["#FBFF45", "#FFFFFF", "#SVGID_", "#21366C", "#ED1C24"],
    colorable: true,
    component: components[IDS.ORC_FACE],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.TUSKS,
    name: "Tusks",
    defaultColors: ["#B7B7B7"],
    colorable: true,
    component: components[IDS.TUSKS],
    inUI: true,
    enabled: false,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
