import React from "react"

const CodPiece = ({
  colors = [
    "#964922",
    "#FFFFFF",
    "#304B60",
    "#B3B3B3",
    "#9D9D9D",
    "#CCCCCC",
    "#4D4D4D",
    "#36479C"
  ]
}) => {
  return (
    <g id="CodPiece">
      <polygon
        opacity="0.3"
        fill="#21376C"
        points="79.8,75.8 77.2,63.6 71.5,65.8 67,66 67,66.1 67,65.9 61,63.6 58.4,75.8 64.5,77.2 63.3,85.4 69.2,80.3 75.2,85.4 74.1,77.1"
      />
      <polygon fill={colors[0]} points="64.4,56.1 58.4,74.4 65.4,76 67,58.5" />
      <polygon
        fill={colors[0]}
        points="74.4,56.2 79.8,74.4 72.8,76 71.2,58.5"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="64.4,56.1 58.4,74.4 65.4,76 67,58.5"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="74.4,56.2 79.8,74.4 72.8,76 71.2,58.5"
      />
      <polygon
        fill={colors[0]}
        points="75.3,84 71.6,58.4 67,58.6 63.4,84 69.2,78.9"
      />
      <path
        fill={colors[2]}
        d="M56.6,58.8l1-4.1c0,0,6.1,0,12.4,0c3.3,0,10.8,0,10.8,0l0.9,4.6c0,0,0.1,1.4-0.5,1.6 C79,61.6,72.8,62,69.3,62c-3.4,0-9.5-0.8-11.9-1.7C56.6,60.1,56.6,58.8,56.6,58.8z"
      />
      <path
        fill={colors[0]}
        d="M76,72.5l-1.6-13.9c-0.2-1.2,1.1-3.5-0.9-3.5h-8.1c-1.9,0-0.6,2.3-0.8,3.5L63,72.5c0,0,2.9,0.7,6.4,0.7 S76,72.5,76,72.5z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M76,72.5l-1.6-13.9c-0.2-1.2,1.1-3.5-0.9-3.5h-8.1 c-1.9,0-0.6,2.3-0.8,3.5L63,72.5c0,0,2.9,0.7,6.4,0.7S76,72.5,76,72.5z"
      />
      <g>
        <polygon fill={colors[3]} points="70.5,70.5 70.5,71.2 70.5,71.2" />
        <polygon
          fill={colors[4]}
          points="70.6,70.4 70.5,70.5 70.5,71.2 70.6,71.2 70.6,70.5"
        />
        <polygon fill="#CCC" points="70.6,71.2 71.3,71.2 70.6,70.5" />
        <polygon fill={colors[4]} points="69.9,71.2 70.5,71.2 70.5,70.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,70.3 70.7,70.3 71.4,71 71.4,71.1 71.5,71"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,70.3 70.7,70.3 71.4,71 71.4,71.1 71.5,71"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71 71.4,71.1 71.4,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71 71.4,71.1 71.4,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71 71.4,71.1 71.4,71.1"
        />
        <polygon fill={colors[4]} points="70.6,70.4 71.4,71 70.7,70.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,70.4 71.4,71 70.7,70.3"
        />
        <polygon fill={colors[4]} points="70.6,70.4 70.6,70.4 70.6,70.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,70.4 70.6,70.4 70.6,70.5"
        />
        <polygon
          fill="#CCC"
          points="71.4,71.1 71.4,71.1 71.4,71 70.6,70.4 70.6,70.4 70.6,70.5 71.3,71.2 71.3,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,71.1 71.4,71.1 71.4,71 70.6,70.4 70.6,70.4 70.6,70.5 71.3,71.2 71.3,71.2"
        />
        <polygon
          fill={colors[3]}
          points="71.5,69.6 71.5,70.9 70.7,70.3 70.7,70.3 71.5,71 71.5,71 71.5,71 71.5,69.5"
        />
        <polygon
          fill={colors[4]}
          points="71.6,69.5 71.6,69.5 71.5,69.5 71.5,71 71.8,70.7 71.6,70.9"
        />
        <polygon
          fill="#CCC"
          points="72.3,70.2 72.3,70.2 71.6,69.5 71.6,70.9 71.8,70.7"
        />
        <polygon fill={colors[4]} points="70.7,70.3 71.5,70.9 71.5,69.6" />
        <polygon fill={colors[4]} points="70.7,70.3 70.6,70.4 70.6,70.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,70.3 70.6,70.4 70.6,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,70.3 70.6,70.4 70.6,70.4"
        />
        <polygon fill={colors[3]} points="70.9,69.9 71.5,69.4 71.4,69.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,69.9 71.5,69.4 71.4,69.3"
        />
        <polygon
          fill={colors[3]}
          points="71.5,69.4 71.5,69.6 71.5,69.5 71.5,69.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,69.4 71.5,69.6 71.5,69.5 71.5,69.5"
        />
        <polygon
          fill={colors[4]}
          points="71.5,69.4 70.9,69.9 70.6,70.1 70.8,70.3 70.7,70.3 70.7,70.3 71.5,69.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,69.4 70.9,69.9 70.6,70.1 70.8,70.3 70.7,70.3 70.7,70.3 71.5,69.6"
        />
        <polygon
          fill={colors[3]}
          points="70.6,68.6 70.5,68.7 70.5,70 70,69.5 70.5,70.1 70.6,70.1"
        />
        <polygon
          fill={colors[4]}
          points="71.4,69.3 70.7,70 70.7,68.6 70.6,68.6 70.6,68.6 70.6,70.1 70.6,70.1 70.9,69.9"
        />
        <polygon
          fill="#CCC"
          points="71.3,69.3 71.3,69.3 70.7,68.6 70.7,70 71.4,69.3"
        />
        <polygon
          fill={colors[4]}
          points="70.5,68.7 69.8,69.4 70,69.5 70.5,70"
        />
        <polygon fill={colors[3]} points="70,69 70.5,68.5 70.5,68.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70,69 70.5,68.5 70.5,68.4"
        />
        <polygon
          fill={colors[3]}
          points="70.5,68.5 70.5,68.7 70.6,68.6 70.6,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.5 70.5,68.7 70.6,68.6 70.6,68.5"
        />
        <polygon fill={colors[4]} points="70.6,68.6 70.6,68.6 70.6,68.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,68.6 70.6,68.6 70.6,68.5"
        />
        <polygon
          fill={colors[4]}
          points="70.5,68.5 70,69 69.7,69.2 69.8,69.3 69.8,69.4 70.5,68.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.5 70,69 69.7,69.2 69.8,69.3 69.8,69.4 70.5,68.7"
        />
        <polygon
          fill={colors[3]}
          points="69.7,67.6 69.6,67.6 69.6,67.6 69.6,69 69.1,68.6 69.6,69.1 69.7,69.2"
        />
        <polygon
          fill={colors[4]}
          points="70.5,68.4 70.5,68.4 69.7,69 69.7,67.7 69.7,67.6 69.7,69.2 69.7,69.2 70,69"
        />
        <polygon
          fill="#CCC"
          points="70.4,68.4 70.4,68.4 69.7,67.7 69.7,69 70.5,68.4"
        />
        <polygon
          fill={colors[4]}
          points="69.1,68.6 69.6,69 69.6,67.6 68.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,68.4 70.8,68.5 71.4,69.1 71.5,69.1 71.5,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,68.4 70.8,68.5 71.4,69.1 71.5,69.1 71.5,69.1"
        />
        <polygon fill={colors[4]} points="70.7,68.5 71.4,69.1 70.8,68.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,68.5 71.4,69.1 70.8,68.5"
        />
        <polygon fill={colors[4]} points="70.6,68.6 70.7,68.6 70.7,68.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,68.6 70.7,68.6 70.7,68.6"
        />
        <polygon
          fill="#CCC"
          points="71.3,69.3 71.5,69.1 71.5,69.1 71.4,69.1 70.7,68.5 70.7,68.6 70.7,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3,69.3 71.5,69.1 71.5,69.1 71.4,69.1 70.7,68.5 70.7,68.6 70.7,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,68.5 70.8,68.4 70.8,68.4 70.8,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,68.5 70.8,68.4 70.8,68.4 70.8,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,68.5 70.8,68.4 70.8,68.4 70.8,68.4"
        />
        <polygon
          fill={colors[3]}
          points="70.5,68.4 70.5,68.5 70.5,68.5 70.5,68.5 70.6,68.5 70.6,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.4 70.5,68.5 70.5,68.5 70.5,68.5 70.6,68.5 70.6,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.4 70.5,68.5 70.5,68.5 70.5,68.5 70.6,68.5 70.6,68.5"
        />
        <polygon
          fill={colors[4]}
          points="70.7,68.5 70.6,68.6 70.6,68.5 70.6,68.5 70.6,68.6 70.7,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,68.5 70.6,68.6 70.6,68.5 70.6,68.5 70.6,68.6 70.7,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,68.5 70.6,68.6 70.6,68.5 70.6,68.5 70.6,68.6 70.7,68.6"
        />
        <polygon
          fill={colors[4]}
          points="70.8,68.4 70.7,68.5 70.7,68.5 70.8,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,68.4 70.7,68.5 70.7,68.5 70.8,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,68.4 70.7,68.5 70.7,68.5 70.8,68.5"
        />
        <polygon fill="#CCC" points="70.4,68.4 70.4,68.4 70.5,68.4 70.5,68.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,68.4 70.4,68.4 70.5,68.4 70.5,68.4"
        />
        <polygon
          fill={colors[3]}
          points="71.5,69 70.8,68.4 70.8,68.4 70.8,68.4 71.5,69.1 71.5,69.1 71.6,69.1 71.6,67.7 71.5,67.7"
        />
        <polygon
          fill={colors[4]}
          points="71.6,67.7 71.6,69.1 71.6,69 71.6,69 71.6,67.7 71.6,67.6"
        />
        <polygon fill="#CCC" points="71.6,69 71.6,69 72.3,68.3 71.6,67.7" />
        <polygon fill={colors[4]} points="70.8,68.4 71.5,69 71.5,67.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,67.5 70.5,68.2 70.5,68.2 70.6,68.2 69.8,67.4 69.8,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,67.5 70.5,68.2 70.5,68.2 70.6,68.2 69.8,67.4 69.8,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.2 70.6,68.2 70.6,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.2 70.6,68.2 70.6,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.2 70.6,68.2 70.6,68.2"
        />
        <polygon fill={colors[3]} points="69.6,67.6 69.7,67.6 69.7,67.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,67.6 69.7,67.6 69.7,67.6"
        />
        <polygon
          fill={colors[4]}
          points="70.5,68.2 69.8,67.5 69.7,67.6 69.7,67.6 69.7,67.7 69.7,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.2 69.8,67.5 69.7,67.6 69.7,67.6 69.7,67.7 69.7,67.6"
        />
        <polygon
          fill="#CCC"
          points="70.4,68.4 70.5,68.2 70.5,68.2 70.5,68.2 69.7,67.6 69.7,67.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,68.4 70.5,68.2 70.5,68.2 70.5,68.2 69.7,67.6 69.7,67.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,68.3 70.6,68.3 70.8,68.4 70.8,68.4 70.7,68.4 70.6,68.3 70.6,68.2 70.6,68.2 70.5,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,68.3 70.6,68.3 70.8,68.4 70.8,68.4 70.7,68.4 70.6,68.3 70.6,68.2 70.6,68.2 70.5,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,68.3 70.6,68.3 70.8,68.4 70.8,68.4 70.7,68.4 70.6,68.3 70.6,68.2 70.6,68.2 70.5,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,68.3 70.6,68.3 70.8,68.4 70.8,68.4 70.7,68.4 70.6,68.3 70.6,68.2 70.6,68.2 70.5,68.2"
        />
        <polygon
          fill={colors[3]}
          points="70.5,68.4 70.5,68.4 70.6,68.5 70.6,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.4 70.5,68.4 70.6,68.5 70.6,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.4 70.5,68.4 70.6,68.5 70.6,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.4 70.5,68.4 70.6,68.5 70.6,68.3"
        />
        <polygon
          fill={colors[4]}
          points="70.7,68.5 70.8,68.4 70.6,68.3 70.6,68.3 70.6,68.5 70.6,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,68.5 70.8,68.4 70.6,68.3 70.6,68.3 70.6,68.5 70.6,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,68.5 70.8,68.4 70.6,68.3 70.6,68.3 70.6,68.5 70.6,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,68.5 70.8,68.4 70.6,68.3 70.6,68.3 70.6,68.5 70.6,68.6"
        />
        <polygon
          fill="#CCC"
          points="70.5,68.2 70.4,68.4 70.5,68.4 70.5,68.4 70.6,68.3 70.6,68.3 70.6,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.2 70.4,68.4 70.5,68.4 70.5,68.4 70.6,68.3 70.6,68.3 70.6,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,68.2 70.4,68.4 70.5,68.4 70.5,68.4 70.6,68.3 70.6,68.3 70.6,68.3"
        />
        <polygon fill={colors[3]} points="71.4,67.5 70.9,68 71.5,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,67.5 70.9,68 71.5,67.5"
        />
        <polygon
          fill={colors[3]}
          points="71.5,67.7 71.6,67.7 71.6,67.6 71.5,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.7 71.6,67.7 71.6,67.6 71.5,67.5"
        />
        <polygon fill={colors[3]} points="70.8,68.4 70.8,68.4 70.7,68.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,68.4 70.8,68.4 70.7,68.4"
        />
        <polygon fill={colors[4]} points="71.6,67.6 71.6,67.6 71.6,67.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,67.6 71.6,67.6 71.6,67.7"
        />
        <polygon
          fill={colors[4]}
          points="70.7,68.4 70.8,68.4 71.5,67.7 71.5,67.5 71.5,67.5 70.9,68 70.6,68.3 70.6,68.2 70.6,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,68.4 70.8,68.4 71.5,67.7 71.5,67.5 71.5,67.5 70.9,68 70.6,68.3 70.6,68.2 70.6,68.3"
        />
        <polygon
          fill={colors[3]}
          points="70.6,66.7 70.6,68.1 69.8,67.4 70.6,68.2 70.6,68.2 70.6,68.2 70.6,68.3 70.6,66.7 70.6,66.6"
        />
        <polygon
          fill={colors[4]}
          points="70.6,68.3 70.9,68 71.4,67.5 71.4,67.5 70.7,68.1 70.7,66.7 70.6,66.7"
        />
        <polygon
          fill="#CCC"
          points="71.4,67.5 71.4,67.4 71.4,67.4 70.7,66.7 70.7,68.1"
        />
        <polygon
          fill={colors[4]}
          points="69.8,67.4 69.8,67.4 69.8,67.4 69.8,67.4 70.6,68.1 70.6,66.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,67.5 72.4,68.1 72.4,68.2 72.4,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,67.5 72.4,68.1 72.4,68.2 72.4,68.2"
        />
        <polygon fill={colors[4]} points="72.4,68.1 71.7,67.5 71.7,67.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,68.1 71.7,67.5 71.7,67.6"
        />
        <polygon fill={colors[4]} points="71.6,67.7 71.6,67.6 71.6,67.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,67.7 71.6,67.6 71.6,67.6"
        />
        <polygon
          fill="#CCC"
          points="71.7,67.6 71.6,67.6 71.6,67.7 72.3,68.3 72.4,68.2 72.4,68.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,67.6 71.6,67.6 71.6,67.7 72.3,68.3 72.4,68.2 72.4,68.1"
        />
        <polygon fill={colors[3]} points="71.5,67.5 71.6,67.6 71.6,67.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.5 71.6,67.6 71.6,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.5 71.6,67.6 71.6,67.6"
        />
        <polygon fill={colors[3]} points="71.5,67.5 71.4,67.5 71.4,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.5 71.4,67.5 71.4,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.5 71.4,67.5 71.4,67.5"
        />
        <polygon fill="#CCC" points="71.6,67.6 71.6,67.6 71.7,67.6 71.7,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,67.6 71.6,67.6 71.7,67.6 71.7,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,67.6 71.6,67.6 71.7,67.6 71.7,67.5"
        />
        <polygon fill="#CCC" points="71.4,67.5 71.4,67.5 71.4,67.4 71.4,67.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,67.5 71.4,67.5 71.4,67.4 71.4,67.4"
        />
        <polygon
          fill={colors[3]}
          points="71.7,67.5 71.7,67.5 71.7,67.5 72.4,68.2 72.5,68.1 72.5,68.1 72.5,68.1 72.5,66.7 72.5,66.8 72.5,68"
        />
        <polygon
          fill={colors[4]}
          points="72.6,66.7 72.5,66.7 72.5,68.1 72.6,68 72.6,68"
        />
        <polygon
          fill="#CCC"
          points="72.6,68 72.6,68 73.3,67.3 73.3,67.3 73.3,67.3 72.6,66.6 72.6,66.7"
        />
        <polygon fill={colors[4]} points="71.7,67.5 72.5,68 72.5,66.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.5 71.5,67.2 71.5,67.3 71.5,67.3 70.8,66.5 70.8,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.5 71.5,67.2 71.5,67.3 71.5,67.3 70.8,66.5 70.8,66.5"
        />
        <polygon fill={colors[3]} points="70.6,66.7 70.6,66.6 70.6,66.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.7 70.6,66.6 70.6,66.6"
        />
        <polygon
          fill={colors[4]}
          points="70.6,66.7 70.7,66.7 70.7,66.6 71.5,67.2 70.7,66.5 70.6,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.7 70.7,66.7 70.7,66.6 71.5,67.2 70.7,66.5 70.6,66.6"
        />
        <polygon
          fill="#CCC"
          points="70.7,66.7 71.4,67.4 71.5,67.3 71.5,67.2 70.7,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.7 71.4,67.4 71.5,67.3 71.5,67.2 70.7,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,67.5 71.7,67.5 71.7,67.4 71.6,67.3 71.6,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,67.5 71.7,67.5 71.7,67.4 71.6,67.3 71.6,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,67.5 71.7,67.5 71.7,67.4 71.6,67.3 71.6,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,67.5 71.7,67.5 71.7,67.4 71.6,67.3 71.6,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.2 71.5,67.2 71.5,67.3 71.5,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.2 71.5,67.2 71.5,67.3 71.5,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.2 71.5,67.2 71.5,67.3 71.5,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.2 71.5,67.2 71.5,67.3 71.5,67.3"
        />
        <polygon
          fill={colors[3]}
          points="71.5,67.5 71.5,67.5 71.5,67.5 71.6,67.6 71.6,67.4 71.5,67.4 71.4,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.5 71.5,67.5 71.5,67.5 71.6,67.6 71.6,67.4 71.5,67.4 71.4,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.5 71.5,67.5 71.5,67.5 71.6,67.6 71.6,67.4 71.5,67.4 71.4,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.5 71.5,67.5 71.5,67.5 71.6,67.6 71.6,67.4 71.5,67.4 71.4,67.5"
        />
        <polygon
          fill={colors[4]}
          points="71.6,67.6 71.6,67.6 71.6,67.5 71.7,67.5 71.7,67.5 71.6,67.3 71.6,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,67.6 71.6,67.6 71.6,67.5 71.7,67.5 71.7,67.5 71.6,67.3 71.6,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,67.6 71.6,67.6 71.6,67.5 71.7,67.5 71.7,67.5 71.6,67.3 71.6,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,67.6 71.6,67.6 71.6,67.5 71.7,67.5 71.7,67.5 71.6,67.3 71.6,67.4"
        />
        <polygon fill="#CCC" points="71.6,67.6 71.7,67.5 71.6,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,67.6 71.7,67.5 71.6,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,67.6 71.7,67.5 71.6,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,67.6 71.7,67.5 71.6,67.5"
        />
        <polygon fill={colors[4]} points="71.5,67.4 71.4,67.5 71.4,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.4 71.4,67.5 71.4,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.4 71.4,67.5 71.4,67.5"
        />
        <polygon
          fill="#CCC"
          points="71.4,67.4 71.4,67.5 71.5,67.4 71.6,67.4 71.6,67.3 71.6,67.3 71.5,67.3 71.6,67.3 71.5,67.3 71.5,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,67.4 71.4,67.5 71.5,67.4 71.6,67.4 71.6,67.3 71.6,67.3 71.5,67.3 71.6,67.3 71.5,67.3 71.5,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,67.4 71.4,67.5 71.5,67.4 71.6,67.4 71.6,67.3 71.6,67.3 71.5,67.3 71.6,67.3 71.5,67.3 71.5,67.3"
        />
        <polygon
          fill={colors[3]}
          points="72.4,66.5 71.9,67 72.5,66.6 72.5,66.8 72.5,66.7 72.5,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,66.5 71.9,67 72.5,66.6 72.5,66.8 72.5,66.7 72.5,66.6"
        />
        <polygon
          fill={colors[4]}
          points="72.6,66.7 72.6,66.6 72.5,66.6 72.5,66.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,66.7 72.6,66.6 72.5,66.6 72.5,66.7"
        />
        <polygon fill="#CCC" points="72.6,66.6 72.6,66.6 72.6,66.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,66.6 72.6,66.6 72.6,66.7"
        />
        <polygon
          fill={colors[4]}
          points="71.5,67.3 71.6,67.3 71.7,67.4 71.7,67.5 72.5,66.8 72.5,66.6 71.9,67 71.6,67.3 71.6,67.3 71.6,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,67.3 71.6,67.3 71.7,67.4 71.7,67.5 72.5,66.8 72.5,66.6 71.9,67 71.6,67.3 71.6,67.3 71.6,67.3"
        />
        <polygon
          fill={colors[3]}
          points="71.5,65.7 71.5,67.1 70.8,66.5 71.5,67.3 71.5,67.3 71.6,67.3 71.6,67.3 71.6,65.7 71.6,65.7"
        />
        <polygon
          fill={colors[4]}
          points="71.6,67.3 71.9,67 72.4,66.5 71.6,67.1 71.6,65.7 71.6,65.7"
        />
        <polygon
          fill="#CCC"
          points="72.4,66.5 72.4,66.5 72.3,66.4 72.3,66.4 71.6,65.7 71.6,67.1"
        />
        <polygon
          fill={colors[4]}
          points="70.8,66.5 70.8,66.5 70.8,66.5 70.8,66.5 71.5,67.1 71.5,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,69.4 72.3,70 72.4,70.1 72.4,70.1 71.7,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,69.4 72.3,70 72.4,70.1 72.4,70.1 71.7,69.3"
        />
        <polygon fill={colors[4]} points="71.6,69.4 72.3,70 71.7,69.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,69.4 72.3,70 71.7,69.4"
        />
        <polygon
          fill="#CCC"
          points="72.4,70.1 72.3,70 71.6,69.4 71.6,69.5 71.6,69.5 72.3,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70.1 72.3,70 71.6,69.4 71.6,69.5 71.6,69.5 72.3,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,69.4 71.7,69.3 71.7,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,69.4 71.7,69.3 71.7,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,69.4 71.7,69.3 71.7,69.3"
        />
        <polygon
          fill={colors[3]}
          points="71.4,69.3 71.5,69.4 71.5,69.4 71.5,69.4 71.5,69.5 71.5,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,69.3 71.5,69.4 71.5,69.4 71.5,69.4 71.5,69.5 71.5,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,69.3 71.5,69.4 71.5,69.4 71.5,69.4 71.5,69.5 71.5,69.4"
        />
        <polygon fill={colors[4]} points="71.7,69.4 71.6,69.4 71.6,69.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,69.4 71.6,69.4 71.6,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,69.4 71.6,69.4 71.6,69.4"
        />
        <polygon
          fill={colors[4]}
          points="71.6,69.5 71.6,69.5 71.5,69.4 71.5,69.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,69.5 71.6,69.5 71.5,69.4 71.5,69.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,69.5 71.6,69.5 71.5,69.4 71.5,69.5"
        />
        <polygon fill="#CCC" points="71.6,69.5 71.6,69.5 71.6,69.4 71.6,69.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,69.5 71.6,69.5 71.6,69.4 71.6,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,69.5 71.6,69.5 71.6,69.4 71.6,69.4"
        />
        <polygon fill="#CCC" points="71.3,69.3 71.3,69.3 71.4,69.3 71.4,69.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.3,69.3 71.3,69.3 71.4,69.3 71.4,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,69.2 71.5,69.1 71.5,69.1 71.6,69.2 71.5,69.2 71.7,69.4 71.7,69.3 71.7,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,69.2 71.5,69.1 71.5,69.1 71.6,69.2 71.5,69.2 71.7,69.4 71.7,69.3 71.7,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,69.2 71.5,69.1 71.5,69.1 71.6,69.2 71.5,69.2 71.7,69.4 71.7,69.3 71.7,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,69.2 71.5,69.1 71.5,69.1 71.6,69.2 71.5,69.2 71.7,69.4 71.7,69.3 71.7,69.3"
        />
        <polygon
          fill={colors[3]}
          points="71.4,69.3 71.4,69.3 71.5,69.4 71.5,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,69.3 71.4,69.3 71.5,69.4 71.5,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,69.3 71.4,69.3 71.5,69.4 71.5,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,69.3 71.4,69.3 71.5,69.4 71.5,69.3"
        />
        <polygon
          fill={colors[4]}
          points="71.6,69.4 71.7,69.4 71.5,69.2 71.5,69.3 71.5,69.4 71.6,69.5 71.6,69.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,69.4 71.7,69.4 71.5,69.2 71.5,69.3 71.5,69.4 71.6,69.5 71.6,69.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,69.4 71.7,69.4 71.5,69.2 71.5,69.3 71.5,69.4 71.6,69.5 71.6,69.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,69.4 71.7,69.4 71.5,69.2 71.5,69.3 71.5,69.4 71.6,69.5 71.6,69.5"
        />
        <polygon
          fill="#CCC"
          points="71.5,69.1 71.3,69.3 71.4,69.3 71.4,69.3 71.5,69.3 71.5,69.2 71.6,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,69.1 71.3,69.3 71.4,69.3 71.4,69.3 71.5,69.3 71.5,69.2 71.6,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,69.1 71.3,69.3 71.4,69.3 71.4,69.3 71.5,69.3 71.5,69.2 71.6,69.2"
        />
        <polygon
          fill={colors[3]}
          points="71.5,69.1 71.5,69.2 71.6,69.1 71.6,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,69.1 71.5,69.2 71.6,69.1 71.6,69.1"
        />
        <polygon
          fill={colors[4]}
          points="71.6,69.1 71.7,69.1 72.2,68.5 71.6,69"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,69.1 71.7,69.1 72.2,68.5 71.6,69"
        />
        <polygon fill="#CCC" points="72.2,68.5 72.3,68.4 72.3,68.3 71.6,69" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.2,68.5 72.3,68.4 72.3,68.3 71.6,69"
        />
        <polygon
          fill="#CCC"
          points="72.3,68.3 72.3,68.4 72.4,68.4 72.3,68.3 72.4,68.2 72.4,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,68.3 72.3,68.4 72.4,68.4 72.3,68.3 72.4,68.2 72.4,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,68.3 72.3,68.4 72.4,68.4 72.3,68.3 72.4,68.2 72.4,68.2"
        />
        <polygon
          fill={colors[3]}
          points="72.5,68.1 72.5,68.1 72.4,68.2 72.4,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,68.1 72.5,68.1 72.4,68.2 72.4,68.2"
        />
        <polygon
          fill={colors[3]}
          points="71.7,69.1 72.4,68.4 72.4,68.4 72.3,68.4 72.2,68.5"
        />
        <polygon
          fill={colors[3]}
          points="72.4,69.9 71.7,69.3 71.7,69.3 71.7,69.3 72.4,70.1 72.5,70 72.5,68.5 72.4,68.4"
        />
        <polygon
          fill={colors[4]}
          points="72.5,68.5 72.5,68.5 72.5,70 72.8,69.7 72.5,69.9"
        />
        <polygon fill="#CCC" points="72.5,69.9 72.8,69.7 73.3,69.2 72.5,68.5" />
        <polygon
          fill={colors[4]}
          points="71.6,69.1 71.5,69.2 71.5,69.2 71.7,69.3 72.4,69.9 72.4,68.4 72.4,68.4 71.7,69.1"
        />
        <polygon fill={colors[3]} points="72.5,68.2 72.5,68.1 72.5,68.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,68.2 72.5,68.1 72.5,68.1"
        />
        <polygon
          fill={colors[4]}
          points="72.5,68.2 72.5,68.2 72.6,68.1 73.1,67.6 72.6,68 72.5,68.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,68.2 72.5,68.2 72.6,68.1 73.1,67.6 72.6,68 72.5,68.1"
        />
        <polygon fill="#CCC" points="72.6,68 73.1,67.6 73.3,67.4 73.3,67.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,68 73.1,67.6 73.3,67.4 73.3,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.1 73.4,69.1 73.4,69.1 72.7,68.4 72.7,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.1 73.4,69.1 73.4,69.1 72.7,68.4 72.7,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,68.4 72.7,68.4 72.7,68.4 72.6,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,68.4 72.7,68.4 72.7,68.4 72.6,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,68.4 72.7,68.4 72.7,68.4 72.6,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,68.2 72.4,68.2 72.4,68.2 72.5,68.3 72.5,68.3 72.6,68.4 72.7,68.4 72.6,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,68.2 72.4,68.2 72.4,68.2 72.5,68.3 72.5,68.3 72.6,68.4 72.7,68.4 72.6,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,68.2 72.4,68.2 72.4,68.2 72.5,68.3 72.5,68.3 72.6,68.4 72.7,68.4 72.6,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,68.2 72.4,68.2 72.4,68.2 72.5,68.3 72.5,68.3 72.6,68.4 72.7,68.4 72.6,68.3"
        />
        <polygon
          fill="#CCC"
          points="72.3,68.3 72.4,68.4 72.5,68.2 72.5,68.3 72.5,68.3 72.4,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,68.3 72.4,68.4 72.5,68.2 72.5,68.3 72.5,68.3 72.4,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,68.3 72.4,68.4 72.5,68.2 72.5,68.3 72.5,68.3 72.4,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,68.3 72.4,68.4 72.5,68.2 72.5,68.3 72.5,68.3 72.4,68.2"
        />
        <polygon fill={colors[3]} points="72.5,68.2 72.5,68.1 72.4,68.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,68.2 72.5,68.1 72.4,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,68.2 72.5,68.1 72.4,68.2"
        />
        <polygon
          fill={colors[3]}
          points="72.4,68.4 72.4,68.4 72.4,68.4 72.5,68.5 72.5,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,68.4 72.4,68.4 72.4,68.4 72.5,68.5 72.5,68.2"
        />
        <polygon
          fill={colors[4]}
          points="72.5,68.5 72.5,68.5 72.5,68.4 73.3,69.1 72.7,68.4 72.6,68.4 72.5,68.3 72.5,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,68.5 72.5,68.5 72.5,68.4 73.3,69.1 72.7,68.4 72.6,68.4 72.5,68.3 72.5,68.2"
        />
        <polygon
          fill="#CCC"
          points="72.5,68.4 72.5,68.5 73.3,69.2 73.4,69.1 73.3,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,68.4 72.5,68.5 73.3,69.2 73.4,69.1 73.3,69.1"
        />
        <polygon
          fill={colors[3]}
          points="73.4,68.9 72.6,68.3 72.7,68.4 72.7,68.4 73.4,69.1 73.4,69.1 73.4,69 73.4,67.5 73.4,67.5"
        />
        <polygon
          fill={colors[3]}
          points="72.6,68.1 73.4,67.5 73.3,67.4 73.1,67.6"
        />
        <polygon
          fill={colors[4]}
          points="73.4,69 73.7,68.7 73.5,68.9 73.5,67.6 73.4,67.5"
        />
        <polygon
          fill="#CCC"
          points="73.5,68.9 73.7,68.7 73.9,68.6 73.8,67.8 73.5,67.6"
        />
        <polygon
          fill={colors[4]}
          points="72.6,68.1 72.5,68.2 72.5,68.2 72.5,68.2 72.5,68.2 72.5,68.2 72.6,68.3 73.4,68.9 73.4,67.5 73.4,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,70.3 69.7,71 69.7,71"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,70.3 69.7,71 69.7,71"
        />
        <polygon fill={colors[3]} points="70.5,70.4 70.5,70.5 70.5,70.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.4 70.5,70.5 70.5,70.5"
        />
        <polygon fill={colors[3]} points="69.7,71 70.5,70.4 70.4,70.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,71 70.5,70.4 70.4,70.3"
        />
        <polygon fill={colors[4]} points="70.5,70.5 70.6,70.4 70.5,70.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.5 70.6,70.4 70.5,70.4"
        />
        <polygon
          fill={colors[4]}
          points="70.5,70.4 70.5,70.4 69.7,71 69.7,71 69.6,71.1 69.7,71.2 69.9,71.2 70.5,70.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.4 70.5,70.4 69.7,71 69.7,71 69.6,71.1 69.7,71.2 69.9,71.2 70.5,70.5"
        />
        <polygon fill={colors[3]} points="70.5,70.4 70.4,70.3 70.4,70.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.4 70.4,70.3 70.4,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.4 70.4,70.3 70.4,70.3"
        />
        <polygon
          fill={colors[4]}
          points="70.5,70.4 70.5,70.4 70.6,70.4 70.6,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.4 70.5,70.4 70.6,70.4 70.6,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.4 70.5,70.4 70.6,70.4 70.6,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.4 69.8,69.4 70.4,70.1 70.5,70.1 70.5,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.4 69.8,69.4 70.4,70.1 70.5,70.1 70.5,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,70.3 70.4,70.3 70.4,70.3 70.5,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,70.3 70.4,70.3 70.4,70.3 70.5,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,70.3 70.4,70.3 70.4,70.3 70.5,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,70.3 70.4,70.3 70.4,70.3 70.5,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,70.2 70.6,70.1 70.6,70.1 70.5,70.1 70.5,70.1 70.6,70.2 70.6,70.2 70.7,70.3 70.7,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,70.2 70.6,70.1 70.6,70.1 70.5,70.1 70.5,70.1 70.6,70.2 70.6,70.2 70.7,70.3 70.7,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,70.2 70.6,70.1 70.6,70.1 70.5,70.1 70.5,70.1 70.6,70.2 70.6,70.2 70.7,70.3 70.7,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,70.2 70.6,70.1 70.6,70.1 70.5,70.1 70.5,70.1 70.6,70.2 70.6,70.2 70.7,70.3 70.7,70.3"
        />
        <polygon fill={colors[3]} points="70.5,70.2 70.4,70.3 70.5,70.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.2 70.4,70.3 70.5,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.2 70.4,70.3 70.5,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.2 70.4,70.3 70.5,70.4"
        />
        <polygon
          fill={colors[4]}
          points="70.5,70.2 70.5,70.4 70.6,70.4 70.6,70.4 70.6,70.4 70.6,70.4 70.7,70.3 70.6,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.2 70.5,70.4 70.6,70.4 70.6,70.4 70.6,70.4 70.6,70.4 70.7,70.3 70.6,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.2 70.5,70.4 70.6,70.4 70.6,70.4 70.6,70.4 70.6,70.4 70.7,70.3 70.6,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,70.2 70.5,70.4 70.6,70.4 70.6,70.4 70.6,70.4 70.6,70.4 70.7,70.3 70.6,70.2"
        />
        <polygon fill={colors[3]} points="70.7,70.3 70.7,70.3 70.7,70.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,70.3 70.7,70.3 70.7,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,70.3 70.7,70.3 70.7,70.2"
        />
        <polygon
          fill={colors[4]}
          points="70.6,70.1 70.6,70.2 70.6,70.1 70.6,70.1 70.7,70.2 70.7,70.3 70.8,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,70.1 70.6,70.2 70.6,70.1 70.6,70.1 70.7,70.2 70.7,70.3 70.8,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,70.1 70.6,70.2 70.6,70.1 70.6,70.1 70.7,70.2 70.7,70.3 70.8,70.3"
        />
        <polygon
          fill={colors[3]}
          points="70.6,70.1 70.5,70.1 70,69.5 69.8,69.4 69.8,69.4 70.5,70.1 70.5,70.1 70.6,70.1 70.6,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,70.1 70.5,70.1 70,69.5 69.8,69.4 69.8,69.4 70.5,70.1 70.5,70.1 70.6,70.1 70.6,70.2"
        />
        <polygon
          fill={colors[3]}
          points="69.8,69.4 69.8,69.4 69.8,69.4 69.8,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.4 69.8,69.4 69.8,69.4 69.8,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.4 69.8,69.4 69.8,69.4 69.8,69.3"
        />
        <polygon
          fill={colors[4]}
          points="69.8,69.3 69.8,69.3 69.8,69.4 69.8,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.3 69.8,69.3 69.8,69.4 69.8,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.3 69.8,69.3 69.8,69.4 69.8,69.4"
        />
        <polygon
          fill={colors[3]}
          points="69.6,70.9 69.1,70.6 69.6,71 69.6,71 69.6,69.5 69.6,69.6"
        />
        <polygon
          fill={colors[4]}
          points="70.4,70.3 70.4,70.3 69.7,70.9 69.7,69.5 69.6,69.5 69.6,71 69.6,71.1 69.7,71"
        />
        <polygon fill={colors[4]} points="69.8,69.4 69.7,69.4 70.4,70.1" />
        <polygon
          fill="#CCC"
          points="70.5,70.2 70.6,70.2 70.6,70.2 70.5,70.1 70.5,70.1 70.4,70.1 69.7,69.4 69.7,69.5 69.7,70.9 70.4,70.3"
        />
        <polygon
          fill={colors[4]}
          points="69.1,70.6 69.6,70.9 69.6,69.6 68.8,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,68.4 68.8,68.4 68.8,68.4 69.5,69.1 69.6,69.2 69.6,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,68.4 68.8,68.4 68.8,68.4 69.5,69.1 69.6,69.2 69.6,69.1"
        />
        <polygon
          fill={colors[3]}
          points="68.9,68.4 69.6,69.1 69.6,69.1 69.1,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,68.4 69.6,69.1 69.6,69.1 69.1,68.6"
        />
        <polygon
          fill={colors[4]}
          points="68.9,68.4 68.9,68.4 68.9,68.4 68.8,68.4 68.9,68.4 69.1,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,68.4 68.9,68.4 68.9,68.4 68.8,68.4 68.9,68.4 69.1,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,70.1 68.8,70.1 69.5,69.4 69.5,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,70.1 68.8,70.1 69.5,69.4 69.5,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.3 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.8,69.4 69.8,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.3 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.8,69.4 69.8,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.3 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.8,69.4 69.8,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.3 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.6,69.2 69.8,69.4 69.8,69.4"
        />
        <polygon
          fill={colors[4]}
          points="69.8,69.3 69.7,69.2 69.7,69.2 69.6,69.2 69.6,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.3 69.7,69.2 69.7,69.2 69.6,69.2 69.6,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.3 69.7,69.2 69.7,69.2 69.6,69.2 69.6,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,69.3 69.7,69.2 69.7,69.2 69.6,69.2 69.6,69.2"
        />
        <polygon
          fill={colors[3]}
          points="69.7,69.2 69.7,69.2 69.6,69.1 69.6,69.1 69.6,69.2 69.6,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,69.2 69.7,69.2 69.6,69.1 69.6,69.1 69.6,69.2 69.6,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,69.2 69.7,69.2 69.6,69.1 69.6,69.1 69.6,69.2 69.6,69.2"
        />
        <polygon
          fill={colors[3]}
          points="69.6,69.2 69.5,69.3 69.5,69.3 69.5,69.4 68.8,70.1 69.6,69.4 69.6,69.6 69.6,69.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,69.2 69.5,69.3 69.5,69.3 69.5,69.4 68.8,70.1 69.6,69.4 69.6,69.6 69.6,69.5"
        />
        <polygon
          fill={colors[4]}
          points="69.7,69.4 69.8,69.4 69.6,69.2 69.6,69.2 69.6,69.5 69.7,69.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,69.4 69.8,69.4 69.6,69.2 69.6,69.2 69.6,69.5 69.7,69.5"
        />
        <polygon
          fill={colors[4]}
          points="68.7,70.1 68.8,70.2 68.8,70.2 68.8,70.3 69.6,69.6 69.6,69.4 68.8,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,70.1 68.8,70.2 68.8,70.2 68.8,70.3 69.6,69.6 69.6,69.4 68.8,70.1"
        />
        <polygon
          fill={colors[3]}
          points="68.6,70 68.2,69.6 68.7,70.1 68.7,68.6 68.6,68.6"
        />
        <polygon
          fill={colors[4]}
          points="68.7,68.6 68.7,70.1 68.7,70.1 68.7,70.1 69.5,69.4 69.5,69.3 69.5,69.3 68.7,70 68.7,68.5"
        />
        <polygon fill={colors[4]} points="68.7,68.5 69.5,69.1 68.8,68.4" />
        <polygon
          fill="#CCC"
          points="69.6,69.2 69.6,69.2 69.5,69.1 68.7,68.5 68.7,68.5 68.7,70 69.5,69.3 69.5,69.3"
        />
        <polygon
          fill={colors[4]}
          points="68.6,68.6 67.9,69.3 67.9,69.3 67.9,69.3 68.2,69.6 68.6,70"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.3 71.6,71 71.6,71"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.3 71.6,71 71.6,71"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,71 71.4,71 71.5,71.1 71.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,71 71.4,71 71.5,71.1 71.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,71 71.4,71 71.5,71.1 71.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,71 71.4,71 71.5,71.1 71.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.1 71.5,71.2 71.6,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.1 71.5,71.2 71.6,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.1 71.5,71.2 71.6,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.1 71.5,71.2 71.6,71.2"
        />
        <polygon fill={colors[3]} points="71.5,71.2 71.5,71.2 71.5,71.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.2 71.5,71.2 71.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.2 71.5,71.2 71.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.2 71.5,71.2 71.5,71.1"
        />
        <polygon fill={colors[4]} points="71.5,71.1 71.5,71.2 71.5,71.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.1 71.5,71.2 71.5,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.1 71.5,71.2 71.5,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.1 71.5,71.2 71.5,71.2"
        />
        <polygon
          fill="#CCC"
          points="71.5,71.1 71.5,71.1 71.5,71.1 71.5,71.1 71.4,71.1 71.3,71.2 71.5,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.1 71.5,71.1 71.5,71.1 71.5,71.1 71.4,71.1 71.3,71.2 71.5,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71.1 71.5,71.1 71.5,71.1 71.5,71.1 71.4,71.1 71.3,71.2 71.5,71.2"
        />
        <polygon
          fill={colors[3]}
          points="71.5,71 71.5,71 71.5,71.1 71.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,71 71.5,71 71.5,71.1 71.5,71.1"
        />
        <polygon
          fill={colors[4]}
          points="72.3,70.3 71.8,70.7 71.5,71 71.5,71.1 71.6,71"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.3 71.8,70.7 71.5,71 71.5,71.1 71.6,71"
        />
        <polygon fill="#CCC" points="71.8,70.7 72.3,70.3 72.3,70.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.8,70.7 72.3,70.3 72.3,70.2"
        />
        <polygon
          fill={colors[3]}
          points="72.4,70.4 72.4,70.4 72.4,71.2 72.4,71.2"
        />
        <polygon fill={colors[3]} points="71.6,71 72.4,70.4 72.3,70.3" />
        <polygon
          fill={colors[4]}
          points="72.4,70.4 72.4,71.2 72.5,71.2 72.5,70.5"
        />
        <polygon fill="#CCC" points="72.5,71.2 73.2,71.2 72.5,70.5" />
        <polygon
          fill={colors[4]}
          points="72.4,70.4 72.4,70.4 71.6,71 71.6,71 71.5,71.1 71.5,71.1 71.5,71.1 71.5,71.1 71.5,71.1 71.6,71.2 72.4,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,71.1 73.5,71.1 74.2,70.4 74.2,70.3 73.4,71"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,70.1 72.6,70 73.3,69.3 73.3,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,70.1 72.6,70 73.3,69.3 73.3,69.3"
        />
        <polygon fill="#CCC" points="72.3,70.2 72.3,70.2 72.4,70.1 72.4,70.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.2 72.3,70.2 72.4,70.1 72.4,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.2 72.3,70.2 72.4,70.1 72.4,70.1"
        />
        <polygon
          fill={colors[3]}
          points="72.4,70.1 72.4,70.1 72.4,70 72.5,70.1 72.5,70"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70.1 72.4,70.1 72.4,70 72.5,70.1 72.5,70"
        />
        <polygon
          fill={colors[4]}
          points="72.5,70.1 72.5,70.1 72.5,70.1 73.3,69.3 73.3,69.3 72.8,69.7 72.5,70"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,70.1 72.5,70.1 72.5,70.1 73.3,69.3 73.3,69.3 72.8,69.7 72.5,70"
        />
        <polygon
          fill="#CCC"
          points="72.8,69.7 73.3,69.3 73.2,69.3 73.3,69.2 73.3,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.8,69.7 73.3,69.3 73.2,69.3 73.3,69.2 73.3,69.2"
        />
        <polygon fill="#CCC" points="73.3,69.2 73.3,69.2 73.4,69.1 73.4,69.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.2 73.3,69.2 73.4,69.1 73.4,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.2 73.3,69.2 73.4,69.1 73.4,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,71 73.4,71.1 72.6,70.3 72.6,70.3 73.3,71 73.4,71.1 73.3,71.2 73.5,71.2 73.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,71 73.4,71.1 72.6,70.3 72.6,70.3 73.3,71 73.4,71.1 73.3,71.2 73.5,71.2 73.5,71.1"
        />
        <polygon fill="#CCC" points="72.3,70.2 72.3,70.2 72.3,70.3 72.3,70.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.2 72.3,70.2 72.3,70.3 72.3,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.2 72.3,70.2 72.3,70.3 72.3,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.3 72.3,70.3 72.4,70.2 72.3,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.3 72.3,70.3 72.4,70.2 72.3,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.3 72.3,70.3 72.4,70.2 72.3,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.3 72.3,70.3 72.4,70.2 72.3,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,70.2 72.6,70.3 72.6,70.3 72.6,70.2 72.5,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,70.2 72.6,70.3 72.6,70.3 72.6,70.2 72.5,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,70.2 72.6,70.3 72.6,70.3 72.6,70.2 72.5,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,70.2 72.6,70.3 72.6,70.3 72.6,70.2 72.5,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70.1 72.4,70.1 72.5,70.2 72.5,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70.1 72.4,70.1 72.5,70.2 72.5,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70.1 72.4,70.1 72.5,70.2 72.5,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70.1 72.4,70.1 72.5,70.2 72.5,70.2"
        />
        <polygon fill={colors[4]} points="72.3,70.2 72.3,70.3 72.3,70.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.2 72.3,70.3 72.3,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.2 72.3,70.3 72.3,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,70.2 72.3,70.3 72.3,70.3"
        />
        <polygon
          fill="#CCC"
          points="72.4,70.1 72.5,70.1 72.4,70.1 72.3,70.2 72.3,70.3 72.3,70.2 72.4,70.2 72.4,70.2 72.5,70.2 72.5,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70.1 72.5,70.1 72.4,70.1 72.3,70.2 72.3,70.3 72.3,70.2 72.4,70.2 72.4,70.2 72.5,70.2 72.5,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70.1 72.5,70.1 72.4,70.1 72.3,70.2 72.3,70.3 72.3,70.2 72.4,70.2 72.4,70.2 72.5,70.2 72.5,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70.1 72.5,70.1 72.4,70.1 72.3,70.2 72.3,70.3 72.3,70.2 72.4,70.2 72.4,70.2 72.5,70.2 72.5,70.1"
        />
        <polygon
          fill={colors[3]}
          points="72.4,70 72.4,70.1 72.5,70.1 72.5,70.1 72.5,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70 72.4,70.1 72.5,70.1 72.5,70.1 72.5,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70 72.4,70.1 72.5,70.1 72.5,70.1 72.5,70.1"
        />
        <polygon
          fill={colors[3]}
          points="72.4,70.2 72.4,70.2 72.3,70.3 72.3,70.3 72.4,70.4 72.4,70.3 72.4,70.4 72.4,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70.2 72.4,70.2 72.3,70.3 72.3,70.3 72.4,70.4 72.4,70.3 72.4,70.4 72.4,70.4"
        />
        <polygon
          fill={colors[4]}
          points="72.5,70.2 72.4,70.2 72.4,70.4 72.5,70.5 72.5,70.3 73.3,71 72.6,70.3 72.6,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,70.2 72.4,70.2 72.4,70.4 72.5,70.5 72.5,70.3 73.3,71 72.6,70.3 72.6,70.3"
        />
        <polygon
          fill="#CCC"
          points="73.3,71 72.5,70.3 72.5,70.5 73.2,71.2 73.3,71.2 73.4,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,71 72.5,70.3 72.5,70.5 73.2,71.2 73.3,71.2 73.4,71.1"
        />
        <polygon fill={colors[4]} points="72.4,70.4 72.4,70.4 72.4,70.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,70.4 72.4,70.4 72.4,70.3"
        />
        <polygon
          fill={colors[3]}
          points="73.3,70.9 72.6,70.2 72.6,70.3 72.6,70.3 73.4,71.1 73.4,69.5 73.3,69.4"
        />
        <polygon fill={colors[3]} points="73.3,69.4 73.3,69.3 72.6,70" />
        <polygon
          fill={colors[4]}
          points="73.5,69.5 73.4,69.5 73.4,71.1 73.4,71 74.2,70.3 74.2,70.3 73.5,70.9"
        />
        <polygon fill="#CCC" points="73.5,70.9 74.2,70.3 74.2,70.2 73.5,69.5" />
        <polygon
          fill={colors[4]}
          points="72.5,70.1 72.5,70.1 72.5,70.1 72.5,70.1 72.5,70.1 72.4,70.1 72.5,70.1 72.6,70.2 73.3,70.9 73.3,69.4 73.3,69.4 72.6,70"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,69.1 73.9,68.7 73.9,68.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,69.1 73.9,68.7 73.9,68.7"
        />
        <polygon
          fill={colors[3]}
          points="73.4,69.1 73.4,69.1 73.4,69.1 73.4,69"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,69.1 73.4,69.1 73.4,69.1 73.4,69"
        />
        <polygon
          fill={colors[4]}
          points="73.4,69 73.4,69.1 73.5,69.1 73.9,68.7 73.9,68.6 73.7,68.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,69 73.4,69.1 73.5,69.1 73.9,68.7 73.9,68.6 73.7,68.7"
        />
        <polygon fill="#CCC" points="73.9,68.6 73.9,68.6 73.7,68.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.9,68.6 73.9,68.6 73.7,68.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6,69.3 74.1,69.9 74.1,69.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6,69.3 74.1,69.9 74.1,69.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6,69.3 73.6,69.3 73.6,69.3 73.5,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6,69.3 73.6,69.3 73.6,69.3 73.5,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6,69.3 73.6,69.3 73.6,69.3 73.5,69.3"
        />
        <polygon fill={colors[4]} points="73.3,69.3 73.4,69.2 73.4,69.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.3 73.4,69.2 73.4,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.3 73.4,69.2 73.4,69.2"
        />
        <polygon fill="#CCC" points="73.3,69.3 73.3,69.3 73.3,69.2 73.2,69.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.3 73.3,69.3 73.3,69.2 73.2,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.3 73.3,69.3 73.3,69.2 73.2,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,69.1 73.4,69.1 73.5,69.2 73.5,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,69.1 73.4,69.1 73.5,69.2 73.5,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,69.1 73.4,69.1 73.5,69.2 73.5,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,69.1 73.4,69.1 73.5,69.2 73.5,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,69.3 73.6,69.3 73.5,69.3 73.4,69.2 73.4,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,69.3 73.6,69.3 73.5,69.3 73.4,69.2 73.4,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,69.3 73.6,69.3 73.5,69.3 73.4,69.2 73.4,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,69.3 73.6,69.3 73.5,69.3 73.4,69.2 73.4,69.2"
        />
        <polygon
          fill="#CCC"
          points="73.3,69.2 73.3,69.3 73.3,69.3 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.2 73.3,69.3 73.3,69.3 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.2 73.3,69.3 73.3,69.3 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.2 73.3,69.3 73.3,69.3 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.2 73.4,69.1"
        />
        <polygon
          fill={colors[3]}
          points="73.4,69.1 73.4,69.1 73.5,69.2 73.5,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,69.1 73.4,69.1 73.5,69.2 73.5,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,69.1 73.4,69.1 73.5,69.2 73.5,69.2"
        />
        <polygon
          fill={colors[3]}
          points="73.3,69.3 73.3,69.3 73.3,69.4 73.3,69.4 73.3,69.4 73.4,69.5 73.4,69.2 73.4,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,69.3 73.3,69.3 73.3,69.4 73.3,69.4 73.3,69.4 73.4,69.5 73.4,69.2 73.4,69.2"
        />
        <polygon
          fill={colors[4]}
          points="73.5,69.3 73.4,69.2 73.4,69.2 73.4,69.5 73.5,69.5 73.5,69.4 74.1,69.9 74.1,69.9 73.6,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,69.3 73.4,69.2 73.4,69.2 73.4,69.5 73.5,69.5 73.5,69.4 74.1,69.9 74.1,69.9 73.6,69.3"
        />
        <polygon fill="#CCC" points="73.5,69.4 73.5,69.5 74.2,70.2 74.1,69.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,69.4 73.5,69.5 74.2,70.2 74.1,69.9"
        />
        <polygon fill={colors[3]} points="73.9,68.7 73.9,68.7 73.5,69.1" />
        <polygon
          fill={colors[3]}
          points="73.5,69.3 73.6,69.3 73.6,69.3 74.1,69.9 74.1,69.7"
        />
        <polygon
          fill={colors[4]}
          points="73.5,69.1 73.4,69.1 73.4,69.1 73.4,69.1 73.4,69.2 73.4,69.2 73.4,69.2 73.5,69.3 74.1,69.7 73.9,68.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,70.3 68.8,70.3 69.5,71 69.5,71.1 69.5,71"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,70.3 68.8,70.3 69.5,71 69.5,71.1 69.5,71"
        />
        <polygon
          fill={colors[3]}
          points="68.8,70.3 68.8,70.3 69.5,71 69.6,71 69.1,70.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,70.3 68.8,70.3 69.5,71 69.6,71 69.1,70.6"
        />
        <polygon
          fill={colors[4]}
          points="68.8,70.2 68.8,70.3 68.8,70.3 68.8,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,70.2 68.8,70.3 68.8,70.3 68.8,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,70.2 68.8,70.3 68.8,70.3 68.8,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71.1 69.6,71.1 69.5,71.1 69.6,71.1 69.5,71.2 69.5,71.2 69.6,71.1 69.6,71.2 69.6,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71.1 69.6,71.1 69.5,71.1 69.6,71.1 69.5,71.2 69.5,71.2 69.6,71.1 69.6,71.2 69.6,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71.1 69.6,71.1 69.5,71.1 69.6,71.1 69.5,71.2 69.5,71.2 69.6,71.1 69.6,71.2 69.6,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71.1 69.6,71.1 69.5,71.1 69.6,71.1 69.5,71.2 69.5,71.2 69.6,71.1 69.6,71.2 69.6,71.2"
        />
        <polygon
          fill={colors[4]}
          points="69.6,71.1 69.6,71.1 69.6,71.1 69.6,71.1 69.6,71.2 69.7,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71.1 69.6,71.1 69.6,71.1 69.6,71.1 69.6,71.2 69.7,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71.1 69.6,71.1 69.6,71.1 69.6,71.1 69.6,71.2 69.7,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71.1 69.6,71.1 69.6,71.1 69.6,71.1 69.6,71.2 69.7,71.2"
        />
        <polygon
          fill={colors[3]}
          points="69.6,71 69.6,71 69.5,71 69.6,71.1 69.6,71.1 69.6,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71 69.6,71 69.5,71 69.6,71.1 69.6,71.1 69.6,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71 69.6,71 69.5,71 69.6,71.1 69.6,71.1 69.6,71.1"
        />
        <polygon fill={colors[4]} points="69.6,71 69.6,71.1 69.6,71.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71 69.6,71.1 69.6,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71 69.6,71.1 69.6,71.1"
        />
        <polygon fill={colors[3]} points="69.5,71.2 69.6,71.2 69.6,71.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,71.2 69.6,71.2 69.6,71.1"
        />
        <polygon fill={colors[4]} points="69.6,71.2 69.6,71.2 69.6,71.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,71.2 69.6,71.2 69.6,71.1"
        />
        <polygon
          fill={colors[3]}
          points="68.6,70.5 68.6,71.2 68.6,71.2 68.6,70.4"
        />
        <polygon
          fill={colors[4]}
          points="68.7,70.4 68.6,70.4 68.6,71.2 68.7,71.2"
        />
        <polygon fill={colors[4]} points="68.8,70.3 68.7,70.4 69.5,71" />
        <polygon
          fill="#CCC"
          points="69.5,71.1 69.5,71.1 69.5,71 68.7,70.4 68.7,70.4 68.7,71.2 69.5,71.2 69.6,71.1"
        />
        <polygon fill={colors[4]} points="67.9,71.2 68.6,71.2 68.6,70.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,70.3 67.6,71.1 67.6,71"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,69.4 67.9,69.4 68.5,70 68.6,70.1 68.6,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,69.4 67.9,69.4 68.5,70 68.6,70.1 68.6,70.1"
        />
        <polygon
          fill={colors[4]}
          points="68.8,70.2 68.8,70.2 68.7,70.1 68.7,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,70.2 68.8,70.2 68.7,70.1 68.7,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,70.2 68.8,70.2 68.7,70.1 68.7,70.1"
        />
        <polygon
          fill={colors[3]}
          points="68.2,69.6 67.9,69.4 67.9,69.4 68.6,70.1 68.6,70.1 68.7,70.1 68.7,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2,69.6 67.9,69.4 67.9,69.4 68.6,70.1 68.6,70.1 68.7,70.1 68.7,70.1"
        />
        <polygon fill={colors[4]} points="67.9,69.4 68.2,69.6 67.9,69.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,69.4 68.2,69.6 67.9,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,71 68.5,70.3 68.5,70.3 67.7,71.1 67.6,71 67.6,71.1 67.7,71.1 67.7,71.2 67.7,71.2 67.7,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,71 68.5,70.3 68.5,70.3 67.7,71.1 67.6,71 67.6,71.1 67.7,71.1 67.7,71.2 67.7,71.2 67.7,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,70.3 68.6,70.2 68.7,70.2 68.6,70.1 68.6,70.1 68.6,70.1 68.5,70.3 68.5,70.3 68.5,70.3 68.6,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,70.3 68.6,70.2 68.7,70.2 68.6,70.1 68.6,70.1 68.6,70.1 68.5,70.3 68.5,70.3 68.5,70.3 68.6,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,70.3 68.6,70.2 68.7,70.2 68.6,70.1 68.6,70.1 68.6,70.1 68.5,70.3 68.5,70.3 68.5,70.3 68.6,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,70.3 68.6,70.2 68.7,70.2 68.6,70.1 68.6,70.1 68.6,70.1 68.5,70.3 68.5,70.3 68.5,70.3 68.6,70.2"
        />
        <polygon
          fill={colors[4]}
          points="68.7,70.1 68.7,70.2 68.7,70.2 68.6,70.2 68.8,70.3 68.8,70.3 68.8,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,70.1 68.7,70.2 68.7,70.2 68.6,70.2 68.8,70.3 68.8,70.3 68.8,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,70.1 68.7,70.2 68.7,70.2 68.6,70.2 68.8,70.3 68.8,70.3 68.8,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,70.1 68.7,70.2 68.7,70.2 68.6,70.2 68.8,70.3 68.8,70.3 68.8,70.2"
        />
        <polygon
          fill={colors[3]}
          points="68.7,70.1 68.6,70.1 68.6,70.1 68.6,70.1 68.7,70.2 68.7,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,70.1 68.6,70.1 68.6,70.1 68.6,70.1 68.7,70.2 68.7,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,70.1 68.6,70.1 68.6,70.1 68.6,70.1 68.7,70.2 68.7,70.2"
        />
        <polygon
          fill={colors[3]}
          points="68.6,70.2 68.5,70.3 68.5,70.3 67.8,71 68.6,70.4 68.6,70.5 68.6,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,70.2 68.5,70.3 68.5,70.3 67.8,71 68.6,70.4 68.6,70.5 68.6,70.4"
        />
        <polygon
          fill={colors[4]}
          points="68.6,70.2 68.6,70.4 68.7,70.4 68.7,70.4 68.7,70.4 68.8,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,70.2 68.6,70.4 68.7,70.4 68.7,70.4 68.7,70.4 68.8,70.3"
        />
        <polygon
          fill={colors[4]}
          points="68.6,70.4 67.8,71 67.7,71.1 67.7,71.2 67.9,71.2 68.6,70.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,70.4 67.8,71 67.7,71.1 67.7,71.2 67.9,71.2 68.6,70.5"
        />
        <polygon
          fill={colors[3]}
          points="67.7,69.6 67.6,69.6 67.6,70.9 66.9,70.3 66.9,70.3 67.6,71 67.7,71.1"
        />
        <polygon fill={colors[4]} points="67.8,69.5 68.5,70 67.9,69.4" />
        <polygon
          fill={colors[4]}
          points="68.5,70.3 67.7,70.9 67.7,69.5 67.7,69.6 67.7,71.1"
        />
        <polygon
          fill="#CCC"
          points="68.6,70.1 68.6,70.1 68.5,70 67.8,69.5 67.7,69.5 67.7,70.9 68.5,70.3"
        />
        <polygon fill={colors[4]} points="67.6,70.9 67.6,69.6 66.9,70.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6,71.2 73.6,71.2 73.7,71.1 74.2,70.5 74.2,70.4 73.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6,71.2 73.6,71.2 73.7,71.1 74.2,70.5 74.2,70.4 73.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,71.1 73.5,71.2 73.5,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,71.1 73.5,71.2 73.5,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,71.1 73.5,71.2 73.5,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,71.1 73.5,71.2 73.6,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,71.1 73.5,71.2 73.6,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,71.1 73.5,71.2 73.6,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,71.2 73.5,71.2 73.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,71.2 73.5,71.2 73.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,71.2 73.5,71.2 73.5,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,71.2 73.5,71.2 73.5,71.1"
        />
        <polygon fill={colors[3]} points="74.2,70.6 74.2,70.5 73.7,71.1" />
        <polygon
          fill={colors[4]}
          points="73.7,71.1 73.6,71.2 74.3,71.2 74.2,70.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,69.4 66,69.4 66.7,70.2 66.7,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.1 66.7,70.1 66.7,70.1 66.7,70.1 66.7,70.2 66.7,70.2 66.9,70.3 66.9,70.3 66.8,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.1 66.7,70.1 66.7,70.1 66.7,70.1 66.7,70.2 66.7,70.2 66.9,70.3 66.9,70.3 66.8,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.4 67.9,69.4 67.9,69.4 67.9,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.4 67.9,69.4 67.9,69.4 67.9,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.4 67.9,69.4 67.9,69.4 67.9,69.4"
        />
        <polygon fill={colors[4]} points="67.9,69.3 67.9,69.4 67.9,69.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,69.3 67.9,69.4 67.9,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,69.3 67.9,69.4 67.9,69.4"
        />
        <polygon fill={colors[3]} points="66.9,70.3 66.9,70.3 66.8,70.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,70.3 66.9,70.3 66.8,70.3"
        />
        <polygon
          fill={colors[3]}
          points="67.7,69.5 67.6,69.4 67.1,69.8 67.6,69.4 67.6,69.6 67.7,69.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.5 67.6,69.4 67.1,69.8 67.6,69.4 67.6,69.6 67.7,69.6"
        />
        <polygon fill={colors[4]} points="67.7,69.5 67.7,69.5 67.7,69.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.5 67.7,69.5 67.7,69.6"
        />
        <polygon
          fill={colors[4]}
          points="67.8,69.4 67.8,69.4 67.8,69.5 67.9,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.4 67.8,69.4 67.8,69.5 67.9,69.4"
        />
        <polygon fill="#CCC" points="67.8,69.4 67.7,69.5 67.7,69.5 67.8,69.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.4 67.7,69.5 67.7,69.5 67.8,69.5"
        />
        <polygon
          fill={colors[4]}
          points="67.6,69.4 67.1,69.8 66.8,70.2 66.7,70.1 66.7,70.1 66.7,70.1 66.8,70.3 66.9,70.3 67.6,69.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,69.4 67.1,69.8 66.8,70.2 66.7,70.1 66.7,70.1 66.7,70.1 66.8,70.3 66.9,70.3 67.6,69.6"
        />
        <polygon
          fill={colors[3]}
          points="66.8,68.6 66.7,68.6 66.7,70 66,69.4 66,69.4 66,69.4 66.7,70.1 66.7,70.1 66.7,70.1 66.8,70.2"
        />
        <polygon
          fill={colors[4]}
          points="66.8,70 66.8,68.6 66.8,68.6 66.8,68.6 66.8,70.2 67.1,69.8 67.6,69.4 67.6,69.4"
        />
        <polygon fill="#CCC" points="66.8,68.6 66.8,70 67.6,69.4" />
        <polygon fill={colors[4]} points="66.7,68.6 66,69.4 66,69.4 66.7,70" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.2 67.8,69.2 67.8,69.1 68.5,68.4 68.5,68.4 68.5,68.4 68.5,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.2 67.8,69.2 67.8,69.1 68.5,68.4 68.5,68.4 68.5,68.4 68.5,68.4"
        />
        <polygon
          fill={colors[4]}
          points="68.9,68.4 68.9,68.4 68.8,68.4 68.8,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,68.4 68.9,68.4 68.8,68.4 68.8,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,68.4 68.9,68.4 68.8,68.4 68.8,68.4"
        />
        <polygon
          fill={colors[3]}
          points="68.5,68.4 67.8,69.1 68.6,68.5 68.6,68.6 68.7,68.6 68.7,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,68.4 67.8,69.1 68.6,68.5 68.6,68.6 68.7,68.6 68.7,68.5"
        />
        <polygon fill={colors[4]} points="68.8,68.4 68.7,68.5 68.7,68.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,68.4 68.7,68.5 68.7,68.5"
        />
        <polygon fill={colors[4]} points="68.7,68.5 68.7,68.5 68.7,68.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.5 68.7,68.5 68.7,68.6"
        />
        <polygon
          fill={colors[4]}
          points="67.9,69.3 68.6,68.6 68.6,68.5 67.8,69.1 67.8,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,69.3 68.6,68.6 68.6,68.5 67.8,69.1 67.8,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,69.2 67.6,69.1 66.9,68.4 66.9,68.4 67.6,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,69.2 67.6,69.1 66.9,68.4 66.9,68.4 67.6,69.1"
        />
        <polygon
          fill={colors[7]}
          points="67.7,69.2 67.8,69.2 67.8,69.2 67.8,69.2"
        />
        <polygon
          fill={colors[7]}
          points="67.7,69.2 67.8,69.2 67.8,69.2 67.8,69.2"
        />
        <polygon
          fill={colors[7]}
          points="67.7,69.2 67.8,69.2 67.8,69.2 67.8,69.2"
        />
        <polygon
          fill={colors[4]}
          points="67.8,69.2 67.8,69.2 67.9,69.3 67.9,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.2 67.8,69.2 67.9,69.3 67.9,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.2 67.8,69.2 67.9,69.3 67.9,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.8,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.8,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.8,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.8,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.3 67.7,69.2 67.7,69.2 67.8,69.4 67.9,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.3 67.7,69.2 67.7,69.2 67.8,69.4 67.9,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.3 67.7,69.2 67.7,69.2 67.8,69.4 67.9,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.3 67.7,69.2 67.7,69.2 67.8,69.4 67.9,69.4"
        />
        <polygon fill={colors[3]} points="67.9,69.4 67.9,69.4 67.8,69.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,69.4 67.9,69.4 67.8,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,69.4 67.9,69.4 67.8,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,69.4 67.9,69.4 67.8,69.3"
        />
        <polygon
          fill={colors[4]}
          points="67.8,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.8,69.3 67.9,69.4 67.9,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.8,69.3 67.9,69.4 67.9,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.8,69.3 67.9,69.4 67.9,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,69.2 67.7,69.2 67.7,69.2 67.7,69.2 67.8,69.3 67.9,69.4 67.9,69.3"
        />
        <polygon
          fill={colors[3]}
          points="67.6,69.3 67.6,69.4 67.7,69.5 67.7,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,69.3 67.6,69.4 67.7,69.5 67.7,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,69.3 67.6,69.4 67.7,69.5 67.7,69.3"
        />
        <polygon
          fill={colors[4]}
          points="67.7,69.2 67.7,69.3 67.7,69.5 67.7,69.5 67.7,69.5 67.7,69.4 67.8,69.4 67.8,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.2 67.7,69.3 67.7,69.5 67.7,69.5 67.7,69.5 67.7,69.4 67.8,69.4 67.8,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.2 67.7,69.3 67.7,69.5 67.7,69.5 67.7,69.5 67.7,69.4 67.8,69.4 67.8,69.4"
        />
        <polygon fill="#CCC" points="67.7,69.5 67.8,69.4 67.7,69.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.5 67.8,69.4 67.7,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.5 67.8,69.4 67.7,69.4"
        />
        <polygon
          fill={colors[4]}
          points="66.8,68.6 66.8,68.6 66.8,68.6 66.8,68.6 66.8,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.6 66.8,68.6 66.8,68.6 66.8,68.6 66.8,68.5"
        />
        <polygon fill={colors[4]} points="66.9,68.4 66.8,68.5 67.6,69.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,68.4 66.8,68.5 67.6,69.1"
        />
        <polygon fill={colors[4]} points="67.6,69.4 67.6,69.4 67.6,69.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,69.4 67.6,69.4 67.6,69.3"
        />
        <polygon
          fill="#CCC"
          points="67.7,69.2 67.7,69.2 67.7,69.2 67.6,69.2 67.6,69.1 66.8,68.5 66.8,68.5 66.8,68.6 67.6,69.4 67.6,69.3 67.7,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,69.2 67.7,69.2 67.7,69.2 67.6,69.2 67.6,69.1 66.8,68.5 66.8,68.5 66.8,68.6 67.6,69.4 67.6,69.3 67.7,69.3"
        />
        <polygon
          fill={colors[3]}
          points="67.7,69.2 67.7,69.2 67.7,67.6 67.7,67.7 67.7,69 66.9,68.4 66.9,68.4 67.6,69.1"
        />
        <polygon
          fill={colors[4]}
          points="67.7,69.2 68.5,68.4 68.5,68.4 67.8,69 67.8,67.7 67.7,67.6 67.7,67.6"
        />
        <polygon fill="#CCC" points="67.8,67.7 67.8,69 68.5,68.4" />
        <polygon fill={colors[4]} points="67.7,69 67.7,67.7 66.9,68.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,67.5 66.8,68.2 66.9,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,67.5 66.8,68.2 66.9,68.2"
        />
        <polygon fill={colors[4]} points="66.8,68.5 66.9,68.4 66.9,68.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.5 66.9,68.4 66.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.5 66.9,68.4 66.9,68.4"
        />
        <polygon
          fill={colors[4]}
          points="66.8,68.5 66.8,68.5 66.8,68.6 66.8,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.5 66.8,68.5 66.8,68.6 66.8,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.5 66.8,68.5 66.8,68.6 66.8,68.6"
        />
        <polygon fill={colors[3]} points="66.9,68.2 67.6,67.5 67.6,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,68.2 67.6,67.5 67.6,67.5"
        />
        <polygon
          fill={colors[4]}
          points="67.7,67.7 67.7,67.6 67.6,67.5 66.9,68.2 66.8,68.2 66.8,68.2 67,68.4 66.9,68.4 66.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.7 67.7,67.6 67.6,67.5 66.9,68.2 66.8,68.2 66.8,68.2 67,68.4 66.9,68.4 66.9,68.4"
        />
        <polygon
          fill={colors[3]}
          points="66.3,67.7 66.8,68.2 66.8,66.7 66.7,66.8 66.7,68.1"
        />
        <polygon
          fill={colors[4]}
          points="67.6,67.5 67.6,67.5 66.8,68.1 66.8,66.7 66.8,66.7 66.8,66.7 66.8,68.2 66.8,68.2 66.8,68.2"
        />
        <polygon
          fill="#CCC"
          points="67.6,67.4 66.8,66.7 66.8,68.1 67.6,67.5 67.5,67.4"
        />
        <polygon
          fill={colors[4]}
          points="66.7,68.1 66.7,66.8 66,67.4 66.3,67.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.3 67.7,67.3 67,66.5 66.9,66.6 67.6,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.3 67.7,67.3 67,66.5 66.9,66.6 67.6,67.2"
        />
        <polygon fill={colors[4]} points="66.9,66.6 66.9,66.6 67.6,67.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,66.6 66.9,66.6 67.6,67.2"
        />
        <polygon
          fill="#CCC"
          points="67.7,67.3 67.6,67.2 66.9,66.6 66.8,66.7 66.8,66.7 67.6,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.3 67.6,67.2 66.9,66.6 66.8,66.7 66.8,66.7 67.6,67.4"
        />
        <polygon
          fill={colors[3]}
          points="67.7,67.2 67.7,67.2 67.7,65.8 67.7,65.8 67.7,67.1 67,66.5 67,66.5 67.7,67.3"
        />
        <polygon
          fill={colors[4]}
          points="67.8,67.1 67.8,65.7 67.8,65.7 67.7,65.8 67.7,67.2 68,66.9"
        />
        <polygon
          fill="#CCC"
          points="68.5,66.4 67.8,65.7 67.8,67.1 68,66.9 68.5,66.5"
        />
        <polygon fill={colors[4]} points="67.7,67.1 67.7,65.8 67,66.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,66.5 67,66.5 66.9,66.6 66.9,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,66.5 67,66.5 66.9,66.6 66.9,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,66.5 67,66.5 66.9,66.6 66.9,66.6"
        />
        <polygon fill="#CCC" points="66.9,66.6 66.9,66.6 66.8,66.6 66.8,66.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,66.6 66.9,66.6 66.8,66.6 66.8,66.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,66.6 66.9,66.6 66.8,66.6 66.8,66.7"
        />
        <polygon
          fill={colors[3]}
          points="67.7,65.6 67.7,65.8 67.7,65.8 67.7,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,65.6 67.7,65.8 67.7,65.8 67.7,65.7"
        />
        <polygon fill={colors[3]} points="67.6,65.6 67.1,66.1 67.7,65.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,65.6 67.1,66.1 67.7,65.6"
        />
        <polygon fill={colors[4]} points="67.7,65.7 67.7,65.8 67.8,65.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,65.7 67.7,65.8 67.8,65.7"
        />
        <polygon
          fill={colors[4]}
          points="67.7,65.8 67.7,65.6 67.7,65.6 67.1,66.1 66.8,66.4 67,66.5 67,66.5 67,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,65.8 67.7,65.6 67.7,65.6 67.1,66.1 66.8,66.4 67,66.5 67,66.5 67,66.5"
        />
        <polygon
          fill={colors[3]}
          points="66.2,65.7 66.8,66.3 66.8,66.3 66.8,64.9 66.8,64.9 66.8,66.2"
        />
        <polygon
          fill={colors[4]}
          points="66.8,66.4 67.1,66.1 67.6,65.6 67.6,65.6 66.9,66.2 66.9,64.8 66.9,64.8 66.8,64.9 66.8,66.3"
        />
        <polygon
          fill="#CCC"
          points="67.6,65.5 67.6,65.5 66.9,64.8 66.9,66.2 67.6,65.6"
        />
        <polygon
          fill={colors[4]}
          points="66.8,66.2 66.8,64.9 66.1,65.6 66.2,65.7"
        />
        <polygon
          fill={colors[3]}
          points="66.8,64.8 66.8,64.7 66.8,64.9 66.8,64.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,64.8 66.8,64.7 66.8,64.9 66.8,64.9"
        />
        <polygon fill={colors[3]} points="66.7,64.7 66.2,65.2 66.8,64.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,64.7 66.2,65.2 66.8,64.7"
        />
        <polygon fill={colors[4]} points="66.9,64.8 66.8,64.8 66.8,64.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.8 66.8,64.8 66.8,64.9"
        />
        <polygon
          fill={colors[4]}
          points="66.1,65.6 66.8,64.9 66.8,64.7 66.8,64.7 66.2,65.2 65.9,65.4 66,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,65.6 66.8,64.9 66.8,64.7 66.8,64.7 66.2,65.2 65.9,65.4 66,65.6"
        />
        <polygon
          fill={colors[3]}
          points="65.9,65.4 65.9,64.1 65.8,64.2 65.8,65.3 65.7,65.1 65.7,65.2 65.8,65.4"
        />
        <polygon
          fill={colors[4]}
          points="65.9,65.4 66.2,65.2 66.7,64.7 66.7,64.7 66,65.3 66,64.1 65.9,64.1"
        />
        <polygon
          fill="#CCC"
          points="66.6,64.6 66.7,64.6 66,64 66,64.1 66,65.3 66.7,64.7"
        />
        <polygon
          fill={colors[4]}
          points="65.8,64.2 65.8,64.2 65.7,65.1 65.8,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,64.7 67,64.7 67.6,65.3 67.7,65.4 67.7,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,64.7 67,64.7 67.6,65.3 67.7,65.4 67.7,65.4"
        />
        <polygon fill={colors[4]} points="66.9,64.7 67.6,65.3 67,64.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.7 67.6,65.3 67,64.7"
        />
        <polygon
          fill="#CCC"
          points="67.6,65.5 67.7,65.4 67.6,65.3 66.9,64.7 66.9,64.8 66.9,64.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,65.5 67.7,65.4 67.6,65.3 66.9,64.7 66.9,64.8 66.9,64.8"
        />
        <polygon
          fill={colors[3]}
          points="66.8,64.7 66.8,64.7 66.8,64.8 66.8,64.7 66.7,64.6 66.7,64.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,64.7 66.8,64.7 66.8,64.8 66.8,64.7 66.7,64.6 66.7,64.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,64.7 66.8,64.7 66.8,64.8 66.8,64.7 66.7,64.6 66.7,64.7"
        />
        <polygon
          fill={colors[4]}
          points="66.9,64.8 66.9,64.8 66.9,64.8 66.8,64.7 66.8,64.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.8 66.9,64.8 66.9,64.8 66.8,64.7 66.8,64.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.8 66.9,64.8 66.9,64.8 66.8,64.7 66.8,64.8"
        />
        <polygon
          fill={colors[4]}
          points="67,64.7 67,64.7 66.9,64.7 66.9,64.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,64.7 67,64.7 66.9,64.7 66.9,64.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,64.7 67,64.7 66.9,64.7 66.9,64.7"
        />
        <polygon fill="#CCC" points="66.9,64.7 66.9,64.8 66.9,64.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.7 66.9,64.8 66.9,64.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.7 66.9,64.8 66.9,64.8"
        />
        <polygon fill="#CCC" points="66.7,64.6 66.6,64.6 66.7,64.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,64.6 66.6,64.6 66.7,64.7"
        />
        <polygon
          fill={colors[3]}
          points="67.7,65.4 67.8,65.3 67.8,63.9 67.7,64 67.7,65.2 67,64.6 67,64.6 67,64.7"
        />
        <polygon
          fill={colors[4]}
          points="67.8,65.2 67.8,63.9 67.8,63.9 67.8,63.9 67.8,65.3"
        />
        <polygon fill="#CCC" points="67.8,63.9 67.8,65.2 67.8,65.2 68.5,64.5" />
        <polygon fill={colors[4]} points="67.7,65.2 67.7,64 67,64.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,64.5 66.8,64.4 66.2,63.8 66.2,63.9 66.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,64.5 66.8,64.4 66.2,63.8 66.2,63.9 66.7,64.4"
        />
        <polygon fill={colors[4]} points="66.2,63.9 66.1,63.9 66.7,64.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.2,63.9 66.1,63.9 66.7,64.4"
        />
        <polygon
          fill="#CCC"
          points="66.7,64.6 66.8,64.5 66.7,64.4 66.1,63.9 66,64"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,64.6 66.8,64.5 66.7,64.4 66.1,63.9 66,64"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,64.4 66.8,64.4 66.8,64.5 66.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,64.4 66.8,64.4 66.8,64.5 66.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,64.4 66.8,64.4 66.8,64.5 66.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.5 66.9,64.5 67,64.7 67,64.6 67,64.6 66.8,64.5 66.8,64.5 66.8,64.4 66.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.5 66.9,64.5 67,64.7 67,64.6 67,64.6 66.8,64.5 66.8,64.5 66.8,64.4 66.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.5 66.9,64.5 67,64.7 67,64.6 67,64.6 66.8,64.5 66.8,64.5 66.8,64.4 66.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.5 66.9,64.5 67,64.7 67,64.6 67,64.6 66.8,64.5 66.8,64.5 66.8,64.4 66.8,64.5"
        />
        <polygon
          fill={colors[3]}
          points="66.7,64.6 66.7,64.6 66.8,64.7 66.8,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,64.6 66.7,64.6 66.8,64.7 66.8,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,64.6 66.7,64.6 66.8,64.7 66.8,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,64.6 66.7,64.6 66.8,64.7 66.8,64.6"
        />
        <polygon
          fill={colors[4]}
          points="66.9,64.8 66.9,64.7 66.9,64.7 67,64.7 66.9,64.5 66.8,64.6 66.8,64.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.8 66.9,64.7 66.9,64.7 67,64.7 66.9,64.5 66.8,64.6 66.8,64.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.8 66.9,64.7 66.9,64.7 67,64.7 66.9,64.5 66.8,64.6 66.8,64.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,64.8 66.9,64.7 66.9,64.7 67,64.7 66.9,64.5 66.8,64.6 66.8,64.7"
        />
        <polygon
          fill="#CCC"
          points="66.8,64.6 66.9,64.5 66.9,64.5 66.8,64.5 66.8,64.5 66.7,64.6 66.7,64.6 66.7,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,64.6 66.9,64.5 66.9,64.5 66.8,64.5 66.8,64.5 66.7,64.6 66.7,64.6 66.7,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,64.6 66.9,64.5 66.9,64.5 66.8,64.5 66.8,64.5 66.7,64.6 66.7,64.6 66.7,64.6"
        />
        <polygon fill={colors[3]} points="67.7,63.7 67.1,64.2 67.7,63.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,63.7 67.1,64.2 67.7,63.8"
        />
        <polygon
          fill={colors[3]}
          points="67.8,63.8 67.7,63.8 67.7,64 67.8,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.8 67.7,63.8 67.7,64 67.8,63.9"
        />
        <polygon fill={colors[4]} points="67.8,63.8 67.8,63.9 67.8,63.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.8 67.8,63.9 67.8,63.9"
        />
        <polygon
          fill={colors[4]}
          points="67,64.6 67,64.6 67.7,64 67.7,63.8 67.7,63.8 67.1,64.2 66.9,64.5 66.8,64.5 66.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,64.6 67,64.6 67.7,64 67.7,63.8 67.7,63.8 67.1,64.2 66.9,64.5 66.8,64.5 66.8,64.5"
        />
        <polygon
          fill={colors[3]}
          points="66.8,64.4 66.8,64.5 66.9,64.5 66.9,63.2 66.8,63.2 66.8,64.3 66.2,63.8 66.2,63.8"
        />
        <polygon
          fill={colors[4]}
          points="66.9,64.5 67.1,64.2 67.7,63.7 67.7,63.7 66.9,64.3 66.9,63.1 66.9,63.2"
        />
        <polygon
          fill="#CCC"
          points="67.6,63.6 67.6,63.6 67,63 66.9,63.1 66.9,64.3 67.7,63.7"
        />
        <polygon
          fill={colors[4]}
          points="66.8,64.3 66.8,63.2 66.2,63.8 66.2,63.8 66.2,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,64.4 68.7,64.4 68.7,64.4 68,63.7 68,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,64.4 68.7,64.4 68.7,64.4 68,63.7 68,63.7"
        />
        <polygon fill={colors[4]} points="67.9,63.8 68.6,64.4 68,63.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,63.8 68.6,64.4 68,63.7"
        />
        <polygon fill={colors[4]} points="67.8,63.9 67.8,63.9 67.8,63.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.9 67.8,63.9 67.8,63.8"
        />
        <polygon
          fill="#CCC"
          points="68.5,64.5 68.7,64.4 68.6,64.4 67.9,63.8 67.8,63.8 67.8,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,64.5 68.7,64.4 68.6,64.4 67.9,63.8 67.8,63.8 67.8,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,63.7 68,63.7 67.9,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,63.7 68,63.7 67.9,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,63.7 68,63.7 67.9,63.7"
        />
        <polygon fill={colors[3]} points="67.8,63.8 67.7,63.7 67.7,63.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.8 67.7,63.7 67.7,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.8 67.7,63.7 67.7,63.8"
        />
        <polygon
          fill={colors[3]}
          points="67.7,63.7 67.7,63.7 67.7,63.7 67.7,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,63.7 67.7,63.7 67.7,63.7 67.7,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,63.7 67.7,63.7 67.7,63.7 67.7,63.8"
        />
        <polygon
          fill={colors[4]}
          points="68,63.7 67.9,63.7 67.9,63.8 67.9,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,63.7 67.9,63.7 67.9,63.8 67.9,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,63.7 67.9,63.7 67.9,63.8 67.9,63.8"
        />
        <polygon
          fill={colors[4]}
          points="67.8,63.8 67.8,63.8 67.8,63.8 67.8,63.8 67.8,63.8 67.8,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.8 67.8,63.8 67.8,63.8 67.8,63.8 67.8,63.8 67.8,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.8 67.8,63.8 67.8,63.8 67.8,63.8 67.8,63.8 67.8,63.9"
        />
        <polygon fill="#CCC" points="67.9,63.8 67.8,63.8 67.8,63.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,63.8 67.8,63.8 67.8,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,63.8 67.8,63.8 67.8,63.8"
        />
        <polygon fill="#CCC" points="67.6,63.6 67.6,63.6 67.7,63.7 67.7,63.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,63.6 67.6,63.6 67.7,63.7 67.7,63.7"
        />
        <polygon
          fill={colors[3]}
          points="68,63.7 68.7,64.4 68.7,64.4 68.7,64.3 68.7,62.9 68.7,63 68.7,64.3"
        />
        <polygon
          fill={colors[4]}
          points="68.8,64.3 68.8,64.3 68.8,62.9 68.7,62.9 68.7,64.3"
        />
        <polygon
          fill="#CCC"
          points="69.5,63.6 69.5,63.5 68.8,62.9 68.8,62.9 68.8,64.3 68.8,64.3"
        />
        <polygon fill={colors[4]} points="68.7,64.3 68.7,63 68,63.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,63.5 67.8,63.5 67.2,62.9 67.1,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,63.5 67.8,63.5 67.2,62.9 67.1,62.9"
        />
        <polygon fill={colors[4]} points="67.1,62.9 67.1,63 67.7,63.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,62.9 67.1,63 67.7,63.5"
        />
        <polygon
          fill="#CCC"
          points="67.6,63.6 67.7,63.5 67.7,63.5 67.7,63.5 67.1,63 67,63"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,63.6 67.7,63.5 67.7,63.5 67.7,63.5 67.1,63 67,63"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,63.7 67.9,63.6 67.8,63.6 67.8,63.6 67.9,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,63.7 67.9,63.6 67.8,63.6 67.8,63.6 67.9,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,63.7 67.9,63.6 67.8,63.6 67.8,63.6 67.9,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,63.7 67.9,63.6 67.8,63.6 67.8,63.6 67.9,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.5 67.8,63.5 67.9,63.5 67.9,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.5 67.8,63.5 67.9,63.5 67.9,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.5 67.8,63.5 67.9,63.5 67.9,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.5 67.8,63.5 67.9,63.5 67.9,63.5"
        />
        <polygon fill={colors[3]} points="67.7,63.7 67.8,63.8 67.8,63.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,63.7 67.8,63.8 67.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,63.7 67.8,63.8 67.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,63.7 67.8,63.8 67.8,63.6"
        />
        <polygon
          fill={colors[4]}
          points="67.8,63.8 67.8,63.7 67.9,63.8 67.9,63.7 67.8,63.6 67.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.8 67.8,63.7 67.9,63.8 67.9,63.7 67.8,63.6 67.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.8 67.8,63.7 67.9,63.8 67.9,63.7 67.8,63.6 67.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.8 67.8,63.7 67.9,63.8 67.9,63.7 67.8,63.6 67.8,63.6"
        />
        <polygon fill="#CCC" points="67.8,63.7 67.8,63.8 67.9,63.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.7 67.8,63.8 67.9,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.7 67.8,63.8 67.9,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.7 67.8,63.8 67.9,63.8"
        />
        <polygon
          fill="#CCC"
          points="67.8,63.6 67.8,63.6 67.8,63.6 67.8,63.5 67.8,63.5 67.7,63.5 67.6,63.6 67.7,63.7 67.7,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.6 67.8,63.6 67.8,63.6 67.8,63.5 67.8,63.5 67.7,63.5 67.6,63.6 67.7,63.7 67.7,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.6 67.8,63.6 67.8,63.6 67.8,63.5 67.8,63.5 67.7,63.5 67.6,63.6 67.7,63.7 67.7,63.7"
        />
        <polygon
          fill={colors[3]}
          points="68.7,63 68.7,62.9 68.7,62.8 68.6,62.7 68.6,62.7 68.1,63.3 68.7,62.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,63 68.7,62.9 68.7,62.8 68.6,62.7 68.6,62.7 68.1,63.3 68.7,62.8"
        />
        <polygon fill={colors[4]} points="68.8,62.9 68.7,62.8 68.7,62.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,62.9 68.7,62.8 68.7,62.9"
        />
        <polygon
          fill={colors[4]}
          points="67.8,63.5 67.8,63.6 67.9,63.6 68,63.7 68.7,63 68.7,62.8 68.1,63.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,63.5 67.8,63.6 67.9,63.6 68,63.7 68.7,63 68.7,62.8 68.1,63.3"
        />
        <polygon
          fill={colors[3]}
          points="67.8,63.5 67.8,63.5 67.8,63.5 67.8,63.5 67.8,62.2 67.8,62.3 67.8,63.4 67.2,62.9 67.2,62.9"
        />
        <polygon
          fill={colors[4]}
          points="67.8,63.5 68.1,63.3 68.6,62.7 67.9,63.4 67.9,62.2 67.8,62.2"
        />
        <polygon
          fill="#CCC"
          points="68.5,62.6 68.5,62.6 68,62 67.9,62.2 67.9,63.4 68.6,62.7 68.6,62.7"
        />
        <polygon
          fill={colors[4]}
          points="67.8,63.4 67.8,62.3 67.2,62.9 67.2,62.9 67.2,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,65.6 67.9,65.6 68.6,66.3 68.6,66.3 68.7,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,65.6 67.9,65.6 68.6,66.3 68.6,66.3 68.7,66.3"
        />
        <polygon fill={colors[4]} points="67.9,65.7 68.6,66.3 67.9,65.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,65.7 68.6,66.3 67.9,65.6"
        />
        <polygon
          fill="#CCC"
          points="68.6,66.3 68.6,66.3 67.9,65.7 67.8,65.7 67.8,65.7 68.5,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.3 68.6,66.3 67.9,65.7 67.8,65.7 67.8,65.7 68.5,66.4"
        />
        <polygon
          fill={colors[3]}
          points="67.7,65.6 67.7,65.6 67.7,65.7 67.7,65.7 67.6,65.6 67.6,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,65.6 67.7,65.6 67.7,65.7 67.7,65.7 67.6,65.6 67.6,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,65.6 67.7,65.6 67.7,65.7 67.7,65.7 67.6,65.6 67.6,65.6"
        />
        <polygon
          fill={colors[4]}
          points="67.8,65.7 67.8,65.7 67.8,65.7 67.7,65.7 67.7,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.7 67.8,65.7 67.8,65.7 67.7,65.7 67.7,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.7 67.8,65.7 67.8,65.7 67.7,65.7 67.7,65.7"
        />
        <polygon
          fill={colors[4]}
          points="67.9,65.6 67.9,65.6 67.8,65.7 67.9,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,65.6 67.9,65.6 67.8,65.7 67.9,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,65.6 67.9,65.6 67.8,65.7 67.9,65.7"
        />
        <polygon fill="#CCC" points="67.8,65.7 67.8,65.7 67.8,65.7 67.9,65.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.7 67.8,65.7 67.8,65.7 67.9,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.7 67.8,65.7 67.8,65.7 67.9,65.7"
        />
        <polygon fill="#CCC" points="67.6,65.5 67.6,65.5 67.6,65.6 67.6,65.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,65.5 67.6,65.5 67.6,65.6 67.6,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,65.6 67.9,65.5 67.8,65.4 67.8,65.4 67.7,65.4 67.7,65.4 67.8,65.4 67.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,65.6 67.9,65.5 67.8,65.4 67.8,65.4 67.7,65.4 67.7,65.4 67.8,65.4 67.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,65.6 67.9,65.5 67.8,65.4 67.8,65.4 67.7,65.4 67.7,65.4 67.8,65.4 67.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,65.6 67.9,65.5 67.8,65.4 67.8,65.4 67.7,65.4 67.7,65.4 67.8,65.4 67.8,65.5"
        />
        <polygon
          fill={colors[3]}
          points="67.6,65.6 67.6,65.6 67.7,65.7 67.7,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,65.6 67.6,65.6 67.7,65.7 67.7,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,65.6 67.6,65.6 67.7,65.7 67.7,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,65.6 67.6,65.6 67.7,65.7 67.7,65.5"
        />
        <polygon
          fill={colors[4]}
          points="67.8,65.7 67.8,65.6 67.8,65.7 67.9,65.6 67.8,65.5 67.7,65.5 67.7,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.7 67.8,65.6 67.8,65.7 67.9,65.6 67.8,65.5 67.7,65.5 67.7,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.7 67.8,65.6 67.8,65.7 67.9,65.6 67.8,65.5 67.7,65.5 67.7,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.7 67.8,65.6 67.8,65.7 67.9,65.6 67.8,65.5 67.7,65.5 67.7,65.7"
        />
        <polygon fill="#CCC" points="67.8,65.6 67.8,65.7 67.8,65.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.6 67.8,65.7 67.8,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.6 67.8,65.7 67.8,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.6 67.8,65.7 67.8,65.7"
        />
        <polygon
          fill="#CCC"
          points="67.6,65.6 67.6,65.6 67.7,65.5 67.8,65.5 67.8,65.4 67.7,65.4 67.7,65.4 67.6,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,65.6 67.6,65.6 67.7,65.5 67.8,65.5 67.8,65.4 67.7,65.4 67.7,65.4 67.6,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,65.6 67.6,65.6 67.7,65.5 67.8,65.5 67.8,65.4 67.7,65.4 67.7,65.4 67.6,65.5"
        />
        <polygon
          fill={colors[3]}
          points="67.8,65.4 67.8,65.4 67.8,65.3 67.7,65.4 67.7,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.4 67.8,65.4 67.8,65.3 67.7,65.4 67.7,65.4"
        />
        <polygon
          fill={colors[4]}
          points="67.8,65.4 67.9,65.3 68.4,64.7 67.8,65.2 67.8,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,65.4 67.9,65.3 68.4,64.7 67.8,65.2 67.8,65.3"
        />
        <polygon fill="#CCC" points="68.5,64.5 67.8,65.2 68.4,64.7 68.6,64.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,64.5 67.8,65.2 68.4,64.7 68.6,64.6"
        />
        <polygon fill="#CCC" points="68.5,64.5 68.6,64.6 68.6,64.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,64.5 68.6,64.6 68.6,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,64.5 68.6,64.6 68.6,64.6"
        />
        <polygon
          fill={colors[3]}
          points="68.7,66.3 68.7,66.2 68.7,64.7 68.7,64.7 68.7,66.1 67.9,65.5 67.9,65.6 67.9,65.6"
        />
        <polygon
          fill={colors[3]}
          points="68.7,64.7 68.6,64.6 68.6,64.6 68.4,64.7 67.9,65.3"
        />
        <polygon
          fill={colors[4]}
          points="68.8,66.1 68.8,64.8 68.7,64.7 68.7,66.2 69,65.9"
        />
        <polygon fill="#CCC" points="69,65.9 69.5,65.5 68.8,64.8 68.8,66.1" />
        <polygon
          fill={colors[4]}
          points="67.9,65.5 68.7,66.1 68.7,64.7 68.7,64.7 67.9,65.3 67.8,65.4 67.8,65.4 67.8,65.4"
        />
        <polygon fill={colors[3]} points="68.7,64.3 68.7,64.4 68.7,64.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.3 68.7,64.4 68.7,64.4"
        />
        <polygon
          fill={colors[4]}
          points="68.8,64.4 68.9,64.3 69.4,63.8 68.8,64.3 68.7,64.3 68.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,64.4 68.9,64.3 69.4,63.8 68.8,64.3 68.7,64.3 68.7,64.4"
        />
        <polygon fill="#CCC" points="69.5,63.6 69.5,63.6 68.8,64.3 69.4,63.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,63.6 69.5,63.6 68.8,64.3 69.4,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.3 68.9,64.6 68.9,64.6 69.5,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.3 68.9,64.6 68.9,64.6 69.5,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.5 68.7,64.5 68.9,64.6 68.9,64.6 68.9,64.5 68.7,64.5 68.7,64.5 68.7,64.4 68.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.5 68.7,64.5 68.9,64.6 68.9,64.6 68.9,64.5 68.7,64.5 68.7,64.5 68.7,64.4 68.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.5 68.7,64.5 68.9,64.6 68.9,64.6 68.9,64.5 68.7,64.5 68.7,64.5 68.7,64.4 68.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.5 68.7,64.5 68.9,64.6 68.9,64.6 68.9,64.5 68.7,64.5 68.7,64.5 68.7,64.4 68.7,64.4"
        />
        <polygon
          fill="#CCC"
          points="68.7,64.5 68.7,64.5 68.7,64.5 68.7,64.4 68.5,64.5 68.6,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.5 68.7,64.5 68.7,64.5 68.7,64.4 68.5,64.5 68.6,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.5 68.7,64.5 68.7,64.5 68.7,64.4 68.5,64.5 68.6,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.5 68.7,64.5 68.7,64.5 68.7,64.4 68.5,64.5 68.6,64.6"
        />
        <polygon
          fill={colors[3]}
          points="68.7,64.5 68.7,64.4 68.7,64.4 68.7,64.4 68.7,64.4 68.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.5 68.7,64.4 68.7,64.4 68.7,64.4 68.7,64.4 68.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.5 68.7,64.4 68.7,64.4 68.7,64.4 68.7,64.4 68.7,64.4"
        />
        <polygon fill={colors[4]} points="68.8,64.4 68.7,64.4 68.7,64.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,64.4 68.7,64.4 68.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,64.4 68.7,64.4 68.7,64.4"
        />
        <polygon
          fill={colors[3]}
          points="68.7,64.5 68.6,64.6 68.7,64.7 68.7,64.7 68.7,64.7 68.7,64.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.5 68.6,64.6 68.7,64.7 68.7,64.7 68.7,64.7 68.7,64.7"
        />
        <polygon
          fill={colors[4]}
          points="68.7,64.7 68.8,64.8 68.8,64.7 69.5,65.3 68.9,64.6 68.9,64.6 68.7,64.5 68.7,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,64.7 68.8,64.8 68.8,64.7 69.5,65.3 68.9,64.6 68.9,64.6 68.7,64.5 68.7,64.5"
        />
        <polygon
          fill="#CCC"
          points="69.6,65.3 69.5,65.3 68.8,64.7 68.8,64.8 69.5,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.3 69.5,65.3 68.8,64.7 68.8,64.8 69.5,65.5"
        />
        <polygon
          fill={colors[3]}
          points="69.6,63.7 69.5,63.6 69.4,63.8 68.9,64.3"
        />
        <polygon
          fill={colors[3]}
          points="69.6,65.3 69.6,65.3 69.6,65.3 69.7,65.3 69.7,63.7 69.6,63.7 69.6,65.2 68.9,64.5 68.9,64.6 68.9,64.6"
        />
        <polygon
          fill={colors[4]}
          points="69.7,65.3 70,65 69.7,65.2 69.7,63.8 69.7,63.7"
        />
        <polygon
          fill="#CCC"
          points="70,65 70.4,64.5 70.4,64.5 70.5,64.5 69.7,63.8 69.7,65.2"
        />
        <polygon
          fill={colors[4]}
          points="69.6,65.2 69.6,63.7 69.6,63.7 68.9,64.3 68.8,64.4 68.7,64.4 68.7,64.4 68.7,64.5 68.7,64.5 68.9,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6,66.5 66.6,66.5 65.9,67.3 66,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6,66.5 66.6,66.5 65.9,67.3 66,67.2"
        />
        <polygon
          fill={colors[3]}
          points="66.8,66.6 66.7,66.6 66.7,66.8 66.8,66.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.6 66.7,66.6 66.7,66.8 66.8,66.7"
        />
        <polygon fill={colors[3]} points="66,67.2 66.7,66.6 66.6,66.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.2 66.7,66.6 66.6,66.5"
        />
        <polygon fill={colors[4]} points="66.8,66.6 66.8,66.7 66.8,66.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.6 66.8,66.7 66.8,66.7"
        />
        <polygon
          fill={colors[4]}
          points="66,67.4 66.7,66.8 66.7,66.6 66.7,66.6 66,67.2 65.9,67.3 65.9,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.4 66.7,66.8 66.7,66.6 66.7,66.6 66,67.2 65.9,67.3 65.9,67.3"
        />
        <polygon
          fill={colors[3]}
          points="66.8,66.6 66.7,66.5 66.6,66.5 66.7,66.6 66.7,66.6 66.7,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.6 66.7,66.5 66.6,66.5 66.7,66.6 66.7,66.6 66.7,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.6 66.7,66.5 66.6,66.5 66.7,66.6 66.7,66.6 66.7,66.6"
        />
        <polygon fill={colors[4]} points="66.8,66.7 66.8,66.6 66.8,66.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.7 66.8,66.6 66.8,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.7 66.8,66.6 66.8,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,65.6 66,65.6 66.7,66.3 66.7,66.3 66.8,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,65.6 66,65.6 66.7,66.3 66.7,66.3 66.8,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.3 66.7,66.3 66.7,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.3 66.7,66.3 66.7,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.3 66.7,66.3 66.7,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,66.5 66.9,66.5 66.8,66.4 66.8,66.4 66.8,66.3 66.7,66.3 66.8,66.4 66.8,66.4 66.9,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,66.5 66.9,66.5 66.8,66.4 66.8,66.4 66.8,66.3 66.7,66.3 66.8,66.4 66.8,66.4 66.9,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,66.5 66.9,66.5 66.8,66.4 66.8,66.4 66.8,66.3 66.7,66.3 66.8,66.4 66.8,66.4 66.9,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,66.5 66.9,66.5 66.8,66.4 66.8,66.4 66.8,66.3 66.7,66.3 66.8,66.4 66.8,66.4 66.9,66.6"
        />
        <polygon
          fill={colors[3]}
          points="66.8,66.4 66.7,66.5 66.7,66.5 66.8,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.4 66.7,66.5 66.7,66.5 66.8,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.4 66.7,66.5 66.7,66.5 66.8,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.4 66.7,66.5 66.7,66.5 66.8,66.6"
        />
        <polygon
          fill={colors[4]}
          points="66.8,66.7 66.8,66.6 66.8,66.6 66.9,66.6 66.9,66.6 66.8,66.4 66.8,66.4 66.8,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.7 66.8,66.6 66.8,66.6 66.9,66.6 66.9,66.6 66.8,66.4 66.8,66.4 66.8,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.7 66.8,66.6 66.8,66.6 66.9,66.6 66.9,66.6 66.8,66.4 66.8,66.4 66.8,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.7 66.8,66.6 66.8,66.6 66.9,66.6 66.9,66.6 66.8,66.4 66.8,66.4 66.8,66.6"
        />
        <polygon fill="#CCC" points="66.8,66.6 66.8,66.6 66.9,66.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.6 66.8,66.6 66.9,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.6 66.8,66.6 66.9,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.6 66.8,66.6 66.9,66.6"
        />
        <polygon fill={colors[3]} points="66.9,66.5 67,66.5 67,66.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,66.5 67,66.5 67,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,66.5 67,66.5 67,66.5"
        />
        <polygon
          fill={colors[4]}
          points="67,66.5 66.8,66.4 66.8,66.4 66.8,66.4 66.8,66.4 66.8,66.4 66.9,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,66.5 66.8,66.4 66.8,66.4 66.8,66.4 66.8,66.4 66.8,66.4 66.9,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,66.5 66.8,66.4 66.8,66.4 66.8,66.4 66.8,66.4 66.8,66.4 66.9,66.5"
        />
        <polygon
          fill={colors[3]}
          points="66.8,66.3 66.8,66.4 66.8,66.4 66.8,66.4 66.8,66.3 66.8,66.3 66.2,65.7 66.1,65.6 66.1,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.3 66.8,66.4 66.8,66.4 66.8,66.4 66.8,66.3 66.8,66.3 66.2,65.7 66.1,65.6 66.1,65.6"
        />
        <polygon fill={colors[4]} points="66.8,66.3 66.8,66.4 66.8,66.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,66.3 66.8,66.4 66.8,66.4"
        />
        <polygon fill={colors[4]} points="66.1,65.6 66.1,65.6 66.2,65.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,65.6 66.1,65.6 66.2,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,65.6 66,65.6 66,65.6 66,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,65.6 66,65.6 66,65.6 66,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,65.6 66,65.6 66,65.6 66,65.6"
        />
        <polygon fill={colors[3]} points="66.1,65.6 66,65.6 66,65.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,65.6 66,65.6 66,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,65.6 66,65.6 66,65.6"
        />
        <polygon
          fill={colors[4]}
          points="66,65.6 66,65.6 66.1,65.6 66.1,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,65.6 66,65.6 66.1,65.6 66.1,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,65.6 66,65.6 66.1,65.6 66.1,65.6"
        />
        <polygon
          fill={colors[3]}
          points="65.8,67.2 65.8,67.3 65.8,65.8 65.8,65.8 65.8,67.1 65.4,66.8 65.4,66.8"
        />
        <polygon
          fill={colors[4]}
          points="65.9,67.3 65.9,67.3 66.6,66.5 66.7,66.5 66.7,66.5 65.9,67.1 65.9,65.7 65.8,65.8 65.8,67.3"
        />
        <polygon
          fill={colors[4]}
          points="66.7,66.3 66,65.6 66,65.6 65.9,65.7"
        />
        <polygon
          fill="#CCC"
          points="66.8,66.4 66.8,66.4 66.8,66.4 66.7,66.3 66.7,66.3 66.7,66.3 65.9,65.7 65.9,65.7 65.9,67.1 66.7,66.5 66.7,66.5"
        />
        <polygon
          fill={colors[4]}
          points="65.8,65.8 65.5,66.1 65.4,66.8 65.8,67.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,65.4 65.8,65.4 65.6,65.2 65.6,65.3 65.7,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,65.4 65.8,65.4 65.6,65.2 65.6,65.3 65.7,65.3"
        />
        <polygon fill={colors[3]} points="65.8,65.4 65.7,65.2 65.6,65.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,65.4 65.7,65.2 65.6,65.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,65.6 65.6,65.7 65.6,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,65.6 65.6,65.7 65.6,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,65.6 66,65.6 65.9,65.4 65.9,65.4 65.8,65.4 65.8,65.4 65.9,65.5 65.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,65.6 66,65.6 65.9,65.4 65.9,65.4 65.8,65.4 65.8,65.4 65.9,65.5 65.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,65.6 66,65.6 65.9,65.4 65.9,65.4 65.8,65.4 65.8,65.4 65.9,65.5 65.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,65.6 66,65.6 65.9,65.4 65.9,65.4 65.8,65.4 65.8,65.4 65.9,65.5 65.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,65.6 65.7,65.6 65.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,65.6 65.7,65.6 65.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,65.6 65.7,65.6 65.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,65.6 65.7,65.6 65.8,65.5"
        />
        <polygon
          fill={colors[4]}
          points="66,65.6 65.9,65.4 65.9,65.5 65.9,65.4 65.9,65.4 65.9,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,65.6 65.9,65.4 65.9,65.5 65.9,65.4 65.9,65.4 65.9,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,65.6 65.9,65.4 65.9,65.5 65.9,65.4 65.9,65.4 65.9,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,65.6 65.9,65.4 65.9,65.5 65.9,65.4 65.9,65.4 65.9,65.4"
        />
        <polygon
          fill={colors[3]}
          points="65.9,65.4 65.9,65.4 65.9,65.5 65.9,65.4 65.8,65.4 65.8,65.4 65.8,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,65.4 65.9,65.4 65.9,65.5 65.9,65.4 65.8,65.4 65.8,65.4 65.8,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,65.4 65.9,65.4 65.9,65.5 65.9,65.4 65.8,65.4 65.8,65.4 65.8,65.4"
        />
        <polygon fill={colors[4]} points="65.9,65.5 65.9,65.4 65.9,65.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,65.5 65.9,65.4 65.9,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,65.5 65.9,65.4 65.9,65.4"
        />
        <polygon
          fill={colors[3]}
          points="65.8,65.6 65.8,65.8 65.8,65.8 65.8,65.5 65.8,65.5 65.7,65.6 65.7,65.6 65.6,65.7 65.5,65.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,65.6 65.8,65.8 65.8,65.8 65.8,65.5 65.8,65.5 65.7,65.6 65.7,65.6 65.6,65.7 65.5,65.8"
        />
        <polygon
          fill={colors[4]}
          points="65.9,65.7 65.9,65.6 65.9,65.7 66,65.6 65.8,65.5 65.8,65.5 65.8,65.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,65.7 65.9,65.6 65.9,65.7 66,65.6 65.8,65.5 65.8,65.5 65.8,65.8"
        />
        <polygon fill="#CCC" points="65.9,65.6 65.9,65.7 65.9,65.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,65.6 65.9,65.7 65.9,65.7"
        />
        <polygon
          fill={colors[4]}
          points="65.8,65.6 65.5,65.8 65.5,66.1 65.8,65.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,65.6 65.5,65.8 65.5,66.1 65.8,65.8"
        />
        <polygon fill={colors[4]} points="65.6,65.3 65.6,65.3 65.7,65.3" />
        <polygon
          fill={colors[4]}
          points="65.7,65.6 65.7,65.6 65.6,65.7 65.6,65.7"
        />
        <polygon
          fill="#CCC"
          points="65.7,65.6 65.8,65.5 65.8,65.5 65.8,65.5 65.9,65.5 65.8,65.4 65.8,65.4 65.7,65.3 65.6,65.3 65.6,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,68.2 69.4,67.5 69.4,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.4 68.7,68.2 68.8,68.3 68.9,68.2 69.5,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.4 68.7,68.2 68.8,68.3 68.9,68.2 69.5,67.5"
        />
        <polygon fill={colors[3]} points="68.9,68.2 69.6,67.6 69.5,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,68.2 69.6,67.6 69.5,67.5"
        />
        <polygon
          fill={colors[4]}
          points="69.6,67.6 68.9,68.2 68.8,68.3 68.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,67.6 68.9,68.2 68.8,68.3 68.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,67.4 69.8,67.5 69.8,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,67.4 69.8,67.5 69.8,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,67.4 69.8,67.5 69.8,67.5"
        />
        <polygon
          fill={colors[3]}
          points="69.7,67.5 69.6,67.6 69.6,67.6 69.7,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,67.5 69.6,67.6 69.6,67.6 69.7,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,67.5 69.6,67.6 69.6,67.6 69.7,67.6"
        />
        <polygon
          fill={colors[4]}
          points="69.7,67.5 69.7,67.6 69.8,67.5 69.8,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,67.5 69.7,67.6 69.8,67.5 69.8,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,67.5 69.7,67.6 69.8,67.5 69.8,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.2 68.7,68.2 68.8,68.3 68.8,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.2 68.7,68.2 68.8,68.3 68.8,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.2 68.7,68.2 68.8,68.3 68.8,68.3"
        />
        <polygon
          fill={colors[4]}
          points="68.8,68.3 68.8,68.3 68.9,68.4 68.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,68.3 68.8,68.3 68.9,68.4 68.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,68.3 68.8,68.3 68.9,68.4 68.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,67.5 68.5,68.2 68.6,68.2 68.6,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,67.5 68.5,68.2 68.6,68.2 68.6,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.3 68.6,68.2 68.6,68.2 68.7,68.3 68.7,68.2 68.7,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.3 68.6,68.2 68.6,68.2 68.7,68.3 68.7,68.2 68.7,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.3 68.6,68.2 68.6,68.2 68.7,68.3 68.7,68.2 68.7,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,68.4 68.5,68.4 68.6,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,68.4 68.5,68.4 68.6,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,68.4 68.5,68.4 68.6,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.3 68.8,68.3 68.7,68.2 68.7,68.3 68.7,68.3 68.6,68.4 68.5,68.4 68.5,68.4 68.7,68.3 68.8,68.4 68.8,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.3 68.8,68.3 68.7,68.2 68.7,68.3 68.7,68.3 68.6,68.4 68.5,68.4 68.5,68.4 68.7,68.3 68.8,68.4 68.8,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.3 68.8,68.3 68.7,68.2 68.7,68.3 68.7,68.3 68.6,68.4 68.5,68.4 68.5,68.4 68.7,68.3 68.8,68.4 68.8,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.3 68.8,68.3 68.7,68.2 68.7,68.3 68.7,68.3 68.6,68.4 68.5,68.4 68.5,68.4 68.7,68.3 68.8,68.4 68.8,68.4"
        />
        <polygon
          fill={colors[4]}
          points="68.8,68.3 68.7,68.3 68.8,68.4 68.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,68.3 68.7,68.3 68.8,68.4 68.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,68.3 68.7,68.3 68.8,68.4 68.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,68.3 68.7,68.3 68.8,68.4 68.9,68.4"
        />
        <polygon fill={colors[3]} points="68.5,68.4 68.7,68.5 68.7,68.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,68.4 68.7,68.5 68.7,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,68.4 68.7,68.5 68.7,68.3"
        />
        <polygon
          fill={colors[4]}
          points="68.7,68.5 68.7,68.5 68.7,68.5 68.7,68.5 68.7,68.5 68.8,68.4 68.7,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.5 68.7,68.5 68.7,68.5 68.7,68.5 68.7,68.5 68.8,68.4 68.7,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,68.5 68.7,68.5 68.7,68.5 68.7,68.5 68.7,68.5 68.8,68.4 68.7,68.3"
        />
        <polygon fill={colors[4]} points="67.8,67.6 68.5,68.2 67.9,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,67.6 68.5,68.2 67.9,67.5"
        />
        <polygon fill={colors[4]} points="67.7,67.6 67.8,67.7 67.8,67.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.6 67.8,67.7 67.8,67.6"
        />
        <polygon
          fill="#CCC"
          points="68.5,68.2 67.8,67.6 67.8,67.6 67.8,67.7 68.5,68.4 68.5,68.4 68.6,68.4 68.7,68.3 68.7,68.3 68.6,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,68.2 67.8,67.6 67.8,67.6 67.8,67.7 68.5,68.4 68.5,68.4 68.6,68.4 68.7,68.3 68.7,68.3 68.6,68.2"
        />
        <polygon
          fill={colors[3]}
          points="67.7,67.6 67.6,67.5 67.6,67.5 67.6,67.5 67.7,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.6 67.6,67.5 67.6,67.5 67.6,67.5 67.7,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.6 67.6,67.5 67.6,67.5 67.6,67.5 67.7,67.5"
        />
        <polygon fill={colors[4]} points="67.9,67.5 67.8,67.5 67.8,67.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,67.5 67.8,67.5 67.8,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,67.5 67.8,67.5 67.8,67.6"
        />
        <polygon
          fill={colors[4]}
          points="67.8,67.6 67.7,67.6 67.7,67.6 67.7,67.6 67.7,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,67.6 67.7,67.6 67.7,67.6 67.7,67.6 67.7,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,67.6 67.7,67.6 67.7,67.6 67.7,67.6 67.7,67.6"
        />
        <polygon fill="#CCC" points="67.8,67.6 67.8,67.6 67.8,67.6 67.8,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,67.6 67.8,67.6 67.8,67.6 67.8,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,67.6 67.8,67.6 67.8,67.6 67.8,67.5"
        />
        <polygon fill={colors[4]} points="67.6,67.5 67.7,67.6 67.7,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,67.5 67.7,67.6 67.7,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,67.5 67.7,67.6 67.7,67.5"
        />
        <polygon fill="#CCC" points="67.6,67.4 67.5,67.4 67.6,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,67.4 67.5,67.4 67.6,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,66.5 68.5,66.5 67.8,67.2 67.9,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,66.5 68.5,66.5 67.8,67.2 67.9,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.3 67.7,67.3 67.8,67.4 67.8,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.3 67.7,67.3 67.8,67.4 67.8,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.3 67.7,67.3 67.8,67.4 67.8,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.3 67.7,67.3 67.8,67.4 67.8,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,67.4 67.6,67.4 67.6,67.5 67.7,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,67.4 67.6,67.4 67.6,67.5 67.7,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,67.4 67.6,67.4 67.6,67.5 67.7,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,67.4 67.6,67.4 67.6,67.5 67.7,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,67.4 67.7,67.3 67.7,67.3 67.9,67.5 67.9,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,67.4 67.7,67.3 67.7,67.3 67.9,67.5 67.9,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,67.4 67.7,67.3 67.7,67.3 67.9,67.5 67.9,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,67.4 67.7,67.3 67.7,67.3 67.9,67.5 67.9,67.5"
        />
        <polygon
          fill={colors[3]}
          points="67.7,67.4 67.6,67.5 67.7,67.6 67.7,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.4 67.6,67.5 67.7,67.6 67.7,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.4 67.6,67.5 67.7,67.6 67.7,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.4 67.6,67.5 67.7,67.6 67.7,67.3"
        />
        <polygon
          fill={colors[4]}
          points="67.8,67.6 67.8,67.5 67.8,67.5 67.9,67.5 67.7,67.3 67.7,67.3 67.7,67.6 67.7,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,67.6 67.8,67.5 67.8,67.5 67.9,67.5 67.7,67.3 67.7,67.3 67.7,67.6 67.7,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,67.6 67.8,67.5 67.8,67.5 67.9,67.5 67.7,67.3 67.7,67.3 67.7,67.6 67.7,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,67.6 67.8,67.5 67.8,67.5 67.9,67.5 67.7,67.3 67.7,67.3 67.7,67.6 67.7,67.6"
        />
        <polygon
          fill="#CCC"
          points="67.7,67.4 67.7,67.3 67.7,67.3 67.7,67.3 67.7,67.3 67.7,67.3 67.7,67.3 67.6,67.4 67.6,67.4 67.6,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.4 67.7,67.3 67.7,67.3 67.7,67.3 67.7,67.3 67.7,67.3 67.7,67.3 67.6,67.4 67.6,67.4 67.6,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.4 67.7,67.3 67.7,67.3 67.7,67.3 67.7,67.3 67.7,67.3 67.7,67.3 67.6,67.4 67.6,67.4 67.6,67.4"
        />
        <polygon fill={colors[3]} points="67.7,67.3 67.7,67.2 67.7,67.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.3 67.7,67.2 67.7,67.2"
        />
        <polygon
          fill={colors[4]}
          points="67.7,67.3 67.8,67.2 68.5,66.5 68.5,66.5 68,66.9 67.7,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,67.3 67.8,67.2 68.5,66.5 68.5,66.5 68,66.9 67.7,67.2"
        />
        <polygon fill="#CCC" points="68,66.9 68.5,66.5 68.5,66.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,66.9 68.5,66.5 68.5,66.5"
        />
        <polygon
          fill={colors[3]}
          points="68.7,66.7 68.6,66.6 68.6,68.1 67.9,67.4 67.9,67.5 67.9,67.5 68.6,68.2 68.7,68.3"
        />
        <polygon fill={colors[3]} points="67.9,67.2 68.6,66.6 68.5,66.5" />
        <polygon
          fill={colors[4]}
          points="69.5,67.4 68.7,68.1 68.7,66.7 68.7,66.7 68.7,68.3 68.7,68.2 68.7,68.2"
        />
        <polygon fill="#CCC" points="68.7,68.1 69.5,67.4 68.7,66.7" />
        <polygon
          fill={colors[4]}
          points="68.6,68.1 68.6,66.6 68.6,66.6 67.9,67.2 67.8,67.2 67.7,67.3 67.7,67.3 67.7,67.3 67.7,67.3 67.7,67.3 67.9,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,67.3 69.7,67.3 69.7,67.3 69.7,67.3 70.4,66.5 70.4,66.5 70.4,66.5 70.4,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,67.3 69.8,67.2 70.5,66.5 70.4,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,67.3 69.8,67.2 70.5,66.5 70.4,66.5"
        />
        <polygon fill={colors[3]} points="70.6,66.6 70.5,66.5 69.8,67.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.6 70.5,66.5 69.8,67.2"
        />
        <polygon fill={colors[3]} points="70.6,66.6 70.6,66.6 70.6,66.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.6 70.6,66.6 70.6,66.7"
        />
        <polygon
          fill={colors[4]}
          points="69.7,67.3 69.7,67.3 69.8,67.4 70.6,66.7 70.6,66.6 70.6,66.6 69.8,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,67.3 69.7,67.3 69.8,67.4 70.6,66.7 70.6,66.6 70.6,66.6 69.8,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,66.5 70.5,66.5 70.4,66.5 70.4,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,66.5 70.5,66.5 70.4,66.5 70.4,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,66.5 70.5,66.5 70.4,66.5 70.4,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.5 70.7,66.5 70.8,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.5 70.7,66.5 70.8,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.5 70.7,66.5 70.8,66.5"
        />
        <polygon
          fill={colors[3]}
          points="70.6,66.6 70.6,66.6 70.5,66.5 70.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.6 70.6,66.6 70.5,66.5 70.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.6 70.6,66.6 70.5,66.5 70.5,66.5"
        />
        <polygon
          fill={colors[4]}
          points="70.6,66.6 70.6,66.6 70.7,66.5 70.7,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.6 70.6,66.6 70.7,66.5 70.7,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.6 70.6,66.6 70.7,66.5 70.7,66.5"
        />
        <polygon
          fill={colors[4]}
          points="70.7,66.5 70.8,66.5 70.8,66.5 70.8,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.5 70.8,66.5 70.8,66.5 70.8,66.5"
        />
        <polygon fill="#CCC" points="68.5,66.5 68.5,66.4 68.5,66.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,66.5 68.5,66.4 68.5,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,66.3 68.8,66.2 69.5,65.6 69.5,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,66.3 68.8,66.2 69.5,65.6 69.5,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.3 68.7,66.3 68.6,66.3 68.6,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.3 68.7,66.3 68.6,66.3 68.6,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.3 68.7,66.3 68.6,66.3 68.6,66.3"
        />
        <polygon fill="#CCC" points="68.6,66.3 68.6,66.3 68.5,66.4 68.5,66.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.3 68.6,66.3 68.5,66.4 68.5,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.3 68.6,66.3 68.5,66.4 68.5,66.4"
        />
        <polygon fill={colors[3]} points="68.7,66.3 68.7,66.3 68.7,66.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.3 68.7,66.3 68.7,66.2"
        />
        <polygon
          fill={colors[4]}
          points="68.7,66.3 68.7,66.3 68.8,66.3 69.5,65.6 69.5,65.6 69,65.9 68.7,66.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.3 68.7,66.3 68.8,66.3 69.5,65.6 69.5,65.6 69,65.9 68.7,66.2"
        />
        <polygon
          fill="#CCC"
          points="69,65.9 69.5,65.6 69.4,65.5 69.5,65.5 69.5,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,65.9 69.5,65.6 69.4,65.5 69.5,65.5 69.5,65.5"
        />
        <polygon fill="#CCC" points="69.5,65.5 69.5,65.5 69.6,65.3 69.6,65.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,65.5 69.5,65.5 69.6,65.3 69.6,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,65.5 69.5,65.5 69.6,65.3 69.6,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.2 69.6,67.3 69.7,67.3 69.7,67.3 69.6,67.3 68.8,66.5 68.8,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.2 69.6,67.3 69.7,67.3 69.7,67.3 69.6,67.3 68.8,66.5 68.8,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.4 69.5,67.5 69.5,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.4 69.5,67.5 69.5,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.4 69.5,67.5 69.5,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,67.3 69.7,67.3 69.7,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,67.3 69.7,67.3 69.7,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,67.3 69.7,67.3 69.7,67.3"
        />
        <polygon
          fill={colors[3]}
          points="69.5,67.5 69.5,67.5 69.6,67.6 69.6,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.5 69.5,67.5 69.6,67.6 69.6,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.5 69.5,67.5 69.6,67.6 69.6,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,67.3 69.6,67.3 69.5,67.4 69.5,67.4 69.5,67.5 69.7,67.4 69.8,67.5 69.8,67.4 69.7,67.3 69.7,67.3 69.7,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,67.3 69.6,67.3 69.5,67.4 69.5,67.4 69.5,67.5 69.7,67.4 69.8,67.5 69.8,67.4 69.7,67.3 69.7,67.3 69.7,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,67.3 69.6,67.3 69.5,67.4 69.5,67.4 69.5,67.5 69.7,67.4 69.8,67.5 69.8,67.4 69.7,67.3 69.7,67.3 69.7,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,67.3 69.6,67.3 69.5,67.4 69.5,67.4 69.5,67.5 69.7,67.4 69.8,67.5 69.8,67.4 69.7,67.3 69.7,67.3 69.7,67.3"
        />
        <polygon
          fill={colors[3]}
          points="69.5,67.5 69.6,67.6 69.6,67.6 69.6,67.6 69.6,67.6 69.7,67.5 69.7,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.5 69.6,67.6 69.6,67.6 69.6,67.6 69.6,67.6 69.7,67.5 69.7,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.5 69.6,67.6 69.6,67.6 69.6,67.6 69.6,67.6 69.7,67.5 69.7,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.5 69.6,67.6 69.6,67.6 69.6,67.6 69.6,67.6 69.7,67.5 69.7,67.4"
        />
        <polygon fill={colors[4]} points="69.7,67.4 69.7,67.5 69.8,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,67.4 69.7,67.5 69.8,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,67.4 69.7,67.5 69.8,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,67.4 69.7,67.5 69.8,67.5"
        />
        <polygon
          fill={colors[4]}
          points="69.8,67.4 69.8,67.4 69.7,67.3 69.7,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,67.4 69.8,67.4 69.7,67.3 69.7,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,67.4 69.8,67.4 69.7,67.3 69.7,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.5 68.6,66.5 68.5,66.5 68.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.5 68.6,66.5 68.5,66.5 68.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.5 68.6,66.5 68.5,66.5 68.5,66.5"
        />
        <polygon fill={colors[4]} points="68.6,66.5 68.5,66.5 68.5,66.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.5 68.5,66.5 68.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.5 68.5,66.5 68.5,66.5"
        />
        <polygon fill="#CCC" points="68.5,66.4 68.5,66.5 68.5,66.5 68.6,66.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,66.4 68.5,66.5 68.5,66.5 68.6,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,66.4 68.5,66.5 68.5,66.5 68.6,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.3 68.7,66.3 68.8,66.3 68.8,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.3 68.7,66.3 68.8,66.3 68.8,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.3 68.7,66.3 68.8,66.3 68.8,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.3 68.7,66.3 68.8,66.3 68.8,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.4 68.8,66.5 68.8,66.5 68.8,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.4 68.8,66.5 68.8,66.5 68.8,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.4 68.8,66.5 68.8,66.5 68.8,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.4 68.8,66.5 68.8,66.5 68.8,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.5 68.6,66.5 68.6,66.5 68.6,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.5 68.6,66.5 68.6,66.5 68.6,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.5 68.6,66.5 68.6,66.5 68.6,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.5 68.6,66.5 68.6,66.5 68.6,66.4"
        />
        <polygon
          fill="#CCC"
          points="68.6,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.6,66.3 68.5,66.4 68.6,66.5 68.6,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.6,66.3 68.5,66.4 68.6,66.5 68.6,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.6,66.3 68.5,66.4 68.6,66.5 68.6,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.7,66.4 68.6,66.3 68.5,66.4 68.6,66.5 68.6,66.5"
        />
        <polygon
          fill={colors[3]}
          points="68.7,66.4 68.6,66.4 68.6,66.5 68.5,66.5 68.6,66.6 68.6,66.6 68.6,66.6 68.7,66.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.4 68.6,66.4 68.6,66.5 68.5,66.5 68.6,66.6 68.6,66.6 68.6,66.6 68.7,66.7"
        />
        <polygon
          fill={colors[4]}
          points="68.7,66.6 69.5,67.2 68.8,66.5 68.8,66.5 68.7,66.4 68.7,66.4 68.7,66.7 68.7,66.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,66.6 69.5,67.2 68.8,66.5 68.8,66.5 68.7,66.4 68.7,66.4 68.7,66.7 68.7,66.7"
        />
        <polygon
          fill="#CCC"
          points="69.5,67.4 69.5,67.4 69.6,67.3 69.6,67.3 69.6,67.3 69.5,67.2 68.7,66.6 68.7,66.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,67.4 69.5,67.4 69.6,67.3 69.6,67.3 69.6,67.3 69.5,67.2 68.7,66.6 68.7,66.7"
        />
        <polygon
          fill={colors[3]}
          points="69.6,67.1 68.8,66.5 68.8,66.5 68.8,66.5 69.6,67.3 69.6,65.7 69.6,65.6"
        />
        <polygon fill={colors[3]} points="69.6,65.6 69.5,65.6 68.8,66.2" />
        <polygon
          fill={colors[4]}
          points="69.6,67.3 69.7,67.3 70.4,66.5 70.4,66.5 69.7,67.1 69.7,65.8 69.6,65.7"
        />
        <polygon fill="#CCC" points="70.4,66.5 69.7,65.8 69.7,67.1" />
        <polygon
          fill={colors[4]}
          points="68.8,66.3 68.7,66.3 68.7,66.3 68.7,66.3 68.7,66.4 68.7,66.4 68.7,66.4 68.8,66.5 69.6,67.1 69.6,65.6 69.6,65.6 68.8,66.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.3 70.6,66.3 71.4,65.5 71.4,65.5 71.4,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.3 70.6,66.3 70.6,66.3 70.7,66.3 70.8,66.3 71.5,65.6 71.4,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.3 70.6,66.3 70.6,66.3 70.7,66.3 70.8,66.3 71.5,65.6 71.4,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.3 70.6,66.3 70.7,66.4 70.7,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.3 70.6,66.3 70.7,66.4 70.7,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.3 70.6,66.3 70.7,66.4 70.7,66.4"
        />
        <polygon
          fill={colors[3]}
          points="71.5,65.6 71.5,65.7 71.6,65.7 71.5,65.6 70.8,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.6 71.5,65.7 71.6,65.7 71.5,65.6 70.8,66.3"
        />
        <polygon
          fill={colors[4]}
          points="70.8,66.3 70.7,66.3 70.7,66.4 70.8,66.5 71.5,65.7 71.5,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,66.3 70.7,66.3 70.7,66.4 70.8,66.5 71.5,65.7 71.5,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,65.3 69.8,65.3 70.5,64.6 70.5,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,65.3 69.8,65.3 70.5,64.6 70.5,64.6"
        />
        <polygon
          fill={colors[3]}
          points="69.7,65.3 69.6,65.3 69.7,65.4 69.7,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,65.3 69.6,65.3 69.7,65.4 69.7,65.4"
        />
        <polygon
          fill={colors[4]}
          points="70,65 69.7,65.3 69.7,65.4 69.7,65.3 70.5,64.6 70.5,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70,65 69.7,65.3 69.7,65.4 69.7,65.3 70.5,64.6 70.5,64.6"
        />
        <polygon fill="#CCC" points="70.4,64.5 70,65 70.5,64.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,64.5 70,65 70.5,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.3 69.8,65.6 69.8,65.6 70.5,66.2 70.6,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.3 69.8,65.6 69.8,65.6 70.5,66.2 70.6,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,66.5 70.5,66.4 70.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,66.5 70.5,66.4 70.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.4 70.6,66.3 70.6,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.4 70.6,66.3 70.6,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.4 70.6,66.3 70.6,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,66.5 70.4,66.5 70.5,66.4 70.5,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,66.5 70.4,66.5 70.5,66.4 70.5,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.4,66.5 70.4,66.5 70.5,66.4 70.5,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.4 70.5,66.4 70.4,66.5 70.5,66.5 70.6,66.4 70.7,66.5 70.7,66.5 70.6,66.4 70.7,66.4 70.6,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.4 70.5,66.4 70.4,66.5 70.5,66.5 70.6,66.4 70.7,66.5 70.7,66.5 70.6,66.4 70.7,66.4 70.6,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.4 70.5,66.4 70.4,66.5 70.5,66.5 70.6,66.4 70.7,66.5 70.7,66.5 70.6,66.4 70.7,66.4 70.6,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.4 70.5,66.4 70.4,66.5 70.5,66.5 70.6,66.4 70.7,66.5 70.7,66.5 70.6,66.4 70.7,66.4 70.6,66.3"
        />
        <polygon
          fill={colors[3]}
          points="70.6,66.6 70.6,66.6 70.6,66.6 70.6,66.6 70.6,66.4 70.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.6 70.6,66.6 70.6,66.6 70.6,66.6 70.6,66.4 70.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.6 70.6,66.6 70.6,66.6 70.6,66.6 70.6,66.4 70.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,66.6 70.6,66.6 70.6,66.6 70.6,66.6 70.6,66.4 70.5,66.5"
        />
        <polygon fill={colors[4]} points="70.7,66.5 70.6,66.4 70.6,66.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.5 70.6,66.4 70.6,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.5 70.6,66.4 70.6,66.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.5 70.6,66.4 70.6,66.6"
        />
        <polygon
          fill={colors[4]}
          points="70.7,66.5 70.8,66.5 70.7,66.4 70.6,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.5 70.8,66.5 70.7,66.4 70.6,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,66.5 70.8,66.5 70.7,66.4 70.6,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,65.6 69.5,65.6 69.5,65.5 69.5,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,65.6 69.5,65.6 69.5,65.5 69.5,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,65.6 69.5,65.6 69.5,65.5 69.5,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,65.6 69.8,65.6 69.8,65.6 69.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,65.6 69.8,65.6 69.8,65.6 69.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,65.6 69.8,65.6 69.8,65.6 69.8,65.5"
        />
        <polygon fill={colors[4]} points="69.5,65.6 69.5,65.5 69.5,65.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,65.6 69.5,65.5 69.5,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,65.6 69.5,65.5 69.5,65.5"
        />
        <polygon fill="#CCC" points="69.5,65.6 69.5,65.5 69.5,65.5 69.4,65.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,65.6 69.5,65.5 69.5,65.5 69.4,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,65.6 69.5,65.5 69.5,65.5 69.4,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.4 69.8,65.6 69.8,65.5 69.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.4 69.8,65.6 69.8,65.5 69.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.4 69.8,65.6 69.8,65.5 69.8,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.4 69.8,65.6 69.8,65.5 69.8,65.5"
        />
        <polygon
          fill="#CCC"
          points="69.6,65.4 69.7,65.4 69.6,65.3 69.5,65.5 69.5,65.5 69.5,65.5 69.6,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.4 69.7,65.4 69.6,65.3 69.5,65.5 69.5,65.5 69.5,65.5 69.6,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.4 69.7,65.4 69.6,65.3 69.5,65.5 69.5,65.5 69.5,65.5 69.6,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.4 69.7,65.4 69.6,65.3 69.5,65.5 69.5,65.5 69.5,65.5 69.6,65.5"
        />
        <polygon fill={colors[3]} points="69.6,65.3 69.7,65.4 69.7,65.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.3 69.7,65.4 69.7,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.3 69.7,65.4 69.7,65.4"
        />
        <polygon
          fill={colors[3]}
          points="69.5,65.5 69.5,65.6 69.6,65.6 69.6,65.6 69.6,65.6 69.6,65.7 69.6,65.4 69.6,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,65.5 69.5,65.6 69.6,65.6 69.6,65.6 69.6,65.6 69.6,65.7 69.6,65.4 69.6,65.5"
        />
        <polygon fill={colors[4]} points="70.5,66.5 70.4,66.5 70.4,66.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,66.5 70.4,66.5 70.4,66.5"
        />
        <polygon
          fill={colors[4]}
          points="69.6,65.4 69.6,65.4 69.6,65.7 69.7,65.8 69.7,65.6 70.5,66.2 69.8,65.6 69.8,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,65.4 69.6,65.4 69.6,65.7 69.7,65.8 69.7,65.6 70.5,66.2 69.8,65.6 69.8,65.6"
        />
        <polygon
          fill="#CCC"
          points="69.7,65.6 69.7,65.8 70.4,66.5 70.5,66.5 70.5,66.4 70.5,66.4 70.6,66.4 70.6,66.4 70.6,66.4 70.5,66.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,65.6 69.7,65.8 70.4,66.5 70.5,66.5 70.5,66.4 70.5,66.4 70.6,66.4 70.6,66.4 70.6,66.4 70.5,66.2"
        />
        <polygon
          fill={colors[3]}
          points="70.6,64.7 70.5,64.6 69.8,65.3 70.5,64.7 70.5,66.1 69.8,65.5 69.8,65.5 69.8,65.6 70.6,66.3"
        />
        <polygon
          fill={colors[4]}
          points="70.6,64.7 70.6,66.3 70.6,66.3 71.4,65.5 70.6,66.1"
        />
        <polygon
          fill="#CCC"
          points="70.6,66.1 71.4,65.5 71.4,65.5 71.3,65.4 71.4,65.4 70.6,64.7"
        />
        <polygon
          fill={colors[4]}
          points="69.7,65.4 69.7,65.4 69.7,65.4 69.7,65.4 69.6,65.4 69.6,65.4 69.8,65.5 70.5,66.1 70.5,64.7 69.8,65.3 69.7,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6,68.4 66.6,68.4 65.8,69.2 65.8,69.2 65.9,69.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6,68.4 66.6,68.4 65.8,69.2 65.8,69.2 65.9,69.1"
        />
        <polygon fill={colors[3]} points="65.9,69.1 66.7,68.5 66.6,68.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,69.1 66.7,68.5 66.6,68.4"
        />
        <polygon fill={colors[3]} points="66.8,68.6 66.7,68.5 66.7,68.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.6 66.7,68.5 66.7,68.6"
        />
        <polygon
          fill={colors[4]}
          points="66,69.4 66.7,68.6 66.7,68.5 66.7,68.5 65.9,69.1 65.8,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,69.4 66.7,68.6 66.7,68.5 66.7,68.5 65.9,69.1 65.8,69.2"
        />
        <polygon
          fill={colors[3]}
          points="66.8,68.5 66.6,68.4 66.6,68.4 66.7,68.5 66.7,68.5 66.7,68.5 66.8,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.5 66.6,68.4 66.6,68.4 66.7,68.5 66.7,68.5 66.7,68.5 66.8,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.5 66.6,68.4 66.6,68.4 66.7,68.5 66.7,68.5 66.7,68.5 66.8,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.5 66,67.5 66.6,68.2 66.7,68.2 66.7,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.5 66,67.5 66.6,68.2 66.7,68.2 66.7,68.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.3 66.9,68.4 66.9,68.4 66.9,68.4 66.8,68.3 66.6,68.4 66.6,68.4 66.6,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.3 66.9,68.4 66.9,68.4 66.9,68.4 66.8,68.3 66.6,68.4 66.6,68.4 66.6,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.3 66.9,68.4 66.9,68.4 66.9,68.4 66.8,68.3 66.6,68.4 66.6,68.4 66.6,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.3 66.9,68.4 66.9,68.4 66.9,68.4 66.8,68.3 66.6,68.4 66.6,68.4 66.6,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,68.2 66.7,68.2 66.7,68.3 66.7,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,68.2 66.7,68.2 66.7,68.3 66.7,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,68.2 66.7,68.2 66.7,68.3 66.7,68.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,68.2 66.7,68.2 66.7,68.3 66.7,68.3"
        />
        <polygon fill={colors[3]} points="66.8,68.3 66.6,68.4 66.8,68.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.3 66.6,68.4 66.8,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.3 66.6,68.4 66.8,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.3 66.6,68.4 66.8,68.5"
        />
        <polygon
          fill={colors[4]}
          points="66.8,68.6 66.8,68.5 66.8,68.5 66.8,68.5 66.9,68.4 66.8,68.3 66.8,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.6 66.8,68.5 66.8,68.5 66.8,68.5 66.9,68.4 66.8,68.3 66.8,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.6 66.8,68.5 66.8,68.5 66.8,68.5 66.9,68.4 66.8,68.3 66.8,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,68.6 66.8,68.5 66.8,68.5 66.8,68.5 66.9,68.4 66.8,68.3 66.8,68.5"
        />
        <polygon
          fill={colors[4]}
          points="67,68.4 66.8,68.2 66.8,68.3 66.8,68.3 66.8,68.3 66.8,68.3 66.8,68.3 66.9,68.4 66.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,68.4 66.8,68.2 66.8,68.3 66.8,68.3 66.8,68.3 66.8,68.3 66.8,68.3 66.9,68.4 66.9,68.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,68.4 66.8,68.2 66.8,68.3 66.8,68.3 66.8,68.3 66.8,68.3 66.8,68.3 66.9,68.4 66.9,68.4"
        />
        <polygon
          fill={colors[3]}
          points="66.7,68.2 66.8,68.3 66.8,68.3 66.8,68.2 66.3,67.7 66,67.5 66,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,68.2 66.8,68.3 66.8,68.3 66.8,68.2 66.3,67.7 66,67.5 66,67.5"
        />
        <polygon fill={colors[4]} points="66,67.4 66,67.5 66.3,67.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.4 66,67.5 66.3,67.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.5 66,67.5 65.9,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.5 66,67.5 65.9,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.5 66,67.5 65.9,67.5"
        />
        <polygon
          fill={colors[4]}
          points="66,67.4 66,67.5 66,67.5 66,67.4 65.9,67.3 65.9,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.4 66,67.5 66,67.5 66,67.4 65.9,67.3 65.9,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.4 66,67.5 66,67.5 66,67.4 65.9,67.3 65.9,67.3"
        />
        <polygon
          fill={colors[3]}
          points="65.8,67.3 65.8,67.3 65.8,67.2 65.8,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,67.3 65.8,67.3 65.8,67.2 65.8,67.2"
        />
        <polygon
          fill={colors[4]}
          points="65.9,67.3 65.9,67.3 65.8,67.3 65.8,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,67.3 65.9,67.3 65.8,67.3 65.8,67.3"
        />
        <polygon
          fill={colors[3]}
          points="65.8,69.2 65.8,67.6 65.8,67.7 65.8,69 65.1,68.5 65.1,68.5"
        />
        <polygon
          fill={colors[4]}
          points="66.6,68.4 66.6,68.4 66.6,68.4 65.9,69 65.9,67.6 65.8,67.6 65.8,69.2 65.8,69.2"
        />
        <polygon
          fill={colors[4]}
          points="66.6,68.2 66,67.5 65.9,67.5 65.9,67.5"
        />
        <polygon
          fill="#CCC"
          points="66.8,68.3 66.8,68.3 66.7,68.2 66.7,68.2 66.6,68.2 65.9,67.5 65.9,67.6 65.9,69 66.6,68.4"
        />
        <polygon
          fill={colors[4]}
          points="65.8,67.7 65.1,68.3 65.1,68.5 65.8,69"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,67.3 65.8,67.3 65.4,66.9 65.4,66.9 65.7,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,67.3 65.8,67.3 65.4,66.9 65.4,66.9 65.7,67.2"
        />
        <polygon
          fill={colors[3]}
          points="65.8,67.2 65.4,66.8 65.4,66.9 65.8,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,67.2 65.4,66.8 65.4,66.9 65.8,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,67.5 65.2,67.9 65.2,68"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,67.5 65.2,67.9 65.2,68"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.5 66,67.4 65.8,67.3 65.8,67.3 65.8,67.3 65.8,67.3 65.8,67.3 65.7,67.4 65.7,67.5 65.7,67.5 65.8,67.3 65.9,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.5 66,67.4 65.8,67.3 65.8,67.3 65.8,67.3 65.8,67.3 65.8,67.3 65.7,67.4 65.7,67.5 65.7,67.5 65.8,67.3 65.9,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.5 66,67.4 65.8,67.3 65.8,67.3 65.8,67.3 65.8,67.3 65.8,67.3 65.7,67.4 65.7,67.5 65.7,67.5 65.8,67.3 65.9,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.5 66,67.4 65.8,67.3 65.8,67.3 65.8,67.3 65.8,67.3 65.8,67.3 65.7,67.4 65.7,67.5 65.7,67.5 65.8,67.3 65.9,67.5"
        />
        <polygon fill={colors[3]} points="66,67.4 66,67.5 66,67.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.4 66,67.5 66,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.4 66,67.5 66,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.4 66,67.5 66,67.5"
        />
        <polygon
          fill={colors[4]}
          points="66,67.4 65.9,67.3 65.8,67.3 65.8,67.3 65.8,67.3 66,67.4 66,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.4 65.9,67.3 65.8,67.3 65.8,67.3 65.8,67.3 66,67.4 66,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.4 65.9,67.3 65.8,67.3 65.8,67.3 65.8,67.3 66,67.4 66,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,67.4 65.9,67.3 65.8,67.3 65.8,67.3 65.8,67.3 66,67.4 66,67.5"
        />
        <polygon
          fill={colors[3]}
          points="65.8,67.3 65.8,67.3 65.8,67.3 65.8,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,67.3 65.8,67.3 65.8,67.3 65.8,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,67.3 65.8,67.3 65.8,67.3 65.8,67.2"
        />
        <polygon fill={colors[4]} points="65.8,67.3 65.8,67.3 65.9,67.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,67.3 65.8,67.3 65.9,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,67.3 65.8,67.3 65.9,67.3"
        />
        <polygon
          fill={colors[3]}
          points="65.8,67.5 65.8,67.7 65.8,67.6 65.8,67.3 65.7,67.5 65.7,67.5 65.2,68 65.2,68"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,67.5 65.8,67.7 65.8,67.6 65.8,67.3 65.7,67.5 65.7,67.5 65.2,68 65.2,68"
        />
        <polygon
          fill={colors[4]}
          points="65.9,67.6 65.9,67.5 65.9,67.5 65.9,67.5 65.8,67.3 65.8,67.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,67.6 65.9,67.5 65.9,67.5 65.9,67.5 65.8,67.3 65.8,67.6"
        />
        <polygon
          fill={colors[4]}
          points="65.8,67.5 65.2,68 65.1,68.3 65.8,67.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,67.5 65.2,68 65.1,68.3 65.8,67.7"
        />
        <polygon fill={colors[4]} points="65.4,66.9 65.4,66.9 65.7,67.2" />
        <polygon
          fill={colors[4]}
          points="65.7,67.5 65.7,67.4 65.2,67.8 65.2,67.9"
        />
        <polygon
          fill="#CCC"
          points="65.7,67.4 65.8,67.3 65.8,67.3 65.8,67.3 65.7,67.2 65.4,66.9 65.2,67.8"
        />
        <polygon fill={colors[3]} points="73.3,65.1 73.3,65 73,64.8" />
        <polygon
          fill={colors[4]}
          points="72.7,64.5 72.7,64.5 73,64.8 73.3,65 73.1,64.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6,67.4 73.7,67.5 73.7,67.5 73.7,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6,67.4 73.7,67.5 73.7,67.5 73.7,67.4"
        />
        <polygon fill="#CCC" points="73.3,67.3 73.3,67.4 73.3,67.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,67.3 73.3,67.4 73.3,67.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,67.3 73.3,67.4 73.3,67.4"
        />
        <polygon
          fill={colors[3]}
          points="73.3,67.4 73.3,67.4 73.4,67.5 73.4,67.5 73.4,67.5 73.4,67.5 73.4,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,67.4 73.3,67.4 73.4,67.5 73.4,67.5 73.4,67.5 73.4,67.5 73.4,67.5"
        />
        <polygon
          fill={colors[4]}
          points="73.6,67.4 73.4,67.5 73.4,67.5 73.4,67.5 73.5,67.6 73.5,67.5 73.7,67.7 73.7,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.6,67.4 73.4,67.5 73.4,67.5 73.4,67.5 73.5,67.6 73.5,67.5 73.7,67.7 73.7,67.5"
        />
        <polygon fill="#CCC" points="73.5,67.5 73.5,67.6 73.8,67.8 73.7,67.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,67.5 73.5,67.6 73.8,67.8 73.7,67.7"
        />
        <polygon
          fill={colors[3]}
          points="73.6,67.4 73.6,67.4 73.7,67.4 73.7,67.5 73.7,67.4"
        />
        <polygon fill={colors[4]} points="73.6,67.4 73.7,67.4 73.7,67.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,67.2 73.4,67.2 73.4,67.2 72.7,66.5 72.7,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,67.2 73.4,67.2 73.4,67.2 72.7,66.5 72.7,66.5"
        />
        <polygon fill={colors[4]} points="73.3,67.2 72.7,66.5 72.6,66.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,67.2 72.7,66.5 72.6,66.6"
        />
        <polygon fill="#CCC" points="72.6,66.6 73.3,67.3 73.4,67.2 73.3,67.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,66.6 73.3,67.3 73.4,67.2 73.3,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,66.5 72.7,66.5 72.6,66.4 72.6,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,66.5 72.7,66.5 72.6,66.4 72.6,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,66.5 72.7,66.5 72.6,66.4 72.6,66.4"
        />
        <polygon
          fill={colors[3]}
          points="72.5,66.6 72.5,66.5 72.5,66.5 72.4,66.5 72.4,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.6 72.5,66.5 72.5,66.5 72.4,66.5 72.4,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.6 72.5,66.5 72.5,66.5 72.4,66.5 72.4,66.5"
        />
        <polygon
          fill={colors[4]}
          points="72.5,66.6 72.6,66.6 72.6,66.6 72.6,66.6 72.7,66.5 72.6,66.4 72.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.6 72.6,66.6 72.6,66.6 72.6,66.6 72.7,66.5 72.6,66.4 72.5,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.6 72.6,66.6 72.6,66.6 72.6,66.6 72.7,66.5 72.6,66.4 72.5,66.5"
        />
        <polygon fill="#CCC" points="72.4,66.5 72.4,66.5 72.3,66.4 72.3,66.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,66.5 72.4,66.5 72.3,66.4 72.3,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,67.2 73.6,67.1 73.6,67.1 73.6,67"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,67.2 73.6,67.1 73.6,67.1 73.6,67"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,67.2 73.5,67.1 73.5,67.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,67.2 73.5,67.1 73.5,67.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,67.2 73.5,67.1 73.5,67.1"
        />
        <polygon fill="#CCC" points="73.3,67.3 73.3,67.3 73.4,67.2 73.4,67.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,67.3 73.3,67.3 73.4,67.2 73.4,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,67.3 73.3,67.3 73.4,67.2 73.4,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,67.3 73.4,67.3 73.6,67.4 73.6,67.4 73.6,67.3 73.4,67.2 73.5,67.2 73.5,67.2 73.4,67.2 73.4,67.2 73.4,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,67.3 73.4,67.3 73.6,67.4 73.6,67.4 73.6,67.3 73.4,67.2 73.5,67.2 73.5,67.2 73.4,67.2 73.4,67.2 73.4,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,67.3 73.4,67.3 73.6,67.4 73.6,67.4 73.6,67.3 73.4,67.2 73.5,67.2 73.5,67.2 73.4,67.2 73.4,67.2 73.4,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,67.3 73.4,67.3 73.6,67.4 73.6,67.4 73.6,67.3 73.4,67.2 73.5,67.2 73.5,67.2 73.4,67.2 73.4,67.2 73.4,67.2"
        />
        <polygon fill="#CCC" points="73.3,67.3 73.3,67.4 73.4,67.3 73.4,67.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,67.3 73.3,67.4 73.4,67.3 73.4,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,67.3 73.3,67.4 73.4,67.3 73.4,67.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,67.3 73.3,67.4 73.4,67.3 73.4,67.2"
        />
        <polygon
          fill={colors[3]}
          points="73.4,67.3 73.4,67.3 73.3,67.4 73.4,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,67.3 73.4,67.3 73.3,67.4 73.4,67.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,67.3 73.4,67.3 73.3,67.4 73.4,67.5"
        />
        <polygon
          fill={colors[4]}
          points="73.4,67.5 73.4,67.5 73.6,67.4 73.4,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,67.5 73.4,67.5 73.6,67.4 73.4,67.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.4,67.5 73.4,67.5 73.6,67.4 73.4,67.3"
        />
        <polygon
          fill={colors[4]}
          points="73.5,67.2 73.5,67.2 73.4,67.2 73.6,67.3 73.6,67.4 73.7,67.3 73.6,67.1 73.6,67.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.5,67.2 73.5,67.2 73.4,67.2 73.6,67.3 73.6,67.4 73.7,67.3 73.6,67.1 73.6,67.1"
        />
        <polygon
          fill={colors[3]}
          points="73.4,67 72.6,66.4 72.6,66.4 72.7,66.5 73.4,67.2 73.4,67.2 73.4,67.2 73.4,66 73.4,65.7 73.4,65.7"
        />
        <polygon
          fill={colors[4]}
          points="73.4,67.2 73.5,67.2 73.5,67.2 73.6,67 73.6,66.9 73.5,67 73.5,66.3 73.4,66"
        />
        <polygon fill="#CCC" points="73.6,66.9 73.5,66.3 73.5,67" />
        <polygon fill={colors[4]} points="73.4,67 73.4,65.7 72.6,66.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,65.5 71.7,65.5 71.7,65.6 72.4,66.3 72.5,66.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,65.5 71.7,65.5 71.7,65.6 72.4,66.3 72.5,66.2"
        />
        <polygon
          fill={colors[4]}
          points="71.6,65.7 71.6,65.7 71.6,65.7 71.6,65.6 72.4,66.3 72.4,66.3 71.7,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.7 71.6,65.7 71.6,65.7 71.6,65.6 72.4,66.3 72.4,66.3 71.7,65.6"
        />
        <polygon fill="#CCC" points="72.4,66.3 71.6,65.6 71.6,65.7 72.3,66.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,66.3 71.6,65.6 71.6,65.7 72.3,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.6 71.5,65.5 71.4,65.5 71.4,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.6 71.5,65.5 71.4,65.5 71.4,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.6 71.5,65.5 71.4,65.5 71.4,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,65.6 71.7,65.5 71.7,65.5 71.7,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,65.6 71.7,65.5 71.7,65.5 71.7,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,65.6 71.7,65.5 71.7,65.5 71.7,65.5"
        />
        <polygon
          fill={colors[3]}
          points="71.6,65.6 71.5,65.5 71.5,65.6 71.6,65.7 71.6,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.6 71.5,65.5 71.5,65.6 71.6,65.7 71.6,65.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.6 71.5,65.5 71.5,65.6 71.6,65.7 71.6,65.7"
        />
        <polygon
          fill={colors[4]}
          points="71.6,65.6 71.6,65.7 71.7,65.6 71.7,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.6 71.6,65.7 71.7,65.6 71.7,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.6 71.6,65.7 71.7,65.6 71.7,65.5"
        />
        <polygon fill="#CCC" points="71.4,65.5 71.4,65.5 71.4,65.4 71.3,65.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,65.5 71.4,65.5 71.4,65.4 71.3,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,64.6 73.3,65.2 73.3,65.2 73.3,65.2 72.7,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,64.6 73.3,65.2 73.3,65.2 73.3,65.2 72.7,64.5"
        />
        <polygon
          fill={colors[3]}
          points="72.7,64.5 72.7,64.5 73.3,65.2 73.3,65.1 73,64.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,64.5 72.7,64.5 73.3,65.2 73.3,65.1 73,64.8"
        />
        <polygon fill={colors[4]} points="73,64.8 72.7,64.5 72.7,64.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73,64.8 72.7,64.5 72.7,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.3 72.5,66.3 72.6,66.2 73.3,65.5 73.3,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.3 72.5,66.3 72.6,66.2 73.3,65.5 73.3,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.3 72.5,66.2 72.4,66.3 72.4,66.3 72.5,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.3 72.5,66.2 72.4,66.3 72.4,66.3 72.5,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.3 72.5,66.2 72.4,66.3 72.4,66.3 72.5,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,66.4 72.5,66.4 72.6,66.4 72.6,66.4 72.6,66.4 72.5,66.3 72.5,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,66.4 72.5,66.4 72.6,66.4 72.6,66.4 72.6,66.4 72.5,66.3 72.5,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,66.4 72.5,66.4 72.6,66.4 72.6,66.4 72.6,66.4 72.5,66.3 72.5,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,66.4 72.5,66.4 72.6,66.4 72.6,66.4 72.6,66.4 72.5,66.3 72.5,66.3"
        />
        <polygon fill={colors[3]} points="72.5,66.5 72.5,66.4 72.4,66.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.5 72.5,66.4 72.4,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.5 72.5,66.4 72.4,66.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.5 72.5,66.4 72.4,66.5"
        />
        <polygon
          fill={colors[4]}
          points="72.5,66.5 72.6,66.4 72.5,66.4 72.5,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.5 72.6,66.4 72.5,66.4 72.5,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.5 72.6,66.4 72.5,66.4 72.5,66.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,66.5 72.6,66.4 72.5,66.4 72.5,66.4"
        />
        <polygon
          fill="#CCC"
          points="72.3,66.4 72.4,66.5 72.5,66.4 72.5,66.4 72.6,66.4 72.5,66.3 72.4,66.3 72.4,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,66.4 72.4,66.5 72.5,66.4 72.5,66.4 72.6,66.4 72.5,66.3 72.4,66.3 72.4,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,66.4 72.4,66.5 72.5,66.4 72.5,66.4 72.6,66.4 72.5,66.3 72.4,66.3 72.4,66.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,65.5 73.3,65.5 73.3,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,65.5 73.3,65.5 73.3,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,65.5 73.3,65.5 73.3,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,65.4 73.3,65.5 73.4,65.4 73.4,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,65.4 73.3,65.5 73.4,65.4 73.4,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,65.4 73.3,65.5 73.4,65.4 73.4,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,65.4 73.3,65.5 73.4,65.4 73.4,65.4"
        />
        <polygon
          fill={colors[3]}
          points="73.3,65.5 72.6,66.2 73.4,65.5 73.4,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.3,65.5 72.6,66.2 73.4,65.5 73.4,65.4"
        />
        <polygon
          fill={colors[4]}
          points="72.6,66.2 72.5,66.3 72.5,66.3 72.5,66.3 72.6,66.4 72.6,66.4 73.4,65.7 73.4,65.6 73.4,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,66.2 72.5,66.3 72.5,66.3 72.5,66.3 72.6,66.4 72.6,66.4 73.4,65.7 73.4,65.6 73.4,65.5"
        />
        <polygon
          fill={colors[3]}
          points="72.4,66.1 71.7,65.5 71.7,65.5 72.5,66.2 72.5,66.3 72.5,64.7 72.4,64.8"
        />
        <polygon
          fill={colors[4]}
          points="72.5,64.7 72.5,66.3 72.5,66.3 73.3,65.5 73.3,65.4 73.3,65.4 72.6,66.1 72.6,64.7"
        />
        <polygon fill={colors[4]} points="73.3,65.2 72.7,64.6 72.6,64.6" />
        <polygon
          fill="#CCC"
          points="72.6,64.6 72.6,64.7 72.6,66.1 73.3,65.4 73.4,65.4 73.3,65.2 73.3,65.2"
        />
        <polygon fill={colors[4]} points="72.4,66.1 72.4,64.8 71.7,65.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,64.6 70.7,64.6 71.5,65.3 71.5,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,64.6 70.8,64.6 70.8,64.5 70.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,64.6 70.8,64.6 70.8,64.5 70.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,64.6 71.4,65.3 71.5,65.3 71.5,65.3 70.8,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,64.6 71.4,65.3 71.5,65.3 71.5,65.3 70.8,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,64.6 70.8,64.6 70.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,64.6 70.8,64.6 70.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,64.6 70.8,64.6 70.8,64.5"
        />
        <polygon fill="#CCC" points="70.5,64.6 70.5,64.5 70.5,64.5 70.4,64.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.6 70.5,64.5 70.5,64.5 70.4,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.6 70.5,64.5 70.5,64.5 70.4,64.5"
        />
        <polygon fill={colors[3]} points="70.5,64.6 70.6,64.7 70.6,64.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.6 70.6,64.7 70.6,64.6"
        />
        <polygon
          fill={colors[4]}
          points="70.6,64.7 71.4,65.3 70.7,64.6 70.7,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.7 71.4,65.3 70.7,64.6 70.7,64.6"
        />
        <polygon
          fill={colors[4]}
          points="70.6,64.6 70.6,64.7 70.6,64.7 70.6,64.7 70.6,64.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.6 70.6,64.7 70.6,64.7 70.6,64.7 70.6,64.7"
        />
        <polygon
          fill="#CCC"
          points="70.6,64.7 70.6,64.7 70.6,64.7 71.4,65.4 71.5,65.3 71.4,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.7 70.6,64.7 70.6,64.7 71.4,65.4 71.5,65.3 71.4,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.3 71.5,65.3 71.6,65.4 71.6,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.3 71.5,65.3 71.6,65.4 71.6,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.4 71.6,65.4 71.7,65.5 71.7,65.5 71.7,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.4 71.6,65.4 71.7,65.5 71.7,65.5 71.7,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.4 71.6,65.4 71.7,65.5 71.7,65.5 71.7,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.3 71.5,65.4 71.6,65.4 71.6,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.3 71.5,65.4 71.6,65.4 71.6,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.3 71.5,65.4 71.6,65.4 71.6,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.4 71.5,65.4 71.5,65.5 71.4,65.5 71.5,65.5 71.6,65.4 71.7,65.5 71.7,65.5 71.6,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.4 71.5,65.4 71.5,65.5 71.4,65.5 71.5,65.5 71.6,65.4 71.7,65.5 71.7,65.5 71.6,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.4 71.5,65.4 71.5,65.5 71.4,65.5 71.5,65.5 71.6,65.4 71.7,65.5 71.7,65.5 71.6,65.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,65.4 71.5,65.4 71.5,65.5 71.4,65.5 71.5,65.5 71.6,65.4 71.7,65.5 71.7,65.5 71.6,65.4"
        />
        <polygon fill={colors[3]} points="71.6,65.6 71.6,65.4 71.5,65.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.6 71.6,65.4 71.5,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.6 71.6,65.4 71.5,65.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.6 71.6,65.4 71.5,65.5"
        />
        <polygon fill={colors[4]} points="71.7,65.5 71.6,65.4 71.6,65.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,65.5 71.6,65.4 71.6,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,65.5 71.6,65.4 71.6,65.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,65.5 71.6,65.4 71.6,65.6"
        />
        <polygon
          fill="#CCC"
          points="71.4,65.4 71.4,65.5 71.5,65.5 71.5,65.4 71.5,65.4 71.5,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,65.4 71.4,65.5 71.5,65.5 71.5,65.4 71.5,65.4 71.5,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.4,65.4 71.4,65.5 71.5,65.5 71.5,65.4 71.5,65.4 71.5,65.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,64.6 72.7,64.6 72.7,64.5 72.7,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,64.6 72.7,64.6 72.7,64.5 72.7,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,64.6 72.7,64.6 72.7,64.5 72.7,64.5"
        />
        <polygon fill={colors[3]} points="71.7,65.4 71.7,65.5 71.7,65.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,65.4 71.7,65.5 71.7,65.5"
        />
        <polygon
          fill={colors[3]}
          points="72,65 72.4,64.6 72.4,64.8 72.5,64.7 72.5,64.6 72.4,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72,65 72.4,64.6 72.4,64.8 72.5,64.7 72.5,64.6 72.4,64.5"
        />
        <polygon
          fill={colors[4]}
          points="72.5,64.7 72.5,64.6 72.5,64.7 72.6,64.7 72.6,64.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.7 72.5,64.6 72.5,64.7 72.6,64.7 72.6,64.7"
        />
        <polygon
          fill={colors[4]}
          points="72.6,64.6 72.6,64.6 72.7,64.6 72.7,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,64.6 72.6,64.6 72.7,64.6 72.7,64.6"
        />
        <polygon fill="#CCC" points="72.6,64.7 72.6,64.7 72.6,64.6 72.6,64.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,64.7 72.6,64.7 72.6,64.6 72.6,64.6"
        />
        <polygon
          fill={colors[4]}
          points="71.6,65.3 71.5,65.3 71.5,65.3 71.5,65.3 71.6,65.3 71.6,65.4 71.7,65.4 71.7,65.5 72.4,64.8 72.4,64.6 72,65"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,65.3 71.5,65.3 71.5,65.3 71.5,65.3 71.6,65.3 71.6,65.4 71.7,65.4 71.7,65.5 72.4,64.8 72.4,64.6 72,65"
        />
        <polygon
          fill={colors[3]}
          points="71.5,65.2 70.8,64.5 70.8,64.5 70.8,64.6 71.5,65.3 71.5,65.3 71.6,65.3 71.6,65.3 71.6,63.8 71.5,63.8"
        />
        <polygon
          fill={colors[4]}
          points="71.6,63.8 71.6,65.3 72,65 72.4,64.5 72.4,64.5 71.6,65.2 71.6,63.8 71.6,63.7"
        />
        <polygon fill="#CCC" points="71.6,65.2 72.4,64.5 71.6,63.8" />
        <polygon fill={colors[4]} points="70.8,64.5 71.5,65.2 71.5,63.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,64.4 72.7,64.3 73.1,63.9 73.1,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,64.4 72.7,64.3 73.1,63.9 73.1,63.8"
        />
        <polygon fill={colors[3]} points="73.1,63.9 73.1,63.9 72.7,64.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="73.1,63.9 73.1,63.9 72.7,64.3"
        />
        <polygon
          fill={colors[4]}
          points="72.6,64.4 72.7,64.5 73.1,64.1 73.1,63.9 72.7,64.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,64.4 72.7,64.5 73.1,64.1 73.1,63.9 72.7,64.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,64.3 72.4,64.3 72.5,64.3 71.7,63.6 71.7,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,64.3 72.4,64.3 72.5,64.3 71.7,63.6 71.7,63.6"
        />
        <polygon
          fill={colors[4]}
          points="72.7,64.5 72.7,64.5 72.6,64.4 72.6,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,64.5 72.7,64.5 72.6,64.4 72.6,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,64.5 72.7,64.5 72.6,64.4 72.6,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.3 72.5,64.3 72.5,64.4 72.5,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.3 72.5,64.3 72.5,64.4 72.5,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.3 72.5,64.3 72.5,64.4 72.5,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.5,64.4 72.7,64.6 72.7,64.5 72.7,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.5,64.4 72.7,64.6 72.7,64.5 72.7,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.5,64.4 72.7,64.6 72.7,64.5 72.7,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.5,64.4 72.7,64.6 72.7,64.5 72.7,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.5,64.4 72.6,64.4 72.6,64.4 72.5,64.4 72.5,64.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.5,64.4 72.6,64.4 72.6,64.4 72.5,64.4 72.5,64.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.5,64.4 72.6,64.4 72.6,64.4 72.5,64.4 72.5,64.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.5,64.4 72.6,64.4 72.6,64.4 72.5,64.4 72.5,64.3"
        />
        <polygon
          fill={colors[4]}
          points="72.5,64.4 72.7,64.5 72.7,64.5 72.7,64.5 72.6,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.7,64.5 72.7,64.5 72.7,64.5 72.6,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.7,64.5 72.7,64.5 72.7,64.5 72.6,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.7,64.5 72.7,64.5 72.7,64.5 72.6,64.4"
        />
        <polygon
          fill={colors[3]}
          points="72.4,64.5 72.4,64.5 72.5,64.6 72.5,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,64.5 72.4,64.5 72.5,64.6 72.5,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,64.5 72.4,64.5 72.5,64.6 72.5,64.4"
        />
        <polygon
          fill={colors[4]}
          points="72.5,64.4 72.5,64.6 72.5,64.7 72.6,64.7 72.6,64.6 72.6,64.6 72.7,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.5,64.6 72.5,64.7 72.6,64.7 72.6,64.6 72.6,64.6 72.7,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,64.4 72.5,64.6 72.5,64.7 72.6,64.7 72.6,64.6 72.6,64.6 72.7,64.6"
        />
        <polygon fill="#CCC" points="72.6,64.7 72.6,64.6 72.6,64.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,64.7 72.6,64.6 72.6,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,64.7 72.6,64.6 72.6,64.6"
        />
        <polygon fill={colors[4]} points="72.4,64.3 71.7,63.6 71.7,63.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,64.3 71.7,63.6 71.7,63.7"
        />
        <polygon fill={colors[4]} points="71.6,63.7 71.6,63.7 71.6,63.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.7 71.6,63.7 71.6,63.8"
        />
        <polygon
          fill="#CCC"
          points="72.4,64.3 71.7,63.7 71.6,63.7 71.6,63.8 72.4,64.5 72.4,64.5 72.5,64.4 72.5,64.4 72.5,64.4 72.5,64.4 72.5,64.4 72.5,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,64.3 71.7,63.7 71.6,63.7 71.6,63.8 72.4,64.5 72.4,64.5 72.5,64.4 72.5,64.4 72.5,64.4 72.5,64.4 72.5,64.4 72.5,64.4"
        />
        <polygon
          fill={colors[3]}
          points="72.5,64.2 71.7,63.6 71.7,63.6 72.5,64.3 72.5,64.3 72.5,64.4 72.5,62.8 72.5,62.9"
        />
        <polygon
          fill={colors[4]}
          points="72.6,64.2 72.6,62.8 72.6,62.8 72.5,62.8 72.5,64.4 72.6,64.4 72.6,64.4 73.1,63.8 73.1,63.8"
        />
        <polygon fill="#CCC" points="72.6,64.2 73.1,63.8 73,63.2 72.6,62.8" />
        <polygon fill={colors[4]} points="71.7,63.6 72.5,64.2 72.5,62.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,63.3 72.4,62.7 72.4,62.6 71.7,63.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,63.3 72.4,62.7 72.4,62.6 71.7,63.4"
        />
        <polygon fill={colors[4]} points="71.7,63.7 71.7,63.6 71.7,63.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,63.7 71.7,63.6 71.7,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,63.7 71.7,63.6 71.7,63.6"
        />
        <polygon fill="#CCC" points="71.6,63.7 71.6,63.7 71.7,63.7 71.7,63.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.7 71.6,63.7 71.7,63.7 71.7,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.7 71.6,63.7 71.7,63.7 71.7,63.7"
        />
        <polygon fill={colors[3]} points="72.5,62.7 72.4,62.7 71.7,63.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.7 72.4,62.7 71.7,63.3"
        />
        <polygon
          fill={colors[3]}
          points="72.5,62.7 72.5,62.9 72.5,62.8 72.5,62.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.7 72.5,62.9 72.5,62.8 72.5,62.8"
        />
        <polygon
          fill={colors[4]}
          points="71.7,63.3 71.7,63.4 71.6,63.4 71.8,63.6 71.7,63.6 71.7,63.6 72.5,62.9 72.5,62.7 72.5,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,63.3 71.7,63.4 71.6,63.4 71.8,63.6 71.7,63.6 71.7,63.6 72.5,62.9 72.5,62.7 72.5,62.7"
        />
        <polygon
          fill={colors[3]}
          points="71.5,63.3 71.1,62.9 71.6,63.4 71.6,61.9 71.5,61.9"
        />
        <polygon
          fill={colors[4]}
          points="71.6,61.9 71.6,63.4 71.6,63.4 71.7,63.4 72.4,62.6 72.4,62.6 71.7,63.3 71.7,61.9"
        />
        <polygon
          fill="#CCC"
          points="71.7,61.9 71.7,63.3 72.4,62.6 72.4,62.6 72.4,62.6"
        />
        <polygon
          fill={colors[4]}
          points="71.1,62.9 71.5,63.3 71.5,61.9 70.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,62.4 72.5,62.5 72.5,62.4 71.8,61.7 71.8,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,62.4 72.5,62.5 72.5,62.4 71.8,61.7 71.8,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.5 72.5,62.4 72.5,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.5 72.5,62.4 72.5,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.5 72.5,62.4 72.5,62.4"
        />
        <polygon fill={colors[4]} points="72.4,62.4 71.8,61.7 71.7,61.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,62.4 71.8,61.7 71.7,61.8"
        />
        <polygon
          fill={colors[4]}
          points="71.6,61.8 71.6,61.9 71.7,61.9 71.7,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.8 71.6,61.9 71.7,61.9 71.7,61.8"
        />
        <polygon
          fill="#CCC"
          points="72.4,62.4 71.7,61.8 71.7,61.8 71.7,61.9 72.4,62.6 72.5,62.5 72.5,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,62.4 71.7,61.8 71.7,61.8 71.7,61.9 72.4,62.6 72.5,62.5 72.5,62.5"
        />
        <polygon
          fill={colors[3]}
          points="72.5,62.3 71.8,61.7 71.8,61.7 72.5,62.4 72.5,62.4 72.6,62.4 72.6,61 72.5,61"
        />
        <polygon
          fill={colors[4]}
          points="72.6,62.4 72.8,62.2 72.8,62.1 72.6,62.3 72.6,61.1 72.6,60.9 72.6,61"
        />
        <polygon fill="#CCC" points="72.8,62.1 72.6,61.1 72.6,62.3" />
        <polygon fill={colors[4]} points="71.8,61.7 72.5,62.3 72.5,61" />
        <polygon
          fill={colors[4]}
          points="71.6,61.8 71.6,61.8 71.7,61.8 71.7,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.8 71.6,61.8 71.7,61.8 71.7,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.8 71.6,61.8 71.7,61.8 71.7,61.8"
        />
        <polygon fill={colors[4]} points="71.7,61.8 71.8,61.7 71.8,61.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,61.8 71.8,61.7 71.8,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,61.8 71.8,61.7 71.8,61.7"
        />
        <polygon
          fill={colors[3]}
          points="72.5,61 72.6,61 72.6,60.9 72.5,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,61 72.6,61 72.6,60.9 72.5,60.8"
        />
        <polygon fill={colors[3]} points="72.5,60.8 72.5,60.8 71.9,61.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,60.8 72.5,60.8 71.9,61.3"
        />
        <polygon
          fill={colors[4]}
          points="72.6,61 72.6,60.9 72.6,60.9 72.6,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,61 72.6,60.9 72.6,60.9 72.6,60.9"
        />
        <polygon
          fill={colors[4]}
          points="71.7,61.5 71.8,61.7 71.8,61.7 71.8,61.7 72.5,61 72.5,60.8 72.5,60.8 71.9,61.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,61.5 71.8,61.7 71.8,61.7 71.8,61.7 72.5,61 72.5,60.8 72.5,60.8 71.9,61.3"
        />
        <polygon
          fill={colors[3]}
          points="71.6,61.4 71.1,60.9 71.6,61.5 71.6,61.5 71.6,60 71.6,60.1"
        />
        <polygon
          fill={colors[4]}
          points="71.6,60 71.6,61.5 71.7,61.5 71.9,61.3 72.5,60.8 72.5,60.7 71.7,61.4 71.7,60 71.7,60"
        />
        <polygon
          fill="#CCC"
          points="71.7,61.4 72.5,60.7 72.4,60.7 72.4,60.7 71.7,60"
        />
        <polygon
          fill={colors[4]}
          points="71.1,60.9 71.6,61.4 71.6,60.1 70.9,60.8"
        />
        <polygon fill={colors[3]} points="71.6,59.9 71.5,59.8 71,60.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.9 71.5,59.8 71,60.4"
        />
        <polygon
          fill={colors[3]}
          points="71.6,60.1 71.6,60 71.6,59.9 71.6,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,60.1 71.6,60 71.6,59.9 71.6,59.9"
        />
        <polygon fill={colors[4]} points="71.7,60 71.6,59.9 71.6,60" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,60 71.6,59.9 71.6,60"
        />
        <polygon
          fill={colors[4]}
          points="70.7,60.6 70.9,60.7 70.9,60.8 71.6,60.1 71.6,59.9 71.6,59.9 71,60.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.6 70.9,60.7 70.9,60.8 71.6,60.1 71.6,59.9 71.6,59.9 71,60.4"
        />
        <polygon
          fill={colors[3]}
          points="70.1,60 70.7,60.5 70.7,60.6 70.7,59.3 70.7,59.4 70.7,60.4"
        />
        <polygon
          fill={colors[4]}
          points="70.7,60.6 70.7,60.6 71,60.4 71.5,59.8 71.5,59.8 70.8,60.4 70.8,59.2 70.7,59.3"
        />
        <polygon
          fill="#CCC"
          points="70.8,59.2 70.8,60.4 71.5,59.8 71.5,59.8 71.5,59.8 70.9,59.2"
        />
        <polygon
          fill={colors[4]}
          points="70.1,60 70.7,60.4 70.7,59.4 70.1,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.8,59.9 72.5,60.5 72.5,60.6 72.5,60.5 72.5,60.5 71.8,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.8,59.9 72.5,60.5 72.5,60.6 72.5,60.5 72.5,60.5 71.8,59.8"
        />
        <polygon fill={colors[4]} points="72.5,60.5 71.8,59.9 71.8,59.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,60.5 71.8,59.9 71.8,59.9"
        />
        <polygon
          fill="#CCC"
          points="71.7,60 71.7,60 72.4,60.7 72.5,60.6 72.5,60.5 71.8,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,60 71.7,60 72.4,60.7 72.5,60.6 72.5,60.5 71.8,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.8,59.9 71.8,59.8 71.8,59.8 71.8,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.8,59.9 71.8,59.8 71.8,59.8 71.8,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.8,59.9 71.8,59.8 71.8,59.8 71.8,59.8"
        />
        <polygon
          fill={colors[3]}
          points="71.5,59.8 71.6,59.9 71.6,59.9 71.6,59.9 71.6,59.9 71.6,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,59.8 71.6,59.9 71.6,59.9 71.6,59.9 71.6,59.9 71.6,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,59.8 71.6,59.9 71.6,59.9 71.6,59.9 71.6,59.9 71.6,59.9"
        />
        <polygon fill={colors[4]} points="71.7,60 71.6,59.9 71.6,59.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,60 71.6,59.9 71.6,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,60 71.6,59.9 71.6,59.9"
        />
        <polygon
          fill={colors[4]}
          points="71.7,59.9 71.8,59.9 71.8,59.9 71.8,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,59.9 71.8,59.9 71.8,59.9 71.8,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,59.9 71.8,59.9 71.8,59.9 71.8,59.8"
        />
        <polygon fill="#CCC" points="71.7,60 71.8,59.9 71.7,59.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,60 71.8,59.9 71.7,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,60 71.8,59.9 71.7,59.9"
        />
        <polygon
          fill={colors[3]}
          points="71.8,59.8 71.8,59.8 71.8,59.8 72.5,60.5 72.5,60.4"
        />
        <polygon fill={colors[4]} points="71.8,59.8 72.5,60.4 72.3,59.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.6 71.6,59.6 71.6,59.6 71,59 71,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.6 71.6,59.6 71.6,59.6 71,59 71,59"
        />
        <polygon fill={colors[4]} points="71.6,59.6 71,59 70.9,59.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.6 71,59 70.9,59.1"
        />
        <polygon
          fill="#CCC"
          points="70.9,59.2 71.5,59.8 71.6,59.6 71.6,59.6 70.9,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,59.2 71.5,59.8 71.6,59.6 71.6,59.6 70.9,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.6 71.7,59.7 71.7,59.7 71.8,59.8 71.8,59.8 71.8,59.8 71.6,59.7 71.7,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.6 71.7,59.7 71.7,59.7 71.8,59.8 71.8,59.8 71.8,59.8 71.6,59.7 71.7,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.6 71.7,59.7 71.7,59.7 71.8,59.8 71.8,59.8 71.8,59.8 71.6,59.7 71.7,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.6 71.7,59.7 71.7,59.7 71.8,59.8 71.8,59.8 71.8,59.8 71.6,59.7 71.7,59.7"
        />
        <polygon
          fill={colors[3]}
          points="71.6,59.9 71.6,59.7 71.6,59.8 71.5,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.9 71.6,59.7 71.6,59.8 71.5,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.9 71.6,59.7 71.6,59.8 71.5,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.9 71.6,59.7 71.6,59.8 71.5,59.8"
        />
        <polygon
          fill={colors[4]}
          points="71.6,59.9 71.7,60 71.7,60 71.7,59.9 71.7,59.9 71.8,59.8 71.7,59.7 71.6,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.9 71.7,60 71.7,60 71.7,59.9 71.7,59.9 71.8,59.8 71.7,59.7 71.6,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.9 71.7,60 71.7,60 71.7,59.9 71.7,59.9 71.8,59.8 71.7,59.7 71.6,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.9 71.7,60 71.7,60 71.7,59.9 71.7,59.9 71.8,59.8 71.7,59.7 71.6,59.7"
        />
        <polygon fill="#CCC" points="71.7,60 71.7,59.9 71.7,59.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,60 71.7,59.9 71.7,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,60 71.7,59.9 71.7,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,60 71.7,59.9 71.7,59.9"
        />
        <polygon fill={colors[4]} points="71.6,59.8 71.5,59.8 71.5,59.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.8 71.5,59.8 71.5,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,59.8 71.5,59.8 71.5,59.8"
        />
        <polygon
          fill="#CCC"
          points="71.5,59.8 71.5,59.8 71.6,59.8 71.6,59.7 71.7,59.7 71.7,59.7 71.6,59.6 71.6,59.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,59.8 71.5,59.8 71.6,59.8 71.6,59.7 71.7,59.7 71.7,59.7 71.6,59.6 71.6,59.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,59.8 71.5,59.8 71.6,59.8 71.6,59.7 71.7,59.7 71.7,59.7 71.6,59.6 71.6,59.6"
        />
        <polygon fill={colors[3]} points="72.3,59.1 72.3,59.1 72,59.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.3,59.1 72.3,59.1 72,59.4"
        />
        <polygon
          fill={colors[4]}
          points="71.7,59.7 71.7,59.7 71.6,59.7 71.8,59.8 71.8,59.8 72.3,59.4 72.3,59.1 72,59.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.7,59.7 71.7,59.7 71.6,59.7 71.8,59.8 71.8,59.8 72.3,59.4 72.3,59.1 72,59.4"
        />
        <polygon
          fill={colors[4]}
          points="71,59 71,59 71,59 71.6,59.5 71.6,58.4 71.6,58.4"
        />
        <polygon
          fill={colors[3]}
          points="72.5,60.8 72.5,60.8 72.5,60.8 72.6,60.9 72.6,60.8 72.5,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,60.8 72.5,60.8 72.5,60.8 72.6,60.9 72.6,60.8 72.5,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,60.8 72.5,60.8 72.5,60.8 72.6,60.9 72.6,60.8 72.5,60.7"
        />
        <polygon
          fill={colors[4]}
          points="72.6,60.9 72.6,60.9 72.6,60.9 72.6,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,60.9 72.6,60.9 72.6,60.9 72.6,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,60.9 72.6,60.9 72.6,60.9 72.6,60.8"
        />
        <polygon
          fill={colors[7]}
          points="72.5,60.8 72.5,60.7 72.5,60.7 72.5,60.7"
        />
        <polygon
          fill={colors[7]}
          points="72.5,60.8 72.5,60.7 72.5,60.7 72.5,60.7"
        />
        <polygon fill="#CCC" points="72.5,60.7 72.5,60.7 72.4,60.7 72.4,60.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,60.7 72.5,60.7 72.4,60.7 72.4,60.7"
        />
        <polygon
          fill={colors[7]}
          points="72.5,60.6 72.5,60.5 72.5,60.5 72.5,60.5"
        />
        <polygon
          fill={colors[7]}
          points="72.5,60.6 72.5,60.5 72.5,60.5 72.5,60.5"
        />
        <polygon
          fill={colors[7]}
          points="72.5,60.6 72.5,60.5 72.5,60.5 72.5,60.5"
        />
        <polygon fill={colors[3]} points="72.5,60.7 72.6,60.8 72.6,60.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,60.7 72.6,60.8 72.6,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,60.7 72.6,60.8 72.6,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,60.7 72.6,60.8 72.6,60.8"
        />
        <polygon fill={colors[4]} points="72.6,60.9 72.6,60.8 72.6,60.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,60.9 72.6,60.8 72.6,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,60.9 72.6,60.8 72.6,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,60.9 72.6,60.8 72.6,60.8"
        />
        <polygon
          fill="#CCC"
          points="72.5,60.6 72.4,60.7 72.5,60.7 72.5,60.7 72.5,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,60.6 72.4,60.7 72.5,60.7 72.5,60.7 72.5,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,60.6 72.4,60.7 72.5,60.7 72.5,60.7 72.5,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,62.4 70.8,62.4 71.5,61.7 71.5,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,62.4 70.8,62.4 71.5,61.7 71.5,61.7"
        />
        <polygon
          fill={colors[3]}
          points="71.5,61.8 71.5,61.9 71.6,61.9 71.6,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,61.8 71.5,61.9 71.6,61.9 71.6,61.8"
        />
        <polygon fill={colors[3]} points="71.5,61.8 71.5,61.7 70.8,62.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,61.8 71.5,61.7 70.8,62.4"
        />
        <polygon fill={colors[4]} points="71.6,61.9 71.6,61.9 71.6,61.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.9 71.6,61.9 71.6,61.8"
        />
        <polygon
          fill={colors[4]}
          points="70.7,62.4 70.7,62.5 70.8,62.6 71.5,61.9 71.5,61.8 71.5,61.8 70.8,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,62.4 70.7,62.5 70.8,62.6 71.5,61.9 71.5,61.8 71.5,61.8 70.8,62.4"
        />
        <polygon
          fill={colors[3]}
          points="71.5,61.7 71.5,61.8 71.5,61.8 71.5,61.8 71.6,61.8 71.6,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,61.7 71.5,61.8 71.5,61.8 71.5,61.8 71.6,61.8 71.6,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,61.7 71.5,61.8 71.5,61.8 71.5,61.8 71.6,61.8 71.6,61.8"
        />
        <polygon
          fill={colors[4]}
          points="71.6,61.9 71.6,61.8 71.6,61.8 71.6,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.9 71.6,61.8 71.6,61.8 71.6,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.9 71.6,61.8 71.6,61.8 71.6,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,61.5 71.6,61.5 71.6,61.5 70.9,60.8 70.8,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,61.5 71.6,61.5 71.6,61.5 70.9,60.8 70.8,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.6 71.6,61.6 71.8,61.7 71.8,61.7 71.7,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.6 71.6,61.6 71.8,61.7 71.8,61.7 71.7,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.6 71.6,61.6 71.8,61.7 71.8,61.7 71.7,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.6 71.6,61.6 71.8,61.7 71.8,61.7 71.7,61.7"
        />
        <polygon
          fill={colors[3]}
          points="71.5,61.7 71.6,61.8 71.6,61.6 71.5,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,61.7 71.6,61.8 71.6,61.6 71.5,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,61.7 71.6,61.8 71.6,61.6 71.5,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,61.7 71.6,61.8 71.6,61.6 71.5,61.6"
        />
        <polygon
          fill={colors[4]}
          points="71.6,61.6 71.6,61.8 71.6,61.8 71.7,61.8 71.7,61.8 71.7,61.8 71.8,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.6 71.6,61.8 71.6,61.8 71.7,61.8 71.7,61.8 71.7,61.8 71.8,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.6 71.6,61.8 71.6,61.8 71.7,61.8 71.7,61.8 71.7,61.8 71.8,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.6 71.6,61.8 71.6,61.8 71.7,61.8 71.7,61.8 71.7,61.8 71.8,61.7"
        />
        <polygon fill={colors[3]} points="71.8,61.7 71.8,61.7 71.7,61.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.8,61.7 71.8,61.7 71.7,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.8,61.7 71.8,61.7 71.7,61.7"
        />
        <polygon
          fill={colors[4]}
          points="71.6,61.5 71.6,61.5 71.6,61.5 71.7,61.7 71.8,61.7 71.8,61.7 71.7,61.5 71.6,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.5 71.6,61.5 71.6,61.5 71.7,61.7 71.8,61.7 71.8,61.7 71.7,61.5 71.6,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.5 71.6,61.5 71.6,61.5 71.7,61.7 71.8,61.7 71.8,61.7 71.7,61.5 71.6,61.6"
        />
        <polygon
          fill={colors[3]}
          points="71.6,61.5 71.1,60.9 70.9,60.8 70.9,60.8 71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.5 71.1,60.9 70.9,60.8 70.9,60.8 71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.5 71.6,61.6"
        />
        <polygon fill={colors[4]} points="71.6,61.6 71.7,61.5 71.6,61.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,61.6 71.7,61.5 71.6,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,60.8 70.8,60.8 70.9,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,60.8 70.8,60.8 70.9,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,60.8 70.8,60.8 70.9,60.8"
        />
        <polygon fill={colors[3]} points="70.8,60.8 70.9,60.8 70.9,60.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,60.8 70.9,60.8 70.9,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,60.8 70.9,60.8 70.9,60.8"
        />
        <polygon
          fill={colors[4]}
          points="70.9,60.7 70.8,60.8 70.9,60.8 70.9,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,60.7 70.8,60.8 70.9,60.8 70.9,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,60.7 70.8,60.8 70.9,60.8 70.9,60.8"
        />
        <polygon
          fill={colors[3]}
          points="70.6,62.3 70.2,62 70.6,62.4 70.6,62.4 70.7,62.4 70.7,60.9 70.6,61"
        />
        <polygon
          fill={colors[4]}
          points="70.7,60.9 70.7,62.4 70.7,62.5 70.7,62.4 71.5,61.7 71.5,61.7 71.5,61.7 70.7,62.3"
        />
        <polygon
          fill={colors[4]}
          points="71.5,61.5 70.8,60.8 70.8,60.8 70.7,60.9"
        />
        <polygon
          fill="#CCC"
          points="71.5,61.5 70.7,60.9 70.7,60.9 70.7,62.3 71.5,61.7 71.5,61.6 71.6,61.6 71.6,61.6 71.6,61.6 71.6,61.5 71.6,61.5"
        />
        <polygon
          fill={colors[4]}
          points="70.2,62 70.6,62.3 70.6,61 69.9,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,60.6 70.6,60.6 70.1,60 70,60 70.5,60.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,60.6 70.6,60.6 70.1,60 70,60 70.5,60.5"
        />
        <polygon
          fill={colors[3]}
          points="70.7,60.5 70.1,60 70.1,60 70.1,60 70.6,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.5 70.1,60 70.1,60 70.1,60 70.6,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,60.8 70.5,60.8 69.8,61.5 69.8,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,60.8 70.5,60.8 69.8,61.5 69.8,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.6 70.7,60.6 70.7,60.6 70.6,60.6 70.7,60.6 70.7,60.6 70.8,60.8 70.8,60.8 70.8,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.6 70.7,60.6 70.7,60.6 70.6,60.6 70.7,60.6 70.7,60.6 70.8,60.8 70.8,60.8 70.8,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.6 70.7,60.6 70.7,60.6 70.6,60.6 70.7,60.6 70.7,60.6 70.8,60.8 70.8,60.8 70.8,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.6 70.7,60.6 70.7,60.6 70.6,60.6 70.7,60.6 70.7,60.6 70.8,60.8 70.8,60.8 70.8,60.7"
        />
        <polygon fill={colors[3]} points="70.8,60.8 70.8,60.8 70.8,60.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,60.8 70.8,60.8 70.8,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,60.8 70.8,60.8 70.8,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,60.8 70.8,60.8 70.8,60.7"
        />
        <polygon
          fill={colors[4]}
          points="70.7,60.6 70.7,60.6 70.7,60.6 70.8,60.7 70.8,60.8 70.9,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.6 70.7,60.6 70.7,60.6 70.8,60.7 70.8,60.8 70.9,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.6 70.7,60.6 70.7,60.6 70.8,60.7 70.8,60.8 70.9,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.6 70.7,60.6 70.7,60.6 70.8,60.7 70.8,60.8 70.9,60.7"
        />
        <polygon
          fill={colors[3]}
          points="70.7,60.6 70.7,60.6 70.7,60.6 70.7,60.6 70.7,60.6 70.7,60.5 70.6,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.6 70.7,60.6 70.7,60.6 70.7,60.6 70.7,60.6 70.7,60.5 70.6,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.6 70.7,60.6 70.7,60.6 70.7,60.6 70.7,60.6 70.7,60.5 70.6,60.6"
        />
        <polygon
          fill={colors[3]}
          points="70.5,60.8 69.8,61.5 70.6,60.8 70.6,61 70.7,60.9 70.7,60.6 70.6,60.7 70.5,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,60.8 69.8,61.5 70.6,60.8 70.6,61 70.7,60.9 70.7,60.6 70.6,60.7 70.5,60.7"
        />
        <polygon
          fill={colors[4]}
          points="70.7,60.6 70.7,60.9 70.7,60.9 70.7,60.8 70.7,60.9 70.8,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.6 70.7,60.9 70.7,60.9 70.7,60.8 70.7,60.9 70.8,60.8"
        />
        <polygon fill="#CCC" points="70.7,60.9 70.7,60.9 70.7,60.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,60.9 70.7,60.9 70.7,60.8"
        />
        <polygon
          fill={colors[4]}
          points="69.9,61.7 70.6,61 70.6,60.8 69.8,61.5 69.8,61.5 69.7,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,61.7 70.6,61 70.6,60.8 69.8,61.5 69.8,61.5 69.7,61.5"
        />
        <polygon
          fill={colors[3]}
          points="69.2,61 69.7,61.5 69.7,61.5 69.7,61.5 69.7,60.3 69.7,60.4 69.7,61.4"
        />
        <polygon
          fill={colors[4]}
          points="69.7,61.5 69.7,61.5 69.8,61.5 70.5,60.8 70.5,60.7 70.5,60.7 69.8,61.4 69.8,60.3 69.7,60.3"
        />
        <polygon fill={colors[4]} points="70,60 70,60.1 70.5,60.5" />
        <polygon
          fill="#CCC"
          points="69.8,60.3 69.8,61.4 70.5,60.7 70.6,60.7 70.7,60.6 70.7,60.6 70.7,60.6 70.6,60.6 70.6,60.6 70.5,60.5 70,60.1"
        />
        <polygon
          fill={colors[4]}
          points="69.7,61.4 69.7,60.4 69.1,60.9 69.1,60.9 69.1,60.9 69.2,61"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,62.7 72.9,62.9 72.9,62.9 72.7,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,62.7 72.9,62.9 72.9,62.9 72.7,62.6"
        />
        <polygon
          fill={colors[4]}
          points="72.6,62.7 72.9,63 72.9,62.9 72.7,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,62.7 72.9,63 72.9,62.9 72.7,62.7"
        />
        <polygon
          fill="#CCC"
          points="72.6,62.8 72.6,62.8 73,63.2 72.9,63 72.6,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,62.8 72.6,62.8 73,63.2 72.9,63 72.6,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,62.7 72.7,62.6 72.7,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,62.7 72.7,62.6 72.7,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,62.7 72.7,62.6 72.7,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,62.6 72.4,62.6 72.4,62.6 72.4,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,62.6 72.4,62.6 72.4,62.6 72.4,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,62.6 72.4,62.6 72.4,62.6 72.4,62.7"
        />
        <polygon
          fill={colors[3]}
          points="72.4,62.7 72.5,62.7 72.5,62.7 72.5,62.7 72.5,62.8 72.5,62.7 72.4,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,62.7 72.5,62.7 72.5,62.7 72.5,62.7 72.5,62.8 72.5,62.7 72.4,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,62.7 72.5,62.7 72.5,62.7 72.5,62.7 72.5,62.8 72.5,62.7 72.4,62.6"
        />
        <polygon
          fill={colors[4]}
          points="72.6,62.7 72.6,62.7 72.7,62.7 72.7,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,62.7 72.6,62.7 72.7,62.7 72.7,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,62.7 72.6,62.7 72.7,62.7 72.7,62.7"
        />
        <polygon
          fill={colors[4]}
          points="72.6,62.8 72.5,62.7 72.5,62.8 72.6,62.8 72.6,62.8 72.6,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,62.8 72.5,62.7 72.5,62.8 72.6,62.8 72.6,62.8 72.6,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,62.8 72.5,62.7 72.5,62.8 72.6,62.8 72.6,62.8 72.6,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,62.4 72.8,62.2 72.8,62.2 72.6,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,62.4 72.8,62.2 72.8,62.2 72.6,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,62.7 72.7,62.6 72.7,62.6 72.6,62.5 72.5,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,62.7 72.7,62.6 72.7,62.6 72.6,62.5 72.5,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,62.7 72.7,62.6 72.7,62.6 72.6,62.5 72.5,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.7,62.7 72.7,62.6 72.7,62.6 72.6,62.5 72.5,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.4 72.5,62.4 72.5,62.5 72.5,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.4 72.5,62.4 72.5,62.5 72.5,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.4 72.5,62.4 72.5,62.5 72.5,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.4 72.5,62.4 72.5,62.5 72.5,62.5"
        />
        <polygon
          fill={colors[3]}
          points="72.5,62.7 72.5,62.5 72.5,62.6 72.4,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.7 72.5,62.5 72.5,62.6 72.4,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.7 72.5,62.5 72.5,62.6 72.4,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.7 72.5,62.5 72.5,62.6 72.4,62.6"
        />
        <polygon
          fill={colors[4]}
          points="72.6,62.8 72.6,62.7 72.6,62.7 72.6,62.7 72.7,62.7 72.5,62.5 72.5,62.5 72.5,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,62.8 72.6,62.7 72.6,62.7 72.6,62.7 72.7,62.7 72.5,62.5 72.5,62.5 72.5,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,62.8 72.6,62.7 72.6,62.7 72.6,62.7 72.7,62.7 72.5,62.5 72.5,62.5 72.5,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,62.8 72.6,62.7 72.6,62.7 72.6,62.7 72.7,62.7 72.5,62.5 72.5,62.5 72.5,62.7"
        />
        <polygon
          fill="#CCC"
          points="72.4,62.6 72.4,62.6 72.4,62.6 72.5,62.6 72.5,62.5 72.5,62.5 72.6,62.5 72.5,62.5 72.5,62.5 72.5,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,62.6 72.4,62.6 72.4,62.6 72.5,62.6 72.5,62.5 72.5,62.5 72.6,62.5 72.5,62.5 72.5,62.5 72.5,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.4,62.6 72.4,62.6 72.4,62.6 72.5,62.6 72.5,62.5 72.5,62.5 72.6,62.5 72.5,62.5 72.5,62.5 72.5,62.5"
        />
        <polygon
          fill={colors[3]}
          points="72.5,62.4 72.6,62.5 72.6,62.5 72.6,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.5,62.4 72.6,62.5 72.6,62.5 72.6,62.4"
        />
        <polygon
          fill={colors[4]}
          points="72.6,62.5 72.6,62.4 72.8,62.2 72.8,62.2 72.6,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="72.6,62.5 72.6,62.4 72.8,62.2 72.8,62.2 72.6,62.4"
        />
        <polygon
          fill={colors[3]}
          points="72.7,62.6 72.7,62.6 72.7,62.6 72.9,62.9 72.9,62.8"
        />
        <polygon fill={colors[3]} points="72.8,62.3 72.8,62.2 72.7,62.4" />
        <polygon
          fill={colors[4]}
          points="72.6,62.4 72.6,62.5 72.6,62.5 72.5,62.5 72.5,62.5 72.6,62.5 72.7,62.6 72.9,62.8 72.8,62.3 72.7,62.4"
        />
        <polygon fill="#CCC" points="69.5,63.5 69.5,63.6 69.5,63.6 69.5,63.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,63.5 69.5,63.6 69.5,63.6 69.5,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.3 70.6,64.4 70.6,64.4 70.6,64.4 70.6,64.4 69.9,63.6 69.9,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.3 70.6,64.4 70.6,64.4 70.6,64.4 70.6,64.4 69.9,63.6 69.9,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,63.7 69.9,63.6 69.8,63.6 69.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,63.7 69.9,63.6 69.8,63.6 69.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,63.7 69.9,63.6 69.8,63.6 69.8,63.6"
        />
        <polygon fill="#CCC" points="69.5,63.6 69.5,63.6 69.5,63.6 69.6,63.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,63.6 69.5,63.6 69.5,63.6 69.6,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,63.6 69.5,63.6 69.5,63.6 69.6,63.6"
        />
        <polygon
          fill={colors[3]}
          points="69.6,63.7 69.6,63.7 69.6,63.7 69.7,63.7 69.7,63.7 69.6,63.6 69.5,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,63.7 69.6,63.7 69.6,63.7 69.7,63.7 69.7,63.7 69.6,63.6 69.5,63.6"
        />
        <polygon
          fill={colors[4]}
          points="69.7,63.7 69.7,63.7 69.7,63.8 69.7,63.7 70.5,64.3 69.9,63.7 69.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,63.7 69.7,63.7 69.7,63.8 69.7,63.7 70.5,64.3 69.9,63.7 69.8,63.6"
        />
        <polygon
          fill="#CCC"
          points="69.7,63.7 69.7,63.8 70.5,64.5 70.5,64.4 70.6,64.4 70.5,64.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,63.7 69.7,63.8 70.5,64.5 70.5,64.4 70.6,64.4 70.5,64.3"
        />
        <polygon fill="#CCC" points="70.5,64.4 70.6,64.4 70.6,64.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.4 70.6,64.4 70.6,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.4 70.6,64.4 70.6,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,64.3 71.4,63.6 71.4,63.6 70.7,64.4 70.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,64.3 71.4,63.6 71.4,63.6 70.7,64.4 70.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,64.4 70.8,64.5 70.8,64.5 70.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,64.4 70.8,64.5 70.8,64.5 70.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,64.4 70.8,64.5 70.8,64.5 70.8,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.4 70.6,64.4 70.7,64.4 70.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.4 70.6,64.4 70.7,64.4 70.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.4 70.6,64.4 70.7,64.4 70.7,64.4"
        />
        <polygon fill="#CCC" points="70.5,64.4 70.5,64.5 70.5,64.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.4 70.5,64.5 70.5,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.4 70.5,64.5 70.5,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.4 70.6,64.5 70.7,64.6 70.8,64.5 70.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.4 70.6,64.5 70.7,64.6 70.8,64.5 70.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.4 70.6,64.5 70.7,64.6 70.8,64.5 70.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.4 70.6,64.5 70.7,64.6 70.8,64.5 70.7,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.6 70.6,64.5 70.5,64.5 70.5,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.6 70.6,64.5 70.5,64.5 70.5,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.6 70.6,64.5 70.5,64.5 70.5,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.6 70.6,64.5 70.5,64.5 70.5,64.5"
        />
        <polygon
          fill="#CCC"
          points="70.5,64.4 70.5,64.5 70.5,64.5 70.5,64.5 70.6,64.5 70.6,64.5 70.6,64.5 70.6,64.4 70.6,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.4 70.5,64.5 70.5,64.5 70.5,64.5 70.6,64.5 70.6,64.5 70.6,64.5 70.6,64.4 70.6,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.4 70.5,64.5 70.5,64.5 70.5,64.5 70.6,64.5 70.6,64.5 70.6,64.5 70.6,64.4 70.6,64.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.4 70.5,64.5 70.5,64.5 70.5,64.5 70.6,64.5 70.6,64.5 70.6,64.5 70.6,64.4 70.6,64.4"
        />
        <polygon
          fill={colors[3]}
          points="70.5,64.6 70.6,64.6 70.6,64.5 70.6,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.6 70.6,64.6 70.6,64.5 70.6,64.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,64.6 70.6,64.6 70.6,64.5 70.6,64.5"
        />
        <polygon
          fill={colors[4]}
          points="70.6,64.5 70.6,64.5 70.6,64.6 70.6,64.7 70.6,64.7 70.6,64.7 70.6,64.7 70.7,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.5 70.6,64.5 70.6,64.6 70.6,64.7 70.6,64.7 70.6,64.7 70.6,64.7 70.7,64.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,64.5 70.6,64.5 70.6,64.6 70.6,64.7 70.6,64.7 70.6,64.7 70.6,64.7 70.7,64.6"
        />
        <polygon
          fill={colors[3]}
          points="71.5,63.7 71.5,63.8 71.6,63.8 71.6,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,63.7 71.5,63.8 71.6,63.8 71.6,63.7"
        />
        <polygon fill={colors[3]} points="71.5,63.7 71.4,63.6 70.8,64.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,63.7 71.4,63.6 70.8,64.3"
        />
        <polygon fill={colors[4]} points="71.6,63.8 71.6,63.7 71.6,63.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.8 71.6,63.7 71.6,63.7"
        />
        <polygon
          fill={colors[4]}
          points="70.7,64.4 70.6,64.4 70.6,64.4 70.7,64.4 70.8,64.5 70.8,64.5 71.5,63.8 71.5,63.7 71.5,63.7 70.8,64.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,64.4 70.6,64.4 70.6,64.4 70.7,64.4 70.8,64.5 70.8,64.5 71.5,63.8 71.5,63.7 71.5,63.7 70.8,64.3"
        />
        <polygon
          fill={colors[3]}
          points="71.5,63.7 71.5,63.7 71.5,63.7 71.6,63.7 71.6,63.7 71.5,63.6 71.4,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,63.7 71.5,63.7 71.5,63.7 71.6,63.7 71.6,63.7 71.5,63.6 71.4,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,63.7 71.5,63.7 71.5,63.7 71.6,63.7 71.6,63.7 71.5,63.6 71.4,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,63.3 71.5,63.4 71.6,63.4 70.8,62.7 70.8,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,63.3 71.5,63.4 71.6,63.4 70.8,62.7 70.8,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.5 71.5,63.6 71.4,63.6 71.5,63.6 71.6,63.5 71.7,63.6 71.7,63.6 71.7,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.5 71.5,63.6 71.4,63.6 71.5,63.6 71.6,63.5 71.7,63.6 71.7,63.6 71.7,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.5 71.5,63.6 71.4,63.6 71.5,63.6 71.6,63.5 71.7,63.6 71.7,63.6 71.7,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.5 71.5,63.6 71.4,63.6 71.5,63.6 71.6,63.5 71.7,63.6 71.7,63.6 71.7,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,63.4 71.6,63.4 71.6,63.4 71.6,63.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,63.4 71.6,63.4 71.6,63.4 71.6,63.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,63.4 71.6,63.4 71.6,63.4 71.6,63.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.5,63.4 71.6,63.4 71.6,63.4 71.6,63.4"
        />
        <polygon fill={colors[3]} points="71.6,63.5 71.5,63.6 71.6,63.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.5 71.5,63.6 71.6,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.5 71.5,63.6 71.6,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.5 71.5,63.6 71.6,63.7"
        />
        <polygon
          fill={colors[4]}
          points="71.6,63.7 71.6,63.7 71.6,63.7 71.6,63.7 71.7,63.7 71.7,63.6 71.6,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.7 71.6,63.7 71.6,63.7 71.6,63.7 71.7,63.7 71.7,63.6 71.6,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.7 71.6,63.7 71.6,63.7 71.6,63.7 71.7,63.7 71.7,63.6 71.6,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.7 71.6,63.7 71.6,63.7 71.6,63.7 71.7,63.7 71.7,63.6 71.6,63.5"
        />
        <polygon fill="#CCC" points="71.6,63.7 71.6,63.7 71.7,63.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.7 71.6,63.7 71.7,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.7 71.6,63.7 71.7,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.7 71.6,63.7 71.7,63.7"
        />
        <polygon
          fill={colors[4]}
          points="71.6,63.4 71.6,63.4 71.6,63.4 71.6,63.5 71.6,63.5 71.7,63.6 71.7,63.6 71.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.4 71.6,63.4 71.6,63.4 71.6,63.5 71.6,63.5 71.7,63.6 71.7,63.6 71.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.6,63.4 71.6,63.4 71.6,63.4 71.6,63.5 71.6,63.5 71.7,63.6 71.7,63.6 71.8,63.6"
        />
        <polygon
          fill={colors[3]}
          points="71.1,62.9 70.8,62.6 70.8,62.7 71.6,63.4 71.6,63.4 71.6,63.4 71.6,63.4 71.6,63.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71.1,62.9 70.8,62.6 70.8,62.7 71.6,63.4 71.6,63.4 71.6,63.4 71.6,63.4 71.6,63.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,62.7 70.8,62.7 70.8,62.7 70.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,62.7 70.8,62.7 70.8,62.7 70.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,62.7 70.8,62.7 70.8,62.7 70.8,62.6"
        />
        <polygon
          fill={colors[4]}
          points="70.7,62.5 70.7,62.5 70.8,62.6 70.8,62.6 70.8,62.6 70.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,62.5 70.7,62.5 70.8,62.6 70.8,62.6 70.8,62.6 70.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,62.5 70.7,62.5 70.8,62.6 70.8,62.6 70.8,62.6 70.8,62.6"
        />
        <polygon
          fill={colors[3]}
          points="70.6,62.4 70.6,62.4 70.7,62.4 70.7,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,62.4 70.6,62.4 70.7,62.4 70.7,62.4"
        />
        <polygon
          fill={colors[4]}
          points="70.7,62.5 70.7,62.5 70.7,62.4 70.7,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,62.5 70.7,62.5 70.7,62.4 70.7,62.4"
        />
        <polygon
          fill={colors[3]}
          points="70.6,64.2 69.8,63.6 69.8,63.6 69.9,63.6 70.6,64.4 70.6,64.4 70.6,62.8 70.6,62.9"
        />
        <polygon
          fill={colors[4]}
          points="70.7,64.2 70.7,62.7 70.6,62.8 70.6,64.4 70.7,64.4 71.4,63.6 71.4,63.6 71.5,63.6"
        />
        <polygon fill={colors[4]} points="70.8,62.7 70.7,62.7 71.5,63.3" />
        <polygon
          fill="#CCC"
          points="71.5,63.4 71.5,63.3 70.7,62.7 70.7,62.7 70.7,64.2 71.5,63.6 71.6,63.5 71.6,63.5 71.6,63.4"
        />
        <polygon fill={colors[4]} points="69.8,63.6 70.6,64.2 70.6,62.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,63.4 69.6,63.4 69,62.7 68.9,62.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,63.4 69.6,63.4 69,62.7 68.9,62.8"
        />
        <polygon fill={colors[4]} points="68.9,62.8 68.8,62.8 69.6,63.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,62.8 68.8,62.8 69.6,63.4"
        />
        <polygon
          fill="#CCC"
          points="69.5,63.5 69.6,63.4 69.6,63.4 68.8,62.8 68.8,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,63.5 69.6,63.4 69.6,63.4 68.8,62.8 68.8,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,62.6 68.8,62.7 68.9,62.8 69,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,62.6 68.8,62.7 68.9,62.8 69,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,62.6 68.8,62.7 68.9,62.8 69,62.7"
        />
        <polygon fill={colors[3]} points="68.7,62.8 68.7,62.7 68.6,62.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.8 68.7,62.7 68.6,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.8 68.7,62.7 68.6,62.7"
        />
        <polygon
          fill={colors[4]}
          points="68.8,62.8 68.8,62.8 68.9,62.8 68.8,62.7 68.7,62.8 68.7,62.8 68.8,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,62.8 68.8,62.8 68.9,62.8 68.8,62.7 68.7,62.8 68.7,62.8 68.8,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,62.8 68.8,62.8 68.9,62.8 68.8,62.7 68.7,62.8 68.7,62.8 68.8,62.9"
        />
        <polygon fill="#CCC" points="68.8,62.8 68.8,62.8 68.8,62.9 68.8,62.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,62.8 68.8,62.8 68.8,62.9 68.8,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,62.8 68.8,62.8 68.8,62.9 68.8,62.9"
        />
        <polygon fill="#CCC" points="68.5,62.6 68.5,62.6 68.6,62.7 68.7,62.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.5,62.6 68.5,62.6 68.6,62.7 68.7,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,62.4 70.6,62.5 70.6,62.4 69.9,61.7 69.8,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.5,62.4 70.6,62.5 70.6,62.4 69.9,61.7 69.8,61.7"
        />
        <polygon
          fill={colors[3]}
          points="69.9,61.7 70.6,62.4 70.6,62.4 70.2,62"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,61.7 70.6,62.4 70.6,62.4 70.2,62"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,61.7 69.8,61.7 69.9,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,61.7 69.8,61.7 69.9,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,61.7 69.8,61.7 69.9,61.7"
        />
        <polygon
          fill={colors[4]}
          points="69.9,61.7 69.8,61.7 69.9,61.7 69.9,61.7 69.7,61.5 69.7,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,61.7 69.8,61.7 69.9,61.7 69.9,61.7 69.7,61.5 69.7,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,61.7 69.8,61.7 69.9,61.7 69.9,61.7 69.7,61.5 69.7,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,63.4 69.7,63.4 69.8,63.3 70.5,62.7 70.5,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,63.4 69.7,63.4 69.8,63.3 70.5,62.7 70.5,62.6"
        />
        <polygon fill="#CCC" points="69.5,63.5 69.5,63.6 69.6,63.5 69.6,63.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,63.5 69.5,63.6 69.6,63.5 69.6,63.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,63.5 69.5,63.6 69.6,63.5 69.6,63.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,63.5 69.7,63.4 69.7,63.4 69.7,63.4 69.7,63.4 69.6,63.5 69.7,63.5 69.7,63.5 69.8,63.6 69.8,63.6 69.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,63.5 69.7,63.4 69.7,63.4 69.7,63.4 69.7,63.4 69.6,63.5 69.7,63.5 69.7,63.5 69.8,63.6 69.8,63.6 69.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,63.5 69.7,63.4 69.7,63.4 69.7,63.4 69.7,63.4 69.6,63.5 69.7,63.5 69.7,63.5 69.8,63.6 69.8,63.6 69.8,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,63.5 69.7,63.4 69.7,63.4 69.7,63.4 69.7,63.4 69.6,63.5 69.7,63.5 69.7,63.5 69.8,63.6 69.8,63.6 69.8,63.6"
        />
        <polygon fill="#CCC" points="69.5,63.6 69.6,63.6 69.7,63.5 69.6,63.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,63.6 69.6,63.6 69.7,63.5 69.6,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,63.6 69.6,63.6 69.7,63.5 69.6,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,63.6 69.6,63.6 69.7,63.5 69.6,63.5"
        />
        <polygon
          fill={colors[3]}
          points="69.6,63.6 69.7,63.7 69.7,63.5 69.7,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,63.6 69.7,63.7 69.7,63.5 69.7,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,63.6 69.7,63.7 69.7,63.5 69.7,63.5"
        />
        <polygon
          fill={colors[4]}
          points="69.7,63.7 69.7,63.7 69.8,63.6 69.7,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,63.7 69.7,63.7 69.8,63.6 69.7,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,63.7 69.7,63.7 69.8,63.6 69.7,63.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,62.5 70.7,62.5 70.6,62.5 70.6,62.5 70.6,62.5 70.5,62.6 70.5,62.6 70.5,62.6 70.6,62.5 70.8,62.7 70.8,62.6 70.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,62.5 70.7,62.5 70.6,62.5 70.6,62.5 70.6,62.5 70.5,62.6 70.5,62.6 70.5,62.6 70.6,62.5 70.8,62.7 70.8,62.6 70.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,62.5 70.7,62.5 70.6,62.5 70.6,62.5 70.6,62.5 70.5,62.6 70.5,62.6 70.5,62.6 70.6,62.5 70.8,62.7 70.8,62.6 70.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,62.5 70.7,62.5 70.6,62.5 70.6,62.5 70.6,62.5 70.5,62.6 70.5,62.6 70.5,62.6 70.6,62.5 70.8,62.7 70.8,62.6 70.8,62.6"
        />
        <polygon
          fill={colors[4]}
          points="70.7,62.5 70.6,62.5 70.8,62.6 70.8,62.6 70.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,62.5 70.6,62.5 70.8,62.6 70.8,62.6 70.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,62.5 70.6,62.5 70.8,62.6 70.8,62.6 70.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,62.5 70.6,62.5 70.8,62.6 70.8,62.6 70.8,62.6"
        />
        <polygon
          fill={colors[3]}
          points="70.6,62.5 70.7,62.5 70.7,62.5 70.7,62.4 70.6,62.4 70.6,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,62.5 70.7,62.5 70.7,62.5 70.7,62.4 70.6,62.4 70.6,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,62.5 70.7,62.5 70.7,62.5 70.7,62.4 70.6,62.4 70.6,62.4"
        />
        <polygon fill={colors[4]} points="70.7,62.4 70.7,62.5 70.7,62.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,62.4 70.7,62.5 70.7,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,62.4 70.7,62.5 70.7,62.5"
        />
        <polygon
          fill={colors[3]}
          points="70.6,62.5 70.5,62.6 70.5,62.7 69.8,63.3 70.6,62.7 70.6,62.9 70.6,62.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,62.5 70.5,62.6 70.5,62.7 69.8,63.3 70.6,62.7 70.6,62.9 70.6,62.8"
        />
        <polygon
          fill={colors[4]}
          points="70.6,62.5 70.6,62.8 70.7,62.7 70.7,62.7 70.7,62.7 70.8,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.6,62.5 70.6,62.8 70.7,62.7 70.7,62.7 70.7,62.7 70.8,62.7"
        />
        <polygon
          fill={colors[4]}
          points="69.7,63.4 69.7,63.5 69.8,63.6 69.8,63.6 70.6,62.9 70.6,62.7 69.8,63.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,63.4 69.7,63.5 69.8,63.6 69.8,63.6 70.6,62.9 70.6,62.7 69.8,63.3"
        />
        <polygon
          fill={colors[3]}
          points="69.7,61.8 69.6,61.9 69.6,63.2 68.9,62.6 68.9,62.6 69,62.7 69.6,63.4 69.7,63.4 69.7,63.4"
        />
        <polygon fill={colors[4]} points="69.8,61.7 69.8,61.8 70.5,62.4" />
        <polygon
          fill={colors[4]}
          points="69.7,63.2 69.7,61.8 69.7,61.8 69.7,63.4 69.7,63.4 69.7,63.4 70.5,62.6 70.5,62.6 70.5,62.6"
        />
        <polygon
          fill="#CCC"
          points="70.5,62.4 69.8,61.8 69.7,61.8 69.7,63.2 70.5,62.6 70.6,62.5 70.6,62.5 70.6,62.5"
        />
        <polygon fill={colors[4]} points="69.6,63.2 69.6,61.9 68.9,62.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,61.9 68.6,62.5 68.7,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,61.9 68.6,62.5 68.7,62.4"
        />
        <polygon fill={colors[4]} points="68.6,62.5 68.1,61.9 68,62" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.6,62.5 68.1,61.9 68,62"
        />
        <polygon fill="#CCC" points="68,62 68,62 68.5,62.6 68.6,62.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,62 68,62 68.5,62.6 68.6,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,61.5 69.6,61.5 69.1,60.9 69.1,61 69.5,61.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.6,61.5 69.6,61.5 69.1,60.9 69.1,61 69.5,61.4"
        />
        <polygon
          fill={colors[3]}
          points="69.7,61.5 69.2,61 69.1,60.9 69.1,60.9 69.6,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.5 69.2,61 69.1,60.9 69.1,60.9 69.6,61.5"
        />
        <polygon fill={colors[4]} points="69.1,60.9 69.1,60.9 69.2,61" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,60.9 69.1,60.9 69.2,61"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,61.7 69.5,61.7 68.7,62.5 68.7,62.5 68.9,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,61.7 69.5,61.7 68.7,62.5 68.7,62.5 68.9,62.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.4 68.6,62.5 68.6,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.4 68.6,62.5 68.6,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.5 68.7,62.4 68.6,62.5 68.6,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,62.6 68.7,62.5 68.7,62.5 68.8,62.6 68.8,62.6 68.8,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,62.6 68.7,62.5 68.7,62.5 68.8,62.6 68.8,62.6 68.8,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,62.6 68.7,62.5 68.7,62.5 68.8,62.6 68.8,62.6 68.8,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,62.6 68.7,62.5 68.7,62.5 68.8,62.6 68.8,62.6 68.8,62.7"
        />
        <polygon
          fill={colors[3]}
          points="68.7,62.7 68.7,62.8 68.7,62.8 68.7,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.7 68.7,62.8 68.7,62.8 68.7,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.7 68.7,62.8 68.7,62.8 68.7,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.7 68.7,62.8 68.7,62.8 68.7,62.6"
        />
        <polygon
          fill={colors[4]}
          points="68.7,62.6 68.7,62.8 68.8,62.7 68.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.6 68.7,62.8 68.8,62.7 68.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.6 68.7,62.8 68.8,62.7 68.8,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.6 68.7,62.8 68.8,62.7 68.8,62.6"
        />
        <polygon
          fill="#CCC"
          points="68.7,62.7 68.7,62.6 68.8,62.6 68.8,62.6 68.7,62.5 68.6,62.5 68.6,62.5 68.5,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.7 68.7,62.6 68.8,62.6 68.8,62.6 68.7,62.5 68.6,62.5 68.6,62.5 68.5,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,62.7 68.7,62.6 68.8,62.6 68.8,62.6 68.7,62.5 68.6,62.5 68.6,62.5 68.5,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,61.7 69.5,61.7 69.5,61.7 69.6,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,61.7 69.5,61.7 69.5,61.7 69.6,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.5,61.7 69.5,61.7 69.5,61.7 69.6,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.8,61.7 69.8,61.7 69.8,61.7 69.7,61.6 69.7,61.6 69.7,61.5 69.7,61.5 69.7,61.5 69.5,61.7 69.5,61.7 69.6,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.8,61.7 69.8,61.7 69.8,61.7 69.7,61.6 69.7,61.6 69.7,61.5 69.7,61.5 69.7,61.5 69.5,61.7 69.5,61.7 69.6,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.8,61.7 69.8,61.7 69.8,61.7 69.7,61.6 69.7,61.6 69.7,61.5 69.7,61.5 69.7,61.5 69.5,61.7 69.5,61.7 69.6,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.8,61.7 69.8,61.7 69.8,61.7 69.7,61.6 69.7,61.6 69.7,61.5 69.7,61.5 69.7,61.5 69.5,61.7 69.5,61.7 69.6,61.7"
        />
        <polygon
          fill={colors[4]}
          points="69.7,61.6 69.7,61.6 69.8,61.7 69.8,61.7 69.9,61.7 69.7,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.7,61.6 69.8,61.7 69.8,61.7 69.9,61.7 69.7,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.7,61.6 69.8,61.7 69.8,61.7 69.9,61.7 69.7,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.7,61.6 69.8,61.7 69.8,61.7 69.9,61.7 69.7,61.5"
        />
        <polygon
          fill={colors[3]}
          points="69.7,61.6 69.7,61.6 69.7,61.5 69.7,61.5 69.6,61.5 69.7,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.7,61.6 69.7,61.5 69.7,61.5 69.6,61.5 69.7,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.7,61.6 69.7,61.5 69.7,61.5 69.6,61.5 69.7,61.5"
        />
        <polygon fill={colors[4]} points="69.7,61.6 69.7,61.5 69.7,61.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.7,61.5 69.7,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.7,61.5 69.7,61.5"
        />
        <polygon
          fill={colors[3]}
          points="69.7,61.6 69.6,61.7 69.5,61.7 68.9,62.4 69.6,61.8 69.6,61.9 69.7,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.6,61.7 69.5,61.7 68.9,62.4 69.6,61.8 69.6,61.9 69.7,61.8"
        />
        <polygon
          fill={colors[4]}
          points="69.7,61.6 69.7,61.8 69.7,61.8 69.7,61.8 69.8,61.8 69.8,61.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,61.6 69.7,61.8 69.7,61.8 69.7,61.8 69.8,61.8 69.8,61.7"
        />
        <polygon
          fill={colors[4]}
          points="68.9,62.6 69.6,61.9 69.6,61.8 68.9,62.4 68.7,62.5 68.7,62.5 68.7,62.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,62.6 69.6,61.9 69.6,61.8 68.9,62.4 68.7,62.5 68.7,62.5 68.7,62.5"
        />
        <polygon
          fill={colors[3]}
          points="68.7,61.3 68.7,61.4 68.7,62.3 68.1,61.9 68.1,61.9 68.7,62.4 68.7,62.5"
        />
        <polygon fill={colors[4]} points="69.1,61 69,61 69.5,61.4" />
        <polygon
          fill={colors[4]}
          points="69.5,61.7 69.5,61.7 68.8,62.3 68.8,61.2 68.7,61.3 68.7,62.5 68.7,62.5"
        />
        <polygon
          fill="#CCC"
          points="69.5,61.7 69.7,61.5 69.7,61.5 69.6,61.5 69.5,61.4 69,61 68.8,61.2 68.8,62.3"
        />
        <polygon fill={colors[4]} points="68.7,62.3 68.7,61.4 68.1,61.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,71.2 67.7,71.2 67.7,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,71.2 67.7,71.2 67.7,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,71.2 67.7,71.2 67.7,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,70.3 66.9,70.4 66.9,70.4 67.6,71.1 67.6,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,70.3 66.9,70.4 66.9,70.4 67.6,71.1 67.6,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,71.1 67.6,71.1 67.6,71.1 67.6,71.2 67.6,71.2 67.7,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,71.1 67.6,71.1 67.6,71.1 67.6,71.2 67.6,71.2 67.7,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,71.1 67.6,71.1 67.6,71.1 67.6,71.2 67.6,71.2 67.7,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,70.2 66.8,70.2 66.9,70.4 66.9,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,70.2 66.8,70.2 66.9,70.4 66.9,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,70.2 66.8,70.2 66.9,70.4 66.9,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,71.2 67.7,71.2 67.7,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,71.2 67.7,71.2 67.7,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,71.2 67.7,71.2 67.7,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.6,71.2 67.7,71.2 67.7,71.1"
        />
        <polygon
          fill={colors[3]}
          points="66.7,70.6 66.7,71.2 66.7,71.2 66.7,70.5"
        />
        <polygon
          fill={colors[4]}
          points="66.8,70.5 67.6,71.1 67.6,71.1 66.9,70.4 66.7,70.5 66.7,71.2 66.8,71.2"
        />
        <polygon fill="#CCC" points="66.8,70.5 66.8,71.2 67.6,71.2 67.6,71.1" />
        <polygon fill={colors[4]} points="66.1,71.2 66.7,71.2 66.7,70.6" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,69.2 65.8,69.2 65.1,68.5 65.1,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,69.2 65.8,69.2 65.1,68.5 65.1,68.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,69.4 66,69.4 65.9,69.3 65.8,69.2 65.8,69.2 65.8,69.2 65.8,69.2 65.8,69.2 65.7,69.2 65.7,69.2 65.7,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,69.4 66,69.4 65.9,69.3 65.8,69.2 65.8,69.2 65.8,69.2 65.8,69.2 65.8,69.2 65.7,69.2 65.7,69.2 65.7,69.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,69.4 66,69.4 65.9,69.3 65.8,69.2 65.8,69.2 65.8,69.2 65.8,69.2 65.8,69.2 65.7,69.2 65.7,69.2 65.7,69.2"
        />
        <polygon fill={colors[3]} points="65.9,69.3 66,69.4 66,69.4" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,69.3 66,69.4 66,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,69.3 66,69.4 66,69.4"
        />
        <polygon
          fill={colors[4]}
          points="66,69.4 65.8,69.2 65.8,69.2 65.9,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,69.4 65.8,69.2 65.8,69.2 65.9,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,69.4 65.8,69.2 65.8,69.2 65.9,69.3"
        />
        <polygon
          fill={colors[3]}
          points="65.8,69.2 65.8,69.2 65.1,68.5 65.1,68.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,69.2 65.8,69.2 65.1,68.5 65.1,68.5"
        />
        <polygon
          fill={colors[4]}
          points="65.1,69.8 64.9,70 64.9,69.6 64.8,70 64.8,70.1"
        />
        <polygon fill={colors[4]} points="65.1,68.6 65.1,68.7 65.7,69.2" />
        <polygon
          fill="#CCC"
          points="65.1,69.8 65.7,69.2 65.7,69.2 65.7,69.2 65.1,68.7 64.9,69.6 64.9,70"
        />
        <polygon
          fill={colors[3]}
          points="64.8,70.5 64.7,70.5 64.7,70.6 64.7,71.2 64.8,71.2"
        />
        <polygon
          fill={colors[4]}
          points="64.8,70.5 64.8,71.2 64.8,71.2 64.8,70.6"
        />
        <polygon fill="#CCC" points="64.8,71.2 65.4,71.2 64.8,70.6" />
        <polygon fill={colors[4]} points="64.7,70.6 64.6,71.2 64.7,71.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6,70.3 66.6,70.4 65.8,71.2 65.8,71.2 65.8,71.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.1 66,69.4 65.9,69.5 66.6,70.1 66.6,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.1 66,69.4 65.9,69.5 66.6,70.1 66.6,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.1 66.6,70.2 66.7,70.2 66.7,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.1 66.6,70.2 66.7,70.2 66.7,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.1 66.6,70.2 66.7,70.2 66.7,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.4 66.6,70.3 65.8,71.2 65.8,71.2 65.8,71.1 65.8,71.2 65.9,71.2 65.9,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.4 66.6,70.3 65.8,71.2 65.8,71.2 65.8,71.1 65.8,71.2 65.9,71.2 65.9,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,70.3 66.8,70.4 66.9,70.4 66.9,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,70.3 66.8,70.4 66.9,70.4 66.9,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,70.3 66.8,70.4 66.9,70.4 66.9,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6,70.3 66.6,70.3 66.6,70.3 66.7,70.4 66.7,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6,70.3 66.6,70.3 66.6,70.3 66.7,70.4 66.7,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.6,70.3 66.6,70.3 66.6,70.3 66.7,70.4 66.7,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.2 66.7,70.2 66.6,70.3 66.7,70.4 66.7,70.3 66.8,70.4 66.9,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.2 66.7,70.2 66.6,70.3 66.7,70.4 66.7,70.3 66.8,70.4 66.9,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.2 66.7,70.2 66.6,70.3 66.7,70.4 66.7,70.3 66.8,70.4 66.9,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.2 66.7,70.2 66.6,70.3 66.7,70.4 66.7,70.3 66.8,70.4 66.9,70.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,71.2 65.8,71.2 65.8,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,71.2 65.8,71.2 65.8,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,71.2 65.8,71.2 65.8,71.1"
        />
        <polygon
          fill={colors[3]}
          points="66.7,70.3 66.7,70.4 66.7,70.4 65.9,71.1 66.7,70.5 66.7,70.6 66.7,70.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.3 66.7,70.4 66.7,70.4 65.9,71.1 66.7,70.5 66.7,70.6 66.7,70.5"
        />
        <polygon
          fill={colors[4]}
          points="66.7,70.3 66.7,70.5 66.9,70.4 66.8,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.3 66.7,70.5 66.9,70.4 66.8,70.4"
        />
        <polygon
          fill={colors[4]}
          points="66.7,70.5 65.9,71.1 65.9,71.2 66.1,71.2 66.7,70.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.7,70.5 65.9,71.1 65.9,71.2 66.1,71.2 66.7,70.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,69.4 64.9,70.2 64.9,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,69.4 64.9,70.2 64.9,70.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,69.4 65.9,69.4 65.9,69.5 66,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,69.4 65.9,69.4 65.9,69.5 66,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,69.4 65.9,69.4 65.9,69.5 66,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,69.2 65.7,69.2 65.8,69.3 65.7,69.3 65.9,69.4 65.9,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,69.2 65.7,69.2 65.8,69.3 65.7,69.3 65.9,69.4 65.9,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,69.2 65.7,69.2 65.8,69.3 65.7,69.3 65.9,69.4 65.9,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,69.2 65.7,69.2 65.8,69.3 65.7,69.3 65.9,69.4 65.9,69.4"
        />
        <polygon fill={colors[3]} points="64.8,70.2 64.8,70.1 64.8,70.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.8,70.2 64.8,70.1 64.8,70.1"
        />
        <polygon
          fill={colors[4]}
          points="65.1,69.8 64.8,70.1 64.8,70.2 64.9,70.2 65.7,69.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.1,69.8 64.8,70.1 64.8,70.2 64.9,70.2 65.7,69.4"
        />
        <polygon
          fill="#CCC"
          points="65.7,69.2 65.1,69.8 65.7,69.4 65.7,69.4 65.7,69.4 65.7,69.3 65.7,69.3 65.7,69.3 65.8,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.7,69.2 65.1,69.8 65.7,69.4 65.7,69.4 65.7,69.4 65.7,69.3 65.7,69.3 65.7,69.3 65.8,69.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.9,70.4 65.6,71.1 65.7,71.2 65.7,71.2 65,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.9,70.4 65.6,71.1 65.7,71.2 65.7,71.2 65,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.9,70.4 65,70.4 65,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.9,70.4 65,70.4 65,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.9,70.4 65,70.4 65,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.8,70.3 64.9,70.4 65,70.4 64.9,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.8,70.3 64.9,70.4 65,70.4 64.9,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.8,70.3 64.9,70.4 65,70.4 64.9,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.8,70.3 64.9,70.4 65,70.4 64.9,70.4"
        />
        <polygon fill="#CCC" points="64.8,70.3 64.8,70.3 64.8,70.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.8,70.3 64.8,70.3 64.8,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.8,70.3 64.8,70.3 64.8,70.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.8,70.3 64.8,70.3 64.8,70.2"
        />
        <polygon
          fill={colors[3]}
          points="64.8,70.3 64.8,70.3 64.7,70.5 64.8,70.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.8,70.3 64.8,70.3 64.7,70.5 64.8,70.5"
        />
        <polygon
          fill={colors[4]}
          points="64.8,70.3 64.8,70.3 64.8,70.5 64.8,70.6 64.8,70.5 65.6,71.1 64.9,70.4 64.9,70.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.8,70.3 64.8,70.3 64.8,70.5 64.8,70.6 64.8,70.5 65.6,71.1 64.9,70.4 64.9,70.4"
        />
        <polygon
          fill="#CCC"
          points="64.8,70.5 64.8,70.6 65.4,71.2 65.7,71.2 65.6,71.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="64.8,70.5 64.8,70.6 65.4,71.2 65.7,71.2 65.6,71.1"
        />
        <polygon
          fill={colors[3]}
          points="65.7,69.3 65.7,69.3 65.7,69.4 65.7,69.4 64.9,70.1 65.7,69.5 65.7,71 64.9,70.4 65,70.4 65,70.4 65.7,71.2 65.7,71.2"
        />
        <polygon
          fill={colors[4]}
          points="66.6,70.4 65.8,71 65.8,69.5 66.6,70.1 65.9,69.5 65.9,69.4 65.7,69.3 65.7,69.3 65.7,71.2 65.8,71.2 65.8,71.2"
        />
        <polygon
          fill="#CCC"
          points="66.6,70.3 66.7,70.2 66.7,70.2 66.6,70.2 66.6,70.1 65.8,69.5 65.8,71 66.6,70.4"
        />
        <polygon
          fill={colors[4]}
          points="64.8,70.3 64.9,70.4 65.7,71 65.7,69.5 64.9,70.1 64.9,70.2 64.8,70.2 64.8,70.2 64.8,70.2 64.8,70.2 64.8,70.2 64.8,70.2"
        />
        <polygon fill={colors[4]} points="66.3,61.4 66.3,61.5 66.4,61.4" />
        <polygon
          fill="#CCC"
          points="66.8,60.9 66.8,60.9 66.8,60.9 66.5,60.5 66.3,61.4 66.4,61.4"
        />
        <polygon
          fill={colors[3]}
          points="65.9,64.1 65.9,63.9 65.9,63.8 65.8,64 65.8,64.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,64.1 65.9,63.9 65.9,63.8 65.8,64 65.8,64.2"
        />
        <polygon
          fill={colors[4]}
          points="66,63.9 65.9,63.9 65.9,64.1 66,64.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.9 65.9,63.9 65.9,64.1 66,64.1"
        />
        <polygon fill="#CCC" points="66,64 66,64 66,63.9 66,64.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,64 66,64 66,63.9 66,64.1"
        />
        <polygon fill={colors[4]} points="65.8,64 65.8,64.2 65.8,64.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.8,64 65.8,64.2 65.8,64.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.2,63.8 66.2,63.8 66.2,63.8 66.2,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.2,63.8 66.2,63.8 66.2,63.8 66.2,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.2,63.8 66.2,63.8 66.2,63.8 66.2,63.9"
        />
        <polygon fill={colors[4]} points="66.1,63.9 66.2,63.9 66.2,63.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,63.9 66.2,63.9 66.2,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,63.9 66.2,63.9 66.2,63.8"
        />
        <polygon fill="#CCC" points="66,64 66.1,63.9 66.1,63.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,64 66.1,63.9 66.1,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,64 66.1,63.9 66.1,63.9"
        />
        <polygon
          fill={colors[3]}
          points="66.9,63.2 66.9,62.9 66.8,62.9 66.8,62.9 66.8,63.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,63.2 66.9,62.9 66.8,62.9 66.8,62.9 66.8,63.2"
        />
        <polygon
          fill={colors[4]}
          points="66.9,63 66.9,62.9 66.9,63.2 66.9,63.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,63 66.9,62.9 66.9,63.2 66.9,63.1"
        />
        <polygon fill="#CCC" points="67,63 67,63 66.9,63 66.9,63.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,63 67,63 66.9,63 66.9,63.1"
        />
        <polygon
          fill={colors[4]}
          points="66.8,63.2 66.8,62.9 66.1,63.7 66.2,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,63.2 66.8,62.9 66.1,63.7 66.2,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,62.9 67.1,62.9 67.1,62.9 67.1,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,62.9 67.1,62.9 67.1,62.9 67.1,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,62.9 67.1,62.9 67.1,62.9 67.1,62.9"
        />
        <polygon
          fill={colors[4]}
          points="67.1,62.9 67.1,62.9 67.1,62.9 67.1,63"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,62.9 67.1,62.9 67.1,62.9 67.1,63"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,62.9 67.1,62.9 67.1,62.9 67.1,63"
        />
        <polygon fill="#CCC" points="67,63 67.1,63 67.1,62.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,63 67.1,63 67.1,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,63 67.1,63 67.1,62.9"
        />
        <polygon fill={colors[3]} points="67.2,62.9 67.2,62.9 67.1,62.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,62.9 67.2,62.9 67.1,62.9"
        />
        <polygon
          fill={colors[3]}
          points="65.9,63.9 65.9,63.8 65.9,63.8 65.9,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,63.9 65.9,63.8 65.9,63.8 65.9,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,63.9 65.9,63.8 65.9,63.8 65.9,63.8"
        />
        <polygon fill={colors[4]} points="66,63.9 65.9,63.9 65.9,63.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.9 65.9,63.9 65.9,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.9 65.9,63.9 65.9,63.9"
        />
        <polygon fill="#CCC" points="66,64 66,63.9 66,63.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,64 66,63.9 66,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,64 66,63.9 66,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.7 66,63.7 66.2,63.8 66.2,63.8 66,63.7 66,63.7 66,63.7 65.9,63.6 65.9,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.7 66,63.7 66.2,63.8 66.2,63.8 66,63.7 66,63.7 66,63.7 65.9,63.6 65.9,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.7 66,63.7 66.2,63.8 66.2,63.8 66,63.7 66,63.7 66,63.7 65.9,63.6 65.9,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.7 66,63.7 66.2,63.8 66.2,63.8 66,63.7 66,63.7 66,63.7 65.9,63.6 65.9,63.6"
        />
        <polygon fill={colors[3]} points="65.9,63.8 65.9,63.8 65.9,63.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,63.8 65.9,63.8 65.9,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,63.8 65.9,63.8 65.9,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,63.8 65.9,63.8 65.9,63.9"
        />
        <polygon
          fill={colors[4]}
          points="66,63.8 66.1,63.9 66.2,63.8 66,63.7 65.9,63.8 65.9,63.9 66,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.8 66.1,63.9 66.2,63.8 66,63.7 65.9,63.8 65.9,63.9 66,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.8 66.1,63.9 66.2,63.8 66,63.7 65.9,63.8 65.9,63.9 66,63.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.8 66.1,63.9 66.2,63.8 66,63.7 65.9,63.8 65.9,63.9 66,63.9"
        />
        <polygon fill={colors[4]} points="65.9,63.6 65.9,63.6 66,63.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,63.6 65.9,63.6 66,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,63.6 65.9,63.6 66,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,63.6 65.9,63.6 66,63.7"
        />
        <polygon fill="#CCC" points="66.1,63.9 66,63.8 66,63.9 66,64" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,63.9 66,63.8 66,63.9 66,64"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,63.9 66,63.8 66,63.9 66,64"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.1,63.9 66,63.8 66,63.9 66,64"
        />
        <polygon fill={colors[3]} points="66,63.7 66.2,63.8 66.2,63.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.7 66.2,63.8 66.2,63.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66,63.7 66.2,63.8 66.2,63.8"
        />
        <polygon
          fill={colors[4]}
          points="66.2,63.8 66.1,63.7 66,63.7 66,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.2,63.8 66.1,63.7 66,63.7 66,63.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.2,63.8 66.1,63.7 66,63.7 66,63.7"
        />
        <polygon
          fill="#CCC"
          points="65.9,63.8 66,63.7 66,63.7 66,63.7 65.9,63.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="65.9,63.8 66,63.7 66,63.7 66,63.7 65.9,63.6"
        />
        <polygon
          fill={colors[3]}
          points="66,63 66,63.3 66,63.5 65.9,63.5 65.9,63.6 66,63.7"
        />
        <polygon
          fill={colors[4]}
          points="66,63.7 66,63.7 66.1,63.7 66.8,62.9 66.8,62.9 66.8,62.9 66.1,63.5 66.1,62.7 66,63"
        />
        <polygon fill="#CCC" points="66.1,63.5 66.8,62.9 66.2,62.2 66.1,62.7" />
        <polygon fill={colors[4]} points="66,63.3 65.9,63.5 66,63.5" />
        <polygon
          fill={colors[3]}
          points="67.8,62.2 67.8,61.9 67.8,61.9 67.8,62 67.8,62.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,62.2 67.8,61.9 67.8,61.9 67.8,62 67.8,62.3"
        />
        <polygon
          fill={colors[4]}
          points="67.9,61.9 67.8,61.9 67.8,61.9 67.8,62.2 67.9,62.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,61.9 67.8,61.9 67.8,61.9 67.8,62.2 67.9,62.2"
        />
        <polygon fill="#CCC" points="67.9,61.9 67.9,62.2 68,62" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,61.9 67.9,62.2 68,62"
        />
        <polygon
          fill={colors[4]}
          points="67.8,62.3 67.8,62 67,62.7 67.2,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,62.3 67.8,62 67,62.7 67.2,62.9"
        />
        <polygon fill={colors[4]} points="68.1,61.9 68.1,61.9 68,62 68,62" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,61.9 68.1,61.9 68,62 68,62"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,61.9 68.1,61.9 68,62 68,62"
        />
        <polygon fill={colors[4]} points="67.9,61.9 67.8,61.9 67.8,61.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,61.9 67.8,61.9 67.8,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,61.9 67.8,61.9 67.8,61.9"
        />
        <polygon fill="#CCC" points="68,62 68,62 68,62 67.9,61.9 67.9,61.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,62 68,62 68,62 67.9,61.9 67.9,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,62 68,62 68,62 67.9,61.9 67.9,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,61 66.3,61.6 66.3,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,61 66.3,61.6 66.3,61.6"
        />
        <polygon
          fill={colors[4]}
          points="66.9,61 66.4,61.4 66.3,61.5 66.3,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,61 66.4,61.4 66.3,61.5 66.3,61.6"
        />
        <polygon fill="#CCC" points="66.4,61.4 66.9,61 66.8,60.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.4,61.4 66.9,61 66.8,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,62.6 66.2,62 66.2,62"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,62.6 66.2,62 66.2,62"
        />
        <polygon fill={colors[3]} points="66.9,62.9 66.8,62.9 66.8,62.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,62.9 66.8,62.9 66.8,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,62.9 66.8,62.9 66.8,62.9"
        />
        <polygon fill={colors[4]} points="66.9,63 66.9,62.9 66.9,62.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,63 66.9,62.9 66.9,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,63 66.9,62.9 66.9,62.9"
        />
        <polygon fill="#CCC" points="67,63 67,63 66.9,63 66.9,63" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,63 67,63 66.9,63 66.9,63"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,63 67,63 66.9,63 66.9,63"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,62.9 67.1,62.9 67,62.7 67,62.7 66.9,62.6 66.9,62.6 66.9,62.6 66.8,62.6 66.8,62.6 66.9,62.6 66.9,62.6 66.9,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,62.9 67.1,62.9 67,62.7 67,62.7 66.9,62.6 66.9,62.6 66.9,62.6 66.8,62.6 66.8,62.6 66.9,62.6 66.9,62.6 66.9,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,62.9 67.1,62.9 67,62.7 67,62.7 66.9,62.6 66.9,62.6 66.9,62.6 66.8,62.6 66.8,62.6 66.9,62.6 66.9,62.6 66.9,62.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,62.9 67.1,62.9 67,62.7 67,62.7 66.9,62.6 66.9,62.6 66.9,62.6 66.8,62.6 66.8,62.6 66.9,62.6 66.9,62.6 66.9,62.6"
        />
        <polygon
          fill={colors[3]}
          points="66.9,62.9 66.9,62.9 66.9,62.9 66.8,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,62.9 66.9,62.9 66.9,62.9 66.8,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,62.9 66.9,62.9 66.9,62.9 66.8,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,62.9 66.9,62.9 66.9,62.9 66.8,62.9"
        />
        <polygon
          fill={colors[4]}
          points="66.9,63 66.9,62.8 67.1,62.9 67.1,62.9 66.9,62.6 66.9,62.7 67,62.8 66.9,62.9 66.9,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,63 66.9,62.8 67.1,62.9 67.1,62.9 66.9,62.6 66.9,62.7 67,62.8 66.9,62.9 66.9,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,63 66.9,62.8 67.1,62.9 67.1,62.9 66.9,62.6 66.9,62.7 67,62.8 66.9,62.9 66.9,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,63 66.9,62.8 67.1,62.9 67.1,62.9 66.9,62.6 66.9,62.7 67,62.8 66.9,62.9 66.9,62.9"
        />
        <polygon fill="#CCC" points="67.1,62.9 66.9,62.8 66.9,63 67,63" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,62.9 66.9,62.8 66.9,63 67,63"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,62.9 66.9,62.8 66.9,63 67,63"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,62.9 66.9,62.8 66.9,63 67,63"
        />
        <polygon
          fill={colors[3]}
          points="67,62.7 67,62.7 67.1,62.9 67.2,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,62.7 67,62.7 67.1,62.9 67.2,62.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,62.7 67,62.7 67.1,62.9 67.2,62.9"
        />
        <polygon fill={colors[4]} points="67.2,62.9 67,62.7 67,62.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,62.9 67,62.7 67,62.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,62.9 67,62.7 67,62.7"
        />
        <polygon
          fill={colors[4]}
          points="66.9,62.6 66.8,62.6 66.8,62.6 66.2,62 66.2,62.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,62.6 66.8,62.6 66.8,62.6 66.2,62 66.2,62.1"
        />
        <polygon
          fill="#CCC"
          points="66.8,62.9 66.9,62.9 66.9,62.9 67,62.8 66.9,62.7 66.9,62.6 66.9,62.6 66.2,62.1 66.2,62.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,62.9 66.9,62.9 66.9,62.9 67,62.8 66.9,62.7 66.9,62.6 66.9,62.6 66.2,62.1 66.2,62.2"
        />
        <polygon
          fill={colors[3]}
          points="66.8,62.6 66.9,62.6 66.9,62.6 67,62.7 67,61.1 66.9,61 66.3,61.6 66.3,61.6 66.9,61.1 66.9,62.5 66.2,61.9 66.2,62"
        />
        <polygon
          fill={colors[4]}
          points="67,62.7 67,62.7 67.8,62 67.8,61.9 67,62.5 67,61.1 67,61.1"
        />
        <polygon fill="#CCC" points="67.8,61.9 67.8,61.9 67,61.1 67,62.5" />
        <polygon
          fill={colors[4]}
          points="66.9,62.5 66.9,61.1 66.3,61.6 66.2,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,60.9 67.2,60.9 67.7,61.5 67.7,61.5"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,61.5 67.7,61.5 67.8,61.6 67.8,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.7,61.5 67.7,61.5 67.8,61.6 67.8,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,61.7 68,61.7 67.9,61.7 67.8,61.6 67.8,61.6 68.1,61.9 68.1,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,61.7 68,61.7 67.9,61.7 67.8,61.6 67.8,61.6 68.1,61.9 68.1,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,61.7 68,61.7 67.9,61.7 67.8,61.6 67.8,61.6 68.1,61.9 68.1,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,60.9 69.1,60.9 69.1,60.9 69.1,61"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,60.9 69.1,60.9 69.1,60.9 69.1,61"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,60.9 69.1,60.9 69.1,60.9 69.1,61"
        />
        <polygon fill={colors[3]} points="68.1,61.9 68,61.7 68,61.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,61.9 68,61.7 68,61.7"
        />
        <polygon
          fill={colors[3]}
          points="68.7,61.3 68.7,61 68.7,61.1 68.7,61.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,61.3 68.7,61 68.7,61.1 68.7,61.4"
        />
        <polygon
          fill={colors[4]}
          points="68.8,61.2 68.8,60.9 68.7,61 68.7,61.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,61.2 68.8,60.9 68.7,61 68.7,61.3"
        />
        <polygon fill={colors[4]} points="69,61 69.1,61 69.1,60.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,61 69.1,61 69.1,60.9"
        />
        <polygon
          fill="#CCC"
          points="68.8,61.2 69,61 69,61 68.9,61.1 68.8,60.9 68.8,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,61.2 69,61 69,61 68.9,61.1 68.8,60.9 68.8,60.9"
        />
        <polygon
          fill={colors[4]}
          points="67.9,61.7 67.8,61.5 67.8,61.5 67.8,61.6 67.8,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,61.7 67.8,61.5 67.8,61.5 67.8,61.6 67.8,61.6"
        />
        <polygon
          fill={colors[4]}
          points="68.7,61.4 68.7,61.1 68,61.7 68.1,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,61.4 68.7,61.1 68,61.7 68.1,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,61 67.2,60.9 67.2,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,61 67.2,60.9 67.2,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,61.6 67.8,61.5 67.8,61.5 67.7,61.5 67.7,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,61.6 67.8,61.5 67.8,61.5 67.7,61.5 67.7,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,61.6 67.8,61.5 67.8,61.5 67.7,61.5 67.7,61.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,61.9 67.8,61.6 67.8,61.6 67.8,61.7 67.8,61.7 67.8,61.7 67.9,61.8 67.9,61.8 67.9,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,61.9 67.8,61.6 67.8,61.6 67.8,61.7 67.8,61.7 67.8,61.7 67.9,61.8 67.9,61.8 67.9,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,61.9 67.8,61.6 67.8,61.6 67.8,61.7 67.8,61.7 67.8,61.7 67.9,61.8 67.9,61.8 67.9,61.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,61.9 67.8,61.6 67.8,61.6 67.8,61.7 67.8,61.7 67.8,61.7 67.9,61.8 67.9,61.8 67.9,61.8"
        />
        <polygon
          fill={colors[4]}
          points="67.9,61.9 68,62 68.1,61.9 67.9,61.8 67.8,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,61.9 68,62 68.1,61.9 67.9,61.8 67.8,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,61.9 68,62 68.1,61.9 67.9,61.8 67.8,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,61.9 68,62 68.1,61.9 67.9,61.8 67.8,61.9"
        />
        <polygon fill="#CCC" points="68,62 67.9,61.9 67.9,61.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,62 67.9,61.9 67.9,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,62 67.9,61.9 67.9,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,62 67.9,61.9 67.9,61.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,61 67.2,60.9 67.1,61 67.1,61"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,61 67.2,60.9 67.1,61 67.1,61"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,61 67.2,60.9 67.1,61 67.1,61"
        />
        <polygon fill="#CCC" points="66.8,60.9 66.8,60.9 66.9,61 66.9,60.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,60.9 66.8,60.9 66.9,61 66.9,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.8,60.9 66.8,60.9 66.9,61 66.9,60.9"
        />
        <polygon fill={colors[3]} points="66.9,60.9 66.9,61 67,61.1 67,61" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,60.9 66.9,61 67,61.1 67,61"
        />
        <polygon fill={colors[4]} points="67,61.1 67,61.1 67,61.1 67,61" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,61.1 67,61.1 67,61.1 67,61"
        />
        <polygon
          fill={colors[4]}
          points="67.8,61.7 67.8,61.6 67.7,61.6 67.1,61 67.1,61 67,61.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,61.7 67.8,61.6 67.7,61.6 67.1,61 67.1,61 67,61.1"
        />
        <polygon
          fill="#CCC"
          points="67.8,61.9 67.9,61.8 67.9,61.8 67.9,61.8 67.8,61.7 67.8,61.7 67.8,61.7 67,61.1 67,61.1 67,61.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,61.9 67.9,61.8 67.9,61.8 67.9,61.8 67.8,61.7 67.8,61.7 67.8,61.7 67,61.1 67,61.1 67,61.1"
        />
        <polygon
          fill={colors[3]}
          points="67.8,61.5 67.9,61.7 68,61.7 68,60.1 67.9,60.2 67.9,61.5 67.2,60.9 67.2,60.9 67.2,61 67.7,61.5"
        />
        <polygon
          fill={colors[4]}
          points="68,61.7 68.7,61.1 68.7,61 68.8,60.9 68.8,60.9 68.8,60.9 68,61.5 68,60.2 68,60.1 68,60.1"
        />
        <polygon fill="#CCC" points="68,60.2 68,61.5 68.8,60.9" />
        <polygon fill={colors[4]} points="67.9,61.5 67.9,60.2 67.2,60.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,60 70,60 70,60 70,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,60 70,60 70,60 70,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,60 70,60 70,60 70,60"
        />
        <polygon fill={colors[3]} points="70.1,60 70.1,59.9 70,60" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,60 70.1,59.9 70,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,60 70.1,59.9 70,60"
        />
        <polygon
          fill={colors[3]}
          points="69.7,60.3 69.7,60 69.7,60.1 69.7,60.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,60.3 69.7,60 69.7,60.1 69.7,60.4"
        />
        <polygon
          fill={colors[4]}
          points="69.8,60.3 69.8,60 69.7,60 69.7,60 69.7,60.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,60.3 69.8,60 69.7,60 69.7,60 69.7,60.3"
        />
        <polygon fill={colors[4]} points="70,60.1 70,60 70,60 70,60" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70,60.1 70,60 70,60 70,60"
        />
        <polygon
          fill="#CCC"
          points="69.8,60 69.8,60.3 70,60.1 70,60 69.9,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,60 69.8,60.3 70,60.1 70,60 69.9,60.1"
        />
        <polygon
          fill={colors[4]}
          points="69.7,60.4 69.7,60.1 69,60.8 69.1,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,60.4 69.7,60.1 69,60.8 69.1,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,60 68.1,60 68.7,60.6 68.7,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,60 68.1,60 68.7,60.6 68.7,60.6"
        />
        <polygon fill={colors[4]} points="69.1,60.9 69,60.8 69,60.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,60.9 69,60.8 69,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,60.9 69,60.8 69,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,60.5 68.7,60.5 68.7,60.6 68.7,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,60.5 68.7,60.5 68.7,60.6 68.7,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,60.5 68.7,60.5 68.7,60.6 68.7,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,60.6 68.8,60.7 68.9,60.8 68.9,60.8 69.1,60.9 69.1,60.9 68.9,60.8 68.9,60.7 68.8,60.6 68.8,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,60.6 68.8,60.7 68.9,60.8 68.9,60.8 69.1,60.9 69.1,60.9 68.9,60.8 68.9,60.7 68.8,60.6 68.8,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,60.6 68.8,60.7 68.9,60.8 68.9,60.8 69.1,60.9 69.1,60.9 68.9,60.8 68.9,60.7 68.8,60.6 68.8,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.7,60.6 68.8,60.7 68.9,60.8 68.9,60.8 69.1,60.9 69.1,60.9 68.9,60.8 68.9,60.7 68.8,60.6 68.8,60.6"
        />
        <polygon fill={colors[3]} points="69.1,60.9 68.9,60.8 68.9,60.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,60.9 68.9,60.8 68.9,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,60.9 68.9,60.8 68.9,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,60.9 68.9,60.8 68.9,60.8"
        />
        <polygon
          fill={colors[4]}
          points="68.9,60.7 68.8,60.6 68.8,60.6 68.8,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,60.7 68.8,60.6 68.8,60.6 68.8,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,60.7 68.8,60.6 68.8,60.6 68.8,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,60.7 68.8,60.6 68.8,60.6 68.8,60.6"
        />
        <polygon
          fill={colors[4]}
          points="69,60.8 68.9,60.8 69.1,60.9 69.1,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,60.8 68.9,60.8 69.1,60.9 69.1,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,60.8 68.9,60.8 69.1,60.9 69.1,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,60.8 68.9,60.8 69.1,60.9 69.1,60.9"
        />
        <polygon
          fill={colors[4]}
          points="69.1,60.9 68.9,60.8 68.9,60.9 69,61"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,60.9 68.9,60.8 68.9,60.9 69,61"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,60.9 68.9,60.8 68.9,60.9 69,61"
        />
        <polygon
          fill="#CCC"
          points="69,61 68.9,60.9 68.8,60.9 68.8,60.9 68.9,61.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,61 68.9,60.9 68.8,60.9 68.8,60.9 68.9,61.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,61 68.9,60.9 68.8,60.9 68.8,60.9 68.9,61.1"
        />
        <polygon
          fill={colors[4]}
          points="68,60.2 68,60.1 68,60.1 68,60.1 68,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,60.2 68,60.1 68,60.1 68,60.1 68,60.1"
        />
        <polygon
          fill={colors[4]}
          points="68.8,60.7 68.7,60.6 68.7,60.6 68.7,60.6 68.1,60 68.1,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,60.7 68.7,60.6 68.7,60.6 68.7,60.6 68.1,60 68.1,60.1"
        />
        <polygon
          fill="#CCC"
          points="68,60.2 68.8,60.9 68.8,60.9 68.9,60.9 68.9,60.8 68.9,60.8 68.8,60.7 68.1,60.1 68,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,60.2 68.8,60.9 68.8,60.9 68.9,60.9 68.9,60.8 68.9,60.8 68.8,60.7 68.1,60.1 68,60.1"
        />
        <polygon
          fill={colors[3]}
          points="68.7,60.5 68.7,60.6 68.8,60.6 68.8,60.6 68.9,60.7 68.9,60.8 68.9,59.2 68.9,59.3 68.9,60.6 68.1,60 68.1,60"
        />
        <polygon
          fill={colors[4]}
          points="69,60.8 69,60.8 69.7,60.1 69.7,60 69.7,60 69.7,60 69,60.6 69,59.2 69,59.2 68.9,59.2 68.9,60.8 68.9,60.8"
        />
        <polygon fill="#CCC" points="69,60.6 69.7,60 69,59.2" />
        <polygon fill={colors[4]} points="68.9,60.6 68.9,59.3 68.1,60" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,59 68.8,59 68.1,59.8 68.1,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,59 68.8,59 68.1,59.8 68.1,59.7"
        />
        <polygon fill={colors[4]} points="68.1,60.1 68.1,60 68.1,60" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,60.1 68.1,60 68.1,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,60.1 68.1,60 68.1,60"
        />
        <polygon fill="#CCC" points="68.1,60.1 68.1,60.1 68,60.1 68,60.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,60.1 68.1,60.1 68,60.1 68,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,60.1 68.1,60.1 68,60.1 68,60.1"
        />
        <polygon fill={colors[3]} points="68.1,59.7 68.9,59.1 68.8,59" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,59.7 68.9,59.1 68.8,59"
        />
        <polygon fill={colors[3]} points="68.9,59.2 68.9,59.1 68.9,59.3" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,59.2 68.9,59.1 68.9,59.3"
        />
        <polygon fill={colors[4]} points="68.9,59.2 68.9,59.2 69,59.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,59.2 68.9,59.2 69,59.2"
        />
        <polygon
          fill={colors[4]}
          points="68.2,60 68.1,60 68.1,60 68.9,59.3 68.9,59.1 68.9,59.1 68.1,59.7 68.1,59.8 68,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.2,60 68.1,60 68.1,60 68.9,59.3 68.9,59.1 68.9,59.1 68.1,59.7 68.1,59.8 68,59.8"
        />
        <polygon
          fill={colors[3]}
          points="67.5,59.3 68,59.8 68,58.3 67.9,58.3 67.9,59.6"
        />
        <polygon
          fill={colors[4]}
          points="68,59.8 68,59.8 68.1,59.8 68.8,59 68.8,59 68.1,59.6 68.1,58.3 68,58.2 68,58.3"
        />
        <polygon
          fill="#CCC"
          points="68.8,59 68.1,58.3 68.1,59.6 68.8,59 68.7,59"
        />
        <polygon
          fill={colors[4]}
          points="67.9,59.6 67.9,58.3 67.2,59 67.5,59.3"
        />
        <polygon
          fill={colors[4]}
          points="68.1,58.3 68.1,58.2 68,58.2 68,58.2 68,58.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,58.3 68.1,58.2 68,58.2 68,58.2 68,58.2"
        />
        <polygon
          fill="#CCC"
          points="68.1,58.3 68.8,59 68.9,58.9 68.8,58.8 68.1,58.2 68.1,58.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,58.3 68.8,59 68.9,58.9 68.8,58.8 68.1,58.2 68.1,58.2"
        />
        <polygon
          fill={colors[4]}
          points="68.1,58.2 68.1,58.2 68,58.2 68,58.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,58.2 68.1,58.2 68,58.2 68,58.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.1,58.2 68.1,58.2 68,58.2 68,58.2"
        />
        <polygon
          fill={colors[3]}
          points="70.7,59.3 70.7,59 70.7,59 70.7,59 70.7,59.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,59.3 70.7,59 70.7,59 70.7,59 70.7,59.4"
        />
        <polygon
          fill={colors[4]}
          points="70.8,59.1 70.7,59 70.7,59.3 70.8,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,59.1 70.7,59 70.7,59.3 70.8,59.2"
        />
        <polygon
          fill="#CCC"
          points="70.8,59.2 70.8,59.1 70.8,59.2 70.9,59.2 70.9,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,59.2 70.8,59.1 70.8,59.2 70.9,59.2 70.9,59.2"
        />
        <polygon
          fill={colors[4]}
          points="70.7,59.4 70.7,59 69.9,59.8 70.1,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,59.4 70.7,59 69.9,59.8 70.1,59.9"
        />
        <polygon fill={colors[4]} points="70.9,59.1 71,59 71,59" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,59.1 71,59 71,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,59.1 71,59 71,59"
        />
        <polygon fill="#CCC" points="70.9,59.2 70.9,59.1 70.9,59.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,59.2 70.9,59.1 70.9,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,59.2 70.9,59.1 70.9,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,59 69.1,59 69.6,59.6 69.6,59.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,59 69.1,59 69.6,59.6 69.6,59.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,59.6 69.7,59.6 69.7,59.7 69.7,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,59.6 69.7,59.6 69.7,59.7 69.7,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,59.6 69.7,59.6 69.7,59.7 69.7,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,59.7 69.9,59.8 69.9,59.9 70,60 70,60 69.9,59.8 69.9,59.8 69.9,59.8 69.8,59.7 69.8,59.7 69.8,59.7 69.7,59.7 69.7,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,59.7 69.9,59.8 69.9,59.9 70,60 70,60 69.9,59.8 69.9,59.8 69.9,59.8 69.8,59.7 69.8,59.7 69.8,59.7 69.7,59.7 69.7,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,59.7 69.9,59.8 69.9,59.9 70,60 70,60 69.9,59.8 69.9,59.8 69.9,59.8 69.8,59.7 69.8,59.7 69.8,59.7 69.7,59.7 69.7,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,59.7 69.9,59.8 69.9,59.9 70,60 70,60 69.9,59.8 69.9,59.8 69.9,59.8 69.8,59.7 69.8,59.7 69.8,59.7 69.7,59.7 69.7,59.7"
        />
        <polygon fill={colors[3]} points="69.9,59.8 70,60 70.1,59.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,59.8 70,60 70.1,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,59.8 70,60 70.1,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,59.8 70,60 70.1,59.9"
        />
        <polygon
          fill={colors[4]}
          points="70.1,59.9 70.1,59.9 69.9,59.8 69.9,59.8 69.9,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,59.9 70.1,59.9 69.9,59.8 69.9,59.8 69.9,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,59.9 70.1,59.9 69.9,59.8 69.9,59.8 69.9,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,59.9 70.1,59.9 69.9,59.8 69.9,59.8 69.9,59.8"
        />
        <polygon fill={colors[4]} points="70,60 70,60 69.9,59.9 69.8,59.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70,60 70,60 69.9,59.9 69.8,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70,60 70,60 69.9,59.9 69.8,59.9"
        />
        <polygon fill={colors[4]} points="69.8,60 69.8,59.9 69.8,60 69.7,60" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,60 69.8,59.9 69.8,60 69.7,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,60 69.8,59.9 69.8,60 69.7,60"
        />
        <polygon
          fill="#CCC"
          points="69.9,60.1 70,60 69.8,59.9 69.8,59.9 69.8,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,60.1 70,60 69.8,59.9 69.8,59.9 69.8,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.9,60.1 70,60 69.8,59.9 69.8,59.9 69.8,60"
        />
        <polygon
          fill={colors[4]}
          points="69.8,59.7 69.7,59.7 69.7,59.6 69.1,59.1 69,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,59.7 69.7,59.7 69.7,59.6 69.1,59.1 69,59.1"
        />
        <polygon fill={colors[4]} points="69.8,60 69.7,60 69.7,60" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,60 69.7,60 69.7,60"
        />
        <polygon
          fill="#CCC"
          points="69,59.1 69,59.2 69,59.2 69.7,60 69.8,60 69.8,59.9 69.8,59.9 69.9,59.9 69.9,59.8 69.8,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,59.1 69,59.2 69,59.2 69.7,60 69.8,60 69.8,59.9 69.8,59.9 69.9,59.9 69.9,59.8 69.8,59.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,59.1 69.1,59 69.1,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,59.1 69.1,59 69.1,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,59.1 69.1,59 69.1,59"
        />
        <polygon
          fill={colors[3]}
          points="68.9,59.1 68.8,59 68.8,59 68.9,59.1 68.9,59.1 68.9,59.1 68.9,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,59.1 68.8,59 68.8,59 68.9,59.1 68.9,59.1 68.9,59.1 68.9,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,59.1 68.8,59 68.8,59 68.9,59.1 68.9,59.1 68.9,59.1 68.9,59.2"
        />
        <polygon
          fill={colors[4]}
          points="69,59.2 69,59.2 69,59.1 69,59.2 68.9,59.1 68.9,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,59.2 69,59.2 69,59.1 69,59.2 68.9,59.1 68.9,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,59.2 69,59.2 69,59.1 69,59.2 68.9,59.1 68.9,59.2"
        />
        <polygon
          fill={colors[4]}
          points="69.1,59.1 69.1,59.1 69,59.1 69,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,59.1 69.1,59.1 69,59.1 69,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,59.1 69.1,59.1 69,59.1 69,59.1"
        />
        <polygon fill="#CCC" points="69,59.1 69,59.1 69,59.1 69,59.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,59.1 69,59.1 69,59.1 69,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,59.1 69,59.1 69,59.1 69,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,58.8 69.8,58.1 69.8,58.1 69,58.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,58.8 69.8,58.1 69.8,58.1 69,58.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,59 69.1,59 69,58.9 68.9,58.9 69.1,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,59 69.1,59 69,58.9 68.9,58.9 69.1,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,59 69.1,59 69,58.9 68.9,58.9 69.1,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.1,59 69.1,59 69,58.9 68.9,58.9 69.1,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,58.8 68.9,58.8 68.9,58.9 68.9,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,58.8 68.9,58.8 68.9,58.9 68.9,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,58.8 68.9,58.8 68.9,58.9 68.9,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,58.8 68.9,58.8 68.9,58.9 68.9,58.9"
        />
        <polygon
          fill={colors[3]}
          points="68.9,59.1 68.9,58.9 68.9,59 68.8,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,59.1 68.9,58.9 68.9,59 68.8,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,59.1 68.9,58.9 68.9,59 68.8,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.9,59.1 68.9,58.9 68.9,59 68.8,59"
        />
        <polygon
          fill={colors[4]}
          points="69,59.2 69,59.1 69,59.1 69,59.1 69.1,59.1 68.9,58.9 68.9,58.9 68.9,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,59.2 69,59.1 69,59.1 69,59.1 69.1,59.1 68.9,58.9 68.9,58.9 68.9,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,59.2 69,59.1 69,59.1 69,59.1 69.1,59.1 68.9,58.9 68.9,58.9 68.9,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,59.2 69,59.1 69,59.1 69,59.1 69.1,59.1 68.9,58.9 68.9,58.9 68.9,59.1"
        />
        <polygon
          fill="#CCC"
          points="68.8,59 68.8,59 68.8,59 68.9,59 68.9,58.9 68.9,58.9 69,58.9 68.9,58.9 68.9,58.9 68.9,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,59 68.8,59 68.8,59 68.9,59 68.9,58.9 68.9,58.9 69,58.9 68.9,58.9 68.9,58.9 68.9,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68.8,59 68.8,59 68.8,59 68.9,59 68.9,58.9 68.9,58.9 69,58.9 68.9,58.9 68.9,58.9 68.9,58.9"
        />
        <polygon
          fill={colors[3]}
          points="69,58.9 69,58.8 68.9,58.8 68.9,58.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,58.9 69,58.8 68.9,58.8 68.9,58.8"
        />
        <polygon
          fill={colors[4]}
          points="69,58.9 69,58.8 69.8,58.1 69.7,58.1 69.3,58.5 69,58.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69,58.9 69,58.8 69.8,58.1 69.7,58.1 69.3,58.5 69,58.8"
        />
        <polygon fill="#CCC" points="69.7,58.1 69.7,58.1 69.7,58.1 69.3,58.5" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.7,58.1 69.7,58.1 69.7,58.1 69.3,58.5"
        />
        <polygon
          fill={colors[3]}
          points="69.8,59.6 69.1,59 69.1,59 69.1,59 69.7,59.6 69.7,59.7 69.8,59.7 69.8,59.7 69.9,59.8 69.9,58.2 69.8,58.2"
        />
        <polygon fill={colors[3]} points="69.8,58.2 69.8,58.1 69.1,58.8" />
        <polygon
          fill={colors[4]}
          points="69.9,59.8 69.9,59.8 69.9,59.8 70.7,59 70.7,59 70.7,59 70,59.6 70,58.3 69.9,58.2"
        />
        <polygon fill="#CCC" points="70,59.6 70.7,59 70,58.3" />
        <polygon
          fill={colors[4]}
          points="69,58.8 69,58.9 69,58.9 68.9,58.9 68.9,58.9 69,58.9 69.1,59 69.8,59.6 69.8,58.2 69.8,58.2 69.1,58.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,58.1 70,58.1 70.7,58.7 70.7,58.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,58.1 70,58.1 70.7,58.7 70.7,58.7"
        />
        <polygon fill={colors[4]} points="70.8,59.1 70.7,59 70.7,59" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,59.1 70.7,59 70.7,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,59.1 70.7,59 70.7,59"
        />
        <polygon fill="#CCC" points="70.8,59.2 70.9,59.2 70.8,59.1 70.8,59.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,59.2 70.9,59.2 70.8,59.1 70.8,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,59.2 70.9,59.2 70.8,59.1 70.8,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,58.8 70.8,58.9 70.9,58.9 70.9,58.9 71,59 71,59 70.9,58.9 70.9,58.9 70.8,58.9 70.7,58.7 70.7,58.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,58.8 70.8,58.9 70.9,58.9 70.9,58.9 71,59 71,59 70.9,58.9 70.9,58.9 70.8,58.9 70.7,58.7 70.7,58.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,58.8 70.8,58.9 70.9,58.9 70.9,58.9 71,59 71,59 70.9,58.9 70.9,58.9 70.8,58.9 70.7,58.7 70.7,58.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,58.8 70.8,58.9 70.9,58.9 70.9,58.9 71,59 71,59 70.9,58.9 70.9,58.9 70.8,58.9 70.7,58.7 70.7,58.7"
        />
        <polygon
          fill={colors[4]}
          points="70.8,59.1 70.8,59 70.9,59.1 71,59 70.9,58.9 70.7,59 70.7,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,59.1 70.8,59 70.9,59.1 71,59 70.9,58.9 70.7,59 70.7,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,59.1 70.8,59 70.9,59.1 71,59 70.9,58.9 70.7,59 70.7,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.8,59.1 70.8,59 70.9,59.1 71,59 70.9,58.9 70.7,59 70.7,59"
        />
        <polygon fill="#CCC" points="70.9,59.1 70.8,59 70.8,59.1 70.9,59.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,59.1 70.8,59 70.8,59.1 70.9,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,59.1 70.8,59 70.8,59.1 70.9,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,59.1 70.8,59 70.8,59.1 70.9,59.2"
        />
        <polygon fill={colors[3]} points="70.9,58.9 71,59 71,59" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,58.9 71,59 71,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.9,58.9 71,59 71,59"
        />
        <polygon
          fill={colors[4]}
          points="71,59 71,59 70.9,58.8 70.9,58.9 70.9,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71,59 71,59 70.9,58.8 70.9,58.9 70.9,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="71,59 71,59 70.9,58.8 70.9,58.9 70.9,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,58.1 70,58.1 70,58.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,58.1 70,58.1 70,58.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.1,58.1 70,58.1 70,58.1"
        />
        <polygon
          fill={colors[4]}
          points="69.8,58.1 69.8,58.1 69.8,58.1 69.7,58.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,58.1 69.8,58.1 69.8,58.1 69.7,58.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,58.1 69.8,58.1 69.8,58.1 69.7,58.1"
        />
        <polygon fill="#CCC" points="69.8,58.1 69.7,58.1 69.7,58.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,58.1 69.7,58.1 69.7,58.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,58.1 69.7,58.1 69.7,58.1"
        />
        <polygon
          fill={colors[3]}
          points="69.8,58.1 69.8,58.2 69.8,58.1 69.8,58.2 69.9,58.2 69.9,58.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,58.1 69.8,58.2 69.8,58.1 69.8,58.2 69.9,58.2 69.9,58.1"
        />
        <polygon
          fill="#CCC"
          points="70.7,59 70.7,59 70.7,59 70.9,58.9 70.9,58.9 70.8,58.9 70.7,58.8 70.7,58.8 70.7,58.8 70,58.1 70,58.3"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="70.7,59 70.7,59 70.7,59 70.9,58.9 70.9,58.9 70.8,58.9 70.7,58.8 70.7,58.8 70.7,58.8 70,58.1 70,58.3"
        />
        <polygon fill={colors[4]} points="69.8,58.1 69.8,58.2 69.8,58.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="69.8,58.1 69.8,58.2 69.8,58.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,60.8 67,60.8 67,60.8 67,60.8 66.5,60.3 66.5,60.3 66.9,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,60.8 67,60.8 67,60.8 67,60.8 66.5,60.3 66.5,60.3 66.9,60.7"
        />
        <polygon fill={colors[4]} points="66.5,60.3 66.5,60.4 66.9,60.7" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.5,60.3 66.5,60.4 66.9,60.7"
        />
        <polygon
          fill="#CCC"
          points="66.9,60.8 67,60.8 66.9,60.7 66.5,60.4 66.5,60.5 66.8,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,60.8 67,60.8 66.9,60.7 66.5,60.4 66.5,60.5 66.8,60.9"
        />
        <polygon fill="#CCC" points="67,60.8 67,60.8 66.9,60.8" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,60.8 67,60.8 66.9,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,60.8 67,60.8 66.9,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,60 67.8,60 67,60.8 67.1,60.8 67.2,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.8,60 67.8,60 67,60.8 67.1,60.8 67.2,60.7"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,60.8 67,60.8 67,60.8 67,60.8 67,60.8 67,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,60.8 67,60.8 67,60.8 67,60.8 67,60.8 67,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,60.8 67,60.8 67,60.8 67,60.8 67,60.8 67,60.8"
        />
        <polygon fill="#CCC" points="66.9,60.8 66.8,60.9 66.8,60.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,60.8 66.8,60.9 66.8,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,60.8 66.8,60.9 66.8,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,60.8 67,60.9 67.1,61 67.2,60.9 67.1,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,60.8 67,60.9 67.1,61 67.2,60.9 67.1,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,60.8 67,60.9 67.1,61 67.2,60.9 67.1,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,60.8 67,60.9 67.1,61 67.2,60.9 67.1,60.8"
        />
        <polygon
          fill="#CCC"
          points="66.9,60.9 66.9,60.9 66.9,60.9 67,60.9 67,60.9 67,60.8 67,60.8 66.9,60.8 66.8,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,60.9 66.9,60.9 66.9,60.9 67,60.9 67,60.9 67,60.8 67,60.8 66.9,60.8 66.8,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,60.9 66.9,60.9 66.9,60.9 67,60.9 67,60.9 67,60.8 67,60.8 66.9,60.8 66.8,60.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,60.9 66.9,60.9 66.9,60.9 67,60.9 67,60.9 67,60.8 67,60.8 66.9,60.8 66.8,60.9"
        />
        <polygon fill={colors[3]} points="67,60.9 66.9,60.9 66.9,60.9 67,61" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,60.9 66.9,60.9 66.9,60.9 67,61"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,60.9 66.9,60.9 66.9,60.9 67,61"
        />
        <polygon
          fill={colors[4]}
          points="67,61.1 67.1,61 67,60.9 67,60.9 67,61"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,61.1 67.1,61 67,60.9 67,60.9 67,61"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,61.1 67.1,61 67,60.9 67,60.9 67,61"
        />
        <polygon fill={colors[3]} points="68,60.1 67.9,60.1 67.9,60.2" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,60.1 67.9,60.1 67.9,60.2"
        />
        <polygon fill={colors[3]} points="67.2,60.7 67.9,60.1 67.8,60" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,60.7 67.9,60.1 67.8,60"
        />
        <polygon
          fill={colors[4]}
          points="67.2,60.9 67.9,60.2 67.9,60.1 67.9,60.1 67.2,60.7 67.1,60.8 67,60.8 67,60.8 67.1,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,60.9 67.9,60.2 67.9,60.1 67.9,60.1 67.2,60.7 67.1,60.8 67,60.8 67,60.8 67.1,60.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,60 67.8,60 67.8,60 67.8,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,60 67.8,60 67.8,60 67.8,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,60 67.8,60 67.8,60 67.8,60"
        />
        <polygon
          fill={colors[3]}
          points="68,60.1 67.9,60 67.8,60 67.9,60.1 67.9,60.1 67.9,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,60.1 67.9,60 67.8,60 67.9,60.1 67.9,60.1 67.9,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,60.1 67.9,60 67.8,60 67.9,60.1 67.9,60.1 67.9,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.2,59.1 67.9,59.7 67.9,59.8 67.9,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.2,59.1 67.9,59.7 67.9,59.8 67.9,59.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.9 68.1,60 68.1,60 68.1,60 68,59.9 67.9,60 67.8,60 67.9,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.9 68.1,60 68.1,60 68.1,60 68,59.9 67.9,60 67.8,60 67.9,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.9 68.1,60 68.1,60 68.1,60 68,59.9 67.9,60 67.8,60 67.9,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.9 68.1,60 68.1,60 68.1,60 68,59.9 67.9,60 67.8,60 67.9,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.8 68,59.8 68,59.9 68,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.8 68,59.8 68,59.9 68,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.8 68,59.8 68,59.9 68,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.8 68,59.8 68,59.9 68,59.9"
        />
        <polygon fill={colors[3]} points="68,59.9 67.9,60 68,60.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.9 67.9,60 68,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.9 67.9,60 68,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.9 67.9,60 68,60.1"
        />
        <polygon
          fill={colors[4]}
          points="68,60.1 68,60.1 68,60.1 68.1,60.1 68.1,60 68,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,60.1 68,60.1 68,60.1 68.1,60.1 68.1,60 68,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,60.1 68,60.1 68,60.1 68.1,60.1 68.1,60 68,59.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,60.1 68,60.1 68,60.1 68.1,60.1 68.1,60 68,59.9"
        />
        <polygon fill="#CCC" points="68,60.1 68,60.1 68.1,60.1" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,60.1 68,60.1 68.1,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,60.1 68,60.1 68.1,60.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,60.1 68,60.1 68.1,60.1"
        />
        <polygon
          fill={colors[4]}
          points="68,59.8 68,59.8 68,59.8 68,59.8 68,59.9 68.1,60 68.1,60 68.2,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.8 68,59.8 68,59.8 68,59.8 68,59.9 68.1,60 68.1,60 68.2,60"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="68,59.8 68,59.8 68,59.8 68,59.8 68,59.9 68.1,60 68.1,60 68.2,60"
        />
        <polygon
          fill={colors[3]}
          points="67.9,59.8 67.9,59.8 68,59.8 68,59.8 68,59.8 67.5,59.3 67.2,59 67.2,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.9,59.8 67.9,59.8 68,59.8 68,59.8 68,59.8 67.5,59.3 67.2,59 67.2,59"
        />
        <polygon fill={colors[4]} points="67.5,59.3 67.2,59 67.2,59" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.5,59.3 67.2,59 67.2,59"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.2,59 67.2,59.1 67.2,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.2,59 67.2,59.1 67.2,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.2,59 67.2,59.1 67.2,59.1"
        />
        <polygon
          fill={colors[4]}
          points="67.2,59 67.2,59 67.2,59 67.2,59 67.1,58.9 67.1,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.2,59 67.2,59 67.2,59 67.1,58.9 67.1,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.2,59 67.2,59 67.2,59 67.1,58.9 67.1,58.9"
        />
        <polygon
          fill={colors[3]}
          points="67.1,58.8 67.1,58.8 67,58.8 67,58.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,58.8 67.1,58.8 67,58.8 67,58.8"
        />
        <polygon
          fill={colors[4]}
          points="67.1,58.9 67.1,58.9 67.1,58.8 67.1,58.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,58.9 67.1,58.9 67.1,58.8 67.1,58.8"
        />
        <polygon
          fill={colors[3]}
          points="67,60.8 67,59.2 67,59.2 67,60.6 66.5,60.2 66.5,60.3"
        />
        <polygon
          fill={colors[4]}
          points="67.8,60 67.8,60 67.9,60 67.1,60.6 67.1,59.1 67,59.2 67,60.8 67,60.8"
        />
        <polygon
          fill={colors[4]}
          points="67.9,59.7 67.2,59.1 67.2,59.1 67.1,59.1"
        />
        <polygon
          fill="#CCC"
          points="68,59.8 68,59.8 67.9,59.8 67.9,59.8 67.9,59.7 67.1,59.1 67.1,59.1 67.1,60.6 67.9,60 68,59.9"
        />
        <polygon
          fill={colors[4]}
          points="67,59.2 66.6,59.6 66.5,60.2 67,60.6"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,59 66.7,59.2 66.7,59.3 66.9,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="66.9,59 66.7,59.2 66.7,59.3 66.9,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.2,59 67,58.9 67,58.9 67,58.9 67,58.9 67,58.9 66.9,59 66.9,59 66.9,59 67,58.9 67.2,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.2,59 67,58.9 67,58.9 67,58.9 67,58.9 67,58.9 66.9,59 66.9,59 66.9,59 67,58.9 67.2,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.2,59 67,58.9 67,58.9 67,58.9 67,58.9 67,58.9 66.9,59 66.9,59 66.9,59 67,58.9 67.2,59.1"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.2,59 67,58.9 67,58.9 67,58.9 67,58.9 67,58.9 66.9,59 66.9,59 66.9,59 67,58.9 67.2,59.1"
        />
        <polygon
          fill={colors[4]}
          points="67.2,59 67.1,58.9 67.1,58.9 67,58.9 67,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.1,58.9 67.1,58.9 67,58.9 67,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.1,58.9 67.1,58.9 67,58.9 67,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.2,59 67.1,58.9 67.1,58.9 67,58.9 67,58.9"
        />
        <polygon
          fill={colors[3]}
          points="67,58.9 67.1,58.9 67.1,58.8 67,58.8 67,58.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,58.9 67.1,58.9 67.1,58.8 67,58.8 67,58.8"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,58.9 67.1,58.9 67.1,58.8 67,58.8 67,58.8"
        />
        <polygon fill={colors[4]} points="67.1,58.8 67.1,58.9 67.1,58.9" />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,58.8 67.1,58.9 67.1,58.9"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,58.8 67.1,58.9 67.1,58.9"
        />
        <polygon
          fill={colors[3]}
          points="67,59.1 67,59.2 67,59.2 67,58.9 66.9,59 66.9,59.1 66.7,59.3 66.6,59.4"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,59.1 67,59.2 67,59.2 67,58.9 66.9,59 66.9,59.1 66.7,59.3 66.6,59.4"
        />
        <polygon
          fill={colors[4]}
          points="67.1,59.1 67.1,59.1 67.1,59.1 67.2,59.1 67,58.9 67,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67.1,59.1 67.1,59.1 67.1,59.1 67.2,59.1 67,58.9 67,59.2"
        />
        <polygon
          fill={colors[4]}
          points="67,59.1 66.6,59.4 66.6,59.6 67,59.2"
        />
        <polygon
          opacity="0.23"
          fill={colors[6]}
          points="67,59.1 66.6,59.4 66.6,59.6 67,59.2"
        />
        <polygon fill={colors[4]} points="66.9,58.7 66.8,58.7 66.9,58.8" />
        <polygon
          fill={colors[4]}
          points="66.9,59 66.9,59 66.7,59.2 66.7,59.2"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,70.3L70.7,70.3L70.7,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,70.3L70.7,70.3L70.7,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,70.3L70.7,70.3L70.7,70.3z"
        />
        <path fill={colors[4]} d="M70.6,70.4L70.6,70.4L70.6,70.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,70.4L70.6,70.4L70.6,70.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,70.4L70.6,70.4L70.6,70.4z"
        />
        <path fill="#CCC" d="M70.6,70.4L70.6,70.4L70.6,70.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,70.4L70.6,70.4L70.6,70.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,70.4L70.6,70.4L70.6,70.4z"
        />
        <path
          fill={colors[7]}
          d="M70.7,70.3L70.7,70.3L70.7,70.3L70.7,70.3L70.7,70.3z"
        />
        <path
          fill={colors[7]}
          d="M70.7,70.3L70.7,70.3L70.7,70.3L70.7,70.3L70.7,70.3z"
        />
        <path fill={colors[4]} d="M71.5,69.5L71.5,69.5L71.5,69.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,69.5L71.5,69.5L71.5,69.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,69.1L71.5,69.1L71.5,69.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,69.1L71.5,69.1L71.5,69.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,69.1L71.5,69.1L71.5,69.1z"
        />
        <path fill="#CCC" d="M70.7,68.5L70.7,68.5L70.7,68.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,68.5L70.7,68.5L70.7,68.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,68.5L70.7,68.5L70.7,68.5z"
        />
        <path fill={colors[4]} d="M70.5,68.5L70.5,68.5L70.5,68.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,68.5L70.5,68.5L70.5,68.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,68.5L70.5,68.5L70.5,68.5z"
        />
        <path
          fill={colors[7]}
          d="M70.5,68.4L70.5,68.4L70.5,68.4L70.5,68.4L70.5,68.4z"
        />
        <path
          fill={colors[7]}
          d="M70.5,68.4L70.5,68.4L70.5,68.4L70.5,68.4L70.5,68.4z"
        />
        <path fill="#CCC" d="M70.7,68.5L70.7,68.5L70.7,68.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,68.5L70.7,68.5L70.7,68.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,68.5L70.7,68.5L70.7,68.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,68.5L70.7,68.5L70.7,68.5z"
        />
        <path fill={colors[4]} d="M70.5,68.4L70.5,68.4L70.5,68.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,68.4L70.5,68.4L70.5,68.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,68.4L70.5,68.4L70.5,68.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,67.5L71.7,67.5L71.7,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,67.5L71.7,67.5L71.7,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,67.5L71.7,67.5L71.7,67.5z"
        />
        <path fill={colors[4]} d="M71.7,67.5L71.7,67.5L71.7,67.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,67.5L71.7,67.5L71.7,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,67.5L71.7,67.5L71.7,67.5z"
        />
        <path fill={colors[4]} d="M71.6,67.6L71.6,67.6L71.6,67.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,67.6L71.6,67.6L71.6,67.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,67.6L71.6,67.6L71.6,67.6z"
        />
        <path fill={colors[4]} d="M71.5,67.5L71.5,67.5L71.5,67.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,67.5L71.5,67.5L71.5,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,67.5L71.5,67.5L71.5,67.5z"
        />
        <path
          fill={colors[7]}
          d="M71.4,67.5L71.4,67.5L71.4,67.5L71.4,67.5L71.4,67.5z"
        />
        <path
          fill={colors[7]}
          d="M71.4,67.5L71.4,67.5L71.4,67.5L71.4,67.5L71.4,67.5z"
        />
        <path
          fill={colors[7]}
          d="M71.5,67.3L71.5,67.3L71.5,67.3L71.5,67.3L71.5,67.3z"
        />
        <path
          fill={colors[7]}
          d="M71.5,67.3L71.5,67.3L71.5,67.3L71.5,67.3L71.5,67.3z"
        />
        <path
          fill={colors[7]}
          d="M71.5,67.3L71.5,67.3L71.5,67.3L71.5,67.3L71.5,67.3z"
        />
        <path fill={colors[4]} d="M71.5,67.5L71.5,67.5L71.5,67.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,67.5L71.5,67.5L71.5,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,67.5L71.5,67.5L71.5,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,67.5L71.5,67.5L71.5,67.5z"
        />
        <path fill={colors[3]} d="M71.7,67.5L71.7,67.5L71.7,67.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,67.5L71.7,67.5L71.7,67.5z"
        />
        <path fill={colors[4]} d="M71.6,69.5L71.6,69.5L71.6,69.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,69.5L71.6,69.5L71.6,69.5z"
        />
        <path fill={colors[4]} d="M71.5,69.4L71.5,69.4L71.5,69.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,69.4L71.5,69.4L71.5,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,69.4L71.5,69.4L71.5,69.4z"
        />
        <path fill={colors[7]} d="M71.4,69.3L71.4,69.3L71.4,69.3z" />
        <path fill={colors[7]} d="M71.4,69.3L71.4,69.3L71.4,69.3z" />
        <path fill="#CCC" d="M71.6,69.4L71.6,69.4L71.6,69.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,69.4L71.6,69.4L71.6,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,69.4L71.6,69.4L71.6,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,69.4L71.6,69.4L71.6,69.4z"
        />
        <path fill={colors[4]} d="M71.4,69.3L71.4,69.3L71.4,69.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.4,69.3L71.4,69.3L71.4,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.4,69.3L71.4,69.3L71.4,69.3z"
        />
        <path
          fill={colors[7]}
          d="M72.4,68.2L72.4,68.2L72.4,68.2L72.4,68.2L72.4,68.2z"
        />
        <path
          fill={colors[7]}
          d="M72.4,68.2L72.4,68.2L72.4,68.2L72.4,68.2L72.4,68.2z"
        />
        <path
          fill={colors[7]}
          d="M72.4,68.2L72.4,68.2L72.4,68.2L72.4,68.2L72.4,68.2z"
        />
        <path fill={colors[4]} d="M72.5,68.2L72.5,68.2L72.5,68.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,68.2L72.5,68.2L72.5,68.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,68.2L72.5,68.2L72.5,68.2z"
        />
        <path fill={colors[4]} d="M72.5,68.2L72.5,68.2L72.5,68.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,68.2L72.5,68.2L72.5,68.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,68.2L72.5,68.2L72.5,68.2z"
        />
        <path fill={colors[4]} d="M72.4,68.4L72.4,68.4L72.4,68.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,68.4L72.4,68.4L72.4,68.4z"
        />
        <path
          fill={colors[7]}
          d="M70.4,70.3L70.4,70.3L70.4,70.3L70.4,70.3L70.4,70.3z"
        />
        <path
          fill={colors[7]}
          d="M70.4,70.3L70.4,70.3L70.4,70.3L70.4,70.3L70.4,70.3z"
        />
        <path
          fill={colors[7]}
          d="M70.4,70.3L70.4,70.3L70.4,70.3L70.4,70.3L70.4,70.3z"
        />
        <path fill={colors[4]} d="M70.5,70.4L70.5,70.4L70.5,70.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,70.4L70.5,70.4L70.5,70.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,70.4L70.5,70.4L70.5,70.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,70.1L70.5,70.1L70.5,70.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,70.1L70.5,70.1L70.5,70.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,70.1L70.5,70.1L70.5,70.1z"
        />
        <path fill="#CCC" d="M70.6,70.4L70.6,70.4L70.6,70.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,70.4L70.6,70.4L70.6,70.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,70.4L70.6,70.4L70.6,70.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,70.4L70.6,70.4L70.6,70.4z"
        />
        <path fill={colors[4]} d="M70.6,70.1L70.6,70.1L70.6,70.1z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,70.1L70.6,70.1L70.6,70.1z"
        />
        <path fill={colors[4]} d="M69.8,69.4l0.2,0.1L69.8,69.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,69.4l0.2,0.1L69.8,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,69.4L69.8,69.4L69.8,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,69.4L69.8,69.4L69.8,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,69.4L69.8,69.4L69.8,69.4z"
        />
        <path
          fill={colors[7]}
          d="M69.5,69.4C69.5,69.4,69.5,69.3,69.5,69.4C69.5,69.3,69.5,69.3,69.5,69.4C69.5,69.3,69.5,69.3,69.5,69.4 L69.5,69.4z"
        />
        <path
          fill={colors[7]}
          d="M69.5,69.4C69.5,69.4,69.5,69.3,69.5,69.4C69.5,69.3,69.5,69.3,69.5,69.4C69.5,69.3,69.5,69.3,69.5,69.4 L69.5,69.4z"
        />
        <path
          fill={colors[7]}
          d="M69.5,69.4C69.5,69.4,69.5,69.3,69.5,69.4C69.5,69.3,69.5,69.3,69.5,69.4C69.5,69.3,69.5,69.3,69.5,69.4 L69.5,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,69.3L69.5,69.3L69.5,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,69.3L69.5,69.3L69.5,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,69.3L69.5,69.3L69.5,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,69.3L69.5,69.3L69.5,69.3z"
        />
        <path fill={colors[3]} d="M69.8,69.3L69.8,69.3L69.8,69.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,69.3L69.8,69.3L69.8,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,69.3L69.8,69.3L69.8,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,69.3L69.8,69.3L69.8,69.3z"
        />
        <path fill={colors[4]} d="M69.7,69.2L69.7,69.2L69.7,69.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,69.2L69.7,69.2L69.7,69.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,69.2L69.7,69.2L69.7,69.2z"
        />
        <path fill="#CCC" d="M69.7,69.4L69.7,69.4L69.7,69.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,69.4L69.7,69.4L69.7,69.4z"
        />
        <path fill="#CCC" d="M72.3,70.2L72.3,70.2L72.3,70.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3,70.2L72.3,70.2L72.3,70.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,70.1L72.4,70.1L72.4,70.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,70.1L72.4,70.1L72.4,70.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,70.1L72.4,70.1L72.4,70.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4,69.1L73.4,69.1L73.4,69.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4,69.1L73.4,69.1L73.4,69.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4,69.1L73.4,69.1L73.4,69.1z"
        />
        <path fill={colors[3]} d="M73.4,69.1L73.4,69.1L73.4,69.1z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4,69.1L73.4,69.1L73.4,69.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,70.3L72.6,70.3L72.6,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,70.3L72.6,70.3L72.6,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,70.3L72.6,70.3L72.6,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3,70.3L72.3,70.3L72.3,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3,70.3L72.3,70.3L72.3,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3,70.3L72.3,70.3L72.3,70.3z"
        />
        <path fill={colors[4]} d="M72.3,70.3L72.3,70.3L72.3,70.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3,70.3L72.3,70.3L72.3,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.3,70.3L72.3,70.3L72.3,70.3z"
        />
        <path fill={colors[4]} d="M72.5,70.1L72.5,70.1L72.5,70.1z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,70.1L72.5,70.1L72.5,70.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,70.1L72.5,70.1L72.5,70.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,69.3L73.3,69.3L73.3,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,69.3L73.3,69.3L73.3,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,69.3L73.3,69.3L73.3,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,69.3L73.3,69.3L73.3,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,69.3L73.3,69.3L73.3,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,69.3L73.3,69.3L73.3,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,69.3L73.3,69.3L73.3,69.3z"
        />
        <path fill={colors[4]} d="M73.3,69.3L73.3,69.3L73.3,69.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,69.3L73.3,69.3L73.3,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,69.3L73.3,69.3L73.3,69.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,69.3L73.3,69.3L73.3,69.3z"
        />
        <path fill={colors[4]} d="M73.3,69.4L73.3,69.4L73.3,69.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,69.4L73.3,69.4L73.3,69.4z"
        />
        <path fill={colors[4]} d="M68.8,70.3l0.3,0.3L68.8,70.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,70.3l0.3,0.3L68.8,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,70.3L68.8,70.3L68.8,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,70.3L68.8,70.3L68.8,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,70.3L68.8,70.3L68.8,70.3z"
        />
        <path fill={colors[3]} d="M68.8,70.3L68.8,70.3L68.8,70.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,70.3L68.8,70.3L68.8,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,70.3L68.8,70.3L68.8,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,71.1L69.5,71.1L69.5,71.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,71.1L69.5,71.1L69.5,71.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,71.1L69.5,71.1L69.5,71.1z"
        />
        <path fill={colors[4]} d="M68.7,70.1L68.7,70.1L68.7,70.1z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,70.1L68.7,70.1L68.7,70.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.5,70.3L68.5,70.3L68.5,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.5,70.3L68.5,70.3L68.5,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.5,70.3L68.5,70.3L68.5,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.6,70.1L68.6,70.1L68.6,70.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.6,70.1L68.6,70.1L68.6,70.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.6,70.1L68.6,70.1L68.6,70.1z"
        />
        <path fill={colors[3]} d="M68.8,70.3L68.8,70.3L68.8,70.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,70.3L68.8,70.3L68.8,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,70.3L68.8,70.3L68.8,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,70.3L68.8,70.3L68.8,70.3z"
        />
        <path fill={colors[4]} d="M68.7,70.1L68.7,70.1L68.7,70.1z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,70.1L68.7,70.1L68.7,70.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,70.1L68.7,70.1L68.7,70.1z"
        />
        <path fill="#CCC" d="M68.7,70.4L68.7,70.4L68.7,70.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,70.4L68.7,70.4L68.7,70.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.5,71.1L73.5,71.1L73.5,71.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.5,71.1L73.5,71.1L73.5,71.1z"
        />
        <path
          fill={colors[7]}
          d="M67.9,69.3L67.9,69.3L67.9,69.3L67.9,69.3L67.9,69.3z"
        />
        <path
          fill={colors[7]}
          d="M67.9,69.3L67.9,69.3L67.9,69.3L67.9,69.3L67.9,69.3z"
        />
        <path
          fill={colors[7]}
          d="M67.9,69.4L67.9,69.4L67.9,69.4L67.9,69.4L67.9,69.4z"
        />
        <path
          fill={colors[7]}
          d="M67.9,69.4L67.9,69.4L67.9,69.4L67.9,69.4L67.9,69.4z"
        />
        <path
          fill={colors[7]}
          d="M67.9,69.4L67.9,69.4L67.9,69.4L67.9,69.4L67.9,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,68.4L68.8,68.4L68.8,68.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,68.4L68.8,68.4L68.8,68.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,68.4L68.8,68.4L68.8,68.4z"
        />
        <path fill={colors[7]} d="M68.5,68.4L68.5,68.4L68.5,68.4z" />
        <path fill={colors[7]} d="M68.5,68.4L68.5,68.4L68.5,68.4z" />
        <path fill={colors[7]} d="M68.5,68.4L68.5,68.4L68.5,68.4z" />
        <path fill="#CCC" d="M68.7,68.5L68.7,68.5L68.7,68.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,68.5L68.7,68.5L68.7,68.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6,69.2L67.6,69.2L67.6,69.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6,69.2L67.6,69.2L67.6,69.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6,69.2L67.6,69.2L67.6,69.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,68.4L66.9,68.4L66.9,68.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,68.4L66.9,68.4L66.9,68.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,68.4L66.9,68.4L66.9,68.4z"
        />
        <path fill="#CCC" d="M66.8,68.5L66.8,68.5L66.8,68.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,68.5L66.8,68.5L66.8,68.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,68.5L66.8,68.5L66.8,68.5z"
        />
        <path fill={colors[3]} d="M67.7,67.6L67.7,67.6L67.7,67.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,67.6L67.7,67.6L67.7,67.6z"
        />
        <path fill={colors[7]} d="M66.9,68.4L66.9,68.4L66.9,68.4z" />
        <path fill={colors[7]} d="M66.9,68.4L66.9,68.4L66.9,68.4z" />
        <path fill={colors[4]} d="M67.7,67.6L67.7,67.6L67.7,67.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,67.6L67.7,67.6L67.7,67.6z"
        />
        <path fill={colors[4]} d="M66.8,66.7L66.8,66.7L66.8,66.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,66.7L66.8,66.7L66.8,66.7z"
        />
        <path fill={colors[4]} d="M66.8,66.7L66.8,66.7L66.8,66.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,66.7L66.8,66.7L66.8,66.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,66.7L66.8,66.7L66.8,66.7z"
        />
        <path fill={colors[4]} d="M66.9,66.6L66.9,66.6L66.9,66.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,66.6L66.9,66.6L66.9,66.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,66.6L66.9,66.6L66.9,66.6z"
        />
        <path fill={colors[7]} d="M67,66.5L67,66.5L67,66.5L67,66.5L67,66.5z" />
        <path fill={colors[7]} d="M67,66.5L67,66.5L67,66.5L67,66.5L67,66.5z" />
        <path fill={colors[4]} d="M66.9,64.8L66.9,64.8L66.9,64.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,64.8L66.9,64.8L66.9,64.8z"
        />
        <path opacity="0.23" fill={colors[6]} d="M67,64.7L67,64.7L67,64.7z" />
        <path opacity="0.23" fill={colors[6]} d="M67,64.7L67,64.7L67,64.7z" />
        <path opacity="0.23" fill={colors[6]} d="M67,64.7L67,64.7L67,64.7z" />
        <path fill={colors[4]} d="M66.8,64.7L66.8,64.7L66.8,64.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,64.7L66.8,64.7L66.8,64.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,64.7L66.8,64.7L66.8,64.7z"
        />
        <path
          fill={colors[7]}
          d="M66.7,64.6C66.7,64.6,66.7,64.7,66.7,64.6C66.7,64.7,66.7,64.7,66.7,64.6C66.7,64.7,66.7,64.7,66.7,64.6 L66.7,64.6z"
        />
        <path
          fill={colors[7]}
          d="M66.7,64.6C66.7,64.6,66.7,64.7,66.7,64.6C66.7,64.7,66.7,64.7,66.7,64.6C66.7,64.7,66.7,64.7,66.7,64.6 L66.7,64.6z"
        />
        <path fill="#CCC" d="M66.9,64.7L66.9,64.7L66.9,64.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,64.7L66.9,64.7L66.9,64.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,64.7L66.9,64.7L66.9,64.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,64.7L66.9,64.7L66.9,64.7z"
        />
        <path fill={colors[4]} d="M66.7,64.6L66.7,64.6L66.7,64.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7,64.6L66.7,64.6L66.7,64.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7,64.6L66.7,64.6L66.7,64.6z"
        />
        <path fill={colors[3]} d="M67,64.6L67,64.6L67,64.6z" />
        <path opacity="0.23" fill={colors[6]} d="M67,64.6L67,64.6L67,64.6z" />
        <path
          fill={colors[7]}
          d="M67.7,63.7L67.7,63.7C67.7,63.8,67.7,63.8,67.7,63.7C67.7,63.8,67.7,63.8,67.7,63.7L67.7,63.7z"
        />
        <path
          fill={colors[7]}
          d="M67.7,63.7L67.7,63.7C67.7,63.8,67.7,63.8,67.7,63.7C67.7,63.8,67.7,63.8,67.7,63.7L67.7,63.7z"
        />
        <path
          fill={colors[7]}
          d="M67.7,63.7L67.7,63.7C67.7,63.8,67.7,63.8,67.7,63.7C67.7,63.8,67.7,63.8,67.7,63.7L67.7,63.7z"
        />
        <path
          fill={colors[7]}
          d="M67.7,63.7L67.7,63.7L67.7,63.7L67.7,63.7L67.7,63.7z"
        />
        <path
          fill={colors[7]}
          d="M67.7,63.7L67.7,63.7L67.7,63.7L67.7,63.7L67.7,63.7z"
        />
        <path
          fill={colors[7]}
          d="M67.8,63.5L67.8,63.5C67.7,63.5,67.7,63.5,67.8,63.5C67.7,63.5,67.7,63.5,67.8,63.5 C67.7,63.5,67.8,63.5,67.8,63.5z"
        />
        <path
          fill={colors[7]}
          d="M67.8,63.5L67.8,63.5C67.7,63.5,67.7,63.5,67.8,63.5C67.7,63.5,67.7,63.5,67.8,63.5 C67.7,63.5,67.8,63.5,67.8,63.5z"
        />
        <path
          fill={colors[7]}
          d="M67.8,63.5L67.8,63.5C67.7,63.5,67.7,63.5,67.8,63.5C67.7,63.5,67.7,63.5,67.8,63.5 C67.7,63.5,67.8,63.5,67.8,63.5z"
        />
        <path fill={colors[4]} d="M67.7,63.7L67.7,63.7L67.7,63.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,63.7L67.7,63.7L67.7,63.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,63.7L67.7,63.7L67.7,63.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,63.7L67.7,63.7L67.7,63.7z"
        />
        <path fill={colors[4]} d="M67.7,63.7L67.7,63.7L67.7,63.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,63.7L67.7,63.7L67.7,63.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,63.7L67.7,63.7L67.7,63.7z"
        />
        <path fill={colors[3]} d="M68,63.7L68,63.7L68,63.7z" />
        <path opacity="0.23" fill={colors[6]} d="M68,63.7L68,63.7L68,63.7z" />
        <path fill="#CCC" d="M68.8,62.9L68.8,62.9L68.8,62.9z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,62.9L68.8,62.9L68.8,62.9z"
        />
        <path fill={colors[4]} d="M67.8,65.7L67.8,65.7L67.8,65.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,65.7L67.8,65.7L67.8,65.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9,65.6L67.9,65.6L67.9,65.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9,65.6L67.9,65.6L67.9,65.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9,65.6L67.9,65.6L67.9,65.6z"
        />
        <path fill={colors[4]} d="M67.7,65.6L67.7,65.6L67.7,65.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,65.6L67.7,65.6L67.7,65.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,65.6L67.7,65.6L67.7,65.6z"
        />
        <path fill={colors[7]} d="M67.6,65.6L67.6,65.6L67.6,65.6z" />
        <path fill={colors[7]} d="M67.6,65.6L67.6,65.6L67.6,65.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,65.4L67.7,65.4L67.7,65.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,65.4L67.7,65.4L67.7,65.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.7,65.4L67.7,65.4L67.7,65.4z"
        />
        <path fill={colors[4]} d="M67.6,65.6L67.6,65.6L67.6,65.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6,65.6L67.6,65.6L67.6,65.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6,65.6L67.6,65.6L67.6,65.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9,64.6L68.9,64.6L68.9,64.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9,64.6L68.9,64.6L68.9,64.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9,64.6L68.9,64.6L68.9,64.6z"
        />
        <path fill={colors[4]} d="M68.7,64.5L68.7,64.5L68.7,64.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,64.5L68.7,64.5L68.7,64.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,64.5L68.7,64.5L68.7,64.5z"
        />
        <path fill={colors[4]} d="M68.7,64.7L68.7,64.7L68.7,64.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,64.7L68.7,64.7L68.7,64.7z"
        />
        <path
          fill={colors[7]}
          d="M66.7,66.5L66.7,66.5C66.6,66.5,66.6,66.5,66.7,66.5C66.6,66.5,66.6,66.5,66.7,66.5L66.7,66.5z"
        />
        <path
          fill={colors[7]}
          d="M66.7,66.5L66.7,66.5C66.6,66.5,66.6,66.5,66.7,66.5C66.6,66.5,66.6,66.5,66.7,66.5L66.7,66.5z"
        />
        <path
          fill={colors[7]}
          d="M66.7,66.5L66.7,66.5C66.6,66.5,66.6,66.5,66.7,66.5C66.6,66.5,66.6,66.5,66.7,66.5L66.7,66.5z"
        />
        <path fill={colors[4]} d="M66.7,66.6L66.7,66.6L66.7,66.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7,66.6L66.7,66.6L66.7,66.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7,66.6L66.7,66.6L66.7,66.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7,66.5L66.7,66.5L66.7,66.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7,66.5L66.7,66.5L66.7,66.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7,66.5L66.7,66.5L66.7,66.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7,66.5L66.7,66.5L66.7,66.5z"
        />
        <path
          fill={colors[7]}
          d="M65.7,65.6L65.7,65.6L65.7,65.6L65.7,65.6L65.7,65.6z"
        />
        <path
          fill={colors[7]}
          d="M65.7,65.6L65.7,65.6L65.7,65.6L65.7,65.6L65.7,65.6z"
        />
        <path
          fill={colors[7]}
          d="M65.7,65.6L65.7,65.6L65.7,65.6L65.7,65.6L65.7,65.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.8,65.4L65.8,65.4L65.8,65.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.8,65.4L65.8,65.4L65.8,65.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.8,65.4L65.8,65.4L65.8,65.4z"
        />
        <path fill={colors[3]} d="M66,65.6L66,65.6L66,65.6z" />
        <path opacity="0.23" fill={colors[6]} d="M66,65.6L66,65.6L66,65.6z" />
        <path opacity="0.23" fill={colors[6]} d="M66,65.6L66,65.6L66,65.6z" />
        <path opacity="0.23" fill={colors[6]} d="M66,65.6L66,65.6L66,65.6z" />
        <path fill={colors[3]} d="M69.6,67.6L69.6,67.6L69.6,67.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,67.6L69.6,67.6L69.6,67.6z"
        />
        <path fill={colors[4]} d="M69.8,67.4L69.8,67.4L69.8,67.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,67.4L69.8,67.4L69.8,67.4z"
        />
        <path fill={colors[7]} d="M68.7,68.2L68.7,68.2L68.7,68.2z" />
        <path fill={colors[7]} d="M68.7,68.2L68.7,68.2L68.7,68.2z" />
        <path fill="#CCC" d="M68.7,68.5L68.7,68.5L68.7,68.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,68.5L68.7,68.5L68.7,68.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,68.5L68.7,68.5L68.7,68.5z"
        />
        <path fill={colors[4]} d="M68.5,68.4L68.5,68.4L68.5,68.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.5,68.4L68.5,68.4L68.5,68.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6,67.5L67.6,67.5L67.6,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6,67.5L67.6,67.5L67.6,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6,67.5L67.6,67.5L67.6,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9,67.5L67.9,67.5L67.9,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9,67.5L67.9,67.5L67.9,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9,67.5L67.9,67.5L67.9,67.5z"
        />
        <path fill={colors[4]} d="M67.6,67.5L67.6,67.5L67.6,67.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6,67.5L67.6,67.5L67.6,67.5z"
        />
        <path fill="#CCC" d="M67.8,67.5L67.8,67.5L67.8,67.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,67.5L67.8,67.5L67.8,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,67.5L67.8,67.5L67.8,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,67.5L67.8,67.5L67.8,67.5z"
        />
        <path fill={colors[4]} d="M67.6,67.4L67.6,67.4L67.6,67.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6,67.4L67.6,67.4L67.6,67.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.6,67.4L67.6,67.4L67.6,67.4z"
        />
        <path
          fill={colors[7]}
          d="M69.7,67.3L69.7,67.3L69.7,67.3L69.7,67.3L69.7,67.3z"
        />
        <path
          fill={colors[7]}
          d="M69.7,67.3L69.7,67.3L69.7,67.3L69.7,67.3L69.7,67.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,67.3L69.7,67.3L69.7,67.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,67.3L69.7,67.3L69.7,67.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,67.3L69.7,67.3L69.7,67.3z"
        />
        <path fill={colors[7]} d="M70.4,66.5L70.4,66.5L70.4,66.5z" />
        <path fill={colors[7]} d="M70.4,66.5L70.4,66.5L70.4,66.5z" />
        <path fill={colors[3]} d="M70.6,66.6L70.6,66.6L70.6,66.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,66.6L70.6,66.6L70.6,66.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,66.6L70.6,66.6L70.6,66.6z"
        />
        <path fill={colors[4]} d="M70.6,66.6L70.6,66.6L70.6,66.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,66.6L70.6,66.6L70.6,66.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,66.6L70.6,66.6L70.6,66.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,65.3L69.6,65.3L69.6,65.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,65.3L69.6,65.3L69.6,65.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,65.3L69.6,65.3L69.6,65.3z"
        />
        <path fill={colors[7]} d="M69.6,65.3L69.6,65.3L69.6,65.3z" />
        <path fill={colors[7]} d="M69.6,65.3L69.6,65.3L69.6,65.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,67.4L69.5,67.4L69.5,67.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,67.4L69.5,67.4L69.5,67.4z"
        />
        <path
          fill={colors[7]}
          d="M69.6,67.6L69.6,67.6L69.6,67.6L69.6,67.6L69.6,67.6z"
        />
        <path
          fill={colors[7]}
          d="M69.6,67.6L69.6,67.6L69.6,67.6L69.6,67.6L69.6,67.6z"
        />
        <path
          fill={colors[7]}
          d="M69.6,67.6L69.6,67.6L69.6,67.6L69.6,67.6L69.6,67.6z"
        />
        <path fill={colors[7]} d="M69.6,67.6L69.6,67.6L69.6,67.6z" />
        <path fill={colors[7]} d="M69.6,67.6L69.6,67.6L69.6,67.6z" />
        <path fill={colors[7]} d="M69.6,67.6L69.6,67.6L69.6,67.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,67.4L69.5,67.4L69.5,67.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,67.4L69.5,67.4L69.5,67.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,67.4L69.5,67.4L69.5,67.4z"
        />
        <path fill={colors[4]} d="M69.6,67.6L69.6,67.6L69.6,67.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,67.6L69.6,67.6L69.6,67.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,67.6L69.6,67.6L69.6,67.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,67.6L69.6,67.6L69.6,67.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,66.5L68.8,66.5L68.8,66.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,66.5L68.8,66.5L68.8,66.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,66.5L68.8,66.5L68.8,66.5z"
        />
        <path fill={colors[4]} d="M68.6,66.5L68.6,66.5L68.6,66.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.6,66.5L68.6,66.5L68.6,66.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.6,66.5L68.6,66.5L68.6,66.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.6,66.5L68.6,66.5L68.6,66.5z"
        />
        <path fill={colors[3]} d="M68.7,66.3L68.7,66.3L68.7,66.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,66.3L68.7,66.3L68.7,66.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,66.3L68.7,66.3L68.7,66.3z"
        />
        <path fill={colors[4]} d="M68.7,66.3L68.7,66.3L68.7,66.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,66.3L68.7,66.3L68.7,66.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.7,66.3L68.7,66.3L68.7,66.3z"
        />
        <path fill={colors[4]} d="M69.5,67.4L69.5,67.4L69.5,67.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,67.4L69.5,67.4L69.5,67.4z"
        />
        <path fill={colors[4]} d="M68.6,66.6L68.6,66.6L68.6,66.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.6,66.6L68.6,66.6L68.6,66.6z"
        />
        <path fill={colors[4]} d="M70.6,66.6L70.6,66.6L70.6,66.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,66.6L70.6,66.6L70.6,66.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,66.6L70.6,66.6L70.6,66.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,66.6L70.6,66.6L70.6,66.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,65.4L69.6,65.4L69.6,65.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,65.4L69.6,65.4L69.6,65.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,65.4L69.6,65.4L69.6,65.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,65.4L69.6,65.4L69.6,65.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,65.5L69.5,65.5L69.5,65.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,65.5L69.5,65.5L69.5,65.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,65.5L69.5,65.5L69.5,65.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,65.5L69.5,65.5L69.5,65.5z"
        />
        <path fill={colors[4]} d="M69.5,65.5L69.5,65.5L69.5,65.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,65.5L69.5,65.5L69.5,65.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,65.5L69.5,65.5L69.5,65.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.5,65.5L69.5,65.5L69.5,65.5z"
        />
        <path fill={colors[4]} d="M69.6,65.6L69.6,65.6L69.6,65.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,65.6L69.6,65.6L69.6,65.6z"
        />
        <path fill={colors[4]} d="M66.8,68.6L66.8,68.6L66.8,68.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,68.6L66.8,68.6L66.8,68.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6,68.4L66.6,68.4L66.6,68.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6,68.4L66.6,68.4L66.6,68.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6,68.4L66.6,68.4L66.6,68.4z"
        />
        <path
          fill={colors[7]}
          d="M66.8,68.5C66.8,68.5,66.8,68.6,66.8,68.5C66.8,68.6,66.8,68.6,66.8,68.5C66.8,68.6,66.8,68.6,66.8,68.5 C66.8,68.6,66.8,68.5,66.8,68.5z"
        />
        <path
          fill={colors[7]}
          d="M66.8,68.5C66.8,68.5,66.8,68.6,66.8,68.5C66.8,68.6,66.8,68.6,66.8,68.5C66.8,68.6,66.8,68.6,66.8,68.5 C66.8,68.6,66.8,68.5,66.8,68.5z"
        />
        <path
          fill={colors[7]}
          d="M66.8,68.5C66.8,68.5,66.8,68.6,66.8,68.5C66.8,68.6,66.8,68.6,66.8,68.5C66.8,68.6,66.8,68.6,66.8,68.5 C66.8,68.6,66.8,68.5,66.8,68.5z"
        />
        <path fill={colors[4]} d="M66.7,68.5L66.7,68.5L66.7,68.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7,68.5L66.7,68.5L66.7,68.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.7,68.5L66.7,68.5L66.7,68.5z"
        />
        <path
          fill={colors[7]}
          d="M66.7,68.2L66.7,68.2L66.7,68.2L66.7,68.2L66.7,68.2z"
        />
        <path
          fill={colors[7]}
          d="M66.7,68.2L66.7,68.2L66.7,68.2L66.7,68.2L66.7,68.2z"
        />
        <path
          fill={colors[7]}
          d="M66.7,68.2L66.7,68.2L66.7,68.2L66.7,68.2L66.7,68.2z"
        />
        <path fill="#CCC" d="M66.8,68.5L66.8,68.5L66.8,68.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,68.5L66.8,68.5L66.8,68.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,68.5L66.8,68.5L66.8,68.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,68.5L66.8,68.5L66.8,68.5z"
        />
        <path fill={colors[3]} d="M66.9,68.4L66.9,68.4L66.9,68.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,68.4L66.9,68.4L66.9,68.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,68.4L66.9,68.4L66.9,68.4z"
        />
        <path fill={colors[4]} d="M66.8,68.2L66.8,68.2L66.8,68.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,68.2L66.8,68.2L66.8,68.2z"
        />
        <path fill={colors[7]} d="M66,67.5L66,67.5L66,67.5L66,67.5L66,67.5z" />
        <path fill={colors[7]} d="M66,67.5L66,67.5L66,67.5L66,67.5L66,67.5z" />
        <path fill={colors[7]} d="M66,67.5L66,67.5L66,67.5L66,67.5L66,67.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.7,67.5L65.7,67.5L65.7,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.7,67.5L65.7,67.5L65.7,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.7,67.5L65.7,67.5L65.7,67.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.8,67.3L65.8,67.3L65.8,67.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.8,67.3L65.8,67.3L65.8,67.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.8,67.3L65.8,67.3L65.8,67.3z"
        />
        <path fill="#CCC" d="M65.9,67.5L65.9,67.5L65.9,67.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.9,67.5L65.9,67.5L65.9,67.5z"
        />
        <path fill="#CCC" d="M73.3,67.3L73.3,67.3L73.3,67.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.3,67.3L73.3,67.3L73.3,67.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.6,67.4L73.6,67.4L73.6,67.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.6,67.4L73.6,67.4L73.6,67.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.6,67.4L73.6,67.4L73.6,67.4z"
        />
        <path fill={colors[4]} d="M73.4,67.5L73.4,67.5L73.4,67.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4,67.5L73.4,67.5L73.4,67.5z"
        />
        <path fill="#CCC" d="M72.6,66.6L72.6,66.6L72.6,66.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,66.6L72.6,66.6L72.6,66.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,66.6L72.6,66.6L72.6,66.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.5,67.2L73.5,67.2L73.5,67.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.5,67.2L73.5,67.2L73.5,67.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.5,67.2L73.5,67.2L73.5,67.2z"
        />
        <path fill={colors[3]} d="M73.6,67.1L73.6,67.1L73.6,67.1z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.6,67.1L73.6,67.1L73.6,67.1z"
        />
        <path fill={colors[3]} d="M73.6,67.4L73.6,67.4L73.6,67.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.6,67.4L73.6,67.4L73.6,67.4z"
        />
        <path fill={colors[3]} d="M71.6,65.7L71.6,65.7L71.6,65.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,65.7L71.6,65.7L71.6,65.7z"
        />
        <path
          fill={colors[7]}
          d="M71.4,65.5L71.4,65.5L71.4,65.5L71.4,65.5L71.4,65.5z"
        />
        <path
          fill={colors[7]}
          d="M71.4,65.5L71.4,65.5L71.4,65.5L71.4,65.5L71.4,65.5z"
        />
        <path fill={colors[4]} d="M72.4,66.3L72.4,66.3L72.4,66.3z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,66.3L72.4,66.3L72.4,66.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,66.3L72.4,66.3L72.4,66.3z"
        />
        <path fill={colors[3]} d="M73.4,65.7L73.4,65.7L73.4,65.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M73.4,65.7L73.4,65.7L73.4,65.7z"
        />
        <path fill={colors[3]} d="M72.6,66.4L72.6,66.4L72.6,66.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,66.4L72.6,66.4L72.6,66.4z"
        />
        <path
          fill={colors[7]}
          d="M70.4,64.5L70.4,64.5C70.4,64.5,70.5,64.5,70.4,64.5C70.5,64.5,70.5,64.5,70.4,64.5L70.4,64.5z"
        />
        <path
          fill={colors[7]}
          d="M70.4,64.5L70.4,64.5C70.4,64.5,70.5,64.5,70.4,64.5C70.5,64.5,70.5,64.5,70.4,64.5L70.4,64.5z"
        />
        <path
          fill={colors[7]}
          d="M70.5,64.5C70.5,64.5,70.5,64.6,70.5,64.5C70.5,64.6,70.5,64.6,70.5,64.5C70.5,64.6,70.5,64.6,70.5,64.5 C70.5,64.6,70.5,64.5,70.5,64.5z"
        />
        <path
          fill={colors[7]}
          d="M70.5,64.5C70.5,64.5,70.5,64.6,70.5,64.5C70.5,64.6,70.5,64.6,70.5,64.5C70.5,64.6,70.5,64.6,70.5,64.5 C70.5,64.6,70.5,64.5,70.5,64.5z"
        />
        <path
          fill={colors[7]}
          d="M70.5,64.5C70.5,64.5,70.5,64.6,70.5,64.5C70.5,64.6,70.5,64.6,70.5,64.5C70.5,64.6,70.5,64.6,70.5,64.5 C70.5,64.6,70.5,64.5,70.5,64.5z"
        />
        <path
          fill={colors[7]}
          d="M70.5,64.6L70.5,64.6C70.5,64.5,70.5,64.5,70.5,64.6C70.5,64.5,70.5,64.6,70.5,64.6L70.5,64.6z"
        />
        <path
          fill={colors[7]}
          d="M70.5,64.6L70.5,64.6C70.5,64.5,70.5,64.5,70.5,64.6C70.5,64.5,70.5,64.6,70.5,64.6L70.5,64.6z"
        />
        <path
          fill={colors[7]}
          d="M70.5,64.6L70.5,64.6C70.5,64.5,70.5,64.5,70.5,64.6C70.5,64.5,70.5,64.6,70.5,64.6L70.5,64.6z"
        />
        <path
          fill={colors[7]}
          d="M72.7,64.5L72.7,64.5L72.7,64.5L72.7,64.5L72.7,64.5z"
        />
        <path
          fill={colors[7]}
          d="M72.7,64.5L72.7,64.5L72.7,64.5L72.7,64.5L72.7,64.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,65.4L71.6,65.4L71.6,65.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,65.4L71.6,65.4L71.6,65.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.4,65.5L71.4,65.5L71.4,65.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.4,65.5L71.4,65.5L71.4,65.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.4,65.5L71.4,65.5L71.4,65.5z"
        />
        <path
          fill={colors[7]}
          d="M72.7,64.5L72.7,64.5L72.7,64.5L72.7,64.5L72.7,64.5z"
        />
        <path
          fill={colors[7]}
          d="M72.7,64.5L72.7,64.5L72.7,64.5L72.7,64.5L72.7,64.5z"
        />
        <path
          fill={colors[7]}
          d="M72.7,64.5L72.7,64.5L72.7,64.5L72.7,64.5L72.7,64.5z"
        />
        <path fill={colors[4]} d="M72.7,64.5L72.7,64.5L72.7,64.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.7,64.5L72.7,64.5L72.7,64.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.7,64.5L72.7,64.5L72.7,64.5z"
        />
        <path fill={colors[7]} d="M72.6,64.4L72.6,64.4L72.6,64.4z" />
        <path fill={colors[7]} d="M72.6,64.4L72.6,64.4L72.6,64.4z" />
        <path fill={colors[7]} d="M72.6,64.4L72.6,64.4L72.6,64.4z" />
        <path fill={colors[3]} d="M72.7,64.5L72.7,64.5L72.7,64.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.7,64.5L72.7,64.5L72.7,64.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.7,64.5L72.7,64.5L72.7,64.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.7,64.5L72.7,64.5L72.7,64.5z"
        />
        <path fill={colors[4]} d="M72.4,64.5L72.4,64.5L72.4,64.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,64.5L72.4,64.5L72.4,64.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,63.6L71.7,63.6L71.7,63.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,63.6L71.7,63.6L71.7,63.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,63.6L71.7,63.6L71.7,63.6z"
        />
        <path fill={colors[4]} d="M71.6,63.7L71.6,63.7L71.6,63.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,63.7L71.6,63.7L71.6,63.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,63.7L71.6,63.7L71.6,63.7z"
        />
        <path fill={colors[7]} d="M71.7,63.6L71.7,63.6L71.7,63.6z" />
        <path fill={colors[7]} d="M71.7,63.6L71.7,63.6L71.7,63.6z" />
        <path fill={colors[4]} d="M72.5,62.8L72.5,62.8L72.5,62.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,62.8L72.5,62.8L72.5,62.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.8,61.7L71.8,61.7L71.8,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.8,61.7L71.8,61.7L71.8,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.8,61.7L71.8,61.7L71.8,61.7z"
        />
        <path fill="#CCC" d="M71.7,61.8L71.7,61.8L71.7,61.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,61.8L71.7,61.8L71.7,61.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,61.8L71.7,61.8L71.7,61.8z"
        />
        <path
          fill={colors[7]}
          d="M71.8,61.7L71.8,61.7L71.8,61.7L71.8,61.7L71.8,61.7z"
        />
        <path
          fill={colors[7]}
          d="M71.8,61.7L71.8,61.7L71.8,61.7L71.8,61.7L71.8,61.7z"
        />
        <path fill={colors[4]} d="M71.7,60L71.7,60L71.7,60z" />
        <path opacity="0.23" fill={colors[6]} d="M71.7,60L71.7,60L71.7,60z" />
        <path fill={colors[4]} d="M71.6,59.9L71.6,59.9L71.6,59.9z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,59.9L71.6,59.9L71.6,59.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,59.9L71.6,59.9L71.6,59.9z"
        />
        <path
          fill={colors[7]}
          d="M71.5,59.8L71.5,59.8L71.5,59.8L71.5,59.8L71.5,59.8z"
        />
        <path
          fill={colors[7]}
          d="M71.5,59.8L71.5,59.8L71.5,59.8L71.5,59.8L71.5,59.8z"
        />
        <path fill="#CCC" d="M71.5,59.8L71.5,59.8L71.5,59.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,59.8L71.5,59.8L71.5,59.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,59.6L71.6,59.6L71.6,59.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,59.6L71.6,59.6L71.6,59.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,59.6L71.6,59.6L71.6,59.6z"
        />
        <path fill={colors[3]} d="M71.8,59.8L71.8,59.8L71.8,59.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.8,59.8L71.8,59.8L71.8,59.8z"
        />
        <path
          fill={colors[3]}
          d="M71,59L71,59l0.6,0.6l0,0l0,0l0,0v-1.2c0,0,0,0-0.1,0v1.1L71,59z"
        />
        <path
          fill={colors[4]}
          d="M71.7,59.5v-1c0,0,0,0-0.1,0v1.2l0.3-0.3l0.3-0.3l0,0L71.7,59.5z"
        />
        <path
          fill="#CCC"
          d="M71.7,58.5v1l0.5-0.4V59C72.2,58.8,72.1,58.6,71.7,58.5z"
        />
        <path fill={colors[4]} d="M72.5,60.8L72.5,60.8L72.5,60.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,60.8L72.5,60.8L72.5,60.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,60.8L72.5,60.8L72.5,60.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,60.6L72.5,60.6L72.5,60.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,60.6L72.5,60.6L72.5,60.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,60.6L72.5,60.6L72.5,60.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,60.6L72.5,60.6L72.5,60.6z"
        />
        <path fill={colors[4]} d="M72.5,60.7L72.5,60.7L72.5,60.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,60.7L72.5,60.7L72.5,60.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,60.7L72.5,60.7L72.5,60.7z"
        />
        <path
          fill={colors[7]}
          d="M71.5,61.7L71.5,61.7L71.5,61.7L71.5,61.7L71.5,61.7z"
        />
        <path
          fill={colors[7]}
          d="M71.5,61.7L71.5,61.7L71.5,61.7L71.5,61.7L71.5,61.7z"
        />
        <path
          fill={colors[7]}
          d="M71.5,61.7L71.5,61.7L71.5,61.7L71.5,61.7L71.5,61.7z"
        />
        <path fill={colors[4]} d="M71.5,61.8L71.5,61.8L71.5,61.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,61.8L71.5,61.8L71.5,61.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,61.8L71.5,61.8L71.5,61.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,61.5L71.6,61.5L71.6,61.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,61.5L71.6,61.5L71.6,61.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,61.5L71.6,61.5L71.6,61.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,61.7L71.5,61.7L71.5,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,61.7L71.5,61.7L71.5,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,61.7L71.5,61.7L71.5,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,61.7L71.5,61.7L71.5,61.7z"
        />
        <path fill="#CCC" d="M71.7,61.8L71.7,61.8L71.7,61.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,61.8L71.7,61.8L71.7,61.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,61.8L71.7,61.8L71.7,61.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,61.8L71.7,61.8L71.7,61.8z"
        />
        <path fill={colors[4]} d="M70.9,60.8l0.2,0.1L70.9,60.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.9,60.8l0.2,0.1L70.9,60.8z"
        />
        <path fill={colors[4]} d="M70.1,60L70.1,60L70.1,60z" />
        <path opacity="0.23" fill={colors[6]} d="M70.1,60L70.1,60L70.1,60z" />
        <path
          fill={colors[7]}
          d="M70.5,60.7C70.5,60.7,70.5,60.8,70.5,60.7C70.5,60.8,70.5,60.8,70.5,60.7C70.5,60.8,70.5,60.8,70.5,60.7 L70.5,60.7z"
        />
        <path
          fill={colors[7]}
          d="M70.5,60.7C70.5,60.7,70.5,60.8,70.5,60.7C70.5,60.8,70.5,60.8,70.5,60.7C70.5,60.8,70.5,60.8,70.5,60.7 L70.5,60.7z"
        />
        <path
          fill={colors[7]}
          d="M70.5,60.7C70.5,60.7,70.5,60.8,70.5,60.7C70.5,60.8,70.5,60.8,70.5,60.7C70.5,60.8,70.5,60.8,70.5,60.7 L70.5,60.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,60.6L70.6,60.6L70.6,60.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,60.6L70.6,60.6L70.6,60.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,60.6L70.6,60.6L70.6,60.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,60.7L70.5,60.7L70.5,60.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,60.7L70.5,60.7L70.5,60.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,60.7L70.5,60.7L70.5,60.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,60.7L70.5,60.7L70.5,60.7z"
        />
        <path fill={colors[4]} d="M70.7,60.6L70.7,60.6L70.7,60.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,60.6L70.7,60.6L70.7,60.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,60.6L70.7,60.6L70.7,60.6z"
        />
        <path fill={colors[4]} d="M72.6,62.8L72.6,62.8L72.6,62.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,62.8L72.6,62.8L72.6,62.8z"
        />
        <path fill="#CCC" d="M72.6,62.7L72.6,62.7L72.6,62.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,62.7L72.6,62.7L72.6,62.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,62.7L72.6,62.7L72.6,62.7z"
        />
        <path fill={colors[4]} d="M72.5,62.7L72.5,62.7L72.5,62.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,62.7L72.5,62.7L72.5,62.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.5,62.7L72.5,62.7L72.5,62.7z"
        />
        <path fill={colors[4]} d="M72.4,62.6L72.4,62.6L72.4,62.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,62.6L72.4,62.6L72.4,62.6z"
        />
        <path fill="#CCC" d="M72.4,62.6L72.4,62.6L72.4,62.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,62.6L72.4,62.6L72.4,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,62.6L72.4,62.6L72.4,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,62.6L72.4,62.6L72.4,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,62.6L72.4,62.6L72.4,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,62.6L72.4,62.6L72.4,62.6z"
        />
        <path fill="#CCC" d="M72.6,62.7L72.6,62.7L72.6,62.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,62.7L72.6,62.7L72.6,62.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,62.7L72.6,62.7L72.6,62.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.6,62.7L72.6,62.7L72.6,62.7z"
        />
        <path fill={colors[4]} d="M72.4,62.6L72.4,62.6L72.4,62.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,62.6L72.4,62.6L72.4,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M72.4,62.6L72.4,62.6L72.4,62.6z"
        />
        <path fill={colors[4]} d="M69.6,63.7L69.6,63.7L69.6,63.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,63.7L69.6,63.7L69.6,63.7z"
        />
        <path
          fill={colors[7]}
          d="M70.6,64.4L70.6,64.4L70.6,64.4L70.6,64.4L70.6,64.4z"
        />
        <path
          fill={colors[7]}
          d="M70.6,64.4L70.6,64.4L70.6,64.4L70.6,64.4L70.6,64.4z"
        />
        <path
          fill={colors[7]}
          d="M70.6,64.4L70.6,64.4L70.6,64.4L70.6,64.4L70.6,64.4z"
        />
        <path fill={colors[4]} d="M70.5,64.5L70.5,64.5L70.5,64.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,64.5L70.5,64.5L70.5,64.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,64.5L70.5,64.5L70.5,64.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,64.5L70.5,64.5L70.5,64.5z"
        />
        <path fill="#CCC" d="M70.6,64.7L70.6,64.7L70.6,64.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,64.7L70.6,64.7L70.6,64.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,64.7L70.6,64.7L70.6,64.7z"
        />
        <path fill={colors[3]} d="M70.8,64.5L70.8,64.5L70.8,64.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.8,64.5L70.8,64.5L70.8,64.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.4,63.6L71.4,63.6L71.4,63.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.4,63.6L71.4,63.6L71.4,63.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.4,63.6L71.4,63.6L71.4,63.6z"
        />
        <path
          fill={colors[7]}
          d="M71.6,63.8C71.6,63.8,71.6,63.7,71.6,63.8C71.6,63.7,71.6,63.7,71.6,63.8C71.6,63.7,71.6,63.7,71.6,63.8 C71.6,63.7,71.6,63.8,71.6,63.8z"
        />
        <path
          fill={colors[7]}
          d="M71.6,63.8C71.6,63.8,71.6,63.7,71.6,63.8C71.6,63.7,71.6,63.7,71.6,63.8C71.6,63.7,71.6,63.7,71.6,63.8 C71.6,63.7,71.6,63.8,71.6,63.8z"
        />
        <path
          fill={colors[7]}
          d="M71.6,63.8C71.6,63.8,71.6,63.7,71.6,63.8C71.6,63.7,71.6,63.7,71.6,63.8C71.6,63.7,71.6,63.7,71.6,63.8 C71.6,63.7,71.6,63.8,71.6,63.8z"
        />
        <path fill={colors[4]} d="M71.5,63.7L71.5,63.7L71.5,63.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,63.7L71.5,63.7L71.5,63.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.5,63.7L71.5,63.7L71.5,63.7z"
        />
        <path
          fill={colors[7]}
          d="M71.5,63.4L71.5,63.4C71.6,63.4,71.6,63.4,71.5,63.4C71.6,63.4,71.6,63.4,71.5,63.4L71.5,63.4z"
        />
        <path
          fill={colors[7]}
          d="M71.5,63.4L71.5,63.4C71.6,63.4,71.6,63.4,71.5,63.4C71.6,63.4,71.6,63.4,71.5,63.4L71.5,63.4z"
        />
        <path
          fill={colors[7]}
          d="M71.5,63.4L71.5,63.4C71.6,63.4,71.6,63.4,71.5,63.4C71.6,63.4,71.6,63.4,71.5,63.4L71.5,63.4z"
        />
        <path fill={colors[3]} d="M71.7,63.6L71.7,63.6L71.7,63.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,63.6L71.7,63.6L71.7,63.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.7,63.6L71.7,63.6L71.7,63.6z"
        />
        <path fill={colors[4]} d="M71.6,63.4L71.6,63.4L71.6,63.4z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M71.6,63.4L71.6,63.4L71.6,63.4z"
        />
        <path fill={colors[4]} d="M70.8,62.6l0.3,0.3L70.8,62.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.8,62.6l0.3,0.3L70.8,62.6z"
        />
        <path
          fill={colors[7]}
          d="M70.8,62.6L70.8,62.6C70.8,62.6,70.8,62.7,70.8,62.6C70.8,62.7,70.8,62.7,70.8,62.6L70.8,62.6z"
        />
        <path
          fill={colors[7]}
          d="M70.8,62.6L70.8,62.6C70.8,62.6,70.8,62.7,70.8,62.6C70.8,62.7,70.8,62.7,70.8,62.6L70.8,62.6z"
        />
        <path
          fill={colors[7]}
          d="M70.8,62.6L70.8,62.6C70.8,62.6,70.8,62.7,70.8,62.6C70.8,62.7,70.8,62.7,70.8,62.6L70.8,62.6z"
        />
        <path fill={colors[4]} d="M69.9,61.7l0.3,0.3L69.9,61.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9,61.7l0.3,0.3L69.9,61.7z"
        />
        <path fill={colors[3]} d="M69.9,61.7L69.9,61.7L69.9,61.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9,61.7L69.9,61.7L69.9,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9,61.7L69.9,61.7L69.9,61.7z"
        />
        <path fill={colors[3]} d="M69.7,61.5L69.7,61.5L69.7,61.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,61.5L69.7,61.5L69.7,61.5z"
        />
        <path fill={colors[4]} d="M69.7,61.5L69.7,61.5L69.7,61.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,61.5L69.7,61.5L69.7,61.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,63.4L69.6,63.4L69.6,63.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,63.4L69.6,63.4L69.6,63.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,63.4L69.6,63.4L69.6,63.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,63.4L69.7,63.4L69.7,63.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,63.4L69.7,63.4L69.7,63.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,63.4L69.7,63.4L69.7,63.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,62.6L70.5,62.6L70.5,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,62.6L70.5,62.6L70.5,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.5,62.6L70.5,62.6L70.5,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,62.4L70.6,62.4L70.6,62.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,62.4L70.6,62.4L70.6,62.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.6,62.4L70.6,62.4L70.6,62.4z"
        />
        <path fill={colors[3]} d="M70.8,62.6L70.8,62.6L70.8,62.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.8,62.6L70.8,62.6L70.8,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.8,62.6L70.8,62.6L70.8,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.8,62.6L70.8,62.6L70.8,62.6z"
        />
        <path fill={colors[3]} d="M69.8,63.6L69.8,63.6L69.8,63.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,63.6L69.8,63.6L69.8,63.6z"
        />
        <path fill="#CCC" d="M70.7,62.7L70.7,62.7L70.7,62.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,62.7L70.7,62.7L70.7,62.7z"
        />
        <path fill={colors[4]} d="M68.6,62.5L68.6,62.5L68.6,62.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.6,62.5L68.6,62.5L68.6,62.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.6,62.5L68.6,62.5L68.6,62.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,61.5L69.6,61.5L69.6,61.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,61.5L69.6,61.5L69.6,61.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.6,61.5L69.6,61.5L69.6,61.5z"
        />
        <path fill={colors[3]} d="M69.8,61.7L69.8,61.7L69.8,61.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,61.7L69.8,61.7L69.8,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,61.7L69.8,61.7L69.8,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,61.7L69.8,61.7L69.8,61.7z"
        />
        <path fill={colors[3]} d="M68.9,62.6L68.9,62.6L68.9,62.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9,62.6L68.9,62.6L68.9,62.6z"
        />
        <path fill="#CCC" d="M69.7,61.8L69.7,61.8L69.7,61.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.7,61.8L69.7,61.8L69.7,61.8z"
        />
        <path opacity="0.23" fill={colors[6]} d="M66,69.4L66,69.4L66,69.4z" />
        <path opacity="0.23" fill={colors[6]} d="M66,69.4L66,69.4L66,69.4z" />
        <path fill={colors[3]} d="M66,69.4L66,69.4L66,69.4z" />
        <path opacity="0.23" fill={colors[6]} d="M66,69.4L66,69.4L66,69.4z" />
        <path fill={colors[4]} d="M66,69.4L66,69.4L66,69.4z" />
        <path opacity="0.23" fill={colors[6]} d="M66,69.4L66,69.4L66,69.4z" />
        <path fill={colors[4]} d="M65.8,69.2L65.8,69.2L65.8,69.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.8,69.2L65.8,69.2L65.8,69.2z"
        />
        <path fill={colors[3]} d="M64.8,70.1L64.8,70.1L64.8,70.1z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6,70.3L66.6,70.3L66.6,70.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.6,70.3L66.6,70.3L66.6,70.3z"
        />
        <path
          fill={colors[7]}
          d="M65.7,69.4L65.7,69.4L65.7,69.4L65.7,69.4L65.7,69.4z"
        />
        <path
          fill={colors[7]}
          d="M65.7,69.4L65.7,69.4L65.7,69.4L65.7,69.4L65.7,69.4z"
        />
        <path
          fill={colors[7]}
          d="M65.7,69.4L65.7,69.4L65.7,69.4L65.7,69.4L65.7,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.7,69.4L65.7,69.4L65.7,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.7,69.4L65.7,69.4L65.7,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.7,69.4L65.7,69.4L65.7,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.7,69.4L65.7,69.4L65.7,69.4z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.8,71.2L65.8,71.2L65.8,71.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M65.8,71.2L65.8,71.2L65.8,71.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.8,70.2L64.8,70.2L64.8,70.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.8,70.2L64.8,70.2L64.8,70.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.8,70.2L64.8,70.2L64.8,70.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.8,70.2L64.8,70.2L64.8,70.2z"
        />
        <path fill="#CCC" d="M64.8,70.2L64.8,70.2L64.8,70.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.8,70.2L64.8,70.2L64.8,70.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.8,70.2L64.8,70.2L64.8,70.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.8,70.2L64.8,70.2L64.8,70.2z"
        />
        <path fill={colors[3]} d="M64.8,70.2L64.8,70.2L64.8,70.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.8,70.2L64.8,70.2L64.8,70.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M64.8,70.2L64.8,70.2L64.8,70.2z"
        />
        <path fill={colors[3]} d="M66.2,63.8L66.2,63.8L66.2,63.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.2,63.8L66.2,63.8L66.2,63.8z"
        />
        <path
          fill={colors[7]}
          d="M66.2,63.8L66.2,63.8L66.2,63.8L66.2,63.8L66.2,63.8z"
        />
        <path
          fill={colors[7]}
          d="M66.2,63.8L66.2,63.8L66.2,63.8L66.2,63.8L66.2,63.8z"
        />
        <path
          fill={colors[7]}
          d="M67.2,62.8C67.2,62.8,67.2,62.9,67.2,62.8C67.2,62.9,67.2,62.9,67.2,62.8C67.2,62.9,67.2,62.9,67.2,62.8 C67.2,62.9,67.2,62.8,67.2,62.8z"
        />
        <path
          fill={colors[7]}
          d="M67.2,62.8C67.2,62.8,67.2,62.9,67.2,62.8C67.2,62.9,67.2,62.9,67.2,62.8C67.2,62.9,67.2,62.9,67.2,62.8 C67.2,62.9,67.2,62.8,67.2,62.8z"
        />
        <path fill={colors[7]} d="M65.9,63.8L65.9,63.8L65.9,63.8z" />
        <path fill={colors[7]} d="M65.9,63.8L65.9,63.8L65.9,63.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,61.9L68.1,61.9L68.1,61.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,61.9L68.1,61.9L68.1,61.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,61.9L68.1,61.9L68.1,61.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,62.6L66.8,62.6L66.8,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,62.6L66.8,62.6L66.8,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.8,62.6L66.8,62.6L66.8,62.6z"
        />
        <path fill={colors[3]} d="M66.9,62.6L66.9,62.6L66.9,62.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,62.6L66.9,62.6L66.9,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,62.6L66.9,62.6L66.9,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,62.6L66.9,62.6L66.9,62.6z"
        />
        <path fill={colors[4]} d="M66.9,62.6L66.9,62.6L66.9,62.6z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,62.6L66.9,62.6L66.9,62.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,62.6L66.9,62.6L66.9,62.6z"
        />
        <path
          fill={colors[7]}
          d="M66.8,62.9L66.8,62.9L66.8,62.9L66.8,62.9L66.8,62.9z"
        />
        <path
          fill={colors[7]}
          d="M66.8,62.9L66.8,62.9L66.8,62.9L66.8,62.9L66.8,62.9z"
        />
        <path
          fill={colors[7]}
          d="M69.1,60.9L69.1,60.9L69.1,60.9L69.1,60.9L69.1,60.9z"
        />
        <path
          fill={colors[7]}
          d="M69.1,60.9L69.1,60.9L69.1,60.9L69.1,60.9L69.1,60.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,61.6L67.8,61.6L67.8,61.6z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,61.6L67.8,61.6L67.8,61.6z"
        />
        <path fill={colors[3]} d="M69.1,60.9L69.1,60.9L69.1,60.9z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.1,60.9L69.1,60.9L69.1,60.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.1,60.9L69.1,60.9L69.1,60.9z"
        />
        <path
          fill={colors[7]}
          d="M69.1,60.9L69.1,60.9L69.1,60.9L69.1,60.9L69.1,60.9z"
        />
        <path
          fill={colors[7]}
          d="M69.1,60.9L69.1,60.9L69.1,60.9L69.1,60.9L69.1,60.9z"
        />
        <path
          fill={colors[7]}
          d="M69.1,60.9L69.1,60.9L69.1,60.9L69.1,60.9L69.1,60.9z"
        />
        <path
          fill={colors[7]}
          d="M66.8,60.9L66.8,60.9L66.8,60.9L66.8,60.9L66.8,60.9z"
        />
        <path
          fill={colors[7]}
          d="M66.8,60.9L66.8,60.9L66.8,60.9L66.8,60.9L66.8,60.9z"
        />
        <path fill={colors[3]} d="M67.8,61.7L67.8,61.7L67.8,61.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,61.7L67.8,61.7L67.8,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,61.7L67.8,61.7L67.8,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,61.7L67.8,61.7L67.8,61.7z"
        />
        <path fill={colors[4]} d="M67.8,61.7L67.8,61.7L67.8,61.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,61.7L67.8,61.7L67.8,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,61.7L67.8,61.7L67.8,61.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,61.7L67.8,61.7L67.8,61.7z"
        />
        <path fill={colors[4]} d="M67.8,61.5L67.8,61.5L67.8,61.5z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,61.5L67.8,61.5L67.8,61.5z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.8,61.5L67.8,61.5L67.8,61.5z"
        />
        <path
          fill={colors[7]}
          d="M66.9,60.9L66.9,60.9C66.9,61,66.9,61,66.9,60.9C66.9,61,66.9,61,66.9,60.9L66.9,60.9z"
        />
        <path
          fill={colors[7]}
          d="M66.9,60.9L66.9,60.9C66.9,61,66.9,61,66.9,60.9C66.9,61,66.9,61,66.9,60.9L66.9,60.9z"
        />
        <path
          fill={colors[7]}
          d="M66.9,60.9L66.9,60.9C66.9,61,66.9,61,66.9,60.9C66.9,61,66.9,61,66.9,60.9L66.9,60.9z"
        />
        <path
          fill={colors[7]}
          d="M66.9,60.9L66.9,60.9C66.9,61,66.9,61,66.9,60.9C66.9,61,66.9,61,66.9,60.9L66.9,60.9z"
        />
        <path
          fill={colors[7]}
          d="M66.9,60.9L66.9,60.9C66.9,61,66.9,61,66.9,60.9C66.9,61,66.9,61,66.9,60.9L66.9,60.9z"
        />
        <path
          fill={colors[7]}
          d="M66.9,60.9L66.9,60.9C66.9,61,66.9,61,66.9,60.9C66.9,61,66.9,61,66.9,60.9L66.9,60.9z"
        />
        <path
          fill={colors[7]}
          d="M70.1,60C70.1,60,70.1,59.9,70.1,60C70.1,59.9,70.1,59.9,70.1,60C70.1,59.9,70.1,60,70.1,60L70.1,60z"
        />
        <path
          fill={colors[7]}
          d="M70.1,60C70.1,60,70.1,59.9,70.1,60C70.1,59.9,70.1,59.9,70.1,60C70.1,59.9,70.1,60,70.1,60L70.1,60z"
        />
        <path
          fill={colors[7]}
          d="M70.1,60C70.1,60,70.1,59.9,70.1,60C70.1,59.9,70.1,59.9,70.1,60C70.1,59.9,70.1,60,70.1,60L70.1,60z"
        />
        <path fill={colors[4]} d="M68.8,60.9L68.8,60.9L68.8,60.9z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,60.9L68.8,60.9L68.8,60.9z"
        />
        <path opacity="0.23" fill={colors[6]} d="M68.1,60L68.1,60L68.1,60z" />
        <path opacity="0.23" fill={colors[6]} d="M68.1,60L68.1,60L68.1,60z" />
        <path opacity="0.23" fill={colors[6]} d="M68.1,60L68.1,60L68.1,60z" />
        <path fill={colors[4]} d="M68,60.1L68,60.1L68,60.1z" />
        <path opacity="0.23" fill={colors[6]} d="M68,60.1L68,60.1L68,60.1z" />
        <path opacity="0.23" fill={colors[6]} d="M68,60.1L68,60.1L68,60.1z" />
        <path fill={colors[7]} d="M68.1,60L68.1,60L68.1,60z" />
        <path fill={colors[7]} d="M68.1,60L68.1,60L68.1,60z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9,58.9L68.9,58.9l-0.7-0.7c0,0,0,0-0.1,0L68.9,58.9 L68.9,58.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9,58.9L68.9,58.9l-0.7-0.7c0,0,0,0-0.1,0L68.9,58.9 L68.9,58.9z"
        />
        <path fill={colors[4]} d="M68.8,58.8l-0.6-0.6l0,0l0,0L68.8,58.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.8,58.8l-0.6-0.6l0,0l0,0L68.8,58.8z"
        />
        <path
          fill={colors[3]}
          d="M68.9,58.8L68.9,58.8v-0.7c0,0,0,0-0.1,0v0.6l-0.7-0.5l0,0L68.9,58.8L68.9,58.8z"
        />
        <path
          fill={colors[4]}
          d="M69.3,58.5L69,58.7v-0.6c0,0,0,0-0.1,0v0.7L69.3,58.5z"
        />
        <path fill="#CCC" d="M69.3,58.5l0.4-0.4h-0.6l0,0v0.6L69.3,58.5z" />
        <path
          fill={colors[4]}
          d="M68.9,58.7v-0.6c-0.2,0-0.5,0-0.7,0.1L68.9,58.7z"
        />
        <path
          fill={colors[4]}
          d="M68.1,58.2L68.1,58.2L68.1,58.2L68.1,58.2L68.1,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,58.2L68.1,58.2L68.1,58.2L68.1,58.2L68.1,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,58.2L68.1,58.2L68.1,58.2L68.1,58.2L68.1,58.2z"
        />
        <path fill="#CCC" d="M68.1,58.2L68.1,58.2L68.1,58.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,58.2L68.1,58.2L68.1,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,58.2L68.1,58.2L68.1,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.2,58.8l0.5-0.5l0,0L67.2,58.8L67.2,58.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.2,58.8l0.5-0.5l0,0L67.2,58.8L67.2,58.8z"
        />
        <path
          fill={colors[3]}
          d="M67.9,58.2c-0.1,0-0.1,0-0.2,0l-0.5,0.5L67.9,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9,58.2c-0.1,0-0.1,0-0.2,0l-0.5,0.5L67.9,58.2z"
        />
        <path
          fill={colors[3]}
          d="M68,58.3L68,58.3L68,58.3C68,58.2,67.9,58.2,68,58.3L68,58.3L68,58.3z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68,58.3L68,58.3L68,58.3C68,58.2,67.9,58.2,68,58.3L68,58.3 L68,58.3z"
        />
        <path fill={colors[4]} d="M68,58.2L68,58.2L68,58.2z" />
        <path opacity="0.23" fill={colors[6]} d="M68,58.2L68,58.2L68,58.2z" />
        <path
          fill={colors[4]}
          d="M67.1,58.8L67.1,58.8l0.1,0.2l0.7-0.7v-0.1c0,0,0,0-0.1,0L67.1,58.8L67.1,58.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.1,58.8L67.1,58.8l0.1,0.2l0.7-0.7v-0.1c0,0,0,0-0.1,0 L67.1,58.8L67.1,58.8z"
        />
        <path fill={colors[3]} d="M68,58.2L68,58.2L68,58.2L68,58.2L68,58.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68,58.2L68,58.2L68,58.2L68,58.2L68,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68,58.2L68,58.2L68,58.2L68,58.2L68,58.2z"
        />
        <path fill={colors[4]} d="M68,58.2L68,58.2L68,58.2z" />
        <path opacity="0.23" fill={colors[6]} d="M68,58.2L68,58.2L68,58.2z" />
        <path opacity="0.23" fill={colors[6]} d="M68,58.2L68,58.2L68,58.2z" />
        <path fill={colors[3]} d="M68,58.2L68,58.2L68,58.2L68,58.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68,58.2L68,58.2L68,58.2L68,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68,58.2L68,58.2L68,58.2L68,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68,58.2L68,58.2L68,58.2L68,58.2z"
        />
        <path
          fill={colors[4]}
          d="M68,58.2L68,58.2L68,58.2L68,58.2L68,58.2L68,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68,58.2L68,58.2L68,58.2L68,58.2L68,58.2L68,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68,58.2L68,58.2L68,58.2L68,58.2L68,58.2L68,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68,58.2L68,58.2L68,58.2L68,58.2L68,58.2L68,58.2z"
        />
        <path fill={colors[4]} d="M68.1,58.2L68.1,58.2L68.1,58.2L68.1,58.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,58.2L68.1,58.2L68.1,58.2L68.1,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,58.2L68.1,58.2L68.1,58.2L68.1,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,58.2L68.1,58.2L68.1,58.2L68.1,58.2z"
        />
        <path fill="#CCC" d="M68.1,58.2L68.1,58.2L68.1,58.2z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,58.2L68.1,58.2L68.1,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,58.2L68.1,58.2L68.1,58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.1,58.2L68.1,58.2L68.1,58.2z"
        />
        <path
          fill={colors[3]}
          d="M67,58.8L67,58.8v-0.2c0,0,0,0-0.1,0v0.1h-0.1l0,0L67,58.8L67,58.8z"
        />
        <path
          fill={colors[4]}
          d="M67.1,58.8L67.1,58.8L67.1,58.8l0.6-0.6h-0.1l-0.5,0.4v-0.2c0,0,0,0-0.1,0L67.1,58.8L67.1,58.8z"
        />
        <path
          fill="#CCC"
          d="M67.6,58.3c-0.2,0.1-0.4,0.2-0.5,0.2v0.2L67.6,58.3z"
        />
        <path fill={colors[4]} d="M67,58.6L67,58.6L67,58.6L67,58.6z" />
        <path opacity="0.23" fill={colors[6]} d="M71,59L71,59L71,59z" />
        <path opacity="0.23" fill={colors[6]} d="M71,59L71,59L71,59z" />
        <path opacity="0.23" fill={colors[6]} d="M71,59L71,59L71,59z" />
        <path fill={colors[3]} d="M71,59L71,59L71,59z" />
        <path opacity="0.23" fill={colors[6]} d="M71,59L71,59L71,59z" />
        <path fill={colors[7]} d="M71,59L71,59L71,59L71,59L71,59z" />
        <path fill={colors[7]} d="M71,59L71,59L71,59L71,59L71,59z" />
        <path fill={colors[4]} d="M69.8,59.7L69.8,59.7L69.8,59.7z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,59.7L69.8,59.7L69.8,59.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,59.7L69.8,59.7L69.8,59.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,59.7L69.8,59.7L69.8,59.7z"
        />
        <path fill={colors[4]} d="M69,59.2L69,59.2L69,59.2z" />
        <path opacity="0.23" fill={colors[6]} d="M69,59.2L69,59.2L69,59.2z" />
        <path opacity="0.23" fill={colors[6]} d="M68.8,59L68.8,59L68.8,59z" />
        <path opacity="0.23" fill={colors[6]} d="M68.8,59L68.8,59L68.8,59z" />
        <path opacity="0.23" fill={colors[6]} d="M68.8,59L68.8,59L68.8,59z" />
        <path fill={colors[4]} d="M68.9,59.1L68.9,59.1L68.9,59.1z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9,59.1L68.9,59.1L68.9,59.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M68.9,59.1L68.9,59.1L68.9,59.1z"
        />
        <path fill={colors[4]} d="M68.8,59L68.8,59L68.8,59z" />
        <path opacity="0.23" fill={colors[6]} d="M68.8,59L68.8,59L68.8,59z" />
        <path fill="#CCC" d="M68.8,59L68.8,59L68.8,59z" />
        <path opacity="0.23" fill={colors[6]} d="M68.8,59L68.8,59L68.8,59z" />
        <path opacity="0.23" fill={colors[6]} d="M68.8,59L68.8,59L68.8,59z" />
        <path opacity="0.23" fill={colors[6]} d="M68.8,59L68.8,59L68.8,59z" />
        <path opacity="0.23" fill={colors[6]} d="M68.8,59L68.8,59L68.8,59z" />
        <path opacity="0.23" fill={colors[6]} d="M68.8,59L68.8,59L68.8,59z" />
        <path fill="#CCC" d="M69,59.1L69,59.1L69,59.1z" />
        <path opacity="0.23" fill={colors[6]} d="M69,59.1L69,59.1L69,59.1z" />
        <path opacity="0.23" fill={colors[6]} d="M69,59.1L69,59.1L69,59.1z" />
        <path opacity="0.23" fill={colors[6]} d="M69,59.1L69,59.1L69,59.1z" />
        <path fill={colors[4]} d="M68.8,59L68.8,59L68.8,59z" />
        <path opacity="0.23" fill={colors[6]} d="M68.8,59L68.8,59L68.8,59z" />
        <path opacity="0.23" fill={colors[6]} d="M68.8,59L68.8,59L68.8,59z" />
        <path
          fill={colors[4]}
          d="M70.9,58.8L71,59l0.6-0.6c-0.1,0-0.1-0.1-0.2-0.1L70.9,58.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.9,58.8L71,59l0.6-0.6c-0.1,0-0.1-0.1-0.2-0.1L70.9,58.8z"
        />
        <path fill={colors[3]} d="M70.7,59L70.7,59L70.7,59z" />
        <path opacity="0.23" fill={colors[6]} d="M70.7,59L70.7,59L70.7,59z" />
        <path opacity="0.23" fill={colors[6]} d="M70.7,59L70.7,59L70.7,59z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,58.7L70.7,58.7L70.7,58.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,58.7L70.7,58.7L70.7,58.7z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,58.7L70.7,58.7L70.7,58.7z"
        />
        <path fill={colors[3]} d="M70.7,59L70.7,59L70.7,59z" />
        <path opacity="0.23" fill={colors[6]} d="M70.7,59L70.7,59L70.7,59z" />
        <path opacity="0.23" fill={colors[6]} d="M70.7,59L70.7,59L70.7,59z" />
        <path opacity="0.23" fill={colors[6]} d="M70.7,59L70.7,59L70.7,59z" />
        <path fill={colors[3]} d="M70.7,58.8L70.7,58.8L70.7,58.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,58.8L70.7,58.8L70.7,58.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,58.8L70.7,58.8L70.7,58.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,58.8L70.7,58.8L70.7,58.8z"
        />
        <path fill={colors[4]} d="M70.7,58.8L70.7,58.8L70.7,58.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,58.8L70.7,58.8L70.7,58.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,58.8L70.7,58.8L70.7,58.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.7,58.8L70.7,58.8L70.7,58.8z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,58.1L69.8,58.1L69.8,58.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,58.1L69.8,58.1L69.8,58.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,58.1L69.8,58.1L69.8,58.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1,58.1L70.1,58.1C70,58.1,70,58.1,70.1,58.1L70.1,58.1 L70.1,58.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1,58.1L70.1,58.1C70,58.1,70,58.1,70.1,58.1L70.1,58.1 L70.1,58.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1,58.1L70.1,58.1C70,58.1,70,58.1,70.1,58.1L70.1,58.1 L70.1,58.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M70.1,58.1L70.1,58.1C70,58.1,70,58.1,70.1,58.1L70.1,58.1 L70.1,58.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,58.1L69.8,58.1L69.8,58.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,58.1L69.8,58.1L69.8,58.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,58.1L69.8,58.1L69.8,58.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.8,58.1L69.8,58.1L69.8,58.1z"
        />
        <path fill={colors[7]} d="M70.7,59L70.7,59L70.7,59L70.7,59L70.7,59z" />
        <path fill={colors[7]} d="M70.7,59L70.7,59L70.7,59L70.7,59L70.7,59z" />
        <path
          fill={colors[4]}
          d="M69.9,58.2L69.9,58.2L69.9,58.2l0.8,0.6l-0.1-0.1l0,0L70,58.1l0,0l0,0h-0.1l0,0V58.2z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M69.9,58.2L69.9,58.2L69.9,58.2l0.8,0.6l-0.1-0.1l0,0 L70,58.1l0,0l0,0h-0.1l0,0V58.2z"
        />
        <path
          fill={colors[3]}
          d="M70.1,58.1l0.6,0.6l0,0l0.2,0.2v-0.7c0,0,0,0-0.1,0v0.5L70.1,58.1C70,58.1,70,58.1,70.1,58.1L70.1,58.1 L70.1,58.1z"
        />
        <path
          fill={colors[4]}
          d="M70.8,58.9L70.8,58.9L70.8,58.9l0.6-0.6l0,0l-0.5,0.4v-0.5c0,0,0,0-0.1,0V58.9z"
        />
        <path
          fill="#CCC"
          d="M70.9,58.7l0.5-0.4c-0.1-0.1-0.3-0.1-0.5-0.1V58.7z"
        />
        <path
          fill={colors[4]}
          d="M70.8,58.7v-0.5c-0.2,0-0.5-0.1-0.7-0.1L70.8,58.7z"
        />
        <path fill={colors[7]} d="M67,60.8L67,60.8L67,60.8L67,60.8L67,60.8z" />
        <path fill={colors[7]} d="M67,60.8L67,60.8L67,60.8L67,60.8L67,60.8z" />
        <path fill={colors[7]} d="M67,60.8L67,60.8L67,60.8L67,60.8L67,60.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.2,60.9L67.2,60.9L67.2,60.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.2,60.9L67.2,60.9L67.2,60.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.2,60.9L67.2,60.9L67.2,60.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,60.9L66.9,60.9L66.9,60.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,60.9L66.9,60.9L66.9,60.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,60.9L66.9,60.9L66.9,60.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,60.9L66.9,60.9L66.9,60.9z"
        />
        <path fill={colors[4]} d="M66.9,60.9L66.9,60.9L66.9,60.9z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,60.9L66.9,60.9L66.9,60.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,60.9L66.9,60.9L66.9,60.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M66.9,60.9L66.9,60.9L66.9,60.9z"
        />
        <path fill="#CCC" d="M67,61.1L67,61.1L67,61.1z" />
        <path opacity="0.23" fill={colors[6]} d="M67,61.1L67,61.1L67,61.1z" />
        <path opacity="0.23" fill={colors[6]} d="M67,61.1L67,61.1L67,61.1z" />
        <path fill={colors[3]} d="M67.2,60.9L67.2,60.9L67.2,60.9z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.2,60.9L67.2,60.9L67.2,60.9z"
        />
        <path fill={colors[4]} d="M68,60.1L68,60.1L68,60.1z" />
        <path opacity="0.23" fill={colors[6]} d="M68,60.1L68,60.1L68,60.1z" />
        <path fill={colors[4]} d="M68,60.1L68,60.1L68,60.1z" />
        <path opacity="0.23" fill={colors[6]} d="M68,60.1L68,60.1L68,60.1z" />
        <path opacity="0.23" fill={colors[6]} d="M68,60.1L68,60.1L68,60.1z" />
        <path fill={colors[4]} d="M67.9,60.1L67.9,60.1L67.9,60.1z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9,60.1L67.9,60.1L67.9,60.1z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.9,60.1L67.9,60.1L67.9,60.1z"
        />
        <path
          fill={colors[7]}
          d="M68,59.8C68,59.8,67.9,59.8,68,59.8C67.9,59.8,67.9,59.8,68,59.8C67.9,59.8,67.9,59.8,68,59.8 C67.9,59.8,68,59.8,68,59.8z"
        />
        <path
          fill={colors[7]}
          d="M68,59.8C68,59.8,67.9,59.8,68,59.8C67.9,59.8,67.9,59.8,68,59.8C67.9,59.8,67.9,59.8,68,59.8 C67.9,59.8,68,59.8,68,59.8z"
        />
        <path
          fill={colors[7]}
          d="M68,59.8C68,59.8,67.9,59.8,68,59.8C67.9,59.8,67.9,59.8,68,59.8C67.9,59.8,67.9,59.8,68,59.8 C67.9,59.8,68,59.8,68,59.8z"
        />
        <path fill={colors[3]} d="M68.1,60L68.1,60L68.1,60z" />
        <path opacity="0.23" fill={colors[6]} d="M68.1,60L68.1,60L68.1,60z" />
        <path opacity="0.23" fill={colors[6]} d="M68.1,60L68.1,60L68.1,60z" />
        <path fill={colors[4]} d="M68,59.8L68,59.8L68,59.8z" />
        <path opacity="0.23" fill={colors[6]} d="M68,59.8L68,59.8L68,59.8z" />
        <path fill={colors[7]} d="M67.2,59L67.2,59L67.2,59L67.2,59L67.2,59z" />
        <path fill={colors[7]} d="M67.2,59L67.2,59L67.2,59L67.2,59L67.2,59z" />
        <path fill={colors[7]} d="M67.2,59L67.2,59L67.2,59L67.2,59L67.2,59z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67,58.9L67,58.9L67,58.9L67,58.9L67,58.9L67,58.9z"
        />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67,58.9L67,58.9L67,58.9L67,58.9L67,58.9L67,58.9z"
        />
        <path fill={colors[3]} d="M67,58.8L67,58.8L67,58.8L67,58.8L67,58.8z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67,58.8L67,58.8L67,58.8L67,58.8L67,58.8z"
        />
        <path opacity="0.23" fill={colors[6]} d="M66.9,59L66.9,59L66.9,59z" />
        <path opacity="0.23" fill={colors[6]} d="M66.9,59L66.9,59L66.9,59z" />
        <path opacity="0.23" fill={colors[6]} d="M66.9,59L66.9,59L66.9,59z" />
        <path opacity="0.23" fill={colors[6]} d="M67,58.8L67,58.8L67,58.8z" />
        <path opacity="0.23" fill={colors[6]} d="M67,58.8L67,58.8L67,58.8z" />
        <path opacity="0.23" fill={colors[6]} d="M67,58.8L67,58.8L67,58.8z" />
        <path fill={colors[3]} d="M67.2,59L67.2,59L67.2,59z" />
        <path opacity="0.23" fill={colors[6]} d="M67.2,59L67.2,59L67.2,59z" />
        <path opacity="0.23" fill={colors[6]} d="M67.2,59L67.2,59L67.2,59z" />
        <path opacity="0.23" fill={colors[6]} d="M67.2,59L67.2,59L67.2,59z" />
        <path fill="#CCC" d="M67.1,59.1L67.1,59.1L67.1,59.1z" />
        <path
          opacity="0.23"
          fill={colors[6]}
          d="M67.1,59.1L67.1,59.1L67.1,59.1z"
        />
        <path
          fill="#CCC"
          d="M66.9,59L66.9,59L66.9,59L66.9,59v-0.2l0,0C66.8,58.9,66.8,58.9,66.9,59l-0.1,0.3L66.9,59z"
        />
        <rect
          x="67.4"
          y="60.9"
          transform="rotate(-44.25 67.471 61.31)"
          opacity="0.23"
          fill={colors[6]}
          width="0.1"
          height="0.8"
        />
        <rect
          x="67.4"
          y="60.9"
          transform="rotate(-44.25 67.471 61.31)"
          opacity="0.23"
          fill={colors[6]}
          width="0.1"
          height="0.8"
        />
      </g>
    </g>
  )
}

export default CodPiece
