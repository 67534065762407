import React from "react"

const FlameLeft = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameLeft">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M90.3198013,90.8262482c-2.1999969-3.8000031-2.5-7-1-10.3000031 c0.5999985-1.3000031,1.5-2.6000061,2.5-3.9000092c0.8000031-1.0999908,1.7000046-2.1999969,2.3000031-3.3999939 c0.0999985-0.3000031,0.3000031-0.6000061,0.5-1l0.5999985,0.0999908c-0.0999985,0.5,0,1.1000061,0.0999985,1.6000061 c0.4000015,1.5,1.7000046,2.8999939,3.8000031,4.1999969l-0.0999985,0.0999908l-0.5000076,0.6999969l1,0.1999969 c0.3000031,0.8000031,0.7000046,1.6000061,1.1000061,2.3000031c0.1999969,0.5,0.5,1,0.6999969,1.5 c0.1000061,0.3999939,0.3000031,0.8000031,0.4000015,1.1999969c-0.5999985,1.4000092-1.2999954,2.6999969-2.0999985,4 c-0.3000031,0.5-0.6999969,1-1,1.5c-1.5,0.7000122-3.1999969,1.3000031-5,1.9000092 C92.5198059,91.5262299,91.5198059,91.3262329,90.3198013,90.8262482z"
        />
        <path
          fill={colors[1]}
          d="M91.5198059,91.2262268c-2.0999985-3.5999908-2.4000015-6.5999908-0.9000015-9.6999969 c0.5999985-1.3000031,1.5-2.5,2.4000015-3.8000031c0.5-0.8000031,1.0999985-1.5,1.5999985-2.3000031 c0.5,1.3000031,1.5999985,2.6000061,3.4000015,3.8000031l-0.7999954,1.1000061l1.5,0.1999969 c0.3000031,0.6999969,0.6999969,1.3999939,1,2c0.1999969,0.3999939,0.5,0.8999939,0.6999969,1.3999939 c0.2000046,0.3000031,0.3000031,0.6000061,0.4000015,1c-0.5999985,1.4000092-1.1999969,2.6000061-2,3.8000031 c-0.2000046,0.5-0.5,0.9000092-0.8000031,1.3000031c-1.3000031,0.6000061-2.8000031,1.1000061-4.3000031,1.6000061 C92.9198074,91.626236,92.2198029,91.4262238,91.5198059,91.2262268z"
        />
        <path
          fill={colors[2]}
          d="M92.5198059,91.6262207c-1.9000015-3.4000092-2.2000046-6.3000031-0.8000031-9.1000061 c0.5999985-1.1999969,1.5-2.3999939,2.4000015-3.6999969c0.1999969-0.2999878,0.3000031-0.5,0.5-0.6999969 c0.5999985,0.8000031,1.4000015,1.5,2.4000015,2.1999969l-1,1.4000092l2.0999985,0.3000031 c0.2000046,0.5999908,0.5,1.1999969,0.8000031,1.6999969c0.1999969,0.3999939,0.5,0.8999939,0.6999969,1.3999939 c0.1000061,0.3000031,0.2000046,0.5,0.3000031,0.8000031c-0.5999985,1.1999969-1.2000046,2.4000092-1.9000015,3.5 c-0.1999969,0.3000031-0.5,0.6999969-0.6999969,1c-1.0999985,0.5-2.3000031,0.9000092-3.5,1.3000031 C93.4198074,91.626236,92.9198074,91.5262299,92.5198059,91.6262207z"
        />
        <path
          fill={colors[3]}
          d="M93.5198135,91.7262268c-1.5999985-3.1000061-1.9000015-5.8000031-0.5999985-8.4000092 c0.5-1,1.2000046-2,1.9000015-3c0.2999954,0.3000031,0.6999969,0.6000061,1.0999985,0.9000092l-1.1999969,1.5999908 l2.5999985,0.4000092c0.1999969,0.5,0.5,0.8999939,0.6999969,1.3999939s0.5,0.9000092,0.6999969,1.4000092 c0.0999985,0.1999969,0.0999985,0.3999939,0.1999969,0.5999908c-0.6000061,1.2000122-1.1000061,2.2000122-1.8000031,3.3000031 c-0.1999969,0.3000031-0.3000031,0.5-0.5,0.8000031c-0.8999939,0.4000092-1.7999954,0.6999969-2.6999969,1 C93.819809,91.7262268,93.6198044,91.626236,93.5198135,91.7262268z"
        />
        <path
          fill={colors[4]}
          d="M93.9198151,83.8262329l2.5999985,0.3999939 c0.1999969,0.3999939,0.4000015,0.8000031,0.5999985,1.1999969c0.1999969,0.5,0.5,0.9000092,0.6999969,1.4000092 c0.0999985,0.1999969,0.1999969,0.3000031,0.1999969,0.3999939c-0.5,1-1.0999985,2-1.6999969,3 c-0.2000046,0.1999969-0.3000031,0.4000092-0.4000015,0.6000061c-0.5,0.1999969-1.0999985,0.3999939-1.5999985,0.5999908 C92.9198074,88.7262268,92.7198029,86.2262268,93.9198151,83.8262329z"
        />
        <path
          fill={colors[5]}
          d="M94.1198044,91.7262268c-1-2-1.0999985-3.8999939-0.5-5.8000031l1,0.1999969 c0.0999985,0.3000031,0.3000031,0.6000061,0.4000015,0.9000092c0.1999969,0.5,0.5,0.8999939,0.6999969,1.3999939 c0.0999985,0.1000061,0.0999985,0.1000061,0.0999985,0.1999969c-0.4000015,1-0.9000015,1.9000092-1.5,2.8000031 C94.2198029,91.5262299,94.2198029,91.626236,94.1198044,91.7262268 C94.1198044,91.7262268,94.0198059,91.8262329,94.1198044,91.7262268z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M85.6197968,83.4262238c0.7999954,1.5,1.6999969,3,2.6999969,4.4000092 c-0.2000046-0.3000031-0.2000046-0.5-0.3000031-0.6999969c-0.4000015-1.5-1.6000061-3.1000061-0.8000031-4.6000061 c-0.0999985-0.1000061-0.1999969-0.1999969-0.1999969-0.3000031c0.0999985-2.5999908,0.8000031-4.1999969,2-6.6999969 c1-2,1.1000061-2.2999878,3.3000031-3.8999939c0.0999985-0.1000061,1.0999985-4.8000031,1.0999985-6.1999969 c0-0.1000061,0.0999985-0.1000061,0.1999969-0.1000061c-0.0999985-0.5,0.6999969-1.8999977,0.5-1.3999977 c-0.5999985,1.7999992,2,5.8999977,2.0999985,6.2999992c0,0,1.0999985,3.5000076,3.4000015,5.7000046 c1.8000031,1.7999878,2.0999985,3.5999908,2.3000031,5.5999908c0.5999985,0.1999969,1.1999969,0.5,1.1999969,0.6999969 c0,1.8000031-0.7999954,3.5-1.7999954,5.1000061c1.9000015-1.4000092,3.5999985-3,4-4.9000092 c-0.2000046-0.1000061-0.4000015-0.1999969-0.4000015-0.3000031c-1.1000061-3.0999908-4.1000061-5.5999908-5.2000046-8.6999969 c-0.0999985-0.1999969,0.6000061,0,1.0000076,0.1000061c-0.7000046-2.3000031-2.7000046-7.4000092-2.8000031-7.5 c-1.3999939-1.4000015-3.8999939-5.5000038-4.6999969-7.1000023c-0.5,0.7999992-2.2999954,5.1000023-2.6999969,5.7999992 c-0.5,0.9000015,1.1000061,1.1999969,1.3000031,2.1999969c0,0.4000092-1.2000046,0.3000031-1.4000015,0.2000046 c-0.0999985,0-1,0.7000046-1.0999985,0.7000046c-2,1.6999969-2.1999969,3.5999908-3.5999985,5.5999908 C85.6198044,76.7262268,85.5198059,80.0262299,85.6197968,83.4262238z"
      />
    </g>
  )
}

export default FlameLeft
