import React from "react"
import { withSkinColor } from "../../builderConnector"

const LeftThumb = ({ skinColor, color = [] }) => {
  return (
    <g id="LeftThumb">
      <path
        fill={skinColor}
        d="M25.9,73.4c1.9,0.1,2.8,0.8,2.8,0.8l0.3,1.2l-6.2,2.2l-0.4-0.9c0,0,1-1.1,1.6-2.1 C24.3,74,25.2,73.7,25.9,73.4z"
      />
      <path
        opacity="0.19"
        fill="#21376C"
        d="M25.9,73.4c1.9,0.1,2.8,0.8,2.8,0.8l0.3,1.2l-6.2,2.2 l-0.4-0.9c0,0,1-1.1,1.6-2.1C24.3,74,25.2,73.7,25.9,73.4z"
      />
    </g>
  )
}

export default withSkinColor(LeftThumb, { character: "undefined" })
