import React from "react"

const LongswordLeft = ({
  colors = [
    "#D0AA2B",
    "#A9ABAE",
    "#D6D8DB",
    "#B49330",
    "#SVGID_",
    "#EED793",
    "#7B6529",
    "#A38634",
    "#7951A1",
    "#FFFFFF",
    "#F6F6F6",
    "#E4E5E6",
    "#C8CACC",
    "#A2A4A7",
    "#757779",
    "#2D2D2E",
    "#000000"
  ]
}) => {
  return (
    <g id="LongswordLeft">
      <rect
        x="62.837"
        y="84.751"
        transform="rotate(-77.947 69.187 85.851)"
        fill={colors[0]}
        width="12.7"
        height="2.2"
      />
      <path
        fill={colors[1]}
        d="M69.1250458,78.2569962l3.3433838,0.6868744l10.6739426-49.0007858 c0,0,1.8011627-8.6507721,0.3374481-9.9265366c-1.6809921,0.6117687-3.6808319,9.2396679-3.6808319,9.2396679 L69.1250458,78.2569962z"
      />
      <path
        fill={colors[2]}
        d="M83.4798279,20.0165443c1.463707,1.2757645-0.3374405,9.9265366-0.3374405,9.9265366 L72.4684372,78.9438705l-1.6774139-0.2937698L83.4798279,20.0165443z"
      />
      <path
        fill={colors[0]}
        d="M75.0392532,76.7233047c0,0-0.2823257,1.578064-4.2139206,1.6287613 c-3.4391479-1.6038361-3.3440704-3.3041229-3.3440704-3.3041229s-3.647892,3.7071381,3.0274353,5.1802216 S75.0392532,76.7233047,75.0392532,76.7233047z"
      />
      <path
        fill={colors[3]}
        d="M67.4698181,75.1472931c0,0-1.5852356,1.5287323,1.8003159,2.7237625 c0.4852829,0.1565247,0.5317154,2.3764038,1.2271194,2.456459c0.6839676,0.1793976,1.7188873-1.8153305,2.2156067-1.7581482 c3.488472,0.3009262,2.3263779-1.8460617,2.3263779-1.8460617s2.9352646,3.3577194-0.0600739,4.0195084 c-0.1529388-0.4202499-0.6153488-0.7754593-1.1999817-0.943428c-0.8826599-0.2022705-1.9061356,1.6931152-2.1848831,2.3656464 c-0.5882111,0.7375717-1.2414551,1.1656723-1.8375168,1.0970535c-0.6839676-0.1793976-0.9898529-1.0198975-1.2078323-1.7496109 c0.0800552-0.6954041-0.3637466-2.9610291-1.257843-3.0639572c-0.4852905-0.1565247-1.0048752-0.0150223-1.3372192,0.248703 C63.9398766,76.9554596,67.4698181,75.1472931,67.4698181,75.1472931z"
      />
      <path
        fill="url(#SVGID_17_)"
        d="M69.702507,80.2360153c-0.1679611,0.5846252,0.0728836,1.115654,0.4702606,1.1613998 s0.7411499-0.3173218,0.9091187-0.901947c0.1679611-0.5846252-0.0728836-1.115654-0.4702606-1.1613998 S69.8704681,79.6513901,69.702507,80.2360153z"
      />
      <path
        fill={colors[3]}
        d="M66.9243011,93.0020523l1.5666275,0.3816605c0.4967194,0.0571823,0.9284058-0.1951065,1.0849304-0.6803894 l0.0571823-0.4967194c0.0571823-0.4967194-0.1951065-0.9284058-0.6803894-1.0849304l-1.5666275-0.3816605 c-0.4967194-0.0571823-0.9284058,0.1951065-1.0849304,0.6803894l-0.0571823,0.4967194 C66.1753006,92.5131912,66.4275818,92.94487,66.9243011,93.0020523z"
      />
      <path
        fill={colors[5]}
        d="M69.633049,92.2066116c0.0571823-0.4967194-0.1951065-0.9284058-0.6803894-1.0849304l-1.5666275-0.3816605 c-0.4967194-0.0571823-0.9284058,0.1951065-1.0849304,0.6803894C67.4281921,91.2481766,68.9948196,91.629837,69.633049,92.2066116 z"
      />
      <ellipse
        transform="rotate(-77.947 70.38 80.424)"
        fill={colors[6]}
        cx="70.381"
        cy="80.424"
        rx="1.5"
        ry="1.1"
      />
      <ellipse
        transform="rotate(-77.947 70.38 80.424)"
        fill={colors[7]}
        cx="70.381"
        cy="80.424"
        rx="1.3"
        ry="0.9"
      />
      <ellipse
        transform="rotate(-77.947 70.381 80.423)"
        fill={colors[8]}
        cx="70.381"
        cy="80.423"
        rx="1.1"
        ry="0.7"
      />
      <radialGradient
        id="SVGID_17_"
        cx="-885.197"
        cy="634.981"
        r="0.903"
        gradientTransform="matrix(-.97794 -.20882 -.20882 .97794 -662.682 -725.417)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.698" stopColor="#fff" stopOpacity="0.1" />
        <stop offset="0.724" stopColor="#f6f6f6" stopOpacity="0.1" />
        <stop offset="0.762" stopColor="#e4e5e6" stopOpacity="0.1" />
        <stop offset="0.809" stopColor="#c8cacc" stopOpacity="0.1" />
        <stop offset="0.862" stopColor="#a2a4a7" stopOpacity="0.1" />
        <stop offset="0.921" stopColor={colors[14]} stopOpacity="0.1" />
        <stop offset="0.983" stopColor="#2d2d2e" stopOpacity="0.1" />
        <stop offset="0.995" stopOpacity="0.1" />
      </radialGradient>
    </g>
  )
}

export default LongswordLeft
