import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Tail = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Tail") ? (
    <g id="Tail">
      <path d="M50.1,106.8c-3.3,0-6-0.5-8.2-1.5c-2.6-1.2-9.5-4.4-10.4-12.3C30.2,81.3,41,66.4,60.4,53.2l0.8-0.5c2.7-1.3,5.1-2,7.3-2 c3.1,0,5.9,1.3,7.8,3.5c3.4,4,2.9,9.4,2.6,11l-3.5,5.1c-10.5,6-20.3,14.2-23.4,19.3c4.8-0.1,13.3-1.1,17.5-2.5l9.2,6.1 c0.2,2.2-0.6,4.3-2.1,5.9C72.4,103.9,57,106.8,50.1,106.8z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Tail, { character: "ManMixed" })
