import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const AxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("AxeLeft") ? (
    <g id="AxeLeft">
      <path
        id="S_x24_axeLeft"
        d="M61.1377029,122.7352448l-3-1.4000015l-3.7999992-8l7.7999992-34.3999939h-2.0999985 c-3.9000015-3.7000046-5.5999985-8.9000015-4.7000008-13.7000046l0,0c1-4.8999977,4.5-8.9999962,9.6000023-10.8999939 l3.6999969,1.5999985l4.9000015-1.2000008l3.8000031,0.7000008l4.1999969,2.9000015l3.9000015-0.0999985 c3.9000015,3.7000008,5.5999985,8.9000015,4.6999969,13.6999969v0.0999985c-1,4.9000015-4.5,8.9000015-9.5999985,10.9000015 l-2.0999985-0.9000015c-2.1999969,9.4000015-4.4000015,18.5999985-4.9000015,21.6999969 c-0.8000031,4.5-1.3000031,10.8000031-1.4000015,12.5999985l-3.5,5.5999985c-0.8000031,0.5-1.5999985,0.8000031-2.5,1.0999985 L61.1377029,122.7352448z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(AxeLeft, {
  character: "ManHalfling"
})
