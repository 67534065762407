import React from "react"

const ShortPants = ({ colors = ["#643A00"] }) => {
  return (
    <g id="ShortPants">
      <path
        fill={colors[0]}
        d="M56.7268066,95.0635223c2.6832886,0.0897827,5.3210449,0.2197266,7.9883423,0.2543335 c0.913208-3.4285889,1.8115845-7.0078125,2.4234619-8.6611938c0.3849487-0.262207,0.9185791-0.4291382,1.3947144-0.4291382 c0.5817261,0,0.9328613,0.1432495,1.3947144,0.536438c0.8688354,2.5300293,1.7216187,5.7581177,2.3956299,8.5328979 c3.3728638-0.0142212,6.7457275-0.0162964,10.1184692-0.0029907c0.8040161-7.7995605-1.3563232-14.3770142-1.3563232-14.3770142 l-0.777832-3.2587891c0,0-9.789856,0.9521484-11.881958,0.9521484c-2.092041,0-11.4125366-1.5556641-11.4125366-1.5556641 c-2.4384766,7.1529541-2.4515991,13.6235962-2.1750488,17.882019 C55.6647339,94.9710541,56.4043579,95.0527191,56.7268066,95.0635223z"
      />
    </g>
  )
}

export default ShortPants
