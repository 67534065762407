

import get from 'lodash/get'
const queryString = require('query-string')

const DEFAULT_CHARACTER = 'WomanMixed'
const DEFAULT_SKIN_COLOR = '#C88E62'

const searchSelector = (state) => (
  get(state, 'router.location.search') || get(state, 'location.search')
)

export const setQuery = (locationSearch, params) => {
  const parsedLocationSearch = queryString.parse(locationSearch)

  return queryString.stringify({
    ...parsedLocationSearch,
    ...params
  })
}

export const characterIdSelector = state => {
  const query = queryString.parse(searchSelector(state))
  return query.pawnId
}

export const skinColorSelector = state => {
  const query = queryString.parse(searchSelector(state))
  return (
    query.skinColor
    || get(state, 'builderProps.skinColor.hex')
    || DEFAULT_SKIN_COLOR
  )
}

export const characterSelector = state => {
  const query = queryString.parse(searchSelector(state))
  return (
    query.character
    || get(state, 'builderReducer.character')
    || get(state, 'builderProps.character')
    || DEFAULT_CHARACTER
  )
}
