import React from "react"

const OrcFace = ({
  colors = ["#FBFF45", "#FFFFFF", "#SVGID_", "#21366C", "#ED1C24"]
}) => {
  return (
    <g id="OrcFace">
      <path
        fill={colors[0]}
        d="M73.0690308,8.4010935c-1.2197876,0-2.2579117-0.1038122-2.9326859-0.1557178 C70.9343948,9.5300474,72.7640839,9.5040951,73.0690308,8.4010935z"
      />
      <g opacity="0.74">
        <path
          fill={colors[0]}
          d="M63.4648857,8.4010935c1.2197914,0,2.2579079-0.1038122,2.932682-0.1557178 C65.5995178,9.5300474,63.7698326,9.5040951,63.4648857,8.4010935z"
        />
        <g opacity="0.3">
          <g fill="#FFF">
            <path d="M63.4648857,8.4010935c1.2197914,0,2.2579079-0.1038122,2.932682-0.1557178 C65.5995178,9.5300474,63.7698326,9.5040951,63.4648857,8.4010935z" />
            <path d="M73.0690308,8.4010935c-1.2197876,0-2.2579117-0.1038122-2.9326859-0.1557178 C70.9343948,9.5300474,72.7640839,9.5040951,73.0690308,8.4010935z" />
          </g>
          <g fill="#262261">
            <path d="M74.003334,8.4400263l-4.8013-0.3503685c0,0-0.077858,0.1427412,0.7526398,0.3633413 C73.4064178,11.1002026,74.003334,8.4400263,74.003334,8.4400263z" />
            <path d="M62.5305786,8.4400263l4.8013-0.3503685c0,0,0.077858,0.1427412-0.7526398,0.3633413 C63.1274948,11.1002026,62.5305786,8.4400263,62.5305786,8.4400263z" />
          </g>
          <path
            fill="#262261"
            d="M67.4097366,8.6096802c0,0-0.0194626-0.4421635,0.2854843-0.7406225 c-0.0027466-0.0035877,0.0233765-0.7239866-0.0519104-0.9213305c-0.1755524-0.4602122-0.570961-0.1427412-0.7266769-0.8045421 c-0.1730957,0.1488504,0.4144669,0.8689299,0.5087814,1.0988054c0.2800369,0.6825047-0.4618301,0.7286148-0.5164948,0.6851182 c-0.1533127-0.1219931-4.0666046-0.0676203-4.1642303-0.1618629c0.110302,0.4022708-0.3179245,0.5839419-0.9278183,0.3892941 c-0.6098938-0.1946468-0.3454399-1.0738325-0.3097-1.2798877c-1.1047363,2.4477715,1.023407,1.5653739,1.023407,1.5653739 c0.0423012-0.1150904-0.1297646,0.830492,0.8175163,1.1419277c0.9472847,0.3114357,2.1529121-0.0650892,2.5304146-0.4671535 c-0.2530365,0.6228714-1.0670471,1.1521978-1.0121613,1.1678839c0.0781021,0.0223236,1.3086472-0.7501373,1.7194901-1.3156548 l0.5124664,0.368371L67.4097366,8.6096802z"
          />
          <path
            fill="#262261"
            d="M69.4513931,9.3354006l0.5124588-0.368371 c0.4108429,0.5655174,1.6413879,1.3379784,1.7194977,1.3156548c0.0548782-0.015686-0.7591324-0.5450125-1.0121689-1.1678839 c0.3775024,0.4020643,1.5831299,0.7785892,2.5304184,0.4671535c0.9472809-0.3114357,0.7752151-1.2570181,0.8175125-1.1419277 c0,0,2.1281433,0.8823977,1.023407-1.5653739c0.0357437,0.2060552,0.3001938,1.0852408-0.3097,1.2798877 c-0.6098938,0.1946478-1.0381165,0.0129766-0.9278183-0.3892941c-0.0976257,0.0942426-4.01091,0.0398698-4.1642303,0.1618629 c-0.0546646,0.0434966-0.796524-0.0026135-0.5164948-0.6851182c0.0943146-0.2298756,0.6818771-0.949955,0.5087891-1.0988054 c-0.1557236,0.6618009-0.5511322,0.3443298-0.7266846,0.8045421 c-0.0752792,0.1973438-0.0491638,0.9177427-0.0519104,0.9213305c0.3049469,0.2984591,0.2854843,0.7406225,0.2854843,0.7406225 L69.4513931,9.3354006z"
          />
          <path
            fill="#262261"
            d="M66.0601807,12.397851c0.9568634,0,1.0640717,1.8167086,2.1800537,1.8426609 c1.1159744,0.0259533,1.3495483-1.2716951,2.1540909-1.7128954c0,0,0.2595367-0.3114357-0.1038055-0.5190601 c-0.3633423-0.2076235-0.8564529,1.012166-1.9724274,0.9343071c-1.1159821-0.0778589-1.4662018-0.9103794-2.0542526-1.0956717 C66.0615692,12.1184397,66.0455933,12.0597649,66.0601807,12.397851z"
          />
          <path
            fill="#262261"
            d="M67.9807053,16.4984207c0,0,0.7007294-0.7007303,1.0900192-0.077858 C68.7333374,17.2510567,68.2661819,17.2510567,67.9807053,16.4984207z"
          />
          <path
            opacity="0.45"
            fill="#262261"
            d="M67.6952209,16.7839031c-0.0477753,0.0804615-0.5450134,0.5709667-0.5450134,0.5709667 s0.3373871,0.1557178,0.6488266-0.1557178c-0.0519104,0.8824005,0.3633423,1.3236008,0.9083481,1.4533653 c0.0259552-0.4671535,0.5190659-1.2716961,0.4412003-1.6090832c0.2854843,0.4671535,0.6488266,0.5969181,0.9343109,0.4931049 c-0.1816711-0.5450115-1.19384-1.3236008-1.19384-1.3236008S68.188324,15.9534082,67.6952209,16.7839031z"
          />
        </g>
        <g id="L1_x24_C_x24_nose">
          <path
            opacity="0.4"
            fill="#262261"
            d="M69.2953796,12.848917c-0.0325851,0.0350809-0.0671921,0.0630007-0.1014938,0.0920134 c-0.2047958,0.1040907-0.5047302,0.2247629-0.8231735,0.2488289c-0.3184433-0.024066-0.6183853-0.1447382-0.8231888-0.2488289 c-0.0343018-0.0290127-0.0689087-0.0569324-0.1014938-0.0920134c-0.259903-0.411315-1.3805923-1.037673-1.1522675-0.6327362 c-0.053566,0.1067038,0,0.0778589,0,0.0778589c0.0470963,0.0800686,0.7266769,0.5190582,0.7266769,0.5190582 s-0.016777-0.2762623,0.2769547-0.0045185l0.1684341,0.2256489l0.0541992,0.2135859 c0,0,0.2530594,0.6812572,0.77211,0.6812572c0.026886,0,0.0528412-0.0028639,0.0785751-0.0063343 c0.0257339,0.0034704,0.0516739,0.0063343,0.0785599,0.0063343c0.5190582,0,0.77211-0.6812572,0.77211-0.6812572 l0.0541992-0.2135859l0.1684341-0.2256489c0.2937317-0.2717438,0.2769547,0.0045185,0.2769547,0.0045185 s0.8093414-0.4130421,0.8564453-0.4931116c0,0-0.103569,0.1326637-0.157135,0.025959 C70.2229462,11.9565125,69.5552826,12.437602,69.2953796,12.848917z"
          />
          <path
            opacity="0.76"
            fill="url(#SVGID_1_)"
            d="M67.390274,8.1675167l-1.265213,3.8540163 l0.0194702,0.3892946c0.6142349-0.0159922,1.091629,0.2410927,1.4014587,0.7591248l0.7980576,0.2400646l0.7980499-0.2400646 l0.4476852-0.4476891l0.8759155-0.1946468c-0.1446381-1.4654207-0.9408493-2.9306898-1.4793167-4.3795643L67.390274,8.1675167 z"
          />
          <path
            opacity="0.4"
            fill="#262261"
            d="M66.0601807,12.397851c0.9568634,0,1.0640717,1.8167086,2.1800537,1.8426609 c1.1159744,0.0259533,1.3495483-1.2716951,2.1540909-1.7128954c0,0,0.2595367-0.3114357-0.1038055-0.5190601 c-0.3633423-0.2076235-0.8564529,1.012166-1.9724274,0.9343071c-1.1159821-0.0778589-1.4662018-0.9103794-2.0542526-1.0956717 C66.0615692,12.1184397,66.0455933,12.0597649,66.0601807,12.397851z"
          />
          <path
            opacity="0.3"
            fill={colors[3]}
            d="M67.7990341,5.0272074c-0.0376129-0.1851597-0.0519104,1.5312252,0.4412003,1.7128959 c0.1038132-0.3633413,0.5969162-1.0640717,0.4152451-1.7128959 C68.3959503,5.7279372,68.1364212,6.6881976,67.7990341,5.0272074z"
          />
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="68.327"
            y1="14.599"
            x2="68.227"
            y2="7.199"
          >
            <stop offset="0" stopColor={colors[4]} />
            <stop offset="0.155" stopColor={colors[4]} stopOpacity="0.955" />
            <stop offset="0.281" stopColor={colors[4]} stopOpacity="0.719" />
            <stop offset="0.431" stopColor={colors[4]} stopOpacity="0.55" />
            <stop offset="0.547" stopColor={colors[4]} stopOpacity="0.325" />
            <stop offset="0.667" stopColor={colors[4]} stopOpacity="0.156" />
            <stop offset="0.786" stopColor={colors[4]} stopOpacity="0.07" />
            <stop offset="0.895" stopColor={colors[4]} stopOpacity="0.007" />
            <stop offset="1" stopColor={colors[4]} stopOpacity="0" />
          </linearGradient>
          <ellipse
            opacity="0.4"
            fill="#FFF"
            cx="68.007"
            cy="11.593"
            rx="0.169"
            ry="0.195"
          />
        </g>
        <path
          opacity="0.3"
          fill={colors[3]}
          d="M73.7682114,14.3183718c0,0-0.0212479-0.0078106-0.0527573-0.0103788 l-0.018013-0.0674896c-0.0147171,0.0232391-0.0277405,0.0471144-0.0406342,0.0710325 c-0.0553207,0.0101137-0.1214218,0.0431576-0.1757889,0.1355839c-0.1993179,0.3389235-0.5422134,0.8481426-0.9323883,1.117156 c-0.0727005,0.0016975-1.9183884,0.0442791-3.7820053,0.0882778c-0.0878296,0.0020666-0.1756058,0.0041637-0.2633133,0.0062304 c-0.0876999-0.0020666-0.1754913-0.0041637-0.2633286-0.0062304c-1.8636093-0.0439987-3.7092972-0.0865803-3.7819977-0.0882778 c-0.3901901-0.2690134-0.7330818-0.7782326-0.9323921-1.117156c-0.0543633-0.0924263-0.1204643-0.1254702-0.1757889-0.1355839 c-0.0128899-0.0239182-0.0259132-0.0477934-0.0406303-0.0710325l-0.018013,0.0674896 c-0.0315094,0.0025539-0.0527687,0.0103788-0.0527687,0.0103788s0.0073357,0.0395994,0.0223503,0.1035452l-0.0223503,0.0837154 c0,0,0.1616135,1.4672403,0.9596596,1.7786732c0.2604599,0.1016388,0.6125717,0.0758152,1.0227661-0.0037651 c1.2850876-0.0365734,1.9312057-0.4999628,3.2824936-0.5549459c1.3512955,0.0549831,1.9973984,0.5183725,3.2824707,0.5549459 c0.4101944,0.0795803,0.7623062,0.1054039,1.0227661,0.0037651c0.7980652-0.3114328,0.9596634-1.7786732,0.9596634-1.7786732 l-0.0223389-0.0837154C73.7608871,14.3579712,73.7682114,14.3183718,73.7682114,14.3183718z"
        />
        <path
          opacity="0.3"
          fill={colors[3]}
          d="M73.7682114,14.3183718c0,0-0.0212479-0.0078106-0.0527573-0.0103788 l-0.018013-0.0674896c-0.0147171,0.0232391-0.0277405,0.0471144-0.0406342,0.0710325 c-0.0553207,0.0101137-0.1214218,0.0431576-0.1757889,0.1355839c-0.1993179,0.3389235-0.5422134,0.8481426-0.9323883,1.117156 c-0.0727005,0.0016975-1.9183884,0.0442791-3.7820053,0.0882778c-0.0878296,0.0020666-0.1756058,0.0041637-0.2633133,0.0062304 c-0.0876999-0.0020666-0.1754913-0.0041637-0.2633286-0.0062304c-1.8636093-0.0439987-3.7092972-0.0865803-3.7819977-0.0882778 c-0.3901901-0.2690134-0.7330818-0.7782326-0.9323921-1.117156c-0.0543633-0.0924263-0.1204643-0.1254702-0.1757889-0.1355839 c-0.0128899-0.0239182-0.0259132-0.0477934-0.0406303-0.0710325l-0.018013,0.0674896 c-0.0315094,0.0025539-0.0527687,0.0103788-0.0527687,0.0103788s0.0073357,0.0395994,0.0223503,0.1035452l-0.0223503,0.0837154 c0,0,0.1616135,1.4672403,0.9596596,1.7786732c0.2604599,0.1016388,0.6125717,0.0758152,1.0227661-0.0037651 c1.2850876-0.0365734,1.9312057-0.4999628,3.2824936-0.5549459c1.3512955,0.0549831,1.9973984,0.5183725,3.2824707,0.5549459 c0.4101944,0.0795803,0.7623062,0.1054039,1.0227661,0.0037651c0.7980652-0.3114328,0.9596634-1.7786732,0.9596634-1.7786732 l-0.0223389-0.0837154C73.7608871,14.3579712,73.7682114,14.3183718,73.7682114,14.3183718z"
        />
        <path
          opacity="0.4"
          fill={colors[3]}
          d="M73.5394135,14.4625692c-0.1993179,0.3389235-0.5422134,0.8481426-0.9323883,1.117156 c-0.0727005,0.0016975-1.2314835,0.0442791-3.0951004,0.0882778c-0.0878296,0.0020666-1.9072952,0.0020666-1.9951324,0 c-1.8636093-0.0439987-2.9277115-0.0865803-3.000412-0.0882778c-0.3901901-0.2690134-0.7710838-0.6499243-0.9703941-0.9888477 c-0.0543633-0.0924273-0.2418633-0.2174568-0.2268486-0.153511l-0.0058784,0.0220194l-0.0164757,0.0616961 c0,0,0.2589455,1.3934011,1.0569916,1.7048321c0.2604599,0.1016407,2.8530884-0.4802961,4.2043839-0.5352793 c1.3512878,0.0549831,3.9439163,0.5687933,4.2043762,0.4671526c0.7980652-0.3114309,0.9148407-1.5863743,0.9148407-1.5863743 S73.5937805,14.3701429,73.5394135,14.4625692z"
        />
      </g>
      <g fill={colors[0]}>
        <path d="M63.4648857,8.4010935c1.2197914,0,2.2579079-0.1038122,2.932682-0.1557178 C65.5995178,9.5300474,63.7698326,9.5040951,63.4648857,8.4010935z" />
        <path d="M73.0690308,8.4010935c-1.2197876,0-2.2579117-0.1038122-2.9326859-0.1557178 C70.9343948,9.5300474,72.7640839,9.5040951,73.0690308,8.4010935z" />
      </g>
    </g>
  )
}

export default OrcFace
