import React from "react"

const SwordLeftHip = ({
  colors = [
    "#8A7C6B",
    "#665C4F",
    "#3D3730",
    "#615240",
    "#838383",
    "#C7C7C6",
    "#4D4D4D",
    "#5C3434"
  ]
}) => {
  return (
    <g id="SwordLeftHip">
      <path
        fill={colors[0]}
        d="M54.0508652,80.6222534l16.881424,26.0497818c0,0,1.3825378,2.0374146,0.800415,2.6195297 c-0.5821228,0.6548843-1.7463531-1.5280609-1.7463531-1.5280609L52.2317467,81.9320221 c0,0-0.2182922-0.3638229,0.582119-1.0914688C53.6142769,80.1128998,54.0508652,80.6222534,54.0508652,80.6222534z"
      />
      <path
        fill={colors[1]}
        d="M71.8054657,109.2915726c-0.5821228,0.6548843-1.7463531-1.5280609-1.7463531-1.5280609 L52.2317467,81.9320221c0,0-0.2182922-0.3638229,0.582119-1.0914688 c-0.0727654,0.8004074,1.4552956,2.9105835,3.5654755,6.1849976c4.0020599,6.1122437,10.405365,15.2806015,14.3346558,21.1745453 C71.0050583,108.7822189,71.4416428,108.8549805,71.8054657,109.2915726z"
      />
      <path
        fill={colors[2]}
        d="M54.2691612,80.9133148c0,0,0.2910576,0.509346-0.582119,1.0914688 s-1.3825302,0.2182999-1.3825302,0.2182999L54.2691612,80.9133148z"
      />
      <path
        fill={colors[3]}
        d="M53.3232193,83.6056061c0,0,0.6548843,0.7276459,2.1101799-0.8731766 c-0.0727654-0.1455231-0.1455307-0.2910538-0.2910614-0.4365845c0.1455307,0.2182999,4.5841789-1.9646454,4.5841789-1.9646454 l0.2910614,1.6735916l-3.9292984,1.6735916c0,0,0.5093536,1.4552917-1.746357,1.3825302 c-0.0727615,0-0.2182922-0.2182922-0.2182922-0.2182922c-2.1829453-0.0727692-2.6195297,0.3638229-2.6195297,0.3638229 l-0.7276497-0.8731766c0,0,1.382534-1.0914688,2.4012375-1.0187073 C53.2504539,83.3145523,53.3232193,83.5328445,53.3232193,83.6056061z"
      />
      <path
        fill={colors[4]}
        d="M49.1028633,75.6014862c0.4365883-0.3638229,1.0187073-0.3638229,1.2369995-0.0727692 c0.1455307,0.2182999,0.1455307,0.4365921,0,0.6548843l2.9833565,3.7110062l3.0561218-1.164238l0.1455269,0.6548843 l-6.3305321,4.438652l-0.7276497-0.0727692l2.0374146-2.6922913l-2.4012375-4.0748215 c-0.2182922,0-0.3638229-0.0727692-0.4365883-0.2182999C48.447979,76.4746628,48.5935097,75.8925476,49.1028633,75.6014862z"
      />
      <path
        fill={colors[5]}
        d="M49.4666862,75.528717c0,0-0.1455307,1.164238-0.4365883,1.2369995 C48.7390404,76.8384857,48.2296867,76.2563705,49.4666862,75.528717z"
      />
      <path
        fill={colors[6]}
        d="M50.3398628,76.2563705c0,0-0.1455269,0.2910538-0.5093536,0.5821152 c-0.3638229,0.2910614-0.7276459,0.2182999-0.7276459,0.2182999s0.2182922,0.4365845,0.8731766-0.0727615 C50.6309204,76.5474243,50.4126282,76.2563705,50.3398628,76.2563705z"
      />
      <path
        fill={colors[7]}
        d="M50.3398628,76.2563705c0,0,0.2182961,0.2910538-0.3638229,0.7276459 c-0.5821152,0.4365845-0.8731766,0-0.8731766,0l2.4740028,4.0020599c0,0,0.582119,0.5821228,1.3097649,0 c0.7276497-0.5821228,0.4365883-1.0914688,0.4365883-1.0914688L50.3398628,76.2563705z"
      />
      <polygon
        fill={colors[5]}
        points="49.6122169,83.7511368 51.6496315,81.0588379 53.3959846,79.9673691 56.4521027,78.7303696 56.5976334,79.3852539 53.8325729,80.695015 52.1589851,81.8592606 50.2670975,83.8239059"
      />
    </g>
  )
}

export default SwordLeftHip
