import React from "react"

const BreastPlate = ({ colors = ["#898888", "#FFFFFF", "#262261"] }) => {
  return (
    <g id="BreastPlate">
      <path
        fill={colors[0]}
        d="M58.7,55.9c0-1.2,0.2-1.9-0.6-4.8c-0.7-3.8-1.9-10.7-2.1-11.3c-1.4-1-2.4-10.7-1.7-10.9 c0.2-0.1,8.9-3.1,8.9-3.1c2.9,2.2,3.4,4.9,5.9,4.9s3.6-2.9,6.2-4.9c0,0,8.5,3.2,8.9,3.3c0.1,0-0.7,9.5-1.9,10.4 c-0.4,0.5-1.5,8.7-2.1,12.4c-0.6,3.4-0.6,3.9-0.6,3.9s-5.3,2.4-10.8,2.4C64,58.2,59.3,56,58.7,55.9z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M78,39.1c-2.7,0.8-14.8,0.8-17.4,0c-3.8-1.2-5.9-7.7-5.9-7.7 l1.2,4.2c0,0,0.5,3.9,4.3,5.1c1.6,0.5,5.4,0,9.3,0c3.7,0,7.4,0.5,8.9,0c3.8-1.2,4.3-5.1,4.3-5.1l1.2-4.2 C83.8,31.4,81.8,37.9,78,39.1z"
      />
      <path
        opacity="0.5"
        fill="#FFF"
        d="M69.4,41c-6.3,0-9.3,5-10.6,8.7c-0.5,0.9-0.1,2,0.3,2 c1.5-3.6,4.4-9.3,10.4-9.3c5.8,0,8.7,5.5,10.2,9.1c0.4,0.3,0.6-1,0.3-1.9C78.5,45.8,75.6,41,69.4,41z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M60.8,56.3c0,0,0.4-13.6,8.5-13.6c7.8,0,8.2,13.6,8.2,13.6 s-1.6,1.8-8.2,1.8S60.8,56.3,60.8,56.3z"
      />
      <line fill="none" x1="64.1" y1="40.6" x2="64.1" y2="40.6" />
      <path
        opacity="0.5"
        fill="#FFF"
        d="M83.6,29c-2.1-0.4-8.1-3-8.1-3s-3.9,4.7-4.5,4.9 c-0.2,0.1-1,0.4-1.6,0.6c-0.6-0.3-2.2-1.1-2.5-1.2C66.4,30,63,26,63,26s-6.1,2.4-8.2,2.8c-2.1,0.3,2.6,7.6,6.1,8.6 c3,0.9,13.9,0.9,16.8,0C81.1,36.4,85.7,29.3,83.6,29z M54.9,31.6c0.1,0,0.1,0,0.2,0C55,31.6,54.9,31.6,54.9,31.6L54.9,31.6z"
      />
      <path fill="#FFF" d="M65.2,28.1" />
      <g>
        <path
          opacity="0.3"
          fill={colors[2]}
          d="M74.8,26.1c-0.1,0-0.2,0-0.2,0c-1.7,1.4-4.4,4.6-6.1,3.7 c-1.2-0.6-3-2.5-3.8-3.6C64.5,26,64.3,26,64.1,26c-0.1,0.2-0.3,0.3-0.4,0.5c0,0.1,0,0.2,0.1,0.2c0.8,1.1,1.9,2,2.9,2.9 c0.7,0.7,1.4,1.2,2.4,1.3c1,0.1,2.1-0.3,2.9-0.9c1.2-0.8,2.8-2,3.6-3.3c0.1-0.1,0.1-0.2,0.1-0.3C75.3,26.4,75,26.3,74.8,26.1z"
        />
        <g opacity="0.2">
          <path d="M70.5,72.2c0.1,0,0.2,0,0.2,0S70.6,72.2,70.5,72.2z" />
          <path d="M57.7,102.8L57.7,102.8C57.6,102.9,57.7,102.9,57.7,102.8z" />
        </g>
      </g>
    </g>
  )
}

export default BreastPlate
