import React from "react"

const PlateGoldArmor = ({
  colors = ["#8A784E", "#EED793", "#A59A76", "#E6BB21"]
}) => {
  return (
    <g id="PlateGoldArmor">
      <path
        fill={colors[0]}
        d="M60.622467,58.3283386l-4.4397888-0.6457863c0,0-4.8434029,1.6144676-7.0229378,1.6144676 c-2.1795311,0-7.8565979-1.7877312-7.8565979-1.7877312l-4.9619789,0.9119301c0,0-2.0345306,3.7818451-1.6309166,7.4143982 c0.4036179,3.6325455,2.5024261,7.1036606,2.5024261,7.1036606l-0.2421722,8.9603043h23.8941307l-0.1614418-9.0410233 c0,0,2.1795311-3.5518341,2.5024223-7.1036606C63.60923,62.1223412,60.622467,58.3283386,60.622467,58.3283386z"
      />
      <path
        fill={colors[1]}
        d="M39.8765488,79.6393204c0,0-2.6638756-14.2073212,9.0410233-14.2073212 s9.8482552,13.8037033,9.8482552,13.8037033L39.8765488,79.6393204z"
      />
      <path
        fill={colors[0]}
        d="M49.4826317,62.1223412L49.4826317,62.1223412h-0.3228951h-0.3228951l0,0l-12.9157448,0.3228951 l0.9686813,3.9554443c0,0,0,0.7265167,7.2651062,2.3409805c2.2602539-0.161438,3.7940025,0,5.0048523-0.4036102 c1.2915726,0.4036102,2.7445984,0.3228912,5.0048523,0.4036102c7.26511-1.6144638,7.26511-2.3409805,7.26511-2.3409805 l0.9686813-3.9554443L49.4826317,62.1223412z"
      />
      <path
        fill={colors[0]}
        d="M49.3211899,59.8620834L49.3211899,59.8620834h-0.3228951h-0.3228951l0,0l-10.7362175,1.5337486 l0.3228951,4.5205116c0,0,0.5650673,0.1614456,5.6506424,1.7759171c2.2602539-0.2421799,3.7939987,0,5.0048523-0.4843445 c1.2915726,0.4843445,2.7445946,0.3228989,5.0048523,0.4843445c4.1168938-1.2108536,5.0855751-1.6144714,5.0855751-1.6144714 l0.8072319-4.1168938L49.3211899,59.8620834z"
      />
      <path
        fill={colors[1]}
        d="M48.9982948,66.1585083c0,0,2.9867668,0.6457901,4.6819572,0.4843445 c1.6951904-0.1614532,5.7313614-1.7759171,5.7313614-1.7759171l0.6457863-3.1482162L49.4019089,59.619915l0,0h-0.3228951 h-0.3228951l0,0l-10.7362137,1.5337448l0.2421722,3.8747215c0,0,3.8747215,1.2915802,5.8120842,1.3722992 C46.0115242,66.4814072,48.9982948,66.1585083,48.9982948,66.1585083z"
      />
      <path
        fill={colors[2]}
        d="M59.0887222,65.6741714c0,0-2.5024261,1.2915726-5.1663017,1.6951904 c-2.0988083,0.2421722-4.5205116-0.3228912-5.0048523-0.5650635c-0.5650635,0.2421722-2.583149,0.7265167-4.8434067,0.4036179 c-2.583149-0.3228912-5.892807-1.5337448-5.892807-1.5337448l-0.0807228-4.1976166h-0.1614494l0.3228951,4.5205078 c0,0,0.5650673,0.1614456,5.6506424,1.7759171c2.2602539-0.2421722,3.7939987,0,5.0048523-0.4843369 c1.2915726,0.4843369,2.7445946,0.3228912,5.0048523,0.4843369c4.1168938-1.2108536,5.0855751-1.6144714,5.0855751-1.6144714 l1.177475-4.1700287l-0.1280746-0.2697601L59.0887222,65.6741714z"
      />
      <path
        fill={colors[0]}
        d="M39.7958221,77.7826843c0,0.1614456,0.0807228,0.2421722,0.0807228,0.2421722l18.7278328-0.4036179 c-0.4843369-4.6012344-4.4397888-8.2337875-9.3639183-8.2337875 C44.235611,69.3874512,40.1187172,73.1007233,39.7958221,77.7826843z"
      />
      <path
        fill={colors[0]}
        d="M36.9705009,76.8947296c0,0,7.6687279,1.7759094,12.6735802,1.7759094 s11.2205505-2.0180817,11.2205505-2.0180817l0.5650673,2.5831451c0,0-6.6193237,3.5518341-11.704895,3.5518341 s-12.9157486-3.5518341-12.9157486-3.5518341L36.9705009,76.8947296z"
      />
      <path
        fill={colors[2]}
        d="M39.9572678,79.0742569c0,0.1614456,0.0807228,0.2421722,0.0807228,0.2421722l18.6471138-0.4036179 c-0.4843407-4.6012268-4.4397888-8.2337875-9.3639145-8.2337875 C44.3163338,70.6790237,40.2801628,74.3922958,39.9572678,79.0742569z"
      />
      <path
        fill={colors[2]}
        d="M38.4235268,79.3971558c-0.6457863-0.2421722-1.1301308-0.4843445-1.3722992-0.5650635 l-1.1301308,9.1217499l0.4843407,3.3903809l2.3409805-2.5831451L38.4235229,88.35746 C38.4235229,88.35746,38.4235229,79.3971558,38.4235268,79.3971558z"
      />
      <path
        fill={colors[2]}
        d="M62.0754852,87.6309433l-0.8879585-8.718132 c-0.3228951,0.161438-0.9736786,0.2808838-1.4580193,0.5230484l-0.308445,8.5962906l-0.0094452,0.3253021l2.5831451,2.986763 L62.0754852,87.6309433z"
      />
      <path
        fill={colors[1]}
        d="M41.8139381,88.5996475v-7.9108887c-0.484375-0.161499-1.5957642-0.5952759-1.9993896-0.7567139 l-0.0041504,0.0233765c-0.5296021-0.2062988-0.9994507-0.3827515-1.4675903-0.5582886v8.8795776l0.322876,0.4036255 l0.0650024-0.0742798l0.0157471,0.0742798l2.0180664,3.0675049l1.5337524-2.5831299L41.8139381,88.5996475z"
      />
      <path
        fill={colors[1]}
        d="M58.0393181,80.2851105c-0.3228951,0.1614456-0.6457863,0.2421722-0.9686813,0.4036179 l-0.4036179,7.7494431L55.940506,89.568306l1.5337448,2.1795273l1.9373665-3.3903809l-0.2421722-0.2421722 L58.0393181,80.2851105z"
      />
      <path
        fill={colors[1]}
        d="M59.8959541,79.4778748c-0.5650635,0.2421722-1.130127,0.4843369-1.856636,0.8072357 l1.0494041,7.8301697l0.2421684,0.2421722l0.1614456-0.2421722L59.8959541,79.4778748z"
      />
      <path
        fill={colors[2]}
        d="M55.0525513,81.4152374c-0.6457901,0.2421722-1.54356,0.5547409-2.1893463,0.7161865 l-0.7166977,7.3561554l2.1795349,2.6638794l1.6951904-2.5831528l-0.9686813-1.2915726V81.4152374z"
      />
      <path
        fill={colors[2]}
        d="M57.0706367,80.6887283c-0.6457901,0.2421722-1.2915764,0.4843369-2.0180855,0.7265091v6.861496 l0.8879547,1.2915726l0.7265129-1.1301346L57.0706367,80.6887283z"
      />
      <path
        fill={colors[2]}
        d="M46.0115089,81.8995743c-0.968689-0.2421875-1.9373169-0.484314-2.8253174-0.807251 c-0.484314-0.161438-0.8879395-0.322876-1.3722534-0.484314v7.9108887l0.4662476,0.5439453l-0.0626831,0.1018677 l2.4217529,2.9867554l2.028183-2.9257584L46.0115089,81.8995743z"
      />
      <path
        fill={colors[1]}
        d="M51.3392754,82.2224731c-0.5650673,0.080719-1.1301308,0.080719-1.6144714,0.080719 c-0.6457863,0-1.4530182-0.080719-2.1795311-0.2421722l-0.4036179,6.2964325l-0.5650635,0.8879547l0.2421684,3.0674973 l2.2602539,2.4216995l2.6638756-2.5831451l0.3228912-2.7445984l-0.7265091-0.8879547 C51.3392715,88.5189056,51.3392715,82.2224731,51.3392754,82.2224731z"
      />
      <path
        fill={colors[1]}
        d="M51.3392754,82.2224731v6.2964325l0.7265091,0.8879547l0.8879585-7.5072784 C52.3886757,82.0610199,51.8236122,82.2224731,51.3392754,82.2224731z"
      />
      <path
        fill={colors[1]}
        d="M47.5452728,82.1417542c-0.4843407-0.0807266-0.9686813-0.1614456-1.5337486-0.3228989 l0.5650673,7.4265518l0.5650635-0.8879547L47.5452728,82.1417542z"
      />
      <path
        fill={colors[3]}
        d="M38.4235268,78.9128113h2.2602539L37.0512276,78.58992v0.1614456 c0.3228951,0.1614456,0.8072357,0.3228912,1.3722992,0.5650558V78.9128113z"
      />
      <path
        fill={colors[3]}
        d="M61.1067848,79.0742569c0.0807495,0,0.161499-0.0806885,0.161499-0.0806885l-0.322937-1.8566895 c-1.6144409,0.484375-7.0229492,1.9373779-11.3012695,1.9373779s-10.7362061-1.4530029-12.5928345-1.8566284 l-0.0807495,1.5337524h0.0807495v-0.161438l3.6325684,0.322876h-2.2603149v0.484314 c0.5651245,0.2421875,1.291626,0.484375,2.0988159,0.807251c0.4036255,0.161438,0.8880005,0.322876,1.291626,0.484375 c0.4035645,0.161438,0.8879395,0.322876,1.3722534,0.484314c0.968689,0.322876,1.9373779,0.5650635,2.8253174,0.807251 c0.484375,0.0806885,1.0494385,0.2421265,1.5337524,0.322876c0.807251,0.161438,1.5337524,0.2421875,2.1795654,0.2421875 c0.484314,0,1.0493774-0.0807495,1.6144409-0.0807495c0.5650635-0.0806885,1.130127-0.161438,1.6951904-0.322876 c0.7265015-0.161438,1.3723145-0.322876,2.0180664-0.5650635c0.7265625-0.2421875,1.3723145-0.484375,2.0181274-0.7265015 c0.322876-0.161438,0.645752-0.2421875,0.968689-0.4036255c0.7265015-0.322876,1.3722534-0.5650635,1.8566284-0.807251 L61.1067848,79.0742569z"
      />
    </g>
  )
}

export default PlateGoldArmor
