import React from "react"

const AxeRight = ({
  colors = ["#8B5E3C", "#6D6E71", "#D1D3D4", "#808285"]
}) => {
  return (
    <g id="AxeRight">
      <path
        fill={colors[0]}
        d="M23.4,58.1l-1-9c-0.2-0.1-0.4-0.3-0.6-0.4L18,49.4c-0.1,0.2-0.2,0.4-0.3,0.5l2.7,8.8c0,0,6,24.8,7.1,30.7 c0.9,4.8,1.4,11.2,1.5,13.4c0.3,0.2,0.6,0.3,0.9,0.4l3-1.4l-8.7-38.3L23.4,58.1z"
      />
      <path
        fill={colors[1]}
        d="M26.6,47.8c-0.6,1.6-3,3.2-6.1,3.8s-5.8,0.1-7-1.2c-2.1,2-3.1,4.6-2.6,7.2c0.5,2.6,2.5,4.6,5.2,5.7 c0.6-1.6,3-3.2,6.1-3.8c3-0.6,5.8-0.1,7,1.2c2.1-2,3.1-4.6,2.6-7.2C31.3,50.9,29.3,48.9,26.6,47.8z"
      />
      <g fill={colors[2]}>
        <path d="M12.5,57.7c-0.2-1.2,0-2.4,0.5-3.6c0-0.1-0.5-0.5-0.8-0.8c0.8,0.3,1,0.3,1.1,0.2c0.5-0.8,1-1.5,1.8-2.2 c-0.6-0.2-1.2-0.5-1.5-0.9c-2.1,2-3.1,4.6-2.6,7.2c0.5,2.6,2.5,4.6,5.2,5.7c0.1-0.4,0.4-0.7,0.7-1C14.5,61.4,12.9,59.8,12.5,57.7 z" />
        <path d="M26.6,47.8c-0.2,0.5-0.6,1-1,1.4c2.4,0.8,4.3,2.5,4.7,4.8c0.1,0.7,0.1,1.4,0,2c0,0.1,0.2,0.1,0.2,0.2 c-0.1,0-0.1,0-0.2,0c0,0.1-0.1,0.5-0.1,0.6s0.2,0.3,0.7,0.8c-0.3-0.2-0.9-0.3-1-0.2c-0.4,0.9-1,1.8-1.8,2.6 c0.4,0.2,0.8,0.4,1,0.7c2.1-2,3.1-4.6,2.6-7.2C31.3,50.9,29.3,48.8,26.6,47.8z" />
      </g>
      <polygon
        fill={colors[3]}
        points="24.1,59.4 20.2,60.1 17.9,51.9 23.2,50.8"
      />
    </g>
  )
}

export default AxeRight
