import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const JugR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("JugR") ? (
    <g id="JugR">
      <path d="M26.3962822,111.7597656c4.3271484,0,8.515625-1.7832031,11.2041016-4.7685547 c2.1933594-2.4365234,3.2197266-5.4990234,2.8916016-8.6230469l-0.0957031-0.6337891 c-0.5029297-2.5400391-1.3027344-6.9707031-1.8867188-10.2070312l-0.2929688-1.6171875L37.94804,84.8779297 c-0.3867188-1.1289062-0.9042969-2.2011719-1.5488281-3.2119141c0.2382812-1.4560547,0.0244141-2.9667969-0.6523438-4.3369141 l-0.0048828,0.0029297c-1.3759766-2.7543945-4.5332031-4.6914062-7.9257812-4.6914062 c-0.7226562,0-1.4492188,0.0849609-2.1630859,0.2524414l-1.4882812-2.0864258c-0.6035156-0.0830078-1.2128906-0.125-1.8125-0.125 c-0.9511719,0-1.8935547,0.1049805-2.7998047,0.3125l0.0117188,0.0532227 c-0.5078125,0.0688477-0.9277344,0.1757812-1.171875,0.2373047l0.3828125,1.6538086 c0,0-7.6503906,11.9228516-7.9765625,13.4511719c-0.0068359,0.0292969-0.3623047,1.7402344-0.2197266,3.4921875 c0.1523438,2.2724609,1.2529297,5.8310547,4.4404297,14.3916016c0.2148438,0.578125,0.3730469,0.9990234,0.4394531,1.1904297 l1.9931641,3.0292969c2.3535156,2.1064453,5.5292969,3.2666016,8.9433594,3.2666016H26.3962822z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(JugR, { character: "ManHalfling" })
