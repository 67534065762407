import React from "react"

const WideMouth = ({ colors = ["#CB776E", "#282560"] }) => {
  return (
    <g id="WideMouth">
      <path
        fill={colors[0]}
        d="M65.6,21.3c0,0,3.3-0.4,3.5-0.2s0.2,0.1,0.4,0c0.2-0.1,3.6,0.2,3.6,0.2S71.2,22,70.9,22 c-0.4,0-0.1,0-1.5,0s-1.2,0-1.5,0S65.6,21.3,65.6,21.3z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M65.6,21.3c0,0,3.3-0.4,3.5-0.2s0.2,0.1,0.4,0 c0.2-0.1,3.6,0.2,3.6,0.2s-3.2,0-3.7,0.1C68.8,21.2,65.6,21.3,65.6,21.3z"
      />
      <path
        opacity="0.2"
        fill={colors[1]}
        d="M68.2,22h2.3l0.2,0.8c0,0-0.5-0.3-1.4-0.3 c-0.8,0-1.3,0.3-1.3,0.3L68.2,22z"
      />
    </g>
  )
}

export default WideMouth
