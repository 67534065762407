import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const RectangleShieldRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("RectangleShieldRight") ? (
    <g id="RectangleShieldRight">
      <path
        id="S_x24_rectangleShieldRight"
        d="M28.9,108.6c-2.1,0-4.3-0.2-6.8-0.6h-0.6c-1-0.2-2-0.4-3-0.6h-0.2l-5.5-6.9V58.8l7.2-5.7 v-1.3h0.3l1.6,0.2c3.1,0.7,5.2,1,7.1,1s4-0.3,7.1-1l0.3-0.1l8.6,6.9v41.6l-5.1,6.5l0.1,0.3l-0.2,0.1l-0.6,0.2c-3,0.7-5.3,1-7.6,1.2 h-0.2l-0.1-0.1v0.1C30.4,108.5,29.7,108.6,28.9,108.6z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(RectangleShieldRight, {
  character: "ManDwarf"
})
