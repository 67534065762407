import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WarhammerR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WarhammerR") ? (
    <g id="WarhammerR">
      <path
        id="S_x24_warhammerR"
        d="M62.1143303,106.3436279c2.2932396-1.2673187,3.0296898-3.2001877,1.367897-7.8050613 c-2.7696609-7.6747818-10.5408478-23.3346939-10.5408478-23.3346939c3.7206497-1.9121017,3.7818298-7.8855209,3.7818298-7.8855209 c-1.9120979-3.7206497-5.8322144-5.6315079-9.5604362-3.6499977l-4.681778,1.9602394 c-3.7206459,1.9120941-4.1844025,6.5551605-2.2028923,10.2833862c0,0,2.386982,2.9048233,5.4214706,2.5909348 c0.570015-0.05896,6.8183327,16.2508621,10.1385269,23.6565552 C57.9763222,106.9287872,59.7187843,107.6674805,62.1143303,106.3436279z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WarhammerR, {
  character: "WomanGnome"
})
