import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongSwordRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongSwordRight") ? (
    <g id="LongSwordRight">
      <path d="M28.7466393,91.8520203c1.059145,0.6154709,2.3576336,0.8190536,3.4645042,0.6751404l1.0480194-0.1781006 c2.9580002-0.4850006,4.9276466-3.197258,4.4426422-6.1552658l-0.039753-0.3379364 c-0.005558-0.3967819-0.1629944-0.8031158-0.295784-1.1164093c1.3255501-1.1974869,1.9449997-2.8052902,1.8392677-4.5196533 c-0.2249947-2.7281876-2.1627693-4.4052124-3.3054047-5.1479111l-3.7432747-23.8993568 c-1.2753391-6.8805313-2.8942585-8.2935524-4.3064518-9.1141739l-0.6226044-0.2830887l-4.892622,0.6988792 c-1.6388359,1.3302803-2.9970016,3.5320511-1.833786,10.7409821l3.8704948,23.8158569 c-0.8603764,1.0742569-1.9545422,2.9571762-1.6078873,5.2050934c0.2576046,1.7239075,1.2348385,3.1575928,2.7647114,4.046608 c0.1765251,0.1025696,0.3530445,0.2051544,0.5295696,0.3077316c-0.0532761,0.362587-0.047718,0.7593689,0.0508747,1.1315155 l0.0397568,0.3379364C26.3818684,89.6907425,27.3344479,91.031395,28.7466393,91.8520203L28.7466393,91.8520203z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongSwordRight, {
  character: "ManGnome"
})
