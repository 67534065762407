import React from "react"

const Skarf = ({ colors = ["#7F0E05", "#262261"] }) => {
  return (
    <g id="Skarf">
      <path
        fill={colors[0]}
        d="M64.6917572,62.1584892c0,0-1.2753067-0.630764-2.1624718-0.3153839 c-0.1108971-0.4415321-0.9980621-0.504612-1.2752991-0.3784561c-1.0535164,0.6307602-1.108963,2.2076645-1.108963,2.2076645 s2.4397125,7.0645332,6.3765144,7.6322212c0,0,2.328804,0.3153839,3.8259048-0.0630722 c1.4970932-0.3153763,5.7665787-7.6322212,5.7665787-7.6322212s0-1.4507523-1.0535126-1.8922882 c-1.0535126-0.3784599-1.5525436-0.2523041-2.2733612,0.3153801c-0.3881302-0.4415321-0.9980621,0.1261559-0.9980621,0.1261559 s-1.6079941,2.0815086-3.6041183,1.9553642C66.2442932,63.9876976,65.4125748,62.5369415,64.6917572,62.1584892z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M62.5847321,61.7800293 c0,0-0.1108971,5.0460968,6.5983047,5.7399368C66.632431,66.3215103,64.6363068,65.2492218,62.5847321,61.7800293z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M72.6208038,62.4107933 c0,0-1.9961243,4.9199409-6.5983047,2.7753487C67.6859436,66.3215103,71.0682678,67.5199661,72.6208038,62.4107933z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M62.1965981,65.3753738 c2.6060524,1.7030563,6.2656136,4.5414886,10.3133125,1.5138245c-1.2753067,1.1984558-3.5486679,2.4599762-8.5944252,1.0092239 c-0.1663475,0.7569122,5.2675514,4.7937927,10.1469688-1.9553604c-0.8871689,1.3246002-3.5486679,6.7491531-8.6498795,4.0368805 C63.5273476,68.9076462,62.1965981,65.3753738,62.1965981,65.3753738z"
      />
    </g>
  )
}

export default Skarf
