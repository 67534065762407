import React from "react"
import { withSkinColor } from "../../builderConnector"

const RightThumb = ({ skinColor, color = [] }) => {
  return (
    <g id="RightThumb">
      <path
        fill={skinColor}
        d="M42,53.4c0.1-0.1,0.6-0.8,1.2-1c0.6-0.2,1.3-0.4,1.9,0.4c1.7,1.9,1.8,1.4,1.9,1.7c0.1,0.4-0.9,2.6-1,2.9 c0,0-0.5,0.3-0.8,0s0,0,0,0l-0.8-1.5L42,53.4z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M42,53.3c0.1-0.1,0.6-0.8,1.2-1c0.6-0.2,1.3-0.4,1.9,0.4 c1.7,1.9,1.8,1.4,1.9,1.7c0.1,0.4-0.9,2.6-1,2.9c0,0-0.5,0.3-0.8,0s0,0,0,0l-0.8-1.5L42,53.3z"
      />
    </g>
  )
}

export default withSkinColor(RightThumb, { character: "ManMixed" })
