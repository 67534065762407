const queryString = require('query-string')

export const LOAD_CHARACTER = 'BUILDER.LOAD_CHARACTER'
export const REMIX_CHARACTER = 'BUILDER.REMIX_CHARACTER'

export const setQuery = (search, params) => {
  const parsedSearch = queryString.parse(search)

  return queryString.stringify({
    ...parsedSearch,
    ...params
  })
}

export const characterSearch = (character, search) =>
  setQuery(search, { character })

export const setCharacterState = ({
  character,
  enabledElements,
  colors
}) => ({
  type: LOAD_CHARACTER,
  payload: {
    colors,
    enabledElements: { [character]:  enabledElements }
  }
})

export const remixCharacter = (character_id) => ({
  type: REMIX_CHARACTER,
  character_id
})