import React from "react"

const OnyxDaggerL = ({ colors = ["#44403F", "#FFFFFF"] }) => {
  return (
    <g id="OnyxDaggerL">
      <path
        fill={colors[0]}
        d="M93.7257996,54.0833931c2.907135-5.0273056,4.011116-17.9405823,4.011116-17.9405823 s-7.245636,12.5190964-7.911972,18.2880821c1.49189-0.4102669,0.1228561,1.506691,0.1228561,1.506691 s-0.9555817,1.3506012-1.0194168,3.2506371c-0.0638351,1.900032-1.0443344,3.4966583-1.0443344,3.4966583l0.1930695,0.0639038 l1.5383987,0.5091972l0.7283707-0.4934464c0,0,0.0218735-1.6879196,1.1042404-3.2508278 c1.0823135-1.5629272,0.9745712-3.968708,0.9745712-3.968708S92.7732849,52.8640518,93.7257996,54.0833931z"
      />
      <path
        opacity="0.3"
        fill="#FFF"
        d="M90.2239609,54.1643219 c-0.0593567,0.0605927,2.5764236-0.9553871,2.5764236-0.9553871l4.8755112-16.7786484 c0,0-3.3119354,11.1371269-5.0820847,16.3571205C91.9396667,53.2913284,91.2977524,53.5662155,90.2239609,54.1643219z"
      />
      <path
        opacity="0.65"
        fill="#FFF"
        d="M97.7315674,36.1887894c0,0-6.6006546,11.1211891-7.8447266,17.9910889 c0.2405396-0.1023636,0.1582642-0.2228279,0.3613205-0.6035881c0.0814133-0.1526604,0.9072571-2.9965439,2.3386841-6.3268585 C94.7255859,42.2734947,97.7315674,36.1887894,97.7315674,36.1887894z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M93.0973358,53.2507172l0.580307,0.6794319 c0,0,2.9465103-5.445118,3.8282547-15.6632118c-1.1256943,9.162632-3.7213364,15.0841103-3.7213364,15.0841103 L93.0973358,53.2507172z"
      />
      <path
        opacity="0.3"
        fill="#FFF"
        d="M91.3285446,55.0949821c0,0-0.0451813-1.3145714,0.9387283-1.2996826 c0.6724396,0.1236839,0.0966492,1.5858765,0.0206146,1.7302246c-0.0760269,0.144352,0.2847443,2.2979469-1.3138733,4.3963051 c-0.6749573,1.6977692-0.7490158,2.7751045-0.7490158,2.7751045l-0.6439285,0.3236618l0.7056274-3.2415047 C90.2866974,59.7790909,91.7241058,57.6556282,91.3285446,55.0949821z"
      />
      <path
        opacity="0.65"
        fill="#FFF"
        d="M90.6766891,54.6743927 c0.0016937-0.0603256-0.1882935,1.2090416-0.6073532,1.5788803c-0.4190674,0.3698387-0.8267136,3.1378593-0.864769,3.1888313 c-0.038063,0.050972-0.6299515,2.9275208-1.0047684,3.163681c-0.0199051-0.3879967,0.9103546-2.6863861,0.9020233-3.6214752 c-0.0083389-0.9350929,0.3066406-2.3988533,0.8527908-2.8325768 C90.3162537,55.6993217,90.6707687,54.8843269,90.6766891,54.6743927z"
      />
    </g>
  )
}

export default OnyxDaggerL
