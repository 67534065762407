import React from "react"

const Turban = ({ colors = ["#785C8E", "#96842D"] }) => {
  return (
    <g id="Turban">
      <path
        fill={colors[0]}
        d="M76.9,9.6c-0.5-0.5-3.8-2.3-7.7-0.6c-4-1.3-6.2-0.4-7.2,0.3c-0.9,0.4-0.6,1.6-0.5,1.7c0,0,0.4,2.4,1.2,3 c0.1,0.4,0.4,1.2,0.6,1.2c2.5-2.2,5.8-3.1,5.8-3.1c2.2,0.3,6,3.1,6,3.1s0.3-0.7,0.5-1.2c0.7-0.6,1-2.1,1.2-2.6 C77,10.8,77.4,10.1,76.9,9.6z"
      />
      <circle fill={colors[1]} cx="69.2" cy="10.5" r="2.2" />
    </g>
  )
}

export default Turban
