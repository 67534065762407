import React from "react"

const DaggerLeft = ({
  colors = [
    "#6E5E26",
    "#99B7D9",
    "#6B91BF",
    "#57789B",
    "#E1E2E3",
    "#9EA0A3",
    "#ECD592"
  ]
}) => {
  return (
    <g id="DaggerLeft">
      <polygon
        fill={colors[0]}
        points="86.6364288,79.4421616 87.4045258,80.7688675 85.2398911,81.7464523 84.6114426,84.0507431 83.4243851,86.9136581 83.5640411,87.9610596 82.7959442,88.6593323 81.8881912,88.3800201 81.6088791,87.3326187 82.2373199,86.7041702 82.9355927,83.5619507 83.9131775,81.3274841 82.7261124,79.2326736 84.192482,78.6042328"
      />
      <polygon
        fill={colors[1]}
        points="81.9580154,88.3101959 82.2373199,88.0308838 82.6562881,88.1705475 82.7261124,88.5895081"
      />
      <polygon
        fill={colors[2]}
        points="82.7959442,88.5895081 82.7261124,88.1705475 83.1450806,87.8214111 83.5640411,87.9610596"
      />
      <polygon
        fill={colors[1]}
        points="81.9580154,88.2403717 82.1674957,87.9610596 82.0976715,87.4722748 81.6787109,87.3326187"
      />
      <polygon
        fill={colors[3]}
        points="82.3071518,86.7740021 82.4468079,87.1231384 83.0054169,87.2627869 83.3545532,87.0533066"
      />
      <polygon
        fill={colors[2]}
        points="81.7485352,87.2627869 82.0976715,87.4024429 82.3769836,87.1231384 82.3071518,86.7740021"
      />
      <polygon
        fill={colors[3]}
        points="83.2149048,87.7515793 83.0752487,87.3326187 83.3545532,87.1231384 83.4942093,87.8912354"
      />
      <polygon
        fill={colors[2]}
        points="82.1674957,87.4024429 82.3071518,87.9610596 82.7261124,88.1007156 83.0752487,87.7515793 83.0054169,87.4024429 82.4468079,87.1929626"
      />
      <path
        fill={colors[4]}
        d="M86.7062607,80.4197311l-1.5361938-0.6284409l-1.6758575-0.3491364 c0,0,0.9775848-1.7456818,1.6060257-5.4465179c0.9775848-3.7008362,3.9103165-6.2844391,3.9103165-6.2844391l0,0 L86.7062607,80.4197311z"
      />
      <path
        fill={colors[5]}
        d="M85.1700668,79.7912903l3.8404846-12.0800858c0,0,0.5586243,4.3991089-0.7680969,7.960289 c-1.1172333,2.4439468-1.6060257,4.8180695-1.6060257,4.8180695L85.1700668,79.7912903z"
      />
      <path
        fill={colors[6]}
        d="M83.9830017,85.3774643l-0.5586166,1.3965378 c-0.1396561,0.0698242-0.3491364,0.0698242-0.4887924,0.0698242l-0.1396484-0.0698242 c-0.1396561-0.0698242-0.2793121-0.1396484-0.3491364-0.2793121l0.4887848-2.4439468 C83.2149048,84.6093597,83.4942093,85.0283279,83.9830017,85.3774643z"
      />
      <path
        fill={colors[6]}
        d="M83.284729,83.0033417l0.6982727-1.6060257l0.4887848,0.6982651l0.6982727-0.2793045 l-0.5586166,2.0948105l-0.4887924,1.1172333C83.6338654,84.6093597,83.3545532,83.6317825,83.284729,83.0033417z"
      />
    </g>
  )
}

export default DaggerLeft
