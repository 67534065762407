import React from "react"

const MaulR = ({ colors = ["#888787", "#282560", "#FFFFFF"] }) => {
  return (
    <g id="MaulR">
      <path
        fill={colors[0]}
        d="M43.3156548,44.4337997c-0.2333641,0.3500786,5.2151146,31.6502228,5.2151146,31.6502228 c-0.0653305,0.3407288,0.7328453,1.0548248,1.8063583,0.6580353c1.0735092-0.3967896,0.8540955-1.311615,0.8540955-1.311615 S45.7147179,43.6262093,45.62603,43.5468636C45.4486618,43.388176,43.6330299,44.0790558,43.3156548,44.4337997z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M46.6064796,49.0544891 c-0.5182495-3.2579041-0.980442-5.5076294-0.980442-5.5076294c-0.1773758-0.1586838-2.0770226,0.5368652-2.3103828,0.88694 c-0.0793457,0.0886879,0.3035011,2.4271011,0.8264198,5.7690201c0.1680298-0.0093422,0.3360558-0.0186844,0.5834351-0.1167145 C44.9775543,50.0720901,45.7943497,49.6053009,46.6064796,49.0544891z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M45.127182,55.7944641L45.127182,55.7944641 c0.004673,0.0840149,0.0093422,0.1680336,0.0140152,0.2520447c0.9568672,0.5367203,1.9090691,0.9894295,2.9452744,1.4374619 c-0.0933571-0.1633606-0.0980263-0.2473755-0.1073685-0.4154053C47.0269089,56.61586,45.9906998,56.1678276,45.127182,55.7944641z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M51.0652428,76.1959152 c0.2333565-0.3500748,0.1306572-0.6814651,0.1306572-0.6814651s-0.1494141-1.1715393-0.5042343-3.0058594 c-0.3454056-0.1493378-0.6114616-0.3873749-0.9568672-0.5367203c0.1494141,1.1715469,0.2194862,2.4317703,0.2895584,3.6920013 C50.3697548,75.8132172,50.7151604,75.9625626,51.0652428,76.1959152z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M47.8488693,66.8516922 c-0.1213837-0.6674423-0.1540871-1.2555542-0.2707977-1.8389893c-0.3500786-0.2333603-0.7841682-0.4620438-1.134243-0.6954041 c0.1213913,0.66745,0.242775,1.3349075,0.3594894,1.918335C47.1487236,66.3849792,47.4988022,66.6183395,47.8488693,66.8516922z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M49.1561775,75.2064972 c-0.2427711-1.3349075-0.401535-2.6744766-0.6443062-4.0093765c-0.3454056-0.1493378-0.6114616-0.3873749-0.9568672-0.5367126 c0.3501434,1.7502975,0.6022644,3.2532349,0.7376633,4.1727295C48.5540543,74.9871521,48.899456,75.1364975,49.1561775,75.2064972 z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M47.8250084,55.8130074 c-0.0093422-0.1680336-0.1073723-0.4154053-0.1167145-0.5834389c-0.8681793-0.4573746-1.8157043-0.8260612-2.7632256-1.1947517 c-0.0840149,0.0046692-0.0840149,0.0046692-0.1680298,0.0093422c0.004673,0.0840187,0.0140152,0.2520485,0.0186882,0.3360634 C45.8366051,54.9122696,46.7888069,55.364975,47.8250084,55.8130074z"
      />
      <path
        fill={colors[0]}
        d="M50.3776016,44.1254158c0,0-8.6673622,4.7799492-8.475975,5.1906853 c0.1913834,0.4107323-5.1155815,0.4529915-5.0549278,0.028244c0.0653343-0.3407364-3.1649742-8.420063-3.1649742-8.420063 s5.5728836-2.8381157,9.9509468-4.4299469c3.1879005-0.2615318,5.8670464-0.579052,6.0397453-0.5043793L50.3776016,44.1254158z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M39.5535126,41.9461937l0.1026993,0.3313904 c0.1633568-0.0933571,0.3313904-0.1026993,0.4200745-0.0233574c-0.5367889-0.5600777,0,0,0.0933571,0.1633568 c0.0886841,0.0793457,0.0980301,0.2473793,0.1867142,0.3267212l0,0c-0.0840187,0.004673-0.1633644,0.0933571-0.2473755,0.0980263 c0.0886841,0.0793457,0.0933571,0.1633606,0.1820412,0.2427025c0,0-0.0746765,0.1727028-0.2380333,0.2660599l0.3127785,1.0781822 c0.1586876-0.177372,0.2380333-0.2660637,0.2333603-0.3500748c0.004673,0.0840111,0.0093422,0.1680298,0.0140152,0.2520447 c0.0046692,0.0840111-0.0699997,0.2567139-0.1446724,0.4294167l0.0933571,0.1633606 c0.1586914-0.1773758,0.3173752-0.3547478,0.3173752-0.3547478c0.004673,0.0840187,0.0886879,0.0793457,0.0933571,0.1633606 c0.004673,0.0840111-0.1540108,0.2613869-0.3967171,0.4434319l0,0c0.0840149-0.004673,0.0793419-0.0886841,0.1633568-0.0933571 c-0.004673-0.0840149-0.004673-0.0840149-0.004673-0.0840149l0,0c0.1633644-0.0933571,0.2427063-0.182045,0.2427063-0.182045 l0.004673,0.0840149c0,0-0.0793419,0.0886879-0.1586876,0.177372c0.1773796,0.1586876,0.2660637,0.2380333,0.3594208,0.4013901 c0,0-0.0793419,0.0886917-0.0746689,0.1727028c0.0046692,0.0840187,0.4061317,1.2415428,0.0933533,0.1633606l0.0091324-4.3828049 L39.5535126,41.9461937z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M40.5429916,41.5540733l1.5173035,7.5846519 c0,0,7.4911499-4.71455,8.1539307-4.9199524c0.6627808-0.2053986-0.5324783-8.0607834-0.5324783-8.0607834l-0.3267174,0.186718 c0.0186844,0.3360634-0.0606575,0.4247475-0.312706,0.4387627c-0.0840149,0.0046692-0.2520485,0.0140152-0.3360596,0.0186844 l-1.0595016,0.648838l0.0886841,0.0793419l-0.0840111,0.0046692c-0.1633568,0.0933609-0.4154053,0.1073761-0.5787621,0.2007332 l-1.3862114,0.8355522l0,0l0,0l-0.0793419,0.0886879c0.0046692,0.0840111-0.0793419,0.0886841-0.0793419,0.0886841 c-0.0699997,0.2567177-0.1540146,0.2613907-0.4013901,0.359417c-0.0840149,0.004673-0.0840149,0.004673-0.1680298,0.009346 c-0.2426987,0.182045-0.4060593,0.2754021-0.4900742,0.2800713c-0.0840149,0.004673-0.0840149,0.004673-0.0840149,0.004673l0,0 l-0.4107323,0.1913872c0.004673,0.0840187,0.0886841,0.0793457,0.0933571,0.1633606 c0.0933571,0.1633606,0.1026993,0.3313904,0.0233574,0.4200745l0,0l0,0L44.01091,40.2656784 c-0.2427063,0.1820412-0.5834312,0.1167145-0.8354797,0.1307259c-0.2520485,0.0140152-0.4200783,0.0233574-0.672123,0.0373726 l-0.9801521,0.5601501l0,0c0.0933647,0.1633568,0.1820488,0.2426987,0.2754059,0.4060593c0,0,0,0-0.0840149,0.004673 c0.0793495-0.0886879,0.0840225-0.004673,0,0c0.0793495-0.0886879,0.0793495-0.0886879,0,0l0,0 C41.2944717,41.4280167,40.8790665,41.5353851,40.5429916,41.5540733z"
      />
    </g>
  )
}

export default MaulR
