import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import PlateArmor from "./PlateArmor"
import LeatherArmor from "./LeatherArmor"
import PlateGoldArmor from "./PlateGoldArmor"

const CATEGORY = "armor"
const CHARACTER = "ManDwarf"
export const IDS = {
  PLATE_ARMOR: "PlateArmor",
  LEATHER_ARMOR: "LeatherArmor",
  PLATE_GOLD_ARMOR: "PlateGoldArmor"
}

export const components = {
  [IDS.PLATE_ARMOR]: PlateArmor,
  [IDS.LEATHER_ARMOR]: LeatherArmor,
  [IDS.PLATE_GOLD_ARMOR]: PlateGoldArmor
}

export const Group = ({ props }) => (
  <g id="armor">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.PLATE_ARMOR,
    name: "Plate Armor",
    defaultColors: ["#737373", "#9E9E9E", "#595A5A"],
    colorable: false,
    component: components[IDS.PLATE_ARMOR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.LEATHER_ARMOR,
    name: "Leather Armor",
    defaultColors: ["#754C29", "#603913", "#8B5E3C"],
    colorable: false,
    component: components[IDS.LEATHER_ARMOR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.PLATE_GOLD_ARMOR,
    name: "Plate Gold Armor",
    defaultColors: ["#8A784E", "#EED793", "#A59A76", "#E6BB21"],
    colorable: false,
    component: components[IDS.PLATE_GOLD_ARMOR],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
