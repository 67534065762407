import React from "react"

const MaulL = ({ colors = ["#898888", "#262261", "#FFFFFF"] }) => {
  return (
    <g id="MaulL">
      <path
        fill={colors[0]}
        d="M95.8,40.3c-0.1,0.1-4.5,38.2-4.5,38.2s-0.2,1.1,1.1,1.5c1.3,0.4,2.2-0.5,2.1-0.9c0,0,4.4-37.5,4.1-37.9 C98.2,40.8,96,40.1,95.8,40.3z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M97.3,48c0.3,0.1,0.5,0.1,0.7,0.1c0.4-4,0.7-6.8,0.6-6.9 c-0.3-0.4-2.6-1.1-2.8-0.9c0,0-0.4,2.7-0.8,6.6C96,47.5,97,48,97.3,48z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M93.9,56.5c0,0.2,0,0.3-0.1,0.5c1.2-0.6,2.3-1.2,3.4-1.9 c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0C96.2,55.3,95,55.9,93.9,56.5z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M92.7,78.7c0-1.5,0-3,0.1-4.4c-0.4,0.2-0.7,0.5-1.1,0.7 c-0.3,2.2-0.4,3.6-0.4,3.6s-0.1,0.4,0.2,0.8C91.9,79.1,92.3,78.9,92.7,78.7z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M95.9,67.3c0.1-0.7,0.2-1.5,0.3-2.3c-0.4,0.3-0.9,0.6-1.3,0.9 c-0.1,0.7-0.1,1.4-0.2,2.2C95.1,67.8,95.5,67.5,95.9,67.3z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M94.7,77.6c0.1-1.1,0.3-2.9,0.6-5c-0.4,0.2-0.7,0.5-1.1,0.7 c-0.2,1.6-0.3,3.2-0.5,4.8C94,78,94.4,77.8,94.7,77.6z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M97.5,53.1c0-0.1,0-0.3,0-0.4c-0.1,0-0.1,0-0.2,0 c-1.1,0.5-2.2,1-3.2,1.6c0,0.2-0.1,0.5-0.1,0.7C95.2,54.4,96.3,53.8,97.5,53.1z"
      />
      <path
        fill={colors[0]}
        d="M90.5,31.6c0.2-0.1,3.4,0.1,7.2,0.2c5.3,1.6,12.1,4.6,12.1,4.6s-3.3,9.8-3.2,10.2c0.1,0.5-6.2,0.8-6,0.3 s-10.4-5.6-10.4-5.6L90.5,31.6z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M100.9,37.5l0.3,5.2c-0.3,1.3,0.1-0.1,0.1-0.2 c0-0.1-0.1-0.2-0.1-0.2c0.1-0.2,0.2-0.3,0.4-0.5c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0-0.1,0-0.1c0,0,0.1,0.1,0.3,0.2c0,0,0,0,0,0 c0,0,0,0,0,0.1c0.1,0,0.1,0.1,0.2,0.1l0,0c-0.3-0.2-0.5-0.4-0.5-0.5c0-0.1,0.1-0.1,0.1-0.2c0,0,0.2,0.2,0.4,0.4l0.1-0.2 c-0.1-0.2-0.2-0.4-0.2-0.5c0-0.1,0-0.2,0-0.3c0,0.1,0.1,0.2,0.3,0.4l0.3-1.3c-0.2-0.1-0.3-0.3-0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3 c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.7-0.9,0.1-0.2c0.1-0.1,0.3-0.1,0.5,0l0.1-0.4L100.9,37.5z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M100.3,37.5C100.3,37.5,100.3,37.5,100.3,37.5 C100.2,37.4,100.2,37.4,100.3,37.5C100.2,37.5,100.2,37.4,100.3,37.5c-0.1,0-0.1,0-0.1,0c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0,0,0,0 l-1.2-0.6c-0.3,0-0.5,0-0.8,0c-0.3,0-0.7,0.1-1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.3,0-0.5 c0-0.1,0.1-0.1,0.1-0.2l-0.5-0.2c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1,0-0.3-0.1-0.6-0.3c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.4-0.1-0.5-0.4 c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0l-1.7-0.9c-0.2-0.1-0.5-0.1-0.7-0.2c0,0-0.1,0-0.1,0 c0,0,0.1-0.1,0.1-0.1l-1.3-0.7c-0.1,0-0.3,0-0.4,0c-0.3,0-0.4-0.1-0.4-0.5l-0.4-0.2c0,0-0.9,9.4-0.1,9.6c0.8,0.2,10,5.3,10,5.3 l1.3-9.1C101.3,37.6,100.8,37.5,100.3,37.5z"
      />
    </g>
  )
}

export default MaulL
