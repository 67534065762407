import React from "react"

const Banner = ({ colors = ["#4D576B", "#9D9D9D", "#3D527E"] }) => {
  return (
    <g id="Banner">
      <path
        fill={colors[0]}
        d="M59.0104523,71.7142639l1.9488945-13.9211693 c0.109478-1.1876793,1.8038902-3.1333618,0.0743294-3.5692825l-2.9447212,0.07061 c-1.7295609-0.4359207-3.064373,4.1029053-3.5309029,5.200592l-7.2493782,9.2699165c0,0,2.3572426,1.3637466,5.5365715,2.1650696 C56.0245781,71.7313156,59.0104523,71.7142639,59.0104523,71.7142639z"
      />
      <path
        fill={colors[0]}
        d="M78.1907578,71.7042007l-1.9488983-13.9211693 c-0.1094742-1.1876793-1.8038864-3.1333618-0.0743256-3.5692825l2.9447174,0.07061 c1.7295609-0.4359207,3.0643768,4.1029015,3.5309067,5.200592l7.2493744,9.2699165c0,0-2.3572388,1.3637466-5.5365677,2.165062 C81.1766357,71.7212524,78.1907578,71.7042007,78.1907578,71.7042007z"
      />
      <path
        fill={colors[1]}
        d="M48.377594,67.3980865c0,0,0.4094582,2.9138336,8.869175,3.0717468 c1.1580238-5.0536423,3.3074646-15.1220932,3.3074646-15.1220932l-4.1279869,0.0000038L48.377594,67.3980865z"
      />
      <path
        fill={colors[1]}
        d="M89.0550232,67.1290131c0,0-0.409462,2.9138412-8.8691788,3.0717545 c-1.15802-5.0536423-3.9545517-14.8530273-3.9545517-14.8530273l3.5955276,0.0000038L89.0550232,67.1290131z"
      />
      <path
        fill={colors[2]}
        d="M55.7967377,55.3477402l-7.7403717,11.459259c0,0,2.5678482,2.169014,8.3698807,2.7659454 c1.1866608-4.7243271,3.3043022-14.6609573,3.3043022-14.6609573l-3.71278-0.3786964L55.7967377,55.3477402z"
      />
      <path
        fill={colors[2]}
        d="M80.6208725,55.5903664l8.7292099,10.7251625c0,0-2.3646927,2.3888626-8.0906067,3.4996338 c-1.6022797-4.6000061-4.5955811-14.3088303-4.5955811-14.3088303l3.6643677-0.7075195L80.6208725,55.5903664z"
      />
    </g>
  )
}

export default Banner
