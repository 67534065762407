import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LuteRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LuteRight") ? (
    <g id="LuteRight">
      <path
        id="S_x24_luteRight"
        d="M37.7000008,119.4000015c-11.3999996,0-16.1000004-9.3000031-17.5-13.8000031L20,104.6999969 c-1-6.0999985-0.3999996-12.5999985,0-16l-3.6000004-8.0999985c0,0-4.6000004-8.4000015-4.6000004-12 S21.5,61.7999992,21.5,61.7999992l15.2999992,19.0000038c7.4000015,4.8000031,15.2999992,16.4000015,16.3999977,20.3000031 c3,10.3000031-2.7999992,15.1999969-6.5999985,17.3000031C45.5,119,42.0999985,119.4000015,37.7000008,119.4000015 L37.7000008,119.4000015z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LuteRight, {
  character: "ManHalfling"
})
