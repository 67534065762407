import React from "react"

const SidePlates = ({ colors = ["#898888"] }) => {
  return (
    <g id="SidePlates">
      <path
        opacity="0.3"
        fill="#21376C"
        d="M85,66.4c0.5,2.5,0.9,5.1,1,7.6c0.1,2.4-0.1,4.9-0.5,7.3 c0,0.1-0.1,0.2-0.1,0.2c-1.4,0-2.8,0-4.2,0l0,0c-1.2-4.5-2.6-8.9-4.4-13.2L85,66.4z"
      />
      <path
        fill={colors[0]}
        d="M85.4,65.8c0.5,2.5,0.9,5.1,1,7.6c0.1,2.4-0.1,4.9-0.5,7.3c0,0.1-0.1,0.2-0.1,0.2c-1.4,0-2.8,0-4.2,0l0,0 c-1.2-4.5-2.6-8.9-4.4-13.2L85.4,65.8z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M72.8,59.4L72.8,59.4c2-1.9,4-3.6,6.2-5l-0.1-0.1 c0,0,0.1-0.2,0.2-0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1 c2.7,2.5,4.4,5.7,5.6,9.1c0.5,1.5,1,3.1,1.3,4.7l-8.3,1.9C76.4,66.4,74.7,62.8,72.8,59.4z"
      />
      <path
        fill={colors[0]}
        d="M73.5,58.5L73.5,58.5c2-1.9,4-3.6,6.2-5l-0.1-0.1c0,0,0.1-0.2,0.2-0.2c0.1,0.1,0.1,0.1,0.2,0.2 c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c2.7,2.5,4.4,5.7,5.6,9.1c0.5,1.5,1,3.1,1.3,4.7l-8.3,1.9 C77.1,65.5,75.4,61.9,73.5,58.5z"
      />
      <g>
        <path
          opacity="0.3"
          fill="#21376C"
          d="M53.3,66.4c-0.5,2.5-0.9,5.1-1,7.6c-0.1,2.4,0.1,4.9,0.5,7.3 c0,0.1,0.1,0.2,0.1,0.2c1.4,0,2.8,0,4.2,0l0,0c1.2-4.5,2.6-8.9,4.4-13.2L53.3,66.4z"
        />
        <path
          fill={colors[0]}
          d="M52.9,65.8c-0.5,2.5-0.9,5.1-1,7.6c-0.1,2.4,0.1,4.9,0.5,7.3c0,0.1,0.1,0.2,0.1,0.2c1.4,0,2.8,0,4.2,0 l0,0c1.2-4.5,2.6-8.9,4.4-13.2L52.9,65.8z"
        />
        <path
          opacity="0.3"
          fill="#21376C"
          d="M65.6,59.4L65.6,59.4c-2-1.9-4-3.6-6.2-5l0.1-0.1 c0,0-0.1-0.2-0.2-0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1,0,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1 c-2.7,2.5-4.4,5.7-5.6,9.1c-0.5,1.5-1,3.1-1.3,4.7l8.3,1.9C62,66.4,63.7,62.8,65.6,59.4z"
        />
        <path
          fill={colors[0]}
          d="M64.9,58.5L64.9,58.5c-2-1.9-4-3.6-6.2-5l0.1-0.1c0,0-0.1-0.2-0.2-0.2c-0.1,0.1-0.1,0.1-0.2,0.2 c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1,0,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1c-2.7,2.5-4.4,5.7-5.6,9.1c-0.5,1.5-1,3.1-1.3,4.7 l8.3,1.9C61.3,65.5,63,61.9,64.9,58.5z"
        />
      </g>
    </g>
  )
}

export default SidePlates
