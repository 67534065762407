import React from "react"

const Cloak = ({ colors = ["#304B60"] }) => {
  return (
    <g id="Cloak">
      <path
        opacity="0.2"
        fill="#21376C"
        d="M92.8559723,56.4074593 c0,0-12.1431274-20.7782516-12.5029297-21.1380501c-6.5662842-6.9260864-15.2014008-6.9260864-22.1274872-0.8994942 c-2.1587791,1.3492393-12.3532753,18.967041-12.44384,19.3741646c-0.0052643,0.0236702-5.6209373,57.7895813-4.9013443,58.5991364 c2.0688324,0.2698517,10.2392006,8.5151596,15.9059982,8.5151596 c-1.1693382-15.2913589,1.7374191-84.9303436,11.4519272-86.3695374 c12.9526749-1.9788818,10.8554611,77.0148163,8.7866287,86.4594879c3.0582733,0,17.3545303-6.2333908,18.9736176-8.1223221 C97.7075729,111.1169586,95.5544434,77.3656082,92.8559723,56.4074593z"
      />
      <path
        fill="#21376C"
        d="M87.334198,34.8150673c-0.4497452-1.8889313-11.5134964-7.3758335-12.0531845-9.0848656 c-0.3598022-0.9894409-0.0899506-10.0743055-0.3598022-11.0637465c-0.9894409-1.709033-3.5080185-6.2964401-6.5662918-5.9366436 s-5.2170486,3.9577627-5.8466911,5.8466949c-0.1799011,0.4497461,0.5396919,9.8044577,0.1798973,10.7938986 c-0.6296425,1.709034-15.1256256,25.2516079-17.194458,28.9395199 c6.2762299-1.4885902,10.1784248-16.4365921,13.2366943-18.3255196 c1.6190834-1.0793915,9.8044586-2.3969498,9.5346146-4.0160351c-5.8466988-7.1959324-4.1376648-14.8732872-3.5080185-14.9632359 c2.2487259,0.5396957,5.2170486,0.719593,7.4657822,0c0.6296387,0,2.1587753,6.6879158-3.4180756,14.9632359 c-0.1798935,1.6190853,8.8150253,3.0265942,10.3441544,4.1959324 c2.9683228,2.2487297,8.5328064,17.7887802,11.8609238,19.1380196 C89.1208191,51.524456,88.4135818,38.8627777,87.334198,34.8150673z"
        opacity="0.2"
      />
      <path
        fill={colors[0]}
        d="M93.8528442,59.4059906c-0.0498657,0.0111084-0.1060181-0.0008545-0.1670532-0.031311 c-0.8820801-4.2493286-5.1294937-22.0121841-6.828186-25.7462769c-2.4737701-5.4378967-8.2942276-3.8489933-8.9903946-4.3697529 c-0.2244949-0.3527794-2.4035339,0.0320129-2.4139328-1.8775043c-0.0187607-3.4460888,2.399826-9.0148468,1.126503-11.9087429 c-1.5291138-3.5979614-3.8441849-8.1991024-7.8918533-8.1991024c-3.7778931-0.0899658-6.5645828,4.457593-8.0937576,8.1454592 c-1.112381,2.5520029,0.6034889,7.5422964,0.3218575,11.3186712c-0.1055069,1.4147396-1.9449959,1.316864-2.0920753,2.1993618 c0-0.0899658-6.4371567-0.4827862-8.3957291,4.8714809c-1.0794067,3.9577637-4.8406906,19.629982-5.1625366,20.9576187 c-0.0400314-0.1654129-0.7656517,5.7827377-1.2069702,5.7129745c-1.7989502,9.984375-8.2921753,48.9860229-7.9324341,49.0759888 c0.5397339,4.3175659,12.5029907,9.3547363,12.5029907,9.3547363s-2.6796379-55.2121429-2.7563477-58.2435303 c2.019104-8.0601807,10.9158936-24.4418945,13.1904297-25.958252c1.6190796-1.0794067,8.3652344-0.8678589,8.2753296-2.4869385 c-5.9366455-7.3757935-4.0477295-15.1431274-3.8678589-16.4923706c3.1482544-4.4074707,8.3652954-3.0582886,10.5240479,0 c0.4497681,2.5185547,1.7090454,8.3969727-3.8677979,16.5823364c-0.0899658,1.6190796,7.2858887,1.1376343,8.9049683,2.217041 c2.1902466,1.460144,8.3796997,14.0087852,12.2995605,21.2840538l-2.7649536,61.9662514 c0,0,11.0637817-2.5186157,11.6934204-5.8466797C100.7097778,111.8415375,95.7417603,69.8401337,93.8528442,59.4059906z"
      />
    </g>
  )
}

export default Cloak
