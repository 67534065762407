import React from "react"

const Boots = ({
  colors = ["#494846", "#01060F", "#FFFFFF", "#6B6967", "#9C7000"]
}) => {
  return (
    <g id="Boots">
      <path
        fill={colors[0]}
        d="M52.6165352,104.2529831l0.2256966,9.9024048l-0.3385429,5.755249h10.015255 c0,0-0.7617226-5.0781555-2.313385-6.4323349c0.0282135-2.2005386,0.9027863-8.2590561,0.9027863-8.2590561 S57.0458183,107.187027,52.6165352,104.2529831z"
      />
      <path
        fill={colors[0]}
        d="M44.4735069,104.605629l-0.09272,9.5920792l0.3385429,5.755249h-10.015255 c0,0,0.7617226-5.0781631,2.3133812-6.4323349c-0.0282097-2.2005386-0.8171158-8.3860168-0.8171158-8.3860168 S40.1782303,107.1658707,44.4735069,104.605629z"
      />
      <path
        opacity="0.23"
        fill={colors[1]}
        d="M58.0939751,112.5632401 c-0.1072121-1.1968307-0.3435211-5.9570465-0.3992805-6.6739655c-0.5933838,0.0703735-1.5016708-0.2052383-2.1716843-0.2977295 c0.0332146,0.7969208,0.3683319,4.5452118,0.4550667,7.2256012 C56.6833763,112.506813,57.2476158,112.3657532,58.0939751,112.5632401z"
      />
      <path
        opacity="0.23"
        fill={colors[1]}
        d="M39.1290436,112.4785995 c0.1072121-1.1968231,0.4573975-5.815979,0.5131607-6.5328979c0.59338,0.0703659,1.3330383-0.1614151,2.0030479-0.2539062 c-0.0332108,0.7969131-0.3135757,4.3603287-0.4003105,7.0407181 C40.5396423,112.4221802,39.9754028,112.2811203,39.1290436,112.4785995z"
      />
      <path
        opacity="0.23"
        fill={colors[1]}
        d="M61.446888,115.6506271l0.0211601-0.1057968 c0,0-3.8615189-5.2474289-8.2935791-0.027298c0.2387581,0.1328888,0.6647301,0.3843307,0.9027824,0.5363617 c0.0096321,0.0003815,0.2407875-0.0764313,0.3865852-0.0641022 C57.5324745,112.3180847,60.8967552,115.3014984,61.446888,115.6506271z"
      />
      <path
        opacity="0.23"
        fill={colors[1]}
        d="M35.7761307,115.6506271l-0.0211601-0.1057968 c0,0,3.861515-5.2474289,8.2935791-0.027298c-0.2387581,0.1328888-0.6647339,0.3843307-0.9027824,0.5363617 c-0.0096321,0.0003815-0.2407913-0.0764313-0.3865852-0.0641022 C39.6905441,112.3180847,36.3262634,115.3014984,35.7761307,115.6506271z"
      />
      <path
        fill={colors[0]}
        d="M36.2627869,114.3705063c0.4231796-0.9309921,1.3012772-1.8196716,3.8298073-1.653923 c1.5912476,0.2585983,2.993969,0.6382904,3.9227753,2.7087021c-0.3019295,0.153244-0.6034737,0.3447495-0.9041824,0.5403671 c-0.0065498,0.0001907-0.021637,0.0014114-0.0267715,0.0014725c-0.5745201-0.9228668-1.9969292-1.8827515-3.4679298-1.9563217 c-1.9590416-0.0979691-3.108139,0.8316345-3.8086166,1.5763474 C35.5126152,115.5710983,35.9348221,115.1004944,36.2627869,114.3705063z"
      />
      <path
        opacity="0.23"
        fill={colors[1]}
        d="M40.7414398,114.5785675l-0.2821198,0.5360336 c0,0-0.9592056-0.64888-2.0030518-0.0282135c0.4796066-1.128479,0.4796066-0.7617264,0.4796066-0.7617264 S39.7822342,114.014328,40.7414398,114.5785675z"
      />
      <path
        fill={colors[0]}
        d="M60.9602318,114.3705063c-0.4231796-0.9309921-1.3012772-1.8196716-3.8298111-1.653923 c-1.5912437,0.2585983-2.9939651,0.6382904-3.9227715,2.7087021c0.3019295,0.153244,0.6034737,0.3447495,0.9041824,0.5403671 c0.0065498,0.0001907,0.021637,0.0014114,0.0267715,0.0014725c0.5745201-0.9228668,1.9969254-1.8827515,3.467926-1.9563217 c1.9590454-0.0979691,3.108139,0.8316345,3.8086205,1.5763474 C61.7104034,115.5710983,61.2881966,115.1004944,60.9602318,114.3705063z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M44.0482407,115.4070511 c-0.3025093,0.1504211-0.6044388,0.3420563-0.9057236,0.5379257c-0.567524-0.9848862-2.4893379-2.2272263-4.033844-2.3044586 c-0.9168167-0.0458374-1.5234871-0.225708-1.6253281-0.428627 C39.1827278,111.8208466,43.6261177,112.8682175,44.0482407,115.4070511z"
      />
      <path
        opacity="0.23"
        fill={colors[1]}
        d="M56.4815788,114.5785675l0.2821198,0.5360336 c0,0,0.9592056-0.64888,2.0030518-0.0282135c-0.4796066-1.128479-0.4796066-0.7617264-0.4796066-0.7617264 S57.4407845,114.014328,56.4815788,114.5785675z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M53.174778,115.4070511 c0.3025093,0.1504211,0.6044388,0.3420563,0.9057236,0.5379257c0.567524-0.9848862,2.4893379-2.2272263,4.033844-2.3044586 c0.9168167-0.0458374,1.5234871-0.225708,1.6253281-0.428627 C58.0402908,111.8208466,53.5969009,112.8682175,53.174778,115.4070511z"
      />
      <g>
        <path
          fill={colors[0]}
          d="M53.4699478,108.9009094l0.1340065,0.2045364c0,0,0.9874191-0.7758331,1.9078369-3.2725906 c-0.0211601-0.2327499-0.0317383-0.3914413-0.0317383-0.3914413S54.6125336,107.768898,53.4699478,108.9009094z"
        />
        <path
          opacity="0.2"
          fill="#FFF"
          d="M53.4699478,108.9009094l0.1340065,0.2045364 c0,0,0.9874191-0.7758331,1.9078369-3.2725906c-0.0211601-0.2327499-0.0317383-0.3914413-0.0317383-0.3914413 S54.6125336,107.768898,53.4699478,108.9009094z"
        />
        <path
          fill={colors[0]}
          d="M53.4699478,108.9009094l0.1340065,0.2045364c0,0,0.9874191-0.7758331,1.9078369-3.2725906 c-0.0211601-0.2327499-0.0317383-0.3914413-0.0317383-0.3914413S54.6125336,107.768898,53.4699478,108.9009094z"
        />
        <path
          opacity="0.2"
          fill="#FFF"
          d="M53.4699478,108.9009094l0.1340065,0.2045364 c0,0,0.9874191-0.7758331,1.9078369-3.2725906c-0.0211601-0.2327499-0.0317383-0.3914413-0.0317383-0.3914413 S54.6125336,107.768898,53.4699478,108.9009094z"
        />
        <g>
          <path
            opacity="0.23"
            fill={colors[1]}
            d="M58.1511993,112.5834885 c0,0-0.5058784-6.6448441-0.4388733-6.5707855c0.0670013,0.0740585,0.1870651,0.3860931,0.1870651,0.3860931 l0.3877525,6.2331467L58.1511993,112.5834885z"
          />
          <path
            fill={colors[3]}
            d="M59.18993,112.7165756c0,0,0.5137825,0.1612625,0.3103294-1.1848984 c-0.2034531-1.3461685-0.4796028-3.6675644-0.4796028-3.6675644l0.7052994,4.090744 C59.725956,111.9548569,59.8952293,112.6319427,59.18993,112.7165756z"
          />
          <path
            opacity="0.5"
            fill={colors[1]}
            d="M52.6447487,118.0627518l0.0423164,1.3118591 l0.9733124,0.0846329l0.0282135-1.072052c0,0,0.5642395,1.2695389,1.5798721,1.2695389 c0.5383682,0,2.9832573-0.7186127,4.7114029-0.7335129c1.5319939-0.0132141,2.3980179,0.6770859,2.3980179,0.6770859 l0.1410599,0.3103333h-10.015255L52.6447487,118.0627518z"
          />
          <path
            fill="#FFF"
            d="M54.5067368,116.5110931l-1.4831467-0.8324127 c0.1303215-0.6807098,0.4539528-1.6164169,1.2715302-2.5248337c-0.7288475-0.2319107-0.0485497-3.3874512,0.0635033-4.231781 c0.0310745-0.2341309-0.4443321,0.8040466-0.4443321,0.8040466l-1.1849289-0.4796371l0.1888733,6.6229401 c-0.1888542,2.9973755,0.1496925,2.1510162,0.6292839,1.5021362 c0.1341667-0.1815262,0.2962761,1.5375748,1.2413597,1.8337936c0.9450798,0.2962189,2.0312691-1.6080704,4.8806267-1.4105911 c1.6128845,0.1117706,1.5798988,0.2397842,2.3626022,0.5695801c-0.0491943-0.5554886-0.6014824-2.5726624-0.669857-2.5726624 c-0.0265541,0-1.4454765-1.0962067-2.3839111-1.3118439c-0.1403847-0.0322571,0.0304184,0.6438828-0.1128502,0.6206665 c-0.8085556-0.1310272-1.5657654-0.112854-2.2428513,0.1410599c-0.2115898-0.112854-0.1551666-0.4090729-0.2962265-0.5783463 C55.5407066,114.8753357,55.0060463,115.5578918,54.5067368,116.5110931z"
            opacity="0.1"
          />
          <path
            opacity="0.1"
            fill="#FFF"
            d="M60.6851425,116.5533981c-0.3493729-0.348732-1.9639244-0.1777725-0.4231377-0.5924377 c-1.1849289-0.4232025-1.5657578-0.3949509-1.5657578-0.3949509c0.0846138-0.1128616-0.2680359-0.0564346,0.8886452-0.1411133 c-1.2131119-0.6206131-2.68013,0-2.68013,0l1.1566811-0.112793l-1.2131119,0.3667068 c0,0,0.1974754,1.5516968,0.8463402,1.1567383c0.163578-0.0995712,0.4350128-0.1665955,0.749012-0.2116013 c0.0663185,0.4404755,0.5521812,0.8937836,0.6052094,0.9733276c0.0564308,0.0846176,0.9873848,0.0564346,0.9873848,0.0564346 l0.1471443-1.1128998C60.4778404,116.5446625,60.6851425,116.5533981,60.6851425,116.5533981z"
          />
          <path
            opacity="0.11"
            fill="#FFF"
            d="M59.3309898,108.7951126c0,0,0.7123528,3.2725906,0.5642395,3.7380905 c0.039669,0.0423126-0.4220657,0.2825851-0.3949699,0.3244324c0.189682,0.2929535,0.592453,0.3597031,0.592453,0.3597031 l0.4690247-3.9073563l-0.8181496,0.6277161L59.3309898,108.7951126z"
          />
          <ellipse
            opacity="0.7"
            fill="#FFF"
            cx="59.557"
            cy="117.005"
            rx="0.324"
            ry="0.169"
          />
        </g>
      </g>
      <g>
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M55.3672028,105.7341156l0.4654999,7.0812073 c0,0-1.2272224,0.6488724-1.3964958-0.2397995c-0.1692696-0.8886795,0.2539101-5.9386292,0.0564232-6.3759155 C54.2951469,105.7623215,54.7888565,105.3814621,55.3672028,105.7341156z"
        />
        <path
          fill={colors[0]}
          d="M55.5082626,105.5930557l0.4654999,7.0812073c0,0-1.2272224,0.6488724-1.3964958-0.2397995 c-0.1692696-0.8886795,0.2539101-5.9386292,0.0564232-6.3759155 C54.4362068,105.6212616,54.9299164,105.2404022,55.5082626,105.5930557z"
        />
        <path
          fill={colors[0]}
          d="M57.7229042,105.6635818l0.4282951,6.9199066c0,0,1.3023376,0.4806442,1.3524399-0.4226227 C59.5537376,111.2575989,58.9078102,105.198082,57.7229042,105.6635818z"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M41.8558159,105.7341156l-0.4654999,7.0812073 c0,0,1.2272224,0.6488724,1.3964958-0.2397995c0.1692696-0.8886795-0.2539101-5.9386292-0.056427-6.3759155 C42.9278717,105.7623215,42.4341621,105.3814621,41.8558159,105.7341156z"
        />
        <path
          fill={colors[0]}
          d="M41.714756,105.5930557l-0.4654999,7.0812073c0,0,1.2272224,0.6488724,1.3964958-0.2397995 c0.1692696-0.8886795-0.2539101-5.9386292-0.056427-6.3759155 C42.7868118,105.6212616,42.2931023,105.2404022,41.714756,105.5930557z"
        />
        <path
          fill={colors[0]}
          d="M39.5001144,105.6635818l-0.4282951,6.9199066c0,0-1.3023415,0.4806442-1.3524399-0.4226227 s0.8747292-5.5016708,0.7368889-5.9612579C38.3184319,105.7400208,39.0779648,105.7693787,39.5001144,105.6635818z"
        />
      </g>
      <g fill={colors[1]}>
        <g opacity="0.3">
          <path d="M57.9543457,107.2613754l-0.8327942-0.3799973l0.7778397-0.4825821 c0.0939903-0.0582962,0.1231346-0.1828384,0.0648422-0.2768326c-0.0582924-0.0939178-0.1828384-0.1230698-0.2767639-0.0647736 l-1.0052986,0.6236954l-1.0763016-0.491127c-0.1005363-0.0458984-0.2203979-0.001152-0.2663002,0.0994492 s-0.0011559,0.2203979,0.0994453,0.2662964l0.8327942,0.3799973l-0.7778397,0.4825897 c-0.0939217,0.0582886-0.1231346,0.1828384-0.0648422,0.2767639c0.0582962,0.0939865,0.1828423,0.1231308,0.2767639,0.0648346 l1.0052986-0.6236954l1.0763016,0.491127c0.1006012,0.0458374,0.2203979,0.001091,0.2663002-0.0994415 C58.0996933,107.4270782,58.0549469,107.3072128,57.9543457,107.2613754z" />
          <path d="M58.0530891,109.4478073l-0.8327942-0.3799973l0.7778397-0.4825897 c0.0939865-0.0582886,0.1231346-0.1828384,0.0648422-0.276825c-0.0582962-0.0939255-0.1828423-0.1230698-0.2767639-0.0647736 l-1.0052986,0.6236954l-1.0763016-0.491127c-0.1005363-0.0459061-0.2203979-0.001152-0.2663002,0.0994415 c-0.0459023,0.1006012-0.0011559,0.2203979,0.0994453,0.266304l0.8327942,0.3799973l-0.7778397,0.4825821 c-0.0939255,0.0582962-0.1231346,0.1828384-0.0648422,0.2767639c0.0582924,0.0939865,0.1828384,0.1231384,0.2767639,0.0648422 l1.0052986-0.6236954l1.0763016,0.491127c0.1006012,0.0458374,0.2203979,0.001091,0.2663002-0.0994492 C58.1984367,109.6135025,58.1536903,109.4936447,58.0530891,109.4478073z" />
          <path d="M58.1518326,111.6342392l-0.832798-0.3799973l0.7778397-0.4825897 c0.0939903-0.0582962,0.1231346-0.1828384,0.0648422-0.276825c-0.0582924-0.0939255-0.1828384-0.1230698-0.2767639-0.0647812 l-1.0052948,0.6236954l-1.0763054-0.4911194c-0.1005363-0.0459061-0.2203941-0.0011597-0.2662964,0.0994415 c-0.0459061,0.1006012-0.0011559,0.2203979,0.0994415,0.2662964l0.8327942,0.3799973l-0.7778397,0.4825897 c-0.0939217,0.0582886-0.1231308,0.1828384-0.0648384,0.2767639c0.0582924,0.0939865,0.1828384,0.1231308,0.2767639,0.0648422 l1.0052948-0.6236954l1.0763054,0.4911194c0.1005974,0.0458374,0.2203941,0.001091,0.2662964-0.0994415 C58.2971764,111.7999344,58.25243,111.6800766,58.1518326,111.6342392z" />
        </g>
        <g opacity="0.3">
          <path d="M39.2686729,107.2613754l0.8327942-0.3799973l-0.7778397-0.4825821 c-0.0939903-0.0582962-0.1231346-0.1828384-0.0648422-0.2768326c0.0582924-0.0939178,0.1828384-0.1230698,0.2767639-0.0647736 l1.0052986,0.6236954l1.0763016-0.491127c0.1005363-0.0458984,0.2203941-0.001152,0.2662964,0.0994492 c0.0459061,0.1006012,0.0011559,0.2203979-0.0994415,0.2662964l-0.8327942,0.3799973l0.7778397,0.4825897 c0.0939217,0.0582886,0.1231346,0.1828384,0.0648384,0.2767639c-0.0582924,0.0939865-0.1828384,0.1231308-0.2767639,0.0648346 l-1.0052948-0.6236954l-1.0763054,0.491127c-0.1005974,0.0458374-0.2203941,0.001091-0.2662964-0.0994415 C39.1233253,107.4270782,39.1680717,107.3072128,39.2686729,107.2613754z" />
          <path d="M39.1699295,109.4478073l0.8327942-0.3799973l-0.7778397-0.4825897 c-0.0939865-0.0582886-0.1231346-0.1828384-0.0648422-0.276825c0.0582924-0.0939255,0.1828423-0.1230698,0.2767639-0.0647736 l1.0052986,0.6236954l1.0763016-0.491127c0.1005363-0.0459061,0.2203979-0.001152,0.2663002,0.0994415 c0.0459023,0.1006012,0.0011559,0.2203979-0.0994453,0.266304l-0.8327942,0.3799973l0.7778397,0.4825821 c0.0939255,0.0582962,0.1231346,0.1828384,0.0648422,0.2767639c-0.0582924,0.0939865-0.1828423,0.1231384-0.2767639,0.0648422 l-1.0052986-0.6236954l-1.0763016,0.491127c-0.1006012,0.0458374-0.2203979,0.001091-0.2663002-0.0994492 C39.0245819,109.6135025,39.0693283,109.4936447,39.1699295,109.4478073z" />
          <path d="M39.0711861,111.6342392l0.8327942-0.3799973l-0.7778397-0.4825897 c-0.0939865-0.0582962-0.1231346-0.1828384-0.0648384-0.276825c0.0582924-0.0939255,0.1828384-0.1230698,0.2767601-0.0647812 l1.0052986,0.6236954l1.0763054-0.4911194c0.1005363-0.0459061,0.2203941-0.0011597,0.2662964,0.0994415 s0.0011559,0.2203979-0.0994453,0.2662964l-0.8327942,0.3799973l0.7778397,0.4825897 c0.0939255,0.0582886,0.1231346,0.1828384,0.0648422,0.2767639c-0.0582924,0.0939865-0.1828384,0.1231308-0.2767639,0.0648422 l-1.0052986-0.6236954l-1.0763016,0.4911194c-0.1006012,0.0458374-0.2203941,0.001091-0.2663002-0.0994415 C38.9258385,111.7999344,38.9705887,111.6800766,39.0711861,111.6342392z" />
        </g>
      </g>
      <g>
        <g fill={colors[4]}>
          <path d="M58.998085,112.238739c-0.1371574,0.0115509-0.2596703-0.1090546-0.2731934-0.2697525 c-0.0135269-0.1607056,0.087101-0.300087,0.2242584-0.3116302c0.1361351-0.0114594,0.2586479,0.1091461,0.272171,0.2698441 C59.234848,112.0878983,59.1342201,112.2272873,58.998085,112.238739z" />
          <path d="M58.8341064,111.1314011c-0.1371613,0.0115433-0.2596703-0.1090546-0.2731972-0.2697601 c-0.0135231-0.1606979,0.0871048-0.3000793,0.2242622-0.3116302 c0.1361351-0.0114517,0.2586441,0.1091461,0.272171,0.2698517 C59.0708694,110.9805603,58.9702415,111.1199417,58.8341064,111.1314011z" />
          <path d="M58.7263489,110.0193329c-0.1371574,0.0115433-0.2596664-0.1090622-0.2731934-0.2697601 c-0.0135269-0.1607056,0.087101-0.300087,0.2242622-0.3116302c0.1361351-0.0114594,0.2586441,0.1091461,0.272171,0.2698441 C58.9631119,109.8684921,58.862484,110.0078735,58.7263489,110.0193329z" />
          <path d="M58.6326523,108.9060745c-0.1371613,0.0115433-0.2596703-0.1090546-0.2731972-0.2697601 c-0.0135231-0.1606979,0.0871048-0.300087,0.2242622-0.3116302c0.1361351-0.0114517,0.2586441,0.1091461,0.272171,0.2698517 C58.8694153,108.7552338,58.7687874,108.8946152,58.6326523,108.9060745z" />
          <path d="M58.5389519,107.7928162c-0.1371574,0.0115509-0.2596703-0.1090546-0.2731934-0.2697525 c-0.0135269-0.1607056,0.087101-0.300087,0.2242584-0.3116302c0.1361351-0.0114594,0.2586479,0.1091461,0.272171,0.2698441 C58.7757149,107.6419754,58.675087,107.7813644,58.5389519,107.7928162z" />
          <path d="M58.3516808,106.687439c-0.1371574,0.0115433-0.2596703-0.1090546-0.2731934-0.2697601 c-0.0135269-0.1606979,0.087101-0.3000793,0.2242584-0.3116302c0.1361351-0.0114517,0.2586479,0.1091461,0.272171,0.2698517 C58.5884438,106.5365982,58.4878159,106.6759796,58.3516808,106.687439z" />
        </g>
        <g fill={colors[4]}>
          <path d="M55.3112679,112.3452988c-0.1376419,0-0.2496071-0.1304474-0.2496071-0.2917175 s0.1119652-0.2917252,0.2496071-0.2917252c0.1366158,0,0.2485809,0.130455,0.2485809,0.2917252 S55.4478836,112.3452988,55.3112679,112.3452988z" />
          <path d="M55.2407379,111.2281113c-0.1376419,0-0.2496071-0.130455-0.2496071-0.2917252 s0.1119652-0.2917175,0.2496071-0.2917175c0.1366158,0,0.2485809,0.1304474,0.2485809,0.2917175 S55.3773537,111.2281113,55.2407379,111.2281113z" />
          <path d="M55.226635,110.1109161c-0.1376457,0-0.2496109-0.1304474-0.2496109-0.2917175 s0.1119652-0.2917252,0.2496109-0.2917252c0.1366158,0,0.2485771,0.130455,0.2485771,0.2917252 S55.3632507,110.1109161,55.226635,110.1109161z" />
          <path d="M55.226635,108.9937286c-0.1376457,0-0.2496109-0.130455-0.2496109-0.2917252 s0.1119652-0.2917175,0.2496109-0.2917175c0.1366158,0,0.2485771,0.1304474,0.2485771,0.2917175 S55.3632507,108.9937286,55.226635,108.9937286z" />
          <path d="M55.226635,107.8765335c-0.1376457,0-0.2496109-0.1304474-0.2496109-0.2917175 s0.1119652-0.2917252,0.2496109-0.2917252c0.1366158,0,0.2485771,0.130455,0.2485771,0.2917252 S55.3632507,107.8765335,55.226635,107.8765335z" />
          <path d="M55.1327286,106.759346c-0.1376419,0-0.2496071-0.130455-0.2496071-0.2917252 s0.1119652-0.2917175,0.2496071-0.2917175c0.1366196,0,0.2485809,0.1304474,0.2485809,0.2917175 S55.2693481,106.759346,55.1327286,106.759346z" />
        </g>
        <path
          fill={colors[0]}
          d="M52.6306419,104.0413895c-0.0493736-0.0493698-0.6488762,2.3486481-1.3682823,3.2584915 c0.7509155,1.3349533,1.5498085,1.4841309,2.1804581,1.5962067c0.009079,0.0016174,0.0181198,0.0032196,0.0271301,0.0048218 c0.9239426-0.5289764,2.0530624-3.3939972,2.0530624-3.3939972S53.2586899,105.1015854,52.6306419,104.0413895z"
        />
        <path
          fill={colors[0]}
          d="M53.6039543,109.1054459l-0.1340065-0.2045364c0,0-1.4952354-0.2186432-2.2075882-1.6010284 c0.0599518,0.5324936,0.9697876,1.6010284,2.3451233,1.8443527"
        />
        <path
          opacity="0.23"
          fill={colors[1]}
          d="M53.6039543,109.1054459l-0.1340065-0.2045364 c0,0-1.4952354-0.2186432-2.2075882-1.6010284c0.0599518,0.5324936,0.9697876,1.6010284,2.3451233,1.8443527"
        />
        <g>
          <g fill={colors[4]}>
            <path d="M38.2249336,112.238739c0.1371574,0.0115509,0.2596664-0.1090546,0.2731934-0.2697525 c0.0135269-0.1607056-0.087101-0.300087-0.2242622-0.3116302c-0.1361351-0.0114594-0.2586441,0.1091461-0.272171,0.2698441 C37.9881706,112.0878983,38.0887985,112.2272873,38.2249336,112.238739z" />
            <path d="M38.3889122,111.1314011c0.1371613,0.0115433,0.2596703-0.1090546,0.2731972-0.2697601 c0.0135231-0.1606979-0.0871048-0.3000793-0.2242622-0.3116302 c-0.1361351-0.0114517-0.2586441,0.1091461-0.272171,0.2698517 C38.1521492,110.9805603,38.2527771,111.1199417,38.3889122,111.1314011z" />
            <path d="M38.4966698,110.0193329c0.1371574,0.0115433,0.2596664-0.1090622,0.2731934-0.2697601 c0.0135269-0.1607056-0.087101-0.300087-0.2242622-0.3116302c-0.1361351-0.0114594-0.2586441,0.1091461-0.272171,0.2698441 C38.2599068,109.8684921,38.3605347,110.0078735,38.4966698,110.0193329z" />
            <path d="M38.5903664,108.9060745c0.1371613,0.0115433,0.2596703-0.1090546,0.2731972-0.2697601 c0.0135231-0.1606979-0.0871048-0.300087-0.2242622-0.3116302c-0.1361351-0.0114517-0.2586441,0.1091461-0.272171,0.2698517 C38.3536034,108.7552338,38.4542313,108.8946152,38.5903664,108.9060745z" />
            <path d="M38.6840668,107.7928162c0.1371574,0.0115509,0.2596703-0.1090546,0.2731934-0.2697525 c0.0135269-0.1607056-0.087101-0.300087-0.2242584-0.3116302c-0.1361351-0.0114594-0.2586479,0.1091461-0.272171,0.2698441 C38.4473038,107.6419754,38.5479317,107.7813644,38.6840668,107.7928162z" />
            <path d="M38.8713379,106.687439c0.1371574,0.0115433,0.2596703-0.1090546,0.2731934-0.2697601 c0.0135269-0.1606979-0.087101-0.3000793-0.2242584-0.3116302c-0.1361351-0.0114517-0.2586479,0.1091461-0.272171,0.2698517 C38.6345749,106.5365982,38.7352028,106.6759796,38.8713379,106.687439z" />
          </g>
          <g fill={colors[4]}>
            <path d="M41.9117508,112.3452988c0.1376419,0,0.2496071-0.1304474,0.2496071-0.2917175 s-0.1119652-0.2917252-0.2496071-0.2917252c-0.1366196,0-0.2485809,0.130455-0.2485809,0.2917252 S41.7751312,112.3452988,41.9117508,112.3452988z" />
            <path d="M41.9822807,111.2281113c0.1376419,0,0.2496071-0.130455,0.2496071-0.2917252 s-0.1119652-0.2917175-0.2496071-0.2917175c-0.1366196,0-0.2485809,0.1304474-0.2485809,0.2917175 S41.8456612,111.2281113,41.9822807,111.2281113z" />
            <path d="M41.9963837,110.1109161c0.1376457,0,0.2496071-0.1304474,0.2496071-0.2917175 s-0.1119614-0.2917252-0.2496071-0.2917252c-0.1366158,0-0.2485771,0.130455-0.2485771,0.2917252 S41.8597679,110.1109161,41.9963837,110.1109161z" />
            <path d="M41.9963837,108.9937286c0.1376457,0,0.2496071-0.130455,0.2496071-0.2917252 s-0.1119614-0.2917175-0.2496071-0.2917175c-0.1366158,0-0.2485771,0.1304474-0.2485771,0.2917175 S41.8597679,108.9937286,41.9963837,108.9937286z" />
            <path d="M41.9963837,107.8765335c0.1376457,0,0.2496071-0.1304474,0.2496071-0.2917175 s-0.1119614-0.2917252-0.2496071-0.2917252c-0.1366158,0-0.2485771,0.130455-0.2485771,0.2917252 S41.8597679,107.8765335,41.9963837,107.8765335z" />
            <path d="M42.0902863,106.759346c0.1376457,0,0.2496071-0.130455,0.2496071-0.2917252 s-0.1119614-0.2917175-0.2496071-0.2917175c-0.1366158,0-0.2485771,0.1304474-0.2485771,0.2917175 S41.9536705,106.759346,42.0902863,106.759346z" />
          </g>
          <g fill={colors[0]}>
            <path d="M44.5369835,104.5633163c0.0493698-0.0493774,0.7042694,1.8267212,1.4236755,2.7365646 c-0.7509155,1.3349533-1.5498085,1.4841309-2.1804581,1.5962067 c-0.009079,0.0016174-0.0181236,0.0032196-0.0271301,0.0048218 c-0.9239426-0.5289764-2.0530624-3.3939972-2.0530624-3.3939972S43.9089355,105.6235046,44.5369835,104.5633163z" />
            <path d="M39.1436806,105.8298645c0,0,0.0970879-0.1236801-0.0916061-0.1035538 c-0.5744438,0.0612793-2.8587875-0.771553-3.1338539-0.6975021 c-0.0954475,0.4867783,0.1410599,2.5249786-0.7652512,3.8403625 c-0.0364075,0.1278687,0.0563431,0.084877,0.0211601,0.1904297c0.5257378,0.1249619,2.1370583,0.222168,2.1370583,0.222168 C37.7918205,108.4213028,38.6124649,107.0071793,39.1436806,105.8298645z" />
            <path d="M57.9332008,105.8298645c0,0-0.0970879-0.1236801,0.0916061-0.1035538 c0.5744438,0.0612793,2.9671631-0.782135,3.2422295-0.7080765c0.0954475,0.4867783-0.2494354,2.535553,0.6568756,3.8509369 c0.0364075,0.1278687-0.0563431,0.084877-0.0211601,0.1904297c-0.5257378,0.1249619-2.1370583,0.222168-2.1370583,0.222168 C59.2850609,108.4213028,58.4644165,107.0071793,57.9332008,105.8298645z" />
          </g>
          <path
            fill={colors[0]}
            d="M43.6190643,109.1054459l0.1340065-0.2045364c0,0,1.4952354-0.2186432,2.2075882-1.6010284 c-0.0599518,0.5324936-0.9697876,1.6010284-2.3451233,1.8443527"
          />
          <path
            opacity="0.23"
            fill={colors[1]}
            d="M43.6190643,109.1054459l0.1340065-0.2045364 c0,0,1.4952354-0.2186432,2.2075882-1.6010284c-0.0599518,0.5324936-0.9697876,1.6010284-2.3451233,1.8443527"
          />
          <path
            opacity="0.2"
            fill="#FFF"
            d="M39.0520744,105.7263107l0.0916061,0.1035538 c0,0-0.8477287,1.6250153-1.7478561,3.4730606c-0.0528984-0.0846329-0.0211601-0.1904297-0.0211601-0.1904297 S38.4189987,106.561554,39.0520744,105.7263107z"
          />
        </g>
        <g>
          <path
            fill={colors[0]}
            d="M43.7530708,108.9009094l-0.1340065,0.2045364c0,0-0.9874191-0.7758331-1.9078369-3.2725906 c0.0211601-0.2327499,0.0317383-0.3914413,0.0317383-0.3914413S42.6104851,107.768898,43.7530708,108.9009094z"
          />
          <path
            opacity="0.2"
            fill="#FFF"
            d="M43.7530708,108.9009094l-0.1340065,0.2045364 c0,0-0.9874191-0.7758331-1.9078369-3.2725906c0.0211601-0.2327499,0.0317383-0.3914413,0.0317383-0.3914413 S42.6104851,107.768898,43.7530708,108.9009094z"
          />
        </g>
        <g>
          <path
            opacity="0.23"
            fill={colors[1]}
            d="M39.0718193,112.5834885 c0,0,0.5058784-6.6448441,0.4388733-6.5707855s-0.1870651,0.3860931-0.1870651,0.3860931l-0.3877525,6.2331467 L39.0718193,112.5834885z"
          />
          <path
            fill={colors[3]}
            d="M38.0330887,112.7165756c0,0-0.5137825,0.1612625-0.3103294-1.1848984 c0.2034531-1.3461685,0.4796028-3.6675644,0.4796028-3.6675644l-0.7052994,4.090744 C37.4970627,111.9548569,37.3277893,112.6319427,38.0330887,112.7165756z"
          />
          <path
            opacity="0.5"
            fill={colors[1]}
            d="M44.57827,118.0627518l-0.0423164,1.3118591 l-0.9733124,0.0846329l-0.0282135-1.072052c0,0-0.5642395,1.2695389-1.5798721,1.2695389 c-0.5383682,0-2.9832573-0.7186127-4.7114029-0.7335129c-1.5319939-0.0132141-2.3980179,0.6770859-2.3980179,0.6770859 l-0.1410599,0.3103333h10.015255L44.57827,118.0627518z"
          />
          <path
            fill="#FFF"
            d="M42.7162781,116.5110931l1.4831505-0.8324127 c-0.1303253-0.6807098-0.4539528-1.6164169-1.2715302-2.5248337c0.7288475-0.2319107,0.0485497-3.3874512-0.0635071-4.231781 c-0.0310707-0.2341309,0.4443359,0.8040466,0.4443359,0.8040466l1.1849289-0.4796371l-0.1888771,6.6229401 c0.1888542,2.9973755-0.1496887,2.1510162-0.6292801,1.5021362 c-0.1341667-0.1815262-0.2962799,1.5375748-1.2413597,1.8337936s-2.0312691-1.6080704-4.8806305-1.4105911 c-1.6128807,0.1117706-1.579895,0.2397842-2.3625984,0.5695801c0.0491905-0.5554886,0.6014824-2.5726624,0.6698532-2.5726624 c0.0265541,0,1.4454803-1.0962067,2.3839149-1.3118439c0.1403847-0.0322571-0.0304184,0.6438828,0.1128502,0.6206665 c0.8085556-0.1310272,1.5657654-0.112854,2.2428513,0.1410599c0.2115898-0.112854,0.1551666-0.4090729,0.2962265-0.5783463 C41.6823082,114.8753357,42.2169724,115.5578918,42.7162781,116.5110931z"
            opacity="0.1"
          />
          <path
            opacity="0.1"
            fill="#FFF"
            d="M36.5378761,116.5533981c0.3493729-0.348732,1.9639244-0.1777725,0.4231377-0.5924377 c1.1849289-0.4232025,1.5657578-0.3949509,1.5657578-0.3949509c-0.0846138-0.1128616,0.2680321-0.0564346-0.8886452-0.1411133 c1.2131081-0.6206131,2.68013,0,2.68013,0l-1.1566811-0.112793l1.2131119,0.3667068 c0,0-0.1974754,1.5516968-0.8463402,1.1567383c-0.163578-0.0995712-0.4350166-0.1665955-0.7490158-0.2116013 c-0.0663147,0.4404755-0.5521774,0.8937836-0.6052055,0.9733276 c-0.0564308,0.0846176-0.9873848,0.0564346-0.9873848,0.0564346l-0.1471481-1.1128998 C36.7451744,116.5446625,36.5378761,116.5533981,36.5378761,116.5533981z"
          />
          <path
            opacity="0.11"
            fill="#FFF"
            d="M37.8920288,108.7951126c0,0-0.7123528,3.2725906-0.5642395,3.7380905 c-0.039669,0.0423126,0.4220619,0.2825851,0.3949699,0.3244324c-0.189682,0.2929535-0.592453,0.3597031-0.592453,0.3597031 l-0.4838867-4.0100021l0.7801132,0.1167526L37.8920288,108.7951126z"
          />
          <path
            opacity="0.23"
            fill={colors[1]}
            d="M37.3958244,109.3029251 c0,0-0.0211601-0.2242508-0.0211601-0.1904297l-2.2216949-0.2433243l0.0211601,0.1904297L37.3958244,109.3029251z"
          />
          <ellipse
            opacity="0.7"
            fill="#FFF"
            cx="37.666"
            cy="117.005"
            rx="0.324"
            ry="0.169"
          />
        </g>
        <g>
          <path
            opacity="0.23"
            fill={colors[1]}
            d="M59.8070641,109.0701752 c0,0-0.1144791,0.2115936-0.0413704,0.2115936c0.0731125,0,2.0726357-0.2115936,2.0726357-0.2115936l0.0634766-0.1586914 L59.8070641,109.0701752z"
          />
          <polygon
            opacity="0.2"
            fill="#FFF"
            points="57.9133377,105.6741638 59.7330093,109.2394485 59.8388062,109.0066986 58.1143456,105.6212616"
          />
        </g>
      </g>
      <g>
        <path
          opacity="0.1"
          fill="#FFF"
          d="M53.2371979,115.4284592l0.8433037,0.5165176c0,0,2.7008286-4.08181,7.25354-0.7175293 c-0.5078163-0.7899399-0.4937134-1.0297394-0.9874229-1.6221924 C55.2684631,111.1508102,53.2371979,115.4284592,53.2371979,115.4284592z"
        />
        <path
          fill={colors[4]}
          d="M60.7909584,113.8203735c0,0-0.6276932-0.7511444-0.6276932-0.7934494 s-0.4373245,0.08461-0.4373245,0.08461l0.4513855,1.8056107l1.3542213,0.846344l0.0634537-0.1340256L60.7909584,113.8203735z M60.6287117,114.9735107l-0.504261-1.6926956l0.4866562,0.4972382 c0.0139084-0.0027542,0.9005814,1.6003418,0.7088394,1.6750946 C61.2725487,115.4716263,60.6287117,114.9735107,60.6287117,114.9735107z"
        />
        <polygon
          fill={colors[4]}
          points="60.5687904,114.2118149 60.6745834,114.34935 61.108345,114.7619476 60.9602318,114.3705063 60.5476303,114.0954361"
        />
        <g>
          <path
            opacity="0.1"
            fill="#FFF"
            d="M43.9858208,115.4284592l-0.8433037,0.5165176 c0,0-2.7008286-4.08181-7.25354-0.7175293c0.5078163-0.7899399,0.4937096-1.0297394,0.9874191-1.6221924 C41.9545555,111.1508102,43.9858208,115.4284592,43.9858208,115.4284592z"
          />
          <path
            fill={colors[4]}
            d="M35.6280174,115.6294632l0.0634537,0.1340256l1.3542213-0.846344l0.4513855-1.8056107 c0,0-0.4373245-0.126915-0.4373245-0.08461s-0.6276932,0.7934494-0.6276932,0.7934494L35.6280174,115.6294632z M35.9030724,115.4531479c-0.1917419-0.0747528,0.694931-1.6778488,0.7088394-1.6750946l0.4866562-0.4972382 l-0.504261,1.6926956C36.5943069,114.9735107,35.9504662,115.4716263,35.9030724,115.4531479z"
          />
          <polygon
            fill={colors[4]}
            points="36.6542282,114.2118149 36.5484352,114.34935 36.1146736,114.7619476 36.2627869,114.3705063 36.6753883,114.0954361"
          />
        </g>
      </g>
    </g>
  )
}

export default Boots
