import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const AxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("AxeRight") ? (
    <g id="AxeRight">
      <path
        id="S_x24_axeRight"
        d="M31.8695812,126.4074707c-0.7999992-0.1999969-1.6000004-0.5999985-2.5-1.0999985l-3.5-5.5999985 c-0.1000004-1.8000031-0.6000004-8.0999985-1.3999996-12.5999985c-0.6000004-3.0999985-2.7000008-12.3000031-4.8999996-21.6999969 l-2.1000004,0.8999939c-5-2-8.6000004-6-9.6000004-10.9000015l0,0c-1-4.9000015,0.8000007-10,4.7000003-13.7000046 l3.9000006,0.0999985l4.2000008-2.9000015l3.7999992-0.7000008l4.8999996,1.2000008l3.6999989-1.5999985 c5,2,8.6000023,6,9.6000023,10.9000015l0,0c1,4.9000015-0.7999992,10-4.7000008,13.6999969h-2.1000023l7.8000031,34.4000015 l-3.7999992,8l-3,1.4000015L31.8695812,126.4074707z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(AxeRight, {
  character: "ManHalfling"
})
