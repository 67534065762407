import React from "react"

const LongswordRight = ({
  colors = [
    "#D0AA2B",
    "#A9ABAE",
    "#D6D8DB",
    "#B49330",
    "#SVGID_",
    "#EED793",
    "#7B6529",
    "#A38634",
    "#7951A1",
    "#FFFFFF",
    "#F6F6F6",
    "#E4E5E6",
    "#C8CACC",
    "#A2A4A7",
    "#757779",
    "#2D2D2E",
    "#000000"
  ]
}) => {
  return (
    <g id="LongswordRight">
      <rect
        x="27.145"
        y="79.597"
        transform="rotate(-13.691 28.245 85.947)"
        fill={colors[0]}
        width="2.2"
        height="12.7"
      />
      <path
        fill={colors[1]}
        d="M28.0587368,78.3489304l-3.3223858,0.7821503L12.6663122,30.4553719 c0,0-2.0476561-8.5957603-0.6210146-9.9128361c1.6977911,0.5634766,3.9434013,9.1306953,3.9434013,9.1306953 L28.0587368,78.3489304z"
      />
      <path
        fill={colors[2]}
        d="M12.0452967,20.5425339c-1.4266424,1.3170776,0.6210155,9.9128361,0.6210155,9.9128361 L24.736351,79.1310806l1.6540585-0.4405594L12.0452967,20.5425339z"
      />
      <path
        fill={colors[0]}
        d="M22.103117,76.9849014c0,0,0.3273125,1.5693512,4.2587471,1.5076599 c3.3919029-1.7014694,3.2482719-3.3983383,3.2482719-3.3983383s3.7523556,3.6013565-2.8781452,5.2646255 C20.2004642,82.0078354,22.103117,76.9849014,22.103117,76.9849014z"
      />
      <path
        fill={colors[3]}
        d="M29.723381,75.1789246c0,0,1.6282768,1.4828033-1.7217312,2.7741013 c-0.4806118,0.1703262-0.4635773,2.3906326-1.156414,2.4905243c-0.6785641,0.1988754-1.7700653-1.7654648-2.2649479-1.6941071 c-3.478447,0.4005051-2.3781967-1.7788162-2.3781967-1.7788162s-2.8380928,3.440239,0.1749344,4.0161438 c0.1408691-0.4244461,0.5929394-0.7927322,1.1725235-0.9773407c0.8765182-0.2274094,1.9537506,1.6379547,2.2515984,2.3022385 c0.6090508,0.7204666,1.2742634,1.1297226,1.868124,1.0440903c0.6785641-0.1988678,0.9603024-1.0477676,1.1573334-1.7834091 c-0.0998955-0.6928329,0.2789783-2.9702148,1.1697655-3.0986557c0.4806118-0.1703339,1.0040359-0.0437393,1.3437786,0.2103806 C33.303566,76.8854675,29.723381,75.1789246,29.723381,75.1789246z"
      />
      <path
        fill="url(#SVGID_13_)"
        d="M27.5380726,80.3436584c0.1846027,0.5795822-0.0409737,1.1172791-0.4368782,1.1743622 c-0.3959064,0.0570831-0.7499218-0.2960129-0.9345207-0.8755951c-0.1846008-0.5795898,0.0409718-1.1172867,0.4368782-1.1743698 C26.9994564,79.4109726,27.452446,79.7498016,27.5380726,80.3436584z"
      />
      <path
        fill={colors[3]}
        d="M30.7789688,93.0108032l-1.5550823,0.4262848 c-0.4948826,0.0713501-0.9336014-0.1684952-1.1039333-0.6491089l-0.0713539-0.4948807 c-0.0713558-0.4948807,0.1684895-0.9336014,0.6491013-1.1039276l1.5550823-0.4262924 c0.4948807-0.0713501,0.9335995,0.1684952,1.1039314,0.6491089l0.0713558,0.4948807 C31.4994259,92.4017487,31.2738514,92.9394455,30.7789688,93.0108032z"
      />
      <path
        fill={colors[5]}
        d="M27.949625,92.3073807c-0.0713558-0.4948883,0.1684895-0.933609,0.6491013-1.1039352l1.5550823-0.4262848 c0.4948826-0.0713577,0.9336014,0.1684952,1.1039314,0.6491013C30.2251625,91.2720337,28.6558094,91.5993423,27.949625,92.3073807 z"
      />
      <ellipse
        transform="rotate(-13.691 26.959 80.525)"
        fill={colors[6]}
        cx="26.959"
        cy="80.525"
        rx="1.1"
        ry="1.5"
      />
      <ellipse
        transform="rotate(-13.691 26.959 80.525)"
        fill={colors[7]}
        cx="26.959"
        cy="80.525"
        rx="0.9"
        ry="1.3"
      />
      <ellipse
        transform="rotate(-13.691 26.959 80.525)"
        fill={colors[8]}
        cx="26.959"
        cy="80.525"
        rx="0.7"
        ry="1.1"
      />
      <radialGradient
        id="SVGID_13_"
        cx="139.32"
        cy="733.58"
        r="0.902"
        gradientTransform="rotate(-13.691 -2636.96 875.348) scale(.99998)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.698" stopColor="#fff" stopOpacity="0.1" />
        <stop offset="0.724" stopColor="#f6f6f6" stopOpacity="0.1" />
        <stop offset="0.762" stopColor="#e4e5e6" stopOpacity="0.1" />
        <stop offset="0.809" stopColor="#c8cacc" stopOpacity="0.1" />
        <stop offset="0.862" stopColor="#a2a4a7" stopOpacity="0.1" />
        <stop offset="0.921" stopColor={colors[14]} stopOpacity="0.1" />
        <stop offset="0.983" stopColor="#2d2d2e" stopOpacity="0.1" />
        <stop offset="0.995" stopOpacity="0.1" />
      </radialGradient>
    </g>
  )
}

export default LongswordRight
