import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import FireFistsL from "./FireFistsL"
import FireFistsR from "./FireFistsR"
import FrontShieldL from "./FrontShieldL"
import FrontShieldR from "./FrontShieldR"

const CATEGORY = "foreGround"
const CHARACTER = "ManMixed"
export const IDS = {
  FIRE_FISTS_L: "FireFistsL",
  FIRE_FISTS_R: "FireFistsR",
  FRONT_SHIELD_L: "FrontShieldL",
  FRONT_SHIELD_R: "FrontShieldR"
}

export const components = {
  [IDS.FIRE_FISTS_L]: FireFistsL,
  [IDS.FIRE_FISTS_R]: FireFistsR,
  [IDS.FRONT_SHIELD_L]: FrontShieldL,
  [IDS.FRONT_SHIELD_R]: FrontShieldR
}

export const Group = ({ props }) => (
  <g id="foreGround">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.FIRE_FISTS_L,
    name: "Fire Fists L",
    defaultColors: [
      "#CC9900",
      "#CC7900",
      "#CC6500",
      "#E15000",
      "#F24000",
      "#FC3600",
      "#FF3300",
      "#SVGID_"
    ],
    colorable: false,
    component: components[IDS.FIRE_FISTS_L],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.FIRE_FISTS_R,
    name: "Fire Fists R",
    defaultColors: [
      "#CC9900",
      "#CC7900",
      "#CC6500",
      "#E15000",
      "#F24000",
      "#FC3600",
      "#FF3300",
      "#SVGID_"
    ],
    colorable: false,
    component: components[IDS.FIRE_FISTS_R],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.FRONT_SHIELD_L,
    name: "Front Shield L",
    defaultColors: ["#A8A6A3", "#262261", "#FFFFFF"],
    colorable: true,
    component: components[IDS.FRONT_SHIELD_L],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.FRONT_SHIELD_R,
    name: "Front Shield R",
    defaultColors: ["#A8A6A3", "#262261", "#FFFFFF"],
    colorable: true,
    component: components[IDS.FRONT_SHIELD_R],
    inUI: true,
    enabled: false,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
