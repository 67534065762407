import React from "react"

const GoatHorns = ({ colors = ["#70706E"] }) => {
  return (
    <g id="GoatHorns" fill={colors[0]}>
      <path d="M65.3367538,13.4970398c0.1999969,0,0.4000015,0,0.5,0.1000004 c-2.1000023-6.5999999-4.6000023-7.0999999-4.6000023-6.8000002c0,0,1.2999992,1.6999998,2.8000069,7.5 C64.3367538,13.79704,64.8367538,13.4970398,65.3367538,13.4970398z" />
      <path d="M71.8367538,13.4970398c-0.1999969,0-0.4000015,0-0.5,0.1000004 c2.0999985-6.6000004,4.5999985-7.1000004,4.5999985-6.8000002c0,0-1.3000031,1.6999998-2.8000031,7.5 C72.8367538,13.79704,72.3367538,13.4970398,71.8367538,13.4970398z" />
    </g>
  )
}

export default GoatHorns
