import React from "react"

const WarhammerR = ({
  colors = [
    "#B9BABB",
    "#727373",
    "#E7E8E8",
    "#646464",
    "#7F8080",
    "#636464",
    "#CECFCF",
    "#A2A2A3",
    "#D6D7D6",
    "#A1A2A3"
  ]
}) => {
  return (
    <g id="WarhammerR">
      <path
        opacity="0.63"
        d="M38.9,102.9c0.2,0,0.4-0.1,0.6-0.2c0.8-0.5,0.8-1.4,0.6-1.8l-1.9-3.4H38 L24.4,72.6v-0.2l-0.5-1v-0.2l-0.2-0.4h-0.2L21,66.3l-0.2-0.1l-1.6-2.8v-0.2l-0.3-0.6l1.7-1l0.2-0.5l0.5,0.1l1-0.6l0.2-0.3L24,56 l-0.4-0.7l-4.4-1l-0.4,0.1l-1,0.6l-0.2,0.5l-0.5-0.1l-6,3.3l-0.5-0.1l-1.2,0.7L9.6,60l-0.9,0.5l-0.1,0.4H8.5l-0.1,1.4l0.1,0.3 l1.8,3.2l0.2,0.2l1.2,0.6h0.5l0.9-0.5l0.4,0.1l1.2-0.7l0.1-0.4l1.8-1l0.3,0.6H17l1.5,2.8v0.1l2.5,4.6v0.2l0.2,0.4h0.2l0.5,1H22 l13.7,24.8v0.1l1.9,3.4C37.7,102.3,38.2,102.9,38.9,102.9z"
      />
      <rect
        x="27.5"
        y="60.5"
        transform="rotate(-28.959 28.278 82.454)"
        fill={colors[0]}
        width="1.6"
        height="43.9"
      />
      <rect
        x="16.7"
        y="63"
        transform="rotate(-28.959 17.613 63.018)"
        fill={colors[1]}
        width="1.9"
        height="0.1"
      />
      <rect
        x="18.9"
        y="67"
        transform="rotate(-28.959 19.803 67.035)"
        fill={colors[1]}
        width="1.9"
        height="0.1"
      />
      <rect
        x="18.8"
        y="67"
        transform="rotate(-28.959 19.752 67.05)"
        fill={colors[2]}
        width="1.9"
        height="0.1"
      />
      <rect
        x="16.9"
        y="63.1"
        transform="rotate(-28.959 17.847 63.599)"
        fill={colors[3]}
        width="1.9"
        height="1"
      />
      <rect
        x="20.6"
        y="66.7"
        transform="rotate(-28.959 21.508 70.073)"
        fill={colors[4]}
        width="1.9"
        height="6.8"
      />
      <rect
        x="22.4"
        y="73.1"
        transform="rotate(-28.959 23.192 73.312)"
        fill={colors[5]}
        width="1.6"
        height="0.4"
      />
      <rect
        x="28.7"
        y="77"
        transform="rotate(-28.959 29.493 84.583)"
        fill={colors[4]}
        width="1.6"
        height="15.2"
      />
      <rect
        x="37"
        y="97.9"
        transform="rotate(-28.959 37.916 99.786)"
        fill={colors[4]}
        width="1.9"
        height="3.8"
      />
      <rect
        x="36.2"
        y="98.1"
        transform="rotate(-28.959 37.01 98.186)"
        fill={colors[6]}
        width="1.6"
        height="0.2"
      />
      <polyline
        fill={colors[7]}
        points="23.3,71.1 23.5,71.5 21.7,72.6 21.4,72.1"
      />
      <path
        fill={colors[8]}
        d="M38.1,102l1.6-0.9c0,0,0.3,0.9-0.4,1.2C38.6,102.7,38.1,102,38.1,102z"
      />
      <path
        fill={colors[9]}
        d="M22.2,60.4l-1,0.6c0,0-0.1-0.2-0.3-0.5c-0.7,0.2-0.4,0.9-0.4,0.9l-8.6,4.8L9,60.9l8.6-4.8 c0,0,0.4,0.7,1,0.2c-0.3-0.6-0.3-0.6-0.3-0.6l1-0.6l4.4,1L22.2,60.4z"
      />
      <rect
        x="11.5"
        y="59.2"
        transform="rotate(-28.959 12.165 62.482)"
        fill={colors[3]}
        width="1.4"
        height="6.6"
      />
      <rect
        x="19.7"
        y="55.2"
        transform="rotate(-28.959 19.912 58.21)"
        fill={colors[3]}
        width="0.5"
        height="6"
      />
      <rect
        x="18.7"
        y="55.8"
        transform="rotate(-28.959 18.853 58.787)"
        fill={colors[3]}
        width="0.4"
        height="6"
      />
      <polygon fill={colors[3]} points="11.9,66.2 10.7,65.5 8.9,62.3 9,60.9" />
      <polygon fill={colors[3]} points="23.8,56.1 20.8,57.8 22.2,60.4" />
      <path
        fill={colors[3]}
        d="M19.8,60.1l0.6-0.6l0.5,1c0,0-0.7,0.1-0.4,0.9C20.2,60.7,19.8,60.1,19.8,60.1z"
      />
    </g>
  )
}

export default WarhammerR
