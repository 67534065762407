import React from "react"

const LeftScaled = ({ colors = ["#9D9D9D", "#FFFFFF"] }) => {
  return (
    <g id="LeftScaled">
      <path
        fill={colors[0]}
        d="M90.6,36.7c0.4,0.1,0.8,0.2,1.2,0.3c-2-2.7-2.8-6.2-5.5-8.6c-0.3-0.3-0.7-0.6-1.1-0.9 c-1.5-0.3-2.9-0.8-4.2-1.7l0,0c0.5,1.7,1.1,3.2,2,4.7c0.3,0.6,0.7,1.2,1.2,1.7c0.2,0.8,0.5,1.6,0.8,2.3c0.1,0.2,0.2,0.5,0.3,0.7 c1.5,2.3,2.8,4.8,6.5,5.8C91.3,39.6,91,38.1,90.6,36.7z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M83.9,31.6c0.3,0.1,0.7,0.3,0.9,0.7 c1.3,2.2,4,3.6,6.4,4.7c0.3,0.1,0.7,0.2,1,0.2c-2-2.7-2.8-6.2-5.5-8.6c-0.3-0.3-0.7-0.6-1.1-0.9c-1.5-0.3-2.9-0.8-4.2-1.7l0,0 c0.5,1.7,1.1,3.2,2,4.7C83.5,31.1,83.7,31.4,83.9,31.6z"
      />
      <path
        opacity="0.28"
        d="M84.8,31.1c-0.2-0.4-0.6-0.6-0.9-0.7 c-0.2-0.3-0.4-0.6-0.5-0.9c-0.7-1-1.1-2.2-1.6-3.4c-0.2-0.1-0.5-0.3-0.7-0.4l0,0c0.5,1.7,1.1,3.2,2,4.7c0.2,0.3,0.3,0.6,0.5,0.9 c0.3,0.1,0.7,0.3,0.9,0.7c1.3,2.2,4,3.6,6.4,4.7c0.3,0.1,0.7,0.2,1,0.2c-0.3-0.4-0.5-0.8-0.8-1.2C88.7,34.6,86.1,33.3,84.8,31.1z"
      />
    </g>
  )
}

export default LeftScaled
