import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SpellBookL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SpellBookL") ? (
    <g id="SpellBookL">
      <path d="M74.6486359,77.6446228l0.7509995,8.2610245c0,0,13.0888901,5.5252304,16.843895,4.237793 c3.7550125-1.2874298,14.1081085-11.3723145,14.1617508-11.5332413s-0.4827881-9.2802353-0.4827881-9.2802353L88.2203064,75.23069 L74.6486359,77.6446228z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SpellBookL, {
  character: "WomanDwarf"
})
