import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const DaggerLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("DaggerLeft") ? (
    <g id="DaggerLeft">
      <path
        id="S_x24_daggerLeft"
        d="M63.6930351,98.9497299l-4.7000008-5l1.5-10.1999969l2.6000023-7.5999985 c0,0,7.2999992-18,8.2999992-18.9000015l0,0l6.0999985,0.5999985l5.5,3l-4.2000046,20.3999939l-2,6.4000015l-0.3000031,1 l-1.6999969,4.0999985l-1.9000015,4.4000015l-1.1999969,1l-6.6999969,1.3000031L63.6930351,98.9497299z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(DaggerLeft, {
  character: "ManHalfling"
})
