import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const Antlers = ({ allEnabledElements }) => {
  return allEnabledElements.includes("Antlers") ? (
    <g id="Antlers">
      <path
        id="S_x24_antlers"
        d="M29.2,44.2c-1.5-0.4-2.8-1.1-3.5-1.6c-1.5-1-3.2-2.6-4.3-5.4c-0.4-0.3-0.7-0.6-1.1-0.9 c-2.3-2.2-3.4-5.2-2.9-8.2c0-0.2,5.2-7.2,5.8-9.3c0.4-1.6,1.1-2.8,1.6-3.6l0.2-0.3c0.7-1.1,1.7-2.9,3.5-4.3 c13.2-4,12.7,9.2,20.2,9.3c5.7,0.1,6.5-12.1,19.8-9.3c1.7,1.5,2.8,3.2,3.5,4.3l0.2,0.3c0.5,0.8,1.2,2,1.6,3.6 c0.6,2.4,5.8,9.1,5.8,9.4c0.4,3-0.6,5.9-2.9,8.1c-0.4,0.3-0.7,0.6-1.1,0.9c-1.1,2.8-2.8,4.5-4.3,5.5c-0.8,0.5-2,1.2-3.5,1.6 C66,44.6,30.8,44.6,29.2,44.2z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(Antlers, { character: "ManDwarf" })
