import React from "react"

const WizardShoes = ({ colors = ["#40706A", "#FFFFFF"] }) => {
  return (
    <g id="WizardShoes">
      <path fill="#21376C" d="M49.1,45" />
      <path
        fill={colors[0]}
        d="M59.7,91.9c0.1-0.3-7.7-0.6-7.7-0.6l-1.1-0.4l-1.8,19.8c1.9,0.6,5.1,2.8,5.1,2.8s5.7-20.3,5.9-20.4 C60.2,92.8,59.6,92.2,59.7,91.9z M50.9,110.3L50.9,110.3L50.9,110.3L50.9,110.3L50.9,110.3z"
      />
      <path
        opacity="0.16"
        fill="#FFF"
        d="M53.8,94.5c0,0,3.7-1.8,3.9-2.5c0,0-2.7,0.2-5.5-0.3 c-0.5-0.1-0.9,1-1.3,0.9c-0.2,0.6,1.2,1.3,1.1,1.3c-0.1,0-0.9,0.4-0.9,0.5c1,0.8,0.2,1.4,0.2,1.5c1.2,1,2,0.9-0.1,1.1 c0,0-0.4,0.1-0.4,0.1c0.2,0.2,0.7,0.6,1,0.8c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.9,0.1-0.9,0.1c0.2,0.2,0.4,0.4,0.7,0.6 c-1,3.6-1.2,4.8-1.3,7.3c0,0,0.1,0.1,0.3,0.1c0,0.1,0,0.2,0,0.2c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0 c-0.4,0-0.7,0.1-1.1,0.1c0,0-0.3,0-0.2,0.1c0.1,0.1,1.7,1.6,0.7,1.4c-0.1,0-0.7,0.1-0.7,0.1c0.5,0.8-0.4,3.4,0.3,4.1 c0.2,0.2,1.3-3.1,1.4-3.1c-0.2-0.3,0.9-0.1,0.6-0.4c0.6,0-0.2-0.5,0.5-0.8c0,0,0.1,0,0-0.1c-0.4-0.1-1.4-0.8-0.9-1.2 c0.2-0.2,0.7,0,0.9,0c0.4-0.1,0.5-0.1,1-0.4c0,0-0.6-0.1-0.7,0c-1.4,0.2-0.9-0.5-0.5-1.4c0.3-0.8,0-4.3,0.4-5.8 c0.2,0,0.4,0,0.5-0.1c0.2-0.2,0.2-0.4,0.2-0.6c0.3,0,0.5,0,0.5,0c0,0,0.4-0.1,0.2-0.1c-0.3,0-0.5,0-0.7-0.1 c0-0.3-0.1-0.7-0.1-0.9c0-0.1-0.5-0.1-0.8,0c0,0,0-0.1-0.1-0.1c0.5-0.1,1-0.1,1.5-0.2c0,0,0.5-0.1,0.3-0.1 c-0.3-0.1-3-0.8-0.8-0.6c0.1,0,0.9-0.1,0.8-0.1C53.6,95.5,53.5,94.5,53.8,94.5z M50.4,110.5L50.4,110.5L50.4,110.5L50.4,110.5 L50.4,110.5z"
      />
      <path
        fill={colors[0]}
        d="M49.2,109.4c-1,1.7-7.3,8.6-7.3,8.6l12.4,0.1l0.4-7.1C54.7,111.1,51.5,106.6,49.2,109.4z M50.9,110.3 L50.9,110.3L50.9,110.3L50.9,110.3L50.9,110.3z"
      />
      <path
        opacity="0.35"
        fill="#FFF"
        d="M49.9,108.8c0,0-5.8,7-6.5,8.4c1.1,0.8,8.9-8.3,8.9-8.3 S50.7,107.8,49.9,108.8z M50.8,110.3L50.8,110.3L50.8,110.3L50.8,110.3L50.8,110.3z"
      />
      <path
        opacity="0.56"
        fill="#21376C"
        d="M45.9,113.9c0,0,0.6-0.8,1.6-0.8c1,0.1,3.5,3.7,3.9,4.1 c-0.4-0.2-3-3.7-3.9-3.8C46.6,113.4,45.9,113.9,45.9,113.9z"
      />
      <path
        opacity="0.56"
        fill="#21376C"
        d="M47,112.2c0,0,0.6-0.8,1.6-0.8c1,0.1,3.5,3.7,3.9,4.1 c-0.4-0.2-3-3.7-3.9-3.8C47.6,111.8,47,112.2,47,112.2z"
      />
      <path
        opacity="0.56"
        fill="#21376C"
        d="M48.3,110.9c0,0,0.6-0.8,1.6-0.8c1,0.1,3.5,3.7,3.9,4.1 c-0.4-0.2-3-3.7-3.9-3.8C48.9,110.4,48.3,110.9,48.3,110.9z"
      />
      <path
        opacity="0.56"
        fill="#21376C"
        d="M59.4,91.8l-1.3,1.8c0,0-0.8,0.5-1.1,0.7 c0,0,0.8,0.9,0.6,0.9c-0.2,0-1.7-0.1-1.7-0.1l0.1,0.5l1.2,0.5l-1.7,0.5l0.2,0.8l0.6,0.4l-1.2,0.6l-1.3,6.9l0.9,0.6l-1.1,0.7 l-0.1,0.8l0.6,0.9l-1.4,1.9l0.8,1.7c0,0-2.6,4.7-6.8,6.2c2.5,1,7.7,0.1,7.7,0.1l0.4-6.9l5.4-18.3L59.4,91.8z"
      />
      <g>
        <path fill="#21376C" d="M88.9,45" />
        <path
          fill={colors[0]}
          d="M77.9,92.9c0.2,0.2,5.9,20.4,5.9,20.4s3.2-2.2,5.1-2.8l-1.6-19.7L86,91.2c0,0-7.7,0.3-7.7,0.6 C78.4,92.2,77.8,92.8,77.9,92.9z M87.1,110.2L87.1,110.2L87.1,110.2L87.1,110.2L87.1,110.2z"
        />
        <path
          opacity="0.16"
          fill="#FFF"
          d="M83.9,95.9c0,0,0.7,0.1,0.8,0.1c2.2-0.2-0.6,0.5-0.8,0.6 c-0.2,0.1,0.3,0.1,0.3,0.1c0.5,0.1,1,0.1,1.5,0.2c0,0,0,0.1-0.1,0.1c-0.3-0.1-0.8,0-0.8,0c0,0.3-0.1,0.6-0.1,0.9 c-0.2,0.1-0.4,0.1-0.7,0.1c-0.2,0,0.1,0.1,0.2,0.1c0,0,0.3,0,0.5,0c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.1,0.5,0.1 c0.5,1.4,0.1,4.9,0.4,5.8c0.4,0.9,0.9,1.6-0.5,1.4c-0.1,0-0.7,0-0.7,0c0.5,0.2,0.6,0.3,1,0.4c0.2,0,0.8-0.1,0.9,0 c0.5,0.5-0.5,1.1-0.9,1.2c-0.1,0,0,0.1,0,0.1c0.6,0.2-0.1,0.8,0.5,0.8c-0.2,0.3,0.9,0.1,0.6,0.4c0,0,1.2,3.2,1.4,3.1 c0.8-0.6-0.2-3.2,0.3-4.1c0,0-0.6-0.2-0.7-0.1c-1,0.3,0.6-1.2,0.7-1.4c0,0-0.2-0.1-0.2-0.1c-0.4,0-0.7-0.1-1.1-0.1c0,0,0,0,0,0 c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0-0.1,0-0.2,0-0.2c0.2,0,0.3,0,0.3-0.1c0-2.5-0.2-3.8-1.3-7.3c0.2-0.2,0.4-0.3,0.7-0.6 c0,0-0.7-0.1-0.9-0.1c0-0.1,0-0.2-0.1-0.2c0.4-0.2,0.8-0.5,1-0.8c0,0-0.3-0.1-0.4-0.1c-2.1-0.3-1.4-0.1-0.1-1.1 c0,0-0.8-0.6,0.2-1.5c0-0.1-0.8-0.6-0.9-0.5c0,0,1.3-0.7,1.1-1.3c-0.4,0.2-0.8-0.9-1.3-0.9C83,92.2,80.3,92,80.3,92 c0.2,0.7,3.9,2.5,3.9,2.5C84.5,94.5,84.4,95.5,83.9,95.9z M87.6,110.4L87.6,110.4L87.6,110.4L87.6,110.4L87.6,110.4z"
        />
        <path
          fill={colors[0]}
          d="M83.3,111.1l0.4,7.1l12.4-0.1c0,0-6.4-7-7.3-8.6C86.5,106.6,83.3,111.1,83.3,111.1z M87.1,110.2 L87.1,110.2L87.1,110.2L87.1,110.2L87.1,110.2z"
        />
        <path
          opacity="0.35"
          fill="#FFF"
          d="M85.8,108.9c0,0,7.7,9.1,8.9,8.3c-0.8-1.4-6.5-8.4-6.5-8.4 C87.3,107.8,85.8,108.9,85.8,108.9z M87.1,110.3L87.1,110.3L87.1,110.3L87.1,110.3L87.1,110.3z"
        />
        <path
          opacity="0.56"
          fill="#21376C"
          d="M92.1,113.9c0,0-0.6-0.8-1.6-0.8c-1,0.1-3.5,3.7-3.9,4.1 c0.4-0.2,3-3.7,3.9-3.8C91.4,113.4,92.1,113.9,92.1,113.9z"
        />
        <path
          opacity="0.56"
          fill="#21376C"
          d="M91,112.2c0,0-0.6-0.8-1.6-0.8s-3.5,3.7-3.9,4.1 c0.4-0.2,3-3.7,3.9-3.8S91,112.2,91,112.2z"
        />
        <path
          opacity="0.56"
          fill="#21376C"
          d="M89.8,110.9c0,0-0.6-0.8-1.6-0.8c-1,0.1-3.5,3.7-3.9,4.1 c0.4-0.2,3-3.7,3.9-3.8C89.1,110.4,89.8,110.9,89.8,110.9z"
        />
        <path
          opacity="0.56"
          fill="#21376C"
          d="M78.6,91.8l1.3,1.8c0,0,0.8,0.5,1.1,0.7c0,0-0.8,0.9-0.6,0.9 s1.7-0.1,1.7-0.1l-0.1,0.5l-1.2,0.5l1.7,0.5l-0.2,0.8l-0.6,0.4l1.2,0.6l1.3,6.9l-0.9,0.6l1.1,0.7l0.1,0.8l-0.6,0.9l1.4,1.9 l-0.8,1.7c0,0,2.6,4.7,6.8,6.2c-2.5,1-7.7,0.1-7.7,0.1l-0.4-6.9l-5.4-18.3L78.6,91.8z"
        />
      </g>
      <g>
        <polygon
          fill="#21376C"
          points="49.7,102 49.4,104.2 56.2,107.2 56.9,104.4"
        />
        <path
          fill={colors[0]}
          d="M49.7,102.2l-0.2,2l6.8,3l0.6-2.6C54.3,104.1,52,103.2,49.7,102.2z"
        />
        <path
          fill="#21376C"
          d="M56.8,105.5l-1.2,3.7c0,0-5.9-1-6.5,0.2c-0.6,1.2,0.2-2.2,0.2-2.2S54.3,106.2,56.8,105.5z"
        />
        <path
          fill={colors[0]}
          d="M49.1,107.6c-0.2,0.9-0.6,2.7-0.1,1.7c0.6-1.2,6.5-0.2,6.5-0.2l1.2-3.3C53.9,106.6,51.5,107,49.1,107.6z"
        />
        <g>
          <path
            fill="#21376C"
            d="M50.3,91.9c0,0,6.8,0.1,9.4,0.5c0.3,0,0.5,0.3,0.5,0.6c0,0.8-0.3,1.7-0.5,2.2c-0.1,0.2-0.3,0.4-0.6,0.3 l-8.5-1.6c-0.3,0-0.5-0.3-0.4-0.6L50.3,91.9C50.3,92,50.3,92,50.3,91.9z"
          />
          <path
            fill={colors[0]}
            d="M50.5,92c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0l-0.2,1.4c0,0.3,0.2,0.5,0.4,0.6l8.5,1.6c0.3,0,0.5-0.1,0.6-0.3 c0.2-0.5,0.4-1.4,0.5-2.2c0-0.1,0-0.1,0-0.2C56.9,92.5,53.6,92.5,50.5,92z"
          />
        </g>
        <g>
          <path
            fill="#21376C"
            d="M49.8,95.7c-0.2,0-0.4,3.7-0.4,3.7s3.3-0.2,5.5-0.5s3.9-1,3.9-1s1.1-3.4,1.1-3.6 C60,94,53.5,96.5,49.8,95.7z"
          />
          <path
            fill={colors[0]}
            d="M52.9,96.1c-0.9,0.1-2.2,0.3-3.1-0.2c-0.1,0.7-0.3,3.1-0.3,3.4h0c0.1,0,0.1,0,0.2,0 c0.7,0,3.4-0.2,5.4-0.5c2.3-0.3,3.9-1,3.9-1s0.7-2.3,1-3.3C57.6,95.3,55.2,95.8,52.9,96.1z"
          />
        </g>
      </g>
      <g>
        <polygon
          fill="#21376C"
          points="88.4,101.7 88.6,104 81.8,107 81.2,104.2"
        />
        <path
          fill={colors[0]}
          d="M88.4,102l0.2,2l-6.8,3l-0.6-2.6C83.7,103.9,86.1,103,88.4,102z"
        />
        <path
          fill="#21376C"
          d="M81.3,105.2l1.2,3.7c0,0,5.9-1,6.5,0.2c0.6,1.2-0.2-2.2-0.2-2.2S83.7,106,81.3,105.2z"
        />
        <path
          fill={colors[0]}
          d="M88.9,107.4c0.2,0.9,0.6,2.7,0.1,1.7c-0.6-1.2-6.5-0.2-6.5-0.2l-1.2-3.3 C84.2,106.4,86.6,106.8,88.9,107.4z"
        />
        <g>
          <path
            fill="#21376C"
            d="M87.8,91.7c0,0-6.8,0.1-9.4,0.5c-0.3,0-0.5,0.3-0.5,0.6c0,0.8,0.3,1.7,0.5,2.2c0.1,0.2,0.3,0.4,0.6,0.3 l8.5-1.6c0.3,0,0.5-0.3,0.4-0.6L87.8,91.7C87.8,91.8,87.8,91.7,87.8,91.7z"
          />
          <path
            fill={colors[0]}
            d="M87.6,91.8c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0l0.2,1.4c0,0.3-0.2,0.5-0.4,0.6L79,95.3 c-0.3,0-0.5-0.1-0.6-0.3c-0.2-0.5-0.4-1.4-0.5-2.2c0-0.1,0-0.1,0-0.2C81.2,92.3,84.4,92.2,87.6,91.8z"
          />
        </g>
        <g>
          <path
            fill="#21376C"
            d="M88.3,95.5c0.2,0,0.4,3.7,0.4,3.7s-3.3-0.2-5.5-0.5c-2.3-0.3-3.9-1-3.9-1s-1.1-3.4-1.1-3.6 C78.1,93.7,84.6,96.3,88.3,95.5z"
          />
          <path
            fill={colors[0]}
            d="M85.2,95.9c0.9,0.1,2.2,0.3,3.1-0.2c0.1,0.7,0.3,3.1,0.3,3.4h0c-0.1,0-0.1,0-0.2,0 c-0.7,0-3.4-0.2-5.4-0.5c-2.3-0.3-3.9-1-3.9-1s-0.7-2.3-1-3.3C80.5,95,82.9,95.6,85.2,95.9z"
          />
        </g>
      </g>
    </g>
  )
}

export default WizardShoes
