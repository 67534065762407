import React from "react"

const Belt = ({ colors = ["#1E1E1E", "#9C7000", "#FFFFFF"] }) => {
  return (
    <g id="Belt">
      <polygon
        fill={colors[0]}
        points="40.4395638,85.72052 54.6245689,85.8694153 54.5759659,89.1453094 40.3509941,89.108078"
      />
      <g fill={colors[1]}>
        <path d="M45.2098999,85.6212692v3.5240402h4.5823441v-3.5240402H45.2098999z M49.3670769,88.5993118h-3.779274 v-2.3328094h3.779274V88.5993118z" />
        <rect x="48.033" y="87.239" width="1.64" height="0.459" />
      </g>
      <path
        opacity="0.2"
        fill="#FFF"
        d="M54.5861778,89.1453171l0.047245-3.2758942 l-1.4201431-0.0111389c0.0519943,0.1696854,0.0992928,0.3524017,0.1417198,0.5278091 c0.2866707,0.9258804,0.5892143,1.8438873,0.9052696,2.7581711L54.5861778,89.1453171z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M53.8358879,89.1431351l0.2116699,0.0005646 c-0.0341492-0.0641556-0.0686188-0.1281204-0.1026611-0.1923141c-0.1676331-0.2986374-0.1792068-0.8429718-0.2832298-1.0826111 c0.1557312,0.3587952,0.2943001,0.717865,0.4331207,1.0838928c0.0308533,0.0813217,0.0538216,0.1414871,0.0729561,0.1913376 l0.0084953,0.0000381c-0.0523529-0.1484528-0.1299362-0.365242-0.1539078-0.4389572 c-0.1713562-0.5267181-0.3350449-1.0561905-0.4955788-1.5866318c-0.0297775-0.0984955-0.0912323-0.3992004-0.1717529-0.7323608 c-0.0544319-0.1757507-0.1062469-0.3519897-0.159565-0.528038l-0.4955826-0.0056076 C53.1257019,86.9242554,53.5291519,88.0287476,53.8358879,89.1431351z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M53.1138039,87.8864288 c0.0075264,0.0223236,0.0138321,0.045105,0.021286,0.0674286c0.0265503,0.0867462,0.0532494,0.1731873,0.079155,0.2599716 c0.0188141,0.0587692,0.0351524,0.1184845,0.0539665,0.1772537c0.0996895,0.1765442,0.2038231,0.362381,0.3025093,0.5554504 c-0.2571449-0.9236984-0.5370407-1.8308258-0.9560432-2.688446c0.0513115,0.1862106,0.1033058,0.3721619,0.1553383,0.5581512 C52.889164,87.171875,53.0036507,87.5286484,53.1138039,87.8864288z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M50.9528503,87.3867416 c0.0887604,0.5298843,0.1348076,1.1607742,0.2477913,1.7495422l0.5399437,0.0014267l-0.1120529-0.1947174 c-0.1774864-0.3085022-0.4900627-0.9768982-0.1000137-0.2902451c-0.2848053-0.5013809-0.3287735-1.2280884-0.4469872-1.7937775 c-0.071312-0.3412933-0.1329079-0.6845474-0.1980171-1.0270462l-0.2683945-0.003006 C50.7491379,86.3426056,50.8644867,86.8592377,50.9528503,87.3867416z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M50.5009155,85.8276367l-0.0119667-0.0001526 c-0.0059128,0.1287994-0.011467,0.2576752-0.0108223,0.3867035c0.0494499,0.3785629,0.0966415,0.757431,0.1503563,1.1353989 c0.080162,0.5636139,0.0989037,1.2134476,0.2635231,1.7859039l0.2606544,0.000679 C50.850296,88.0584869,50.6989326,86.965332,50.5009155,85.8276367z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M44.0764313,85.7550507l-0.2028885-0.0022964 c-0.0697327,1.1257935-0.2268295,2.2421036-0.3632851,3.3635406l0.2685394,0.0007095 C43.6491165,88.0303268,43.7615967,86.8258057,44.0764313,85.7550507z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M43.3079414,85.7463531 c-0.0251198,1.120903-0.0999413,2.2448959-0.1950798,3.3688812l0.0404205,0.0001144 c0.1470985-1.118866,0.2778206-2.238678,0.3035851-3.3673019L43.3079414,85.7463531z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M42.4413376,87.7242355 c0.0057335-0.080719,0.1084709-0.1522522,0.210598-0.222435c0.0557213-0.5873337,0.1138763-1.1747437,0.1816025-1.7608337 l-0.3075943-0.0034637c0.0009308,0.002182,0.0009308,0.0065155,0.0020409,0.0082474 c-0.0014343-0.002182-0.0017204-0.0057983-0.0029373-0.0082474l-0.096714-0.001091 c-0.2037506,1.1209793-0.401123,2.2451172-0.5492973,3.3756256l0.4430847,0.0011673 C42.3641891,88.6504517,42.4083023,88.1878891,42.4413376,87.7242355z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M42.0478477,85.7321167l-0.0761795-0.0008621 c-0.2097359,0.9779129-0.3131866,2.0226898-0.3661156,2.9749985c-0.0083122,0.1495438-0.238903,0.2724304-0.326622,0.4042435 l0.2869568,0.0007477C41.7137718,87.9834137,41.8730164,86.857048,42.0478477,85.7321167z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M41.3199539,85.7285767L40.433136,85.72052 l-0.086071,3.3875656l0.4868393,0.0023346C40.9691124,87.8851776,41.1344337,87.0846481,41.3199539,85.7285767z"
      />
      <rect
        x="45.288"
        y="85.712"
        opacity="0.36"
        fill="#FFF"
        width="4.401"
        height="0.223"
      />
      <rect
        x="45.603"
        y="88.632"
        opacity="0.36"
        fill="#FFF"
        width="3.732"
        height="0.083"
      />
    </g>
  )
}

export default Belt
