import React from "react"

const SmallEyes = ({ colors = ["#010101"] }) => {
  return (
    <g id="SmallEyes">
      <g id="C_x24_D_x24_eyesRound" fill={colors[0]}>
        <circle cx="44.754" cy="56.222" r="0.5" />
        <circle cx="50.354" cy="56.325" r="0.5" />
      </g>
    </g>
  )
}

export default SmallEyes
