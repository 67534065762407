import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Pants from "./Pants"
import SexyStrapPants from "./SexyStrapPants"

const CATEGORY = "pants"
const CHARACTER = "WomanGnome"
export const IDS = {
  PANTS: "Pants",
  SEXY_STRAP_PANTS: "SexyStrapPants"
}

export const components = {
  [IDS.PANTS]: Pants,
  [IDS.SEXY_STRAP_PANTS]: SexyStrapPants
}

export const Group = ({ props }) => (
  <g id="pants">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.PANTS,
    name: "Pants",
    defaultColors: ["#6B645F"],
    colorable: true,
    component: components[IDS.PANTS],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.SEXY_STRAP_PANTS,
    name: "Sexy Strap Pants",
    defaultColors: ["#4C3C25"],
    colorable: true,
    component: components[IDS.SEXY_STRAP_PANTS],
    inUI: true,
    enabled: false,
    subGroupId: 1
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
