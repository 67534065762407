import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const SwordRightHip = ({ allEnabledElements }) => {
  return allEnabledElements.includes("SwordRightHip") ? (
    <g id="SwordRightHip">
      <path
        id="S_x24_swordRightHip"
        d="M26.7999992,119.4000015H8.5c0,0-6.1999998-8.4000015,15.8999996-34.0999985 S26.7999992,119.4000015,26.7999992,119.4000015z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(SwordRightHip, {
  character: "ManHalfling"
})
