import React from "react"

const ScrollLeft = ({
  colors = ["#4D391B", "#805F2D", "#B0823D", "#CBA02C"]
}) => {
  return (
    <g id="ScrollLeft">
      <polygon
        fill={colors[0]}
        points="65.5999985,99.8000031 56.7000008,76.5 54.7999992,76.5999985 55.0999985,93.3000031 56.7000008,93.5999985 56.5999985,80.5999985 64,98.6999969"
      />
      <path
        fill={colors[1]}
        d="M64.8000031,102.5c-0.5,0-11.2999992-6.8000031-11.2999992-6.8000031s-1.2000008-0.5-1.0999985-1.8000031 c0.0999985-1.3000031,1.0999985-1.8000031,2.0999985-1.6999969C55.5000038,92.2999954,66,100,66,100 s0.4000015,0.5999985,0.1999969,1.5C66,102.3000031,65.1999969,102.5,64.8000031,102.5z"
      />
      <path
        fill={colors[2]}
        d="M55.5999985,92.5c0,0,0.7999992,1.0999985,0.2999992,2.5 c-0.4000015,1.5-2.0999985,1.4000015-2.0999985,1.4000015l-1.7000008-1.0999985l0.2999992-2.0999985l1.5999985-1.3000031 L55.5999985,92.5z"
      />
      <path
        fill={colors[3]}
        d="M54,91.8000031c0,0,1.2000008,0.9000015,0.7000008,2.4000015s-2.4000015,1.0999985-2.4000015,1.0999985 l0.2999992-2l0.7000008,1.5999985l1.0999985-0.8000031l-0.2999992-1.4000015l-1.2999992,0.5L54,91.8000031z"
      />
      <path
        fill={colors[0]}
        d="M65,99.3000031c0,0,0.9000015,0.9000015,0.3000031,1.8000031s-1.4000015,0.9000015-1.4000015,0.9000015 l0.4000015,0.3000031c0,0,1.3000031,0,1.5-1S65.5,99.4000092,65.5,99.4000092L65,99.3000031z"
      />
      <path
        fill={colors[2]}
        d="M65.1999969,99.3000031c0,0,0.8000031,1,0.1999969,2s-1.4000015,0.9000015-1.4000015,0.9000015 l0.6999969,0.4000015c0,0,1,0,1.5-0.9000015c0.5999985-0.9000015-0.1999969-1.8000031-0.1999969-1.8000031L65.1999969,99.3000031z"
      />
    </g>
  )
}

export default ScrollLeft
