import React from "react"

const WizardHat = ({ colors = ["#6C7670", "#7E8A83", "#49504C"] }) => {
  return (
    <g id="WizardHat">
      <path
        fill={colors[0]}
        d="M69.2997665,50.0016823c-0.082695,0,0-0.0826988,0.1653976-0.0826988 c-0.5788879-0.4961891-4.3830109,0-10.9988708,0.4134941c-1.4058723,0.082695-8.2698288,0.5788879-10.0891914,0.8269806 c-2.7290421,0.2480965-6.2023697,0.4961891-8.9314117,0.6615868c-2.9771385,0.1653976-5.0445938,0.1653976-7.9390354,0.5788879 c-1.9020596,0.2480927-4.383009,0.7442856-6.1196709,1.3231697c-0.2480946,0.1653976-0.4134922,0.2480965-0.4134922,0.4134941 c0,0.082695,0.0826988,0.1653938,0.1653976,0.1653938l0,0c0.2480946,0,0.5788879-0.082695,0.8269825-0.082695 c0.0826988,0,0.1653976,0,0.2480946,0c0.5788879-0.0826988,1.7366638-0.2480927,1.9847584,0 c0.0826969,0.082695-0.4134922,0.082695-0.4134922,0.1653976c-0.4961891,0-0.9923801,0-1.4885674,0 c1.9020596,0.2480927,5.7061806,0.2480927,9.5103035,0.4134903c3.5560226-0.082695,7.7736359-0.3307953,12.2393417-0.6615868 c2.4809494-0.1653938,4.7965012-0.4961853,7.0293541-0.6615829c1.4058723-0.1653976,2.8117409-0.4134941,4.0522156-0.5788879 l6.4504662-2.0674591l3.9695206-0.5788879v-0.0826988C69.4651642,50.0843773,69.3824615,50.0843773,69.2997665,50.0016823z M28.612215,53.3923111c-0.5788879,0.082695,0.6615849-0.2480965,0.9096813-0.2480965 C30.1007843,53.0615196,28.8603096,53.3096123,28.612215,53.3923111z"
      />
      <path
        fill={colors[1]}
        d="M53.5870934,51.4902496l-4.8792-9.0141106c-0.0826988,0-0.1653976,0-0.1653976-0.0826988 c-0.082695-0.0826988-0.1653976-0.2480965-0.2480927-0.3307915c-0.0826988-0.0826988,0-0.0826988,0-0.1653976 c0.082695-0.0826988,0.1653976-0.0826988,0.2480927-0.1653976c0,0,0,0,0-0.0826988c-0.082695,0-0.1653976,0-0.2480927,0 c0,0-0.3307953,0-0.2480965-0.0826988c0.1653976-0.1653976,0.2480965-0.3307915,0.3307953-0.4134903L46.5577393,33.79282 l-4.1349144,9.5102997c0.0826988,0,0.1653976,0,0.1653976,0.0826988s-0.1653976,0.1653976-0.3307953,0.1653976v0.0826988 v0.0826988c0.0826988,0,0.0826988,0,0.1653976,0c0,0,0.3307953,0,0.2480965,0.0826988 c-0.1653976,0.1653938-0.2480965,0.2480927-0.4134941,0.4134903l-1.1577759,8.0217323 c-0.082695,0.0826988-0.1653976,0.1653976-0.1653976,0.2480927c0.0826988,0.6615868,2.9771385,0.7442894,6.533165,0.4961891 c3.5560265-0.2480927,6.3677673-0.6615829,6.2850685-1.4058685 C53.6697922,51.6556473,53.5870934,51.5729485,53.5870934,51.4902496z"
      />
      <path
        fill={colors[2]}
        d="M66.9842148,50.0843773c-3.6387253,1.9847603-12.5701408,3.3906288-13.6452179,3.4733276 c6.3677673-0.4134903,12.9009323-2.0674553,14.3895035-2.7290421c0.5788879-0.2480927,1.2404709-0.4961853,1.6539612-0.6615829 c0.0827026,0,0.0827026-0.0826988,0.1653976-0.0826988c-0.082695,0-0.2481003,0-0.3307953-0.082695 c-0.16539,0,0.3307953-0.1653976,0.4134903-0.1653976s0.2481003,0,0.3307953-0.0826988 C70.7056351,49.4227943,71.1191254,48.8439026,66.9842148,50.0843773z M67.3977051,50.2497749 c-0.5788879,0.0826988,0.6615906-0.2480927,0.9096832-0.2480927 C68.8862762,50.0016823,67.6458054,50.2497749,67.3977051,50.2497749z"
      />
    </g>
  )
}

export default WizardHat
