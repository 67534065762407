import React from "react"
import { withSkinColor } from "../../builderConnector"

const Tail = ({ skinColor, color = [] }) => {
  return (
    <g id="Tail">
      <path
        fill={skinColor}
        d="M72,64.2c0,0-27.7,15.9-28.1,27.9c-0.1,2,1.6,3.1,4.2,4.2c2.4,1.1,16.8-0.3,23.6-2.4 c0.2,2.3-20.6,7.9-26.9,5c-3.7-1.7-6-3.5-6.3-6.7C37.7,83.9,47,71,64.4,59C73.5,54.4,72,64.2,72,64.2z"
      />
    </g>
  )
}

export default withSkinColor(Tail, { character: "ManMixed" })
