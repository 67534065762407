import React from "react"

const TightPants = ({ colors = ["#A6A8AB"] }) => {
  return (
    <g id="TightPants">
      <path
        id="D_x24_tightPants"
        fill={colors[0]}
        d="M85.0402145,95.648735l-2.2770386-8.4354477l-0.5666351-3.1165085 c0,0,0.2839661-7.6186142,0.2839661-12.1517181c-0.3963776-4.072403-0.7861862-6.2572708-3.4398575-16.0526657 c0,0-4.9895325,1.767971-10.4257126,1.767971S58.01408,56.149334,58.01408,56.149334 c-2.5936089,9.5136909-3.0926552,11.3701744-3.3467026,16.2200127c-0.2833214,4.344223,0.3305359,11.5857773,0.3305359,11.5857773 l-0.3305359,2.7387466c0,0-1.8887939,4.7050705-2.2193375,8.9548645c-0.2833138,2.9276199,0.1416626,4.2969971,0,6.9885254 c0.1889038,2.4554214,5.4775085,2.4554214,5.4775085,2.4554214l1.841568-6.3746643l1.1332779-8.5298767l1.1804962-4.0136871 l0.4249763-3.1165085l5.0525208-17.1711044c0,0,2.1485062,0.0102234,2.3137741,0 c0.9207916,4.5700989,4.7692032,16.6989059,4.7692032,16.6989059l0.6138611,3.7303696l1.1332779,3.3053894l1.2749329,8.8604126 l1.8992691,6.6107635c0,0,5.2886581,0,5.4775085-2.4554214C85.2291183,100.1818314,85.0402145,95.648735,85.0402145,95.648735z"
      />
    </g>
  )
}

export default TightPants
