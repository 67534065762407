import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import OpenCloak from "./OpenCloak"

const CATEGORY = "cloaks"
const CHARACTER = "ManDwarf"
export const IDS = {
  OPEN_CLOAK: "OpenCloak"
}

export const components = {
  [IDS.OPEN_CLOAK]: OpenCloak
}

export const Group = ({ props }) => (
  <g id="cloaks">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.OPEN_CLOAK,
    name: "Open Cloak",
    defaultColors: ["#297544", "#0E0D0D"],
    colorable: true,
    component: components[IDS.OPEN_CLOAK],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
