import React from "react"

const BobR = ({ colors = ["#4691FF", "#FFFFFF"] }) => {
  return (
    <g id="BobR">
      <path
        opacity="0.1"
        fill="#21376C"
        d="M68.6644516,49.7857208 c-0.0895844,0.1338005-0.3583298,0.8027954-0.3583298,0.8027954s-2.1051941-0.0446014-2.239563,0 c-0.1343765,0.0446014-1.2093658,1.4717865-1.3885345,2.0961838c-0.1791611,0.6243935-0.3583298,3.3003731-0.31353,4.058567 c0.0447845,0.758194,0.4031143,2.4975777,0.985405,3.1219749c0.582283,0.6243935,2.2843475,1.6501846,2.2843475,1.6501846 l0.3135452,0.2229996l-1.4781113-0.0892029l-1.65728-1.1595879l-2.1499786-3.4341698l2.239563-7.5373421L68.6644516,49.7857208z"
      />
      <path
        fill={colors[0]}
        d="M68.1381531,47.0093956c-0.6382751-0.1338005-1.4781113-0.8362427-3.3257523,0.5686455 c-3.6616821,5.954052-3.9976196,10.3359642-3.9976196,10.068367c0-0.1458435,2.3728828,3.3222733,3.1577873,4.1812134 c0.2312202,0.2530365,1.3571053,0.9792976,1.7804527,0.8027954c0-0.5017433-0.0885315-1.1042175,0.0335922-1.2041893 c-0.2687454-0.3010406-1.3773346-1.605587-1.3773346-1.605587s1.2093658,1.3045387,2.8890381,1.7393875 c-1.6124878-0.9031448-2.2507553-1.0703926-3.3929367-4.348465c0.0671883-2.0738831,0.3320923-4.5084801,1.1085815-5.8537025 c0.7149506-1.2386017,1.1951065-1.2689476,3.29216-1.0369415C68.6756516,48.0463371,68.7764282,47.1431961,68.1381531,47.0093956z"
      />
      <path
        opacity="0.39"
        fill="#21376C"
        d="M61.4194641,58.3488503l1.5788918,0.6689949 c0,0-0.9406128-0.7358894-0.5710869-2.2076797c0.1007805,0.9700432,1.4445152,2.7763252,1.4445152,2.7763252l-0.1679649-0.6020927 l1.3437424,1.6724854l0.7390518,0.7693443l-0.0335922,1.2041893l-0.6382751-0.0668983l-1.0413971-0.6020927l-2.6538849-3.4453239 V58.3488503z"
      />
      <path
        opacity="0.39"
        fill="#21376C"
        d="M68.3648987,47.3104362 c-0.0250702-0.0201454-0.0505066-0.0321693-0.075676-0.0508461l0.0000992-0.0076599l-0.0102921,0.0008087 c-0.8870926-0.6517639-1.9426422-0.4975166-2.6603851-0.0760956c-0.731575,0.4295387-1.1172104,1.13377-1.1505737,1.2376137 c1.0876617-0.9786797,3.0905838-0.3512077,3.0905838-0.3512077s-1.452919-0.158886-1.856041,0.1672554 c1.545311-0.2675896,1.8056412,0.8947945,1.8056412,0.8947945s-0.411499-0.5435677-1.4445114-0.0752563 c0.8314667-0.3512573,2.0491943,0.9031219,2.0491943,0.9031219l0.1679764-2.0153618l0.0080032-0.6508102 C68.3141403,47.2965851,68.3397217,47.2997818,68.3648987,47.3104362z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M66.508873,49.6965256 c-0.520874,0.1239204-0.9831467,0.3628922-1.3854675,0.689415c-0.7678757,0.9062004-2.306675,4.2170677-1.8170433,6.6237793 c0.2523766,1.2405205,0.8041725,2.3600082,1.6684074,3.0896683c-0.5509796-0.7743721-0.991291-1.8130074-1.2359886-2.9048576 c0.2626381-5.1279297,0.983387-5.9530678,1.6783028-6.8067131C65.7125397,50.0248795,66.2623444,49.8137665,66.508873,49.6965256z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M63.7692757,51.1027374 c-0.0003052-0.5318642,0.5392265-1.8187599,1.0263329-2.638279c-1.1085854,1.0034904-1.8140259,2.8264961-1.8140259,2.8264961 s-0.9013596,1.9209633-1.3605537,3.629303c-0.3071785,1.1427765-0.2250099,2.2172241-0.2519531,2.4585533 c0.1861191-0.3705978,0.0398521-0.5360756,1.1925659-2.1575089c-0.1044235,1.0030403-0.033596,1.5721359,0.3191376,2.3247566 c0.0335846-1.7561035,0.0335808-3.3616905,1.6628914-7.1080894 C64.5436707,50.4379692,64.1269379,50.6486702,63.7692757,51.1027374z"
      />
    </g>
  )
}

export default BobR
