import React from "react"
import { withSkinColor } from "../../builderConnector"

const RightHand = ({ skinColor, color = [] }) => {
  return (
    <g id="RightHand">
      <path
        fill={skinColor}
        d="M44.2,53.4c-0.1,0.2-3.4-0.5-5.5,1.1c-0.5,0.6,0,1.1,0.1,1.3c0,0.2-0.4,0.6-0.4,1s0.5,0.7,0.5,1 s-0.4,0.5-0.4,1s1.1,1.2,1.3,1.8c0,0.2,0,0.6,0.3,0.9s2,0.1,2,0.1l3-1.2l0.4-0.3c0,0,0.2-0.5,0.1-0.8c0-0.3-0.4-0.5-0.4-0.5 l0.3-0.2c0,0,0.4-0.5,0.4-0.8s-0.4-0.7-0.4-0.7H45l0.4-0.1c0,0,0.5-0.5,0.5-0.9s-0.6-0.9-0.6-0.9h-0.8L45,55c0,0,0.4-0.5,0.4-1 C45.3,53.6,44.5,53.4,44.2,53.4z"
      />
    </g>
  )
}

export default withSkinColor(RightHand, { character: "ManMixed" })
