import React from "react"

const ThighWrapL = ({ colors = ["#493812"] }) => {
  return (
    <g id="ThighWrapL">
      <path
        fill={colors[0]}
        d="M71.9,72c0.7,0.1,1.5,0.2,2.3,0.3c0-0.3,7.3-1,10.2-2c0,0,0.4,2,0,2.1c0,0-0.1,0-0.1,0 c0.2,0,0.3-0.1,0.5-0.1c0,0,0.1,0.2,0.1,0.6c0.1,0,0.1-0.1,0.2-0.1c0,0.7,0,1.4,0,2.1c-1.7,0.8-4,1.7-6.3,2.2 c2-0.1,3.9-0.6,5.7-1.3c0,0,0.3,1.3,0.1,1.9c0.1-0.1,0.2-0.1,0.4-0.2c0,0.3,0,0.7,0.1,1c0,0.3,0,0.7,0,1c-0.3,0.1-0.1,0.1-0.4,0.2 c0.1,0.5,0.4,1.7,0.1,2c-0.9,0.9-2.4,1.6-3.4,2.1c1.4-0.4,2.8-0.8,4.1-1.4c0,0.5,0,1,0,1.5c0,0.2,0,0.4,0,0.6 c-1.9,0.9-4,1.4-6.1,1.9c-1,0.2-2.3,0.6-3.4,0.5c-0.2-0.8-0.3-1.5-0.5-2.2c0.6,0.3,1.5,0.2,2.3,0.1c-0.8,0-2.5-0.8-2.6-1.1 c0-0.6-0.3-1.6-0.3-1.6c3.1,0.7,6.7,0.2,9-1.9c-1.6,0.7-3.3,1.2-5,1.5c-1.1,0.2-3.5,0.9-4.3-0.2c-0.1-0.3-0.1-0.5-0.2-0.8 c0-0.6,0.2-1.2,0.1-1.4c0.7,1.4,4,0.4,5.2,0.1c0.8-0.2,1.5-0.4,2.3-0.6c-2.5,0.6-5.4,0.9-7.8-0.1c-0.2-0.1-0.3-0.6-0.3-1 c-0.3,0-0.2-0.1-0.4-0.2c-0.2-0.7-0.4-1.5-0.7-2.2c2,0.4,4.3,0.1,6.6-0.5c-2.4-0.1-4.8-0.5-7.1-0.8c-0.1-0.2-0.2-0.5-0.3-0.7 C71.7,72.8,71.8,72,71.9,72z"
      />
    </g>
  )
}

export default ThighWrapL
