import React from "react"

const FlameLeft = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameLeft">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M94.7046738,63.260479c-2.5253525-4.3619919-2.8697281-8.035244-1.1478882-11.8232918 c0.688736-1.4922638,1.7218399-2.9845276,2.8697281-4.4767914c0.9183197-1.2626724,1.9514236-2.5253601,2.6401596-3.9028282 c0.1147842-0.3443718,0.344368-0.6887398,0.5739441-1.147892l0.688736,0.1147804 c-0.1147919,0.5739441,0,1.2626877,0.1147842,1.8366356c0.4591599,1.7218361,1.9514236,3.3288803,4.3619919,4.8211441 l-0.1147842,0.1147766l-0.5739517,0.8035202l1.1478882,0.2295761c0.3443756,0.9183159,0.8035278,1.8366356,1.2626877,2.6401558 c0.2295761,0.5739441,0.5739441,1.147892,0.8035202,1.7218399c0.1147995,0.4591484,0.3443756,0.9183159,0.4591599,1.3774643 c-0.688736,1.6070595-1.4922562,3.099308-2.4105682,4.5915718c-0.3443756,0.5739441-0.8035202,1.147892-1.1478958,1.7218361 c-1.7218399,0.8035393-3.6732483,1.4922638-5.7394638,2.1810036 C97.2300491,64.0639801,96.0821533,63.8344078,94.7046738,63.260479z"
        />
        <path
          fill={colors[1]}
          d="M96.0821533,63.7196121c-2.4105682-4.1324005-2.7549438-7.5760803-1.0331039-11.134552 c0.688736-1.4922638,1.7218399-2.8697319,2.7549438-4.3619919c0.5739441-0.9183197,1.2626801-1.7218399,1.8366241-2.6401558 c0.5739441,1.49226,1.8366241,2.9845238,3.902832,4.3619919l-0.9183044,1.2626877l1.7218399,0.2295761 c0.344368,0.8035202,0.8035202,1.6070404,1.1478882,2.295784c0.2295761,0.4591522,0.5739517,1.0330963,0.8035202,1.6070442 c0.2295837,0.344368,0.3443756,0.6887398,0.4591599,1.147892c-0.6887283,1.6070595-1.3774643,2.9845238-2.295784,4.3619919 c-0.2295837,0.5739479-0.5739441,1.0331154-0.9183197,1.4922638c-1.4922562,0.6887436-3.2140961,1.2626877-4.935936,1.8366356 C97.6892014,64.1787796,96.8856735,63.9491882,96.0821533,63.7196121z"
        />
        <path
          fill={colors[2]}
          d="M97.2300491,64.1787643c-2.1809998-3.9028473-2.5253754-7.2317276-0.9183197-10.4458275 c0.688736-1.3774681,1.7218399-2.7549362,2.7549438-4.2472c0.2295761-0.3443527,0.344368-0.5739441,0.5739441-0.8035202 c0.688736,0.9183159,1.607048,1.7218399,2.7549438,2.5253601l-1.1478958,1.6070595l2.4105759,0.3443718 c0.2295837,0.6887245,0.5739441,1.3774643,0.9183197,1.9514122c0.2295685,0.4591484,0.5739441,1.0330963,0.8035202,1.6070404 c0.1147919,0.3443718,0.2295837,0.5739479,0.344368,0.9183197c-0.688736,1.3774643-1.3774719,2.7549515-2.1809998,4.0176201 c-0.2295685,0.3443718-0.5739441,0.803524-0.8035202,1.1478958c-1.2626801,0.5739441-2.640152,1.0331116-4.0176239,1.49226 C98.2631531,64.1787796,97.6892014,64.0639801,97.2300491,64.1787643z"
        />
        <path
          fill={colors[3]}
          d="M98.3779449,64.2935562c-1.8366241-3.5584717-2.1809921-6.6577759-0.6887283-9.6423035 c0.5739441-1.147892,1.3774719-2.295784,2.1809921-3.443676c0.3443604,0.3443718,0.8035202,0.6887398,1.2626801,1.0331116 l-1.3774643,1.8366165l2.9845123,0.4591675c0.2295761,0.5739479,0.5739517,1.0330963,0.8035278,1.6070442 c0.2295685,0.5739441,0.5739441,1.0331116,0.8035202,1.6070595c0.1147842,0.2295761,0.1147842,0.4591484,0.2295761,0.6887245 c-0.6887436,1.3774834-1.2626953,2.5253754-2.0662155,3.7880478c-0.2295685,0.3443718-0.344368,0.5739479-0.5739441,0.9183159 c-1.0330963,0.4591675-2.0662003,0.803524-3.0993042,1.147892 C98.7223053,64.2935562,98.4927216,64.1787796,98.3779449,64.2935562z"
        />
        <path
          fill={colors[4]}
          d="M98.8371048,55.2252159l2.98452,0.4591484c0.2295761,0.4591522,0.4591599,0.9183197,0.688736,1.3774681 c0.2295685,0.5739479,0.5739441,1.0331154,0.8035202,1.6070595c0.1147842,0.2295761,0.2295685,0.3443718,0.2295685,0.4591522 c-0.5739441,1.147892-1.2626724,2.295784-1.9514084,3.443676c-0.2295837,0.2295761-0.3443756,0.4591675-0.4591599,0.6887398 c-0.5739441,0.2295761-1.2626801,0.4591522-1.8366241,0.6887283 C97.6892014,60.8498802,97.4596176,57.9801521,98.8371048,55.2252159z"
        />
        <path
          fill={colors[5]}
          d="M99.0666733,64.2935562c-1.1478958-2.295784-1.2626801-4.4767723-0.5739517-6.6577759 l1.1478958,0.2295723c0.1147842,0.3443718,0.344368,0.6887436,0.4591599,1.0331154 c0.2295761,0.5739441,0.5739441,1.0330963,0.8035202,1.6070404c0.1147842,0.1147995,0.1147842,0.1147995,0.1147842,0.2295761 c-0.4591522,1.147892-1.0331039,2.1810074-1.7218323,3.2141037 C99.1814575,64.0639801,99.1814575,64.1787796,99.0666733,64.2935562 C99.0666733,64.2935562,98.9518814,64.4083557,99.0666733,64.2935562z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M89.3095779,54.7660484c0.9183121,1.7218399,1.951416,3.443676,3.0993042,5.0507355 c-0.2295837-0.3443718-0.2295837-0.5739441-0.344368-0.8035202c-0.4591599-1.7218399-1.8366394-3.5584717-0.9183197-5.2803116 c-0.1147842-0.1147957-0.2295761-0.2295761-0.2295761-0.3443718c0.1147919-2.9845085,0.9183197-4.8211441,2.295784-7.6908722 c1.1478958-2.295784,1.2626877-2.6401405,3.7880478-4.4767723c0.1147919-0.1147995,1.2626801-5.5098877,1.2626801-7.1169281 c0-0.1147995,0.1147919-0.1147995,0.2295761-0.1147995c-0.1147842-0.5739441,0.8035202-2.1809921,0.5739441-1.6070442 c-0.6887283,2.0662041,2.2957916,6.7725601,2.4105759,7.23172c0,0,1.2626801,4.0176315,3.902832,6.5429916 c2.0662155,2.0661926,2.4105759,4.1324005,2.6401596,6.4281845c0.688736,0.2295761,1.3774643,0.5739479,1.3774643,0.8035202 c0,2.0662117-0.9183044,4.0176239-2.0662003,5.8542595c2.1809998-1.6070595,4.1324081-3.443676,4.591568-5.6246834 c-0.2295837-0.1147957-0.4591599-0.2295761-0.4591599-0.3443718c-1.2626877-3.5584564-4.7063599-6.4281845-5.9690399-9.98666 c-0.1147919-0.2295723,0.6887436,0,1.1478958,0.1147995c-0.8035278-2.6401558-3.0993118-8.4944153-3.2140961-8.6091919 c-1.6070404-1.6070518-4.4767761-6.3134136-5.3950882-8.1500397c-0.5739517,0.918314-2.640152,5.8542519-3.0993118,6.6577759 c-0.5739441,1.0331039,1.2626877,1.3774643,1.4922638,2.5253601c0,0.4591637-1.3774719,0.344368-1.607048,0.2295837 c-0.1147919,0-1.1478958,0.8035278-1.2626801,0.8035278c-2.295784,1.9514122-2.5253601,4.1324005-4.1324081,6.4281845 C89.3095856,47.0751724,89.1948013,50.8632202,89.3095779,54.7660484z"
      />
    </g>
  )
}

export default FlameLeft
