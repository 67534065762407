import React from "react"

const FlavorSaver = ({ colors = ["#1387BA"] }) => {
  return (
    <g id="FlavorSaver">
      <path
        id="C_x24_flavorSavor"
        fill={colors[0]}
        d="M46.6674385,62.2030487l-0.2145691,0.6705399h0.6437149 c0,0,0.1877518,0.7788544,0.2682152,0.7241783l0.6973572-0.8046455l0.4291458-0.0804634l-0.3754997-0.4023209 l-0.3755035-0.1609306L46.6674385,62.2030487z"
      />
    </g>
  )
}

export default FlavorSaver
