import React from "react"

const BodyBase = ({ colors = [] }) => {
  return (
    <g id="BodyBase">
      <path
        id="bodyBase"
        d="M54.827816,124.3833923c-0.1757317,0-0.3514595,0-0.5271873,0h-5.4476089 c-0.0878639,0-0.1757317,0-0.2635956,0c-1.9330215,0-3.6903152-0.7836685-4.9204178-2.2530365 c-1.2301064-1.4693756-1.9330254-3.3305817-1.8451576-5.4856567c0,0,3.9539108-18.3181686,4.3932343-21.1589584 l0.1757278-0.7836685c0.7907829-3.330574,1.9330254-23.5099564,4.3932343-28.7996979 c0.0878639-0.6856995-3.2509918,4.1142426-7.5563583,2.7428284c-3.5145874-0.8816223-10.4558983-6.9550247-4.7446938-16.1630898 c0,0,6.2383881-14.4978065,7.1170349-16.3590126c0.2635956-2.7428284,1.2301064-5.9754486,2.9874001-8.3264427 c1.9330254-2.6448727,5.5647736-5.0917816,8.4643059-5.1897373l-0.9958076,0.9775372 c0.5271873-0.1959171-0.0900459-11.3655577,3.2488136-15.6757164c4.9204178-6.8570704,16.2852325-4.4594359,19.0969009,0.5364304 c2.7238083,4.8979073,2.6078568,14.9433708,3.1350403,15.1392879c0,0,5.8869324,0.6857071,7.7320938,3.3305759 c1.7572937,2.350996,2.2844772,6.4652367,2.5480728,9.3060265c0.8786469,1.8612061,5.8869324,13.1263924,6.6777191,15.2814674 c2.1087494,2.8407898,4.2175064,7.4448204,2.6359406,11.3631439c-1.2301102,3.134655-5.4476089,6.2693214-9.4893875,5.3876991 c-1.9330215-0.3918304-5.184021-3.0366974-5.0082855-2.2530441c0.5271835,2.9387512,3.6024475,25.37117,4.3932343,28.7996979 l0.1757278,0.7836685c0.3514557,2.8407898,4.3932343,21.1589661,4.3932343,21.1589661 c0.0878601,2.0571136-0.6150513,4.0162811-1.8451614,5.4856491c-1.2301025,1.4693756-2.9873962,2.2530441-4.9204178,2.2530441 c-0.0878601,0-0.1757278,0-0.2635956,0h-5.4476089c-0.1757278,0-0.3514557,0-0.5271835,0 C79.9571152,124.3833923,57.5516243,124.3833923,54.827816,124.3833923z"
      />
    </g>
  )
}

export default BodyBase
