import React from "react"

const Eyes = ({ colors = ["#603813"] }) => {
  return (
    <g id="Eyes" fill={colors[0]}>
      <path d="M64.9477539,54.9899406c-0.4000015-0.0999985-0.5,0-0.6999969,0 c-0.3000031,0-0.5999985-0.2999992-0.5999985-0.5999985c0-0.0999985-0.2999992-0.2999992-0.2000008-0.5999985 c0.3999977,0.2999992,0.6000023,0,0.7999992,0C64.9477539,53.7899399,64.9477539,54.7899399,64.9477539,54.9899406z" />
      <path d="M71.5477524,55.0899391c0.4000015-0.0999985,0.5,0,0.6999969,0 c0.3000031,0,0.5999985-0.2999992,0.5999985-0.5999985c0-0.0999985,0.3000031-0.2999992,0.1999969-0.5999985 c-0.4000015,0.2999992-0.5999985,0-0.8000031,0C71.5477524,53.8899422,71.5477524,54.8899422,71.5477524,55.0899391z" />
    </g>
  )
}

export default Eyes
