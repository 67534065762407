import React from "react"

const Cloak = ({ colors = ["#304B60", "#262261"] }) => {
  return (
    <g id="Cloak">
      <path
        opacity="0.3"
        fill="#21376C"
        d="M96.4,61.4l-1.7-0.7c-0.6-0.1-1.1-0.4-1.5-0.8 c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.4,0.1-0.7c0-0.1,0.1-0.1,0.1-0.2c-0.4-0.2-0.6-0.6-0.6-1.1c-0.5-0.6-0.8-1.2-0.9-1.7 c0-0.1,0-0.2-0.1-0.3s-0.3-0.3-0.4-0.4c-0.2-0.3-0.6-0.7-0.7-1.1c-0.6-0.2-1.2-0.3-1.7-1.6c-1.2-3.5-6-14.2-6.4-14.6 c-7.3-7.7-16.9-7.7-24.6-1c-2.4,1.5-4.6,5.5-6.9,11c-0.2,0.4,0.5,0.7,0.3,1.2c-0.3,0.6-0.6,1.3-0.9,2c-0.7,1.6-2.3,3.1-3.8,4.8 c-0.2,0.5-0.4,1-0.8,1.4c-0.1,0-0.1,0.1-0.2,0.1c0.1,0.5-0.3,1.1-0.8,1.4c0.4,0.2,0.7,0.7,0.3,1.1c0,0.1,0,0.1-0.1,0.2 c-0.2,0.4-2.6,1.3-3.1,1.4l0.1,0.4c-4.1,23.2,0.2,55.1,1,56c2.3,0.3,7,0.7,13.3,0.7c-1.3-17,0.5-83.9,11.3-85.5 c14.4-2.2,13.5,75.1,11.2,85.6c3.4,0,13,1.5,14.8-0.6C95.5,116.2,99.4,84.7,96.4,61.4z"
      />
      <path
        fill={colors[0]}
        d="M42.5,61.4c0,0-0.8,0.5-2.2,0.3c-2,11.1-7.6,43.5-7.2,43.6c0.6,4.8,13.9,10.4,13.9,10.4L42.5,61.4z"
      />
      <path
        fill={colors[0]}
        d="M96.1,61.5c0,0,1.2,0.3,2.1,0.1c2.1,11.6,6.7,46.6,6.2,46.7c-0.7,3.7-13,6.5-13,6.5L96.1,61.5z"
      />
      <path
        fill={colors[1]}
        d="M90,36.9c-0.5-2.1-12.8-8.2-13.4-10.1c-0.4-1.1-0.1-11.2-0.4-12.3c-1.1-1.9-3.9-7-7.3-6.6 s-5.8,4.4-6.5,6.5c-0.2,0.5,0.6,10.9,0.2,12c-0.7,1.9-13.6,8.4-14.2,10.5c-1.2,4.5-1.2,4.7-3.5,8.8c3.8-1.7,9.9-5.4,13.3-7.5 c1.8-1.2,10.9-6,10.6-7.8c-6.5-8-4.6-13.2-3.9-13.3c2.5,0.6,5.8,0.8,8.3,0c0.7,0,2.4,4.1-3.8,13.3c-0.2,1.8,9.8,6.7,11.5,8 c3.3,2.5,8.3,6.3,12,7.8C90.8,42,91.2,41.4,90,36.9z"
        opacity="0.3"
      />
      <path
        fill={colors[0]}
        d="M51.9,29.2c-1.6,1.4-2.4,4.6-2.9,6.7c-1.2,4.4-1.5,5.6-3.7,9.5c0,0.1,0,0.1,0.1,0.1 c3.5-2.2,9.9-6.4,13.2-8.6c1.8-1.2,9.3-4.3,9.2-6.1l0,0c-6.6-8.2-4.5-13.5-4.3-15l0,0c3.5-4.9,9.3-3.4,11.7,0l0,0 c0.5,2.8,1.9,6-4.3,15.1l0,0c-0.1,1.8,8.1,4.6,9.9,5.8c3.3,2.2,8.8,6.9,12.2,9.2c0.1,0,0.1,0,0.1-0.1c-2.1-3.9-2.4-5.7-3.6-10.1 c-0.5-2.1-0.8-4.1-2.2-5.9c-0.9-1.1-8.6-4.7-9.5-5.5c-0.7-1.1,2.6-5.9,0.4-10.9c-1.7-4-4.4-7-8.9-7c-4.2-0.1-7.4,3-9.1,7.1 c-1.7,3.9,1.2,7.6,0.6,11.2C60.8,24.6,53,28.2,51.9,29.2z"
      />
      <g fill="#21376C">
        <path
          opacity="0.36"
          d="M60.6,24.4c0,0.1,0.2,2.8,6.6,6.6 C60,28.2,60.6,24.4,60.6,24.4z"
        />
        <path
          opacity="0.36"
          d="M77.9,24.4c0,0.1-0.2,2.8-6.6,6.6 C78.5,28.3,77.9,24.4,77.9,24.4z"
        />
      </g>
    </g>
  )
}

export default Cloak
