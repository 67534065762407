import React from "react"

const WhiteCollar = ({ colors = ["#F1F2F2"] }) => {
  return (
    <g id="WhiteCollar">
      <path
        fill={colors[0]}
        d="M74.4,80.1c0,0-0.7-7.8-2.7-8.6c-0.4-0.2,0.8-15-3.8-16.3C66.1,54,57.1,53.4,54.6,53c0,0-5.2,9.6-6,9.7 C45,57.3,42,53.1,42,53.1c-2.3,0.4-11.3,1.2-13,2.2c-4.3,0.9-3,16-3.4,16c-1.7,0.5-2.8,6.1-2.8,6.1s-0.2,4.4,1.5,5.4 s5.8-1.3,5.8-1.3s2.1-5.8,2.5-7.5s0.7-5.8,0.7-5.8c0.3,0.7,1.6,2.8,1.4,5.8c-0.2,4.5-1,9.9-1.2,11c1,3.2,11.4,2.4,16.3,3.2 c0-0.4,0-0.4,0-0.7c1.3-0.1,11.5,0,13.1-1.4c-0.3-1.9-1.9-12.4-1-14.9c0.5-1.3,1-2.4,1.7-4.2c0,0,0.5,6.7,1,8.4 c0.4,1.7,3.3,6.4,3.3,6.4s3.1,1.9,4.9,0.9C74.6,81.7,74.4,80.1,74.4,80.1z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M26.4,70.3c0,0,2.5-1.7,3.8,0.3l-0.7-1.8c0,0,1.1-0.5,1.6,0.2 c0.8-1.2,1.2-2.7,1.7-4.3s0.7,0.3,0.7,0.3s-0.1,7.5-0.8,9c-0.4,1.5-1.7,5.1-1.7,5.1s-1.9-2.4-3.8-2.3c0.3-1,1.1-2.3,1.1-2.3 S28.6,71.3,26.4,70.3z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M71,70.3c0,0-2.5-1.7-3.8,0.3l0.7-1.8c0,0-1.1-0.5-1.6,0.2 c-0.8-1.2-1.2-2.7-1.7-4.3s-1-0.4-1-0.4s0.7,9.6,1.1,11.1c0.7,2.3,2.3,4.4,2.3,4.4s1.2-3.1,3.1-3.1c-0.3-1-1.1-2.3-1.1-2.3 S68.8,71.3,71,70.3z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M43.9,65.3l-4.5-11.7l5,11.6l4.3-2.4c0,0-0.7,2.3-1.5,3 C46.4,66.3,43.9,65.3,43.9,65.3z"
      />
      <path
        opacity="0.42"
        fill="#21376C"
        d="M48.7,62.7c0,0,0.3,2.4,1.6,3s2.6-0.4,2.6-0.4l5-11.7 l-5.3,11.6L48.7,62.7z"
      />
      <polygon
        opacity="0.42"
        fill="#21376C"
        points="50,87.6 48.7,62.7 50.9,87.6"
      />
      <circle opacity="0.42" fill="#21376C" cx="47.9" cy="65.4" r="0.4" />
      <circle opacity="0.42" fill="#21376C" cx="48.1" cy="67.3" r="0.4" />
      <circle opacity="0.42" fill="#21376C" cx="48.2" cy="69.2" r="0.4" />
      <circle opacity="0.42" fill="#21376C" cx="48.4" cy="71.1" r="0.4" />
      <circle opacity="0.42" fill="#21376C" cx="48.5" cy="73" r="0.4" />
      <circle opacity="0.42" fill="#21376C" cx="48.7" cy="74.8" r="0.4" />
      <circle opacity="0.42" fill="#21376C" cx="48.8" cy="76.7" r="0.4" />
      <circle opacity="0.42" fill="#21376C" cx="48.8" cy="78.6" r="0.4" />
      <circle opacity="0.42" fill="#21376C" cx="48.9" cy="80.5" r="0.4" />
      <circle opacity="0.42" fill="#21376C" cx="49.1" cy="82.4" r="0.4" />
      <circle opacity="0.42" fill="#21376C" cx="49.1" cy="84.3" r="0.4" />
      <circle opacity="0.42" fill="#21376C" cx="49.1" cy="86.2" r="0.4" />
    </g>
  )
}

export default WhiteCollar
