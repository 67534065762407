import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BookSpellL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BookSpellL") ? (
    <g id="BookSpellL">
      <path d="M80.4957199,75.2843323c0.0755081-0.1519012-7.7245941-5.5788651-0.8046417-12.3378868 c6.9199448-6.7590141,10.8895264-2.5748634,10.8895264-2.5748634s-1.6629333-4.4523659,6.3835144-4.3450813 c8.0464478,0.1072884,8.5292358,6.061657,8.5292358,6.061657s5.9007263-4.935154,9.4948044,8.10009 s-3.8622971,16.6829758-3.8622971,16.6829758s-0.858284,4.8278656-6.2762299,8.7438049 c-5.4179382,3.9159393-9.6557312,1.8775024-9.6557312,1.8775024s-2.3066483,2.0384369-5.3643036,2.5748672 c-3.0576477,0.5364304-10.0312347-8.6901703-10.0312347-8.6901703S75.8824234,84.5645752,80.4957199,75.2843323z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BookSpellL, {
  character: "WomanDwarf"
})
