import React from "react"

const HighBrow = ({ colors = ["#282560", "#754C28"] }) => {
  return (
    <g id="HighBrow">
      <path
        opacity="0.1"
        fill={colors[0]}
        d="M68.3,16.1c0-0.1-0.2-0.2-0.2-0.3c0-0.2-0.1-0.4-0.1-0.4 s-0.1,0-0.1,0.1c-0.1-0.3-0.3-0.5-0.5-0.7c-0.1-0.1-0.2-0.3-0.3-0.4l0,0c0-0.1-0.1-0.1-0.1-0.2l0,0l0,0l0.7,0.1l0.5,0.7 C68.2,14.9,68.4,15.1,68.3,16.1z"
      />
      <path
        opacity="0.82"
        fill={colors[1]}
        d="M68.1,14.1L67.9,14l-0.3-0.4l-1.2-0.8l-1.6,0.5L64.3,14v0.3 l0.9-0.3l2,0.3c0,0,0.2,0.3,0.3,0.3c0.1,0,0.7-0.3,0.7-0.3L68.1,14.1C68,14.2,68.1,14.2,68.1,14.1z"
      />
      <path
        opacity="0.1"
        fill={colors[0]}
        d="M69.9,16.1c0-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.4,0.1-0.4 s0.1,0,0.1,0.1c0.1-0.3,0.3-0.5,0.5-0.7c0.1-0.1,0.2-0.3,0.3-0.4l0,0c0-0.1,0.1-0.1,0.1-0.2l0,0l0,0l-0.7,0.1L70,14.9 C70,14.9,69.8,15.1,69.9,16.1z"
      />
      <path
        opacity="0.82"
        fill={colors[1]}
        d="M70.1,14.1l0.2-0.1l0.3-0.4l1.2-0.8l1.6,0.5l0.5,0.7v0.3 L73,14l-2,0.3c0,0-0.2,0.3-0.3,0.3c-0.1,0-0.7-0.3-0.7-0.3L70.1,14.1C70.2,14.2,70.1,14.2,70.1,14.1z"
      />
    </g>
  )
}

export default HighBrow
