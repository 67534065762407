import React from "react"

const MaulL = ({ colors = ["#888787", "#FFFFFF"] }) => {
  return (
    <g id="MaulL">
      <path
        fill={colors[0]}
        d="M107.585968,77.1350174c0,0,6.2485046,10.3277054,6.8202515,10.1822662 s-2.4100647,6.9190369-2.9387512,6.7386627c-0.4200745-0.1660309-10.2772293-0.4007263-10.2772293-0.4007263 s-3.8109512-4.7010193-4.7878876-10.6868668c0.4368515-4.1414261,0.4502945-6.3637314,0.5876083-6.5665894L107.585968,77.1350174z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M104.0077591,87.7873001 c-0.2567062,0.4324112-2.8175201,5.8679199-2.8175201,5.8679199s-4.440155-7.803215-4.7620163-8.9833603 c-0.0596237-1.6759262-0.0258713-1.7035065-0.0258713-1.7035065l0.8108902-6.345047L104.0077591,87.7873001z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M104.0077591,87.7873001l0.4487839-0.0511856 c-0.079895-0.2315674-0.0511856-0.4487839,0.0717697-0.5430374c-0.8463745,0.5511627,0,0,0.2315674-0.079895 c0.122963-0.0942535,0.3401871-0.0655441,0.4631424-0.1597977l0,0c-0.0143509,0.1086121,0.079895,0.231575,0.0655365,0.3401794 c0.1229706-0.0942459,0.231575-0.079895,0.3545303-0.1741486c0,0,0.2028656,0.1373215,0.2827606,0.3688889l1.4549561-0.1392212 c-0.1885071-0.2459259-0.2827606-0.3688889-0.3913651-0.3832397c0.1086044,0.0143509,0.2172165,0.0287094,0.3258209,0.0430603 c0.1085968,0.0143585,0.3114624,0.1516724,0.5143204,0.2889862l0.231575-0.079895 c-0.1885071-0.2459335-0.3770142-0.4918518-0.3770142-0.4918518c0.1086121,0.0143585,0.122963-0.0942535,0.2315674-0.079895 c0.1086044,0.0143509,0.2971191,0.2602692,0.4712677,0.6148071l0,0c0.0143509-0.1086044-0.0942535-0.122963-0.0799026-0.2315674 c-0.1086044-0.0143509-0.1086044-0.0143509-0.1086044-0.0143509l0,0c-0.079895-0.231575-0.1741486-0.354538-0.1741486-0.354538 l0.1086044,0.0143585c0,0,0.0942535,0.1229553,0.1885071,0.2459183c0.2459259-0.1885147,0.1896439,0.2816925,0.4212112,0.2017975 c0,0,0.1227341,0.2572174,0.2313309,0.2715683c0.1086121,0.0143585,0.3784256-0.2579651,0.9161148-0.3791351l-6.1382828-1.4717178 L104.0077591,87.7873001z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M103.745575,86.4266205l10.7066956,1.0346756 c0,0-6.6840286-9.1971207-6.7864075-10.0946884c-1.1661682-0.2315903-10.4586868-0.9361191-10.4586868-0.9361191 l0.1597977,0.4631348c0.434433,0.0574188,0.3203354-0.3137131,0.2772675,0.0121078 c-0.0143585,0.1086044-0.0430603,0.3258286-0.0574188,0.4344254l0.5736465,1.5123825l0.122963-0.0942535L98.2690735,78.86689 c0.0799026,0.2315674,0.4738693-0.2303391,0.5537643,0.0012207l0.7334442,1.9755096l0,0l0,0l0.0942535,0.1229553 c0.1086044,0.0143585,0.0942535,0.122963,0.0942535,0.122963c0.31147,0.1516647,0.2971115,0.2602768,0.3626556,0.6004486 c-0.0143509,0.1086121-0.0143509,0.1086121-0.0287094,0.2172165c0.1741562,0.3545227,0.2540512,0.5860977,0.2396927,0.6947021 c-0.0143509,0.1086044-0.0143509,0.1086044-0.0143509,0.1086044l0,0l0.1454468,0.5717468 c0.1086121,0.0143509,0.122963-0.0942459,0.2315674-0.079895c0.2315674-0.0799026,0.4487839-0.0511932,0.5430374,0.0717697l0,0 l0,0l0.0942535,0.1229553c0.1741409,0.354538,1.6263199-0.943985,1.583252-0.6181641 c-0.0430679,0.3258286-0.6899643,1.2616272-0.7330246,1.5874481l1.09758,0.6708221l0,0 c0.2315674-0.0799103,0.3545303-0.1741562,0.5860977-0.2540512c0,0,0,0-0.0143509,0.1086044 c-0.0942535-0.1229706,0.0143509-0.1086197,0,0c-0.0942535-0.1229706-0.0942535-0.1229706,0,0l0,0 C103.7661667,85.4347839,103.8029938,85.9921722,103.745575,86.4266205z"
      />
      <path
        fill={colors[0]}
        d="M100.0325699,85.9319153c0-0.9471359-0.6697388-1.4233932-0.8167725-1.4996338 c-0.1229706-0.0637589-54.4642601,0.4181671-54.4642601,0.4181671s-1.2233887-0.0401154-1.4712105,0.9537735 s0.8588333,1.567688,1.2789001,1.4553833c0,0,54.731926,0.0365448,55.1232986-0.2226944 C99.8341217,86.9031296,100.0325699,86.8790512,100.0325699,85.9319153z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M86.1625366,84.8602295 c-0.2172165,0.0194244-0.3258209,0.0291367-0.5573883-0.02491c0.8238907,0.8233261,1.6334381,1.5731888,2.5515823,2.3133469 c0.1086044-0.0097122,0.2172165-0.0194244,0.3258209-0.029129l0,0 C87.7959671,86.4334183,86.9720764,85.6100922,86.1625366,84.8602295z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M54.4118156,86.2131271 c1.6291084-0.1456451,3.258213-0.2912903,4.7930756-0.3537674c-0.2746353-0.2744446-0.6435242-0.4657059-0.918148-0.7401505 c-2.4324226-0.0067825-3.9672737,0.055687-3.9672737,0.055687s-8.4487839-0.0346222-8.8401527,0.2246094 C45.8625488,85.6642456,54.1371841,85.9386902,54.4118156,86.2131271z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M74.720047,86.9427643 c0.7745972,0.0055008,1.657814,0.0012817,2.5410309-0.0029297c-0.3832397-0.2647324-0.7808304-0.6029282-1.1640701-0.8676682 c-0.7745972-0.0054932-1.5348587,0.0624771-2.4180603,0.0666885 C74.0621796,86.4035797,74.4454193,86.6683197,74.720047,86.9427643z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M63.3612633,87.0612717 c1.2090263-0.0333405,3.1926765-0.0611801,5.5164833-0.044693c-0.2746277-0.2744446-0.6435165-0.4657059-0.9181366-0.7401505 c-1.7664261,0.0084229-3.5184937,0.0903091-5.2849274,0.0987396 C62.826355,86.5858536,63.1009865,86.8602982,63.3612633,87.0612717z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M90.371933,87.0297165 c0.1086121-0.0097046,0.3258209-0.029129,0.434433-0.0388336c-0.0143509-0.0734634-0.0143509-0.0734634-0.0287094-0.1469345 c-0.7009354-0.7595673-1.4018631-1.5191422-2.1970444-2.1955338c-0.2172165,0.0194168-0.5573883-0.0249176-0.7746124-0.0055008 C88.6298904,85.4662323,89.4394379,86.2161026,90.371933,87.0297165z"
      />
    </g>
  )
}

export default MaulL
