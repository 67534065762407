import React from "react"

const Dagger = ({
  colors = ["#8B5E3C", "#353891", "#878791", "#FFFFFF", "#4C2C26"]
}) => {
  return (
    <g id="Dagger">
      <path
        fill={colors[0]}
        d="M51.539978,83.0245514c0.0639038,1.1652222,0.1619263,2.4295044,0.3191528,3.6154785 c0.6760254-0.2416992,1.6305389-0.6558762,2.3049164-0.9011154c-0.3194809-1.366188-0.4897881-2.646965-0.6167374-2.7574081 C52.8909721,82.7391663,51.7832031,82.9274445,51.539978,83.0245514z"
      />
      <path
        opacity="0.39"
        fill={colors[1]}
        d="M51.539978,83.0245514c0.0639038,1.1652222,0.1619263,2.4295044,0.3191528,3.6154785 c0.6760254-0.2416992,1.6305389-0.6558762,2.3049164-0.9011154c-0.3194809-1.366188-0.4897881-2.646965-0.6167374-2.7574081 C52.8909721,82.7391663,51.7832031,82.9274445,51.539978,83.0245514z"
      />
      <path
        fill={colors[2]}
        d="M52.1926689,84.3508987l1.005806,8.8913269l-0.2011604,0.8851089l0.2656631-0.2578506l1.1022301-1.0698166 l-0.9253387-8.6096954c0,0-0.4023247-3.7416-0.7644157-4.2646179c-0.3620872-0.5230179-0.8448753,0.0804596-0.8448753,0.0804596 L52.1926689,84.3508987z"
      />
      <path
        opacity="0.36"
        fill="#FFF"
        d="M53.8908615,92.5944061c-0.0938721,0.3352661-0.6328545,1.2575607-0.6328545,1.2575607 l1.1022339-1.069809l-0.9253426-8.7840424l-0.3889122,0.1072845C53.0459862,84.1054001,53.9847374,92.25914,53.8908615,92.5944061 z"
      />
      <path
        opacity="0.39"
        fill={colors[1]}
        d="M52.434063,86.4899139c0,0,0.0134087,0.6973572,0.0603485,0.6370087 c0.046936-0.0603485,1.2136726-0.4224396,1.2136726-0.4224396l-0.1072884-0.7644119L52.434063,86.4899139z"
      />
      <path
        fill={colors[4]}
        d="M52.1725502,84.1296234l1.2472-0.3352737l-0.744297-4.6334076 c0,0-0.3620872-0.1005859-0.4626694-0.1609344s-0.3017426,0.3017426-0.382206,0.34198 C51.7501144,79.3822174,52.1725502,84.1296234,52.1725502,84.1296234z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M51.9646835,79.7040787l0.0670547,0.6437149 c0,0,0.7375908-0.1743393,0.6839485-0.1609344c-0.0536423,0.0134125-0.0536423,0.120697-0.0536423,0.120697l-0.6034851,0.1609344 l0.0536423,0.6973572l0.697361-0.1609268v0.1341019l-0.670536,0.120697l0.1072845,1.6227036l0.8046455-0.1743393 l0.0268211,0.0938721l-0.8180542,0.2011642l0.0938721,0.8851089l0.8582878-0.268219l0.0268211,0.120697l-1.005806,0.3084488 l-0.3620872-4.626709l0.2816238-0.3822021C52.1524353,79.0402451,51.9378624,79.2212906,51.9646835,79.7040787z"
      />
      <polygon
        opacity="0.2"
        fill={colors[1]}
        points="52.0585594,86.2887497 52.8095627,94.6570587 54.7675323,92.9941254 53.8396568,85.6596832"
      />
      <polygon
        opacity="0.6"
        fill="#FFF"
        points="52.9973145,94.1273346 53.3191719,93.2019958 52.6151085,87.0665741 52.4944115,87.1269226 53.1783562,93.1617584"
      />
      <polygon
        fill={colors[0]}
        points="51.9713898,83.1640472 52.3154144,86.4802856 54.1640472,85.7389145 53.8421898,84.2704315"
      />
    </g>
  )
}

export default Dagger
