import React from "react"

const OnyxDaggerR = ({ colors = ["#44403F", "#FFFFFF"] }) => {
  return (
    <g id="OnyxDaggerR">
      <path
        fill={colors[0]}
        d="M43.2142143,54.0833931c-2.9071312-5.0273056-4.011116-17.9405823-4.011116-17.9405823 s7.245636,12.5190964,7.9119759,18.2880821c-1.4918976-0.4102669-0.1228638,1.506691-0.1228638,1.506691 s0.9555817,1.3506012,1.0194168,3.2506371c0.0638351,1.900032,1.0443382,3.4966583,1.0443382,3.4966583l-0.1930695,0.0639038 l-1.5384026,0.5091972l-0.7283669-0.4934464c0,0-0.0218773-1.6879196-1.1042442-3.2508278 c-1.0823097-1.5629272-0.9745674-3.968708-0.9745674-3.968708S44.166729,52.8640518,43.2142143,54.0833931z"
      />
      <path
        opacity="0.3"
        fill="#FFF"
        d="M46.716053,54.1643219 c0.0593567,0.0605927-2.5764275-0.9553871-2.5764275-0.9553871l-4.8755112-16.7786484 c0,0,3.3119354,11.1371269,5.0820847,16.3571205C45.0003471,53.2913284,45.6422577,53.5662155,46.716053,54.1643219z"
      />
      <path
        opacity="0.65"
        fill="#FFF"
        d="M39.2084427,36.1887894c0,0,6.6006584,11.1211891,7.8447266,17.9910889 c-0.2405396-0.1023636-0.1582603-0.2228279-0.3613205-0.6035881c-0.0814133-0.1526604-0.9072533-2.9965439-2.3386803-6.3268585 C42.2144279,42.2734947,39.2084427,36.1887894,39.2084427,36.1887894z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M43.8426781,53.2507172l-0.5803108,0.6794319 c0,0-2.9465103-5.445118-3.8282547-15.6632118c1.1257019,9.162632,3.7213364,15.0841103,3.7213364,15.0841103 L43.8426781,53.2507172z"
      />
      <path
        opacity="0.3"
        fill="#FFF"
        d="M45.6114655,55.0949821c0,0,0.0451851-1.3145714-0.9387283-1.2996826 c-0.6724358,0.1236839-0.0966454,1.5858765-0.0206108,1.7302246c0.0760307,0.144352-0.2847481,2.2979469,1.3138695,4.3963051 c0.6749573,1.6977692,0.7490196,2.7751045,0.7490196,2.7751045l0.6439285,0.3236618l-0.7056274-3.2415047 C46.6533165,59.7790909,45.2159042,57.6556282,45.6114655,55.0949821z"
      />
      <path
        opacity="0.65"
        fill="#FFF"
        d="M46.2633286,54.6743927 c-0.0017014-0.0603256,0.1882858,1.2090416,0.6073494,1.5788803c0.4190674,0.3698387,0.8267097,3.1378593,0.864769,3.1888313 s0.6299515,2.9275208,1.0047722,3.163681c0.0199013-0.3879967-0.9103584-2.6863861-0.9020233-3.6214752 c0.0083313-0.9350929-0.3066444-2.3988533-0.8527985-2.8325768 C46.6237564,55.6993217,46.2692451,54.8843269,46.2633286,54.6743927z"
      />
    </g>
  )
}

export default OnyxDaggerR
