import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { apiRoutine } from '../routines'
import { apiRequestAction } from '../reducers/apiReducer'
import { remixCharacter } from '../actions/builderActions'
import MinisGrid from './MinisGrid'

class RecentMinis extends React.Component {
  componentDidMount() {
    this.props.apiRoutine(apiRequestAction('minis.recent', 'recentMinis'))
  }

  handleRemix = (mini) => {
    this.props.remixCharacter(mini.id)
  }

  render() {
    const { recentMinis } = this.props

    const minis = recentMinis.map(mini =>
      <div key={mini.id}>
        <div className="pawn-container">
          <div className="pawn-image">
            <a onClick={() => this.handleRemix(mini)}><i className="fas fa-pen-square"></i></a>
            <img width="200" src={mini.file.default} />
          </div>
        </div>
      </div>
    )

    return (
      <div>
        <h1>Feed of Recent Creations</h1>
        <div className="content"><MinisGrid items={minis} /></div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  recentMinis: get(state, 'apiReducer.data.recentMinis') || [],
  queue: get(state, 'printQueueReducer.queue')
})

const mapDispatchToProps = {
  apiRoutine,
  remixCharacter
}


export default connect(mapStateToProps, mapDispatchToProps)(RecentMinis)
