import React from "react"

const HalfCircle = ({ colors = [] }) => {
  return (
    <g id="HalfCircle">
      <path d="M100.3593674,118.0940247c0,17.5999908-14.3000031,31.8999939-31.9000015,31.8999939 s-31.8999977-14.3000031-31.8999977-31.8999939H100.3593674z" />
    </g>
  )
}

export default HalfCircle
