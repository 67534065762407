import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const DaggerRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("DaggerRight") ? (
    <g id="DaggerRight">
      <path
        id="S_x24_daggerRight"
        d="M56.5182571,93.0584412l3.2818718-3.4913559l-1.0474052-7.1223602l-1.815506-5.3068542 c0,0-5.0973816-12.5688782-5.7956505-13.1973267l0,0l-4.2594528,0.4189606l-3.8404884,2.0948181l2.9327393,14.2447205 l1.3965416,4.4689331l0.2094841,0.6982727l1.1870575,2.8629074l1.3267174,3.0723953l0.8379211,0.6982727l4.6784134,0.907753 L56.5182571,93.0584412z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(DaggerRight, {
  character: "WomanGnome"
})
