import React from "react"

const Tank = ({ colors = ["#77634F"] }) => {
  return (
    <g id="Tank">
      <path
        fill={colors[0]}
        d="M40.7141762,85.8931046l13.7036057-0.34198l-0.4951286-5.7665863l0.4425545-4.9083328v-7.8050537 l-1.2069664-0.120697c0,0-3.4401741,7.3609467-5.778595,7.3609467c-2.3384171,0-4.8829498-7.6023407-4.8829498-7.6023407 l-1.7299843,0.2816238l-0.2011604,8.126915l0.4827843,4.3853149L40.7141762,85.8931046z"
      />
    </g>
  )
}

export default Tank
