import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LongBowRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LongBowRight") ? (
    <g id="LongBowRight">
      <path d="M27.2652893,91.0384293c-0.2221794-1.0873718-0.3554821-1.7397995-0.4653988-2.0005341l-0.2408676-0.3472519 l-0.1099167-0.2607346l-0.0876942-0.1520004l-1.8264427-4.7809067l-0.1333122-0.6524277 c-0.1753902-0.3039932-0.4606972-0.8687286-0.9868698-1.7807083c-2.0170021-3.4959259-8.1112423-13.9182358-3.7315598-23.2522545 l0.2806015-0.5670815c0.4314232-0.9377289,1.3164806-2.7044449,1.7911625-3.7076492l-0.0876942-0.1519966l0.1262512-1.0452881 c0.5751858-3.0060883,2.9825668-5.0838547,5.8775616-5.0523567l4.6909924,2.7230072l0.175396,0.3039932l0.641964,2.5874748 c0,0,19.7810097,51.3506508,20.2405281,51.9364204l1.1856499,2.476387l0.0666504,0.32621l-1.6543274,5.2089386 c-1.6696014,1.5305481-4.6265602,2.3046417-7.4420929,0.4444733l-0.2619133-0.1730347l-0.6769905-0.5413361l-0.1099129-0.2607269 c-1.046463-0.4092026-2.8997002-1.1633148-3.8806915-1.5292511l-0.6103363-0.215126 c-0.9809952-0.3659439-1.8099785-0.8195801-2.66119-1.3819504C30.1068268,104.390892,27.9318237,94.3005447,27.2652893,91.0384293 z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LongBowRight, {
  character: "ManGnome"
})
