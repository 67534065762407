import React from "react"

const MediumSleve = ({ colors = ["#00A69C", "#FFFFFF", "#262261"] }) => {
  return (
    <g id="MediumSleve">
      <g id="boobs">
        <path
          fill={colors[0]}
          d="M74.4072418,69.5177155c0.296524-0.6580124,0.1341019-0.509613,0.3218536-0.5632553 c-0.0242538-0.0120163,0.5667038,1.3933258,0.7890778,1.8235779c0.956665,2.1781769,1.331337,2.9705734,1.7267227,4.2190857 c0.9884109-0.5736542,2.7857819-1.1810379,3.4117508-1.2147903c-0.0994568-0.3675919-2.0116119-5.6056976-2.0116119-5.6056976 s-0.1420975-4.4219894-3.20224-3.9670029c-0.716629,0.3669739-1.5870667,0.8523636-1.3927994,0.7425537 c0.0515823-0.0291443-0.1408463,0.0747528-0.497467,0.2411575c-0.0103073-0.0081024-1.840332,0.6710587-3.1261292,1.1235428 c-0.7279816,0.1810684-1.4987793,0.3105774-2.2486115,0.3223648c-2.0588074,0.032402-5.1791687-1.4613037-5.2180786-1.4270554 c-0.3017807-0.1427917-0.4585571-0.2277222-0.4111748-0.2009583c0.1943054,0.1098099-0.6761322-0.3755798-1.3927536-0.7425537 c-3.1021461-0.311924-3.1096535,4.0688858-3.1096535,4.0688858s-1.93927,4.854538-2.0920753,5.3911209 c0.6260147,0.0337524,2.3938408,0.6695099,3.3822899,1.2431717c0.3953476-1.2485199,0.9769173-2.4047089,1.8500557-4.5727844 c0.2223778-0.4302521,0.5585709-1.5371017,0.5342751-1.5250778c0.0352745,0.1038132,0.2399025-0.0679398,0.5364304,0.5900726 c0.0023422,0.1391907,0.0091591,0.8606415,0.1609306,1.3410721c0.1665916,0.527359,0.4885559,0.7864609,0.6906509,1.1399155 c0.502903,1.4483566,0.2888412,4.950592,0.0067062,5.5654602c-0.8582878,0.8314667-2.4675789,4.1573257-2.4675789,4.1573257 l7.9123459,1.7165756l7.3759079-1.4751816c0,0-1.0920105-3.009613-2.2938843-4.1837845 c-0.0842972-0.0823517-0.1691284-0.1556702-0.254158-0.2182922c-0.3620911-1.6394653-0.7565613-3.528717,0.0804672-5.4682312 C73.978096,71.2611084,74.401474,69.8604889,74.4072418,69.5177155z"
        />
        <path
          opacity="0.15"
          fill="#FFF"
          d="M63.5612984,69.0684509 c0.0528259-0.1250839,2.6553307-1.4584198,4.1271629,0.7167511 C69.0581284,71.5290604,65.0674057,69.5530472,63.5612984,69.0684509z"
        />
        <path
          opacity="0.1"
          fill={colors[2]}
          d="M74.1737671,69.66185 c-0.0098267,0.0799561-1.2197876,2.4400024-2.7056274,2.8867188c-1.1826782,0.3555298-2.7369995-0.7342682-3.0890503-1.9412231 c-0.2111969,1.0762024-1.9202271,2.4023438-3.1029053,2.046814c-1.4857788-0.4467163-2.6958008-2.8067017-2.7056274-2.8866577 c-0.0708618,0.5629883,0.1408081,1.9512329,0.7543945,2.6854858c0.4111328,1.9727173,1.9819336,6.7341309,3.3451538,6.1390991 c0.3264771-0.1425171,0.7332764-0.279541,1.1610107-0.3792114c0.7966309-0.0654297,1.6738892,0.0252075,2.243103,0.2736206 c1.3632202,0.5950317,2.8020935-4.5587234,3.2132263-6.5313797 C73.9010315,71.2208633,74.2446289,70.2248383,74.1737671,69.66185z"
        />
        <path
          opacity="0.15"
          fill="#FFF"
          d="M68.9603119,69.7751465 c1.4718399-2.1751709,4.0743408-0.841835,4.1271667-0.7167511 C71.5813751,69.5429916,67.5906448,71.5190048,68.9603119,69.7751465z"
        />
        <path
          opacity="0.1"
          fill={colors[2]}
          d="M63.4044151,72.3674698 c0.4111328,1.972702,1.849575,3.0895538,3.2127953,2.4945068c0.9010925-0.3932953,2.5760269-0.3932953,3.4771271,0 c1.3632202,0.595047,2.7316513-0.8738098,3.1428146-2.8465118c0.4626694-0.6235962,0.9962463-1.5793839,0.9253387-2.1423645 c-0.0087357,0.0712357-1.0557327,2.3935623-2.3435287,2.9369507c-1.3080215,0.5519257-2.9016418,0.0480957-3.3694458-1.4383011 c-0.0033875-0.184906-0.084404-0.1680908-0.0877914,0.0168076c-2.6167526,4.848732-5.6831894-1.0228882-5.6855354-1.0226135 C62.5826454,70.3769531,62.857235,72.0657578,63.4044151,72.3674698z"
        />
      </g>
    </g>
  )
}

export default MediumSleve
