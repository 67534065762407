import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Head from "./Head"
import SquareJaw from "./SquareJaw"
import HeadPointedChin from "./HeadPointedChin"

const CATEGORY = "head"
const CHARACTER = "ManMixed"
export const IDS = {
  HEAD: "Head",
  SQUARE_JAW: "SquareJaw",
  HEAD_POINTED_CHIN: "HeadPointedChin"
}

export const components = {
  [IDS.HEAD]: Head,
  [IDS.SQUARE_JAW]: SquareJaw,
  [IDS.HEAD_POINTED_CHIN]: HeadPointedChin
}

export const Group = ({ props }) => (
  <g id="head">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.HEAD,
    name: "Head",
    defaultColors: ["#262261", "#CD977A"],
    colorable: false,
    component: components[IDS.HEAD],
    inUI: true,
    enabled: true,
    subGroupId: 0
  },
  {
    id: IDS.SQUARE_JAW,
    name: "Square Jaw",
    defaultColors: ["#CD977A"],
    colorable: false,
    component: components[IDS.SQUARE_JAW],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.HEAD_POINTED_CHIN,
    name: "Head Pointed Chin",
    defaultColors: ["#CD977A"],
    colorable: false,
    component: components[IDS.HEAD_POINTED_CHIN],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
