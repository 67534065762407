import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GoldAxeLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GoldAxeLeft") ? (
    <g id="GoldAxeLeft">
      <path
        id="S_x24_goldAxeLeft"
        display="none"
        d="M51,113.8l-4.1-3c-0.5-0.7-0.9-1.6-1.2-2.5l1-6.6c1.2-1.6,5.1-7.1,7.6-11.3 c1.7-2.9,6.4-12.2,11.2-21.6L62.9,68c-2.7-4.9-2.8-10.6-0.2-15.1l0,0c2.5-4.5,7.4-7.4,12.9-7.7l3.1,2.7l5.3,0.5l0.4,0.2v0.1h0.3 l0.6,0.4l2.4,1.4l3.1,4.2l4.1,1.2c2.7,4.9,2.8,10.6,0.2,15.1l0,0l0,0c-2.5,4.5-7.3,7.4-12.9,7.7l-1.9-1.6l-17.7,34l-8.1,3.6 L51,113.8z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GoldAxeLeft, {
  character: "ManDwarf"
})
