import React from "react"

const LongSwordLeft = ({
  colors = [
    "#D0A92B",
    "#A8ABAD",
    "#D5D8DA",
    "#B49330",
    "#FFD144",
    "#EDD692",
    "#7B6528",
    "#A38634",
    "#7951A0"
  ]
}) => {
  return (
    <g id="LongSwordLeft">
      <rect
        x="81.745"
        y="82.527"
        transform="rotate(-80.643 84.262 83.18)"
        fill={colors[0]}
        width="5.034"
        height="1.308"
      />
      <path
        fill={colors[1]}
        d="M83.8644104,79.8217773l2.0137711,0.3422165l4.5596237-27.6802406 c0,0,0.8135376-5.2368851-0.0512695-5.9442902c-0.9702072,0.4125443-1.9625092,5.6020699-1.9625092,5.6020699 L83.8644104,79.8217773z"
      />
      <path
        fill={colors[2]}
        d="M90.3300018,46.5723152c0.8976517,0.7639351,0.0512695,5.9442902,0.0512695,5.9442902 l-4.5030899,27.6473885l-1.0068817-0.1711121L90.3300018,46.5723152z"
      />
      <path
        fill={colors[0]}
        d="M87.5833054,79.3243942c0,0-0.2391281-0.5416336-2.5737457-0.394928 c-2.106987-0.8930283-2.6394577-0.507988-2.6394577-0.507988s-1.7211456,1.9832382,2.2735519,2.611145 C88.7277374,81.6842194,87.5833054,79.3243942,87.5833054,79.3243942z"
      />
      <path
        fill={colors[3]}
        d="M82.7238083,77.9890747c0,0-0.8769989,0.963356,1.1222687,1.5408707 c0.2681503,0.0710449,0.4400482,1.4079514,0.8541107,1.4698257s1.0137482-1.1184311,1.3055725-1.1367722 c2.084816,0.0740738,1.3055725-1.1367722,1.3055725-1.1367722s1.8228149,1.9656143,0.0336456,2.400322 c-0.1314011-0.2261276-0.4087143-0.4430847-0.7662506-0.5378113c-0.5034409-0.085556-1.1031265,1.0947571-1.2543793,1.4851379 c-0.3537064,0.4324036-0.7731094,0.7517395-1.0412598,0.6806946c-0.4140625-0.0618744-0.6203308-0.5469742-0.8266068-1.0320816 c0.0053406-0.3812103-0.3124771-1.7089539-0.8159256-1.7945099c-0.3246765-0.0381927-0.5836563,0.0366821-0.8097763,0.1680832 C80.5992813,79.1480179,82.7238083,77.9890747,82.7238083,77.9890747z"
      />
      <path
        fill={colors[4]}
        d="M84.3961639,82.6888733c0.1787567,0.0473557,0.5416336-0.2391281,0.8953323-0.671524 c0.1512604-0.3903809,0.7746277-1.6600723,1.3674469-1.5508347c0.3246841,0.0381927,0.6585312,0.2222977,0.8227844,0.5049591 c0.315506-0.1077194,0.4522552-0.2628021,0.5561447-0.4744186c0.0947266-0.3575287-0.1115494-0.8426285-0.3980331-1.2055054 c-0.0236816,0.0893784-0.0473633,0.1787643-0.0710449,0.2681503c-0.1841049,0.333847-0.7020645,0.4835892-1.521019,0.5057526 c-0.0565262,0.0328522-0.3063354,0.2536392-0.4102325,0.4652557c-0.2971649,0.3995514-0.5706635,0.7097168-0.89534,0.671524 s-0.4415665-0.4996185-0.6149902-0.9281921c-0.0420151-0.202446-0.1497421-0.517952-0.2391205-0.5416336 c-0.8372955-0.2696609-1.3262253-0.5905151-1.4102631-0.9954071c-0.0328522-0.0565338-0.0420227-0.202446-0.0183411-0.291832 c-0.3063431,0.2536392-0.692894,0.629509-0.6982346,1.0107193c-0.0145111,0.2352982,0.0840378,0.4048843,0.2719727,0.5981674 c0.2261276-0.1314011,0.4851074-0.2062759,0.8097839-0.1680832c0.5928268,0.1092377,0.9434967,1.4935074,0.9710083,1.9312515 l0.0328522,0.0565338C83.9309082,82.2786407,84.0714798,82.6506805,84.3961639,82.6888733z"
      />
      <path
        fill={colors[3]}
        d="M83.2938309,86.1274185l0.9175034,0.1474304c0.2681503,0.0710449,0.5744858-0.1825943,0.5889969-0.4178848 l0.0382004-0.3246841c0.0710373-0.2681503-0.1825943-0.5744858-0.4178925-0.5889969l-0.9175034-0.1474304 c-0.2681503-0.0710449-0.5744858,0.1825943-0.5889969,0.4178848l-0.0381927,0.3246841 C82.8048935,85.8065643,82.9691467,86.0892258,83.2938309,86.1274185z"
      />
      <path
        fill={colors[5]}
        d="M84.960762,85.6124954c0.0710449-0.2681503-0.1825943-0.5744858-0.4178925-0.5889969l-0.9503555-0.2039642 c-0.2681503-0.0710449-0.5744858,0.1825943-0.5889969,0.4178848 C83.5214691,85.0876846,84.4955063,85.2022629,84.960762,85.6124954z"
      />
      <ellipse
        transform="rotate(-80.643 84.848 79.531)"
        fill={colors[6]}
        cx="84.848"
        cy="79.531"
        rx="0.654"
        ry="0.915"
      />
      <ellipse
        transform="rotate(-80.643 84.848 79.531)"
        fill={colors[7]}
        cx="84.848"
        cy="79.531"
        rx="0.785"
        ry="0.523"
      />
      <ellipse
        transform="rotate(-80.643 84.848 79.531)"
        fill={colors[8]}
        cx="84.848"
        cy="79.531"
        rx="0.654"
        ry="0.392"
      />
    </g>
  )
}

export default LongSwordLeft
