import React from "react"

const MaceR = ({
  colors = ["#58595A", "#B9BABA", "#767575", "#9C9D9E", "#E1E2E3", "#E4E4E5"]
}) => {
  return (
    <g id="MaceR">
      <path
        opacity="0.53"
        d="M29,91.1l-0.8-1.5l-0.1-0.3L27.9,88v-0.2l-9.2-23.5c-0.3,0.1-0.6,0.1-0.6,0.1 l-0.5-0.3l-0.5-1.3c-0.2-0.2-1-1.1-3.1-1.3l-0.2-0.6l-0.2-0.1l0,0h-0.1l0.1-0.1l0,0v-0.1c0.1-0.1,0.1-0.4-0.1-1 c-0.2-0.5-0.5-1-0.5-1l0,0l-0.1-0.1v-0.4l1.8-4.2l0.4-0.3c0,0,0.1,0,0.1-0.1L15.1,52l0.9-0.4l0.7,1.2l0,0l0.6-0.1l4.2,1.8l0.3,0.4 l0,0l0,0l0,0l0,0c0,0,0.1,0.6,0.3,1.1c0.2,0.6,0.5,0.8,0.5,0.8l0,0v0.1V57h0.2l-0.1,0.2l0.1,0.1l0.3,0.5L23,57.7l0.1,0.2 c-1.4,1.5-1.4,2.7-1.4,3l0.5,1.3L22,62.8l0,0c0,0-0.2,0.1-0.5,0.3l9.2,23.5l0.9,1.2l0.1,0.2l0,0v0.1l0.4,1.7l-0.2,0.5 c-0.1,0.1-0.8,0.5-1.2,0.7s-1.3,0.3-1.4,0.3L29,91.1z"
      />
      <path
        fill={colors[0]}
        d="M21.3,55.2l-4.2-1.8c-0.3,0.2-0.5,0.1-0.7,0L15.6,52l0.3,1.5c-0.1,0.3-0.2,0.4-0.6,0.5l-1.8,4.2 c0,0,1.3,2.1,0.6,2.8c2.6,0.2,3.5,1.5,3.5,1.5l0.5,1.4l0.9-0.1l9.4,24l0.1,1.6l0.8,1.5l1.3-0.3l1.1-0.6l-0.4-1.7l-1-1.2l-9.4-24 l0.8-0.6l-0.5-1.4c0,0-0.2-1.5,1.5-3.5C21.8,57.6,21.3,55.2,21.3,55.2z"
      />
      <path
        fill={colors[1]}
        d="M21.3,55.3l-4.2-1.8c-0.3,0.2-0.5,0.1-0.7,0l-0.8-1.3l0.3,1.5c-0.1,0.3-0.2,0.4-0.6,0.5l-1.8,4.1 c0,0,1.3,2.1,0.6,2.8c2.6,0.2,3.5,1.5,3.5,1.5l0.5,1.4l0.9-0.1l9.4,24l0.1,1.6l0.8,1.5c0,0,0.9-0.1,1.3-0.3 c0.4-0.2,1.1-0.6,1.1-0.6l-0.4-1.7l-1-1.2l-9.4-24l0.8-0.6l-0.5-1.4c0,0-0.2-1.5,1.5-3.5C21.8,57.7,21.3,55.3,21.3,55.3z M18.2,62.1l-2.8-1.9L14.5,58l1-3.4l0.3,2.8l0.8,2.2l1.9,2.4L18.2,62.1z M20.4,61.3L20,61.5l-0.2-3l-0.9-2.2L17.2,54l3,1.8 l0.9,2.2L20.4,61.3z"
      />
      <path
        fill={colors[2]}
        d="M21.8,62.6c0,0-0.7,0.6-1.7,1s-1.9,0.5-1.9,0.5l-0.5-1.3c0,0,1.4-0.4,1.9-0.6c0.5-0.2,1.8-0.8,1.8-0.8 L21.8,62.6z"
      />
      <path
        fill={colors[2]}
        d="M31.3,88.3c0,0,0,0.3-1.2,0.8s-1.5,0.3-1.5,0.3l-0.2-1.6c0,0,0.5,0.1,1-0.1s0.9-0.6,0.9-0.6L31.3,88.3z"
      />
      <g>
        <path
          fill={colors[3]}
          d="M21.1,58.1l-0.7,3.1L20,61.4l-0.2-2.8l-2.9,1.1l1.7,2.2l-0.4,0.2l-2.7-1.8l-1.3,0.5 c0,0.1-0.1,0.2-0.1,0.2c2.6,0.2,3.5,1.5,3.5,1.5l0.5,1.4l0,0l-0.5-1.3c0,0,1.4-0.4,1.9-0.6c0.5-0.2,1.8-0.8,1.8-0.8l0.5,1.3l0,0 l-0.5-1.4c0,0-0.2-1.5,1.5-3.5c-0.1,0-0.2,0-0.3-0.1L21.1,58.1z"
        />
        <path
          fill={colors[4]}
          d="M14.5,57.9L14.5,57.9l1-3.4l0.3,2.8v0.1l3-1.2l-0.1-0.1L17,53.9l3,1.8l0,0l1.1-0.4c0-0.1,0-0.1,0-0.1 l-4.2-1.8c-0.3,0.2-0.5,0.1-0.7,0L15.6,52l0.3,1.5c-0.1,0.3-0.2,0.4-0.6,0.5l-1.8,4.2c0,0,0,0,0.1,0.1L14.5,57.9z"
        />
        <polygon
          fill={colors[5]}
          points="20,63.7 29.2,87.6 29.7,87.5 20.4,63.7"
        />
      </g>
    </g>
  )
}

export default MaceR
