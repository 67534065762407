import React from "react"

const FlameLeft = ({
  colors = ["#F1D212", "#F4B81A", "#F49A35", "#F47B20", "#F1662F", "#EF4223"]
}) => {
  return (
    <g id="FlameLeft">
      <g opacity="0.98">
        <path
          fill={colors[0]}
          d="M65.6420975,88.0496902c-0.1180038,0.0045242-0.2393951-0.0162888-0.3513412-0.0538788 c-0.9151077-0.3072662-1.7792053-0.6173477-2.5469322-0.9756241c-0.1630898-0.2718201-0.3805389-0.5436325-0.5436325-0.8154526 c-0.434906-0.7067261-0.8154488-1.4134445-1.1416283-2.1745377c0.0543594-0.217453,0.1630898-0.434906,0.217453-0.652359 c0.1087265-0.2718124,0.2718163-0.5436325,0.3805428-0.8154449c0.2174568-0.3805466,0.434906-0.8154526,0.5979996-1.2503586 l0.5436325-0.1087265l-0.2718201-0.3805466l-0.0543633-0.0543594c1.1416321-0.7067261,1.8483582-1.4678116,2.0658112-2.2832565 c0.0543594-0.2718201,0.1087265-0.5979996,0.0543594-0.8698196l0.3261795-0.0543594 c0.1087265,0.217453,0.2174606,0.3805389,0.2718201,0.5436325c0.3261795,0.652359,0.8154526,1.2503586,1.2503586,1.8483582 c0.5436325,0.7067261,1.0329056,1.4134445,1.3590851,2.1201706c0.7735214,1.7017517,0.6665421,3.3545837-0.3673401,5.2833176 c-0.1084366,0.2022934-0.29702,0.3700104-0.5137634,0.445549C66.4719086,87.9576492,66.0668564,88.0334091,65.6420975,88.0496902 z"
        />
        <path
          fill={colors[1]}
          d="M65.5917282,88.101059c-0.1200943,0.0082779-0.2431335-0.0132446-0.3573151-0.0513687 c-0.7571869-0.2528229-1.5077324-0.5089645-2.1644096-0.8120499c-0.1630898-0.217453-0.3261795-0.434906-0.434906-0.7067184 c-0.4349098-0.652359-0.7610893-1.3047256-1.0872688-2.0658112c0.0543633-0.217453,0.1087265-0.3805466,0.217453-0.5436325 c0.1087303-0.2718201,0.2718201-0.5436401,0.3805428-0.7610931c0.1630936-0.3261795,0.3805428-0.7067261,0.5436363-1.087265 l0.8154488-0.1087265l-0.434906-0.5979919c0.9785423-0.652359,1.5765419-1.3590851,1.8483543-2.0658112 c0.2718201,0.434906,0.5979996,0.8154449,0.8698196,1.2503586c0.4892654,0.7067261,0.9785385,1.3590851,1.304718,2.0658112 c0.7647629,1.5805054,0.6688538,3.113205-0.2877274,4.9119873c-0.1259918,0.2369156-0.3571854,0.4107819-0.6178894,0.4742966 C65.9957504,88.0496979,65.7994232,88.0867386,65.5917282,88.101059z"
        />
        <path
          fill={colors[2]}
          d="M65.3532639,88.1618195c-0.5615768-0.1871948-1.1231613-0.3743896-1.6500587-0.5962677 c-0.1459084-0.1178818-0.2829857-0.2286377-0.428894-0.3465195c-0.471096-0.6780624-0.8541565-1.4441833-1.2372093-2.210289 c0.0543594-0.1630936,0.1087227-0.2718201,0.1630898-0.434906c0.1087265-0.2718201,0.2718163-0.5436401,0.3805428-0.7610931 c0.1630898-0.2718124,0.3261795-0.5979919,0.4349098-0.9241714l1.1416283-0.1630936l-0.5436325-0.7610855 c0.5436325-0.3805466,0.9785385-0.7610931,1.304718-1.1959991c0.1087265,0.1087265,0.1630936,0.2174606,0.2718201,0.3805466 c0.4892731,0.7067261,0.9785385,1.3590851,1.304718,2.0114441c0.7610931,1.522171,0.5979996,3.0987091-0.434906,4.9470673 C65.8425369,88.053093,65.5707169,88.10746,65.3532639,88.1618195z"
        />
        <path
          fill={colors[3]}
          d="M65.2989044,88.1618195c-0.4892731-0.1630936-0.9785461-0.3261795-1.4678116-0.5436325 c-0.1087265-0.1630936-0.1630898-0.2718201-0.2718163-0.434906c-0.3805428-0.5979996-0.652359-1.1416321-0.9785423-1.7939987 c0.0543633-0.1087265,0.0543633-0.217453,0.1087265-0.3261795c0.1087227-0.2718124,0.2718163-0.4892654,0.3805389-0.7610855 c0.1087265-0.2718201,0.2718163-0.4892731,0.3805428-0.7610855l1.4134483-0.2174606l-0.652359-0.869812 c0.217453-0.1630859,0.434906-0.3261795,0.5979919-0.4892654c0.3805466,0.5436325,0.7610931,1.087265,1.0329056,1.6308975 c0.7067261,1.4134445,0.5436401,2.8812637-0.3261795,4.5665207C65.461998,88.10746,65.3532639,88.1618195,65.2989044,88.1618195z"
        />
        <path
          fill={colors[4]}
          d="M65.0814514,87.9987259c-0.2718201-0.1087265-0.5979996-0.217453-0.8698196-0.3261795 c-0.0543594-0.1087265-0.1087189-0.217453-0.2174492-0.3261795c-0.3261795-0.5436325-0.652359-1.087265-0.9241791-1.6308975 c0-0.0543671,0.0543633-0.1087265,0.1087265-0.217453c0.1107864-0.2769775,0.2780228-0.4975052,0.3866653-0.7766266 c0.3311806-0.2602158,0.5986977-0.4704132,0.929882-0.730629l0.0479202-0.00737 c0.4704514-0.0723877,0.9355392,0.2155991,1.0572586,0.675766C65.8814087,85.7213745,65.689888,86.8253098,65.0814514,87.9987259 z"
        />
        <path
          fill={colors[5]}
          d="M65.1901779,88.1618195c-0.0543594-0.0543594-0.0543594-0.1087265-0.1087265-0.1630936 c-0.3261795-0.4892654-0.5979919-0.9785385-0.8154526-1.522171c0-0.0543671,0-0.0543671,0.0543671-0.1087265 c0.1087265-0.2718201,0.2718124-0.4892731,0.3805389-0.7610931c0.0543671-0.1630859,0.1630936-0.3261795,0.217453-0.4892654 l0.5436401-0.1087265C65.7881775,86.0416489,65.7338104,87.0745544,65.1901779,88.1618195 C65.2445374,88.2161789,65.1901779,88.1618195,65.1901779,88.1618195z"
        />
      </g>
      <path
        fill={colors[0]}
        d="M69.7646103,78.4746857c-0.0051575-0.1710968-0.0631027-0.3370819-0.154335-0.4819183 c-0.6339188-1.0063858-0.7971573-1.962326-1.8106537-2.8237915c-0.0543594,0-0.5436325-0.3805466-0.5979996-0.3805466 c-0.1087189,0.0543594-0.7610855,0.1087265-0.7610855-0.1087265c0.1087265-0.5436325,0.9785461-0.7067261,0.7067261-1.1959991 c-0.105957-0.1854172-0.3926086-0.8354874-0.690155-1.5036926c-0.3080826-0.691864-1.261734-0.753746-1.6606979-0.1099854 c-0.5554199,0.8962173-1.2194557,1.8678284-1.6720352,2.3204041c-0.0543594,0.0543594-1.1416283,2.8268967-1.522171,4.0772552 c0.217453-0.0543671,0.5979958-0.1630936,0.5436325-0.0543671c-0.5979958,1.6852646-2.2288971,3.0443497-2.8268929,4.7296143 c0,0.0543594-0.1087265,0.1087265-0.2174568,0.1630936c0.2174568,1.0329056,1.1416321,1.9027176,2.1745377,2.6638031 c-0.5436363-0.869812-0.9785423-1.7939911-0.9785423-2.7725296c0-0.1087265,0.3261795-0.2718201,0.6523628-0.3805389 c0.1087265-1.0872726,0.2718163-2.0658112,1.2503548-3.0443497c1.2503586-1.1959915,1.8483543-3.0987167,1.8483543-3.0987167 c0.0543671-0.217453,1.4678116-2.4463501,1.1416321-3.4248886c-0.1087265-0.2718201,0.3261795,0.4892731,0.2718201,0.7610855 c0.0543594,0,0.1087189,0,0.1087189,0.0543671c0,0.5934525,0.3305359,2.2776718,0.501915,3.0165634 c0.0510406,0.2200699,0.1790543,0.4129105,0.3598633,0.5483551c0.9439087,0.7070999,1.029686,0.9247208,1.5302124,1.9257812 c0.652359,1.3590851,1.0329056,2.2288971,1.0872726,3.6423416c0,0.0543594-0.0543671,0.1087265-0.1087265,0.1630936 c0.434906,0.8154526-0.217453,1.6852646-0.4349136,2.5007095c-0.0543594,0.1087265-0.0543594,0.2174606-0.1630859,0.3805466 c0.4976501-0.6967239,0.9497528-1.4389954,1.3562927-2.1851273c0.0742035-0.1361771,0.1141129-0.2893372,0.1179199-0.4443741 C69.8585663,81.7369614,69.8137665,80.1047363,69.7646103,78.4746857z"
      />
    </g>
  )
}

export default FlameLeft
