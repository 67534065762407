import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FlameLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FlameLeft") ? (
    <g id="FlameLeft">
      <path
        id="S_x24_flameLeft"
        d="M68.9292831,52.2254524c0.1609268-0.5632477-14.3226814,16.3342934-14.1617508,26.6337471 c0.1609268,10.2994537,6.0348358,16.4147568,12.9547806,16.4147568 c15.4491806-0.4023285,17.7499619-20.2616425,13.1157074-25.6681747 C75.9139404,63.8611221,68.9292831,52.2254524,68.9292831,52.2254524z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FlameLeft, {
  character: "ManHalfling"
})
