import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import BlackBoots from "./BlackBoots"
import BrownBoots from "./BrownBoots"
import BrownShoes from "./BrownShoes"
import StrapBoots from "./StrapBoots"

const CATEGORY = "shoes"
const CHARACTER = "ManDwarf"
export const IDS = {
  BLACK_BOOTS: "BlackBoots",
  BROWN_BOOTS: "BrownBoots",
  BROWN_SHOES: "BrownShoes",
  STRAP_BOOTS: "StrapBoots"
}

export const components = {
  [IDS.BLACK_BOOTS]: BlackBoots,
  [IDS.BROWN_BOOTS]: BrownBoots,
  [IDS.BROWN_SHOES]: BrownShoes,
  [IDS.STRAP_BOOTS]: StrapBoots
}

export const Group = ({ props }) => (
  <g id="shoes">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.BLACK_BOOTS,
    name: "Black Boots",
    defaultColors: [
      "#616161",
      "#454443",
      "#919091",
      "#352515",
      "#FFFFFF",
      "#4D4A46",
      "#55514D",
      "#6C6662",
      "#857D77",
      "#SVGID_",
      "#696869",
      "#454545",
      "#525252",
      "#1F1F1F"
    ],
    colorable: false,
    component: components[IDS.BLACK_BOOTS],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BROWN_BOOTS,
    name: "Brown Boots",
    defaultColors: [
      "#614723",
      "#453627",
      "#926259",
      "#352515",
      "#EED793",
      "#4D3A21",
      "#564027",
      "#6D5036",
      "#856146",
      "#SVGID_",
      "#69533A",
      "#FFD245",
      "#524028",
      "#996A38",
      "#FED712"
    ],
    colorable: false,
    component: components[IDS.BROWN_BOOTS],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.BROWN_SHOES,
    name: "Brown Shoes",
    defaultColors: [
      "#774F1F",
      "#6B441B",
      "#A16628",
      "#372513",
      "#948F8B",
      "#EED793",
      "#70431B",
      "#794B22",
      "#925F3A",
      "#9E6A47",
      "#SVGID_",
      "#594B1F"
    ],
    colorable: false,
    component: components[IDS.BROWN_SHOES],
    inUI: true,
    enabled: false,
    subGroupId: 0
  },
  {
    id: IDS.STRAP_BOOTS,
    name: "Strap Boots",
    defaultColors: [
      "#A87F54",
      "#754C29",
      "#9C5D26",
      "#262262",
      "#3A3019",
      "#5C3C20",
      "#644223"
    ],
    colorable: false,
    component: components[IDS.STRAP_BOOTS],
    inUI: true,
    enabled: true,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
