import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const ClubR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("ClubR") ? (
    <g id="ClubR">
      <path d="M46.1,78.2l6-8c-0.1-0.7-0.2-1.5-0.3-2.2c-0.1-0.5-0.1-0.9-0.2-1.3c-0.1-1-0.3-2.3-0.3-2.9c0.1-0.6,0.2-0.9,0.2-0.9l0,0.1 L50,55.8c0.9,1,1.5,2.3,1.6,3.8c-0.1-0.7-0.1-1.4-0.1-2c0-0.7-0.1-1.4-0.1-2.1c0-0.6,0-1.3,0-1.9c0-0.9,0-1.8,0-2.7l0-0.6 c0-2,0-4.2-0.1-6.4c0-0.1,0-0.2,0-0.3l0,0l0-0.2l0,0l0-0.3c-0.1-1-0.1-2-0.2-3c-0.1-0.6-0.1-1.1-0.2-1.7c-0.1-0.6-0.2-1.2-0.3-1.8 l0,0c-0.1-0.3-0.1-0.7-0.2-1l0,0c0-0.3-0.1-0.6-0.1-0.9l0-0.3c0-0.1,0-0.3,0-0.4l0-0.5c0.1-0.4,0.1-0.9,0.1-1.3 c0-0.5,0.1-1,0.1-1.6c0-0.6,0.1-1.3,0.2-1.9c0-0.3,0.1-0.6,0.1-0.9c0-0.2,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3 c0-0.1,0.1-0.2,0.1-0.4c0,0.1-0.1,0.2-0.1,0.4l0.5-2.9l-0.1-2.6l-0.3-1.8L51,19.1c-0.1-0.4-0.2-0.8-0.3-1.1l0-0.2 c-0.1-0.3-0.2-0.6-0.2-0.9l-0.2-1.1c-0.1-0.3-0.1-0.6-0.2-1l0-0.1c-0.1-0.4-0.1-0.7-0.1-1c-0.1-0.5-0.1-0.9-0.2-1.4 c0-0.2-0.1-0.4-0.1-0.6l-1.3-3.1c-2.3-3.1-5.7-4.8-9.6-4.8c-0.2,0-0.4,0-0.6,0c-0.5,0-1.1,0.1-1.6,0.2c-0.3,0-0.6,0.1-0.9,0.2 l-0.1,0c-1.6,0.4-3.1,1-4.3,1.9c-0.2,0.1-0.4,0.3-0.6,0.4l-2.5,3.9l-0.1,0.3c-0.2,0.6-0.4,1.2-0.6,1.8c-0.3,0.9-0.6,1.8-0.9,2.9 l-0.1,0.3c-0.1,0.3-0.1,0.6-0.2,0.9L26,16.8c-0.1,0.5-0.2,1-0.2,1.5c0,0.3,0,0.5-0.1,0.7l0,0l0,1.2c0.2,1.8,0.6,3.3,0.9,4.1l1.4,7 l0.7,1.9c0.2,0.4,0.4,1.6,0.5,2.4l0,0.3c0.3,1.6,0.7,3,1,4.2c0.3,1,0.6,1.9,0.7,2.6l0,0.2c0,0.2,0,0.4,0,0.7l0,0.1l0,0.2 c0,0.9,0,2.1,0.2,3.4c0.2,1.1,0.6,2.1,1,2.8l-0.4,0.4l0.3,1.6l0.5,0.6L32.6,53c0.3,1.5,0.6,2.9,1,4.2c0.4,1.5,0.7,2.9,0.9,4.2 c0,0.1,0.1,0.6,0.1,0.6c0.1,0.4,0.1,0.8,0.1,1.2c0.1,0.6,0.1,1.2,0.2,1.7l0.1,1.3l0.1,0.7l1.2,5.8l8,5.6L46.1,78.2z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(ClubR, { character: "ManMixed" })
