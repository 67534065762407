import React from "react"

const PlateHelm = ({ colors = ["#BCBEC0", "#999999", "#DEDEDE"] }) => {
  return (
    <g id="PlateHelm">
      <path
        opacity="0.42"
        fill="#21376C"
        d="M57.7447166,44.5c0,0,0-0.0999985-0.1049957-0.2000008 v-0.2000008h-0.1049957C57.0097427,42.6999969,55.014801,38,50.3949432,36.7999992l0,0l-1.8899422-1.8999977l-2.0999374,1.8999977 c-4.7248573,1.0999985-6.719799,5.9000015-7.139782,7.2999992h-0.1049957v0.2000008 c0,0.0999985-0.1049957,0.2000008-0.1049957,0.2000008h0.1049957v0.0999985v2l7.6647682,0.5l0.2099915,4.0999985h2.6249237 l0.2099953-4.0999985l7.6647682-0.5v-2L57.7447166,44.5L57.7447166,44.5z"
      />
      <path
        fill={colors[0]}
        stroke="#999"
        strokeWidth="0.175"
        strokeMiterlimit="10"
        d="M57.7446899,43.5999908 c0,0-0.0341797-0.1210938-0.1049805-0.328125v-0.0718384h-0.0257568c-0.5568848-1.5935669-3.0358887-7.5-9.2139282-7.5 c-6.1781006,0-8.5786133,5.9064331-9.1143188,7.5h-0.0204468v0.0582275 c-0.0717773,0.2171631-0.1049805,0.3417358-0.1049805,0.3417358h0.1049805v2.6000366h18.3744507v-2.6000366H57.7446899z"
      />
      <rect
        x="39.265"
        y="43.7"
        opacity="0.42"
        fill="#21376C"
        width="18.374"
        height="2"
      />
      <polygon
        fill={colors[0]}
        points="46.4050598,35.9000015 47.1400375,50.9000015 49.7649612,50.9000015 50.499939,35.9000015 48.5049973,34"
      />
      <polygon
        opacity="0.42"
        fill="#21376C"
        points="48.295002,34.2000008 46.7200508,35.7000008 47.4550285,50.5 48.4000015,50.5"
      />
      <polygon
        fill={colors[2]}
        points="48.5049973,34.2000008 50.0799484,35.7000008 49.4499702,50.5 48.4000015,50.5"
      />
    </g>
  )
}

export default PlateHelm
