import React from "react"

const Leggings = ({ colors = ["#6B6B6B"] }) => {
  return (
    <g id="Leggings">
      <path
        id="L2_x24_D_x24_C_x24_legging"
        fill={colors[0]}
        d="M89.1,111c-0.6-4.1-0.4-8.8-1.2-14.5c-1.2-4.5-2.9-6.6-3-7.7 c0-0.5-0.8-2.6-0.7-3.9c0.1-1.3-0.3-7-0.4-8.9c-0.2-2.4-0.3-6.4-0.9-9.4c-0.8-4-3.5-10.2-3.6-10.8L59,55.7 c-0.1,0.6-2.7,6.9-3.5,10.9c-0.6,2.9-0.7,7-0.8,9.5c-0.1,1.8-0.5,7.5-0.4,8.8s-0.7,3.4-0.7,3.9c-0.1,1.1-2,3-3.1,7.7 c-0.8,5.7-0.6,11.5-1.5,15.5c0,0,5.1,0.2,5.1,0c0.5-2.4,2.4-9.1,3.5-11.5s1.2-7.9,2.5-9.5s0.8-3.2,1.5-5c0.7-1.9,1.6-3.8,2-5.9 c0.4-1.8,1-3.2,4.3-12.3c1.5,0.5,2.4-0.1,2.4-0.1c3.3,9,3.8,10.5,4.2,12.3c0.5,2,1.4,4,2,5.9c0.7,1.9,0.2,3.5,1.5,5.1 s1.6,7,2.6,9.4c1.1,2.4,2.7,8.4,3.3,10.8c0,0.2,0.3,0.6,0.3,0.7c1,0,1.7,0.3,2.9,0S89.1,111,89.1,111z"
      />
    </g>
  )
}

export default Leggings
