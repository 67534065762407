import React from 'react'
import { connect } from 'react-redux'
import {
  setSkinColor,
  clearColorActiveItem,
  setColor,
  currentItemCurrentColorSelector
} from '../reducers/builderReducer'

import { skinColorSelector, setQuery } from '../selectors/builderSelectors'

import {
  SketchPicker,
  CirclePicker
} from 'react-color'

import { push } from 'connected-react-router'

const SkinColorPicker = ({ skinColor, setSkinColor, search, push }) =>
  <div>
    <CirclePicker
      width="1000"
      color={skinColor}
      colors={[
        '#FFD225',
        '#FFDFBD',
        '#E9C197',
        '#C88E62',
        '#A86637',
        '#60463A',
        '#8d5524',
        '#c68642',
        '#e0ac69',
        '#f1c27d',
        '#ffdbac',
        '#C25246',
        '#8E84B2',
        '#B2B095',
        '#81B2A4',
        '#5C83B2',
        '#AF71B2',
        '#775931',
        '#693720',
        '#c19aa4',
        '#eae3da',
        '#6e5128',
        '#9b9897',
        '#cec4c4',
        '#edc3cd',
        '#e1c4b7',
        '#56433c',
        '#ffb888',
        '#a5997a',
        '#bca580',
        '#532615',
        '#d3bfb6',
        '#5e2d00',
        '#c4b99e',
        '#9e8d82',
        '#8a614f',
        '#DAA520',
        '#FFFFF0',
        '#1E90FF',
        '#9ACD32',
        '#C0C0C0',
      ]}
      onChange={(a) => {
        const query = setQuery(search, { skinColor: a.hex })
        push(`/builder?${query}`)
      }}
    />
  </div>

const mapStateToProps = state => ({
  skinColor: skinColorSelector(state),
  search: state.router.location.search,
})

export const SkinColor = connect(mapStateToProps, { setSkinColor, push })(SkinColorPicker)


const ColorPicker = ({
  pickerOpen = true,
  handleColorChange,
  color
}) => (
  pickerOpen
    ? <div style={{display: 'flex', justifyContent: 'center'}}><SketchPicker color={color} onChangeComplete={ handleColorChange } /></div>
    : null
)

const ItemColorPicker = ({
  item,
  clearColorActiveItem,
  setColor,
  color
}) => {
  if (!item) return null

  return (
    <div>
      <div
        className="modal"
        aria-labelledby="color picker"
        aria-describedby="select item colors"
        style={{ display: "flex" }}
      >
        <div
          className="modal-background"
          style={{backgroundColor: "transparent"}}
          onClick={clearColorActiveItem}
        ></div>
        <div className="modal-content" style={{display: 'flex', justifyContent: 'flex-end', padding: 5 }}>
          <ColorPicker
            pickerOpen={!!item}
            handleColorChange={(color) => setColor(item, color, 0)}
            color={color}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps2 = state => ({
  item: state.builderReducer.colorActiveItem,
  color: currentItemCurrentColorSelector(state)
})

const mapDispatchToProps = {
  clearColorActiveItem,
  setColor,
  push
}

export default connect(mapStateToProps2, mapDispatchToProps)(ItemColorPicker)