import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BattleAxeRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BattleAxeRight") ? (
    <g id="BattleAxeRight">
      <path
        id="S_x24_battleAxeRight"
        d="M19.1457195,119.4000015l1-62.0999985l-3.6000004,0.4000015 C9.9457197,52.3000031,7.245719,44.8000031,8.9457197,36.6000061c1.0999994-5.6000004,3.999999-9.7000008,4.3000002-10.1000004 l9.1999989-0.5l1-2.6000004l5.2000008-3.2000008l5.3999996,3.5l0.9000015,2.5l9.2000008,0.7000008 c0.2999992,0.5,3,4.6000004,4,10.2999992c1.4000015,8.2999992-1.5,15.7000008-8.2000008,20.9000015l-3.5999985-0.5l-1,61.9000015 L19.1457195,119.4000015z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BattleAxeRight, {
  character: "ManHalfling"
})
