import React from "react"
import { withSkinColor } from "../../builderConnector"

const ElfEars = ({ skinColor, color = [] }) => {
  return (
    <g id="ElfEars">
      <path
        fill={skinColor}
        d="M75.1,15.9c0,0,1.6-1.9,2.2-3.1c0.2-0.1,0.4,0.5,0.4,0.6s-0.6,2.5-0.6,2.5l-0.9,3.4c0,0-0.6,0.7-0.8,0.6 s-0.3-0.8-0.3-0.8s0.7-0.4,0.8-1.3C76,17.1,75.1,15.9,75.1,15.9z"
      />
      <path
        opacity="0.28"
        fill="url(#SVGID_4_)"
        d="M75.3,15.8c0,0,1.4-1.8,2-3c0.2-0.1,0.4,0.5,0.4,0.6 c-0.1,0.2-0.6,2.5-0.6,2.5L76,19.3c0,0-0.4,0.8-0.7,0.6c-0.2-0.1-0.3-0.8-0.3-0.8s0.7-0.4,0.8-1.3C76,17.1,75.3,15.8,75.3,15.8z"
      />
      <path
        opacity="0.3"
        fill="url(#SVGID_5_)"
        d="M75.6,16.6c0.6-1,1.3-1.9,1.5-2.8c0,0.9-1,4-1.5,5.1 c-0.2,0.4-0.3-0.1-0.3-0.1S75,17.6,75.6,16.6z"
      />
      <linearGradient
        id="SVGID_4_"
        gradientUnits="userSpaceOnUse"
        x1="191.43"
        y1="1202.187"
        x2="189.418"
        y2="1206.411"
        gradientTransform="rotate(5.309 12938.32 -624.477) scale(.99999)"
      >
        <stop offset="0.004" stopColor="#ec1c24" stopOpacity="0.7" />
        <stop offset="0.007" stopColor="#ec1c24" stopOpacity="0.696" />
        <stop offset="0.432" stopColor="#ec1c24" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1="63.14"
        y1="1038.322"
        x2="64.482"
        y2="1038.322"
        gradientTransform="matrix(1.1313 .1051 -.1074 1.1562 115.487 -1190.797)"
      >
        <stop offset="0" stopColor="#262261" />
        <stop offset="0.932" stopColor="#262261" stopOpacity="0" />
      </linearGradient>
      <g>
        <path
          fill={skinColor}
          d="M63.2,15.9c0,0-1.6-1.9-2.2-3.1c-0.2-0.1-0.4,0.5-0.4,0.6c0.1,0.2,0.6,2.5,0.6,2.5l0.9,3.4 c0,0,0.6,0.7,0.8,0.6s0.3-0.8,0.3-0.8s-0.7-0.4-0.8-1.3S63.2,15.9,63.2,15.9z"
        />
        <path
          opacity="0.28"
          fill="url(#SVGID_6_)"
          d="M63,15.7c0,0-1.4-1.8-2-3c-0.2-0.1-0.4,0.5-0.4,0.6 c0.1,0.2,0.6,2.5,0.6,2.5L62,19c0,0,0.7,0.9,1,0.7c0.2-0.1,0.3-0.8,0.3-0.8s-0.7-0.4-0.8-1.3C62.3,17,63,15.7,63,15.7z"
        />
        <path
          opacity="0.3"
          fill="url(#SVGID_7_)"
          d="M62.7,16.6c-0.6-1-1.3-1.9-1.5-2.8c0,0.9,1,4,1.5,5.1 c0.2,0.4,0.3-0.1,0.3-0.1S63.3,17.6,62.7,16.6z"
        />
        <linearGradient
          id="SVGID_6_"
          gradientUnits="userSpaceOnUse"
          x1="-1674.064"
          y1="1375.494"
          x2="-1676.076"
          y2="1379.718"
          gradientTransform="scale(-.99999 .99999) rotate(5.308 13806.102 18092.4)"
        >
          <stop offset="0.004" stopColor="#ec1c24" stopOpacity="0.7" />
          <stop offset="0.007" stopColor="#ec1c24" stopOpacity="0.696" />
          <stop offset="0.432" stopColor="#ec1c24" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="SVGID_7_"
          gradientUnits="userSpaceOnUse"
          x1="-1578.835"
          y1="1187.575"
          x2="-1577.494"
          y2="1187.575"
          gradientTransform="matrix(-1.1313 .1051 .1074 1.1562 -1850.732 -1190.797)"
        >
          <stop offset="0" stopColor="#262261" />
          <stop offset="0.932" stopColor="#262261" stopOpacity="0" />
        </linearGradient>
      </g>
    </g>
  )
}

export default withSkinColor(ElfEars, { character: "ManMixed" })
