import React from "react"

const Knickers = ({ colors = ["#B98957", "#754C29", "#262262"] }) => {
  return (
    <g id="Knickers">
      <path
        fill={colors[0]}
        d="M62.5,80.4C61.9,79,62.3,79,62.3,79s-0.1-1.7-1-1.2C61,78,61,78.3,60.6,78.5c-2.2,1.3-7.7,1.2-11.9,1.2 c-0.1,0-0.1,0.6-0.1,1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.4,0-1-0.1-1c-4.2,0-9.7,0.1-11.9-1.2 c-0.4-0.3-0.4-0.5-0.7-0.7c-0.8-0.5-1,1.2-1,1.2s0.4,0-0.2,1.4c-2.1,4.7-2,13.1-0.2,18.7c-0.5,1.5,3.7,4.2,6.5,4s4-1.7,4.5-3.6 c0.5-1.8-0.2-5.2,1.6-6.8c0.5,0,2.7,0,3.3,0c1.9,1.5,1.1,5,1.6,6.8s1.7,3.4,4.5,3.6c2.8,0.2,7-2.4,6.5-4 C64.5,93.4,64.5,85.1,62.5,80.4z"
      />
      <path
        fill={colors[1]}
        d="M57.1,80.6c2.4-0.5,3.5-1,3.5-1s0.3-0.2,0.4,0S60.9,80,60.9,80s-1.3,0.5-3.5,1.1 C57.2,81,57.1,80.8,57.1,80.6z"
      />
      <path fill={colors[1]} d="M45.3,81.3v0.8" />
      <path
        fill={colors[1]}
        d="M51.9,81.2c1.6-0.1,3-0.3,4.1-0.4c0,0.2,0,0.4,0,0.6c-1.2,0.2-2.6,0.5-4.2,0.6v-0.8H51.9z"
      />
      <path
        opacity="0.42"
        fill={colors[2]}
        d="M59.5,99.9c-3.9,4.3-6.3-1.3-6.3-1.3s1,6,5.5,3.1 S59.5,99.9,59.5,99.9z"
      />
      <path
        fill={colors[1]}
        d="M61.3,77.7c0.5,0.2,0.5,0.2,0.5,0.2s0,2.5-13.1,1.8C55.5,79.6,58.1,79.8,61.3,77.7z"
      />
      <path fill={colors[1]} d="M47.4,79.8" />
      <path
        fill={colors[1]}
        d="M48.3,81.7c-0.7,0.1-1.2,0.9-1.1,1.6c0.3,1.4,0.6,3,1.2,4.4c0.1,0.3,0.6,0.2,0.5-0.1 c-0.3-0.8-1.9-5.2-0.4-5.4C48.7,82.1,48.6,81.7,48.3,81.7L48.3,81.7z"
      />
      <path
        fill={colors[1]}
        d="M48.3,82.2c0.5-0.1,1.1-0.4,1.6-0.4c0.5-0.2,0.6-0.1,0,0.4c-0.3-0.1-0.6-0.2-0.9-0.4 c-0.7-0.3-1.3-0.6-2-0.6c-0.8,0-2.4,0.4-1.8,1.5c0,0.1,0.1,0.1,0.2,0.1c0.9-0.1,2.6-0.2,3.2-0.9c0.3-0.3,0.4-0.9-0.2-0.9 c-0.5,0-0.7,0.5-0.6,0.9c0.2,0.6,0.7,1.3,1,1.8c0.2,0.4,1.3,1.8,1.1,2.2c-0.1,0.3,0.3,0.5,0.4,0.3c0.2-0.5,0-0.8-0.3-1.3 c-0.5-0.8-0.9-1.9-1.5-2.6c-0.1-0.4-0.4-0.6-0.9-0.6c-0.3,0.1-0.5,0.2-0.8,0.3s-0.5,0.1-0.8,0.2c-0.2-0.5,0-0.6,0.6-0.5 c1.1,0,1.8,0.6,2.8,0.9c0.6,0.2,2.3,0,1.9-1.1c-0.3-1-2.8,0-3.4,0.2C47.8,81.8,48,82.2,48.3,82.2L48.3,82.2z"
      />
      <g>
        <path
          fill={colors[1]}
          d="M39.5,80.6c-2.4-0.5-3.5-1-3.5-1s-0.3-0.2-0.4,0s0.1,0.4,0.1,0.4s1.3,0.5,3.5,1.1 C39.4,80.9,39.5,80.7,39.5,80.6z"
        />
        <path fill={colors[1]} d="M51.3,81.2V82" />
        <path
          fill={colors[1]}
          d="M44.7,81.1c-1.6-0.1-3-0.3-4.1-0.4c0,0.2,0,0.4,0,0.6c1.2,0.2,2.6,0.5,4.2,0.6v-0.8H44.7z"
        />
        <path
          opacity="0.42"
          fill={colors[2]}
          d="M37.1,99.8c2.6,3.2,6.3-1.3,6.3-1.3s-1,6-5.5,3.1 S37.1,99.8,37.1,99.8z"
        />
        <path
          fill={colors[1]}
          d="M35.3,77.6c-0.5,0.2-0.5,0.2-0.5,0.2s0,2.5,13.1,1.8C41.1,79.5,38.5,79.7,35.3,77.6z"
        />
        <path fill={colors[1]} d="M49.2,79.7" />
      </g>
    </g>
  )
}

export default Knickers
