import React from "react"

const MaulL = ({ colors = ["#898888", "#FFFFFF"] }) => {
  return (
    <g id="MaulL">
      <path
        fill={colors[0]}
        d="M101.8000031,40.2999992c-0.0999985,0.0999985-4.5,38.2000008-4.5,38.2000008 s-0.1999969,1.0999985,1.0999985,1.5c1.3000031,0.4000015,2.1999969-0.5,2.0999985-0.9000015 c0,0,4.4000015-37.5,4.0999985-37.9000015C104.1999969,40.7999992,102,40.0999985,101.8000031,40.2999992z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M103.3000031,48 c0.3000031,0.0999985,0.5,0.0999985,0.6999969,0.0999985c0.4000015-4,0.6999969-6.7999992,0.5999985-6.9000015 c-0.3000031-0.4000015-2.5999985-1.0999985-2.8000031-0.9000015c0,0-0.4000015,2.7000008-0.8000031,6.5999985 C102,47.5,103,48,103.3000031,48z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M99.9000015,56.5 c0,0.2000008,0,0.2999992-0.0999985,0.5c1.1999969-0.5999985,2.3000031-1.2000008,3.4000015-1.9000015 c0-0.0999985,0-0.2000008,0-0.2999992l0,0C102.1999969,55.2999992,101,55.9000015,99.9000015,56.5z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M98.6999969,78.6999969 c0-1.5,0-3,0.0999985-4.4000015c-0.4000015,0.1999969-0.6999969,0.5-1.0999985,0.6999969 c-0.3000031,2.1999969-0.4000015,3.5999985-0.4000015,3.5999985s-0.0999985,0.4000015,0.1999969,0.8000031 C97.9000015,79.0999985,98.3000031,78.9000015,98.6999969,78.6999969z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M101.9000015,67.3000031 C102,66.6000061,102.0999985,65.8000031,102.2000046,65c-0.4000015,0.3000031-0.9000015,0.5999985-1.3000031,0.9000015 c-0.0999985,0.6999969-0.0999985,1.4000015-0.1999969,2.1999969C101.0999985,67.8000031,101.5,67.5,101.9000015,67.3000031z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M100.6999969,77.5999985 c0.0999985-1.0999985,0.3000031-2.9000015,0.5999985-5c-0.4000015,0.1999969-0.6999969,0.5-1.0999985,0.6999969 c-0.1999969,1.5999985-0.3000031,3.1999969-0.5,4.8000031C100,78,100.4000015,77.8000031,100.6999969,77.5999985z"
      />
      <path
        opacity="0.3"
        fill="#262261"
        d="M103.5,53.0999985 c0-0.0999985,0-0.2999992,0-0.4000015c-0.0999985,0-0.0999985,0-0.1999969,0c-1.0999985,0.5-2.1999969,1-3.1999969,1.5999985 c0,0.2000008-0.0999985,0.5-0.0999985,0.7000008C101.1999969,54.4000015,102.3000031,53.7999992,103.5,53.0999985z"
      />
      <path
        fill={colors[0]}
        d="M96.5,31.6000004c0.1999969-0.1000004,3.4000015,0.1000004,7.1999969,0.2000008 C109,33.4000015,115.7999954,36.4000015,115.7999954,36.4000015s-3.3000031,9.7999992-3.1999969,10.2000008 c0.0999985,0.5-6.1999969,0.7999992-6,0.2999992s-10.4000015-5.5999985-10.4000015-5.5999985L96.5,31.6000004z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M106.9000015,37.5l0.3000031,5.2000008 C106.9000015,44,107.3000031,42.6000023,107.3000031,42.5c0-0.0999985-0.0999985-0.2000008-0.0999985-0.2000008 c0.0999985-0.2000008,0.1999969-0.2999992,0.4000015-0.5c-0.0999985-0.0999985-0.1999969-0.2000008-0.1999969-0.2000008V41.5 c0,0,0.0999985,0.0999985,0.3000031,0.2000008l0,0c0,0,0,0,0,0.0999985c0.0999985,0,0.0999985,0.0999985,0.1999969,0.0999985l0,0 c-0.3000031-0.2000008-0.5-0.4000015-0.5-0.5s0.0999985-0.0999985,0.0999985-0.2000008 c0,0,0.1999969,0.2000008,0.4000015,0.4000015l0.0999985-0.2000008c-0.0999985-0.2000008-0.1999969-0.4000015-0.1999969-0.5 s0-0.2000008,0-0.2999992c0,0.0999985,0.0999985,0.2000008,0.3000031,0.4000015l0.3000031-1.2999992 c-0.1999969-0.0999985-0.3000031-0.2999992-0.3000031-0.2999992c0.0999985-0.0999985,0.0999985-0.2000008,0.1999969-0.2999992 c-0.0999985,0-0.1999969-0.0999985-0.3000031-0.0999985l0,0c0.0999985-0.0999985,0.0999985-0.2999992,0.1999969-0.4000015 c0.0999985-0.2000008,0.6999969-0.9000015,0.0999985-0.2000008c0.0999985-0.0999985,0.3000031-0.0999985,0.5,0L108.9000015,38 L106.9000015,37.5z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M106.3000031,37.5L106.3000031,37.5 C106.1999969,37.4000015,106.1999969,37.4000015,106.3000031,37.5C106.1999969,37.5,106.1999969,37.4000015,106.3000031,37.5 c-0.0999985,0-0.0999985,0-0.0999985,0c0.0999985-0.2000008,0.1999969-0.2999992,0.3000031-0.5l0,0l-1.1999969-0.5999985 c-0.3000031,0-0.5,0-0.8000031,0s-0.6999969,0.0999985-1-0.0999985l-0.0999985-0.0999985l0,0l0,0 c-0.0999985-0.0999985-0.0999985-0.2999992,0-0.5c0-0.0999985,0.0999985-0.0999985,0.0999985-0.2000008l-0.5-0.2000008l0,0 c0,0,0,0-0.0999985,0s-0.3000031-0.0999985-0.5999985-0.2999992c-0.0999985,0-0.0999985,0-0.1999969,0 c-0.3000031-0.0999985-0.4000015-0.0999985-0.5-0.4000015c0,0-0.0999985,0-0.0999985-0.0999985l-0.0999985-0.0999985l0,0l0,0 l-1.6999969-0.9000015c-0.1999969-0.0999985-0.5-0.0999985-0.6999969-0.2000008h-0.0999985l0.0999985-0.0999985 l-1.3000031-0.7000008c-0.0999985,0-0.3000031,0-0.4000015,0c-0.3000031,0-0.4000015-0.0999985-0.4000015-0.5 l-0.4000015-0.2000008c0,0-0.9000015,9.4000015-0.0999985,9.5999985c0.8000031,0.2000008,10,5.2999992,10,5.2999992 l1.3000031-9.1000023C107.3000031,37.5999985,106.8000031,37.5,106.3000031,37.5z"
      />
    </g>
  )
}

export default MaulL
