import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import Cape from "./Cape"
import LeafClasp from "./LeafClasp"
import Skarf from "./Skarf"

const CATEGORY = "overWear"
const CHARACTER = "WomanDwarf"
export const IDS = {
  CAPE: "Cape",
  LEAF_CLASP: "LeafClasp",
  SKARF: "Skarf"
}

export const components = {
  [IDS.CAPE]: Cape,
  [IDS.LEAF_CLASP]: LeafClasp,
  [IDS.SKARF]: Skarf
}

export const Group = ({ props }) => (
  <g id="overWear">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.CAPE,
    name: "Cape",
    defaultColors: ["#7B0A00", "#SVGID_", "#FFFFFF"],
    colorable: true,
    component: components[IDS.CAPE],
    inUI: true,
    enabled: false,
    subGroupId: 2
  },
  {
    id: IDS.LEAF_CLASP,
    name: "Leaf Clasp",
    defaultColors: ["#FCDF54", "#421115"],
    colorable: true,
    component: components[IDS.LEAF_CLASP],
    inUI: true,
    enabled: false,
    subGroupId: 1
  },
  {
    id: IDS.SKARF,
    name: "Skarf",
    defaultColors: ["#7F0E05", "#262261"],
    colorable: true,
    component: components[IDS.SKARF],
    inUI: true,
    enabled: false,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
