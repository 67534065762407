import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FrontShieldL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FrontShieldL") ? (
    <g id="FrontShieldL">
      <path
        id="_x3C_path_x3E_"
        d="M87.6999969,106.5C84,106.5,80.5,104.5999985,78,101.0999985l0,0 c-0.5999985-0.9000015-1.1999969-1.8000031-1.6999969-2.9000015L76.0999985,98c-0.1999969-0.5-0.4000015-1-0.6999969-1.5 c-2.1999969-5.5999985-3.0999985-13.0999985-2.1999969-20.0999985c1.5-12.5,8-25.9000015,18.7000046-25.9000015 c1.4000015,0,2.9000015,0.3000031,4.1999969,0.8000031C107.8000031,55.5,108.5000076,70,107.0000076,81.4000015 c-0.1999969,1.7999954-0.5,3.5999985-0.9000015,5.4000015l0,0l0,0v0.0999985 c-0.1999969,0.9000015-0.4000015,1.6999969-0.5999985,2.5l-0.0999985,0.1999969 C102.1000061,100.6999969,95.5000076,104.6999969,90.5000076,106C89.5999985,106.4000015,88.5999985,106.5,87.6999969,106.5z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FrontShieldL, {
  character: "WomanDwarf"
})
