import React from "react"

const GoldAxeRight = ({ colors = ["#897441", "#A39155", "#EDCD6E"] }) => {
  return (
    <g id="GoldAxeRight">
      <path
        fill={colors[0]}
        d="M20.4802628,76.3726349c0.6359406,2.1021576,2.4263153,3.5951462,4.7138977,4.2557907 c0.38801-1.4057846,2.2058411-2.889183,4.6403828-3.6089172c2.4345379-0.7197342,4.7461567-0.524704,5.8703747,0.4291916 c1.5678482-1.7738953,2.2120171-3.986618,1.5760689-6.088768c-0.6359406-2.10215-2.4263229-3.5951462-4.7139015-4.2557907 c-0.3880081,1.4057846-2.2058392,2.8891907-4.640379,3.6089172c-2.4345398,0.7197342-4.7461567,0.5247116-5.8703747-0.4291916 C20.5468521,72.1491013,19.8278351,74.3453522,20.4802628,76.3726349z"
      />
      <path
        fill={colors[1]}
        d="M29.7006512,80.8358078l8.4493008,31.1353226l2.6934509-0.113327 c0.1991539-0.1916122,0.4147987-0.4580612,0.5390968-0.666153c-0.7183571-1.7278671-2.8061142-6.5042343-4.0107002-10.3012924 c-1.4724274-4.7193604-6.6582165-25.0115433-6.6582165-25.0115433l-1.4195614-7.4547119 c-0.2080841-0.1243057-0.3578014-0.1572647-0.4910297-0.2650833l-3.0244579,0.9037857 c-0.1243019,0.2080841-0.1572647,0.3577957-0.2815666,0.5658875l2.798563,6.8949966L29.7006512,80.8358078z"
      />
      <path
        fill={colors[0]}
        d="M38.6485367,111.8454666l-8.3579655-31.193718l-1.4051228-4.3121185 l-2.6818142-6.7123184c-0.1661892,0.0418854-0.0672989-0.4072495-0.0508175-0.4821014l2.3761654-0.8110733 c0,0,0.1840534,0.58992,0.2589092,0.6064072l1.3941517,7.2136536c0.0418892,0.1661987,5.1274204,20.2008514,6.6163235,24.8453674 c1.1791801,3.5559998,3.1920815,8.3158798,4.085556,10.3177719c-0.1078262,0.1332245,0.158638,0.3488617,0.0673027,0.4072418 L38.6485367,111.8454666z"
      />
      <path
        fill={colors[2]}
        d="M38.8895874,111.8200455l-8.1588078-31.3853073l-1.5713158-4.2702408 l-2.5334778-7.3860168l1.5122299-0.4518967l1.8357353,7.7033157c0.544611,2.1605301,5.2695751,20.6245728,6.6582165,25.0115509 c1.1043167,3.5395279,3.2010117,8.6317978,4.0944824,10.6336823L38.8895874,111.8200455z"
      />
      <g fill={colors[2]}>
        <path d="M34.7812233,77.0098648c0.3577995,0.1572723,0.6407394,0.2980576,0.9072037,0.5136948 c1.567852-1.7738953,2.2120209-3.986618,1.5760689-6.088768c-0.6359367-2.10215-2.4263153-3.5951462-4.7138977-4.2557907 c-0.0824089,0.3742828-0.422348,0.8488312-0.7458076,1.2485199c0.7485619,0.1648178,1.4641609,0.4793472,2.0884247,0.8522568 c0.0748596,0.0164795,0.3159065-0.008934,0.7471886-0.5418549c-0.0824089,0.3742828-0.3310089,0.7904587-0.2726364,0.8817978 c0.8158684,0.5720596,1.5074348,1.352211,1.7587929,2.3493729C36.6038628,73.722374,36.0585938,75.4859619,34.7812233,77.0098648 z" />
        <path d="M20.5551186,76.3891296c0.6359406,2.10215,2.426321,3.5951462,4.7139034,4.2557907 c0.0659275-0.2994232,0.2067146-0.5823746,0.4388313-0.9236908c-0.8817959-0.272644-1.6557693-0.6785126-2.3219223-1.2176056 c-0.0748577-0.0164871-0.5315456,0.2753906-0.6558456,0.4834824c0.2067089-0.5823669,0.2396736-0.7320862,0.1812973-0.8234177 c-0.0583744-0.0913391-0.2499809-0.2904968-0.3083553-0.3818359c-0.0164814,0.074852-0.0164814,0.074852-0.1078167,0.1332245 c-0.0748558-0.0164719,0.0494461-0.2245636,0.0494461-0.2245636c-0.2918739-0.4566879-0.6421204-1.0047226-0.7677994-1.5033035 c-0.535677-1.8446121,0.1174126-3.7414322,1.5609627-5.3072433c-0.5240002-0.1153641-0.9566555-0.2891159-1.2814884-0.5960999 C20.4884815,72.0577698,19.8278351,74.3453522,20.5551186,76.3891296z" />
      </g>
    </g>
  )
}

export default GoldAxeRight
