import React from "react"
import { withSkinColor } from "../../builderConnector"

const Ears = ({ skinColor, colors = ["#E7B080", "#353891"] }) => {
  return (
    <g id="Ears">
      <path
        fill={skinColor}
        d="M41.6807022,57.2298317c-0.1072884-0.1072845-1.6943054-2.8189735-1.7747688-2.8457947 c-0.0386162-0.0128708-0.648735-1.7771416-0.4023247-2.6821518c-0.1328125-0.1097908-0.4887505-0.2461052-0.8314667,0.0536461 c-1.3142509,2.3602905,0.6168976,6.973587,0.6168976,6.973587s0.0268211,1.9311485,0.0536423,2.0116119 c0.0143852,0.0431557,0.3375664,0.6841278,0.8896561,0.9682922c0.5900764-0.8582878,0.9819069-0.6581001,1.0192184-0.6705399 C41.3320198,61.0116615,41.6807022,57.2298317,41.6807022,57.2298317z"
      />
      <path
        opacity="0.2"
        fill={colors[1]}
        d="M40.5839157,56.5833855c-0.7241821,0.1609306-1.5288239-1.8104515-1.9311485-3.8220634 c-0.1609268,2.132309,1.0121536,3.9391098,1.7299881,4.8278694c-0.1609306,0.2270851-0.3802681,0.2559853,0,1.3276634 c0.0571518,0.0422668,0.1223984,0.3447762,0.1609268,0.7644119l-0.7644119-0.3620872 c0,0.1728554,0.5230179,0.255703,0.6236,0.617794c0.1005821,0.3620872,0.0636101,0.6647186,0.1032372,0.6364174 c0.0777206-0.0555191,0.2235031-0.5328026,0.1985054-1.0990906c-0.0192719-0.4365387-0.2135201-0.9660568-0.1639481-1.1479836 c0.1565514-0.5746155,0.6914177-0.3457108,0.6914177-0.3457108S41.5173187,57.8624992,40.5839157,56.5833855z"
      />
      <g>
        <path
          fill={skinColor}
          d="M53.2886543,57.2298317c0.1072845-0.1072845,1.6943016-2.8189735,1.7747688-2.8457947 c0.0386124-0.0128708,0.6487312-1.7771416,0.4023209-2.6821518c0.1328163-0.1097908,0.4887505-0.2461052,0.8314667,0.0536461 c1.3142509,2.3602905-0.6168938,6.973587-0.6168938,6.973587s-0.0268211,1.9311485-0.0536423,2.0116119 c-0.0143852,0.0431557-0.3375702,0.6841278-0.8896599,0.9682922c-0.5900726-0.8582878-0.9819069-0.6581001-1.0192184-0.6705399 C53.6373329,61.0116615,53.2886543,57.2298317,53.2886543,57.2298317z"
        />
        <path
          opacity="0.2"
          fill={colors[1]}
          d="M54.385437,56.5833855c0.7241821,0.1609306,1.5288277-1.8104515,1.9311485-3.8220634 c0.1609306,2.132309-1.0121536,3.9391098-1.7299843,4.8278694c0.1609268,0.2270851,0.3802643,0.2559853,0,1.3276634 c-0.0571556,0.0422668-0.1224022,0.3447762-0.1609306,0.7644119l0.7644119-0.3620872 c0,0.1728554-0.5230179,0.255703-0.6236,0.617794c-0.1005783,0.3620872-0.0636101,0.6647186-0.1032372,0.6364174 c-0.0777206-0.0555191-0.2234993-0.5328026-0.1985054-1.0990906c0.0192719-0.4365387,0.2135201-0.9660568,0.1639481-1.1479836 c-0.1565514-0.5746155-0.6914177-0.3457108-0.6914177-0.3457108S53.452034,57.8624992,54.385437,56.5833855z"
        />
      </g>
    </g>
  )
}

export default withSkinColor(Ears, { character: "ManGnome" })
