import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const WizardRobe = ({ allEnabledElements }) => {
  return allEnabledElements.includes("WizardRobe") ? (
    <g id="WizardRobe">
      <path
        id="S_x24_wizardRobe"
        d="M20.6000004,119.4000015c0-0.0999985,1.1000004-6.5999985,3.2000008-21.2000046 l-3.2999992,0.1999969l-1.1000004-2.0999985l-0.6000004-1.4000015l-3.6999998-14.0999985l-0.1999998-1.5999985 c-0.1999998-5.5,0.8999996-9.4000015,3.2999992-11.8000031l0.2000008-0.3000031l0.2000008-2.3000031v-0.4000015 l0.3999996-3.0999985c-0.2999992-2.9000015-0.2999992-9.2000008,4.6999989-12.9000015 c3.3999996-2.5999985,6.8999996-3.0999985,8.6000023-3.2999992c0.0999985,0,0.2000008,0,0.2999992,0 c2.5-0.9000015,5-1.7000008,7.5-1.7000008c2.2999992,0,4.2999992,0.5999985,6.0999985,1.7999992L46.5,45.3999863l1.5,1.0999985 c0.2000008-0.0999985,0.7000008-0.5,0.7000008-0.5L49,45.799984c2-1.2999992,4.0999985-1.9000015,6.4000015-1.9000015 c1.9000015,0,3.5999985,0.5,5.2999992,1c0.2000008,0.0999985,0.5,0.0999985,0.7000008,0.2000008 c0.2999992,0,0.9000015,0.0999985,1.2999992,0.0999985C66,45.3999825,70.5999985,45.5999832,73.8000031,48.0999832 c3.3000031,2.5,4.8000031,5.9000015,5.4000015,7.7999992c0.8000031,2.2000008,0.9000015,4.9000015,1,7.7000008v0.6999969 c-0.0999985,1-0.0999985,2.1999969-0.0999985,3.0999985c1.1999969,1.5999985,2.3000031,3.3000031,2.8000031,4.3000031 c1.4000015,2.5,2.5999985,4.5999985-4.5,26.6999969l-6,0.0999985c1.1999969,7.4000015,2.8000031,16.5,3.5999985,21 L20.6000004,119.4000015L20.6000004,119.4000015z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(WizardRobe, {
  character: "ManHalfling"
})
