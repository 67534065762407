import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const CutlassL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("CutlassL") ? (
    <g id="CutlassL">
      <path
        id="_x3C_path_x3E_"
        fill="#01060F"
        d="M60.6722984,84.056572l11.0660362-32.6052818l3.8862839,2.8323784 c0,0,2.7006378,7.6408348-5.0719299,28.0603027c0.7904282,3.4252014,1.6520538,11.2854614-3.5569382,11.3953857 c-5.2090034,0.1099167-7.5090981-2.041954-7.5090981-2.041954L60.6722984,84.056572z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(CutlassL, { character: "ManGnome" })
