import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BlueFlameLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BlueFlameLeft") ? (
    <g id="BlueFlameLeft">
      <path d="M85.9984894,46.5939331c0,0,10.0533447,18.9896545,9.4948273,29.8109665 c-0.1396332,4.1888962,0.4188919,12.07798-11.1703873,12.1477966 C63.0250168,83.9318237,85.9984894,46.5939331,85.9984894,46.5939331z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BlueFlameLeft, {
  character: "WomanGnome"
})
