import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const PoleArmR = ({ allEnabledElements }) => {
  return allEnabledElements.includes("PoleArmR") ? (
    <g id="PoleArmR">
      <path d="M31.2,19c0-0.1,0-0.1,0-0.2c-0.2-2.2,0.3-4.3,0.6-6.4l-0.6-0.1l6-27.1l14,1.5v48.7c0,0.8-0.1,1.6-0.4,2.4l-0.5,78.8 l-0.6,2.8l-0.7,1.7l-13.2-0.5l-0.6-1.7l-0.4-2.4V36l-0.4-0.5l0,0.2l0-0.2h0.1l-0.1-0.2c0-1.9-0.2-2.8-0.5-4 c-0.1-0.4-0.2-0.9-0.3-1.3c-0.4-1.6-0.9-3.6-2.2-6l0,0l-0.1-0.2l0,0l0,0l0.4-0.2c-0.2-0.6-0.4-1.3-0.5-2 C31,20.6,31.1,19.8,31.2,19z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(PoleArmR, { character: "ManMixed" })
