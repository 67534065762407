import React from "react"

const LeatherArmor = ({ colors = ["#754C29", "#FFFFFF", "#010101"] }) => {
  return (
    <g id="LeatherArmor">
      <path
        fill={colors[0]}
        d="M61.9272499,58.1796074l-7.0390015-0.9385338l-6.1004677,2.502758l-6.7261581-2.502758 l-7.1954231,1.0949554c0,0,1.8770676,2.8938141,2.1116982,5.8658371c0.2346344,2.972023-0.3910561,4.3798218-0.3910561,4.3798218 l1.4078026,9.4747849l-1.9552803,14.3238602l11.5752487,0.9385376l0.7157593-3.6774292l0.6920433,3.6774292l12.2791443-0.93853 l-1.9552803-13.8546066l1.3295898-10.7261581c0,0-0.7821083-0.6256866-0.469265-3.5977097 C60.5194473,61.1516342,61.9272499,58.1796074,61.9272499,58.1796074z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M50.7174873,61.6934433l-1.9847908,9.1193085l-2.1457214-9.2265968l-7.4973755,0.8168564 l-0.2346344,6.6479454c0,0,8.8378601,2.7373886,9.7763901,2.2681198 c1.0167427,0.4692688,9.7763901-2.2681198,9.7763901-2.2681198l0.1564255-6.6479454L50.7174873,61.6934433z"
      />
      <path
        opacity="0.2"
        fill={colors[2]}
        d="M56.0211182,79.1973419c-0.1624146-0.9896851-0.8964233-4.5834351-3.2693481-6.7456665 c4.5479126-1.5065918,4.5610352-2.2275391,4.5610352-2.2275391l1.5047607-1.0206909L48.732666,72.046524l-10.2457886-2.8430786 l1.3066406,1.0206909c0,0,0.0109863,0.7127075,4.4841919,2.2023315 c-2.2247314,2.2627563-2.4295654,6.1261597-2.4483032,6.8930664l-3.2090454-1.1848755l9.6831665,5.8206177l10.5734863-5.8206177 L56.0211182,79.1973419z M48.4749146,82.5927277l-6.2330322-3.9481201c0,0-0.493103-5.9177246,6.1548462-5.9177246 s6.9876709,5.8104858,6.9876709,5.8104858L48.4749146,82.5927277z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M42.2418938,78.6446228c0,0-0.4931183-5.9177475,6.1548271-5.9177475 s6.9877052,5.810463,6.9877052,5.810463l-6.9094925,4.0553741L42.2418938,78.6446228z"
      />
      <polygon
        opacity="0.2"
        fill="#FFF"
        points="38.0577393,78.4300537 48.357193,84.3844223 59.3462791,78.5257339 61.2851562,92.216301 55.0357475,92.725914 53.6142082,82.345993 53.8824234,92.8600159 49.2691269,93.2355194 48.3303719,88.3540039 47.4452629,93.2891617 43.422039,92.7527313 43.422039,82.6142044 42.0273209,92.6990891 36.1802368,92.48452"
      />
      <polygon
        opacity="0.2"
        fill={colors[2]}
        points="47.2235565,61.2343483 48.7877769,70.3850555 50.2737923,61.312561 49.209137,60.3662033 48.8659897,60.0611839"
      />
    </g>
  )
}

export default LeatherArmor
