import React from "react"

const GoldAxeRight = ({ colors = ["#897441", "#A39155", "#EDCD6E"] }) => {
  return (
    <g id="GoldAxeRight">
      <path
        fill={colors[0]}
        d="M25.7999992,69.0999985C27.5,70,30.5,69.5999985,33.3999977,68 c2.9000015-1.5999985,4.7999992-4,4.9000015-5.9000015c3.0999985,0.2000008,5.7999992,1.5999985,7.2000008,4.0999985 s1.2000008,5.5-0.2999992,8.1999969C43.5,73.4999924,40.5,73.8999939,37.6000023,75.4999924 c-2.9000015,1.5999985-4.7999992,4-4.9000015,5.9000015C29.6000004,81.1999969,26.9000015,79.7999954,25.5,77.2999954 C24.1000004,74.9000015,24.2999992,71.8000031,25.7999992,69.0999985z"
      />
      <path
        fill={colors[1]}
        d="M35.5,75.3000031l-5.5-8C30.1000004,67,30.1000004,66.8000031,30.2000008,66.5l3.5999985-2 C34,64.5999985,34.2000008,64.5999985,34.5,64.6999969l3.9000015,9.0999985c0,0,12.2999992,24.4000015,15.5,30 c2.5999985,4.5,6.5999985,10,8,12c-0.0999985,0.3000031-0.2999992,0.6999969-0.5,1L58,117.6999969L38.5999985,80.3000031 L35.5,75.3000031z"
      />
      <path
        fill={colors[0]}
        d="M61.4000015,116.5999985c0.0999985-0.0999985-0.2999992-0.3000031-0.2000008-0.5 c-1.7000008-2.3000031-5.5999985-7.8000031-8.1000023-12c-3.2000008-5.5-15.2999992-29.5999985-15.4000015-29.7999954 L33.8999977,65.5c-0.0999985,0-0.5-0.6999969-0.5-0.6999969L30.5999985,66.5c0,0.0999985,0,0.6999969,0.2000008,0.5999985 l5.2999992,7.8000031l3,5.0999985L58.5,117.5L61.4000015,116.5999985z"
      />
      <path
        fill={colors[2]}
        d="M61.2000008,116.5999985C59.5,114.2999954,55.4000015,108.4000015,53,104.1999969 c-3-5.1999969-14.2000008-27.4000015-15.5-30L32.9000015,65l-1.7999992,1l5.2999992,8.6999969l3.2000008,5l19.2000008,37.6999969 L61.2000008,116.5999985z"
      />
      <g fill={colors[2]}>
        <path d="M44.0999985,67.3000031c-0.5999985-1.1999969-1.7000008-2-2.9000015-2.5 c-0.0999985-0.0999985,0.0999985-0.6999969,0.0999985-1.2000008C40.8999939,64.4000015,40.5999947,64.5,40.4999962,64.5 c-0.9000015-0.3000031-1.9000015-0.5-2.9000015-0.5c0.2999992-0.5999985,0.5999985-1.2999992,0.5999985-1.7999992 c3.0999985,0.2000008,5.7999992,1.5999985,7.2000008,4.1000023c1.4000015,2.5,1.2000008,5.5-0.2999992,8.1999969 c-0.4000015-0.1999969-0.7999992-0.3000031-1.2999992-0.4000015C45,71.8000031,45.2000008,69.3000031,44.0999985,67.3000031z" />
        <path d="M25.7000008,69.0999985C26.2000008,69.4000015,26.8000011,69.5,27.5,69.5 c-1.3999996,2.4000015-1.7000008,5-0.5,7.1999969c0.2999992,0.5999985,0.8999996,1.1999969,1.3999996,1.6999969 c0,0-0.1000004,0.3000031,0,0.3000031C28.5,78.5999985,28.5,78.5999985,28.5,78.5 c0.1000004,0.0999985,0.3999996,0.3000031,0.5,0.4000015S29.1000004,79.2000046,29,80 c0.1000004-0.3000031,0.6000004-0.8000031,0.7000008-0.8000031c1,0.5,2.1000004,0.8000031,3.2999992,0.9000015 c-0.2000008,0.5-0.2999992,0.9000015-0.2999992,1.3000031C29.6000004,81.2000046,26.9000015,79.8000031,25.5,77.3000031 C24.1000004,74.8000031,24.2999992,71.8000031,25.7000008,69.0999985z" />
      </g>
    </g>
  )
}

export default GoldAxeRight
