import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LuteLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LuteLeft") ? (
    <g id="LuteLeft">
      <path
        id="S_x24_luteLeft_1_"
        d="M54.6610947,112.8647308c-3.1979027,0-5.7416916-0.2907104-6.4684906-0.7267914 c-2.7618294-1.453598-7.0499382-5.0149078-4.7968636-12.5735931c0.7994766-2.8345108,6.5411758-11.3380356,11.9194794-14.7539902 l6.0324211-10.829277l5.4509811-2.8345108l3.0525436,1.8169861c0,0,3.6339874,2.180397,3.6339874,3.5613098 s-2.5437927,8.1401291-2.5437927,8.1401291l-3.4159393,5.9597397c0.3633881,2.4711075,0.7994766,7.1952972,0,11.6287613 l-0.1453629,0.6541138C66.2898636,106.1781921,62.9465942,112.8647308,54.6610947,112.8647308z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LuteLeft, { character: "ManGnome" })
