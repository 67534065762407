import React from "react"

const LuteRight = ({
  colors = ["#93712B", "#D8A43D", "#6A5121", "#B68B32", "#8E6D2B"]
}) => {
  return (
    <g id="LuteRight">
      <path
        fill={colors[0]}
        d="M29.0297546,77.6877441h0.58144l0.0726814,0.2907181l0.3633957,0.0726776 l0.2907238-0.5087585V77.106308l-0.5087585,0.0726776l-0.1453571,0.2180405l-0.726799,0.0726776 C28.957077,77.5423813,28.8843994,77.6877441,29.0297546,77.6877441z"
      />
      <path
        fill={colors[0]}
        d="M28.957077,77.0336227l0.6541176,0.0726852v0.1453476l0.29072-0.1453476 l0.2907219-0.2907257l0.0726776-0.3634033l-0.3633995,0.1453629l-0.29072,0.2180405l-0.7267952,0.0726776 C28.8843994,76.9609451,28.8117199,77.0336227,28.957077,77.0336227z"
      />
      <path
        fill={colors[0]}
        d="M28.8843994,76.5248642h0.5814381l0.0726814,0.1453552l0.3633957-0.0726776 l0.2907219-0.2907104l0.0726776-0.3634033l-0.3633995,0.1453552l-0.29072,0.2180481l-0.7267952,0.0726776 C28.8117199,76.379509,28.7390423,76.5248642,28.8843994,76.5248642z"
      />
      <path
        fill={colors[0]}
        d="M29.1751194,78.5598984l0.6541176,0.07267l0.0726776,0.1453629h0.3633995 l0.2907181-0.4360733l0.0726814-0.3634033l-0.3633995,0.1453552l-0.2907181,0.2180481l-0.7268009,0.0726776 C29.1751194,78.4145432,29.102438,78.5598984,29.1751194,78.5598984z"
      />
      <path
        fill={colors[1]}
        d="M34.6987801,88.2989807c3.8520241-0.1453552,11.4833946,10.4658813,12.137516,12.718956 c0.6541138,2.3257446,0.8721504,4.9422302-2.3257523,6.6138535c-3.1979103,1.6716309-7.5586967-1.453598-8.7942467-3.2705841 C34.3353767,102.5442047,32.0823097,88.7350616,34.6987801,88.2989807z"
      />
      <path
        fill={colors[2]}
        d="M27.5034828,77.6150665l0.0726757-0.726799l6.2504597,12.3555527 c0,0-2.2530708,20.3503265,10.6112404,18.4606552c0,0-8.2128143,4.7968597-11.7741165-6.3231354 c-0.8721523-5.2329407,0.2907181-11.4834061,0.2907181-11.4834061"
      />
      <path
        fill={colors[0]}
        d="M27.7942009,79.7227783l-0.5814381,0.2180405v0.1453629l-0.2907219,0.1453476 l-0.3633957-0.2180328l-0.1453571-0.2907257h0.5087528h0.2180424l0.726799-0.0726776 C27.8668823,79.5774155,27.939558,79.7227783,27.7942009,79.7227783z"
      />
      <path
        fill={colors[0]}
        d="M27.6488438,78.1238174l-0.5814381,0.2180405v0.2907257l-0.2907219,0.1453552 l-0.3633957-0.3633957l-0.1453571-0.4360809h0.5087528l0.2180424,0.1453552H27.6488438 C27.6488438,77.9784622,27.7215252,78.0511398,27.6488438,78.1238174z"
      />
      <path
        fill={colors[0]}
        d="M27.5761585,77.469696l-0.5814362,0.2180481v0.2180328l-0.29072-0.07267 l-0.3633995-0.2180405l-0.1453571-0.2907257h0.4360752l0.3634014,0.1453552l0.7267952-0.0726776 C27.5761585,77.3243408,27.6488438,77.469696,27.5761585,77.469696z"
      />
      <path
        fill={colors[0]}
        d="M27.4308014,76.9609451l-0.5814381,0.1453629v0.1453476l-0.3633995,0.0726776 l-0.3633995-0.2180328l-0.1453571-0.2907257h0.4360809l0.3633957,0.1453705l0.726799-0.0726776 C27.4308014,76.8155899,27.5761585,76.8882675,27.4308014,76.9609451z"
      />
      <path
        fill={colors[0]}
        d="M27.6488438,78.9959793l-0.5814381,0.2180405v0.2180405l-0.3633995,0.0726776 l-0.3633995-0.3634033l-0.1453571-0.2907181h0.4360771l0.3633995,0.1453552h0.6541176 C27.7215252,78.8506165,27.7942009,78.9959793,27.6488438,78.9959793z"
      />
      <polygon
        fill={colors[3]}
        points="36.0070152,88.662384 29.102438,76.1614685 27.3581257,76.5975418 33.826622,89.2438202"
      />
      <polygon
        fill={colors[4]}
        points="45.67342,102.3261795 41.7487106,104.2885284 41.4579926,103.4163666 45.2373428,101.5267029"
      />
      <polygon
        fill={colors[0]}
        points="27.5761585,77.0336227 27.5034828,77.6150665 28.0122433,80.0861816 28.6663589,79.9408112"
      />
      <polygon
        fill={colors[2]}
        points="27.7942009,79.7227783 28.0122433,80.0861816 27.6488438,76.8155899 27.3581257,76.5975418"
      />
      <ellipse
        transform="rotate(-31.851 39.104 95.43)"
        fill={colors[0]}
        cx="39.104"
        cy="95.43"
        rx="1.308"
        ry="1.89"
      />
      <line fill="none" x1="44.293" y1="102.399" x2="28.884" y2="76.307" />
      <line fill="none" x1="43.929" y1="102.544" x2="28.448" y2="76.307" />
      <line fill="none" x1="43.493" y1="102.617" x2="28.085" y2="76.452" />
      <line fill="none" x1="43.13" y1="102.835" x2="27.722" y2="76.525" />
    </g>
  )
}

export default LuteRight
