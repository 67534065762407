import React from "react"

const TorchR = ({
  colors = [
    "#654219",
    "#282560",
    "#F0D118",
    "#FDD301",
    "#FDD400",
    "#SVGID_",
    "#FFFFFF",
    "#F2B61B",
    "#F39934",
    "#F17B21",
    "#F0672F",
    "#ED4424"
  ]
}) => {
  return (
    <g id="TorchR">
      <path
        fill={colors[0]}
        d="M42.1122589,45.9955635L48.32938,66.0565643l1.3019676,0.0276413l0.9695206-0.5540771 l-5.0370674-20.4267082C43.0651474,45.1423187,42.1122589,45.9955635,42.1122589,45.9955635z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M43.7798576,45.4026794 c-0.1994553,0.0110893-0.2936325,0.1163559-0.3933563,0.1219025c1.8617477,4.6980553,3.0476074,9.8338203,4.2888336,14.1662483 c0.6206093,2.166214,1.3409462,4.3268852,2.0557365,6.3878288l0.8697968-0.5485306l-2.709713-10.9529686 c-0.1108131-0.1939049-0.1163559-0.2936287-0.227169-0.4875336C46.5059357,51.253067,44.9434433,48.3389626,43.7798576,45.4026794 z"
      />
      <path
        opacity="0.3"
        fill={colors[1]}
        d="M44.7996979,54.749054l3.5352287,11.4072342 l0.6980667-0.0388184c-0.8366928-2.4542961-1.5736618-4.914135-2.5045395-7.2631645 C45.9522018,57.4858894,45.3759499,56.1174698,44.7996979,54.749054z"
      />
      <path
        opacity="0.84"
        fill={colors[2]}
        d="M38.1672935,39.8128128 c0.576252,1.3684158,1.052784,2.7423782,1.7232132,4.0055275c-0.0166359-0.2991714-0.127449-0.4930763-0.1385384-0.6925278 c-0.3768044-1.3795052-1.0583267-2.8421021-0.7370567-4.2604256c-0.1052666-0.0941772-0.1108131-0.1939049-0.1163559-0.2936287 c-0.0333557-2.3989296,0.1826439-3.9114304,0.7531776-6.2439117c0.4985428-1.82831,0.4819107-2.1274834,1.5954247-3.689888 c-0.0055466-0.0997276,0.3488197-4.520874,0.2767372-5.8172913c-0.0055466-0.0997238-0.0055466-0.0997238,0.0941772-0.1052685 c-0.0277252-0.4986229,0.2990913-1.8172207,0.2270927-1.3130531c-0.3046341,1.7174969,1.4019394,5.423851,1.4241219,5.8227482 c0,0,0.8810577,3.2520924,2.1941948,5.2797661c1.0859642,1.5401459,1.3796806,3.2243652,1.5847588,5.1135902 c0.310257,0.1828117,0.6260681,0.4653511,0.6371613,0.6647987c0.0887184,1.5955963-0.2214622,3.2133636-0.7366333,4.7425003 c0.9196167-1.4515877,1.733963-2.9973602,1.8391457-4.7037659c-0.1052704-0.0941772-0.210537-0.1883621-0.2160835-0.2880821 c-0.7591553-2.8587379-2.4878235-5.1634064-3.2469749-8.0221462c-0.1108131-0.193903,0.2991791-0.0166359,0.5041695,0.0719986 c-0.5153465-2.0720329-1.8840179-6.8975372-1.8840179-6.8975372c-0.7756958-1.3573284-2.3825493-5.0692291-2.864624-6.5429153 c-0.2603607,0.7147064-1.0358047,4.7591324-1.1964378,5.4682941c-0.2492714,0.914156,0.6648865,1.1634254,0.8145142,2.0554008 c0.1219101,0.3933544-0.5817108,0.3324432-0.6869812,0.2382641c-0.09972,0.0055447-0.5595284,0.7313423-0.5595284,0.7313423 c-1.0082474,1.6565857-1.1078911,3.4627171-1.7061501,5.2965717 C37.9121475,33.4249077,38.0840416,36.5163651,38.1672935,39.8128128z"
      />
      <radialGradient
        id="SVGID_11_"
        cx="-280.829"
        cy="682.699"
        r="16.66"
        gradientTransform="scale(-1 1) rotate(3.183 11520.765 4656.998)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fdd301" stopOpacity="0.5" />
        <stop offset="1" stopColor="#fdd400" stopOpacity="0" />
      </radialGradient>
      <circle fill="url(#SVGID_11_)" cx="42.023" cy="33.596" r="16.68" />
      <g fill="#FFF">
        <path
          opacity="0.18"
          d="M47.2760963,52.5106735l-0.1219025-0.3933563 c-1.2410583-0.7312584-2.5818329-1.4569702-3.9226112-2.1826782l0.1163559,0.2936287 C44.6942558,51.0537033,46.035038,51.7794151,47.2760963,52.5106735z"
        />
        <path
          opacity="0.18"
          d="M47.6418037,53.6907272l-0.0166359-0.2991714 c-1.2244148-0.4320831-2.45438-0.9638901-3.6843414-1.495697l0.0055428,0.0997238 C45.1818771,52.6271133,46.4118462,53.1589241,47.6418037,53.6907272z"
        />
        <path
          opacity="0.18"
          d="M46.9879227,50.9261665l-1.4185753-5.7230263 c-2.5042038-0.0608253-3.4515419,0.8921471-3.4515419,0.8921471l0.6039772,1.8670425 C44.0736465,48.8874893,45.5307846,49.906826,46.9879227,50.9261665z"
        />
      </g>
      <g opacity="0.98">
        <path
          fill={colors[2]}
          d="M41.3532753,46.737999c-1.4904861-3.4182739-1.7621117-6.5041847-1.130661-9.5402794 c0.3323593-1.2188721,0.7644424-2.4432907,1.1965256-3.6677094c0.343441-1.0194244,0.7810707-2.1441193,1.0192528-3.2577229 c0.0830956-0.304718,0.1661835-0.6094379,0.2437248-1.0138798l0.3047218,0.0830898 c-0.0719986,0.5041676-0.0442734,1.0027885,0.0831757,1.4958668c0.2770729,1.3850517,0.9475098,2.648201,2.210743,3.7783546 l0.0055428,0.0997238l-0.2659073,0.6149788l0.5041695,0.0719986c0.2382584,0.6869774,0.4765282,1.3739586,0.7147903,2.0609436 c0.1274452,0.4930763,0.3490791,0.8808861,0.4765282,1.3739624c0.1163559,0.2936287,0.1385384,0.692524,0.2604408,1.0858765 c-0.2270851,1.3130531-0.5594444,2.531929-0.8918037,3.7508011c-0.0719986,0.5041695-0.2492714,0.9141579-0.4209938,1.4238701 c-0.758976,0.7424316-1.6232262,1.3906822-2.5927467,1.9447556 C42.4668808,46.9761734,41.8574448,46.8099937,41.3532753,46.737999z"
        />
        <path
          fill={colors[7]}
          d="M41.7632675,46.9152603c-1.2854996-3.3296356-1.6402092-6.1108322-1.0032158-9.0472031 c0.2381821-1.1136017,0.6702652-2.3380203,1.1023445-3.562439c0.2603645-0.7147026,0.5207176-1.429409,0.7810783-2.1441193 c0.3657188,1.1800613,1.0306015,2.3434868,2.0888405,3.3850021l-0.3434486,1.0194244l0.8088875,0.1550903 c0.1385384,0.6925278,0.3712616,1.2797852,0.6039772,1.8670425c0.1219025,0.3933487,0.3435364,0.7811584,0.4709854,1.2742386 c0.1163559,0.2936287,0.1329918,0.592804,0.2493515,0.8864288c-0.2326317,1.2133293-0.564991,2.4322052-0.9028969,3.5513535 c-0.0775414,0.4044418-0.1550903,0.8088837-0.3323593,1.2188721c-0.6648064,0.6371613-1.4348717,1.1801453-2.2104912,1.6234016 C42.6718788,47.0648079,42.2674332,46.9872627,41.7632675,46.9152603z"
        />
        <path
          fill={colors[8]}
          d="M42.2618904,46.8875465c-1.1691399-3.0360069-1.4185791-5.7230263-0.870224-8.4543991 c0.2381821-1.1136093,0.675808-2.2383003,1.1078911-3.462719c0.0886421-0.2049904,0.1772766-0.4099884,0.2659073-0.6149826 c0.3435364,0.7811623,0.8754272,1.3517799,1.4128647,2.0221291l-0.4265366,1.3241425l1.1136017,0.2381783 c0.2327156,0.5872536,0.4598961,1.0747871,0.5873413,1.5678635c0.1219025,0.3933525,0.3435364,0.7811623,0.4709854,1.2742386 c0.1108131,0.1939049,0.2216263,0.3878098,0.2382622,0.6869812c-0.2326393,1.2133293-0.4708138,2.326931-0.8142624,3.3463554 c-0.1772728,0.4099922-0.2603607,0.7147102-0.3434486,1.0194244c-0.5761642,0.4321709-1.1523399,0.8643379-1.828228,1.3020515 C42.8768692,47.1534462,42.6718788,47.0648079,42.2618904,46.8875465z"
        />
        <path
          fill={colors[9]}
          d="M42.8768768,47.1534424c-1.0583267-2.8421059-1.2966728-5.32967-0.7372284-7.861599 c0.1495399-0.9086075,0.4985352-1.8283081,0.8419838-2.8477325c0.2160797,0.2880859,0.4321709,0.5761681,0.6482506,0.8642502 l-0.5151711,1.5291367l1.4183235,0.32127c0.1219025,0.3933525,0.3490829,0.8808861,0.4709854,1.2742386 s0.3490791,0.8808823,0.4709816,1.2742386c0.0055466,0.0997238,0.1163597,0.2936249,0.127449,0.4930763 c-0.2381821,1.1136017-0.4818993,2.1274834-0.8253517,3.1469078c-0.0830956,0.304718-0.1661835,0.609436-0.2548141,0.8144302 c-0.382267,0.3213539-0.864254,0.6482544-1.346241,0.9751511C43.0763206,47.1423531,42.976593,47.1478996,42.8768768,47.1534424z"
        />
        <path
          fill={colors[10]}
          d="M42.682621,40.0619202l1.4183235,0.32127c0.1219101,0.3933487,0.2438126,0.786705,0.3601723,1.0803299 c0.2216339,0.3878059,0.3490791,0.8808861,0.4709854,1.2742386c0.0055428,0.0997238,0.1108131,0.1939049,0.1163559,0.2936287 c-0.2437248,1.0138779-0.4929924,1.9280319-0.7422638,2.8421898c-0.0941772,0.1052666-0.0830879,0.3047142-0.1717186,0.5097122 c-0.1939087,0.1108131-0.481987,0.3268967-0.7700729,0.5429802C42.5221634,44.3722496,42.3004494,42.183857,42.682621,40.0619202 z"
        />
        <path
          fill={colors[11]}
          d="M43.281311,47.2309875c-0.6039734-1.8670387-0.8035088-3.6565361-0.5985947-5.3684845l0.609436,0.1661797 c0.0166321,0.2991714,0.1274452,0.4930763,0.2438049,0.786705c0.2216339,0.3878059,0.3490791,0.8808823,0.4709816,1.2742386 c0.0055466,0.0997276,0.0055466,0.0997276,0.0110931,0.1994476c-0.1495514,0.9086113-0.3988152,1.8227654-0.6536293,2.6371956 C43.3699455,47.0259933,43.2757683,47.1312637,43.281311,47.2309875L43.281311,47.2309875z"
        />
      </g>
    </g>
  )
}

export default TorchR
