import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const FrontShieldL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("FrontShieldL") ? (
    <g id="FrontShieldL">
      <path
        id="_x3C_path_x3E_"
        d="M80.4015656,97.728096c-2.3813629,0-4.6340027-1.2228622-6.2430344-3.475502l0,0 c-0.3861618-0.5792542-0.7723312-1.1585007-1.0941391-1.866478l-0.1287231-0.1287155 c-0.1287231-0.3218079-0.2574463-0.6436157-0.4505234-0.9654236c-1.415947-3.6042252-1.9952011-8.4313126-1.415947-12.9365921 c0.965416-8.0451508,5.1488953-16.669548,12.0355453-16.669548c0.9010544,0,1.8664703,0.1930847,2.7031631,0.5148926 c7.5302582,2.7031631,7.9807892,12.0355377,7.0153732,19.3727112c-0.1287231,1.1585007-0.3218079,2.3170013-0.5792542,3.4755096 l0,0l0,0v0.0643539c-0.1287155,0.5792542-0.2574463,1.0941391-0.3861618,1.6090317l-0.0643616,0.1287231 c-2.1239243,7.1440887-6.3717575,9.7185364-9.5898209,10.5552292 C81.6244278,97.6637344,80.9808197,97.728096,80.4015656,97.728096z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(FrontShieldL, {
  character: "WomanGnome"
})
