import React from "react"
import connectToBuilder from "../../builderConnector"
import { uiSchemaService } from "../../uiSchema"

import LeftThumb from "./LeftThumb"
import RightThumb from "./RightThumb"

const CATEGORY = "thumbs"
const CHARACTER = "WomanGnome"
export const IDS = {
  LEFT_THUMB: "LeftThumb",
  RIGHT_THUMB: "RightThumb"
}

export const components = {
  [IDS.LEFT_THUMB]: LeftThumb,
  [IDS.RIGHT_THUMB]: RightThumb
}

export const Group = ({ props }) => (
  <g id="thumbs">
    {Object.keys(components).map(key => {
      const Element = components[key]
      return <Element {...props} key={key} />
    })}
  </g>
)

uiSchemaService.register(CHARACTER, CATEGORY, [
  {
    id: IDS.LEFT_THUMB,
    name: "Left Thumb",
    defaultColors: ["#E7B080"],
    colorable: false,
    component: components[IDS.LEFT_THUMB],
    inUI: false,
    enabled: true,
    subGroupId: 0
  },
  {
    id: IDS.RIGHT_THUMB,
    name: "Right Thumb",
    defaultColors: ["#E7B080"],
    colorable: false,
    component: components[IDS.RIGHT_THUMB],
    inUI: false,
    enabled: true,
    subGroupId: 0
  }
])

export default connectToBuilder(components, {
  character: CHARACTER,
  category: CATEGORY
})
