import get from 'lodash/get'

import { LOAD_CHARACTER } from '../actions/builderActions'

const TOGGLE_ELEMENT = 'BUILDER.TOGGLE_ELEMENT'
const SET_COLOR = 'BUILDER.SET_COLOR'
const SET_SKIN_COLOR = 'BUILDER.SET_SKIN_COLOR'
const SET_DEFAULT_ENABLED = 'BUILDER.SET_DEFAULT_ENABLED'
const SET_COLOR_ITEM = 'BUILDER.SET_COLOR_ITEM'
const CLEAR_COLOR_ITEM = 'BUILDER.CLEAR_COLOR_ITEM'
const SET_CHARACTER = 'BUILDER.SET_CHARACTER'
const SET_SAVED_ALERT = 'BUILDER.SET_SAVED_ALERT'
const RESET_CHARACTER = 'BUILDER.RESET_CHARACTER'

// Action Creators
export const resetCharacter = (payload) => ({
  type: RESET_CHARACTER,
  payload
})

export const setSavedAlert = (payload) => ({
  type: SET_SAVED_ALERT,
  payload
})

export const toggleElement = (payload) => ({
  type: TOGGLE_ELEMENT,
  payload
})

export const setSkinColor = (color) => ({
  type: SET_SKIN_COLOR,
  payload: {
    skinColor: color
  }
})

export const setDefaultEnabled = (payload) => ({
  type: SET_DEFAULT_ENABLED,
  payload
})

export const setColorActiveItem = (item) => ({
  type: SET_COLOR_ITEM,
  payload: item
})

export const clearColorActiveItem = () => ({
  type: CLEAR_COLOR_ITEM
})

export const setColor = (item, color, index) => ({
  type: SET_COLOR,
  payload: { item, color, index }
})

export const setCharacter = (character) => ({
  type: SET_CHARACTER,
  payload: character
})


// Selectors
export const categorySelector = (state, { character, category }) => {
  return get(state, ['builderReducer', 'enabledElements', character, category])
}

export const allEnabledElementsSelector = (state, character) => {
  const enabledElements = get(state, ['builderReducer', 'enabledElements', character]) || {}
  return  Object.keys(enabledElements).reduce((ele, category) => {
    return [...ele, ...enabledElements[category].map(e => e.id)]
  }, [])
}

export const itemSelected = (state, { category, character, id }) => {
  const layers = get(state.builderReducer, ['enabledElements', character, category]) || []

  return !!layers.filter(l => l.id === id).length
}

export const skinColorSelector = (state) => get(state, ['builderReducer', 'skinColor', 'hex'])

export const itemColorSelector = (id) => (state) => {
  const colors = get(state, ['builderReducer', 'colors', id])
  if (!colors) return

  return colors.map(c => {
    if(typeof c === 'object') return c.hex
    return c
  })
}

export const currentColorItemSelector = state => get(state, "builderReducer.colorActiveItem")

export const currentItemCurrentColorSelector = state => {
  const itemId = get(currentColorItemSelector(state), 'id')
  return  get(state, ['builderReducer', 'colors', itemId, 0])
}

// Reducers
const defaultState = {
  character: 'WomanMixed',
  skinColor: { hex: '#C88E62' },
  colorActiveItem: undefined,
  colors: {},
  enabledElements: {
    'WomanMixed': {},
    'ManMixed': {},
    'ManDwarf': {},
    'WomanDwarf': {},
    'ManGnome': {},
    'WomanGnome': {},
    'ManHalfling': {},
    'ManDragonborn': {}
  }
}

const builderReducer = (state = defaultState, action) => {
  switch(action.type) {
    case SET_DEFAULT_ENABLED:
      return { ...state, enabledElements:
        { ...state.enabledElements, [action.payload.character]: action.payload.elements }
      }
    case TOGGLE_ELEMENT:
      return { ...state, enabledElements: enabledElementsReducer(state.enabledElements, action) }
    case SET_SKIN_COLOR:
      return { ...state, ...action.payload }
    case SET_COLOR_ITEM:
      return { ...state, colorActiveItem: action.payload }
    case CLEAR_COLOR_ITEM:
      return { ...state, colorActiveItem: undefined }
    case SET_COLOR:
      return { ...state,
        colors: colorsReducer(state.colors, action.payload)
      }
    case SET_CHARACTER:
      return { ...state, character: action.payload }
    case RESET_CHARACTER:
      return { ...state, enabledElements: { ...state.enabledElements, [action.payload]: {} } }
    case SET_SAVED_ALERT:
      return { ...state, savedAlertEnabled: action.payload }
    case LOAD_CHARACTER:
      return {
        ...state,
        colors: action.payload.colors,
        enabledElements: {
          ...state.enabledElements,
          ...action.payload.enabledElements
        }
      }
    default:
      return state
  }
}

const colorsReducer = (state, action) => {
  const curr = state[action.item.id] || action.item.defaultColors
  const next = curr.map((color, index) => {
    if (index !== action.index) return color
    return action.color
  })

  return { ...state, [action.item.id]: next }
}

const enabledElementsReducer = (state, action) => {
  switch(action.type) {
    case TOGGLE_ELEMENT:
      return {
        ...state,
          [action.payload.character]: {...state[action.payload.character], [action.payload.category]: categoryReducer(
            state[action.payload.character][action.payload.category],
            action.payload.element
          )
        }
      }
    default:
      return state
  }
}

const categoryReducer = (state = [], element) => {
  if(state.map(i => i.id).includes(element.id)) {
    return state.filter(i => element.id !== i.id)
  }

  return [...state.filter(e => e.subGroupId != element.subGroupId), element]
}

export default builderReducer