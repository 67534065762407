import React from "react"

const SmNose = ({ colors = ["#ED1C24", "#redGra", "#FFFFFF"] }) => {
  return (
    <g id="SmNose">
      <linearGradient
        id="redGradient_1_"
        gradientUnits="userSpaceOnUse"
        x1="68.33"
        y1="-545.878"
        x2="68.437"
        y2="-541.292"
        gradientTransform="matrix(1 0 0 -1 0 -488)"
      >
        <stop offset="0" stopColor={colors[0]} />
        <stop offset="0.281" stopColor={colors[0]} stopOpacity="0.719" />
        <stop offset="0.547" stopColor={colors[0]} stopOpacity="0.325" />
        <stop offset="0.786" stopColor={colors[0]} stopOpacity="0.07" />
        <stop offset="1" stopColor={colors[0]} stopOpacity="0" />
      </linearGradient>
      <path
        id="redGradient"
        opacity="0.76"
        fill="url(#redGradient_1_)"
        d="M67.6964035,53.7000008 c0,0-0.5,3.0999985-0.5,3.2000008c0,0,0.9000015,1,1.0999985,1S69.4963989,57,69.4963989,57 c0.0999985-0.0999985-0.5-3.2999992-0.5-3.2999992C68.8964005,53.5,67.6964035,53.5,67.6964035,53.7000008z"
      />
      <path
        id="shadow"
        opacity="0.17"
        d="M67.1964035,56.7999992 c0,0,0.8000031,0.5999985,0.9000015,0.7000008c0.0999985,0.0999985,0.3000031,0.0999985,0.5,0 c0.0999985-0.2000008,0.9000015-0.7000008,1-0.7000008c0,0-0.5999985,1.2000008-0.9000015,1.2000008s-0.1999969,0-0.5999985,0 S67.1964035,56.7999992,67.1964035,56.7999992z"
      />
      <ellipse
        id="Highlight"
        opacity="0.66"
        fill="#FFF"
        cx="68.096"
        cy="57.1"
        rx="0.3"
        ry="0.2"
      />
    </g>
  )
}

export default SmNose
