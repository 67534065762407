import React from "react"

const LeatherHelm = ({
  colors = ["#8B5E3C", "#C0A172", "#996464", "#603913"]
}) => {
  return (
    <g id="LeatherHelm">
      <path
        opacity="0.16"
        d="M57.3,40.5c0,0,0-0.1-0.1-0.2v-0.2h-0.1c-0.5-1.4-2.4-6.1-6.8-7.3l0,0 l-1.8-1.9l-2,1.9c-4.5,1.1-6.4,5.9-6.8,7.3h-0.1v0.2c0,0.1-0.1,0.2-0.1,0.2h0.1v0.1v2l7.3,1.5h2.9l7.3-1.5v-2L57.3,40.5L57.3,40.5 z"
      />
      <path
        fill={colors[0]}
        d="M39.6,39.6h17.7c0,0-2.1-7.9-8.9-7.9S39.6,39.6,39.6,39.6z"
      />
      <rect
        x="39.7"
        y="39.2"
        fill={colors[1]}
        stroke={colors[2]}
        strokeWidth="0.17"
        strokeMiterlimit="10"
        width="17.5"
        height="3"
      />
      <rect x="39.7" y="39.7" fill={colors[3]} width="17.5" height="2" />
      <polygon
        fill={colors[1]}
        points="46.5,31.9 47.1,39.3 49.7,39.3 50.4,31.9 48.5,30"
      />
      <polygon fill={colors[3]} points="48.3,30.2 46.8,31.7 47.4,39 48.2,39" />
      <polygon fill={colors[0]} points="48.5,30.2 50,31.7 49.4,39 48.4,39" />
    </g>
  )
}

export default LeatherHelm
