import React from 'react'

class CenteredSvg extends React.Component {
  constructor(props) {
    super(props)

    this.svgRef = React.createRef();
  }

  centerBox = () => {
    const bB = this.svgRef.current.getBBox()
    this.svgRef.current.setAttribute('width', this.props.width)
    this.svgRef.current.setAttribute('height', this.props.height || 'auto' )
    this.svgRef.current.setAttribute('viewBox', bB.x + ',' + bB.y + ',' + bB.width + ',' + bB.height)
  }

  componentDidMount() {
    this.centerBox()
  }

  render() {
    const Component = this.props.render

    return (
      <svg version="1.1"
        viewBox="0 0 413.3 724"
        ref={this.svgRef}
      >
        <Component {...this.props}/>
      </svg>
    )
  }
}

export default CenteredSvg