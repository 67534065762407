import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BlueFlameLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BlueFlameLeft") ? (
    <g id="BlueFlameLeft">
      <path
        id="S_x24_blueFlameLeft"
        d="M57,91.3000031c-2.4000015-2.8000031-5.2000008-6.5999985-5.9000015-11.5c0,0,1-5,0.5-3.0999985 c0.7999992-3.1999969,4.7999992-11.5,5.2999992-12.9000015c0,0,0.0999985-6.5999985,0.2000008-10.2999992 c0,0,1.2000008-3.7999992,1.0999985-3.7000008c0.2000008-0.4000015,1-1.9000015,2.2000008-4.2999992 c0.2000008-0.2999992,0.2999992-1.4000015,0.2999992-1.7000008c-0.0999985-1.5999985-0.2000008-3.7999992,0.5999985-6.2000008 L67.4999924,32l7,4.7000008c0.3000031,0.7000008,0.9000015,2,1.5999985,3.9000015 c0.3000031,0.7000008,0.5,1.4000015,0.6999969,1.7999992c1.0999985,1.7999992,1.9000015,6.2000008,1.9000015,6.4000015 c0.3000031,0.9000015,0.5,1.7999992,0.5999985,2.5999985h0.0999985c2.1999969,2.9000015,4.1999969,6.2000008,6,10.0999985 l0.6999969,2.9000015c0.0999985,4.4000015-2.5,23.4000015-3.5999985,25.7999954l-12.4000015,7.0999985L57,91.3000031z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BlueFlameLeft, {
  character: "ManHalfling"
})
