import React from "react"

const DarkPatch = ({ colors = [] }) => {
  return (
    <g id="DarkPatch" fill="#21376C">
      <path
        opacity="0.42"
        d="M44.699585,95.1277618 c-0.6704712-0.4052124-3.0344849-1.0709839-2.5548706-0.6451416c-0.1682739,0.4355469-0.3364868,0.8710938-0.5047607,1.3066406 c-0.0462036,0.0662842-0.0722656,0.142395-0.0854492,0.2211304c-0.288208,0.7459106-0.576355,1.4918823-0.864563,2.237793 c-0.0964355,0.2495117,2.8907471,1.1290283,2.9500122,0.9356079c0.354126-1.156189,0.7801514-2.2805176,1.2761841-3.3828735 C45.032959,95.541214,44.9414673,95.2740021,44.699585,95.1277618z"
      />
      <path d="M42.6978607,94.0669861c-0.0804672,0.0201187-0.1810455,0.6034851-0.1810455,0.6034851 l0.2816238-0.5833664L42.6978607,94.0669861z" />
      <path d="M40.5655518,97.7884674c0,0-0.1810455,0.2011642-0.0804672,0.2011642 c0.1005821,0,0.502903,0.1609268,0.502903,0.1609268L40.5655518,97.7884674z" />
      <path d="M41.9535637,98.6735764c-0.0804672,0.0402298-0.4425545,0.5833664-0.4425545,0.5833664l0.241394,0.120697 L41.9535637,98.6735764z" />
      <path d="M43.562851,98.6735764c0.0517502-0.0310516,0.5230217,0.3822098,0.5230217,0.3822098 s0.1408119-0.241394,0.0804634-0.2816315C44.1059875,98.7339249,43.4622726,98.7339249,43.562851,98.6735764z" />
      <polygon points="43.6231995,94.3083801 43.4421539,94.8917465 43.804245,94.3687286" />
      <polygon points="44.4680786,94.7308197 44.307148,95.1934891 44.6491241,94.8716278" />
      <polygon points="40.7465973,97.2453308 41.2495003,97.4464951 40.8270607,97.0441742" />
      <polygon points="41.0684547,96.4205704 41.0081062,96.6619644 41.5713577,96.7021942" />
      <polygon points="41.2897301,95.6561584 41.2293816,95.8573227 41.812748,95.9981308" />
      <polygon points="41.5311241,94.952095 41.4506607,95.2136078 42.0742607,95.3946457" />
      <polygon points="41.3299637,98.4120636 40.8672905,98.9350891 41.048336,99.0356674" />
      <polygon points="42.8789024,98.9954376 42.5168152,99.4983368 42.7783241,99.5989151" />
      <polygon points="43.8243637,97.8890457 44.3674965,98.1907883 44.427845,97.9695129" />
      <polygon points="44.1059875,97.2855682 44.5284271,97.5470734 44.6088905,97.3056793" />
      <polygon points="44.3674965,96.6820831 44.7094727,96.8832397 44.7698212,96.6820831" />
      <polygon points="44.5887756,96.1188278 44.9709816,96.4406891 45.0112114,96.1590652" />
    </g>
  )
}

export default DarkPatch
