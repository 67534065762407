import React from "react"

const SimpleVest = ({ colors = ["#6E5238", "#CFA987", "#8C725C"] }) => {
  return (
    <g id="SimpleVest">
      <g id="vest" fill={colors[0]}>
        <path d="M32.9,68.5c0.7,3.8,1.5,6.4,1.5,6.4l-1.8,7.4l14.6-1.1l-0.1-12.1l-5.7-16.2l-4.8,0.8c0,0-2.3-0.4-5.9-1.5 C32,54.9,33.5,63.7,32.9,68.5z" />
        <path d="M63.9,68.5c-0.7,3.8-1.5,6.4-1.5,6.4l1.8,7.4l-14.7-1.1l0.7-12.3l5.1-16l5,0.7c0,0,2.1-0.2,5.7-1.4 C64.7,54.9,63.2,63.7,63.9,68.5z" />
      </g>
      <ellipse fill={colors[1]} cx="46.3" cy="69.2" rx="0.4" ry="0.4" />
      <ellipse fill={colors[2]} cx="46.3" cy="69.2" rx="0.3" ry="0.3" />
      <ellipse fill={colors[1]} cx="46.2" cy="72.9" rx="0.4" ry="0.4" />
      <ellipse fill={colors[2]} cx="46.2" cy="72.9" rx="0.3" ry="0.3" />
      <g>
        <ellipse fill={colors[1]} cx="46.2" cy="76.5" rx="0.4" ry="0.4" />
        <ellipse fill={colors[2]} cx="46.2" cy="76.5" rx="0.3" ry="0.3" />
      </g>
      <g>
        <ellipse fill={colors[1]} cx="46.1" cy="80.2" rx="0.4" ry="0.4" />
        <ellipse fill={colors[2]} cx="46.1" cy="80.2" rx="0.3" ry="0.3" />
      </g>
    </g>
  )
}

export default SimpleVest
