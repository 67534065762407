import React from "react"

const SlingShotL = ({
  colors = ["#362812", "#454545", "#949494", "#724F1F"]
}) => {
  return (
    <g id="SlingShotL">
      <path
        fill={colors[0]}
        d="M91.4,65.8l1.1-10l6.2-2.9c0,0,2.2,2,2.1,4.6c0,0.4,2.1,34.2,2.1,34.2s-0.2,2.4-1.2,2.8s-3.2-0.4-3.2-0.4 l-0.5-2.8l0.5-34.2l1.2-1.9l-1.1-1.5l-5.3,2.8l1,2.3l-0.1,8.7L92,70.3l0.5-5.5L91.4,65.8z"
      />
      <path
        fill={colors[1]}
        d="M103.1,90.2c-0.8-1.1-1-1.8-2.5-1.6c-0.2,0-1.9,0.1-2,0.3c-0.5,0.8-1.2,2.7-1.2,2.7s1.1,2.1,2.5,2.2 c1,0.1,3.1-0.9,3.1-0.9S103.5,90.8,103.1,90.2z"
      />
      <path
        fill={colors[2]}
        d="M100.8,90c-0.2-0.4-0.4-0.9-0.6-1.3c-0.6,0-1.4,0.1-1.6,0.3c-0.5,0.8-1.2,2.7-1.2,2.7s0.4,0.8,1.1,1.4 c0.7-0.2,1.4-0.6,2.1-1.1C100.7,91.3,100.8,90.6,100.8,90z"
      />
      <path
        fill={colors[3]}
        d="M99.7,93.5l1.4-0.1l0.2-2.4l-0.6-2.6l-1.3-32.2l0.3-0.9l0.8,0.5l2.3,35.9c0,0-0.4,2.8-1.7,2.4 c-0.2-0.1-1.8,0-2.4-0.4C99.6,93.4,99.7,93.5,99.7,93.5z"
      />
    </g>
  )
}

export default SlingShotL
