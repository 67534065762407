import React from "react"

const SlingShotRight = ({
  colors = ["#352915", "#464646", "#939393", "#724F20"]
}) => {
  return (
    <g id="SlingShotRight">
      <path
        fill={colors[0]}
        d="M55.7487907,89.8131104l-0.7680969-6.9827042l-4.329277-2.0249863 c0,0-1.5361938,1.3965378-1.4663696,3.2120438c0,0.2793045-1.4663658,23.8808594-1.4663658,23.8808594 s0.1396523,1.6758575,0.8379211,1.955162c0.6982727,0.2793121,2.2344666-0.2793045,2.2344666-0.2793045l0.3491325-1.955162 L50.791069,83.7381439l-0.837925-1.3267136l0.7680969-1.0474091l3.70084,1.955162l-0.6982727,1.6060257l0.069828,6.0749588 l1.6060181,1.8853378l-0.3491364-3.8404922L55.7487907,89.8131104z"
      />
      <path
        fill={colors[1]}
        d="M47.5790215,106.8509216c0.5586205-0.7680969,0.6982727-1.2568893,1.7456779-1.1172256 c0.1396523,0,1.3267174,0.0698242,1.3965416,0.2094803c0.3491364,0.5586166,0.837925,1.8853302,0.837925,1.8853302 s-0.7680969,1.4663696-1.7456779,1.5361938c-0.6982727,0.0698242-2.1646385-0.6284485-2.1646385-0.6284485 S47.2997131,107.200058,47.5790215,106.8509216z"
      />
      <path
        fill={colors[2]}
        d="M49.1850433,106.6414413c0.1396523-0.2793121,0.2793083-0.6284485,0.4189606-0.907753 c0.4189606,0,0.977581,0.0698242,1.1172333,0.2094803c0.3491364,0.5586243,0.8379211,1.8853302,0.8379211,1.8853302 s-0.2793083,0.5586166-0.7680969,0.9775848c-0.4887848-0.1396561-0.977581-0.4189682-1.4663658-0.7680969 C49.3246994,107.6190186,49.2548676,107.1302338,49.1850433,106.6414413z"
      />
      <path
        fill={colors[3]}
        d="M49.9531403,109.1552124l-0.977581-0.0698242l-0.1396523-1.6758499l0.4189606-1.815506 l0.9077568-22.5541458l2.6534309-1.2568893l-1.4663696-0.6982727l-0.6982689-0.3491364l-0.977581,0.4887924l-0.6284447,1.466362 l-1.3965416,25.1377563c0,0,0.2793083,1.955162,1.1870575,1.6758499c0.1396523-0.0698242,1.2568893,0,1.6758499-0.2793045 C50.0927963,109.015564,49.9531403,109.1552124,49.9531403,109.1552124z"
      />
    </g>
  )
}

export default SlingShotRight
