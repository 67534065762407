import React from "react"

const SideBodyBag = ({ colors = ["#724B2B", "#FFFFFF"] }) => {
  return (
    <g id="SideBodyBag">
      <path
        opacity="0.3"
        fill="#21376C"
        d="M59.6249924,29.7328606 c0.9572678,2.5982933,5.7435913,10.5299187,6.4273529,12.170948c1.2307663,2.4615402,1.8461533,5.1965828,2.8717957,7.7264977 c0.2735062,0.615387,0.5470047,1.2307701,0.7521362,1.8461571c0.0683746,0.0683746,0.0683746,0.1367531,0.1367493,0.2051277 c3.2136765,4.5811996,7.8632584,7.6581268,9.2991486,5.6752167c2.5982971-3.6239357,1.3675232-8.9572716-2.1196518-11.8290672 c0,0-6.4273605-1.9145317-8.2735138-5.3333359l-0.4102554-0.1367531 c-0.0683746,0.0683746-6.1538467-11.7606945-6.2222214-11.692318 C62.018158,28.4337082,59.4882355,29.1174698,59.6249924,29.7328606z"
      />
      <path
        fill={colors[0]}
        d="M59.8984871,29.1858501c1.0256424,2.803421,6.7008629,12.1025715,6.4957314,12.4444523 c-0.0683746,0.1367531-0.0683746,0.2735023-0.0683746,0.5470085c0.1367493,0.2735023,0.2051315,0.4786301,0.3418808,0.7521362 c1.2991486,2.0512848,1.9829102,4.512825,3.0769272,6.6324844c0.683754,1.4358978,1.5042725,2.8034172,2.2564087,4.1709404 c2.8717957,3.0769234,6.0854797,4.7179527,7.2478714,3.1453018c2.5982895-3.6239357,1.9145279-9.02565-1.5726471-11.8974457 c0,0-6.9743729-1.8461571-8.8205261-5.2649612l-0.4102554-0.1367531 c-0.0683746,0.0683784-6.1538467-11.7606907-6.2222214-11.6923141S59.5566177,28.6388378,59.8984871,29.1858501z"
      />
      <path
        opacity="0.64"
        fill="#21376C"
        d="M80.4797058,50.3140678l-1.2991486,2.8034172 l-6.4957275-6.9059868c0,0-3.6923141-4.3076935-3.8290634-4.4444466s0-0.9572639,0-0.9572639 s0.4786301,0.5470085,0.6837616,1.2307663c0.2051239,0.6837616,9.7777863,10.3931694,9.7777863,10.3931694 s1.2991409-2.5299149,1.2307663-2.6666679L80.4797058,50.3140678z"
      />
      <path
        opacity="0.64"
        fill="#21376C"
        d="M78.428421,50.6559486 c0.4102554,0,1.094017,5.0598335,1.094017,5.0598335l1.094017,0.7521362v-0.8888931c0,0-0.8205109-0.9572678-0.7521362-2.3931656 c0-1.435894-0.5470047-2.3931618-0.5470047-2.3931618S78.0181656,50.6559486,78.428421,50.6559486z"
      />
      <path
        fill={colors[0]}
        d="M78.7019272,50.6559486c0.4102554,0,1.094017,5.0598335,1.094017,5.0598335l1.094017,0.7521362 v-0.8888931c0,0-0.8205109-0.9572678-0.7521362-2.3931656c0-1.435894-0.5470123-2.3931618-0.5470123-2.3931618 S78.2916718,50.6559486,78.7019272,50.6559486z"
      />
      <path
        opacity="0.3"
        fill="#21376C"
        d="M59.693367,29.459343 c1.8461533,4.1025658,6.6324844,12.170948,6.4957275,12.4444504c-0.0683746,0.0683784-0.0683746,0.2051315-0.0683746,0.3418808 c0.0683746,0,0.0683746,0.0683784,0.0683746,0.0683784c0.0683823,0.2735023,0.2051315,0.5470047,0.3418808,0.8205109 c1.2307739,1.9829102,1.9145355,4.3076973,3.0085526,6.4273567c0.8205109,1.5726509,1.709404,3.0769234,2.5299149,4.5811996 c2.803421,2.9401741,5.9487228,4.512825,7.0427399,2.9401741c0.2051315-0.3418808,0.4102554-0.6837616,0.615387-1.0256424 c0-0.2051277-0.0683823-0.3418808-0.1367569-0.4786301c-0.2051239-0.0683784-0.2735062-0.9572639-0.3418808-1.1623917 c-0.0683746-0.0683784-0.1367493-0.0683784-0.2051239-0.1367531c0-0.0683746-0.1367569,0.5470085-0.2051315,0.7521362 c0,0,0,0.2051315-0.0683746,0.3418808l0,0c0,0.0683746,0,0.0683746,0,0.0683746 c-0.0683746,0.2735062-0.3418808,0.4786339-0.6837616,0.4102554c-3.2136765-0.6837578-5.8119659-4.5128212-7.7265015-6.9743614 c-1.9145355-2.4615364-3.5555573-5.6752167-4.4444427-8.8888931c-1.2991486-2.1196594-4.5811996-8.8888931-5.5384674-11.2136803 C60.1719971,28.775589,59.693367,29.1174698,59.693367,29.459343z"
      />
      <path
        fill="#21376C"
        d="M78.1549149,49.4251747l1.0256424,0.2735062 C78.5651703,48.9465408,78.1549149,49.4251747,78.1549149,49.4251747z"
      />
      <path
        fill="#21376C"
        d="M77.5395279,50.4508171l2.0512848,0.3418808 C79.043808,50.2456894,77.5395279,50.3824425,77.5395279,50.4508171z"
      />
      <polygon
        fill="#21376C"
        points="77.2660294,50.1773109 77.1292725,49.2884216 77.4027786,49.220047 77.5395279,50.3824425"
      />
      <g opacity="0.54" fill="#FFF">
        <path
          opacity="0.23"
          d="M73.0950851,43.8183327 c-0.2735062-0.4102554-1.2991486-1.094017-1.5726547-1.5042725c-1.1623917-0.4102592-2.2564163-2.0512848-2.7350464-2.6666718 c0.4102554,0.8205147,2.324791,3.0769272,3.0085526,3.6923103 C72.0010681,43.4080772,72.8899536,43.8183327,73.0950851,43.8183327z"
        />
        <path
          opacity="0.23"
          d="M78.7703018,46.0747452 c-0.3418808-0.4102554-0.6837616-0.7521362-1.094017-1.094017c0,0-3.6239319-1.4359016-5.606842-2.3247871 c0.1367569,0.2051277,2.1196594,1.3675194,2.2564163,1.6410255 C75.7617569,44.7756004,77.2660294,45.3909836,78.7703018,46.0747452z"
        />
        <path
          opacity="0.23"
          d="M61.3343887,30.0747356 c0.0683823,0.1367531,0.1367569,0.2735043,0.2051315,0.4102573c0.6837616,0,1.2991486,0,1.9829102,0 c-0.1367531-0.2051296-0.2735062-0.4102573-0.3418808-0.6153851 C62.5651627,29.9379845,61.9497795,30.006361,61.3343887,30.0747356z"
        />
        <path
          opacity="0.23"
          d="M60.5822525,28.775589 c0.1367569,0.2051277,0.4786377,0.7521381,0.615387,1.0256424c0.615387,0,1.2991486-0.2735043,1.9145355-0.2735043 c-0.2051277-0.3418808-0.3418808-0.6153851-0.4786339-0.8888893 C62.018158,28.7072144,61.1292648,28.7072144,60.5822525,28.775589z"
        />
        <path
          opacity="0.23"
          d="M62.496788,33.1516571 c0.0683746,0.1367531,0.7521362,1.4358978,0.9572639,1.709404c0.5470085-0.0683746,1.5726509-0.5470085,2.1196632-0.6837616 c-0.2735062-0.4786301-0.4786377-0.9572639-0.7521362-1.4358978 C64.6164474,32.9465332,62.7702942,33.2200356,62.496788,33.1516571z"
        />
        <path
          opacity="0.23"
          d="M63.5224342,35.0661926 c0.2734985,0.4786339,0.5470085,1.0256424,0.8205147,1.5042725c0.5470047-0.0683746,1.7093964-0.341877,2.2564087-0.4102554 c-0.2735062-0.5470085-0.615387-1.094017-0.8888931-1.6410255C65.1634598,34.6559372,64.0010681,34.997818,63.5224342,35.0661926 z"
        />
        <path
          opacity="0.23"
          d="M64.4796982,37.1174736 c0.2734985,0.4102554,0.5470047,0.9572678,0.8205109,1.3675232c0.4102554-0.1367531,1.6410217-0.7521362,2.0512772-0.8888893 c-0.1367493-0.2735023-0.3418732-0.5470085-0.4786301-0.8888893 C66.3258514,36.7755966,65.0267029,37.1174736,64.4796982,37.1174736z"
        />
        <path
          opacity="0.23"
          d="M79.7959442,52.0234642 c0.1367493,0.8205109,0.3418808,1.6410255,0.5470047,2.4615402c0.4102554-1.2307663,0.5470123-2.5982895,0.3418808-3.897438 h-0.0683746c-0.2051239,0.4102592-0.4786301,0.8888931-0.6837616,1.2991486 C79.8643188,51.9550934,79.8643188,52.023468,79.7959442,52.0234642z"
        />
        <path
          opacity="0.23"
          d="M77.6762848,49.835434l0.8205185,0.2051277 c0.3418808-0.0683746,0.6153793,0,0.7521362,0.3418808c0.0683746-0.0683784,0.0683746-0.0683784,0.0683746-0.1367531 c0.0683746-0.2735062,0.0683746-0.0683746,0.0683746-0.3418808 C78.7019272,49.3568001,77.1292725,49.3568001,77.6762848,49.835434z"
        />
        <path
          opacity="0.23"
          d="M71.3856812,48.3995323 c0.5470123,0,1.1623993,0,1.5042725-0.1367531C72.7532043,48.1260262,71.1805573,48.1260262,71.3856812,48.3995323z"
        />
        <path
          opacity="0.23"
          d="M72.6848297,49.972187 c1.5726471,1.9829063,5.1282043,6.222229,5.8119659,4.6495781c0.1367569-0.2051315,0.2735062-0.2735062,0.4786377-0.2735062 c-0.0683746-0.3418846-0.1367569-1.0940208-0.2051315-1.4359016c-1.0256424-0.5470085-3.4871826-2.3247871-4.7179489-3.5555573 C73.6420975,49.5619278,73.1634598,49.8354301,72.6848297,49.972187z"
        />
        <path
          opacity="0.23"
          d="M73.9156036,49.220047 c-0.2735062-0.2735023-0.4786301-0.5470085-0.7521362-0.7521362c-0.4102631,0.2051277-0.8205185,0.3418808-1.2991486,0.4102554 c0,0.0683746,0.0683746,0.1367531,0.1367493,0.2051277c0.0683746,0.1367531,0.2735062,0.4102592,0.615387,0.8205147 C73.0267105,49.6986809,73.5053406,49.4251747,73.9156036,49.220047z"
        />
      </g>
    </g>
  )
}

export default SideBodyBag
