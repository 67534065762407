import React from "react"

const LightPatch = ({ colors = ["#FFFFFF"] }) => {
  return (
    <g id="LightPatch">
      <path
        opacity="0.2"
        fill="#FFF"
        d="M56.6749382,101.807518 c0.9964523-0.061676,3.9564781-1.0786209,3.1450539-1.1769867c-0.1395683-0.5784378-0.2791977-1.1568298-0.418766-1.7352676 c0.0003395-0.1029663-0.0275536-0.201622-0.0708427-0.2936859c-0.2389946-0.9906464-0.4780998-1.9813004-0.7170944-2.9719467 c-0.0799179-0.3313904-3.8479576,0.9135513-3.7697754,1.1592102c0.4674911,1.4683685,0.8365326,2.9554901,1.1162796,4.4703674 C56.0257416,101.6160736,56.3154144,101.8297119,56.6749382,101.807518z"
      />
      <g fill="#21376C">
        <path d="M59.5418282,101.467247c0.069725-0.0794373-0.2479858-0.7636719-0.2479858-0.7636719l0.1280022,0.8155441 L59.5418282,101.467247z" />
        <path d="M59.0770569,96.0211334c0,0,0.0438118-0.3421021-0.0615845-0.2691498 c-0.1053925,0.0729523-0.6436882,0.1961136-0.6436882,0.1961136L59.0770569,96.0211334z" />
        <path d="M56.9806633,96.1003647c0.0551338-0.1005173,0.0406265-0.9322662,0.0406265-0.9322662 l-0.3404846,0.0486069L56.9806633,96.1003647z" />
        <path d="M55.2943535,97.2675552c-0.0317039,0.0700684-0.8252563-0.0211639-0.8252563-0.0211639 s0.0275269,0.3550797,0.1199417,0.3534698C54.6814575,97.5982437,55.3559799,97.1313705,55.2943535,97.2675552z" />
        <polygon points="58.3971214,101.885437 58.1637268,101.1428375 58.1636429,101.9535065" />
        <polygon points="57.2054253,102.0555573 57.0384865,101.4540176 56.9135857,102.0393143" />
        <polygon points="59.2812729,96.7215729 58.6084061,96.8755264 59.3428574,96.990715" />
        <polygon points="59.5421982,97.8192444 59.4303551,97.522522 58.8109703,97.8888855" />
        <polygon points="59.8647461,98.7807236 59.7820854,98.5261688 59.0686684,98.8017197" />
        <polygon points="60.122448,99.6935654 60.0170937,99.3611755 59.2323418,99.6237564" />
        <polygon points="57.8237762,95.9221039 57.9292526,95.0384827 57.6665916,95.0643997" />
        <polygon points="55.7775955,96.4342346 55.7922707,95.6446457 55.4452934,95.72892" />
        <polygon points="55.5893364,98.2792969 54.8013573,98.3570404 54.8986092,98.6326828" />
        <polygon points="55.731926,99.115921 55.0996017,99.1482849 55.1903648,99.4595871" />
        <polygon points="55.8955994,99.9379578 55.3913612,99.9751968 55.4740219,100.2297516" />
        <polygon points="56.0722504,100.688652 55.4383125,100.6286011 55.6004143,100.9528809" />
      </g>
    </g>
  )
}

export default LightPatch
