import React from "react"

const Pants = ({ colors = ["#643A00"] }) => {
  return (
    <g id="Pants">
      <path
        fill={colors[0]}
        d="M57.0135002,77.0545502c0,0,9.3204689,1.5556488,11.4125481,1.5556488 c2.0920715,0,11.8819199-0.9521637,11.8819199-0.9521637l0.7778244,3.258812c0,0,2.7894363,8.4755936,0.858284,17.594902 c2.7894363,4.0768661,2.4675827,7.6173019,1.8238678,14.2690353c-2.253006-0.6437149-5.1497269,1.3947144-5.1497269,1.3947144 l-3.1649399-9.6557388l-1.7702179-3.2185745c0,0-1.8435898-8.9711761-3.7550125-14.5372543 c-0.461853-0.3931427-0.8130188-0.5364304-1.3947144-0.5364304c-0.4761734,0-1.0097504,0.1669388-1.3947144,0.4291458 c-0.9654922,2.6091003-2.6444244,10.0154419-3.9695854,14.0544662c-0.5865746,1.7878571-1.2337875,2.8967209-1.2337875,2.8967209 l-3.379509,10.7822418c0,0-1.1801453-3.3258667-5.3642998-1.8238678 c0.2145729-5.2033691-0.6437149-11.5868835,1.9311485-14.2690277 C54.8007278,94.7567368,53.7949219,86.4957199,57.0135002,77.0545502z"
      />
    </g>
  )
}

export default Pants
