import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const LuteRight = ({ allEnabledElements }) => {
  return allEnabledElements.includes("LuteRight") ? (
    <g id="LuteRight">
      <path
        id="S_x24_luteRight"
        d="M60.917366,110.9341736c3.0723915,0,5.5163422-0.2793045,6.2146072-0.6982651 c2.6534348-1.3965454,6.7732315-4.8180771,4.6085892-12.0800858c-0.7680969-2.723259-6.2844391-10.8930283-11.4516411-14.1749039 L54.4932671,73.576683l-5.23703-2.723259l-2.9327354,1.7456741c0,0-3.4913559,2.0948181-3.4913559,3.4215317 s2.4439507,7.8206329,2.4439507,7.8206329l3.281868,5.7258224c-0.3491325,2.374115-0.7680969,6.9128799,0,11.1723328 l0.1396523,0.6284409C49.7450294,104.5100784,52.9570732,110.9341736,60.917366,110.9341736z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(LuteRight, {
  character: "WomanGnome"
})
