import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const BowLeft = ({ allEnabledElements }) => {
  return allEnabledElements.includes("BowLeft") ? (
    <g id="BowLeft">
      <path
        id="S_x24_bowLeft"
        d="M35.4031639,121.8794174l-0.2749176-1.2829437l-0.1832771-1.649498v-0.0916367l0.1832771-1.6494904 l0.0916405-0.4581985l0.641468-1.6494827l0.2749176-0.5498352l0.1832771-0.2749176l30.9738235-70.4700317v-0.1832733 l0.1832733-0.5498314l0.7331161-1.5578575l0.2749176-0.4581909l1.0996552-1.2829399l0.0916367-0.0916367l1.3745804-1.0080261 l0.8247452-0.4581909l1.5578537-0.5498314h0.0916367l1.649498-0.1832771 c3.9404602,0.1832771,7.0561676,3.1157074,7.6976395,7.2394447l0.0916367,0.5498276v0.1832771v0.9163857 c0.3665543,0.9163857,1.0080185,2.3826027,2.1076889,4.9484825l0.3665543,0.7331085 c5.315033,12.6461182-17.4113235,64.8800735-30.3323593,69.5536423L35.4031639,121.8794174z"
      />
    </g>
  ) : null
}

export default connectToAllEnabledElements(BowLeft, {
  character: "ManHalfling"
})
