import React from "react"

const BattleAxeRight = ({ colors = ["#7A7B7D"] }) => {
  return (
    <g id="BattleAxeRight">
      <path
        fill={colors[0]}
        d="M38.4066467,30.7999992c-0.2000008,0.5-0.7000008,1-1.2000008,1.2000008 c-2.0999985,1-3.7999992,1.5-6.1000004,1.5c-0.2999992,0.2999992-1,0.7000008-1.3999996,1.0999985 c0-0.2000008,0.1000004-3.7999992,0.1000004-4c0,0,0.7999992-0.1000004,0.7999992-0.2000008 c0,0-1.5-0.7000008-1.7000008-5.2000008c-0.5,4.5-1.8999996,5.2000008-1.8999996,5.2000008 c0,0.2000008,0.7999992,0.2000008,0.7999992,0.2000008c0,0.2000008-0.1000004,4-0.1000004,4S26.8066483,34,26.3066483,33.4000015 c-2.2999992-0.0999985-4-0.5999985-6-1.7000008C19.8066483,31.5,19.3066483,31,19.2066479,30.5c0,0-9,12.5999985,2,21.7000008 C19.8066483,46.9000015,19.5066471,39.5,26.006649,39c0.1000004,0,1.6000004-0.0999985,1.6000004-0.0999985L27.4066486,43.5 c-0.1000004,0.2000008-0.3999996,0.2999992-0.7000008,0.7000008h0.8999996v1.4000015h-1c0,0.0999985,0,1.9000015,0,2 c0.1000004,0,1,0,1.1000004,0c-0.2999992,19.6000023-0.7999992,51.0000038-1.1000004,70.5999908h-0.1000004 c0.2999992,0.9000015,0.7000008,1.3000031,1.1000004,1.1999969c0.3999996,0.1999969,0.7999992-0.1999969,1.1000004-1.0999985 h-0.1000004c0.2999992-19.5999985,0.7999992-51,1.1000004-70.5999985c0.1000004,0,1,0,1.1000004,0c0-0.0999985,0-1.9000015,0-2 h-1v-1.4000015h0.8999996c-0.1000004-0.2000008-0.5-0.5-0.7000008-0.7000008v-4.5c0,0,1.3999996,0.0999985,1.6000004,0.0999985 c6.5,0.7000008,6,8.1000023,4.4000015,13.2999992C47.006649,43.7000008,38.4066467,30.7999992,38.4066467,30.7999992z"
      />
      <path
        d="M26.3771858,33.4000015c-2.2000008-0.0999985-3.7999992-0.5-5.7000008-1.5 c-2.1000004,3.5-5.7000008,11.4000015,0,17.4000015c-0.7000008-4.7000008,0-9.9000015,5.3999996-10.2999992 c0.1000004,0,1.2000008,0,1.5,0l0.2000008-4.4000015C27.4771862,34.4000015,26.7771854,33.7999992,26.3771858,33.4000015z"
        opacity="0.3"
      />
      <path
        d="M31.4185772,33.5c2.2000027,0,3.8000011-0.4000015,5.7000027-1.2999992c2,3.5,5.2999992,11.5-0.5,17.3999977 c0.9000015-4.7000008,0.2999992-9.9000015-5.1000023-10.5c-0.1000004,0-1.2999992,0-1.6000004,0v-4.5 C30.3185787,34.4000015,31.118578,33.7999992,31.4185772,33.5z"
        opacity="0.3"
      />
      <rect
        x="28.222"
        y="45.15"
        transform="rotate(-89.133 28.622 47.2)"
        opacity="0.3"
        width="0.8"
        height="4.1"
      />
      <polygon
        opacity="0.3"
        points="27.5839462,44.7614937 27.6066475,44.2594719 29.8066483,44.2999954 29.8168373,44.7221413"
      />
      <path
        opacity="0.3"
        d="M27.8005238,30.620697c0,0-0.5,0-0.7999992-0.2000008c0.5-0.2000008,2.2000008-0.2000008,3.6000004,0 c-0.1000004,0.1000004-0.7999992,0.2000008-0.7999992,0.2000008S28.9005241,30.3206978,27.8005238,30.620697z"
      />
    </g>
  )
}

export default BattleAxeRight
