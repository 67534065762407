import React from "react"
import { connectToAllEnabledElements } from "../../builderConnector"

const GreatAxeL = ({ allEnabledElements }) => {
  return allEnabledElements.includes("GreatAxeL") ? (
    <g id="GreatAxeL">
      <path d="M95.6,121.5c-6.8,0-6.2-6.6-6.2-6.6S88.8,75,88.3,53.4c-12.6,0-26.7-43.1,7.2-43.1S112.9,55,102,52.4 c0.2,10.9-0.6,61.7-0.6,61.7S102.4,121.5,95.6,121.5z" />
    </g>
  ) : null
}

export default connectToAllEnabledElements(GreatAxeL, { character: "ManMixed" })
