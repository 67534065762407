import React from "react"

const RightCuff = ({ colors = ["#898888", "#FFFFFF"] }) => {
  return (
    <g id="RightCuff">
      <path
        fill={colors[0]}
        d="M46.7,56.3c0.7-1.1,1.8-2.1,2.6-3.2c0.4-0.5,0.8-2.2,1.2-2.8c0.1-0.3,0.1-0.7,0-1.1 c-0.3-1.5-1.3-2.1-2.7-2.5c-1.8-0.6-2.5-0.6-3.6,0.3c-0.8,0.5-1.5,2-1.6,2.1c-0.5,1.5-0.5,2.9-1.1,4.3c0,0.1-0.8,2.5,2.1,3.6 C46.4,58.4,46.7,56.3,46.7,56.3z"
      />
      <path
        opacity="0.7"
        fill="#FFF"
        d="M44.2,47.1l-1.5,5.5h1l1.9-6.2c0,0-0.2,0-0.8,0.2 C44.4,46.9,44.2,47.1,44.2,47.1z"
      />
      <path
        opacity="0.2"
        fill="#FFF"
        d="M47.3,46.7L44.9,54l1.7,1.9l4-6.5c0,0-0.2-1-1-1.6 C48.7,47.1,47.3,46.7,47.3,46.7z"
      />
    </g>
  )
}

export default RightCuff
