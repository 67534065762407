import React from "react"
import { withSkinColor } from "../../builderConnector"

const LeftThumb = ({ skinColor, colors = ["#E7B080"] }) => {
  return (
    <g id="LeftThumb">
      <path
        fill={skinColor}
        d="M61.582859,83.570282c0.0926628-0.223938-0.42939-2.0240479-0.2440605-2.1630402 c0.1853256-0.1389999,1.7266769-0.7095337,1.8656731-0.755867c0.1389961-0.0463257,1.806942,1.0656357,1.806942,1.0656357 l-1.7427711,0.4356918c-0.3139915,0.0784988-0.5984039,0.2465591-0.8186378,0.4837265L61.582859,83.570282z"
      />
      <path
        opacity="0.3"
        d="M61.3522072,84.089386c0.0926628-0.2239304-0.1987381-2.5431519-0.0134087-2.6821442 c0.1853256-0.1389999,1.7266769-0.7095337,1.8656731-0.755867c0.1389961-0.0463257,1.806942,1.0656357,1.806942,1.0656357 l-1.7427711,0.4356918c-0.3139915,0.0784988-0.5984039,0.2465591-0.8186378,0.4837265l-0.5881882,1.3724976L61.3522072,84.089386z"
      />
    </g>
  )
}

export default withSkinColor(LeftThumb, { character: "ManGnome" })
